import { Flex, Link } from 'rebass/styled-components';
import { Attachment } from '@deepstream/common/rfq-utils';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useDownloadRfqAttachment } from '../ExchangeModal/AttachmentLink';

export const RfqAttachmentLink = ({
  attachment,
}: {
  attachment: Attachment;
}) => {
  const [download, { isLoading }] = useDownloadRfqAttachment();

  return (
    <Link
      variant="primary"
      display="inline-block"
      fontWeight={500}
      onClick={event => {
        event.stopPropagation();
        download(attachment);
      }}
      disabled={isLoading}
    >
      <Flex alignItems="center" sx={{ maxWidth: '100%' }}>
        <IconText
          isIconRegular
          gap={2}
          mr={2}
          icon="file"
          text={attachment.name}
          truncate
        />
        <Icon icon="download" />
      </Flex>
    </Link>
  );
};
