import * as React from 'react';
import { Panel, PanelProps } from '@deepstream/ui-kit/elements/Panel';
import { useQuestionnaireTemplateState } from './questionnaireTemplateUtils';

export const DraftPanel: React.FC<PanelProps & { panelId: string; heading: string }> = ({
  panelId,
  heading,
  ...props
}) => {
  // @ts-expect-error ts(2339) FIXME: Property 'editingPanelId' does not exist on type 'QuestionnaireTemplateStateContextType | undefined'.
  const { editingPanelId } = useQuestionnaireTemplateState();

  const isEditing = Boolean(editingPanelId);
  const isEditingThisPanel = panelId === editingPanelId;

  const isEditingAnotherPanel = isEditing && !isEditingThisPanel;

  return (
    <Panel
      as="section"
      aria-label={heading}
      sx={{
        opacity: isEditingAnotherPanel ? 0.5 : 1,
        boxShadow: isEditingThisPanel ? '0 0 8px 0 rgba(0, 0, 0, 0.3)' : '',
      }}
      {...props}
    />
  );
};
