import { Flex } from 'rebass/styled-components';
import { noop } from 'lodash';
import { EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { PanelHeader } from '@deepstream/ui-kit/elements/Panel';
import * as rfx from '../rfx';

type SummaryPanelHeaderProps = {
  heading: string;
  panelId: string;
};

export const SummaryPanelHeader = ({
  heading,
  panelId,
}: SummaryPanelHeaderProps) => {
  const { startEditing } = rfx.useActions();
  const { isLive, editingPanelId, isReview, isTemplatePreview } = rfx.useState();
  const isPendingCollaborator = rfx.useIsPendingCollaborator();
  const summaryNavigation = rfx.useSummaryNavigation();
  const rfxPermissions = rfx.useRfxPermissions();

  const isEditingAnyPanel = Boolean(editingPanelId);
  const isEditingThisPanel = editingPanelId && editingPanelId === panelId;

  return (
    <PanelHeader heading={heading}>
      <Flex alignItems="center">
        {isLive || isTemplatePreview || isPendingCollaborator || isEditingThisPanel ? (
          null
        ) : isReview ? (
          <EditButton
            small
            variant="primary"
            onClick={summaryNavigation?.navigateToSummary ?? noop}
          />
        ) : rfxPermissions.canEditSummary ? (
          <EditButton
            small
            onClick={() => startEditing(panelId)}
            disabled={isEditingAnyPanel}
          />
        ) : null}
      </Flex>
    </PanelHeader>
  );
};
