import { RfxSection, SectionType, getTagFromStageId } from '@deepstream/common/rfq-utils';
import { first } from 'lodash';
import { RfxStructure } from '../types';

export const isSectionVisibleInStage = (
  section: RfxSection,
  exchangeDefById: RfxStructure['exchangeDefById'],
  stageId: string,
) => {
  if (section.type === SectionType.LINE_ITEMS && section.responseTagConfig) {
    return section.responseTagConfig.tags.includes(getTagFromStageId(stageId));
  }

  const sectionStageId = first(section.stages);

  if (sectionStageId) {
    return sectionStageId === stageId;
  }

  return section.exchangeDefIds.some(exchangeDefId => {
    const exchangeDef = exchangeDefById[exchangeDefId];

    if (!exchangeDef) {
      return false;
    }

    const exchangeStageId = first(exchangeDef.stages);

    if (exchangeStageId) {
      return exchangeStageId === stageId;
    } else {
      // include sections with stageless exchange defs
      return true;
    }
  });
};
