import { DraftSpend } from './Spend/Spend';
import * as Layout from '../../Layout';
import { DraftHeader } from './DraftHeader';

export const DraftSpendPageContent = ({
  rfqId,
  isRevising,
  isTemplate,
  navigateToTeam,
}: {
  rfqId: string;
  isRevising?: boolean;
  isTemplate?: boolean;
  navigateToTeam: () => void;
}) => {
  const tabId = 'spend';

  return (
    <Layout.HeaderAndContent
      header={
        <DraftHeader
          rfqId={rfqId}
          isRevising={isRevising}
          isTemplate={isTemplate}
          selectedTabId={tabId}
        />
      }
      content={
        <DraftSpend
          rfqId={rfqId}
          isRevising={isRevising}
          isTemplate={isTemplate}
          navigateToTeam={navigateToTeam}
        />
      }
      contentPaddingBottom={Layout.BOTTOM_TOOLBAR_CONTENT_PADDING_BOTTOM}
    />
  );
};
