import { useQueryClient } from 'react-query';
import { Action } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { callAll } from '@deepstream/utils/callAll';
import { useApi } from '../../../../api';
import { useCurrentCompanyId } from '../../../../currentCompanyId';
import { useMutation } from '../../../../useMutation';
import { useExchangeRefetch } from '../../../../rfx';
import { useToaster } from '../../../../toast';
import { useQuestionnaireExchange, useQuestionnaireQueryKey } from '../useQuestionnaire';
import { SendExchangeReplyPayload } from '../../../../ExchangeModal/useSendExchangeReply';

export const useSendQuestionnaireExchangeReply = ({
  questionnaireId,
  exchangeId,
}: {
  questionnaireId: string;
  exchangeId: string;
}) => {
  const { t } = useTranslation('preQualification');
  const toaster = useToaster();
  const api = useApi();
  const queryClient = useQueryClient();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const questionnaireQueryKey = useQuestionnaireQueryKey();
  const refetchExchange = useExchangeRefetch();

  const { queryKey: exchangeQueryKey } = useQuestionnaireExchange({
    exchangeId,
    enabled: false,
  });

  return useMutation(
    async (action: SendExchangeReplyPayload) => {
      await api.sendQuestionnaireExchangeReply({
        questionnaireId,
        exchangeId,
        currentCompanyId,
        action: {
          ...action,
          companyId: currentCompanyId,
        },
      });

      if (refetchExchange) {
        // waiting for `refetchExchange()` ensures that the mutation promise is
        // only resolved after the exchange data has been updated; this allows
        // forms to set `isSubmitting` to `false` *after* the updated data has
        // arrived
        await refetchExchange();
      }
    },
    {
      mutationKey: ['sendQuestionnaireExchangeReply'],
      onSettled: callAll(
        () => queryClient.invalidateQueries(questionnaireQueryKey),
        refetchExchange ? undefined : () => queryClient.invalidateQueries(exchangeQueryKey),
      ),
      onSuccess: () => toaster.success(t('toaster.actionSubmitted.success')),
      onError: () => toaster.error(t('toaster.actionSubmitted.failed')),
    },
  );
};
