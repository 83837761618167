import { useCallback } from 'react';
import { FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';
import { Flex, Box, Text } from 'rebass/styled-components';
import { Company, Live } from '@deepstream/common/rfq-utils';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { callAll } from '@deepstream/utils/callAll';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { pick } from 'lodash';
import { useApi } from '../../../api';
import { CompanyLogo } from '../../../CompanyLogo';
import { TextField } from '../../../form/TextField';
import { ModalForm } from '../../../ModalForm';
import { useMutation } from '../../../useMutation';
import * as rfx from '../../../rfx';
import { useLiveRfqStructureQueryKey, useRfqId } from '../../../useRfq';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useToaster } from '../../../toast';
import { useWaitForRfqUnlock } from '../../../useWaitForUnlock';

type DeclineRecipientData = {
  companyId: string;
  companyName: string;
  reason: string;
};

const DeclineReasonField = ({
  name,
  recipient,
}: {
  name: string;
  recipient: DeclineRecipientData;
}) => (
  <Flex mb={1}>
    <Box flex="0 0 180px" mr={2}>
      <Truncate>
        <CompanyLogo companyId={recipient.companyId} />
        {recipient.companyName}
      </Truncate>
    </Box>
    <Box flex="1 1 auto">
      <TextField isMultiLine required name={name} />
    </Box>
  </Flex>
);

const DeclineToBidModalBase = ({
  isOpen,
  onCancel,
  onSubmit,
  recipients,
  hasSelectionWarning,
}: {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (values: { recipients: DeclineRecipientData[] }, actions) => void;
  recipients: Company[];
  hasSelectionWarning: boolean;
}) => {
  const { t } = useTranslation();

  const initialValues = {
    recipients: recipients.map(({ _id, company: { name } }) => ({
      companyId: _id,
      companyName: name,
      reason: '',
    })),
  };

  return (
    <ModalForm
      isOpen={isOpen}
      heading={t('declineToBid.heading')}
      onSubmit={onSubmit}
      onCancel={onCancel}
      validationSchema={yup.object().shape({
        recipients: yup.array().of(
          yup.object().shape({
            reason: yup.string().required(t('general.required')),
            companyId: yup.string().required(),
          }),
        ),
      })}
      initialValues={initialValues}
    >
      {!recipients.length ? (
        <MessageBlock variant="error">
          {t('declineToBid.messages.emptySelection')}
        </MessageBlock>
      ) : (
        <>
          <Flex>
            <Box flex="0 0 180px" mr={2}>
              <Text color="subtext" fontSize={1} fontWeight="700">
                {t('declineToBid.fields.company')}
              </Text>
            </Box>
            <Box flex="1 1 auto">
              <Text color="subtext" fontSize={1} fontWeight="700">
                {t('declineToBid.fields.reason')}
                <Text color="danger" ml={1} sx={{ display: 'inline-block' }}>*</Text>
              </Text>
            </Box>
          </Flex>
          <FieldArray name="recipients">
            {({ form }) =>
              form.values.recipients.map((recipient, index) => (
                <DeclineReasonField
                  key={recipient.companyId}
                  recipient={recipient}
                  name={`recipients.[${index}].reason`}
                />
              ))
            }
          </FieldArray>
          <MessageBlock variant="info" mt={0}>
            {t('declineToBid.messages.info')}
          </MessageBlock>
          {hasSelectionWarning && (
            <MessageBlock variant="info" mt={0}>
              {t('declineToBid.messages.partialSelection')}
            </MessageBlock>
          )}
        </>
      )}
    </ModalForm>
  );
};

export const DeclineToBidModal = ({
  recipients,
  hasSelectionWarning,
  isOpen,
  onClose,
}: {
  recipients: Company[];
  isOpen: boolean;
  hasSelectionWarning: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { bidById } = rfx.useStructure<Live>();
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqId = useRfqId({ required: true });
  const waitForRfqUnlock = useWaitForRfqUnlock();
  const liveStructureQueryKey = useLiveRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
  });

  const [declineToBidOnBehalf] = useMutation(
    (payload) => waitForRfqUnlock({
      command: () => api.declineToBidOnBehalf(payload),
    }),
    {
      onSuccess: callAll(
        () => toaster.success(t('request.suppliersTable.toaster.declineToBidSuccess')),
        () => queryClient.invalidateQueries(liveStructureQueryKey),
        () => queryClient.invalidateQueries(['allExchanges', { rfqId, currentCompanyId }]),
        () => queryClient.invalidateQueries(['exchanges', { rfqId, currentCompanyId }]),
        () => queryClient.invalidateQueries(['statsByRecipientId', { rfqId, currentCompanyId }]),
        onClose,
      ),
      onError: () => toaster.error(t('request.suppliersTable.toaster.declineToBidError')),
    },
  );

  const handleOnConfirm = useCallback(
    async (values: {
      recipients: DeclineRecipientData[];
    }) => {
      const declinedData = values.recipients.map((recipientData: DeclineRecipientData) => ({
        ...pick(recipientData, ['companyId', 'reason']),
        stageId: bidById[recipientData.companyId].stageId,
      }));

      await declineToBidOnBehalf({
        rfqId,
        recipients: declinedData,
        currentCompanyId,
      });
    },
    [declineToBidOnBehalf, bidById, rfqId, currentCompanyId],
  );

  return (
    <DeclineToBidModalBase
      isOpen={isOpen}
      onCancel={onClose}
      onSubmit={handleOnConfirm}
      recipients={recipients}
      hasSelectionWarning={hasSelectionWarning}
    />
  );
};
