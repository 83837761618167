import * as React from 'react';
import { Box, Flex, Text, TextProps } from 'rebass/styled-components';
import {
  Company,
  CurrencyExchangeDefinition,
  ExchangeProvider,
  ExchangeType,
  LineItemExchangeDefinition,
  SectionType,
  RfxOtherSection,
  StageResponseTag,
  isBuyerReplyField,
  getStageIdFromTag,
  FieldConfig,
  isDefinitionField,
  isFormulaField,
  isSupplierReplyField,
} from '@deepstream/common/rfq-utils';
import { find, flatMap, isEmpty, keyBy, mapValues, propertyOf, sumBy, compact, isNil, dropRightWhile, assign, pickBy, isFinite, findLastIndex, identity, takeWhile } from 'lodash';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Clamp } from '@deepstream/ui-kit/elements/text/Clamp';
import { OverflowTooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { KeyCode } from '@deepstream/ui-utils/KeyCode';
import { ComparisonGrid } from '@deepstream/ui-kit/grid/core/ComparisonGrid';
import { DefaultComparisonGridStyles } from '@deepstream/ui-kit/grid/core/ComparisonGridStyles';
import { DEFAULT_FROZEN_LEFT_COLUMN_IDS } from '@deepstream/ui-kit/grid/core/constants';
import {
  ColumnData,
  DataCellProps,
  FrozenHeaderCellProps,
  GridDataAndCommands,
  isExpandable,
  RowData,
} from '@deepstream/ui-kit/grid/core/utils';
import { stopEvent } from '@deepstream/ui-utils/domEvent';
import { LineItemsExchangeSnapshot, PublishedExchangeDefinition, RfxStructure } from '../../../../types';
import {
  exchangeDefLineClampByRowHeight,
  exchangeLineClampByRowHeight,
  twoLinesRowLineClamp,
  RowHeight,
  rowHeightMeasurements,
} from '../rowHeight';
import { UseExchangeModalStateProps } from '../../../../useExchangeModalState';
import * as rfx from '../../../../rfx';
import { createLineItemRecipientHeaderCell, lineItemsHeaderHeight } from './createLineItemRecipientHeaderCell';
import { InlineLabel } from '../InlineLabel';
import { ConvertedCurrency } from '../../../../ui/Currency';
import { useCurrentCompanyId } from '../../../../currentCompanyId';
import { useRfqId } from '../../../../useRfq';
import { useExchangeRates } from '../../../../useExchangeRates';
import { SavingsDisplayMode } from './useSelectSavingsDisplayMode';
import { useNavigate } from '../../../../tanstackRouter';
import { legacyRequestSentRecipientBidIndexRoute, requestSentRecipientBidIndexRoute } from '../../../../AppRouting';
import { computeFormula } from '../../Live/restorePreviousLineItemResponseStage';
import { SectionCellContent } from '../SectionCellContent';
import { BuyerDataCellWithSubcolumns } from './BuyerDataCellWithSubcolumns';
import { LockedSupplierDataCell } from './LockedSupplierDataCell';
import { SupplierDataCellWithSubcolumns } from './SupplierDataCellWithSubcolumns';
import { DiffFromStage } from './DiffFromStage';
import { ExtendedLineItemExchangeSnapshot, GridLineItemsSection, LineItemsSubcolumnConfig, LineItemSubRowData } from './types';

const EMPTY_STRING = '';

const MIN_DATA_CELL_WIDTH = 250;

const staticGridCellWidthsMap = {
  exchangeStatus: 42,
  description: 300,
  targetPrice: 150,
  price: 150,
  totalCost: 170,
  'leadTime:submitter': 100,
  unspscCode: 150,
};

const CUSTOM_FIELD_SUBCOLUMN_WIDTH = 150;

type GridContentData = Record<string, {
  exchangeById: Record<string, ExtendedLineItemExchangeSnapshot>;
  sectionsById: Record<string, GridLineItemsSection>;
  currencyBySectionId: Record<string, string>;
}>;

const getSubcolumnWidth = (columnId: string) => {
  return staticGridCellWidthsMap[columnId] || CUSTOM_FIELD_SUBCOLUMN_WIDTH;
};

function getExchangeTotal(exchange: ExtendedLineItemExchangeSnapshot) {
  if (!exchange) {
    return 0;
  }
  return exchange.computedFormulas?.totalCost;
}

const TotalPrice = ({
  t,
  value,
  previousValue,
  stageIndex,
  currencyCode,
  savingsDisplayMode,
  totalCostSubcolumnOffset,
  totalCostSubcolumnWidth,
}: TextProps & {
  t: TFunction;
  value: number;
  currencyCode: string;
  previousValue?: number;
  stageIndex?: number;
  savingsDisplayMode?: SavingsDisplayMode;
  totalCostSubcolumnOffset: number;
  totalCostSubcolumnWidth: number;
}) => {
  return (
    <Flex flexDirection="row" height="100%" width="100%" alignItems="center">
      {totalCostSubcolumnOffset && (
        <Flex
          sx={{ height: '45px' }}
          style={{ width: `${totalCostSubcolumnOffset}px` }}
          py={2}
        >
          &nbsp;
        </Flex>
      )}
      <Text
        fontSize={1}
        textAlign="right"
        py={2}
        px="9px"
        sx={{ width: totalCostSubcolumnWidth }}
      >
        <Truncate textAlign="right">
          {isFinite(value) ? (
            <>
              <InlineLabel mr="6px">
                {t('general.total')}
              </InlineLabel>
              <ConvertedCurrency value={value} currencyCode={currencyCode} />
            </>
          ) : (
            null
          )}
        </Truncate>
        {isFinite(value) && isFinite(previousValue) ? (
          <DiffFromStage
            value={value}
            previousValue={previousValue!}
            // @ts-expect-error ts(2322) FIXME: Type 'number | undefined' is not assignable to type 'number'.
            stageIndex={stageIndex}
            // @ts-expect-error ts(2322) FIXME: Type 'SavingsDisplayMode | undefined' is not assignable to type 'SavingsDisplayMode'.
            savingsDisplayMode={savingsDisplayMode}
            mt="2px"
            currencyCode={currencyCode}
          />
        ) : (
          null
        )}
      </Text>
    </Flex>
  );
};

const createFrozenFooterCell = ({
  t,
  submitterSubcolumnConfig,
  gridContentData,
  savingsDisplayMode,
  totalCostSubcolumnOffset,
  totalCostSubcolumnWidth,
  senders,
}: {
  t: TFunction;
  submitterSubcolumnConfig: LineItemsSubcolumnConfig[];
  gridContentData: GridContentData;
  savingsDisplayMode: SavingsDisplayMode;
  totalCostSubcolumnOffset: number;
  totalCostSubcolumnWidth: number;
  senders: Company[];
}) =>
  ({ column }: FrozenHeaderCellProps<Company>) => {
    const recipientId = column.original._id;
    const data = gridContentData[recipientId];

    if (!data) {
      return null;
    }

    const exchanges = Object.values(data.exchangeById);

    const isBuyerFieldsColumn = !!find(senders, ['_id', column.original._id]);
    const isSameCurrency = exchanges.every(exchange => exchange.currency === exchanges[0].currency);
    if (isBuyerFieldsColumn || !isSameCurrency || !exchanges.length) {
      return null;
    }

    const hasTotalCost = submitterSubcolumnConfig.some(data => data._id === 'totalCost');

    if (!hasTotalCost) {
      return null;
    }

    const isAnyExchangeLocked = exchanges.some(exchange => exchange && exchange.isLocked);
    if (isAnyExchangeLocked) {
      return (
        <Text
          fontSize={1}
          width={`${totalCostSubcolumnWidth}px`}
          textAlign="right"
          py={2}
          px="9px"
          ml={`${totalCostSubcolumnOffset}px`}
        >
          <EmDash />
        </Text>
      );
    }

    // We don't display the percentage difference to the previous stage's price
    // if the reciepient's exchanges' percentage differences are relative to
    // multiple previous stages. (Example: When displaying the multi-stage responses
    // at stage 3, one section has multi-stage responses in stages 1 and 3 and
    // another section in stages 2 and 3.)
    // @ts-expect-error ts(18048) FIXME: 'exchange.previousStageIndex' is possibly 'undefined'.
    const previousStageIndex = exchanges.find(exchange => exchange.previousStageIndex > -1)?.previousStageIndex;
    const previousValue = (
      // @ts-expect-error ts(18048) FIXME: 'previousStageIndex' is possibly 'undefined'.
      previousStageIndex > -1 &&
      isFinite(previousStageIndex) &&
      exchanges.every(exchange => exchange.previousStageIndex === previousStageIndex || isNil(exchange.previousStageIndex))
    )
      ? sumBy(exchanges, exchange => exchange?.previousComputedFormulas?.totalCost as number)
      : null;

    return (
      <TotalPrice
        t={t}
        // @ts-expect-error ts(2345) FIXME: Argument of type '(exchange: ExtendedLineItemExchangeSnapshot) => number | undefined' is not assignable to parameter of type 'string | ((value: ExtendedLineItemExchangeSnapshot) => number) | undefined'.
        value={sumBy(exchanges, getExchangeTotal)}
        currencyCode={exchanges[0].currency}
        stageIndex={previousStageIndex}
        // @ts-expect-error ts(2322) FIXME: Type 'number | null' is not assignable to type 'number | undefined'.
        previousValue={previousValue}
        savingsDisplayMode={savingsDisplayMode}
        totalCostSubcolumnOffset={totalCostSubcolumnOffset}
        totalCostSubcolumnWidth={totalCostSubcolumnWidth}
      />
    );
  };

const FirstColumnCell = ({ row }: DataCellProps<Company, LineItemSubRowData, GridLineItemsSection>) => {
  const { t } = useTranslation('translation');

  if (isExpandable(row)) {
    return (
      <SectionCellContent
        icon="list-ul"
        sectionName={row.original.name}
        // @ts-expect-error ts(2322) FIXME: Type 'string | null | undefined' is not assignable to type 'string | undefined'.
        lotName={row.original.lotName}
      />
    );
  }

  if (row.original.isSubHeader) {
    return (
      <Flex flexDirection="row" alignItems="center" height="32px">
        <Clamp lines={exchangeDefLineClampByRowHeight[row.height]} style={{ flex: 1 }}>
          {row.original.description}
        </Clamp>
      </Flex>
    );
  }

  const unitField = row.original.fields.unit;
  const quantityField = row.original.fields.quantity;

  return (
    <Flex flexDirection="row" alignItems="center" height="100%" width="100%">
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        sx={{ height: '100%', flex: 1 }}
        p={2}
      >
        <OverflowTooltip content={row.original.description}>
          <Clamp lines={twoLinesRowLineClamp[row.height]}>
            {row.original.description}
          </Clamp>
        </OverflowTooltip>
        {row.height !== rowHeightMeasurements[RowHeight.SHORT] ? (
          <Flex fontSize="9px" fontWeight="normal" letterSpacing="0.3px" alignItems="center" sx={{ gap: 1 }}>
            {unitField && (
              <OverflowTooltip content={row.original[unitField.source.key]}>
                <Truncate flexGrow={1} maxWidth="max-content">
                  <InlineLabel>{t('general.unit')}</InlineLabel>
                  {row.original[unitField.source.key]}
                </Truncate>
              </OverflowTooltip>
            )}
            {quantityField && (
              <Box minWidth="max-content">
                <InlineLabel>{t('general.quantity')}</InlineLabel>
                {row.original[quantityField.source.key]}
              </Box>
            )}
          </Flex>
        ) : (
          null
        )}
      </Flex>
    </Flex>
  );
};

const createDataCell =
  ({
    gridContentData,
    openExchangeModal,
    t,
    evaluatorSubcolumnConfig,
    submitterSubcolumnConfig,
    totalCostSubcolumnOffset,
    savingsDisplayMode,
    senders,
  }: {
    gridContentData: GridContentData;
    openExchangeModal: (
      row: RowData<LineItemSubRowData, GridLineItemsSection>,
      column: ColumnData<Company>
    ) => void;
    t: TFunction;
    evaluatorSubcolumnConfig: LineItemsSubcolumnConfig[];
    submitterSubcolumnConfig: LineItemsSubcolumnConfig[];
    totalCostSubcolumnOffset: number;
    savingsDisplayMode: SavingsDisplayMode;
    senders: Company[];
  }) =>
    ({
      row,
      column,
      isActive,
    }: DataCellProps<Company, LineItemSubRowData, GridLineItemsSection>) => {
      const recipientId = column.original._id;

      const hasTotalCost = submitterSubcolumnConfig.some(data => data._id === 'totalCost');

      if (isExpandable(row)) {
        const data = gridContentData[recipientId]?.sectionsById?.[row.original._id];

        if (!data) {
          return null;
        }

        const exchanges = Object.values(data.subRows.map(subRow => gridContentData[recipientId]?.exchangeById[subRow._id]));
        const isAnyExchangeLocked = exchanges.some(exchange => exchange && exchange.isLocked);
        const currency = find(exchanges, 'currency')?.currency;

        const hideSectionTotal = !exchanges.find(exchange =>
          exchange?.def.type === ExchangeType.LINE_ITEM &&
          !find(senders, ['_id', recipientId]),
        )?.def.fields.totalCost || !hasTotalCost;

        return hideSectionTotal ? null : (
          <TotalPrice
            t={t}
            // @ts-expect-error ts(2345) FIXME: Argument of type '(exchange: ExtendedLineItemExchangeSnapshot) => number | undefined' is not assignable to parameter of type 'string | ((value: ExtendedLineItemExchangeSnapshot) => number) | undefined'.
            value={isAnyExchangeLocked ? NaN : sumBy(exchanges, getExchangeTotal)}
            currencyCode={currency || ''}
            totalCostSubcolumnOffset={totalCostSubcolumnOffset}
            totalCostSubcolumnWidth={staticGridCellWidthsMap.totalCost}
          />
        );
      }

      if (row.original.isSubHeader && row.original.sectionId) {
        const subsectionExchanges = row.original.lineItemsIds?.map((id) => gridContentData[recipientId]?.exchangeById[id]) ?? [];
        // @ts-expect-error ts(2345) FIXME: Argument of type '(exchange: ExtendedLineItemExchangeSnapshot) => number | undefined' is not assignable to parameter of type 'string | ((value: ExtendedLineItemExchangeSnapshot) => number) | undefined'.
        const subsectionTotal = sumBy(subsectionExchanges, getExchangeTotal);
        const currency = gridContentData[recipientId]?.currencyBySectionId[row.original.sectionId];
        const isAnyExchangeLocked = subsectionExchanges.some(exchange => exchange && exchange.isLocked);

        const hideSubsectionTotal = !subsectionExchanges[0]?.def.fields.totalCost ||
          !!find(senders, ['_id', recipientId]) || !hasTotalCost;

        return hideSubsectionTotal ? null : (
          <TotalPrice
            t={t}
            value={isAnyExchangeLocked ? NaN : subsectionTotal ?? 0}
            currencyCode={currency}
            totalCostSubcolumnOffset={totalCostSubcolumnOffset}
            totalCostSubcolumnWidth={staticGridCellWidthsMap.totalCost}
          />
        );
      }

      const exchangeDefId = row.original._id;
      const exchange = gridContentData[recipientId]?.exchangeById[exchangeDefId];
      if (!exchange) {
        return (
          <Text py={2} px="9px" color="subtext">
            {t('general.notApplicableShort')}
          </Text>
        );
      }

      const isBuyerFieldsColumn = !!find(senders, ['_id', column.original._id]);

      return (
        <Flex justifyContent="space-between" alignItems="stretch" height="100%">
          {isBuyerFieldsColumn ? (
            <BuyerDataCellWithSubcolumns
              exchange={exchange}
              subcolumnConfig={evaluatorSubcolumnConfig}
              row={row}
              isSupplierExchange={row.original.isProvidedBySupplier}
            />
          ) : exchange.isLocked ? (
            <LockedSupplierDataCell
              exchange={exchange}
              row={row}
              column={column}
              exchangeStatusSubcolumnWidth={staticGridCellWidthsMap.exchangeStatus}
              isRowActive={isActive}
              openExchangeModal={openExchangeModal}
            />
          ) : (
            <SupplierDataCellWithSubcolumns
              exchange={exchange}
              hasShortCellHeight={row.height === rowHeightMeasurements[RowHeight.SHORT]}
              subcolumnConfig={submitterSubcolumnConfig}
              lines={exchangeLineClampByRowHeight[row.height]}
              savingsDisplayMode={savingsDisplayMode}
              row={row}
              column={column}
              isRowActive={isActive}
              openExchangeModal={openExchangeModal}
            />
          )}
        </Flex>
      );
    };

export const useRowData = (structure: RfxStructure, t: TFunction) => React.useMemo(() => {
  const {
    settings,
    recipients,
    senders,
    pages,
    sectionById,
    exchangeDefById,
    lotById,
    lots,
  } = structure;

  const allSectionIds = flatMap(pages, (page) => page.sections);
  const lineItemsSections: RfxOtherSection[] = allSectionIds
    .map(propertyOf(sectionById))
    .filter((section) => section.type === SectionType.LINE_ITEMS);

  const exchangeDefsBySectionId: Record<string, (LineItemExchangeDefinition | CurrencyExchangeDefinition)[]> = lineItemsSections
    .reduce((acc, section) => ({
      ...acc,
      [section._id]: section.exchangeDefIds.map(propertyOf(exchangeDefById)),
    }), {});

  const nonObsoleteLineItemsSections = lineItemsSections.filter((section) =>
    exchangeDefsBySectionId[section._id].some(
      (exchangeDef) => !exchangeDef.isObsolete,
    ),
  );

  const recipientsById = keyBy(recipients, '_id');
  const sendersById = keyBy(senders, '_id');

  const { areLotsEnabled } = settings;

  const sectionsWithSubRows = nonObsoleteLineItemsSections.map(
    (section) => {
      const nonObsoleteExchangeDefs = exchangeDefsBySectionId[section._id].filter(
        (exchangeDef): exchangeDef is LineItemExchangeDefinition =>
          !exchangeDef.isObsolete && exchangeDef.type === ExchangeType.LINE_ITEM,
      );
      const currency = nonObsoleteExchangeDefs[0]?.evaluatorFieldCurrency;

      // @ts-expect-error ts(2322) FIXME: Type '{ isSubHeader: false; isProvidedBySupplier: false; currency: string | null | undefined; type: ExchangeType.LINE_ITEM; fields: LineItemExchangeFields & Record<...>; ... 24 more ...; productOrService?: ProductTag | ... 1 more ... | undefined; }[]' is not assignable to type 'LineItemSubRowData[]'.
      const buyerLineItemDefs: LineItemSubRowData[] = nonObsoleteExchangeDefs
        .filter(exchangeDef => sendersById[(exchangeDef as PublishedExchangeDefinition).creatorId])
        .map(exchangeDef => ({
          ...exchangeDef,
          isSubHeader: false,
          isProvidedBySupplier: false,
          currency,
        }));

      const supplierLineItemDefs = nonObsoleteExchangeDefs
        .filter(exchangeDef => recipientsById[(exchangeDef as PublishedExchangeDefinition).creatorId])
        .map(exchangeDef => ({
          ...exchangeDef,
          isSubHeader: false,
          isProvidedBySupplier: true,
        }));

      return {
        ...section,
        lotId: !areLotsEnabled
          ? null
          : section.lotIds?.[0] || 'general',
        lotName: !areLotsEnabled ? (
          null
        ) : section.lotIds?.[0] ? (
          // @ts-expect-error ts(18048) FIXME: 'section.lotIds' is possibly 'undefined'.
          `${t('request.lot', { count: 1 })} ${lots.findIndex(lot => lot._id === section.lotIds[0]) + 1} – ${lotById[section.lotIds[0]].name}`
        ) : (
          t('request.generalRequirement_other')
        ),
        subRows: [
          ...(buyerLineItemDefs.length ? [{
            _id: `${section._id}_${ExchangeProvider.BUYER}`,
            isSubHeader: true,
            sectionId: section._id,
            description: t('request.lineItems.buyerAddedComparison'),
            lineItemsIds: buyerLineItemDefs.map(({ _id }) => _id),
            currency,
          }] : []),
          ...(buyerLineItemDefs.length ? buyerLineItemDefs : []),
          ...(supplierLineItemDefs.length ? [{
            _id: `${section._id}_${ExchangeProvider.SUPPLIER}`,
            isSubHeader: true,
            sectionId: section._id,
            description: t('request.lineItems.supplierAddedComparison'),
            lineItemsIds: supplierLineItemDefs.map(({ _id }) => _id),
            currency,
          },
          ...supplierLineItemDefs] : []),
        ],
      } as GridLineItemsSection;
    },
  );

  return sectionsWithSubRows.filter(({ subRows }) => !isEmpty(subRows));
}, [structure, t]);

const useGridContentData = ({
  columnData,
  rowData,
  exchanges,
  currencyExchanges,
  selectedResponseTag,
  responseTags,
}: {
  columnData: Company[];
  rowData: ReturnType<typeof useRowData>;
  exchanges: ExtendedLineItemExchangeSnapshot[];
  currencyExchanges: any[];
  selectedResponseTag?: StageResponseTag;
  responseTags: StageResponseTag[];
}) => {
  const exchangeRates = useExchangeRates();
  const { stages } = rfx.useStructure();
  const currentCompanyId = useCurrentCompanyId();

  return React.useMemo(() => {
    const previousAndCurrentResponseTags = selectedResponseTag
      ? dropRightWhile(responseTags, responseTag => responseTag !== selectedResponseTag)
      : null;

    const exchangeIds = flatMap(rowData, ({ subRows }) =>
      subRows.map((subRow) => subRow._id ?? ''),
    );
    const sectionsById = keyBy(rowData, '_id');
    const recipientById = keyBy(columnData, '_id');

    return mapValues(recipientById, (_, recipientId) => {
      const recipientExchanges = exchanges.filter((exchange) =>
        currentCompanyId === recipientId ||
        (exchange.recipientId === recipientId &&
        exchangeIds.includes(exchange._id)),
      );

      // adjust latestReply and computed values to selected response tag
      const adjustedRecipientExchanges = selectedResponseTag
        ? recipientExchanges.map(exchange => {
          if (exchange.supplierReplyByTag) {
            const buyerReplies = pickBy(
              exchange.latestReply,
              (_, key) => isBuyerReplyField(exchange.def.fields[key]),
            );
            // @ts-expect-error ts(18047) FIXME: 'previousAndCurrentResponseTags' is possibly 'null'.
            const supplierReplies = previousAndCurrentResponseTags.map(responseTag => {
              return exchange.supplierReplyByTag?.[responseTag] || null;
            });
            const previousSupplierReplies = supplierReplies.slice(0, -1);
            const latestReply = assign({}, buyerReplies, ...supplierReplies);
            const previousReply = assign({}, buyerReplies, ...previousSupplierReplies);
            const computedFormulas = mapValues(
              exchange.computedFormulas,
              (computedValue, fieldId) => {
                // The target total cost only gets calculated when it does not
                // depend on supplier-provided fields; thus, it's the same for
                // all supplier response stages and we can keep the value that
                // exists on the exchange.
                return fieldId === 'targetTotalCost'
                  ? computedValue
                  : computeFormula({
                    exchange,
                    fieldId,
                    valuesMap: latestReply,
                    exchangeRates,
                  });
              },
            );
            const previousComputedFormulas = mapValues(
              exchange.computedFormulas,
              (computedValue, fieldId) => {
                // The target total cost only gets calculated when it does not
                // depend on supplier-provided fields; thus, it's the same for
                // all supplier response stages and we can keep the value that
                // exists on the exchange.
                return fieldId === 'targetTotalCost'
                  ? computedValue
                  : computeFormula({
                    exchange,
                    fieldId,
                    valuesMap: previousReply,
                    exchangeRates,
                  });
              },
            );

            const previousResponseIndex = findLastIndex(
              previousSupplierReplies,
              identity,
            );
            // @ts-expect-error ts(18047) FIXME: 'previousAndCurrentResponseTags' is possibly 'null'.
            const previousTag = previousAndCurrentResponseTags[previousResponseIndex];

            const previousStageId = previousTag ? getStageIdFromTag(previousTag) : null;

            const previousStageIndex = previousStageId
              ? stages.findIndex(stage => stage._id === previousStageId)
              : -1;

            return {
              ...exchange,
              latestReply,
              previousReply,
              computedFormulas,
              previousComputedFormulas,
              previousStageIndex,
              selectedResponseTag,
            };
          } else {
            return exchange;
          }
        })
        : recipientExchanges;

      const recipientExchangesById = keyBy(adjustedRecipientExchanges, '_id');
      const currencyBySectionId = mapValues(sectionsById, (section) => {
        const currencyExchange = currencyExchanges.find(currencyExchange => (
          currencyExchange.recipientId === recipientId &&
          currencyExchange.def.sectionId === section._id
        ));

        return currencyExchange?.latestCurrency;
      });

      return {
        exchangeById: recipientExchangesById,
        sectionsById,
        currencyBySectionId,
      };
    });
  }, [currentCompanyId, selectedResponseTag, responseTags, rowData, columnData, exchanges, stages, exchangeRates, currencyExchanges]);
};

export const LineItemsComparisonGrid = ({
  rowData,
  recipientColumns,
  orderedFields,
  subRowHeight,
  collapsedRowIds,
  setCollapsedRowIds,
  setExchangeModalProps,
  selectedVisibleFieldsetIds,
  selectedResponseTag,
  responseTags,
  savingsDisplayMode,
  gridRef,
}: {
  rowData: ReturnType<typeof useRowData>;
  recipientColumns: Company[];
  orderedFields: FieldConfig[];
  subRowHeight: number;
  collapsedRowIds: string[];
  setCollapsedRowIds: React.Dispatch<React.SetStateAction<string[]>>;
  setExchangeModalProps: (props: UseExchangeModalStateProps) => void;
  selectedVisibleFieldsetIds: string[];
  selectedResponseTag?: StageResponseTag;
  responseTags: StageResponseTag[];
  savingsDisplayMode: SavingsDisplayMode;
  gridRef?: React.MutableRefObject<any>;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const navigate = useNavigate();
  const rfqId = useRfqId();
  const structure = rfx.useStructure();
  const allExchanges = rfx.useExchanges();
  const exchanges = React.useMemo(
    () => allExchanges.filter(exchange => exchange.def.type === ExchangeType.LINE_ITEM) as LineItemsExchangeSnapshot[],
    [allExchanges],
  );
  const currencyExchanges = React.useMemo(
    () => allExchanges.filter(exchange => exchange.def.type === ExchangeType.CURRENCY),
    [allExchanges],
  );
  const senders = rfx.useSenders();
  const currentCompany = senders.find(sender => sender._id === currentCompanyId);

  const evaluatorSubcolumnConfig = React.useMemo(() => {
    if (isEmpty(orderedFields)) {
      return [];
    }

    const subcolumnConfig = orderedFields
      .filter(field => {
        if (!isDefinitionField(field) && !isBuyerReplyField(field)) {
          return false;
        }

        const fieldsetId = field._id.split(':')[0];

        if (!selectedVisibleFieldsetIds.includes(fieldsetId)) {
          return false;
        }

        return true;
      })
      .map(field => ({
        _id: field._id,
        label: (field as any).label,
        width: getSubcolumnWidth(field._id),
      }));

    const subColumnsWidth = sumBy(subcolumnConfig, 'width');
    if (subcolumnConfig.length > 0 && subColumnsWidth < MIN_DATA_CELL_WIDTH) {
      // When the subcolumns width is smaller than the parent column width,
      // increase the last column width to fit the parent column width
      subcolumnConfig[subcolumnConfig.length - 1].width += MIN_DATA_CELL_WIDTH - subColumnsWidth;
    }

    return subcolumnConfig;
  }, [selectedVisibleFieldsetIds, orderedFields]);

  const submitterSubcolumnConfig = React.useMemo(() => {
    if (isEmpty(orderedFields)) {
      return [];
    }

    const subcolumnConfig = orderedFields
      .filter(field => {
        if (!isFormulaField(field) && !isSupplierReplyField(field)) {
          return false;
        }

        const fieldsetId = field._id.split(':')[0];

        if (!selectedVisibleFieldsetIds.includes(fieldsetId)) {
          return false;
        }

        return true;
      })
      .map(field => ({
        _id: field._id,
        label: (field as any).label,
        width: getSubcolumnWidth(field._id),
      }));

    // Prepend the status subcolumn if any column is visible
    if (subcolumnConfig.length > 0) {
      const exchangeStatusSubcolumn = {
        _id: 'exchangeStatus',
        label: EMPTY_STRING,
        width: staticGridCellWidthsMap.exchangeStatus,
      };

      subcolumnConfig.unshift(exchangeStatusSubcolumn);
    }

    const subColumnsWidth = sumBy(subcolumnConfig, 'width');
    if (subcolumnConfig.length > 0 && subColumnsWidth < MIN_DATA_CELL_WIDTH) {
      // When the subcolumns width is smaller than the parent column width,
      // increase the last column width to fit the parent column width
      subcolumnConfig[subcolumnConfig.length - 1].width += MIN_DATA_CELL_WIDTH - subColumnsWidth;
    }

    return subcolumnConfig;
  }, [orderedFields, selectedVisibleFieldsetIds]);

   // @ts-expect-error ts(2322) FIXME: Type '({ width: number; _id: string; users: User[]; isPending?: boolean | undefined; email?: string | undefined; company: CompanyMinimized; } | { width: number; role?: string | undefined; ... 8 more ...; company?: CompanyMinimized | undefined; })[]' is not assignable to type 'Company[]'.
   const columnData: Company[] = React.useMemo(() => {
    return compact([
      evaluatorSubcolumnConfig.length > 0
        ? {
            ...currentCompany,
            width: sumBy(evaluatorSubcolumnConfig, 'width'),
          }
        : null,
      ...(submitterSubcolumnConfig.length > 0
        ? recipientColumns.map((recipientColumn) => ({
            ...recipientColumn,
            width: sumBy(submitterSubcolumnConfig, 'width'),
          }))
        : []
      ),
    ]);
  }, [evaluatorSubcolumnConfig, currentCompany, recipientColumns, submitterSubcolumnConfig]);

  const gridContentData = useGridContentData({
    columnData,
    rowData,
    exchanges,
    currencyExchanges,
    selectedResponseTag,
    responseTags,
  });

  const openExchangeModal = React.useCallback(
    (
      row: RowData<LineItemSubRowData, GridLineItemsSection>,
      column: ColumnData<Company>,
    ) => {
      const exchangeId = row.original._id;
      const recipientId = column.original._id;

      if (
        !isExpandable(row) &&
        !row.original.isSubHeader &&
        !!gridContentData[recipientId].exchangeById[exchangeId]
      ) {
        setExchangeModalProps({ exchangeId, recipientId });
      }
    },
    [setExchangeModalProps, gridContentData],
  );

  const frozenLeftColumnIds = DEFAULT_FROZEN_LEFT_COLUMN_IDS;
  const frozenLeftSubcolumnData = React.useMemo(
    () => {
      if (isEmpty(orderedFields)) {
        return [];
      }

      // TODO: We will update the logic in the future to allow the user to select which columns should be frozen
      // For now we only leave description, but we don't cleanup all the logic yet.
      return DEFAULT_FROZEN_LEFT_COLUMN_IDS.map((_id) => ({
          _id,
          width: getSubcolumnWidth(_id),
        }),
      );
    },
    [orderedFields],
  );

  const frozenLeftColumnWidth = React.useMemo(() => {
    if (!frozenLeftSubcolumnData) {
      return 0;
    }

    return sumBy(frozenLeftSubcolumnData, 'width');
  }, [frozenLeftSubcolumnData]);

  const {
    FrozenHeaderCell,
    DataCell,
  } = React.useMemo(() => {
    return {
      FrozenHeaderCell: createLineItemRecipientHeaderCell({
        structure,
        showBidStatus: true,
        navigateToBidPage: (recipientId: string) => {
          navigate({
            to: structure.newFeaturesDisabled
              ? legacyRequestSentRecipientBidIndexRoute.to
              : requestSentRecipientBidIndexRoute.to,
            params: { currentCompanyId, rfqId, recipientId },
          });
        },
        evaluatorSubcolumnConfig,
        submitterSubcolumnConfig,
      }),
      DataCell: createDataCell({
        // @ts-expect-error ts(2322) FIXME: Type '{ [x: string]: { exchangeById: Dictionary<ExtendedLineItemExchangeSnapshot | { latestReply: any; previousReply: any; computedFormulas: { [x: string]: number | null; }; ... 42 more ...; supplierReplyByTag?: Record<...> | ... 1 more ... | undefined; }>; sectionsById: Dictionary<...>; currencyBySectionId: { ...; }; }; }' is not assignable to type 'GridContentData'.
        gridContentData,
        openExchangeModal,
        t,
        evaluatorSubcolumnConfig,
        submitterSubcolumnConfig,
        totalCostSubcolumnOffset: sumBy(
          takeWhile(submitterSubcolumnConfig, data => data._id !== 'totalCost'),
          data => data.width,
        ),
        savingsDisplayMode,
        senders,
      }),
    };
  }, [
    currentCompanyId,
    evaluatorSubcolumnConfig,
    gridContentData,
    navigate,
    openExchangeModal,
    rfqId,
    savingsDisplayMode,
    senders,
    structure,
    submitterSubcolumnConfig,
    t,
  ]);

  const FrozenFooterCell = React.useMemo(() => createFrozenFooterCell({
    // @ts-expect-error ts(2322) FIXME: Type '{ [x: string]: { exchangeById: Dictionary<ExtendedLineItemExchangeSnapshot | { latestReply: any; previousReply: any; computedFormulas: { [x: string]: number | null; }; ... 42 more ...; supplierReplyByTag?: Record<...> | ... 1 more ... | undefined; }>; sectionsById: Dictionary<...>; currencyBySectionId: { ...; }; }; }' is not assignable to type 'GridContentData'.
    gridContentData,
    t,
    submitterSubcolumnConfig,
    totalCostSubcolumnOffset: sumBy(
      takeWhile(submitterSubcolumnConfig, data => data._id !== 'totalCost'),
      data => data.width,
    ),
    totalCostSubcolumnWidth: staticGridCellWidthsMap.totalCost,
    savingsDisplayMode,
    senders,
  }), [gridContentData, t, submitterSubcolumnConfig, savingsDisplayMode, senders]);

  const handleDataCellKeyboardAction = React.useCallback((
    data: GridDataAndCommands<
      (typeof columnData)[number],
      (typeof rowData)[number]['subRows'][number],
      (typeof rowData)[number]
    >,
    event: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    // @ts-expect-error ts(18047) FIXME: 'data.activeCellIndices' is possibly 'null'.
    const row = data.rows[data.activeCellIndices.rowIndex];
    // @ts-expect-error ts(18047) FIXME: 'data.activeCellIndices' is possibly 'null'.
    const column = data.columns[data.activeCellIndices.columnIndex];

    if ([KeyCode.ENTER, KeyCode.SPACE].includes(event.code as KeyCode) && column.original._id !== currentCompanyId) {
      stopEvent(event);

      // @ts-expect-error ts(2345) FIXME: Argument of type 'RowData<LineItemSubRowData, GridLineItemsSection> | null' is not assignable to parameter of type 'RowData<LineItemSubRowData, GridLineItemsSection>'.
      openExchangeModal(row, column);
    }
  }, [currentCompanyId, openExchangeModal]);

  const columnDataWithFirstColumn = React.useMemo(() => {
    return [
      {
        _id: 'description',
        width: frozenLeftColumnWidth,
      } as any,
      ...columnData,
    ];
  }, [columnData, frozenLeftColumnWidth]);

  return rowData ? (
    <DefaultComparisonGridStyles
      frozenHeaderHeight={lineItemsHeaderHeight}
      cellPadding="0"
      headerCellPadding="0"
      subHeaderCellPadding="0 8px"
    >
      <ComparisonGrid
        gridRef={gridRef}
        columnData={columnDataWithFirstColumn}
        rowData={rowData}
        subRowHeight={subRowHeight}
        collapsedRowIds={collapsedRowIds}
        setCollapsedRowIds={setCollapsedRowIds}
        onDataCellKeyboardAction={handleDataCellKeyboardAction}
        staticRowHeights
        FrozenHeaderCell={FrozenHeaderCell}
        FrozenFooterCell={FrozenFooterCell}
        FirstColumnCell={FirstColumnCell}
        DataCell={DataCell}
        frozenLeftColumnIds={frozenLeftColumnIds}
        frozenHeaderHeight={lineItemsHeaderHeight}
      />
    </DefaultComparisonGridStyles>
  ) : (
    null
  );
};
