import * as React from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { compact } from 'lodash';
import { Flex, Box, Text, FlexProps } from 'rebass/styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { useOpenState } from '@deepstream/ui-kit/hooks/useOpenState';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { Pictogram } from '@deepstream/ui-kit';
import { WrapperButton } from '@deepstream/ui-kit/elements/button/WrapperButton';
import { useMediaQuery } from '@deepstream/ui-kit/hooks/useMediaQuery';
import { SHOW_CHRISTMAS_LOGO } from '@deepstream/common/constants';
import { NavbarDropdown } from '../NavbarDropdown';
import { useDeviceSize } from '../ui/useDeviceSize';
import { TaskCounter, TaskCounterProps, getFilteredTasksCount } from '../TaskCounter';
import { NotificationsDropdown } from '../modules/Notifications/NotificationsDropdown';
import { useSystemFeatureFlags } from '../systemFeatureFlags';
import { useCurrentUser } from '../useCurrentUser';

import { MainNavWrapper } from './styles';
import { NAVBAR_HEIGHT } from '../constants';
import { useIntercomTrackEvent } from '../ui/useIntercomTrackEvent';
import { useNavigate } from '../tanstackRouter';
import { companyProfileRoute, companySettingsRoute, contractsRoute, dashboardRoute, driveRoute, networkRoute, notificationsRoute, preQualificationRoute, reportingIndexRoute, requestsIndexRoute, supplierDiscoveryRoute, teamManagementRoute, userProfileRoute } from '../AppRouting';
import { useCurrentCompanyId } from '../currentCompanyId';
import { CompanySettingsTab } from '../modules/CompanySettings/companySettingsConstants';
import { IconStyleModifier, MobileMenu } from './MobileNav';
import { CounterIcon, CounterIconProps } from '../CounterIcon';
import { useTasks } from '../TasksProvider';
import { NavbarContainer } from './NavbarContainer';

const NAV_BUTTON_WIDTH = 75;
const NAVBAR_MOBILE_BREAKPOINT = 900;

const useNavigationCallbacks = () => {
  const navigate = useNavigate();
  const currentCompanyId = useCurrentCompanyId();

  return {
    navigateToDashboard: () => {
      // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
      navigate({ to: dashboardRoute.to, params: { currentCompanyId } });
    },
    navigateToPreQualification: () => {
      // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
      navigate({ to: preQualificationRoute.to, params: { currentCompanyId } });
    },
    navigateToRequests: (tab?: string) => {
      navigate({
        to: requestsIndexRoute.to,
        // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
        params: { currentCompanyId },
        search: { tab: tab ?? 'sent' },
      });
    },
    navigateToContracts: () => {
      // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
      navigate({ to: contractsRoute.to, params: { currentCompanyId } });
    },
    navigateToDiscovery: () => {
      // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
      navigate({ to: supplierDiscoveryRoute.to, params: { currentCompanyId } });
    },
    navigateToCompanyProfile: () => {
      navigate({
        to: companyProfileRoute.to,
        // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
        params: { currentCompanyId, targetCompanyId: currentCompanyId },
      });
    },
    navigateToNetwork: () => {
      // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
      navigate({ to: networkRoute.to, params: { currentCompanyId } });
    },
    navigateToDrive: () => {
      // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
      navigate({ to: driveRoute.to, params: { currentCompanyId } });
    },
    navigateToReporting: () => {
      // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
      navigate({ to: reportingIndexRoute.to, params: { currentCompanyId } });
    },
    navigateToTeamManagement: () => {
      // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
      navigate({ to: teamManagementRoute.to, params: { currentCompanyId } });
    },
    navigateToNotifications: () => {
      // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
      navigate({ to: notificationsRoute.to, params: { currentCompanyId } });
    },
    navigateToCompanySettings: () => {
      // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
      navigate({ to: companySettingsRoute.to, params: { currentCompanyId } });
    },
    navigateToIntegrationsMarketplace: () => {
      navigate({
        to: companySettingsRoute.to,
        // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
        params: { currentCompanyId },
        search: { tab: CompanySettingsTab.MARKETPLACE },
      });
    },
    navigateToUserSettings: () => {
      navigate({ to: userProfileRoute.to });
    },
  };
};

export const BaseNavButton = ({
  sx,
  ...props
}: React.ComponentProps<typeof WrapperButton> & { testId?: string }) => {
  const theme = useTheme();

  return (
    <WrapperButton
      sx={{
        height: '100%',
        fontWeight: 500,
        textAlign: 'left',
        borderRadius: 0,
        backgroundColor: theme.colors.darkGray,
        ':hover': {
          backgroundColor: lighten(0.1, theme.colors.darkGray),
        },
        ':focus:not(:focus-visible)': {
          outline: 'none',
        },
        ...sx,
      }}
      {...props}
    />
  );
};

type NavButtonProps = ButtonProps & {
  text?: string;
  icon?: IconProps['icon'];
  count: CounterIconProps['count'];
  testId?: string;
  iconStyleModifier?: IconStyleModifier;
};
export const NavButton = ({ icon, text, count: notificationsCount, testId, iconStyleModifier, ...props }: NavButtonProps) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <BaseNavButton
      data-test={testId}
      onPointerEnter={() => setIsHovered(true)}
      onPointerLeave={() => setIsHovered(false)}
      {...props}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyItems="center"
        sx={{
          rowGap: '3px',
        }}
      >
        <CounterIcon
          count={notificationsCount}
          // @ts-expect-error ts(2322) FIXME: Type '"function" | "sort" | "filter" | "download" | "angle-left" | "angle-right" | "archive" | "arrow-down" | "arrow-down-short-wide" | "arrow-left" | "arrow-right" | "arrow-right-arrow-left" | ... 191 more ... | undefined' is not assignable to type '"function" | "sort" | "filter" | "download" | "angle-left" | "angle-right" | "archive" | "arrow-down" | "arrow-down-short-wide" | "arrow-left" | "arrow-right" | "arrow-right-arrow-left" | ... 190 more ... | "person-circle-check"'.
          icon={icon}
          iconProps={iconStyleModifier}
          counterProps={{
            sx: {
              height: '16px',
              minWidth: '16px',
              // The same color as the navbar background
              border: (theme) =>
                `2px solid ${isHovered ? lighten(0.1, theme.colors.darkGray) : theme.colors.darkGray}`,
            },
          }}
        />
        {text && (<Text fontSize="11px" sx={{ whiteSpace: 'nowrap' }}>{text}</Text>)}
      </Flex>
    </BaseNavButton>
  );
};

type MenuNavButtonProps = {
  taskCounterFilter?: TaskCounterProps['filter'];
} & Omit<NavButtonProps, 'count'>;
const MenuNavButton = ({
  taskCounterFilter,
  ...props
}: MenuNavButtonProps) => {
  const { tasks } = useTasks();
  const notificationsCount = React.useMemo(() => {
    if (!taskCounterFilter) {
      return 0;
    } else {
      return getFilteredTasksCount(tasks, taskCounterFilter);
    }
  }, [tasks, taskCounterFilter]);

  return <NavButton count={notificationsCount} {...props} />;
};

const Overlay = styled(Box)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
`;

type NavbarProps = {
  switchCompany: (companyId: string) => void;
  logout: () => void;
};

const MenuNavButtons = ({ ...props } : FlexProps) => {
  const { t } = useTranslation('general');
  const navigation = useNavigationCallbacks();

  const systemFeatureFlags = useSystemFeatureFlags();
  const trackPreQualVisitedEvent = useIntercomTrackEvent({
    eventName: 'pre-qualification-page-visited',
  });

  const buttons = React.useMemo(() => {
    return compact([
      {
        text: t('preQualification'),
        iconStyleModifier: { regular: true },
        icon: 'file-check',
        onClick: () => {
          trackPreQualVisitedEvent();
          navigation.navigateToPreQualification();
        },
        testId: 'prequalification-nav-button',
      },
      {
        text: t('request_other'),
        icon: 'file-text-o',
        taskCounterFilter: [
          { type: 'answerQuestion' },
          { type: 'decideApproval' },
        ],
        onClick: () => navigation.navigateToRequests(),
        testId: 'requests-nav-button',
      },
      // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'.
      systemFeatureFlags.contractManagementEnabled && {
        text: t('contract_other'),
        icon: 'file-contract',
        iconStyleModifier: { regular: true },
        onClick: () => navigation.navigateToContracts(),
        testId: 'contracts-nav-button',
      },
      // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'.
      systemFeatureFlags.discoveryEnabled && {
        text: t('general.discovery', { ns: 'translation' }),
        iconStyleModifier: { light: true },
        icon: 'crosshairs',
        onClick: () => navigation.navigateToDiscovery(),
        testId: 'discovery-nav-button',
      },
      {
        text: t('network'),
        icon: 'sitemap',
        iconStyleModifier: { regular: true },
        onClick: () => navigation.navigateToNetwork(),
        testId: 'network-nav-button',
      },
      {
        text: t('profile'),
        icon: 'building-o',
        onClick: () => navigation.navigateToCompanyProfile(),
        testId: 'profile-nav-button',
      },
      {
        text: t('drive'),
        icon: 'hdd-o',
        onClick: () => navigation.navigateToDrive(),
        taskCounterFilter: { filter: { type: 'addDocument' } },
        testId: 'documents-nav-button',
      },
      // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'.
      systemFeatureFlags.managementReportingEnabled && {
        text: t('reporting'),
        icon: 'chart-bar',
        onClick: () => navigation.navigateToReporting(),
        testId: 'reporting-nav-button',
      },
    ]) as MenuNavButtonProps[];
  // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'.
  }, [navigation, t, trackPreQualVisitedEvent, systemFeatureFlags.contractManagementEnabled, systemFeatureFlags.discoveryEnabled, systemFeatureFlags.managementReportingEnabled]);

  return (
    <Flex {...props}>
      {buttons.map((button, index) => (
        <MenuNavButton
          key={index}
          sx={{
            padding: '3px',
            height: `${NAVBAR_HEIGHT}px`,
            witdh: `${NAV_BUTTON_WIDTH}px`,
            minWidth: `${NAV_BUTTON_WIDTH}px`,
          }}
          {...button}
        />
      ))}
    </Flex>
  );
};

export const MainNav = React.memo(({ switchCompany, logout }: NavbarProps) => {
  const { t } = useTranslation(['translation', 'general']);
  const { isLarge, isExtraLarge } = useDeviceSize();
  const mobileMenu = useOpenState();
  const currentUser = useCurrentUser();
  const companyRoles = currentUser?.companyRoles;
  const navigation = useNavigationCallbacks();

  const isMobile = useMediaQuery(`(max-width: ${NAVBAR_MOBILE_BREAKPOINT}px)`);

  return (
    <MainNavWrapper>
      <NavbarContainer
        as="nav"
        onClick={() => {
          if (mobileMenu.isOpen) {
            mobileMenu.close();
          }
        }}
      >
        <Flex
          sx={{
            columnGap: isMobile ? 0 : isLarge || isExtraLarge ? '48px' : '24px',
            height: '100%',
          }}
        >
          <Flex sx={{ height: '100%' }}>
            {SHOW_CHRISTMAS_LOGO ? (
              <BaseNavButton
                sx={{ width: '60px', padding: '9px 15px 15px' }}
                onClick={() => navigation.navigateToDashboard()}
                data-test="dashboard-nav-button"
              >
                <Pictogram variant="deepstream-logo-xmas" />
              </BaseNavButton>
            ) : (
              <BaseNavButton
                sx={{ width: '60px', padding: '15px' }}
                onClick={() => navigation.navigateToDashboard()}
                data-test="dashboard-nav-button"
              >
                <Pictogram variant="deepstream-logo" />
              </BaseNavButton>
            )}

            {isMobile && (
              <BaseNavButton
                onClick={mobileMenu.toggle}
                testId="toggle-nav-button"
                sx={{ width: '100px' }}
              >
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  sx={{ height: '100%', columnGap: 1 }}
                >
                  <Text>{t('menu', { ns: 'general' })}</Text>
                  <TaskCounter
                    filter={[
                      { type: 'answerQuestion' },
                      { type: 'decideApproval' },
                      { type: 'addDocument' },
                    ]}
                  />
                  <Icon
                    icon={mobileMenu.isOpen ? 'caret-up' : 'caret-down'}
                    aria-hidden="true"
                  />
                </Flex>
              </BaseNavButton>
            )}
          </Flex>
          {!isMobile && <MenuNavButtons />}
        </Flex>

        <Flex height="100%" alignItems="center">
          <NotificationsDropdown
            navigateToNotifications={navigation.navigateToNotifications}
          />
          <NavbarDropdown
            currentUser={currentUser}
            companyRoles={companyRoles}
            switchCompany={switchCompany}
            navigateToTeam={navigation.navigateToTeamManagement}
            navigateToCompanySettings={navigation.navigateToCompanySettings}
            navigateToUserSettings={navigation.navigateToUserSettings}
            logout={logout}
          />
        </Flex>
      </NavbarContainer>
      <AnimatePresence initial={false}>
        {isMobile && mobileMenu.isOpen && (
          <>
            <motion.div
              key="mobileMenu"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { height: 'auto' },
                collapsed: { height: 0 },
              }}
              style={{
                position: 'fixed',
                top: NAVBAR_HEIGHT,
                left: 0,
                right: 0,
                zIndex: 102,
              }}
              transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <MobileMenu
                onClick={mobileMenu.toggle}
                height="100%"
                overflow="hidden"
              >
                <MenuNavButtons
                  sx={{ borderTop: 'gray' }}
                  justifyContent="center"
                  flexWrap="wrap"
                />
              </MobileMenu>
            </motion.div>
            <Overlay onClick={mobileMenu.toggle} />
          </>
        )}
      </AnimatePresence>
    </MainNavWrapper>
  );
});
