import * as React from 'react';
import { CellProps } from 'react-table';
import { useTranslation } from 'react-i18next';
import { RfqStatus } from '@deepstream/common/rfq-utils';
import { isCompanySuperUser } from '@deepstream/common/user-utils';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { DeleteMenuItem, DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { ExtendedSentRequestOverview } from './types';
import { useUserFlags } from './UserFlagsContext';
import { useCurrentCompanyId } from './currentCompanyId';
import { useCurrentUser } from './useCurrentUser';

export type SentRequestActionButtonsProps = CellProps<ExtendedSentRequestOverview> & {
  openDeleteModal: (request: ExtendedSentRequestOverview) => void;
  onExpandedStateChange?: (isExpanded: boolean) => void;
};

export const SentRequestActionButtons: React.FC<SentRequestActionButtonsProps> = ({
  row,
  openDeleteModal,
  openTemplateFromRequestModal,
  onExpandedStateChange,
}) => {
  const { t } = useTranslation('translation');
  const request = row.original;
  const isDraft = request.sentDashboard.status === RfqStatus.DRAFT;
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const isSuperUser = isCompanySuperUser(currentUser, currentCompanyId);

  const { hasSendRfqPermission, belongsToPayingCompany } = useUserFlags();

  const showUseAsTemplate = hasSendRfqPermission && belongsToPayingCompany;
  const showDelete = (isSuperUser || request.isOwner) && isDraft;

  return showUseAsTemplate || showDelete ? (
    <ButtonGroup marginBetween="-1px">
      <DropdownMenu
        small
        variant="primary-outline"
        iconLeft="ellipsis-h"
        onExpandedStateChange={onExpandedStateChange}
        menuZIndex={10}
        onClick={stopPropagation}
      >
        {showUseAsTemplate && (
          <DropdownMenuItem
            icon="files-o"
            onSelect={() => openTemplateFromRequestModal(request)}
          >
            {t('requests.useAsTemplate')}
          </DropdownMenuItem>
        )}
        {showDelete && (
          <DeleteMenuItem onSelect={() => openDeleteModal(request)} />
        )}
      </DropdownMenu>
    </ButtonGroup>
  ) : (
    null
  );
};
