import { ExtendedSupplierListRule, SupplierListType } from '@deepstream/common';
import { CompanyMinimized } from '@deepstream/common/rfq-utils';

export enum ListConfigFlowStepType {
  NAME_AND_DESCRIPTION = 'nameAndDescription',
  LIST_TYPE = 'listType',
  AUTOMATIC_LIST_RULES = 'automaticListRules',
  ADD_SUPPLIERS = 'addSuppliers',
  REVIEW = 'review',
  SUCCESS = 'success', // Only used for redirecting the user after saving
}

export type ListConfigFlowStep = { type: ListConfigFlowStepType };

export type ListConfigFlowData = {
  steps: ListConfigFlowStep[];
  currentStep: ListConfigFlowStep;
  _id?: string; // Only present when editing an existing list
  name: string;
  description: string;
  listType: SupplierListType;
  rules: ExtendedSupplierListRule[];
  suppliers: CompanyMinimized[];
};
