import * as React from 'react';
import { useIntercom } from 'react-use-intercom';
import { Modal } from '@deepstream/ui-kit/elements/popup/Modal';
import { ErrorPanel } from '../ui/ErrorMessage';
import { LoadingPanel } from '../ui/Loading';
import { useRfqExchange, useRfqId } from '../useRfq';
import * as rfx from '../rfx';
import { ExchangeSnapshot } from '../types';
import { CurrencyCodeProvider } from '../ui/Currency';
import { SendExchangeReply, useSendExchangeReply } from './useSendExchangeReply';
import { ExchangeProvider } from '../useExchange';
import { RfxExchangeModalContent } from './RfxExchangeModalContent';
import { ExchangeDefFieldValueProvider } from '../ExchangeDefFieldValueContext';
import { PageFooter } from './PageFooter';
import { PageFooterBidAutoAdvance } from './PageFooterBidAutoAdvance';
import { useTrackActiveSession } from '../modules/ActiveSession/ActiveSessionContext';
import { useCurrentCompanyId } from '../currentCompanyId';

export const RfxExchangeModal = React.memo(({
  close,
  exchangeId,
  isOpen,
  recipientId,
  showRecipient,
  showExchangeSwitcher,
  showFooter,
  canAdvanceHorizontally,
  FooterAutoAdvancer,
  FooterProgress,
  onLoad,
  onUpdate,
}: {
  exchangeId?: string;
  isOpen: boolean;
  recipientId?: string;
  showRecipient?: boolean;
  showExchangeSwitcher?: boolean;
  showFooter?: boolean;
  canAdvanceHorizontally?: boolean;
  FooterAutoAdvancer?: React.FC<{
    exchangeId: string;
    recipientId?: string;
    advanceMode?: 'vertical' | 'horizontal';
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  }>
  FooterProgress?: React.FC;
  close: () => void;
  onLoad?: (exchange: ExchangeSnapshot) => void;
  onUpdate?: (exchange: ExchangeSnapshot) => void;
}) => {
  const intercom = useIntercom();
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { sectionById, pages, pageById, extendedStatus } = rfx.useStructure();
  const { data: exchange, refetch, error, isLoading } = useRfqExchange({
    exchangeId,
    recipientId,
    onSuccess: onLoad,
  });
  const pagePermissions = rfx.usePagePermissions();
  // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const section = exchange && sectionById[exchange.def.sectionId];
  const pageId = section && pages.find((page) => page.sections.includes(section._id))?._id;
  const isSender = currentCompanyId !== recipientId;
  // @ts-expect-error ts(2345) FIXME: Argument of type '{ type: "entity"; entityId: string; entityType: "request"; sectionId: string | undefined; sectionType: any; sectionName: any; pageId: any; pageType: PageType | undefined; pageName: string; ... 4 more ...; scope: ("live" | ... 2 more ... | "exchangeModal")[]; } | null' is not assignable to parameter of type 'SegmentContext'.
  useTrackActiveSession(React.useMemo(() => !isOpen ? null : ({
      type: 'entity',
      entityId: rfqId,
      entityType: 'request',
      sectionId: exchange?.def.sectionId,
      sectionType: section?.type,
      sectionName: section?.name,
      pageId,
      pageType: pageById[pageId]?.type,
      pageName: pageById[pageId]?.name,
      exchangeId,
      exchangeType: exchange?.def.type,
      requestStatus: extendedStatus,
      recipientId: exchange?.recipientId,
      scope: [isSender ? 'sender' : 'recipient', 'live', 'exchangeModal'],
    }),
  [
    isOpen,
    exchange?.def.sectionId,
    exchange?.def.type,
    exchange?.recipientId,
    exchangeId,
    isSender,
    pageById,
    pageId,
    rfqId,
    section?.type,
    section?.name,
    extendedStatus,
  ]));

  const refetchExchange = React.useCallback(async () => {
    const { data: exchange, isSuccess } = await refetch();

    if (exchange && isSuccess && onUpdate) {
      onUpdate(exchange);
    }
  }, [onUpdate, refetch]);

  React.useEffect(
    () => {
      if (isOpen && showFooter) {
        intercom.update({ verticalPadding: 80 });
      }
      return () => {
        intercom.update({ verticalPadding: 20 });
      };
    },
    [isOpen, showFooter, intercom],
  );

  const sendExchangeReplyMutation = useSendExchangeReply({
    rfqId,
    // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
    exchangeId: exchange?._id,
    recipientId: exchange?.recipientId,
    sectionId: exchange?.def.sectionId,
  });

  return (
    <>
      <Modal
        shouldCloseOnEsc
        onRequestClose={close}
        isOpen={isOpen}
        panelStyle={{
          margin: 'auto',
          maxWidth: '90%',
          width: 1400,
        }}
      >
        {isLoading ? (
          <LoadingPanel />
        ) : error ? (
          <ErrorPanel error={`There was an error loading this exchange (id: ${exchangeId}`} />
        ) : exchange ? (
          <SendExchangeReply.Provider value={sendExchangeReplyMutation}>
            <rfx.SectionProvider section={sectionById[exchange.def.sectionId!]}>
              <rfx.ExchangeRefetchProvider refetch={refetchExchange}>
                <ExchangeProvider exchange={exchange}>
                  <CurrencyCodeProvider code={(exchange as any).currency ?? ''}>
                    <ExchangeDefFieldValueProvider>
                      <RfxExchangeModalContent
                        showExchangeSwitcher={showExchangeSwitcher}
                        showFooter={showFooter}
                        showRecipient={showRecipient}
                        close={close}
                      />
                    </ExchangeDefFieldValueProvider>
                  </CurrencyCodeProvider>
                </ExchangeProvider>
              </rfx.ExchangeRefetchProvider>
            </rfx.SectionProvider>
          </SendExchangeReply.Provider>
        ) : (
          null
        )}
      </Modal>
      {showFooter && isOpen ? (
        <PageFooter
          // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
          exchangeId={exchangeId}
          recipientId={recipientId}
          autoAdvance={FooterAutoAdvancer && pagePermissions?.canRespond ? (
            <PageFooterBidAutoAdvance
              canAdvanceHorizontally={canAdvanceHorizontally}
              // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
              exchangeId={exchangeId}
              recipientId={recipientId}
              contextId={rfqId}
              AutoAdvancer={FooterAutoAdvancer}
            />
          ) : (
            null
          )}
          progress={FooterProgress ? (
            <FooterProgress />
          ) : (
            null
          )}
        />
      ) : (
        null
      )}
    </>
  );
});
