import { conforms } from 'lodash';
import { BidStatus, Live } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils';
import { useLiveRfqStructure, useRfqId } from '../../../useRfq';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useNotificationSubject } from '../../Notifications/useNotificationSubject';
import { Notification } from '../../Notifications/types';
import { RequestLiveDetails } from '../Live/Details';
import { BidIntentionPanel } from '../../../BidIntentionPanel';
import { useRequestRecipientNavigation } from '../../../appNavigation';
import * as rfx from '../../../rfx';
import { LoadingPanel } from '../../../ui/Loading';
import { ErrorPanel } from '../../../ui/ErrorMessage';

const LegacyRequestReceivedDetailsContent = () => {
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId();
  const navigation = useRequestRecipientNavigation();
  const structure = rfx.useStructure<Live>();

  // @ts-expect-error ts(2538) FIXME: Type 'null' cannot be used as an index type.
  const bid = structure.bidById[currentCompanyId];

  const isBidOutcomeDecided = bid.status === BidStatus.AWARDED || bid.status === BidStatus.UNSUCCESSFUL;
  const isBidWithdrawn = bid.status === BidStatus.WITHDRAWN;
  const enteredStageNumber = bid.enteredStageIds.length;
  const activatedStageNumber = bid.activatedStageIds.length;
  const hasIntentionToBid = activatedStageNumber > 0;

  const showBidIntentionPanel = (
    !isBidOutcomeDecided &&
    (isBidWithdrawn || enteredStageNumber !== activatedStageNumber)
  );

  return (
    <>
      {showBidIntentionPanel && (
        <BidIntentionPanel
          rfqId={rfqId}
          // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
          recipientId={currentCompanyId}
          // @ts-expect-error ts(2322) FIXME: Type '(() => void) | null' is not assignable to type '(() => void) | undefined'.
          onSendIntentionSuccess={hasIntentionToBid ? (
            null
          ) : (
            () => navigation.navigateToBid()
          )}
        />
      )}
      <RequestLiveDetails rfqId={rfqId} />
    </>
  );
};

export const LegacyRequestReceivedDetails = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqId = useRfqId();
  const { t } = useTranslation('translation');

  const { data: structure, isLoading, isError, isSuccess } =
    useLiveRfqStructure({ rfqId, recipientId: currentCompanyId, currentCompanyId });

  const ref = useNotificationSubject({
    filter: conforms<Partial<Notification>>({
      domain: domain => domain === NotificationDomain.RFQ_RECEIVED,
      action: action => [
        NotificationAction.RFQ_CREATED,
        NotificationAction.NEW_MEMBER,
        NotificationAction.REMOVED_MEMBER,
        NotificationAction.RFQ_AWARDED,
        NotificationAction.RFQ_LOST,
        NotificationAction.BID_SENT,
      ].includes(action),
      meta: meta => meta.rfqId === rfqId,
      to: to => to.companyId === currentCompanyId,
    }),
  });

  return isLoading ? (
    <LoadingPanel />
  ) : isError ? (
    <ErrorPanel error={t('errors.unexpected')} />
  ) : isSuccess && structure ? (
    <div ref={ref}>
      <rfx.StructureProvider structure={structure}>
        <LegacyRequestReceivedDetailsContent />
      </rfx.StructureProvider>
    </div>
  ) : (
    null
  );
};
