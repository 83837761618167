import { compact, orderBy } from 'lodash';
import { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text, SxStyleProp } from 'rebass/styled-components';
import { ContractSummary, ExpiryDateType, StartDateType } from '@deepstream/common/contract';
import { getTimeUnitTranslationKey } from '@deepstream/common/contract/contractDates';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { CollapsiblePanelHeader, Panel, PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { DateFormat } from '@deepstream/utils';
import { Datetime2 } from '../../Datetime';
import { PropertyList, ValueOrEmDash } from '../../PropertyList';
import { useContractData, useMilestonesList } from './contract';
import { LABEL_WIDTH } from '../../draft/SummaryLabelConfigProvider';

const InternalOnly = ({
  sx,
}: {
  sx?: SxStyleProp | undefined;
}) => {
  const { t } = useTranslation('general');

  return (
    <IconText
      icon="eye-slash"
      color="subtext"
      text={t('internalOnly')}
      sx={sx}
    />
  );
};

const MilestoneInfo = (props) => (
  <Flex alignItems="center">
    <Text sx={{ width: LABEL_WIDTH }}>
      <Datetime2 format={DateFormat.DD_MMM_YYYY} value={props.value} />
    </Text>
    {props.internalOnly && (
      <InternalOnly sx={{ width: LABEL_WIDTH }} />
    )}
  </Flex>
);

export const CollapsibleMilestonesPanel = () => {
  const { t } = useTranslation('contracts');
  const contract = useContractData();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const customMilestonesEntries = useMilestonesList({
    milestones: contract.customMilestones,
    Component: MilestoneInfo,
  });

  const heading = t('milestone_other');

  const calculatedStartDate = contract.calculatedStartDate || contract.summary.startDate;
  const calculatedExpiryDate = contract.calculatedExpiryDate || contract.summary.expiryDate;

  const getPartialStartDate = useCallback(
    (summary: ContractSummary) => {
      const { startDateType, startDateOffsetConfig } = summary;

      switch (startDateType) {
        case StartDateType.TIME_BASED: {
          if (startDateOffsetConfig?.amount) {
            const startDateOffsetKey = getTimeUnitTranslationKey(startDateOffsetConfig.unit);
            // @ts-expect-error ts(2769) FIXME: No overload matches this call.
            return `${t(startDateOffsetKey, { count: startDateOffsetConfig?.amount, ns: 'general' })} ${t('summary.afterSignature')}`;
          } else {
            return null;
          }
        }
        case StartDateType.ON_SIGNATURE:
          return t('summary.startDateType.onSignature');
        default:
          return null;
      }
    },
    [t],
  );

  const getPartialExpiryDate = useCallback(
    (summary: ContractSummary) => {
      const { expiryDateOffsetConfig } = summary;
      if (expiryDateOffsetConfig?.amount) {
        const expiryDateOffsetKey = getTimeUnitTranslationKey(expiryDateOffsetConfig.unit);
        // @ts-expect-error ts(2769) FIXME: No overload matches this call.
        return `${t(expiryDateOffsetKey, { count: expiryDateOffsetConfig?.amount, ns: 'general' })} ${t('summary.afterStartDate')}`;
      } else {
        return null;
      }
    },
    [t],
  );

  const entries = useMemo(
    () => orderBy(
      // For the default start and expiry dates milestones we will display either:
      // a. the date if it has been calculated (on signature) or set as an exact date (on summary update)
      // b. the configuration set if TIME_BASED type has been selected and the offset configuration is complete
      // c. `—` - if the milestone exists, but the information is incomplete
      compact([
        {
          name: t('summary.startDate'),
          value: calculatedStartDate || getPartialStartDate(contract.summary),
          Component: calculatedStartDate ? MilestoneInfo : ValueOrEmDash,
        },
        contract.summary.expiryDateType !== ExpiryDateType.NO_EXPIRY_DATE && {
          name: t('summary.expiryDate'),
          value: calculatedExpiryDate || getPartialExpiryDate(contract.summary),
          Component: calculatedExpiryDate ? MilestoneInfo : ValueOrEmDash,
        },
        ...customMilestonesEntries,
      ]),
      entry => entry.value,
      'asc',
    ),
    [t, calculatedStartDate, getPartialStartDate, contract.summary, calculatedExpiryDate, getPartialExpiryDate, customMilestonesEntries],
  );

  return (
    <Panel as="section" aria-label={heading}>
      <CollapsiblePanelHeader
        heading={heading}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      />
      {!isCollapsed && (
        <>
          <PanelDivider />
          <PropertyList properties={entries} />
        </>
      )}
    </Panel>
  );
};
