import { Box, Text, Flex, BoxProps } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Bold } from '../../Bold';

export const RequestVisibilityStatus = ({ isPubliclyAvailable, ...props }: { isPubliclyAvailable: boolean } & BoxProps) => {
  const { t } = useTranslation();

  return (
    <Box {...props}>
      <Text fontSize={1} fontWeight={500} mb={1}>
        {t('general.visibility')}
      </Text>
      <Flex alignItems="center" fontSize={2}>
        <Icon fixedWidth light icon={isPubliclyAvailable ? 'globe' : 'eye-slash'} mr={1} />
        <Bold>
          {isPubliclyAvailable ? (
            t('request.visibility.publicVisibility')
          ) : (
            t('request.visibility.privateVisibility')
          )}
        </Bold>
      </Flex>
    </Box>
  );
};
