import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { ContractTemplate } from '@deepstream/common/contract/contractTemplate';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { DeleteMenuItem, DropdownMenu, EditMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { callAll } from '@deepstream/utils/callAll';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useNavigate } from '../../tanstackRouter';
import { contractDraftSummaryRoute, contractTemplateSummaryRoute } from '../../AppRouting';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useMutation } from '../../useMutation';
import { useApi } from '../../api';
import { useToaster } from '../../toast';

export type ContractTemplateActionButtonsProps = CellProps<ContractTemplate> & {
  openDeleteModal: (template: ContractTemplate) => void;
  onExpandedStateChange?: (isExpanded: boolean) => void;
};

export const ContractTemplateActionButtons = ({
  row,
  openDeleteModal,
  onExpandedStateChange,
}: ContractTemplateActionButtonsProps) => {
  const { t } = useTranslation(['translation', 'contracts']);
  const currentCompanyId = useCurrentCompanyId();
  const navigate = useNavigate();
  const toaster = useToaster();
  const api = useApi();

  const template = row.original;

  const navigateToContractTemplateEdit = useCallback((id) => {
    navigate({
      to: contractTemplateSummaryRoute.to,
      // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
      params: { currentCompanyId, contractId: id },
    });
  }, [currentCompanyId, navigate]);

  const [createContractFromTemplate, { isLoading }] = useMutation(
    api.createContractFromTemplate,
    {
      onSuccess: callAll(
        contract => {
          navigate({
            to: contractDraftSummaryRoute.to,
            // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
            params: { currentCompanyId, contractId: contract._id },
          });
        },
        () => {
          toaster.success(t(
            'toaster.createContractFromTemplate.success',
            { ns: 'contracts', name: template?.meta.name },
          ));
        },
      ),
      onError: () => toaster.error(t('toaster.createContractFromTemplate.failed', { ns: 'contracts' })),
    },
  );

  return (
    <ButtonGroup marginBetween="-1px">
      <Button
        small
        variant="primary-outline"
        disabled={isLoading}
        onClick={e => {
          e.stopPropagation();
          createContractFromTemplate({
            // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
            currentCompanyId,
            templateId: template._id,
          });
        }}
      >
        <Icon fontSize={1} mr={2} icon="plus" />
        {t('newContract', { ns: 'contracts' })}
      </Button>
      <DropdownMenu
        small
        variant="primary-outline"
        iconLeft="ellipsis-h"
        onExpandedStateChange={onExpandedStateChange}
        stopClickEvents
        onClick={stopPropagation}
        menuZIndex={10}
      >
        <EditMenuItem onSelect={() => navigateToContractTemplateEdit(template._id)} />
        <DeleteMenuItem onSelect={() => openDeleteModal(template)} />
      </DropdownMenu>
    </ButtonGroup>
  );
};
