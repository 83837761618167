import { Box, Text, Flex } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Bold } from './Bold';

export type EditStatus = 'draft' | 'revision' | 'template' | 'amendment';

export const EditStatusIndicator = ({
  editStatus,
  isContract,
}: {
  editStatus: EditStatus;
  isContract?: boolean;
}) => {
  const { t } = useTranslation(['translation', 'contracts']);

  return (
    <Box>
      <Text fontSize={1} fontWeight={500} mb={1}>
        {t('general.status')}
      </Text>
      <Flex alignItems="center" fontSize={2}>
        <Icon fixedWidth color="subtext" icon="pencil" mr={1} />
        <Bold>
          {isContract ? (
            t(`editStatus.${editStatus}`, { ns: 'contracts' })
          ) : (
            t(`request.editStatus.${editStatus}`)
          )}
        </Bold>
      </Flex>
    </Box>
  );
};
