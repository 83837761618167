import { Box, Text } from 'rebass/styled-components';
import { Pictogram } from '@deepstream/ui-kit';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';

export type ModelSizeLimitMessages = {
  heading: string;
  title: string;
  warning: string;
  body: string;
};

export const ModelSizeLimitDialog = ({ modal, messages }: {
  modal: any;
  messages?: ModelSizeLimitMessages | null;
}) => {
  return messages ? (
    <Dialog
      heading={messages?.heading}
      style={{ content: { width: '375px', minWidth: '375px' } }}
      body={(
        <Box textAlign="center">
          <Pictogram
            variant="danger"
            label={messages.title}
            labelSx={{ fontSize: 3 }}
          />
          <MessageBlock variant="error" mt="10px" mb={3} textAlign="left">
            {messages.warning}
          </MessageBlock>
          <Text fontWeight={500} maxWidth="80%" margin="auto">
            {messages.body}
          </Text>
        </Box>
      )}
      showCancelButton={false}
      showCloseIcon
      isOpen={modal.isOpen}
      onOk={modal.close}
      onCancel={modal.close}
    />
  ) : (
    null
  );
};
