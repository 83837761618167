import { useState, useMemo } from 'react';
import { Box, Text } from 'rebass/styled-components';
import { map, includes, filter, pick, values } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Accordion } from '@reach/accordion';
import { Illustration } from '@deepstream/ui-kit';

import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { CategoryQuestionsCheckbox } from '../CategoriesList/CategoryQuestionsCheckbox';
import { QuestionEntry } from './QuestionEntry';
import { useImportQuestionsContext } from '../../ImportQuestionsProvider';

export const QuestionsPanel = () => {
  const { t } = useTranslation('preQualification');

  const [expandedQuestions, setExpandedQuestions] = useState<number[]>([]);
  const { activeCategoryId, categoriesById, questionsById } = useImportQuestionsContext();

  const categoryQuestions = useMemo(() => {
    if (!activeCategoryId) return [];
    const activeCategoryQuestions = pick(questionsById, categoriesById[activeCategoryId].questionIds);

    return values(activeCategoryQuestions);
  }, [activeCategoryId, categoriesById, questionsById]);

  const handleAccordionToggle = (index: number) => {
    setExpandedQuestions((prev) => {
      if (includes(prev, index)) {
        return filter(prev, (value) => value !== index);
      }

      return [...prev, index];
    });
  };

  const isEmptyOrInvalidCategory = !activeCategoryId || !categoriesById[activeCategoryId] || categoryQuestions.length === 0;

  return (
    <Box>
      {activeCategoryId && categoriesById[activeCategoryId] ? (
        <CategoryQuestionsCheckbox
          categoryId={activeCategoryId}
          label={(
            <Text sx={{ textTransform: 'uppercase', fontWeight: 500 }} title={categoriesById[activeCategoryId].name}>
              <Truncate>
                {categoriesById[activeCategoryId].name}
              </Truncate>
            </Text>
          )}
          style={{
            borderBottom: '1px solid #D9D9D9',
            padding: '15px',
          }}
        />
      ) : null}
      <Box sx={{ height: '700px', overflow: 'auto' }}>
        {isEmptyOrInvalidCategory ? (
          <Illustration
            variant="empty-state"
            label={t('importQuestions.modal.noQuestionsAvailable')}
            labelSx={{
              textTransform: 'uppercase',
              fontSize: 1,
              fontWeight: 500,
            }}
          />
        ) : (
          <Accordion
            multiple
            collapsible
            onChange={handleAccordionToggle}
          >
            {map(categoryQuestions, (question, index) => (
              <QuestionEntry
                key={question._id}
                question={question}
                isExpanded={includes(expandedQuestions, index)}
              />
            ))}
          </Accordion>
        )}
      </Box>
    </Box>
  );
};
