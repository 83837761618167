import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { get, mapValues, noop, times } from 'lodash';
import { ActionType } from '@deepstream/common/rfq-utils';
import { Flex } from 'rebass/styled-components';
import * as yup from 'yup';
import { withProps } from '@deepstream/ui-utils/withProps';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Modal, ModalBody, ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useRfqId } from './useRfq';
import { ModalForm } from './ModalForm';
import { SendExchangeReply, useSendExchangeReply } from './ExchangeModal/useSendExchangeReply';
import { useCurrentCompanyId } from './currentCompanyId';
import { useCurrentUser } from './useCurrentUser';
import { MoneyField } from './form/MoneyField';
import { FormTableStyles, StaticTableStyles } from './TableStyles';
import { Table } from './Table';
import { TextCell } from './TextCell';
import { CurrencyAmountCell } from './ui/Currency';
import { isRole } from './lock';
import { ReenableResponsesDropdown, useShouldShowReenableResponses } from './ExchangeModal/ReenableResponsesDropdown';
import { withLock } from './modules/Exchange/withLock';

/**
 * For an `array` with a length less than the `amount`, append the
 * `defaultValue` until the length reaches the `amount`.
 */
const ensureMinLength = (array, amount, defaultValue = null) => {
  if (array.length >= amount) return array;

  for (let i = array.length; i < amount; i += 1) {
    array.push(defaultValue);
  }

  return array;
};

/**
 * Take a hire period exchange and make copies based on the interval quantity in
 * the definition, placing an index on each new definition. Also sets the
 * `latestPrice` based on the provided `vesselId` based on the current index.
 */
const expandIntervals = (hirePeriodExchange, vesselId) => {
  const expandedIntervals = times(
    hirePeriodExchange.def.quantity,
    n => ({
      ...hirePeriodExchange,
      def: {
        ...hirePeriodExchange.def,
        index: n,
      },
      // need to ensure each has a unique id for the `key` prop
      _id: `${hirePeriodExchange.def._id}-${n}`,
      latestPrice: hirePeriodExchange.latestPricesByVesselId?.[vesselId]?.[n] ?? null,
    }),
  );

  return expandedIntervals;
};

/**
 * Used to align text baselines with the text in the input fields. Can't align
 * middle because an error message might increase the height of the row.
 */
const TextCellWithTopMargin = withProps(TextCell, { sx: { marginTop: '12px' } });

const useHirePeriodColumns = ({ isReadOnly }) => {
  const { t } = useTranslation(['translation', 'general']);

  const columns = useMemo(
    () => [
      {
        id: 'intervalType',
        Header: t('general.type'),
        accessor: (exchange) => t(`request.vesselPricing.intervalType.${get(exchange, 'def.intervalType')}`),
        Cell: isReadOnly ? TextCell : TextCellWithTopMargin,
      },
      {
        id: 'intervalPeriod',
        Header: t('request.vesselPricing.hirePeriods.period'),
        accessor: ({ def: { amount, unit } }) => t(`${unit}Count`, { count: amount, ns: 'general' }),
        Cell: isReadOnly ? TextCell : TextCellWithTopMargin,
      },
      {
        id: 'number',
        Header: t('general.number_short'),
        accessor: ({ def: { index } }) => `${index + 1}.`,
        Cell: isReadOnly ? TextCell : TextCellWithTopMargin,
        width: 50,
      },
      isReadOnly ? (
        withLock({
          id: 'dayRate',
          Header: t('request.vesselPricing.hirePeriods.dayRate', { count: 1 }),
          accessor: 'latestPrice',
          Cell: CurrencyAmountCell,
          textAlign: 'right',
        }, {
          when: isRole('evaluator'),
        })
      ) : {
        id: 'dayRate',
        Header: t('request.vesselPricing.hirePeriods.dayRate', { count: 1 }),
        Cell: ({ row }) => (
          <MoneyField
            required
            hideLabel
            symbol
            name={`rates[${row.index}]`}
          />
        ),
        width: 200,
      },
    ],
    [t, isReadOnly],
  );

  return columns;
};

type EditHirePeriodRatesModalProps = {
  vesselId: string;
  hirePeriodExchange: any;
  isOpen: boolean;
  onCancel: any;
  onError?: any;
  onSuccess?: any;
};

export const EditHirePeriodRatesModal = ({
  vesselId,
  hirePeriodExchange,
  isOpen,
  onCancel,
  onSuccess = noop,
  onError = noop,
}: EditHirePeriodRatesModalProps) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId();
  const user = useCurrentUser();

  const [sendExchangeReply] = useSendExchangeReply({
    rfqId: useRfqId(),
    exchangeId: hirePeriodExchange._id,
    recipientId: hirePeriodExchange.recipientId,
  });

  const columns = useHirePeriodColumns({ isReadOnly: false });
  const expandedIntervals = expandIntervals(hirePeriodExchange, vesselId);
  const rates = hirePeriodExchange.latestPricesByVesselId[vesselId];

  return (
    <ModalForm
      disableSubmitIfNotDirty
      heading={t('request.vesselPricing.hirePeriods.editRates')}
      initialValues={{ rates }}
      validationSchema={
        yup.object().shape({
          rates: yup.array(
            yup.number().nullable(true).default(null),
          ),
        })
      }
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={async ({ rates }, { setSubmitting }) => {
        await sendExchangeReply({
          companyId: currentCompanyId!,
          user: { _id: user?._id, name: user?.name } as any,
          date: Date.now() as any,
          value: ActionType.SUBMIT,
          vessels: {
            ...mapValues(
              hirePeriodExchange.latestPricesByVesselId,
              prices => ({ rates: prices, expanded: true }),
            ),
            [vesselId]: {
              rates: ensureMinLength(rates, hirePeriodExchange.def.quantity),
              expanded: true,
            },
          },
        }, {
          onSuccess,
          onError,
          onSettled: () => setSubmitting(false),
        });
      }}
      submitLabel={t('general.save')}
      style={{ content: { width: '500px' } }}
    >
      <FormTableStyles noBottomPadding>
        <Table
          isPaginated={false}
          isSortable={false}
          data={expandedIntervals}
          columns={columns}
        />
      </FormTableStyles>
    </ModalForm>
  );
};

export const ViewHirePeriodRatesModal = ({
  isOpen,
  onClose,
  vesselId,
  hirePeriodExchange,
  isRecipient,
}) => {
  const { t } = useTranslation();
  const columns = useHirePeriodColumns({ isReadOnly: true });
  const expandedIntervals = expandIntervals(hirePeriodExchange, vesselId);
  const rfqId = useRfqId();

  const shouldShowReenableButton = useShouldShowReenableResponses(hirePeriodExchange.disabledReason);

  const sendExchangeReplyMutation = useSendExchangeReply({
    rfqId,
    exchangeId: hirePeriodExchange._id,
    recipientId: hirePeriodExchange.recipientId,
  });

  return (
    <Modal
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      isOpen={isOpen}
      style={{ content: { width: '500px' } }}
    >
      <ModalHeader onClose={onClose}>
        {t('request.vesselPricing.hirePeriods.dayRate_other')}
      </ModalHeader>
      <ModalBody p={0}>
        <StaticTableStyles>
          <Table
            isPaginated={false}
            isSortable={false}
            data={expandedIntervals}
            columns={columns}
          />
        </StaticTableStyles>
        {!isRecipient && shouldShowReenableButton && (
          <SendExchangeReply.Provider value={sendExchangeReplyMutation}>
            <PanelPadding>
              <Stack gap="20px">
                <MessageBlock variant="info" mt={0}>
                  {t('request.vesselPricing.suppliersCannotRespond')}
                </MessageBlock>
                <Flex justifyContent="flex-end">
                  <ReenableResponsesDropdown />
                </Flex>
              </Stack>
            </PanelPadding>
          </SendExchangeReply.Provider>
        )}
      </ModalBody>
    </Modal>
  );
};
