import * as React from 'react';
import { useIntercom } from 'react-use-intercom';
import { IconValue } from '@deepstream/common';
import { PanelHeader } from '@deepstream/ui-kit/elements/Panel';
import * as draft from './draft';
import * as rfx from '../rfx';
import { SectionPanelActions } from './SectionPanelActions';
import { SectionPanelHeading } from './SectionPanelHeading';
import { useError } from './validation';

export const DraftSectionViewPanelHeader = ({
  icon,
  intercomEditEventName,
  heading,
}: {
  icon?: IconValue,
  intercomEditEventName?: string;
  heading?: React.ReactNode;
}) => {
  const intercom = useIntercom();
  const showValidationErrors = draft.useShowValidationErrors();
  const { error: sectionNameError } = useError('section.name');

  const section = rfx.useSectionWithPosition();
  const linkedSection = draft.useLinkedSection();
  const { isDraft } = rfx.useState();

  return (
    <PanelHeader
      icon={icon}
      heading={heading ?? <SectionPanelHeading section={section} />}
      description={linkedSection?.description || section.description}
      bg={showValidationErrors && sectionNameError ? 'errorBackground' : 'white'}
      data-test="view-section-header"
      alignItems="flex-start"
    >
      {isDraft && (
        <SectionPanelActions
          onEditClick={intercomEditEventName ? (
            () => { intercom.trackEvent(intercomEditEventName); }
          ) : (
            undefined
          )}
        />
      )}
    </PanelHeader>
  );
};
