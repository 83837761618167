import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { ScoringType } from '@deepstream/common/rfq-utils';

export const useScoringTypeOptions = () => {
  const { t } = useTranslation('evaluation');

  return useMemo(() => Object.values(ScoringType).map(type => ({
    value: type,
    label: t(`fields.scoringType.options.${type}.label`),
    description: t(`fields.scoringType.options.${type}.description`),
  })), [t]);
};
