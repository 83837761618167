import { useCallback, useState } from 'react';
import { Flex } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { useField, useFormikContext } from 'formik';
import { mapValues } from 'lodash';
import { RfxAuctionLineItemsSection } from '@deepstream/common/rfq-utils';
import { roundToDecimals } from '@deepstream/utils';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';
import { Panel, SidebarPanelHeading } from '@deepstream/ui-kit/elements/Panel';
import { AuctionInitialPricesType } from '../../../types';
import { PercentageStepperField } from '../../../form/PercentageStepperField';
import { MoneyStepperField } from '../../../form/MoneyStepperField';
import * as rfx from '../../../rfx';

const AuctionBulkReduceFixedAmount = ({
  auctionInitialPrices,
  auctionTotalBid,
  setTotalBidError,
  disabled,
}: {
  auctionInitialPrices: AuctionInitialPricesType,
  auctionTotalBid: number,
  setTotalBidError: (errorMessage: string) => void;
  disabled?: boolean;
}) => {
  const [,, pricesFormik] = useField('prices');
  const [{ value }] = useField('fixedAmountField');

  const auctionReduceBidFixedAmount = useCallback(
    (reductionAmount) => {
      if (value === null) {
        return;
      }
      const reductionFactor = Number(reductionAmount) / auctionTotalBid;
      pricesFormik.setValue(
        mapValues(auctionInitialPrices, price => price - (price * reductionFactor)),
      );
      setTotalBidError('');
    },
    [value, auctionInitialPrices, auctionTotalBid, pricesFormik, setTotalBidError],
  );
  return (
    <MoneyStepperField
      maxValue={auctionTotalBid}
      onChange={auctionReduceBidFixedAmount}
      name="fixedAmountField"
      increment={1000}
      disabled={disabled}
    />
  );
};

const AuctionBulkReducePercentage = ({
  auctionInitialPrices,
  setTotalBidError,
  disabled,
}: {
  auctionInitialPrices: AuctionInitialPricesType;
  setTotalBidError: (errorMessage: string) => void;
  disabled?: boolean;
}) => {
  const section = rfx.useSection<RfxAuctionLineItemsSection>();
  const decimalPlaces = section.auctionRules.decimalPlaces ?? 2;
  const [{ value }] = useField('percentageStepperField');

  const [,, pricesFormik] = useField('prices');
  const auctionReduceBidPercentage = useCallback(
    (reductionPercentage) => {
      if (value === null) {
        return;
      }
      const reductionFactor = Number(reductionPercentage) / 100;
      pricesFormik.setValue(
        mapValues(auctionInitialPrices, value =>
          roundToDecimals(value - (value * reductionFactor), decimalPlaces),
      ));
      setTotalBidError('');
    },
    [value, auctionInitialPrices, decimalPlaces, pricesFormik, setTotalBidError],
  );
  return (
    <PercentageStepperField
      onChange={auctionReduceBidPercentage}
      name="percentageStepperField"
      increment={0.5}
      maxValue={99.5}
      disabled={disabled}
    />
  );
};

export const AuctionBulkReduceBid = ({
  initialPrices,
  submittedBidTotal,
  setError,
  disabled,
} : {
  initialPrices: AuctionInitialPricesType;
  submittedBidTotal: number;
  setError: (errorMessage: string) => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [displayField, setDisplayField] = useState<'percentage' | 'fixedAmount'>('percentage');
  const formikContext = useFormikContext();

  return (
    <Panel width={220} py="24px" px={3} mr={2}>
      <Flex flexDirection="column">

        <SidebarPanelHeading fontWeight={400}>
          {t('request.auction.bulkReduceBid.reduceBidTotal')}
        </SidebarPanelHeading>

        <ButtonGroup marginBetween="-1" my={3} width="100%">
          <Button
            type="button"
            variant="secondary-outline"
            backgroundColor={displayField === 'percentage' ? 'lightGray' : 'white'}
            small
            width="50%"
            onClick={() => {
              setDisplayField('percentage');
              formikContext.setFieldValue('fixedAmountField', null);
            }}
            disabled={disabled}
          >
            {t('request.auction.bulkReduceBid.percentage')}
          </Button>

          <Button
            type="button"
            variant="secondary-outline"
            backgroundColor={displayField === 'fixedAmount' ? 'lightGray' : 'white'}
            small
            width="50%"
            onClick={() => {
              setDisplayField('fixedAmount');
              formikContext.setFieldValue('percentageStepperField', null);
            }}
            disabled={disabled}
          >
            {t('request.auction.bulkReduceBid.fixedAmount')}
          </Button>
        </ButtonGroup>

        {displayField === 'percentage' ? (
          <AuctionBulkReducePercentage
            auctionInitialPrices={initialPrices}
            setTotalBidError={setError}
            disabled={disabled}
          />
        ) : (
          <AuctionBulkReduceFixedAmount
            auctionInitialPrices={initialPrices}
            auctionTotalBid={submittedBidTotal}
            setTotalBidError={setError}
            disabled={disabled}
          />
        )}

      </Flex>
    </Panel>
  );
};
