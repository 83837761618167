import * as React from 'react';
import { compact, every, get, isEmpty, isEqual, map, noop, set } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { useField } from 'formik';

import { DocumentExchangeDefinition, getEmptyFieldValue, getRequirementFromExchangeType, isFieldValueDefined, lockableExchangeTypes } from '@deepstream/common/rfq-utils';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { EditableGrid, EditableGridProps } from '@deepstream/ui-kit/grid/EditableGrid/EditableGrid';
import { DefaultEditableGridStyles } from '@deepstream/ui-kit/grid/EditableGrid/EditableGridStyles';
import { ReadOnlyGrid } from '@deepstream/ui-kit/grid/EditableGrid/ReadOnlyGrid';
import { useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { useGridMenuState } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { ACTION_COLUMN_WIDTH, EditableGridColumn, calculateMaxGridHeight } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { ColumnData, RowData } from '@deepstream/ui-kit/grid/core/utils';
import { withProps } from '@deepstream/ui-utils/withProps';

import { useEditableGridActions } from '@deepstream/ui-kit/grid/EditableGrid/useEditableGridActions';
import { FieldType } from '@deepstream/common/exchangesConfig';
import * as rfx from '../../rfx';
import { useHandleGridClipboardEvent } from './useHandleGridClipboardEvent';
import { SimpleHeader } from './header';
import { AttachmentCell, LockCell, RequirementValueCell, TextValueCell, ValidationAwareRowNumberCell } from './validationAwareValueCell';
import { StageCell } from './nonValidationAwareValueCell';
import { AttachmentInputCell, DocumentRequirementInputCell, LockInputCell, TextInputCell } from './inputCell';
import { AddDocumentButton } from '../../draft/AddDocumentButton';
import { createEmptyDocument, createInformationDocument } from '../../draft/exchangeDefs';
import { ExchangeDefsGridMenu } from './ExchangeDefsGridMenu';
import { parseCellValue } from './utils';
import { useAddGridRowsState } from './useAddGridRowsState';

const liveDocumentEditableFields = ['category'];
const frozenLeftColumnIds = ['rowNumber', 'category'];
const navigableRange = { startRowIndex: 1, startColumnIndex: 0 };
const selectableRange = { startColumnIndex: 1 };

export const useDefaultStageIds = () => {
  const isSender = rfx.useIsSender();
  const existingExchangeDefs = rfx.useSectionExchangeDefs();
  const stages = rfx.useStages();

  // This is only used on the buyer side (for the draft request)
  const [{ value: section }] = useField({ name: 'section' });
  const sectionStages = section?.stages;

  return React.useMemo(
    () => {
      if (!isSender) return [];

      if (sectionStages) return sectionStages;

      // For single stage requests we need to set the visibility here because the user can't edit it
      if (stages.length === 1) return map(stages, '_id');

      const isVisibilityConsistent = existingExchangeDefs.length && every(
        existingExchangeDefs,
        exchangeDef => isEqual(exchangeDef.stages, existingExchangeDefs[0].stages),
      );

      return isVisibilityConsistent
        ? existingExchangeDefs[0].stages
        : map(stages, '_id');
    },
    [isSender, sectionStages, existingExchangeDefs, stages],
  );
};

const generateCsvCellValue = (columnConfig, documentDef) => {
  const { _id: columnId } = columnConfig;
  if (columnId === 'locking') {
    return documentDef.locking?.type;
  } else if (columnId === 'attachments') {
    // Attachments cell cannot be copy/pasted
    return null;
  } else {
    return documentDef[columnConfig._id];
  }
};

const addCsvDataToRows = async ({ rows, data, affectedColumns }: {
  rows: any;
  data: any;
  affectedColumns: any;
}) => {
  let validCellCount = 0;

  const updatedRows = rows.map((documentDef, documentDefIndex) => {
    if (documentDef.isObsolete) {
      return documentDef;
    }

    const rowData = data[documentDefIndex] ?? [];
    const updatedDocumentDef = { ...documentDef };

    affectedColumns.forEach((column, index) => {
      if (documentDef.isLive && !liveDocumentEditableFields.includes(column.original._id)) {
        return;
      }
      const rawValue = rowData[index];

      const {
        canInsertValue,
        cellValue,
        isInputValid,
      } = parseCellValue(rawValue, { _id: column.original._id, type: column.original.fieldType });

      if (canInsertValue) {
        if (column.original._id === 'locking') {
          updatedDocumentDef.locking = cellValue ? { type: cellValue } : null;
        } else {
          set(updatedDocumentDef, column.original.accessorKey, cellValue);
        }
      }

      if (isInputValid) {
        validCellCount += 1;
      }
    });

    return updatedDocumentDef;
  });

  return {
    updatedRows,
    validCellCount,
  };
};

const createClearFieldsUpdater = ({
  startRowIndex,
  endRowIndex,
  affectedColumns,
}: { startRowIndex: number, endRowIndex: number, affectedColumns: { original: EditableGridColumn }[] }) =>
  (documentDef: Record<string, unknown>, index: number) => {
    if (!documentDef.isObsolete && index >= startRowIndex && index < endRowIndex) {
      const updatedRow = { ...documentDef };

      for (const column of affectedColumns) {
        const { accessorKey, fieldType } = column.original;

        const fieldValue = get(documentDef, accessorKey);

        // @ts-expect-error ts(2345) FIXME: Argument of type 'FieldType | undefined' is not assignable to parameter of type 'FieldType'.
        if (isFieldValueDefined(fieldValue, fieldType)) {
          // @ts-expect-error ts(2345) FIXME: Argument of type 'FieldType | undefined' is not assignable to parameter of type 'FieldType'.
          const newFieldValue = getEmptyFieldValue(fieldType);

          set(updatedRow, accessorKey, newFieldValue);
        }
      }

      return updatedRow;
    } else {
      return documentDef;
    }
  };

const FooterContent = ({ height }) => {
  const isSender = rfx.useIsSender();
  const defaultStageIds = useDefaultStageIds();
  const {
    appendRows,
  } = useEditableGridData<Partial<DocumentExchangeDefinition>>();

  return (
    <Box
      sx={{
        position: 'absolute',
        left: 8,
        top: height - 40,
      }}
    >
      <AddDocumentButton
        type="button"
        onClick={() => {
          const document = isSender
            ? createEmptyDocument(defaultStageIds)
            : createInformationDocument();

          appendRows([document]);
        }}
      />
    </Box>
  );
};

const DocumentsEditableGridWrapper = ({
  ...props
}: Pick<
  EditableGridProps,
  | 'columns'
  | 'rowData'
  | 'bodyPaddingBottom'
  | 'frozenLeftColumnIds'
>) => {
  const isSender = rfx.useIsSender();
  const handleGridClipboardEvent = useHandleGridClipboardEvent();

  const { selectPasteMethod } = useAddGridRowsState();

  const defaultStageIds = useDefaultStageIds();
  const gridActions = useEditableGridActions({
    // @ts-expect-error ts(2322) FIXME: Type '(additionalRowCount: number) => Promise<GridPasteMethod | null>' is not assignable to type '(additionalRowCount: number) => Promise<GridPasteMethod>'.
    selectPasteMethod,
    createRow: () => isSender ? createEmptyDocument(defaultStageIds) : createInformationDocument(),
    generateCsvCellValue,
    addCsvDataToRows,
    // @ts-expect-error ts(2322) FIXME: Type '({ startRowIndex, endRowIndex, affectedColumns, }: { startRowIndex: number; endRowIndex: number; affectedColumns: { original: EditableGridColumn; }[]; }) => (documentDef: Record<string, unknown>, index: number) => Record<...>' is not assignable to type '({ startRowIndex, endRowIndex, affectedColumns, }: { startRowIndex: number; endRowIndex: number; affectedColumns: ({ original: any; } | null)[]; }) => (originalRowData: any, index: number) => unknown'.
    createClearFieldsUpdater,
  });

  return (
    <EditableGrid
      FooterContent={FooterContent}
      gridActions={gridActions}
      navigableRange={navigableRange}
      selectableRange={selectableRange}
      highlightSelectionInHeaders
      onGridClipboardEvent={handleGridClipboardEvent}
      {...props}
    />
  );
};

export const DocumentExchangeDefsGrid = ({
  showExchangeDefVisibility,
  isReadOnly,
}: {
  showExchangeDefVisibility: boolean;
  isReadOnly?: boolean;
}) => {
  const { t } = useTranslation(['general', 'contracts', 'translation']);
  const isSender = rfx.useIsSender();

  const {
    pendingKeyboardEvent,
    rowData: documentExchangeDefs,
    setEditedCell,
  } = useEditableGridData<Partial<DocumentExchangeDefinition>>();

  const { menuReferenceId, toggleMenu } = useGridMenuState();

  const startEditingDocumentCell = React.useCallback(
    (
      row: RowData<any, any>,
      column: ColumnData<EditableGridColumn>,
      event?: React.KeyboardEvent<HTMLDivElement>,
    ) => {
      const { isObsolete, isLive } = row.original;
      if (isObsolete) {
        return;
      }

      const { _id: columnId } = column.original;
      if (isLive && !liveDocumentEditableFields.includes(columnId)) {
        return;
      }

      if (columnId === 'locking' && !lockableExchangeTypes.includes(row.original.type)) {
        return;
      }

      pendingKeyboardEvent.current = event ?? null;

      setEditedCell({
        rowId: row.original._id,
        columnId: column.original._id,
      });
    },
    [setEditedCell, pendingKeyboardEvent],
  );

  const columns = React.useMemo(
    () =>
      compact([
        {
          _id: 'rowNumber',
          accessorKey: 'rowNumber',
          Header: SimpleHeader,
          label: '',
          ValueCell: ValidationAwareRowNumberCell,
          toggleMenu: isReadOnly ? undefined : toggleMenu,
          width: isReadOnly ? ACTION_COLUMN_WIDTH : 2 * ACTION_COLUMN_WIDTH,
          fixedWidth: true,
        },
        {
          _id: 'category',
          Header: SimpleHeader,
          label: t('description'),
          description: !isSender
            ? t('supplier_one')
            : t('buyer', { ns: 'general' }),
          accessorKey: 'category',
          fieldType: FieldType.STRING,
          ValueCell: TextValueCell,
          InputCell: TextInputCell,
          startEditingCell: startEditingDocumentCell,
          showIconWhenObsolete: true,
          width: isSender ? 270 : 355,
        },
        {
          _id: 'type',
          Header: SimpleHeader,
          label: t('request.documents.requirement_one', { ns: 'translation' }),
          accessorKey: 'type',
          fieldType: FieldType.STRING,
          accessorFn: getRequirementFromExchangeType as any,
          description: !isSender
            ? t('supplier_one')
            : t('buyer', { ns: 'general' }),
          ValueCell: withProps(RequirementValueCell, {
            isDropdownButton: !isReadOnly,
          }),
          startEditingCell: startEditingDocumentCell,
          InputCell: DocumentRequirementInputCell,
          width: 349,
        },
        {
          _id: 'attachments',
          Header: SimpleHeader,
          label: t('document', { ns: 'general' }),
          accessorKey: 'attachments',
          ValueCell: isReadOnly ? AttachmentCell : AttachmentInputCell,
          InputCell: AttachmentInputCell,
          // Attachments cannot be edited using keyboard
          startEditingCell: noop,
          width: isSender ? 318 : 373,
        },
        isSender &&
          showExchangeDefVisibility && {
            _id: 'stages',
            Header: SimpleHeader,
            label: t('request.visibleFromStage', { ns: 'translation' }),
            accessorKey: 'stages',
            ValueCell: StageCell,
            width: 318,
          },
        isSender && {
          _id: 'locking',
          Header: SimpleHeader,
          label: t('request.lockResponses', { ns: 'translation' }),
          accessorKey: 'locking',
          fieldType: FieldType.STRING,
          ValueCell: isReadOnly ? withProps(LockCell, { isReadOnly }) : LockInputCell,
          InputCell: LockInputCell,
          startEditingCell: startEditingDocumentCell,
          width: 140,
        },
      ]),
    [t, isSender, isReadOnly, toggleMenu, startEditingDocumentCell, showExchangeDefVisibility],
  );

  // @ts-expect-error ts(2774) FIXME: This condition will always return true since this function is always defined. Did you mean to call it instead?
  const bodyPaddingBottom = FooterContent ? 45 : 0;

  const maxGridHeight = calculateMaxGridHeight(
    documentExchangeDefs.length,
    bodyPaddingBottom,
  );

  return isEmpty(documentExchangeDefs) ? (
    <PanelPadding>
      <Text color="subtext" fontSize={2}>
        {t('request.documents.noDocumentsAdded', { ns: 'translation' })}
      </Text>
    </PanelPadding>
  ) : (
    <PanelPadding>
      <DefaultEditableGridStyles
        style={{
          width: '100%',
          height: `min(100vh - 300px, ${maxGridHeight}px)`,
        }}
        isReadOnly={isReadOnly}
      >
        {isReadOnly ? (
          <ReadOnlyGrid
            columns={columns}
            rowData={documentExchangeDefs as any}
            frozenLeftColumnIds={frozenLeftColumnIds}
          />
        ) : (
          <DocumentsEditableGridWrapper
            columns={columns}
            rowData={documentExchangeDefs as any}
            frozenLeftColumnIds={frozenLeftColumnIds}
            bodyPaddingBottom={bodyPaddingBottom}
          />
        )}
        {menuReferenceId ? <ExchangeDefsGridMenu /> : null}
      </DefaultEditableGridStyles>
    </PanelPadding>
  );
};
