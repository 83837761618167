import { createContext, useContext, ReactNode } from 'react';
import { ExtendedSupplierListOverview } from '@deepstream/common';

const SupplierListContext = createContext<ExtendedSupplierListOverview | null>(null);

type SupplierListProviderProps = {
  supplierList: ExtendedSupplierListOverview;
  children?: ReactNode;
};

export const SupplierListProvider = ({ supplierList, ...props }: SupplierListProviderProps) => (
  <SupplierListContext.Provider value={supplierList} {...props} />
);

export function useSupplierListData(a: { required: true }): ExtendedSupplierListOverview;
export function useSupplierListData(): ExtendedSupplierListOverview;
export function useSupplierListData(a: { required: false }): ExtendedSupplierListOverview | null;
export function useSupplierListData({ required = true } = {}) {
  const supplierList = useContext<ExtendedSupplierListOverview | null>(SupplierListContext);
  if (required && !supplierList) throw new Error('No supplier list context found');
  return supplierList;
}

const SupplierListIdContext = createContext<string | null>(null);

type SupplierListIdProviderProps = {
  supplierListId: string;
  children?: ReactNode;
};

export const SupplierListIdProvider = ({ supplierListId, ...props }: SupplierListIdProviderProps) => (
  <SupplierListIdContext.Provider value={supplierListId} {...props} />
);

/**
 * Define all possible variations so that we get static AND runtime guarantees
 */
export function useSupplierListId(a: { required: true }): string;
export function useSupplierListId(): string;
export function useSupplierListId(a: { required: false }): string | null;
export function useSupplierListId({ required = true } = {}) {
  const supplierListId = useContext<string | null>(SupplierListIdContext);
  if (required && !supplierListId) throw new Error('No supplier list id context found');
  return supplierListId;
}
