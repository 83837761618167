import { FieldType } from '@deepstream/common/exchangesConfig';
import { ResponseTag } from '@deepstream/common/rfq-utils';

export type ConfigurableFieldType =
  | FieldType.STRING
  | FieldType.NUMBER
  | FieldType.DATE
  | FieldType.PRICE
  | FieldType.BOOLEAN
  | FieldType.FORMULA;

export type PredefinedFields = 'price' | 'leadTime' | 'partNumber' | 'manufacturer' | 'deliveryDate' | 'unit' | 'quantity';

export enum SupplierResponseValue {
  REPLY = 'reply',
  BOOLEAN = 'boolean',
}

export type FormulaFieldType =
  | FieldType.PRICE
  | FieldType.NUMBER;

export type ConfigurableFieldFormValues<T = string> = {
  fieldsetId: T;
  type: ConfigurableFieldType;
  fieldName?: string;
  decimalPlaces?: number;
  buyerProvidedValueEnabled?: boolean;
  supplierResponseEnabled?: boolean;
  supplierResponseType?: SupplierResponseValue;
  supplierResponseTags?: ResponseTag[];
  formula?: string;
  formulaFormat?: FormulaFieldType;
};

export type PredefinedLineItemFormValues = ConfigurableFieldFormValues<PredefinedFields>;
