import { EditableGridDataProvider } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { ExchangeType, setExchangeReplyFieldValue } from '@deepstream/common/rfq-utils';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import * as React from 'react';
import { useContext, useEffect, useMemo } from 'react';
import * as rfx from '../../rfx';
import { RequestHooksProvider } from '../../modules/Request/RequestHooksProvider';
import { useRfqExchanges } from '../../useRfq';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { LineItemsExchangeSnapshot } from '../../types';
import { EditLineItemsGrid } from '../ExchangeDefsGrid/EditLineItemsGrid';
import { ValidationErrors } from '../../draft/validation';
import { BulkSubmitContext } from './BulkSubmitContext';

type Props = {
  onSelectedExchangeChange: (exchange: LineItemsExchangeSnapshot) => void;
};

export const BulkSubmitMainContent = ({
  onSelectedExchangeChange,
}: Props) => {
  const section = rfx.useSection();
  const currentCompanyId = useCurrentCompanyId();
  const { data = [], isSuccess } = useRfqExchanges({
    sectionIds: [section._id],
    recipientId: currentCompanyId,
  });
  const { setLineItems, setValidationErrors, setSelectedExchange } = useContext(BulkSubmitContext);
  const currentSectionExchanges = useMemo(() =>
    data?.filter(exchange => exchange.def.type === ExchangeType.LINE_ITEM) as LineItemsExchangeSnapshot[],
    [data],
  );

  const onValidationErrorsChange = React.useCallback((validationErrors: ValidationErrors<any>) => {
    setValidationErrors(validationErrors);
  }, [setValidationErrors]);

  const onExchangesChange = React.useCallback((exchanges: LineItemsExchangeSnapshot[]) => {
    setLineItems(exchanges);
  }, [setLineItems]);

  useEffect(() => {
    if (isSuccess && currentSectionExchanges?.length) {
      setLineItems(currentSectionExchanges);
      setSelectedExchange(currentSectionExchanges[0]);
    }
  }, [isSuccess, currentSectionExchanges, setLineItems, setSelectedExchange]);

  return (
    currentSectionExchanges?.length ? (
      <rfx.SectionProvider key={section._id} section={section}>
        <RequestHooksProvider showErrors>
          <EditableGridDataProvider
            rowData={currentSectionExchanges}
            setValueInRow={setExchangeReplyFieldValue}
          >
            <GridIdPrefixProvider>
              <GridMenuStateProvider>
                <EditLineItemsGrid
                  height="100%"
                  onValidationErrorsChange={onValidationErrorsChange}
                  onExchangesChange={onExchangesChange}
                  onSelectedExchangeChange={onSelectedExchangeChange}
                />
              </GridMenuStateProvider>
            </GridIdPrefixProvider>
          </EditableGridDataProvider>
        </RequestHooksProvider>
      </rfx.SectionProvider>
    ) : 'Loading...'
  );
};
