import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { useQuery, useQueryClient } from 'react-query';
import { ChangeType } from '@deepstream/common/rfq-utils';
import * as yup from 'yup';
import { callAll } from '@deepstream/utils/callAll';
import { ModalForm } from './ModalForm';
import { useToaster } from './toast';
import { useApi, wrap } from './api';
import { useCurrentCompanyId } from './currentCompanyId';
import { useRequestsNavigation } from './RequestsNavigationContext';
import { useMutation } from './useMutation';
import { TextField } from './form/TextField';

type NewBlankTemplateModalProps = {
  isOpen: boolean;
  close: any;
};

export const NewBlankTemplateModal: React.FC<NewBlankTemplateModalProps> = ({
  isOpen,
  close,
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const { navigateToTemplateEdit } = useRequestsNavigation();

  const { data: templates } = useQuery(
    ['templates', { companyId: currentCompanyId }],
    wrap(api.getTemplates),
  );

  const [createBlankTemplate] = useMutation(
    api.createBlankTemplate,
    {
      onSettled: callAll(
        () => queryClient.invalidateQueries(['templates', { companyId: currentCompanyId }]),
        close,
      ),
      onSuccess: callAll(
        () => toaster.success(t('requests.toaster.templateCreated.success')),
        (templateId) => navigateToTemplateEdit(templateId),
      ),
      onError: () => toaster.error(t('requests.toaster.templateCreated.failed')),
    },
  );

  const initialValues = { name: '' };

  const stages = React.useMemo(
    () => [
      {
        _id: uuid(),
        name: '',
        intentionDeadline: null,
        completionDeadline: null,
        isPrivate: false,
      },
    ],
    [],
  );

  const createTemplate = React.useCallback(
    ({ name }) => createBlankTemplate({
      companyId: currentCompanyId,
      stages,
      changes: [{
        type: ChangeType.TEMPLATE_FIELD_UPDATED,
        fieldName: 'name',
        fieldValue: name,
      }],
    }),
    [currentCompanyId, stages, createBlankTemplate],
  );

  return templates ? (
    (
      <ModalForm
        heading={t('requests.dialog.newTemplate.heading')}
        initialValues={initialValues}
        validationSchema={
          yup.object().shape({
            name: yup.string()
              .required(t('general.required'))
              .test(
                'uniqueName',
                t('template.errors.duplicateTemplateName'),
                value => !templates.find(template =>
                  template.meta.name.toLowerCase() === value?.toLowerCase().trim(),
                ),
              ),
          })
        }
        disableSubmitIfNotDirty={true}
        isOpen={isOpen}
        onCancel={close}
        onSubmit={createTemplate}
        submitLabel={t('requests.dialog.newTemplate.okButtonText')}
      >
        <TextField name="name" label={t('template.templateName')} required />
      </ModalForm>
    )
  ) : null;
};
