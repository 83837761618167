import {
  BidStatus,
} from '@deepstream/common/rfq-utils';
import { spendAndSavingsInitialState } from '@deepstream/common/rfq-utils/spendAndSavings';
import { useMachine } from '@xstate/react';
import { isEmpty, last, map, unary } from 'lodash';
import { useEffect } from 'react';
import { isPast } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { useApi } from '../../../api';
import { useMutation } from '../../../useMutation';
import * as rfx from '../../../rfx';
import { useWaitForRfqUnlock } from '../../../useWaitForUnlock';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useGetRfqComputedCostAndSavings, useRfqId } from '../../../useRfq';
import { awardRequestMachine } from './awardRequestMachine';
import { CannotEndRequestModal } from './CannotEndRequestModal';
import { RequestOutcomeModal } from './RequestOutcomeModal';
import { useAwardStateToComponent } from './RequestOutcomeModalStates';
import { Actions } from './Actions';

type AwardRequestModalProps = ModalProps & {
  onClose: (shouldRefetchRfq?: boolean) => void;
  isAwarded: boolean;
};

export const AwardRequestModal = ({
  onClose,
  isOpen,
  isAwarded,
}: AwardRequestModalProps) => {
  const { t } = useTranslation();
  const rfqId = useRfqId();
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { bidById, recipients, stages, spendAndSavings } = rfx.useStructure();
  // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
  const lastStageDeadline = rfx.useStageDeadline(last(stages)._id);
  const awardStateToComponent = useAwardStateToComponent();
  const waitForRfqUnlock = useWaitForRfqUnlock();

  const [awardBid] = useMutation(
    ({ rfqId, companyId, awarded, unsuccessful, spendAndSavings }: any) => waitForRfqUnlock({
      command: () => api.awardBid({
        rfqId,
        companyId,
        awarded: {
          recipientIds: map(awarded.suppliers, '_id'),
          message: awarded.hasMessage ? awarded.message : undefined,
          attachments: awarded.attachments,
        },
        unsuccessful: {
          recipientIds: map(unsuccessful.suppliers, '_id'),
          message: unsuccessful.hasMessage ? unsuccessful.message : undefined,
          attachments: unsuccessful.attachments,
        },
        spendAndSavings,
      }),
    }),
  );

  const getRfqComputedCostAndSavings = useGetRfqComputedCostAndSavings({
    rfqId,
    currentCompanyId,
  });

  const [current, send] = useMachine(
    awardRequestMachine,
    {
      services: {
        onConfirm: unary(awardBid),
        fetchCalculatedData: async (context) => {
          const awardedSupplierIds = map(context.awarded.suppliers, '_id');

          if (isEmpty(awardedSupplierIds)) {
            return {};
          }

          return getRfqComputedCostAndSavings(awardedSupplierIds);
        },
      },
    },
  );

  useEffect(
    () => {
      if (isAwarded) {
        return;
      }

      send(Actions.INIT, {
        rfqId,
        companyId: currentCompanyId,
        suppliers: recipients
          .map(recipient => ({
            ...recipient,
            bidStatus: bidById[recipient._id].status,
          }))
          .filter(({ bidStatus }) => {
            return bidStatus !== BidStatus.UNSUCCESSFUL;
          }),
        spendAndSavings: spendAndSavings || {
          ...spendAndSavingsInitialState,
          enabled: false,
        },
        // we hide the item to enable/disable spend and savings
        // if they are already enabled in the request
        canToggleSpendAndSavings: !spendAndSavings,
      });
    },
    [isAwarded, rfqId, currentCompanyId, send, isOpen, recipients, bidById, spendAndSavings],
  );

  return (
    // @ts-expect-error ts(2345) FIXME: Argument of type 'Date | null' is not assignable to parameter of type 'number | Date'.
    isPast(lastStageDeadline) ? (
      <RequestOutcomeModal
        modalTitle={t('request.awardRequest')}
        isOpen={isOpen}
        stateComponents={awardStateToComponent}
        send={send}
        state={current}
        onClose={onClose}
      />
    ) : (
      <CannotEndRequestModal
        isOpen={isOpen}
        onClose={onClose}
      />
    )
  );
};
