import { useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { isEmpty, noop } from 'lodash';
import { Form, Formik } from 'formik';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { SupplierGroup } from '@deepstream/common/rfq-utils';
import { useMemo } from 'react';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData } from '../types';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { CheckboxField2, CheckboxFieldBase } from '../../../../form/CheckboxField';
import { Direction } from '../../../../ui/MultiStepFlow/types';

type SupplierGroupOrNone = SupplierGroup | 'none';

const iconPropsBySupplierGroup: Record<string, IconProps> = {
  awarded: {
    icon: 'trophy',
    regular: true,
  },
  unsuccessful: {
    icon: 'circle-xmark',
    regular: true,
  },
  previouslyUnsuccessful: {
    icon: 'circle-xmark',
    regular: true,
  },
  nonBidding: {
    icon: 'circle-o',
  },
};

const FieldLabel = ({ supplierGroup }: { supplierGroup: SupplierGroupOrNone }) => {
  const { t } = useTranslation('translation');

  const iconProps = iconPropsBySupplierGroup[supplierGroup];

  return (
    <>
      <Text
        as="span"
        fontSize={4}
        fontWeight={500}
        lineHeight={1.4}
        sx={{
          position: 'relative',
          top: '-4px',
        }}
      >
        {iconProps && <Icon {...iconProps} mr={1} />}
        {t(`request.awardFlow.steps.chooseSupplierTypesToMessage.options.${supplierGroup}.label`)}
      </Text>
      <Text fontSize={2} color="subtext">
        {t(`request.awardFlow.steps.chooseSupplierTypesToMessage.options.${supplierGroup}.description`)}
      </Text>
    </>
  );
};

export const ChooseSupplierTypesToMessageStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (data: Partial<AwardFlowData> | null, direction: Direction) => void,
}) => {
  const { t } = useTranslation('translation');

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      supplierGroupsToMessage: z.custom<AwardFlowData['supplierGroupsToMessage']>().refine(
        value => Boolean(
          value.awarded ||
          value.unsuccessful ||
          value.previouslyUnsuccessful ||
          value.nonBidding ||
          value.none,
        ),
        { message: t('request.awardFlow.errors.selectSupplierTypesToMessage') },
      ),
    });

    return toFormikValidationSchema(Schema);
  }, [t]);

  return (
    <Formik
      validateOnBlur
      initialValues={{
        supplierGroupsToMessage: data.supplierGroupsToMessage,
      }}
      validationSchema={validationSchema}
      onSubmit={noop}
    >
      {({ values, submitForm, validateForm, dirty, setFieldValue, setFieldTouched }) => (
        <Form>
          <lotPagesLayout.ContentWrapper>
            <StepNavigation
              onBackClick={() => submitAndNavigate(dirty ? values : null, Direction.BACK)}
              onContinueClick={async () => {
                const errors = await validateForm();

                await submitForm();

                if (isEmpty(errors)) {
                  submitAndNavigate(dirty ? values : null, Direction.FORWARD);
                }
              }}
            >
              <FormErrors />
              <lotPagesLayout.Section heading={t('request.awardFlow.steps.chooseSupplierTypesToMessage.heading')}>
                <Text mt="20px">
                  {t('request.awardFlow.steps.chooseSupplierTypesToMessage.info1')}
                </Text>
                <Text mt={2}>
                  {t('request.awardFlow.steps.chooseSupplierTypesToMessage.info2')}
                </Text>
                <lotPagesLayout.H3 mt="40px">
                  {t('request.awardFlow.steps.chooseSupplierTypesToMessage.chooseWhichType')}
                </lotPagesLayout.H3>
                <Box mt="20px">
                  <Stack gap="20px">
                    {([
                      'awarded',
                      'unsuccessful',
                      'previouslyUnsuccessful',
                      'nonBidding',
                    ] as const).map(supplierGroup => (
                      <CheckboxField2
                        key={supplierGroup}
                        fieldLabel={<FieldLabel supplierGroup={supplierGroup} />}
                        name={`supplierGroupsToMessage.${supplierGroup}`}
                        disabled={isEmpty(data.supplierIdsByGroup[supplierGroup]) || values.supplierGroupsToMessage.none}
                        errorMessageStyle={{ fontSize: '14px', marginTop: '16px', marginLeft: '-24px' }}
                      />
                    ))}
                    <Text fontSize={2}>
                      {t('request.awardFlow.steps.chooseSavingsMethod.optionSeparator')}
                    </Text>
                    <CheckboxFieldBase
                      fieldLabel={<FieldLabel supplierGroup="none" />}
                      name="supplierGroupsToMessage.none"
                      value={values.supplierGroupsToMessage.none}
                      onChange={async (event: any) => {
                        await setFieldValue('supplierGroupsToMessage', { none: Boolean(event.target.checked) });
                        await setFieldTouched('supplierGroupsToMessage.none', true);
                      }}
                      errorMessageStyle={{ fontSize: '14px', marginTop: '16px', marginLeft: '-24px' }}
                    />
                  </Stack>
                </Box>
                <lotPagesLayout.InfoText mt="20px">
                  {t('request.awardFlow.steps.chooseSupplierTypesToMessage.info3')}
                </lotPagesLayout.InfoText>
              </lotPagesLayout.Section>
            </StepNavigation>
          </lotPagesLayout.ContentWrapper>
        </Form>
      )}
    </Formik>
  );
};
