import { Box } from 'rebass/styled-components';
import { QuestionType } from '@deepstream/common/rfq-utils';

import { useTranslation } from 'react-i18next';
import {
  DateTimeField,
  QuestionOptionField,
  ShortTextField,
  LongTextField,
  AddressFields,
  DocumentField,
  GridField,
  YesNoField,
  PriceField,
} from '../../../../draft/QuestionFields';
import { FieldContainer } from '../../../../form/FieldContainer';
import { useQuestionForm } from '../../utils';

export const SupplierResponse = () => {
  const { t } = useTranslation('preQualification');
  const { values } = useQuestionForm();
  const { questionType } = values;

  const isGridQuestion = questionType === QuestionType.GRID;

  return (
    <Box mt={3} mb={2}>
      <FieldContainer
        label={isGridQuestion ? undefined : t('questionModal.supplierResponse')}
        showAsterisk
      >
        {questionType === QuestionType.SHORT_TEXT ? (
          <ShortTextField />
        ) : questionType === QuestionType.LONG_TEXT ? (
          <LongTextField />
        ) : questionType === QuestionType.ADDRESS ? (
          <AddressFields />
        ) : questionType === QuestionType.CHECKBOXES || questionType === QuestionType.MULTIPLE_CHOICE ? (
          <QuestionOptionField type={questionType} />
        ) : questionType === QuestionType.DATE_TIME ? (
          <DateTimeField />
        ) : questionType === QuestionType.DOCUMENT ? (
          <DocumentField />
        ) : questionType === QuestionType.GRID ? (
          <GridField />
        ) : questionType === QuestionType.YES_NO ? (
          <YesNoField />
        ) : questionType === QuestionType.PRICE ? (
          <PriceField />
        ) : (
          null
        )}
      </FieldContainer>
    </Box>
  );
};
