import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { TabButtonProps, TabButton } from '@deepstream/ui-kit/elements/button/TabButton';
import { useModalState } from '../../ui/useModalState';
import { AddContractPageModal } from './AddContractPageModal';
import { useDraftContractNavigation } from '../../appNavigation';

export const AddPageButton = ({ children, ...props }: TabButtonProps) => {
  const { t } = useTranslation('contracts');
  const addPageModal = useModalState();
  const { navigateToDetailsPage } = useDraftContractNavigation();

  const onAddPageSuccess = useCallback(
    (pageId: string) => {
      navigateToDetailsPage(pageId);
      addPageModal.close();
    },
    [addPageModal, navigateToDetailsPage],
  );

  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        height="37px"
      >
        <TabButton
          iconLeft="plus"
          height="100%"
          py={0}
          px={3}
          onClick={addPageModal.open}
          {...props}
        >
          {t('details.addPage')}
        </TabButton>
      </Flex>
      {addPageModal.isOpen && (
        <AddContractPageModal
          isOpen={addPageModal.isOpen}
          onCancel={addPageModal.close}
          onSuccess={onAddPageSuccess}
        />
      )}
    </>
  );
};
