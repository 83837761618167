import { useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { isEmpty, noop, isFinite } from 'lodash';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { TotalSavingsCalculationMethod } from '@deepstream/common/rfq-utils';
import { MessageBlock3 } from '@deepstream/ui-kit/elements/MessageBlock';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData } from '../types';
import { RadioField } from '../../../../form/RadioField';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { Direction } from '../../../../ui/MultiStepFlow/types';

type SavingsMethodOption =
  | TotalSavingsCalculationMethod.SUM_SPECIFIC_SAVINGS
  | TotalSavingsCalculationMethod.BUDGET_FINAL_VALUE_DIFF
  | TotalSavingsCalculationMethod.MANUAL
  | 'none';

const useOptions = (data: AwardFlowData) => {
  const { t } = useTranslation('translation');

  return useMemo(() => {
    const canSelectSumSpecificSavings = data.hasLineItemsWithTotalCost;
    const canSelectBudgetFinalValueDiff = (
      data.spendAndSavings.hasBudgetedTotalValue &&
      isFinite(data.spendAndSavings.budgetedTotalValue) &&
      (
        (
          isFinite(data.spendAndSavings.calculatedTotalValue) &&
          data.spendAndSavings.isCalculatedTotalValueAccurate
        ) ||
        isFinite(data.spendAndSavings.manualTotalValue)
      )
    );

    return [
      TotalSavingsCalculationMethod.SUM_SPECIFIC_SAVINGS,
      TotalSavingsCalculationMethod.BUDGET_FINAL_VALUE_DIFF,
      TotalSavingsCalculationMethod.MANUAL,
      'none',
    ].map(value => ({
      value,
      label: (
        <Box fontSize={4} mt={value === 'none' ? 0 : '-5px'} color="text">
          {t(`request.awardFlow.steps.chooseSavingsMethod.options.${value}.label`)}
        </Box>
      ),
      description: value === 'none'
        ? null
        : (
          <Box as="span" fontSize={2}>
            {value === TotalSavingsCalculationMethod.SUM_SPECIFIC_SAVINGS && !canSelectSumSpecificSavings ? (
              t('request.awardFlow.steps.chooseSavingsMethod.sumSpecificSavingsNotAvailable')
            ) : value === TotalSavingsCalculationMethod.BUDGET_FINAL_VALUE_DIFF && !canSelectBudgetFinalValueDiff ? (
              t('request.awardFlow.steps.chooseSavingsMethod.budgetFinalValueDiffNotAvailable')
            ) : (
              t(`request.awardFlow.steps.chooseSavingsMethod.options.${value}.description`)
            )}
          </Box>
        ),
      separatorAbove: value === 'none'
        ? (
          <Text fontSize={2}>
            {t('request.awardFlow.steps.chooseSavingsMethod.optionSeparator')}
          </Text>
        )
        : null,
      disabled: Boolean(
        (value === TotalSavingsCalculationMethod.SUM_SPECIFIC_SAVINGS && !canSelectSumSpecificSavings) ||
        (value === TotalSavingsCalculationMethod.BUDGET_FINAL_VALUE_DIFF && !canSelectBudgetFinalValueDiff),
      ),
    }));
  }, [t, data]);
};

const getInitialSavingsMethod = ({ spendAndSavings }: AwardFlowData): SavingsMethodOption => {
  if (spendAndSavings.canProvideTotalSavings === false) {
    return 'none';
  } else {
    // @ts-expect-error ts(2322) FIXME: Type 'TotalSavingsCalculationMethod | null' is not assignable to type 'SavingsMethodOption'.
    return spendAndSavings.totalSavingsCalculationMethod;
  }
};

const getSubmissionDataFromFormValues = (
  { savingsMethod }: { savingsMethod: SavingsMethodOption },
  data: AwardFlowData,
): Partial<AwardFlowData> | null => {
  if (savingsMethod === 'none') {
    return {
      spendAndSavings: {
        ...data.spendAndSavings,
        canProvideTotalSavings: false,
        totalSavingsCalculationMethod: null,
      },
    };
  } else if (savingsMethod) {
    return {
      spendAndSavings: {
        ...data.spendAndSavings,
        canProvideTotalSavings: true,
        totalSavingsCalculationMethod: savingsMethod,
      },
    };
  } else {
    return null;
  }
};

export const ChooseSavingsMethodStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (data: Partial<AwardFlowData> | null, direction: Direction) => void,
}) => {
  const { t } = useTranslation('translation');
  const options = useOptions(data);
  const theme = useTheme();

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      savingsMethod: z.string({ message: t('request.awardFlow.errors.selectSavingsMethod') }),
  });

    return toFormikValidationSchema(Schema);
  }, [t]);

  return (
    <Formik
      validateOnBlur
      initialValues={{
        savingsMethod: getInitialSavingsMethod(data),
      }}
      validationSchema={validationSchema}
      onSubmit={noop}
    >
      {({ values, submitForm, validateForm, dirty }) => (
        <Form>
          <lotPagesLayout.ContentWrapper>
            <StepNavigation
              onBackClick={() => submitAndNavigate(dirty ? getSubmissionDataFromFormValues(values, data) : null, Direction.BACK)}
              onContinueClick={async () => {
                const errors = await validateForm();

                await submitForm();

                if (isEmpty(errors)) {
                  submitAndNavigate(dirty ? getSubmissionDataFromFormValues(values, data) : null, Direction.FORWARD);
                }
              }}
            >
              <FormErrors />
              <lotPagesLayout.Section
                heading={t('request.awardFlow.steps.chooseSavingsMethod.heading')}
              >
                <Text mt="20px">
                  {t('request.awardFlow.steps.chooseSavingsMethod.info1')}
                </Text>
                <Text mt={2}>
                  {t('request.awardFlow.steps.chooseSavingsMethod.info2')}
                </Text>
                <Box mt="20px">
                  <RadioField
                    name="savingsMethod"
                    fieldLabelStyle={{
                      fontSize: theme.fontSizes[4],
                      fontWeight: 500,
                      color: theme.colors.text,
                      marginBottom: '12px',
                    }}
                    gap="20px"
                    options={options}
                    showError
                    errorMessageStyle={{ fontSize: '14px', marginTop: '16px' }}
                  />
                </Box>
                {values.savingsMethod === 'none' && (
                  <MessageBlock3 variant="warn" mt="20px">
                    {t('request.awardFlow.steps.chooseSavingsMethod.noSavingsWarning')}
                  </MessageBlock3>
                )}
              </lotPagesLayout.Section>
            </StepNavigation>
          </lotPagesLayout.ContentWrapper>
        </Form>
      )}
    </Formik>
  );
};
