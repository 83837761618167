import { QuestionnaireOverview } from '@deepstream/common/preQual';
import { SentContractOverview } from '@deepstream/common/contract';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { CompanyProfilePreQualificationActivity } from './CompanyProfilePreQualificationActivity';
import { CompanyProfileRequestsActivity } from './CompanyProfileRequestsActivity';
import { CompanyProfileContractsActivity } from './CompanyProfileContractsActivity';
import { SentRequestOverview } from '../../../types';

export const CompanyProfileActivityContent = ({
  questionnaires,
  requests,
  contracts,
  currentCompanyId,
  companyId,
}: {
  questionnaires: QuestionnaireOverview[],
  requests: SentRequestOverview[],
  contracts: SentContractOverview[],
  currentCompanyId: string,
  companyId: string,
}) => {
  return (
    <Stack gap={3} sx={{ backgroundColor: 'lightGray3', mt: 20 }}>
      <CompanyProfilePreQualificationActivity
        questionnaires={questionnaires}
        currentCompanyId={currentCompanyId}
      />
      <CompanyProfileRequestsActivity
        requests={requests}
        currentCompanyId={currentCompanyId}
        companyId={companyId}
      />
      <CompanyProfileContractsActivity
        contracts={contracts}
        currentCompanyId={currentCompanyId}
      />
    </Stack>
  );
};
