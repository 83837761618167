import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';

import { CancelButton, Button } from '@deepstream/ui-kit/elements/button/Button';
import { PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { Modal, ModalHeader, ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { useCurrentCompanyId } from './currentCompanyId';
import { UnmappedExternalSystemSuppliers } from './ExternalSuppliersDetails';
import { useNavigate } from './tanstackRouter';
import { companySettingsRoute } from './AppRouting';
import { CompanySettingsTab } from './modules/CompanySettings/companySettingsConstants';

type DialogProps = ModalProps & {
  heading: string
  body: React.ReactNode;
  onOk: () => void;
  onCancel?: () => void;
};

type UnmappedExternalSuppliersWarningDialogProps = Omit<DialogProps, 'heading' | 'body'> & {
  externalSystem: { name: string; systemId: string; companyId: string };
  externalSupplierIds: [];
  isAdmin: boolean;
};

export const UnmappedExternalSuppliersWarningDialog = ({
  externalSystem,
  externalSupplierIds = [],
  isAdmin,
  ...props
}: UnmappedExternalSuppliersWarningDialogProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  return (
    <Modal onRequestClose={props.onCancel} contentLabel={t('unmappedSuppliersWarning', { ns: 'integration' })} {...props}>
      <ModalHeader onClose={props.onCancel}>
        {t('unmappedSuppliersWarning', { ns: 'integration' })}
      </ModalHeader>
      <Text p={3} fontSize={2}>
        <UnmappedExternalSystemSuppliers
          externalSystemName={externalSystem.name}
          externalSupplierIds={externalSupplierIds}
        />
      </Text>
      <PanelDivider />
      {isAdmin ? (
        <Flex p={3} justifyContent="space-between">
          <CancelButton
            onClick={props.onCancel}
          >
            {t('general.cancel')}
          </CancelButton>
          <Box>
            <Button
              type="button"
              variant="secondary"
              onClick={props.onOk}
              style={{ minWidth: 60 }}
              mr={2}
            >
              {t('requests.newRequest')}
            </Button>
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                navigate({
                  to: companySettingsRoute.to,
                  params: { currentCompanyId },
                  search: { tab: CompanySettingsTab.SUPPLIER_MAP },
                });
              }}
            >
              {t('openSupplierMap', { ns: 'integration' })}
            </Button>
          </Box>
        </Flex>
      ) : (
        <Flex p={3} justifyContent="flex-end">
          <Button
            type="button"
            variant="secondary"
            onClick={props.onOk}
            style={{ minWidth: 60 }}
            mr={2}
          >
            {t('requests.newRequest')}
          </Button>
          <Button
            variant="primary"
            onClick={props.onCancel}
            type="button"
          >
            {t('general.dismiss')}
          </Button>
        </Flex>
      )}
    </Modal>
  );
};
