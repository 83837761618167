import { Text, Box } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useRouter } from '@tanstack/react-router';
import { BidIntentionStatus, RequestInteractivityStatus } from '@deepstream/common/rfq-utils';
import { useModalState } from '../../../ui/useModalState';
import * as rfx from '../../../rfx';
import { IntentionToBidModal, NoIntentionToBidModal } from '../../../BidIntentionPanel';
import { useRequestRecipientNavigation } from '../../../appNavigation';
import * as lotPagesLayout from '../Live/lotPagesLayout';
import { HtmlSectionId } from '../Live/constants';

export const RequestRecipientMyResponseSection = () => {
  const { t } = useTranslation('translation');
  const router = useRouter();
  const navigation = useRequestRecipientNavigation();
  const intentionToBidModal = useModalState();
  const noIntentionToBidModal = useModalState();
  const bid = rfx.useBid();
  const requestInteractivityStatus = rfx.useRequestInteractivityStatus();
  const bidIntentionStatus = rfx.useBidIntentionStatus();

  const hasIntentionToBid = Boolean(bid.activatedStageIds.length);
  const enteredStageNumber = bid.enteredStageIds.length;
  const activatedStageNumber = bid.activatedStageIds.length;
  const hasWithdrawnInCurrentStage = (
    bidIntentionStatus === BidIntentionStatus.BID_WITHDRAWN &&
    bid.outcome?.stageId === bid.stageId
  );

  return requestInteractivityStatus === RequestInteractivityStatus.LIVE ? (
    <>
      <lotPagesLayout.Section heading={t('request.myResponse')} id={HtmlSectionId.MY_RESPONSE}>
        <Text mt="12px">
          {bidIntentionStatus === BidIntentionStatus.NO_RESPONSE ? (
            t('request.intention.youHaveNotYetResponded')
          ) : bidIntentionStatus === BidIntentionStatus.DECLINED_TO_BID ? (
            <>
              {t('request.intention.youDeclined')}
              {' '}
              {t('request.intention.youCanStillStartBidding')}
            </>
          ) : bidIntentionStatus === BidIntentionStatus.BID_WITHDRAWN ? (
            <>
              {t('request.intention.youWithdrewYourBid')}
              {' '}
              {t('request.intention.youCanStillStartBidding')}
            </>
          ) : (
            null
          )}
        </Text>
        <Text mt="6px" mb="14px">
          {t('request.intention.ifYouStartBidding')}
        </Text>
        <Box>
          <Button onClick={intentionToBidModal.open} iconLeft="check" mr="10px">
            {t('request.intention.startBidding')}
          </Button>
          {bidIntentionStatus === BidIntentionStatus.NO_RESPONSE && (
            <Button variant="secondary" iconLeft="xmark" onClick={noIntentionToBidModal.open}>
              {t('request.suppliersTable.declineToBid')}
            </Button>
          )}
          <lotPagesLayout.InfoText>
            {t('request.intention.youCanOpenStagesBelow')}
          </lotPagesLayout.InfoText>
        </Box>
      </lotPagesLayout.Section>
      <IntentionToBidModal
        isOpen={intentionToBidModal.isOpen}
        enteredStageNumber={enteredStageNumber!}
        activatedStageNumber={activatedStageNumber!}
        isBidWithdrawn={bidIntentionStatus === BidIntentionStatus.BID_WITHDRAWN}
        hasWithdrawnInCurrentStage={hasWithdrawnInCurrentStage}
        close={intentionToBidModal.close}
      />
      <NoIntentionToBidModal
        isOpen={noIntentionToBidModal.isOpen}
        hasIntentionToBid={hasIntentionToBid}
        close={noIntentionToBidModal.close}
      />
    </>
  ) : (
    <lotPagesLayout.Section heading={t('request.myResponse')}>
      <Text mt="12px">
        {bidIntentionStatus === BidIntentionStatus.NO_RESPONSE ? (
          t('request.intention.youDidNotRespond')
        ) : bidIntentionStatus === BidIntentionStatus.DECLINED_TO_BID ? (
          t('request.intention.youDeclined')
        ) : bidIntentionStatus === BidIntentionStatus.BID_WITHDRAWN ? (
          t('request.intention.youWithdrewYourBid')
        ) : (
          null
        )}
      </Text>
      <lotPagesLayout.InfoText>
        {t('request.intention.youCannotChangeYourResponse')}
      </lotPagesLayout.InfoText>
    </lotPagesLayout.Section>
  );
};
