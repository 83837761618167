import { useState, useMemo, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { Draft, Stage } from '@deepstream/common/rfq-utils';
import { first, some, values } from 'lodash';
import { EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { MoveUpMenuItem, MoveDownMenuItem, DeleteMenuItem, EllipsisMenu } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { useModalState } from '../ui/useModalState';
import { Bold } from '../Bold';
import { ConfirmMoveStageDialog } from './ConfirmMoveStageDialog';
import * as rfx from '../rfx';

type EditButtonProps = {
  stage: Stage<Draft>;
  stageIndex: number;
  disabled?: boolean;
  editStage: () => void;
  deleteStage: () => void;
  moveUp: () => void;
  moveDown: () => void;
  onExpandedStateChange?: (expanded: boolean) => void;
};

export const LegacyEditStageButton = ({
  stage,
  stageIndex,
  disabled,
  editStage,
  deleteStage,
  moveUp,
  moveDown,
  onExpandedStateChange,
}: EditButtonProps) => {
  const { t } = useTranslation();
  const deleteConfirmationDialog = useModalState();
  const moveConfirmationDialog = useModalState();
  const [affectedStageIndex, setAffectedStageIndex] = useState<number | null>(null);
  const { exchangeDefById, stages } = rfx.useStructure();

  const hasRelatedExchanges = useMemo(
    () => some(
      values(exchangeDefById),
      exchangeDef => first(exchangeDef.stages) === stage._id,
    ),
    [exchangeDefById, stage],
  );

  const showOverflowActions = useMemo(
    () => stages.length > 1 && !stage.isLive,
    [stages, stage],
  );

  const canMoveUp = useMemo(
    () => {
      const previousStage = stages[stageIndex - 1];

      return !stage.isLive && stageIndex > 0 && !previousStage.isLive;
    },
    [stageIndex, stages, stage],
  );

  const canMoveDown = useMemo(
    () => !stage.isLive && stageIndex !== stages.length - 1,
    [stageIndex, stages, stage],
  );

  const onDelete = useCallback(
    () => {
      if (hasRelatedExchanges) {
        deleteConfirmationDialog.open();
      } else {
        deleteStage();
      }
    },
    [hasRelatedExchanges, deleteStage, deleteConfirmationDialog],
  );

  const onConfirmDelete = useCallback(
    () => {
      deleteStage();
      deleteConfirmationDialog.close();
    },
    [deleteStage, deleteConfirmationDialog],
  );

  const shouldEditVisibility = useCallback(
    (firstStage, secondStage) => secondStage.isPrivate && !firstStage.isPrivate,
    [],
  );

  const onMoveUp = useCallback(
    () => {
      const previousStage = stages[stageIndex - 1];
      const currentStage = stages[stageIndex];

      if (shouldEditVisibility(previousStage, currentStage)) {
        setAffectedStageIndex(stageIndex - 1);
        moveConfirmationDialog.open();
      } else {
        moveUp();
      }
    },
    [stages, stageIndex, moveConfirmationDialog, moveUp, shouldEditVisibility],
  );

  const onMoveDown = useCallback(
    () => {
      const currentStage = stages[stageIndex];
      const nextStage = stages[stageIndex + 1];

      if (shouldEditVisibility(currentStage, nextStage)) {
        setAffectedStageIndex(stageIndex + 1);
        moveConfirmationDialog.open();
      } else {
        moveDown();
      }
    },
    [stages, stageIndex, moveConfirmationDialog, moveDown, shouldEditVisibility],
  );

  const onConfirmMove = useCallback(
    () => {
      if (stageIndex < affectedStageIndex!) {
        moveDown();
      } else {
        moveUp();
      }

      setAffectedStageIndex(null);
      moveConfirmationDialog.close();
    },
    [stageIndex, affectedStageIndex, moveConfirmationDialog, moveUp, moveDown],
  );

  const onCancelMove = useCallback(
    () => {
      moveConfirmationDialog.close();
      setAffectedStageIndex(null);
    },
    [moveConfirmationDialog],
  );

  return (
    <>
      <Flex alignItems="center">
        <ButtonGroup marginBetween="-1px">
          <EditButton small onClick={editStage} disabled={disabled} />
          {showOverflowActions && (
            <EllipsisMenu
              small
              disabled={disabled}
              onExpandedStateChange={onExpandedStateChange}
            >
              <MoveUpMenuItem onSelect={onMoveUp} disabled={!canMoveUp} />
              <MoveDownMenuItem onSelect={onMoveDown} disabled={!canMoveDown} />
              <DeleteMenuItem onSelect={onDelete} />
            </EllipsisMenu>
          )}
        </ButtonGroup>
      </Flex>
      <Dialog
        heading={t('request.stages.dialog.deleteStageConfirmation.heading', { stageNumber: stageIndex + 1 })}
        body={(
          <MessageBlock variant="warn" mt={0} maxWidth="470px">
            <Trans i18nKey="request.stages.dialog.deleteStageConfirmation.generalBody">
              This stage contains content on the <Bold>Details</Bold> tab which will also be deleted. This cannot be undone.
            </Trans>
          </MessageBlock>
        )}
        okButtonText={t('general.delete')}
        okButtonVariant="danger"
        cancelButtonText={t('general.cancel')}
        cancelButtonVariant="secondary"
        isOpen={deleteConfirmationDialog.isOpen}
        onOk={onConfirmDelete}
        onCancel={deleteConfirmationDialog.close}
      />
      <ConfirmMoveStageDialog
        stage={stage}
        stageIndex={stageIndex}
        affectedStageIndex={affectedStageIndex!}
        isOpen={moveConfirmationDialog.isOpen}
        onOk={onConfirmMove}
        onCancel={onCancelMove}
      />
    </>
  );
};
