import { useCallback } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { RfqStatus, BidStatus } from '@deepstream/common/rfq-utils';
import { ContractStatus } from '@deepstream/common/contract';
import { useTranslation } from 'react-i18next';
import { Dashboard } from '.';
import {
  requestEditSummaryRoute,
  dashboardRoute,
  notificationsRoute,
  reportingIndexRoute,
  teamManagementRoute,
  requestsIndexRoute,
  requestSentIndexRoute,
  legacyRequestReceivedIndexRoute,
  contractDraftSummaryRoute,
  contractLiveSummaryRoute,
  contractsRoute,
} from '../../AppRouting';
import * as Layout from '../../Layout';

type DashboardNavigation =
  | {
    type: 'request';
    item: {
      _id: string;
      status: RfqStatus;
      bidStatus?: BidStatus;
      type: 'received' | 'sent';
      subject?: string;
    };
  }
  | {
    type: 'contract';
    item: {
      _id: string;
      status: ContractStatus;
    };
  }
  | {
    type: 'notification';
    item: {
      _id: string;
      navigateOptions?: any;
      notificationAction?: () => any;
    };
  };

export const DashboardPage = () => {
  const { t } = useTranslation();
  const { currentCompanyId } = dashboardRoute.useParams();
  const navigate = useNavigate({ from: dashboardRoute.id });

  const navigateToItem = useCallback(
    ({ type, item }: DashboardNavigation) => {
      switch (type) {
        case 'request': {
          const rfqId = item._id;

          if (item.status === 'draft') {
            navigate({
              to: requestEditSummaryRoute.to,
              params: { currentCompanyId, rfqId },
            });
          } else if (item.type === 'sent') {
            navigate({
              to: requestSentIndexRoute.to,
              params: { currentCompanyId, rfqId },
            });
          } else if (item.type === 'received') {
            navigate({
              to: legacyRequestReceivedIndexRoute.to,
              params: { currentCompanyId, rfqId },
            });
          }

          break;
        }

        case 'contract': {
          const contractId = item._id;

          if (item.status === 'draft') {
            navigate({
              to: contractDraftSummaryRoute.to,
              params: { currentCompanyId, contractId },
            });
          } else {
            navigate({
              to: contractLiveSummaryRoute.to,
              params: { currentCompanyId, contractId },
            });
          }

          break;
        }

        case 'notification': {
          return item.navigateOptions
            ? navigate(item.navigateOptions)
            : item.notificationAction
              ? item.notificationAction()
              : null;
        }
      }
    },
    [currentCompanyId, navigate],
  );

  const navigateToItemIndex = useCallback(
    (type: 'requests' | 'contracts' | 'notifications' | 'report') => {
      switch (type) {
        case 'requests':
          return navigate({ to: requestsIndexRoute.to, params: { currentCompanyId } });
        case 'contracts':
          return navigate({ to: contractsRoute.to, params: { currentCompanyId } });
        case 'notifications':
          return navigate({ to: notificationsRoute.to, params: { currentCompanyId } });
        case 'report':
          return navigate({ to: reportingIndexRoute.to, params: { currentCompanyId } });
      }
    },
    [currentCompanyId, navigate],
  );

  return (
    <Layout.BasicHeader
      heading={t('pageHeader.dashboard')}
      icon="tachometer"
      headerDataTest="dashboard-page-header"
    >
      <Dashboard
        onItemClick={(type, item) => navigateToItem({ type, item })}
        onTeamManagementClick={() => navigate({ to: teamManagementRoute.to })}
        onGoToAllClick={navigateToItemIndex}
      />
    </Layout.BasicHeader>
  );
};
