import { SVGProps } from 'react';
import * as React from 'react';
import { Flex, Box, SxStyleProp } from 'rebass/styled-components';

import variantsMap from './SvgComponents';

type IllustrationVariantsType = keyof typeof variantsMap;

export const Illustration = ({
  variant,
  label,
  svgProps,
  labelSx = {},
}: {
  variant: IllustrationVariantsType;
  label?: React.ReactNode;
  labelSx?: SxStyleProp;
  svgProps?: SVGProps<SVGSVGElement>
}) => {
  const Component = variantsMap[variant];

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        width: '100%',
        height: '100%',
        textAlign: 'center',
      }}
    >
      <Component {...svgProps} />
      {label && (
        <Box
          sx={{
            mt: '20px',
            fontSize: 2,
            ...labelSx,
          }}
        >
          {label}
        </Box>
      )}
    </Flex>
  );
};
