import { Company, ExchangeType, FieldConfig, LineItemExchangeDefinition, Live, LotIntentionStatus, PageType, SectionType, StageResponseTag, getFieldIdsInDefaultDisplayOrder, getStageIdFromTag } from '@deepstream/common/rfq-utils';
import { compact, first, identity, intersection, isEmpty, last, some } from 'lodash';
import { useMemo, useRef, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { InlineButton } from '@deepstream/ui-kit/elements/button/InlineButton';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { ComparisonControlsPanel } from '../ComparisonControlsPanel';
import { LineItemsComparisonGrid, useRowData } from './LineItemsComparisonGrid';
import * as rfx from '../../../../rfx';
import { EmptyTableMessage } from '../../../../ui/EmptyTableMessage';
import { CurrencyConversionProvider } from '../../../../ui/Currency';
import { HideColumnsSelectDropdownMenu, SelectDropdownMenu } from '../../../../ui/MultiSelect';
import { ControlledRadioButtonGroup } from '../../../../ui/RadioButton';
import { EmptyGridPanel } from '../EmptyGridPanel';
import { useSelectView, View } from '../useSelectView';
import { UseExchangeModalStateProps, useExchangeModalState } from '../../../../useExchangeModalState';
import { useLineItemsGridState } from './useLineItemsGridState';
import { RecipientIdProvider, useRfqId } from '../../../../useRfq';
import { useExchangeRates } from '../../../../useExchangeRates';
import { useFilteredRowData } from '../useFilteredRowData';
import { EmptyGridMessagePanel } from '../EmptyGridMessagePanel';
import { useExchangeModalPage } from '../useExchangeModalPage';
import { RequestHooksProvider } from '../../RequestHooksProvider';
import { RfxExchangeModal } from '../../../../ExchangeModal/RfxExchangeModal';
import { ExchangeSnapshot } from '../../../../types';

const useFilteredColumnData = (
  selectedRequirementGroups: { value: string }[],
  selectedResponseTag: StageResponseTag | null,
): Company[] => {
  const structure = rfx.useStructure<Live>();
  const recipients = rfx.useRecipients();

  return useMemo(() => {
    const selectedRequirementGroup = first(selectedRequirementGroups)?.value;

    const filterByRequirementGroup = selectedRequirementGroup && selectedRequirementGroup !== 'general'
      ? recipients => recipients.filter(recipient => {
        return structure.bidById[recipient._id]?.intentionStatusByLotId[selectedRequirementGroup] === LotIntentionStatus.BIDDING;
      })
      : identity;

    const selectedStage = selectedResponseTag
      ? getStageIdFromTag(selectedResponseTag)
      : null;

    const filterByResponseTag = selectedStage
      ? recipients => recipients.filter(recipient => structure.bidById[recipient._id]?.activatedStageIds.includes(selectedStage))
      : identity;

    return filterByResponseTag(filterByRequirementGroup(recipients));
  }, [structure, recipients, selectedRequirementGroups, selectedResponseTag]);
};

const useRequestContainsHiddenRegularPage = () => {
  const structure = rfx.useStructure();
  const hiddenPageIds = rfx.useHiddenPageIds();

  const bidPageIds = structure.pages
    .filter(page => (
      page.type !== PageType.CHAT &&
      page.type !== PageType.EVALUATION
    ))
    .map(page => page._id);

  return !isEmpty(intersection(hiddenPageIds, bidPageIds));
};

export const NoLineItemsPanel = ({
  navigateToTeam,
}: {
  navigateToTeam: () => void;
}) => {
  const { t } = useTranslation();

  // Hidden sections are currently not part of live structures. Therefore,
  // we can't determine whether the request contains a line items section
  // that is hidden from the user.
  // Since we want to combine the comparison grids into a single grid,
  // this issue will soon disappear. For now, as an approximation, we
  // determine whether the request contains a hidden regular page (which
  // might or might not contain line items) and adjust the message for the
  // user accordingly.
  const requestContainsHiddenRegularPage = useRequestContainsHiddenRegularPage();

  return (
    <EmptyGridPanel>
      <PanelPadding>
        <EmptyTableMessage
          header={requestContainsHiddenRegularPage ? (
            <>
              <Icon icon="eye-slash" mr={2} />
              {t('request.comparison.cantViewContentHeader')}
            </>
          ) : (
            t('request.comparison.noLineItemsToShow')
          )}
          body={requestContainsHiddenRegularPage ? (
            <>
              <Box mb={1}>
                {t('request.comparison.cantViewContentBody1')}
              </Box>
              <Trans i18nKey="request.comparison.cantViewContentBody2">
                Permissions can be updated by an owner on
                the <InlineButton onClick={navigateToTeam}>Team</InlineButton> tab.
              </Trans>
            </>
          ) : (
            t('request.comparison.noLineItemsInRequest')
          )}
        />
      </PanelPadding>
    </EmptyGridPanel>
  );
};

/**
 * Renders a comparison grid with controls displaying the recipients, line item
 * exchange definitions and line item exchanges of a live request to buyers.
 */
export const LineItemsComparison = ({
  navigateToTeam,
  availableViews,
  selectView,
  isExpandedView,
  setIsExpandedView,
  updateCachedExchange,
}: {
  navigateToTeam: () => void;
  availableViews: {
    evaluation: boolean;
    'line-items': boolean;
    questions: boolean;
  };
  selectView: ReturnType<typeof useSelectView>;
  isExpandedView: boolean;
  setIsExpandedView: (isExpandedView: boolean) => void;
  updateCachedExchange?: (exchange: ExchangeSnapshot) => void;
}) => {
  const { t } = useTranslation();
  const rfqId = useRfqId();
  const structure = rfx.useStructure();
  const rowData = useRowData(structure, t);
  const [exchangeModalProps, setExchangeModalProps] =
    useState<UseExchangeModalStateProps>({});
  const exchangeModal = useExchangeModalState(exchangeModalProps);

  const exchangeModalPage = useExchangeModalPage(structure, exchangeModal?.exchangeId);

  const {
    nonObsoleteSectionIds,
    orderedFields,
    availableRequirementGroupIds,
  } = useMemo(() => {
    const orderedFields: FieldConfig[] = [];
    const nonObsoleteSectionIds: string[] = [];

    for (const page of structure.pages) {
      if (!page.type) {
        for (const sectionId of page.sections) {
          const section = structure.sectionById[sectionId];

          if (section?.type === SectionType.LINE_ITEMS) {
            for (const exchangeDefId of section.exchangeDefIds) {
              const exchangeDef = structure.exchangeDefById[exchangeDefId];

              if (exchangeDef?.type === ExchangeType.LINE_ITEM && !exchangeDef.isObsolete) {
                const orderedSectionFieldIds = (
                  exchangeDef.orderedFieldIds ||
                  getFieldIdsInDefaultDisplayOrder(Object.keys(exchangeDef.fields))
                );

                for (const sectionFieldId of orderedSectionFieldIds) {
                  const sectionField = exchangeDef.fields[sectionFieldId];

                  if (sectionField && !orderedFields.some(field => field._id === sectionField._id)) {
                    orderedFields.push(sectionField);
                  }
                }

                nonObsoleteSectionIds.push(section._id);
              }
            }
          }
        }
      }
    }

    return {
      nonObsoleteSectionIds,
      orderedFields,
      availableRequirementGroupIds: compact(rowData.map(row => row.lotId)),
    };
  }, [structure, rowData]);

  const gridState = useLineItemsGridState(rfqId, View.LINE_ITEMS, orderedFields, nonObsoleteSectionIds, availableRequirementGroupIds);

  const hasLineItemsSection = some(
    structure.sectionById,
    { type: SectionType.LINE_ITEMS },
  );

  const exchangeRates = useExchangeRates();

  const filteredRowData = useFilteredRowData(rowData, gridState.selectRequirementGroups.selectedItems);
  const filteredRecipientColumns = useFilteredColumnData(
    gridState.selectRequirementGroups.selectedItems,
    gridState.selectedResponseTag?.value,
  );

  const gridRef = useRef<any>();
  useEffect(() => {
    gridRef.current?.resetAfterRowIndex(0);
    gridRef.current?.resetAfterColumnIndex(0);
  }, [structure, gridState.selectedVisibleFieldsetIds, filteredRowData, filteredRecipientColumns]);

  const disabled = !hasLineItemsSection || isEmpty(rowData);

  return (
    <>
      <ComparisonControlsPanel
        availableViews={availableViews}
        selectView={selectView}
        selectRowHeight={gridState.selectRowHeight}
        isExpandedView={isExpandedView}
        setIsExpandedView={setIsExpandedView}
        disabled={disabled}
        selectRequirementGroups={gridState.selectRequirementGroups}
        additionalHeaderContentLeft={
          <>
            {isEmpty(gridState.selectResponseTags.items) ? (
              null
            ) : (
              <Box padding={1}>
                <SelectDropdownMenu
                  buttonIcon="list-ol"
                  menuWidth={180}
                  menuZIndex={151}
                  getButtonText={items => last(items)?.shortLabel ?? t('general.stage', { count: 1 })}
                  disabled={disabled}
                  {...gridState.selectResponseTags}
                />
              </Box>
            )}
            <Box padding={1}>
              {/*
               // @ts-expect-error ts(2322) FIXME: Type '{ itemToString: (item: SelectCurrencyItem | null) => string; items: ({ value: null; label: string; } | { value: string; label: string; })[]; selectedItems: SelectCurrencyItem[]; ... 5 more ...; disabled: boolean; }' is not assignable to type 'Omit<MultiSelectProps<SelectCurrencyItem>, "onChange">'. */}
              <SelectDropdownMenu
                buttonIcon="money-bill-alt"
                menuWidth={180}
                menuZIndex={151}
                getButtonText={items => last(items)?.value ?? t('request.lineItems.currency.sourceCurrency')}
                disabled={disabled}
                {...gridState.selectCurrency}
              />
            </Box>
            {isEmpty(gridState.selectResponseTags.items) ? (
              null
            ) : (
              <Flex as="fieldset" padding={1} ml="24px" alignItems="center">
                <Text as="label" sx={{ fontSize: '1', fontWeight: 500, color: 'text' }} mr={2}>
                  {t('general.savings')}
                </Text>
                <ControlledRadioButtonGroup
                  boxStyle={{ width: '30px' }}
                  renderLabel={(input) => (
                    <Icon fontWeight={400} icon={input.icon!} title={input.label} />
                  )}
                  {...gridState.selectSavingsDisplayMode}
                />
              </Flex>
            )}
          </>
        }
        additionalHeaderContentRight={isEmpty(gridState.selectVisibleFields.items) ? (
          null
        ) : (
          <Box mr={2}>
            <HideColumnsSelectDropdownMenu
              buttonText={t('request.comparison.hideFields')}
              menuWidth={266}
              menuZIndex={151}
              disabled={disabled}
              {...gridState.selectVisibleFields}
            />
          </Box>
        )}
      />
      {!hasLineItemsSection ? (
        <NoLineItemsPanel navigateToTeam={navigateToTeam} />
      ) : isEmpty(rowData) ? (
        <EmptyGridMessagePanel
          header={t('request.comparison.noLineItemsToShow')}
          body={t('request.comparison.allLineItemsObsolete')}
        />
      ) : isEmpty(filteredRowData) ? (
        <EmptyGridMessagePanel
          header={gridState.selectedResponseTag?.value ? (
            t('request.comparison.noLineItemsToShowForLotAndStage')
          ) : (
            t('request.comparison.noLineItemsToShowForLot')
          )}
        />
      ) : (
        <CurrencyConversionProvider targetCurrency={gridState.selectedCurrency} exchangeRates={exchangeRates}>
          <LineItemsComparisonGrid
            gridRef={gridRef}
            rowData={filteredRowData}
            recipientColumns={filteredRecipientColumns}
            orderedFields={orderedFields}
            subRowHeight={gridState.subRowHeight}
            selectedVisibleFieldsetIds={gridState.selectedVisibleFieldsetIds}
            collapsedRowIds={gridState.collapsedRowIds}
            setCollapsedRowIds={gridState.setCollapsedRowIds}
            setExchangeModalProps={setExchangeModalProps}
            selectedResponseTag={gridState.selectedResponseTag?.value}
            responseTags={gridState.responseTags}
            savingsDisplayMode={gridState.selectSavingsDisplayMode.value}
          />
        </CurrencyConversionProvider>
      )}
      {exchangeModalPage && exchangeModal.recipientId && (
        <RecipientIdProvider recipientId={exchangeModal.recipientId}>
          <rfx.PageProvider page={exchangeModalPage}>
            <RequestHooksProvider>
              <RfxExchangeModal
                {...exchangeModal}
                showRecipient
                close={() => setExchangeModalProps({})}
                onUpdate={updateCachedExchange}
              />
            </RequestHooksProvider>
          </rfx.PageProvider>
        </RecipientIdProvider>
      )}
    </>
  );
};
