import { uniqBy } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  isDefinitionField,
  isReplyField, isFormulaField,
  FieldConfig,
} from '@deepstream/common/rfq-utils';
import { Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { useLocalStorageFilterProps } from '../../../../filtering';
import { renderLabel } from '../../../../RequestsTable';

const ignoredFieldIds = [
  'description',
  'quantity',
  'unit',
  'evaluatorFieldCurrency',
];

export const Heading = styled(Text)`
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: ${props => props.theme.fontSizes[1]}px;
`;

export const useSelectVisibleFields = ({
  storageKey,
  orderedFields,
}: {
  storageKey: string;
  orderedFields: FieldConfig[],
}) => {
  const { t } = useTranslation();

  const items = useMemo(() => {
    const pairs = orderedFields
      .filter(field => {
        return (
          !ignoredFieldIds.includes(field._id) &&
          (isDefinitionField(field) || isReplyField(field) || isFormulaField(field))
        );
      })
      .map(field => [field._id.split(':')[0], field]);

    return uniqBy(pairs, entry => entry[0])
      .map(([fieldUuid, field]) => ({
        _id: fieldUuid,
        label: (field as unknown as { label: string }).label || t(`request.fields.predefinedFieldLabel.${fieldUuid}`),
      }));
  }, [orderedFields, t]) as { _id: string, label: string }[];

  return useLocalStorageFilterProps({
    storageKey,
    items,
    idProp: '_id',
    renderItem: renderLabel,
    // All items are initially selected
    defaultValue: items,
  });
};
