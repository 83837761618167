import { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';
import { Text, Flex, Box } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { AuctionStatus, BidStatus, Live, StageType } from '@deepstream/common/rfq-utils';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { callAll } from '@deepstream/utils/callAll';
import { Button, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useRouter } from '@tanstack/react-router';
import { useApi } from './api';
import { useCurrentCompanyId } from './currentCompanyId';
import { DeadlineCountdown } from './DeadlineCountdown';
import { useDeadline } from './deadline';
import { useModalState } from './ui/useModalState';
import { useMutation } from './useMutation';
import { useToaster } from './toast';
import { TextField } from './form/TextField';
import {
  useLiveRfqStructure,
  RfqIdProvider,
  useRfqId,
  useRecipientId,
  RecipientIdProvider,
  useLiveRfqStructureQueryKey,
} from './useRfq';
import { HelpCenterLink } from './HelpCenterLink';
import * as rfx from './rfx';
import { useWaitForRfqUnlock } from './useWaitForUnlock';

export const useRequestData = () => {
  const rfqId = useRfqId({ required: true });
  const recipientId = useRecipientId({ required: true });
  const bid = rfx.useBid();
  const isBidWithdrawn = bid.status === BidStatus.WITHDRAWN;

  return useMemo(
    () => ({
      rfqId,
      recipientId,
      stageId: bid.stageId!,
      isBidWithdrawn,
    }),
    [rfqId, recipientId, bid, isBidWithdrawn],
  );
};

const useMutationOptions = (onSuccess?: () => void) => {
  const router = useRouter();
  const { t } = useTranslation();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { rfqId, recipientId } = useRequestData();
  const liveStructureQueryKey = useLiveRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
    recipientId,
  });

  return useMemo(
    () => ({
      onSuccess: callAll(
        () => toaster.success(t('request.intention.toaster.bidUpdatedSuccess')),
        async () => {
          await Promise.all([
            queryClient.invalidateQueries(liveStructureQueryKey),
            queryClient.invalidateQueries(['exchanges', { rfqId }]),
          ]);

          if (onSuccess) {
            onSuccess();
          } else {
            await router.invalidate();
          }
        },
      ),
      onError: () => toaster.error(t('request.intention.toaster.bidUpdatedError')),
    }),
    [toaster, t, queryClient, liveStructureQueryKey, rfqId, onSuccess, router],
  );
};

const useWithdrawBid = (onSuccess?: () => void) => {
  const api = useApi();
  const { rfqId, recipientId, stageId } = useRequestData();
  const waitForRfqUnlock = useWaitForRfqUnlock();

  return useMutation(
    ({ reason }: any) => waitForRfqUnlock({
      command: () => api.withdrawBid({
        rfqId,
        recipientId,
        stageId,
        reason,
      }),
    }),
    useMutationOptions(onSuccess),
  );
};

export const useSendIntention = (onSuccess?: () => void) => {
  const api = useApi();
  const { rfqId, recipientId, stageId, isBidWithdrawn } = useRequestData();
  const waitForRfqUnlock = useWaitForRfqUnlock();

  return useMutation(
    ({ intention, reason }: any) => waitForRfqUnlock({
      command: () => api.sendIntention({
        rfqId,
        recipientId,
        stageId,
        intention,
        reason,
        isBidWithdrawn,
      }),
    }),
    useMutationOptions(onSuccess),
  );
};

const useReopenBid = (onSuccess?: () => void) => {
  const api = useApi();
  const { rfqId, recipientId, stageId } = useRequestData();
  const waitForRfqUnlock = useWaitForRfqUnlock();

  return useMutation(
    () => waitForRfqUnlock({
      command: () => api.reopenBid({
        rfqId,
        recipientId,
        stageId,
      }),
    }),
    useMutationOptions(onSuccess),
  );
};

const Heading = styled(Text)`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
`;

const SubText = styled(Text)`
  color: ${props => props.theme.colors.subtext};
`;

interface IntentionToBidModalProps {
  isOpen: boolean;
  enteredStageNumber: number;
  activatedStageNumber: number;
  isBidWithdrawn: boolean;
  hasWithdrawnInCurrentStage?: boolean;
  onSendIntentionSuccess?: () => void;
  close: () => void;
}

export const IntentionToBidModal = ({
  isOpen,
  enteredStageNumber,
  activatedStageNumber,
  isBidWithdrawn,
  hasWithdrawnInCurrentStage,
  onSendIntentionSuccess,
  close,
}: IntentionToBidModalProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const numUnconfirmedStages = enteredStageNumber - activatedStageNumber;
  const hasIntentionToBid = activatedStageNumber > 0;

  const [sendIntention] = useSendIntention(onSendIntentionSuccess);
  const [reopenBid] = useReopenBid();

  return (
    <Modal
      shouldCloseOnEsc
      isOpen={isOpen}
      onRequestClose={close}
      style={{ content: { maxWidth: '500px' } }}
    >
      <ModalHeader onClose={close}>
        {!hasIntentionToBid ? (
          t('request.intention.confirmYouIntendToBid')
        ) : isBidWithdrawn && !hasWithdrawnInCurrentStage ? (
          t('request.intention.reopenAndContinue', { stageNumber: enteredStageNumber })
        ) : numUnconfirmedStages > 0 ? (
          t('request.intention.confirmYouWantToContinue', { stageNumber: enteredStageNumber })
        ) : (
          t('request.intention.confirmYouWantToReopen')
        )}
      </ModalHeader>
      <ModalBody>
        {!hasIntentionToBid ? (
          <Stack as="ul" pl={3} gap={2}>
            {numUnconfirmedStages > 1 && (
              <Text as="li">
                {t('request.intention.confirmationDisclosure1', { stageNumber: enteredStageNumber })}
              </Text>
            )}
            <Text as="li">
              {t('request.intention.confirmationDisclosure2')}
            </Text>
            <Text as="li">
              {t('request.intention.confirmationDisclosure3')}
            </Text>
          </Stack>
        ) : isBidWithdrawn && !hasWithdrawnInCurrentStage ? (
          <MessageBlock variant="info" mt={0}>
            {t('request.intention.youHaveBeenMoved', { stageNumber: enteredStageNumber })}
          </MessageBlock>
        ) : (
          <MessageBlock variant="info" mt={0}>
            {t('request.intention.youCanWithdrawLater')}
          </MessageBlock>
        )}
      </ModalBody>
      <ModalFooter>
        {!hasIntentionToBid && (
          <HelpCenterLink
            text={t('general.moreInformation')}
            path="/en/articles/6341808-responding-to-a-request"
          />
        )}
        <Flex>
          <CancelButton type="button" onClick={close} mr={2} />
          <Button
            disabled={isLoading}
            onClick={() => {
              setIsLoading(true);

              if (isBidWithdrawn && numUnconfirmedStages === 0) {
                reopenBid(
                  undefined,
                  {
                    onSuccess: close,
                    onSettled: () => setIsLoading(false),
                  },
                );
              } else {
                sendIntention({
                  intention: true,
                }, {
                  onSuccess: close,
                  onSettled: () => setIsLoading(false),
                });
              }
            }}
          >
            {t('general.confirm')}
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

interface NoIntentionToBidModalProps {
  isOpen: boolean;
  hasIntentionToBid: boolean;
  close?: () => void;
}

export const NoIntentionToBidModal = ({
  isOpen,
  hasIntentionToBid,
  close,
}: NoIntentionToBidModalProps) => {
  const { t } = useTranslation();
  const rfxStructure = rfx.useStructure();

  const [withdrawBid] = useWithdrawBid();
  const [sendIntention] = useSendIntention();

  return (
    <Modal
      shouldCloseOnEsc
      isOpen={isOpen}
      onRequestClose={close}
      style={{ content: { maxWidth: '500px' } }}
    >
      <Formik<{ reason: string }>
        validateOnBlur
        enableReinitialize
        initialValues={{
          reason: '',
        }}
        validationSchema={
          yup.object().shape({
            reason: yup.string().required(t('request.intention.errors.reasonRequired')),
          })
        }
        onSubmit={({ reason }, { setSubmitting }) => {
          if (hasIntentionToBid) {
            withdrawBid({
              reason,
            }, {
              onSettled: () => setSubmitting(false),
              onSuccess: close,
            });
          } else {
            sendIntention({
              intention: false,
              reason,
            }, {
              onSettled: () => setSubmitting(false),
              onSuccess: close,
            });
          }
        }}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form>
            <ModalHeader onClose={close}>
              {t(hasIntentionToBid ? 'request.intention.confirmBidWithdrawal' : 'request.intention.confirmYouDeclineToBid')}
            </ModalHeader>
            <ModalBody>
              <Text mb={3}>
                {t(
                  hasIntentionToBid ? 'request.intention.withdrawReasonLabel' : 'request.intention.declineReasonLabel',
                  { companyName: rfxStructure.senders[0].company.name },
                )}
              </Text>
              <Box mb={2}>
                <TextField
                  name="reason"
                  label={t('request.intention.reason')}
                  required
                  isMultiLine
                  rows={4}
                />
              </Box>
              <MessageBlock variant="info">
                {t(hasIntentionToBid ? 'request.intention.withdrawBidInfo' : 'request.intention.declineToBidInfo')}
              </MessageBlock>
            </ModalBody>
            <ModalFooter>
              <CancelButton type="button" onClick={close} mr={2} />
              <Button disabled={isSubmitting || !dirty || !isValid}>
                {t('general.confirm')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const BidIntentionPanelContent = ({ onSendIntentionSuccess }: { onSendIntentionSuccess?: () => void }) => {
  const { t } = useTranslation();
  const { stages, auction } = rfx.useStructure<Live>();
  const intentionToBidModal = useModalState();
  const noIntentionToBidModal = useModalState();
  const rfxBid = rfx.useBid();
  const currentStageId = rfxBid.stageId;
  const currentStage = stages.find(stage => stage._id === currentStageId);
  const stageDeadline = rfx.useStageDeadline(currentStageId);

  const noResponse = rfxBid.status === BidStatus.NO_RESPONSE;
  const hasNoIntentionToBid = rfxBid.status === BidStatus.NO_INTEND_TO_BID;
  const isBidWithdrawn = rfxBid.status === BidStatus.WITHDRAWN;
  const hasIntentionToBid = Boolean(rfxBid.activatedStageIds.length);
  const enteredStageNumber = rfxBid.enteredStageIds.length;
  const activatedStageNumber = rfxBid.activatedStageIds.length;
  const hasWithdrawnInCurrentStage = rfxBid && isBidWithdrawn && rfxBid.outcome?.stageId === rfxBid.stageId;

  const isCancelledAuctionStage = (
    currentStage?.type === StageType.AUCTION &&
    auction?.status === AuctionStatus.CANCELLED
  );

  const auctionPauseDate = rfx.useAuctionPauseDate(currentStage?._id);

  const deadline = useDeadline({
    // @ts-expect-error ts(2322) FIXME: Type 'Date | null' is not assignable to type 'Date | undefined'.
    deadline: stageDeadline,
    referenceDate: auctionPauseDate,
  });

  if (!rfxBid) {
    return null;
  }

  return (
    <>
      <Panel
        mb="20px"
        py={3}
        px="20px"
        sx={{
          boxShadow: '0px 8px 16px rgba(8, 35, 48, 0.2)',
          position: 'sticky',
          zIndex: 1,
        }}
      >
        <Flex
          alignItems={['flex-start', null, 'center']}
          flexDirection={['column', null, 'row']}
        >
          <Box flex={1} mb={[3, null, 0]} mr={[0, null, '30px']}>
            {noResponse ? (
              <>
                <Heading>
                  {t(deadline.hasPassed ? 'request.intention.youDidNotRespond' : 'request.intention.doYouIntendToBid')}
                </Heading>
                {!deadline.hasPassed && (
                  <SubText>
                    {t('request.intention.respondBeforeDeadline')}
                  </SubText>
                )}
              </>
            ) : hasNoIntentionToBid ? (
              <>
                <Heading>
                  {t('request.intention.youDeclined')}
                </Heading>
                {!deadline.hasPassed && (
                  <SubText>
                    {t('request.intention.youCanStillSendIntention')}
                  </SubText>
                )}
              </>
            ) : isBidWithdrawn ? (
              <>
                <Heading>
                  {t('request.intention.youWithdrewYourBid')}
                </Heading>
                {!deadline.hasPassed && (
                  <SubText>
                    {t('request.intention.youCanStillReopen')}
                  </SubText>
                )}
              </>
            ) : enteredStageNumber !== activatedStageNumber ? (
              <>
                <Heading>
                  {deadline.hasPassed ? (
                    t('request.intention.youDidNotRespondToContinue')
                  ) : (
                    t('request.intention.doYouContinue', { stageNumber: enteredStageNumber })
                  )}
                </Heading>
                {!deadline.hasPassed && (
                  <SubText>
                    {t('request.intention.respondBeforeDeadline')}
                  </SubText>
                )}
              </>
            ) : (
              null
            )}
          </Box>
          <Box
            mb={[deadline.hasPassed ? 0 : 3, null, 0]}
            mr={[0, null, deadline.hasPassed ? '20px' : '30px']}
          >
            <Text fontSize={1} fontWeight={500} lineHeight={1.5}>
              {t('general.deadline')}
            </Text>
            {stageDeadline ? (
              <DeadlineCountdown
                deadline={stageDeadline}
                isDeadlineAvailable={!isCancelledAuctionStage}
                referenceDate={auctionPauseDate}
                fontSize={2}
                iconStyle={{ fontSize: 2 }}
              />
            ) : (
              null
            )}
          </Box>
          {deadline.hasPassed ? (
            null
          ) : (
            <Box>
              {noResponse ? (
                <>
                  <Button variant="secondary" onClick={noIntentionToBidModal.open} mr={2}>
                    {t('request.intention.noDeclineToBid')}
                  </Button>
                  <Button onClick={intentionToBidModal.open}>
                    {t('request.intention.yesSendIntention')}
                  </Button>
                </>
              ) : hasNoIntentionToBid ? (
                <Button onClick={intentionToBidModal.open}>
                  {t('request.intention.sendIntention')}
                </Button>
              ) : isBidWithdrawn ? (
                <Button onClick={intentionToBidModal.open}>
                  {t('request.intention.reopenBid')}
                </Button>
              ) : enteredStageNumber !== activatedStageNumber ? (
                <>
                  <Button variant="secondary" onClick={noIntentionToBidModal.open} mr={2}>
                    {t('request.intention.noWithdrawBid')}
                  </Button>
                  <Button onClick={intentionToBidModal.open}>
                    {t('request.intention.yesContinueToStage', { stageNumber: enteredStageNumber })}
                  </Button>
                </>
              ) : (
                null
              )}
            </Box>
          )}
        </Flex>
      </Panel>
      <IntentionToBidModal
        isOpen={intentionToBidModal.isOpen}
        enteredStageNumber={enteredStageNumber!}
        activatedStageNumber={activatedStageNumber!}
        isBidWithdrawn={isBidWithdrawn}
        hasWithdrawnInCurrentStage={hasWithdrawnInCurrentStage}
        onSendIntentionSuccess={onSendIntentionSuccess}
        close={intentionToBidModal.close}
      />
      <NoIntentionToBidModal
        isOpen={noIntentionToBidModal.isOpen}
        hasIntentionToBid={hasIntentionToBid}
        close={noIntentionToBidModal.close}
      />
    </>
  );
};

interface BidIntentionPanelProps {
  rfqId: string;
  recipientId: string;
  onSendIntentionSuccess?: () => void;
}

export const BidIntentionPanel = ({
  rfqId,
  recipientId,
  onSendIntentionSuccess,
}: BidIntentionPanelProps) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { data: rfxStructure, isLoading } = useLiveRfqStructure({
    rfqId,
    recipientId,
    currentCompanyId,
  });

  return (
    <RfqIdProvider rfqId={rfqId}>
      <RecipientIdProvider recipientId={recipientId}>
        {isLoading ? (
          null
        ) : rfxStructure ? (
          <rfx.StructureProvider structure={rfxStructure}>
            <BidIntentionPanelContent onSendIntentionSuccess={onSendIntentionSuccess} />
          </rfx.StructureProvider>
        ) : (
          null
        )}
      </RecipientIdProvider>
    </RfqIdProvider>
  );
};
