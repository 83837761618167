import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';
import { Box } from 'rebass/styled-components';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { DropdownMenu, DropdownMenuItem, DropdownMenuDivider } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { callAll } from '@deepstream/utils/callAll';
import { LegacyContractsBulkImport } from './Legacy';
import { contractDraftSummaryRoute, contractsRoute } from '../../AppRouting';
import { useSystemFeatureFlags } from '../../systemFeatureFlags';
import { useCompanyFeatureFlags } from '../../companyFeatureFlags';
import { useApi } from '../../api';
import { useUserFlags } from '../../UserFlagsContext';
import { useMutation } from '../../useMutation';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useToaster } from '../../toast';
import { useModalState } from '../../ui/useModalState';
import { UpgradeToUnlockContractsDialog } from './UpgradeToUnlockContractsDialog';
import { CreateFromRequestModal } from './CreateFromRequestModal';

export const CreateContractButton = () => {
  const { t } = useTranslation(['contracts']);
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const navigate = useNavigate();
  const api = useApi();
  const queryClient = useQueryClient();
  const companyFeatureFlags = useCompanyFeatureFlags();
  const systemFeatureFlags = useSystemFeatureFlags();
  const { hasSendContractPermission } = useUserFlags();
  const toaster = useToaster();
  const createFromRequestModal = useModalState();
  const upgradeDialog = useModalState();
  const legacyBulkImportModal = useModalState();

  const [createEmptyContract] = useMutation(
    api.createEmptyContract,
    {
      onSettled: callAll(
        () => queryClient.invalidateQueries(['draftContracts', { currentCompanyId }]),
        () => queryClient.invalidateQueries(['draftContractFilterItems', { currentCompanyId }]),
      ),
      onSuccess: callAll(
        (contract) => navigate({
          to: contractDraftSummaryRoute.to,
          params: { currentCompanyId, contractId: contract._id },
        }),
        () => toaster.success(t('toaster.contractCreated.success')),
      ),
      onError: () => toaster.error(t('toaster.contractCreated.failed')),
    },
  );

  const navigateToContractTemplates = useCallback(
    () => navigate({
      to: contractsRoute.to,
      params: { currentCompanyId },
      search: { tab: 'templates' },
    }),
    [currentCompanyId, navigate],
  );

  return !hasSendContractPermission ? (
    null
  ) : companyFeatureFlags.contractManagementEnabled ? (
    <Box m={1} ml="20px">
      <DropdownMenu
        rightAligned
        small
        variant="primary"
        buttonText={t('newContract')}
        iconLeft="plus"
        iconRight="caret-down"
        menuZIndex={102}
      >
        <DropdownMenuItem
          icon="file-o"
          onSelect={() => createEmptyContract({ companyId: currentCompanyId })}
        >
          {t('blankContract')}
        </DropdownMenuItem>
        <DropdownMenuItem
          icon="trophy"
          onSelect={createFromRequestModal.open}
        >
          {t('fromAwardedRequest')}
        </DropdownMenuItem>
        {/*
         // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'. */}
        {systemFeatureFlags.contractTemplatesEnabled && (
          <DropdownMenuItem
            icon="files-o"
            onSelect={navigateToContractTemplates}
          >
            {t('fromContractTemplate')}
          </DropdownMenuItem>
        )}
        <DropdownMenuDivider />
        <DropdownMenuItem icon="upload" onSelect={legacyBulkImportModal.open}>
          {t('legacy.uploadTriggerLabel')}
        </DropdownMenuItem>
      </DropdownMenu>
      {createFromRequestModal.isOpen && (
        <CreateFromRequestModal
          isOpen={createFromRequestModal.isOpen}
          heading={t('selectPreviouslyAwardedRequest')}
          onCancel={createFromRequestModal.close}
          navigateToSenderDraftContract={contractId => {
            navigate({
              to: contractDraftSummaryRoute.to,
              params: { currentCompanyId, contractId },
            });
          }}
        />
      )}
      {legacyBulkImportModal.isOpen && (
        <LegacyContractsBulkImport onClose={legacyBulkImportModal.close} />
      )}
    </Box>
  ) : (
    <Box m={1}>
      <Button
        small
        variant="primary"
        iconLeft="lock"
        onClick={upgradeDialog.open}
      >
        {t('newContract')}
      </Button>
      <UpgradeToUnlockContractsDialog
        isOpen={upgradeDialog.isOpen}
        onCancel={upgradeDialog.close}
      />
    </Box>
  );
};
