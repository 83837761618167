import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Form, Formik, FormikProps } from 'formik';
import { AutoExtensionTrigger, ChangeType, Draft, SectionType, RfxAuctionLineItemsSection } from '@deepstream/common/rfq-utils';
import { find, isNumber, pick } from 'lodash';
import * as yup from 'yup';
import { localeFormatNumber } from '@deepstream/utils';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { callAll } from '@deepstream/utils/callAll';
import { CancelButton, EditButton, SaveButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelHeader, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import * as rfx from '../rfx';
import { SwitchField } from '../form/SwitchField';
import { FieldContainer } from '../form/FieldContainer';
import { SelectField } from '../form/SelectField';
import { MinutesStepperField } from '../form/MinutesStepperField';
import { Bold } from '../Bold';
import { AuctionConfigLabelConfigProvider } from './AuctionConfigLabelConfigProvider';
import { auctionLineItemSectionKeys } from './draft';
import { useCurrentUserLocale } from '../useCurrentUser';
import { LeavePageModal } from './LeavePageModal';
import { AuctionTabId } from './AuctionTabId';

const panelId = AuctionTabId.TIMING_RULES;

const fieldNames = ['duration', 'autoExtension', 'preBids'];

const useAutoExtensionTriggerOptions = () => {
  const { t } = useTranslation();

  return useMemo(() => Object.values(AutoExtensionTrigger).map(trigger => ({
    value: trigger,
    label: t(`request.auction.timingRules.autoExtension.trigger.${trigger}`),
  })), [t]);
};

const AuctionConfigTimingRulesView = () => {
  const { t } = useTranslation();
  const locale = useCurrentUserLocale();
  const pagePermissions = rfx.usePagePermissions();
  const { startEditing } = rfx.useActions();
  const isAuctionEditable = rfx.useIsAuctionEditable();
  const section = rfx.useSection<RfxAuctionLineItemsSection<Draft>>();

  const { auctionRules } = section;

  return (
    <AuctionConfigLabelConfigProvider fieldNames={fieldNames}>
      <PanelHeader
        heading={t('request.auction.sectionTitle.timingRules')}
        description={t('request.auction.sectionDescription.timingRules')}
        py={1}
      >
        {(pagePermissions.canEdit && isAuctionEditable) && (
          <EditButton
            small
            onClick={() => startEditing(panelId)}
          />
        )}
      </PanelHeader>
      <PanelDivider />
      <PanelPadding>
        <FieldContainer
          label={t('request.auction.timingRules.duration.label')}
          description={t('request.auction.timingRules.duration.description')}
          name="duration"
        >
          {isNumber(auctionRules.duration) ? (
            `${localeFormatNumber(auctionRules.duration, { locale })}\u2006${t('general.minuteShort', { count: auctionRules.duration })}`
          ) : (
            <EmDash />
          )}
        </FieldContainer>
      </PanelPadding>
      <PanelDivider />
      <PanelPadding>
        <FieldContainer
          name="autoExtension"
          label={t('request.auction.timingRules.autoExtension.label')}
          description={t('request.auction.timingRules.autoExtension.description')}
        >
          <Flex flexDirection="column">
            <Text>
              {auctionRules.autoExtension ? (
                t('general.enabled')
              ) : (
                t('general.disabled')
              )}
            </Text>
            {auctionRules.autoExtension && (
              <Flex flexDirection="row" flexWrap="wrap" alignItems="center" mt="12px">
                {isNumber(auctionRules.autoExtension.timeToRespond) ? (
                  <Text>
                    <Bold>
                      {`${localeFormatNumber(auctionRules.autoExtension.timeToRespond, { locale })}\u2006${t('general.minuteShort', { count: auctionRules.autoExtension.timeToRespond })}`}
                    </Bold>
                    {' '}
                    {t('request.auction.timingRules.autoExtension.linkText1')}
                    {' '}
                    {t('request.auction.timingRules.autoExtension.linkText2')}
                    {' '}
                    <Bold>
                      {t(`request.auction.timingRules.autoExtension.trigger.${auctionRules.autoExtension.trigger}`)}
                    </Bold>
                    {' '}
                    {t('request.auction.timingRules.autoExtension.linkText3')}
                  </Text>
                ) : (
                  <EmDash />
                )}
              </Flex>
            )}
          </Flex>
        </FieldContainer>
      </PanelPadding>
      <PanelDivider />
      <PanelPadding>
        <FieldContainer
          name="preBids"
          label={t('request.auction.timingRules.preBids.label')}
          description={t('request.auction.timingRules.preBids.description')}
        >
          {auctionRules.preBids ? (
            t('general.enabled')
          ) : (
            t('general.disabled')
          )}
        </FieldContainer>
      </PanelPadding>
    </AuctionConfigLabelConfigProvider>
  );
};

const AuctionConfigTimingRulesInnerForm = ({
  isSubmitting,
  isValid,
  dirty,
  resetForm,
  values,
  setFieldValue,
}: FormikProps<{
  duration: number | null;
  autoExtension: {
    trigger: AutoExtensionTrigger;
    timeToRespond: number | null;
  } | null;
  preBids: boolean;
}>) => {
  const { t } = useTranslation();
  const autoExtensionDescriptionId = useUniqueId();
  const autoExtensionTriggerOptions = useAutoExtensionTriggerOptions();
  const { stopEditing } = rfx.useActions();
  rfx.useFormResetOnAuctionStart();

  return (
    <AuctionConfigLabelConfigProvider fieldNames={fieldNames}>
      <Form style={{ width: '100%' }}>
        <PanelHeader
          heading={t('request.auction.sectionTitle.timingRules')}
          description={t('request.auction.sectionDescription.timingRules')}
          py={1}
        />
        <PanelDivider />
        <PanelPadding>
          <MinutesStepperField
            name="duration"
            label={t('request.auction.timingRules.duration.label')}
            description={t('request.auction.timingRules.duration.description')}
            increment={5}
          />
        </PanelPadding>
        <PanelDivider />
        <PanelPadding>
          <FieldContainer
            label={t('request.auction.timingRules.autoExtension.label')}
            description={t('request.auction.timingRules.autoExtension.description')}
            name="autoExtension"
            descriptionId={autoExtensionDescriptionId}
          >
            <Flex flexDirection="column">
              <SwitchField
                name="autoExtension"
                hideLabel
                aria-label={`${t('request.auction.timingRules.autoExtension.label')} - ${t('general.enabled')}?`}
                aria-describedby={autoExtensionDescriptionId}
                useDefaultLabelConfig={false}
                checkedIcon={false}
                uncheckedIcon={false}
                checkedText={t('general.enabled')}
                uncheckedText={t('general.disabled')}
                width={42}
                onChange={(enabled: boolean) => setFieldValue('autoExtension', enabled ? { trigger: AutoExtensionTrigger.NEW_LEAD_BID, timeToRespond: null } : null)}
              />
            </Flex>
            {values.autoExtension && (
              <Flex flexDirection="row" flexWrap="wrap" alignItems="center">
                <Box mt={3} mr={2}>
                  <MinutesStepperField
                    name="autoExtension.timeToRespond"
                    hideLabel
                    aria-label={`${t('request.auction.timingRules.autoExtension.label')} - ${t('request.auction.timingRules.autoExtension.timeToRespond')}`}
                    aria-describedby={autoExtensionDescriptionId}
                    increment={1}
                  />
                </Box>
                <Text mt={3}>
                  {t('request.auction.timingRules.autoExtension.linkText1')}
                </Text>
                &nbsp;
                <Text mt={3} mr={2}>
                  {t('request.auction.timingRules.autoExtension.linkText2')}
                </Text>
                <Box width="120px" flexShrink={0} mr={2} mt={3}>
                  <SelectField
                    name="autoExtension.trigger"
                    items={autoExtensionTriggerOptions}
                    hideLabel
                    aria-label={`${t('request.auction.timingRules.autoExtension.label')} - ${t('request.auction.timingRules.autoExtension.triggerFieldLabel')}`}
                    aria-describedby={autoExtensionDescriptionId}
                    menuZIndex={10}
                  />
                </Box>
                <Text mt={3}>
                  {t('request.auction.timingRules.autoExtension.linkText3')}
                </Text>
              </Flex>
            )}
          </FieldContainer>
        </PanelPadding>
        <PanelDivider />
        <PanelPadding>
          <SwitchField
            name="preBids"
            label={t('request.auction.timingRules.preBids.label')}
            description={t('request.auction.timingRules.preBids.description')}
            useDefaultLabelConfig={false}
            checkedIcon={false}
            uncheckedIcon={false}
            checkedText={t('general.enabled')}
            uncheckedText={t('general.disabled')}
            width={42}
          />
        </PanelPadding>
        <PanelDivider />
        <PanelPadding>
          <Flex justifyContent="flex-end">
            <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
            <SaveButton disabled={isSubmitting || !dirty || !isValid} />
          </Flex>
        </PanelPadding>
        <LeavePageModal />
      </Form>
    </AuctionConfigLabelConfigProvider>
  );
};

const AuctionConfigTimingRulesForm = () => {
  const { t } = useTranslation();
  const { stopEditing } = rfx.useActions();
  const saveChanges = rfx.useSaveChanges();

  const section = rfx.useSection<RfxAuctionLineItemsSection<Draft>>();

  const { auctionRules } = section;

  return (
    <Formik<{
      duration: number | null;
      autoExtension: {
        trigger: AutoExtensionTrigger;
        timeToRespond: number | null;
      } | null;
      preBids: boolean;
    }>
      validateOnBlur
      enableReinitialize
      initialValues={{
        duration: auctionRules.duration,
        autoExtension: auctionRules.autoExtension,
        preBids: auctionRules.preBids,
      }}
      validationSchema={
        yup.object().shape({
          duration: yup.number().nullable().min(1, t('errors.min1')),
          autoExtension: yup.object().shape({
            trigger: yup.string().oneOf(Object.values(AutoExtensionTrigger)),
            timeToRespond: yup.number().nullable().min(1, t('errors.min1')),
          }).nullable(),
          preBids: yup.boolean(),
        })
      }
      onSubmit={({ duration, autoExtension, preBids }) => saveChanges({
        changes: [{
          type: ChangeType.SECTION_UPDATED,
          section: {
            ...pick(section, auctionLineItemSectionKeys),
            auctionRules: {
              ...section.auctionRules,
              duration,
              autoExtension,
              preBids,
            },
          },
        }],
      }, {
        onSuccess: stopEditing,
      })}
      component={AuctionConfigTimingRulesInnerForm}
    />
  );
};

export const AuctionConfigTimingRulesPanel = () => {
  const { sectionById } = rfx.useStructure<Draft>();

  const section = find(sectionById, { type: SectionType.AUCTION_LINE_ITEMS });

  const { editingPanelId } = rfx.useState();

  const isEditingThisPanel = editingPanelId === panelId;

  return section ? (
    <rfx.SectionProvider section={section}>
      <Panel>
        {isEditingThisPanel ? (
          <AuctionConfigTimingRulesForm />
        ) : (
          <AuctionConfigTimingRulesView />
        )}
      </Panel>
    </rfx.SectionProvider>
  ) : (
    null
  );
};
