import { useCallback, useMemo } from 'react';
import { find, findIndex } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import { BulletinExchangeDefinition } from '@deepstream/common/rfq-utils';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { withProps } from '@deepstream/ui-utils/withProps';
import { DateFormat, assertDefined } from '@deepstream/utils';
import { EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { AddBulletinModal } from './AddBulletinModal';
import { Disclosure2 } from '../../ui/Disclosure';
import { useModalState } from '../../ui/useModalState';
import { RfqIdProvider, useRfqId } from '../../useRfq';
import * as rfx from '../../rfx';
import { RequestMessagesGrid } from './RequestMessagesGrid';
import { Bold } from '../../Bold';
import { useExchangeNavigation } from '../../useExchangeModalState';
import { SortableHeader } from '../../ui/ExchangeDefsGrid/header';
import { BulletinMessageCell } from '../../ui/ExchangeDefsGrid/BulletinMessageCell';
import { DateOrDashCell, TextOrDashCell } from '../../ui/ExchangeDefsGrid/nonValidationAwareValueCell';
import { useBulletinSection } from './useBulletinSection';

const NewBulletinButton = (props) => {
  const { t } = useTranslation();

  return (
    <Button small iconLeft="plus" {...props}>
      {t('request.bulletin.newBulletin')}
    </Button>
  );
};

type BulletinsProps = {
  exchangeDefs?: BulletinExchangeDefinition[];
  sectionId: string;
};

export const Bulletins = ({ exchangeDefs, sectionId }: BulletinsProps) => {
  const { t } = useTranslation(['translation', 'general']);
  const newBulletinModal = useModalState();
  const rfqId = useRfqId();
  const pagePermissions = rfx.usePagePermissions({ required: true });
  const { openExchange } = useExchangeNavigation();
  const exchanges = rfx.useExchanges();
  const bulletinSection = useBulletinSection();

  const data = useMemo(
    () => {
      return exchangeDefs
        ? exchangeDefs.map((bulletin) => {
          const bulletinIndex = bulletinSection
            ? findIndex(
              bulletinSection.exchangeDefIds,
              exchangeDefId => exchangeDefId === bulletin._id,
            )
            : null;
          return { ...bulletin, bulletinIndex, rfqId };
        })
        : null;
    },
    [bulletinSection, exchangeDefs, rfqId],
  );

  const columns: EditableGridColumn<BulletinExchangeDefinition & { bulletinIndex: number, publishedAt: any }>[] = useMemo(
    () => ([
      {
        _id: 'bulletinNumber',
        accessorKey: 'bulletinIndex',
        accessorFn: (exchange) => exchange.bulletinIndex + 1,
        Header: SortableHeader,
        label: t('number_short', { ns: 'general' }),
        width: 68,
        ValueCell: TextOrDashCell,
      },
      {
        _id: 'message',
        accessorKey: 'message',
        Header: SortableHeader,
        label: t('message', { ns: 'general' }),
        width: 420,
        ValueCell: BulletinMessageCell,
      },
      {
        _id: 'postedBy',
        accessorKey: 'user.name',
        Header: SortableHeader,
        label: t('request.bulletin.postedBy'),
        width: 200,
        ValueCell: withProps(TextOrDashCell, { truncate: true }),
      },
      {
        _id: 'lastUpdated',
        accessorKey: 'publishedAt',
        accessorFn: (exchange) => new Date(exchange.publishedAt),
        Header: SortableHeader,
        label: t('request.bulletin.lastUpdate'),
        width: 207,
        ValueCell: withProps(DateOrDashCell, { dateFormat: DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ }),
      },
    ]),
    [t],
  );

  /**
   * We need to display the bulletin data in an exchange modal on both buyer/supplier sides.
   * For the buyer side, this is a bit tricky because we are rendering the list of bulletin exchange definitions
   * and clicking on one of them will open the exchange modal, where we actually need an exchange instance.
   */
  const openExchangeModal = useCallback((exchangeDef) => {
    const exchange = find(exchanges, exchange => exchange._id === exchangeDef._id);

    assertDefined(exchange, 'exchange');

    openExchange(exchange);
  }, [exchanges, openExchange]);

  return (
    <RfqIdProvider rfqId={rfqId}>
      <Panel>
        <PanelHeader heading={t('request.bulletin.bulletin_other')} collapse={false} icon="list-ul">
          <NewBulletinButton onClick={newBulletinModal.open} disabled={!pagePermissions.canEdit} />
        </PanelHeader>
        <PanelDivider />
        {!data ? (
          null
        ) : data.length ? (
          <PanelPadding>
            <GridIdPrefixProvider>
              <GridMenuStateProvider>
                <RequestMessagesGrid data={data} columns={columns} onRowClick={openExchangeModal} />
              </GridMenuStateProvider>
            </GridIdPrefixProvider>
          </PanelPadding>
        ) : (
          <Flex width="100%" height="200px" justifyContent="center" alignItems="center">
            <Bold color="darkGray2">
              {t('request.bulletin.noBulletins')}
            </Bold>
          </Flex>
        )}
      </Panel>
      <Text mt={3}>
        <Disclosure2
          width="100%"
          summary={t('request.bulletin.disclosure.heading')}
        >
          {t('request.bulletin.disclosure.body.sender')}
        </Disclosure2>
      </Text>
      <AddBulletinModal
        rfqId={rfqId}
        sectionId={sectionId}
        isOpen={newBulletinModal.isOpen}
        onClose={newBulletinModal.close}
      />
    </RfqIdProvider>
  );
};
