import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass/styled-components';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { callAll } from '@deepstream/utils/callAll';
import { CancelButton, EditButton, SaveButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { useIntercom } from 'react-use-intercom';
import { useApi } from '../../api';
import { useMutation } from '../../useMutation';
import { useDraftRfqStructureQueryKey, useRfqId } from '../../useRfq';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { RadioField } from '../../form/RadioField';
import * as rfx from '../../rfx';
import { LeavePageModal } from '../../draft/LeavePageModal';
import { useDeviceSize } from '../../ui/useDeviceSize';
import { RequestVisibility, RequestVisibilityBlock, VisibilityDescription, VisibilityLabel } from './RequestVisibilityBlock';
import { useCompanyFeatureFlags } from '../../companyFeatureFlags';
import { useModalState } from '../../ui/useModalState';
import { useToaster } from '../../toast';
import { useWaitForRfqUnlock } from '../../useWaitForUnlock';

export const requestVisibilityOptions = [
  {
    value: RequestVisibility.Private,
    label: <VisibilityLabel isPubliclyAvailable={false} />,
    description: <VisibilityDescription isPubliclyAvailable={false} />,
  },
  {
    value: RequestVisibility.Public,
    label: <VisibilityLabel isPubliclyAvailable={true} />,
    description: <VisibilityDescription isPubliclyAvailable={true} />,
  },
];

const panelId = 'requestVisibility';

export const RequestVisibilityEditPanel = ({
  onClickReviewEdit,
}: {
  onClickReviewEdit?: () => void;
}) => {
  const intercom = useIntercom();
  const { t } = useTranslation('translation');
  const toaster = useToaster();
  const theme = useTheme();
  const { isSmall } = useDeviceSize();

  const { startEditing, stopEditing } = rfx.useActions();
  const { isReview, isLive, isTemplate, isTemplatePreview, editingPanelId, isRevising } = rfx.useState();
  const rfxPermissions = rfx.useRfxPermissions();
  const structure = rfx.useStructure();

  const { settings: { isPubliclyAvailable } } = structure;
  const isEditingThisPanel = editingPanelId && editingPanelId === panelId;

  const api = useApi();
  const queryClient = useQueryClient();
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqQueryKey = useDraftRfqStructureQueryKey({ rfqId, currentCompanyId, isTemplate });
  const waitForRfqUnlock = useWaitForRfqUnlock();
  const [updateRequestSettings] = useMutation(
    (payload: any) => waitForRfqUnlock({
      command: () => api.updateRequestSettings(payload),
    }),
    {
      onSuccess: () => stopEditing(),
      onError: () => toaster.error(t('errors.unexpected')),
      onSettled: () => queryClient.invalidateQueries(rfqQueryKey),
    },
  );

  const isEditingAnyPanel = Boolean(editingPanelId);

  const publicRequestsInfoModal = useModalState();

  const { publicRequestsEnabled } = useCompanyFeatureFlags();
  const handleRequestVisibilityChange = useCallback((value: RequestVisibility) => {
    if (value === RequestVisibility.Public && !publicRequestsEnabled) {
      publicRequestsInfoModal.open();
    }
  }, [publicRequestsInfoModal, publicRequestsEnabled]);

  const handleInfoModalClose = useCallback(
    (sendIntercomEvent = false) => {
      if (sendIntercomEvent) {
        intercom.trackEvent('public-requests-modal-learn-more');
      }
      stopEditing();
      publicRequestsInfoModal.close();
    },
    [intercom, publicRequestsInfoModal, stopEditing],
  );

  const heading = t('visibility', { ns: 'general' });

  return (
    <>
      <Panel as="section" aria-label={heading} mb={20}>
        <PanelHeader
          heading={heading}
          description={t('request.visibility.panelDescription')}
        >
          {isLive || isEditingThisPanel || isTemplatePreview ? null : isReview ? (
            <EditButton
              small
              variant="primary"
              onClick={onClickReviewEdit}
              disabled={isEditingAnyPanel}
            />
          ) : rfxPermissions.canManageRequestVisibility ? (
            <EditButton
              small
              onClick={() => startEditing(panelId)}
              disabled={isEditingAnyPanel}
            />
          ) : null}
        </PanelHeader>
        <PanelDivider />
        {isEditingThisPanel ? (
          <Formik
            validateOnBlur
            enableReinitialize
            initialValues={{
              isPubliclyAvailable: isPubliclyAvailable
                ? RequestVisibility.Public
                : RequestVisibility.Private,
            }}
            validationSchema={yup.object().shape({
              isPubliclyAvailable: yup.string().required(),
            })}
            onSubmit={async ({ isPubliclyAvailable }) =>
              updateRequestSettings({
                rfqId,
                companyId: currentCompanyId,
                settings: {
                  isPubliclyAvailable:
                    isPubliclyAvailable === RequestVisibility.Public,
                },
                isTemplate,
                isRevising,
              })
            }
          >
            {({ isSubmitting, dirty, isValid, resetForm }) => (
              <Form>
                <Flex mx={15} mb={20} mt={20} sx={{ maxWidth: '640px' }}>
                  <RadioField
                    required
                    name="isPubliclyAvailable"
                    options={requestVisibilityOptions}
                    variant={isSmall ? 'stacked' : 'inline'}
                    gap="12px"
                    labelStyle={{
                      fontWeight: 'normal',
                      color: theme.colors.text,
                      whiteSpace: 'pre-wrap',
                      lineHeight: '15px',
                    }}
                    onChange={handleRequestVisibilityChange}
                  />
                </Flex>
                <PanelDivider />
                <PanelPadding>
                  <Flex justifyContent="space-between">
                    <IconText
                      icon="question-circle-o"
                      text={t('learnMore', { ns: 'general' })}
                      onClick={() =>
                        window.open(
                          'https://knowledge.deepstreamtech.com/en/articles/8420638-public-requests',
                          '_blank',
                        )
                      }
                      sx={{
                        cursor: 'pointer',
                        'span:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                      fontWeight={500}
                    />
                    <Box>
                      <CancelButton
                        onClick={callAll(resetForm, stopEditing)}
                        mr={2}
                      />
                      <SaveButton
                        disabled={isSubmitting || !dirty || !isValid}
                      />
                    </Box>
                  </Flex>
                </PanelPadding>
                <LeavePageModal />
              </Form>
            )}
          </Formik>
        ) : (
          <PanelPadding>
            <RequestVisibilityBlock isPubliclyAvailable={isPubliclyAvailable} />
          </PanelPadding>
        )}
      </Panel>
      <Dialog
        heading={t('request.visibility.enableFeatureDialog.heading')}
        body={t('request.visibility.enableFeatureDialog.body')}
        okButtonText={t('request.visibility.enableFeatureDialog.okButtonText')}
        cancelButtonText={t('request.visibility.enableFeatureDialog.cancelButtonText')}
        isOpen={publicRequestsInfoModal.isOpen}
        onOk={() => handleInfoModalClose(true)}
        onCancel={() => handleInfoModalClose()}
        showCloseIcon
        style={{ content: { maxWidth: '500px' } }}
      />
    </>
  );
};
