import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BidIntentionStatus,
} from '@deepstream/common/rfq-utils';
import { IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { iconPropsByBidIntentionStatus } from '../Live/iconPropsByBidIntentionStatus';

export type BidIntentionStatusFilter = {
  value: BidIntentionStatus;
  label: string;
  iconProps: {
    icon: IconProps['icon'];
    color: string;
    regular?: boolean;
  };
};

export const useBidIntentionStatusFilterItems = (): BidIntentionStatusFilter[] => {
  const { t } = useTranslation('translation');

  return useMemo(() => {
    return [
      BidIntentionStatus.BIDDING,
      BidIntentionStatus.NO_RESPONSE,
      BidIntentionStatus.BID_WITHDRAWN,
      BidIntentionStatus.DECLINED_TO_BID,
    ].map(bidIntentionStatus => ({
      value: bidIntentionStatus,
      label: t(`request.bidIntentionStatus.${bidIntentionStatus}.status`),
      iconProps: iconPropsByBidIntentionStatus.buyer[bidIntentionStatus],
    }));
  }, [t]);
};
