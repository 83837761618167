import { useTranslation } from 'react-i18next';
import { Draft, LineItemsSection, getStageIdFromTag } from '@deepstream/common/rfq-utils';
import * as rfx from '../rfx';
import { MultiStageBehavior } from './useMultiStageBehaviorSelectItems';

export const useMultiStageBehaviorDescription = (
  responseTagConfig: LineItemsSection['responseTagConfig'] | undefined,
  stageId: string,
) => {
  const structure = rfx.useStructure<Draft>();
  const { t } = useTranslation('translation');
  const stages = rfx.useStages();

  if (responseTagConfig) {
    const stageNums = responseTagConfig.tags
      .map(tag => {
        const stageId = getStageIdFromTag(tag);

        return stages.findIndex(stage => stage._id === stageId) + 1;
      })
      .join(', ');
    return `${t(`request.multiStageBehavior.${MultiStageBehavior.PER_STAGE}.label`)} (${stageNums || '—'})`;
  } else if (stages.length > 1) {
    return structure.newFeaturesDisabled
      ? `${t(`request.multiStageBehavior.${MultiStageBehavior.SINGLE}.label`)} (${t('request.multiStageBehavior.startingAtStage', { stageNum: stages.findIndex(stage => stage._id === stageId) + 1 })})`
      : `${t(`request.multiStageBehavior.${MultiStageBehavior.SINGLE}.label`)} (${t('general.stage', { count: 1 })} ${stages.findIndex(stage => stage._id === stageId) + 1})`;
  } else {
    return null;
  }
};
