import { DropdownMenuDivider, DropdownMenuItem, InlineMenu } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { Popover, PopoverData } from '@deepstream/ui-kit/elements/popup/usePopover';
import { SuppliersGridActionConfig } from './useSuppliersGridActionConfig';

export const SuppliersContextMenu = ({
  contextMenu,
  suppliersGridActionConfig,
}: {
  contextMenu: {
    popover: PopoverData;
  },
  suppliersGridActionConfig: SuppliersGridActionConfig[];
}) => {
  return (
    <Popover
      {...contextMenu.popover}
      onClickOutside={contextMenu.popover.close}
      py="8px"
      sx={{
        marginLeft: 0,
        marginRight: 0,
        marginTop: '4px',
        marginBottom: 0,
        zIndex: 10,
        backgroundColor: 'white',
      }}
    >
      <InlineMenu close={contextMenu.popover.close}>
        {suppliersGridActionConfig.map((config) => {
          if (!config.showInContextMenu) {
            return null;
          }

          if (config.type === 'divider') {
            return (
              <DropdownMenuDivider key={config.id} />
            );
          } else {
            const {
              id,
              icon,
              isIconRegular,
              onSelect,
              label,
            } = config;

            return (
              <DropdownMenuItem
                key={id}
                icon={icon}
                isIconRegular={isIconRegular}
                onSelect={onSelect}
              >
                {label}
              </DropdownMenuItem>
            );
          }
        })}
      </InlineMenu>
    </Popover>
  );
};
