import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { withProps } from '@deepstream/ui-utils/withProps';
import {
  Company,
  Live,
  getBidIntentionStatus,
  getBidOutcomeStatus,
  LotIntentionStatus,
  BidIntentionStatus,
  ActionType,
  renderStageName,
  RequestInteractivityStatus,
  BidStatus,
  ExternalSystem,
  BidOutcomeStatus,
} from '@deepstream/common/rfq-utils';
import {
  compact,
  conforms, fromPairs, get,
  groupBy,
  isEmpty,
  isNil,
  isNumber,
  last, mapValues,
} from 'lodash';
import differenceInHours from 'date-fns/differenceInHours';
import { DateFormat } from '@deepstream/utils';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils/types';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { Box } from 'rebass/styled-components';
import { ActiveStageFilterOperator, FilterComparisonOperator } from '@deepstream/common';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import * as rfx from '../../../rfx';
import {
  ActionCountCell,
  AuctionBidderAgreementStatus,
  AuctionBidderAgreementStatusCell,
  BidIntentionStatusCell,
  BidOutcomeStatusCell,
  CompanyLogoAndNameCell,
  CurrentAuctionBidCell,
  DateOrDashCell,
  LockStatusCell,
  LotIntentionStatusCell,
  ProgressPercentCell,
  TextOrDashCell,
  UnreadCommentsCell,
  ValueOrNotMappedCellWithTooltip,
} from '../../../ui/ExchangeDefsGrid/nonValidationAwareValueCell';
import { SortableHeader } from '../../../ui/ExchangeDefsGrid/header';
import { StatsByRecipientId } from '../../../types';
import { useSupplierIntegrationMap } from '../../../useExternalSystems';
import { useNotifications } from '../../Notifications';
import { useRfqId } from '../../../useRfq';
import { useCurrentUserLocale } from '../../../useCurrentUser';
import { renderLabel } from '../../../RequestsTable';
import { ColumnId, GroupingCriterion } from './requestSentSuppliersViews';
import { useBidIntentionStatusFilterItems } from './useBidIntentionStatusFilterItems';
import { useLotIntentionStatusFilterItems } from './useLotIntentionStatusFilterItems';
import { useBidOutcomeStatusFilterItems } from './useBidOutcomeStatusFilterItems';
import { NOT_APPLICABLE_PLACEHOLDER } from '../../../constants';
import { GridFilterConfig, MultiSelectFilterPage, OperatorAndDateFilterPage, OperatorAndNumberFilterPage } from '../../../ui/ExchangeDefsGrid/GridFilterDropdown';
import { isAuctionStage } from '../../../utils';

export type SuppliersGridRow = {
  _id: string;
  isSubHeader?: boolean;
  recipient?: Company;
  label?: React.ReactNode;
  iconProps?: IconProps;
  numItems?: number;
  groupProps?: {
    groupBy: GroupingCriterion;
    lotId?: string;
    stageId?: string;
    bidIntentionStatus?: BidIntentionStatus;
    bidOutcomeStatus?: BidOutcomeStatus;
  }
};

const renderLabelWithIcon = (item: { label: string; iconProps: IconProps } | null) => {
  return item ? (
    <Box as="span">
      <Icon fixedWidth mr={1} {...item.iconProps} />
      {item.label}
    </Box>
  ) : (
    ''
  );
};

const getSupplierInviteStatus = (recipient: Company) => {
  return recipient.company.isPending ? 'notSignedUp' : 'signedUp';
};

const iconPropsByAuctionBidderAgreementStatus = {
  [AuctionBidderAgreementStatus.ACCEPTED]: {
    icon: 'check-circle',
    color: 'success',
  },
  [AuctionBidderAgreementStatus.NOT_ACCEPTED]: {
    icon: 'circle-dashed',
    color: 'subtext',
  },
  [NOT_APPLICABLE_PLACEHOLDER]: {
    icon: 'minus',
    regular: true,
  },
};

const iconPropsByLockStatus = {
  locked: {
    icon: 'lock',
  },
  unlocked: {
    icon: 'unlock',
  },
  [NOT_APPLICABLE_PLACEHOLDER]: {
    icon: 'minus',
    regular: true,
  },
};

const getLockStatus = (lockedItemCount: number | null) => {
  if (lockedItemCount! >= 1) {
    return 'locked';
  } else if (lockedItemCount === 0) {
    return 'unlocked';
  } else {
    return NOT_APPLICABLE_PLACEHOLDER;
  }
};

const orderedExternalSupplierStatuses = [
  'green',
  'gray',
  'amber',
  'red',
];

const useUnreadCommentsByRecipientId = () => {
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const notificationFilter = useMemo(() => {
    return conforms({
      domain: domain => domain === NotificationDomain.RFQ_SENT,
      action: action => action === NotificationAction.EXCHANGE_REPLY_SENT,
      to: to => to.companyId === currentCompanyId,
      meta: meta => (
        (meta.actionType === ActionType.NONE || meta.hasComment) &&
        meta.rfqId === rfqId
      ),
    });
  }, [currentCompanyId, rfqId]);

  const notifications = useNotifications(notificationFilter);

  return useMemo(() => {
    const groupedNotifications = groupBy(notifications, notification => notification.meta.recipientId);

    return mapValues(
      groupedNotifications,
      notifications => notifications.length,
    );
  }, [notifications]);
};

const useStageFilterItems = () => {
  const { t } = useTranslation('translation');

  return useMemo(() => {
    return [
      { label: t('requests.filtering.isAtStage'), operator: ActiveStageFilterOperator.EQ },
      { label: t('requests.filtering.isNotAtStage'), operator: ActiveStageFilterOperator.NEQ },
      { label: t('requests.filtering.isBeforeStage'), operator: ActiveStageFilterOperator.LT },
      { label: t('requests.filtering.isAfterStage'), operator: ActiveStageFilterOperator.GT },
      { label: t('requests.filtering.isAtOrBeforeStage'), operator: ActiveStageFilterOperator.LTE },
      { label: t('requests.filtering.isAtOrAfterStage'), operator: ActiveStageFilterOperator.GTE },
      { label: t('requests.filtering.isAtFinalStage'), operator: ActiveStageFilterOperator.FINAL_STAGE },
    ];
  }, [t]);
};

const stageIndexMatches = (
  actualStageIndex: number,
  selectedStageIndex: number,
  finalStageIndex: number,
  operator: string,
) => {
  switch (operator) {
    case ActiveStageFilterOperator.EQ:
      return actualStageIndex === selectedStageIndex;
    case ActiveStageFilterOperator.NEQ:
      return actualStageIndex !== selectedStageIndex;
    case ActiveStageFilterOperator.LT:
      return actualStageIndex < selectedStageIndex;
    case ActiveStageFilterOperator.GT:
      return actualStageIndex > selectedStageIndex;
    case ActiveStageFilterOperator.LTE:
      return actualStageIndex <= selectedStageIndex;
    case ActiveStageFilterOperator.GTE:
      return actualStageIndex >= selectedStageIndex;
    case ActiveStageFilterOperator.FINAL_STAGE:
      return actualStageIndex === finalStageIndex;
    default:
      return false;
  }
};

enum ApplicabilityFilterOperator {
  NOT_APPLICABLE = 'notApplicable',
  NOT_NOT_APPLICABLE = 'notNotApplicable',
}

const useNumberOrApplicabilityFilterItems = () => {
  const { t } = useTranslation('translation');

  return useMemo(() => {
    return [
      { label: t('requests.filtering.isEqual'), operator: FilterComparisonOperator.EQ },
      { label: t('requests.filtering.isNotEqual'), operator: FilterComparisonOperator.NEQ },
      { label: t('requests.filtering.isLessThan'), operator: FilterComparisonOperator.LT },
      { label: t('requests.filtering.isGreaterThan'), operator: FilterComparisonOperator.GT },
      { label: t('requests.filtering.isLessThanOrEqual'), operator: FilterComparisonOperator.LTE },
      { label: t('requests.filtering.isGreaterThanOrEqual'), operator: FilterComparisonOperator.GTE },
      { label: t('requests.filtering.isNotApplicable'), operator: ApplicabilityFilterOperator.NOT_APPLICABLE },
      { label: t('requests.filtering.isNotNotApplicable'), operator: ApplicabilityFilterOperator.NOT_NOT_APPLICABLE },
    ];
  }, [t]);
};

const numberOrApplicabilityMatches = (
  actualNumber: number | undefined | null,
  selectedNumber: number,
  operator: string,
): boolean => {
  switch (operator) {
    case FilterComparisonOperator.EQ:
      return isNumber(actualNumber) && actualNumber === selectedNumber;
    case FilterComparisonOperator.NEQ:
      return isNumber(actualNumber) && actualNumber !== selectedNumber;
    case FilterComparisonOperator.LT:
      return isNumber(actualNumber) && actualNumber < selectedNumber;
    case FilterComparisonOperator.GT:
      return isNumber(actualNumber) && actualNumber > selectedNumber;
    case FilterComparisonOperator.LTE:
      return isNumber(actualNumber) && actualNumber <= selectedNumber;
    case FilterComparisonOperator.GTE:
      return isNumber(actualNumber) && actualNumber >= selectedNumber;
    case ApplicabilityFilterOperator.NOT_APPLICABLE:
      return isNil(actualNumber);
    case ApplicabilityFilterOperator.NOT_NOT_APPLICABLE:
      return !isNil(actualNumber);
    default:
      return false;
  }
};

const useNumberFilterItems = () => {
  const { t } = useTranslation('translation');

  return useMemo(() => {
    return [
      { label: t('requests.filtering.isEqual'), operator: FilterComparisonOperator.EQ },
      { label: t('requests.filtering.isNotEqual'), operator: FilterComparisonOperator.NEQ },
      { label: t('requests.filtering.isLessThan'), operator: FilterComparisonOperator.LT },
      { label: t('requests.filtering.isGreaterThan'), operator: FilterComparisonOperator.GT },
      { label: t('requests.filtering.isLessThanOrEqual'), operator: FilterComparisonOperator.LTE },
      { label: t('requests.filtering.isGreaterThanOrEqual'), operator: FilterComparisonOperator.GTE },
    ];
  }, [t]);
};

const numberMatches = (
  actualNumber: number,
  selectedNumber: number,
  operator: string,
): boolean => {
  switch (operator) {
    case FilterComparisonOperator.EQ:
      return actualNumber === selectedNumber;
    case FilterComparisonOperator.NEQ:
      return actualNumber !== selectedNumber;
    case FilterComparisonOperator.LT:
      return actualNumber < selectedNumber;
    case FilterComparisonOperator.GT:
      return actualNumber > selectedNumber;
    case FilterComparisonOperator.LTE:
      return actualNumber <= selectedNumber;
    case FilterComparisonOperator.GTE:
      return actualNumber >= selectedNumber;
    default:
      return false;
  }
};

const useDateFilterItems = () => {
  const { t } = useTranslation('translation');

  return useMemo(() => {
    return [
      { label: t('requests.filtering.is'), operator: FilterComparisonOperator.EQ },
      { label: t('requests.filtering.isNot'), operator: FilterComparisonOperator.NEQ },
      { label: t('requests.filtering.isBefore'), operator: FilterComparisonOperator.LT },
      { label: t('requests.filtering.isAfter'), operator: FilterComparisonOperator.GT },
      { label: t('requests.filtering.isOnOrBefore'), operator: FilterComparisonOperator.LTE },
      { label: t('requests.filtering.isOnOrAfter'), operator: FilterComparisonOperator.GTE },
    ];
  }, [t]);
};

const dateMatches = (
  actualDate: Date | null | undefined,
  selectedDate: Date | null | undefined,
  operator: string,
): boolean => {
  if (!actualDate || !selectedDate) {
    return true;
  }

  // We want to 1) ignore the time of the day and 2) make sure that the day
  // boundaries match the current user's locale (rather than the ISO date),
  // so we construct strings from `getFullYear`, `getMonth` and `getDay`, which
  // return year, month and day according to locale time and then.
  const actualDay = `${actualDate.getFullYear()}-${actualDate.getMonth()}-${actualDate.getDay()}}`;
  const selectedDay = `${selectedDate.getFullYear()}-${selectedDate.getMonth()}-${selectedDate.getDay()}}`;

  switch (operator) {
    case FilterComparisonOperator.EQ:
      return actualDay === selectedDay;
    case FilterComparisonOperator.NEQ:
      return actualDay !== selectedDay;
    case FilterComparisonOperator.LT:
      return actualDay < selectedDay;
    case FilterComparisonOperator.GT:
      return actualDay > selectedDay;
    case FilterComparisonOperator.LTE:
      return actualDay <= selectedDay;
    case FilterComparisonOperator.GTE:
      return actualDay >= selectedDay;
    default:
      return false;
  }
};

export const useSuppliersGridColumnConfigById = (
  statsByRecipientId?: StatsByRecipientId,
  externalSystem?: ExternalSystem,
): Record<ColumnId, Omit<EditableGridColumn<SuppliersGridRow>, 'Header'> & GridFilterConfig<Company> | null> => {
  const { t } = useTranslation(['translation', 'general', 'integration']);
  const structure = rfx.useStructure<Live>();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const requestInteractivityStatusByRecipientId = rfx.useRequestInteractivityStatusByRecipientId();
  const suppliersIntegrationMap = useSupplierIntegrationMap({ currentCompanyId, systemId: externalSystem?.systemId });
  const unreadCommentsByRecipientId = useUnreadCommentsByRecipientId();
  const bidIntentionStatusItems = useBidIntentionStatusFilterItems();
  const lotIntentionStatusItems = useLotIntentionStatusFilterItems();
  const bidOutcomeStatusItems = useBidOutcomeStatusFilterItems();
  const stageFilterItems = useStageFilterItems();
  const numberOrApplicabilityFilterItems = useNumberOrApplicabilityFilterItems();
  const numberFilterItems = useNumberFilterItems();
  const dateFilterItems = useDateFilterItems();
  const locale = useCurrentUserLocale();

  return useMemo(() => {
    const { auction, bidById, stages, recipients } = structure;

    const firstStageId = stages[0]._id;
    const auctionStageIndex = stages.findIndex(isAuctionStage);

    const bidIntentionStatusByRecipientId = fromPairs(
      recipients.map(recipient => {
        const bid = bidById[recipient._id];

        const status = recipient.company.isPending || !bid
          ? BidIntentionStatus.NO_RESPONSE
          : getBidIntentionStatus(bid);

        return [
          recipient._id,
          status,
        ];
      }),
    );

    const getStageIndex = (recipientId: string) => {
      const { stageId } = bidById[recipientId];
      return structure.stages.findIndex(stage => stage._id === stageId);
    };

    const getCurrentStageCompletion = (recipientId: string) => {
      if (bidIntentionStatusByRecipientId[recipientId] === BidIntentionStatus.NO_RESPONSE) {
        return null;
      }

      return statsByRecipientId?.[recipientId]?.currentStageBidCompletion;
    };

    const getStageCompletion = (recipientId: string, stageId: string) => {
      if (bidIntentionStatusByRecipientId[recipientId] === BidIntentionStatus.NO_RESPONSE) {
        return null;
      }

      return statsByRecipientId?.[recipientId]?.completionByStageId[stageId];
    };

    const getAuctionBidderAgreementStatus = (recipientId: string) => {
      if (
        bidIntentionStatusByRecipientId[recipientId] === BidIntentionStatus.NO_RESPONSE ||
        bidById[recipientId].enteredStageIds.length <= auctionStageIndex
      ) {
        return null;
      }

      return auction.bidderIds?.includes(recipientId)
        ? AuctionBidderAgreementStatus.ACCEPTED
        : AuctionBidderAgreementStatus.NOT_ACCEPTED;
    };

    const getLatestAuctionBid = (recipientId: string) => {
      if (
        bidIntentionStatusByRecipientId[recipientId] === BidIntentionStatus.NO_RESPONSE ||
        bidById[recipientId].enteredStageIds.length <= auctionStageIndex ||
        !auction.bidderIds?.includes(recipientId)
      ) {
        return null;
      }

      return last(auction.lots[0].bidsByBidderId[recipientId])?.price || 0;
    };

    const getBidActionCount = (recipientId: string, propName: 'numOwnBidActions' | 'numTeamBidActions') => {
      if (
        requestInteractivityStatusByRecipientId[recipientId] !== RequestInteractivityStatus.LIVE ||
        bidIntentionStatusByRecipientId[recipientId] === BidIntentionStatus.NO_RESPONSE
      ) {
        return null;
      }

      return statsByRecipientId?.[recipientId]?.[propName];
    };

    const getLockedItemCount = (recipientId: string) => {
      if (bidIntentionStatusByRecipientId[recipientId] === BidIntentionStatus.NO_RESPONSE) {
        return null;
      }

      return statsByRecipientId?.[recipientId]?.numLockedItems || 0;
    };

    const getEvaluationCompletion = (recipientId: string) => {
      if (bidIntentionStatusByRecipientId[recipientId] !== BidIntentionStatus.BIDDING) {
        return null;
      }

      return statsByRecipientId?.[recipientId]?.evaluationCompletion;
    };

    const getEvaluationActionCount = (recipientId: string, propName: 'numOwnEvaluationActions' | 'numTeamEvaluationActions') => {
      const bidStatus = bidById[recipientId].status;

      if (
        bidStatus === BidStatus.AWARDED ||
        bidStatus === BidStatus.UNSUCCESSFUL ||
        bidIntentionStatusByRecipientId[recipientId] !== BidIntentionStatus.BIDDING
      ) {
        return null;
      }

      return statsByRecipientId?.[recipientId]?.[propName];
    };

    const getAddedDate = (recipientId: string) => {
      const firstStageEnterDate = bidById[recipientId].enterDateByStageId[firstStageId];

      return firstStageEnterDate ? new Date(firstStageEnterDate) : null;
    };

    return {
      supplier: {
        _id: 'supplier',
        accessorKey: 'supplier',
        accessorFn: ({ recipient }) => get(recipient, 'company'),
        sortAccessorFn: ({ recipient }) => get(recipient, 'company.name'),
        sortType: 'caseInsensitive',
        label: t('general.supplier', { count: 1 }),
        ValueCell: CompanyLogoAndNameCell,
        width: 300,
        iconProps: {
          icon: 'building',
          isIconRegular: true,
        },
        FilterPageComponent: MultiSelectFilterPage,
        getFilterItems: (recipients) => {
          return recipients
            .map(recipient => {
              return {
                value: recipient._id,
                label: get(recipient, 'company.name'),
              };
            })
            .sort((a, b) => a.label.localeCompare(b.label, locale));
        },
        renderFilterItem: renderLabel,
        matchesFilter: (recipient, gridFilter) => {
          const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

          if (isEmpty(selectedItems)) {
            return true;
          } else {
            const value = recipient._id;

            return selectedItems.some(item => item === value);
          }
        },
      },

      // filter-only config -- cannot be used to render a column
      supplierInviteStatus: {
        _id: 'supplierInviteStatus',
        accessorKey: 'supplierInviteStatus',
        label: t('request.suppliersTable.supplierInviteStatus'),
        ValueCell: () => { throw new Error('this config cannot be used for column rendering'); },
        iconProps: {
          icon: 'building',
          isIconRegular: true,
        },
        FilterPageComponent: MultiSelectFilterPage,
        getFilterItems: (recipients) => {
          return compact([
            recipients.some(recipient => getSupplierInviteStatus(recipient) === 'signedUp') && {
              value: 'signedUp',
              label: t('request.suppliersTable.signedUp'),
              iconProps: {
                icon: 'circle-check',
                regular: true,
                color: 'success',
              },
            },
            recipients.some(recipient => getSupplierInviteStatus(recipient) === 'notSignedUp') && {
              value: 'notSignedUp',
              label: t('request.suppliersTable.notSignedUpInvited'),
              iconProps: {
                icon: 'circle-dashed',
                regular: true,
                color: 'subtext',
              },
            },
          ]);
        },
        renderFilterItem: renderLabelWithIcon,
        matchesFilter: (recipient, gridFilter) => {
          const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

          if (isEmpty(selectedItems)) {
            return true;
          } else {
            const value = getSupplierInviteStatus(recipient);

            return selectedItems.includes(value);
          }
        },
      },

      bidIntentionStatus: {
        _id: 'bidIntentionStatus',
        accessorKey: 'bidIntentionStatus',
        accessorFn: ({ recipient }) => bidIntentionStatusByRecipientId[recipient!._id],
        sortAccessorFn: ({ recipient }) => {
          const bidIntentionStatus = bidIntentionStatusByRecipientId[recipient!._id];

          switch (bidIntentionStatus) {
            case BidIntentionStatus.BIDDING: return 1;
            case BidIntentionStatus.NO_RESPONSE: return 2;
            case BidIntentionStatus.BID_WITHDRAWN: return 3;
            case BidIntentionStatus.DECLINED_TO_BID: return 4;
            default: return 0;
          }
        },
        label: t('request.suppliersTable.requestBidStatus'),
        ValueCell: BidIntentionStatusCell,
        iconProps: {
          icon: 'circle-dashed',
        },
        FilterPageComponent: MultiSelectFilterPage,
        getFilterItems: (recipients) => {
          return bidIntentionStatusItems.filter(
            item => recipients.some(recipient => {
              const value = bidIntentionStatusByRecipientId[recipient!._id];

              return value === item.value;
            }),
          );
        },
        renderFilterItem: renderLabelWithIcon,
        matchesFilter: (recipient, gridFilter) => {
          const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

          if (isEmpty(selectedItems)) {
            return true;
          } else {
            const value = bidIntentionStatusByRecipientId[recipient!._id];

            return selectedItems.some(item => item === value);
          }
        },
      },

      lotIntentionStatus: {
        _id: 'lotIntentionStatus',
        accessorKey: 'lotIntentionStatus',
        accessorFn: ({ recipient, groupProps }) => groupProps?.lotId
          ? bidById[recipient!._id].intentionStatusByLotId[groupProps.lotId] || LotIntentionStatus.NO_RESPONSE
          : null,
        sortAccessorFn: ({ recipient, groupProps }) => {
          const lotIntentionStatus = groupProps?.lotId
            ? bidById[recipient!._id].intentionStatusByLotId[groupProps.lotId] || LotIntentionStatus.NO_RESPONSE
            : null;

          switch (lotIntentionStatus) {
            case LotIntentionStatus.BIDDING: return 1;
            case LotIntentionStatus.NO_RESPONSE: return 2;
            case LotIntentionStatus.BID_WITHDRAWN: return 3;
            case LotIntentionStatus.DECLINED_TO_BID: return 4;
            default: return 0;
          }
        },
        label: t('request.suppliersTable.lotStatus'),
        ValueCell: LotIntentionStatusCell,
        iconProps: {
          icon: 'circle-dashed',
        },
        FilterPageComponent: MultiSelectFilterPage,
        getFilterItems: (recipients, lotId?: string) => {
          return lotIntentionStatusItems.filter(
            item => recipients.some(recipient => {
              const value = lotId
                ? bidById[recipient!._id].intentionStatusByLotId[lotId] || LotIntentionStatus.NO_RESPONSE
                : null;

              return value === item.value;
            }),
          );
        },
        renderFilterItem: renderLabelWithIcon,
        matchesFilter: (recipient, gridFilter, lotId?: string) => {
          const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

          if (isEmpty(selectedItems) || gridFilter.groupId !== lotId) {
            return true;
          } else {
            const value = lotId
              ? bidById[recipient!._id].intentionStatusByLotId[lotId] || LotIntentionStatus.NO_RESPONSE
              : null;

            return selectedItems.some(item => item === value);
          }
        },
        getFilterLabel: (groupId: string) => t('request.suppliersTable.lotNumStatus', {
          lotNum: structure.lots.findIndex(lot => lot._id === groupId) + 1,
        }),
      },

      // filter-only config -- cannot be used to render a column
      nonLotViewLotIntentionStatus: {
        _id: 'nonLotViewLotIntentionStatus',
        accessorKey: 'nonLotViewLotIntentionStatus',
        label: '',
        ValueCell: () => { throw new Error('this config cannot be used for column rendering'); },
        iconProps: {
          icon: 'circle-dashed',
        },
        FilterPageComponent: MultiSelectFilterPage,
        getFilterItems: (recipients, lotId?: string) => {
          return lotIntentionStatusItems.filter(
            item => recipients.some(recipient => {
              const value = lotId
                ? bidById[recipient!._id].intentionStatusByLotId[lotId] || LotIntentionStatus.NO_RESPONSE
                : null;

              return value === item.value;
            }),
          );
        },
        renderFilterItem: renderLabelWithIcon,
        matchesFilter: (recipient, gridFilter) => {
          const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

          const lotId = gridFilter.groupId!;

          if (isEmpty(selectedItems)) {
            return true;
          } else {
            const value = lotId
              ? bidById[recipient!._id].intentionStatusByLotId[lotId] || LotIntentionStatus.NO_RESPONSE
              : null;

            return selectedItems.some(item => item === value);
          }
        },
        getFilterLabel: (groupId: string) => t('request.suppliersTable.lotNumStatus', {
          lotNum: structure.lots.findIndex(lot => lot._id === groupId) + 1,
        }),
      },

      bidOutcomeStatus: {
        _id: 'bidOutcomeStatus',
        accessorKey: 'bidOutcomeStatus',
        accessorFn: ({ recipient }) => getBidOutcomeStatus(bidById[recipient!._id]),
        sortAccessorFn: ({ recipient, groupProps }) => {
          const bidOutcomeStatus = getBidOutcomeStatus(bidById[recipient!._id]);

          switch (bidOutcomeStatus) {
            case BidOutcomeStatus.AWARDED: return 1;
            case BidOutcomeStatus.UNSUCCESSFUL: return 2;
            case BidOutcomeStatus.PENDING: return 3;
          }
        },
        label: t('request.bidOutcome'),
        ValueCell: BidOutcomeStatusCell,
        iconProps: {
          icon: 'circle-dashed',
        },
        FilterPageComponent: MultiSelectFilterPage,
        getFilterItems: (recipients) => {
          return bidOutcomeStatusItems.filter(
            item => recipients.some(recipient => {
              const value = getBidOutcomeStatus(bidById[recipient!._id]);

              return value === item.value;
            }),
          );
        },
        renderFilterItem: renderLabelWithIcon,
        matchesFilter: (recipient, gridFilter) => {
          const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

          if (isEmpty(selectedItems)) {
            return true;
          } else {
            const value = getBidOutcomeStatus(bidById[recipient!._id]);

            return selectedItems.some(item => item === value);
          }
        },
      },

      currentStage: {
        _id: 'currentStage',
        accessorKey: 'currentStage',
        accessorFn: ({ recipient }) => {
          const stageIndex = getStageIndex(recipient!._id);
          const stage = stages[stageIndex];

          return renderStageName(stage, t, stageIndex);
        },
        sortAccessorFn: ({ recipient }) => {
          return getStageIndex(recipient!._id);
        },
        label: t('request.suppliersTable.currentStage'),
        ValueCell: TextOrDashCell,
        width: 250,
        iconProps: {
          icon: 'list-ol',
        },
        FilterPageComponent: withProps(OperatorAndNumberFilterPage, {
          defaultValue: 1,
          minValue: 1,
          maxValue: stages.length,
          valueLessOperators: [ActiveStageFilterOperator.FINAL_STAGE],
        }),
        getFilterItems: () => stageFilterItems,
        renderFilterItem: renderLabel,
        matchesFilter: (recipient, gridFilter) => {
          const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

          if (isEmpty(selectedItems)) {
            return true;
          } else {
            const { value, operator } = selectedItems[0] as { value: any; operator: any };
            const selectedStageIndex = value - 1;

            const recipientStageIndex = getStageIndex(recipient!._id);

            return stageIndexMatches(recipientStageIndex, selectedStageIndex, stages.length - 1, operator);
          }
        },
      },

      currentStageCompletion: {
        _id: 'currentStageCompletion',
        accessorKey: 'currentStageCompletion',
        accessorFn: ({ recipient }) => {
          return getCurrentStageCompletion(recipient!._id);
        },
        sortAccessorFn: ({ recipient }) => {
          const currentStageCompletion = getCurrentStageCompletion(recipient!._id);

          return isNumber(currentStageCompletion)
            ? currentStageCompletion
            : currentStageCompletion === null ? -1 : -0.5;
        },
        label: t('request.suppliersTable.currentStageCompletion'),
        ValueCell: ProgressPercentCell,
        iconProps: {
          icon: 'percent',
          isIconRegular: true,
        },
        FilterPageComponent: withProps(OperatorAndNumberFilterPage, {
          defaultValue: 0,
          minValue: 0,
          maxValue: 100,
          selectOperatorMinWidth: '180px',
          valueLessOperators: [
            ApplicabilityFilterOperator.NOT_APPLICABLE,
            ApplicabilityFilterOperator.NOT_NOT_APPLICABLE,
          ],
          valueSuffix: '%',
        }),
        getFilterItems: () => numberOrApplicabilityFilterItems,
        renderFilterItem: renderLabel,
        matchesFilter: (recipient, gridFilter) => {
          const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

          if (isEmpty(selectedItems)) {
            return true;
          } else {
            const { value, operator } = selectedItems[0] as { value: any; operator: any };
            const selectedCompletion = value ? value / 100 : value;

            const recipientStageCompletion = getCurrentStageCompletion(recipient!._id);

            return numberOrApplicabilityMatches(recipientStageCompletion, selectedCompletion, operator);
          }
        },
      },

      // filter-only config -- cannot be used to render a column
      stageNCompletion: {
        _id: 'stageNCompletion',
        accessorKey: 'stageNCompletion',
        label: '',
        ValueCell: () => { throw new Error('this config cannot be used for column rendering'); },
        iconProps: {
          icon: 'percent',
          isIconRegular: true,
        },
        FilterPageComponent: withProps(OperatorAndNumberFilterPage, {
          defaultValue: 0,
          minValue: 0,
          maxValue: 100,
          selectOperatorMinWidth: '180px',
          valueLessOperators: [
            ApplicabilityFilterOperator.NOT_APPLICABLE,
            ApplicabilityFilterOperator.NOT_NOT_APPLICABLE,
          ],
          valueSuffix: '%',
        }),
        getFilterItems: () => numberOrApplicabilityFilterItems,
        renderFilterItem: renderLabel,
        matchesFilter: (recipient, gridFilter) => {
          const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

          const stageId = gridFilter.groupId!;

          if (isEmpty(selectedItems)) {
            return true;
          } else {
            const { value, operator } = selectedItems[0] as { value: any; operator: any };
            const selectedCompletion = value ? value / 100 : value;

            const recipientStageCompletion = getStageCompletion(recipient!._id, stageId);

            return numberOrApplicabilityMatches(recipientStageCompletion, selectedCompletion, operator);
          }
        },
        getFilterLabel: (groupId: string) => t('request.suppliersTable.stageNumCompletion', {
          stageNum: structure.stages.findIndex(stage => stage._id === groupId) + 1,
        }),
      },

      auctionBidderAgreementStatus: {
        _id: 'auctionBidderAgreementStatus',
        accessorKey: 'auctionBidderAgreementStatus',
        accessorFn: ({ recipient }) => {
          return getAuctionBidderAgreementStatus(recipient!._id);
        },
        sortAccessorFn: ({ recipient }) => {
          if (bidIntentionStatusByRecipientId[recipient!._id] === BidIntentionStatus.NO_RESPONSE) {
            return 0;
          }

          return auction.bidderIds?.includes(recipient!._id)
            ? 1
            : 2;
        },
        label: t('request.suppliersTable.auctionBidderAgreement'),
        ValueCell: AuctionBidderAgreementStatusCell,
        width: 250,
        iconProps: {
          icon: 'circle-dashed',
        },
        FilterPageComponent: MultiSelectFilterPage,
        getFilterItems: (recipients) => {
          return Object.keys(iconPropsByAuctionBidderAgreementStatus)
          .filter(value => {
            return recipients.some(recipient => {
              return (getAuctionBidderAgreementStatus(recipient!._id) || NOT_APPLICABLE_PLACEHOLDER) === value;
            });
          }).map(value => ({
            value,
            label: value === NOT_APPLICABLE_PLACEHOLDER
              ? t('general.notApplicableShort')
              : t(`request.suppliersTable.auctionBidderAgreementStatus.${value}`),
            iconProps: iconPropsByAuctionBidderAgreementStatus[value],
          }));
        },
        renderFilterItem: renderLabelWithIcon,
        matchesFilter: (recipient, gridFilter) => {
          const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

          if (isEmpty(selectedItems)) {
            return true;
          } else {
            const value = getAuctionBidderAgreementStatus(recipient!._id) || NOT_APPLICABLE_PLACEHOLDER;

            return selectedItems.some(item => item === value);
          }
        },
      },
      currentAuctionBid: {
        _id: 'currentAuctionBid',
        accessorKey: 'currentAuctionBid',
        accessorFn: ({ recipient }) => {
          return getLatestAuctionBid(recipient!._id);
        },
        sortAccessorFn: ({ recipient }) => {
          const latestAuctionBid = getLatestAuctionBid(recipient!._id);

          return isNil(latestAuctionBid)
            ? -1
            : latestAuctionBid;
        },
        label: t('request.suppliersTable.auctionLatestBid'),
        ValueCell: CurrentAuctionBidCell,
        width: 200,
        iconProps: {
          icon: 'dollar-sign',
          isIconRegular: true,
        },
        FilterPageComponent: withProps(OperatorAndNumberFilterPage, {
          defaultValue: 0,
          minValue: 0,
          selectOperatorMinWidth: '180px',
          valueLessOperators: [
            ApplicabilityFilterOperator.NOT_APPLICABLE,
            ApplicabilityFilterOperator.NOT_NOT_APPLICABLE,
          ],
          showStepper: false,
          valueFormat: 'money.positive',
          valuePrefix: auction?.lots?.[0].rules.currency,
        }),
        getFilterItems: () => numberOrApplicabilityFilterItems,
        renderFilterItem: renderLabel,
        matchesFilter: (recipient, gridFilter) => {
          const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

          if (isEmpty(selectedItems)) {
            return true;
          } else {
            const { value, operator } = selectedItems[0] as { value: any; operator: any };
            const selectedAmount = value;

            const recipientAmount = getLatestAuctionBid(recipient!._id);

            return numberOrApplicabilityMatches(recipientAmount, selectedAmount, operator);
          }
        },
      },

      yourBidActions: {
        _id: 'yourBidActions',
        accessorKey: 'yourBidActions',
        accessorFn: ({ recipient }) => {
          return getBidActionCount(recipient!._id, 'numOwnBidActions');
        },
        sortAccessorFn: ({ recipient }) => {
          const actionCount = getBidActionCount(recipient!._id, 'numOwnBidActions');

          return isNumber(actionCount)
            ? actionCount
            : actionCount === null ? -1 : -0.5;
        },
        label: t('request.suppliersTable.yourBidActions'),
        ValueCell: ActionCountCell,
        iconProps: {
          icon: 'exclamation-circle',
          isIconRegular: true,
        },
        FilterPageComponent: withProps(OperatorAndNumberFilterPage, {
          defaultValue: 0,
          minValue: 0,
          selectOperatorMinWidth: '180px',
        }),
        getFilterItems: () => numberFilterItems,
        renderFilterItem: renderLabel,
        matchesFilter: (recipient, gridFilter) => {
          const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

          if (isEmpty(selectedItems)) {
            return true;
          } else {
            const { value, operator } = selectedItems[0] as { value: any; operator: any };
            const selectedActionCount = value;

            const actionCount = getBidActionCount(recipient!._id, 'numOwnBidActions') || 0;

            return numberMatches(actionCount, selectedActionCount, operator);
          }
        },
      },

      teamBidActions: {
        _id: 'teamBidActions',
        accessorKey: 'teamBidActions',
        accessorFn: ({ recipient }) => {
          return getBidActionCount(recipient!._id, 'numTeamBidActions');
        },
        sortAccessorFn: ({ recipient }) => {
          const actionCount = getBidActionCount(recipient!._id, 'numTeamBidActions');

          return isNumber(actionCount)
            ? actionCount
            : actionCount === null ? -1 : -0.5;
        },
        label: t('request.suppliersTable.teamBidActions'),
        ValueCell: ActionCountCell,
        iconProps: {
          icon: 'exclamation-circle',
          isIconRegular: true,
        },
        FilterPageComponent: withProps(OperatorAndNumberFilterPage, {
          defaultValue: 0,
          minValue: 0,
          selectOperatorMinWidth: '180px',
        }),
        getFilterItems: () => numberFilterItems,
        renderFilterItem: renderLabel,
        matchesFilter: (recipient, gridFilter) => {
          const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

          if (isEmpty(selectedItems)) {
            return true;
          } else {
            const { value, operator } = selectedItems[0] as { value: any; operator: any };
            const selectedActionCount = value;

            const actionCount = getBidActionCount(recipient!._id, 'numTeamBidActions') || 0;

            return numberMatches(actionCount, selectedActionCount, operator);
          }
        },
      },

      unreadComments: {
        _id: 'unreadComments',
        accessorKey: 'unreadComments',
        accessorFn: ({ recipient }) => {
          return unreadCommentsByRecipientId[recipient!._id] || 0;
        },
        label: t('request.suppliersTable.unreadComments'),
        ValueCell: UnreadCommentsCell,
        iconProps: {
          icon: 'comment-o',
        },
        FilterPageComponent: withProps(OperatorAndNumberFilterPage, {
          defaultValue: 0,
          minValue: 0,
          selectOperatorMinWidth: '180px',
        }),
        getFilterItems: () => numberFilterItems,
        renderFilterItem: renderLabel,
        matchesFilter: (recipient, gridFilter) => {
          const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

          if (isEmpty(selectedItems)) {
            return true;
          } else {
            const { value, operator } = selectedItems[0] as { value: any; operator: any };
            const selectedCommentCount = value;

            const commentCount = unreadCommentsByRecipientId[recipient!._id] || 0;

            return numberMatches(commentCount, selectedCommentCount, operator);
          }
        },
      },

      lockStatus: {
        _id: 'lockStatus',
        accessorKey: 'lockStatus',
        accessorFn: ({ recipient }) => {
          return getLockedItemCount(recipient!._id);
        },
        sortAccessorFn: ({ recipient }) => {
          const lockedItemCount = getLockedItemCount(recipient!._id);

          return isNil(lockedItemCount)
            ? -1
            : lockedItemCount;
        },
        label: t('request.suppliersTable.lockStatus'),
        ValueCell: LockStatusCell,
        iconProps: {
          icon: 'lock',
          isIconRegular: true,
        },
        FilterPageComponent: MultiSelectFilterPage,
        getFilterItems: (recipients) => {
          return Object.keys(iconPropsByLockStatus).filter(value => {
            return recipients.some(recipient => {
              return getLockStatus(getLockedItemCount(recipient!._id)) === value;
            });
          }).map(value => ({
            value,
            label: t(`request.suppliersTable.lockStatusFilter.${value}`),
            iconProps: iconPropsByLockStatus[value],
          }));
        },
        renderFilterItem: renderLabelWithIcon,
        matchesFilter: (recipient, gridFilter) => {
          const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

          if (isEmpty(selectedItems)) {
            return true;
          } else {
            const value = getLockStatus(getLockedItemCount(recipient!._id));

            return selectedItems.some(item => item === value);
          }
        },
      },

      evaluationCompletion: {
        _id: 'evaluationCompletion',
        accessorKey: 'evaluationCompletion',

        accessorFn: ({ recipient }) => {
          return getEvaluationCompletion(recipient!._id);
        },
        sortAccessorFn: ({ recipient }) => {
          const evaluationCompletion = getEvaluationCompletion(recipient!._id);

          return isNumber(evaluationCompletion)
            ? evaluationCompletion
            : evaluationCompletion === null ? -1 : -0.5;
        },
        label: t('request.suppliersTable.evaluationCompletion'),
        ValueCell: ProgressPercentCell,
        iconProps: {
          icon: 'percent',
          isIconRegular: true,
        },
        FilterPageComponent: withProps(OperatorAndNumberFilterPage, {
          defaultValue: 0,
          minValue: 0,
          maxValue: 100,
          selectOperatorMinWidth: '180px',
          valueLessOperators: [
            ApplicabilityFilterOperator.NOT_APPLICABLE,
            ApplicabilityFilterOperator.NOT_NOT_APPLICABLE,
          ],
          valueSuffix: '%',
        }),
        getFilterItems: () => numberOrApplicabilityFilterItems,
        renderFilterItem: renderLabel,
        matchesFilter: (recipient, gridFilter) => {
          const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

          if (isEmpty(selectedItems)) {
            return true;
          } else {
            const { value, operator } = selectedItems[0] as { value: any; operator: any };
            const selectedCompletion = value;

            const recipientStageCompletion = getEvaluationCompletion(recipient!._id);

            return numberOrApplicabilityMatches(recipientStageCompletion, selectedCompletion, operator);
          }
        },
      },

      yourEvaluationActions: {
        _id: 'yourEvaluationActions',
        accessorKey: 'yourEvaluationActions',
        accessorFn: ({ recipient }) => {
          return getEvaluationActionCount(recipient!._id, 'numOwnEvaluationActions');
        },
        sortAccessorFn: ({ recipient }) => {
          const actionCount = getEvaluationActionCount(recipient!._id, 'numOwnEvaluationActions');

          return isNumber(actionCount)
            ? actionCount
            : actionCount === null ? -1 : -0.5;
        },
        label: t('request.suppliersTable.yourEvaluationActions'),
        ValueCell: ActionCountCell,
        iconProps: {
          icon: 'exclamation-circle',
          isIconRegular: true,
        },
        FilterPageComponent: withProps(OperatorAndNumberFilterPage, {
          defaultValue: 0,
          minValue: 0,
          selectOperatorMinWidth: '180px',
        }),
        getFilterItems: () => numberFilterItems,
        renderFilterItem: renderLabel,
        matchesFilter: (recipient, gridFilter) => {
          const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

          if (isEmpty(selectedItems)) {
            return true;
          } else {
            const { value, operator } = selectedItems[0] as { value: any; operator: any };
            const selectedActionCount = value;

            const actionCount = getEvaluationActionCount(recipient!._id, 'numOwnEvaluationActions') || 0;

            return numberMatches(actionCount, selectedActionCount, operator);
          }
        },
      },

      teamEvaluationActions: {
        _id: 'teamEvaluationActions',
        accessorKey: 'teamEvaluationActions',
        accessorFn: ({ recipient }) => {
          return getEvaluationActionCount(recipient!._id, 'numTeamEvaluationActions');
        },
        sortAccessorFn: ({ recipient }) => {
          const actionCount = getEvaluationActionCount(recipient!._id, 'numTeamEvaluationActions');

          return isNumber(actionCount)
            ? actionCount
            : actionCount === null ? -1 : -0.5;
        },
        label: t('request.suppliersTable.teamEvaluationActions'),
        ValueCell: ActionCountCell,
        iconProps: {
          icon: 'exclamation-circle',
          isIconRegular: true,
        },
        FilterPageComponent: withProps(OperatorAndNumberFilterPage, {
          defaultValue: 0,
          minValue: 0,
          selectOperatorMinWidth: '180px',
        }),
        getFilterItems: () => numberFilterItems,
        renderFilterItem: renderLabel,
        matchesFilter: (recipient, gridFilter) => {
          const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

          if (isEmpty(selectedItems)) {
            return true;
          } else {
            const { value, operator } = selectedItems[0] as { value: any; operator: any };
            const selectedActionCount = value;

            const actionCount = getEvaluationActionCount(recipient!._id, 'numTeamEvaluationActions') || 0;

            return numberMatches(actionCount, selectedActionCount, operator);
          }
        },
      },

      addedToRequest: {
        _id: 'addedToRequest',
        accessorKey: 'addedToRequest',
        accessorFn: ({ recipient }) => {
          return getAddedDate(recipient!._id);
        },
        sortType: 'datetime',
        label: t('request.suppliersTable.addedToRequest'),
        ValueCell: withProps(DateOrDashCell, { dateFormat: DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ }) as any,
        width: 220,
        iconProps: {
          icon: 'calendar',
          isIconRegular: true,
        },
        FilterPageComponent: OperatorAndDateFilterPage,
        getFilterItems: () => dateFilterItems,
        renderFilterItem: renderLabel,
        matchesFilter: (recipient, gridFilter) => {
          const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

          if (isEmpty(selectedItems)) {
            return true;
          } else {
            const { value, operator } = selectedItems[0] as { value: any; operator: any };
            const selectedDate = value ? new Date(value) : null;

            const addedDate = getAddedDate(recipient!._id);

            return dateMatches(addedDate, selectedDate, operator);
          }
        },
      },

      externalSupplierStatus: externalSystem
        ? {
          _id: 'externalSupplierStatus',
          accessorKey: 'externalSupplierStatus',
          Header: withProps(SortableHeader, {
            clamp: true,
            info: t('externalStatus.statusHeaderTooltip', {
              externalSystemName: externalSystem.name,
              hoursPassed: t('externalStatus.hourCount', {
                count: differenceInHours(new Date(), new Date(externalSystem.externalStatusesUpdatedAt)),
                ns: 'integration',
              }),
              ns: 'integration',
            }),
          }),
          label: `${t('status', { ns: 'general' })} ${externalSystem.name}`,
          accessorFn: ({ recipient }) => {
            return suppliersIntegrationMap?.[recipient!._id];
          },
          sortAccessorFn: ({ recipient }) => {
            const value = suppliersIntegrationMap?.[recipient!._id];

            return orderedExternalSupplierStatuses.indexOf(value);
          },
          ValueCell: withProps(ValueOrNotMappedCellWithTooltip, { externalSystemName: externalSystem.name }) as any,
          iconProps: {
            icon: 'circle-dashed',
          },
          FilterPageComponent: MultiSelectFilterPage,
          getFilterItems: (recipients) => {
            return [
              ...orderedExternalSupplierStatuses,
              'notMapped',
            ].filter(value => {
              return recipients.some(recipient => {
                return (suppliersIntegrationMap?.[recipient!._id] || 'notMapped') === value;
              });
            }).map(value => ({
              value,
              label: value === 'notMapped'
                ? t('notMapped', { ns: 'integration' })
                : t(`externalStatus.externalStatus.${value}`, { ns: 'integration' }),
            }));
          },
          renderFilterItem: renderLabel,
          matchesFilter: (recipient, gridFilter) => {
            const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

            if (isEmpty(selectedItems)) {
              return true;
            } else {
              const value = (suppliersIntegrationMap?.[recipient!._id] || 'notMapped');

              return selectedItems.some(item => item === value);
            }
          },
        }
        : null,
    };
  }, [
    externalSystem,
    requestInteractivityStatusByRecipientId,
    statsByRecipientId,
    structure,
    suppliersIntegrationMap,
    t,
    unreadCommentsByRecipientId,
    bidIntentionStatusItems,
    lotIntentionStatusItems,
    bidOutcomeStatusItems,
    locale,
    stageFilterItems,
    numberOrApplicabilityFilterItems,
    numberFilterItems,
    dateFilterItems,
  ]);
};
