import * as Layout from '../../../Layout';
import { Header } from './Header';
import { QuestionnaireTeam } from './QuestionnaireTeam';
import { QuestionnairePageTab } from './utils';

export const QuestionnaireTeamPageContent = () => {
  const tabId = QuestionnairePageTab.TEAM;

  return (
    <Layout.HeaderAndContent
      header={
        <Header selectedTabId={tabId} />
      }
      content={
        <QuestionnaireTeam />
      }
    />
  );
};
