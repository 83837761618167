import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import { useModalState } from './ui/useModalState';

const InformationModal = () => {
  const {
    isOpen,
    close,
    title,
    okButtonText,
    message,
  } = React.useContext(InformationModalContext);

  return (
    <Modal
      shouldCloseOnEsc
      isOpen={isOpen}
      onRequestClose={close}
      style={{ content: { maxWidth: '500px' } }}
    >
      <ModalHeader onClose={close}>{title}</ModalHeader>
      <ModalBody>
        <MessageBlock variant="info" mt={0}>
          {message}
        </MessageBlock>
      </ModalBody>
      <ModalFooter>
        <Flex sx={{ columnGap: '8px' }}>
          <Button disabled={false} onClick={close}>
            {okButtonText}
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

const InformationModalContext = React.createContext<any>(null);

export const InformationModalProvider = ({ children }: { children: React.ReactNode }) => {
  const informationModalState = useModalState();

  const [title, setTitle] = React.useState<string>();
  const [message, setMessage] = React.useState<string>();
  const [variant, setVariant] = React.useState<'info' | 'warn' | 'error' | 'success'>();
  const [okButtonText, setOkButtonText] = React.useState<string>();

  const value = React.useMemo(() => {
    return {
      title,
      message,
      okButtonText,
      variant,
      setTitle,
      setMessage,
      setOkButtonText,
      setVariant,
      isOpen: informationModalState.isOpen,
      close: informationModalState.close,
      open: informationModalState.open,
    };
  }, [
    title,
    message,
    okButtonText,
    variant,
    informationModalState,
  ]);

  return (
    <InformationModalContext.Provider value={value}>
      <InformationModal />
      {children}
    </InformationModalContext.Provider>
  );
};

export type ShowInfoModalParams = {
  message: string;
  title?: string;
  okButtonText?: string;
};

export const useInformationModal = () => {
  const { t } = useTranslation('general');

  // const {
  //   setTitle,
  //   setMessage,
  //   setOkButtonText,
  //   setVariant,
  //   open,
  // } = React.useContext(InformationModalContext);
  const informationModalContext = React.useContext(InformationModalContext);

  const showModal = React.useCallback((params: ShowInfoModalParams & { variant: 'info' | 'warn' | 'error' | 'success' }) => {
    if (!informationModalContext) {
      throw Error('InformationModalContext is not provided');
    }

    const { setMessage, setTitle, setOkButtonText, setVariant, open } = informationModalContext;
    const { message, title, okButtonText, variant } = params;

    setMessage(message);
    setTitle(title ?? t('information'));
    setOkButtonText(okButtonText ?? t('ok'));
    setVariant(variant);
    open();
  }, [t, informationModalContext]);

  return {
    showSuccess: React.useCallback((params: ShowInfoModalParams) => showModal({ ...params, variant: 'success' }), [showModal]),
    showError: React.useCallback((params: ShowInfoModalParams) => showModal({ ...params, variant: 'error' }), [showModal]),
    showInfo: React.useCallback((params: ShowInfoModalParams) => showModal({ ...params, variant: 'info' }), [showModal]),
    showWarn: React.useCallback((params: ShowInfoModalParams) => showModal({ ...params, variant: 'warn' }), [showModal]),
  };
};
