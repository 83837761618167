import { useCallback, useMemo, useState } from 'react';
import { Flex, Text, Box } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { useHover } from '@deepstream/ui-kit/hooks/useHover';
import { useMediaQueries } from '@deepstream/ui-kit/hooks/useMediaQueries';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { Table } from '../../Table';
import { Datetime } from '../../Datetime';
import { useGetNotificationData } from './notificationTable';

import {
  useDeleteNotificationsMutation,
  useMarkAsReadNotificationsMutation,
  useMarkAsUnreadNotificationsMutation,
} from './NotificationsProvider';
import { useTableData } from '../../TableDataContext';
import { EmptyTableMessage } from '../../ui/EmptyTableMessage';
import { LoadingPanel } from '../../ui/Loading';
import { ErrorPanel } from '../../ui/ErrorMessage';
import { MIN_CELL_HEIGHT } from '../../FieldsCell';
import { useNavigate } from '../../tanstackRouter';

const RowActions = ({
  onExpandedStateChange,
  notification,
}) => {
  const { t } = useTranslation();
  const isRead = notification.seen;
  const [markAsUnread] = useMarkAsUnreadNotificationsMutation();
  const [markAsRead] = useMarkAsReadNotificationsMutation();
  const [deleteNotifications] = useDeleteNotificationsMutation();

  return (
    <DropdownMenu
      small
      variant="primary-outline"
      iconLeft="ellipsis-h"
      onExpandedStateChange={onExpandedStateChange}
    >
      {isRead ? (
        <DropdownMenuItem
          icon="envelope"
          onSelect={() => {
            markAsUnread({ notificationIds: [notification._id] });
          }}
        >
          {t('notificationsPage.actions.markAsUnread')}
        </DropdownMenuItem>
      ) : (
        <DropdownMenuItem
          icon="envelope-open-o"
          onSelect={() => {
            markAsRead({ notificationIds: [notification._id] });
          }}
        >
          {t('notificationsPage.actions.markAsRead')}
        </DropdownMenuItem>
      )}
      <DropdownMenuItem
        icon="times"
        onSelect={() => {
          deleteNotifications({ notificationIds: [notification._id] });
        }}
      >
        {t('notificationsPage.actions.remove')}
      </DropdownMenuItem>
    </DropdownMenu>
  );
};

const CustomRowCells = ({ row }) => {
  const getNotificationData = useGetNotificationData();
  const [cellRef, cellHovered] = useHover<HTMLTableCellElement>();
  const { canHover: deviceCanHover } = useMediaQueries();
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const navigate = useNavigate();

  const notification = row.original;
  const notificationData = getNotificationData(notification);
  // @ts-expect-error ts(2339) FIXME: Property 'message' does not exist on type '{ message: any; navigateOptions: NavigateOptions<AnyRouter, any, string, any, string> | undefined; notificationAction: any; } | { message: string; navigateOptions: null; notificationAction: null; } | undefined'.
  const { message, navigateOptions, notificationAction } = notificationData;

  const [markAsRead] = useMarkAsReadNotificationsMutation([
    () => navigateOptions
      ? navigate(navigateOptions)
      : notificationAction
        ? notificationAction()
        : null,
  ]);

  const isRead = notification.seen;

  const handleOnClick = useCallback(() => {
    markAsRead({ notificationIds: [notification._id] });
  }, [markAsRead, notification]);

  return (
    <Flex
      as="td"
      py="11px"
      ref={cellRef}
      alignItems="center"
      style={{ height: 'auto' }}
      minHeight={MIN_CELL_HEIGHT}
      sx={{
        background: (theme) => isRead ? theme.colors.disabledBackground : 'transparent',
        color: (theme) => isRead ? theme.colors.gray : 'inherit',
      }}
    >

      <Box
        alignSelf="flex-start"
        flex="0 0 auto"
        mr={2}
        mt="6px"
        fontSize="8px"
        sx={{
          visibility: isRead ? 'hidden' : 'visible',
        }}
      >
        <Icon fixedWidth color="primary" icon="circle" />
      </Box>
      <Box
        flex="1 1 auto"
        onClick={handleOnClick}
      >
        <Truncate fontWeight="500" flex={1}>
          {message}
        </Truncate>
        <Text fontSize={1} mt={1}>
          <Datetime value={notification.date} />
        </Text>
      </Box>
      <Flex flex="0 0 auto" justifyContent="flex-end">
        {(!deviceCanHover || cellHovered || isMenuExpanded) && (
          <RowActions
            onExpandedStateChange={setIsMenuExpanded}
            notification={notification}
          />
        )}
      </Flex>
    </Flex>
  );
};

export const NotificationsTableContent = () => {
  const columns = useMemo(() => [{ id: 'custom-notification-column' }], []);
  const { data, query } = useTableData();
  const { t } = useTranslation();

  return query.isError ? (
    <ErrorPanel style={{ border: 0 }} error={t('errors.unexpected')} />
  ) : query.isLoading ? (
    <LoadingPanel style={{ border: 0 }} />
  ) : !data.length ? (
    <PanelPadding>
      <EmptyTableMessage
        header={t('notificationsPage.noData.title')}
        body={t('notificationsPage.noData.message')}
      />
    </PanelPadding>
  ) : (
    <Table
      columns={columns}
      noDataPlaceholder={t('notificationsPage.noData.message')}
      isPaginated
      hideHeader
      isSortable
      hasStaticHeight={false}
      minCellHeight={MIN_CELL_HEIGHT}
      smallPageControls
      enforcePagination
      pageSizes={[5, 10, 20, 50, 100]}
      CustomRowCells={CustomRowCells}
    />
  );
};
