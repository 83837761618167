import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Text, Heading } from 'rebass/styled-components';
import { map, slice } from 'lodash';
import { Attachment } from '@deepstream/common/rfq-utils';

import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { DateFormat } from '@deepstream/utils';
import { Avatar } from '../../Avatar';
import { Datetime2 } from '../../Datetime';
import { useToaster } from '../../toast';
import { FileList } from '../../ui/FileList';
import { TextArea } from '../../ui/Input';
import { useCurrentUser } from '../../useCurrentUser';
import { useCommentsAttachmentDownload, useCommentPost } from './hooks';

import { RequestCommentsThread, RequestCommentsComment } from './types';

const MAX_INITIAL_VISIBLE_COMMENTS = 3;

const Comment = ({
  comment,
  rfqId,
  isReply,
}: {
  comment: RequestCommentsComment;
  rfqId: string;
  isReply?: boolean;
}) => {
  const { userId, userName, createdAt, message, attachments } = comment;
  const downloadAttachment = useCommentsAttachmentDownload();

  return (
    <Box
      p="20px"
      mx={isReply ? '20px' : 0}
      sx={{
        borderTop: isReply ? 'lightGray2' : undefined,
      }}
    >
      <Flex alignItems="center" mb={2}>
        <Avatar
          userId={userId}
          sx={{
            flex: '0 0 auto',
          }}
        />
        <Text mx={2} fontWeight={500}>{userName}</Text>
        <Text fontSize={1} color="subtext">
          <Datetime2 format={DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ} value={createdAt} />
        </Text>
      </Flex>
      <Text sx={{ whiteSpace: 'pre-wrap' }} as="p">{message}</Text>
      {!!attachments.length && (
        <Box mt={4}>
          <FileList
            isReadOnly
            small
            truncateFileName
            // TODO: Fix initialAttachments type (cast to fit)
            initialAttachments={(attachments as Attachment[])}
            downloadFn={(attachment) => downloadAttachment(rfqId, attachment)}
          />
        </Box>
      )}
    </Box>
  );
};

const AddResponse = ({
  rfqId,
  threadId,
}: {
  rfqId: string;
  threadId: string;
}) => {
  const { t } = useTranslation('request');
  const [response, setResponse] = useState('');
  const [rows, setRows] = useState(1);
  const inputRef = useRef<HTMLTextAreaElement>();
  const currentUser = useCurrentUser();
  const [postComment, { isLoading }] = useCommentPost();
  const toaster = useToaster();

  useEffect(() => {
    const inputEl = inputRef.current;

    if (inputEl) {
      const handleOnFocus = () => setRows(4);
      const handleOnBlur = (event) => {
        if (!event.target.value) setRows(1);
      };

      inputEl.addEventListener('focus', handleOnFocus);
      inputEl.addEventListener('blur', handleOnBlur);

      return () => {
        inputEl.removeEventListener('focus', handleOnFocus);
        inputEl.removeEventListener('blur', handleOnBlur);
      };
    }
  }, [inputRef]);

  return (
    <Flex
      p="20px"
      sx={{
        flexWrap: 'wrap',
        backgroundColor: 'lightGray3',
      }}
    >
      <Flex flex="1 1 100%">
        <Avatar
          userId={currentUser._id}
          sx={{
            marginRight: 2,
            flex: '0 0 auto',
          }}
        />
        <Box
          sx={{
            flex: '1 1 auto',
          }}
        >
          <TextArea
            ref={inputRef}
            rows={rows}
            value={response}
            disabled={isLoading}
            placeholder={t('comments.response.messagePlaceholder')}
            onChange={(event) => setResponse(event.target.value)}
          />
        </Box>
      </Flex>
      <Button
        mt={3}
        disabled={isLoading}
        sx={{
          marginLeft: 'auto',
          display: !response ? 'none' : 'block',
        }}
        onClick={async () => {
          try {
            await postComment(rfqId, threadId, response);
            setResponse('');
            setRows(1);
          } catch (e) {
            toaster.error(t('comments.response.postError'));
          }
        }}
      >
        {t('comments.response.buttonLabel')}
      </Button>
    </Flex>
  );
};

export const Thread = ({
  thread,
}: {
  thread: RequestCommentsThread;
}) => {
  const { t } = useTranslation('request');
  // First one is always visible
  const hiddenCommentsCount = thread.comments.length - MAX_INITIAL_VISIBLE_COMMENTS - 1;
  const hasExpandComments = hiddenCommentsCount > 0;
  const [expandComments, setExpandComments] = useState(!hasExpandComments);

  const threadStartComment = thread.comments[0];
  const visibleComments = slice(
    thread.comments,
    expandComments ? 1 : thread.comments.length - MAX_INITIAL_VISIBLE_COMMENTS,
  );

  return (
    <Box
      sx={{
        overflow: 'hidden',
        backgroundColor: 'white',
        borderRadius: 'small',
        border: 'lightGray2',
        marginBottom: '20px',
        '&:last-child': {
          marginBottom: 0,
        },
      }}
    >
      <Heading p="20px" pb="0">{thread.subject}</Heading>
      <Comment
        rfqId={thread.rfqId}
        comment={threadStartComment}
        isReply={false}
      />
      {hasExpandComments && (
        <Box sx={{ margin: '0 20px 10px' }}>
          <Button
            small
            variant="secondary-outline"
            sx={{ width: '100%' }}
            onClick={() => setExpandComments((prev) => !prev)}
          >
            {!expandComments
              ? t('comments.thread.showPrevious', { count: hiddenCommentsCount })
              : t('comments.thread.hideComments')
            }
          </Button>
        </Box>
      )}
      {map(visibleComments, (comment) => (
        <Comment
          rfqId={thread.rfqId}
          comment={comment}
          isReply
        />
      ))}
      <AddResponse
        rfqId={thread.rfqId}
        threadId={thread._id}
      />
    </Box>
  );
};
