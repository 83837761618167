import * as React from 'react';

export const nestValues = (A: React.FC<any>, B: React.FC<any>): React.FC<any> => ({ value, ...props }) => (
  <A
    {...props}
    value={(
      <B value={value} {...props} />
    )}
  />
);
