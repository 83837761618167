import { useQuery } from 'react-query';
import { useApi, wrap } from '../../api';
import { useCurrentCompanyId } from '../../currentCompanyId';

export const useRequestSizeLimits = ({ rfqId, isTemplate }: { rfqId: string, isTemplate: boolean }) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId();

  return useQuery(
    ['requestSizeLimits', { rfqId, currentCompanyId, isTemplate }],
    wrap(api.getRequestSizeLimits),
    {
      staleTime: 60 * 1000,
    },
  );
};
