import { useTranslation } from 'react-i18next';
import { Box, Text, Flex } from 'rebass/styled-components';
import { CellProps } from 'react-table';
import { QuestionnaireTemplateOverview, QuestionnaireTemplateStatus, questionnaireTemplateStatusConfig } from '@deepstream/common/preQual';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { Bold } from '../../../Bold';

export const QuestionnaireTemplateStatusIcon = ({ row }: CellProps<QuestionnaireTemplateOverview>) => {
  const value = row.original.status;
  const { icon } = questionnaireTemplateStatusConfig[value];

  return icon ? (
    <Box alignSelf="flex-start" flex="0 0 auto" mr="8px" mt="2px">
      <Icon
        fixedWidth
        color={icon.color}
        icon={icon.value}
        regular={icon.isRegular}
      />
    </Box>
  ) : (
    null
  );
};

export const LabeledQuestionnaireTemplateStatus = ({ status }: { status: QuestionnaireTemplateStatus }) => {
  const { t } = useTranslation(['preQualification', 'general']);
  const { icon } = questionnaireTemplateStatusConfig[status];

  return (
    <Box ml={4}>
      <Text fontSize={1} fontWeight={500} mb={1}>
        {t('status', { ns: 'general' })}
      </Text>
      <Flex alignItems="center" fontSize={2}>
        <Icon
          fixedWidth
          color={icon.color}
          icon={icon.value as IconProps['icon']}
          regular={icon.isRegular}
          mr={1}
        />
        <Bold>{t(`questionnaireTemplateStatus.${status}`)}</Bold>
      </Flex>
    </Box>
  );
};
