import { useMemo } from 'react';
import * as React from 'react';
import { compact, first, flatten, isEmpty, isFunction, partition } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass/styled-components';

import { PageType, getPagesInDisplayOrder, isAuctionStage, isEvaluationPage, isLinkedEvaluationPage } from '@deepstream/common/rfq-utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { useMediaQueries } from '@deepstream/ui-kit/hooks/useMediaQueries';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { useModalState } from '../../ui/useModalState';
import { ReviseRequestModal } from './ReviseRequestModal';
import { SendRequestModal } from './SendRequestModal';
import { RfqIdProvider, useDraftRfqStructure, useRfqId } from '../../useRfq';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { DeleteTemplateDialog } from '../../DeleteTemplateDialog';
import { DeleteRequestDialog } from '../../DeleteRequestDialog';
import { useSystemFeatureFlags } from '../../systemFeatureFlags';
import { ModelSizeLimitDialog, ModelSizeLimitMessages } from '../../ModelSizeLimitDialog';
import * as rfx from '../../rfx';
import { requestsIndexRoute } from '../../AppRouting';
import { useRequestEditNavigation } from '../../appNavigation';
import { useNavigate } from '../../tanstackRouter';

const tabIds = [
  'summary',
  'stages',
  'details',
  'auction',
  'evaluation',
  'spend',
  'team',
  'suppliers',
  'review',
] as const;

export type DraftTabId = typeof tabIds[number];

const ButtonDelete = ({
  onClick,
  isTemplate,
  disabled,
}: {
  onClick: () => void;
  isTemplate?: boolean;
  disabled?: boolean;
}) => {
  const { t } = useTranslation('request');

  return (
    <Button
      data-test={isTemplate ? 'deleteTemplate' : 'deleteRequest'}
      iconLeft="trash"
      sx={{ marginRight: 'auto' }}
      onClick={onClick}
      variant="danger-outline"
      disabled={disabled}
    >
      {isTemplate
        ? t('toolbar.deleteTemplate')
        : t('toolbar.deleteRequest')
      }
    </Button>
  );
};

const ButtonGoBack = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation('request');

  return (
    <Button
      data-test="goBack"
      iconLeft="arrow-left"
      onClick={onClick}
      disabled={disabled}
    >
      {t('toolbar.back')}
    </Button>
  );
};

const ButtonSaveAsDraft = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation('request');

  return (
    <Button
      data-test="saveAsDraft"
      iconLeft="folder"
      onClick={onClick}
      variant="primary-outline"
      disabled={disabled}
    >
      {t('toolbar.saveAsDraft')}
    </Button>
  );
};

const ButtonSaveAndContinue = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation('request');

  return (
    <Button
      data-test="saveAndContinue"
      iconRight="arrow-right"
      onClick={onClick}
      disabled={disabled}
    >
      {t('toolbar.saveAndContinue')}
    </Button>
  );
};

const SubmitButton = ({
  isRevising,
  isTemplate,
  isRequestValid,
  isSuperUserOrOwner,
  disabled: isLoading,
}: {
  isRevising?: boolean;
  isTemplate?: boolean;
  isSuperUserOrOwner?: boolean;
  isRequestValid?: boolean,
  disabled?: boolean;
}) => {
  const { t } = useTranslation('request');
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const navigate = useNavigate();

  const { data: structure, refetch } = useDraftRfqStructure({
    rfqId,
    currentCompanyId,
    isTemplate,
    enabled: !isTemplate,
  });

  const modal = useModalState();

  const label = useMemo(() => {
    if (isRevising) return t('toolbar.issueRevision');
    if (isTemplate) return t('toolbar.saveAndExit');

    return t('toolbar.sendRequest');
  }, [t, isTemplate, isRevising]);

  const tooltipContent = useMemo(() => {
    if (!isSuperUserOrOwner) {
      if (isRevising) return t('toolbar.submitInfo.reviseWithoutPermission');

      return t('toolbar.submitInfo.reviseWithErrors');
    }

    if (!isRequestValid) {
      if (isRevising) return t('toolbar.submitInfo.reviseWithErrors');
      return t('toolbar.submitInfo.sendWithErrors');
    }

    return '';
  }, [t, isRequestValid, isSuperUserOrOwner, isRevising]);

  const buttonProps: ButtonProps = useMemo(() => {
    if (isTemplate) {
      return {
        variant: 'primary',
        dataTest: 'saveRequestTemplate',
      };
    }

    return {
      dataTest: isRevising ? 'reviseRequest' : 'sendRequest',
      iconRight: 'arrow-right',
      variant: 'success',
      disabled: !isRequestValid || !isSuperUserOrOwner,
    };
  }, [isSuperUserOrOwner, isRequestValid, isRevising, isTemplate]);

  const onClickCallback = React.useCallback(
    async () => {
      if (isTemplate) {
        navigate({
          to: requestsIndexRoute.to,
          params: { currentCompanyId },
          search: { tab: 'templates' },
        });
      } else if (isRequestValid) {
        modal.open();
        await refetch();
      }
    },
    [isTemplate, isRequestValid, navigate, currentCompanyId, modal, refetch],
  );

  useWatchValue(
    isRequestValid,
    (isRequestValid) => {
      if (!isRequestValid && modal.isOpen) {
        modal.close();
      }
    },
  );

  return isTemplate || structure ? (
    <>
      <Tooltip content={!isTemplate && !(isRequestValid && isSuperUserOrOwner) && tooltipContent}>
        <div className="disabledButtonDoesNotTriggerEventsSoWeNeedThisWrapper">
          {isLoading ? (
            <Button variant="success" disabled>
              {label}
              <Icon icon="spinner" spin ml={2} />
            </Button>
          ) : (
            <Button
              onClick={onClickCallback}
              {...buttonProps}
            >
              {label}
            </Button>
          )}
        </div>
      </Tooltip>
      {modal.isOpen && isRevising ? (
        // @ts-expect-error ts(2322) FIXME: Type 'RfxStructure<"draft"> | undefined' is not assignable to type 'RfxStructure<AnyScope>'.
        <rfx.StructureProvider structure={structure}>
          <ReviseRequestModal {...modal} />
        </rfx.StructureProvider>
      ) : modal.isOpen && !isTemplate ? (
        // @ts-expect-error ts(2322) FIXME: Type 'RfxStructure<"draft"> | undefined' is not assignable to type 'RfxStructure<AnyScope>'.
        <rfx.StructureProvider structure={structure}>
          <SendRequestModal {...modal} />
        </rfx.StructureProvider>
      ) : (
        null
      )}
    </>
  ) : (
    null
  );
};

const ButtonContinue = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation('request');

  return (
    <Button
      data-test="continue"
      iconRight="arrow-right"
      onClick={onClick}
      disabled={disabled}
    >
      {t('toolbar.continue')}
    </Button>
  );
};

const useDraftNavigationTargets = ({
  rfqId,
  tabId,
  pageId,
  isTemplate,
}: {
  rfqId: string;
  tabId: DraftTabId;
  pageId?: string;
  isTemplate?: boolean;
}) => {
  const systemFeatureFlags = useSystemFeatureFlags();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const navigation = useRequestEditNavigation();

  const { data: structure } = useDraftRfqStructure({
    rfqId,
    currentCompanyId,
    isTemplate,
  });

  return React.useMemo(() => {
    if (!structure) {
      return null;
    }

    const orderedPages = getPagesInDisplayOrder(structure.pages)
      // @ts-expect-error ts(2345) FIXME: Argument of type 'PageType | undefined' is not assignable to parameter of type 'PageType'.
      .filter(page => ![PageType.AUCTION, PageType.CHAT].includes(page.type));

    const [evaluationPages, rfxPages] = partition(orderedPages, isEvaluationPage);
    const firstEvaluationPage = first(evaluationPages);
    const hasLegacyEvaluationPage = firstEvaluationPage && !isLinkedEvaluationPage(firstEvaluationPage);

    const resolvedDetailPages = hasLegacyEvaluationPage
      ? [...rfxPages, firstEvaluationPage]
      : rfxPages;

    // Users on the evaluation settings page should be able to navigate back / forth, so
    // we need to add the settings page to know its position, but the settings page itself
    // should not be a navigation target
    const resolvedEvaluationPages = tabId === 'evaluation' && pageId === 'settings'
      ? [...evaluationPages, { _id: 'settings' }]
      : evaluationPages;

    return compact(flatten([
      {
        tabId: 'summary',
        navigate: () => navigation.navigateToSummary(),
      },
      {
        tabId: 'stages',
        navigate: () => navigation.navigateToStages(),
      },
      isEmpty(resolvedDetailPages) ? ({
        tabId: 'details',
        navigate: () => navigation.navigateToDetailsIndex(),
      }) : (
        resolvedDetailPages.map(page => ({
          tabId: 'details',
          pageId: page._id,
          navigate: () => navigation.navigateToDetailsPage(page._id),
        }))
      ),
      structure.stages.some(isAuctionStage) ? {
        tabId: 'auction',
        navigate: () => navigation.navigateToAuction(),
      } : (
        null
      ),
      // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'.
      systemFeatureFlags.evaluationUpdatesEnabled && !hasLegacyEvaluationPage ? (
        isEmpty(resolvedEvaluationPages) ? (
          structure.settings.isEvaluationEnabled ? ({
            tabId: 'evaluation',
            pageId: 'no-pages',
            navigate: () => navigation.navigateToEvaluationPage('no-pages'),
          }) : ({
            tabId: 'evaluation',
            navigate: () => navigation.navigateToEvaluationIndex(),
          })
        ) : (
          resolvedEvaluationPages.map(page => ({
            tabId: 'evaluation',
            pageId: page._id,
            navigate: () => navigation.navigateToEvaluationPage(page._id),
          }))
        )
      ) : (
        null
      ),
      {
        tabId: 'spend',
        navigate: () => navigation.navigateToSpend(),
      },
      {
        tabId: 'team',
        navigate: () => navigation.navigateToTeam(),
      },
      {
        tabId: 'suppliers',
        navigate: () => navigation.navigateToSuppliers(),
      },
      {
        tabId: 'review',
        navigate: () => navigation.navigateToReview(),
      },
    ]));
  // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'.
  }, [structure, tabId, pageId, systemFeatureFlags.evaluationUpdatesEnabled, navigation]);
};

type ToolbarNavigation = {
  previous?: () => void;
  next?: () => void
};

const useDraftToolbarNavigation = ({ rfqId, tabId, pageId, isTemplate }): ToolbarNavigation => {
  const draftNavigationTargets = useDraftNavigationTargets({
    rfqId,
    tabId,
    pageId,
    isTemplate,
  });

  const currentNavigationTargetIndex = draftNavigationTargets && pageId ? (
    draftNavigationTargets.findIndex(target => target.tabId === tabId && target.pageId === pageId)
  ) : draftNavigationTargets ? (
    draftNavigationTargets.findIndex(target => target.tabId === tabId)
  ) : (
    -1
  );

  return {
    previous: draftNavigationTargets?.[currentNavigationTargetIndex - 1]?.navigate,
    next: draftNavigationTargets?.[currentNavigationTargetIndex + 1]?.navigate,
  };
};

export const DraftToolbar = ({
  rfqId,
  tabId,
  pageId,

  hasUnsavedChanges,
  onClickSaveAsDraft,
  isLoading,

  isRevising,
  isTemplate,
  isSuperUserOrOwner,

  isRequestValid,

  showSubmitButton,
  excessSupplierCount,
}: {
  rfqId: string;
  tabId: DraftTabId;
  pageId?: string;

  hasUnsavedChanges?: boolean;

  // Suppliers tab method only
  onClickSaveAsDraft?: (navigateToNextPage?: () => void) => void;
  isLoading?: boolean;

  isRevising?: boolean;
  isTemplate?: boolean;
  isSuperUserOrOwner?: boolean;

  isRequestValid?: boolean;

  showSubmitButton?: boolean;
  excessSupplierCount?: number;
}) => {
  const { t } = useTranslation();
  const { isExtraLarge } = useMediaQueries();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const hasDeletePermission = isSuperUserOrOwner;
  const canDelete = hasDeletePermission && !isRevising;
  const deleteModal = useModalState();
  const navigate = useNavigate();
  const modelSizeLimitModal = useModalState();
  const [modelSizeLimitMessages, setModelSizeLimitMessages] = React.useState<ModelSizeLimitMessages | null>(null);

  const toolbarNavigation = useDraftToolbarNavigation({
    rfqId,
    tabId,
    pageId,
    isTemplate,
  });

  const showBackButton = Boolean(toolbarNavigation.previous);
  const showContinueButton = (
    Boolean(toolbarNavigation.next) &&
    // When there are unsaved changes on the suppliers tab, we show
    // the "Save as draft" / "Save and continue" buttons instead.
    !hasUnsavedChanges
  );

  const showSizeLimitModal = () => {
    setModelSizeLimitMessages({
      heading: t('request.dialog.requestSizeLimit.heading'),
      title: t('request.dialog.requestSizeLimit.addSupplier.title'),
      warning: t('request.dialog.requestSizeLimit.addSupplier.warning'),
      body: t('request.dialog.requestSizeLimit.addSupplier.body', {
        count: excessSupplierCount,
      }),
    });
    modelSizeLimitModal.open();
  };

  return (
    <>
      <RfqIdProvider rfqId={rfqId}>
        <Box
          as="footer"
          sx={{
            position: 'fixed',
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'white',
            borderTop: (theme) => `3px solid ${theme.colors.primary}`,
            zIndex: 101,
          }}
        >
          <Flex
            data-test="footer"
            alignItems="center"
            justifyContent="flex-end"
            sx={{
              maxWidth: 1200,
              margin: '0 auto',
              padding: '15px',
              gap: 2,
            }}
          >
            {canDelete && (
              <ButtonDelete
                onClick={deleteModal.open}
                isTemplate={isTemplate}
                disabled={isLoading}
              />
            )}

            {showBackButton && (
              <ButtonGoBack
                // @ts-expect-error ts(2322) FIXME: Type '(() => void) | undefined' is not assignable to type '() => void'.
                onClick={toolbarNavigation.previous}
                disabled={isLoading}
              />
            )}

            {/* Continue button can be omitted in Suppliers and Review tabs */}
            {showContinueButton && (
              <ButtonContinue
                // @ts-expect-error ts(2322) FIXME: Type '(() => void) | undefined' is not assignable to type '() => void'.
                onClick={toolbarNavigation.next}
                disabled={isLoading}
              />
            )}

            {/* Suppliers tab only */}
            {tabId === 'suppliers' && isFunction(onClickSaveAsDraft) && hasUnsavedChanges && (
              <>
                <ButtonSaveAsDraft
                  onClick={excessSupplierCount ? showSizeLimitModal : () => onClickSaveAsDraft()}
                  disabled={isLoading}
                />
                <ButtonSaveAndContinue
                  onClick={excessSupplierCount ? showSizeLimitModal : () => {
                    // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
                    onClickSaveAsDraft(() => toolbarNavigation.next());
                  }}
                  disabled={isLoading}
                />
              </>
            )}

            {/* The submit button is only shown on the review & send tab  */}
            {showSubmitButton && (
              <SubmitButton
                isRevising={isRevising}
                isTemplate={isTemplate}
                isSuperUserOrOwner={isSuperUserOrOwner}
                isRequestValid={isRequestValid}
                disabled={isLoading}
              />
            )}
          </Flex>
        </Box>
      </RfqIdProvider>
      {!canDelete ? (
        null
      ) : isTemplate ? (
        <DeleteTemplateDialog
          {...deleteModal}
          rfqId={rfqId}
          hideRfqName
          onSuccess={() => {
            navigate({
              to: requestsIndexRoute.to,
              params: { currentCompanyId },
              search: { tab: 'templates' },
            });
          }}
          onError={deleteModal.close}
        />
      ) : (
        <DeleteRequestDialog
          {...deleteModal}
          rfqId={rfqId}
          hideRfqName
          onSuccess={() => {
            navigate({
              to: requestsIndexRoute.to,
              params: { currentCompanyId },
              search: { tab: 'drafts' },
            });
          }}
          onError={deleteModal.close}
        />
      )}
      <ModelSizeLimitDialog
        modal={modelSizeLimitModal}
        messages={modelSizeLimitMessages}
      />
    </>
  );
};
