import { useState, useMemo, useCallback, useEffect } from 'react';
import { Flex, Box, Heading, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { compact, findIndex, isEmpty, isEqual, isObject, map } from 'lodash';
import { v4 as uuid } from 'uuid';
import { isNonLinkedEvaluationPage, PageRole, PageType, renderPageName } from '@deepstream/common/rfq-utils';
import styled from 'styled-components';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { TabButtonProps, TabButton } from '@deepstream/ui-kit/elements/button/TabButton';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { useCurrentCompanyId } from '../currentCompanyId';
import { useDraftRfqStructure, useRfqId } from '../useRfq';
import { TabPanels, TabPanel } from '../ui/TabsVertical';
import * as rfx from '../rfx';
import * as draft from './draft';
import { Loading } from '../ui/Loading';
import { ErrorMessage } from '../ui/ErrorMessage';
import { Disclosure } from '../ui/Disclosure';
import { DraftPage } from './DraftPage';
import { useModalState } from '../ui/useModalState';
import { AddPageModal } from './AddPageModal';
import { Tabs, TabList, Tab } from '../ui/Tabs';
import { RequestHooksProvider } from '../modules/Request/RequestHooksProvider';
import { useSystemFeatureFlags } from '../systemFeatureFlags';
import { AddPageButton, buttonTabStyle } from './AddPageButton';
import { ModelSizeLimitsProvider } from '../modelSizeLimits';
import { DraftToolbar } from '../modules/Request/DraftToolbar';
import { useRequestSizeLimits } from '../modules/Request/useRequestSizeLimits';
import { useRequestEditNavigation } from '../appNavigation';

const DraftDetailsPageToolbar = ({ pageId }: { pageId: string }) => {
  const rfqId = useRfqId();
  const { editingPanelId, isTemplate, isRevising } = rfx.useState();
  const isSuperUserOrOwner = rfx.useIsSuperUserOrOwner();
  const isEditing = Boolean(editingPanelId);

  if (isEditing) {
    return null;
  }

  return (
    <DraftToolbar
      rfqId={rfqId}
      pageId={pageId}
      tabId="details"
      hasUnsavedChanges={false}
      showSubmitButton={false}
      isSuperUserOrOwner={isSuperUserOrOwner}
      isTemplate={isTemplate}
      isRevising={isRevising}
      isRequestValid={true}
      excessSupplierCount={0}
    />
  );
};

const Divider = styled(Box)`
  position: absolute;
  left: -8px;
  top: 9px;
  height: 20px;
  width: 1px;
  background-color: ${props => props.theme.colors.lightGray2};
  cursor: default;
`;

const dividerTabStyle = {
  position: 'relative' as const,
  marginLeft: '16px',
};

const AddEvaluationButton = ({ children, ...props }: TabButtonProps) => {
  const { t } = useTranslation();

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      height="37px"
    >
      <Divider />
      <TabButton iconLeft="plus" height="100%" py={0} px={3} {...props}>
        {t('request.pages.addEvaluation')}
      </TabButton>
    </Flex>
  );
};

const ReadOnlyNoPagesPlaceholder = () => {
  const { t } = useTranslation();

  return (
    <Box py="20px" sx={{ maxWidth: 500 }}>
      <Heading fontSize={5} fontWeight={500} mb="20px">
        {t('request.pages.noPagesAdded')}
      </Heading>
      <Text>
        {t('request.pages.requestOwnersCanAddPages')}
      </Text>
    </Box>
  );
};

const NoPagesPlaceholder = () => {
  const { t } = useTranslation();

  return (
    <Box py="20px">
      <Heading fontSize={5} fontWeight={500} mb="20px">
        {t('request.pages.noPagesAddedYet')}
      </Heading>
      <Disclosure summary={t('request.pages.disclosure.summary')}>
        {t('request.pages.disclosure.content')}
      </Disclosure>
    </Box>
  );
};

type TabInfo = {
  ids: Array<string | undefined>;
  counter: number;
};

const DraftDetailsPage = ({ pageId }: { pageId?: string }) => {
  const theme = useTheme();
  const systemFeatureFlags = useSystemFeatureFlags();
  const [tabInfo, setTabInfo] = useState<TabInfo>({ ids: [], counter: 0 });
  const rfxStructure = rfx.useStructure();
  const generalPages = draft.useGeneralDraftPages();
  const addPageModal = useModalState();
  const { t } = useTranslation();
  const rfxPermissions = rfx.useRfxPermissions();
  const [addPage, { isLoading }] = draft.useAddPage();
  const { editingPanelId } = rfx.useState();
  const { stopEditing } = rfx.useActions();
  const navigation = useRequestEditNavigation();

  const isEditingAnyPanel = Boolean(editingPanelId);

  const evaluationPage = useMemo(
    () => rfxStructure.pages.find(page => isNonLinkedEvaluationPage(page)),
    [rfxStructure],
  );

  const tabs = useMemo(
    () => rfxPermissions.canEditPages ? compact([
      ...generalPages,
      { key: 'addPageButton' },
      evaluationPage ?? { key: 'addEvaluationButton' },
    ]) : (
      compact([...generalPages, evaluationPage])
    ),
    [rfxPermissions, generalPages, evaluationPage],
  );

  useEffect(() => {
    const ids = map(generalPages, '_id');
    if (!isEqual(tabInfo.ids, ids)) {
      setTabInfo({ ids, counter: tabInfo.counter + 1 });
    }
  }, [generalPages, tabInfo]);

  const selectedTabIndex = useMemo(
    () => findIndex(tabs, { _id: pageId }),
    [tabs, pageId],
  );

  useWatchValue(
    selectedTabIndex,
    () => stopEditing(),
  );

  const onTabChange = (index: number) => {
    const page = tabs[index];

    if (isObject(page) && '_id' in page) {
      navigation.navigateToDetailsPage(page._id);
    }
  };

  const onAddPageSuccess = useCallback(
    (pageId: string) => {
      navigation.navigateToDetailsPage(pageId);
      addPageModal.close();
    },
    [addPageModal, navigation],
  );

  const addEvaluationPage = useCallback(
    () => {
      const pageId = uuid();

      addPage({
        _id: pageId,
        name: t('request.evaluation.evaluation'),
        type: PageType.EVALUATION,
      }, {
        onSuccess: () => {
          navigation.navigateToDetailsPage(pageId);
        },
      });
    },
    [addPage, t, navigation],
  );

  return isEmpty(tabs) && !rfxPermissions.canEditPages ? (
    <ReadOnlyNoPagesPlaceholder />
  ) : (
    <>
      <Tabs
        // The tabs break after reordering the pages, so we're using a key to force remounting
        key={(pageId ?? '') + tabInfo.counter}
        index={selectedTabIndex}
        onChange={onTabChange}
        canOverflow
      >
        <Box mb={3}>
          {!rfxPermissions.canEditPages && isEmpty(tabs) ? (
            null
          ) : (
            <TabList
              style={{
                backgroundColor: 'inherit',
                fontSize: theme.fontSizes[2],
              }}
              scrollLeftBackground="linear-gradient(90deg, rgba(247,249,251,1) 0%, rgba(247,249,251,1) 60%, rgba(247,249,251,0.5) 80%, rgba(247,249,251,0) 100%)"
              scrollRightBackground="linear-gradient(90deg, rgba(247,249,251,0) 0%, rgba(247,249,251,0.5) 20%, rgba(247,249,251,1) 40%, rgba(247,249,251,1) 100%)"
            >
              {generalPages.map(page => (
                <Tab key={page._id}>
                  <Truncate>
                    {page.name}
                  </Truncate>
                </Tab>
              ))}

              {rfxPermissions.canEditPages ? (
                <Tab disabled={isLoading || isEditingAnyPanel} style={{ padding: 0, ...buttonTabStyle }}>
                  <AddPageButton
                    color={isEmpty(generalPages) ? theme.colors.primary : undefined}
                    disabled={isLoading || isEditingAnyPanel}
                    onClick={addPageModal.open}
                  />
                </Tab>
              ) : (
                null
              )}

              {evaluationPage ? (
                <Tab style={{ ...dividerTabStyle }}>
                  <Divider />
                  {renderPageName(evaluationPage, t)}
                </Tab>
              // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'.
              ) : rfxPermissions.canEditPages && !systemFeatureFlags.evaluationUpdatesEnabled ? (
                <Tab disabled={isLoading || isEditingAnyPanel} style={{ padding: 0, ...buttonTabStyle, ...dividerTabStyle }}>
                  <AddEvaluationButton
                    disabled={isLoading || isEditingAnyPanel}
                    onClick={addEvaluationPage}
                  />
                </Tab>
              ) : (
                null
              )}
            </TabList>
          )}
        </Box>
        {selectedTabIndex > -1 ? (
          <rfx.StructureProvider structure={rfxStructure}>
            <TabPanels>
              {tabs.map((page: any) => page && '_id' in page ? (
                <TabPanel key={page._id}>
                  <rfx.PageProvider page={page}>
                    <DraftPage />
                  </rfx.PageProvider>
                </TabPanel>
              ) : (
                <TabPanel key={page.key} />
              ))}
            </TabPanels>
          </rfx.StructureProvider>
        ) : (
          <NoPagesPlaceholder />
        )}
      </Tabs>
      <AddPageModal
        isOpen={addPageModal.isOpen}
        onCancel={addPageModal.close}
        onSuccess={onAddPageSuccess}
      />
    </>
  );
};

export const DraftDetailsPageContainer = ({
  rfqId,
  isTemplate = false,
  isRevising = false,
  pageId,
}: {
  rfqId: string;
  isTemplate: boolean;
  isRevising: boolean;
  pageId?: string;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { data: rfxStructure, isLoading, isError, isSuccess } = useDraftRfqStructure({
    rfqId,
    currentCompanyId,
    isTemplate,
  });

  const { data: sizeLimits, status: sizeLimitsStatus } = useRequestSizeLimits({
    rfqId,
    isTemplate,
  });

  // Templates should be editable by all members of a company but the template
  // structure doesn't contain ownership / team membership information in
  // `teamById` which we use to determine page permissions so we provide
  // overrides here
  const rfxOverrides = useMemo(
    (): rfx.UserOverrides => isTemplate ? { isOwner: true, pageRole: PageRole.EDITOR } : {},
    [isTemplate],
  );

  return (
    <rfx.StateProvider
      isRevising={isRevising}
      isTemplate={isTemplate}
    >
      {isLoading || sizeLimitsStatus === 'loading' ? (
        <Box p="20px">
          <Loading />
        </Box>
      ) : isError || sizeLimitsStatus === 'error' ? (
        <Box p="20px">
          <ErrorMessage error={t('errors.unexpected')} />
        </Box>
      ) : isSuccess && sizeLimitsStatus === 'success' && rfxStructure ? (
        <ModelSizeLimitsProvider {...sizeLimits}>
          <rfx.StructureProvider structure={rfxStructure}>
            <rfx.OverridesProvider {...rfxOverrides}>
              <rfx.PageNavigationProvider predicate={page => page.type !== PageType.EVALUATION}>
                <RequestHooksProvider>
                  <DraftDetailsPage pageId={pageId} />
                  {/*
                   // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'. */}
                  <DraftDetailsPageToolbar pageId={pageId} />
                </RequestHooksProvider>
              </rfx.PageNavigationProvider>
            </rfx.OverridesProvider>
          </rfx.StructureProvider>
        </ModelSizeLimitsProvider>
      ) : (
        null
      )}
    </rfx.StateProvider>
  );
};
