import { documentExchangeTypes } from '@deepstream/common/exchangesConfig';
import { ExchangeType, Page } from '@deepstream/common/rfq-utils';
import { createContext, memo, useContext } from 'react';
import { propertyOf } from 'lodash';

export const sizeRelevantExchangeTypes = [
  ExchangeType.LINE_ITEM,
  ExchangeType.QUESTION,
  ExchangeType.EVALUATION_CRITERION,
  ...documentExchangeTypes,
];

export const hasSizeRelevantExchangeType = ({ type, supertype }: { type: ExchangeType; supertype?: string }) =>
  sizeRelevantExchangeTypes.includes(type) || supertype === 'document';

export type ModelSizeLimits = {
  maxComplexity: number;
  maxExchangeDefCount: number;
};

const ModelSizesLimitsContext = createContext<ModelSizeLimits | null>(null);

export const ModelSizeLimitsProvider = memo<{ children: React.ReactNode } & ModelSizeLimits>(({ children, ...props }) => (
  <ModelSizesLimitsContext.Provider value={props}>
    {children}
  </ModelSizesLimitsContext.Provider>
));

export const useModelSizeLimits = () => {
  const modelSizeLimits = useContext<ModelSizeLimits | null>(ModelSizesLimitsContext);
  if (!modelSizeLimits) throw new Error('No model size limit found');
  return modelSizeLimits;
};

export const getSizeRelevantExchangeDefCount = (
  exchangeDefById: Record<string, { type: ExchangeType; supertype?: string }> | { type: ExchangeType; supertype?: string }[],
) => {
  return Object
    .values(exchangeDefById)
    .filter(hasSizeRelevantExchangeType)
    .length;
};

export const getPageExchangeDefs = (sectionById, exchangeDefById, page: Page) => {
  return page.sections
    .map(propertyOf(sectionById))
    .flatMap(section => section.exchangeDefIds.map(propertyOf(exchangeDefById)));
};

export const getSectionExchangeDefs = (exchangeDefById, section: { exchangeDefIds: string[] }) => {
  return section.exchangeDefIds.map(propertyOf(exchangeDefById));
};
