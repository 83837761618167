import { isEmpty } from 'lodash';
import { useMemo } from 'react';

export const useFilteredRowData = (rowData, selectedRequirementGroups: { value: string }[]) => {
  return useMemo(() => {
    if (isEmpty(selectedRequirementGroups)) {
      return rowData;
    }

    const selectedRequirementGroupIds = selectedRequirementGroups
      .map(item => item.value);

    return rowData.filter(({ lotId }) => selectedRequirementGroupIds.includes(lotId));
  }, [rowData, selectedRequirementGroups]);
};
