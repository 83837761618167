import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { QuestionnaireExpiryConfig, QuestionnaireRenewalConfig } from '@deepstream/common/preQual';
import { ExpiryOffset } from './ExpiryOffset';
import { RenewalFrequency } from './RenewalFrequency';

export const ExpiryAndRenewalConfiguration = ({
  value,
}: {
  value: {
    renewalConfig: QuestionnaireRenewalConfig;
    expiryConfig: QuestionnaireExpiryConfig;
  };
}) => (
  <>
    {value.renewalConfig.isRecurring ? (
      <RenewalFrequency value={value.renewalConfig} />
    ) : value.expiryConfig.doesExpire ? (
      <ExpiryOffset value={value.expiryConfig} />
    ) : (
      <EmDash />
    )}
  </>
);
