import * as React from 'react';
import { SectionType } from '@deepstream/common/rfq-utils';

import { ErrorPanel } from './ui/ErrorMessage';
import { LineItemBidSection } from './LineItemBidSection';
import * as rfx from './rfx';
import { ClarificationBidSection } from './modules/RequestMessages/ClarificationBidSection';
import { ChatBidSection } from './modules/RequestMessages/ChatBidSection';
import { DocumentBidSection } from './DocumentBidSection';
import { BulletinsBidSection } from './modules/RequestMessages/BulletinsBidSection';
import { VesselPricingBidSection } from './VesselPricingBidSection';
import { QuestionBidSection } from './QuestionBidSection';

export const BidSection: React.FC = () => {
  const section = rfx.useSection();

  switch (section.type) {
    case SectionType.LINE_ITEMS:
      return <LineItemBidSection />;
    case SectionType.CHAT:
      return <ChatBidSection />;
    case SectionType.CLARIFICATIONS:
      return <ClarificationBidSection />;
    case SectionType.DOCUMENT:
      return <DocumentBidSection />;
    case SectionType.BULLETINS:
      return <BulletinsBidSection />;
    case SectionType.VESSEL_PRICING:
      return <VesselPricingBidSection />;
    case SectionType.QUESTION:
      return <QuestionBidSection />;
    case SectionType.DELIVERY:
    case SectionType.PAYMENT:
      return (
        // TODO we should report this / throw an error instead of showing a message to the user
        <ErrorPanel error={`An error occurred displaying section type: ${section.type}`} />
      );
    case SectionType.INTERNAL_DOCUMENT:
      return (
        // TODO we should report this / throw an error instead of showing a message to the user
        <ErrorPanel error="Internal documents cannot be rendered as a bid section" />
      );
    case SectionType.AUCTION_TERMS:
    case SectionType.AUCTION_LINE_ITEMS:
      return null;
    default:
      return (
        // TODO we should report this / throw an error instead of showing a message to the user
        <ErrorPanel error={`Unknown section type: ${section.type}`} />
      );
  }
};
