import { useMemo } from 'react';
import { reject, find, set, cloneDeep, omit, assign, noop, pick } from 'lodash';
import { useField } from 'formik';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import {
  ExchangeDefinition,
  ExchangeType,
  ExchangeId,
  EvaluationCriterionExchangeDefinition,
  LineItemExchangeDefinition,
  DocumentExchangeDefinition,
  DocumentExchangeSupertype,
  InclusionsExchangeDefinition,
  TermsExchangeDefinition,
  InclusionOption,
  FeesExchangeDefinition,
  FeeType,
  HirePeriodExchangeDefinition,
  QuestionExchangeDefinition,
  QuestionType,
  Draft,
  Live,
  LinkedAuctionLineItemExchangeDefinition,
  StandaloneAuctionLineItemExchangeDefinition,
  isLinkedAuctionLineItemExchangeDef,
  getBasicLineItemFields,
  createUnlinkedEvaluationFields,
  ScoringType,
  getCollationType,
  LineItemsSection,
} from '@deepstream/common/rfq-utils';
import { ContractDocumentExchangeDefinition, ContractProvidedBy, ContractSignatureType, LegacyContractExchangeDefinition } from '@deepstream/common/contract';
import {
  getLinkedAuctionLineItemFields,
  getStandaloneAuctionLineItemFields,
} from '@deepstream/common/rfq-utils/auctionLineItemFields';
import { swap } from '@deepstream/utils/swap';
import { RfxStructure } from '../types';

export const createEvaluationCriterion = (scoringType: ScoringType): EvaluationCriterionExchangeDefinition => {
  const collationType = getCollationType(scoringType);

  return ({
    _id: uuid(),
    type: ExchangeType.EVALUATION_CRITERION,
    fields: createUnlinkedEvaluationFields(collationType),
    description: '',
    maxPoints: undefined as any,
    weight: 1,
  }) as EvaluationCriterionExchangeDefinition;
};

export const createLineItem = (responseTagConfig?: LineItemsSection['responseTagConfig']): LineItemExchangeDefinition => ({
  _id: uuid(),
  type: ExchangeType.LINE_ITEM,
  fields: getBasicLineItemFields(responseTagConfig),
  totalCost: 0,
  description: '',
  quantity: null,
  unit: '',
  targetPrice: null,
  evaluatorFieldCurrency: null,
});

export const createStandaloneAuctionLineItem = (): StandaloneAuctionLineItemExchangeDefinition<Draft> => ({
  _id: uuid(),
  type: ExchangeType.AUCTION_LINE_ITEM,
  fields: getStandaloneAuctionLineItemFields(),
  description: '',
  unit: '',
  quantity: undefined as any,
});

export const createLinkedAuctionLineItem = (lineItem: LineItemExchangeDefinition):
  LinkedAuctionLineItemExchangeDefinition => ({
  _id: uuid(),
  type: ExchangeType.AUCTION_LINE_ITEM,
  fields: getLinkedAuctionLineItemFields(lineItem._id),
  linkedExchangeDefId: lineItem._id,
});

export const createQuestion = (): QuestionExchangeDefinition => ({
  _id: uuid(),
  type: ExchangeType.QUESTION,
  description: '',
  questionType: QuestionType.MULTIPLE_CHOICE,
  isRequired: true,
  options: [''],
  allowCustomOption: false,
});

export const createFee = (vesselId?: string) => (): FeesExchangeDefinition => ({
  _id: uuid(),
  type: ExchangeType.FEES,
  vesselId,
  description: '',
  feeType: FeeType.LUMP_SUM,
});

export const createInformationDocument = (): DocumentExchangeDefinition => ({
  _id: uuid(),
  supertype: DocumentExchangeSupertype.DOCUMENT,
  type: ExchangeType.INFORMATION, // TODO: handle others
  category: '',
  attachments: [],
});

export const createEmptyDocument = (stages: string[] = []): Partial<DocumentExchangeDefinition> => ({
  _id: uuid(),
  // We need to set the supertype for the exchange to be processed by the rfx machine
  supertype: DocumentExchangeSupertype.DOCUMENT,
  category: '',
  attachments: [],
  stages,
});

export const createContract = (): Partial<ContractDocumentExchangeDefinition> => ({
  _id: uuid(),
  type: ExchangeType.CONTRACT,
  description: '',
  attachments: [],
  signatureType: ContractSignatureType.MANUAL,
  providedBy: ContractProvidedBy.BUYER,
});

export const createLegacyContract = (): Partial<LegacyContractExchangeDefinition> => ({
  _id: uuid(),
  type: ExchangeType.LEGACY_CONTRACT,
  description: '',
  attachments: [],
});

export const createInclusion = (): InclusionsExchangeDefinition => ({
  _id: uuid(),
  type: ExchangeType.INCLUSIONS,
  description: '',
  option: InclusionOption.INCLUDED,
});

export const createTerm = (): TermsExchangeDefinition => ({
  _id: uuid(),
  type: ExchangeType.TERMS,
  description: '',
});

export const createDayRate = (hirePeriodId: string) => (): Partial<HirePeriodExchangeDefinition> => ({
  _id: uuid(),
  type: ExchangeType.HIRE_PERIOD,
  hirePeriodId,
  intervalType: 'Firm',
  unit: 'month',
});

export const findAndSet = (array, iteratee, key, value) => {
  const arrayClone = cloneDeep(array);
  const item = find(arrayClone, iteratee);
  if (!item) return arrayClone;
  set(item as any, key, value);
  return arrayClone;
};

export const findAndUpdate = (array, iteratee, updateExchange) => {
  const arrayClone = cloneDeep(array);
  const item = find(arrayClone, iteratee);
  if (!item) return arrayClone;
  updateExchange(item as any);
  return arrayClone;
};

/**
 * Wrapper around formik's `useField` for an array of exchange definitions
 */
export const useExchangeDefsField = <
  TDef extends Partial<ExchangeDefinition<Draft>>,
  TFunc extends (...args: any[]) => TDef,
> (
  fieldName: string,
  createExchangeDef?: TFunc,
  structure?: RfxStructure<Draft>,
) => {
  const { t } = useTranslation();
  const [{ value: exchangeDefs },, helpers] = useField<TDef[]>(fieldName);

  const actions = useMemo(
    () => ({
      addExchangeDef: createExchangeDef
        ? (extension?: Partial<TDef & { file?: File }>) =>
          helpers.setValue([
            ...exchangeDefs,
            { ...createExchangeDef(), ...extension },
          ])
        : noop,

      addExchangeDefs: (newExchangeDefs) =>
        helpers.setValue([...exchangeDefs, ...newExchangeDefs]),

      moveExchangeDef: (index: number, delta: number) =>
        helpers.setValue(swap(exchangeDefs, index, index + delta)),

      moveExchangeDefToPosition: (index: number, position: number) => {
        const newExchangeDefs = [...exchangeDefs];
        const [removed] = newExchangeDefs.splice(index, 1);
        newExchangeDefs.splice(position, 0, removed);
        helpers.setValue(newExchangeDefs);
      },

      removeExchangeDef: (exchangeIndex: number) =>
        helpers.setValue(reject(exchangeDefs, (_, index) => index === exchangeIndex)),

      setExchangeDefIsObsolete: (exchangeId: ExchangeId, isObsolete: boolean) =>
        helpers.setValue(findAndSet(exchangeDefs, { _id: exchangeId }, 'isObsolete', isObsolete)),

      setFeeType: (exchangeId: ExchangeId, feeType: FeeType) =>
        helpers.setValue(findAndSet(exchangeDefs, { _id: exchangeId }, 'feeType', feeType)),

      resetToLiveVersion: (liveVersion: ExchangeDefinition<Live>) =>
        helpers.setValue(findAndUpdate(
          exchangeDefs,
          { _id: liveVersion._id },
          exchangeDef => assign(exchangeDef, omit(liveVersion, ['publishedAt', 'publisherId'])),
        )),

      duplicateExchangeDef: (sourceExchangeDefIndex: number) => {
        const sourceExchangeDef = exchangeDefs[sourceExchangeDefIndex] as TDef;

        const payload = structure && isLinkedAuctionLineItemExchangeDef(sourceExchangeDef as any)
          ? {
            ...pick(structure.exchangeDefById[(sourceExchangeDef as any).linkedExchangeDefId], ['description', 'unit', 'quantity']),
            fields: getStandaloneAuctionLineItemFields(),
            type: ExchangeType.AUCTION_LINE_ITEM,
          }
          : sourceExchangeDef;

        const hasDescription = payload.hasOwnProperty('description');
        const hasCategory = payload.hasOwnProperty('category');

        const newExchangeDef = {
          // always omit links to other exchanges to maintain 1:1 relation
          ...omit(payload, ['linkedExchangeDefId']) as any,
          _id: uuid(),
          category: hasCategory
            ? `${t('general.copyOf')} ${(payload as any).category}`
            : undefined,
          description: hasDescription
            ? `${t('general.copyOf')} ${(payload as any).description}`
            : undefined,
        };

        const newExchangeDefs = [
          ...exchangeDefs.slice(0, sourceExchangeDefIndex + 1),
          newExchangeDef,
          ...exchangeDefs.slice(sourceExchangeDefIndex + 1),
        ];

        helpers.setValue(newExchangeDefs);

        const columnId = hasCategory ? 'category' : 'description';
        const descriptionFieldName = `${fieldName}[${sourceExchangeDefIndex + 1}].${columnId}`;

        // We need to wait for the new exchange def to be added to the DOM
        setTimeout(() => {
          const inputElement = document.querySelector(`[name="${descriptionFieldName}"]`) as HTMLInputElement;

          if (inputElement) {
            inputElement.focus();
          }
        }, 100);
      },
    }),
    [createExchangeDef, exchangeDefs, helpers, fieldName, structure, t],
  );

  return useMemo(
    () => ({
      exchangeDefs,
      nonObsoleteExchangeDefs: reject(exchangeDefs, 'isObsolete'),
      ...actions,
    }),
    [exchangeDefs, actions],
  );
};
