import * as React from 'react';
import * as Sentry from '@sentry/react';
import { Box, Flex } from 'rebass/styled-components';
import { QuestionType, AddressQuestionExchangeDefinition, QuestionExchangeDefinition, QuestionAddressField } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';

import { UnexpectedStateError } from '@deepstream/errors';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { addressFields, contactFields } from '../../../draft/QuestionFields';
import { DisabledInputBox } from '../../../ui/Input';
import { CheckboxFieldBase } from '../../../form/CheckboxField';

const DisabledAddressField = ({
  addressFieldName,
  isChecked,
  fieldMaxWidth,
}: {
  addressFieldName: QuestionAddressField;
  isChecked?: boolean;
  fieldMaxWidth?: React.CSSProperties['maxWidth'];
}) => {
  const { t } = useTranslation();

  return (
    <Flex maxWidth={fieldMaxWidth} alignItems="center">
      <DisabledInputBox flex={1} height="40px" mr="24px">
        {t(`request.question.addressField.${addressFieldName}`)}
      </DisabledInputBox>
      <Box>
        <CheckboxFieldBase
          value={isChecked}
          fieldLabel={t('general.visible')}
          disabled
        />
      </Box>
    </Flex>
  );
};

export const DisabledAddressFields = ({
  exchangeDef,
  fieldMaxWidth,
}: {
  exchangeDef: QuestionExchangeDefinition;
  fieldMaxWidth?: React.CSSProperties['maxWidth'];
}) => {
  const { questionType, visibleFields } = exchangeDef as AddressQuestionExchangeDefinition;

  const checkIsChecked = React.useCallback((field) => {
    return (visibleFields || []).includes(field);
  }, [visibleFields]);

  if (questionType !== QuestionType.ADDRESS) {
    Sentry.captureException(new UnexpectedStateError('[AddressLocation] Wrong question type passed', { questionType }));
    return null;
  }

  return (
    <Flex flexDirection={['column', 'row']} flexWrap="wrap">
      <Box flex={1} mr={['0px', '40px']} mb={[4, 0]}>
        <Stack gap={1}>
          {addressFields.map(addressFieldName => (
            <DisabledAddressField
              key={addressFieldName}
              addressFieldName={addressFieldName}
              isChecked={checkIsChecked(addressFieldName)}
              fieldMaxWidth={fieldMaxWidth}
            />
          ))}
        </Stack>
      </Box>
      <Box flex={1}>
        <Stack gap={1}>
          {contactFields.map(addressFieldName => (
            <DisabledAddressField
              key={addressFieldName}
              addressFieldName={addressFieldName}
              isChecked={checkIsChecked(addressFieldName)}
              fieldMaxWidth={fieldMaxWidth}
            />
          ))}
        </Stack>
      </Box>
    </Flex>
  );
};
