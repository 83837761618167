import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { useApi, wrap } from '../../api';
import { CompanyLogo, CompanyLogoAndName } from '../../CompanyLogo';
import { CompanyNamesTooltip } from '../../ui/TooltipList';

const SupplierToApprove: React.FC<{ companyId: string }> = ({ companyId }) => {
  const api = useApi();

  const { data: company } = useQuery(
    ['publicCompany', { companyId }],
    wrap(api.getPublicCompany),
  );

  return company ? <CompanyLogoAndName company={company} size="xs" /> : null;
};

export const SuppliersToApproveGroup: React.FC<{ companyIds: string[] }> = ({
  companyIds,
}) => {
  const theme = useTheme();
  const { t } = useTranslation('translation');

  return (
    <Flex sx={{ columnGap: '4px' }} alignItems="center">
      <Box display="grid" sx={{ gridTemplateColumns: 'repeat(3, 18px) 1fr' }}>
        {companyIds.slice(0, 4).map((value, index) => (
          <Box
            key={index}
            sx={{ background: theme.colors.white, borderRadius: '4px' }}
          >
            <CompanyLogo key={index} size="xs" companyId={value} mr={0} />
          </Box>
        ))}
      </Box>
      {companyIds.length > 4 && (
        <Text>
          +
          {t('request.stageApprovals.otherSupplierCount', {
            count: companyIds.length - 4,
          })}
        </Text>
      )}
    </Flex>
  );
};

export const SuppliersToApprove: React.FC<{ companyIds: string[] }> = ({
  companyIds,
}) => {
  const { t } = useTranslation('translation');

  return companyIds.length === 1 ? (
    <SupplierToApprove companyId={companyIds[0]} />
  ) : (
    <Tooltip
      content={
        <CompanyNamesTooltip
          title={t('request.stageApprovals.suppliersToApprove')}
          companyIds={companyIds}
        />
      }
    >
      <Box>
        <SuppliersToApproveGroup companyIds={companyIds} />
      </Box>
    </Tooltip>
  );
};
