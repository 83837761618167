import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useApi, wrap } from '../../api';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { LineClamp } from '../../LineClamp';
import { useMarkAsReadNotificationsMutation } from '../Notifications/NotificationsProvider';
import { useGetNotificationData } from '../Notifications/notificationTable';

import { DashboardNoData } from './InfoBoxes';
import { DashboardListItem } from './DashboardListItem';
import { DashboardPanel } from './DashboardPanel';

const useFetchNotifications = () => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId();
  const { data, isLoading, isError } = useQuery(
    ['notifications', {
      companyId: currentCompanyId,
      filter: { seen: false, deleted: false },
      limit: 5,
    }],
    wrap(api.getNotifications),
  );

  return { notifications: data?.notifications ?? [], isLoading, isError };
};

export const UnreadNotifications = ({
  onItemClick,
  onGoToAllClick = noop,
}: {
  onItemClick: (data: { _id: string; navigateOptions?: any; notificationAction?: () => any }) => void;
  onGoToAllClick?: () => void;
}) => {
  const { t } = useTranslation(['dashboard']);
  const [markAsRead] = useMarkAsReadNotificationsMutation();
  const { notifications, isLoading, isError } = useFetchNotifications();
  const getNotificationData = useGetNotificationData();

  return (
    <DashboardPanel
      icon="bell-o"
      title={t('panels.unreadNotifications.heading')}
      isLoading={isLoading}
      isError={isError}
      goToAll={() => onGoToAllClick()}
    >
      {notifications.length === 0 ? (
        <DashboardNoData message={t('panels.unreadNotifications.empty')} />
      ) : notifications.map((notification) => {
        // @ts-expect-error ts(2339) FIXME: Property 'message' does not exist on type '{ message: any; navigateOptions: NavigateOptions<AnyRouter, any, string, any, string> | undefined; notificationAction: any; } | { message: string; navigateOptions: null; notificationAction: null; } | undefined'.
        const { message, navigateOptions, notificationAction } = getNotificationData(notification);

        return navigateOptions || notificationAction ? (
          <DashboardListItem
            key={notification._id}
            onClick={() => {
              markAsRead({ notificationIds: [notification._id] });

              onItemClick({
                _id: notification._id,
                navigateOptions,
                notificationAction,
              });
            }}
          >
            <Icon
              icon="circle"
              color="primary"
              mr={2}
              mt="4px"
              sx={{ fontSize: 1 }}
              fixedWidth
            />
            <LineClamp lines={2}>
              {message}
            </LineClamp>
          </DashboardListItem>
        ) : (
          null
        );
      })}
    </DashboardPanel>
  );
};
