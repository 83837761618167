import { Box, Flex } from 'rebass/styled-components';
import { DataCellProps } from '@deepstream/ui-kit/grid/core/utils';
import { EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { OverflowTooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { getCellValue } from '@deepstream/ui-kit/grid/EditableGrid/getCellValue';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { useExchangeCommentNotifications, Dot } from '../../Exchange/ChatCell';

export const NumCommentsValueCell = ({
  row,
  column,
}: DataCellProps<EditableGridColumn, any, any>) => {
  const value = getCellValue(row.original, column.original);
  const notifications = useExchangeCommentNotifications(row.original);

  const showDot = Boolean(notifications.length);

  return (
    <div className="cell-content">
      <Flex
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: '120px' }}
      >
        <Box mr={1}>
          {showDot && (
            <Dot />
          )}
        </Box>
        <OverflowTooltip content={value}>
          <Truncate>
            {value}
          </Truncate>
        </OverflowTooltip>
      </Flex>
    </div>
  );
};
