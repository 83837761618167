import { useMemo, createContext, useContext } from 'react';
import { matches, overEvery, last } from 'lodash';
import { addSeconds, isAfter, isBefore } from 'date-fns';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils';
import { useCurrentCompanyId } from '../currentCompanyId';
import { Notification } from '../modules/Notifications/types';
import { useNotificationSubject } from '../modules/Notifications/useNotificationSubject';
import { useRfqId } from '../useRfq';
import { ExchangeSnapshot } from '../types';

/**
 * Mark the action's corresponding notification as read when
 * the element comes into view.
 */
export const useRfxActionNotificationSubject = ({
  exchange,
  action,
}: {
  exchange: ExchangeSnapshot;
  action: any;
}) => {
  const rfqId = useRfqId({ required: true });
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const isRecipient = exchange.recipientId === currentCompanyId;

  const notificationFilter = useMemo(
    () => overEvery([
      matches({
        action: NotificationAction.EXCHANGE_REPLY_SENT,
        domain: isRecipient ? NotificationDomain.RFQ_RECEIVED : NotificationDomain.RFQ_SENT,
        to: {
          companyId: currentCompanyId,
        },
        meta: exchange.recipientId ? {
          rfqId,
          recipientId: exchange.recipientId,
          exchangeId: exchange._id,
          actionType: action.type,
        } : {
          rfqId,
          exchangeId: exchange._id,
          actionType: action.type,
        },
      }),
      (notification: Notification) => {
        if (notification.meta.eventId) {
          return notification.meta.eventId === action.eventId;
        }

        // Deprecated comparison mechanism (older notifications do not have an event ID):
        // We need to compare timestamps between the action and the notification. We know
        // the notification is created after the action, but most of the time
        // they'll be relatively close together (ie: 10 seconds).
        const notificationDate = new Date(notification.date);
        const actionDate = new Date(action.date);

        return (
          isAfter(notificationDate, actionDate) &&
          isBefore(notificationDate, addSeconds(actionDate, 10))
        );
      },
    ]) as any,
    [rfqId, action, currentCompanyId, exchange, isRecipient],
  );

  return useNotificationSubject({
    filter: notificationFilter,
    // Due to filtering notifications within 10 seconds of the
    // action, we want to use the one to closest in time to the
    // action (ie: the last one)
    transform: last,
  });
};

type ActionNotificationSubjectFn = ({
  exchange,
  action,
}: {
  exchange: ExchangeSnapshot;
  action: any;
}) => ReturnType<typeof useNotificationSubject>;

// @ts-expect-error ts(2345) FIXME: Argument of type 'null' is not assignable to parameter of type 'ActionNotificationSubjectFn'.
export const ActionNotificationSubject = createContext<ActionNotificationSubjectFn>(null);

export const useActionNotificationSubject = () => {
  const notificationSubject = useContext(ActionNotificationSubject);
  if (!notificationSubject) throw new Error('ActionNotificationSubject is falsy');
  return notificationSubject;
};
