import { Company, Live, RfqStatus } from '@deepstream/common/rfq-utils';
import { compact, intersection, isEmpty, last, noop } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Flex, Heading, Text } from 'rebass/styled-components';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { DropdownMenu, DropdownMenuDivider, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { GridSelectionState, useGridSelection } from '@deepstream/ui-kit/grid/EditableGrid/GridSelectionContext';
import { Bold } from '../../../Bold';
import * as rfx from '../../../rfx';
import { HideColumnsSelectDropdownMenu } from '../../../ui/MultiSelect';
import { SuppliersGridConfig } from './useSuppliersGridConfig';
import { viewConfigById } from './requestSentSuppliersViews';
import { MultiSortDropdown } from '../../../ui/SortDropdown';
import { SUPPLIERS_GRID_FILTER_DROPDOWN_ID, SUPPLIERS_GRID_SORT_DROPDOWN_ID } from './SuppliersGridColumnHeader';
import { SelectGridViewDropdown } from './SelectGridViewDropdown';
import { SuppliersGridActionConfig } from './useSuppliersGridActionConfig';
import { GridFilter, GridFilterDropdown } from '../../../ui/ExchangeDefsGrid/GridFilterDropdown';

const MoreActionDisabledPlaceholder = ({
  isStageApprovalVisible,
}: {
  isStageApprovalVisible?: boolean;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Text
      color={theme.colors.subtext}
      px={3} pb={2}
      fontSize={2}
      sx={{
        whiteSpace: 'normal',
        width: isStageApprovalVisible ? '170px' : '140px',
      }}
    >
      {isStageApprovalVisible ? (
        t('request.suppliersTable.selectSuppliers')
      ) : (
        t('request.suppliersTable.selectOneOrMoreSuppliers')
      )}
    </Text>
  );
};

export const SuppliersTableHeader = ({
  numSelectedRecipients,
  numVisibleRecipients,
  navigateToAddSuppliers = noop,
  canRequestStageApproval,
  suppliersGridConfig,
  suppliersGridActionConfig,
  gridFilters,
}: {
  numSelectedRecipients: number;
  numVisibleRecipients: number;
  navigateToAddSuppliers: () => void;
  canRequestStageApproval: boolean;
  suppliersGridConfig: SuppliersGridConfig;
  suppliersGridActionConfig: SuppliersGridActionConfig[];
  gridFilters: GridFilter<Company>[];
}) => {
  const { t } = useTranslation('translation');
  const theme = useTheme();
  const { status } = rfx.useStructure<Live>();
  const { canAddSuppliers } = rfx.useRfxPermissions();
  const { setGridSelectionState } = useGridSelection();

  const selectedViewId = suppliersGridConfig.view.selectedItems[0].value;
  const defaultColumnIds = intersection(
    viewConfigById[selectedViewId].defaultColumnIds,
    suppliersGridConfig.columns.items.map(item => item._id),
  );

  const isResetColumnsButtonDisabled = (
    suppliersGridConfig.columns.selectedItems.length === defaultColumnIds.length &&
    suppliersGridConfig.columns.selectedItems.every(item => defaultColumnIds.includes(item._id))
  );

  const handleColumnsResetClick = () => {
    const defaultColumnItems = compact(
      defaultColumnIds
        .map(columnId => suppliersGridConfig.columns.items.find(item => item._id === columnId)),
    );

    suppliersGridConfig.columns.onChange(defaultColumnItems);
  };

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
      px={3}
      pt={2}
      pb={3}
      style={{ borderBottom: theme.borders.lightGray }}
    >
      <Flex
        mt={2}
        alignItems="center"
        sx={{ minHeight: '32px' }}
        fontSize={2}
      >
        {numSelectedRecipients > 0 ? (
          <>
            <Trans
              t={t}
              i18nKey="request.suppliersTable.supplierSelectedCount"
              count={numSelectedRecipients}
              components={{ b: <Bold mr="0.3em" /> }}
            />
            <Button
              small
              iconLeft="xmark"
              variant="secondary-transparent-outline"
              ml={3}
              onClick={() => setGridSelectionState(GridSelectionState.NONE)}
            >
              {t('request.suppliersTable.clearSelection')}
            </Button>
          </>
        ) : (
          <Flex alignItems="center" sx={{ minHeight: '32px' }}>
            <SelectGridViewDropdown selectSuppliersGridView={suppliersGridConfig.view} />
            <Heading as="h2" textAlign="left" fontSize={4} fontWeight={500} ml="12px">
              {t('request.suppliersTable.heading')}
              {' '}
              <Text as="span" color="subtext">
                {last(suppliersGridConfig.view.selectedItems)?.label}
              </Text>
            </Heading>
          </Flex>
        )}
      </Flex>
      <Flex
        justifyContent="flex-end"
        mt={2}
      >
        {numSelectedRecipients === 0 && (
          <Box mr={2}>
            <GridFilterDropdown
              id={SUPPLIERS_GRID_FILTER_DROPDOWN_ID}
              gridFilters={gridFilters}
            />
          </Box>
        )}

        {numSelectedRecipients === 0 && (
          <Box mr={2}>
            <MultiSortDropdown
              id={SUPPLIERS_GRID_SORT_DROPDOWN_ID}
              {...suppliersGridConfig.sorting}
            />
          </Box>
        )}

        {numSelectedRecipients === 0 && !isEmpty(suppliersGridConfig.columns.items) && (
          <Box mr={4}>
            <HideColumnsSelectDropdownMenu
              buttonText={t('request.suppliersTable.hideColumn_other')}
              menuWidth={330}
              menuZIndex={151}
              onResetClick={handleColumnsResetClick}
              isResetButtonDisabled={isResetColumnsButtonDisabled}
              {...suppliersGridConfig.columns}
            />
          </Box>
        )}
        {numSelectedRecipients === 0 && canAddSuppliers && status !== RfqStatus.AWARDED && status !== RfqStatus.CLOSED && (
          <Button
            small
            mr={2}
            variant="secondary-outline"
            iconLeft="plus"
            onClick={navigateToAddSuppliers}
          >
            {t('request.suppliersTable.addSuppliers')}
          </Button>
        )}
        <DropdownMenu
          small
          rightAligned
          variant="secondary-outline"
          buttonText={(
            <Box display="inline-flex" style={{ alignItems: 'center' }}>
              {t('general.moreActions')}
            </Box>
          )}
          iconRight="caret-down"
          disabled={numVisibleRecipients === 0 && !canRequestStageApproval}
          menuStyle={{ width: '245px' }}
        >
          {!numSelectedRecipients ? (
            <MoreActionDisabledPlaceholder isStageApprovalVisible={canRequestStageApproval} />
          ) : (
            null
          )}

          {suppliersGridActionConfig.map((config) => {
            if (!config.showInGridHeader) {
              return null;
            }

            if (config.type === 'divider') {
              return (
                <DropdownMenuDivider key={config.id} />
              );
            } else {
              const {
                id,
                label,
                isDisabledInGridHeader,
                gridHeaderDescription,
                icon,
                isIconRegular,
                onSelect,
              } = config;

              return (
                <DropdownMenuItem
                  key={id}
                  disabled={isDisabledInGridHeader}
                  icon={icon}
                  isIconRegular={isIconRegular}
                  onSelect={onSelect}
                >
                  {label}
                  {gridHeaderDescription && (
                    <Text fontSize={1} color="subtext" ml="26px">
                      {gridHeaderDescription}
                    </Text>
                  )}
                </DropdownMenuItem>
              );
            }
          })}
        </DropdownMenu>
      </Flex>
    </Flex>
  );
};
