import { useMemo, useCallback } from 'react';
import { intersection, difference } from 'lodash';

import { Checkbox, CheckboxProps } from '@deepstream/ui-kit/elements/input/Checkbox';
import { useImportQuestionsContext } from '../../ImportQuestionsProvider';

export const CategoryQuestionsCheckbox = ({
  categoryId,
  style,
  label,
}: {
  categoryId: string;
  style?: CheckboxProps['style'];
  label?: CheckboxProps['label'];
}) => {
  const {
    selectedQuestionExchangeDefIds,
    initialExchangeDefIds,
    toggleQuestions,
    exchangeDefQuestionIdsByCategoryId,
  } = useImportQuestionsContext();

  const checkboxState = useMemo(() => {
    const categoryExchangeDefQuestionIds = exchangeDefQuestionIdsByCategoryId[categoryId];

    if (categoryExchangeDefQuestionIds.length === 0) return { checked: false, disabled: true };

    const selectedQuestionsFromCategory = intersection(
      exchangeDefQuestionIdsByCategoryId[categoryId],
      [...selectedQuestionExchangeDefIds, ...initialExchangeDefIds],
    );

    const isDisabled = intersection(categoryExchangeDefQuestionIds, initialExchangeDefIds).length === categoryExchangeDefQuestionIds.length;

    const isChecked = selectedQuestionsFromCategory.length === categoryExchangeDefQuestionIds.length;

    const isIndeterminate = selectedQuestionsFromCategory.length > 0;

    return {
      disabled: isDisabled,
      checked: isChecked,
      indeterminate: isIndeterminate && !isChecked,
    };
  }, [categoryId, exchangeDefQuestionIdsByCategoryId, initialExchangeDefIds, selectedQuestionExchangeDefIds]);

  const handleOnChange = useCallback(() => {
    // Do not touch disabled checkboxes in order to avoid re-adding them
    const exchangeDefIdsToToggle = difference(exchangeDefQuestionIdsByCategoryId[categoryId], initialExchangeDefIds);

    return toggleQuestions(exchangeDefIdsToToggle);
  }, [initialExchangeDefIds, categoryId, exchangeDefQuestionIdsByCategoryId, toggleQuestions]);

  return (
    <Checkbox
      onChange={handleOnChange}
      style={style}
      label={label}
      {...checkboxState}
    />
  );
};
