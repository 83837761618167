import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass/styled-components';
import { ApproximateTotal } from '@deepstream/common/rfq-utils';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { withProps } from '@deepstream/ui-utils/withProps';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { FieldContainer } from '../../form/FieldContainer';
import { HelperText } from '../../form/Field';
import { SelectField } from '../../form/SelectField';
import { MoneyField } from '../../form/MoneyField';
import { DEFAULT_CURRENCY, useCurrencySelectItems } from '../../ui/currencies';
import { CurrencyAmount, CurrencyCodeProvider } from '../../ui/Currency';
import { PropertyList } from '../../PropertyList';
import { nestValues } from '../../nestValues';
import { Validation } from '../../draft/validation';
import { ValidationPropertyRow } from '../../draft/ValidationPropertyRow';
import { ValidationErrorValue } from '../../draft/ValidationErrorValue';
import { useShowValidationErrors, useUpdateContractSummary } from './draftContract';
import { ContractSummaryPanelHeader } from './ContractSummaryPanelHeader';
import { useContractActions, useContractData, useContractState } from './contract';
import { LeavePageModal } from '../../draft/LeavePageModal';

const panelId = 'spendData';

const SpendData = ({ value: spendData }: { value: ApproximateTotal }) => spendData ? (
  <CurrencyCodeProvider code={spendData.currencyCode}>
    <CurrencyAmount value={spendData.amount} />
  </CurrencyCodeProvider>
) : (
  <EmDash />
);

export const ContractSpendDataPanel = () => {
  const { t } = useTranslation(['contracts', 'general']);
  const currencySelectItems = useCurrencySelectItems();
  const contract = useContractData();
  const { stopEditing } = useContractActions();
  // @ts-expect-error ts(2339) FIXME: Property 'editingPanelId' does not exist on type 'ContractStateContextType | undefined'.
  const { editingPanelId, isLive, isTemplatePreview } = useContractState();
  const [updateContractSummary] = useUpdateContractSummary();
  const showValidationErrors = useShowValidationErrors();

  const isEditingOtherPanel = editingPanelId && editingPanelId !== panelId;
  const isEditingThisPanel = editingPanelId && editingPanelId === panelId;

  const properties = useMemo(() => [{
    fieldName: 'spendData',
    name: t('summary.contractValue'),
    value: contract.summary.spendData ?? {},
    Component: nestValues(
      withProps(ValidationErrorValue, { useShowValidationErrors, fieldName: 'spendData.amount' }),
      SpendData,
    ),
  }], [contract, t]);

  const { currencyCode, amount } = contract.summary.spendData || {};

  const initialValues = {
    amount,
    currencyCode: currencyCode || DEFAULT_CURRENCY,
  };

  // If a default currency will be used we need to enable saving the form
  const hasUsedDefaultCurrency = !currencyCode;

  const heading = t('summary.spendData');

  return (
    <Panel
      as="section"
      aria-label={heading}
      sx={{
        opacity: isEditingOtherPanel ? 0.5 : 1,
        boxShadow: isEditingThisPanel ? '0 0 8px 0 rgba(0, 0, 0, 0.3)' : '',
      }}
    >
      <ContractSummaryPanelHeader
        heading={heading}
        panelId={panelId}
        canEdit={!isLive && !isTemplatePreview}
      />
      <PanelDivider />
      {isEditingThisPanel ? (
        <Formik
          validateOnBlur
          enableReinitialize
          initialValues={initialValues}
          validationSchema={
            yup.object().shape({
              spendData: yup.object().shape({
                amount: yup.number().nullable(),
              }),
            })
          }
          onSubmit={async (values) => {
            await updateContractSummary({
              summary: { spendData: values },
            }, {
              onSuccess: stopEditing,
            });
          }}
        >
          {({ isSubmitting, dirty, isValid, values, resetForm }) => (
            <CurrencyCodeProvider code={values.currencyCode || ''}>
              <Form>
                <Flex m={20}>
                  <FieldContainer
                    name="spendData"
                    label={t('summary.contractValue')}
                    width="100%"
                    showAsterisk
                  >
                    <Flex flexDirection="row">
                      <Box width="115px" mr={2}>
                        <SelectField
                          hideError
                          hideLabel
                          menuWidth={300}
                          placement="bottom-start"
                          name="currencyCode"
                          items={currencySelectItems}
                          buttonStyle={{ fontWeight: 500 }}
                          menuZIndex={10}
                        />
                      </Box>
                      <MoneyField
                        symbol
                        required
                        hideLabel
                        name="amount"
                        sx={{ width: 200 }}
                        // quick fix: we can remove setting the inputStyle height once a line height is
                        // set for Input
                        inputStyle={{ height: 40 }}
                      />
                    </Flex>
                    <HelperText text={t('summary.notVisibleToSuppliers')} />
                  </FieldContainer>
                </Flex>
                <PanelDivider />
                <PanelPadding>
                  <Flex justifyContent="flex-end">
                    <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
                    <SaveButton disabled={isSubmitting || (!dirty && !hasUsedDefaultCurrency) || !isValid} />
                  </Flex>
                </PanelPadding>
                <LeavePageModal />
              </Form>
            </CurrencyCodeProvider>
          )}
        </Formik>
      ) : (
        <Validation
          values={{
            spendData: contract.summary.spendData ?? {},
          }}
          schema={showValidationErrors ? (
            yup.object().shape({
              spendData: yup.object().shape({
                amount: yup.number()
                  .nullable()
                  .transform((curr, orig) => orig === '' ? null : curr)
                  .required(t('required', { ns: 'general' })),
              }),
            })
          ) : (
            yup.mixed()
          )}
        >
          <PropertyList
            Row={withProps(
              ValidationPropertyRow,
              { useShowValidationErrors, fieldName: 'spendData.amount' },
            )}
            properties={properties}
          />
        </Validation>
      )}
    </Panel>
  );
};
