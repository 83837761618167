import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Heading, Text } from 'rebass/styled-components';
import { AuctionStatus, ExchangeType, RfqStatus, SavingsCalculationResultByRecipientId, SavingsType } from '@deepstream/common/rfq-utils';
import { compact, isEmpty, mapValues, size, some, sumBy } from 'lodash';
import { withProps } from '@deepstream/ui-utils/withProps';
import { Panel, PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { CurrencyAmount } from '../../../ui/Currency';
import { PropertyList } from '../../../PropertyList';
import * as rfx from '../../../rfx';
import { DraftSectionLabelConfigProvider } from '../../../draft/DraftSectionLabelConfigProvider';
import { SpendPanelHeader } from './SpendPanelHeader';
import { useRfqId, useRfqComputedCostAndSavings } from '../../../useRfq';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { LabeledValue } from '../../../draft/LabeledValue';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import { LoadingPanel } from '../../../ui/Loading';
import { SavingsDiffCalculation } from './SavingsDiffCalculation';

const TotalSavingsCalculation = ({ value }: { value: Record<string, number> }) => {
  const { recipients } = rfx.useStructure();

  const pairs = Object.entries(value);
  const total = sumBy(pairs, ([, value]) => value);

  return (
    <Flex alignItems="center">
      {pairs.map(([recipientId, value], index) => (
        <Fragment key={recipientId}>
          {index !== 0 && '+'}
          <LabeledValue
            label={recipients.find(recipient => recipient._id === recipientId)?.company.name}
            value={<CurrencyAmount showCode value={value} />}
            align="left"
            mr={3}
            ml={index === 0 ? 0 : 3}
          />
        </Fragment>
      ))}
      {pairs.length > 1 && (
        <>
          =
          <Box ml={3} fontWeight={500}>
            <CurrencyAmount showCode value={total} />
          </Box>
        </>
      )}
    </Flex>
  );
};

export const SpecificSavingsPanelContent = ({
  savingsType,
  savings,
  labelWidth = 290,
  Row,
  NoteContainer = Text,
}: {
  savingsType: SavingsType;
  savings: SavingsCalculationResultByRecipientId;
  labelWidth?: number;
  Row?: React.FC<any>;
  NoteContainer?: React.FC<any>;
}) => {
  const { t } = useTranslation();
  const { recipients } = rfx.useStructure();

  const properties = useMemo(() => {
    return compact([
      {
        fieldName: 'description',
        name: t('general.description'),
        value: t(`request.spendAndSavings.specificSaving.${savingsType}.description`),
        labelWidth,
        heightAuto: true,
      },
      {
        fieldName: 'valueADescription',
        name: t('request.spendAndSavings.valueA'),
        value: [SavingsType.SINGLE_RESPONSE_LINE_ITEMS, SavingsType.MULTI_RESPONSE_LINE_ITEMS].includes(savingsType)
          ? `${t(`request.spendAndSavings.specificSaving.${savingsType}.adjustedValueADescription`)}*`
          : t(`request.spendAndSavings.specificSaving.${savingsType}.valueADescription`),
        labelWidth,
        heightAuto: true,
      },
      {
        fieldName: 'valueBDescription',
        name: t('request.spendAndSavings.valueB'),
        value: t(`request.spendAndSavings.specificSaving.${savingsType}.valueBDescription`),
        labelWidth,
        heightAuto: true,
      },
      ...(size(savings) < 2 ? [{
          fieldName: 'calculatedSavings',
          name: t('request.spendAndSavings.calculatedSavings'),
          value: {
            valueA: Object.values(savings || {})[0]?.valueA,
            valueB: Object.values(savings || {})[0]?.valueB,
          },
          Component: withProps(SavingsDiffCalculation, {
            labelA: t('request.spendAndSavings.valueA'),
            labelB: t('request.spendAndSavings.valueB'),
          }),
          labelWidth,
          heightAuto: true,
        }] : [
          ...Object.entries(savings).map(([recipientId, { valueA, valueB }]) => ({
            fieldName: `calculatedSavings-${recipientId}`,
            name: t('request.spendAndSavings.calculatedSavings'),
            description: recipients.find(recipient => recipient._id === recipientId)?.company.name,
            value: {
              valueA,
              valueB,
            },
            Component: withProps(SavingsDiffCalculation, {
              labelA: t('request.spendAndSavings.valueA'),
              labelB: t('request.spendAndSavings.valueB'),
            }),
            labelWidth,
            heightAuto: true,
          })),
          {
            fieldName: 'totalCalculatedSavings',
            name: t('request.spendAndSavings.totalCalculatedSavings'),
            value: mapValues(
              savings,
              value => value.result,
            ),
            Component: TotalSavingsCalculation,
            labelWidth,
            heightAuto: true,
          },
        ]),
    ]);
  }, [t, savingsType, labelWidth, savings, recipients]);

  return (
    <>
      <PropertyList properties={properties} Row={Row} />
      {[SavingsType.SINGLE_RESPONSE_LINE_ITEMS, SavingsType.MULTI_RESPONSE_LINE_ITEMS].includes(savingsType) && (
        <NoteContainer>
          * {t('request.spendAndSavings.adjustedAverageDescription')}
        </NoteContainer>
      )}
    </>
  );
};

const NoteContainer = withProps(Text, { px: '20px', pb: '20px' });

const SpecificSavingsPanel = ({ savingsType, savings }: { savingsType: SavingsType; savings: SavingsCalculationResultByRecipientId }) => {
  const { t } = useTranslation();
  const { status } = rfx.useStructure();

  const { editingPanelId } = rfx.useState();
  const isEditingOtherPanel = editingPanelId;

  const isPanelDisabled = status !== RfqStatus.AWARDED;

  const heading = t(`request.spendAndSavings.specificSaving.${savingsType}.title`);

  return (
    <DraftSectionLabelConfigProvider>
      <Panel
        as="section"
        aria-label={heading}
        mb={20}
        sx={{
          opacity: isEditingOtherPanel || isPanelDisabled ? 0.5 : 1,
        }}
      >
        <SpendPanelHeader
          heading={heading}
          panelId={savingsType}
          isPanelDisabled
        />
        <PanelDivider />
        <SpecificSavingsPanelContent
          savingsType={savingsType}
          savings={savings}
          NoteContainer={NoteContainer}
        />
      </Panel>
    </DraftSectionLabelConfigProvider>
  );
};

export const SpecificSavingsPanels = () => {
  const { t } = useTranslation();
  const { spendAndSavings, status, sectionById, exchangeDefById, auction } = rfx.useStructure();

  const { editingPanelId } = rfx.useState();
  const isEditingOtherPanel = editingPanelId;

  const isPanelDisabled = status !== RfqStatus.AWARDED;
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  // @ts-expect-error ts(18049) FIXME: 'spendAndSavings' is possibly 'null' or 'undefined'.
  const requestHasCalculatedSavingsByType = Boolean(spendAndSavings.calculatedSavingsByType);

  const { data: computedCostAndSavingsResponse, isLoading, isError } = useRfqComputedCostAndSavings({
    rfqId,
    currentCompanyId,
    enabled: status === RfqStatus.AWARDED && !requestHasCalculatedSavingsByType,
  });

  const savingsToShow = useMemo(() => {
    const calculatedSavingsByType = requestHasCalculatedSavingsByType
      // @ts-expect-error ts(18049) FIXME: 'spendAndSavings' is possibly 'null' or 'undefined'.
      ? spendAndSavings.calculatedSavingsByType
      : computedCostAndSavingsResponse?.calculatedSavingsByType || {};

    if (calculatedSavingsByType) {
      return compact([
        SavingsType.SINGLE_RESPONSE_LINE_ITEMS,
        SavingsType.MULTI_RESPONSE_LINE_ITEMS,
        SavingsType.AUCTION_LINE_ITEMS,
      ].map(savingsType => {
        const savings = calculatedSavingsByType[savingsType];

        return savings
          ? { savingsType, savings }
          : null;
      }));
    } else {
      const hasSingleResponseLineItems = some(
        exchangeDefById,
        exchangeDef => (
          exchangeDef.type === ExchangeType.LINE_ITEM &&
          !exchangeDef.isObsolete &&
          // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
          sectionById[exchangeDef.sectionId] &&
          // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
          !sectionById[exchangeDef.sectionId].responseTagConfig
        ),
      );
      const hasMultiResponseLineItems = some(
        exchangeDefById,
        exchangeDef => (
          exchangeDef.type === ExchangeType.LINE_ITEM &&
          !exchangeDef.isObsolete &&
          // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
          Boolean(sectionById[exchangeDef.sectionId]?.responseTagConfig)
        ),
      );
      const hasAuctionLineItems = auction && auction.status !== AuctionStatus.CANCELLED && some(
        exchangeDefById,
        exchangeDef => exchangeDef.type === ExchangeType.AUCTION_LINE_ITEM && !exchangeDef.isObsolete,
      );

      return compact([
        hasSingleResponseLineItems && SavingsType.SINGLE_RESPONSE_LINE_ITEMS,
        hasMultiResponseLineItems && SavingsType.MULTI_RESPONSE_LINE_ITEMS,
        hasAuctionLineItems && SavingsType.AUCTION_LINE_ITEMS,
      ]).map(savingsType => ({
        savingsType,
        savings: calculatedSavingsByType?.[savingsType],
      }));
    }
  }, [
    requestHasCalculatedSavingsByType,
    // @ts-expect-error ts(18049) FIXME: 'spendAndSavings' is possibly 'null' or 'undefined'.
    spendAndSavings.calculatedSavingsByType,
    computedCostAndSavingsResponse?.calculatedSavingsByType,
    exchangeDefById,
    auction,
    sectionById,
  ]);

  return isEmpty(savingsToShow) ? (
    null
  ) : (
    <>
      <Box
        mt={4}
        mb={3}
        sx={{
          opacity: isEditingOtherPanel || isPanelDisabled ? 0.5 : 1,
        }}
      >
        <Heading as="h2" fontSize={5} lineHeight="normal" fontWeight="500">
          {t('request.spendAndSavings.calculatedSpecificSavings')}
        </Heading>
        <Text color="subtext" mt={1}>
          {t('request.spendAndSavings.calculatedSpecificSavingsDescription')}
        </Text>
      </Box>
      {isLoading ? (
        <LoadingPanel />
      ) : isError ? (
        <ErrorPanel error={t('errors.unexpected')} />
      ) : (
        savingsToShow.map(({ savingsType, savings }) => (
          <SpecificSavingsPanel key={savingsType} savingsType={savingsType} savings={savings} />
        ))
      )}
    </>
  );
};
