import { useTranslation } from 'react-i18next';

import { localeFormatFactorAsPercent, localeFormatPrice } from '@deepstream/utils';
import { diffFactor } from '@deepstream/utils/math';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { useCurrentUserLocale } from '../../../../useCurrentUser';
import { SavingsDisplayMode } from './useSelectSavingsDisplayMode';

export const DiffFromStage = ({ value, previousValue, stageIndex, savingsDisplayMode, currencyCode, mt }: {
  value: number;
  previousValue: number;
  stageIndex: number;
  savingsDisplayMode: SavingsDisplayMode;
  mt?: string;
  currencyCode: string;
}) => {
  const { t } = useTranslation('translation');
  const locale = useCurrentUserLocale();

  const factor = diffFactor(previousValue, value);

  return (
    <Truncate
      fontSize="9px"
      fontWeight="normal"
      letterSpacing="0.3px"
      mt={mt}
      sx={{
        textAlign: 'right',
        color: factor === 0 ? (
          'subtext'
        ) : factor > 0 ? (
          'danger'
        ) : (
          'success'
        ),
      }}
    >
      <Icon icon={factor > 0 ? 'arrow-up' : 'arrow-down'} mr={1} />
      {t('request.lineItems.diffFromStage', {
        diff: savingsDisplayMode === 'amount'
          ? localeFormatPrice(Math.abs(value - previousValue), currencyCode, { locale, decimalPlaces: 2 })
          : localeFormatFactorAsPercent(Math.abs(factor), { locale, decimalPlaces: 1 }),
        stageNum: stageIndex + 1,
      })}
    </Truncate>
  );
};
