import { createContext, memo, useContext, useMemo } from 'react';
import { Direction } from './types';

interface FlowContextValue <TStepType = any, TData = any> {
  data: TData;
  submitAndNavigate: (data: Partial<TData> | null, direction: Direction, targetStep?: TStepType) => void;
}

const MultiStepFlowContext = createContext<FlowContextValue | null>(null);

export const MultiStepFlowProvider = memo<{ data: any; submitAndNavigate: any; children: React.ReactNode }>(({
  data,
  submitAndNavigate,
  ...props
}) => {
  const value = useMemo(() => ({
    data,
    submitAndNavigate,
  }), [data, submitAndNavigate]);

  return (
    <MultiStepFlowContext.Provider value={value} {...props} />
  );
});

export function useMultiStepFlowData<TStepType, TData>(a: { required: true }): FlowContextValue<TStepType, TData>;
export function useMultiStepFlowData<TStepType, TData>(): FlowContextValue<TStepType, TData>;
export function useMultiStepFlowData<TStepType, TData>(a: { required: false }): FlowContextValue<TStepType, TData> | null;
export function useMultiStepFlowData <TStepType, TData>({ required = true } = {}) {
  const flowData = useContext<FlowContextValue<TStepType, TData> | null>(MultiStepFlowContext);
  if (required && !flowData) throw new Error('No multi step flow context found');
  return flowData;
}
