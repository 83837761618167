import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { useQueryClient } from 'react-query';
import { Box } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';

import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Modal, ModalHeader, ModalFooter, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';

import { useApi } from '../../api';
import { useToaster } from '../../toast';
import { useMutation } from '../../useMutation';
import { emailStringToArray } from '../../utils';
import { IntegrationDataType } from '../../types';
import { useConfirmDialog } from '../../ui/useModalState';
import { ConfirmRemoveRecordDialog } from '../../ConfirmRemoveRecordDialog';
import { AddOrEditRecordModalBody, RecordModalProps, getInternalCompanyIds, getOtherMappedRecords, useHandleSubmit } from './supplierMapUtils';

type EditRecordModalProps = RecordModalProps & {
  record: any;
};

export const EditRecordModal = ({
  systemId,
  companyId,
  externalSupplierData,
  allowManyToOneMapping,
  record,
  onCancel,
  onSave,
  ...props
}: EditRecordModalProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('integration');
  const toaster = useToaster();
  const api = useApi();

  const [addOrUpdateSupplierMapRecord] = useMutation(
    api.addOrUpdateSupplierMapRecord,
    {
      onSuccess: callAll(
        () => toaster.success(t('toaster.recordUpdatedSuccess')),
        onSave,
      ),
      onError: () => toaster.error(t('toaster.recordUpdatedError')),
      onSettled: () => queryClient.invalidateQueries(['integrationData', {
        companyId,
        type: IntegrationDataType.EXTERNAL_COMPANY_TO_INTERNAL_COMPANY,
      }]),
    },
  );

  const [deleteSupplierMapRecord] = useMutation(
    api.deleteSupplierMapRecord,
    {
      onSuccess: () => toaster.success(t('toaster.recordDeletedSuccess')),
      onError: () => toaster.error(t('toaster.recordDeletedError')),
      onSettled: callAll(
        () => queryClient.invalidateQueries(['integrationData', {
          companyId,
          type: IntegrationDataType.EXTERNAL_COMPANY_TO_INTERNAL_COMPANY,
        }]),
        onCancel,
      ),
    },
  );

  const { confirm, ...dialogProps } = useConfirmDialog();
  const internalCompanyIds = getInternalCompanyIds(externalSupplierData);
  const handleSubmit = useHandleSubmit({
    addOrUpdateSupplierMapRecord,
    companyId,
    systemId,
    internalCompanyIds,
    allowManyToOneMapping,
  });

  return (
    <Modal style={{ content: { width: '500px' } }} {...props}>
      <Formik
        initialValues={{
          externalSupplierId: record.externalSupplierId,
          externalSupplierName: record.externalSupplierName,
          externalSupplierEmails: record.externalSupplierContactEmails?.replace(/,/g, ',\n'),
          companyInput: '',
          company: record.internalCompanyId ? { _id: record.internalCompanyId, name: record.internalCompanyName } : null,
        }}
        validationSchema={
          yup.object().shape({
            externalSupplierId: yup
              .string()
              .notOneOf(
                externalSupplierData?.map(supplierData => supplierData.externalCompanyId)
                  .filter(id => id !== record.externalSupplierId) || [],
                t('errors.externalSupplierIdError'),
              )
              .required(t('general.required', { ns: 'translation' })),
            externalSupplierName: yup
              .string(),
            externalSupplierEmails: yup
              .string()
              .test(
                'emailsFormat',
                t('errors.invalidEmail', { ns: 'translation' }),
                value => emailStringToArray(value)
                  .every(item => yup.string().email().isValidSync(item)),
              ),
          })
        }
        onSubmit={async (values, { setErrors }) => handleSubmit(values, setErrors)}
      >
        {({ isSubmitting, dirty, isValid, values }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              {t('editRecord')}
            </ModalHeader>
            <AddOrEditRecordModalBody
              company={values.company}
              internalCompanyIds={internalCompanyIds.filter(id => id !== record.internalCompanyId)}
              allowManyToOneMapping={allowManyToOneMapping}
              otherMappedRecords={
                getOtherMappedRecords(externalSupplierData, values.company?._id !== record.internalCompanyId ? values.company : null)
              }
            />
            <ModalFooter justifyContent="space-between">
              <Button
                variant="danger-outline"
                onClick={() => confirm(() => deleteSupplierMapRecord({
                  companyId,
                  externalCompanyId: values.externalSupplierId,
                  systemId,
                }))}
                type="button"
              >
                {t('deleteRecord')}
              </Button>
              <Box>
                <CancelButton onClick={onCancel} />
                <Button type="submit" disabled={isSubmitting || !dirty || !isValid}>
                  {t('general.saveChanges', { ns: 'translation' })}
                </Button>
              </Box>
            </ModalFooter>
            <ConfirmRemoveRecordDialog
              record={record}
              {...dialogProps}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
