import { useMemo } from 'react';
import { mapValues } from 'lodash';
import { Approval, ApprovalResponseStatus } from '@deepstream/common/rfq-utils';
import { useLabeledApprovalResponseConfig } from '../../status';

export const useResponsesCountConfig = (approvals: Approval[]) => {
  const requestLabelStatusConfig = useLabeledApprovalResponseConfig();

  return useMemo(() => {
    const pendingCount = approvals.filter((value) => value.status === 'pending').length;
    const approvedCount = approvals.filter((value) => value.status === 'approved').length;
    const rejectedCount = approvals.filter((value) => value.status === 'rejected').length;

    return mapValues(requestLabelStatusConfig, (config) => ({
      ...config,
      count:
        config.status === ApprovalResponseStatus.APPROVED
          ? approvedCount
          : config.status === ApprovalResponseStatus.REJECTED
          ? rejectedCount
          : pendingCount,
    }));
  }, [approvals, requestLabelStatusConfig]);
};
