import { useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';

export const TemplateCount = ({ count }) => {
  const { t } = useTranslation();

  return (
    <Box fontSize={2}>
      {t('requests.templateCount', { count })}
    </Box>
  );
};
