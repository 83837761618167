import { Trans, useTranslation } from 'react-i18next';
import React, { useCallback, useMemo } from 'react';
import { compact, differenceBy, flatten, intersectionBy, isEmpty, sortBy, toLower, values } from 'lodash';
import { Flex, FlexProps, Text, Box } from 'rebass/styled-components';
import { SupplierListType } from '@deepstream/common';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Clamp2 } from '@deepstream/ui-kit/elements/text/Clamp';
import { useQuery } from 'react-query';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { ListConfigFlowData, ListConfigFlowStepType } from '../types';
import * as layout from '../../../Request/Live/lotPagesLayout';
import { StepNavigation2 } from '../../../../ui/MultiStepFlow/StepNavigation';
import { listTypeIconProps } from '../../utils';
import { PropertyList } from '../../../../PropertyList';
import { Bold } from '../../../../Bold';
import { useApi } from '../../../../api';
import { useCurrentCompanyId } from '../../../../currentCompanyId';
import { Loading } from '../../../../ui/Loading';
import { ErrorMessage } from '../../../../ui/ErrorMessage';
import { QuestionnaireRules } from '../../QuestionnaireRules';
import { useSupplierListData } from '../../SupplierListProvider';
import { useUpdateSupplierList } from '../../useUpdateSupplierList';
import { useAddSupplierList } from '../../useAddSupplierList';
import { WordWrap } from '../../../../WordWrap';
import { Direction } from '../../../../ui/MultiStepFlow/types';
import { useMultiStepFlowData } from '../../../../ui/MultiStepFlow/MultiStepFlowContext';

const Row = (props: FlexProps) => (
  <Flex
    fontSize={2}
    width="100%"
    p="12px 0px !important"
    lineHeight={1.3}
    sx={{
      borderBottom: 'secondary',
    }}
    {...props}
  />
);

const RowLabel = ({ children }: { children: React.ReactNode }) => (
  <Box width="200px">
    <Bold>
      {children}
    </Bold>
  </Box>
);

const RowValue = ({ children }: { children: React.ReactNode }) => (
  <Flex flex={1}>
    <Clamp2 lines={1}>
      {children}
    </Clamp2>
  </Flex>
);

const ListDescriptionValue = ({ value }) => {
  return value ? (
    <WordWrap>{value}</WordWrap>
  ) : (
    <EmDash />
  );
};

const GeneralDetailsContent = ({ data }: { data: ListConfigFlowData }) => {
  const { t } = useTranslation();

  const properties = useMemo(
    () => [
      {
        name: t('supplierLists.name'),
        value: data.name,
        heightAuto: true,
      },
      {
        name: t('supplierLists.description'),
        value: data.description,
        Component: ListDescriptionValue,
        heightAuto: true,
      },
      {
        name: t('supplierLists.type'),
        value: (
          <IconText
            icon={listTypeIconProps[data.listType].icon}
            isIconRegular={listTypeIconProps[data.listType].regular}
            text={t(`supplierLists.listType.${data.listType}`)}
          />
        ),
        heightAuto: true,
      },
    ],
    [data, t],
  );

  return (
    <PropertyList properties={properties} Row={Row} />
  );
};

const ListRulesContent = ({ data }: { data: ListConfigFlowData }) => (
  <Stack gap="20px">
    <Text>
      <Trans
        i18nKey="supplierLists.listRulesDescription"
        ns="translation"
        components={{ b: <b /> }}
      />
    </Text>
    <QuestionnaireRules rules={data.rules} />
  </Stack>
);

const sortBySupplierName = (suppliers: { name: string }[]) => sortBy(suppliers, supplier => toLower(supplier.name));

const ReviewSuppliersContent = ({ data }: { data: ListConfigFlowData }) => {
  const { t } = useTranslation();
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const previousSupplierList = useSupplierListData({ required: false });

  const { data: automaticSuppliers, isLoading, isError } = useQuery(
    ['previewSuppliersForRules', { rules: data.rules }],
    () => api.previewSuppliersForListRules({
      companyId: currentCompanyId,
      rules: data.rules.map(({ questionnaireTemplateName, ...rule }) => rule),
    }),
    {
      enabled: data.listType === SupplierListType.AUTOMATIC,
    },
  );

  const supplierChanges = useMemo(
    () => {
      const suppliers = data.listType === SupplierListType.AUTOMATIC ? automaticSuppliers : data.suppliers;

      const added = previousSupplierList
        ? differenceBy(suppliers, previousSupplierList.suppliers, list => list._id)
        : suppliers;
      const removed = previousSupplierList
        // @ts-expect-error ts(2769) FIXME: No overload matches this call.
        ? differenceBy(previousSupplierList.suppliers, suppliers, list => list._id)
        : [];
      const unaffected = previousSupplierList
        // @ts-expect-error ts(2769) FIXME: No overload matches this call.
        ? intersectionBy(previousSupplierList.suppliers, suppliers, list => list._id)
        : [];

      return {
        // @ts-expect-error ts(2345) FIXME: Argument of type '{ _id: string; name: string; }[] | undefined' is not assignable to parameter of type '{ name: string; }[]'.
        added: sortBySupplierName(added),
        removed: sortBySupplierName(removed),
        unaffected: sortBySupplierName(unaffected),
      };
    },
    [data, automaticSuppliers, previousSupplierList],
  );

  return isLoading ? (
    <Row>
      <Loading />
    </Row>
  ) : isError ? (
    <Row>
      <ErrorMessage error={t('supplierLists.configFlow.errors.previewSuppliers')} />
    </Row>
  ) : !isEmpty(flatten(values(supplierChanges))) ? (
    <>
      {!isEmpty(supplierChanges.added) && (
        <Row>
          <RowLabel>
            {t('supplierLists.configFlow.supplierAddedCount', { count: supplierChanges.added.length })}
          </RowLabel>
          <RowValue>
            {supplierChanges.added.map(supplier => supplier.name).join(', ')}
          </RowValue>
        </Row>
      )}
      {!isEmpty(supplierChanges.removed) && (
        <Row>
          <RowLabel>
            {t('supplierLists.configFlow.supplierRemovedCount', { count: supplierChanges.removed.length })}
          </RowLabel>
          <RowValue>
            {supplierChanges.removed.map(supplier => supplier.name).join(', ')}
          </RowValue>
        </Row>
      )}
      {!isEmpty(supplierChanges.unaffected) && (
        <Row>
          <RowLabel>
            {t('supplierLists.configFlow.supplierUnaffectedCount', { count: supplierChanges.unaffected.length })}
          </RowLabel>
          <RowValue>
            {supplierChanges.unaffected.map(supplier => supplier.name).join(', ')}
          </RowValue>
        </Row>
      )}
    </>
  ) : (
    <Row>
      {previousSupplierList ? (
        t('supplierLists.configFlow.noSupplierChanges')
       ) : (
        t('supplierLists.configFlow.noSuppliersAdded')
      )}
    </Row>
  );
};

const ConfirmAndSaveContent = ({
  isEditing,
  isLoading,
  isError,
  onConfirm,
}: {
  isEditing: boolean;
  isLoading: boolean;
  isError: boolean;
  onConfirm: () => void;
}) => {
  const { t } = useTranslation(['translation', 'general']);

  return (
    <Stack gap="20px">
      <Stack gap={1}>
        <Text>
          {isEditing ? (
            t('supplierLists.configFlow.confirmEditDescription1')
          ) : (
            t('supplierLists.configFlow.confirmCreateDescription1')
          )}
        </Text>
        <Text>
          {isEditing ? (
            t('supplierLists.configFlow.confirmEditDescription2')
          ) : (
            t('supplierLists.configFlow.confirmCreateDescription2')
          )}
        </Text>
      </Stack>
      <Flex sx={{ gap: 4 }}>
        <Button type="button" variant="primary" onClick={onConfirm} disabled={isLoading}>
          {isEditing ? (
            t('supplierLists.configFlow.confirmAndSubmit')
          ) : (
            t('supplierLists.configFlow.confirmAndCreateList')
          )}
        </Button>
        {isLoading && (
          <>
            <Icon icon="spinner" spin />
            <Text>
              {t('processing', { ns: 'general' })}
            </Text>
          </>
        )}
      </Flex>
      {isError && (
        <MessageBlock variant="error" mt={0}>
          {isEditing ? (
            t('supplierLists.configFlow.errors.couldNotEditList')
          ) : (
            t('supplierLists.configFlow.errors.couldNotCreateList')
          )}
        </MessageBlock>
      )}
    </Stack>
  );
};

export const ReviewStep = () => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { data, submitAndNavigate } = useMultiStepFlowData<ListConfigFlowStepType, ListConfigFlowData>();
  const useConfirmMutation = data._id ? useUpdateSupplierList : useAddSupplierList;

  const [confirm, { isError, isLoading }] = useConfirmMutation();

  const onConfirm = useCallback(
    () => {
      if (data._id) {
        confirm({
          listId: data._id,
          companyId: currentCompanyId,
          name: data.name,
          description: data.description,
          listType: data.listType,
          rules: data.listType === SupplierListType.AUTOMATIC
            ? data.rules.map(({ questionnaireTemplateName, ...rule }) => rule)
            : [],
          supplierIds: data.suppliers.map(supplier => supplier._id),
        }, {
          onSuccess: () => submitAndNavigate(null, Direction.FORWARD),
        });
      } else {
        (confirm as ReturnType<typeof useAddSupplierList>[0])({
          companyId: currentCompanyId,
          name: data.name,
          description: data.description,
          listType: data.listType,
          rules: data.listType === SupplierListType.AUTOMATIC
            ? data.rules.map(({ questionnaireTemplateName, ...rule }) => rule)
            : [],
          supplierIds: data.suppliers.map(supplier => supplier._id),
        }, {
          onSuccess: () => submitAndNavigate(null, Direction.FORWARD),
        });
      }
    },
    [data, currentCompanyId, confirm, submitAndNavigate],
  );

  const subsections = useMemo(() => {
    return compact([
      {
        id: 'generalDetails',
        heading: t('supplierLists.configFlow.generalDetails'),
        content: <GeneralDetailsContent data={data} />,
      },
      data.listType === SupplierListType.AUTOMATIC && ({
        id: 'listRules',
        heading: t('supplierLists.listRules'),
        content: <ListRulesContent data={data} />,
      }),
      {
        id: 'reviewSuppliers',
        heading: t('supplierLists.configFlow.reviewSuppliers'),
        content: <ReviewSuppliersContent data={data} />,
      },
      {
        id: 'confirmAndSave',
        heading: data._id
          ? t('supplierLists.configFlow.confirmAndSubmit')
          : t('supplierLists.configFlow.confirmAndCreateList'),
        content: (
          <ConfirmAndSaveContent
            isEditing={Boolean(data._id)}
            onConfirm={onConfirm}
            isLoading={isLoading}
            isError={isError}
          />
        ),
      },
    ]);
  }, [t, data, onConfirm, isLoading, isError]);

  return (
    <layout.ContentWrapper2>
      <StepNavigation2<ListConfigFlowStepType, ListConfigFlowData>
        showBackButton={!isLoading}
      >
        <layout.Section2 heading={t('supplierLists.configFlow.reviewAndConfirm')}>
          <Stack gap="40px" mt="40px">
            <layout.Subsection2 heading={t('supplierLists.configFlow.contents')}>
              <layout.Ul style={{ marginTop: '12px' }} listStyleType="—  ">
                {subsections.map(({ id, heading }) => (
                  <layout.IndexListItem key={id} id={id} heading={heading} />
                ))}
              </layout.Ul>
            </layout.Subsection2>
            {subsections.map(({ id, heading, content }) => (
              <layout.Subsection2 key={id} id={id} heading={heading}>
                {content}
              </layout.Subsection2>
            ))}
          </Stack>
        </layout.Section2>
      </StepNavigation2>
    </layout.ContentWrapper2>
  );
};
