import { createRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Text } from 'rebass/styled-components';
import { useRouter } from '@tanstack/react-router';
import { useQueryClient } from 'react-query';
import { isEmpty } from 'lodash';
import { DEFAULT_PANEL_PADDING, Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { callAll } from '@deepstream/utils/callAll';
import { BORDER_ADJUSTMENT, DEFAULT_FROZEN_HEADER_HEIGHT } from '@deepstream/ui-kit/grid/core/constants';
import { DEFAULT_ROW_HEIGHT } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { getScrollbarSize } from '@deepstream/ui-utils/getScrollbarSize';
import { SuppliersGrid } from './SuppliersGrid';
import { SupplierListTabs } from './SupplierListTabs';
import { SupplierListTab } from './utils';
import { useSupplierListData } from './SupplierListProvider';
import { Bold } from '../../Bold';
import { SupplierSearchBox } from '../RequestSuppliers/SupplierSearchBox';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useMutation } from '../../useMutation';
import { useApi } from '../../api';
import { getExtendedSupplierListsQueryKey, getSupplierListsQueryKey } from './useSupplierLists';
import { getSupplierListQueryKey } from './useSupplierList';
import { useToaster } from '../../toast';
import { Pagination } from '../../Pagination';
import { useIsAuthorizedStakeholder } from '../../useIsAuthorizedStakeholder';

const tabId = SupplierListTab.SUPPLIERS;
const initialPageSize = 10;

export const SuppliersTab = () => {
  const { t } = useTranslation();
  const supplierList = useSupplierListData();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const router = useRouter();
  const supplierSearchRef = createRef<HTMLInputElement>();
  const isAuthorizedStakeholder = useIsAuthorizedStakeholder();

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(initialPageSize);

  const [addSupplierListCompany] = useMutation(api.addSupplierListCompany, {
    onSuccess: () => toaster.success(t('supplierLists.toaster.addSupplierSuccess')),
    onError: () => toaster.error(t('supplierLists.toaster.addSupplierError')),
    onSettled: callAll(
      () => queryClient.invalidateQueries(getSupplierListsQueryKey(currentCompanyId)),
      () => queryClient.invalidateQueries(getExtendedSupplierListsQueryKey(currentCompanyId)),
      () => queryClient.invalidateQueries(getSupplierListQueryKey(currentCompanyId, supplierList._id)),
      () => router.invalidate(),
    ),
  });

  const isListEmpty = isEmpty(supplierList.suppliers);
  const isAutomatic = !isEmpty(supplierList.rules);
  const numPages = Math.ceil(supplierList.supplierIds.length / pageSize);

  const numVisibleRows = supplierList.supplierIds.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize).length;
  const numEmptyRows = pageSize - numVisibleRows;

  const maxGridHeight = (
    DEFAULT_FROZEN_HEADER_HEIGHT +
    BORDER_ADJUSTMENT +
    DEFAULT_ROW_HEIGHT * (numVisibleRows || 0) +
    getScrollbarSize()
  );

  const gridContainerHeight = (
    maxGridHeight +
    numEmptyRows * DEFAULT_ROW_HEIGHT +
    2 * DEFAULT_PANEL_PADDING
  );

  return (
    <Stack gap="20px">
      <SupplierListTabs selectedTab={tabId} />
      <Panel>
        <PanelHeader
          height="68px"
          heading={!isListEmpty ? t('supplierLists.supplierCount', { count: supplierList.supplierIds.length }) : ''}
        >
          {isAutomatic ? (
            <Flex alignItems="center" color="subtext" style={{ gap: '5px' }}>
              <Icon icon="bolt-auto" regular />
              {t('supplierLists.automaticList')}
              <Tooltip content={t('supplierLists.automaticListTooltip')}>
                <Icon icon="info-circle" regular />
              </Tooltip>
            </Flex>
          ) : !isAuthorizedStakeholder ? (
            <Flex alignItems="center">
              <Text mr={2}>{t('supplierLists.addSupplierToList')}</Text>
              <Box width="272px">
                <SupplierSearchBox
                  ref={supplierSearchRef}
                  companyIdsToExclude={[currentCompanyId]}
                  companyIdsAlreadySelected={supplierList.supplierIds}
                  placeholder={t('supplierLists.searchBySupplierName')}
                  onResultClick={({ value }) => {
                    addSupplierListCompany({
                      companyId: currentCompanyId,
                      supplierListId: supplierList._id,
                      addedCompanyId: value._id,
                    });

                    supplierSearchRef.current?.focus();
                  }}
                />
              </Box>
            </Flex>
          ) : null}
        </PanelHeader>
        <PanelDivider />
        {!isListEmpty ? (
          <>
            <PanelPadding height={gridContainerHeight}>
              <GridIdPrefixProvider>
                <GridMenuStateProvider>
                  <SuppliersGrid
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    maxGridHeight={maxGridHeight}
                  />
                </GridMenuStateProvider>
              </GridIdPrefixProvider>
            </PanelPadding>
            <Pagination
              small
              pageIndex={pageIndex}
              pageSize={pageSize}
              canPreviousPage={pageIndex > 0}
              canNextPage={pageIndex < numPages - 1}
              pageCount={numPages}
              gotoPage={setPageIndex}
              nextPage={() => setPageIndex(pageIndex + 1)}
              previousPage={() => setPageIndex(pageIndex - 1)}
              setPageSize={setPageSize}
            />
          </>
        ) : (
          <Flex alignItems="center" justifyContent="center" height="300px">
            <Bold>
              {t('supplierLists.noSuppliersInList')}
            </Bold>
          </Flex>
        )}
      </Panel>
    </Stack>
  );
};
