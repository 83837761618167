import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { findIndex } from 'lodash';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { SidebarLayout } from '../../ui/ProfileLayout';
import { Tab, TabListPanel, TabPanels, TabPanel, Tabs } from '../../ui/TabsVertical';
import { FormattingPanel } from './FormattingPanel';
import { SupportContactPanel } from './SupportContactPanel';
import { EmailNotificationsPanel } from './EmailNotificationsPanel';
import { ProfilePanel } from './ProfilePanel';
import { CompanyPanel } from './CompanyPanel';
import { AccountPanel } from './AccountPanel';
import * as Api from './Api';
import { LanguageAndRegionPanel } from './LanguageAndRegion';
import { useNavigate, useSearch } from '../../tanstackRouter';
import { userProfileRoute } from '../../AppRouting';

export type UserProfileTabId = 'Account' | 'Companies' | 'Notifications' | 'Preferences';

type UserProfileTab = {
  id: UserProfileTabId;
  name: string;
  iconProps?: IconProps;
  Component: () => React.ReactElement;
};

// NB There's at least one place (`sendEmail` job) where we
// use one of the tab IDs below (in that case, 'Notifications').
// If we want to turn the tab IDs into snake case, we need to
// make sure that all links to them still work.
const useTabs = (): UserProfileTab[] => {
  const { t } = useTranslation();

  return React.useMemo(() => [
    {
      id: 'Account',
      name: t('user.profile.account'),
      Component: () => (
        <Stack gap={3}>
          <ProfilePanel />
          <AccountPanel />
          <SupportContactPanel />
        </Stack>
      ),
    },
    {
      id: 'Companies',
      name: t('general.companies'),
      Component: () => (
        <Stack gap={3}>
          <Api.GetMyCompanies>
            {companies => (
              companies.map(company => (
                <CompanyPanel key={company._id} company={company} />
              ))
            )}
          </Api.GetMyCompanies>
        </Stack>
      ),
    },
    {
      id: 'Notifications',
      name: t('pageHeader.notifications'),
      Component: () => (
        <Stack gap={3}>
          <EmailNotificationsPanel />
        </Stack>
      ),
    },
    {
      id: 'Preferences',
      name: t('user.profile.preferences'),
      iconProps: { icon: 'cog', regular: true },
      Component: () => (
        <Stack gap={3}>
          <LanguageAndRegionPanel />
          <FormattingPanel />
        </Stack>
      ),
    },
  ], [t]);
};

export const UserProfile = () => {
  const { t } = useTranslation();
  const tabs = useTabs();
  const search = useSearch({ from: userProfileRoute.to });
  const navigate = useNavigate({ from: userProfileRoute.to });

  const getTabIndex = React.useCallback(
    (tabId: UserProfileTabId) => findIndex(tabs, tab => tab.id === tabId),
    [tabs],
  );

  const getTabId = React.useCallback(
    (index: number) => tabs[index].id,
    [tabs],
  );

  const navigateToTab = React.useCallback(
    (tab: UserProfileTabId) => {
      navigate({
        to: userProfileRoute.to,
        params: params => params,
        search: { tab },
      });
    },
    [navigate],
  );

  React.useEffect(
    () => {
      if (!search.tab) {
        navigateToTab(getTabId(0));
      }
    },
    [getTabId, navigate, navigateToTab, search.tab, tabs],
  );

  return (
    <Tabs
      // @ts-expect-error ts(2345) FIXME: Argument of type 'UserProfileTabId | undefined' is not assignable to parameter of type 'UserProfileTabId'.
      index={getTabIndex(search.tab)}
      onChange={index => navigateToTab(getTabId(index))}
    >
      <SidebarLayout
        sidebar={
          <TabListPanel heading={t('pageHeader.userSettings')}>
            {tabs.map(tab => (
              <Tab key={tab.id} data-test={`${tab.id}-tab`}>
                {tab.iconProps && (
                  <Icon mr={1} {...tab.iconProps} />
                )}
                {tab.name}
              </Tab>
            ))}
          </TabListPanel>
        }
        main={
          <TabPanels>
            {tabs.map((tab, index) => (
              <TabPanel key={tab.id} tabIndex={index}>
                <tab.Component />
              </TabPanel>
            ))}
          </TabPanels>
        }
      />
    </Tabs>
  );
};
