import { useContext } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { CollapsibleHeaderContext } from '../../CollapsibleHeaderContext';
import { BulkSubmitContext } from './BulkSubmitContext';

export const BulkSubmitControlsPanel = () => {
  const { isCollapsed: isHeaderCollapsed, toggleCollapsed: toggleHeaderCollapsed } = useContext(CollapsibleHeaderContext);
  const { isLeftCollapsed, isRightCollapsed, setLeftCollapsed, setRightCollapsed } = useContext(BulkSubmitContext);

  return (
    <Flex px={2} py={3} alignItems="center" sx={{ gap: 2 }} bg="lightGray3" height="100%">
      <Button iconLeft="sidebar" variant="secondary-outline" small onClick={() => setLeftCollapsed(!isLeftCollapsed)}>
        Section details
      </Button>

      <Box flex={1} />

      <Button iconLeft="sidebar-flip" variant="secondary-outline" small onClick={() => setRightCollapsed(!isRightCollapsed)}>
        Activity and comments
      </Button>

      <Button iconLeft={isHeaderCollapsed ? 'chevron-down' : 'chevron-up'} variant="secondary-outline" small onClick={() => toggleHeaderCollapsed()}>
        {isHeaderCollapsed ? 'Show menus' : 'Hide menus'}
      </Button>
    </Flex>
  );
};
