import { get, isMatch, has, without, filter, isEmpty, isEqual, partition } from 'lodash';
import { useMemo, useState, useEffect } from 'react';
import * as React from 'react';
import { useFormikContext } from 'formik';
import * as PreQ from '@deepstream/common/legacy-pre-q-utils';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { UploadFn, DownloadFn } from '../ui/types';
import { Element } from './Element'; // eslint-disable-line import/no-cycle

const isVisibleElement = (questionAnswer: any, element: any) =>
  !element.match || isMatch(questionAnswer, element.match);

const ElementList: React.FC<any> = React.memo(({
  parentName,
  answer,
  answerPending,
  error,
  elements,
  visibleElements,
  isLocked,
  isReadOnly,
  upload,
  download,
}) => (
  <>
    {visibleElements.map((element: any) => {
      const elementName = parentName ? `${parentName}.${element.key}` : element.key;
      const elementAnswerPending = answerPending?.[element.key];
      const elementError = error?.[element.key];
      const errorMessage = elementError
        ? typeof elementError === 'string'
          ? elementError
          : elementError.message
        : undefined;

      const elementSiblings = without(elements, element);

      // Get elements which are dependent on this element AND have a value
      // (ie: will be cleared if current element changes)
      const dependentElements = filter(
        elementSiblings,
        elementSibling => Boolean(
          has(elementSibling.match, element.key) &&
          !isEmpty(get(answerPending, elementSibling.key)),
        ),
      );

      const guardedValues = dependentElements
        .map(dependentElement => dependentElement.match![element.key])
        .filter(value => value === elementAnswerPending);

      return (
        <Element
          key={elementName}
          name={elementName}
          element={element}
          answer={answer?.[element.key]}
          error={errorMessage}
          isLocked={isLocked}
          isReadOnly={isReadOnly}
          guardedValues={guardedValues}
          dependentElements={dependentElements}
          upload={upload}
          download={download}
        />
      );
    })}
  </>
));

ElementList.displayName = 'ElementList';

type ElementsProps = {
  parentName?: string;
  elements: PreQ.QuestionElement[];
  answer?: PreQ.Answer;
  isReadOnly?: boolean;
  isLocked?: boolean;
  upload?: UploadFn;
  download?: DownloadFn;
};

export const Elements: React.FC<ElementsProps> = React.memo(({
  parentName = '',
  elements,
  answer = {},
  isLocked,
  isReadOnly,
  upload,
  download,
}) => {
  const { values, errors, setFieldValue } = useFormikContext();

  const nextAnswerPending = get(values, parentName);
  const error = parentName ? get(errors, parentName) : errors;

  const [answerPending, setAnswerPending] = useState(nextAnswerPending);
  useWatchValue(nextAnswerPending, setAnswerPending, isEqual);

  const [visibleElements, hiddenElements] = useMemo(
    () => partition(elements, element => isVisibleElement(answerPending, element)),
    [elements, answerPending],
  );

  useEffect(
    () => {
      for (const element of hiddenElements) {
        const fieldName = parentName ? `${parentName}.${element.key}` : element.key;
        setFieldValue(fieldName, null);
      }
    },
    [parentName, hiddenElements, setFieldValue],
  );

  return (
    <ElementList
      parentName={parentName}
      answer={answer}
      answerPending={answerPending}
      elements={elements}
      error={error}
      isLocked={isLocked}
      isReadOnly={isReadOnly}
      upload={upload}
      download={download}
      visibleElements={visibleElements}
    />
  );
});
