import { useEffect } from 'react';
import * as React from 'react';
import { useQuery } from 'react-query';

import { FilterProps } from '../../filtering';
import { TableData, TableDataContext } from '../../TableDataContext';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { wrap } from '../../api';
import { Notification } from './types';

export type NotificationsQueryData = {
  notifications: Notification[];
  totalItems: number;
};

export const NotificationsTableDataProvider = <
  QueryDataT extends NotificationsQueryData,
  StatusFilterItemT extends Record<string, unknown>,
>({
    queryTag,
    queryFn,
    children,
    pageIndex,
    pageSize,
    statusFilter,
    setPageControls,
  }: {
    queryTag: string;
    queryFn: (...params: any[]) => Promise<QueryDataT>;
    children: React.ReactElement;
    pageIndex,
    pageSize,
    statusFilter: FilterProps<StatusFilterItemT>;
    setPageControls,
}): React.ReactElement<TableData<QueryDataT>> => {
  const companyId = useCurrentCompanyId({ required: true });

  const query = useQuery([queryTag, {
    companyId,
    offset: pageIndex * pageSize,
    limit: pageSize,
    filter: {
      ...statusFilter.getQueryParam(statusFilter.selectedItems),
      deleted: false,
    },
  }], wrap(queryFn), { refetchInterval: 5000 });

  useEffect(() => {
    setPageControls({ pageIndex: 0, pageSize });
  }, [statusFilter.selectedItems, pageSize, setPageControls]);

  const value = React.useMemo(() => ({
    data: query?.data?.notifications ?? [],
    pageCount: Math.ceil((query?.data?.totalItems ?? 0) / pageSize),
    pageSize,
    pageIndex,
    query,
    setPageControls,
  }), [query, pageSize, pageIndex, setPageControls]);

  return (
    <TableDataContext.Provider value={value}>
      {children}
    </TableDataContext.Provider>
  );
};
