import { QuestionnaireStatus } from '@deepstream/common/preQual';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { Bold } from '../../../Bold';
import { LabeledValue } from '../../../draft/LabeledValue';
import { ProgressPercentage } from '../../../ui/ProgressPercentage';
import { useIsRecipient, useQuestionnaireData } from './questionnaireUtils';

const valueProps = { fontWeight: 400 };
const labelProps = { color: 'subtext' };

export const useShowProgressPanel = () => {
  const questionnaire = useQuestionnaireData();
  const isRecipient = useIsRecipient();

  return (
    questionnaire.status === QuestionnaireStatus.IN_PROGRESS &&
    isRecipient
  );
};

export const QuestionnaireProgressPanel = () => {
  const { t } = useTranslation('preQualification');
  const questionnaire = useQuestionnaireData();

  const {
    numRequirements,
    numResolvedRequirements,
    numUnresolvedRequirements,
    numExpiredDocuments,
    onlyExpiredDocumentExchangeResponsesWereReset,
    hadExpiredDocuments,
    isUpdating,
    isBeingRenewed,
    isUpdateCausedByRenewal,
  } = questionnaire;
  const isQuestionnaireComplete = numUnresolvedRequirements === 0;
  const hasExpiredDocuments = numExpiredDocuments > 0;

  const icon = isQuestionnaireComplete && !isBeingRenewed ? 'exclamation-circle' : 'exclamation-triangle';
  const iconColor = isQuestionnaireComplete && !isBeingRenewed ? 'danger' : 'warning';

  return (
    <Panel padded>
      <Flex alignItems="center">
        <Box flex="1 1 auto">
          <Text fontSize={4} lineHeight="normal" mb={1}>
            <Icon icon={icon} color={iconColor} regular={isQuestionnaireComplete} mr={2} />
            <Bold>
              {isBeingRenewed ? (
                t('questionnaire.progress.expired')
              ) : isQuestionnaireComplete ? (
                t('questionnaire.progress.submitForReview')
              ) : isUpdating ? (
                t('questionnaire.progress.actionRequired')
              ) : hasExpiredDocuments ? (
                t('questionnaire.progress.documentExpired')
              ) : (
                t('questionnaire.progress.incomplete')
              )}
            </Bold>
          </Text>
          <Text color="subtext">
            {isUpdateCausedByRenewal ? (
              t('questionnaire.progress.expiredNewVersionDescription')
            ) : isBeingRenewed ? (
              t('questionnaire.progress.expiredDescription')
            ) : isQuestionnaireComplete ? (
              isUpdating ? (
                t('questionnaire.progress.completeUpdatingDescription')
              ) : onlyExpiredDocumentExchangeResponsesWereReset && hadExpiredDocuments ? (
                t('questionnaire.progress.documentExpiredCompleteDescription')
              ) : (
                t('questionnaire.progress.completeDescription')
              )
            ) : (
              isUpdating ? (
                t('questionnaire.progress.incompleteUpdatingDescription')
              ) : hasExpiredDocuments ? (
                t('questionnaire.progress.documentExpiredDescription')
              ) : (
                t('questionnaire.progress.incompleteDescription')
              )
            )}
          </Text>
        </Box>
        <Flex flex="0 0 auto" sx={{ gap: 3 }}>
          <LabeledValue
            align="left"
            label={t('questionnaire.progress.complete')}
            value={(
              <ProgressPercentage progress={numResolvedRequirements / numRequirements} width={14} />
            )}
            valueProps={valueProps}
            labelProps={labelProps}
          />
          <LabeledValue
            align="left"
            label={t('questionnaire.progress.yourActions')}
            value={isQuestionnaireComplete ? (
              numUnresolvedRequirements
            ) : (
              <IconText
                text={numUnresolvedRequirements}
                icon="exclamation-circle"
                iconColor="danger"
              />
            )}
            valueProps={valueProps}
            labelProps={labelProps}
          />
        </Flex>
      </Flex>
    </Panel>
  );
};
