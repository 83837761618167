import { useMemo } from 'react';
import { find, first, flatMap, map, propertyOf, reject } from 'lodash';
import { ExchangeType, PageType } from '@deepstream/common/rfq-utils';
import { ContractExchangeDefinition, ContractPage } from '@deepstream/common/contract/contract';
import { PublishedExchangeDefinition } from '../../types';
import { useContractData, useVisiblePages } from './contract';
import { ExchangeSwitcherTarget } from '../../ExchangeModal/SwitchToExchange';

const orderInitiatorLast = (creatorId: string | null) =>
  (left: PublishedExchangeDefinition, right: PublishedExchangeDefinition) => {
    const leftMatchesPublisher = left.creatorId === creatorId;
    const rightMatchesPublisher = right.creatorId === creatorId;

    if (leftMatchesPublisher === rightMatchesPublisher) {
      return 0;
    } else if (rightMatchesPublisher) {
      return -1;
    } else {
      return 1;
    }
  };

/**
 * Returns exchangeId and pageId of all exchanges to which users can
 * navigate from an exchange modal.
 * The order of the returned objects matches the contract's page,
 * page sections and section exchanges structure.
 */
export const useContractExchangeSwitcherTargets = (): ExchangeSwitcherTarget[] => {
  const contract = useContractData();
  const pages = useVisiblePages();
  // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
  const recipientId = first(contract.recipients)._id;

  return useMemo(() => {
    const filteredPages = reject(
      pages,
      page => page.type && [
        PageType.CONTRACT,
        PageType.CHAT,
      ].includes(page.type),
    ) as ContractPage[];

    const sections = map(
      flatMap(filteredPages, 'sections') as string[],
      propertyOf(contract.sectionById),
    );

    const exchangeDefs: ContractExchangeDefinition[] = flatMap(
      sections,
      section => {
        const sectionExchangeDefs = section.exchangeDefIds
          .map(propertyOf(contract.exchangeDefById))
          .filter((exchangeDef: ContractExchangeDefinition) => exchangeDef.type !== ExchangeType.CURRENCY);

        sectionExchangeDefs.sort(orderInitiatorLast(recipientId));

        return sectionExchangeDefs;
      },
    );

    return map(exchangeDefs, exchangeDef => ({
      exchangeId: exchangeDef._id,
      // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
      pageId: find(
        filteredPages,
        // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
        page => page.sections.includes(exchangeDef.sectionId),
      )._id,
    }));
  }, [pages, contract.sectionById, contract.exchangeDefById, recipientId]);
};
