import { useMemo } from 'react';
import { map, keyBy, compact } from 'lodash';
import { useTranslation } from 'react-i18next';
import { RfxOtherSection } from '@deepstream/common/rfq-utils';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { useRfqExchanges, useRecipientId } from './useRfq';
import { useExchangeColumns } from './modules/Exchange/columns';
import * as rfx from './rfx';
import { Loading } from './ui/Loading';
import { ErrorMessage } from './ui/ErrorMessage';
import { useExchangeNavigation } from './useExchangeModalState';
import { ExchangesGrid } from './modules/Request/Live/ExchangesGrid';

export const QuestionBidSection = () => {
  const { t } = useTranslation();
  const recipientId = useRecipientId();
  const sections = rfx.useSections();
  const section = rfx.useSection<RfxOtherSection>();
  const { openExchange } = useExchangeNavigation();

  const { data: exchanges = [], isSuccess, isError, isLoading } = useRfqExchanges({
    recipientId,
    sectionIds: sections.map(section => section._id),
    enabled: Boolean(sections?.length) && Boolean(recipientId),
    select: exchanges => {
      const exchangeById = keyBy(exchanges, 'def._id');
      return compact(map(section.exchangeDefIds, exchangeId => exchangeById[exchangeId]));
    },
  });

  const exchangeColumns = useExchangeColumns();
  const columns = useMemo(
    () => exchangeColumns.questions(),
    [exchangeColumns],
  );

  return (
    <Panel>
      <PanelHeader heading={section.name} description={section.description} icon="question" />
      <PanelDivider />
      {isLoading ? (
        <PanelPadding>
          <Loading />
        </PanelPadding>
      ) : isError ? (
        <PanelPadding>
          <ErrorMessage error={t('request.question.errors.getQuestions')} />
        </PanelPadding>
      ) : isSuccess ? (
        <PanelPadding>
          <ExchangesGrid
            exchanges={exchanges as any}
            columns={columns}
            onRowClick={openExchange}
          />
        </PanelPadding>
      ) : (
        null
      )}
    </Panel>
  );
};
