import {
  sumBy, values, map, filter, reject, keyBy, uniq,
  get, some, mapValues, partition, isEmpty, isMatch, flatMap,
  omit, isNumber, isString,
} from 'lodash';
import { PreQQuestionnaire } from './pre-q-questionnaire';
import { PreQAnswerSet } from './answer-set';
import {
  CategorySummary,
  Category,
  CategorySummaries,
  Question,
  QuestionElement,
  QuestionElementType,
  TableQuestionElement,
  MoneyQuestionElement,
  Answer,
  AddressQuestionElement,
} from './types';

export class AnsweredPreQQuestionnaire {
  questionnaire: PreQQuestionnaire;
  answerSet: PreQAnswerSet;

  constructor(questionnaire: PreQQuestionnaire, answerSet: PreQAnswerSet) {
    this.questionnaire = questionnaire;
    this.answerSet = answerSet;
  }

  get categories(): Category[] {
    return this.questionnaire.categories;
  }

  get categorySummaries(): CategorySummaries {
    return mapValues(
      keyBy(this.categories, 'key'),
      category => this.getCategorySummary(category.key),
    );
  }

  get numQuestions(): number {
    return this.questionnaire.numQuestions;
  }

  get numCompletedQuestions(): number {
    return sumBy(values(this.categorySummaries), 'numCompleted');
  }

  get isEmpty() {
    return this.answerSet.isEmpty;
  }

  get isIncomplete() {
    return this.numCompletedQuestions < this.numQuestions;
  }

  getQuestion(questionId) {
    return this.questionnaire.getQuestionById(questionId);
  }

  hasAnswer(questionId) {
    return this.answerSet.hasAnswer(questionId);
  }

  getAnswer(questionId) {
    return this.answerSet.getAnswer(questionId);
  }

  getQuestionsByCompletion(categoryId) {
    const category = this.questionnaire.getCategory(categoryId);

    const [complete, incomplete] = partition(category.questions, question => this.isAnswerComplete(question.key));

    return { complete, incomplete };
  }

  getQuestionAnswerPair(questionId): [Question, Answer] {
    return [
      this.getQuestion(questionId),
      this.getAnswer(questionId),
    ];
  }

  getQuestionAnswerPairs(questionIds) {
    return map(questionIds, questionId => this.getQuestionAnswerPair(questionId));
  }

  getCategorySummary(categoryId): CategorySummary {
    const questions = this.questionnaire.getCategoryQuestions(categoryId);
    const questionIds = map(questions, 'key');
    const completedQuestionIds = filter(questionIds, (questionId) => this.isAnswerComplete(questionId));

    return {
      numQuestions: questions.length,
      numCompleted: completedQuestionIds.length,
    };
  }

  getCategoryNumQuestions(categoryId) {
    return this.getCategorySummary(categoryId).numQuestions;
  }

  getCategoryNumCompletedQuestions(categoryId) {
    return this.getCategorySummary(categoryId).numCompleted;
  }

  getCategoryNumIncompleteQuestions(categoryId) {
    return this.getCategoryNumQuestions(categoryId) - this.getCategoryNumCompletedQuestions(categoryId);
  }

  isCategoryComplete(categoryId) {
    const { numCompleted, numQuestions } = this.getCategorySummary(categoryId);
    return numCompleted === numQuestions;
  }

  isAnswerComplete(questionId) {
    const [question, answer] = this.getQuestionAnswerPair(questionId);
    return this.isQuestionAnswerComplete(question, answer);
  }

  private getVisibleElements(question: Question, answer: Answer) {
    return reject(
      question.elements,
      element => element.isOptional || (element.match && !isMatch(answer, element.match)),
    ) as QuestionElement[];
  }

  private isQuestionAnswerComplete(question: Question, answer: Answer) {
    if (question.deprecated) return true;
    if (isEmpty(answer)) return false;

    const visibleElements = this.getVisibleElements(question, answer);
    const hasIncompleteElementAnswers = some(visibleElements, element => !this.isElementAnswerComplete(element, answer));

    return !hasIncompleteElementAnswers;
  }

  private isElementAnswerComplete(element: QuestionElement, answer: Answer): boolean {
    const elementAnswer = get(answer, element.key);

    switch (element.type) {
      case QuestionElementType.TEXTBOX:
      case QuestionElementType.DATE_TIME:
      case QuestionElementType.USER:
      case QuestionElementType.RADIO:
      case QuestionElementType.DROPDOWN:
        return Boolean(elementAnswer);

      case QuestionElementType.LIST:
        return Boolean(elementAnswer && elementAnswer.length) && elementAnswer.every(item =>
          element.elements.every(element =>
            this.isElementAnswerComplete(element, item),
          ),
        );

      case QuestionElementType.ATTACHMENTS:
        return !isEmpty(elementAnswer);

      case QuestionElementType.ADDRESS:
        return this.isAddressElementAnswerComplete(element, elementAnswer);

      case QuestionElementType.MONEY:
        return this.isMoneyElementAnswerComplete(element, elementAnswer);

      case QuestionElementType.TABLE:
        return this.isTableElementAnswerComplete(element, elementAnswer);

      default:
        return false;
    }
  }

  private isAddressElementAnswerComplete(element: AddressQuestionElement, elementAnswer) {
    return (
      elementAnswer &&
      elementAnswer.lineOne &&
      elementAnswer.city &&
      elementAnswer.postcode &&
      elementAnswer.country
    );
  }

  private isMoneyElementAnswerComplete(element: MoneyQuestionElement, elementAnswer) {
    return (
      elementAnswer &&
      elementAnswer.currencyCode &&
      isNumber(elementAnswer.amount)
    );
  }

  private isTableElementAnswerComplete(element: TableQuestionElement, elementAnswer) {
    if (isEmpty(elementAnswer)) {
      return false;
    }

    const isMoneyTable = element.inputType === 'money';

    // A money table requires a currency code to be selected
    if (isMoneyTable && !isString(elementAnswer.currencyCode)) {
      return false;
    }

    const columns = isMoneyTable
      ? Object.keys(omit(elementAnswer, ['currencyCode']))
      : Object.keys(elementAnswer);

    const rows = uniq(flatMap(columns, column => Object.keys(elementAnswer[column] || {})));

    const incompleteValues = [undefined, null];

    for (const column of columns) {
      for (const row of rows) {
        const value = get(elementAnswer, [column, row]);

        if (incompleteValues.includes(value)) {
          return false;
        }
      }
    }

    return true;
  }
}
