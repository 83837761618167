import { useCallback, useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, ModalHeader, ModalBody } from '@deepstream/ui-kit/elements/popup/Modal';
import { Spinner } from '../../ui/Loading';
import { QueryDocument, useDocumentsBatchDownload } from './DocumentsTableDataProvider';

export const DocumentsBatchDownload = ({
  trigger,
  documents,
}: {
  trigger: ({ download } : { download: (() => void) }) => React.ReactNode;
  documents: QueryDocument[];
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const batchDownload = useDocumentsBatchDownload();
  const ids = documents.map((item) => item._id);

  const download = useCallback(async () => {
    setIsLoading(true);
    await batchDownload(ids);
    setIsLoading(false);
  }, [ids, batchDownload]);

  return (
    <>
      {trigger({ download })}
      <Modal isOpen={isLoading}>
        <ModalHeader>
          {t('drivePage.batchDownloadModal.title')}
        </ModalHeader>
        <ModalBody>
          {t('drivePage.batchDownloadModal.content', { count: documents.length })}
          <Spinner ml={2} />
        </ModalBody>
      </Modal>
    </>
  );
};
