import * as Sentry from '@sentry/react';
import { map, reject, forEach, findIndex, cloneDeep } from 'lodash';

import { RecipientSource } from '@deepstream/common/rfq-utils';
import { UnexpectedStateError } from '@deepstream/errors';
import { ReducerAction, SupplierState } from './types';

export const reducer = (state: SupplierState[], action: ReducerAction) => {
  const { type, payload } = action;

  switch (type) {
    case 'add':
      return [...payload, ...state];

    case 'remove':
      return reject(state, (supplier) => supplier.company._id === payload);

    case 'updateUsersSelection':
      return map(state, (supplier) => {
        if (supplier.company._id !== payload.supplierId) return supplier;

        return {
          ...supplier,
          // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
          users: supplier.users.map((user) => ({ ...user, selected: payload.users[user._id] })),
        };
      });

    case 'addInvitations': {
      const newState = cloneDeep(state);

      forEach(payload, (invitation) => {
        const users = map(invitation.users, (user) => ({
          ...user,
          selected: true,
          isPending: true,
        }));

        const supplierIndex = findIndex(
          state,
          (supplier) => supplier.company._id === invitation.company._id,
        );

        if (supplierIndex !== -1) {
          newState[supplierIndex].users.push(...users);
        } else {
          newState.push({
            company: invitation.company,
            users,
            source: RecipientSource.INVITE,
          });
        }
      });

      return newState;
    }

    // Need to be removed after getting rid of Angular
    case 'empty':
      return [];

    default:
      Sentry.captureException(new UnexpectedStateError(`[request suppliers reducer] ${type} action is missing`, { type }));
      return state;
  }
};
