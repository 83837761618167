import * as React from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { useCurrentCompanyId } from '../currentCompanyId';
import { useLocalStorageState } from '../useLocalStorageState';
import { useCurrentUser } from '../useCurrentUser';
import { Switch } from '../ui/Switch';
import { SelectFieldBase } from '../form/SelectField';

const LoadingIndicator = () => {
  const { t } = useTranslation('general');

  return (
    <Flex alignItems="center" ml={4} color="subtext">
      <Icon icon="loader" mr={1} spin />
      {t('loading')}
    </Flex>
  );
};

export const PageFooterBidAutoAdvance = ({
  canAdvanceHorizontally,
  exchangeId,
  recipientId,
  contextId,
  AutoAdvancer,
}: {
  exchangeId: string;
  recipientId?: string;
  canAdvanceHorizontally?: boolean;
  contextId?: string; // rfqId, contractId or questionnaireId
  AutoAdvancer: React.FC<{
    exchangeId: string;
    recipientId?: string;
    advanceMode?: 'vertical' | 'horizontal';
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  }>
}) => {
  const { t } = useTranslation('translation');
  const currentUser = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const [isLoading, setIsLoading] = React.useState(false);

  const [autoAdvance, setAutoAdvance] = useLocalStorageState<{ isEnabled: boolean; autoAdvanceMode: 'vertical' | 'horizontal' }>({
    key: `${currentUser._id}.${currentCompanyId}.${contextId}.bidModalAutoAdvance`,
    defaultValue: () => ({ isEnabled: true, autoAdvanceMode: 'vertical' }),
  });

  useWatchValue(
    autoAdvance.isEnabled,
    (isEnabled) => {
      if (!isEnabled) {
        setIsLoading(false);
      }
    },
  );
  useWatchValue(
    exchangeId,
    () => setIsLoading(false),
  );

  return (
    <Flex alignItems="center" pl={4} sx={{ gap: 2 }}>
      <Switch
        checked={autoAdvance.isEnabled}
        onChange={(isEnabled) => setAutoAdvance({ ...autoAdvance, isEnabled })}
        checkedIcon={false}
        uncheckedIcon={false}
        width={42}
      />
      <Text ml={2} sx={{ lineHeight: 1.7, userSelect: 'none' }}>
        {t('request.exchange.autoAdvance')}
      </Text>

      {canAdvanceHorizontally && autoAdvance.isEnabled && (
        <SelectFieldBase
          hideLabel
          small
          label={t('request.lineItems.currency.buyerCurrency')}
          items={[{ value: 'horizontal', label: t('request.exchange.autoAdvanceMode.horizontal.title') }, { value: 'vertical', label: t('request.exchange.autoAdvanceMode.vertical.title') }]}
          value={autoAdvance.autoAdvanceMode || 'vertical'}
          getItemLabel={item => (
            <div>
              <Text>{item.label}</Text>
              <Text color="subtext" fontSize={1}>{t(`request.exchange.autoAdvanceMode.${item.value}.description`)}</Text>
            </div>
          )}
          getButtonLabel={item => item.label}
          required
          menuWidth={300}
          onChange={(autoAdvanceMode) => setAutoAdvance({ ...autoAdvance, autoAdvanceMode })}
        />
      )}
      <Tooltip content={t('request.exchange.autoAdvanceTooltip')}>
        <Icon icon="info-circle" color="subtext" ml={1} />
      </Tooltip>
      {autoAdvance.isEnabled && (
        <AutoAdvancer exchangeId={exchangeId} recipientId={recipientId} setIsLoading={setIsLoading} advanceMode={autoAdvance.autoAdvanceMode} />
      )}
      {isLoading && (
        <LoadingIndicator />
      )}
    </Flex>
  );
};
