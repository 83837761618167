import * as React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass/styled-components';
import { get, omit, keyBy, filter, keys } from 'lodash';
import { useTranslation } from 'react-i18next';
import { lighten } from 'polished';
import { APP_ADMIN_COMPANY_ID } from '@deepstream/common/constants';
import { MenuLink } from '@reach/menu-button';
import { IntegrationMarketplaceOrigin } from '@deepstream/common';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { DropdownMenu, DropdownMenuItem, DropdownMenuDivider } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { CompanyLogo } from './CompanyLogo';
import { Bold } from './Bold';
import { useCurrentCompanyId } from './currentCompanyId';
import { SwitchCompanyModal } from './SwitchCompanyModal';
import { useModalState } from './ui/useModalState';
import { TaskCounter } from './TaskCounter';
import { CurrentAvatar } from './CurrentAvatar';
import { Badge } from './ui/Badge';
import { CompanySettingsTab } from './modules/CompanySettings/companySettingsConstants';
import { useNavigate } from './tanstackRouter';
import { companySettingsRoute } from './AppRouting';
import { CompanyGroup, useUserFlags } from './UserFlagsContext';

const CurrentCompanyHeader: React.FC<{ name: string }> = ({ name }) => {
  const currentCompanyId = useCurrentCompanyId()!;

  return (
    <Flex px="12px" pb={1} alignItems="center">
      <Box flex="0 0 auto">
        <CompanyLogo size="xs" companyId={currentCompanyId} />
      </Box>
      <Truncate>
        <Bold fontSize={2}>
          {name}
        </Bold>
      </Truncate>
    </Flex>
  );
};

const CurrentUserHeader: React.FC<{ currentUser: any }> = ({ currentUser }) => (
  <Flex px="12px" pt="12px" pb={1} alignItems="center">
    <Box flex="0 0 auto" mr={2}>
      <CurrentAvatar width="xs" />
    </Box>
    <Truncate>
      <Bold fontSize={2}>
        {currentUser.name}
      </Bold>
    </Truncate>
  </Flex>
);

const NavMenuLink = styled(MenuLink)`
  // Override _links styleguide
  text-decoration: none !important;
  color: ${(props) => props.theme.colors.text} !important;
`;

type NavbarDropdownProps = {
  currentUser: any;
  companyRoles: any[];
  switchCompany: (companyId: string) => void;
  navigateToTeam: () => void;
  navigateToCompanySettings: () => void;
  navigateToUserSettings: () => void;
  logout: () => void;
};

export const NavbarDropdown: React.FC<NavbarDropdownProps> = ({
  currentUser,
  companyRoles,
  switchCompany,
  navigateToTeam,
  navigateToCompanySettings,
  navigateToUserSettings,
  logout,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const currentCompanyId = useCurrentCompanyId()!;
  const switchCompanyModal = useModalState();
  const navigate = useNavigate();
  const { belongsToPayingCompany } = useUserFlags();
  const isAppAdmin = get(currentUser, ['roles', APP_ADMIN_COMPANY_ID, 'admin']);

  // Excludes APP_ADMIN_COMPANY_ID
  const companyRoleById = React.useMemo(
    () => omit(
      keyBy(companyRoles, '_id'),
      APP_ADMIN_COMPANY_ID,
    ),
    [companyRoles],
  );

  const numCompanyRoles = React.useMemo(
    () => keys(companyRoleById).length,
    [companyRoleById],
  );

  const otherCompanyRole = React.useMemo(
    () => {
      if (numCompanyRoles !== 2) return null;

      const roles = filter(
        companyRoles,
        companyRole => ![APP_ADMIN_COMPANY_ID, currentCompanyId].includes(companyRole._id),
      );

      return roles[0];
    },
    [numCompanyRoles, currentCompanyId, companyRoles],
  );

  const isAdmin = currentUser.roles[currentCompanyId].admin;

  return (
    <>
      <DropdownMenu
        variant="wrapper"
        aria-label={t('general.toggleMenu')}
        rightAligned
        sx={{
          cursor: 'pointer',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          borderRadius: 0,
          ':hover': {
            backgroundColor: lighten(0.1, theme.colors.darkGray),
          },
          ':focus': { outline: 'none' },
        }}
        popoverProps={{
          style: { zIndex: 999 },
        }}
        menuStyle={{ width: '220px', marginTop: 0, maxHeight: 'none' }}
        wrapperStyle={{ height: '100%' }}
        buttonText={(
          <>
            <Flex
              alignItems="center"
              style={{ display: 'inline-flex', height: '100%' }}
            >
              <Box style={{ position: 'relative', left: '12px' }}>
                <CompanyLogo companyId={currentCompanyId} size="xs" backgroundColor="white" />
              </Box>
              <Box style={{ zIndex: 1, width: '25px' }}>
                <CurrentAvatar
                  width="xs"
                  style={{ boxShadow: 'rgb(0, 0, 0, 0.3) -2px 0px 4px 0px' }}
                />
              </Box>
            </Flex>
            <TaskCounter filter={{ type: 'decideTeamMemberRequest' }} ml={2} />
            <Icon icon="caret-down" ml={2} aria-hidden="true" />
          </>
        )}
        px="15px"
        data-test="navbar-dropdown-button"
      >
        <CurrentCompanyHeader name={companyRoleById[currentCompanyId]?.name} />
        <DropdownMenuItem onSelect={navigateToTeam}>
          <Flex>
            <Truncate style={{ flex: 1 }}>{t('navbar.manageTeam')}</Truncate>
            <TaskCounter filter={{ type: 'decideTeamMemberRequest' }} ml={2} />
          </Flex>
        </DropdownMenuItem>
        {isAdmin && belongsToPayingCompany ? (
          <DropdownMenuItem onSelect={navigateToCompanySettings}>
            <Flex>
              <Truncate style={{ flex: 1 }}>{t('companySettings.companySettings')}</Truncate>
            </Flex>
          </DropdownMenuItem>
        ) : (
          null
        )}
        {isAdmin && belongsToPayingCompany ? (
          <DropdownMenuItem
            onSelect={() =>
              navigate({
                to: companySettingsRoute.to,
                params: { currentCompanyId },
                search: { tab: CompanySettingsTab.MARKETPLACE },
                state: { from: IntegrationMarketplaceOrigin.NAVBAR_DROPDOWN },
              })
            }
          >
            <Flex>
              <Truncate style={{ flex: 1 }}>{t('integrationsMarketplace.integrationsMarketplace')}</Truncate>
              <Badge
                color="warning"
                px={1}
                sx={{
                  fontSize: '9px',
                  fontWeight: 500,
                  height: 16,
                  marginTop: '4px',
                }}
              >
                {t('new', { ns: 'general' })}
              </Badge>
            </Flex>
          </DropdownMenuItem>
        ) : (
          null
        )}
        {numCompanyRoles > 2 ? (
          <DropdownMenuItem onSelect={switchCompanyModal.open}>
            {t('navbar.switchCompany')}
          </DropdownMenuItem>
        ) : numCompanyRoles === 2 ? (
          <DropdownMenuItem onSelect={() => switchCompany(otherCompanyRole._id)}>
            <Truncate>
              {t('navbar.switchTo', { companyName: otherCompanyRole?.name ?? '' })}
            </Truncate>
          </DropdownMenuItem>
        ) : (
          null
        )}
        <NavMenuLink href={`${process.env.NX_ONBOARDING_URL}/find-company`}>
          {t('navbar.joinAnotherCompany')}
        </NavMenuLink>
        <DropdownMenuDivider />
        <CurrentUserHeader currentUser={currentUser} />
        <DropdownMenuItem
          onSelect={navigateToUserSettings}
          data-test="user-settings-menu-item"
        >
          {t('navbar.userSettings')}
        </DropdownMenuItem>
        <DropdownMenuDivider />
        <NavMenuLink target="_blank" href={process.env.NX_HELP_CENTER_URL}>
          {t('navbar.knowledgeCenter')}
        </NavMenuLink>
        <DropdownMenuItem
          onSelect={logout}
          data-test="sign-out-menu-item"
        >
          {t('navbar.signOut')}
        </DropdownMenuItem>
        {isAppAdmin && (
          <>
            <DropdownMenuDivider />
            <NavMenuLink href={process.env.NX_ADMIN_URL}>
              {/* No need to translate this as it's admin-only */}
              Admin client
            </NavMenuLink>
          </>
        )}
      </DropdownMenu>
      <SwitchCompanyModal
        isOpen={switchCompanyModal.isOpen}
        onClose={switchCompanyModal.close}
        companyRoles={companyRoles}
        switchCompany={switchCompany}
      />
    </>
  );
};
