import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { callAll } from '@deepstream/utils/callAll';
import { useApi } from '../../api';
import { useToaster } from '../../toast';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useMutation } from '../../useMutation';
import { getExtendedSupplierListsQueryKey, getSupplierListsQueryKey } from './useSupplierLists';

export const useAddSupplierList = () => {
  const { t } = useTranslation();
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  return useMutation(
    api.addSupplierList,
    {
      onSuccess: () => toaster.success(t('supplierLists.toaster.createListSuccess')),
      onSettled: callAll(
        () => queryClient.invalidateQueries(getSupplierListsQueryKey(currentCompanyId)),
        () => queryClient.invalidateQueries(getExtendedSupplierListsQueryKey(currentCompanyId)),
      ),
    },
  );
};
