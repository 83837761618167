import { useTranslation } from 'react-i18next';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useDraftRfqStructure, RfqIdProvider, useRfqId } from '../useRfq';
import { useCurrentCompanyId } from '../currentCompanyId';
import { Loading } from '../ui/Loading';
import { ErrorMessage } from '../ui/ErrorMessage';
import * as rfx from '../rfx';
import { SummaryPanels } from './SummaryPanels';
import { DraftToolbar } from '../modules/Request/DraftToolbar';

const DraftSummaryToolbar = () => {
  const rfqId = useRfqId();
  const { editingPanelId, isTemplate, isRevising } = rfx.useState();
  const isSuperUserOrOwner = rfx.useIsSuperUserOrOwner();
  const isEditing = Boolean(editingPanelId);

  if (isEditing) {
    return null;
  }

  return (
    <DraftToolbar
      rfqId={rfqId}
      tabId="summary"
      hasUnsavedChanges={false}
      showSubmitButton={false}
      isSuperUserOrOwner={isSuperUserOrOwner}
      isTemplate={isTemplate}
      isRevising={isRevising}
      isRequestValid={true}
      excessSupplierCount={0}
    />
  );
};

export const DraftSummary = ({
  rfqId,
  isRevising,
  isTemplate,
}: {
  rfqId: string;
  isRevising: boolean;
  isTemplate: boolean;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { data: structure, isLoading, isError, isSuccess } = useDraftRfqStructure({
    rfqId,
    currentCompanyId,
    isTemplate,
  });

  return (
    <RfqIdProvider rfqId={rfqId}>
      <rfx.StateProvider
        isRevising={isRevising}
        isTemplate={isTemplate}
      >
        {isLoading ? (
          <PanelPadding>
            <Loading />
          </PanelPadding>
        ) : isError ? (
          <PanelPadding>
            <ErrorMessage error={t('errors.unexpected')} />
          </PanelPadding>
        ) : isSuccess && structure ? (
          <rfx.StructureProvider structure={structure}>
            {isRevising && (
              <MessageBlock variant="info" mb={4} mt={0}>
                {t('request.revisionInfo')}
              </MessageBlock>
            )}
            {isTemplate && (
              <MessageBlock variant="info" mb={20} mt={0}>
                {t('template.templateChangesInfo')}<br />
                {t('template.templateFieldsInfo')}
              </MessageBlock>
            )}
            <SummaryPanels />
            <DraftSummaryToolbar />
          </rfx.StructureProvider>
        ) : (
          null
        )}
      </rfx.StateProvider>
    </RfqIdProvider>
  );
};
