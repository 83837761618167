import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useFormikContext } from 'formik';
import { ModalForm } from '../../ModalForm';
import { TextField } from '../../form/TextField';
import { useContractData } from './contract';
import { useAddContractPage, useDuplicateContractPage } from './draftContract';

const NameField = () => {
  const { t } = useTranslation('general');
  const { submitForm } = useFormikContext();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      submitForm();
    }
  };

  return (
    <TextField
      required
      name="name"
      autoFocus // eslint-disable-line jsx-a11y/no-autofocus
      autoComplete="off"
      label={t('name')}
      onKeyDown={handleKeyDown}
    />
  );
};

export const AddContractPageModal = ({
  isOpen,
  sourcePageId, // used for duplicating a page
  onCancel,
  onSuccess,
}: {
  isOpen: boolean;
  sourcePageId?: string;
  onCancel: () => void;
  onSuccess: (pageId: string) => void;
}) => {
  const { t } = useTranslation(['contracts', 'general']);
  const { pageById } = useContractData();
  const [addPage] = useAddContractPage();
  const [duplicatePage] = useDuplicateContractPage();

  const isDuplicate = Boolean(sourcePageId);

  const initialName = isDuplicate
    ? `${t('copyOf', { ns: 'general' })} ${pageById[sourcePageId || ''].name}`
    : '';

  return (
    <ModalForm
      heading={isDuplicate ? t('details.duplicatePage') : t('details.addPage')}
      initialValues={{
        name: initialName,
      }}
      validationSchema={
        yup.object().shape({
          name: yup.string().trim().required(t('details.errors.nameRequired')),
        })
      }
      disableSubmitIfNotDirty={false}
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={async ({ name }) => {
        if (isDuplicate) {
          await duplicatePage({
            name: name.trim(),
            // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
            sourcePageId,
          }, {
            onSuccess: ({ _id }) => onSuccess(_id),
          });
        } else {
          await addPage({
            name: name.trim(),
          }, {
            onSuccess: ({ _id }) => onSuccess(_id),
          });
        }
      }}
      submitLabel={t('save', { ns: 'general' })}
      style={{ content: { width: '500px' } }}
    >
      <NameField />
      {isDuplicate && (
        <MessageBlock variant="info" mt={0}>
          {t('details.duplicatePageInfo')}
        </MessageBlock>
      )}
    </ModalForm>
  );
};
