import { useCallback, useMemo, useState } from 'react';
import { isNumber, trim } from 'lodash';
import { useQueryClient } from 'react-query';
import { Flex, Heading, Text, Box } from 'rebass/styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { callAll } from '@deepstream/utils/callAll';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelHeader, PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';

import { ExternalSupplier } from '@deepstream/common/rfq-utils';
import { useMutation } from '../../useMutation';
import { useModalState } from '../../ui/useModalState';
import { useApi } from '../../api';
import { IntegrationDataType } from '../../types';
import { ErrorPanel } from '../../ui/ErrorMessage';
import { SupplierMapTable } from './SupplierMapTable';
import { AddRecordModal } from './AddRecordModal';
import { Bold } from '../../Bold';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useCurrentUser } from '../../useCurrentUser';
import { useToaster } from '../../toast';
import { BulkUploadSuppliersModal } from '../../BulkUploadSuppliersModal';
import { useExternalSystems, useIntegrationData } from '../../useExternalSystems';
import { SelectFieldBase } from '../../form/SelectField';
import { matchStringValue } from '../../searchUtils';
import { SearchInput } from '../../ui/Input';

const RecordCount = ({ count }: { count: number | null }) => {
  const { t } = useTranslation('integration');

  return (
    <Box fontSize={2} fontWeight={500}>
      {!isNumber(count) ? (
        null
      ) : (
        t('recordCount', { count })
      )}
    </Box>
  );
};

const BulkUploadButton = ({
  companyId,
  systemId,
  ...props
}: ButtonProps & { companyId: string, systemId: string }) => {
  const { isOpen, open, close } = useModalState();
  const api = useApi();
  const { t } = useTranslation('integration');
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const [addOrUpdateSupplierMapRecord] = useMutation(
    api.addOrUpdateSupplierMapRecord,
    {
      onSuccess: callAll(
        close,
      ),
      onError: () => toaster.error(t('toaster.recordAddedError')),
      onSettled: () => queryClient.invalidateQueries(['integrationData', {
        companyId,
        type: IntegrationDataType.EXTERNAL_COMPANY_TO_INTERNAL_COMPANY,
      }]),
    },
  );

  const onImport = (externalSuppliers) => addOrUpdateSupplierMapRecord({
    companyId,
    companies: externalSuppliers.map(externalSupplier => ({
      externalCompanyId: externalSupplier.externalSupplierId.toString(),
      companyName: externalSupplier.externalSupplierName,
      emails: externalSupplier.externalSupplierContactEmails.replace(/\s/g, ''),
    })),
    systemId,
  });

  const handleOnImport = (externalSuppliers: ExternalSupplier[]) => {
    onImport(externalSuppliers);
    toaster.success(t('bulkUpload.uploadSuccess'));
    close();
  };

  return (
    <>
      <Button small variant="secondary" iconLeft="upload" onClick={open} {...props}>
        {t('bulkUpload.bulkUpload')}
      </Button>

      {isOpen && (
        <BulkUploadSuppliersModal
          isOpen={isOpen}
          onCancel={close}
          onImport={handleOnImport}
        />
      )}
    </>
  );
};

const filterCompanyData = (value: string, items) => {
  const trimmedValue = trim(value);

  return trimmedValue
    ? items.filter(item =>
        matchStringValue(trimmedValue, item.externalSupplierId) || matchStringValue(trimmedValue, item.externalSupplierName))
    : items;
};

export const SupplierMap = () => {
  const { t } = useTranslation('integration');
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();
  const isAdmin = user.roles[currentCompanyId].admin;
  const queryClient = useQueryClient();

  const { data: externalSystems = [] } = useExternalSystems();

  const [selectedExternalSystem, setExternalSystem] = useState(() =>
    externalSystems.length > 0 ? externalSystems[0] : null,
  );
  const externalSystem = selectedExternalSystem || externalSystems[0];

  const { data: integrationData, isError: errorLoadingIntegrationData } = useIntegrationData({
    systemId: externalSystem?.systemId,
    currentCompanyId,
    enabled: isAdmin,
  });

  const addRecordModal = useModalState();
  const [filterQuery, setFilterQuery] = useState('');
  const companyData = useMemo(() => {
    if (!integrationData) {
      return null;
    }

    return Object
      .entries(integrationData.data as Record<string, { internalCompanyId: string, companyName: string }>[])
      .map(([
          externalCompanyId, {
            companyName,
            emails,
            internalCompanyId,
            internalCompanyName,
            externalStatus,
            description,
            address,
            numberOfEmployees,
          },
        ]) => ({
          externalSupplierId: externalCompanyId,
          externalSupplierName: companyName,
          externalSupplierContactEmails: emails,
          internalCompanyId,
          internalCompanyName,
          externalStatus,
          description,
          address,
          numberOfEmployees,
      }));
    },
   [integrationData]);

  const filteredCompanyData = useMemo(() =>
    filterCompanyData(filterQuery, companyData),
    [filterQuery, companyData],
  );
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilterQuery(event.target.value);
    },
    [setFilterQuery],
  );

  const clearSearch = useCallback(
    () => setFilterQuery(''),
    [setFilterQuery],
  );
  const recordCount = integrationData?.data
    ? Object.keys(integrationData.data).length
    : null;

  return isAdmin && externalSystem ? (
    <>
      <Flex justifyContent="space-between" alignItems="center" mb="20px" minHeight="40px">
        <Heading fontSize={6} fontWeight={500} mr={3} flex={1}>
          {t('supplierMap')}
        </Heading>
        <SelectFieldBase
          label={t('externalSystem')}
          items={externalSystems.map(externalSystem => ({
              label: externalSystem.name,
              value: externalSystem._id,
            }))}
          value={externalSystem?._id}
          menuWidth={270}
          buttonStyle={{ fontWeight: 500, width: 270 }}
          disabled={externalSystems.length === 1}
          onChange={externalSystemId => {
              const selectedSystem = externalSystems.find(
                (externalSystem) => externalSystem._id === externalSystemId,
              );
              setExternalSystem(selectedSystem || externalSystems[0]);
              queryClient.invalidateQueries('externalSystems');
            }}
        />
      </Flex>
      <MessageBlock variant="info" mb="20px">
        <Trans i18nKey="supplierMapInfo" values={{ externalSystemName: externalSystem.name }}>
          This is a map to associate suppliers between the external system <Bold>{externalSystem.name}</Bold> and
          <Bold>DeepStream</Bold> to ensure integrations function correctly between these systems
        </Trans>
      </MessageBlock>
      {errorLoadingIntegrationData ? (
        <ErrorPanel error={t('supplierMapError')} />
        ) : (
          <Panel mb={4}>
            <PanelHeader heading={<RecordCount count={recordCount} />}>
              <Flex
                flexWrap="wrap"
                alignItems="center"
                justifyContent="flex-end"
                sx={{ columnGap: 1 }}
              >
                <Box mr={2}>
                  <SearchInput
                    placeholder={t('searchForRecord')}
                    onChange={onChange}
                    initialValue={filterQuery}
                    canClear={Boolean(filterQuery.length)}
                    clearSearch={clearSearch}
                    minHeight={30}
                  />
                </Box>
                <Button small iconLeft="plus" onClick={addRecordModal.open}>
                  {t('newRecord')}
                </Button>
                <BulkUploadButton
                  type="button"
                  ml={2}
                  companyId={currentCompanyId}
                  systemId={integrationData && integrationData.meta.systemId}
                />
              </Flex>
            </PanelHeader>
            <PanelDivider />
            {integrationData ? (
              <SupplierMapTable
                systemId={integrationData.meta.systemId}
                companyData={filteredCompanyData}
                companyId={currentCompanyId}
              />
            ) : (
              null
            )}
          </Panel>
        )}
      <AddRecordModal
        systemId={externalSystem.systemId}
        companyId={currentCompanyId}
        externalSupplierData={integrationData && Object
          .keys(integrationData.data)
          .map(externalCompanyId => ({
            _id: integrationData.data[externalCompanyId]._id,
            externalCompanyId,
            externalCompanyName: integrationData.data[externalCompanyId].companyName,
            internalCompanyId: integrationData.data[externalCompanyId].internalCompanyId,
          }))}
        isOpen={addRecordModal.isOpen}
        onCancel={addRecordModal.close}
        onSave={addRecordModal.close}
        onRequestClose={addRecordModal.close}
        allowManyToOneMapping={externalSystem.allowManyToOneMapping}
      />
    </>
  ) : (
    <>
      <Bold as="div" fontSize={5} mb={3}>
        <Icon icon="eye-slash" mr={2} />
        {t('request.pages.pageNotVisible', { ns: 'translation' })}
      </Bold>
      <Text mb={1}>
        {t('request.pages.pageNotVisible', { ns: 'translation' })}
      </Text>
    </>
  );
};
