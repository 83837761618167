import { useState } from 'react';
import { conforms, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils/types';
import { BidIntentionStatus, BidOutcomeStatus, Live, LotIntentionStatus, RequestInteractivityStatus } from '@deepstream/common/rfq-utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Clamp2 } from '@deepstream/ui-kit/elements/text/Clamp';
import { isBiddingOnLot, isLotInactive } from '@deepstream/common/rfq-utils/lot';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useLiveRfqStructure, useRecipientId, useRfqId } from '../../../useRfq';
import * as rfx from '../../../rfx';
import { NoIntentionToBidModal } from '../../../BidIntentionPanel';
import { useModalState } from '../../../ui/useModalState';
import { RequestRecipientStagesNavigation } from './RequestRecipientStagesNavigation';
import * as lotPagesLayout from '../Live/lotPagesLayout';
import { useNotificationSubject } from '../../Notifications/useNotificationSubject';
import { Notification } from '../../Notifications/types';
import { LoadingPanel } from '../../../ui/Loading';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import { useInvalidateQueryOnMessage } from '../../../useInvalidateQueryOnMessage';
import { RequestRecipientBidStatusTable } from './RequestRecipientBidStatusTable';
import { RequestRecipientMyResponseSection } from './RequestRecipientMyResponseSection';
import { RequestRecipientContinueToStageSection } from './RequestRecipientContinueToStageSection';
import { Disclosure2 } from '../../../ui/Disclosure';
import { Bold } from '../../../Bold';
import { useSendLotIntention } from '../Received/useSendLotIntention';
import { HtmlSectionId } from '../Live/constants';
import { InactiveLotsToggle } from './InactiveLotsToggle';
import { useLotsInfo } from './useLotsInfo';
import { LotRevisionsSection } from './LotRevisionsSection';
import { iconPropsByLotIntentionStatus } from '../Live/iconPropsByLotIntentionStatus';
import { NavigationMenu, NavigationMenuItem } from './NavigationMenu';
import { useRequestRecipientNavigation } from '../../../appNavigation';

const StatusSection = () => {
  const { t } = useTranslation('translation');

  return (
    <lotPagesLayout.Section heading={t('general.status')}>
      <Box mt="6px">
        <RequestRecipientBidStatusTable />
      </Box>
    </lotPagesLayout.Section>
  );
};

const AwardSummarySection = () => {
  const { t } = useTranslation('translation');
  const currentCompanyGroup = rfx.useCurrentCompanyGroup();
  const navigation = useRequestRecipientNavigation();
  const heading = t('request.recipientPage.awardSummary.title');

  return (
    <lotPagesLayout.Section heading={heading}>
      <NavigationMenu heading={heading}>
        <NavigationMenuItem
          icon="trophy"
          isIconRegular
          title={heading}
          body={t(`request.recipientPage.awardSummary.description.${currentCompanyGroup}`)}
          linkProps={navigation.getAwardSummaryLinkProps()}
        />
      </NavigationMenu>
    </lotPagesLayout.Section>
  );
};

const LotsSection = () => {
  const { t } = useTranslation('translation');
  const { lots } = rfx.useStructure<Live>();
  const bid = rfx.useBid();
  const bidIntentionStatus = rfx.useBidIntentionStatus();
  const requestInteractivityStatus = rfx.useRequestInteractivityStatus();
  const currentCompanyGroup = rfx.useCurrentCompanyGroup();
  const [sendLotIntention, { isLoading }] = useSendLotIntention();

  const [showInactiveLots, setShowInactiveLots] = useState<boolean>(false);

  const {
    inactiveLotCount,
    nonObsoleteLotCount,
    biddingCount,
    hasLotWithNoResponse,
    areAllLotsInactive,
  } = useLotsInfo();

  return (
    <lotPagesLayout.Section heading={t('request.lot_other')} id={HtmlSectionId.LOTS}>
      {currentCompanyGroup === 'supplier' ? (
        <>
          {inactiveLotCount > 0 && areAllLotsInactive ? (
            <InactiveLotsToggle
              inactiveLotCount={inactiveLotCount}
              setShowInactiveLots={setShowInactiveLots}
              showInactiveLots={showInactiveLots}
              areAllLotsInactive
            />
          ) : (
            <Text mt="12px">
              {requestInteractivityStatus === RequestInteractivityStatus.LIVE && bidIntentionStatus !== BidIntentionStatus.BIDDING ? (
                t('request.lots.toStartBiddingOnLots')
              ) : (
                <>
                  {t('request.lots.biddingLotCount', {
                    biddingCount,
                    count: nonObsoleteLotCount,
                  })}
                  {hasLotWithNoResponse && ` ${t('request.lots.chooseAnOption')}`}
                </>
              )}
            </Text>
          )}
          <Text mt="12px" pb="6px">
            <Disclosure2
              width="100%"
              summary={t('request.lots.aboutLotsExplainerHeading')}
            >
              {t('request.lots.aboutLotsExplainerBody')}
            </Disclosure2>
          </Text>
        </>
      ) : (
        <Text mt="12px">
          {t('request.lots.buyerBiddingLotCount', {
            biddingCount,
            count: nonObsoleteLotCount,
          })}
        </Text>
      )}
      <lotPagesLayout.OrderedListContainer>
        {lots.map((lot, index) => {
          const lotIntentionStatus = bid.intentionStatusByLotId[lot._id] || LotIntentionStatus.NO_RESPONSE;

          const isInactive = isLotInactive(lot, lotIntentionStatus);

          return showInactiveLots || !isInactive ? (
            <lotPagesLayout.TitledListItem
              key={lot._id}
              title={`${t('request.lot_one')} ${index + 1} – ${lot.name}`}
              titleColor={isInactive ? 'subtext' : 'text'}
              body={
                <Box color={isInactive ? 'subtext' : 'text'}>
                  {lot.isObsolete ? (
                    <Text mb={1}>
                      <Icon fixedWidth mr={1} icon="ban" />
                      <Bold>{t('general.obsolete')}</Bold>
                    </Text>
                  ) : (
                    <Text mb={1}>
                      <Icon fixedWidth mr={1} {...iconPropsByLotIntentionStatus[currentCompanyGroup][lotIntentionStatus]} />
                      <Bold>{t(`request.lots.lotIntentionStatus.${lotIntentionStatus}.status`)}</Bold>
                    </Text>
                  )}
                  <Clamp2 lines={1}>
                    {lot.description}
                  </Clamp2>
                </Box>
              }
              pt={3}
              pb={3}
              contentRight={
                <Flex flexDirection="column" mt={1} ml={4}>
                  {!(
                    requestInteractivityStatus === RequestInteractivityStatus.LIVE &&
                    bidIntentionStatus === BidIntentionStatus.BIDDING &&
                    !lot.isObsolete
                  ) ? (
                    null
                  ) : currentCompanyGroup === 'buyer' ? (
                    null
                  ) : lotIntentionStatus === LotIntentionStatus.NO_RESPONSE ? (
                    <>
                      <Button
                        disabled={isLoading}
                        small
                        iconLeft="check"
                        mb={1}
                        onClick={() => sendLotIntention({ lotId: lot._id, intention: LotIntentionStatus.BIDDING })}
                      >
                        {t('request.intention.startBidding')}
                      </Button>
                      <Button
                        disabled={isLoading}
                        small
                        variant="secondary"
                        iconLeft="xmark"
                        onClick={() => sendLotIntention({ lotId: lot._id, intention: LotIntentionStatus.DECLINED_TO_BID })}
                      >
                        {t('request.suppliersTable.declineToBid')}
                      </Button>
                    </>
                  ) : lotIntentionStatus === LotIntentionStatus.BIDDING ? (
                    <Button
                      disabled={isLoading}
                      small
                      iconLeft="xmark"
                      variant="danger"
                      onClick={() => sendLotIntention({ lotId: lot._id, intention: LotIntentionStatus.BID_WITHDRAWN })}
                    >
                      {t('request.withdrawBid')}
                    </Button>
                  ) : (
                    <Button
                      disabled={isLoading}
                      small
                      iconLeft="check"
                      onClick={() => sendLotIntention({ lotId: lot._id, intention: LotIntentionStatus.BIDDING })}
                    >
                      {t('request.intention.startBidding')}
                    </Button>
                  )}
                </Flex>
              }
            />
          ) : (
            null
          );
        })}
      </lotPagesLayout.OrderedListContainer>
      {inactiveLotCount > 0 && !areAllLotsInactive && (
        <InactiveLotsToggle
          inactiveLotCount={inactiveLotCount}
          setShowInactiveLots={setShowInactiveLots}
          showInactiveLots={showInactiveLots}
        />
      )}
      {currentCompanyGroup === 'supplier' ? (
        <>
          {(!areAllLotsInactive || (showInactiveLots && lots.some(lot => !lot.isObsolete))) && (
            <lotPagesLayout.InfoText>
              {t('request.lots.lotResponseInfo.supplier')}
            </lotPagesLayout.InfoText>
          )}
        </>
      ) : (
        <lotPagesLayout.InfoText>
          {t('request.lots.lotResponseInfo.buyer')}
        </lotPagesLayout.InfoText>
      )}
    </lotPagesLayout.Section>
  );
};

const StagesNavigationSection = () => {
  const { t } = useTranslation('translation');
  const currentCompanyGroup = rfx.useCurrentCompanyGroup();

  return (
    <lotPagesLayout.Section heading={t('general.stage_other')}>
      <Text mt="12px">
        {t(`request.stages.openStageDescription.${currentCompanyGroup}`)}
      </Text>
      <RequestRecipientStagesNavigation />
    </lotPagesLayout.Section>
  );
};

const WithdrawBidSection = () => {
  const { t } = useTranslation('translation');
  const noIntentionToBidModal = useModalState();
  const requestInteractivityStatus = rfx.useRequestInteractivityStatus();
  const { lots } = rfx.useStructure<Live>();
  const bid = rfx.useBid();

  const isBiddingOnAtLeastOneLot = lots.some(lot => isBiddingOnLot(lot, bid.intentionStatusByLotId[lot._id]));

  return (
    <lotPagesLayout.Section heading={t('request.withdrawMyBid')}>
      {requestInteractivityStatus === RequestInteractivityStatus.LIVE ? (
        <>
          <Text mt="12px">
            {t('request.withdrawBidDescription')}
          </Text>
          {isBiddingOnAtLeastOneLot && (
            <Text mt="6px">
              {t('request.withdrawBidLotsDescription')}
            </Text>
          )}
          <Button iconLeft="xmark" variant="danger" onClick={noIntentionToBidModal.open} mt="14px">
            {t('request.withdrawBid')}
          </Button>
          <lotPagesLayout.InfoText>
            {t('request.mayBeUnableToReopen')}
          </lotPagesLayout.InfoText>
          <NoIntentionToBidModal
            isOpen={noIntentionToBidModal.isOpen}
            hasIntentionToBid
            close={noIntentionToBidModal.close}
          />
        </>
      ) : (
        <Text mt="12px">
          {t('request.cannotWithdrawBid')}
        </Text>
      )}
    </lotPagesLayout.Section>
  );
};

export const RequestRecipientBidIndexContent = () => {
  const recipientId = useRecipientId();
  const structure = rfx.useStructure();
  const currentCompanyGroup = rfx.useCurrentCompanyGroup();
  const bidIntentionStatus = rfx.useBidIntentionStatus();
  const bidOutcomeStatus = rfx.useBidOutcomeStatus();
  const { areAllLotsInactive } = useLotsInfo();

  const bid = structure.bidById[recipientId];

  const enteredStageNumber = bid.enteredStageIds.length;
  const activatedStageNumber = bid.activatedStageIds.length;

  return (
    <lotPagesLayout.ContentWrapper>
      <StatusSection />
      {bidOutcomeStatus === BidOutcomeStatus.AWARDED && (
        <AwardSummarySection />
      )}

      {currentCompanyGroup === 'supplier' && (
        <>
          {[
            BidIntentionStatus.NO_RESPONSE,
            BidIntentionStatus.DECLINED_TO_BID,
            BidIntentionStatus.BID_WITHDRAWN,
          ].includes(bidIntentionStatus) && <RequestRecipientMyResponseSection />}

          {bidIntentionStatus === BidIntentionStatus.BIDDING && enteredStageNumber !== activatedStageNumber && (
            <RequestRecipientContinueToStageSection />
          )}
        </>
      )}

      {!isEmpty(structure.lots) && !areAllLotsInactive && (
        <>
          <LotsSection />
          {currentCompanyGroup === 'supplier' && !isEmpty(structure.lotsRevisionHistory) && <LotRevisionsSection />}
        </>
      )}

      <StagesNavigationSection />

      {!isEmpty(structure.lots) && areAllLotsInactive && (
        <>
          <LotsSection />
          {currentCompanyGroup === 'supplier' && !isEmpty(structure.lotsRevisionHistory) && <LotRevisionsSection />}
        </>
      )}

      {currentCompanyGroup === 'supplier' && bidIntentionStatus === BidIntentionStatus.BIDDING && (
        <WithdrawBidSection />
      )}
    </lotPagesLayout.ContentWrapper>
  );
};

/*
* Recipient-specific request pages, level 2.
*
* Renders the "My bid" page.
*/
export const RequestRecipientBidIndex = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const recipientId = useRecipientId();
  const rfqId = useRfqId();
  const { t } = useTranslation('translation');

  const isRecipient = currentCompanyId === recipientId;

  const { data: structure, isLoading, isError, isSuccess, queryKey } =
    useLiveRfqStructure({ rfqId, recipientId, currentCompanyId });

  useInvalidateQueryOnMessage(`rfx.${rfqId}`, queryKey);

  const ref = useNotificationSubject({
    filter: conforms<Partial<Notification>>({
      domain: domain => domain === (isRecipient ? NotificationDomain.RFQ_RECEIVED : NotificationDomain.RFQ_SENT),
      action: action => [
        NotificationAction.RFQ_CREATED,
        NotificationAction.RFQ_CREATED,
        NotificationAction.RFQ_AWARDED,
        NotificationAction.BIDS_REJECTED,
        NotificationAction.BIDS_REINSTATED,
        NotificationAction.RFQ_CLOSED,
        NotificationAction.RFQ_LOST,
        NotificationAction.BID_SENT,
        NotificationAction.EXCHANGE_REPLIES_SENT,
      ].includes(action),
      meta: isRecipient
        ? meta => meta.rfqId === rfqId
        : meta => meta.rfqId === rfqId && meta.recipientId === recipientId,
      to: to => to.companyId === currentCompanyId,
    }),
  });

  return isLoading ? (
    <LoadingPanel />
  ) : isError ? (
    <ErrorPanel error={t('errors.unexpected')} />
  ) : isSuccess && structure ? (
    <div ref={ref}>
      <rfx.StructureProvider structure={structure}>
        <RequestRecipientBidIndexContent />
      </rfx.StructureProvider>
    </div>
  ) : (
    null
  );
};
