import { useContext, useMemo, forwardRef } from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  ChartOptions,
  ScriptableContext,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart } from 'react-chartjs-2';
import { isEmpty, pickBy, some, toUpper } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { localeFormatNumber } from '@deepstream/utils';
import { ONE_MINUTE } from '@deepstream/common/constants';
import * as rfx from '../../../rfx';
import { generateColor, lighten30 } from '../../../utils';
import { useCurrentUserLocale } from '../../../useCurrentUser';

ChartJS.register(
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  annotationPlugin,
);

enum Order {
  FRONT = 0,
  DEFAULT = 1,
}

type ExtendedLineChartContext = ScriptableContext<'line'> & {
  xAnimationStarted?: boolean;
  yAnimationStarted?: boolean;
};

const HAIRSPACE = '\u200A';

const animations = {
  x: {
    easing: 'easeInOutQuad',
    duration: 400,
    from: (ctx: ExtendedLineChartContext) => {
      if (ctx.type === 'data' && !ctx.xAnimationStarted && ctx.dataIndex > 0) {
        ctx.xAnimationStarted = true;

        return ctx.chart.getDatasetMeta(ctx.datasetIndex)
          .data[ctx.dataIndex - 1]
          .getProps(['x'], false).x;
      }
    },
  },
  y: {
    easing: 'easeInOutQuad',
    duration: 400,
    from: (ctx: ExtendedLineChartContext) => {
      if (ctx.type === 'data' && !ctx.yAnimationStarted && ctx.dataIndex > 0) {
        ctx.yAnimationStarted = true;

        return ctx.chart.getDatasetMeta(ctx.datasetIndex)
          .data[ctx.dataIndex - 1]
          .getProps(['y'], false).y;
      }
    },
  },
};

export const activateDataset = (dataset) => {
  dataset.borderColor = dataset.activeColor;
  dataset.backgroundColor = dataset.activeColor;
  dataset.order = Order.FRONT;
};

export const deactivateDataset = (dataset) => {
  dataset.borderColor = dataset.inactiveColor;
  dataset.backgroundColor = dataset.inactiveColor;
  dataset.order = Order.DEFAULT;
};

// ChartJS doesn't support text transformation or
// letter spacing out of the box so we emulate it.
const formatAxisTitle = (label: string) =>
  toUpper(label).split('').join(HAIRSPACE);

const getDistanceInMinutes = (startDate: Date | string, endDate: Date | string) =>
  ((new Date(endDate)).valueOf() - (new Date(startDate)).valueOf()) / ONE_MINUTE;

const useAuctionGraphOptions = () => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const lot = rfx.useAuctionLot();
  const locale = useCurrentUserLocale();

  // only show ticks on the y (price) axis when there
  // is data to determine the scaling of the y axis
  const showPriceTicks = (
    lot.rules.ceilingPrice ||
    some(lot.bidsByBidderId, bids => !isEmpty(bids))
  );

  return useMemo(() => {
    // When the tick value is not an integer, return `null` to hide
    // the tick. Otherwise return a formatted string.
    const transformTick = (value: number | undefined) =>
      // @ts-expect-error ts(2345) FIXME: Argument of type 'number | undefined' is not assignable to parameter of type 'number'.
      Number.isInteger(value) ? localeFormatNumber(value, { locale }) : null;

    return {
      responsive: true,
      maintainAspectRatio: false,
      // don't cut off points at the borders of the grid
      clip: false as const,
      font: {
        size: 12,
        family: 'aktiv-grotesk, sans-serif',
      },
      hover: {
        mode: 'dataset',
        intersect: false,
      },
      layout: {
        padding: {
          // 1 is required for the top border to be fully visible
          top: lot.rules.ceilingPrice ? 34 : 1,
        },
      },
      animations,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
        ...(lot.rules.ceilingPrice ? {
          annotation: {
            annotations: {
              ceilingPrice: {
                type: 'line',
                drawTime: 'beforeDatasetsDraw',
                yMin: lot.rules.ceilingPrice.amount,
                yMax: lot.rules.ceilingPrice.amount,
                borderColor: theme.colors.blueDark,
                borderWidth: 1,
                borderDash: [5],
                label: {
                  display: true,
                  content: t('request.auction.bidRules.ceilingPrice.label'),
                  position: 'end',
                  backgroundColor: theme.colors.blueLight,
                  color: theme.colors.blue,
                  font: {
                    size: 12,
                    weight: 'normal',
                  },
                  height: 24,
                  xAdjust: 13,
                  yAdjust: -37,
                  padding: {
                    top: 6,
                    right: 12,
                    bottom: 6,
                    left: 12,
                  },
                  borderRadius: 4,
                },
              },
            },
          },
        } : {}),
      },
      scales: {
        x: {
          title: {
            display: true,
            text: formatAxisTitle(t('request.auction.durationInMinutes')),
            color: theme.colors.subtext,
            font: {
              size: 10,
              weight: 'bold',
            },
            padding: {
              top: 22,
            },
          },
          grid: {
            color: theme.colors.secondary,
            borderColor: theme.colors.secondary,
          },
          type: 'linear',
          position: 'bottom',
          min: 0,
          // rounding up because ChartJS doesn't render the right grid border
          // when `max` is not an integer
          max: Math.ceil(getDistanceInMinutes(lot.startDate, lot.endDate)),
          ticks: {
            color: theme.colors.subtext,
            callback: transformTick,
          },
        },
        y: {
          ...(
            lot.rules.ceilingPrice
              ? { max: lot.rules.ceilingPrice.amount }
              : {}
          ),

          title: {
            display: true,
            text: formatAxisTitle(t('request.auction.priceWithCurrency', {
              currency: lot.rules.currency,
            })),
            color: theme.colors.subtext,
            font: {
              size: 10,
              weight: 'bold',
            },
            padding: {
              bottom: 22,
            },
          },
          grid: {
            color: theme.colors.secondary,
            borderColor: theme.colors.secondary,
          },
          ticks: {
            display: showPriceTicks,
            color: theme.colors.subtext,
            callback: transformTick,
          },
        },
      },
    } as ChartOptions<'line'>;
  }, [lot, theme, showPriceTicks, locale, t]);
};

const useAuctionGraphData = () => {
  const lot = rfx.useAuctionLot();

  return useMemo(() => {
    const nonEmptyBidsByBidderId = pickBy(
      lot.bidsByBidderId,
      bids => !isEmpty(bids),
    );

    return {
      datasets: Object.entries(nonEmptyBidsByBidderId).map(([bidderId, bids]) => {
        const color = generateColor(bidderId);

        return {
          label: bidderId,
          data: bids.map(bid => {
            return {
              x: Math.max(0, getDistanceInMinutes(lot.startDate, bid.date)),
              y: bid.price,
            };
          }),
          borderWidth: 2,
          borderColor: color,
          backgroundColor: color,
          order: Order.DEFAULT,
          activeColor: color,
          inactiveColor: lighten30(color),
        };
      }),
    };
  }, [lot]);
};

export const AuctionGraph = forwardRef<ChartJS, { description: string }>(({ description }, ref) => {
  const options = useAuctionGraphOptions();
  const data = useAuctionGraphData();

  return (
    <Chart
      // @ts-expect-error ts(2322) FIXME: Type 'ForwardedRef<Chart<keyof ChartTypeRegistry, (number | [number, number] | Point | BubbleDataPoint | null)[], unknown>>' is not assignable to type 'ForwardedRef<ChartJSOrUndefined<keyof ChartTypeRegistry, (number | [number, number] | Point | BubbleDataPoint | null)[], unknown>> | undefined'.
      ref={ref}
      type="line"
      options={options}
      data={data}
      fallbackContent={<p>{description}</p>}
    />
  );
});
