import { isNil, omitBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass/styled-components';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelHeader } from '@deepstream/ui-kit/elements/Panel';
import { SelectDropdownMenu } from '../../../ui/MultiSelect';
import { useSelectRowHeight } from '../Comparison/useSelectRowHeight';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useCurrentUser, useCurrentUserLocale } from '../../../useCurrentUser';
import { useRfqId } from '../../../useRfq';
import useDownload from '../../../useDownload';
import { useMutation } from '../../../useMutation';
import * as rfx from '../../../rfx';
import { ExpandViewButton } from '../Comparison/ExpandViewButton';
import { FilterProps } from '../../../filtering';

export const AuctionComparisonControlsPanel = ({
  supplierFilter,
  disabled,
  selectRowHeight,
  isExpandedView,
  setIsExpandedView,
  onlyObsoleteExchangeDefs,
}: {
  supplierFilter: FilterProps<{ _id: string; name: string }>;
  disabled?: boolean;
  selectRowHeight: ReturnType<typeof useSelectRowHeight>;
  isExpandedView: boolean;
  setIsExpandedView: (isExpandedView: boolean) => void;
  onlyObsoleteExchangeDefs?: boolean;
}) => {
  const { t } = useTranslation();
  const download = useDownload();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();
  const locale = useCurrentUserLocale();
  const rfqId = useRfqId({ required: true });
  const rfxPermissions = rfx.useRfxPermissions();

  const [downloadComparisonCsv, { isLoading }] = useMutation(() => {
    const queryParams = new URLSearchParams(
      omitBy({
        csvSeparator: user.preferences?.csvSeparator,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        onlyObsoleteExchangeDefs,
        locale,
      }, isNil) as Record<string, string>,
    );

    return download(`/download/company/${currentCompanyId}/rfq/${rfqId}/auction-line-items?${queryParams.toString()}`);
  });

  return (
    <Panel
      flex={0}
      style={{
        borderBottom: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }}
    >
      <PanelHeader
        height="56px"
        pt="1px"
        pb="2px"
        heading={(
          <Box padding={1} pl={0}>
            <SelectDropdownMenu
              multi
              buttonText={t('general.supplierName')}
              buttonIcon="filter"
              menuWidth={180}
              menuZIndex={151}
              disabled={disabled}
              {...supplierFilter}
            />
          </Box>
        )}
        collapse={false}
      >
        <Flex>
          <Box mr={4}>
            <Button
              small
              variant="secondary-outline"
              iconLeft="download"
              disabled={disabled || isLoading || !rfxPermissions.canDownloadReports}
              onClick={() => downloadComparisonCsv(null)}
            >
              {t('general.downloadCSV')}
            </Button>
          </Box>
          <Box mr={2}>
            <SelectDropdownMenu
              buttonText={t('request.comparison.rowHeight')}
              buttonIcon="arrows-v"
              menuWidth={120}
              menuZIndex={151}
              disabled={disabled}
              {...selectRowHeight}
            />
          </Box>
          <ExpandViewButton
            isExpandedView={isExpandedView}
            setIsExpandedView={setIsExpandedView}
          />
        </Flex>
      </PanelHeader>
    </Panel>
  );
};
