import { rowHeightMeasurements } from './rowHeight';
import { useLocalStorageState } from '../../../useLocalStorageState';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useSelectRowHeight } from './useSelectRowHeight';
import { useCurrentUser } from '../../../useCurrentUser';
import { View } from './useSelectView';
import { useSelectBidStatus } from './useSelectBidStatus';
import { useSelectRecipientOrder } from './useSelectRecipientOrder';
import { useSelectRequirementGroups } from './useSelectRequirementGroups';

export const useGridState = (
  rfqId: string,
  view: View,
  availableRequirementGroupIds: string[],
) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();

  const [collapsedRowIds, setCollapsedRowIds] = useLocalStorageState<string[]>({
    key: `${currentCompanyId}.${currentUser._id}.${rfqId}.${view}.collapsedRowIds`,
    defaultValue: [],
  });

  const selectRequirementGroups = useSelectRequirementGroups({
    storageKey: `${currentCompanyId}.${currentUser._id}.${rfqId}.${view}.selectedRequirementIds`,
    availableRequirementGroupIds,
  });

  const selectRowHeight = useSelectRowHeight({
    storageKey: `${currentCompanyId}.${currentUser._id}.${rfqId}.${view}.rowHeight`,
  });

  const selectBidStatus = useSelectBidStatus({
    storageKey: `${currentCompanyId}.${currentUser._id}.${rfqId}.${view}.bidStatus`,
  });

  const selectRecipientOrder = useSelectRecipientOrder({
    storageKey: `${currentCompanyId}.${currentUser._id}.${rfqId}.${view}.recipientOrder`,
  });

  return {
    collapsedRowIds,
    setCollapsedRowIds,
    selectBidStatus,
    selectRecipientOrder,
    selectRowHeight,
    subRowHeight: rowHeightMeasurements[selectRowHeight.selectedItems[0].value],
    selectRequirementGroups,
  };
};
