import { useEffect } from 'react';
import { ListConfigFlowData, ListConfigFlowStepType } from '../types';
import { useSupplierListNavigation } from '../../../../appNavigation';
import { useMultiStepFlowData } from '../../../../ui/MultiStepFlow/MultiStepFlowContext';

export const SuccessStep = () => {
  const { navigateToListSettings, navigateToLists } = useSupplierListNavigation();
  const { data } = useMultiStepFlowData<ListConfigFlowStepType, ListConfigFlowData>();

  useEffect(() => {
    if (data._id) {
      navigateToListSettings();
    } else {
      navigateToLists();
    }
  }, [navigateToListSettings, navigateToLists, data._id]);

  return null;
};
