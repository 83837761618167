import { ExchangeStatus, StageApprovalStatus } from './types';

export const getApprovalRequestStatus = (status: string): StageApprovalStatus => {
  switch (status) {
    case ExchangeStatus.INCOMPLETE:
      return StageApprovalStatus.PENDING;
    case ExchangeStatus.COMPLETE:
      return StageApprovalStatus.COMPLETE;
    case ExchangeStatus.OBSOLETE:
      return StageApprovalStatus.CANCELLED;
    default:
      throw new Error(`Invalid stage approval exchange status ${status}`);
  }
};
