import { useCallback } from 'react';
import { Draft, ExchangeDefinitionBase, ExchangeType, RfxOtherSection } from '@deepstream/common/rfq-utils';
import { useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { useField } from 'formik';
import { compact, sortBy } from 'lodash';
import * as rfx from '../rfx';

export const useHandleGridLotChange = <TExchangeDef extends ExchangeDefinitionBase = ExchangeDefinitionBase>() => {
  const section = rfx.useSection<RfxOtherSection>();
  const { exchangeDefById } = rfx.useStructure<Draft>();
  const [{ value: formSection }, , sectionFormik] = useField({ name: 'section' });
  const [, , currencyExchangeDefFormik] = useField({ name: 'currencyExchangeDef' });

  const {
    updateDataWith,
  } = useEditableGridData<TExchangeDef>();

  return useCallback((newLotIds: string[] | null, isNewValueObsolete: boolean, wasPreviousValueObsolete: boolean) => {
    if (isNewValueObsolete && !wasPreviousValueObsolete) {
      sectionFormik.setValue({
        ...section.liveVersion,
        isLive: section.isLive,
        liveVersion: section.liveVersion,
        lotIds: newLotIds,
        isObsolete: true,
      });

      const currencyExchangeDef = Object.values(exchangeDefById).find(exchangeDef => (
        exchangeDef.type === ExchangeType.CURRENCY &&
        exchangeDef.sectionId === section._id
      ));

      if (currencyExchangeDef?.isLive) {
        currencyExchangeDefFormik.setValue({
          ...currencyExchangeDef.liveVersion,
          isLive: currencyExchangeDef.isLive,
          liveVersion: currencyExchangeDef.liveVersion,
          isObsolete: true,
        });
      }

      updateDataWith(rows => {
        const newRows = compact(rows.map(row => {
          // remove rows that aren't live yet
          return row.isLive
            ? {
              ...row.liveVersion,
              isLive: row.isLive,
              liveVersion: row.liveVersion,
              isObsolete: true,
            }
            : null;
        }));

        // @ts-expect-error ts(2339) FIXME: Property '_id' does not exist on type '{ isLive: true; liveVersion: ExchangeDefinition<"live"> | undefined; isObsolete: boolean; } | { isLive: true; liveVersion: ExchangeDefinition<"live"> | undefined; ... 11 more ...; fields?: Record<...> | undefined; } | ... 35 more ... | { ...; }'.
        return sortBy(newRows, row => section.exchangeDefIds.indexOf(row._id)) as TExchangeDef[];
      });
    } else if (!isNewValueObsolete && wasPreviousValueObsolete) {
      sectionFormik.setValue({
        ...formSection,
        lotIds: newLotIds,
        isObsolete: false,
      });

      const currencyExchangeDef = Object.values(exchangeDefById).find(exchangeDef => (
        exchangeDef.type === ExchangeType.CURRENCY &&
        exchangeDef.sectionId === section._id
      ));

      if (currencyExchangeDef && !currencyExchangeDef.liveVersion?.isObsolete) {
        currencyExchangeDefFormik.setValue({
          ...currencyExchangeDef,
          isObsolete: false,
        });
      }

      updateDataWith(rows => {
        return rows.map(row => {
          // @ts-expect-error ts(18048) FIXME: 'row.liveVersion' is possibly 'undefined'.
          return row.liveVersion.isObsolete
            // don't set `isObsolete` to false when the live version
            // of the exchangeDef is obsolete
            ? row
            : {
              ...row,
              isObsolete: false,
            };
        });
      });
    } else {
      sectionFormik.setValue({
        ...formSection,
        lotIds: newLotIds,
      });
    }
  }, [sectionFormik, section, exchangeDefById, updateDataWith, currencyExchangeDefFormik, formSection]);
};
