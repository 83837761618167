import { MessageBlock2, MessageBlockProps } from '@deepstream/ui-kit/elements/MessageBlock';
import { Trans } from 'react-i18next';
import { AuctionStatus, BidIntentionStatus, BidStatus, Live, LotIntentionStatus, RequestInteractivityStatus } from '@deepstream/common/rfq-utils';
import * as rfx from '../../../rfx';
import * as lotPagesLayout from '../Live/lotPagesLayout';
import { HtmlSectionId } from '../Live/constants';
import { Quote } from '../../../ui/Quote';
import { ErrorMessage } from '../../../form/Field';
import { useRequestRecipientNavigation } from '../../../appNavigation';

const PageTopMessageBlock = ({
  i18nKey,
  hash,
  ...props
}: {
  i18nKey: string;
  hash?: string;
} & MessageBlockProps) => {
  const navigation = useRequestRecipientNavigation();

  return (
    <MessageBlock2 {...props}>
      <Trans
        i18nKey={i18nKey}
        ns="translation"
        components={{
        a: (
          <lotPagesLayout.TanstackInlineLink
            {...navigation.getBidLinkProps()}
            hash={hash}
          />
        ),
      }}
      />
    </MessageBlock2>
  );
};

export const StaticFormErrorMessage = ({
  i18nKey,
  hash,
}: {
  i18nKey: string;
  hash?: string;
}) => {
  return (
    <ErrorMessage
      fontWeight={400}
      style={{ marginBottom: '16px' }}
      error={
        <Trans
          i18nKey={i18nKey}
          ns="translation"
          components={{ a: <Quote /> }}
        />
      }
    />
  );
};

export const RequestRecipientExchangeStageMessage = () => {
  const { lotById } = rfx.useStructure<Live>();
  const { activatedStageIds, intentionStatusByLotId } = rfx.useBid();
  const currentCompanyGroup = rfx.useCurrentCompanyGroup();
  const stageId = rfx.useStageId();
  const requirementGroupId = rfx.useRequirementGroupId();
  const requestInteractivityStatus = rfx.useRequestInteractivityStatus();
  const bidIntentionStatus = rfx.useBidIntentionStatus();
  // @ts-expect-error ts(2345) FIXME: Argument of type 'string | null' is not assignable to parameter of type 'string'.
  const isStageActive = rfx.useIsEnteredStageActive(stageId);

  if (requirementGroupId && requirementGroupId !== 'general' && lotById[requirementGroupId]?.isObsolete) {
    return (
      <PageTopMessageBlock
        maxWidth={lotPagesLayout.DEFAULT_SECTION_WIDTH}
        variant="info"
        i18nKey="request.lots.wasMadeObsolete"
      />
    );
  }

  if (requestInteractivityStatus === RequestInteractivityStatus.ENDED) {
    return (
      <PageTopMessageBlock
        maxWidth={lotPagesLayout.DEFAULT_SECTION_WIDTH}
        variant="info"
        i18nKey={`request.stages.cannotRespondRequestEnded.${currentCompanyGroup}`}
      />
    );
  }

  if (requestInteractivityStatus === RequestInteractivityStatus.PENDING) {
    return (
      <PageTopMessageBlock
        maxWidth={lotPagesLayout.DEFAULT_SECTION_WIDTH}
        variant="info"
        i18nKey={`request.stages.cannotRespondDeadlinesPassed.${currentCompanyGroup}`}
      />
    );
  }

  if (bidIntentionStatus === BidIntentionStatus.NO_RESPONSE) {
    return (
      <PageTopMessageBlock
        maxWidth={lotPagesLayout.DEFAULT_SECTION_WIDTH}
        variant={currentCompanyGroup === 'supplier' ? 'error' : 'info'}
        i18nKey={`request.stages.cannotRespondNoResponse.${currentCompanyGroup}`}
        hash={HtmlSectionId.MY_RESPONSE}
      />
    );
  }

  const isBidding = bidIntentionStatus === BidIntentionStatus.BIDDING;

  if (!isBidding) {
    return (
      <PageTopMessageBlock
        maxWidth={lotPagesLayout.DEFAULT_SECTION_WIDTH}
        variant="info"
        i18nKey={`request.stages.cannotRespondStartBidding.${currentCompanyGroup}`}
        hash={HtmlSectionId.MY_RESPONSE}
      />
    );
  }

  const hasActivatedStage = activatedStageIds.includes(stageId);

  if (!hasActivatedStage) {
    return (
      <PageTopMessageBlock
        maxWidth={lotPagesLayout.DEFAULT_SECTION_WIDTH}
        variant={currentCompanyGroup === 'supplier' ? 'error' : 'info'}
        i18nKey={`request.stages.cannotRespondUntilContinue.${currentCompanyGroup}`}
        hash={HtmlSectionId.MY_RESPONSE}
      />
    );
  }

  if (requirementGroupId && requirementGroupId !== 'general') {
    const lotIntentionStatus = intentionStatusByLotId[requirementGroupId] || LotIntentionStatus.NO_RESPONSE;

    if (lotIntentionStatus !== LotIntentionStatus.BIDDING) {
      return (
        <PageTopMessageBlock
          maxWidth={lotPagesLayout.DEFAULT_SECTION_WIDTH}
          variant={lotIntentionStatus === LotIntentionStatus.NO_RESPONSE ? 'error' : 'info'}
          i18nKey={`request.stages.cannotRespondStartBiddingLot.${currentCompanyGroup}`}
          hash={HtmlSectionId.LOTS}
        />
      );
    }
  }

  if (!isStageActive) {
    return (
      <PageTopMessageBlock
        maxWidth={lotPagesLayout.DEFAULT_SECTION_WIDTH}
        variant="info"
        i18nKey={`request.stages.canStillRespond.${currentCompanyGroup}`}
        hash={HtmlSectionId.LOTS}
      />
    );
  }

  return null;
};

export const RequestRecipientAuctionStageMessage = ({ MessageComponent = PageTopMessageBlock }) => {
  const currentCompanyGroup = rfx.useCurrentCompanyGroup();
  const { activatedStageIds, status } = rfx.useBid();
  const { auction } = rfx.useStructure<Live>();
  const stageId = rfx.useStageId();
  const bidIntentionStatus = rfx.useBidIntentionStatus();

  if ([BidStatus.AWARDED, BidStatus.UNSUCCESSFUL].includes(status)) {
    return (
      <MessageComponent
        mb="20px"
        variant="info"
        i18nKey={`request.auction.cannotRespondRequestEnded.${currentCompanyGroup}`}
      />
    );
  }

  if (auction.status === AuctionStatus.ENDED) {
    return (
      <MessageComponent
        mb="20px"
        variant="info"
        i18nKey={`request.auction.cannotRespondAuctionEnded.${currentCompanyGroup}`}
      />
    );
  }

  if (auction.status === AuctionStatus.CANCELLED) {
    return (
      <MessageComponent
        mb="20px"
        variant="info"
        i18nKey={`request.auction.cannotRespondAuctionCancelled.${currentCompanyGroup}`}
      />
    );
  }

  if (bidIntentionStatus === BidIntentionStatus.NO_RESPONSE) {
    return (
      <MessageComponent
        mb="20px"
        variant={currentCompanyGroup === 'supplier' ? 'error' : 'info'}
        i18nKey={`request.auction.cannotRespondNoResponse.${currentCompanyGroup}`}
        hash={HtmlSectionId.MY_RESPONSE}
      />
    );
  }

  const isBidding = bidIntentionStatus === BidIntentionStatus.BIDDING;

  if (!isBidding) {
    return (
      <MessageComponent
        mb="20px"
        variant="info"
        i18nKey={`request.auction.cannotRespondStartBidding.${currentCompanyGroup}`}
        hash={HtmlSectionId.MY_RESPONSE}
      />
    );
  }

  const hasActivatedStage = activatedStageIds.includes(stageId);

  if (!hasActivatedStage) {
    return (
      <MessageComponent
        mb="20px"
        variant={currentCompanyGroup === 'supplier' ? 'error' : 'info'}
        i18nKey={`request.auction.cannotRespondUntilContinue.${currentCompanyGroup}`}
        hash={HtmlSectionId.MY_RESPONSE}
      />
    );
  }

  return null;
};
