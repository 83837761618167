import { assign, createMachine, DoneInvokeEvent, InterpreterFrom, StateMachine } from 'xstate';
import { Attachment, BidSubmissionExcelSection } from '@deepstream/common/rfq-utils';
import { createContext } from 'react';
import { some } from 'lodash';
import { LineItemsExchangeSnapshot } from '../../types';

type ExcelBidFlowEvent = DoneInvokeEvent<any>;

export enum ExcelBidFlowActions {
  BACK = 'back',
  CONTINUE = 'continue',
  SET_FILE = 'setFile',
  SET_FILE_ERROR = 'setFileError',
  SET_SELECTED_SECTIONS = 'setSelectedSections',
  SET_AVAILABLE_SECTIONS = 'setAvailableSections',
  SET_CURRENT_SECTION_EXCHANGES = 'setCurrentSectionExchanges',
  SET_CURRENT_SECTION_SUPPLIER_CURRENCY = 'setCurrentSectionSupplierCurrency',
  SET_SECTIONS_VALIDATION = 'setSectionsValidation',
  RESET_TO_INITIAL_CONTEXT = 'resetToInitialContext',
}

interface ExcelBidFlowContext {
  rfqId: string;
  recipientId: string;
  steps: any;
  excelAttachment?: Attachment | null;
  excelFileError?: string;
  availableSections?: BidSubmissionExcelSection[];
  selectedSections: string[];
  processedAttachmentId?: string;
  exchangeSnapshotBySectionId: Record<string, LineItemsExchangeSnapshot[]>;
  supplierCurrencyBySectionId: Record<string, { currencyExchangeId: string; currency: string }>;
  sectionsValidationById: Record<string, {
    sectionName: string;
    isSectionValid: boolean;
    invalidLineItemsCount: number;
    validLineItemsCount: number;
  }>;
  currentSectionIndex: number;
}

export const excelBidModalSteps = {
  downloadBidExcel: 'downloadBidExcel',
  uploadBidExcel: 'uploadBidExcel',
  reviewSectionMapping: 'reviewSectionMapping',
};

export const excelBidFlowInitialContext: ExcelBidFlowContext = {
  rfqId: '',
  recipientId: '',
  steps: excelBidModalSteps,
  excelAttachment: null,
  excelFileError: undefined,
  selectedSections: [],
  exchangeSnapshotBySectionId: {},
  supplierCurrencyBySectionId: {},
  sectionsValidationById: {},
  currentSectionIndex: 0,
};

type ExcelBidMachineReactContext = {
  service: InterpreterFrom<StateMachine<ExcelBidFlowContext, any, ExcelBidFlowEvent>>;
  context: ExcelBidFlowContext;
};

export const ExcelBidMachineContext = createContext<ExcelBidMachineReactContext>(null as any);

export const excelBidFlowMachine = createMachine<
  ExcelBidFlowContext,
  ExcelBidFlowEvent
>(
  {
    id: 'excelBidFlowMachine',
    initial: 'downloadExcelFile',
    on: {
      [ExcelBidFlowActions.RESET_TO_INITIAL_CONTEXT]: {
        target: 'downloadExcelFile',
        actions: ['resetToInitialContext'],
      },
    },
    states: {
      downloadExcelFile: {
        id: 'downloadExcelFile',
        on: {
          [ExcelBidFlowActions.CONTINUE]: 'uploadBidExcel',
        },
      },
      uploadBidExcel: {
        id: 'uploadBidExcel',
        on: {
          [ExcelBidFlowActions.BACK]: 'downloadExcelFile',
          [ExcelBidFlowActions.CONTINUE]: 'reviewSectionMapping',
          [ExcelBidFlowActions.SET_FILE]: {
            actions: ['setExcelFile'],
          },
          [ExcelBidFlowActions.SET_FILE_ERROR]: {
            actions: ['setExcelFileError'],
          },
        },
      },
      reviewSectionMapping: {
        id: 'reviewSectionMapping',
        on: {
          [ExcelBidFlowActions.BACK]: 'uploadBidExcel',
          [ExcelBidFlowActions.SET_SELECTED_SECTIONS]: {
            actions: ['setSelectedSections'],
          },
          [ExcelBidFlowActions.SET_AVAILABLE_SECTIONS]: {
            actions: ['setAvailableSections'],
          },
          [ExcelBidFlowActions.SET_SECTIONS_VALIDATION]: {
            actions: ['setSectionsValidation'],
          },
          [ExcelBidFlowActions.CONTINUE]: [
            {
              cond: 'areAllSectionsValid',
              actions: ['updateStepsAfterValidation'],
              target: 'reviewImportedSectionData',
            },
            {
              cond: 'areSomeSectionsInvalid',
              target: 'reviewInvalidSections',
            },
          ],
        },
      },
      reviewInvalidSections: {
        id: 'reviewInvalidSections',
        on: {
          [ExcelBidFlowActions.BACK]: 'reviewSectionMapping',
          [ExcelBidFlowActions.CONTINUE]: {
            actions: ['updateStepsAfterValidation'],
            target: 'reviewImportedSectionData',
          },
        },
      },
      reviewImportedSectionData: {
        id: 'reviewImportedSectionData',
        on: {
          [ExcelBidFlowActions.BACK]: [
            {
              cond: 'isFirstSectionIndex',
              target: 'reviewSectionMapping',
            },
            {
              cond: 'isNotFirstSectionIndex',
              actions: ['decreaseCurrentSectionIndex'],
            },
          ],
          [ExcelBidFlowActions.CONTINUE]: [
            {
              cond: 'isNotLastSectionIndex',
              actions: ['increaseCurrentSectionIndex'],
            },
            {
              cond: 'isLastSectionIndex',
              target: 'finalConfirmation',
            },
          ],
          [ExcelBidFlowActions.SET_CURRENT_SECTION_EXCHANGES]: {
            actions: ['setCurrentSectionExchanges'],
          },
          [ExcelBidFlowActions.SET_CURRENT_SECTION_SUPPLIER_CURRENCY]: {
            actions: ['setCurrentSectionSupplierCurrency'],
          },
        },
      },
      finalConfirmation: {
        id: 'finalConfirmation',
        on: {
          [ExcelBidFlowActions.BACK]: 'reviewImportedSectionData',
          [ExcelBidFlowActions.CONTINUE]: 'submissionSuccess',
        },
      },
      submissionSuccess: {
        id: 'submissionSuccess',
      },
    },
  },
  {
    actions: {
      resetToInitialContext: assign((_, event) => event.data),
      setExcelFile: assign((_, event) => {
        return ({
          steps: excelBidModalSteps,
          selectedSections: [],
          exchangeSnapshotBySectionId: {},
          supplierCurrencyBySectionId: {},
          currentSectionIndex: 0,
          excelAttachment: event.data,
        });
      }),
      setExcelFileError: assign((_, event) => {
        return {
          excelFileError: event.data,
        };
      }),
      setSelectedSections: assign((_, event) => {
        return {
          selectedSections: event.data,
        };
      }),
      setAvailableSections: assign((_, event) => {
        return {
          processedAttachmentId: event.data.attachmentId,
          availableSections: event.data.availableSections,
          selectedSections: event.data.availableSections.map((section) => section._id),
        };
      }),
      setCurrentSectionExchanges: assign((context, event) => {
        return {
          exchangeSnapshotBySectionId: {
            ...context.exchangeSnapshotBySectionId,
            [context.selectedSections[context.currentSectionIndex]]: event.data,
          },
        };
      }),
      setCurrentSectionSupplierCurrency: assign((context, event) => {
        return {
          supplierCurrencyBySectionId: {
            ...context.supplierCurrencyBySectionId,
            [context.selectedSections[context.currentSectionIndex]]: event.data,
          },
        };
      }),
      increaseCurrentSectionIndex: assign((context) => {
        return {
          currentSectionIndex: context.currentSectionIndex + 1,
        };
      }),
      decreaseCurrentSectionIndex: assign((context) => {
        return {
          currentSectionIndex: context.currentSectionIndex - 1,
        };
      }),
      setSectionsValidation: assign((context, event) => {
        const hasInvalidSections = some(event.data, (section) => !section.isSectionValid);
        return {
          sectionsValidationById: event.data,
          steps: hasInvalidSections ? {
            ...context.steps,
            reviewInvalidSections: 'reviewInvalidSections',
          } : {
            ...context.steps,
          },
        };
      }),
      updateStepsAfterValidation: assign((context) => {
        const validSections = context.selectedSections.filter(sectionId => context.sectionsValidationById[sectionId].isSectionValid);
        const validSteps = validSections
          .reduce((acc, sectionId, index) => ({
            ...acc,
            [`reviewImportedSectionData${index}`]: 'reviewImportedSectionData',
          }), {});
        return {
          steps: {
            ...context.steps,
            ...validSteps,
            'finalConfirmation': 'finalConfirmation',
          },
          selectedSections: validSections,
        };
      }),
    },
    guards: {
      isFirstSectionIndex: (context) => context.currentSectionIndex === 0,
      isNotFirstSectionIndex: (context) => context.currentSectionIndex > 0,
      isNotLastSectionIndex: (context) => context.currentSectionIndex < context.selectedSections.length - 1,
      isLastSectionIndex: (context) => context.currentSectionIndex === context.selectedSections.length - 1,
      areAllSectionsValid: (context) => {
        return context.selectedSections.every((sectionId) =>
          context.sectionsValidationById[sectionId]?.isSectionValid &&
          context.sectionsValidationById[sectionId]?.invalidLineItemsCount === 0);
      },
      areSomeSectionsInvalid: (context) => {
        return context.selectedSections.some((sectionId) =>
          !context.sectionsValidationById[sectionId]?.isSectionValid ||
          context.sectionsValidationById[sectionId]?.invalidLineItemsCount > 0);
      },
    },
  },
);
