import { Lot, LotIntentionStatus } from './types';

export const isLotInactive = (lot: Lot, lotIntentionStatus?: LotIntentionStatus): boolean =>
  lot.isObsolete || Boolean(
    lotIntentionStatus &&
    [LotIntentionStatus.BID_WITHDRAWN, LotIntentionStatus.DECLINED_TO_BID].includes(lotIntentionStatus),
  );

export const isBiddingOnLot = (lot: Lot, lotIntentionStatus?: LotIntentionStatus): boolean =>
  !lot.isObsolete && lotIntentionStatus === LotIntentionStatus.BIDDING;
