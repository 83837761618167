import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { v4 as uuid } from 'uuid';
import { find } from 'lodash';
import { SectionType } from '@deepstream/common/rfq-utils';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import * as draft from './draft';
import * as rfx from '../rfx';
import { ModalForm } from '../ModalForm';
import { TextField } from '../form/TextField';

export const AddPageModal = ({
  isOpen,
  sourcePageId, // used for duplicating a page
  onCancel,
  onSuccess,
}: {
  isOpen: boolean;
  sourcePageId?: string;
  onCancel: () => void;
  onSuccess: (pageId: string) => void;
}) => {
  const { t } = useTranslation();
  const { sectionById, pageById } = rfx.useStructure();
  const [addPage] = draft.useAddPage();
  const [duplicatePage] = draft.useDuplicatePage();

  const isDuplicate = Boolean(sourcePageId);

  const hasVesselPricingSection = useMemo(
    () => isDuplicate
      ? find(
        pageById[sourcePageId!].sections,
        sectionId => sectionById[sectionId].type === SectionType.VESSEL_PRICING,
      )
      : false,
    [sourcePageId, pageById, sectionById, isDuplicate],
  );

  const initialName = isDuplicate
    ? `${t('general.copyOf')} ${pageById[sourcePageId!].name}`
    : '';

  return (
    <ModalForm
      heading={isDuplicate ? t('request.pages.duplicatePage') : t('request.pages.addPage', { count: 1 })}
      initialValues={{
        name: initialName,
      }}
      validationSchema={
        yup.object().shape({
          name: yup.string().trim().required(t('request.pages.errors.nameRequired')),
        })
      }
      disableSubmitIfNotDirty={false}
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={async ({ name }, { setSubmitting }) => {
        const pageId = uuid();

        if (isDuplicate) {
          await duplicatePage({
            _id: pageId,
            name: name.trim(),
            sourcePageId: sourcePageId!,
          }, {
            onSuccess: () => onSuccess(pageId),
            onError: () => setSubmitting(false),
          });
        } else {
          await addPage({
            _id: pageId,
            name: name.trim(),
          }, {
            onSuccess: () => onSuccess(pageId),
            onError: () => setSubmitting(false),
          });
        }
      }}
      submitLabel={t('general.save')}
      style={{ content: { width: '500px' } }}
    >
      <TextField
        required
        name="name"
        label={t('general.name')}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
      {isDuplicate && (
        <MessageBlock variant="info" mt={0}>
          {hasVesselPricingSection ? (
            t('request.pages.duplicatePageWithVesselPricing')
          ) : (
            t('request.pages.duplicatePageWithoutVesselPricing')
          )}
        </MessageBlock>
      )}
    </ModalForm>
  );
};
