import { useMemo, useCallback } from 'react';
import { compact, filter, map, pick, some } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Text, Flex } from 'rebass/styled-components';
import { ExchangeType } from '@deepstream/common/rfq-utils';
import { ContractDocumentExchangeDefinition, ContractSignatureType, isLegacyContractExchangeDef } from '@deepstream/common/contract';
import { useField } from 'formik';
import { useQuery } from 'react-query';
import { withProps } from '@deepstream/ui-utils/withProps';
import { WrapperButton } from '@deepstream/ui-kit/elements/button/WrapperButton';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { useContractState } from './contract';
import { useExchangeDefsField } from '../../draft/exchangeDefs';
import {
  ContractExchangeFileFieldCell, ExchangeDefDescriptionFieldCell, ContractTypeFieldCell,
  ContractExchangeDefActionsCell,
  ContractExchangeDefProviderFieldCell,
} from './contractDraftCell';
import { WordWrap } from '../../WordWrap';
import { Required } from '../../Required';
import { Table } from '../../Table';
import { useCollapsibleContent } from '../../ui/useCollapsibleContent';
import { LabelConfig, LabelConfigProvider } from '../../LabelConfigProvider';
import { SwitchField, SwitchFieldBase } from '../../form/SwitchField';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useApi, wrap } from '../../api';
import { FieldContainer } from '../../form/FieldContainer';
import { SelectedUsersAvatarList } from '../../SelectedUsersAvatarList';
import { useModalState } from '../../ui/useModalState';
import { SupplierModalForm } from '../RequestSuppliers/SuppliersPanel';

const labelStyle = {
  fontSize: 2,
  marginTop: '2px',
};

const FieldColumnHeader = ({ column }) => (
  <WordWrap pr={column.headerPaddingRight}>
    {column.label}
    {column.required && <Required />}
  </WordWrap>
);

export const useContractExchangeColumns = (fieldName: string) => {
  const { t } = useTranslation(['general', 'contracts']);
  // @ts-expect-error ts(2339) FIXME: Property 'isLive' does not exist on type 'ContractStateContextType | undefined'.
  const { isLive } = useContractState();
  const { exchangeDefs } = useExchangeDefsField(fieldName);

  const hasAddedNewContract = useMemo(
    () => some(exchangeDefs, exchangeDef => !exchangeDef.isLive),
    [exchangeDefs],
  );

  const showContractTypeColumn = (
    exchangeDefs.length !== 1 ||
    !hasAddedNewContract ||
    exchangeDefs[0].type === ExchangeType.LEGACY_CONTRACT
  );

  return useMemo(
    () => compact([
      showContractTypeColumn ? {
        id: 'type',
        Header: FieldColumnHeader,
        label: t('type'),
        accessor: exchangeDef => isLegacyContractExchangeDef(exchangeDef)
          ? t('legacyContract', { ns: 'contracts' })
          : exchangeDef.isAddendum
            ? t('addendum', { ns: 'contracts' })
            : t('contract', { ns: 'contracts' }),
        Cell: ContractTypeFieldCell,
        required: true,
        width: 170,
      } : null,
      {
        id: 'providedBy',
        Header: FieldColumnHeader,
        label: t('provenance', { ns: 'contracts' }),
        accessor: 'providedBy',
        Cell: ContractExchangeDefProviderFieldCell,
        required: true,
        hideNumbering: true,
        width: 270,
      },
      {
        id: 'attachments',
        Header: FieldColumnHeader,
        label: t('document'),
        accessor: 'attachments',
        Cell: withProps(
          ContractExchangeFileFieldCell,
          {
            descriptionFieldName: 'description',
            // Only accept pdf files because Verified e-signature requires pdf files
            acceptedFileTypes: '.pdf',
          },
        ),
        required: true,
        width: 270,
      },
      {
        id: 'description',
        Header: FieldColumnHeader,
        label: t('description'),
        accessor: 'description',
        Cell: ExchangeDefDescriptionFieldCell,
        required: true,
        hideNumbering: true,
      },
      {
        id: 'menu',
        Header: () => '',
        accessor: null,
        width: 48,
        Cell: withProps(ContractExchangeDefActionsCell, { fieldName, onlyAllowRemoval: isLive }),
      },
    ]),
    [t, fieldName, isLive, showContractTypeColumn],
  );
};

const SignatureConfig = ({
  index,
  fieldName,
}: {
  index: number;
  fieldName: string;
}) => {
  const { t } = useTranslation(['contracts', 'general', 'translation']);
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const [{ value: exchangeDefs }] = useField<ContractDocumentExchangeDefinition[]>(fieldName);
  const [,, signatureTypeHelper] = useField(`${fieldName}.${index}.signatureType`);
  const [,, senderSignersHelper] = useField(`${fieldName}.${index}.senderSigners`);
  const [,, requireApprovalHelper] = useField(`${fieldName}.${index}.requireApprovalBeforeCountersigning`);
  const isLast = index === exchangeDefs.length - 1;
  const { isOpen, getButtonProps, getPanelProps } = useCollapsibleContent(isLast);
  const exchangeDef = exchangeDefs[index];
  // @ts-expect-error ts(2339) FIXME: Property 'isLive' does not exist on type 'ContractStateContextType | undefined'.
  const { isLive } = useContractState();
  const selectUsersModal = useModalState();

  const { data: companyUsers = [], isLoading: isLoadingUsers } = useQuery(
    ['usersForCompany', { companyId: currentCompanyId }],
    wrap(api.getUsersForCompany),
  );

  const onChangeESignature = useCallback(
    (checked: boolean) => {
        // Clean up e-signature fields
        senderSignersHelper.setValue([]);
        requireApprovalHelper.setValue(false);

        if (checked) {
          signatureTypeHelper.setValue(ContractSignatureType.VERIFIED);
        } else {
          signatureTypeHelper.setValue(ContractSignatureType.MANUAL);
        }
    },
    [signatureTypeHelper, senderSignersHelper, requireApprovalHelper],
  );

  const isContractExchange = exchangeDef.type === ExchangeType.CONTRACT;

  const isDisabled = isContractExchange
    ? exchangeDef.isLive
    : !isLive; // Legacy contract signature fields can be updated when the contract is live

  const allUsers = useMemo(
    () => map(
      companyUsers,
      user => ({
        ...user,
        selected: exchangeDef.senderSigners?.some(({ _id }) => _id === user._id),
      }),
    ),
    [companyUsers, exchangeDef.senderSigners],
  );

  return (
    <Box>
      <Box mb={isOpen ? 2 : 0}>
        <WrapperButton {...getButtonProps()}>
          <Text fontSize={1} color="subtext" letterSpacing="1px" sx={{ textTransform: 'uppercase' }}>
            <Icon fixedWidth icon={isOpen ? 'chevron-down' : 'chevron-right'} mr={2} />
            {t('signature.signatureConfiguration')}
          </Text>
        </WrapperButton>
      </Box>
      <Box {...getPanelProps()}>
        <Stack gap={20} m="20px 0 20px 20px">
          <LabelConfigProvider
            variant={LabelConfig.LEFT}
            width="250px"
            style={{
              eSignatureEnabled: labelStyle,
              [`${fieldName}.${index}.requireApprovalBeforeCountersigning`]: labelStyle,
              signerIds: {
                ...labelStyle,
                position: 'relative',
                top: '2px',
              },
            }}
          >
            <SwitchFieldBase
              name="eSignatureEnabled"
              label={t('signature.eSignature')}
              useDefaultLabelConfig={false}
              onChange={onChangeESignature}
              value={exchangeDef.signatureType === ContractSignatureType.VERIFIED}
              checkedIcon={false}
              uncheckedIcon={false}
              checkedText={t('enabled', { ns: 'general' })}
              uncheckedText={t('disabled', { ns: 'general' })}
              width={40}
              disabled={isDisabled}
            />
            {exchangeDef.signatureType === ContractSignatureType.VERIFIED && (
              <>
                <SwitchField
                  name={`${fieldName}.${index}.requireApprovalBeforeCountersigning`}
                  label={t('signature.requireApproval')}
                  infoTooltip={t('signature.requireApprovalTooltip')}
                  useDefaultLabelConfig={false}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  checkedText={t('enabled', { ns: 'general' })}
                  uncheckedText={t('disabled', { ns: 'general' })}
                  width={40}
                  disabled={isDisabled}
                />
                {!isDisabled && (
                  <>
                    <FieldContainer
                      name="signerIds"
                      label={t('signature.signer_other')}
                      width="100%"
                      showAsterisk
                    >
                      <Flex justifyContent="space-between">
                        <SelectedUsersAvatarList
                          // @ts-expect-error ts(2322) FIXME: Type 'UserMinimized[] | undefined' is not assignable to type 'SupplierStateUser[]'.
                          users={exchangeDef.senderSigners}
                          withValidation={false}
                        />
                        <Button
                          type="button"
                          variant="secondary"
                          onClick={selectUsersModal.open}
                          disabled={isLoadingUsers}
                        >
                          {t('edit', { ns: 'general' })}
                        </Button>
                      </Flex>
                    </FieldContainer>
                    {selectUsersModal.isOpen && (
                      <SupplierModalForm
                        users={allUsers}
                        isOpen
                        allowOnlyInvitedUsers
                        heading={t('supplierUsersModal.company', { ns: 'translation' })}
                        onCancel={selectUsersModal.close}
                        onSubmit={(values) => {
                          const selectedUsers = filter(values.users, user => user.selected);

                          senderSignersHelper.setValue(map(selectedUsers, user => pick(user, ['_id', 'name'])));
                          selectUsersModal.close();
                        }}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </LabelConfigProvider>
        </Stack>
      </Box>
    </Box>
  );
};

export const ContractExchangePanel = ({
  fieldName,
  index,
}: {
  fieldName: string;
  index: number;
}) => {
  const columns = useContractExchangeColumns(fieldName);
  const [{ value: exchangeDef }] = useField(`${fieldName}.${index}`);

  const data = useMemo(
    () => [exchangeDef],
    [exchangeDef],
  );

  return (
    <Panel py="12px" px="20px">
      <Stack gap="12px">
        <Table columns={columns} data={data} />
        {exchangeDef.type === ExchangeType.CONTRACT && (
          <SignatureConfig fieldName={fieldName} index={index} />
        )}
      </Stack>
    </Panel>
  );
};
