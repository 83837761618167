import { useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { Flex, Heading, Text, Box } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { concat, trim } from 'lodash';
import prismatic, { getMessageIframe, PrismaticMessageEvent } from '@prismatic-io/embedded';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { ConfigurableIntegrations, IntegrationMarketplaceEventType } from '@deepstream/common';

import { useDebouncedCallback } from 'use-debounce';
import { Grid } from '@deepstream/ui-kit/elements/Grid';
import { Bold } from '../../Bold';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useCurrentUser } from '../../useCurrentUser';
import { useMarketplaceIntegrations, useMarketplaceToken } from '../../useExternalSystems';
import { IntegrationCard } from './IntegrationCard';
import { useEnv } from '../../env';
import { matchStringValue } from '../../searchUtils';
import { SearchInput } from '../../ui/Input';
import { useDeviceSize } from '../../ui/useDeviceSize';
import { BOTTOM_TOOLBAR_CONTENT_PADDING_BOTTOM } from '../../Layout';
import { useTracking } from '../../useTracking';
import { ErrorMessage } from '../../ui/ErrorMessage';
import { useMutation } from '../../useMutation';
import { useApi } from '../../api';
import { useToaster } from '../../toast';
import { useCompanyFeatureFlags } from '../../companyFeatureFlags';

const TextCountWithCheckbox = ({ selectedCount }) => {
  const { t } = useTranslation('integration');

  return (
    <>
      <Checkbox checked={true} readOnly />
      <Text mr="32px">{t('integrationMarketplace.integrationCountSelected', { count: selectedCount })}</Text>
    </>
  );
};

const IntegrationCount = ({ selectedCount }) => {
  const { t } = useTranslation('integration');

  return (
    <>
      {selectedCount > 0
        ? <TextCountWithCheckbox selectedCount={selectedCount} />
        : <Text mr="32px">{t('integrationMarketplace.selectIntegrations')}</Text>
      }
    </>

  );
};

const ScheduleCallButton = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation('integration');
  const env = useEnv();

  return (
    <a href={env.HUBSPOT_URL} target="_blank" rel="noopener noreferrer">
      <Button
        data-test="scheduleCall"
        iconLeft="calendar"
        onClick={onClick}
        disabled={disabled}
      >
        {t('integrationMarketplace.scheduleCall')}
      </Button>
    </a>
  );
};

interface IntegrationBase {
  id: string;
  logo: string;
  name: string;
  description: string;
}

const filterIntegrations = (value: string, items: IntegrationBase[]) => {
  const trimmedValue = trim(value);

  return trimmedValue
    ? items.filter(item => matchStringValue(trimmedValue, item.name) || matchStringValue(trimmedValue, item.description))
    : items;
};

export const Marketplace = () => {
  const { t } = useTranslation('integration');
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();
  const [selectedIntegrations, setSelectedIntegrations] = useState<{ [integrationId: string]: boolean }>({});
  const [filterQuery, setFilterQuery] = useState('');
  const { isLarge, isExtraLarge } = useDeviceSize();
  const { trackIntegrationMarketplaceEvent } = useTracking();
  const env = useEnv();
  const api = useApi();
  const toaster = useToaster();

  const [debouncedTrackIntegrationMarketplaceEvent] = useDebouncedCallback(trackIntegrationMarketplaceEvent, 2000);
  const isAdmin = user.roles[currentCompanyId].admin;

  const { data: marketplaceIntegrations = [], isLoading, isError } = useMarketplaceIntegrations();
  const { data: token, isLoading: isLoadingToken, isError: isErrorToken } = useMarketplaceToken();
  const companyFeatureFlags = useCompanyFeatureFlags();

  useEffect(() => {
    const authenticate = async () => {
      prismatic.init({
        prismaticUrl: env.PRISMATIC_URL,
      });
      await prismatic.authenticate({ token });
      prismatic.showMarketplace({
        selector: '#embedded-prismatic-marketplace',
        filters: {
          marketplace: {
            category: 'configurable',
          },
        },
        screenConfiguration: {
          marketplace: {
            hideSearch: true,
            hideActiveIntegrationsFilter: true,
          },
          configurationWizard: {
            hideSidebar: true,
            isInModal: true,
            triggerDetailsConfiguration: 'hidden',
          },
        },
      });
    };
    if (!isLoadingToken && token && companyFeatureFlags?.notificationsIntegration) {
      authenticate();
    }
  }, [isLoadingToken, token, env, companyFeatureFlags]);

  const [configureSubscription] = useMutation(
    api.configureSubscription,
    {
      onError: () => toaster.error(t('toaster.integrationConfiguredError')),
      onSuccess: () => toaster.success(t('toaster.integrationConfiguredSuccess')),
    },
  );

  useEffect(() => {
    const listener = (message: MessageEvent) => {
      const { event, data } = message.data;
      if (Object.values(ConfigurableIntegrations).includes(data?.integrationName)) {
        if (
          event === PrismaticMessageEvent.INSTANCE_CONFIGURATION_LOADED &&
          !data.readOnly
        ) {
          const iframe = getMessageIframe(message);
          prismatic.setConfigVars({
            iframe,
            configVars: {
              companyId: {
                value: currentCompanyId,
              },
              userEmail: {
                value: user.email,
              },
            },
          });
        }
        if (event === PrismaticMessageEvent.INSTANCE_DEPLOYED) {
          configureSubscription({
            companyId: currentCompanyId,
            instanceId: data.instanceId,
            integrationName: data.integrationName,
            receiveNotifications: true,
          });
        }
      }
    };

    if (companyFeatureFlags?.notificationsIntegration) {
      window.addEventListener('message', listener);
    }

    return () => {
      window.removeEventListener('message', listener);
    };
  }, [currentCompanyId, configureSubscription, companyFeatureFlags, user.email]);

  const customIntegrations = useMemo(() => ([
    {
      id: 'customIntegration',
      name: t('integrationMarketplace.customIntegration'),
      description: t('integrationMarketplace.contactUs'),
      icon: 'plug-circle-plus',
    },
    {
      id: 'webhook',
      name: t('integrationMarketplace.universalWebhook'),
      description: t('integrationMarketplace.triggerIntegration'),
      icon: 'webhook',
    },
  ]), [t]);

  const allIntegrations = useMemo(() => concat(marketplaceIntegrations, customIntegrations), [marketplaceIntegrations, customIntegrations]);
  const selectedIntegrationsCount = useMemo(() =>
    allIntegrations.filter((integration) => selectedIntegrations[integration.id]).length,
    [allIntegrations, selectedIntegrations],
  );

  const filteredIntegrations = filterIntegrations(filterQuery, marketplaceIntegrations);
  const selectedIntegrationsNames = useMemo(() =>
    Object.keys(selectedIntegrations).map(integrationId => allIntegrations.find(integration => integration.id === integrationId).name),
    [allIntegrations, selectedIntegrations],
  );

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilterQuery(event.target.value);
      if (event.target.value.trim() === '') {
        return;
      }
      debouncedTrackIntegrationMarketplaceEvent({
        eventType: IntegrationMarketplaceEventType.SEARCH_INTEGRATION,
        searchQuery: event.target.value.trim(),
        selectedIntegrations: selectedIntegrationsNames,
        searchResults: filteredIntegrations.map(integration => integration.name),
      });
    },
    [setFilterQuery, debouncedTrackIntegrationMarketplaceEvent, selectedIntegrationsNames, filteredIntegrations],
  );

  const clearSearch = useCallback(
    () => setFilterQuery(''),
    [setFilterQuery],
  );

  const handleScheduleCallClick = useCallback(
    () => trackIntegrationMarketplaceEvent({
      eventType: IntegrationMarketplaceEventType.SCHEDULE_CALL,
      selectedIntegrations: selectedIntegrationsNames,
    }),
    [trackIntegrationMarketplaceEvent, selectedIntegrationsNames],
  );

  return isAdmin ? (
    <>
      {companyFeatureFlags?.notificationsIntegration ? (
        (!isLoadingToken && isErrorToken) ? (
          <ErrorMessage
            error={t('integrationMarketplace.couldNotLoadData')}
            fontSize={2}
          />
        ) : (
          <Flex height={500} id="embedded-prismatic-marketplace" />
        )
      ) : (
        null
      )}
      <Flex justifyContent="space-between" alignItems="center" minHeight="40px" maxWidth="100%">
        <Heading fontSize={6} fontWeight={500}>
          <Icon regular icon="store" mr={2} />{t('integrationMarketplace.sampleIntegrations')}
        </Heading>
        {(!isLoading && !isError) && (
          <SearchInput
            placeholder={t('integrationMarketplace.searchIntegration')}
            onChange={onChange}
            initialValue={filterQuery}
            canClear={Boolean(filterQuery.length)}
            clearSearch={clearSearch}
          />
        )}
      </Flex>
      {(!isLoading && isError) ? (
        <ErrorMessage
          error={t('integrationMarketplace.couldNotLoadData')}
          fontSize={2}
        />
      ) : (
        <>
          {!isLoading && (
            <Text color="subtext" mb="32px">{t('integrationMarketplace.message')}</Text>
          )}
          {(!isLoading && !marketplaceIntegrations.length) ? (
            <>
              <Flex justifyContent="space-between" alignItems="center" minHeight="40px" maxWidth="100%">
                <Heading fontSize={4} mt="32px">
                  {t('integrationMarketplace.currentlyUnavailable')}
                </Heading>
              </Flex>
              <Text color="subtext">{t('integrationMarketplace.currentlyUnavailableMessage')}</Text>
            </>
          ) : (
            <>
              {filteredIntegrations.length ? (
                <Grid
                  sx={{
                    gridTemplateColumns: isLarge || isExtraLarge
                      ? '1fr 1fr 1fr'
                      : '1fr 1fr',
                  }}
                  paddingBottom={BOTTOM_TOOLBAR_CONTENT_PADDING_BOTTOM}
                >
                  {filteredIntegrations.map(integration => (
                    <IntegrationCard
                      key={integration.id}
                      logo={integration.logo}
                      name={integration.name}
                      description={integration.description}
                      selected={!!selectedIntegrations[integration.id]}
                      onClick={(selected) => {
                        setSelectedIntegrations({ ...selectedIntegrations, [integration.id]: selected });
                        trackIntegrationMarketplaceEvent({
                          eventType: selected
                            ? IntegrationMarketplaceEventType.INTEGRATION_CARD_SELECTED
                            : IntegrationMarketplaceEventType.INTEGRATION_CARD_DESELECTED,
                          integrationName: integration.name,
                          selectedIntegrations: selectedIntegrationsNames,
                        });
                      }}
                    />
                  ))}
                </Grid>
              ) : !isLoading && (
                <>
                  <Flex justifyContent="space-between" alignItems="center" minHeight="40px" maxWidth="100%">
                    <Heading fontSize={4} mt="32px">
                      {t('integrationMarketplace.noIntegrationYet')}
                    </Heading>
                  </Flex>
                  <Text color="subtext" mb="32px">{t('integrationMarketplace.buildSolution')}</Text>
                  <Grid
                    sx={{
                      gridTemplateColumns: isLarge || isExtraLarge
                        ? '1fr 1fr 1fr'
                        : '1fr 1fr',
                    }}
                  >
                    {customIntegrations.map(integration => (
                      <IntegrationCard
                        key={integration.id}
                        icon={integration.icon}
                        name={integration.name}
                        description={integration.description}
                        selected={!!selectedIntegrations[integration.id]}
                        onClick={(selected) => {
                          setSelectedIntegrations({ ...selectedIntegrations, [integration.id]: selected });
                          trackIntegrationMarketplaceEvent({
                            eventType: selected
                              ? IntegrationMarketplaceEventType.INTEGRATION_CARD_SELECTED
                              : IntegrationMarketplaceEventType.INTEGRATION_CARD_DESELECTED,
                            integrationName: integration.name,
                            selectedIntegrations: selectedIntegrationsNames,
                          });
                        }}
                      />
                    ))}
                  </Grid>
                </>
              )}
              <Box
                sx={{
                  position: 'fixed',
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'white',
                  borderTop: (theme) => `3px solid ${theme.colors.primary}`,
                }}
              >
                <Flex
                  data-test="footer"
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{
                  maxWidth: '1200px',
                  margin: '0 auto',
                  py: 3,
                  px: '15px',
                  gap: 2,
                }}
                >
                  <IntegrationCount selectedCount={selectedIntegrationsCount} />
                  <ScheduleCallButton
                    onClick={handleScheduleCallClick}
                    disabled={selectedIntegrationsCount < 1}
                  />
                </Flex>
              </Box>
            </>
          )}
        </>
      )}
    </>
  ) : (
    <>
      <Bold as="div" fontSize={5} mb={3}>
        <Icon icon="eye-slash" mr={2} />
        {t('request.pages.pageNotVisible', { ns: 'translation' })}
      </Bold>
      <Text mb={1}>
        {t('request.pages.pageNotVisible', { ns: 'translation' })}
      </Text>
    </>
  );
};
