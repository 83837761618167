import * as React from 'react';
import { reject } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import {
  EvaluationCriterionExchangeDefinition,
  EvaluationSection,
  LinkedEvaluationSection,
  isLinkedEvaluationSection,
} from '@deepstream/common/rfq-utils';

import { getScrollbarSize } from '@deepstream/ui-utils/getScrollbarSize';
import { BORDER_ADJUSTMENT, DEFAULT_FROZEN_HEADER_HEIGHT } from '@deepstream/ui-kit/grid/core/constants';
import { ColumnData, RowData } from '@deepstream/ui-kit/grid/core/utils';
import { withProps } from '@deepstream/ui-utils/withProps';
import { EditableGrid } from '@deepstream/ui-kit/grid/EditableGrid/EditableGrid';
import { useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { DefaultEditableGridStyles } from '@deepstream/ui-kit/grid/EditableGrid/EditableGridStyles';
import { useGridMenuState } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { ReadOnlyGrid } from '@deepstream/ui-kit/grid/EditableGrid/ReadOnlyGrid';
import { ACTION_COLUMN_WIDTH, DEFAULT_EDITABLE_DATA_CELL_WIDTH, DEFAULT_ROW_HEIGHT, EditableGridColumn, GridPasteMethod } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { useEditableGridActions } from '@deepstream/ui-kit/grid/EditableGrid/useEditableGridActions';
import {
  NumberInputCell,
  TextInputCell,
} from './inputCell';
import {
  NumberValueCell,
  TextValueCell,
  ValidationAwareRowNumberCell,
} from './validationAwareValueCell';
import { ExchangeDefsGridMenu } from './ExchangeDefsGridMenu';
import { SimpleHeader } from './header';
import { createEvaluationCriterion } from '../../draft/exchangeDefs';
import * as rfx from '../../rfx';
import { useExchangeDefFieldValue } from '../../ExchangeDefFieldValueContext';
import { useAddGridRowsState } from './useAddGridRowsState';
import { useGetExchangeDefCsvCellValue } from './useGetExchangeDefCsvCellValue';
import { AddMoreItemsModal } from './AddMoreItemsModal';
import { getWeightAsPercent } from './utils';
import { AddCriterionButton } from '../../draft/AddCriterionButton';
import { useHandleGridClipboardEvent } from './useHandleGridClipboardEvent';
import { useAddCsvDataToExchangeDefs } from './useAddCsvDataToExchangeDefs';
import { createClearFieldsUpdater } from './exchangeDefUpdaters';

const navigableRangeWithActionColumn = { startRowIndex: 1, startColumnIndex: 0 };
const navigableRangeWithoutActionColumn = { startRowIndex: 1, startColumnIndex: 1 };
const selectableRange = { startColumnIndex: 1 };

const frozenLeftColumnIds = ['rowNumber', 'description'];

export const EvaluationExchangeDefsGrid = ({
  viewportHeightDelta,
  isReadOnly,
}: {
  /**
   * Used to determine the maximum height of the grid.
   *
   * `maxGridHeight = 100vh - viewportHeightDelta`
   */
  viewportHeightDelta: number;
  isReadOnly?: boolean;
}) => {
  const { t } = useTranslation();
  const handleGridClipboardEvent = useHandleGridClipboardEvent();
  const section = rfx.useSectionWithPosition<EvaluationSection | LinkedEvaluationSection>();
  const isLinkedSection = isLinkedEvaluationSection(section);
  const scoringType = rfx.useEvaluationScoringType();
  const {
    pendingKeyboardEvent,
    rowData: exchangeDefs,
    setEditedCell,
    appendRows,
  } = useEditableGridData<EvaluationCriterionExchangeDefinition>();
  const { menuReferenceId } = useGridMenuState();
  const { getFieldValue } = useExchangeDefFieldValue();
  const generateCsvCellValue = useGetExchangeDefCsvCellValue({ getFieldValue });
  const addCsvDataToRows = useAddCsvDataToExchangeDefs();
  const nonObsoleteExchangeDefs = React.useMemo(
    () => reject(exchangeDefs, 'isObsolete'),
    [exchangeDefs]);

  const {
    addGridRowsModal,
    additionalRowCount,
    selectPasteMethod,
    resolveWithPasteMethod,
  } = useAddGridRowsState();

  const gridActions = useEditableGridActions({
    // when the evaluation section is linked, the addition of grid rows
    // must be prevented
    // @ts-expect-error ts(2322) FIXME: Type '((additionalRowCount: number) => Promise<GridPasteMethod | null>) | null' is not assignable to type '((additionalRowCount: number) => Promise<GridPasteMethod>) | null | undefined'.
    selectPasteMethod: isLinkedSection ? null : selectPasteMethod,
    createRow: () => createEvaluationCriterion(scoringType),
    generateCsvCellValue,
    addCsvDataToRows,
    // @ts-expect-error ts(2322) FIXME: Type '({ startRowIndex, endRowIndex, affectedColumns, }: { startRowIndex: number; endRowIndex: number; affectedColumns: { original: EditableGridColumn; }[]; }) => (exchangeDef: LineItemExchangeDefinition, index: number) => LineItemExchangeDefinition' is not assignable to type '({ startRowIndex, endRowIndex, affectedColumns, }: { startRowIndex: number; endRowIndex: number; affectedColumns: ({ original: any; } | null)[]; }) => (originalRowData: any, index: number) => unknown'.
    createClearFieldsUpdater,
  });

  const { toggleMenu } = useGridMenuState();

  const startEditingInputCell = React.useCallback((
    row: RowData<any, any>,
    column: ColumnData<EditableGridColumn>,
    event?: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (row.original.isObsolete) return;

    pendingKeyboardEvent.current = event ?? null;

    setEditedCell({
      rowId: row.original._id,
      columnId: column.original._id,
    });
  }, [setEditedCell, pendingKeyboardEvent]);

  const columns: EditableGridColumn[] = React.useMemo(() => {
    return [
      {
        _id: 'rowNumber',
        accessorKey: 'rowNumber',
        Header: SimpleHeader,
        label: '',
        ValueCell: withProps(ValidationAwareRowNumberCell, { rowNumberPrefix: `${section.index + 1}.` }),
        toggleMenu: isReadOnly || isLinkedSection ? undefined : toggleMenu,
        width: isReadOnly || isLinkedSection ? ACTION_COLUMN_WIDTH : 2 * ACTION_COLUMN_WIDTH,
        fixedWidth: true,
      },
      {
        _id: 'description',
        accessorKey: 'description',
        accessorFn: ((exchangeDef: EvaluationCriterionExchangeDefinition) => getFieldValue(exchangeDef, 'description')) as any,
        Header: SimpleHeader,
        label: t('general.description'),
        required: !isReadOnly && !isLinkedSection,
        disabled: isLinkedSection,
        startEditingCell: isLinkedSection ? undefined : startEditingInputCell,
        InputCell: TextInputCell,
        ValueCell: TextValueCell,
        showIconWhenObsolete: true,
        width: 507,
      },
      {
        _id: 'maxPoints',
        accessorKey: 'maxPoints',
        Header: SimpleHeader,
        label: t('request.evaluation.maxScorePoints'),
        required: !isReadOnly && !isLinkedSection,
        startEditingCell: startEditingInputCell,
        InputCell: NumberInputCell,
        ValueCell: NumberValueCell,
        format: 'integer.positive',
        minValue: 1,
        width: DEFAULT_EDITABLE_DATA_CELL_WIDTH,
      },
      {
        _id: 'weight',
        accessorKey: 'weight',
        Header: SimpleHeader,
        label: t('request.evaluation.weightPoints'),
        required: !isReadOnly && !isLinkedSection,
        startEditingCell: startEditingInputCell,
        InputCell: NumberInputCell,
        ValueCell: NumberValueCell,
        format: 'integer.positive',
        minValue: 1,
        width: DEFAULT_EDITABLE_DATA_CELL_WIDTH,
      },
      {
        _id: 'weightPercent',
        accessorKey: 'weight',
        Header: SimpleHeader,
        label: t('general.weight'),
        disabled: true,
        hideError: true,
        format: 'percent',
        accessorFn: getWeightAsPercent(nonObsoleteExchangeDefs) as any,
        ValueCell: NumberValueCell,
        width: DEFAULT_EDITABLE_DATA_CELL_WIDTH,
      },
    ];
  }, [section.index, isReadOnly, isLinkedSection, toggleMenu, t, startEditingInputCell, nonObsoleteExchangeDefs, getFieldValue]);

  const FooterContent = React.useMemo(() => {
    if (isReadOnly || isLinkedSection) {
      return null;
    } else {
      return ({ height }) => {
        return (
          <Box
            sx={{
                position: 'absolute',
                left: 8,
                top: height - 40,
              }}
          >
            <AddCriterionButton
              type="button"
              onClick={() => {
                const evaluationCriterion = createEvaluationCriterion(scoringType);

                appendRows([evaluationCriterion]);
              }}
            />
          </Box>
        );
      };
    }
  }, [appendRows, isLinkedSection, isReadOnly, scoringType]);

  const bodyPaddingBottom = FooterContent ? 45 : 0;

  const maxGridHeight = (
    DEFAULT_FROZEN_HEADER_HEIGHT +
    BORDER_ADJUSTMENT +
    bodyPaddingBottom +
    DEFAULT_ROW_HEIGHT * exchangeDefs.length +
    getScrollbarSize()
  );

  return (
    <>
      {addGridRowsModal.isOpen && (
        <AddMoreItemsModal
          {...addGridRowsModal}
          heading={t('request.evaluation.addMoreCriteriaModal.heading')}
          info={(
            <Trans
              i18nKey="request.evaluation.addMoreCriteriaModal.info"
              values={{ count: additionalRowCount }}
              components={{ b: <b /> }}
            />
          )}
          onSubmit={(pasteMethod: GridPasteMethod) => {
            if (resolveWithPasteMethod.current) {
              resolveWithPasteMethod.current(pasteMethod);
            }
            resolveWithPasteMethod.current = null;
            addGridRowsModal.close();
          }}
          onCancel={() => {
            if (resolveWithPasteMethod.current) {
              resolveWithPasteMethod.current(null);
            }
            resolveWithPasteMethod.current = null;
            addGridRowsModal.close();
          }}
        />
      )}

      {exchangeDefs.length > 0 ? (
        <DefaultEditableGridStyles
          style={{ width: '100%', height: `min(100vh - ${viewportHeightDelta}px, ${maxGridHeight}px)` }}
          isReadOnly={isReadOnly}
        >
          {isReadOnly ? (
            <ReadOnlyGrid
              frozenLeftColumnIds={frozenLeftColumnIds}
              columns={columns}
              rowData={exchangeDefs as any}
            />
          ) : (
            <EditableGrid
              frozenLeftColumnIds={frozenLeftColumnIds}
              columns={columns}
              rowData={exchangeDefs as any}
              bodyPaddingBottom={bodyPaddingBottom}
              FooterContent={FooterContent}
              gridActions={gridActions}
              onGridClipboardEvent={handleGridClipboardEvent}
              navigableRange={isLinkedSection ? (
                navigableRangeWithoutActionColumn
              ) : (
                navigableRangeWithActionColumn
              )}
              selectableRange={selectableRange}
              highlightSelectionInHeaders
            />
          )}
        </DefaultEditableGridStyles>
      ) : (
        <Text color="subtext" fontSize={2} mb={2}>
          {t('request.evaluation.noEvaluationCriteriaAdded')}
        </Text>
      )}
      {menuReferenceId ? (
        <ExchangeDefsGridMenu />
      ) : (
        null
      )}
    </>
  );
};
