import { find, first, pick, reject } from 'lodash';
import { Draft, EvaluationSection, isLinkedEvaluationSection } from '@deepstream/common/rfq-utils';
import { PanelDivider, PanelPadding, ExpandablePanelSubSection } from '@deepstream/ui-kit/elements/Panel';
import { EditableGridDataProvider } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import * as rfx from '../rfx';
import * as draft from './draft';
import { SectionPanelHeading } from './SectionPanelHeading';
import { DraftPanel } from './DraftPanel';
import { EvaluationExchangeDefsGrid } from '../ui/ExchangeDefsGrid/EvaluationExchangeDefsGrid';
import { SectionConfigHeading } from './SectionConfigHeading';
import { EvaluationSectionConfigIndicators } from './EvaluationSectionConfigIndicators';
import { DraftSectionViewPanelHeader } from './DraftSectionViewPanelHeader';
import { ExchangeDefFieldValueProvider } from '../ExchangeDefFieldValueContext';
import { NO_LOT_PROXY_ID, useLotSelectItems } from './useLotSelectItems';

const ViewSectionConfig = () => {
  const { settings, sectionById } = rfx.useStructure<Draft>();
  const section = rfx.useSectionWithPosition<EvaluationSection>();
  const exchangeDefs = rfx.useSectionExchangeDefs();
  const { relativeSectionWeightById } = rfx.useEvaluationWeights();

  const nonObsoleteExchangeDefs = reject(exchangeDefs, 'isObsolete');
  const areExchangeDefsObsolete = exchangeDefs.length >= 1 && nonObsoleteExchangeDefs.length === 0;

  const lotIds = !settings.areLotsEnabled ? (
    null
  ) : isLinkedEvaluationSection(section) ? (
    sectionById[section.linkedSectionId]?.lotIds
  ) : (
    section.lotIds
  );
  const lotSelectItems = useLotSelectItems({ isEvaluation: true });
  const selectedLot = settings.areLotsEnabled
    ? find(lotSelectItems, { value: first(lotIds) || NO_LOT_PROXY_ID })
    : null;

  return (
    <ExpandablePanelSubSection
      heading={<SectionConfigHeading />}
      renderCollapsedContent={() => (
        <EvaluationSectionConfigIndicators
          criterionCount={nonObsoleteExchangeDefs.length}
          relativeSectionWeight={areExchangeDefsObsolete ? NaN : relativeSectionWeightById[section._id]}
          // @ts-expect-error ts(2322) FIXME: Type 'LotSelectItem | null | undefined' is not assignable to type 'LotSelectItem | undefined'.
          selectedLot={selectedLot}
        />
      )}
    />
  );
};

const EvaluationSectionViewPanelContent = () => {
  const section = rfx.useSectionWithPosition<EvaluationSection>();
  const linkedSection = draft.useLinkedSection();

  return (
    <DraftPanel panelId={section._id}>
      <DraftSectionViewPanelHeader
        icon="balance-scale"
        heading={(
          <SectionPanelHeading
            section={linkedSection ? (
              { ...linkedSection, ...pick(section, ['index', 'number', 'isFirst', 'isLast']) } as any
            ) : (
              section
            )}
            isHeadingNumbered
          />
        )}
        intercomEditEventName="edit-evaluation-section-opened"
      />
      <PanelDivider />
      <ViewSectionConfig />
      <PanelDivider />
      <PanelPadding>
        <EvaluationExchangeDefsGrid
          viewportHeightDelta={400}
          isReadOnly
        />
      </PanelPadding>
    </DraftPanel>
  );
};

export const EvaluationSectionViewPanel = () => {
  const exchangeDefs = rfx.useSectionExchangeDefs();

  return (
    <ExchangeDefFieldValueProvider>
      <EditableGridDataProvider
        rowData={exchangeDefs}
        enableReinitialize
      >
        <GridIdPrefixProvider>
          <GridMenuStateProvider>
            <EvaluationSectionViewPanelContent />
          </GridMenuStateProvider>
        </GridIdPrefixProvider>
      </EditableGridDataProvider>
    </ExchangeDefFieldValueProvider>
  );
};
