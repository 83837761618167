import {
  Draft,
  QuestionExchangeDefinition,
  QuestionType,
  ExchangeType,
} from '@deepstream/common/rfq-utils';
import { useCallback, useState } from 'react';
import { parse, ParseResult } from 'papaparse';
import * as yup from 'yup';
import { omit, sum } from 'lodash';
import { useTranslation } from 'react-i18next';

import { CancelButton, SaveButton, Button } from '@deepstream/ui-kit/elements/button/Button';
import { IconTextButton } from '@deepstream/ui-kit/elements/button/IconTextButton';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { FileList } from '../ui/FileList';
import { BulkImportQuestionsTable } from './BulkImportQuestionsTable';
import { useErrors, Validation } from './validation';
import { fastFakeUploadFn } from '../ui/fakeUploadFn';
import { ErrorMessage } from '../ui/ErrorMessage';
import {
  getHeaderKey,
  fileQuestionTypeToQuestionType,
  mapParsedQuestion,
  ParsedAddressQuestion,
  ParsedCheckboxesQuestion,
  ParsedDateTimeQuestion,
  ParsedLongTextQuestion,
  ParsedMultipleChoiceQuestion,
  ParsedPriceQuestion,
  ParsedQuestion,
  ParsedShortTextQuestion,
} from './bulk-upload-questions-utils';
import useDownload from '../useDownload';
import { useCurrentUserLocale } from '../useCurrentUser';
import { useModelSizeLimits } from '../modelSizeLimits';

const questionSchema = yup.lazy((parsedQuestion: ParsedQuestion) => {
  const { questionType } = parsedQuestion;
  let resultingSchema: yup.ObjectSchema;
  const commonSchema = {
    description: yup.string().required(),
    required: yup.boolean(),
  };

  switch (fileQuestionTypeToQuestionType[questionType]) {
    case QuestionType.SHORT_TEXT: {
      const { shortTextOnlyNumbers } = parsedQuestion as ParsedShortTextQuestion;
      if (!shortTextOnlyNumbers) {
        resultingSchema = yup.object().shape({
          ...commonSchema,
          questionType: yup.string<QuestionType.SHORT_TEXT>().required(),
          shortTextOnlyNumbers: yup.boolean(),
        });
        break;
      } else {
        resultingSchema = yup.object().shape<ParsedShortTextQuestion>({
          ...commonSchema,
          questionType: yup.string<QuestionType.SHORT_TEXT>().required(),
          shortTextOnlyNumbers: yup.boolean().required(),
          shortTextNumberType: yup.string().oneOf(['decimal', 'whole']).required(),
          shortTextMin: yup.number().required(),
          shortTextMax: yup.number().required(),
        });
        break;
      }
    }

    case QuestionType.LONG_TEXT:
      resultingSchema = yup.object().shape<ParsedLongTextQuestion>({
        ...commonSchema,
        questionType: yup.string<QuestionType.LONG_TEXT>().required(),
      });
      break;

    case QuestionType.MULTIPLE_CHOICE:
      resultingSchema = yup.object().shape<ParsedMultipleChoiceQuestion>({
        ...commonSchema,
        questionType: yup.string<QuestionType.MULTIPLE_CHOICE>().required(),
        multipleChoiceOptions: yup.array().of(yup.string()).min(1).required(),
        multipleChoiceOther: yup.boolean(),
      });
      break;

    case QuestionType.CHECKBOXES:
      resultingSchema = yup.object().shape<ParsedCheckboxesQuestion>({
        ...commonSchema,
        questionType: yup.string<QuestionType.CHECKBOXES>().required(),
        checkboxesOptions: yup.array().of(yup.string()).min(1).required(),
        checkboxesOther: yup.boolean(),
      });
      break;

    case QuestionType.PRICE:
      resultingSchema = yup.object().shape<ParsedPriceQuestion>({
        ...commonSchema,
        questionType: yup.string<QuestionType.PRICE>().required(),
        // @ts-expect-error ts(2322) FIXME: Type 'StringSchema<string | null>' is not assignable to type 'Ref | Schema<string>'.
        currency: yup.string().nullable(),
        specifyCurrency: yup.boolean(),
      });
      break;

    case QuestionType.ADDRESS:
      resultingSchema = yup.object().shape<ParsedAddressQuestion>({
        ...commonSchema,
        questionType: yup.string<QuestionType.ADDRESS>().required(),
        addressCompanyNameVisible: yup.boolean(),
        addressLineOneVisible: yup.boolean(),
        addressLineTwoVisible: yup.boolean(),
        addressCityVisible: yup.boolean(),
        addressStateVisible: yup.boolean(),
        addressPostcodeVisible: yup.boolean(),
        addressCountryVisible: yup.boolean(),
        addressContactNameVisible: yup.boolean(),
        addressEmailVisible: yup.boolean(),
        addressPhoneVisible: yup.boolean(),
      });
      break;

    case QuestionType.DATE_TIME:
      resultingSchema = yup.object().shape<ParsedDateTimeQuestion>({
        ...commonSchema,
        questionType: yup.string<QuestionType.DATE_TIME>().required(),
        dateTimeDateEnabled: yup.boolean(),
        dateTimeTimeEnabled: yup.boolean(),
      });
      break;

    default:
      resultingSchema = yup.object().shape(commonSchema);
      break;
  }

  const fields = Object.keys(resultingSchema.fields);
  const extraFields = Object.keys(parsedQuestion).filter(key => !fields.includes(key));
  if (extraFields.length > 0) {
    // This is to ensure all the other fields that are not part of the schema are invalid
    resultingSchema = resultingSchema.concat(
      yup.object().shape(Object.fromEntries(
        extraFields.map((key) => [key, yup.mixed().oneOf([undefined, '', null])]),
      )),
    );
  }

  return resultingSchema as yup.Schema<ParsedQuestion>;
});

const questionsListSchema = yup.array().of(questionSchema);

type Props = {
  onImport?: (questions: Partial<QuestionExchangeDefinition<Draft>>[]) => void;
  onCancel?: () => void;
} & ModalProps;

const BulkUploadPreviewMode = ({ parsedQuestions }: { parsedQuestions: ParsedQuestion[] }) => {
  const { t } = useTranslation();
  const { errors, isValid } = useErrors();
  const errorRows = Object.values(errors);
  const errorRowsCount = errorRows.length;
  // @ts-expect-error ts(2769) FIXME: No overload matches this call.
  const errorsCount = sum(errorRows.map(row => Object.keys(row).length));

  return (
    <>
      {!isValid && (
        <p>{t('request.question.bulkUpload.errors', { errorRowsCount, errorsCount })}</p>
      )}

      <BulkImportQuestionsTable parsedQuestions={parsedQuestions} />
    </>
  );
};

export const BulkUploadQuestionsModal = ({ onImport, isOpen, onCancel }: Props) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [viewMode, setViewMode] = useState<'upload' | 'preview'>('upload');
  const [questions, setQuestions] = useState<ParsedQuestion[]>([]);
  const locale = useCurrentUserLocale();
  const download = useDownload();
  const {
    maxExchangeDefCount,
  } = useModelSizeLimits();

  const downloadQuestionsSampleFiles = useCallback(
    async (fileType) => {
      const queryParams = new URLSearchParams({ locale, type: ExchangeType.QUESTION, fileType });
      const url = `/ajax/sampleFiles?${queryParams.toString()}`;

      await download(url);
    },
    [download, locale],
  );

  const handleFileUploadStart = (file: File) => {
    if (file.type !== 'text/csv') {
      setError(t('request.question.bulkUpload.fileTypeError'));
      return;
    }

    parse(file, {
      skipEmptyLines: 'greedy',
      preview: maxExchangeDefCount,
      dynamicTyping: true,
      header: true,
      transformHeader(header: string): string {
        return getHeaderKey(header) || header;
      },
      transform(value: string, header: string) {
        if (['multipleChoiceOptions', 'checkboxesOptions'].includes(header)) {
          return value.length > 0 ? value.split(/\r?\n|\r|\n/g) : value;
        }
        if (!['description'].includes(header)) {
          return value.toLowerCase().trim();
        }
        return value.trim();
      },
      complete(results: ParseResult<ParsedQuestion>) {
        setQuestions(results.data);
      },
    });
  };

  const getQuestionsWithoutInvalidProps = () => {
    return questions.map(question => {
      try {
        questionSchema.validateSync(question, { abortEarly: false });
        return question;
      } catch (validationError) {
        return omit(question, (validationError as any).inner.map(error => error.path)) as ParsedQuestion;
      }
    });
  };

  const handleOnSaveClick = () => {
    const questionDefs = getQuestionsWithoutInvalidProps().map(question => mapParsedQuestion(question));
    // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
    onImport(questionDefs);
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader onClose={onCancel}>{t('request.question.bulkUpload.modalTitle')}</ModalHeader>
      <ModalBody width="500px">
        {{
          upload: (
            <>
              <p>{t('request.question.bulkUpload.helperText')}</p>

              <FileList
                onUploadStart={handleFileUploadStart}
                uploadFn={fastFakeUploadFn}
                onChange={(attachments) => {
                  if (attachments.length === 0) {
                    setQuestions([]);
                    setError('');
                  }
                }}
                limit={1}
              />
              {error ? <ErrorMessage error={error} /> : null}

              <IconTextButton
                type="button"
                variant="secondary-subtle"
                icon="download"
                onClick={() => downloadQuestionsSampleFiles('xlsx')}
                py="8px"
              >
                {t('downloadSampleXLSX', { ns: 'general' })}
              </IconTextButton>
              <IconTextButton
                type="button"
                variant="secondary-subtle"
                icon="download"
                onClick={() => downloadQuestionsSampleFiles('csv')}
                py="8px"
              >
                {t('downloadSampleCSV', { ns: 'general' })}
              </IconTextButton>
            </>
          ),
          preview: (
            <Validation schema={questionsListSchema} values={questions}>
              <BulkUploadPreviewMode parsedQuestions={questions} />
            </Validation>
          ),
        }[viewMode]}
      </ModalBody>
      <ModalFooter>
        <CancelButton onClick={onCancel} mr={2} />
        {{
          upload: (
            <Button type="button" disabled={!questions.length} mr={2} onClick={() => setViewMode('preview')}>
              {t('general.next')}
            </Button>
          ),
          preview: (
            <SaveButton disabled={!questions.length} onClick={handleOnSaveClick} />
          ),
        }[viewMode]}
      </ModalFooter>
    </Modal>
  );
};
