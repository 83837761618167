import { compact, find, values } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RowHeight } from './rowHeight';
import { useLocalStorageState } from '../../../useLocalStorageState';

type SelectRowHeightItem = { value: RowHeight; label: string };

export const useSelectRowHeight = ({
  storageKey,
}: {
  storageKey: string;
}) => {
  const { t } = useTranslation();

  const items = useMemo(() => values(RowHeight).map(rowHeight => ({
    value: rowHeight,
    label: t(`request.comparison.rowHeights.${rowHeight}`),
  })), [t]);

  const [selectedItems, setSelectedItems] = useLocalStorageState<SelectRowHeightItem[]>({
    key: storageKey,
    defaultValue: items.slice(1, 2),
    mapInitialValue: (initialItems) => compact(
      initialItems.map(({ value }) => find(items, { value })),
    ),
  });

  return {
    itemToString: (item: SelectRowHeightItem | null) => item ? item.label : '',
    items,
    selectedItems,
    onChange: setSelectedItems,
  };
};
