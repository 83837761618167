import { useMemo } from 'react';
import { Flex, Box } from 'rebass/styled-components';
import { Form, useFormikContext } from 'formik';
import { QuestionExchangeDefinition, QuestionType } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';

import { isEqual, omit } from 'lodash';
import { ModalHeader, ModalFooter, ModalBody, CancelButton, SaveButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { TextField } from '../../../../form/TextField';
import { QuestionTypeSelector } from '../../../../draft/QuestionFields';

import { SupplierResponse } from './SupplierResponse';
import { CategorySelectorField } from '../../CategorySelectorField';

import { QuestionModalValues } from '../../utils';

const QUESTION_TYPES_TO_IGNORE = [];

export const QuestionForm = ({
  isEditMode,
  initialQuestionDef,
  initialCategoryId,
  submitDisabled,
  onCancel,
  onQuestionTypeChange,
  isCreatedFromQuestionnaireTemplate,
}: {
  isEditMode: boolean;
  initialQuestionDef?: QuestionExchangeDefinition;
  initialCategoryId?: string
  submitDisabled: boolean;
  onCancel: () => void;
  onQuestionTypeChange: (value: QuestionType) => void;
  isCreatedFromQuestionnaireTemplate: boolean;
}) => {
  const { t } = useTranslation(['preQualification', 'translation']);
  const { values, isValid, isSubmitting } = useFormikContext<QuestionModalValues>();

  const hasChanges = useMemo(
    () => {
      if (!isEditMode) return true;

      return !isEqual(values, { ...omit(initialQuestionDef, ['user', 'creatorId']), categoryId: initialCategoryId });
    },
    [values, initialQuestionDef, isEditMode, initialCategoryId],
  );

  return (
    <Form>
      <ModalHeader>
        {isEditMode ? t('questionModal.editQuestion') : t('questionModal.addQuestion')}
      </ModalHeader>
      <ModalBody>
        {isCreatedFromQuestionnaireTemplate && (
          <MessageBlock variant="info" mt={3} mb={4}>
            {t('questionModal.questionCreatedFromQuestionnaireInfo')}
          </MessageBlock>
        )}
        <Flex sx={{ gap: '16px' }}>
          <Box sx={{ flex: '1 1 70%' }}>
            <TextField
              label={t('request.question.question', { count: 1, ns: 'translation' })}
              required
              name="description"
            />
          </Box>
          <Box sx={{ flex: '1 1 30%' }}>
            <QuestionTypeSelector
              onChange={onQuestionTypeChange}
              value={values.questionType}
              // @ts-expect-error ts(2345) FIXME: Argument of type 'import("/Users/ahlsen/dev/deepstream/libs/shared/common/src/rfq-utils/types").QuestionType' is not assignable to parameter of type 'never'.
              filter={(questionType) => !QUESTION_TYPES_TO_IGNORE.includes(questionType)}
            />
          </Box>
        </Flex>
        <Box sx={{ maxWidth: '85%' }}>
          <SupplierResponse />
        </Box>
        <Box sx={{ maxWidth: '200px', marginTop: 3 }}>
          <CategorySelectorField fieldName="categoryId" />
        </Box>
      </ModalBody>
      <ModalFooter>
        <CancelButton onClick={onCancel} />
        <SaveButton
          disabled={!isValid || isSubmitting || !hasChanges || submitDisabled}
          label={isEditMode ? t('update') : undefined}
        />
      </ModalFooter>
    </Form>
  );
};
