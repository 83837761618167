import { useCallback, useState } from 'react';
import { Flex } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Illustration } from '@deepstream/ui-kit';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useApi } from '../../../api';
import { Loading } from '../../../ui/Loading';
import { ErrorMessage } from '../../../ui/ErrorMessage';
import { MIN_CELL_HEIGHT } from '../../../FieldsCell';
import { useModalState } from '../../../ui/useModalState';
import { useTableData } from '../../../TableDataContext';
import { useCurrentUser } from '../../../useCurrentUser';
import { useLocalStorageState } from '../../../useLocalStorageState';
import { UpgradeToUnlockPreQualDialog } from '../UpgradeToUnlockPreQualDialog';
import { QuestionnaireTemplatesTableData, QuestionnaireTemplatesTableDataProvider } from './QuestionnaireTemplatesTableDataProvider';
import { LiveQuestionnaireTemplatesTable } from './LiveQuestionnaireTemplatesTable';
import { ArchiveQuestionnaireTemplateDialog } from './ArchiveQuestionnaireTemplateDialog';

const ActiveTemplatesContent = () => {
  const { t } = useTranslation('preQualification');
  const upgradeDialog = useModalState();
  const archiveTemplateModal = useModalState();
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);

  const {
    query,
    pageSize,
  } = useTableData<QuestionnaireTemplatesTableData>({ required: true });

  const { status, data } = query;
  const {
    totalItems = 0,
  } = data ?? {};

  const openArchiveModal = useCallback(
    (templateId: string) => {
      setSelectedTemplateId(templateId);
      archiveTemplateModal.open();
    },
    [archiveTemplateModal],
  );

  return (
    <>
      <Panel>
        <PanelHeader
          heading={t('activeQuestionnaires')}
          sx={{ height: '60px', alignItems: 'center' }}
        />
        <PanelDivider />
        {status === 'success' && totalItems > 0 ? (
          <LiveQuestionnaireTemplatesTable openArchiveModal={openArchiveModal} />
        ) : (
          <Flex
            height={status === 'loading' ? MIN_CELL_HEIGHT * pageSize : '455px'}
            flexDirection="column"
            justifyContent="center"
          >
            <PanelPadding>
              {status === 'loading' ? (
                <Loading fontSize={4} fontWeight={400} />
              ) : status === 'error' ? (
                <ErrorMessage fontSize={3} error={t('errors.getQuestionnaireTemplates')} />
              ) : (
                <Illustration
                  variant="empty-state"
                  label={t('questionnaireTemplate.companyHasNotCreatedTemplates')}
                />
              )}
            </PanelPadding>
          </Flex>
        )}
      </Panel>
      <UpgradeToUnlockPreQualDialog
        isOpen={upgradeDialog.isOpen}
        onCancel={upgradeDialog.close}
      />
      {selectedTemplateId && (
        <ArchiveQuestionnaireTemplateDialog
          {...archiveTemplateModal}
          templateId={selectedTemplateId}
          onSuccess={archiveTemplateModal.close}
          onError={archiveTemplateModal.close}
        />
      )}
    </>
  );
};

export const ActiveQuestionnaireTemplates = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();
  const api = useApi();

  const pageSizeState = useLocalStorageState<number>({
    key: `${currentCompanyId}.${user._id}.activeQuestionnaireTemplates.pageSize`,
    defaultValue: 10,
  });

  return (
    <QuestionnaireTemplatesTableDataProvider
      queryTag="activeQuestionnaireTemplates"
      queryFn={api.getActiveQuestionnaireTemplates}
      pageSizeState={pageSizeState}
    >
      <ActiveTemplatesContent />
    </QuestionnaireTemplatesTableDataProvider>
  );
};
