import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Flex, Box, Text, Heading } from 'rebass/styled-components';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import {
  ChangeType,
  LockType,
  SenderAddedChange,
  SenderUpdatedChange,
  SenderRemovedChange,
  SenderChange,
  CollaboratorInviteStatus,
  Sender,
} from '@deepstream/common/rfq-utils';
import { cloneDeep, pick, isEmpty, findIndex } from 'lodash';
import { diffArrayBy } from '@deepstream/utils';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton, EditButton, Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelHeader, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { ConfirmDeleteDialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { ModalForm } from '../../../ModalForm';
import { useDeviceSize } from '../../../ui/useDeviceSize';
import { TextField } from '../../../form/TextField';
import { useConfirmDialog, useModalState } from '../../../ui/useModalState';
import { LabelConfig, LabelConfigProvider } from '../../../LabelConfigProvider';
import { Tab, TabListPanel, TabPanels, TabPanel, Tabs } from '../../../ui/TabsVertical';
import { SidebarLayout } from '../../../ui/ProfileLayout';
import * as rfx from '../../../rfx';
import { RfxStructure } from '../../../types';
import { Bold } from '../../../Bold';
import { RequestTeamUsersPanel } from './RequestTeamUsersPanel';
import { ReadOnlySenderTeamDetails } from './ReadOnlySenderTeamDetails';
import { CompanyFinderForm } from '../../../CompanyFinderForm';
import { LeavePageModal } from '../../../draft/LeavePageModal';

const panelId = 'companyDetails';

const labelStyles = {
  name: {
    fontSize: 2,
    position: 'relative',
    top: '12px',
  },
};

const LABEL_WIDTH = '200px';

const hasTeamMemberUnlockCriterion = (obj: { locking?: { type: LockType } }) => (
  obj.locking &&
  [LockType.TEAM_MEMBER, LockType.TEAM_MEMBER_AFTER_BID_DEADLINE]
    .includes(obj.locking.type)
);

function hasTeamMemberLocks(structure: RfxStructure) {
  return (
    Object.values(structure.sectionById).some(hasTeamMemberUnlockCriterion) ||
    Object.values(structure.exchangeDefById).some(hasTeamMemberUnlockCriterion)
  );
}

const getSenderChanges = (values: Sender[], initialValues: Sender[]): SenderChange[] => {
  const diffSenders = diffArrayBy(values, initialValues, 'company._id');

  const sendersAdded: SenderAddedChange[] = diffSenders.added.map((item) => ({
    type: ChangeType.SENDER_ADDED,
    sender: {
      _id: item.company._id,
      role: item.company.role,
      company: pick(item.company, ['_id', 'name']),
      inviteStatus: CollaboratorInviteStatus.PENDING,
      users: [],
    },
  }));

  const sendersUpdated: SenderUpdatedChange[] = diffSenders.updated.map((item) => ({
    type: ChangeType.SENDER_UPDATED,
    sender: {
      _id: item.company._id,
      role: item.company.role,
    },
  }));

  const sendersRemoved: SenderRemovedChange[] = diffSenders.removed.map((item) => ({
    type: ChangeType.SENDER_REMOVED,
    senderId: item.company._id,
  }));

  const changes = [...sendersAdded, ...sendersUpdated, ...sendersRemoved];

  return changes;
};

const updateSenderRole = (senders: Sender[], companyId: string, role?: string) => {
  const updatedSenders = senders.map((item) => {
    if (item._id === companyId) {
      item.company.role = role;
      return item;
    }
    return item;
  });

  return updatedSenders;
};

const CompanyDetailsPanel = ({ sender }: { sender: Sender }) => {
  const { t } = useTranslation();

  const { isLive, editingPanelId, isTemplate } = rfx.useState();
  const { canEditTeam } = rfx.useRfxPermissions();
  const { senders } = rfx.useStructure();
  const { startEditing, stopEditing } = rfx.useActions();
  const saveChanges = rfx.useSaveChanges();

  const isEditing = Boolean(editingPanelId);
  const canEdit = canEditTeam && (!isLive || isTemplate);

  return (
    <Panel
      mt={4}
      sx={{ boxShadow: isEditing ? '0 0 8px 0 rgba(0, 0, 0, 0.3)' : '' }}
    >
      <Formik
        validateOnBlur
        enableReinitialize
        initialValues={{
          companyRole: sender.company.role,
        }}
        validationSchema={
          yup.object().shape({
            companyRole: yup.string(),
          })
        }
        onSubmit={async (values) => {
          const clonedSenders = cloneDeep(senders);
          const updatedSenders = updateSenderRole(clonedSenders, sender._id, values.companyRole);

          await saveChanges({
            changes: getSenderChanges(
              updatedSenders,
              senders,
            ),
          }, {
            onSuccess: stopEditing,
          });
        }}
      >
        {({ isSubmitting, dirty, isValid, resetForm }) => (
          <>
            <PanelHeader heading={t('general.details')} collapse={false}>
              <Flex alignItems="center">
                {canEdit && (
                  <EditButton
                    small
                    onClick={() => startEditing(`${panelId}-${sender._id}`)}
                    disabled={isEditing}
                  />
                )}
              </Flex>
            </PanelHeader>
            <PanelDivider />
            {editingPanelId === `${panelId}-${sender._id}` ? (
              <Form>
                <Flex width={9.5 / 10} ml={3} mt={3} mb={3} alignItems="center">
                  <TextField
                    name="companyRole"
                    label={t('general.companyRole')}
                    helperText={t('teamManagement.describeCompanyRole')}
                    required
                    // quick fix: we can remove setting the inputStyle height once a line height is
                    // set for Input
                    inputStyle={{ height: 40 }}
                  />
                </Flex>
                <PanelDivider />
                <PanelPadding>
                  <Flex justifyContent="flex-end">
                    <CancelButton
                      onClick={() => {
                        stopEditing();
                        resetForm();
                      }}
                      mr={2}
                    />
                    <SaveButton disabled={isSubmitting || !dirty || !isValid} />
                  </Flex>
                </PanelPadding>
                <LeavePageModal />
              </Form>
            ) : (
              <ReadOnlySenderTeamDetails sender={sender} />
            )}
          </>
        )}
      </Formik>
    </Panel>
  );
};

const TeamTabPanel = ({ sender, selectCompanyId }: { sender: Sender; selectCompanyId: (companyId: string) => void }) => {
  const { t } = useTranslation();
  const { senders, teamById } = rfx.useStructure();
  const { canEditTeam } = rfx.useRfxPermissions();
  const { isLive, isRevising, editingPanelId, isTemplate } = rfx.useState();
  const { stopEditing } = rfx.useActions();
  const saveChanges = rfx.useSaveChanges();

  const isEditing = Boolean(editingPanelId);
  const nonRejectedSenders = senders.filter(sender => sender.inviteStatus !== CollaboratorInviteStatus.REJECTED);

  const companyIsCollaborator = useMemo(
    () => findIndex(nonRejectedSenders, { _id: sender._id }) > 0,
    [nonRejectedSenders, sender],
  );
  const companyHasAnyUser = !isEmpty(teamById[sender._id].users);
  const canRemove = (companyIsCollaborator && canEditTeam) &&
    ((!isLive && !isRevising) || isTemplate);

  const { confirm, ...dialogProps } = useConfirmDialog();

  const removeCollaborator = async () => {
    await saveChanges({
      changes: getSenderChanges(
        nonRejectedSenders.filter(item => item._id !== sender._id),
        nonRejectedSenders,
      ),
    }, {
      onSuccess: callAll(
        stopEditing,
        // This timeout is only here to improve the UX and doesn't have any
        // impact on the functionality of the app
        () => setTimeout(() => {
          selectCompanyId(nonRejectedSenders[0]._id);
        }, 500),
      ),
    });
  };

  return (
    <LabelConfigProvider
      variant={LabelConfig.LEFT}
      width={LABEL_WIDTH}
      style={labelStyles}
    >
      <TabPanel>
        <Flex justifyContent="space-between" alignItems="center" mt={2} minHeight="40px">
          <Heading fontWeight={500} fontSize={5}>{sender.company.name}</Heading>
          {canRemove && (
            <Button
              type="button"
              variant="danger-outline"
              iconLeft="times"
              onClick={() => (
                companyHasAnyUser
                  ? confirm(async () => removeCollaborator())
                  : removeCollaborator()
              )}
              sx={{ backgroundColor: 'transparent' }}
              disabled={isEditing}
            >
              {t('teamManagement.removeCollaborator')}
            </Button>
          )}
        </Flex>
        <CompanyDetailsPanel sender={sender} />
        {isTemplate ? (
          <Box>
            <MessageBlock variant="info">
              {t('teamManagement.templateView.editUserInfo')}
            </MessageBlock>
          </Box>
        ) : (
          <RequestTeamUsersPanel
            company={sender.company}
            mt={4}
          />
        )}
      </TabPanel>
      <ConfirmDeleteDialog
        heading={t('request.team.dialog.confirmRemoveCollaborator.heading', { companyName: sender.company.name })}
        message={t('request.team.dialog.confirmRemoveCollaborator.body')}
        okButtonText={t('general.remove')}
        {...dialogProps}
      />
    </LabelConfigProvider>
  );
};

type AddCollaboratorModalProps = {
  isOpen: boolean;
  close: any;
  selectCompanyId: (_id: string) => void;
};

export const AddCollaboratorModal = ({
  isOpen,
  close,
  selectCompanyId,
}: AddCollaboratorModalProps) => {
  const { t } = useTranslation();
  const structure = rfx.useStructure();
  const saveChanges = rfx.useSaveChanges();

  const nonRejectedSenders = structure.senders.filter(sender => sender.inviteStatus !== CollaboratorInviteStatus.REJECTED);

  const hasLocks = hasTeamMemberLocks(structure);

  const initialValues = {
    companyInput: '',
    company: '',
  };

  const addCollaborator = async (values) => {
    const clonedSenders = cloneDeep(nonRejectedSenders);
    const updatedSenders = [...clonedSenders, {
      company: values.company,
      _id: values.company._id,
      inviteStatus: CollaboratorInviteStatus.PENDING,
    } as Sender];

    await saveChanges({
      changes: getSenderChanges(
        updatedSenders,
        clonedSenders,
      ),
    }, {
      onSuccess: callAll(
        close,
        () => selectCompanyId(values.company._id),
      ),
    });
  };

  return (
    <ModalForm
      heading={t('teamManagement.addCollaborator')}
      initialValues={initialValues}
      validationSchema={
        yup.object().shape({
          company: yup.object().required(t('companyFinder.selectACompany')),
        })
      }
      disableSubmitIfNotDirty={true}
      isOpen={isOpen}
      onCancel={close}
      onSubmit={addCollaborator}
      submitLabel={t('teamManagement.addCollaborator')}
    >
      <Box maxWidth="500px">
        <CompanyFinderForm required disabledCompanies={nonRejectedSenders} label={t('general.company')} />
        {hasLocks && (
          <MessageBlock variant="info">
            <Trans i18nKey="teamManagement.dialog.defaultLock">
              The default lock setting for this company will be
              <Bold>Can’t unlock</Bold> but this can be edited on the <Bold>Details</Bold> tab
            </Trans>
          </MessageBlock>
        )}
      </Box>
    </ModalForm>
  );
};

export const RequestTeam = ({
  selectedCompanyIndex,
  selectCompanyId,
}: {
  selectedCompanyIndex: number;
  selectCompanyId: (companyId: string) => void;
}) => {
  const { t } = useTranslation();
  const { isExtraSmall, isSmall } = useDeviceSize();
  const { canEditTeam } = rfx.useRfxPermissions();
  const addCollaboratorModal = useModalState();
  const { isLive, isRevising, editingPanelId, isTemplate } = rfx.useState();
  const isEditing = Boolean(editingPanelId);

  const { senders } = rfx.useStructure();

  const nonRejectedSenders = senders.filter(sender =>
    sender.inviteStatus !== CollaboratorInviteStatus.REJECTED,
  );

  return (
    <>
      <Tabs
        index={selectedCompanyIndex}
        onChange={index => selectCompanyId(nonRejectedSenders[index]._id)}
        key={selectedCompanyIndex}
      >
        <SidebarLayout
          sidebar={
            <TabListPanel
              heading={t('general.companies')}
              headerButton={(canEditTeam && !isLive && !isRevising) || (canEditTeam && isTemplate) ? (
                <Button
                  small
                  variant="primary"
                  iconLeft="plus"
                  onClick={addCollaboratorModal.open}
                  disabled={isEditing}
                >
                  {t('teamManagement.addCollaborator')}
                </Button>
              ) : (
                null
              )}
            >
              {nonRejectedSenders.map(sender => (
                <Tab key={sender._id} data-test={sender._id} disabled={isEditing}>
                  <Flex alignItems="center">
                    <Text flex={1}>{sender.company.name}</Text>
                  </Flex>
                </Tab>
              ))}
            </TabListPanel>
          }
          main={
            <TabPanels>
              {nonRejectedSenders.map(sender => (
                <TeamTabPanel
                  key={sender._id}
                  sender={sender}
                  selectCompanyId={selectCompanyId}
                />
              ))}
            </TabPanels>
          }
          sidebarStyle={!isExtraSmall && !isSmall ? { maxWidth: '363px', flex: '0 0 auto' } : undefined}
          mainStyle={!isExtraSmall && !isSmall ? { maxWidth: '763px', flex: '0 0 auto' } : undefined}
        />
      </Tabs>
      {addCollaboratorModal.isOpen && (
        <AddCollaboratorModal
          isOpen={addCollaboratorModal.isOpen}
          close={addCollaboratorModal.close}
          selectCompanyId={selectCompanyId}
        />
      )}
    </>
  );
};
