import { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Text, Heading, FlexProps, Flex } from 'rebass/styled-components';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ConfirmDeleteDialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { SupplierListType } from '@deepstream/common';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { SupplierListTabs } from './SupplierListTabs';
import { SupplierListTab, listTypeIconProps } from './utils';
import { PropertyList, PropertyListAction } from '../../PropertyList';
import { useSupplierListData } from './SupplierListProvider';
import { useConfirmDialog, useModalState } from '../../ui/useModalState';
import { useDeleteSupplierList } from './useDeleteSupplierList';
import { useSupplierListNavigation } from '../../appNavigation';
import { QuestionnaireRules } from './QuestionnaireRules';
import { useIsAuthorizedStakeholder } from '../../useIsAuthorizedStakeholder';
import { EditListNameModal } from './EditListNameModal';
import { EditListDescriptionModal } from './EditListDescriptionModal';
import { WordWrap } from '../../WordWrap';

const tabId = SupplierListTab.SETTINGS;
const DEFAULT_SECTION_WIDTH = '773px';

const Section = ({ heading, children }) => {
  return (
    <Stack as="section" gap={3} maxWidth={DEFAULT_SECTION_WIDTH}>
      <Heading as="h2" fontSize={6} fontWeight={500}>
        {heading}
      </Heading>
      {children}
    </Stack>
  );
};

const ListDescriptionValue = ({ value }) => {
  return value ? (
    <WordWrap>{value}</WordWrap>
  ) : (
    <EmDash />
  );
};

const ListTypeValue = ({ value }: { value: number }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Text>
        <Icon {...listTypeIconProps[value]} mr="6px" mt="2px" />
        {t(`supplierLists.listType.${value}`)}
      </Text>
      <Text color="subtext">
        {t(`supplierLists.listTypeDescription.${value}`)}
      </Text>
    </Box>
  );
};

export const Row = (props: FlexProps) => (
  <Flex
    fontSize={2}
    width="100%"
    p="12px 0px !important"
    lineHeight={1.3}
    {...props}
  />
);

export const SettingsTab = () => {
  const { t } = useTranslation();
  const supplierList = useSupplierListData();
  const { confirm, ...confirmDialogProps } = useConfirmDialog();
  const { navigateToLists, navigateToListEdit } = useSupplierListNavigation();
  const [deleteSupplierList] = useDeleteSupplierList({
    onSuccess: () => navigateToLists(),
  });
  const isAuthorizedStakeholder = useIsAuthorizedStakeholder();
  const editNameModal = useModalState();
  const editDescriptionModal = useModalState();

  const properties = useMemo(
    () => [
      {
        name: t('supplierLists.name'),
        value: supplierList.name,
        heightAuto: true,
      },
      {
        name: t('supplierLists.description'),
        value: supplierList.description,
        Component: ListDescriptionValue,
        heightAuto: true,
      },
      {
        name: t('supplierLists.type'),
        value: supplierList.listType,
        Component: ListTypeValue,
        heightAuto: true,
      },
    ],
    [supplierList, t],
  );

  const onDelete = useCallback(
    async () => {
      confirm(async () => {
        await deleteSupplierList(supplierList._id);
      });
    },
    [deleteSupplierList, supplierList, confirm],
  );

  return (
    <Stack gap="40px">
      <SupplierListTabs selectedTab={tabId} />
      <Section heading={t('supplierLists.listDetails')}>
        <PropertyList properties={properties} Row={Row} hasLastRowBorder rowBorder="secondary">
          {!isAuthorizedStakeholder && (
            <PropertyListAction
              label={t('general.edit')}
              icon="pencil"
              variant="secondary"
              onClick={editNameModal.open}
              sx={{ position: 'relative', top: '9px' }}
            />
          )}
          {!isAuthorizedStakeholder && (
            <PropertyListAction
              label={t('general.edit')}
              icon="pencil"
              variant="secondary"
              onClick={editDescriptionModal.open}
              sx={{ position: 'relative', top: '9px' }}
            />
          )}
        </PropertyList>
      </Section>
      {supplierList.listType === SupplierListType.AUTOMATIC && (
        <Section heading={t('supplierLists.listRules')}>
          <Stack gap={3}>
            <Text>
              <Trans
                i18nKey="supplierLists.listRulesDescription"
                ns="translation"
                components={{ b: <b /> }}
              />
            </Text>
            <QuestionnaireRules rules={supplierList.rules} />
          </Stack>
        </Section>
      )}
      {!isAuthorizedStakeholder && (
        <Section heading={t('supplierLists.editList')}>
          <Stack gap={3}>
            <Text>
              {t('supplierLists.editListDescription')}
            </Text>
            <Box>
              <Button iconLeft="pencil" variant="secondary" onClick={() => navigateToListEdit()}>
                {t('supplierLists.editList')}
              </Button>
            </Box>
          </Stack>
        </Section>
      )}
      {!isAuthorizedStakeholder && (
        <Section heading={t('supplierLists.deleteList')}>
          <Stack gap={3}>
            <Text>{t('supplierLists.deleteListDescription')}</Text>
            <Box>
              <Button iconLeft="trash" variant="danger" onClick={onDelete}>
                {t('supplierLists.deleteList')}
              </Button>
            </Box>
          </Stack>
        </Section>
      )}
      <ConfirmDeleteDialog
        heading={t('supplierLists.dialog.confirmDeleteList.heading')}
        message={t('supplierLists.dialog.confirmDeleteList.message')}
        okButtonText={t('supplierLists.deleteList')}
        {...confirmDialogProps}
      />
      <EditListNameModal
        isOpen={editNameModal.isOpen}
        close={editNameModal.close}
      />
      <EditListDescriptionModal
        isOpen={editDescriptionModal.isOpen}
        close={editDescriptionModal.close}
      />
    </Stack>
  );
};
