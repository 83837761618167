import {
  ExchangeDefinition,
  ExchangeType,
  FieldConfig,
  isBuyerReplyField,
  isDefinitionField,
  isFormulaField,
  isReplyField,
  isSupplierReplyField,
} from '@deepstream/common/rfq-utils';
import { Box, Text } from 'rebass/styled-components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { useGetFormattedFormula } from '../../ui/ExchangeDefsGrid/useFormattedTotalCostFormula';
import { useConfigurableExchangeFieldColumns } from '../../ui/ExchangeDefsGrid/useConfigurableFieldColumns';
import { PreWrapCell2 } from '../../PreWrapCell';
import { IncompleteCellShouldNotReply, IncompleteCellShouldReply, NoResponseCellShouldNotReply, NoResponseCellShouldReply } from '../../NoResponseCell';
import { TargetPriceWithTotalCell } from '../../draft/cell';

const isNotTargetPriceField = (field: FieldConfig) => field._id !== 'targetPrice';

const InternalOnlyHeader = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <Box as="label" sx={{ display: 'inline-flex' }} flexDirection="column">
      <Text fontSize={2} fontWeight={500}>
        {children}
      </Text>
      <Text fontSize={1} color="subtext" mt={1}>
        <Icon icon="eye-slash" mr={1} />
        {t('request.exchange.internalOnly', { ns: 'translation' })}
      </Text>
    </Box>
  );
};

export const useLineItemExchangeModalColumns = ({
  isRecipient,
  exchangeDef,
  orderedFieldIds,
  setEmptyCell,
  addLocks,
}: {
  isRecipient?: boolean,
  exchangeDef: ExchangeDefinition | undefined,
  orderedFieldIds?: string[],
  setEmptyCell?: boolean,
  addLocks?: boolean,
}) => {
  const { t } = useTranslation();
  const isLineItem = exchangeDef?.type === ExchangeType.LINE_ITEM;
  const getFormattedFormula = useGetFormattedFormula(exchangeDef?.fields);

  const EmptyBuyerReplyCell = isRecipient
    ? NoResponseCellShouldNotReply
    : NoResponseCellShouldReply;
  const EmptySupplierReplyCell = isRecipient
    ? NoResponseCellShouldReply
    : NoResponseCellShouldNotReply;
  const EmptyFormulaCell = isRecipient
    ? IncompleteCellShouldReply
    : IncompleteCellShouldNotReply;

  const getColumnProps = React.useCallback((field) => {
    const fieldsetId = field._id.split(':')[0];
    const label = field.label || t(`request.fields.predefinedFieldLabel.${fieldsetId}`);
    const isFormula = isFormulaField(field);
    const formattedFormula = isFormula ? getFormattedFormula(field._id) : null;

    return {
      width: 190,
      Header: () => (
        <Text as="label" fontSize={2} fontWeight={500}>
          {label}
          {formattedFormula && (
            <Tooltip content={formattedFormula}>
              <Icon icon="info-circle" ml={1} color="lightNavy" />
            </Tooltip>
          )}
        </Text>
      ),
      textAlign: null,
      accessor: isDefinitionField(field)
        ? `def[${field._id}]`
        : isReplyField(field)
          ? `latestReply[${field._id}]`
          : isFormula
            ? `computedFormulas[${field._id}]`
            : '',
      ...(field.type === 'string' ? { Cell: PreWrapCell2 } : {}),
      ...(setEmptyCell && isBuyerReplyField(field) ? (
        { EmptyCell: EmptyBuyerReplyCell }
      ) : setEmptyCell && isSupplierReplyField(field) ? (
        { EmptyCell: EmptySupplierReplyCell }
      ) : setEmptyCell && isFormula ? (
        { EmptyCell: EmptyFormulaCell }
      ) : (
        undefined
      )),
      ...(fieldsetId === 'targetPrice' ? {
        Cell: TargetPriceWithTotalCell,
        Header: () => (
          <InternalOnlyHeader>
            {t('request.lineItems.targetPrice', { ns: 'translation' })}
          </InternalOnlyHeader>
        ),
      } : {}),
      ...(fieldsetId === 'leadTime' ? {
        Header: t('request.lineItems.leadTime', { ns: 'translation' }),
        format: { suffix: ` ${t('request.lineItems.workingDaysSuffix', { ns: 'translation' })}` },
      } : {}),
    };
  }, [t, getFormattedFormula, setEmptyCell, EmptyBuyerReplyCell, EmptySupplierReplyCell, EmptyFormulaCell]);

  return useConfigurableExchangeFieldColumns({
    fields: isLineItem ? exchangeDef?.fields : undefined,
    orderedFieldIds,
    fieldsFilter: isRecipient ? isNotTargetPriceField : null,
    columnProps: getColumnProps,
    addLocks,
  });
};
