import { find, isEmpty, last, uniq } from 'lodash';
import { useMemo } from 'react';
import {
  Live,
  RfxSection,
  StageResponseTag,
  getTagFromStageId,
} from '@deepstream/common/rfq-utils';
import { Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import * as rfx from '../../../../rfx';
import { useLocalStorageState } from '../../../../useLocalStorageState';

type SelectStageResponseTagsItem = { value: StageResponseTag; label: string; shortLabel: string };

export const Heading = styled(Text)`
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: ${props => props.theme.fontSizes[1]}px;
`;

export const useSelectStageResponseTags = ({
  storageKey,
  nonObsoleteSectionIds,
}: {
  storageKey: string;
  nonObsoleteSectionIds: string[],
}) => {
  const { t } = useTranslation();
  const { stages, sectionById, bidById } = rfx.useStructure<Live>();

  const items = useMemo(() => {
    const activatedStageIds = uniq(
      Object.values(bidById).flatMap(bid => bid.activatedStageIds),
    );

    const tags = uniq(
      nonObsoleteSectionIds
        .flatMap(sectionId => {
          const section = sectionById[sectionId] as RfxSection | undefined;

          return section?.responseTagConfig?.tags || [];
        }),
    );

    return stages
      .filter(stage => activatedStageIds.includes(stage._id))
      .map((stage, index) => {
        const shortLabel = `${t('general.stage', { count: 1 })} ${index + 1}`;
        return {
          value: getTagFromStageId(stage._id),
          label: stage.name
            ? `${shortLabel} – ${stage.name}`
            : `${shortLabel}`,
          shortLabel,
        };
      })
      .filter(({ value }) => tags.includes(value));
  }, [stages, nonObsoleteSectionIds, sectionById, bidById, t]) as { value: StageResponseTag, label: string; shortLabel: string }[];

  const [selectedItems, setSelectedItems] = useLocalStorageState<SelectStageResponseTagsItem[]>({
    key: storageKey,
    // @ts-expect-error ts(2322) FIXME: Type '({ value: `stage:${string}`; label: string; shortLabel: string; } | undefined)[]' is not assignable to type 'SelectStageResponseTagsItem[] | (() => SelectStageResponseTagsItem[])'.
    defaultValue: isEmpty(items) ? [] : [last(items)],
    // @ts-expect-error ts(2322) FIXME: Type '(initialItems: SelectStageResponseTagsItem[]) => ({ value: `stage:${string}`; label: string; shortLabel: string; } | undefined)[]' is not assignable to type '(value: SelectStageResponseTagsItem[]) => SelectStageResponseTagsItem[]'.
    mapInitialValue: (initialItems) => {
      const initialItem = last(initialItems);
      const mappedInitialItem = initialItem
        ? find(items, { value: initialItem.value })
        : null;

      return mappedInitialItem ? (
        [mappedInitialItem]
      ) : !isEmpty(items) ? (
        [last(items)]
      ) : (
        []
      );
    },
  });

  return {
    itemToString: (item: SelectStageResponseTagsItem | null) => item ? item.value : '',
    renderItem: (item: SelectStageResponseTagsItem | null) => item ? item.label : '',
    items: items ?? [],
    selectedItems,
    onChange: setSelectedItems,
  };
};
