import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { useCollapsibleHeader } from './CollapsibleHeaderContext';

export const HeaderToggleButton = (props: ButtonProps) => {
  const collapsibleHeader = useCollapsibleHeader();
  const { t } = useTranslation();

  return (
    <Button
      className="keep-visible"
      iconLeft={collapsibleHeader.isCollapsed ? 'arrow-down' : 'arrow-up'}
      small
      variant="secondary-outline"
      onClick={() => collapsibleHeader.toggleCollapsed()}
      {...props}
    >
      {collapsibleHeader.isCollapsed ? (
        t('collapsibleHeaderToggle.expand')
      ) : (
        t('collapsibleHeaderToggle.collapse')
      )}
    </Button>
  );
};
