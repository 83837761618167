import { BulletinExchangeDefinition } from '@deepstream/common/rfq-utils';
import { ExchangeSnapshot } from '../../types';

/**
 * Depending on the side of the app (buyer/supplier), a bulletin is represented through an exchange def or an exchange snapshot
 */
export type Bulletin = (BulletinExchangeDefinition & { rfqId: string, bulletinIndex: number }) | ExchangeSnapshot;

const isBulletinExchange = (
  bulletin: Bulletin,
): bulletin is ExchangeSnapshot =>
  (bulletin as ExchangeSnapshot).def !== undefined;

export const getBulletinAttachments = (
  bulletin: Bulletin,
): BulletinExchangeDefinition['attachments'] | undefined => {
  if (isBulletinExchange(bulletin)) {
    return (bulletin.def as BulletinExchangeDefinition)?.attachments;
  } else {
    return bulletin.attachments;
  }
};
