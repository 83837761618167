import { isEmpty, isNumber, sortBy } from 'lodash';
import { ActionSubtype, ActionType, ExchangeType, LineItemExchangeDefinition, getFieldIdsInDefaultDisplayOrder, getStageIdFromTag } from '@deepstream/common/rfq-utils';
import { memo, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, FlexProps, Text } from 'rebass/styled-components';
import { getChangedRevisionFields } from '@deepstream/common/rfq-utils/exchangeAction';
import { DateFormat, localeFormatDate, localeFormatPrice } from '@deepstream/utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { Avatar } from '../Avatar';
import { Bold } from '../Bold';
import { Visibility } from '../Visibility';
import { useActionNotificationSubject } from './useActionNotificationSubject';
import { RevisionChangesTable } from './RevisionChangesTable';
import { DynamicFieldsRevisionChangesTable } from './DynamicFieldsRevisionChangesTable';
import { useExchange } from '../useExchange';
import { QuestionResponse } from '../QuestionResponse';
import { RfqAttachmentList } from './AttachmentLink';
import { ContractAttachmentList } from '../modules/Contracts/ContractAttachment';
import { useCurrentUserLocale } from '../useCurrentUser';
import { BulletinPost } from './BulletinPost';
import { DistanceToNow } from '../DistanceToNow';
import { Datetime2 } from '../Datetime';
import { ContextType, useHooks } from '../useHooks';
import { QuestionnaireAttachmentList } from '../modules/PreQualification/Questionnaire/QuestionnaireAttachment';
import { LineItemModalLatestResponseStageInfo } from '../modules/Request/Recipient/LineItemLatestResponseStageInfo';
import { BulletinRevisionChanges } from './BulletinRevisionChanges';

const ToggleChanges = ({ isOpen, ...props }) => {
  return (
    <IconButton icon={isOpen ? 'chevron-down' : 'chevron-right'} {...props} />
  );
};

const NotVisibleToSupplier = () => {
  const { t } = useTranslation();

  return (
    <IconText
      mt={1}
      fontSize={1}
      lineHeight={1.4}
      icon="eye-slash"
      text={t('request.exchange.notVisibleToSupplier')}
      color="subtext"
      isIconRegular
    />
  );
};

const ActionIcon = ({ icon, iconBackgroundColor }) => (
  <Flex
    width={18}
    height={18}
    bg={iconBackgroundColor}
    sx={{ borderRadius: 25 }}
    alignItems="center"
    justifyContent="center"
  >
    <Icon icon={icon} color="white" fontSize="8px" />
  </Flex>
);

const ActionHeading = ({ action }) => {
  const { description, user } = action;
  const name = user?.name;
  const timestamp = action.type === ActionType.INITIATE
    ? action.def.publishedAt || action.date
    : action.date;
  // account for missing timestamp in the case of
  // locked exchanges
  const date = timestamp ? new Date(timestamp) : null;

  return (
    <Text color="darkGray" ml={2}>
      {name && (
        <Bold display="inline">{name} </Bold>
      )}
      {description && (
        <Text display="inline">{description}.</Text>
      )}
      {date && (
        <Tooltip
          content={<Datetime2 value={date} format={DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ} />}
        >
          <Text color="subtext" ml={2} fontSize={1} lineHeight={1.4} display="inline">
            <DistanceToNow date={date} />
          </Text>
        </Tooltip>
      )}
    </Text>
  );
};

const LineItemFieldValue = ({
  field,
  decimalPlaces = 2,
}: {
  field: { _id: string; type: string; value: any; currency?: any; },
  decimalPlaces?: number,
}) => {
  const { t } = useTranslation(['request', 'translation']);
  const locale = useCurrentUserLocale();

  switch (field.type) {
    case 'date': {
      return field.value
        ? localeFormatDate(new Date(field.value), DateFormat.DD_MMM_YYYY, { locale })
        : <EmDash />;
    }
    case 'number': {
      if (!isNumber(field.value)) {
        return <EmDash />;
      }

      return field._id === 'leadTime:submitter'
        ? `${field.value} ${t('request.lineItems.workingDaysSuffix', { ns: 'translation' })}`
        : field.value;
    }
    case 'price':
      return isNumber(field.value)
        ? localeFormatPrice(field.value ?? 0, field.currency, { locale, decimalPlaces })
        : <EmDash />;
    case 'boolean':
      return field.value
        ? t('lineItems.cell.boolean.accepted')
        : t('lineItems.cell.boolean.rejected');
    default:
      return field.value || <EmDash />;
  }
};

const LineItemFieldsTable = ({ action, exchange }) => {
  const { t } = useTranslation('translation');
  const { useExchangeDefById } = useHooks();
  const exchangeDefById = useExchangeDefById();

  const sortedFields = useMemo(() => {
    const exchangeDef = exchangeDefById[exchange._id] as LineItemExchangeDefinition;
    const orderedFieldIds = (
      exchangeDef.orderedFieldIds ||
      getFieldIdsInDefaultDisplayOrder(Object.keys(exchangeDef.fields))
    );

    return sortBy(
      action.fields,
      field => orderedFieldIds.indexOf(field._id),
    );
  }, [action.fields, exchange._id, exchangeDefById]);

  return (
    <Box as="table" my={1}>
      <Box as="tbody">
        {sortedFields.map((field, index) => (
          <Box as="tr" key={index}>
            <Box as="td" py="2px" pr={3}>
              <Text color="subtext" fontSize={1} lineHeight={1.4}>
                {field.name || t(`request.fields.predefinedFieldLabel.${field._id.split(':')[0]}`)}
              </Text>
            </Box>
            <Box as="td" py="2px">
              <LineItemFieldValue field={field} decimalPlaces={exchange.def.fields[field._id]?.decimalPlaces} />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

type ExchangeActionProps =
  Omit<FlexProps, 'action'> & {
    action: any;
    hideAvatar: boolean;
    canRespond: boolean;
    canRenderLatestResponseStageLink?: boolean;
    /**
     * The history events up to (and including) the current action.
     */
    historySlice: any[];
  };

export const HistoryAction = memo(({
  action,
  canRespond,
  hideAvatar,
  historySlice,
  canRenderLatestResponseStageLink,
  ...props
}: ExchangeActionProps) => {
  const { t } = useTranslation(['exchangeActionLabel', 'translation']);
  const exchange = useExchange();
  const isBulletinExchange = exchange.def.type === ExchangeType.BULLETIN;
  const [areDetailsVisible, setAreDetailsVisible] = useState(false);
  const actionNotificationSubject = useActionNotificationSubject();
  const { contextType, useContextId } = useHooks();
  const contextId = useContextId();

  if (!contextId) {
    throw new Error('No `contextId` was provided');
  }

  const notificationSubjectRef = actionNotificationSubject({
    exchange,
    action,
  });

  const changedRevisionFields = useMemo(
    () => getChangedRevisionFields(exchange, action, historySlice),
    [exchange, action, historySlice],
  );

  const isContractExchange = exchange.def.type === ExchangeType.CONTRACT;

  const hasAttachments = !isEmpty(action.def?.attachments || action.attachments || action.response?.value?.attachments);

  const shouldShowToggleButton = (action.subtype !== ActionSubtype.CLEAR && action.fields?.length) ||
    (action.type === ActionType.DEVIATE) ||
    (changedRevisionFields && !isEmpty(changedRevisionFields)) ||
    (action.hideFromRoles && action.hideFromRoles.includes('submitter')) ||
    (exchange.def.type === ExchangeType.QUESTION && action.type === ActionType.SUBMIT && action.subtype !== ActionSubtype.CLEAR) ||
    (action.comment && action.type !== ActionType.NONE) ||
    (hasAttachments && !(exchange.isChat || exchange.isClarification)) ||
    (action.type === ActionType.REFER_TO_BULLETIN && action.bulletinId) ||
    (isContractExchange && action.type === ActionType.AGREE) ||
    (action.awardedSplitQuantity >= 0);

  const renderAttachmentsBlock = () => (
    contextType === ContextType.RFX ? (
      <RfqAttachmentList attachments={action.def?.attachments || action.attachments} mt={1} />
    ) : contextType === ContextType.CONTRACT ? (
      <ContractAttachmentList attachments={action.def?.attachments || action.attachments} mt={1} />
    ) : contextType === ContextType.QUESTIONNAIRE ? (
      <QuestionnaireAttachmentList attachments={action.response?.value?.attachments} mt={1} />
    ) : (
      null
    )
  );

  return (
    <Flex ref={notificationSubjectRef} width="100%" alignItems="flex-start" flexDirection="column" {...props}>
      <Flex alignItems="baseline" width="100%">
        <Box alignSelf="center">
          {!action.user?._id && action.icon ? (
            <ActionIcon
              icon={action.icon}
              iconBackgroundColor="darkGray"
            />
          ) : action.user?._id ? (
            <Visibility visibility={hideAvatar ? 'hidden' : 'visible'}>
              <Avatar userId={action.user._id} width="xxs" />
            </Visibility>
          ) : null}
        </Box>
        <ActionHeading action={action} />
        <Box flex={1} />
        {!isBulletinExchange && shouldShowToggleButton && (
          <ToggleChanges isOpen={areDetailsVisible} onClick={() => setAreDetailsVisible(value => !value)} />
        )}
      </Flex>
      {isBulletinExchange ? (
        <Box width="100%">
          <Box ml="26px">
            {action.type === ActionType.INITIATE && (
              <>
                <Text sx={{ whiteSpace: 'pre-wrap', borderRadius: '4px' }} fontSize={1} lineHeight={1.4} mt={1} px={2} py={1} width="fit-content" bg="lightGray2">
                  {action.def.message}
                </Text>
                {action.def.attachments && !isEmpty(action.def.attachments) && <RfqAttachmentList attachments={action.def.attachments} mt={1} iconColor="subtext" width="100%" />}
              </>
            )}
            {changedRevisionFields && !isEmpty(changedRevisionFields) && (
              <Box mt={1}>
                <BulletinRevisionChanges
                  action={action}
                  exchange={exchange}
                  changedFields={changedRevisionFields}
                />
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box ml="26px">
          {areDetailsVisible && (
            <>
              {action.subtype !== ActionSubtype.CLEAR && action.fields?.length && (
                <LineItemFieldsTable
                  action={action}
                  exchange={exchange}
                />
              )}
              {action.type === ActionType.DEVIATE && (
                <Box as="table" my={1}>
                  <Box as="td" py="2px" pr={3}>
                    <Text color="subtext" fontSize={1} lineHeight={1.4}>
                      {t('response', { ns: 'general' })}
                    </Text>
                  </Box>
                  <Box as="td" py="2px">
                    {isContractExchange
                      ? t('contract.suggestEdits', { ns: 'exchangeActionLabel' })
                      : t('document.offerDeviation', { ns: 'exchangeActionLabel' })}
                  </Box>
                </Box>
              )}
              {changedRevisionFields && !isEmpty(changedRevisionFields) && (
                <Box mt={1}>
                  {exchange.def.fields ? (
                    <DynamicFieldsRevisionChangesTable
                      action={action}
                      exchange={exchange}
                      changedFields={changedRevisionFields}
                    />
                  ) : (
                    <RevisionChangesTable
                      action={action}
                      exchange={exchange}
                      changedFields={changedRevisionFields}
                    />
                  )}
                </Box>
              )}
              {action.hideFromRoles && action.hideFromRoles.includes('submitter') && (
                <NotVisibleToSupplier />
              )}
              {exchange.def.type === ExchangeType.QUESTION &&
              action.type === ActionType.SUBMIT &&
              action.subtype !== ActionSubtype.CLEAR && (
                <Text color="darkGray" fontSize={1}>
                  <QuestionResponse
                    historySlice={historySlice}
                    response={action.response}
                    exchangeDef={exchange.def}
                    isMultiLine
                  />
                </Text>
              )}
              {hasAttachments && !(exchange.isChat || exchange.isClarification) && renderAttachmentsBlock()}
              {isContractExchange && action.type === ActionType.AGREE && (
                <Flex sx={{ gap: 3 }}>
                  <Text>{t('signature.signer_other', { ns: 'contracts' })}</Text>
                  <Text>{action.signers.map(signer => signer.name).join(', ')}</Text>
                </Flex>
              )}
              {action.comment && action.type !== ActionType.NONE && (
                <Text sx={{ whiteSpace: 'pre-wrap', borderRadius: '4px' }} fontSize={1} lineHeight={1.4} mt={1} px={2} py={1} bg="lightGray2" width="100%">
                  {action.comment}
                </Text>
              )}
              {action.type === ActionType.REFER_TO_BULLETIN && (
                <BulletinPost bulletinId={action.bulletinId} />
              )}
              {action.awardedSplitQuantity && (
                <Flex sx={{ gap: 3 }} my={1} alignItems="center">
                  <Text color="subtext" fontSize={1} lineHeight={1.4}>
                    {t('request.awardSummary.awardedLineItems.awardedQuantity', { ns: 'translation' })}
                  </Text>
                  <Text>{action.awardedSplitQuantity}</Text>
                </Flex>
              )}
            </>
          )}
          {action.comment && action.type === ActionType.NONE && (
            <Text sx={{ whiteSpace: 'pre-wrap', borderRadius: '4px' }} fontSize={1} lineHeight={1.4} mt={1} px={2} py={1} bg="lightGray2" width="100%">
              {action.comment}
            </Text>
          )}
          {hasAttachments && (exchange.isChat || exchange.isClarification) && renderAttachmentsBlock()}
          {action.type === 'response-tag-updated' && canRenderLatestResponseStageLink && (
            <LineItemModalLatestResponseStageInfo
              stageId={getStageIdFromTag(action.tag)}
              exchangeId={exchange._id}
            />
          )}
        </Box>
      )}

    </Flex>
  );
});
