import { useCallback, useMemo } from 'react';
import { Text, Flex, Box } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { useModalState } from '../../ui/useModalState';
import { useToaster } from '../../toast';
import { useMutation } from '../../useMutation';
import { useApi } from '../../api';
import { useContractState, useContractData, useContractPermissions } from './contract';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { PageFooter } from '../../PageFooter';
import { contractsRoute } from '../../AppRouting';
import { useNavigate } from '../../tanstackRouter';
import { DeleteContractTemplateDialog } from './DeleteContractTemplateDialog';

export const DraftContractFooter = ({
  onBack,
  onContinue,
  onIssue,
  issueDisabled,
}: {
  onBack?: () => void,
  onContinue?: () => void,
  onIssue?: any,
  issueDisabled?: boolean;
}) => {
  const { t } = useTranslation(['contracts', 'general', 'translation']);
  const api = useApi();
  const toaster = useToaster();
  const navigate = useNavigate();
  const deleteContractModal = useModalState();
  const deleteTemplateModal = useModalState();
  // @ts-expect-error ts(2339) FIXME: Property 'editingPanelId' does not exist on type 'ContractStateContextType | undefined'.
  const { editingPanelId, isRevising, isAmending, isTemplate } = useContractState();
  const contract = useContractData();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { canDeleteContract, canPublish } = useContractPermissions();

  const [deleteContractMutation] = useMutation(
    api.deleteContract,
    {
      onSettled: deleteContractModal.close,
      onSuccess: callAll(
        () => toaster.success(t('toaster.contractDeleted.success')),
        () => navigate({ to: contractsRoute.to, params: { currentCompanyId }, search: { tab: 'sent' } }),
      ),
      onError: () => toaster.error(t('toaster.contractDeleted.failed')),
    },
  );

  const navigateToTemplates = useCallback(
    () => {
      navigate({
        to: contractsRoute.to,
        params: { currentCompanyId },
        search: { tab: 'templates' },
      });
    },
    [currentCompanyId, navigate],
  );

  const sendContractTooltip = useMemo(
    () => {
      if (issueDisabled) {
        if (isRevising) {
          return t('review.sendRevisionTooltip');
        } else if (isAmending) {
          return t('review.sendAmendmentTooltip');
        } else {
          return t('review.sendContractTooltip');
        }
      } else {
        return null;
      }
    },
    [isRevising, isAmending, issueDisabled, t],
  );

  return !editingPanelId ? (
    <>
      <PageFooter>
        {!isRevising && !isAmending && canDeleteContract ? (
          <Button
            iconLeft="trash"
            variant="danger-outline"
            onClick={isTemplate ? deleteTemplateModal.open : deleteContractModal.open}
          >
            {isTemplate ? t('deleteTemplate') : t('deleteContract')}
          </Button>
        ) : (
          null
        )}
        <Flex sx={{ gap: 2 }} ml="auto">
          {onBack && (
            <Button iconLeft="arrow-left" onClick={onBack}>
              {t('back', { ns: 'general' })}
            </Button>
          )}
          {onContinue && (
            <Button iconRight="arrow-right" onClick={onContinue}>
              {t('continue', { ns: 'general' })}
            </Button>
          )}
          {onIssue && canPublish && (
            <Tooltip content={sendContractTooltip}>
              <Box as="span">
                <Button
                  variant="success"
                  iconRight="arrow-right"
                  onClick={onIssue}
                  disabled={issueDisabled}
                >
                  {isRevising ? (
                    t('issueRevision')
                  ) : isAmending ? (
                    t('issueAmendment')
                  ) : isTemplate ? (
                    t('saveAndExit')
                  ) : (
                    t('sendContract')
                  )}
                </Button>
              </Box>
            </Tooltip>
          )}
        </Flex>
      </PageFooter>
      {isTemplate ? (
        <DeleteContractTemplateDialog
          {...deleteTemplateModal}
          hideTemplateName
          templateId={contract._id}
          onSuccess={navigateToTemplates}
          onError={deleteTemplateModal.close}
        />
      ) : (
        <Dialog
          heading={t('dialog.confirmContractDeletion.heading')}
          body={(
            <>
              <Text mb={2}>{t('dialog.confirmContractDeletion.body')}:</Text>
              <Text fontWeight={500}>{contract.summary.name}</Text>
              <MessageBlock variant="warn" mt={3}>
                {t('dialog.confirmContractDeletion.warning')}
              </MessageBlock>
            </>
          )}
          okButtonText={t('dialog.confirmContractDeletion.okButtonText')}
          okButtonVariant="danger"
          isOpen={deleteContractModal.isOpen}
          onOk={() => deleteContractMutation({
            companyId: currentCompanyId,
            contractId: contract._id,
          })}
          onCancel={deleteContractModal.close}
          showCloseIcon
        />
      )}
    </>
  ) : (
    null
  );
};
