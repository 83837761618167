import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import { conforms, findIndex, last, map } from 'lodash';
import { RfxOtherSection } from '@deepstream/common/rfq-utils';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { NotificationDomain, NotificationAction } from '@deepstream/common/notification-utils/types';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { withProps } from '@deepstream/ui-utils/withProps';
import { DateFormat } from '@deepstream/utils';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useRfqExchanges, useRfqId, useRecipientId } from '../../useRfq';
import * as rfx from '../../rfx';
import { Loading } from '../../ui/Loading';
import { ErrorMessage } from '../../ui/ErrorMessage';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { Disclosure2 } from '../../ui/Disclosure';
import { useNotificationSubject } from '../Notifications/useNotificationSubject';
import { Notification } from '../Notifications/types';
import { Bold } from '../../Bold';
import { RequestMessagesGrid } from './RequestMessagesGrid';
import { useExchangeNavigation } from '../../useExchangeModalState';
import { ExchangeSnapshot } from '../../types';
import { SortableHeader } from '../../ui/ExchangeDefsGrid/header';
import { DateOrDashCell, TextOrDashCell } from '../../ui/ExchangeDefsGrid/nonValidationAwareValueCell';
import { BulletinMessageCell } from '../../ui/ExchangeDefsGrid/BulletinMessageCell';
import { useModalState } from '../../ui/useModalState';
import { AddBulletinModal } from './AddBulletinModal';
import { useBulletinSection } from './useBulletinSection';

export const BulletinsBidSection = () => {
  const { t } = useTranslation(['translation', 'general']);
  const newBulletinModal = useModalState();
  const rfqId = useRfqId();
  const pagePermissions = rfx.usePagePermissions({ required: true });
  const recipientId = useRecipientId();
  const section = rfx.useSection<RfxOtherSection>();
  const isRecipient = useCurrentCompanyId() === recipientId;
  const currentCompanyId = useCurrentCompanyId()!;
  const { openExchange } = useExchangeNavigation();
  const bulletinSection = useBulletinSection();

  const { data: bulletinExchanges, isError, isLoading } = useRfqExchanges({
    recipientId,
    sectionIds: [section?._id],
    enabled: Boolean(section) && Boolean(recipientId),
  });

  const indexedBulletinExchanges = useMemo(() => map(
    bulletinExchanges,
    (exchange) => {
      const bulletinIndex = bulletinSection
        ? findIndex(
          bulletinSection.exchangeDefIds,
          exchangeDefId => exchangeDefId === exchange.def._id,
        )
        : null;
      return ({ ...exchange, bulletinIndex });
    },
  ), [bulletinExchanges, bulletinSection]);

  const columns: EditableGridColumn<ExchangeSnapshot & { bulletinIndex: number }>[] = useMemo(
    () => ([
      {
        _id: 'bulletinNumber',
        accessorKey: 'bulletinIndex',
        accessorFn: (exchange) => exchange.bulletinIndex + 1,
        Header: SortableHeader,
        label: t('number_short', { ns: 'general' }),
        width: 68,
        ValueCell: TextOrDashCell,
      },
      {
        _id: 'message',
        accessorKey: 'def.message',
        Header: SortableHeader,
        label: t('message', { ns: 'general' }),
        width: 420,
        ValueCell: BulletinMessageCell,
      },
      {
        _id: 'postedBy',
        accessorKey: 'def.user.name',
        Header: SortableHeader,
        label: t('request.bulletin.postedBy'),
        width: 200,
        ValueCell: withProps(TextOrDashCell, { truncate: true }),
      },
      {
        _id: 'lastUpdated',
        accessorKey: 'publishedAt',
        // Temporary fix so that the correct `last updated` date is displayed
        // (at the moment, `publishedAt` doesn't get updated inside exchange.def when updating a bulletin)
        accessorFn: (exchange) => new Date(last((exchange as ExchangeSnapshot).history).date),
        Header: SortableHeader,
        label: t('request.bulletin.lastUpdate'),
        width: 207,
        ValueCell: withProps(DateOrDashCell, { dateFormat: DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ }),
      },
    ]),
    [t],
  );

  const bulletinNotificationsRef = useNotificationSubject({
    filter: conforms<Partial<Notification>>({
      domain: domain => domain === NotificationDomain.RFQ_RECEIVED,
      action: action => [NotificationAction.REQUEST_BULLETIN_ADDED, NotificationAction.REQUEST_BULLETIN_UPDATED].includes(action),
      to: to => to.companyId === currentCompanyId,
      meta: meta => meta.rfqId === rfqId && meta.sectionId === section._id,
    }),
  });

  return (
    <>
      <Panel ref={bulletinNotificationsRef}>
        <PanelHeader heading={t('request.bulletin.bulletin_other')} collapse={false} icon="list-ul">
          {!isRecipient && (
            <Button small iconLeft="plus" onClick={newBulletinModal.open} disabled={!pagePermissions.canEdit}>
              {t('request.bulletin.newBulletin')}
            </Button>
          )}
        </PanelHeader>
        <PanelDivider />
        {isLoading ? (
          <PanelPadding>
            <Loading />
          </PanelPadding>
        ) : isError ? (
          <PanelPadding>
            <ErrorMessage error={t('errors.unexpected')} />
          </PanelPadding>
        ) : (
          // @ts-expect-error ts(18048) FIXME: 'bulletinExchanges' is possibly 'undefined'.
          bulletinExchanges.length ? (
            <PanelPadding>
              <GridIdPrefixProvider>
                <GridMenuStateProvider>
                  <RequestMessagesGrid data={indexedBulletinExchanges} columns={columns} onRowClick={openExchange} />
                </GridMenuStateProvider>
              </GridIdPrefixProvider>
            </PanelPadding>
          ) : (
            <Flex width="100%" height="200px" justifyContent="center" alignItems="center">
              <Bold color="darkGray2">
                {t('request.bulletin.noBulletins')}
              </Bold>
            </Flex>
          )
        )}
      </Panel>
      <Text mt={3}>
        <Disclosure2
          width="100%"
          summary={t('request.bulletin.disclosure.heading')}
        >
          {isRecipient ? t('request.bulletin.disclosure.body.recipient') : t('request.bulletin.disclosure.body.sender')}
        </Disclosure2>
      </Text>
      <AddBulletinModal
        rfqId={rfqId}
        sectionId={section._id}
        isOpen={newBulletinModal.isOpen}
        onClose={newBulletinModal.close}
      />
    </>
  );
};
