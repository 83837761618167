import { useState } from 'react';
import { Box } from 'rebass/styled-components';

import { useModalState } from '../../../ui/useModalState';

import { CategoriesPanel } from './CategoriesPanel';
import { CategoryModal } from './CategoryModal';
import { DeleteCategoryModal } from './DeleteCategoryModal';

export const Categories = () => {
  const categoryModal = useModalState();
  const deleteCategoryModal = useModalState();
  const [categoryId, setCategoryId] = useState<string | null>(null);

  return (
    <Box>
      <CategoriesPanel
        onClickAdd={categoryModal.open}
        onClickDelete={(categoryId) => {
          setCategoryId(categoryId);
          deleteCategoryModal.open();
        }}
        onClickEdit={(categoryId) => {
          setCategoryId(categoryId);
          categoryModal.open();
        }}
      />
      {categoryModal.isOpen && (
        <CategoryModal
          // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string | undefined'.
          id={categoryId}
          close={() => {
            if (categoryId) setCategoryId(null);
            categoryModal.close();
          }}
        />
      )}
      {deleteCategoryModal.isOpen && (
        <DeleteCategoryModal
          // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
          id={categoryId}
          close={deleteCategoryModal.close}
        />
      )}
    </Box>
  );
};
