import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';
import { Box, Text, Flex } from 'rebass/styled-components';
import { first, isNil, omitBy } from 'lodash';
import * as yup from 'yup';
import { ContractStatus } from '@deepstream/common/contract';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { callAll } from '@deepstream/utils/callAll';
import { useContractData, useContractId, useContractPermissions } from './contract';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { CompanyLogo } from '../../CompanyLogo';
import { TextField } from '../../form/TextField';
import { useMutation } from '../../useMutation';
import { useApi } from '../../api';
import { useContractMutationArgs } from './useContractMutationArgs';
import { useModalState } from '../../ui/useModalState';
import { ModalForm } from '../../ModalForm';
import { useDraftContractNavigation } from '../../appNavigation';
import { useToaster } from '../../toast';
import { contractsRoute } from '../../AppRouting';
import useDownload from '../../useDownload';
import { useCurrentUserLocale } from '../../useCurrentUser';
import { useSystemFeatureFlags } from '../../systemFeatureFlags';

// Used for marking a contract as failed or terminated, depending on the current state.
const CloseContractModal = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close?: () => void;
}) => {
  const { t } = useTranslation(['contracts', 'general']);
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { _id: contractId, status, recipients, isLegacy } = useContractData();

  const recipient = first(recipients);
  const isNegotiating = status === ContractStatus.NEGOTIATION;

  const mutationArgs = useContractMutationArgs({
    name: isNegotiating ? 'contractFailed' : 'contractTerminated',
    onSuccess: close,
  });

  const [mutationFunction] = useMutation(
    isNegotiating ? api.markContractAsFailed : api.markContractAsTerminated,
    mutationArgs,
  );

  return (
    <ModalForm
      isOpen={isOpen}
      heading={isNegotiating ? (
        t('dialog.confirmContractFailed.heading')
      ) : (
        t('dialog.confirmContractTerminated.heading')
      )}
      onSubmit={async ({ message }) => {
        await mutationFunction({
          contractId,
          currentCompanyId,
          message: message.trim(),
        });
      }}
      onCancel={close}
      validationSchema={yup.object().shape({
        message: yup.string().trim().required(t('required', { ns: 'general' })),
      })}
      initialValues={{
        message: '',
      }}
      submitLabel={isNegotiating ? (
        t('dialog.confirmContractFailed.okButtonText')
      ) : (
        t('dialog.confirmContractTerminated.okButtonText')
      )}
      submitVariant="danger"
      style={{ content: { minWidth: '390px', maxWidth: '390px' } }}
    >
      <MessageBlock variant="warn" mt={0}>
        {isNegotiating ? (
          t('dialog.confirmContractFailed.warning')
        ) : (
          t('dialog.confirmContractTerminated.warning')
        )}
      </MessageBlock>
      {isLegacy ? (
        <Text>
          {t('dialog.confirmContractTerminated.reasonForTermination')}:
        </Text>
      ) : (
        <Box>
          <Text mb={2}>
            {t('summary.counterParty')}:
          </Text>
          <Flex alignItems="center">
            <Box flex="0 0 auto">
              {/*
               // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'. */}
              <CompanyLogo size="xs" companyId={recipient?._id} />
            </Box>
            <Truncate>
              {/*
               // @ts-expect-error ts(18048) FIXME: 'recipient' is possibly 'undefined'. */}
              {recipient.name}
            </Truncate>
          </Flex>
        </Box>
      )}
      <TextField
        name="message"
        hideLabel
        placeholder={isNegotiating ? (
          t('dialog.confirmContractFailed.inputPlaceholder')
        ) : isLegacy ? (
          t('dialog.confirmContractTerminated.legacyInputPlaceholder')
        ) : (
          t('dialog.confirmContractTerminated.inputPlaceholder')
        )}
        isMultiLine
        rows={2}
      />
    </ModalForm>
  );
};

const DeleteLegacyContractDialog = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close?: () => void;
}) => {
  const { t } = useTranslation('contracts');
  const api = useApi();
  const toaster = useToaster();
  const navigate = useNavigate();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractId = useContractId();

  const [deleteContractMutation] = useMutation(
    api.deleteContract,
    {
      onSettled: close,
      onSuccess: callAll(
        () => toaster.success(t('toaster.contractDeleted.success')),
        () => navigate({ to: contractsRoute.to, params: { currentCompanyId }, search: { tab: 'sent' } }),
      ),
      onError: () => toaster.error(t('toaster.contractDeleted.failed')),
    },
  );

  const deleteContract = useCallback(
    () => deleteContractMutation({
      companyId: currentCompanyId,
      contractId,
    }),
    [contractId, currentCompanyId, deleteContractMutation],
  );

  return (
    <Dialog
      heading={t('dialog.confirmLegacyContractDeletion.heading')}
      body={
        <MessageBlock variant="error" mt={0}>
          {t('dialog.confirmLegacyContractDeletion.warning')}
        </MessageBlock>
      }
      style={{ content: { width: '500px' } }}
      okButtonText={t('dialog.confirmLegacyContractDeletion.okButtonText')}
      okButtonVariant="danger"
      isOpen={isOpen}
      onOk={deleteContract}
      onCancel={close}
      showCloseIcon
    />
  );
};

export const ContractActions = () => {
  const { t } = useTranslation('contracts');
  const { status, isLegacy } = useContractData();
  const modal = useModalState();
  const deleteModal = useModalState();
  const draftContractNavigation = useDraftContractNavigation();
  const { canMarkAsFailed, canTerminate, canDownloadLineItems } = useContractPermissions();
  const download = useDownload();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractId = useContractId();
  const systemFeatureFlags = useSystemFeatureFlags();
  const locale = useCurrentUserLocale();

  const queryParams = new URLSearchParams(omitBy(
    {
      locale,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    } as Record<string, string>,
    isNil,
  ));

  const downloadLineItems = async () => {
    await download(`/download/company/${currentCompanyId}/contract/${contractId}/lineItems?${queryParams.toString()}`);
  };

  return (
    <Box ml={4}>
      <DropdownMenu
        variant="secondary"
        buttonText={t('contractActions')}
        iconRight="caret-down"
        menuStyle={{ width: '200px' }}
      >
        {status === ContractStatus.NEGOTIATION ? (
          <>
            <DropdownMenuItem disabled={!canMarkAsFailed} icon="times" onSelect={modal.open}>
              {t('failed')}
            </DropdownMenuItem>
            <DropdownMenuItem icon="pencil" onSelect={draftContractNavigation.navigateToSummary}>
              {t('reviseContract')}
            </DropdownMenuItem>
          </>
        ) : isLegacy ? (
          <DropdownMenuItem icon="trash" onSelect={deleteModal.open} color="danger">
            {t('deleteLegacyContract')}
          </DropdownMenuItem>
        ) : [ContractStatus.AGREED, ContractStatus.ACTIVE, ContractStatus.EXPIRED].includes(status) ? (
          <>
            {status !== ContractStatus.EXPIRED && (
              <DropdownMenuItem icon="times" onSelect={modal.open} disabled={!canTerminate}>
                {t('terminate')}
              </DropdownMenuItem>
            )}
            <DropdownMenuItem icon="edit" onSelect={draftContractNavigation.navigateToSummary}>
              {t('amendContract')}
            </DropdownMenuItem>
            {systemFeatureFlags?.downloadContractLineItemsEnabled && (
              <DropdownMenuItem icon="download" onSelect={downloadLineItems} disabled={!canDownloadLineItems}>
                {t('downloadLineItems')}
              </DropdownMenuItem>
            )}
          </>
        ) : (
          null
        )}
      </DropdownMenu>
      <CloseContractModal isOpen={modal.isOpen} close={modal.close} />
      <DeleteLegacyContractDialog isOpen={deleteModal.isOpen} close={deleteModal.close} />
    </Box>
  );
};
