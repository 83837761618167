import * as React from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Button, ButtonLink } from '@deepstream/ui-kit/elements/button/Button';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { copyToClipboard } from '@deepstream/ui-utils/clipboard';
import { slugify } from '@deepstream/utils/slugify';
import { useDeviceSize } from './useDeviceSize';
import { UrlContext } from './UrlProvider';

type ProfileLinkProps = {
  companyId: string;
  companyName: string;
  toaster?: any;
};

export const PublicProfileLinkPanel: React.FC<ProfileLinkProps> = ({ companyId, companyName, toaster }) => {
  const { t } = useTranslation(['companyProfile', 'general']);
  const { isExtraSmall } = useDeviceSize();
  const { publicPages } = React.useContext(UrlContext);

  const url = `${publicPages}/company/${companyId}/${slugify(companyName)}`;

  const copyLink = React.useCallback(
    () => {
      copyToClipboard(url);
      if (toaster) {
        toaster.success(t('toaster.copyProfileLinkSuccess'));
      }
    },
    [url, toaster, t],
  );

  const position = isExtraSmall ? { left: 'calc(50% - 165px)' } : { left: 20 };
  const style: React.CSSProperties = { position: 'absolute', top: 0, borderRadius: '0px 0px 4px 4px', borderTop: 'none', ...position };

  return (
    <Panel padded width={330} maxWidth="100%" style={style}>
      <Flex alignItems="center">
        <Text color="subtext" flex="1 1 auto">
          {t('header.publicProfile')}
        </Text>
        <ButtonLink small href={url} target="_blank" variant="secondary-outline" iconLeft="external-link" mr={2}>
          {t('open', { ns: 'general' })}
        </ButtonLink>
        <Button small variant="secondary-outline" iconLeft="link" onClick={copyLink}>
          {t('header.copyLink')}
        </Button>
      </Flex>
    </Panel>
  );
};
