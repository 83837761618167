import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { compact, findLast, isEmpty, map, some } from 'lodash';
import { Box, Text } from 'rebass/styled-components';
import { ContractDocumentExchangeDefinition, ContractSignatureType, isContractExchangeDef, isLegacyContractExchangeDef } from '@deepstream/common/contract/contract';
import { ExchangeType } from '@deepstream/common/rfq-utils';
import { withProps } from '@deepstream/ui-utils/withProps';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { StaticTableStyles, FormTableStyles } from '../../TableStyles';
import { Table } from '../../Table';
import { ExchangeDefDescriptionCell, ValidationErrorCell } from '../../draft/cell';
import { AttachmentCell, ContractTypeCell, SignatureTypeCell } from './contractDraftCell';
import { nestCells } from '../../nestCells';
import { WordWrap } from '../../WordWrap';
import { ErrorMessage } from '../../ui/ErrorMessage';
import { ContractExchangeDefRowCells } from './ContractExchangeDefRowCells';
import * as validation from '../../draft/validation';
import { useShowValidationErrors } from './draftContract';
import { useContractData, useContractState, useSectionExchangeDefs } from './contract';
import { createContract, createLegacyContract, useExchangeDefsField } from '../../draft/exchangeDefs';
import { useIsESignatureEnabled } from './useIsESignatureEnabled';
import { TruncateCell } from '../../TruncateCell';
import { ContractExchangePanel, useContractExchangeColumns } from './ContractExchangePanel';
import { NameArray } from '../../NameArray';

const ReadOnlyColumnHeader = ({ column }) => (
  <WordWrap>
    {column.label}
  </WordWrap>
);

const ApprovalRequiredCell = ({ cell: { value }, row }) => {
  const { t } = useTranslation(['general']);

  return (
    <>
      {row.original.signatureType !== ContractSignatureType.VERIFIED ? (
        t('notApplicable')
      ) : (
        value ? t('yes') : t('no')
      )}
    </>
  );
};

const SignersCell = ({ cell: { value: signers }, row }) => {
  const { t } = useTranslation(['general']);
  const signerNames = useMemo(
    () => map(signers, signer => signer.name),
    [signers],
  );

  return row.original.signatureType === ContractSignatureType.VERIFIED ? (
    <Truncate>
      <NameArray entities={signerNames} />
    </Truncate>
  ) : (
    <>
      {t('notApplicable')}
    </>
  );
};

export const ContractTable = () => {
  const { t } = useTranslation(['general', 'contracts']);
  const exchangeDefs = useSectionExchangeDefs();
  const showValidationErrors = useShowValidationErrors();
  const isESignatureEnabled = useIsESignatureEnabled();

  const { error } = validation.useError('exchangeDefs');

  const showContractTypeColumn = (
    exchangeDefs.length !== 1 ||
    exchangeDefs[0].type === ExchangeType.LEGACY_CONTRACT
  );

  const columns = useMemo(
    () => compact([
      showContractTypeColumn && {
        id: 'type',
        Header: ReadOnlyColumnHeader,
        label: t('type'),
        accessor: exchangeDef => isLegacyContractExchangeDef(exchangeDef)
          ? t('legacyContract', { ns: 'contracts' })
          : exchangeDef.isAddendum
            ? t('addendum', { ns: 'contracts' })
            : t('contract', { ns: 'contracts' }),
        Cell: nestCells(
          withProps(ValidationErrorCell, { useShowValidationErrors }),
          ContractTypeCell,
        ),
      },
      {
        id: 'attachments',
        Header: ReadOnlyColumnHeader,
        label: t('document'),
        accessor: ({ attachments }: ContractDocumentExchangeDefinition) => attachments?.[0],
        Cell: nestCells(
          withProps(ValidationErrorCell, { useShowValidationErrors }),
          AttachmentCell,
        ),
      },
      {
        id: 'description',
        Header: ReadOnlyColumnHeader,
        label: t('description'),
        accessor: 'description',
        Cell: nestCells(
          withProps(ValidationErrorCell, { useShowValidationErrors }),
          ExchangeDefDescriptionCell,
        ),
      },
      isESignatureEnabled && {
        id: 'signatureType',
        Header: ReadOnlyColumnHeader,
        label: t('signature.signatureType', { ns: 'contracts' }),
        accessor: 'signatureType',
        Cell: nestCells(
          withProps(ValidationErrorCell, { useShowValidationErrors }),
          nestCells(TruncateCell, SignatureTypeCell),
        ),
        width: 150,
      },
      isESignatureEnabled && {
        id: 'requireApproval',
        Header: ReadOnlyColumnHeader,
        label: t('signature.approvalRequired', { ns: 'contracts' }),
        accessor: exchangeDef => exchangeDef.requireApprovalBeforeCountersigning,
        Cell: nestCells(
          withProps(ValidationErrorCell, { useShowValidationErrors }),
          ApprovalRequiredCell,
        ),
        width: 180,
      },
      isESignatureEnabled && {
        id: 'senderSigners',
        Header: ReadOnlyColumnHeader,
        label: t('signature.signer_other', { ns: 'contracts' }),
        accessor: exchangeDef => exchangeDef.senderSigners,
        Cell: nestCells(
          withProps(ValidationErrorCell, { useShowValidationErrors }),
          SignersCell,
        ),
        width: 280,
      },
    ]),
    [t, showContractTypeColumn, isESignatureEnabled],
  );

  return showValidationErrors && error ? (
    <PanelPadding bg="errorBackground" py="16px">
      <ErrorMessage fontSize={2} error={error} />
    </PanelPadding>
  ) : isEmpty(exchangeDefs) ? (
    <PanelPadding>
      <Text color="subtext" fontSize={2}>
        {t('noContractsAdded', { ns: 'contracts' })}
      </Text>
    </PanelPadding>
  ) : (
    <StaticTableStyles>
      <Table
        columns={columns}
        data={exchangeDefs}
        CustomRowCells={ContractExchangeDefRowCells}
      />
    </StaticTableStyles>
  );
};

export const ContractTableField = ({
  fieldName = 'exchangeDefs',
}: {
  fieldName?: string;
}) => {
  const { t } = useTranslation(['general', 'contracts']);
  // @ts-expect-error ts(2339) FIXME: Property 'isRevising' does not exist on type 'ContractStateContextType | undefined'.
  const { isRevising, isLive } = useContractState();
  const contract = useContractData();
  const isESignatureEnabled = useIsESignatureEnabled();
  const { exchangeDefs, addExchangeDef } = useExchangeDefsField(
    fieldName,
    contract.isLegacy && isLive ? createLegacyContract : createContract,
  );

  const initialSignatureConfig = useMemo(
    () => {
      const lastContractExchangeDef = findLast(
        exchangeDefs,
        isContractExchangeDef,
      ) as ContractDocumentExchangeDefinition | undefined;

      if (lastContractExchangeDef) {
        return {
          signatureType: lastContractExchangeDef.signatureType,
          senderSigners: lastContractExchangeDef.signatureType === ContractSignatureType.MANUAL
            ? undefined
            : [],
          requireApprovalBeforeCountersigning: lastContractExchangeDef.requireApprovalBeforeCountersigning,
        };
      } else if (isESignatureEnabled) {
        return {
          signatureType: ContractSignatureType.VERIFIED,
          senderSigners: [],
          requireApprovalBeforeCountersigning: false,
        };
      } else {
        return {
          signatureType: ContractSignatureType.MANUAL,
        };
      }
    },
    [exchangeDefs, isESignatureEnabled],
  );

  const hasAddedNewContract = useMemo(
    () => some(exchangeDefs, exchangeDef => !exchangeDef.isLive),
    [exchangeDefs],
  );

  const columns = useContractExchangeColumns(fieldName);

  return (
    <FormTableStyles>
      <Stack gap={3}>
        {!isEmpty(exchangeDefs) ? (
          isESignatureEnabled ? (
            <Stack gap="20px">
              {exchangeDefs.map((exchangeDef, index) => (
                <ContractExchangePanel
                  key={exchangeDef._id}
                  fieldName={fieldName}
                  index={index}
                />
              ))}
            </Stack>
          ) : (
            <Table columns={columns} data={exchangeDefs} />
          )
        ) : (
          <Text color="subtext" fontSize={2}>
            {t('noContractsAdded', { ns: 'contracts' })}
          </Text>
        )}
        {!isRevising && !hasAddedNewContract && (
          <Box>
            <Button
              small
              variant="primary-outline"
              type="button"
              iconLeft="plus"
              onClick={() => addExchangeDef(initialSignatureConfig)}
            >
              {t('add')}
            </Button>
          </Box>
        )}
      </Stack>
    </FormTableStyles>
  );
};
