import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { withProps } from '@deepstream/ui-utils/withProps';
import { branch } from '@deepstream/ui-utils/branch';
import { LearnMoreButton } from '@deepstream/ui-kit/elements/button/Button';
import { BasicTableStyles } from './TableStyles';
import { Table } from './Table';
import { ExtendedReceivedRequestOverview } from './types';
import {
  BidStatusFieldValue,
  DateFieldValue,
  DeadlineFieldValue,
  FieldsCell,
  MIN_CELL_HEIGHT,
  NameArrayFieldValue,
  UniversalBidStatusFieldValue,
} from './FieldsCell';
import { ReceivedRequestBidStatus, ReceivedRequestUniversalBidStatus } from './ReceivedRequestBidStatus';
import { ReceivedRequestDisabledStatus } from './ReceivedRequestDisabledStatus';
import { useRequestsNavigation } from './RequestsNavigationContext';
import { useSystemFeatureFlags } from './systemFeatureFlags';
import { useTableData } from './TableDataContext';
import { RequestsTableData } from './RequestsTableDataProvider';

const ReceivedRequestLearnMoreButton = ({
  row,
  openCannotAccessRequestDialog,
}: CellProps<ExtendedReceivedRequestOverview> & {
  openCannotAccessRequestDialog: (request: ExtendedReceivedRequestOverview) => void;
}) => {
  const request = row.original;

  return (
    <LearnMoreButton
      small
      onClick={(e) => {
        e.stopPropagation();
        openCannotAccessRequestDialog(request);
      }}
    />
  );
};

export const ReceivedRequestsTable = ({
  openCannotAccessRequestDialog,
}: {
  openCannotAccessRequestDialog?: (request: ExtendedReceivedRequestOverview) => void;
}) => {
  const { t } = useTranslation(['translation', 'company']);
  const systemFeatureFlags = useSystemFeatureFlags({ required: true });
  const { selectedItemsIds, selectItem, unselectItem } =
    useTableData<RequestsTableData<ExtendedReceivedRequestOverview>>({ required: true });
  const columns = useMemo(
    () => [{ id: 'custom' }],
    [],
  );

  const { navigateToReceivedRequest } = useRequestsNavigation();
  const onRowClick = useCallback((row) => {
    const { _id: id, hasAccess } = row;
    if (hasAccess) {
      navigateToReceivedRequest(id);
    } else {
      // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
      openCannotAccessRequestDialog(row);
    }
  }, [navigateToReceivedRequest, openCannotAccessRequestDialog]);

  const CustomRowCells = useMemo(
    () => {
      const fields = [
        systemFeatureFlags.universalBidStatusEnabled
          ? {
            label: t('general.bidStatus'),
            accessor: 'receivedDashboard.universalBidStatus',
            FieldValueComponent: UniversalBidStatusFieldValue,
          }
          : {
            label: t('general.bidStatus'),
            accessor: 'receivedDashboard.bidStatus',
            FieldValueComponent: BidStatusFieldValue,
          },
        {
          label: t('requests.from'),
          accessor: 'receivedDashboard.sendersNames',
          FieldValueComponent: NameArrayFieldValue,
        },
        {
          label: t('yourRole', { ns: 'company' }),
          accessor: (request) => t(`requests.dashboardRole.${request.role}`),
        },
        {
          label: t('requests.issued'),
          accessor: 'receivedDashboard.issueDate',
          FieldValueComponent: DateFieldValue,
        },
        {
          label: t('requests.currentDeadline'),
          accessor: 'receivedDashboard.bidDeadline',
          FieldValueComponent: DeadlineFieldValue,
        },
      ];

      const selectField = {
        onSelect: (row) => {
          const request = row.original;
          if (selectedItemsIds?.includes(request._id)) {
            // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
            unselectItem(request._id);
          } else {
            // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
            selectItem(request._id);
          }
        },
        isSelected: (row) => selectedItemsIds?.includes(row.original._id),
      };

      return branch(
        ({ row }) => row.original.hasAccess,
        withProps(
          FieldsCell,
          {
            fields,
            titleAccessor: 'receivedDashboard.subject',
            defaultTitle: t('requests.untitledRequest'),
            RowIconComponent: systemFeatureFlags.universalBidStatusEnabled
              ? ReceivedRequestUniversalBidStatus
              : ReceivedRequestBidStatus,
            ...selectField,
          },
        ),
        withProps(
          FieldsCell,
          {
            disabledMessage: t('requests.notOnTeamForThisRequest'),
            titleAccessor: 'receivedDashboard.subject',
            defaultTitle: t('requests.untitledRequest'),
            RowIconComponent: ReceivedRequestDisabledStatus,
            ActionsComponent: withProps(
              ReceivedRequestLearnMoreButton,
              { openCannotAccessRequestDialog },
            ),
            ...selectField,
          },
        ),
      );
    },
    [systemFeatureFlags.universalBidStatusEnabled, t, openCannotAccessRequestDialog, selectedItemsIds, unselectItem, selectItem],
  );

  return (
    <BasicTableStyles hoverBackgroundColor="lightGray6">
      <Table
        columns={columns}
        isPaginated
        pageSizes={[5, 10, 20, 50, 100]}
        hideHeader
        CustomRowCells={CustomRowCells}
        hasStaticHeight={false}
        enforcePagination
        minCellHeight={MIN_CELL_HEIGHT}
        smallPageControls
        onRowClick={onRowClick}
      />
    </BasicTableStyles>
  );
};
