import { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { map, first, sumBy, pick, some, reject, keyBy, compact, isEmpty, isString, isNumber, filter, find, omitBy, isUndefined, last, intersection, dropRightWhile, isFinite } from 'lodash';
import { Box, Flex, Text } from 'rebass/styled-components';
import {
  LineItemExchangeDefinition,
  RfqEventChange,
  ExchangeChange,
  ExchangeDefinition,
  ExchangeProvider,
  ExchangeType,
  ActionType,
  isLineItemExchangeDef,
  getSupplierExchangeDefFields,
  RfxOtherSection,
  hasSupplierPriceField,
  setExchangeDefFieldValue,
  getTagFromStageId,
  getStageIdFromTag,
  Live,
} from '@deepstream/common/rfq-utils';
import { useQueryClient } from 'react-query';
import { diffArrayBy } from '@deepstream/utils';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { groupBy2d } from '@deepstream/utils/groupBy2d';
import { ButtonLink, CancelButton, SaveButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelHeader, PanelPadding, PanelSubHeader } from '@deepstream/ui-kit/elements/Panel';
import { Modal, ModalFooter, ModalBody, ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import { EditableGridDataProvider, useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { InlineButton } from '@deepstream/ui-kit/elements/button/InlineButton';
import styled from 'styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useRfqExchanges, useRecipientId, useRfqId, doesExchangesQueryMatchSectionId, doesExchangeQueryMatchExchangeIds } from './useRfq';
import * as rfx from './rfx';
import { LabeledValue, LabeledNumber } from './draft/LabeledValue';
import { CurrencyCodeProvider } from './ui/Currency';
import { Loading } from './ui/Loading';
import { ErrorMessage } from './ui/ErrorMessage';
import { useConfirmDialog, useModalState } from './ui/useModalState';
import { useCurrentCompanyId } from './currentCompanyId';
import { mappings, mapDiffToChanges } from './ui/diff';
import { useToaster } from './toast';
import { Locked } from './lock';
import { Disclosure } from './ui/Disclosure';
import { LockedTooltip } from './LockedTooltip';
import { useUpdateExchanges } from './useUpdateExchanges';
import { useExchangeNavigation } from './useExchangeModalState';
import { SelectFieldBase } from './form/SelectField';
import { useCurrencySelectItems } from './ui/currencies';
import { useSendExchangeReply } from './ExchangeModal/useSendExchangeReply';
import { GlobalDatePickerStyles } from './ui/DatePicker';
import { LineItemExchangeDefsGrid } from './ui/ExchangeDefsGrid/LineItemExchangeDefsGrid';
import { AddSupplierProvidedLineItemButton } from './draft/AddLineItemButton';
import { ConfirmChangeCurrencyDialog } from './ConfirmChangeCurrencyDialog';
import { ExchangesGrid } from './modules/Request/Live/ExchangesGrid';
import { useLineItemExchangesGridColumns } from './modules/Exchange/useLineItemExchangesGridColumns';
import { LineItemsExchangeSnapshot } from './types';
import { EditSupplierExchangeDefsButton } from './modules/Request/Live/EditSupplierExchangeDefsButton';
import { useExchangeRates } from './useExchangeRates';
import { restorePreviousLineItemResponseStage } from './modules/Request/Live/restorePreviousLineItemResponseStage';
import { useRequestRecipientNavigation } from './appNavigation';
import * as lotPagesLayout from './modules/Request/Live/lotPagesLayout';
import { useSystemFeatureFlags } from './systemFeatureFlags';

const hasNoMissingUnitAndQuantityValues = (exchanges: LineItemsExchangeSnapshot[]) => {
  return exchanges.every(({ def }) => {
    return (!def.fields.unit || def.unit) && (!def.fields.quantity || isFinite(def.quantity));
  });
};

const sanitize = <T,> (items: T[], diffKeys: string[]) =>
  map(items, def => pick(def, diffKeys));

const getChanges = <TChange extends RfqEventChange> ({ next, previous, keys, mapping }): TChange[] =>
  mapDiffToChanges(
    diffArrayBy(sanitize(next, keys), sanitize(previous, keys), '_id'),
    mappings[mapping],
  ) as any;

const EditSupplierAddedExchangesModalContent = ({ onSuccess, onCancel }) => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const section = rfx.useSection<RfxOtherSection>();
  const { isEditingSupplierExchangeDefs } = rfx.useState();
  const previousSupplierAddedDefs = rfx.useSectionExchangeDefsByCreator<LineItemExchangeDefinition>({
    group: 'recipient',
  });
  const buyerAddedDefs = rfx.useSectionExchangeDefsByCreator<LineItemExchangeDefinition>({
    group: 'sender',
  });
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    isDirty: isGridDataDirty,
    rowData: lineItemExchangeDefs,
    editedCell,
  } = useEditableGridData<LineItemExchangeDefinition>();

  const modelExchangeDef = useMemo(() => {
    const firstBuyerLineItemExchangeDef = find(buyerAddedDefs, isLineItemExchangeDef);

    return firstBuyerLineItemExchangeDef
      ? omitBy({
        evaluatorFieldCurrency: firstBuyerLineItemExchangeDef.evaluatorFieldCurrency,
        fields: getSupplierExchangeDefFields(firstBuyerLineItemExchangeDef.fields),
        orderedFieldIds: firstBuyerLineItemExchangeDef.orderedFieldIds,
      }, isUndefined) as unknown as LineItemExchangeDefinition
      : {} as LineItemExchangeDefinition;
  }, [buyerAddedDefs]);

  const isEditingCell = Boolean(editedCell);

  const isGridDataValid = useMemo(() => {
    return lineItemExchangeDefs.every(exchangeDef => {
      const unitField = exchangeDef.fields.unit;
      const quantityField = exchangeDef.fields.quantity;
      return (
        isString(exchangeDef.description) && !isEmpty(exchangeDef.description) &&
        (!unitField ||
          (isString(exchangeDef[unitField.source.key]) && !isEmpty(exchangeDef[unitField.source.key]))) &&
        // the number input component only allows entering values greater than 0
        // so no need to check that it's 1 or greater here
        (!quantityField || isNumber(exchangeDef[quantityField.source.key]))
      );
    });
  }, [lineItemExchangeDefs]);

  const [updateExchanges] = useUpdateExchanges({
    getChanges: (nextSupplierAddedDefs: ExchangeDefinition[]) =>
      getChanges<ExchangeChange>({
        mapping: 'exchangeDef',
        next: nextSupplierAddedDefs,
        previous: previousSupplierAddedDefs,
        keys: ['_id', 'type', 'description', 'quantity', 'unit', 'isObsolete', 'fields', 'evaluatorFieldCurrency', 'orderedFieldIds'],
      }).map(change => {
        change.sectionName = section._id;
        return change;
      }),
    onSuccess: () => toaster.success(t('request.lineItems.toaster.lineItemsUpdated')),
    onError: () => toaster.error('request.lineItems.toaster.couldNotUpdateLineItems'),
  });

  const handleSubmitClick = useCallback(async (event) => {
    event.stopPropagation();

    setSubmitting(true);

    await updateExchanges(lineItemExchangeDefs, {
      onSuccess,
      onSettled: () => setSubmitting(false),
    });
  }, [lineItemExchangeDefs, onSuccess, updateExchanges]);

  return (
    <>
      <ModalHeader onClose={onCancel}>
        {t('request.lineItems.supplierAddedLineItems')}
      </ModalHeader>
      <ModalBody>
        <Flex justifyContent="flex-end" mb={2}>
          <AddSupplierProvidedLineItemButton modelExchangeDef={modelExchangeDef} ml={2} />
        </Flex>
        <LineItemExchangeDefsGrid
          viewportHeightDelta={400}
          isEditingSupplierExchangeDefs={isEditingSupplierExchangeDefs}
        />
      </ModalBody>
      <ModalFooter>
        <CancelButton type="button" onClick={onCancel} mr={2} />
        <SaveButton
          type="button"
          disabled={isEditingCell || isSubmitting || !isGridDataDirty || !isGridDataValid}
          onClick={handleSubmitClick}
        />
      </ModalFooter>
    </>
  );
};

const EditSupplierAddedExchangesModal = ({ isOpen, onSuccess, onCancel }) => {
  const previousSupplierAddedDefs = rfx.useSectionExchangeDefsByCreator<LineItemExchangeDefinition>({
    group: 'recipient',
  });

  return (
    <Modal
      shouldCloseOnEsc
      isOpen={isOpen}
      onRequestClose={onCancel}
      style={{ content: { width: '810px' } }}
    >
      <EditableGridDataProvider
        rowData={previousSupplierAddedDefs}
        setValueInRow={setExchangeDefFieldValue}
      >
        <GlobalDatePickerStyles />
        <GridIdPrefixProvider>
          <GridMenuStateProvider>
            <rfx.StateProvider isEditingSupplierExchangeDefs>
              <EditSupplierAddedExchangesModalContent
                onSuccess={onSuccess}
                onCancel={onCancel}
              />
            </rfx.StateProvider>
          </GridMenuStateProvider>
        </GridIdPrefixProvider>
      </EditableGridDataProvider>
    </Modal>
  );
};

const LineItemLatestResponseStageInfo = ({ stageId }: { stageId: string }) => {
  const { t } = useTranslation('translation');
  const { stages } = rfx.useStructure<Live>();
  const requirementGroupId = rfx.useRequirementGroupId();
  const page = rfx.usePage();
  const navigation = useRequestRecipientNavigation();

  const stageIndex = stages.findIndex(stage => stage._id === stageId);

  return (
    <lotPagesLayout.InfoText>
      {t('request.lineItems.newResponseRequired', { stageNumber: stageIndex + 1 })}
      {' '}
      <InlineButton
        style={{ fontWeight: 400, textDecoration: 'underline' }}
        onClick={() => {
          // @ts-expect-error ts(18047) FIXME: 'page' is possibly 'null'.
          navigation.navigateToBid(stageId, requirementGroupId, page._id);
        }}
      >
        {t('request.lineItems.openStage', { stageNumber: stageIndex + 1 })}
      </InlineButton>
    </lotPagesLayout.InfoText>
  );
};

const OpenSectionOverlay = styled(ButtonLink)`
  position: absolute;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 9;
  font-size: 24px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  
  &:hover:not(:disabled):not(.disabled) {
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

const OpenSectionButton = styled(Flex)`
  gap: 4px;
  text-decoration: underline;
  border: 1px solid ${props => props.theme.colors.lightGray2};
  border-radius: 4px;
  align-items: center;
  padding: 20px;
  background: ${props => props.theme.colors.lightGray3};
  color: ${props => props.theme.colors.primary};
`;

// For stable reference
const EMPTY_ARRAY = [];

export const LineItemBidSection = () => {
  const { t } = useTranslation();
  const recipientId = useRecipientId();
  const { exchangeDefById, newFeaturesDisabled } = rfx.useStructure<Live>();
  const senders = rfx.useSenders();
  const sections = rfx.useSections();
  const section = rfx.useSection<RfxOtherSection>();
  const editLineItemsModal = useModalState();
  const isRecipient = useCurrentCompanyId() === recipientId;
  const { openExchange } = useExchangeNavigation();
  const pagePermissions = rfx.usePagePermissions();
  const page = rfx.usePage();
  const currencySelectItems = useCurrencySelectItems();
  const { confirm, ...dialogProps } = useConfirmDialog();
  const queryClient = useQueryClient();
  const isSender = rfx.useIsSender();
  const bid = rfx.useBid();
  const isBidding = rfx.useIsBidding();
  const stageId = rfx.useStageId();
  const exchangeRates = useExchangeRates();
  const systemFeatureFlags = useSystemFeatureFlags();
  const [isSectionHovered, setIsSectionHovered] = useState(false);
  const navigation = useRequestRecipientNavigation();
  const requirementGroupId = rfx.useRequirementGroupId();

  const hasRecipientAddedSubSection = section.providedBy === ExchangeProvider.BOTH;

  const { data: exchanges = [], isSuccess, isError, isLoading, refetch } = useRfqExchanges({
    recipientId,
    sectionIds: sections.map(section => section._id),
    enabled: sections.length > 0,
    select: exchanges => {
      const exchangeById = keyBy(exchanges, 'def._id');
      return compact(map(section.exchangeDefIds, exchangeId => exchangeById[exchangeId]));
    },
  });

  const shouldLockSectionTotal = some(
    exchanges,
    exchange => exchange.isLocked && exchange.roles.includes('evaluator'),
  );

  const exchangesInitiatedBy = useMemo(
    () => groupBy2d(
      exchanges,
      exchange => {
        const exchangeDef = exchangeDefById[exchange._id];
        // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
        return exchange.companies[exchangeDef.creatorId].group!;
      },
      exchange => exchange.def.type,
    ),
    [exchanges, exchangeDefById],
  );

  const firstCurrencyExchangeInitiatedBySender = first(exchangesInitiatedBy.sender?.currency as any[]);
  const currencyCode = firstCurrencyExchangeInitiatedBySender?.latestCurrency ?? '';
  const hasSingleCurrency = firstCurrencyExchangeInitiatedBySender?.def.currencies.length === 1;

  const {
    adjustedBuyerLineItemExchanges,
    lastResponseStageId,
  } = useMemo(() => {
    const exchanges = exchangesInitiatedBy.sender?.lineItem as LineItemsExchangeSnapshot[];

    if (isEmpty(exchanges) || !exchangeRates) {
      return {
        adjustedBuyerLineItemExchanges: EMPTY_ARRAY,
        lastResponseStageId: null,
      };
    }

    if (stageId && section.responseTagConfig) {
      const activatedResponseTags = intersection(
        bid.activatedStageIds.map(getTagFromStageId),
        section.responseTagConfig.tags,
      );

      const lastActivatedResponseTag = last(activatedResponseTags);
      const stageResponseTag = getTagFromStageId(stageId);

      if (stageResponseTag === lastActivatedResponseTag) {
        return {
          adjustedBuyerLineItemExchanges: exchanges,
          lastResponseStageId: null,
        };
      }

      const responseTags = dropRightWhile(activatedResponseTags, responseTag => responseTag !== stageResponseTag);

      const adjustedBuyerLineItemExchanges = exchanges.map(exchange => {
        if (!exchange.responseTag) {
          return exchange;
        }

        return restorePreviousLineItemResponseStage(
          exchange,
          responseTags,
          exchangeRates,
        );
      });

      return {
        adjustedBuyerLineItemExchanges,
        // @ts-expect-error ts(2345) FIXME: Argument of type '`stage:${string}` | undefined' is not assignable to parameter of type '`stage:${string}`'.
        lastResponseStageId: getStageIdFromTag(lastActivatedResponseTag),
      };
    }

    return {
      adjustedBuyerLineItemExchanges: exchanges,
      lastResponseStageId: null,
    };
  }, [exchangesInitiatedBy.sender, stageId, section, bid, exchangeRates]);

  const sectionTotal = sumBy(
    [
      ...(reject(adjustedBuyerLineItemExchanges, 'isObsolete') ?? []),
      ...(reject(exchangesInitiatedBy.recipient?.lineItem, 'isObsolete') ?? []),
    ],
    (exchange: any) => exchange.computedFormulas?.totalCost || 0,
  );

  const hideSectionTotal = !sectionTotal && section.exchangeDefIds.some(exchangeDefId => {
    const exchangeDef = exchangeDefById[exchangeDefId];

    return (
      exchangeDef?.type === ExchangeType.LINE_ITEM &&
      !exchangeDef.fields.totalCost
    );
  });

  const allLineItemExchangeIds = map(
    [
      ...(adjustedBuyerLineItemExchanges ?? []),
      ...(exchangesInitiatedBy.recipient?.lineItem ?? []),
    ],
    '_id',
  );

  const [latestCurrency, setLatestCurrency] = useState(firstCurrencyExchangeInitiatedBySender?.latestCurrency);
  useEffect(() => {
    setLatestCurrency(firstCurrencyExchangeInitiatedBySender?.latestCurrency);
  }, [firstCurrencyExchangeInitiatedBySender?.latestCurrency]);

  const rfqId = useRfqId();
  const currencyExchange = exchanges.find(exchange => exchange.def.type === ExchangeType.CURRENCY);
  const [sendExchangeReply] = useSendExchangeReply({
    rfqId,
    // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
    exchangeId: currencyExchange?._id,
    recipientId,
    sectionId: section._id,
  });

  const firstBuyerExchange = first(adjustedBuyerLineItemExchanges as any[]);
  const firstBuyerExchangeDef = firstBuyerExchange
    ? exchangeDefById[firstBuyerExchange.def._id] as LineItemExchangeDefinition
    : null;
  const buyerProvidedFields = firstBuyerExchangeDef?.fields;

  const firstSupplierExchange = first(exchangesInitiatedBy.recipient?.lineItem as any[]);
  const firstSupplierExchangeDef = firstSupplierExchange
    ? exchangeDefById[firstSupplierExchange.def._id] as LineItemExchangeDefinition
    : null;
  const supplierProvidedFields = firstSupplierExchangeDef?.fields;

  const buyerLineItemColumns = useLineItemExchangesGridColumns({
    isRecipient,
    isProvidedBySupplier: false,
    fields: buyerProvidedFields,
    orderedFieldIds: firstBuyerExchangeDef?.orderedFieldIds,
    addLocks: true,
  });

  const supplierLineItemColumns = useLineItemExchangesGridColumns({
    isRecipient,
    isProvidedBySupplier: true,
    fields: supplierProvidedFields,
    orderedFieldIds: firstSupplierExchangeDef?.orderedFieldIds,
    addLocks: true,
  });

  const handleOpenLineItemsSectionClick = () => {
    navigation.navigateToBid(stageId, requirementGroupId, page?._id, undefined, section._id);
  };

  // Render nothing if there are no exchanges to display
  if (!allLineItemExchangeIds.length) {
    return null;
  }

  const isCurrencySelectDisabled = (
    hasSingleCurrency ||
    isSender ||
    !isBidding ||
    exchanges.some(exchange => exchange.disabledReason) ||
    (
      section.responseTagConfig &&
      firstBuyerExchange?.responseTag !== section.responseTagConfig.tags[0]
    ) ||
    currencyExchange?.isObsolete
  );

  return (
    <CurrencyCodeProvider code={currencyCode}>
      <>
        <Panel
          sx={{ position: 'relative' }}
          onMouseEnter={() => setIsSectionHovered(true)}
          onFocus={() => setIsSectionHovered(true)}
          onMouseLeave={() => setIsSectionHovered(false)}
          onBlur={() => setIsSectionHovered(false)}
        >
          {isRecipient && systemFeatureFlags?.lineItemsBulkSubmitEnabled && !newFeaturesDisabled && isSectionHovered && (
            <OpenSectionOverlay fontSize={5} color="primary" onClick={handleOpenLineItemsSectionClick}>
              <OpenSectionButton>
                <Icon
                  icon="table"
                  fixedWidth
                  mr={1}
                  flex={0}
                />
                <Box>
                  {t('request.lineItems.openLineItemsSection')}
                </Box>
              </OpenSectionButton>
            </OpenSectionOverlay>
          )}
          <PanelHeader heading={section.name} description={section.description} icon="list-ul" alignItems="flex-start">
            <Flex alignItems="flex-start">
              {!hasSupplierPriceField(buyerProvidedFields) ? (
                null
              ) : !hasSingleCurrency ? (
                <Box mr={hideSectionTotal ? 0 : 3} sx={{ lineHeight: 1 }}>
                  <SelectFieldBase
                    label={t('general.currency')}
                    items={filter(currencySelectItems, item =>
                      firstCurrencyExchangeInitiatedBySender.def.currencies.includes(item.value),
                    )}
                    value={latestCurrency || firstCurrencyExchangeInitiatedBySender?.latestCurrency}
                    onChange={async value => {
                      if (firstCurrencyExchangeInitiatedBySender?.latestCurrency) {
                        return confirm(async () => {
                          setLatestCurrency(value);
                          await sendExchangeReply({
                            value: ActionType.SUBMIT,
                            currency: value,
                          });
                          refetch();
                          queryClient.invalidateQueries({
                            predicate: doesExchangesQueryMatchSectionId({
                              rfqId,
                              recipientId,
                              sectionId: section._id,
                            }),
                          });
                          queryClient.invalidateQueries({
                            predicate: doesExchangeQueryMatchExchangeIds({
                              rfqId,
                              recipientId,
                              exchangeIds: allLineItemExchangeIds,
                            }),
                          });
                        });
                      }

                      setLatestCurrency(value);
                      await sendExchangeReply({
                        value: ActionType.SUBMIT,
                        currency: value,
                      });
                    }}
                    disabled={isCurrencySelectDisabled}
                    buttonStyle={{ fontWeight: 500, width: 180, marginTop: '4px' }}
                    placeholder={t('request.lineItems.currency.select')}
                  />
                </Box>
              ) : (
                <LabeledValue
                  label={t('general.currency')}
                  value={currencyCode || <EmDash />}
                />
              )}
              {hideSectionTotal ? (
                null
              ) : shouldLockSectionTotal ? (
                <LabeledValue
                  ml={3}
                  label={t('request.lineItems.sectionTotal')}
                  value={(
                    <LockedTooltip senders={senders} lock={section.locking}>
                      <div style={{ display: 'inline-block' }}>
                        <Locked />
                      </div>
                    </LockedTooltip>
                  )}
                />
              ) : (
                <LabeledNumber
                  ml={3}
                  label={t('request.lineItems.sectionTotal')}
                  number={isLoading ? NaN : sectionTotal}
                  format="money"
                />
              )}
            </Flex>
          </PanelHeader>
          <PanelDivider />
          {hasRecipientAddedSubSection && (
            <>
              <PanelSubHeader heading={t('request.lineItems.buyerAddedLineItems')} />
              <PanelDivider />
            </>
          )}
          {isLoading ? (
            <PanelPadding>
              <Loading />
            </PanelPadding>
          ) : isError ? (
            <PanelPadding>
              <ErrorMessage error={t('request.lineItems.errors.couldNotGetLineItems')} />
            </PanelPadding>
          ) : isSuccess ? (
            <PanelPadding>
              <ExchangesGrid
                exchanges={adjustedBuyerLineItemExchanges}
                columns={buyerLineItemColumns}
                onRowClick={latestCurrency ? openExchange : null}
              />
              {lastResponseStageId && (
                <LineItemLatestResponseStageInfo stageId={lastResponseStageId} />
              )}
            </PanelPadding>
          ) : (
            null
          )}
          {hasRecipientAddedSubSection && (
            <>
              <PanelDivider />
              <PanelSubHeader heading={t('request.lineItems.supplierAddedLineItems')}>
                {isRecipient && pagePermissions.canEdit ? (
                  <EditSupplierExchangeDefsButton onClick={editLineItemsModal.open} />
                ) : (
                  null
                )}
              </PanelSubHeader>
              <PanelDivider />
              {isLoading ? (
                <PanelPadding>
                  <Loading />
                </PanelPadding>
              ) : isError ? (
                <PanelPadding>
                  <ErrorMessage error={t('request.lineItems.errors.couldNotGetLineItems')} />
                </PanelPadding>
              ) : isSuccess ? (
                exchangesInitiatedBy.recipient?.lineItem?.length ? (
                  <PanelPadding>
                    <ExchangesGrid
                      exchanges={exchangesInitiatedBy.recipient.lineItem}
                      columns={supplierLineItemColumns}
                      onRowClick={(
                        latestCurrency &&
                        hasNoMissingUnitAndQuantityValues(exchangesInitiatedBy.recipient.lineItem as LineItemsExchangeSnapshot[])
                      ) ? (
                        openExchange
                      ) : (
                        null
                      )}
                    />
                  </PanelPadding>
                ) : (
                  <PanelPadding>
                    <Text color="subtext" fontSize={2} mb={3}>
                      {t('request.lineItems.noLineItemsAdded')}
                    </Text>
                    {isRecipient ? (
                      <Disclosure summary={t('request.lineItems.supplierAddedDisclosure.recipient.summary')}>
                        {t('request.lineItems.supplierAddedDisclosure.recipient.content')}
                      </Disclosure>
                    ) : (
                      <Disclosure summary={t('request.lineItems.supplierAddedDisclosure.sender.summary')}>
                        {t('request.lineItems.supplierAddedDisclosure.sender.content')}
                      </Disclosure>
                    )}
                  </PanelPadding>
                )
              ) : (
                null
              )}
            </>
          )}
        </Panel>
        <ConfirmChangeCurrencyDialog
          {...dialogProps}
        />
        {hasRecipientAddedSubSection && isRecipient && pagePermissions.canEdit ? (
          <EditSupplierAddedExchangesModal
            isOpen={editLineItemsModal.isOpen}
            onSuccess={editLineItemsModal.close}
            onCancel={editLineItemsModal.close}
          />
        ) : (
          null
        )}
      </>
    </CurrencyCodeProvider>
  );
};
