import { find } from 'lodash';
import { DataCellProps } from '@deepstream/ui-kit/grid/core/utils';
import { EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { getCellValue } from '@deepstream/ui-kit/grid/EditableGrid/getCellValue';
import { getCurrentCompanyGroup } from '@deepstream/common/rfq-utils';
import { ExchangeStatusIconText } from '../../../ExchangeStatusCell';

export const ExchangeStatusValueCell = ({
  row,
  column,
  truncate,
}: DataCellProps<EditableGridColumn, any, any>) => {
  const value = getCellValue(row.original, column.original);
  const recipientId = row.original.recipientId ?? find(row.original.companies, { group: 'recipient' })?._id;
  const isRecipient = recipientId === row.original.currentCompanyId;
  const currentCompanyGroup = getCurrentCompanyGroup(isRecipient);

  return (
    <div className="cell-content">
      <ExchangeStatusIconText
        type={row.original.def.type}
        status={value}
        currentCompanyGroup={currentCompanyGroup}
        showTooltip
        truncate={truncate}
      />
    </div>
  );
};
