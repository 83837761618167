import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { isEmpty, isNil, some } from 'lodash';
import { useQuery, useQueryClient } from 'react-query';
import { getQuestionnaireTemplateChanges, QuestionnaireTemplate } from '@deepstream/common/preQual';
import { callAll } from '@deepstream/utils/callAll';
import { AsyncActionDialog, ConfirmActionDialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { LoadingPanel } from '../../../ui/Loading';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import { useQuestionnaireTemplate, useQuestionnaireTemplateQueryKey } from './useQuestionnaireTemplate';
import {
  QuestionnaireTemplateProvider,
  useQuestionnaireTemplateId,
  useQuestionnaireTemplateState,
} from './questionnaireTemplateUtils';
import { DraftPageContent } from './DraftPageContent';
import { DraftFooter } from './DraftFooter';
import { QuestionsSectionPanel } from './QuestionsSectionPanel';
import { BasicDetailsPanel } from './BasicDetailsPanel';
import { ReviewErrorsPanel } from './ReviewErrorsPanel';
import { useToaster } from '../../../toast';
import { useApi, wrap } from '../../../api';
import { useMutation } from '../../../useMutation';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { RevisionChangeLog } from './RevisionChangeLog';
import { useConfirmDialog, useModalState } from '../../../ui/useModalState';
import { UpdateSentQuestionnairesDialog } from './UpdateSentQuestionnairesDialog';
import { ExpiryAndRenewalPanel } from './ExpiryAndRenewalPanel';
import { PurposePanel } from './PurposePanel';
import { usePreQualNavigation } from '../../../AppRouting';
import { useDraftQuestionnaireTemplateNavigation } from '../../../appNavigation';
import { PreQualTab } from '../utils';

const validateQuestionnaireTemplate = (questionnaire: QuestionnaireTemplate, t: TFunction): string[] => {
  const errors = [];
  const { summary, renewalConfig, exchangeDefById, internal } = questionnaire;
  const { isRecurring, frequency } = renewalConfig;

  if (!summary.name) {
    errors.push(t('review.errors.nameRequired'));
  }

  if (!summary.description) {
    errors.push(t('review.errors.descriptionRequired'));
  }

  if (!internal.purpose) {
    errors.push(t('review.errors.purposeRequired'));
  }

  if (isRecurring && some([frequency.amount, frequency.unit], isNil)) {
    errors.push(t('review.errors.frequencyRequired'));
  }

  if (isEmpty(exchangeDefById)) {
    errors.push(t('review.errors.questionRequired'));
  }

  return errors;
};

const DraftReviewFooter = ({ isInvalid }: { isInvalid?: boolean }) => {
  const { t } = useTranslation(['preQualification', 'general']);
  const api = useApi();
  const toaster = useToaster();
  const draftNavigation = useDraftQuestionnaireTemplateNavigation();
  const preQualNavigation = usePreQualNavigation();
  const templateId = useQuestionnaireTemplateId({ required: true });
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const queryClient = useQueryClient();
  // @ts-expect-error ts(2339) FIXME: Property 'isRevising' does not exist on type 'QuestionnaireTemplateStateContextType | undefined'.
  const { isRevising } = useQuestionnaireTemplateState();
  const { confirm: confirmWithoutQuestionnaires, ...confirmWithoutQuestionnairesDialogProps } = useConfirmDialog();
  const { confirm: confirmWithQuestionnaires, ...confirmWithQuestionnairesDialogProps } = useConfirmDialog();
  const questionnairesUpdatedDialog = useModalState();
  const activeTemplateQueryKey = useQuestionnaireTemplateQueryKey({
    scope: 'current',
  });

  // "Active" (ie excluding rejected, declined and expired) sent questionnaires created using this template
  const { data: sentQuestionnaires = [], isLoading: isLoadingSentQuestionnaires } = useQuery(
    ['activeQuestionnairesFromTemplate', { currentCompanyId, templateId }],
    wrap(api.getActiveQuestionnairesFromTemplate),
    {
      enabled: isRevising,
    },
  );

  // History (all versions) for the revised template
  const { data: templateVersions, isLoading: isLoadingTemplateVersions } = useQuery(
    ['questionnaireTemplateHistory', { currentCompanyId, templateId }],
    wrap(api.getQuestionnaireTemplateHistory),
    {
      enabled: isRevising,
    },
  );

  const [publishQuestionnaireTemplate, { isLoading }] = useMutation(
    api.publishQuestionnaireTemplate,
    {
      onSuccess: callAll(
        () => toaster.success(t('toaster.templatePublished.success')),
        (_, params) => {
          if (params.shouldUpdateQuestionnaires) {
            questionnairesUpdatedDialog.open();
          } else {
            preQualNavigation.navigateToPreQualificationQuestionnaires(PreQualTab.ACTIVE_QUESTIONNAIRES);
          }
        },
        () => queryClient.invalidateQueries(['activeQuestionnaireTemplates', { currentCompanyId }]),
        () => queryClient.invalidateQueries(activeTemplateQueryKey),
        ...sentQuestionnaires.map(
          questionnaire => () => queryClient.invalidateQueries(['questionnaire', { questionnaireId: questionnaire._id }]),
        ),
      ),
      onError: () => toaster.error(t('toaster.templatePublished.failed')),
    },
  );

  const onPublish = useCallback(
    () => {
      if (!isRevising) {
        publishQuestionnaireTemplate({ templateId, currentCompanyId });
      } else if (isEmpty(sentQuestionnaires)) {
        confirmWithoutQuestionnaires(() => publishQuestionnaireTemplate({ templateId, currentCompanyId }));
      } else {
        confirmWithQuestionnaires((shouldUpdateQuestionnaires) => {
          return publishQuestionnaireTemplate({
            templateId,
            currentCompanyId,
            shouldUpdateQuestionnaires,
          });
        });
      }
    },
    [
      templateId,
      currentCompanyId,
      isRevising,
      sentQuestionnaires,
      confirmWithoutQuestionnaires,
      confirmWithQuestionnaires,
      publishQuestionnaireTemplate,
    ],
  );

  return (
    <>
      <DraftFooter
        onBack={() => draftNavigation.navigateToQuestions()}
        onPublish={onPublish}
        publishDisabled={isInvalid || isLoading || isLoadingSentQuestionnaires || isLoadingTemplateVersions}
        isInvalid={isInvalid}
      />
      <ConfirmActionDialog
        heading={t('questionnaireTemplate.confirmationDialogWithoutQuestionnaires.header')}
        variant="info"
        message={t('questionnaireTemplate.confirmationDialogWithoutQuestionnaires.message')}
        okButtonText={t('questionnaireTemplate.publishChanges')}
        okButtonVariant="primary"
        {...confirmWithoutQuestionnairesDialogProps}
      />
      {confirmWithQuestionnairesDialogProps.isOpen && (
        <UpdateSentQuestionnairesDialog
          sentQuestionnaires={sentQuestionnaires}
          // @ts-expect-error ts(2322) FIXME: Type 'QuestionnaireTemplateVersion[] | undefined' is not assignable to type 'QuestionnaireTemplateVersion[]'.
          templateVersions={templateVersions}
          {...confirmWithQuestionnairesDialogProps}
        />
      )}
      <AsyncActionDialog
        heading={t('questionnaireTemplate.questionnairesUpdatedDialog.header')}
        message={t('questionnaireTemplate.questionnairesUpdatedDialog.body')}
        onOk={() => {
          questionnairesUpdatedDialog.close();
          preQualNavigation.navigateToPreQualificationQuestionnaires(PreQualTab.ACTIVE_QUESTIONNAIRES);
        }}
        isOpen={questionnairesUpdatedDialog.isOpen}
      />
    </>
  );
};

export const DraftReview = () => {
  const { t } = useTranslation(['preQualification', 'translation']);
  // @ts-expect-error ts(2339) FIXME: Property 'isRevising' does not exist on type 'QuestionnaireTemplateStateContextType | undefined'.
  const { isRevising } = useQuestionnaireTemplateState();
  const { data: template, isLoading, isError } = useQuestionnaireTemplate({
    scope: 'draft',
  });
  const { data: activeTemplate } = useQuestionnaireTemplate({
    scope: 'current',
    enabled: isRevising,
  });

  const hasRevisionChanges = useMemo(
    () => {
      if (!template || !activeTemplate) return false;

      const changes = getQuestionnaireTemplateChanges(template, activeTemplate);

      return changes.length > 0;
    },
    [template, activeTemplate],
  );

  if (isLoading) {
    return <LoadingPanel />;
  }

  if (isError) {
    return <ErrorPanel error={t('errors.unexpected', { ns: 'translation' })} />;
  }

  // @ts-expect-error ts(2345) FIXME: Argument of type 'QuestionnaireTemplate | undefined' is not assignable to parameter of type 'QuestionnaireTemplate'.
  const errors = validateQuestionnaireTemplate(template, t);

  // Cannot publish a new version with no changes
  const isInvalidRevision = isRevising && !hasRevisionChanges;

  return (
    // @ts-expect-error ts(2322) FIXME: Type 'QuestionnaireTemplate | undefined' is not assignable to type 'QuestionnaireTemplate'.
    <QuestionnaireTemplateProvider template={template}>
      <DraftPageContent>
        <Stack gap="20px">
          <ReviewErrorsPanel errors={errors} />
          {activeTemplate && <RevisionChangeLog activeTemplate={activeTemplate} />}
          <BasicDetailsPanel />
          <PurposePanel />
          <ExpiryAndRenewalPanel />
          <QuestionsSectionPanel />
        </Stack>
      </DraftPageContent>
      <DraftReviewFooter isInvalid={!isEmpty(errors) || isInvalidRevision} />
    </QuestionnaireTemplateProvider>
  );
};
