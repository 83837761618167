import { Attachment } from '@deepstream/common/rfq-utils';
import * as React from 'react';
import { useQuery } from 'react-query';
import { Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { IconTextButton } from '@deepstream/ui-kit/elements/button/IconTextButton';
import { Panel, PanelText } from '@deepstream/ui-kit/elements/Panel';
import { useApi, wrap } from './api';
import { AuditTrailTable } from './AuditTrailTable';
import { useToaster } from './toast';
import { Loading } from './ui/Loading';
import { downloadUrl } from './useDownload';
import { useMutation } from './useMutation';

type AuditTrailProps = {
  companyId: string;
  navigateToPreQualification?: () => void;
};

export const AuditTrail: React.FC<AuditTrailProps> = ({ companyId, navigateToPreQualification }) => {
  const { t } = useTranslation('companyProfile');
  const api = useApi();
  const toaster = useToaster();

  const { data: answerSetAudit, status } = useQuery(
    ['answerSetAudit', { companyId }],
    wrap(api.getAnswerSetAudit),
  );

  const { data: questionnaire } = useQuery(
    'legacyQuestionnaire',
    wrap(api.getLegacyQuestionnaire),
  );

  const downloadAttachment = React.useCallback(
    async (attachment: Attachment) => {
      const { url } = await api.downloadPreQualDocument({
        currentCompanyId: companyId,
        attachmentId: attachment._id,
      });

      const { name } = attachment;
      downloadUrl(url, name);

      toaster.success(t('toaster.downloadSuccess', { name }));
    },
    [api, companyId, toaster, t],
  );

  const [downloadPreQualDocument] = useMutation(downloadAttachment);

  return (
    <>
      <IconTextButton
        icon="chevron-left"
        fontSize={3}
        onClick={navigateToPreQualification}
      >
        {t('auditTrail.backToPreQualification')}
      </IconTextButton>
      <Flex alignItems="center" mt={3} mb={4}>
        <Icon icon="history" fontSize="26px" mr={3} />
        <Text fontSize={8} fontWeight={500}>{t('auditTrail.auditTrail')}</Text>
      </Flex>
      <Panel>
        {status === 'loading' ? (
          <PanelText>
            <Loading />
          </PanelText>
        ) : status === 'error' ? (
          <PanelText>{t('error.errorGettingAuditTrail')}</PanelText>
        ) : answerSetAudit?.events.length ? (
          <AuditTrailTable
            auditTrailEvents={answerSetAudit.events}
            questionnaire={questionnaire}
            download={downloadPreQualDocument}
          />
        ) : (
          <PanelText>
            <Text color="gray">{t('auditTrail.noChanges')}</Text>
          </PanelText>
        )}
      </Panel>
    </>
  );
};
