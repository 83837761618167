import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import { SectionType } from '@deepstream/common/rfq-utils';
import { ErrorMessage } from '../../ui/ErrorMessage';
import * as validation from '../../draft/validation';
import { useShowValidationErrors } from './draftContract';
import { useContractSection } from './contract';

export const ContractSectionPanelHeading = () => {
  const { t } = useTranslation(['contracts', 'general']);
  const section = useContractSection();
  const showValidationErrors = useShowValidationErrors();
  const { error } = validation.useError('section.name');

  return (
    <Flex sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
      {showValidationErrors && error ? (
        <ErrorMessage fontSize="18px" error={error} />
      ) : section.type === SectionType.CONTRACT ? (
        t('contract')
      ) : section.name ? (
        <Text color="text">{section.name}</Text>
      ) : (
        <Text color="subtext">{t('untitled', { ns: 'general' })}</Text>
      )}
    </Flex>
  );
};
