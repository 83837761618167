import { Box, Text } from 'rebass/styled-components';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';

import { useImportQuestionsContext } from '../../ImportQuestionsProvider';
import { CategoryEntry } from './CategoryEntry';

export const CategoriesList = () => {
  const { categories } = useImportQuestionsContext();
  const { t } = useTranslation('preQualification');

  return (
    <Box>
      <Text
        sx={{
          textTransform: 'uppercase',
          fontWeight: 500,
          px: 3,
          py: 2,
          borderBottom: 'lightGray2',
        }}
      >
        {t('importQuestions.modal.categoriesListHeading')}
      </Text>
      <Box sx={{ maxHeight: '700px', overflow: 'auto' }}>
        <Box>
          {map(categories, (category) => (
            <CategoryEntry category={category} key={category._id} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
