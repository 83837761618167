import { useCallback, useMemo } from 'react';
import * as React from 'react';
import * as Sentry from '@sentry/react';
import { parse } from 'date-fns';
import { isEmpty, map } from 'lodash';
import { MutateOptions, useQueryClient } from 'react-query';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  ImportLegacyContractPayload,
  ImportLegacyContractValidationPayload,
} from '@deepstream/common/contract/contract';

import { DateFormat } from '@deepstream/utils';
import { useToaster } from '../../../toast';
import { useApi } from '../../../api';
import { useCurrentCompanyId } from '../../../currentCompanyId';

import { BulkImportModal } from '../../../ui/BulkImport';
import { useMutation } from '../../../useMutation';
import { useCurrencies } from '../../../ui/currencies';
import { getHeaderKey, getHeaderLabel } from './bulk-upload-contracts-utils';
import { useCurrentUserLocale } from '../../../useCurrentUser';

type LegacyContractCSVData = Omit<ImportLegacyContractPayload, 'spendData'> & {
  startDate: string;
  expiryDate?: string;
  amount: ImportLegacyContractPayload['spendData']['amount'];
  currencyCode: ImportLegacyContractPayload['spendData']['currencyCode'];
};

type LegacyContractsImportResponse = {
  success: {
    _id: string;
    name: string;
  }[];
  failed: {
    name: string;
    reason: string;
  }[];
};

const mapCSVDataToContractPayload = (csvContracts: LegacyContractCSVData[]): ImportLegacyContractPayload[] => {
  return map(csvContracts, (csvContract) => {
    const {
      amount,
      currencyCode,
      reference,
      counterpartyId,
      startDate: rawStartDate,
      expiryDate: rawExpiryDate,
      ...rest
    } = csvContract;

    const startDate = parse(rawStartDate, DateFormat.DD_MM_YYYY_SLASH, new Date());
    const expiryDate = rawExpiryDate
      ? parse(rawExpiryDate, DateFormat.DD_MM_YYYY_SLASH, new Date())
      : undefined;

    return {
      ...rest,
      startDate,
      expiryDate,
      spendData: { amount, currencyCode },
      counterpartyId: counterpartyId ? String(counterpartyId) : undefined,
      reference: reference ? String(reference) : undefined,
    };
  });
};

const mapCSVDataToContractValidation = (csvContracts: LegacyContractCSVData[]): ImportLegacyContractValidationPayload[] => {
  return map(csvContracts, (csvContract) => {
    const { contractOwnerEmail, counterpartyId } = csvContract;
    return {
      contractOwnerEmail,
      counterpartyId: counterpartyId ? String(counterpartyId) : undefined,
    };
  });
};

const useImportLegacyContractsMutation = () => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId();
  const [mutate] = useMutation(api.importLegacyContracts);
  return useCallback(async (
    payload: ImportLegacyContractPayload[],
    options?: MutateOptions<LegacyContractsImportResponse, unknown, { companyId: string; contracts: ImportLegacyContractPayload[]; }, unknown>,
  ) => {
    return mutate({
      // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
      companyId: currentCompanyId,
      contracts: payload,
    }, options);
  }, [currentCompanyId, mutate]);
};

const useDataValidationSchema = () => {
  const { t } = useTranslation(['contracts', 'general']);
  const currencies = useCurrencies();
  const api = useApi();
  const locale = useCurrentUserLocale();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const toaster = useToaster();

  const schema = useMemo(() => {
    const currencyCodes = Object.keys(currencies);

    const getColumnRequiredError = (params: { path?: string }) => {
      const key = params.path!.split('.')[1];
      const column = getHeaderLabel(key, locale) || key;

      return t('legacy.validation.requiredColumn', { column });
    };

    const contractShape = yup.object().shape({
      name: yup.string().ensure().required(t('required', { ns: 'general' })),
      description: yup.string().nullable(),
      startDate: yup.date().transform((value, originalValue) => {
        return originalValue ? parse(originalValue, DateFormat.DD_MM_YYYY_SLASH, new Date()) : undefined;
      }).typeError(t('legacy.validation.invalidDateFormat')).required(
        t('required', { ns: 'general' }),
      ),
      expiryDate: yup.date().transform((value, originalValue) => {
        return originalValue ? parse(originalValue, DateFormat.DD_MM_YYYY_SLASH, new Date()) : undefined;
      }).typeError(t('legacy.validation.invalidDateFormat')).min(
        yup.ref('startDate'),
        t('legacy.validation.minExpiryDate'),
      )
        .nullable(),
      amount: yup.number().min(0).required(t('required', { ns: 'general' })),
      currencyCode: yup.string().ensure()
        .oneOf(currencyCodes, t('legacy.validation.invalidCurrency'))
        .required(t('required', { ns: 'general' })),
      counterpartyName: yup.string().when('counterpartyId', {
        is: (counterpartyId) => !counterpartyId,
        then: yup.string().ensure().required(t('legacy.validation.atLeastOneCounterpartyNameAndId')),
        otherwise: yup.string().max(0, t('legacy.validation.notBothCounterpartyNameAndId')).nullable(true),
      }),
      counterpartyId: yup.string().when('counterpartyName', {
        is: (counterpartyName) => !counterpartyName,
        then: yup.string().ensure().required(t('legacy.validation.atLeastOneCounterpartyNameAndId')),
        otherwise: yup.string().max(0, t('legacy.validation.notBothCounterpartyNameAndId')).nullable(true),
      }),
      reference: yup.string().nullable(),
      contractOwnerEmail: yup.string().ensure().email().required(t('required', { ns: 'general' })),
    }, [['counterpartyName', 'counterpartyId']]);

    const columns = yup.object().shape({
      name: yup.boolean().required(getColumnRequiredError),
      description: yup.boolean().required(getColumnRequiredError),
      startDate: yup.boolean().required(getColumnRequiredError),
      expiryDate: yup.boolean().notRequired(),
      currencyCode: yup.boolean().required(getColumnRequiredError),
      counterpartyName: yup.boolean().required(getColumnRequiredError),
      counterpartyId: yup.boolean().required(getColumnRequiredError),
      amount: yup.boolean().required(getColumnRequiredError),
      reference: yup.boolean().notRequired(),
      contractOwnerEmail: yup.boolean().required(getColumnRequiredError),
    }).strict(true).noUnknown(true, t('legacy.validation.extraColumns'));

    return yup.object({
      columns,
      data: contractShape,
    });
  }, [t, locale, currencies]);

  const validateAsync = useCallback(async function(this: { createError: any }, data: LegacyContractCSVData[]) {
    try {
      const validationErrors = await api.validateLegacyContractsPayload({
        companyId: currentCompanyId,
        contracts: mapCSVDataToContractValidation(data),
      });

      const errors = [];

      validationErrors.forEach((contractError, index) => {
        if (!isEmpty(contractError)) {
          for (const [key, value] of Object.entries(contractError)) {
            // @ts-expect-error ts(2345) FIXME: Argument of type 'any' is not assignable to parameter of type 'never'.
            errors.push(this.createError({
              path: `data[${index}].${key}`,
              message: t(`legacy.validation.${value}`),
            }));
          }
        }
      });

      if (errors.length === 0) {
        return true;
      }

      return this.createError({
        message: () => errors,
      });
    } catch (error) {
      Sentry.captureException(error);

      const errorMessage = t('legacy.validation.serverValidationFailed');

      toaster.error(errorMessage);

      return this.createError({
        message: errorMessage,
      });
    }
  }, [api, currentCompanyId, t, toaster]);

  return [schema, validateAsync] as const;
};

export const LegacyContractsBulkImport = ({ onClose }: { onClose: () => void }) => {
  const uploadLegacyContracts = useImportLegacyContractsMutation();
  const { t } = useTranslation(['contracts', 'general']);
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const locale = useCurrentUserLocale();
  const [schema, validateAsync] = useDataValidationSchema();

  return (
    <BulkImportModal<LegacyContractCSVData>
      heading={t('legacy.uploadModal.heading')}
      description={t('legacy.uploadModal.description')}
      getHeaderLabel={(key) => getHeaderLabel(key, locale) || key}
      // @ts-expect-error ts(2322) FIXME: Type '(header: string) => string | undefined' is not assignable to type '(label: string) => string'.
      getHeaderKey={getHeaderKey}
      onClose={onClose}
      onSave={async ({ data }) => {
        const payload = mapCSVDataToContractPayload(data);
        await uploadLegacyContracts(payload, {
          onSuccess: ({ success, failed }) => {
            if (success.length > 0) {
              toaster.success(t('legacy.uploadMessages.uploadSuccess', { count: success.length }));

              queryClient.invalidateQueries(['sentContracts']);
            }

            if (failed.length > 0) {
              toaster.error(t('legacy.uploadMessages.uploadFailed', { count: failed.length }));
            }
          },
          onError: () => toaster.error(t('legacy.uploadMessages.networkError')),
        });
      }}
      validationSchema={schema}
      validateAsync={validateAsync}
    />
  );
};
