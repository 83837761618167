import { compact, find, first, isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Live } from '@deepstream/common/rfq-utils';
import { useLocalStorageState } from '../../../useLocalStorageState';
import * as rfx from '../../../rfx';

type RequirementGroupSelectItem = { value: string; label: string };

export const useSelectRequirementGroups = ({
  storageKey,
  availableRequirementGroupIds,
}: {
  storageKey: string;
  availableRequirementGroupIds: string[];
}) => {
  const { t } = useTranslation('translation');
  const { lots } = rfx.useStructure<Live>();

  const items = useMemo(() => {
   return [
      ...availableRequirementGroupIds.includes('general')
        ? [{
          value: 'general',
          label: t('request.generalRequirement_other'),
        }]
        : [],
      ...compact(lots.map((lot, index) => {
        return availableRequirementGroupIds.includes(lot._id) && !lot.isObsolete
          ? {
            value: lot._id,
            label: `${index + 1} – ${lot.name}`,
          }
          : null;
      })),
    ];
  }, [t, lots, availableRequirementGroupIds]);

  const [selectedItems, setSelectedItems] = useLocalStorageState<RequirementGroupSelectItem[]>({
    key: storageKey,
    // @ts-expect-error ts(2322) FIXME: Type '({ value: string; label: string; } | undefined)[]' is not assignable to type 'RequirementGroupSelectItem[] | (() => RequirementGroupSelectItem[])'.
    defaultValue: isEmpty(items) ? [] : [first(items)],
    // @ts-expect-error ts(2322) FIXME: Type '(initialItems: RequirementGroupSelectItem[]) => ({ value: string; label: string; } | undefined)[]' is not assignable to type '(value: RequirementGroupSelectItem[]) => RequirementGroupSelectItem[]'.
    mapInitialValue: (initialItems) => {
      const initialItem = first(initialItems);
      const mappedInitialItem = initialItem
        ? find(items, { value: initialItem.value })
        : null;

      return mappedInitialItem ? (
        [mappedInitialItem]
      ) : !isEmpty(items) ? (
        [first(items)]
      ) : (
        []
      );
    },
  });

  return {
    itemToString: (item: RequirementGroupSelectItem | null) => item ? item.value : '',
    renderItem: (item: RequirementGroupSelectItem | null) => item ? item.label : '',
    items: items ?? [],
    selectedItems,
    onChange: setSelectedItems,
  };
};
