import { useNavigate } from '../../tanstackRouter';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { Requests } from '../../Requests';
import {
  requestEditSummaryRoute,
  requestsIndexRoute,
  templatePreviewRoute,
  templateSummaryRoute,
  requestSentSuppliersViewRoute,
  legacyRequestReceivedIndexRoute,
} from '../../AppRouting';
import { RequestsLayout } from './RequestsLayout';

export const RequestsPage = ({ selectedTabId }: { selectedTabId?: string }) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const navigate = useNavigate();

  return (
    <RequestsLayout>
      <Requests
        selectedTabId={selectedTabId}
        navigateToSenderRequest={({ requestId, isDraft }) => {
          if (isDraft) {
            navigate({
              to: requestEditSummaryRoute.to,
              params: { currentCompanyId, rfqId: requestId },
            });
          } else {
            navigate({
              to: requestSentSuppliersViewRoute.to,
              params: { currentCompanyId, rfqId: requestId },
            });
          }
        }}
        navigateToReceivedRequest={requestId => {
          navigate({
            to: legacyRequestReceivedIndexRoute.to,
            params: { currentCompanyId, rfqId: requestId },
          });
        }}
        navigateToTemplatePreview={templateId => {
          navigate({
            to: templatePreviewRoute.to,
            params: { currentCompanyId, rfqId: templateId },
          });
        }}
        navigateToTemplateEdit={templateId => {
          navigate({
            to: templateSummaryRoute.to,
            params: { currentCompanyId, rfqId: templateId },
          });
        }}
        navigateToRequestsTab={(tab, replace) => {
          navigate({
            to: requestsIndexRoute.to,
            params: { currentCompanyId },
            search: tab ? { tab } : undefined,
            replace,
          });
        }}
      />
    </RequestsLayout>
  );
};
