/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import * as React from 'react';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import {
  TExpandableRowDataBase,
  TOriginalSubRowDataBase,
  getGridCellId,
  DataCellProps,
  GridDataAndCommands,
} from '../core/utils';
import { EditableGridColumn } from './utils';
import { useHover } from '../../hooks/useHover';
import { useWatchValue } from '../../hooks/useWatchValue';
import { useGridSelection } from './GridSelectionContext';

const RenderSelectableGridDataCellBase = <
  TOriginalSubRowData extends TOriginalSubRowDataBase,
  TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
>({
  data,
  rowIndex,
  columnIndex,
  style,
}: {
  data: GridDataAndCommands<EditableGridColumn, TOriginalSubRowData, TOriginalRowData> & {
    DataCell: React.FunctionComponent<DataCellProps<
      EditableGridColumn,
      TOriginalSubRowData,
      TOriginalRowData
    >>;
    bodyPaddingLeft: number;
    frozenLeftColumnCount: number;
  };
  columnIndex: number;
  rowIndex: number;
  style: React.CSSProperties;
}) => {
  const [cellRef, cellHovered] = useHover<HTMLDivElement>();
  const { isSelected } = useGridSelection();
  const row = data.rows[rowIndex];
  const column = data.columns[columnIndex];

  const { DataCell, hoverCellIndices } = data;

  useWatchValue(
    cellHovered,
    (hovered) => {
      if (hovered) {
        data.setHoverCellIndices({ rowIndex, columnIndex });
      } else {
        data.setHoverCellIndices((hoverCellIndices) => {
          // only set hoverCellIndices to null when the current value
          // hasn't been set to a different cell
          return isEqual(hoverCellIndices, { rowIndex, columnIndex })
            ? null
            : hoverCellIndices;
        });
      }
    },
  );

  const isHover = Boolean(
    hoverCellIndices &&
    hoverCellIndices.rowIndex === rowIndex,
  );

  if (rowIndex === 0 || !row) {
    return null;
  }

  const selected = isSelected(row.original._id);

  if ((row.original as any).isSubHeader) {
    return (
      <div
        className="group-header"
        style={{
          ...style,
          // TODO simplify!
          top: columnIndex < data.frozenLeftColumnCount ? `${row.top}px` : style.top,
        }}
      >
        {column.index === 0 && (
          <DataCell
            row={row}
            column={column}
            isActive={false}
          />
        )}
      </div>
    );
  }

  return (
    <div
      ref={cellRef}
      id={getGridCellId(data.idPrefix, { rowIndex, columnIndex })}
      aria-rowindex={rowIndex + 1}
      aria-colindex={columnIndex + 1}
      role="gridcell"
      className={clsx(
        selected && 'selected',
        isHover && 'hover',
      )}
      style={{
        ...style,
        // TODO simplify!
        top: columnIndex < data.frozenLeftColumnCount ? `${row.top}px` : style.top,
      }}
      onClick={() => {
        data.onRowClick?.(row.original);
      }}
    >
      <DataCell
        row={row}
        column={column}
        isActive={false}
      />
    </div>
  );
};

export const RenderSelectableGridDataCell = React.memo(
  RenderSelectableGridDataCellBase,
) as typeof RenderSelectableGridDataCellBase;
