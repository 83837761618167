import { LineItemExchangeDefinition } from '@deepstream/common/rfq-utils';
import { useMemo } from 'react';
import { find, isEqual, keys, map, mapValues, pick, reduce } from 'lodash';
import { LineItemsExchangeSnapshot } from '../../types';

export const useExchangeDefsWithContractExchanges = ({
  isContract,
  exchangeDefs,
  exchanges,
}) => {
  return useMemo(() => {
    if (!isContract) {
      return exchangeDefs;
    }

    // We need the actual exchange to be able to show the value in exchange def table
    const defsWithExchange = map(exchangeDefs, (exchangeDef) => {
      const exchange = find(
        exchanges,
        (exchange): exchange is LineItemsExchangeSnapshot => exchange._id === exchangeDef._id,
      );

      return { ...exchangeDef, exchange };
    });

    // If buyer makes a change to an already submitted line item we have to
    // revert back to the `Supplier to provide` cell text.
    // For this the best approach is to compare the live version of the
    // exchange def with the current exchange def.
    // If there has been something change we set all keys from `latestReply` to
    // `null` so we will render `Supplier to provide` cell dynamically.
    // Note: The `latestReply` fields are set automatically to null after
    // issuing a revision/draft for line items that have been changed.
    return reduce(defsWithExchange, (accumulator, defWithExchange) => {
      const hasLiveVersion = !!defWithExchange?.liveVersion;

      if (!hasLiveVersion) {
        return [...accumulator, defWithExchange];
      }

      const keysToCheck = keys(defWithExchange.liveVersion.fields);
      const areColumnsTheSame = isEqual(
        // NB Omitting the response tags is necessary because we initially include
        // the response tags when importing request exchangeDefs/exchanges into a contract
        // to ensure that the replaying of line item events yields that same results as
        // in requests.
        // After replaying, we remove the response tags from the line item exchanges
        // (including its `def`) and update the draft of the exchangeDef but the
        // exchangeDef's live version still contains them, so we need to ignore them here.
        mapValues(defWithExchange.liveVersion.fields, ({ responseTags, ...field }) => field),
        mapValues(defWithExchange.fields, ({ responseTags, ...field }) => field),
      );
      const areValuesTheSame = isEqual(
        pick(defWithExchange, keysToCheck),
        pick(defWithExchange.liveVersion, keysToCheck),
      );

      if (areColumnsTheSame && areValuesTheSame) {
        return [...accumulator, defWithExchange];
      }
      // @ts-expect-error ts(2769) FIXME: No overload matches this call.
      const exchangeWithEmptyReplies = mapValues(defWithExchange.exchange.latestReply, null);

      return [
        ...accumulator,
        {
          ...defWithExchange,
          exchange: exchangeWithEmptyReplies,
        },
      ];
    }, [] as (LineItemExchangeDefinition & { exchange: LineItemsExchangeSnapshot })[]);
  }, [isContract, exchangeDefs, exchanges]);
};
