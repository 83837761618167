import { useCallback } from 'react';
import { isUndefined, omitBy } from 'lodash';
import { TFunction } from 'i18next';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';

import { ExchangeType, PageType } from '@deepstream/common/rfq-utils';
import {
  OPEN_APPROVAL_QUERY_PARAM,
  APPROVAL_REQUEST_ID_QUERY_PARAM,
  OpenApprovalParamValue,
} from '@deepstream/common/stageApprovals';
import { AUCTION_CHAT_EXCHANGE_NAME } from '@deepstream/common/constants';
import { NotificationDomain, NotificationAction, getNotificationMessage } from '@deepstream/common/notification-utils';
import { RenderNotificationError } from '@deepstream/errors';

import { useNavigate } from '../../tanstackRouter';
import {
  companyProfileRoute,
  contractLiveContractRoute,
  contractLiveDetailsIndexRoute,
  contractLiveDetailsPageRoute,
  contractLiveIndexRoute,
  contractLiveSummaryRoute,
  contractLiveTeamRoute,
  contractsRoute,
  preQualificationQuestionnairesRoute,
  questionnaireDetailsRoute,
  questionnaireQuestionsRoute,
  questionnaireTemplateActiveSuppliersRoute,
  requestEditApprovalsRoute,
  legacyRequestReceivedAuctionRoute,
  legacyRequestReceivedAuditRoute,
  legacyRequestReceivedAwardRoute,
  legacyRequestReceivedBidIndexRoute,
  legacyRequestReceivedBidPageRoute,
  legacyRequestReceivedDetailsRoute,
  legacyRequestReceivedLegacyBulletinQuestionsRoute,
  legacyRequestReceivedLegacyBulletinsListRoute,
  legacyRequestReceivedTeamRoute,
  requestSentAuctionRoute,
  requestSentCommentsRoute,
  requestSentComparisonRoute,
  requestSentDetailsRoute,
  requestSentLegacyBulletinQuestionsRoute,
  requestSentLegacyBulletinsListRoute,
  requestSentMessagesRoute,
  legacyRequestSentRecipientBidExchangeRoute,
  requestSentSuppliersViewRoute,
  requestSentTeamRoute,
  requestsIndexRoute,
  teamManagementRoute,
  questionnaireTeamRoute,
  requestReceivedBidIndexRoute,
  requestSentRecipientBidRoute,
  requestSentRecipientBidIndexRoute,
} from '../../AppRouting';
import { Notification } from './types';
import { CompanyFeatureFlags } from '../../types';
import { useCompanyFeatureFlags } from '../../companyFeatureFlags';
import { useApi } from '../../api';
import { ApiClient } from '../../apiClient';
import { downloadUrl } from '../../useDownload';

const messagesExchangeTypes = [
  ExchangeType.CHAT,
  ExchangeType.CHAT_NO_RECEIVER_UPLOAD,
  ExchangeType.CHAT_ONLY_RECEIVER_UPLOAD,
  ExchangeType.CLARIFICATION,
];

// These exchange types should be opened in the new ExchangeModal (React)
const newExchangeModalTypes = [
  ExchangeType.LINE_ITEM,
  ExchangeType.DOCUMENT_REQUEST,
  ExchangeType.DOCUMENT_REQUEST_CLOSED,
  ExchangeType.DOCUMENT_REQUEST_LOCKED,
  ExchangeType.COMPLETE_OR_SIGN,
  ExchangeType.COMPLETE_OR_SIGN_CLOSED,
  ExchangeType.COMPLETE_OR_SIGN_LOCKED,
  ExchangeType.ACCEPT,
  ExchangeType.ACCEPT_CLOSED,
  ExchangeType.INFORMATION,
  ExchangeType.INCLUSIONS,
  ExchangeType.TERMS,
  ExchangeType.FEES,
  ExchangeType.QUESTION,
];

type NotificationOptions = {
  navigateOptions: ((
    notification: Notification,
    companyFeatureFlags: CompanyFeatureFlags,
  ) => Parameters<ReturnType<typeof useNavigate>>[0]) | null,
};

type NotificationTableType = Partial<Record<
  NotificationDomain,
  Partial<Record<NotificationAction, NotificationOptions>>
>>;

export const notificationNavigateOptionsTable: NotificationTableType = {
  [NotificationDomain.RFQ_RECEIVED]: {
    [NotificationAction.RFQ_CREATED]: {
      navigateOptions: ({ to, meta }, companyFeatureFlags) => ({
        to: legacyRequestReceivedDetailsRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.RFQ_UPDATED]: {
      navigateOptions: ({ to, meta }) => ({
        to: legacyRequestReceivedAuditRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.RFQ_AWARDED]: {
      navigateOptions: ({ to, meta }) => ({
        to: legacyRequestReceivedAwardRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.BIDS_REJECTED]: {
      navigateOptions: ({ to, meta }) => ({
        to: legacyRequestReceivedBidIndexRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.BIDS_REINSTATED]: {
      navigateOptions: ({ to, meta }) => ({
        to: legacyRequestReceivedBidIndexRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.RFQ_CLOSED]: {
      navigateOptions: ({ to, meta }) => ({
        to: legacyRequestReceivedBidIndexRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.RFQ_LOST]: {
      navigateOptions: ({ to, meta }, companyFeatureFlags) => ({
        to: legacyRequestReceivedBidIndexRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.NEW_MEMBER]: {
      navigateOptions: ({ to, meta }) => ({
        to: legacyRequestReceivedTeamRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.REMOVED_MEMBER]: {
      navigateOptions: ({ to }) => ({
        to: requestsIndexRoute.to,
        params: { currentCompanyId: to.companyId },
      }),
    },
    [NotificationAction.RFQ_MEMBER_ROLES_UPDATED]: {
      navigateOptions: ({ to, meta }, companyFeatureFlags) => ({
        to: legacyRequestReceivedTeamRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.BID_SENT]: {
      navigateOptions: ({ to, meta }) => ({
        to: legacyRequestReceivedBidIndexRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.BULLETIN_QUESTION_POSTED]: {
      navigateOptions: ({ to, meta }) => ({
        to: legacyRequestReceivedLegacyBulletinQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.BULLETIN_SIMPLE_CLARIFICATION]: {
      navigateOptions: ({ to, meta }) => ({
        to: legacyRequestReceivedLegacyBulletinsListRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.BULLETIN_ANSWER_TO_QUESTION_POSTED]: {
      navigateOptions: ({ to, meta }) => ({
        to: legacyRequestReceivedLegacyBulletinQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.BULLETIN_CLARIFICATION_TO_QUESTION_POSTED]: {
      navigateOptions: ({ to, meta }) => ({
        to: legacyRequestReceivedLegacyBulletinQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.EXCHANGE_REPLY_SENT]: {
      // @ts-expect-error ts(2322) FIXME: Type '({ to, meta }: Notification) => { to: "/$currentCompanyId/request/received/$rfqId/auction"; params: { currentCompanyId: string; rfqId: any; pageId?: undefined; }; search?: undefined; } | { ...; } | undefined' is not assignable to type '(notification: Notification, companyFeatureFlags: CompanyFeatureFlags) => NavigateOptions<AnyRouter, any, string, any, string>'.
      navigateOptions: ({ to, meta }) => {
        if (messagesExchangeTypes.includes(meta.exchangeType)) {
          // We check the exchangeName for backwards compatibility with legacy notifications without pageType
          if (meta.pageType === PageType.AUCTION || meta.exchangeName === AUCTION_CHAT_EXCHANGE_NAME) {
            return {
              to: legacyRequestReceivedAuctionRoute.to,
              params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
            };
          } else {
            return {
              to: legacyRequestReceivedBidPageRoute.to,
              params: { currentCompanyId: to.companyId, rfqId: meta.rfqId, pageId: meta.pageId },
              search: omitBy({ sectionId: meta.sectionId, exchangeId: meta.exchangeId }, isUndefined),
            };
          }
        } else if (newExchangeModalTypes.includes(meta.exchangeType)) {
          return {
            to: legacyRequestReceivedBidPageRoute.to,
            params: { currentCompanyId: to.companyId, rfqId: meta.rfqId, pageId: meta.pageId },
            search: omitBy({ exchangeId: meta.exchangeId, recipientId: meta.recipientId }, isUndefined),
          };
        }
      },
    },
    [NotificationAction.EXCHANGE_REPLIES_SENT]: {
      navigateOptions: ({ to, meta }) => {
        return {
          to: requestReceivedBidIndexRoute.to,
          params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
        };
      },
    },
    [NotificationAction.REQUEST_BULLETIN_ADDED]: {
      navigateOptions: ({ to, meta }) => ({
        to: legacyRequestReceivedBidPageRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId, pageId: meta.pageId },
        search: { sectionId: meta.sectionId, exchangeId: meta.bulletinId, recipientId: to.companyId },
      }),
    },
    [NotificationAction.REQUEST_BULLETIN_UPDATED]: {
      navigateOptions: ({ to, meta }) => ({
        to: legacyRequestReceivedBidPageRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId, pageId: meta.pageId },
        search: { sectionId: meta.sectionId, exchangeId: meta.bulletinId, recipientId: to.companyId },
      }),
    },
    [NotificationAction.UPCOMING_AUCTION]: {
      navigateOptions: ({ to, meta }) => ({
        to: legacyRequestReceivedAuctionRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.AUCTION_RESCHEDULED]: {
      navigateOptions: ({ to, meta }) => ({
        to: legacyRequestReceivedAuctionRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.AUCTION_REVISED]: {
      navigateOptions: ({ to, meta }) => ({
        to: legacyRequestReceivedAuctionRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.AUCTION_STARTED]: {
      navigateOptions: ({ to, meta }) => ({
        to: legacyRequestReceivedAuctionRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
  },
  [NotificationDomain.RFQ_SENT]: {
    [NotificationAction.RFQ_CREATED]: {
      navigateOptions: ({ to, meta }) => ({
        to: requestSentDetailsRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.RFQ_UPDATED]: {
      navigateOptions: null,
    },
    [NotificationAction.NEW_MESSAGE_RFQ]: {
      navigateOptions: null,
    },
    [NotificationAction.NEW_MEMBER]: {
      navigateOptions: ({ to, meta }) => ({
        to: requestSentTeamRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
        search: { companyId: to.companyId },
      }),
    },
    [NotificationAction.REMOVED_MEMBER]: {
      navigateOptions: ({ to }) => ({
        to: requestsIndexRoute.to,
        params: { currentCompanyId: to.companyId },
      }),
    },
    [NotificationAction.RFQ_MEMBER_ROLES_UPDATED]: {
      navigateOptions: ({ to, meta }) => ({
        to: requestSentTeamRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
        search: { companyId: to.companyId },
      }),
    },
    [NotificationAction.NEW_RECIPIENT]: {
      navigateOptions: ({ to, meta }) => ({
        to: requestSentSuppliersViewRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.RFQ_AWARDED]: {
      navigateOptions: ({ to, meta }) => ({
        to: requestSentSuppliersViewRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.BIDS_REJECTED]: {
      navigateOptions: ({ to, meta }) => ({
        to: requestSentSuppliersViewRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.BIDS_REINSTATED]: {
      navigateOptions: ({ to, meta }) => ({
        to: requestSentSuppliersViewRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.RFQ_CLOSED]: {
      navigateOptions: ({ to, meta }) => ({
        to: requestSentSuppliersViewRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.INTENTION_TO_BID]: {
      navigateOptions: ({ to, meta }) => ({
        to: requestSentSuppliersViewRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.BID_SENT]: {
      navigateOptions: ({ to, meta }) => ({
        to: requestSentSuppliersViewRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.BULLETIN_QUESTION_POSTED]: {
      navigateOptions: ({ to, meta }) => ({
        to: requestSentLegacyBulletinQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.BULLETIN_SIMPLE_CLARIFICATION]: {
      navigateOptions: ({ to, meta }) => ({
        to: requestSentLegacyBulletinsListRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.BULLETIN_ANSWER_TO_QUESTION_POSTED]: {
      navigateOptions: ({ to, meta }) => ({
        to: requestSentLegacyBulletinQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    bulletinClarificationToQuestionPosted: {
      navigateOptions: ({ to, meta }) => ({
        to: requestSentLegacyBulletinQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.RFQ_COMMENT_ADDED]: {
      navigateOptions: ({ to, meta }) => ({
        to: requestSentCommentsRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.EXCHANGE_REPLY_SENT]: {
      navigateOptions: ({ to, meta }) => {
        if (messagesExchangeTypes.includes(meta.exchangeType)) {
          // We check the exchangeName for backwards compatibility with legacy notifications without pageType
          if (meta.pageType === PageType.AUCTION || meta.exchangeName === AUCTION_CHAT_EXCHANGE_NAME) {
            return {
              to: requestSentAuctionRoute.to,
              params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
            };
          } else {
            return {
              to: requestSentMessagesRoute.to,
              params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
              search: { exchangeId: meta.exchangeId, recipientId: meta.recipientId },
            };
          }
        } else if (meta.exchangeType === ExchangeType.EVALUATION_CRITERION) {
          return {
            to: requestSentMessagesRoute.to,
            params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
          };
        } else {
          return {
            to: legacyRequestSentRecipientBidExchangeRoute.to,
            params: {
              currentCompanyId: to.companyId,
              rfqId: meta.rfqId,
              recipientId: meta.recipientId,
              exchangeId: meta.exchangeId,
            },
            search: omitBy({ pageId: meta.pageId }, isUndefined),
          };
        }
      },
    },
    [NotificationAction.EXCHANGE_REPLIES_SENT]: {
      navigateOptions: ({ to, meta }) => {
        return {
          to: requestSentRecipientBidIndexRoute.to,
          params: { currentCompanyId: to.companyId, recipientId: meta.recipientId, rfqId: meta.rfqId },
        };
      },
    },
    [NotificationAction.APPROVAL_REQUESTED]: {
      navigateOptions: ({ to, meta }) => {
        const commonSearchParams = {
          [OPEN_APPROVAL_QUERY_PARAM]: OpenApprovalParamValue.submitResponse,
          [APPROVAL_REQUEST_ID_QUERY_PARAM]: meta.approvalRequestId,
        };

        if (meta.stageId) {
          return {
            to: requestSentSuppliersViewRoute.to,
            params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
            search: { ...commonSearchParams },
          };
        } else {
          return {
            to: requestEditApprovalsRoute.to,
            params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
            search: { ...commonSearchParams },
          };
        }
      },
    },
    [NotificationAction.APPROVAL_DECIDED]: {
      navigateOptions: ({ to, meta }) => {
        const commonSearchParams = {
          [OPEN_APPROVAL_QUERY_PARAM]: OpenApprovalParamValue.viewApproval,
          [APPROVAL_REQUEST_ID_QUERY_PARAM]: meta.approvalRequestId,
        };

        if (meta.stageId) {
          return {
            to: requestSentSuppliersViewRoute.to,
            params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
            search: { ...commonSearchParams },
          };
        } else {
          return {
            to: requestEditApprovalsRoute.to,
            params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
            search: { ...commonSearchParams },
          };
        }
      },
    },
    [NotificationAction.APPROVAL_CANCELLED]: {
      navigateOptions: ({ to, meta }) => {
        if (meta.stageId) {
          return {
            to: requestSentSuppliersViewRoute.to,
            params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
          };
        } else {
          return {
            to: requestEditApprovalsRoute.to,
            params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
          };
        }
      },
    },
    [NotificationAction.UPCOMING_AUCTION]: {
      navigateOptions: ({ to, meta }) => ({
        to: requestSentAuctionRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.AUCTION_STARTED]: {
      navigateOptions: ({ to, meta }) => ({
        to: requestSentAuctionRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
    [NotificationAction.EVALUATION_REMINDER]: {
      navigateOptions: ({ to, meta }) => ({
        to: requestSentComparisonRoute.to,
        params: { currentCompanyId: to.companyId, rfqId: meta.rfqId },
      }),
    },
  },
  [NotificationDomain.TEAM_MANAGEMENT]: {
    [NotificationAction.MEMBERSHIP_REQUEST_DECISION]: {
      navigateOptions: ({ to }) => ({
        to: teamManagementRoute.to,
        params: { currentCompanyId: to.companyId },
      }),
    },
    [NotificationAction.REQUESTED_TO_JOIN]: {
      navigateOptions: ({ to }) => ({
        to: teamManagementRoute.to,
        params: { currentCompanyId: to.companyId },
        search: { tab: 'requests-to-join' },
      }),
    },
    [NotificationAction.USER_PERMISSION_CHANGED]: {
      navigateOptions: ({ to }) => ({
        to: teamManagementRoute.to,
        params: { currentCompanyId: to.companyId },
      }),
    },
    [NotificationAction.USER_REMOVED_FROM_TEAM]: {
      navigateOptions: ({ to }) => ({
        to: teamManagementRoute.to,
        params: { currentCompanyId: to.companyId },
      }),
    },
    [NotificationAction.USER_ADDED_TO_TEAM]: {
      navigateOptions: ({ to }) => ({
        to: teamManagementRoute.to,
        params: { currentCompanyId: to.companyId },
      }),
    },
  },
  [NotificationDomain.SUPPLIER_MANAGEMENT]: {
    [NotificationAction.SUPPLIER_INVITED_CREATES_ACCOUNT]: {
      navigateOptions: ({ to, meta }) => ({
        to: companyProfileRoute.to,
        params: { currentCompanyId: to.companyId, targetCompanyId: meta.companyAffected._id },
      }),
    },
  },
  [NotificationDomain.CLIENT_COMPANIES]: {
    [NotificationAction.CLIENT_COMPANY_REQUEST_SENT]: {
      navigateOptions: null,
    },
    [NotificationAction.CLIENT_COMPANY_CLIENT_REPLIED_TO_REQUEST]: {
      navigateOptions: null,
    },
  },
  [NotificationDomain.PQQ_RECEIVED]: {
    [NotificationAction.PQQ_ISSUED]: {
      navigateOptions: ({ to }) => ({
        to: companyProfileRoute.to,
        params: { currentCompanyId: to.companyId, targetCompanyId: to.companyId },
        search: { show: 'pre-qualification' },
      }),
    },
  },
  [NotificationDomain.PQQ_SENT]: {
    [NotificationAction.PQQ_ACCESS_GRANTED]: {
      navigateOptions: ({ to, from }) => ({
        to: companyProfileRoute.to,
        params: { currentCompanyId: to.companyId, targetCompanyId: from.companyId },
        search: { show: 'pre-qualification' },
      }),
    },
  },
  [NotificationDomain.CONTRACT_RECEIVED]: {
    [NotificationAction.CONTRACT_SENT]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractLiveIndexRoute.to,
        params: { currentCompanyId: to.companyId, contractId: meta.contractId },
      }),
    },
    [NotificationAction.CONTRACT_TERMINATED]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractLiveIndexRoute.to,
        params: { currentCompanyId: to.companyId, contractId: meta.contractId },
      }),
    },
    [NotificationAction.CONTRACT_FAILED]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractLiveIndexRoute.to,
        params: { currentCompanyId: to.companyId, contractId: meta.contractId },
      }),
    },
    [NotificationAction.CONTRACT_REVISED]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractLiveDetailsIndexRoute.to,
        params: { currentCompanyId: to.companyId, contractId: meta.contractId },
      }),
    },
    [NotificationAction.CONTRACT_AMENDED]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractLiveContractRoute.to,
        params: { currentCompanyId: to.companyId, contractId: meta.contractId },
      }),
    },
    [NotificationAction.CONTRACT_EXCHANGE_REPLY_SENT]: {
      navigateOptions: ({ to, meta }) => {
        if (meta.exchangeType === ExchangeType.CONTRACT) {
          return {
            to: contractLiveContractRoute.to,
            params: { currentCompanyId: to.companyId, contractId: meta.contractId },
            search: { exchangeId: meta.exchangeId },
          };
        } else {
          return {
            to: contractLiveDetailsPageRoute.to,
            params: { currentCompanyId: to.companyId, contractId: meta.contractId, pageId: meta.exchangePageId },
            search: { exchangeId: meta.exchangeId },
          };
        }
      },
    },
    [NotificationAction.CONTRACT_TEAM_MEMBER_ADDED]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractLiveSummaryRoute.to,
        params: { currentCompanyId: to.companyId, contractId: meta.contractId },
      }),
    },
    [NotificationAction.CONTRACT_TEAM_MEMBER_REMOVED]: {
      navigateOptions: ({ to }) => ({
        to: contractsRoute.to,
        params: { currentCompanyId: to.companyId },
      }),
    },
    [NotificationAction.CONTRACT_TEAM_MEMBER_ROLES_UPDATED]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractLiveTeamRoute.to,
        params: { currentCompanyId: to.companyId, contractId: meta.contractId },
      }),
    },
    [NotificationAction.CONTRACT_E_SIGNATURE_REQUIRED]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractLiveContractRoute.to,
        params: { currentCompanyId: to.companyId, contractId: meta.contractId },
        search: { exchangeId: meta.exchangeId },
      }),
    },
    [NotificationAction.CONTRACT_E_SIGNATURE_COMPLETE]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractLiveSummaryRoute.to,
        params: { currentCompanyId: to.companyId, contractId: meta.contractId },
      }),
    },
    [NotificationAction.CONTRACT_E_SIGNATURE_REMINDER]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractLiveContractRoute.to,
        params: { currentCompanyId: to.companyId, contractId: meta.contractId },
        search: { exchangeId: meta.exchangeId },
      }),
    },
    [NotificationAction.CONTRACT_E_SIGNATURE_REJECTED]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractLiveContractRoute.to,
        params: { currentCompanyId: to.companyId, contractId: meta.contractId },
        search: { exchangeId: meta.exchangeId },
      }),
    },
  },
  [NotificationDomain.CONTRACT_SENT]: {
    [NotificationAction.CONTRACT_SENT]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractLiveIndexRoute.to,
        params: { currentCompanyId: to.companyId, contractId: meta.contractId },
      }),
    },
    [NotificationAction.CONTRACT_TERMINATED]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractLiveIndexRoute.to,
        params: { currentCompanyId: to.companyId, contractId: meta.contractId },
      }),
    },
    [NotificationAction.CONTRACT_FAILED]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractLiveIndexRoute.to,
        params: { currentCompanyId: to.companyId, contractId: meta.contractId },
      }),
    },
    [NotificationAction.CONTRACT_REVISED]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractLiveDetailsIndexRoute.to,
        params: { currentCompanyId: to.companyId, contractId: meta.contractId },
      }),
    },
    [NotificationAction.CONTRACT_AMENDED]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractLiveContractRoute.to,
        params: { currentCompanyId: to.companyId, contractId: meta.contractId },
      }),
    },
    [NotificationAction.CONTRACT_EXCHANGE_REPLY_SENT]: {
      navigateOptions: ({ to, meta }) => {
        if (meta.exchangeType === ExchangeType.CONTRACT) {
          return {
            to: contractLiveContractRoute.to,
            params: { currentCompanyId: to.companyId, contractId: meta.contractId },
            search: { exchangeId: meta.exchangeId },
          };
        } else {
          return {
            to: contractLiveDetailsPageRoute.to,
            params: { currentCompanyId: to.companyId, contractId: meta.contractId, pageId: meta.exchangePageId },
            search: meta.exchangeType === ExchangeType.APPROVAL
              ? undefined
              : { exchangeId: meta.exchangeId },
          };
        }
      },
    },
    [NotificationAction.CONTRACT_PAGE_APPROVALS]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractLiveDetailsPageRoute.to,
        params: { currentCompanyId: to.companyId, contractId: meta.contractId, pageId: meta.exchangePageId },
      }),
    },
    [NotificationAction.CONTRACT_TEAM_MEMBER_ADDED]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractLiveSummaryRoute.to,
        params: { currentCompanyId: to.companyId, contractId: meta.contractId },
      }),
    },
    [NotificationAction.CONTRACT_TEAM_MEMBER_REMOVED]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractsRoute,
        params: { currentCompanyId: to.companyId },
      }),
    },
    [NotificationAction.CONTRACT_TEAM_MEMBER_ROLES_UPDATED]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractLiveTeamRoute.to,
        params: { currentCompanyId: to.companyId, contractId: meta.contractId },
      }),
    },
    [NotificationAction.CONTRACT_E_SIGNATURE_APPROVAL_REQUIRED]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractLiveContractRoute.to,
        params: { currentCompanyId: to.companyId, contractId: meta.contractId },
        search: { exchangeId: meta.exchangeId },
      }),
    },
    [NotificationAction.CONTRACT_E_SIGNATURE_REQUIRED]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractLiveContractRoute.to,
        params: { currentCompanyId: to.companyId, contractId: meta.contractId },
        search: { exchangeId: meta.exchangeId },
      }),
    },
    [NotificationAction.CONTRACT_E_SIGNATURE_COMPLETE]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractLiveSummaryRoute.to,
        params: { currentCompanyId: to.companyId, contractId: meta.contractId },
      }),
    },
    [NotificationAction.CONTRACT_E_SIGNATURE_REMINDER]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractLiveContractRoute.to,
        params: { currentCompanyId: to.companyId, contractId: meta.contractId },
        search: { exchangeId: meta.exchangeId },
      }),
    },
    [NotificationAction.CONTRACT_E_SIGNATURE_REJECTED]: {
      navigateOptions: ({ to, meta }) => ({
        to: contractLiveContractRoute.to,
        params: { currentCompanyId: to.companyId, contractId: meta.contractId },
        search: { exchangeId: meta.exchangeId },
      }),
    },
  },
  [NotificationDomain.QUESTIONNAIRE_RECEIVED]: {
    [NotificationAction.QUESTIONNAIRE_RECEIVED]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_APPROVED]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_REJECTED]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_MARKED_AS_OBSOLETE]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_REOPENED]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_RESUBMISSION_REQUESTED]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_TEAM_MEMBER_ADDED]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_TEAM_MEMBER_ROLES_UPDATED]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireTeamRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_TEAM_MEMBER_REMOVED]: {
      navigateOptions: ({ to }) => ({
        to: preQualificationQuestionnairesRoute.to,
        params: { currentCompanyId: to.companyId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_DOCUMENT_EXPIRED]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_UPCOMING_DOCUMENT_EXPIRY]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_REVISED]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_NAME_UPDATED]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireDetailsRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_RENEWAL_REQUIRED]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_UPCOMING_RENEWAL]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_MORE_INFO_REQUIRED]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
        search: { exchangeId: meta.exchangeId },
      }),
    },
  },
  [NotificationDomain.QUESTIONNAIRE_SENT]: {
    [NotificationAction.QUESTIONNAIRE_ACCEPTED]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_DECLINED]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_SUBMITTED]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_UPDATED]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_TEAM_MEMBER_ADDED]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_TEAM_MEMBER_ROLES_UPDATED]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireTeamRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_TEAM_MEMBER_REMOVED]: {
      navigateOptions: ({ to }) => ({
        to: preQualificationQuestionnairesRoute.to,
        params: { currentCompanyId: to.companyId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_DOCUMENT_EXPIRED]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_EXPIRED]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_UPCOMING_DOCUMENT_EXPIRY]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
    [NotificationAction.QUESTIONNAIRES_REVISION_COMPLETED]: {
      navigateOptions: ({ to, meta }) => {
        // Old versions of this type of notification do not contain a questionnaireTemplateId, therefore we must handle both cases
        if (meta?.questionnaireTemplateId) {
          return {
            to: questionnaireTemplateActiveSuppliersRoute.to,
            params: { currentCompanyId: to.companyId, templateId: meta.questionnaireTemplateId },
          };
        } else {
          return {
            to: preQualificationQuestionnairesRoute.to,
            params: { currentCompanyId: to.companyId },
            search: { tab: 'active' },
          };
        }
      },
    },
    [NotificationAction.QUESTIONNAIRES_SEND_COMPLETED]: {
      navigateOptions: ({ to }) => ({
        to: preQualificationQuestionnairesRoute.to,
        params: { currentCompanyId: to.companyId },
        search: { tab: 'active' },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_RENEWAL_REQUIRED]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
    [NotificationAction.QUESTIONNAIRE_UPCOMING_RENEWAL]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireQuestionsRoute.to,
        params: { currentCompanyId: to.companyId, questionnaireId: meta.questionnaireId },
      }),
    },
  },
  [NotificationDomain.USER_REPORT]: {
    [NotificationAction.USER_REPORT_COMPLETED]: {
      navigateOptions: null,
    },
    [NotificationAction.USER_REPORT_FAILED]: {
      navigateOptions: ({ to, meta }) => ({
        to: questionnaireTemplateActiveSuppliersRoute.to,
        params: { currentCompanyId: to.companyId, templateId: meta.questionnaireTemplateId },
      }),
    },
  },
};

const getNotificationAction = (notification: Notification, api: ApiClient) => {
  const notificationActionTable = {
    [NotificationDomain.USER_REPORT]: {
      [NotificationAction.USER_REPORT_COMPLETED]: async () => {
        const { to, meta } = notification;
        const { companyId } = to;
        const { reportId } = meta;

        const { url } = await api.getUserReportDownloadUrl({ companyId, reportId });

        downloadUrl(url, '');
      },
    },
  };

  return notificationActionTable[notification.domain]?.[notification.action];
};

const getNotificationData = (notification: Notification, t: TFunction, companyFeatureFlags: CompanyFeatureFlags, api: ApiClient) => {
  const { domain, action } = notification;
  const notificationData = notificationNavigateOptionsTable[domain]?.[action];
  const notificationAction = getNotificationAction(notification, api);

  if (notificationData) {
    try {
      return {
        message: getNotificationMessage(notification, t),
        navigateOptions: notificationData.navigateOptions?.(notification, companyFeatureFlags),
        notificationAction,
      };
    } catch (error) {
      Sentry.captureException(error);
    }
  } else {
    Sentry.captureException(new RenderNotificationError(`Could not find navigate options for message ${domain}|${action}`, { domain, action }));

    return {
      message: `${t('error.errorDisplayingNotification')}. (${domain}|${action})`,
      navigateOptions: null,
      notificationAction: null,
    };
  }
};

export const useGetNotificationData = () => {
  const { t } = useTranslation(['notifications', 'translation', 'contracts']);
  const companyFeatureFlags = useCompanyFeatureFlags();
  const api = useApi();

  return useCallback((notification) => {
    return getNotificationData(notification, t, companyFeatureFlags, api);
  }, [t, companyFeatureFlags, api]);
};
