import * as React from 'react';
import * as Sentry from '@sentry/react';
import { QuestionExchangeDefinition, QuestionType } from '@deepstream/common/rfq-utils';

import { UnexpectedStateError } from '@deepstream/errors';
import { ShortText } from './ShortText';
import { LongText } from './LongText';
import { AddressLocation } from './AddressLocation';
import { Options } from './Options';
import { DateTime } from './DateTime';
import { Document } from './Document';
import { Grid } from './Grid';
import { YesNo } from './YesNo';
import { Price } from './Price';

const QUESTION_TYPE_MAP = {
  [QuestionType.SHORT_TEXT]: ShortText,
  [QuestionType.LONG_TEXT]: LongText,
  [QuestionType.CHECKBOXES]: Options,
  [QuestionType.MULTIPLE_CHOICE]: Options,
  [QuestionType.ADDRESS]: AddressLocation,
  [QuestionType.DATE_TIME]: DateTime,
  [QuestionType.DOCUMENT]: Document,
  [QuestionType.GRID]: Grid,
  [QuestionType.YES_NO]: YesNo,
  [QuestionType.PRICE]: Price,
} as const;

export const QuestionPreview = ({
  exchangeDef,
  fieldMaxWidth,
}: {
  exchangeDef: QuestionExchangeDefinition;
  fieldMaxWidth?: React.CSSProperties['maxWidth'];
}) => {
  const { questionType } = exchangeDef;

  if (!QUESTION_TYPE_MAP[questionType]) {
    Sentry.captureException(new UnexpectedStateError(`[QuestionPreview] No question preview available for question type ${questionType}`, { questionType }));
    return null;
  }

  const Component = QUESTION_TYPE_MAP[questionType];

  return <Component exchangeDef={exchangeDef} fieldMaxWidth={fieldMaxWidth} />;
};
