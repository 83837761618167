import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Text } from 'rebass/styled-components';
import { QuestionnaireStatus } from '@deepstream/common/preQual';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { ConfirmActionDialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { Bold } from '../../../Bold';
import { useConfirmDialog, useModalState } from '../../../ui/useModalState';
import { useIsSender, useQuestionnaireData, useQuestionnairePermissions } from './questionnaireUtils';
import { useApproveQuestionnaire } from './questionnaireMutations';
import { RejectQuestionnaireModal } from './RejectQuestionnaireModal';

export const useShowDecisionPanel = () => {
  const questionnaire = useQuestionnaireData();
  const isSender = useIsSender();
  const questionnairePermissions = useQuestionnairePermissions();

  return (
    questionnaire.status === QuestionnaireStatus.PENDING_REVIEW &&
    isSender &&
    questionnairePermissions.canSubmitDecision
  );
};

export const DecisionPanel = () => {
  const { t } = useTranslation(['preQualification', 'general']);
  const { confirm: confirmApprove, ...confirmApproveDialogProps } = useConfirmDialog();
  const rejectQuestionnaireModal = useModalState();

  const [approveQuestionnaire] = useApproveQuestionnaire();

  const onApprove = useCallback(
    () => confirmApprove(approveQuestionnaire),
    [approveQuestionnaire, confirmApprove],
  );

  const onReject = useCallback(
    () => rejectQuestionnaireModal.open(),
    [rejectQuestionnaireModal],
  );

  return (
    <>
      <Panel
        padded
        sx={{
          position: 'sticky',
          top: '180px',
          zIndex: 1,
        }}
      >
        <Flex alignItems="center">
          <Box flex="1 1 auto">
            <Text fontSize={4} lineHeight="normal" mb={1}>
              <Bold>
                {t('questionnaire.decisionPanel.header')}
              </Bold>
            </Text>
            <Text color="subtext">
              {t('questionnaire.decisionPanel.description')}
            </Text>
          </Box>
          <Flex flex="0 0 auto" sx={{ gap: 2 }}>
            <Button variant="danger" onClick={onReject}>
              {t('questionnaire.decisionPanel.reject')}
            </Button>
            <Button variant="success" onClick={onApprove}>
              {t('questionnaire.decisionPanel.approve')}
            </Button>
          </Flex>
        </Flex>
      </Panel>
      <ConfirmActionDialog
        heading={t('confirmation', { ns: 'general' })}
        variant="info"
        message={t('questionnaire.decisionPanel.confirmApproveMessage')}
        okButtonText={t('questionnaire.decisionPanel.approve')}
        okButtonVariant="success"
        {...confirmApproveDialogProps}
      />
      {rejectQuestionnaireModal.isOpen && (
        <RejectQuestionnaireModal
          close={rejectQuestionnaireModal.close}
        />
      )}
    </>
  );
};
