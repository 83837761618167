import { useTranslation } from 'react-i18next';
import { mapValues, noop } from 'lodash';
import { ActionType } from '@deepstream/common/rfq-utils';
import { Text, Box, Flex } from 'rebass/styled-components';
import * as yup from 'yup';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Modal, ModalHeader, ModalBody } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useRfqId } from './useRfq';
import { ModalForm } from './ModalForm';
import { SendExchangeReply, useSendExchangeReply } from './ExchangeModal/useSendExchangeReply';
import { useCurrentCompanyId } from './currentCompanyId';
import { useCurrentUser } from './useCurrentUser';
import { Label } from './ui/Label';
import { MoneyField } from './form/MoneyField';
import { CurrencyAmount } from './ui/Currency';
import { Lock, and, isRole, isNotRole } from './lock';
import { ReenableResponsesDropdown, useShouldShowReenableResponses } from './ExchangeModal/ReenableResponsesDropdown';

type EditFeeBidModalProps = {
  sectionId: string;
  vesselId: string;
  feeExchange: any;
  currencyCode: string;
  isOpen: boolean;
  onCancel: any;
  onError?: any;
  onSuccess?: any;
};

export const EditFeeBidModal = ({
  vesselId,
  feeExchange,
  isOpen,
  onCancel,
  onSuccess = noop,
  onError = noop,
}: EditFeeBidModalProps) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();
  const latestPrice = feeExchange.latestPriceByVesselId[vesselId];

  const [sendExchangeReply] = useSendExchangeReply({
    rfqId: useRfqId(),
    exchangeId: feeExchange._id,
    recipientId: feeExchange.recipientId,
  });

  return (
    <ModalForm
      heading={t('request.vesselPricing.fees.editFee')}
      initialValues={{
        price: latestPrice,
      }}
      validationSchema={
        yup.object().shape({
          price: yup.number().nullable(true).default(null),
        })
      }
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={async ({ price }, { setSubmitting }) => {
        await sendExchangeReply({
          companyId: currentCompanyId,
          user: {
            _id: user._id,
            name: user.name,
          },
          date: Date.now() as any,
          value: ActionType.SUBMIT,
          vessels: {
            ...mapValues(feeExchange.latestPriceByVesselId, rate => ({ rate })),
            [vesselId]: { rate: price },
          },
        }, {
          onSuccess,
          onError,
          onSettled: () => setSubmitting(false),
        });
      }}
      submitLabel={t('general.save')}
      style={{ content: { width: '500px' } }}
    >
      <Box>
        <Label label={t('general.description')} color="lightNavy" />
        <Text>{feeExchange.def.description}</Text>
      </Box>
      <Box>
        <Label label={t('general.type')} color="lightNavy" />
        <Text>{t(`request.vesselPricing.fees.feeType.${feeExchange.def.feeType}`)}</Text>
      </Box>
      <MoneyField
        symbol
        required
        name="price"
        label={t('general.price')}
        sx={{ width: 200 }}
      />
    </ModalForm>
  );
};

export const ViewFeeModal = ({
  vesselId,
  feeExchange,
  isOpen,
  onClose = noop,
  isRecipient,
}: {
  vesselId: string;
  feeExchange: any;
  isOpen: boolean;
  onClose: any;
  isRecipient: boolean;
}) => {
  const { t } = useTranslation();
  const { feeType, description } = feeExchange.def;
  const latestPrice = feeExchange.latestPriceByVesselId[vesselId];
  const rfqId = useRfqId();

  const shouldShowReenableButton = useShouldShowReenableResponses(feeExchange.disabledReason);

  const sendExchangeReplyMutation = useSendExchangeReply({
    rfqId,
    exchangeId: feeExchange._id,
    recipientId: feeExchange.recipientId,
  });

  return (
    <Modal
      shouldCloseOnOverlayClick
      isOpen={isOpen}
      style={{ content: { width: '500px' } }}
    >
      <ModalHeader onClose={onClose}>
        {t('request.fee')}
      </ModalHeader>
      <ModalBody>
        <Stack gap={3}>
          <Box>
            <Label label={t('general.description')} color="lightNavy" />
            <Lock
              exchange={feeExchange}
              value={feeType}
              getIsLockableValue={and(isRole('evaluator'), isNotRole('initiator'))}
            >
              <Text>{description}</Text>
            </Lock>
          </Box>
          <Box>
            <Label label={t('general.type')} color="lightNavy" />
            <Lock
              exchange={feeExchange}
              value={feeType}
              getIsLockableValue={and(isRole('evaluator'), isNotRole('initiator'))}
            >
              <Text>{t(`request.vesselPricing.fees.feeType.${feeType}`)}</Text>
            </Lock>
          </Box>
          <Box>
            <Label label={t('general.price')} color="lightNavy" />
            <Lock exchange={feeExchange} value={latestPrice} getIsLockableValue={isRole('evaluator')}>
              <CurrencyAmount value={latestPrice} />
            </Lock>
          </Box>
          {!isRecipient && shouldShowReenableButton ? (
            <SendExchangeReply.Provider value={sendExchangeReplyMutation}>
              <MessageBlock variant="info" mt={0}>
                {t('request.vesselPricing.suppliersCannotRespond')}
              </MessageBlock>
              <Flex justifyContent="flex-end">
                <ReenableResponsesDropdown />
              </Flex>
            </SendExchangeReply.Provider>
          ) : (
            null
          )}
        </Stack>
      </ModalBody>
    </Modal>
  );
};
