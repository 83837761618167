import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useField } from 'formik';
import { cloneDeep, filter, find, findIndex, forEach, keyBy, map, mapValues, reduce, uniq } from 'lodash';
import { Pictogram } from '@deepstream/ui-kit';
import { Panel, PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { Bold } from '../../../../Bold';
import { SupplierSearchBox } from '../../../RequestSuppliers/SupplierSearchBox';
import { SupplierState } from '../../../RequestSuppliers/types';
import { useFetchSupplierCompany } from '../../../RequestSuppliers/SuppliersContext';
import { useCurrentCompanyId } from '../../../../currentCompanyId';
import { SuppliersTable } from '../../../RequestSuppliers/SuppliersPanel';
import { InviteModal, SendInviteButton } from '../../../../InviteModal';
import { useModalState } from '../../../../ui/useModalState';

export const SuppliersPanel = () => {
  const { t } = useTranslation(['preQualification', 'translation']);
  const [{ value: suppliers },, suppliersHelper] = useField<SupplierState[]>('suppliers');
  const [{ value: templates }] = useField('templates');
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const fetchSupplierCompany = useFetchSupplierCompany();
  const inviteModalState = useModalState();

  const senderIds = useMemo(
    () => [currentCompanyId],
    [currentCompanyId],
  );

  const supplierIds = useMemo(
    () => map(suppliers, supplier => supplier.company._id),
    [suppliers],
  );

  const selectedUserIdsBySupplierId = useMemo(
    () => mapValues(
      keyBy(suppliers, (supplier) => supplier.company._id),
      (supplier) => {
        const selectedUsers = filter(supplier.users, (user) => user.selected);

        // @ts-expect-error ts(2339) FIXME: Property '_id' does not exist on type 'number | SupplierStateUser | (() => IterableIterator<SupplierStateUser>) | { [x: number]: boolean | undefined; length?: boolean | undefined; ... 33 more ...; readonly [Symbol.unscopables]?: boolean | undefined; } | ... 31 more ... | ((index: number) => SupplierStateUser | undefined)'.
        return map(selectedUsers, (user) => user._id);
      },
    ),
    [suppliers],
  );

  const disabledSupplierIds = useMemo(
    () => {
      return uniq(reduce(
        map(templates, (template) => template.supplierIds),
        // @ts-expect-error ts(2769) FIXME: No overload matches this call.
        (acc, supplierIds) => [...acc, ...supplierIds],
        [],
      ));
    },
    [templates],
  );

  const updateUsersSelection = useCallback(
    async (supplierId: string, users: Record<string, boolean>) => {
      suppliersHelper.setValue(map(
        suppliers,
        supplier => {
          if (supplier.company._id === supplierId) {
            return {
              ...supplier,
              users: map(
                supplier.users,
                user => ({
                  ...user,
                  // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
                  selected: users[user._id],
                }),
              ),
            };
          }

          return supplier;
        },
      ));
    },
    [suppliers, suppliersHelper],
  );

  const addSupplier = useCallback(
    async (supplierId: string) => {
      const { company, users: companyUsers } = await fetchSupplierCompany({ companyId: supplierId, role: 'receiveQuestionnaires' });
      const users = map(companyUsers, (user) => ({ ...user, selected: true }));

      suppliersHelper.setValue([...suppliers, { company, users }]);
    },
    [fetchSupplierCompany, suppliers, suppliersHelper],
  );

  const addSupplierByUser = useCallback(
    async (userId: string, supplierId: string) => {
      const { company, users: companyUsers } = await fetchSupplierCompany({ companyId: supplierId, role: 'receiveQuestionnaires' });
      const users = map(companyUsers, (user) => ({ ...user, selected: user._id === userId }));

      const supplier = find(
        suppliers,
        supplier => supplier.company._id === supplierId,
      );

      if (!supplier) {
        suppliersHelper.setValue([...suppliers, { company, users }]);
      } else {
        // If the supplier is already added but the user is not selected we select it
        const currentSelection = mapValues(
          keyBy(supplier.users, (user) => user._id),
          // @ts-expect-error ts(2339) FIXME: Property 'selected' does not exist on type 'number | SupplierStateUser | (() => IterableIterator<SupplierStateUser>) | { [x: number]: boolean | undefined; length?: boolean | undefined; ... 33 more ...; readonly [Symbol.unscopables]?: boolean | undefined; } | ... 31 more ... | ((index: number) => SupplierStateUser | undefined)'.
          (user) => user.selected,
        );
        updateUsersSelection(supplierId, { ...currentSelection, [userId]: true });
      }
    },
    [fetchSupplierCompany, suppliers, suppliersHelper, updateUsersSelection],
  );

  const removeSupplier = useCallback(
    async (supplierId: string) => {
      suppliersHelper.setValue(filter(
        suppliers,
        (supplier) => supplier.company._id !== supplierId),
      );
    },
    [suppliers, suppliersHelper],
  );

  const addInvitations = useCallback(
    (invites) => {
      const updatedSuppliers = cloneDeep(suppliers);
      forEach(invites, invite => {
        const users = map(invite.users, (user) => ({
          ...user,
          selected: true,
          isPending: true,
        }));

        const supplierIndex = findIndex(
          suppliers,
          (supplier) => supplier.company._id === invite.company._id,
        );

        if (supplierIndex !== -1) {
          updatedSuppliers[supplierIndex].users.push(...users);
        } else {
          updatedSuppliers.push({ company: invite.company, users });
        }
      });
      suppliersHelper.setValue(updatedSuppliers);
    },
    [suppliers, suppliersHelper]);

  return (
    <Panel>
      <Flex p="12px 20px" sx={{ gap: 3 }}>
        <Box flex="1 1 auto">
          <Bold lineHeight="normal" mb={1}>{t('supplier_other')}</Bold>
          <Text color="subtext">
            {t('questionnaire.suppliersDescription')}
          </Text>
        </Box>
        <Box flex="1 1 auto">
          <SupplierSearchBox
            canSearchByEmail
            canSendInvites
            requiredRole="receiveQuestionnaires"
            companyIdsToExclude={senderIds}
            companyIdsAlreadySelected={supplierIds}
            companyIdsDisabled={disabledSupplierIds}
            selectedUserIdsByCompanyId={selectedUserIdsBySupplierId}
            placeholder={t('questionnaire.search')}
            onInviteClick={() => inviteModalState.open()}
            onResultClick={({ type, value }) => {
              if (type === 'user') {
                addSupplierByUser(value._id, value.companyId);
              } else {
                addSupplier(value._id);
              }
            }}
            disabledResultTooltip={t('questionnaire.selectionDisabledTooltip')}
          />
          <InviteModal
            close={inviteModalState.close}
            customizedMessage={t('invite.customizedMessages.forQuestionnaire', { ns: 'translation' })}
            isOpen={inviteModalState.isOpen}
            onInvitesSent={addInvitations}
            isQuestionnaire
          />
        </Box>
        <Box>
          <SendInviteButton
            isQuestionnaire
            onInvitesSent={addInvitations}
          />
        </Box>
      </Flex>
      <PanelDivider />
      {suppliers.length > 0 ? (
        <SuppliersTable
          suppliers={suppliers}
          removeSupplier={removeSupplier}
          updateUsersSelection={updateUsersSelection}
          isQuestionnaire
          canSendInvites
          onInvitesSent={addInvitations}
        />
      ) : (
        <Box p={4}>
          <Pictogram variant="empty-state" label={t('questionnaire.emptyState.noSuppliers')} />
        </Box>
      )}
    </Panel>
  );
};
