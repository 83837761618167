import * as React from 'react';
import styled from 'styled-components';
import { display } from 'styled-system';
import hoistStatics from 'hoist-non-react-statics';
import { Box } from 'rebass/styled-components';

const mapProps = <T extends (props: any) => any> (map: T) => (Component: React.ComponentType) =>
  hoistStatics(props => <Component {...map(props)} />, Component);

const Base = styled(Box)(display);

interface ShowProps {
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  xlarge?: boolean;
  display?: string;
}

export const Show = mapProps(
  ({ small, medium, large, xlarge, display, ...props }: ShowProps) => ({
    display: display || [small, medium, large, xlarge].map(n => n ? 'block' : 'none'),
    ...props,
  }),
)(Base);
