import { useMemo, useCallback, useState } from 'react';
import { flatMap, reject } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Heading } from 'rebass/styled-components';
import { isEvaluationPage, PageType, SectionType, RfxSection } from '@deepstream/common/rfq-utils';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { PanelHeader, Panel } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { ConfirmDeleteDialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { DropdownMenu, DropdownMenuItem, DuplicateMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { useIntercom } from 'react-use-intercom';
import { Disclosure } from '../ui/Disclosure';
import { ErrorPanel } from '../ui/ErrorMessage';
import * as draft from './draft';
import * as rfx from '../rfx';
import { LabeledNumber } from './LabeledValue';
import { EvaluationSectionPanel } from './EvaluationSectionPanel';
import { LineItemsSectionPanel } from './LineItemsSectionPanel';
import { DocumentsSectionPanel } from './DocumentsSectionPanel';
import { QuestionSectionPanel } from './QuestionSectionPanel';
import { Bold } from '../Bold';
import { AddSectionDropdown } from './AddSectionDropdown';
import { VesselPricingSectionPanel } from './VesselPricingSectionPanel';
import { ReorderPagesModal } from './ReorderPagesModal';
import { useConfirmDialog, useModalState } from '../ui/useModalState';
import { ModalForm } from '../ModalForm';
import { TextField } from '../form/TextField';
import { AddPageModal } from './AddPageModal';
import { HiddenRequestPagePlaceholder } from '../HiddenRequestPagePlaceholder';
import { getPageExchangeDefs, getSizeRelevantExchangeDefCount, useModelSizeLimits } from '../modelSizeLimits';
import { ModelSizeLimitDialog, ModelSizeLimitMessages } from '../ModelSizeLimitDialog';
import { useRequestEditNavigation } from '../appNavigation';

const EvaluationSectionDisclosure = () => {
  const { t } = useTranslation();

  return (
    <Disclosure
      summary={t('request.evaluation.sections.disclosure.summary')}
    >
      {t('request.evaluation.sections.disclosure.content')}
    </Disclosure>
  );
};

const GeneralSectionDisclosure = () => {
  const { t } = useTranslation();

  return (
    <Disclosure
      summary={t('request.sections.disclosure.summary')}
    >
      {t('request.sections.disclosure.content')}
    </Disclosure>
  );
};

/**
 * High-level stats for the current evaluation page
 */
const EvaluationSummaryPanel = () => {
  const { t } = useTranslation();
  const sections = rfx.useSections();

  const numSections = sections.length;
  const numCriteria = reject(flatMap(sections, 'docXDefs'), 'isObsolete').length;

  return (
    <Panel>
      <PanelHeader heading={t('request.evaluation.evaluation')} collapse={false}>
        <Flex>
          <LabeledNumber label={t('general.section_other')} number={numSections} mr={3} />
          <LabeledNumber label={t('general.criterion_other')} number={numCriteria} />
        </Flex>
      </PanelHeader>
    </Panel>
  );
};

export const AddSectionButton = ({ disabled, ...props }: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button mt={2} iconLeft="plus" width="fit-content" disabled={disabled} {...props}>
      {t('request.addSection')}
    </Button>
  );
};

export const SectionsPlaceholder = () => {
  const { t } = useTranslation();

  return (
    <Bold fontSize={4}>
      {t('request.noSectionsAdded')}
    </Bold>
  );
};

/**
 * High-level structure for the current evaluation page
 */
const DraftEvaluationPage = () => {
  const { startEditing } = rfx.useActions();
  const { editingPanelId } = rfx.useState();
  const sections = rfx.useSections() as RfxSection[];
  const pagePermissions = rfx.usePagePermissions();
  const isEditingAnyPanel = Boolean(editingPanelId);
  const [addEvaluationSection, { isLoading }] = draft.useAddEvaluationSection();

  return (
    <Stack gap={20}>
      {sections.length ? (
        <>
          <EvaluationSummaryPanel />
          {sections.map((section: any) => (
            <rfx.SectionProvider key={section._id} section={section}>
              <rfx.EvaluationWeightsProvider>
                <EvaluationSectionPanel />
              </rfx.EvaluationWeightsProvider>
            </rfx.SectionProvider>
          ))}
        </>
      ) : (
        <SectionsPlaceholder />
      )}
      {pagePermissions.canEdit ? (
        <AddSectionButton
          disabled={isLoading || isEditingAnyPanel}
          onClick={() => {
            const sectionId = uuid();

            addEvaluationSection({
              _id: sectionId,
              name: '',
              weight: 1,
            }, {
              onSuccess: () => startEditing(sectionId),
            });
          }}
        />
      ) : (
        null
      )}
      <EvaluationSectionDisclosure />
    </Stack>
  );
};

/**
 * High-level structure for an "general" page
 */
const DraftGeneralPage = () => {
  const intercom = useIntercom();
  const { editingPanelId } = rfx.useState();
  const { startEditing } = rfx.useActions();
  const isEditingAnyPanel = Boolean(editingPanelId);
  const sections = rfx.useSections() as RfxSection[];
  const pagePermissions = rfx.usePagePermissions();
  const [addLineItemsSection, { isLoading: addingLineItemsSection }] = draft.useAddLineItemsSection();
  const [addDocumentsSection, { isLoading: addingDocumentsSection }] = draft.useAddDocumentsSection();
  const [addVesselPricingSection, { isLoading: addingVesselPricingSection }] = draft.useAddVesselPricingSection();
  const [addQuestionSection, { isLoading: addingQuestionSection }] = draft.useAddQuestionSection();

  const isLoading = addingLineItemsSection || addingDocumentsSection || addingVesselPricingSection || addingQuestionSection;

  const mutationBySectionType = useMemo(
    () => ({
      [SectionType.LINE_ITEMS]: addLineItemsSection,
      [SectionType.DOCUMENT]: addDocumentsSection,
      [SectionType.VESSEL_PRICING]: addVesselPricingSection,
      [SectionType.QUESTION]: addQuestionSection,
    }),
    [addLineItemsSection, addDocumentsSection, addVesselPricingSection, addQuestionSection],
  );

  const addSection = useCallback((sectionType: SectionType) => {
    const sectionId = uuid();
    const addSectionMutation = mutationBySectionType[sectionType];

    if (!addSectionMutation) {
      throw new Error(`Unsupported section type: ${sectionType}`);
    }

    addSectionMutation({
      _id: sectionId,
    }, {
      onSuccess: () => {
        startEditing(sectionId);

        if (sectionType === SectionType.LINE_ITEMS) {
          intercom.trackEvent('edit-line-items-section-opened');
        } else if (sectionType === SectionType.EVALUATION) {
          intercom.trackEvent('edit-evaluation-section-opened');
        }
      },
    });
  }, [intercom, mutationBySectionType, startEditing]);

  return (
    <Stack gap={20}>
      {sections.length ? (
        sections.map(section => (
          <rfx.SectionProvider key={section._id} section={section}>
            {section.type === SectionType.LINE_ITEMS ? (
              <LineItemsSectionPanel />
            ) : section.type === SectionType.DOCUMENT ? (
              <DocumentsSectionPanel />
            ) : section.type === SectionType.VESSEL_PRICING ? (
              <VesselPricingSectionPanel />
            ) : section.type === SectionType.QUESTION ? (
              <QuestionSectionPanel />
            ) : (
              null
            )}
          </rfx.SectionProvider>
        ))
      ) : (
        <SectionsPlaceholder />
      )}
      {pagePermissions.canEdit ? (
        <Box mt={2}>
          <AddSectionDropdown
            disabled={isLoading || isEditingAnyPanel}
            onSelect={addSection}
          />
        </Box>
      ) : (
        null
      )}
      <GeneralSectionDisclosure />
    </Stack>
  );
};

const RenamePageModal = ({
  isOpen,
  onCancel,
  onSuccess,
}: {
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const page = rfx.usePage();
  const [renamePage] = draft.useRenamePage();

  return (
    <ModalForm
      heading={t('request.pages.renamePage')}
      initialValues={{
        // @ts-expect-error ts(18047) FIXME: 'page' is possibly 'null'.
        name: page.name,
      }}
      validationSchema={
        yup.object().shape({
          name: yup.string().trim().required(t('request.pages.errors.nameRequired')),
        })
      }
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={async ({ name }, { setSubmitting }) => {
        await renamePage({
          // @ts-expect-error ts(18047) FIXME: 'page' is possibly 'null'.
          _id: page._id,
          name: name.trim(),
        },
        {
          onSuccess,
          onError: () => setSubmitting(false),
        });
      }}
      submitLabel={t('general.save')}
      style={{ content: { width: '500px' } }}
    >
      <TextField
        required
        name="name"
        label={t('general.name')}
      />
    </ModalForm>
  );
};

const DraftPageHeader = () => {
  const { t } = useTranslation();
  const generalPages = draft.useGeneralDraftPages();
  const { isRevising, editingPanelId } = rfx.useState();
  const structure = rfx.useStructure();
  const { maxExchangeDefCount } = useModelSizeLimits();
  const reorderPagesModal = useModalState();
  const renamePageModal = useModalState();
  const duplicatePageModal = useModalState();
  const { confirm, ...dialogProps } = useConfirmDialog();
  const [removePage] = draft.useRemovePage();
  const page = rfx.usePage();
  const rfxPermissions = rfx.useRfxPermissions();
  const modelSizeLimitModal = useModalState();
  const [modelSizeLimitMessages, setModelSizeLimitMessages] = useState<ModelSizeLimitMessages | null>(null);
  const { getNextVisiblePage, getPreviousVisiblePage } = rfx.usePageNavigation();

  const navigation = useRequestEditNavigation();

  const isEditingAnyPanel = Boolean(editingPanelId);

  const handleDuplicateClick = () => {
    const totalExchangeDefCount = getSizeRelevantExchangeDefCount(structure.exchangeDefById);
    const pageExchangeDefCount = getSizeRelevantExchangeDefCount(
      // @ts-expect-error ts(2345) FIXME: Argument of type 'Page | null' is not assignable to parameter of type 'Page'.
      getPageExchangeDefs(structure.sectionById, structure.exchangeDefById, page),
    );

    if (totalExchangeDefCount + pageExchangeDefCount > maxExchangeDefCount) {
      setModelSizeLimitMessages({
        heading: t('request.dialog.requestSizeLimit.heading'),
        title: t('request.dialog.requestSizeLimit.duplicatePage.title'),
        warning: t('request.dialog.requestSizeLimit.duplicatePage.warning'),
        body: t('request.dialog.requestSizeLimit.duplicatePage.body', {
          count: totalExchangeDefCount + pageExchangeDefCount - maxExchangeDefCount,
        }),
      });
      modelSizeLimitModal.open();
    } else {
      duplicatePageModal.open();
    }
  };

  const onDeletePage = () => confirm(async () => {
    // @ts-expect-error ts(18047) FIXME: 'page' is possibly 'null'.
    const redirectPage = getPreviousVisiblePage(page._id) ?? getNextVisiblePage(page._id);

    // @ts-expect-error ts(18047) FIXME: 'page' is possibly 'null'.
    await removePage({ pageId: page._id });

    if (redirectPage) {
      navigation.navigateToDetailsPage(redirectPage._id);
    } else if (rfxPermissions.canEditPages) {
      navigation.navigateToDetailsIndex();
    } else {
      navigation.navigateToTeam();
    }
  });

  const onDuplicatePageSuccess = useCallback(
    (pageId: string) => {
      navigation.navigateToDetailsPage(pageId);
      duplicatePageModal.close();
    },
    [duplicatePageModal, navigation],
  );

  return (
    <>
      <Flex justifyContent="space-between" alignItems="flex-start" mb="20px" minHeight="40px">
        <Heading fontSize={6} fontWeight={500} mt="5px" mr={3} flex={1}>
          {/*
           // @ts-expect-error ts(18047) FIXME: 'page' is possibly 'null'. */}
          {page.name}
        </Heading>
        {rfxPermissions.canEditPages && (
          // @ts-expect-error ts(2345) FIXME: Argument of type 'Page | null' is not assignable to parameter of type '{ type?: PageType | undefined; }'.
          isEvaluationPage(page) ? (
            // @ts-expect-error ts(2339) FIXME: Property 'isLive' does not exist on type 'never'.
            page.isLive ? (
              null
            ) : (
              <Button
                variant="danger-outline"
                iconLeft="trash"
                onClick={onDeletePage}
                disabled={isEditingAnyPanel}
              >
                {t('request.pages.deletePage')}
              </Button>
            )
          ) : (
            <DropdownMenu
              variant="secondary-outline"
              buttonText={t('request.pages.pageActions')}
              rightAligned
              iconRight="caret-down"
              disabled={isEditingAnyPanel}
              data-test="page-actions-button"
              popoverProps={{
                'data-test': 'page-actions-menu',
              }}
            >
              <DropdownMenuItem
                icon="pencil"
                onSelect={renamePageModal.open}
              >
                {t('general.rename')}
              </DropdownMenuItem>
              {!isRevising && (
                <DuplicateMenuItem
                  onSelect={handleDuplicateClick}
                  data-test="duplicate-page-button"
                />
              )}
              {generalPages.length > 1 && (
                <DropdownMenuItem
                  icon="refresh"
                  onSelect={reorderPagesModal.open}
                >
                  {t('request.pages.reorderPages')}
                </DropdownMenuItem>
              )}
              {/*
               // @ts-expect-error ts(18047) FIXME: 'page' is possibly 'null'. */}
              {!page.isLive && (
                <DropdownMenuItem
                  icon="trash"
                  onSelect={onDeletePage}
                  color="danger"
                >
                  {t('general.delete')}
                </DropdownMenuItem>
              )}
            </DropdownMenu>
          )
        )}
      </Flex>
      <ReorderPagesModal
        isOpen={reorderPagesModal.isOpen}
        onCancel={reorderPagesModal.close}
        onSuccess={reorderPagesModal.close}
      />
      <RenamePageModal
        isOpen={renamePageModal.isOpen}
        onCancel={renamePageModal.close}
        onSuccess={renamePageModal.close}
      />
      <AddPageModal
        isOpen={duplicatePageModal.isOpen}
        // @ts-expect-error ts(18047) FIXME: 'page' is possibly 'null'.
        sourcePageId={page._id}
        onCancel={duplicatePageModal.close}
        onSuccess={onDuplicatePageSuccess}
      />
      <ConfirmDeleteDialog
        heading={t('request.pages.deletePage')}
        message={t('request.pages.deletePageWarning')}
        {...dialogProps}
      />
      <ModelSizeLimitDialog
        modal={modelSizeLimitModal}
        messages={modelSizeLimitMessages}
      />
    </>
  );
};

export const DraftPage = () => {
  const { t } = useTranslation();
  const page = rfx.usePage();
  const rfxStructure = rfx.useStructure();
  const pagePermissions = rfx.usePagePermissions();
  const { isRevising } = rfx.useState();

  const sections = useMemo(
    // @ts-expect-error ts(18047) FIXME: 'page' is possibly 'null'.
    () => page.sections.map(sectionId => rfxStructure.sectionById[sectionId]),
    [page, rfxStructure],
  );

  return (
    <Box py="20px">
      <DraftPageHeader />
      {pagePermissions.canRead ? (
        <rfx.SectionsProvider sections={sections}>
          {isRevising && (
            <MessageBlock variant="info" mb="20px">
              {t('request.revisionInfo')}
            </MessageBlock>
          )}
          {/*
           // @ts-expect-error ts(2345) FIXME: Argument of type 'Page | null' is not assignable to parameter of type '{ type?: PageType | undefined; }'. */}
          {isEvaluationPage(page) ? (
            <DraftEvaluationPage />
          // @ts-expect-error ts(18047) FIXME: 'page' is possibly 'null'.
          ) : page.type === PageType.CHAT ? (
            <ErrorPanel error={t('request.errors.chatPageNotEditable')} />
          ) : (
            <DraftGeneralPage />
          )}
        </rfx.SectionsProvider>
      ) : (
        <HiddenRequestPagePlaceholder />
      )}
    </Box>
  );
};
