import { useState, useCallback, useEffect } from 'react';
import {
  AuctionRules,
  AuctionStatus,
} from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';

import { usePopover, Popover } from '@deepstream/ui-kit/elements/popup/usePopover';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useRfqExchange, useRfqId } from '../../../useRfq';
import * as rfx from '../../../rfx';
import { FixedFooterButton } from '../../../FixedFooter';
import { AuctionOverview } from './AuctionOverviewContent';
import { useInvalidateQueryOnMessage } from '../../../useInvalidateQueryOnMessage';

export const AuctionOverviewButton = ({ auctionRules }: { auctionRules: AuctionRules }) => {
  const { t } = useTranslation();
  const auction = rfx.useAuction();

  const popover = usePopover({
    placement: 'top-start',
    alignToWindowEdge: 'left',
  });

  const [hasClosedOverview, setHasClosedOverview] = useState(false);
  const closePopover = useCallback(
    () => {
      popover.close();
      setHasClosedOverview(true);
    },
    [popover],
  );

  const currentCompanyId = useCurrentCompanyId({ required: true });
  const isRecipient = !rfx.useIsSender();
  const auctionTermsExchangeDef = rfx.useAuctionTermsExchangeDef();
  const rfqId = useRfqId({ required: true });

  const { data: exchange, isSuccess, queryKey } = useRfqExchange({
    // @ts-expect-error ts(18047) FIXME: 'auctionTermsExchangeDef' is possibly 'null'.
    exchangeId: auctionTermsExchangeDef._id,
    recipientId: currentCompanyId,
    enabled: isRecipient,
  });

  const canAcceptTerms = isRecipient && exchange && !exchange.isResolved &&
    ![AuctionStatus.CANCELLED, AuctionStatus.ENDED].includes(auction.status);

  // Open the popover if the exchange isn't resolved and they've never closed
  // the overview (ie: it's the first time they're seeing it)
  useEffect(() => {
    if (!hasClosedOverview && canAcceptTerms) {
      popover.open();
    }
  }, [popover, hasClosedOverview, canAcceptTerms]);

  useInvalidateQueryOnMessage(`rfx.${rfqId}`, queryKey);

  return (
    <>
      <FixedFooterButton
        // @ts-expect-error ts(2322) FIXME: Type 'Dispatch<SetStateAction<HTMLElement | undefined>>' is not assignable to type 'LegacyRef<HTMLButtonElement> | undefined'.
        ref={popover.setReferenceElement}
        icon="list-ul"
        iconRegular={false}
        onClick={popover.toggle}
        actionNeeded={isSuccess ? canAcceptTerms : false}
        sx={{ borderRight: 'lightGray' }}
      >
        {t('request.auction.footer.overview')}
      </FixedFooterButton>
      <Popover width="100%" maxWidth={376} height={700} onClickOutside={closePopover} {...popover}>
        <AuctionOverview
          onClose={closePopover}
          auctionRules={auctionRules}
        />
      </Popover>
    </>
  );
};
