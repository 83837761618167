import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  isFormulaField,
  FieldConfig,
  isDefinitionField,
  isReplyField,
  isBuyerReplyField,
} from '@deepstream/common/rfq-utils';
import { useColumnById } from './columns';
import { useGetFormattedFormula } from '../../ui/ExchangeDefsGrid/useFormattedTotalCostFormula';
import { useConfigurableExchangeFieldColumns } from '../../ui/ExchangeDefsGrid/useConfigurableFieldColumns';
import { useHooks } from '../../useHooks';
import { TargetPriceWithTotalCell } from '../../draft/cell';

const isNotTargetPriceField = (field: FieldConfig) => field._id !== 'targetPrice';

const widthMap = {
  unit: 150,
  quantity: 150,
  price: 170,
  targetPrice: 210,
  unspscCode: 170,
  leadTime: 170,
};

export const useLineItemExchangesGridColumns = ({
  isRecipient,
  isProvidedBySupplier,
  fields,
  orderedFieldIds,
  addLocks,
}: {
  isRecipient?: boolean;
  isProvidedBySupplier?: boolean;
  fields?: Record<string, FieldConfig>;
  orderedFieldIds?: string[];
  addLocks?: boolean;
}) => {
  const { t } = useTranslation(['translation', 'general']);
  const getFormattedFormula = useGetFormattedFormula(fields);
  const { usePagePermissions } = useHooks();
  // TODO check why there's this unused variable -- can remove or bug?
  const pagePermissions = usePagePermissions();

  const getColumnProps = useCallback((field) => {
    const fieldsetId = field._id.split(':')[0];
    const label = field.label || t(`request.fields.predefinedFieldLabel.${fieldsetId}`);
    const isFormula = isFormulaField(field);
    const formattedFormula = isFormula ? getFormattedFormula(field._id) : null;

    return {
      width: widthMap[fieldsetId] || 190,
      Header: label,
      description: isFormula ? (
        null
      ) : (!isProvidedBySupplier && isDefinitionField(field)) || isBuyerReplyField(field) ? (
        t('buyer', { ns: 'general' })
      ) : (
        t('supplier', { count: 1, ns: 'general' })
      ),
      headerInfoAccessor: formattedFormula
        ? () => formattedFormula
        : null,
      accessor: isDefinitionField(field)
        ? `def[${field._id}]`
        : isReplyField(field)
          ? `latestReply[${field._id}]`
          : isFormula
            ? `computedFormulas[${field._id}]`
            : '',
      ...(field._id === 'description' ? {
        showIconWhenObsolete: true,
      } : {}),
      ...(fieldsetId === 'targetPrice' ? {
        Cell: TargetPriceWithTotalCell,
        ValueCell: TargetPriceWithTotalCell,
      } : {}),
    };
  }, [getFormattedFormula, isProvidedBySupplier, t]);

  const column = useColumnById();

  const configurableColumns = useConfigurableExchangeFieldColumns({
    fields,
    orderedFieldIds,
    columnProps: getColumnProps,
    fieldsFilter: isRecipient ? isNotTargetPriceField : null,
    addLocks,
  });

  return useMemo(() => {
    const [descriptionColumn, ...otherColumns] = configurableColumns;

    return [
      column.gridRowNumberWithHoverState(),
      // The description is not guaranteed to always exist because the
      // fields provided by the caller can be undefined (which can be
      // the case, for example, when data is being loaded asynchronously).
      ...descriptionColumn ? [descriptionColumn] : [],
      column.exchangeStatus({ iconFontSize: 2, width: 165 }),
      ...otherColumns,
      column.commentsGrid,
    ];
  }, [column, configurableColumns]);
};
