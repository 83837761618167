import { ContractDraftHeader } from './ContractDraftHeader';
import * as Layout from '../../Layout';
import { ContractDraftReminders } from './ContractDraftReminders';
import { useContractId } from './contract';

export const ContractDraftRemindersPageContent = () => {
  const tabId = 'reminders';
  const contractId = useContractId();

  return (
    <Layout.HeaderAndContent
      header={
        <ContractDraftHeader
          key={contractId}
          contractId={contractId}
          selectedTabId={tabId}
        />
      }
      content={
        <ContractDraftReminders
          contractId={contractId}
        />
      }
    />
  );
};
