import { useQuery } from 'react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { differenceInHours } from 'date-fns';

import { TOKEN_VALID_SECONDS } from '@deepstream/common';

import { useApi, wrap } from './api';
import { useCurrentCompanyId } from './currentCompanyId';
import { IntegrationDataType } from './types';
import { useCurrentUser } from './useCurrentUser';

export const useDefaultExternalSystem = () => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const isAdmin = currentUser.roles[currentCompanyId].admin;

  const { data: externalSystems } = useQuery(
    ['externalSystems', { companyId: currentCompanyId, withCompaniesData: true }],
    wrap(api.getExternalSystems),
    {
      enabled: Boolean(isAdmin),
    },
  );

  return externalSystems && externalSystems[0];
};

export const useExternalSystemWithExternalStatus = ({ currentCompanyId }: { currentCompanyId?: string }) => {
  const api = useApi();
  const currentUser = useCurrentUser();
  const isAdmin = currentCompanyId && currentUser
    ? currentUser.roles[currentCompanyId].admin
    : false;

  const { data: externalSystems } = useQuery(
    ['externalSystems', { companyId: currentCompanyId, withCompaniesData: true }],
    wrap(api.getExternalSystems),
    {
      enabled: Boolean(currentCompanyId && isAdmin),
    },
  );

  return externalSystems?.find(externalSystem => externalSystem.externalData?.hasSupplierStatuses);
};

export const useExternalSystems = () => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const isAdmin = currentUser.roles[currentCompanyId].admin;

  return useQuery(
    ['externalSystems', { companyId: currentCompanyId, withCompaniesData: true }],
    wrap(api.getExternalSystems),
    {
      enabled: Boolean(isAdmin),
    },
  );
};

export const useMarketplaceIntegrations = () => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const isAdmin = currentUser.roles[currentCompanyId].admin;

  return useQuery(
    ['marketplaceIntegrations', { companyId: currentCompanyId }],
    wrap(api.getMarketplaceIntegrations),
    {
      enabled: Boolean(isAdmin),
    },
  );
};

export const useIntegrationData = ({
  currentCompanyId,
  systemId,
  enabled = true,
}: { currentCompanyId: string, systemId?: string, enabled?: boolean }) => {
  const api = useApi();

  return useQuery(
    ['integrationData', { companyId: currentCompanyId, type: IntegrationDataType.EXTERNAL_COMPANY_TO_INTERNAL_COMPANY, systemId }],
    wrap(api.getIntegrationData),
    {
      enabled: Boolean(currentCompanyId && systemId && enabled),
    },
  );
};

export const useUnmappedExternalSupplierIds = ({
  systemId,
  externalSupplierIds,
  enabled = true,
}: {
  systemId: string;
  externalSupplierIds: string[];
  enabled?: boolean;
}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  return useQuery(
    ['unmappedExternalSupplierIds', { companyId: currentCompanyId, externalSupplierIds: JSON.stringify(externalSupplierIds), systemId }],
    wrap(api.getUnmappedExternalSuppliers),
    {
      enabled: Boolean(systemId && enabled),
    },
  );
};

export const useExternalSystem = ({
  systemId,
}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  return useQuery(
    ['externalSystem', { companyId: currentCompanyId, systemId }],
    wrap(api.getExternalSystem),
    {
      enabled: Boolean(systemId),
    },
  );
};

type SupplierIntegrationData = {
  internalCompanyId: string;
  externalStatus: string;
};
export const useSupplierIntegrationMap = ({ currentCompanyId, systemId }) => {
  const currentUser = useCurrentUser();
  const isAdmin = currentCompanyId && currentUser
    ? currentUser.roles[currentCompanyId].admin
    : false;

  const { data: integrationData } = useIntegrationData({
    systemId,
    currentCompanyId,
    enabled: isAdmin,
  });

  return useMemo(() => integrationData && Object
    .values(integrationData.data as SupplierIntegrationData[])
    .reduce((acc, supplier) => {
      acc[supplier.internalCompanyId] = supplier.externalStatus;

      return acc;
    }, {}), [integrationData]);
};

export const useHoursPassed = ({ externalStatusesUpdatedAt }) => {
  const { t } = useTranslation('integration');
  const hourCount = differenceInHours(new Date(), new Date(externalStatusesUpdatedAt));

  return t('externalStatus.hourCount', { count: hourCount });
};

export const useMarketplaceToken = () => {
  const api = useApi();
  const user = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const isAdmin = user?.roles[currentCompanyId].admin;

  return useQuery(
    ['marketplaceToken', { companyId: currentCompanyId }],
    wrap(api.getMarketplaceToken),
    {
      enabled: Boolean(isAdmin),
      refetchInterval: (TOKEN_VALID_SECONDS - 30) * 1000,
    },
  );
};
