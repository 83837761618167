import { QuestionnaireStatus } from '@deepstream/common/preQual';
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { Bold } from '../../../Bold';
import { useIsSender, useQuestionnaireData } from './questionnaireUtils';

export const useShowDocumentUpdatingPanel = () => {
  const questionnaire = useQuestionnaireData();
  const isSender = useIsSender();

  return (
    isSender &&
    questionnaire.status === QuestionnaireStatus.IN_PROGRESS &&
    questionnaire.hadExpiredDocuments
  );
};

export const QuestionnaireDocumentUpdatingPanel = () => {
  const { t } = useTranslation('preQualification');
  const questionnaire = useQuestionnaireData();

  return (
    <Panel padded>
      <Text fontSize={4} lineHeight="normal" mb={1}>
        <Icon icon="info-circle" color="primary" mr={2} />
        <Bold>{t('questionnaire.documentUpdating.documentBeingUpdated')}</Bold>
      </Text>
      <Text color="subtext">
        {t(
          'questionnaire.documentUpdating.documentBeingUpdatedDescription',
          { recipientName: questionnaire.recipient.name },
        )}
      </Text>
    </Panel>
  );
};
