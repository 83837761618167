import * as draft from './draft';
import * as validation from './validation';
import { ErrorMessage } from '../ui/ErrorMessage';

export const ValidationErrorValue = ({
  value,
  fieldName,
  useShowValidationErrors = draft.useShowValidationErrors,
}) => {
  const showValidationErrors = useShowValidationErrors();
  const { error } = validation.useError(fieldName);

  return showValidationErrors && error ? (
    <ErrorMessage fontSize={2} error={error} />
  ) : (
    value
  );
};
