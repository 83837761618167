import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { Box } from 'rebass/styled-components';
import { ExtendedReceivedContractOverview, ReceivedContractOverviews } from '@deepstream/common/contract';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { withProps } from '@deepstream/ui-utils/withProps';
import { branch } from '@deepstream/ui-utils/branch';
import { LearnMoreButton } from '@deepstream/ui-kit/elements/button/Button';
import { useNavigate } from '@tanstack/react-router';
import { BasicTableStyles } from '../../TableStyles';
import { Table } from '../../Table';
import { FieldsCell, MIN_CELL_HEIGHT, NameArrayFieldValue } from '../../FieldsCell';
import { ContractStatusFieldValue, ContractStatusIcon } from './ContractStatus';
import { useTableData } from '../../TableDataContext';
import { ContractsTableData } from './ContractsTableDataProvider';
import { contractLiveSummaryRoute } from '../../AppRouting';
import { useCurrentCompanyId } from '../../currentCompanyId';

const ReceivedContractLearnMoreButton = ({
  row,
  openCannotAccessContractDialog,
}: CellProps<ExtendedReceivedContractOverview> & {
  openCannotAccessContractDialog: (contract: ExtendedReceivedContractOverview) => void;
}) => {
  const contract = row.original;

  return (
    <LearnMoreButton small onClick={() => openCannotAccessContractDialog(contract)} />
  );
};

const DisabledStatusIcon = () => (
  <Box alignSelf="flex-start" flex="0 0 auto" mr="8px" mt="2px">
    <Icon fixedWidth color="darkGrey" icon="user-times" />
  </Box>
);

export const ReceivedContractsTable = ({
  openCannotAccessContractDialog,
}: {
  openCannotAccessContractDialog?: (contract: ExtendedReceivedContractOverview) => void;
}) => {
  const { t } = useTranslation(['contracts', 'general', 'company', 'translation']);
  const { selectedItemsIds, selectItem, unselectItem } = useTableData<ContractsTableData<ReceivedContractOverviews>>({ required: true });
  const navigate = useNavigate();
  const currentCompanyId = useCurrentCompanyId();

  const handleContractRowClick = useCallback(
    (contract: ExtendedReceivedContractOverview) => {
      if (contract.hasAccess) {
        navigate({
          to: contractLiveSummaryRoute.to,
          // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
          params: { currentCompanyId, contractId: contract._id },
        });
      } else {
        // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
        openCannotAccessContractDialog(contract);
      }
    },
    [currentCompanyId, navigate, openCannotAccessContractDialog],
  );

  const columns = useMemo(
    () => [{ id: 'custom' }],
    [],
  );

  const CustomRowCells = useMemo(
    () => {
      const fields = [
        {
          label: t('status', { ns: 'general' }),
          accessor: 'status',
          FieldValueComponent: ContractStatusFieldValue,
        },
        {
          label: t('from_uppercase', { ns: 'general' }),
          accessor: 'sendersNames',
          FieldValueComponent: NameArrayFieldValue,
        },
        {
          label: t('yourRole', { ns: 'company' }),
          accessor: (request) => t(`requests.dashboardRole.${request.role}`, { ns: 'translation' }),
        },
      ];

      const selectField = {
        onSelect: (row) => {
          const overview = row.original;
          if (selectedItemsIds?.includes(overview._id)) {
            // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
            unselectItem(overview._id);
          } else {
            // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
            selectItem(overview._id);
          }
        },
        isSelected: (row) => selectedItemsIds?.includes(row.original._id),
      };

      return branch(
        ({ row }) => row.original.hasAccess,
        withProps(
          FieldsCell,
          {
            fields,
            titleAccessor: 'name',
            defaultTitle: t('untitledContract'),
            RowIconComponent: ContractStatusIcon,
            ...selectField,
          },
        ),
        withProps(
          FieldsCell,
          {
            disabledMessage: t('notOnTeamForThisContract'),
            titleAccessor: 'name',
            defaultTitle: t('untitledContract'),
            RowIconComponent: DisabledStatusIcon,
            ActionsComponent: withProps(
              ReceivedContractLearnMoreButton,
              { openCannotAccessContractDialog },
            ),
            ...selectField,
          },
        ),
      );
    },
    [t, openCannotAccessContractDialog, selectedItemsIds, selectItem, unselectItem],
  );

  return (
    <BasicTableStyles hoverBackgroundColor="lightGray6">
      <Table
        columns={columns}
        isPaginated
        pageSizes={[5, 10, 20, 50, 100]}
        hideHeader
        CustomRowCells={CustomRowCells}
        hasStaticHeight={false}
        enforcePagination
        minCellHeight={MIN_CELL_HEIGHT}
        smallPageControls
        onRowClick={handleContractRowClick}
      />
    </BasicTableStyles>
  );
};
