import { useMemo } from 'react';
import { EvaluationPage, PageRole } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { fromPairs, keyBy, mapValues, pick } from 'lodash';
import * as yup from 'yup';
import { useQueries, UseQueryResult } from 'react-query';
import { useApi, wrap } from '../../../api';
import { ModalForm } from '../../../ModalForm';
import * as rfx from '../../../rfx';
import { RequestTeamEditUsersFields } from './RequestTeamEditUsersFields';
import { Loading } from '../../../ui/Loading';
import { ErrorMessage } from '../../../ui/ErrorMessage';
import { getPageRoleChanges } from './utils';
import { User } from '../../../types';

export const RequestTeamEditUsersModal = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: any;
}) => {
  const { t } = useTranslation();
  const api = useApi();
  const saveChanges = rfx.useSaveChanges();
  const { teamById, senders } = rfx.useStructure();
  const page = rfx.usePage() as EvaluationPage;

  const senderIds = senders.map(sender => sender._id);

  const queryResults = useQueries(
    senderIds.map(senderId => ({
      queryKey: ['companyData', { companyId: senderId }],
      queryFn: wrap(api.getUsersForCompany),
    })),
  ) as UseQueryResult<User[]>[];

  const isLoading = queryResults.some(query => query.isLoading);
  const isError = queryResults.some(query => query.isError);

  const companyUserById = useMemo(() => {
    if (isLoading || isError) return {};

    return fromPairs(
      queryResults.map((query, index) => {
        return [
          senderIds[index],
          keyBy(query.data, user => user._id),
        ];
      }),
    ) as Record<string, Record<string, User>>;
  }, [senderIds, queryResults, isLoading, isError]);

  const evaluationPageId = page._id;
  const detailsPageId = page.linkedPageId;

  const normalizedRolesByUserId = useMemo(() => {
    const senderTeamById = pick(teamById, senderIds);

    return mapValues(
      senderTeamById,
      team => mapValues(
        team.users,
        user => {
          const isOwner = team.owners.includes(user._id);

          return {
            // @ts-expect-error ts(2464) FIXME: A computed property name must be of type 'string', 'number', 'symbol', or 'any'.
            [detailsPageId]: user.rfqRoles[detailsPageId] ?? (
              isOwner ? PageRole.EDITOR : PageRole.NONE
            ),
            // @ts-expect-error ts(18048) FIXME: 'user.rfqRoles' is possibly 'undefined'.
            [evaluationPageId]: user.rfqRoles[evaluationPageId] ?? (
              isOwner ? PageRole.EDITOR : PageRole.NONE
            ),
          };
        },
      ),
    );
  }, [detailsPageId, evaluationPageId, senderIds, teamById]);

  return (
    <ModalForm
      heading={t('request.team.editPagePermissions')}
      initialValues={normalizedRolesByUserId}
      validationSchema={yup.mixed()}
      disableSubmitIfNotDirty
      isOpen={isOpen}
      onCancel={close}
      onSubmit={async (values) => {
        const pageRoleChanges = Object.entries(normalizedRolesByUserId)
          .flatMap(([companyId, companyUserRoles]) => {
            return Object.entries(companyUserRoles)
              .flatMap(([userId, roles]) => getPageRoleChanges(
                roles,
                values[companyId][userId],
                companyId,
                userId,
              ));
          });

        await saveChanges({
          changes: pageRoleChanges,
        }, {
          onSettled: close,
        });
      }}
      submitLabel={t('general.saveChanges')}
    >
      {isLoading ? (
        <Loading />
      ) : isError ? (
        <ErrorMessage
          error={t('request.team.errors.couldNotLoadCompanyUsers')}
          fontSize={2}
        />
      ) : (
        <RequestTeamEditUsersFields
          companyUserById={companyUserById}
        />
      )}
    </ModalForm>
  );
};
