import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import { Form, Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { NoLineItemsReason, RequestNotAccurateReason, RfqStatus, RfxSpendAndSavings, TotalSavingsCalculationMethod } from '@deepstream/common/rfq-utils';
import { getFinalValueSectionStatus } from '@deepstream/common/rfq-utils/spendAndSavings';
import { compact, isBoolean, isFinite, pick } from 'lodash';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { withProps } from '@deepstream/ui-utils/withProps';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { MoneyField } from '../../../form/MoneyField';
import { CurrencyAmount } from '../../../ui/Currency';
import { PropertyList, YesNoWithIcon } from '../../../PropertyList';
import * as rfx from '../../../rfx';
import { DraftSectionLabelConfigProvider } from '../../../draft/DraftSectionLabelConfigProvider';
import { YesNoRadioField } from '../../../form/RadioField';
import { useUpdateSpendAndSavings } from './useUpdateSpendAndSavings';
import { SpendPanelHeader } from './SpendPanelHeader';
import { FieldContainer } from '../../../form/FieldContainer';
import { useRfqId, useRfqComputedCostAndSavings } from '../../../useRfq';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { ActionRequiredPlaceholder } from './ActionRequiredPlaceholder';
import { useConfirmDialog } from '../../../ui/useModalState';
import { ConfirmTotalSpendUpdateDialog } from './ConfirmTotalSpendUpdateDialog';
import { TextField } from '../../../form/TextField';
import { CheckboxFieldArray } from '../../../form/CheckboxField';
import { LeavePageModal } from '../../../draft/LeavePageModal';

const panelId = 'finalValue';

const FinalValueViewPanelContent = ({ calculatedTotalValue }: { calculatedTotalValue?: number }) => {
  const { spendAndSavings, status } = rfx.useStructure();
  const { t } = useTranslation();

  const isPanelDisabled = status !== RfqStatus.AWARDED;

  const properties = React.useMemo(() => {
    const {
      // @ts-expect-error ts(2339) FIXME: Property 'isCalculatedTotalValueAccurate' does not exist on type 'RfxSpendAndSavings | null | undefined'.
      isCalculatedTotalValueAccurate,
      // @ts-expect-error ts(2339) FIXME: Property 'canProvideManualTotalValue' does not exist on type 'RfxSpendAndSavings | null | undefined'.
      canProvideManualTotalValue,
      // @ts-expect-error ts(2339) FIXME: Property 'manualTotalValue' does not exist on type 'RfxSpendAndSavings | null | undefined'.
      manualTotalValue,
    } = spendAndSavings;
    const hasCalculatedTotalValue = isFinite(calculatedTotalValue);

    return compact([
      {
        fieldName: 'description',
        name: t('general.description'),
        value: t('request.spendAndSavings.finalValueDescription'),
        labelWidth: 290,
        heightAuto: true,
      },
      {
        fieldName: 'calculatedTotalValue',
        name: t('request.spendAndSavings.calculatedTotalValue'),
        infoTooltip: t('request.spendAndSavings.calculatedTotalValueTooltip'),
        value: calculatedTotalValue,
        Component: withProps(CurrencyAmount, { showCode: true }),
        labelWidth: 290,
        heightAuto: true,
      },
      !isPanelDisabled && hasCalculatedTotalValue && {
        fieldName: 'isCalculatedTotalValueAccurate',
        name: t('request.spendAndSavings.isCalculatedTotalValueAccurate'),
        value: isCalculatedTotalValueAccurate,
        Component: withProps(YesNoWithIcon, { placeholder: <ActionRequiredPlaceholder /> }),
        labelWidth: 290,
        heightAuto: true,
      },
      !isPanelDisabled && (!hasCalculatedTotalValue || isCalculatedTotalValueAccurate === false) && {
        fieldName: 'canProvideManualTotalValue',
        name: t('request.spendAndSavings.canProvideManualTotalValue'),
        value: canProvideManualTotalValue,
        Component: withProps(YesNoWithIcon, { placeholder: <ActionRequiredPlaceholder /> }),
        labelWidth: 290,
        heightAuto: true,
      },
      !isPanelDisabled && (!hasCalculatedTotalValue || isCalculatedTotalValueAccurate === false) && canProvideManualTotalValue && {
        fieldName: 'manualTotalValue',
        name: t('request.spendAndSavings.manualTotalValue'),
        description: t('request.spendAndSavings.manualTotalValueDescription'),
        value: manualTotalValue,
        Component: withProps(CurrencyAmount, { showCode: true, placeholder: <ActionRequiredPlaceholder /> }),
        labelWidth: 290,
        heightAuto: true,
      },
    ]);
  }, [spendAndSavings, isPanelDisabled, calculatedTotalValue, t]);

  return (
    <PropertyList properties={properties} />
  );
};

export const FinalValueFields = ({
  values,
  setFieldValue,
  validateForm,
  Padding = PanelPadding,
  Divider = PanelDivider,
  calculatedTotalValue,
  isModal,
  hasLineItemsWithTotalCost,
}: {
  Padding?: (props: { children: React.ReactNode }) => React.ReactNode;
  Divider?: React.FC;
  calculatedTotalValue?: number | null;
  isModal?: boolean;
  hasLineItemsWithTotalCost: boolean;
} & FormikProps<Partial<RfxSpendAndSavings>>) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const hasCalculatedTotalValue = isFinite(calculatedTotalValue);

  const noLineItemsReasonOptions = React.useMemo(() => {
    return Object.values(NoLineItemsReason).map(reason => ({
      value: reason,
      label: t(`request.spendAndSavings.noLineItemsReason.${reason}`),
    }));
  }, [t]);

  return (
    <>
      <Padding>
        <FieldContainer
          name="calculatedTotalValue"
          label={t('request.spendAndSavings.calculatedTotalValue')}
          infoTooltip={t('request.spendAndSavings.calculatedTotalValueTooltip')}
          labelStyle={{ marginBottom: 0 }}
        >
          {!hasCalculatedTotalValue && !hasLineItemsWithTotalCost ? (
            <>
              <IconText
                icon="square-question"
                isIconRegular
                fontWeight={500}
                text={t('request.spendAndSavings.cannotBeCalculated')}
                gap={2}
              />
              <Text fontSize={1} color="subtext">
                {t('request.spendAndSavings.cannotBeCalculatedDescription')}
              </Text>
            </>
          ) : (
            <CurrencyAmount showCode value={calculatedTotalValue} />
          )}
        </FieldContainer>
      </Padding>
      <Divider />
      {hasCalculatedTotalValue && (
        <>
          <Padding>
            <YesNoRadioField
              name="isCalculatedTotalValueAccurate"
              label={t('request.spendAndSavings.isCalculatedTotalValueAccurate')}
              required
              variant="inline"
              showError
              onChange={async (value) => {
                if (value) {
                  await setFieldValue('canProvideManualTotalValue', null);
                  await setFieldValue('manualTotalValue', null);

                  if (isModal) {
                    await setFieldValue('calculatedTotalValueNotAccurateReasons', []);
                    await setFieldValue('calculatedTotalValueNotAccurateOtherReason', null);
                    await setFieldValue('cannotProvideManualTotalValueReason', null);
                  }
                }
                await validateForm();
              }}
              fieldLabelStyle={{ marginBottom: 0 }}
              helperText={values.isCalculatedTotalValueAccurate ? (
                <IconText
                  icon="check-circle"
                  iconColor={theme.colors.success}
                  isIconRegular
                  fontSize={1}
                  text={t('request.spendAndSavings.valueWillBeConfirmed')}
                  gap={2}
                />
              ) : (
                null
              )}
            />
          </Padding>
          <Divider />
        </>
      )}

      {isModal && !hasCalculatedTotalValue && !hasLineItemsWithTotalCost && (
        <>
          <Padding>
            <CheckboxFieldArray
              name="noLineItemsReasons"
              label={t('request.spendAndSavings.noLineItemsReasons')}
              description={t('request.spendAndSavings.noLineItemsReasonsDescription')}
              required
              flexDirection="column"
              options={noLineItemsReasonOptions}
              onChange={(event) => {
                if (event.target.getAttribute('data-value') === RequestNotAccurateReason.OTHER && !event.target.checked) {
                  setFieldValue('calculatedTotalValueNotAccurateOtherReason', undefined);
                }
              }}
            />
          </Padding>
          <Divider />
        </>
      )}

      {isModal && values.noLineItemsReasons?.includes(NoLineItemsReason.OTHER) && (
        <>
          <Padding>
            <TextField
              name="noLineItemsOtherReason"
              label=" "
              placeholder={t('request.spendAndSavings.pleaseSpecify')}
            />
          </Padding>
          <Divider />
        </>
      )}

      {isModal && values.isCalculatedTotalValueAccurate === false && (
        <>
          <Padding>
            <CheckboxFieldArray
              name="calculatedTotalValueNotAccurateReasons"
              label={t('request.spendAndSavings.calculatedTotalValueNotAccurateReasons')}
              description={t('request.spendAndSavings.calculatedTotalValueNotAccurateReasonsDescription')}
              required
              flexDirection="column"
              options={[
                {
                  value: RequestNotAccurateReason.BAD_PRICES_STRUCTURE,
                  label: t('request.spendAndSavings.calculatedTotalValueNotAccurateReason.badPricesStructure'),
                },
                {
                  value: RequestNotAccurateReason.PRICES_NOT_ACCOUNTED_FOR,
                  label: t('request.spendAndSavings.calculatedTotalValueNotAccurateReason.pricesNotAccountedFor'),
                },
                {
                  value: RequestNotAccurateReason.MULTIPLE_LINE_ITEMS_USED,
                  label: t('request.spendAndSavings.calculatedTotalValueNotAccurateReason.multipleLineItemsUsed'),
                },
                {
                  value: RequestNotAccurateReason.BUSINESS_SHARED,
                  label: t('request.spendAndSavings.calculatedTotalValueNotAccurateReason.businessShared'),
                },
                {
                  value: RequestNotAccurateReason.PRICING_MULTIPLE_SCENARIOS,
                  label: t('request.spendAndSavings.calculatedTotalValueNotAccurateReason.pricingMultipleScenarios'),
                },
                {
                  value: RequestNotAccurateReason.OTHER,
                  label: t('request.spendAndSavings.calculatedTotalValueNotAccurateReason.other'),
                },
              ]}
              onChange={(event) => {
                if (event.target.getAttribute('data-value') === RequestNotAccurateReason.OTHER && !event.target.checked) {
                  setFieldValue('calculatedTotalValueNotAccurateOtherReason', undefined);
                }
              }}
            />
          </Padding>
          <Divider />
        </>
      )}

      {isModal && values.calculatedTotalValueNotAccurateReasons?.includes(RequestNotAccurateReason.OTHER) && (
        <>
          <Padding>
            <TextField
              name="calculatedTotalValueNotAccurateOtherReason"
              label=" "
              placeholder={t('request.spendAndSavings.pleaseSpecify')}
            />
          </Padding>
          <Divider />
        </>
      )}

      {(!hasCalculatedTotalValue || values.isCalculatedTotalValueAccurate === false) && (
        <>
          <Padding>
            <YesNoRadioField
              name="canProvideManualTotalValue"
              label={t('request.spendAndSavings.canProvideManualTotalValue')}
              required
              variant="inline"
              showError
              onChange={async (value) => {
                if (value) {
                  if (isModal) {
                    await setFieldValue('cannotProvideManualTotalValueReason', null);
                  }
                } else {
                  await setFieldValue('manualTotalValue', null);
                }
                await validateForm();
              }}
              fieldLabelStyle={{ marginBottom: 0 }}
              helperText={values.canProvideManualTotalValue ? (
                null
              ) : (
                <MessageBlock variant="warn">
                  {t('request.spendAndSavings.noAdjustedValueWarning')}
                </MessageBlock>
              )}
            />
          </Padding>
          <Divider />
        </>
      )}

      {(!hasCalculatedTotalValue || values.isCalculatedTotalValueAccurate === false) && values.canProvideManualTotalValue && (
        <>
          <Padding>
            <MoneyField
              required
              label={t('request.spendAndSavings.manualTotalValue')}
              description={t('request.spendAndSavings.manualTotalValueDescription')}
              name="manualTotalValue"
              sx={{ width: 200 }}
                // quick fix: we can remove setting the inputStyle height once a line height is
                // set for Input
              inputStyle={{ height: 40 }}
            />
          </Padding>
          <Divider />
        </>
      )}

      {(
        isModal &&
        (!hasCalculatedTotalValue || values.isCalculatedTotalValueAccurate === false) &&
        values.canProvideManualTotalValue === false
      ) && (
        <>
          <Padding>
            <TextField
              required
              name="cannotProvideManualTotalValueReason"
              label={t('request.spendAndSavings.cannotProvideManualTotalValueReason')}
              placeholder={t('request.spendAndSavings.pleaseSpecify')}
            />
          </Padding>
          <Divider />
        </>
      )}

      {isModal && (
        <>
          <Padding>
            <TextField
              name="awardQuestionnaireComment"
              label={t('request.spendAndSavings.awardQuestionnaireComment')}
              description={t('request.spendAndSavings.awardQuestionnaireCommentDescription')}
              // quick fix: we can remove setting the inputStyle height once a line height is
              // set for Input
              inputStyle={{ height: 40 }}
            />
          </Padding>
          <Divider />
        </>
      )}
    </>
  );
};

const FinalValueEditPanelContent = ({ calculatedTotalValue }: { calculatedTotalValue?: number }) => {
  const { t } = useTranslation();
  const { spendAndSavings } = rfx.useStructure();
  const { stopEditing } = rfx.useActions();
  const [updateSpendAndSavings] = useUpdateSpendAndSavings();
  const { confirm, ...dialogProps } = useConfirmDialog();
  const hasLineItemsWithTotalCost = rfx.useHasLineItemsWithTotalCost();

  // @ts-expect-error ts(18049) FIXME: 'spendAndSavings' is possibly 'null' or 'undefined'.
  const requestHasCalculatedTotalValue = isFinite(spendAndSavings.calculatedTotalValue);

  const initialValues = pick(spendAndSavings, [
    'isCalculatedTotalValueAccurate',
    'canProvideManualTotalValue',
    'manualTotalValue',
  ]);

  return (
    <>
      <Formik
        validateOnBlur
        enableReinitialize
        initialValues={initialValues}
        validationSchema={yup.object().shape({
          manualTotalValue: yup.number()
            .nullable()
            .transform((curr, orig) => orig === '' ? null : curr)
            .moreThan(0, t('errors.greaterThan', { min: 0 })),
        })}
        onSubmit={async (values) => {
          const payload: Partial<RfxSpendAndSavings> = { ...values };
          // When the calculated total value has been confirmed as accurate/inaccurate
          // but is not yet stored in the request, include it in the update
          if (
            isBoolean(values.isCalculatedTotalValueAccurate) &&
            !requestHasCalculatedTotalValue &&
            isFinite(calculatedTotalValue)
          ) {
            payload.calculatedTotalValue = calculatedTotalValue;
          }

          const hasFinalValue = values.isCalculatedTotalValueAccurate === false
            ? isFinite(values.manualTotalValue)
            : isFinite(calculatedTotalValue);

          if (
            !hasFinalValue &&
            // @ts-expect-error ts(18049) FIXME: 'spendAndSavings' is possibly 'null' or 'undefined'.
            spendAndSavings.totalSavingsCalculationMethod === TotalSavingsCalculationMethod.BUDGET_FINAL_VALUE_DIFF
          ) {
            confirm(async () => {
              await updateSpendAndSavings({
                ...payload,
                totalSavingsCalculationMethod: null,
                areTotalSavingsAccurate: null,
              }, {
                onSuccess: () => stopEditing(),
              });
            });
          } else {
            await updateSpendAndSavings(payload, {
              onSuccess: () => stopEditing(),
            });
          }
        }}
      >
        {(props) => (
          <Form>
            <PanelPadding>
              <FieldContainer
                name="description"
                label={t('general.description')}
                labelStyle={{ marginBottom: 0 }}
              >
                {t('request.spendAndSavings.finalValueDescription')}
              </FieldContainer>
            </PanelPadding>
            <PanelDivider />
            <FinalValueFields
              calculatedTotalValue={calculatedTotalValue}
              hasLineItemsWithTotalCost={hasLineItemsWithTotalCost}
              {...props as any}
            />
            <PanelPadding>
              <Flex justifyContent="flex-end">
                <CancelButton onClick={callAll(props.resetForm, stopEditing)} mr={2} />
                <SaveButton disabled={props.isSubmitting || !props.dirty || !props.isValid} />
              </Flex>
            </PanelPadding>
            <LeavePageModal />
          </Form>
        )}
      </Formik>
      <ConfirmTotalSpendUpdateDialog {...dialogProps} />
    </>
  );
};

export const FinalValuePanel = () => {
  const { t } = useTranslation();
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { spendAndSavings, status } = rfx.useStructure();
  const { editingPanelId } = rfx.useState();

  // @ts-expect-error ts(18049) FIXME: 'spendAndSavings' is possibly 'null' or 'undefined'.
  const requestHasCalculatedTotalValue = isFinite(spendAndSavings.calculatedTotalValue);

  const { data: computedCostAndSavingsResponse, isLoading: isLoadingCalculatedTotalValue } = useRfqComputedCostAndSavings({
    rfqId,
    currentCompanyId,
    enabled: status === RfqStatus.AWARDED && !requestHasCalculatedTotalValue,
  });

  const calculatedTotalValue = requestHasCalculatedTotalValue
    // @ts-expect-error ts(18049) FIXME: 'spendAndSavings' is possibly 'null' or 'undefined'.
    ? spendAndSavings.calculatedTotalValue
    : computedCostAndSavingsResponse?.totalRequestValue;

  const isEditingOtherPanel = editingPanelId && editingPanelId !== panelId;
  const isEditingThisPanel = editingPanelId && editingPanelId === panelId;

  const isPanelDisabled = status !== RfqStatus.AWARDED;

  const heading = t('request.spendAndSavings.finalValue');

  return (
    <DraftSectionLabelConfigProvider>
      <Panel
        as="section"
        aria-label={heading}
        mb={20}
        sx={{
          opacity: isEditingOtherPanel || isPanelDisabled ? 0.5 : 1,
          boxShadow: isEditingThisPanel ? '0 0 8px 0 rgba(0, 0, 0, 0.3)' : '',
        }}
      >
        <SpendPanelHeader
          heading={heading}
          panelId={panelId}
          // @ts-expect-error ts(2345) FIXME: Argument of type 'RfxSpendAndSavings | null | undefined' is not assignable to parameter of type 'RfxSpendAndSavings | undefined'.
          status={getFinalValueSectionStatus(spendAndSavings)}
          isPanelDisabled={isPanelDisabled}
          isEditButtonDisabled={isLoadingCalculatedTotalValue}
        />
        <PanelDivider />
        {isEditingThisPanel ? (
          <FinalValueEditPanelContent
            // @ts-expect-error ts(2322) FIXME: Type 'number | null | undefined' is not assignable to type 'number | undefined'.
            calculatedTotalValue={calculatedTotalValue}
          />
        ) : (
          <FinalValueViewPanelContent
            // @ts-expect-error ts(2322) FIXME: Type 'number | null | undefined' is not assignable to type 'number | undefined'.
            calculatedTotalValue={calculatedTotalValue}
          />
        )}
      </Panel>
    </DraftSectionLabelConfigProvider>
  );
};
