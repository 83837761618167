import { sumBy } from 'lodash';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { ExchangeSnapshot } from '../../../types';

export const CommentCount = ({ exchange }: { exchange: ExchangeSnapshot }) => {
  const commentCount = sumBy(exchange.history, action => action.comment ? 1 : 0);

  return commentCount > 0 ? (
    <IconText
      isIconRegular
      icon="comment"
      color="subtext"
      text={commentCount}
      fontSize={1}
      iconFontSize={1}
      gap={1}
      sx={{ wordWrap: 'break-word' }}
    />
  ) : (
    null
  );
};
