import { useState } from 'react';
import { Box } from 'rebass/styled-components';
import { uniq, difference } from 'lodash';
import { useTranslation } from 'react-i18next';
import { PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { RequestTag } from '../../ui/types';
import { useRequestTagsContext } from './RequestTagsContext';
import { useModalState } from '../../ui/useModalState';
import RequestTagModal from './RequestTagModal';
import RequestTagCheckbox from './RequestTagCheckbox';

export const EditContractTagsDropdown = ({ contractIds, onSave }: { contractIds: string[]; onSave: () => void; }) => {
  const { t } = useTranslation();
  const [updatedTags, setUpdatedTags] = useState<{ [id: string]: RequestTag }>({});
  const { tags, updateTag } = useRequestTagsContext();
  const hasUpdatedTags = Object.keys(updatedTags).length > 0;
  const addTagModal = useModalState();

  const isTagSelected = (tag) => {
    if (updatedTags[tag._id]) {
      // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
      return contractIds.every((request) => updatedTags[tag._id].contractIds.includes(request));
    }
    return contractIds.every(request => tag.contractIds.includes(request));
  };

  const isTagIndeterminate = (tag) => {
    if (updatedTags[tag._id]) {
      // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
      return contractIds.some((request) => updatedTags[tag._id].contractIds.includes(request)) && !isTagSelected(tag);
    }
    return contractIds.some(request => tag.contractIds.includes(request)) && !isTagSelected(tag);
  };

  const handleTagChange = (tag, checked) => {
    if (checked) {
      setUpdatedTags({
        ...updatedTags,
        [tag._id]: {
          ...tag,
          contractIds: uniq([...tag.contractIds, ...contractIds]),
        },
      });
    } else {
      setUpdatedTags({
        ...updatedTags,
        [tag._id]: {
          ...tag,
          contractIds: difference(tag.contractIds, contractIds),
        },
      });
    }
  };

  const handleSaveChanges = async () => {
    await Promise.all(Object.values(updatedTags).map(tag => updateTag(tag)));
    setUpdatedTags({});
    onSave();
  };

  return (
    <>
      <DropdownMenu
        rightAligned
        small
        variant="secondary"
        buttonText={t('requests.tags.dropdownLabel')}
        iconLeft="tag"
        iconRight="caret-down"
        menuZIndex={10}
        footerMenuItem={hasUpdatedTags && (
          <DropdownMenuItem color="primary" style={{ fontWeight: 'bold' }} onSelect={handleSaveChanges}>
            {t('requests.tags.dropdownSave')}
          </DropdownMenuItem>
        )}
        disabled={tags.length === 0}
      >
        {tags.map((tag, tagIndex) => {
          const isRootTag = !tag.parentTagId;
          const isFirstTag = tagIndex === 0;
          const shouldShowDivider = isRootTag && !isFirstTag;

          return (
            <Box key={tag._id}>
              {shouldShowDivider && <PanelDivider style={{ margin: '8px 0' }} />}
              <Box px={2} py={1}>
                <RequestTagCheckbox
                  key={tag._id}
                  onChange={(event) => handleTagChange(tag, (event.target as HTMLInputElement).checked)}
                  checked={isTagSelected(tag)}
                  indeterminate={isTagIndeterminate(tag)}
                  tag={tag}
                />
              </Box>
            </Box>
          );
        })}
      </DropdownMenu>
      <RequestTagModal onClose={addTagModal.close} isOpen={addTagModal.isOpen} />
    </>
  );
};
