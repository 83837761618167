import { useState, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionType,
  ExchangeStatus,
  ExchangeType,
  InclusionOption,
  QuestionDocument,
  QuestionType,
  QuestionYesNo,
  ResponseTag,
  getStageIdFromTag,
  getTagFromStageId,
  isEmptyResponse,
  isFormulaField,
  isGridQuestion,
} from '@deepstream/common/rfq-utils';
import { findLast, first, intersection, isEmpty, last } from 'lodash';
import { Flex, Box } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ContractProvidedBy, ContractStatus } from '@deepstream/common/contract';
import { useSearch } from '@tanstack/react-router';
import { useCurrentCompanyId } from '../currentCompanyId';
import { getContractExchangeSnapshotStatus, getExchangeSnapshotStatus } from '../exchangeStatus';
import { useExchangeColumns, useLineItemExchangeModalSectionColumns, useSupplierDocumentModalColumns } from '../modules/Exchange/columns';
import { usePossibleActions } from '../modules/Exchange/usePossibleActions';
import { ExchangeHistoryAction, ExchangeSnapshot, LineItemsExchangeSnapshot } from '../types';
import { ContextType, useHooks } from '../useHooks';
import { ExchangeFieldStatusIcon } from './ExchangeFieldStatusIcon';
import * as modal from './layout';
import { StandaloneExchangeFields } from '../ExchangeTable';
import { InlineExchangeReply } from '../modules/Exchange/InlineExchangeReply';
import * as rfx from '../rfx';
import { StageName } from '../StageName';
import { PreviousStageEmptyResponseCell, PreviousStageIncompleteCell } from '../NoResponseCell';
import { useCurrentUser } from '../useCurrentUser';
import { canESignContract } from '../modules/Contracts/contract';
import { useApi } from '../api';
import { useMutation } from '../useMutation';
import { useToaster } from '../toast';
import { ExpandViewButton } from '../modules/Request/Comparison/ExpandViewButton';

const TagHeader = ({ tag }: { tag: ResponseTag }) => {
  const stageId = getStageIdFromTag(tag);
  const { stages } = rfx.useStructure();

  const stageIndex = stages.findIndex(stage => stage._id === stageId);

  return (
    <StageName index={stageIndex} stage={stages[stageIndex]} />
  );
};

const ActionButtons = ({
  isExchangeComplete,
  sendReminderDisabled,
  isExpandedView,
  onEditClick,
  onClearResponseClick,
  onSendReminderClick,
  setIsExpandedView,
}: {
  isExchangeComplete: boolean;
  sendReminderDisabled?: boolean;
  isExpandedView?: boolean;
  onEditClick: (isOpen: boolean) => void;
  onClearResponseClick: () => void;
  onSendReminderClick?: () => void;
  setIsExpandedView?: (isExpandedView: boolean) => void;
}) => {
  const { t } = useTranslation(['translation', 'general', 'contracts']);

  return (
    <Flex minWidth="100px" flexDirection="column" sx={{ gap: 2 }} justifyContent="flex-start" ml={2}>
      {setIsExpandedView && (
        <ExpandViewButton
          // @ts-expect-error ts(2322) FIXME: Type 'boolean | undefined' is not assignable to type 'boolean'.
          isExpandedView={isExpandedView}
          setIsExpandedView={setIsExpandedView}
          shrinkedVariant="secondary-transparent-outline"
          type="button"
        />
      )}
      {onEditClick && !isExchangeComplete && (
        <Button
          sx={{ textTransform: 'capitalize' }}
          variant="primary"
          onClick={() => onEditClick(true)}
          small
          iconLeft="reply"
        >
          {t('respond', { ns: 'general' })}
        </Button>
      )}
      {onEditClick && isExchangeComplete && (
        <Button
          sx={{ textTransform: 'capitalize' }}
          variant="secondary-outline"
          onClick={() => onEditClick(true)}
          small
          iconLeft="pencil"
        >
          {t('edit', { ns: 'general' })}
        </Button>
      )}
      {onClearResponseClick && (
        <Button
          sx={{ textTransform: 'capitalize' }}
          variant="danger-outline"
          onClick={onClearResponseClick}
          small
        >
          {t('request.exchange.clearResponse')}
        </Button>
      )}
      {onSendReminderClick && (
        <Button
          variant="secondary-outline"
          onClick={onSendReminderClick}
          small
          iconLeft="pencil"
          disabled={sendReminderDisabled}
        >
          {t('signature.sendReminder', { ns: 'contracts' })}
        </Button>
      )}
    </Flex>
  );
};

export const SupplierFieldsSection = ({
  exchange,
  activatedStageIds,
}: {
  exchange: ExchangeSnapshot;
  activatedStageIds?: string[];
}) => {
  const { t } = useTranslation(['translation', 'contract', 'general']);
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const {
    contextType,
    usePagePermissions,
    useRecipients,
    useSection,
    useExchangeDefById,
    useContextId,
    useStatus,
    useIsSuperUserOrOwner,
  } = useHooks();
  const pagePermissions = usePagePermissions();
  const recipients = useRecipients();
  const { actions, clearResponseWithObsoleteAction, clearResponse, canReusePreviousResponse } = usePossibleActions(exchange);
  const [isReplyFormOpen, setIsReplyFormOpen] = useState<boolean | null>(null);
  const section = useSection();
  const exchangeDefById = useExchangeDefById();
  const currentUser = useCurrentUser();
  const [hasSentReminder, setHasSentReminder] = useState<boolean>(false);
  const api = useApi();
  const toaster = useToaster();
  const contextId = useContextId();
  const status = useStatus();
  const search = useSearch({ strict: false }) as { from?: 'verified' };
  const exchangeColumns = useExchangeColumns();
  const lineItemExchangeColumns = useLineItemExchangeModalSectionColumns(exchange, 'supplier');
  const documentExchangeColumns = useSupplierDocumentModalColumns(exchange);
  const isSuperUserOrOwner = useIsSuperUserOrOwner();
  const [isExpandedView, setIsExpandedView] = useState<boolean>(false);

  const isRecipient = useMemo(
    () => {
      const recipientIds = recipients.map(recipient => recipient._id);

      return recipientIds.includes(currentCompanyId);
    },
    [recipients, currentCompanyId],
  );

  const exchangeStatus = useMemo(
    () => contextType === ContextType.CONTRACT
      ? getContractExchangeSnapshotStatus(
        exchange,
        currentCompanyId,
        exchangeDefById,
        currentUser._id,
        status as ContractStatus,
        isSuperUserOrOwner,
      )
      : getExchangeSnapshotStatus(exchange, pagePermissions),
    [exchange, pagePermissions, currentCompanyId, exchangeDefById, contextType, currentUser, status, isSuperUserOrOwner],
  );

  const isSupplierActionRequired = useMemo(
    () => (
      exchangeStatus === ExchangeStatus.WAITING_FOR_SUPPLIER ||
      (isRecipient && exchangeStatus === ExchangeStatus.ACTION_REQUIRED)
    ),
    [exchangeStatus, isRecipient],
  );

  const canESign = contextType === ContextType.CONTRACT && canESignContract(currentUser._id, exchange, status as ContractStatus);
  const canTakeAction = isRecipient && (actions.length > 0 || canESign);
  const isExchangeComplete = exchangeStatus === ExchangeStatus.COMPLETE;
  const isFromVerified = search.from === 'verified';
  const shouldFormBeOpen = canTakeAction && !isExchangeComplete && !isFromVerified;
  const canSendReminder = (
    isRecipient && exchange.isAwaitingSubmitterESignature && !canESign && status === ContractStatus.NEGOTIATION
  );

  useEffect(
    () => {
      setIsReplyFormOpen(null);
    },
    [exchange._id],
  );

  useEffect(
    () => {
      if (shouldFormBeOpen && isReplyFormOpen === null) {
        setIsReplyFormOpen(true);
      } else if (actions.length === 0 && !canESign && isReplyFormOpen) {
        setIsReplyFormOpen(false);
      }
    },
    [shouldFormBeOpen, isReplyFormOpen, actions, canESign],
  );

  // Questionnaire exchanges don't have a publisherId, but have a `creatorId` instead.
  const isSupplierAdded = useMemo(
    () => recipients.some(recipient => recipient._id === (exchange.def.publisherId || exchange.def.creatorId)),
    [recipients, exchange.def.publisherId, exchange.def.creatorId],
  );

  const columns = useMemo(() => {
    const columns: any[] = [];

    columns.push(...lineItemExchangeColumns);
    columns.push(...documentExchangeColumns);

    if (exchange.def.type === ExchangeType.QUESTION) {
      if (isSupplierAdded) {
        columns.push(...exchangeColumns.questionModal());
      } else {
        columns.push(...exchangeColumns.questionReplyModal({
          showDocument: (
            exchange.def.questionType === QuestionType.DOCUMENT &&
            // @ts-expect-error ts(18048) FIXME: 'exchange.latestResponse' is possibly 'undefined'.
            !isEmpty((exchange.latestResponse.value as QuestionDocument).attachments)
          ),
          showExpiryDate: (
            exchange.def.questionType === QuestionType.DOCUMENT &&
            // @ts-expect-error ts(18048) FIXME: 'exchange.latestResponse' is possibly 'undefined'.
            Boolean((exchange.latestResponse.value as QuestionDocument).expiryDate)
          ),
          showMoreInformation: (
            exchange.def.questionType === QuestionType.YES_NO &&
            // @ts-expect-error ts(18048) FIXME: 'exchange.latestResponse' is possibly 'undefined'.
            Boolean((exchange.latestResponse.value as QuestionYesNo).moreInformation)
          ),
          showYesNoDocument: (
            exchange.def.questionType === QuestionType.YES_NO &&
            // @ts-expect-error ts(18048) FIXME: 'exchange.latestResponse' is possibly 'undefined'.
            !isEmpty((exchange.latestResponse.value as QuestionYesNo).attachments)
          ),
          isExpandedView,
          setIsExpandedView,
        }));
      }
    } else if (exchange.def.type === ExchangeType.INCLUSIONS) {
      if (isSupplierAdded) {
        columns.push(...exchangeColumns.inclusionModalDefinitionReply());
      } else if (exchange.def.option === InclusionOption.FLEXIBLE) {
        columns.push(...exchangeColumns.inclusionModalReply());
      }
    } else if (exchange.def.type === ExchangeType.TERMS && isSupplierAdded) {
      columns.push(...exchangeColumns.termModal());
    } else if (exchange.def.type === ExchangeType.CONTRACT) {
      const lastSupplierActionType = findLast(
        exchange.history,
        ({ companyId, type }) => type !== ActionType.NONE && companyId === exchange.recipientId,
      )?.type as ActionType;
      const lastBuyerActionType = findLast(
        exchange.history,
        ({ companyId, type }) => type !== ActionType.NONE && companyId === exchange.def.publisherId,
      )?.type as ActionType;
      const isBuyerDeviationOngoing = lastBuyerActionType === ActionType.DEVIATE;

      const isProvidedByBuyer = exchange.def.providedBy === ContractProvidedBy.BUYER;

      const isSupplierDeviationResponse = [ActionType.ACCEPT, ActionType.REJECT].includes(lastSupplierActionType);

      const showResponseStatus = isProvidedByBuyer
        ? true
        : !exchange.isAwaitingInitiatorApproval && !isBuyerDeviationOngoing && !isSupplierDeviationResponse;

      columns.push(...exchangeColumns.supplierSectionContractModal({
        showResponseStatus,
        isDeviationOngoing: isBuyerDeviationOngoing,
        hasSupplierAttachment: !!exchange.latestSupplierAttachment,
        showSigners: !isEmpty(exchange.verified?.recipientSigners),
      }));
    }

    return columns;
  }, [
    lineItemExchangeColumns,
    documentExchangeColumns,
    exchange,
    isSupplierAdded,
    exchangeColumns,
    isExpandedView,
  ]);

  const handleOnClose = () => {
    setIsReplyFormOpen(false);
  };

  const handleClearResponseObsolete = () => {
    setIsReplyFormOpen(false);
    clearResponseWithObsoleteAction?.();
  };

  const handleClearResponse = (selectedAction: ExchangeHistoryAction) => {
    setIsReplyFormOpen(false);
    clearResponse?.(selectedAction);
  };

  const header = contextType !== ContextType.CONTRACT
    ? t('request.lineItems.deliveryDate.supplierFields')
    : isRecipient
      ? t('exchange.modal.myFields', { ns: 'contracts' })
      : t('exchange.modal.counterpartyFields', { ns: 'contracts' });

  const [sendESignatureReminderMutation] = useMutation(
    api.sendContractESignatureReminder,
    {
      onSuccess: () => toaster.success(t('toaster.reminderSent.success', { ns: 'contracts' })),
      onError: () => toaster.error(t('toaster.reminderSent.failed', { ns: 'contracts' })),
      onSettled: () => setHasSentReminder(true),
    },
  );

  const sendESignatureReminder = useCallback(
    () => {
      setHasSentReminder(true);
      sendESignatureReminderMutation({
        companyId: currentCompanyId,
        contractId: contextId,
        exchangeId: exchange._id,
      });
    },
    [currentCompanyId, contextId, exchange, sendESignatureReminderMutation],
  );

  const canExpandView = (
    exchange.def.type === ExchangeType.QUESTION &&
    isGridQuestion(exchange.def) &&
    // @ts-expect-error ts(2345) FIXME: Argument of type 'QuestionResponse | undefined' is not assignable to parameter of type 'QuestionResponse'.
    !isEmptyResponse(exchange.latestResponse, exchange.def)
  );

  if (isEmpty(columns)) {
    return null;
  }

  if (
    contextType === ContextType.RFX &&
    activatedStageIds &&
    section &&
    'responseTagConfig' in section &&
    section.responseTagConfig &&
    (exchange as LineItemsExchangeSnapshot).responseTag &&
    (exchange as LineItemsExchangeSnapshot).supplierReplyByTag
  ) {
    const { responseTagConfig } = section;
    const currentTag = (exchange as LineItemsExchangeSnapshot).responseTag;

    if (
      !isRecipient ||
      // recipients should see the stage subsections only after the first response stage
      currentTag !== first(responseTagConfig.tags)
    ) {
      // exclude future tags
      const tags = intersection(
        activatedStageIds.map(getTagFromStageId),
        responseTagConfig.tags,
      );

      const latestResponseTag = last(tags);

      return (
        <modal.ClosableSection
          headerIcon={isSupplierActionRequired ? (
            <ExchangeFieldStatusIcon exchange={exchange} />
          ) : (
            <Icon color="subtext" icon="reply" />
          )}
          header={header}
          px={0}
          hasDividerAtStartOfContent
          isBottomHighlighted={!!isReplyFormOpen}
        >
          {tags.map(tag => {
            const latestReply = (exchange as LineItemsExchangeSnapshot).supplierReplyByTag?.[tag];
            const sectionColumns = columns
              .filter(column => {
                return (
                  // @ts-expect-error ts(2339) FIXME: Property '_id' does not exist on type 'never'.
                  column._id in latestReply ||
                  // @ts-expect-error ts(18048) FIXME: 'exchange.def.fields' is possibly 'undefined'.
                  (tag === latestResponseTag && isFormulaField(exchange.def.fields[column._id]))
                );
              })
              .map(column => {
                if (tag !== currentTag && column.EmptyCell) {
                  return {
                    ...column,
                    EmptyCell: column.fieldType === 'formula'
                      ? PreviousStageIncompleteCell
                      : PreviousStageEmptyResponseCell,
                  };
                } else {
                  return column;
                }
              });
            const patchedExchange = {
              ...exchange,
              latestReply,
            };

            return (
              <modal.Subsection
                key={tag}
                // @ts-expect-error ts(2322) FIXME: Type 'Element | null' is not assignable to type 'null | undefined'.
                headerIcon={tag === currentTag && isSupplierActionRequired ? (
                  <ExchangeFieldStatusIcon exchange={exchange} />
                ) : (
                  null
                )}
                // @ts-expect-error ts(2322) FIXME: Type 'Element' is not assignable to type 'null | undefined'.
                header={<TagHeader tag={tag} />}
                // @ts-expect-error ts(2322) FIXME: Type 'false | null' is not assignable to type 'boolean | undefined'.
                isHighlighted={tag === currentTag && isReplyFormOpen}
              >
                {tag === currentTag ? (
                  <Flex pt={3}>
                    <Box flex={1}>
                      {isReplyFormOpen ? (
                        <InlineExchangeReply
                          actions={actions}
                          onClose={handleOnClose}
                          clearResponse={clearResponse && handleClearResponse}
                          columns={sectionColumns}
                        />
                      ) : (
                        <StandaloneExchangeFields
                          vertical
                          small
                          exchange={patchedExchange}
                          columns={sectionColumns}
                        />
                      )}
                    </Box>
                    {!isReplyFormOpen && (
                      <ActionButtons
                        // @ts-expect-error ts(2322) FIXME: Type 'Dispatch<SetStateAction<null>> | null' is not assignable to type '(isOpen: boolean) => void'.
                        onEditClick={canTakeAction ? setIsReplyFormOpen : null}
                        isExchangeComplete={isExchangeComplete}
                        // @ts-expect-error ts(2322) FIXME: Type '(() => void) | null' is not assignable to type '() => void'.
                        onClearResponseClick={clearResponseWithObsoleteAction && isRecipient ? handleClearResponseObsolete : null}
                      />
                    )}
                  </Flex>
                ) : (
                  <Flex pt={3}>
                    <Box flex={1}>
                      <StandaloneExchangeFields
                        vertical
                        small
                        exchange={patchedExchange}
                        columns={sectionColumns}
                      />
                    </Box>
                    <Flex minWidth="100px" />
                  </Flex>
                )}
              </modal.Subsection>
            );
          })}
        </modal.ClosableSection>
      );
    }
  }

  return (
    <modal.ClosableSection
      headerIcon={isSupplierActionRequired ? (
        <ExchangeFieldStatusIcon exchange={exchange} />
      ) : (
        <Icon color="subtext" icon="reply" />
      )}
      header={header}
      isHighlighted={!!isReplyFormOpen}
    >
      <Flex pt={3}>
        <Box flex={1}>
          {isReplyFormOpen ? (
            <InlineExchangeReply
              actions={actions}
              onClose={handleOnClose}
              clearResponse={clearResponse && handleClearResponse}
              canESign={canESign}
              canReusePreviousResponse={canReusePreviousResponse}
              columns={columns}
            />
          ) : (
            <StandaloneExchangeFields
              vertical
              small
              exchange={exchange}
              columns={columns}
            />
          )}
        </Box>
        {!isReplyFormOpen && (
          <ActionButtons
            isExpandedView={isExpandedView}
            // @ts-expect-error ts(2322) FIXME: Type 'Dispatch<SetStateAction<null>> | null' is not assignable to type '(isOpen: boolean) => void'.
            onEditClick={canTakeAction ? setIsReplyFormOpen : null}
            isExchangeComplete={isExchangeComplete}
            // @ts-expect-error ts(2322) FIXME: Type '(() => void) | null' is not assignable to type '() => void'.
            onClearResponseClick={clearResponseWithObsoleteAction && isRecipient ? handleClearResponseObsolete : null}
            // @ts-expect-error ts(2322) FIXME: Type '(() => void) | null' is not assignable to type '(() => void) | undefined'.
            onSendReminderClick={canSendReminder ? sendESignatureReminder : null}
            sendReminderDisabled={hasSentReminder}
            // @ts-expect-error ts(2322) FIXME: Type 'Dispatch<SetStateAction<boolean>> | null' is not assignable to type '((isExpandedView: boolean) => void) | undefined'.
            setIsExpandedView={canExpandView ? setIsExpandedView : null}
          />
        )}
      </Flex>
    </modal.ClosableSection>
  );
};
