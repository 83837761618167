import { useMemo } from 'react';
import { filter, find, first, isEmpty, mapValues, pick, values } from 'lodash';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Flex, Box, Text } from 'rebass/styled-components';
import {
  VesselPricingSection,
  InclusionOption,
  FeeType,
  isCurrencyExchangeDef,
  isInclusionExchangeDef,
  isTermExchangeDef,
  isFeeExchangeDef,
  timePeriods,
  Draft,
} from '@deepstream/common/rfq-utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { PanelHeader, Panel, PanelDivider, ExpandablePanelSubSection } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import * as draft from './draft';
import * as rfx from '../rfx';
import { useStageName } from './useStageName';
import { Tab, TabListPanel, Tabs, TabPanel, TabPanels } from '../ui/TabsVertical';
import { SidebarLayout } from '../ui/ProfileLayout';
import { InclusionTable } from './InclusionTable';
import { TermTable } from './TermTable';
import { ViewDraftHirePeriodSections } from './DraftHirePeriodSections';
import { DraftDeliverySection } from './DraftDeliverySection';
import { DraftPanel } from './DraftPanel';
import { Validation, useError, useErrors } from './validation';
import { intervalTypes } from './DayRatesTable';
import { SectionConfigHeading } from './SectionConfigHeading';
import { VesselPricingSectionConfigIndicators } from './VesselPricingSectionConfigIndicators';
import { DraftSectionViewPanelHeader } from './DraftSectionViewPanelHeader';
import { NO_LOT_PROXY_ID, useLotSelectItems } from './useLotSelectItems';
import { FeeTable } from './FeeTable';

const ViewSectionConfig = () => {
  const { settings } = rfx.useStructure<Draft>();
  const isSender = rfx.useIsSender();
  const section = rfx.useSectionWithPosition<VesselPricingSection>();
  const exchangeDefs = rfx.useSectionExchangeDefs();
  const currencyExchangeDef = find(exchangeDefs, isCurrencyExchangeDef);
  // @ts-expect-error ts(18048) FIXME: 'currencyExchangeDef' is possibly 'undefined'.
  const currencyCode = currencyExchangeDef.currencies[0];
  const showValidationErrors = draft.useShowValidationErrors();
  const { error: supplierCurrencyError } = useError('currencyExchangeDef.currencies');

  // @ts-expect-error ts(18048) FIXME: 'section.stages' is possibly 'undefined'.
  const stageId = section.stages[0];
  const stageName = useStageName(stageId);

  const lotSelectItems = useLotSelectItems();
  const selectedLot = settings.areLotsEnabled
    ? find(lotSelectItems, { value: first(section.lotIds) || NO_LOT_PROXY_ID })
    : null;

  return (
    <ExpandablePanelSubSection
      heading={<SectionConfigHeading />}
      renderCollapsedContent={() => (
        <VesselPricingSectionConfigIndicators
          isSender={isSender}
          // @ts-expect-error ts(2322) FIXME: Type 'string | number | null' is not assignable to type 'string | number | undefined'.
          stageName={stageName}
          locking={section.locking}
          allowSupplierInclusions={section.allowSuppliersToAddInclusionsAndExclusions}
          allowSupplierTerms={section.allowSuppliersToAddAdditionalTerms}
          allowSupplierFees={section.allowSuppliersToAddFees}
          currencyCode={currencyCode}
          // @ts-expect-error ts(2322) FIXME: Type 'LotSelectItem | null | undefined' is not assignable to type 'LotSelectItem | undefined'.
          selectedLot={selectedLot}
        />
      )}
      bg={showValidationErrors && supplierCurrencyError ? 'errorBackground' : 'lightGray3'}
    />
  );
};

const InclusionsSection = () => {
  const { t } = useTranslation('translation');

  return (
    <Panel>
      <PanelHeader
        heading={t('request.vesselPricing.inclusions.inclusion_other')}
        bg="lightGray3"
      />
      <PanelDivider />
      <InclusionTable />
    </Panel>
  );
};

const TermsSection = () => {
  const { t } = useTranslation('translation');

  return (
    <Panel>
      <PanelHeader
        heading={t('request.vesselPricing.terms.additionalTerm_other')}
        bg="lightGray3"
      />
      <PanelDivider />
      <TermTable />
    </Panel>
  );
};

const FeeSection = () => {
  const { t } = useTranslation();

  return (
    <Panel>
      <PanelHeader
        heading={t('request.vesselPricing.fees.fee_other')}
        bg="lightGray3"
      />
      <PanelDivider />
      <FeeTable />
    </Panel>
  );
};

const VesselPricingSectionTabs = () => {
  const { t } = useTranslation('translation');

  const showValidationErrors = draft.useShowValidationErrors();
  const { errors } = useErrors();
  const hasGeneralError = !isEmpty(
    pick(errors, ['inclusionExchangeDefs', 'termExchangeDefs']),
  );
  const hasVesselSpecificError = !isEmpty(
    pick(errors, ['hirePeriods', 'intervalsByHirePeriodId', 'feeExchangeDefs']),
  );

  return (
    <Tabs>
      <Box p={20}>
        <SidebarLayout
          sidebar={
            <TabListPanel panelStyle={{ height: 'fit-content' }}>
              <Tab data-test="general-tab">
                <Flex alignItems="space-between">
                  <Text flex={1}>{t('request.vesselPricing.general')}</Text>
                  {showValidationErrors && hasGeneralError && (
                    <Icon icon="exclamation-circle" ml={2} sx={{ top: '3px' }} color="danger" fixedWidth />
                  )}
                </Flex>
              </Tab>
              <Tab data-test="vessel-specific-tab">
                <Flex alignItems="space-between">
                  <Text flex={1}>{t('request.vesselPricing.vesselSpecific')}</Text>
                  {showValidationErrors && hasVesselSpecificError && (
                    <Icon icon="exclamation-circle" ml={2} sx={{ top: '3px' }} color="danger" fixedWidth />
                  )}
                </Flex>
              </Tab>
            </TabListPanel>
          }
          main={
            <TabPanels>
              <TabPanel>
                <Stack gap="20px">
                  <InclusionsSection />
                  <TermsSection />
                </Stack>
              </TabPanel>
              <TabPanel>
                <Stack gap="20px">
                  <DraftDeliverySection />
                  <ViewDraftHirePeriodSections />
                  <FeeSection />
                </Stack>
              </TabPanel>
            </TabPanels>
          }
          sidebarStyle={{ maxWidth: '240px', flex: '0 0 auto', pr: '20px !important' }}
          mainStyle={{ flex: '1 1 auto' }}
        />
      </Box>
    </Tabs>
  );
};

/**
 * Displays a panel for the current vessel pricing section
 */
export const VesselPricingSectionViewPanel = () => {
  const { t } = useTranslation('translation');

  const showValidationErrors = draft.useShowValidationErrors();
  const hirePeriods = rfx.useHirePeriods();
  const section = rfx.useSectionWithPosition<VesselPricingSection>();
  const exchangeDefs = rfx.useSectionExchangeDefs();

  const inclusionExchangeDefs = useMemo(
    () => filter(exchangeDefs, isInclusionExchangeDef),
    [exchangeDefs],
  );
  const termExchangeDefs = useMemo(
    () => filter(exchangeDefs, isTermExchangeDef),
    [exchangeDefs],
  );
  const intervalsByHirePeriodId = rfx.useIntervalsByHirePeriodId();
  const feeExchangeDefs = useMemo(
    () => filter(exchangeDefs, isFeeExchangeDef),
    [exchangeDefs],
  );

  return (
    <Validation
      values={{
        section,
        inclusionExchangeDefs,
        termExchangeDefs,
        hirePeriods,
        intervalsByHirePeriodId,
        feeExchangeDefs,
      }}
      schema={showValidationErrors ? (
        yup.object().shape({
          section: yup.object().shape({
            name: yup.string().required(t('general.required')),
          }),
          inclusionExchangeDefs: yup.array(
            yup.object().shape({
              description: yup.string().required(t('general.required')),
              option: yup.string().oneOf(values(InclusionOption)).required(t('general.required')),
            }),
          ),
          termExchangeDefs: yup.array(
            yup.object().shape({
              description: yup.string().required(t('general.required')),
            }),
          ),
          hirePeriods: yup.array(
            yup.object().shape({
              name: yup.string().required(t('general.required')),
              fromDate: yup.date()
                .nullable()
                .transform((curr, orig) => orig === '' ? null : curr)
                .required(t('general.required')),
              toDate: yup.date()
                .nullable()
                .transform((curr, orig) => orig === '' ? null : curr)
                .required(t('general.required')),
            }),
          ).required().min(1, t('request.review.errors.minimumOneRequired')),
          intervalsByHirePeriodId: yup.lazy((intervals: Record<string, unknown>) => yup.object(
            mapValues(intervals, () => yup.array(
              yup.object().shape({
                intervalType: yup.string().oneOf(intervalTypes).required(t('general.required')),
                amount: yup.number()
                  .nullable()
                  .transform((curr, orig) => orig === '' ? null : curr)
                  .required(t('general.required'))
                  .min(1, t('request.review.errors.minimumOne')),
                unit: yup.string().oneOf(timePeriods).required(t('general.required')),
                quantity: yup.number()
                  .nullable()
                  .transform((curr, orig) => orig === '' ? null : curr)
                  .required(t('general.required'))
                  .min(1, t('request.review.errors.minimumOne')),
              }),
            ).required().min(1, t('request.review.errors.minimumOneRequired'))),
          )),
          feeExchangeDefs: yup.array(
            yup.object().shape({
              description: yup.string().required(t('general.required')),
              feeType: yup.string().oneOf(values(FeeType)).required(t('general.required')),
            }),
          ),
        })
      ) : (
        yup.mixed()
      )}
    >
      <DraftPanel panelId={section._id}>
        <DraftSectionViewPanelHeader icon="ship" />
        <PanelDivider />
        <ViewSectionConfig />
        <PanelDivider />
        <VesselPricingSectionTabs />
      </DraftPanel>
    </Validation>
  );
};
