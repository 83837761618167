import { useMemo } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Box } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { CancelButton, Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { RequestTag } from '../../ui/types';
import { useRequestTagsContext } from './RequestTagsContext';
import { TextField } from '../../form/TextField';
import { SelectField } from '../../form/SelectField';
import { renderRequestTagLabel } from './RequestTagLabel';
import { getRequestTagAncestorsIds } from './utils';

type Props = {
  tag?: RequestTag;
  onClose: () => void;
} & ModalProps;

const RequestTagModal = ({ tag, onClose, ...props }: Props) => {
  const { t } = useTranslation();
  const isEdit = !!tag;
  const { tags, tagById, addTag, updateTag } = useRequestTagsContext();
  const validParentTags = useMemo(
    () => tags.filter(requestTag => {
      const tagAncestors = getRequestTagAncestorsIds(requestTag, tagById);
      // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
      return tag?._id !== requestTag?._id && !tagAncestors.includes(tag?._id);
    }),
    [tags, tagById, tag?._id],
  );

  return (
    <Modal style={{ content: { width: '500px' } }} {...props}>
      <Formik
        initialValues={{
          name: tag?.name || '',
          parentTagId: tag?.parentTagId,
        }}
        validationSchema={
          yup.object().shape({
            name: yup.string().required(t('requests.tags.nameRequired'))
              .notOneOf(validParentTags.map(t => t.name), t('requests.tags.nameExists')),
            parentTagId: yup.string().nullable().notRequired(),
          })
        }
        onSubmit={(values) => {
          if (isEdit) {
            updateTag({ ...tag, ...values });
          } else {
            addTag({
              // @ts-expect-error ts(2339) FIXME: Property '_id' does not exist on type 'never'.
              _id: tag?._id || '',
              name: values.name,
              parentTagId: values.parentTagId,
            });
          }
          onClose();
        }}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form>
            <ModalHeader onClose={onClose}>
              {isEdit ? t('requests.tags.editTag') : t('requests.tags.addTag')}
            </ModalHeader>
            <ModalBody>
              <Box mb={3}>
                <TextField name="name" label={t('requests.tags.addTagName')} required />
              </Box>
              <Box mb={3}>
                <SelectField
                  disabled={!validParentTags.length}
                  canDeselect
                  name="parentTagId"
                  label={t('requests.tags.addTagParent')}
                  placeholder={t('requests.tags.addTagParentPlaceholder')}
                  items={validParentTags.map(tag => ({ value: tag._id, label: renderRequestTagLabel(tag, tagById) }))}
                  truncateLineItem={false}
                  renderPreItemContent={(item, index) => !tagById[item.value]?.parentTagId && index > 0 ? <PanelDivider /> : null}
                />
              </Box>

              <MessageBlock variant="info" my={3}>
                {t('requests.tags.tagModalInfo')}
              </MessageBlock>
            </ModalBody>

            <ModalFooter>
              <CancelButton onClick={onClose} />
              <Button type="submit" disabled={isSubmitting || !dirty || !isValid}>
                {isEdit ? t('requests.tags.addTagEdit') : t('requests.tags.addTagCreate')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default RequestTagModal;
