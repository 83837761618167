import { DefaultEditableGridStyles } from '@deepstream/ui-kit/grid/EditableGrid/EditableGridStyles';
import { EditableGrid } from '@deepstream/ui-kit/grid/EditableGrid/EditableGrid';
import * as React from 'react';
import { BORDER_ADJUSTMENT, DEFAULT_FROZEN_HEADER_HEIGHT } from '@deepstream/ui-kit/grid/core/constants';
import {
  ACTION_COLUMN_WIDTH,
  DEFAULT_EDITABLE_DATA_CELL_WIDTH,
  DEFAULT_ROW_HEIGHT,
  EditableGridColumn,
} from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { getScrollbarSize, withProps } from '@deepstream/ui-utils';
import { ActionType, FieldConfig, LineItemExchangeDefinition, isDefinitionField, isFormulaField } from '@deepstream/common/rfq-utils';
import { FieldType } from '@deepstream/common/exchangesConfig';
import { ColumnData, RowData } from '@deepstream/ui-kit/grid/core/utils';
import { useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { first } from 'lodash';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { usePrevious } from '@deepstream/ui-kit/hooks/usePrevious';
import { useConfigurableExchangeBidFieldColumns } from './useConfigurableFieldColumns';
import { SimpleRowNumberCell } from './nonValidationAwareValueCell';
import { useGetFormattedFormula } from './useFormattedTotalCostFormula';
import { useSchema, ValidationContext, ValidationErrors } from '../../draft/validation';
import { SimpleHeader } from './header';
import { LineItemsExchangeSnapshot } from '../../types';
import { useHandleGridClipboardEvent } from './useHandleGridClipboardEvent';
import { getExchangeReplyFormConfig } from '../../ExchangeModal/exchangeReplyFormConfig';
import { GlobalDatePickerStyles } from '../DatePicker';
import { useHooks } from '../../useHooks';

const navigableRange = { startRowIndex: 1, startColumnIndex: 1 };
const frozenLeftColumnIds = ['rowNumber', 'description'];

type Props = {
  /**
   * Determines the maximum height of the grid when `height` is not set.
   *
   * `maxGridHeight = 100vh - viewportHeightDelta`
   */
  viewportHeightDelta?: number;
  height?: string;
  showOnlyInvalidRows?: boolean;
  onValidationErrorsChange?: (validationErrors: ValidationErrors<{ exchangeDefs: LineItemsExchangeSnapshot[] }>) => void;
  onExchangesChange?: (exchanges: LineItemsExchangeSnapshot[]) => void;
  onSelectedExchangeChange?: (exchange: LineItemsExchangeSnapshot) => void;
};

export const EditLineItemsGrid = ({
  viewportHeightDelta,
  height,
  showOnlyInvalidRows,
  onValidationErrorsChange,
  onExchangesChange,
  onSelectedExchangeChange,
}: Props) => {
  const { t } = useTranslation('translation');
  const { useExchangeDefById } = useHooks();
  const exchangeDefById = useExchangeDefById();

  const handleGridClipboardEvent = useHandleGridClipboardEvent();
  const {
    pendingKeyboardEvent,
    rowData: exchanges,
    setEditedCell,
  } = useEditableGridData<LineItemsExchangeSnapshot>();
  const modelExchangeDef = exchanges[0]
    ? exchangeDefById[exchanges[0]._id] as LineItemExchangeDefinition
    : null;
  const fields = modelExchangeDef?.fields;

  const getFormattedFormula = useGetFormattedFormula(fields);

  const startEditingCell = React.useCallback((
    row: RowData<any, any>,
    column: ColumnData<EditableGridColumn>,
    event?: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (row.original.isObsolete) return;

    if (column.original.startEditingCell) {
      pendingKeyboardEvent.current = event ?? null;

      setEditedCell({
        rowId: row.original._id,
        columnId: column.original._id,
      });
    }
  }, [setEditedCell, pendingKeyboardEvent]);

  const columnProps = React.useCallback((field: FieldConfig) => {
    const info = field.source.type === 'formula' ? getFormattedFormula(field._id) : null;

    return {
      startEditingCell: isDefinitionField(field) || field.type === FieldType.BOOLEAN || isFormulaField(field) ? null : startEditingCell,
      Header: withProps(SimpleHeader, { info, showFieldTypeIcon: true }),
      // unlike other number fields, 'quantity' only allows numbers > 0
      ...(['quantity'].includes(field._id) ? {
        format: 'number.positive',
        exclusiveMinValue: 0,
      } : {}),
      width: field._id === 'description' ? 390 : DEFAULT_EDITABLE_DATA_CELL_WIDTH,
    };
  }, [getFormattedFormula, startEditingCell]);

  const columns = useConfigurableExchangeBidFieldColumns({
    fields,
    columnProps,
    orderedFieldIds: modelExchangeDef?.orderedFieldIds,
  });
  const columnsWithRowNumber = React.useMemo(() => {
    return [
      {
        _id: 'rowNumber',
        accessorKey: 'rowNumber',
        Header: SimpleHeader,
        label: '',
        ValueCell: SimpleRowNumberCell,
        width: ACTION_COLUMN_WIDTH,
        fixedWidth: true,
      },
      ...columns,
    ];
  }, [columns]);

  const firstBuyerExchange = React.useMemo(() => first(exchanges), [exchanges]);
  const validationSchema = React.useMemo(
    () => {
      if (!firstBuyerExchange) {
        return null;
      }

      const formConfig = getExchangeReplyFormConfig(firstBuyerExchange, { type: ActionType.SUBMIT });
      const latestReply = formConfig.getValidationSchema(t, firstBuyerExchange);

      return yup.object({
        exchangeDefs: yup.array()
          .of(yup.object({
            latestReply,
          })),
      });
    },
    [firstBuyerExchange, t],
  );
  const validationResult = useSchema(
    React.useMemo(() => ({ exchangeDefs: exchanges }), [exchanges]),
    // @ts-expect-error ts(2345) FIXME: Argument of type 'ObjectSchema<{ exchangeDefs: { latestReply: ...; }[]; }> | null' is not assignable to parameter of type 'Schema<any>'.
    validationSchema,
    { validateOnChange: true },
  );

  const filteredExchanges = React.useMemo(() =>
    showOnlyInvalidRows &&
      !validationResult.isValid
        ? exchanges.filter((exchange, index) => !!validationResult.errors?.exchangeDefs?.[index])
        : exchanges,
    [showOnlyInvalidRows, exchanges, validationResult],
  );
  const invalidExchangeDefsErrors = React.useMemo(() => validationResult.errors?.exchangeDefs?.filter(Boolean), [validationResult]);
  const previousInvalidExchangeDefsErrors = usePrevious(invalidExchangeDefsErrors);
  const filteredValidationResult = React.useMemo(() => showOnlyInvalidRows && !validationResult.isValid && invalidExchangeDefsErrors ? ({
    ...validationResult,
    errors: {
      exchangeDefs: invalidExchangeDefsErrors,
    },
  }) : validationResult, [showOnlyInvalidRows, invalidExchangeDefsErrors, validationResult]);

  const bodyPaddingBottom = 50;

  const maxGridHeight = (
    DEFAULT_FROZEN_HEADER_HEIGHT +
    BORDER_ADJUSTMENT +
    bodyPaddingBottom +
    DEFAULT_ROW_HEIGHT * exchanges.length +
    getScrollbarSize()
  );

  React.useEffect(() => {
    if (validationResult.isValid && onExchangesChange) {
      onExchangesChange(exchanges);
    }
  }, [validationResult.isValid, exchanges, onExchangesChange]);

  React.useEffect(() => {
    if (!previousInvalidExchangeDefsErrors || invalidExchangeDefsErrors?.length !== previousInvalidExchangeDefsErrors?.length) {
      onValidationErrorsChange?.(filteredValidationResult.errors);
    }
  }, [filteredValidationResult, invalidExchangeDefsErrors, onValidationErrorsChange, previousInvalidExchangeDefsErrors]);

  return (
    <ValidationContext.Provider value={filteredValidationResult}>
      <GlobalDatePickerStyles zIndex={203} />
      <DefaultEditableGridStyles
        style={{ width: '100%', height: height || `min(100vh - ${viewportHeightDelta}px, ${maxGridHeight}px)` }}
      >
        <EditableGrid
          frozenLeftColumnIds={frozenLeftColumnIds}
          columns={columnsWithRowNumber}
          rowData={filteredExchanges as any}
          bodyPaddingBottom={bodyPaddingBottom}
          onGridClipboardEvent={handleGridClipboardEvent}
          navigableRange={navigableRange}
          onActiveRowChange={(rowIndex) => {
            onSelectedExchangeChange?.(filteredExchanges[rowIndex - 1]);
          }}
          highlightSelectionInHeaders
        />
      </DefaultEditableGridStyles>
    </ValidationContext.Provider>
  );
};
