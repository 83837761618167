import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text, Heading } from 'rebass/styled-components';
import { Draft, PageRole } from '@deepstream/common/rfq-utils';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { RfqIdProvider } from '../../useRfq';
import { DraftEvaluationEnabled } from './DraftEvaluationEnabled';
import * as rfx from '../../rfx';
import { useModalState } from '../../ui/useModalState';
import { EnableEvaluationModal } from './EnableEvaluationModal';

interface DraftEvaluationProps {
  rfqId: string;
  pageId?: string;
}

const DraftEvaluationContent = ({ rfqId, pageId }: DraftEvaluationProps) => {
  const { t } = useTranslation('evaluation');
  const enableEvaluationModal = useModalState();
  const { settings } = rfx.useStructure<Draft>();
  const rfxPermissions = rfx.useRfxPermissions();

  return (
    <>
      <RfqIdProvider rfqId={rfqId}>
        {settings.isEvaluationEnabled ? (
          <DraftEvaluationEnabled pageId={pageId} />
        ) : (
          <Flex flexDirection="column" height="100%">
            <Heading as="h1" fontSize={6} lineHeight="normal" fontWeight="500" mb={4}>
              {t('title')}
            </Heading>
            {rfxPermissions.canManageEvaluation ? (
              <>
                <Text mb={4} sx={{ maxWidth: '500px' }}>{t('disabled.enableDescription')}</Text>
                <Button sx={{ width: '150px' }} variant="primary" onClick={enableEvaluationModal.open}>
                  {t('disabled.enableButton')}
                </Button>
              </>
            ) : (
              <Text mb={4} sx={{ maxWidth: '500px' }}>{t('disabled.ownersCanEnableDescription')}</Text>
            )}
          </Flex>
        )}
      </RfqIdProvider>
      <EnableEvaluationModal {...enableEvaluationModal} />
    </>
  );
};

export const DraftEvaluation = ({ rfqId, pageId }: DraftEvaluationProps) => {
  const { isTemplate } = rfx.useState();
  const isSuperUserOrOwner = rfx.useIsSuperUserOrOwner();

  const rfxOverrides: rfx.UserOverrides = useMemo(() => {
    if (isTemplate || isSuperUserOrOwner) {
      // Templates should be editable by all members of a company but the template
      // structure doesn't contain ownership / team membership information in
      // `teamById` which we use to determine page permissions so we provide
      // overrides here.
      // Owners can have page roles lower than PageRole.EDITOR for new evaluation
      // pages; they should nonetheless be allowed to edit drafts so we override
      // their actual page role with PageRole.EDITOR here.
      return { isOwner: true, pageRole: PageRole.EDITOR };
    } else {
      return {};
    }
  }, [isTemplate, isSuperUserOrOwner]);

  return (
    <rfx.OverridesProvider {...rfxOverrides}>
      <DraftEvaluationContent rfqId={rfqId} pageId={pageId} />
    </rfx.OverridesProvider>
  );
};
