import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text, Flex, Link } from 'rebass/styled-components';
import { first } from 'lodash';
import { AxiosError } from 'axios';
import { PageRole, RfqStatus } from '@deepstream/common/rfq-utils';
import { useQuery } from 'react-query';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Panel, PanelDivider, PanelHeader } from '@deepstream/ui-kit/elements/Panel';
import { slugify } from '@deepstream/utils/slugify';
import { StagesTable } from '../../../ReadOnlyStages';
import { LoadingPanel } from '../../../ui/Loading';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import { SummaryPanels } from '../../../draft/SummaryPanels';
import { useCurrentCompanyId, CurrentCompanyIdContext } from '../../../currentCompanyId';
import { RfqIdProvider, usePublicRfqStructure, FAKE_COMPANY_ID } from '../../../useRfq';
import * as rfx from '../../../rfx';
import { RequestPages } from '../RequestPages';
import { PageHeading } from '../../../page-headers/PageHeading';
import { RequestStatus } from '../../../RequestStatus';
import { HeadingRow } from '../../../page-headers/HeadingRow';
import { PublicRequestBidIntentionPanel } from './PublicRequestBidIntentionPanel';
import { Bold } from '../../../Bold';
import { useDeadline } from '../../../deadline';
import { useApi, wrap } from '../../../api';
import { PageNotFound } from '../../../PageNotFound';
import { PublicBidIntention } from '../../../types';
import { ModelSizeLimitsProvider } from '../../../modelSizeLimits';
import * as Layout from '../../../Layout';
import { useNavigate, useSearch } from '../../../tanstackRouter';
import { legacyRequestReceivedIndexRoute, publicRequestRoute, publicRequestSlugRoute } from '../../../AppRouting';
import { usePublicRequestPreviewBanner } from './PublicRequestPreviewBanner';

/**
 * Used in both SSR and client-rendered contexts
 */
export const PublicRequestLayout = ({
  isPublicRoute,
  publicBidIntention,
  navigateToRequestReceivedDetails,
}: {
  isPublicRoute?: boolean;
  publicBidIntention?: PublicBidIntention;
  navigateToRequestReceivedDetails?: () => void;
}) => {
  const { t } = useTranslation('translation');

  const rfxStructure = rfx.useStructure();
  const { stages } = rfxStructure;
  const currentStage = first(stages);
  // @ts-expect-error ts(18048) FIXME: 'currentStage' is possibly 'undefined'.
  const stageDeadline = rfx.useStageDeadline(currentStage._id);
  // @ts-expect-error ts(2322) FIXME: Type 'Date | null' is not assignable to type 'Date | undefined'.
  const { hasPassed } = useDeadline({ deadline: stageDeadline });

  return (
    <Layout.HeaderAndContent
      header={
        <Layout.PageHeaderWrapper>
          <HeadingRow>
            <Flex flexDirection="column" sx={{ rowGap: 1 }}>
              {isPublicRoute && (
                <Link href="/requests" display="inline-block">
                  <IconText
                    icon="angle-left"
                    text={t('requests.allPublicRequests')}
                    fontSize={1}
                    fontWeight={500}
                    color="primary"
                  />
                </Link>
              )}
              <PageHeading
                icon="file-text-o"
                text={rfxStructure?.summary?.subject}
              />
            </Flex>
            <RequestStatus
              // @ts-expect-error ts(2322) FIXME: Type 'RfqStatus | null' is not assignable to type 'RfqStatus'.
              status={hasPassed ? RfqStatus.CLOSED : rfxStructure?.status}
            />
          </HeadingRow>
        </Layout.PageHeaderWrapper>
      }
      content={
        !hasPassed ? (
          // TODO instead of providing dummy model size limits here, better
          // refactor document (and other?) section panel components to only
          // depend on model sizes in edit mode
          <ModelSizeLimitsProvider maxComplexity={0} maxExchangeDefCount={0}>
            <PublicRequestBidIntentionPanel
              // @ts-expect-error ts(2322) FIXME: Type 'Date | null' is not assignable to type 'Date'.
              deadline={stageDeadline}
              publicBidIntention={publicBidIntention}
              isPublicRoute={isPublicRoute}
              navigateToRequestReceivedDetails={navigateToRequestReceivedDetails}
            />
            <SummaryPanels hideCurrency />
            <Box mb="20px">
              <Panel>
                <PanelHeader
                  heading={t('general.stage_other')}
                  collapse={false}
                />
                <PanelDivider />
                <StagesTable />
              </Panel>
            </Box>

            <RequestPages />
          </ModelSizeLimitsProvider>
        ) : (
          <Flex flexDirection="column" sx={{ rowGap: '20px' }}>
            <Flex alignItems="center" fontSize="21px">
              <Icon fixedWidth color="subtext" icon="times" mr={1} />
              <Bold>{t('request.intention.publicRequestClosedTitle')}</Bold>
            </Flex>
            <Text fontSize={2}>
              {t('request.intention.publicRequestClosedDescription')}
            </Text>
          </Flex>
        )
      }
    />
  );
};

/**
 * Not used for SSR
 */
export const PublicRequestContainer = ({
  rfqId,
  rfqSlug,
}: {
  rfqId: string;
  rfqSlug?: string;
}) => {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const api = useApi();

  const { isPublicRequestPreview } = rfx.useState();

  const {
    data: rfxStructure,
    isLoading: isLoadingRfxStructure,
    isError: isErrorRfxStructure,
    error: errorRfxStructure,
    isSuccess: isSuccessRfxStructure,
  } = usePublicRfqStructure({
    rfqId,
    isPreview: isPublicRequestPreview,
  });

  const currentCompanyId = useCurrentCompanyId();
  const {
    data: publicBidIntention,
    isLoading: isLoadingPublicBidIntention,
    isError: isErrorPublicBidIntention,
    error: errorPublicBidIntention,
    isSuccess: isSuccessPublicBidIntention,
  } = useQuery(
    ['publicBidIntention', { rfqId, currentCompanyId }],
    wrap(api.getPublicBidIntention),
  );

  const isSuccess = isSuccessRfxStructure && isSuccessPublicBidIntention;
  const isLoading = isLoadingRfxStructure || isLoadingPublicBidIntention;
  const isError = isErrorRfxStructure || isErrorPublicBidIntention;
  const error = errorRfxStructure || errorPublicBidIntention;

  const rfxOverrides = { isOwner: false, pageRole: PageRole.READER };

  useEffect(
    () => {
      if (isSuccess && rfxStructure?.summary?.subject && !rfqSlug) {
        navigate({
          to: publicRequestSlugRoute.to,
          params: {
            rfqId,
            slug: slugify(rfxStructure?.summary?.subject),
          },
          replace: true,
        });
      }
    },
    [rfqSlug, isSuccess, rfxStructure?.summary?.subject, navigate, rfqId, currentCompanyId],
  );

  return (
    <RfqIdProvider rfqId={rfqId}>
      {isLoading ? (
        <LoadingPanel />
      ) : isError ? (
        // When a request is private, we return a 403 error
        (error as AxiosError)?.response?.status === 403 ? (
          <PageNotFound />
        ) : (
          <ErrorPanel error={t('errors.unexpected')} />
        )
      ) : isSuccess && rfxStructure ? (
        <rfx.StructureProvider structure={rfxStructure}>
          <rfx.OverridesProvider {...rfxOverrides}>
            <PublicRequestLayout
              publicBidIntention={publicBidIntention}
              navigateToRequestReceivedDetails={() => (
                navigate({
                  to: legacyRequestReceivedIndexRoute.to,
                  // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
                  params: { currentCompanyId, rfqId },
                })
              )}
            />
          </rfx.OverridesProvider>
        </rfx.StructureProvider>
      ) : null}
    </RfqIdProvider>
  );
};

/**
 * Not used for SSR
 */
export const PublicRequestPage = ({
  rfqId,
  rfqSlug,
  currentCompanyId,
}: {
  rfqId: string;
  rfqSlug?: string;
  currentCompanyId: string;
}) => {
  const publicRequestPreviewBanner = usePublicRequestPreviewBanner();

  const { isPreview = false } = useSearch({
    from: rfqSlug ? publicRequestSlugRoute.id : publicRequestRoute.id,
  });

  useEffect(
    () => {
      if (isPreview && !publicRequestPreviewBanner.isVisible) {
        publicRequestPreviewBanner.setIsVisible(true);
      }

      return () => {
        if (publicRequestPreviewBanner.isVisible) {
          publicRequestPreviewBanner.setIsVisible(false);
        }
      };
    },
    [isPreview, publicRequestPreviewBanner],
  );

  return (
    <rfx.StateProvider isPublicRequestPreview={isPreview} isLive>
      <CurrentCompanyIdContext.Provider value={currentCompanyId ?? FAKE_COMPANY_ID}>
        <PublicRequestContainer
          rfqId={rfqId}
          rfqSlug={rfqSlug}
        />
      </CurrentCompanyIdContext.Provider>
    </rfx.StateProvider>
  );
};
