import * as React from 'react';
import { compact, find, first, groupBy, map, orderBy, mapValues, filter, isEmpty } from 'lodash';
import { PageType, SectionType, ActionType, ExchangeStatus, MessagesSectionType } from '@deepstream/common/rfq-utils';
import { Trans, useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import { omitNil } from '@deepstream/utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { InlineButton } from '@deepstream/ui-kit/elements/button/InlineButton';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { RfxExchangeModal } from '../../ExchangeModal/RfxExchangeModal';
import { Bulletins } from './Bulletins';
import { ErrorPanel } from '../../ui/ErrorMessage';
import { LoadingPanel } from '../../ui/Loading';
import { useModalState } from '../../ui/useModalState';
import { ExchangeNavigationProvider, useExchangeModalState } from '../../useExchangeModalState';
import { useRfqId, RecipientIdProvider, useLiveRfqStructure } from '../../useRfq';
import * as rfx from '../../rfx';
import { Tab, TabListPanel, TabPanels, TabPanel, Tabs } from '../../ui/TabsVertical';
import { SidebarLayout } from '../../ui/ProfileLayout';
import { useDeviceSize } from '../../ui/useDeviceSize';
import { NotificationCounter } from '../Notifications/NotificationCounter';
import { ExchangeSnapshot } from '../../types';
import { Bold } from '../../Bold';
import { ExchangeDefFieldValueProvider } from '../../ExchangeDefFieldValueContext';
import { RequestHooksProvider } from '../Request/RequestHooksProvider';
import { useBulletinSectionExchangeDefs } from './useBulletins';
import { useAllExchanges } from '../../useAllExchanges';
import { requestSentMessagesRoute } from '../../AppRouting';
import { useRequestSentNavigation } from '../../appNavigation';
import { useNavigate } from '../../tanstackRouter';
import { MessagesReportModal } from './MessagesReportModal';
import { Clarifications } from './Clarifications';
import { Chats } from './Chats';

const sortByStatusAndOpenDate = (exchanges: ExchangeSnapshot[] = []) =>
  orderBy(
    exchanges,
    [
      exchange => exchange.status === ExchangeStatus.CLOSED || exchange.status === ExchangeStatus.RESOLVED ? 1 : 0,
      exchange => exchange.history[0].date,
    ],
    [
      'asc',
      'desc',
    ],
  );

const getCommentFilter = (exchanges: any[]) => {
  const exchangeIds = map(exchanges, '_id');

  return map(
    exchangeIds,
    exchangeId => ({
      domain: NotificationDomain.RFQ_SENT,
      action: NotificationAction.EXCHANGE_REPLY_SENT,
      meta: {
        exchangeId,
        actionType: ActionType.NONE,
      },
    }),
  );
};

const RequestMessagesContent = ({
  exchangeId,
  recipientId,
  sectionType,
}: {
  exchangeId?: string;
  recipientId?: string;
  sectionType?: SectionType.CLARIFICATIONS | SectionType.CHAT | SectionType.BULLETINS;
}) => {
  const rfqId = useRfqId();
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { isExtraSmall, isSmall } = useDeviceSize();
  const navigate = useNavigate();
  const navigation = useRequestSentNavigation();
  const messagesReportModal = useModalState();

  const exchangeModal = useExchangeModalState({
    exchangeId,
    recipientId,
  });

  const { data: structure, status: structureStatus } = useLiveRfqStructure({
    rfqId,
    currentCompanyId,
  });

  // TODO only request relevant exchanges
  const { data: exchanges, status: exchangesStatus, updateCachedExchange } = useAllExchanges({
    rfqId,
    currentCompanyId,
  });

  const bulletinSection = find(structure?.sectionById, { type: SectionType.BULLETINS });
  const { data: bulletinExchangeDefs, status: bulletinExchangeDefsStatus } = useBulletinSectionExchangeDefs({
    rfqId,
    currentCompanyId,
    sectionId: bulletinSection?._id,
  });

  const isSuccess = (
    structureStatus === 'success' &&
    exchangesStatus === 'success' &&
    (!bulletinSection?._id || bulletinExchangeDefsStatus === 'success')
  );

  const chatPage = structure && structure.pages.find(page => page.type === PageType.CHAT);
  const sectionsByType = mapValues(
    groupBy(structure?.sectionById, section => section.type),
    // @ts-expect-error ts(18048) FIXME: 'chatPage' is possibly 'undefined'.
    sections => filter(sections, section => chatPage.sections.includes(section._id)),
  );
  const chatSection = first(sectionsByType.chat);
  const clarificationsSection = first(sectionsByType.clarifications);
  const bulletinsSection = first(sectionsByType.bulletins);

  const sectionTypes = compact([
    bulletinsSection ? SectionType.BULLETINS : null,
    clarificationsSection ? SectionType.CLARIFICATIONS : null,
    SectionType.CHAT,
  ] as const);

  const { chats, clarifications } = React.useMemo(
    () => {
      if (!exchanges) {
        return {};
      }

      return {
        chats: sortByStatusAndOpenDate(
          // @ts-expect-error ts(18048) FIXME: 'chatSection' is possibly 'undefined'.
          exchanges.filter(exchange => chatSection.exchangeDefIds.includes(exchange._id)),
        ),
        clarifications: sortByStatusAndOpenDate(
          // @ts-expect-error ts(18048) FIXME: 'clarificationsSection' is possibly 'undefined'.
          exchanges.filter(exchange => clarificationsSection.exchangeDefIds.includes(exchange._id)),
        ),
      };
    },
    [exchanges, chatSection, clarificationsSection],
  );

  const modalSectionType = React.useMemo(
    () => {
      if (!exchanges) return null;

      if (exchangeId && recipientId) {
        const exchange = find(exchanges, { _id: exchangeId });

        if (!exchange || exchange.recipientId !== recipientId) {
          return null;
        }

        // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
        return structure?.sectionById[exchange.def.sectionId]?.type;
      }

      return null;
    },
    [exchangeId, exchanges, recipientId, structure?.sectionById],
  );

  React.useEffect(
    () => {
      if (isSuccess && !sectionType) {
        const defaultSectionType = sectionTypes[0];

        navigation.navigateToMessages(defaultSectionType, true);
      }
    },
    [isSuccess, modalSectionType, navigation, sectionType, sectionTypes],
  );

  const exchangeModalSection = React.useMemo(
    () => {
      if (!modalSectionType) return null;

      switch (modalSectionType) {
        case SectionType.BULLETINS:
          return bulletinsSection;
        case SectionType.CHAT:
          return chatSection;
        case SectionType.CLARIFICATIONS:
          return clarificationsSection;
      }
    },
    [bulletinsSection, chatSection, clarificationsSection, modalSectionType],
  );

  const clarificationNotificationFilter = React.useMemo(
    () => clarifications
      ? getCommentFilter(clarifications)
      : undefined,
    [clarifications],
  );

  const chatNotificationFilter = React.useMemo(
    () => chats
      ? getCommentFilter(chats)
      : undefined,
    [chats],
  );

  const bulletinsSectionNotificationFilter = React.useMemo(
    () => bulletinExchangeDefs
      ? getCommentFilter(bulletinExchangeDefs)
      : undefined,
    [bulletinExchangeDefs],
  );

  const getSectionTypeIndex = (sectionType: any) => sectionTypes.indexOf(sectionType);
  const getSectionType = (index: number) => sectionTypes[index];

  const navigateToExchange = React.useCallback(
    ({ recipientId, exchangeId }: { recipientId?: string; exchangeId?: string }) => {
      return navigate({
        search: search => omitNil({ ...search, recipientId, exchangeId }),
      });
    },
    [navigate],
  );

  const closeExchange = React.useCallback(
    () => navigateToExchange({ exchangeId: undefined, recipientId: undefined }),
    [navigateToExchange],
  );

  const showMessagesButton = !isEmpty(chats) || !isEmpty(clarifications) || !isEmpty(bulletinExchangeDefs);

  return structureStatus === 'loading' || exchangesStatus === 'loading' ? (
    <LoadingPanel />
  ) : structureStatus === 'error' || exchangesStatus === 'error' ? (
    <ErrorPanel error={t('errors.unexpected')} />
  ) : structureStatus === 'success' && exchangesStatus === 'success' && structure && exchanges && chatPage ? (
    // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
    <RecipientIdProvider recipientId={recipientId}>
      <ExchangeNavigationProvider navigateToExchange={navigateToExchange}>
        <RequestHooksProvider>
          <rfx.StructureProvider structure={structure}>
            <ExchangeDefFieldValueProvider>
              <rfx.ExchangesProvider exchanges={exchanges}>
                <rfx.RecipientsProvider recipients={structure.recipients}>
                  <rfx.PageProvider page={chatPage}>
                    <Tabs
                      index={getSectionTypeIndex(sectionType)}
                      onChange={index => navigation.navigateToMessages(getSectionType(index))}
                    >
                      <SidebarLayout
                        sidebar={
                          <>
                            <TabListPanel heading={t('request.messages')}>
                              {Boolean(bulletinsSection) && (
                                <Tab data-test="bulletins-tab">
                                  <Flex alignItems="center">
                                    <Icon icon="list-ul" mr={2} fixedWidth />
                                    <Text flex={1}>{t('request.bulletin.bulletin_other')}</Text>
                                    <NotificationCounter filter={bulletinsSectionNotificationFilter} ml={1} />
                                  </Flex>
                                </Tab>
                              )}
                              {Boolean(clarificationsSection) && (
                                <Tab data-test="clarification-tab">
                                  <Flex alignItems="center">
                                    <Icon icon="question" mr={2} fixedWidth />
                                    <Text flex={1}>{t('request.clarifications.clarification_other')}</Text>
                                    <NotificationCounter filter={clarificationNotificationFilter} ml={1} />
                                  </Flex>
                                </Tab>
                              )}
                              {Boolean(chatSection) && (
                                <Tab data-test="chat-tab">
                                  <Flex alignItems="center">
                                    <Icon icon="comment-o" mr={2} fixedWidth />
                                    <Text flex={1}>{t('request.chats.chat')}</Text>
                                    <NotificationCounter filter={chatNotificationFilter} ml={1} />
                                  </Flex>
                                </Tab>
                              )}
                            </TabListPanel>

                            {showMessagesButton && (
                              <Button
                                iconLeft="file-archive"
                                variant="secondary"
                                sx={{
                                  width: 'fit-content',
                                  margin: '0 auto',
                                }}
                                onClick={messagesReportModal.open}
                              >
                                {t('request.messagesReport')}
                              </Button>
                            )}
                          </>
                        }
                        main={
                          <TabPanels>
                            {Boolean(bulletinsSection) && (
                              <TabPanel>
                                <Bulletins
                                  exchangeDefs={bulletinExchangeDefs}
                                  // @ts-expect-error ts(18048) FIXME: 'bulletinsSection' is possibly 'undefined'.
                                  sectionId={bulletinsSection._id}
                                />
                              </TabPanel>
                            )}
                            {Boolean(clarificationsSection) && (
                              <TabPanel>
                                <Clarifications
                                  exchanges={clarifications || []}
                                  // @ts-expect-error ts(18048) FIXME: 'clarificationsSection' is possibly 'undefined'.
                                  sectionId={clarificationsSection._id}
                                />
                              </TabPanel>
                            )}
                            {Boolean(chatSection) && (
                              <TabPanel>
                                <Chats
                                  exchanges={chats || []}
                                  // @ts-expect-error ts(18048) FIXME: 'chatSection' is possibly 'undefined'.
                                  sectionId={chatSection._id}
                                />
                              </TabPanel>
                            )}
                          </TabPanels>
                        }
                        sidebarStyle={!isExtraSmall && !isSmall ? { maxWidth: '232px', flex: '0 0 auto' } : undefined}
                        mainStyle={!isExtraSmall && !isSmall ? { flex: '1 1 auto' } : undefined}
                      />
                    </Tabs>
                    {exchangeModalSection && (
                      <RfxExchangeModal
                        {...exchangeModal}
                        showRecipient
                        close={() => closeExchange()}
                        onLoad={exchange => {
                          const { sectionId } = exchange.def;

                          if (sectionId) {
                            const sectionType = structure?.sectionById[sectionId]?.type as MessagesSectionType;

                            navigate({
                              from: requestSentMessagesRoute.to,
                              to: requestSentMessagesRoute.to,
                              search: search => ({ ...search, sectionType }),
                            });
                          }
                        }}
                        onUpdate={updateCachedExchange}
                      />
                    )}
                    {messagesReportModal.isOpen && (
                      <MessagesReportModal
                        isOpen={messagesReportModal.isOpen}
                        onCancel={messagesReportModal.close}
                        rfqId={rfqId}
                      />
                    )}
                  </rfx.PageProvider>
                </rfx.RecipientsProvider>
              </rfx.ExchangesProvider>
            </ExchangeDefFieldValueProvider>
          </rfx.StructureProvider>
        </RequestHooksProvider>
      </ExchangeNavigationProvider>
    </RecipientIdProvider>
  ) : (
    null
  );
};

export const RequestMessagesContentWrapper = ({
  exchangeId,
  recipientId,
  sectionType,
}: {
  exchangeId?: string;
  recipientId?: string;
  sectionType?: SectionType.CLARIFICATIONS | SectionType.CHAT | SectionType.BULLETINS;
}) => {
  const { t } = useTranslation();
  const pagePermissions = rfx.usePagePermissions({ required: true });
  const navigation = useRequestSentNavigation();

  return pagePermissions.canRead ? (
    <RequestMessagesContent
      exchangeId={exchangeId}
      recipientId={recipientId}
      sectionType={sectionType}
    />
  ) : (
    <>
      <Bold as="div" fontSize={5} mt={1} mb={3}>
        <Icon icon="eye-slash" mr={2} />
        {t('request.pages.pageNotVisible')}
      </Bold>
      <Text mb={1}>
        {t('request.pages.pageNotVisible1')}
      </Text>
      <Text>
        <Trans i18nKey="request.pages.permissionsCanBeUpdatedByRequestOwner">
          Permissions can be updated by a request owner on
          the <InlineButton onClick={() => navigation.navigateToTeam()}>Team</InlineButton> tab.
        </Trans>
      </Text>
    </>
  );
};

export const RequestMessages = ({
  exchangeId,
  recipientId,
  sectionType,
}: {
  exchangeId?: string;
  recipientId?: string;
  sectionType?: SectionType.CLARIFICATIONS | SectionType.CHAT | SectionType.BULLETINS;
}) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqId = useRfqId();
  const { t } = useTranslation('translation');

  const { data: structure, isLoading, isError, isSuccess } = useLiveRfqStructure({
    rfqId,
    currentCompanyId,
  });

  return isLoading ? (
    <LoadingPanel />
  ) : isError ? (
    <ErrorPanel error={t('errors.unexpected')} />
  ) : isSuccess && structure ? (
    <rfx.StructureProvider structure={structure}>
      {/*
       // @ts-expect-error ts(2322) FIXME: Type 'Page | undefined' is not assignable to type 'Page'. */}
      <rfx.PageProvider page={structure.pages.find(page => page.type === PageType.CHAT)}>
        <RequestMessagesContentWrapper
          exchangeId={exchangeId}
          recipientId={recipientId}
          sectionType={sectionType}
        />
      </rfx.PageProvider>
    </rfx.StructureProvider>
  ) : (
    null
  );
};
