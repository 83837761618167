import * as React from 'react';
import { CellProps } from 'react-table';
import { Flex } from 'rebass/styled-components';
import { GridQuestionExchangeDefinition, QuestionType } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { EditableGridDataProvider } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { isNil, isString } from 'lodash';
import {
  GridQuestionExpandedViewContainer,
  GridQuestionResponseValidation,
  ReadOnlyQuestionResponseGrid,
  setGridResponseValueInRow,
} from './ui/ExchangeDefsGrid/QuestionResponseGrid';
import { ExchangeSnapshot } from './types';
import { ExpandViewButton } from './modules/Request/Comparison/ExpandViewButton';

export const QuestionGridResponseCell = (
  props: CellProps<ExchangeSnapshot> & {
    column: { EmptyCell?: React.FC<any> };
    showGridPlaceholder?: boolean;
    NonGridCell: React.FC<any>;
    isExpandedView: boolean;
    setIsExpandedView: (isExpandedView: boolean) => void;
  },
) => {
  const {
    row: { original: exchange },
    cell: { value },
    column,
    showGridPlaceholder,
    NonGridCell,
    isExpandedView,
    setIsExpandedView,
  } = props;
  const { t } = useTranslation(['general', 'translation']);
  const exchangeDef = exchange.def as GridQuestionExchangeDefinition;

  if (exchangeDef.questionType !== QuestionType.GRID) {
    return (
      <NonGridCell {...props} />
    );
  }

  const { EmptyCell = EmDash as React.FC<any> } = column;

  const Container = isExpandedView ? GridQuestionExpandedViewContainer : React.Fragment;

  return isString(value) ? (
    <>{value}</>
  ) : isNil(value) ? (
    <EmptyCell exchange={exchange} />
  ) : showGridPlaceholder ? (
    <>{t('request.question.grid.openToViewSupplierResponse', { ns: 'translation' })}</>
  ) : (
    <Container>
      <GridIdPrefixProvider>
        <GridMenuStateProvider>
          <EditableGridDataProvider
            rowData={value?.rows || []}
            setValueInRow={setGridResponseValueInRow}
          >
            <GridQuestionResponseValidation
              columns={exchangeDef.columns}
              rowsConfig={exchangeDef.rowsConfig}
            >
              {isExpandedView && (
                <Flex justifyContent="flex-end" mb="20px">
                  <ExpandViewButton
                    isExpandedView={isExpandedView}
                    setIsExpandedView={setIsExpandedView}
                    shrinkedVariant="secondary-transparent-outline"
                    type="button"
                  />
                </Flex>
              )}
              <ReadOnlyQuestionResponseGrid
                columns={exchangeDef.columns}
                currencyCode={value?.currencyCode}
                isExpandedView={isExpandedView}
                viewportHeightDelta={100}
              />
            </GridQuestionResponseValidation>
          </EditableGridDataProvider>
        </GridMenuStateProvider>
      </GridIdPrefixProvider>
    </Container>
  );
};
