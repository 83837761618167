import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Text } from 'rebass/styled-components';
import { findIndex, isEmpty, reject } from 'lodash';
import { Form, Formik, useField } from 'formik';
import * as yup from 'yup';
import { ContractStatus, Reminder, REMINDERS_PAGE_ID } from '@deepstream/common/contract';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton, Button, EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelHeader, PanelPadding, PanelText } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { PropertyList } from '../../PropertyList';
import { SummaryLabelConfigProvider } from '../../draft/SummaryLabelConfigProvider';
import { useContractData, useContractActions, useContractState, usePagesPermissions, useIsPendingCollaborator } from './contract';
import { useUpdateContractReminders } from './draftContract';
import { FieldContainer } from '../../form/FieldContainer';
import { useModalState } from '../../ui/useModalState';
import { ReminderModal } from './ReminderModal';
import { ReminderDescription, ReminderDescriptionValue } from './ReminderDescription';
import { useDraftContractNavigation } from '../../appNavigation';
import { LeavePageModal } from '../../draft/LeavePageModal';

const panelId = 'reminders';

export const RemindersPanelHeader = ({ heading }: { heading: string }) => {
  const { startEditing } = useContractActions();
  // @ts-expect-error ts(2339) FIXME: Property 'isLive' does not exist on type 'ContractStateContextType | undefined'.
  const { isLive, isAmending, isRevising, isDraft, editingPanelId, isReview, isTemplatePreview } = useContractState();
  const contract = useContractData();
  const isPendingCollaborator = useIsPendingCollaborator();
  const permissionsByPageId = usePagesPermissions();
  const { navigateToReminders } = useDraftContractNavigation();

  const { canEdit: canEditRemindersPage } = permissionsByPageId[REMINDERS_PAGE_ID];

  const isEditingAnyPanel = Boolean(editingPanelId);
  const isEditingThisPanel = editingPanelId && editingPanelId === panelId;

  const isDraftingState = isDraft && !isAmending && !isRevising && !isTemplatePreview;
  const canEdit = isLive
    ? [
      ContractStatus.NEGOTIATION,
      ContractStatus.ACTIVE,
      ContractStatus.AGREED,
      ContractStatus.EXPIRED,
    ].includes(contract.status)
    : isDraftingState;

  return (
    <PanelHeader heading={heading}>
      <Flex alignItems="center">
        {isPendingCollaborator || isEditingThisPanel || !canEdit || !canEditRemindersPage ? (
          null
        ) : isReview ? (
          <EditButton
            small
            type="button"
            variant="primary"
            onClick={() => navigateToReminders()}
          />
        ) : (
          <EditButton
            small
            type="button"
            onClick={() => startEditing(panelId)}
            disabled={isEditingAnyPanel}
          />
        )}
      </Flex>
    </PanelHeader>
  );
};

const NewReminderButton = () => {
  const { t } = useTranslation('contracts');
  const modal = useModalState();
  const [{ value: reminders },, remindersHelper] = useField('reminders');

  const onSave = useCallback(
    (newReminder: Reminder) => {
      remindersHelper.setValue([
        ...reminders,
        newReminder,
      ]);
    },
    [reminders, remindersHelper],
  );

  return (
    <>
      <Button small type="button" iconLeft="plus" variant="secondary" onClick={modal.open}>
        {t('summary.newReminder')}
      </Button>
      {modal.isOpen && (
        <ReminderModal
          isOpen={modal.isOpen}
          close={modal.close}
          onSave={onSave}
        />
      )}
    </>
  );
};

const EditReminderButton = ({ reminder }: { reminder: Reminder }) => {
  const { t } = useTranslation('contracts');
  const modal = useModalState();
  const [{ value: reminders },, remindersHelper] = useField<Reminder[]>('reminders');

  const onSave = useCallback(
    (newReminder: Reminder) => {
      const newReminders = [...reminders];
      const index = findIndex(newReminders, reminder => reminder._id === newReminder._id);
      newReminders[index] = newReminder;

      remindersHelper.setValue(newReminders);
    },
    [reminders, remindersHelper],
  );

  const onDelete = useCallback(
    () => {
      remindersHelper.setValue(reject(reminders, { _id: reminder._id }));
    },
    [reminder, reminders, remindersHelper],
  );

  return (
    <>
      <Button type="button" variant="secondary" onClick={modal.open}>
        {t('summary.editReminder')}
      </Button>
      {modal.isOpen && (
        <ReminderModal
          reminder={reminder}
          isOpen={modal.isOpen}
          close={modal.close}
          onSave={onSave}
          onDelete={onDelete}
        />
      )}
    </>
  );
};

const ConfirmationModal = ({ close }: { close: () => void }) => {
  const { t } = useTranslation(['contracts', 'general']);
  const { navigateToReminders } = useDraftContractNavigation();

  return (
    <Modal onRequestClose={close} isOpen style={{ content: { maxWidth: '550px', minWidth: '550px' } }}>
      <ModalHeader onClose={close}>
        {t('dialog.editLiveReminders.heading')}
      </ModalHeader>
      <ModalBody>
        <Stack gap={3}>
          <MessageBlock variant="info" mt={0}>
            {t('dialog.editLiveReminders.message')}
          </MessageBlock>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <CancelButton onClick={close} mr={2} />
        <Button type="button" onClick={navigateToReminders}>
          {t('dialog.editLiveReminders.goToDraftReminders')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const ContractRemindersPanel = () => {
  const { t } = useTranslation(['contracts', 'general']);
  const { stopEditing } = useContractActions();
  // @ts-expect-error ts(2339) FIXME: Property 'editingPanelId' does not exist on type 'ContractStateContextType | undefined'.
  const { editingPanelId } = useContractState();
  const contract = useContractData();
  const [updateContractReminders] = useUpdateContractReminders();
  const confirmationModal = useModalState();

  const isEditingOtherPanel = editingPanelId && editingPanelId !== panelId;
  const isEditingThisPanel = editingPanelId && editingPanelId === panelId;
  const heading = t('summary.reminder_other');

  const properties = useMemo(
    () => contract.reminders.map(reminder => ({
      name: reminder.name,
      value: reminder,
      truncateLabel: true,
      Component: ReminderDescriptionValue,
    })),
    [contract.reminders],
  );

  return (
    <SummaryLabelConfigProvider>
      <Panel
        as="section"
        aria-label={heading}
        sx={{
          opacity: isEditingOtherPanel ? 0.5 : 1,
          boxShadow: isEditingThisPanel ? '0 0 8px 0 rgba(0, 0, 0, 0.3)' : '',
        }}
      >
        <Formik
          validateOnBlur
          enableReinitialize
          initialValues={{
            reminders: contract.reminders,
          }}
          validationSchema={
            yup.object().shape({
              reminders: yup.array(),
            })
          }
          onSubmit={async (values) => {
            await updateContractReminders(
              {
                reminders: values.reminders,
              },
              {
                onSuccess: stopEditing,
              },
            );
          }}
        >
          {({ isSubmitting, dirty, isValid, resetForm, values }) => (
            <>
              <RemindersPanelHeader heading={heading} />
              <PanelDivider />
              {isEditingThisPanel ? (
                <Form>
                  <Stack gap={20} m={20}>
                    {values.reminders.map(reminder => (
                      <FieldContainer
                        key={reminder._id}
                        label={reminder.name}
                        labelStyle={{ fontSize: 2, lineHeight: '40px' }}
                      >
                        <Flex justifyContent="space-between" alignItems="center" sx={{ gap: 2 }}>
                          <Box flex="1 1 auto">
                            <ReminderDescription reminder={reminder} />
                          </Box>
                          <Box flex="0 0 auto">
                            <EditReminderButton reminder={reminder} />
                          </Box>
                        </Flex>
                      </FieldContainer>
                    ))}
                    {isEmpty(values.reminders) && (
                      <Text color="subtext">
                        {t('summary.noRemindersAdded')}
                      </Text>
                    )}
                  </Stack>
                  <Box m={20}>
                    <NewReminderButton />
                  </Box>
                  <PanelDivider />
                  <PanelPadding>
                    <Flex justifyContent="flex-end">
                      <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
                      <SaveButton disabled={isSubmitting || !dirty || !isValid} />
                    </Flex>
                  </PanelPadding>
                  <LeavePageModal />
                </Form>
              ) : isEmpty(contract.reminders) ? (
                <PanelText color="subtext">
                  {t('summary.noRemindersAdded')}
                </PanelText>
              ) : (
                <PropertyList properties={properties} />
              )}
            </>
          )}
        </Formik>
      </Panel>
      {confirmationModal.isOpen && (
        <ConfirmationModal
          close={confirmationModal.close}
        />
      )}
    </SummaryLabelConfigProvider>
  );
};
