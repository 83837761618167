import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Switch } from '../../../ui/Switch';
import { useApprovalsContext } from '../contract';

import { ApprovalBox } from './ApprovalBox';
import { AcceptApprovalButton } from './AcceptApprovalButton';

export const ApprovalReview = () => {
  // @ts-expect-error ts(2339) FIXME: Property 'toggleDirtyExchanges' does not exist on type '{ onlyDirtyExchanges: boolean; toggleDirtyExchanges: () => void; removeFilter: () => void; } | null'.
  const { toggleDirtyExchanges, onlyDirtyExchanges } = useApprovalsContext();
  const { t } = useTranslation('contracts');

  return (
    <ApprovalBox
      heading={(
        <>
          <Icon icon="exclamation-triangle" color="warning" mr={2} />
          {t('approval.review.heading')}
        </>
      )}
      description={(
        <Flex alignItems="center">
          <Text mr={2}>
            {t('approval.review.description')}
          </Text>
          <Switch
            text={t('approval.review.changesLabel')}
            checked={onlyDirtyExchanges}
            onChange={toggleDirtyExchanges}
          />
        </Flex>
      )}
      action={<AcceptApprovalButton label={t('approval.review.action')} />}
    />
  );
};
