import {
  ChangeType,
  Draft,
  PageUpdatedChange,
  SectionChange,
} from '@deepstream/common/rfq-utils';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Form, Formik } from 'formik';
import { has, isEmpty, sortBy, without } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text, TextProps } from 'rebass/styled-components';
import { MultiStageLineItemsEventType } from '@deepstream/common';
import * as draft from '../../../../draft/draft';
import { CheckboxFieldArray } from '../../../../form/CheckboxField';
import * as rfx from '../../../../rfx';
import { useToaster } from '../../../../toast';
import { StepNavigationBackLink } from '../../../../ui/MultiStepFlow/StepNavigation';
import * as lotPagesLayout from '../../Live/lotPagesLayout';

import { useMultiStepFlowData } from '../../../../ui/MultiStepFlow/MultiStepFlowContext';
import { Direction } from '../../../../ui/MultiStepFlow/types';
import {
  SetupMultiStageResponsesFlowData,
  SetupMultiStageResponsesFlowStep,
  SetupMultiStageResponsesFlowStepType,
} from '../types';
import { useTrackSetupMultiStageResponsesEvent } from '../tracking';

const CheckboxLabel = (props: TextProps) => {
  return (
    <Text
      color="text"
      fontWeight="500"
      fontSize={18}
      ml="12px"
      {...props}
    />
  );
};

export const DeleteSections = () => {
  const { t } = useTranslation('translation');

  const isMutationLoading = draft.useIsMutationLoading();
  const structure = rfx.useStructure<Draft>();
  const pages = Object.values(structure.pageById);
  const pageIdBySectionId = pages.reduce((result, currentPage) => {
    const { _id: pageId, sections } = currentPage;
    sections.forEach((sectionId) => {
      result[sectionId] = pageId;
    });
    return result;
  }, {});

  const toaster = useToaster();

  const { data, submitAndNavigate } = useMultiStepFlowData<
    SetupMultiStageResponsesFlowStepType,
    SetupMultiStageResponsesFlowData
  >();

  const [removeSections] = draft.useSaveDraftChanges({
    getChanges: (sectionIds: string[]) => {
      const sectionsToRemoveByPageId: Record<string, string[]> =
        sectionIds.reduce(
          (result, currentSectionId) => {
            const pageId = pageIdBySectionId[currentSectionId];
            if (has(result, pageId)) {
              result[pageId] = [...result[pageId], currentSectionId];
            } else {
              result[pageId] = [currentSectionId];
            }
            return result;
          },
          {} as Record<string, string[]>,
        );

      const pageUpdatedChanges: PageUpdatedChange[] = Object.entries(
        sectionsToRemoveByPageId,
      ).map(([pageId, sectionsToRemove]) => ({
        type: ChangeType.PAGE_UPDATED,
        page: {
          _id: pageId,
          sections: without(
            structure.pageById[pageId].sections,
            ...sectionsToRemove,
          ),
        },
      }));

      const sectionRemovedChanges: SectionChange[] = sectionIds.map(
        (sectionId) => ({
          type: ChangeType.SECTION_REMOVED,
          sectionId,
        }),
      );

      return [...pageUpdatedChanges, ...sectionRemovedChanges];
    },
    onSuccess: () => submitAndNavigate(null, Direction.FORWARD),
    onError: () =>
      toaster.error(t('request.toaster.sectionRemovedError_other')),
  });

  const { deletableSectionIds } = data.currentStep as Extract<
    SetupMultiStageResponsesFlowStep,
    { type: SetupMultiStageResponsesFlowStepType.DELETE_SECTIONS }
  >;
  const availableSections = useMemo(() => {
    return sortBy(deletableSectionIds
      // Check if the section still exists, as it might have been deleted already
      .filter((id) => Object.keys(structure.sectionById).includes(id))
      .map((id) => ({
        value: id,
        label: structure.sectionById[id].name || t('general.untitled'),
      })), (item) => item.label.toLowerCase());
  }, [deletableSectionIds, structure, t]);

  const onBackClick = useCallback(
    () => submitAndNavigate(null, Direction.BACK),
    [submitAndNavigate],
  );

  const trackFlowEvent = useTrackSetupMultiStageResponsesEvent({
    eventType: MultiStageLineItemsEventType.DELETE_SECTIONS_STEP_COMPLETED,
  });

  const handleSubmit = useCallback(
    async ({ sectionIds }) => {
      if (!isEmpty(sectionIds)) {
        await trackFlowEvent();
        await removeSections(sectionIds);
      } else {
        submitAndNavigate(null, Direction.FORWARD);
      }
    },
    [removeSections, submitAndNavigate, trackFlowEvent],
  );

  return (
    <Formik
      validateOnBlur
      initialValues={{
        sectionIds: [],
      }}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form>
          <lotPagesLayout.ContentWrapper2>
            <StepNavigationBackLink onClick={onBackClick} />
            <lotPagesLayout.Section2
              heading={t(
                'request.setupMultiStageResponsesFlow.steps.deleteSections.heading',
              )}
            >
              <Text mb="6px">
                {t(
                  'request.setupMultiStageResponsesFlow.steps.deleteSections.description1',
                )}
              </Text>
              <Text>
                {t(
                  'request.setupMultiStageResponsesFlow.steps.deleteSections.description2',
                )}
              </Text>
              <lotPagesLayout.Subsection2
                heading={t(
                  'request.setupMultiStageResponsesFlow.steps.deleteSections.action.heading',
                )}
                mt="16px"
              >
                <Text mb="20px">
                  {t(
                    'request.setupMultiStageResponsesFlow.steps.deleteSections.action.description',
                  )}
                </Text>
                <Box sx={{ paddingLeft: '3px' }} >
                  <CheckboxFieldArray
                    name="sectionIds"
                    flexDirection="column"
                    options={availableSections}
                    CheckboxLabel={CheckboxLabel}
                    checkboxStyle={{ padding: '12px 0px' }}
                    withSeparator
                  />
                </Box>
              </lotPagesLayout.Subsection2>
            </lotPagesLayout.Section2>
            <Box>
              <Button
                type="submit"
                disabled={isMutationLoading}
                sx={{ alignSelf: 'flex-start' }}
              >
                {!isEmpty(values.sectionIds)
                  ? t('toolbar.saveAndContinue', { ns: 'request' })
                  : t('continue', { ns: 'general' })}
              </Button>
            </Box>
          </lotPagesLayout.ContentWrapper2>
        </Form>
      )}
    </Formik>
  );
};
