import * as React from 'react';
import { first, isEmpty, isNil, last, omitBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Panel, PanelHeader } from '@deepstream/ui-kit/elements/Panel';
import { DropdownMenu, DropdownMenuDivider, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { Live } from '@deepstream/common/rfq-utils';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { SelectDropdownMenu } from '../../../ui/MultiSelect';
import { useSelectRowHeight } from './useSelectRowHeight';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useCurrentUser, useCurrentUserLocale } from '../../../useCurrentUser';
import { useRfqId } from '../../../useRfq';
import useDownload from '../../../useDownload';
import { useMutation } from '../../../useMutation';
import { useSelectView, View } from './useSelectView';
import * as rfx from '../../../rfx';
import { ExpandViewButton } from './ExpandViewButton';
import { useSelectCurrency } from './useSelectCurrency';
import { useSelectBidStatus } from './useSelectBidStatus';
import { useSelectRecipientOrder } from './useSelectRecipientOrder';
import { useSelectRequirementGroups } from './useSelectRequirementGroups';

export const ComparisonControlsPanel = ({
  availableViews,
  disabled,
  selectView,
  selectRowHeight,
  selectBidStatus,
  selectRecipientOrder,
  selectCurrency,
  isExpandedView,
  setIsExpandedView,
  additionalHeaderContentLeft,
  additionalHeaderContentCenter,
  additionalHeaderContentRight,
  selectRequirementGroups,
}: {
  availableViews: {
    evaluation: boolean;
    'line-items': boolean;
    questions: boolean;
  };
  disabled?: boolean;
  selectView: ReturnType<typeof useSelectView>;
  selectRowHeight: ReturnType<typeof useSelectRowHeight>;
  selectBidStatus?: ReturnType<typeof useSelectBidStatus>;
  selectCurrency?: ReturnType<typeof useSelectCurrency>;
  selectRecipientOrder?: ReturnType<typeof useSelectRecipientOrder>;
  selectRequirementGroups: ReturnType<typeof useSelectRequirementGroups>;
  isExpandedView: boolean;
  setIsExpandedView: (isExpandedView: boolean) => void;
  additionalHeaderContentLeft?: React.ReactNode;
  additionalHeaderContentCenter?: React.ReactNode;
  additionalHeaderContentRight?: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const { settings } = rfx.useStructure<Live>();
  const download = useDownload();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();
  const locale = useCurrentUserLocale();
  const rfqId = useRfqId({ required: true });
  const rfxPermissions = rfx.useRfxPermissions();

  const selectedView = first(selectView.selectedItems)?.value;

  const [downloadComparisonCsv, { isLoading: isDownloadingCsv }] = useMutation(({ full }: { full?: boolean } = {}) => {
    const view = selectedView;

    const fullReportTypes = {
      [View.QUESTIONS]: 'questions-full',
      [View.LINE_ITEMS]: 'line-items-full',
      [View.EVALUATION]: 'evaluation-full',
    };
    const basicReportTypes = {
      [View.QUESTIONS]: 'questions',
      [View.LINE_ITEMS]: 'line-items',
      [View.EVALUATION]: 'evaluation',
    };

    const reportType = full
      // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
      ? fullReportTypes[view]
      // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
      : basicReportTypes[view];

    if (!reportType) {
      return Promise.resolve(true);
    }

    const queryParams = new URLSearchParams(
      omitBy({
        csvSeparator: user.preferences?.csvSeparator,
        currencyCode: view === View.LINE_ITEMS
          ? selectCurrency?.selectedItems[0]?.value
          : null,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        locale,
      }, isNil) as Record<string, string>,
    );

    return download(`/download/company/${currentCompanyId}/rfq/${rfqId}/${reportType}?${queryParams.toString()}`);
  });

  return (
    <Panel flex={0} mb="20px">
      <PanelHeader
        height="56px"
        pt="1px"
        pb="2px"
        heading={(
          <Flex>
            <Box py={1} mr={2}>
              <SelectDropdownMenu
                menuWidth={180}
                menuZIndex={151}
                getButtonText={items => last(items)?.label ?? ''}
                getButtonIcon={items => last(items)?.icon ?? ''}
                {...selectView}
                isItemDisabled={item => !availableViews[item.value]}
              />
            </Box>
            {settings.areLotsEnabled && !isEmpty(selectRequirementGroups.items) && (
              <Flex mr={2} alignItems="center">
                <SelectDropdownMenu
                  menuWidth={220}
                  variant="secondary-outline"
                  menuZIndex={151}
                  disabled={disabled}
                  getButtonText={items => (
                    <IconText
                      icon="grid-2"
                      isIconRegular
                      fontSize={1}
                      text={first(items)?.label ?? t('request.lot_other')}
                    />
                  )}
                  {...selectRequirementGroups}
                />
              </Flex>
            )}
            {selectRecipientOrder && (
              <Flex mr={2} alignItems="center">
                {/*
                 // @ts-expect-error ts(2322) FIXME: Type '{ itemToString: (item: RecipientOrderItem | null) => RecipientOrderItem | null; renderItem: (item: RecipientOrderItem) => Element; ... 7 more ...; disabled: boolean | undefined; }' is not assignable to type 'Omit<MultiSelectProps<any>, "onChange">'. */}
                <SelectDropdownMenu
                  buttonText={t('general.sort')}
                  buttonIcon="sort"
                  variant="secondary-outline"
                  menuZIndex={151}
                  disabled={disabled}
                  {...selectRecipientOrder}
                />
              </Flex>
            )}
            {additionalHeaderContentLeft}
          </Flex>
        )}
        collapse={false}
      >
        <Flex>
          {additionalHeaderContentCenter}
          {selectBidStatus && (
            <Box mr={2}>
              {/*
               // @ts-expect-error ts(2322) FIXME: Type '{ itemToString: (item: SelectBidStatusItem | null) => string; renderItem: (item: SelectBidStatusItem) => Element | null; ... 10 more ...; footer: Element; }' is not assignable to type 'Omit<MultiSelectProps<SelectBidStatusItem>, "onChange">'. */}
              <SelectDropdownMenu
                buttonText={t('request.comparison.bidStatus.dropdownButton')}
                buttonIcon="filter"
                variant="secondary-outline"
                menuWidth={170}
                menuZIndex={151}
                multi
                disabled={disabled}
                footer={(
                  <Text fontSize={0} lineHeight={1.3} color="subtext" p={2} pt={3}>
                    <Icon regular icon="info-circle" mr={1} />
                    {t('request.comparison.bidStatus.dropdownInfo')}
                  </Text>
                )}
                {...selectBidStatus}
              />
            </Box>
          )}
          <Box mr={4}>
            <DropdownMenu
              disabled={disabled || isDownloadingCsv || !rfxPermissions.canDownloadReports}
              variant="secondary-outline"
              menuStyle={{ width: '233px' }}
              menuZIndex={151}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
              small
              buttonText={(
                <Flex>
                  <Text fontSize={1}>{t('general.downloadCSV')}</Text>
                </Flex>
              )}
              iconLeft="download"
              iconRight="caret-down"
              footer={(
                <>
                  <DropdownMenuDivider />
                  <Box mt="15px" mb="10px" mx="10px">
                    <Text fontSize={0} lineHeight={1.3} color="subtext" pb={2}>
                      <Icon icon="info-circle" mr={1} />
                      {t('request.comparison.downloadCsv.dropdownInfo')}
                    </Text>
                  </Box>
                </>
              )}
            >
              <DropdownMenuItem onSelect={() => downloadComparisonCsv({ full: false })}>
                {t('request.comparison.downloadCsv.basic')}
                <Box mt={1}>
                  <Text fontSize={0} lineHeight={1.3} color="subtext"> {t('request.comparison.downloadCsv.basicReportDetails')} </Text>
                </Box>
              </DropdownMenuItem>
              <DropdownMenuItem onSelect={() => downloadComparisonCsv({ full: true })}>
                {t('request.comparison.downloadCsv.full')}
                <Box mt={1}>
                  <Text fontSize={0} lineHeight={1.3} color="subtext"> {t('request.comparison.downloadCsv.fullReportDetails')} </Text>
                </Box>
              </DropdownMenuItem>
            </DropdownMenu>
          </Box>
          {additionalHeaderContentRight}
          <Box mr={2}>
            <SelectDropdownMenu
              buttonText={t('request.comparison.rowHeight')}
              buttonIcon="arrows-v"
              menuWidth={120}
              menuZIndex={151}
              disabled={disabled}
              {...selectRowHeight}
            />
          </Box>
          <ExpandViewButton
            isExpandedView={isExpandedView}
            setIsExpandedView={setIsExpandedView}
          />
        </Flex>
      </PanelHeader>
    </Panel>
  );
};
