import { Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { first } from 'lodash';
import { useLocalStorageState } from '../../../useLocalStorageState';

export type RecipientOrderItem = 'alphabetical' | 'lowestTotalScore' | 'highestTotalScore';

export const useSelectRecipientOrder = ({
  storageKey,
}: {
  storageKey: string;
}) => {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useLocalStorageState<RecipientOrderItem>({
    key: storageKey,
    defaultValue: 'alphabetical',
  });

  return {
    itemToString: (item: RecipientOrderItem | null) => item,
    renderItem: (item: RecipientOrderItem) => (
      <Text key={item}>
        {t(`request.comparison.recipientOrder.${item}`)}
      </Text>
    ),
    items: ['alphabetical', 'lowestTotalScore', 'highestTotalScore'],
    selectedItems: [selectedItem],
    // @ts-expect-error ts(2345) FIXME: Argument of type 'RecipientOrderItem | undefined' is not assignable to parameter of type 'SetStateAction<RecipientOrderItem>'.
    onChange: (items: RecipientOrderItem[]) => setSelectedItem(first(items)),
  };
};
