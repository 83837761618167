import { filter, identity, isEmpty, isNil, map, propertyOf, size, sum } from 'lodash';
import { useCallback, useMemo } from 'react';
import { getWeightedAverageScore, isLinkedEvaluationPage, SectionType, getPagesInDisplayOrder, ExchangeType, Live } from '@deepstream/common/rfq-utils';
import { Box, Flex, Heading } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { Panel, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { ErrorMessage } from './ui/ErrorMessage';
import { Loading } from './ui/Loading';
import { useExchangeModalState, ExchangeNavigationProvider } from './useExchangeModalState';
import { RecipientIdProvider, RfqIdProvider, useLiveRfqStructure, useRecipientId, useRfqExchanges } from './useRfq';
import { EvaluationBidPage } from './Evaluation';
import * as rfx from './rfx';
import { RfxExchangeModal } from './ExchangeModal/RfxExchangeModal';
import { SwitchToExchangeProvider } from './ExchangeModal/SwitchToExchange';
import { Tabs, Tab, TabList, TabPanel, TabPanels } from './ui/Tabs';
import { LabeledNumber, LabeledValue } from './draft/LabeledValue';
import { EvaluationExchangeSnapshot, LineItemsExchangeSnapshot } from './types';
import { EvaluationNavigation } from './EvaluationNavigation';
import { ExchangeDefFieldValueProvider } from './ExchangeDefFieldValueContext';
import { RequestHooksProvider } from './modules/Request/RequestHooksProvider';
import { useEvaluationExchangeSwitcherTargets } from './ExchangeModal/useRfxExchangeSwitcherTargets';
import { useRequestRecipientNavigation, useRequestSentNavigation } from './appNavigation';
import * as lotPagesLayout from './modules/Request/Live/lotPagesLayout';
import { requestBidStatusAllowsScoreSubmissions } from './modules/NewEvaluation/utils';
import { useIsEvaluationActionRequiredByPageId } from './modules/NewEvaluation/useIsEvaluationActionRequiredByPageId';

const NonLinkedEvaluationPageContainer = ({
  pageId,
  exchangeId,
}: {
  pageId: string;
  exchangeId?: string;
}) => {
  const { t } = useTranslation('translation');
  const structure = rfx.useStructure<Live>();
  const recipientId = useRecipientId();
  const sentNavigation = useRequestSentNavigation();
  const recipientNavigation = useRequestRecipientNavigation();

  const { data: exchanges, isSuccess, isError, isLoading } = useRfqExchanges({
    recipientId,
    sectionIds: structure
      ? map(filter(structure.sectionById, { type: SectionType.EVALUATION }), '_id')
      : [],
  });

  const exchangeModal = useExchangeModalState({
    exchangeId,
    recipientId,
  });

  const navigateToExchange = useCallback(({ exchangeId }) => {
    if (structure.newFeaturesDisabled) {
      sentNavigation.navigateToSupplierBidEvaluation(pageId, exchangeId);
    } else {
      recipientNavigation.navigateToEvaluation(pageId, exchangeId);
    }
  }, [structure, sentNavigation, recipientNavigation, pageId]);

  const exchangeSwitcherTargets = useEvaluationExchangeSwitcherTargets();

  return isLoading ? (
    <PanelPadding>
      <Loading />
    </PanelPadding>
  ) : isError ? (
    <PanelPadding>
      <ErrorMessage error={t('general.couldNotGetData')} />
    </PanelPadding>
  ) : isSuccess ? (
    <ExchangeNavigationProvider navigateToExchange={navigateToExchange}>
      <rfx.ExchangesProvider exchanges={exchanges}>
        <rfx.EvaluationWeightsProvider>
          <rfx.PageProvider page={structure.pageById[pageId]}>
            <EvaluationBidPage />
            <SwitchToExchangeProvider
              switchToExchange={(target) => {
                // @ts-expect-error ts(18047) FIXME: 'target' is possibly 'null'.
                exchangeModal.open({ exchangeId: target.exchangeId, recipientId });
                // @ts-expect-error ts(18047) FIXME: 'target' is possibly 'null'.
                navigateToExchange({ exchangeId: target.exchangeId });
              }}
              verticalTargets={exchangeSwitcherTargets}
            >
              <RequestHooksProvider>
                <RfxExchangeModal
                  showExchangeSwitcher
                  showRecipient
                  {...exchangeModal as any}
                  close={() => navigateToExchange({ exchangeId: undefined })}
                />
              </RequestHooksProvider>
            </SwitchToExchangeProvider>
          </rfx.PageProvider>
        </rfx.EvaluationWeightsProvider>
      </rfx.ExchangesProvider>
    </ExchangeNavigationProvider>
  ) : (
    null
  );
};

const LinkedEvaluationPagesHeader = ({
  incompletePageIds,
  isActionRequiredByPageId,
  renderOnLegacyBidPages,
}: {
  incompletePageIds: string[],
  isActionRequiredByPageId: Record<string, boolean>;
  renderOnLegacyBidPages?: boolean;
}) => {
  const { t } = useTranslation();
  const recipientId = useRecipientId();
  const structure = rfx.useStructure<Live>();
  const exchanges = rfx.useExchanges() as (EvaluationExchangeSnapshot | LineItemsExchangeSnapshot)[];
  const {
    absolutePageWeightById,
    relativeSectionWeightById,
    absoluteExchangeDefWeightById,
  } = rfx.useEvaluationWeights();

  const {
    totalScore,
    canSubmitScore,
  } = useMemo(() => {
    const nonObsoleteExchanges = exchanges.filter((exchange): exchange is EvaluationExchangeSnapshot => (
      exchange.def.type === ExchangeType.EVALUATION_CRITERION &&
      !exchange.isObsolete
    ));

    const averageScores = nonObsoleteExchanges.map(exchange =>
      getWeightedAverageScore(exchange, absoluteExchangeDefWeightById[exchange._id]),
    );

    return {
      // @ts-expect-error ts(2345) FIXME: Argument of type 'BidStatus | null' is not assignable to parameter of type 'BidStatus'.
      canSubmitScore: requestBidStatusAllowsScoreSubmissions(structure.bidById[recipientId].status),
      totalScore: averageScores.some(isNil)
        ? null
        : sum(averageScores),
    };
  }, [exchanges, absoluteExchangeDefWeightById, recipientId, structure]);

  const isActionRequired = Object.values(isActionRequiredByPageId).some(identity);

  return (
    <>
      {!renderOnLegacyBidPages && (
        <lotPagesLayout.H3 mt="20px" mb="12px">
          {t('request.evaluation.overallStatus')}
        </lotPagesLayout.H3>
      )}
      <Flex justifyContent="space-between" mb={renderOnLegacyBidPages ? null : '40px'}>
        {renderOnLegacyBidPages && (
          <Heading as="h1" fontSize={6} lineHeight="normal" fontWeight="500">
            {t('request.evaluation.evaluation')}
          </Heading>
        )}
        <Flex alignItems="center">
          <LabeledValue
            label={t('request.evaluation.status.evaluationStatus')}
            value={isActionRequired ? (
              <IconText
                icon="exclamation-circle"
                iconColor="danger"
                text={(t('request.evaluation.status.incomplete'))}
              />
            ) : isEmpty(incompletePageIds) ? (
              <IconText
                icon={canSubmitScore ? 'check-circle' : 'circle'}
                iconColor={canSubmitScore ? 'success' : 'secondary'}
                isIconRegular={!canSubmitScore}
                text={(t('request.evaluation.status.complete'))}
              />
            ) : (
              <IconText
                icon="circle"
                iconColor="secondary"
                isIconRegular
                text={(t('request.evaluation.status.incomplete'))}
              />
            )}
            mr={3}
          />
          <LabeledNumber
            label={t('general.page_other')}
            number={size(absolutePageWeightById)}
            mr={3}
          />
          <LabeledNumber
            label={t('general.section_other')}
            number={size(relativeSectionWeightById)}
            mr={3}
          />
          <LabeledNumber
            label={t('general.criterion_other')}
            number={size(absoluteExchangeDefWeightById)}
            mr={4}
          />
          <LabeledNumber
            label={t('general.totalScore')}
            format="score"
            number={totalScore}
          />
        </Flex>
      </Flex>
    </>
  );
};

const LinkedEvaluationPageContent = ({ exchangeId }: { exchangeId?: string }) => {
  const structure = rfx.useStructure<Live>();
  const page = rfx.usePage();
  const recipientId = useRecipientId();
  const sentNavigation = useRequestSentNavigation();
  const recipientNavigation = useRequestRecipientNavigation();

  const exchangeModal = useExchangeModalState({
    exchangeId,
    recipientId,
  });

  const navigateToExchange = useCallback(({ exchangeId }) => {
    if (structure.newFeaturesDisabled) {
      // @ts-expect-error ts(18047) FIXME: 'page' is possibly 'null'.
      sentNavigation.navigateToSupplierBidEvaluation(page._id, exchangeId);
    } else {
      // @ts-expect-error ts(18047) FIXME: 'page' is possibly 'null'.
      recipientNavigation.navigateToEvaluation(page._id, exchangeId);
    }
  // @ts-expect-error ts(18047) FIXME: 'page' is possibly 'null'.
  }, [structure, sentNavigation, recipientNavigation, page._id]);

  const exchangeSwitcherTargets = useEvaluationExchangeSwitcherTargets();

  return (
    <ExchangeNavigationProvider navigateToExchange={navigateToExchange}>
      <EvaluationBidPage />
      <SwitchToExchangeProvider
        switchToExchange={(target) => {
          // @ts-expect-error ts(18047) FIXME: 'target' is possibly 'null'.
          if (target.pageId === page._id) {
            // @ts-expect-error ts(18047) FIXME: 'target' is possibly 'null'.
            exchangeModal.open({ exchangeId: target.exchangeId, recipientId });
            // @ts-expect-error ts(18047) FIXME: 'target' is possibly 'null'.
            navigateToExchange({ exchangeId: target.exchangeId });
          } else {
            // eslint-disable-next-line no-lonely-if
            if (structure.newFeaturesDisabled) {
              // @ts-expect-error ts(18047) FIXME: 'target' is possibly 'null'.
              sentNavigation.navigateToSupplierBidEvaluation(target.pageId, target.exchangeId);
            } else {
              // @ts-expect-error ts(18047) FIXME: 'target' is possibly 'null'.
              recipientNavigation.navigateToEvaluation(target.pageId, target.exchangeId);
            }
          }
        }}
        verticalTargets={exchangeSwitcherTargets}
      >
        <RequestHooksProvider>
          <RfxExchangeModal
            showExchangeSwitcher
            showRecipient
            {...exchangeModal as any}
            close={() => navigateToExchange({ exchangeId: undefined })}
          />
        </RequestHooksProvider>
      </SwitchToExchangeProvider>
    </ExchangeNavigationProvider>
  );
};

const LinkedEvaluationPagesContainer = ({
  pageId,
  exchangeId,
  renderOnLegacyBidPages,
}: {
  pageId: string;
  exchangeId?: string;
  renderOnLegacyBidPages?: boolean;
}) => {
  const { t } = useTranslation('translation');
  const structure = rfx.useStructure();
  const recipientId = useRecipientId();
  const theme = useTheme();
  const { canManageEvaluation } = rfx.useRfxPermissions();
  const sentNavigation = useRequestSentNavigation();
  const recipientNavigation = useRequestRecipientNavigation();

  const {
    orderedPages,
    incompletePageIds,
  } = useMemo(() => {
    const orderedPages = getPagesInDisplayOrder(structure.pages).filter(isLinkedEvaluationPage);

    const incompletePageIds = orderedPages
      .filter(page => {
        const pageSections = page.sections.map(propertyOf(structure.sectionById));

        return pageSections.some(section =>
          structure.bidById[recipientId].sectionById[section._id].status !== 'complete',
        );
      })
      .map(page => page._id);

    return {
      orderedPages,
      incompletePageIds,
    };
  }, [structure, recipientId]);

  const { data: exchanges, isSuccess, isError, isLoading } = useRfqExchanges({
    recipientId,
    sectionIds: structure
      ? map(filter(structure.sectionById, { type: SectionType.EVALUATION }), '_id')
      : [],
  });

  const exchangesOrEmptyArray = (exchanges || []) as EvaluationExchangeSnapshot[];

  const isActionRequiredByPageId = useIsEvaluationActionRequiredByPageId(
    exchangesOrEmptyArray,
  );

  const onTabsChange = useCallback(index => {
    const pageId = orderedPages[index]?._id;

    if (pageId) {
      if (structure.newFeaturesDisabled) {
        sentNavigation.navigateToSupplierBidEvaluation(pageId);
      } else {
        recipientNavigation.navigateToEvaluation(pageId);
      }
    }
  }, [structure, orderedPages, sentNavigation, recipientNavigation]);

  const selectedTabIndex = orderedPages.findIndex(page => page._id === pageId);

  return isLoading ? (
    <PanelPadding>
      <Loading />
    </PanelPadding>
  ) : isError ? (
    <PanelPadding>
      <ErrorMessage error={t('general.couldNotGetData')} />
    </PanelPadding>
  ) : isSuccess ? (
    <rfx.ExchangesProvider exchanges={exchangesOrEmptyArray}>
      <rfx.EvaluationWeightsProvider>
        <Box>
          {canManageEvaluation && (
            <LinkedEvaluationPagesHeader
              isActionRequiredByPageId={isActionRequiredByPageId}
              incompletePageIds={incompletePageIds}
              renderOnLegacyBidPages={renderOnLegacyBidPages}
            />
          )}
          <Tabs index={selectedTabIndex} onChange={onTabsChange} canOverflow>
            <TabList
              style={{
                backgroundColor: 'inherit',
                fontSize: theme.fontSizes[2],
                marginTop: canManageEvaluation ? '8px' : undefined,
                marginBottom: '32px',
              }}
              scrollLeftBackground="linear-gradient(90deg, rgba(247,249,251,1) 0%, rgba(247,249,251,1) 60%, rgba(247,249,251,0.5) 80%, rgba(247,249,251,0) 100%)"
              scrollRightBackground="linear-gradient(90deg, rgba(247,249,251,0) 0%, rgba(247,249,251,0.5) 20%, rgba(247,249,251,1) 40%, rgba(247,249,251,1) 100%)"
            >
              {orderedPages.map(page => (
                <Tab key={page._id}>
                  <Flex alignItems="center">
                    {structure.pageById[page.linkedPageId]?.name || <EmDash />}
                    {isActionRequiredByPageId[page._id] ? (
                      <Icon
                        icon="exclamation-circle"
                        color="danger"
                        ml={1}
                      />
                    ) : (
                      null
                    )}
                  </Flex>
                </Tab>
              ))}
            </TabList>
            <TabPanels style={{ width: '100%' }}>
              {orderedPages.map((page) => (
                <TabPanel key={page._id}>
                  <rfx.PageProvider page={page}>
                    {page._id === pageId ? (
                      <LinkedEvaluationPageContent exchangeId={exchangeId} />
                    ) : (
                      null
                    )}
                  </rfx.PageProvider>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Box>
      </rfx.EvaluationWeightsProvider>
    </rfx.ExchangesProvider>
  ) : (
    null
  );
};

export const EvaluationContainer = ({
  rfqId,
  recipientId,
  exchangeId,
  pageId,
  renderOnLegacyBidPages,
}: {
  rfqId: string;
  recipientId: string;
  exchangeId?: string;
  pageId: string;
  renderOnLegacyBidPages?: boolean;
}) => {
  const { t } = useTranslation();

  const { data: structure, isLoading, isError, isSuccess } = useLiveRfqStructure({
    rfqId,
    ignoreRecipientId: true,
  });
  const hasLinkedEvaluationPage = structure?.pages.some(isLinkedEvaluationPage);

  return (
    <rfx.StateProvider isLive>
      <RfqIdProvider rfqId={rfqId}>
        <RecipientIdProvider recipientId={recipientId}>
          {isLoading ? (
            <Panel padded>
              <Loading />
            </Panel>
          ) : isError ? (
            <Panel padded>
              <ErrorMessage error={t('errors.unexpected')} />
            </Panel>
          ) : isSuccess && structure ? (
            <rfx.StructureProvider structure={structure}>
              <ExchangeDefFieldValueProvider>
                <rfx.RecipientsProvider recipients={structure.recipients}>
                  {hasLinkedEvaluationPage ? (
                    <LinkedEvaluationPagesContainer
                      pageId={pageId}
                      exchangeId={exchangeId}
                      renderOnLegacyBidPages={renderOnLegacyBidPages}
                    />
                  ) : (
                    <NonLinkedEvaluationPageContainer
                      pageId={pageId}
                      exchangeId={exchangeId}
                    />
                  )}
                  <Box height="80px" />
                  <EvaluationNavigation pageId={pageId} />
                </rfx.RecipientsProvider>
              </ExchangeDefFieldValueProvider>
            </rfx.StructureProvider>
          ) : (
            null
          )}
        </RecipientIdProvider>
      </RfqIdProvider>
    </rfx.StateProvider>
  );
};
