import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass/styled-components';
import { Form, Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { RfqStatus, RfxSpendAndSavings, SavingsCalculationResultByRecipientIdBySavingsType, SavingsType, TotalSavingsCalculationMethod } from '@deepstream/common/rfq-utils';
import { getTotalSavingsSectionStatus } from '@deepstream/common/rfq-utils/spendAndSavings';
import { compact, isFinite, pick, sumBy } from 'lodash';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { withProps } from '@deepstream/ui-utils/withProps';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { MoneyField } from '../../../form/MoneyField';
import { CurrencyAmount } from '../../../ui/Currency';
import { PropertyList, YesNoWithIcon } from '../../../PropertyList';
import * as rfx from '../../../rfx';
import { DraftSectionLabelConfigProvider } from '../../../draft/DraftSectionLabelConfigProvider';
import { YesNoRadioField } from '../../../form/RadioField';
import { useUpdateSpendAndSavings } from './useUpdateSpendAndSavings';
import { SpendPanelHeader } from './SpendPanelHeader';
import { FieldContainer } from '../../../form/FieldContainer';
import { useRfqComputedCostAndSavings, useRfqId } from '../../../useRfq';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { ActionRequiredPlaceholder } from './ActionRequiredPlaceholder';
import { TextField } from '../../../form/TextField';
import { SelectFieldBase } from '../../../form/SelectField';
import { useSavingsCalculationMethodSelectItems } from './useSavingsCalculationMethodSelectItems';
import { CheckboxFieldBase } from '../../../form/CheckboxField';
import { SavingsDiffCalculation } from './SavingsDiffCalculation';
import { LabeledValue } from '../../../draft/LabeledValue';
import { ConfirmFinalValuePlaceholder } from './ConfirmFinalValuePlaceholder';
import { LeavePageModal } from '../../../draft/LeavePageModal';
import { SpecificSavingsPanelContent } from './SpecificSavingsPanels';

const panelId = 'totalSavings';

export const SpecificSavingsCalculation = ({
  value,
  showSpecificSavingsTooltips,
}: {
  value: SavingsCalculationResultByRecipientIdBySavingsType;
  showSpecificSavingsTooltips?: boolean;
}) => {
  const { t } = useTranslation();

  const {
    items,
    total,
  } = React.useMemo(() => {
    const items = Object.entries(value || {}).map(([savingsType, savings]) => {
      return {
        value: sumBy(Object.values(savings), item => item.result),
        label: t(`request.spendAndSavings.specificSaving.${savingsType}.shortTitle`),
        infoTooltip: showSpecificSavingsTooltips
          ? (
            <Box width="700px">
              <SpecificSavingsPanelContent
                savingsType={savingsType as SavingsType}
                savings={savings}
                labelWidth={220}
              />
            </Box>
          )
          : null,
      };
    });

    const total = sumBy(items, item => item.value);

    return {
      items,
      total,
    };
  }, [value, t, showSpecificSavingsTooltips]);

  return (
    <Flex alignItems="center">
      {items.map(({ value, label, infoTooltip }, index) => (
        <React.Fragment key={index}>
          {index !== 0 && '+'}
          <LabeledValue
            label={label}
            value={<CurrencyAmount showCode value={value} />}
            infoTooltip={infoTooltip}
            align="left"
            mr={3}
            ml={index === 0 ? 0 : 3}
          />
        </React.Fragment>
      ))}
      {items.length > 1 && (
        <>
          =
          <Box ml={3} fontWeight={500}>
            <CurrencyAmount showCode value={total} />
          </Box>
        </>
      )}
    </Flex>
  );
};

const TotalSavingsViewPanelContent = ({
  calculatedTotalValue,
  calculatedSavingsByType,
}: {
  calculatedTotalValue?: number | null,
  calculatedSavingsByType: SavingsCalculationResultByRecipientIdBySavingsType,
}) => {
  const { spendAndSavings, status } = rfx.useStructure();
  const { t } = useTranslation();
  const methodSelectItems = useSavingsCalculationMethodSelectItems(
    // @ts-expect-error ts(2345) FIXME: Argument of type 'RfxSpendAndSavings | null | undefined' is not assignable to parameter of type 'RfxSpendAndSavings'.
    spendAndSavings,
    calculatedTotalValue,
    calculatedSavingsByType,
  );
  const hasCalculatedTotalValue = isFinite(calculatedTotalValue);

  const isPanelDisabled = status !== RfqStatus.AWARDED;

  const properties = React.useMemo(() => {
    // @ts-expect-error ts(18049) FIXME: 'spendAndSavings' is possibly 'null' or 'undefined'.
    const adjustedSpendAndSavings = spendAndSavings.totalSavingsCalculationMethod === null
      ? {
        ...spendAndSavings,
        totalSavingsCalculationMethod: methodSelectItems[0].value,
      }
      : spendAndSavings;
    const {
      // @ts-expect-error ts(2339) FIXME: Property 'budgetedTotalValue' does not exist on type 'RfxSpendAndSavings | { totalSavingsCalculationMethod: TotalSavingsCalculationMethod; enabled?: boolean | undefined; hasBudgetedTotalValue?: boolean | undefined; ... 17 more ...; calculatedSavingsByType?: SavingsCalculationResultByRecipientIdBySavingsType | ... 1 more ... | undefined; } | null | undefined'.
      budgetedTotalValue,
      // @ts-expect-error ts(2339) FIXME: Property 'isCalculatedTotalValueAccurate' does not exist on type 'RfxSpendAndSavings | { totalSavingsCalculationMethod: TotalSavingsCalculationMethod; enabled?: boolean | undefined; hasBudgetedTotalValue?: boolean | undefined; ... 17 more ...; calculatedSavingsByType?: SavingsCalculationResultByRecipientIdBySavingsType | ... 1 more ... | undefined; } | null | undefined'.
      isCalculatedTotalValueAccurate,
      // @ts-expect-error ts(2339) FIXME: Property 'manualTotalValue' does not exist on type 'RfxSpendAndSavings | { totalSavingsCalculationMethod: TotalSavingsCalculationMethod; enabled?: boolean | undefined; hasBudgetedTotalValue?: boolean | undefined; ... 17 more ...; calculatedSavingsByType?: SavingsCalculationResultByRecipientIdBySavingsType | ... 1 more ... | undefined; } | null | undefined'.
      manualTotalValue,

      // @ts-expect-error ts(2339) FIXME: Property 'canProvideTotalSavings' does not exist on type 'RfxSpendAndSavings | { totalSavingsCalculationMethod: TotalSavingsCalculationMethod; enabled?: boolean | undefined; hasBudgetedTotalValue?: boolean | undefined; ... 17 more ...; calculatedSavingsByType?: SavingsCalculationResultByRecipientIdBySavingsType | ... 1 more ... | undefined; } | null | undefined'.
      canProvideTotalSavings,
      // @ts-expect-error ts(2339) FIXME: Property 'cannotProvideTotalSavingsReason' does not exist on type 'RfxSpendAndSavings | { totalSavingsCalculationMethod: TotalSavingsCalculationMethod; enabled?: boolean | undefined; hasBudgetedTotalValue?: boolean | undefined; ... 17 more ...; calculatedSavingsByType?: SavingsCalculationResultByRecipientIdBySavingsType | ... 1 more ... | undefined; } | null | undefined'.
      cannotProvideTotalSavingsReason,
      // @ts-expect-error ts(2339) FIXME: Property 'totalSavingsCalculationMethod' does not exist on type 'RfxSpendAndSavings | { totalSavingsCalculationMethod: TotalSavingsCalculationMethod; enabled?: boolean | undefined; hasBudgetedTotalValue?: boolean | undefined; ... 17 more ...; calculatedSavingsByType?: SavingsCalculationResultByRecipientIdBySavingsType | ... 1 more ... | undefined; } | null | undefined'.
      totalSavingsCalculationMethod,
      // @ts-expect-error ts(2339) FIXME: Property 'manualTotalSavings' does not exist on type 'RfxSpendAndSavings | { totalSavingsCalculationMethod: TotalSavingsCalculationMethod; enabled?: boolean | undefined; hasBudgetedTotalValue?: boolean | undefined; ... 17 more ...; calculatedSavingsByType?: SavingsCalculationResultByRecipientIdBySavingsType | ... 1 more ... | undefined; } | null | undefined'.
      manualTotalSavings,
      // @ts-expect-error ts(2339) FIXME: Property 'manualTotalSavingsDescription' does not exist on type 'RfxSpendAndSavings | { totalSavingsCalculationMethod: TotalSavingsCalculationMethod; enabled?: boolean | undefined; hasBudgetedTotalValue?: boolean | undefined; ... 17 more ...; calculatedSavingsByType?: SavingsCalculationResultByRecipientIdBySavingsType | ... 1 more ... | undefined; } | null | undefined'.
      manualTotalSavingsDescription,
      // @ts-expect-error ts(2339) FIXME: Property 'areTotalSavingsAccurate' does not exist on type 'RfxSpendAndSavings | { totalSavingsCalculationMethod: TotalSavingsCalculationMethod; enabled?: boolean | undefined; hasBudgetedTotalValue?: boolean | undefined; ... 17 more ...; calculatedSavingsByType?: SavingsCalculationResultByRecipientIdBySavingsType | ... 1 more ... | undefined; } | null | undefined'.
      areTotalSavingsAccurate,
    } = adjustedSpendAndSavings;

    return compact([
      {
        fieldName: 'description',
        name: t('general.description'),
        value: t('request.spendAndSavings.totalSavingsDescription'),
        labelWidth: 290,
        heightAuto: true,
      },
      {
        fieldName: 'canProvideTotalSavings',
        name: t('request.spendAndSavings.canProvideTotalSavings'),
        value: canProvideTotalSavings,
        Component: withProps(YesNoWithIcon, { placeholder: isPanelDisabled ? <EmDash /> : <ActionRequiredPlaceholder /> }),
        labelWidth: 290,
        heightAuto: true,
      },
      ...(canProvideTotalSavings ? [
          {
          fieldName: 'totalSavingsCalculationMethod',
          name: t('request.spendAndSavings.totalSavingsCalculationMethod'),
          value: totalSavingsCalculationMethod
            ? t(`request.spendAndSavings.totalSavingsCalculationMethods.${totalSavingsCalculationMethod}`)
            : (isPanelDisabled ? <EmDash /> : <ActionRequiredPlaceholder />),
          labelWidth: 290,
          heightAuto: true,
        },
        ...(totalSavingsCalculationMethod === TotalSavingsCalculationMethod.MANUAL ? [
          {
            fieldName: 'manualTotalSavings',
            name: t('request.spendAndSavings.manualTotalSavings'),
            value: manualTotalSavings,
            Component: withProps(CurrencyAmount, {
              showCode: true,
              placeholder: isPanelDisabled ? <EmDash /> : <ActionRequiredPlaceholder />,
            }),
            labelWidth: 290,
            heightAuto: true,
          },
          {
            fieldName: 'manualTotalSavingsDescription',
            name: t('request.spendAndSavings.manualTotalSavingsDescription'),
            value: manualTotalSavingsDescription || (isPanelDisabled ? <EmDash /> : <ActionRequiredPlaceholder />),
            labelWidth: 290,
            heightAuto: true,
          },
          {
            fieldName: 'areTotalSavingsAccurate',
            name: t('request.spendAndSavings.areTotalSavingsAccurate'),
            value: areTotalSavingsAccurate,
            Component: withProps(YesNoWithIcon, {
              showCode: true,
              placeholder: isPanelDisabled ? <EmDash /> : <ActionRequiredPlaceholder />,
            }),
            labelWidth: 290,
            heightAuto: true,
          },
        ] : totalSavingsCalculationMethod === TotalSavingsCalculationMethod.BUDGET_FINAL_VALUE_DIFF ? (
          [
            {
              fieldName: 'calculatedSavings',
              name: t('request.spendAndSavings.calculatedSavings'),
              value: {
                valueA: budgetedTotalValue,
                valueB: (!hasCalculatedTotalValue || isCalculatedTotalValueAccurate === false)
                  ? manualTotalValue
                  : calculatedTotalValue,
              },
              Component: withProps(SavingsDiffCalculation, {
                labelA: t('request.spendAndSavings.budgetedTotalValue'),
                labelB: t('request.spendAndSavings.finalTotalValue'),
              }),
              labelWidth: 290,
              heightAuto: true,
            },
            {
              fieldName: 'areTotalSavingsAccurate',
              name: t('request.spendAndSavings.areTotalSavingsAccurate'),
              value: areTotalSavingsAccurate,
              Component: withProps(YesNoWithIcon, {
                showCode: true,
                placeholder: isPanelDisabled ? (
                  <EmDash />
                ) : !isCalculatedTotalValueAccurate && !isFinite(manualTotalValue) ? (
                  <ConfirmFinalValuePlaceholder />
                ) : (
                  <ActionRequiredPlaceholder />
                ),
              }),
              labelWidth: 290,
              heightAuto: true,
            },
          ]
        ) : totalSavingsCalculationMethod === TotalSavingsCalculationMethod.SUM_SPECIFIC_SAVINGS ? (
          [
            {
              fieldName: 'calculatedSavings',
              name: t('request.spendAndSavings.calculatedSavings'),
              value: calculatedSavingsByType,
              Component: SpecificSavingsCalculation,
              labelWidth: 290,
              heightAuto: true,
            },
            {
              fieldName: 'areTotalSavingsAccurate',
              name: t('request.spendAndSavings.areTotalSavingsAccurate'),
              value: areTotalSavingsAccurate,
              Component: withProps(YesNoWithIcon, {
                showCode: true,
                placeholder: isPanelDisabled ? <EmDash /> : <ActionRequiredPlaceholder />,
              }),
              labelWidth: 290,
              heightAuto: true,
            },
          ]
        ) : (
          []
        )),
        ] : [{
          fieldName: 'cannotProvideTotalSavingsReason',
          name: t('request.spendAndSavings.cannotProvideTotalSavingsReason'),
          value: cannotProvideTotalSavingsReason || (isPanelDisabled ? <EmDash /> : <ActionRequiredPlaceholder />),
          labelWidth: 290,
          heightAuto: true,
        }]),
    ]);
  }, [spendAndSavings, methodSelectItems, t, isPanelDisabled, hasCalculatedTotalValue, calculatedTotalValue, calculatedSavingsByType]);

  return (
    <PropertyList properties={properties} />
  );
};

export const TotalSavingsFields = ({
  values,
  setFieldValue,
  validateForm,
  Padding = PanelPadding,
  Divider = PanelDivider,
  methodSelectItems,
  spendAndSavings,
  calculatedTotalValue,
  calculatedSavingsByType,
  showSpecificSavingsTooltips,
}: {
  Padding?: (props: { children: React.ReactNode }) => React.ReactNode;
  Divider?: React.FC;
  methodSelectItems: {
    value: TotalSavingsCalculationMethod;
    label: string;
  }[];
  spendAndSavings: RfxSpendAndSavings;
  calculatedTotalValue?: number | null;
  calculatedSavingsByType: SavingsCalculationResultByRecipientIdBySavingsType;
  showSpecificSavingsTooltips?: boolean;
} & FormikProps<Partial<RfxSpendAndSavings>>) => {
  const { t } = useTranslation();
  const hasCalculatedTotalValue = isFinite(calculatedTotalValue);

  return (
    <>
      <Padding>
        <YesNoRadioField
          name="canProvideTotalSavings"
          label={t('request.spendAndSavings.canProvideTotalSavings')}
          required
          variant="inline"
          showError
          onChange={async (value) => {
            if (value) {
              await setFieldValue('cannotProvideTotalSavingsReason', null);
              await setFieldValue('totalSavingsCalculationMethod', methodSelectItems[0].value);
            } else {
              await setFieldValue('totalSavingsCalculationMethod', null);
              await setFieldValue('manualTotalSavings', null);
              await setFieldValue('manualTotalSavingsDescription', null);
              await setFieldValue('areTotalSavingsAccurate', null);
            }
            await validateForm();
          }}
          fieldLabelStyle={{ marginBottom: 0 }}
          helperText={values.canProvideTotalSavings ? (
            null
          ) : (
            <MessageBlock variant="warn">
              {t('request.spendAndSavings.noTotalSavingsWarning')}
            </MessageBlock>
          )}
        />
      </Padding>
      <Divider />
      {values.canProvideTotalSavings ? (
        <>
          <Padding>
            <SelectFieldBase
              name="totalSavingsCalculationMethod"
              label={t('request.spendAndSavings.totalSavingsCalculationMethod')}
              required
              items={methodSelectItems}
              buttonStyle={{ width: '250px' }}
              value={values.totalSavingsCalculationMethod}
              onChange={async (calculationMethod) => {
                await setFieldValue('totalSavingsCalculationMethod', calculationMethod);
                await setFieldValue('areTotalSavingsAccurate', null);

                if (calculationMethod !== TotalSavingsCalculationMethod.MANUAL) {
                  await setFieldValue('manualTotalSavings', null);
                  await setFieldValue('manualTotalSavingsDescription', null);
                }
              }}
            />
          </Padding>
          <Divider />
          {values.totalSavingsCalculationMethod === TotalSavingsCalculationMethod.MANUAL ? (
            <>
              <Padding>
                <MoneyField
                  required
                  label={t('request.spendAndSavings.manualTotalSavings')}
                  name="manualTotalSavings"
                  sx={{ width: 200 }}
                  // quick fix: we can remove setting the inputStyle height once a line height is
                  // set for Input
                  inputStyle={{ height: 40 }}
                />
              </Padding>
              <Divider />
              <Padding>
                <TextField
                  name="manualTotalSavingsDescription"
                  label={t('request.spendAndSavings.manualTotalSavingsDescription')}
                  required
                  // quick fix: we can remove setting the inputStyle height once a line height is
                  // set for Input
                  inputStyle={{ height: 40 }}
                />
              </Padding>
              <Divider />
            </>
          ) : values.totalSavingsCalculationMethod === TotalSavingsCalculationMethod.BUDGET_FINAL_VALUE_DIFF ? (
            <>
              <Padding>
                <FieldContainer
                  name="calculatedSavings"
                  label={t('request.spendAndSavings.calculatedSavings')}
                  labelStyle={{ marginBottom: 0 }}
                >
                  <SavingsDiffCalculation
                    labelA={t('request.spendAndSavings.budgetedTotalValue')}
                    labelB={t('request.spendAndSavings.finalTotalValue')}
                    value={{
                      // @ts-expect-error ts(2322) FIXME: Type 'number | null' is not assignable to type 'number | undefined'.
                      valueA: spendAndSavings.budgetedTotalValue,
                      // @ts-expect-error ts(2322) FIXME: Type 'number | null | undefined' is not assignable to type 'number | undefined'.
                      valueB: !hasCalculatedTotalValue || spendAndSavings.isCalculatedTotalValueAccurate === false
                        ? spendAndSavings.manualTotalValue
                        : calculatedTotalValue,
                    }}
                  />
                </FieldContainer>
              </Padding>
              <Divider />
            </>
          ) : values.totalSavingsCalculationMethod === TotalSavingsCalculationMethod.SUM_SPECIFIC_SAVINGS ? (
            <>
              <Padding>
                <FieldContainer
                  name="calculatedSavings"
                  label={t('request.spendAndSavings.calculatedSavings')}
                  labelStyle={{ marginBottom: 0 }}
                >
                  <SpecificSavingsCalculation
                    value={calculatedSavingsByType}
                    showSpecificSavingsTooltips={showSpecificSavingsTooltips}
                  />
                </FieldContainer>
              </Padding>
              <Divider />
            </>
          ) : (
            null
          )}
          {values.totalSavingsCalculationMethod && (
            <>
              <Padding>
                <CheckboxFieldBase
                  name="areTotalSavingsAccurate"
                  label={t('request.spendAndSavings.areTotalSavingsAccurate')}
                  required
                  fieldLabel={t('general.yes')}
                  // @ts-expect-error ts(2322) FIXME: Type 'boolean | null | undefined' is not assignable to type 'boolean | undefined'.
                  value={values.areTotalSavingsAccurate}
                  onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.checked ? true : null;
                    await setFieldValue('areTotalSavingsAccurate', value);
                  }}
                  disabled={(
                    values.totalSavingsCalculationMethod === TotalSavingsCalculationMethod.BUDGET_FINAL_VALUE_DIFF &&
                    !spendAndSavings.isCalculatedTotalValueAccurate &&
                    !isFinite(spendAndSavings.manualTotalValue)
                  )}
                  helperText={(
                    values.totalSavingsCalculationMethod === TotalSavingsCalculationMethod.BUDGET_FINAL_VALUE_DIFF &&
                    !spendAndSavings.isCalculatedTotalValueAccurate &&
                    !isFinite(spendAndSavings.manualTotalValue)
                  ) ? (
                    <ConfirmFinalValuePlaceholder fontSize={1} color="danger" />
                  ) : (
                    <IconText
                      icon="info-circle"
                      isIconRegular
                      fontSize={1}
                      text={t('request.spendAndSavings.areTotalSavingsAccurateInfo')}
                    />
                  )}
                />
              </Padding>
              <Divider />
            </>
          )}
        </>
      ) : (
        <>
          <Padding>
            <TextField
              name="cannotProvideTotalSavingsReason"
              label={t('request.spendAndSavings.cannotProvideTotalSavingsReason')}
              // quick fix: we can remove setting the inputStyle height once a line height is
              // set for Input
              required
              inputStyle={{ height: 40 }}
            />
          </Padding>
          <Divider />
        </>
      )}
    </>
  );
};

const TotalSavingsEditPanelContent = ({
  calculatedTotalValue,
  calculatedSavingsByType,
}: {
  calculatedTotalValue?: number | null,
  calculatedSavingsByType: SavingsCalculationResultByRecipientIdBySavingsType,
}) => {
  const { t } = useTranslation();
  const { spendAndSavings } = rfx.useStructure();
  const { stopEditing } = rfx.useActions();
  const [updateSpendAndSavings] = useUpdateSpendAndSavings();

  // @ts-expect-error ts(18049) FIXME: 'spendAndSavings' is possibly 'null' or 'undefined'.
  const requestHasCalculatedSavingsByType = Boolean(spendAndSavings.calculatedSavingsByType);

  const methodSelectItems = useSavingsCalculationMethodSelectItems(
    // @ts-expect-error ts(2345) FIXME: Argument of type 'RfxSpendAndSavings | null | undefined' is not assignable to parameter of type 'RfxSpendAndSavings'.
    spendAndSavings,
    calculatedTotalValue,
    calculatedSavingsByType,
  );

  const {
    initialValues,
    hasUnsavedMethod,
  } = React.useMemo(() => {
    const initialValues = pick(spendAndSavings, [
      'canProvideTotalSavings',
      'cannotProvideTotalSavingsReason',
      'totalSavingsCalculationMethod',
      'manualTotalSavings',
      'manualTotalSavingsDescription',
      'areTotalSavingsAccurate',
    ]);

    // @ts-expect-error ts(18049) FIXME: 'spendAndSavings' is possibly 'null' or 'undefined'.
    if (spendAndSavings.totalSavingsCalculationMethod === null) {
      return {
        initialValues: {
          ...initialValues,
          totalSavingsCalculationMethod: methodSelectItems[0].value,
        },
        hasUnsavedMethod: true,
      };
    } else {
      return {
        initialValues,
        hasUnsavedMethod: false,
      };
    }
  }, [spendAndSavings, methodSelectItems]);

  return (
    <Formik
      validateOnBlur
      enableReinitialize
      initialValues={initialValues}
      validationSchema={yup.object().shape({
        manualTotalSavings: yup.number()
          .nullable()
          .transform((curr, orig) => orig === '' ? null : curr)
          .moreThan(0, t('errors.greaterThan', { min: 0 })),
      })}
      onSubmit={async (values) => {
        const payload: Partial<RfxSpendAndSavings> = { ...values };
        // When specific savings are available but not yet stored in
        // the request, include it in the update
        if (!requestHasCalculatedSavingsByType && calculatedSavingsByType) {
          payload.calculatedSavingsByType = calculatedSavingsByType;
        }

        if (
          values.totalSavingsCalculationMethod === TotalSavingsCalculationMethod.MANUAL &&
          !isFinite(values.manualTotalSavings)
        ) {
          payload.areTotalSavingsAccurate = null;
        }

        await updateSpendAndSavings(payload, {
          onSuccess: () => stopEditing(),
        });
      }}
    >
      {(props) => (
        <Form>
          <PanelPadding>
            <FieldContainer
              name="description"
              label={t('general.description')}
              labelStyle={{ marginBottom: 0 }}
            >
              {t('request.spendAndSavings.totalSavingsDescription')}
            </FieldContainer>
          </PanelPadding>
          <PanelDivider />
          <TotalSavingsFields
            methodSelectItems={methodSelectItems}
            spendAndSavings={spendAndSavings}
            calculatedTotalValue={calculatedTotalValue}
            calculatedSavingsByType={calculatedSavingsByType}
            {...props as any}
          />
          <PanelPadding>
            <Flex justifyContent="flex-end">
              <CancelButton onClick={callAll(props.resetForm, stopEditing)} mr={2} />
              <SaveButton disabled={props.isSubmitting || (!props.dirty && !hasUnsavedMethod) || !props.isValid} />
            </Flex>
          </PanelPadding>
          <LeavePageModal />
        </Form>
      )}
    </Formik>
  );
};

export const TotalSavingsPanel = () => {
  const { t } = useTranslation();
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { spendAndSavings, status } = rfx.useStructure();
  const { editingPanelId } = rfx.useState();

  // @ts-expect-error ts(18049) FIXME: 'spendAndSavings' is possibly 'null' or 'undefined'.
  const requestHasCalculatedTotalValue = isFinite(spendAndSavings.calculatedTotalValue);
  // @ts-expect-error ts(18049) FIXME: 'spendAndSavings' is possibly 'null' or 'undefined'.
  const requestHasCalculatedSavingsByType = Boolean(spendAndSavings.calculatedSavingsByType);

  const { data: computedCostAndSavingsResponse, isLoading: isLoadingComputedCostAndSavings } = useRfqComputedCostAndSavings({
    rfqId,
    currentCompanyId,
    enabled: status === RfqStatus.AWARDED && (!requestHasCalculatedSavingsByType || !requestHasCalculatedTotalValue),
  });

  const calculatedTotalValue = requestHasCalculatedTotalValue
    // @ts-expect-error ts(18049) FIXME: 'spendAndSavings' is possibly 'null' or 'undefined'.
    ? spendAndSavings.calculatedTotalValue
    : computedCostAndSavingsResponse?.totalRequestValue;

  const calculatedSavingsByType = requestHasCalculatedSavingsByType
    // @ts-expect-error ts(18049) FIXME: 'spendAndSavings' is possibly 'null' or 'undefined'.
    ? spendAndSavings.calculatedSavingsByType
    : computedCostAndSavingsResponse?.calculatedSavingsByType || {} as SavingsCalculationResultByRecipientIdBySavingsType;

  const isEditingOtherPanel = editingPanelId && editingPanelId !== panelId;
  const isEditingThisPanel = editingPanelId && editingPanelId === panelId;

  const isPanelDisabled = status !== RfqStatus.AWARDED;

  const heading = t('request.spendAndSavings.totalSavings');

  return (
    <DraftSectionLabelConfigProvider>
      <Panel
        as="section"
        aria-label={heading}
        mb={20}
        sx={{
          opacity: isEditingOtherPanel || isPanelDisabled ? 0.5 : 1,
          boxShadow: isEditingThisPanel ? '0 0 8px 0 rgba(0, 0, 0, 0.3)' : '',
        }}
      >
        <SpendPanelHeader
          heading={heading}
          panelId={panelId}
          // @ts-expect-error ts(2345) FIXME: Argument of type 'RfxSpendAndSavings | null | undefined' is not assignable to parameter of type 'RfxSpendAndSavings | undefined'.
          status={getTotalSavingsSectionStatus(spendAndSavings)}
          isPanelDisabled={isPanelDisabled}
          isEditButtonDisabled={isLoadingComputedCostAndSavings}
        />
        <PanelDivider />
        {isEditingThisPanel ? (
          <TotalSavingsEditPanelContent
            calculatedTotalValue={calculatedTotalValue}
            // @ts-expect-error ts(2322) FIXME: Type 'SavingsCalculationResultByRecipientIdBySavingsType | null' is not assignable to type 'SavingsCalculationResultByRecipientIdBySavingsType'.
            calculatedSavingsByType={calculatedSavingsByType}
          />
        ) : (
          <TotalSavingsViewPanelContent
            calculatedTotalValue={calculatedTotalValue}
            // @ts-expect-error ts(2322) FIXME: Type 'SavingsCalculationResultByRecipientIdBySavingsType | null' is not assignable to type 'SavingsCalculationResultByRecipientIdBySavingsType'.
            calculatedSavingsByType={calculatedSavingsByType}
          />
        )}
      </Panel>
    </DraftSectionLabelConfigProvider>
  );
};
