import { useMemo, useCallback } from 'react';
import { AnyScope, AuctionStatus, Draft, StageType } from '@deepstream/common/rfq-utils';
import { Box, Flex } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { every } from 'lodash';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { StageDelimiter } from './StageDelimiter';
import { LegacyStageHeader } from './LegacyStageHeader';
import { StageHeader } from './StageHeader';
import { LabelConfig, LabelConfigProvider } from '../LabelConfigProvider';
import { useAddStage, useUpdateStage } from './useStageMutations';
import { useDeviceSize } from '../ui/useDeviceSize';
import { useCurrentDraft } from './useCurrentDraft';
import * as rfx from '../rfx';
import { TextField } from '../form/TextField';
import { DatetimeField } from '../form/DatetimeField';
import { SwitchField } from '../form/SwitchField';
import { FieldContainer } from '../form/FieldContainer';
import { useSystemFeatureFlags } from '../systemFeatureFlags';
import { HelperText } from '../form/Field';
import { MinutesStepperField } from '../form/MinutesStepperField';
import { StructureStage } from '../types';
import { isAuctionStage } from '../utils';
import { LeavePageModal } from './LeavePageModal';

const commonLabelStyle = {
  fontSize: 2,
};

const centeredLabelStyle = {
  position: 'relative',
  top: '12px',
};

const NAME_CHAR_LIMIT = 30;
const LABEL_WIDTH = '100px';

type DraftStageProps = {
  stage: StructureStage<Draft>;
  stageIndex: number;
  editingStageId: string | null;
  setEditingStageId: (editMode: string | null) => void;
  canAddAuctionStage: boolean;
  showStageNameValidation: boolean;
};

export const DraftStage = ({
  stage,
  stageIndex,
  editingStageId,
  setEditingStageId,
  canAddAuctionStage,
  showStageNameValidation,
}: DraftStageProps) => {
  const { t } = useTranslation('translation');
  const { isExtraSmall } = useDeviceSize();
  const { isRevising, isTemplate, mutationPending } = useCurrentDraft();
  const { auction, sectionById, stages } = rfx.useStructure<AnyScope>();
  const isAuctionEditable = rfx.useIsAuctionEditable();
  const systemFeatureFlags = useSystemFeatureFlags();

  const addStageMutation = useAddStage();
  const updateStageMutation = useUpdateStage({
    onSuccess: () => setEditingStageId(null),
  });

  const canEditVisibility = useMemo(
    () => {
      if (stageIndex === 0) return false;

      const previousStages = stages.slice(0, stageIndex);

      return every(previousStages, stage => !stage.isPrivate);
    },
    [stages, stageIndex],
  );

  const hasMultipleStages = stages.length > 1;
  const isStageNameRequired = hasMultipleStages;

  const labelStyles = useMemo(
    () => ({
      name: { ...centeredLabelStyle, ...commonLabelStyle },
      startDate: { ...centeredLabelStyle, ...commonLabelStyle },
      duration: { ...centeredLabelStyle, ...commonLabelStyle },
      completionDeadline: { ...centeredLabelStyle, ...commonLabelStyle },
      isPrivate: canEditVisibility
        ? { ...commonLabelStyle, position: 'relative', top: '3px' }
        : commonLabelStyle,
    }),
    [canEditVisibility],
  );

  const editStage = useCallback(
    () => {
      setEditingStageId(stage._id);
    },
    [setEditingStageId, stage._id],
  );

  const addStage = useCallback(
    async (type: StageType, index?: number) => {
      const newStage = await addStageMutation(type, index);

      setEditingStageId(newStage._id);
    },
    [addStageMutation, setEditingStageId],
  );

  const firstHiddenStageNumber = useMemo(
    () => {
      const index = stageIndex && !canEditVisibility && stages.findIndex(rfqStage => rfqStage.isPrivate);

      return index
        ? index + 1
        : null;
    },
    [stages, stageIndex, canEditVisibility],
  );

  const now = new Date();

  const validationSchema = isAuctionStage(stage) ? (
    yup.object().shape({
      startDate: yup.date().nullable().min(now, t('request.stages.noDateInPast')),
      duration: yup.number().nullable().min(1, t('errors.min1')),
    })
  ) : (
    yup.object().shape({
      // only enforce character limit when the stage name stored in the
      // structure doesn't exceed it
      name: stage.name.length <= NAME_CHAR_LIMIT
        ? yup.string().max(NAME_CHAR_LIMIT)
        : yup.string(),

      // Although deadlines cannot be in the past, an exception is made while revising to
      // allow the stage's live completion deadline. Otherwise, a user couldn't add
      // a missing stage name in a revision without requiring an update to the deadline.
      completionDeadline: isRevising && stage.isLive
        ? yup.date().nullable().test('noPastDateWithException', t('request.stages.noDateInPast'), value => (
          !value ||
          new Date(value).getTime() === new Date(stage.liveVersion!.completionDeadline!).getTime() ||
          new Date(value).getTime() > now.getTime()
        ))
        : yup.date().nullable().min(now, t('request.stages.noDateInPast')),
    })
  );

  const duration = rfx.getAuctionLineItemsSection(sectionById, stage._id)?.auctionRules.duration;

  return (
    <LabelConfigProvider
      variant={isExtraSmall ? LabelConfig.ABOVE : LabelConfig.LEFT}
      width={LABEL_WIDTH}
      style={isExtraSmall ? {} : labelStyles}
    >
      <Formik
        initialValues={{
          ...stage,
          duration,
        }}
        validateOnBlur
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values) => updateStageMutation({ ...stage, duration }, values)}
      >
        {({ isSubmitting, dirty, isValid, values, resetForm }) => {
          if (!isAuctionEditable && dirty) resetForm();

          return (
            <Form style={{ width: '100%' }}>
              <Panel width="100%">
                {systemFeatureFlags?.auctionsEnabled ? (
                  <StageHeader
                    stage={{
                      ...stage,
                      ...values,
                    } as StructureStage<Draft> & { duration?: number | null }}
                    stageIndex={stageIndex}
                    canEdit={!editingStageId}
                    editStage={editStage}
                    showStageNameValidation={showStageNameValidation && editingStageId !== stage._id}
                  />
                ) : (
                  <LegacyStageHeader
                    stage={{ ...stage, ...values }}
                    stageIndex={stageIndex}
                    canEdit={!editingStageId}
                    editStage={editStage}
                  />
                )}
                {editingStageId === stage._id && (
                  <>
                    <PanelDivider mx="-20px" />
                    <PanelPadding>
                      <Stack gap={3}>
                        {isAuctionStage(stage) ? (
                          <>
                            {!isAuctionEditable && (
                              <MessageBlock variant="info" mt={0} mb={1}>
                                {auction?.status === AuctionStatus.CANCELLED ? (
                                  t('request.auction.stageInfoReviseCancelledAuction')
                                ) : (
                                  t('request.auction.stageInfoReviseStartedAuction')
                                )}
                              </MessageBlock>
                            )}
                            <DatetimeField
                              required
                              hasTime
                              validateOnMount={!stage.isLive}
                              name="startDate"
                              label={t('general.start')}
                              dateFormat="dd MMM yyyy h:mm a"
                              disabled={isTemplate || !isAuctionEditable}
                              helperText={t('request.stages.startDateDescription')}
                              checkMinDateOnOpen={false}
                              min={now}
                            />
                            {isTemplate && (
                              <MessageBlock variant="info" ml={isExtraSmall ? 0 : LABEL_WIDTH} mt={0}>
                                {t('request.stages.templateStartDateDisabled')}
                              </MessageBlock>
                            )}
                            <MinutesStepperField
                              required
                              name="duration"
                              label={t('general.duration')}
                              helperText={t('request.stages.durationDescription')}
                              increment={5}
                              gap={1}
                              disabled={!isAuctionEditable}
                            />
                          </>
                        ) : (
                          <>
                            <TextField
                              name="name"
                              label={t('request.stages.name')}
                              required={isStageNameRequired}
                              helperText={isStageNameRequired
                                ? t('request.stages.nameDescription')
                                : t('request.stages.optionalNameDescription')
                              }
                            />
                            <DatetimeField
                              required
                              hasTime
                              validateOnMount={!stage.isLive}
                              name="completionDeadline"
                              label={t('general.deadline')}
                              dateFormat="dd MMM yyyy h:mm a"
                              disabled={isTemplate}
                              helperText={t('request.stages.deadlineDescription')}
                              checkMinDateOnOpen={false}
                              min={now}
                            />
                            {isTemplate && (
                              <MessageBlock variant="info" ml={isExtraSmall ? 0 : LABEL_WIDTH} mt={0}>
                                {t('request.stages.templateDeadlineDisabled')}
                              </MessageBlock>
                            )}
                          </>
                        )}
                        {hasMultipleStages && (
                          <FieldContainer
                            name="isPrivate"
                            label={t('request.stages.visibility')}
                            width="100%"
                          >
                            {canEditVisibility ? (
                              <Box>
                                <SwitchField
                                  name="isPrivate"
                                  label={t('request.stages.visibilitySwitch')}
                                />
                                <HelperText text={t('request.stages.visibilityDescription')} />
                              </Box>
                            ) : (
                              <MessageBlock variant="info" mt={0}>
                                {stageIndex === 0 ? (
                                  t('request.stages.visibilityInfoFirstStage')
                                ) : (
                                  t('request.stages.visibilityInfoPreviousStages', { stageNumber: firstHiddenStageNumber })
                                )}
                              </MessageBlock>
                            )}
                          </FieldContainer>
                        )}
                      </Stack>
                    </PanelPadding>
                    <PanelDivider mx="-20px" my="20px" />
                    <PanelPadding>
                      <Flex justifyContent="flex-end">
                        <Button
                          variant="secondary"
                          onClick={() => {
                            resetForm();
                            setEditingStageId(null);
                          }}
                          mr={2}
                        >
                          {t('general.cancel')}
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting || !dirty || !isValid}>
                          {t('general.save')}
                        </Button>
                      </Flex>
                    </PanelPadding>
                    <LeavePageModal />
                  </>
                )}
              </Panel>
              <StageDelimiter
                stage={stage}
                stageIndex={stageIndex}
                canAddStage={!mutationPending}
                editMode={!!editingStageId}
                addStage={addStage}
                canAddAuctionStage={canAddAuctionStage}
              />
            </Form>
          );
        }}
      </Formik>
    </LabelConfigProvider>
  );
};
