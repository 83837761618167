import { compact, find, map, uniq, values } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isBidActive, UniversalBidStatus } from '@deepstream/common/rfq-utils';
import { Flex } from 'rebass/styled-components';
import { universalBidStatusesConfig } from '@deepstream/common/rfq-utils/statusConfigs';
import { IconValue } from '@deepstream/common';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useLocalStorageState } from '../../../useLocalStorageState';
import * as rfx from '../../../rfx';

type SelectBidStatusItem = { value: UniversalBidStatus; label: string };

export const useSelectBidStatus = ({
  storageKey,
}: {
  storageKey: string;
}) => {
  const { t } = useTranslation();
  const structure = rfx.useStructure();

  const availableBidStatuses = useMemo(() =>
    uniq(map(values(structure.bidById).filter(bid => bid?.status && isBidActive(bid.status)), 'universalStatus')),
    [structure.bidById],
  );

  const items = useMemo(() => availableBidStatuses.map(bidStatus => ({
    value: bidStatus as UniversalBidStatus,
    label: t(`request.universalBidStatus.${bidStatus}`),
  })), [t, availableBidStatuses]);

  const [selectedItems, setSelectedItems] = useLocalStorageState<SelectBidStatusItem[]>({
    key: storageKey,
    defaultValue: [],
    mapInitialValue: (initialItems) => compact(
      initialItems.map(({ value }) => find(items, { value })),
    ),
  });

  return {
    itemToString: (item: SelectBidStatusItem | null) => item ? item.label : '',
    renderItem: (item: SelectBidStatusItem) => {
      if (!universalBidStatusesConfig[item.value]) return null;

      const { icon } = universalBidStatusesConfig[item.value];
      return (
        <Flex key={item.value} alignItems="center" sx={{ gap: 2 }}>
          {/*
           // @ts-expect-error ts(18048) FIXME: 'icon' is possibly 'undefined'. */}
          <Icon fixedWidth flexBasis="30px" icon={icon.value as IconValue} color={icon.color} />
          {item.label}
        </Flex>
      );
    },
    items,
    selectedItems,
    onChange: setSelectedItems,
  };
};
