import { Text } from 'rebass/styled-components';
import { CellProps } from 'react-table';
import { documentExchangeTypes } from '@deepstream/common/exchangesConfig';
import { useTranslation } from 'react-i18next';
import { getRequirementFromExchangeType } from '@deepstream/common/rfq-utils';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { OverflowTooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';

/**
 * Renders the label for a given exchange type
 * TODO add translations for non-document exchanges
 */
export const ExchangeTypeCell = ({
  cell: { value: exchangeType },
  column,
}: CellProps<any> & { column: { showTooltip?: boolean; truncate?: boolean } }) => {
  const { t } = useTranslation();

  const Wrapper = column.truncate ? Truncate : Text;

  if (documentExchangeTypes.includes(exchangeType)) {
    const requirement = getRequirementFromExchangeType({ type: exchangeType });
    const value = t(`request.documents.requirements.${requirement}`);

    return (
      <Wrapper>
        <OverflowTooltip content={column.showTooltip ? value : null}>
          <>{value}</>
        </OverflowTooltip>
      </Wrapper>
    );
  }

  const value = t(`request.exchangeType.${exchangeType}`);

  return (
    <Wrapper>
      <OverflowTooltip content={column.showTooltip ? value : null}>
        <>{value}</>
      </OverflowTooltip>
    </Wrapper>
  );
};
