import { useMemo } from 'react';
import { EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { DefaultEditableGridStyles } from '@deepstream/ui-kit/grid/EditableGrid/EditableGridStyles';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { DataCellProps } from '@deepstream/ui-kit/grid/core/utils';
import { ReadOnlyGrid } from '@deepstream/ui-kit/grid/EditableGrid/ReadOnlyGrid';
import { getCellValue } from '@deepstream/ui-kit/grid/EditableGrid/getCellValue';
import { NonVirtualGrid } from '@deepstream/ui-kit/grid/core/NonVirtualGrid';
import { RenderTableDataCell } from '@deepstream/ui-kit/grid/EditableGrid/RenderTableDataCell';
import { useTranslation } from 'react-i18next';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { AuctionLineItemExchangeDefinition, LineItemExchangeDefinition } from '@deepstream/common/rfq-utils';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Box, SxStyleProp } from 'rebass/styled-components';
import { localeFormatNumber, localeFormatPrice } from '@deepstream/utils';
import { isFinite } from 'lodash';
import { withProps } from '@deepstream/ui-utils/withProps';
import { OverflowTooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { SimpleHeader } from './header';

const ValueCell = ({
  row,
  column,
  sx,
  disabledContent,
}: DataCellProps<EditableGridColumn, any, any> & { sx?: SxStyleProp, disabledContent?: string }) => {
  const value = getCellValue(row.original, column.original);

  if (row.original.isSubHeader) {
    return (
      <div className="cell-content disabled" style={{ cursor: 'default' }}>
        {column.original._id === 'label' ? (
          <OverflowTooltip content={value}>
            <Truncate>
              <Box as="span" color="lightNavy" fontWeight={500}>
                {value || <EmDash />}
              </Box>
              <Box as="span" color="subtext" ml={2}>
                {row.original.numItems}
              </Box>
            </Truncate>
          </OverflowTooltip>
        ) : (
          null
        )}
      </div>
    );
  }

  if (!value && disabledContent) {
    return (
      <Box className="cell-content" sx={{ backgroundColor: 'lightGrayTransparent' }}>
        <Truncate sx={sx}>
          {disabledContent}
        </Truncate>
      </Box>
    );
  }

  return (
    <div className="cell-content">
      <OverflowTooltip content={value}>
        <Truncate sx={sx}>
          {value || <EmDash />}
        </Truncate>
      </OverflowTooltip>
    </div>
  );
};

export type AwardedLineItemsGridRow = {
  _id: string;
  label: string;
  isSubHeader?: boolean;
  numItems?: number;
  awardedExchangeId?: string;
  originalPricePerUnit?: number;
  originalQuantity?: number;
  supplierCurrencyCode?: string;
  requestCurrencyAwardedCost?: number | null;
  exchangeDef?: LineItemExchangeDefinition | AuctionLineItemExchangeDefinition;
  awardedQuantityAmount?: number;
  awardedQuantityPercentage?: number;
};

export const AwardedLineItemsGrid = ({
  rows,
  onRowClick,
  currencyCode,
  locale,
  showSplitDecisionDetails,
}: {
  rows: AwardedLineItemsGridRow[];
  onRowClick;
  currencyCode: string;
  locale: string;
  showSplitDecisionDetails?: boolean;
}) => {
  const { t } = useTranslation('translation');
  const theme = useTheme();

  const columns: EditableGridColumn<AwardedLineItemsGridRow>[] = useMemo(() => {
    const rightColumns = showSplitDecisionDetails
      ? [
        {
          _id: 'pricePerUnit',
          accessorKey: 'pricePerUnit',
          accessorFn: row => {
            if (isFinite(row.originalPricePerUnit) && row.supplierCurrencyCode) {
              return localeFormatPrice(row.originalPricePerUnit, row.supplierCurrencyCode, { locale, showCode: true });
            } else {
              return null;
            }
          },
          Header: SimpleHeader,
          label: t('request.fields.predefinedFieldLabel.price'),
          width: '20%' as any,
          ValueCell: withProps(ValueCell, { sx: { textAlign: 'right' }, disabledContent: t('general.notApplicableShort') }),
        },
        {
          _id: 'awardedQuantity',
          accessorKey: 'awardedQuantity',
          accessorFn: row => {
            const value = isFinite(row.awardedQuantityAmount) ? (
              row.awardedQuantityAmount
            ) : isFinite(row.awardedQuantityPercentage) ? (
              row.originalQuantity * (row.awardedQuantityPercentage / 100)
            ) : (
              null
            );

            return isFinite(value)
              ? localeFormatNumber(value, { locale })
              : null;
          },
          Header: SimpleHeader,
          label: t('request.awardSummary.awardedLineItems.awardedQuantity'),
          width: '20%' as any,
          ValueCell: withProps(ValueCell, { sx: { textAlign: 'right' } }),
        },
        {
          _id: 'requestCurrencyAwardedCost',
          accessorKey: 'requestCurrencyAwardedCost',
          accessorFn: row => {
            const value = row.requestCurrencyAwardedCost;

            return isFinite(value)
              ? localeFormatPrice(value, currencyCode, { locale, showCode: true })
              : null;
          },
          Header: SimpleHeader,
          label: t('request.awardSummary.awardedLineItems.awardedTotalCost'),
          width: '20%' as any,
          ValueCell: withProps(ValueCell, { sx: { textAlign: 'right' } }),
        },
      ]
      : [
        {
          _id: 'requestCurrencyAwardedCost',
          accessorKey: 'requestCurrencyAwardedCost',
          accessorFn: row => {
            const value = row.requestCurrencyAwardedCost;

            return isFinite(value)
              ? localeFormatPrice(value, currencyCode, { locale, showCode: true })
              : null;
          },
          Header: SimpleHeader,
          label: t('request.lineItems.totalCost.title'),
          width: '20%' as any,
          ValueCell: withProps(ValueCell, { sx: { textAlign: 'right' } }),
        },
      ];

    return [
      {
        _id: 'label',
        accessorKey: 'label',
        Header: SimpleHeader,
        label: t('general.description'),
        width: `${100 - rightColumns.length * 20}%` as any,
        ValueCell,
      },
      ...rightColumns,
    ];
  }, [currencyCode, locale, t, showSplitDecisionDetails]);

  return (
    <DefaultEditableGridStyles
      style={{ height: 'auto' }}
      isReadOnly
      cellContentCss="align-items: flex-start; padding: 10px"
      overrides={`[role=gridcell] { background-color: inherit } tbody > tr { background-color: white } tbody > tr:hover { background-color: ${theme.colors.lightPrimary4} }`}
      omitLastRowBorderBottom
    >
      <GridIdPrefixProvider>
        <ReadOnlyGrid
          columns={columns}
          rowData={rows as any}
          GridComponent={NonVirtualGrid}
          RenderDataCell={RenderTableDataCell}
          onRowClick={onRowClick}
        />
      </GridIdPrefixProvider>
    </DefaultEditableGridStyles>
  );
};
