import { useTranslation } from 'react-i18next';
import { useState, useMemo, Fragment } from 'react';
import { EvaluationPage, ExchangeType, LinkedEvaluationSection, SectionType, renderSectionName } from '@deepstream/common/rfq-utils';
import { Flex, Text } from 'rebass/styled-components';
import { difference, filter, propertyOf } from 'lodash';
import { IconValue } from '@deepstream/common';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { BorderedIcon } from '@deepstream/ui-kit/elements/icon/BorderedIcon';
import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { Panel, PanelDivider, PanelSubHeader } from '@deepstream/ui-kit/elements/Panel';
import { CancelButton, Modal, ModalBody, ModalFooter, ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import * as rfx from '../../rfx';
import { getSectionExchangeDefs, getSizeRelevantExchangeDefCount, useModelSizeLimits } from '../../modelSizeLimits';
import { useModalState } from '../../ui/useModalState';
import { ModelSizeLimitDialog, ModelSizeLimitMessages } from '../../ModelSizeLimitDialog';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (sectionIds: string[]) => void;
}

const useSectionIcon = (): IconValue => {
  const section = rfx.useSection();
  switch (section.type) {
    case SectionType.LINE_ITEMS:
      return 'list-ul';
    case SectionType.QUESTION:
      return 'question';
    case SectionType.DOCUMENT:
      return 'file';
    case SectionType.AUCTION_LINE_ITEMS:
      return 'list-ul';
    default:
      return 'list-ul';
  }
};

const PanelSelectSectionHeading = ({
  selected,
  onSelect,
}) => {
  const section = rfx.useSection();
  const icon = useSectionIcon();
  const { t } = useTranslation();
  const sectionName = renderSectionName(section, t);
  return (
    <Flex alignItems="center" sx={{ gap: '16px' }}>
      <Checkbox checked={selected} onChange={onSelect} />
      <BorderedIcon icon={icon} />
      {sectionName ? (
        <Text>{sectionName}</Text>
      ) : (
        <Text color="subtext">{t('general.untitled')}</Text>
      )}
    </Flex>
  );
};

const PanelSelectAllSectionHeading = ({
  selected,
  indeterminate,
  onSelect,
}) => {
  const { t } = useTranslation('evaluation');
  return (
    <Flex alignItems="center" sx={{ gap: '16px' }}>
      <Checkbox checked={selected} indeterminate={indeterminate} onChange={() => onSelect(!selected)} />
      <Text>
        {t('addLinkedSectionsModal.selectAll')}
      </Text>
    </Flex>
  );
};

const SelectableSection = ({
  selected,
  onSelect,
}) => {
  const exchangeDefs = rfx.useSectionExchangeDefs().filter((exchangeDef) => exchangeDef.type !== ExchangeType.CURRENCY);
  return (
    <Flex flexDirection="column">
      <PanelSubHeader
        heading={
          <PanelSelectSectionHeading
            onSelect={() => onSelect(!selected)}
            selected={selected}
          />
        }
      />
      {exchangeDefs.map((exchangeDef) => {
        const description = 'description' in exchangeDef ? exchangeDef.description
          : 'category' in exchangeDef ? exchangeDef.category
          : null;
        return (
          <Fragment key={exchangeDef._id}>
            <PanelDivider />
            <Text ml="52px" py={3}>{description || <EmDash />}</Text>
          </Fragment>
        );
      })}
    </Flex>
  );
};

export const AddLinkedSectionsModal = ({ isOpen, onCancel, onSubmit }: Props) => {
  const { t } = useTranslation(['evaluation', 'translation']);
  const [selectedSections, setSelectedSections] = useState<{ [sectionId: string]: boolean }>({});
  const rfxStructure = rfx.useStructure();
  const page = rfx.usePage() as EvaluationPage;
  // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const linkedPage = rfxStructure.pageById[page.linkedPageId];
  const sections = rfx.useSections() as LinkedEvaluationSection[];
  const { maxExchangeDefCount } = useModelSizeLimits();
  const modelSizeLimitModal = useModalState();
  const [modelSizeLimitMessages, setModelSizeLimitMessages] = useState<ModelSizeLimitMessages | null>(null);

  const linkedPageSectionIds: string[] = difference(
    filter(
      linkedPage.sections,
      (sectionId) => [
        SectionType.LINE_ITEMS,
        SectionType.QUESTION,
        SectionType.DOCUMENT,
        SectionType.AUCTION_LINE_ITEMS,
      ].includes(rfxStructure.sectionById[sectionId]?.type),
    ),
    sections.map((section) => section.linkedSectionId),
  );

  const areAllSectionsSelected = useMemo(() =>
    linkedPageSectionIds.every((sectionId) => selectedSections[sectionId]),
    [selectedSections, linkedPageSectionIds],
  );

  const areAllSectionsDeselected = useMemo(() =>
    linkedPageSectionIds.every((sectionId) => !selectedSections[sectionId]),
    [selectedSections, linkedPageSectionIds],
  );

  const isIndeterminate = !areAllSectionsSelected && !areAllSectionsDeselected;

  const selectAll = (selected) => {
    const newSelectedSections = {};
    linkedPageSectionIds.forEach((sectionId) => {
      newSelectedSections[sectionId] = selected;
    });
    setSelectedSections(newSelectedSections);
  };

  const handleSubmit = () => {
    const selectedSectionIds = linkedPageSectionIds.filter((sectionId) => selectedSections[sectionId]);

    const totalExchangeDefCount = getSizeRelevantExchangeDefCount(rfxStructure.exchangeDefById);

    const newSectionExchangeDefs = selectedSectionIds
      .map(propertyOf(rfxStructure.sectionById))
      .flatMap(section => getSectionExchangeDefs(rfxStructure.exchangeDefById, section));

    const addedCount = getSizeRelevantExchangeDefCount(newSectionExchangeDefs);

    if (totalExchangeDefCount + addedCount > maxExchangeDefCount) {
      setModelSizeLimitMessages({
        heading: t('request.dialog.requestSizeLimit.heading', { ns: 'translation' }),
        title: t('request.dialog.requestSizeLimit.addLinkedSections.title', { ns: 'translation' }),
        warning: t('request.dialog.requestSizeLimit.addLinkedSections.warning', { ns: 'translation' }),
        body: t('request.dialog.requestSizeLimit.addLinkedSections.body', {
          count: totalExchangeDefCount + addedCount - maxExchangeDefCount,
          ns: 'translation',
        }),
      });
      modelSizeLimitModal.open();
    } else {
      onSubmit(selectedSectionIds);
      setSelectedSections({});
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={onCancel} shouldCloseOnEsc>
        <ModalHeader>
          {t('addLinkedSectionsModal.title')}
        </ModalHeader>
        <ModalBody>
          {!linkedPage.sections.length ? (
            <Text>{t('addLinkedSectionsModal.noAvailableSections')}</Text>
          ) : !linkedPageSectionIds.length ? (
            <Text>{t('addLinkedSectionsModal.allSectionsSelected')}</Text>
          ) : (
            <Panel>
              <PanelSubHeader
                heading={
                  <PanelSelectAllSectionHeading
                    selected={areAllSectionsSelected}
                    indeterminate={isIndeterminate}
                    onSelect={selectAll}
                  />
                }
              />
              {linkedPageSectionIds.map(sectionId => (
                <rfx.SectionProvider key={sectionId} section={rfxStructure.sectionById[sectionId]}>
                  <SelectableSection
                    selected={!!selectedSections[sectionId]}
                    onSelect={(selected) => setSelectedSections({ ...selectedSections, [sectionId]: selected })}
                  />
                </rfx.SectionProvider>
              ))}
            </Panel>
          )}
        </ModalBody>
        <ModalFooter>
          <CancelButton onClick={onCancel} />
          <Button variant="primary" disabled={areAllSectionsDeselected} onClick={handleSubmit}>
            {t('addLinkedSectionsModal.submit')}
          </Button>
        </ModalFooter>
      </Modal>
      <ModelSizeLimitDialog
        modal={modelSizeLimitModal}
        messages={modelSizeLimitMessages}
      />
    </>
  );
};
