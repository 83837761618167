import { orderBy } from 'lodash';
import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Attachment } from '@deepstream/common/rfq-utils';

import { useApi, wrap } from '../../api';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useMutation } from '../../useMutation';
import { downloadUrl } from '../../useDownload';

import { RequestCommentsAttachment } from './types';

export const useRequestComments = (id: string) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { data: threads, ...rest } = useQuery(
    ['requestComments', { rfqId: id, currentCompanyId }],
    wrap(api.getRequestComments),
  );

  return {
    threads: orderBy(threads, 'createdAt', ['desc']),
    ...rest,
  };
};

export const useCommentsAttachmentDownload = () => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const [downloadAttachment] = useMutation(api.downloadCommentAttachment);

  return useCallback(async (rfqId: string, attachment: RequestCommentsAttachment) => {
    const { url } = await downloadAttachment({ rfqId, attachmentId: attachment._id, currentCompanyId });
    downloadUrl(url, attachment.name);
  }, [downloadAttachment, currentCompanyId]);
};

export const useThreadPost = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const [postThread, ...rest] = useMutation(api.postRequestCommentsThread, {
    onSuccess: (response) => queryClient.invalidateQueries(['requestComments', { rfqId: response.rfqId }]),
  });

  const handlePost = useCallback(async (
    rfqId: string,
    subject: string,
    message: string,
    attachments: Attachment[],
  ) => {
    await postThread({
      rfqId,
      subject,
      message,
      attachments,
      companyId: currentCompanyId,
    });
  }, [postThread, currentCompanyId]);

  return [handlePost, ...rest] as const;
};

export const useCommentPost = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const currentCompanyId = useCurrentCompanyId();

  const [postComment, ...rest] = useMutation(api.postRequestCommentsResponse, {
    onSuccess: (response) => queryClient.invalidateQueries(['requestComments', { rfqId: response.rfqId }]),
  });

  const handlePost = useCallback(async (
    rfqId: string,
    threadId: string,
    message: string,
    attachments?: Attachment[],
  ) => {
    await postComment({
      rfqId,
      threadId,
      message,
      // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
      companyId: currentCompanyId,
      attachments,
    });
  }, [postComment, currentCompanyId]);

  return [handlePost, ...rest] as const;
};
