import { useState, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { QuestionnaireTemplateOverview } from '@deepstream/common/preQual';
import { Illustration } from '@deepstream/ui-kit';
import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { FlexPanelHeader, Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useApi } from '../../../api';
import { Loading } from '../../../ui/Loading';
import { ErrorMessage } from '../../../ui/ErrorMessage';
import { MIN_CELL_HEIGHT } from '../../../FieldsCell';
import { useModalState } from '../../../ui/useModalState';
import { useToaster } from '../../../toast';
import { useMutation } from '../../../useMutation';
import { useTableData } from '../../../TableDataContext';
import { useCurrentUser } from '../../../useCurrentUser';
import { useLocalStorageState } from '../../../useLocalStorageState';
import { UpgradeToUnlockPreQualDialog } from '../UpgradeToUnlockPreQualDialog';
import { QuestionnaireTemplatesTableData, QuestionnaireTemplatesTableDataProvider } from './QuestionnaireTemplatesTableDataProvider';
import { DraftQuestionnaireTemplatesTable } from './DraftQuestionnaireTemplatesTable';
import { useCompanyFeatureFlags } from '../../../companyFeatureFlags';
import { useUserFlags } from '../../../UserFlagsContext';
import { useDraftQuestionnaireTemplateNavigation } from '../../../appNavigation';

const DraftTemplatesContent = () => {
  const { t } = useTranslation('preQualification');
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { hasManagePreQualPermission } = useUserFlags();
  const companyFeatureFlags = useCompanyFeatureFlags();
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const draftTemplateNavigation = useDraftQuestionnaireTemplateNavigation();
  const deleteQuestionnaireModal = useModalState();
  const upgradeDialog = useModalState();
  const [selectedTemplate, setSelectedTemplate] = useState<QuestionnaireTemplateOverview | null>(null);
  const {
    query,
    pageSize,
  } = useTableData<QuestionnaireTemplatesTableData>({ required: true });

  const { status, data } = query;
  const {
    totalItems = 0,
  } = data ?? {};

  const [deleteTemplateMutation] = useMutation(
    api.deleteQuestionnaireTemplate,
    {
      onSettled: callAll(
        () => queryClient.invalidateQueries(['draftQuestionnaireTemplates', { currentCompanyId }]),
        deleteQuestionnaireModal.close,
        () => setSelectedTemplate(null),
      ),
      onSuccess: () => toaster.success(t('toaster.templateDeleted.success')),
      onError: () => toaster.error(t('toaster.templateDeleted.failed')),
    },
  );

  const [createEmptyTemplate] = useMutation(
    api.createEmptyQuestionnaireTemplate,
    {
      onSettled: () => queryClient.invalidateQueries(['draftQuestionnaireTemplates', { currentCompanyId }]),
      onSuccess: callAll(
        (data) => draftTemplateNavigation.navigateToSummary(data._id),
        () => toaster.success(t('toaster.templateCreated.success')),
      ),
      onError: () => toaster.error(t('toaster.templateCreated.failed')),
    },
  );

  const openDeleteModal = useCallback(
    (questionnaire) => {
      setSelectedTemplate(questionnaire);
      deleteQuestionnaireModal.open();
    },
    [setSelectedTemplate, deleteQuestionnaireModal],
  );

  const deleteTemplate = () => deleteTemplateMutation({
    companyId: currentCompanyId,
    // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
    templateId: selectedTemplate?._id,
  });

  const defaultQuestionnaireName = t('untitledTemplate');

  return (
    <>
      <Panel>
        <FlexPanelHeader
          heading={t('draftQuestionnaires')}
          sx={{ height: '60px', alignItems: 'center' }}
        >
          {!companyFeatureFlags.preQualEnabled ? (
            <Button
              small
              variant="primary"
              iconLeft="lock"
              onClick={upgradeDialog.open}
            >
              {t('questionnaireTemplate.createTemplate')}
            </Button>
          ) : hasManagePreQualPermission ? (
            <Button
              small
              iconLeft="plus"
              ml="20px"
              onClick={() => createEmptyTemplate({ currentCompanyId })}
            >
              {t('questionnaireTemplate.createTemplate')}
            </Button>
          ) : (
            null
          )}
        </FlexPanelHeader>
        <PanelDivider />
        {status === 'success' && totalItems > 0 ? (
          <DraftQuestionnaireTemplatesTable
            openDeleteModal={openDeleteModal}
          />
        ) : (
          <Flex
            height={status === 'loading' ? MIN_CELL_HEIGHT * pageSize : '455px'}
            flexDirection="column"
            justifyContent="center"
          >
            <PanelPadding>
              {status === 'loading' ? (
                <Loading fontSize={4} fontWeight={400} />
              ) : status === 'error' ? (
                <ErrorMessage fontSize={3} error={t('errors.getQuestionnaireTemplates')} />
              ) : (
                <Illustration
                  variant="empty-state"
                  label={t('questionnaireTemplate.companyHasNotCreatedTemplates')}
                />
              )}
            </PanelPadding>
          </Flex>
        )}
      </Panel>
      <Dialog
        heading={t('dialog.confirmTemplateDeletion.heading')}
        body={(
          <>
            <Text mb={2}>{t('dialog.confirmTemplateDeletion.body')}:</Text>
            <Text fontWeight={500}>{selectedTemplate?.name || defaultQuestionnaireName}</Text>
            <MessageBlock variant="warn" mt={3}>
              {t('dialog.confirmTemplateDeletion.warning')}
            </MessageBlock>
          </>
        )}
        okButtonText={t('dialog.confirmTemplateDeletion.okButtonText')}
        okButtonVariant="danger"
        isOpen={deleteQuestionnaireModal.isOpen}
        onOk={deleteTemplate}
        onCancel={deleteQuestionnaireModal.close}
        showCloseIcon
      />
      <UpgradeToUnlockPreQualDialog
        isOpen={upgradeDialog.isOpen}
        onCancel={upgradeDialog.close}
      />
    </>
  );
};

export const DraftQuestionnaireTemplates = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();
  const api = useApi();

  const pageSizeState = useLocalStorageState<number>({
    key: `${currentCompanyId}.${user._id}.draftQuestionnaireTemplates.pageSize`,
    defaultValue: 10,
  });

  return (
    <QuestionnaireTemplatesTableDataProvider
      queryTag="draftQuestionnaireTemplates"
      queryFn={api.getDraftQuestionnaireTemplates}
      pageSizeState={pageSizeState}
    >
      <DraftTemplatesContent />
    </QuestionnaireTemplatesTableDataProvider>
  );
};
