import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { DataCellProps } from '@deepstream/ui-kit/grid/core/utils';
import { EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { getCellValue } from '@deepstream/ui-kit/grid/EditableGrid/getCellValue';
import { PreWrap } from '../../PreWrapCell';
import { RfqAttachmentLink } from '../RfqAttachmentLink';
import { getBulletinAttachments } from '../../modules/RequestMessages/utils';

export const BulletinMessageCell = ({
  row,
  column,
}: DataCellProps<EditableGridColumn, any, any>) => {
  const value = getCellValue(row.original, column.original);
  const attachments = getBulletinAttachments(row.original);

  return (
    <div className="cell-content">
      <Stack gap={1}>
        <PreWrap>
          {value}
        </PreWrap>
        {attachments?.map((attachment) => (
          <RfqAttachmentLink
            key={attachment._id}
            attachment={attachment}
          />
        ))}
      </Stack>
    </div>
  );
};
