import { useCallback } from 'react';
import {
  ChangeType,
  DetailsChange,
  RfqEventChange,
  StageType,
  StageId,
  StageRemovedChange,
  StagesReorderedChange,
  StageUpdatedChange,
  Draft,
  SectionUpdatedChange,
  Stage,
} from '@deepstream/common/rfq-utils';
import { useQueryClient } from 'react-query';
import { v4 as uuid } from 'uuid';
import { last, findIndex, omit, compact } from 'lodash';
import { useTranslation } from 'react-i18next';
import { callAll } from '@deepstream/utils/callAll';
import { useCurrentCompanyId } from '../currentCompanyId';
import { useApi } from '../api';
import {
  getStageAddedRelatedChanges,
  getStageRemovedRelatedChanges,
  getStagesReorderedRelatedChanges,
  getStageUpdatedRelatedChanges,
} from './stageUtils';
import { useToaster } from '../toast';
import { useCurrentDraft } from './useCurrentDraft';
import { useMutation } from '../useMutation';
import { useDraftRfqStructureQueryKey } from '../useRfq';
import * as rfx from '../rfx';
import { useSystemFeatureFlags } from '../systemFeatureFlags';
import { StructureAuctionStage, StructureGeneralStage, StructureStage } from '../types';
import { isAuctionStage, sanitizePublishableEntity } from '../utils';
import { useWaitForRfqUnlock } from '../useWaitForUnlock';

type StageMutationArgs = {
  onSuccess?: (...args: any[]) => void;
  onError?: (...args: any[]) => void;
  onSettled?: (...args: any[]) => void;
};

export const useStageMutation = ({
  onSuccess,
  onError,
  onSettled,
}: StageMutationArgs) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const queryClient = useQueryClient();
  const { rfqId, isTemplate, isRevising, setMutationPending } = useCurrentDraft();
  const queryKey = useDraftRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
    isTemplate,
  });
  const waitForRfqUnlock = useWaitForRfqUnlock();
  const [updateMutation] = useMutation(
    (payload) => waitForRfqUnlock({
      isTemplate,
      command: () => api.updateDraftRequest(payload),
    }),
    {
      onSuccess,
      onError,
      onSettled: callAll(
        () => queryClient.invalidateQueries(queryKey),
        () => setMutationPending(false),
        onSettled,
      ),
    },
  );

  return useCallback(
    (changes: RfqEventChange[]) => {
      setMutationPending(true);

      return updateMutation({
        currentCompanyId,
        rfqId,
        changes,
        isTemplate,
        isRevising,
      });
    },
    [currentCompanyId, rfqId, isRevising, isTemplate, updateMutation, setMutationPending],
  );
};

export const useAddStage = () => {
  const toaster = useToaster();
  const { t } = useTranslation();
  const rfxStructure = rfx.useStructure<Draft>();
  const { stages } = rfxStructure;
  const systemFeatureFlags = useSystemFeatureFlags();

  const updateDraft = useStageMutation({
    onError: () => toaster.error(t('request.stages.toaster.stageAddedError')),
    onSuccess: () => toaster.success(t('request.stages.toaster.stageAddedSuccess')),
  });

  return useCallback(
    async (type: StageType, index?: number) => {
      const previousStage = index ? stages[index - 1] : last(stages);

      const newStage: StructureStage<Draft> = systemFeatureFlags?.auctionsEnabled && type === StageType.GENERAL
        ? {
          _id: uuid() as StageId,
          type,
          name: '',
          intentionDeadline: null,
          completionDeadline: null,
          isPrivate: previousStage?.isPrivate,
        } : systemFeatureFlags?.auctionsEnabled && type === StageType.AUCTION ? {
          _id: uuid() as StageId,
          type,
          name: t('general.auction'),
          startDate: null,
          completionDeadline: null,
          isPrivate: previousStage?.isPrivate,
        } : {
          _id: uuid() as StageId,
          name: '',
          intentionDeadline: null,
          completionDeadline: null,
          isPrivate: previousStage?.isPrivate,
        };

      // when there's an index, we add the changes to update the stageIds
      // in sections and exchangeDefs below by calling
      // `getStagesReorderedRelatedChanges`.
      // In this case, we must not call `getStageAddedRelatedChanges`
      // because it would wrongly append the new stageId to sections /
      // exchangeDef stages that don't include the preceding stage's
      // ID and thus should not be changed.
      //
      // Example 1:
      // Given an initial sequence a, b, c, where a new stage gets
      // appended, resulting in the sequence a, b, c, N.
      //
      // - N should be appended to all section / exchangeDef stage
      // sequences
      //
      // Example 2:
      // Given an initial sequence a, b, c, where a new stage gets
      // added between the first and the section stage, resulting in the
      // sequence a, N, b, c.
      //
      // - the stage sequence a, b, c should be changed to a, N, b, c
      // - the stage sequence b, c must remain as it is
      // - the stage sequence c must remain as it is
      const relatedChanges = index
        ? []
        : getStageAddedRelatedChanges(newStage._id, rfxStructure);

      let changes: DetailsChange[] = [
        {
          type: ChangeType.STAGE_ADDED,
          stage: newStage,
        },
        ...relatedChanges,
      ];

      if (index) {
        const oldStageIds = stages.map(stage => stage._id);
        const newStageIds = [
          ...oldStageIds.slice(0, index),
          newStage._id,
          ...oldStageIds.slice(index),
        ];

        const reorderRelatedChanges = getStagesReorderedRelatedChanges(newStageIds, previousStage!._id, rfxStructure);

        changes = changes.concat([
          {
            type: ChangeType.STAGES_REORDERED,
            stageIds: newStageIds,
          },
          ...reorderRelatedChanges,
        ]);
      }

      await updateDraft(changes);

      return newStage;
    },
    [stages, systemFeatureFlags?.auctionsEnabled, rfxStructure, updateDraft, t],
  );
};

export const useDeleteStage = ({ onSuccess, onError, onSettled }: StageMutationArgs = {}) => {
  const toaster = useToaster();
  const { t } = useTranslation();
  const rfxStructure = rfx.useStructure<Draft>();

  const updateDraft = useStageMutation({
    onError: callAll(
      onError,
      () => toaster.error(t('request.stages.toaster.stageDeletedError')),
    ),
    onSuccess: callAll(
      onSuccess,
      () => toaster.success(t('request.stages.toaster.stageDeletedSuccess')),
    ),
    onSettled,
  });

  return useCallback(
    async (stage: StructureStage<Draft>) => {
      const stageRemovedChange: StageRemovedChange = {
        type: ChangeType.STAGE_REMOVED,
        stageId: stage._id,
      };

      const relatedChanges = getStageRemovedRelatedChanges(stage, rfxStructure);

      await updateDraft([...relatedChanges, stageRemovedChange]);
    },
    [rfxStructure, updateDraft],
  );
};

export const useSwapConsecutiveStages = ({ onSuccess, onError, onSettled }: StageMutationArgs = {}) => {
  const toaster = useToaster();
  const { t } = useTranslation();
  const rfxStructure = rfx.useStructure<Draft>();
  const { stages } = rfxStructure;

  const updateDraft = useStageMutation({
    onError: callAll(
      onError,
      () => toaster.error(t('request.stages.toaster.stageMovedError')),
    ),
    onSuccess: callAll(
      onSuccess,
      () => toaster.success(t('request.stages.toaster.stageMovedSuccess')),
    ),
    onSettled,
  });

  return useCallback(
    async (fromIndex, toIndex) => {
      const stageUpdatedChanges: StageUpdatedChange[] = [];
      const stageIds = stages.map(stage => stage._id);
      const fromStage = stages[fromIndex];
      const toStage = stages[toIndex];
      stageIds[fromIndex] = toStage._id;
      stageIds[toIndex] = fromStage._id;
      const shouldEditVisibility = toStage.isPrivate && !fromStage.isPrivate;

      if (shouldEditVisibility) {
        const stageUpdatedChange: StageUpdatedChange = {
          type: ChangeType.STAGE_UPDATED,
          stage: fromIndex === 0
            ? { ...sanitizePublishableEntity(toStage), isPrivate: false } as Stage<Draft>
            : { ...sanitizePublishableEntity(fromStage), isPrivate: true } as Stage<Draft>,
        };

        stageUpdatedChanges.push(stageUpdatedChange);
      }

      const stagesReorderedChange: StagesReorderedChange = {
        type: ChangeType.STAGES_REORDERED,
        stageIds,
      };

      const relatedChanges = getStagesReorderedRelatedChanges(stageIds, toStage._id, rfxStructure);

      await updateDraft([
        stagesReorderedChange,
        ...stageUpdatedChanges,
        ...relatedChanges,
      ]);
    },
    [stages, rfxStructure, updateDraft],
  );
};

export const useUpdateStage = ({ onSuccess, onError, onSettled }: StageMutationArgs = {}) => {
  const toaster = useToaster();
  const { t } = useTranslation();
  const rfxStructure = rfx.useStructure<Draft>();
  const { sectionById, stages } = rfxStructure;

  const updateDraft = useStageMutation({
    onError: callAll(
      onError,
      () => toaster.error(t('request.stages.toaster.stageUpdatedError')),
    ),
    onSuccess: callAll(
      onSuccess,
      () => toaster.success(t('request.stages.toaster.stageUpdatedSuccess')),
    ),
    onSettled,
  });

  return useCallback(
    async (
      stage: StructureStage<Draft> & { duration?: number | null },
      values: StructureStage<Draft> & { duration?: number | null },
    ) => {
      const stageIndex = findIndex(stages, ['_id', stage._id]);
      const updatedStage = isAuctionStage(values)
        ? omit({
          ...stage,
          ...values,
        }, 'duration') as StructureAuctionStage<Draft>
        : omit({
          ...stage,
          ...values,
          name: values.name.trim(),
        }, 'duration') as StructureGeneralStage<Draft>;

      const auctionLineItemsSection = rfx.getAuctionLineItemsSection(sectionById, stage._id);

      await updateDraft(compact([
        ...getStageUpdatedRelatedChanges(
          updatedStage,
          stageIndex,
          rfxStructure,
        ),
        {
          type: ChangeType.STAGE_UPDATED,
          stage: sanitizePublishableEntity(updatedStage),
        } as StageUpdatedChange,
        auctionLineItemsSection && stage.duration !== values.duration
          ? {
            type: ChangeType.SECTION_UPDATED,
            section: {
              _id: auctionLineItemsSection._id,
              auctionRules: {
                ...auctionLineItemsSection.auctionRules,
                duration: values.duration,
              },
            },
          } as SectionUpdatedChange
          : null,
      ]));
    },
    [stages, updateDraft, rfxStructure, sectionById],
  );
};
