import { useMemo, useEffect, useCallback } from 'react';
import { conforms, map, compact, filter, find, omit, first, isEmpty } from 'lodash';
import { SectionType, RfqId, ActionType, PageType, StageType, Live, renderPageName, RfxSection } from '@deepstream/common/rfq-utils';
import { Text, Flex, Heading } from 'rebass/styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';
import { omitNil } from '@deepstream/utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { InlineButton } from '@deepstream/ui-kit/elements/button/InlineButton';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils';
import { RfqIdProvider, RecipientIdProvider, useLiveRfqStructure, useRfqId, useRecipientId } from './useRfq';
import { LoadingPanel } from './ui/Loading';
import { ErrorPanel } from './ui/ErrorMessage';
import { BidSection } from './BidSection';
import * as rfx from './rfx';
import * as TabsVertical from './ui/TabsVertical';
import { SidebarLayout } from './ui/ProfileLayout';
import { NotificationCounter } from './modules/Notifications/NotificationCounter';
import { useCurrentCompanyId } from './currentCompanyId';
import { useDeviceSize } from './ui/useDeviceSize';
import { ExchangeNavigationProvider, useExchangeModalState } from './useExchangeModalState';
import { RfxExchangeModal } from './ExchangeModal/RfxExchangeModal';
import { DeadlinesPassedProvider } from './deadline';
import { SwitchToExchangeProvider } from './ExchangeModal/SwitchToExchange';
import { RequestHooksProvider } from './modules/Request/RequestHooksProvider';
import { HiddenRequestPagePlaceholder } from './HiddenRequestPagePlaceholder';
import * as Tabs from './ui/Tabs';
import { Counter } from './ui/Badge';
import { ExchangeDefFieldValueProvider } from './ExchangeDefFieldValueContext';
import { useBidExchangeSwitcherTargets } from './ExchangeModal/useRfxExchangeSwitcherTargets';
import { PageFooterBidProgress } from './ExchangeModal/PageFooterBidProgress';
import { RfxBidAutoAdvancer } from './ExchangeModal/RfxBidAutoAdvancer';
import { useRequestRecipientNavigation, useRequestSentNavigation } from './appNavigation';
import { isSectionVisibleInStage } from './draft/section';

const getNotificationFilterForSection = (
  rfqId: RfqId,
  section: RfxSection,
  recipientId: string,
  currentCompanyId: string,
) => {
  const isSender = currentCompanyId !== recipientId;

  if (section.type === SectionType.BULLETINS) {
    // For now bulletins don't support comments so we don't filter for `exchangeReplySent`
    return conforms({
      to: to => to.companyId === currentCompanyId,
      domain: domain => domain === (isSender ? NotificationDomain.RFQ_SENT : NotificationDomain.RFQ_RECEIVED),
      action: action => [NotificationAction.REQUEST_BULLETIN_ADDED, NotificationAction.REQUEST_BULLETIN_UPDATED].includes(action),
      meta: meta => meta.rfqId === rfqId && meta.sectionId === section._id,
    });
  } else {
    return conforms({
      to: to => to.companyId === currentCompanyId,
      domain: domain => domain === (isSender ? NotificationDomain.RFQ_SENT : NotificationDomain.RFQ_RECEIVED),
      action: action => action === NotificationAction.EXCHANGE_REPLY_SENT,
      meta: meta => meta.rfqId === rfqId && section.exchangeDefIds.includes(meta.exchangeId) && meta.actionType === ActionType.NONE,
    });
  }
};

const useBidPage = () => {
  const page = rfx.usePage();
  const recipientId = useRecipientId({ required: true });
  const { sectionById, bidById, exchangeDefById } = rfx.useStructure();
  const { activatedStageIds, enteredStageIds } = bidById[recipientId];
  const isSender = rfx.useIsSender();
  const includedStageIds = isSender ? enteredStageIds : activatedStageIds;
  const contextStageId = rfx.useStageId();
  const requirementGroupId = rfx.useRequirementGroupId();

  return useMemo(() => {
    // HACK: Shouldn't need to `compact` but there's a bug which seems to include sections that don't exist
    // @ts-expect-error ts(18047) FIXME: 'page' is possibly 'null'.
    const sections = compact(map(page.sections, sectionId => sectionById[sectionId]));

    const sectionFilterPredicate = !contextStageId ? (
      // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
      (section: RfxSection) => !section.stages || includedStageIds.includes(first(section.stages))
    ) : requirementGroupId === 'general' ? (
      (section: RfxSection) => isSectionVisibleInStage(section, exchangeDefById, contextStageId) && isEmpty(section.lotIds)
    ) : requirementGroupId ? (
      (section: RfxSection) =>
        isSectionVisibleInStage(section, exchangeDefById, contextStageId) && first(section.lotIds) === requirementGroupId
    ) : (
      (section: RfxSection) => isSectionVisibleInStage(section, exchangeDefById, contextStageId)
    );

    const visibleSections = filter(sections, sectionFilterPredicate) as RfxSection[];

    for (const section of visibleSections) {
      section.exchangeDefIds = filter(
        section.exchangeDefIds,
        exchangeDefId => {
          const { stages } = exchangeDefById[exchangeDefId];

          // When section.stages is defined, we've already checked stages
          // on the section level and ignore the exchangeDef's `stages`
          // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
          return Boolean(section.stages || !stages || includedStageIds.includes(first(stages)));
        },
      ) as string[];
    }

    return {
      page,
      sections: visibleSections,
    };
  }, [contextStageId, exchangeDefById, includedStageIds, page, requirementGroupId, sectionById]);
};

const BidSectionList = () => {
  const { sections } = useBidPage();

  return (
    <Stack gap={4}>
      <rfx.SectionsProvider sections={sections}>
        {sections.map(section => (
          <rfx.SectionProvider key={section._id} section={section}>
            <BidSection />
          </rfx.SectionProvider>
        ))}
      </rfx.SectionsProvider>
    </Stack>
  );
};

// Only used on legacy bid pages
// TODO [post-lots] remove when lots are always enabled
const AuctionMessageBlock = () => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const recipientId = useRecipientId();
  const { bidById, stages } = rfx.useStructure<Live>();
  const requestReceivedNavigation = useRequestRecipientNavigation();
  const requestSentNavigation = useRequestSentNavigation();

  const bid = bidById[recipientId];

  const isSender = currentCompanyId !== recipientId;

  const isAuctionAccessibleToRecipient = useMemo(
    () => {
      const auctionStage = find(stages, { type: StageType.AUCTION });

      return auctionStage && bid.activatedStageIds.includes(auctionStage._id);
    },
    [stages, bid],
  );

  return isSender ? (
    <MessageBlock variant="info">
      <Trans i18nKey="request.bidPageHeader.youCanAccessTheAuctionBids">
        <InlineButton onClick={() => requestSentNavigation.navigateToAuction()} />
      </Trans>
    </MessageBlock>
  ) : isAuctionAccessibleToRecipient ? (
    <MessageBlock variant="info">
      <Trans i18nKey="request.bidPageHeader.youCanAccessTheAuction">
        <InlineButton onClick={() => requestReceivedNavigation.navigateToAuction()} />
      </Trans>
    </MessageBlock>
  ) : (
    <MessageBlock variant="info">
      {t('request.bidPageHeader.auctionWillBeAccessible')}
    </MessageBlock>
  );
};

const iconBySectionType = {
  [SectionType.CHAT]: 'comment-o',
  [SectionType.CLARIFICATIONS]: 'question',
  [SectionType.BULLETINS]: 'list-ul',
};

const MessagesBidSectionTabs = ({
  sectionId,
  navigateToBidSection,
}: {
  sectionId: string;
  navigateToBidSection: (sectionId: string, replace?: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { sections } = useBidPage();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqId = useRfqId();
  const recipientId = useRecipientId();
  const { isExtraSmall, isSmall } = useDeviceSize();

  const getSectionId = (index: number) => sections[index]._id;
  const getSectionIndex = (sectionId: string) => sections.findIndex(section => section._id === sectionId);

  useEffect(
    () => {
      if (!sectionId) {
        navigateToBidSection(sections[0]._id, true);
      }
    },
    [navigateToBidSection, sectionId, sections],
  );

  return (
    <TabsVertical.Tabs
      index={getSectionIndex(sectionId)}
      onChange={index => navigateToBidSection(getSectionId(index))}
    >
      <SidebarLayout
        sidebar={
          <TabsVertical.TabListPanel heading={t('request.messages')}>
            {sections.map(section => (
              <TabsVertical.Tab key={section._id}>
                <Flex alignItems="center">
                  {iconBySectionType[section.type] && (
                    <Icon icon={iconBySectionType[section.type]} mr={2} fixedWidth />
                  )}
                  <Text flex={1}>
                    {section.type === SectionType.BULLETINS ? (
                      t('request.bulletin.bulletin_other')
                    ) : section.type === SectionType.CLARIFICATIONS ? (
                      t('request.clarifications.clarification_other')
                    ) : (
                      t('request.chats.chat')
                    )}
                  </Text>
                  <NotificationCounter
                    filter={getNotificationFilterForSection(rfqId, section, recipientId, currentCompanyId)}
                    ml={1}
                  />
                </Flex>
              </TabsVertical.Tab>
            ))}
          </TabsVertical.TabListPanel>
        }
        main={
          <TabsVertical.TabPanels>
            {sections.map((section, index) => (
              <TabsVertical.TabPanel key={section._id} tabIndex={index}>
                <rfx.SectionProvider section={section}>
                  <BidSection />
                </rfx.SectionProvider>
              </TabsVertical.TabPanel>
            ))}
          </TabsVertical.TabPanels>
        }
        sidebarStyle={!isExtraSmall && !isSmall ? { maxWidth: '232px', flex: '0 0 auto' } : undefined}
        mainStyle={!isExtraSmall && !isSmall ? { flex: '1 1 auto' } : undefined}
      />
    </TabsVertical.Tabs>
  );
};

type BidSectionsProps = {
  recipientId?: string;
  sectionId?: string;
  exchangeId?: string;
};

const BidSections = ({ exchangeId, sectionId }: BidSectionsProps) => {
  const structure = rfx.useStructure<Live>();
  const page = rfx.usePage();
  const pagePermissions = rfx.usePagePermissions();
  const recipientId = useRecipientId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const navigate = useNavigate();
  const sentNavigation = useRequestSentNavigation();
  const recipientNavigation = useRequestRecipientNavigation();
  const contextStageId = rfx.useStageId();
  const requirementGroupId = rfx.useRequirementGroupId();

  const navigateToExchange = ({ recipientId, exchangeId }: { recipientId?: string; exchangeId: string }) =>
    navigate({
      search: search => omitNil({ ...search, recipientId, exchangeId }),
    });

  const navigateToSection = (sectionId: string, replace?: boolean) =>
    navigate({
      search: search => omitNil({ ...search, sectionId }),
      replace,
    });

  const closeExchange = () =>
    navigate({
      search: search => omitNil(omit(search, ['exchangeId', 'recipientId'])),
    });

  useWatchValue(
    exchangeId,
    (exchangeId) => {
      if (exchangeId) {
        exchangeModal.open({ exchangeId, recipientId });
      } else {
        exchangeModal.close();
      }
    },
  );

  // @ts-expect-error ts(18047) FIXME: 'page' is possibly 'null'.
  const isMessagesPage = page.type === PageType.CHAT;

  const exchangeModal = useExchangeModalState({
    exchangeId,
    recipientId,
  });

  const isRecipient = currentCompanyId === recipientId;
  const exchangeSwitcherTargets = useBidExchangeSwitcherTargets(isMessagesPage);

  const isAnyEnteredStageActive = rfx.useIsAnyEnteredStageActive(structure.newFeaturesDisabled);

  return pagePermissions.canRead ? (
    <ExchangeNavigationProvider navigateToExchange={navigateToExchange}>
      <RequestHooksProvider>
        {/*
         // @ts-expect-error ts(18047) FIXME: 'page' is possibly 'null'. */}
        {page.type === PageType.AUCTION ? (
          structure.newFeaturesDisabled ? <AuctionMessageBlock /> : null
        ) : (
          <DeadlinesPassedProvider haveDeadlinesPassed={!isAnyEnteredStageActive}>
            {!isMessagesPage && (
              <Heading fontSize={6} fontWeight={500} mb="20px">
                {/*
                 // @ts-expect-error ts(18047) FIXME: 'page' is possibly 'null'. */}
                {page.name}
              </Heading>
            )}
            {isMessagesPage ? (
              <MessagesBidSectionTabs
                // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
                sectionId={sectionId}
                navigateToBidSection={navigateToSection}
              />
            ) : (
              <BidSectionList />
            )}
            <SwitchToExchangeProvider
              switchToExchange={(target) => {
                if (!isRecipient && structure.newFeaturesDisabled) {
                  // @ts-expect-error ts(18047) FIXME: 'target' is possibly 'null'.
                  sentNavigation.navigateToSupplierBid(target.pageId, target.exchangeId);
                } else {
                  // @ts-expect-error ts(18047) FIXME: 'target' is possibly 'null'.
                  recipientNavigation.navigateToBid(contextStageId, requirementGroupId, target.pageId, target.exchangeId);
                }
              }}
              verticalTargets={exchangeSwitcherTargets}
            >
              <RfxExchangeModal
                showExchangeSwitcher={!isRecipient}
                showFooter={isRecipient}
                FooterAutoAdvancer={isMessagesPage ? undefined : RfxBidAutoAdvancer}
                FooterProgress={isMessagesPage ? undefined : PageFooterBidProgress}
                {...exchangeModal as any}
                close={() => closeExchange()}
                onLoad={exchange => {
                  if (isMessagesPage) {
                    // If we're using the tabbed bid sections, then ensure that the
                    // selected section is the exchange's parent section.
                    navigateToSection(exchange.def.sectionId!);
                  }
                }}
              />
            </SwitchToExchangeProvider>
          </DeadlinesPassedProvider>
        )}
      </RequestHooksProvider>
    </ExchangeNavigationProvider>
  ) : (
    <HiddenRequestPagePlaceholder />
  );
};

const RecipientPageTabs = ({ hideActionCount }: { hideActionCount?: boolean }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { pages, enteredBidPageIds, enteredPageIdsByRequirementGroupIdByStageId } = rfx.useStructure<Live>();
  const page = rfx.usePage();
  const bid = rfx.useBid();
  const recipientId = useRecipientId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contextStageId = rfx.useStageId();
  const requirementGroupId = rfx.useRequirementGroupId();
  const navigation = useRequestRecipientNavigation();

const isRecipient = currentCompanyId === recipientId;

  // @ts-expect-error ts(18047) FIXME: 'page' is possibly 'null'.
  const pageId = page._id;

  const tabbedPages = useMemo(() => {
    const pageIds = !contextStageId ? (
      enteredBidPageIds
    ) : (
      // @ts-expect-error ts(18048) FIXME: 'enteredPageIdsByRequirementGroupIdByStageId' is possibly 'undefined'.
      enteredPageIdsByRequirementGroupIdByStageId[contextStageId]?.[requirementGroupId || 'general'] || []
    );

    // @ts-expect-error ts(18048) FIXME: 'pageIds' is possibly 'undefined'.
    return pages.filter(page => pageIds.includes(page._id));
  }, [contextStageId, enteredBidPageIds, enteredPageIdsByRequirementGroupIdByStageId, pages, requirementGroupId]);

  const selectedPageIndex = tabbedPages.findIndex(page => page._id === pageId);

  const onTabsChange = useCallback(
    (index) => {
      navigation.navigateToBid(
        contextStageId,
        requirementGroupId,
        tabbedPages[index]._id,
      );
    },
    [navigation, contextStageId, requirementGroupId, tabbedPages],
  );

  const countedProgressProp = isRecipient
    ? 'awaitingRecipient' as const
    : 'awaitingSender' as const;

  return (
    <Tabs.Tabs index={selectedPageIndex} canOverflow onChange={onTabsChange}>
      <Tabs.TabList
        style={{
          backgroundColor: 'transparent',
          fontSize: theme.fontSizes[2],
          marginBottom: '20px',
        }}
      >
        {tabbedPages.map((page) => (
          <Tabs.Tab key={page._id} style={{ padding: '8px 12px' }}>
            <Flex alignItems="center">
              {renderPageName(page, t)}
              {/*
                TODO [post-lots] when lots are always enabled, remove this condition -- on the new
                recipient pages with lots support, the auction page is not part of the
                bid pages
              */}
              {page.type === PageType.AUCTION || hideActionCount ? (
                null
              ) : contextStageId ? (
                <Counter
                  count={bid.progressByPageIdByRequirementGroupIdByStageId?.[contextStageId]?.[requirementGroupId || 'general']?.[page._id]?.[countedProgressProp]}
                  ml={1}
                />
              ) : (
                <Counter count={bid.progressByPageId?.[page._id]?.[countedProgressProp]} ml={1} />
              )}
            </Flex>
          </Tabs.Tab>
        ))}
      </Tabs.TabList>
    </Tabs.Tabs>
  );
};

type BidSectionsContainerProps = {
  rfqId: string;
  pageId: string;
  recipientId: string;
  sectionId?: string;
  exchangeId?: string;
  hideActionCount?: boolean;
};

export const BidSectionsContainer = ({
  rfqId,
  recipientId,
  pageId,
  sectionId,
  exchangeId,
  hideActionCount,
}: BidSectionsContainerProps) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const isRecipient = currentCompanyId === recipientId;
  const stageId = rfx.useStageId();

  const { data: structure, isLoading, isError, isSuccess } = useLiveRfqStructure({
    rfqId,
    recipientId,
  });

  const page = structure?.pageById[pageId];

  return (
    <RfqIdProvider rfqId={rfqId}>
      <RecipientIdProvider recipientId={recipientId}>
        {isLoading ? (
          <LoadingPanel />
        ) : isError ? (
          <ErrorPanel error={t('errors.unexpected')} />
        ) : isSuccess && structure ? (
          <rfx.StructureProvider structure={structure}>
            <rfx.StateProvider isLive>
              <ExchangeDefFieldValueProvider>
                <rfx.RecipientsProvider recipients={structure.recipients}>
                  {/*
                   // @ts-expect-error ts(2322) FIXME: Type 'Page | undefined' is not assignable to type 'Page'. */}
                  <rfx.PageProvider page={page}>
                    {/*
                     // @ts-expect-error ts(18048) FIXME: 'page' is possibly 'undefined'. */}
                    {(isRecipient || stageId) && page.type !== PageType.CHAT && (
                      <RecipientPageTabs hideActionCount={hideActionCount} />
                    )}
                    <BidSections
                      exchangeId={exchangeId}
                      sectionId={sectionId}
                    />
                  </rfx.PageProvider>
                </rfx.RecipientsProvider>
              </ExchangeDefFieldValueProvider>
            </rfx.StateProvider>
          </rfx.StructureProvider>
          ) : null}
      </RecipientIdProvider>
    </RfqIdProvider>
  );
};
