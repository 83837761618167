import { Flex, Box } from 'rebass/styled-components';
import { Attachment } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { useApi } from '../../api';
import { useToaster } from '../../toast';
import { useMutation } from '../../useMutation';
import { downloadUrl } from '../../useDownload';
import { useContractId } from './contract';
import { FileList } from '../../ui/FileList';
import { fakeUploadFn } from '../../ui/fakeUploadFn';

export const useDownloadContractAttachment = () => {
  const { t } = useTranslation('contracts');
  const api = useApi();
  const toaster = useToaster();
  const contractId = useContractId();

  return useMutation(
    async ({ _id: attachmentId }: Attachment) => {
      const { url } = await api.getContractAttachmentDownloadUrl({ contractId, attachmentId });
      downloadUrl(url, '');
    },
    {
      onSuccess: () => toaster.success(t('toaster.documentDownloaded.success')),
      onError: () => toaster.error(t('toaster.documentDownloaded.failed')),
    },
  );
};

export const ContractAttachment = ({
  attachment,
  truncate = false,
  showTooltip,
}: {
  attachment,
  truncate?: boolean;
  showTooltip?: boolean;
}) => {
  const [download, { isLoading }] = useDownloadContractAttachment();

  return (
    <Flex
      alignItems="center"
      sx={{ maxWidth: '100%' }}
    >
      <IconText
        isIconRegular
        gap={2}
        icon="file"
        iconColor="subtext"
        text={attachment.name}
        truncate={truncate}
        showTooltip={showTooltip}
      />
      <IconButton
        fixedWidth
        icon="download"
        color="primary"
        onClick={event => {
          event.stopPropagation();
          download(attachment);
        }}
        disabled={isLoading}
        ml={2}
      />
    </Flex>
  );
};

export const ContractAttachmentList = ({ attachments, ...props }) => {
  const [download] = useDownloadContractAttachment();

  return (
    <Box maxWidth={425} width="fit-content" {...props}>
      <FileList
        isReadOnly
        small
        initialAttachments={attachments}
        uploadFn={fakeUploadFn}
        downloadFn={download}
      />
    </Box>
  );
};
