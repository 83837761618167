import { useState, useCallback } from 'react';

import { LineItemExchangeFields } from '@deepstream/common/rfq-utils';
import { useModalState } from '../../../../ui/useModalState';
import { ConfigurableFieldFormValues } from '../types';
import { TextFieldProps } from '../../../../form/TextField';

export type InputsConfig = {
  fieldName?: Pick<TextFieldProps, 'disabled' | 'helperText'>;
  // Deny enabling both toggles at the same time
  divergentToggles?: boolean;
  buyerProvidedToggle?: Pick<React.HTMLProps<HTMLInputElement>, 'disabled' | 'onChange'>;
  supplierResponseToggle?: Pick<React.HTMLProps<HTMLInputElement>, 'disabled' | 'onChange'>;

  showSharedFieldNameWarning?: boolean;
  enableDecimalPlaces?: boolean;
  enableBuyerProvided?: boolean;
  enableSupplierResponse?: boolean;
  isFormula?: boolean;
  disableFormulaFormatSelection?: boolean;
  isFixedSupplierResponseField?: boolean;
};

export type RelativeFieldInsertion = { fieldId: string; direction: 'left' | 'right' };

export type ConfigureFieldModalConfig<T extends ConfigurableFieldFormValues,> = {
  isEdit?: boolean;
  relativeFieldInsertion?: RelativeFieldInsertion | null;
  heading: string;
  submitLabel: string;
  initialValues?: T;
  inputsConfig?: InputsConfig;
  fields?: LineItemExchangeFields;
};

export type ConfigureFieldModalProps<T extends ConfigurableFieldFormValues,> = {
  open: (config: ConfigureFieldModalConfig<T>) => void;
  close: () => void;
  isOpen: boolean;
  config?: ConfigureFieldModalConfig<T>;
};

export const useConfigureFieldModal = <T extends ConfigurableFieldFormValues>() => {
  const [config, setConfig] = useState<ConfigureFieldModalConfig<T> | undefined>(undefined);

  const modal = useModalState();

  const open = useCallback((config : ConfigureFieldModalConfig<T>) => {
    setConfig(config);
    modal.open();
  }, [modal]);

  const close = useCallback(() => {
    modal.close();
    setConfig(undefined);
  }, [modal]);

  return {
    open,
    close,
    isOpen: modal.isOpen,
    config,
  };
};
