import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';
import { HirePeriod, HirePeriodExchangeDefinition, timePeriods } from '@deepstream/common/rfq-utils';
import { compact, isEmpty } from 'lodash';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { withProps } from '@deepstream/ui-utils/withProps';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { FormTableStyles, StaticTableStyles } from '../TableStyles';
import { Table } from '../Table';
import { Required } from '../Required';
import { ExchangeDefActionsCell, NumberCell, NumberFieldCell, SelectFieldCell, ValidationErrorCell } from './cell';
import { createDayRate, useExchangeDefsField } from './exchangeDefs';
import { nestCells } from '../nestCells';
import * as rfx from '../rfx';
import { TextCell } from '../TextCell';
import { ExchangeList } from '../ExchangeTable';
import { useDeviceSize } from '../ui/useDeviceSize';
import * as draft from './draft';
import * as validation from './validation';
import { ErrorMessage } from '../ui/ErrorMessage';
import { ExchangeDefRowCells } from './ExchangeDefRowCells';
import { ExchangeDefListItem } from './ExchangeDefListItem';

export const intervalTypes = ['Firm', 'Option'];

const AddRateButton = (props: ButtonProps) => {
  const { t } = useTranslation();
  const isSender = rfx.useIsSender();

  return (
    <Button
      small
      variant={isSender ? 'secondary' : 'primary-outline'}
      iconLeft="plus"
      {...props}
    >
      {t('request.vesselPricing.hirePeriods.addRate')}
    </Button>
  );
};

const FieldColumnHeader = ({ column }) => (
  <>
    {column.label}
    {column.required && <Required />}
  </>
);

export const PeriodValidationErrorVell = ({ cell: { value }, row, fieldName }) => {
  const showValidationErrors = draft.useShowValidationErrors();
  const { errors } = validation.useErrors(`${fieldName}[${row.index}]`);

  const error = errors.amount || errors.unit;

  return showValidationErrors && error ? (
    <ErrorMessage fontSize={2} error={error} />
  ) : (
    value
  );
};

export const DayRatesTable = ({ hirePeriodId }: { hirePeriodId: string }) => {
  const { t } = useTranslation();
  const { isExtraSmall } = useDeviceSize();
  const intervalsByHirePeriodId = rfx.useIntervalsByHirePeriodId();
  const intervals = intervalsByHirePeriodId[hirePeriodId];

  const showValidationErrors = draft.useShowValidationErrors();
  const fieldName = `intervalsByHirePeriodId[${hirePeriodId}]`;
  const { error } = validation.useError(fieldName);

  const { DayRateErrorCell, PeriodErrorCell, CustomListItem, CustomRowCells } = useMemo(() => ({
    DayRateErrorCell: withProps(ValidationErrorCell, { fieldName }),
    PeriodErrorCell: withProps(PeriodValidationErrorVell, { fieldName }),
    CustomListItem: withProps(ExchangeDefListItem, { fieldName }),
    CustomRowCells: withProps(ExchangeDefRowCells, { fieldName }),
  }), [fieldName]);

  const columns = useMemo(
    () => [
      {
        id: 'intervalType',
        Header: t('general.type'),
        accessor: ({ intervalType }: HirePeriodExchangeDefinition) => (
          t(`request.vesselPricing.intervalType.${intervalType}`)
        ),
        Cell: nestCells(DayRateErrorCell, TextCell),
      },
      {
        id: 'period',
        Header: t('request.vesselPricing.hirePeriods.period'),
        accessor: ({ amount, unit }: HirePeriodExchangeDefinition) => (
          amount
            ? t(`general.${unit}Count`, { count: amount })
            : undefined
        ),
        Cell: nestCells(PeriodErrorCell, TextCell),
        defaultValue: <EmDash />,
      },
      {
        id: 'quantity',
        Header: t('general.quantity'),
        accessor: 'quantity',
        Cell: nestCells(DayRateErrorCell, NumberCell),
      },
    ],
    [t, DayRateErrorCell, PeriodErrorCell],
  );

  return showValidationErrors && error ? (
    <PanelPadding bg="errorBackground" py="16px">
      <ErrorMessage fontSize={2} error={error} />
    </PanelPadding>
  ) : isEmpty(intervals) ? (
    <PanelPadding>
      <Text color="subtext" fontSize={2}>
        {t('request.vesselPricing.hirePeriods.noDayRatesAdded')}
      </Text>
    </PanelPadding>
  ) : isExtraSmall ? (
    <ExchangeList
      columns={columns}
      exchanges={intervals}
      onRowClick={null}
      CustomListItem={CustomListItem}
    />
  ) : (
    <StaticTableStyles>
      <Table
        columns={columns}
        data={intervals}
        isSortable={false}
        CustomRowCells={CustomRowCells}
      />
    </StaticTableStyles>
  );
};

export const DayRatesTableField = ({
  hirePeriod,
  fieldName = 'exchangeDefs',
  isSectionObsolete,
}: {
  hirePeriod: HirePeriod;
  fieldName: string;
  isSectionObsolete?: boolean;
}) => {
  const { t } = useTranslation();

  const { exchangeDefs, addExchangeDef } = useExchangeDefsField(
    fieldName,
    createDayRate(hirePeriod._id),
  );

  const columns = useMemo(
    () => compact([
      {
        id: 'intervalType',
        Header: FieldColumnHeader,
        label: t('general.type'),
        accessor: 'intervalType',
        Cell: withProps(SelectFieldCell, { fieldName }),
        required: true,
        options: intervalTypes.map((value) => ({
          value,
          label: t(`request.vesselPricing.intervalType.${value}`),
        })),
      },
      {
        id: 'amount',
        Header: FieldColumnHeader,
        label: t('request.vesselPricing.hirePeriods.period'),
        accessor: 'amount',
        Cell: withProps(NumberFieldCell, { fieldName }),
        format: 'integer.positive',
        required: true,
        width: 83,
      },
      {
        id: 'unit',
        Header: FieldColumnHeader,
        label: t('general.unit'),
        accessor: 'unit',
        Cell: withProps(SelectFieldCell, { fieldName }),
        required: true,
        options: timePeriods.map((value) => ({
          value,
          label: t(`general.${value}`),
        })),
      },
      {
        id: 'quantity',
        Header: FieldColumnHeader,
        label: t('general.quantity'),
        accessor: 'quantity',
        Cell: withProps(NumberFieldCell, { fieldName }),
        format: 'integer.positive',
        required: true,
        width: 83,
      },
      !isSectionObsolete && {
        id: 'menu',
        Header: () => '',
        accessor: null,
        width: 48,
        Cell: hirePeriod.isObsolete
          ? () => null
          : withProps(ExchangeDefActionsCell, { fieldName, allowObsoleteStateChange: false }),
      },
    ]),
    [t, hirePeriod, fieldName, isSectionObsolete],
  );

  return (
    <>
      {exchangeDefs.length > 0 ? (
        <FormTableStyles>
          <Table columns={columns} data={exchangeDefs} />
        </FormTableStyles>
      ) : (
        <Text color="subtext" fontSize={2} mb={2}>
          {t('request.vesselPricing.hirePeriods.noDayRatesAdded')}
        </Text>
      )}
      {!isSectionObsolete && (
        <AddRateButton
          type="button"
          onClick={() => addExchangeDef()}
          mt={2}
          disabled={hirePeriod.isObsolete}
        />
      )}
    </>
  );
};
