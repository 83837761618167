import { memo } from 'react';
import * as React from 'react';
import { Text } from 'rebass/styled-components';
import * as PreQ from '@deepstream/common/legacy-pre-q-utils';
import { useTranslation } from 'react-i18next';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { UploadFn, DownloadFn } from '../ui/types';
import { Elements } from './Elements'; // eslint-disable-line import/no-cycle
import { ErrorBoundary } from '../ui/ErrorBoundary';

type QuestionProps = {
  question: PreQ.Question;
  answer: PreQ.Answer;
  isReadOnly: boolean;
  isLocked?: boolean;
  upload?: UploadFn;
  download?: DownloadFn;
};

export const Question: React.FC<QuestionProps> = memo(({ question, answer, isReadOnly, isLocked, upload, download }) => {
  const { t } = useTranslation('companyProfile');

  return (
    <Panel key={question.key} p={3}>
      <ErrorBoundary message={t('error.errorDisplayingQuestion')}>
        <Stack gap={2}>
          <Text fontWeight={500} lineHeight={1.5}>
            {t(`questionnaire.sentence.${question.key}`)}
          </Text>
          <Stack gap={3}>
            <Elements
              parentName={question.key}
              elements={question.elements}
              answer={answer}
              isReadOnly={isReadOnly}
              isLocked={isLocked}
              upload={upload}
              download={download}
            />
          </Stack>
        </Stack>
      </ErrorBoundary>
    </Panel>
  );
});
