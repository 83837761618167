export enum RequestsReportingTab {
  BUDGET = 'budget',
  VALUE = 'value',
  SAVINGS = 'savings',
  TEAM_PRODUCTIVITY = 'teamProductivity',
  SUPPLIER_ENGAGEMENT = 'supplierEngagement',
  AUCTIONS = 'auctions',
}

export const tabsWithoutAuctions = [
  RequestsReportingTab.BUDGET,
  RequestsReportingTab.VALUE,
  RequestsReportingTab.SAVINGS,
  RequestsReportingTab.TEAM_PRODUCTIVITY,
  RequestsReportingTab.SUPPLIER_ENGAGEMENT,
];

export const tabsWithAuctions = [
  ...tabsWithoutAuctions,
  RequestsReportingTab.AUCTIONS,
];
