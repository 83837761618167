import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { compact, fromPairs, isEqual, pickBy } from 'lodash';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { withProps } from '@deepstream/ui-utils/withProps';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { DateFormat } from '@deepstream/utils';
import { LeavePageModal } from '../../../draft/LeavePageModal';
import { TextField } from '../../../form/TextField';
import { PreWrapValueOrEmDash, PropertyList, ValueOrEmDash } from '../../../PropertyList';
import { LABEL_WIDTH, SummaryLabelConfigProvider } from '../../../draft/SummaryLabelConfigProvider';
import { Validation } from '../../../draft/validation';
import { ValidationPropertyRow } from '../../../draft/ValidationPropertyRow';
import { ValidationErrorValue } from '../../../draft/ValidationErrorValue';
import { nestValues } from '../../../nestValues';
import { useQuestionnaireTemplateActions, useQuestionnaireTemplateData, useQuestionnaireTemplateState } from './questionnaireTemplateUtils';
import { SummaryPanelHeader } from './SummaryPanelHeader';
import { useUpdateSummary, useShowValidationErrors } from './draftQuestionnaireTemplate';
import { DraftPanel } from './DraftPanel';
import { Datetime2 } from '../../../Datetime';
import { FieldContainer } from '../../../form/FieldContainer';
import { LabelConfig, LabelConfigProvider } from '../../../LabelConfigProvider';
import { useDeviceSize } from '../../../ui/useDeviceSize';

const PANEL_ID = 'basicDetails';

const readOnlyLabelStyle = {
  fontSize: 2,
};

const ReadOnlyFieldsLabelConfigProvider = (props) => {
  const { isExtraSmall } = useDeviceSize();

  const labelStyles = useMemo(
    () => fromPairs(
      ['createdBy', 'createdOn', 'numQuestions', 'version']
        .map(fieldName => [fieldName, readOnlyLabelStyle]),
    ),
    [],
  );

  return (
    <LabelConfigProvider
      variant={isExtraSmall ? LabelConfig.ABOVE : LabelConfig.LEFT}
      width={LABEL_WIDTH}
      style={isExtraSmall ? {} : labelStyles}
      {...props}
    />
  );
};

export const BasicDetailsPanel = () => {
  const { t } = useTranslation(['preQualification', 'general']);
  const { stopEditing } = useQuestionnaireTemplateActions();
  // @ts-expect-error ts(2339) FIXME: Property 'editingPanelId' does not exist on type 'QuestionnaireTemplateStateContextType | undefined'.
  const { editingPanelId, isLive, isRevising } = useQuestionnaireTemplateState();
  const template = useQuestionnaireTemplateData();
  const [updateSummary] = useUpdateSummary();
  const showValidationErrors = useShowValidationErrors();

  const isEditingThisPanel = editingPanelId && editingPanelId === PANEL_ID;

  const properties = useMemo(() => compact([
    {
      fieldName: 'name',
      name: t('questionnaireSummary.fieldName.name'),
      value: template.summary.name,
      Component: nestValues(
        withProps(ValidationErrorValue, { useShowValidationErrors }),
        ValueOrEmDash,
      ),
    },
    {
      fieldName: 'description',
      name: t('questionnaireSummary.fieldName.description'),
      value: template.summary.description,
      Component: nestValues(
        withProps(ValidationErrorValue, { useShowValidationErrors }),
        PreWrapValueOrEmDash,
      ),
      heightAuto: true,
    },
    ...(isLive || isRevising
      ? [
        {
          fieldName: 'createdBy',
          name: t('createdBy'),
          value: template.meta.creatorUsername,
        },
        {
          fieldName: 'createdOn',
          name: t('createdOn'),
          value: template.meta.creationTimestamp,
          Component: withProps(Datetime2, { format: DateFormat.DD_MMM_YYYY }),
        },
        {
          fieldName: 'numQuestions',
          name: t('numberOfQuestions'),
          value: template.exchangeDefSequence.length,
        },
        {
          fieldName: 'version',
          name: t('version'),
          value: template.version,
        },
      ]
      : []
    ),
  ]), [t, template, isLive, isRevising]);

  const initialValues = template.summary;
  const heading = t('questionnaireSummary.basicDetails');

  return (
    <DraftPanel panelId={PANEL_ID} heading={heading}>
      <SummaryPanelHeader heading={heading} panelId={PANEL_ID} canEdit={!isRevising} />
      <PanelDivider />
      {isEditingThisPanel ? (
        <Formik
          validateOnBlur
          enableReinitialize
          initialValues={initialValues}
          validationSchema={
            yup.object().shape({
              name: isLive
                ? yup.string().required(t('required', { ns: 'general' }))
                : yup.string().nullable(),
              description: isLive
                ? yup.string().required(t('required', { ns: 'general' }))
                : yup.string().nullable(),
            })
          }
          onSubmit={async (values) => {
            const newSummary = pickBy(
              values,
              (value, key) => !isEqual(value, initialValues[key]),
            );

            await updateSummary(
              { summary: newSummary },
              { onSuccess: () => stopEditing() },
            );
          }}
        >
          {({ isSubmitting, dirty, isValid, resetForm }) => (
            <Form>
              <SummaryLabelConfigProvider>
                <Stack gap={20} m={20}>
                  {isLive && (
                    <MessageBlock variant="info" mt={0}>
                      {t('questionnaireTemplate.summaryUpdateInfo')}
                    </MessageBlock>
                  )}
                  <TextField
                    label={t('questionnaireSummary.fieldName.name')}
                    name="name"
                    required
                    // quick fix: we can remove setting the inputStyle height once a line height is
                    // set for Input
                    inputStyle={{ height: 40 }}
                  />
                  <TextField
                    name="description"
                    isMultiLine
                    rows={9}
                    label={t('questionnaireSummary.fieldName.description')}
                    placeholder={t('questionnaireSummary.descriptionPlaceholder')}
                    required
                  />
                  {isLive && (
                    <ReadOnlyFieldsLabelConfigProvider>
                      <FieldContainer
                        name="createdBy"
                        label={t('createdBy')}
                        width="100%"
                      >
                        {template.meta.creatorUsername}
                      </FieldContainer>
                      <FieldContainer
                        name="createdOn"
                        label={t('createdOn')}
                        width="100%"
                      >
                        <Datetime2
                          format={DateFormat.DD_MMM_YYYY}
                          value={template.meta.creationTimestamp}
                        />
                      </FieldContainer>
                      <FieldContainer
                        name="numQuestions"
                        label={t('numberOfQuestions')}
                        width="100%"
                      >
                        {template.exchangeDefSequence.length}
                      </FieldContainer>
                      <FieldContainer
                        name="version"
                        label={t('version')}
                        width="100%"
                      >
                        {template.version}
                      </FieldContainer>
                    </ReadOnlyFieldsLabelConfigProvider>
                  )}
                </Stack>
              </SummaryLabelConfigProvider>
              <PanelDivider />
              <PanelPadding>
                <Flex justifyContent="flex-end">
                  <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
                  <SaveButton disabled={isSubmitting || !dirty || !isValid} />
                </Flex>
              </PanelPadding>
              <LeavePageModal />
            </Form>
          )}
        </Formik>
      ) : (
        <Validation
          values={template.summary}
          schema={showValidationErrors ? (
            yup.object().shape({
              name: yup.string().nullable().required(t('required', { ns: 'general' })),
              description: yup.string().nullable().required(t('required', { ns: 'general' })),
            })
          ) : (
            yup.mixed()
          )}
        >
          <PropertyList
            Row={withProps(ValidationPropertyRow, { useShowValidationErrors })}
            properties={properties}
          />
        </Validation>
      )}
    </DraftPanel>
  );
};
