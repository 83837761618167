import { useCallback } from 'react';
import { Box } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { PRect } from '@reach/rect';
import { BidStatus } from '@deepstream/common/rfq-utils';
import { isEmpty } from 'lodash';

import { useNavigate } from '@tanstack/react-router';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { useCurrentCompanyId } from '../currentCompanyId';
import { useDeviceSize } from '../ui/useDeviceSize';
import { BidStatusIndicators } from '../BidStatus';
import { ErrorMessage } from '../ui/ErrorMessage';
import { BidPageRecipientTabs } from './BidPageRecipientTabs';
import * as rfx from '../rfx';
import { RecipientIdProvider, useLiveRfqStructure, RfqIdProvider } from '../useRfq';
import { Loading } from '../ui/Loading';
import { NoIntentionToBidModal } from '../BidIntentionPanel';
import { useModalState } from '../ui/useModalState';
import { HeadingRow } from './HeadingRow';
import { RequestHeading } from './RequestHeading';
import { ReceivedRequestOverview } from '../types';
import * as Layout from '../Layout';
import { legacyRequestReceivedBidIndexRoute } from '../AppRouting';
import { HeaderToggleButton } from '../HeaderToggleButton';

const MoreActionsDropdown = () => {
  const { t } = useTranslation();
  const noIntentionToBidModal = useModalState();
  const bid = rfx.useBid();

  const hasActivatedAnyStage = !isEmpty(bid.activatedStageIds);

  const canWithdrawBid = (
    hasActivatedAnyStage &&
    ![
      BidStatus.WITHDRAWN,
      BidStatus.AWARDED,
      BidStatus.UNSUCCESSFUL,
      null,
    ].includes(bid.status)
  );

  const calculatePosition = useCallback(
    (targetRect?: PRect | null) => {
      if (!targetRect) {
        return {};
      }

      return {
        position: 'fixed' as const,
        right: document.body.clientWidth - targetRect.right,
        top: targetRect.bottom,
        zIndex: 102,
      };
    },
    [],
  );

  return canWithdrawBid ? (
    <>
      <Box ml={3}>
        <DropdownMenu variant="secondary" iconLeft="ellipsis-h" position={calculatePosition}>
          <DropdownMenuItem icon="close" onSelect={noIntentionToBidModal.open}>
            {t('request.withdrawBid')}
          </DropdownMenuItem>
        </DropdownMenu>
      </Box>
      <NoIntentionToBidModal
        isOpen={noIntentionToBidModal.isOpen}
        hasIntentionToBid
        close={noIntentionToBidModal.close}
      />
    </>
  ) : (
    null
  );
};

export const LegacyRecipientPageHeader = ({
  rfqId,
  recipientId,
  withBanner,
  selectedPageId,
  selectedTabId,
}: {
  rfqId: string;
  recipientId: string;
  selectedPageId?: string;
  selectedTabId?: string;
  withBanner?: boolean;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { isExtraLarge, isLarge, isExtraSmall } = useDeviceSize();
  const navigate = useNavigate();

  const { data: structure, isLoading, isError, isSuccess } =
    useLiveRfqStructure({ rfqId, recipientId, currentCompanyId });

  const navigateToRequest = useCallback(
    (request: ReceivedRequestOverview) => {
      navigate({
        to: legacyRequestReceivedBidIndexRoute.to,
        params: { rfqId: request._id, currentCompanyId },
      });
    },
    [currentCompanyId, navigate],
  );

  return (
    <RfqIdProvider rfqId={rfqId}>
      <RecipientIdProvider recipientId={recipientId}>
        <Layout.PageHeaderWrapper>
          {isLoading ? (
            <Loading />
          ) : isError ? (
            <ErrorMessage
              fontSize={2}
              lineHeight={1}
              error={t('request.errors.couldNotGetBidStatus')}
            />
          ) : isSuccess && structure ? (
            <rfx.StructureProvider structure={structure}>
              <HeadingRow>
                <RequestHeading
                  isSender={false}
                  requestId={rfqId}
                  subject={structure.summary.subject}
                  navigateToRequest={navigateToRequest}
                />
                {!isExtraSmall && (
                  <BidStatusIndicators />
                )}
                <MoreActionsDropdown />
              </HeadingRow>
              <BidPageRecipientTabs selectedTabId={selectedTabId} />
              {selectedTabId === 'auction' && (isExtraLarge || isLarge) && (
                <HeaderToggleButton
                  sx={{
                    position: 'absolute',
                    right: '15px',
                    marginLeft: 'auto',
                    bottom: '-14px',
                    width: 'max-content',
                    zIndex: 999,
                  }}
                />
              )}
            </rfx.StructureProvider>
          ) : (
            null
          )}
        </Layout.PageHeaderWrapper>
      </RecipientIdProvider>
    </RfqIdProvider>
  );
};
