import { useTranslation } from 'react-i18next';
import { BidStatus } from '@deepstream/common/rfq-utils';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import * as rfx from './rfx';
import { useCurrentCompanyId } from './currentCompanyId';
import { RecipientIdProvider, RfqIdProvider, useLiveRfqStructure, useRecipientId } from './useRfq';
import { LoadingPanel } from './ui/Loading';
import { ErrorPanel } from './ui/ErrorMessage';
import { useSaveBidTeamChanges } from './useSaveRequestTeamChanges';
import { RecipientTeamUsersPanel } from './modules/Request/Team/RecipientTeamUsersPanel';

const RecipientTeamUsersPanelWrapper = () => {
  const [saveChanges] = useSaveBidTeamChanges({ isSender: true });
  const recipientId = useRecipientId();
  const structure = rfx.useStructure();
  const { status } = rfx.useBid();
  const { t } = useTranslation();

  const recipient = structure.recipients.find(recipient => recipient._id === recipientId);
  const showAddUserButton = status === BidStatus.NO_RESPONSE;

  return recipient ? (
    <rfx.SaveChangesProvider saveChanges={saveChanges}>
      <>
        <RecipientTeamUsersPanel
          mt={1}
          company={recipient.company}
          showAddUserButton={showAddUserButton}
        />
        {!showAddUserButton && (
          <MessageBlock variant="info">
            {t('request.team.cannotAddUsersInfo')}
          </MessageBlock>
        )}
      </>
    </rfx.SaveChangesProvider>
  ) : (
    null
  );
};

export type SupplierBidTeamProps = {
  rfqId: string;
  recipientId: string;
};

export const SupplierBidTeam = ({ rfqId, recipientId }: SupplierBidTeamProps) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { data: rfxStructure, isLoading, isError } = useLiveRfqStructure({
    rfqId,
    currentCompanyId,
    recipientId,
  });

  return (
    <RfqIdProvider rfqId={rfqId}>
      <RecipientIdProvider recipientId={recipientId}>
        <rfx.StateProvider isLive>
          {isLoading ? (
            <LoadingPanel />
          ) : isError ? (
            <ErrorPanel error={t('errors.unexpected')} />
          ) : rfxStructure ? (
            <rfx.StructureProvider structure={rfxStructure}>
              <RecipientTeamUsersPanelWrapper />
            </rfx.StructureProvider>
          ) : (
            null
          )}
        </rfx.StateProvider>
      </RecipientIdProvider>
    </RfqIdProvider>
  );
};
