import { useMemo, useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Box } from 'rebass/styled-components';

import { CellProps } from 'react-table';
import { keyBy } from 'lodash';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';
import { PanelDivider, PanelPadding, Panel } from '@deepstream/ui-kit/elements/Panel';
import { DeleteMenuItem, DropdownMenuItem, EllipsisMenu } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { Table } from '../../Table';
import { BasicTableStyles } from '../../TableStyles';
import { useTableData, TableData } from '../../TableDataContext';
import { nestCells } from '../../nestCells';
import { TruncateCell } from '../../TruncateCell';
import { Disclosure } from '../../ui/Disclosure';
import { ErrorPanel } from '../../ui/ErrorMessage';
import { DateFieldValue } from '../../FieldsCell';
import { LoadingPanel } from '../../ui/Loading';
import { useModalState } from '../../ui/useModalState';
import { DocumentsTableHeader } from './DocumentsTableHeader';

import { DocumentsDeleteModal } from './DocumentsDeleteModal';
import {
  useDocumentDownload,
  useDocumentExpiryDate,
  QueryDocument,
} from './DocumentsTableDataProvider';
import { DocumentModalEdit } from './DocumentModal';
import { useChatbotSubjects } from '../AI/chatbot/ChatbotSubjectContext';
import { BasicTarget } from '../AI/chatbot/types';

const EntryActions = ({
  row,
}) => {
  const {
    open: openDocumentModal,
    close: closeDocumentModal,
    isOpen: isDocumentModalOpen,
  } = useModalState();

  const { t } = useTranslation();
  const downloadDocument = useDocumentDownload();

  const initialValues = useMemo(() => {
    const { name, description, visibility, type, expiryDate, attachment } = row.original;

    return {
      name,
      description,
      visibility,
      type,
      expiryDate,
      attachments: [attachment],
    };
  }, [row.original]);

  return (
    <>
      <DocumentModalEdit
        id={row.original._id}
        isOpen={isDocumentModalOpen}
        closeModal={closeDocumentModal}
        initialValues={initialValues}
      />
      <ButtonGroup marginBetween="-1px" sx={{ float: 'right' }}>
        <EditButton
          small
          onClick={openDocumentModal}
        />
        <EllipsisMenu
          small
        >
          <DropdownMenuItem
            icon="download"
            onSelect={() => downloadDocument(row.original._id, row.original.name)}
          >
            {t('general.download')}
          </DropdownMenuItem>
          <DocumentsDeleteModal
            documents={[row.original]}
            trigger={({ open: openDeleteModal }) => (
              <DeleteMenuItem onSelect={openDeleteModal} />
            )}
          />
        </EllipsisMenu>
      </ButtonGroup>
    </>
  );
};

const DateSensitiveCell = ({
  expiryDate,
  hasIcon,
  children,
  hasTooltip = false,
}: {
  expiryDate: string | null;
  children: React.ReactElement;
  hasIcon?: boolean;
  hasTooltip?: boolean;
}) => {
  const { isNearlyExpired, isExpired } = useDocumentExpiryDate(expiryDate);
  const { t } = useTranslation();

  if (!isNearlyExpired && !isExpired) return children;

  const icon = isNearlyExpired ? 'exclamation-triangle' : 'exclamation-circle';
  const color = isNearlyExpired ? 'warning' : 'danger';

  const tooltipContent = isNearlyExpired
    ? t('drivePage.warnings.expiresSoon')
    : t('drivePage.warnings.expired');

  return (
    <Tooltip content={hasTooltip && tooltipContent}>
      {hasIcon ? (
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
          <Icon icon={icon} color={color} />
          <Text ml={1} color={color}>{children}</Text>
        </Box>
      ) : (
        <Text color={color}>{children}</Text>
      )}
    </Tooltip>
  );
};

export const VisibilityCell: React.FC<CellProps<any>> = ({ value }) => {
  const { t } = useTranslation();

  return (
    <>
      {t(`drivePage.documentForm.fields.visibilityOpts.${value}.label`)}
    </>
  );
};

const TableContent = () => {
  const [selection, setSelection] = useState([]);
  const { data } = useTableData();
  const { t } = useTranslation();
  const columns = useMemo(() => ([
    {
      Header: t('drivePage.documentsColumns.name'),
      accessor: 'name',
      width: '40%',
      Cell: nestCells(
        ({ cell, row }) => (
          <DateSensitiveCell expiryDate={row.values.expiryDate} hasIcon hasTooltip>
            {cell.value}
          </DateSensitiveCell>
        ),
        TruncateCell,
      ),
    },
    {
      Header: t('drivePage.documentsColumns.type'),
      accessor: 'type',
      Cell: TruncateCell,
    },
    {
      Header: t('drivePage.documentsColumns.visibility'),
      accessor: 'visibility',
      Cell: nestCells(
        VisibilityCell,
        TruncateCell,
      ),
    },
    {
      Header: t('drivePage.documentsColumns.updated'),
      accessor: 'uploadDate',
      Cell: nestCells(
        TruncateCell,
        ({ cell }) => <DateFieldValue value={cell.value} />,
      ),
    },
    {
      Header: t('drivePage.documentsColumns.expires'),
      accessor: 'expiryDate',
      Cell: ({ cell, row }) => (
        <DateSensitiveCell expiryDate={row.values.expiryDate}>
          <Truncate>
            <DateFieldValue value={cell.value} />
          </Truncate>
        </DateSensitiveCell>
      ),
    },
    {
      id: 'editButton',
      Header: ' ',
      Cell: ({ row }) => (
        <EntryActions row={row} />
      ),
      width: 130,
    },
  ]), [t]);

  const chatbotTargets = React.useMemo(
    () => {
      const targets = data.map(document => ({
        type: 'attachment',
        _id: document.attachment._id,
        name: document.name,
        source: {
          type: 'drive/document',
          documentId: document._id,
        },
      } as BasicTarget));

      return keyBy(targets, target => target._id);
    },
    [data],
  );

  useChatbotSubjects(chatbotTargets);

  return (
    <BasicTableStyles hoverCursor="default">
      <DocumentsTableHeader selection={selection} />
      {!data.length ? null : (
        <>
          <PanelDivider />
          <Table
            columns={columns}
            isSortable
            // @ts-expect-error ts(2322) FIXME: Type 'Dispatch<SetStateAction<never[]>>' is not assignable to type '(rows: any[]) => void'.
            setSelectedRows={setSelection}
          />
        </>
      )}
      <PanelDivider />
      <PanelPadding>
        <Disclosure summary={t('drivePage.disclosure.summary')}>
          <p>{t('drivePage.disclosure.content')}</p>
        </Disclosure>
      </PanelPadding>
    </BasicTableStyles>
  );
};

export const DocumentsTable = () => {
  const { query } = useTableData<TableData<QueryDocument[]>>();
  const { t } = useTranslation();

  return query.isError ? (
    <ErrorPanel error={t('errors.unexpected')} />
  ) : query.isLoading ? (
    <LoadingPanel />
  ) : (
    <Panel>
      <TableContent />
    </Panel>
  );
};
