import * as Layout from '../../../Layout';
import { Header } from './Header';
import { QuestionnaireDetails } from './QuestionnaireDetails';
import { QuestionnairePageTab } from './utils';

export const QuestionnaireDetailsPageContent = () => {
  const tabId = QuestionnairePageTab.DETAILS;

  return (
    <Layout.HeaderAndContent
      header={
        <Header selectedTabId={tabId} />
      }
      content={
        <QuestionnaireDetails />
      }
    />
  );
};
