import { useTranslation } from 'react-i18next';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { LoadingPanel } from '../../ui/Loading';
import { ErrorPanel } from '../../ui/ErrorMessage';
import { ContractReminders } from './ContractReminders';
import { ContractProvider, useContractState } from './contract';
import { ContractDraftPageContent } from './ContractDraftPageContent';
import { useContract } from './useContract';
import { DraftContractFooter } from './DraftContractFooter';
import { useDraftContractNavigation } from '../../appNavigation';

const DraftRemindersFooter = () => {
  const { navigateToTeam, navigateToReview } = useDraftContractNavigation();

  return (
    <DraftContractFooter
      onBack={navigateToTeam}
      onContinue={navigateToReview}
    />
  );
};

export const ContractDraftReminders = ({
  contractId,
}: {
  contractId: string;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  // @ts-expect-error ts(2339) FIXME: Property 'isTemplate' does not exist on type 'ContractStateContextType | undefined'.
  const { isTemplate } = useContractState();

  const { data: contract, isLoading, isError, isSuccess } = useContract({
    contractId,
    currentCompanyId,
    scope: 'draft',
    isTemplate,
  });

  return (
    <>
      {isLoading ? (
        <LoadingPanel />
      ) : isError ? (
        <ErrorPanel error={t('errors.unexpected')} />
      ) : isSuccess && contract ? (
        <ContractProvider contract={contract}>
          <ContractDraftPageContent>
            <ContractReminders />
          </ContractDraftPageContent>
          <DraftRemindersFooter />
        </ContractProvider>
      ) : (
        null
      )}
    </>
  );
};
