import * as React from 'react';
import { useQuery } from 'react-query';
import { isEmpty, isEqual, omitBy, isUndefined, isArray, uniq } from 'lodash';
import { ReceivedContractOverviews, SentContractOverviews } from '@deepstream/common/contract';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { wrap } from '../../api';
import { TableData, TableDataContext } from '../../TableDataContext';
import { SortProps } from '../../sorting';
import { FilterProps } from '../../filtering';

type PageControls = {
  pageIndex: number;
  pageSize: number;
};

const combineFilterQueryParams = <
  StatusFilterItemT extends Record<string, unknown>,
  RoleFilterItemT extends Record<string, unknown>,
  RecipientFilterItemT extends Record<string, unknown>,
  LegacyFilterItemT extends Record<string, unknown>,
  RequestTagFilterItemT extends Record<string, unknown>,
>(
  statusFilter?: FilterProps<StatusFilterItemT>,
  roleFilter?: FilterProps<RoleFilterItemT>,
  recipientFilter?: FilterProps<RecipientFilterItemT>,
  legacyFilter?: FilterProps<LegacyFilterItemT>,
  requestTagsFilter?: FilterProps<RequestTagFilterItemT>,
) => omitBy(
  {
    ...statusFilter?.getQueryParam(statusFilter.selectedItems),
    ...roleFilter?.getQueryParam(roleFilter.selectedItems),
    ...recipientFilter?.getQueryParam(recipientFilter.selectedItems),
    ...legacyFilter?.getQueryParam(legacyFilter.selectedItems),
    ...requestTagsFilter?.getQueryParam(requestTagsFilter.selectedItems),
  },
  (item) => isArray(item) ? isEmpty(item) : isUndefined(item),
);

/**
 * Provides a TableDataContext for contracts tables.
 */
export const ContractsTableDataProvider = <
  QueryDataT extends SentContractOverviews | ReceivedContractOverviews,
  StatusFilterItemT extends Record<string, unknown>,
  RoleFilterItemT extends Record<string, unknown>,
  RecipientFilterItemT extends Record<string, unknown>,
  LegacyFilterItemT extends Record<string, unknown>,
  RequestTagFilterItemT extends Record<string, unknown>,
>({
    queryTag,
    queryFn,
    statusFilter,
    roleFilter,
    recipientFilter,
    legacyFilter,
    requestTagsFilter,
    preselectedTag,
    pageSizeState,
    searchTextState,
    sort,
    children,
  }: {
  queryTag: string;
  queryFn: (...params: any[]) => Promise<QueryDataT>;
  statusFilter?: FilterProps<StatusFilterItemT>;
  roleFilter?: FilterProps<RoleFilterItemT>;
  recipientFilter?: FilterProps<RecipientFilterItemT>;
  legacyFilter?: FilterProps<LegacyFilterItemT>;
  requestTagsFilter: FilterProps<RequestTagFilterItemT>;
  preselectedTag?: string;
  pageSizeState: [number, (pageSize: number) => void];
  searchTextState?: [string, (searchText: string) => void];
  sort?: SortProps;
  children: React.ReactElement;
}): React.ReactElement<TableData<QueryDataT>> => {
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const [pageSize, setPageSize] = pageSizeState;
  const [pageIndex, setPageIndex] = React.useState<number>(0);
  const [searchText, setSearchText] = searchTextState ?? [undefined, () => { }];
  const [selectedItemsIds, setSelectedItemsIds] = React.useState<string[]>([]);
  const [areAllItemsSelected, setAreAllItemsSelected] = React.useState(false);

  const setPageControls = React.useCallback((pageControls: PageControls) => {
    setPageSize(pageControls.pageSize);
    setPageIndex(pageControls.pageIndex);
  }, [setPageIndex, setPageSize]);

  const [queryParams, setQueryParams] = React.useState(() => ({
    currentCompanyId,
    pageSize,
    pageIndex,
    sort: sort ? sort.getQueryParam(sort.selectedItems) : {},
    filter: combineFilterQueryParams(statusFilter, roleFilter, recipientFilter, legacyFilter, requestTagsFilter),
    ...(searchText && {
      search: {
        text: searchText,
      },
    }),
  }));

  const query = useQuery<QueryDataT>(
    [queryTag, queryParams],
    wrap(queryFn),
  );

  React.useEffect(() => {
    const newQueryParams = {
      currentCompanyId,
      pageSize,
      pageIndex,
      sort: sort ? sort.getQueryParam(sort.selectedItems) : {},
      filter: combineFilterQueryParams(statusFilter, roleFilter, recipientFilter, legacyFilter, requestTagsFilter),
      ...(searchText && {
        search: {
          text: searchText,
        },
      }),
    };

    newQueryParams.filter.requestTags = uniq([
      ...((newQueryParams.filter.requestTags as any[]) ?? []),
      ...(preselectedTag ? [preselectedTag] : []),
    ]);

    if (!isEqual(queryParams, newQueryParams)) {
      setQueryParams(newQueryParams);
    }
  }, [
    currentCompanyId,
    pageSize,
    pageIndex,
    queryParams,
    sort,
    statusFilter,
    roleFilter,
    recipientFilter,
    legacyFilter,
    requestTagsFilter,
    preselectedTag,
    searchText,
  ]);

  const selectItem = React.useCallback((id: string) => {
    setSelectedItemsIds((selectedContractIds) => uniq([...selectedContractIds, id]));
  }, []);

  const unselectItem = React.useCallback((id: string) => {
    setSelectedItemsIds((selectedContractIds) => selectedContractIds.filter((selectedRequestId) => selectedRequestId !== id));
    setAreAllItemsSelected(false);
  }, []);

  const selectAllItems = React.useCallback(() => {
    setAreAllItemsSelected(true);
  }, []);

  const unselectAllItems = React.useCallback(() => {
    setAreAllItemsSelected(false);
    setSelectedItemsIds([]);
  }, []);

  const selectAllItemsOnPage = React.useCallback(() => {
    setSelectedItemsIds((selectedContractIds) => {
      const newSelectedContractIds = query.data?.overviews.map((overview) => overview._id) || [];
      return uniq([...selectedContractIds, ...newSelectedContractIds]);
    });
  }, [query.data]);

  const value = React.useMemo(() => ({
    data: query.data?.overviews ?? [],
    pageCount: Math.ceil((query.data?.totalItems ?? 0) / queryParams.pageSize),
    pageSize: queryParams.pageSize,
    pageIndex: query.data?.pageIndex ?? 0,
    query,
    statusFilter,
    roleFilter,
    recipientFilter,
    legacyFilter,
    requestTagsFilter,
    selectItem,
    unselectItem,
    selectAllItems,
    unselectAllItems,
    selectAllItemsOnPage,
    selectedItemsIds,
    areAllItemsSelected,
    sort,
    setPageControls,
    searchText: queryParams.search?.text,
    setSearchText,
    queryParams,
  }), [
    query,
    queryParams,
    sort,
    statusFilter,
    roleFilter,
    recipientFilter,
    setPageControls,
    legacyFilter,
    requestTagsFilter,
    selectItem,
    unselectItem,
    selectAllItems,
    unselectAllItems,
    selectAllItemsOnPage,
    selectedItemsIds,
    areAllItemsSelected,
    setSearchText,
  ]);

  return (
    <TableDataContext.Provider value={value}>
      {children}
    </TableDataContext.Provider>
  );
};

export type ContractsTableData<T> = TableData<T> & {
  statusFilter: FilterProps<Record<string, unknown>>;
  roleFilter: FilterProps<Record<string, unknown>>;
  recipientFilter: FilterProps<Record<string, unknown>>;
  legacyFilter: FilterProps<Record<string, unknown>>;
  requestTagsFilter: FilterProps<Record<string, unknown>>;
  sort: SortProps;
  searchText: string;
  setSearchText: (searchText: string) => void;
  queryParams: any;
};
