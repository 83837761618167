import { useQueryClient } from 'react-query';
import { Action } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { callAll } from '@deepstream/utils/callAll';
import { useApi } from '../../api';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useMutation } from '../../useMutation';
import { useExchangeRefetch } from '../../rfx';
import { useToaster } from '../../toast';
import { useContractExchange, useContractQueryKey } from './useContract';
import { SendExchangeReplyPayload } from '../../ExchangeModal/useSendExchangeReply';

export const useSendContractExchangeReply = ({
  contractId,
  exchangeId,
}: {
  contractId: string;
  exchangeId: string;
}) => {
  const { t } = useTranslation('contracts');
  const toaster = useToaster();
  const api = useApi();
  const queryClient = useQueryClient();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractQueryKey = useContractQueryKey({ scope: 'current' });
  const refetchExchange = useExchangeRefetch();

  const { queryKey: contractExchangeQueryKey } = useContractExchange({
    exchangeId,
    enabled: false,
  });

  return useMutation(
    async (action: SendExchangeReplyPayload) => {
      await api.sendContractExchangeReply({
        contractId,
        exchangeId,
        currentCompanyId,
        action: {
          ...action,
          companyId: currentCompanyId,
        },
      });

      if (refetchExchange) {
        // waiting for `refetchExchange()` ensures that the mutation promise is
        // only resolved after the exchange data has been updated; this allows
        // forms to set `isSubmitting` to `false` *after* the updated data has
        // arrived
        await refetchExchange();
      }
    },
    {
      mutationKey: ['sendContractExchangeReply'],
      onSettled: callAll(
        () => queryClient.invalidateQueries(contractQueryKey),
        refetchExchange ? undefined : () => queryClient.invalidateQueries(contractExchangeQueryKey),
      ),
      onSuccess: () => toaster.success(t('toaster.actionSubmitted.success')),
      onError: () => toaster.error(t('toaster.actionSubmitted.failed')),
    },
  );
};
