export const iconPropsByExchangesProgressId = {
  actionRequired: {
    icon: 'exclamation-circle',
    color: 'danger',
  },
  waitingForTeam: {
    icon: 'exclamation-circle',
    color: 'subtext',
  },
  waitingForBuyer: {
    icon: 'circle-dashed',
    color: 'subtext',
  },
  waitingForSupplier: {
    icon: 'circle-dashed',
    color: 'subtext',
  },
  complete: {
    icon: 'circle-check',
    color: 'success',
  },
  informationOnly: {
    icon: 'info-circle',
    color: 'subtext',
  },
  noResponse: {
    icon: 'xmark',
    color: 'subtext',
  },
  obsolete: {
    icon: 'ban',
    color: 'subtext',
  },
} as const;
