import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { BulletinPost } from '../../ExchangeModal/BulletinPost';

export const BulletinCell = ({
  bulletinId,
}: { bulletinId?: string }) => {
  if (!bulletinId) {
    return <EmDash />;
  }

  return (
    <BulletinPost bulletinId={bulletinId} />
  );
};
