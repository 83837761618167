import styled from 'styled-components';
import { Box } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';

import { ModalProps, ModalBody } from '@deepstream/ui-kit/elements/popup/Modal';

import { TextField } from '../../form/TextField';
import { CompanyFinderForm } from '../../CompanyFinderForm';
import { FieldContainer } from '../../form/FieldContainer';

export const List = styled.ul`
  padding-left: 1em;
  margin-bottom: 0;

  > * + * {
    margin-top: 4px;
  }
`;

export type RecordModalProps = {
  systemId: string;
  companyId: string;
  externalSupplierData: any;
  onCancel: any;
  onSave: any;
  allowManyToOneMapping: boolean;
} & ModalProps;

export const useHandleSubmit = ({
  addOrUpdateSupplierMapRecord,
  companyId,
  systemId,
  internalCompanyIds,
  allowManyToOneMapping,
}) => {
  const { t } = useTranslation('integration');

  const handleSubmit = async (
    values,
    setErrors,
  ) => {
    if (values.company && internalCompanyIds.includes(values.company._id) && !allowManyToOneMapping) {
      setErrors({ company: t('errors.internalCompanyError') });
    } else {
      return addOrUpdateSupplierMapRecord({
        companyId,
        companies: [{
          externalCompanyId: values.externalSupplierId,
          companyName: values.externalSupplierName,
          emails: values.externalSupplierEmails?.replace(/\s/g, ''),
          // these are explicitly set to null so that they're not omitted in the
          // upsertExternalCompany() db function
          internalCompanyId: values.company ? values.company._id : null,
          internalCompanyName: values.company ? values.company.name : null,
        }],
        systemId,
      });
    }
  };

  return handleSubmit;
};

export const getInternalCompanyIds = (externalSupplierData) =>
  externalSupplierData ? externalSupplierData.map(supplierData => supplierData.internalCompanyId).filter(Boolean) : [];

export const getOtherMappedRecords = (externalSupplierData, company) =>
  company ? externalSupplierData.filter(supplierData => supplierData.internalCompanyId === company._id) : [];

export const AddOrEditRecordModalBody = ({ company, internalCompanyIds, allowManyToOneMapping, otherMappedRecords }) => {
  const { t } = useTranslation('integration');

  return (
    <ModalBody>
      <Box mb={3}>
        <TextField name="externalSupplierId" label={t('externalSupplierIdLabel')} required />
      </Box>
      <Box mb={3}>
        <TextField name="externalSupplierName" label={t('externalSupplierNameLabel')} />
      </Box>
      <Box mb={3}>
        <TextField isMultiLine name="externalSupplierEmails" label={t('externalSupplierContactEmailsLabel')} />
      </Box>
      <Box mb={3}>
        <CompanyFinderForm required={false} label={t('supplierMappedToLabel')} />
      </Box>
      {(company && internalCompanyIds.includes(company._id) && allowManyToOneMapping) ? (
        <Box mb={3}>
          <FieldContainer name="otherRecords" label={t('otherRecordsMapped')} disabled >
            <List>
              {otherMappedRecords.map(mapItem => (<li key={mapItem._id}>{`${mapItem.externalCompanyId} - ${mapItem.externalCompanyName || t('noNameSet')}`}</li>))}
            </List>
          </FieldContainer>
        </Box>
        ) : null
      }
    </ModalBody>
  );
};
