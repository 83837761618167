import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import { compact } from 'lodash';
import { CellProps } from 'react-table';
import { Approval, getApprovalRequestStatus } from '@deepstream/common/rfq-utils';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { withProps } from '@deepstream/ui-utils/withProps';
import { ProgressCircle } from '@deepstream/ui-kit/elements/ProgressCircle';
import { StageApprovalExchangeSnapshot } from '../../types';
import { StatusIconText } from '../../ExchangeStatusCell';
import { useLabeledApprovalResponseConfig, useLabeledStageApprovalsStatusesConfig } from '../../status';
import { Approver } from './Approvers';
import { useResponsesCountConfig } from './useResponsesCountConfig';
import { BasicTableStyles } from '../../TableStyles';
import { Table } from '../../Table';
import { ExpandableTruncateCell } from '../../ExpandableTruncateCell';

const ResponseStatusCell: React.FC<CellProps<Approval, string>> = ({
  cell: { value: status },
}) => {
  const approvalResponseStatusConfig = useLabeledApprovalResponseConfig();

  return (
    <StatusIconText
      label={approvalResponseStatusConfig[status].label}
      icon={approvalResponseStatusConfig[status].icon}
    />
  );
};

const ApproverCell: React.FC<CellProps<Approval, string>> = ({
  cell: { value: userId },
}) => {
  return (
    <Approver
      userId={userId}
    />
  );
};

const StatusIndicatorLayout = ({
  statusText,
  children,
}: {
  statusText: string;
  children: React.ReactNode;
}) => {
  return (
    <Flex flexDirection="column" alignItems="flexStart" sx={{ rowGap: '2px' }}>
      <Text sx={{ fontSize: '12px', fontWeight: 500 }}>{statusText}</Text>
      {children}
    </Flex>
  );
};

const CELL_PADDING_X = 10;

export type ApprovalResponsesTableProps = {
  approvalRequest: StageApprovalExchangeSnapshot;
};

export const ApprovalResponsesTable: React.FC<ApprovalResponsesTableProps> = ({
  approvalRequest,
}) => {
  const { t } = useTranslation('translation');
  const theme = useTheme();
  const { approvals } = approvalRequest;

  const stageApprovalStatusConfig = useLabeledStageApprovalsStatusesConfig();
  const approvalRequestStatus = getApprovalRequestStatus(approvalRequest.status);

  const responsesCountConfig = useResponsesCountConfig(approvals);
  const responsesCount = responsesCountConfig.approved.count + responsesCountConfig.rejected.count;
  const approvedPercentage = responsesCount / approvals.length;

  const columns = React.useMemo(() => {
    return compact([
      {
        Header: t('request.stageApprovals.approver', { count: 1 }),
        accessor: 'userId',
        Cell: ApproverCell,
        width: 153,
      },
      {
        Header: t('general.status'),
        accessor: 'status',
        Cell: ResponseStatusCell,
        width: 101,
      },
      {
        Header: t('general.comment_other'),
        accessor: 'message',
        Cell: withProps(ExpandableTruncateCell, {
          cellPaddingX: CELL_PADDING_X,
        }),
        width: 336,
      },
    ]);
  }, [t]);

  return (
    <Flex flexDirection="column" sx={{ rowGap: '16px' }}>
      <Flex justifyContent="space-between">
        <StatusIndicatorLayout statusText={t('status', { ns: 'general' })}>
          <StatusIconText
            label={stageApprovalStatusConfig[approvalRequestStatus].label}
            icon={stageApprovalStatusConfig[approvalRequestStatus].icon}
            fontWeight={500}
          />
        </StatusIndicatorLayout>
        <Flex sx={{ columnGap: '48px' }}>
          <StatusIndicatorLayout statusText={t('completed', { ns: 'general' })}>
            <Flex alignItems="center" sx={{ columnGap: '6px' }}>
              <ProgressCircle progress={approvedPercentage} width={14} />

              <Text sx={{ fontWeight: 500 }}>
                {responsesCount}/{approvals.length}
              </Text>
            </Flex>
          </StatusIndicatorLayout>
          <Flex sx={{ columnGap: '32px' }}>
            {Object.values(responsesCountConfig).map((config, index) => (
              <StatusIndicatorLayout key={index} statusText={config.label}>
                <StatusIconText
                  label={String(config.count)}
                  icon={config.icon}
                  fontWeight={500}
                />
              </StatusIndicatorLayout>
            ))}
          </Flex>
        </Flex>
      </Flex>
      <BasicTableStyles
        headerTextColor={theme.colors.lightNavy}
        headerBackgroundColor={theme.colors.lightGray3}
        customRowHeight="40px"
        cellPaddingX={`${CELL_PADDING_X}px`}
        hoverCursor="default"
        bordered
      >
        <Table columns={columns} data={approvals} />
      </BasicTableStyles>
    </Flex>
  );
};
