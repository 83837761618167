import * as React from 'react';
import { countBy } from 'lodash';
import { AnswerSet } from '@deepstream/common/legacy-pre-q-utils';
import { Counter, CounterProps } from '../ui/Badge';

export type AccessRequestsCounterProps = Omit<CounterProps & { answerSet?: AnswerSet }, 'count'>;

export const AccessRequestsCounter: React.FC<AccessRequestsCounterProps> = ({
  answerSet,
  ...props
}) => {
  const { pending = 0 } = countBy(answerSet?.accessRequests, 'status');

  return (
    <Counter count={pending} {...props} />
  );
};
