import { useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { isEmpty, noop } from 'lodash';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { immutableSet } from '@deepstream/utils';
import { Clamp2 } from '@deepstream/ui-kit/elements/text/Clamp';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData, AwardFlowStep, AwardFlowStepType, MessageMethod } from '../types';
import * as rfx from '../../../../rfx';
import { RadioField } from '../../../../form/RadioField';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { Direction } from '../../../../ui/MultiStepFlow/types';

const useOptions = () => {
  const { t } = useTranslation('translation');

  return useMemo(() => {
    return [
      MessageMethod.GENERAL,
      MessageMethod.BY_SUPPLIER,
      MessageMethod.CUSTOM,
    ].map(value => ({
      value,
      label: (
        <Box fontSize={4} mt="-5px" color="text">
          {t(`request.awardFlow.steps.chooseMultiSupplierMessageMethod.options.${value}.label`)}
        </Box>
      ),
      description: (
        <Box as="span" fontSize={2}>
          {t(`request.awardFlow.steps.chooseMultiSupplierMessageMethod.options.${value}.description`)}
        </Box>
      ),
    }));
  }, [t]);
};

const getInitialMessageMethod = (data: AwardFlowData): MessageMethod | null => {
  const { supplierGroup } = data.currentStep as Extract<AwardFlowStep, { type: AwardFlowStepType.CHOOSE_MULTI_SUPPLIER_MESSAGE_METHOD }>;

  return data.messageConfigByGroup[supplierGroup]?.messageMethod || null;
};

const getSubmissionDataFromFormValues = (
  { messageMethod }: { messageMethod: MessageMethod | null },
  data: AwardFlowData,
): Partial<AwardFlowData> | null => {
  const { supplierGroup } = data.currentStep as Extract<AwardFlowStep, { type: AwardFlowStepType.CHOOSE_MULTI_SUPPLIER_MESSAGE_METHOD }>;

  if (messageMethod) {
    return {
      messageConfigByGroup: immutableSet(
        data.messageConfigByGroup,
        [supplierGroup, 'messageMethod'],
        messageMethod,
      ),
    };
  } else {
    return null;
  }
};

export const ChooseMultiSupplierMessageMethodStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (data: Partial<AwardFlowData> | null, direction: Direction) => void,
}) => {
  const { t } = useTranslation('translation');
  const theme = useTheme();
  const options = useOptions();
  const { supplierGroup } = data.currentStep as Extract<AwardFlowStep, { type: AwardFlowStepType.CHOOSE_MULTI_SUPPLIER_MESSAGE_METHOD }>;
  const supplierIds = data.supplierIdsByGroup[supplierGroup];
  const supplierNames = rfx.useSortedRecipients(supplierIds).map(recipient => recipient.company.name);

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      messageMethod: z.nativeEnum(MessageMethod, { message: t(`request.awardFlow.errors.chooseHowToMessage.${supplierGroup}`) }),
    });

    return toFormikValidationSchema(Schema);
  }, [t, supplierGroup]);

  return (
    <Formik
      validateOnBlur
      initialValues={{
        messageMethod: getInitialMessageMethod(data),
      }}
      validationSchema={validationSchema}
      onSubmit={noop}
    >
      {({ values, submitForm, validateForm, dirty }) => (
        <Form>
          <lotPagesLayout.ContentWrapper>
            <StepNavigation
              onBackClick={() => submitAndNavigate(dirty ? getSubmissionDataFromFormValues(values, data) : null, Direction.BACK)}
              onContinueClick={async () => {
                const errors = await validateForm();

                await submitForm();

                if (isEmpty(errors)) {
                  submitAndNavigate(dirty ? getSubmissionDataFromFormValues(values, data) : null, Direction.FORWARD);
                }
              }}
            >
              <FormErrors />
              <lotPagesLayout.Section
                heading={t(`request.awardFlow.steps.chooseMultiSupplierMessageMethod.heading.${supplierGroup}`)}
              >
                <Text mt="20px">
                  <Clamp2 lines={2}>
                    {t(`request.awardFlow.steps.chooseMultiSupplierMessageMethod.info.${supplierGroup}`, {
                      count: supplierNames.length,
                      supplierNames,
                    })}
                  </Clamp2>
                </Text>
                <Box mt="26px">
                  <RadioField
                    name="messageMethod"
                    fieldLabelStyle={{
                      fontSize: theme.fontSizes[4],
                      fontWeight: 500,
                      color: theme.colors.text,
                      marginBottom: '12px',
                    }}
                    gap="20px"
                    options={options}
                    showError
                    errorMessageStyle={{ fontSize: '14px', marginTop: '16px' }}
                  />
                </Box>
              </lotPagesLayout.Section>
            </StepNavigation>
          </lotPagesLayout.ContentWrapper>
        </Form>
      )}
    </Formik>
  );
};
