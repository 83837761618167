import { FieldScope } from '@deepstream/common/rfq-utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useFieldScopeSelectItems = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return Object.values(FieldScope).map(value => ({
      value,
      label: t(`request.multiStageBehavior.fieldScope.${value}.label`),
      description: t(`request.multiStageBehavior.fieldScope.${value}.description`),
    }));
  }, [t]);
};
