import { QuestionnaireStatus, questionnaireStatusConfig } from '@deepstream/common/preQual';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';

export const QuestionnaireStatusesList = ({ statuses }: { statuses: QuestionnaireStatus[] }) => {
  const { t } = useTranslation(['preQualification']);

  return (
    <Flex flexWrap="wrap" sx={{ gap: 1 }}>
      {statuses.map((status, index) => (
        <IconText
          key={status}
          icon={questionnaireStatusConfig[status].icon.value}
          isIconRegular={questionnaireStatusConfig[status].icon.isRegular}
          iconColor={questionnaireStatusConfig[status].icon.color}
          text={`${t(`questionnaireStatus.${status}`)}${index < statuses.length - 1 ? ', ' : ''}`}
        />
      ))}
    </Flex>
  );
};
