import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Flex, Box, Text, FlexProps } from 'rebass/styled-components';
import { getInstructionValue, getQuestionnaireTemplateChanges, QuestionnaireExchangeDefinition, QuestionnaireExpiryConfig, QuestionnaireInternalData, QuestionnaireRenewalConfig, QuestionnaireTemplate, QuestionnaireTemplateChangeType, timeUnitToExpireAfterTranslationKey, timeUnitToRenewalTranslationKey } from '@deepstream/common/preQual';
import { compact, first, groupBy, isEmpty, isEqual, map, pick, toLower } from 'lodash';
import { Pictogram } from '@deepstream/ui-kit';
import { Attachment, getOrderedAddressFields, PredefinedQuestionOption, QuestionFormat, QuestionType } from '@deepstream/common/rfq-utils';
import { BorderedIcon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { CollapsiblePanelHeader, Panel, PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { t } from 'i18next';
import { useQuestionnaireTemplateData } from './questionnaireTemplateUtils';
import { Bold } from '../../../Bold';
import { RenewalFrequency } from '../RenewalFrequency';

const LEFT_CELL_WIDTH = '100px';
const RIGHT_CELL_WIDTH = '180px';

const Cell = styled(Flex)`
  height: 57px;
  align-items: center;
  padding: 20px;
`;

const HeaderCell = (props: FlexProps) => (
  <Cell fontWeight={500} {...props} />
);

const QuestionConfig = ({ question }: { question: QuestionnaireExchangeDefinition }) => {
  const { t } = useTranslation(['translation', 'preQualification']);

  return (
    <>
      {question.questionType === QuestionType.ADDRESS ? (
        getOrderedAddressFields(question.visibleFields).map(field => t(`request.question.addressField.${field}`)).join(', ')
      ) : question.questionType === QuestionType.CHECKBOXES || question.questionType === QuestionType.MULTIPLE_CHOICE ? (
        compact([...question.options, question.allowCustomOption ? t('request.question.other') : undefined]).join(', ')
      ) : question.questionType === QuestionType.DATE_TIME ? (
        question.format === QuestionFormat.DATE ? (
          t('request.question.date')
        ) : question.format === QuestionFormat.TIME ? (
          t('request.question.time')
        ) : question.format === QuestionFormat.DATETIME ? (
          `${t('request.question.date')}, ${t('request.question.time')}`
        ) : (
          null
        )
      ) : question.questionType === QuestionType.DOCUMENT ? (
        compact([
          ...question.options.map(option => t(`request.question.predefinedOption.${option}`)),
          question.allowCustomOption ? t('request.question.other') : undefined,
          question.requireDocument ? t('questionnaireTemplate.uploadDocument', { ns: 'preQualification' }) : undefined,
          question.requireExpiry ? t('request.question.document.expiryDate') : undefined,
        ]).join(', ')
      ) : question.questionType === QuestionType.LONG_TEXT ? (
        <EmDash />
      ) : question.questionType === QuestionType.SHORT_TEXT ? (
        question.schema ? (
          <>
            {t(`request.question.schemaType.${question.schema.type}`)}
            {' '}
            {t('questionnaireTemplate.betweenMinAndMax', { min: question.schema.min, max: question.schema.max, ns: 'preQualification' })}
          </>
        ) : (
          <EmDash />
        )
      ) : question.questionType === QuestionType.GRID ? (
        <>
          <Text>
            {t('request.question.grid.column_other')}:
            {' '}
            {question.columns.map(column => `${column.name} (${t(`request.question.grid.columnType.${column.type}`)})`).join(', ')}
          </Text>
          <Text>
            {t('request.question.grid.currency')}:
            {' '}
            {!isEmpty(question.currencies) ? (
              // @ts-expect-error ts(18048) FIXME: 'question.currencies' is possibly 'undefined'.
              question.currencies.join(', ')
            ) : (
              <EmDash />
            )}
          </Text>
          <Text>
            {t('request.question.grid.minimumMaximumRows')}:
            {' '}
            {question.rowsConfig.isCustom ? (
              t('request.question.grid.minimumMaximumValue', pick(question.rowsConfig, ['min', 'max']))
            ) : (
              <EmDash />
            )}
          </Text>
        </>
      ) : question.questionType === QuestionType.YES_NO ? (
        <>
          <Text>
            {t('questionnaireTemplate.configuration', { ns: 'preQualification' })}:
            {' '}
            {compact([
              ...question.options.map(option => t(`request.question.predefinedOption.${option}`)),
              question.allowCustomOption ? t('request.question.other') : undefined,
            ]).join(', ')}
          </Text>
          <Text>
            {t('request.question.yesNo.moreInformationRequired')}:
            {' '}
            {question.requireMoreInformationOn.includes(PredefinedQuestionOption.YES) ? (
              t('general.yes')
            ) : (
              t('general.no')
            )}
          </Text>
        </>
      ) : (
        null
      )}
    </>
  );
};

const UpdatedQuestionValues = ({
  question,
  hasTypeChanged,
  hasDescriptionChanged,
  hasConfigChanged,
}: {
  question: QuestionnaireExchangeDefinition;
  hasTypeChanged: boolean;
  hasDescriptionChanged: boolean;
  hasConfigChanged: boolean;
}) => {
  const { t } = useTranslation(['preQualification', 'translation']);

  return (
    <>
      {hasDescriptionChanged && (
        <Truncate>
          {t('question', { count: 1 })}:
          {' '}
          {question.description}
        </Truncate>
      )}
      {hasTypeChanged && (
        <Text>
          {t('questionnaireTemplate.questionType')}:
          {' '}
          {t(`request.question.questionType.${question.questionType}`, { ns: 'translation' })}
        </Text>
      )}
      {hasConfigChanged && (
        <Truncate>
          {![QuestionType.GRID, QuestionType.YES_NO].includes(question.questionType) && (
            <>
              {t('questionnaireTemplate.configuration')}:
              {' '}
            </>
          )}
          <QuestionConfig question={question} />
        </Truncate>
      )}
    </>
  );
};

const configFields = ['schema', 'options', 'allowCustomOption', 'visibleFields', 'format', 'requireDocument', 'requireExpiry',
  'columns', 'rowsConfig', 'currencies', 'requireMoreInformationOn'];

const QuestionUpdatedDiff = ({
  previous,
  next,
}: {
  previous: QuestionnaireExchangeDefinition;
  next: QuestionnaireExchangeDefinition;
}) => {
  const { t } = useTranslation(['preQualification', 'translation']);
  const hasTypeChanged = previous.questionType !== next.questionType;
  const hasDescriptionChanged = previous.description !== next.description;
  const hasConfigChanged = !isEqual(pick(previous, configFields), pick(next, configFields));

  return (
    <Stack gap={3} fontSize={1}>
      <Box>
        <Bold display="block">{t('questionnaireTemplate.previousValue')}</Bold>
        <UpdatedQuestionValues
          question={previous}
          hasTypeChanged={hasTypeChanged}
          hasDescriptionChanged={hasDescriptionChanged}
          hasConfigChanged={hasConfigChanged}
        />
      </Box>
      <Box>
        <Bold display="block">{t('questionnaireTemplate.newValue')}</Bold>
        <UpdatedQuestionValues
          question={next}
          hasTypeChanged={hasTypeChanged}
          hasDescriptionChanged={hasDescriptionChanged}
          hasConfigChanged={hasConfigChanged}
        />
      </Box>
    </Stack>
  );
};

const QuestionRow = ({
  question,
  previousQuestion,
  index,
}: {
  question: QuestionnaireExchangeDefinition;
  previousQuestion?: QuestionnaireExchangeDefinition;
  index: number;
}) => {
  const { t } = useTranslation(['preQualification', 'translation']);

  return (
    <Flex>
      <Cell width={LEFT_CELL_WIDTH}>
        {index + 1}
      </Cell>
      <Cell flex="1 1 auto" height={previousQuestion ? 'auto !important' : undefined}>
        <Truncate>
          {question.description}
          {previousQuestion && (
            <Box mt={3}>
              <QuestionUpdatedDiff previous={previousQuestion} next={question} />
            </Box>
          )}
        </Truncate>
      </Cell>
      <Cell flex="0 0 auto" width={RIGHT_CELL_WIDTH} justifyContent="flex-end">
        {t(`request.question.questionType.${question.questionType}`, { ns: 'translation' })}
      </Cell>
    </Flex>
  );
};

const QuestionHeaderRow = ({ header }: { header: string }) => {
  const { t } = useTranslation(['preQualification', 'translation']);

  return (
    <Flex>
      <HeaderCell flex="0 0 auto" width={LEFT_CELL_WIDTH}>
        #
      </HeaderCell>
      <HeaderCell flex="1 1 auto">
        {header}
      </HeaderCell>
      <HeaderCell flex="0 0 auto" width={RIGHT_CELL_WIDTH} justifyContent="flex-end">
        {t('questionnaireTemplate.questionType')}
      </HeaderCell>
    </Flex>
  );
};

const QuestionsSection = ({
  header,
  questions,
}: {
  header: string;
  questions: QuestionnaireExchangeDefinition[];
}) => {
  const { t } = useTranslation('preQualification');
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(false);

  return (
    <>
      <HeaderCell>
        <Box mr={2}>
          <IconButton
            icon={isCollapsed ? 'chevron-right' : 'chevron-down'}
            onClick={() => setIsCollapsed(!isCollapsed)}
            fixedWidth
          />
        </Box>
        {header}
      </HeaderCell>
      {!isCollapsed && (
        <>
          <PanelDivider />
          <QuestionHeaderRow header={t('question', { count: 1 })} />
          {map(questions, (question, index) => (
            <React.Fragment key={question._id}>
              <PanelDivider />
              <QuestionRow
                question={question}
                index={index}
              />
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );
};

const QuestionsUpdatedSection = ({
  updates,
  activeTemplate,
}: {
  updates: {
    question: QuestionnaireExchangeDefinition;
    hasOnlyQuestionVersionChanged: boolean;
  }[];
  activeTemplate: QuestionnaireTemplate;
}) => {
  const { t } = useTranslation('preQualification');
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(false);

  // We don't want to show the diff if only the version has changed
  const newQuestionsWithChanges = React.useMemo(
    () => updates
      .filter(update => !update.hasOnlyQuestionVersionChanged)
      .map(update => update.question),
    [updates],
  );

  const previousQuestions = React.useMemo(
    () => newQuestionsWithChanges.map(question => activeTemplate.exchangeDefById[question._id]),
    [newQuestionsWithChanges, activeTemplate],
  );

  if (isEmpty(newQuestionsWithChanges)) {
    return null;
  }

  return (
    <>
      <HeaderCell>
        <Box mr={2}>
          <IconButton
            icon={isCollapsed ? 'chevron-right' : 'chevron-down'}
            onClick={() => setIsCollapsed(!isCollapsed)}
            fixedWidth
          />
        </Box>
        {t('questionnaireTemplate.questionsUpdated')}
      </HeaderCell>
      {!isCollapsed && (
        <>
          <PanelDivider />
          <QuestionHeaderRow header={t('question', { count: 1 })} />
          {map(newQuestionsWithChanges, (question, index) => (
            <React.Fragment key={question._id}>
              <PanelDivider />
              <QuestionRow
                question={question}
                previousQuestion={previousQuestions[index]}
                index={index}
              />
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );
};

const OrderChangedSection = ({
  previousQuestions,
  nextQuestions,
}: {
  previousQuestions: QuestionnaireExchangeDefinition[];
  nextQuestions: QuestionnaireExchangeDefinition[];
}) => {
  const { t } = useTranslation('preQualification');
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(false);
  const draftTemplate = useQuestionnaireTemplateData();
  const { exchangeDefById } = draftTemplate;

  const previousQuestionIds = React.useMemo(
    () => previousQuestions.map(question => question._id),
    [previousQuestions],
  );

  const nextQuestionIds = React.useMemo(
    () => nextQuestions.map(question => question._id),
    [nextQuestions],
  );

  return (
    <>
      <HeaderCell>
        <IconButton
          icon={isCollapsed ? 'chevron-right' : 'chevron-down'}
          onClick={() => setIsCollapsed(!isCollapsed)}
          fixedWidth
          mr={2}
        />
        {t('questionnaireTemplate.orderChanged')}
      </HeaderCell>
      {!isCollapsed && (
        <>
          <PanelDivider />
          <QuestionHeaderRow header={t('questionnaireTemplate.previousOrder')} />
          {map(previousQuestionIds, (questionId, index) => (
            <React.Fragment key={questionId}>
              <PanelDivider />
              <QuestionRow
                question={exchangeDefById[questionId]}
                index={index}
              />
            </React.Fragment>
          ))}
          <PanelDivider />
          <QuestionHeaderRow header={t('questionnaireTemplate.newOrder')} />
          {map(nextQuestionIds, (questionId, index) => (
            <React.Fragment key={questionId}>
              <PanelDivider />
              <QuestionRow
                question={exchangeDefById[questionId]}
                index={index}
              />
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );
};

const SummarySection = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation('preQualification');
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(false);

  return (
    <>
      <PanelDivider />
      <HeaderCell>
        <IconButton
          icon={isCollapsed ? 'chevron-right' : 'chevron-down'}
          onClick={() => setIsCollapsed(!isCollapsed)}
          fixedWidth
          mr={2}
        />
        {t('summary')}
      </HeaderCell>
      {!isCollapsed && children}
    </>
  );
};

const PurposeSection = ({
  previousInternalData,
  nextInternalData,
}: {
  previousInternalData: QuestionnaireInternalData;
  nextInternalData: QuestionnaireInternalData;
}) => {
  const { t } = useTranslation('preQualification');

  return (
    <>
      <PanelDivider />
      <Flex>
        <HeaderCell flex="0 0 auto" width={LEFT_CELL_WIDTH}>
          {t('questionnaireTemplate.edited')}
        </HeaderCell>
        <HeaderCell flex="1 1 auto">
          {t('questionnairePurpose.purpose')}
        </HeaderCell>
      </Flex>
      <PanelDivider />
      <Flex>
        <Cell width={LEFT_CELL_WIDTH} />
        <Cell flex="1 1 auto" height="auto !important">
          <Stack gap={3} fontSize={1}>
            <Box>
              <Bold as="div">
                {t('questionnaireTemplate.previousValue')}
              </Bold>
              {previousInternalData.purpose || <EmDash />}
            </Box>
            <Box>
              <Bold as="div">
                {t('questionnaireTemplate.newValue')}
              </Bold>
              {nextInternalData.purpose || <EmDash />}
            </Box>
          </Stack>
        </Cell>
      </Flex>
    </>
  );
};

const ExpiryAndRenewalConfigLabel = ({
  renewalConfig,
  expiryConfig,
}: {
  renewalConfig: QuestionnaireRenewalConfig;
  expiryConfig: QuestionnaireExpiryConfig;
}) => {
  const { t } = useTranslation('preQualification');

  return (
    <>
      {renewalConfig.isRecurring ? (
        <>
          {t('questionnaireRenewal.configType.renewal')}:
          {' '}
          {toLower(t(timeUnitToRenewalTranslationKey[renewalConfig.frequency.unit], { count: renewalConfig.frequency.amount }))}
        </>
      ) : expiryConfig.doesExpire ? (
        <>
          {t('questionnaireRenewal.configType.expiry')}:
          {' '}
          {toLower(t(timeUnitToExpireAfterTranslationKey[expiryConfig.offset.unit], { count: expiryConfig.offset.amount }))}
        </>
      ) : (
        t('questionnaireRenewal.configType.none')
      )}
    </>
  );
};

const ExpiryAndRenewalSection = ({
  previousRenewalConfig,
  previousExpiryConfig,
  nextRenewalConfig,
  nextExpiryConfig,
}: {
  previousRenewalConfig: QuestionnaireRenewalConfig;
  previousExpiryConfig: QuestionnaireExpiryConfig;
  nextRenewalConfig: QuestionnaireRenewalConfig;
  nextExpiryConfig: QuestionnaireExpiryConfig;
}) => {
  const { t } = useTranslation('preQualification');

  return (
    <>
      <PanelDivider />
      <Flex>
        <HeaderCell flex="0 0 auto" width={LEFT_CELL_WIDTH}>
          {t('questionnaireTemplate.edited')}
        </HeaderCell>
        <HeaderCell flex="1 1 auto">
          {t('questionnaireRenewal.expirationAndRenewal')}
        </HeaderCell>
      </Flex>
      <PanelDivider />
      <Flex>
        <Cell width={LEFT_CELL_WIDTH} />
        <Cell flex="1 1 auto" height="auto !important">
          <Stack gap={3} fontSize={1}>
            <Box>
              <Bold as="div">
                {t('questionnaireTemplate.previousValue')}
              </Bold>
              <ExpiryAndRenewalConfigLabel
                renewalConfig={previousRenewalConfig}
                expiryConfig={previousExpiryConfig}
              />
            </Box>
            <Box>
              <Bold as="div">
                {t('questionnaireTemplate.newValue')}
              </Bold>
              <ExpiryAndRenewalConfigLabel
                renewalConfig={nextRenewalConfig}
                expiryConfig={nextExpiryConfig}
              />
            </Box>
          </Stack>
        </Cell>
      </Flex>
    </>
  );
};

const InstructionsSection = ({
  previousInstructions,
  nextInstructions,
  nextInstructionsAttachments,
  previousInstructionsAttachments,
}: {
  previousInstructions: string;
  nextInstructions: string;
  previousInstructionsAttachments: Attachment[];
  nextInstructionsAttachments: Attachment[];
}) => {
  const { t } = useTranslation('preQualification');

  const previousValue = React.useMemo(
    () => getInstructionValue(previousInstructions, previousInstructionsAttachments, t),
    [previousInstructions, previousInstructionsAttachments, t],
  );

  const nextValue = React.useMemo(
    () => getInstructionValue(nextInstructions, nextInstructionsAttachments, t),
    [nextInstructions, nextInstructionsAttachments, t],
  );

  return (
    <>
      <PanelDivider />
      <Flex>
        <HeaderCell flex="0 0 auto" width={LEFT_CELL_WIDTH}>
          {t('questionnaireTemplate.edited')}
        </HeaderCell>
        <HeaderCell flex="1 1 auto">
          {t('questionnaireTemplate.instructions')}
        </HeaderCell>
      </Flex>
      <PanelDivider />
      <Flex>
        <Cell width={LEFT_CELL_WIDTH} />
        <Cell flex="1 1 auto" height="auto !important">
          <Stack gap={3} fontSize={1}>
            <Box>
              <Bold as="div">
                {t('questionnaireTemplate.previousValue')}
              </Bold>
              {previousValue || <EmDash />}
            </Box>
            <Box>
              <Bold as="div">
                {t('questionnaireTemplate.newValue')}
              </Bold>
              {nextValue || <EmDash />}
            </Box>
          </Stack>
        </Cell>
      </Flex>
    </>
  );
};

export const RevisionChangeLog = ({ activeTemplate }: { activeTemplate: QuestionnaireTemplate }) => {
  const { t } = useTranslation('preQualification');
  const draftTemplate = useQuestionnaireTemplateData();
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(false);

  const changes = React.useMemo(
    () => getQuestionnaireTemplateChanges(draftTemplate, activeTemplate),
    [draftTemplate, activeTemplate],
  );

  const changesByType = React.useMemo(
    () => groupBy(changes, change => change.type),
    [changes],
  );

  const questionsReorderedChange: any = React.useMemo(
    () => first(changesByType[QuestionnaireTemplateChangeType.QUESTIONS_REORDERED]),
    [changesByType],
  );

  const hasSummaryChanges =
    !isEmpty(changesByType[QuestionnaireTemplateChangeType.INTERNAL_DATA_UPDATED]) ||
    !isEmpty(changesByType[QuestionnaireTemplateChangeType.EXPIRY_AND_RENEWAL_CONFIG_UPDATED]) ||
    !isEmpty(changesByType[QuestionnaireTemplateChangeType.INSTRUCTIONS_UPDATED]);

  return (
    <Panel>
      <CollapsiblePanelHeader
        heading={(
          <Flex alignItems="center">
            <BorderedIcon icon="pen-circle" mr={2} sx={{ borderRadius: '5px !important' }} />
            <Text>
              {t('questionnaireTemplate.changeLog')}
            </Text>
          </Flex>
        )}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      />
      {!isCollapsed && (
        isEmpty(changes) ? (
          <>
            <PanelDivider />
            <Box p="20px">
              <Pictogram
                variant="empty-state"
                label={t('questionnaireTemplate.noChanges')}
              />
            </Box>
          </>
        ) : (
          <>
            {hasSummaryChanges && (
              <SummarySection>
                {changesByType[QuestionnaireTemplateChangeType.INTERNAL_DATA_UPDATED]?.map((change: any) => (
                  <PurposeSection
                    key={change.type}
                    nextInternalData={change.nextInternalData}
                    previousInternalData={change.previousInternalData}
                  />
                ))}
                {changesByType[QuestionnaireTemplateChangeType.EXPIRY_AND_RENEWAL_CONFIG_UPDATED]?.map((change: any) => (
                  <ExpiryAndRenewalSection
                    key={change.type}
                    nextRenewalConfig={change.nextRenewalConfig}
                    nextExpiryConfig={change.nextExpiryConfig}
                    previousRenewalConfig={change.previousRenewalConfig}
                    previousExpiryConfig={change.previousExpiryConfig}
                  />
                ))}
                {changesByType[QuestionnaireTemplateChangeType.INSTRUCTIONS_UPDATED]?.map((change: any) => (
                  <InstructionsSection
                    key={change.type}
                    nextInstructions={change.nextInstructions}
                    previousInstructions={change.previousInstructions}
                    nextInstructionsAttachments={change.nextInstructionsAttachments}
                    previousInstructionsAttachments={change.previousInstructionsAttachments}
                  />
                ))}
              </SummarySection>
            )}
            {changesByType[QuestionnaireTemplateChangeType.QUESTIONS_ADDED]?.map((change: any) => (
              <React.Fragment key={change.type}>
                <PanelDivider />
                <QuestionsSection
                  header={t('questionnaireTemplate.questionsAdded')}
                  questions={change.questions}
                />
              </React.Fragment>
            ))}
            {changesByType[QuestionnaireTemplateChangeType.QUESTIONS_UPDATED]?.map((change: any) => (
              <React.Fragment key={change.type}>
                <PanelDivider />
                <QuestionsUpdatedSection
                  updates={change.updates}
                  activeTemplate={activeTemplate}
                />
              </React.Fragment>
            ))}
            {changesByType[QuestionnaireTemplateChangeType.QUESTIONS_REMOVED]?.map((change: any) => (
              <React.Fragment key={change.type}>
                <PanelDivider />
                <QuestionsSection
                  header={t('questionnaireTemplate.questionsRemoved')}
                  questions={change.questions}
                />
              </React.Fragment>
            ))}
            {questionsReorderedChange && (
              <>
                <PanelDivider />
                <OrderChangedSection
                  previousQuestions={questionsReorderedChange.previousQuestions}
                  nextQuestions={questionsReorderedChange.nextQuestions}
                />
              </>
            )}
          </>
        )
      )}
    </Panel>
  );
};
