import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDriveNavigation } from '../../AppRouting';
import * as Layout from '../../Layout';

export const DriveLayout = ({ selectedTabId, children }) => {
  const { t } = useTranslation('translation');
  const { navigateToDocuments, navigateToHistory } = useDriveNavigation();

  const tabs = useMemo(
    () => [{
      id: 'documents',
      name: t('request.documents.document_other'),
      navigate: () => navigateToDocuments(),
    }, {
      id: 'history',
      name: t('general.history'),
      navigate: () => navigateToHistory(),
    }],
    [navigateToDocuments, navigateToHistory, t],
  );

  return (
    <Layout.HeaderAndContent
      header={
        <Layout.PageHeader
          heading={t('pageHeader.drive')}
          icon="hdd-o"
          tabs={tabs}
          selectedTabId={selectedTabId}
        />
      }
      content={children}
    />
  );
};
