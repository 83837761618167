import { conforms } from 'lodash';
import { ActionType, Live, PageType } from '@deepstream/common/rfq-utils';
import { useMemo } from 'react';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils';
import * as rfx from '../../../rfx';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useRecipientId, useRfqId } from '../../../useRfq';
import { useNotifications } from '../../Notifications';

export const useBidCommentNotifications = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqId = useRfqId();
  const recipientId = useRecipientId();
  const { pages } = rfx.useStructure<Live>();

  const notificationFilter = useMemo(() => {
    const isRecipient = currentCompanyId === recipientId;
    const pageIds = pages.filter(page => !page.type || page.type === PageType.AUCTION).map(page => page._id);

    if (isRecipient) {
      return conforms({
        domain: domain => domain === NotificationDomain.RFQ_RECEIVED,
        action: action => action === NotificationAction.EXCHANGE_REPLY_SENT,
        to: to => to.companyId === currentCompanyId,
        meta: meta => {
          return (
            (meta.actionType === ActionType.NONE || meta.hasComment) &&
            meta.rfqId === rfqId &&
            pageIds.includes(meta.pageId)
          );
        },
      });
    } else {
      return conforms({
        domain: domain => domain === NotificationDomain.RFQ_SENT,
        action: action => action === NotificationAction.EXCHANGE_REPLY_SENT,
        to: to => to.companyId === currentCompanyId,
        meta: meta => {
          return (
            (meta.actionType === ActionType.NONE || meta.hasComment) &&
            meta.rfqId === rfqId &&
            pageIds.includes(meta.pageId) &&
            meta.recipientId === recipientId
          );
        },
      });
    }
  }, [currentCompanyId, recipientId, rfqId, pages]);

  const notifications = useNotifications(notificationFilter);

  return notifications;
};
