const DeepStreamIcon = ({
  color,
  width = '16',
  height = '22',
}: {
  color: string;
  width?: string | number;
  height?: string | number;
}) => (
  <svg width={width} height={height} viewBox="2 2 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.2138 8.40293H8.74794V3.83868H6.46582V10.685H15.2138V8.40293Z" fill={color} />
    <path d="M0 14.4888H6.46583V19.053H8.74795V12.2066H0V14.4888Z" fill={color} />
  </svg>
);

export default DeepStreamIcon;
