import { useMemo } from 'react';
import * as rfx from '../../rfx';

export const useStageWithIndex = (stageId?: string) => {
  const structure = rfx.useStructure();

  return useMemo(() => {
    const { stages } = structure;

    if (!stageId) {
      return {
        stage: undefined,
        stageIndex: undefined,
      };
    }

    const stageIndex = stages.map((stage) => stage._id).indexOf(stageId);

    return {
      stage: stages[stageIndex],
      stageIndex,
    };
  }, [structure, stageId]);
};
