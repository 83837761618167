import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';

import { LoadingPanel } from '../../../../ui/Loading';
import { ErrorPanel } from '../../../../ui/ErrorMessage';
import { useListCategories } from '../../utils';
import { QuestionsPanel } from './QuestionsPanel';
import { PreQualQuestion } from '../../../../types';

export const QuestionsList = ({
  onQuestionUpdate,
  onQuestionArchive,
  categoryId,
}: {
  onQuestionUpdate?: (question: PreQualQuestion, categoryId: string) => void;
  onQuestionArchive?: (questionId: string) => void;
  categoryId?: string;
}) => {
  const { t } = useTranslation();
  const { data, isLoading, isError } = useListCategories({
    filter: {
      ...(categoryId ? { categoryIds: [categoryId] } : {}),
    },
  });

  if (isLoading) {
    return <LoadingPanel />;
  }

  if (isError) {
    return <ErrorPanel error={t('errors.unexpected')} />;
  }

  return (
    <>
      {map(data, (category) => (
        <GridIdPrefixProvider key={category._id}>
          <GridMenuStateProvider>
            <QuestionsPanel
              category={category}
              onUpdate={onQuestionUpdate}
              onArchive={onQuestionArchive}
            />
          </GridMenuStateProvider>
        </GridIdPrefixProvider>
      ))}
    </>
  );
};
