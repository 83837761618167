import { useCallback } from 'react';
import { find } from 'lodash';
import { useTranslation, Trans } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Flex, Box, Text } from 'rebass/styled-components';

import { useRouter, useNavigate } from '@tanstack/react-router';
import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import { useApi } from '../../../api';

import { useCurrentCompanyId } from '../../../currentCompanyId';

import { useToaster } from '../../../toast';
import { useModalState } from '../../../ui/useModalState';
import { useMutation } from '../../../useMutation';
import { useRfqId, useDraftRfqStructure } from '../../../useRfq';
import * as rfx from '../../../rfx';
import { requestsIndexRoute } from '../../../AppRouting';

// TODO: Get rid of rfqId after we a have proper structure for using `useRfqId`
export const useSenderStatus = ({ rfqId, isTemplate }: { rfqId: string, isTemplate?: boolean }) => {
  const currentCompanyId = useCurrentCompanyId();

  const structure = rfx.useStructure();

  const companyAsSender = find(
    structure.senders,
    (sender) => sender.company._id === currentCompanyId,
  );

  return {
    sender: companyAsSender,
  };
};

const useCollaborationInviteStatusMutation = (opts?: {
  onSuccess?: () => void | Promise<unknown>,
  onError?: () => void | Promise<unknown>,
}) => {
  const companyId = useCurrentCompanyId();
  const rfqId = useRfqId();
  const api = useApi();
  const [updateStatus, mutationProps] = useMutation(
    api.updateCollaborationInviteStatus,
    {
      onSuccess: opts?.onSuccess,
      onError: opts?.onSuccess,
    },
  );

  const handleUpdate = useCallback((invitationAccepted: boolean) => {
    // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
    return updateStatus({ rfqId, companyId, invitationAccepted });
  }, [updateStatus, rfqId, companyId]);

  return [handleUpdate, mutationProps] as const;
};

const RequestInviteModal = ({
  isOpen,
  onClose,
  companyName,
}: {
  isOpen: boolean;
  onClose: () => void;
  companyName: string;
}) => {
  const currentCompanyId = useCurrentCompanyId();
  const rfqId = useRfqId();
  const queryClient = useQueryClient();
  const { t } = useTranslation(['request', 'translation']);
  const toaster = useToaster();
  const { refetch: refetchRfqDraftStructure, queryKey: rfqDraftStructureKey } = useDraftRfqStructure({
    rfqId,
    // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string | undefined'.
    currentCompanyId,
    isTemplate: false,
  });
  const navigate = useNavigate();
  const router = useRouter();

  const [updateInvitationStatus, { isLoading }] = useCollaborationInviteStatusMutation({
    onSuccess: callAll(
      (_, { invitationAccepted }) => {
        const message = invitationAccepted
          ? t('review.collaboratorInviteModal.toaster.invitationAccepted')
          : t('review.collaboratorInviteModal.toaster.invitationRejected');
        toaster.success(message);
      },
      async (_, { invitationAccepted }) => {
        if (invitationAccepted) {
          try {
            await refetchRfqDraftStructure();
            router.invalidate();
          } catch (err) {
            toaster.error(t('errors.unexpected', { ns: 'translation' }));
          }
        } else {
          queryClient.invalidateQueries(rfqDraftStructureKey);
          navigate({
            to: requestsIndexRoute.to,
            // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
            params: { currentCompanyId },
            search: { tab: 'drafts' },
          });
        }
      },
    ),
    onError: () => toaster.error(t('review.collaboratorInviteModal.toaster.updateError')),
  });

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        {t('review.collaboratorInviteModal.heading')}
      </ModalHeader>
      <ModalBody>
        <Box sx={{ lineHeight: 2 }}>
          <Text>{t('review.collaboratorInviteModal.termsLabel')}</Text>
          <Box as="ul">
            <Box as="li">
              <Trans
                i18nKey="review.collaboratorInviteModal.terms.requestAssociation"
                ns="request"
                values={{ companyName }}
                components={{ b: <strong /> }}
              />
            </Box>
            <Box as="li">
              {t('review.collaboratorInviteModal.terms.requestAccess')}
            </Box>
            <Box as="li">
              {t('review.collaboratorInviteModal.terms.requestTeamMembers')}
            </Box>
          </Box>
        </Box>
        <MessageBlock variant="warn">
          {t('review.collaboratorInviteModal.warning')}
        </MessageBlock>
      </ModalBody>
      <ModalFooter sx={{ gap: '20px' }}>
        <Button
          variant="secondary"
          onClick={onClose} sx={{ marginRight: 'auto' }}
          disabled={isLoading}
        >
          {t('review.collaboratorInviteModal.goBackLabel')}
        </Button>
        <Button
          variant="danger"
          onClick={() => updateInvitationStatus(false)}
          disabled={isLoading}
        >
          {t('review.collaboratorInviteModal.rejectLabel')}
        </Button>
        <Button
          variant="success"
          onClick={() => updateInvitationStatus(true)}
          disabled={isLoading}
        >
          {t('review.collaboratorInviteModal.acceptLabel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const RequestInvite = () => {
  const { isOpen, open, close } = useModalState();
  const { t } = useTranslation('request');
  const rfqId = useRfqId();
  const { sender } = useSenderStatus({ rfqId });

  // @ts-expect-error ts(2339) FIXME: Property 'inviter' does not exist on type 'Sender | undefined'.
  const { inviter, inviterCompany, company: { role } } = sender;

  return (
    <>
      <Flex
        alignItems="center"
        p={3}
        sx={{
          backgroundColor: 'white',
          border: (theme) => `2px solid ${theme.colors.primary}`,
          borderRadius: 'small',
        }}
      >
        {inviter && inviterCompany ? (
          <Box sx={{ flex: '1 1 auto' }}>
            {role ? (
              <Trans
                i18nKey="review.collaboratorInvite.inviteMessageWithRole"
                ns="request"
                values={{ inviterName: inviter.name, inviterCompanyName: inviterCompany.name, collaboratorRole: role }}
                components={{ bold: <strong /> }}
              />
            ) : (
              <Trans
                i18nKey="review.collaboratorInvite.inviteMessage"
                ns="request"
                values={{ inviterName: inviter.name, inviterCompanyName: inviterCompany.name }}
                components={{ bold: <strong /> }}
              />
            )}
          </Box>
        ) : (
          <Box sx={{ flex: '1 1 auto' }}>
            {role ? (
              <Trans
                i18nKey="review.collaboratorInvite.unknownInviteMessageWithRole"
                ns="request"
                values={{ role }}
                components={{ bold: <strong /> }}
              />
            ) : (
              <Trans
                i18nKey="review.collaboratorInvite.unknownInviteMessage"
                ns="request"
              />
            )}
          </Box>
        )}
        <Box sx={{ flex: '0 0 100px' }} ml={4}>
          <Button
            small
            onClick={open}
            minWidth="100%"
            iconLeft="reply"
          >
            {t('review.collaboratorInvite.respondButtonLabel')}
          </Button>
        </Box>
      </Flex>

      <RequestInviteModal
        // @ts-expect-error ts(18048) FIXME: 'sender' is possibly 'undefined'.
        companyName={sender.company.name}
        isOpen={isOpen}
        onClose={close}
      />
    </>
  );
};
