import { useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';
import { compact, isEmpty } from 'lodash';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { ReadOnlyGrid } from '@deepstream/ui-kit/grid/EditableGrid/ReadOnlyGrid';
import { DefaultEditableGridStyles } from '@deepstream/ui-kit/grid/EditableGrid/EditableGridStyles';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { ACTION_COLUMN_WIDTH, DEFAULT_EDITABLE_DATA_CELL_WIDTH, calculateMaxGridHeight } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import * as rfx from '../rfx';
import * as draft from './draft';
import { ErrorMessage } from '../ui/ErrorMessage';
import * as validation from './validation';
import {
  QuestionDescriptionValueCell,
  QuestionSupplierResponseValueCell,
  QuestionTypeValueCell,
  SwitchValueCell,
  ValidationAwareRowNumberCell,
} from '../ui/ExchangeDefsGrid/validationAwareValueCell';
import { SimpleHeader } from '../ui/ExchangeDefsGrid/header';

const frozenLeftColumnIds = ['rowNumber', 'description'];

export const QuestionExchangeDefsGrid = () => {
  const { t } = useTranslation();
  const exchangeDefs = rfx.useSectionExchangeDefs();
  const { isLive } = rfx.useState();
  const showValidationErrors = draft.useShowValidationErrors();
  const { error } = validation.useError('exchangeDefs');
  const isSender = rfx.useIsSender();
  const showSupplierResponse = isSender && !isLive;

  const columns = compact([
    {
      _id: 'rowNumber',
      accessorKey: 'rowNumber',
      Header: SimpleHeader,
      label: '',
      ValueCell: ValidationAwareRowNumberCell,
      width: ACTION_COLUMN_WIDTH,
      fixedWidth: true,
    },
    {
      _id: 'description',
      accessorKey: 'description',
      Header: SimpleHeader,
      label: t('request.question.question', { count: 1 }),
      description: t('buyer', { ns: 'general' }),
      ValueCell: QuestionDescriptionValueCell,
      showIconWhenObsolete: true,
      width: showSupplierResponse ? 310 : 770,
    },
    showSupplierResponse
      ? {
        _id: 'options',
        accessorKey: 'options',
        Header: SimpleHeader,
        label: t('request.question.response', { count: 1 }),
        description: t('supplier', { count: 1, ns: 'general' }),
        ValueCell: QuestionSupplierResponseValueCell,
        width: isSender ? 460 : DEFAULT_EDITABLE_DATA_CELL_WIDTH,
      }
      : null,
    {
      _id: 'questionType',
      accessorKey: 'questionType',
      Header: SimpleHeader,
      label: t('general.type'),
      ValueCell: QuestionTypeValueCell,
      width: isSender ? 150 : DEFAULT_EDITABLE_DATA_CELL_WIDTH,
    },
    {
      _id: 'isRequired',
      accessorKey: 'isRequired',
      Header: SimpleHeader,
      label: t('request.question.required'),
      ValueCell: SwitchValueCell,
      width: isSender ? 150 : DEFAULT_EDITABLE_DATA_CELL_WIDTH,
    },
  ]);

  const maxGridHeight = calculateMaxGridHeight(exchangeDefs.length);

  return showValidationErrors && error ? (
    <PanelPadding bg="errorBackground" py="16px">
      <ErrorMessage fontSize={2} error={error} />
    </PanelPadding>
  ) : !isEmpty(exchangeDefs) ? (
    <PanelPadding>
      <GridIdPrefixProvider>
        <DefaultEditableGridStyles
          style={{ width: '100%', height: `min(100vh - 300px, ${maxGridHeight}px)` }}
          isReadOnly
        >
          <ReadOnlyGrid
            columns={columns}
            rowData={exchangeDefs as any}
            frozenLeftColumnIds={frozenLeftColumnIds}
          />
        </DefaultEditableGridStyles>
      </GridIdPrefixProvider>
    </PanelPadding>
  ) : (
    <PanelPadding>
      <Text color="subtext" fontSize={2}>
        {t('request.question.noQuestionsAdded')}
      </Text>
    </PanelPadding>
  );
};
