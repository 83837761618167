import { useCallback } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Popover, usePopover } from '@deepstream/ui-kit/elements/popup/usePopover';
import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { useCurrentCompanyId } from '../../currentCompanyId';
import {
  useNotifications,
  useMarkAsReadNotificationsMutation,
} from './NotificationsProvider';
import { useGetNotificationData } from './notificationTable';
import { LineClamp } from '../../LineClamp';
import { Bold } from '../../Bold';
import { Datetime } from '../../Datetime';
import { useApi } from '../../api';
import { useToaster } from '../../toast';
import { useMutation } from '../../useMutation';
import { useDeviceSize } from '../../ui/useDeviceSize';
import { useNavigate } from '../../tanstackRouter';
import { NavButton } from '../../page-helpers/MainNav';
import { Notification } from './types';

const NOTIFICATION_HEIGHT = 80;

const useMarkAllNotificationsAsRead = () => {
  const api = useApi();
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId()!;
  const toaster = useToaster();
  const queryClient = useQueryClient();

  return useMutation(
    () => api.markAllNotificationsAsRead({ companyId: currentCompanyId }),
    {
      onSuccess: callAll(
        () => toaster.success(t('notificationsPage.toaster.markAsReadSuccess')),
        () => queryClient.invalidateQueries('notifications'),
      ),
      onError: () => toaster.error(t('notificationsPage.toaster.markAsReadError')),
    },
  );
};

const NotificationsHeader = ({
  onViewAll,
}: {
  onViewAll: () => void;
}) => {
  const { t } = useTranslation();
  const [markAllNotificationsAsRead, { isLoading }] = useMarkAllNotificationsAsRead();

  return (
    <Flex alignItems="center" height="60px" fontSize={5} sx={{ borderBottom: 'darkGray' }} padding="0 20px">
      <Icon icon="bell-o" color="subtext" mr={2} />
      <Bold flex={1}>
        {t('pageHeader.notifications')}
      </Bold>
      <ButtonGroup marginBetween="-1px">
        <Button small variant="secondary-outline" onClick={onViewAll}>
          {t('notificationsPage.viewAll')}
        </Button>
        <DropdownMenu
          small
          stopClickEvents
          variant="secondary-outline"
          iconLeft="ellipsis-h"
          ariaLabel={t('notificationsPage.actions.dropdownLabel')}
          wrapperStyle={{
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px',
          }}
          popoverProps={{
            portal: false,
            style: {
              position: 'absolute',
              right: '20px',
              top: '43px',
            },
          }}
        >
          <DropdownMenuItem
            icon="envelope-open-o"
            onSelect={() => markAllNotificationsAsRead(undefined)}
            disabled={isLoading}
          >
            {t('notificationsPage.actions.markAllAsRead')}
          </DropdownMenuItem>
        </DropdownMenu>
      </ButtonGroup>
    </Flex>
  );
};

const NotificationLine = ({
  notification,
  closePopover,
}: {
  notification: Notification;
  closePopover: () => void;
}) => {
  const getNotificationData = useGetNotificationData();
  const notificationData = getNotificationData(notification) || {};
  // @ts-expect-error ts(2339) FIXME: Property 'message' does not exist on type '{}'.
  const { message, navigateOptions, notificationAction } = notificationData;
  const navigate = useNavigate();
  const [markAsRead] = useMarkAsReadNotificationsMutation([
    () => navigateOptions
      ? navigate(navigateOptions)
      : notificationAction
        ? notificationAction()
        : null,
  ]);

  return (
    <Box
      role="menuitem"
      onClick={() => {
        markAsRead({ notificationIds: [notification._id] });
        closePopover();
      }}
      sx={{
        cursor: 'pointer',
        height: `${NOTIFICATION_HEIGHT}px`,
        borderTop: 'lightGray2',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0px 20px',
        '&:hover': {
          backgroundColor: 'primaryBackground',
        },
        ':first-child': {
          borderTop: 0,
        },
      }}
    >
      <LineClamp lines={2}>
        <Bold>{message}</Bold>
      </LineClamp>
      <Text color="subtext" fontSize={1} mt={1}>
        <Bold>
          <Datetime value={notification.date} />
        </Bold>
      </Text>
    </Box>
  );
};

export const NotificationsDropdown = ({
  navigateToNotifications,
}: {
  navigateToNotifications: () => void;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const notifications = useNotifications({ to: { companyId: currentCompanyId } });
  const { isExtraSmall, isSmall } = useDeviceSize();
  const isMobile = isExtraSmall || isSmall;
  const popover = usePopover({
    placement: 'bottom-end',
    alignToWindowEdge: isMobile ? 'right' : undefined,
    strategy: 'fixed',
  });

  const onViewAll = useCallback(
    () => {
      navigateToNotifications();
      popover.close();
    },
    [navigateToNotifications, popover],
  );

  return (
    <>
      <Flex
        ref={popover.setReferenceElement}
        aria-expanded={popover.isOpen}
        aria-haspopup="true"
        sx={{
          height: '100%',
        }}
      >
        <NavButton
          icon="bell-o"
          count={notifications.length}
          onClick={() => {
            popover.toggle();
            popover.update?.();
          }}
          width="68px"
          minWidth="68px"
        />
      </Flex>

      <Popover
        {...popover}
        sx={{
          marginTop: 0,
        }}
        width="100%"
        maxWidth={isExtraSmall ? '300px' : '400px'}
        onClickOutside={popover.close}
      >
        <Box
          sx={{
            background: 'white',
            borderRadius: 'small',
            width: '100%',
          }}
        >
          <NotificationsHeader
            onViewAll={onViewAll}
          />
          {notifications.length ? (
            <Box
              role="menu"
              sx={{
                maxHeight: 4.5 * NOTIFICATION_HEIGHT,
                overflow: 'auto',
              }}
            >
              {map(notifications, (notification) => (
                <NotificationLine
                  key={notification._id}
                  notification={notification}
                  closePopover={popover.close}
                />
              ))}
            </Box>
          ) : (
            <Box py="30px" px="20px">
              {t('panels.unreadNotifications.empty', { ns: 'dashboard' })}
            </Box>
          )}
        </Box>
      </Popover>
    </>
  );
};
