import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';
import { FeeType, isFeeExchangeDef } from '@deepstream/common/rfq-utils';
import { compact, filter } from 'lodash';
import { withProps } from '@deepstream/ui-utils/withProps';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { StaticTableStyles, FormTableStyles } from '../TableStyles';
import { Table } from '../Table';
import { Required } from '../Required';
import { useExchangeDefsField, createFee } from './exchangeDefs';
import * as rfx from '../rfx';
import {
  ExchangeDefActionsCell,
  ExchangeDefDescriptionCell,
  ExchangeDefDescriptionFieldCell,
  SelectFieldCell,
  ValidationErrorCell,
} from './cell';
import { nestCells } from '../nestCells';
import { TextCell } from '../TextCell';
import { ExchangeList } from '../ExchangeTable';
import { useDeviceSize } from '../ui/useDeviceSize';
import { ExchangeDefRowCells } from './ExchangeDefRowCells';
import { ExchangeDefListItem } from './ExchangeDefListItem';

const AddFeeButton = (props: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button small variant="secondary" iconLeft="plus" {...props}>
      {t('request.vesselPricing.fees.addFee')}
    </Button>
  );
};

const FieldColumnHeader = ({ column }) => (
  <>
    {column.label}
    {column.required && <Required />}
  </>
);

const FeeValidationErrorCell = withProps(ValidationErrorCell, { fieldName: 'feeExchangeDefs' });
const FeeExchangeDefListItem = withProps(ExchangeDefListItem, { fieldName: 'feeExchangeDefs' });
const FeeExchangeDefRowCells = withProps(ExchangeDefRowCells, { fieldName: 'feeExchangeDefs' });

export const FeeTable = () => {
  const { t } = useTranslation();
  const { isExtraSmall } = useDeviceSize();
  const { isTemplate } = rfx.useState();
  const senderSectionExchangeDefs = rfx.useSectionExchangeDefsByCreator({
    filter: isFeeExchangeDef,
    group: 'sender',
  });
  const sectionExchangeDefs = filter(rfx.useSectionExchangeDefs(), isFeeExchangeDef);
  // template exchangeDefs don't have a creatorId so we can't use
  // `useSectionExchangeDefsByCreator`
  const feeExchangeDefs = isTemplate ? sectionExchangeDefs : senderSectionExchangeDefs;

  const columns = useMemo(
    () => [
      {
        id: 'description',
        Header: t('general.description'),
        accessor: 'description',
        Cell: nestCells(FeeValidationErrorCell, ExchangeDefDescriptionCell),
      },
      {
        id: 'feeType',
        Header: t('general.type'),
        accessor: (exchangeDef) => t(`request.vesselPricing.fees.feeType.${exchangeDef.feeType}`),
        Cell: nestCells(FeeValidationErrorCell, TextCell),
        width: '40%',
      },
    ],
    [t],
  );

  return feeExchangeDefs.length === 0 ? (
    <PanelPadding>
      <Text color="subtext" fontSize={2}>
        {t('request.vesselPricing.fees.noFeesAdded')}
      </Text>
    </PanelPadding>
  ) : isExtraSmall ? (
    <ExchangeList
      columns={columns}
      exchanges={feeExchangeDefs}
      onRowClick={null}
      CustomListItem={FeeExchangeDefListItem}
    />
  ) : (
    <StaticTableStyles>
      <Table
        columns={columns}
        data={feeExchangeDefs}
        CustomRowCells={FeeExchangeDefRowCells}
      />
    </StaticTableStyles>
  );
};

export const FeeTableField = ({
  fieldName = 'exchangeDefs',
  vesselId,
  isSectionObsolete,
}: {
  fieldName?: string;
  vesselId?: string;
  isSectionObsolete?: boolean;
}) => {
  const { t } = useTranslation();

  const { exchangeDefs, addExchangeDef } = useExchangeDefsField(
    fieldName,
    createFee(vesselId),
  );

  const columns = useMemo(
    () => compact([
      {
        id: 'description',
        Header: FieldColumnHeader,
        label: t('general.description'),
        accessor: 'description',
        Cell: withProps(ExchangeDefDescriptionFieldCell, { fieldName }),
        hideNumbering: true,
        required: true,
      },
      {
        id: 'feeType',
        Header: FieldColumnHeader,
        label: t('general.type'),
        accessor: 'feeType',
        Cell: withProps(SelectFieldCell, { fieldName }),
        required: true,
        width: 200,
        options: Object.values(FeeType).map(value => ({
          value,
          label: t(`request.vesselPricing.fees.feeType.${value}`),
        })),
      },
      !isSectionObsolete && {
        id: 'menu',
        Header: () => '',
        accessor: null,
        width: 48,
        Cell: withProps(ExchangeDefActionsCell, { fieldName }),
      },
    ]),
    [fieldName, t, isSectionObsolete],
  );

  return (
    <>
      {exchangeDefs.length > 0 ? (
        <FormTableStyles>
          <Table columns={columns} data={exchangeDefs} />
        </FormTableStyles>
      ) : (
        <Text color="subtext" fontSize={2} mb={2}>
          {t('request.vesselPricing.fees.noFeesAdded')}
        </Text>
      )}
      {!isSectionObsolete && (
        <AddFeeButton
          type="button"
          onClick={() => addExchangeDef()}
          mt={2}
        />
      )}
    </>
  );
};
