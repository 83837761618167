import { ContractDraftHeader } from './ContractDraftHeader';
import { ContractDraftSummary } from './ContractDraftSummary';
import * as Layout from '../../Layout';
import { useContractId } from './contract';

export const ContractDraftSummaryPageContent = () => {
  const tabId = 'summary';
  const contractId = useContractId();

  return (
    <Layout.HeaderAndContent
      header={
        <ContractDraftHeader
          key={contractId}
          contractId={contractId}
          selectedTabId={tabId}
        />
      }
      content={
        <ContractDraftSummary contractId={contractId} />
      }
    />
  );
};
