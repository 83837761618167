import { useTranslation } from 'react-i18next';

import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { LoadingPanel } from '../../../ui/Loading';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import { useQuestionnaire } from './useQuestionnaire';
import { QuestionnaireProvider } from './questionnaireUtils';
import { QuestionnairePagePanels } from './QuestionnairePagePanels';
import { TeamUsersPanel } from './Team/TeamUsersPanel';

export const QuestionnaireTeam = () => {
  const { t } = useTranslation();
  const { data: questionnaire, isLoading, isError } = useQuestionnaire();

  return isLoading ? (
    <LoadingPanel />
  ) : isError ? (
    <ErrorPanel error={t('errors.unexpected')} />
  ) : (
    // @ts-expect-error ts(2322) FIXME: Type 'Questionnaire | undefined' is not assignable to type 'Questionnaire'.
    <QuestionnaireProvider questionnaire={questionnaire}>
      <Stack gap="24px">
        <QuestionnairePagePanels />
        <TeamUsersPanel />
      </Stack>
    </QuestionnaireProvider>
  );
};
