import * as React from 'react';
import { Text, Flex, Box, TextProps, BoxProps, ButtonProps, Button } from 'rebass/styled-components';
import styled from 'styled-components';
import { rgba } from 'polished';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { PanelText } from '@deepstream/ui-kit/elements/Panel';
import { DateFormat } from '@deepstream/utils';
import { Document } from './types';
import { Datetime2 } from '../Datetime';

const Truncate = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Label: React.FC<TextProps> = (props) => (
  <Text
    as="label"
    color="lightNavy"
    fontWeight={500}
    fontSize={2}
    {...props}
  />
);

const StyledButton = styled(Button)`
  transition: all 300ms;
  cursor: pointer;

  &:hover, &:active, &:focus {
    background-color: transparent !important;
  }

  &:disabled {
    cursor: inherit;
    background-color: transparent;
    color: ${props => rgba(props.theme.colors.gray, 0.5)};
  }

  &:hover:not(:disabled) i {
    color: #405b8f;
  }
`;

const DownloadDocumentButton: React.FC<ButtonProps> = React.forwardRef((props, ref) => (
  <StyledButton
    ref={ref}
    color="primary"
    bg="transparent"
    p={0}
    m={0}
    width="38px"
    height="38px"
    {...props}
  >
    <Flex alignItems="center" justifyContent="center">
      <Icon icon="download" fontSize={18} textAlign="center" />
    </Flex>
  </StyledButton>
));

DownloadDocumentButton.displayName = 'DownloadDocumentButton';

type PublicDocumentsProps = BoxProps & {
  documents: Document[];
  isLoggedIn?: boolean;
  downloadDocument?: (document: Document) => void;
};

export const PublicDocuments: React.FC<PublicDocumentsProps> = ({ documents, isLoggedIn, downloadDocument, ...props }) => {
  const { t } = useTranslation(['companyProfile', 'translation']);

  return (
    <Box {...props}>
      <PanelText>
        <Flex flexWrap="wrap" mb={1}>
          <Box flex={1}><Label>{t('drivePage.documentsColumns.name', { ns: 'translation' })}</Label></Box>
          <Box width={60}><Label>{t('drivePage.documentsColumns.type', { ns: 'translation' })}</Label></Box>
          <Box width={90}><Label>{t('drivePage.documentsColumns.expires', { ns: 'translation' })}</Label></Box>
          <Box width={38} />
        </Flex>
        {documents.map(document => (
          <Flex key={document._id} flexWrap="wrap" alignItems="center">
            <Box flex={1} pr={2} style={{ minWidth: 0 }}>
              <Truncate>{document.name}</Truncate>
            </Box>
            <Box width={60}>
              {document.type}
            </Box>
            <Box width={90}>
              <Datetime2 value={document.expiryDate} format={DateFormat.DD_MMM_YYYY} />
            </Box>
            <Flex width={38}>
              <Tooltip content={!isLoggedIn ? t('publicDocuments.signInToDownload') : ''}>
                <div>
                  <DownloadDocumentButton
                    disabled={!isLoggedIn}
                    onClick={() => downloadDocument && downloadDocument(document)}
                  />
                </div>
              </Tooltip>
            </Flex>
          </Flex>
        ))}
      </PanelText>
    </Box>
  );
};
