import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';

import { LegacyMultiStageLineItemsEventType, MultiStageLineItemsEventType } from '@deepstream/common/multiStageLineItemsTracking';
import { Draft } from '@deepstream/common/rfq-utils';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Button } from '@deepstream/ui-kit/elements/button/Button';

import { useRequestEditNavigation } from '../../../appNavigation';
import * as rfx from '../../../rfx';
import { useRfqId } from '../../../useRfq';
import { useTracking } from '../../../useTracking';
import { HelpCenterInlineLink } from '../../../HelpCenterLink';
import { useSystemFeatureFlags } from '../../../systemFeatureFlags';

const NewMultiStageResponsesInfoBanner = () => {
  const { t } = useTranslation('request');

  const rfqId = useRfqId();
  const structure = rfx.useStructure<Draft>();
  const { isTemplate } = rfx.useState();
  const rfxPermissions = rfx.useRfxPermissions();

  const {
    hasMultiStageResponses,
    canEnableMultiStageResponses,
  } = rfx.checkMultiStageBannerEligibility({ structure, rfxPermissions });

  const { trackMultiStageLineItemsEvent } = useTracking();
  const editNavigation = useRequestEditNavigation();
  const handleCtaClick = useCallback(async () => {
    await trackMultiStageLineItemsEvent({
      eventType: MultiStageLineItemsEventType.FLOW_OPENED,
      entityType: isTemplate ? 'requestTemplate' : 'request',
      entityId: rfqId,
    });
    editNavigation.navigateToSetupMultiStageResponsesFlow();
  }, [rfqId, trackMultiStageLineItemsEvent, isTemplate, editNavigation]);

  const trackBannerSeenCallback = useCallback(async () => {
    await trackMultiStageLineItemsEvent({
      eventType: MultiStageLineItemsEventType.SUGGESTION_PANEL_SEEN,
      entityType: isTemplate ? 'requestTemplate' : 'request',
      entityId: rfqId,
    });
  }, [rfqId, trackMultiStageLineItemsEvent, isTemplate]);

  const shouldRenderBanner = !hasMultiStageResponses && canEnableMultiStageResponses;

  useEffect(() => {
    if (shouldRenderBanner) {
      trackBannerSeenCallback();
    }
  }, [shouldRenderBanner, trackBannerSeenCallback]);

  return shouldRenderBanner ? (
    <MessageBlock variant="warn" mt="0" contentSx={{ width: '100%' }}>
      <Flex justifyContent="space-between">
        <Flex flexDirection="column" sx={{ rowGap: 1 }}>
          <Text fontSize={4} fontWeight={500}>
            {t('multiStageLineItemsBanner.heading')}
          </Text>
          <Text>{t('multiStageLineItemsBanner.description')}</Text>
        </Flex>
        <Button onClick={handleCtaClick} sx={{ alignSelf: 'center' }}>
          {t('multiStageLineItemsBanner.setupMultiStageResponses')}
        </Button>
      </Flex>
    </MessageBlock>
  ) : null;
};

/** @deprecated */
const LegacyMultiStageLineItemInfoBanner = () => {
  const { t } = useTranslation('request');

  const rfqId = useRfqId();
  const structure = rfx.useStructure<Draft>();
  const { isTemplate } = rfx.useState();
  const rfxPermissions = rfx.useRfxPermissions();

  const {
    hasMultiStageResponses,
    canEnableMultiStageResponses,
  } = rfx.checkMultiStageBannerEligibility({ structure, rfxPermissions });

  const { trackMultiStageLineItemsEvent } = useTracking();
  const handleCtaClick = useCallback(() => {
    trackMultiStageLineItemsEvent({
      eventType: LegacyMultiStageLineItemsEventType.CTA_CLICKED,
      entityType: isTemplate ? 'requestTemplate' : 'request',
      entityId: rfqId,
    });
  }, [rfqId, trackMultiStageLineItemsEvent, isTemplate]);

  const trackBannerSeenCallback = useCallback(() => {
    trackMultiStageLineItemsEvent({
      eventType: LegacyMultiStageLineItemsEventType.BANNER_SEEN,
      entityType: isTemplate ? 'requestTemplate' : 'request',
      entityId: rfqId,
    });
  }, [rfqId, trackMultiStageLineItemsEvent, isTemplate]);

  const shouldRenderBanner = !hasMultiStageResponses && canEnableMultiStageResponses;

  useEffect(() => {
    if (shouldRenderBanner) {
      trackBannerSeenCallback();
    }
  }, [shouldRenderBanner, trackBannerSeenCallback]);

  return shouldRenderBanner ? (
    <MessageBlock variant="warn" mt="0" contentSx={{ width: '100%' }}>
      <Flex justifyContent="space-between">
        <Flex flexDirection="column" sx={{ rowGap: 1 }}>
          <Text fontSize={4} fontWeight={500}>
            {t('multiStageLineItemsBanner.heading')}
          </Text>
          <Text>{t('multiStageLineItemsBanner.description')}</Text>
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="flex-end"
          flexShrink={0}
          ml={3}
        >
          <HelpCenterInlineLink
            fontSize={3}
            fontWeight={500}
            path="/en/articles/9013789-multi-stage-line-item-responses"
            onClick={handleCtaClick}
          >
            {t('multiStageLineItemsBanner.learnHow')}
          </HelpCenterInlineLink>
          <Text>{t('multiStageLineItemsBanner.opensInATab')}</Text>
        </Flex>
      </Flex>
    </MessageBlock>
  ) : (
    null
  );
};

export const MultiStageResponsesInfoBanner = () => {
  const systemFeatureFlags = useSystemFeatureFlags();

  return systemFeatureFlags?.setupMultiStageLineItemsFlowEnabled ? (
    <NewMultiStageResponsesInfoBanner />
  ) : (
    <LegacyMultiStageLineItemInfoBanner />
  );
};
