import { FieldType } from '../exchangesConfig';

export const getStandaloneAuctionLineItemFields = () => ({
  description: {
    _id: 'description',
    type: FieldType.STRING,
    required: true,
    source: {
      type: 'definition',
      key: 'description',
    },
  },
  quantity: {
    _id: 'quantity',
    type: FieldType.NUMBER,
    required: true,
    source: {
      type: 'definition',
      key: 'quantity',
    },
  },
  unit: {
    _id: 'unit',
    type: FieldType.STRING,
    required: true,
    source: {
      type: 'definition',
      key: 'unit',
    },
  },
  price: {
    _id: 'price',
    type: FieldType.PRICE,
    required: true,
    source: {
      type: 'reply',
      role: 'submitter',
      key: 'price',
    },
    decimalPlaces: 2,
  },
} as const);

export const getLinkedAuctionLineItemFields = (exchangeDefId: string) => ({
  description: {
    _id: 'description',
    type: FieldType.STRING,
    required: true,
    source: {
      type: 'linked-exchange-definition-field',
      fieldId: 'description',
      exchangeDefId,
    },
  },
  quantity: {
    _id: 'quantity',
    type: FieldType.NUMBER,
    required: true,
    source: {
      type: 'linked-exchange-definition-field',
      fieldId: 'quantity',
      exchangeDefId,
    },
  },
  unit: {
    _id: 'unit',
    type: FieldType.STRING,
    required: true,
    source: {
      type: 'linked-exchange-definition-field',
      fieldId: 'unit',
      exchangeDefId,
    },
  },
  price: {
    _id: 'price',
    type: FieldType.PRICE,
    required: true,
    source: {
      type: 'reply',
      role: 'submitter',
      key: 'price',
    },
    decimalPlaces: 2,
  },
} as const);
