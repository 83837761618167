/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/no-autofocus */
import { useCallback, useState, useMemo, useRef, useLayoutEffect } from 'react';
import { Box, BoxProps } from 'rebass/styled-components';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import {
  DataCellProps,
  focusGrid,
  getGridCellId,
} from '@deepstream/ui-kit/grid/core/utils';
import { useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { useGridIdPrefix } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { getCellValue } from '@deepstream/ui-kit/grid/EditableGrid/getCellValue';
import { Company, CompanyMinimized } from '@deepstream/common/rfq-utils';
import { GridMenuItem, GridPopup, GridMenuContent } from '@deepstream/ui-kit/grid/EditableGrid/GridMenu';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { KeyCode } from '@deepstream/ui-utils/KeyCode';
import { first, isEmpty, size } from 'lodash';
import styled from 'styled-components';
import { menuWrapperCss } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { MultiSelectDropdownPage } from '../../FilterDropdownPages/MultiSelectDropdownPage';
import { CompanyLogoAndName } from '../../../CompanyLogo';
import { filterBySearchText } from '../../../searchUtils';
import { Counter } from '../../Badge';
import { FilterDropdownNavItem } from '../../FilterDropdownPages/FilterDropdownPages';
import { GridAwardDecision } from '../../../modules/Request/AwardFlow/types';

const SupplierSelectWrapperBox = styled(Box)`
  ${menuWrapperCss}

  margin-top: 1px;
  margin-bottom: 2px;
  outline: none !important;
  padding-top: 8px;
  padding-bottom: 8px;

  width: 400px;
`;

const SupplierSelectWrapper = (props: BoxProps) => {
  const wrapperRef = useRef<HTMLDivElement>();

  useLayoutEffect(() => {
    if (wrapperRef.current) {
      const inputElement = wrapperRef.current.getElementsByTagName('input')[0];

      inputElement?.focus();
    }
  }, []);

  return (
    <SupplierSelectWrapperBox
      ref={wrapperRef}
      {...props}
    />
  );
};

const ReadOnlyCheckbox = ({ checked }: { checked?: boolean }) => {
  return (
    <Box sx={{ flex: '0 0 13px' }}>
      {checked ? (
        <Icon icon="check" color="primary" />
      ) : (
        null
      )}
    </Box>
  );
};

const SelectCompany = ({
  awardableCompanies,
  selectedItems,
  onChange,
}: {
  awardableCompanies: CompanyMinimized[],
  selectedItems: CompanyMinimized[],
  onChange: (items: any) => void,
}) => {
  const { t } = useTranslation('translation');

  return (
    <MultiSelectDropdownPage<CompanyMinimized>
      filterItems={(value) =>
        filterBySearchText(value, awardableCompanies, ['name'])
      }
      filterPlaceholder={t('request.awardFlow.steps.chooseLotLevelAwardSuppliers.searchPlaceholder')}
      items={awardableCompanies}
      onChange={onChange}
      renderItem={item => (
        // @ts-expect-error ts(2322) FIXME: Type 'CompanyMinimized | null' is not assignable to type 'CompanyMinimized | Company'.
        <CompanyLogoAndName company={item} size="xs" />
      )}
      selectedItems={selectedItems}
      idProp="_id"
      CheckboxComponent={ReadOnlyCheckbox}
    />
  );
};

export const AwardedSupplierInputCell = ({
  row,
  column,
  recipientById,
  canSelectMultipleSuppliers,
}: DataCellProps<EditableGridColumn, any, any> & { recipientById: Record<string, Company>, canSelectMultipleSuppliers?: boolean }) => {
  const { t } = useTranslation('translation');

  const [showSelectSupplierPage, setShowSelectSupplierPage] = useState(false);

  const isDisabled = isEmpty(row.original.awardableRecipientIds);

  const {
    cellInputState,
    setCellValue,
    setEditedCell,
    updateFirstMatchingRow,
  } = useEditableGridData();

  const awardDecision = getCellValue(row.original, column.original) as GridAwardDecision | null;

  const onSelect = useCallback(
    (awardDecision: GridAwardDecision | null) => {
      updateFirstMatchingRow(
        { _id: row.original._id },
        (row) => ({
          ...row,
          awardDecision,
        }),
      );

      setCellValue(row.original._id, column.original._id, awardDecision);
    },
    [setCellValue, updateFirstMatchingRow, row.original._id, column.original._id],
  );

  const idPrefix = useGridIdPrefix();
  const [menuReferenceId, setMenuReferenceId] = useState<string | null>(() => {
    const cellId = getGridCellId(idPrefix, {
      // Rows array always have a null element at the start to account for the
      // grid header, hence the +1
      rowIndex: row.index + 1,
      columnIndex: column.index,
    });
    return cellId;
  });

  const closeGridMenu = useCallback(() => {
    setMenuReferenceId(null);
    setShowSelectSupplierPage(false);
    setEditedCell(null);
    cellInputState.current = null;
    focusGrid(idPrefix);
  }, [setMenuReferenceId, setEditedCell, cellInputState, idPrefix]);

  const selectedCompanies = useMemo(() => {
    if (awardDecision?.value === 'award') {
      return Object.values(recipientById)
        .filter(recipient => awardDecision.awardedSupplierIds.includes(recipient._id))
        .map(recipient => recipient.company);
    } else {
      return [];
    }
  }, [awardDecision, recipientById]);

  const onCompanySelectionChange = useCallback((companies: CompanyMinimized[]) => {
    if (isEmpty(companies)) {
      onSelect(null);
    } else {
      onSelect({
        value: 'award',
        awardedSupplierIds: canSelectMultipleSuppliers && row.original.supportsAwardToMultipleSuppliers
          ? companies.map(item => item._id)
          : companies.slice(-1).map(item => item._id),
      });
    }

    if (!canSelectMultipleSuppliers || !row.original.supportsAwardToMultipleSuppliers) {
      closeGridMenu();
    }
  }, [onSelect, closeGridMenu, canSelectMultipleSuppliers, row]);

  const awardableCompanies = useMemo(() => {
    return Object.values(recipientById)
      .filter(recipient => row.original.awardableRecipientIds.includes(recipient._id))
      .map(recipient => recipient.company);
  }, [recipientById, row.original.awardableRecipientIds]);

  return (
    <>
      <div
        className={clsx({
          'cell-content': true,
          // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
          disabled: ['noAward', 'markAsObsolete'].includes(awardDecision?.value),
          highlighted: awardDecision?.value === 'award',
        })}
        style={isDisabled ? undefined : { cursor: 'pointer', fontWeight: 400 }}
        onClick={() => {
          if (!isDisabled) {
            const cellId = getGridCellId(idPrefix, {
              // Rows array always have a null element at the start to account for the
              // grid header, hence the +1
              rowIndex: row.index + 1,
              columnIndex: column.index,
            });

            setMenuReferenceId(menuReferenceId === cellId ? null : cellId);
          }
        }}
      >
        {!awardDecision ? (
          <Truncate color={isDisabled ? undefined : 'text'}>
            {t('request.awardFlow.steps.chooseLotLevelAwardSuppliers.selectableCellPlaceholder')}
          </Truncate>
        ) : awardDecision.value === 'noAward' ? (
          <Truncate color={isDisabled ? undefined : 'text'}>
            <Icon icon="circle-xmark" regular mr={1} />
            {t('request.awardFlow.steps.chooseLotLevelAwardSuppliers.noAward')}
          </Truncate>
        ) : awardDecision.value === 'markAsObsolete' ? (
          <Truncate color={isDisabled ? undefined : 'text'}>
            <Icon icon="ban" regular mr={1} />
            {t('request.awardFlow.steps.chooseLineLevelAwardSuppliers.markAsObsolete')}
          </Truncate>
        ) : awardDecision.awardedSupplierIds.length > 1 ? (
          <Truncate color={isDisabled ? undefined : 'text'}>
            <Icon icon="trophy" regular mr={1} />
            {t('general.supplierCount', { count: awardDecision.awardedSupplierIds.length })}
          </Truncate>
        ) : (
          <Truncate color={isDisabled ? undefined : 'text'}>
            <Icon icon="trophy" regular mr={1} />
            {/*
             // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type. */}
            {recipientById[first(awardDecision.awardedSupplierIds)].company.name}
          </Truncate>
        )}
        {awardDecision ? (
          <IconButton
            icon="xmark"
            ml={2}
            mt="2px"
            color="text"
            onClick={() => {
              onSelect(null);
              closeGridMenu();
            }}
          />
        ) : (
          <Icon icon="caret-down" ml={2} mt="2px" color="text" />
        )}
      </div>

      {menuReferenceId ? (
        <GridPopup
          key={menuReferenceId}
          cellId={menuReferenceId}
          close={closeGridMenu}
          popoverProps={{
            'data-test': 'award-lot-menu',
          }}
          sameWidth
        >
          {showSelectSupplierPage ? (
            <SupplierSelectWrapper
              onKeyDown={(event) => {
                if (event.code === KeyCode.ESCAPE) {
                  setShowSelectSupplierPage(false);
                }
                stopPropagation(event);
              }}
              onKeyUp={stopPropagation}
            >
              <FilterDropdownNavItem
                title={t('request.awardFlow.steps.chooseLotLevelAwardSuppliers.awardTo')}
                icon="trophy"
                isIconRegular
                navigateBack
                onClick={() => {
                  setShowSelectSupplierPage(false);
                }}
                additionalContent={canSelectMultipleSuppliers && !row.original.supportsAwardToMultipleSuppliers ? (
                  <Box ml="auto" fontSize={1} color="subtext">
                    {t('request.awardFlow.steps.chooseLineLevelAwardSuppliers.selectOneSupplier')}
                    <Tooltip
                      content={t('request.awardFlow.steps.chooseLineLevelAwardSuppliers.selectOneSupplierTooltip')}
                    >
                      <Icon icon="question-circle" regular color="lightGray5" ml={1} />
                    </Tooltip>
                  </Box>
                ) : (
                  null
                )}
              />
              <SelectCompany
                awardableCompanies={awardableCompanies}
                selectedItems={selectedCompanies}
                onChange={onCompanySelectionChange}
              />
            </SupplierSelectWrapper>
          ) : (
            <GridMenuContent close={closeGridMenu}>
              {awardableCompanies.length === 1 ? (
                // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
                <GridMenuItem onSelect={() => onSelect({ value: 'award', awardedSupplierIds: [first(awardableCompanies)._id] })}>
                  <Icon fixedWidth icon="trophy" regular mr={2} />
                  {/*
                   // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'. */}
                  {t('request.awardFlow.steps.chooseLineLevelAwardSuppliers.awardToSupplier', { supplierName: first(awardableCompanies).name })}
                </GridMenuItem>
              ) : (
                <GridMenuItem
                  onSelect={() => setShowSelectSupplierPage(true)}
                  preventCloseOnSelect
                  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Box as="span">
                    <Icon fixedWidth icon="trophy" regular mr={2} />
                    {t('request.awardFlow.steps.chooseLotLevelAwardSuppliers.awardTo')}
                  </Box>
                  <Box as="span">
                    <Counter
                      color="primary"
                      count={awardDecision?.value === 'award' ? size(awardDecision.awardedSupplierIds) : 0}
                      sx={{ position: 'relative', top: '-2px' }}
                    />
                    <Icon icon="chevron-right" ml={2} />
                  </Box>
                </GridMenuItem>
              )}
              <GridMenuItem onSelect={() => onSelect({ value: 'noAward' })}>
                <Icon fixedWidth icon="circle-xmark" regular mr={2} />
                {t('request.awardFlow.steps.chooseLotLevelAwardSuppliers.noAward')}
              </GridMenuItem>
              {row.original.canObsolete && (
                <GridMenuItem onSelect={() => onSelect({ value: 'markAsObsolete' })} hasSeparatorAbove>
                  <Icon fixedWidth icon="ban" regular mr={2} />
                  {t('request.awardFlow.steps.chooseLineLevelAwardSuppliers.markAsObsolete')}
                </GridMenuItem>
              )}
            </GridMenuContent>
          )}
        </GridPopup>
      ) : null}
    </>
  );
};
