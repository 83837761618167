import { useEffect } from 'react';
import { Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { useIntercom } from 'react-use-intercom';
import { useTracking } from '../../useTracking';

export const UpgradeToUnlockContractsDialog = ({
    isOpen,
    onCancel,
    unlockTemplates = false,
  }: {
    isOpen: boolean;
    onCancel: () => void;
    unlockTemplates?: boolean;
  }) => {
  const intercom = useIntercom();
  const { t } = useTranslation('contracts');
  const { trackAccessRestricted, trackInformationRequested } = useTracking();

  const trackedFeature = unlockTemplates ? 'createContractTemplate' : 'createContract';

  useEffect(() => {
    if (isOpen) {
      trackAccessRestricted(trackedFeature);
    }
  }, [trackedFeature, isOpen, trackAccessRestricted]);

  return (
    <Dialog
      style={{ content: { width: '500px' } }}
      heading={t('dialog.unlockContracts.heading')}
      body={(
        <>
          <Text mb={2} lineHeight={1.5}>{t('dialog.unlockContracts.body1')}</Text>
          <Text mb={2} lineHeight={1.5}>{t('dialog.unlockContracts.body2')}</Text>
        </>
      )}
      okButtonText={t('dialog.unlockContracts.okButtonText')}
      cancelButtonText={t('dialog.unlockContracts.cancelButtonText')}
      isOpen={isOpen}
      onOk={() => {
        trackInformationRequested(trackedFeature);
        intercom.show();
        intercom.trackEvent(unlockTemplates ? 'contractTemplates:modal:upgrade' : 'contracts:modal:upgrade');
        onCancel();
      }}
      onCancel={onCancel}
      showCloseIcon
    />
  );
};
