import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { SpendSectionStatus } from '@deepstream/common/rfq-utils/spendAndSavings';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { PanelHeader } from '@deepstream/ui-kit/elements/Panel';
import * as rfx from '../../../rfx';
import { LabeledValue } from '../../../draft/LabeledValue';
import { spendSectionStatusIconProps } from './spendSectionStatus';

export const SpendPanelHeader = ({
  heading,
  panelId,
  status,
  isPanelDisabled,
  isEditButtonDisabled,
}: {
  heading: React.ReactNode;
  panelId: string;
  status?: SpendSectionStatus;
  isPanelDisabled?: boolean;
  isEditButtonDisabled?: boolean;
}) => {
  const { t } = useTranslation();
  const { startEditing } = rfx.useActions();
  const { editingPanelId } = rfx.useState();

  const isEditingOtherPanel = editingPanelId && editingPanelId !== panelId;
  const isEditingThisPanel = editingPanelId && editingPanelId === panelId;

  return (
    <PanelHeader heading={heading}>
      {!isEditingThisPanel && !isPanelDisabled && (
        <Flex alignItems="center">
          {status && (
            <LabeledValue
              label={t('general.status')}
              value={(
                <IconText
                  {...spendSectionStatusIconProps[status]}
                  text={t(`request.spendAndSavings.sectionStatus.${status}`)}
                />
              )}
              align="left"
              mr={4}
            />
          )}
          <EditButton
            small
            onClick={() => startEditing(panelId)}
            // @ts-expect-error ts(2322) FIXME: Type 'boolean | "" | null' is not assignable to type 'boolean | undefined'.
            disabled={isEditButtonDisabled || isEditingOtherPanel}
          />
        </Flex>
      )}
    </PanelHeader>
  );
};
