import { useState, useCallback, useMemo } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { flatMap, isEmpty, map } from 'lodash';
import { universalSupplierBidStatuses, supplierBidStatuses } from '@deepstream/common/rfq-utils/statusConfigs';
import { useQuery } from 'react-query';
import { UniversalBidStatus } from '@deepstream/common/rfq-utils';
import { SortingAccessor } from '@deepstream/common';
import { LabeledSortCriterion } from '@deepstream/ui-utils';
import { Illustration } from '@deepstream/ui-kit';
import { IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { FlexPanelHeader, Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Loading } from './ui/Loading';
import { ErrorMessage } from './ui/ErrorMessage';
import { MIN_CELL_HEIGHT } from './FieldsCell';
import { useModalState } from './ui/useModalState';
import { CannotAccessRequestDialog } from './CannotAccessRequestDialog';
import { ReceivedRequestsTable } from './ReceivedRequestsTable';
import {
  BidStatusFilterItem,
  ReceivedRequestOverviews,
  ExtendedReceivedRequestOverview,
  RequestTagFilterItem,
} from './types';
import { RequestCount } from './RequestCount';
import { useTableData } from './TableDataContext';
import { SelectDropdownMenu } from './ui/MultiSelect';
import { renderName, renderStatusIconText } from './RequestsTable';
import { useLocalStorageSortProps2, useSortDirectionItems } from './sorting';
import { ClearFiltersButton, DashboardRoleFilter, useDashboardRoleFilterItems, useLocalStorageFilterProps } from './filtering';
import { RequestsTableData, RequestsTableDataProvider } from './RequestsTableDataProvider';
import { useCurrentCompanyId } from './currentCompanyId';
import { useApi, wrap } from './api';
import { useCurrentUser } from './useCurrentUser';
import { useLocalStorageState } from './useLocalStorageState';
import { useSystemFeatureFlags } from './systemFeatureFlags';
import { useRequestTagsContext } from './modules/RequestTags/RequestTagsContext';
import { EditRequestsTagsDropdown } from './modules/RequestTags/EditRequestTagsDropdown';
import { renderRequestTagLabel } from './modules/RequestTags/RequestTagLabel';
import { SearchRequests } from './SearchRequests';
import { useRequestsNavigation } from './RequestsNavigationContext';
import { SortDropdown } from './ui/SortDropdown';
import { HorizontalDivider } from './ui/HorizontalDivider';

const ReceivedRequestsContent = ({ selectedRequestTagId }: { selectedRequestTagId?: string }) => {
  const { t } = useTranslation();
  const api = useApi();
  const cannotAccessRequestDialog = useModalState();
  const [selectedRequest, setSelectedRequest] = useState<ExtendedReceivedRequestOverview | null>(null);
  const { navigateToReceivedRequest } = useRequestsNavigation();
  const {
    statusFilter,
    roleFilter,
    entityFilter,
    requestTagsFilter,
    sort,
    query,
    pageSize,
    selectedItemsIds,
    selectAllItemsOnPage,
    unselectAllItems,
  } = useTableData<RequestsTableData<ReceivedRequestOverviews>>({ required: true });

  const { status, data, refetch } = query;
  const { rfqs: requests, totalItems = 0 } = data ?? {};

  const openCannotAccessRequestDialog = useCallback(
    (request) => {
      setSelectedRequest(request);
      cannotAccessRequestDialog.open();
    },
    [cannotAccessRequestDialog],
  );

  const handleTagsChange = () => {
    // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
    unselectAllItems();
    refetch();
  };

  const hasFilters = (
    // @ts-expect-error ts(18048) FIXME: 'statusFilter' is possibly 'undefined'.
    !isEmpty(statusFilter.selectedItems) ||
    !isEmpty(roleFilter.selectedItems) ||
    !isEmpty(entityFilter.selectedItems) ||
    !isEmpty(requestTagsFilter.selectedItems)
  );

  // @ts-expect-error ts(18048) FIXME: 'selectedItemsIds' is possibly 'undefined'.
  const showEditTagsButton = selectedItemsIds.length > 0;
  const disableTableControls = showEditTagsButton || status !== 'success';

  return (
    <>
      <Panel>
        <FlexPanelHeader
          heading={
            // Keep the header in the same position while loading the results with search/sort/filter
            // @ts-expect-error ts(18048) FIXME: 'selectedItemsIds' is possibly 'undefined'.
            <Box {...(!selectedItemsIds.length && { width: '130px' })}>
              {requests ? (
                <Flex alignItems="center">
                  {requests.length > 0 && (
                    <Checkbox
                      style={{ padding: '0 12px 0 0' }}
                      // @ts-expect-error ts(18048) FIXME: 'selectedItemsIds' is possibly 'undefined'.
                      checked={selectedItemsIds.length > 0 && selectedItemsIds.length === requests.length}
                      // @ts-expect-error ts(18048) FIXME: 'selectedItemsIds' is possibly 'undefined'.
                      indeterminate={selectedItemsIds.length > 0 && selectedItemsIds.length !== requests.length}
                      onChange={(event) => {
                        if ((event.target as HTMLInputElement).checked) {
                          // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
                          selectAllItemsOnPage();
                        } else {
                          // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
                          unselectAllItems();
                        }
                      }}
                    />
                  )}
                  {/*
                   // @ts-expect-error ts(18048) FIXME: 'selectedItemsIds' is possibly 'undefined'. */}
                  <RequestCount selectedCount={selectedItemsIds.length} count={totalItems} />
                </Flex>
              ) : null}
            </Box>
          }
        >
          {showEditTagsButton && (
            // @ts-expect-error ts(2322) FIXME: Type 'string[] | undefined' is not assignable to type 'string[]'.
            <EditRequestsTagsDropdown requestIds={selectedItemsIds} onSave={handleTagsChange} />
          )}
        </FlexPanelHeader>
        <PanelDivider />
        <Flex
          px="20px"
          py="12px"
          minHeight="54px"
          width="100%"
          flexWrap="wrap"
          justifyContent="space-between"
          sx={{ gap: 1 }}
          data-test="requests-table-actions"
        >
          <SearchRequests<ExtendedReceivedRequestOverview>
            queryFn={api.getReceivedRequests}
            getResultText={(request: ExtendedReceivedRequestOverview) => request.receivedDashboard.subject}
            onResultClick={(request: ExtendedReceivedRequestOverview) => navigateToReceivedRequest(request._id)}
            disabled={disableTableControls}
          />
          <Flex
            flexWrap="wrap"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ columnGap: 1 }}
          >
            {status === 'success' && hasFilters && (
              <ClearFiltersButton
                onClick={() => {
                  // @ts-expect-error ts(18048) FIXME: 'statusFilter' is possibly 'undefined'.
                  statusFilter.onChange([]);
                  roleFilter.onChange([]);
                  entityFilter.onChange([]);
                  requestTagsFilter.onChange([]);
                }}
                disabled={disableTableControls}
              />
            )}
            <SelectDropdownMenu
              multi
              buttonText={t('general.tag')}
              buttonIcon="filter"
              menuWidth={300}
              truncate={false}
              menuZIndex={10}
              disabled={disableTableControls}
              // @ts-expect-error ts(2322) FIXME: Type 'null' is not assignable to type 'number | undefined'.
              itemHeight={null}
              renderPreItemContent={(tag, index) => !tag.parentTagId && index > 0 ? <HorizontalDivider my={2} /> : null}
              {...requestTagsFilter}
            />
            {/*
             // @ts-expect-error ts(2322) FIXME: Type '{ itemToString?: ((item: Record<string, unknown> | null) => any) | undefined; renderItem?: ((item: Record<string, unknown> | null) => string | Element) | undefined; ... 11 more ...; truncate: false; }' is not assignable to type '{ buttonText?: ReactNode; buttonIcon?: "function" | "search" | "link" | "question" | "file" | "columns" | "users" | "sort" | "filter" | "code" | "table" | "circle" | "text" | ... 190 more ... | undefined; ... 4 more ...; highlightIncompleteSelection?: boolean | undefined; }'. */}
            <SelectDropdownMenu
              multi
              buttonText={t('general.bidStatus')}
              buttonIcon="filter"
              menuWidth={180}
              menuZIndex={10}
              disabled={disableTableControls}
              truncate={false}
              {...statusFilter}
            />
            <SelectDropdownMenu
              multi
              buttonText={t('requests.from')}
              buttonIcon="filter"
              menuWidth={180}
              menuZIndex={10}
              disabled={disableTableControls}
              {...entityFilter}
            />
            <SelectDropdownMenu
              multi
              buttonText={t('yourRole', { ns: 'company' })}
              buttonIcon="filter"
              menuWidth={180}
              menuZIndex={10}
              disabled={disableTableControls}
              {...roleFilter}
            />
            <SortDropdown
              disabled={
                disableTableControls || (requests && totalItems === 0)
              }
              {...sort}
            />
          </Flex>
        </Flex>
        <PanelDivider />
        {status === 'success' && totalItems > 0 ? (
          <ReceivedRequestsTable
            openCannotAccessRequestDialog={openCannotAccessRequestDialog}
          />
        ) : (
          <Flex
            height={MIN_CELL_HEIGHT * (status === 'loading' ? pageSize : 5)}
            flexDirection="column"
            justifyContent="center"
          >
            <PanelPadding>
              {status === 'loading' ? (
                <Loading fontSize={4} fontWeight={400} />
              ) : status === 'error' ? (
                <ErrorMessage fontSize={3} error={t('requests.errors.getRequests')} />
              ) : hasFilters ? (
                <Illustration
                  variant="search-results"
                  label={
                    <Flex flexDirection="column" sx={{ gap: 2 }}>
                      <Text>
                        {t('requests.noRequestsWithSearchOrFilter')}
                      </Text>
                      <ClearFiltersButton
                        onClick={() => {
                          // @ts-expect-error ts(18048) FIXME: 'statusFilter' is possibly 'undefined'.
                          statusFilter.onChange([]);
                          entityFilter.onChange([]);
                          requestTagsFilter.onChange([]);
                        }}
                        alignSelf="center"
                      />
                    </Flex>
                  }
                  labelSx={{
                    fontWeight: 500,
                  }}
                />
              ) : (
                <Illustration
                  variant="empty-state"
                  label={selectedRequestTagId
                    ? t('requests.tags.noRequests')
                    : t('requests.companyHasNotReceivedRequests')}
                  labelSx={{
                    fontWeight: 500,
                  }}
                />
              )}
            </PanelPadding>
          </Flex>
        )}
      </Panel>
      <CannotAccessRequestDialog
        isOpen={cannotAccessRequestDialog.isOpen}
        onCancel={cannotAccessRequestDialog.close}
        request={selectedRequest}
      />
    </>
  );
};

type BidStatusFilter = {
  status: string;
  statuses: string[];
  label: string;
  icon: {
    value: IconProps['icon'];
    color: string;
  };
};

type UniversalBidStatusFilter = {
  status: string;
  label: string;
  icon: {
    value: IconProps['icon'];
    color: string;
  };
};

type FromFilter = {
  name: string;
  _id: string;
};

const useBidStatusFilterItems = (
  bidStatusFilterItems: BidStatusFilterItem[] | undefined,
) => {
  const { t } = useTranslation();

  return useMemo(() => {
    if (!bidStatusFilterItems) {
      return;
    }

    return bidStatusFilterItems.map(statuses => ({
      // the 'intendToBid' and 'inProgress' bid statuses are represented as a
      // single item; we use the configuration for the first of them and
      // additionally pass both statuses in the `statuses` prop which will be
      // used when constructing the filter query parameters
      ...supplierBidStatuses[statuses[0]],
      statuses,
      label: t(`request.supplierBidStatus.${statuses[0]}`),
    })) as BidStatusFilter[];
  }, [t, bidStatusFilterItems]);
};

const useUniversalBidStatusFilterItems = (
  universalBidStatusFilterItems: UniversalBidStatus[] | undefined,
) => {
  const { t } = useTranslation();

  return useMemo(() => {
    if (!universalBidStatusFilterItems) {
      return;
    }

    return universalBidStatusFilterItems.map(status => ({
      ...universalSupplierBidStatuses[status],
      label: t(`request.universalSupplierBidStatus.${status}`),
    })) as UniversalBidStatusFilter[];
  }, [t, universalBidStatusFilterItems]);
};

const useSortCriteriaItems = (): LabeledSortCriterion[] => {
  const { t } = useTranslation('translation');

  return useMemo(() => [
    { label: t('requests.sorting.creationDate'), accessor: SortingAccessor.creationDate },
    { label: t('requests.sorting.issueDate'), accessor: SortingAccessor.issueDate },
    { label: t('requests.sorting.deadline'), accessor: SortingAccessor.bidDeadline },
    { label: t('requests.sorting.automatedReference'), accessor: SortingAccessor.automatedReference },
    { label: t('requests.sorting.requestName'), accessor: SortingAccessor.requestName },
  ], [t]);
};

export const ReceivedRequests = ({ selectedRequestTagId }: { selectedRequestTagId?: string }) => {
  const { tags, tagById } = useRequestTagsContext();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();
  const api = useApi();
  const systemFeatureFlags = useSystemFeatureFlags({ required: true });

  const filterItems = useQuery(
    ['receivedRequestFilterItems', { currentCompanyId }],
    wrap(api.getReceivedRequestFilterItems),
  );

  const bidStatusFilterItems = useBidStatusFilterItems(
    filterItems.data?.bidStatuses,
  );

  const universalBidStatusFilterItems = useUniversalBidStatusFilterItems(
    filterItems.data?.universalBidStatuses,
  );

  const dashboardRoleFilterItems = useDashboardRoleFilterItems(
    filterItems.data?.roles,
  );

  const bidStatusFilterConfig = useMemo(() => {
    return systemFeatureFlags.universalBidStatusEnabled
      ? {
        storageKey: `${currentCompanyId}.${user._id}.receivedRequests.universalBidStatusFilter`,
        items: universalBidStatusFilterItems,
        idProp: 'status',
        renderItem: renderStatusIconText,
        getQueryParam: (selectedItems: UniversalBidStatusFilter[]) => ({
          // sort so the same set of IDs always results in the
          // same query key irrespective of the order of selection
          universalBidStatuses: selectedItems.map(item => item.status).sort(),
        }),
      }
      : {
        storageKey: `${currentCompanyId}.${user._id}.receivedRequests.bidStatusFilter`,
        items: bidStatusFilterItems,
        idProp: 'status',
        renderItem: renderStatusIconText,
        getQueryParam: (selectedItems: BidStatusFilter[]) => ({
          // sort so the same set of IDs always results in the
          // same query key irrespective of the order of selection
          bidStatuses: flatMap(selectedItems, 'statuses').sort(),
        }),
      };
  }, [bidStatusFilterItems, currentCompanyId, systemFeatureFlags.universalBidStatusEnabled, universalBidStatusFilterItems, user._id]);

  const bidStatusFilterProps = useLocalStorageFilterProps(bidStatusFilterConfig as any);

  const roleFilterProps = useLocalStorageFilterProps({
    storageKey: `${currentCompanyId}.${user._id}.receivedRequests.roleFilter`,
    items: dashboardRoleFilterItems,
    idProp: '_id',
    renderItem: renderName,
    getQueryParam: (selectedItems: DashboardRoleFilter[]) => ({
      roles: map(selectedItems, '_id').sort(),
    }),
  });

  const requestTagsFilterItems = useMemo(
    () => tags.filter(({ _id }) => _id !== selectedRequestTagId),
    [tags, selectedRequestTagId],
  ) as RequestTagFilterItem[];

  const senderFilterProps = useLocalStorageFilterProps({
    storageKey: `${currentCompanyId}.${user._id}.receivedRequests.senderFilter`,
    items: filterItems.data?.senders,
    idProp: '_id',
    renderItem: renderName,
    getQueryParam: (selectedItems: FromFilter[]) => ({
      // sort so the same set of IDs always results in the
      // same query key irrespective of the order of selection
      senderIds: map(selectedItems, '_id').sort(),
    }),
  });

  const requestTagsFilterProps = useLocalStorageFilterProps({
    storageKey: `${currentCompanyId}.${user._id}.sentRequests.requestTagsFilter`,
    items: requestTagsFilterItems,
    idProp: '_id',
    // @ts-expect-error ts(2345) FIXME: Argument of type 'RequestTagFilterItem | null' is not assignable to parameter of type 'RequestTag'.
    renderItem: (requestTag) => renderRequestTagLabel(requestTag, tagById),
    getQueryParam: (selectedItems: RequestTagFilterItem[]) => ({
      // sort so the same set of IDs always results in the
      // same query key irrespective of the order of selection
      requestTags: map(selectedItems, '_id'),
    }),
  });

  const criteriaItems = useSortCriteriaItems();
  const directionItems = useSortDirectionItems();
  const sortProps = useLocalStorageSortProps2({
    storageKey: `${currentCompanyId}.${user._id}.receivedRequests.sort`,
    criteriaItems,
    directionItems,
  });

  const pageSizeState = useLocalStorageState<number>({
    key: `${currentCompanyId}.${user._id}.receivedRequests.pageSize`,
    defaultValue: 10,
  });

  const searchTextState = useLocalStorageState<string>({
    key: `${currentCompanyId}.${user._id}.receivedRequests.searchText`,
    defaultValue: '',
  });

  return (
    <RequestsTableDataProvider
      queryTag="receivedRequests"
      queryFn={api.getReceivedRequests}
      statusFilter={bidStatusFilterProps}
      roleFilter={roleFilterProps}
      entityFilter={senderFilterProps}
      requestTagsFilter={requestTagsFilterProps}
      preselectedTag={selectedRequestTagId}
      sort={sortProps}
      pageSizeState={pageSizeState}
      searchTextState={searchTextState}
    >
      <ReceivedRequestsContent selectedRequestTagId={selectedRequestTagId} />
    </RequestsTableDataProvider>
  );
};
