import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';
import { compact, filter } from 'lodash';
import { InclusionOption, isInclusionExchangeDef } from '@deepstream/common/rfq-utils';
import { keyByInclusionOptionType } from '@deepstream/common/rfq-utils/vesselPricing';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { withProps } from '@deepstream/ui-utils/withProps';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { FormTableStyles, StaticTableStyles } from '../TableStyles';
import { Table } from '../Table';
import { Required } from '../Required';
import { ExchangeDefActionsCell, ExchangeDefDescriptionCell, ExchangeDefDescriptionFieldCell, SelectFieldCell, ValidationErrorCell } from './cell';
import { createInclusion, useExchangeDefsField } from './exchangeDefs';
import * as rfx from '../rfx';
import { nestCells } from '../nestCells';
import { TextCell } from '../TextCell';
import { ExchangeDefRowCells } from './ExchangeDefRowCells';

const AddInclusionButton = (props: ButtonProps) => {
  const { t } = useTranslation();
  const isSender = rfx.useIsSender();

  return (
    <Button
      small
      variant={isSender ? 'secondary' : 'primary-outline'}
      iconLeft="plus"
      {...props}
    >
      {t('request.vesselPricing.inclusions.addInclusion')}
    </Button>
  );
};

const FieldColumnHeader = ({ column }) => (
  <>
    {column.label}
    {column.required && <Required />}
  </>
);

const InclusionValidationErrorCell = withProps(ValidationErrorCell, { fieldName: 'inclusionExchangeDefs' });
const InclusionExchangeDefRowCells = withProps(ExchangeDefRowCells, { fieldName: 'inclusionExchangeDefs' });

export const InclusionTable = () => {
  const { t } = useTranslation();
  const { isTemplate } = rfx.useState();
  const senderSectionExchangeDefs = rfx.useSectionExchangeDefsByCreator({
    filter: isInclusionExchangeDef,
    group: 'sender',
  });
  const sectionExchangeDefs = filter(rfx.useSectionExchangeDefs(), isInclusionExchangeDef);
  // template exchangeDefs don't have a creatorId so we can't use
  // `useSectionExchangeDefsByCreator`
  const inclusionExchangeDefs = isTemplate ? sectionExchangeDefs : senderSectionExchangeDefs;

  const columns = useMemo(
    () => [
      {
        id: 'description',
        Header: t('general.description'),
        accessor: 'description',
        Cell: nestCells(InclusionValidationErrorCell, ExchangeDefDescriptionCell),
      },
      {
        id: 'option',
        Header: t('request.vesselPricing.inclusions.includedInRates'),
        accessor: (exchange) => t(keyByInclusionOptionType[exchange.option]),
        Cell: nestCells(InclusionValidationErrorCell, TextCell),
        defaultValue: <EmDash />,
        width: 240,
      },
    ],
    [t],
  );

  return (
    <>
      {inclusionExchangeDefs.length === 0 ? (
        <PanelPadding>
          <Text color="subtext" fontSize={2}>
            {t('request.vesselPricing.inclusions.noInclusionsAdded')}
          </Text>
        </PanelPadding>
      ) : (
        <StaticTableStyles>
          <Table
            columns={columns}
            data={inclusionExchangeDefs}
            CustomRowCells={InclusionExchangeDefRowCells}
          />
        </StaticTableStyles>
      )}
    </>
  );
};

export const InclusionTableField = ({
  fieldName = 'exchangeDefs',
  isSectionObsolete,
}: {
  fieldName?: string;
  isSectionObsolete?: boolean
}) => {
  const { t } = useTranslation();
  const isSender = rfx.useIsSender();

  const { exchangeDefs, addExchangeDef } = useExchangeDefsField(
    fieldName,
    createInclusion,
  );

  const columns = useMemo(
    () => compact([
      {
        id: 'description',
        Header: FieldColumnHeader,
        label: t('general.description'),
        accessor: 'description',
        Cell: withProps(ExchangeDefDescriptionFieldCell, { fieldName }),
        hideNumbering: true,
        required: true,
      },
      {
        id: 'option',
        Header: FieldColumnHeader,
        label: t('request.vesselPricing.inclusions.includedInRates'),
        accessor: 'option',
        Cell: withProps(SelectFieldCell, { fieldName }),
        required: true,
        width: 180,
        options: compact([
          InclusionOption.INCLUDED,
          InclusionOption.EXCLUDED,
          isSender ? InclusionOption.FLEXIBLE : undefined,
        ]).map(option => ({
          value: option,
          label: t(keyByInclusionOptionType[option]),
        })),
      },
      !isSectionObsolete && {
        id: 'menu',
        Header: () => '',
        accessor: null,
        width: 48,
        Cell: withProps(ExchangeDefActionsCell, { fieldName }),
      },
    ]),
    [t, fieldName, isSender, isSectionObsolete],
  );

  return (
    <>
      {exchangeDefs.length > 0 ? (
        <FormTableStyles>
          <Table columns={columns} data={exchangeDefs} />
        </FormTableStyles>
      ) : (
        <Text color="subtext" fontSize={2} mb={2}>
          {t('request.vesselPricing.inclusions.noInclusionsAdded')}
        </Text>
      )}
      {!isSectionObsolete && (
        <AddInclusionButton
          type="button"
          onClick={() => addExchangeDef()}
          mt={2}
        />
      )}
    </>
  );
};
