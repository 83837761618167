import { size } from 'lodash';
import { AwardFlowData, MessageConfig } from './types';

export const getExchangeIdsAwardedToMultipleSuppliers = (data: AwardFlowData) => {
  return Object.entries(data.awardDecisionByExchangeId || {})
    .filter(([, awardDecision]) => awardDecision?.value === 'award' && size(awardDecision.awards) > 1)
    .map(([exchangeId]) => exchangeId);
};

export const getDefaultQuantitySplitMode = () => 'amount' as const;

export const getEmptyMessageConfig = (): MessageConfig => ({
  messageMethod: null,
  generalMessage: null,
  generalMessageSupplierIds: [],
  excludedSupplierIds: [],
  messageBySupplierId: {},
});
