import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ContractTemplate } from '@deepstream/common/contract/contractTemplate';
import { withProps } from '@deepstream/ui-utils/withProps';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useCurrentUser } from '../../useCurrentUser';
import { DateFieldValue, FieldsCell, MIN_CELL_HEIGHT } from '../../FieldsCell';
import { useLocalStorageState } from '../../useLocalStorageState';
import { BasicTableStyles } from '../../TableStyles';
import { Table } from '../../Table';
import { ContractTemplateActionButtons } from './ContractTemplateActionButtons';
import { contractTemplatePreviewRoute } from '../../AppRouting';
import { useNavigate } from '../../tanstackRouter';

export const ContractTemplatesTable = ({
  templates,
  openDeleteModal,
}: {
  templates: ContractTemplate[];
  openDeleteModal: (template: ContractTemplate) => void;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();
  const navigate = useNavigate();

  const columns = useMemo(
    () => [{ id: 'custom' }],
    [],
  );

  const CustomRowCells = useMemo(
    () => {
      const fields = [
        {
          label: t('requests.creator'),
          accessor: 'meta.createdBy.name',
        },
        {
          label: t('requests.created'),
          accessor: 'meta.createdAt',
          FieldValueComponent: DateFieldValue,
        },
        {
          label: t('requests.updated'),
          accessor: 'meta.lastEditedAt',
          FieldValueComponent: DateFieldValue,
        },
      ];

      return withProps(
        FieldsCell,
        {
          fields,
          titleAccessor: 'meta.name',
          ActionsComponent: withProps(
            ContractTemplateActionButtons,
            { openDeleteModal },
          ),
        },
      );
    },
    [openDeleteModal, t],
  );

  const [initialPageSize, setInitialPageSize] = useLocalStorageState<number>({
    key: `${currentCompanyId}.${user._id}.contractTemplates.pageSize`,
    defaultValue: 10,
  });

  const onRowClick = useCallback((row) => {
    navigate({
      to: contractTemplatePreviewRoute.to,
      params: { currentCompanyId, contractId: row._id },
    });
  }, [currentCompanyId, navigate]);

  return (
    <BasicTableStyles hoverBackgroundColor="lightGray6" hoverCursor="default">
      <Table
        columns={columns}
        data={templates}
        isPaginated
        pageSizes={[5, 10, 20, 50, 100]}
        initialPageSize={initialPageSize}
        onPageSizeChange={setInitialPageSize}
        onRowClick={onRowClick}
        hideHeader
        CustomRowCells={CustomRowCells}
        hasStaticHeight={false}
        enforcePagination
        minCellHeight={MIN_CELL_HEIGHT}
        smallPageControls
      />
    </BasicTableStyles>
  );
};
