import { Trans, useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { InlineButton } from '@deepstream/ui-kit/elements/button/InlineButton';
import { Bold } from './Bold';
import * as rfx from './rfx';
import {
  useRequestEditNavigation,
  useRequestRecipientNavigation,
  useRequestSentNavigation,
} from './appNavigation';
import { useCurrentCompanyId } from './currentCompanyId';

export const HiddenRequestPagePlaceholder = ({
  accessRequiresOwnerRole,
}: {
  accessRequiresOwnerRole?: boolean;
}) => {
  const { t } = useTranslation();
  const isSender = rfx.useIsSender();
  const { isDraft, isRevising } = rfx.useState();
  const currentCompanyId = useCurrentCompanyId();

  const editNavigation = useRequestEditNavigation();
  const sentNavigation = useRequestSentNavigation();
  const recipientNavigation = useRequestRecipientNavigation();

  const navigateToRequestTeam = () => {
    if (isSender) {
      if (isDraft || isRevising) {
        // @ts-expect-error ts(2345) FIXME: Argument of type 'string | null' is not assignable to parameter of type 'string | undefined'.
        editNavigation.navigateToTeam(currentCompanyId);
      } else {
        // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string | undefined'.
        sentNavigation.navigateToTeam({ companyId: currentCompanyId });
      }
    } else {
      recipientNavigation.navigateToTeam();
    }
  };

  return (
    <Box>
      <Bold as="div" fontSize={5} mt={1} mb={3}>
        <Icon icon="eye-slash" mr={2} />
        {t('request.pages.pageNotVisible')}
      </Bold>
      {isSender && accessRequiresOwnerRole ? (
        <Text mb={1}>
          {t('request.pages.accessRequiresOwnerRole')}
        </Text>
      ) : (
        <Text mb={1}>
          {t('request.pages.pageNotVisible1')}
        </Text>
      )}
      <Text>
        {isSender ? (
          <Trans i18nKey="request.pages.permissionsCanBeUpdatedByRequestOwner">
            Permissions can be updated by a request owner on
            the <InlineButton onClick={() => navigateToRequestTeam()}>Team</InlineButton> tab.
          </Trans>
        ) : (
          <Trans i18nKey="request.pages.permissionsCanBeUpdatedByBidOwner">
            Permissions can be updated by a bid owner on
            the <InlineButton onClick={() => navigateToRequestTeam()}>Team</InlineButton> tab.
          </Trans>
        )}
      </Text>
    </Box>
  );
};
