import { useState, useEffect, useRef, createRef, useCallback, Fragment } from 'react';
import {
  ComboboxItem,
  ComboboxList,
  ComboboxPopover,
  useComboboxState,
} from 'ariakit/combobox';
import { SelectItem } from 'ariakit/select';
import ASCIIFolder from 'fold-to-ascii';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Clamp } from '@deepstream/ui-kit/elements/text/Clamp';
import { useIsomorphicLayoutEffect } from '@deepstream/ui-kit/hooks/useIsomorphicLayoutEffect';
import { splitBySearchTerm } from '../searchUtils';
import { SearchInputCombobox, SearchInputComboboxProps, SEARCH_INPUT_HEIGHT } from './SearchInputCombobox';

const DEFAULT_MAX_RESULTS_HEIGHT = 150;
const COMBOBOX_LIST_GAP = 8;
const MAX_VISIBLE_RESULTS = 5;

const SearchPopover = styled(ComboboxPopover as any)`
  background-color: ${(props) => props.theme.colors.white};
  border: ${(props) => props.theme.borders.lightGray};
  border-radius: 3px;
  box-sizing: border-box;
  position: fixed;
  font-weight: normal;
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  width: 100%;

  overflow: hidden;

  z-index: 202;
  color: ${(props) => props.theme.colors.text};

  &:focus {
    outline: 0;
  }
`;

export const SearchResult = styled(ComboboxItem)`
  padding: ${(props) => props.theme.space[1]}px;

  font-size: ${(props) => props.theme.fontSizes[2]}px;
  font-weight: 400;
  line-height: 123%;

  outline: none;
  display: flex;
  cursor: pointer;

  scroll-margin-top: 0.5px;

  align-items: center;

  &[data-active-item] {
    background-color: ${(props) => props.theme.colors.blueLight};
  }
`;

export const Strong = styled.strong`
  color: ${props => props.theme.colors.text};
`;

export type SearchComboboxProps<T> = {
  results?: T[];
  noResultsMessage: string;
  initialValue?: string;
  onChange?: (value: string) => void;
  getResultId: (item: T) => string;
  getResultText: (item: T) => string;
  onResultClick?: (item: T) => void;
  isLoading: boolean;
  disabled?: boolean;
} & Omit<
  SearchInputComboboxProps,
  'isLoading' | 'comboboxState' | 'onKeyDown' | 'onBlur'
>;

const EMPTY_ARRAY = [];
const EMPTY_STRING = '';

export const SearchCombobox = <T,>({
  results = EMPTY_ARRAY,
  textPlaceholder,
  noResultsMessage,
  initialValue = EMPTY_STRING,
  onChange,
  onSubmit,
  getResultId,
  getResultText,
  onResultClick,
  isLoading,
  disabled,
}: SearchComboboxProps<T>) => {
  const { t } = useTranslation('general');

  const [ids, setIds] = useState<string[]>([]);

  const comboboxState = useComboboxState({
    list: ids,
    gutter: 0,
    sameWidth: true,
    // Prevent the search results to be displayed above the search box
    flip: false,
  });
  const { value, setValue, contentElement, open, hide } = comboboxState;

  useEffect(() => {
    if (open && results) {
      setIds(results.map(getResultId));
    }
  }, [results, getResultId, setIds, open]);

  const searchResultsRefs = useRef<React.RefObject<HTMLDivElement>[]>();
  useEffect(() => {
    searchResultsRefs.current = results.map(() => createRef<HTMLDivElement>());
  }, [results]);
  const searchTextBoxRef = useRef<HTMLInputElement>(null);

  const [maxResultsHeight, setMaxResultsHeight] = useState<number>(
    DEFAULT_MAX_RESULTS_HEIGHT,
  );
  useIsomorphicLayoutEffect(() => {
    // Since a search result item height is variable, we need to dynamically
    // calculate the height of the search results list, which is limited to
    // MAX_VISIBLE_RESULTS items
    if (searchResultsRefs.current && searchResultsRefs.current?.length > MAX_VISIBLE_RESULTS) {
      let newHeight = 0;
      searchResultsRefs.current.slice(0, MAX_VISIBLE_RESULTS).forEach((ref) => {
        const clientHeight = ref.current?.clientHeight;
        // @ts-expect-error ts(18048) FIXME: 'clientHeight' is possibly 'undefined'.
        if (clientHeight > 0) {
          // @ts-expect-error ts(18048) FIXME: 'clientHeight' is possibly 'undefined'.
          newHeight += clientHeight;
        }
      });
      if (newHeight > 0) {
        const searchBoxHeight = searchTextBoxRef.current?.clientHeight;
        setMaxResultsHeight(
          newHeight +
            // @ts-expect-error ts(18048) FIXME: 'searchBoxHeight' is possibly 'undefined'.
            searchBoxHeight +
            (MAX_VISIBLE_RESULTS + 1) * COMBOBOX_LIST_GAP,
        );
      }
    } else {
      setMaxResultsHeight(DEFAULT_MAX_RESULTS_HEIGHT);
    }
  }, [searchResultsRefs.current?.length, searchTextBoxRef.current?.clientHeight]);

  const onBlur = useCallback(
    (event: any) => {
      setValue(initialValue);
      // We call `selectState.hide()` to close the popover when the
      // user moves the focus away by pressing TAB. Without this call,
      // the popover would only get closed on click outside.
      // The conditional around `selectState.hide()` prevents closing
      // of the popover when the user clicks on the scrollbars of the
      // combobox list.
      if (
        event.relatedTarget !== contentElement &&
        !contentElement?.contains(event.relatedTarget)
      ) {
        hide();
      }
    },
    [initialValue, contentElement, hide, setValue],
  );

  const renderSearchResult = (text) => {
    const asciiFoldedValue = ASCIIFolder.foldReplacing(value);
    const substrings = splitBySearchTerm(text, asciiFoldedValue, { includeTerm: true });

    return (
      <Clamp lines={3}>
        <Text color="gray">
          {substrings.map((substring, i) =>
            substring.toLowerCase() === asciiFoldedValue.toLowerCase() ? (
              <Strong key={i}>{substring}</Strong>
            ) : (
              <Fragment key={i}>{substring}</Fragment>
            ),
          )}
        </Text>
      </Clamp>
    );
  };

  return (
    <form onSubmit={(event) => event.preventDefault()}>
      <SearchInputCombobox
        initialValue={initialValue}
        comboboxState={comboboxState}
        textPlaceholder={textPlaceholder}
        isLoading={isLoading}
        disabled={disabled}
        onChange={onChange}
        onSubmit={onSubmit}
        onBlur={onBlur}
        hideOnSubmit
      />
      {value && (
        <SearchPopover state={comboboxState} portal>
          <ComboboxList
            state={comboboxState}
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: `${COMBOBOX_LIST_GAP}px`,
              overflowY: 'auto',
              maxHeight: `${maxResultsHeight}px`,
            }}
          >
            <SearchResult
              ref={searchTextBoxRef}
              focusOnHover
              onClick={() => onSubmit?.(value)}
            >
              <Flex flexDirection="row" sx={{ columnGap: '4px' }}>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    minWidth: `${SEARCH_INPUT_HEIGHT}px`,
                    borderTopRightRadius: '4px',
                    borderBottomRightRadius: '4px',
                  }}
                >
                  <Icon
                    icon="search"
                    color="subtext"
                    aria-hidden="true"
                    sx={{ width: '14px', height: '14px' }}
                    fixedWidth
                  />
                </Flex>
                <Box sx={{ width: '100%', overflowWrap: 'break-word' }}>
                  <Text>
                    {value} – {t('search')}
                  </Text>
                </Box>
              </Flex>
            </SearchResult>
            {!isLoading &&
              (isEmpty(results) ? (
                <Text color="subtext" pt="18px" pb="24px" px="10px">
                  {noResultsMessage}
                </Text>
              ) : (
                results?.map((item, i) => {
                  const id = getResultId(item);

                  return (
                    <SearchResult
                      key={id + i}
                      focusOnHover
                      // @ts-expect-error ts(18048) FIXME: 'searchResultsRefs.current' is possibly 'undefined'.
                      ref={searchResultsRefs.current[i]}
                      onClick={() => onResultClick?.(item)}
                    >
                      {(props: any) => (
                        <SelectItem {...props} value={id}>
                          {renderSearchResult(getResultText(item))}
                        </SelectItem>
                      )}
                    </SearchResult>
                  );
                })
              ))}
          </ComboboxList>
        </SearchPopover>
      )}
    </form>
  );
};
