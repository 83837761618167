import { Live, PageType, SectionType } from '@deepstream/common/rfq-utils';
import { useCallback, useState } from 'react';
import * as rfx from '../../../rfx';
import { useRecipientId, useRfqExchange } from '../../../useRfq';
import { LoadingPanel } from '../../../ui/Loading';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import { useExchangeModalState } from '../../../useExchangeModalState';
import { RfxExchangeModal } from '../../../ExchangeModal/RfxExchangeModal';
import { AwardedMessageGrid } from '../../../ui/ExchangeDefsGrid/AwardedMessageGrid';

export const AwardedMessageSectionContent = ({ awardedChatExchangeId }: { awardedChatExchangeId: string }) => {
  const { pages, sectionById, recipients } = rfx.useStructure<Live>();
  const recipientId = useRecipientId();
  const { data: exchange, error, isLoading } = useRfqExchange({
    recipientId,
    exchangeId: awardedChatExchangeId,
  });

  const [exchangeModalData, setExchangeModalData] = useState<{ exchangeId: string | null; recipientId: string | null }>({
    recipientId: null,
    exchangeId: null,
  });

  // @ts-expect-error ts(2345) FIXME: Argument of type '{ exchangeId: string | null; recipientId: string | null; }' is not assignable to parameter of type 'UseExchangeModalStateProps'.
  const exchangeModal = useExchangeModalState(exchangeModalData);

  const rows = exchange ? [exchange] : [];

  const chatPage = pages.find(page => page.type === PageType.CHAT);
  const exchangeModalSection = Object.values(sectionById).find(section => section.type === SectionType.CHAT);

  const closeExchange = useCallback(() => {
    setExchangeModalData({
      recipientId: null,
      exchangeId: null,
    });
  }, []);

  return isLoading ? (
    <LoadingPanel />
  ) : error ? (
    <ErrorPanel error={`There was an error loading this exchange (id: ${awardedChatExchangeId}`} />
  ) : exchange ? (
    <>
      <AwardedMessageGrid
        rows={rows}
        onRowClick={() => {
          setExchangeModalData({
            recipientId,
            exchangeId: awardedChatExchangeId,
          });
        }}
      />
      {exchangeModalSection && (
        <rfx.RecipientsProvider recipients={recipients}>
          {/*
           // @ts-expect-error ts(2322) FIXME: Type 'Page | undefined' is not assignable to type 'Page'. */}
          <rfx.PageProvider page={chatPage}>
            <RfxExchangeModal
              {...exchangeModal}
              showRecipient
              close={() => closeExchange()}
            />
          </rfx.PageProvider>
        </rfx.RecipientsProvider>
      )}
    </>
  ) : (
    null
  );
};
