import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from '../Table';
import { ExchangeList } from '../ExchangeTable';
import { StaticTableStyles } from '../TableStyles';
import { useDeviceSize } from '../ui/useDeviceSize';
import { nestCells } from '../nestCells';
import { TruncateCell } from '../TruncateCell';
import { DisabledCell } from './cell';

export const DeliveryTable = () => {
  const { t } = useTranslation();
  const { isExtraSmall } = useDeviceSize();

  const { columns, data } = useMemo(
    () => ({
      columns: [
        {
          id: 'deliveryPort',
          Header: t('request.vesselPricing.deliveryPort'),
          accessor: 'message',
          Cell: nestCells(TruncateCell, DisabledCell),
        },
        {
          id: 'redeliveryPort',
          Header: t('request.vesselPricing.redeliveryPort'),
          accessor: 'message',
          Cell: nestCells(TruncateCell, DisabledCell),
        },
      ],
      data: [{
        _id: 'delivery-supplier-to-specify',
        message: t('request.vesselPricing.delivery.supplierToSpecify'),
      }],
    }),
    [t],
  );

  return isExtraSmall ? (
    <ExchangeList
      columns={columns}
      exchanges={data}
      onRowClick={null}
    />
  ) : (
    <StaticTableStyles>
      <Table
        columns={columns}
        data={data}
        isSortable={false}
      />
    </StaticTableStyles>
  );
};
