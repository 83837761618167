import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import {
  CurrencyExchangeDefinition,
  ExchangeProvider,
  getExchangeDefFieldValue,
  LineItemExchangeDefinition,
  Lock,
  hasSupplierPriceField,
} from '@deepstream/common/rfq-utils';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { LabeledValue } from './LabeledValue';
import { LockState } from '../lock';
import { ErrorMessage } from '../ui/ErrorMessage';
import { LotSelectItem } from './useLotSelectItems';

export const LineItemsSectionConfigIndicators = ({
  isContract,
  isSender,
  multiStageBehavior,
  locking,
  providedBy,
  modelExchangeDef,
  currencyExchangeDef,
  supplierCurrencyError,
  tagsError,
  selectedLot,
}: {
  isContract?: boolean;
  isSender: boolean;
  multiStageBehavior?: string | null;
  locking?: Lock;
  providedBy?: ExchangeProvider;
  modelExchangeDef?: LineItemExchangeDefinition;
  currencyExchangeDef: CurrencyExchangeDefinition;
  supplierCurrencyError?: string | null;
  tagsError?: string | null;
  selectedLot?: LotSelectItem | null;
}) => {
  const { t } = useTranslation('translation');

  const supplierCurrencyCodes = currencyExchangeDef.currencies?.join(', ');

  return (
    <Flex alignItems="center" sx={{ gap: 3, color: selectedLot?.isObsolete ? 'subtext' : 'text' }}>
      {selectedLot && (
        <LabeledValue
          label={t('request.lots.sectionLotConfiguration')}
          value={
            <>
              {selectedLot.isObsolete && <Icon icon="ban" fixedWidth fontSize="inherit" mr="2px" />}
              {selectedLot?.label}
            </>
          }
          small
        />
      )}
      {!selectedLot?.isObsolete && (
        <>
          {!isContract && isSender && multiStageBehavior && (
            <LabeledValue
              label={t('request.multiStageBehavior.multiStageBehavior')}
              value={(
                <>
                  <Truncate>{multiStageBehavior}</Truncate>
                  {tagsError && (
                    <ErrorMessage style={{ marginTop: '2px' }} fontSize="12px" error={tagsError} />
                  )}
                </>
              )}
              small
            />
          )}
          {hasSupplierPriceField(modelExchangeDef?.fields) && (
            <LabeledValue
              label={t('request.lineItems.currency.supplierCurrency')}
              value={supplierCurrencyError ? (
                <ErrorMessage fontSize="12px" error={supplierCurrencyError} />
              ) : (
                supplierCurrencyCodes || <EmDash />
              )}
              small
            />
          )}
          {modelExchangeDef?.fields.evaluatorFieldCurrency && (
            <LabeledValue
              label={t('request.lineItems.currency.buyerCurrency')}
              // @ts-expect-error ts(2345) FIXME: Argument of type 'string' is not assignable to parameter of type 'never'.
              value={getExchangeDefFieldValue(modelExchangeDef, 'evaluatorFieldCurrency') || (<EmDash />)}
              small
            />
          )}
          {!isContract && isSender && (
            <LabeledValue
              label={t('request.lockResponses')}
              value={<LockState locking={locking} />}
              small
            />
          )}
          {!isContract && (
            <LabeledValue
              label={t('request.lineItems.supplierAddedLineItems')}
              value={providedBy === ExchangeProvider.BOTH ? t('general.enabled') : t('general.disabled')}
              small
            />
          )}
        </>
      )}
    </Flex>
  );
};
