import * as Layout from '../../../Layout';
import { Header } from './Header';
import { TemplatePageTab } from './utils';
import { ActiveSuppliers } from './ActiveSuppliers';

export const QuestionnaireTemplateActiveSuppliersPageContent = () => {
  const tabId = TemplatePageTab.SUPPLIERS;

  return (
    <Layout.HeaderAndContent
      header={
        <Header selectedTabId={tabId} />
      }
      content={
        <ActiveSuppliers />
      }
    />
  );
};
