import { useMemo, useCallback, useRef, useEffect } from 'react';
import * as React from 'react';
import { omit, cloneDeep, sortBy, uniq, flatMap, isPlainObject, map, isNil } from 'lodash';
import { useField, Formik, Form } from 'formik';
import styled from 'styled-components';
import { Box } from 'rebass/styled-components';
import * as yup from 'yup';
import { TableQuestionElement, QuestionElementType, YearConfiguration, QuestionElementOption, DropdownQuestionElement, TextboxQuestionElement } from '@deepstream/common/legacy-pre-q-utils';
import { getCurrencySymbol } from '@deepstream/utils';
import { useTranslation } from 'react-i18next';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { Modal, ModalHeader, ModalBody, ModalFooter, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { ElementProps, Modify, ContainerLabel, Label, NoAnswer, LockedAnswer } from './common';
import { useModalState } from '../ui/useModalState';
import { Input } from '../ui/Input';
import { Row } from '../ui/ProfileLayout';
import { CurrencyFormat } from '../ui/Currency';
import { ErrorMessage } from '../ui/ErrorMessage';
import { TextboxElement } from './TextboxElement';
import { SelectElement } from './SelectElement';

/** utilities to help with table manipulation  */

const sortNumbersDesc = (numbers: number[]) =>
  sortBy(numbers, Number).reverse();

const mapYearsToOptions = (years: string[]): QuestionElementOption[] =>
  years.map(year => ({ key: year, label: year, value: year }));

const getYearsFromRows = (table: any) => {
  table = omit(table, ['currencyCode']);

  const columns = Object.keys(table);

  return sortNumbersDesc(uniq(flatMap(
    columns,
    column => Object.keys(table[column]) as unknown as number[],
  )));
};

const getYearsFromColumns = (table: any) => {
  table = omit(table, ['currencyCode']);

  return sortNumbersDesc(Object.keys(table) as unknown as number[]);
};

const isYearTable = (options: any): options is YearConfiguration =>
  isPlainObject(options) && options.type === 'year';

const StaticTableStyles = styled.div`
  overflow-x: auto;

  table {
    table-layout: fixed;
    border-collapse:  collapse;

    th, td {
      box-sizing: border-box;
      line-height: normal;
      margin: 0;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      text-align: right;
      border: 1px solid ${props => props.theme.colors.lightGray};
    }

    th {
      height: 40px;
      font-weight: 500;
      max-width: 200px;
      text-align: left;
    }

    td {
      max-width: 180px;
      min-width: 180px;
    }
  }
`;

type AddYearFormProps = {
  onSubmit: any;
  onCancel: any;
  invalidYears: any;
};

const AddYearForm: React.FC<AddYearFormProps> = ({ onSubmit, onCancel, invalidYears }) => {
  const { t } = useTranslation(['companyProfile', 'general']);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(
    () => {
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    },
    [],
  );

  const handleSubmit = useCallback(
    (values) => onSubmit(Number(values.year)),
    [onSubmit],
  );

  return (
    <Formik
      initialValues={{
        year: '',
      }}
      validationSchema={
        yup.object().shape({
          year: yup
            .number()
            .required(t('required', { ns: 'general' }))
            .integer(t('error.invalidFormat'))
            .lessThan(new Date().getFullYear() + 1, t('error.yearMustBeLessThan', { year: new Date().getFullYear() + 1 }))
            .moreThan(1900, t('error.yearMustBeGreaterThan1900'))
            .notOneOf(invalidYears, t('error.yearAlreadyAdded'))
            .typeError(t('error.invalidFormat')),
        })
      }
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, handleBlur, errors, touched, dirty, isValid, isSubmitting }) => (
        <Form>
          <ModalHeader onClose={onCancel}>
            {t('element.table.addYear')}
          </ModalHeader>
          <ModalBody>
            <Label width="100%">
              <Box>{t('year', { ns: 'general' })}</Box>
              <Input
                name="year"
                onValueChange={year => setFieldValue('year', year, String(year).length === 4)}
                onBlur={handleBlur}
                maxLength={4}
                format="year"
              />
              {touched && (
                <ErrorMessage error={errors?.year as string} />
              )}
            </Label>
          </ModalBody>
          <ModalFooter>
            <CancelButton onClick={onCancel} />
            <Button type="submit" disabled={!isValid || isSubmitting || !dirty}>
              {t('element.table.addYear')}
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

type TableCellProps = {
  name: string;
  currencyCode?: string;
  answer: any;
  error: any;
  disabled?: boolean;
  isReadOnly?: boolean;
  isLocked?: boolean;
};

const isUnanswered = (answer: any) => isNil(answer) || answer === '';

const TableCell: React.FC<TableCellProps> = ({ name, currencyCode, answer, error, isReadOnly, disabled, isLocked }) => {
  const cellStyle: React.CSSProperties = useMemo(
    () => {
      if (isReadOnly) {
        if (isLocked) {
          return { textAlign: 'left', maxWidth: 300, height: 40 };
        } else {
          return { textAlign: isUnanswered(answer) ? 'left' : 'right', height: 40 };
        }
      } else {
        return { textAlign: 'right', padding: 8 };
      }
    },
    [isLocked, isReadOnly, answer],
  );

  const element = useMemo(
    () => ({
      type: QuestionElementType.TEXTBOX,
      key: '',
      label: '',
      inputType: 'number',
    } as TextboxQuestionElement),
    [],
  );

  return (
    <td style={cellStyle}>
      {isReadOnly ? (
        isUnanswered(answer) ? (
          <NoAnswer />
        ) : isLocked ? (
          <LockedAnswer />
        ) : (
          isNil(currencyCode) ? (
            <>{answer}</>
          ) : (
            <CurrencyFormat currency={currencyCode} amount={answer} />
          )
        )
      ) : (
        <TextboxElement
          element={element}
          name={name}
          answer={answer}
          error={error}
          isReadOnly={isReadOnly}
          isLocked={isLocked}
          disabled={disabled}
          prefix={getCurrencySymbol(currencyCode)}
          format={currencyCode ? 'money' : 'integer.positive'}
        />
      )}
    </td>
  );
};

const EmptyTableCell = () => (
  <TableCell
    name=""
    answer={null}
    error={null}
    isLocked={false}
    isReadOnly={true}
  />
);

export const TableElement: React.FC<Modify<ElementProps, { element: TableQuestionElement }>> = ({
  name,
  element,
  answer,
  hideLabel,
  error,
  isReadOnly,
  isLocked,
  disabled,
}) => {
  const { t } = useTranslation(['companyProfile', 'general']);
  const addYearModal = useModalState();
  const id = useUniqueId();
  const [field,, formik] = useField({ name }); // eslint-disable-line no-unused-vars

  const currencyCodeName = `${name}.currencyCode`;
  const [currencyField] = useField(currencyCodeName);

  const { rowOptions, columnOptions, inputType } = element;

  const isMoneyTable = inputType === 'money';
  const tableValue = isReadOnly ? answer : field.value;
  const hasYearsAsRows = isYearTable(rowOptions);
  const hasYearsAsColumns = isYearTable(columnOptions);

  const currencyCodeElement = useMemo(
    () => ({
      key: 'currencyCode',
      type: QuestionElementType.DROPDOWN,
      subtype: 'currency',
      label: t('currency', { ns: 'general' }),
    } as DropdownQuestionElement),
    [t],
  );

  const rows = useMemo(
    () => {
      if (hasYearsAsRows) {
        return mapYearsToOptions(getYearsFromRows(tableValue) as unknown as string[]);
      } else if (hasYearsAsColumns) {
        return rowOptions.map(option => ({
          key: option.key,
          value: option.key,
          label: t(`element.table.rowOption.${option.key}`),
        })) as QuestionElementOption[];
      } else {
        throw new Error('Invalid table element');
      }
    },
    [hasYearsAsRows, hasYearsAsColumns, rowOptions, tableValue, t],
  );

  const columns = useMemo(
    () => {
      if (hasYearsAsColumns) {
        return mapYearsToOptions(getYearsFromColumns(tableValue) as unknown as string[]);
      } else if (hasYearsAsRows) {
        return columnOptions.map(option => {
          const label = t(`element.table.columnOption.${option.key}`);
          return {
            key: option.key,
            label,
            value: label,
          } as QuestionElementOption;
        });
      } else {
        throw new Error('Invalid table element');
      }
    },
    [hasYearsAsRows, hasYearsAsColumns, columnOptions, tableValue, t],
  );

  const invalidYears = useMemo(
    () => {
      if (hasYearsAsColumns) {
        return map(columns, 'key').map(Number);
      } else if (hasYearsAsRows) {
        return map(rows, 'key').map(Number);
      } else {
        throw new Error('Invalid table element');
      }
    },
    [hasYearsAsRows, hasYearsAsColumns, rows, columns],
  );

  const addYear = useCallback(
    (year) => {
      const tableValue = cloneDeep(field.value) || {};

      if (hasYearsAsRows) {
        for (const column of columns) {
          if (!tableValue[column.key]) {
            tableValue[column.key] = {};
          }

          tableValue[column.key][year] = null;
        }
      } else if (hasYearsAsColumns) {
        tableValue[year] = {};

        for (const row of rows) {
          tableValue[year][row.key] = null;
        }
      }

      formik.setValue(tableValue);
      addYearModal.close();
    },
    [field, formik, hasYearsAsRows, hasYearsAsColumns, columns, rows, addYearModal],
  );

  const removeYear = useCallback(
    (year) => {
      const tableValue = cloneDeep(field.value);

      if (hasYearsAsRows) {
        for (const column of columns) {
          delete tableValue[column.key][year];
        }
      } else if (hasYearsAsColumns) {
        delete tableValue[year];
      }

      formik.setValue(tableValue);
    },
    [field, formik, columns, hasYearsAsRows, hasYearsAsColumns],
  );

  return (
    <ContainerLabel
      key={name}
      htmlFor={id}
      label={element.label || (element.labelId ? t(`element.label.${element.labelId}`) : '')}
      hideLabel={hideLabel}
    >
      <Modal isOpen={addYearModal.isOpen} onRequestClose={addYearModal.close}>
        <AddYearForm
          onSubmit={addYear}
          onCancel={addYearModal.close}
          invalidYears={invalidYears}
        />
      </Modal>
      <Stack gap={3}>
        {isMoneyTable && (
          <Box width={isReadOnly ? (1 / 2) : (1 / 4)}>
            <SelectElement
              element={currencyCodeElement}
              name={currencyCodeName}
              answer={tableValue?.currencyCode}
              error={error?.currencyCode}
              isReadOnly={isReadOnly}
            />
          </Box>
        )}
        {!isReadOnly && (
          <Box mt={isMoneyTable ? 0 : 1}>
            <Button
              small
              type="button"
              variant="primary-outline"
              iconLeft="plus"
              onClick={addYearModal.open}
              disabled={disabled || (isMoneyTable && !currencyField.value)}
            >
              {t('element.table.addYear')}
            </Button>
          </Box>
        )}
        <StaticTableStyles>
          <Box as="table">
            <thead>
              {!isReadOnly || columns.length ? (
                <tr>
                  {!isReadOnly || rows.length ? (
                    <th />
                  ) : (
                    null
                  )}
                  {columns.map(column => (
                    <Box as="th" key={column.key}>
                      <Row alignItems="center">
                        {column.label}
                        {!isReadOnly && hasYearsAsColumns && (
                          <IconButton ml={2} icon="trash" fixedWidth onClick={() => removeYear(column.key)} />
                        )}
                      </Row>
                    </Box>
                  ))}
                </tr>
              ) : (
                null
              )}
            </thead>
            <Box as="tbody">
              {rows.length ? (
                rows.map(row => (
                  <tr key={row.label}>
                    <th>
                      <Row alignItems="center">
                        <Box title={row.label}>
                          {row.label}
                        </Box>
                        {!isReadOnly && hasYearsAsRows && (
                          <IconButton ml={2} icon="trash" fixedWidth onClick={() => removeYear(row.key)} />
                        )}
                      </Row>
                    </th>
                    {columns.length ? (
                      columns.map(column => (
                        <TableCell
                          key={column.key}
                          name={`${name}.${column.key}.${row.key}`}
                          answer={tableValue?.[column.key]?.[row.key]}
                          error={null}
                          isLocked={isLocked}
                          isReadOnly={isReadOnly}
                          currencyCode={tableValue?.currencyCode}
                          disabled={disabled}
                        />
                      ))
                    ) : isReadOnly ? (
                      <EmptyTableCell />
                    ) : (
                      null
                    )}
                  </tr>
                ))
              ) : isReadOnly ? (
                <tr>
                  {columns.map((_, index) => (
                    <EmptyTableCell key={index} />
                  ))}
                </tr>
              ) : (
                null
              )}
            </Box>
          </Box>
        </StaticTableStyles>
      </Stack>
    </ContainerLabel>
  );
};
