import { Box, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';

export const AddAuctionLineItemDropdown = ({
  onSelect,
  ...props
}: Omit<ButtonProps, 'onSelect'> & {
  onSelect: (previous: boolean) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <DropdownMenu
        buttonText={t('request.lineItems.addLineItem')}
        variant="secondary"
        small
        iconLeft="plus"
        iconRight="caret-down"
        menuZIndex={2}
        menuStyle={{ width: '324px' }}
        {...props}
      >
        <DropdownMenuItem onSelect={() => onSelect(false)}>
          <Text lineHeight="18px" color="text">
            {t('request.auction.newLineItem.label')}
          </Text>
          <Text fontSize={1} lineHeight="15px" color="subtext" mt="2px">
            {t('request.auction.newLineItem.description')}
          </Text>
        </DropdownMenuItem>
        <DropdownMenuItem onSelect={() => onSelect(true)}>
          <Text lineHeight="18px" color="text">
            {t('request.auction.previousLineItems.label')}
          </Text>
          <Text fontSize={1} lineHeight="15px" color="subtext" mt="2px">
            {t('request.auction.previousLineItems.description')}
          </Text>
        </DropdownMenuItem>
      </DropdownMenu>
    </Box>
  );
};
