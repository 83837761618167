import { CellProps } from 'react-table';
import { isNil } from 'lodash';
import { ExchangeStatus, getExchangeFieldValue, User } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { localeFormatNumber } from '@deepstream/utils';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { Clamp } from '@deepstream/ui-kit/elements/text/Clamp';
import { OverflowTooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { EvaluationExchangeSnapshot } from './types';
import { useCurrentUser, useCurrentUserLocale } from './useCurrentUser';
import { useCurrentCompanyId } from './currentCompanyId';
import { EvaluationStatus, StatusIcon } from './ExchangeStatusCell';
import { Avatar } from './Avatar';
import * as rfx from './rfx';
import { requestAndLotBidStatusesAllowScoreSubmissions } from './modules/NewEvaluation/utils';

/**
 * Specifies the number of score points submitted for an evaluation criterion exchange
 */
export const CollaborativeExchangeScoreCell = ({
  row: { original: exchange },
  cell: { value: score },
  column: { showTooltip, truncate },
}: CellProps<EvaluationExchangeSnapshot> & { column: { showTooltip?: boolean; truncate?: boolean } }) => {
  const maxPoints = getExchangeFieldValue(exchange, 'maxPoints');
  const Wrapper = truncate ? Truncate : Text;
  const value = `${isNil(score) ? '—' : score} / ${maxPoints}`;

  return (
    <Wrapper>
      <OverflowTooltip content={showTooltip ? value : null}>
        <>{value}</>
      </OverflowTooltip>
    </Wrapper>
  );
};

/**
 * Specifies the average number of score points submitted by all evaluators
 * when the ScoringType is INDIVIDUAL_SCORES.
 */
export const AverageExchangeScoreCell = ({
  row: { original: exchange },
  cell: { value: score },
  column: { showTooltip, truncate },
}: CellProps<EvaluationExchangeSnapshot> & { column: { showTooltip?: boolean; truncate?: boolean } }) => {
  const maxPoints = getExchangeFieldValue(exchange, 'maxPoints');
  const locale = useCurrentUserLocale();
  const Wrapper = truncate ? Truncate : Text;

  const value = isNil(score)
    ? null
    : `${localeFormatNumber(score, { locale, maximumFractionDigits: 2 })} / ${maxPoints}`;

  return value ? (
    <Wrapper>
      <OverflowTooltip content={showTooltip ? value : null}>
        <>{value}</>
      </OverflowTooltip>
    </Wrapper>
  ) : (
    <EmDash />
  );
};

const getIndividualExchangeScoreStatus = (
  hasValue: boolean,
  isCurrentUser: boolean,
  canSubmitScore,
) => {
  if (!canSubmitScore) {
    return ExchangeStatus.BLOCKED;
  } else if (hasValue) {
    return ExchangeStatus.COMPLETE;
  } else if (isCurrentUser) {
    return ExchangeStatus.ACTION_REQUIRED;
  } else {
    return ExchangeStatus.INCOMPLETE;
  }
};

/**
 * Specifies the number of score points submitted by an individual evaluator
 * when the ScoringType is INDIVIDUAL_SCORES.
 */
export const IndividualExchangeScoreCell = ({
  row: { original: exchange },
  column: { user, companyId, iconFontSize, showTooltip, truncate },
  cell: { value: score },
}: CellProps<EvaluationExchangeSnapshot> & { column: { companyId: string, user: User, iconFontSize: number; showTooltip?: boolean; truncate?: boolean } }) => {
  const { t } = useTranslation('evaluation');
  const maxPoints = getExchangeFieldValue(exchange, 'maxPoints');
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const structure = rfx.useStructure();
  const canSubmitScore = requestAndLotBidStatusesAllowScoreSubmissions(exchange, structure);

  const status = getIndividualExchangeScoreStatus(
    !isNil(score),
    currentCompanyId === companyId && currentUser._id === user._id,
    canSubmitScore,
  );

  const Wrapper = truncate ? Truncate : Text;

  const value = isNil(score)
    ? t('individualStatus.noResponse')
    : `${score} / ${maxPoints}`;

  return (
    <Flex alignItems="center">
      <StatusIcon
        type={exchange.def.type}
        status={status}
        currentCompanyGroup="buyer"
        sx={{ top: '-5px' }}
        fixedWidth
        mr="18px"
        size={iconFontSize}
      />
      <OverflowTooltip content={showTooltip ? value : null}>
        <Wrapper color={isNil(score) ? 'subtext' : undefined}>
          {value}
        </Wrapper>
      </OverflowTooltip>
    </Flex>
  );
};

export const EvaluatorScoresCell = ({
  row: { original: exchange },
}: CellProps<EvaluationExchangeSnapshot>) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const { teamById } = rfx.useStructure();
  const maxPoints = getExchangeFieldValue(exchange, 'maxPoints');

  return (
    <Flex flexDirection="column">
      {/*
       // @ts-expect-error ts(2769) FIXME: No overload matches this call. */}
      {Object.entries(exchange.latestReply.score).flatMap(([companyId, companyScores]) => {
        // @ts-expect-error ts(2769) FIXME: No overload matches this call.
        return Object.entries(companyScores).map(([userId, score]) => (
          <Flex key={`${companyId}:${userId}`} height="30px" alignItems="center">
            <Flex width="200px">
              <Avatar width="xxs" userId={userId} sx={{ mr: 2 }} />
              <Text width="100%" fontWeight={500}>
                <Clamp lines={1}>
                  {teamById[companyId]?.users[userId]?.name}
                </Clamp>
              </Text>
            </Flex>
            <Box>
              {isNil(score) ? (
                <EvaluationStatus
                  iconFontSize={2}
                  status={companyId === currentCompanyId && userId === currentUser._id ? (
                    ExchangeStatus.ACTION_REQUIRED
                  ) : (
                    ExchangeStatus.INCOMPLETE
                  )}
                  currentCompanyGroup="buyer"
                  exchange={exchange}
                />
              ) : (
                `${score} / ${maxPoints}`
              )}
            </Box>
          </Flex>
        ));
      })}
    </Flex>
  );
};
