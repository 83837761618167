import { ContractStatus } from '@deepstream/common/contract';
import { compact, findIndex, first, isEmpty, isEqual, isObject, map } from 'lodash';
import { useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Heading } from 'rebass/styled-components';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { Disclosure } from '../../ui/Disclosure';
import { Tab, TabList, Tabs } from '../../ui/Tabs';
import { TabPanels, TabPanel } from '../../ui/TabsVertical';
import { AddPageButton } from './AddPageButton';
import { ApprovalPanel } from './Approvals/ApprovalPanel';
import { ContractLiveSections } from './BidSections';
import {
  useGeneralPages,
  ContractPageProvider,
  useContractState,
  useContractActions,
  useIsLinkedToRequest,
  useIsRecipient,
  useContractData,
  ApprovalsProvider,
  useIsSuperUserOrOwner,
  usePagePermissions,
  useContractPermissions,
  useContractPage,
} from './contract';
import { ContractDraftPage } from './ContractDraftPage';

import { NoEditPermissions } from './Permissions/NoEditPermissions';
import { NoPageAccess } from './Permissions/NoPageAccess';
import { ImportDetails } from './ImportDetails';
import { RequirementsProgressPanel } from './RequirementsProgressPanel';
import { Counter } from '../../ui/Badge';
import { useLiveContractNavigation, useDraftContractNavigation } from '../../appNavigation';
import { useSearch } from '../../tanstackRouter';

type TabInfo = {
  ids: Array<string | undefined>;
  counter: number;
};

const PagePlaceholder = () => {
  const { t } = useTranslation('contracts');
  const isLinkedToRequest = useIsLinkedToRequest();

  return (
    <Box py="20px">
      <Heading fontSize={5} fontWeight={500} mb="20px">
        {t('details.noPagesAddedYet')}
      </Heading>
      <Disclosure summary={t('details.disclosure.page.summary')}>
        {t('details.disclosure.page.content')}
      </Disclosure>
      {isLinkedToRequest && (
        <Disclosure summary={t('details.disclosure.importedContent.summary')} mt="20px">
          {t('details.disclosure.importedContent.content')}
        </Disclosure>
      )}
    </Box>
  );
};

const PageDetailsContent = () => {
  // @ts-expect-error ts(2339) FIXME: Property 'isLive' does not exist on type 'ContractStateContextType | undefined'.
  const { isLive } = useContractState();
  const contract = useContractData();
  const page = useContractPage();
  const isRecipient = useIsRecipient();
  const { canRead, canEdit } = usePagePermissions();
  const { canApprovePage } = useContractPermissions();
  const navigation = useLiveContractNavigation();
  const search = useSearch({ strict: false }) as { exchangeId?: string; };

  return !canRead ? (
    <NoPageAccess />
  ) : isLive ? (
    <ApprovalsProvider>
      {!isRecipient && contract.status === ContractStatus.NEGOTIATION && canApprovePage && (
        <ApprovalPanel />
      )}
      <ContractLiveSections
        exchangeId={search.exchangeId}
        navigateToExchange={({ recipientId, exchangeId }) =>
          navigation.navigateToDetailsPage(page._id, { recipientId, exchangeId })
        }
      />
    </ApprovalsProvider>
  ) : (
    <>
      {!canEdit && (
        <NoEditPermissions />
      )}
      <ContractDraftPage />
    </>
  );
};

const buttonTabStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const ContractDetails = ({ pageId }: { pageId: string }) => {
  const [tabInfo, setTabInfo] = useState<TabInfo>({ ids: [], counter: 0 });
  const generalPages = useGeneralPages();
  const draftNavigation = useDraftContractNavigation();
  const liveNavigation = useLiveContractNavigation();
  // @ts-expect-error ts(2339) FIXME: Property 'isLive' does not exist on type 'ContractStateContextType | undefined'.
  const { isLive, editingPanelId } = useContractState();
  const { stopEditing } = useContractActions();
  const theme = useTheme();
  const isLinkedToRequest = useIsLinkedToRequest();
  const contract = useContractData();
  const isRecipient = useIsRecipient();
  const isSuperUserOrOwner = useIsSuperUserOrOwner();

  const isEditingAnyPanel = Boolean(editingPanelId);

  useEffect(
    () => {
      if (!pageId && !isEmpty(generalPages)) {
        if (isLive) {
          // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
          liveNavigation.navigateToDetailsPage(first(generalPages)._id);
        } else {
          // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
          draftNavigation.navigateToDetailsPage(first(generalPages)._id);
        }
      } else if (pageId && isEmpty(generalPages)) {
        draftNavigation.navigateToDetailsPage('');
      }
    },
    [generalPages, pageId, isLive, draftNavigation, liveNavigation],
  );

  const canImportContent = !isLive && isLinkedToRequest && isSuperUserOrOwner;

  const tabs = useMemo(
    () => compact([
      ...generalPages,
      !isLive ? { key: 'addPageButton' } : null,
      canImportContent ? { key: 'importContentButton' } : null,
    ]),
    [generalPages, isLive, canImportContent],
  );

  useEffect(() => {
    const ids = map(generalPages, '_id');
    if (!isEqual(tabInfo.ids, ids)) {
      setTabInfo({ ids, counter: tabInfo.counter + 1 });
    }
  }, [generalPages, tabInfo]);

  const selectedTabIndex = useMemo(
    () => findIndex(tabs, { _id: pageId }),
    [tabs, pageId],
  );

  useWatchValue(
    selectedTabIndex,
    () => {
      if (!isLive) {
        stopEditing();
      }
    },
  );

  const onTabChange = useCallback(
    (index: number) => {
      const page = tabs[index];

      if (isObject(page) && '_id' in page) {
        if (isLive) {
          liveNavigation.navigateToDetailsPage(page._id);
        } else {
          draftNavigation.navigateToDetailsPage(page._id);
        }
      }
    },
    [tabs, isLive, draftNavigation, liveNavigation],
  );

  return (
    <>
      {(isLive && isRecipient && contract.status !== ContractStatus.FAILED) && (
        <RequirementsProgressPanel />
      )}
      <Tabs
        // The tabs break after reordering the pages, so we're using a key to force remounting
        key={(pageId ?? '') + tabInfo.counter}
        index={selectedTabIndex}
        onChange={onTabChange}
        canOverflow
      >
        <Flex mb="24px">
          <TabList
            style={{
              backgroundColor: 'inherit',
              fontSize: theme.fontSizes[2],
            }}
            scrollLeftBackground="linear-gradient(90deg, rgba(247,249,251,1) 0%, rgba(247,249,251,1) 60%, rgba(247,249,251,0.5) 80%, rgba(247,249,251,0) 100%)"
            scrollRightBackground="linear-gradient(90deg, rgba(247,249,251,0) 0%, rgba(247,249,251,0.5) 20%, rgba(247,249,251,1) 40%, rgba(247,249,251,1) 100%)"
          >
            {generalPages.map(page => (
              <Tab key={page._id}>
                <Flex alignItems="center">
                  <Truncate>
                    {page.name}
                  </Truncate>
                  {isLive && contract.bidProgressByPageId?.[page._id] && (
                    <Counter
                      count={contract.bidProgressByPageId[page._id].awaitingRecipient}
                      ml={1}
                    />
                  )}
                </Flex>
              </Tab>
            ))}
            {(!isLive && isSuperUserOrOwner) && (
              <Tab disabled={isEditingAnyPanel} style={{ padding: 0, ...buttonTabStyle }}>
                <AddPageButton disabled={isEditingAnyPanel} />
              </Tab>
            )}
          </TabList>
          {canImportContent && (
            <ImportDetails
              disabled={isEditingAnyPanel}
              sx={{ flex: '1 0 auto', whiteSpace: 'nowrap', marginLeft: '20px' }}
            />
          )}
        </Flex>
        {selectedTabIndex > -1 ? (
          <TabPanels>
            {tabs.map((page: any, index) => page && '_id' in page ? (
              <TabPanel key={page._id}>
                {selectedTabIndex === index && (
                  <ContractPageProvider page={page}>
                    <PageDetailsContent />
                  </ContractPageProvider>
                )}
              </TabPanel>
            ) : (
              <TabPanel key={page.key} />
            ))}
          </TabPanels>
        ) : !isLive ? (
          <PagePlaceholder />
        ) : (
          null
        )}
      </Tabs>
    </>
  );
};
