import { useRef } from 'react';
import { FieldArrayRenderProps, useField, FieldArray } from 'formik';
import { get, map } from 'lodash';
import { QuestionType } from '@deepstream/common/rfq-utils';
import { Flex, Box } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';

import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';

import { StyledCheckbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { MoveUpMenuItem, MoveDownMenuItem, DeleteMenuItem, EllipsisMenu } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { TextField } from '../../form/TextField';
import { StyledRadio } from '../../ui/Radio';
import { DisabledInputBox } from '../../ui/Input';

const AddOptionButton = (props: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button small variant="primary" iconLeft="plus" type="button" {...props}>
      {t('request.question.addOption')}
    </Button>
  );
};

const AddOtherButton = (props: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button small variant="secondary" iconLeft="plus" type="button" {...props}>
      {t('request.question.addOther')}
    </Button>
  );
};

export const QuestionOptionField = ({
  fieldName,
  type,
  disabled,
}: {
  fieldName?: string;
  type: QuestionType.CHECKBOXES | QuestionType.MULTIPLE_CHOICE;
  disabled?: boolean;
}) => {
  const optionsFieldName = fieldName ? `${fieldName}.options` : 'options';
  const allowCustomOptionFieldName = fieldName ? `${fieldName}.allowCustomOption` : 'allowCustomOption';

  const arrayHelpersRef = useRef<FieldArrayRenderProps>();
  const { t } = useTranslation();
  const [{ value: allowCustomOption }, , allowCustomOptionHelpers] = useField<boolean>(allowCustomOptionFieldName);

  return (
    <Box maxWidth="540px">
      <FieldArray
        name={optionsFieldName}
        render={(props) => {
          arrayHelpersRef.current = props;
          const options = get(props.form.values, props.name) as string[];

          return (
            <Stack gap={3}>
              {map(options, (option, i) => (
                <Flex key={i} alignItems="flex-start">
                  <Box sx={{ marginRight: '8px', flex: '0 0 auto' }}>
                    {type === QuestionType.CHECKBOXES ? (
                      <StyledCheckbox disabled style={{ background: 'transparent', marginTop: '12px' }} />
                    ) : (
                      <StyledRadio disabled style={{ background: 'transparent', marginTop: '12px' }} />
                    )}
                  </Box>
                  <TextField
                    name={`${props.name}[${i}]`}
                    disabled={disabled}
                    required
                  />
                  <EllipsisMenu variant="secondary-outline" menuZIndex={202} width="40px" wrapperStyle={{ marginLeft: '8px' }}>
                    <MoveUpMenuItem
                      onSelect={() => props.move(i, i - 1)}
                      disabled={i === 0 || disabled}
                    />
                    <MoveDownMenuItem
                      onSelect={() => props.move(i, i + 1)}
                      disabled={i === options.length - 1 || disabled}
                    />
                    <DeleteMenuItem onSelect={() => props.remove(i)} disabled={disabled} />
                  </EllipsisMenu>
                </Flex>
              ))}
              {allowCustomOption && (
                <Flex key="custom-option-placeholder" alignItems="center">
                  {type === QuestionType.CHECKBOXES ? (
                    <StyledCheckbox disabled style={{ background: 'transparent' }} />
                  ) : (
                    <StyledRadio disabled style={{ background: 'transparent' }} />
                  )}
                  <DisabledInputBox flex="1 1 100%" sx={{ marginLeft: '8px', marginRight: '8px' }}>
                    {t('request.question.otherOption')}
                  </DisabledInputBox>
                  <Button
                    variant="secondary-outline"
                    width="40px"
                    iconLeft="trash"
                    disabled={disabled}
                    onClick={() => allowCustomOptionHelpers.setValue(false)}
                  />
                </Flex>
              )}
            </Stack>
          );
        }}
      />
      <Flex mt={3}>
        <AddOptionButton
          // @ts-expect-error ts(18048) FIXME: 'arrayHelpersRef.current' is possibly 'undefined'.
          onClick={() => arrayHelpersRef.current.push('')}
          disabled={disabled}
        />

        {!allowCustomOption && (
          <AddOtherButton
            onClick={() => allowCustomOptionHelpers.setValue(true)}
            ml={2}
            disabled={disabled}
          />
        )}
      </Flex>
    </Box>
  );
};
