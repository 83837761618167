import { useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';

export const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <Box width="100%" textAlign="center" mt={6}>
      <Icon icon="times-circle-o" color="danger" fontSize={8} />
      <Box as="h1" mt={3}>
        {t('pageNotFound.heading')}
      </Box>
      <Box as="h3" mt={2}>
        {t('pageNotFound.message')}
      </Box>
    </Box>
  );
};
