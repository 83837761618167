import { omit } from 'lodash';
import { useState } from 'react';
import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useMutation } from '../../../useMutation';
import { useApi, wrap } from '../../../api';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import * as chatbot from './types';

export type BasicTargetById = Record<string, chatbot.BasicTarget>;

type ChatbotTargetsStateContextValue = {
  chatbotId: string | null;
  targetById: BasicTargetById;
  setChatbotId: (chatbotId: string) => void;
  addTargets: (targets: BasicTargetById) => void;
  removeTargets: (subjectIds: string[]) => void;
};

// @ts-expect-error ts(2345) FIXME: Argument of type 'null' is not assignable to parameter of type 'ChatbotTargetsStateContextValue'.
const ChatbotTargetsStateContext = React.createContext<ChatbotTargetsStateContextValue>(null);

export const ChatbotStateProvider = ({ children }: { children: React.ReactNode }) => {
  // @ts-expect-error ts(2345) FIXME: Argument of type 'null' is not assignable to parameter of type 'string | (() => string)'.
  const [chatbotId, setChatbotId] = useState<string>(null);
  const [targetById, setTargetById] = useState<BasicTargetById>({});

  const value = React.useMemo(
    () => ({
      chatbotId,
      targetById,

      setChatbotId,
      addTargets: (newTargets: BasicTargetById) => {
        setTargetById(targets => ({ ...targets, ...newTargets }));
      },
      removeTargets: (targetIds: string[]) => {
        setTargetById(targets => omit(targets, targetIds));
      },
    }),
    [chatbotId, targetById],
  );

  return (
    <ChatbotTargetsStateContext.Provider value={value}>
      {children}
    </ChatbotTargetsStateContext.Provider>
  );
};

export const useChatbotState = (): ChatbotTargetsStateContextValue => {
  const context = React.useContext(ChatbotTargetsStateContext);

  if (!context) {
    throw new Error('useChatbotSubjects must be used within a ChatbotSubjectsProvider');
  }

  return context;
};

export const useChatbotSubjects = (targetById: BasicTargetById) => {
  const { addTargets, removeTargets } = useChatbotState();

  React.useEffect(
    () => {
      addTargets(targetById);

      return () => {
        removeTargets(Object.keys(targetById));
      };
    },
    // FIXME: is this right?
    [], // eslint-disable-line
  );
};

export const ChatbotTarget = ({
  targetId,
  target,
  children,
}: {
  targetId: string;
  target: chatbot.BasicTarget;
  children: React.ReactNode;
}) => {
  useChatbotSubjects({ [targetId]: target });

  return children;
};

export const useCreateChatbotChat = () => {
  const api = useApi();
  const { setChatbotId } = useChatbotState();

  return useMutation(
    api.createChatbotConversation,
    {
      onSuccess: (result) => setChatbotId(result._id),
    },
  );
};

const queryKey = {
  chatbotChat: (chatbotId: string, companyId: string) => ['chatbotConversation', { chatbotId, companyId }],
};

export const useChatbotChat = () => {
  const currentCompanyId = useCurrentCompanyId();
  const { chatbotId } = useChatbotState();
  const api = useApi();

  return useQuery({
    // @ts-expect-error ts(2345) FIXME: Argument of type 'string | null' is not assignable to parameter of type 'string'.
    queryKey: queryKey.chatbotChat(chatbotId, currentCompanyId),
    queryFn: wrap(api.getChatbotChat),
    enabled: Boolean(chatbotId),
    refetchInterval: 1000,
  });
};

export const useDispatchChatbotAction = () => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId();
  const queryClient = useQueryClient();
  const { chatbotId } = useChatbotState();

  return useMutation(
    api.dispatchChatbotAction,
    {
      onSuccess: () =>
        queryClient.invalidateQueries(
          // @ts-expect-error ts(2345) FIXME: Argument of type 'string | null' is not assignable to parameter of type 'string'.
          queryKey.chatbotChat(chatbotId, currentCompanyId),
        ),
    },
  );
};
