import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useExchangeFieldStatus } from '../NoResponseCell';
import { ExchangeSnapshot } from '../types';

export const ExchangeFieldStatusIcon = ({ exchange }: { exchange: ExchangeSnapshot }) => {
  const exchangeFieldStatus = useExchangeFieldStatus(exchange);

  return exchangeFieldStatus ? (
    <Icon color={exchangeFieldStatus.color} icon={exchangeFieldStatus.icon} />
  ) : (
    null
  );
};
