import { useState } from 'react';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Trans, useTranslation } from 'react-i18next';
import { conforms, isEmpty } from 'lodash';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils/types';
import { AwardScenario, Live, LotIntentionStatus } from '@deepstream/common/rfq-utils';
import { Box, Text } from 'rebass/styled-components';
import { Clamp2 } from '@deepstream/ui-kit/elements/text/Clamp';
import { DateFormat } from '@deepstream/utils';
import { isLotInactive } from '@deepstream/common/rfq-utils/lot';
import * as rfx from '../../../rfx';
import { RequestRecipientMenuNavigation } from './RequestRecipientMenuNavigation';
import * as lotPagesLayout from '../Live/lotPagesLayout';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useNotificationSubject } from '../../Notifications/useNotificationSubject';
import { Notification } from '../../Notifications/types';
import { useLiveRfqStructure, useRecipientId, useRfqId } from '../../../useRfq';
import { awardScenarioIconProps, orderedAwardScenarios } from '../../../draft/SummaryLotsPanel';
import { Disclosure2 } from '../../../ui/Disclosure';
import { ViewProductsPanel } from '../../../ViewProductsPanel';
import { Datetime2 } from '../../../Datetime';
import { CompanyLogo } from '../../../CompanyLogo';
import { LoadingPanel } from '../../../ui/Loading';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import { RequestRecipientBidStatusTable } from './RequestRecipientBidStatusTable';
import { useLotsInfo } from './useLotsInfo';
import { InactiveLotsToggle } from './InactiveLotsToggle';
import { useRequestRecipientNavigation } from '../../../appNavigation';

const DetailsSection = () => {
  const { t } = useTranslation('translation');
  const { firstVersionTimestamp, summary, autoReferenceNumber, senders } = rfx.useStructure<Live>();

  return (
    <lotPagesLayout.Section heading={t('general.details')}>
      <lotPagesLayout.DescriptionListContainer style={{ marginTop: '8px' }}>
        <lotPagesLayout.DescriptionListItem
          label={t('requests.from')}
          description={
            <Stack gap={2}>
              {senders.map(sender => (
                <Box key={sender._id}>
                  <Box sx={{ position: 'relative', height: 0 }}>
                    <Box sx={{ position: 'absolute', top: '-2px' }}>
                      <CompanyLogo size="xs" companyId={sender.company._id} />
                    </Box>
                  </Box>
                  <Box as="span" ml="31px">
                    {sender.company.name}
                  </Box>
                  <Box as="span" ml={2} color="subtext">
                    {`(${sender.company.role})`}
                  </Box>
                </Box>
              ))}
            </Stack>
          }
        />
        <lotPagesLayout.DescriptionListItem
          label={t('request.received_one')}
          description={
            // @ts-expect-error ts(2322) FIXME: Type 'number | Date | null | undefined' is not assignable to type 'DatetimeValue | undefined'.
            <Datetime2 value={firstVersionTimestamp} format={DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ} />
          }
        />
        <lotPagesLayout.DescriptionListItem
          label={t('request.summary.automatedReference')}
          description={autoReferenceNumber}
        />
        {summary.reference && (
          <lotPagesLayout.DescriptionListItem
            label={t('request.summary.additionalReference')}
            description={summary.reference}
          />
        )}
      </lotPagesLayout.DescriptionListContainer>
    </lotPagesLayout.Section>
  );
};

const MenuSection = () => {
  const { t } = useTranslation('translation');

  return (
    <lotPagesLayout.Section heading={t('general.menu')}>
      <RequestRecipientMenuNavigation />
    </lotPagesLayout.Section>
  );
};

const StatusSection = () => {
  const { t } = useTranslation('translation');
  const navigation = useRequestRecipientNavigation();

  return (
    <lotPagesLayout.Section heading={t('general.status')}>
      <Box mt="6px">
        <RequestRecipientBidStatusTable />
      </Box>
      <lotPagesLayout.InfoText>
        <Trans
          i18nKey="request.openMyBidPage"
          ns="translation"
          components={{
            a: (
              <lotPagesLayout.TanstackInlineLink
                {...navigation.getBidLinkProps()}
                style={{ fontWeight: 400 }}
              />
            ),
          }}
        />
      </lotPagesLayout.InfoText>
    </lotPagesLayout.Section>
  );
};

const OverviewSection = () => {
  const { t } = useTranslation('translation');
  const { summary } = rfx.useStructure<Live>();

  return (
    <lotPagesLayout.Section heading={t('request.summary.overview')}>
      <Box mt="12px">
        <Clamp2 lines={3}>
          {summary.description}
        </Clamp2>
      </Box>
    </lotPagesLayout.Section>
  );
};

const ProductsAndServicesSection = () => {
  const { t } = useTranslation('translation');
  const { summary } = rfx.useStructure<Live>();

  return isEmpty(summary.productsAndServices) ? (
    null
  ) : (
    <lotPagesLayout.Section heading={t('productsAndServices.productsAndServices')}>
      <ViewProductsPanel
        // @ts-expect-error ts(2322) FIXME: Type 'ProductTag[] | undefined' is not assignable to type 'ProductTag[]'.
        productsAndServices={summary.productsAndServices}
        panelProps={{
          mt: '12px',
          height: 'auto',
          overflowY: 'clip',
          sx: { borderBottom: 0 },
        }}
      />
    </lotPagesLayout.Section>
  );
};

const LotsSection = () => {
  const { t } = useTranslation('translation');
  const { lots } = rfx.useStructure<Live>();
  const bid = rfx.useBid();
  const navigation = useRequestRecipientNavigation();

  const [showInactiveLots, setShowInactiveLots] = useState<boolean>(false);

  const {
    inactiveLotCount,
    nonObsoleteLotCount,
    areAllLotsInactive,
  } = useLotsInfo();

  return (
    <lotPagesLayout.Section heading={t('request.lot_other')}>
      {inactiveLotCount > 0 && areAllLotsInactive ? (
        <InactiveLotsToggle
          inactiveLotCount={inactiveLotCount}
          setShowInactiveLots={setShowInactiveLots}
          showInactiveLots={showInactiveLots}
          areAllLotsInactive
        />
      ) : (
        <Text mt="12px">
          <Trans
            i18nKey="request.lots.lotSupplierDescription"
            ns="translation"
            values={{ count: nonObsoleteLotCount }}
            components={{
              a: (
                <lotPagesLayout.TanstackInlineLink
                  {...navigation.getBidLinkProps()}
                />
              ),
            }}
          />
        </Text>
      )}
      <Text mt="12px" pb="6px">
        <Disclosure2
          width="100%"
          summary={t('request.lots.aboutLotsExplainerHeading')}
        >
          {t('request.lots.aboutLotsExplainerBody')}
        </Disclosure2>
      </Text>
      <lotPagesLayout.OrderedListContainer>
        {lots.map((lot, index) => {
          const lotIntentionStatus = bid.intentionStatusByLotId[lot._id] || LotIntentionStatus.NO_RESPONSE;

          const isInactive = isLotInactive(lot, lotIntentionStatus);

          return showInactiveLots || !isInactive ? (
            <lotPagesLayout.TitledListItem
              key={lot._id}
              // @ts-expect-error ts(2322) FIXME: Type '"ban" | null' is not assignable to type '"function" | "columns" | "sort" | "filter" | "download" | "code" | "link" | "search" | "table" | "circle" | "text" | "circle-dashed" | "square" | "angle-left" | "angle-right" | ... 188 more ... | undefined'.
              icon={lot.isObsolete ? 'ban' : null}
              title={`${t('request.lot_one')} ${index + 1} – ${lot.name}`}
              titleColor={isInactive ? 'subtext' : 'text'}
              body={
                <Box color={isInactive ? 'subtext' : 'text'}>
                  <Clamp2 lines={2}>
                    {lot.description}
                  </Clamp2>
                </Box>
              }
              pt={3}
              pb={3}
            />
          ) : (
            null
          );
        })}
      </lotPagesLayout.OrderedListContainer>
      {inactiveLotCount > 0 && !areAllLotsInactive && (
        <InactiveLotsToggle
          inactiveLotCount={inactiveLotCount}
          setShowInactiveLots={setShowInactiveLots}
          showInactiveLots={showInactiveLots}
        />
      )}
    </lotPagesLayout.Section>
  );
};

const AwardScenariosSection = () => {
  const { t } = useTranslation('translation');
  const { settings } = rfx.useStructure<Live>();

  const selectedAwardScenarios = orderedAwardScenarios
    .filter(awardScenario => settings.awardScenarios[awardScenario]);

  return isEmpty(selectedAwardScenarios) ? (
    null
  ) : (
    <lotPagesLayout.Section heading={t('request.summary.awardScenario_other')}>
      <Text mt="12px" pb="6px">
        {t('request.summary.awardScenariosSupplierDescription')}
      </Text>
      <lotPagesLayout.OrderedListContainer>
        {selectedAwardScenarios.map(awardScenario => {
          const { icon, regular } = awardScenarioIconProps[awardScenario];

          return (
            <lotPagesLayout.TitledListItem
              icon={icon}
              isIconRegular={regular}
              key={awardScenario}
              title={t(`request.awardScenarios.awardScenarios.${awardScenario}.label`)}
              body={awardScenario === AwardScenario.LINE_LEVEL_AWARD && settings.canSplitAwards ? (
                t('request.awardScenarios.awardScenarios.lineLevelAward.splitDescription')
              ) : (
                t(`request.awardScenarios.awardScenarios.${awardScenario}.description`)
              )}
              pt={3}
              pb={3}
            />
          );
        })}
      </lotPagesLayout.OrderedListContainer>
    </lotPagesLayout.Section>
  );
};

const HelpAndSupportSection = () => {
  const { t } = useTranslation('translation');
  const navigation = useRequestRecipientNavigation();

  return (
    <lotPagesLayout.Section heading={t('request.helpAndSupport.helpAndSupport')}>
      <Text mt="12px" mb="20px">
        {t('request.helpAndSupport.helpAndSupportDescription')}
      </Text>
      <lotPagesLayout.DescriptionListContainer style={{ gridTemplateColumns: '100%', gridGap: '6px' }}>
        <dt>
          <lotPagesLayout.TanstackInlineLink
            target="_blank"
            to={process.env.NX_HELP_CENTER_URL as any}
          >
            {t('request.helpAndSupport.knowledgeBaseTitle')}
          </lotPagesLayout.TanstackInlineLink>
        </dt>
        <dd>
          {t('request.helpAndSupport.knowledgeBaseDescription')}
        </dd>
        <dt>
          <lotPagesLayout.TanstackInlineLink
            {...navigation.getMessagesLinkProps()}
          >
            {t('request.helpAndSupport.messagesPageTitle')}
          </lotPagesLayout.TanstackInlineLink>
        </dt>
        <dd>
          {t('request.helpAndSupport.messagesPageDescription')}
        </dd>
        <dt>
          {t('request.helpAndSupport.techSupportTitle')}
        </dt>
        <dd>
          <Trans
            i18nKey="request.helpAndSupport.techSupportDescription"
            ns="translation"
            components={{
              a: (
                <lotPagesLayout.TanstackInlineLink
                  {...navigation.getMessagesLinkProps()}
                />
              ),
            }}
          />
        </dd>
      </lotPagesLayout.DescriptionListContainer>
    </lotPagesLayout.Section>
  );
};

const RequestRecipientIndexContent = () => {
  const { lots } = rfx.useStructure<Live>();
  const { areAllLotsInactive } = useLotsInfo();
  const currentCompanyGroup = rfx.useCurrentCompanyGroup();

  return (
    <lotPagesLayout.ContentWrapper>
      {currentCompanyGroup === 'supplier' && <DetailsSection />}
      <MenuSection />
      <StatusSection />
      {currentCompanyGroup === 'supplier' && (
        <>
          <OverviewSection />
          <ProductsAndServicesSection />
          {!isEmpty(lots) && !areAllLotsInactive && (
            <LotsSection />
          )}
          <AwardScenariosSection />
          <HelpAndSupportSection />
          {!isEmpty(lots) && areAllLotsInactive && (
            <LotsSection />
          )}
        </>
      )}
    </lotPagesLayout.ContentWrapper>
  );
};

/*
* Recipient-specific request pages, level 1.
*
* Renders the root-level request overview.
*/
export const RequestRecipientIndex = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const recipientId = useRecipientId();
  const rfqId = useRfqId();
  const { t } = useTranslation('translation');

  const isRecipient = currentCompanyId === recipientId;

  const { data: structure, isLoading, isError, isSuccess } =
    useLiveRfqStructure({ rfqId, recipientId, currentCompanyId });

  const ref = useNotificationSubject({
    filter: conforms<Partial<Notification>>({
      domain: domain => domain === (isRecipient ? NotificationDomain.RFQ_RECEIVED : NotificationDomain.RFQ_SENT),
      action: action => [
        NotificationAction.RFQ_CREATED,
        NotificationAction.RFQ_UPDATED,
      ].includes(action),
      meta: isRecipient
        ? meta => meta.rfqId === rfqId
        : meta => meta.rfqId === rfqId && meta.recipientId === recipientId,
      to: to => to.companyId === currentCompanyId,
    }),
  });

  return isLoading ? (
    <LoadingPanel />
  ) : isError ? (
    <ErrorPanel error={t('errors.unexpected')} />
  ) : isSuccess && structure ? (
    <div ref={ref}>
      <rfx.StructureProvider structure={structure}>
        <rfx.StateProvider isLive>
          <RequestRecipientIndexContent />
        </rfx.StateProvider>
      </rfx.StructureProvider>
    </div>
  ) : (
    null
  );
};
