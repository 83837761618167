import { Flex, Text } from 'rebass/styled-components';
import { TFunction } from 'i18next';
import { getRegionName } from '@deepstream/common';
import { Address } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { SelectField, SelectFieldProps } from '../../form/SelectField';
import { CompanyLogo } from '../../CompanyLogo';
import { useCurrentUserLocale } from '../../useCurrentUser';

export type CompanyItem = {
  label: string;
  value: string;
  address: Address;
};

const renderButtonLabel = (item: CompanyItem) => (
  <Flex alignItems="center" mr={1} width="100%">
    <CompanyLogo size="sm" companyId={item.value} />
    <Text textAlign="start">
      <Truncate>
        {item.label}
      </Truncate>
    </Text>
  </Flex>
);

/**
 * Note: the `t` function must contain the `general` namespace.
 */
export const renderCompanyItemLabel = (item: CompanyItem, locale: string, t: TFunction) => (
  <Flex alignItems="center">
    <CompanyLogo size="sm" companyId={item.value} />
    <Stack gap={0} flex={1}>
      <Truncate>
        {item.label}
      </Truncate>
      <Truncate color="subtext">
        {/*
         // @ts-expect-error ts(2345) FIXME: Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type 'string | undefined'. */}
        {getRegionName(item.address?.country, locale, t('unknown', { ns: 'general' }))}
      </Truncate>
    </Stack>
  </Flex>
);

type CompanySelectFieldProps = Omit<SelectFieldProps, 'items'>;

export const CompanySelectField = ({ items, ...props }: CompanySelectFieldProps & { items: CompanyItem[] }) => {
  const { t } = useTranslation('general');
  const locale = useCurrentUserLocale();

  return (
    <SelectField
      getButtonLabel={renderButtonLabel}
      getItemLabel={item => renderCompanyItemLabel(item, locale, t)}
      items={items}
      maxHeight="140px"
      buttonStyle={{ height: '40px' }}
      required
      canDeselect
      {...props}
    />
  );
};
