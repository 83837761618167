import { useMemo } from 'react';
import { filter, find, first, flatMap, map, mapValues, set } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Form, Formik, useField } from 'formik';
import * as yup from 'yup';
import { Flex, Box, Text } from 'rebass/styled-components';
import {
  CurrencyExchangeDefinition,
  TermsExchangeDefinition,
  InclusionsExchangeDefinition,
  HirePeriodExchangeDefinition,
  HirePeriod,
  FeesExchangeDefinition,
  isCurrencyExchangeDef,
  isInclusionExchangeDef,
  isTermExchangeDef,
  isFeeExchangeDef,
  Draft,
  RfxVesselPricingSection,
} from '@deepstream/common/rfq-utils';
import { callAll } from '@deepstream/utils/callAll';
import { CancelButton, SaveButton } from '@deepstream/ui-kit/elements/button/Button';
import { PanelHeader, Panel, PanelDivider, PanelPadding, ExpandablePanelSubSection } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import * as draft from './draft';
import * as rfx from '../rfx';
import { useCurrencySelectItems } from '../ui/currencies';
import { useStageName } from './useStageName';
import { useStageSelectItems } from './useStageSelectItems';
import { SelectFieldBase, SelectField } from '../form/SelectField';
import { Tab, TabListPanel, Tabs, TabPanel, TabPanels } from '../ui/TabsVertical';
import { SidebarLayout } from '../ui/ProfileLayout';
import { SwitchField } from '../form/SwitchField';
import { InclusionTableField } from './InclusionTable';
import { TermTableField } from './TermTable';
import { EditDraftHirePeriodSections } from './DraftHirePeriodSections';
import { DraftDeliverySection } from './DraftDeliverySection';
import { DraftPanel } from './DraftPanel';
import { usePreventEnterKeyHandler } from '../usePreventEnterKeyHandler';
import { SectionConfigHeading } from './SectionConfigHeading';
import { VesselPricingSectionConfigIndicators } from './VesselPricingSectionConfigIndicators';
import { DraftSectionLabelConfigProvider } from './DraftSectionLabelConfigProvider';
import { LockField } from './LockField';
import { DraftSectionEditPanelHeader } from './DraftSectionEditPanelHeader';
import { LeavePageModal } from './LeavePageModal';
import { NO_LOT_PROXY_ID, useLotSelectItems } from './useLotSelectItems';
import { SectionConfigLotSelectField } from './SectionConfigLotSelectField';
import { FeeTableField } from './FeeTable';
import { useHandleVesselPricingSectionLotChange } from './useHandleVesselPricingSectionLotChange';

type VesselPricingFormValues = {
  section: RfxVesselPricingSection;
  currencyExchangeDef: CurrencyExchangeDefinition;
  inclusionExchangeDefs: InclusionsExchangeDefinition[];
  termExchangeDefs: TermsExchangeDefinition[];
  hirePeriods: HirePeriod[];
  intervalsByHirePeriodId: Record<string, HirePeriodExchangeDefinition[]>;
  feeExchangeDefs: FeesExchangeDefinition[];
};

const CollapsedEditSectionConfig = () => {
  const { settings } = rfx.useStructure<Draft>();
  const [{ value: stageIds }] = useField<string[]>({ name: 'section.stages' });
  const [{ value: locking }] = useField('section.locking');
  const [{ value: allowSupplierInclusions }] = useField('section.allowSuppliersToAddInclusionsAndExclusions');
  const [{ value: allowSupplierTerms }] = useField('section.allowSuppliersToAddAdditionalTerms');
  const [{ value: allowSupplierFees }] = useField('section.allowSuppliersToAddFees');
  const [{ value: currencyExchangeDef }] = useField({ name: 'currencyExchangeDef' });
  const [{ value: lotIds }] = useField<string[] | null>('section.lotIds');

  const currencyCode = currencyExchangeDef.currencies[0];
  const stageId = first<string>(stageIds);
  const stageName = useStageName(stageId);

  const lotSelectItems = useLotSelectItems();
  const selectedLot = settings.areLotsEnabled
    ? find(lotSelectItems, { value: first(lotIds) || NO_LOT_PROXY_ID })
    : null;

  return (
    <VesselPricingSectionConfigIndicators
      isSender
      // @ts-expect-error ts(2322) FIXME: Type 'string | number | null' is not assignable to type 'string | number | undefined'.
      stageName={stageName}
      locking={locking}
      allowSupplierInclusions={allowSupplierInclusions}
      allowSupplierTerms={allowSupplierTerms}
      allowSupplierFees={allowSupplierFees}
      currencyCode={currencyCode}
      // @ts-expect-error ts(2322) FIXME: Type 'LotSelectItem | null | undefined' is not assignable to type 'LotSelectItem | undefined'.
      selectedLot={selectedLot}
    />
  );
};

const ExpandedEditSectionConfig = () => {
  const { t } = useTranslation('translation');
  const { settings } = rfx.useStructure<Draft>();
  const currencySelectItems = useCurrencySelectItems();
  const senders = rfx.useSenders();
  const section = rfx.useSection<RfxVesselPricingSection>();
  const stageSelectItems = useStageSelectItems();
  const allStages = rfx.useStages();
  const [{ value: stageIds }, , stagesFormik] = useField<string[]>({ name: 'section.stages' });
  const [{ value: isSectionObsolete }] = useField<boolean | null>('section.isObsolete');
  const [{ value: currencyExchangeDef }, , currencyExchangeDefFormik] = useField({ name: 'currencyExchangeDef' });
  const [{ value: inclusionExchangeDefs }, , inclusionExchangeDefsFormik] = useField({ name: 'inclusionExchangeDefs' });
  const [{ value: termExchangeDefs }, , termExchangeDefsFormik] = useField({ name: 'termExchangeDefs' });
  const [{ value: intervalsByHirePeriodId }, , intervalsByHirePeriodIdFormik] = useField({ name: 'intervalsByHirePeriodId' });
  const [{ value: feeExchangeDefs }, , feeExchangeDefsFormik] = useField({ name: 'feeExchangeDefs' });
  const handleLotChange = useHandleVesselPricingSectionLotChange();

  const stageId = first<string>(stageIds);

  return (
    <DraftSectionLabelConfigProvider>
      {settings.areLotsEnabled && (
        <>
          <PanelPadding>
            <SectionConfigLotSelectField
              name="section.lotIds"
              canSelectObsoleteItem={section.isLive}
              onChange={handleLotChange}
            />
          </PanelPadding>
          {!isSectionObsolete && (
            <PanelDivider />
          )}
        </>
      )}
      {!isSectionObsolete && stageSelectItems.length > 1 ? (
        <>
          <PanelPadding>
            <SelectFieldBase
              label={t('request.visibleFromStage')}
              description={t('request.visibleFromStageDescription')}
              items={stageSelectItems}
              value={stageId}
              disabled={section.isLive}
              onChange={stageId => {
                const allStageIds = map(allStages, '_id');
                const indexFrom = allStageIds.indexOf(stageId);
                const newStages = allStageIds.slice(indexFrom);

                stagesFormik.setValue(newStages);

                currencyExchangeDefFormik.setValue({
                  ...currencyExchangeDef,
                  stages: newStages,
                });
                inclusionExchangeDefsFormik.setValue(map(
                  inclusionExchangeDefs,
                  exchangeDef => ({
                    ...exchangeDef,
                    stages: newStages,
                  }),
                ));
                termExchangeDefsFormik.setValue(map(
                  termExchangeDefs,
                  exchangeDef => ({
                    ...exchangeDef,
                    stages: newStages,
                  }),
                ));
                intervalsByHirePeriodIdFormik.setValue(mapValues(
                  intervalsByHirePeriodId,
                  (intervals) => map(
                    intervals,
                    exchangeDef => ({
                      ...exchangeDef,
                      stages: newStages,
                    }),
                  ),
                ));
                feeExchangeDefsFormik.setValue(map(
                  feeExchangeDefs,
                  exchangeDef => ({
                    ...exchangeDef,
                    stages: newStages,
                  }),
                ));
              }}
              buttonStyle={{ fontWeight: 500, maxWidth: 200 }}
            />
          </PanelPadding>
          <PanelDivider />
        </>
      ) : (
        null
      )}
      {!isSectionObsolete && (
        <>
          <PanelPadding>
            <SelectField
              name="currencyExchangeDef.currencies[0]"
              label={t('request.lineItems.currency.supplierCurrency')}
              description={t('request.lineItems.currency.supplierCurrencyDescription')}
              hideError
              required
              menuWidth={270}
              placement="bottom-end"
              items={currencySelectItems}
              buttonStyle={{ fontWeight: 500, maxWidth: 200 }}
            />
          </PanelPadding>
          <PanelDivider />
          <PanelPadding>
            <LockField
              name="section.locking"
              label={t('request.lockResponses')}
              description={t('request.lockResponsesDescription')}
              senders={senders}
              isSectionLive={section.isLive}
            />
          </PanelPadding>
          <PanelDivider />
          <PanelPadding>
            <SwitchField
              name="section.allowSuppliersToAddInclusionsAndExclusions"
              label={t('request.vesselPricing.inclusions.supplierAddedInclusions')}
              description={t('request.vesselPricing.inclusions.supplierAddedInclusionsDescription')}
              checkedIcon={false}
              uncheckedIcon={false}
              width={42}
              checkedText={t('general.abstractFeatureEnabled')}
              uncheckedText={t('general.abstractFeatureDisabled')}
              disabled={section.liveVersion?.allowSuppliersToAddInclusionsAndExclusions}
              useDefaultLabelConfig={false}
            />
          </PanelPadding>
          <PanelDivider />
          <PanelPadding>
            <SwitchField
              name="section.allowSuppliersToAddAdditionalTerms"
              label={t('request.vesselPricing.terms.supplierAddedTerms')}
              description={t('request.vesselPricing.terms.supplierAddedTermsDescription')}
              checkedIcon={false}
              uncheckedIcon={false}
              width={42}
              checkedText={t('general.abstractFeatureEnabled')}
              uncheckedText={t('general.abstractFeatureDisabled')}
              disabled={section.liveVersion?.allowSuppliersToAddAdditionalTerms}
              useDefaultLabelConfig={false}
            />
          </PanelPadding>
          <PanelDivider />
          <PanelPadding>
            <SwitchField
              name="section.allowSuppliersToAddFees"
              label={t('request.vesselPricing.fees.supplierAddedFees')}
              description={t('request.vesselPricing.fees.supplierAddedFeesDescription')}
              checkedIcon={false}
              uncheckedIcon={false}
              width={42}
              checkedText={t('general.abstractFeatureEnabled')}
              uncheckedText={t('general.abstractFeatureDisabled')}
              disabled={section.liveVersion?.allowSuppliersToAddFees}
              useDefaultLabelConfig={false}
            />
          </PanelPadding>
        </>
      )}
    </DraftSectionLabelConfigProvider>
  );
};

const EditSectionConfig = () => {
  return (
    <ExpandablePanelSubSection
      heading={<SectionConfigHeading />}
      renderCollapsedContent={() => <CollapsedEditSectionConfig />}
      renderExpandedContent={() => <ExpandedEditSectionConfig />}
    />
  );
};

const InclusionsSection = ({ isSectionObsolete }: { isSectionObsolete?: boolean }) => {
  const { t } = useTranslation('translation');

  return (
    <Panel>
      <PanelHeader
        heading={t('request.vesselPricing.inclusions.inclusion_other')}
        bg="lightGray3"
      />
      <PanelDivider />
      <PanelPadding>
        <InclusionTableField fieldName="inclusionExchangeDefs" isSectionObsolete={isSectionObsolete} />
      </PanelPadding>
    </Panel>
  );
};

const TermsSection = ({ isSectionObsolete }: { isSectionObsolete?: boolean }) => {
  const { t } = useTranslation('translation');

  return (
    <Panel>
      <PanelHeader
        heading={t('request.vesselPricing.terms.additionalTerm_other')}
        bg="lightGray3"
      />
      <PanelDivider />
      <PanelPadding>
        <TermTableField fieldName="termExchangeDefs" isSectionObsolete={isSectionObsolete} />
      </PanelPadding>
    </Panel>
  );
};

const FeeSection = ({ isSectionObsolete }: { isSectionObsolete?: boolean }) => {
  const { t } = useTranslation();

  return (
    <Panel>
      <PanelHeader
        heading={t('request.vesselPricing.fees.fee_other')}
        bg="lightGray3"
      />
      <PanelDivider />
      <PanelPadding style={{ maxWidth: 570 }}>
        <FeeTableField fieldName="feeExchangeDefs" isSectionObsolete={isSectionObsolete} />
      </PanelPadding>
    </Panel>
  );
};

const VesselPricingSectionTabs = ({ isSectionObsolete }: { isSectionObsolete?: boolean }) => {
  const { t } = useTranslation('translation');

  return (
    <Tabs>
      <Box p={20}>
        <SidebarLayout
          sidebar={
            <TabListPanel panelStyle={{ height: 'fit-content' }}>
              <Tab data-test="general-tab">
                <Flex alignItems="space-between">
                  <Text flex={1}>{t('request.vesselPricing.general')}</Text>
                </Flex>
              </Tab>
              <Tab data-test="vessel-specific-tab">
                <Flex alignItems="space-between">
                  <Text flex={1}>{t('request.vesselPricing.vesselSpecific')}</Text>
                </Flex>
              </Tab>
            </TabListPanel>
          }
          main={
            <TabPanels>
              <TabPanel>
                <Stack gap="20px">
                  <MessageBlock variant="info" mt={0} width="100%">
                    {t('request.vesselPricing.generalSectionInfo')}
                  </MessageBlock>
                  <InclusionsSection isSectionObsolete={isSectionObsolete} />
                  <TermsSection isSectionObsolete={isSectionObsolete} />
                </Stack>
              </TabPanel>
              <TabPanel>
                <Stack gap="20px">
                  <MessageBlock variant="info" mt={0} width="100%">
                    {t('request.vesselPricing.vesselSpecificSectionInfo')}
                  </MessageBlock>
                  <DraftDeliverySection />
                  <EditDraftHirePeriodSections isSectionObsolete={isSectionObsolete} />
                  <FeeSection isSectionObsolete={isSectionObsolete} />
                </Stack>
              </TabPanel>
            </TabPanels>
          }
          sidebarStyle={{ maxWidth: '240px', flex: '0 0 auto', pr: '20px !important' }}
          mainStyle={{ flex: '1 1 auto' }}
        />
      </Box>
    </Tabs>
  );
};

/**
 * Displays a panel for the current vessel pricing section
 */
export const VesselPricingSectionEditPanel = () => {
  const { t } = useTranslation('translation');
  const { stopEditing } = rfx.useActions();
  const hirePeriods = rfx.useHirePeriods();
  const section = rfx.useSectionWithPosition<RfxVesselPricingSection>();
  const exchangeDefs = rfx.useSectionExchangeDefs();
  const [saveSection] = draft.useSaveVesselPricingSection();
  const onKeyDown = usePreventEnterKeyHandler();

  const currencyExchangeDef = find(exchangeDefs, isCurrencyExchangeDef)!;
  const inclusionExchangeDefs = useMemo(
    () => filter(exchangeDefs, isInclusionExchangeDef),
    [exchangeDefs],
  );
  const termExchangeDefs = useMemo(
    () => filter(exchangeDefs, isTermExchangeDef),
    [exchangeDefs],
  );
  const intervalsByHirePeriodId = rfx.useIntervalsByHirePeriodId();
  const feeExchangeDefs = useMemo(
    () => filter(exchangeDefs, isFeeExchangeDef),
    [exchangeDefs],
  );

  return (
    <Formik<VesselPricingFormValues>
      validateOnBlur
      enableReinitialize
      initialValues={{
        section,
        currencyExchangeDef,
        inclusionExchangeDefs,
        termExchangeDefs,
        hirePeriods,
        intervalsByHirePeriodId,
        feeExchangeDefs,
      }}
      validationSchema={
        yup.object().shape({
          section: yup.object().shape({
            name: yup.string(),
            description: yup.string(),
          }),
        })
      }
      onSubmit={async ({
        section,
        currencyExchangeDef,
        inclusionExchangeDefs,
        termExchangeDefs,
        intervalsByHirePeriodId,
        hirePeriods,
        feeExchangeDefs,
      }, { setSubmitting }) => {
        await saveSection({
          section,
          exchangeDefs: [
            currencyExchangeDef,
            // Make sure the exchange definitions have the same stage visibility as the section
            ...map([
              ...inclusionExchangeDefs,
              ...termExchangeDefs,
              ...flatMap(intervalsByHirePeriodId),
              ...feeExchangeDefs,
            ], exchangeDef => set(exchangeDef, 'stages', section.stages)),
          ],
          hirePeriods,
        }, {
          onSuccess: stopEditing,
          onError: () => setSubmitting(false),
        });
      }}
    >
      {({ isSubmitting, dirty, resetForm, values }) => {
        const isSectionObsolete = values.section.isObsolete;

        return (
          <Form style={{ width: '100%' }} onKeyDown={onKeyDown}>
            <DraftPanel panelId={section._id}>
              <DraftSectionEditPanelHeader icon="ship" isSectionObsolete={isSectionObsolete} />
              <PanelDivider />
              {isSectionObsolete && (
                <>
                  <MessageBlock variant="info" m="20px">
                    {t('request.sections.obsoleteSectionInfo')}
                  </MessageBlock>
                  <PanelDivider />
                </>
              )}
              <EditSectionConfig />
              <PanelDivider />
              <VesselPricingSectionTabs isSectionObsolete={isSectionObsolete} />
              <PanelDivider />
              <PanelPadding>
                <Flex justifyContent="flex-end">
                  <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
                  <SaveButton disabled={isSubmitting || !dirty} />
                </Flex>
              </PanelPadding>
            </DraftPanel>
            <LeavePageModal />
          </Form>
        );
      }}
    </Formik>
  );
};
