import { DocumentsSectionEditPanel } from './DocumentsSectionEditPanel';
import { DocumentsSectionViewPanel } from './DocumentsSectionViewPanel';
import * as draft from './draft';

/**
 * Displays a panel for the current documents section
 */
export const DocumentsSectionPanel = () => {
  const isEditing = draft.useIsEditing();

  return isEditing ? (
    <DocumentsSectionEditPanel />
  ) : (
    <DocumentsSectionViewPanel />
  );
};
