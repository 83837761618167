import { useField } from 'formik';
import { keyBy } from 'lodash';
import { useMemo } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { LocationPickerModal } from '../modules/AI/LocationPickerModal';
import { DeliveryCountries } from '../ui/DeliveryCountries';
import { useModalState } from '../ui/useModalState';
import { FieldContainer } from './FieldContainer';
import { useRegionTags } from '../useRegionTags';

export type LocationsFieldBaseProps = {
  value: string[];
  onSubmit: (locations: string[]) => void;
  expandCountryCodesToNames?: boolean;
};

export const LocationsFieldBase = ({
  value,
  onSubmit,
  expandCountryCodesToNames,
}: LocationsFieldBaseProps) => {
  const { t } = useTranslation('supplierDiscovery');
  const includeLocationsModal = useModalState();
  const regionTags = useRegionTags();

  const regionTagByCode = useMemo(
    () => keyBy(regionTags, tag => tag._id),
    [regionTags],
  );

  const regionTagByName = useMemo(
    () => keyBy(regionTags, tag => tag.name),
    [regionTags],
  );

  const countryCodes = expandCountryCodesToNames
    // @ts-expect-error ts(2339) FIXME: Property '_id' does not exist on type 'number | { name: string | undefined; _id: string; code?: string | undefined; } | (() => string) | { (): string; (locales: string | string[], options?: (NumberFormatOptions & DateTimeFormatOptions) | undefined): string; } | ... 31 more ... | { ...; }'.
    ? value.map(name => regionTagByName[name]._id)
    : value;

  return (
    <FieldContainer label={t('search.fields.location.label')}>
      {countryCodes?.length > 0 && (
        <Box pb={2}>
          <Flex sx={{ border: 'lightGray' }}>
            <DeliveryCountries
              deliveryCountries={countryCodes}
              // @ts-expect-error ts(2322) FIXME: Type '{ name: string | undefined; _id: string; code?: string | undefined; }[] | undefined' is not assignable to type 'any[]'.
              regionTags={regionTags}
            />
          </Flex>
        </Box>
      )}
      <Button
        small
        type="button"
        variant="primary-outline"
        onClick={includeLocationsModal.open}
        iconLeft="plus"
      >
        {value.length === 0 ? (
          t('search.fields.location.addLocations')
        ) : (
          t('search.fields.location.editLocations')
        )}
      </Button>
      <LocationPickerModal
        onSave={includeLocationsModal.close}
        onCancel={includeLocationsModal.close}
        isOpen={includeLocationsModal.isOpen}
        onSubmit={({ locations: countryCodes }) => {
          if (expandCountryCodesToNames) {
            onSubmit(countryCodes.map(countryCode => regionTagByCode[countryCode].name));
          } else {
            onSubmit(countryCodes);
          }

          includeLocationsModal.close();
        }}
        currentLocations={countryCodes}
      />
    </FieldContainer>
  );
};

type LocationsFieldProps = Omit<LocationsFieldBaseProps, 'value' | 'onSubmit'> & {
  name?: string;
  fieldName?: string;
};

export const LocationsField = ({
  name,
  fieldName,
  expandCountryCodesToNames,
}: LocationsFieldProps) => {
  const resolvedFieldName = fieldName || name;

  if (!fieldName && !name) {
    throw new Error('`fieldName` is required if no `name` prop included');
  }

  const [field,, formik] = useField({ name: resolvedFieldName! });

  return (
    <LocationsFieldBase
      value={field.value}
      expandCountryCodesToNames={expandCountryCodesToNames}
      onSubmit={locations => {
        formik.setValue(locations);
        formik.setTouched(true);
      }}
    />
  );
};
