import { useMemo } from 'react';
import { Box, BoxProps, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { intervalToDuration } from 'date-fns';
import { ONE_DAY, ONE_HOUR } from '@deepstream/common/constants';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { useSystemFeatureFlags } from '../systemFeatureFlags';

type StageTimerProps = BoxProps & {
  currentDate: Date;
  nextDate: Date;
  stageIndex: number;
};

export const StageTimer = ({ currentDate, nextDate, stageIndex, ...props }: StageTimerProps) => {
  const { t } = useTranslation();
  const systemFeatureFlags = useSystemFeatureFlags();

  const {
    durationText,
    isNegative,
    showWarning,
  } = useMemo(
    () => {
      const parsedCurrentDate = new Date(currentDate);
      const parsedNextDate = new Date(nextDate);

      const duration = intervalToDuration({
        start: parsedCurrentDate,
        end: parsedNextDate,
      });

      const remainingMs = parsedNextDate.valueOf() - parsedCurrentDate.valueOf();
      const remainingHours = remainingMs / ONE_HOUR;

      const isNegative = remainingMs <= 0;

      const showWarning = !isNegative && remainingHours < 24;

      const m = t('general.minuteLetter');
      const h = t('general.hourLetter');
      const d = t('general.dayLetter');

      const hours = duration.hours
        ? `${duration.hours}${h}`
        : '';

      const minutes = duration.minutes
        ? `${duration.minutes}${m}`
        : '';

      return {
        isNegative,
        showWarning,
        durationText: remainingHours < 1
          ? `${duration.minutes}${m}`
          : remainingHours < 24
            ? `${duration.hours}${h} ${minutes}`
            : `${Math.floor(remainingMs / ONE_DAY)}${d} ${hours}`,
      };
    },
    [currentDate, nextDate, t],
  );

  const tooltipContent = useMemo(
    () => isNegative
      ? String(t(
        'request.stages.deadlineErrorTooltip',
        {
          currentStageNumber: stageIndex + 1,
          nextStageNumber: stageIndex + 2,
        },
      ))
      : showWarning
        ? String(t('request.stages.deadlineWarningTooltip'))
        : '',
    [isNegative, showWarning, stageIndex, t],
  );

  const textFontSize = systemFeatureFlags?.auctionsEnabled ? 2 : undefined;
  const iconFontSize = systemFeatureFlags?.auctionsEnabled ? 1 : 2;
  const iconMarginTop = systemFeatureFlags?.auctionsEnabled ? '1px' : undefined;

  return (
    <Box {...props}>
      <Tooltip content={tooltipContent}>
        {isNegative ? (
          <Flex alignItems="center" color="danger" fontSize={textFontSize}>
            <Icon icon="exclamation-circle" fontSize={iconFontSize} mt={iconMarginTop} mr={1} />
            {t('request.stages.deadlineError')}
          </Flex>
        ) : (
          <Flex alignItems="center" color={showWarning ? 'warning' : 'subtext'} fontSize={textFontSize}>
            {showWarning && <Icon icon="exclamation-triangle" fontSize={iconFontSize} mt={iconMarginTop} mr={1} />}
            <Text mr={1}>{durationText}</Text>
            <Text>{t('request.stages.toNextStage')}</Text>
          </Flex>
        )}
      </Tooltip>
    </Box>
  );
};
