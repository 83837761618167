import { useTranslation } from 'react-i18next';
import { Flex, Text, Heading } from 'rebass/styled-components';
import { CompanyMinimized } from '@deepstream/common/rfq-utils';
import { useDeviceSize } from '../../../ui/useDeviceSize';
import { Tab, TabListPanel, TabPanels, TabPanel, Tabs } from '../../../ui/TabsVertical';
import { SidebarLayout } from '../../../ui/ProfileLayout';
import { useContractData, useIsSender } from '../contract';
import { ContractTeamUsersPanel } from './ContractTeamUsersPanel';

const TeamTabPanel = ({ company }: { company: CompanyMinimized }) => (
  <TabPanel>
    <Heading fontWeight={500} fontSize={5}>{company.name}</Heading>
    <ContractTeamUsersPanel
      company={company}
      mt={4}
    />
  </TabPanel>
);

export const ContractTeam = () => {
  const { t } = useTranslation(['contracts', 'general']);
  const { isExtraSmall, isSmall } = useDeviceSize();
  const isSender = useIsSender();
  const { senders, recipients } = useContractData();

  const companies = isSender ? senders : recipients;

  return (
    <Tabs defaultIndex={0}>
      <SidebarLayout
        sidebar={
          <TabListPanel heading={t('company', { ns: 'general' })}>
            {companies.map(company => (
              <Tab key={company._id} data-test={company._id}>
                <Flex alignItems="center">
                  <Text flex={1}>{company.name}</Text>
                </Flex>
              </Tab>
            ))}
          </TabListPanel>
        }
        main={
          <TabPanels>
            {companies.map(company => (
              <TeamTabPanel key={company._id} company={company} />
            ))}
          </TabPanels>
        }
        sidebarStyle={!isExtraSmall && !isSmall ? { maxWidth: '363px', flex: '0 0 auto' } : undefined}
        mainStyle={!isExtraSmall && !isSmall ? { maxWidth: '763px', flex: '0 0 auto' } : undefined}
      />
    </Tabs>
  );
};
