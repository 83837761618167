import { useState, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { find, findLast, isEmpty } from 'lodash';
import { Flex, Box } from 'rebass/styled-components';
import { ActionSubtype, ActionType, ExchangeStatus, ExchangeType, InclusionOption } from '@deepstream/common/rfq-utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ContractStatus } from '@deepstream/common/contract';
import { useSearch } from '@tanstack/react-router';
import { useCurrentCompanyId } from '../currentCompanyId';
import { getContractExchangeSnapshotStatus, getExchangeSnapshotStatus } from '../exchangeStatus';
import { useBuyerDocumentModalColumns, useExchangeColumns, useLineItemExchangeModalSectionColumns } from '../modules/Exchange/columns';
import { usePossibleActions } from '../modules/Exchange/usePossibleActions';
import { ExchangeHistoryAction, ExchangeSnapshot } from '../types';
import { ContextType, useHooks } from '../useHooks';
import { InlineExchangeReply } from '../modules/Exchange/InlineExchangeReply';
import { ExchangeFieldStatusIcon } from './ExchangeFieldStatusIcon';
import * as modal from './layout';
import { StandaloneExchangeFields } from '../ExchangeTable';
import { useCurrentUser } from '../useCurrentUser';
import { canESignContract } from '../modules/Contracts/contract';
import { useMutation } from '../useMutation';
import { useApi } from '../api';
import { useToaster } from '../toast';

export const BuyerFieldsSection = ({
  exchange,
}: {
  exchange: ExchangeSnapshot;
}) => {
  const { t } = useTranslation(['translation', 'contracts', 'general']);
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const {
    contextType,
    usePagePermissions,
    useRecipients,
    useAvailableBulletins,
    useExchangeDefById,
    useContextId,
    useStatus,
    useIsSuperUserOrOwner,
  } = useHooks();
  const pagePermissions = usePagePermissions();
  const recipients = useRecipients();
  const hasAvailableBulletins = !!useAvailableBulletins(exchange)?.length;
  const exchangeDefById = useExchangeDefById();
  const currentUser = useCurrentUser();
  const api = useApi();
  const toaster = useToaster();
  const contextId = useContextId();
  const status = useStatus();
  const search = useSearch({ strict: false }) as { from?: 'verified' };
  const isSuperUserOrOwner = useIsSuperUserOrOwner();
  const exchangeColumns = useExchangeColumns();
  const lineItemExchangeColumns = useLineItemExchangeModalSectionColumns(exchange, 'buyer');
  const documentExchangeColumns = useBuyerDocumentModalColumns(exchange);

  const { actions, clearResponseWithObsoleteAction, clearResponse } = usePossibleActions(exchange);
  const [isReplyFormOpen, setIsReplyFormOpen] = useState(null);
  const [hasSentReminder, setHasSentReminder] = useState(false);

  const isRecipient = useMemo(
    () => {
      const recipientIds = recipients.map(recipient => recipient._id);

      return recipientIds.includes(currentCompanyId);
    },
    [recipients, currentCompanyId],
  );

  // Questionnaire exchanges don't have a publisherId, but have a `creatorId` instead.
  const isBuyerAdded = useMemo(
    () => !recipients.some(recipient => recipient._id === (exchange.def.publisherId || exchange.def.creatorId)),
    [recipients, exchange.def.publisherId, exchange.def.creatorId],
  );

  const exchangeStatus = useMemo(
    () => contextType === ContextType.CONTRACT
      ? getContractExchangeSnapshotStatus(
        exchange,
        currentCompanyId,
        exchangeDefById,
        currentUser._id,
        status as ContractStatus,
        isSuperUserOrOwner,
      )
      : getExchangeSnapshotStatus(exchange, pagePermissions),
    [exchange, pagePermissions, currentCompanyId, exchangeDefById, contextType, currentUser, status, isSuperUserOrOwner],
  );

  const isBuyerActionRequired = useMemo(
    () => (
      exchangeStatus === ExchangeStatus.WAITING_FOR_BUYER ||
      (!isRecipient && exchangeStatus === ExchangeStatus.ACTION_REQUIRED)
    ),
    [exchangeStatus, isRecipient],
  );

  // NB when creating a new chat / clarification, we immediately open the respective
  // exchange modal. At that point, the newly created exchangeDef might not have
  // arrived from the server so we need to access `exchange.def` instead of
  // `exchangeDef`.
  const isExchangeClarification = exchange.def.type === ExchangeType.CLARIFICATION;
  const canESign = contextType === ContextType.CONTRACT && canESignContract(currentUser._id, exchange, status as ContractStatus);
  const canTakeAction = !isRecipient && (actions.length > 0 || canESign);
  const isExchangeComplete = exchangeStatus === ExchangeStatus.COMPLETE || exchangeStatus === ExchangeStatus.SIGNED;
  const isFromVerified = search.from === 'verified';
  const shouldFormBeOpen = canTakeAction && !isExchangeComplete && !isFromVerified;
  const canSendReminder = (
    !isRecipient && exchange.isAwaitingCounterESignature && !canESign && status === ContractStatus.NEGOTIATION
  );
  const canRequireMoreInfo = Boolean(find(actions, action => action.type === ActionType.REQUIRE_MORE_INFO));
  const canRespond = canTakeAction && (!isExchangeComplete || canRequireMoreInfo) && !isExchangeClarification;
  const canEditResponse = canTakeAction && isExchangeComplete && !canRequireMoreInfo && !isExchangeClarification;

  useEffect(
    () => {
      setIsReplyFormOpen(null);
    },
    [exchange._id],
  );

  useEffect(
    () => {
      if (shouldFormBeOpen && isReplyFormOpen === null) {
        // @ts-expect-error ts(2345) FIXME: Argument of type 'true' is not assignable to parameter of type 'SetStateAction<null>'.
        setIsReplyFormOpen(true);
      } else if (actions.length === 0 && !canESign && isReplyFormOpen) {
        // @ts-expect-error ts(2345) FIXME: Argument of type 'false' is not assignable to parameter of type 'SetStateAction<null>'.
        setIsReplyFormOpen(false);
      }
    },
    [shouldFormBeOpen, isReplyFormOpen, actions, canESign],
  );

  const [sendESignatureReminderMutation] = useMutation(
    api.sendContractESignatureReminder,
    {
      onSuccess: () => toaster.success(t('toaster.reminderSent.success', { ns: 'contracts' })),
      onError: () => toaster.error(t('toaster.reminderSent.failed', { ns: 'contracts' })),
    },
  );

  const sendESignatureReminder = useCallback(
    () => {
      setHasSentReminder(true);
      sendESignatureReminderMutation({
        companyId: currentCompanyId,
        contractId: contextId,
        exchangeId: exchange._id,
      });
    },
    [currentCompanyId, contextId, exchange, sendESignatureReminderMutation],
  );

  const columns = useMemo(() => {
    const columns = [];

    // @ts-expect-error ts(2345) FIXME: Argument of type 'any' is not assignable to parameter of type 'never'.
    columns.push(...lineItemExchangeColumns);
    columns.push(...documentExchangeColumns);

    if (exchange.def.type === ExchangeType.QUESTION && isBuyerAdded) {
      const lastActionType = findLast(
        exchange.history,
        ({ type }) => type !== ActionType.NONE,
      )?.type as ActionType;

      const moreInfoRequired = lastActionType === ActionType.REQUIRE_MORE_INFO;

      // @ts-expect-error ts(2345) FIXME: Argument of type '{ id: string; Header: string; Cell: () => Element; } | { id: string; Header: any; accessor: (exchange: ExchangeSnapshot) => any; Cell: FC<CellProps<any>>; } | { ...; }' is not assignable to parameter of type 'never'.
      columns.push(...exchangeColumns.questionModal({ moreInfoRequired }));
    } else if (exchange.isClarification) {
      const remainingBulletins = [];
      exchange.history.forEach((action) => {
        if (action.type === ActionType.REFER_TO_BULLETIN) {
          // @ts-expect-error ts(2345) FIXME: Argument of type 'any' is not assignable to parameter of type 'never'.
          remainingBulletins.push(action);
        }
        if (action.type === ActionType.OBSOLETE_ACTION && action.subtype === ActionSubtype.REFER_TO_BULLETIN) {
          remainingBulletins.pop();
        }
      });
      if (hasAvailableBulletins) {
        // @ts-expect-error ts(2345) FIXME: Argument of type '{}' is not assignable to parameter of type 'never'.
        remainingBulletins.push({});
      }
      // @ts-expect-error ts(2345) FIXME: Argument of type 'any' is not assignable to parameter of type 'never'.
      columns.push(...exchangeColumns.clarificationModal(remainingBulletins));
    } else if (exchange.def.type === ExchangeType.INCLUSIONS && isBuyerAdded) {
      if (exchange.def.option !== InclusionOption.FLEXIBLE) {
        // @ts-expect-error ts(2345) FIXME: Argument of type 'any' is not assignable to parameter of type 'never'.
        columns.push(...exchangeColumns.inclusionModalDefinitionReply());
      } else {
        // @ts-expect-error ts(2345) FIXME: Argument of type 'any' is not assignable to parameter of type 'never'.
        columns.push(...exchangeColumns.inclusionModalDefinition());
      }
    } else if (exchange.def.type === ExchangeType.TERMS && isBuyerAdded) {
      // @ts-expect-error ts(2345) FIXME: Argument of type 'any' is not assignable to parameter of type 'never'.
      columns.push(...exchangeColumns.termModal());
    } else if (exchange.def.type === ExchangeType.CONTRACT) {
      const lastBuyerActionType = findLast(
        exchange.history,
        ({ companyId, type }) => type !== ActionType.NONE && companyId === exchange.def.publisherId,
      )?.type as ActionType;
      const lastSupplierActionType = findLast(
        exchange.history,
        ({ companyId, type }) => type !== ActionType.NONE && companyId === exchange.recipientId,
      )?.type as ActionType;
      const isSupplierDeviationOngoing = lastSupplierActionType === ActionType.DEVIATE;
      const recipientSigners = exchange.verified?.recipientSigners;
      const showBuyerSigners = isRecipient
        ? recipientSigners?.every(signer => signer.hasSigned)
        : Boolean(exchange.verified?.envelopeId);

      const showResponseStatus = (
        exchange.isAwaitingInitiatorApproval || [ActionType.APPROVE_DOCUMENT, ActionType.DEVIATE].includes(lastBuyerActionType)
      );

      columns.push(
        // @ts-expect-error ts(2345) FIXME: Argument of type '{ id: string; Header: string; accessor: () => string; Cell: FC<CellProps<any>>; } | { id: string; Header: string | undefined; accessor: string; Cell: (props: any) => FunctionComponentElement<any>; width: string | ... 1 more ... | undefined; sortType: string; } | { ...; } | { ...; }' is not assignable to parameter of type 'never'.
        ...exchangeColumns.buyerSectionContractModal({
          isDeviationOngoing: isSupplierDeviationOngoing,
          // @ts-expect-error ts(2322) FIXME: Type 'boolean | undefined' is not assignable to type 'boolean'.
          showSigners: showBuyerSigners,
          showResponseStatus,
          // @ts-expect-error ts(2322) FIXME: Type 'boolean | undefined' is not assignable to type 'boolean'.
          showConfiguration: exchange.isAwaitingInitialContract,
          hasBuyerAttachment: !!exchange.latestBuyerAttachment,
          isRecipient,
        }),
      );
    }

    return columns;
  }, [
    lineItemExchangeColumns,
    documentExchangeColumns,
    exchange,
    isBuyerAdded,
    exchangeColumns,
    hasAvailableBulletins,
    isRecipient,
  ]);

  const handleOnClose = () => {
    // @ts-expect-error ts(2345) FIXME: Argument of type 'false' is not assignable to parameter of type 'SetStateAction<null>'.
    setIsReplyFormOpen(false);
  };

  const handleClearResponseObsolete = () => {
    // @ts-expect-error ts(2345) FIXME: Argument of type 'false' is not assignable to parameter of type 'SetStateAction<null>'.
    setIsReplyFormOpen(false);
    clearResponseWithObsoleteAction?.();
  };

  const handleClearResponse = (selectedAction: ExchangeHistoryAction) => {
    // @ts-expect-error ts(2345) FIXME: Argument of type 'false' is not assignable to parameter of type 'SetStateAction<null>'.
    setIsReplyFormOpen(false);
    clearResponse?.(selectedAction);
  };

  const header = contextType !== ContextType.CONTRACT
    ? t('request.lineItems.deliveryDate.buyerFields')
    : isRecipient
      ? t('exchange.modal.counterpartyFields', { ns: 'contracts' })
      : t('exchange.modal.myFields', { ns: 'contracts' });

  return isEmpty(columns) ? (
    null
  ) : (
    <modal.ClosableSection
      headerIcon={isBuyerActionRequired ? (
        <ExchangeFieldStatusIcon exchange={exchange} />
      ) : (
        <Icon color="subtext" icon="share" />
      )}
      header={header}
      isHighlighted={!!isReplyFormOpen}
    >
      <Flex pt={3}>
        <Box flex={1}>
          {isReplyFormOpen ? (
            <InlineExchangeReply
              actions={actions}
              onClose={handleOnClose}
              clearResponse={clearResponse && handleClearResponse}
              canESign={canESign}
              isESignDisabled={exchange.isCountersigningDisabled}
              columns={columns}
            />
          ) : (
            <StandaloneExchangeFields
              vertical
              small
              exchange={exchange}
              columns={columns}
            />
          )}
        </Box>
        {!isReplyFormOpen && (
          <Flex minWidth="100px" flexDirection="column" sx={{ gap: 2 }} justifyContent="flex-end">
            {canRespond && (
              <Button
                sx={{ textTransform: 'capitalize' }}
                variant="primary"
                // @ts-expect-error ts(2345) FIXME: Argument of type 'true' is not assignable to parameter of type 'SetStateAction<null>'.
                onClick={() => setIsReplyFormOpen(true)}
                small
                iconLeft="reply"
              >
                {t('respond', { ns: 'general' })}
              </Button>
            )}
            {canEditResponse && (
              <Button
                sx={{ textTransform: 'capitalize' }}
                variant="secondary-outline"
                // @ts-expect-error ts(2345) FIXME: Argument of type 'true' is not assignable to parameter of type 'SetStateAction<null>'.
                onClick={() => setIsReplyFormOpen(true)}
                small
                iconLeft="pencil"
              >
                {t('edit', { ns: 'general' })}
              </Button>
            )}
            {clearResponseWithObsoleteAction && !isRecipient && !isExchangeClarification && (
              <Button
                sx={{ textTransform: 'capitalize' }}
                variant="danger-outline"
                onClick={handleClearResponseObsolete}
                small
              >
                {t('request.exchange.clearResponse', { ns: 'translation' })}
              </Button>
            )}
            {clearResponseWithObsoleteAction && !isRecipient && isExchangeClarification && (
              <Button
                variant="secondary-outline"
                onClick={handleClearResponseObsolete}
                iconLeft="trash"
                small
              >
                {t('remove', { ns: 'general' })}
              </Button>
            )}
            {canTakeAction && !isRecipient && isExchangeClarification && hasAvailableBulletins && (
              <Button
                variant="secondary-outline"
                // @ts-expect-error ts(2345) FIXME: Argument of type 'true' is not assignable to parameter of type 'SetStateAction<null>'.
                onClick={() => setIsReplyFormOpen(true)}
                small
                iconLeft="plus"
              >
                {t('add', { ns: 'general' })}
              </Button>
            )}
            {canSendReminder && (
              <Button
                variant="secondary-outline"
                onClick={sendESignatureReminder}
                small
                iconLeft="pencil"
                disabled={hasSentReminder}
              >
                {t('signature.sendReminder', { ns: 'contracts' })}
              </Button>
            )}
          </Flex>
        )}
      </Flex>
    </modal.ClosableSection>
  );
};
