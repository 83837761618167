import { Box, Flex } from 'rebass/styled-components';
import {
  Company,
} from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import {
  ColumnData,
  RowData,
} from '@deepstream/ui-kit/grid/core/utils';
import {
  RowHeight,
  rowHeightMeasurements,
} from '../rowHeight';
import { CommentCount } from '../CommentCount';
import { StatusIcon } from '../../../../ExchangeStatusCell';
import { LockedCellContainer } from './LockedCellContainer';
import { ExtendedLineItemExchangeSnapshot, GridLineItemsSection, LineItemSubRowData } from './types';

export const LockedSupplierDataCell = ({
  row,
  column,
  exchange,
  openExchangeModal,
  exchangeStatusSubcolumnWidth,
  isRowActive,
}: {
  row: RowData<LineItemSubRowData, GridLineItemsSection>;
  column: ColumnData<Company>;
  exchange: ExtendedLineItemExchangeSnapshot;
  openExchangeModal: (
    row: RowData<LineItemSubRowData, GridLineItemsSection>,
    column: ColumnData<Company>
  ) => void;
  exchangeStatusSubcolumnWidth: number;
  isRowActive: boolean;
}) => {
  const { t } = useTranslation('translation');

  const isShortHeightRow = row.height === rowHeightMeasurements[RowHeight.SHORT];

  return (
    <Flex flexDirection="row" alignItems="center" height="100%" width="100%">
      <LockedCellContainer width={exchangeStatusSubcolumnWidth}>
        <Flex
          flexDirection={isShortHeightRow ? 'row-reverse' : 'column'}
          alignItems="center"
          justifyContent={isShortHeightRow ? 'center' : 'initial'}
          sx={{ width: '100%', gap: 1 }}
        >
          <StatusIcon
            type={exchange.def.type}
            status={exchange.status}
            currentCompanyGroup="buyer"
          />
          <Box
            className="focus-descendant-primary"
            // min-width is set as 0, which makes this <div> width
            // to be smaller than its children
            sx={{ minWidth: 'auto' }}
          >
            {isRowActive ? (
              <Tooltip content={t('request.exchange.openExchange') as string}>
                <Icon
                  icon="expand"
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => openExchangeModal(row, column)}
                />
              </Tooltip>
            ) : !isShortHeightRow ? (
              <CommentCount exchange={exchange} />
            ) : (
              null
            )}
          </Box>
        </Flex>
      </LockedCellContainer>
      <Box as="span" color="subtext" p={2} sx={{ height: '100%' }}>
        <Icon icon="lock" mr={1} fontSize="12px" />
        {t('general.locked')}
      </Box>
    </Flex>
  );
};
