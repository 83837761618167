import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BidOutcomeStatus,
} from '@deepstream/common/rfq-utils';
import { IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { iconPropsByBidOutcomeStatus } from '../Live/iconPropsByBidOutcomeStatus';

export type BidOutcomeStatusFilter = {
  value: BidOutcomeStatus;
  label: string;
  iconProps: {
    icon: IconProps['icon'];
    color: string;
    regular?: boolean;
  };
};

export const useBidOutcomeStatusFilterItems = (): BidOutcomeStatusFilter[] => {
  const { t } = useTranslation('translation');

  return useMemo(() => {
    return [
      BidOutcomeStatus.AWARDED,
      BidOutcomeStatus.UNSUCCESSFUL,
      BidOutcomeStatus.PENDING,
    ].map(bidOutcomeStatus => ({
      value: bidOutcomeStatus,
      label: t(`request.bidOutcomeStatus.${bidOutcomeStatus}.status`),
      iconProps: iconPropsByBidOutcomeStatus[bidOutcomeStatus],
    }));
  }, [t]);
};
