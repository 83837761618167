import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { last } from 'lodash';
import { Text } from 'rebass/styled-components';
import { QuestionDocument, QuestionYesNo } from '@deepstream/common/rfq-utils';
import { RfqAttachment } from './ExchangeModal/AttachmentLink';
import { ExchangeSnapshot } from './types';
import { ContextType, useHooks } from './useHooks';
import { QuestionnaireAttachment } from './modules/PreQualification/Questionnaire/QuestionnaireAttachment';

export const QuestionDocumentCell = ({
  row: { original: exchange },
  truncate = true,
}: CellProps<ExchangeSnapshot>) => {
  const { t } = useTranslation();
  const { contextType } = useHooks();

  const isQuestionnaire = contextType === ContextType.QUESTIONNAIRE;
  // @ts-expect-error ts(18048) FIXME: 'exchange.latestResponse' is possibly 'undefined'.
  const latestAttachment = last((exchange.latestResponse.value as QuestionDocument | QuestionYesNo).attachments);

  return (
    <>
      {latestAttachment ? (
        isQuestionnaire ? (
          <QuestionnaireAttachment truncate={truncate} attachment={latestAttachment} />
        ) : (
          <RfqAttachment truncate={truncate} attachment={latestAttachment} />
        )
      ) : (
        <Text color="subtext">
          {t('request.documents.noDocument')}
        </Text>
      )}
    </>
  );
};
