import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isLinkedEvaluationSection, RfxSection, SectionType } from '@deepstream/common/rfq-utils';
import { EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';
import { ConfirmDeleteDialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { EllipsisMenu, MoveUpMenuItem, MoveDownMenuItem, DeleteMenuItem, DuplicateMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { useConfirmDialog, useModalState } from '../ui/useModalState';
import * as draft from './draft';
import * as rfx from '../rfx';
import { getSectionExchangeDefs, getSizeRelevantExchangeDefCount, useModelSizeLimits } from '../modelSizeLimits';
import { ModelSizeLimitDialog, ModelSizeLimitMessages } from '../ModelSizeLimitDialog';
import { useRequestEditNavigation } from '../appNavigation';

/**
 * Controls for performing section actions.
 */
export const SectionPanelActions = ({
  onEditClick,
}: {
  onEditClick?: () => void;
}) => {
  const { t } = useTranslation();

  const { editingPanelId, isReview, isTemplatePreview } = rfx.useState();
  const isMutationLoading = draft.useIsMutationLoading();
  const section = rfx.useSectionWithPosition<RfxSection>();
  const isLinkedSection = isLinkedEvaluationSection(section as any);
  const sectionLink = draft.useLinkedSectionTarget();
  const hasLinkedEvaluationSection = !isLinkedSection && Boolean(sectionLink);
  const { startEditing } = rfx.useActions();
  const [moveSection] = draft.useMoveSection();
  const [removeSection] = draft.useRemoveSection();
  const [duplicateSection] = draft.useDuplicateSection();
  const page = rfx.usePage();
  const pagePermissions = rfx.usePagePermissions();
  const isPendingCollaborator = rfx.useIsPendingCollaborator();
  const { confirm, ...dialogProps } = useConfirmDialog();
  const structure = rfx.useStructure();
  const { maxExchangeDefCount } = useModelSizeLimits();
  const modelSizeLimitModal = useModalState();
  const [modelSizeLimitMessages, setModelSizeLimitMessages] = useState<ModelSizeLimitMessages | null>(null);
  const navigation = useRequestEditNavigation();

  const handleDuplicateClick = () => {
    const totalExchangeDefCount = getSizeRelevantExchangeDefCount(structure.exchangeDefById);
    const sectionExchangeDefCount = getSizeRelevantExchangeDefCount(
      getSectionExchangeDefs(structure.exchangeDefById, section),
    );

    if (totalExchangeDefCount + sectionExchangeDefCount > maxExchangeDefCount) {
      setModelSizeLimitMessages({
        heading: t('request.dialog.requestSizeLimit.heading'),
        title: t('request.dialog.requestSizeLimit.duplicateSection.title'),
        warning: t('request.dialog.requestSizeLimit.duplicateSection.warning'),
        body: t('request.dialog.requestSizeLimit.duplicateSection.body', {
          count: totalExchangeDefCount + sectionExchangeDefCount - maxExchangeDefCount,
        }),
      });
      modelSizeLimitModal.open();
    } else {
      duplicateSection({ sourceSectionId: section._id });
    }
  };

  const disabled = Boolean(editingPanelId || isMutationLoading);

  return pagePermissions.canEdit && !isTemplatePreview && !isPendingCollaborator ? (
    !isReview ? (
      <>
        <ButtonGroup marginBetween="-1px" ml={4}>
          <EditButton
            small
            onClick={() => {
              startEditing(section._id);
              onEditClick?.();
            }}
            disabled={disabled || section?.liveVersion?.isObsolete}
            data-test="edit-section-button"
          />
          <EllipsisMenu
            small
            disabled={disabled}
            data-test="more-section-actions-button"
            popoverProps={{
              'data-test': 'more-section-actions-menu',
            }}
          >
            <MoveUpMenuItem
              onSelect={() => moveSection(-1)}
              disabled={section.isFirst}
            />
            <MoveDownMenuItem
              onSelect={() => moveSection(+1)}
              disabled={section.isLast}
            />
            {section.type !== SectionType.VESSEL_PRICING && !isLinkedSection ? (
              <DuplicateMenuItem
                onSelect={handleDuplicateClick}
                data-test="duplicate-section-button"
              />
            ) : (
              null
            )}
            {!section.isLive ? (
              <DeleteMenuItem
                onSelect={() => confirm(() => removeSection(section._id))}
              />
            ) : (
              null
            )}
          </EllipsisMenu>
        </ButtonGroup>
        <ConfirmDeleteDialog
          heading={t('request.dialog.confirmDeleteSection.heading')}
          message={hasLinkedEvaluationSection ? t('request.dialog.confirmDeleteSection.bodyLinkedSection') : t('request.dialog.confirmDeleteSection.body')}
          {...dialogProps}
        />
        <ModelSizeLimitDialog
          modal={modelSizeLimitModal}
          messages={modelSizeLimitMessages}
        />
      </>
    ) : (
      <EditButton
        small
        variant="primary"
        type="button"
        ml={4}
        // @ts-expect-error ts(18047) FIXME: 'page' is possibly 'null'.
        onClick={() => navigation.navigateToDetailsPage(page._id)}
      />
    )
  ) : (
    null
  );
};
