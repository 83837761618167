import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { withProps } from '@deepstream/ui-utils/withProps';
import { BasicTableStyles } from './TableStyles';
import { Table } from './Table';
import { DateFieldValue, FieldsCell, MIN_CELL_HEIGHT } from './FieldsCell';
import { RequestTemplateActionButtons, RequestsPageSearch } from './RequestTemplateActionButtons';
import { DashboardTemplate } from './types';
import { useLocalStorageState } from './useLocalStorageState';
import { useCurrentCompanyId } from './currentCompanyId';
import { useCurrentUser } from './useCurrentUser';
import { useRequestsNavigation } from './RequestsNavigationContext';
import { useSearch } from './tanstackRouter';

export const TemplatesTable = ({
  templates,
  openDeleteModal,
}: {
  templates: DashboardTemplate[];
  openDeleteModal: (template: DashboardTemplate) => void;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();
  const { navigateToTemplatePreview } = useRequestsNavigation();
  const search = useSearch({ strict: false }) as RequestsPageSearch;

  const columns = useMemo(
    () => [{ id: 'custom' }],
    [],
  );

  const onRowClick = useCallback((row) => {
    navigateToTemplatePreview(row._id);
  }, [navigateToTemplatePreview]);

  const CustomRowCells = useMemo(
    () => {
      const fields = [
        {
          label: t('requests.creator'),
          accessor: 'meta.createdBy.name',
        },
        {
          label: t('requests.created'),
          accessor: 'meta.createdAt',
          FieldValueComponent: DateFieldValue,
        },
        {
          label: t('requests.updated'),
          accessor: 'meta.lastEditedAt',
          FieldValueComponent: DateFieldValue,
        },
      ];

      return withProps(
        FieldsCell,
        {
          fields,
          titleAccessor: 'meta.name',
          defaultTitle: t('requests.untitledTemplate'),
          ActionsComponent: withProps(
            RequestTemplateActionButtons,
            { openDeleteModal, search },
          ),
        },
      );
    },
    [t, openDeleteModal, search],
  );

  const [initialPageSize, setInitialPageSize] = useLocalStorageState<number>({
    key: `${currentCompanyId}.${user._id}.templates.pageSize`,
    defaultValue: 10,
  });

  return (
    <BasicTableStyles hoverBackgroundColor="lightGray6" hoverCursor="default">
      <Table
        columns={columns}
        data={templates}
        isPaginated
        pageSizes={[5, 10, 20, 50, 100]}
        initialPageSize={initialPageSize}
        onPageSizeChange={setInitialPageSize}
        onRowClick={onRowClick}
        hideHeader
        CustomRowCells={CustomRowCells}
        hasStaticHeight={false}
        enforcePagination
        minCellHeight={MIN_CELL_HEIGHT}
        smallPageControls
      />
    </BasicTableStyles>
  );
};
