import { useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { isEmpty, isFinite, noop, sum } from 'lodash';
import { Form, Formik } from 'formik';
import { Live, SavingsType } from '@deepstream/common/rfq-utils';
import { localeFormatPrice } from '@deepstream/utils';
import { withProps } from '@deepstream/ui-utils/withProps';
import { calculateSpecificSavings } from '@deepstream/common/rfq-utils/spendAndSavings';
import { useMemo } from 'react';
import * as rfx from '../../../../rfx';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData, AwardFlowStepType } from '../types';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { CheckboxFieldBase } from '../../../../form/CheckboxField';
import { SavingsNotAccurate } from '../SavingsNotAccurate';
import { useCurrentUserLocale } from '../../../../useCurrentUser';
import { CurrencyCodeProvider } from '../../../../ui/Currency';
import { SpecificSavingsCalculation } from '../../Spend/TotalSavingsPanel';
import { SpecificSavingsPanelContent } from '../../Spend/SpecificSavingsPanels';
import { DefaultRow } from '../../../../PropertyList';
import { useCalculatedSavingsByType } from '../useCalculatedSavingsByType';
import { Direction } from '../../../../ui/MultiStepFlow/types';

const SpecificSavingsRow = withProps(DefaultRow, { px: 0 });

export const ConfirmSumSpecificSavingsStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (
    newData: Partial<AwardFlowData> | null,
    direction: Direction | null,
    targetStep?: AwardFlowStepType,
  ) => void,
}) => {
  const { t } = useTranslation('translation');
  const locale = useCurrentUserLocale();
  const { currencyCode } = rfx.useStructure<Live>();
  const calculatedSavingsByType = useCalculatedSavingsByType(data);

  const totalSavings = sum(
    Object.values(calculatedSavingsByType).map(item => calculateSpecificSavings(item)),
  );

  const formattedSavings = isFinite(totalSavings)
    ? localeFormatPrice(totalSavings, currencyCode, { locale, showCode: true })
    : null;

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      areTotalSavingsAccurate: z.literal(true, { errorMap: () => ({ message: t('request.awardFlow.errors.confirmCalculatedSavings') }) }),
    });

    return toFormikValidationSchema(Schema);
  }, [t]);

  return (
    <CurrencyCodeProvider code={currencyCode}>
      <Formik
        validateOnBlur
        initialValues={data.spendAndSavings}
        validationSchema={validationSchema}
        onSubmit={noop}
      >
        {({ values, errors, submitForm, validateForm, setFieldValue, setFieldTouched, dirty }) => (
          <Form>
            <lotPagesLayout.ContentWrapper>
              <StepNavigation
                onBackClick={() => submitAndNavigate(dirty ? { spendAndSavings: { ...values, calculatedSavingsByType } } : null, Direction.BACK)}
                onContinueClick={async () => {
                  const errors = await validateForm();

                  await submitForm();

                  if (isEmpty(errors)) {
                    submitAndNavigate(dirty ? { spendAndSavings: { ...values, calculatedSavingsByType } } : null, Direction.FORWARD);
                  }
                }}
              >
                <FormErrors />
                <lotPagesLayout.Section
                  heading={t('request.awardFlow.steps.confirmSumSpecificSavings.heading')}
                >
                  <Text mt="12px">
                    {t('request.awardFlow.steps.chooseSavingsMethod.options.sumSpecificSavings.description')}
                  </Text>
                  <Box mt="20px">
                    <SpecificSavingsCalculation
                      value={calculatedSavingsByType}
                    />
                  </Box>
                </lotPagesLayout.Section>
                <lotPagesLayout.Section
                  heading={t('request.awardFlow.steps.confirmSumSpecificSavings.specificSavingsHeading')}
                >
                  <Text mt="20px">
                    {t('request.awardFlow.steps.confirmSumSpecificSavings.specificSavingsDescription')}
                  </Text>
                  {Object.entries(calculatedSavingsByType || {}).map(([savingsType, savings]) => {
                    return (
                      <section key={savingsType}>
                        <lotPagesLayout.H3 mt="20px" mb={1}>
                          {t(`request.spendAndSavings.specificSaving.${savingsType}.title`)}
                        </lotPagesLayout.H3>
                        <SpecificSavingsPanelContent
                          savingsType={savingsType as SavingsType}
                          savings={savings}
                          labelWidth={220}
                          Row={SpecificSavingsRow}
                        />
                      </section>
                    );
                  })}
                </lotPagesLayout.Section>
                <lotPagesLayout.Section
                  heading={t('request.awardFlow.confirmationHeading')}
                >
                  <Box mt="12px">
                    <CheckboxFieldBase
                      fieldLabel={(
                        <Text
                          as="span"
                          fontSize={4}
                          fontWeight={500}
                          lineHeight={1.4}
                          sx={{
                            position: 'relative',
                            top: '-4px',
                          }}
                        >
                          {t('request.awardFlow.steps.confirmSumSpecificSavings.confirmCheckboxLabel', {
                            savings: formattedSavings,
                          })}
                        </Text>
                      )}
                      name="areTotalSavingsAccurate"
                      // @ts-expect-error ts(2322) FIXME: Type 'boolean | null' is not assignable to type 'boolean | undefined'.
                      value={values.areTotalSavingsAccurate}
                      error={errors?.areTotalSavingsAccurate as string | undefined}
                      onChange={async (event: any) => {
                        await setFieldValue('areTotalSavingsAccurate', Boolean(event.target.checked));
                        await setFieldTouched('areTotalSavingsAccurate', true);
                      }}
                      errorMessageStyle={{ fontSize: '14px', marginTop: '16px', marginLeft: '-24px' }}
                    />
                  </Box>
                  <SavingsNotAccurate
                    onClick={() => {
                      submitAndNavigate(dirty ? { spendAndSavings: { ...values, calculatedSavingsByType } } : null, null, AwardFlowStepType.CHOOSE_SAVINGS_METHOD);
                    }}
                  />
                </lotPagesLayout.Section>
              </StepNavigation>
            </lotPagesLayout.ContentWrapper>
          </Form>
        )}
      </Formik>
    </CurrencyCodeProvider>
  );
};
