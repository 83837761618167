import { useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';

import { IconText } from '@deepstream/ui-kit/elements/text/IconText';

import { Tab, TabListPanel } from '../../ui/TabsVertical';
import { ARCHIVE_TABS, PreQualTab } from './utils';
import { preQualificationArchiveRoute } from '../../AppRouting';

export const ArchivePageSidebar = () => {
  const { t } = useTranslation('preQualification');
  const { tab }: { tab: PreQualTab } = preQualificationArchiveRoute.useSearch();

  return (
    <Box sx={{ display: ARCHIVE_TABS.includes(tab) ? 'block' : 'none' }}>
      <TabListPanel>
        <Tab key={PreQualTab.ARCHIVE_QUESTIONS}>
          <IconText gap={2} icon="question" text={t('question', { count: 2 })} />
        </Tab>
        <Tab key={PreQualTab.ARCHIVE_QUESTIONNAIRE_TEMPLATES}>
          <IconText gap={2} icon="block-question" isIconRegular text={t('questionnaire', { count: 2 })} />
        </Tab>
      </TabListPanel>
    </Box>
  );
};
