import { Box, BoxProps, Text } from 'rebass/styled-components';
import { Required } from '../../Required';

type LabelProps = BoxProps & {
  label: string;
  required?: boolean;
};

export const Label = ({ label, hidden = false, required, children, ...props }: LabelProps) => (
  <Box as="label" {...props}>
    <Text mb={1} hidden={hidden} sx={{ fontWeight: 500, fontSize: 1 }}>
      {label}
      {required && <Required />}
    </Text>
    {children}
  </Box>
);
