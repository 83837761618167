import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Text } from 'rebass/styled-components';
import { getScrollbarSize } from '@deepstream/ui-utils/getScrollbarSize';
import { BORDER_ADJUSTMENT, DEFAULT_FROZEN_HEADER_HEIGHT } from '@deepstream/ui-kit/grid/core/constants';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { DefaultEditableGridStyles } from '@deepstream/ui-kit/grid/EditableGrid/EditableGridStyles';
import { ReadOnlyGrid } from '@deepstream/ui-kit/grid/EditableGrid/ReadOnlyGrid';
import { ACTION_COLUMN_WIDTH, DEFAULT_ROW_HEIGHT, EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { ErrorMessage } from '../../../ui/ErrorMessage';
import { useQuestionnaireTemplateExchangeDefs } from './questionnaireTemplateUtils';
import { useError } from '../../../draft/validation';
import { SimpleHeader } from '../../../ui/ExchangeDefsGrid/header';
import { QuestionDescriptionValueCell, QuestionSupplierResponseValueCell, QuestionTypeValueCell, SwitchValueCell, ValidationAwareRowNumberCell } from '../../../ui/ExchangeDefsGrid/validationAwareValueCell';

const frozenLeftColumnIds = ['rowNumber', 'description'];

export const QuestionExchangeDefsGrid = () => {
  const { t } = useTranslation();
  const exchangeDefs = useQuestionnaireTemplateExchangeDefs();
  const { error } = useError('exchangeDefs');

  const columns: EditableGridColumn[] = useMemo(() => [
    {
      _id: 'rowNumber',
      accessorKey: 'rowNumber',
      Header: SimpleHeader,
      label: '',
      ValueCell: ValidationAwareRowNumberCell,
      width: ACTION_COLUMN_WIDTH,
      fixedWidth: true,
    },
    {
      _id: 'description',
      Header: SimpleHeader,
      label: t('request.question.question', { count: 1 }),
      description: t('buyer', { ns: 'general' }),
      accessorKey: 'description',
      ValueCell: QuestionDescriptionValueCell,
      showIconWhenObsolete: true,
      width: 310,
    },
    {
      _id: 'options',
      Header: SimpleHeader,
      label: t('request.question.response', { count: 1 }),
      description: t('supplier', { count: 1, ns: 'general' }),
      accessorKey: 'options',
      ValueCell: QuestionSupplierResponseValueCell,
      width: 460,
    },
    {
      _id: 'questionType',
      Header: SimpleHeader,
      label: t('general.type'),
      accessorKey: 'questionType',
      ValueCell: QuestionTypeValueCell,
      width: 150,
    },
    {
      _id: 'isRequired',
      Header: SimpleHeader,
      label: t('request.question.required'),
      accessorKey: 'isRequired',
      ValueCell: SwitchValueCell,
      width: 150,
    },
  ], [t]);

  const maxGridHeight = (
    DEFAULT_FROZEN_HEADER_HEIGHT +
    BORDER_ADJUSTMENT +
    DEFAULT_ROW_HEIGHT * exchangeDefs.length +
    getScrollbarSize()
  );

  return (
    <PanelPadding>
      {error ? (
        <ErrorMessage fontSize={2} error={error} />
      ) : !isEmpty(exchangeDefs) ? (
        <GridIdPrefixProvider>
          <DefaultEditableGridStyles
            style={{ width: '100%', height: `min(100vh - 300px, ${maxGridHeight}px)` }}
            isReadOnly
          >
            <ReadOnlyGrid
              columns={columns}
              rowData={exchangeDefs as any}
              frozenLeftColumnIds={frozenLeftColumnIds}
            />
          </DefaultEditableGridStyles>
        </GridIdPrefixProvider>
      ) : (
        <>
          <Text color="lightNavy" fontSize={2} fontWeight={500} mb={2}>
            {t('request.question.question_other')}
          </Text>
          <Text color="subtext" fontSize={1}>
            {t('request.question.noQuestionsAdded')}
          </Text>
        </>
      )}
    </PanelPadding>
  );
};
