import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { callAll } from '@deepstream/utils/callAll';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useModalState } from '../../ui/useModalState';
import { ModalForm } from '../../ModalForm';
import { SwitchField } from '../../form/SwitchField';
import { TextField } from '../../form/TextField';
import { LabelConfig, LabelConfigProvider } from '../../LabelConfigProvider';
import { useApi } from '../../api';
import { useMutation } from '../../useMutation';
import { useToaster } from '../../toast';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useRfqId } from '../../useRfq';

const NotifyEvaluatorsModalFields = () => {
  const { t } = useTranslation('evaluation');
  const [{ value: addMessage }] = useField('addMessage');

  return (
    <>
      <LabelConfigProvider
        variant={LabelConfig.LEFT}
        width="auto"
        style={{
          addMessage: {
            fontSize: 2,
            lineHeight: '24px',
            mr: 1,
            color: 'text',
            fontWeight: 400,
          },
        }}
      >
        <SwitchField
          name="addMessage"
          label={t('comparisonGrid.notifyEvaluators.modal.fields.addMessage.label')}
          useDefaultLabelConfig={false}
        />
      </LabelConfigProvider>
      {addMessage && (
        <TextField
          name="message"
          required
          placeholder={t('comparisonGrid.notifyEvaluators.modal.fields.message.placeholder')}
          label={t('comparisonGrid.notifyEvaluators.modal.fields.message.label')}
          isMultiLine
          rows={3}
        />
      )}
    </>
  );
};

const NotifyEvaluatorsModal = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const { t } = useTranslation(['evaluation', 'translation']);
  const api = useApi();
  const toaster = useToaster();
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const [sendEvaluationReminders] = useMutation(
    api.sendEvaluationReminders,
    {
      onSuccess: callAll(
        () => toaster.success(t('comparisonGrid.notifyEvaluators.toaster.success')),
        close,
      ),
      onError: () => toaster.error(t('comparisonGrid.notifyEvaluators.toaster.error')),
    },
  );

  return (
    <ModalForm
      style={{ content: { width: '500px' } }}
      isOpen={isOpen}
      onCancel={close}
      onSubmit={async ({ addMessage, message }) => {
        await sendEvaluationReminders({
          rfqId,
          currentCompanyId,
          message: addMessage ? message : undefined,
        });
      }}
      heading={t('comparisonGrid.notifyEvaluators.modal.heading')}
      initialValues={{
        addMessage: false,
        message: '',
      }}
      disableSubmitIfNotDirty={false}
      validationSchema={yup.object().shape({
        message: yup.string().when('addMessage', {
          is: true,
          then: schema => schema.required(t('general.required', { ns: 'translation' })),
          otherwise: schema => schema.nullable(),
        }),
      })}
      submitLabel={t('comparisonGrid.notifyEvaluators.modal.submitLabel')}
    >
      <MessageBlock variant="info">
        {t('comparisonGrid.notifyEvaluators.modal.infoMessage')}
      </MessageBlock>
      <NotifyEvaluatorsModalFields />
    </ModalForm>
  );
};

export const NotifyEvaluatorsButton = (props: ButtonProps) => {
  const { t } = useTranslation('evaluation');
  const notifyEvaluatorsModal = useModalState();

  return (
    <>
      <Button
        small
        variant="secondary-outline"
        iconLeft="bell-o"
        onClick={notifyEvaluatorsModal.open}
        {...props}
      >
        {t('comparisonGrid.notifyEvaluators.buttonLabel')}
      </Button>
      {notifyEvaluatorsModal.isOpen && (
        <NotifyEvaluatorsModal {...notifyEvaluatorsModal} />
      )}
    </>
  );
};
