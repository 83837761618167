import { createContext } from 'react';
import { noop } from 'lodash';
import { LineItemsExchangeSnapshot } from '../../types';
import { ValidationErrors } from '../../draft/validation';

export type BulkSubmitContextType = {
  isLeftCollapsed: boolean;
  isRightCollapsed: boolean;
  setLeftCollapsed: (isCollapsed: boolean) => void;
  setRightCollapsed: (isCollapsed: boolean) => void;

  lineItems?: LineItemsExchangeSnapshot[];
  selectedExchange?: LineItemsExchangeSnapshot;
  selectedCurrency?: string;
  validationErrors?: ValidationErrors<{ exchangeDefs: LineItemsExchangeSnapshot[] }>;

  setSelectedExchange: (exchange: LineItemsExchangeSnapshot) => void;
  setLineItems: (lineItems: LineItemsExchangeSnapshot[]) => void;
  setSelectedCurrency: (currency: string) => void;
  setValidationErrors: (validationErrors: ValidationErrors<{ exchangeDefs: LineItemsExchangeSnapshot[] }>) => void;
};

export const BulkSubmitContext = createContext<BulkSubmitContextType>({
  isLeftCollapsed: false,
  isRightCollapsed: false,
  setLeftCollapsed: noop,
  setRightCollapsed: noop,

  setSelectedExchange: noop,
  setLineItems: noop,
  setSelectedCurrency: noop,
  setValidationErrors: noop,
});
