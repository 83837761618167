import { MouseEventHandler } from 'react';
import { PopoverData, usePopover } from './usePopover';

/**
 * @param callback The callback can be used for side-effects prior
 * to the opening of the context menu. The popover for the context
 * menu only opens when the callback returns `true`.
 */
export const useContextMenu = (
  callback: (event) => boolean,
): {
  onContextMenu: MouseEventHandler<HTMLElement>;
  popover: PopoverData;
} => {
  const popover = usePopover({ placement: 'bottom-start' });

  const onContextMenu = (event) => {
    event.preventDefault();
    const { clientX, clientY } = event;

    const showContextMenu = callback(event);

    if (showContextMenu) {
      const virtualElement = {
        getBoundingClientRect: () => ({
          width: 0,
          height: 0,
          top: clientY,
          right: clientX,
          bottom: clientY,
          left: clientX,
        }),
      };

      popover.setReferenceElement(virtualElement as any);
      popover.toggle();
      popover.update?.();
    }
  };

  return {
    onContextMenu,
    popover,
  };
};
