import { useQuery } from 'react-query';
import { Text } from 'rebass/styled-components';
import { useApi, wrap } from '../api';

export const UserNameText = ({ id: userId }: { id: string }) => {
  const api = useApi();

  const { data: user } = useQuery(['user', { userId }], wrap(api.getUser), {
    staleTime: 60 * 60 * 1000,
  });

  return <Text>{user?.name || user?.email}</Text>;
};
