import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { noop } from 'lodash';
import * as Sentry from '@sentry/react';
import { callAll } from '@deepstream/utils/callAll';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { Draft, SectionType } from '@deepstream/common/rfq-utils';
import { LegacyMultiStageLineItemsEventType } from '@deepstream/common';
import { useApi } from '../../api';
import { useMutation } from '../../useMutation';
import { useToaster } from '../../toast';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useDraftRfqStructureQueryKey, useRfqId } from '../../useRfq';
import { useWaitForRfqUnlock } from '../../useWaitForUnlock';
import * as rfx from '../../rfx';
import { useNavigate } from '../../tanstackRouter';
import { requestsIndexRoute } from '../../AppRouting';
import { useTracking } from '../../useTracking';

export const SendRequestModal = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const { t } = useTranslation(['request', 'general']);
  const { recipients, sectionById } = rfx.useStructure<Draft>();
  const api = useApi();
  const waitForRfqUnlock = useWaitForRfqUnlock();
  const toaster = useToaster();
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { trackMultiStageLineItemsEvent } = useTracking();
  const draftStructureQueryKey = useDraftRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
    isTemplate: false,
  });

  const [sendRequest, { isLoading }] = useMutation(
    (payload) => waitForRfqUnlock({
      command: async () => {
        await api.sendRequest(payload);

        if (Object.values(sectionById).some(section => {
          return (
            section.type === SectionType.LINE_ITEMS &&
            !section.liveVersion?.responseTagConfig &&
            section.responseTagConfig
          );
        })) {
          try {
            await trackMultiStageLineItemsEvent({
              eventType: LegacyMultiStageLineItemsEventType.FEATURE_ENABLED,
              entityType: 'request',
              entityId: rfqId,
            });
          } catch (error) {
            Sentry.captureException(error);
          }
        }
      },
    }),
    {
      onSuccess: callAll(
        close,
        () => toaster.success(t('toolbar.sendRequestModal.toaster.success')),
        () => queryClient.invalidateQueries(draftStructureQueryKey),
        () => navigate({ to: requestsIndexRoute.to, params: { currentCompanyId }, search: { tab: 'sent' } }),
      ),
      onError: callAll(
        () => toaster.error(t('toolbar.sendRequestModal.toaster.error')),
        close,
      ),
    },
  );

  return (
    <Dialog
      heading={t('toolbar.sendRequestModal.heading')}
      body={
        <MessageBlock variant="info" mt={0} maxWidth="470px">
          {recipients.length > 0
            ? t('toolbar.sendRequestModal.infoTextSupplierCount', {
                count: recipients.length,
              })
            : t('toolbar.sendRequestModal.infoTextPublicRequest')}
        </MessageBlock>
      }
      okButtonText={t('toolbar.sendRequestModal.sendButtonLabel')}
      okButtonVariant="primary"
      cancelButtonText={t('cancel', { ns: 'general' })}
      cancelButtonVariant="secondary"
      isOpen={isOpen}
      okButtonDisabled={isLoading}
      onOk={async () => {
        await sendRequest({
          rfqId,
          companyId: currentCompanyId,
        }).catch(noop);
      }}
      onCancel={close}
    />
  );
};
