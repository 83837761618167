import { useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';
import { Live, AwardScenario } from '@deepstream/common/rfq-utils';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { isEmpty, noop } from 'lodash';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData } from '../types';
import * as rfx from '../../../../rfx';
import { RadioField } from '../../../../form/RadioField';
import { awardScenarioIconProps } from '../../../../draft/SummaryLotsPanel';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { Direction } from '../../../../ui/MultiStepFlow/types';

const awardScenariosWithoutLots = [
  AwardScenario.REQUEST_LEVEL_AWARD,
  AwardScenario.LINE_LEVEL_AWARD,
  AwardScenario.NO_AWARD,
];

const awardScenariosWithLots = [
  AwardScenario.LOT_LEVEL_AWARD,
  AwardScenario.NO_AWARD,
];

const useAwardScenarioOptions = (data: AwardFlowData) => {
  const { t } = useTranslation('translation');
  const { settings, lots } = rfx.useStructure<Live>();
  const biddingRecipients = rfx.useSortedBiddingRecipients({ excludeUnsuccessful: true });

  const hasActiveBid = !isEmpty(biddingRecipients);

  const awardScenarios = settings.areLotsEnabled
    ? awardScenariosWithLots
    : awardScenariosWithoutLots;

  return useMemo(() => awardScenarios.map(awardScenario => {
    const iconProps = awardScenarioIconProps[awardScenario];

    const isAwardScenariosWithoutActiveBid = (
      awardScenario !== AwardScenario.NO_AWARD &&
      !hasActiveBid
    );
    const isLotScenarioWithObsoleteLots = (
      awardScenario === AwardScenario.LOT_LEVEL_AWARD &&
      lots.every(lot => lot.isObsolete)
    );
    const isLineScenarioWitoutTotalCost = (
      awardScenario === AwardScenario.LINE_LEVEL_AWARD &&
      !data.hasLineItemsWithTotalCost
    );

    const isScenarioUnavailable = (
      isAwardScenariosWithoutActiveBid ||
      isLotScenarioWithObsoleteLots ||
      isLineScenarioWitoutTotalCost
    );

    return {
      value: awardScenario,
      label: (
        <Box fontSize={4} mt="-5px" color="text">
          <Icon {...iconProps} mr="6px" mt="2px" />
          {t(`request.awardScenarios.awardScenarios.${awardScenario}.label`)}
        </Box>
      ),
      description: (
        <Box as="span" fontSize={2}>
          {isAwardScenariosWithoutActiveBid ? (
            t('request.awardFlow.steps.chooseAwardScenario.unavailableNoActiveBid')
          ) : isLotScenarioWithObsoleteLots ? (
            t('request.awardFlow.steps.chooseAwardScenario.unavailableLotsObsolete')
          ) : isLineScenarioWitoutTotalCost ? (
            t('request.awardFlow.steps.chooseAwardScenario.unavailableNoTotalCost')
          ) : (
            t(`request.awardScenarios.awardScenarios.${awardScenario}.awardDescription`)
          )}
        </Box>
      ),
      disabled: isScenarioUnavailable,
    };
  }), [awardScenarios, hasActiveBid, lots, data.hasLineItemsWithTotalCost, t]);
};

export const ChooseAwardScenarioStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (data: Partial<AwardFlowData> | null, direction: Direction) => void,
}) => {
  const { t } = useTranslation('translation');
  const { settings } = rfx.useStructure<Live>();
  const options = useAwardScenarioOptions(data);

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      awardScenario: z.string({ message: t('request.awardFlow.errors.selectAwardScenario') }),
    });

    return toFormikValidationSchema(Schema);
  }, [t]);

  return (
    <Formik
      validateOnBlur
      initialValues={{
        awardScenario: data.awardScenario,
      }}
      validationSchema={validationSchema}
      onSubmit={noop}
    >
      {({ values, submitForm, validateForm, dirty }) => (
        <Form>
          <lotPagesLayout.ContentWrapper>
            <StepNavigation
              onBackClick={() => submitAndNavigate(dirty ? values : null, Direction.BACK)}
              onContinueClick={async () => {
                const errors = await validateForm();

                await submitForm();

                if (isEmpty(errors)) {
                  submitAndNavigate(dirty ? values : null, Direction.FORWARD);
                }
              }}
            >
              <FormErrors />
              <lotPagesLayout.Section heading={t('request.awardFlow.steps.chooseAwardScenario.heading')}>
                <Box mt="20px">
                  <RadioField
                    name="awardScenario"
                    options={options}
                    showError
                    gap="20px"
                    errorMessageStyle={{ fontSize: '14px', marginTop: '16px' }}
                  />
                </Box>
                {settings.areLotsEnabled && (
                  <lotPagesLayout.InfoText mt="20px">
                    {t('request.awardFlow.steps.chooseAwardScenario.info')}
                  </lotPagesLayout.InfoText>
                )}
              </lotPagesLayout.Section>
            </StepNavigation>
          </lotPagesLayout.ContentWrapper>
        </Form>
      )}
    </Formik>
  );
};
