import { REQUEST_MAX_SUPPLIER_COUNT } from '@deepstream/common/constants';
import { Draft, ExchangeType, SectionType } from '@deepstream/common/rfq-utils';
import { compact, flatMap, isEmpty, map, uniq } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { hasSizeRelevantExchangeType, ModelSizeLimits, useModelSizeLimits } from '../../../modelSizeLimits';
import { RfxStructure } from '../../../types';
import { RfxDraftValidator } from '../RfxDraftValidator';

const useRfxValidationErrorMessages = ({
  draftStructure,
  sizeLimits,
  isRevising,
}: {
  draftStructure: RfxStructure<Draft>,
  sizeLimits: ModelSizeLimits,
  isRevising: boolean,
}) => {
  const { t } = useTranslation();
  const sections = Object.values(draftStructure.sectionById);

  const sectionsToValidate = compact([
    'summary',
    'stages',
    'pages',
    isRevising ? null : 'suppliers',
    ...map(sections, section => section._id),
  ]);

  const errorMessages = uniq(flatMap(
    sectionsToValidate,
    sectionId => new RfxDraftValidator(draftStructure).getValidationErrors(sectionId, t),
  ));

  if (Object.values(draftStructure.exchangeDefById).length === 0) {
    errorMessages.push(t('request.validation.minOneExchange'));
  }

  const exchangeDefCountForSizeLimit = Object
    .values(draftStructure.exchangeDefById)
    .filter(hasSizeRelevantExchangeType)
    .length;

  const recipientCount = draftStructure.recipients.length || 1;
  const maxSupplierCount = Math.min(
    REQUEST_MAX_SUPPLIER_COUNT,
    Math.floor(sizeLimits.maxComplexity / exchangeDefCountForSizeLimit),
  );

  // Limit line items/questions (bulk upload)
  if (recipientCount > maxSupplierCount) {
    // TODO adjust error message when specs are ready
    errorMessages.push(t('request.validation.removeSupplier', {
      count: recipientCount - maxSupplierCount,
    }));
  }

  const noSupplierUsers = draftStructure.recipients
    .map(recipient => Object.values(draftStructure.teamById[recipient._id].users))
    .some(isEmpty);

  // Allow revisions even if there are no supplier users
  if (!isRevising && noSupplierUsers) {
    errorMessages.push(t('request.validation.minOneUserPerSupplier'));
  }

  const onlyPendingSupplierUsers = draftStructure.recipients.some(recipient =>
    Object
      .values(draftStructure.teamById[recipient._id]?.users)
      .some(user =>
        Object
          .keys(user.requestedRoles ?? {})
          .some(companyId => (
            companyId === recipient._id &&
            // @ts-expect-error ts(18048) FIXME: 'user.requestedRoles' is possibly 'undefined'.
            user.requestedRoles[companyId].status === 'pending'
          )),
      ),
  );

  // Allow revisions even if there are suppliers where all users are invited
  if (!isRevising && onlyPendingSupplierUsers) {
    errorMessages.push(t('request.validation.minOneNonInvitedUserPerSupplier'));
  }

  const hasCurrencyExchangeWithoutCurrenciesSet = sections
    .filter(section => section.type === SectionType.LINE_ITEMS)
    .find(section => (
      section.exchangeDefIds
        .map(defId => draftStructure.exchangeDefById[defId])
        .find(def => def.type === ExchangeType.CURRENCY && isEmpty(def.currencies))
    ));

  if (hasCurrencyExchangeWithoutCurrenciesSet) {
    errorMessages.push(t('request.validation.minOneCurrencyPerLineItem'));
  }

  return errorMessages;
};

const RfxValidationContext = React.createContext<{
  isValid: boolean;
  errorMessages: string[];
}>({
  isValid: false,
  errorMessages: [],
});

export const useRfxValidation = () => React.useContext(RfxValidationContext);

export const RfxValidationProvider = ({
  children,
  draftStructure,
  isRevising,
}: {
  children: React.ReactNode,
  draftStructure: RfxStructure<Draft>,
  isRevising: boolean,
}) => {
  const sizeLimits = useModelSizeLimits();

  const errorMessages = useRfxValidationErrorMessages({
    draftStructure,
    sizeLimits,
    isRevising,
  });

  const value = React.useMemo(
    () => ({
      // If we're revising and the live structure hasn't loaded yet, we can't determine
      // if the request is valid yet
      isValid: isEmpty(errorMessages),
      errorMessages,
    }),
    [errorMessages],
  );

  return (
    <RfxValidationContext.Provider value={value}>
      {children}
    </RfxValidationContext.Provider>
  );
};
