import * as React from 'react';
import { noop } from 'lodash';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useIsFormDirty } from './useIsFormDirty';

export const FormButtons: React.FC<{ onCancel: any; disabled?: boolean }> = ({ onCancel = noop, disabled }) => {
  const { t } = useTranslation('general');
  const { isSubmitting } = useFormikContext();
  const isDirty = useIsFormDirty();

  return (
    <>
      <Button type="button" variant="secondary" mr={2} onClick={onCancel} disabled={disabled || isSubmitting}>
        {t('cancel')}
      </Button>
      <Button type="submit" variant="primary" disabled={disabled || isSubmitting || !isDirty}>
        {t('saveChanges')}
      </Button>
    </>
  );
};
