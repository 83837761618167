import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';
import { difference, filter, first, isEmpty, isNumber, isUndefined, some, uniq, values } from 'lodash';
import { TFunction } from 'i18next';
import { isAfter } from 'date-fns';
import {
  DocumentExchangeDefinition,
  ExchangeType,
  LineItemExchangeDefinition,
  SectionType,
  getExchangeDefFieldValue,
  isDefinitionField,
} from '@deepstream/common/rfq-utils';
import {
  Contract,
  ContractDocumentExchangeDefinition,
  ContractSignatureType,
  StartDateType,
  ExpiryDateType,
  REMINDERS_PAGE_ID,
  SUMMARY_PAGE_ID,
  ReminderType,
  ReminderRecipientCategory,
  ContractProvidedBy,
} from '@deepstream/common/contract/contract';
import { informationRequestExchangeTypes } from '@deepstream/common/exchangesConfig';
import { useQuery, useQueryClient } from 'react-query';
import { callAll } from '@deepstream/utils/callAll';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { ContractCustomMilestonePanel } from './ContractCustomMilestonePanel';
import { useContract, useContractQueryKey } from './useContract';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { Loading } from '../../ui/Loading';
import { ErrorMessage } from '../../ui/ErrorMessage';
import { DraftContractFooter } from './DraftContractFooter';
import { ContractBasicDetailsPanel } from './ContractBasicDetailsPanel';
import { ContractCounterPartyPanel } from './ContractCounterPartyPanel';
import { ContractReferenceNumbersPanel } from './ContractReferenceNumbersPanel';
import { ContractSpendDataPanel } from './ContractSpendDataPanel';
import { ContractDraftPageContent } from './ContractDraftPageContent';
import { ContractSectionPanel } from './ContractSectionPanel';

import { useApi, wrap } from '../../api';
import { optionalBuyerFieldKeys } from '../../draft/LineItemsSectionViewPanel';
import { useToaster } from '../../toast';
import { useModalState } from '../../ui/useModalState';
import { useMutation } from '../../useMutation';
import {
  ContractProvider,
  ContractPageProvider,
  ContractSectionProvider,
  useContractId,
  useContractState,
  usePagesPermissions,
  useContractData,
} from './contract';
import { ContractErrorsPanel } from './ContractErrorsPanel';
import { ContractDocumentsSectionPanel } from './ContractDocumentsSectionPanel';
import { ContractLineItemsSectionPanel } from './ContractLineItemsSectionPanel';
import { ContractProductsAndServicesPanel } from './ContractProductsAndServicesPanel';
import { IssueRevisionModal } from './IssueRevisionModal';
import { IssueAmendmentModal } from './IssueAmendmentModal';
import { ContractRemindersPanel } from './ContractRemindersPanel';
import { ModelSizeLimitsProvider } from '../../modelSizeLimits';
import { contractsRoute } from '../../AppRouting';
import { useLiveContractNavigation, useDraftContractNavigation } from '../../appNavigation';
import { ContractTemplateDetailsPanel } from './ContractTemplateDetailsPanel';

const shouldHaveAttachment = (exchangeDef: DocumentExchangeDefinition) => {
  return exchangeDef.type && !informationRequestExchangeTypes.includes(exchangeDef.type);
};

const isMissingSigners = (exchangeDef: ContractDocumentExchangeDefinition) =>
  exchangeDef.signatureType === ContractSignatureType.VERIFIED && isEmpty(exchangeDef.senderSigners);

const validateContract = (contract: Contract, t: TFunction, isAmending?: boolean): string[] => {
  const errors = [];
  const { summary, pages, sectionById, exchangeDefById, teamById, customMilestones, reminders } = contract;
  const recipient = first(contract.recipients);
  const sections = values(sectionById);

  if (!summary.name) {
    errors.push(t('review.errors.nameRequired'));
  }

  if (!summary.description) {
    errors.push(t('review.errors.overviewRequired'));
  }

  if (
    (summary.startDateType === StartDateType.EXACT_DATE && !summary.startDate) ||
    (summary.startDateType === StartDateType.TIME_BASED && !summary.startDateOffsetConfig?.amount)
  ) {
    errors.push(t('review.errors.startDateRequired'));
  }

  if (
    (summary.expiryDateType === ExpiryDateType.EXACT_DATE && !summary.expiryDate) ||
    (summary.expiryDateType === ExpiryDateType.TIME_BASED && !summary.expiryDateOffsetConfig?.amount)
  ) {
    errors.push(t('review.errors.expiryDateRequired'));
  }

  if (
    summary.startDate &&
    summary.expiryDate &&
    isAfter(new Date(summary.startDate), new Date(summary.expiryDate))
  ) {
    errors.push(t('review.errors.startAndExpiryDatesOrder'));
  }

  if (!recipient || recipient.isExternal) {
    errors.push(t('review.errors.counterPartyRequired'));
  }

  if (recipient && !recipient.isExternal && isEmpty(teamById[recipient._id].users)) {
    errors.push(t('review.errors.counterPartyUsersRequired'));
  }

  if (!isNumber(summary.spendData?.amount)) {
    errors.push(t('review.errors.amountRequired'));
  }

  if (customMilestones.some(milestone => !milestone.date)) {
    errors.push(t('review.errors.customMilestoneDateRequired'));
  }

  if (reminders.some(reminder => reminder.type === ReminderType.EXACT_DATE && !reminder.date)) {
    errors.push(t('review.errors.reminderDateRequired'));
  }

  if (reminders.some(
    reminder => reminder.recipientCategories.includes(ReminderRecipientCategory.INTERNAL) && isEmpty(reminder.recipientUserIds),
  )) {
    errors.push(t('review.errors.reminderRecipientUsersRequired'));
  }

  const contractExchangeDefs = filter(
    values(contract.exchangeDefById),
    { type: ExchangeType.CONTRACT },
  ) as ContractDocumentExchangeDefinition[];

  if (isEmpty(contractExchangeDefs)) {
    errors.push(t('review.errors.contractRequired'));
  }

  if (contractExchangeDefs.some(exchangeDef => !exchangeDef.description)) {
    errors.push(t('review.errors.contractDescriptionRequired'));
  }

  if (contractExchangeDefs.some(exchangeDef => exchangeDef.providedBy === ContractProvidedBy.BUYER && isEmpty(exchangeDef.attachments))) {
    errors.push(t('review.errors.contractAttachmentRequired'));
  }

  if (
    contractExchangeDefs.length !== 1 && // First contract is always considered a `primary contract`
    contractExchangeDefs.some(exchangeDef => !exchangeDef.isLive && isUndefined(exchangeDef.isAddendum))
  ) {
    errors.push(t('review.errors.contractTypeRequired'));
  }

  if (isAmending && !contract.isLegacy && contractExchangeDefs.every(exchangeDef => exchangeDef.isLive)) {
    errors.push(t('review.errors.amendmentContractRequired'));
  }

  const newContractExchangeDefs = contractExchangeDefs.filter(exchangeDef => !exchangeDef.isLive);

  if (newContractExchangeDefs.some(exchangeDef => exchangeDef.attachments.some(attachment => attachment.mimetype !== 'application/pdf'))) {
    errors.push(t('review.errors.contractFileType'));
  }

  if (contractExchangeDefs.some(isMissingSigners)) {
    errors.push(t('review.errors.signersRequired'));
  }

  if (pages.some(page => isEmpty(page.sections))) {
    errors.push(t('review.errors.emptyPage'));
  }

  for (const section of sections) {
    const sectionName = section.name;

    if (!sectionName.trim()) {
      errors.push(t('review.errors.sectionNameRequired'));
    }

    switch (section.type) {
      case SectionType.DOCUMENT: {
        const exchangeDefs = section.exchangeDefIds.map(
          exchangeId => exchangeDefById[exchangeId] as DocumentExchangeDefinition,
        );

        if (isEmpty(exchangeDefs)) {
          errors.push(t('review.errors.emptyDocumentSection', { sectionName }));
        }

        if (exchangeDefs.some(exchangeDef => !exchangeDef.category.trim())) {
          errors.push(t('review.errors.documentDescriptionRequired', { sectionName }));
        }

        if (exchangeDefs.some(exchangeDef => !exchangeDef.type || !exchangeDef.type.trim())) {
          errors.push(t('review.errors.documentRequirementRequired', { sectionName }));
        }

        if (exchangeDefs.some(
          exchangeDef => shouldHaveAttachment(exchangeDef) && isEmpty(exchangeDef.attachments),
        )) {
          errors.push(t('review.errors.documentAttachmentRequired', { sectionName }));
        }

        break;
      }

      case SectionType.LINE_ITEMS: {
        const exchangeDefs = section.exchangeDefIds.map(
          exchangeId => exchangeDefById[exchangeId],
        );
        const lineItemExchangeDefs = exchangeDefs.filter(
          (def): def is LineItemExchangeDefinition => def.type === ExchangeType.LINE_ITEM,
        );

        if (isEmpty(lineItemExchangeDefs)) {
          errors.push(t('review.errors.emptyLineItemSection', { sectionName }));
        }

        // @ts-expect-error ts(18047) FIXME: 'lineItemDef.quantity' is possibly 'null'.
        if (lineItemExchangeDefs.some(lineItemDef => lineItemDef.fields.quantity && lineItemDef.quantity <= 0)) {
          errors.push(t('review.errors.quantities', { sectionName }));
        }

        if (lineItemExchangeDefs.some(lineItemDef => {
          const fields = Object.values(lineItemDef.fields)
            .filter(field => isDefinitionField(field) && !optionalBuyerFieldKeys.includes(field._id));

          return fields.some((field) => {
            // @ts-expect-error ts(2345) FIXME: Argument of type 'string' is not assignable to parameter of type 'never'.
            const value = getExchangeDefFieldValue(lineItemDef, field._id);
            switch (field.type) {
              case 'date':
              case 'boolean':
              case 'string':
              case 'unspscCode':
                return !value;
              case 'price':
              case 'number':
                return !isNumber(value);
              default:
                throw new Error(`Unsupported field type: ${field.type}`);
            }
          });
        })) {
          errors.push(t('review.errors.buyerFieldsRequired', { sectionName }));
        }

        if (lineItemExchangeDefs.some(lineItemDef => {
          const needsEvaluatorFieldCurrency = some(
            lineItemDef.fields,
            field => {
              if (!isDefinitionField(field) || field.type !== 'price') {
                return false;
              }

              return field._id === 'targetPrice'
                // In the case of the (optional) target price, a currency is
                // only required if a target price has been set
                // @ts-expect-error ts(2345) FIXME: Argument of type 'string' is not assignable to parameter of type 'never'.
                ? isNumber(getExchangeDefFieldValue(lineItemDef, 'targetPrice'))
                : true;
            },
          );

          return (
            needsEvaluatorFieldCurrency &&
            // @ts-expect-error ts(2345) FIXME: Argument of type 'string' is not assignable to parameter of type 'never'.
            !getExchangeDefFieldValue(lineItemDef, 'evaluatorFieldCurrency')
          );
        })) {
          errors.push(t('review.errors.buyerCurrencyRequired', { sectionName }));
        }

        const fields = lineItemExchangeDefs[0]?.fields;
        Object.values(fields)
          .forEach(field => {
            if (field.source.type === 'formula') {
              const { formula } = field.source;
              const referencedFieldIds = [...formula.matchAll(/\{([^}]+)\}/g)].map(result => result[1]);

              if (!isEmpty(difference(referencedFieldIds, Object.keys(fields)))) {
                errors.push(t('request.validation.invalidFormulaFieldReference', {
                  fieldName: 'label' in field ? field.label : t(`request.fields.predefinedFieldLabel.${field._id.split(':')[0]}`, { ns: 'translation' }),
                  sectionName,
                }));
              }
            }
          });

        break;
      }
    }
  }

  return uniq(errors);
};

const DraftReviewFooter = ({ issueDisabled }: { issueDisabled: boolean }) => {
  const { t } = useTranslation('contracts');
  const toaster = useToaster();
  const api = useApi();
  const navigate = useNavigate();
  const { navigateToReminders, navigateToTeam } = useDraftContractNavigation();
  const contractId = useContractId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  // @ts-expect-error ts(2339) FIXME: Property 'isRevising' does not exist on type 'ContractStateContextType | undefined'.
  const { isRevising, isAmending, isTemplate } = useContractState();
  const issueRevisionModal = useModalState();
  const issueAmendmentModal = useModalState();
  const { navigateToSummary } = useLiveContractNavigation();
  const draftContractQueryKey = useContractQueryKey({ scope: 'draft' });
  const queryClient = useQueryClient();

  const [issueContract, { isLoading }] = useMutation(
    api.issueContract,
    {
      onSuccess: callAll(
        () => toaster.success(t('toaster.contractSent.success')),
        () => navigateToSummary(),
        () => queryClient.invalidateQueries(draftContractQueryKey),
      ),
      onError: () => toaster.error(t('toaster.contractSent.failed')),
    },
  );

  const onIssue = useCallback(
    () => {
      if (isRevising) {
        issueRevisionModal.open();
      } else if (isAmending) {
        issueAmendmentModal.open();
      } else if (isTemplate) {
        navigate({
          to: contractsRoute.to,
          params: { currentCompanyId },
          search: { tab: 'templates' },
        });
      } else {
        return issueContract({ currentCompanyId, contractId });
      }
    },
    [isRevising, isAmending, isTemplate, issueRevisionModal, issueAmendmentModal, navigate, currentCompanyId, issueContract, contractId],
  );

  return (
    <>
      <DraftContractFooter
        onBack={isRevising || isAmending ? navigateToTeam : navigateToReminders}
        onIssue={onIssue}
        issueDisabled={issueDisabled || isLoading}
      />
      {issueRevisionModal.isOpen && (
        <IssueRevisionModal
          isOpen={issueRevisionModal.isOpen}
          onCancel={issueRevisionModal.close}
        />
      )}
      {issueAmendmentModal.isOpen && (
        <IssueAmendmentModal
          isOpen={issueAmendmentModal.isOpen}
          onCancel={issueAmendmentModal.close}
        />
      )}
    </>
  );
};

const DraftReviewPageContent = () => {
  const { t } = useTranslation(['contracts', 'translation']);
  const permissionsByPageId = usePagesPermissions();
  // @ts-expect-error ts(2339) FIXME: Property 'isAmending' does not exist on type 'ContractStateContextType | undefined'.
  const { isAmending, isRevising, isTemplate, isTemplatePreview } = useContractState();
  const contract = useContractData();
  const { detailsSections, contractSection } = useMemo(
    () => {
      const sections = values(contract.sectionById);
      const detailsSections = sections.filter(section => section.type !== SectionType.CONTRACT);
      const contractSection = sections.find(section => section.type === SectionType.CONTRACT);

      return { detailsSections, contractSection };
    },
    [contract],
  );
  const errors = validateContract(contract, t, isAmending);
  const pageIdBySectionId = useMemo(() => {
    return values(contract.pageById).reduce((pageAcc, currentPage) => ({
      ...pageAcc,
      ...currentPage.sections.reduce((sectionAcc, currentSectionId) => ({
        ...sectionAcc,
        [currentSectionId]: currentPage._id,
      }), {}),
    }), {});
  }, [contract]);

  return (
    <>
      <ContractDraftPageContent>
        <Stack gap="20px">
          {permissionsByPageId[SUMMARY_PAGE_ID].canRead && (
            <>
              {isTemplate ? (
                <ContractTemplateDetailsPanel />
              ) : (
                <ContractErrorsPanel errors={errors} />
              )}
              <ContractBasicDetailsPanel />
              <ContractCustomMilestonePanel />
            </>
          )}
          {permissionsByPageId[REMINDERS_PAGE_ID].canRead && !isAmending && !isRevising && (
            <ContractRemindersPanel />
          )}
          {permissionsByPageId[SUMMARY_PAGE_ID].canRead && (
            <>
              {!isTemplate && <ContractCounterPartyPanel />}
              <ContractSpendDataPanel />
              <ContractProductsAndServicesPanel />
              <ContractReferenceNumbersPanel />
            </>
          )}
          {detailsSections.map((section) => {
            const { canRead } = permissionsByPageId[section.pageId];

            if (!canRead) return null;

            return (
              <ContractPageProvider
                key={section._id}
                page={contract.pageById[pageIdBySectionId[section._id]]}
              >
                <ContractSectionProvider section={section}>
                  {section.type === SectionType.DOCUMENT ? (
                    <ContractDocumentsSectionPanel />
                  ) : section.type === SectionType.LINE_ITEMS ? (
                    <ContractLineItemsSectionPanel />
                  ) : (
                    null
                  )}
                </ContractSectionProvider>
              </ContractPageProvider>
            );
          })}
          {/*
           // @ts-expect-error ts(18048) FIXME: 'contractSection' is possibly 'undefined'. */}
          {permissionsByPageId[pageIdBySectionId[contractSection._id]].canRead && (
            <ContractPageProvider
              // @ts-expect-error ts(18048) FIXME: 'contractSection' is possibly 'undefined'.
              page={contract.pageById[pageIdBySectionId[contractSection._id]]}
            >
              {/*
               // @ts-expect-error ts(2322) FIXME: Type 'ContractSection | undefined' is not assignable to type 'ContractSection | null'. */}
              <ContractSectionProvider section={contractSection}>
                <ContractSectionPanel />
              </ContractSectionProvider>
            </ContractPageProvider>
          )}
        </Stack>
      </ContractDraftPageContent>
      {!isTemplatePreview && <DraftReviewFooter issueDisabled={!isEmpty(errors) && !isTemplate} />}
    </>
  );
};

export const ContractDraftReview = ({
  contractId,
}: {
  contractId: string;
}) => {
  const { t } = useTranslation('translation');
  const currentCompanyId = useCurrentCompanyId({ required: true });
  // @ts-expect-error ts(2339) FIXME: Property 'isTemplate' does not exist on type 'ContractStateContextType | undefined'.
  const { isTemplate } = useContractState();
  const api = useApi();

  const { data: contract, isLoading, isError, isSuccess } = useContract({
    contractId,
    currentCompanyId,
    scope: 'draft',
    isTemplate,
  });

  const { data: sizeLimits, status: sizeLimitsStatus } = useQuery(
    ['contractSizeLimits', { contractId, currentCompanyId }],
    wrap(api.getContractSizeLimits),
    {
      staleTime: 60 * 1000,
    },
  );

  return (
    <>
      {isLoading || sizeLimitsStatus === 'loading' ? (
        <PanelPadding>
          <Loading />
        </PanelPadding>
      ) : isError || sizeLimitsStatus === 'error' ? (
        <PanelPadding>
          <ErrorMessage error={t('errors.unexpected')} />
        </PanelPadding>
      ) : isSuccess && sizeLimitsStatus === 'success' && contract ? (
        <ModelSizeLimitsProvider {...sizeLimits}>
          <ContractProvider contract={contract}>
            <DraftReviewPageContent />
          </ContractProvider>
        </ModelSizeLimitsProvider>
      ) : (
        null
      )}
    </>
  );
};
