import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Bold } from './Bold';

type HiddenPagePlaceholderProps = {
  subtitle: string;
  hasCta?: boolean;
  navigateToTeam?: () => void;
  children?: React.ReactNode
};

export const HiddenPagePlaceholder = ({ subtitle, hasCta = false, navigateToTeam, children }: HiddenPagePlaceholderProps) => {
  const { t } = useTranslation();

  return hasCta ? (
    <>
      <Bold as="div" fontSize={5} mb={3}>
        <Icon icon="lock" mr={2} />
        {t('request.pages.pageNotVisible')}
      </Bold>
      <Text mb={1} style={{ whiteSpace: 'pre-line' }}>
        {subtitle}
      </Text>
      <Button
        type="button"
        mt="20px"
        onClick={navigateToTeam}
      >
        {t('request.team.openTeamManagement')}
      </Button>
    </>
  ) : (
    <>
      <Bold as="div" fontSize={5} mb={3}>
        <Icon icon="eye-slash" mr={2} />
        {t('request.pages.pageNotVisible')}
      </Bold>
      <Text mb={1}>
        {subtitle}
      </Text>
      <Text>
        {children}
      </Text>
    </>
  );
};
