import * as React from 'react';
import { CellProps } from 'react-table';
import { useExchangeDefFieldValue } from './ExchangeDefFieldValueContext';

export const LinkedExchangeDefFieldCell: React.FC<CellProps<any>> = ({ cell, row }) => {
  const { getFieldValue } = useExchangeDefFieldValue();
  const field = cell.value;

  return getFieldValue(row.original, field._id);
};
export const LinkedExchangeFieldCell: React.FC<CellProps<any>> = ({ cell, row, column }) => {
  const { getFieldValue } = useExchangeDefFieldValue();
  const field = cell.value;

  return getFieldValue(row.original.def, field._id);
};
