import { useRequestEditNavigation } from '../../../appNavigation';
import * as lotPagesLayout from '../Live/lotPagesLayout';

export const OpenDraftRequestInNewTabLink = ({ children = undefined }) => {
  const navigation = useRequestEditNavigation();

  return (
    <lotPagesLayout.TanstackInlineLink
      target="_blank"
      {...navigation.getSummaryLinkProps()}
    >
      {children}
    </lotPagesLayout.TanstackInlineLink>
  );
};
