import { IconValue } from '@deepstream/common';
import { Flex } from 'rebass/styled-components';
import { OverflowTooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { GridSectionIcon } from './GridSectionIcon';

export const SectionCellContent = ({
  icon,
  sectionName,
  lotName,
}: {
  icon: IconValue;
  sectionName: string;
  lotName?: string;
}) => {
  return (
    <Flex alignItems="center" p={2}>
      <GridSectionIcon icon={icon} />
      <Flex flexDirection="column">
        <OverflowTooltip content={sectionName}>
          <Truncate>
            {sectionName}
          </Truncate>
        </OverflowTooltip>
        {lotName && (
          <Truncate fontSize="9px" color="subtext" mt="2px">
            <Icon icon="grid-2" regular mr={1} />
            {lotName}
          </Truncate>
        )}
      </Flex>
    </Flex>
  );
};
