import * as React from 'react';

/**
 * Utility function to select one of two components to render depending on a
 * predicate.
 * When calling {@link test} with the component props returns `true`, renders
 * {@link Left}, otherwise renders {@link Right}.
 */
export const branch = <T extends Record<string, unknown>>(
  test: (props: T) => boolean,
  Left: React.FC<T>,
  Right: React.FC<T>,
) =>
    (props: T) => test(props)
      ? React.createElement(Left, props)
      : React.createElement(Right, props);
