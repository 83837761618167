import { exchangeStatusConfig } from '@deepstream/common';
import { Contract, ContractDocumentExchangeDefinition, ContractStatus } from '@deepstream/common/contract';
import {
  ActionType,
  ExchangeStatus,
  ExchangeType,
  getCurrentCompanyGroup,
} from '@deepstream/common/rfq-utils';
import { StatusIconConfig } from '@deepstream/common/rfq-utils/statusConfigs';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { find, findLast } from 'lodash';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { NoResponseCell } from './NoResponseCell';
import { EvaluationExchangeSnapshot, ExchangeSnapshot } from './types';
import * as rfx from './rfx';
import { requestAndLotBidStatusesAllowScoreSubmissions } from './modules/NewEvaluation/utils';

type StatusIconProps = Omit<IconProps, 'icon'> & { type: ExchangeType; currentCompanyGroup: 'buyer' | 'supplier'; status: string };

export const StatusIcon = ({ type, status, currentCompanyGroup, ...props }: StatusIconProps) => {
  const { icon } = exchangeStatusConfig[status][currentCompanyGroup] ?? {};
  const { value, color, isRegular } = icon?.[type ?? 'default'] ?? icon?.default ?? {};

  return (
    <Icon
      icon={value as IconProps['icon']}
      color={color}
      regular={isRegular}
      {...props}
    />
  );
};

type StatusProps = {
  label: string;
  icon?: StatusIconConfig;
  iconFontSize?: number | string;
  fontWeight?: number | string;
  showTooltip?: boolean;
  truncate?: boolean;
};

export const StatusIconText: React.FC<StatusProps> = ({ label, icon, iconFontSize = 4, fontWeight, showTooltip, truncate }) => {
  // @ts-expect-error ts(2339) FIXME: Property 'isRegular' does not exist on type 'StatusIconConfig | undefined'.
  const { isRegular, color, value } = icon;

  return (
    <IconText
      isIconRegular={isRegular}
      icon={value as IconProps['icon']}
      iconColor={color}
      iconFontSize={iconFontSize}
      fontSize={2}
      fontWeight={fontWeight}
      text={label}
      gap="6px"
      fixedWidth
      truncate={truncate}
      showTooltip={showTooltip}
    />
  );
};

type ExchangeStatusProps = {
  type?: ExchangeType;
  status: ExchangeStatus;
  currentCompanyGroup: 'buyer' | 'supplier';
  iconFontSize?: number | string;
  truncate?: boolean;
  showTooltip?: boolean;
};

export const ExchangeStatusIconText: React.FC<ExchangeStatusProps> = ({
  type,
  status,
  currentCompanyGroup,
  iconFontSize = 4,
  truncate,
  showTooltip,
}) => {
  const { t } = useTranslation();
  const { icon } = exchangeStatusConfig[status][currentCompanyGroup] ?? {};
  const statusIcon = icon?.[type ?? 'default'] ?? icon?.default ?? {};

  const label = status
    ? t(`request.exchange.exchangeStatus.${status}`)
    : undefined;

  return (
    <StatusIconText
      // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
      label={label}
      icon={statusIcon as StatusIconConfig}
      iconFontSize={iconFontSize}
      showTooltip={showTooltip}
      truncate={truncate}
    />
  );
};

export const EvaluationStatus = ({
  exchange,
  status,
  iconFontSize = 4,
  showTooltip,
  truncate,
}: ExchangeStatusProps & {
  exchange: EvaluationExchangeSnapshot;
  showTooltip?: boolean;
  truncate?: boolean;
}) => {
  const { t } = useTranslation('evaluation');
  const structure = rfx.useStructure();
  // When the bid is inactive, then the user cannot take any action;
  // in this case, we render the BLOCKED icon to indicate that the
  // user can no longer submit a score.
  const iconStatus = (
    requestAndLotBidStatusesAllowScoreSubmissions(exchange, structure) ||
    status === ExchangeStatus.OBSOLETE
  )
    ? status
    : ExchangeStatus.BLOCKED;
  const { icon } = exchangeStatusConfig[iconStatus].buyer ?? {};
  const iconConfig = icon?.[ExchangeType.EVALUATION_CRITERION] ?? icon?.default;

  const label = status
    ? t(`exchangeStatus.${status}`)
    : undefined;

  return (
    <StatusIconText
      // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
      label={label}
      icon={iconConfig as StatusIconConfig}
      iconFontSize={iconFontSize}
      showTooltip={showTooltip}
      truncate={truncate}
    />
  );
};

type ExchangeStatusCellProps = CellProps<ExchangeSnapshot> & {
  iconFontSize?: number | string;
  contract?: Contract;
};

// Virtual status for "action required" exchanges in failed contracts
const NotCompletedExchangeCell = () => {
  const { t } = useTranslation('contracts');

  return (
    <StatusIconText
      icon={{
        value: 'user-times',
        color: 'subtext',
      }}
      label={t('notCompletedExchange')}
    />
  );
};

export const ExchangeStatusCell = ({
  cell: { value: status },
  row: { original: exchange },
  column,
  iconFontSize,
  contract,
}: ExchangeStatusCellProps & { contract?: Contract; column: { showTooltip?: boolean; truncate?: boolean } }) => {
  const recipientId = exchange.recipientId ?? find(exchange.companies, { group: 'recipient' })?._id;
  const isRecipient = recipientId === exchange.currentCompanyId;
  const currentCompanyGroup = getCurrentCompanyGroup(isRecipient);

  if (contract?.status === ContractStatus.FAILED && status === ExchangeStatus.ACTION_REQUIRED) {
    return <NotCompletedExchangeCell />;
  }

  return (
    <ExchangeStatusIconText
      type={exchange.def.type}
      status={status}
      currentCompanyGroup={currentCompanyGroup}
      iconFontSize={iconFontSize}
      showTooltip={column.showTooltip}
      truncate={column.truncate}
    />
  );
};

const actionToIconMap = {
  [ActionType.ACCEPT]: 'check',
  [ActionType.REJECT]: 'times',
  [ActionType.DEVIATE]: 'reply',
  [ActionType.SUBMIT]: 'file-signature',
  [ActionType.UPLOAD_DOCUMENT]: 'file',
  [ActionType.APPROVE_DOCUMENT]: 'check',
  [ActionType.VERIFIED_RECIPIENT_SIGNED]: 'circle-check',
};

const documentDeviationActions = [ActionType.ACCEPT, ActionType.REJECT, ActionType.DEVIATE];

export const SupplierDeviationResponseStatusCell = ({
  cell: { value: status },
  row: { original: exchange },
  iconFontSize,
}: ExchangeStatusCellProps) => {
  const { recipientId } = exchange;
  // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
  const isSupplierTurn = exchange.turn?.includes(recipientId);
  const { t } = useTranslation();
  const isRecipient = recipientId === exchange.currentCompanyId;
  const currentCompanyGroup = getCurrentCompanyGroup(isRecipient);

  const lastSupplierHistoryActionType = findLast(
    exchange.history,
    ({ companyId, type }) => type !== ActionType.NONE && companyId === recipientId,
  )?.type as ActionType;

  if (![ActionType.ACCEPT, ActionType.REJECT].includes(lastSupplierHistoryActionType) && !exchange.latestSupplierAttachment) {
    return (
      <NoResponseCell exchange={exchange} />
    );
  }

  if ((!isSupplierTurn || [ActionType.ACCEPT, ActionType.REJECT].includes(lastSupplierHistoryActionType)) &&
    lastSupplierHistoryActionType &&
    documentDeviationActions.includes(lastSupplierHistoryActionType)) {
    return (
      <IconText
        icon={actionToIconMap[lastSupplierHistoryActionType] as IconProps['icon']}
        iconFontSize={iconFontSize}
        fontSize={2}
        text={t(`request.documents.supplierResponseStatus.${lastSupplierHistoryActionType}`)}
        gap="6px"
        fixedWidth
      />
    );
  }

  return (
    <ExchangeStatusIconText
      type={exchange.def.type}
      status={status}
      currentCompanyGroup={currentCompanyGroup}
      iconFontSize={iconFontSize}
    />
  );
};

const contractActions = [ActionType.ACCEPT, ActionType.REJECT, ActionType.DEVIATE, ActionType.SUBMIT, ActionType.AGREE];

export const ContractSupplierResponseStatusCell = ({
  cell: { value: status },
  row: { original: exchange },
  iconFontSize,
}: ExchangeStatusCellProps) => {
  const { t } = useTranslation(['translation', 'contracts']);

  const { recipientId } = exchange;
  // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
  const isSupplierTurn = exchange.turn?.includes(recipientId);
  const isRecipient = recipientId === exchange.currentCompanyId;
  const currentCompanyGroup = getCurrentCompanyGroup(isRecipient);

  const lastSupplierHistoryActionType = findLast(
    exchange.history,
    ({ companyId, type }) => type !== ActionType.NONE && companyId === recipientId,
  )?.type as ActionType;

  if (
    ![ActionType.ACCEPT, ActionType.REJECT, ActionType.VERIFIED_RECIPIENT_SIGNED].includes(lastSupplierHistoryActionType) &&
    !exchange.latestSupplierAttachment &&
    !exchange.isAwaitingEnvelopePublishing &&
    !exchange.isAwaitingSubmitterESignature
  ) {
    return (
      <NoResponseCell exchange={exchange} />
    );
  }

  if (
    (!isSupplierTurn || [ActionType.ACCEPT, ActionType.REJECT].includes(lastSupplierHistoryActionType)) &&
    lastSupplierHistoryActionType &&
    (
      contractActions.includes(lastSupplierHistoryActionType) ||
      exchange.isAwaitingCounterESignature ||
      exchange.isAwaitingApproval
    )
  ) {
    let text;

    if (lastSupplierHistoryActionType === ActionType.SUBMIT) {
      const { isAddendum } = exchange.def as ContractDocumentExchangeDefinition;

      text = isAddendum
        ? t('exchange.supplierResponseStatus.submitAddendum', { ns: 'contracts' })
        : t('exchange.supplierResponseStatus.submitContract', { ns: 'contracts' });
    } else {
      text = t(`exchange.supplierResponseStatus.${lastSupplierHistoryActionType}`, { ns: 'contracts' });
    }

    return (
      <IconText
        icon={actionToIconMap[lastSupplierHistoryActionType] as IconProps['icon']}
        iconFontSize={iconFontSize}
        fontSize={2}
        text={text}
        gap="6px"
        fixedWidth
        isIconLight={lastSupplierHistoryActionType === ActionType.SUBMIT}
        iconColor={lastSupplierHistoryActionType === ActionType.VERIFIED_RECIPIENT_SIGNED ? 'success' : undefined}
      />
    );
  }

  return (
    <ExchangeStatusIconText
      type={exchange.def.type}
      status={status}
      currentCompanyGroup={currentCompanyGroup}
      iconFontSize={iconFontSize}
    />
  );
};

export const ContractBuyerResponseStatusCell = ({
  cell: { value: status },
  row: { original: exchange },
  iconFontSize,
}: ExchangeStatusCellProps) => {
  const { t } = useTranslation('contracts');

  const { def: { publisherId } } = exchange;
  const isSender = publisherId === exchange.currentCompanyId;
  const isRecipient = !isSender;
  const currentCompanyGroup = getCurrentCompanyGroup(isRecipient);

  const lastBuyerHistoryActionType = findLast(
    exchange.history,
    ({ companyId, type }) => type !== ActionType.NONE && companyId === publisherId,
  )?.type as ActionType;

  if (
    lastBuyerHistoryActionType === ActionType.DEVIATE ||
    (lastBuyerHistoryActionType === ActionType.APPROVE_DOCUMENT && !exchange.isAwaitingInitiatorApproval)
  ) {
    return (
      <IconText
        icon={actionToIconMap[lastBuyerHistoryActionType] as IconProps['icon']}
        iconFontSize={iconFontSize}
        fontSize={2}
        text={t(`exchange.buyerResponseStatus.${lastBuyerHistoryActionType}`)}
        gap="6px"
        fixedWidth
      />
    );
  }

  return (
    <ExchangeStatusIconText
      type={exchange.def.type}
      status={status}
      currentCompanyGroup={currentCompanyGroup}
      iconFontSize={iconFontSize}
    />
  );
};

export const BuyerDeviationResponseStatusCell = ({
  cell: { value: status },
  row: { original: exchange },
  iconFontSize,
}: ExchangeStatusCellProps) => {
  const recipientId = exchange.recipientId ?? find(exchange.companies, { group: 'recipient' })?._id;
  const isBuyerTurn = exchange.turn?.includes(exchange.def.publisherId);
  const isRecipient = recipientId === exchange.currentCompanyId;
  const currentCompanyGroup = getCurrentCompanyGroup(isRecipient);
  const { t } = useTranslation();

  const lastBuyerHistoryActionType = findLast(
    exchange.history,
    ({ companyId, type }) => type !== ActionType.NONE && companyId === exchange.def.publisherId,
  )?.type as ActionType;
  const hasActedOnAPreviousDeviation = [ActionType.ACCEPT, ActionType.REJECT].includes(lastBuyerHistoryActionType) && isBuyerTurn;

  if (isBuyerTurn && exchange.latestSupplierAttachment) {
    return (
      <NoResponseCell exchange={exchange} />
    );
  }

  if (!hasActedOnAPreviousDeviation && lastBuyerHistoryActionType && documentDeviationActions.includes(lastBuyerHistoryActionType)) {
    return (
      <IconText
        icon={actionToIconMap[lastBuyerHistoryActionType] as IconProps['icon']}
        iconFontSize={iconFontSize}
        fontSize={2}
        text={t(`request.documents.buyerDeviationResponseStatus.${lastBuyerHistoryActionType}`)}
        gap="6px"
        fixedWidth
      />
    );
  }

  return (
    <ExchangeStatusIconText
      type={exchange.def.type}
      status={status}
      currentCompanyGroup={currentCompanyGroup}
      iconFontSize={iconFontSize}
    />
  );
};

// NB only team members that are evaluators can see this cell so
// we don't need to handle the case of an incomplete status
// where the current user's action is not required
export const EvaluationExchangeStatusCell = ({
  row: { original: exchange },
  cell: { value: status },
  column,
  iconFontSize,
}: CellProps<EvaluationExchangeSnapshot> & { iconFontSize?: number; column: { showTooltip?: boolean; truncate?: boolean } }) => {
  return (
    <EvaluationStatus
      status={status}
      currentCompanyGroup="buyer"
      iconFontSize={iconFontSize}
      exchange={exchange}
      showTooltip={column.showTooltip}
      truncate={column.truncate}
    />
  );
};
