import { DraftDetailsPageContainer } from '../../draft/DraftDetailsPage';
import * as Layout from '../../Layout';
import { DraftHeader } from './DraftHeader';

export const DraftDetailsPagePageContent = ({
  rfqId,
  pageId,
  isRevising,
  isTemplate,
}) => {
  const tabId = 'details';

  return (
    <Layout.HeaderAndContent
      header={
        <DraftHeader
          rfqId={rfqId}
          isRevising={isRevising}
          isTemplate={isTemplate}
          selectedTabId={tabId}
        />
      }
      content={
        <DraftDetailsPageContainer
          rfqId={rfqId}
          pageId={pageId}
          isRevising={isRevising}
          isTemplate={isTemplate}
        />
      }
      contentPaddingBottom={Layout.BOTTOM_TOOLBAR_CONTENT_PADDING_BOTTOM}
    />
  );
};
