import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { ModalProps, Modal, DoneButton, ModalHeader, ModalBody, ModalFooter } from '@deepstream/ui-kit/elements/popup/Modal';
import { StructuredTags } from '../../../ui/StructuredTags';
import { createDeprecatedProductTagTree } from '../../../utils';
import { HelpCenterInlineLink } from '../../../HelpCenterLink';

type DeprecatedProductsModalProps =
  { company: any; onClose: any } &
  ModalProps;

export const DeprecatedProductsModal: React.FC<DeprecatedProductsModalProps> = ({ company, onClose, ...props }) => {
  const { t } = useTranslation();

  const tags = React.useMemo(
    () => createDeprecatedProductTagTree(company.products),
    [company],
  );

  return (
    <Modal
      onRequestClose={onClose}
      style={{ content: { width: '810px' } }}
      {...props}
    >
      <ModalHeader onClose={onClose}>
        {t('productsAndServices.productsAndServicesOld')}
      </ModalHeader>
      <ModalBody>
        <MessageBlock fontSize={2} variant="warn" mt={0} mb="20px">
          {t('productsAndServices.deprecationWarningDuringMigration')}
          {' '}
          <HelpCenterInlineLink path="/en/articles/6337679-search-for-new-suppliers-using-product-services-tags">
            {t('general.moreInfo')}
          </HelpCenterInlineLink>
        </MessageBlock>
        <StructuredTags tags={tags} mb={-3} />
      </ModalBody>
      <ModalFooter>
        <DoneButton variant="primary" onClick={onClose} mr={0} />
      </ModalFooter>
    </Modal>
  );
};
