import { useReducer, Reducer, useContext, createContext, useMemo, useEffect } from 'react';
import { difference, union, intersection, mapKeys, reduce, pick, map } from 'lodash';

import { PreQualCategory, PreQualQuestion } from '../../../types';
import { useListCategories, useListQuestions } from '../utils';

type ImportQuestionsContextProps = {
  categories: PreQualCategory[];
  questions: PreQualQuestion[];

  activeCategoryId?: string;
  handleChangeCategory: (id: string) => void;

  selectedQuestionExchangeDefIds: string[];
  toggleQuestions: (ids: string[]) => void;

  allQuestionsCount: number;

  categoriesById: Record<string, PreQualCategory>;
  questionsById: Record<string, PreQualQuestion>;
  initialExchangeDefIds: string[];
  exchangeDefQuestionIdsByCategoryId: Record<string, string[]>;
};

enum ActionType {
  CHANGE_CATEGORY = 'CHANGE_CATEGORY',
  ADD_QUESTIONS = 'ADD_QUESTIONS',
  REMOVE_QUESTIONS = 'REMOVE_QUESTIONS',
}

type Action = {
  type: ActionType.CHANGE_CATEGORY;
  payload: { id: string };
} | {
  type: ActionType.ADD_QUESTIONS;
  payload: { ids: string[] }
} | {
  type: ActionType.REMOVE_QUESTIONS;
  payload: { ids: string[] };
};

type State = {
  activeCategoryId: string | undefined;
  selectedQuestionExchangeDefIds: string[];
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.CHANGE_CATEGORY: return {
      ...state,
      activeCategoryId: action.payload.id,
    };

    case ActionType.ADD_QUESTIONS: return {
      ...state,
      selectedQuestionExchangeDefIds: union(state.selectedQuestionExchangeDefIds, action.payload.ids),
    };

    case ActionType.REMOVE_QUESTIONS: return {
      ...state,
      selectedQuestionExchangeDefIds: difference(state.selectedQuestionExchangeDefIds, action.payload.ids),
    };

    default: return state;
  }
};

const ImportQuestionsContext = createContext<ImportQuestionsContextProps>({} as ImportQuestionsContextProps);

export const ImportQuestionsProvider = ({
  children,
  initialExchangeDefIds = [],
}: {
  children: React.ReactNode;
  initialExchangeDefIds: string[];
}) => {
  const { data: categories = [], isLoading: isLoadingCategories, isError: isErrorCategories } = useListCategories();
  const { data: questions = [], isLoading: isLoadingQuestions, isError: isErrorQuestions } = useListQuestions();

  const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, {
    activeCategoryId: undefined,
    selectedQuestionExchangeDefIds: [],
  });

  useEffect(() => {
    if (!categories.length) return;

    dispatch({
      type: ActionType.CHANGE_CATEGORY,
      payload: { id: categories[0]._id },
    });
  }, [categories]);

  const value = useMemo(() => {
    const handleChangeCategory = (id: string) => dispatch({ type: ActionType.CHANGE_CATEGORY, payload: { id } });

    const toggleQuestions = (ids: string[]) => {
      // If all passed ids area already selected means that we have to remove them otherwise need to be added
      if (intersection(state.selectedQuestionExchangeDefIds, ids).length === ids.length) {
        dispatch({
          type: ActionType.REMOVE_QUESTIONS,
          payload: { ids },
        });
      } else {
        dispatch({
          type: ActionType.ADD_QUESTIONS,
          payload: { ids },
        });
      }
    };

    const categoriesById = mapKeys(categories, (category) => category._id);
    const questionsById = mapKeys(questions, (question) => question._id);

    // Map questionIds from category to the corresponding ids of the latest exchange def version
    const exchangeDefQuestionIdsByCategoryId = reduce(categories, (accumulator, category) => {
      const questions = pick(questionsById, category.questionIds);

      return {
        ...accumulator,
        [category._id]: map(questions, (question) => question.currentVersion.exchangeDef._id),
      };
    }, {} as Record<string, string[]>);

    const allQuestionsCount = reduce(categories, (accumulator, category) => {
      return accumulator + category.questionIds.length;
    }, 0);

    return {
      categories,
      questions,

      toggleQuestions,
      selectedQuestionExchangeDefIds: state.selectedQuestionExchangeDefIds,

      handleChangeCategory,
      activeCategoryId: state.activeCategoryId,

      allQuestionsCount,

      initialExchangeDefIds,
      categoriesById,
      questionsById,
      exchangeDefQuestionIdsByCategoryId,
    };
  }, [
    categories,
    questions,
    state.activeCategoryId,
    state.selectedQuestionExchangeDefIds,
    initialExchangeDefIds,
  ]);

  if (isErrorCategories || isLoadingCategories || isErrorQuestions || isLoadingQuestions) return null;

  return (
    <ImportQuestionsContext.Provider value={value}>
      {children}
    </ImportQuestionsContext.Provider>
  );
};

export const useImportQuestionsContext = () => useContext(ImportQuestionsContext);
