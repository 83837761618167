import * as React from 'react';
import styled from 'styled-components';
import { Box, Card } from 'rebass/styled-components';
import { UrlContext } from './UrlProvider';

const Hero = styled(Card)`
  width: 100%;
  height: 228px;
  border-bottom: ${props => props.theme.borders.lightGray};
`;

type CompanyBannerProps = {
  companyId: string;
  style?: any;
  heroUrl?: any;
  canEdit?: boolean;
  updateCompanyBanner?: () => void;
  lastUpdated: number;
};

export const CompanyBanner: React.FC<CompanyBannerProps> = ({ companyId, lastUpdated, style = {} }) => {
  const urls = React.useContext(UrlContext);

  const bannerUrl = React.useMemo(
    () => `/download/hero/${companyId}?t=${lastUpdated}`,
    [companyId, lastUpdated],
  );

  return (
    <Box mb={-64} style={{ position: 'relative' }}>
      <Hero
        sx={{
          backgroundImage: `url(${urls.api}${bannerUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          ...style,
        }}
      />
    </Box>
  );
};
