import { useMemo } from 'react';
import { useField } from 'formik';
import { HirePeriod, HirePeriodExchangeDefinition } from '@deepstream/common/rfq-utils';
import { assign, omit, pick, reject } from 'lodash';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { swap } from '@deepstream/utils/swap';
import { findAndSet, findAndUpdate } from './exchangeDefs';

export const hirePeriodCompareLiveVersionKeys = [
  'name',
  'fromDate',
  'toDate',
];

export const intervalCompareLiveVersionKeys = [
  'intervalType',
  'amount',
  'unit',
  'quantity',
];

/**
 * Wrapper around formik's `useField` for an array of hire periods
 */
export const useHirePeriodsField = <TDef extends Partial<HirePeriod>> (
  hirePeriodsFieldName: string,
  intervalsFieldName: string,
) => {
  const { t } = useTranslation();
  const [{ value: hirePeriods },, hirePeriodHelpers] = useField<TDef[]>(hirePeriodsFieldName);
  const [
    { value: intervalsByHirePeriodId },,
    intervalsHelpers,
  ] = useField<Record<string, HirePeriodExchangeDefinition[]>>(intervalsFieldName);

  const actions = useMemo(
    () => ({
      addHirePeriod: () => {
        const newHirePeriod = {
          _id: uuid(),
          name: `${t('request.vesselPricing.hirePeriods.period')} ${hirePeriods.length + 1}`,
          fromDate: '',
          toDate: '',
          isObsolete: false,
        } as TDef;

        hirePeriodHelpers.setValue([
          ...hirePeriods,
          newHirePeriod,
        ]);
        intervalsHelpers.setValue({
          ...intervalsByHirePeriodId,
          [newHirePeriod._id as string]: [],
        });
      },

      moveHirePeriod: (index: number, delta: number) =>
        hirePeriodHelpers.setValue(swap(hirePeriods, index, index + delta)),

      removeHirePeriod: (hirePeriodId: string) => {
        hirePeriodHelpers.setValue(reject(hirePeriods, def => def._id === hirePeriodId));
        intervalsHelpers.setValue(omit(intervalsByHirePeriodId, [hirePeriodId]));
      },

      setHirePeriodIsObsolete: (hirePeriodId: string, isObsolete: boolean) => {
        hirePeriodHelpers.setValue(findAndSet(hirePeriods, { _id: hirePeriodId }, 'isObsolete', isObsolete));
        intervalsHelpers.setValue({
          ...intervalsByHirePeriodId,
          [hirePeriodId]: intervalsByHirePeriodId[hirePeriodId].map(interval => ({
            ...interval,
            isObsolete,
          })),
        });
      },

      resetToLiveVersion: (hirePeriod: TDef & { _id: string }, intervals: HirePeriodExchangeDefinition[]) => {
        hirePeriodHelpers.setValue(findAndUpdate(
          hirePeriods,
          { _id: hirePeriod._id },
          (hirePeriod: TDef) => assign(hirePeriod, pick(hirePeriod.liveVersion, hirePeriodCompareLiveVersionKeys)),
        ));

        intervalsHelpers.setValue({
          ...intervalsByHirePeriodId,
          [hirePeriod._id]: intervals
            .filter(interval => interval.isLive)
            .map(interval => ({
              ...interval,
              ...pick(interval.liveVersion as HirePeriodExchangeDefinition, intervalCompareLiveVersionKeys),
            })),
        });
      },
    }),
    [hirePeriods, hirePeriodHelpers, intervalsByHirePeriodId, intervalsHelpers, t],
  );

  return useMemo(
    () => ({
      hirePeriods,
      intervalsByHirePeriodId,
      ...actions,
    }),
    [hirePeriods, intervalsByHirePeriodId, actions],
  );
};
