import { useMemo } from 'react';
import { FieldConfig } from '@deepstream/common/rfq-utils';
import { rowHeightMeasurements } from '../rowHeight';
import { useLocalStorageState } from '../../../../useLocalStorageState';
import { useCurrentCompanyId } from '../../../../currentCompanyId';
import { useSelectRowHeight } from '../useSelectRowHeight';
import { useCurrentUser } from '../../../../useCurrentUser';
import { View } from '../useSelectView';
import { useSelectCurrency } from '../useSelectCurrency';
import { useSelectVisibleFields } from './useSelectVisibleFields';
import { useSelectStageResponseTags } from './useSelectStageResponseTags';
import { useSelectSavingsDisplayMode } from './useSelectSavingsDisplayMode';
import { useSelectRequirementGroups } from '../useSelectRequirementGroups';

export const useLineItemsGridState = (
  rfqId: string,
  view: View,
  orderedFields: FieldConfig[],
  nonObsoleteSectionIds: string[],
  availableRequirementGroupIds: string[],
) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();

  const [collapsedRowIds, setCollapsedRowIds] = useLocalStorageState<string[]>({
    key: `${currentCompanyId}.${currentUser._id}.${rfqId}.${view}.collapsedRowIds`,
    defaultValue: [],
  });

  const selectRequirementGroups = useSelectRequirementGroups({
    storageKey: `${currentCompanyId}.${currentUser._id}.${rfqId}.${view}.requirementGroups`,
    availableRequirementGroupIds,
  });

  const selectRowHeight = useSelectRowHeight({
    storageKey: `${currentCompanyId}.${currentUser._id}.${rfqId}.${view}.rowHeight`,
  });

  const selectCurrency = useSelectCurrency({
    storageKey: `${currentCompanyId}.${currentUser._id}.${rfqId}.${view}.currency`,
  });

  const selectVisibleFields = useSelectVisibleFields({
    storageKey: `${currentCompanyId}.${currentUser._id}.${rfqId}.${view}.visibleFields`,
    orderedFields,
  });

  const selectedVisibleFieldsetIds = useMemo(() => {
    return selectVisibleFields.selectedItems.map(item => item._id);
  }, [selectVisibleFields.selectedItems]);

  const selectResponseTags = useSelectStageResponseTags({
    storageKey: `${currentCompanyId}.${currentUser._id}.${rfqId}.${view}.responseTags`,
    nonObsoleteSectionIds,
  });

  const {
    selectedResponseTag,
    responseTags,
  } = useMemo(() => {
    return {
      selectedResponseTag: selectResponseTags.selectedItems[0],
      responseTags: selectResponseTags.items.map(({ value }) => value),
    };
  }, [selectResponseTags]);

  const selectSavingsDisplayMode = useSelectSavingsDisplayMode({
    storageKey: `${currentCompanyId}.${currentUser._id}.${rfqId}.${view}.savingsDisplayMode`,
  });

  return {
    collapsedRowIds,
    setCollapsedRowIds,
    selectRowHeight,
    selectCurrency,
    selectVisibleFields,
    subRowHeight: rowHeightMeasurements[selectRowHeight.selectedItems[0].value],
    selectedCurrency: selectCurrency.selectedItems[0]?.value,
    selectedVisibleFieldsetIds,
    selectResponseTags,
    selectedResponseTag,
    responseTags,
    selectSavingsDisplayMode,
    selectRequirementGroups,
  };
};
