import { reject, map, identity, isEmpty, first } from 'lodash';
import { PageType, RfxSection, SectionType } from '@deepstream/common/rfq-utils';
import { Box } from 'rebass/styled-components';

import { DocumentsSectionPanel } from '../../draft/DocumentsSectionPanel';
import { LineItemsSectionPanel } from '../../draft/LineItemsSectionPanel';
import { QuestionSectionPanel } from '../../draft/QuestionSectionPanel';
import { VesselPricingSectionPanel } from '../../draft/VesselPricingSectionPanel';

import * as rfx from '../../rfx';
import { RequestHooksProvider } from './RequestHooksProvider';
import { isSectionVisibleInStage } from '../../draft/section';

export const PageSections = () => {
  const sections = rfx.usePageSections();
  const stageId = rfx.useStageId();
  const { exchangeDefById } = rfx.useStructure();
  const requirementGroupId = rfx.useRequirementGroupId() || 'general';

  const sectionFilterPredicate = !stageId ? (
    identity
  ) : requirementGroupId === 'general' ? (
    (section: RfxSection) => isSectionVisibleInStage(section, exchangeDefById, stageId) && isEmpty(section.lotIds)
  ) : requirementGroupId ? (
    (section: RfxSection) => isSectionVisibleInStage(section, exchangeDefById, stageId) && first(section.lotIds) === requirementGroupId
  ) : (
    (section: RfxSection) => isSectionVisibleInStage(section, exchangeDefById, stageId)
  );

  return (
    <>
      {sections
        .filter(sectionFilterPredicate)
        .map((section) => (
          <rfx.SectionProvider key={section._id} section={section}>
            <Box mb="20px">
              {section.type === SectionType.LINE_ITEMS ? (
                <LineItemsSectionPanel />
              ) : section.type === SectionType.VESSEL_PRICING ? (
                <VesselPricingSectionPanel />
              ) : section.type === SectionType.DOCUMENT ? (
                <DocumentsSectionPanel />
              ) : section.type === SectionType.QUESTION ? (
                <QuestionSectionPanel />
              ) : (
                null
              )}
            </Box>
          </rfx.SectionProvider>
        ))}
    </>
  );
};

export const RequestPages = () => {
  const pages = rfx.useVisiblePages();
  const visiblePages = reject(
    pages,
    (page) => page.isHiddenWhileEditing || page.type === PageType.EVALUATION,
  );

  return (
    <>
      {map(visiblePages, (page) => (
        <rfx.PageProvider page={page} key={page._id}>
          <RequestHooksProvider>
            <PageSections />
          </RequestHooksProvider>
        </rfx.PageProvider>
      ))}
    </>
  );
};
