import * as React from 'react';
import { Box } from 'rebass/styled-components';
import { ListItemProps } from '../ExchangeTable';
import * as draft from './draft';
import * as validation from './validation';

export const ExchangeDefListItem: React.FC<ListItemProps & { fieldName: string }> = ({ row, fieldName, ...props }) => {
  const showValidationErrors = draft.useShowValidationErrors();
  const { isValid } = validation.useErrors(`${fieldName}[${row.index}]`);

  return (
    <Box
      as="li"
      {...props}
      sx={{
        ...props.sx,
        backgroundColor: showValidationErrors && !isValid
          ? 'errorBackground'
          : undefined,
        opacity: row.original.isObsolete ? 0.4 : undefined,
      }}
    />
  );
};
