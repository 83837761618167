import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass/styled-components';
import { StageApprovalStatus } from '@deepstream/common/rfq-utils';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ModalFooter } from '@deepstream/ui-kit/elements/popup/Modal';
import { StageApprovalBaseModal } from './StageApprovalBaseModal';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '../../ui/Tabs';
import { useStageWithIndex } from './useStageWithIndex';
import { StageApprovalDetails } from './StageApprovalDetails';
import { ApprovalResponseDetails } from './ApprovalResponseDetails';
import { useCurrentUserApproval } from './useCurrentUserApproval';
import { useExchange } from '../../useExchange';
import { StageApprovalExchangeSnapshot } from '../../types';

export type ViewResponseModalProps = {
  currentUserId: string;
  handleEditResponse: () => void;
  close: any;
  isOpen: boolean;
  props?: any;
};

export const ViewResponseModal: React.FC<ViewResponseModalProps> = ({
  currentUserId,
  handleEditResponse,
  close,
  isOpen,
  ...props
}) => {
  const { t } = useTranslation('translation');
  const approvalRequest = useExchange() as StageApprovalExchangeSnapshot;
  const { stage, stageIndex } = useStageWithIndex(approvalRequest.def.stageId);
  const { recipientIds, message, attachments } = approvalRequest.def;

  const { approval, isLastPendingApproval } = useCurrentUserApproval(approvalRequest, currentUserId);

  const canEditResponse = approvalRequest.status === StageApprovalStatus.PENDING && !isLastPendingApproval;

  return (
    <StageApprovalBaseModal
      isOpen={isOpen}
      onClose={close}
      title={t('request.stageApprovals.viewApprovalResponse')}
      withHeaderDivider={false}
      height="350px"
      {...props}
    >
      <Flex flexDirection="column" height="100%" justifyContent="space-between">
        <Tabs>
          <TabList style={{ backgroundColor: 'white', padding: '0px 16px' }}>
            <Tab>{t('details', { ns: 'general' })}</Tab>
            <Tab>{t('response', { ns: 'general' })}</Tab>
          </TabList>
          <Box sx={{ padding: '16px' }}>
            <TabPanels>
              <TabPanel>
                <StageApprovalDetails
                  stage={stage}
                  stageIndex={stageIndex}
                  recipientIds={recipientIds}
                  message={message}
                  attachments={attachments}
                />
              </TabPanel>
              <TabPanel>
                <ApprovalResponseDetails
                  // @ts-expect-error ts(2322) FIXME: Type 'Approval | undefined' is not assignable to type 'Approval'.
                  approval={approval}
                  canEdit={canEditResponse}
                  handleEditResponse={handleEditResponse}
                />
              </TabPanel>
            </TabPanels>
          </Box>
        </Tabs>
        <ModalFooter alignItems="flex-end">
          <Button variant="primary" onClick={close}>
            {t('done', { ns: 'general' })}
          </Button>
        </ModalFooter>
      </Flex>
    </StageApprovalBaseModal>
  );
};
