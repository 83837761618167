import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { Box, Flex } from 'rebass/styled-components';
import { QuestionFormat } from '@deepstream/common/rfq-utils';

import { DisabledInputBox } from '../../ui/Input';
import { DateTimePlaceholderText } from '../../DateTimePlaceholderText';
import { CheckboxFieldBase } from '../../form/CheckboxField';
import { ErrorMessage } from '../../form/Field';

const formatFromConfig = (hasDate: boolean, hasTime: boolean) => hasDate && hasTime
  ? QuestionFormat.DATETIME
  : hasDate
    ? QuestionFormat.DATE
    : hasTime
      ? QuestionFormat.TIME
      : QuestionFormat.INVALID;

export const DateTimeField = ({
  fieldName,
  disabled,
}: {
  fieldName?: string
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const dateTimeFieldName = fieldName ? `${fieldName}.format` : 'format';
  const [{ value }, fieldProps, helper] = useField<QuestionFormat>(dateTimeFieldName);

  const config = useMemo(() => ({
    hasDate: [QuestionFormat.DATE, QuestionFormat.DATETIME].includes(value),
    hasTime: [QuestionFormat.TIME, QuestionFormat.DATETIME].includes(value),
  }), [value]);

  const onDateChange = useCallback(
    (event) => helper.setValue(formatFromConfig(event.target.checked, config.hasTime)),
    [config, helper],
  );

  const onTimeChange = useCallback(
    (event) => helper.setValue(formatFromConfig(config.hasDate, event.target.checked)),
    [config, helper],
  );

  return (
    <Flex flexDirection={['column', 'row']} alignItems="center" flexWrap="wrap">
      <Box flex="0 0 350px">
        <DisabledInputBox maxWidth="350px" height="40px">
          <DateTimePlaceholderText format={value} />
        </DisabledInputBox>
      </Box>
      <Box flex="0 0 auto" ml="24px">
        <CheckboxFieldBase
          name="date"
          disabled={disabled}
          value={[QuestionFormat.DATE, QuestionFormat.DATETIME].includes(value)}
          fieldLabel={t('request.question.date')}
          onChange={onDateChange}
        />
      </Box>
      <Box flex="0 0 auto" ml="24px">
        <CheckboxFieldBase
          name="time"
          disabled={disabled}
          value={[QuestionFormat.TIME, QuestionFormat.DATETIME].includes(value)}
          fieldLabel={t('request.question.time')}
          onChange={onTimeChange}
        />
      </Box>
      {fieldProps.error && (
        <Box sx={{ wordBreak: 'break-word', textAlign: 'left', flexBasis: '100%', mt: 3 }}>
          <ErrorMessage error={fieldProps.error} fontWeight="normal" />
        </Box>
      )}
    </Flex>
  );
};
