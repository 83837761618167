import { useTranslation } from 'react-i18next';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog, DialogProps } from '@deepstream/ui-kit/elements/popup/Dialog';
import { RecordDetails } from './RecordDetails';

type ConfirmRemoveRecordDialogProps = Omit<DialogProps, 'heading' | 'body'> & {
  record: any;
};

export const ConfirmRemoveRecordDialog = ({ record, ...props }: ConfirmRemoveRecordDialogProps) => {
  const { t } = useTranslation('integration');

  return (
    <Dialog
      heading={t('confirmDeleteRecord')}
      body={
        <>
          <RecordDetails record={record} />
          <MessageBlock variant="warn" my={3}>
            {t('confirmDeleteRecordWarning')}
          </MessageBlock>
        </>
      }
      style={{ content: { width: '500px' } }}
      okButtonText={t('deleteRecord')}
      okButtonVariant="danger"
      cancelButtonText={t('general.cancel', { ns: 'translation' })}
      showCloseIcon
      {...props}
    />
  );
};
