import { useCallback, useState } from 'react';
import { identity } from 'lodash';
import { Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Pictogram } from '@deepstream/ui-kit';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { useGridMenuState } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { CollapsiblePanelHeader, Panel, PanelHeader, PanelPadding, PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { PreQualCategory, PreQualQuestion } from '../../../../types';
import { LoadingPanel } from '../../../../ui/Loading';
import { ErrorPanel } from '../../../../ui/ErrorMessage';
import { useListQuestions } from '../../utils';
import { QuestionGridMenu } from './QuestionGridMenu';
import { PreQualQuestionsGrid } from '../../PreQualQuestionsGrid';

export const QuestionsPanel = ({
  category,
  onUpdate = identity,
  onArchive = identity,
}: {
  category: PreQualCategory;
  onUpdate?: (question: PreQualQuestion, categoryId: string) => void;
  onArchive?: (questionId: string) => void;
}) => {
  const { t } = useTranslation(['translation', 'preQualification']);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const { menuReferenceId, dataIndex } = useGridMenuState();
  const { data: questions, isLoading, isError } = useListQuestions({ categoryId: category._id });

  const panelHeading = category.name;

  const onUpdateQuestion = useCallback(() => {
    // @ts-expect-error ts(18048) FIXME: 'questions' is possibly 'undefined'.
    onUpdate(questions[dataIndex], category._id);
  }, [category._id, dataIndex, onUpdate, questions]);

  const onArchiveQuestion = useCallback(() => {
    // @ts-expect-error ts(18048) FIXME: 'questions' is possibly 'undefined'.
    onArchive(questions[dataIndex]._id);
  }, [dataIndex, onArchive, questions]);

  if (isLoading) {
    return <LoadingPanel />;
  }

  if (isError) {
    return <ErrorPanel error={t('errors.loadingQuestions', { category: category.name, ns: 'preQualification' })} />;
  }

  // @ts-expect-error ts(18048) FIXME: 'questions' is possibly 'undefined'.
  if (!questions.length) {
    return (
      <Panel sx={{ marginBottom: 4 }}>
        <PanelHeader heading={panelHeading} />
        <PanelDivider />
        <PanelPadding p="12px 16px">
          <Pictogram
            variant="empty-state"
            label={t('request.question.noQuestionsAdded')}
            labelPosition="right"
            labelSx={{ fontWeight: 'normal' }}
          />
        </PanelPadding>
      </Panel>
    );
  }

  return (
    <Panel sx={{ marginBottom: 4 }}>
      <CollapsiblePanelHeader
        heading={(
          <Flex>
            <Text fontWeight={500}>
              <Truncate>
                {panelHeading}
              </Truncate>
            </Text>
            {/*
             // @ts-expect-error ts(18048) FIXME: 'questions' is possibly 'undefined'. */}
            <Text fontWeight={400} mx={1}>({questions.length})</Text>
          </Flex>
        )}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      />

      {!isCollapsed && (
        <>
          <PanelDivider />
          <PanelPadding p={3}>
            {/*
             // @ts-expect-error ts(2322) FIXME: Type 'PreQualQuestion[] | undefined' is not assignable to type 'PreQualQuestion[]'. */}
            <PreQualQuestionsGrid questions={questions} />
          </PanelPadding>
        </>
      )}
      {menuReferenceId && (
        <QuestionGridMenu
          onEdit={onUpdateQuestion}
          onArchive={onArchiveQuestion}
        />
      )}
    </Panel>
  );
};
