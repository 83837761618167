import { useMemo } from 'react';
import { DropdownMenuItemProps } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { BidStatus, Live, RfqStatus } from '@deepstream/common/rfq-utils';
import { every, identity, size, some, values } from 'lodash';
import { useTranslation } from 'react-i18next';
import * as rfx from '../../../rfx';

export type SuppliersGridActionConfig = {
  type: 'item',
  showInGridHeader?: boolean;
  isDisabledInGridHeader?: boolean;
  showInContextMenu?: boolean;
  label: string;
  gridHeaderDescription?: string;
  id: string;
  icon?: DropdownMenuItemProps['icon'];
  isIconRegular?: DropdownMenuItemProps['isIconRegular'];
  onSelect: DropdownMenuItemProps['onSelect'];
} | {
  type: 'divider';
  showInGridHeader?: boolean;
  showInContextMenu?: boolean;
  id: string;
};

export const useSuppliersGridActionConfig = ({
  numSelectedRecipients,
  numVisibleRecipients,
  isUnlockVisible,
  isMoveToNextStageDisabled,
  areActiveBidsSelected,
  areUnresponsiveBidsSelected,
  canRequestApprovalByStageId,
  hasSelectedInvitedRecipients,
  hasSelectedActiveRecipientsNotInFinalStage,
  selectedSupplierStageIds,
  requestApproval,
  unlockBids,
  rejectBids,
  reinstateBids,
  moveToNextStage,
  downloadFiles,
  onDeclineToBid,
  sendInviteReminders,
}: {
  numSelectedRecipients: number;
  numVisibleRecipients: number;
  isUnlockVisible: boolean;
  isMoveToNextStageDisabled: boolean;
  areActiveBidsSelected: boolean;
  areUnresponsiveBidsSelected: boolean;
  canRequestApprovalByStageId: Record<string, boolean> | null;
  hasSelectedInvitedRecipients: boolean;
  hasSelectedActiveRecipientsNotInFinalStage: boolean;
  selectedSupplierStageIds: string[];
  requestApproval: () => void;
  unlockBids: () => void;
  rejectBids: () => void;
  reinstateBids: () => void;
  moveToNextStage: () => void;
  downloadFiles: () => void;
  onDeclineToBid: () => void;
  sendInviteReminders: () => void;
}): SuppliersGridActionConfig[] => {
  const { t } = useTranslation('translation');
  const { status, bidById, stages } = rfx.useStructure<Live>();
  const {
    canDeclineOnBehalf,
    canReinstateBids,
    canRejectBids,
    canMoveSuppliersToStage,
  } = rfx.useRfxPermissions();

  return useMemo(() => {
    const hasRequestEnded = status === RfqStatus.AWARDED || status === RfqStatus.CLOSED;

    const isMultiStageRequest = size(stages) > 1;

    const isMoveToNextStageVisible = (
      canMoveSuppliersToStage &&
      numVisibleRecipients > 0 &&
      isMultiStageRequest &&
      !hasRequestEnded
    );

    const isRejectBidsVisible = canRejectBids && !hasRequestEnded && areActiveBidsSelected;

    const isRejectBidsDisabled = (
      numVisibleRecipients === 0 ||
      every(bidById, { status: BidStatus.UNSUCCESSFUL })
    );

    const isReinstateBidsVisible = (
      canReinstateBids &&
      some(bidById, { status: BidStatus.UNSUCCESSFUL }) &&
      !hasRequestEnded
    );

    const isReinstateBidsDisabled = numVisibleRecipients === 0;

    const genericActionConfigs = [
      {
        showInGridHeader: true,
        isDisabledInGridHeader: !numSelectedRecipients,
        showInContextMenu: hasSelectedInvitedRecipients,
        id: 'sendInviteReminder',
        type: 'item',
        icon: 'envelope',
        isIconRegular: true,
        onSelect: sendInviteReminders,
        label: t('request.suppliersTable.sendInviteReminder'),
      },
      {
        showInGridHeader: true,
        isDisabledInGridHeader: numSelectedRecipients === 0,
        showInContextMenu: numSelectedRecipients > 0,
        id: 'downloadFiles',
        type: 'item',
        icon: 'download',
        isIconRegular: true,
        onSelect: downloadFiles,
        label: t('request.suppliersTable.downloadFiles'),
      },
      {
        showInGridHeader: isMoveToNextStageVisible,
        isDisabledInGridHeader: !numSelectedRecipients || isMoveToNextStageDisabled,
        showInContextMenu: isMoveToNextStageVisible && hasSelectedActiveRecipientsNotInFinalStage,
        id: 'moveToNextStage',
        type: 'item',
        icon: 'arrow-right',
        onSelect: moveToNextStage,
        label: t('request.suppliersTable.moveToNextStage'),
      },
      {
        showInGridHeader: values(canRequestApprovalByStageId).some(identity),
        isDisabledInGridHeader: !(
          selectedSupplierStageIds.length === 1 &&
          Boolean(canRequestApprovalByStageId?.[selectedSupplierStageIds[0]])
        ),
        showInContextMenu: (
          selectedSupplierStageIds.length === 1 &&
          Boolean(canRequestApprovalByStageId?.[selectedSupplierStageIds[0]])
        ),
        id: 'requestStageApproval',
        type: 'item',
        icon: 'check-circle',
        isIconRegular: true,
        onSelect: requestApproval,
        label: t('request.suppliersTable.requestStageApproval'),
        gridHeaderDescription: selectedSupplierStageIds.length > 1
          ? t('request.suppliersTable.onlyAvailableInSameCurrentStage')
          : undefined,
      },
    ] as const;

    const bidActionConfigs = [
      {
        showInGridHeader: isUnlockVisible,
        isDisabledInGridHeader: numSelectedRecipients === 0,
        showInContextMenu: numSelectedRecipients > 0 && isUnlockVisible,
        id: 'unlockBid',
        type: 'item',
        icon: 'unlock',
        isIconRegular: true,
        onSelect: unlockBids,
        label: t('request.suppliersTable.unlockBid'),
      },
      {
        showInGridHeader: (canDeclineOnBehalf && areUnresponsiveBidsSelected),
        isDisabledInGridHeader: numSelectedRecipients === 0,
        showInContextMenu: numSelectedRecipients > 0 && (canDeclineOnBehalf && areUnresponsiveBidsSelected),
        id: 'declineToBid',
        type: 'item',
        icon: 'times',
        onSelect: onDeclineToBid,
        label: t('request.suppliersTable.declineToBid'),
      },
      {
        showInGridHeader: isRejectBidsVisible,
        isDisabledInGridHeader: numSelectedRecipients === 0 || isRejectBidsDisabled,
        showInContextMenu: numSelectedRecipients > 0 && isRejectBidsVisible && !isRejectBidsDisabled,
        id: 'rejectBid',
        type: 'item',
        icon: 'times',
        onSelect: rejectBids,
        label: t('request.suppliersTable.rejectBid'),
      },
      {
        showInGridHeader: isReinstateBidsVisible,
        isDisabledInGridHeader: numSelectedRecipients === 0 || isReinstateBidsDisabled,
        showInContextMenu: numSelectedRecipients > 0 && isReinstateBidsVisible && !isReinstateBidsDisabled,
        id: 'reinstateBid',
        type: 'item',
        icon: 'redo',
        onSelect: reinstateBids,
        label: t('request.suppliersTable.reinstateBid'),
      },
    ] as const;

    return [
      ...genericActionConfigs,
      {
        id: 'divider',
        showInGridHeader: (
          genericActionConfigs.some(config => config.showInGridHeader) &&
          bidActionConfigs.some(config => config.showInGridHeader)
        ),
        showInContextMenu: (
          genericActionConfigs.some(config => config.showInContextMenu) &&
          bidActionConfigs.some(config => config.showInContextMenu)
        ),
        type: 'divider',
      },
      ...bidActionConfigs,
    ];
  }, [
    areActiveBidsSelected,
    areUnresponsiveBidsSelected,
    bidById,
    canDeclineOnBehalf,
    canMoveSuppliersToStage,
    canReinstateBids,
    canRejectBids,
    canRequestApprovalByStageId,
    selectedSupplierStageIds,
    downloadFiles,
    isMoveToNextStageDisabled,
    isUnlockVisible,
    moveToNextStage,
    numSelectedRecipients,
    numVisibleRecipients,
    onDeclineToBid,
    reinstateBids,
    rejectBids,
    requestApproval,
    sendInviteReminders,
    stages,
    status,
    t,
    unlockBids,
    hasSelectedInvitedRecipients,
    hasSelectedActiveRecipientsNotInFinalStage,
  ]);
};
