import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import * as Layout from '../../Layout';
import { useSupplierListNavigation } from '../../appNavigation';
import { ListConfigFlow } from './ListConfigFlow/ListConfigFlow';
import { useSupplierListData } from './SupplierListProvider';

export const SupplierListEditPageContent = () => {
  const { t } = useTranslation();
  const supplierList = useSupplierListData();
  const {
    navigateToLists,
    getExternalNetworkLinkProps,
    getSupplierListsLinkProps,
    getSupplierListSettingsLinkProps,
  } = useSupplierListNavigation();

  const breadcrumbs = useMemo(
    () => [{
      title: t('general.network'),
      linkProps: getExternalNetworkLinkProps(),
    }, {
      title: t('supplierLists.list', { count: 2 }),
      linkProps: getSupplierListsLinkProps(),
    }, {
      title: supplierList.name,
      linkProps: getSupplierListSettingsLinkProps(supplierList._id),
    }],
    [supplierList, t, getExternalNetworkLinkProps, getSupplierListsLinkProps, getSupplierListSettingsLinkProps],
  );

  return (
    <Layout.HeaderAndContent
      header={
        <Layout.PageHeaderWithBreadcrumbs
          heading={t('supplierLists.editList', { count: 2 })}
          breadcrumbs={breadcrumbs}
        >
          <Button small variant="danger-outline" mt={1} onClick={() => navigateToLists()}>
            {t('general.exitProcess')}
          </Button>
        </Layout.PageHeaderWithBreadcrumbs>
      }
      content={<ListConfigFlow supplierList={supplierList} />}
    />
  );
};
