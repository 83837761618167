import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { first, keyBy, map, pick } from 'lodash';
import { Text } from 'rebass/styled-components';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { FeesExchangeDefinition, RfqEventChange, ExchangeChange, VesselPricingSection, ExchangeType, Vessel } from '@deepstream/common/rfq-utils';
import { diffArrayBy } from '@deepstream/utils';
import { groupBy2d } from '@deepstream/utils/groupBy2d';
import { CancelButton, SaveButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelHeader, PanelPadding, PanelSubHeader } from '@deepstream/ui-kit/elements/Panel';
import { Modal, ModalFooter, ModalBody, ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import { ExchangeTableBase } from './ExchangeTable';
import { useExchangeColumns } from './modules/Exchange/columns';
import * as rfx from './rfx';
import { CurrencyCodeProvider } from './ui/Currency';
import { useModalState } from './ui/useModalState';
import { FeeTableField } from './draft/FeeTable';
import { EditFeeBidModal, ViewFeeModal } from './EditFeeBidModal';
import { mappings, mapDiffToChanges } from './ui/diff';
import { useToaster } from './toast';
import { Disclosure } from './ui/Disclosure';
import { useUpdateExchanges } from './useUpdateExchanges';
import { useHaveDeadlinesPassed } from './deadline';
import { ExchangeSnapshot } from './types';
import { ExchangeProvider } from './useExchange';
import { EditSupplierExchangeDefsButton } from './modules/Request/Live/EditSupplierExchangeDefsButton';

const sanitize = <T,> (items: T[], diffKeys: string[]) =>
  map(items, def => pick(def, diffKeys));

const getChanges = <TChange extends RfqEventChange> ({ next, previous, keys, mapping }): TChange[] =>
  mapDiffToChanges(
    diffArrayBy(sanitize(next, keys), sanitize(previous, keys), '_id'),
    mappings[mapping],
  ) as any;

const obsoleteExchangesLast = (defX, defY) => (defX.isObsolete === defY.isObsolete) ? 0 : defX.isObsolete ? 1 : -1;

const EditSupplierAddedExchangesModal = ({ isOpen, onSuccess, onCancel, vesselId }) => {
  const { t } = useTranslation();
  const section = rfx.useSection();
  const toaster = useToaster();

  const previousSupplierAddedDefs = rfx.useSectionExchangeDefsByCreator<FeesExchangeDefinition>({
    group: 'recipient',
    filter: def => def.type === ExchangeType.FEES && def.vesselId === vesselId,
  });

  const [updateExchanges] = useUpdateExchanges({
    getChanges: (nextSupplierAddedDefs: FeesExchangeDefinition[]) =>
      getChanges<ExchangeChange>({
        mapping: 'exchangeDef',
        next: nextSupplierAddedDefs.map(def => ({ ...def, vesselId, isLive: true })),
        previous: previousSupplierAddedDefs,
        keys: ['_id', 'type', 'description', 'feeType', 'isObsolete', 'vesselId', 'isLive'],
      }).map(change => {
        change.sectionName = section._id;
        return change;
      }),
    onSuccess: () => toaster.success(t('request.toaster.changesSavedSuccess')),
    onError: () => toaster.error('request.toaster.changesSavedError'),
  });

  return (
    <Modal
      shouldCloseOnEsc
      isOpen={isOpen}
      onRequestClose={onCancel}
      style={{ content: { width: '810px' } }}
    >
      <Formik<{ exchangeDefs: FeesExchangeDefinition[] }>
        validateOnBlur
        enableReinitialize
        initialValues={{
          exchangeDefs: previousSupplierAddedDefs,
        }}
        validationSchema={
          yup.object().shape({
            exchangeDefs: yup.array().of(
              yup.object().shape({
                description: yup.string().required(t('general.required')),
                feeType: yup.string().required(t('general.required')),
              }),
            ),
          })
        }
        onSubmit={async ({ exchangeDefs }, { setSubmitting }) => {
          await updateExchanges(exchangeDefs, {
            onSuccess,
            onSettled: () => setSubmitting(false),
          });
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              {t('request.vesselPricing.fees.editSupplierAddedFees')}
            </ModalHeader>
            <ModalBody>
              <FeeTableField vesselId={vesselId} />
            </ModalBody>
            <ModalFooter>
              <CancelButton type="button" onClick={onCancel} mr={2} />
              <SaveButton disabled={isSubmitting || !isValid} />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

type ExchangeTableSectionProps = {
  vessel: Vessel;
  isRecipient: boolean;
  hasEditPermissions: boolean;
  exchanges: ExchangeSnapshot[];
};

export const FeeBidSection: React.FC<ExchangeTableSectionProps> = ({
  vessel,
  isRecipient,
  hasEditPermissions,
  exchanges,
}) => {
  const { t } = useTranslation();
  const { exchangeDefById } = rfx.useStructure();
  const section: VesselPricingSection = rfx.useSection();
  const editFeesModal = useModalState();
  const editFeeBidModal = useModalState();
  const haveDeadlinesPassed = useHaveDeadlinesPassed();
  const isBidding = rfx.useIsBidding();

  const {
    exchangeById,
    exchangesInitiatedByRecipient,
    exchangesInitiatedBySender,
    currencyCode,
  } = React.useMemo(() => {
    const filterByVesselId = (exchange) => exchange.def.vesselId === vessel._id;

    const exchangesInitiatedBy = groupBy2d(
      exchanges,
      exchange => {
        const exchangeDef = exchangeDefById[exchange._id];
        // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
        return exchange.companies[exchangeDef.creatorId].group!;
      },
      exchange => exchange.def.type,
    );

    return {
      exchangeById: keyBy(exchanges, exchange => exchange._id),
      exchangesInitiatedByRecipient: exchangesInitiatedBy.recipient?.fees?.filter(filterByVesselId) ?? [],
      exchangesInitiatedBySender: exchangesInitiatedBy.sender?.fees ?? [],
      currencyCode: first(exchangesInitiatedBy.sender?.currency as any[])?.latestCurrency ?? '',
    };
  }, [exchanges, exchangeDefById, vessel._id]);

  const hasRecipientAddedSubSection = section?.allowSuppliersToAddFees;
  const hasBuyerAddedSubSection = Boolean(exchangesInitiatedBySender.length);

  const [selectedExchangeId, setSelectedExchangeId] = React.useState<string | null>(null);

  const openExchange = React.useCallback(
    exchange => {
      setSelectedExchangeId(exchange._id);
      editFeeBidModal.open();
    },
    [editFeeBidModal],
  );

  const exchangeColumns = useExchangeColumns();
  const feeColumns = React.useMemo(
    () => exchangeColumns.fees(vessel._id),
    [vessel._id, exchangeColumns],
  );

  // @ts-expect-error ts(2538) FIXME: Type 'null' cannot be used as an index type.
  const selectedExchange = exchangeById[selectedExchangeId];

  if (!hasBuyerAddedSubSection && !hasRecipientAddedSubSection) {
    return null;
  }

  return (
    <CurrencyCodeProvider code={currencyCode}>
      <>
        <Panel>
          <PanelHeader heading={t('request.vesselPricing.fees.fee_other')} variant="subheader" />
          {hasBuyerAddedSubSection && (
            <>
              <PanelDivider />
              {hasRecipientAddedSubSection && (
                <>
                  <PanelSubHeader heading={t('request.vesselPricing.fees.buyerAddedFees')} />
                  <PanelDivider />
                </>
              )}
              <ExchangeTableBase
                isPaginated={false}
                columns={feeColumns}
                exchanges={exchangesInitiatedBySender.sort(obsoleteExchangesLast)}
                onRowClick={openExchange}
              />
            </>
          )}
          {hasRecipientAddedSubSection && (
            <>
              <PanelDivider />
              <PanelSubHeader heading={t('request.vesselPricing.fees.supplierAddedFees')}>
                {isRecipient && hasEditPermissions ? (
                  <EditSupplierExchangeDefsButton onClick={editFeesModal.open} />
                ) : (
                  null
                )}
              </PanelSubHeader>
              <PanelDivider />
              {exchangesInitiatedByRecipient.length ? (
                <ExchangeTableBase
                  isPaginated={false}
                  columns={feeColumns}
                  exchanges={exchangesInitiatedByRecipient.sort(obsoleteExchangesLast)}
                  onRowClick={openExchange}
                />
              ) : (
                <PanelPadding>
                  <Text color="subtext" fontSize={2} mb={3}>
                    {t('request.vesselPricing.fees.noFeesAdded')}
                  </Text>
                  {isRecipient ? (
                    <Disclosure summary={t('request.vesselPricing.fees.supplierAddedDisclosure.recipient.summary')}>
                      {t('request.vesselPricing.fees.supplierAddedDisclosure.recipient.content')}
                    </Disclosure>
                  ) : (
                    <Disclosure summary={t('request.vesselPricing.fees.supplierAddedDisclosure.sender.summary')}>
                      {t('request.vesselPricing.fees.supplierAddedDisclosure.sender.content')}
                    </Disclosure>
                  )}
                </PanelPadding>
              )}
            </>
          )}
        </Panel>
        <EditSupplierAddedExchangesModal
          isOpen={editFeesModal.isOpen}
          onSuccess={editFeesModal.close}
          onCancel={editFeesModal.close}
          vesselId={vessel._id}
        />
        {selectedExchange && (
          <ExchangeProvider exchange={selectedExchange}>
            {(
              !isRecipient ||
              !hasEditPermissions ||
              selectedExchange.isObsolete ||
              (haveDeadlinesPassed && !selectedExchange.extendedDeadline) ||
              selectedExchange.disabledReason ||
              !isBidding
            ) ? (
              <ViewFeeModal
                feeExchange={selectedExchange}
                vesselId={vessel._id}
                isOpen={editFeeBidModal.isOpen}
                onClose={editFeeBidModal.close}
                isRecipient={isRecipient}
              />
            ) : (
              <EditFeeBidModal
                feeExchange={selectedExchange}
                currencyCode={currencyCode}
                vesselId={vessel._id}
                sectionId={section._id}
                isOpen={editFeeBidModal.isOpen}
                onSuccess={editFeeBidModal.close}
                onCancel={editFeeBidModal.close}
              />
            )}
          </ExchangeProvider>
        )}
      </>
    </CurrencyCodeProvider>
  );
};
