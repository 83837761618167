import * as React from 'react';
import { QuestionElementType } from '@deepstream/common/legacy-pre-q-utils';
import { ElementProps } from './common';

// Primitive elements
import { SelectElement } from './SelectElement';
import { RadioElement } from './RadioElement';
import { TextboxElement } from './TextboxElement';
import { AttachmentsElement } from './AttachmentsElement';
import { DatetimeElement } from './DatetimeElement';
import { SwitchElement } from './SwitchElement';

// Composite elements (ie: elements made of elements)
import { ListElement } from './ListElement'; // eslint-disable-line import/no-cycle
import { MoneyElement } from './MoneyElement'; // eslint-disable-line import/no-cycle
import { AddressElement } from './AddressElement'; // eslint-disable-line import/no-cycle
import { TableElement } from './TableElement'; // eslint-disable-line import/no-cycle
import { HiddenElement } from './HiddenElement';

const elementTypeToComponent: Record<any, any> = {
  [QuestionElementType.TEXTBOX]: TextboxElement,
  [QuestionElementType.NUMBER]: TextboxElement,
  [QuestionElementType.ATTACHMENTS]: AttachmentsElement,
  [QuestionElementType.USER]: TextboxElement,
  [QuestionElementType.DATE_TIME]: DatetimeElement,
  [QuestionElementType.LIST]: ListElement,
  [QuestionElementType.MONEY]: MoneyElement,
  [QuestionElementType.RADIO]: RadioElement,
  [QuestionElementType.ADDRESS]: AddressElement,
  [QuestionElementType.TABLE]: TableElement,
  [QuestionElementType.DROPDOWN]: SelectElement, // options OK
  [QuestionElementType.SWITCH]: SwitchElement,
  hidden: HiddenElement,
};

export const Element: React.FC<ElementProps> = React.memo((props) => {
  const ElementComponent = elementTypeToComponent[props.element.type];

  return (
    <ElementComponent {...props} />
  );
});
