import { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { Formik, FormikProps } from 'formik';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { QuestionType, ShortTextSchemaType, QuestionFormat, PredefinedQuestionOption, QuestionExchangeDefinition } from '@deepstream/common/rfq-utils';
import { values, omit, includes, isEqual, isNil } from 'lodash';

import { callAll } from '@deepstream/utils/callAll';
import { Modal } from '@deepstream/ui-kit/elements/popup/Modal';
import { FieldType } from '@deepstream/common/exchangesConfig';
import { useToaster } from '../../../../toast';
import { contactFields, addressFields } from '../../../../draft/QuestionFields';
import {
  QuestionModalValues,
  useDefaultCategory,
  useCreateQuestion,
  useListQuestionsQueryKey,
  useListCategoriesQueryKey,
  useUpdateQuestion,
} from '../../utils';
import { QuestionForm } from './QuestionForm';
import { PreQualQuestion } from '../../../../types';
import { useCurrentCompanyId } from '../../../../currentCompanyId';
import { useApi, wrap } from '../../../../api';
import { ConfirmationModalContent } from './ConfirmationModalContent';

type PreviousValues = { _id?: string; description: string; categoryId: string; } & Partial<Record<QuestionType, Record<string, unknown>>>;

const typeSpecificDefaultValues = {
  [QuestionType.CHECKBOXES]: { options: [''], allowCustomOption: false },
  [QuestionType.MULTIPLE_CHOICE]: { options: [''], allowCustomOption: false },
  [QuestionType.ADDRESS]: { visibleFields: [...contactFields, ...addressFields] },
  [QuestionType.DATE_TIME]: { format: QuestionFormat.DATE },
  [QuestionType.DOCUMENT]: {
    options: [PredefinedQuestionOption.YES, PredefinedQuestionOption.NO],
    allowCustomOption: false,
    requireDocument: true,
    requireExpiry: true,
  },
  [QuestionType.GRID]: {
    columns: [{ id: uuid(), name: '', type: FieldType.STRING }],
    rowsConfig: {
      isCustom: false,
    },
  },
  [QuestionType.YES_NO]: {
    options: [PredefinedQuestionOption.YES, PredefinedQuestionOption.NO],
    allowCustomOption: false,
    requireMoreInformationOn: [],
  },
};

const useValidationSchema = () => {
  const { t } = useTranslation(['general', 'translation']);

  return useMemo(() => {
    return {
      [QuestionType.CHECKBOXES]: yup.object().shape({
        options: yup.array().of(yup.string()).when('allowCustomOption', {
          is: (allowCustomOption) => !allowCustomOption,
          then: yup.array().of(yup.string().required(t('required'))).min(1),
        }),
      }),

      [QuestionType.MULTIPLE_CHOICE]: yup.object().shape({
        options: yup.array().of(yup.string()).when('allowCustomOption', {
          is: (allowCustomOption) => !allowCustomOption,
          then: yup.array().of(yup.string().required(t('required'))).min(1),
        }),
      }),

      [QuestionType.ADDRESS]: yup.object().shape({
        visibleFields: yup.array().of(yup.string())
          .min(1, t('request.question.validation.atLeastOneAddressField', { ns: 'translation' })),
      }),

      [QuestionType.SHORT_TEXT]: yup.object().shape({
        schema: yup.object().shape({
          min: yup.number().required(t('required')),
          max: yup.number().required(t('required')),
          type: yup.mixed().oneOf(values(ShortTextSchemaType)).required(t('required')),
        }).default(null).nullable(),
      }),

      [QuestionType.DATE_TIME]: yup.object().shape({
        format: yup.string().oneOf(
          [QuestionFormat.DATE, QuestionFormat.TIME, QuestionFormat.DATETIME],
          t('request.question.validation.atLeastOneDateField', { ns: 'translation' }),
        ),
      }),

      [QuestionType.GRID]: yup.object().shape({
        columns: yup.array().of(yup.object({
          name: yup.string().required(t('required')).min(1),
        })).required(t('required')).min(1),
        rowsConfig: yup.object().shape({
          isCustom: yup.boolean().required(t('required')),
          min: yup.number().when('isCustom', {
            is: true,
            then: yup.number()
              .required(t('required'))
              .min(1, t('request.question.validation.minimumOne', { ns: 'translation' }))
              .when(
                'max',
                {
                  is: max => !isNil(max),
                  then: schema => schema
                    .max(yup.ref('max'), t('request.question.validation.invalid', { ns: 'translation' })),
                },
              ),
          }),
          max: yup.number().when('isCustom', {
            is: true,
            then: yup.number()
              .required(t('required'))
              .when(
                'min',
                {
                  is: min => !isNil(min),
                  then: schema => schema
                    .min(yup.ref('min'), t('request.question.validation.invalid', { ns: 'translation' })),
                },
              ),
          }),
        }),
        currencies: yup.array().of(yup.string()).when('columns', {
          is: (columns) => columns.some((column) => column.type === FieldType.PRICE),
          then: yup.array().of(yup.string()).required(t('required')).min(1),
        }),
      }),
    } as const;
  }, [t]);
};

export const QuestionModal = ({
  question,
  close,
  categoryId: categoryIdProp,
  isCreatedFromQuestionnaireTemplate,
  onAddQuestion,
}: {
  question?: PreQualQuestion;
  close: () => void;
  categoryId?: string;
  isCreatedFromQuestionnaireTemplate?: boolean;
  onAddQuestion?: (exchangeDef: QuestionExchangeDefinition) => void;
}) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const isEditMode = !!question;
  const questionDef = question?.currentVersion.exchangeDef;
  const [questionType, setQuestionType] = useState<QuestionType>(questionDef?.questionType || QuestionType.CHECKBOXES);
  const { t } = useTranslation(['preQualification', 'general', 'translation']);
  const formikRef = useRef<FormikProps<QuestionModalValues>>(null);
  const validationSchema = useValidationSchema();
  const defaultCategory = useDefaultCategory();
  const getListQuestionsQueryKey = useListQuestionsQueryKey();
  const getListCategoriesQueryKey = useListCategoriesQueryKey();
  const queryClient = useQueryClient();
  const [createQuestion] = useCreateQuestion();
  const [updateQuestion] = useUpdateQuestion();
  const toaster = useToaster();
  const [step, setStep] = useState<'form' | 'confirmation'>('form');

  const initialValues = useMemo(
    () => {
      if (!question) {
        return {};
      }

      const questionDef = question?.currentVersion.exchangeDef;
      const typeSpecificValues = omit(questionDef, ['_id', 'description', 'questionType', 'categoryId', 'creatorId', 'user']);

      return {
        description: questionDef.description,
        categoryId: categoryIdProp,
        [questionDef.questionType]: typeSpecificValues,
      };
    },
    [question, categoryIdProp],
  );

  const previousValues = useRef<PreviousValues>(initialValues as PreviousValues);

  const { data: templatesWithQuestion = [], isLoading } = useQuery(
    ['questionnaireTemplatesWithQuestion', { questionId: question?._id, currentCompanyId }],
    wrap(api.getQuestionnaireTemplatesWithQuestion),
    {
      enabled: isEditMode,
    },
  );

  const handleQuestionTypeChange = useCallback((questionType: QuestionType) => {
    // @ts-expect-error ts(18047) FIXME: 'formikRef.current' is possibly 'null'.
    const currentQuestionType = formikRef.current.values.questionType;
    // @ts-expect-error ts(18047) FIXME: 'formikRef.current' is possibly 'null'.
    const typeSpecificValues = omit(formikRef.current.values, ['_id', 'description', 'questionType', 'categoryId']);

    previousValues.current = {
      ...previousValues.current,
      // @ts-expect-error ts(18047) FIXME: 'formikRef.current' is possibly 'null'.
      description: formikRef.current.values.description,
      // @ts-expect-error ts(18047) FIXME: 'formikRef.current' is possibly 'null'.
      categoryId: formikRef.current.values.categoryId,
      // @ts-expect-error ts(18047) FIXME: 'formikRef.current' is possibly 'null'.
      [formikRef.current.values.questionType]: typeSpecificValues,
    };

    // Assign type specific values to all option based types in order to keep the config on both
    // By doing this we allow the user to keep the values if he realized he selected the wrong option based question type
    if (includes([QuestionType.MULTIPLE_CHOICE, QuestionType.CHECKBOXES], currentQuestionType)) {
      previousValues.current[QuestionType.MULTIPLE_CHOICE] = typeSpecificValues;
      previousValues.current[QuestionType.CHECKBOXES] = typeSpecificValues;
    }

    setQuestionType(questionType);
  }, [formikRef]);

  return (
    <Modal
      isOpen
      style={{
        content: {
          width: step === 'confirmation' ? '585px' : '800px',
          minWidth: step === 'confirmation' ? '585px' : '800px',
        },
      }}
    >
      <Formik<QuestionModalValues>
        validateOnMount
        enableReinitialize
        innerRef={formikRef}
        initialValues={{
          // @ts-expect-error ts(18048) FIXME: 'questionDef' is possibly 'undefined'.
          _id: isEditMode ? questionDef._id : undefined,
          description: previousValues.current.description || '',
          categoryId: previousValues.current.categoryId || categoryIdProp || defaultCategory?._id,
          questionType,
          isRequired: true,
          ...typeSpecificDefaultValues[questionType],
          ...previousValues.current[questionType],
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const resetQueryKeys = () => {
            // Reset all `categories` query to properly update the sidebar count
            const listCategoriesQueryKey = getListCategoriesQueryKey();
            const listQuestionsInNewCategoryQueryKey = getListQuestionsQueryKey(values.categoryId);
            const listQuestionsInOldCategoriesQueryKey = getListQuestionsQueryKey(categoryIdProp);
            const listAllQuestionsQueryKey = getListQuestionsQueryKey();

            queryClient.invalidateQueries(listCategoriesQueryKey);
            queryClient.invalidateQueries(listQuestionsInNewCategoryQueryKey);
            queryClient.invalidateQueries(listQuestionsInOldCategoriesQueryKey);
            queryClient.invalidateQueries(listAllQuestionsQueryKey);

            if (isEditMode) {
              // Invalidate caches for all draft questionnaire templates because they might get updated with the new question.
              queryClient.invalidateQueries(['questionnaireTemplate', { scope: 'draft' }]);
            }
          };

          const hasExchangeDefChanges = isEditMode && !isEqual(
            omit(values, ['categoryId']),
            omit(questionDef, ['user', 'creatorId']),
          );

          if (!isEditMode) {
            const question = await createQuestion(
              values,
              {
                onSuccess: callAll(
                  resetQueryKeys,
                  close,
                  () => toaster.success(t('toaster.questionCreated.success')),
                ),
                onError: () => toaster.error(t('toaster.questionCreated.failed')),
              },
            );
            if (onAddQuestion) {
              onAddQuestion(question.currentVersion.exchangeDef);
            }
          } else if (templatesWithQuestion.length === 0 || !hasExchangeDefChanges || step === 'confirmation') {
            return updateQuestion(
              question._id,
              values,
              {
                onSuccess: callAll(
                  resetQueryKeys,
                  close,
                  () => toaster.success(t('toaster.questionUpdated.success')),
                ),
                onError: () => toaster.error(t('toaster.questionUpdated.failed')),
              },
            );
          } else {
            setStep('confirmation');
            setSubmitting(false);
          }
        }}
        validationSchema={yup.object().shape({
          description: yup.string().required(t('required', { ns: 'general' })),
          questionType: yup.string().oneOf(values(QuestionType)).required(t('required', { ns: 'general' })),
        }).concat(validationSchema[questionType])}
      >
        {step === 'form' ? (
          <QuestionForm
            isEditMode={isEditMode}
            initialQuestionDef={isEditMode ? questionDef : undefined}
            initialCategoryId={isEditMode ? categoryIdProp : undefined}
            submitDisabled={isLoading}
            onCancel={close}
            onQuestionTypeChange={handleQuestionTypeChange}
            // @ts-expect-error ts(2322) FIXME: Type 'boolean | undefined' is not assignable to type 'boolean'.
            isCreatedFromQuestionnaireTemplate={isCreatedFromQuestionnaireTemplate}
          />
        ) : (
          <ConfirmationModalContent
            templatesWithQuestion={templatesWithQuestion}
            onCancel={() => setStep('form')}
          />
        )}
      </Formik>
    </Modal>
  );
};
