import * as React from 'react';
import { useDeviceSize } from '../ui/useDeviceSize';
import { LabelConfig, LabelConfigProvider, LabelConfigProviderProps } from '../LabelConfigProvider';

const summaryLabelStyles: Record<string, React.CSSProperties> = {
  default: {
    fontSize: 2,
    position: 'relative',
    top: '10px',
  },
};

export const LABEL_WIDTH = '200px';

export const SummaryLabelConfigProvider = (props: LabelConfigProviderProps) => {
    const { isExtraSmall } = useDeviceSize();

    return (
      <LabelConfigProvider
        variant={isExtraSmall ? LabelConfig.ABOVE : LabelConfig.LEFT}
        width={LABEL_WIDTH}
        style={isExtraSmall ? {} : summaryLabelStyles}
        {...props}
      />
    );
  };
