import * as React from 'react';
import { Stage, BidStatus as BidStatusEnum, Live, AuctionStatus, StageType } from '@deepstream/common/rfq-utils';
import { universalSupplierBidStatuses, supplierBidStatuses } from '@deepstream/common/rfq-utils/statusConfigs';
import styled from 'styled-components';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { DateFormat } from '@deepstream/utils';
import { useDeviceSize } from './ui/useDeviceSize';
import { Bold } from './Bold';
import { DeadlineCountdown } from './DeadlineCountdown';
import { CurrencyAmount, CurrencyCodeProvider } from './ui/Currency';
import { useCurrentCompanyId } from './currentCompanyId';
import { Locked } from './lock';
import * as rfx from './rfx';
import { useRecipientId } from './useRfq';
import { StageName } from './StageName';
import { useSystemFeatureFlags } from './systemFeatureFlags';
import { Datetime2 } from './Datetime';

const mobileStyles = {
  display: 'grid',
  gridTemplateColumns: '50% 50%',
  columnGap: '16px',
  rowGap: '25px',
};

const Column = styled<any>(Box)`
  height: 40px;
  margin-right: ${props => props.isLast ? '0px' : '30px'};
`;

const Label = styled(Bold)`
  display: inline-block;
  font-size: ${props => props.theme.fontSizes[1]}px;
  margin-bottom: ${props => props.theme.space[1]}px;
`;

const BidStatus = () => {
  const { t } = useTranslation();
  const { status, universalStatus, progress } = rfx.useBid();
  const systemFeatureFlags = useSystemFeatureFlags({ required: true });
  const recipientId = useRecipientId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const isSender = currentCompanyId !== recipientId;

  if (systemFeatureFlags.universalBidStatusEnabled) {
    if (!universalStatus) {
      throw new Error('No universal bid status found');
    }
  } else if (!status) {
    throw new Error('No bid status found');
  }

  // in the bid page header, supplierBidStatuses are displayed
  // for both suppliers and buyers
  const { icon } = systemFeatureFlags.universalBidStatusEnabled
    ? universalSupplierBidStatuses[universalStatus]
    : supplierBidStatuses[status];

  const label = systemFeatureFlags.universalBidStatusEnabled
    ? t(`request.universalSupplierBidStatus.${universalStatus}`)
    : t(`request.supplierBidStatus.${status}`);

  return (
    <Tooltip
      content={
        progress && (
          <Stack gap={1}>
            <Flex justifyContent="flex-start" alignItems="center" sx={{ gap: 2 }}>
              <Icon
                sx={{ borderRadius: 4 }}
                backgroundColor="white"
                padding="2px"
                color="success"
                icon="check-circle"
              />
              <Text flex={1}>{t('request.progress.complete')}:</Text>
              <Text>{progress.complete}</Text>
            </Flex>
            <Flex justifyContent="flex-start" alignItems="center" sx={{ gap: 2 }}>
              <Icon
                sx={{ borderRadius: 4 }}
                backgroundColor="white"
                padding="2px"
                color="danger"
                icon="exclamation-circle"
              />
              <Text flex={1}>{t('request.progress.yourActionRequired')}:</Text>
              <Text>{isSender ? progress.awaitingSender : progress.awaitingRecipient}</Text>
            </Flex>
            {progress.awaitingTeam > 0 && (
              <Flex justifyContent="flex-start" alignItems="center" sx={{ gap: 2 }}>
                <Icon
                  sx={{ borderRadius: 4 }}
                  backgroundColor="white"
                  padding="2px"
                  color="gray"
                  icon="exclamation-circle"
                />
                <Text flex={1}>{t('request.progress.teamActionRequired')}:</Text>
                <Text>{progress.awaitingTeam}</Text>
              </Flex>
            )}
            <Flex justifyContent="flex-start" alignItems="center" sx={{ gap: 2 }}>
              <Icon
                sx={{ borderRadius: 4 }}
                backgroundColor="white"
                padding="2px"
                color="gray"
                icon="circle-dashed"
              />
              <Text flex={1}>{isSender ? t('request.progress.waitingForSupplier') : t('request.progress.waitingForBuyer')}:</Text>
              <Text>{isSender ? progress.awaitingRecipient : progress.awaitingSender}</Text>
            </Flex>
            <Flex justifyContent="flex-start" alignItems="center" sx={{ gap: 2 }}>
              <Icon
                sx={{ borderRadius: 4 }}
                backgroundColor="white"
                padding="2px"
                color="gray"
                icon="info-circle"
              />
              <Text flex={1}>{t('request.progress.noActionNeeded')}:</Text>
              <Text>{progress.noAction}</Text>
            </Flex>
          </Stack>
        )
      }
    >
      <Flex alignItems="center" fontSize={2}>
        {icon && (
          <Icon icon={icon.value as IconProps['icon']} color={icon.color} regular={icon.isRegular} mr={1} />
        )}
        <Bold color="text">{label}</Bold>
      </Flex>
    </Tooltip>
  );
};

const StageIndicator: React.FC<{ stageIndex: number; stages: Stage<Live>[] }> = ({ stageIndex, stages }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      content={(
        <Stack gap={3}>
          {stages.map((stage, index) => (
            <Box key={stage._id}>
              <Text mb={1}>
                <StageName index={index} stage={stage} withPrefix />
              </Text>
              <Text>
                {t('general.ends')}: <Datetime2 value={stage.completionDeadline!} format={DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ} />
              </Text>
            </Box>
          ))}
        </Stack>
      )}
    >
      <Flex alignItems="center" fontSize={2}>
        <Icon icon="list-ul" mr={1} />
        <Bold>
          {t('general.countOfTotal', { count: stageIndex + 1, total: stages.length })}
        </Bold>
      </Flex>
    </Tooltip>
  );
};

export const BidStatusIndicators = () => {
  const { t } = useTranslation();
  const recipientId = useRecipientId();
  const { stages, auction } = rfx.useStructure<Live>();
  const bid = rfx.useBid();
  const { isExtraSmall } = useDeviceSize();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const isSender = currentCompanyId !== recipientId;

  const stageIndex = isSender
    ? bid.enteredStageIds.length - 1
    : bid.activatedStageIds.length
      ? bid.activatedStageIds.length - 1
      : 0;

  const stage = stages[stageIndex];
  const stageDeadline = rfx.useStageDeadline(stage._id);
  const hasActivatedAnyStage = bid.activatedStageIds.length > 0;
  const isWithdrawn = bid.status === BidStatusEnum.WITHDRAWN;

  const hasMultipleVisibleStages = stages.length > 1;
  const showStageIndicator = isSender
    ? hasMultipleVisibleStages
    : hasMultipleVisibleStages && hasActivatedAnyStage && !isWithdrawn;

  const isCancelledAuctionStage = (
    stage.type === StageType.AUCTION &&
    auction?.status === AuctionStatus.CANCELLED
  );

  const auctionPauseDate = rfx.useAuctionPauseDate(stage._id);

  return (
    <Flex sx={isExtraSmall ? mobileStyles : {}} ml={4}>
      <Column>
        <Label>{t('general.status')}</Label>
        <BidStatus />
      </Column>
      {showStageIndicator && (
        <Column>
          <Label>{t('general.stage', { count: 1 })}</Label>
          <StageIndicator stageIndex={stageIndex} stages={stages} />
        </Column>
      )}
      {hasActivatedAnyStage && !isWithdrawn && (
        <Column isLast={!bid.lineItemsTotal}>
          <Label>{t('general.deadline')}</Label>
          <DeadlineCountdown
            // @ts-expect-error ts(2322) FIXME: Type 'Date | null' is not assignable to type 'Date'.
            deadline={stageDeadline}
            isDeadlineAvailable={!isCancelledAuctionStage}
            referenceDate={auctionPauseDate}
            fontSize={2}
            iconStyle={{ fontSize: 2 }}
          />
        </Column>
      )}
      {bid.lineItemsTotal && (
        <Column isLast>
          <Label>{t('general.total')}</Label>
          {bid.lineItemsTotal.locked ? (
            <Box>
              <Locked />
            </Box>
          ) : (
            <Flex alignItems="center" fontSize={2}>
              <Bold>
                <CurrencyCodeProvider code={bid.lineItemsTotal.currencyCode}>
                  {/* Just like in the reducer-based bid page header,
                      show a dash when the amount is 0 */}
                  <CurrencyAmount value={bid.lineItemsTotal.amount || null} />
                </CurrencyCodeProvider>
              </Bold>
            </Flex>
          )}
        </Column>
      )}
    </Flex>
  );
};
