import { Box, Flex } from 'rebass/styled-components';
import {
  Company,
  isReplyField,
  isFormulaField,
} from '@deepstream/common/rfq-utils';
import { isNil, isFinite } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Clamp } from '@deepstream/ui-kit/elements/text/Clamp';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import {
  ColumnData,
  RowData,
} from '@deepstream/ui-kit/grid/core/utils';
import {
  RowHeight,
  rowHeightMeasurements,
} from '../rowHeight';
import * as rfx from '../../../../rfx';
import { CommentCount } from '../CommentCount';
import { getExchangeSnapshotStatus } from '../../../../exchangeStatus';
import { StatusIcon } from '../../../../ExchangeStatusCell';
import { ConvertedCurrency } from '../../../../ui/Currency';
import { formatOptions } from '../../../../ui/formatOptions';
import { NumberFormat } from '../../../../NumberFormat';
import { Datetime } from '../../../../Datetime';
import { SavingsDisplayMode } from './useSelectSavingsDisplayMode';
import { DiffFromStage } from './DiffFromStage';
import { ExtendedLineItemExchangeSnapshot, GridLineItemsSection, LineItemSubRowData, LineItemsSubcolumnConfig } from './types';
import { SubcolumnCellContainer } from './SubcolumnCellContainer';

const PriceWithPercentDiff = ({
  value,
  previousValue,
  currencyCode,
  stageIndex,
  decimalPlaces,
  savingsDisplayMode,
}: {
  value: number;
  previousValue: number;
  currencyCode: string;
  stageIndex?: number;
  decimalPlaces?: number;
  savingsDisplayMode: SavingsDisplayMode;
}) => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      sx={{ height: '100%', width: '100%' }}
      fontWeight="normal"
    >
      <Truncate sx={{ textAlign: 'right' }}>
        <ConvertedCurrency
          value={value}
          currencyCode={currencyCode}
          decimalPlaces={decimalPlaces}
        />
      </Truncate>
      <DiffFromStage
        value={value}
        previousValue={previousValue}
        // @ts-expect-error ts(2322) FIXME: Type 'number | undefined' is not assignable to type 'number'.
        stageIndex={stageIndex}
        savingsDisplayMode={savingsDisplayMode}
        currencyCode={currencyCode}
      />
    </Flex>
  );
};

const FormulaFieldValue = ({ exchange, field, lines, savingsDisplayMode }) => {
  const value = exchange.computedFormulas?.[field._id];
  const previousValue = exchange.previousComputedFormulas?.[field._id];

  return (
    isNil(value) || value === '' ? (
      <Truncate sx={{ textAlign: 'right' }}>
        <EmDash />
      </Truncate>
    ) : field.type === 'price' ? (
      isFinite(previousValue) && lines > 1 ? (
        <PriceWithPercentDiff
          value={value}
          previousValue={previousValue}
          currencyCode={exchange.currency}
          stageIndex={exchange.previousStageIndex}
          savingsDisplayMode={savingsDisplayMode}
        />
      ) : (
        <Truncate sx={{ textAlign: 'right' }}>
          <ConvertedCurrency
            value={value}
            currencyCode={exchange.currency}
          />
        </Truncate>
      )
    ) : (
      <Truncate sx={{ textAlign: 'right' }}>
        {value}
      </Truncate>
    )
  );
};

const SupplierReplyFieldValue = ({ exchange, field, lines, savingsDisplayMode }) => {
  const { t } = useTranslation();
  const value = field
    ? exchange.latestReply[field._id]
    : null;
  const previousValue = field
    ? exchange.previousReply?.[field._id]
    : null;

  return isNil(value) || value === '' ? (
    ['number', 'price'].includes(field.type) ? (
      <Truncate sx={{ textAlign: 'right' }}>
        <EmDash />
      </Truncate>
    ) : (
      <EmDash />
    )
  ) : field._id === 'leadTime:submitter' ? (
    <Truncate sx={{ textAlign: 'right' }}>
      <NumberFormat
        displayType="text"
        value={exchange.latestReply['leadTime:submitter'] as number}
        {...formatOptions.integer}
      />
    </Truncate>
  ) : field.type === 'number' ? (
    <Truncate sx={{ textAlign: 'right' }}>
      <NumberFormat
        displayType="text"
        thousandSeparator
        value={value}
      />
    </Truncate>
  ) : field.type === 'date' ? (
    <Datetime onlyDate value={value as Date} />
  ) : field.type === 'boolean' ? (
    <>
      {value ? (
        t('request.lineItems.deliveryDate.accepted')
      ) : (
        t('request.lineItems.deliveryDate.rejected')
      )}
    </>
  ) : field.type === 'price' ? (
    isFinite(previousValue) && lines > 1 ? (
      <PriceWithPercentDiff
        value={value}
        previousValue={previousValue}
        currencyCode={exchange.currency}
        stageIndex={exchange.previousStageIndex}
        decimalPlaces={field.decimalPlaces}
        savingsDisplayMode={savingsDisplayMode}
      />
    ) : (
      <Truncate sx={{ textAlign: 'right' }}>
        <ConvertedCurrency
          value={value}
          currencyCode={exchange.currency}
          decimalPlaces={field.decimalPlaces}
        />
      </Truncate>
    )
  ) : (
    <Clamp lines={lines}>{value}</Clamp>
  );
};

export const SupplierDataCellWithSubcolumns = ({
  exchange,
  subcolumnConfig,
  lines,
  savingsDisplayMode,
  row,
  column,
  isRowActive,
  openExchangeModal,
}: {
  exchange: ExtendedLineItemExchangeSnapshot;
  hasShortCellHeight: boolean;
  subcolumnConfig: LineItemsSubcolumnConfig[];
  lines: number;
  savingsDisplayMode: SavingsDisplayMode;
  row: RowData<LineItemSubRowData, GridLineItemsSection>;
  column: ColumnData<Company>;
  isRowActive: boolean;
  openExchangeModal: (
    row: RowData<LineItemSubRowData, GridLineItemsSection>,
    column: ColumnData<Company>
  ) => void;
}) => {
  const pagePermissions = rfx.usePagePermissions({ required: false });
  const exchangeStatus = getExchangeSnapshotStatus(exchange, pagePermissions);
  const { t } = useTranslation();

  const isShortHeightRow = row.height === rowHeightMeasurements[RowHeight.SHORT];

  return (
    <Flex flexDirection="row" alignItems="center" height="100%" width="100%">
      {subcolumnConfig.map(config => {
        if (config._id === 'exchangeStatus') {
          return (
            <SubcolumnCellContainer key={config._id} width={config.width} hideBorder>
              <Flex
                flexDirection={isShortHeightRow ? 'row-reverse' : 'column'}
                alignItems="center"
                justifyContent={isShortHeightRow ? 'center' : 'initial'}
                sx={{ width: '100%', gap: 1 }}
              >
                {/*
                  Don't show the status icon when the selected response tag doesn't
                  match the exchange's response tag. In this case, the cell value
                  mirrors the selected stage and we don't want to render an exchange
                  status based on a different stage next to it.
                */}
                {(!exchange.selectedResponseTag || !exchange.responseTag || exchange.selectedResponseTag === exchange.responseTag) && (
                  <StatusIcon
                    type={exchange.def.type}
                    status={exchangeStatus}
                    currentCompanyGroup="buyer"
                  />
                )}
                <Box
                  className="focus-descendant-primary"
                  // min-width is set as 0, which makes this <div> width
                  // to be smaller than its children
                  sx={{ minWidth: 'auto' }}
                >
                  {isRowActive ? (
                    <Tooltip content={t('request.exchange.openExchange') as string}>
                      <Icon
                        icon="expand"
                        sx={{
                          cursor: 'pointer',
                        }}
                        onClick={() => openExchangeModal(row, column)}
                      />
                    </Tooltip>
                  ) : !isShortHeightRow ? (
                    <CommentCount exchange={exchange} />
                  ) : (
                    null
                  )}
                </Box>
              </Flex>
            </SubcolumnCellContainer>
          );
        }

        const field = exchange.def.fields[config._id];

        return (
          <SubcolumnCellContainer key={config._id} width={config.width}>
            {!field ? (
              <>
                {t('general.notApplicableShort')}
              </>
            ) : isReplyField(field) ? (
              <SupplierReplyFieldValue
                exchange={exchange}
                field={field}
                lines={lines}
                savingsDisplayMode={savingsDisplayMode}
              />
            ) : isFormulaField(field) ? (
              <FormulaFieldValue
                exchange={exchange}
                field={field}
                lines={lines}
                savingsDisplayMode={savingsDisplayMode}
              />
            ) : t('general.notApplicableShort')}
          </SubcolumnCellContainer>
        );
      })}
    </Flex>
  );
};
