import { useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import { TabPanels, TabPanel, Tabs } from '../../ui/TabsVertical';
import { SidebarLayout } from '../../page-helpers';
import { LoadingPanel } from '../../ui/Loading';
import { ErrorPanel } from '../../ui/ErrorMessage';
import { useSystemFeatureFlags } from '../../systemFeatureFlags';
import { Questions } from './Questions/Questions';
import { ComingSoonOverlay } from './ComingSoonOverlay';
import { MockedSidebar } from './MockedSidebar';
import { MockedMainContent } from './MockedMainContent';
import { PreQualTab, useListCategories } from './utils';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useCurrentUser } from '../../useCurrentUser';
import { useLocalStorageState } from '../../useLocalStorageState';
import { Categories } from './Categories/Categories';
import { preQualificationQuestionsRoute, usePreQualNavigation } from '../../AppRouting';

export const PreQualificationQuestionsPage = () => {
  const { t } = useTranslation();
  const systemFeatureFlags = useSystemFeatureFlags();
  const { data: categories, isLoading, isError } = useListCategories();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const { tab } = preQualificationQuestionsRoute.useSearch();
  const { navigateToPreQualificationQuestions } = usePreQualNavigation();

  const [lastActiveTab, setLastActiveTab] = useLocalStorageState<string>({
    key: `${currentCompanyId}.${currentUser._id}.preQualificationQuestionsPage.lastActiveTab`,
    defaultValue: PreQualTab.ALL_CATEGORIES,
  });

  useEffect(
    () => {
      if (tab && tab !== lastActiveTab) {
        setLastActiveTab(tab);
      }
    },
    [tab, lastActiveTab, setLastActiveTab],
  );

  const tabs = useMemo(
    () => [
      PreQualTab.ALL_CATEGORIES,
      ...map(categories, category => category._id),
    ],
    [categories],
  );

  useEffect(
    () => {
      if (tabs && !tab) {
        navigateToPreQualificationQuestions(lastActiveTab || tabs[0], true);
      }
    },
    [lastActiveTab, tabs, tab, navigateToPreQualificationQuestions],
  );

  const onTabsChange = useCallback(
    (index) => {
      navigateToPreQualificationQuestions(tabs[index]);
    },
    [navigateToPreQualificationQuestions, tabs],
  );

  const selectedTabIndex = tabs.indexOf(tab);

  if (isLoading) {
    return <LoadingPanel />;
  }

  if (isError) {
    return <ErrorPanel error={t('errors.unexpected')} />;
  }

  // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'.
  if (!systemFeatureFlags.newPreQualEnabled) {
    return (
      <>
        <Tabs>
          <SidebarLayout sidebar={<MockedSidebar />}>
            <MockedMainContent />
          </SidebarLayout>
        </Tabs>
        <ComingSoonOverlay />
      </>
    );
  }

  return (
    // Not re-rendering the tabs after refetching the categories will break the tabs functionality
    // Use the actual categories object in order to re-render when a category is edited
    <Tabs key={`categories-${JSON.stringify(tabs)}`} index={selectedTabIndex} onChange={onTabsChange}>
      <SidebarLayout sidebar={<Categories />}>
        <TabPanels>
          <>
            <TabPanel key={PreQualTab.ALL_CATEGORIES}>
              <Questions />
            </TabPanel>
            {map(categories, (category, i) => (
              <TabPanel key={category._id}>
                {/* We need to offset categories index by 1 to account for `All categories` tab */}
                {(selectedTabIndex === i + 1) && (
                  <Questions categoryId={category._id} />
                )}
              </TabPanel>
            ))}
          </>
        </TabPanels>
      </SidebarLayout>
    </Tabs>
  );
};
