import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { Form, Formik, FormikProps } from 'formik';
import { ChangeType, Draft, SectionType, isAuctionTermsExchangeDef } from '@deepstream/common/rfq-utils';
import { find, pick } from 'lodash';
import * as yup from 'yup';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { callAll } from '@deepstream/utils/callAll';
import { CancelButton, EditButton, SaveButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelHeader, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import * as rfx from '../rfx';
import { TextField } from '../form/TextField';
import { auctionTermExchangeDefKeys } from './draft';
import { LeavePageModal } from './LeavePageModal';
import { AuctionTabId } from './AuctionTabId';

const panelId = AuctionTabId.BIDDER_AGREEMENT;

const AuctionConfigBidderAgreementView = () => {
  const { t } = useTranslation();
  const pagePermissions = rfx.usePagePermissions();
  const { startEditing } = rfx.useActions();
  const isAuctionEditable = rfx.useIsAuctionEditable();
  const sectionExchangeDefs = rfx.useSectionExchangeDefs();

  const exchangeDef = find(sectionExchangeDefs, isAuctionTermsExchangeDef);

  return exchangeDef ? (
    <>
      <PanelHeader
        heading={t('request.auction.sectionTitle.bidderAgreement')}
        description={t('request.auction.sectionDescription.bidderAgreement')}
        py={1}
      >
        {(pagePermissions.canEdit && isAuctionEditable) && (
          <EditButton
            small
            onClick={() => startEditing(panelId)}
          />
        )}
      </PanelHeader>
      <PanelDivider />
      <PanelPadding sx={{ whiteSpace: 'pre-wrap' }}>
        {exchangeDef.text || <EmDash />}
      </PanelPadding>
    </>
  ) : (
    null
  );
};

const AuctionConfigBidderAgreementInnerForm = ({
  isSubmitting,
  isValid,
  dirty,
  resetForm,
}: FormikProps<{ text: string }>) => {
  const { stopEditing } = rfx.useActions();
  const { t } = useTranslation();
  rfx.useFormResetOnAuctionStart();

  return (
    <Form style={{ width: '100%' }}>
      <PanelHeader
        heading={t('request.auction.sectionTitle.bidderAgreement')}
        description={t('request.auction.sectionDescription.bidderAgreement')}
        py={1}
      />
      <PanelDivider />
      <PanelPadding>
        <TextField
          name="text"
          isMultiLine
        />
      </PanelPadding>
      <PanelDivider />
      <PanelPadding>
        <Flex justifyContent="flex-end">
          <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
          <SaveButton disabled={isSubmitting || !dirty || !isValid} />
        </Flex>
      </PanelPadding>
      <LeavePageModal />
    </Form>
  );
};

const AuctionConfigBidderAgreementForm = () => {
  const { stopEditing } = rfx.useActions();
  const saveChanges = rfx.useSaveChanges();
  const sectionExchangeDefs = rfx.useSectionExchangeDefs();

  const exchangeDef = find(sectionExchangeDefs, isAuctionTermsExchangeDef);

  return exchangeDef ? (
    <Formik<{
      text: string;
    }>
      validateOnBlur
      enableReinitialize
      initialValues={{
        text: exchangeDef.text,
      }}
      validationSchema={
        yup.object().shape({
          text: yup.string(),
        })
      }
      onSubmit={({ text }) => saveChanges({
        changes: [{
          type: ChangeType.EXCHANGE_UPDATED,
          sectionName: exchangeDef.sectionId,
          docXDef: {
            ...pick(exchangeDef, auctionTermExchangeDefKeys),
            text,
          },
        }],
      }, {
        onSuccess: stopEditing,
      })}
      component={AuctionConfigBidderAgreementInnerForm}
    />
  ) : (
    null
  );
};

export const AuctionConfigBidderAgreementPanel = () => {
  const { sectionById } = rfx.useStructure<Draft>();

  const section = find(sectionById, { type: SectionType.AUCTION_TERMS });

  const { editingPanelId } = rfx.useState();

  const isEditingThisPanel = editingPanelId === panelId;

  return section ? (
    <rfx.SectionProvider section={section}>
      <Panel>
        {isEditingThisPanel ? (
          <AuctionConfigBidderAgreementForm />
        ) : (
          <AuctionConfigBidderAgreementView />
        )}
      </Panel>
    </rfx.SectionProvider>
  ) : (
    null
  );
};
