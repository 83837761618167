import * as React from 'react';
import { Box, BoxProps } from 'rebass/styled-components';

// Similar to the Truncate component, but supports multiple lines
export const LineClamp: React.FC<BoxProps & { lines: number }> = ({ lines, sx = {}, children, ...props }) => (
  <Box
    sx={{
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      ...sx,
    }}
    style={{ WebkitLineClamp: lines }}
    {...props}
  >
    {children}
  </Box>
);
