import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ExternalSupplier } from '@deepstream/common/rfq-utils';

import { withProps } from '@deepstream/ui-utils/withProps';
import { BulkImportTableStyle } from './TableStyles';
import { Table } from './Table';
import { TextCell } from './TextCell';
import { nestCells } from './nestCells';
import { ImportValidationErrorCell } from './draft/cell';
import { ImportValidationRowCells } from './draft/ValidationRowCells';

type Props = {
  externalSuppliers: ExternalSupplier[];
};

export const BulkImportSuppliersTable = ({ externalSuppliers }: Props) => {
  const { t } = useTranslation('integration');
  const columns = useMemo(
    () => [
      {
        Header: ' ',
        id: 'index',
        accessor: (_, i) => i + 1,
      },
      {
        Header: <>{t('external')}<br />{t('supplierId')}</>,
        accessor: 'externalSupplierId',
        Cell: nestCells(withProps(ImportValidationErrorCell, {
          fieldName: 'externalSupplierId',
        }), TextCell),
        canFilter: false,
      },
      {
        Header: <>{t('external')}<br />{t('supplierName')}</>,
        accessor: 'externalSupplierName',
        Cell: nestCells(withProps(ImportValidationErrorCell, {
          fieldName: 'externalSupplierName',
        }), TextCell),
        canFilter: false,
      },
      {
        Header: (
          <>{t('external')}<br /><Trans i18nKey="supplierContactEmails" ns="integration" components={{ br: <br /> }} /></>
        ),
        accessor: 'externalSupplierContactEmails',
        Cell: nestCells(withProps(ImportValidationErrorCell, {
          fieldName: 'externalSupplierContactEmails',
        }), TextCell),
        canFilter: false,
      },
    ],
    [t],
  );

  return (
    <BulkImportTableStyle stickyHeader fixedRowHeight px="4px" py="4px">
      <Table
        columns={columns}
        data={externalSuppliers}
        CustomRowCells={ImportValidationRowCells}
      />
    </BulkImportTableStyle>
  );
};
