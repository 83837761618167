import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';

import { ReadOnlyStages } from '../../../ReadOnlyStages';

import { LoadingPanel } from '../../../ui/Loading';
import { ErrorPanel } from '../../../ui/ErrorMessage';

import { SummaryPanels } from '../../../draft/SummaryPanels';

import { useCurrentCompanyId } from '../../../currentCompanyId';
import { RfqIdProvider, useLiveRfqStructure } from '../../../useRfq';
import * as rfx from '../../../rfx';
import { RequestPages } from '../RequestPages';
import { RequestLiveVisibility } from '../../RequestVisibility/RequestVisibility';
import { ModelSizeLimitsProvider } from '../../../modelSizeLimits';

export const RequestLiveDetails = ({
  rfqId,
  recipientId,
}: {
  rfqId: string;
  recipientId?: string;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { data: rfxStructure, isLoading, isError, isSuccess } = useLiveRfqStructure({
    rfqId,
    currentCompanyId,
    recipientId,
  });

  return (
    <RfqIdProvider rfqId={rfqId}>
      <rfx.StateProvider isLive>
        {isLoading ? (
          <LoadingPanel />
        ) : isError ? (
          <ErrorPanel error={t('errors.unexpected')} />
        ) : isSuccess && rfxStructure ? (
          // TODO instead of providing dummy model size limits here, better
          // refactor document (and other?) section panel components to only
          // depend on model sizes in edit mode
          <ModelSizeLimitsProvider maxComplexity={0} maxExchangeDefCount={0}>
            <rfx.StructureProvider structure={rfxStructure}>
              <SummaryPanels />

              <Box mb="20px">
                {/* TODO: Extract this in Request module and adjust to increase reusability */}
                <ReadOnlyStages
                  rfqId={rfqId}
                  recipientId={recipientId}
                  navigateToDraftStages={noop}
                  isLive
                />
              </Box>

              <RequestLiveVisibility />

              <RequestPages />
            </rfx.StructureProvider>
          </ModelSizeLimitsProvider>
        ) : (
          null
        )}
      </rfx.StateProvider>
    </RfqIdProvider>
  );
};
