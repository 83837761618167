import { assign, createMachine, DoneInvokeEvent } from 'xstate';
import { Attachment, CompanyMinimized } from '@deepstream/common/rfq-utils';
import { UserBase } from '../Request/Team/UserSelect';

type StageApprovalRequestEvent = DoneInvokeEvent<any>;

export enum StageApprovalRequestActions {
  BACK = 'back',
  CONTINUE = 'continue',
  RESET_TO_INITIAL_CONTEXT = 'resetToInitialContext',
  SET_IS_DIRTY = 'setIsDirty',
  ADD_REQUEST_DETAILS = 'addRequestDetails',
  ADD_APPROVERS = 'addApprovers',
  SUBMIT_SUCCESS = 'submitSuccess',
}

export interface StageApprovalRequestContext {
  rfqId: string;
  stageId?: string;
  availableStageIds?: string[];
  recipients?: CompanyMinimized[];
  approvers: UserBase[];
  message: string;
  attachments: Attachment[];
  isDirty: boolean;
}

export const stageApprovalRequestInitialContext = {
  rfqId: '',
  stageId: undefined,
  availableStageIds: undefined,
  recipients: [],
  approvers: [],
  message: '',
  attachments: [],
  isDirty: false,
};

export const stageApprovalRequestMachine = createMachine<
  StageApprovalRequestContext,
  StageApprovalRequestEvent
>(
  {
    id: 'stageApprovalRequestMachine',
    initial: 'approvalDetails',
    on: {
      [StageApprovalRequestActions.RESET_TO_INITIAL_CONTEXT]: {
        target: 'approvalDetails',
        actions: ['resetToInitialContext'],
      },
    },
    states: {
      approvalDetails: {
        id: 'approvalDetails',
        on: {
          [StageApprovalRequestActions.ADD_REQUEST_DETAILS]: {
            target: '#approversSelect',
            actions: ['addApprovalDetailsToContext'],
          },
          [StageApprovalRequestActions.SET_IS_DIRTY]: {
            target: '#approvalDetails',
            actions: ['setIsDirty'],
          },
        },
      },
      approversSelect: {
        id: 'approversSelect',
        on: {
          [StageApprovalRequestActions.BACK]: 'approvalDetails',
          [StageApprovalRequestActions.ADD_APPROVERS]: {
            target: '#reviewAndIssue',
            actions: ['addApproversToContext'],
          },
        },
      },
      reviewAndIssue: {
        id: 'reviewAndIssue',
        states: {},
        on: {
          [StageApprovalRequestActions.BACK]: 'approversSelect',
          [StageApprovalRequestActions.SUBMIT_SUCCESS]: 'submitSuccess',
        },
      },
      submitSuccess: {
        id: 'submitSuccess',
      },
    },
  },
  {
    actions: {
      resetToInitialContext: assign((_, event) => event.data),
      setIsDirty: assign({
        isDirty: (_, event) => Boolean(event.data.isDirty),
      }),
      addApprovalDetailsToContext: assign({
        stageId: (_, event) => event.data.stageId,
        recipients: (_, event) => event.data.recipients,
        message: (_, event) => event.data.message,
        attachments: (_, event) => event.data.attachments,
      }),
      addApproversToContext: assign({
        approvers: (_, event) => event.data.approvers,
      }),
    },
    guards: {},
  },
);
