import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { compact } from 'lodash';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { PreWrapValueOrEmDash, PropertyList, ValueOrEmDash } from '../../../../PropertyList';
import { useIsSender, useQuestionnaireData, useQuestionnaireState } from '../questionnaireUtils';

export const QuestionnaireBasicDetailsPanel = () => {
  const { t } = useTranslation(['preQualification', 'general']);
  const questionnaire = useQuestionnaireData();
  const isSender = useIsSender();
  // @ts-expect-error ts(2339) FIXME: Property 'editingPanelId' does not exist on type 'QuestionnaireStateContextType | undefined'.
  const { editingPanelId } = useQuestionnaireState();

  const properties = useMemo(() => compact([
    {
      fieldName: 'name',
      name: t('questionnaireSummary.fieldName.name'),
      value: questionnaire.summary.name,
      Component: ValueOrEmDash,
    },
    {
      fieldName: 'description',
      name: t('questionnaireSummary.fieldName.description'),
      value: questionnaire.summary.description,
      Component: PreWrapValueOrEmDash,
      heightAuto: true,
    },
    {
      fieldName: 'numQuestions',
      name: t('numberOfQuestions'),
      value: questionnaire.exchangeDefSequence.length,
    },
    isSender && {
      fieldName: 'version',
      name: t('version'),
      value: questionnaire.meta.questionnaireTemplateVersion,
    },
  ]), [t, questionnaire, isSender]);

  const heading = t('questionnaireSummary.basicDetails');
  const isEditingOtherPanel = Boolean(editingPanelId);

  return (
    <Panel
      as="section"
      aria-label={heading}
      heading={heading}
      sx={{
        opacity: isEditingOtherPanel ? 0.5 : 1,
      }}
    >
      <PropertyList properties={properties} />
    </Panel>
  );
};
