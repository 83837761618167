import { useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { isEmpty, noop } from 'lodash';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData } from '../types';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { TextField } from '../../../../form/TextField';
import { Direction } from '../../../../ui/MultiStepFlow/types';

export const CannotProvideTotalSavingsReasonStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (data: Partial<AwardFlowData> | null, direction: Direction) => void,
}) => {
  const { t } = useTranslation('translation');

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      cannotProvideTotalSavingsReason: z.string({ message: t('request.awardFlow.errors.explainCannotProvideSavings') }),
    });

    return toFormikValidationSchema(Schema);
  }, [t]);

  return (
    <Formik
      validateOnBlur
      initialValues={data.spendAndSavings}
      validationSchema={validationSchema}
      onSubmit={noop}
    >
      {({ values, submitForm, validateForm, dirty }) => (
        <Form>
          <lotPagesLayout.ContentWrapper>
            <StepNavigation
              onBackClick={() => submitAndNavigate(dirty ? { spendAndSavings: values } : null, Direction.BACK)}
              onContinueClick={async () => {
                const errors = await validateForm();

                await submitForm();

                if (isEmpty(errors)) {
                  submitAndNavigate(dirty ? { spendAndSavings: values } : null, Direction.FORWARD);
                }
              }}
            >
              <FormErrors />
              <lotPagesLayout.Section
                heading={t('request.awardFlow.steps.cannotProvideTotalSavingsReason.heading')}
              >
                <Box mt="20px">
                  <TextField
                    name="cannotProvideTotalSavingsReason"
                    isMultiLine
                    hideLabel
                    errorMessageStyle={{ fontSize: '14px', marginTop: '16px' }}
                  />
                </Box>
              </lotPagesLayout.Section>
            </StepNavigation>
          </lotPagesLayout.ContentWrapper>
        </Form>
      )}
    </Formik>
  );
};
