// Auto-generated file created by svgr-cli source svgr-config/template.js
// Do not edit
import type { SVGProps } from 'react';

const SvgSearchResults = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={188}
    height={188}
    fill="none"
    {...props}
  >
    <rect
      width={169.5}
      height={169.5}
      x={10.125}
      y={9.625}
      fill="#F9FAFB"
      stroke="#DDE4EE"
      strokeWidth={0.75}
      rx={84.75}
    />
    <path
      fill="#BEC3C7"
      fillRule="evenodd"
      d="M121.528 62.582c15.963 16.109 15.963 42.227 0 58.336s-41.843 16.109-57.806 0-15.963-42.227 0-58.336 41.843-16.11 57.806 0"
      clipRule="evenodd"
    />
    <path
      stroke="#BEC3C7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6.75}
      d="M132.323 127.49 122.165 118z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M111.532 83.79H95.485V72.21h-5.664V89.58h21.711zM73.54 98.625h16.046v11.579h5.664V92.836H73.54z"
    />
  </svg>
);
export default SvgSearchResults;
