import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { LabeledValue } from './LabeledValue';

export const AuctionLineItemsSectionConfigIndicators = ({
  decimalPlaces,
  currency,
}: {
  decimalPlaces: number;
  currency: string,
}) => {
  const { t } = useTranslation('translation');

  return (
    <Flex alignItems="center" sx={{ gap: 3 }}>
      <LabeledValue
        label={t('general.decimalPlaces')}
        value={decimalPlaces}
        small
      />
      <LabeledValue
        label={t('general.currency')}
        value={currency}
        small
      />
    </Flex>
  );
};
