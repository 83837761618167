import { LabeledSortCriterion } from '@deepstream/ui-utils';
import { SortingAccessor } from '@deepstream/common';
import { isEmpty, isEqual, map, omitBy } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Illustration } from '@deepstream/ui-kit';

import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { FlexPanelHeader, Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { RequestCount } from '../../../RequestCount';
import { renderName } from '../../../RequestsTable';
import { TableDataContext } from '../../../TableDataContext';
import { useApi, wrap } from '../../../api';
import { ClearFiltersButton, FilterProps, useLocalStorageFilterProps } from '../../../filtering';
import { SortProps2, useLocalStorageSortProps2, useSortDirectionItems } from '../../../sorting';
import { ErrorMessage } from '../../../ui/ErrorMessage';
import { Loading } from '../../../ui/Loading';
import { SelectDropdownMenu } from '../../../ui/MultiSelect';
import { useCurrentUser } from '../../../useCurrentUser';
import { useLocalStorageState } from '../../../useLocalStorageState';
import { PublicRequestsTable } from './PublicRequestsTable';
import { PageControls } from '../../../RequestsTableDataProvider';
import { SEARCH_RESULTS_LIMIT } from '../../../SearchRequests';
import { useMutation } from '../../../useMutation';
import { SearchCombobox } from '../../../ui/SearchCombobox';
import { PublicRequestOverview, SenderFilterItem } from '../../../types';
import { SortDropdown } from '../../../ui/SortDropdown';
import { useNavigate } from '../../../tanstackRouter';
import { publicRequestRoute } from '../../../AppRouting';

type FromFilter = {
  name: string;
  _id: string;
};

const DEFAULT_PAGE_SIZE = 10;

export type PublicRequestsProps = {
  navigateToRequest: (request: PublicRequestOverview) => void;
  senderFilterProps: FilterProps<SenderFilterItem>;
  sortProps: SortProps2;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  isPublicRoute?: boolean;
};

export const PublicRequests = ({
  navigateToRequest,
  senderFilterProps,
  sortProps,
  pageSize,
  setPageSize,
  isPublicRoute = false,
}: PublicRequestsProps) => {
  const { t } = useTranslation();
  const api = useApi();

  const [pageIndex, setPageIndex] = React.useState<number>(0);
  const [apiQueryParams, setApiQueryParams] = React.useState(() => ({
    pageSize,
    pageIndex,
    sort: sortProps.getQueryParam(sortProps.selectedSortCriterion, sortProps.selectedSortDirection),
    filter: omitBy(
      {
        ...senderFilterProps.getQueryParam(senderFilterProps.selectedItems),
      },
      isEmpty,
    ),
  }));

  React.useEffect(() => {
    const newQueryParams = {
      pageSize,
      pageIndex,
      sort: sortProps.getQueryParam(sortProps.selectedSortCriterion, sortProps.selectedSortDirection),
      filter: omitBy(
        { ...senderFilterProps.getQueryParam(senderFilterProps.selectedItems) },
        isEmpty,
      ),
    };

    if (!isEqual(apiQueryParams, newQueryParams)) {
      setApiQueryParams(newQueryParams);
    }
  }, [pageSize, pageIndex, sortProps, senderFilterProps, apiQueryParams]);

  const query = useQuery(
    ['publicRequests', apiQueryParams],
    wrap(api.getPublicRequests),
  );
  const { data, isSuccess, isLoading, isError } = query;
  const { rfqs: requests, totalItems = 0 } = data ?? {};

  const hasFilters = !isEmpty(senderFilterProps.selectedItems);

  const setPageControls = React.useCallback(
    (pageControls: PageControls) => {
      setPageSize(pageControls.pageSize);
      setPageIndex(pageControls.pageIndex);
    },
    [setPageIndex, setPageSize],
  );

  const tableDataContext = React.useMemo(
    () => ({
      query,
      data: requests ?? [],
      pageCount: Math.ceil((totalItems ?? 0) / apiQueryParams.pageSize),
      pageSize: apiQueryParams.pageSize,
      pageIndex: data?.pageIndex ?? 0,
      setPageControls,
    }),
    [query, data, requests, totalItems, apiQueryParams.pageSize, setPageControls],
  );

  const hasLoadedItems = isSuccess && totalItems > 0;

  const [search, { status: searchStatus, data: searchData }] = useMutation(api.getPublicRequests);
  const { rfqs: searchResults } = searchData ?? {};
  const isSearching = searchStatus === 'loading';
  const [searchText, setSearchText] = React.useState<string>();

  const searchRequests = React.useCallback(
    (text) => {
      if (text) {
        search({
          ...(apiQueryParams as any),
          // Search results limit is always the same, regardless the page size
          // set by the user for the requests table
          pageSize: SEARCH_RESULTS_LIMIT,
          pageIndex: 0,
          search: {
            text,
          },
        });
      }
    },
    [apiQueryParams, search],
  );

  return (
    <Stack gap={3}>
      <Stack gap={2}>
        <IconText
          icon="globe"
          text={isPublicRoute ? t('requests.publicRequestsHeader') : t('requests.public')}
          fontSize={isPublicRoute ? '35px' : 6}
          fontWeight={500}
          gap={2}
          isIconLight
        />

        <Text fontSize={2} fontWeight={400}>
          {t('requests.publicRequestsDescription')}
        </Text>
      </Stack>
      <Panel>
        {(hasLoadedItems || isLoading) && (
          <>
            <FlexPanelHeader
              heading={
                // Keep the header in the same position while loading the results with search/sort/filter
                <Box width="130px">
                  {hasLoadedItems ? <RequestCount count={totalItems} /> : null}
                </Box>
              }
              childrenContainerProps={{ 'data-test': 'requests-table-actions' }}
            />
            <PanelDivider />
            <Flex
              px="20px"
              py="12px"
              minHeight="54px"
              width="100%"
              flexWrap="wrap"
              justifyContent="space-between"
              sx={{ gap: 1 }}
              data-test="requests-table-actions"
            >
              <Box sx={{ width: '300px' }}>
                <SearchCombobox<PublicRequestOverview>
                  initialValue={searchText}
                  results={searchResults}
                  getResultId={(request) => request._id}
                  getResultText={(request: PublicRequestOverview) => request.subject}
                  textPlaceholder={t('requests.searching.searchByRequestName')}
                  noResultsMessage={t('requests.searching.noResults')}
                  onResultClick={navigateToRequest}
                  onChange={searchRequests}
                  onSubmit={setSearchText}
                  isLoading={isSearching}
                />
              </Box>
              <Flex
                flexWrap="wrap"
                alignItems="center"
                justifyContent="flex-end"
                sx={{ columnGap: 1 }}
              >
                {hasFilters && (
                  <ClearFiltersButton
                    onClick={() => {
                      senderFilterProps.onChange([]);
                    }}
                  />
                )}
                <SelectDropdownMenu
                  multi
                  buttonText={t('requests.from')}
                  buttonIcon="filter"
                  disabled={!hasLoadedItems}
                  menuWidth={180}
                  menuZIndex={10}
                  rightAligned
                  {...senderFilterProps}
                />
                <SortDropdown
                  disabled={!hasLoadedItems}
                  {...sortProps}
                />
              </Flex>
            </Flex>
            <PanelDivider />
          </>
        )}

        {isSuccess && !isEmpty(requests) ? (
          <TableDataContext.Provider value={tableDataContext}>
            <PublicRequestsTable
              // @ts-expect-error ts(2322) FIXME: Type 'PublicRequestOverview[] | undefined' is not assignable to type 'PublicRequestOverview[]'.
              requests={requests}
              navigateToRequest={navigateToRequest}
              isPublicRoute={isPublicRoute}
            />
          </TableDataContext.Provider>
        ) : (
          <Flex flexDirection="column" justifyContent="center">
            <PanelPadding>
              {isLoading ? (
                <Loading fontSize={4} fontWeight={400} />
              ) : isError ? (
                <ErrorMessage
                  fontSize={3}
                  error={t('requests.errors.getRequests')}
                />
              ) : hasFilters ? (
                <Illustration
                  variant="search-results"
                  label={
                    <Flex flexDirection="column" sx={{ gap: 2 }}>
                      <Text>
                        {t('requests.noRequestsWithSearchOrFilter')}
                      </Text>
                      <ClearFiltersButton
                        onClick={() => {
                          senderFilterProps.onChange([]);
                        }}
                        alignSelf="center"
                      />
                    </Flex>
                  }
                  labelSx={{
                    fontWeight: 500,
                  }}
                />
              ) : (
                <Illustration
                  variant="empty-state"
                  label={t('requests.noPublicRequests')}
                  labelSx={{ fontWeight: 500 }}
                />
              )}
            </PanelPadding>
          </Flex>
        )}
      </Panel>
    </Stack>
  );
};

const useSortCriteriaItems = (): LabeledSortCriterion[] => {
  const { t } = useTranslation('translation');

  return React.useMemo(() => [
    { label: t('requests.sorting.creationDate'), accessor: SortingAccessor.creationDate },
    { label: t('requests.sorting.issueDate'), accessor: SortingAccessor.issueDate },
    { label: t('requests.sorting.deadline'), accessor: SortingAccessor.firstStageDeadline },
    { label: t('requests.sorting.automatedReference'), accessor: SortingAccessor.automatedReference },
    { label: t('requests.sorting.requestName'), accessor: SortingAccessor.requestName },
  ], [t]);
};

export const PublicRequestsTab = () => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();
  const navigate = useNavigate();

  const navigateToRequest = React.useCallback(
    (request: PublicRequestOverview) => {
      navigate({
        to: publicRequestRoute.to,
        params: { rfqId: request._id },
      });
    },
    [navigate],
  );

  const filterItems = useQuery(
    ['publicRequestFilterItems'],
    wrap(api.getPublicRequestFilterItems),
  );

  const senderFilterProps = useLocalStorageFilterProps({
    storageKey: `${currentCompanyId}.${user._id}.publicRequests.senderFilter`,
    items: filterItems.data?.senders,
    idProp: '_id',
    renderItem: renderName,
    getQueryParam: (selectedItems: FromFilter[]) => ({
      senderIds: map(selectedItems, '_id').sort(),
    }),
  });

  const criteriaItems = useSortCriteriaItems();
  const directionItems = useSortDirectionItems();
  const sortProps = useLocalStorageSortProps2({
    storageKey: `${currentCompanyId}.${user._id}.publicRequests.sort`,
    criteriaItems,
    directionItems,
  });

  const [pageSize, setPageSize] = useLocalStorageState<number>({
    key: `${currentCompanyId}.${user._id}.publicRequests.pageSize`,
    defaultValue: DEFAULT_PAGE_SIZE,
  });

  return (
    <PublicRequests
      navigateToRequest={navigateToRequest}
      senderFilterProps={senderFilterProps}
      sortProps={sortProps}
      pageSize={pageSize}
      setPageSize={setPageSize}
    />
  );
};
