import * as React from 'react';
import { isNil, stubTrue } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass/styled-components';
import { Lock as LockType } from '@deepstream/common/rfq-utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { ExchangeSnapshot } from './types';
import { useCurrentCompanyId } from './currentCompanyId';
import { GridLockedTooltip, LockedTooltip } from './LockedTooltip';
import * as rfx from './rfx';
import { ContextType, useHooks } from './useHooks';

export const and = (...predicates) => (...args) =>
  predicates.reduce((result, predicate) => result && predicate(...args), true);

export const isRole = (role) => ({ roles }) => roles.includes(role);
export const isNotRole = (role) => ({ roles }) => !roles.includes(role);

export const LockedIcon = (props) => (
  <Icon icon="lock" {...props} />
);

export const UnlockedIcon = (props) => (
  <Icon icon="unlock" {...props} />
);

export const LockStateIcon = ({ exchange, ...props }) => {
  const senders = rfx.useSenders();

  return (
    exchange.isLocked ? (
      <LockedTooltip senders={senders} lock={exchange.def.locking}>
        <div style={{ display: 'inline-block' }}>
          <LockedIcon {...props} />
        </div>
      </LockedTooltip>
    ) : (
      <UnlockedIcon {...props} />
    )
  );
};

/**
 * Renders '[lock icon] Lock set' with tooltip when `locking` exists, otherwise
 * renders '[unlock icon] No lock'.
 */
export const LockState: React.FC<{ locking: LockType | undefined }> = ({ locking }) => {
  const { t } = useTranslation();
  const { senders } = rfx.useStructure();

  return (
    locking ? (
      <LockedTooltip senders={senders} lock={locking}>
        <div style={{ display: 'inline-block' }}>
          <IconText icon="lock" text={t('general.lockSet')} gap={2} fontSize="inherit" />
        </div>
      </LockedTooltip>
    ) : (
      <IconText icon="unlock" text={t('general.noLockSet')} gap={2} fontSize="inherit" />
    )
  );
};

export const Locked = ({ gap = 2, isInline }: { gap?: number; isInline?: boolean }) => {
  const { t } = useTranslation();

  return isInline ? (
    <Box as="span" fontSize="inherit">
      <Icon icon="lock" mr={gap} />
      {t('general.locked')}
    </Box>
  ) : (
    <IconText icon="lock" text={t('general.locked')} gap={gap} fontSize="inherit" />
  );
};

type LockProps = {
  /**
   * The exchange snapshot
   */
  exchange: ExchangeSnapshot;
  /**
   * The value being locked
   */
  value: any;
  /**
   * Show the locked state when the exchange is incomplete
   * @default false
   */
  lockEmptyValue?: boolean;
  /**
   * Predicate to determine if the current _value_ has a lock or not. Usually
   * this is based on the current exchange roles (eg: if a line item's submitter
   * is also the initiator, then the description/quantity/unit are locked in
   * addition to price)
   *
   * This is a little confusing, because an exchange can have a lock, but the
   * specific data that is _actually_ locked (ie: hidden from view) is dependent
   * on other conditions.
   */
  getIsLockableValue?: (exchange: ExchangeSnapshot) => boolean;
  /**
   * The content that may or may not be shown depending on the lock's state
   */
  children: React.ReactElement;
};

const getIsNonEmptySingleValue = value => !isNil(value) && !Number.isNaN(value) && value !== '';
const getIsNonEmptyArrayValue = value => value.some(getIsNonEmptySingleValue);

/**
 * Will replace the actual value with a "Locked" message if the value is lockable
 */
export const Lock = ({
  value,
  getIsLockableValue = stubTrue,
  exchange,
  lockEmptyValue = false,
  // @ts-expect-error ts(2322) FIXME: Type 'null' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.
  children = null,
}: LockProps) => {
  const { def: { locking: lock } } = exchange;
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const hasNonEmptyValue = Array.isArray(value)
    ? getIsNonEmptyArrayValue(value)
    : getIsNonEmptySingleValue(value);

  const canBypassLock = lock?.bypass?.includes(currentCompanyId);
  const senders = rfx.useSenders();

  return (
    exchange.hasLock &&
    exchange.isLocked &&
    getIsLockableValue(exchange) &&
    !canBypassLock &&
    (hasNonEmptyValue || lockEmptyValue)
  ) ? (
    <LockedTooltip senders={senders} lock={exchange.def.locking}>
      <div style={{ display: 'inline-block' }}>
        <Locked />
      </div>
    </LockedTooltip>
  ) : (
    children
  );
};

/**
 * Will replace the actual value with a "Locked" message if the value is lockable.
 *
 * Variant of the `Lock` component with native tooltips for usage in grids.
 */
const GridLock = ({
  value,
  getIsLockableValue = stubTrue,
  exchange,
  lockEmptyValue = false,
  // @ts-expect-error ts(2322) FIXME: Type 'null' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.
  children = null,
  textAlign,
}: LockProps & { textAlign?: string }) => {
  const { t } = useTranslation();
  const { def: { locking: lock } } = exchange;
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const hasNonEmptyValue = Array.isArray(value)
    ? getIsNonEmptyArrayValue(value)
    : getIsNonEmptySingleValue(value);

  const canBypassLock = lock?.bypass?.includes(currentCompanyId);
  const senders = rfx.useSenders();

  return (
    exchange.hasLock &&
    exchange.isLocked &&
    getIsLockableValue(exchange) &&
    !canBypassLock &&
    (hasNonEmptyValue || lockEmptyValue)
  ) ? (
    <GridLockedTooltip senders={senders} lock={exchange.def.locking} textAlign={textAlign}>
      <Icon icon="lock" fontSize="inherit" mr={2} />
      <Box>{t('general.locked')}</Box>
    </GridLockedTooltip>
  ) : (
    children
  );
};

/**
 * Prefixes the value with an icon that represents whether the exchange
 * is locked/unlocked
 */
export const LockStateCell = ({ cell, row: { original: exchange } }) => {
  const { contextType } = useHooks();

  if ([ContextType.CONTRACT, ContextType.QUESTIONNAIRE].includes(contextType)) {
    return cell.value;
  }

  return (
    !exchange.hasLock ? (
      cell.value // Without a lock we can just render the value
    ) : exchange.roles.includes('submitter') ? (
      cell.value // Submitters don't know the exchange is locked
    ) : (
      <Flex display="inline-flex" alignItems="center">
        <LockStateIcon exchange={exchange} mr={2} />
        {cell.value}
      </Flex>
    )
  );
};

export const LockedCell = ({ cell, column, row }) => {
  const { contextType } = useHooks();

  if ([ContextType.CONTRACT, ContextType.QUESTIONNAIRE].includes(contextType)) {
    return cell.value;
  }

  return (
    <Lock
      value={row.values[column.id]}
      exchange={row.original}
      getIsLockableValue={column.getIsLockableValue}
      lockEmptyValue={column.lockEmptyValue}
    >
      {cell.value}
    </Lock>
  );
};

export const GridLockedCell = ({ cell, column, row }) => {
  const { contextType } = useHooks();

  if ([ContextType.CONTRACT, ContextType.QUESTIONNAIRE].includes(contextType)) {
    return cell.value;
  }

  return (
    <GridLock
      value={row.values[column.id]}
      exchange={row.original}
      getIsLockableValue={column.getIsLockableValue}
      lockEmptyValue={column.lockEmptyValue}
      textAlign={column.textAlign}
    >
      {cell.value}
    </GridLock>
  );
};
