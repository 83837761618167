import { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PageRole } from '@deepstream/common/rfq-utils';
import { Box, Text } from 'rebass/styled-components';
import { keyBy, keys, mapValues } from 'lodash';
import { useQueryClient } from 'react-query';
import { callAll } from '@deepstream/utils/callAll';
import { Button, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { useBlocker } from '../../tanstackRouter';
import { SuppliersProvider, useSuppliersContext } from './SuppliersContext';
import { SuppliersPanel } from './SuppliersPanel';
import { RequestVisibilityEditPanel } from '../RequestVisibility/RequestVisibilityPanel';
import * as rfx from '../../rfx';
import { RfqIdProvider, useDraftRfqStructure, useDraftRfqStructureQueryKey, useLiveRfqStructureQueryKey, useRfqId } from '../../useRfq';
import { RequestHooksProvider } from '../Request/RequestHooksProvider';
import { Loading } from '../../ui/Loading';
import { ErrorMessage } from '../../ui/ErrorMessage';
import { DraftToolbar } from '../Request/DraftToolbar';
import { useApi } from '../../api';
import { useMutation } from '../../useMutation';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { LeavePageModalBase } from '../../draft/LeavePageModal';
import { ModelSizeLimitsProvider } from '../../modelSizeLimits';
import { useRequestSizeLimits } from '../Request/useRequestSizeLimits';
import { useToaster } from '../../toast';
import { PageFooter } from '../../PageFooter';
import { Bold } from '../../Bold';
import { useModalState } from '../../ui/useModalState';
import { ModelSizeLimitDialog, ModelSizeLimitMessages } from '../../ModelSizeLimitDialog';
import { useRequestSentNavigation } from '../../appNavigation';
import { useWaitForRfqUnlock } from '../../useWaitForUnlock';

/**
 * This handles updating/adding suppliers across the draft and live contexts
 */
const useUpdateRecipients = () => {
  const rfqId = useRfqId();
  const { isTemplate } = rfx.useState();
  const currentCompanyId = useCurrentCompanyId();
  const api = useApi();
  const queryClient = useQueryClient();
  const { suppliers } = useSuppliersContext();
  const toaster = useToaster();
  const { t } = useTranslation();
  const waitForRfqUnlock = useWaitForRfqUnlock();

  const suppliersById = keyBy(suppliers, supplier => supplier.company._id);
  const selectedUserIdsBySupplierId = mapValues(
    suppliersById,
    supplier => supplier.users.filter(user => user.selected).map(user => user._id),
  );
  const sourceByRecipientId = mapValues(
    suppliersById,
    supplier => supplier.source,
  );

  const draftStructureQueryKey = useDraftRfqStructureQueryKey({
    // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string | undefined'.
    currentCompanyId,
    rfqId,
    isTemplate,
  });

  const liveStructureQueryKey = useLiveRfqStructureQueryKey({
    // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string | undefined'.
    currentCompanyId,
    rfqId,
  });

  return useMutation(
    () => waitForRfqUnlock({
      command: () => api.updateRecipients({
        // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
        currentCompanyId,
        rfqId,
        recipientIds: keys(selectedUserIdsBySupplierId),
        // @ts-expect-error ts(2322) FIXME: Type '{ [x: string]: (string | undefined)[]; }' is not assignable to type 'Record<string, string[]>'.
        userIdsByRecipientId: selectedUserIdsBySupplierId,
        // @ts-expect-error ts(2322) FIXME: Type '{ [x: string]: RecipientSource | undefined; }' is not assignable to type 'Record<string, RecipientSource>'.
        sourceByRecipientId,
        isTemplate,
      }),
    }),
    {
      onSuccess: callAll(
        () => queryClient.invalidateQueries(draftStructureQueryKey),
        () => queryClient.invalidateQueries(liveStructureQueryKey),
      ),
      onError: () => toaster.error(t('request.toaster.updateSuppliersError')),
    },
  );
};

const DraftRequestSuppliersToolbar = ({
  setCanShowLeavePageModal,
}: {
  setCanShowLeavePageModal: (value: boolean) => void;
}) => {
  const rfqId = useRfqId();
  const { isTemplate, isRevising } = rfx.useState();
  const { stopEditing } = rfx.useActions();
  const isSuperUserOrOwner = rfx.useIsSuperUserOrOwner();
  const { excessSupplierCount, hasChanges } = useSuppliersContext();
  const [updateRecipients, { isLoading }] = useUpdateRecipients();

  return (
    <DraftToolbar
      rfqId={rfqId}
      tabId="suppliers"
      showSubmitButton={false}
      isSuperUserOrOwner={isSuperUserOrOwner}
      isTemplate={isTemplate}
      isRevising={isRevising}
      isRequestValid={true}
      isLoading={isLoading}
      hasUnsavedChanges={hasChanges}
      onClickSaveAsDraft={(navigateToNextPage) => {
        updateRecipients({}, {
          onSuccess: callAll(
            stopEditing,
            () => {
              if (navigateToNextPage) {
                setCanShowLeavePageModal(false);
                navigateToNextPage();
              }
            },
          ),
        });
      }}
      excessSupplierCount={excessSupplierCount}
    />
  );
};

export const LiveRequestSuppliersToolbar = ({
  setCanShowLeavePageModal,
}: {
  setCanShowLeavePageModal: (value: boolean) => void;
}) => {
  const { t } = useTranslation(['translation', 'request']);
  const modelSizeLimitModal = useModalState();
  const toaster = useToaster();
  const navigation = useRequestSentNavigation();
  const { stopEditing } = rfx.useActions();
  const [modelSizeLimitMessages, setModelSizeLimitMessages] = useState<ModelSizeLimitMessages | null>(null);

  const {
    excessSupplierCount,
    addedCompaniesCount,
    addedUsersCount,
    hasChanges,
  } = useSuppliersContext();

  const navigateToSuppliers = () => {
    stopEditing();
    setCanShowLeavePageModal(false);

    // HACK: no idea, why this is necessary, something to do with the AppBridge,
    // shouldn't be necessary after removing the angular shell
    setTimeout(() => {
      navigation.navigateToSuppliers();
    }, 1);
  };

  const [updateRecipients, { isLoading }] = useUpdateRecipients();

  const canSend = hasChanges && !isLoading && excessSupplierCount === 0;

  return (
    <>
      <PageFooter>
        <Box>
          <Bold>
            {t('toolbar.toBeAddedToRequest', { ns: 'request' })}:
          </Bold>
          <Text color="subtext">
            {t('general.companyCount', { count: addedCompaniesCount || 0 })}
            {' '}&bull;{' '}
            {t('general.userCount', { count: addedUsersCount || 0 })}
          </Text>
        </Box>
        <Box>
          <CancelButton
            onClick={navigateToSuppliers}
            mr={2}
          />
          {excessSupplierCount ? (
            <Button
              variant="success"
              onClick={() => {
                setModelSizeLimitMessages({
                  heading: t('request.dialog.requestSizeLimit.heading'),
                  title: t('request.dialog.requestSizeLimit.addSupplier.title'),
                  warning: t('request.dialog.requestSizeLimit.addSupplier.warning'),
                  body: t('request.dialog.requestSizeLimit.addSupplier.body', {
                    count: excessSupplierCount,
                  }),
                });
                modelSizeLimitModal.open();
              }}
              iconRight="arrow-right"
            >
              {t('toolbar.sendRequest', { ns: 'request' })}
            </Button>
          ) : (
            <Button
              variant="success"
              onClick={async () => {
                await updateRecipients({}, {
                  onSuccess: callAll(
                    () => toaster.success(t('request.toaster.updateSuppliersSuccess')),
                    navigateToSuppliers,
                  ),
                });
              }}
              iconRight="arrow-right"
              disabled={!canSend && !excessSupplierCount}
            >
              {t('toolbar.sendRequest', { ns: 'request' })}
            </Button>
          )}
        </Box>
      </PageFooter>
      <ModelSizeLimitDialog
        modal={modelSizeLimitModal}
        messages={modelSizeLimitMessages}
      />
    </>
  );
};

/**
 * Since the suppliers are not driven by a form (ie: formik), it needs it's own version
 * of the LeavePageModal
 */
const LeavePageModal = () => {
  const [updateRecipients, { isLoading }] = useUpdateRecipients();
  const { excessSupplierCount, hasChanges } = useSuppliersContext();

  const { stopEditing } = rfx.useActions();
  const [reactDecideCanLeavePage, setReactDecideCanLeavePage] = useState<((decision: boolean) => void) | null>(null);

  const decideCanLeavePage = useCallback(
    (decision: boolean) => {
      if (decision === true) {
        stopEditing();
      }

      reactDecideCanLeavePage?.(decision);
    },
    [reactDecideCanLeavePage, stopEditing],
  );

  useBlocker({
    blockerFn: () => new Promise(resolve => {
      setReactDecideCanLeavePage(() => (decision: boolean) => {
        resolve(decision);
        setReactDecideCanLeavePage(null);
      });
    }),
    condition: hasChanges,
  });

  return (
    reactDecideCanLeavePage ? (
      <LeavePageModalBase
        decideCanLeavePage={decideCanLeavePage}
        hasChanges={hasChanges}
        isSaving={isLoading}
        isValid={excessSupplierCount === 0}
        saveChanges={updateRecipients}
      />
    ) : (
      null
    )
  );
};

export const RequestSuppliers = ({
  currentCompanyId,
  rfqId,
  isTemplate = false,
  isTemplatePreview = false,
  isRevising = false,
  isReview = false,
  onChange,
  isReadOnly,
  onClickEdit,
  isLive = false,
}: {
  currentCompanyId: string;
  rfqId: string;
  isTemplate?: boolean;
  isTemplatePreview?: boolean;
  isRevising?: boolean;
  isReview?: boolean;
  onChange?: (suppliers: any, initialSuppliers: any) => void;
  isReadOnly?: boolean;
  onClickEdit?: () => void;
  isLive?: boolean;
}) => {
  // HACK: This prevents showing the leave-page modal when a user clicks
  // "Save and continue". Once everything's migrated to React, we should
  // replace this workaround with a better solution.
  const [canShowLeavePageModal, setCanShowLeavePageModal] = useState(true);
  const { t } = useTranslation();

  // We use the draft structure for both draft and live contexts because the
  // recipients won't be different between the two after it's been sent.
  const { data: rfxStructure, status: structureStatus } = useDraftRfqStructure({
    rfqId,
    currentCompanyId,
    isTemplate,
  });

  const { data: sizeLimits, status: sizeLimitsStatus } = useRequestSizeLimits({
    rfqId,
    isTemplate,
  });

  // Templates should be editable by all members of a company but the template
  // structure doesn't contain ownership / team membership information in
  // `teamById` which we use to determine page permissions so we provide
  // overrides here
  const rfxOverrides = useMemo(
    (): rfx.UserOverrides => isTemplate ? { isOwner: true, pageRole: PageRole.EDITOR } : {},
    [isTemplate],
  );

  return (
    <rfx.StateProvider
      isLive={isLive}
      isRevising={isRevising}
      isTemplate={isTemplate}
      isTemplatePreview={isTemplatePreview}
      isReview={isReview}
    >
      {structureStatus === 'loading' || sizeLimitsStatus === 'loading' ? (
        <Box p="20px">
          <Loading />
        </Box>
        ) : structureStatus === 'error' || sizeLimitsStatus === 'error' ? (
          <Box p="20px">
            <ErrorMessage error={t('errors.unexpected')} />
          </Box>
        ) : rfxStructure && sizeLimits ? (
          <ModelSizeLimitsProvider {...sizeLimits}>
            <rfx.StructureProvider structure={rfxStructure}>
              <rfx.OverridesProvider {...rfxOverrides}>
                <RequestHooksProvider>
                  <RfqIdProvider rfqId={rfqId}>
                    {!isLive && (
                      <RequestVisibilityEditPanel onClickReviewEdit={onClickEdit} />
                    )}
                    <SuppliersProvider
                      rfqId={rfqId}
                      isReadOnly={isReadOnly}
                      onSuppliersChange={onChange}
                    >
                      <SuppliersPanel onClickEdit={onClickEdit} />
                      {!isReadOnly ? (
                        isLive ? (
                          <LiveRequestSuppliersToolbar setCanShowLeavePageModal={setCanShowLeavePageModal} />
                        ) : (
                          <DraftRequestSuppliersToolbar setCanShowLeavePageModal={setCanShowLeavePageModal} />
                        )
                      ) : null}
                      {canShowLeavePageModal && !isReadOnly && (
                        <LeavePageModal />
                      )}
                    </SuppliersProvider>
                  </RfqIdProvider>
                </RequestHooksProvider>
              </rfx.OverridesProvider>
            </rfx.StructureProvider>
          </ModelSizeLimitsProvider>
        ) : null}
    </rfx.StateProvider>
  );
};
