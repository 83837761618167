import { AwardFlowStepType } from '../types';
import { CalculatedValueNotAccurateReasonsStep } from './CalculatedValueNotAccurateReasonsStep';
import { ChooseAwardScenarioStep } from './ChooseAwardScenarioStep';
import { ChooseLineLevelAwardSuppliersStep } from './ChooseLineLevelAwardSuppliersStep';
import { ChooseLotLevelAwardSuppliersStep } from './ChooseLotLevelAwardSuppliersStep';
import { ChooseProvideBudgetStep } from './ChooseProvideBudgetStep';
import { ChooseRequestLevelAwardSupplierStep } from './ChooseRequestLevelAwardSupplierStep';
import { ConfirmTotalValueStep } from './ConfirmTotalValueStep';
import { ConfirmPreviousBudgetStep } from './ConfirmPreviousBudgetStep';
import { EditBudgetStep } from './EditBudgetStep';
import { EnterManualTotalValueStep } from './EnterManualTotalValueStep';
import { GuidelinesStep } from './GuidelinesStep';
import { NoCalculatedTotalValueReasonsStep } from './NoCalculatedTotalValueReasonsStep';
import { ReviewAndSubmitStep } from './ReviewAndSubmitStep';
import { SpendAndSavingsStatusStep } from './SpendAndSavingsStatusStep';
import { SplitLineAwardStep } from './SplitLineAwardStep';
import { CannotProvideManualTotalValueReasonStep } from './CannotProvideManualTotalValueReasonStep';
import { AdditionalCommentsStep } from './AdditionalCommentsStep';
import { ChooseSavingsMethodStep } from './ChooseSavingsMethodStep';
import { CannotProvideTotalSavingsReasonStep } from './CannotProvideTotalSavingsReasonStep';
import { ConfirmSumSpecificSavingsStep } from './ConfirmSumSpecificSavingsStep';
import { ConfirmBudgetFinalValueDiffSavingsStep } from './ConfirmBudgetFinalValueDiffSavingsStep';
import { EnterManualSavingsStep } from './EnterManualSavingsStep';
import { ChooseSupplierTypesToMessageStep } from './ChooseSupplierTypesToMessageStep';
import { EnterSingleSupplierMessageStep } from './EnterSingleSupplierMessageStep';
import { ChooseMultiSupplierMessageMethodStep } from './ChooseMultiSupplierMessageMethodStep';
import { EnterMultiSupplierGeneralMessageStep } from './EnterMultiSupplierGeneralMessageStep';
import { EnterMultiSupplierIndividualMessagesStep } from './EnterMultiSupplierIndividualMessagesStep';
import { ChooseMultiSupplierCustomMessageMethodsStep } from './ChooseMultiSupplierCustomMessageMethodsStep';
import { SubmittedSuccessfullyStep } from './SubmittedSuccessfullyStep';

export const stepComponentByType = {
  [AwardFlowStepType.GUIDELINES]: GuidelinesStep,
  [AwardFlowStepType.CHOOSE_AWARD_SCENARIO]: ChooseAwardScenarioStep,
  [AwardFlowStepType.CHOOSE_REQUEST_LEVEL_AWARD_SUPPLIER]: ChooseRequestLevelAwardSupplierStep,
  [AwardFlowStepType.CHOOSE_LINE_LEVEL_AWARD_SUPPLIERS]: ChooseLineLevelAwardSuppliersStep,
  [AwardFlowStepType.SPLIT_LINE_AWARD]: SplitLineAwardStep,
  [AwardFlowStepType.CHOOSE_LOT_LEVEL_AWARD_SUPPLIERS]: ChooseLotLevelAwardSuppliersStep,
  [AwardFlowStepType.SPEND_AND_SAVINGS_STATUS]: SpendAndSavingsStatusStep,
  [AwardFlowStepType.CONFIRM_PREVIOUS_BUDGET]: ConfirmPreviousBudgetStep,
  [AwardFlowStepType.CHOOSE_PROVIDE_BUDGET]: ChooseProvideBudgetStep,
  [AwardFlowStepType.EDIT_BUDGET]: EditBudgetStep,
  [AwardFlowStepType.NO_CALCULATED_TOTAL_VALUE_REASONS]: NoCalculatedTotalValueReasonsStep,
  [AwardFlowStepType.CONFIRM_TOTAL_VALUE]: ConfirmTotalValueStep,
  [AwardFlowStepType.CALCULATED_VALUE_NOT_ACCURATE_REASONS]: CalculatedValueNotAccurateReasonsStep,
  [AwardFlowStepType.ENTER_MANUAL_TOTAL_VALUE]: EnterManualTotalValueStep,
  [AwardFlowStepType.CANNOT_PROVIDE_MANUAL_TOTAL_VALUE_REASON]: CannotProvideManualTotalValueReasonStep,
  [AwardFlowStepType.ADDITIONAL_COMMENTS]: AdditionalCommentsStep,
  [AwardFlowStepType.CHOOSE_SAVINGS_METHOD]: ChooseSavingsMethodStep,
  [AwardFlowStepType.CANNOT_PROVIDE_TOTAL_SAVINGS_REASON]: CannotProvideTotalSavingsReasonStep,
  [AwardFlowStepType.CONFIRM_SUM_SPECIFIC_SAVINGS]: ConfirmSumSpecificSavingsStep,
  [AwardFlowStepType.CONFIRM_BUDGET_FINAL_VALUE_DIFF_SAVINGS]: ConfirmBudgetFinalValueDiffSavingsStep,
  [AwardFlowStepType.ENTER_MANUAL_SAVINGS]: EnterManualSavingsStep,
  [AwardFlowStepType.CHOOSE_SUPPLIER_TYPES_TO_MESSAGE]: ChooseSupplierTypesToMessageStep,
  [AwardFlowStepType.ENTER_SINGLE_SUPPLIER_MESSAGE]: EnterSingleSupplierMessageStep,
  [AwardFlowStepType.CHOOSE_MULTI_SUPPLIER_MESSAGE_METHOD]: ChooseMultiSupplierMessageMethodStep,
  [AwardFlowStepType.CHOOSE_MULTI_SUPPLIER_CUSTOM_MESSAGE_METHODS]: ChooseMultiSupplierCustomMessageMethodsStep,
  [AwardFlowStepType.ENTER_MULTI_SUPPLIER_GENERAL_MESSAGE]: EnterMultiSupplierGeneralMessageStep,
  [AwardFlowStepType.ENTER_MULTI_SUPPLIER_INDIVIDUAL_MESSAGES]: EnterMultiSupplierIndividualMessagesStep,
  [AwardFlowStepType.REVIEW_AND_SUBMIT]: ReviewAndSubmitStep,
  [AwardFlowStepType.SUBMITTED_SUCCESSFULLY]: SubmittedSuccessfullyStep,
};
