import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as Layout from '../../Layout';
import { SupplierLists } from './SupplierLists';
import { useSearch } from '../../tanstackRouter';
import { useSupplierListNavigation } from '../../appNavigation';

export const SupplierListsPageContent = () => {
  const { t } = useTranslation();
  const search = useSearch({ strict: false }) as { from?: 'internalNetwork' | 'externalNetwork' };
  const { getInternalNetworkLinkProps, getExternalNetworkLinkProps } = useSupplierListNavigation();

  const breadcrumbs = useMemo(
    () => [{
      title: t('general.network'),
      linkProps: search?.from === 'internalNetwork'
        ? getInternalNetworkLinkProps()
        : getExternalNetworkLinkProps(),
    }],
    [t, search, getInternalNetworkLinkProps, getExternalNetworkLinkProps],
  );

  return (
    <Layout.HeaderAndContent
      header={
        <Layout.PageHeaderWithBreadcrumbs
          heading={t('supplierLists.list', { count: 2 })}
          breadcrumbs={breadcrumbs}
        />
      }
      content={
        <SupplierLists />
      }
    />
  );
};
