import { useMemo } from 'react';
import { map, first } from 'lodash';
import { ExchangeType } from '@deepstream/common/rfq-utils';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { RecipientIdProvider } from '../../useRfq';
import { BidSection } from './BidSection';
import { ExchangeNavigationProvider, useExchangeModalState } from '../../useExchangeModalState';
import { ContractExchangeModal } from './ContractExchangeModal';
import { SwitchToExchangeProvider } from '../../ExchangeModal/SwitchToExchange';
import {
  ContractSectionProvider,
  ContractSectionsProvider,
  useContractData,
  useContractPage,
  useIsRecipient,
} from './contract';
import { useContractExchangeSwitcherTargets } from './useContractExchangeSwitcherTargets';
import { PageFooterBidProgress } from '../../ExchangeModal/PageFooterBidProgress';
import { ContractBidAutoAdvancer } from './ContractBidAutoAdvancer';
import { useLiveContractNavigation } from '../../appNavigation';

const ContractLiveSectionList = () => {
  const { sectionById } = useContractData();
  const page = useContractPage();

  const sections = useMemo(
    () => map(page.sections, sectionId => sectionById[sectionId]),
    [page, sectionById],
  );

  return (
    <Stack gap={4}>
      <ContractSectionsProvider sections={sections}>
        {sections.map(section => (
          <ContractSectionProvider key={section._id} section={section}>
            <BidSection />
          </ContractSectionProvider>
        ))}
      </ContractSectionsProvider>
    </Stack>
  );
};

export const ContractLiveSections = ({
  exchangeId,
  navigateToExchange,
}: {
  exchangeId?: string;
  navigateToExchange: (exchangeKey: { exchangeId?: string; recipientId?: string }) => void;
}) => {
  const contract = useContractData();
  const page = useContractPage();
  const isRecipient = useIsRecipient();
  const navigation = useLiveContractNavigation();

  // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
  const recipientId = first(contract.recipients)._id;

  const exchangeModal = useExchangeModalState({
    exchangeId,
    recipientId,
  });

  const exchangeSwitcherTargets = useContractExchangeSwitcherTargets();

  // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const isContractExchange = contract.exchangeDefById[exchangeId]?.type === ExchangeType.CONTRACT;

  return (
    <ExchangeNavigationProvider navigateToExchange={navigateToExchange}>
      <RecipientIdProvider recipientId={recipientId}>
        <ContractLiveSectionList />
        <SwitchToExchangeProvider
          switchToExchange={(target) => {
            // @ts-expect-error ts(18047) FIXME: 'target' is possibly 'null'.
            if (target.pageId === page._id) {
              // @ts-expect-error ts(18047) FIXME: 'target' is possibly 'null'.
              exchangeModal.open({ exchangeId: target.exchangeId, recipientId });
              // @ts-expect-error ts(18047) FIXME: 'target' is possibly 'null'.
              navigateToExchange({ exchangeId: target.exchangeId });
            } else {
              // @ts-expect-error ts(18047) FIXME: 'target' is possibly 'null'.
              navigation.navigateToDetailsPage(target.pageId, { exchangeId: target.exchangeId });
            }
          }}
          verticalTargets={exchangeSwitcherTargets}
        >
          <ContractExchangeModal
            showExchangeSwitcher={!isContractExchange && !isRecipient}
            showFooter={!isContractExchange && isRecipient}
            FooterAutoAdvancer={ContractBidAutoAdvancer}
            FooterProgress={PageFooterBidProgress}
            {...exchangeModal as any}
            close={() => navigateToExchange({ exchangeId: undefined, recipientId: undefined })}
          />
        </SwitchToExchangeProvider>
      </RecipientIdProvider>
    </ExchangeNavigationProvider>
  );
};
