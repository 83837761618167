import { get, intersection, partition } from 'lodash';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import {
  ExchangeType,
  FieldConfig,
  LineItemExchangeDefinition,
  LineItemExchangeFields,
  getFieldIdsInDefaultDisplayOrder,
  isDefinitionField,
  setExchangeDefFieldValue,
} from '@deepstream/common/rfq-utils';

import { assertDefined, immutableSet } from '@deepstream/utils';
import { withProps } from '@deepstream/ui-utils/withProps';
import { getScrollbarSize } from '@deepstream/ui-utils/getScrollbarSize';
import { BORDER_ADJUSTMENT, DEFAULT_FROZEN_HEADER_HEIGHT } from '@deepstream/ui-kit/grid/core/constants';
import { ColumnData, RowData } from '@deepstream/ui-kit/grid/core/utils';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { EditableGrid } from '@deepstream/ui-kit/grid/EditableGrid/EditableGrid';
import { useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { DefaultEditableGridStyles } from '@deepstream/ui-kit/grid/EditableGrid/EditableGridStyles';
import { useGridMenuState } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { ReadOnlyGrid } from '@deepstream/ui-kit/grid/EditableGrid/ReadOnlyGrid';
import { ACTION_COLUMN_WIDTH, DEFAULT_EDITABLE_DATA_CELL_WIDTH, DEFAULT_ROW_HEIGHT, EditableGridColumn, GridPasteMethod } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { useEditableGridActions } from '@deepstream/ui-kit/grid/EditableGrid/useEditableGridActions';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { swap } from '@deepstream/utils/swap';
import { ContextType, useHooks } from '../../useHooks';
import { useConfigureFieldModal } from '../../modules/Request/LineItems/LineItemFields/useConfigureFieldModal';
import { ConfigureFieldModal } from '../../modules/Request/LineItems/LineItemFields/ConfigureFieldModal';
import { useGetEditConfigurableFieldModalProps } from '../../modules/Request/LineItems';
import { useConfirmDialogWithConfig, useModalState } from '../useModalState';
import {
  TextValueCell,
  ValidationAwareRowNumberCell,
} from './validationAwareValueCell';
import {
  SupplierDescriptionValueCell,
} from './nonValidationAwareValueCell';
import { EditUnspscCodeModal } from './EditUnspscCodeModal';
import { ExchangeDefsGridMenu } from './ExchangeDefsGridMenu';
import {
  createClearFieldsUpdater,
  createDescriptionFieldNameUpdater,
  makeObsolete,
  deleteConfigurableField,
} from './exchangeDefUpdaters';
import { LineItemExchangeDefFieldHeader, SimpleHeader } from './header';
import { hiddenFieldIds, useConfigurableExchangeDefFieldColumns } from './useConfigurableFieldColumns';
import { useGetFormattedFormula } from './useFormattedTotalCostFormula';
import { useExchangeDefFieldValue } from '../../ExchangeDefFieldValueContext';
import { AddLineItemFieldDropdown } from '../../draft/AddLineItemFieldDropdown';
import { AddBuyerProvidedLineItemButton, AddSupplierProvidedLineItemButton, createLineItemRow } from '../../draft/AddLineItemButton';
import { AddMoreItemsModal } from './AddMoreItemsModal';
import { useAddGridRowsState } from './useAddGridRowsState';
import { useGetExchangeDefCsvCellValue } from './useGetExchangeDefCsvCellValue';
import { useExchangeDefsWithContractExchanges } from './useExchangeDefsWithContractExchanges';
import { EditDescriptionModal } from './EditDescriptionModal';
import { useHandleGridClipboardEvent } from './useHandleGridClipboardEvent';
import { useAddCsvDataToExchangeDefs } from './useAddCsvDataToExchangeDefs';

const frozenLeftColumnIds = ['rowNumber', 'description'];
const navigableRange = { startRowIndex: 1, startColumnIndex: 0 };
const selectableRange = { startColumnIndex: 1 };

export interface LineItemExchangeDefsGridProps {
  /**
   * Determines the maximum height of the grid when `height` is not set.
   *
   * `maxGridHeight = 100vh - viewportHeightDelta`
   */
  viewportHeightDelta?: number;
  height?: string;
  isSender?: boolean;
  isReadOnly?: boolean;
  isEditingSupplierExchangeDefs?: boolean;
  isExchangeDefDisabled?: (exchangeDef: LineItemExchangeDefinition) => boolean;
  areAnyExchangeDefsLinkedToAuction?: boolean;
  areAnyExchangeDefsLinkedToActiveAuction?: boolean;
  isBeyondFirstResponseStage?: boolean;
  otherSectionFields?: LineItemExchangeFields;
  showEmptyGridValidationError?: boolean;
}

export const LineItemExchangeDefsGrid = ({
  viewportHeightDelta,
  height,
  isSender,
  isReadOnly,
  isEditingSupplierExchangeDefs,
  isExchangeDefDisabled,
  areAnyExchangeDefsLinkedToAuction,
  areAnyExchangeDefsLinkedToActiveAuction,
  isBeyondFirstResponseStage,
  otherSectionFields,
  showEmptyGridValidationError,
}: LineItemExchangeDefsGridProps) => {
  const { t } = useTranslation('translation');
  const {
    configureDialog: openConfirmDialog,
    ...confirmDialogProps
  } = useConfirmDialogWithConfig();
  const handleGridClipboardEvent = useHandleGridClipboardEvent();
  const editDescriptionModal = useModalState();
  const editUnspscCodeModal = useModalState();
  const { getFieldValue } = useExchangeDefFieldValue();
  const {
    addGridRowsModal,
    additionalRowCount,
    selectPasteMethod,
    resolveWithPasteMethod,
  } = useAddGridRowsState();
  const generateCsvCellValue = useGetExchangeDefCsvCellValue({
    getFieldValue: (exchangeDef, fieldId) => {
      return fieldId === 'unspscCode'
        ? get(exchangeDef, 'productOrService')
        : getFieldValue(exchangeDef, fieldId);
    },
  });
  const addCsvDataToRows = useAddCsvDataToExchangeDefs();
  const gridActions = useEditableGridActions({
    // @ts-expect-error ts(2322) FIXME: Type '(additionalRowCount: number) => Promise<GridPasteMethod | null>' is not assignable to type '(additionalRowCount: number) => Promise<GridPasteMethod>'.
    selectPasteMethod,
    createRow: createLineItemRow,
    generateCsvCellValue,
    addCsvDataToRows,
    // @ts-expect-error ts(2322) FIXME: Type '({ startRowIndex, endRowIndex, affectedColumns, }: { startRowIndex: number; endRowIndex: number; affectedColumns: { original: EditableGridColumn; }[]; }) => (exchangeDef: LineItemExchangeDefinition, index: number) => LineItemExchangeDefinition' is not assignable to type '({ startRowIndex, endRowIndex, affectedColumns, }: { startRowIndex: number; endRowIndex: number; affectedColumns: ({ original: any; } | null)[]; }) => (originalRowData: any, index: number) => unknown'.
    createClearFieldsUpdater,
  });
  const getEditConfigurableFieldModalProps = useGetEditConfigurableFieldModalProps(otherSectionFields);
  const { dataIndex } = useGridMenuState();
  const { useExchangeDefById, contextType, useExchanges } = useHooks();
  const exchangeDefById = useExchangeDefById();
  const exchanges = useExchanges({ required: false });

  const {
    pendingKeyboardEvent,
    rowData: exchangeDefs,
    setEditedCell,
    updateEachRowWith,
    updateFirstMatchingRow,
    updateRowAtIndex,
  } = useEditableGridData<LineItemExchangeDefinition>();
  const { menuReferenceId, toggleMenu } = useGridMenuState();
  const configureFieldModal = useConfigureFieldModal();

  const fields = exchangeDefs[0]?.fields;
  const isContract = contextType === ContextType.CONTRACT;

  const exchangeDefsWithContractExchanges = useExchangeDefsWithContractExchanges({
    isContract,
    exchangeDefs,
    exchanges,
  });

  const getFormattedFormula = useGetFormattedFormula(fields);

  const openEditFieldModal = React.useCallback((column: EditableGridColumn) => {
    if (column._id === 'description') {
      editDescriptionModal.open();
    } else {
      const field = fields[column._id];
      // @ts-expect-error ts(2345) FIXME: Argument of type '{ inputsConfig: { fieldName: { disabled: boolean; helperText?: undefined; }; isFixedSupplierResponseField: boolean; enableDecimalPlaces?: undefined; }; initialValues: { type: ConfigurableFieldType; ... 8 more ...; formulaFormat?: undefined; }; heading: string; submitLabel: string; } | { ...; } | { ...; } | { ...; } ...' is not assignable to parameter of type 'ConfigureFieldModalConfig<ConfigurableFieldFormValues>'.
      configureFieldModal.open(getEditConfigurableFieldModalProps(field, fields));
    }
  }, [fields, editDescriptionModal, configureFieldModal, getEditConfigurableFieldModalProps]);

  const openRemoveFieldModal = React.useCallback((column: EditableGridColumn) => {
    const body = t('lineItems.deleteConfigurableField.body', { name: column.label, ns: 'request' });
    const warningText = column._id === 'totalCost' ? t('request.lineItems.totalCost.removalWarning') : null;

    openConfirmDialog({
      action: () => {
        updateEachRowWith((lineItem) => {
          const updatedLineItem = deleteConfigurableField(lineItem, column._id);

          const orderedFieldIds = updatedLineItem.orderedFieldIds
            ? intersection(updatedLineItem.orderedFieldIds, Object.keys(updatedLineItem.fields))
            : getFieldIdsInDefaultDisplayOrder(Object.keys(updatedLineItem.fields));

          return {
            ...updatedLineItem,
            orderedFieldIds,
          };
        });
      },
      props: {
        heading: t('lineItems.deleteConfigurableField.heading', { name: column.label, ns: 'request' }),
        body: warningText
          ? (
            <>
              {body}
              <MessageBlock variant="warn">
                {warningText}
              </MessageBlock>
            </>
          )
          : body,
        okButtonText: t('request.lineItems.deleteField'),
        okButtonVariant: 'danger',
      },
    });
  }, [openConfirmDialog, t, updateEachRowWith]);

  const isRowDisabled = React.useCallback((rowIndex: number) => {
    if (isReadOnly) return false;
    if (isBeyondFirstResponseStage) return true;

    const exchangeDef = exchangeDefs[rowIndex];
    return isExchangeDefDisabled?.(exchangeDef);
  }, [isReadOnly, exchangeDefs, isExchangeDefDisabled, isBeyondFirstResponseStage]);

  const [unspscModalData, setUnspscModalData] =
    React.useState<{ fieldId: string; exchangeId: string; unspscCode: string | null } | null>(null);

  const openEditUnspcCodeModal = React.useCallback((
    row: RowData<any, any>,
    column: ColumnData<EditableGridColumn>,
  ) => {
    setUnspscModalData({
      fieldId: column.original._id,
      exchangeId: row.original._id,
      unspscCode: getFieldValue(row.original, column.original._id),
    });
    editUnspscCodeModal.open();
  }, [editUnspscCodeModal, getFieldValue]);

  const updateUnspscCode = React.useCallback((productTag) => {
    if (unspscModalData) {
      const { exchangeId, fieldId } = unspscModalData;

      updateFirstMatchingRow(
        { _id: exchangeId },
        row => {
          const unspscCode = productTag?._id || null;
          const rowWithUnspscCode = setExchangeDefFieldValue(row, fieldId, unspscCode);

          return immutableSet(rowWithUnspscCode, 'productOrService', productTag);
        },
      );

      editUnspscCodeModal.close();
    }
  }, [editUnspscCodeModal, unspscModalData, updateFirstMatchingRow]);

  const startEditingCell = React.useCallback((
    row: RowData<any, any>,
    column: ColumnData<EditableGridColumn>,
    event?: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (row.original.isObsolete || isRowDisabled(row.index)) return;

    if (column.original._id === 'unspscCode') {
      openEditUnspcCodeModal(row, column);
    } else if (column.original.startEditingCell) {
      pendingKeyboardEvent.current = event ?? null;

      setEditedCell({
        rowId: row.original._id,
        columnId: column.original._id,
      });
    }
  }, [isRowDisabled, setEditedCell, pendingKeyboardEvent, openEditUnspcCodeModal]);

  const isEditingFieldHeaderForbidden = (
    isReadOnly ||
    isEditingSupplierExchangeDefs ||
    areAnyExchangeDefsLinkedToActiveAuction ||
    isBeyondFirstResponseStage
  );

  const columnProps = React.useCallback((field: FieldConfig) => {
    const info = field.source.type === 'formula' ? getFormattedFormula(field._id) : null;

    const onMoveFieldLeftClick = (column: EditableGridColumn) => {
      updateEachRowWith(lineItem => {
        const orderedFieldIds = (
          lineItem.orderedFieldIds ||
          getFieldIdsInDefaultDisplayOrder(Object.keys(lineItem.fields))
        );

        const [invisibleFieldIds, visibleFieldIds] = partition(orderedFieldIds, fieldId => hiddenFieldIds.includes(fieldId));

        const fieldIndex = visibleFieldIds.indexOf(column._id);

        return {
          ...lineItem,
          orderedFieldIds: fieldIndex >= 1
            ? [...swap(visibleFieldIds, fieldIndex, fieldIndex - 1), ...invisibleFieldIds]
            : orderedFieldIds,
        };
      });
    };
    const onMoveFieldRightClick = (column: EditableGridColumn) => {
      updateEachRowWith(lineItem => {
        const orderedFieldIds = (
          lineItem.orderedFieldIds ||
          getFieldIdsInDefaultDisplayOrder(Object.keys(lineItem.fields))
        );

        const [invisibleFieldIds, visibleFieldIds] = partition(orderedFieldIds, fieldId => hiddenFieldIds.includes(fieldId));

        const fieldIndex = visibleFieldIds.indexOf(column._id);

        return {
          ...lineItem,
          orderedFieldIds: fieldIndex <= visibleFieldIds.length - 2
            ? [...swap(visibleFieldIds, fieldIndex, fieldIndex + 1), ...invisibleFieldIds]
            : orderedFieldIds,
        };
      });
    };

    return {
      startEditingCell: isReadOnly || !isDefinitionField(field) ? null : startEditingCell,
      Header: isEditingFieldHeaderForbidden ? (
        withProps(SimpleHeader, { info, showFieldTypeIcon: true })
      ) : (
        withProps(LineItemExchangeDefFieldHeader, {
          info,
          showFieldTypeIcon: true,
          testId: field._id === 'price' ? 'edit-price-per-unit-button' : null,
          // the only editable properties of `leadTime:submitter` concern
          // multi-stage responses, which aren't present in contracts
          onEditFieldClick: ['leadTime:submitter', 'unspscCode'].includes(field._id) || (isContract && field._id === 'leadTime:submitter') ? (
            null
          ) : (
            openEditFieldModal
          ),
          onMoveFieldLeftClick,
          onMoveFieldRightClick,
          onRemoveFieldClick: field._id === 'description'
            ? null
            : openRemoveFieldModal,
          removeFieldDisabledTooltip: areAnyExchangeDefsLinkedToAuction && ['unit', 'quantity', 'price', 'totalCost'].includes(field._id) ? (
            t('request.lineItems.deleteFieldDisabledAuctionTooltip')
          ) : (
            null
          ),
          otherSectionFields,
          configureFieldModal,
        })
      ),
      isDisabled: isRowDisabled,
      // unlike other number fields, 'quantity' only allows numbers > 0
      ...(['quantity'].includes(field._id) ? {
        format: 'number.positive',
        exclusiveMinValue: 0,
      } : {}),
      // adjust first column (which is always the 'description' column) to
      // also contain column numbers and the row's actions menu
      ...(field._id === 'description' ? {
        ValueCell: isEditingSupplierExchangeDefs
          ? SupplierDescriptionValueCell
          : TextValueCell,
        showIconWhenObsolete: true,
        width: 310,
      } : {
        width: DEFAULT_EDITABLE_DATA_CELL_WIDTH,
      }),
      required: isReadOnly ? false : field.required,
    };
  }, [
    getFormattedFormula,
    isReadOnly,
    startEditingCell,
    isEditingFieldHeaderForbidden,
    isContract,
    openEditFieldModal,
    openRemoveFieldModal,
    t,
    areAnyExchangeDefsLinkedToAuction,
    isRowDisabled,
    isEditingSupplierExchangeDefs,
    otherSectionFields,
    configureFieldModal,
    updateEachRowWith,
  ]);

  const columns = useConfigurableExchangeDefFieldColumns({
    fields,
    orderedFieldIds: exchangeDefs[0]?.orderedFieldIds,
    columnProps,
    isSender,
    isEditingSupplierExchangeDefs,
  });

  const columnsWithRowNumber = React.useMemo(() => {
    return [
      {
        _id: 'rowNumber',
        accessorKey: 'rowNumber',
        Header: SimpleHeader,
        label: '',
        ValueCell: ValidationAwareRowNumberCell,
        toggleMenu: isReadOnly ? undefined : toggleMenu,
        width: isReadOnly ? ACTION_COLUMN_WIDTH : 2 * ACTION_COLUMN_WIDTH,
        fixedWidth: true,
      },
      ...columns,
    ];
  }, [columns, isReadOnly, toggleMenu]);

  const handleMakeObsolete = (exchangeId) => {
    const linkedExchangeDefs = Object.values(exchangeDefById).filter(
      (linkedExchangeDef) => 'linkedExchangeDefId' in linkedExchangeDef &&
        linkedExchangeDef.linkedExchangeDefId === exchangeId &&
        linkedExchangeDef.type === ExchangeType.AUCTION_LINE_ITEM,
    );

    assertDefined(dataIndex, 'dataIndex');

    if (linkedExchangeDefs.length > 0) {
      openConfirmDialog({
        action: () => updateRowAtIndex(dataIndex, makeObsolete as any),
        props: {
          heading: t('request.auction.obsoleteDialog.heading'),
          body: (
            <MessageBlock variant="warn">
              {t('request.auction.obsoleteDialog.body')}
            </MessageBlock>
          ),
          okButtonText: t('request.auction.obsoleteDialog.okButton'),
          okButtonVariant: 'danger',
        },
      });
    } else {
      updateRowAtIndex(dataIndex, makeObsolete as any);
    }
  };

  const FooterContent = React.useMemo(() => {
    if (isReadOnly) {
      return null;
    } else {
      return ({ height }) => {
        return (
          <Box
            sx={{
                position: 'absolute',
                left: 8,
                top: height - 40,
              }}
          >
            {isEditingSupplierExchangeDefs ? (
              <AddSupplierProvidedLineItemButton
                modelExchangeDef={exchangeDefs[0] as any}
              />
            ) : (
              <AddBuyerProvidedLineItemButton
                modelExchangeDef={exchangeDefs[0] as any}
                disabled={isBeyondFirstResponseStage}
              />
            )}
          </Box>
        );
      };
    }
  }, [isReadOnly, isEditingSupplierExchangeDefs, exchangeDefs, isBeyondFirstResponseStage]);

  const AdditionalHeaderContent = React.useMemo(() => {
    return ({ width }) => {
      return (
        <Box
          sx={{
            position: 'absolute',
            left: width + 1,
            top: 9,
          }}
          onKeyDown={stopPropagation}
          onKeyUp={stopPropagation}
        >
          <AddLineItemFieldDropdown
            otherSectionFields={otherSectionFields}
            disabled={areAnyExchangeDefsLinkedToActiveAuction || isBeyondFirstResponseStage}
          />
        </Box>
      );
    };
  }, [otherSectionFields, areAnyExchangeDefsLinkedToActiveAuction, isBeyondFirstResponseStage]);

  const bodyPaddingBottom = FooterContent ? 45 : 0;

  const maxGridHeight = (
    DEFAULT_FROZEN_HEADER_HEIGHT +
    BORDER_ADJUSTMENT +
    bodyPaddingBottom +
    DEFAULT_ROW_HEIGHT * exchangeDefs.length +
    getScrollbarSize()
  );

  return (
    <>
      {configureFieldModal.isOpen && (
        <ConfigureFieldModal
          {...configureFieldModal}
        />
      )}

      {editDescriptionModal.isOpen && (
        <EditDescriptionModal
          isOpen={editDescriptionModal.isOpen}
          onClose={editDescriptionModal.close}
          onSubmit={(fieldName) => {
            const updater = createDescriptionFieldNameUpdater(fieldName);
            updateEachRowWith(updater);
            editDescriptionModal.close();
          }}
          initialValue={fields?.description.label}
        />
      )}
      {editUnspscCodeModal.isOpen && (
        <EditUnspscCodeModal
          isOpen={editUnspscCodeModal.isOpen}
          onClose={editUnspscCodeModal.close}
          unspscCode={unspscModalData?.unspscCode || null}
          onSubmit={updateUnspscCode}
        />
      )}
      {addGridRowsModal.isOpen && (
        <AddMoreItemsModal
          {...addGridRowsModal}
          heading={t('request.lineItems.addMoreLineItemsModal.heading')}
          info={(
            <Trans
              i18nKey="request.lineItems.addMoreLineItemsModal.info"
              values={{ count: additionalRowCount }}
              components={{ b: <b /> }}
            />
          )}
          onSubmit={(pasteMethod: GridPasteMethod) => {
            if (resolveWithPasteMethod.current) {
              resolveWithPasteMethod.current(pasteMethod);
            }
            resolveWithPasteMethod.current = null;
            addGridRowsModal.close();
          }}
          onCancel={() => {
            if (resolveWithPasteMethod.current) {
              resolveWithPasteMethod.current(null);
            }
            resolveWithPasteMethod.current = null;
            addGridRowsModal.close();
          }}
        />
      )}

      {exchangeDefs.length > 0 ? (
        <DefaultEditableGridStyles
          style={{ width: '100%', height: height || `min(100vh - ${viewportHeightDelta}px, ${maxGridHeight}px)` }}
          isReadOnly={isReadOnly}
        >
          {isReadOnly ? (
            <ReadOnlyGrid
              frozenLeftColumnIds={frozenLeftColumnIds}
              columns={columnsWithRowNumber}
              rowData={exchangeDefsWithContractExchanges as any}
            />
          ) : (
            <EditableGrid
              frozenLeftColumnIds={frozenLeftColumnIds}
              columns={columnsWithRowNumber}
              rowData={exchangeDefsWithContractExchanges as any}
              bodyPaddingBottom={bodyPaddingBottom}
              FooterContent={FooterContent}
              bodyPaddingRight={isEditingSupplierExchangeDefs ? 0 : 200}
              AdditionalHeaderContent={isEditingSupplierExchangeDefs ? null : AdditionalHeaderContent}
              navigableRange={navigableRange}
              selectableRange={selectableRange}
              highlightSelectionInHeaders
              gridActions={gridActions}
              onGridClipboardEvent={handleGridClipboardEvent}
            />
          )}
        </DefaultEditableGridStyles>
      ) : (
        <>
          <Text color="subtext" fontSize={2} mb={2}>
            {t('request.lineItems.noLineItemsAdded')}
          </Text>
          {showEmptyGridValidationError && (
            <IconText
              fontSize={2}
              color="danger"
              icon="info-circle"
              text={t('request.lineItems.addAtLeastOneItem')}
              mb={2}
            />
          )}
        </>
      )}
      {menuReferenceId ? (
        <ExchangeDefsGridMenu
          onObsoleteClick={handleMakeObsolete}
          canAddItems={!isBeyondFirstResponseStage}
        />
      ) : (
        null
      )}
      <Dialog style={{ content: { width: '500px' } }} {...confirmDialogProps} />
    </>
  );
};
