import { useMemo } from 'react';
import { isArray, isNil } from 'lodash';
import { Field, FieldType } from '@deepstream/common/exchangesConfig';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { FieldData, getRevisionChangesTableData } from '@deepstream/common/rfq-utils/exchangeAction';
import { PredefinedQuestionOption } from '@deepstream/common/rfq-utils';
import { ExchangeSnapshot } from '../types';
import { PreWrap } from '../PreWrapCell';

const InlineHighlightedText = ({ children }: any) => <Text fontSize={1} sx={{ display: 'inline' }} color="text">{children}</Text>;

const Change = ({
  type,
  label,
  previousValue,
  newValue,
}: FieldData) => {
  const { t } = useTranslation();

  const getValue = (value: any) => isNil(value) || value === '' ? (
    '—'
  ) : isArray(value) ? (
    value
      .map(item => [PredefinedQuestionOption.YES, PredefinedQuestionOption.NO].includes(item)
        ? t(`request.question.predefinedOption.${item}`)
        : item,
      )
      .join('\n')
  ) : (
    value
  );

  return (
    <Box mt={1}>
      <Text fontSize={1} lineHeight="normal" color="subtext">
        {
          Array.isArray(previousValue) || Array.isArray(newValue) ? (
            <Trans
              i18nKey="request.exchange.fieldValueChangedArray"
              values={{
                field: type === FieldType.ATTACHMENT ? label.name : label,
                previousValue: getValue(previousValue),
                newValue: getValue(newValue),
              }}
              components={{ b: <InlineHighlightedText />, pre: <PreWrap /> }}
            />
          ) : (
            <Trans
              i18nKey="request.exchange.fieldValueChanged"
              values={{
                field: type === FieldType.ATTACHMENT ? label.name : label,
                previousValue: getValue(previousValue),
                newValue: getValue(newValue),
              }}
              components={{ b: <InlineHighlightedText /> }}
            />
          )
        }
      </Text>
    </Box>
  );
};

interface RevisionChangesTableProps {
  action: any;
  exchange: ExchangeSnapshot;
  changedFields: Field[];
}

export const RevisionChangesTable = ({ action, exchange, changedFields }: RevisionChangesTableProps) => {
  const { t } = useTranslation();

  const data: FieldData[] = useMemo(
    () => getRevisionChangesTableData(action, exchange, changedFields, t),
    [action, exchange, changedFields, t],
  );

  return (
    <Box pt={1}>
      {data.map((props, index) => (
        <Change {...props} isFirst={index === 0} key={String(index)} />
      ))}
    </Box>
  );
};
