import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { compact, pick, uniqBy } from 'lodash';

import { useCurrentCompanyId } from './currentCompanyId';
import { useApi } from './api';
import { CompanyFinderField, CompanyFinderFieldProps } from './form/CompanyFinderField';

type CompanyFinderFormProps = Omit<CompanyFinderFieldProps, 'currentCompanyId' | 'onSelect' | 'searchCompanies'>;

export const CompanyFinderForm = ({
  name = 'company',
  ...props
}: CompanyFinderFormProps) => {
  const { t } = useTranslation();
  const [selectedCompanies, setSelectedCompanies] = useState<Array<{ _id: string | null; name: string }>>([]);

  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const searchCompanies = useCallback(
    async (text) => {
      if (!text) {
        // This branch is intended to immediately display all of the selected companies when the
        // company finder is first focused (ie: the input is empty)
        return selectedCompanies;
      }

      const result = await api.searchCompanies({ text, pageSize: 20, pageIndex: 0 });
      const companies = result.companies.map(company => pick(company, ['_id', 'name', 'address']));

      return [
        ...companies,
      ];
    },
    [api, selectedCompanies],
  );

  return (
    <CompanyFinderField
      currentCompanyId={currentCompanyId}
      name={name}
      onSelect={company => {
        setSelectedCompanies(companies => uniqBy(compact([...companies, company]), '_id'));
      }}
      placeholder={t('companyFinder.searchByCompanyName')}
      searchCompanies={searchCompanies}
      {...props}
    />
  );
};
