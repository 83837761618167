import { useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useApi, wrap } from './api';
import { ExchangeSnapshot } from './types';

// TODO we should ultimately get rid of this function and only request the
// relevant exchanges where needed
export const useAllExchanges = ({ rfqId, currentCompanyId }) => {
  const api = useApi();
  const queryClient = useQueryClient();

  const updateCachedExchange = useCallback((newExchange: ExchangeSnapshot) => {
    queryClient.setQueryData<ExchangeSnapshot[] | undefined>(
      ['allExchanges', { rfqId, currentCompanyId }],
      (exchanges) => {
        if (exchanges) {
          exchanges = exchanges.map(exchange =>
            exchange.def._id === newExchange.def._id &&
              exchange.recipientId === newExchange.recipientId
              ? newExchange
              : exchange,
          );
        }

        return exchanges;
      },
    );
  }, [queryClient, rfqId, currentCompanyId]);

  const queryKey = useMemo(
    () => ['allExchanges', { rfqId, currentCompanyId }],
    [rfqId, currentCompanyId],
  );

  const query = useQuery<ExchangeSnapshot[]>(
    queryKey,
    wrap(api.getAllExchanges),
    {
      staleTime: 10 * 1000,
      onSuccess: (exchanges) => {
        for (const exchange of exchanges) {
          queryClient.setQueryData(
            [
              'exchange',
              {
                rfqId,
                exchangeId: exchange.def._id,
                recipientId: exchange.recipientId,
                currentCompanyId,
              },
            ],
            exchange,
          );
        }
      },
    },
  );

  return useMemo(
    () => ({ ...query, updateCachedExchange, queryKey }),
    [query, updateCachedExchange, queryKey],
  );
};
