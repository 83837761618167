import { useState, useMemo, useCallback } from 'react';
import { filter, isEmpty, isEqual, keyBy, map, pick, values } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { ContractDocumentExchangeDefinition, ContractSection } from '@deepstream/common/contract';
import { Flex, Text } from 'rebass/styled-components';
import { useQueryClient } from 'react-query';
import { ExchangeType } from '@deepstream/common/rfq-utils';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton, EditButton, Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { ExchangeTableBase } from '../../ExchangeTable';
import { useExchangeNavigation } from '../../useExchangeModalState';
import { useContractData, useContractSection, useContractState, usePagePermissions } from './contract';
import { ExchangeSnapshot } from '../../types';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useContract } from './useContract';
import { ContractTableField } from './ContractTable';
import { usePreventEnterKeyHandler } from '../../usePreventEnterKeyHandler';
import { useSaveContractsSection } from './draftContract';
import { useModalState } from '../../ui/useModalState';
import { ChatbotChat } from '../AI/chatbot/ChatbotChat';
import { useChatbotSubjects } from '../AI/chatbot/ChatbotSubjectContext';
import { BasicTarget } from '../AI/chatbot/types';
import { useCompanyFeatureFlags } from '../../companyFeatureFlags';
import { useExchangeColumns } from '../Exchange/columns';
import { useDraftContractNavigation } from '../../appNavigation';
import { LeavePageModal } from '../../draft/LeavePageModal';

const ConfirmationModal = ({ close }: { close: () => void }) => {
  const { t } = useTranslation(['contracts', 'general']);
  const draftContractNavigation = useDraftContractNavigation();

  return (
    <Modal onRequestClose={close} isOpen style={{ content: { maxWidth: '550px', minWidth: '550px' } }}>
      <ModalHeader onClose={close}>
        {t('dialog.editLegacyDocuments.heading')}
      </ModalHeader>
      <ModalBody>
        <Stack gap={3}>
          <MessageBlock variant="info" mt={0}>
            {t('dialog.editLegacyDocuments.message')}
          </MessageBlock>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <CancelButton onClick={close} mr={2} />
        <Button type="button" onClick={draftContractNavigation.navigateToSummary}>
          {t('dialog.editLegacyDocuments.goToDraftedAmendment')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const ContractBidSection = () => {
  const { t } = useTranslation(['contracts', 'general']);
  const companyFeatureFlags = useCompanyFeatureFlags();
  const contract = useContractData();
  const section = useContractSection();
  const { openExchange } = useExchangeNavigation();
  const columns = useExchangeColumns();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { canEdit } = usePagePermissions();
  const [isEditing, setIsEditing] = useState(false);
  const onKeyDown = usePreventEnterKeyHandler();
  // @ts-expect-error ts(2339) FIXME: Property 'isLive' does not exist on type 'ContractStateContextType | undefined'.
  const { isLive } = useContractState();
  const [saveSection] = useSaveContractsSection({ shouldBePublished: contract.isLegacy && isLive });
  const confirmationModal = useModalState();
  const queryClient = useQueryClient();

  const { data: draftContract, isSuccess, queryKey } = useContract({
    contractId: contract._id,
    currentCompanyId,
    scope: 'draft',
    enabled: contract.isLegacy,
  });

  const { exchangeById } = contract;

  const exchanges = filter(
    values(exchangeById),
    (exchange: ExchangeSnapshot) => section.exchangeDefIds.includes(exchange._id),
  ) as ExchangeSnapshot[];

  const liveExchangeDefs = map(exchanges, exchange => exchange.def);
  const showContractType = (
    liveExchangeDefs.length !== 1 ||
    liveExchangeDefs[0].type === ExchangeType.LEGACY_CONTRACT
  );

  const contractColumns = useMemo(
    () => columns.contract({ showContractType }),
    [columns, showContractType],
  );

  const initialValues = useMemo(
    () => ({
      section,
      exchangeDefs: map(exchanges, exchange => exchange.def),
    }),
    [exchanges, section],
  );

  const onEditSection = useCallback(
    () => {
      const keys = ['_id', 'type', 'description', 'attachments'];
      const draftSection = draftContract?.sectionById[section._id];
      const sanitizedDraftExchangeDefs = map(
        // @ts-expect-error ts(18048) FIXME: 'draftSection' is possibly 'undefined'.
        draftSection.exchangeDefIds,
        // @ts-expect-error ts(18048) FIXME: 'draftContract' is possibly 'undefined'.
        exchangeDefId => pick(draftContract.exchangeDefById[exchangeDefId], keys),
      );
      const sanitizedLiveExchangeDefs = map(
        liveExchangeDefs,
        exchangeDef => pick(exchangeDef, keys),
      );
      const hasPendingDraftChanges = !isEqual(sanitizedDraftExchangeDefs, sanitizedLiveExchangeDefs);

      if (hasPendingDraftChanges) {
        confirmationModal.open();
      } else {
        setIsEditing(true);
      }
    },
    [draftContract, liveExchangeDefs, confirmationModal, section._id],
  );

  const chatbotTargets = useMemo(
    () => {
      const targets = (exchanges ?? [])
        .filter(exchange => exchange.latestAttachment?.mimetype === 'application/pdf')
        .map(exchange => ({
          type: 'attachment',
          // @ts-expect-error ts(18048) FIXME: 'exchange.latestAttachment' is possibly 'undefined'.
          _id: exchange.latestAttachment._id,
          // @ts-expect-error ts(18048) FIXME: 'exchange.latestAttachment' is possibly 'undefined'.
          name: exchange.latestAttachment.name,
          source: {
            type: 'contract/contract-exchange',
            // @ts-expect-error ts(18048) FIXME: 'exchange.latestAttachment' is possibly 'undefined'.
            documentId: exchange.latestAttachment._id,
          },
        } as BasicTarget));

      return keyBy(targets, target => target._id);
    },
    [exchanges],
  );

  useChatbotSubjects(chatbotTargets);

  return (
    <>
      <Panel>
        <PanelHeader heading={section.name} description={section.description}>
          {contract.isLegacy && isSuccess && canEdit && !isEditing && (
            <EditButton
              small
              onClick={onEditSection}
            />
          )}
        </PanelHeader>
        <PanelDivider />
        {isEditing ? (
          <Formik<{
            section: ContractSection;
            exchangeDefs: ContractDocumentExchangeDefinition[];
          }>
            validateOnBlur
            enableReinitialize
            initialValues={initialValues as any}
            validationSchema={
              yup.object().shape({
                section: yup.object().shape({
                  name: yup.string(),
                }),
                exchangeDefs: yup.array(
                  yup.object().shape({
                    description: yup.string().trim().required(t('required', { ns: 'general' })),
                    attachments: yup
                      .array(yup.object().required())
                      .min(1, t('required', { ns: 'general' }))
                      .required(t('required', { ns: 'general' })),
                  }),
                ),
              })
            }
            onSubmit={async ({ exchangeDefs }) => {
              await saveSection({
                section,
                exchangeDefs,
              }, {
                onSuccess: callAll(
                  () => setIsEditing(false),
                  () => queryClient.invalidateQueries(queryKey),
                ),
              });
            }}
          >
            {({ isSubmitting, dirty, isValid, resetForm }) => (
              <Form style={{ width: '100%' }} onKeyDown={onKeyDown}>
                <PanelPadding>
                  <ContractTableField fieldName="exchangeDefs" />
                </PanelPadding>
                <PanelDivider />
                <PanelPadding>
                  <Flex justifyContent="flex-end">
                    <CancelButton onClick={callAll(resetForm, () => setIsEditing(false))} mr={2} />
                    <SaveButton disabled={isSubmitting || !dirty || !isValid} />
                  </Flex>
                </PanelPadding>
                <LeavePageModal />
              </Form>
            )}
          </Formik>
        ) : !isEmpty(exchanges) ? (
          <ExchangeTableBase
            isPaginated={false}
            columns={contractColumns}
            exchanges={exchanges}
            onRowClick={openExchange}
          />
        ) : (
          <PanelPadding>
            <Text color="subtext" fontSize={2}>{t('noContractsAdded')}</Text>
          </PanelPadding>
        )}
      </Panel>
      {confirmationModal.isOpen && (
        <ConfirmationModal
          close={confirmationModal.close}
        />
      )}
      {!isEditing && companyFeatureFlags?.contractChatbotEnabled && (
        <ChatbotChat showErrorDisclosures={false} />
      )}
    </>
  );
};
