import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { useContract } from './useContract';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { Loading } from '../../ui/Loading';
import { ErrorMessage } from '../../ui/ErrorMessage';
import { ContractProvider, useContractState, useGeneralPages } from './contract';
import { DraftContractFooter } from './DraftContractFooter';
import { ContractDraftPageContent } from './ContractDraftPageContent';
import { ContractDetails } from './ContractDetails';
import { ModelSizeLimitsProvider } from '../../modelSizeLimits';
import { useApi, wrap } from '../../api';
import { useDraftContractNavigation } from '../../appNavigation';

const DraftDetailsFooter = ({ pageId }: { pageId?: string }) => {
  const { navigateToSummary, navigateToContract, navigateToDetailsPage } = useDraftContractNavigation();
  const generalPages = useGeneralPages();

  const onContinue = useCallback(
    () => {
      const pageIndex = generalPages.findIndex(page => page._id === pageId);
      const isLastPage = pageIndex === generalPages.length - 1;
      const nextPage = generalPages[pageIndex + 1];

      if (!pageId || isLastPage) {
        navigateToContract();
      } else {
        navigateToDetailsPage(nextPage._id);
      }
    },
    [pageId, generalPages, navigateToContract, navigateToDetailsPage],
  );

  const onBack = useCallback(
    () => {
      const pageIndex = generalPages.findIndex(page => page._id === pageId);
      const isFirstPage = pageIndex === 0;
      const previousPage = generalPages[pageIndex - 1];

      if (!pageId || isFirstPage) {
        navigateToSummary();
      } else {
        navigateToDetailsPage(previousPage._id);
      }
    },
    [pageId, generalPages, navigateToSummary, navigateToDetailsPage],
  );

  return (
    <DraftContractFooter onBack={onBack} onContinue={onContinue} />
  );
};

export const ContractDraftDetails = ({
  contractId,
  pageId,
}: {
  contractId: string;
  pageId?: string;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  // @ts-expect-error ts(2339) FIXME: Property 'isTemplate' does not exist on type 'ContractStateContextType | undefined'.
  const { isTemplate } = useContractState();
  const api = useApi();

  const { data: contract, isLoading, isError, isSuccess } = useContract({
    contractId,
    currentCompanyId,
    scope: 'draft',
    isTemplate,
  });

  const { data: sizeLimits, status: sizeLimitsStatus } = useQuery(
    ['contractSizeLimits', { contractId, currentCompanyId }],
    wrap(api.getContractSizeLimits),
    {
      staleTime: 60 * 1000,
    },
  );

  return (
    <>
      {isLoading || sizeLimitsStatus === 'loading' ? (
        <PanelPadding>
          <Loading />
        </PanelPadding>
      ) : isError || sizeLimitsStatus === 'error' ? (
        <PanelPadding>
          <ErrorMessage error={t('errors.unexpected')} />
        </PanelPadding>
      ) : isSuccess && sizeLimitsStatus === 'success' && contract ? (
        <ModelSizeLimitsProvider {...sizeLimits}>
          <ContractProvider contract={contract}>
            <ContractDraftPageContent>
              {/*
               // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'. */}
              <ContractDetails pageId={pageId} />
            </ContractDraftPageContent>
            <DraftDetailsFooter pageId={pageId} />
          </ContractProvider>
        </ModelSizeLimitsProvider>
      ) : (
        null
      )}
    </>
  );
};
