import { Box, Text, Flex } from 'rebass/styled-components';
import * as Sentry from '@sentry/react';
import { UnexpectedStateError } from '@deepstream/errors';
import { QuestionExchangeDefinition, QuestionType, ShortTextQuestionExchangeDefinition } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';

export const ShortText = ({
  exchangeDef,
}: {
  exchangeDef: QuestionExchangeDefinition
}) => {
  const { t } = useTranslation();
  const { questionType, schema } = exchangeDef as ShortTextQuestionExchangeDefinition;

  if (questionType !== QuestionType.SHORT_TEXT) {
    Sentry.captureException(new UnexpectedStateError('[ShortText] Wrong question type passed', { questionType }));
    return null;
  }

  return (
    <Box>
      <Text sx={{ display: 'block' }}>
        {t(`request.question.questionType.${questionType}`)}
      </Text>
      {typeof schema !== 'undefined' && (
        <Flex mt={2}>
          <Text>{t('request.question.allowANumberOnly')}</Text>
          <Text sx={{ ml: 3 }}>{t(`request.question.schemaType.${schema.type}`)}</Text>
          <Text sx={{ ml: 3 }}>{t('request.question.minimumValue', { value: schema.min })}</Text>
          <Text sx={{ ml: 3 }}>{t('request.question.maximumValue', { value: schema.max })}</Text>
        </Flex>
      )}
    </Box>
  );
};
