import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Draft, Lot } from '@deepstream/common/rfq-utils';
import { IconValue } from '@deepstream/common';
import * as rfx from '../rfx';

export const NO_LOT_PROXY_ID = 'no-lot';

export type LotSelectItem = {
  value: string;
  label: string;
  description: string;
  icon: IconValue;
  isIconRegular?: boolean;
  isObsolete?: boolean;
};

export const useLotSelectItems = ({ isEvaluation, lots: lotsParam }: { isEvaluation?: boolean, lots?: Lot[] } = {}): LotSelectItem[] => {
  const { lots: structureLots } = rfx.useStructure<Draft>();
  const { t } = useTranslation('translation');

  const lots = lotsParam || structureLots;

  return useMemo(() => {
    return [
      {
        value: NO_LOT_PROXY_ID,
        label: isEvaluation
          ? t('request.lots.evaluationSectionLotConfigurationNoLot')
          : t('request.lots.sectionLotConfigurationNoLot'),
        description: isEvaluation
          ? t('request.lots.evaluationSectionLotConfigurationNoLotHelperText')
          : t('request.lots.sectionLotConfigurationNoLotHelperText'),
        icon: 'xmark' as const,
      },
      ...lots.map((lot, index) => ({
        value: lot._id,
        label: `${index + 1} – ${lot.name || t('request.lots.untitledLot')}`,
        description: isEvaluation
          ? t('request.lots.evaluationSectionLotConfigurationLotHelperText')
          : t('request.lots.sectionLotConfigurationLotHelperText'),
        icon: 'grid-2' as const,
        isIconRegular: true,
        isObsolete: lot.isObsolete,
      })),
    ];
  }, [lots, t, isEvaluation]);
};
