import {
  AddressQuestionExchangeDefinition,
  CheckboxesQuestionExchangeDefinition,
  DateTimeQuestionExchangeDefinition,
  Draft,
  ExchangeType,
  LongTextQuestionExchangeDefinition,
  MultipleChoiceQuestionExchangeDefinition,
  PriceQuestionExchangeDefinition,
  QuestionAddressField,
  QuestionExchangeDefinition,
  QuestionFormat,
  QuestionType,
  ShortTextQuestionExchangeDefinition,
  ShortTextSchemaType,
} from '@deepstream/common/rfq-utils';
import { camelCase, pickBy } from 'lodash';

// NB the keys are required to match the header texts in all published
// versions of questions-sample.csv and questions-sample.xlsx;
// We cannot rely on translation strings to map the headers
// as these might change overtime without warning and would break the bulk upload mechanism.
const headerMap = [
  {
    key: 'description',
    headers: ['Question', 'Question', 'Pregunta', 'Pergunta', '问题'],
  },
  {
    key: 'questionType',
    headers: ['Type', 'Type', 'Tipo', 'Tipo', '类型'],
  },
  {
    key: 'required',
    headers: ['Required', 'Obligatoire', 'Obligatorio', 'Obrigatório', '必填'],
  },
  {
    key: 'shortTextOnlyNumbers',
    headers: [
      'Short-answer text - "Allow a number only" enabled',
      'Texte de réponse courte – « Autoriser un numéro seulement » activé',
      'Texto de respuesta corta: "Permitir solo un número" activado',
      'Texto de resposta curta - "Permitir apenas um número" ativado',
      '简短文本回答 — “仅限数字”已启用',
    ],
  },
  {
    key: 'shortTextNumberType',
    headers: [
      'Short-answer text - Response validation number type',
      'Texte de réponse courte – Type de numéro de validation de la réponse',
      'Texto de respuesta corta: tipo de número de validación de la respuesta',
      'Texto de resposta curta - Tipo de número de validação de resposta',
      '简短文本回答 — 响应验证数字类型',
    ],
  },
  {
    key: 'shortTextMin',
    headers: [
      'Short-answer text - Response validation number min',
      'Texte de réponse courte – Nombre min. de validation de la réponse',
      'Texto de respuesta corta: número mínimo de validación de la respuesta',
      'Texto de resposta curta - Número mínimo de validação de resposta',
      '简短文本回答 — 响应验证最小值',
    ],
  },
  {
    key: 'shortTextMax',
    headers: [
      'Short-answer text - Response validation number max',
      'Texte de réponse courte – Nombre max. de validation de la réponse',
      'Texto de respuesta corta: número máximo de validación de la respuesta',
      'Texto de resposta curta - Número máximo de validação de resposta',
      '简短文本回答 — 响应验证最大值',
    ],
  },
  {
    key: 'multipleChoiceOptions',
    headers: [
      'Multiple choice - Response list',
      'Choix multiple – Liste de réponses',
      'Opción múltiple: lista de respuestas',
      'Escolha múltipla - Lista de respostas',
      '选择题 — 响应列表',
    ],
  },
  {
    key: 'multipleChoiceOther',
    headers: [
      'Multiple choice - "Other" response enabled',
      'Choix multiple – Réponse « Autre » activée',
      'Opción múltiple: respuesta "Otros" activada',
      'Escolha múltipla - resposta "Outro" ativada',
      '选择题 — “其他”响应已启用',
    ],
  },
  {
    key: 'checkboxesOptions',
    headers: [
      'Checkboxes - Response list',
      'Cases à cocher – Liste de réponses',
      'Casillas de verificación: lista de respuestas',
      'Caixas de seleção - Lista de respostas',
      '复选框 — 响应列表',
    ],
  },
  {
    key: 'checkboxesOther',
    headers: [
      'Checkboxes - "Other" response enabled',
      'Cases à cocher – Réponse « Autre » activée',
      'Casillas de verificación: respuesta "Otros" activada',
      'Caixas de seleção - resposta "Outro" ativada',
      '复选框 — “其他”响应已启用',
    ],
  },
  {
    key: 'specifyCurrency',
    headers: [
      'Price - "Specify currency" enabled',
      'Prix – « Spécifier la devise » activé',
      'Precio: "Especificar moneda" activado',
      'Preço - "Especificar moeda" ativado',
      // legacy questions-sample template
      'Price - "Specify currency"',
      'Prix – « Spécifier la devise »',
      '价格 — “指定货币”已启用',
    ],
  },
  {
    key: 'currency',
    headers: [
      'Price - Currency code',
      'Prix – Code de la devise',
      'Precio: código de moneda',
      'Preço - Código da moeda',
      '价格 — 货币代码',
    ],
  },
  {
    key: 'addressCompanyNameVisible',
    headers: [
      'Address / Location - "Company name" visible',
      'Adresse / Emplacement – « Nom de l’entreprise » visible',
      'Dirección / Ubicación: "Nombre de la empresa" visible',
      'Morada / Localização - "Nome da empresa" visível',
      '地址/位置 — 显示“公司名称”',
    ],
  },
  {
    key: 'addressLineOneVisible',
    headers: [
      'Address / Location - "Address line 1" visible',
      'Adresse / Emplacement – « Ligne d’adresse 1 » visible',
      'Dirección / Ubicación: "Dirección 1" visible',
      'Morada / Localização - "Morada linha 1" visível',
      '地址/位置 — 显示“地址行 1”',
    ],
  },
  {
    key: 'addressLineTwoVisible',
    headers: [
      'Address / Location - "Address line 2 (optional)" visible',
      'Adresse / Emplacement – « Ligne d’adresse 2 (facultatif) » visible',
      'Dirección / Ubicación: "Dirección 2 (opcional)" visible',
      'Morada / Localização - "Morada linha 2 (opcional)" visível',
      '地址/位置 — 显示“地址行 2（可选）”',
    ],
  },
  {
    key: 'addressCityVisible',
    headers: [
      'Address / Location - "City / Town" visible',
      'Adresse / Emplacement – « Ville / Commune » visible',
      'Dirección / Ubicación: "Ciudad / Población" visible',
      'Morada / Localização - "Cidade" visível',
      '地址/位置 — 显示“城市/镇”',
    ],
  },
  {
    key: 'addressStateVisible',
    headers: [
      'Address / Location - "State / Province (optional)" visible',
      'Adresse / Emplacement – « État / Province (facultatif) » visible',
      'Dirección / Ubicación: "Estado / Provincia (opcional)" visible',
      'Morada / Localização - "Estado / Província (opcional)" visível',
      '地址/位置 — 显示“州/省（可选）”',
    ],
  },
  {
    key: 'addressPostcodeVisible',
    headers: [
      'Address / Location - "ZIP / Postal code" visible',
      'Adresse / Emplacement – « Code postal / ZIP » visible',
      'Dirección / Ubicación: "Código postal" visible',
      'Morada / Localização - "Código postal" visível',
      '地址/位置 — 显示“邮政编码”',
    ],
  },
  {
    key: 'addressCountryVisible',
    headers: [
      'Address / Location - "Country" visible',
      'Adresse / Emplacement – « Pays » visible',
      'Dirección / Ubicación: "País" visible',
      'Morada / Localização - "País" visível',
      '地址/位置 — 显示“国家/地区”',
    ],
  },
  {
    key: 'addressContactNameVisible',
    headers: [
      'Address / Location - "Contact name" visible',
      'Adresse / Emplacement – « Nom du contact » visible',
      'Dirección / Ubicación: "Nombre de contacto" visible',
      'Morada / Localização - "Nome de contacto" visível',
      '地址/位置 — 显示“联系人姓名”',
    ],
  },
  {
    key: 'addressEmailVisible',
    headers: [
      'Address / Location - "Email address" visible',
      'Adresse / Emplacement – « Adresse e-mail » visible',
      'Dirección / Ubicación: "Dirección de correo electrónico" visible',
      'Morada / Localização - "Endereço de email" visível',
      '地址/位置 — 显示“电子邮件地址”',
    ],
  },
  {
    key: 'addressPhoneVisible',
    headers: [
      'Address / Location - "Phone number" visible',
      'Adresse / Emplacement – « Numéro de téléphone » visible',
      'Dirección / Ubicación: "Número de teléfono" visible',
      'Morada / Localização - "Número de telefone" visível',
      '地址/位置 — 显示“电话号码”',
    ],
  },
  {
    key: 'dateTimeDateEnabled',
    headers: [
      'Date / Time - Date enabled',
      'Date / Heure – Date activée',
      'Fecha / Hora: fecha activada',
      'Data / Hora - Data ativada',
      '日期/时间 — 日期已启用',
    ],
  },
  {
    key: 'dateTimeTimeEnabled',
    headers: [
      'Date / Time - Time enabled',
      'Date / Heure – Heure activée',
      'Fecha / Hora: hora activada',
      'Data / Hora - Hora ativada',
      '日期/时间 — 时间已启用',
    ],
  },
];

export const getHeaderKey = (header: string) => headerMap.find(item => item.headers.includes(header))?.key;

export const fileQuestionTypeToQuestionType = {
  'short-answer text': QuestionType.SHORT_TEXT,
  'long-answer text': QuestionType.LONG_TEXT,
  'multiple choice': QuestionType.MULTIPLE_CHOICE,
  'checkboxes': QuestionType.CHECKBOXES,
  'price': QuestionType.PRICE,
  'address / location': QuestionType.ADDRESS,
  'date / time': QuestionType.DATE_TIME,
};

export type ParsedQuestion =
  | ParsedShortTextQuestion
  | ParsedLongTextQuestion
  | ParsedMultipleChoiceQuestion
  | ParsedCheckboxesQuestion
  | ParsedPriceQuestion
  | ParsedAddressQuestion
  | ParsedDateTimeQuestion;

export type BaseParsedQuestion<QuestionType> = {
  questionType: QuestionType;
  description: string;
  required: boolean;
};

export type ParsedShortTextQuestion = BaseParsedQuestion<QuestionType.SHORT_TEXT> & {
  shortTextOnlyNumbers: boolean;
  shortTextNumberType: string;
  shortTextMin: number;
  shortTextMax: number;
};

export function mapParsedShortTextExchangeDefinition(parsedQuestion: ParsedShortTextQuestion): Partial<ShortTextQuestionExchangeDefinition> {
  const { description, required, shortTextOnlyNumbers, shortTextNumberType, shortTextMin, shortTextMax } = parsedQuestion;
  return {
    type: ExchangeType.QUESTION,
    questionType: QuestionType.SHORT_TEXT,
    description,
    isRequired: required,
    schema: shortTextOnlyNumbers ? { type: shortTextNumberType === 'whole' ? ShortTextSchemaType.INTEGER : ShortTextSchemaType.DECIMAL, min: shortTextMin, max: shortTextMax } : undefined,
  };
}

export type ParsedLongTextQuestion = BaseParsedQuestion<QuestionType.LONG_TEXT>;

export function mapParsedLongTextExchangeDefinition(parsedQuestion: ParsedLongTextQuestion): Partial<LongTextQuestionExchangeDefinition> {
  const { description, required } = parsedQuestion;
  return {
    type: ExchangeType.QUESTION,
    questionType: QuestionType.LONG_TEXT,
    description,
    isRequired: required,
  };
}

export type ParsedMultipleChoiceQuestion = BaseParsedQuestion<QuestionType.MULTIPLE_CHOICE> & {
  multipleChoiceOther: boolean;
  multipleChoiceOptions: string[];
};

export function mapParsedMultipleChoiceExchangeDefinition(parsedQuestion: ParsedMultipleChoiceQuestion): Partial<MultipleChoiceQuestionExchangeDefinition> {
  const { description, required, multipleChoiceOther, multipleChoiceOptions } = parsedQuestion;
  return {
    type: ExchangeType.QUESTION,
    questionType: QuestionType.MULTIPLE_CHOICE,
    description,
    isRequired: required,
    options: multipleChoiceOptions,
    allowCustomOption: multipleChoiceOther,
  };
}

export type ParsedCheckboxesQuestion = BaseParsedQuestion<QuestionType.CHECKBOXES> & {
  checkboxesOther: boolean;
  checkboxesOptions: string[];
};

export function mapParsedCheckboxesExchangeDefinition(parsedQuestion: ParsedCheckboxesQuestion): Partial<CheckboxesQuestionExchangeDefinition> {
  const { description, required, checkboxesOther, checkboxesOptions } = parsedQuestion;
  return {
    type: ExchangeType.QUESTION,
    questionType: QuestionType.CHECKBOXES,
    description,
    isRequired: required,
    options: checkboxesOptions,
    allowCustomOption: checkboxesOther,
  };
}

export type ParsedPriceQuestion = BaseParsedQuestion<QuestionType.PRICE> & {
  specifyCurrency: boolean;
  currency: string;
};

export function mapParsedPriceExchangeDefinition(parsedQuestion: ParsedPriceQuestion): Partial<PriceQuestionExchangeDefinition> {
  const { description, required, specifyCurrency, currency } = parsedQuestion;
  return {
    type: ExchangeType.QUESTION,
    questionType: QuestionType.PRICE,
    description,
    isRequired: required,
    currencies: specifyCurrency ? [currency.toUpperCase()] : null,
  };
}

export type ParsedAddressQuestion = BaseParsedQuestion<QuestionType.ADDRESS> & {
  addressCompanyNameVisible: boolean;
  addressLineOneVisible: boolean;
  addressLineTwoVisible: boolean;
  addressCityVisible: boolean;
  addressStateVisible: boolean;
  addressPostcodeVisible: boolean;
  addressCountryVisible: boolean;
  addressContactNameVisible: boolean;
  addressEmailVisible: boolean;
  addressPhoneVisible: boolean;
};

export function mapParsedAddressExchangeDefinition(parsedQuestion: ParsedAddressQuestion): Partial<AddressQuestionExchangeDefinition> {
  const { description, required, ...addressFields } = parsedQuestion;
  return {
    type: ExchangeType.QUESTION,
    questionType: QuestionType.ADDRESS,
    description,
    isRequired: required,
    visibleFields: Object
      .keys(pickBy(addressFields, value => value))
      .map(key => camelCase(
        key
          .replace('address', '')
          .replace('Visible', ''),
      )) as QuestionAddressField[],
  };
}

export type ParsedDateTimeQuestion = BaseParsedQuestion<QuestionType.DATE_TIME> & {
  dateTimeDateEnabled: boolean;
  dateTimeTimeEnabled: boolean;
};

export function mapParsedDateTimeExchangeDefinition(parsedQuestion: ParsedDateTimeQuestion): Partial<DateTimeQuestionExchangeDefinition<Draft>> {
  const { description, required, dateTimeDateEnabled, dateTimeTimeEnabled } = parsedQuestion;
  return {
    type: ExchangeType.QUESTION,
    questionType: QuestionType.DATE_TIME,
    description,
    isRequired: required,
    format: dateTimeDateEnabled && dateTimeTimeEnabled
      ? QuestionFormat.DATETIME
      : dateTimeDateEnabled ? QuestionFormat.DATE
      : dateTimeTimeEnabled ? QuestionFormat.TIME
      : QuestionFormat.INVALID,
  };
}

// @ts-expect-error ts(2366) FIXME: Function lacks ending return statement and return type does not include 'undefined'.
export function mapParsedQuestion(parsedQuestion: ParsedQuestion): Partial<QuestionExchangeDefinition<Draft>> {
  switch (fileQuestionTypeToQuestionType[parsedQuestion.questionType]) {
    case QuestionType.SHORT_TEXT:
      return mapParsedShortTextExchangeDefinition(parsedQuestion as ParsedShortTextQuestion);
    case QuestionType.LONG_TEXT:
      return mapParsedLongTextExchangeDefinition(parsedQuestion as ParsedLongTextQuestion);
    case QuestionType.MULTIPLE_CHOICE:
      return mapParsedMultipleChoiceExchangeDefinition(parsedQuestion as ParsedMultipleChoiceQuestion);
    case QuestionType.CHECKBOXES:
      return mapParsedCheckboxesExchangeDefinition(parsedQuestion as ParsedCheckboxesQuestion);
    case QuestionType.PRICE:
      return mapParsedPriceExchangeDefinition(parsedQuestion as ParsedPriceQuestion);
    case QuestionType.ADDRESS:
      return mapParsedAddressExchangeDefinition(parsedQuestion as ParsedAddressQuestion);
    case QuestionType.DATE_TIME:
      return mapParsedDateTimeExchangeDefinition(parsedQuestion as ParsedDateTimeQuestion);
  }
}
