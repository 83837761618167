import * as React from 'react';
import { Form, Formik, useField, useFormikContext } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Text } from 'rebass/styled-components';
import { transparentize } from 'polished';
import { concat, difference, intersection, isEmpty, size, uniq, without } from 'lodash';
import styled from 'styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { withProps } from '@deepstream/ui-utils/withProps';
import { WrapperButton } from '@deepstream/ui-kit/elements/button/WrapperButton';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { ExtendedSupplierListOverview } from '@deepstream/common';
import { useModalState } from '../../ui/useModalState';
import { useExtendedSupplierLists } from '../SupplierLists/useSupplierLists';
import { Loading } from '../../ui/Loading';
import { ErrorMessage } from '../../ui/ErrorMessage';
import { Counter } from '../../ui/Badge';
import { CompanyLogo } from '../../CompanyLogo';
import { useDeviceSize } from '../../ui/useDeviceSize';

export const AddRecipientFromListButton = ({
  recipientIds,
  onAddFromList,
}: {
  recipientIds: string[];
  onAddFromList: (companyIds: string[]) => void;
}) => {
  const { t } = useTranslation();
  const { supplierLists, supplierListsStatus } = useExtendedSupplierLists();
  const addFromListModal = useModalState();

  return (
    <>
      <Tooltip
        content={supplierListsStatus === 'success' && isEmpty(supplierLists)
          ? t('supplierLists.noListsToAdd') as string
          : ''
        }
      >
        <span style={{ display: 'inline-block' }}>
          <Button
            variant="secondary"
            iconLeft="list-ul"
            disabled={supplierListsStatus !== 'success' || isEmpty(supplierLists)}
            onClick={addFromListModal.open}
          >
            {t('request.myLists')}
          </Button>
        </span>
      </Tooltip>
      {addFromListModal.isOpen && (
        <AddRecipientFromListModal
          recipientIds={recipientIds}
          isOpen={addFromListModal.isOpen}
          onCancel={addFromListModal.close}
          onSave={onAddFromList}
        />
      )}
    </>
  );
};

const SupplierListItem = ({
  supplierList,
  isSelected,
  selectList,
}: {
  supplierList: ExtendedSupplierListOverview;
  isSelected: boolean;
  selectList: (listId: string) => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isExtraSmall } = useDeviceSize();
  const [{ value: selectedCompanyIds }] = useField('selectedCompanyIds');

  const numSelectedCompaniesInList = React.useMemo(
    () => size(intersection(supplierList.supplierIds, selectedCompanyIds)),
    [supplierList, selectedCompanyIds],
  );

  return (
    <WrapperButton
      display="block"
      width="100%"
      sx={{
        backgroundColor: isSelected ? transparentize(0.95, theme.colors.primary) : '',
        ':not(:first-child)': {
          borderTop: 'lightGray2',
        },
      }}
      onClick={() => selectList(supplierList._id)}
    >
      <Flex p={3} alignItems="center" height="65px">
        <Box flex="1 1 auto" textAlign="start" lineHeight={1.3}>
          {/* @ts-expect-error FIXME */}
          <Truncate as="div" color="text" fontWeight={500}>
            {supplierList.name}
          </Truncate>
          <Text as="div" color="subtext">
            {t('general.companyCount', { count: size(supplierList.supplierIds) })}
          </Text>
        </Box>
        <Counter
          color="primary"
          count={numSelectedCompaniesInList}
          ml={2}
          sx={{
            fontSize: 1,
            fontWeight: 400,
            height: '20px',
            minWidth: '20px',
            lineHeight: '20px',
          }}
        />
        {isExtraSmall && (
          <Icon
            icon="chevron-right"
            color="subtext"
            fontSize={4}
            ml={3}
            width="16px"
          />
        )}
      </Flex>
    </WrapperButton>
  );
};

const Footer = ({ disabledCompanyIds, onCancel }: { disabledCompanyIds: string[]; onCancel: () => void }) => {
  const { t } = useTranslation();
  const { isSubmitting, isValid, dirty } = useFormikContext();
  const [{ value: selectedCompanyIds }] = useField('selectedCompanyIds');

  const newlySelectedCompanyIds = React.useMemo(
    () => difference(selectedCompanyIds, disabledCompanyIds),
    [selectedCompanyIds, disabledCompanyIds],
  );

  return (
    <ModalFooter justifyContent="space-between">
      <Text>
        {t('general.selectedCompaniesCount', { count: size(newlySelectedCompanyIds) })}
      </Text>
      <Box>
        <CancelButton onClick={onCancel} />
        <Button type="submit" disabled={isSubmitting || !dirty || !isValid} width={70}>
          {t('general.add')}
        </Button>
      </Box>
    </ModalFooter>
  );
};

const Row = styled(Flex)`
  height: 45px;
  padding: ${props => props.theme.space[3]}px;
  align-items: center;
`;

const CompanyCheckboxLabel = ({
  company,
  children,
}: {
  company: { _id: string; name: string };
  children: React.ReactNode;
}) => (
  <Flex alignItems="center" ml={3}>
    <Box flex="0 0 auto">
      <CompanyLogo companyId={company._id} size="xs" />
    </Box>
    <Truncate ml={2} fontSize={2} color="text" fontWeight={400}>
      {children}
    </Truncate>
  </Flex>
);

const ListCheckboxLabel = styled(Text)`
  padding-left: ${props => props.theme.space[3]}px;
  font-size: ${props => props.theme.fontSizes[2]}px;
  color: ${props => props.theme.colors.text};
  font-weight: 400;
`;

const CompanyCheckbox = ({
  company,
  disabledCompanyIds,
}: {
  company: { _id: string; name: string };
  disabledCompanyIds: string[];
}) => {
  const [{ value: selectedCompanyIds },, helper] = useField('selectedCompanyIds');

  const onToggleCompany = React.useCallback(
    (companyId) => {
      if (selectedCompanyIds.includes(companyId)) {
        helper.setValue(without(selectedCompanyIds, companyId));
      } else {
        helper.setValue([...selectedCompanyIds, companyId]);
      }
    },
    [selectedCompanyIds, helper],
  );

  const CheckboxLabel = React.useMemo(
    () => withProps(CompanyCheckboxLabel, { company }),
    [company],
  );

  return (
    <Checkbox
      label={company.name}
      checked={selectedCompanyIds.includes(company._id)}
      disabled={disabledCompanyIds.includes(company._id)}
      onChange={() => onToggleCompany(company._id)}
      style={{ alignItems: 'center', width: '100%' }}
      CheckboxLabel={CheckboxLabel}
    />
  );
};

const SelectedListPanel = ({
  selectedListId,
  disabledCompanyIds,
}: {
  selectedListId: string | null;
  disabledCompanyIds: string[];
}) => {
  const { t } = useTranslation();
  const { isExtraSmall } = useDeviceSize();
  const { supplierLists } = useExtendedSupplierLists();
  const [{ value: selectedCompanyIds },, helper] = useField('selectedCompanyIds');
  const checkboxesContainerRef = React.useRef<HTMLDivElement>(null);
  const [containerOverflows, setContainerOverflows] = React.useState(false);

  const selectedList = React.useMemo(
    () => selectedListId
      ? supplierLists.find(list => list._id === selectedListId)
      : null,
    [supplierLists, selectedListId],
  );

  const selectedCompanyIdsFromList = React.useMemo(
    () => selectedList
      ? intersection(selectedList.supplierIds, selectedCompanyIds)
      : [],
    [selectedList, selectedCompanyIds],
  );

  const newlySelectedCompanyIdsFromList = React.useMemo(
    () => difference(selectedCompanyIdsFromList, disabledCompanyIds),
    [selectedCompanyIdsFromList, disabledCompanyIds],
  );

  const enabledCompanyIds = React.useMemo(
    () => difference(selectedList?.supplierIds, disabledCompanyIds),
    [selectedList, disabledCompanyIds],
  );

  const allCompaniesSelected = selectedCompanyIdsFromList.length === selectedList?.supplierIds.length;

  const onToggleAll = React.useCallback(
    (event) => {
      if (event.target.checked) {
        helper.setValue(uniq(concat(selectedCompanyIds, ...enabledCompanyIds)));
      } else {
        helper.setValue(difference(selectedCompanyIds, enabledCompanyIds));
      }
    },
    [selectedCompanyIds, enabledCompanyIds, helper],
  );

  React.useEffect(
    () => {
      const element = checkboxesContainerRef.current;
      const overflows = Boolean(element && element.scrollHeight > element.clientHeight);

      setContainerOverflows(overflows);
    },
    [selectedList, setContainerOverflows],
  );

  return (
    <Box flex="1 1 auto">
      {selectedList && (
        <Flex flexDirection="column" height="100%">
          {!isExtraSmall && (
            <Row sx={{ borderBottom: 'lightGray2' }}>
              <Truncate fontSize={4} fontWeight={500}>
                {selectedList.name}
              </Truncate>
            </Row>
          )}
          <Box
            ref={checkboxesContainerRef}
            overflowY="auto"
            sx={{
              borderBottom: containerOverflows || isEmpty(selectedList.suppliers) ? '' : 'lightGray2',
            }}
          >
            <Row>
              <Checkbox
                label={t('general.selectedCompaniesCount', { count: size(newlySelectedCompanyIdsFromList) })}
                indeterminate={!allCompaniesSelected && !isEmpty(selectedCompanyIdsFromList)}
                checked={allCompaniesSelected && !isEmpty(selectedCompanyIdsFromList)}
                onChange={onToggleAll}
                style={{ alignItems: 'center' }}
                CheckboxLabel={ListCheckboxLabel}
              />
            </Row>
            {isEmpty(selectedList.supplierIds) ? (
              <Row sx={{ borderTop: 'lightGray2' }}>
                <Text color="subtext">
                  {t('supplierLists.noSuppliersInList')}
                </Text>
              </Row>
            ) : (
              selectedList.suppliers.map(company => (
                <Row key={company._id} sx={{ borderTop: 'lightGray2' }}>
                  <CompanyCheckbox company={company} disabledCompanyIds={disabledCompanyIds} />
                </Row>
              ))
            )}
          </Box>
        </Flex>
      )}
    </Box>
  );
};

const SupplierListsPanel = ({
  selectedListId,
  setSelectedListId,
}: {
  selectedListId: string | null;
  setSelectedListId: (listId: string | null) => void;
}) => {
  const { t } = useTranslation();
  const { isExtraSmall } = useDeviceSize();
  const panelRef = React.useRef<HTMLDivElement>(null);
  const { supplierLists, supplierListsStatus } = useExtendedSupplierLists();

  const element = panelRef.current;
  const panelOverflows = Boolean(element && element.scrollHeight > element.clientHeight);

  return (
    <Box
      ref={panelRef}
      height="100%"
      overflowY="auto"
      sx={!isExtraSmall
        ? {
          flex: '0 0 auto',
          width: '300px',
          borderRight: 'lightGray2',
        }
        : {}
      }
    >
      {supplierListsStatus === 'loading' ? (
        <Box p={3}>
          <Loading fontSize={2} />
        </Box>
      ) : supplierListsStatus === 'error' ? (
        <Box p={3}>
          <ErrorMessage error={t('supplierLists.errors.getSupplierLists')} fontSize={2} />
        </Box>
      ) : !isEmpty(supplierLists) ? (
        <Box sx={{ borderBottom: !panelOverflows ? 'lightGray2' : '' }}>
          {supplierLists.map(supplierList => (
            <SupplierListItem
              key={supplierList._id}
              supplierList={supplierList}
              isSelected={selectedListId === supplierList._id}
              selectList={setSelectedListId}
            />
          ))}
        </Box>
      ) : (
        <Text color="subtext" fontSize={2}>{t('supplierLists.noLists')}</Text>
      )}
    </Box>
  );
};

type Props =
  { recipientIds: string[]; onCancel: any; onSave: any } &
  ModalProps;

export const AddRecipientFromListModal: React.FC<Props> = ({ recipientIds, onCancel, onSave, ...props }) => {
  const { isExtraSmall } = useDeviceSize();
  const [selectedListId, setSelectedListId] = React.useState<string | null>(null);
  const { t } = useTranslation();
  const { supplierLists, supplierListsStatus } = useExtendedSupplierLists();

  React.useEffect(
    () => {
      if (!isEmpty(supplierLists) && !selectedListId && !isExtraSmall) {
        setSelectedListId(supplierLists[0]._id);
      }
    },
    [supplierLists, supplierListsStatus, selectedListId, isExtraSmall],
  );

  const selectedList = React.useMemo(
    () => selectedListId
      ? supplierLists.find(list => list._id === selectedListId)
      : null,
    [supplierLists, selectedListId],
  );

  return (
    <Modal
      style={{ content: { width: '800px' } }}
      onRequestClose={onCancel}
      {...props}
    >
      <Formik
        initialValues={{
          selectedCompanyIds: recipientIds,
        }}
        validationSchema={
          yup.object().shape({
            selectedCompanyIds: yup.array().of(yup.string()).min(1),
          })
        }
        onSubmit={({ selectedCompanyIds }) => {
          const newlySelectedCompanyIds = difference(selectedCompanyIds, recipientIds);

          onSave(newlySelectedCompanyIds);
          onCancel();
        }}
      >
        {() => (
          <Form>
            <ModalHeader onClose={onCancel}>
              {isExtraSmall && selectedList ? (
                <Flex alignItems="center">
                  <IconButton
                    icon="chevron-left"
                    color="subtext"
                    fontSize={4}
                    onClick={() => setSelectedListId(null)}
                    mr={3}
                    width="16px"
                  />
                  <Truncate>
                    {selectedList.name}
                  </Truncate>
                </Flex>
              ) : (
                t('request.myLists')
              )}
            </ModalHeader>
            <ModalBody p={0}>
              <Flex height="387px" overflow="hidden">
                {!isExtraSmall ? (
                  <>
                    <SupplierListsPanel
                      selectedListId={selectedListId}
                      setSelectedListId={setSelectedListId}
                    />
                    <SelectedListPanel
                      selectedListId={selectedListId}
                      disabledCompanyIds={recipientIds}
                    />
                  </>
                ) : selectedListId ? (
                  <SelectedListPanel
                    selectedListId={selectedListId}
                    disabledCompanyIds={recipientIds}
                  />
                ) : (
                  <SupplierListsPanel
                    selectedListId={selectedListId}
                    setSelectedListId={setSelectedListId}
                  />
                )}
              </Flex>
            </ModalBody>
            <Footer disabledCompanyIds={recipientIds} onCancel={onCancel} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
