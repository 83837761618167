import styled from 'styled-components';
import { Flex } from 'rebass/styled-components';
import { SMALL_PAGE_HEADER_HEIGHT, LARGE_PAGE_HEADER_HEIGHT } from '../constants';

export const HeadingRow = styled<any>(Flex)`
  height: ${({ small }) => small ? SMALL_PAGE_HEADER_HEIGHT : LARGE_PAGE_HEADER_HEIGHT}px;
  align-items: center;
  justify-content: space-between;
  padding: 16px 1px;
`;
