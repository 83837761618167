import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useApprovalsContext } from '../contract';
import { Switch } from '../../../ui/Switch';

import { ApprovalBox } from './ApprovalBox';

export const ApprovalBlockedDirty = () => {
  const { t } = useTranslation('contracts');
  // @ts-expect-error ts(2339) FIXME: Property 'toggleDirtyExchanges' does not exist on type '{ onlyDirtyExchanges: boolean; toggleDirtyExchanges: () => void; removeFilter: () => void; } | null'.
  const { toggleDirtyExchanges, onlyDirtyExchanges } = useApprovalsContext();

  return (
    <ApprovalBox
      heading={(
        <>
          <Icon icon="times" color="danger" mr={2} />
          {t('approval.blockedDirty.heading')}
        </>
      )}
      description={(
        <Flex alignItems="center">
          <Text mr={2}>
            {t('approval.blockedDirty.description')}
          </Text>
          <Switch
            text={t('approval.review.changesLabel')}
            checked={onlyDirtyExchanges}
            onChange={toggleDirtyExchanges}
          />
        </Flex>
      )}
    />
  );
};
