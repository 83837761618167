import { useMemo } from 'react';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';

export const useStageApprovalLabelStyle = () => {
  const theme = useTheme();

  return useMemo(() => {
    return {
      color: theme.colors.subtext,
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 500,
    };
  }, [theme]);
};
