import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { ApprovalBox } from './ApprovalBox';
import { AcceptApprovalButton } from './AcceptApprovalButton';

export const ApprovalReady = () => {
  const { t } = useTranslation('contracts');

  return (
    <ApprovalBox
      heading={(
        <>
          <Icon icon="spinner" spin mr={2} />
          {t('approval.ready.heading')}
        </>
      )}
      description={t('approval.ready.description')}
      action={<AcceptApprovalButton label={t('approval.ready.action')} />}
    />
  );
};
