import { useTranslation } from 'react-i18next';
import { forwardRef, useRef, useMemo } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { mapValues, pickBy } from 'lodash';
import { canAddFieldToFormula, LineItemExchangeFields } from '@deepstream/common/rfq-utils';
import { Box } from 'rebass/styled-components';
import { useField } from 'formik';
import ReactDOM from 'react-dom';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { FieldContainer } from './FieldContainer';
import { useGetFormattedFieldLabel } from '../ui/ExchangeDefsGrid/useFormattedTotalCostFormula';

const styles = {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
  },

  '&multiLine': {
    flex: 1,
    control: {
      fontFamily: 'monospace',
      minHeight: 63,
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
    },
    input: {
      padding: 9,
      border: '1px solid silver',
    },
  },

  '&singleLine': {
    display: 'inline-block',
    flex: 1,

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};

const mentionStyle = {
  backgroundColor: '#cee4e5',
};

type FormulaFieldBaseProps = {
  fieldId?: string;
  hideLabel?: boolean;
  error?: string;
  label?: string;
  id?: string;
  description?: string;
  name?: string;
  required?: boolean;
  fields?: LineItemExchangeFields;
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
};

const FormulaPortal = forwardRef<HTMLDivElement>((_, ref) => {
  return ReactDOM.createPortal(
    <Box sx={{ position: 'relative', zIndex: 999 }} ref={ref} />,
  document.body,
  );
});

// We need to transform the formula from the version we save it
// as: {field} to the version properly supported by react-motions
// @field@
const transformSavedFormula = (formula: string) => {
  return formula.replace(/\{([^{} ]+)\}/g, '@$1@');
};

const transformToSavedFormula = (formula: string) => {
  return formula.replace(/@([^@ ]+)@/g, '{$1}');
};

export const FormulaFieldBase = ({ hideLabel, id, fieldId, label, description, name, required, fields, value: formula = '', onChange, onBlur, error }: FormulaFieldBaseProps) => {
  const { t } = useTranslation();
  const getFormattedFieldLabel = useGetFormattedFieldLabel();
  const ref = useRef<HTMLDivElement>(null);

  const {
    fieldLabelById,
    data,
  } = useMemo(() => {
    const fieldLabelById = mapValues(
      pickBy(fields, field => canAddFieldToFormula(field) && field._id !== fieldId),
      field => getFormattedFieldLabel(field),
    );

    return {
      fieldLabelById,
      data: Object.entries(fieldLabelById).map(([fieldId, label]) => ({
        id: fieldId,
        display: label,
      })),
    };
  }, [fields, getFormattedFieldLabel, fieldId]);

  return (
    <>
      <FormulaPortal ref={ref} />
      <FieldContainer
        name={name}
        htmlFor={id}
        label={label}
        hideLabel={hideLabel}
        showAsterisk={required}
        width="100%"
        description={description}
      >
        <MentionsInput
          name={name}
          value={transformSavedFormula(formula)}
          onChange={(event) => onChange?.(transformToSavedFormula(event.target.value))}
          onBlur={onBlur}
          placeholder={t('request.lineItems.formula.editModal.inputPlaceholder')}
          a11ySuggestionsListLabel={t('request.lineItems.formula.editModal.suggestionsListLabel')}
          style={styles}
          // @ts-expect-error ts(2322) FIXME: Type 'HTMLDivElement | null' is not assignable to type 'Element | undefined'.
          suggestionsPortalHost={ref.current}
        >
          {/* Markup and trigger cannot use the same character */}
          <Mention
            markup="@__id__@"
            trigger="{"
            // @ts-expect-error ts(2322) FIXME: Type '{ id: string; display: DefaultTFuncReturn; }[]' is not assignable to type 'SuggestionDataItem[] | DataFunc'.
            data={data}
            renderSuggestion={(suggestion, search, highlightedDisplay) => (
              <div className="user">{highlightedDisplay}</div>
            )}
            displayTransform={(id) => {
              const label = fieldLabelById[id] || `[${t('request.lineItems.invalidField')}]`;
              return `{${label}}`;
            }}
            style={mentionStyle}
          />
        </MentionsInput>
        {error && (
          <Box color="danger" fontSize={1} fontWeight={400} mt={1}>
            <IconText
              icon="exclamation-circle"
              color="danger"
              fontSize={1}
              text={error}
            />
          </Box>
        )}
      </FieldContainer>
    </>
  );
};

export const FormulaField = (props: FormulaFieldBaseProps) => {
  // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
  const [field, meta, formik] = useField({ name: props.name });
  const { value } = field;

  return (
    <FormulaFieldBase
      error={meta.touched && meta.error ? meta.error : undefined}
      value={value}
      onChange={(value) => formik.setValue(value)}
      onBlur={() => formik.setTouched(true)}
      {...props}
    />
  );
};
