import { createContext, useContext } from 'react';
import { useQueryClient } from 'react-query';
import { Action, RfqId, CompanyId, ExchangeId, SectionId, UserMinimized } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { callAll } from '@deepstream/utils/callAll';
import { useApi } from '../api';
import { useCurrentCompanyId } from '../currentCompanyId';
import {
  useRfqExchange,
  doesExchangesQueryMatchSectionId,
  useLiveRfqStructureQueryKey,
} from '../useRfq';
import { useMutation } from '../useMutation';
import * as rfx from '../rfx';
import { useToaster } from '../toast';
import { ExchangeSnapshot } from '../types';

export type SendExchangeReplyPayload = Omit<Action, 'companyId' | 'user' | 'date'> & {
  companyId?: string;
  user?: UserMinimized;
  date?: Date;
};

export const useSendExchangeReply = ({
  rfqId,
  exchangeId,
  recipientId,
  sectionId,
}: {
  rfqId: RfqId;
  exchangeId: ExchangeId;
  recipientId?: CompanyId;
  sectionId?: SectionId;
}) => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const api = useApi();
  const queryClient = useQueryClient();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const senders = rfx.useSenders();
  const refetchExchange = rfx.useExchangeRefetch();

  const { queryKey: rfqExchangeQueryKey } = useRfqExchange({
    exchangeId,
    recipientId,
    enabled: false,
    onSuccess: (exchange) => queryClient
      .getQueryCache()
      .findAll({
        predicate: doesExchangesQueryMatchSectionId({ rfqId, recipientId, sectionId: exchange.def.sectionId }),
      })
      .forEach((x) => {
        const { queryKey, state } = x;

        if (state?.data) {
          // @ts-expect-error ts(2345) FIXME: Argument of type '(data: ExchangeSnapshot[]) => ExchangeSnapshot[]' is not assignable to parameter of type 'Updater<ExchangeSnapshot[] | undefined, ExchangeSnapshot[]>'.
          queryClient.setQueryData(queryKey, (data: ExchangeSnapshot[]) => {
            const exchangeIndex = data.findIndex(item => item._id === exchange._id);

            if (exchangeIndex === -1) {
              return data;
            }

            const clonedData = [...data];

            clonedData.splice(exchangeIndex, 1, cloneDeep(exchange));

            return clonedData;
          });
        }
      }),
  });

  const rfqStructureQueryKey = useLiveRfqStructureQueryKey({
    currentCompanyId,
    rfqId,
    recipientId,
  });
  const liveStructureQueryKey = useLiveRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
  });

  return useMutation(
    async (action: SendExchangeReplyPayload) => {
      const payload = {
        rfqId,
        exchangeId,
        senderId: senders[0]._id,
        recipientId,
        currentCompanyId,
        action: {
          ...action,
          companyId: currentCompanyId,
        },
      };
      await api.sendExchangeReply(payload);

      if (refetchExchange) {
        // waiting for `refetchExchange()` ensures that the mutation promise is
        // only resolved after the exchange data has been updated; this allows
        // forms to set `isSubmitting` to `false` *after* the updated data has
        // arrived
        await refetchExchange();
      }
    },
    {
      mutationKey: ['sendRfxExchangeReply'],
      onSettled: callAll(
        () => queryClient.invalidateQueries(rfqStructureQueryKey),
        refetchExchange ? undefined : () => queryClient.invalidateQueries(rfqExchangeQueryKey),
        () => {
          if (currentCompanyId !== recipientId) {
            queryClient.invalidateQueries(liveStructureQueryKey);
            queryClient.invalidateQueries(['allExchanges', { rfqId, currentCompanyId }]);
            queryClient.invalidateQueries(['statsByRecipientId', { rfqId, currentCompanyId }]);
          }
        },
      ),
      onSuccess: () => toaster.success(t('request.toaster.actionSubmittedSuccess')),
      onError: () => toaster.error(t('request.toaster.actionSubmittedError')),
    },
  );
};

// @ts-expect-error ts(2345) FIXME: Argument of type 'null' is not assignable to parameter of type 'readonly [UseMutateAsyncFunction<void, any, SendExchangeReplyPayload, any>, { data: undefined; error: null; isError: false; isIdle: true; ... 8 more ...; variables: SendExchangeReplyPayload | undefined; } | { ...; } | { ...; } | { ...; }]'.
export const SendExchangeReply = createContext<ReturnType<typeof useSendExchangeReply>>(null);

export const useSendExchangeReplyContext = () => {
  const sendExchangeReply = useContext(SendExchangeReply);
  if (!sendExchangeReply) throw new Error('sendExchangeReply is falsy');
  return sendExchangeReply;
};
