import NumberFormat from 'react-number-format';
import * as Sentry from '@sentry/react';
import { Box, Text, Flex } from 'rebass/styled-components';
import { Draft, QuestionExchangeDefinition, QuestionType, ShortTextQuestionExchangeDefinition, ShortTextSchema, ShortTextSchemaType } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { UnexpectedStateError } from '@deepstream/errors';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { DisabledInputBox } from '../../../ui/Input';
import { CheckboxFieldBase } from '../../../form/CheckboxField';
import { FieldContainer } from '../../../form/FieldContainer';
import { formatOptions } from '../../../ui/formatOptions';

const DisabledResponseValidationFields = ({
  schema,
}: {
  schema: ShortTextSchema<Draft>;
}) => {
  const { t } = useTranslation();

  const format = schema.type === ShortTextSchemaType.INTEGER ? 'integer' : 'number';

  return (
    <FieldContainer label={t('request.question.responseValidation')}>
      <Flex flexDirection={['column', 'row']}>
        <Box
          mr={[0, 3]}
          mb={[2, 0]}
          flex={1}
          minWidth="100px"
          maxWidth={['100%', '150px']}
        >
          <DisabledInputBox>
            {t(`request.question.schemaType.${schema.type}`)}
          </DisabledInputBox>
        </Box>
        <Box flexShrink={0} mr={[0, 3]} mb={[2, 0]} sx={{ lineHeight: '40px' }}>
          <Text fontSize={2}>
            {t('request.question.between')}
          </Text>
        </Box>
        <Box maxWidth={['100%', '120px']} mr={[0, 3]} mb={[2, 0]}>
          <DisabledInputBox>
            <NumberFormat
              displayType="text"
              // @ts-expect-error ts(2783) FIXME: 'thousandSeparator' is specified more than once, so this usage will be overwritten.
              thousandSeparator
              value={schema.min}
              {...formatOptions[format]}
            />
          </DisabledInputBox>
        </Box>
        <Box flexShrink={0} mr={[0, 3]} mb={[2, 0]}>
          <Text fontSize={2} sx={{ lineHeight: '40px' }}>
            {t('general.and')}
          </Text>
        </Box>
        <Box maxWidth={['100%', '120px']}>
          <DisabledInputBox>
            <NumberFormat
              displayType="text"
              // @ts-expect-error ts(2783) FIXME: 'thousandSeparator' is specified more than once, so this usage will be overwritten.
              thousandSeparator
              value={schema.max}
              {...formatOptions[format]}
            />
          </DisabledInputBox>
        </Box>
      </Flex>
    </FieldContainer>
  );
};

export const DisabledShortTextField = ({
  exchangeDef,
}: {
  exchangeDef: QuestionExchangeDefinition
}) => {
  const { t } = useTranslation();
  const { questionType, schema } = exchangeDef as ShortTextQuestionExchangeDefinition;

  if (questionType !== QuestionType.SHORT_TEXT) {
    Sentry.captureException(new UnexpectedStateError('[ShortTextField] Wrong question type passed', { questionType }));
    return null;
  }

  return (
    <Stack gap={3}>
      <Flex flexDirection="row" alignItems="center">
        <Box flex={1} mr="24px" maxWidth="500px">
          <DisabledInputBox height="40px">
            {t('request.question.questionType.shortText')}
          </DisabledInputBox>
        </Box>
        <Box flex={1}>
          <CheckboxFieldBase
            value={!!schema}
            fieldLabel={t('request.question.allowANumberOnly')}
            disabled
          />
        </Box>
      </Flex>
      {schema && (
        <DisabledResponseValidationFields schema={schema} />
      )}
    </Stack>
  );
};
