import * as Layout from '../../Layout';
import { ContractDraftHeader } from './ContractDraftHeader';
import { ContractDraftTeam } from './ContractDraftTeam';
import { useContractId } from './contract';

export const ContractDraftTeamPageContent = () => {
  const tabId = 'team';
  const contractId = useContractId();

  return (
    <Layout.HeaderAndContent
      header={
        <ContractDraftHeader
          contractId={contractId}
          selectedTabId={tabId}
        />
      }
      content={
        <ContractDraftTeam
          contractId={contractId}
        />
      }
    />
  );
};
