import { useState, useMemo } from 'react';
import { filter, find } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Form, Formik, useField, useFormikContext } from 'formik';
import * as yup from 'yup';
import { Flex } from 'rebass/styled-components';
import {
  CurrencyExchangeDefinition,
  LineItemExchangeDefinition,
  isLineItemExchangeDef,
  getExchangeDefFieldValue,
  setExchangeDefFieldValue,
  hasSupplierPriceField,
  getFieldIdsInDefaultDisplayOrder,
} from '@deepstream/common/rfq-utils';
import { ContractSection, isCurrencyExchangeDef } from '@deepstream/common/contract/contract';
import { SaveButton, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { ExpandablePanelSubSection, PanelDivider, PanelPadding, PanelSubHeader } from '@deepstream/ui-kit/elements/Panel';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { EditableGridDataProvider, useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { useCurrencySelectItems } from '../../ui/currencies';
import { SelectField, SelectFieldBase, getItemValue } from '../../form/SelectField';
import { ContractDraftPanel } from './ContractDraftPanel';
import { usePreventEnterKeyHandler } from '../../usePreventEnterKeyHandler';
import { useSaveLineItemsSection, useGetOtherSectionFieldLabelChangeCommands } from './draftContract';
import { useContractActions, useContractData, useContractSection, useIsSender, useOtherSectionLineItemFields, useSectionExchangeDefs } from './contract';
import { LineItemExchangeDefsGrid } from '../../ui/ExchangeDefsGrid/LineItemExchangeDefsGrid';
import { AddBuyerProvidedLineItemButton } from '../../draft/AddLineItemButton';
import { GlobalDatePickerStyles } from '../../ui/DatePicker';
import { useSystemFeatureFlags } from '../../systemFeatureFlags';
import { CurrencyCodeProvider } from '../../ui/Currency';
import { AddLineItemFieldDropdown } from '../../draft/AddLineItemFieldDropdown';
import { MoreActionsLineItemDropdown } from '../../draft/MoreActionsLineItemDropdown';
import { ExpandViewButton } from '../Request/Comparison/ExpandViewButton';
import { ModelSizeLimitDialog, ModelSizeLimitMessages } from '../../ModelSizeLimitDialog';
import { getSizeRelevantExchangeDefCount, useModelSizeLimits } from '../../modelSizeLimits';
import { useModalState } from '../../ui/useModalState';
import { SectionConfigHeading } from '../../draft/SectionConfigHeading';
import { LineItemsSectionConfigIndicators } from '../../draft/LineItemsSectionConfigIndicators';
import { DraftSectionLabelConfigProvider } from '../../draft/DraftSectionLabelConfigProvider';
import { DraftSectionEditPanelHeader } from '../../draft/DraftSectionEditPanelHeader';
import { LeavePageModal } from '../../draft/LeavePageModal';
import { MultiSelectField } from '../../form/MultiSelectField';

const EditSectionConfig = () => {
  const { t } = useTranslation('translation');
  const currencySelectItems = useCurrencySelectItems();
  const { updateEachRowWith, rowData } = useEditableGridData<LineItemExchangeDefinition>();
  const showSelectBuyerCurrency = Boolean(rowData[0]?.fields.evaluatorFieldCurrency);
  const [{ value: currencyExchangeDef }] = useField({ name: 'currencyExchangeDef' });

  const modelExchangeDef = rowData[0];

  return (
    <ExpandablePanelSubSection
      heading={<SectionConfigHeading />}
      renderCollapsedContent={() => (
        <LineItemsSectionConfigIndicators
          isContract
          isSender
          modelExchangeDef={modelExchangeDef}
          currencyExchangeDef={currencyExchangeDef}
        />
      )}
      renderExpandedContent={() => (
        <DraftSectionLabelConfigProvider>
          {hasSupplierPriceField(modelExchangeDef?.fields) && (
            <PanelPadding>
              <MultiSelectField
                name="currencyExchangeDef.currencies"
                label={t('request.lineItems.currency.supplierCurrency')}
                description={t('request.lineItems.currency.supplierCurrenciesDescription')}
                required
                variant="secondary-outline"
                buttonWidth={200}
                menuWidth={270}
                items={currencySelectItems}
                itemToString={getItemValue}
                placeholder={t('request.lineItems.currency.select')}
                getButtonText={(items) => items.length === 1 ? (
                  items[0].label
                ) : items.length > 1 ? (
                  items.map(item => item.value).join(', ')
                ) : null
                }
                alwaysShowCaret
              />
            </PanelPadding>
          )}
          {showSelectBuyerCurrency ? (
            <>
              <PanelDivider />
              <PanelPadding>
                <SelectFieldBase
                  label={t('request.lineItems.currency.buyerCurrency')}
                  description={t('request.lineItems.currency.buyerCurrencyDescription')}
                  items={currencySelectItems}
                  // @ts-expect-error ts(2345) FIXME: Argument of type 'string' is not assignable to parameter of type 'never'.
                  value={getExchangeDefFieldValue(rowData[0], 'evaluatorFieldCurrency')}
                  placeholder={t('request.lineItems.currency.select')}
                  menuWidth={270}
                  required
                  onChange={currency => {
                    updateEachRowWith(exchangeDef =>
                      setExchangeDefFieldValue(exchangeDef, 'evaluatorFieldCurrency', currency || null),
                    );
                  }}
                  buttonStyle={{ fontWeight: 500, maxWidth: 200 }}
                />
              </PanelPadding>
              <PanelDivider />
            </>
          ) : (
            null
          )}
        </DraftSectionLabelConfigProvider>
      )}
    />
  );
};

const EditPanelFooter = () => {
  const {
    isSubmitting,
    isValid,
    dirty: isFormikDataDirty,
  } = useFormikContext();
  const { stopEditing } = useContractActions();
  const {
    isDirty: isGridDataDirty,
    editedCell,
  } = useEditableGridData<LineItemExchangeDefinition>();

  const isDirty = isGridDataDirty || isFormikDataDirty;
  const isEditingCell = Boolean(editedCell);

  return (
    <PanelPadding>
      <Flex justifyContent="flex-end">
        <CancelButton onClick={stopEditing} mr={2} />
        <SaveButton disabled={isEditingCell || isSubmitting || !isDirty || !isValid} />
      </Flex>
    </PanelPadding>
  );
};

export const EditPanelContent = () => {
  const { t } = useTranslation(['contracts', 'translation']);
  const [isExpandedView, setIsExpandedView] = useState(false);
  const systemFeatureFlags = useSystemFeatureFlags({ required: true });
  const { stopEditing } = useContractActions();
  const { exchangeDefById } = useContractData();
  const section = useContractSection();
  const isSender = useIsSender();
  const exchangeDefs = useSectionExchangeDefs();
  const [saveSection] = useSaveLineItemsSection();
  const onKeyDown = usePreventEnterKeyHandler();
  const {
    rowData: lineItemExchangeDefs,
  } = useEditableGridData<LineItemExchangeDefinition>();
  const { maxExchangeDefCount } = useModelSizeLimits();
  const modelSizeLimitModal = useModalState();
  const [modelSizeLimitMessages, setModelSizeLimitMessages] = useState<ModelSizeLimitMessages | null>(null);

  const currencyExchangeDef = find(exchangeDefs, isCurrencyExchangeDef);
  const getOtherSectionFieldLabelChangeCommands = useGetOtherSectionFieldLabelChangeCommands();
  const otherSectionFields = useOtherSectionLineItemFields();

  const handleSubmit = async ({ section, currencyExchangeDef }, { setSubmitting }) => {
    const totalExchangeDefCount = getSizeRelevantExchangeDefCount(exchangeDefById);
    const previousSectionExchangeDefCount = getSizeRelevantExchangeDefCount(exchangeDefs);
    const newSectionExchangeDefCount = getSizeRelevantExchangeDefCount(lineItemExchangeDefs);
    const addedCount = newSectionExchangeDefCount - previousSectionExchangeDefCount;

    if (
      newSectionExchangeDefCount > previousSectionExchangeDefCount &&
      totalExchangeDefCount + addedCount > maxExchangeDefCount
    ) {
      setModelSizeLimitMessages({
        heading: t('dialog.contractSizeLimit.heading'),
        title: t('dialog.contractSizeLimit.saveLineItemsSection.title'),
        warning: t('dialog.contractSizeLimit.saveLineItemsSection.warning'),
        body: t('dialog.contractSizeLimit.saveLineItemsSection.body', {
          count: totalExchangeDefCount + addedCount - maxExchangeDefCount,
        }),
      });
      modelSizeLimitModal.open();
      setSubmitting(false);
    } else {
      await saveSection({
        section,
        exchangeDefs: [
          currencyExchangeDef,
          ...lineItemExchangeDefs,
        ],
        extraCommands: getOtherSectionFieldLabelChangeCommands(section._id, lineItemExchangeDefs[0]?.fields),
      }, {
        onSuccess: stopEditing,
      });
    }
  };

  return (
    <>
      <Flex
        flexDirection="column"
        sx={isExpandedView ? ({
          padding: '20px',
          inset: 0,
          zIndex: 150,
          position: 'fixed',
          backgroundColor: 'lightGray3',
        }) : ({
          height: '100%',
        })}
      >
        <Formik<{
          section: ContractSection;
          currencyExchangeDef: CurrencyExchangeDefinition;
        }>
          validateOnBlur
          enableReinitialize
          initialValues={{
            section,
            // @ts-expect-error ts(2322) FIXME: Type 'CurrencyExchangeDefinition | undefined' is not assignable to type 'CurrencyExchangeDefinition'.
            currencyExchangeDef,
          }}
          validationSchema={
            yup.object().shape({
              section: yup.object().shape({
                name: yup.string(),
                description: yup.string(),
              }),
            })
          }
          onSubmit={handleSubmit}
        >
          {() => (
            <Form style={{ width: '100%' }} onKeyDown={onKeyDown}>
              {/*
                // @ts-expect-error ts(2322) FIXME: Type 'string | null | undefined' is not assignable to type 'string'. */}
              <CurrencyCodeProvider code={lineItemExchangeDefs[0]?.evaluatorFieldCurrency}>
                <ContractDraftPanel panelId={section._id} suppressBoxShadow={isExpandedView}>
                  {!isExpandedView && (
                    <>
                      <DraftSectionEditPanelHeader icon="list-ul" />
                      <PanelDivider />
                      <EditSectionConfig />
                      <PanelDivider />
                    </>
                  )}
                  <PanelSubHeader height="52px">
                    <ExpandViewButton
                      isExpandedView={isExpandedView}
                      setIsExpandedView={setIsExpandedView}
                      shrinkedVariant="secondary-transparent-outline"
                      type="button"
                      mr={3}
                    />
                    <MoreActionsLineItemDropdown
                      modelExchangeDef={lineItemExchangeDefs[0]}
                      isContract
                    />
                    {systemFeatureFlags?.lineItemFieldsEnabled ? (
                      <AddLineItemFieldDropdown
                        otherSectionFields={otherSectionFields}
                      />
                    ) : (
                      null
                    )}
                    <AddBuyerProvidedLineItemButton modelExchangeDef={lineItemExchangeDefs[0]} ml={2} />
                  </PanelSubHeader>
                  <PanelDivider />
                  <PanelPadding>
                    <LineItemExchangeDefsGrid
                      viewportHeightDelta={400}
                      height={isExpandedView ? 'calc(100vh - 216px)' : undefined}
                      otherSectionFields={otherSectionFields}
                      isSender={isSender}
                    />
                  </PanelPadding>
                  <PanelDivider />
                  <EditPanelFooter />
                  <LeavePageModal />
                </ContractDraftPanel>
              </CurrencyCodeProvider>
            </Form>
          )}
        </Formik>
      </Flex>
      <ModelSizeLimitDialog
        modal={modelSizeLimitModal}
        messages={modelSizeLimitMessages}
      />
    </>
  );
};

export const ContractLineItemsSectionEditPanel = () => {
  const exchangeDefs = useSectionExchangeDefs();
  const lineItemExchangeDefs = useMemo(
    () => filter(exchangeDefs, isLineItemExchangeDef),
    [exchangeDefs],
  );

  return (
    <EditableGridDataProvider
      rowData={lineItemExchangeDefs}
      setValueInRow={setExchangeDefFieldValue}
    >
      <GlobalDatePickerStyles />
      <GridIdPrefixProvider>
        <GridMenuStateProvider>
          <EditPanelContent />
        </GridMenuStateProvider>
      </GridIdPrefixProvider>
    </EditableGridDataProvider>
  );
};
