import * as React from 'react';
import { Approval } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { FieldContainer } from '../../form/FieldContainer';
import { LabelConfig, LabelConfigProvider } from '../../LabelConfigProvider';
import { useStageApprovalLabelStyle } from './useStageApprovalLabelStyle';
import { StatusIconText } from '../../ExchangeStatusCell';
import { useLabeledApprovalResponseConfig } from '../../status';
import { Approvers } from './Approvers';
import { useDatetime } from '../../useDate';

export type ApprovalResponseProps = {
  approval: Approval;
  handleEditResponse?: () => void;
  canEdit?: boolean;
};

export const ApprovalResponseDetails: React.FC<ApprovalResponseProps> = ({
  approval,
  canEdit = false,
  handleEditResponse,
}) => {
  const { t } = useTranslation('translation');
  const labelStyle = useStageApprovalLabelStyle();
  const approvalResponseStatusConfig = useLabeledApprovalResponseConfig();

  const { userId, status, message, date } = approval;
  // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string | Date'.
  const datetime = useDatetime(date);

  return (
    <LabelConfigProvider
      variant={LabelConfig.LEFT}
      width="180px"
      style={{
        approval: labelStyle,
        response: labelStyle,
        comments: labelStyle,
        submitted: labelStyle,
      }}
    >
      <Flex
        flexDirection="column"
        sx={{
          rowGap: '16px',
        }}
      >
        <FieldContainer
          name="approval"
          label={t('request.stageApprovals.approver', { count: 1 })}
        >
          <Approvers approverIds={[userId]} />
        </FieldContainer>

        <FieldContainer
          name="response"
          label={t('response', { ns: 'general' })}
        >
          <Flex sx={{ columnGap: '16px' }}>
            <StatusIconText
              label={approvalResponseStatusConfig[status].label}
              icon={approvalResponseStatusConfig[status].icon}
            />
            {canEdit && (
              <Button
                variant="secondary-outline"
                small
                iconLeft="pencil"
                onClick={handleEditResponse}
              >
                {t('general.edit')}
              </Button>
            )}
          </Flex>
        </FieldContainer>

        <FieldContainer
          name="comments"
          label={t('comment_other', { ns: 'general' })}
        >
          <Text>
            {message || <EmDash />}
          </Text>
        </FieldContainer>

        {date && (
          <FieldContainer
            name="submitted"
            label={t('request.stageApprovals.stageApprovalResponseSubmitted')}
          >
            <Text>{datetime}</Text>
          </FieldContainer>
        )}
      </Flex>
    </LabelConfigProvider>
  );
};
