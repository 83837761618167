import * as Layout from '../../../Layout';
import { Header } from './Header';
import { QuestionnaireAudit } from './QuestionnaireAudit';
import { QuestionnairePageTab } from './utils';

export const QuestionnaireAuditPageContent = () => {
  const tabId = QuestionnairePageTab.AUDIT;

  return (
    <Layout.HeaderAndContent
      header={
        <Header selectedTabId={tabId} />
      }
      content={
        <QuestionnaireAudit />
      }
    />
  );
};
