import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box } from 'rebass/styled-components';
import { compact, isUndefined, map, omitBy, values } from 'lodash';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { TimeUnit } from '@deepstream/common/rfq-utils';
import { withProps } from '@deepstream/ui-utils/withProps';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { LeavePageModal } from '../../../draft/LeavePageModal';
import { TextField } from '../../../form/TextField';
import { PropertyList } from '../../../PropertyList';
import { LABEL_WIDTH } from '../../../draft/SummaryLabelConfigProvider';
import { Validation } from '../../../draft/validation';
import { ValidationPropertyRow } from '../../../draft/ValidationPropertyRow';
import { ValidationErrorValue } from '../../../draft/ValidationErrorValue';
import { nestValues } from '../../../nestValues';
import { useQuestionnaireTemplateActions, useQuestionnaireTemplateData, useQuestionnaireTemplateState } from './questionnaireTemplateUtils';
import { SummaryPanelHeader } from './SummaryPanelHeader';
import { useShowValidationErrors, useUpdateExpiryAndRenewalConfig } from './draftQuestionnaireTemplate';
import { DraftPanel } from './DraftPanel';
import { FieldContainer } from '../../../form/FieldContainer';
import { LabelConfig, LabelConfigProvider } from '../../../LabelConfigProvider';
import { SelectField } from '../../../form/SelectField';
import { ExpiryAndRenewalConfiguration } from '../ExpiryAndRenewalConfiguration';

const PANEL_ID = 'expirationAndRenewalConfig';

const ConfigType = {
  NONE: 'none',
  EXPIRY: 'expiry',
  RENEWAL: 'renewal',
};

export const ExpiryAndRenewalPanel = () => {
  const { t } = useTranslation(['preQualification', 'general']);
  const { stopEditing } = useQuestionnaireTemplateActions();
  // @ts-expect-error ts(2339) FIXME: Property 'editingPanelId' does not exist on type 'QuestionnaireTemplateStateContextType | undefined'.
  const { editingPanelId, isLive } = useQuestionnaireTemplateState();
  const template = useQuestionnaireTemplateData();
  const [updateExpiryAndRenewalConfig] = useUpdateExpiryAndRenewalConfig();
  const showValidationErrors = useShowValidationErrors();

  const isEditingThisPanel = editingPanelId && editingPanelId === PANEL_ID;

  const properties = useMemo(() => compact([
    {
      fieldName: 'configuration',
      name: t('questionnaireRenewal.configuration'),
      value: template,
      Component: nestValues(
        withProps(ValidationErrorValue, { useShowValidationErrors }),
        ExpiryAndRenewalConfiguration,
      ),
    },
  ]), [t, template]);

  const initialValues = useMemo(
    () => {
      const configType = template.renewalConfig.isRecurring
        ? ConfigType.RENEWAL
        : template.expiryConfig.doesExpire
          ? ConfigType.EXPIRY
          : ConfigType.NONE;

      return {
        configType,
        frequency: template.renewalConfig.frequency,
        offset: template.expiryConfig.offset,
      };
    },
    [template],
  );

  const heading = t('questionnaireRenewal.expirationAndRenewal');

  const unitItems = useMemo(
    () => [
      { value: TimeUnit.DAYS, label: t('timeUnit.days', { ns: 'general' }) },
      { value: TimeUnit.MONTHS, label: t('timeUnit.months', { ns: 'general' }) },
      { value: TimeUnit.YEARS, label: t('timeUnit.years', { ns: 'general' }) },
    ],
    [t],
  );

  const configTypeItems = useMemo(
    () => map(
      [ConfigType.NONE, ConfigType.RENEWAL, ConfigType.EXPIRY],
      configType => ({
        value: configType,
        label: t(`questionnaireRenewal.configType.${configType}`),
      }),
    ),
    [t],
  );

  return (
    <DraftPanel panelId={PANEL_ID} heading={heading}>
      <SummaryPanelHeader heading={heading} panelId={PANEL_ID} canEdit={!isLive} />
      <PanelDivider />
      {isEditingThisPanel ? (
        <Formik
          validateOnBlur
          enableReinitialize
          initialValues={initialValues}
          validationSchema={
            yup.object().shape({
              configType: yup.string().oneOf(values(ConfigType)).required(),
              frequency: yup.object().when(
                'configType',
                {
                  is: ConfigType.RENEWAL,
                  then: yup.object().shape({
                    unit: yup.string().required(),
                    amount: yup.number().required().min(1),
                  }),
                  otherwise: yup.object().shape({
                    unit: yup.string().nullable(),
                    amount: yup.number().nullable(),
                  }),
                },
              ),
              offset: yup.object().when(
                'configType',
                {
                  is: ConfigType.EXPIRY,
                  then: yup.object().shape({
                    unit: yup.string().required(),
                    amount: yup.number().required().min(1),
                  }),
                  otherwise: yup.object().shape({
                    unit: yup.string().nullable(),
                    amount: yup.number().nullable(),
                  }),
                },
              ),
            })
          }
          onSubmit={async (values) => {
            const isRecurring = values.configType === ConfigType.RENEWAL;
            const doesExpire = values.configType === ConfigType.EXPIRY;

            const newRenewalConfig = omitBy({
              isRecurring,
              frequency: isRecurring ? values.frequency : undefined,
            }, isUndefined);

            const newExpiryConfig = omitBy({
              doesExpire,
              offset: doesExpire ? values.offset : undefined,
            }, isUndefined);

            await updateExpiryAndRenewalConfig(
              {
                renewalConfig: newRenewalConfig,
                expiryConfig: newExpiryConfig,
              },
              { onSuccess: () => stopEditing() },
            );
          }}
        >
          {({ isSubmitting, dirty, isValid, values, resetForm }) => (
            <Form>
              <LabelConfigProvider
                variant={LabelConfig.LEFT}
                width={LABEL_WIDTH}
                gap={40}
              >
                <Stack gap={20} m={20}>
                  <Box width="220px">
                    <SelectField
                      name="configType"
                      items={configTypeItems}
                    />
                  </Box>
                  {values.configType === ConfigType.RENEWAL && (
                    <FieldContainer
                      name="frequency"
                      label={t('questionnaireRenewal.frequency')}
                      description={t('questionnaireRenewal.frequencyDescription')}
                      showAsterisk
                      labelStyle={{ fontSize: 2, fontWeight: 500 }}
                      sx={{ alignItems: 'flex-end' }}
                    >
                      <Flex sx={{ gap: 2 }}>
                        <Box width="100px">
                          <SelectField name="frequency.unit" items={unitItems} />
                        </Box>
                        <Box width="70px">
                          <TextField
                            name="frequency.amount"
                            inputType="number"
                            format="integer.positive"
                            hideError
                          />
                        </Box>
                      </Flex>
                    </FieldContainer>
                  )}
                  {values.configType === ConfigType.EXPIRY && (
                    <FieldContainer
                      name="offset"
                      label={t('questionnaireRenewal.expiry')}
                      description={t('questionnaireRenewal.expiryDescription')}
                      showAsterisk
                      labelStyle={{ fontSize: 2, fontWeight: 500 }}
                      sx={{ alignItems: 'flex-end' }}
                    >
                      <Flex sx={{ gap: 2 }}>
                        <Box width="100px">
                          <SelectField name="offset.unit" items={unitItems} />
                        </Box>
                        <Box width="70px">
                          <TextField
                            name="offset.amount"
                            inputType="number"
                            format="integer.positive"
                            hideError
                          />
                        </Box>
                      </Flex>
                    </FieldContainer>
                  )}
                </Stack>
              </LabelConfigProvider>
              <PanelDivider />
              <PanelPadding>
                <Flex justifyContent="flex-end">
                  <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
                  <SaveButton disabled={isSubmitting || !dirty || !isValid} />
                </Flex>
              </PanelPadding>
              <LeavePageModal />
            </Form>
          )}
        </Formik>
      ) : (
        <Validation
          values={initialValues}
          schema={showValidationErrors ? (
            yup.object().shape({
              configType: yup.string().oneOf(values(ConfigType)).required(),
              frequency: yup.object().when(
                'configType',
                {
                  is: ConfigType.RENEWAL,
                  then: yup.object().shape({
                    unit: yup.string().required(),
                    amount: yup.number().required().min(1),
                  }),
                  otherwise: yup.object().shape({
                    unit: yup.string().nullable(),
                    amount: yup.number().nullable(),
                  }),
                },
              ),
              offset: yup.object().when(
                'configType',
                {
                  is: ConfigType.EXPIRY,
                  then: yup.object().shape({
                    unit: yup.string().required(),
                    amount: yup.number().required().min(1),
                  }),
                  otherwise: yup.object().shape({
                    unit: yup.string().nullable(),
                    amount: yup.number().nullable(),
                  }),
                },
              ),
            })
          ) : (
            yup.mixed()
          )}
        >
          <PropertyList
            Row={withProps(ValidationPropertyRow, { useShowValidationErrors })}
            properties={properties}
          />
        </Validation>
      )}
    </DraftPanel>
  );
};
