import { Stage, renderStageName } from '@deepstream/common/rfq-utils';
import * as React from 'react';
import { Box, Text, Flex } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { DateFormat } from '@deepstream/utils';
import { Bold } from './Bold';
import { Datetime2 } from './Datetime';

type RequestStagesProps = {
  activeStageIndex: number;
  stages: Stage[];
};

const StagesTooltip: React.FC<any> = ({ stages }) => {
  const { t } = useTranslation(['general', 'translation']);

  return (
    <Box>
      {stages.map(
        (stage, index) => (
          <Box mt={index > 0 ? 2 : 0} key={index}>
            <Text>{t('stage', { count: 1 })} {index + 1} – {renderStageName(stage, t)}</Text>
            <Text>{t('ends')}: <Datetime2 value={stage.completionDeadline!} format={DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ} /></Text>
          </Box>
        ),
      )}
    </Box >
  );
};

export const RequestStages: React.FC<RequestStagesProps> = ({ activeStageIndex, stages }) => {
  const { t } = useTranslation('general');

  return stages.length > 1 && activeStageIndex > -1 ? (
    <Tooltip content={<StagesTooltip stages={stages} />}>
      <Box mr={4}>
        <Text fontSize={1} fontWeight={500} mb={1}>{t('activeStage')}</Text>
        <Flex alignItems="center" fontSize={2}>
          <Icon fixedWidth icon="list-ul" mr={1} />
          <Bold>{t('countOfTotal', { count: activeStageIndex + 1, total: stages.length })}</Bold>
        </Flex>
      </Box>
    </Tooltip>
  ) : (
    null
  );
};
