import { useMemo } from 'react';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';

import { PreQualCategory } from '../../types';
import { SelectField } from '../../form/SelectField';
import { useListCategories } from './utils';

export const CategorySelectorField = ({
  fieldName,
  required,
  map: mapCbProp,
}: {
  fieldName: string;
  required?: boolean;
  map?: (category: PreQualCategory) => { label?: string; value?: string; disabled?: boolean };
}) => {
  const { t } = useTranslation('preQualification');
  const { data, isLoading, isError } = useListCategories();

  const items = useMemo(() => {
    if (!data || !data.length) return [];

    const mappingFunction = typeof mapCbProp === 'function'
      ? (category: PreQualCategory) => ({ label: `${category.name} (${category.questionIds.length})`, value: category._id, ...mapCbProp(category) })
      : (category: PreQualCategory) => ({ label: `${category.name} (${category.questionIds.length})`, value: category._id });

    return map(data, mappingFunction);
  }, [data, mapCbProp]);

  return (
    <SelectField
      required={required}
      name={fieldName}
      label={t('categorySelector.fieldName')}
      items={items}
      disabled={isLoading || isError}
    />
  );
};
