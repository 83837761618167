import { useTranslation } from 'react-i18next';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';

import { ApprovalBox } from './ApprovalBox';

export const ApprovalBlocked = () => {
  const { t } = useTranslation('contracts');

  return (
    <ApprovalBox
      heading={(
        <>
          <Icon icon="times" color="danger" mr={2} />
          {t('approval.blocked.heading')}
        </>
      )}
      description={t('approval.blocked.description')}
    />
  );
};
