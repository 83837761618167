import { AuctionLineItemExchangeDefinition, AwardDecision, ExchangeType, LineItemExchangeDefinition, Live, PageType, RfxSection, SectionType, isStandaloneAuctionLineItemExchangeDef, renderSectionName } from '@deepstream/common/rfq-utils';
import { useCallback, useMemo, useState } from 'react';
import { difference, first, groupBy, intersection, isEmpty, without } from 'lodash';
import { TFunction, useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';
import * as rfx from '../../../rfx';
import { useRecipientId } from '../../../useRfq';
import { useExchangeModalState } from '../../../useExchangeModalState';
import { RfxExchangeModal } from '../../../ExchangeModal/RfxExchangeModal';
import { AwardedLineItemsGrid, AwardedLineItemsGridRow } from '../../../ui/ExchangeDefsGrid/AwardedLineItemsGrid';
import { useCurrentUserLocale } from '../../../useCurrentUser';
import * as lotPagesLayout from '../Live/lotPagesLayout';
import { useModalState } from '../../../ui/useModalState';
import { OpenAuctionStageModal } from './OpenAuctionStageModal';

type SectionData = {
  section: RfxSection;
  splitSectionExchangeRows: AwardedLineItemsGridRow[];
  nonSplitSectionExchangeRows: AwardedLineItemsGridRow[];
};

const flattenSectionData = (
  sectionData: SectionData[],
  exchangeRowsPropName: 'splitSectionExchangeRows' | 'nonSplitSectionExchangeRows',
  t: TFunction,
): AwardedLineItemsGridRow[] => {
  return sectionData.flatMap((sectionData) => {
    const exchangeRows = sectionData[exchangeRowsPropName];

    return isEmpty(exchangeRows)
      ? []
      : [
        {
          _id: sectionData.section._id,
          label: `${t('general.section', { count: 1 })} ${sectionData.section.name}`,
          isSubHeader: true,
          numItems: exchangeRows.length,
        },
        ...exchangeRows,
      ];
  });
};

export const AwardedLineItemsSectionContent = () => {
  const { t } = useTranslation('translation');
  const { pages, sectionById, recipients, meta, exchangeDefById, currencyCode } = rfx.useStructure<Live>();
  const recipientId = useRecipientId();
  const locale = useCurrentUserLocale();
  const currentCompanyGroup = rfx.useCurrentCompanyGroup();
  const openAuctionStageModal = useModalState();

  const [exchangeModalData, setExchangeModalData] = useState<{ exchangeId: string | null; recipientId: string | null }>({
    recipientId: null,
    exchangeId: null,
  });

  // @ts-expect-error ts(2345) FIXME: Argument of type '{ exchangeId: string | null; recipientId: string | null; }' is not assignable to parameter of type 'UseExchangeModalStateProps'.
  const exchangeModal = useExchangeModalState(exchangeModalData);

  const {
    splitExchangeRows,
    nonSplitExchangeRows,
  } = useMemo(() => {
    const awardDecisionByExchangeId = meta.outcomeDetails?.awardDecisionByExchangeId || {};
    const splitDecisionByExchangeId = meta.outcomeDetails?.splitDecisionByExchangeId || {};

    const awardedExchangeIds = Object.keys(awardDecisionByExchangeId);
    const splitExchangeIds = Object.keys(splitDecisionByExchangeId);

    const nonSplitExchangeIds = difference(awardedExchangeIds, splitExchangeIds);

    const lineItemExchangeDefs = Object.values(exchangeDefById)
      .filter(exchangeDef => (
        !exchangeDef.isObsolete &&
        (
          (exchangeDef.type === ExchangeType.LINE_ITEM && exchangeDef.fields.totalCost) ||
          isStandaloneAuctionLineItemExchangeDef(exchangeDef)
        )
      ));

    // we don't access `section.exchangeDefIds` for this because it doesn't
    // include IDs of supplier-provided exchanges, which we want to include
    // here
    const lineItemExchangeDefsBySectionId = groupBy(
      lineItemExchangeDefs,
      exchangeDef => exchangeDef.sectionId,
    );

    const sections = Object.values(sectionById)
      .filter(section => [SectionType.LINE_ITEMS, SectionType.AUCTION_LINE_ITEMS].includes(section.type))
      .map(section => {
        return {
          // @ts-expect-error ts(2783) FIXME: 'name' is specified more than once, so this usage will be overwritten.
          name: renderSectionName(section, t),
          ...section,
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name, locale));

    const sectionData = sections
      .map(section => {
        const sectionExchangeDefs = lineItemExchangeDefsBySectionId[section._id] || [];
        const sectionExchangeDefIds = sectionExchangeDefs.map(exchangeDef => exchangeDef._id);

        const orderedExchangeDefIds = [
          // make sure all buyer-provided exchangeDefs are ordered according
          // to section.exchangeDefById
          ...intersection(section.exchangeDefIds, sectionExchangeDefIds),
          ...without(sectionExchangeDefIds, ...section.exchangeDefIds),
        ];

        const nonSplitSectionExchangeRows = intersection(orderedExchangeDefIds, nonSplitExchangeIds).map(exchangeDefId => {
          const exchangeDef = sectionExchangeDefs
            .find(exchangeDef => exchangeDef._id === exchangeDefId) as LineItemExchangeDefinition | AuctionLineItemExchangeDefinition;

          const awardDecision = awardDecisionByExchangeId[exchangeDefId] as Extract<AwardDecision, { value: 'award' }>;
          const award = first(awardDecision.awards);

          return {
            _id: exchangeDef._id,
            label: (exchangeDef as any).description as string,
            // @ts-expect-error ts(18048) FIXME: 'award' is possibly 'undefined'.
            awardedExchangeId: award.awardedExchangeId,
            // @ts-expect-error ts(18048) FIXME: 'award' is possibly 'undefined'.
            requestCurrencyAwardedCost: award.requestCurrencyAwardedCost,
          };
        });

        const splitSectionExchangeRows = intersection(orderedExchangeDefIds, splitExchangeIds).map(exchangeDefId => {
          const exchangeDef = sectionExchangeDefs
            .find(exchangeDef => exchangeDef._id === exchangeDefId) as LineItemExchangeDefinition | AuctionLineItemExchangeDefinition;

          const awardDecision = awardDecisionByExchangeId[exchangeDefId] as Extract<AwardDecision, { value: 'award' }>;
          const award = first(awardDecision.awards);

          const splitDecision = splitDecisionByExchangeId[exchangeDefId];
          // @ts-expect-error ts(18047) FIXME: 'splitDecision' is possibly 'null'.
          const recipientSplitDecision = splitDecision.decisionByRecipientId[recipientId];

          return {
            _id: exchangeDef._id,
            label: (exchangeDef as any).description as string,
            // @ts-expect-error ts(18048) FIXME: 'award' is possibly 'undefined'.
            awardedExchangeId: award.awardedExchangeId,
            originalPricePerUnit: recipientSplitDecision.originalPricePerUnit,
            originalQuantity: recipientSplitDecision.originalQuantity,
            supplierCurrencyCode: recipientSplitDecision.supplierCurrencyCode,
            requestCurrencyAwardedCost: recipientSplitDecision.requestCurrencyAwardedCost,
            awardedQuantityAmount: recipientSplitDecision.awardedQuantityAmount,
            awardedQuantityPercentage: recipientSplitDecision.awardedQuantityPercentage,
          };
        });

        return {
          section,
          nonSplitSectionExchangeRows,
          splitSectionExchangeRows,
        };
      });

    return {
      splitExchangeRows: flattenSectionData(sectionData, 'splitSectionExchangeRows', t),
      nonSplitExchangeRows: flattenSectionData(sectionData, 'nonSplitSectionExchangeRows', t),
    };
  }, [meta, exchangeDefById, sectionById, t, locale, recipientId]);

  const chatPage = pages.find(page => page.type === PageType.CHAT);
  const exchangeModalSection = Object.values(sectionById).find(section => section.type === SectionType.CHAT);

  const closeExchange = useCallback(() => {
    setExchangeModalData({
      recipientId: null,
      exchangeId: null,
    });
  }, []);

  const handleRowClick = (row: AwardedLineItemsGridRow) => {
    const { isSubHeader, awardedExchangeId } = row;

    if (isSubHeader) {
      return;
    }

    // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
    const exchangeDef = exchangeDefById[awardedExchangeId];

    if (exchangeDef.type === ExchangeType.AUCTION_LINE_ITEM) {
      openAuctionStageModal.open();
    } else {
      setExchangeModalData({
        recipientId,
        exchangeId: row._id,
      });
    }
  };

  return (
    <>
      {!isEmpty(nonSplitExchangeRows) && (
        <>
          <Box mt="12px">
            {t(`request.awardSummary.awardedLineItems.awardedInfo1.${currentCompanyGroup}`)}
          </Box>
          <Box mt="6px" mb={3}>
            {t('request.awardSummary.awardedLineItems.awardedInfo2')}
          </Box>
          <AwardedLineItemsGrid
            rows={nonSplitExchangeRows}
            onRowClick={handleRowClick}
            currencyCode={currencyCode}
            locale={locale}
          />
        </>
      )}

      {!isEmpty(splitExchangeRows) && (
        <>
          <lotPagesLayout.H3 mt={isEmpty(nonSplitExchangeRows) ? '20px' : '40px'}>
            {t('request.awardSummary.awardedLineItems.partiallyAwardedHeading')}
          </lotPagesLayout.H3>
          <Box mt="12px">
            {t(`request.awardSummary.awardedLineItems.partiallyAwardedInfo1.${currentCompanyGroup}`)}
          </Box>
          <Box mt="6px" mb={3}>
            {t(`request.awardSummary.awardedLineItems.partiallyAwardedInfo2.${currentCompanyGroup}`)}
          </Box>
          <AwardedLineItemsGrid
            rows={splitExchangeRows}
            onRowClick={handleRowClick}
            currencyCode={currencyCode}
            locale={locale}
            showSplitDecisionDetails
          />
        </>
      )}

      {exchangeModalSection && (
        <rfx.RecipientsProvider recipients={recipients}>
          {/*
           // @ts-expect-error ts(2322) FIXME: Type 'Page | undefined' is not assignable to type 'Page'. */}
          <rfx.PageProvider page={chatPage}>
            <RfxExchangeModal
              {...exchangeModal}
              showRecipient
              close={() => closeExchange()}
            />
          </rfx.PageProvider>
        </rfx.RecipientsProvider>
      )}
      {openAuctionStageModal.isOpen && (
        <OpenAuctionStageModal {...openAuctionStageModal} />
      )}
    </>
  );
};
