import { useTranslation } from 'react-i18next';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { CancelButton, Modal, ModalBody, ModalFooter, ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import { useUpdateSpendAndSavings } from './useUpdateSpendAndSavings';

export const DisableSpendAndSavingsModal = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const { t } = useTranslation('translation');
  const [updateSpendAndSavings, { isLoading }] = useUpdateSpendAndSavings();

  const disableSpendAndSavings = () => updateSpendAndSavings({ enabled: false });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      shouldCloseOnEsc
      style={{ content: { width: '500px', minWidth: 'min(500px, 100vw - 32px)' } }}
    >
      <ModalHeader>
        {t('request.spendAndSavings.disableModal.title')}
      </ModalHeader>
      <ModalBody>
        <MessageBlock variant="warn" mt={0}>
          {t('request.spendAndSavings.disableModal.warning')}
        </MessageBlock>
      </ModalBody>
      <ModalFooter>
        <CancelButton onClick={close} />
        <Button variant="danger" onClick={disableSpendAndSavings} disabled={isLoading}>
          {t('request.spendAndSavings.disable')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
