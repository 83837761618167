import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';

type RecordDetailsProps = {
  record: any;
};

export const RecordDetails: React.FC<RecordDetailsProps> = ({ record }) => {
  const { t } = useTranslation('integration');

  return (
    <Flex alignItems="center" style={{ overflow: 'hidden' }}>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Text color="lightNavy" fontSize={1}>
          {t('externalSupplierIdLabel')}
        </Text>
        <Text fontSize={2} mb={8}>
          {record.externalSupplierId}
        </Text>
        <Text color="lightNavy" fontSize={1}>
          {t('externalSupplierNameLabel')}
        </Text>
        <Text fontSize={2}>
          {record.externalSupplierName || <EmDash />}
        </Text>
      </Flex>
    </Flex>
  );
};
