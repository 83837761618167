import * as React from 'react';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { UnexpectedStateError } from '@deepstream/errors';
import { Flex } from 'rebass/styled-components';
import { GridQuestionExchangeDefinition, QuestionExchangeDefinition, QuestionType } from '@deepstream/common/rfq-utils';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { ReadOnlyGridColumnTable } from './Grid';
import { RowsConfig } from '../../../draft/QuestionFields/GridField';
import { DisabledInputBox } from '../../../ui/Input';
import { Bold } from '../../../Bold';

export const DisabledGridField = ({
  exchangeDef,
  fieldMaxWidth,
}: {
  exchangeDef: QuestionExchangeDefinition;
  fieldMaxWidth?: React.CSSProperties['maxWidth'];
}) => {
  const { t } = useTranslation();
  const { questionType, currencies, rowsConfig } = exchangeDef as GridQuestionExchangeDefinition;

  if (questionType !== QuestionType.GRID) {
    Sentry.captureException(new UnexpectedStateError('[Grid] Wrong question type passed', { questionType }));
    return null;
  }

  return (
    <Stack gap={2} sx={{ maxWidth: fieldMaxWidth }}>
      <ReadOnlyGridColumnTable exchangeDef={exchangeDef as GridQuestionExchangeDefinition} isDisabled />
      {!isEmpty(currencies) && (
        <Flex alignItems="center" sx={{ gap: 3 }}>
          <Bold>
            {t('currency', { ns: 'general' })}
          </Bold>
          <DisabledInputBox maxWidth="140px">
            <Truncate>
              {/*
               // @ts-expect-error ts(18048) FIXME: 'currencies' is possibly 'undefined'. */}
              {currencies.join(', ')}
            </Truncate>
          </DisabledInputBox>
        </Flex>
      )}
      <RowsConfig rowsConfig={rowsConfig} isDisabled />
    </Stack>
  );
};
