import { compact, fromPairs, isEmpty } from 'lodash';
import { useMemo } from 'react';
import { MessagesBySupplierGroup } from '@deepstream/common/rfq-utils';
import { AwardFlowData, MessageMethod } from './types';

const getMessagesBySupplierGroup = (data: AwardFlowData): MessagesBySupplierGroup => {
  const supplierGroupsWithMessages = ([
    'awarded',
    'unsuccessful',
    'previouslyUnsuccessful',
    'nonBidding',
  ] as const).filter(supplierGroup => data.supplierGroupsToMessage[supplierGroup]);

  return fromPairs(
    supplierGroupsWithMessages.map(supplierGroup => {
      const messageConfig = data.messageConfigByGroup[supplierGroup];

      const generalMessageSupplierIds = messageConfig.messageMethod === MessageMethod.GENERAL ? (
        data.supplierIdsByGroup[supplierGroup]
      ) : messageConfig.messageMethod === MessageMethod.CUSTOM ? (
        messageConfig.generalMessageSupplierIds
      ) : (
        []
      );

      // @ts-expect-error ts(2345) FIXME: Argument of type 'MessageMethod | null' is not assignable to parameter of type 'MessageMethod'.
      const messageBySupplierId = [MessageMethod.BY_SUPPLIER, MessageMethod.CUSTOM].includes(messageConfig.messageMethod)
        ? messageConfig.messageBySupplierId
        : {};

      return [
        supplierGroup,
        compact([
          isEmpty(generalMessageSupplierIds || !messageConfig.generalMessage)
            ? null
            : {
              message: messageConfig.generalMessage,
              supplierIds: generalMessageSupplierIds,
            },
          ...Object.entries(messageBySupplierId).map(([supplierId, message]) => ({
            message,
            supplierIds: [supplierId],
          })),
        ]),
      ];
    }),
  ) as MessagesBySupplierGroup;
};

export const useMessagesBySupplierGroup = (data: AwardFlowData): MessagesBySupplierGroup => {
  return useMemo(() => getMessagesBySupplierGroup(data), [data]);
};
