import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CollaboratorInviteStatus, PageRole } from '@deepstream/common/rfq-utils';

import * as rfx from './rfx';
import { useCurrentCompanyId } from './currentCompanyId';
import { RfqIdProvider, useDraftRfqStructure, useRfqId } from './useRfq';
import { LoadingPanel } from './ui/Loading';
import { ErrorPanel } from './ui/ErrorMessage';
import { RequestTeam } from './modules/Request/Team/RequestTeam';
import { useSaveDraftTeamChanges } from './useSaveRequestTeamChanges';
import { DraftToolbar } from './modules/Request/DraftToolbar';
import { useTeamRouteSearch } from './AppRouting';
import { useRequestEditNavigation } from './appNavigation';

const DraftTeamToolbar = () => {
  const rfqId = useRfqId();
  const { editingPanelId, isTemplate, isRevising } = rfx.useState();
  const isSuperUserOrOwner = rfx.useIsSuperUserOrOwner();
  const isEditing = Boolean(editingPanelId);

  if (isEditing) {
    return null;
  }

  return (
    <DraftToolbar
      rfqId={rfqId}
      tabId="team"
      hasUnsavedChanges={false}
      showSubmitButton={false}
      isSuperUserOrOwner={isSuperUserOrOwner}
      isTemplate={isTemplate}
      isRevising={isRevising}
      isRequestValid={true}
      excessSupplierCount={0}
    />
  );
};

const RequestTeamWrapper = () => {
  const [saveChanges] = useSaveDraftTeamChanges();
  const { isTemplate } = rfx.useState();
  const { senders } = rfx.useStructure();

  const { navigateToTeam } = useRequestEditNavigation();
  const search = useTeamRouteSearch({ isTemplate });

  const nonRejectedSenders = senders.filter(sender =>
    sender.inviteStatus !== CollaboratorInviteStatus.REJECTED,
  );

  useEffect(
    () => {
      if (!search.companyId) {
        navigateToTeam(nonRejectedSenders[0]._id, true);
      }
    },
    [navigateToTeam, nonRejectedSenders, search.companyId],
  );

  const selectedCompanyIndex = nonRejectedSenders.findIndex(
    sender => sender._id === String(search.companyId),
  );

  return (
    <rfx.SaveChangesProvider saveChanges={saveChanges}>
      <RequestTeam
        selectedCompanyIndex={selectedCompanyIndex}
        selectCompanyId={navigateToTeam}
      />
    </rfx.SaveChangesProvider>
  );
};

export type DraftTeamProps = {
  rfqId: string;
  isRevising?: boolean;
  isTemplate?: boolean;
  navigateToCompanyTeam: () => void;
};

export const DraftTeam = ({
  rfqId,
  isRevising,
  isTemplate,
  navigateToCompanyTeam,
}: DraftTeamProps) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { data: rfxStructure, isLoading, isError } = useDraftRfqStructure({
    rfqId,
    currentCompanyId,
    isTemplate,
  });

  // Templates should be editable by all members of a company but the template
  // structure doesn't contain ownership / team membership information in
  // `teamById` which we use to determine page permissions so we provide
  // overrides here
  const rfxOverrides = useMemo(
    (): rfx.UserOverrides => isTemplate ? { isOwner: true, pageRole: PageRole.EDITOR } : {},
    [isTemplate],
  );

  return (
    <RfqIdProvider rfqId={rfqId}>
      <rfx.StateProvider isRevising={isRevising} isTemplate={isTemplate}>
        <rfx.CompanyTeamNavigationProvider navigateToCompanyTeam={navigateToCompanyTeam}>
          {isLoading ? (
            <LoadingPanel />
          ) : isError ? (
            <ErrorPanel error={t('errors.unexpected')} />
          ) : rfxStructure ? (
            <rfx.StructureProvider structure={rfxStructure}>
              <rfx.OverridesProvider {...rfxOverrides}>
                <RequestTeamWrapper />
                <DraftTeamToolbar />
              </rfx.OverridesProvider>
            </rfx.StructureProvider>
          ) : (
            null
          )}
        </rfx.CompanyTeamNavigationProvider>
      </rfx.StateProvider>
    </RfqIdProvider>
  );
};
