import * as React from 'react';
import { Box, BoxProps } from 'rebass/styled-components';

type VisibilityProps =
  BoxProps &
  { visibility: React.CSSProperties['visibility'] };

export const Visibility = ({ visibility, ...props }: VisibilityProps) => (
  <Box sx={{ visibility }} {...props} />
);
