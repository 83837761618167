import { useMemo, useEffect, useCallback } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { matches } from 'lodash';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils';
import {
  Tab,
  TabListPanel,
  TabPanels,
  TabPanel,
  Tabs,
} from './ui/TabsVertical';
import { SidebarLayout } from './ui/ProfileLayout';
import { Templates } from './Templates';
import { useDeviceSize } from './ui/useDeviceSize';
import { ErrorPanel } from './ui/ErrorMessage';
import { LoadingPanel } from './ui/Loading';
import { DraftSentRequests, LiveSentRequests } from './SentRequests';
import { ReceivedRequests } from './ReceivedRequests';
import { TemplatesNotPayingCompany } from './TemplatesNotPayingCompany';
import { RequestsNavigationProvider, useRequestsNavigation } from './RequestsNavigationContext';
import { UserFlagsProvider, useUserFlags } from './UserFlagsContext';
import { useCurrentUser } from './useCurrentUser';
import { ReverseOrderIf } from './ReverseOrderIf';
import { RequestTagsProvider, useRequestTagsContext } from './modules/RequestTags/RequestTagsContext';
import RequestTagsPanel from './modules/RequestTags/RequestTagsPanel';
import RequestTagPanel from './modules/RequestTags/RequestTagPanel';
import { getTagIdFromIndex, getTreeIndexFromId, isTagIndex } from './modules/RequestTags/utils';
import { PublicRequestsTab } from './modules/Request/Public/PublicRequests';
import { useCurrentCompanyId } from './currentCompanyId';
import { useLocalStorageState } from './useLocalStorageState';
import { buyerOrBothTabs, RequestsTab, supplierTabs } from './requestsConstants';
import { useNotificationSubject } from './modules/Notifications/useNotificationSubject';

export const RequestTabs = ({ selectedTabId }: { selectedTabId?: string }) => {
  const { t } = useTranslation('translation');
  const { isExtraSmall, isSmall } = useDeviceSize();
  const { tags, isLoading: areTagsLoading } = useRequestTagsContext();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const { navigateToRequestsTab } = useRequestsNavigation();

  const {
    belongsToSupplierOnlyCompany,
    belongsToPayingCompany,
    hasSendRfqPermission,
    status: userFlagsStatus,
  } = useUserFlags();

  const requestTabs = userFlagsStatus === 'success' ? (
    belongsToSupplierOnlyCompany ? supplierTabs : buyerOrBothTabs
  ) : undefined;

  const requestTagsTabs = useMemo(
    () => tags.map(tag => tag._id),
    [tags],
  );

  const tabs = useMemo(
    // @ts-expect-error ts(2769) FIXME: No overload matches this call.
    () => (requestTabs ?? [])?.concat(requestTagsTabs),
    [requestTabs, requestTagsTabs],
  );

  const [lastActiveTab, setLastActiveTab] = useLocalStorageState<string>({
    key: `${currentCompanyId}.${currentUser._id}.requestsPage.lastActiveTab`,
    // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string | (() => string)'.
    defaultValue: requestTabs?.[0],
  });

  useEffect(
    () => {
      if (selectedTabId && selectedTabId !== lastActiveTab) {
        setLastActiveTab(selectedTabId);
      }
    },
    [selectedTabId, lastActiveTab, setLastActiveTab],
  );

  useEffect(
    () => {
      if (requestTabs && !selectedTabId) {
        navigateToRequestsTab(lastActiveTab || requestTabs[0], true);
      }
    },
    [requestTabs, selectedTabId, lastActiveTab, navigateToRequestsTab],
  );

  const onTabsChange = useCallback(
    (index) => {
      if (tabs && !isTagIndex(index)) {
        navigateToRequestsTab(tabs[index]);
      }
      if (isTagIndex(index)) {
        navigateToRequestsTab(getTagIdFromIndex(index));
      }
    },
    [tabs, navigateToRequestsTab],
  );

  const selectedTabIndex = !selectedTabId ? (
    -1
  ) : requestTagsTabs.includes(selectedTabId) ? (
    getTreeIndexFromId(selectedTabId)
  ) : (
    tabs?.indexOf(selectedTabId)
  );

  return userFlagsStatus === 'loading' || areTagsLoading ? (
    <LoadingPanel />
  ) : userFlagsStatus === 'error' ? (
    <ErrorPanel error={t('company.errors.getCompany')} />
  ) : (
    <Tabs key={tabs.length} index={selectedTabIndex} onChange={onTabsChange}>
      <SidebarLayout
        sidebar={
          <>
            <TabListPanel>
              <ReverseOrderIf condition={belongsToSupplierOnlyCompany}>
                <Tab data-test="sent-tab" key={RequestsTab.sent}>
                  <Flex alignItems="center">
                    <Icon icon="share" mr={2} fixedWidth />
                    <Text flex={1}>{t('requests.sent')}</Text>
                  </Flex>
                </Tab>
                <Tab data-test="received-tab" key={RequestsTab.received}>
                  <Flex alignItems="center">
                    <Icon icon="reply" mr={2} fixedWidth />
                    <Text flex={1}>{t('requests.received')}</Text>
                  </Flex>
                </Tab>
              </ReverseOrderIf>
              <Tab data-test="public-tab" key={RequestsTab.public}>
                <Flex alignItems="center">
                  <Icon icon="globe" mr={2} fixedWidth light />
                  <Text flex={1}>{t('requests.public')}</Text>
                </Flex>
              </Tab>
              <Tab data-test="drafts-tab" key={RequestsTab.drafts}>
                <Flex alignItems="center">
                  <Icon icon="pencil" mr={2} fixedWidth />
                  <Text flex={1}>{t('requests.drafts')}</Text>
                </Flex>
              </Tab>
              {hasSendRfqPermission && (
                <Tab data-test="templates-tab" key={RequestsTab.templates}>
                  <Flex alignItems="center">
                    <Icon icon="files-o" mr={2} fixedWidth />
                    <Text flex={1}>{t('requests.template_other')}</Text>
                  </Flex>
                </Tab>
              )}
            </TabListPanel>
            <RequestTagsPanel />
          </>
        }
        main={
          <TabPanels>
            <ReverseOrderIf condition={belongsToSupplierOnlyCompany}>
              <TabPanel key={RequestsTab.sent}>
                <LiveSentRequests />
              </TabPanel>
              <TabPanel key={RequestsTab.received}>
                <ReceivedRequests />
              </TabPanel>
            </ReverseOrderIf>
            {/*
             // @ts-expect-error ts(18048) FIXME: 'requestTabs' is possibly 'undefined'. */}
            <TabPanel key={RequestsTab.public} tabIndex={requestTabs.indexOf(RequestsTab.public)}>
              <PublicRequestsTab />
            </TabPanel>
            <TabPanel key={RequestsTab.drafts}>
              <DraftSentRequests />
            </TabPanel>
            {hasSendRfqPermission && (
              <TabPanel key={RequestsTab.templates}>
                {belongsToPayingCompany ? (
                  <Templates />
                ) : (
                  <TemplatesNotPayingCompany />
                )}
              </TabPanel>
            )}
            {tags?.map(tag => (
              <RequestTagPanel requestTag={tag} key={tag._id} />
            ))}
          </TabPanels>
        }
        sidebarStyle={!isExtraSmall && !isSmall ? { maxWidth: '350px', flex: '0 0 auto' } : undefined}
        mainStyle={!isExtraSmall && !isSmall ? { flex: '1 1 auto' } : undefined}
      />
    </Tabs>
  );
};

export const Requests = ({
  navigateToSenderRequest,
  navigateToReceivedRequest,
  navigateToTemplatePreview,
  navigateToTemplateEdit,
  navigateToRequestsTab,
  selectedTabId,
}: {
  navigateToSenderRequest: (arg: { requestId: string, isDraft: boolean }) => void;
  navigateToReceivedRequest: (requestId: string) => void;
  navigateToTemplatePreview: (templateId: string) => void;
  navigateToTemplateEdit: (templateId: string) => any;
  navigateToRequestsTab: (tab?: string, replace?: boolean) => void;
  selectedTabId?: string;
}) => {
  const currentCompanyId = useCurrentCompanyId();
  const user = useCurrentUser();

  const notificationsFilter = useMemo(
    () => {
      switch (selectedTabId) {
        case RequestsTab.sent:
          return matches({
            domain: NotificationDomain.RFQ_SENT,
            action: NotificationAction.REMOVED_MEMBER,
            to: { companyId: currentCompanyId },
          });
        case RequestsTab.received:
          return matches({
            domain: NotificationDomain.RFQ_RECEIVED,
            action: NotificationAction.REMOVED_MEMBER,
            to: { companyId: currentCompanyId },
          });
        default:
          return () => false;
      }
    },
    [currentCompanyId, selectedTabId],
  );

  const notificationsRef = useNotificationSubject({ filter: notificationsFilter });

  return user ? (
    <RequestsNavigationProvider
      navigateToSenderRequest={navigateToSenderRequest}
      navigateToReceivedRequest={navigateToReceivedRequest}
      navigateToTemplatePreview={navigateToTemplatePreview}
      navigateToTemplateEdit={navigateToTemplateEdit}
      navigateToRequestsTab={navigateToRequestsTab}
    >
      <UserFlagsProvider>
        <RequestTagsProvider>
          <Box ref={notificationsRef}>
            <RequestTabs selectedTabId={selectedTabId} />
          </Box>
        </RequestTagsProvider>
      </UserFlagsProvider>
    </RequestsNavigationProvider>
  ) : (
    null
  );
};
