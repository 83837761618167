import { useMemo, memo } from 'react';
import * as React from 'react';
import { Text } from 'rebass/styled-components';
import * as PreQ from '@deepstream/common/legacy-pre-q-utils';
import { sumBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Panel, PanelText } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Loading } from '../ui/Loading';
import { ProgressBarObsolete } from '../ui/ProgressBar';

type Totals = {
  numCompleted: number;
  numQuestions: number;
};

type ProgressProps = {
  questionnaire: PreQ.Questionnaire;
  answerSet: PreQ.AnswerSet;
};

export const Progress: React.FC<ProgressProps> = memo(({ questionnaire, answerSet }) => {
  const { t } = useTranslation('companyProfile');

  const totals: Totals = useMemo(
    () => {
      const answeredQuestionnaire = new PreQ.AnsweredPreQQuestionnaire(
        new PreQ.PreQQuestionnaire(questionnaire),
        new PreQ.PreQAnswerSet(answerSet),
      );

      const summaries = Object.values(answeredQuestionnaire.categorySummaries);

      return {
        numCompleted: sumBy(summaries, 'numCompleted'),
        numQuestions: sumBy(summaries, 'numQuestions'),
      };
    },
    [questionnaire, answerSet],
  );

  const percentage = Math.round((totals.numCompleted / totals.numQuestions) * 100);

  return totals ? (
    <Panel>
      <PanelText>
        <Stack gap={2}>
          <Text fontWeight={500}>
            {t('questionnaire.progress.numOfTotalCompleted', { num: totals.numCompleted, total: totals.numQuestions })}
          </Text>
          <ProgressBarObsolete percentage={percentage} height="20px" />
        </Stack>
      </PanelText>
    </Panel>
  ) : (
    <Panel>
      <PanelText>
        <Loading />
      </PanelText>
    </Panel>
  );
});
