import { Component } from 'react';
import * as Sentry from '@sentry/react';
import { ErrorMessage } from './ErrorMessage';

export class ErrorBoundary extends Component<
  { message?: string; children: React.ReactNode },
  { error: Error | null }
> {
  constructor(props: any) {
    super(props);

    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  override componentDidUpdate(_: unknown, prevState: { error: Error | null }) {
    const { error } = this.state;

    if (error && error !== prevState.error) {
      Sentry.captureException(error);
    }
  }

  override render() {
    if (this.state.error) {
      return (
        <ErrorMessage error={this.props.message} fontSize={2} gap={2} />
      );
    }

    return <>{this.props.children}</>;
  }
}
