import { useMemo } from 'react';
import { differenceInMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { padStart } from 'lodash';
import { transparentize } from 'polished';
import { Flex, Box, Text, BoxProps } from 'rebass/styled-components';
import styled from 'styled-components';

import { AuctionStatus } from '@deepstream/common/rfq-utils';
import { IconValue } from '@deepstream/common';
import { DateFormat, localeFormatDate } from '@deepstream/utils';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { assertUnreachable } from '@deepstream/utils/assertUnreachable';
import { useCountdown } from '../../../deadline';
import { useCurrentUserLocale } from '../../../useCurrentUser';

const VALUE_BOX_HEIGHT = 56;

const ValueBoxTimer = styled(Text)``;

const CountdownContainer = styled(Flex)<{
  valueMinor: number;
  isEndingSoon?: boolean;
  isPaused?: boolean;
}>`
  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.4);
    }
    100% {
      transform: scale(1);
    }
  }

  ${ValueBoxTimer} {
    animation: ${(props) => {
      const animate = props.isEndingSoon && props.valueMinor % 2 === 1;

      return animate && 'zoom-in-zoom-out 1s infinite';
    }};

    color: ${(props) => props.isEndingSoon ? props.theme.colors.danger : 'inherit'};
    opacity: ${(props) => props.isPaused ? '0.4' : '1'};
  }
`;

const ValueBox = ({
  value,
  label,
  ...props
}: {
  value: string;
  label: string;
} & BoxProps) => {
  return (
    <Box
      sx={{ position: 'relative' }}
      {...props}
    >
      <Box
        bg="lightGray3"
        lineHeight={`${VALUE_BOX_HEIGHT}px`}
        height={VALUE_BOX_HEIGHT}
        fontWeight="600"
        fontSize={5}
        sx={{
          borderRadius: 'small',
          border: 'secondary',
        }}
      >
        <ValueBoxTimer>
          {padStart(value, 2, '0')}
        </ValueBoxTimer>
      </Box>
      <Text
        sx={{ textTransform: 'uppercase' }}
        color="gray"
        fontSize={0}
        mt={1}
      >
        {label}
      </Text>
    </Box>
  );
};

const CountdownHeader = ({
  status,
  startsNextHr,
}: {
  status: AuctionStatus;
  startsNextHr: boolean;
}) => {
  const { t } = useTranslation();
  const data: {
    countdownLabel: string;
    statusLabel: string;
    color: string;
    icon: IconValue;
  } = useMemo(() => {
    switch (status) {
      case AuctionStatus.PENDING:
        return startsNextHr
          ? {
            countdownLabel: t('auctionCountdown.countdownLabel.startsIn'),
            statusLabel: t('auctionCountdown.statusLabel.upcoming'),
            icon: 'calendar',
            color: 'info',
          }
          : {
            countdownLabel: t('auctionCountdown.countdownLabel.starts'),
            statusLabel: t('auctionCountdown.statusLabel.upcoming'),
            icon: 'calendar',
            color: 'info',
          };
      case AuctionStatus.ACTIVE:
        return {
          countdownLabel: t('auctionCountdown.countdownLabel.endsIn'),
          statusLabel: t('auctionCountdown.statusLabel.live'),
          icon: 'circle',
          color: 'success',
        };
      case AuctionStatus.PAUSED:
        return {
          countdownLabel: t('auctionCountdown.countdownLabel.endsIn'),
          statusLabel: t('auctionCountdown.statusLabel.paused'),
          icon: 'pause',
          color: 'gray',
        };
      case AuctionStatus.ENDED:
        return {
          countdownLabel: t('auctionCountdown.countdownLabel.endedOn'),
          statusLabel: t('auctionCountdown.statusLabel.ended'),
          icon: 'square',
          color: 'gray',
        };
      case AuctionStatus.CANCELLED:
        return {
          countdownLabel: t('auctionCountdown.countdownLabel.cancelledOn'),
          statusLabel: t('auctionCountdown.statusLabel.cancelled'),
          icon: 'square',
          color: 'gray',
        };
    }
  }, [t, status, startsNextHr]);

  return (
    <Flex
      flexDirection="column"
      fontSize={0}
      sx={{ textTransform: 'uppercase', letterSpacing: '0.083333em' }}
    >
      <Flex
        sx={{
          marginLeft: 'auto',
          borderRadius: 'small',
          background: ({ colors }) => transparentize(0.8, colors[data.color]),
          lineHeight: 1,
        }}
        px={2}
        py={1}
        alignItems="baseline"
      >
        <Icon
          icon={data.icon}
          color={data.color}
          fontSize="10px"
          mr={1}
          fixedWidth
        />
        <Text>{data.statusLabel}</Text>
      </Flex>
      <Text color="gray" mt={2}>
        {data.countdownLabel}
      </Text>
    </Flex>
  );
};

const Countdown = ({
  endDate,
  pauseDate,
  status,
}: {
  endDate: Date;
  pauseDate?: Date;
  status: AuctionStatus;
}) => {
  const { t } = useTranslation();

  const countdown = useCountdown({
    deadline: endDate,
    // @ts-expect-error ts(2322) FIXME: Type 'Date | undefined' is not assignable to type 'Date'.
    referenceDate: status === 'paused' ? pauseDate : new Date(),
    isActive: status !== 'paused',
  });

  const data = useMemo(() => {
    const { days, hours, minutes, seconds } = countdown;

    if (days) {
      return {
        label: {
          major: t('auctionCountdown.day', { count: days }),
          minor: t('auctionCountdown.hour', { count: hours }),
        },
        value: { major: days, minor: hours },
      };
    } else if (hours) {
      return {
        label: {
          major: t('auctionCountdown.hour', { count: hours }),
          minor: t('auctionCountdown.minute', { count: minutes }),
        },
        value: { major: hours, minor: minutes },
      };
    } else {
      return {
        label: {
          major: t('auctionCountdown.minute', { count: minutes }),
          minor: t('auctionCountdown.seconds'),
        },
        value: { major: minutes, minor: seconds },
      };
    }
  }, [countdown, t]);

  const isEndingSoon = (status === 'active' || status === 'paused') &&
    countdown.days === 0 &&
    countdown.hours === 0 &&
    countdown.minutes === 0 &&
    countdown.seconds <= 59;

  return (
    <CountdownContainer
      valueMinor={data.value.minor}
      isEndingSoon={isEndingSoon}
      isPaused={status === 'paused'}
      textAlign="center"
      mt={2}
    >
      <ValueBox
        flex="1 1 auto"
        label={data.label.major}
        value={data.value.major.toString()}
      />
      <Text
        flex="0 0 auto"
        px={2}
        lineHeight={`${VALUE_BOX_HEIGHT}px`}
        height={VALUE_BOX_HEIGHT}
      >
        :
      </Text>
      <ValueBox
        flex="1 1 auto"
        label={data.label.minor}
        value={data.value.minor.toString()}
      />
    </CountdownContainer>
  );
};

const AuctionDate = ({
  endDate,
}: {
  endDate: Date;
}) => {
  const locale = useCurrentUserLocale();

  return (
    <Text fontSize={3} fontWeight={500}>
      {localeFormatDate(new Date(endDate), DateFormat.DD_MMM_YYYY, { locale })}
      <br />
      {localeFormatDate(new Date(endDate), DateFormat.HH_MM_B_O, { locale })}
    </Text>
  );
};

export const AuctionCountdown = ({
  status,
  endDate,
  pauseDate,
}: {
  status: AuctionStatus;
  endDate: Date;
  pauseDate?: Date;
}) => {
  const startsNextHr = status === 'pending' && differenceInMinutes(endDate, new Date()) <= 60;
  const isLive = status === 'active' || status === 'paused';

  return (
    <>
      <CountdownHeader
        status={status}
        startsNextHr={startsNextHr}
      />
      {
        (startsNextHr || isLive) ? (
          <Countdown
            key={status}
            status={status}
            endDate={endDate}
            pauseDate={pauseDate}
          />
        ) : (
          <AuctionDate endDate={endDate} />
        )
      }
    </>
  );
};
