import { Draft, Lot } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { GridMenu, GridMenuItem } from '@deepstream/ui-kit/grid/EditableGrid/GridMenu';
import { useGridMenuState } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import * as rfx from '../../rfx';

export const LotsGridMenu = () => {
  const { t } = useTranslation();
  const { lotById } = rfx.useStructure<Draft>();
  const { dataIndex } = useGridMenuState();
  const {
    rowData: draftLots,
    moveRow,
    removeRow,
    updateRowAtIndex,
  } = useEditableGridData<Lot<Draft>>();

  // @ts-expect-error ts(2538) FIXME: Type 'null' cannot be used as an index type.
  const draftLot = draftLots[dataIndex];
  const lotId = draftLot._id;
  const lot = lotById[lotId];

  const canChangeObsoleteState = (
    lot?.isLive &&
    !lot.liveVersion?.isObsolete
  );
  const canDelete = !lot?.isLive;

  return draftLot ? (
    <GridMenu
      popoverProps={{
        'data-test': 'more-lot-actions-menu',
      }}
    >
      <GridMenuItem
        icon="arrow-up"
        // @ts-expect-error ts(2345) FIXME: Argument of type 'number | null' is not assignable to parameter of type 'number'.
        onSelect={() => moveRow(dataIndex, -1)}
        disabled={dataIndex === 0}
      >
        {t('general.moveUp')}
      </GridMenuItem>

      <GridMenuItem
        icon="arrow-down"
        // @ts-expect-error ts(2345) FIXME: Argument of type 'number | null' is not assignable to parameter of type 'number'.
        onSelect={() => moveRow(dataIndex, +1)}
        disabled={dataIndex === draftLots.length - 1}
      >
        {t('general.moveDown')}
      </GridMenuItem>

      {canDelete && (
        <GridMenuItem
          icon="trash-o"
          // @ts-expect-error ts(2345) FIXME: Argument of type 'number | null' is not assignable to parameter of type 'number'.
          onSelect={() => removeRow(dataIndex)}
          color="danger"
        >
          {t('general.delete')}
        </GridMenuItem>
      )}

      {canChangeObsoleteState && !draftLot.isObsolete && (
        <GridMenuItem
          icon="ban"
          color="danger"
          onSelect={() => {
            updateRowAtIndex(
              // @ts-expect-error ts(2345) FIXME: Argument of type 'number | null' is not assignable to parameter of type 'number'.
              dataIndex,
              () => ({
                ...lot.liveVersion,
                isObsolete: true,
              }),
            );
          }}
        >
          {t('general.makeObsolete')}
        </GridMenuItem>
      )}

      {canChangeObsoleteState && draftLot.isObsolete && (
        <GridMenuItem
          icon="undo"
          onSelect={() => {
            updateRowAtIndex(
              // @ts-expect-error ts(2345) FIXME: Argument of type 'number | null' is not assignable to parameter of type 'number'.
              dataIndex,
              lot => ({
                ...lot,
                isObsolete: false,
              }),
            );
          }}
        >
          {t('general.makeNonObsolete')}
        </GridMenuItem>
      )}
    </GridMenu>
  ) : (
    null
  );
};
