import { useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';

import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Tab, TabListPanel } from '../../ui/TabsVertical';

import { useUserFlags } from '../../UserFlagsContext';
import { preQualificationQuestionnairesRoute } from '../../AppRouting';
import { PreQualTab, QUESTIONNAIRE_TABS } from './utils';

export const QuestionnairesPageSidebar = () => {
  const { t } = useTranslation('preQualification');
  const { tab }: { tab: PreQualTab } = preQualificationQuestionnairesRoute.useSearch();
  const { belongsToPayingCompany } = useUserFlags();

  return (
    <Stack gap={4}>
      <Box sx={{ display: QUESTIONNAIRE_TABS.includes(tab) ? 'block' : 'none' }}>
        <TabListPanel>
          {belongsToPayingCompany && (
            <>
              <Tab key={PreQualTab.ACTIVE_QUESTIONNAIRES}>
                <IconText gap={2} icon="check-circle" text={t('questionnaireTemplateStatus.active')} />
              </Tab>
              <Tab key={PreQualTab.DRAFT_QUESTIONNAIRES}>
                <IconText gap={2} icon="pencil" text={t('questionnaireTemplateStatus.draft')} />
              </Tab>
            </>
          )}
          <Tab key={PreQualTab.RECEIVED_QUESTIONNAIRES}>
            <IconText gap={2} icon="reply" text={t('questionnaire.received_other')} />
          </Tab>
        </TabListPanel>
      </Box>
    </Stack>
  );
};
