import * as React from 'react';
import { get } from 'lodash';
import { Row } from 'react-table';
import { ThemeContext } from 'styled-components';
import * as draft from './draft';
import * as validation from './validation';

export const ValidationRowCells = ({
  row,
  fieldName,
  cellStyle = {},
  useShowValidationErrors = draft.useShowValidationErrors,
}: {
  row: Row;
  fieldName: string;
  cellStyle?: React.CSSProperties;
  useShowValidationErrors?: () => boolean;
}) => {
  const theme = React.useContext(ThemeContext);
  const showValidationErrors = useShowValidationErrors();
  const { isValid } = validation.useError(`${fieldName}[${row.index}]`);
  const { isValid: hasValidFields } = validation.useErrors(`${fieldName}[${row.index}]`);

  const hasErrors = !isValid || !hasValidFields;

  return (
    <>
      {row.cells.map(cell => {
        const { key, ...props } = cell.getCellProps({
          style: {
            width: cell.column.width,
            textAlign: (cell.column as any).textAlign,
            verticalAlign: (cell.column as any).verticalAlign,
            backgroundColor: showValidationErrors && hasErrors
              ? theme.colors.errorBackground
              : undefined,
            ...cellStyle,
          },
        });

        return (
          <td key={key} {...props}>
            {cell.render('Cell')}
          </td>
        );
      })}
    </>
  );
};

export const ImportValidationRowCells = ({
  row,
  cellStyle = {},
}: {
  row: Row;
  cellStyle?: React.CSSProperties;
}) => {
  const theme = React.useContext(ThemeContext);
  const { errors } = validation.useErrors();

  const getErrors = (path: string) => get(errors, path);

  return (
    <>
      {row.cells.map(cell => {
        const { key, ...props } = cell.getCellProps({
          style: {
            width: cell.column.width,
            textAlign: (cell.column as any).textAlign,
            verticalAlign: (cell.column as any).verticalAlign,
            backgroundColor: getErrors(`[${row.index}].${cell.column.id}`)
              ? theme.colors.errorBackground
              : undefined,
            ...cellStyle,
          },
        });

        return (
          <td key={key} {...props}>
            {cell.render('Cell')}
          </td>
        );
      })}
    </>
  );
};
