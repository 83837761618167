import { Contract, SUMMARY_PAGE_ID } from '@deepstream/common/contract/contract';
import { useTranslation } from 'react-i18next';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { ErrorMessage } from '../../ui/ErrorMessage';
import { Loading } from '../../ui/Loading';
import { ContractProvider, usePagesPermissions } from './contract';
import { ContractBasicDetailsPanel } from './ContractBasicDetailsPanel';
import { ContractCounterPartyPanel } from './ContractCounterPartyPanel';
import { ContractCustomMilestonePanel } from './ContractCustomMilestonePanel';
import { ContractProductsAndServicesPanel } from './ContractProductsAndServicesPanel';
import { ContractReferenceNumbersPanel } from './ContractReferenceNumbersPanel';
import { ContractSpendDataPanel } from './ContractSpendDataPanel';
import { NoPageAccess } from './Permissions/NoPageAccess';
import { useContract } from './useContract';

const LiveSummaryPageContent = ({
  contract,
}: {
  contract: Contract;
}) => {
  const permissionsByPageId = usePagesPermissions();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const isSender = Boolean(contract?.senders.find(sender => sender._id === currentCompanyId));

  const { canRead } = permissionsByPageId[SUMMARY_PAGE_ID];

  if (!canRead) {
    return <NoPageAccess />;
  }

  return (
    <Stack gap="20px">
      <ContractBasicDetailsPanel />
      {!contract.isLegacy && (
        <ContractCustomMilestonePanel />
      )}
      <ContractCounterPartyPanel />
      {isSender && (
        <>
          <ContractSpendDataPanel />
          {!contract.isLegacy && (
            <ContractProductsAndServicesPanel />
          )}
          <ContractReferenceNumbersPanel />
        </>
      )}
    </Stack>
  );
};

export const ContractLiveSummary = ({
  contractId,
}: {
  contractId: string;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { data: contract, isLoading, isError, isSuccess } = useContract({
    contractId,
    currentCompanyId,
    scope: 'current',
  });

  return (
    <>
      {isLoading ? (
        <PanelPadding>
          <Loading />
        </PanelPadding>
      ) : isError ? (
        <PanelPadding>
          <ErrorMessage error={t('errors.unexpected')} />
        </PanelPadding>
      ) : isSuccess && contract ? (
        <ContractProvider contract={contract}>
          <LiveSummaryPageContent contract={contract} />
        </ContractProvider>
      ) : (
        null
      )}
    </>
  );
};
