import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { useIntercomTrackEvent } from '../../ui/useIntercomTrackEvent';

const Overlay = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  backdrop-filter: blur(8px);
  justify-content: center;
  padding: ${props => props.theme.space[3]}px;
`;

export const ComingSoonOverlay = () => {
  const { t } = useTranslation('preQualification');
  const handleOnClick = useIntercomTrackEvent({ eventName: 'pre-qualification-interest-expressed', showChat: true });

  return (
    <Overlay onClick={(event) => event.stopPropagation()}>
      <Panel sx={{ maxWidth: '500px', position: 'relative', top: '80px', height: 'fit-content' }}>
        <PanelHeader heading={t('comingSoonOverlay.header')} />
        <PanelDivider />
        <PanelPadding>
          <Text fontSize={2} lineHeight="1.4" sx={{ whiteSpace: 'pre-line' }}>
            {t('comingSoonOverlay.body')}
          </Text>
        </PanelPadding>
        <PanelDivider />
        <PanelPadding>
          <Flex justifyContent="flex-end" alignItems="center">
            <Button variant="primary" onClick={handleOnClick}>
              {t('comingSoonOverlay.button')}
            </Button>
          </Flex>
        </PanelPadding>
      </Panel>
    </Overlay>
  );
};
