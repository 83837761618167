import { useRef, useState, useCallback, useMemo, useEffect } from 'react';
import { useComboboxState, ComboboxState } from 'ariakit/combobox';
import { useSelectState } from 'ariakit/select';
import { Checkbox } from 'ariakit/checkbox';
import { constant, isEmpty, keyBy, map, omit } from 'lodash';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { usePromise } from '@deepstream/ui-kit/hooks/usePromise';
import {
  ComboboxScrollList,
  ComboboxWrapper,
  EMPTY_ARRAY,
  EMPTY_LIST_WRAPPER,
  FilterSelectComboboxProps,
  SelectButtonContainer,
  SelectButtonIcon,
  SelectContainer,
  StyledCombobox,
  StyledComboboxFooter,
  StyledComboboxItem,
  StyledSelect,
  StyledSelectPopover,
} from './FilterSelectCombobox';

const useMultiSelectState = <T,>({
  comboboxState,
  itemById,
  props,
}: {
  comboboxState: ComboboxState;
  itemById: Record<string, T>;
  props: Partial<FilterMultiSelectComboboxProps<T>>;
}) => {
  const { getId, initialItems, controlled, selected, onChange } = props;

  const selectState = useSelectState<string[]>({
    ...omit(comboboxState, ['value', 'setValue']),
    // @ts-expect-error ts(2322) FIXME: Type 'boolean[]' is not assignable to type 'string[]'.
    defaultValue: initialItems ? map(initialItems, getId) : EMPTY_ARRAY,
    ...(controlled && {
      value: selected ? map(selected, getId) : EMPTY_ARRAY,
      setValue: (value: string[]) =>
        onChange?.(map(value, (id) => itemById[id])),
    }),
  });

  return {
    selectState,
    resetSelectValue: () => selectState.setValue(EMPTY_ARRAY),
  };
};

export type FilterMultiSelectComboboxProps<T> = Omit<
  FilterSelectComboboxProps<T>,
  'initialItem' | 'selected' | 'onChange' | 'SelectedItem'
> & {
  /**
   * The item that is selected initially.
   */
  initialItems?: T[];
  /**
   * The items that are selected.
   * If not provided then the state will be held internally by the component.
   */
  selected?: T[];
  /**
   * Called when the selected items have changed.
   */
  onChange?: (item?: T[]) => void;
  /**
   * The component to render the selected items.
   * When rendering the selected item, `disabled` is always `false`.
   * If not provided then Item will be used instead when count of selected items is 1,
   * and a text component otherwise
   */
  SelectedItems?: ({
    items,
  }: {
    items: T[];
    disabled: boolean;
  }) => JSX.Element | null;
};

/**
 * Combobox select component with a filter text field.
 */
export const FilterMultiSelectCombobox = <T,>({
  items = EMPTY_ARRAY,
  initialItems,
  controlled,
  selected,
  disabled,
  onChange,
  onTouched,
  onFocus,
  StaticSelectButtonContent,
  filterPlaceholder,
  emptyFilteredItemsMessage,
  filterItems,
  getId,
  isDisabled = constant(false),
  Item,
  SelectedItems,
  ListWrapper = EMPTY_LIST_WRAPPER,
  renderMenuFooter,
  labelId,
  selectContainerStyle,
  selectButtonStyle,
  listStyle,
  itemWrapperStyle,
  popoverWidth,
  popoverPlacement,
  maxItems = 5,
}: FilterMultiSelectComboboxProps<T>) => {
  if (!SelectedItems && !StaticSelectButtonContent) {
    throw new Error('`StaticSelectButtonContent` is required if no `SelectedItems` props included');
  }

  const selectContainerRef = useRef<HTMLDivElement>(null);
  const [ids, setIds] = useState<string[]>([]);
  const [itemById, setItemById] = useState<Record<string, T>>({});

  const comboboxState = useComboboxState({
    list: ids,
    gutter: 4,
    sameWidth: !popoverWidth,
    placement: popoverPlacement,
  });

  const { selectState, resetSelectValue } = useMultiSelectState({
    comboboxState,
    itemById,
    props: { getId, initialItems, controlled, selected, onChange },
  });

  // reset `comboboxState` value when popover is collapsed
  if (!selectState.mounted && comboboxState.value) {
    comboboxState.setValue('');
  }

  // call `onTouched()` when the popover is collapsed
  useWatchValue(
    selectState.mounted,
    useCallback(
      (mounted) => {
        if (!mounted && onTouched) {
          onTouched();
        } else if (mounted && onFocus) {
          onFocus();
        }
      },
      [onTouched, onFocus]),
  );

  // call `onChange()` when the selected value changes
  useWatchValue(
    selectState.value,
    useCallback((value) => {
      if (!controlled && onChange) {
        onChange(map(value, (x) => itemById[x]));
      }
    }, [controlled, itemById, onChange]),
  );

  const { data: filteredItems } = usePromise(useMemo(
    () => filterItems?.(comboboxState.value, items),
    [comboboxState.value, filterItems, items],
  ), []);

  useEffect(() => {
    if (filteredItems) {
      setIds(filteredItems.map(getId));
      setItemById(keyBy(filteredItems, getId));
    }
  }, [filteredItems, getId]);

  const menuFooter = useMemo(() => renderMenuFooter?.(comboboxState, selectState), [renderMenuFooter, comboboxState, selectState]);

  const selectedItems = selected || map(selectState.value, (id) => itemById[id]);

  return (
    <SelectContainer>
      <SelectButtonContainer ref={selectContainerRef} style={selectContainerStyle}>
        <StyledSelect
          state={selectState}
          aria-labelledby={labelId}
          disabled={disabled}
          showOnKeyDown
          toggleOnClick
          style={selectButtonStyle}
        >
          {SelectedItems ? (
            <SelectedItems items={selectedItems} disabled={false} />
          ) : (
            // @ts-expect-error ts(2604) FIXME: JSX element type 'StaticSelectButtonContent' does not have any construct or call signatures.
            <StaticSelectButtonContent />
          )}
        </StyledSelect>
        {!StaticSelectButtonContent && (
          <SelectButtonIcon
            icon={!isEmpty(selectedItems) ? 'close' : 'caret-down'}
            onClick={() => {
              if (!isEmpty(selectedItems)) {
                resetSelectValue();
              } else {
                selectState.show();
              }
            }}
            disabled={disabled}
          />
        )}
      </SelectButtonContainer>

      <StyledSelectPopover
        state={selectState}
        composite={false}
        portal
        style={popoverWidth ? { width: popoverWidth } : undefined}
      >
        <ComboboxWrapper>
          <Icon
            icon="search"
            color="subtext"
            aria-hidden="true"
            sx={{
              position: 'absolute',
              top: '23px',
              left: '20px',
            }}
          />
          <StyledCombobox
            state={comboboxState}
            autoSelect
            placeholder={filterPlaceholder}
            onBlur={(event) => {
              // We call `selectState.hide()` to close the popover when the
              // user moves the focus away by pressing TAB. Without this call,
              // the popover would only get closed on click outside.
              // The conditional around `selectState.hide()` prevents closing
              // of the popover when the user clicks on the scrollbars of the
              // combobox list.
              if (
                event.relatedTarget !== selectState.contentElement &&
                !selectState.contentElement?.contains(event.relatedTarget)
              ) {
                selectState.hide();
              }
            }}
          />
        </ComboboxWrapper>
        {isEmpty(filteredItems) && !comboboxState.value ? null : (
          <>
            <ListWrapper>
              <ComboboxScrollList
                state={comboboxState}
                style={listStyle}
                maxItems={maxItems}
              >
                {isEmpty(filteredItems) ? (
                  <Text color="subtext" pt="18px" pb="24px" px="10px">
                    {emptyFilteredItemsMessage}
                  </Text>
                ) : (
                  filteredItems?.map((item, i) => {
                    const id = getId(item);
                    const disabled = isDisabled(item);

                    return (
                      <StyledComboboxItem
                        key={id + i}
                        focusOnHover
                        disabled={disabled}
                        style={itemWrapperStyle}
                      >
                        {(itemProps: any) => (
                          <Checkbox
                            {...itemProps}
                            as="div"
                            state={selectState}
                            value={id}
                          >
                            <Flex
                              width="100%"
                              alignItems="center"
                              style={{
                                lineHeight: 'inherit',
                                textAlign: 'left',
                              }}
                            >
                              <Box width={21}>
                                {selectState.value.includes(id) && (
                                  <Icon
                                    icon="check"
                                    color="primary"
                                    aria-hidden="true"
                                  />
                                )}
                              </Box>
                              <Item item={item} disabled={disabled} />
                            </Flex>
                          </Checkbox>
                        )}
                      </StyledComboboxItem>
                    );
                  })
                )}
              </ComboboxScrollList>
            </ListWrapper>
            {menuFooter ? (
              <StyledComboboxFooter>{menuFooter}</StyledComboboxFooter>
            ) : null}
          </>
        )}
      </StyledSelectPopover>
    </SelectContainer>
  );
};
