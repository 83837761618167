import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { usePopover } from '@deepstream/ui-kit/elements/popup/usePopover';
import { Modal, ModalBody, ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import { ConfirmationPopover } from '@deepstream/ui-kit/elements/popup/ConfirmationPopover';
import { isEqual, last, sumBy } from 'lodash';
import { useMachine } from '@xstate/react';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import {
  ActionType,
  BidSubmissionExcelSection,
  ExchangeType,
  getTagFromStageId,
  setExchangeReplyFieldValue,
} from '@deepstream/common/rfq-utils';
import { EditableGridDataProvider } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { ExcelFlowTrackingEventType } from '@deepstream/common';
import { assertDefined } from '@deepstream/utils';
import { StepFooter } from '../../StepFooter';
import { downloadBlob } from '../../useDownload';
import { useApi } from '../../api';
import { useRfqId } from '../../useRfq';
import * as rfx from '../../rfx';
import { useCurrentUserLocale } from '../../useCurrentUser';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { ExcelBidFlowActions, excelBidFlowInitialContext, excelBidFlowMachine, ExcelBidMachineContext } from './excelBidFlowMachine';
import { FilesFieldBase } from '../../form/FilesField';
import { useMutation } from '../../useMutation';
import { useOwnDownload } from '../../uploads/useOwnDownload';
import { CheckboxList } from '../../CheckboxList';
import { HorizontalDivider } from '../../ui/HorizontalDivider';
import { ErrorMessage } from '../../form/Field';
import { ExcelBidSubmitLineItemsGrid } from '../../ui/ExchangeDefsGrid/ExcelBidSubmitLineItemsGrid';
import { RequestHooksProvider } from '../Request/RequestHooksProvider';
import { LineItemsExchangeSnapshot } from '../../types';
import { convertToBatchedExchangeReplyPayload, useSendBatchedExchangeReply } from '../../ExchangeModal/useSendBatchedExchangeReply';
import { HelpCenterInlineLink } from '../../HelpCenterLink';
import { CheckboxFieldBase } from '../../form/CheckboxField';
import { useTracking } from '../../useTracking';
import { useToaster } from '../../toast';

export const useActiveStageLineItemsCount = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const stageId = rfx.useStageId({ required: true });

  const exchangeDefsById = rfx.useExchangeDefById();
  const bid = rfx.useBid();
  const { sectionById } = rfx.useStructure();

  return React.useMemo(() =>
      Object.values(exchangeDefsById)
        .filter(def => {
          const { sectionId } = def;

          assertDefined(sectionId, 'sectionId');

          const section = sectionById[sectionId];

          assertDefined(section, 'section');

          if (def.type !== ExchangeType.LINE_ITEM || def.isObsolete) {
            return false;
          }

          if (def.publisherId === currentCompanyId) {
            return false;
          }

          if ('responseTagConfig' in section && section.responseTagConfig) {
            // buyer-provided line items in multi stage response sections can
            // only be submitted in the latest activated stage
            return (
              section.responseTagConfig.tags.includes(getTagFromStageId(stageId)) &&
              stageId === last(bid.activatedStageIds)
            );
          } else {
            return (section.stages! || def.stages).includes(stageId);
          }
        })
        .length,
    [exchangeDefsById, sectionById, currentCompanyId, stageId, bid.activatedStageIds],
  );
};

const REQUEST_NAME_MAX_LENGTH = 30;

export const DownloadBidExcelStep = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const { context, service } = React.useContext(ExcelBidMachineContext);
  const { trackExcelSubmitEvent } = useTracking();
  const stages = rfx.useStages();
  const summary = rfx.useSummary();
  const stageId = rfx.useStageId({ required: true });
  const activeStageId = rfx.useActiveStageId();
  const activeStageIndex = stages.findIndex(stage => stage._id === activeStageId);
  const activeStage = stages[activeStageIndex];
  const activeStageLineItemsCount = useActiveStageLineItemsCount();
  const { rfqId, recipientId, steps } = context;
  const toaster = useToaster();

  const locale = useCurrentUserLocale();
  const api = useApi();
  const hasActiveStage = !!rfx.useActiveStageId();

  const handleDownloadClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    trackExcelSubmitEvent({
      eventType: ExcelFlowTrackingEventType.EXCEL_DOWNLOADED,
      requestId: rfqId,
      requestStageNumber: activeStageIndex + 1,
      requestStageName: activeStage?.name,
      totalLineItems: activeStageLineItemsCount,
    });
    try {
      const downloadedBid = await api.downloadBid({ rfqId, recipientId, stageId, locale });
      const blob = new Blob(
        [downloadedBid.data],
        { type: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' },
      );
      const stageNumber = activeStageIndex + 1;
      const truncatedRequestName = summary.subject && summary.subject.length > 30
        ? `${summary.subject.slice(0, REQUEST_NAME_MAX_LENGTH - 3)}...`
        : summary.subject;
      const fileName = t(
        'excelBid.steps.downloadBidExcel.fileName',
        { requestName: truncatedRequestName, stageNumber, ns: 'request' },
      );
      downloadBlob(blob, `${fileName}.xlsx`);
    } catch (error) {
      toaster.error(t('request.suppliersTable.downloadState.error'));
    }
  };

  return (
    <Flex flexDirection="column" height="100%" justifyContent="space-between">
      <Box
        sx={{
          padding: '16px 16px 0 16px',
        }}
      >
        <Text flex={1}>{t('excelBid.steps.downloadBidExcel.line1', { ns: 'request' })}</Text>
        <Text flex={1}>
          {t('excelBid.steps.downloadBidExcel.toGetStarted', { ns: 'request' })}
          <Button
            type="button"
            iconRight="download"
            variant="wrapper"
            color="primary"
            onClick={handleDownloadClick}
          >
            {t('excelBid.steps.downloadBidExcel.downloadButton', { ns: 'request' })}
          </Button>
        </Text>
        <Text flex={1}>{t('excelBid.steps.downloadBidExcel.followInstructions', { ns: 'request' })}</Text>

        {!hasActiveStage && (
          <MessageBlock variant="warn">
            {t('excelBid.steps.downloadBidExcel.noActiveDeadlineWarning', { ns: 'request' })}
          </MessageBlock>
        )}
      </Box>
      <StepFooter
        steps={steps}
        activeState={steps.downloadBidExcel}
        onContinue={() => {
          service.send(ExcelBidFlowActions.CONTINUE);
        }}
        onBack={() => onClose()}
        backButtonText={t('general.cancel')}
        primaryButtonText={t('general.next')}
        isBackVisible={true}
      />
    </Flex>
  );
};

const XLXS_MIME = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const UploadBidExcelStep = () => {
  const { t } = useTranslation();
  const { context, service } = React.useContext(ExcelBidMachineContext);
  const { rfqId, recipientId, steps } = context;
  const popover = usePopover({
    placement: 'right-start',
    overReferenceElement: true,
  });
  const removeUploadFn = React.useRef<() => void>();
  const api = useApi();

  const [validateAttachment, { isLoading: isValidatingExcelFile }] = useMutation(api.validateBidExcel, {
    onError: () => {
      service.send(ExcelBidFlowActions.SET_FILE_ERROR, { data: t('excelBid.steps.uploadBidExcel.errors.fileNotMatching', { ns: 'request' }) });
    },
  });
  const [download] = useOwnDownload();

  React.useEffect(() => {
    if (!context.excelAttachment) {
      service.send(ExcelBidFlowActions.SET_FILE_ERROR, { data: null });
    }
  }, [context.excelAttachment, service]);

  return (
    <Flex flexDirection="column" height="100%" justifyContent="space-between">
      <Flex
        sx={{
          padding: '16px 16px 0 16px',
          gap: 2,
        }}
        flexDirection="column"
      >
        <Text flex={1}>{t('excelBid.steps.uploadBidExcel.line1', { ns: 'request' })}</Text>
        {/*
         // @ts-expect-error ts(2322) FIXME: Type 'Dispatch<SetStateAction<HTMLElement | undefined>>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'. */}
        <div ref={popover.setReferenceElement}>
          <FilesFieldBase
            label={t('excelBid.steps.uploadBidExcel.excelFile', { ns: 'request' })}
            hideLabel
            purpose="bidUpload"
            max={1}
            value={context.excelAttachment ? [context.excelAttachment] : undefined}
            download={download}
            error={context.excelFileError}
            onChange={async attachments => {
              const attachment = attachments[0];

              if (attachment?.mimetype === XLXS_MIME) {
                validateAttachment({
                  rfqId,
                  recipientId,
                  attachmentId: attachment?._id,
                });
                service.send(ExcelBidFlowActions.SET_FILE, { data: attachment });
              } else if (attachment) {
                service.send(ExcelBidFlowActions.SET_FILE_ERROR, { data: t('excelBid.steps.uploadBidExcel.errors.fileNotExcel', { ns: 'request' }) });
                service.send(ExcelBidFlowActions.SET_FILE, { data: attachment });
              }
            }}
            confirmRemoveFn={async (attachment, removeFn) => {
              removeUploadFn.current = removeFn;
              popover.open();
            }}
            accept=".xls,.xlsx"
          />
        </div>
        <IconText
          icon="info-circle"
          text={t('excelBid.steps.uploadBidExcel.info', { ns: 'request' })}
          fontSize={1}
        />
      </Flex>
      <StepFooter
        steps={steps}
        activeState={steps.uploadBidExcel}
        onContinue={() => {
          service.send(ExcelBidFlowActions.CONTINUE);
        }}
        onBack={() => {
          service.send(ExcelBidFlowActions.BACK);
        }}
        backButtonText={t('back', { ns: 'general' })}
        primaryButtonText={t('general.next')}
        isBackVisible={true}
        isNextDisabled={!!context.excelFileError || !context.excelAttachment || isValidatingExcelFile}
      />

      {popover.isOpen && (
        <ConfirmationPopover
          width="100%"
          maxWidth="265px"
          popover={popover}
          heading={t('excelBid.steps.uploadBidExcel.deleteFileWarning.title', { ns: 'request' })}
          body={<Text mb={2}>{t('excelBid.steps.uploadBidExcel.deleteFileWarning.text', { ns: 'request' })}</Text>}
          okButtonText={t('general.delete')}
          okButtonVariant="danger"
          cancelButtonText={t('review.collaboratorInviteModal.goBackLabel', {
            ns: 'request',
          })}
          onOk={() => {
            removeUploadFn.current?.();
            service.send(ExcelBidFlowActions.SET_FILE, { data: null });
            removeUploadFn.current = undefined;
            popover.close();
          }}
          onCancel={() => {
            popover.close();
            removeUploadFn.current = undefined;
          }}
        />
      )}
    </Flex>
  );
};

export const ReviewSectionMappingStep = () => {
  const { t } = useTranslation();
  const { context, service } = React.useContext(ExcelBidMachineContext);
  const { rfqId, recipientId, steps } = context;

  const api = useApi();

  const [processAttachment, {
    isLoading: isProcessingAttachment,
    status: processingAttachmentStatus,
  }] = useMutation(api.processLineItemsSections, {
    onSuccess(sections: BidSubmissionExcelSection[]) {
      service.send(ExcelBidFlowActions.SET_AVAILABLE_SECTIONS, {
        data: {
          availableSections: sections,
          attachmentId: context.excelAttachment?._id,
        },
      });
    },
  });

  const [validateAttachmentSections] = useMutation(api.processSectionsLineItemsValidation);
  const shouldProcessAttachment = context.excelAttachment?._id && context.excelAttachment._id !== context.processedAttachmentId;
  const attachmentNotProcessed = !['error', 'success'].includes(processingAttachmentStatus) && shouldProcessAttachment;
  const noSectionsAvailable = context.availableSections?.length === 0 && !attachmentNotProcessed;
  const areAllSectionsSelected = context.selectedSections?.length === context.availableSections?.length;
  const areNoSectionsSelected = context.selectedSections?.length === 0;

  React.useEffect(() => {
    if (shouldProcessAttachment) {
      processAttachment({
        rfqId,
        recipientId,
        // @ts-expect-error ts(18049) FIXME: 'context.excelAttachment' is possibly 'null' or 'undefined'.
        attachmentId: context.excelAttachment._id,
      });
    }
  // @ts-expect-error ts(18049) FIXME: 'context.excelAttachment' is possibly 'null' or 'undefined'.
  }, [processAttachment, rfqId, recipientId, shouldProcessAttachment, context.excelAttachment._id]);

  const selectAllSections = () => {
    // @ts-expect-error ts(18048) FIXME: 'context.availableSections' is possibly 'undefined'.
    service.send(ExcelBidFlowActions.SET_SELECTED_SECTIONS, { data: context.availableSections.map(section => section._id) });
  };

  const clearAllSections = () => {
    service.send(ExcelBidFlowActions.SET_SELECTED_SECTIONS, { data: [] });
  };

  const handleContinue = async () => {
    const sectionsValidation = await validateAttachmentSections({
      rfqId,
      recipientId,
      // @ts-expect-error ts(18049) FIXME: 'context.excelAttachment' is possibly 'null' or 'undefined'.
      attachmentId: context.excelAttachment._id,
      sectionsIds: context.selectedSections,
    });

    service.send(ExcelBidFlowActions.SET_SECTIONS_VALIDATION, { data: sectionsValidation });
    service.send(ExcelBidFlowActions.CONTINUE);
  };

  return (
    <Flex flexDirection="column" height="100%" justifyContent="space-between">
      <Flex
        sx={{
          padding: '16px 16px 0 16px',
          gap: 2,
        }}
        flexDirection="column"
      >
        <Text flex={1}>{t('excelBid.steps.reviewSectionMapping.line1', { ns: 'request' })}</Text>
        <HorizontalDivider />
        {attachmentNotProcessed ? null : noSectionsAvailable ? (
          <MessageBlock variant="error">
            {t('excelBid.steps.reviewSectionMapping.errors.noSectionsAvailable', { ns: 'request' })}
          </MessageBlock>
        ) : (
          <>
            <Flex justifyContent="space-between" alignItems="center">
              <Text fontWeight={500}>{t('excelBid.steps.reviewSectionMapping.sectionsSelectedCount', { ns: 'request', selected: context.selectedSections?.length, total: context.availableSections?.length })}</Text>

              <Flex sx={{ gap: 1 }}>
                <Button
                  variant="wrapper"
                  color={areAllSectionsSelected ? 'subtext' : 'primary'}
                  onClick={selectAllSections}
                  disabled={areAllSectionsSelected}
                >
                  {t('excelBid.steps.reviewSectionMapping.selectAll', { ns: 'request' })}
                </Button>
                <Button
                  variant="wrapper"
                  color={areNoSectionsSelected ? 'subtext' : 'primary'}
                  onClick={clearAllSections}
                  disabled={areNoSectionsSelected}
                >
                  {t('excelBid.steps.reviewSectionMapping.clearAll', { ns: 'request' })}
                </Button>
              </Flex>
            </Flex>
            <Box maxHeight="160px" overflowY="auto">
              <CheckboxList
                value={context.selectedSections}
                items={context.availableSections || []}
                onChange={selectedSections => {
                  service.send(ExcelBidFlowActions.SET_SELECTED_SECTIONS, { data: selectedSections });
                }}
                renderItem={(section: BidSubmissionExcelSection) => (
                  <Text fontWeight={400} sx={{ whiteSpace: 'normal' }}>
                    {section.name}{' '}
                    <Text color="subtext" size={1} display="inline">({t('excelBid.steps.reviewSectionMapping.sectionLineItemsCount', { ns: 'request', count: section.lineItemsCount })})</Text>
                  </Text>
                )}
              />
            </Box>
            {!context.selectedSections?.length ? (
              <Box sx={{ wordBreak: 'break-all', textAlign: 'left' }}>
                <ErrorMessage error={t('excelBid.steps.reviewSectionMapping.errors.noSectionsSelected', { ns: 'request' })} fontWeight="normal" />
              </Box>
            ) : null}
          </>
        )}
        <HorizontalDivider />
        <Text flex={1}>{t('excelBid.steps.reviewSectionMapping.line2', { ns: 'request' })}</Text>
      </Flex>
      <StepFooter
        steps={steps}
        activeState={steps.reviewSectionMapping}
        onContinue={handleContinue}
        onBack={() => {
          service.send(ExcelBidFlowActions.BACK);
        }}
        backButtonText={t('back', { ns: 'general' })}
        primaryButtonText={t('general.next')}
        isBackVisible={true}
        isNextDisabled={context.selectedSections.length === 0 || isProcessingAttachment}
      />
    </Flex>
  );
};

export const ReviewInvalidSectionsStep = () => {
  const { t } = useTranslation();
  const { context, service } = React.useContext(ExcelBidMachineContext);
  const { steps, sectionsValidationById } = context;
  const sectionValidationValues = Object.values(sectionsValidationById);
  const invalidLineItemsTotal = sumBy(sectionValidationValues, 'invalidLineItemsCount');
  const validLineItemsTotal = sumBy(sectionValidationValues, 'validLineItemsCount');

  return (
    <Flex flexDirection="column" height="100%" justifyContent="space-between">
      <Flex
        sx={{
          padding: '16px 16px 0 16px',
          gap: 2,
        }}
        flexDirection="column"
      >
        <Text flex={1}>{t('excelBid.steps.reviewInvalidSections.line1', { ns: 'request', count: invalidLineItemsTotal })}</Text>
        <HorizontalDivider />
        {sectionValidationValues.map((validation) => (
          <Flex key={validation.sectionName} flexDirection="row">
            <Text flex={1}>{validation.sectionName}</Text>
            <IconText icon="check-circle" iconColor="success" text={validation.validLineItemsCount} mr={2} />
            <IconText icon="circle-xmark" iconColor="subtext" text={validation.invalidLineItemsCount} />
          </Flex>
        ))}
        <HorizontalDivider />
        <Text flex={1}>{t('excelBid.steps.reviewInvalidSections.line2', { ns: 'request' })}</Text>
        <HelpCenterInlineLink
          path="/en/articles/9620286-understanding-errors-when-submitting-line-items-with-excel"
        >
          {t('excelBid.steps.reviewInvalidSections.readMoreLink', { ns: 'request' })}
        </HelpCenterInlineLink>
      </Flex>
      <StepFooter
        steps={steps}
        activeState={steps.reviewSectionMapping}
        onContinue={() => {
          service.send(ExcelBidFlowActions.CONTINUE);
        }}
        onBack={() => {
          service.send(ExcelBidFlowActions.BACK);
        }}
        backButtonText={t('back', { ns: 'general' })}
        primaryButtonText={t('general.next')}
        isBackVisible={true}
        isNextDisabled={validLineItemsTotal === 0}
      />
    </Flex>
  );
};

export const ReviewImportedSectionData = () => {
  const { t } = useTranslation();
  const { context, service } = React.useContext(ExcelBidMachineContext);
  const { currentSectionIndex, steps, selectedSections, availableSections, rfqId, recipientId,
  exchangeSnapshotBySectionId } = context;
  // @ts-expect-error ts(18048) FIXME: 'availableSections' is possibly 'undefined'.
  const currentSection = availableSections.find(section => section._id === selectedSections[currentSectionIndex]);
  // @ts-expect-error ts(18048) FIXME: 'currentSection' is possibly 'undefined'.
  const currentSectionExchanges = exchangeSnapshotBySectionId[currentSection._id];
  const { sectionById } = rfx.useStructure();
  // @ts-expect-error ts(18048) FIXME: 'currentSection' is possibly 'undefined'.
  const section = sectionById[currentSection._id];
  const locale = useCurrentUserLocale();
  const api = useApi();
  const [isValid, setIsValid] = React.useState(false);

  const [processSectionLineItems, {
    isLoading: isProcessingSection,
  }] = useMutation(api.processSectionLineItems);

  React.useEffect(() => {
    if (currentSection && !currentSectionExchanges) {
      processSectionLineItems({
        rfqId,
        recipientId,
        // @ts-expect-error ts(18049) FIXME: 'context.excelAttachment' is possibly 'null' or 'undefined'.
        attachmentId: context.excelAttachment._id,
        sectionId: currentSection._id,
        locale,
      }).then((data) => {
        if (data.supplierCurrency) {
          service.send(ExcelBidFlowActions.SET_CURRENT_SECTION_SUPPLIER_CURRENCY, { data: data.supplierCurrency });
        }
        service.send(ExcelBidFlowActions.SET_CURRENT_SECTION_EXCHANGES, { data: data.lineItems });
      });
    }
  // @ts-expect-error ts(18049) FIXME: 'context.excelAttachment' is possibly 'null' or 'undefined'.
  }, [locale, processSectionLineItems, currentSection, context.excelAttachment._id, rfqId, recipientId, currentSectionExchanges, service]);

  const onValidChange = React.useCallback((isValid: boolean) => {
    setIsValid(isValid);
  }, []);

  const onExchangesChange = React.useCallback((exchanges: LineItemsExchangeSnapshot[]) => {
    service.send(ExcelBidFlowActions.SET_CURRENT_SECTION_EXCHANGES, { data: exchanges });
  }, [service]);

  return (
    <Flex flexDirection="column" height="100%" justifyContent="space-between">
      <Flex
        sx={{
          padding: '16px 16px 0 16px',
          gap: 2,
        }}
        flexDirection="column"
      >
        {/*
         // @ts-expect-error ts(18048) FIXME: 'currentSection' is possibly 'undefined'. */}
        <IconText icon="list-ul" flex={1} text={currentSection.name} />
        {/*
         // @ts-expect-error ts(18048) FIXME: 'currentSection' is possibly 'undefined'. */}
        <Text>{t('excelBid.steps.reviewImportedSectionData.line1', { ns: 'request', count: currentSection.lineItemsCount })}</Text>
        <HorizontalDivider />

        {currentSectionExchanges?.length ? (
          <rfx.SectionProvider key={section._id} section={section}>
            <RequestHooksProvider showErrors>
              <EditableGridDataProvider
                rowData={currentSectionExchanges}
                setValueInRow={setExchangeReplyFieldValue}
              >
                <GridIdPrefixProvider>
                  <GridMenuStateProvider>
                    <ExcelBidSubmitLineItemsGrid
                      viewportHeightDelta={400}
                      onValidChange={onValidChange}
                      onExchangesChange={onExchangesChange}
                    />
                  </GridMenuStateProvider>
                </GridIdPrefixProvider>
              </EditableGridDataProvider>
            </RequestHooksProvider>
          </rfx.SectionProvider>
        ) : null}
      </Flex>
      <StepFooter
        steps={steps}
        activeState={`reviewImportedSectionData${currentSectionIndex}`}
        currentStepName={t('modalStep.reviewImportedSectionData', {
          ns: 'translation',
          count: currentSectionIndex + 1,
          total: selectedSections.length,
        })}
        onContinue={() => {
          service.send(ExcelBidFlowActions.CONTINUE);
        }}
        onBack={() => {
          service.send(ExcelBidFlowActions.BACK);
        }}
        backButtonText={t('back', { ns: 'general' })}
        primaryButtonText={t('general.next')}
        isBackVisible={true}
        isNextDisabled={isProcessingSection || !isValid}
      />
    </Flex>
  );
};

export const FinalConfirmationStep = () => {
  const { t } = useTranslation();
  const { context, service } = React.useContext(ExcelBidMachineContext);
  const { steps } = context;
  const [confirmed, setConfirmed] = React.useState<boolean | undefined>(undefined);
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const [sendBatchedExchangeReply] = useSendBatchedExchangeReply({
    rfqId: context.rfqId,
    recipientId: context.recipientId,
  });

  const onContinue = () => {
    sendBatchedExchangeReply([
      ...context.selectedSections
        .filter(sectionId => context.supplierCurrencyBySectionId[sectionId])
        .map(sectionId => {
          const { currencyExchangeId, currency } = context.supplierCurrencyBySectionId[sectionId];
          return {
            exchangeId: currencyExchangeId,
            companyId: '',
            action: {
              value: ActionType.SUBMIT,
              currency,
            },
          };
        }),
      ...convertToBatchedExchangeReplyPayload(
        context.selectedSections.flatMap(sectionId => context.exchangeSnapshotBySectionId[sectionId]),
        currentCompanyId,
      ),
    ]);
    service.send(ExcelBidFlowActions.CONTINUE);
  };

  return (
    <Flex flexDirection="column" height="100%" justifyContent="space-between">
      <Box
        sx={{
          padding: '16px 16px 0 16px',
        }}
      >
        <Text flex={1}>{t('excelBid.steps.finalConfirmation.line1', { ns: 'request' })}</Text>
        <Text flex={1}>{t('excelBid.steps.finalConfirmation.line2', { ns: 'request' })}</Text>

        <ul>
          <li>
            <Text>{t('excelBid.steps.finalConfirmation.ul.li1', { ns: 'request' })}</Text>
          </li>
          <li>
            <Text>{t('excelBid.steps.finalConfirmation.ul.li2', { ns: 'request' })}</Text>
          </li>
          <li>
            <Text>{t('excelBid.steps.finalConfirmation.ul.li3', { ns: 'request' })}</Text>
          </li>
        </ul>

        <CheckboxFieldBase
          fieldLabel={t('excelBid.steps.finalConfirmation.confirmation', { ns: 'request' })}
          value={confirmed}
          onChange={() => setConfirmed(prevState => !prevState)}
          error={confirmed || confirmed === undefined ? undefined : t('required', { ns: 'general' })}
        />
      </Box>
      <StepFooter
        steps={steps}
        activeState={steps.finalConfirmation}
        onContinue={() => {
          onContinue();
        }}
        onBack={() => {
          service.send(ExcelBidFlowActions.BACK);
        }}
        backButtonText={t('back', { ns: 'general' })}
        primaryButtonText={t('general.submit')}
        isBackVisible={true}
        isNextDisabled={!confirmed}
      />
    </Flex>
  );
};

export const SubmissionSuccess = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const { context } = React.useContext(ExcelBidMachineContext);
  const { steps, sectionsValidationById, selectedSections } = context;
  const { trackExcelSubmitEvent } = useTracking();
  const rfqId = useRfqId({ required: true });
  const stages = rfx.useStages();
  const activeStageId = rfx.useActiveStageId();
  const activeStageIndex = stages.findIndex(stage => stage._id === activeStageId);
  const activeStage = stages[activeStageIndex];
  const activeStageLineItemsCount = useActiveStageLineItemsCount();
  const sectionValidationValues = Object.values(sectionsValidationById);
  const invalidLineItemsTotal = sumBy(sectionValidationValues, 'invalidLineItemsCount');
  const submittedExchangesTotal = selectedSections.flatMap(sectionId => context.exchangeSnapshotBySectionId[sectionId]).length;

  React.useEffect(() => {
    trackExcelSubmitEvent({
      eventType: ExcelFlowTrackingEventType.LINE_ITEMS_SUBMITTED,
      requestId: rfqId,
      requestStageNumber: activeStageIndex + 1,
      requestStageName: activeStage?.name,
      lineItemsExcluded: invalidLineItemsTotal,
      lineItemsSubmitted: submittedExchangesTotal,
      // TODO: currently we don't have a way to track failed line items, so this stays 0.
      lineItemsFailed: 0,
    });
  }, [
    activeStage?.name,
    activeStageIndex,
    activeStageLineItemsCount,
    invalidLineItemsTotal,
    rfqId,
    submittedExchangesTotal,
    trackExcelSubmitEvent,
  ]);

  return (
    <Flex flexDirection="column" height="100%" justifyContent="space-between">
      <Flex
        sx={{
          padding: '16px 16px 0 16px',
          gap: 2,
        }}
        flexDirection="column"
      >
        <Text flex={1}>{t('excelBid.steps.submissionSummary.line1', { ns: 'request' })}</Text>
        <HorizontalDivider />
        <Box>
          <Flex flexDirection="row">
            <Text flex={1}>{t('excelBid.steps.submissionSummary.submittedSuccessfully', { ns: 'request' })}</Text>
            <IconText icon="check-circle" iconColor="success" text={submittedExchangesTotal} />
          </Flex>
          <Flex flexDirection="row">
            <Text flex={1}>{t('excelBid.steps.submissionSummary.excludedFromImport', { ns: 'request' })}</Text>
            <IconText icon="circle-xmark" iconColor="subtext" text={invalidLineItemsTotal} />
          </Flex>
        </Box>
        <HorizontalDivider />
        <Text flex={1}>{t('excelBid.steps.submissionSummary.line2', { ns: 'request' })}</Text>
        <HelpCenterInlineLink
          path="/en/articles/9620286-understanding-errors-when-submitting-line-items-with-excel"
        >
          {t('excelBid.steps.submissionSummary.readMoreLink', { ns: 'request' })}
        </HelpCenterInlineLink>
      </Flex>
      <StepFooter
        steps={steps}
        activeState={steps.submissionSuccess}
        onContinue={() => {
          onClose();
        }}
        primaryButtonText={t('general.dismiss')}
        isBackVisible={false}
        areStepsVisible={false}
      />
    </Flex>
  );
};

export type ExcelBidBaseModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  withHeaderDivider?: boolean;
  height: string;
  width?: string;
  children?: React.ReactNode;
  needsCloseConfirmation?: () => boolean;
};

export const ExcelBidBaseModal = ({
  isOpen,
  onClose,
  title,
  needsCloseConfirmation = () => false,
  withHeaderDivider = true,
  height,
  width = '700px',
  children,
  ...props
}: ExcelBidBaseModalProps) => {
  const { t } = useTranslation('general');

  const popover = usePopover({
    placement: 'right-start',
    strategy: 'fixed',
    overReferenceElement: true,
  });

  const handleClose = React.useCallback(() => {
    if (needsCloseConfirmation()) {
      if (popover.update) {
        popover.update();
      }
      popover.open();
    } else {
      onClose();
    }
  }, [needsCloseConfirmation, onClose, popover]);

  return (
    <>
      <Modal style={{ content: { width } }} {...props} isOpen={isOpen}>
        {/*
         // @ts-expect-error ts(2322) FIXME: Type 'Dispatch<SetStateAction<HTMLElement | undefined>>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'. */}
        <div ref={popover.setReferenceElement}>
          <ModalHeader
            onClose={handleClose}
            ref={popover.setReferenceElement}
            divider={withHeaderDivider}
          >
            {title}
          </ModalHeader>
          <ModalBody
            height={height}
            maxHeight={height}
            overflowY="auto"
            p={0}
            style={{
              opacity: popover.isOpen ? 0.2 : 1,
              transition: 'opacity 0.4s ease-out',
              ...(popover.isOpen && {
                pointerEvents: 'none',
              }),
            }}
          >
            {children}
          </ModalBody>
        </div>
      </Modal>
      <ConfirmationPopover
        width="100%"
        maxWidth="265px"
        popover={popover}
        heading={t('areYouSure')}
        body={<Text mb={2}>{t('closeAndLoseDetails')}</Text>}
        okButtonText={t('excelBid.cancelImport', { ns: 'request' })}
        okButtonVariant="danger"
        cancelButtonText={t('review.collaboratorInviteModal.goBackLabel', {
          ns: 'request',
        })}
        onOk={onClose}
        onCancel={popover.close}
      />
    </>
  );
};

type ExcelBidFlowModalProps = {
  close: any;
  isOpen: boolean;
  props?: any;
};

export const ExcelBidFlowModal: React.FC<ExcelBidFlowModalProps> = ({
  close,
  isOpen,
  ...props
}) => {
  const { t } = useTranslation();
  const rfqId = useRfqId({ required: true });
  const currentCompanyId = useCurrentCompanyId();
  const { trackExcelSubmitEvent } = useTracking();
  const stages = rfx.useStages();
  const activeStageId = rfx.useActiveStageId();
  const activeStageIndex = stages.findIndex(stage => stage._id === activeStageId);
  const activeStage = stages[activeStageIndex];
  const activeStageLineItemsCount = useActiveStageLineItemsCount();

  const initialMachineContext = React.useMemo(() => {
    return {
      ...excelBidFlowInitialContext,
      rfqId,
      recipientId: currentCompanyId,
    };
  }, [rfqId, currentCompanyId]);

  const [currentState, send, service] = useMachine(
    // @ts-expect-error ts(2345) FIXME: Argument of type '{ rfqId: string; recipientId: string | null; steps: any; excelAttachment?: Attachment | null; excelFileError?: string; availableSections?: BidSubmissionExcelSection[]; ... 5 more ...; currentSectionIndex: number; }' is not assignable to parameter of type 'ExcelBidFlowContext | (() => ExcelBidFlowContext)'.
    React.useMemo(() => excelBidFlowMachine.withContext(initialMachineContext), [initialMachineContext]),
  );

  React.useEffect(() => {
    trackExcelSubmitEvent({
      eventType: ExcelFlowTrackingEventType.FLOW_OPENED,
      requestId: rfqId,
      requestStageNumber: activeStageIndex + 1,
      requestStageName: activeStage?.name,
      totalLineItems: activeStageLineItemsCount,
    });
  }, [activeStage?.name, activeStageIndex, activeStageLineItemsCount, rfqId, trackExcelSubmitEvent]);

  const handleClose = React.useCallback(() => {
    send(ExcelBidFlowActions.RESET_TO_INITIAL_CONTEXT, { data: initialMachineContext });
    close();
  },
    [close, send, initialMachineContext],
  );

  const { context } = currentState;
  const needsCloseConfirmation = React.useCallback(() => {
    return !currentState.matches('submissionSuccess') &&
      !isEqual(context, initialMachineContext) &&
      !!context.excelAttachment;
  }, [context, currentState, initialMachineContext]);

  const excelBidMachineContextValue = React.useMemo(() => {
    return { service, context };
  }, [service, context]);

  return (
    <ExcelBidBaseModal
      isOpen={isOpen}
      title={t('excelBid.title', { ns: 'request' })}
      onClose={handleClose}
      needsCloseConfirmation={needsCloseConfirmation}
      height={currentState.matches('reviewImportedSectionData') ? '100%' : '440px'}
      width={currentState.matches('reviewImportedSectionData') ? '100%' : '700px'}
      {...props}
    >
      <ExcelBidMachineContext.Provider
        value={excelBidMachineContextValue}
      >
        {currentState.matches('downloadExcelFile') ? (
          <DownloadBidExcelStep onClose={handleClose} />
        ) : currentState.matches('uploadBidExcel') ? (
          <UploadBidExcelStep />
        ) : currentState.matches('reviewSectionMapping') ? (
          <ReviewSectionMappingStep />
        ) : currentState.matches('reviewInvalidSections') ? (
          <ReviewInvalidSectionsStep />
        ) : currentState.matches('reviewImportedSectionData') ? (
          <ReviewImportedSectionData />
        ) : currentState.matches('finalConfirmation') ? (
          <FinalConfirmationStep />
        ) : currentState.matches('submissionSuccess') ? (
          <SubmissionSuccess onClose={handleClose} />
        ) : null}
      </ExcelBidMachineContext.Provider>
    </ExcelBidBaseModal>
  );
};
