import { useTranslation } from 'react-i18next';

import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { LoadingPanel } from '../../../ui/Loading';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import { useQuestionnaireTemplate } from './useQuestionnaireTemplate';
import { BasicDetailsPanel } from './BasicDetailsPanel';
import { ExpiryAndRenewalPanel } from './ExpiryAndRenewalPanel';
import { PurposePanel } from './PurposePanel';
import { QuestionnaireTemplateProvider } from './questionnaireTemplateUtils';

export const ActiveDetails = () => {
  const { t } = useTranslation();
  const { data: template, isLoading, isError } = useQuestionnaireTemplate({
    scope: 'current',
  });

  if (isLoading) {
    return <LoadingPanel />;
  }

  if (isError) {
    return <ErrorPanel error={t('errors.unexpected')} />;
  }

  return (
    // @ts-expect-error ts(2322) FIXME: Type 'QuestionnaireTemplate | undefined' is not assignable to type 'QuestionnaireTemplate'.
    <QuestionnaireTemplateProvider template={template}>
      <Stack gap="20px">
        <BasicDetailsPanel />
        <PurposePanel />
        <ExpiryAndRenewalPanel />
      </Stack>
    </QuestionnaireTemplateProvider>
  );
};
