import { useQueryClient, MutationFunction } from 'react-query';
import { useTranslation } from 'react-i18next';
import { callAll } from '@deepstream/utils/callAll';
import { useApi } from '../../api';
import { useToaster } from '../../toast';
import { useMutation } from '../../useMutation';
import { useCurrentUser } from '../../useCurrentUser';

export const useUserMutation = <TResult, TVariables> ({
  fn,
  fieldName,
  onError,
  onSuccess,
}: {
  fn: MutationFunction<TResult, TVariables>;
  fieldName?: string;
  onSuccess?: (...args: any[]) => void;
  onError?: (...args: any[]) => void;
}) => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const user = useCurrentUser();

  return useMutation(
    fn,
    {
      onError: callAll(
        fieldName ? () => toaster.error(t('user.profile.toaster.fieldUpdateError', { fieldName })) : null,
        onError,
      ),
      onSuccess: callAll(
        fieldName ? () => toaster.success(t('user.profile.toaster.fieldUpdateSuccess', { fieldName })) : null,
        onSuccess,
      ),
      onSettled: () => {
        queryClient.invalidateQueries('me');
        queryClient.invalidateQueries(['user', { userId: user._id }]); // for the avatar
      },
    },
  );
};

export type UseUpdateUserProfileProps = {
  fieldName: string;
  onSuccess?: any;
  onError?: any;
};

export const useUpdateUserProfile = ({
  fieldName,
  onSuccess,
  onError,
}: UseUpdateUserProfileProps) => {
  const api = useApi();

  return useUserMutation({
    fn: api.updateUserProfile,
    fieldName,
    onSuccess,
    onError,
  });
};
