import { isBoolean, noop } from 'lodash';
import * as React from 'react';
import { useField } from 'formik';
import { Box } from 'rebass/styled-components';
import { SwitchQuestionElement } from '@deepstream/common/legacy-pre-q-utils';
import { useTranslation } from 'react-i18next';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { Modify, ElementProps, ContainerLabel, LockedAnswer, NoAnswer } from './common';
import { Switch } from '../ui/Switch';
import { ErrorMessage, HelperText } from '../form/Field';

export const SwitchElement: React.FC<Modify<ElementProps, { element: SwitchQuestionElement }>> = ({
  name,
  element,
  answer,
  hideLabel,
  error,
  isReadOnly,
  isLocked,
  disabled,
  // Replaces the form element with anything else
  replacement,
  onChange = noop,
}) => {
  const { t } = useTranslation(['companyProfile', 'general']);
  const id = useUniqueId();
  const [field, , helpers] = useField({ name, type: 'checkbox' });

  return (
    <ContainerLabel
      name={name}
      htmlFor={id}
      label={element.label || (element.labelId ? t(`element.label.${element.labelId}`) : '')}
      hideLabel={hideLabel}
    >
      {isReadOnly ? (
        isBoolean(answer) ? (
          isLocked ? (
            <LockedAnswer />
          ) : (
            <Box>{answer ? t('yes', { ns: 'general' }) : t('no', { ns: 'general' })}</Box>
          )
        ) : (
          <NoAnswer />
        )
      ) : !replacement ? (
        <>
          <Switch
            id={id}
            name={name}
            checked={Boolean(field.checked)}
            onChange={checked => { helpers.setValue(checked); onChange(checked); }}
            disabled={disabled}
            text={element.switchText}
          />
          {error ? (
            <ErrorMessage error={error} />
          ) : element.helperText ? (
            <HelperText text={element.helperText} />
          ) : (
            null
          )}
        </>
      ) : (
        replacement
      )}
    </ContainerLabel>
  );
};
