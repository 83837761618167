import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { DownloadCsvButton } from '@deepstream/ui-kit/elements/button/Button';
import { useApi } from '../../../api';
import { useSession } from '../../../auth';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useToaster } from '../../../toast';
import { getContentDispositionFilename, downloadBlob } from '../../../useDownload';
import { useMutation } from '../../../useMutation';
import { useRfqId } from '../../../useRfq';
import { useCurrentUserLocale } from '../../../useCurrentUser';

const useCSVDownloadReport = () => {
  const { t } = useTranslation('request');
  const api = useApi();
  const toaster = useToaster();
  const locale = useCurrentUserLocale();
  const [getCsvDownloadUrl, { isLoading }] = useMutation(api.getVesselPricingCsvReport);

  const downloadCSVReport = useCallback(async (
    rfqId: string,
    currentCompanyId: string,
    separator?: 'comma' | 'semicolon' | 'space' | 'tab',
  ) => {
    try {
      const response = await getCsvDownloadUrl({
        rfqId,
        currentCompanyId,
        separator,
        locale,
      });
      const filename = getContentDispositionFilename(response);

      downloadBlob(response.data, filename);
    } catch {
      toaster.error(t('vesselPricing.reportDownloadErrorToaster'));
    }
  }, [getCsvDownloadUrl, locale, toaster, t]);

  return [downloadCSVReport, { isLoading }] as const;
};

export const VesselPricingReportPanel = () => {
  const { user } = useSession();
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const [downloadCSV, { isLoading }] = useCSVDownloadReport();

  return (
    <DownloadCsvButton
      variant="primary"
      disabled={isLoading}
      onClick={() => downloadCSV(rfqId, currentCompanyId, user?.preferences?.csvSeparator)}
    />
  );
};
