import { ExchangeType, Live, LotIntentionStatus, getScore, isEvaluationPage, isLinkedEvaluationSection } from '@deepstream/common/rfq-utils';
import { useMemo } from 'react';
import { first, groupBy, isNil, mapValues, pickBy } from 'lodash';
import { useCurrentCompanyId } from '../../currentCompanyId';
import * as rfx from '../../rfx';
import { EvaluationExchangeSnapshot } from '../../types';
import { useCurrentUser } from '../../useCurrentUser';
import { requestBidStatusAllowsScoreSubmissions } from './utils';
import { useRecipientId } from '../../useRfq';

export const useIsEvaluationActionRequiredByPageId = (exchanges: EvaluationExchangeSnapshot[]) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const recipientId = useRecipientId();
  const currentUser = useCurrentUser();
  const structure = rfx.useStructure<Live>();
  const permissionsByPageId = rfx.usePagesPermissions();

  return useMemo(() => {
    const bid = structure.bidById[recipientId];
    // @ts-expect-error ts(2345) FIXME: Argument of type 'BidStatus | null' is not assignable to parameter of type 'BidStatus'.
    const canSubmitScore = requestBidStatusAllowsScoreSubmissions(bid.status);

    const evaluationPageById = pickBy(structure.pageById, isEvaluationPage);

    const nonObsoleteEvaluationExchanges = exchanges.filter(exchange =>
      exchange.def.type === ExchangeType.EVALUATION_CRITERION &&
      !exchange.isObsolete,
    ) as EvaluationExchangeSnapshot[];

    const evaluationExchangeBySectionId = groupBy(
      nonObsoleteEvaluationExchanges,
      exchange => exchange.def.sectionId,
    );

    return mapValues(
      evaluationPageById,
      (page, pageId) => {
        if (!canSubmitScore) {
          return false;
        }

        const isEvaluator = permissionsByPageId[pageId].canRespond;

        if (!isEvaluator) {
          return false;
        }

        const sectionIds = page.sections;

        return sectionIds.some(sectionId => {
          const section = structure.sectionById[sectionId];
          const originalSection = isLinkedEvaluationSection(section)
            ? structure.sectionById[section.linkedSectionId]
            : section;
          const lotId = first(originalSection.lotIds);

          if (lotId && bid.intentionStatusByLotId[lotId] !== LotIntentionStatus.BIDDING) {
            return false;
          }

          const sectionExchanges = evaluationExchangeBySectionId[sectionId] || [];

          return sectionExchanges.some(exchange => {
            // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
            if (exchange.isObsolete || !sectionIds.includes(exchange.def.sectionId)) {
              return false;
            }

            const score = getScore(exchange, { companyId: currentCompanyId, userId: currentUser._id });

            return isNil(score);
          });
        });
      },
    );
  }, [structure, recipientId, permissionsByPageId, exchanges, currentCompanyId, currentUser._id]);
};
