import { get } from 'lodash';
import { Box } from 'rebass/styled-components';
import { CellProps } from 'react-table';
import { requestStatusesConfig } from '@deepstream/common/rfq-utils/statusConfigs';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { ExtendedSentRequestOverview } from './types';

export const SentRequestStatus = ({ row }:
  CellProps<ExtendedSentRequestOverview>,
) => {
  const value = get(row.original, 'meta.extendedStatus');

  const icon = requestStatusesConfig[value]?.icon;

  return icon ? (
    <Box alignSelf="flex-start" flex="0 0 auto" mr="8px" mt="2px">
      <Icon fixedWidth color={icon.color} icon={icon.value} />
    </Box>
  ) : (
    null
  );
};
