import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { QuestionnaireTemplateOverview } from '@deepstream/common/preQual';
import { withProps } from '@deepstream/ui-utils/withProps';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { DeleteMenuItem, DropdownMenu } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { BasicTableStyles } from '../../../TableStyles';
import { Table } from '../../../Table';
import { FieldsCell, MIN_CELL_HEIGHT } from '../../../FieldsCell';
import { QuestionnaireTemplateStatusIcon } from './QuestionnaireTemplateStatus';
import { useUserFlags } from '../../../UserFlagsContext';
import { useDraftQuestionnaireTemplateNavigation } from '../../../appNavigation';

type ActionButtonsProps = CellProps<QuestionnaireTemplateOverview> & {
  openDeleteModal: (template: QuestionnaireTemplateOverview) => void;
  onExpandedStateChange?: (isExpanded: boolean) => void;
};

const ActionButtons = ({
  row,
  openDeleteModal,
  onExpandedStateChange,
}: ActionButtonsProps) => {
  const { hasManagePreQualPermission } = useUserFlags();

  const template = row.original;

  return hasManagePreQualPermission ? (
    <DropdownMenu
      small
      variant="primary-outline"
      iconLeft="ellipsis-h"
      menuStyle={{ width: '185px' }}
      menuZIndex={10}
      onExpandedStateChange={onExpandedStateChange}
      onClick={stopPropagation}
    >
      <DeleteMenuItem onSelect={() => openDeleteModal(template)} />
    </DropdownMenu>
  ) : (
    null
  );
};

export const DraftQuestionnaireTemplatesTable = ({
  openDeleteModal,
}: {
  openDeleteModal: (questionnaire: QuestionnaireTemplateOverview) => void;
}) => {
  const { t } = useTranslation('preQualification');

  const draftTemplateNavigation = useDraftQuestionnaireTemplateNavigation();
  const onRowClick = useCallback((row) => {
    const id = row._id;

    draftTemplateNavigation.navigateToQuestions(id);
  }, [draftTemplateNavigation]);

  const columns = useMemo(
    () => [{ id: 'custom' }],
    [],
  );

  const CustomRowCells = useMemo(
    () => {
      const fields = [
        {
          label: t('numberOfQuestions'),
          accessor: 'numQuestions',
        },
      ];

      return withProps(
        FieldsCell,
        {
          fields,
          titleAccessor: 'name',
          defaultTitle: t('untitledTemplate'),
          RowIconComponent: QuestionnaireTemplateStatusIcon,
          actionColumnWidth: 100,
          ActionsComponent: withProps(
            ActionButtons,
            {
              openDeleteModal,
            },
          ),
        },
      );
    },
    [t, openDeleteModal],
  );

  return (
    <BasicTableStyles hoverBackgroundColor="lightGray6">
      <Table
        columns={columns}
        isPaginated
        pageSizes={[5, 10, 20, 50, 100]}
        hideHeader
        CustomRowCells={CustomRowCells}
        hasStaticHeight={false}
        enforcePagination
        minCellHeight={MIN_CELL_HEIGHT}
        smallPageControls
        onRowClick={onRowClick}
      />
    </BasicTableStyles>
  );
};
