import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { HistoryInviteStatus } from '@deepstream/common/company';
import { withProps } from '@deepstream/ui-utils/withProps';
import { StaticTableStyles } from './TableStyles';
import { Table } from './Table';
import { DatetimeCell } from './DatetimeCell';
import { TruncateCell } from './TruncateCell';
import { FilterSelect, filterMultipleValues } from './FilterSelect';

type InvitesTableProps = {
  invites: any[];
};

export const SentInvitesTable: React.FC<InvitesTableProps> = ({ invites }) => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        id: 'company',
        Header: t('general.company'),
        accessor: 'inviteeCompany',
        Cell: TruncateCell,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
        width: '20%',
      },
      {
        id: 'email',
        Header: t('general.email'),
        accessor: 'email',
        Cell: TruncateCell,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
        width: 140,
      },
      {
        id: 'invitedBy',
        Header: t('invite.invitedBy'),
        accessor: 'invitedBy',
        Cell: TruncateCell,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
        width: '15%',
      },
      {
        id: 'invitedOn',
        Header: t('invite.invitedOn'),
        accessor: 'invitedOn',
        Cell: DatetimeCell,
        onlyDate: true,
        sort: 'datetime',
        width: '15%',
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
      },
      {
        id: 'status',
        Header: t('general.status'),
        accessor: (item => {
          return item.status === HistoryInviteStatus.CONFIRMED
            ? t('invite.status.confirmed')
            : t('invite.status.invited');
        }),
        Cell: TruncateCell,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
        width: 90,
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ],
    [t],
  );

  return (
    <StaticTableStyles>
      <Table
        isPaginated
        initialPageSize={10}
        columns={columns}
        data={invites}
        isSortable
        noFilteredDataPlaceholder={t('requests.filtering.noRequestsMatchChosenFilters')}
      />
    </StaticTableStyles>
  );
};
