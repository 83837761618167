import { useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { v4 as uuid } from 'uuid';
import { Box, Flex, Text } from 'rebass/styled-components';
import { isEmpty, isFinite, keys, map, toUpper, values } from 'lodash';
import { Form, Formik, useField } from 'formik';
import {
  EXPIRY_DATE_MILESTONE, ExpiryDateType, OffsetType, Reminder, ReminderRecipientCategory, ReminderType, START_DATE_MILESTONE,
} from '@deepstream/common/contract';
import { TimeUnit } from '@deepstream/common/rfq-utils';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { Modal, ModalHeader, ModalBody, ModalFooter, SaveButton, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { TextField } from '../../form/TextField';
import { SwitchField } from '../../form/SwitchField';
import { DatetimeField } from '../../form/DatetimeField';
import { LabelConfig, LabelConfigProvider } from '../../LabelConfigProvider';
import { RadioField } from '../../form/RadioField';
import { getItemLabelWithDescription, getItemValue, SelectField } from '../../form/SelectField';
import { useContractData, useContractState } from './contract';
import { FieldContainer } from '../../form/FieldContainer';
import { CheckboxFieldArray } from '../../form/CheckboxField';
import { MultiSelectField } from '../../form/MultiSelectField';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { NameArray } from '../../NameArray';

const LABEL_WIDTH = '190px';

const labelStyle = {
  fontSize: 2,
};

const labelConfigStyle = {
  name: {
    ...labelStyle,
    position: 'relative',
    top: '9px',
  },
  type: labelStyle,
  date: labelStyle,
  isRecurring: labelStyle,
  note: labelStyle,
  recipientCategories: labelStyle,
};

const defaultReminder = {
  name: '',
  type: ReminderType.MILESTONE,
  milestoneId: START_DATE_MILESTONE,
  offsetType: OffsetType.BEFORE,
  amount: null,
  unit: TimeUnit.DAYS,
  note: '',
  recipientCategories: [],
  recipientUserIds: [],
};

const ConfigForm = () => {
  const { t } = useTranslation(['contracts', 'general']);
  const [{ value: reminderType }] = useField('type');
  const [{ value: offsetType },, offsetTypeHelper] = useField('offsetType');
  const [{ value: isRecurring },, isRecurringHelper] = useField('isRecurring');
  const [,, milestoneIdHelper] = useField('milestoneId');
  const [,, unitHelper] = useField('unit');
  const [,, amountHelper] = useField('amount');
  const [,, dateHelper] = useField('date');
  const contract = useContractData();
  // @ts-expect-error ts(2339) FIXME: Property 'isTemplate' does not exist on type 'ContractStateContextType | undefined'.
  const { isTemplate } = useContractState();

  const hasNoExpiryDate = contract.summary.expiryDateType === ExpiryDateType.NO_EXPIRY_DATE;

  const reminderTypeOptions = useMemo(
    () => [
      { value: ReminderType.MILESTONE, label: t(`summary.reminderType.${ReminderType.MILESTONE}`) },
      { value: ReminderType.EXACT_DATE, label: t(`summary.reminderType.${ReminderType.EXACT_DATE}`) },
    ],
    [t],
  );

  const offsetTypeItems = useMemo(
    () => [
      { value: OffsetType.BEFORE, label: t(`summary.offsetType.${OffsetType.BEFORE}`) },
      { value: OffsetType.ON, label: t(`summary.offsetType.${OffsetType.ON}`) },
      { value: OffsetType.AFTER, label: t(`summary.offsetType.${OffsetType.AFTER}`) },
    ],
    [t],
  );

  const milestoneItems = useMemo(
    () => [
      { value: START_DATE_MILESTONE, label: t('summary.startDate') },
      { value: EXPIRY_DATE_MILESTONE, label: t('summary.expiryDate'), disabled: hasNoExpiryDate },
      ...contract.customMilestones.map(milestone => ({
        value: milestone._id,
        label: milestone.name,
      })),
    ],
    [contract, hasNoExpiryDate, t],
  );

  const timeUnitItems = useMemo(
    () => [
      { value: TimeUnit.DAYS, label: t(`timeUnit.${TimeUnit.DAYS}`, { ns: 'general' }) },
      { value: TimeUnit.MONTHS, label: t(`timeUnit.${TimeUnit.MONTHS}`, { ns: 'general' }) },
      { value: TimeUnit.YEARS, label: t(`timeUnit.${TimeUnit.YEARS}`, { ns: 'general' }) },
    ],
    [t],
  );

  const onTypeChange = useCallback(
    (newType: ReminderType) => {
      if (newType === ReminderType.EXACT_DATE) {
        // Reset previous MilestoneReminder fields
        offsetTypeHelper.setValue(undefined);
        milestoneIdHelper.setValue(undefined);
        amountHelper.setValue(undefined);
        unitHelper.setValue(undefined);

        // Set ExactDateReminder defaults
        isRecurringHelper.setValue(false);
      } else {
        // Reset previous ExactDateReminder fields
        dateHelper.setValue(undefined);
        dateHelper.setTouched(false);
        isRecurringHelper.setValue(undefined);
        amountHelper.setValue(undefined);
        amountHelper.setTouched(false);

        // Set MilestoneReminder defaults
        offsetTypeHelper.setValue(OffsetType.BEFORE);
        milestoneIdHelper.setValue(START_DATE_MILESTONE);
        unitHelper.setValue(TimeUnit.DAYS);
      }
    },
    [offsetTypeHelper, milestoneIdHelper, isRecurringHelper, amountHelper, unitHelper, dateHelper],
  );

  const onIsRecurringChange = useCallback(
    isRecurring => {
      if (isRecurring) {
        // Set defaults
        unitHelper.setValue(TimeUnit.DAYS);
      } else {
        // Reset fields
        amountHelper.setValue(undefined);
        amountHelper.setTouched(false);
        unitHelper.setValue(undefined);
      }
    },
    [amountHelper, unitHelper],
  );

  const onOffsetTypeChange = useCallback(
    (newOffsetType) => {
      // The `onChange` prop for `SelectField` replaces the default behavior (which is to
      // set the formik field value), so we need to set it here.
      offsetTypeHelper.setValue(newOffsetType);

      if (newOffsetType === OffsetType.ON) {
        // Reset fields
        amountHelper.setValue(undefined);
        amountHelper.setTouched(false);
        unitHelper.setValue(undefined);
      } else if (offsetType === OffsetType.ON) {
        // Set defaults
        unitHelper.setValue(TimeUnit.DAYS);
      }
    },
    [amountHelper, unitHelper, offsetType, offsetTypeHelper],
  );

  return (
    <>
      <TextField
        name="name"
        label={t('name', { ns: 'general' })}
        required
        // quick fix: we can remove setting the inputStyle height once a line height is
        // set for Input
        inputStyle={{ height: 40 }}
      />
      <Box mx="-16px">
        <PanelDivider />
      </Box>
      {!isTemplate && (
        <RadioField
          name="type"
          label={t('summary.when')}
          description={t('summary.whenDescription')}
          required
          options={reminderTypeOptions}
          gap={1}
          onChange={onTypeChange}
        />
      )}
      {reminderType === ReminderType.MILESTONE ? (
        <FieldContainer
          label=" "
          {...isTemplate && {
            showAsterisk: true,
            label: t('summary.when'),
            description: t('summary.whenTemplateDescription'),
            labelStyle: { fontSize: 2, fontWeight: 500 },
          }}
        >
          <Flex sx={{ gap: 2 }}>
            <SelectField
              name="offsetType"
              items={offsetTypeItems}
              menuWidth="90px"
              buttonStyle={{ width: '90px' }}
              onChange={onOffsetTypeChange}
            />
            <SelectField
              name="milestoneId"
              items={milestoneItems}
              menuWidth={milestoneItems.length > 2 ? '270px' : '225px'}
              buttonStyle={{ width: '225px' }}
            />
          </Flex>
          {offsetType !== OffsetType.ON && (
            <Flex sx={{ gap: 2 }} mt={2}>
              <Box>
                <SelectField
                  name="unit"
                  items={timeUnitItems}
                  menuWidth="90px"
                  buttonStyle={{ width: '90px' }}
                />
              </Box>
              <Box>
                <TextField
                  name="amount"
                  format="integer.positive"
                  // quick fix: we can remove setting the inputStyle height once a line height is
                  // set for Input
                  inputStyle={{ height: 40, width: '70px' }}
                />
              </Box>
            </Flex>
          )}
        </FieldContainer>
      ) : (
        <>
          <FieldContainer label=" ">
            <DatetimeField
              name="date"
              hasTime
              dateFormat="dd MMM yyyy h:mm a"
              min={new Date()}
              popperModifiers={{
                preventOverflow: {
                  boundariesElement: 'viewport',
                },
              }}
            />
          </FieldContainer>
          <Box mx="-16px">
            <PanelDivider />
          </Box>
          <SwitchField
            name="isRecurring"
            label={t('summary.recurrence')}
            useDefaultLabelConfig={false}
            checkedText={t('summary.repeats')}
            uncheckedText={t('summary.doesNotRepeat')}
            checkedIcon={false}
            uncheckedIcon={false}
            width={42}
            onChange={onIsRecurringChange}
          />
          {isRecurring && (
            <FieldContainer label=" ">
              <Flex sx={{ gap: 2 }}>
                <Text sx={{ position: 'relative', top: '9px' }}>
                  {t('every', { ns: 'general' })}
                </Text>
                <Box>
                  <TextField
                    name="amount"
                    format="integer.positive"
                    // quick fix: we can remove setting the inputStyle height once a line height is
                    // set for Input
                    inputStyle={{ height: 40, width: '70px' }}
                  />
                </Box>
                <SelectField
                  name="unit"
                  items={timeUnitItems}
                  menuWidth="90px"
                  buttonStyle={{ width: '90px' }}
                />
              </Flex>
            </FieldContainer>
          )}
        </>
      )}
      <Box mx="-16px">
        <PanelDivider />
      </Box>
      <TextField
        name="note"
        label={t('summary.notes')}
        isMultiLine
        rows={3}
      />
    </>
  );
};

const SelectAllCheckbox = () => {
  const { t } = useTranslation('contracts');
  const contract = useContractData();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const [{ value: recipientUserIds },, helper] = useField('recipientUserIds');

  const team = contract.teamById[currentCompanyId];
  const allUserIds = keys(team.users);

  const onChange = useCallback(
    (event) => {
      if (event.target.checked) {
        helper.setValue(allUserIds);
      } else {
        helper.setValue([]);
      }
    },
    [helper, allUserIds],
  );

  return (
    <Box p={12}>
      <Checkbox
        checked={recipientUserIds.length === allUserIds.length}
        onChange={onChange}
        label={(
          <Text color="subtext" fontSize={1}>
            {toUpper(t('teamMember_other'))}
          </Text>
        )}
      />
    </Box>
  );
};

type UserItem = {
  value: string;
  label: string;
  description: string;
};

const RecipientsForm = () => {
  const { t } = useTranslation(['contracts', 'general']);
  const [{ value: recipientCategories }] = useField('recipientCategories');
  const [,, recipientUserIdsHelper] = useField('recipientUserIds');
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contract = useContractData();
  // @ts-expect-error ts(2339) FIXME: Property 'isTemplate' does not exist on type 'ContractStateContextType | undefined'.
  const { isTemplate } = useContractState();

  const options = useMemo(
    () => [
      {
        value: ReminderRecipientCategory.COUNTER_PARTY,
        label: (
          <Box>
            <Text mb={1}>
              {t(`summary.recipientCategory.${ReminderRecipientCategory.COUNTER_PARTY}`)}
            </Text>
            <Text color="subtext" fontSize={1}>
              {t(`summary.recipientCategoryDescription.${ReminderRecipientCategory.COUNTER_PARTY}`)}
            </Text>
          </Box>
        ),
      },
      {
        value: ReminderRecipientCategory.INTERNAL,
        label: (
          <Box>
            <Text mb={1}>
              {t(`summary.recipientCategory.${ReminderRecipientCategory.INTERNAL}`)}
            </Text>
            <Text color="subtext" fontSize={1}>
              {t(`summary.recipientCategoryDescription.${ReminderRecipientCategory.INTERNAL}`)}
            </Text>
          </Box>
        ),
      },
    ],
    [t],
  );

  // @ts-expect-error ts(2322) FIXME: Type '{ value: string; label: string; description: string | undefined; }[]' is not assignable to type 'UserItem[]'.
  const userItems: UserItem[] = useMemo(
    () => {
      const team = contract.teamById[currentCompanyId];

      return map(
        values(team.users),
        user => ({
          value: user._id,
          label: user.name,
          description: user.email,
        }),
      );
    },
    [contract, currentCompanyId],
  );

  const getUserSelectButtonText = useCallback(
    (selectedItems: UserItem[]) => (
      <Box flex={1} textAlign="left" fontWeight={400}>
        {isEmpty(selectedItems) ? (
          t('noUsersSelected', { ns: 'general' })
        ) : selectedItems.length === userItems.length ? (
          t('summary.allUsersSelected')
        ) : (
          <NameArray entities={map(selectedItems, item => item.label)} />
        )}
      </Box>
    ),
    [userItems, t],
  );

  const onRecipientCategoryChange = useCallback(
    (event) => {
      if (event.target.getAttribute('data-value') === ReminderRecipientCategory.INTERNAL) {
        if (!event.target.checked) {
          recipientUserIdsHelper.setValue([]);
        } else if (!recipientCategories.includes(ReminderRecipientCategory.INTERNAL)) {
          const allUserIds = keys(contract.teamById[currentCompanyId].users);
          recipientUserIdsHelper.setValue(allUserIds);
        }
      }
    },
    [contract, recipientUserIdsHelper, recipientCategories, currentCompanyId],
  );

  return (
    <>
      <CheckboxFieldArray
        name="recipientCategories"
        label={t('recipient_other')}
        required
        options={options}
        flexDirection="column"
        onChange={onRecipientCategoryChange}
      />
      {recipientCategories.includes(ReminderRecipientCategory.INTERNAL) && !isTemplate && (
        <MultiSelectField
          name="recipientUserIds"
          label=" "
          variant="secondary-outline"
          getButtonText={getUserSelectButtonText}
          items={userItems}
          itemToString={getItemValue}
          renderItem={getItemLabelWithDescription}
          withSelectButton
          buttonWidth="100%"
          menuZIndex={202}
          menuWidth={324}
          itemHeight={50}
          alwaysShowCaret
          header={<SelectAllCheckbox />}
        />
      )}
    </>
  );
};

enum Step {
  CONFIG = 'config',
  RECIPIENTS = 'recipients',
}

export const ReminderModal = ({
  reminder,
  isOpen,
  close,
  onSave,
  onDelete,
}: {
  reminder?: Reminder;
  isOpen: boolean;
  close: () => void;
  onSave: (reminder: Reminder) => void;
  onDelete?: () => void;
}) => {
  const { t } = useTranslation(['contracts', 'general']);
  const [step, setStep] = useState<Step>(Step.CONFIG);
  // @ts-expect-error ts(2339) FIXME: Property 'isTemplate' does not exist on type 'ContractStateContextType | undefined'.
  const { isTemplate } = useContractState();

  const onSubmit = useCallback(
    values => {
      onSave({
        _id: reminder ? reminder._id : uuid(),
        ...values,
      });
      close();
    },
    [reminder, onSave, close],
  );

  const isEditingReminder = Boolean(reminder);

  return (
    <LabelConfigProvider
      variant={LabelConfig.LEFT}
      width={LABEL_WIDTH}
      style={labelConfigStyle}
    >
      <Modal isOpen={isOpen} style={{ content: { maxWidth: '550px', minWidth: '550px' } }}>
        <Formik
          initialValues={reminder || defaultReminder}
          validationSchema={
            yup.object().shape({
              name: yup.string().required(t('required', { ns: 'general' })),
              type: yup.string().oneOf(values(ReminderType)).required(),
              note: yup.string(),
              offsetType: yup.string().oneOf(values(OffsetType)).when('type', {
                is: ReminderType.MILESTONE,
                then: schema => schema.required(),
                otherwise: schema => schema.nullable(),
              }),
              milestoneId: yup.string().when('type', {
                is: ReminderType.MILESTONE,
                then: schema => schema.required(),
                otherwise: schema => schema.nullable(),
              }),
              unit: yup
                .string()
                .oneOf([TimeUnit.DAYS, TimeUnit.MONTHS, TimeUnit.YEARS])
                .when(['type', 'offsetType', 'isRecurring'], {
                  is: (type, offsetType, isRecurring) => type === ReminderType.MILESTONE
                    ? offsetType !== OffsetType.ON
                    : isRecurring,
                  then: schema => schema.required(),
                  otherwise: schema => schema.nullable(),
                }),
              amount: yup
                .number()
                .transform(value => isFinite(value) ? value : undefined)
                .when(['type', 'offsetType', 'isRecurring'], {
                  is: (type, offsetType, isRecurring) => type === ReminderType.MILESTONE
                    ? offsetType !== OffsetType.ON
                    : isRecurring,
                  then: schema => schema
                    .min(1, t('errors.min1_short'))
                    .required(t('required', { ns: 'general' })),
                  otherwise: schema => schema.nullable(),
                }),
              date: yup.date().when('type', {
                is: type => type === ReminderType.EXACT_DATE,
                then: schema => schema.required(t('required', { ns: 'general' })),
                otherwise: schema => schema.nullable(),
              }),
              isRecurring: yup.boolean().when('type', {
                is: ReminderType.EXACT_DATE,
                then: schema => schema.required(),
                otherwise: schema => schema.nullable(),
              }),
              recipientCategories: step === Step.RECIPIENTS
                ? yup
                  .array()
                  .of(yup.string().oneOf(values(ReminderRecipientCategory)))
                  .required(t('required', { ns: 'general' }))
                : yup.array(),
              recipientUserIds: step === Step.RECIPIENTS && !isTemplate
                ? yup
                  .array()
                  .of(yup.string())
                  .when('recipientCategories', {
                    is: recipientCategories =>
                      recipientCategories.includes(ReminderRecipientCategory.INTERNAL),
                    then: schema => schema.min(1, t('required', { ns: 'general' })),
                  })
                : yup.array(),
            })
          }
          validateOnBlur
          validateOnMount
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <ModalHeader onClose={close}>
                {isEditingReminder ? t('summary.editReminder') : t('summary.createReminder')}
              </ModalHeader>
              <ModalBody>
                <Stack gap={3}>
                  {step === Step.CONFIG ? (
                    <ConfigForm />
                  ) : (
                    <RecipientsForm />
                  )}
                </Stack>
              </ModalBody>
              <ModalFooter justifyContent="space-between">
                <Flex>
                  {reminder && (
                    <Button
                      type="button"
                      variant="danger"
                      onClick={() => {
                        // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
                        onDelete();
                        close();
                      }}
                    >
                      {t('remove', { ns: 'general' })}
                    </Button>
                  )}
                </Flex>
                <Flex>
                  {step === Step.CONFIG ? (
                    <CancelButton onClick={close} />
                  ) : (
                    <Button type="button" variant="secondary" onClick={() => setStep(Step.CONFIG)} mr={2}>
                      {t('back', { ns: 'general' })}
                    </Button>
                  )}
                  {step === Step.CONFIG ? (
                    <Button
                      type="button"
                      variant="primary"
                      onClick={() => setStep(Step.RECIPIENTS)}
                      disabled={isSubmitting || !isValid}
                    >
                      {t('next', { ns: 'general' })}
                    </Button>
                  ) : (
                    <SaveButton
                      label={isEditingReminder
                        ? t('saveChanges', { ns: 'general' })
                        : t('create', { ns: 'general' })}
                      disabled={isSubmitting || !isValid}
                    />
                  )}
                </Flex>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </LabelConfigProvider>
  );
};
