import * as React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, SaveButton, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { useToaster } from '../../../toast';
import { useApi } from '../../../api';
import { useMutation } from '../../../useMutation';
import { TextField } from '../../../form/TextField';

type EditModalProps =
  { company: any; onCancel: any; onSave: any } &
  ModalProps;

export const EditDescriptionModal: React.FC<EditModalProps> = ({ company, onCancel, onSave, ...props }) => {
  const { t } = useTranslation(['companyProfile', 'general']);
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const [updateCompany] = useMutation(
    api.updateCompany,
    {
      onSettled: () => queryClient.invalidateQueries(['publicCompany', { companyId: company._id }]),
    },
  );

  return (
    <Modal onRequestClose={onCancel} {...props}>
      <Formik
        initialValues={{
          statement: company.statement,
        }}
        validationSchema={
          yup.object().shape({
            statement: yup.string(),
          })
        }
        onSubmit={async ({ statement }, { setSubmitting }) => {
          try {
            await updateCompany({
              companyId: company._id,
              companyPatch: {
                statement,
              },
            });
            toaster.success(t('toaster.updateProfileSuccess'));
            setSubmitting(false);
            onSave();
          } catch (error) {
            toaster.error(t('toaster.updateProfileSuccess'));
            setSubmitting(false);
            throw error;
          }
        }}
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              {t('description', { ns: 'general' })}
            </ModalHeader>
            <ModalBody>
              <TextField isMultiLine name="statement" label={t('description', { ns: 'general' })} rows={9} />
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} />
              <SaveButton disabled={isSubmitting || !dirty} />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
