import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueries } from 'react-query';
import { Box, Flex, Text } from 'rebass/styled-components';

import { CancelButton, Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { Modal, ModalHeader, ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { UnmappedExternalSystemCompanies } from './CompaniesDetails';
import { wrap, useApi } from './api';
import { useCurrentCompanyId } from './currentCompanyId';
import { Loading } from './ui/Loading';
import { useNavigate } from './tanstackRouter';
import { companySettingsRoute } from './AppRouting';

type DialogProps = ModalProps & {
  heading: string
  body: React.ReactNode;
  onOk: () => void;
  onCancel?: () => void;
};

type UnmappedSuppliersWarningDialogProps = Omit<DialogProps, 'heading' | 'body'> & {
  externalSystem: { name: string; systemId: string; companyId: string };
  companyIds: string[];
  isAdmin: boolean;
};

export const UnmappedSuppliersWarningDialog = ({
  externalSystem,
  companyIds = [],
  isAdmin,
  ...props
}: UnmappedSuppliersWarningDialogProps) => {
  const { t } = useTranslation();
  const api = useApi();
  const navigate = useNavigate();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const queryData = useQueries(
    companyIds.map(id => ({
      queryKey: ['companyData', { companyId: id }],
      queryFn: wrap(api.getPublicCompany),
    })),
  );

  const { onCancel, onOk } = props;
  // TODO check: the only effect of calling setDisabled would be a new render
  // -- is that what we want to achieve here of can we just remove this state hook?
  const [, setDisabled] = React.useState<boolean>(false);

  const handleOk = React.useCallback(
    async () => {
      try {
        setDisabled(true);
        try {
          await onOk();
        } catch (_) {
          // ignore
        }
      } finally {
        setDisabled(false);
      }
    },
    [onOk],
  );

  return (
    <Modal onRequestClose={onCancel} contentLabel={t('unmappedSuppliersWarning', { ns: 'integration' })} {...props}>
      <ModalHeader onClose={onCancel}>
        {t('unmappedSuppliersWarning', { ns: 'integration' })}
      </ModalHeader>
      <Text p={3} fontSize={2}>
        <>
          {queryData.every(data => data.isSuccess) ? (
            <UnmappedExternalSystemCompanies
              externalSystemName={externalSystem.name}
              companies={queryData.map(data => data.data)}
            />
          ) : (
            <Loading />
          )}
          <MessageBlock variant="warn" my={3}>
            {t(isAdmin ? 'unmappedSuppliersWarningText' : 'unmappedSuppliersWarningTextNonAdmin', { ns: 'integration' })}
          </MessageBlock>
        </>
      </Text>
      <PanelDivider />
      {isAdmin ? (
        <Flex p={3} justifyContent="space-between">
          <CancelButton
            onClick={onCancel}
          >
            {t('general.cancel')}
          </CancelButton>
          <Box>
            <Button
              type="button"
              variant="secondary"
              onClick={handleOk}
              style={{ minWidth: 60 }}
              mr={2}
            >
              {t('request.awardRequest')}
            </Button>
            <Button
              variant="primary"
              onClick={() => navigate({ to: companySettingsRoute.to, params: { currentCompanyId } })}
              type="button"
            >
              {t('openSupplierMap', { ns: 'integration' })}
            </Button>
          </Box>
        </Flex>
      ) : (
        <Flex p={3} justifyContent="flex-end">
          <Button
            type="button"
            variant="secondary"
            onClick={handleOk}
            style={{ minWidth: 60 }}
            mr={2}
          >
            {t('request.awardRequest')}
          </Button>
          <Button
            variant="primary"
            onClick={onCancel}
            type="button"
          >
            {t('general.dismiss')}
          </Button>
        </Flex>
      )}
    </Modal>
  );
};
