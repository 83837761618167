import { Trans, useTranslation } from 'react-i18next';

import { InlineButton } from '@deepstream/ui-kit/elements/button/InlineButton';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { LoadingPanel } from '../../../ui/Loading';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import { useQuestionnaireTemplate } from './useQuestionnaireTemplate';
import { DraftPageContent } from './DraftPageContent';
import { DraftFooter } from './DraftFooter';
import { BasicDetailsPanel } from './BasicDetailsPanel';
import { ExpiryAndRenewalPanel } from './ExpiryAndRenewalPanel';
import { PurposePanel } from './PurposePanel';
import { QuestionnaireTemplateProvider, useQuestionnaireTemplateState } from './questionnaireTemplateUtils';
import { useDraftQuestionnaireTemplateNavigation, useActiveQuestionnaireTemplateNavigation } from '../../../appNavigation';

const DraftSummaryFooter = () => {
  const draftNavigation = useDraftQuestionnaireTemplateNavigation();

  return (
    <DraftFooter onContinue={() => draftNavigation.navigateToQuestions()} />
  );
};

export const DraftSummary = () => {
  const { t } = useTranslation();
  const { data: template, isLoading, isError } = useQuestionnaireTemplate({
    scope: 'draft',
  });
  // @ts-expect-error ts(2339) FIXME: Property 'isRevising' does not exist on type 'QuestionnaireTemplateStateContextType | undefined'.
  const { isRevising } = useQuestionnaireTemplateState();
  const activeNavigation = useActiveQuestionnaireTemplateNavigation();

  if (isLoading) {
    return <LoadingPanel />;
  }

  if (isError) {
    return <ErrorPanel error={t('errors.unexpected')} />;
  }

  return (
    // @ts-expect-error ts(2322) FIXME: Type 'QuestionnaireTemplate | undefined' is not assignable to type 'QuestionnaireTemplate'.
    <QuestionnaireTemplateProvider template={template}>
      <DraftPageContent>
        <Stack gap="20px">
          {isRevising && (
            <MessageBlock variant="info" mt={0}>
              <Trans
                i18nKey="questionnaireTemplate.reviseBasicDetailsInfo"
                ns="preQualification"
                components={{
                  b: (
                    <InlineButton
                      type="button"
                      onClick={() => activeNavigation.navigateToDetails()}
                    />
                  ),
                }}
              />
            </MessageBlock>
          )}
          <BasicDetailsPanel />
          <PurposePanel />
          <ExpiryAndRenewalPanel />
        </Stack>
      </DraftPageContent>
      <DraftSummaryFooter />
    </QuestionnaireTemplateProvider>
  );
};
