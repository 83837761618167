import * as React from 'react';
import { Flex, Box, Text } from 'rebass/styled-components';

export const ApprovalBox = ({
  heading,
  description,
  action,
}: {
  heading: React.ReactNode;
  description: React.ReactNode;
  action?: React.ReactNode
}) => {
  return (
    <Flex
      sx={{
        borderRadius: 'small',
        border: 'secondary',
        background: 'white',
        p: 3,
        color: 'subtext',
        alignItems: 'center',
        mb: 4,
      }}
    >
      <Box
        sx={{
          flex: '1 1 auto',
        }}
      >
        <Text
          fontSize={4}
          fontWeight={500}
          mb={1}
          color="text"
        >
          {heading}
        </Text>
        <Text>{description}</Text>
      </Box>
      {action && (
        <Box sx={{ marginLeft: 'auto' }}>
          {action}
        </Box>
      )}
    </Flex>
  );
};
