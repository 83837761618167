import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import { Form, Formik } from 'formik';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { SelectField } from '../form/SelectField';
import { DEFAULT_CURRENCY, useCurrencySelectItems } from '../ui/currencies';
import * as rfx from '../rfx';
import { useUpdateRequestCurrency } from './useUpdateRequestCurrency';
import { SummaryPanelHeader } from './SummaryPanelHeader';
import { SummaryLabelConfigProvider } from './SummaryLabelConfigProvider';
import { LeavePageModal } from './LeavePageModal';

const panelId = 'requestCurrency';

export const SummaryCurrencyPanel = () => {
  const { t } = useTranslation();
  const currencySelectItems = useCurrencySelectItems();
  const structure = rfx.useStructure();
  const { stopEditing } = rfx.useActions();
  const { editingPanelId } = rfx.useState();
  const [updateRequestCurrency] = useUpdateRequestCurrency();

  const isEditingOtherPanel = editingPanelId && editingPanelId !== panelId;
  const isEditingThisPanel = editingPanelId && editingPanelId === panelId;

  const { currencyCode } = structure;

  const initialValues = {
    currencyCode: currencyCode || DEFAULT_CURRENCY,
  };

  // If a default currency will be used we need to enable saving the form
  const hasUsedDefaultCurrency = !currencyCode;

  const heading = t('request.summary.requestCurrency');

  return (
    <SummaryLabelConfigProvider>
      <Panel
        as="section"
        aria-label={heading}
        mb={20}
        sx={{
          opacity: isEditingOtherPanel ? 0.5 : 1,
          boxShadow: isEditingThisPanel ? '0 0 8px 0 rgba(0, 0, 0, 0.3)' : '',
        }}
      >
        <SummaryPanelHeader
          heading={heading}
          panelId={panelId}
        />
        <PanelDivider />
        {isEditingThisPanel ? (
          <Formik
            validateOnBlur
            enableReinitialize
            initialValues={initialValues}
            onSubmit={async (values) => {
              await updateRequestCurrency({
                currencyCode: values.currencyCode,
              }, {
                onSuccess: () => stopEditing(),
              });
            }}
          >
            {({ isSubmitting, dirty, isValid, resetForm }) => (
              <Form>
                <PanelPadding>
                  <SelectField
                    hideError
                    hideLabel
                    required
                    menuWidth="300px"
                    placement="bottom-start"
                    helperText={t('request.summary.requestCurrencyInfo')}
                    name="currencyCode"
                    items={currencySelectItems}
                    buttonStyle={{ fontWeight: 500, width: '300px' }}
                    menuZIndex={10}
                  />
                </PanelPadding>
                <PanelDivider />
                <PanelPadding>
                  <Flex justifyContent="flex-end">
                    <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
                    <SaveButton disabled={isSubmitting || (!dirty && !hasUsedDefaultCurrency) || !isValid} />
                  </Flex>
                </PanelPadding>
                <LeavePageModal />
              </Form>
            )}
          </Formik>
        ) : (
          <PanelPadding py={3}>
            {currencySelectItems.find((item) => item.value === structure.currencyCode)?.label}
            <Text color="subtext" fontSize={1}>
              {t('request.summary.requestCurrencyInfo')}
            </Text>
          </PanelPadding>
        )}
      </Panel>
    </SummaryLabelConfigProvider>
  );
};
