import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import * as Sentry from '@sentry/react';
import { Draft, SectionType } from '@deepstream/common/rfq-utils';
import { useQueryClient } from 'react-query';
import { find, noop } from 'lodash';
import { callAll } from '@deepstream/utils/callAll';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { LegacyMultiStageLineItemsEventType } from '@deepstream/common';
import { ModalForm } from '../../ModalForm';
import { SwitchField } from '../../form/SwitchField';
import { TextField } from '../../form/TextField';
import { LabelConfig, LabelConfigProvider } from '../../LabelConfigProvider';
import { useApi } from '../../api';
import { useMutation } from '../../useMutation';
import { useToaster } from '../../toast';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useDraftRfqStructureQueryKey, useLiveRfqStructureQueryKey, useRfqId } from '../../useRfq';
import { useWaitForRfqUnlock } from '../../useWaitForUnlock';
import * as rfx from '../../rfx';
import { requestSentDetailsRoute } from '../../AppRouting';
import { useNavigate } from '../../tanstackRouter';
import { useTracking } from '../../useTracking';

const ReviseRequestModalFields = () => {
  const { t } = useTranslation('request');
  const [{ value: addMessage }] = useField('addMessage');

  return (
    <>
      <LabelConfigProvider
        variant={LabelConfig.LEFT}
        width="auto"
        style={{
          addMessage: {
            fontSize: 2,
            lineHeight: '24px',
            mr: 1,
            color: 'text',
            fontWeight: 400,
          },
        }}
      >
        <SwitchField
          name="addMessage"
          label={t('toolbar.reviseRequestModal.fields.addMessage.label')}
          useDefaultLabelConfig={false}
        />
      </LabelConfigProvider>
      {addMessage ? (
        <TextField
          name="message"
          required
          placeholder={t('toolbar.reviseRequestModal.fields.message.placeholder')}
          label={t('toolbar.reviseRequestModal.fields.message.label')}
          isMultiLine
          rows={3}
        />
      ) : (
        <MessageBlock variant="warn">
          {t('toolbar.reviseRequestModal.noMessageWarning')}
        </MessageBlock>
      )}
    </>
  );
};

export const ReviseRequestModal = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const { t } = useTranslation(['request', 'translation']);
  const { sectionById } = rfx.useStructure<Draft>();
  const api = useApi();
  const waitForRfqUnlock = useWaitForRfqUnlock();
  const toaster = useToaster();
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { trackMultiStageLineItemsEvent } = useTracking();
  const draftStructureQueryKey = useDraftRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
    isTemplate: false,
  });
  const liveStructureQueryKey = useLiveRfqStructureQueryKey({
    currentCompanyId,
    rfqId,
  });

  const [reviseRequest] = useMutation(
    (payload) => waitForRfqUnlock({
      command: async () => {
        await api.reviseRequest(payload);

        if (Object.values(sectionById).some(section => {
          return (
            section.type === SectionType.LINE_ITEMS &&
            !section.liveVersion?.responseTagConfig &&
            section.responseTagConfig
          );
        })) {
          try {
            await trackMultiStageLineItemsEvent({
              eventType: LegacyMultiStageLineItemsEventType.FEATURE_ENABLED,
              entityType: 'request',
              entityId: rfqId,
            });
          } catch (error) {
            Sentry.captureException(error);
          }
        }
      },
    }),
    {
      onSuccess: callAll(
        close,
        () => toaster.success(t('toolbar.reviseRequestModal.toaster.success')),
        () => queryClient.invalidateQueries(draftStructureQueryKey),
        () => queryClient.invalidateQueries(liveStructureQueryKey),
        () => navigate({ to: requestSentDetailsRoute.to, params: { currentCompanyId, rfqId } }),
      ),
      onError: callAll(
        () => toaster.error(t('toolbar.reviseRequestModal.toaster.error')),
        close,
      ),
    },
  );

  return (
    <ModalForm
      style={{ content: { width: '500px' } }}
      isOpen={isOpen}
      onCancel={close}
      onSubmit={async ({ addMessage, message }) => {
        const bulletinSectionId = find(
          sectionById,
          section => section.type === SectionType.BULLETINS,
        )?._id;

        await reviseRequest({
          rfqId,
          companyId: currentCompanyId,
          message: addMessage ? message : undefined,
          bulletinSectionId,
        }).catch(noop);
      }}
      heading={t('toolbar.reviseRequestModal.heading')}
      initialValues={{
        addMessage: true,
        message: '',
      }}
      disableSubmitIfInvalid
      disableCancelWhenSubmitting
      validationSchema={yup.object().shape({
        message: yup.string().when('addMessage', {
          is: true,
          then: schema => schema.required(t('general.required', { ns: 'translation' })),
          otherwise: schema => schema.nullable(),
        }),
      })}
      submitLabel={t('toolbar.reviseRequestModal.issueButtonLabel')}
    >
      <ReviseRequestModalFields />
    </ModalForm>
  );
};
