import { useCallback } from 'react';
import { get, isEmpty, isFunction } from 'lodash';
import { useTranslation } from 'react-i18next';
import { LineItemExchangeDefinition } from '@deepstream/common/rfq-utils';
import { DateFormat, localeFormatDate } from '@deepstream/utils';
import { unparse } from 'papaparse';
import { getDelimiter } from '@deepstream/common/user-utils';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { BulkUploadLineItemsModal } from './BulkUploadLineItemsModal';
import { useToaster } from '../toast';
import { useModalState } from '../ui/useModalState';
import { useSystemFeatureFlags } from '../systemFeatureFlags';
import { useConfigurableExchangeDefFieldColumns } from '../ui/ExchangeDefsGrid/useConfigurableFieldColumns';
import { useCurrentUser, useCurrentUserLocale } from '../useCurrentUser';
import { useExchangeDefFieldValue } from '../ExchangeDefFieldValueContext';
import { downloadBlob } from '../useDownload';
import { useHooks } from '../useHooks';
import { useGetExchangeDefCsvCellValue } from '../ui/ExchangeDefsGrid/useGetExchangeDefCsvCellValue';
import { useExchangeDefsWithContractExchanges } from '../ui/ExchangeDefsGrid/useExchangeDefsWithContractExchanges';

const isEditingSupplierExchangeDefs = false;
const isSender = true;

export const MoreActionsLineItemDropdown = ({
  modelExchangeDef,
  isContract,
  isUploadDisabled,
}: {
  modelExchangeDef?: LineItemExchangeDefinition;
  isContract?: boolean;
  isUploadDisabled?: boolean;
}) => {
  const { t } = useTranslation(['translation', 'general']);
  const systemFeatureFlags = useSystemFeatureFlags();
  const { appendRows, rowData: exchangeDefs } = useEditableGridData<LineItemExchangeDefinition>();
  const toaster = useToaster();
  const bulkUploadModal = useModalState();
  const locale = useCurrentUserLocale();
  const { getFieldValue: getExchangeDefFieldValue } = useExchangeDefFieldValue();
  const user = useCurrentUser();
  const { useSummary, useSection, useExchanges } = useHooks();
  const summary = useSummary();
  const section = useSection();
  const exchanges = useExchanges({ required: false });
  const generateCsvCellValue = useGetExchangeDefCsvCellValue({
    getFieldValue: (exchangeDef, fieldId) => {
      return fieldId === 'unspscCode'
        ? get(exchangeDef, 'productOrService')
        : getExchangeDefFieldValue(exchangeDef, fieldId);
    },
  });

  const columns = useConfigurableExchangeDefFieldColumns({
    fields: exchangeDefs[0]?.fields,
    orderedFieldIds: exchangeDefs[0]?.orderedFieldIds,
    isSender,
    isEditingSupplierExchangeDefs,
  });

  const rowData = useExchangeDefsWithContractExchanges({
    isContract,
    exchangeDefs,
    exchanges,
  });

  const downloadLineItemsCsv = useCallback(() => {
    if (!isEmpty(rowData)) {
      const headerRow = columns.map(column => {
        const labelAndDescription = column.description
          ? `${column.label} (${column.description})`
          : column.label;

        const prefix = isFunction(column.prefix)
          ? column.prefix(modelExchangeDef)
          : column.prefix;

        return prefix
          ? `${labelAndDescription} [${prefix}]`
          : labelAndDescription;
      });

      const dataRows = rowData.map(exchangeDef => {
        return columns.map(column => {
          return generateCsvCellValue(
            column,
            exchangeDef,
          );
        });
      });

      const csvSeparator = user.preferences?.csvSeparator;
      const delimiter = csvSeparator ? getDelimiter(csvSeparator) : '\t';

      try {
        const csv = unparse([headerRow, ...dataRows], { delimiter });
        const blob = new Blob([csv]);

        const requestName = get(summary, 'subject') || get(summary, 'name') || t('untitled', { ns: 'general' });
        const sectionName = section.name || t('untitled', { ns: 'general' });
        const date = localeFormatDate(new Date(), DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ, { locale });
        downloadBlob(blob, `${requestName} - ${sectionName} - ${date}.csv`);

        toaster.success(t('request.lineItems.toaster.downloadCsvSuccess'));
      } catch (err) {
        toaster.error(t('request.lineItems.toaster.downloadCsvError'));
      }
    }
  }, [rowData, columns, user.preferences?.csvSeparator, modelExchangeDef, generateCsvCellValue, summary, t, section.name, locale, toaster]);

  const handleModalImport = (lineItems: LineItemExchangeDefinition[]) => {
    appendRows(lineItems);
    toaster.success(t('request.lineItems.bulkUploadModal.bulkUploadSuccess'));
    bulkUploadModal.close();
  };

  return (
    <>
      <DropdownMenu
        small
        variant="secondary-transparent-outline"
        buttonText={t('moreActions', { ns: 'general' })}
        iconRight="caret-down"
        rightAligned
        menuZIndex={151}
        wrapperStyle={{ height: 'fit-content' }}
      >
        <DropdownMenuItem
          disabled={isEmpty(rowData)}
          onSelect={downloadLineItemsCsv}
          icon="download"
        >
          {t('general.downloadCSV')}
        </DropdownMenuItem>
        {systemFeatureFlags?.bulkImportEnabled && (
          <DropdownMenuItem
            disabled={isUploadDisabled}
            onSelect={bulkUploadModal.open}
            icon="upload"
          >
            {t('request.lineItems.importFromCsv')}
          </DropdownMenuItem>
        )}
      </DropdownMenu>
      {bulkUploadModal.isOpen && (
        <BulkUploadLineItemsModal
          isOpen={bulkUploadModal.isOpen}
          onCancel={bulkUploadModal.close}
          onImport={handleModalImport}
        />
      )}
    </>
  );
};
