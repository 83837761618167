import { assign, createMachine, DoneInvokeEvent } from 'xstate';
import {
  AnyScope,
  Stage,
} from '@deepstream/common/rfq-utils';
import { StageApprovalExchangeSnapshot } from '../../types';

type StageApprovalResponseEvent = DoneInvokeEvent<any>;

export enum StageApprovalResponseAction {
  BACK = 'back',
  CONTINUE = 'continue',
  RESET_TO_INITIAL_CONTEXT = 'resetToInitialContext',
  SET_IS_DIRTY = 'setIsDirty',
  ADD_RESPONSE_DETAILS = 'addResponseDetails',
  SUBMIT_SUCCESS = 'submitSuccess',
}

export enum StageApprovalResponseStates {
  viewDetails = 'viewDetails',
  enterResponse = 'enterResponse',
  reviewAndSubmit = 'reviewAndSubmit',
  submitSuccess = 'submitSuccess',
}

export interface StageApprovalResponseContext {
  rfqId: string;
  stage?: Stage<AnyScope>;
  stageIndex?: number;
  approvalRequest: StageApprovalExchangeSnapshot;
  currentUserId: string;
  response?: 'pending' | 'approved' | 'rejected';
  message: string;
  isDirty: boolean;
}

export const stageApprovalResponseInitialContext: StageApprovalResponseContext = {
  rfqId: '',
  stage: undefined,
  stageIndex: 0,
  // @ts-expect-error ts(2322) FIXME: Type 'null' is not assignable to type 'StageApprovalExchangeSnapshot'.
  approvalRequest: null,
  currentUserId: '',
  response: undefined,
  message: '',
  isDirty: false,
};

export const stageApprovalResponseMachine = createMachine<
  StageApprovalResponseContext,
  StageApprovalResponseEvent
>(
  {
    id: 'stageApprovalResponseMachine',
    initial: StageApprovalResponseStates.viewDetails,
    on: {},
    states: {
      [StageApprovalResponseStates.viewDetails]: {
        id: StageApprovalResponseStates.viewDetails,
        on: {
          [StageApprovalResponseAction.CONTINUE]:
            StageApprovalResponseStates.enterResponse,
        },
      },
      [StageApprovalResponseStates.enterResponse]: {
        id: StageApprovalResponseStates.enterResponse,
        on: {
          [StageApprovalResponseAction.SET_IS_DIRTY]: {
            target: StageApprovalResponseStates.enterResponse,
            actions: ['setIsDirty'],
          },
          [StageApprovalResponseAction.BACK]:
            StageApprovalResponseStates.viewDetails,
          [StageApprovalResponseAction.ADD_RESPONSE_DETAILS]: {
            target: StageApprovalResponseStates.reviewAndSubmit,
            actions: ['addResponseDetailsToContext'],
          },
        },
      },
      [StageApprovalResponseStates.reviewAndSubmit]: {
        id: StageApprovalResponseStates.reviewAndSubmit,
        on: {
          [StageApprovalResponseAction.BACK]:
            StageApprovalResponseStates.enterResponse,
          [StageApprovalResponseAction.SUBMIT_SUCCESS]:
            StageApprovalResponseStates.submitSuccess,
        },
      },
      [StageApprovalResponseStates.submitSuccess]: {
        id: StageApprovalResponseStates.submitSuccess,
      },
    },
  },
  {
    actions: {
      setIsDirty: assign({
        isDirty: (_, event) => Boolean(event.data.isDirty),
      }),
      addResponseDetailsToContext: assign({
        response: (_, event) => event.data.response,
        message: (_, event) => event.data.message,
      }),
    },
    guards: {},
  },
);
