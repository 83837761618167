import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DraftQuestionFormat, LiveQuestionFormat, QuestionFormat } from '@deepstream/common/rfq-utils';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';

export const DateTimePlaceholderText = ({ format, showDash = false }: { format: DraftQuestionFormat | LiveQuestionFormat; showDash?: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <>
      {format !== QuestionFormat.INVALID ? (
        t(`request.question.datetimeFormat.${format}`)
      ) : showDash ? (
        <EmDash />
      ) : (
        ''
      )}
    </>
  );
};
