import { find, includes, filter, groupBy, reduce, every, some } from 'lodash';
import { SectionType } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';

import { Panel } from '@deepstream/ui-kit/elements/Panel';
import * as rfx from '../../../rfx';
import { RfqIdProvider, useLiveRfqStructure } from '../../../useRfq';
import { useAllExchanges } from '../../../useAllExchanges';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { HiddenRequestPagePlaceholder } from '../../../HiddenRequestPagePlaceholder';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import { LoadingPanel } from '../../../ui/Loading';

import { VesselPricingLockedPanel } from './VesselPricingLockedPanel';
import { VesselPricingReportPanel } from './VesselPricingReportPanel';

const useLockStatus = () => {
  const currentCompanyId = useCurrentCompanyId();
  const vesselPricingSection = rfx.useSection();
  const exchanges = rfx.useExchanges();

  const vesselSectionExchanges = filter(
    exchanges,
    (exchange) => exchange.def.sectionId === vesselPricingSection._id,
  );

  const vesselExchangesByRecipientId = groupBy(
    vesselSectionExchanges,
    (exchange) => exchange.recipientId,
  );

  const vesselSectionLockStatusByRecipientId = reduce(
    vesselExchangesByRecipientId,
    (accumulator, exchanges, recipientId) => {
      const isLocked = some(
        exchanges,
        (exchange) => exchange.hasLock && exchange.isLocked,
      );

      return {
        ...accumulator,
        [recipientId]: isLocked,
      };
    },
    {},
  );

  const isLockedForAllRecipients = every(
    vesselSectionLockStatusByRecipientId,
    (locked) => locked,
  );
  const isBuyerBypass = includes(vesselPricingSection?.locking?.bypass, currentCompanyId);

  return {
    isBuyerBypass,
    isLockedForAllRecipients,
  } as const;
};

const VesselPricingPanel = () => {
  const { t } = useTranslation('request');
  const { isBuyerBypass, isLockedForAllRecipients } = useLockStatus();

  return (
    <Panel
      heading={t('vesselPricing.reportPanelHeading')}
      headingDescription={t('vesselPricing.reportPanelSubHeading')}
      padded
    >
      {(isBuyerBypass || !isLockedForAllRecipients) && (
        <VesselPricingReportPanel />
      )}

      {isLockedForAllRecipients && (
        <VesselPricingLockedPanel />
      )}
    </Panel>
  );
};

export const VesselPricingReport = ({
  rfqId,
}: {
  rfqId: string;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId();
  const { data: structure, status: structureStatus } = useLiveRfqStructure({
    rfqId,
    // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string | undefined'.
    currentCompanyId,
  });
  // TODO only request relevant exchanges
  const { data: exchanges, status: exchangesStatus } = useAllExchanges({
    rfqId,
    currentCompanyId,
  });

  const section = find(structure?.sectionById, (section) => section?.type === SectionType.VESSEL_PRICING);
  // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
  const vesselPricePage = find(structure?.pages, (page) => page.sections.includes(section?._id));

  return structureStatus === 'loading' || exchangesStatus === 'loading' ? (
    <LoadingPanel />
  ) : structureStatus === 'error' || exchangesStatus === 'error' ? (
    <ErrorPanel error={t('errors.unexpected')} />
  ) : structure && exchanges ? (
    <RfqIdProvider rfqId={rfqId}>
      <rfx.StateProvider isLive>
        <rfx.StructureProvider structure={structure}>
          {/*
           // @ts-expect-error ts(2322) FIXME: Type 'Page | undefined' is not assignable to type 'Page'. */}
          <rfx.PageProvider page={vesselPricePage}>
            {/*
             // @ts-expect-error ts(2322) FIXME: Type 'Compact<Modify<RfxSectionBase, { type: SectionType.VESSEL_PRICING; hirePeriodIds: string[]; allowSuppliersToAddFees: boolean; allowSuppliersToAddAdditionalTerms: boolean; allowSuppliersToAddInclusionsAndExclusions: boolean; liveVersion?: Compact<...> | undefined; }>> | undefined' is not assignable to type 'Section | RfxSection | null'. */}
            <rfx.SectionProvider section={section}>
              <rfx.ExchangesProvider exchanges={exchanges}>
                <rfx.RecipientsProvider recipients={structure.recipients}>
                  {/* For users that don't have access to the page containing the
                  vessel pricing section, neither the vessel pricing section nor
                  the containing page are included in the structure.
                  Since checking the page permissions for read access is not
                  possible in this case (because we don't know which of the pages
                  contains the vessel pricing section), we infer from the absence
                  of a vessel pricing section in the structure that the current
                  user doesn't have read access. */}
                  {section ? (
                    <VesselPricingPanel />
                  ) : (
                    <HiddenRequestPagePlaceholder />
                  )}
                </rfx.RecipientsProvider>
              </rfx.ExchangesProvider>
            </rfx.SectionProvider>
          </rfx.PageProvider>
        </rfx.StructureProvider>
      </rfx.StateProvider>
    </RfqIdProvider>
  ) : null;
};
