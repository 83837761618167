import { useState, useMemo } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { StaticTableStyles } from '../../../TableStyles';
import { Table } from '../../../Table';
import { AuctionLineItemsExchangeSnapshot } from '../../../types';
import { nestCells } from '../../../nestCells';
import { TruncateCell } from '../../../TruncateCell';
import { AlwaysObsoleteCellWithIcon, AlwaysObsoleteCell } from '../../../draft/cell';
import { LinkedExchangeFieldCell } from '../../../LinkedExchangeDefFieldCell';
import { AuctionLineItemPriceCell } from './AuctionLineItemPriceCell';

export const AuctionBidObsoleteLineItems = ({ lineItemExchanges }: { lineItemExchanges: AuctionLineItemsExchangeSnapshot[] }) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const columns = useMemo(
    () => ([
      {
        id: 'description',
        Header: t('general.description'),
        accessor: 'def.fields.description',
        Cell: nestCells(AlwaysObsoleteCellWithIcon, nestCells(TruncateCell, LinkedExchangeFieldCell)),
      },
      {
        id: 'unit',
        Header: t('general.unit'),
        accessor: 'def.fields.unit',
        Cell: nestCells(AlwaysObsoleteCell, LinkedExchangeFieldCell),
        width: 100,
      },
      {
        id: 'quantity',
        Header: t('general.quantity'),
        accessor: 'def.fields.quantity',
        Cell: nestCells(AlwaysObsoleteCell, LinkedExchangeFieldCell),
        width: 100,
      },
      {
        id: 'previousPrice',
        Header: t('request.auction.submittedBid'),
        accessor: 'latestReply.price',
        textAlign: 'right',
        paddingRight: '16px',
        width: 150,
        Cell: nestCells(AlwaysObsoleteCell, AuctionLineItemPriceCell),
      },
    ]),
    [t],
  );

  if (!lineItemExchanges.length) {
    return null;
  }

  return (
    <Stack gap="20px" mb="64px">
      <Flex alignItems="center" sx={{ gap: '16px' }}>
        <Text>{t('request.auction.obsoleteLineItems', { count: lineItemExchanges.length })}</Text>
        <Button variant="secondary-outline" onClick={() => setIsVisible((isVisible) => !isVisible)}>
          {isVisible
            ? t('request.auction.hideItems', { count: lineItemExchanges.length })
            : t('request.auction.showItems', { count: lineItemExchanges.length })}
        </Button>
      </Flex>
      {isVisible ? (
        <>
          <MessageBlock variant="info">
            {t('request.auction.obsoleteLineItemsMessage', { count: lineItemExchanges.length })}
          </MessageBlock>
          <Panel p={0}>
            <StaticTableStyles>
              <Table
                data={lineItemExchanges}
                columns={columns}
              />
            </StaticTableStyles>
          </Panel>
        </>
      ) : null}
    </Stack>
  );
};
