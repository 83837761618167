import { EditButton, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import * as rfx from '../../../rfx';
import { useHaveDeadlinesPassed } from '../../../deadline';

export const EditSupplierExchangeDefsButton = (props: ButtonProps) => {
  const haveDeadlinesPassed = useHaveDeadlinesPassed();
  const isBidding = rfx.useIsBidding();
  const section = rfx.useSection();

  return (
    <EditButton
      {...props}
      small
      disabled={(
        haveDeadlinesPassed ||
        !isBidding ||
        section.isObsolete
      )}
    />
  );
};
