import * as Layout from '../../Layout';
import { ContractLiveHeader } from './ContractLiveHeader';
import { ContractLiveContract } from './ContractLiveContract';
import { useContractId } from './contract';

export const ContractLiveContractPageContent = () => {
  const tabId = 'contract';
  const contractId = useContractId();

  return (
    <Layout.HeaderAndContent
      header={
        <ContractLiveHeader
          key={contractId}
          contractId={contractId}
          selectedTabId={tabId}
        />
      }
      content={
        <ContractLiveContract
          contractId={contractId}
        />
      }
    />
  );
};
