import { Box, BoxProps } from 'rebass/styled-components';
import styled from 'styled-components';
import { IconValue } from '@deepstream/common';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { Counter, CounterProps } from './ui/Badge';

const BorderedIconWrapper = styled(Box)`
  position: relative;
  height: 18px;
`;

export type CounterIconProps = {
  icon: IconValue;
  iconProps?: Partial<IconProps>;
  counterProps?: Partial<CounterProps>;
  count: CounterProps['count'];
} & BoxProps;

export const CounterIcon = ({
  icon,
  iconProps,
  counterProps,
  count,
  ...props
}: CounterIconProps) => {
  const { sx: counterPropsSx, ...otherCounterProps } = counterProps || {};

  return (
    <BorderedIconWrapper {...props}>
      <Counter
        count={count}
        sx={{
          position: 'absolute',
          bottom: 10,
          left: 10,
          zIndex: 1,
          ...counterPropsSx,
        }}
        {...otherCounterProps}
      />
      <Icon fontSize={4} icon={icon} {...iconProps} />
    </BorderedIconWrapper>
  );
};
