import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { compact } from 'lodash';

import { withProps } from '@deepstream/ui-utils/withProps';
import { Button } from '@deepstream/ui-kit/elements/button/Button';

import { Table } from '../../Table';
import { nestCells } from '../../nestCells';
import { TruncateCell } from '../../TruncateCell';
import { StaticTableStyles } from '../../TableStyles';
import { useModalState } from '../../ui/useModalState';
import { ValueOrDashCell } from '../../ValueOrDashCell';
import { EditRecordModal } from './EditRecordModal';
import { CompanyMinimizedNameAndLogoCell } from '../../CompanyMinimizedNameAndLogoCell';
import { ValueOrNotMappedCell } from '../../ValueOrNotMappedCell';
import { useExternalSystem } from '../../useExternalSystems';

type SupplierMapTableProps = {
  companyId: string;
  systemId: string;
  companyData: Record<string, { internalCompanyId: string, companyName: string }>[];
};

export const SupplierMapTable = ({ companyId, systemId, companyData }: SupplierMapTableProps) => {
  const { t } = useTranslation('integration');
  const { data: externalSystem } = useExternalSystem({ systemId });

  const editRecordModal = useModalState();
  const [selectedRow, setSelectedRow] = useState(null);

  const columns = useMemo(
    () => compact([
      {
        Header: <>{t('external')}<br />{t('supplierId')}</>,
        accessor: 'externalSupplierId',
        Cell: ValueOrDashCell,
      },
      {
        Header: <>{t('external')}<br />{t('supplierName')}</>,
        accessor: 'externalSupplierName',
        Cell: ValueOrDashCell,
      },
      {
        Header: (
          <>{t('external')}<br /><Trans i18nKey="supplierContactEmails" ns="integration" components={{ br: <br /> }} /></>
        ),
        accessor: 'externalSupplierContactEmails',
        Cell: nestCells(TruncateCell, ValueOrDashCell),
      },
      {
        Header: <>DeepStream<br />{t('supplierMappedTo')}</>,
        accessor: 'internalCompanyName',
        Cell: nestCells(
          withProps(CompanyMinimizedNameAndLogoCell, { hasLink: false, companyIdField: 'internalCompanyId' }),
          ValueOrNotMappedCell,
        ),
      },
      externalSystem?.externalData?.hasSupplierStatuses ? {
        Header: t('status', { ns: 'general' }),
        accessor: (supplier) => {
          return supplier.externalStatus && t(`externalStatus.externalStatus.${supplier.externalStatus}`);
        },
        Cell: ValueOrNotMappedCell,
      } : null,
      {
        id: 'actions',
        accessor: 'internalCompanyId',
        Cell: ({ cell }) => {
          return (
            <Button
              small
              iconLeft="pencil"
              onClick={(event) => {
                event.stopPropagation();
                setSelectedRow(cell.row.original);
                editRecordModal.open();
              }}
              variant="secondary-outline"
            >
              {t('general.edit', { ns: 'translation' })}
            </Button>
          );
        },
        textAlign: 'right',
        width: 100,
        disableSortBy: true,
      },
    ]),
    [t, editRecordModal, externalSystem],
  );

  return (
    <StaticTableStyles headerColor="lightNavy">
      <Table
        isPaginated
        isSortable
        columns={columns}
        data={companyData}
        hasStaticHeight={false}
        smallPageControls
        initialPageSize={10}
        autoResetPage
      />
      {selectedRow && externalSystem && (
        <EditRecordModal
          systemId={systemId}
          companyId={companyId}
          externalSupplierData={companyData && Object
            .keys(companyData)
            .map(externalCompanyId => ({
              _id: companyData[externalCompanyId]._id,
              externalCompanyId,
              externalCompanyName: companyData[externalCompanyId].companyName,
              internalCompanyId: companyData[externalCompanyId].internalCompanyId,
            }))}
          record={selectedRow}
          allowManyToOneMapping={externalSystem.allowManyToOneMapping}
          isOpen={editRecordModal.isOpen}
          onCancel={editRecordModal.close}
          onSave={editRecordModal.close}
          onRequestClose={editRecordModal.close}
        />
      )}
    </StaticTableStyles>
  );
};
