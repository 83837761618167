import * as React from 'react';
import { find } from 'lodash';
import { Box } from 'rebass/styled-components';
import { useQuery, useQueryClient } from 'react-query';
import { useField } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { callAll } from '@deepstream/utils/callAll';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { ModalForm } from './ModalForm';
import { useToaster } from './toast';
import { useApi, wrap } from './api';
import { useCurrentCompanyId } from './currentCompanyId';
import { useMutation } from './useMutation';
import { ExtendedSentRequestOverview } from './types';
import { SelectField } from './form/SelectField';
import { TextField } from './form/TextField';
import { useCurrentUserLocale } from './useCurrentUser';
import { waitForUnlock } from './useWaitForUnlock';
import { useGlobalProcessing } from './GlobalProcessingProvider';

const NewTemplateForm: React.FC<{
  overviews: any[];
  initialRequestId?: string;
}> = ({ overviews, initialRequestId }) => {
  const { t } = useTranslation();
  const [rfqIdField] = useField('rfqId');
  const [,, nameFieldHelpers] = useField('name');

  // When an initial requestId has been set, set the name field as 'touched'
  // to display any possible errors.
  React.useEffect(() => {
    if (initialRequestId) {
      nameFieldHelpers.setTouched(true);
    }
  // adding `textFieldHelpers` to the dependencies array would cause an
  // infinite loop so we need to disable eslint to prevent it from adding
  // `textFieldHelpers`:
  // eslint-disable-next-line
  }, [initialRequestId]);

  // Initialize template name with request subject when the selection changes
  useWatchValue(
    rfqIdField.value,
    React.useCallback(
      (rfqId) => {
        const overview = find(overviews, { _id: rfqId });
        nameFieldHelpers.setValue(overview.subject);

        setTimeout(() => {
          nameFieldHelpers.setTouched(true);
        }, 0);
      },
      [overviews, nameFieldHelpers],
    ),
  );

  return (
    <Stack gap={3}>
      <SelectField
        required
        name="rfqId"
        label={t('template.existingRequest')}
        placeholder={t('template.chooseARequest')}
        items={overviews.map(request => ({
          label: request.subject || t('general.untitled'),
          value: request._id,
        }))}
        disabled={!overviews.length}
        maxHeight="140px"
      />
      <TextField name="name" label={t('template.templateName')} required />
    </Stack>
  );
};

type NewTemplateModalProps = {
  isOpen: boolean;
  close: any;
  initialRequest?: ExtendedSentRequestOverview | null;
};

export const NewTemplateModal: React.FC<NewTemplateModalProps> = ({
  isOpen,
  close,
  initialRequest,
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId()!;
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const locale = useCurrentUserLocale();
  const { setProcessingContext } = useGlobalProcessing();

  const { data: templates } = useQuery(
    ['templates', { companyId: currentCompanyId }],
    wrap(api.getTemplates),
  );

  const { data: overviews = [] } = useQuery(
    ['requestsSentAsCreator', { companyId: currentCompanyId }],
    wrap(api.getRequestsSentAsCreator),
    {
      enabled: isOpen,
    },
  );

  const [createTemplateFromRequest] = useMutation(
    async ({
      templateName,
      rfqId,
      currentCompanyId,
      locale,
    }) => {
      const newTemplateId = await api.createEventlessTemplateFromRequest({
        templateName,
        rfqId,
        currentCompanyId,
      });

      await waitForUnlock({
        command: () => api.addRequestCreatedEventFromRequest({
          templateId: newTemplateId,
          rfqId,
          currentCompanyId,
          locale,
        }),
        getLockState: () => api.getRfqLockState({
          currentCompanyId,
          rfqId: newTemplateId,
          isTemplate: true,
        }),
        // @ts-expect-error ts(2322) FIXME: Type '((isProcessing: ProcessingContext) => void) | undefined' is not assignable to type '((processingContext: ProcessingContext | null) => void) | undefined'.
        setIsProcessingModalVisible: setProcessingContext,
      });
    },
    {
      onSettled: callAll(
        () => queryClient.invalidateQueries(['templates', { companyId: currentCompanyId }]),
        close,
      ),
      onSuccess: () => toaster.success(t('requests.toaster.templateCreated.success')),
      onError: () => toaster.error(t('requests.toaster.templateCreated.failed')),
    },
  );

  const initialValues = initialRequest
    ? { rfqId: initialRequest._id, name: initialRequest.sentDashboard.subject }
    : { rfqId: '', name: '' };

  const createTemplate = React.useCallback(
    ({ name, rfqId }) => createTemplateFromRequest({ templateName: name, rfqId, currentCompanyId, locale }),
    [createTemplateFromRequest, currentCompanyId, locale],
  );

  return templates && overviews ? (
    (
      <ModalForm
        heading={t('requests.dialog.newTemplate.heading')}
        initialValues={initialValues}
        validationSchema={
          yup.object().shape({
            rfqId: yup.string().required(t('general.required')),
            name: yup.string()
              .nullable()
              .required(t('general.required'))
              .test(
                'uniqueName',
                t('template.errors.duplicateTemplateName'),
                value => !templates.find(template =>
                  template.meta.name.toLowerCase() === value?.toLowerCase().trim(),
                ),
              ),
          })
        }
        disableSubmitIfNotDirty={false}
        isOpen={isOpen}
        onCancel={close}
        onSubmit={createTemplate}
        submitLabel={t('requests.dialog.newTemplate.okButtonText')}
      >
        <Box maxWidth="500px">
          <MessageBlock mt={0} mb={3} variant="info">
            {t('requests.dialog.newTemplate.info')}
          </MessageBlock>
          <NewTemplateForm
            overviews={overviews}
            initialRequestId={initialRequest?._id}
          />
        </Box>
      </ModalForm>
    )
  ) : null;
};
