import { useMemo } from 'react';
import { Box } from 'rebass/styled-components';
import { PageRole } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { DraftEvaluation } from './DraftEvaluation';
import * as rfx from '../../rfx';
import { useDraftRfqStructure, useRfqId } from '../../useRfq';
import { Loading } from '../../ui/Loading';
import { ErrorMessage } from '../../ui/ErrorMessage';
import { RequestHooksProvider } from '../Request/RequestHooksProvider';
import { ModelSizeLimitsProvider } from '../../modelSizeLimits';
import { DraftToolbar } from '../Request/DraftToolbar';
import { useRequestSizeLimits } from '../Request/useRequestSizeLimits';

const DraftEvaluationPageToolbar = ({ pageId }: { pageId: string }) => {
  const rfqId = useRfqId();
  const { editingPanelId, isTemplate, isRevising } = rfx.useState();
  const isSuperUserOrOwner = rfx.useIsSuperUserOrOwner();
  const isEditing = Boolean(editingPanelId);

  if (isEditing) {
    return null;
  }

  return (
    <DraftToolbar
      rfqId={rfqId}
      pageId={pageId}
      tabId="evaluation"
      hasUnsavedChanges={false}
      showSubmitButton={false}
      isSuperUserOrOwner={isSuperUserOrOwner}
      isTemplate={isTemplate}
      isRevising={isRevising}
      isRequestValid={true}
      excessSupplierCount={0}
    />
  );
};

export const DraftEvaluationPage = ({
  rfqId,
  isTemplate = false,
  isRevising = false,
  pageId,
  navigateToPage,
  navigateToTeam,
}: {
  rfqId: string;
  isTemplate: boolean;
  isRevising: boolean;
  pageId?: string;
  navigateToPage: (pageId: string) => void;
  navigateToTeam: () => void;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { data: rfxStructure, isLoading, isError, isSuccess } = useDraftRfqStructure({
    rfqId,
    currentCompanyId,
    isTemplate,
  });

  const { data: sizeLimits, status: sizeLimitsStatus } = useRequestSizeLimits({
    rfqId,
    isTemplate,
  });

  // Templates should be editable by all members of a company but the template
  // structure doesn't contain ownership / team membership information in
  // `teamById` which we use to determine page permissions so we provide
  // overrides here
  const rfxOverrides = useMemo(
    (): rfx.UserOverrides => isTemplate ? { isOwner: true, pageRole: PageRole.EDITOR } : {},
    [isTemplate],
  );

  return (
    <rfx.StateProvider
      isRevising={isRevising}
      isTemplate={isTemplate}
    >
      {isLoading || sizeLimitsStatus === 'loading' ? (
        <Box p="20px">
          <Loading />
        </Box>
      ) : isError || sizeLimitsStatus === 'error' ? (
        <Box p="20px">
          <ErrorMessage error={t('errors.unexpected')} />
        </Box>
      ) : isSuccess && sizeLimitsStatus === 'success' && rfxStructure ? (
        <ModelSizeLimitsProvider {...sizeLimits}>
          <rfx.StructureProvider structure={rfxStructure}>
            <rfx.OverridesProvider {...rfxOverrides}>
              <rfx.PageNavigationProvider>
                <RequestHooksProvider>
                  <DraftEvaluation rfqId={rfqId} pageId={pageId} />
                  {/*
                   // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'. */}
                  <DraftEvaluationPageToolbar pageId={pageId} />
                </RequestHooksProvider>
              </rfx.PageNavigationProvider>
            </rfx.OverridesProvider>
          </rfx.StructureProvider>
        </ModelSizeLimitsProvider>
      ) : (
        null
      )}
    </rfx.StateProvider>
  );
};
