import { useMemo, useCallback, useEffect } from 'react';

import { TabPanels, TabPanel, Tabs } from '../../ui/TabsVertical';
import { SidebarLayout } from '../../page-helpers';
import { useSystemFeatureFlags } from '../../systemFeatureFlags';
import { ComingSoonOverlay } from './ComingSoonOverlay';
import { MockedSidebar } from './MockedSidebar';
import { MockedMainContent } from './MockedMainContent';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useCurrentUser } from '../../useCurrentUser';
import { useLocalStorageState } from '../../useLocalStorageState';
import { preQualificationArchiveRoute, usePreQualNavigation } from '../../AppRouting';
import { PreQualTab } from './utils';
import { ArchivePageSidebar } from './ArchivePageSidebar';
import { ArchiveQuestions } from './Archive/ArchiveQuestions';
import { ArchiveQuestionnaireTemplates } from './Archive/ArchiveQuestionnaireTemplates';

export const PreQualificationArchivePage = () => {
  const systemFeatureFlags = useSystemFeatureFlags();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const { tab }: { tab: PreQualTab } = preQualificationArchiveRoute.useSearch();
  const { navigateToPreQualificationArchive } = usePreQualNavigation();

  const [lastActiveTab, setLastActiveTab] = useLocalStorageState<string>({
    key: `${currentCompanyId}.${currentUser._id}.preQualificationArchivePage.lastActiveTab`,
    defaultValue: PreQualTab.ARCHIVE_QUESTIONS,
  });

  const tabs = useMemo(
    () => [PreQualTab.ARCHIVE_QUESTIONS, PreQualTab.ARCHIVE_QUESTIONNAIRE_TEMPLATES],
    [],
  );

  const isValidTab = useCallback(tab => tabs.includes(tab), [tabs]);

  useEffect(
    () => {
      if (isValidTab(tab) && tab !== lastActiveTab) {
        setLastActiveTab(tab);
      }
    },
    [tab, lastActiveTab, setLastActiveTab, isValidTab],
  );

  useEffect(
    () => {
      if (!isValidTab(tab)) {
        navigateToPreQualificationArchive(isValidTab(lastActiveTab) ? lastActiveTab : tabs[0], true);
      }
    },
    [lastActiveTab, tab, navigateToPreQualificationArchive, isValidTab, tabs],
  );

  const onTabsChange = useCallback(
    (index) => {
      navigateToPreQualificationArchive(tabs[index]);
    },
    [navigateToPreQualificationArchive, tabs],
  );

  const selectedTabIndex = tabs.indexOf(tab as PreQualTab);

  // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'.
  if (!systemFeatureFlags.newPreQualEnabled) {
    return (
      <>
        <Tabs>
          <SidebarLayout sidebar={<MockedSidebar />}>
            <MockedMainContent />
          </SidebarLayout>
        </Tabs>
        <ComingSoonOverlay />
      </>
    );
  }

  return (
    <Tabs index={selectedTabIndex} onChange={onTabsChange}>
      <SidebarLayout sidebar={<ArchivePageSidebar />}>
        <TabPanels>
          <TabPanel key={PreQualTab.ARCHIVE_QUESTIONS}>
            <ArchiveQuestions />
          </TabPanel>
          <TabPanel key={PreQualTab.ARCHIVE_QUESTIONNAIRE_TEMPLATES}>
            <ArchiveQuestionnaireTemplates />
          </TabPanel>
        </TabPanels>
      </SidebarLayout>
    </Tabs>
  );
};
