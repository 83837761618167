import styled from 'styled-components';
import { Flex, Box, BoxProps } from 'rebass/styled-components';

import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { useDeviceSize } from '../ui/useDeviceSize';

import type { IconStyleModifier } from './MobileNav';

type NavLinkProps = BoxProps & {
  icon?: IconProps['icon'];
  iconMargin?: number;
  testId: string;
  iconStyleModifier?: IconStyleModifier;
  href: string;
};

const NavLinkContainer = styled(Box)`
  text-decoration: none;
  color: ${props => props.theme.colors.white}!important;

  &:hover {
    text-decoration: none;
    color: ${props => props.theme.colors.whiteHover}!important;
  }
`;

export const NavLink = ({ icon, iconMargin = 2, children, testId, iconStyleModifier, ...props }: NavLinkProps) => {
  const { isExtraSmall, isSmall } = useDeviceSize();
  const isMobile = isSmall || isExtraSmall;
  const mr = isMobile ? '5px' : '20px';

  return (
    <NavLinkContainer
      as="a"
      mr={mr}
      p={isMobile ? '5px' : '10px'}
      data-test={testId}
      sx={{ position: 'relative' }}
      {...props}
    >
      <Flex alignItems="center" sx={{ whiteSpace: 'nowrap' }}>
        {icon && <Icon fontSize={4} icon={icon} mr={iconMargin} {...iconStyleModifier} />}
        {children}
      </Flex>
    </NavLinkContainer>
  );
};
