import { Flex, Box, SxStyleProp, BoxProps } from 'rebass/styled-components';
import { Attachment } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { useRfqId } from '../useRfq';
import { useApi } from '../api';
import { useToaster } from '../toast';
import { useMutation } from '../useMutation';
import { downloadUrl } from '../useDownload';
import { FileList } from '../ui/FileList';
import { fakeUploadFn } from '../ui/fakeUploadFn';
import { useSession } from '../auth';
import * as rfx from '../rfx';
import { useInformationModal } from '../InformationModal';

export const useDownloadRfqAttachment = ({ isTemplate } = { isTemplate: false }) => {
  const api = useApi();
  const toaster = useToaster();
  const rfqId = useRfqId();
  const { t } = useTranslation();

  return useMutation(
    async ({ _id: attachmentId }: Attachment) => {
      const { url } = await api.getRfqAttachmentDownloadUrl({ rfqId, attachmentId, isTemplate });
      downloadUrl(url, '');
    },
    {
      onSuccess: () => toaster.success(t('request.exchange.toaster.downloadAttachmentSuccess')),
      onError: () => toaster.error(t('request.exchange.toaster.downloadAttachmentError')),
    },
  );
};

export const RfqAttachment = ({
  attachment,
  truncate = false,
  isTemplate = false,
  showTooltip,
  sx,
}: {
  attachment,
  truncate?: boolean;
  isTemplate?: boolean;
  showTooltip?: boolean;
  sx?: SxStyleProp;
}) => {
  const { t } = useTranslation('translation');
  const [download, { isLoading }] = useDownloadRfqAttachment({ isTemplate });
  const { isAuthenticated } = useSession();
  const rfxState = rfx.useState({ required: false });
  const { showInfo } = useInformationModal();

  return (
    <Flex alignItems="center" sx={{ maxWidth: '100%', ...sx }}>
      <IconText
        isIconRegular
        gap={2}
        icon="file"
        iconColor="subtext"
        text={attachment.name}
        truncate={truncate}
        showTooltip={showTooltip}
      />
      <IconButton
        fixedWidth
        icon="download"
        color="primary"
        onClick={event => {
          event.stopPropagation();
          if (isAuthenticated && !rfxState?.isPublicRequestPreview) {
            download(attachment);
          } else {
            showInfo({
              message: t('request.intention.signupToDownloadDescription'),
            });
          }
        }}
        disabled={isLoading}
        ml={2}
      />
    </Flex>
  );
};

export const RfqAttachmentList = ({
  attachments,
  iconColor,
  ...props
}: {
  attachments: Attachment[],
  iconColor?: string
} & BoxProps) => {
  const [download] = useDownloadRfqAttachment();

  return (
    <Box maxWidth={425} width="fit-content" {...props}>
      <FileList
        isReadOnly
        small
        iconColor={iconColor}
        initialAttachments={attachments}
        uploadFn={fakeUploadFn}
        downloadFn={download}
      />
    </Box>
  );
};
