import { useMemo } from 'react';
import { compact, find } from 'lodash';
import { CollaboratorInviteStatus, isAuctionStage, isNonLinkedEvaluationPage } from '@deepstream/common/rfq-utils';
import { useQuery } from 'react-query';
import { Box } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '../../tanstackRouter';
import { useSystemFeatureFlags } from '../../systemFeatureFlags';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useDraftRfqStructure } from '../../useRfq';
import { useApi, wrap } from '../../api';
import { requestEditSummaryRoute, requestSentSuppliersViewRoute } from '../../AppRouting';
import { useRequestEditNavigation } from '../../appNavigation';
import { EditStatusIndicator } from '../../EditStatus';
import { PageHeading } from '../../page-headers/PageHeading';
import { RequestHeading } from '../../page-headers/RequestHeading';
import * as Layout from '../../Layout';
import { SentRequestOverview } from '../../types';

type DraftHeaderProps = {
  rfqId: string;
  isTemplate?: boolean;
  isRevising?: boolean;
  selectedTabId: string;
};

export const DraftHeader = ({ rfqId, isTemplate, isRevising, selectedTabId }: DraftHeaderProps) => {
  const { t } = useTranslation('translation');
  const systemFeatureFlags = useSystemFeatureFlags();
  const navigate = useNavigate();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const navigation = useRequestEditNavigation();

  const { data: structure } = useDraftRfqStructure({
    rfqId,
    currentCompanyId,
    isTemplate,
  });

  const { data: template } = useQuery(
    ['template', { currentCompanyId, templateId: rfqId }],
    wrap(api.getTemplate),
    {
      enabled: isTemplate,
    },
  );

  const heading = isTemplate
    ? template?.meta.name
    : structure?.summary.subject;

  const fallbackHeading = isTemplate
    ? t('requests.untitledTemplate', { ns: 'translation' })
    : t('requests.untitledRequest', { ns: 'translation' });

  const hasAuctionStage = Boolean(structure?.stages.some(isAuctionStage));

  const hasPageLinkedEvaluation = (
    // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'.
    systemFeatureFlags.evaluationUpdatesEnabled &&
    structure &&
    // This checks if non-linked evaluation page exists, which means the request is using
    // the initial single-page version of evaluation which didn't have it's own top-level tab
    // and instead sits at the level of the request details pages
    !structure.pages.some(isNonLinkedEvaluationPage)
  );

  const companyAsSender = find(
    structure?.senders,
    (sender) => sender.company._id === currentCompanyId,
  );

  const isCollaboratorInvitePending = companyAsSender?.inviteStatus === CollaboratorInviteStatus.PENDING;

  const tabs = useMemo(
    () => compact([
      {
        id: 'summary',
        name: t('general.summary'),
        navigate: () => navigation.navigateToSummary(),
      },
      {
        id: 'stages',
        name: t('general.stage_other'),
        disabled: !rfqId,
        navigate: () => navigation.navigateToStages(),
      },
      {
        id: 'details',
        name: t('general.details'),
        disabled: !rfqId,
        navigate: () => navigation.navigateToDetailsIndex(),
      },
      {
        id: 'auction',
        name: t('general.auction'),
        hide: !rfqId || !hasAuctionStage,
        navigate: () => navigation.navigateToAuction(),
      },
      hasPageLinkedEvaluation ? {
        id: 'evaluation',
        name: t('general.evaluation'),
        disabled: !rfqId,
        navigate: () => navigation.navigateToEvaluationIndex(),
      } : (
        null
      ),
      {
        id: 'spend',
        name: t('request.spendAndSavings.spend'),
        iconLeft: 'circle-dollar',
        iconProps: { regular: true },
        disabled: !rfqId,
        tooltip: !rfqId ? t('pageHeader.tooltip.youMustAddARequestName') : '',
        navigate: () => navigation.navigateToSpend(),
      },
      {
        id: 'team',
        name: t('general.team'),
        disabled: !rfqId,
        tooltip: !rfqId ? t('pageHeader.tooltip.youMustAddARequestName') : '',
        navigate: () => navigation.navigateToTeam(),
      },
      {
        id: 'suppliers',
        name: isRevising ? t('general.visibility') : t('general.supplier_other'),
        disabled: !rfqId,
        tooltip: !rfqId ? t('pageHeader.tooltip.youMustAddARequestName') : '',
        navigate: () => navigation.navigateToSuppliers(),
      },
      {
        id: 'review',
        name: isTemplate ? t('general.reviewAndExit') : t('general.reviewAndSend'),
        disabled: !rfqId,
        tooltip: !rfqId ? t('pageHeader.tooltip.youMustAddARequestName') : '',
        navigate: () => navigation.navigateToReview(),
      },
      {
        id: 'approvals',
        name: t('request.stageApprovals.approval_other'),
        iconLeft: 'circle-check',
        iconProps: { regular: true },
        hide: isRevising || isTemplate || !rfqId,
        isRightAligned: true,
        navigate: () => navigation.navigateToApprovals(),
      },
      {
        id: 'comments',
        name: t('general.comment_other'),
        iconLeft: 'comment-o',
        hide: isRevising || isTemplate || !rfqId,
        isRightAligned: true,
        navigate: () => navigation.navigateToComments(),
      },
    ]),
    [rfqId, hasAuctionStage, hasPageLinkedEvaluation, isRevising, isTemplate, navigation, t],
  );

  const navigateToRequest = (request: SentRequestOverview) => {
    if (request.sentDashboard.status === 'draft') {
      navigate({
        to: requestEditSummaryRoute.to,
        params: { currentCompanyId, rfqId: request._id },
      });
    } else {
      navigate({
        to: requestSentSuppliersViewRoute.to,
        params: { currentCompanyId, rfqId: request._id },
      });
    }
  };

  return (
    <Layout.PageHeader
      tabs={isCollaboratorInvitePending ? undefined : tabs}
      selectedTabId={selectedTabId}
      heading={() => (
        isTemplate ? (
          <PageHeading
            icon="file-text-o"
            text={heading || fallbackHeading}
          />
        ) : (
          <RequestHeading
            isSender
            requestId={rfqId!}
            subject={heading || fallbackHeading}
            navigateToRequest={navigateToRequest}
          />
        )
      )}
    >
      <Box ml={4} flex="0 0 auto">
        <EditStatusIndicator editStatus={isTemplate ? 'template' : isRevising ? 'revision' : 'draft'} />
      </Box>
    </Layout.PageHeader>
  );
};
