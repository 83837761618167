import { uniqueId, noop } from 'lodash';
import { useState, useCallback } from 'react';
import * as React from 'react';
import { Heading, Flex, Box } from 'rebass/styled-components';
import { AttachmentPurpose, Attachment } from '@deepstream/common/rfq-utils';
import { getRegionName } from '@deepstream/common';
import { useTranslation } from 'react-i18next';
import { IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { CompanyBanner } from '../../../ui/CompanyBanner';
import { CompanyLogo } from '../../../ui/CompanyLogo';
import { CompanySize } from '../../../ui/CompanySize';
import { Company, UploadFn } from '../../../ui/types';
import { ImageUploadOverlay, ImageUploadOverlayProps } from '../../../ui/ImageUploadOverlay';
import { PublicProfileLinkPanel } from '../../../ui/PublicProfileLinkPanel';
import { useDeviceSize } from '../../../ui/useDeviceSize';
import { useCurrentUserLocale } from '../../../useCurrentUser';

const createDummyAttachment = (): Attachment => ({
  _id: uniqueId('attachment'),
  name: '',
  title: '',
  notSaved: false,
  companyId: '',
  userId: '',
  purpose: AttachmentPurpose.HERO,
  mimetype: '',
});

/**
 * We don't have access to the attachment objects for the company banner/logo, but since
 * there is a placeholder image for missing banners/logos, the upload component should
 * always initialize into a "completed" state. This "dummy" attachment is only used initialize
 * the upload components to the completed state (it's a bit of a hack, but fairly simple)
 */
const dummyAttachment = createDummyAttachment();

type BannerUploadProps = ImageUploadOverlayProps & {
  editButtonPosition: 'top-right' | 'bottom-right';
};

const BannerUpload: React.FC<BannerUploadProps> = React.memo(({ editButtonPosition, ...props }) => {
  let position: React.CSSProperties = {};
  if (editButtonPosition === 'top-right') {
    position = { top: 16 };
  } else if (editButtonPosition === 'bottom-right') {
    position = { bottom: 16 };
  }

  return (
    <ImageUploadOverlay
      editButtonStyle={{ right: 16, ...position }}
      overlayStyle={{ backgroundColor: 'white', opacity: 0.93 }}
      containerStyle={{ alignItems: 'flex-start', justifyContent: 'center', paddingTop: 4 }}
      {...props}
    />
  );
});

const LogoUpload: React.FC<ImageUploadOverlayProps> = React.memo((props) => (
  <ImageUploadOverlay
    editButtonStyle={{ right: -18, top: -9 }}
    overlayStyle={{ backgroundColor: 'white', opacity: 0.93, borderRadius: 'large' }}
    containerStyle={{ alignItems: 'center', justifyContent: 'center' }}
    {...props}
  />
));

type CompanyInfoProps = {
  name: string;
  icon: IconProps['icon'];
  text: string | React.ReactNode;
  isLast?: boolean;
};

const CompanyInfo: React.FC<CompanyInfoProps> = ({ name, icon, text, isLast }) =>
  text ? (
    <Box
      mr={isLast ? undefined : [null, null, 3, null]}
      mb={isLast ? undefined : [2, null, 0, null]}
    >
      <IconText
        name={name}
        gap={2}
        fontSize={2}
        fontWeight={500}
        iconColor="lightGray5"
        icon={icon}
        text={text}
      />
    </Box>
  ) : null;

type CompanyProfileHeaderProps = {
  company: Company;
  showPublicProfilePanel: boolean;
  apiUrl: string;
  canEdit?: boolean;
  bannerStyle?: any;
  uploadCompanyLogo?: UploadFn;
  uploadCompanyBanner?: UploadFn;
  updateCompanyLogo?: () => Promise<void>;
  updateCompanyBanner?: () => Promise<void>;
  toaster?: any;
  noTabsAfter?: boolean;
};

export const CompanyProfileHeader: React.FC<CompanyProfileHeaderProps> = ({
  company,
  showPublicProfilePanel,
  apiUrl,
  canEdit,
  bannerStyle,
  uploadCompanyLogo,
  uploadCompanyBanner,
  updateCompanyLogo = noop,
  updateCompanyBanner = noop,
  toaster,
  noTabsAfter,
}) => {
  const { t } = useTranslation('companyProfile');
  const theme = useTheme();
  const [lastUpdated, setLastUpdated] = useState(Date.now());
  const { isExtraSmall } = useDeviceSize();
  const locale = useCurrentUserLocale();

  const updateBanner = useCallback(
    async (logo) => {
      await updateCompanyBanner(logo);
      setLastUpdated(Date.now());
    },
    [updateCompanyBanner, setLastUpdated],
  );

  const updateLogo = useCallback(
    async (logo) => {
      await updateCompanyLogo(logo);
      setLastUpdated(Date.now());
    },
    [updateCompanyLogo, setLastUpdated],
  );

  return (
    <Panel
      pb={16}
      sx={{
        borderRadius: noTabsAfter ? '0px 0px 4px 4px' : 0,
        borderTop: 'none',
        borderBottom: noTabsAfter ? 'lightGray' : 'none',
      }}
    >
      <div style={{ position: 'relative' }}>
        <CompanyBanner
          companyId={company._id}
          style={bannerStyle}
          canEdit={canEdit}
          lastUpdated={lastUpdated}
        />
        {canEdit && (
          <BannerUpload
            initialAttachment={dummyAttachment}
            onChange={updateBanner}
            uploadFn={uploadCompanyBanner!}
            editButtonPosition={isExtraSmall && showPublicProfilePanel ? 'bottom-right' : 'top-right'}
          />
        )}
        {showPublicProfilePanel && (
          <PublicProfileLinkPanel companyId={company._id} companyName={company.name} toaster={toaster} />
        )}
      </div>
      <Flex mt={-115} justifyContent="center" mb={3} sx={{ position: 'relative' }}>
        <div style={{ position: 'relative' }}>
          <CompanyLogo
            src={`${apiUrl}/download/logo/${company._id}?t=${lastUpdated}`}
            p={2}
            mx="auto"
            width="140px"
          />
          {canEdit && (
            <LogoUpload
              initialAttachment={dummyAttachment}
              onChange={updateLogo}
              uploadFn={uploadCompanyLogo!}
            />
          )}
        </div>
      </Flex>
      <Flex justifyContent="center" color={theme.colors.text} mb={2} px={16}>
        <Heading as="h1" fontSize="24px" lineHeight="32px" fontWeight={500}>
          {company.name}
        </Heading>
      </Flex>
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection={['column', 'column', 'row', 'row']}
        color={theme.colors.text}
        px={16}
      >
        <CompanyInfo
          name={t('common.companyCountry')}
          icon="map-marker"
          text={getRegionName(company.address?.country, locale)}
        />
        <CompanyInfo
          name={t('common.companyType')}
          icon="industry"
          text={company.group ? t(`common.companyGroup.${company.group}`) : null}
        />
        <CompanyInfo
          name={t('common.companySize')}
          icon="users"
          text={company.employeesNb && <CompanySize employeesNb={company.employeesNb} />}
          isLast
        />
      </Flex>
    </Panel>
  );
};
