import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass/styled-components';
import { CollaboratorInviteStatus, CompanyMinimized } from '@deepstream/common/rfq-utils';
import { compact, pick } from 'lodash';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { CellProps } from 'react-table';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { TextField } from '../form/TextField';
import { PreWrapValueOrEmDash, PropertyList, ValueOrEmDash } from '../PropertyList';
import { FormTableStyles } from '../TableStyles';
import { Table } from '../Table';
import { CompanyLogo } from '../CompanyLogo';
import * as rfx from '../rfx';
import { ValueOrDashCell } from '../ValueOrDashCell';
import { nestCells } from '../nestCells';
import { TruncateCell } from '../TruncateCell';
import { useUpdateSummary } from './useUpdateSummary';
import { SummaryPanelHeader } from './SummaryPanelHeader';
import { SummaryLabelConfigProvider } from './SummaryLabelConfigProvider';
import { Validation } from './validation';
import * as draft from './draft';
import { ValidationPropertyRow } from './ValidationPropertyRow';
import { ValidationErrorValue } from './ValidationErrorValue';
import { nestValues } from '../nestValues';
import { LeavePageModal } from './LeavePageModal';

const panelId = 'basicDetails';

const ReadOnlyCompany = ({
  cell: { value: company },
}: CellProps<CompanyMinimized>) => (
  <>
    <CompanyLogo companyId={company._id} />
    {company.name}
  </>
);

const ReadOnlySenderColumnHeader = ({ column }) => (
  <Box>
    {column.label}
  </Box>
);

const ReadOnlySenderTableField = ({
  value: senders,
}) => {
  const { t } = useTranslation();
  const newSenders = senders.map(sender => ({
    input: sender.company.name,
    company: sender.company,
    role: sender.role || sender.company.role,
  }));

  const fromColumns = useMemo(
    () => [
      {
        id: 'company',
        Header: ReadOnlySenderColumnHeader,
        label: t('general.companyName'),
        accessor: 'company',
        Cell: ReadOnlyCompany,
      },
      {
        id: 'role',
        Header: t('general.companyRole'),
        label: t('general.companyRole'),
        accessor: 'company.role',
        Cell: nestCells(TruncateCell, ValueOrDashCell),
      },
    ],
    [t],
  );

  return (
    <FormTableStyles>
      <Table columns={fromColumns} data={newSenders} />
    </FormTableStyles>
  );
};

export const SummaryBasicDetailsPanel = () => {
  const { t } = useTranslation();
  const { stopEditing } = rfx.useActions();
  const { isReview, isLive, editingPanelId } = rfx.useState();
  const structure = rfx.useStructure();
  const [updateDraft] = useUpdateSummary();
  const showValidationErrors = draft.useShowValidationErrors();

  const nonRejectedSenders = structure.senders.filter(sender => sender.inviteStatus !== CollaboratorInviteStatus.REJECTED);

  const isEditingOtherPanel = editingPanelId && editingPanelId !== panelId;
  const isEditingThisPanel = editingPanelId && editingPanelId === panelId;

  const properties = useMemo(() => compact([
    {
      fieldName: 'subject',
      name: t('request.summary.requestName'),
      value: structure.summary.subject,
      Component: nestValues(ValidationErrorValue, ValueOrEmDash),
    },
    isReview || isLive ? {
      fieldName: 'senders',
      name: t('general.from_uppercase'),
      value: nonRejectedSenders,
      Component: ReadOnlySenderTableField,
      heightAuto: true,
    } : (
      null
    ),
    {
      fieldName: 'description',
      name: t('request.summary.overview'),
      value: structure.summary.description,
      Component: nestValues(ValidationErrorValue, PreWrapValueOrEmDash),
      heightAuto: true,
    },
  ]), [t, structure.summary.subject, structure.summary.description, isReview, isLive, nonRejectedSenders]);

  const initialValues = {
    'subject': structure.summary.subject,
    'senders': nonRejectedSenders.map(sender => ({
      input: sender.company.name,
      company: pick(sender.company, ['_id', 'name']),
      role: sender.company.role,
    })),
    'description': structure.summary.description,
  };

  const heading = t('request.summary.basicDetails');

  return (
    <SummaryLabelConfigProvider>
      <Panel
        as="section"
        aria-label={heading}
        sx={{
          opacity: isEditingOtherPanel ? 0.5 : 1,
          boxShadow: isEditingThisPanel ? '0 0 8px 0 rgba(0, 0, 0, 0.3)' : '',
        }}
        mb={20}
      >
        <SummaryPanelHeader heading={heading} panelId={panelId} />
        <PanelDivider />
        {isEditingThisPanel ? (
          <Formik
            validateOnBlur
            enableReinitialize
            initialValues={initialValues}
            validationSchema={
              yup.object().shape({
                subject: yup.string(),
                description: yup.string(),
              })
            }
            onSubmit={async (values) => {
              await updateDraft({
                values: {
                  ...values,
                  // @ts-expect-error ts(18048) FIXME: 'values.subject' is possibly 'undefined'.
                  subject: values.subject.trim(),
                },
                initialValues,
              }, {
                onSuccess: () => stopEditing(),
              });
            }}
          >
            {({ isSubmitting, dirty, isValid, resetForm }) => (
              <Form>
                <Flex mx={15} mb={20} mt={20}>
                  <TextField
                    label={t('request.summary.requestName')}
                    name="subject"
                    required
                    // quick fix: we can remove setting the inputStyle height once a line height is
                    // set for Input
                    inputStyle={{ height: 40 }}
                  />
                </Flex>
                <Flex mx={15} mb={20}>
                  <TextField
                    name="description"
                    isMultiLine
                    rows={9}
                    label={t('request.summary.overview')}
                    helperText={t('request.summary.overviewHelperText')}
                    placeholder={t('request.summary.overviewPlaceholder')}
                    required
                  />
                </Flex>
                <PanelDivider />
                <PanelPadding>
                  <Flex justifyContent="flex-end">
                    <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
                    <SaveButton disabled={isSubmitting || !dirty || !isValid} />
                  </Flex>
                </PanelPadding>
                <LeavePageModal />
              </Form>
            )}
          </Formik>
        ) : (
          <Validation
            values={{
              subject: structure.summary.subject,
              description: structure.summary.description,
            }}
            schema={showValidationErrors ? (
              yup.object().shape({
                subject: yup.string().nullable().required(t('general.required')),
                description: yup.string().nullable().required(t('general.required')),
              })
            ) : (
              yup.mixed()
            )}
          >
            <PropertyList Row={ValidationPropertyRow} properties={properties} />
          </Validation>
        )}
      </Panel>
    </SummaryLabelConfigProvider>
  );
};
