import { useMemo, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { documentExchangeTypes } from '@deepstream/common/exchangesConfig';
import {
  DocumentExchangeDefinition,
  ExchangeStatus,
  ExchangeType,
} from '@deepstream/common/rfq-utils';
import { Box } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { useQueryClient } from 'react-query';
import { callAll } from '@deepstream/utils/callAll';
import { useInterval } from '@deepstream/ui-kit/hooks/useInterval';
import { useSearch } from '@tanstack/react-router';
import { getContractExchangeSnapshotStatus } from '../../exchangeStatus';
import { StandaloneExchangeFields } from '../../ExchangeTable';
import { useExchange } from '../../useExchange';
import { ExchangeSwitcher } from '../../ExchangeModal/ExchangeSwitcher';
import { ExchangeTimeline } from '../../ExchangeModal/ExchangeTimeline';
import * as modal from '../../ExchangeModal/layout';
import { ActionNotificationSubject } from '../../ExchangeModal/useActionNotificationSubject';
import { useExchangeDefFieldValue } from '../../ExchangeDefFieldValueContext';
import { ExchangeSnapshot } from '../../types';
import { SendMessageForm } from '../../SendMessageForm';
import { ScrollToBottomHintButton, useScrollToBottomHint } from '../../hooks/useScrollToBottomHint';
import { useHooks } from '../../useHooks';
import { useContractActionNotificationSubject } from './useContractActionNotificationSubject';
import { ContractExchangeModalFooter } from './ContractExchangeModalFooter';
import { useExchangeColumns } from '../Exchange/columns';
import { useSendContractExchangeReply } from './useSendContractExchangeReply';
import { canESignContract, useContractData } from './contract';
import { usePossibleActions } from '../Exchange/usePossibleActions';
import { BuyerFieldsSection } from '../../ExchangeModal/BuyerFieldsSection';
import { SupplierFieldsSection } from '../../ExchangeModal/SupplierFieldsSection';
import { FixedFieldsSection } from '../../ExchangeModal/FixedFieldsSection';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useCurrentUser } from '../../useCurrentUser';
import { useContractExchange, useContractQueryKey } from './useContract';
import { useMutation } from '../../useMutation';
import { useApi } from '../../api';
import { useExchangeRefetch } from '../../rfx';

const FinalFieldsSection = ({
  exchange,
}: {
  exchange: ExchangeSnapshot;
}) => {
  const { t } = useTranslation();
  const exchangeColumns = useExchangeColumns();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contract = useContractData();
  const currentUser = useCurrentUser();
  const { useIsSuperUserOrOwner } = useHooks();
  const isSuperUserOrOwner = useIsSuperUserOrOwner();

  const exchangeStatus = useMemo(
    () => getContractExchangeSnapshotStatus(
      exchange,
      currentCompanyId,
      contract.exchangeDefById,
      currentUser._id,
      contract.status,
      isSuperUserOrOwner,
    ),
    [exchange, currentCompanyId, contract, currentUser, isSuperUserOrOwner],
  );

  const columns = useMemo(() => {
    const columns = [];

    if (exchange.def.type !== ExchangeType.CONTRACT || exchangeStatus !== ExchangeStatus.SIGNED) {
      return [];
    }

    // @ts-expect-error ts(2345) FIXME: Argument of type '{ id: string; Header: string | undefined; accessor: string; Cell: (props: any) => FunctionComponentElement<any>; width: string | number | undefined; sortType: string; }' is not assignable to parameter of type 'never'.
    columns.push(...exchangeColumns.finalSectionContractModal());

    return columns;
  }, [exchange.def, exchangeColumns, exchangeStatus]);

  return isEmpty(columns) ? (
    null
  ) : (
    <modal.ClosableSection
      headerIcon={<Icon color="subtext" icon="file-contract" />}
      header={t('exchange.modal.finalFields', { ns: 'contracts' })}
    >
      <Box pt={3} pr="60px">
        <StandaloneExchangeFields
          vertical
          small
          exchange={exchange}
          columns={columns}
        />
      </Box>
    </modal.ClosableSection>
  );
};

export const ContractExchangeModalContentNew = memo(({
  close,
  showExchangeSwitcher,
  showFooter,
  showRecipient,
}: {
  close: (...args: any[]) => void;
  showExchangeSwitcher?: boolean;
  showFooter?: boolean;
  showRecipient?: boolean;
}) => {
  const { t } = useTranslation();
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { setElement: setScrollToBottomRef, showHint, scrollToBottom } = useScrollToBottomHint();
  const exchange = useExchange();
  const { _id: contractId, status } = useContractData();
  const refetchExchange = useExchangeRefetch();
  const { usePagePermissions } = useHooks();
  const pagePermissions = usePagePermissions();
  const queryClient = useQueryClient();
  const contractQueryKey = useContractQueryKey({ scope: 'current' });
  const search = useSearch({ strict: false }) as { from?: 'verified' };
  const currentUser = useCurrentUser();

  // If the envelope is pending creation and publishing we need to refetch the exchange every second until it is done.
  useContractExchange({
    exchangeId: exchange._id,
    refetchInterval: exchange.isAwaitingEnvelopePublishing ? 1000 : undefined,
    enabled: exchange.isAwaitingEnvelopePublishing,
    onSuccess: (exchange) => {
      if (!exchange.isAwaitingEnvelopePublishing) {
        queryClient.invalidateQueries(contractQueryKey);
      }
    },
  });

  const [sendExchangeReplyMutation] = useSendContractExchangeReply({
    contractId,
    exchangeId: exchange?._id,
  });

  const [checkESignatureUpdates, { isLoading: isCheckingUpdates }] = useMutation(
    api.checkContractESignatureUpdates,
    {
      onSuccess: callAll(
        () => refetchExchange?.(),
        () => queryClient.invalidateQueries(contractQueryKey),
      ),
    },
  );

  useEffect(
    () => {
      if ((exchange.isAwaitingSubmitterESignature || exchange.isAwaitingCounterESignature) && !isCheckingUpdates) {
        checkESignatureUpdates({
          companyId: currentCompanyId,
          contractId,
          exchangeId: exchange._id,
        });
      }
    },
    // We don't want to re-run this effect when the exchange changes
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const isFromVerified = search?.from === 'verified';

  // Check for updates every second if the user is redirected back from Verified and the contract is not updated
  // with the user's action (e-sign or decline).
  useInterval(
    () => {
      if (!isCheckingUpdates) {
        checkESignatureUpdates({
          companyId: currentCompanyId,
          contractId,
          exchangeId: exchange._id,
        });
      }
    },
    isFromVerified && canESignContract(currentUser._id, exchange, status) ? 2000 : null,
  );

  const { canPostComment } = usePossibleActions(exchange);
  // @ts-expect-error ts(2345) FIXME: Argument of type 'null' is not assignable to parameter of type 'HTMLDivElement | (() => HTMLDivElement)'.
  const [timelineElement, setTimelineElement] = useState<HTMLDivElement>(null);
  const timelineElementHeight = useMemo(
    () => timelineElement ? timelineElement.getBoundingClientRect().height / 2 : undefined,
    [timelineElement],
  );

  const { getFieldValue } = useExchangeDefFieldValue();

  const modalHeadingValue = useMemo(() => {
    if (exchange.def.type === ExchangeType.LINE_ITEM) {
      return getFieldValue(exchange.def, 'description');
    } else if (documentExchangeTypes.includes(exchange.def.type)) {
      return (exchange.def as DocumentExchangeDefinition).category;
    } else if (exchange.def.type === ExchangeType.CONTRACT) {
      return exchange.def.description;
    } else {
      return null;
    }
  }, [exchange.def, getFieldValue]);

  const modalHeading = modalHeadingValue ?? <EmDash />;

  return (
    <modal.Content height={showFooter ? 'calc(100vh - 120px)' : 'calc(100vh - 56px)'}>
      <modal.Header
        onClose={close}
        leftActionComponent={showExchangeSwitcher && (
          <ExchangeSwitcher exchangeId={exchange._id} />
        )}
      >
        {modalHeading}
      </modal.Header>
      <modal.Body maxHeight="unset">
        {/*
         // @ts-expect-error ts(2322) FIXME: Type 'Dispatch<SetStateAction<HTMLDivElement | undefined>>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'. */}
        <modal.Fields p={0} flex={5} position="relative" ref={setScrollToBottomRef}>
          <FixedFieldsSection
            exchange={exchange}
            showRecipient={showRecipient}
          />
          <FinalFieldsSection exchange={exchange} />
          <BuyerFieldsSection exchange={exchange} />
          <SupplierFieldsSection exchange={exchange} />
          <ScrollToBottomHintButton sx={{ position: 'sticky', bottom: '10px', left: '50%', visibility: showHint ? 'visible' : 'hidden' }} onClick={scrollToBottom} />
        </modal.Fields>
        {/*
         // @ts-expect-error ts(2322) FIXME: Type 'Dispatch<SetStateAction<HTMLDivElement>>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'. */}
        <modal.Timeline bg="lightGray3" flex={3} overflowY="hidden" ref={setTimelineElement}>
          <modal.TimelineHeader>
            {t('request.exchange.activityAndComments')}
          </modal.TimelineHeader>
          {/*
           // @ts-expect-error ts(2322) FIXME: Type '({ exchange, action, }: { exchange: ExchangeSnapshot; action: any; }) => null' is not assignable to type 'ActionNotificationSubjectFn'. */}
          <ActionNotificationSubject.Provider value={useContractActionNotificationSubject}>
            <ExchangeTimeline canRespond={pagePermissions.canRespond} />
          </ActionNotificationSubject.Provider>
          {canPostComment && (
            <modal.TimelineLeaveComment>
              <SendMessageForm
                onSubmit={sendExchangeReplyMutation}
                placeholder={t('request.exchange.leaveAComment')}
                buttonProps={{ variant: 'primary-outline' }}
                showButtonOnFocus
                maxTextFieldHeight={timelineElementHeight}
              />
            </modal.TimelineLeaveComment>
          )}
        </modal.Timeline>
      </modal.Body>
      <modal.Footer>
        <ContractExchangeModalFooter hideReplyForm />
      </modal.Footer>
    </modal.Content>
  );
});
