import * as yup from 'yup';
import { useState } from 'react';
import { difference, isEmpty, sum } from 'lodash';
import { useTranslation } from 'react-i18next';
import { parse, ParseResult } from 'papaparse';
import { ExternalSupplier } from '@deepstream/common/rfq-utils';

import { Button, CancelButton, SaveButton } from '@deepstream/ui-kit/elements/button/Button';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter } from '@deepstream/ui-kit/elements/popup/Modal';
import { FileList } from './ui/FileList';
import { emailStringToArray } from './utils';
import { ErrorMessage } from './ui/ErrorMessage';
import { fastFakeUploadFn } from './ui/fakeUploadFn';
import { useErrors, Validation } from './draft/validation';
import { BulkImportSuppliersTable } from './BulkImportSuppliersTable';

const externalSupplierListSchema = yup.array().of(yup.object({
  externalSupplierId: yup
    .string()
    .required(),
  externalSupplierName: yup
    .string()
    .required(),
  externalSupplierContactEmails: yup
    .string()
    .test(
      'emailsFormat',
      'Invalid email',
      value => emailStringToArray(value).every(item =>
        yup.string().email().isValidSync(item),
      ),
    ),
}));

type Props = {
  onImport?: (externalSuppliers: ExternalSupplier[]) => void;
  onCancel?: () => void;
} & ModalProps;

const BulkUploadPreviewMode = ({ externalSuppliers }: { externalSuppliers: ExternalSupplier[] }) => {
  const { t } = useTranslation('integration');
  const { errors, isValid } = useErrors();
  const errorRows = Object.values(errors);
  const errorRowsCount = errorRows.length;
  // @ts-expect-error ts(2769) FIXME: No overload matches this call.
  const errorsCount = sum(errorRows.map(row => Object.keys(row).length));

  return (
    <>
      {!isValid && (
        <p>{t('bulkUpload.uploadErrors', { errorRowsCount, errorsCount })}</p>
       )}

      <BulkImportSuppliersTable externalSuppliers={externalSuppliers} />
    </>);
};

const ValidatedSaveButton = ({ externalSuppliers, onImport }) => {
  const { errors } = useErrors();
  return (
    <SaveButton disabled={!externalSuppliers.length || !isEmpty(errors)} onClick={() => onImport(externalSuppliers)} />
  );
};

export const BulkUploadSuppliersModal = ({ onImport, isOpen, onCancel }: Props) => {
  const { t } = useTranslation('integration');
  const [error, setError] = useState('');
  const [viewMode, setViewMode] = useState<'upload' | 'preview'>('upload');
  const [externalSuppliers, setExternalSuppliers] = useState<ExternalSupplier[]>([]);
  const handleFileUploadStart = (file: File) => {
    if (file.type !== 'text/csv') {
      setError(t('bulkUpload.fileTypeError'));
      return;
    }
    parse(file, {
      skipEmptyLines: 'greedy',
      preview: 1000,
      worker: true,
      dynamicTyping: false,
      complete(results: ParseResult<string[]>) {
        const [firstRow, ...otherRows] = results.data;
        let expectedHeaderRow = ['externalSupplierId', 'externalSupplierName', 'externalSupplierContactEmails'];
        const isHeaderRow = expectedHeaderRow.some(headerName => firstRow.includes(headerName));
        if (isHeaderRow) {
          // Include missing headers
          firstRow.push(...difference(expectedHeaderRow, firstRow));
          expectedHeaderRow = firstRow;
        }
        const rows = isHeaderRow ? otherRows : results.data;

        setExternalSuppliers(rows.map(([first, second, third]) => ({
          [expectedHeaderRow[0]]: first?.trim(),
          [expectedHeaderRow[1]]: second?.trim(),
          [expectedHeaderRow[2]]: third?.trim(),
        } as ExternalSupplier)));
      },
    });
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader onClose={onCancel}>{t('bulkUpload.uploadModalTitle')}</ModalHeader>
      <ModalBody width="500px">
        {{
          upload: (
            <>
              <p>{t('bulkUpload.uploadHelperText')}</p>

              <FileList
                onUploadStart={handleFileUploadStart}
                uploadFn={fastFakeUploadFn}
                onChange={(attachments) => {
                  if (attachments.length === 0) {
                    setExternalSuppliers([]);
                    setError('');
                  }
                }}
                limit={1}
              />
              {error ? <ErrorMessage error={error} /> : null}
            </>
          ),
          preview: (
            <Validation schema={externalSupplierListSchema} values={externalSuppliers}>
              <BulkUploadPreviewMode externalSuppliers={externalSuppliers} />
            </Validation>
          ),
        }[viewMode]}
      </ModalBody>
      <ModalFooter>
        <CancelButton mr={2} onClick={onCancel} />
        {{
          upload: (
            <Button type="button" disabled={!externalSuppliers.length} mr={2} onClick={() => setViewMode('preview')}>
              {t('general.next', { ns: 'translation' })}
            </Button>
          ),
          preview: (
            <Validation schema={externalSupplierListSchema} values={externalSuppliers}>
              <ValidatedSaveButton externalSuppliers={externalSuppliers} onImport={onImport} />
            </Validation>
          ),
        }[viewMode]}
      </ModalFooter>
    </Modal>
  );
};
