import { useQueryClient } from 'react-query';
import { RfqEventChange } from '@deepstream/common/rfq-utils';
import { useRfqId, useLiveRfqStructureQueryKey } from './useRfq';
import { useApi } from './api';
import { useCurrentCompanyId } from './currentCompanyId';
import { useMutation } from './useMutation';
import { useWaitForRfqUnlock } from './useWaitForUnlock';

/*
 * Mutations
 */

/**
 * Base mutation for updating live sender request
 */
export const useSaveLiveChanges = <TGetChanges extends (args: any) => RfqEventChange[]> ({
  getChanges,
  onError,
  onSuccess,
}: {
  getChanges: TGetChanges;
  onError: any;
  onSuccess: any;
}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqId = useRfqId();
  const liveStructureQueryKey = useLiveRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
  });
  const queryClient = useQueryClient();
  const waitForRfqUnlock = useWaitForRfqUnlock();

  const mutation = useMutation(
    (payload: Parameters<TGetChanges>[0]) => waitForRfqUnlock({
      command: () => api.updateDraftRequest({
        currentCompanyId,
        rfqId,
        changes: getChanges(payload),
        isRevising: false,
      }),
    }),
    {
      mutationKey: liveStructureQueryKey,
      onError,
      onSuccess,
      onSettled: () => queryClient.invalidateQueries(liveStructureQueryKey),
    },
  );

  return mutation;
};
