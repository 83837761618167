import * as React from 'react';
import { Box } from 'rebass/styled-components';
import { universalSupplierBidStatuses, supplierBidStatuses } from '@deepstream/common/rfq-utils/statusConfigs';
import { CellProps } from 'react-table';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { ExtendedReceivedRequestOverview } from './types';

type ReceivedRequestBidStatusProps = CellProps<ExtendedReceivedRequestOverview>;

export const ReceivedRequestBidStatus: React.FC<ReceivedRequestBidStatusProps> = ({ row }) => {
  const value = row.original.receivedDashboard.bidStatus;
  const { value: icon, color: iconColor } = supplierBidStatuses[value]?.icon ?? {};
  return icon ? (
    <Box alignSelf="flex-start" flex="0 0 auto" mr={2} mt="2px">
      <Icon fixedWidth color={iconColor} icon={icon as IconProps['icon']} />
    </Box>
  ) : null;
};

export const ReceivedRequestUniversalBidStatus: React.FC<ReceivedRequestBidStatusProps> = ({ row }) => {
  const value = row.original.receivedDashboard.universalBidStatus;
  const { value: icon, color: iconColor } = universalSupplierBidStatuses[value]?.icon ?? {};

  return icon ? (
    <Box alignSelf="flex-start" flex="0 0 auto" mr={2} mt="2px">
      <Icon fixedWidth color={iconColor} icon={icon as IconProps['icon']} />
    </Box>
  ) : null;
};
