import { useTranslation } from 'react-i18next';
import { Flex, Box } from 'rebass/styled-components';
import { useField } from 'formik';

import { DocumentQuestionExchangeDefinition, PredefinedQuestionOption } from '@deepstream/common/rfq-utils';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useCallback, useMemo, useState } from 'react';
import { Radio } from '../../ui/Radio';
import { DisabledInputBox } from '../../ui/Input';
import { CheckboxField } from '../../form/CheckboxField';
import { SelectFieldBase } from '../../form/SelectField';

export const DocumentField = ({
  fieldName,
  disabled,
}: {
  fieldName?: string,
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const allowCustomOptionFieldName = fieldName ? `${fieldName}.allowCustomOption` : 'allowCustomOption';
  const requireDocumentFieldName = fieldName ? `${fieldName}.requireDocument` : 'requireDocument';
  const requireExpiryFieldName = fieldName ? `${fieldName}.requireExpiry` : 'requireExpiry';
  const optionsFieldName = fieldName ? `${fieldName}.options` : 'options';
  const [{ value: allowCustomOptionValue }, , allowCustomOptionHelper] = useField<boolean>(allowCustomOptionFieldName);
  const [{ value: options },, optionsHelper] = useField<DocumentQuestionExchangeDefinition['options']>(optionsFieldName);

  const initialOptionsVariant = options[0] === PredefinedQuestionOption.YES ? 'yes-or-no' : 'upload-or-cannot-provide';
  const [optionsVariant, setOptionsVariant] = useState<'yes-or-no' | 'upload-or-cannot-provide'>(initialOptionsVariant);

  const optionLabelVariantItems = useMemo(
    () => [
      {
        value: 'yes-or-no',
        label: t(`request.question.predefinedOption.${PredefinedQuestionOption.YES}`),
      },
      {
        value: 'upload-or-cannot-provide',
        label: t(`request.question.predefinedOption.${PredefinedQuestionOption.UPLOAD_DOCUMENT}`),
      },
    ],
    [t],
  );

  const onChangeOptionsVariant = useCallback(
    (value: 'yes-or-no' | 'upload-or-cannot-provide') => {
      const newOptions = value === 'yes-or-no'
        ? [PredefinedQuestionOption.YES, PredefinedQuestionOption.NO]
        : [PredefinedQuestionOption.UPLOAD_DOCUMENT, PredefinedQuestionOption.CANNOT_PROVIDE];

      optionsHelper.setValue(newOptions);
      setOptionsVariant(value);
    },
    [optionsHelper],
  );

  return (
    <Box>
      <Flex alignItems="flex-start">
        <Stack gap={8} sx={{ marginRight: '16px', flex: '1 1 50%' }}>
          <Flex alignItems="center">
            <Radio disabled />
            <Box flex="1 1 auto">
              <SelectFieldBase
                items={optionLabelVariantItems}
                value={optionsVariant}
                onChange={onChangeOptionsVariant}
              />
            </Box>
          </Flex>
          <Flex alignItems="center">
            <Radio disabled />
            <DisabledInputBox sx={{ flex: '1 1 auto' }}>
              {optionsVariant === 'yes-or-no'
                ? t(`request.question.predefinedOption.${PredefinedQuestionOption.NO}`)
                : t(`request.question.predefinedOption.${PredefinedQuestionOption.CANNOT_PROVIDE}`)
              }
            </DisabledInputBox>
          </Flex>
          {allowCustomOptionValue && (
            <Flex alignItems="center">
              <Radio disabled />
              <DisabledInputBox sx={{ marginRight: 8, flex: '1 1 auto' }}>
                {t('request.question.otherOption')}
              </DisabledInputBox>
              <Button
                variant="secondary-outline"
                width="40px"
                iconLeft="trash"
                sx={{ borderRadius: 4, width: '40px', height: '40px' }}
                onClick={() => allowCustomOptionHelper.setValue(false)}
              />
            </Flex>
          )}
        </Stack>
        <Flex flex="1 1 auto" mt="6px">
          <CheckboxField
            disabled={disabled}
            fieldLabel={t('request.question.document.requireDocument')}
            name={requireDocumentFieldName}
          />
          <CheckboxField
            disabled={disabled}
            fieldLabel={t('request.question.document.requireExpiry')}
            name={requireExpiryFieldName}
            checkboxStyle={{ marginLeft: '12px' }}
          />
        </Flex>
      </Flex>
      <Flex sx={{ gap: 12, marginTop: 3 }}>
        <Button
          small
          iconLeft="plus"
          variant="secondary"
          disabled={allowCustomOptionValue || disabled}
          onClick={() => allowCustomOptionHelper.setValue(true)}
        >
          {t('request.question.addOther')}
        </Button>
      </Flex>
    </Box>
  );
};
