import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useRfqId } from '../../useRfq';
import * as Layout from '../../Layout';
import { RequestPageHeader } from '../../page-headers/RequestPageHeader';
import { RfxAuctionContainer } from './Sent/RfxAuction';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useRequestSentNavigation } from '../../appNavigation';

export const LiveAuctionPageContent = () => {
  const intercom = useIntercom();
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId();
  const navigation = useRequestSentNavigation();

  useEffect(
    () => {
      intercom.update({
        hideDefaultLauncher: true,
        verticalPadding: 72,
      });

      return () => {
        intercom.update({
          hideDefaultLauncher: false,
        });
      };
    },
    [intercom],
  );

  return (
    <Layout.HeaderAndContent
      header={
        <RequestPageHeader selectedTabId="auction" rfqId={rfqId} />
      }
      content={
        <RfxAuctionContainer
          rfqId={rfqId}
          // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string | undefined'.
          navigateToTeam={() => navigation.navigateToTeam({ companyId: currentCompanyId })}
        />
      }
    />
  );
};
