import { useState, useCallback, useMemo } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useQuery } from 'react-query';
import { useNavigate } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { ExtendedReceivedContractOverview, ReceivedContractOverviews } from '@deepstream/common/contract';
import { isEmpty, map } from 'lodash';
import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { Illustration } from '@deepstream/ui-kit';
import { FlexPanelHeader, Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Loading } from '../../ui/Loading';
import { ErrorMessage } from '../../ui/ErrorMessage';
import { MIN_CELL_HEIGHT } from '../../FieldsCell';
import { useModalState } from '../../ui/useModalState';
import { CannotAccessContractDialog } from './CannotAccessContractDialog';
import { ReceivedContractsTable } from './ReceivedContractsTable';
import { useTableData } from '../../TableDataContext';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useApi, wrap } from '../../api';
import { useCurrentUser } from '../../useCurrentUser';
import { useLocalStorageState } from '../../useLocalStorageState';
import { ContractsTableData, ContractsTableDataProvider } from './ContractsTableDataProvider';
import { useRequestTagsContext } from '../RequestTags/RequestTagsContext';
import { ClearFiltersButton, DashboardRoleFilter, useDashboardRoleFilterItems, useLocalStorageFilterProps } from '../../filtering';
import { renderRequestTagLabel } from '../RequestTags/RequestTagLabel';
import { RequestTagFilterItem } from '../../types';
import { EditContractTagsDropdown } from '../RequestTags/EditContractTagsDropdown';
import { SelectDropdownMenu } from '../../ui/MultiSelect';
import { renderName } from '../../RequestsTable';
import { SearchContracts } from './SearchContracts';
import { contractLiveSummaryRoute } from '../../AppRouting';
import { HorizontalDivider } from '../../ui/HorizontalDivider';

const ReceivedContractsContent = ({ selectedRequestTagId }: { selectedRequestTagId?: string }) => {
  const { t } = useTranslation(['contracts', 'translation']);
  const api = useApi();
  const navigate = useNavigate();
  const currentCompanyId = useCurrentCompanyId();
  const cannotAccessContractDialog = useModalState();
  const [selectedContract, setSelectedContract] = useState<ExtendedReceivedContractOverview | null>(null);
  const { query, pageSize, roleFilter, requestTagsFilter, selectedItemsIds, selectAllItemsOnPage, unselectAllItems } =
    useTableData<ContractsTableData<ReceivedContractOverviews>>({ required: true });

  const { status, data, refetch } = query;
  const { overviews, totalItems = 0 } = data ?? {};

  const openCannotAccessContractDialog = useCallback(
    (contract) => {
      setSelectedContract(contract);
      cannotAccessContractDialog.open();
    },
    [cannotAccessContractDialog],
  );

  const handleTagsChange = () => {
    // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
    unselectAllItems();
    refetch();
  };

  const hasFilters = (
    !isEmpty(roleFilter.selectedItems) ||
    !isEmpty(requestTagsFilter.selectedItems)
  );

  // @ts-expect-error ts(18048) FIXME: 'selectedItemsIds' is possibly 'undefined'.
  const showEditTagsButton = selectedItemsIds.length > 0;
  const disableTableControls = showEditTagsButton || status !== 'success';

  return (
    <>
      <Panel>
        <FlexPanelHeader
          heading={overviews ? (
            <Flex alignItems="center">
              {overviews.length > 0 && (
                <Checkbox
                  style={{ paddingRight: '12px' }}
                  // @ts-expect-error ts(18048) FIXME: 'selectedItemsIds' is possibly 'undefined'.
                  checked={selectedItemsIds.length > 0 && selectedItemsIds.length === overviews.length}
                  // @ts-expect-error ts(18048) FIXME: 'selectedItemsIds' is possibly 'undefined'.
                  indeterminate={selectedItemsIds.length > 0 && selectedItemsIds.length !== overviews.length}
                  onChange={(event) => {
                    if ((event.target as HTMLInputElement).checked) {
                      // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
                      selectAllItemsOnPage();
                    } else {
                      // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
                      unselectAllItems();
                    }
                  }}
                />
              )}
              <Text fontSize={2}>
                {/*
                 // @ts-expect-error ts(18048) FIXME: 'selectedItemsIds' is possibly 'undefined'. */}
                {selectedItemsIds.length > 0
                  // @ts-expect-error ts(18048) FIXME: 'selectedItemsIds' is possibly 'undefined'.
                  ? t('contractCountSelected', { count: totalItems, selectedCount: selectedItemsIds.length })
                  : t('contractCount', { count: totalItems })}
              </Text>
            </Flex>
          ) : null}
          childrenContainerProps={{ 'data-test': 'contracts-table-actions' }}
        >
          {showEditTagsButton ? (
            // @ts-expect-error ts(2322) FIXME: Type 'string[] | undefined' is not assignable to type 'string[]'.
            <EditContractTagsDropdown contractIds={selectedItemsIds} onSave={handleTagsChange} />
            ) : (
              <>
                {status === 'success' && hasFilters && (
                  <Box m={1}>
                    <ClearFiltersButton
                      onClick={() => {
                        roleFilter.onChange([]);
                        requestTagsFilter.onChange([]);
                      }}
                    />
                  </Box>
                )}
                <Box m={1}>
                  <SelectDropdownMenu
                    multi
                    buttonText={t('tag', { ns: 'general' })}
                    buttonIcon="filter"
                    menuWidth={300}
                    truncate={false}
                    menuZIndex={10}
                    disabled={status !== 'success'}
                    // @ts-expect-error ts(2322) FIXME: Type 'null' is not assignable to type 'number | undefined'.
                    itemHeight={null}
                    renderPreItemContent={(tag, index) => !tag.parentTagId && index > 0 ? <HorizontalDivider my={2} /> : null}
                    // @ts-expect-error ts(2783) FIXME: 'itemToString' is specified more than once, so this usage will be overwritten.
                    itemToString={(item) => item?._id}
                    {...requestTagsFilter}
                  />
                </Box>
                <Box m={1}>
                  <SelectDropdownMenu
                    multi
                    buttonText={t('yourRole', { ns: 'company' })}
                    buttonIcon="filter"
                    menuWidth={180}
                    menuZIndex={10}
                    disabled={status !== 'success'}
                    {...roleFilter}
                  />
                </Box>
                <Box m={1}>
                  <SearchContracts<ExtendedReceivedContractOverview>
                    queryFn={api.getReceivedContracts}
                    getResultText={(contract: ExtendedReceivedContractOverview) => contract.name}
                    onResultClick={(contract: ExtendedReceivedContractOverview) => contract.hasAccess
                      ? navigate({
                        to: contractLiveSummaryRoute.to,
                        // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
                        params: { currentCompanyId, contractId: contract._id },
                      })
                      : openCannotAccessContractDialog(contract)
                    }
                    disabled={disableTableControls}
                  />
                </Box>
              </>
            )}
        </FlexPanelHeader>
        <PanelDivider />
        {status === 'success' && totalItems > 0 ? (
          <ReceivedContractsTable
            openCannotAccessContractDialog={openCannotAccessContractDialog}
          />
        ) : (
          <Flex
            height={MIN_CELL_HEIGHT * (status === 'loading' ? pageSize : 5)}
            flexDirection="column"
            justifyContent="center"
          >
            <PanelPadding>
              {status === 'loading' ? (
                <Loading fontSize={4} fontWeight={400} />
              ) : status === 'error' ? (
                <ErrorMessage fontSize={3} error={t('errors.getContracts')} />
              ) : hasFilters ? (
                <Illustration
                  variant="search-results"
                  label={
                    <Flex flexDirection="column" sx={{ gap: 2 }}>
                      <Text>{t('noResultsMatchYourFilters', { ns: 'general' })}</Text>
                      <ClearFiltersButton
                        onClick={() => { requestTagsFilter.onChange([]); }}
                        alignSelf="center"
                      />
                    </Flex>
                  }
                />
              ) : (
                <Illustration
                  variant="empty-state"
                  label={selectedRequestTagId
                    ? t('requests.tags.noContracts', { ns: 'translation' })
                    : t('companyHasNotReceivedContracts')
                  }
                />
              )}
            </PanelPadding>
          </Flex>
        )}
      </Panel>
      <CannotAccessContractDialog
        isOpen={cannotAccessContractDialog.isOpen}
        onCancel={cannotAccessContractDialog.close}
        contract={selectedContract}
      />
    </>
  );
};

export const ReceivedContracts = ({ selectedRequestTagId }: { selectedRequestTagId?: string }) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();
  const api = useApi();
  const { tags, tagById } = useRequestTagsContext();

  const filterItems = useQuery(
    ['receivedContractFilterItems', { currentCompanyId }],
    wrap(api.getReceivedContractsFilterItems),
  );

  const dashboardRoleFilterItems = useDashboardRoleFilterItems(
    filterItems.data?.roles,
  );

  const roleFilterProps = useLocalStorageFilterProps({
    storageKey: `${currentCompanyId}.${user._id}.receivedContracts.roleFilter`,
    items: dashboardRoleFilterItems,
    idProp: '_id',
    renderItem: renderName,
    getQueryParam: (selectedItems: DashboardRoleFilter[]) => ({
      roles: map(selectedItems, '_id').sort(),
    }),
  });

  const requestTagsFilterItems = useMemo(
    () => tags.filter(({ _id }) => _id !== selectedRequestTagId),
    [tags, selectedRequestTagId],
  ) as RequestTagFilterItem[];

  const requestTagsFilterProps = useLocalStorageFilterProps({
    storageKey: `${currentCompanyId}.${user._id}.sentContracts.requestTagsFilter`,
    items: requestTagsFilterItems,
    idProp: '_id',
    // @ts-expect-error ts(2345) FIXME: Argument of type 'RequestTagFilterItem | null' is not assignable to parameter of type 'RequestTag'.
    renderItem: (requestTag) => renderRequestTagLabel(requestTag, tagById),
    getQueryParam: (selectedItems: RequestTagFilterItem[]) => ({
      // sort so the same set of IDs always results in the
      // same query key irrespective of the order of selection
      requestTags: map(selectedItems, '_id'),
    }),
  });

  const pageSizeState = useLocalStorageState<number>({
    key: `${currentCompanyId}.${user._id}.receivedContracts.pageSize`,
    defaultValue: 10,
  });

  return (
    <ContractsTableDataProvider
      queryTag="receivedContracts"
      queryFn={api.getReceivedContracts}
      roleFilter={roleFilterProps}
      requestTagsFilter={requestTagsFilterProps}
      preselectedTag={selectedRequestTagId}
      pageSizeState={pageSizeState}
    >
      <ReceivedContractsContent selectedRequestTagId={selectedRequestTagId} />
    </ContractsTableDataProvider>
  );
};
