import { useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { InlineButton } from '@deepstream/ui-kit/elements/button/InlineButton';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import * as rfx from '../../../rfx';

export const InactiveLotsToggle = ({
  inactiveLotCount,
  setShowInactiveLots,
  showInactiveLots,
  areAllLotsInactive,
}: {
  inactiveLotCount: number;
  setShowInactiveLots: (hidden: boolean) => void;
  showInactiveLots: boolean;
  areAllLotsInactive?: boolean;
}) => {
  const { t } = useTranslation('translation');
  const currentCompanyGroup = rfx.useCurrentCompanyGroup();

  return (
    <Text mt="12px">
      {areAllLotsInactive ? (
        t('request.lots.allLotsAreInactive')
      ) : (
        t('request.lots.inactiveLotCount', { count: inactiveLotCount })
      )}
      {currentCompanyGroup === 'supplier' ? (
        <Tooltip content={t('request.lots.inactiveLotsTooltip')}>
          <Icon icon="info-circle" regular color="lightGray5" ml={1} mr="12px" sx={{ top: '1px' }} />
        </Tooltip>
      ) : (
        <Box as="span" ml={3} />
      )}
      <InlineButton
        onClick={() => setShowInactiveLots(!showInactiveLots)}
      >
        {showInactiveLots ? (
          t('request.lots.hideInactiveLot', { count: inactiveLotCount })
        ) : (
          t('request.lots.showInactiveLot', { count: inactiveLotCount })
        )}
      </InlineButton>
    </Text>
  );
};
