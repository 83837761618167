import { useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { compact, map, isEmpty, remove, noop, isEqual, values } from 'lodash';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Form, Formik, useField, useFormikContext } from 'formik';
import { CellProps } from 'react-table';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';
import { useMachine } from '@xstate/react';
import { CompanyMinimized } from '@deepstream/common/rfq-utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { ModalFooter } from '@deepstream/ui-kit/elements/popup/Modal';
import { useMutation } from '../../useMutation';
import { useApi } from '../../api';
import { TextField } from '../../form/TextField';
import { StepFooter } from '../../StepFooter';
import * as rfx from '../../rfx';
import { CompanyLogoAndName } from '../../CompanyLogo';
import { useUploadApi } from '../../ExchangeModal/useUpload';
import { StageApprovalRequestActions, stageApprovalRequestInitialContext, stageApprovalRequestMachine } from './requestApprovalMachine';
import { BasicTableStyles } from '../../TableStyles';
import { Table } from '../../Table';
import { FileList } from '../../ui/FileList';
import { MultiSelectFinderField } from '../../form/MultiSelectFinderField';
import { filterUserItems, UserBase } from '../Request/Team/UserSelect';
import { UserDetails } from '../../UserDetails';
import { useDraftRfqStructureQueryKey, useLiveRfqStructureQueryKey, useRfqId } from '../../useRfq';
import { useIsFormDirty } from '../../pre-q/useIsFormDirty';
import { useToaster } from '../../toast';
import { matchStringValue } from '../../searchUtils';
import { ErrorMessage } from '../../form/Field';
import { FieldContainer } from '../../form/FieldContainer';
import { LabelConfig, LabelConfigProvider } from '../../LabelConfigProvider';
import { StageApprovalBaseModal } from './StageApprovalBaseModal';
import { StageApprovalDetails } from './StageApprovalDetails';
import { useStageApprovalLabelStyle } from './useStageApprovalLabelStyle';
import { Approver } from './Approvers';
import { SuppliersToApprove } from './SuppliersToApprove';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { ApprovalStageName } from './ApprovalStageName';
import { useCurrentUser } from '../../useCurrentUser';
import { useStageWithIndex } from './useStageWithIndex';
import { useStageSelectItems } from '../../draft/useStageSelectItems';
import { SelectFieldBase } from '../../form/SelectField';

const modalSteps = {
  stageApprovalEnterDetails: 'stageApprovalEnterDetails',
  selectStageApprovers: 'selectStageApprovers',
  stageApprovalReviewAndIssue: 'stageApprovalReviewAndIssue',
};

const RequestApprovalMachineContext = React.createContext(null as any);

type FileListFieldProps = {
  onUploadStatusChange?: (isUploading: boolean) => void;
};

const FileListField = ({ onUploadStatusChange }: FileListFieldProps) => {
  const [field, , attachmentsFormik] = useField({
    name: 'attachments',
  });

  const [upload] = useUploadApi({ purpose: 'rfq' });

  return (
    <FileList
      uploadFn={upload}
      onChange={(attachments) => {
        attachmentsFormik.setValue(attachments);
      }}
      onUploadStatusChange={onUploadStatusChange}
      initialAttachments={field.value}
    />
  );
};

const ReadOnlyApprovalStageField = ({ stageId }: { stageId?: string }) => {
  const { t } = useTranslation('translation');
  const { stage, stageIndex } = useStageWithIndex(stageId);

  return (
    <FieldContainer
      name="stageName"
      label={t('request.stageApprovals.approvalStage')}
    >
      <ApprovalStageName stage={stage} index={stageIndex} />
    </FieldContainer>
  );
};

const StageSelectField = ({ availableStageIds }: { availableStageIds: string [] }) => {
  const { t } = useTranslation('translation');
  const stageSelectItems = useStageSelectItems(availableStageIds);

  const [, , recipientsHelpers] = useField('recipients');
  const [{ value: stageId }, , stageIdHelpers] = useField('stageId');

  return (
    <SelectFieldBase
      label={t('request.stageApprovals.approvalStage')}
      items={stageSelectItems}
      value={stageId}
      onChange={async (stageId) => {
        await stageIdHelpers.setValue(stageId);
        await recipientsHelpers.setValue([], true);
        await stageIdHelpers.setTouched(true);
      }}
    />
  );
};

const ApprovalDetailsForm = () => {
  const { t } = useTranslation('translation');
  const structure = rfx.useStructure();
  const labelStyle = useStageApprovalLabelStyle();

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const { service, context } = React.useContext(RequestApprovalMachineContext);

  const { availableStageIds } = context;

  const [{ value: stageId }] = useField('stageId');
  const [{ value: recipients }] = useField('recipients');

  const availableRecipients = React.useMemo(() => {
    const { recipients, bidById } = structure;

    if (!stageId) {
      // when there's no stageId then we're dealing with
      // draft stage approvals, which are available for
      // all recipients
      return recipients.map(recipient => recipient.company);
    } else {
      return recipients
        .filter((recipient) => bidById[recipient._id]?.stageId === stageId)
        .map((recipient) => recipient.company);
    }
  }, [structure, stageId]);

  const searchCompanies = (text: string) => {
    const trimmedText = text.trim();
    if (!trimmedText) {
      return availableRecipients;
    }

    return availableRecipients.filter(({ name }) => matchStringValue(trimmedText, name));
  };

  const { isValid } = useFormikContext();

  const isDirty = useIsFormDirty();

  React.useEffect(() => {
    service.send(StageApprovalRequestActions.SET_IS_DIRTY, { data: { isDirty } });
  }, [isDirty, service]);

  return (
    <Form
      style={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <LabelConfigProvider
        variant={LabelConfig.LEFT}
        width="180px"
        style={{
          stageName: labelStyle,
          suppliersToApprove: labelStyle,
          approvers: labelStyle,
          message: labelStyle,
          attachments: labelStyle,
        }}
      >
        <Flex
          flexDirection="column"
          sx={{
            rowGap: '16px',
            padding: '16px 16px 0 16px',
          }}
        >
          {!availableStageIds || isEmpty(availableStageIds) ? (
            <ReadOnlyApprovalStageField stageId={stageId} />
          ) : (
            <StageSelectField availableStageIds={availableStageIds} />
          )}

          <FieldContainer
            name="suppliersToApprove"
            label={t('request.stageApprovals.suppliersToApprove')}
            showAsterisk
          >
            <Box>
              <MultiSelectFinderField<CompanyMinimized>
                name="recipients"
                filterPlaceholder={t('companyFinder.searchByCompanyName')}
                emptyFilteredItemsMessage={t('companySelect.noCompaniesFound')}
                items={recipients}
                filterItems={searchCompanies}
                disabled={isEmpty(availableRecipients)}
                Item={(props) => (
                  <CompanyLogoAndName company={props.item} size="xs" />
                )}
                SelectedItems={({
                  items,
                }: {
                  items: CompanyMinimized[];
                  disabled: boolean;
                }) => {
                  if (isEmpty(items)) {
                    return (
                      <Text>
                        {t('request.stageApprovals.selectSuppliersInThisStage')}
                      </Text>
                    );
                  } else {
                    return (
                      <SuppliersToApprove companyIds={map(items, '_id')} />
                    );
                  }
                }}
              />
              {isEmpty(availableRecipients) && (
                <Box sx={{ wordBreak: 'break-word', textAlign: 'left' }}>
                  <ErrorMessage
                    error={t('request.stageApprovals.noSuppliersInThisStage')}
                    fontWeight="normal"
                  />
                </Box>
              )}
            </Box>
          </FieldContainer>

          <FieldContainer
            name="message"
            label={t('general.description')}
            showAsterisk
          >
            <TextField
              name="message"
              placeholder={t(
                'request.stageApprovals.requestDescriptionPlaceholder',
              )}
              isMultiLine
              required
            />
          </FieldContainer>

          <FieldContainer
            name="attachments"
            label={t('file_other', { ns: 'general' })}
          >
            <FileListField onUploadStatusChange={setIsUploading} />
          </FieldContainer>
        </Flex>
      </LabelConfigProvider>
      <StepFooter
        steps={modalSteps}
        activeState={modalSteps.stageApprovalEnterDetails}
        isBackVisible={false}
        onContinue={noop}
        primaryButtonText={t('general.next')}
        isNextDisabled={!isValid || isUploading}
      />
    </Form>
  );
};

const ApprovalDetails = () => {
  const { t } = useTranslation('translation');
  const { service, context } = React.useContext(RequestApprovalMachineContext);
  const { recipients, message, attachments, stageId } = context;

  return (
    <Flex height="100%" flexDirection="column">
      <Formik
        validateOnBlur
        enableReinitialize
        initialValues={{
          stageId,
          recipients,
          message,
          attachments,
        }}
        validationSchema={yup.object().shape({
          recipients: yup.array().of(yup.object()).required(t('general.required')),
          message: yup.string().required(t('general.required')),
          attachments: yup.array().of(yup.object()),
        })}
        onSubmit={(values) => {
          service.send(StageApprovalRequestActions.ADD_REQUEST_DETAILS, { data: values });
        }}
      >
        <ApprovalDetailsForm />
      </Formik>
    </Flex>
  );
};

const ApproversHeader = ({ requestTeamUsers }: ApprovalSelectFormProps) => {
  const { t } = useTranslation('translation');
  const theme = useTheme();

  return (
    <Flex justifyContent="space-between" px="10px">
      <Flex sx={{ columnGap: '16px' }} alignItems="center">
        <Text sx={{ lineHeight: '18px' }}>
          {t('request.stageApprovals.approver', { count: 1 })}
        </Text>
        <Tooltip
          content={t('request.stageApprovals.requestApproversTooltip')}
        >
          <Box>
            <IconText
              icon="question-circle"
              iconPosition="right"
              text={t('general.moreInfo')}
              color={theme.colors.subtext}
              textStyle={{
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '18px',
              }}
            />
          </Box>
        </Tooltip>
      </Flex>
      <Box>
        <MultiSelectFinderField<UserBase>
          name="approvers"
          showValidationError={false}
          filterPlaceholder={t('userSelect.searchByNameOrEmail')}
          emptyFilteredItemsMessage={t('userSelect.noUsersFound')}
          items={requestTeamUsers as UserBase[]}
          filterItems={filterUserItems}
          Item={(props) => <UserDetails user={props.item} />}
          StaticSelectButtonContent={() => (
            <IconText
              icon="plus"
              text={t('request.stageApprovals.addApprover')}
              color={theme.colors.primary}
              textStyle={{
                fontWeight: 500,
                fontSize: '12px',
              }}
            />
          )}
          popoverWidth={400}
          popoverPlacement="bottom-end"
          selectContainerStyle={{
            backgroundColor: 'transparent',
            border: 'none',
            width: 'unset',
            height: '28px',
          }}
          selectButtonStyle={{ padding: '0px 8px' }}
        />
      </Box>
    </Flex>
  );
};

const ApproverCell = ({ cell: { value: userId } }: CellProps<UserBase, string>) => {
  const theme = useTheme();
  const { values, setValues } = useFormikContext<{ approvers: UserBase[] }>();

  return (
    <Flex justifyContent="space-between" sx={{ paddingLeft: '10px' }}>
      <Approver userId={userId} />
      <Button
        variant="tooltip"
        onClick={(event) => {
          event.preventDefault();
          setValues({
            approvers: remove(
              values.approvers,
              (approver) => approver._id !== userId,
            ),
          });
        }}
        sx={{
          width: '40px',
          borderRadius: '0px',
          backgroundColor: theme.colors.lightGray3,
          borderLeft: `1px solid ${theme.colors.secondary}`,
        }}
      >
        <Icon icon="xmark" sx={{ color: theme.colors.darkGray }} />
      </Button>
    </Flex>
  );
};

type ApprovalSelectFormProps = {
  requestTeamUsers: UserBase[];
};

const ApprovalsSelectForm = ({ requestTeamUsers }: ApprovalSelectFormProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { service } = React.useContext(RequestApprovalMachineContext);

  const columns = React.useMemo(() => {
    return compact([
      {
        id: 'approver',
        Header: (<ApproversHeader requestTeamUsers={requestTeamUsers} />),
        accessor: '_id',
        Cell: ApproverCell,
      },
    ]);
  }, [requestTeamUsers]);

  const { isValid, values } = useFormikContext<{ approvers: UserBase[] }>();

  const sortedValues = React.useMemo(() => {
    return values.approvers.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
  }, [values.approvers]);

  return (
    <Form
      style={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <PanelPadding>
        <BasicTableStyles
          headerTextColor={theme.colors.lightNavy}
          headerBackgroundColor={theme.colors.lightGray3}
          customRowHeight="40px"
          cellPaddingX="0px"
          hoverCursor="default"
          hoverBackgroundColor="background"
          bordered
        >
          <Table
            columns={columns}
            data={sortedValues}
            noDataPlaceholder={t('request.stageApprovals.addApproversPlaceholder')}
          />
        </BasicTableStyles>
      </PanelPadding>
      <StepFooter
        steps={modalSteps}
        activeState={modalSteps.selectStageApprovers}
        isBackVisible={true}
        onContinue={noop}
        onBack={() => {
          service.send(StageApprovalRequestActions.BACK);
        }}
        primaryButtonText={t('general.next')}
        isNextDisabled={!isValid}
      />
    </Form>
  );
};

const ApprovalsSelect = () => {
  const { t } = useTranslation('translation');
  const currentUser = useCurrentUser();
  const structure = rfx.useStructure();

  const sortedSenderUsers = React.useMemo(() => {
    const { senders, teamById } = structure;

    const senderUsers = senders.flatMap(sender => values(teamById[sender._id].users));

    return (senderUsers as UserBase[])
      .filter((user) => user._id !== currentUser._id)
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
  }, [structure, currentUser._id]);

  const { service, context } = React.useContext(RequestApprovalMachineContext);

  return (
    <Formik<{ approvers: UserBase[] }>
      validateOnMount
      enableReinitialize
      initialValues={{
        approvers: context.approvers,
      }}
      validationSchema={yup.object().shape({
        approvers: yup
          .array()
          .of(yup.object())
          .required(t('general.required')),

      })}
      onSubmit={(values) => {
        service.send(StageApprovalRequestActions.ADD_APPROVERS, { data: values });
      }}
    >
      <ApprovalsSelectForm requestTeamUsers={sortedSenderUsers} />
    </Formik>
  );
};

type ReviewAndIssueProps = {
  handleClose: () => void;
};

const ReviewAndIssue = ({ handleClose }: ReviewAndIssueProps) => {
  const { t } = useTranslation();
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();

  const { service, context } = React.useContext(RequestApprovalMachineContext);
  const { rfqId, stageId, message, recipients, approvers, attachments } = context;

  const { stage, stageIndex } = useStageWithIndex(stageId);

  const currentCompanyId = useCurrentCompanyId({ required: true });
  const liveStructureQueryKey = useLiveRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
  });
  const draftStructureQueryKey = useDraftRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
  });
  const [requestApproval, { isLoading, isSuccess }] = useMutation(
    api.requestStageApproval,
    {
      onError: () => toaster.error(t('error.somethingWentWrong', { ns: 'supplierDiscovery' })),
      onSuccess: () => service.send(StageApprovalRequestActions.SUBMIT_SUCCESS),
      onSettled: callAll(
        () => queryClient.invalidateQueries(liveStructureQueryKey),
        () => queryClient.invalidateQueries(['allExchanges', { rfqId, currentCompanyId }]),
        () => queryClient.invalidateQueries(['exchanges', { rfqId, currentCompanyId }]),
        () => queryClient.invalidateQueries(['statsByRecipientId', { rfqId, currentCompanyId }]),
        () => queryClient.invalidateQueries(draftStructureQueryKey),
        () => queryClient.invalidateQueries(['rfqApprovalExchanges', { rfqId, currentCompanyId }]),
      ),
    },
  );

  return (
    <Flex flexDirection="column" height="100%" justifyContent="space-between">
      <Flex
        flexDirection="column"
        sx={{
          rowGap: '16px',
          padding: '16px 16px 0 16px',
        }}
      >
        <MessageBlock
          variant={isSuccess ? 'success' : 'warn'}
          mt={0}
          px="20px"
          py="14px"
        >
          {isSuccess
            ? t('request.stageApprovals.submitSuccess')
            : t('request.stageApprovals.preSubmitWarning')}
        </MessageBlock>
        <StageApprovalDetails
          stage={stage}
          stageIndex={stageIndex}
          recipientIds={map(recipients, '_id')}
          message={message}
          attachments={attachments}
          approverIds={map(approvers, '_id')}
          isApprovalSubmitted={isSuccess}
        />
      </Flex>
      {!isLoading ? (
        isSuccess ? (
          <ModalFooter alignItems="flex-end">
            <Button variant="primary" onClick={() => handleClose()}>
              {t('close', { ns: 'general' })}
            </Button>
          </ModalFooter>
        ) : (
          <StepFooter
            steps={modalSteps}
            activeState={modalSteps.stageApprovalReviewAndIssue}
            isBackVisible={true}
            onContinue={async () => {
              const { stageId, recipients, approvers, message, attachments } =
                context;
              await requestApproval({
                rfqId,
                ...(stageId && { stageId }),
                recipientIds: map(recipients, '_id'),
                approverIds: map(approvers, '_id'),
                message,
                attachments,
                currentCompanyId,
              });
            }}
            onBack={() => {
              service.send(StageApprovalRequestActions.BACK);
            }}
            primaryButtonText={t('submit', { ns: 'general' })}
          />
        )
      ) : null}
    </Flex>
  );
};

type StageApprovalRequestModalProps = {
  initialStageId?: string;
  /**
   * When defined, we allow users to choose a stage;
   * otherwise, the stage is predefined by `initialStageId`.
   */
  availableStageIds?: string[];
  initialRecipients?: CompanyMinimized[];
  close: any;
  isOpen: boolean;
  props?: any;
};

export const StageApprovalRequestModal: React.FC<StageApprovalRequestModalProps> = ({
  initialStageId,
  availableStageIds,
  initialRecipients = [],
  close,
  isOpen,
  ...props
}) => {
  const { t } = useTranslation();
  const rfqId = useRfqId({ required: true });

  const initialMachineContext = React.useMemo(() => {
    return {
      ...stageApprovalRequestInitialContext,
      rfqId,
      stageId: initialStageId,
      availableStageIds,
      recipients: initialRecipients,
    };
  }, [rfqId, initialStageId, availableStageIds, initialRecipients]);

  const [currentState, send, service] = useMachine(
    stageApprovalRequestMachine.withContext(initialMachineContext),
  );

  const handleClose = React.useCallback(() => {
    send(StageApprovalRequestActions.RESET_TO_INITIAL_CONTEXT, { data: initialMachineContext });
    close();
  },
    [close, send, initialMachineContext],
  );

  const { context } = currentState;
  const needsCloseConfirmation = React.useCallback(() => {
    return !currentState.matches('submitSuccess') && (context.isDirty || !isEqual(context, initialMachineContext));
  }, [context, currentState, initialMachineContext]);

  const requestApprovalMachineContext = React.useMemo(() => {
    return { service, context };
  }, [service, context]);

  return (
    <StageApprovalBaseModal
      isOpen={isOpen}
      title={t('request.stageApprovals.newApprovalRequest')}
      onClose={handleClose}
      needsCloseConfirmation={needsCloseConfirmation}
      height="400px"
      {...props}
    >
      <RequestApprovalMachineContext.Provider
        value={requestApprovalMachineContext}
      >
        {currentState.matches('approvalDetails') ? (
          <ApprovalDetails />
        ) : currentState.matches('approversSelect') ? (
          <ApprovalsSelect />
        ) : currentState.matches('reviewAndIssue') ||
          currentState.matches('submitSuccess') ? (
            <ReviewAndIssue handleClose={handleClose} />
        ) : null}
      </RequestApprovalMachineContext.Provider>
    </StageApprovalBaseModal>
  );
};
