import { useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { Live } from '@deepstream/common/rfq-utils';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { isEmpty, isFinite, noop } from 'lodash';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { localeFormatPrice } from '@deepstream/utils';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData } from '../types';
import * as rfx from '../../../../rfx';
import { RadioField } from '../../../../form/RadioField';
import { useCurrentUserLocale } from '../../../../useCurrentUser';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { Disclosure2 } from '../../../../ui/Disclosure';
import { useCalculatedTotalValue } from '../useCalculatedTotalValue';
import { Direction } from '../../../../ui/MultiStepFlow/types';

const useOptions = () => {
  const { t } = useTranslation('translation');

  return useMemo(() => {
    return ['yes', 'no'].map(value => ({
      value,
      label: (
        <Box fontSize={4} color="text">
          {t(`request.awardFlow.steps.confirmTotalValue.options.${value}`)}
        </Box>
      ),
    }));
  }, [t]);
};

const getSubmissionDataFromFormValues = (
  { isCalculatedTotalValueAccurate, calculatedTotalValue }: {
    isCalculatedTotalValueAccurate: string;
    calculatedTotalValue?: number | null;
  },
  data: AwardFlowData,
): Partial<AwardFlowData> | null => {
  if (isCalculatedTotalValueAccurate) {
    return {
      spendAndSavings: {
        ...data.spendAndSavings,
        isCalculatedTotalValueAccurate: isCalculatedTotalValueAccurate === 'yes',
        calculatedTotalValue,
      },
    };
  } else {
    return null;
  }
};

export const ConfirmTotalValueStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (data: Partial<AwardFlowData> | null, direction: Direction) => void,
}) => {
  const { t } = useTranslation('translation');
  const locale = useCurrentUserLocale();
  const { currencyCode } = rfx.useStructure<Live>();
  const options = useOptions();
  const theme = useTheme();

  const calculatedTotalValue = useCalculatedTotalValue(data);

  const formattedValue = isFinite(calculatedTotalValue)
    ? localeFormatPrice(calculatedTotalValue, currencyCode, { locale, showCode: true })
    : '—';

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      isCalculatedTotalValueAccurate: z.string({ message: t('request.awardFlow.errors.selectCalculatedTotalValueAccurate') }),
    });

    return toFormikValidationSchema(Schema);
  }, [t]);

  return (
    <Formik
      validateOnBlur
      initialValues={{
        calculatedTotalValue,
        isCalculatedTotalValueAccurate: data.spendAndSavings.isCalculatedTotalValueAccurate ? (
          'yes'
        ) : data.spendAndSavings.isCalculatedTotalValueAccurate === false ? (
          'no'
        ) : (
          undefined
        ),
      }}
      validationSchema={validationSchema}
      onSubmit={noop}
    >
      {({ values, submitForm, validateForm, dirty }) => (
        <Form>
          <lotPagesLayout.ContentWrapper>
            <StepNavigation
              onBackClick={() => {
                // @ts-expect-error ts(2345) FIXME: Argument of type '{ calculatedTotalValue: number; isCalculatedTotalValueAccurate: string | undefined; }' is not assignable to parameter of type '{ isCalculatedTotalValueAccurate: string; calculatedTotalValue?: number | null | undefined; }'.
                submitAndNavigate(dirty ? getSubmissionDataFromFormValues(values, data) : null, Direction.BACK);
              }}
              onContinueClick={async () => {
                const errors = await validateForm();

                await submitForm();

                if (isEmpty(errors)) {
                  // @ts-expect-error ts(2345) FIXME: Argument of type '{ calculatedTotalValue: number; isCalculatedTotalValueAccurate: string | undefined; }' is not assignable to parameter of type '{ isCalculatedTotalValueAccurate: string; calculatedTotalValue?: number | null | undefined; }'.
                  submitAndNavigate(dirty ? getSubmissionDataFromFormValues(values, data) : null, Direction.FORWARD);
                }
              }}
            >
              <FormErrors />
              <lotPagesLayout.Section
                heading={t('request.awardFlow.steps.confirmTotalValue.heading', { value: formattedValue })}
              >
                <Box mt="20px">
                  <RadioField
                    name="isCalculatedTotalValueAccurate"
                    options={options}
                    showError
                    fieldLabelStyle={{
                      fontSize: theme.fontSizes[4],
                      fontWeight: 500,
                      color: theme.colors.text,
                      marginBottom: '12px',
                    }}
                    variant="inline"
                    gap="2px"
                    errorMessageStyle={{ fontSize: '14px', marginTop: '16px' }}
                  />
                </Box>
                <lotPagesLayout.InfoText mt="20px">
                  {t('request.awardFlow.steps.confirmTotalValue.info')}
                </lotPagesLayout.InfoText>
                <Text mt={3}>
                  <Disclosure2
                    width="100%"
                    summary={t('request.awardFlow.steps.confirmTotalValue.disclosure.summary')}
                  >
                    <Text mb={2}>{t('request.awardFlow.steps.confirmTotalValue.disclosure.p1')}</Text>
                    <Text mb={2}>{t('request.awardFlow.steps.confirmTotalValue.disclosure.p2')}</Text>
                    <Text mb={2}>{t('request.awardFlow.steps.confirmTotalValue.disclosure.p3')}</Text>
                    <lotPagesLayout.Ul>
                      <li>{t('request.awardFlow.steps.confirmTotalValue.disclosure.li1')}</li>
                      <li>{t('request.awardFlow.steps.confirmTotalValue.disclosure.li2')}</li>
                      <li>{t('request.awardFlow.steps.confirmTotalValue.disclosure.li3')}</li>
                    </lotPagesLayout.Ul>
                  </Disclosure2>
                </Text>
              </lotPagesLayout.Section>
            </StepNavigation>
          </lotPagesLayout.ContentWrapper>
        </Form>
      )}
    </Formik>
  );
};
