import { useRouter } from '@tanstack/react-router';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Live } from '@deepstream/common/rfq-utils';
import { uniqBy } from 'lodash';
import * as rfx from '../../../rfx';
import { useRecipientId } from '../../../useRfq';

/**
 * Returns the route matches with a getTitle property that's not
 * inherited from an ancestor route.
 */
const getMatchesWithTitle = (matches) => {
  return uniqBy(
    [...matches].filter(match => match.context.getTitle),
    match => match.context.getTitle,
  );
};

export const useRecipientPageHeaderConfig = () => {
  const router = useRouter<any>();
  const { t } = useTranslation('translation');
  const structure = rfx.useStructure<Live>();
  const recipientId = useRecipientId({ required: false });

  return useMemo(() => {
    const matchesWithTitle = getMatchesWithTitle(router.state.matches);

    const lastMatchWithTitle = matchesWithTitle.pop();

    return {
      heading: lastMatchWithTitle.context.getTitle(t, structure, recipientId),
      breadcrumbs: matchesWithTitle.map(match => ({
        title: match.context.getTitle(t, structure, recipientId),
        linkProps: {
          to: match.routeId,
          params: match.params,
        },
      })),
    };
  }, [t, structure, recipientId, router.state.matches]);
};
