import { SortingDirection } from '@deepstream/common';
import { compact } from 'lodash';
import { Live, PageType } from '@deepstream/common/rfq-utils';
import { useMemo } from 'react';
import * as rfx from '../../../rfx';

export type GroupingCriterion =
  | 'stageId'
  | 'lotId'
  | 'bidIntentionStatus'
  | 'bidOutcomeStatus';

export type ViewId =
  | 'byCurrentStage'
  | 'byLot'
  | 'byBidStatus'
  | 'auction'
  | 'locks'
  | 'evaluation'
  | 'byBidOutcome'
  | 'myView';

export const orderedViewIds: ViewId[] = [
  'byCurrentStage',
  'byLot',
  'byBidStatus',
  'auction',
  'locks',
  'evaluation',
  'byBidOutcome',
  'myView',
];

export const useAvailableViewIds = (): ViewId[] => {
  const structure = rfx.useStructure<Live>();
  const canReadAuctionPage = rfx.useCanReadPageOfType(PageType.AUCTION);

  return useMemo(() => {
    const { areLotsEnabled, isEvaluationEnabled } = structure.settings;
    const hasLocks = (
      Object.values(structure.sectionById).some(section => section.locking) ||
      Object.values(structure.exchangeDefById).some(exchangeDef => exchangeDef.locking)
    );

    return compact([
      'byCurrentStage',
      areLotsEnabled && 'byLot',
      'byBidStatus',
      canReadAuctionPage && 'auction',
      hasLocks && 'locks',
      isEvaluationEnabled && 'evaluation',
      'byBidOutcome',
      'myView',
    ]);
  }, [structure, canReadAuctionPage]);
};

export type ColumnId =
  | 'supplier'
  | 'supplierInviteStatus' // filter-only -- cannot be used to render a column
  | 'bidIntentionStatus'
  | 'currentStage'
  | 'currentStageCompletion'
  | 'stageNCompletion' // filter-only -- cannot be used to render a column
  | 'yourBidActions'
  | 'teamBidActions'
  | 'unreadComments'
  | 'evaluationCompletion'
  | 'yourEvaluationActions'
  | 'teamEvaluationActions'
  | 'lotIntentionStatus'
  | 'nonLotViewLotIntentionStatus' // filter-only -- cannot be used to render a column
  | 'bidOutcomeStatus'
  | 'auctionBidderAgreementStatus'
  | 'currentAuctionBid'
  | 'lockStatus'
  | 'externalSupplierStatus'
  | 'addedToRequest';

export const useAvailableColumnIds = (
  hasExternalSupplierStatuses: boolean,
): ColumnId[] => {
  const structure = rfx.useStructure<Live>();
  const canReadAuctionPage = rfx.useCanReadPageOfType(PageType.AUCTION);

  return useMemo(() => {
    const { areLotsEnabled, isEvaluationEnabled } = structure.settings;
    const hasLocks = (
      Object.values(structure.sectionById).some(section => section.locking) ||
      Object.values(structure.exchangeDefById).some(exchangeDef => exchangeDef.locking)
    );

    return compact([
      'supplier',
      'bidIntentionStatus',
      areLotsEnabled && 'lotIntentionStatus',
      'bidOutcomeStatus',
      'currentStage',
      'currentStageCompletion',
      canReadAuctionPage && 'auctionBidderAgreementStatus',
      canReadAuctionPage && 'currentAuctionBid',
      'yourBidActions',
      'teamBidActions',
      'unreadComments',
      hasLocks && 'lockStatus',
      isEvaluationEnabled && 'evaluationCompletion',
      isEvaluationEnabled && 'yourEvaluationActions',
      isEvaluationEnabled && 'teamEvaluationActions',
      'addedToRequest',
      hasExternalSupplierStatuses && 'externalSupplierStatus',
    ]);
  }, [structure, canReadAuctionPage, hasExternalSupplierStatuses]);
};

export const useAvailableFilterIds = (
  hasExternalSupplierStatuses: boolean,
): ColumnId[] => {
  const structure = rfx.useStructure<Live>();
  const canReadAuctionPage = rfx.useCanReadPageOfType(PageType.AUCTION);

  return useMemo(() => {
    const { areLotsEnabled, isEvaluationEnabled } = structure.settings;
    const hasLocks = (
      Object.values(structure.sectionById).some(section => section.locking) ||
      Object.values(structure.exchangeDefById).some(exchangeDef => exchangeDef.locking)
    );

    return compact([
      'supplier',
      'supplierInviteStatus',
      'bidIntentionStatus',
      areLotsEnabled && 'lotIntentionStatus',
      areLotsEnabled && 'nonLotViewLotIntentionStatus',
      'bidOutcomeStatus',
      'currentStage',
      'currentStageCompletion',
      'stageNCompletion',
      canReadAuctionPage && 'auctionBidderAgreementStatus',
      canReadAuctionPage && 'currentAuctionBid',
      'yourBidActions',
      'teamBidActions',
      'unreadComments',
      hasLocks && 'lockStatus',
      isEvaluationEnabled && 'evaluationCompletion',
      isEvaluationEnabled && 'yourEvaluationActions',
      isEvaluationEnabled && 'teamEvaluationActions',
      'addedToRequest',
      hasExternalSupplierStatuses && 'externalSupplierStatus',
    ]);
  }, [structure, canReadAuctionPage, hasExternalSupplierStatuses]);
};

export type ViewConfig = {
  groupBy?: GroupingCriterion;
  /**
   * IDs of columns that should not be available view because
   * they'd duplicate information that already gets rendered
   * in group column headers and could cause problems in
   * sorting.
   */
  excludedColumnIds: ColumnId[];
  excludedFilterIds: ColumnId[];
  defaultColumnIds: ColumnId[];
  defaultSorting: SortingConfig[];
};

export type SortingConfig = {
  columnId: ColumnId;
  direction: SortingDirection;
};

export const viewConfigById: Record<string, ViewConfig> = {
  byCurrentStage: {
    groupBy: 'stageId',
    excludedColumnIds: [
      'currentStage',
      'lotIntentionStatus',
    ],
    excludedFilterIds: [
      'lotIntentionStatus',
    ],
    defaultColumnIds: [
      'supplier',
      'bidIntentionStatus',
      'currentStageCompletion',
      'yourBidActions',
      'teamBidActions',
      'unreadComments',
      'externalSupplierStatus',
    ],
    defaultSorting: [
      {
        columnId: 'bidIntentionStatus',
        direction: SortingDirection.ASC,
      },
    ],
  },

  byLot: {
    groupBy: 'lotId',
    excludedColumnIds: [
      'currentStageCompletion',
      'stageNCompletion',
      'yourBidActions',
      'teamBidActions',
      'unreadComments',
      'evaluationCompletion',
      'yourEvaluationActions',
      'teamEvaluationActions',
      'bidOutcomeStatus',
      'auctionBidderAgreementStatus',
      'currentAuctionBid',
      'lockStatus',
    ],
    excludedFilterIds: [
      'nonLotViewLotIntentionStatus',
      'currentStageCompletion',
      'stageNCompletion',
      'yourBidActions',
      'teamBidActions',
      'unreadComments',
      'evaluationCompletion',
      'yourEvaluationActions',
      'teamEvaluationActions',
      'bidOutcomeStatus',
      'auctionBidderAgreementStatus',
      'currentAuctionBid',
      'lockStatus',
    ],
    defaultColumnIds: [
      'supplier',
      'lotIntentionStatus',
      'currentStage',
    ],
    defaultSorting: [
      {
        columnId: 'supplier',
        direction: SortingDirection.ASC,
      },
    ],
  },
  byBidStatus: {
    groupBy: 'bidIntentionStatus',
    excludedColumnIds: [
      'bidIntentionStatus',
      'lotIntentionStatus',
    ],
    excludedFilterIds: [
      'lotIntentionStatus',
    ],
    defaultColumnIds: [
      'supplier',
      'currentStage',
      'yourBidActions',
      'teamBidActions',
      'unreadComments',
    ],
    defaultSorting: [
      {
        columnId: 'supplier',
        direction: SortingDirection.ASC,
      },
    ],
  },
  auction: {
    excludedColumnIds: [
      'lotIntentionStatus',
    ],
    excludedFilterIds: [
      'lotIntentionStatus',
    ],
    defaultColumnIds: [
      'supplier',
      'bidIntentionStatus',
      'auctionBidderAgreementStatus',
      'currentAuctionBid',
    ],
    defaultSorting: [
      {
        columnId: 'bidIntentionStatus',
        direction: SortingDirection.ASC,
      },
      {
        columnId: 'auctionBidderAgreementStatus',
        direction: SortingDirection.ASC,
      },
    ],
  },
  locks: {
    excludedColumnIds: [
      'lotIntentionStatus',
    ],
    excludedFilterIds: [
      'lotIntentionStatus',
    ],
    defaultColumnIds: [
      'supplier',
      'bidIntentionStatus',
      'bidOutcomeStatus',
      'lockStatus',
    ],
    defaultSorting: [
      {
        columnId: 'lockStatus',
        direction: SortingDirection.DESC,
      },
      {
        columnId: 'supplier',
        direction: SortingDirection.ASC,
      },
    ],
  },
  evaluation: {
    excludedColumnIds: [
      'lotIntentionStatus',
    ],
    excludedFilterIds: [
      'lotIntentionStatus',
    ],
    defaultColumnIds: [
      'supplier',
      'bidIntentionStatus',
      'bidOutcomeStatus',
      'evaluationCompletion',
      'yourEvaluationActions',
      'teamEvaluationActions',
    ],
    defaultSorting: [
      {
        columnId: 'bidIntentionStatus',
        direction: SortingDirection.ASC,
      },
      {
        columnId: 'supplier',
        direction: SortingDirection.ASC,
      },
    ],
  },
  byBidOutcome: {
    groupBy: 'bidOutcomeStatus',
    excludedColumnIds: [
      'bidOutcomeStatus',
      'lotIntentionStatus',
    ],
    excludedFilterIds: [
      'lotIntentionStatus',
    ],
    defaultColumnIds: [
      'supplier',
      'bidIntentionStatus',
      'currentStage',
    ],
    defaultSorting: [
      {
        columnId: 'supplier',
        direction: SortingDirection.ASC,
      },
    ],
  },
  myView: {
    excludedColumnIds: [
      'lotIntentionStatus',
    ],
    excludedFilterIds: [
      'lotIntentionStatus',
    ],
    defaultColumnIds: [
      'supplier',
      'bidIntentionStatus',
      'currentStage',
      'currentStageCompletion',
      'yourBidActions',
      'teamBidActions',
      'unreadComments',
      'evaluationCompletion',
      'yourEvaluationActions',
      'teamEvaluationActions',
      'bidOutcomeStatus',
      'auctionBidderAgreementStatus',
      'currentAuctionBid',
      'lockStatus',
      'externalSupplierStatus',
      'addedToRequest',
    ],
    defaultSorting: [
      {
        columnId: 'bidIntentionStatus',
        direction: SortingDirection.ASC,
      },
      {
        columnId: 'supplier',
        direction: SortingDirection.ASC,
      },
    ],
  },
};
