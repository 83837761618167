import * as React from 'react';
import { SpaceProps, WidthProps } from 'styled-system';
import { useTranslation } from 'react-i18next';
import { IconText, IconTextProps } from '@deepstream/ui-kit/elements/text/IconText';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { CompanyAddress } from '../../../ui/CompanyAddress';
import { CompanySize } from '../../../ui/CompanySize';
import { Details } from '../../../ui/Details';
import { Company } from '../../../ui/types';
import { useExternalSystemWithExternalStatus, useSupplierIntegrationMap } from '../../../useExternalSystems';

const Detail: React.FC<IconTextProps & { isMultiline?: boolean }> = ({ text, isMultiline, ...props }) => {
  const { t } = useTranslation('companyProfile');
  const theme = useTheme();

  return (
    <IconText
      fixedWidth
      gap={2}
      fontSize={2}
      iconColor="lightGray5"
      text={text || t('overview.notProvided')}
      color={text ? 'inherit' : theme.colors.subtext}
      fontWeight="normal"
      lineHeight={isMultiline ? 1.5 : 1.3}
      alignItems={isMultiline ? 'flex-start' : 'center'}
      {...props}
    />
  );
};

const hasAddress = (company: Company) => {
  const { address } = company;

  return address && Object.values(address).some(value => !!value);
};

type CompanyProfileInfoProps =
  {
    company: Company;
    currentCompanyId: string;
    canEdit?: boolean;
    numMembers?: number;
    openBasicInformationModal?: () => void;
    openRegisteredInformationModal?: () => void;
    openContactDetailsModal?: () => void;
  } &
  WidthProps &
  SpaceProps;

export const CompanyProfileOverview: React.FC<CompanyProfileInfoProps> = ({
  company,
  currentCompanyId,
  canEdit,
  numMembers,
  openBasicInformationModal,
  openRegisteredInformationModal,
  openContactDetailsModal,
  ...props
}) => {
  const { t } = useTranslation(['companyProfile', 'company', 'general']);
  const externalSystem = useExternalSystemWithExternalStatus({ currentCompanyId });

  const suppliersIntegrationMap = useSupplierIntegrationMap({ currentCompanyId, systemId: externalSystem?.systemId });

  return (
    <>
      <Panel {...props}>
        <Details
          label={t('overview.basicInformation')}
          isTeamMember={company._id === currentCompanyId}
          canEdit={canEdit}
          dataTest="basic-information-panel"
          onEdit={openBasicInformationModal}
        >
          <Detail name={t('common.companyName')} icon="building" text={company.name} />
          <Detail name={t('common.companyType')} icon="industry" text={company.group && t(`common.companyGroup.${company.group}`)} />
          <Detail
            name={t('common.companySize')}
            icon="users"
            text={company.employeesNb && <CompanySize employeesNb={company.employeesNb} />}
          />
          <Detail
            name={t('overview.employeesOnDeepstream')}
            icon="users"
            text={numMembers && t('overview.employeeOnDeepstreamCount', { count: numMembers })}
          />
        </Details>
        <Details
          label={t('dialog.editRegisteredInformation.heading', { ns: 'company' })}
          isTeamMember={company._id === currentCompanyId}
          canEdit={canEdit}
          onEdit={openRegisteredInformationModal}
        >
          <Detail name={t('dialog.editRegisteredInformation.fields.registeredName', { ns: 'company' })} icon="registered" text={company.registeredName} />
          <Detail name={t('dialog.editRegisteredInformation.fields.number', { ns: 'company' })} icon="hashtag" text={company.number} />
          <Detail name={t('dialog.editRegisteredInformation.fields.DUNSNb', { ns: 'company' })} icon="hashtag" text={company.DUNSNb} />
        </Details>
        <Details
          label={t('dialog.editContactDetails.heading', { ns: 'company' })}
          isLast
          isTeamMember={company._id === currentCompanyId}
          canEdit={canEdit}
          onEdit={openContactDetailsModal}
        >
          <Detail name={t('common.website')} icon="globe" text={company.web} />
          <Detail name={t('email', { ns: 'general' })} icon="envelope" text={company.email} />
          <Detail name={t('common.phone')} icon="phone" text={company.telephone} />
          <Detail
            isMultiline
            name={t('common.address')}
            icon="building"
            text={hasAddress(company) && <CompanyAddress value={company.address} />}
          />
        </Details>
      </Panel>
      {externalSystem?.externalData.hasSupplierStatuses && (
        <Panel>
          <Details
            label={`${t('status', { ns: 'general' })} (${externalSystem.name})`}
            hasTooltip
            externalSystemName={externalSystem.name}
            externalStatusesUpdatedAt={externalSystem.externalStatusesUpdatedAt}
          >
            {suppliersIntegrationMap?.[company._id]
            ? t(`externalStatus.externalStatus.${suppliersIntegrationMap[company._id]}`, { ns: 'integration' })
            : t('notMapped', { ns: 'integration' })
            }
          </Details>
        </Panel>
      )}
    </>
  );
};
