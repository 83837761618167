import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Text } from 'rebass/styled-components';
import { first } from 'lodash';
import { useQueryClient } from 'react-query';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { callAll } from '@deepstream/utils/callAll';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useContractData } from './contract';
import { CompanyLogo } from '../../CompanyLogo';
import { useModalState } from '../../ui/useModalState';
import { useMutation } from '../../useMutation';
import { useApi } from '../../api';
import { useToaster } from '../../toast';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useContractQueryKey } from './useContract';

export const ImportTagsButton = (props: ButtonProps) => {
  const { t } = useTranslation('contracts');
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const { _id: contractId, requestSubject, recipients } = useContractData();
  const importTagsInfoModal = useModalState();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const recipient = first(recipients);

  const contractQueryKey = useContractQueryKey({
    contractId,
    currentCompanyId,
    scope: 'draft',
  });

  const [importTags] = useMutation(
    api.importProductTags,
    {
      onSuccess: () => toaster.success(t('toaster.productTagsImported.success')),
      onError: () => toaster.error(t('toaster.productTagsImported.failed')),
      onSettled: callAll(
        importTagsInfoModal.close,
        () => queryClient.invalidateQueries(contractQueryKey),
      ),
    },
  );

  const onImport = useCallback(
    () => importTags({
      contractId,
      currentCompanyId,
    }),
    [contractId, currentCompanyId, importTags],
  );

  return (
    <>
      <Button
        small
        variant="primary-outline"
        iconLeft="file-import"
        onClick={importTagsInfoModal.open}
        {...props}
      >
        {t('summary.importTags')}
      </Button>
      <Dialog
        body={
          <Stack gap={3}>
            <Text>
              {t('summary.importTagsInfo')}
            </Text>
            <Box>
              <Text mb={1}>
                {t('summary.linkedRequest')}:
              </Text>
              <Truncate fontWeight={500}>
                {requestSubject}
              </Truncate>
            </Box>
            <Box>
              <Text mb={1}>
                {t('awardedSupplier')}:
              </Text>
              <Flex alignItems="center">
                <Box flex="0 0 auto">
                  {/*
                   // @ts-expect-error ts(18048) FIXME: 'recipient' is possibly 'undefined'. */}
                  <CompanyLogo size="xs" companyId={recipient._id} />
                </Box>
                <Truncate>
                  {/*
                   // @ts-expect-error ts(18048) FIXME: 'recipient' is possibly 'undefined'. */}
                  {recipient.name}
                </Truncate>
              </Flex>
            </Box>
          </Stack>
        }
        style={{ content: { minWidth: '390px', maxWidth: '390px' } }}
        okButtonText={t('summary.importTags')}
        cancelButtonText={t('cancel', { ns: 'general' })}
        heading={t('summary.importTags')}
        isOpen={importTagsInfoModal.isOpen}
        onOk={onImport}
        onCancel={importTagsInfoModal.close}
      />
    </>
  );
};
