import { CellProps } from 'react-table';
import { QuestionnaireOverview } from '@deepstream/common/preQual';
import { DateFormat } from '@deepstream/utils';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { useTranslation } from 'react-i18next';
import { Datetime2 } from '../../../Datetime';

export const QuestionnaireExpiryAndRenewalDateCell = ({ row: { original: questionnaire } }: CellProps<QuestionnaireOverview, Date>) => {
  const { t } = useTranslation('preQualification');

  return questionnaire.renewalDate ? (
    <>
      {t('questionnaireRenewal.renewalRequired')}
      {' '}
      <Datetime2
        value={questionnaire.renewalDate}
        format={DateFormat.DD_MMM_YYYY}
      />
    </>
  ) : questionnaire.expiryDate ? (
    <>
      {t('questionnaireRenewal.expires')}
      {' '}
      <Datetime2
        value={questionnaire.expiryDate}
        format={DateFormat.DD_MMM_YYYY}
      />
    </>
  ) : (
    <EmDash />
  );
};
