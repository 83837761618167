import { useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { AwardDecision, ExchangeType, Live, Company, SectionType, isStandaloneAuctionLineItemExchangeDef, renderSectionName } from '@deepstream/common/rfq-utils';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { fromPairs, groupBy, intersection, isEmpty, isEqual, keyBy, mapValues, noop, without } from 'lodash';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { immutableSet } from '@deepstream/utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { EditableGridDataProvider } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { FormikGridRowUpdater } from '@deepstream/ui-kit/grid/EditableGrid/FormikGridRowUpdater';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData, GridAwardDecision } from '../types';
import * as rfx from '../../../../rfx';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { AwardDecisionGridRowData, EditAwardDecisionGrid } from '../../../../ui/ExchangeDefsGrid/EditAwardDecisionGrid';
import { RequestHooksProvider } from '../../RequestHooksProvider';
import { AwardLineItemsRecipientCell } from '../../../../ui/ExchangeDefsGrid/nonValidationAwareValueCell';
import { useCurrentUserLocale } from '../../../../useCurrentUser';
import { getLineAwardData } from '../useCalculatedTotalValue';
import { Direction } from '../../../../ui/MultiStepFlow/types';

const hasRowChanged = (gridRow: AwardDecisionGridRowData, formikRow: AwardDecisionGridRowData) =>
  !isEqual(gridRow.awardDecision, formikRow.awardDecision);

const useBaseRowData = (recipients: Company[]) => {
  const { t } = useTranslation('translation');
  const locale = useCurrentUserLocale();
  const { sectionById, exchangeDefById, senders } = rfx.useStructure<Live>();
  const { costAndSavingsByRecipientId } = rfx.useCostAndSavingsData();

  return useMemo(() => {
    const senderIds = senders.map(sender => sender._id);
    const recipientIds = recipients.map(sender => sender._id);

    const lineItemExchangeDefs = Object.values(exchangeDefById)
      .filter(exchangeDef => (
        !exchangeDef.isObsolete &&
        (
          (exchangeDef.type === ExchangeType.LINE_ITEM && exchangeDef.fields.totalCost) ||
          isStandaloneAuctionLineItemExchangeDef(exchangeDef)
        )
      ));

    // we don't access `section.exchangeDefIds` for this because it doesn't
    // include IDs of supplier-provided exchanges, which we want to include
    // here
    const lineItemExchangeDefsBySectionId = groupBy(
      lineItemExchangeDefs,
      exchangeDef => exchangeDef.sectionId,
    );

    const sections = Object.values(sectionById)
      .filter(section => [SectionType.LINE_ITEMS, SectionType.AUCTION_LINE_ITEMS].includes(section.type))
      .map(section => {
        return {
          // @ts-expect-error ts(2783) FIXME: 'name' is specified more than once, so this usage will be overwritten.
          name: renderSectionName(section, t),
          ...section,
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name, locale));

    const rows = sections
      .flatMap(section => {
        const includedExchangeDefs = lineItemExchangeDefsBySectionId[section._id] || [];
        const includedExchangeDefIds = includedExchangeDefs.map(exchangeDef => exchangeDef._id);

        const orderedExchangeDefIds = [
          // make sure all buyer-provided exchangeDefs are ordered according
          // to section.exchangeDefById
          ...intersection(section.exchangeDefIds, includedExchangeDefIds),
          ...without(includedExchangeDefIds, ...section.exchangeDefIds),
        ];

        const sectionExchangeRows = orderedExchangeDefIds.map(exchangeDefId => {
          const exchangeDef = includedExchangeDefs.find(exchangeDef => exchangeDef._id === exchangeDefId);

          // @ts-expect-error ts(18048) FIXME: 'exchangeDef' is possibly 'undefined'.
          const awardableRecipientIds = senderIds.includes(exchangeDef.creatorId)
            ? recipientIds
            // @ts-expect-error ts(18048) FIXME: 'exchangeDef' is possibly 'undefined'.
            : [exchangeDef.creatorId];

          const recipientData = recipients.map(recipient => [
            recipient._id,
            awardableRecipientIds.includes(recipient._id)
              // @ts-expect-error ts(18048) FIXME: 'exchangeDef' is possibly 'undefined'.
              ? getLineAwardData(costAndSavingsByRecipientId[recipient._id], exchangeDef._id)
              : null,
          ]);

          return {
            // @ts-expect-error ts(18048) FIXME: 'exchangeDef' is possibly 'undefined'.
            _id: exchangeDef._id,
            label: (exchangeDef as any).description,
            // @ts-expect-error ts(18048) FIXME: 'exchangeDef' is possibly 'undefined'.
            supportsAwardToMultipleSuppliers: Boolean(exchangeDef.fields.quantity),
            latestResponseExchangeIdByRecipientId: fromPairs(
              recipientData.map(([recipientId, data]) => [
                recipientId,
                (data as any)?.exchangeId,
              ]),
            ) as any,
            bidAmountByRecipientId: fromPairs(
              recipientData.map(([recipientId, data]) => [
                recipientId,
                (data as any)?.valueB || null,
              ]),
            ) as any,
            awardableRecipientIds,
            canObsolete: true,
          };
        });

        return isEmpty(sectionExchangeRows)
          ? []
          : [
            {
              _id: section._id,
              label: section.name,
              isSubHeader: true,
              numItems: sectionExchangeRows.length,
            } as any,
            ...sectionExchangeRows,
          ];
      });

    return rows;
  }, [costAndSavingsByRecipientId, exchangeDefById, recipients, senders, sectionById, locale, t]);
};

const getSubmissionDataFromFormValues = ({
  rowData,
}: {
  rowData: AwardDecisionGridRowData[];
}) => {
  // exclude section rows
  const exchangeRowData = [
    ...rowData,
  ].filter(item => !item.isSubHeader);
  const rowDataByLotId = keyBy(exchangeRowData, '_id');

  return {
    awardDecisionByExchangeId: mapValues(
      rowDataByLotId,
      ({ awardDecision, bidAmountByRecipientId, latestResponseExchangeIdByRecipientId }) => {
        if (awardDecision?.value === 'award') {
          return {
            value: 'award',
            awards: (awardDecision as any).awardedSupplierIds.map(recipientId => ({
              recipientId,
              // @ts-expect-error ts(18048) FIXME: 'latestResponseExchangeIdByRecipientId' is possibly 'undefined'.
              awardedExchangeId: latestResponseExchangeIdByRecipientId[recipientId],
              requestCurrencyAwardedCost: bidAmountByRecipientId[recipientId],
            })),
          } as Extract<AwardDecision, { value: 'award' }>;
        } else {
          return awardDecision;
        }
      },
    ),
  };
};

const addAwardFlowData = (rows, data: AwardFlowData) => {
  return rows.map(item => {
    if (item.isSubHeader) {
      return item;
    }

    const storedAwardDecision = data.awardDecisionByExchangeId?.[item._id];

    return {
      ...item,
      awardDecision: storedAwardDecision
        ? (
          storedAwardDecision.value === 'award'
            ? {
              value: 'award',
              awardedSupplierIds: storedAwardDecision.awards.map(award => award.recipientId),
            }
            : storedAwardDecision
        ) as GridAwardDecision
        : (
          // when no supplier can be selected, set 'no award' decision
          // as a static value in initial data
          isEmpty(item.awardableRecipientIds) ? { value: 'noAward' } : null
        ) as GridAwardDecision,
    };
  });
};

export const ChooseLineLevelAwardSuppliersStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (data: Partial<AwardFlowData> | null, direction: Direction) => void,
}) => {
  const { t } = useTranslation('translation');
  const { currencyCode } = rfx.useStructure<Live>();
  const recipients = rfx.useSortedBiddingRecipients({ excludeUnsuccessful: true });
  const baseRowData = useBaseRowData(recipients);

  const initialDefaultRowData = addAwardFlowData(baseRowData, data);

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      rowData: z.array(
        z.custom<AwardDecisionGridRowData>().refine(
          value => Boolean(value?.isSubHeader || value?.awardDecision),
          value => ({ message: t('request.awardFlow.errors.selectLineItemAwardDecision', { lineItemName: value.label }) }),
        ),
      ),
    });

    return toFormikValidationSchema(Schema);
  }, [t]);

  return (
    <RequestHooksProvider>
      <rfx.StateProvider isLive>
        <Formik
          validateOnBlur
          initialValues={{
            rowData: initialDefaultRowData,
          }}
          validationSchema={validationSchema}
          onSubmit={noop}
        >
          {({ values, validateForm, submitForm, dirty }) => (
            <Form>
              <lotPagesLayout.ContentWrapper>
                <StepNavigation
                  onBackClick={() => submitAndNavigate(dirty ? getSubmissionDataFromFormValues(values) : null, Direction.BACK)}
                  onContinueClick={async () => {
                    const errors = await validateForm();

                    await submitForm();

                    if (isEmpty(errors)) {
                      submitAndNavigate(dirty ? getSubmissionDataFromFormValues(values) : null, Direction.FORWARD);
                    }
                  }}
                >
                  <FormErrors />
                  <lotPagesLayout.Section heading={t('request.awardFlow.steps.chooseLineLevelAwardSuppliers.heading')} maxWidth="100%">
                    <Box sx={{ maxWidth: lotPagesLayout.DEFAULT_SECTION_WIDTH }} mb="40px">
                      <Text mt="12px" mb="24px">
                        {t('request.awardFlow.steps.chooseLineLevelAwardSuppliers.info1')}
                      </Text>
                      <lotPagesLayout.DescriptionListContainer style={{ marginTop: '8px' }}>
                        <lotPagesLayout.DescriptionListItem
                          label={
                            <>
                              <Icon icon="trophy" regular mr={1} />
                              {t('request.awardFlow.steps.chooseLineLevelAwardSuppliers.awardTo')}
                            </>
                          }
                          description={t('request.awardFlow.steps.chooseLineLevelAwardSuppliers.awardToDescription')}
                        />
                        <lotPagesLayout.DescriptionListItem
                          label={
                            <>
                              <Icon icon="circle-xmark" regular mr={1} />
                              {t('request.awardFlow.steps.chooseLineLevelAwardSuppliers.noAward')}
                            </>
                          }
                          description={t('request.awardFlow.steps.chooseLineLevelAwardSuppliers.noAwardDescription')}
                        />
                        <lotPagesLayout.DescriptionListItem
                          label={
                            <>
                              <Icon icon="ban" regular mr={1} />
                              {t('request.awardFlow.steps.chooseLineLevelAwardSuppliers.markAsObsolete')}
                            </>
                          }
                          description={t('request.awardFlow.steps.chooseLineLevelAwardSuppliers.markAsObsoleteDescription')}
                        />
                      </lotPagesLayout.DescriptionListContainer>
                      <Text mt="24px">
                        {t('request.awardFlow.steps.chooseLineLevelAwardSuppliers.info2')}
                      </Text>
                    </Box>

                    {!isEmpty(initialDefaultRowData) && (
                      <GridIdPrefixProvider>
                        <GridMenuStateProvider>
                          <EditableGridDataProvider
                            rowData={initialDefaultRowData}
                            setValueInRow={immutableSet}
                          >
                            <FormikGridRowUpdater fieldName="rowData" hasRowChanged={hasRowChanged} />
                            <EditAwardDecisionGrid
                              recipients={recipients}
                              currencyCode={currencyCode}
                              showTotalCost
                              labelColumnHeader={t('general.description')}
                              canSelectMultipleSuppliers
                              RecipientCell={AwardLineItemsRecipientCell}
                              viewportHeightDelta={200}
                            />
                          </EditableGridDataProvider>
                        </GridMenuStateProvider>
                      </GridIdPrefixProvider>
                    )}
                  </lotPagesLayout.Section>
                </StepNavigation>
              </lotPagesLayout.ContentWrapper>
            </Form>
          )}
        </Formik>
      </rfx.StateProvider>
    </RequestHooksProvider>
  );
};
