import { Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { PropertyListAction, PropertyListActionProps } from '../../PropertyList';

type ValueOrNotSpecifiedProps = { value: any };

export const ValueOrNotSpecified = ({ value }: ValueOrNotSpecifiedProps) => {
  const { t } = useTranslation();
  return (
    <>
      {value || <Text color="subtext">{t('general.notSpecified')}</Text>}
    </>
  );
};

export const EditProperty = (
  props: Omit<PropertyListActionProps, 'label' | 'icon'>,
) => {
  const { t } = useTranslation();
  return (
    <PropertyListAction {...props} label={t('general.edit')} icon="pencil" />
  );
};
