import * as draft from './draft';
import { QuestionSectionEditPanel } from './QuestionSectionEditPanel';
import { QuestionSectionViewPanel } from './QuestionSectionViewPanel';

/**
 * Displays a panel for the current questions section
 */
export const QuestionSectionPanel = () => {
  const isEditing = draft.useIsEditing();

  return isEditing ? (
    <QuestionSectionEditPanel />
  ) : (
    <QuestionSectionViewPanel />
  );
};
