import * as React from 'react';
import * as Sentry from '@sentry/react';
import { Box, Flex } from 'rebass/styled-components';
import { map } from 'lodash';
import { OptionQuestionExchangeDefinition, QuestionExchangeDefinition, QuestionType } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';

import { UnexpectedStateError } from '@deepstream/errors';
import { StyledCheckbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { StyledRadio } from '../../../ui/Radio';
import { DisabledInputBox } from '../../../ui/Input';
import { FieldContainer } from '../../../form/FieldContainer';

export const DisabledOptionsField = ({
  exchangeDef,
  fieldMaxWidth,
}: {
  exchangeDef: QuestionExchangeDefinition;
  fieldMaxWidth?: React.CSSProperties['maxWidth'];
}) => {
  const { t } = useTranslation();
  const { questionType, options, allowCustomOption } = exchangeDef as OptionQuestionExchangeDefinition;

  if (![QuestionType.MULTIPLE_CHOICE, QuestionType.CHECKBOXES].includes(questionType)) {
    Sentry.captureException(new UnexpectedStateError('[Options] Wrong question type passed', { questionType }));
    return null;
  }

  const allOptions = allowCustomOption ? [...options, t('request.question.other')] : options;

  return (
    <Stack gap={2} sx={{ maxWidth: fieldMaxWidth }}>
      {map(allOptions, (option, i) => (
        <Flex key={i} alignItems="flex-start">
          <Box sx={{ marginRight: '8px', flex: '0 0 auto' }}>
            {questionType === QuestionType.CHECKBOXES ? (
              <StyledCheckbox disabled style={{ background: 'transparent', marginTop: '12px' }} />
            ) : (
              <StyledRadio disabled style={{ background: 'transparent', marginTop: '12px' }} />
            )}
          </Box>
          <FieldContainer flex={1} showAsterisk>
            <DisabledInputBox>{option}</DisabledInputBox>
          </FieldContainer>
        </Flex>
      ))}
    </Stack>
  );
};
