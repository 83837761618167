import * as draft from './draft';
import { VesselPricingSectionEditPanel } from './VesselPricingSectionEditPanel';
import { VesselPricingSectionViewPanel } from './VesselPricingSectionViewPanel';

/**
 * Displays a panel for the current vessel pricing section
 */
export const VesselPricingSectionPanel = () => {
  const isEditing = draft.useIsEditing();

  return isEditing ? (
    <VesselPricingSectionEditPanel />
  ) : (
    <VesselPricingSectionViewPanel />
  );
};
