import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ExchangeType } from '@deepstream/common/rfq-utils';
import { chatExchangeTypes } from '@deepstream/common/exchangesConfig';
import { noop, capitalize } from 'lodash';
import * as yup from 'yup';
import { callAll } from '@deepstream/utils/callAll';
import { ModalForm } from '../../ModalForm';
import { useStartChatLikeExchange } from '../../useStartChatLikeExchange';
import { useToaster } from '../../toast';
import * as rfx from '../../rfx';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { TextField } from '../../form/TextField';
import { FileField } from '../../form/FilesField';
import { SelectField } from '../../form/SelectField';
import { HiddenField } from '../../form/HiddenField';
import { SwitchField } from '../../form/SwitchField';
import { sortByName } from '../../utils';
import { CHANGE_EXCHANGE_MAX_ATTACHMENTS } from '../../ExchangeModal/exchangeReplyFormConfig';

type NewChatModalProps = {
  rfqId: string;
  sectionId: string;
  recipientId?: string;
  isOpen: boolean;
  onCancel: any;
  onSuccess?: any;
  onError?: any;
};

export const NewChatModal: React.FC<NewChatModalProps> = ({
  rfqId,
  sectionId,
  recipientId,
  isOpen,
  onSuccess = noop,
  onError = noop,
  onCancel,
}) => {
  const { t } = useTranslation(['translation', 'general']);
  const isRecipient = useCurrentCompanyId() === recipientId;
  const recipients = rfx.useRecipients();
  const toaster = useToaster();

  const [startChatExchange] = useStartChatLikeExchange({
    rfqId,
    recipientId,
    sectionId,
    onSuccess,
    onError: callAll(
      () => toaster.error(t('request.chats.newChatFailed')),
      onError,
    ),
  });

  return (
    <ModalForm
      heading={t('request.chats.newChat')}
      initialValues={{
        type: null,
        // If there's only 1 recipient, initialize the form with its id. There
        // is no need to make the user select from a list of with a single item.
        recipientId: !recipientId && recipients.length === 1
          ? recipients[0]._id
          : '',
        subject: '',
        message: '',
        attachments: [],
        allowAttachments: false,
      }}
      validationSchema={
        yup.object().shape({
          type: yup.string().oneOf(chatExchangeTypes).required(t('general.required')),
          recipientId: yup.string().required(t('general.required')),
          subject: yup.string().required(t('general.required')),
          message: yup.string().required(t('general.required')),
          attachments: yup.array(yup.object({ _id: yup.string().required() })).max(CHANGE_EXCHANGE_MAX_ATTACHMENTS),
        })
      }
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={values => startChatExchange(values)}
      submitLabel={t('request.chats.openChat')}
    >
      <HiddenField
        name="type"
        value={({ allowAttachments }) =>
          isRecipient ? (
            ExchangeType.CHAT_ONLY_RECEIVER_UPLOAD
          ) : allowAttachments ? (
            ExchangeType.CHAT
          ) : (
            ExchangeType.CHAT_NO_RECEIVER_UPLOAD
          )}
      />
      {recipientId ? (
        <HiddenField name="recipientId" value={recipientId} />
      ) : (
        <SelectField
          required
          name="recipientId"
          label={capitalize(t('general.to'))}
          placeholder={t('general.chooseASupplier')}
          items={sortByName(recipients).map(({ company }) => ({
            label: company.name,
            value: company._id,
          }))}
        />
      )}
      <TextField name="subject" label={t('general.subject')} required />
      <TextField name="message" label={t('general.message')} isMultiLine required />
      <FileField name="attachments" label={t('attachment_other', { ns: 'general' })} purpose="rfq" max={CHANGE_EXCHANGE_MAX_ATTACHMENTS} />
      <SwitchField name="allowAttachments" label={t('request.chats.allowSupplierDocuments?')} />
    </ModalForm>
  );
};
