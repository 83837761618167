import { CellProps } from 'react-table';
import { Text, Flex, Box } from 'rebass/styled-components';
import { addDays, isBefore } from 'date-fns';
import { QuestionDocument, QuestionExchangeDefinition, QuestionType } from '@deepstream/common/rfq-utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { ExchangeSnapshot } from './types';

export const QuestionResponseCellWithUpcomingExpiry = ({
  row: { original: exchange },
  cell: { value },
}: CellProps<ExchangeSnapshot>) => {
  const isDocumentQuestion = (exchange.def as QuestionExchangeDefinition).questionType === QuestionType.DOCUMENT;
  // @ts-expect-error ts(18048) FIXME: 'exchange.latestResponse' is possibly 'undefined'.
  const expiryDate = isDocumentQuestion && (exchange.latestResponse.value as QuestionDocument).expiryDate;
  const isNearExpiry = expiryDate && isBefore(new Date(expiryDate), addDays(new Date(), 30));

  return isNearExpiry ? (
    <Flex width="100%">
      <Text flex="1 1 auto">{value}</Text>
      <Box flex="0 0 auto" ml={2}>
        <Icon icon="timer" color="danger" />
      </Box>
    </Flex>
  ) : (
    value
  );
};
