import { Flex, Text, Heading } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { canRespond, EvaluationPage, getPagesInDisplayOrder, isLinkedEvaluationPage } from '@deepstream/common/rfq-utils';
import { size } from 'lodash';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { ConfirmDeleteDialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import * as rfx from '../../rfx';
import * as draft from '../../draft/draft';
import { LabeledNumber, LabeledValue } from '../../draft/LabeledValue';
import { EditPageWeightsModal } from './EditPageWeightsModal';
import { useConfirmDialog, useModalState } from '../../ui/useModalState';
import { AvatarGroup } from '../../AvatarGroup';
import { RequestTeamEditUsersModal } from '../Request/Team/RequestTeamEditUsersModal';
import { useSaveDraftTeamChanges } from '../../useSaveRequestTeamChanges';
import { FactorAsPercent } from '../../ui/Number';
import { useRequestEditNavigation } from '../../appNavigation';

const EvaluatorAvatars = () => {
  const [saveTeamChanges] = useSaveDraftTeamChanges();
  const { canEditTeam } = rfx.useRfxPermissions();
  const { teamById, senders } = rfx.useStructure();
  const { editingPanelId } = rfx.useState();
  const isEditingAnyPanel = Boolean(editingPanelId);
  const page = rfx.usePage() as EvaluationPage;
  const editPagePermissionsModalState = useModalState();

  const evaluators = senders.flatMap(sender => {
    const { users } = teamById[sender._id];

    // @ts-expect-error ts(18048) FIXME: 'user.rfqRoles' is possibly 'undefined'.
    return Object.values(users).filter(user => canRespond(user.rfqRoles[page._id]));
  });

  return (
    <>
      <Flex>
        <AvatarGroup users={evaluators} width="xxs" />
        {canEditTeam && (
          <IconButton
            ml={1}
            fontSize={1}
            icon="pencil"
            onClick={editPagePermissionsModalState.open}
            disabled={isEditingAnyPanel}
          />
        )}
      </Flex>
      {editPagePermissionsModalState.isOpen && (
        <rfx.SaveChangesProvider saveChanges={saveTeamChanges}>
          <RequestTeamEditUsersModal
            isOpen
            close={editPagePermissionsModalState.close}
          />
        </rfx.SaveChangesProvider>
      )}
    </>
  );
};

/**
 * Heading and high-level stats for the current evaluation page
 */
export const DraftEvaluationPageHeader = () => {
  const { t } = useTranslation(['general', 'evaluation', 'translation']);
  const { canManageEvaluation } = rfx.useRfxPermissions();
  const pageRoles = rfx.usePagesPermissions();
  const { pageById, pages } = rfx.useStructure();
  const { confirm, ...dialogProps } = useConfirmDialog();
  const page = rfx.usePage() as EvaluationPage;
  const { navigateToEvaluationPage } = useRequestEditNavigation();
  const [removePage] = draft.useRemovePage();
  const editPageWeightsModalState = useModalState();
  const { editingPanelId, isTemplate } = rfx.useState();
  const {
    absolutePageWeightById,
    relativeSectionWeightById,
    absoluteExchangeDefWeightById,
  } = rfx.useEvaluationWeights();

  const onDeletePage = () => confirm(async () => {
    const pageId = page._id;
    const visibleEvaluationPages = getPagesInDisplayOrder(pages).filter(page => (
      isLinkedEvaluationPage(page) &&
      pageRoles[page._id].canRead
    ));

    const pageIndex = visibleEvaluationPages.findIndex(page => page._id === pageId);

    const redirectPage = visibleEvaluationPages[pageIndex - 1] || visibleEvaluationPages[pageIndex + 1];

    await removePage({ pageId: page._id });

    navigateToEvaluationPage(redirectPage?._id);
  });

  const isEditingAnyPanel = Boolean(editingPanelId);

  const linkedEvaluationPages = pages.filter(isLinkedEvaluationPage);

  return (
    <>
      <Flex justifyContent="space-between">
        <Heading as="h1" fontSize={6} lineHeight="normal" fontWeight="500">
          {/*
           // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type. */}
          {pageById[page.linkedPageId].name}
        </Heading>
        <Flex sx={{ gap: '16px' }}>
          {linkedEvaluationPages.length > 1 ? (
            <LabeledValue
              label={t('weight')}
              value={(
                <Flex>
                  <Text>
                    {page.weight && absolutePageWeightById[page._id] ? (
                      <FactorAsPercent value={absolutePageWeightById[page._id]} />
                    ) : (
                      <EmDash />
                    )}
                  </Text>
                  {canManageEvaluation && (
                    <IconButton
                      ml={1}
                      fontSize={1}
                      icon="pencil"
                      onClick={editPageWeightsModalState.open}
                      disabled={isEditingAnyPanel}
                    />
                  )}
                </Flex>
              )}
            />
          ) : (
            null
          )}
          <LabeledNumber label={t('section_other')} number={size(relativeSectionWeightById)} />
          <LabeledNumber label={t('criterion_other')} number={size(absoluteExchangeDefWeightById)} />
          {/* Team users can't get added / edited in templates so we hide the evaluation avatars
              in evaluation context. */}
          {!isTemplate && (
            <LabeledValue
              label={t('evaluator_other', { ns: 'evaluation' })}
              value={<EvaluatorAvatars />}
            />
          )}
          {!page.isLive && canManageEvaluation && (
            <Button
              ml={2}
              variant="danger-outline"
              iconLeft="trash"
              onClick={onDeletePage}
              disabled={isEditingAnyPanel}
              data-test="delete-evaluation-page-button"
            >
              {t('request.pages.deletePage', { ns: 'translation' })}
            </Button>
          )}
        </Flex>
      </Flex>
      <EditPageWeightsModal
        isOpen={editPageWeightsModalState.isOpen}
        onCancel={editPageWeightsModalState.close}
        onSubmit={editPageWeightsModalState.close}
      />
      <ConfirmDeleteDialog
        heading={t('request.pages.deletePage', { ns: 'translation' })}
        message={t('request.pages.deletePageWarning', { ns: 'translation' })}
        {...dialogProps}
      />
    </>
  );
};
