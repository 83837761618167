import * as React from 'react';
import { reverse, cloneDeep, flatMap, keyBy } from 'lodash';
import { Box } from 'rebass/styled-components';
import { Formik } from 'formik';
import { Row } from 'react-table';
import { useTranslation } from 'react-i18next';
import { withProps } from '@deepstream/ui-utils/withProps';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { StaticTableStyles } from './TableStyles';
import { Table } from './Table';
import { DatetimeCell } from './DatetimeCell';
import { useDeviceSize } from './ui/useDeviceSize';
import { RightAlignedCell } from './RightAlignedCell';
import { AuditEventCell } from './AuditEventCell';
import { nestCells } from './nestCells';
import { Question } from './pre-q/Question';
import { DownloadFn } from './ui/types';

type ExpandedRowProps = {
  row: Row;
  allQuestions: any[];
  download?: DownloadFn;
};

const ExpandedRow: React.FC<ExpandedRowProps> = ({ row, allQuestions, download }) => {
  const original: any = row.original; // eslint-disable-line prefer-destructuring
  const initialValues = original.changes;

  return (
    <tr style={{ backgroundColor: 'inherit', cursor: 'initial' }}>
      <td colSpan={3}>
        <Box p={20}>
          <Formik initialValues={initialValues} onSubmit={() => {}}>
            <Stack gap={20}>
              {Object.keys(original.changes).map(key => (
                // Ignore obsolete questions
                allQuestions[key] ? (
                  <Question
                    key={key}
                    question={allQuestions[key]}
                    answer={original.changes[key]}
                    isReadOnly
                    download={download}
                  />
                ) : (
                  null
                )
              ))}
            </Stack>
          </Formik>
        </Box>
      </td>
    </tr>
  );
};

type AuditTrailTableProps = {
  auditTrailEvents: any[];
  questionnaire?: any;
  download?: DownloadFn;
};

const getEventDetails = (event, t) => {
  // eslint-disable-next-line no-underscore-dangle
  switch (event._type) {
    case 'access-revoked':
    case 'access-granted':
    case 'access-requested':
      // eslint-disable-next-line no-underscore-dangle
      return `${t(`auditTrail.column.details.eventType.${event._type}`)} (${event.buyerCompany?.name})`;
    case 'answers-changed':
      return event;
    default:
      return '';
  }
};

export const AuditTrailTable: React.FC<AuditTrailTableProps> = ({ auditTrailEvents, questionnaire, download }) => {
  const { t } = useTranslation('companyProfile');
  const { isExtraLarge } = useDeviceSize();

  const reversedEvents = React.useMemo(
    () => {
      const clonedEvents = cloneDeep(auditTrailEvents);
      reverse(clonedEvents);

      return clonedEvents;
    },
    [auditTrailEvents],
  );

  const columns = React.useMemo(
    () => [
      {
        Header: t('auditTrail.column.date.header'),
        accessor: '_meta.timestamp',
        Cell: DatetimeCell,
        width: isExtraLarge ? 240 : 190,
      },
      {
        Header: t('auditTrail.column.user.header'),
        accessor: '_meta.user.name',
      },
      {
        Header: ' ',
        accessor: event => getEventDetails(event, t),
        Cell: nestCells(RightAlignedCell, AuditEventCell),
      },
    ],
    [isExtraLarge, t],
  );

  const allQuestions = React.useMemo(
    () => keyBy(flatMap(questionnaire.categories, 'questions'), 'key'),
    [questionnaire],
  );

  return (
    <StaticTableStyles>
      <Table
        columns={columns}
        data={reversedEvents}
        ExpandedRow={withProps(ExpandedRow, { allQuestions, download })}
      />
    </StaticTableStyles>
  );
};
