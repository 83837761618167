import { useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { Company, UniversalBidStatus } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { universalSupplierBidStatuses } from '@deepstream/common/rfq-utils/statusConfigs';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { useHover } from '@deepstream/ui-kit/hooks/useHover';
import { FrozenHeaderCellProps } from '@deepstream/ui-kit/grid/core/utils';
import { useMediaQueries } from '@deepstream/ui-kit/hooks/useMediaQueries';
import { DropdownMenuItem, EllipsisMenu } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { RfxStructure } from '../../../types';
import { CompanyLogo } from '../../../CompanyLogo';
import { InlineLabel } from './InlineLabel';

export const LabeledUniversalBidStatus = ({
  universalBidStatus,
}: {
  universalBidStatus: UniversalBidStatus | null,
}) => {
  const { t } = useTranslation();

  if (!universalBidStatus) {
    return null;
  }

  const { icon } = universalSupplierBidStatuses[universalBidStatus];

  return (
    <Box as="span" fontSize="9px" mt="2px" letterSpacing="0.3px">
      <InlineLabel>{t('general.bidStatus')}</InlineLabel>
      {icon && (
        <Icon
          fixedWidth
          color={icon.color}
          icon={icon.value as IconProps['icon']} mr="2px"
        />
      )}
      {t(`request.universalSupplierBidStatus.${universalBidStatus}`)}
    </Box>
  );
};

export const createRecipientHeaderCell = ({
  structure,
  navigateToBidPage,
  showBidStatus,
}: {
  structure: RfxStructure;
  navigateToBidPage: (companyId: string) => void;
  showBidStatus?: boolean;
}) =>
  ({ column }: FrozenHeaderCellProps<Company>) => {
    const [cellRef, cellHovered] = useHover<HTMLTableCellElement>();
    const [isDropdownExpanded, setIsDropdownExpanded] = useState(false);
    const { canHover: deviceCanHover } = useMediaQueries();
    const { t } = useTranslation('translation');
    const companyId = column.original._id;

    if (column.index === 0) {
      return null;
    }

    return (
      <Flex alignItems="center" justifyContent="stretch" width="100%" ref={cellRef}>
        <Box width="32px">
          <CompanyLogo companyId={companyId} size="xs" backgroundColor="white" />
        </Box>
        <Flex flexDirection="column" flex={1}>
          <Truncate fontWeight={500}>
            {column.original.company.name}
          </Truncate>
          {showBidStatus && structure.bidById[companyId] ? (
            <LabeledUniversalBidStatus
              universalBidStatus={structure.bidById[companyId].universalStatus}
            />
          ) : (
            null
          )}
        </Flex>
        <Box width="28px">
          {(!deviceCanHover || cellHovered || isDropdownExpanded) && (
            <EllipsisMenu
              small
              variant="secondary-outline"
              menuZIndex={202}
              width="28px"
              onExpandedStateChange={setIsDropdownExpanded}
            >
              <DropdownMenuItem
                icon="external-link"
                iconColor="primary"
                onSelect={() => navigateToBidPage(companyId)}
              >
                {t('request.comparison.openBidPage')}
              </DropdownMenuItem>
            </EllipsisMenu>
          )}
        </Box>
      </Flex>
    );
  };
