import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { isEmpty, noop } from 'lodash';
import { Form, Formik } from 'formik';
import { Live, Message } from '@deepstream/common/rfq-utils';
import { immutableSet } from '@deepstream/utils';
import { Clamp2 } from '@deepstream/ui-kit/elements/text/Clamp';
import { useMemo } from 'react';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData, AwardFlowStep, AwardFlowStepType, MessageMethod } from '../types';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { TextField } from '../../../../form/TextField';
import * as rfx from '../../../../rfx';
import { FileField } from '../../../../form/FilesField';
import { Direction } from '../../../../ui/MultiStepFlow/types';

export const SUPPLIER_MESSAGE_MAX_ATTACHMENTS = 20;

const getSubmissionDataFromFormValues = (values: Message, data: AwardFlowData) => {
  const { supplierGroup, supplierId } =
    data.currentStep as Extract<AwardFlowStep, { type: AwardFlowStepType.ENTER_SINGLE_SUPPLIER_MESSAGE }>;

  return {
    messageConfigByGroup: immutableSet(
      data.messageConfigByGroup,
      supplierGroup,
      {
        messageMethod: MessageMethod.BY_SUPPLIER,
        generalMessage: null,
        generalMessageSupplierIds: [],
        excludedSupplierIds: [],
        messageBySupplierId: {
          [supplierId]: values,
        },
      },
    ),
  };
};

export const EnterSingleSupplierMessageStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (data: Partial<AwardFlowData> | null, direction: Direction) => void,
}) => {
  const { t } = useTranslation('translation');
  const { recipients } = rfx.useStructure<Live>();

  const { supplierGroup, supplierId } =
    data.currentStep as Extract<AwardFlowStep, { type: AwardFlowStepType.ENTER_SINGLE_SUPPLIER_MESSAGE }>;

  const supplierName = recipients.find(recipient => recipient._id === supplierId)?.company.name;

  const initialValues = data.messageConfigByGroup[supplierGroup].messageBySupplierId[supplierId] || {
    text: null,
    attachments: [],
  };

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      text: z.string({ message: t('request.awardFlow.errors.enterMessage') }),
    });

    return toFormikValidationSchema(Schema);
  }, [t]);

  return (
    <Formik
      validateOnBlur
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={noop}
    >
      {({ values, submitForm, validateForm, dirty }) => (
        <Form>
          <lotPagesLayout.ContentWrapper>
            <StepNavigation
              onBackClick={() => submitAndNavigate(dirty ? getSubmissionDataFromFormValues(values, data) : null, Direction.BACK)}
              onContinueClick={async () => {
                const errors = await validateForm();

                await submitForm();

                if (isEmpty(errors)) {
                  submitAndNavigate(dirty ? getSubmissionDataFromFormValues(values, data) : null, Direction.FORWARD);
                }
              }}
            >
              <FormErrors />
              <lotPagesLayout.Section
                heading={t(`request.awardFlow.steps.enterSingleSupplierMessage.heading.${supplierGroup}`)}
              >
                <Text mt="20px">
                  <Clamp2 lines={2}>
                    {t(`request.awardFlow.messageRecipientNames.${supplierGroup}`, { count: 1, supplierNames: [supplierName] })}
                  </Clamp2>
                </Text>
                <Box mt="20px">
                  <TextField
                    name="text"
                    isMultiLine
                    hideLabel
                    hideError
                    errorMessageStyle={{ fontSize: '14px', marginTop: '16px' }}
                  />
                  <Flex alignItems="center" mt="12px">
                    <Box width="400px">
                      <FileField name="attachments" hideLabel purpose="rfq" max={SUPPLIER_MESSAGE_MAX_ATTACHMENTS} />
                    </Box>
                    <Text flex="1 0 auto" color="subtext" ml={3}>
                      {t('request.awardFlow.optional')}
                    </Text>
                  </Flex>
                </Box>
              </lotPagesLayout.Section>
            </StepNavigation>
          </lotPagesLayout.ContentWrapper>
        </Form>
      )}
    </Formik>
  );
};
