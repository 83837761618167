import { Box, Flex } from 'rebass/styled-components';
import { BreadcrumbProps, Breadcrumbs } from '@deepstream/ui-kit/elements/navigation/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Link } from '@tanstack/react-router';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { AnyScope } from '@deepstream/common/rfq-utils';
import * as Layout from '../Layout';
import * as lotPagesLayout from '../modules/Request/Live/lotPagesLayout';
import { useDraftRfqStructure, useLiveRfqStructure, useRfqId } from '../useRfq';
import { useCurrentCompanyId } from '../currentCompanyId';
import { Loading } from '../ui/Loading';
import { ErrorMessage } from '../ui/ErrorMessage';
import * as rfx from '../rfx';
import { requestEditReviewRoute, requestSentSuppliersViewRoute, templateReviewRoute } from '../AppRouting';
import { RfxStructure } from '../types';

const RequestFlowHeaderContent = ({
  title,
  breadcrumbs,
  linkTo,
  linkParams,
} : {
  title: string,
  breadcrumbs: BreadcrumbProps[],
  linkTo: string,
  linkParams?: any,
}) => {
  const { t } = useTranslation('translation');

  return (
    <Flex justifyContent="space-between" sx={{ width: '100%' }}>
      <Box flex={1} pb={2} mr={2}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <lotPagesLayout.H1 mt="3px">
          {title}
        </lotPagesLayout.H1>
      </Box>
      <Link
        to={linkTo}
        params={linkParams}
        tabIndex={-1}
      >
        <Button small variant="danger-outline" mt={1}>
          {t('request.awardFlow.exitProcess')}
        </Button>
      </Link>
    </Flex>
  );
};

const RequestFlowHeaderLayout = ({
  structure,
  isLoading,
  isError,
  isSuccess,
  children,
}: {
  structure?: RfxStructure<AnyScope>
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  children: React.ReactNode;
}) => {
  const { t } = useTranslation('translation');

  return (
    <Layout.PageHeaderWrapper>
      <Flex
        pt="14px"
        pb="6px"
        px="1px"
        justifyContent="spaceBetween"
        alignItems="flex-start"
      >
        {isLoading ? (
          <Loading mb="13px" />
        ) : isError ? (
          <ErrorMessage
            fontSize={2}
            lineHeight={1}
            style={{ margin: '3px 0 13px' }}
            error={t('errors.unexpected')}
          />
        ) : isSuccess && structure ? (
          <rfx.StructureProvider structure={structure}>
            {children}
          </rfx.StructureProvider>
        ) : null}
      </Flex>
    </Layout.PageHeaderWrapper>
  );
};

const AwardFlowHeaderContent = () => {
  const { t } = useTranslation('translation');
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const structure = rfx.useStructure();

  const breadcrumbs = useMemo(() => {
    return [
      {
        title: structure.summary.subject,
        linkProps: {
          to: requestSentSuppliersViewRoute.to,
          params: { rfqId, currentCompanyId },
          target: '_blank',
        },
        fullLength: true,
      },
    ];
  }, [structure.summary.subject, rfqId, currentCompanyId]);

  return (
    <RequestFlowHeaderContent
      title={t('request.awardOrCloseRequest')}
      breadcrumbs={breadcrumbs}
      linkTo={requestSentSuppliersViewRoute.to}
      linkParams={{ rfqId, currentCompanyId }}
    />
  );
};

export const AwardFlowHeader = () => {
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const {
    data: structure,
    isLoading,
    isError,
    isSuccess,
  } = useLiveRfqStructure({ rfqId, currentCompanyId });

  return (
    <RequestFlowHeaderLayout
      structure={structure}
      isLoading={isLoading}
      isError={isError}
      isSuccess={isSuccess}
    >
      <AwardFlowHeaderContent />
    </RequestFlowHeaderLayout>
  );
};

const SetupMultiStateResponsesFlowHeaderContent = () => {
  const { t } = useTranslation('translation');
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const structure = rfx.useStructure();
  const { isTemplate } = rfx.useState();

  const breadcrumbs = useMemo(() => {
    return [
      {
        title: structure.summary.subject,
        linkProps: {
          to: isTemplate ? templateReviewRoute.to : requestEditReviewRoute.to,
          params: { rfqId, currentCompanyId },
          target: '_blank',
        },
        fullLength: true,
      },
    ];
  }, [isTemplate, structure.summary.subject, rfqId, currentCompanyId]);

  return (
    <RequestFlowHeaderContent
      title={t('request.setupMultiStageResponsesFlow.title')}
      breadcrumbs={breadcrumbs}
      linkTo={isTemplate ? templateReviewRoute.to : requestEditReviewRoute.to}
      linkParams={{ rfqId, currentCompanyId }}
    />
  );
};

export const SetupMultiStageResponsesFlowHeader = () => {
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { isTemplate } = rfx.useState();

  const {
    data: structure,
    isLoading,
    isError,
    isSuccess,
  } = useDraftRfqStructure({ rfqId, currentCompanyId, isTemplate });

  return (
    <RequestFlowHeaderLayout
      structure={structure}
      isLoading={isLoading}
      isError={isError}
      isSuccess={isSuccess}
    >
      <SetupMultiStateResponsesFlowHeaderContent />
    </RequestFlowHeaderLayout>
  );
};
