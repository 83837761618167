import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient, useQuery } from 'react-query';
import { Flex } from 'rebass/styled-components';
import {
  ChangeType,
} from '@deepstream/common/rfq-utils';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { useMutation } from '../useMutation';
import { useApi, wrap } from '../api';
import { useToaster } from '../toast';
import { TextField } from '../form/TextField';
import { PropertyList } from '../PropertyList';
import { useCurrentCompanyId } from '../currentCompanyId';
import * as rfx from '../rfx';
import { useRfqId } from '../useRfq';
import { SummaryPanelHeader } from './SummaryPanelHeader';
import { SummaryLabelConfigProvider } from './SummaryLabelConfigProvider';
import { LeavePageModal } from './LeavePageModal';

const panelId = 'templateDetails';

export const SummaryTemplateDetailsPanel = () => {
  const { t } = useTranslation();
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const { stopEditing } = rfx.useActions();
  const { editingPanelId } = rfx.useState();

  const isEditingOtherPanel = editingPanelId && editingPanelId !== panelId;
  const isEditingThisPanel = editingPanelId && editingPanelId === panelId;

  const { data: existingTemplates } = useQuery(
    ['templates', { companyId: currentCompanyId }],
    wrap(api.getTemplates),
  );

  const { data: currentTemplate } = useQuery(
    ['template', { currentCompanyId, templateId: rfqId }],
    wrap(api.getTemplate),
  );

  const properties = useMemo(
    () => [
      {
        name: t('general.name'),
        value: currentTemplate?.meta.name,
      },
    ],
    [t, currentTemplate],
  );

  const [updateTemplateField] = useMutation(
    api.updateTemplateField,
    {
      onSettled: callAll(
        () => queryClient.invalidateQueries(['template', { currentCompanyId, templateId: rfqId }]),
      ),
      onSuccess: callAll(
        () => toaster.success(t('request.toaster.changesSavedSuccess')),
        stopEditing,
      ),
      onError: () => toaster.error(t('request.toaster.changesSavedError')),
    },
  );

  const updateTemplate = useCallback(
    ({ name }) => updateTemplateField({
      companyId: currentCompanyId,
      templateId: rfqId,
      changes: [{
        type: ChangeType.TEMPLATE_FIELD_UPDATED,
        fieldName: 'name',
        fieldValue: name,
      }],
    }),
    [currentCompanyId, rfqId, updateTemplateField],
  );

  const initialValues = {
    'name': currentTemplate?.meta.name,
  };

  return (
    <SummaryLabelConfigProvider>
      <Panel
        mb={20}
        sx={{
          opacity: isEditingOtherPanel ? 0.5 : 1,
          boxShadow: isEditingThisPanel ? '0 0 8px 0 rgba(0, 0, 0, 0.3)' : '',
        }}
      >
        <SummaryPanelHeader
          heading={t('template.templateDetails')}
          panelId={panelId}
        />
        <PanelDivider />
        {isEditingThisPanel ? (
          <Formik
            validateOnBlur
            enableReinitialize
            initialValues={initialValues}
            validationSchema={
              yup.object().shape({
                'name': yup.string()
                  .test(
                    'uniqueName',
                    t('template.errors.duplicateTemplateName'),
                    value => !existingTemplates?.find(template =>
                      template.meta.name.toLowerCase() === value?.toLowerCase().trim(),
                    ),
                  )
                  .required(t('general.required')),
              })
            }
            onSubmit={updateTemplate}
          >
            {({ isSubmitting, dirty, isValid, resetForm }) => (
              <Form>
                <Flex mx={15} mb={20} mt={20}>
                  <TextField
                    label={t('general.name')}
                    name="name"
                    required
                    // quick fix: we can remove setting the inputStyle height once a line height is
                    // set for Input
                    inputStyle={{ height: 40 }}
                  />
                </Flex>
                <PanelDivider />
                <PanelPadding>
                  <Flex justifyContent="flex-end">
                    <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
                    <SaveButton disabled={isSubmitting || !dirty || !isValid} />
                  </Flex>
                </PanelPadding>
                <LeavePageModal />
              </Form>
            )}
          </Formik>
        ) : (
          // no validation here because field cannot be submitted
          // when invalid
          <PropertyList properties={properties} />
        )}
      </Panel>
    </SummaryLabelConfigProvider>
  );
};
