import { Flex, Heading, Text } from 'rebass/styled-components';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import {
  ScoringType,
  isLinkedEvaluationPage,
} from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { callAll } from '@deepstream/utils/callAll';
import { Button, EditButton, SaveButton, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import * as rfx from '../../rfx';
import * as draft from '../../draft/draft';
import { useModalState } from '../../ui/useModalState';
import { DisableEvaluationModal } from './DisableEvaluationModal';
import { usePreventEnterKeyHandler } from '../../usePreventEnterKeyHandler';
import { useScoringTypeOptions } from './useScoringTypeOptions';
import { RadioField } from '../../form/RadioField';
import { DraftPanel } from '../../draft/DraftPanel';
import { useApi } from '../../api';
import { useMutation } from '../../useMutation';
import { useDraftRfqStructureQueryKey, useRfqId } from '../../useRfq';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useToaster } from '../../toast';
import { useWaitForRfqUnlock } from '../../useWaitForUnlock';
import { LeavePageModal } from '../../draft/LeavePageModal';

const scoringTypePanelId = 'scoringType';
const disableEvaluationPanelId = 'disableEvaluation';

const ScoringTypeViewPanel = () => {
  const { t } = useTranslation('evaluation');
  const { startEditing } = rfx.useActions();
  const { editingPanelId, isRevising } = rfx.useState();
  const isMutationLoading = draft.useIsMutationLoading();
  const disabled = Boolean(editingPanelId || isMutationLoading);
  const scoringType = rfx.useEvaluationScoringType();
  const rfxPermissions = rfx.useRfxPermissions();

  return (
    <DraftPanel panelId={scoringTypePanelId}>
      <PanelHeader heading={t('settings.scoringTypePanel.heading')}>
        {/* For now, we don't allow changing the scoring type in revisions */}
        {rfxPermissions.canManageEvaluation && !isRevising && (
          <EditButton
            small
            onClick={() => {
              startEditing(scoringTypePanelId);
            }}
            disabled={disabled}
            data-test="edit-section-button"
          />
        )}
      </PanelHeader>
      <PanelDivider />
      <PanelPadding>
        <Text fontSize={2} fontWeight={400} color="text">
          {t(`fields.scoringType.options.${scoringType}.label`)}
        </Text>
        <Text fontSize={1} color="subtext">
          {t(`fields.scoringType.options.${scoringType}.description`)}
        </Text>
      </PanelPadding>
    </DraftPanel>
  );
};

const ScoringTypeEditPanel = () => {
  const { t } = useTranslation('evaluation');
  const { stopEditing } = rfx.useActions();
  const onKeyDown = usePreventEnterKeyHandler();
  const scoringType = rfx.useEvaluationScoringType();
  const theme = useTheme();
  const api = useApi();
  const options = useScoringTypeOptions();
  const rfqId = useRfqId();
  const queryClient = useQueryClient();
  const { isTemplate, isRevising } = rfx.useState();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqQueryKey = useDraftRfqStructureQueryKey({ rfqId, currentCompanyId, isTemplate });
  const toaster = useToaster();
  const waitForRfqUnlock = useWaitForRfqUnlock();

  const [updateRequestSettings] = useMutation(
    (payload: any) => waitForRfqUnlock({
      isTemplate,
      command: () => api.updateRequestSettings(payload),
    }),
    {
      onSuccess: () => toaster.success(t('settings.toaster.success')),
      onError: () => toaster.error(t('settings.toaster.error')),
      onSettled: () => queryClient.invalidateQueries(rfqQueryKey),
    },
  );

  return (
    <Formik<{ scoringType: ScoringType }>
      validateOnBlur
      enableReinitialize
      initialValues={{ scoringType }}
      validationSchema={yup.mixed()}
      onSubmit={async ({ scoringType }) => {
        await updateRequestSettings({
          rfqId,
          companyId: currentCompanyId,
          settings: { scoringType },
          isTemplate,
          isRevising,
        }, {
          onSuccess: stopEditing,
        });
      }}
    >
      {({ isSubmitting, dirty, resetForm }) => (
        <Form style={{ width: '100%' }} onKeyDown={onKeyDown}>
          <DraftPanel panelId={scoringTypePanelId}>
            <PanelHeader heading={t('settings.scoringTypePanel.heading')} />
            <PanelDivider />
            <PanelPadding>
              <RadioField
                name="scoringType"
                options={options}
                gap="12px"
                labelStyle={{
                  fontWeight: 'normal',
                  color: theme.colors.text,
                  whiteSpace: 'pre-wrap',
                  lineHeight: '15px',
                }}
              />
              {isRevising && (
                <MessageBlock variant="warn">
                  {t('settings.scoringTypePanel.revisionWarning')}
                </MessageBlock>
            )}
            </PanelPadding>
            <PanelDivider />
            <PanelPadding>
              <Flex justifyContent="flex-end">
                <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
                <SaveButton disabled={isSubmitting || !dirty} />
              </Flex>
            </PanelPadding>
          </DraftPanel>
          <LeavePageModal />
        </Form>
      )}
    </Formik>
  );
};

const ScoringTypePanel = () => {
  const { editingPanelId } = rfx.useState();

  return editingPanelId === scoringTypePanelId ? (
    <ScoringTypeEditPanel />
  ) : (
    <ScoringTypeViewPanel />
  );
};

const DisableEvaluationPanel = () => {
  const { t } = useTranslation('evaluation');
  const { editingPanelId } = rfx.useState();
  const isEditingAnyPanel = Boolean(editingPanelId);
  const disableEvaluationModalState = useModalState();

  return (
    <>
      <DraftPanel panelId={disableEvaluationPanelId}>
        <PanelHeader heading={t('settings.disableEvaluationPanel.heading')} />
        <PanelDivider />
        <PanelPadding>
          <Text>
            {t('settings.disableEvaluationPanel.body')}
          </Text>
          <Button
            mt={3}
            small
            variant="danger"
            iconLeft="close"
            onClick={disableEvaluationModalState.open}
            disabled={isEditingAnyPanel}
          >
            {t('settings.disableEvaluationPanel.disableButton')}
          </Button>
        </PanelPadding>
      </DraftPanel>
      <DisableEvaluationModal {...disableEvaluationModalState} />
    </>
  );
};

export const DraftEvaluationSettingsTab = () => {
  const { t } = useTranslation('evaluation');
  const { pages } = rfx.useStructure();
  const rfxPermissions = rfx.useRfxPermissions();

  const canDisableEvaluation = !pages.some(page => (
    isLinkedEvaluationPage(page) &&
    page.isLive
  ));

  return (
    <Stack gap={20}>
      <Heading as="h1" fontSize={6} lineHeight="normal" fontWeight="500">
        {t('settings.heading')}
      </Heading>
      <Stack gap={20}>
        <ScoringTypePanel />
        {rfxPermissions.canManageEvaluation && canDisableEvaluation && (
          <DisableEvaluationPanel />
        )}
      </Stack>
    </Stack>
  );
};
