import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { ExchangeProvider } from '../../useExchange';
import { BulkSubmitControlsPanel } from './BulkSubmitControlsPanel';
import { BulkSubmitSectionDetails } from './BulkSubmitSectionDetails';
import { BulkSubmitActivityPanel } from './BulkSubmitActivityPanel';
import { BulkSubmitBottomBar } from './BulkSubmitBottomBar';
import { BulkSubmitMainContent } from './BulkSubmitMainContent';
import { LineItemsExchangeSnapshot } from '../../types';
import { SendExchangeReply, useSendExchangeReply } from '../../ExchangeModal/useSendExchangeReply';
import { useRfqId } from '../../useRfq';
import { BulkSubmitContext, BulkSubmitContextType } from './BulkSubmitContext';
import { ValidationErrors } from '../../draft/validation';

const SIDEBAR_WIDTH = 400;
const BOTTOM_BAR_HEIGHT = 70;
const CONTROL_PANEL_HEIGHT = 52;

type ContainerProps = {
  isLeftCollapsed: boolean;
  isRightCollapsed: boolean;
};

const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-areas: 
    "left-sidebar control-panel right-sidebar"
    "left-sidebar main-content right-sidebar"
    "bottom-bar bottom-bar bottom-bar";
  grid-template-columns: ${(props) => props.isLeftCollapsed ? '0px' : `${SIDEBAR_WIDTH}px`} 1fr ${(props) => props.isRightCollapsed ? '0px' : `${SIDEBAR_WIDTH}px`};
  grid-template-rows: ${BOTTOM_BAR_HEIGHT}px 1fr ${CONTROL_PANEL_HEIGHT}px;
  height: 100%;
  transition: grid-template-columns 0.3s;
`;

const Sidebar = styled.div`
  background-color: #f0f0f0;
`;

const LeftSidebar = styled(Sidebar)`
  grid-area: left-sidebar;
  min-width: ${SIDEBAR_WIDTH}px;
  border-right: 1px solid #E2E8EF;
  border-top: 1px solid #E2E8EF;
`;

const RightSidebar = styled(Sidebar)`
  grid-area: right-sidebar;
  min-width: ${SIDEBAR_WIDTH}px;
  border-left: 1px solid #E2E8EF;
  border-top: 1px solid #E2E8EF;
`;

const ControlPanel = styled.div`
  grid-area: control-panel;
  background-color: #e0e0e0;
  border-top: 1px solid #E2E8EF;
  border-bottom: 1px solid #E2E8EF;
`;

const MainContent = styled.div`
  grid-area: main-content;
  background-color: #fff;
`;

const BottomBar = styled.div`
  grid-area: bottom-bar;
  background-color: #d0d0d0;
  border-top: 3px solid #3498DB;
`;

export const BulkSubmitPage = () => {
  const [isLeftCollapsed, setLeftCollapsed] = useState(false);
  const [isRightCollapsed, setRightCollapsed] = useState(false);
  const [selectedExchange, setSelectedExchange] = useState<LineItemsExchangeSnapshot>();
  const [lineItems, setLineItems] = useState<LineItemsExchangeSnapshot[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<string>();
  const [validationErrors, setValidationErrors] = useState<ValidationErrors<any>>();
  const rfqId = useRfqId();
  const sendExchangeReplyMutation = useSendExchangeReply({
    rfqId,
    exchangeId: selectedExchange?._id || '',
    recipientId: selectedExchange?.recipientId,
    sectionId: selectedExchange?.def.sectionId,
  });

  const bulkSubmitContext = useMemo<BulkSubmitContextType>(() => ({
    selectedExchange,
    setSelectedExchange,
    lineItems,
    selectedCurrency,
    setLineItems,
    setSelectedCurrency,
    setValidationErrors,
    validationErrors,
    setLeftCollapsed,
    setRightCollapsed,
    isLeftCollapsed,
    isRightCollapsed,
  }), [isLeftCollapsed, isRightCollapsed, lineItems, selectedCurrency, selectedExchange, validationErrors]);

  return (
    <BulkSubmitContext.Provider value={bulkSubmitContext}>
      <Container isLeftCollapsed={isLeftCollapsed} isRightCollapsed={isRightCollapsed}>
        <LeftSidebar>
          <BulkSubmitSectionDetails setIsCollapsed={setLeftCollapsed} />
        </LeftSidebar>

        <ControlPanel>
          <BulkSubmitControlsPanel />
        </ControlPanel>
        <MainContent>
          <BulkSubmitMainContent onSelectedExchangeChange={setSelectedExchange} />
        </MainContent>

        <RightSidebar>
          <ExchangeProvider exchange={selectedExchange || null}>
            <SendExchangeReply.Provider value={sendExchangeReplyMutation}>
              {selectedExchange && <BulkSubmitActivityPanel setIsCollapsed={setRightCollapsed} />}
            </SendExchangeReply.Provider>
          </ExchangeProvider>
        </RightSidebar>

        <BottomBar>
          <BulkSubmitBottomBar />
        </BottomBar>
      </Container>
    </BulkSubmitContext.Provider>
  );
};
