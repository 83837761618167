import { useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { isEmpty, noop, sortBy } from 'lodash';
import { Form, Formik } from 'formik';
import { Live } from '@deepstream/common/rfq-utils';
import { useMemo } from 'react';
import * as rfx from '../../../../rfx';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData, AwardFlowStepType } from '../types';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { MoneyField } from '../../../../form/MoneyField';
import { CurrencyCodeProvider } from '../../../../ui/Currency';
import { CheckboxFieldBase } from '../../../../form/CheckboxField';
import { TextField } from '../../../../form/TextField';
import { SavingsNotAccurate } from '../SavingsNotAccurate';
import { Direction } from '../../../../ui/MultiStepFlow/types';

const errorKeys = [
  'manualTotalSavings',
  'manualTotalSavingsDescription',
  'areTotalSavingsAccurate',
];

const transformErrors = (errors) => {
  const sortedEntries = sortBy(
    Object.entries(errors),
    ([key]) => errorKeys.indexOf(key),
  );

  return Object.fromEntries(sortedEntries);
};

export const EnterManualSavingsStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (
    newData: Partial<AwardFlowData> | null,
    direction: Direction | null,
    targetStep?: AwardFlowStepType,
  ) => void,
}) => {
  const { t } = useTranslation('translation');
  const { currencyCode } = rfx.useStructure<Live>();

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      manualTotalSavings: z.number({ message: t('request.awardFlow.errors.enterSavingsAmount') }),
      manualTotalSavingsDescription: z.string({ message: t('request.awardFlow.errors.describeSavings') }),
      areTotalSavingsAccurate: z.literal(true, { errorMap: () => ({ message: t('request.awardFlow.errors.confirmSavings') }) }),
    });

    return toFormikValidationSchema(Schema);
  }, [t]);

  return (
    <Formik
      validateOnBlur
      initialValues={data.spendAndSavings}
      validationSchema={validationSchema}
      onSubmit={noop}
    >
      {({ values, errors, submitForm, validateForm, setFieldValue, setFieldTouched, dirty }) => (
        <Form>
          <lotPagesLayout.ContentWrapper>
            <StepNavigation
              onBackClick={() => submitAndNavigate(dirty ? { spendAndSavings: values } : null, Direction.BACK)}
              onContinueClick={async () => {
                const errors = await validateForm();

                await submitForm();

                if (isEmpty(errors)) {
                  submitAndNavigate(dirty ? { spendAndSavings: values } : null, Direction.FORWARD);
                }
              }}
            >
              <FormErrors transformErrors={transformErrors} />
              <lotPagesLayout.Section
                heading={t('request.awardFlow.steps.enterManualSavings.heading')}
              >
                <Box mt="20px">
                  <CurrencyCodeProvider code={currencyCode}>
                    <MoneyField
                      required
                      name="manualTotalSavings"
                      sx={{ width: 200 }}
                      // quick fix: we can remove setting the inputStyle height once a line height is
                      // set for Input
                      inputStyle={{ height: 40 }}
                      errorMessageStyle={{ fontSize: '14px', marginTop: '16px' }}
                    />
                  </CurrencyCodeProvider>
                </Box>
                <lotPagesLayout.InfoText mt="10px">
                  {t('request.awardFlow.steps.enterManualSavings.info')}
                </lotPagesLayout.InfoText>
              </lotPagesLayout.Section>
              <lotPagesLayout.Section
                heading={t('request.awardFlow.steps.enterManualSavings.descriptionHeading')}
              >
                <Box mt="20px">
                  <TextField
                    name="manualTotalSavingsDescription"
                    isMultiLine
                    hideLabel
                    errorMessageStyle={{ fontSize: '14px', marginTop: '16px' }}
                  />
                </Box>
              </lotPagesLayout.Section>
              <lotPagesLayout.Section
                heading={t('request.awardFlow.confirmationHeading')}
              >
                <Box mt="12px">
                  <CheckboxFieldBase
                    fieldLabel={(
                      <Text
                        as="span"
                        fontSize={4}
                        fontWeight={500}
                        lineHeight={1.4}
                        sx={{
                          position: 'relative',
                          top: '-4px',
                        }}
                      >
                        {t('request.awardFlow.steps.enterManualSavings.confirmCheckboxLabel')}
                      </Text>
                    )}
                    name="areTotalSavingsAccurate"
                    // @ts-expect-error ts(2322) FIXME: Type 'boolean | null' is not assignable to type 'boolean | undefined'.
                    value={values.areTotalSavingsAccurate}
                    error={errors?.areTotalSavingsAccurate as string | undefined}
                    onChange={async (event: any) => {
                      await setFieldValue('areTotalSavingsAccurate', Boolean(event.target.checked));
                      await setFieldTouched('areTotalSavingsAccurate', true);
                    }}
                    errorMessageStyle={{ fontSize: '14px', marginTop: '16px', marginLeft: '-24px' }}
                  />
                </Box>
                <SavingsNotAccurate
                  onClick={() => {
                    submitAndNavigate(dirty ? { spendAndSavings: values } : null, null, AwardFlowStepType.CHOOSE_SAVINGS_METHOD);
                  }}
                />
              </lotPagesLayout.Section>
            </StepNavigation>
          </lotPagesLayout.ContentWrapper>
        </Form>
      )}
    </Formik>
  );
};
