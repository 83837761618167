import * as React from 'react';
import styled from 'styled-components';
import { Link as RebassLink, LinkProps } from 'rebass/styled-components';
import { UrlContext } from './UrlProvider';

const Link = styled(RebassLink)`
  text-decoration: none;
`;

export const SignUpLink: React.FC<LinkProps & any> = ({ children, ...props }) => {
  const urls = React.useContext(UrlContext);

  return (
    <Link href={urls.signup} {...props}>
      {children}
    </Link>
  );
};
