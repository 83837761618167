import { useMemo, useState } from 'react';
import { first } from 'lodash';
import { getScrollbarSize } from '@deepstream/ui-utils/getScrollbarSize';
import { BORDER_ADJUSTMENT, DEFAULT_FROZEN_HEADER_HEIGHT } from '@deepstream/ui-kit/grid/core/constants';
import { DEFAULT_ROW_HEIGHT, EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { DefaultEditableGridStyles } from '@deepstream/ui-kit/grid/EditableGrid/EditableGridStyles';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { SortConfigProvider } from '@deepstream/ui-kit/grid/EditableGrid/sortConfig';
import { useSortedGridData } from '@deepstream/ui-kit/grid/EditableGrid/useSortedGridData';
import { RenderHoverableGridDataCell } from '@deepstream/ui-kit/grid/EditableGrid/RenderHoverableGridDataCell';
import { ReadOnlyGrid } from '@deepstream/ui-kit/grid/EditableGrid/ReadOnlyGrid';
import { RenderGridDataCell } from '@deepstream/ui-kit/grid/EditableGrid/RenderGridDataCell';
import * as rfx from '../../../rfx';
import { useTransformedColumns } from '../../Exchange/columns';

const viewportHeightDelta = 320;

export const ExchangesGrid = ({
  exchanges,
  columns,
  onRowClick,
}: {
  exchanges,
  columns,
  onRowClick,
}) => {
  const modelExchange = first(exchanges);

  const exchangesWithIndexes = useMemo(() => {
    return exchanges.map((exchange, index) => ({
      // `exchange` can overwrite the default array index; useful when filtering exchanges
      // and we want to show the same index for the same exchange.
      index,
      ...exchange,
    }));
  }, [exchanges]);

  const transformedColumns: EditableGridColumn[] = useTransformedColumns(columns, modelExchange);

  const maxGridHeight = (
    DEFAULT_FROZEN_HEADER_HEIGHT +
    BORDER_ADJUSTMENT +
    DEFAULT_ROW_HEIGHT * exchangesWithIndexes.length +
    getScrollbarSize()
  );

  const frozenLeftColumnIds = useMemo(() => {
    return transformedColumns.slice(0, 3).map(column => column._id);
  }, [transformedColumns]);

  const [sortConfig, setSortConfig] = useState({
    columnId: null,
    direction: null,
  });

  const sortedExchanges = useSortedGridData(sortConfig, exchangesWithIndexes, transformedColumns);

  return (
    <rfx.StateProvider>
      <SortConfigProvider sortConfig={sortConfig} setSortConfig={setSortConfig}>
        <GridIdPrefixProvider>
          <DefaultEditableGridStyles
            style={{ width: '100%', height: `min(100vh - ${viewportHeightDelta}px, ${maxGridHeight}px)` }}
            isReadOnly
            highlightOnHover
          >
            <ReadOnlyGrid
              frozenLeftColumnIds={frozenLeftColumnIds}
              columns={transformedColumns}
              rowData={sortedExchanges}
              RenderDataCell={onRowClick ? RenderHoverableGridDataCell : RenderGridDataCell}
              onRowClick={onRowClick}
            />
          </DefaultEditableGridStyles>
        </GridIdPrefixProvider>
      </SortConfigProvider>
    </rfx.StateProvider>
  );
};
