import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { findIndex } from 'lodash';
import { Flex, Text } from 'rebass/styled-components';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';
import { useSwitchToExchange } from './SwitchToExchange';
import { PageFooterContainer } from './PageFooterContainer';

export const PageFooter = ({
  exchangeId,
  recipientId,
  autoAdvance,
  progress,
}: {
  exchangeId: string;
  recipientId?: string;
  autoAdvance?: React.ReactNode;
  progress: React.ReactNode;
}) => {
  const { t } = useTranslation(['translation', 'general']);
  const { switchToExchange, verticalTargets, horizontalTargets } = useSwitchToExchange();

  if (!verticalTargets && !horizontalTargets) {
    return null;
  }

  const verticalIndex = exchangeId ? findIndex(verticalTargets, { exchangeId }) : -1;
  const previousVerticalTarget = verticalTargets?.[verticalIndex - 1];
  const nextVerticalTarget = verticalTargets?.[verticalIndex + 1];

  const horizontalIndex = recipientId ? findIndex(horizontalTargets, { recipientId }) : -1;
  const previousHorizontalTarget = horizontalTargets?.[horizontalIndex - 1];
  const nextHorizontalTarget = horizontalTargets?.[horizontalIndex + 1];

  return (
    <PageFooterContainer>
      <Flex alignItems="center" sx={{ gap: '8px' }}>
        {verticalTargets && (
          <Flex sx={{ gap: 2 }} alignItems="center">
            <ButtonGroup marginBetween="-1px">
              <Button
                type="button"
                variant="secondary-outline"
                iconLeft="chevron-up"
                small
                disabled={!previousVerticalTarget || verticalIndex === -1}
                onClick={() => switchToExchange({ ...previousVerticalTarget, ...horizontalTargets?.[horizontalIndex] })}
                aria-label={t('request.exchange.previousExchange')}
              />
              <Button
                type="button"
                variant="secondary-outline"
                iconLeft="chevron-down"
                small
                disabled={!nextVerticalTarget || verticalIndex === -1}
                onClick={() => switchToExchange({ ...nextVerticalTarget, ...horizontalTargets?.[horizontalIndex] })}
                aria-label={t('request.exchange.nextExchange')}
              />
            </ButtonGroup>
            {verticalIndex > -1 ? (
              <Flex flexDirection="column">
                <Text color="subtext" fontSize={1}>{t('general.exchange')}</Text>
                <Text>
                  {t('countOfTotal', { count: verticalIndex + 1, total: verticalTargets.length, ns: 'general' })}
                </Text>
              </Flex>
            ) : (
              null
            )}
          </Flex>
        )}
        {horizontalTargets && (
          <Flex sx={{ gap: 2 }} alignItems="center">
            <ButtonGroup marginBetween="-1px">
              <Button
                type="button"
                variant="secondary-outline"
                iconLeft="chevron-left"
                small
                disabled={!previousHorizontalTarget || horizontalIndex === -1}
                onClick={() => switchToExchange({ ...previousHorizontalTarget, ...verticalTargets?.[verticalIndex] })}
                aria-label={t('request.exchange.previousExchange')}
              />
              <Button
                type="button"
                variant="secondary-outline"
                iconLeft="chevron-right"
                small
                disabled={!nextHorizontalTarget || horizontalIndex === -1}
                onClick={() => switchToExchange({ ...nextHorizontalTarget, ...verticalTargets?.[verticalIndex] })}
                aria-label={t('request.exchange.nextExchange')}
              />
            </ButtonGroup>
            {verticalIndex > -1 ? (
              <Flex flexDirection="column">
                <Text color="subtext" fontSize={1}>{t('general.supplier', { count: 1 })}</Text>
                <Text>
                  {t('countOfTotal', { count: horizontalIndex + 1, total: horizontalTargets.length, ns: 'general' })}
                </Text>
              </Flex>
            ) : (
              null
            )}
          </Flex>
        )}
        {autoAdvance}
      </Flex>
      {progress}
    </PageFooterContainer>
  );
};
