import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Flex, Box } from 'rebass/styled-components';
import { size } from 'lodash';
import { Panel, PanelDivider, PanelText } from '@deepstream/ui-kit/elements/Panel';
import { useApi, wrap } from './api';
import { Loading } from './ui/Loading';
import { SentInvitesTable } from './SentInvitesTable';
import { SendInviteButton } from './InviteModal';
import { ErrorMessage } from './ui/ErrorMessage';
import { useCurrentCompanyId } from './currentCompanyId';
import * as Layout from './Layout';

export const SentInvitesPage = () => {
  const currentCompanyId = useCurrentCompanyId();
  const { t } = useTranslation();
  const api = useApi();

  const { data: invites, status: invitesStatus, refetch } = useQuery(
    ['invites', { companyId: currentCompanyId }],
    wrap(api.getCompanyInvites),
  );

  return (
    <Layout.BasicHeader heading={t('pageHeader.invites')} icon="envelope">
      <Panel mb={4} mt={2}>
        <Flex justifyContent="space-between">
          <PanelText>
            {t('invite.inviteCount', { count: size(invites) })}
          </PanelText>
          <Box my={3} mr={2}>
            <SendInviteButton
              label={t('invite.newInvite')}
              iconLeft="plus"
              isNetwork
              onInvitesSent={() => refetch()}
              small
            />
          </Box>
        </Flex>

        <PanelDivider />

        {invitesStatus === 'loading' ? (
          <PanelText>
            <Loading />
          </PanelText>
        ) : invitesStatus === 'error' ? (
          <ErrorMessage error={t('errors.unexpected')} />
        ) : invites.length ? (
          <SentInvitesTable invites={invites} />
        ) : (
          <PanelText color="gray">{t('invite.noInvitesYet')}</PanelText>
        )}
      </Panel>
    </Layout.BasicHeader>
  );
};
