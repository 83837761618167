import { isFuture } from 'date-fns';
import { chatExchangeTypes } from '@deepstream/common/exchangesConfig';
import { ExchangeType } from '@deepstream/common/rfq-utils';
import { useExchange } from '../useExchange';

const exchangeTypesEnabledAfterDeadline = [
  ...chatExchangeTypes,
  ExchangeType.CLARIFICATION,
  ExchangeType.BULLETIN,
  ExchangeType.INTERNAL_DOCUMENT,
  ExchangeType.EVALUATION_CRITERION,
];

export const useIsEnabledAfterDeadline = () => {
  const exchange = useExchange();

  if (exchangeTypesEnabledAfterDeadline.includes(exchange.def.type)) {
    return true;
  }

  if (!exchange.extendedDeadline) {
    return false;
  }

  return (
    exchange.extendedDeadline === true ||
    isFuture(new Date(exchange.extendedDeadline))
  );
};
