import { Box, Flex } from 'rebass/styled-components';
import { Breadcrumbs } from '@deepstream/ui-kit/elements/navigation/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import * as Layout from '../Layout';
import { HeaderToggleButton } from '../HeaderToggleButton';
import { useRecipientPageHeaderConfig } from '../modules/Request/Recipient/useRecipientPageHeaderConfig';
import * as lotPagesLayout from '../modules/Request/Live/lotPagesLayout';
import { useLiveRfqStructure, useRecipientId, useRfqId } from '../useRfq';
import { useCurrentCompanyId } from '../currentCompanyId';
import { Loading } from '../ui/Loading';
import { ErrorMessage } from '../ui/ErrorMessage';
import * as rfx from '../rfx';

const RecipientPageHeaderContent = () => {
  const {
    heading,
    breadcrumbs,
  } = useRecipientPageHeaderConfig();

  return (
    <Box flex={1} pb={2}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <lotPagesLayout.H1 mt="3px">
        {heading}
      </lotPagesLayout.H1>
    </Box>
  );
};

export const RecipientPageHeader = ({
  isHeaderCollapsible,
}: {
  isHeaderCollapsible?: boolean;
}) => {
  const { t } = useTranslation('translation');
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const recipientId = useRecipientId();

  const { data: structure, isLoading, isError, isSuccess } =
    useLiveRfqStructure({ rfqId, recipientId, currentCompanyId });

  return (
    <Layout.PageHeaderWrapper>
      <Flex
        pt="14px"
        pb="6px"
        px="1px"
        justifyContent="spaceBetween"
        alignItems="flex-start"
      >
        {isLoading ? (
          <Loading mb="13px" />
        ) : isError ? (
          <ErrorMessage
            fontSize={2}
            lineHeight={1}
            style={{ margin: '3px 0 13px' }}
            error={t('errors.unexpected')}
          />
        ) : isSuccess && structure ? (
          <rfx.StructureProvider structure={structure}>
            <RecipientPageHeaderContent />
          </rfx.StructureProvider>
        ) : (
          null
        )}
        {isHeaderCollapsible && (
          <HeaderToggleButton
            sx={{
              position: 'absolute',
              right: '15px',
              marginLeft: 'auto',
              bottom: '-14px',
              width: 'max-content',
              zIndex: 999,
            }}
          />
        )}
      </Flex>
    </Layout.PageHeaderWrapper>
  );
};
