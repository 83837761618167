import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Text } from 'rebass/styled-components';
import { formatDocumentAuditTrailActionDetail } from '@deepstream/common/documents';
import { DownloadCsvButton } from '@deepstream/ui-kit/elements/button/Button';
import { PanelDivider, PanelHeader, Panel } from '@deepstream/ui-kit/elements/Panel';
import { DatetimeCell } from '../../DatetimeCell';

import { Table } from '../../Table';
import { BasicTableStyles } from '../../TableStyles';
import { useTableData } from '../../TableDataContext';
import { TruncateCell } from '../../TruncateCell';
import { ErrorPanel } from '../../ui/ErrorMessage';
import { DateFieldValue } from '../../FieldsCell';
import { LoadingPanel } from '../../ui/Loading';
import { useHistoryCSV } from './HistoryTableDataProvider';
import { VisibilityCell } from './DocumentsTable';
import { nestCells } from '../../nestCells';
import { useCurrentUserLocale } from '../../useCurrentUser';

const TableHeader = () => {
  const downloadCSV = useHistoryCSV();
  const { t } = useTranslation();
  const { data } = useTableData();
  const historyCount = data.length;

  return (
    <PanelHeader
      heading={
        <Text fontSize={2} fontWeight={500}>
          {t('drivePage.historyTableTitle', { count: historyCount })}
        </Text>
      }
    >
      <Flex>
        <Box mr={2}>
          {!!historyCount && (
            <DownloadCsvButton
              onClick={() => downloadCSV()}
              small
            />
          )}
        </Box>
      </Flex>
    </PanelHeader>
  );
};

const HistoryActionDetailCell = ({ row: { original: event } }) => {
  const { t } = useTranslation();
  const locale = useCurrentUserLocale();

  return (
    <>
      {formatDocumentAuditTrailActionDetail({
        event,
        t,
        locale,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })}
    </>
  );
};

const TableContent = () => {
  const { t } = useTranslation();
  const columns = useMemo(() => ([
    {
      Header: t('drivePage.historyColumns.date'),
      accessor: 'date',
      Cell: DatetimeCell,
      width: 200,
    },
    {
      Header: t('drivePage.historyColumns.user'),
      accessor: 'user',
      Cell: TruncateCell,
      width: 160,
    },
    {
      Header: t('drivePage.historyColumns.name'),
      accessor: 'documentName',
      Cell: TruncateCell,
      width: 160,
    },
    {
      Header: t('drivePage.historyColumns.type'),
      accessor: 'documentType',
      Cell: TruncateCell,
      width: 70,
    },
    {
      Header: t('drivePage.historyColumns.visibility'),
      accessor: 'documentVisibility',
      Cell: VisibilityCell,
      width: 90,
    },
    {
      Header: t('drivePage.historyColumns.id'),
      accessor: 'documentId',
      Cell: TruncateCell,
      width: 190,
    },
    {
      Header: t('drivePage.historyColumns.expiry'),
      accessor: 'documentExpiryDate',
      Cell: ({ cell }) => (
        <DateFieldValue value={cell.value} />
      ),
      width: 140,
    },
    {
      Header: t('drivePage.historyColumns.action'),
      accessor: (event) => t(`drivePage.auditTrail.actions.${event.actionType}`),
      width: 160,
    },
    {
      id: 'detail',
      Header: t('drivePage.historyColumns.detail'),
      accessor: 'detail',
      Cell: nestCells(HistoryActionDetailCell, TruncateCell),
      width: 380,
    },
  ]), [t]);

  return (
    <BasicTableStyles hoverCursor="default">
      <TableHeader />
      <PanelDivider />
      <div style={{ overflow: 'auto' }}>
        <Table
          columns={columns}
        />
      </div>
    </BasicTableStyles>
  );
};

export const HistoryTable = () => {
  const { query, data } = useTableData();
  const { t } = useTranslation();

  return query.isError ? (
    <ErrorPanel error={t('errors.unexpected')} />
  ) : query.isLoading ? (
    <LoadingPanel />
  ) : !data.length ? (
    <Panel>
      <Text p={3} fontSize={2} color="gray">
        {t('drivePage.warnings.noHistoryYet')}
      </Text>
    </Panel>
  ) : (
    <Panel>
      <TableContent />
    </Panel>
  );
};
