import { useFormikContext } from 'formik';
import { get } from 'lodash';
import { useWatchValue } from '../../hooks/useWatchValue';
import { useEditableGridData } from './editableGridData';

/**
 * Watches for changes in the editable grid data and submits
 * rows for which `hasRowChanged` returns `true` to Formik.
 *
 * The underlying logic is only concerned with updates of
 * properties of individual rows. Operations like reordering,
 * adding or removing rows are not supported.
 */
export const FormikGridRowUpdater = <TRow extends object>({
  fieldName,
  hasRowChanged,
}: {
  fieldName: string;
  hasRowChanged: (gridRow: TRow, formikRow: TRow) => boolean;
}) => {
  const { rowData } = useEditableGridData<TRow>();
  const { values, setFieldValue, setFieldTouched } = useFormikContext<any>();

  useWatchValue(
    rowData,
    (rowData) => {
      // We update formik at the row level to prevent error messages for rows
      // that the user hasn't edited yet (which would show if we updated the
      // whole array at once).
      const updateField = async (path, value) => {
        await setFieldTouched(path, true, false);
        await setFieldValue(path, value);
      };

      Promise.all(
        rowData.map((item, index) => {
          const storedItem = get(values, fieldName)[index];

          if (hasRowChanged(item, storedItem)) {
            return updateField(`${fieldName}[${index}]`, item);
          } else {
            return null;
          }
        }),
      );
    },
  );

  return null;
};
