import { BidStatus, isBidActive, Live } from '@deepstream/common/rfq-utils';
import { useMachine } from '@xstate/react';
import { map, partition, size, unary } from 'lodash';
import { useEffect } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { callAll } from '@deepstream/utils/callAll';
import { ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { useApi } from '../../../api';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useMutation } from '../../../useMutation';
import { useRfqId } from '../../../useRfq';
import * as rfx from '../../../rfx';
import { useToaster } from '../../../toast';
import { useWaitForRfqUnlock } from '../../../useWaitForUnlock';
import { defaultRejectBidsValue, twoStepSelectSupplierMachine } from './twoStepSelectSupplierMachine';
import { RequestOutcomeModal } from './RequestOutcomeModal';
import { useRejectBidsStateToComponent } from './RequestOutcomeModalStates';
import { Actions } from './Actions';

type RejectBidsModalProps = {
  loserIds: string[];
  onClose: (shouldRefetchRfq: boolean) => void;
} & ModalProps;

const rejectBidsMachine = twoStepSelectSupplierMachine.withContext(defaultRejectBidsValue);

export const RejectBidsModal: React.FC<RejectBidsModalProps> = ({
  onClose,
  loserIds,
  ...props
}) => {
  const { t } = useTranslation();
  const api = useApi();
  const toaster = useToaster();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqId = useRfqId({ required: true });
  const { recipients, bidById } = rfx.useStructure<Live>();
  const rejectBidsStateToComponent = useRejectBidsStateToComponent();
  const waitForRfqUnlock = useWaitForRfqUnlock();
  const [rejectBids] = useMutation(
    ({ rfqId, companyId, outcome }) => waitForRfqUnlock({
      command: () => api.rejectBids({
        rfqId,
        companyId,
        unsuccessful: {
          recipientIds: map(outcome.suppliers, '_id'),
          message: outcome.hasMessage ? outcome.message : undefined,
          attachments: outcome.attachments,
        },
      }),
    }),
    {
      onSuccess: callAll(
        (data, variables) => toaster.success(t('request.suppliersTable.toaster.rejectBidSuccess', { count: size(variables.outcome.suppliers) })),
        () => onClose(true),
      ),
      onError: (data, variables) => toaster.error(t('request.suppliersTable.toaster.rejectBidError', { count: size(variables.outcome.suppliers) })),
    },
  );

  const [current, send] = useMachine(
    rejectBidsMachine,
    {
      services: {
        onConfirm: unary(rejectBids),
      },
    },
  );

  useEffect(
    () => {
      const recipientCompanies = recipients.map(recipient => recipient.company);

      const notUnsuccessfulSuppliers = recipientCompanies
        .filter(recipient => (
          bidById[recipient._id]?.status !== BidStatus.UNSUCCESSFUL
        ));

      const [activeSuppliers, inactiveSuppliers] = partition(
        notUnsuccessfulSuppliers,
        // @ts-expect-error ts(2345) FIXME: Argument of type 'BidStatus | null' is not assignable to parameter of type 'BidStatus'.
        supplier => isBidActive(bidById[supplier._id]?.status),
      );

      const selectedActiveSuppliers = notUnsuccessfulSuppliers
        .filter(supplier => (
          loserIds.includes(supplier._id) &&
          // @ts-expect-error ts(2345) FIXME: Argument of type 'BidStatus | null' is not assignable to parameter of type 'BidStatus'.
          isBidActive(bidById[supplier._id]?.status)
        ));

      send(Actions.INIT, {
        rfqId,
        companyId: currentCompanyId,
        activeSuppliers,
        inactiveSuppliers,
        selectedActiveSuppliers,
        outcome: {},
      });
    },
    [rfqId, currentCompanyId, send, props.isOpen, loserIds, recipients, bidById],
  );

  return (
    <RequestOutcomeModal
      modalTitle={t('request.suppliersTable.rejectBid')}
      isOpen={props.isOpen}
      stateComponents={rejectBidsStateToComponent}
      send={send}
      state={current}
      onClose={onClose}
    />
  );
};
