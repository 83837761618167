import { Flex, Box } from 'rebass/styled-components';
import { Attachment } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { useApi } from '../../../api';
import { useToaster } from '../../../toast';
import { useMutation } from '../../../useMutation';
import { downloadUrl } from '../../../useDownload';
import { FileList } from '../../../ui/FileList';
import { fakeUploadFn } from '../../../ui/fakeUploadFn';
import { useQuestionnaireData } from './questionnaireUtils';

export const useDownloadQuestionnaireAttachment = () => {
  const { t } = useTranslation('preQualification');
  const api = useApi();
  const toaster = useToaster();
  const { _id: questionnaireId } = useQuestionnaireData();

  return useMutation(
    async ({ _id: attachmentId }: Attachment) => {
      const { url } = await api.getQuestionnaireAttachmentDownloadUrl({ questionnaireId, attachmentId });
      downloadUrl(url, '');
    },
    {
      onSuccess: () => toaster.success(t('toaster.documentDownloaded.success')),
      onError: () => toaster.error(t('toaster.documentDownloaded.failed')),
    },
  );
};

export const QuestionnaireAttachment = ({
  attachment,
  truncate = false,
}) => {
  const [download, { isLoading }] = useDownloadQuestionnaireAttachment();

  return (
    <Flex alignItems="center" sx={{ maxWidth: '100%' }}>
      <IconText
        isIconRegular
        gap={2}
        icon="file"
        iconColor="subtext"
        text={attachment.name}
        truncate={truncate}
      />
      <IconButton
        fixedWidth
        icon="download"
        color="primary"
        onClick={event => {
          event.stopPropagation();
          download(attachment);
        }}
        disabled={isLoading}
        ml={2}
      />
    </Flex>
  );
};

export const QuestionnaireAttachmentList = ({ attachments, ...props }) => {
  const [download] = useDownloadQuestionnaireAttachment();

  return (
    <Box maxWidth={425} width="fit-content" {...props}>
      <FileList
        isReadOnly
        small
        initialAttachments={attachments}
        uploadFn={fakeUploadFn}
        downloadFn={download}
      />
    </Box>
  );
};
