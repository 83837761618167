// initSentry needs to be the first import
import './initSentry';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import Modal from 'react-modal';
import axios from 'axios';
import { initReactTranslations } from '@deepstream/ui/i18n';
import { router } from '@deepstream/ui/AppRouting';
import { App } from './App';

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = (config) => Boolean(config.withCredentials);

Sentry.addIntegration(Sentry.tanstackRouterBrowserTracingIntegration(router));

Modal.setAppElement('#root');

const container = document.getElementById('root');
if (!container) {
  // This shouldn't happen, but if it does, we want to know about it
  Sentry.captureException(new Error('Root element not found'));
  throw new Error('Root element not found');
}
const root = createRoot(container);

initReactTranslations()
  .then(() => {
    root.render(<App />);
  })
  .catch(err => Sentry.captureException(err));
