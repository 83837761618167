import * as React from 'react';
import { useQuery } from 'react-query';
import { isEqual } from 'lodash';
import { QuestionnaireTemplateOverviewsResponse } from '@deepstream/common/preQual';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { wrap } from '../../../api';
import { TableData, TableDataContext } from '../../../TableDataContext';

type PageControls = {
  pageIndex: number;
  pageSize: number;
};

/**
 * Provides a TableDataContext for questionnaire templates tables.
 */
export const QuestionnaireTemplatesTableDataProvider = <
  QueryDataT extends QuestionnaireTemplateOverviewsResponse,
>({
  queryTag,
  queryFn,
  pageSizeState,
  children,
}: {
  queryTag: string;
  queryFn: (...params: any[]) => Promise<QueryDataT>;
  pageSizeState: [number, (pageSize: number) => void];
  children: React.ReactElement;
}): React.ReactElement<TableData<QueryDataT>> => {
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const [pageSize, setPageSize] = pageSizeState;
  const [pageIndex, setPageIndex] = React.useState<number>(0);

  const setPageControls = React.useCallback((pageControls: PageControls) => {
    setPageSize(pageControls.pageSize);
    setPageIndex(pageControls.pageIndex);
  }, [setPageIndex, setPageSize]);

  const [queryParams, setQueryParams] = React.useState(() => ({
    currentCompanyId,
    pageSize,
    pageIndex,
  }));

  const query = useQuery<QueryDataT>(
    [queryTag, queryParams],
    wrap(queryFn),
  );

  React.useEffect(() => {
    const newQueryParams = {
      currentCompanyId,
      pageSize,
      pageIndex,
    };

    if (!isEqual(queryParams, newQueryParams)) {
      setQueryParams(newQueryParams);
    }
  }, [
    currentCompanyId,
    pageSize,
    pageIndex,
    queryParams,
  ]);

  const value = React.useMemo(() => ({
    data: query.data?.overviews ?? [],
    pageCount: Math.ceil((query.data?.totalItems ?? 0) / queryParams.pageSize),
    pageSize: queryParams.pageSize,
    pageIndex: query.data?.pageIndex ?? 0,
    query,
    setPageControls,
  }), [
    query,
    queryParams,
    setPageControls,
  ]);

  return (
    <TableDataContext.Provider value={value}>
      {children}
    </TableDataContext.Provider>
  );
};

export type QuestionnaireTemplatesTableData = TableData<QuestionnaireTemplateOverviewsResponse>;
