import { useTranslation } from 'react-i18next';
import {
  LineItemExchangeDefinition,
  LineItemsSection,
  getExchangeDefFieldValue,
  getFieldIdsInDefaultDisplayOrder,
} from '@deepstream/common/rfq-utils';
import { pick } from 'lodash';
import { useField } from 'formik';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';

import { createLineItem } from './exchangeDefs';

export const createLineItemRow = (
  modelExchangeDef: LineItemExchangeDefinition | undefined,
  responseTagConfig?: LineItemsSection['responseTagConfig'],
) => {
  const newLineItem = createLineItem(responseTagConfig);

  return modelExchangeDef
    ? {
      ...pick(newLineItem, ['_id', 'type']),
      evaluatorFieldCurrency: modelExchangeDef
        // @ts-expect-error ts(2345) FIXME: Argument of type 'string' is not assignable to parameter of type 'never'.
        ? getExchangeDefFieldValue(modelExchangeDef, 'evaluatorFieldCurrency')
        : undefined,
      fields: {
        ...modelExchangeDef.fields,
      },
      orderedFieldIds: modelExchangeDef.orderedFieldIds,
    } as LineItemExchangeDefinition
    : {
      ...newLineItem,
      orderedFieldIds: getFieldIdsInDefaultDisplayOrder(Object.keys(newLineItem.fields)),
    };
};

export const AddBuyerProvidedLineItemButton = ({
  modelExchangeDef,
  ...props
}: { modelExchangeDef?: LineItemExchangeDefinition } & ButtonProps) => {
  const { t } = useTranslation();
  const [{ value: responseTagConfig }] = useField('section.responseTagConfig');
  const { appendRows } = useEditableGridData<LineItemExchangeDefinition>();

  return (
    <Button
      small
      variant="secondary-transparent-outline"
      iconLeft="plus"
      type="button"
      onClick={() => {
          const newRow = createLineItemRow(modelExchangeDef, responseTagConfig);

          appendRows([newRow]);
        }}
      {...props}
    >
      {t('request.lineItems.addLineItem')}
    </Button>
  );
};

export const AddSupplierProvidedLineItemButton = ({
  modelExchangeDef,
  ...props
}: { modelExchangeDef?: LineItemExchangeDefinition } & ButtonProps) => {
  const { t } = useTranslation();
  const { appendRows } = useEditableGridData<LineItemExchangeDefinition>();

  return (
    <Button
      small
      variant="secondary-transparent-outline"
      iconLeft="plus"
      type="button"
      onClick={() => {
          const newRow = createLineItemRow(modelExchangeDef);

          appendRows([newRow]);
        }}
      {...props}
    >
      {t('request.lineItems.addLineItem')}
    </Button>
  );
};
