import { findIndex, isEmpty, isEqual, isNumber, some } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { Flex } from 'rebass';
import { Draft, isAuctionStage } from '@deepstream/common/rfq-utils';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { MultiStageLineItemsEventType } from '@deepstream/common/multiStageLineItemsTracking';
import { scrollToTop } from '@deepstream/ui-utils/scrollToTop';
import { DraftStages } from '../../../../draft/DraftStages';
import * as rfx from '../../../../rfx';
import { RfxStructure } from '../../../../types';
import { FormErrorsLayout } from '../../../../ui/MultiStepFlow/FormErrors';
import { useMultiStepFlowData } from '../../../../ui/MultiStepFlow/MultiStepFlowContext';
import { StepNavigationBackLink } from '../../../../ui/MultiStepFlow/StepNavigation';
import { Direction } from '../../../../ui/MultiStepFlow/types';
import { useRfqId } from '../../../../useRfq';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { SetupMultiStageResponsesFlowData, SetupMultiStageResponsesFlowStepType } from '../types';
import { useTrackSetupMultiStageResponsesEvent } from '../tracking';

const hasAtLeastTwoGeneralStages = (rfxStructure: RfxStructure<Draft>) : boolean => {
  const { stages, meta: { enteredRecipientStageIds } } = rfxStructure;
  const previouslyEnteredStageIds = (enteredRecipientStageIds || []).slice(0, -1);

  const generalStages = stages.filter((stage) => !isAuctionStage(stage) && !previouslyEnteredStageIds.includes(stage._id));
  return generalStages.length >= 2;
};

const getValidationErrors = (
  rfxStructure: RfxStructure<Draft>,
  t: TFunction,
): string[] => {
  const errors = [];
  const { stages } = rfxStructure;

  if (!hasAtLeastTwoGeneralStages(rfxStructure)) {
    errors.push(t('request.setupMultiStageResponsesFlow.steps.reviewStages.errors.addAtLeastTwoStages', { ns: 'translation' }));
  }
  if (stages.length > 1 && some(stages, (stage) => !stage.name)) {
    errors.push(t('request.validation.missingStageName', { ns: 'translation' }));
  }

  return errors;
};

export const ReviewStages = () => {
  const { t } = useTranslation('translation');
  const rfqId = useRfqId();
  const structure = rfx.useStructure<Draft>();
  const { isTemplate, isRevising } = rfx.useState();

  const { submitAndNavigate } = useMultiStepFlowData<
    SetupMultiStageResponsesFlowStepType,
    SetupMultiStageResponsesFlowData
  >({ required: true });

  const onBackClick = useCallback(
    () => submitAndNavigate(null, Direction.BACK),
    [submitAndNavigate],
  );

  const [showValidationErrors, setShowValidationErrors] = useState<boolean>();
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  useEffect(() => {
    if (showValidationErrors) {
      const updatedErrorMessages = getValidationErrors(structure, t);
      if (!isEqual(updatedErrorMessages, errorMessages)) {
        setErrorMessages(getValidationErrors(structure, t));
      }
    }
  }, [structure, t, showValidationErrors, errorMessages]);

  const trackFlowEvent = useTrackSetupMultiStageResponsesEvent({
    eventType: MultiStageLineItemsEventType.REVIEW_STAGES_STEP_COMPLETED,
  });

  const onContinueClick = useCallback(
    async () => {
      setShowValidationErrors(true);
      const { stages } = structure;

      const errors = getValidationErrors(structure, t);
      if (isEmpty(errors)) {
        await trackFlowEvent();
        submitAndNavigate({ updatedStages: stages }, Direction.FORWARD);
      } else {
        setErrorMessages(errors);
        scrollToTop('smooth');
      }
    },
    [trackFlowEvent, structure, submitAndNavigate, t],
  );

  const [editingStageIndex, setEditingStageIndex] = useState<number | null>(null);
  const onEditingStageIdChange = useCallback((editingStageId) => {
    if (editingStageId === null) {
      setEditingStageIndex(null);
    }
    const stageIndex = findIndex(structure?.stages, (stage) => stage._id === editingStageId);
    setEditingStageIndex(stageIndex === -1 ? null : stageIndex);
  }, [structure, setEditingStageIndex]);

  return (
    <lotPagesLayout.ContentWrapper2>
      <StepNavigationBackLink onClick={onBackClick} />
      <FormErrorsLayout errors={errorMessages} />
      <lotPagesLayout.Section2
        heading={t(
          'request.setupMultiStageResponsesFlow.steps.reviewStages.heading',
        )}
      >
        <lotPagesLayout.Subsection2
          heading={t('request.setupMultiStageResponsesFlow.steps.guidelines.things.heading')}
          mt="16px"
        >
          <lotPagesLayout.Ul style={{ marginTop: '12px' }}>
            <Trans
              i18nKey="request.setupMultiStageResponsesFlow.steps.reviewStages.things.body"
              ns="translation"
              components={{
                li: <li />,
                b: <b />,
              }}
            />
          </lotPagesLayout.Ul>
        </lotPagesLayout.Subsection2>
        <lotPagesLayout.Subsection2
          heading={t('general.stage_other')}
          mt="16px"
        >
          <DraftStages
            rfqId={rfqId}
            isRevising={isRevising}
            isTemplate={isTemplate}
            showDisclosure={false}
            showDraftingToolbar={false}
            canAddAuctionStage={false}
            showStageNameValidation={showValidationErrors}
            onEditingStageIdChange={onEditingStageIdChange}
          />
          {showValidationErrors && !hasAtLeastTwoGeneralStages(structure) && (
            <IconText
              mt="20px"
              fontSize={1}
              fontWeight={500}
              color="danger"
              icon="info-circle"
              text={t('request.setupMultiStageResponsesFlow.steps.reviewStages.errors.addAtLeastTwoStages')}
            />
          )}
        </lotPagesLayout.Subsection2>
      </lotPagesLayout.Section2>
      <Flex flexDirection="column" sx={{ rowGap: '16px' }}>
        <Button
          onClick={onContinueClick}
          disabled={isNumber(editingStageIndex)}
          sx={{ alignSelf: 'flex-start' }}
        >
          {t('continue', { ns: 'general' })}
        </Button>
        {isNumber(editingStageIndex) && (
          <IconText
            fontSize={1}
            color="subtext"
            icon="info-circle"
            isIconRegular
            text={t(
              'request.setupMultiStageResponsesFlow.steps.reviewStages.saveChangesToContinue',
              { stageIndex: editingStageIndex + 1 },
            )}
          />
        )}
      </Flex>
    </lotPagesLayout.ContentWrapper2>
  );
};
