import { DraftTeam } from '../../DraftTeam';
import { useNavigate } from '../../tanstackRouter';
import { useCurrentCompanyId } from '../../currentCompanyId';
import * as Layout from '../../Layout';
import { DraftHeader } from './DraftHeader';
import { teamManagementRoute } from '../../AppRouting';

export const DraftTeamPageContent = ({
  rfqId,
  isRevising,
  isTemplate,
}) => {
  const tabId = 'team';

  const navigate = useNavigate();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  return (
    <Layout.HeaderAndContent
      header={
        <DraftHeader
          rfqId={rfqId}
          isRevising={isRevising}
          isTemplate={isTemplate}
          selectedTabId={tabId}
        />
      }
      content={
        <DraftTeam
          rfqId={rfqId}
          isRevising={isRevising}
          isTemplate={isTemplate}
          navigateToCompanyTeam={() => {
            navigate({
              to: teamManagementRoute.to,
              params: { currentCompanyId },
            });
          }}
        />
      }
      contentPaddingBottom={Layout.BOTTOM_TOOLBAR_CONTENT_PADDING_BOTTOM}
    />
  );
};
