import { RequestSuppliers } from '../RequestSuppliers';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useRequestEditNavigation } from '../../appNavigation';
import { DraftHeader } from './DraftHeader';
import * as Layout from '../../Layout';

export const DraftSuppliersPageContent = ({
  rfqId,
  isRevising,
  isTemplate,
}) => {
  const tabId = 'suppliers';

  const { navigateToSuppliers } = useRequestEditNavigation();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  return (
    <Layout.HeaderAndContent
      header={
        <DraftHeader
          rfqId={rfqId}
          isRevising={isRevising}
          isTemplate={isTemplate}
          selectedTabId={tabId}
        />
      }
      content={
        <RequestSuppliers
          rfqId={rfqId}
          isRevising={isRevising}
          isTemplate={isTemplate}
          currentCompanyId={currentCompanyId}
          isReadOnly={false}
          onClickEdit={navigateToSuppliers}
        />
      }
      contentPaddingBottom={Layout.BOTTOM_TOOLBAR_CONTENT_PADDING_BOTTOM}
    />
  );
};
