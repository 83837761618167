import { useTranslation } from 'react-i18next';
import { EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { OverflowTooltip, Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { isString } from 'lodash';
import { immutableSet } from '@deepstream/utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { FrozenHeaderCellProps } from '@deepstream/ui-kit/grid/core/utils';
import { Box, Flex } from 'rebass/styled-components';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { DropdownMenu, DropdownMenuDivider, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { Clamp } from '@deepstream/ui-kit/elements/text/Clamp';
import { Company } from '@deepstream/common/rfq-utils';
import { SuppliersGridConfig } from './useSuppliersGridConfig';
import { GridFilterConfig } from '../../../ui/ExchangeDefsGrid/GridFilterDropdown';

export const SUPPLIERS_GRID_FILTER_DROPDOWN_ID = 'suppliers-grid-filter-dropdown';
export const SUPPLIERS_GRID_SORT_DROPDOWN_ID = 'suppliers-grid-sort-dropdown';

export const SuppliersGridColumnHeader = (props: FrozenHeaderCellProps<EditableGridColumn> & {
  suppliersGridConfig: SuppliersGridConfig;
  canHideColumn?: boolean;
  testId?: string; // Used for intercom/e2e tests to target an element
}) => {
  const { t } = useTranslation('translation');
  const {
    column,
    suppliersGridConfig,
    canHideColumn,
    info,
    testId,
  } = props;
  const { label, description, descriptionIcon } = column.original;

  // Columns with a getFilterLabel property have multiple filter pages;
  // since there's no single filter page that can be associated with the
  // current column, we hide the filter button.
  const showFilterDropdownItem = !(column.original as unknown as GridFilterConfig<Company>).getFilterLabel;

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
      width="100%"
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        mr={2}
      >
        <OverflowTooltip content={isString(label) ? label : undefined}>
          <Clamp lines={2}>{label}</Clamp>
        </OverflowTooltip>
        {description ? (
          <Box fontSize="10px" fontWeight={400} color="gray" mt="2px">
            {descriptionIcon}
            {description}
          </Box>
        ) : (
          null
        )}
      </Flex>
      <Box onKeyDown={stopPropagation} flex="0 0 auto">
        {info && (
          <Tooltip content={info}>
            <Icon regular color="subtext" icon="info-circle" mx={2} sx={{ pointerEvents: 'all' }} />
          </Tooltip>
        )}
        <DropdownMenu
          small
          variant="transparent-subtle"
          iconLeft="chevron-down"
          sx={{ width: '12px', height: '28px' }}
          menuZIndex={151}
          rightAligned
          data-test={testId}
        >
          {suppliersGridConfig.sorting.directionItems.map(item => (
            <DropdownMenuItem
              key={item.direction}
              icon={item.icon}
              onSelect={() => {
                const selectedSortingIndex = suppliersGridConfig.sorting.selectedSorting
                  .findIndex(sorting => sorting.criterion.accessor === column.original._id);

                const newSorting = selectedSortingIndex === -1
                  ? [
                    ...suppliersGridConfig.sorting.selectedSorting,
                    {
                      criterion: suppliersGridConfig.sorting.criteriaItems
                        .find(criteriaItem => criteriaItem.accessor === column.original._id)!,
                      direction: suppliersGridConfig.sorting.directionItems
                        .find(directionItem => directionItem.direction === item.direction)!,
                    },
                  ]
                  : immutableSet(
                    suppliersGridConfig.sorting.selectedSorting,
                    [selectedSortingIndex, 'direction'],
                    suppliersGridConfig.sorting.directionItems
                      .find(directionItem => directionItem.direction === item.direction),
                  );

                  suppliersGridConfig.sorting.onSortingChange(newSorting);

                  document.getElementById(SUPPLIERS_GRID_SORT_DROPDOWN_ID)?.click();
              }}
            >
              {t(`request.suppliersTable.sortLabel.${item.direction}`)}
            </DropdownMenuItem>
          ))}
          {showFilterDropdownItem ? (
            <>
              <DropdownMenuDivider />
              <DropdownMenuItem
                icon="filter"
                isIconRegular
                onSelect={() => {
                  const event = new CustomEvent('openpage', { detail: column.original._id });

                  document.getElementById(SUPPLIERS_GRID_FILTER_DROPDOWN_ID)?.dispatchEvent(event);
                }}
              >
                {t('requests.filtering.filterByColumn')}
              </DropdownMenuItem>
            </>
          ) : (
            null
          )}
          {canHideColumn ? (
            <>
              <DropdownMenuDivider />
              <DropdownMenuItem
                icon="eye-slash"
                onSelect={() => {
                  suppliersGridConfig.columns.onChange(
                    suppliersGridConfig.columns.selectedItems.filter(item => item._id !== column.original._id),
                  );
                }}
              >
                {t('request.suppliersTable.hideColumn', { count: 1 })}
              </DropdownMenuItem>
            </>
          ) : null}
        </DropdownMenu>
      </Box>
    </Flex>
  );
};
