import { useCallback } from 'react';
import { noop } from 'lodash';
import { ParseConfig, parse, ParseResult } from 'papaparse';

export type ImportFileParserSuccess<T> = {
  data: ParseResult<T>['data'];
  columns: string[];
};

export type ImportFileParserErrors<T> = ParseResult<T>['errors'];

export type ImportFileParserProps<T> = {
  onSuccess?: (props: ImportFileParserSuccess<T>) => void;
  onError?: (props: ImportFileParserErrors<T>) => void;
  transform?: ParseConfig<T>['transform'];
  transformHeader?: ParseConfig<T>['transformHeader'];
};

export const useImportFileParser = <T>({
  onSuccess = noop,
  onError = noop,
  transform = (value) => value.trim(),
  transformHeader,
}: ImportFileParserProps<T>) => {
  return useCallback((file: File) => {
    parse<T, File>(file, {
      skipEmptyLines: 'greedy',
      preview: 1000,
      dynamicTyping: true,
      header: true,
      transform,
      transformHeader,
      complete: (results) => {
        if (results.errors && results.errors.length) {
          onError(results.errors);

          return;
        }

        onSuccess({
          data: results.data,
          // @ts-expect-error ts(2322) FIXME: Type 'string[] | undefined' is not assignable to type 'string[]'.
          columns: results.meta.fields,
        });
      },
    });
  }, [onSuccess, onError, transform, transformHeader]);
};
