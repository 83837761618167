import { useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DefaultEditableGridStyles } from '@deepstream/ui-kit/grid/EditableGrid/EditableGridStyles';
import { ReadOnlyGrid } from '@deepstream/ui-kit/grid/EditableGrid/ReadOnlyGrid';
import { DEFAULT_ROW_HEIGHT, EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { RenderSelectableGridDataCell } from '@deepstream/ui-kit/grid/EditableGrid/RenderSelectableGridDataCell';
import { BORDER_ADJUSTMENT, DEFAULT_FROZEN_HEADER_HEIGHT } from '@deepstream/ui-kit/grid/core/constants';
import { getScrollbarSize } from '@deepstream/ui-utils/getScrollbarSize';
import { Company, Live } from '@deepstream/common/rfq-utils';
import { isEmpty } from 'lodash';
import { useGridSelection } from '@deepstream/ui-kit/grid/EditableGrid/GridSelectionContext';
import { Box, Flex } from 'rebass/styled-components';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import * as rfx from '../../rfx';
import { ViewId, viewConfigById } from '../../modules/Request/Sent/requestSentSuppliersViews';
import { defaultColumnWidth } from '../../modules/Request/Sent/useSuppliersGridColumns';
import { suppliersGridGroupConfig } from '../../modules/Request/Sent/suppliersGridGroupConfig';
import { SuppliersGridRow } from '../../modules/Request/Sent/useSuppliersGridColumnConfigById';
import { Bold } from '../../Bold';
import { getUuidV5 } from '../../utils';

const viewportHeightDelta = 312;

const frozenLeftColumnIds = ['rowSelectionCheckbox', 'supplier'];

export const GROUPED_ROW_SEPARATOR = '.';

export const SuppliersGrid = ({
  columns,
  recipients,
  navigateToSupplier,
  viewId,
  sortRows,
  filterRecipients,
  clearFilters,
  onColumnResize,
}: {
  columns: EditableGridColumn<SuppliersGridRow>[],
  recipients: Company[];
  navigateToSupplier: (company: Company) => void;
  viewId: ViewId;
  sortRows: (rows: SuppliersGridRow[]) => SuppliersGridRow[];
  filterRecipients: (recipients: Company[], groupId?: string) => Company[];
  clearFilters: () => void;
  onColumnResize?: (column: { id: string; width: number }) => void;
}) => {
  const { t } = useTranslation(['translation', 'request']);
  const structure = rfx.useStructure<Live>();
  const { setAllRowIds } = useGridSelection();

  const {
    totalRowCount,
    visibleRowCount,
    rows,
  }: {
    totalRowCount: number;
    visibleRowCount: number;
    rows: SuppliersGridRow[];
  } = useMemo(() => {
    const { groupBy } = viewConfigById[viewId];

    if (groupBy) {
      const {
        getGroupIds,
        isRecipientInGroup,
        getGroupLabel,
        getGroupIconProps,
        showEmptyGroupHeader,
      } = suppliersGridGroupConfig[groupBy];

      const groupIds = getGroupIds(structure);

      let totalRowCount = 0;
      let visibleRowCount = 0;

      const rows = groupIds.flatMap(groupId => {
        const recipientsInGroup = recipients.filter(recipient => isRecipientInGroup(groupId, recipient, structure));

        const filteredRecipients = filterRecipients(recipientsInGroup, groupId);

        totalRowCount += recipientsInGroup.length;
        visibleRowCount += filteredRecipients.length;

        const groupProps = {
          groupBy,
          [groupBy]: groupId,
        };

        if (isEmpty(filteredRecipients)) {
          return showEmptyGroupHeader(groupId, structure)
            ? [
              {
                _id: groupId,
                isSubHeader: true,
                numItems: filteredRecipients.length,
                label: getGroupLabel(groupId, structure, t),
                iconProps: getGroupIconProps?.(groupId, structure),
                groupProps,
              },
            ]
            : [];
        } else {
          return [
            {
              _id: groupId,
              isSubHeader: true,
              numItems: filteredRecipients.length,
              label: getGroupLabel(groupId, structure, t),
              iconProps: getGroupIconProps?.(groupId, structure),
              groupProps,
            },
            ...sortRows(filteredRecipients.map(recipient => ({
              _id: `${recipient._id}${GROUPED_ROW_SEPARATOR}${groupId}`,
              recipient,
              groupProps,
            }))),
          ];
        }
      });

      return {
        totalRowCount,
        visibleRowCount,
        rows,
      };
    } else {
      const filteredRecipients = filterRecipients(recipients);

      const rows = filteredRecipients.map(recipient => ({
        _id: recipient._id,
        recipient,
      }));

      return {
        totalRowCount: recipients.length,
        visibleRowCount: rows.length,
        rows: sortRows(rows),
      };
    }
  }, [structure, recipients, viewId, t, sortRows, filterRecipients]);

  useEffect(() => {
    const allRowIds = rows
      .map(row => row.isSubHeader ? null : row._id);

    setAllRowIds(allRowIds);
  }, [rows, setAllRowIds]);

  const hiddenRowCount = totalRowCount - visibleRowCount;
  const showFooter = hiddenRowCount && totalRowCount;

  const maxGridHeight = (
    DEFAULT_FROZEN_HEADER_HEIGHT +
    BORDER_ADJUSTMENT +
    DEFAULT_ROW_HEIGHT * rows.length +
    getScrollbarSize() +
    (showFooter ? 48 : 0)
  );

  const [columnsUuid, setColumnsUuid] = useState<string>('initial');
  const gridRef = useRef<any>();
  useEffect(() => {
    gridRef.current?.resetAfterRowIndex(0);
    gridRef.current?.resetAfterColumnIndex(0);
    setColumnsUuid(getUuidV5(columns.map(column => column._id).join(':')));
  }, [columns]);

  const FooterContent = useMemo(() => {
    if (showFooter) {
      return ({ height, width }) => {
        return (
          <Flex
            color="lightNavy"
            alignItems="center"
            sx={{
              position: 'absolute',
              left: 10,
              top: height - 48,
              height: '48px',
              width,
              zIndex: 6000,
              backgroundColor: 'rgb(247, 249, 251)',
            }}
          >
            <Box mb="3px">
              <Trans
                t={t}
                i18nKey="requests.filtering.rowHiddenByFilter"
                ns="translation"
                count={hiddenRowCount}
                components={{ b: <Bold mr="3px" /> }}
              />
            </Box>
            <Button
              ml={3}
              iconLeft="xmark"
              small
              variant="secondary-outline"
              type="button"
              onKeyDown={stopPropagation}
              onClick={clearFilters}
            >
              {t('requests.filtering.clearFilters')}
            </Button>
          </Flex>
        );
      };
    } else {
      return null;
    }
  }, [hiddenRowCount, showFooter, t, clearFilters]);

  return (
    <DefaultEditableGridStyles
      style={{ width: '100%', height: `min(100vh - ${viewportHeightDelta}px, ${maxGridHeight}px)` }}
      highlightOnHover
      cellContentCss="justify-content: flex-start;"
      hoverBackgroundColor="lightGray3Hover"
      selectedHoverBackgroundColor="lightPrimary36"
      disabledHoverBackgroundColor="lightGray3HoverDarker"
      disabledCellsWithPointer
    >
      <ReadOnlyGrid
        key={`${viewId}:${columnsUuid}`}
        gridRef={gridRef}
        columns={columns}
        rowData={rows as any}
        defaultColumnWidth={defaultColumnWidth}
        RenderDataCell={RenderSelectableGridDataCell}
        frozenLeftColumnIds={frozenLeftColumnIds}
        onRowClick={(row: any) => {
          if (row.recipient) {
            navigateToSupplier(row.recipient);
          }
        }}
        FooterContent={FooterContent}
        onColumnResize={onColumnResize}
      />
    </DefaultEditableGridStyles>
  );
};
