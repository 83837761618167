import { useTranslation } from 'react-i18next';
import { OkButton } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';

type LockedExchangesModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const LockedExchangesModal = ({ isOpen, onClose }: LockedExchangesModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} style={{ content: { width: '500px' } }}>
      <ModalHeader onClose={() => onClose()}>
        {t('requests.dialog.lockedExchanges.heading')}
      </ModalHeader>
      <ModalBody>
        <MessageBlock variant="error" mt={0}>
          {t('requests.dialog.lockedExchanges.body')}
        </MessageBlock>
      </ModalBody>
      <ModalFooter>
        <OkButton onClick={() => onClose()}>
          {t('general.dismiss')}
        </OkButton>
      </ModalFooter>
    </Modal>
  );
};
