import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { TextField, TextFieldBase } from '../form/TextField';
import { PropertyList } from '../PropertyList';
import * as rfx from '../rfx';
import { useUpdateSummary } from './useUpdateSummary';
import { SummaryPanelHeader } from './SummaryPanelHeader';
import { SummaryLabelConfigProvider } from './SummaryLabelConfigProvider';
import { LeavePageModal } from './LeavePageModal';

const panelId = 'referenceNumber';

export const SummaryReferenceNumbersPanel = () => {
  const { t } = useTranslation();
  const { stopEditing } = rfx.useActions();
  const { editingPanelId, isTemplate } = rfx.useState();
  const structure = rfx.useStructure();
  const [updateDraft] = useUpdateSummary();

  const isEditingOtherPanel = editingPanelId && editingPanelId !== panelId;
  const isEditingThisPanel = editingPanelId && editingPanelId === panelId;

  const properties = useMemo(() => [
    { name: t('request.summary.automatedReference'), value: structure.autoReferenceNumber },
    { name: t('request.summary.additionalReference'), value: structure.summary.reference },
  ], [structure.autoReferenceNumber, structure.summary.reference, t]);

  const initialValues = {
    'reference': structure.summary.reference,
  };

  const heading = t('request.summary.referenceNumbers');

  return (
    <SummaryLabelConfigProvider>
      <Panel
        mb={20}
        as="section"
        aria-label={heading}
        sx={{
          opacity: isEditingOtherPanel ? 0.5 : 1,
          boxShadow: isEditingThisPanel ? '0 0 8px 0 rgba(0, 0, 0, 0.3)' : '',
        }}
      >
        <SummaryPanelHeader
          heading={heading}
          panelId={panelId}
        />
        <PanelDivider />
        {isEditingThisPanel ? (
          <Formik
            validateOnBlur
            enableReinitialize
            initialValues={initialValues}
            validationSchema={
              yup.object().shape({
                'reference': yup.string(),
              })
            }
            onSubmit={async (values) => {
              await updateDraft({
                values,
                initialValues,
              }, {
                onSuccess: () => stopEditing(),
              });
            }}
          >
            {({ isSubmitting, dirty, isValid, resetForm }) => (
              <Form>
                <Flex mx={15} mb={20} mt={20}>
                  <TextFieldBase
                    label={t('request.summary.automatedReference')}
                    helperText={t('request.summary.automatedReferenceHelperText')}
                    disabled
                    value={isTemplate ? (
                      t('template.newRequestsOnly') as string
                    ) : (
                      structure.autoReferenceNumber ?? undefined
                    )}
                    name="autoReferenceNumber"
                    // quick fix: we can remove setting the inputStyle height once a line height is
                    // set for Input
                    inputStyle={{ height: 40 }}
                  />
                </Flex>
                <Flex mx={15} mb={20}>
                  <TextField
                    label={t('request.summary.additionalReference')}
                    helperText={t('request.summary.additionalReferenceHelperText')}
                    name="reference"
                    // quick fix: we can remove setting the inputStyle height once a line height is
                    // set for Input
                    inputStyle={{ height: 40 }}
                  />
                </Flex>
                <PanelDivider />
                <PanelPadding>
                  <Flex justifyContent="flex-end">
                    <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
                    <SaveButton disabled={isSubmitting || !dirty || !isValid} />
                  </Flex>
                </PanelPadding>
                <LeavePageModal />
              </Form>
            )}
          </Formik>
        ) : (
          <PropertyList properties={properties} />
        )}
      </Panel>
    </SummaryLabelConfigProvider>
  );
};
