import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { Tab, TabList, Tabs } from '../../ui/Tabs';
import { useSupplierListNavigation } from '../../appNavigation';
import { SupplierListTab } from './utils';

export const SupplierListTabs = ({ selectedTab }: { selectedTab: string }) => {
  const { t } = useTranslation();
  const { navigateToListSettings, navigateToListSuppliers } = useSupplierListNavigation();

  const onTabChange = useCallback(
    (index: number) => {
      if (index === 0) {
        navigateToListSuppliers();
      } else if (index === 1) {
        navigateToListSettings();
      }
    },
    [navigateToListSettings, navigateToListSuppliers],
  );

  return (
    <Tabs
      index={selectedTab === SupplierListTab.SUPPLIERS ? 0 : 1}
      onChange={onTabChange}
      canOverflow
    >
      <TabList style={{ backgroundColor: 'inherit' }}>
        <Tab>
          <IconText icon="buildings" isIconRegular text={t('supplierLists.supplier', { count: 2 })} />
        </Tab>
        <Tab>
          <IconText icon="gear" isIconRegular text={t('supplierLists.settings')} />
        </Tab>
      </TabList>
    </Tabs>
  );
};
