import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { fromPairs, isEmpty, mapValues, noop, omit } from 'lodash';
import { Form, Formik } from 'formik';
import { immutableUpdate } from '@deepstream/utils';
import { Message } from '@deepstream/common/rfq-utils';
import { useMemo } from 'react';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData, AwardFlowStep, AwardFlowStepType, MessageConfig, MessageMethod } from '../types';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { TextField } from '../../../../form/TextField';
import * as rfx from '../../../../rfx';
import { FileField } from '../../../../form/FilesField';
import { SwitchField } from '../../../../form/SwitchField';
import { CompanyLogoAndName } from '../../../../CompanyLogo';
import { LabelConfig, LabelConfigProvider } from '../../../../LabelConfigProvider';
import { Direction } from '../../../../ui/MultiStepFlow/types';

const doNotMessageLabelStyles = {
  default: {
    fontSize: 2,
    position: 'relative',
    color: 'text',
    fontWeight: 400,
    mr: '6px',
  },
};

type MessageWithExcludeFlag = Message & { exclude?: boolean };

export const SUPPLIER_MESSAGE_MAX_ATTACHMENTS = 20;

const getSubmissionDataFromFormValues = (values: Record<string, MessageWithExcludeFlag>, data: AwardFlowData) => {
  const { supplierGroup } =
    data.currentStep as Extract<AwardFlowStep, { type: AwardFlowStepType.ENTER_MULTI_SUPPLIER_INDIVIDUAL_MESSAGES }>;

  const excludedSupplierIds = Object.entries(values)
    .filter(([_, message]) => message.exclude)
    .map(([supplierId]) => supplierId);

  return {
    messageConfigByGroup: immutableUpdate(
      data.messageConfigByGroup,
      supplierGroup,
      (config: MessageConfig) => ({
        ...config,
        excludedSupplierIds,
        messageBySupplierId: mapValues(
          omit(values, excludedSupplierIds),
          message => omit(message, ['exclude']),
        ),
      }),
    ),
  };
};

export const EnterMultiSupplierIndividualMessagesStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (data: Partial<AwardFlowData> | null, direction: Direction) => void,
}) => {
  const { t } = useTranslation('translation');
  const { supplierGroup } =
    data.currentStep as Extract<AwardFlowStep, { type: AwardFlowStepType.ENTER_MULTI_SUPPLIER_INDIVIDUAL_MESSAGES }>;
  const messageConfig = data.messageConfigByGroup[supplierGroup];
  const supplierIds = messageConfig.messageMethod === MessageMethod.BY_SUPPLIER ? (
    data.supplierIdsByGroup[supplierGroup]
  ) : messageConfig.messageMethod === MessageMethod.CUSTOM ? ([
    ...messageConfig.excludedSupplierIds,
    ...Object.keys(messageConfig.messageBySupplierId),
  ]) : (
    []
  );

  const suppliers = rfx.useSortedRecipients(supplierIds);

  // @ts-expect-error ts(2322) FIXME: Type 'Dictionary<{ exclude: boolean; text: string; attachments: Attachment[]; } | { text: null; attachments: never[]; exclude: boolean; }>' is not assignable to type 'Record<string, MessageWithExcludeFlag>'.
  const initialValues: Record<string, MessageWithExcludeFlag> = fromPairs(
    suppliers.map(supplier => [
      supplier._id,
      messageConfig.messageBySupplierId[supplier._id]
        ? {
          ...messageConfig.messageBySupplierId[supplier._id],
          exclude: messageConfig.excludedSupplierIds.includes(supplier._id),
        }
        : {
          text: null,
          attachments: [],
          exclude: messageConfig.excludedSupplierIds.includes(supplier._id),
        },
    ]),
  );

  const validationSchema = useMemo(() => {
    const Schema = z.object(fromPairs(
      suppliers.map(supplier => [
        supplier._id,
        z.custom<MessageWithExcludeFlag>().refine(
          value => Boolean(value?.text || value?.exclude),
          { message: t('request.awardFlow.errors.enterMessageForSupplier', { supplierName: supplier.company.name }) },
        ),
      ]),
    ));

    return toFormikValidationSchema(Schema);
  }, [suppliers, t]);

  return (
    <LabelConfigProvider
      variant={LabelConfig.LEFT}
      width="fit-content"
      style={doNotMessageLabelStyles}
    >
      <Formik
        validateOnBlur
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={noop}
      >
        {({ values, submitForm, validateForm, dirty, setFieldValue }) => (
          <Form>
            <lotPagesLayout.ContentWrapper>
              <StepNavigation
                onBackClick={() => submitAndNavigate(dirty ? getSubmissionDataFromFormValues(values, data) : null, Direction.BACK)}
                onContinueClick={async () => {
                  const errors = await validateForm();

                  await submitForm();

                  if (isEmpty(errors)) {
                    submitAndNavigate(dirty ? getSubmissionDataFromFormValues(values, data) : null, Direction.FORWARD);
                  }
                }}
              >
                <FormErrors />
                <lotPagesLayout.Section
                  heading={t(`request.awardFlow.steps.enterMultiSupplierIndividualMessages.heading.${supplierGroup}`)}
                >
                  <Text mt="20px">
                    {t('request.awardFlow.steps.enterMultiSupplierIndividualMessages.info1')}
                  </Text>
                  <Text mt={2} mb="20px">
                    {t('request.awardFlow.steps.enterMultiSupplierIndividualMessages.info2')}
                  </Text>
                  {suppliers.map(supplier => {
                    return (
                      <Box key={supplier._id} mt="12px" py="20px" sx={{ borderBottom: 'lightGray2' }}>
                        <Flex justifyContent="space-between" alignItems="center" mb="14px">
                          <CompanyLogoAndName sx={{ marginLeft: '6px', fontSize: 4, fontWeight: 500 }} company={supplier.company} />
                          <SwitchField
                            name={`${supplier._id}.exclude`}
                            label={t('request.awardFlow.steps.enterMultiSupplierIndividualMessages.doNotMessage')}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            width={42}
                            useDefaultLabelConfig={false}
                            onChange={checked => {
                              if (checked) {
                                setFieldValue(`${supplier._id}.text`, null);
                                setFieldValue(`${supplier._id}.attachments`, []);
                              }
                            }}
                          />
                        </Flex>
                        {values[supplier._id]?.exclude ? (
                          <Text color="subtext">
                            {t('request.awardFlow.steps.enterMultiSupplierIndividualMessages.willReceiveNoMessage')}
                          </Text>
                        ) : (
                          <>
                            <TextField
                              name={`${supplier._id}.text`}
                              isMultiLine
                              hideLabel
                              hideError
                              errorMessageStyle={{ fontSize: '14px', marginTop: '16px' }}
                            />
                            <Flex alignItems="center" mt="12px">
                              <Box width="400px">
                                <FileField name={`${supplier._id}.attachments`} hideLabel purpose="rfq" max={SUPPLIER_MESSAGE_MAX_ATTACHMENTS} />
                              </Box>
                              <Text flex="1 0 auto" color="subtext" ml={3}>
                                {t('request.awardFlow.optional')}
                              </Text>
                            </Flex>
                          </>
                        )}
                      </Box>
                    );
                  })}
                </lotPagesLayout.Section>
              </StepNavigation>
            </lotPagesLayout.ContentWrapper>
          </Form>
        )}
      </Formik>
    </LabelConfigProvider>
  );
};
