import * as React from 'react';
import { Box, BoxProps } from 'rebass/styled-components';
import styled from 'styled-components';
import ReactDOM from 'react-dom';

const maxContentWidth = 1260;
const paddingX = 30;

const OuterContainer = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  height: 70px;
  z-index: 202;
  animation: slide-bottom 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
`;

const InnerContainer = styled<any>(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: ${maxContentWidth + 2 * paddingX}px;
  padding: 0 ${paddingX}px;
  height: 100%;
`;

export const PageFooterContainer: React.FC<BoxProps> = ({ children, ...props }) => ReactDOM.createPortal(
  <OuterContainer {...props}>
    <InnerContainer>
      {children}
    </InnerContainer>
  </OuterContainer>,
  document.body,
  'page-footer',
);
