import { forwardRef } from 'react';
import * as React from 'react';
import { Flex, FlexProps, Box, BoxProps } from 'rebass/styled-components';

import { Stack } from '@deepstream/ui-kit/elements/Stack';

const SidebarColumn: React.FC<BoxProps> = forwardRef(({ children, ...props }, ref) => (
  <Box
    ref={ref}
    width={[1, 1, 4 / 12, 3 / 12]}
    pr={[null, null, 32, 32]}
    mb={[3, null, 0]}
    {...props}
  >
    <Stack gap={20}>
      {children}
    </Stack>
  </Box>
));

const MainColumn: React.FC<BoxProps> = forwardRef((props, ref) => (
  <Box
    ref={ref}
    width={[1, 1, 8 / 12, 9 / 12]}
    {...props}
  />
));

const Row: React.FC<FlexProps> = forwardRef((props, ref) => (
  <Flex
    ref={ref}
    width={[1, null, null, null]}
    mx="auto"
    flexWrap="wrap"
    {...props}
  />
));

export const SidebarLayout = ({
  sidebar,
  children,
}: {
  sidebar: React.ReactNode;
  children: React.ReactNode;
}) => (
  <Row mx={0}>
    <SidebarColumn>
      {sidebar}
    </SidebarColumn>
    <MainColumn>
      {children}
    </MainColumn>
  </Row>
);
