import { useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';
import { Live } from '@deepstream/common/rfq-utils';
import { isLotInactive } from '@deepstream/common/rfq-utils/lot';
import * as lotPagesLayout from '../Live/lotPagesLayout';
import { RequestRecipientRequirementsMenuSection } from './RequestRecipientRequirementsMenuSection';
import * as rfx from '../../../rfx';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { LoadingPanel } from '../../../ui/Loading';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import { useLiveRfqStructure, useRecipientId, useRfqId } from '../../../useRfq';
import { EnteredStageTimelineSection } from './EnteredStageTimelineSection';
import { RequestRecipientStageStatusTable } from './RequestRecipientStageStatusTable';
import { RequestRecipientExchangeStageMessage } from './RequestRecipientStageMessage';
import { RequestRecipientToolsSection } from './RequestRecipientToolsSection';

const StageStatusSection = () => {
  const { t } = useTranslation('translation');

  return (
    <lotPagesLayout.Section heading={t('general.status')}>
      <Box mt="6px">
        <RequestRecipientStageStatusTable />
      </Box>
    </lotPagesLayout.Section>
  );
};

export const RequestRecipientBidStageIndexContent = () => {
  const { lots, enteredPageIdsByRequirementGroupIdByStageId } = rfx.useStructure<Live>();
  const currentCompanyGroup = rfx.useCurrentCompanyGroup();
  const bid = rfx.useBid();
  const stageId = rfx.useStageId();

  const hasActivatedStage = bid.activatedStageIds.includes(stageId);

  const areAllRequirementGroupsInactive = (
    // @ts-expect-error ts(18048) FIXME: 'enteredPageIdsByRequirementGroupIdByStageId' is possibly 'undefined'.
    !enteredPageIdsByRequirementGroupIdByStageId[stageId]?.general &&
    lots.every(lot => isLotInactive(lot, bid.intentionStatusByLotId[lot._id]))
  );

  return (
    <lotPagesLayout.ContentWrapper>
      <RequestRecipientExchangeStageMessage />
      <EnteredStageTimelineSection />
      {hasActivatedStage && (
        <StageStatusSection />
      )}
      {!areAllRequirementGroupsInactive && (
        <RequestRecipientRequirementsMenuSection />
      )}
      {currentCompanyGroup === 'supplier' && <RequestRecipientToolsSection />}
      {areAllRequirementGroupsInactive && (
        <RequestRecipientRequirementsMenuSection />
      )}
    </lotPagesLayout.ContentWrapper>
  );
};

/**
 * Recipient-specific request pages, level 3. Only shown when lots are enabled.
 *
 * Contains an overview of the request's lots (or general requirements)
 * in the stage in concern and provides a navigation component that
 * directs to the individual level 4 bid pages (which render the
 * RequestRecipientBidStagePageInstance component).
 *
 * When lots are not enabled, the bid stage index route redirects to
 * individual bid pages, which render RequestRecipientBidStagePageInstance
 * components on level 3.
 */
export const RequestRecipientBidStageIndex = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const recipientId = useRecipientId();
  const rfqId = useRfqId();
  const { t } = useTranslation('translation');

  const { data: structure, isLoading, isError, isSuccess } =
    useLiveRfqStructure({ rfqId, recipientId, currentCompanyId });

  return isLoading ? (
    <LoadingPanel />
  ) : isError ? (
    <ErrorPanel error={t('errors.unexpected')} />
  ) : isSuccess && structure ? (
    <rfx.StructureProvider structure={structure}>
      <rfx.StateProvider isLive>
        <RequestRecipientBidStageIndexContent />
      </rfx.StateProvider>
    </rfx.StructureProvider>
  ) : (
    null
  );
};
