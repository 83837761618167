import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';
import { RequestsTableData } from './RequestsTableDataProvider';
import { useTableData } from './TableDataContext';
import { ExtendedReceivedRequestOverview, ExtendedSentRequestOverview, PublicRequestOverview, SentRequestOverviews } from './types';
import { SearchCombobox, SearchComboboxProps } from './ui/SearchCombobox';
import { useMutation } from './useMutation';

export const SEARCH_RESULTS_LIMIT = 20;

type RequestsOverviewT = ExtendedReceivedRequestOverview | ExtendedSentRequestOverview | PublicRequestOverview;

export type SearchRequestsProps<T extends RequestsOverviewT> = {
  queryFn: (...params: any[]) => Promise<{ rfqs :T[] }>;
  getResultText: (request: T) => string;
  onResultClick: (request: T) => void;
  disabled?: SearchComboboxProps<T>['disabled'];
};

export const SearchRequests = <T extends RequestsOverviewT>({
  queryFn,
  getResultText,
  onResultClick,
  disabled,
}: SearchRequestsProps<T>) => {
  const { t } = useTranslation('translation');
  const { searchText, setSearchText, queryParams } = useTableData<
    RequestsTableData<SentRequestOverviews>
  >({ required: true });

  const [search, { status, data }] = useMutation(queryFn);
  const { rfqs: requests } = data ?? {};
  const isLoading = status === 'loading';

  const searchRequests = useCallback(
    (text) => {
      if (text) {
        search({
          ...queryParams,
          // Search results limit is always the same, regardless the page size
          // set by the user for the requests table
          pageSize: SEARCH_RESULTS_LIMIT,
          pageIndex: 0,
          search: {
            text,
          },
        });
      }
    },
    [search, queryParams],
  );

  return (
    <Box width="300px" disabled>
      <SearchCombobox<T>
        initialValue={searchText}
        results={requests}
        getResultId={(request) => request._id}
        getResultText={getResultText}
        textPlaceholder={t('requests.searching.searchByRequestName')}
        noResultsMessage={t('requests.searching.noResults')}
        onResultClick={onResultClick}
        onSubmit={setSearchText}
        onChange={searchRequests}
        isLoading={isLoading}
        disabled={disabled}
      />
    </Box>
  );
};
