import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { Lock } from '@deepstream/common/rfq-utils';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { LabeledValue } from './LabeledValue';
import { LockState } from '../lock';
import { LotSelectItem } from './useLotSelectItems';

export const QuestionSectionConfigIndicators = ({
  isSender,
  stageName,
  locking,
  selectedLot,
}: {
  isSender: boolean;
  stageName?: string | number;
  locking?: Lock;
  selectedLot?: LotSelectItem;
}) => {
  const { t } = useTranslation('translation');

  return (
    <Flex alignItems="center" sx={{ gap: 3, color: selectedLot?.isObsolete ? 'subtext' : 'text' }}>
      {selectedLot && (
        <LabeledValue
          label={t('request.lots.sectionLotConfiguration')}
          value={
            <>
              {selectedLot.isObsolete && <Icon icon="ban" fixedWidth fontSize="inherit" mr="2px" />}
              {selectedLot?.label}
            </>
          }
          small
        />
      )}
      {!selectedLot?.isObsolete && (
        <>
          {isSender && stageName && (
            <LabeledValue
              label={t('request.visibleFromStage')}
              value={<Truncate>{stageName}</Truncate>}
              small
            />
          )}
          {isSender && (
            <LabeledValue
              label={t('request.lockResponses')}
              value={<LockState locking={locking} />}
              small
            />
          )}
        </>
      )}
    </Flex>
  );
};
