import { SetupMultiStageResponsesFlowStepType } from '../types';
import { Guidelines } from './Guidelines';
import { ReviewStages } from './ReviewStages';
import { SelectSections } from './SelectSections';
import { ConfigureSection } from './ConfigureSection';
import { AddSections } from './AddSections';
import { DeleteSections } from './DeleteSections';
import { ReviewChanges } from './ReviewChanges';

export const stepComponentByType = {
  [SetupMultiStageResponsesFlowStepType.GUIDELINES]: Guidelines,
  [SetupMultiStageResponsesFlowStepType.REVIEW_STAGES]: ReviewStages,
  [SetupMultiStageResponsesFlowStepType.SELECT_SECTIONS]: SelectSections,
  [SetupMultiStageResponsesFlowStepType.CONFIGURE_SECTION]: ConfigureSection,
  [SetupMultiStageResponsesFlowStepType.ADD_SECTIONS]: AddSections,
  [SetupMultiStageResponsesFlowStepType.DELETE_SECTIONS]: DeleteSections,
  [SetupMultiStageResponsesFlowStepType.REVIEW_CHANGES]: ReviewChanges,
};
