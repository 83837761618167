import * as React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, SaveButton, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { TextField } from '../../../form/TextField';
import { useToaster } from '../../../toast';
import { useApi } from '../../../api';
import { useMutation } from '../../../useMutation';
import { SelectField } from '../../../form/SelectField';

const useSizeOptions = () => {
  const { t } = useTranslation('companyProfile');

  return React.useMemo(() => {
    return [
      { label: `1-10 ${t('common.employee_other')}`, value: '1-10' },
      { label: `11-50 ${t('common.employee_other')}`, value: '11-50' },
      { label: `51-200 ${t('common.employee_other')}`, value: '51-200' },
      { label: `201-500 ${t('common.employee_other')}`, value: '201-500' },
      { label: `501-1000 ${t('common.employee_other')}`, value: '501-1000' },
      { label: `1001-5000 ${t('common.employee_other')}`, value: '1001-5000' },
      { label: `5001-10,000 ${t('common.employee_other')}`, value: '5001-10000' },
      { label: `10,001+ ${t('common.employee_other')}`, value: '10001' },
    ];
  }, [t]);
};

const useTypeOptions = () => {
  const { t } = useTranslation('companyProfile');

  return React.useMemo(() => {
    return ['buyer', 'supplier', 'both'].map(value => ({
      value,
      label: t(`basicInformationModal.fields.group.option.${value}`),
    }));
  }, [t]);
};

type EditModalProps =
  { company: any; onCancel: any; onSave: any } &
  ModalProps;

export const EditBasicInformationModal: React.FC<EditModalProps> = ({ company, onCancel, onSave, ...props }) => {
  const { t } = useTranslation(['companyProfile', 'translation']);
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const sizeOptions = useSizeOptions();
  const typeOptions = useTypeOptions();

  const [updateCompany] = useMutation(
    api.updateCompany,
    {
      onSettled: () => queryClient.invalidateQueries(['publicCompany', { companyId: company._id }]),
    },
  );

  return (
    <Modal onRequestClose={onCancel} {...props}>
      <Formik
        initialValues={{
          name: company.name,
          group: company.group,
          employeesNb: company.employeesNb,
        }}
        validationSchema={
          yup.object().shape({
            name: yup.string().trim().required(t('general.required', { ns: 'translation' })),
            group: yup.string().required(t('general.required', { ns: 'translation' })),
            employeesNb: yup.string().required(t('general.required', { ns: 'translation' })),
          })
        }
        onSubmit={async ({ name, group, employeesNb }, { setSubmitting }) => {
          try {
            await updateCompany({
              companyId: company._id,
              companyPatch: {
                name,
                group,
                employeesNb,
              },
            });
            toaster.success(t('toaster.updateProfileSuccess'));
            setSubmitting(false);
            onSave();
          } catch (error) {
            toaster.error(t('toaster.updateProfileError'));
            setSubmitting(false);
            throw error;
          }
        }}
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              {t('overview.basicInformation')}
            </ModalHeader>
            <ModalBody>
              <Stack gap={3}>
                <TextField
                  required
                  name="name"
                  label={t('basicInformationModal.fields.name.label')}
                />
                <SelectField
                  required
                  name="group"
                  label={t('basicInformationModal.fields.group.label')}
                  placeholder={t('basicInformationModal.fields.group.placeholder')}
                  items={typeOptions}
                />
                <SelectField
                  required
                  name="employeesNb"
                  label={t('basicInformationModal.fields.employees.label')}
                  placeholder={t('basicInformationModal.fields.employees.placeholder')}
                  items={sizeOptions}
                  placement="top"
                />
              </Stack>
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} />
              <SaveButton disabled={isSubmitting || !dirty} />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
