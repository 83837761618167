import { useTranslation } from 'react-i18next';
import { Attachment } from '@deepstream/common/rfq-utils';
import { useApi } from '../api';
import { useToaster } from '../toast';
import { useMutation } from '../useMutation';
import { downloadUrl } from '../useDownload';

export const useOwnDownload = () => {
  const api = useApi();
  const toaster = useToaster();
  const { t } = useTranslation();

  return useMutation(
    async ({ _id: attachmentId }: Attachment) => {
      const { url } = await api.getOwnAttachmentDownloadUrl({ attachmentId });
      downloadUrl(url, '');
    },
    {
      onSuccess: () => toaster.success(t('request.exchange.toaster.downloadAttachmentSuccess')),
      onError: () => toaster.error(t('request.exchange.toaster.downloadAttachmentError')),
    },
  );
};
