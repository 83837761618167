import { Trans, useTranslation } from 'react-i18next';
import { OkButton } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import { Live, StageType } from '@deepstream/common/rfq-utils';
import { useRequestRecipientNavigation } from '../../../appNavigation';
import * as rfx from '../../../rfx';
import * as lotPagesLayout from '../Live/lotPagesLayout';

type OpenAuctionStageModalProps = {
  isOpen: boolean;
  close: () => void;
};

export const OpenAuctionStageModal = ({ isOpen, close }: OpenAuctionStageModalProps) => {
  const { t } = useTranslation();
  const { stages } = rfx.useStructure<Live>();
  const navigation = useRequestRecipientNavigation();

  const auctionStage = stages.find(stage => stage.type === StageType.AUCTION);

  return (
    <Modal isOpen={isOpen} style={{ content: { width: '450px' } }}>
      <ModalHeader onClose={() => close()}>
        {t('request.dialog.openAuctionStage.title')}
      </ModalHeader>
      <ModalBody>
        <MessageBlock variant="info" mt={0}>
          <Trans
            i18nKey="request.dialog.openAuctionStage.body"
            ns="translation"
            components={{
              a: (
                <lotPagesLayout.TanstackInlineLink
                  // @ts-expect-error ts(18048) FIXME: 'auctionStage' is possibly 'undefined'.
                  {...navigation.getBidLinkProps(auctionStage._id)}
                />
              ),
            }}
          />
        </MessageBlock>
      </ModalBody>
      <ModalFooter>
        <OkButton onClick={() => close()}>
          {t('general.dismiss')}
        </OkButton>
      </ModalFooter>
    </Modal>
  );
};
