import { useTranslation } from 'react-i18next';

import { LoadingPanel } from '../../../ui/Loading';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import { useQuestionnaireTemplate } from './useQuestionnaireTemplate';
import { QuestionnaireTemplateProvider } from './questionnaireTemplateUtils';
import { DraftPageContent } from './DraftPageContent';
import { DraftFooter } from './DraftFooter';
import { QuestionsSectionPanel } from './QuestionsSectionPanel';
import { useDraftQuestionnaireTemplateNavigation } from '../../../appNavigation';

const DraftQuestionsFooter = () => {
  const draftNavigation = useDraftQuestionnaireTemplateNavigation();

  return (
    <DraftFooter
      onBack={() => draftNavigation.navigateToSummary()}
      onContinue={() => draftNavigation.navigateToReview()}
    />
  );
};

export const DraftQuestions = () => {
  const { t } = useTranslation();
  const { data: template, isLoading, isError } = useQuestionnaireTemplate({
    scope: 'draft',
  });

  if (isLoading) {
    return <LoadingPanel />;
  }

  if (isError) {
    return <ErrorPanel error={t('errors.unexpected')} />;
  }

  return (
    // @ts-expect-error ts(2322) FIXME: Type 'QuestionnaireTemplate | undefined' is not assignable to type 'QuestionnaireTemplate'.
    <QuestionnaireTemplateProvider template={template}>
      <DraftPageContent>
        <QuestionsSectionPanel />
      </DraftPageContent>
      <DraftQuestionsFooter />
    </QuestionnaireTemplateProvider>
  );
};
