import { addDays, addMonths, addYears } from 'date-fns';
import { TimeUnit } from '../rfq-utils';

export type OffsetConfig = {
  unit: TimeUnit.YEARS | TimeUnit.MONTHS | TimeUnit.DAYS;
  amount?: number;
};

export const getOffsetDate = (date: Date, offsetConfig: OffsetConfig) => {
  const { unit, amount } = offsetConfig;

  if (!amount) return null;

  if (unit === TimeUnit.YEARS) {
    return addYears(date, amount);
  } else if (unit === TimeUnit.MONTHS) {
    return addMonths(date, amount);
  } else if (unit === TimeUnit.DAYS) {
    return addDays(date, amount);
  }
};

export const getTimeUnitTranslationKey = (unit: TimeUnit) => unit
  // Ugly: time units are plural (eg `days`) and translation key are singular (eg dayCount),
  // so we need to remove the `s` at the end
  ? `${unit.slice(0, -1)}Count`
  : undefined;
