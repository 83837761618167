import { useMemo, useState } from 'react';
import { reject, map, sumBy, find, first } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Form, Formik, useField, useFormikContext } from 'formik';
import * as yup from 'yup';
import { Flex, Text, Box } from 'rebass/styled-components';
import {
  EvaluationSection,
  EvaluationCriterionExchangeDefinition,
  isLinkedEvaluationSection,
  isSectionLinkedFromEvaluation,
  setExchangeDefFieldValue,
  Draft,
  RfxEvaluationSection,
} from '@deepstream/common/rfq-utils';
import { IconValue } from '@deepstream/common';
import { localeFormatFactorAsPercent } from '@deepstream/utils';
import { SaveButton, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { BorderedIcon } from '@deepstream/ui-kit/elements/icon/BorderedIcon';
import { ExpandablePanelSubSection, PanelDivider, PanelPadding, PanelSubHeader } from '@deepstream/ui-kit/elements/Panel';
import { EditableGridDataProvider, useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import * as draft from './draft';
import * as rfx from '../rfx';
import { TextField } from '../form/TextField';
import { DraftPanel } from './DraftPanel';
import { usePreventEnterKeyHandler } from '../usePreventEnterKeyHandler';
import { GlobalDatePickerStyles } from '../ui/DatePicker';
import { EvaluationExchangeDefsGrid } from '../ui/ExchangeDefsGrid/EvaluationExchangeDefsGrid';
import { useCurrentUserLocale } from '../useCurrentUser';
import { ModelSizeLimitDialog, ModelSizeLimitMessages } from '../ModelSizeLimitDialog';
import { getSizeRelevantExchangeDefCount, useModelSizeLimits } from '../modelSizeLimits';
import { useModalState } from '../ui/useModalState';
import { SectionConfigHeading } from './SectionConfigHeading';
import { EvaluationSectionConfigIndicators } from './EvaluationSectionConfigIndicators';
import { DraftSectionLabelConfigProvider } from './DraftSectionLabelConfigProvider';
import { AddCriterionButton } from './AddCriterionButton';
import { createEvaluationCriterion } from './exchangeDefs';
import { LeavePageModal } from './LeavePageModal';
import { ExchangeDefFieldValueProvider } from '../ExchangeDefFieldValueContext';
import { NO_LOT_PROXY_ID, useLotSelectItems } from './useLotSelectItems';
import { SectionConfigLotSelectField } from './SectionConfigLotSelectField';
import { ObsoleteTextField } from './DraftSectionEditPanelHeader';
import { useHandleGridLotChange } from './useHandleGridLotChange';

/**
 * Header component for the current rfq section
 */
const EditSectionPanelHeader = ({ icon }: { icon?: IconValue }) => {
  const { t } = useTranslation();
  const { sectionById } = rfx.useStructure<Draft>();
  const section = rfx.useSectionWithPosition<EvaluationSection>();
  const isLinkedSection = isLinkedEvaluationSection(section);
  const [{ value: formIsSectionObsolete }] = useField<boolean | null>('section.isObsolete');

  const isSectionObsolete = formIsSectionObsolete || (
    isLinkedSection && sectionById[section.linkedSectionId]?.isObsolete
  );

  const TitleComponent = isSectionObsolete ? ObsoleteTextField : TextField;

  return (
    <PanelPadding>
      <Flex flexDirection="column" sx={{ gap: 2 }}>
        <Flex alignItems="flex-end">
          {icon && <BorderedIcon icon={icon} mr="8px" mb="5px" />}
          <TitleComponent
            required
            name="name"
            label={t('general.title')}
            // @ts-expect-error ts(2322) FIXME: Type 'Element' is not assignable to type 'undefined'.
            prefix={(
              <Text fontSize={2}>{section.number}</Text>
            )}
            disabled={isLinkedSection || isSectionObsolete}
              // quick fix: we can remove setting the inputStyle height once a line height is
              // set for Input
            inputStyle={{ height: 40 }}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
        </Flex>

        <Box ml="36px">
          <TextField
            isMultiLine
            hasDynamicHeight
            hideLabel
            disabled={isLinkedSection || isSectionObsolete}
            minHeight={38}
            maxHeight={200}
            fontSize={1}
            placeholder={t('general.descriptionOptional')}
            name="description"
          />
        </Box>
      </Flex>
    </PanelPadding>
  );
};

const CollapsedEditSectionConfig = () => {
  const { settings, sectionById } = rfx.useStructure<Draft>();
  const [{ value: weight }] = useField({ name: 'section.weight' });
  const [{ value: formLotIds }] = useField<string[] | null>('section.lotIds');
  const { relativeSectionWeightById } = rfx.useEvaluationWeights();
  const pageSections = rfx.usePageSections() as unknown[] as EvaluationSection[];
  const section = rfx.useSectionWithPosition<EvaluationSection>();
  const { rowData: exchangeDefs } = useEditableGridData<EvaluationCriterionExchangeDefinition>();
  const nonObsoleteExchangeDefs = reject(exchangeDefs, 'isObsolete');
  const areSectionExchangeDefsObsolete = exchangeDefs.length >= 1 && nonObsoleteExchangeDefs.length === 0;

  const otherSectionsTotalWeight = useMemo(() => {
    const nonObsoleteSectionIds = Object.keys(relativeSectionWeightById);
    const otherSections = pageSections.filter(pageSection => (
      pageSection._id !== section._id &&
      nonObsoleteSectionIds.includes(pageSection._id)
    ));

    return sumBy(otherSections, section => section.weight || 0);
  }, [pageSections, section, relativeSectionWeightById]);

  const lotIds = !settings.areLotsEnabled ? (
    null
  ) : isLinkedEvaluationSection(section) ? (
    sectionById[section.linkedSectionId]?.lotIds
  ) : (
    formLotIds
  );
  const lotSelectItems = useLotSelectItems({ isEvaluation: true });
  const selectedLot = settings.areLotsEnabled
    ? find(lotSelectItems, { value: first(lotIds) || NO_LOT_PROXY_ID })
    : null;

  return (
    <EvaluationSectionConfigIndicators
      criterionCount={nonObsoleteExchangeDefs.length}
      relativeSectionWeight={areSectionExchangeDefsObsolete ? NaN : weight / (otherSectionsTotalWeight + weight)}
      // @ts-expect-error ts(2322) FIXME: Type 'LotSelectItem | null | undefined' is not assignable to type 'LotSelectItem | undefined'.
      selectedLot={selectedLot}
    />
  );
};

const ExpandedEditSectionConfig = () => {
  const { t } = useTranslation();
  const [{ value: weight }] = useField({ name: 'section.weight' });
  const [{ value: formLotIds }] = useField<string[] | null>('section.lotIds');
  const { settings, sectionById, lotById } = rfx.useStructure<Draft>();
  const { relativeSectionWeightById } = rfx.useEvaluationWeights();
  const locale = useCurrentUserLocale();
  const pageSections = rfx.usePageSections() as unknown[] as EvaluationSection[];
  const section = rfx.useSectionWithPosition<EvaluationSection>();
  const { rowData: exchangeDefs } = useEditableGridData<EvaluationCriterionExchangeDefinition>();
  const nonObsoleteExchangeDefs = reject(exchangeDefs, 'isObsolete');
  const handleLotChange = useHandleGridLotChange();

  const otherSectionsTotalWeight = useMemo(() => {
    const nonObsoleteSectionIds = Object.keys(relativeSectionWeightById);
    const otherSections = pageSections.filter(pageSection => (
      pageSection._id !== section._id &&
      nonObsoleteSectionIds.includes(pageSection._id)
    ));

    return sumBy(otherSections, section => section.weight || 0);
  }, [pageSections, section, relativeSectionWeightById]);

  const isLinkedSection = isLinkedEvaluationSection(section);

  const lotIds = !settings.areLotsEnabled ? (
    null
  ) : isLinkedSection ? (
    sectionById[section.linkedSectionId]?.lotIds
  ) : (
    formLotIds
  );

  const areSectionExchangeDefsObsolete = (exchangeDefs.length >= 1 && nonObsoleteExchangeDefs.length === 0);

  // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const isSectionObsolete = first(lotIds) && lotById[first(lotIds)]?.isObsolete;

  return (
    <DraftSectionLabelConfigProvider>
      {settings.areLotsEnabled && (
        <>
          <PanelPadding>
            <SectionConfigLotSelectField
              name="section.lotIds"
              disabled={isLinkedSection}
              staticValue={isLinkedSection ? first(sectionById[section.linkedSectionId]?.lotIds) : null}
              isEvaluation
              isLinkedSection={isLinkedSection}
              onChange={handleLotChange}
              canSelectObsoleteItem={section.isLive}
            />
          </PanelPadding>
          {!isSectionObsolete && (
            <PanelDivider />
          )}
        </>
      )}
      {!isSectionObsolete && (
        <PanelPadding>
          <TextField
            required
            name="section.weight"
            label={t('request.evaluation.sectionWeight')}
            description={t('request.evaluation.sectionWeightDescription')}
            format="integer.positive"
            // quick fix: we can remove setting the inputStyle height once a line height is
            // set for Input
            inputStyle={{ textAlign: 'right', height: 40, maxWidth: 150 }}
            additionalContent={(
              <Flex ml={3} alignItems="center">
                {localeFormatFactorAsPercent(
                  areSectionExchangeDefsObsolete ? NaN : weight / (otherSectionsTotalWeight + weight),
                  { locale },
                )}
              </Flex>
            )}
          />
        </PanelPadding>
      )}
    </DraftSectionLabelConfigProvider>
  );
};

const EditSectionConfig = () => {
  return (
    <ExpandablePanelSubSection
      heading={<SectionConfigHeading />}
      renderCollapsedContent={() => <CollapsedEditSectionConfig />}
      renderExpandedContent={() => <ExpandedEditSectionConfig />}
    />
  );
};

const EditPanelFooter = () => {
  const {
    isSubmitting,
    isValid,
    dirty: isFormikDataDirty,
  } = useFormikContext();
  const { stopEditing } = rfx.useActions();
  const {
    isDirty: isGridDataDirty,
    editedCell,
  } = useEditableGridData<EvaluationCriterionExchangeDefinition>();

  const isDirty = isGridDataDirty || isFormikDataDirty;
  const isEditingCell = Boolean(editedCell);

  return (
    <PanelPadding>
      <Flex justifyContent="flex-end">
        <CancelButton onClick={stopEditing} mr={2} />
        <SaveButton disabled={isEditingCell || isSubmitting || !isDirty || !isValid} />
      </Flex>
    </PanelPadding>
  );
};

const EvaluationSectionEditPanelContent = () => {
  const { t } = useTranslation();

  const { stopEditing } = rfx.useActions();
  const { sectionById, exchangeDefById } = rfx.useStructure<Draft>();
  const sections = rfx.useSections();
  const section = rfx.useSectionWithPosition<RfxEvaluationSection>();
  const linkedSection = draft.useLinkedSection();
  const initialExchangeDefs = rfx.useSectionExchangeDefs();
  const scoringType = rfx.useEvaluationScoringType();
  const isLinkedSection = isLinkedEvaluationSection(section);
  const {
    rowData: exchangeDefs,
    appendRows,
  } = useEditableGridData<EvaluationCriterionExchangeDefinition>();
  const [saveSection] = draft.useSaveEvaluationSection();
  const onKeyDown = usePreventEnterKeyHandler();
  const { maxExchangeDefCount } = useModelSizeLimits();
  const modelSizeLimitModal = useModalState();
  const [modelSizeLimitMessages, setModelSizeLimitMessages] = useState<ModelSizeLimitMessages | null>(null);

  const unavailableSectionNames = useMemo(
    () => map(reject(sections, { _id: section._id }), 'name'),
    [sections, section],
  );

  const handleSubmit = async (
    { section, name, description }: { section: RfxEvaluationSection; name: string; description?: string },
    { setSubmitting },
  ) => {
    const totalExchangeDefCount = getSizeRelevantExchangeDefCount(exchangeDefById);

    const previousSectionExchangeDefCount = getSizeRelevantExchangeDefCount(initialExchangeDefs);
    const newSectionExchangeDefCount = getSizeRelevantExchangeDefCount(exchangeDefs);
    const addedCount = newSectionExchangeDefCount - previousSectionExchangeDefCount;
    const factor = isSectionLinkedFromEvaluation(section, sectionById) ? 2 : 1;

    if (
      newSectionExchangeDefCount > previousSectionExchangeDefCount &&
      totalExchangeDefCount + (addedCount * factor) > maxExchangeDefCount
    ) {
      setModelSizeLimitMessages({
        heading: t('request.dialog.requestSizeLimit.heading'),
        title: t('request.dialog.requestSizeLimit.saveEvaluationSection.title'),
        warning: t('request.dialog.requestSizeLimit.saveEvaluationSection.warning'),
        body: t('request.dialog.requestSizeLimit.saveEvaluationSection.body', {
          count: totalExchangeDefCount + (addedCount * factor) - maxExchangeDefCount,
        }),
      });
      modelSizeLimitModal.open();
      setSubmitting(false);
    } else {
      const updatedSection = {
        ...section,
        name,
      };

      if (!isLinkedSection) {
        updatedSection.description = description;
      }

      await saveSection({
        section: updatedSection,
        exchangeDefs,
      }, {
        onSuccess: stopEditing,
        onError: () => setSubmitting(false),
      });
    }
  };

  return (
    <>
      <Formik<{ section: RfxEvaluationSection; name: string; description?: string; }>
        validateOnBlur
        enableReinitialize
        initialValues={{
          section,
          // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
          name: isLinkedSection ? linkedSection?.name : section.name,
          description: isLinkedSection ? linkedSection?.description : section.description,
        }}
        validationSchema={
          yup.object().shape({
            section: yup.object().shape({
              weight: yup.number().min(1, t('errors.min1_short')),
            }),
            name: yup.string().test(
              'uniqueName',
              t('request.errors.duplicateSectionName'),
              value => !unavailableSectionNames.find(name =>
                name.toLowerCase().trim() === value?.toLowerCase().trim(),
              ),
            ),
            description: yup.string().nullable(),
          })
        }
        onSubmit={handleSubmit}
      >
        {({ values }) => {
          const isSectionObsolete = values.section?.isObsolete || (
            isLinkedSection && sectionById[section.linkedSectionId]?.isObsolete
          );

          return (
            <Form style={{ width: '100%' }} onKeyDown={onKeyDown}>
              <DraftPanel panelId={section._id}>
                <EditSectionPanelHeader icon="balance-scale" />
                <PanelDivider />
                {isSectionObsolete && (
                  <>
                    <MessageBlock variant="info" m="20px">
                      {t('request.sections.obsoleteSectionInfo')}
                    </MessageBlock>
                    <PanelDivider />
                  </>
                )}
                <EditSectionConfig />
                <PanelDivider />
                {!isSectionObsolete && !isLinkedSection && (
                  <>
                    <PanelSubHeader height="52px">
                      <AddCriterionButton
                        type="button"
                        onClick={() => {
                          const evaluationCriterion = createEvaluationCriterion(scoringType);

                          appendRows([evaluationCriterion]);
                        }}
                      />
                    </PanelSubHeader>
                    <PanelDivider />
                  </>
                )}
                <PanelPadding>
                  <EvaluationExchangeDefsGrid
                    viewportHeightDelta={400}
                    isReadOnly={isSectionObsolete}
                  />
                </PanelPadding>
                <PanelDivider />
                <EditPanelFooter />
              </DraftPanel>
              <LeavePageModal />
            </Form>
          );
        }}
      </Formik>
      <ModelSizeLimitDialog
        modal={modelSizeLimitModal}
        messages={modelSizeLimitMessages}
      />
    </>
  );
};

export const EvaluationSectionEditPanel = () => {
  const exchangeDefs = rfx.useSectionExchangeDefs() as EvaluationCriterionExchangeDefinition[];

  return (
    <ExchangeDefFieldValueProvider>
      <EditableGridDataProvider
        rowData={exchangeDefs}
        setValueInRow={setExchangeDefFieldValue}
      >
        <GlobalDatePickerStyles />
        <GridIdPrefixProvider>
          <GridMenuStateProvider>
            <EvaluationSectionEditPanelContent />
          </GridMenuStateProvider>
        </GridIdPrefixProvider>
      </EditableGridDataProvider>
    </ExchangeDefFieldValueProvider>
  );
};
