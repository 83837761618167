import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Modal, ModalHeader, ModalFooter, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';

import { useApi } from '../../api';
import { useToaster } from '../../toast';
import { useMutation } from '../../useMutation';
import { emailStringToArray } from '../../utils';
import { IntegrationDataType } from '../../types';
import { AddOrEditRecordModalBody, RecordModalProps, getInternalCompanyIds, getOtherMappedRecords, useHandleSubmit } from './supplierMapUtils';

export const AddRecordModal = ({
  systemId,
  companyId,
  externalSupplierData,
  allowManyToOneMapping,
  onCancel,
  onSave,
  ...props
}: RecordModalProps) => {
  const { t } = useTranslation('integration');
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const api = useApi();

  const [addOrUpdateSupplierMapRecord] = useMutation(
    api.addOrUpdateSupplierMapRecord,
    {
      onSuccess: callAll(
        onSave,
        () => toaster.success(t('toaster.recordAddedSuccess')),
      ),
      onError: () => toaster.error(t('toaster.recordAddedError')),
      onSettled: () => queryClient.invalidateQueries(['integrationData', {
        companyId,
        type: IntegrationDataType.EXTERNAL_COMPANY_TO_INTERNAL_COMPANY,
      }]),
    },
  );
  const internalCompanyIds = getInternalCompanyIds(externalSupplierData);

  const handleSubmit = useHandleSubmit({
    addOrUpdateSupplierMapRecord,
    companyId,
    systemId,
    internalCompanyIds,
    allowManyToOneMapping,
  });

  return (
    <Modal style={{ content: { width: '500px' } }} {...props}>
      <Formik
        initialValues={{
          externalSupplierId: '',
          externalSupplierName: '',
          externalSupplierEmails: '',
          companyInput: '',
          company: null,
        }}
        validationSchema={
          yup.object().shape({
            externalSupplierId: yup
              .string()
              .notOneOf(externalSupplierData?.map(supplierData => supplierData.externalCompanyId) || [], t('errors.externalSupplierIdError'))
              .required(t('general.required', { ns: 'translation' })),
            externalSupplierName: yup
              .string(),
            externalSupplierEmails: yup
              .string()
              .test(
                'emailsFormat',
                t('errors.invalidEmail', { ns: 'translation' }),
                value => emailStringToArray(value)
                  .every(item => yup.string().email().isValidSync(item)),
              ),
          })
        }
        onSubmit={async (values, { setErrors }) => handleSubmit(values, setErrors)}
      >
        {({ isSubmitting, dirty, isValid, values }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              {t('addNewRecord')}
            </ModalHeader>
            <AddOrEditRecordModalBody
              company={values.company}
              internalCompanyIds={internalCompanyIds}
              allowManyToOneMapping={allowManyToOneMapping}
              otherMappedRecords={getOtherMappedRecords(externalSupplierData, values.company)}
            />
            <ModalFooter>
              <CancelButton onClick={onCancel} />
              <Button type="submit" disabled={isSubmitting || !dirty || !isValid}>
                {t('addRecord')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
