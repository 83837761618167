import { Draft, SectionType } from '@deepstream/common/rfq-utils';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { Form, Formik, useFormikContext } from 'formik';
import { isEmpty, noop, sortBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Text, TextProps } from 'rebass/styled-components';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MultiStageLineItemsEventType } from '@deepstream/common';
import { scrollToTop } from '@deepstream/ui-utils/scrollToTop';
import { z } from 'zod';
import { CheckboxFieldArray } from '../../../../form/CheckboxField';
import * as rfx from '../../../../rfx';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { useTrackSetupMultiStageResponsesEvent } from '../tracking';
import { Direction } from '../../../../ui/MultiStepFlow/types';
import { useMultiStepFlowData } from '../../../../ui/MultiStepFlow/MultiStepFlowContext';
import { SetupMultiStageResponsesFlowData, SetupMultiStageResponsesFlowStepType } from '../types';
import { StepNavigationBackLink } from '../../../../ui/MultiStepFlow/StepNavigation';

const CheckboxLabel = (props: TextProps) => {
  return (
    <Text color="text" fontWeight="500" fontSize={18} ml="12px" {...props} />
  );
};

const ContinueButton = () => {
  const { t } = useTranslation();
  const formikContext = useFormikContext<{ sectionIds: string[] }>();

  const { submitAndNavigate } = useMultiStepFlowData<
    SetupMultiStageResponsesFlowStepType,
    SetupMultiStageResponsesFlowData
  >();

  const trackFlowEvent = useTrackSetupMultiStageResponsesEvent({
    eventType: MultiStageLineItemsEventType.SELECT_SECTIONS_STEP_COMPLETED,
  });

  const handleSubmit = useCallback(async () => {
    const { values, validateForm, submitForm } = formikContext;
    const { sectionIds } = values;

    const errors = await validateForm();

    await submitForm();

    if (isEmpty(errors)) {
      trackFlowEvent();
      submitAndNavigate({ selectedSectionIds: sectionIds }, Direction.FORWARD);
    } else {
      scrollToTop('smooth');
    }
  }, [formikContext, trackFlowEvent, submitAndNavigate]);

  return (
    <Box>
      <Button sx={{ alignSelf: 'flex-start' }} onClick={handleSubmit}>
        {t('continue', { ns: 'general' })}
      </Button>
    </Box>
  );
};

export const SelectSections = () => {
  const { t } = useTranslation('translation');

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      sectionIds: z.array(z.string()).min(1, { message: t('request.setupMultiStageResponsesFlow.steps.selectSections.errors.atLeastOneItemSection') }),
    });

    return toFormikValidationSchema(Schema);
  }, [t]);

  const structure = rfx.useStructure<Draft>();

  const availableSections = useMemo(() => {
    const sections = Object.values(structure.sectionById);

    return sortBy(sections
      .filter((section) => section.type === SectionType.LINE_ITEMS)
      .map((section) => ({
        value: section._id,
        label: section.name || t('general.untitled'),
      })), (item) => item.label.toLowerCase());
  }, [structure, t]);

  const { submitAndNavigate } = useMultiStepFlowData<
    SetupMultiStageResponsesFlowStepType,
    SetupMultiStageResponsesFlowData
  >({ required: true });

  const onBackClick = useCallback(
    () => submitAndNavigate(null, Direction.BACK),
    [submitAndNavigate],
  );

  return (
    <Formik
      validateOnBlur
      initialValues={{
        sectionIds: [],
      }}
      validationSchema={validationSchema}
      onSubmit={noop}
    >
      {() => (
        <Form>
          <lotPagesLayout.ContentWrapper2>
            <StepNavigationBackLink onClick={onBackClick} />
            <FormErrors />
            <lotPagesLayout.Section2
              heading={t('request.setupMultiStageResponsesFlow.steps.selectSections.heading',
              )}
            >
              <lotPagesLayout.Subsection2
                heading={t('request.setupMultiStageResponsesFlow.steps.guidelines.things.heading')}
                mt="16px"
              >
                <lotPagesLayout.Ul style={{ marginTop: '12px' }}>
                  <Trans
                    i18nKey="request.setupMultiStageResponsesFlow.steps.selectSections.things.body"
                    ns="translation"
                    components={{
                      li: <li />,
                    }}
                  />
                </lotPagesLayout.Ul>
              </lotPagesLayout.Subsection2>
              <lotPagesLayout.Subsection2
                heading={t('request.setupMultiStageResponsesFlow.steps.selectSections.action.heading')}
                mt="16px"
              >
                <Text mb="20px">
                  {t('request.setupMultiStageResponsesFlow.steps.selectSections.action.description')}
                </Text>
                <Box sx={{ paddingLeft: '3px' }} >
                  <CheckboxFieldArray
                    name="sectionIds"
                    flexDirection="column"
                    options={availableSections}
                    CheckboxLabel={CheckboxLabel}
                    checkboxStyle={{ padding: '12px 0px' }}
                    withSeparator
                  />
                </Box>
              </lotPagesLayout.Subsection2>
            </lotPagesLayout.Section2>
            <ContinueButton />
          </lotPagesLayout.ContentWrapper2>
        </Form>
      )}
    </Formik>
  );
};
