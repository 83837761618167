import { useMemo, useCallback, useEffect } from 'react';

import { TabPanels, TabPanel, Tabs } from '../../ui/TabsVertical';
import { SidebarLayout } from '../../page-helpers';
import { useSystemFeatureFlags } from '../../systemFeatureFlags';
import { QuestionnairesPageSidebar } from './QuestionnairesPageSidebar';
import { ComingSoonOverlay } from './ComingSoonOverlay';
import { MockedSidebar } from './MockedSidebar';
import { MockedMainContent } from './MockedMainContent';
import { ReceivedQuestionnaires } from './Questionnaire/ReceivedQuestionnaires';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useCurrentUser } from '../../useCurrentUser';
import { useLocalStorageState } from '../../useLocalStorageState';
import { useUserFlags } from '../../UserFlagsContext';
import { preQualificationQuestionnairesRoute, usePreQualNavigation } from '../../AppRouting';
import { ActiveQuestionnaireTemplates } from './QuestionnaireTemplate/ActiveQuestionnaireTemplates';
import { DraftQuestionnaireTemplates } from './QuestionnaireTemplate/DraftQuestionnaireTemplates';
import { PreQualTab, QUESTIONNAIRE_TABS } from './utils';

export const PreQualificationQuestionnairesPage = () => {
  const systemFeatureFlags = useSystemFeatureFlags();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const { belongsToPayingCompany } = useUserFlags();
  const { tab }: { tab: PreQualTab } = preQualificationQuestionnairesRoute.useSearch();
  const { navigateToPreQualificationQuestionnaires } = usePreQualNavigation();

  const [lastActiveTab, setLastActiveTab] = useLocalStorageState<string>({
    key: `${currentCompanyId}.${currentUser._id}.preQualificationQuestionnairesPage.lastActiveTab`,
    defaultValue: belongsToPayingCompany ? PreQualTab.ACTIVE_QUESTIONNAIRES : PreQualTab.RECEIVED_QUESTIONNAIRES,
  });

  const tabs = useMemo(
    () => belongsToPayingCompany ? QUESTIONNAIRE_TABS : [PreQualTab.RECEIVED_QUESTIONNAIRES],
    [belongsToPayingCompany],
  );

  const isValidTab = useCallback(tab => tabs.includes(tab), [tabs]);

  useEffect(
    () => {
      if (isValidTab(tab) && tab !== lastActiveTab) {
        setLastActiveTab(tab);
      }
    },
    [tab, lastActiveTab, setLastActiveTab, isValidTab],
  );

  useEffect(
    () => {
      if (!isValidTab(tab)) {
        navigateToPreQualificationQuestionnaires(isValidTab(lastActiveTab) ? lastActiveTab : tabs[0], true);
      }
    },
    [lastActiveTab, tab, navigateToPreQualificationQuestionnaires, isValidTab, tabs],
  );

  const onTabsChange = useCallback(
    (index) => {
      navigateToPreQualificationQuestionnaires(tabs[index]);
    },
    [navigateToPreQualificationQuestionnaires, tabs],
  );

  const selectedTabIndex = tabs.indexOf(tab as PreQualTab);

  // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'.
  if (!systemFeatureFlags.newPreQualEnabled) {
    return (
      <>
        <Tabs>
          <SidebarLayout sidebar={<MockedSidebar />}>
            <MockedMainContent />
          </SidebarLayout>
        </Tabs>
        <ComingSoonOverlay />
      </>
    );
  }

  return (
    <Tabs index={selectedTabIndex} onChange={onTabsChange}>
      <SidebarLayout sidebar={<QuestionnairesPageSidebar />}>
        <TabPanels>
          {belongsToPayingCompany && (
            <>
              <TabPanel key={PreQualTab.ACTIVE_QUESTIONNAIRES}>
                <ActiveQuestionnaireTemplates />
              </TabPanel>
              <TabPanel key={PreQualTab.DRAFT_QUESTIONNAIRES}>
                <DraftQuestionnaireTemplates />
              </TabPanel>
            </>
          )}
          <TabPanel key={PreQualTab.RECEIVED_QUESTIONNAIRES}>
            <ReceivedQuestionnaires />
          </TabPanel>
        </TabPanels>
      </SidebarLayout>
    </Tabs>
  );
};
