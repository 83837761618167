import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { useNavigate } from '@tanstack/react-router';
import { callAll } from '@deepstream/utils/callAll';
import { IconTextButton } from '@deepstream/ui-kit/elements/button/IconTextButton';
import { EllipsisMenu, EditMenuItem, DeleteMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useModalState } from '../../ui/useModalState';
import { contractDraftSummaryRoute, contractTemplateSummaryRoute, contractsRoute } from '../../AppRouting';
import { DeleteContractTemplateDialog } from './DeleteContractTemplateDialog';
import { useApi, wrap } from '../../api';
import { useMutation } from '../../useMutation';
import { useToaster } from '../../toast';

export const ContractTemplatePreviewControls = ({
  templateId,
}: {
  templateId: string;
}) => {
  const { t } = useTranslation(['translation', 'contracts']);
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const deleteTemplateModal = useModalState();
  const navigate = useNavigate();
  const toaster = useToaster();

  const navigateToTemplates = () => {
    navigate({
      to: contractsRoute.to,
      params: { currentCompanyId },
      search: { tab: 'templates' },
    });
  };

  const navigateToEditTemplate = () => {
    navigate({
      to: contractTemplateSummaryRoute.to,
      params: { currentCompanyId, contractId: templateId },
    });
  };

  const { data: template } = useQuery(
    ['contractTemplate', { currentCompanyId, templateId }],
    wrap(api.getContractTemplate),
  );

  const [createContractFromTemplate, { isLoading }] = useMutation(
    api.createContractFromTemplate,
    {
      onSuccess: callAll(
        contract => {
          navigate({
            to: contractDraftSummaryRoute.to,
            params: { currentCompanyId, contractId: contract._id },
          });
        },
        () => {
          toaster.success(t(
            'toaster.createContractFromTemplate.success',
            { ns: 'contracts', name: template?.meta.name },
          ));
        },
      ),
      onError: () => toaster.error(t('toaster.createContractFromTemplate.failed', { ns: 'contracts' })),
    },
  );

  return template ? (
    <>
      <Flex width="100%" justifyContent="space-between" alignItems="center" mb={3}>
        <IconTextButton icon="chevron-left" mb="3px" fontSize={1} onClick={navigateToTemplates}>
          {t('request.backToTemplates')}
        </IconTextButton>

        <ButtonGroup marginBetween="1px">
          <Button
            type="button"
            variant="secondary"
            iconLeft="plus"
            disabled={isLoading}
            onClick={() => createContractFromTemplate({
              currentCompanyId,
              templateId,
            })}
          >
            {t('newContract', { ns: 'contracts' })}
          </Button>
          <EllipsisMenu variant="secondary" menuZIndex={202}>
            <EditMenuItem onSelect={navigateToEditTemplate} />
            <DeleteMenuItem
              onSelect={deleteTemplateModal.open}
              disabled={isLoading}
            />
          </EllipsisMenu>
        </ButtonGroup>
      </Flex>
      <DeleteContractTemplateDialog
        {...deleteTemplateModal}
        templateId={template._id}
        templateName={template.meta.name}
        onSuccess={navigateToTemplates}
        onError={deleteTemplateModal.close}
      />
    </>
  ) : (
    null
  );
};
