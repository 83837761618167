import * as React from 'react';
import { filter, find, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Text, Flex, Box, Heading } from 'rebass/styled-components';
import styled from 'styled-components';
import { RevisionChange, RevisionChangeGroup, RevisionChangeType } from '@deepstream/common/rfq-utils';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { ModalBody, ModalFooter, Modal, ModalHeader, ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { DateFormat } from '@deepstream/utils';
import { useApi, wrap } from '../../../api';
import { Datetime2 } from '../../../Datetime';
import { ErrorMessage } from '../../../ui/ErrorMessage';
import { Loading } from '../../../ui/Loading';
import { useRfqId } from '../../../useRfq';
import { PreWrap } from '../../../PreWrapCell';
import { AuctionDuration, AuctionStart, AutoExtension, BidFeedback, CeilingPrice, ExampleFeedback,
  MinimumReduction, PreBids, TieBids } from '../Live/AuctionOverviewContent';
import { Table } from '../../../Table';
import { CompactTableStyles } from '../../../TableStyles';
import { TruncateCell } from '../../../TruncateCell';
import { nestCells } from '../../../nestCells';
import { ExchangeDefDescriptionCell, ObsoleteCell } from '../../../draft/cell';
import { useCurrentCompanyId } from '../../../currentCompanyId';

const RevisionChangesContext = React.createContext<RevisionChange[]>([]);

const RevisionChangesProvider = ({
  revisionChanges,
  ...props
}: {
  revisionChanges: RevisionChange[];
  children: React.ReactNode;
}) => (
  <RevisionChangesContext.Provider value={revisionChanges} {...props} />
);

const useRevisionChanges = () => {
  const revisionChanges = React.useContext(RevisionChangesContext);

  if (!revisionChanges) {
    throw new Error('Cannot find a RevisionChanges provider');
  }

  return revisionChanges;
};

const List = styled.ul`
  padding-left: 1em;
  margin-bottom: 0;

  > * + * {
    margin-top: 4px;
  }
`;

const PreviousValueColumn = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <Box width="50%" mr={3}>
      <Text color="subtext" fontSize={1} mb={2} sx={{ textTransform: 'uppercase', letterSpacing: '1px' }}>
        {t('request.auction.revisionChanges.previousValue')}
      </Text>
      {children}
    </Box>
  );
};

const UpdatedValueColumn = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <Box width="50%">
      <Text color="subtext" fontSize={1} mb={2} sx={{ textTransform: 'uppercase', letterSpacing: '1px' }}>
        {t('request.auction.revisionChanges.updatedValue')}
      </Text>
      {children}
    </Box>
  );
};

const Section = ({ title, children }: { title: string; children: React.ReactNode }) => (
  <Box
    pt={3}
    mt={3}
    sx={{
      borderTop: 'lightGray',
    }}
  >
    <Heading as="h3" fontSize={2} mb={3}>
      {title}
    </Heading>
    <Flex>
      {children}
    </Flex>
  </Box>
);

const ScheduleChanges = () => {
  const { t } = useTranslation();
  const changes = useRevisionChanges();

  const scheduleChanges = filter(
    changes,
    { group: RevisionChangeGroup.AUCTION_SCHEDULE },
  );

  return !isEmpty(scheduleChanges) ? (
    <Section title={t('request.auction.revisionChanges.schedule')}>
      <PreviousValueColumn>
        <List>
          {scheduleChanges.map(change => (
            <li key={change.type}>
              {change.type === RevisionChangeType.AUCTION_START_DATE ? (
                <AuctionStart date={change.previousValue} />
              ) : change.type === RevisionChangeType.AUCTION_LOT_DURATION ? (
                <AuctionDuration duration={change.previousValue} />
              ) : (
                null
              )}
            </li>
          ))}
        </List>
      </PreviousValueColumn>
      <UpdatedValueColumn>
        <List>
          {scheduleChanges.map(change => (
            <li key={change.type}>
              {change.type === RevisionChangeType.AUCTION_START_DATE ? (
                <AuctionStart date={change.updatedValue} />
              ) : change.type === RevisionChangeType.AUCTION_LOT_DURATION ? (
                <AuctionDuration duration={change.updatedValue} />
              ) : (
                null
              )}
            </li>
          ))}
        </List>
      </UpdatedValueColumn>
    </Section>
  ) : (
    null
  );
};

const AwardingPrinciplesChanges = () => {
  const { t } = useTranslation();
  const changes = useRevisionChanges();

  const awardingPrinciplesChange = find(
    changes,
    { type: RevisionChangeType.AUCTION_INFORMATION },
  );

  return awardingPrinciplesChange ? (
    <Section title={t('request.auction.sectionTitle.awardingPrinciples')}>
      <PreviousValueColumn>
        <PreWrap>
          {awardingPrinciplesChange.previousValue}
        </PreWrap>
      </PreviousValueColumn>
      <UpdatedValueColumn>
        <PreWrap>
          {awardingPrinciplesChange.updatedValue}
        </PreWrap>
      </UpdatedValueColumn>
    </Section>
  ) : (
    null
  );
};

const BidderAgreementChanges = () => {
  const { t } = useTranslation();
  const changes = useRevisionChanges();

  const bidderAgreementChange = find(
    changes,
    { type: RevisionChangeType.AUCTION_TERMS },
  );

  return bidderAgreementChange ? (
    <Section title={t('request.auction.sectionTitle.bidderAgreement')}>
      <PreviousValueColumn>
        <PreWrap>
          {bidderAgreementChange.previousValue}
        </PreWrap>
      </PreviousValueColumn>
      <UpdatedValueColumn>
        <PreWrap>
          {bidderAgreementChange.updatedValue}
        </PreWrap>
      </UpdatedValueColumn>
    </Section>
  ) : (
    null
  );
};

const CurrencyChanges = () => {
  const { t } = useTranslation();
  const changes = useRevisionChanges();

  const currencyChange = find(
    changes,
    { type: RevisionChangeType.AUCTION_LOT_CURRENCY },
  );

  return currencyChange ? (
    <Section title={t('general.currency')}>
      <PreviousValueColumn>
        {currencyChange.previousValue}
      </PreviousValueColumn>
      <UpdatedValueColumn>
        {currencyChange.updatedValue}
      </UpdatedValueColumn>
    </Section>
  ) : (
    null
  );
};

const DecimalPlacesChanges = () => {
  const { t } = useTranslation();
  const changes = useRevisionChanges();

  const decimalPlacesChange = find(
    changes,
    { type: RevisionChangeType.AUCTION_LOT_DECIMAL_PLACES },
  );

  return decimalPlacesChange ? (
    <Section title={t('general.decimalPlaces')}>
      <PreviousValueColumn>
        {decimalPlacesChange.previousValue}
      </PreviousValueColumn>
      <UpdatedValueColumn>
        {decimalPlacesChange.updatedValue}
      </UpdatedValueColumn>
    </Section>
  ) : (
    null
  );
};

const BidFeedbackChanges = () => {
  const { t } = useTranslation();
  const changes = useRevisionChanges();

  const bidFeedbackChange = find(
    changes,
    { type: RevisionChangeType.AUCTION_LOT_BID_FEEDBACK },
  );

  return bidFeedbackChange ? (
    <Section title={t('request.auction.sectionTitle.bidFeedback')}>
      <PreviousValueColumn>
        <List>
          <li><BidFeedback bidFeedback={bidFeedbackChange.previousValue} /></li>
          <li><ExampleFeedback bidFeedback={bidFeedbackChange.previousValue} /></li>
        </List>
      </PreviousValueColumn>
      <UpdatedValueColumn>
        <List>
          <li><BidFeedback bidFeedback={bidFeedbackChange.updatedValue} /></li>
          <li><ExampleFeedback bidFeedback={bidFeedbackChange.updatedValue} /></li>
        </List>
      </UpdatedValueColumn>
    </Section>
  ) : (
    null
  );
};

const BidRulesChanges = () => {
  const { t } = useTranslation();
  const changes = useRevisionChanges();

  const bidRulesChanges = filter(
    changes,
    { group: RevisionChangeGroup.AUCTION_BID_RULES },
  );

  return !isEmpty(bidRulesChanges) ? (
    <Section title={t('request.auction.sectionTitle.bidRules')}>
      <PreviousValueColumn>
        <List>
          {bidRulesChanges.map(change => (
            <li key={change.type}>
              {change.type === RevisionChangeType.AUCTION_LOT_MINIMUM_REDUCTION ? (
                <MinimumReduction minimumReduction={change.previousValue} />
              ) : change.type === RevisionChangeType.AUCTION_LOT_TIE_BIDS ? (
                <TieBids enabled={change.previousValue} />
              ) : change.type === RevisionChangeType.AUCTION_LOT_CEILING_PRICE ? (
                <CeilingPrice ceilingPrice={change.previousValue} />
              ) : (
                null
              )}
            </li>
          ))}
        </List>
      </PreviousValueColumn>
      <UpdatedValueColumn>
        <List>
          {bidRulesChanges.map(change => (
            <li key={change.type}>
              {change.type === RevisionChangeType.AUCTION_LOT_MINIMUM_REDUCTION ? (
                <MinimumReduction minimumReduction={change.updatedValue} />
              ) : change.type === RevisionChangeType.AUCTION_LOT_TIE_BIDS ? (
                <TieBids enabled={change.updatedValue} />
              ) : change.type === RevisionChangeType.AUCTION_LOT_CEILING_PRICE ? (
                <CeilingPrice ceilingPrice={change.updatedValue} />
              ) : (
                null
              )}
            </li>
          ))}
        </List>
      </UpdatedValueColumn>
    </Section>
  ) : (
    null
  );
};

const TimingRulesChanges = () => {
  const { t } = useTranslation();
  const changes = useRevisionChanges();

  const timingRulesChanges = filter(
    changes,
    { group: RevisionChangeGroup.AUCTION_TIMING_RULES },
  );

  return !isEmpty(timingRulesChanges) ? (
    <Section title={t('request.auction.sectionTitle.timingRules')}>
      <PreviousValueColumn>
        <List>
          {timingRulesChanges.map(change => (
            <li key={change.type}>
              {change.type === RevisionChangeType.AUCTION_LOT_AUTO_EXTENSION ? (
                <AutoExtension {...change.previousValue} />
              ) : change.type === RevisionChangeType.AUCTION_LOT_PRE_BIDS ? (
                <PreBids enabled={change.previousValue} />
              ) : (
                null
              )}
            </li>
          ))}
        </List>
      </PreviousValueColumn>
      <UpdatedValueColumn>
        <List>
          {timingRulesChanges.map(change => (
            <li key={change.type}>
              {change.type === RevisionChangeType.AUCTION_LOT_AUTO_EXTENSION ? (
                <AutoExtension {...change.updatedValue} />
              ) : change.type === RevisionChangeType.AUCTION_LOT_PRE_BIDS ? (
                <PreBids enabled={change.updatedValue} />
              ) : (
                null
              )}
            </li>
          ))}
        </List>
      </UpdatedValueColumn>
    </Section>
  ) : (
    null
  );
};

const LineItemsChanges = () => {
  const { t } = useTranslation();
  const changes = useRevisionChanges();

  const lineItemsChange = find(
    changes,
    { type: RevisionChangeType.AUCTION_LOT_LINE_ITEMS },
  );

  const columns = React.useMemo(
    () => [
      {
        id: 'description',
        accessor: 'description',
        Cell: nestCells(ObsoleteCell, ExchangeDefDescriptionCell),
      },
      {
        id: 'unit',
        accessor: 'unit',
        width: 100,
        Cell: nestCells(ObsoleteCell, TruncateCell),
      },
      {
        id: 'quantity',
        accessor: 'quantity',
        width: 100,
        Cell: nestCells(ObsoleteCell, TruncateCell),
      },
    ],
    [],
  );

  return lineItemsChange ? (
    <Section title={t('request.auction.sectionTitle.lineItems')}>
      <PreviousValueColumn>
        <Box sx={{ borderBottom: 'lightGray2' }}>
          <CompactTableStyles>
            <Table
              columns={columns}
              data={lineItemsChange.previousValue}
              hideHeader
            />
          </CompactTableStyles>
        </Box>
      </PreviousValueColumn>
      <UpdatedValueColumn>
        <Box sx={{ borderBottom: 'lightGray2' }}>
          <CompactTableStyles>
            <Table
              columns={columns}
              data={lineItemsChange.updatedValue}
              hideHeader
            />
          </CompactTableStyles>
        </Box>
      </UpdatedValueColumn>
    </Section>
  ) : (
    null
  );
};

const RevisionChangesContent = ({
  date,
}: {
  date: string | number | Date;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <MessageBlock variant="info" mt={0} mb={3}>
        <Text>
          {t('request.auction.revisionChanges.info1')}
          {' '}
          <Datetime2 value={date} format={DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ} />
        </Text>
        <Text>
          {t('request.auction.revisionChanges.info2')}
        </Text>
      </MessageBlock>
      <ScheduleChanges />
      <AwardingPrinciplesChanges />
      <BidderAgreementChanges />
      <CurrencyChanges />
      <DecimalPlacesChanges />
      <LineItemsChanges />
      <BidFeedbackChanges />
      <BidRulesChanges />
      <TimingRulesChanges />
    </>
  );
};

type AuctionRevisionChangesModalProps = ModalProps & {
  revisionDate: string | number | Date;
  onClose: () => void;
};

export const AuctionRevisionChangesModal = ({
  revisionDate,
  onClose,
  ...props
}: AuctionRevisionChangesModalProps) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const rfqId = useRfqId({ required: true });

  const { data: revisionChanges, isLoading, isError } = useQuery(
    ['revisionChanges', { rfqId, revisionDate, currentCompanyId }],
    wrap(api.getRevisionChanges),
  );

  return (
    <Modal style={{ content: { width: '950px' } }} {...props}>
      <ModalHeader onClose={onClose}>
        {t('request.auction.revisionChanges.auctionRevisions')}
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <Loading />
        ) : isError ? (
          <ErrorMessage error={t('request.auction.revisionChanges.errors.getRevision')} />
        ) : revisionChanges ? (
          <RevisionChangesProvider revisionChanges={revisionChanges}>
            <RevisionChangesContent date={revisionDate} />
          </RevisionChangesProvider>
        ) : (
          null
        )}
      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" onClick={onClose}>
          {t('general.close')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
