import { useMemo } from 'react';
import styled from 'styled-components';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { DateFormatOptions, getDateFnsLocale } from '@deepstream/utils';
import { Popover, usePopover } from '@deepstream/ui-kit/elements/popup/usePopover';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { format } from 'date-fns';
import { TextField } from '../form/TextField';
import { useCurrentUserLocale } from '../useCurrentUser';

const PopoverBackground = styled(Box)`
  background: white;
  border-radius: ${props => props.theme.radii.small};
  width: 100%;
`;

const Ul = styled.ul`
  padding-inline-start: 20px;
`;

interface Props {
  onClose: () => void;
  popover: ReturnType<typeof usePopover>;
  dateFormat: string;
  setDateFormat: (dateFormat: string) => void;
}

const formatBulkUploadDate = (date: number | Date, dateFormat: string, options: DateFormatOptions = {}) => {
  const dateFnsLocale = getDateFnsLocale(options.locale);

  return format(date, dateFormat, { ...options, locale: dateFnsLocale });
};

export const convertDateFormat = (dateFormat: string) => {
  return dateFormat
    .replace(/m/g, 'M')
    .replace(/Y/g, 'y')
    .replace(/D/g, 'd');
};

const InfoTooltip = () => {
  const { t } = useTranslation();
  const locale = useCurrentUserLocale();

  return (
    <Box>
      <Text py={2}>{t('request.lineItems.bulkUploadModal.adjustDatesFormat')}</Text>

      <Text>{t('request.lineItems.bulkUploadModal.dateFormatExamples')}</Text>
      <Ul>
        <li><Text>dd/mm/yyyy - {formatBulkUploadDate(new Date(), convertDateFormat('dd/mm/yyyy'), { locale })}</Text></li>
        <li><Text>mm/dd/yyyy - {formatBulkUploadDate(new Date(), convertDateFormat('mm/dd/yyyy'), { locale })}</Text></li>
        <li><Text>d mmm yyyy - {formatBulkUploadDate(new Date(), convertDateFormat('d mmm yyyy'), { locale })}</Text></li>
        <li><Text>mmmm d, yyyy - {formatBulkUploadDate(new Date(), convertDateFormat('mmmm d, yyyy'), { locale })}</Text></li>
      </Ul>
    </Box>
  );
};

export const BulkUploadDateFormatPopover = ({ onClose, popover, dateFormat, setDateFormat }: Props) => {
  const { t } = useTranslation();

  const formattedCurrentDate = useMemo(() => {
    try {
      return formatBulkUploadDate(new Date(), convertDateFormat(dateFormat));
    } catch {
      return '';
    }
  }, [dateFormat]);

  return (
    <Popover
      onClickOutside={onClose}
      {...popover}
    >
      <PopoverBackground>
        <Box p={2}>
          <TextField
            required
            name="dateFormat"
            label={t('request.lineItems.bulkUploadModal.csvDateFormat')}
            extendedInfo={() => <Text fontSize={1} color="subtext">{formattedCurrentDate}</Text>}
            value={dateFormat}
            onChange={({ target }) => setDateFormat(target.value)}
            infoTooltip={<InfoTooltip />}
            tooltipIcon="info-circle"
          />
        </Box>
        <PanelDivider />
        <Flex width="100%" p={2} justifyContent="flex-end">
          <Button
            type="button"
            variant="secondary"
            onClick={onClose}
            small
          >
            {t('request.lineItems.bulkUploadModal.dateFormatDone')}
          </Button>
        </Flex>
      </PopoverBackground>
    </Popover>
  );
};
