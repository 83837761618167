import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BidIntentionStatus, BidStatus, getBidIntentionStatus, getBidOutcomeStatus } from '@deepstream/common/rfq-utils';
import { compact } from 'lodash';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import * as rfx from '../../../rfx';
import * as lotPagesLayout from '../Live/lotPagesLayout';
import { Bold } from '../../../Bold';
import { iconPropsByBidIntentionStatus } from '../Live/iconPropsByBidIntentionStatus';
import { iconPropsByRequestInteractivityStatus } from '../Live/iconPropsByRequestInteractivityStatus';
import { iconPropsByBidOutcomeStatus } from '../Live/iconPropsByBidOutcomeStatus';
import { useRequestRecipientNavigation } from '../../../appNavigation';

export const RequestRecipientBidStatusTable = () => {
  const { t } = useTranslation('translation');
  const bid = rfx.useBid();
  const requestInteractivityStatus = rfx.useRequestInteractivityStatus();
  const currentCompanyGroup = rfx.useCurrentCompanyGroup();
  const navigation = useRequestRecipientNavigation();

  const columns = useMemo(() => {
    return [
      {
        id: 'name',
        title: t('request.statusItem.name'),
        width: '20%',
      },
      {
        id: 'status',
        title: t('request.statusItem.status'),
        width: '20%',
      },
      {
        id: 'description',
        title: t('request.statusItem.description'),
      },
    ];
  }, [t]);

  const rows = useMemo(() => {
    const bidIntentionStatus = getBidIntentionStatus(bid);
    const bidOutcomeStatus = getBidOutcomeStatus(bid);

    return compact([
      {
        id: 'requestStatus',
        name: t('request.spendAndSavings.requestStatus'),
        status: (
          <>
            <Icon fixedWidth mr={1} {...iconPropsByRequestInteractivityStatus[requestInteractivityStatus]} />
            <Bold>{t(`request.requestInteractivityStatus.${requestInteractivityStatus}.status`)}</Bold>
          </>
        ),
        description: t(`request.requestInteractivityStatus.${requestInteractivityStatus}.description.${currentCompanyGroup}`),
      },
      {
        id: 'bidIntentionStatus',
        name: t('general.bidStatus'),
        status: (
          <>
            <Icon fixedWidth mr={1} {...iconPropsByBidIntentionStatus[currentCompanyGroup][bidIntentionStatus]} />
            <Bold>{t(`request.bidIntentionStatus.${bidIntentionStatus}.status`)}</Bold>
          </>
        ),
        description: t(`request.bidIntentionStatus.${bidIntentionStatus}.description.${currentCompanyGroup}`),
      },
      bidIntentionStatus === BidIntentionStatus.BIDDING || bid.status === BidStatus.AWARDED
        ? {
          id: 'bidOutcomeStatus',
          name: t('request.bidOutcome'),
          status: (
            <>
              <Icon fixedWidth mr={1} {...iconPropsByBidOutcomeStatus[bidOutcomeStatus]} />
              <Bold>{t(`request.bidOutcomeStatus.${bidOutcomeStatus}.status`)}</Bold>
            </>
          ),
          description: (
            <Trans
              i18nKey={`request.bidOutcomeStatus.${bidOutcomeStatus}.description.${currentCompanyGroup}`}
              ns="translation"
              components={{
                a: (
                  <lotPagesLayout.TanstackInlineLink
                    {...navigation.getAwardSummaryLinkProps()}
                  />
                ),
              }}
            />
          ),
        }
        : null,
    ]);
  }, [t, requestInteractivityStatus, bid, currentCompanyGroup, navigation]);

  return (
    <lotPagesLayout.Table
      columns={columns}
      rows={rows}
    />
  );
};
