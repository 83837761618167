import { useMemo, memo, useState, useCallback } from 'react';
import { Form, Formik } from 'formik';
import {
  ActionType,
  ExchangeStatus,
  ExchangeType,
  getEmptyResponseValue,
  QuestionExchangeDefinition,
  getExchangeFieldValue,
  isBuyerReplyField,
  isSupplierReplyField,
  Action,
  isFieldDisabled,
  ReplyFieldConfig,
} from '@deepstream/common/rfq-utils';
import {
  first,
  identity,
  isEmpty,
  isNil,
  partition,
} from 'lodash';
import { Box, BoxProps, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isDocumentExchangeDefinition } from '@deepstream/common/contract';
import { usePopover } from '@deepstream/ui-kit/elements/popup/usePopover';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { Button, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { ConfirmationPopover } from '@deepstream/ui-kit/elements/popup/ConfirmationPopover';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useCurrentCompanyId } from '../currentCompanyId';
import { LabelConfig, LabelConfigProvider } from '../LabelConfigProvider';
import { useDeviceSize } from '../ui/useDeviceSize';
import { ExchangeHistoryAction, ExchangeSnapshot, LineItemsExchangeSnapshot } from '../types';
import { RadioFieldBase } from '../form/RadioField';
import { useExchange } from '../useExchange';
import { useSendExchangeReplyContext } from './useSendExchangeReply';
import { useCurrentUser } from '../useCurrentUser';
import { getExchangeReplyFormConfig } from './exchangeReplyFormConfig';
import { useLineItemExchangeModalSectionColumns } from '../modules/Exchange/columns';
import { useIsSender } from '../modules/Contracts/contract';

const INITIAL_ACTION_INDEX = -1;

const canOnlySubmitWhenDirty = (exchange: ExchangeSnapshot) => [
  ExchangeType.QUESTION,
  ExchangeType.LINE_ITEM,
  ExchangeType.EVALUATION_CRITERION,
].includes(exchange.def.type);

const ClearResponseDropdown = ({
  onConfirm,
  disabled,
}: {
  onConfirm: () => void;
  disabled: boolean;
}) => {
  const { t } = useTranslation();
  const popover = usePopover({
    placement: 'left-end',
    strategy: 'fixed',
    overReferenceElement: true,
  });

  return (
    <>
      <Button
        // @ts-expect-error ts(2322) FIXME: Type 'Dispatch<SetStateAction<HTMLElement | undefined>>' is not assignable to type 'LegacyRef<HTMLButtonElement> | undefined'.
        ref={popover.setReferenceElement}
        disabled={disabled}
        type="button"
        variant="danger-outline"
        onClick={() => {
          if (popover.update) {
            popover.update();
          }
          popover.toggle();
        }}
      >
        {t('request.exchange.clearResponse')}
      </Button>
      <ConfirmationPopover
        width="100%"
        maxWidth="265px"
        popover={popover}
        heading={t('request.dialog.clearResponse.heading')}
        body={t('request.dialog.clearResponse.body')}
        okButtonText={t('request.exchange.clearResponse')}
        okButtonVariant="danger-outline"
        okButtonDisabled={disabled}
        onOk={async () => {
          onConfirm();
          popover.close();
        }}
        onCancel={popover.close}
      />
    </>
  );
};

// Hack for angular side
const ResetLabelStyle = styled(Box)`
  label {
    font-weight: normal;
    color: ${props => props.theme.colors.text};
  }
`;

type ExchangeActionGroup = {
  type?: ActionType;
  actionLabelTranslationKey: string;
  isActionGroup: boolean;
  disabled?: boolean;
};

const SelectLevel1Action = ({
  exchangeType,
  actions,
  actionIndex,
  setActionIndex,
  mb,
  mt,
  initialValues,
}: Pick<BoxProps, 'mb' | 'mt'> & {
  actions: (ExchangeHistoryAction | ExchangeActionGroup)[];
  actionIndex: number;
  setActionIndex: (index: number) => void;
  exchangeType: ExchangeType,
  initialValues: { points: number | null },
}) => {
  const { t } = useTranslation();
  const { isExtraSmall } = useDeviceSize();
  const isInline = !isExtraSmall;

  // There can be multiple actions with the same `type`, so we need to rely on the index. This is safe
  // because the selected action is reset when `actions` array changes.
  const options: any = useMemo(
    () => actions.map((action, index) => {
      if (
        exchangeType === ExchangeType.EVALUATION_CRITERION &&
        action.type === ActionType.SUBMIT
      ) {
        return {
          value: String(index),
          label: isNil(initialValues.points)
            ? t('request.exchange.actionLabel.evaluation.submit')
            : t('request.exchange.actionLabel.evaluation.update'),
          disabled: action.disabled,
        };
      } else {
        return {
          value: String(index),
          label: t(getActionLabel(action), { ns: 'exchangeActionLabel' }),
          disabled: action.disabled,
        };
      }
    }),
    [actions, exchangeType, initialValues.points, t],
  );

  return (
    <ResetLabelStyle mt={mt} mb={mb} px={3}>
      <RadioFieldBase
        variant={isInline ? 'inline' : 'stacked'}
        label={t('request.exchange.selectAnAction')}
        options={options}
        value={String(actionIndex)}
        onChange={setActionIndex}
      />
    </ResetLabelStyle>
  );
};

const SelectLevel2Action = ({
  actions,
  actionIndex,
  setActionIndex,
}: {
  actions: ExchangeHistoryAction[];
  actionIndex: number;
  setActionIndex: (index: number) => void;
}) => {
  const { t } = useTranslation(['translation', 'general']);

  const options: any = useMemo(
    () => actions.map((action, index) => {
      return {
        value: String(index),
        label: t(getActionLabel(action), { ns: 'exchangeActionLabel' }),
        disabled: action.disabled,
      };
    }),
    [actions, t],
  );

  return (
    <ResetLabelStyle mt="18px" mb={2} px={3}>
      <RadioFieldBase
        variant="stacked"
        label={t('response', { ns: 'general' })}
        options={options}
        value={String(actionIndex)}
        onChange={setActionIndex}
      />
    </ResetLabelStyle>
  );
};

const getActionLabel = (action: { submitLabelTranslationKey?: string; actionLabelTranslationKey?: string }) => {
  if (action.submitLabelTranslationKey) {
    return action.submitLabelTranslationKey;
  }

  if (action.actionLabelTranslationKey) {
    return action.actionLabelTranslationKey;
  }

  return 'common.submit';
};

const SubmitButtonLabelText = ({
  exchangeType,
  action,
  initialValues,
}: {
  exchangeType: ExchangeType,
  action: ExchangeHistoryAction | ExchangeActionGroup,
  initialValues: { points: number | null },
}) => {
  const { t } = useTranslation();

  // TODO use same pattern also for other exchanges, like line items
  const label = (
    exchangeType === ExchangeType.EVALUATION_CRITERION &&
    action.type === ActionType.SUBMIT
  )
    ? isNil(initialValues.points)
      ? t('request.exchange.actionLabel.evaluation.submit')
      : t('request.exchange.actionLabel.evaluation.update')
    : t(getActionLabel(action), { ns: 'exchangeActionLabel' });

  return (
    <>{label}</>
  );
};

export const ContractExchangeReplyForm = memo(({
  actions,
  canClearResponseWithObsoleteAction,
}: {
  actions: ExchangeHistoryAction[];
  canClearResponseWithObsoleteAction?: boolean;
}) => {
  const { t } = useTranslation();
  const exchange = useExchange();
  const [sendExchangeReply, { isLoading }] = useSendExchangeReplyContext();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const isSender = useIsSender();
  const panelSection = isSender ? 'buyer' : 'supplier';
  const lineItemExchangeColumns = useLineItemExchangeModalSectionColumns(exchange, panelSection);

  // We currently only pass the line item columns to `getExchangeReplyFormConfig`
  // because we currenty only need those (to get the correct order of line item
  // fields).
  // In case we adjust `getExchangeReplyFormConfig` to use the columns also for
  // other exchange types, we'd either need to add them here (for models, see
  // BuyerFieldsSection and SupplierFieldsSection) or replace the ContractReplyForm
  // with the InlineReplyForm that we use in requests.
  const columns = lineItemExchangeColumns;

  if (!actions.length && !canClearResponseWithObsoleteAction) {
    throw new Error('There must be at least 1 visible action to render this form');
  }

  const { level1Actions, level2Actions } = useMemo(() => {
    if (isDocumentExchangeDefinition(exchange.def)) {
      const [commentActions, otherActions] = partition(
        actions,
        action => action.type === ActionType.NONE,
      );

      return {
        level1Actions: isEmpty(otherActions) && !canClearResponseWithObsoleteAction
          ? commentActions
          : [
            {
              actionLabelTranslationKey: canClearResponseWithObsoleteAction
                ? 'common.updateResponse'
                : 'common.submitResponse',
              isActionGroup: true,
            },
            ...commentActions,
          ],
        level2Actions: otherActions,
      };
    } else {
      return {
        level1Actions: actions,
        level2Actions: [],
      };
    }
  }, [actions, exchange, canClearResponseWithObsoleteAction]);

  const [level1ActionIndex, setLevel1ActionIndex] = useState(INITIAL_ACTION_INDEX);
  const [level2ActionIndex, setLevel2ActionIndex] = useState(INITIAL_ACTION_INDEX);

  const hasSelectedLevel1Action = level1ActionIndex > INITIAL_ACTION_INDEX;
  // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
  const hasSingleSubmitLevel2Action = (level2Actions.length === 1 && first(level2Actions).type === ActionType.SUBMIT);

  useWatchValue(actions, () => setLevel1ActionIndex(INITIAL_ACTION_INDEX));
  useWatchValue(level1ActionIndex, () => {
    const newLevel2ActionIndex = hasSingleSubmitLevel2Action
      ? 0
      : INITIAL_ACTION_INDEX;

    setLevel2ActionIndex(newLevel2ActionIndex);
  });

  const selectedLevel1Action = level1Actions[level1ActionIndex] ?? level1Actions[0];

  const selectedAction = 'isActionGroup' in selectedLevel1Action
    ? level2Actions[level2ActionIndex]
    : selectedLevel1Action as ExchangeHistoryAction;

  // Track the number of resets (ie: submissions) so that we can use it
  // as a key to re-mount uncontrolled components.
  const [numResets, setNumResets] = useState(0);

  const { initialValues, validationSchema, Fields, sanitize } = useMemo(
    () => {
      const formConfig = getExchangeReplyFormConfig(exchange, selectedAction);

      return {
        initialValues: formConfig.getInitialValues(exchange, { companyId: currentCompanyId, userId: currentUser._id }),
        validationSchema: formConfig.getValidationSchema(t, exchange),
        Fields: formConfig.Fields,
        sanitize: formConfig.sanitize || identity,
      };
    },
    [exchange, selectedAction, currentCompanyId, currentUser._id, t],
  );

  const clearResponse = useCallback(
    async () => {
      if (canClearResponseWithObsoleteAction) {
        await sendExchangeReply({ value: ActionType.OBSOLETE_ACTION } as Action);
      } else if (exchange.def.type === ExchangeType.QUESTION) {
        await sendExchangeReply({
          value: selectedAction.type,
          response: {
            noAnswer: false,
            value: getEmptyResponseValue(exchange.def as unknown as QuestionExchangeDefinition),
          },
          ...selectedAction.payload,
        });
      } else if (exchange.def.type === ExchangeType.LINE_ITEM) {
        const entries = Object
          .values(exchange.def.fields)
          .filter(exchange.roles.includes('submitter')
            ? ((field): field is ReplyFieldConfig =>
              isSupplierReplyField(field) && !isFieldDisabled(field, exchange as LineItemsExchangeSnapshot))
            : isBuyerReplyField,
          )
          .map(field => field.source.key)
          .map(key => [key, null]);

        await sendExchangeReply({
          value: selectedAction.type,
          ...Object.fromEntries(entries),
          ...selectedAction.payload,
        });
      }

      setNumResets(numResets => numResets + 1);
    },
    [canClearResponseWithObsoleteAction, exchange, selectedAction, sendExchangeReply],
  );

  const canClearResponse = useMemo(() => {
    if (canClearResponseWithObsoleteAction) {
      return true;
    }

    if (!selectedAction) {
      return false;
    }

    if (selectedAction.type !== ActionType.SUBMIT) {
      return false;
    }

    if (exchange.def.type === ExchangeType.QUESTION) {
      return (
        exchange.status === ExchangeStatus.COMPLETE &&
        exchange.roles.includes('submitter')
      );
    } else if (exchange.def.type === ExchangeType.LINE_ITEM) {
      const isSupplier = exchange.roles.includes('submitter');

      if (isSupplier) {
        return Object
          .values(exchange.def.fields)
          .filter((field): field is ReplyFieldConfig =>
            isSupplierReplyField(field) && !isFieldDisabled(field, exchange as LineItemsExchangeSnapshot))
          .some(field => {
            const value = getExchangeFieldValue(exchange as any, field._id);

            return !isNil(value) && value !== '';
          });
      } else {
        return Object.values(exchange.def.fields)
          .filter(isBuyerReplyField)
          .some(field => {
            const value = getExchangeFieldValue(exchange as any, field._id);

            return !isNil(value) && value !== '';
          });
      }
    } else {
      return false;
    }
  }, [exchange, selectedAction, canClearResponseWithObsoleteAction]);

  return (
    <LabelConfigProvider variant={LabelConfig.LEFT}>
      <Box
        pt={3}
        pb={hasSelectedLevel1Action ? undefined : 3}
        bg="lightGray3"
        sx={{ boxShadow: hasSelectedLevel1Action ? 'rgb(51 61 78 / 20%) 0px 0px 16px' : undefined }}
      >
        <SelectLevel1Action
          exchangeType={exchange.def.type}
          actions={level1Actions}
          setActionIndex={setLevel1ActionIndex}
          actionIndex={level1ActionIndex}
          mt={hasSelectedLevel1Action ? undefined : '18px'}
          mb={hasSelectedLevel1Action ? '14px' : 3}
          initialValues={initialValues}
        />
        {hasSelectedLevel1Action && (
          <>
            <PanelDivider />
            {'isActionGroup' in selectedLevel1Action && level2Actions.length > 0 && !hasSingleSubmitLevel2Action ? (
              <SelectLevel2Action
                actions={level2Actions}
                setActionIndex={setLevel2ActionIndex}
                actionIndex={level2ActionIndex}
              />
            ) : (
              null
            )}
            {hasSelectedLevel1Action ? (
              <Formik
                key={`${exchange._id}-${selectedAction?.type}-${exchange.history.length}`}
                validateOnBlur
                enableReinitialize
                initialValues={initialValues}
                isInitialValid={validationSchema.isValidSync(initialValues)}
                validationSchema={validationSchema}
                onSubmit={async action => {
                  await sendExchangeReply({
                    value: selectedAction.type,
                    ...sanitize(action, exchange),
                    ...selectedAction.payload,
                  });

                  setNumResets(numResets => numResets + 1);
                }}
              >
                {({ isSubmitting, isValid, dirty }) => (
                  <Form>
                    <Stack gap="24px" p={3} maxHeight="70vh" overflowY="auto">
                      <Fields
                        exchange={exchange}
                        numResets={numResets}
                        columns={columns}
                      />
                      <Flex justifyContent="space-between">
                        <Box>
                          {canClearResponse && (
                            <ClearResponseDropdown
                              onConfirm={clearResponse}
                              disabled={isLoading || isSubmitting}
                            />
                          )}
                        </Box>
                        <Box>
                          <CancelButton
                            onClick={() => setLevel1ActionIndex(INITIAL_ACTION_INDEX)}
                            mr={2}
                          />
                          <Button
                            type="submit"
                            disabled={(
                              isLoading ||
                              isSubmitting ||
                              !isValid ||
                              !selectedAction ||
                              (canOnlySubmitWhenDirty(exchange) && !dirty)
                            )}
                          >
                            <SubmitButtonLabelText
                              exchangeType={exchange.def.type}
                              action={selectedLevel1Action}
                              initialValues={initialValues}
                            />
                          </Button>
                        </Box>
                      </Flex>
                    </Stack>
                  </Form>
                )}
              </Formik>
            ) : (
              null
            )}
          </>
        )}
      </Box>
    </LabelConfigProvider>
  );
});
