import { useMemo } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box } from 'rebass/styled-components';
import { filter, keyBy, map, mapValues } from 'lodash';

import { RecipientSource } from '@deepstream/common/rfq-utils';
import { useModalState } from '../../ui/useModalState';
import { AddRecipientFromListButton } from './AddRecipientFromListModal';
import { SendInviteButton, InviteModal } from '../../InviteModal';

import { useSuppliersContext } from './SuppliersContext';
import { SupplierSearchBox } from './SupplierSearchBox';
import { AddRecipientFromPreQualButton } from './AddRecipientFromPreQual';

export const AddSuppliersPanel = () => {
  const inviteModalState = useModalState();
  const { t } = useTranslation(['translation', 'request']);
  const {
    addSupplier,
    addSuppliersBulk,
    suppliers,
    senders,
    isLive,
    rfqName,
    addInvitations,
    addSupplierByUser,
  } = useSuppliersContext();

  const inviteModalMessage = useMemo(() => {
    const isRequest = true;

    // Duplicate customized message from `SendInviteButton` until we find a better way of handling this modal
    // Keep isRequest for consistency and readability
    if (isRequest && isLive) {
      return t('invite.customizedMessages.forLiveRequest', { requestTitle: rfqName });
    } else if (isRequest && rfqName) {
      return t('invite.customizedMessages.forRequestWithTitle', { requestTitle: rfqName });
    } else if (isRequest) {
      return t('invite.customizedMessages.forRequestWithoutTitle');
    }
  }, [t, isLive, rfqName]);

  const senderIds = React.useMemo(
    () => map(senders, sender => sender.company._id),
    [senders],
  );

  const supplierIds = React.useMemo(
    () => map(suppliers, supplier => supplier.company._id),
    [suppliers],
  );

  const suppliersSelectedUsersMap = React.useMemo(
    () => mapValues(
      keyBy(suppliers, (supplier) => supplier.company._id),
      (supplier) => {
        const selectedUsers = filter(supplier.users, (user) => user.selected);

        // @ts-expect-error ts(2339) FIXME: Property '_id' does not exist on type 'number | SupplierStateUser | (() => IterableIterator<SupplierStateUser>) | { [x: number]: boolean | undefined; length?: boolean | undefined; ... 33 more ...; readonly [Symbol.unscopables]?: boolean | undefined; } | ... 31 more ... | ((index: number) => SupplierStateUser | undefined)'.
        return map(selectedUsers, (user) => user._id);
      },
    ),
    [suppliers],
  );

  const addSuppliersFromList = React.useCallback(
    (supplierIds) => addSuppliersBulk(supplierIds, RecipientSource.SUPPLIER_LIST),
    [addSuppliersBulk],
  );

  const addSuppliersFromPreQual = React.useCallback(
    (supplierIds) => addSuppliersBulk(supplierIds, RecipientSource.PRE_QUAL),
    [addSuppliersBulk],
  );

  return (
    <Flex justifyContent="space-between" alignItems="flex-end" sx={{ gap: 3, width: '780px' }}>
      <Box flex="1 1 auto">
        <SupplierSearchBox
          canSearchByEmail
          canSendInvites
          requiredRole="receiveRFQ"
          companyIdsToExclude={senderIds}
          companyIdsAlreadySelected={supplierIds}
          selectedUserIdsByCompanyId={suppliersSelectedUsersMap}
          placeholder={t('suppliers.addSearchPanel.placeholder', { ns: 'request' })}
          onInviteClick={() => inviteModalState.open()}
          onResultClick={({ type, value }) => {
            if (type === 'user') {
              addSupplierByUser(value._id, value.companyId, RecipientSource.SEARCH);
            } else {
              addSupplier(value._id, RecipientSource.SEARCH);
            }
          }}
        />
        <InviteModal
          close={inviteModalState.close}
          // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
          customizedMessage={inviteModalMessage}
          isOpen={inviteModalState.isOpen}
          onInvitesSent={addInvitations}
        />
      </Box>
      <AddRecipientFromPreQualButton
        recipientIds={suppliers.map((supplier) => supplier.company._id)}
        addRecipients={addSuppliersFromPreQual}
      />
      <Box>
        <AddRecipientFromListButton
          recipientIds={suppliers.map((supplier) => supplier.company._id)}
          onAddFromList={addSuppliersFromList}
        />
      </Box>
      <Box>
        <SendInviteButton
          isRequest
          isLive={isLive}
          rfqName={rfqName}
          onInvitesSent={addInvitations}
        />
      </Box>
    </Flex>
  );
};
