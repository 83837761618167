import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';
import { compact, isEmpty } from 'lodash';
import { v4 as uuid } from 'uuid';
import { DocumentExchangeDefinition, getRequirementFromExchangeType } from '@deepstream/common/rfq-utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { withProps } from '@deepstream/ui-utils/withProps';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { StaticTableStyles, FormTableStyles } from '../../TableStyles';
import { Table } from '../../Table';
import { Required } from '../../Required';
import { useExchangeDefsField } from '../../draft/exchangeDefs';
import {
  RequirementFieldCell,
  RequirementCell,
  ExchangeDefDescriptionCell,
  ValidationErrorCell,
} from '../../draft/cell';
import { nestCells } from '../../nestCells';
import { WordWrap } from '../../WordWrap';
import { ErrorMessage } from '../../ui/ErrorMessage';
import { ContractExchangeDefRowCells } from './ContractExchangeDefRowCells';
import * as validation from '../../draft/validation';
import { useContractSection, useIsSender, useSectionExchangeDefsByCreator } from './contract';
import { useShowValidationErrors } from './draftContract';
import {
  AttachmentCell, ContractExchangeFileFieldCell, ExchangeDefDescriptionFieldCell,
  ContractExchangeDefActionsCell,
} from './contractDraftCell';
import { AddDocumentButton } from '../../draft/AddDocumentButton';

const FieldColumnHeader = ({ column }) => (
  <WordWrap pr={column.headerPaddingRight}>
    {column.label}
    {column.required && <Required />}
    {column.infoTooltip && (
      <Tooltip
        content={column.infoTooltip}
        placement="top"
      >
        <Icon
          color="gray"
          ml={1}
          icon="info-circle"
        />
      </Tooltip>
    )}
  </WordWrap>
);

const ReadOnlyColumnHeader = ({ column }) => (
  <WordWrap>
    {column.label}
  </WordWrap>
);

export const ContractDocumentTable = ({
  publisherGroup,
}: {
  publisherGroup?: 'sender' | 'recipient';
}) => {
  const { t } = useTranslation(['contracts', 'general']);
  const showValidationErrors = useShowValidationErrors();
  const { error } = validation.useError('exchangeDefs');
  const isSender = useIsSender();

  const defaultPublisherGroup = isSender ? 'sender' : 'recipient';

  const exchangeDefs = useSectionExchangeDefsByCreator({
    group: publisherGroup || defaultPublisherGroup,
  });

  const columns = useMemo(
    () => [{
      id: 'type',
      Header: ReadOnlyColumnHeader,
      label: t('requirement'),
      accessor: getRequirementFromExchangeType,
      Cell: nestCells(
        withProps(ValidationErrorCell, { useShowValidationErrors }),
        RequirementCell,
      ),
      width: 300,
    }, {
      id: 'attachments',
      Header: ReadOnlyColumnHeader,
      label: t('document', { ns: 'general' }),
      accessor: ({ attachments }: DocumentExchangeDefinition) => attachments?.[0],
      Cell: nestCells(
        withProps(ValidationErrorCell, { useShowValidationErrors }),
        AttachmentCell,
      ),
      width: 255,
    },
    {
      id: 'category',
      Header: ReadOnlyColumnHeader,
      label: t('description', { ns: 'general' }),
      accessor: 'category',
      Cell: nestCells(
        withProps(ValidationErrorCell, { useShowValidationErrors }),
        ExchangeDefDescriptionCell,
      ),
    }],
    [t],
  );

  return showValidationErrors && error ? (
    <PanelPadding bg="errorBackground" py="16px">
      <ErrorMessage fontSize={2} error={error} />
    </PanelPadding>
  ) : isEmpty(exchangeDefs) ? (
    <PanelPadding>
      <Text color="subtext" fontSize={2}>
        {t('details.noDocumentsAdded')}
      </Text>
    </PanelPadding>
  ) : (
    <StaticTableStyles>
      <Table
        columns={columns}
        data={exchangeDefs}
        CustomRowCells={ContractExchangeDefRowCells}
      />
    </StaticTableStyles>
  );
};

// This component is used for both draft document sections and supplier-added documents
export const ContractDocumentTableField = ({
  fieldName = 'exchangeDefs',
}: {
  fieldName?: string;
}) => {
  const { t } = useTranslation(['contracts', 'general']);
  const section = useContractSection();

  const { exchangeDefs, addExchangeDef } = useExchangeDefsField(
    fieldName,
    () => ({
      _id: uuid(),
      category: '',
      attachments: [],
      sectionId: section._id,
    }),
  );

  const columns = useMemo(
    () => compact([
      {
        id: 'requirement',
        Header: FieldColumnHeader,
        label: t('requirement'),
        accessor: getRequirementFromExchangeType,
        Cell: withProps(RequirementFieldCell, { fieldName }),
        required: true,
        width: 300,
        infoTooltip: t('details.requirementTooltip'),
      },
      {
        id: 'attachments',
        Header: FieldColumnHeader,
        label: t('document', { ns: 'general' }),
        accessor: 'attachments',
        Cell: withProps(ContractExchangeFileFieldCell, { fieldName }),
        required: true,
        width: 330,
      },
      {
        id: 'category',
        Header: FieldColumnHeader,
        label: t('description', { ns: 'general' }),
        accessor: 'category',
        Cell: withProps(ExchangeDefDescriptionFieldCell, { fieldName }),
        required: true,
        hideNumbering: true,
      },
      {
        id: 'menu',
        Header: () => '',
        accessor: null,
        width: 48,
        Cell: withProps(ContractExchangeDefActionsCell, { fieldName }),
      },
    ]),
    [t, fieldName],
  );

  return (
    <>
      {exchangeDefs.length > 0 ? (
        <FormTableStyles>
          <Table columns={columns} data={exchangeDefs} />
        </FormTableStyles>
      ) : (
        <Text color="subtext" fontSize={2} mb={2}>
          {t('details.noDocumentsAdded')}
        </Text>
      )}
      <AddDocumentButton
        type="button"
        mt={3}
        variant="secondary"
        onClick={() => addExchangeDef()}
      />
    </>
  );
};
