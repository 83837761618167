import { Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useRouter } from '@tanstack/react-router';
import { RequestInteractivityStatus } from '@deepstream/common/rfq-utils';
import { useModalState } from '../../../ui/useModalState';
import * as rfx from '../../../rfx';
import { useRequestRecipientNavigation } from '../../../appNavigation';
import * as lotPagesLayout from '../Live/lotPagesLayout';
import { IntentionToBidModal } from '../../../BidIntentionPanel';

export const RequestRecipientContinueToStageSection = () => {
  const { t } = useTranslation(['translation', 'general']);
  const router = useRouter();
  const navigation = useRequestRecipientNavigation();
  const requestInteractivityStatus = rfx.useRequestInteractivityStatus();

  const intentionToBidModal = useModalState();
  const bid = rfx.useBid();

  const enteredStageNumber = bid.enteredStageIds.length;
  const activatedStageNumber = bid.activatedStageIds.length;

  return requestInteractivityStatus === RequestInteractivityStatus.LIVE ? (
    <>
      <lotPagesLayout.Section heading={t('request.intention.continueToStage', { stageNumber: enteredStageNumber })}>
        <Text mt="12px">
          {t('request.intention.confirmYouWillContinue')}
        </Text>
        <Text mt="6px" mb="14px">
          {t('request.intention.youMustCompleteAllRequirements')}
        </Text>
        <Button onClick={intentionToBidModal.open} iconLeft="check">
          {t('continue', { ns: 'general' })}
        </Button>
        <lotPagesLayout.InfoText>
          {t('request.intention.ifYouNoLongerWishToContinue')}
        </lotPagesLayout.InfoText>
      </lotPagesLayout.Section>
      <IntentionToBidModal
        isOpen={intentionToBidModal.isOpen}
        enteredStageNumber={enteredStageNumber!}
        activatedStageNumber={activatedStageNumber!}
        isBidWithdrawn={false}
        hasWithdrawnInCurrentStage={false}
        close={intentionToBidModal.close}
      />
    </>
  ) : (
    <lotPagesLayout.Section heading={t('request.intention.youDidNotContinueToStage', { stageNumber: enteredStageNumber })}>
      <Text mt="12px">
        {t('request.intention.youCanNoLongerContinue')}
      </Text>
      <lotPagesLayout.InfoText>
        {t('request.intention.youWillBeNotified')}
      </lotPagesLayout.InfoText>
    </lotPagesLayout.Section>
  );
};
