import { useMemo } from 'react';
import { sumBy } from 'lodash';
import { Live, LotIntentionStatus } from '@deepstream/common/rfq-utils';
import { isBiddingOnLot, isLotInactive } from '@deepstream/common/rfq-utils/lot';
import * as rfx from '../../../rfx';

export const useLotsInfo = () => {
  const { lots } = rfx.useStructure<Live>();
  const bid = rfx.useBid();

  return useMemo(() => {
    const inactiveLotCount = sumBy(lots, lot => Number(
      isLotInactive(lot, bid.intentionStatusByLotId[lot._id]),
    ));

    const nonObsoleteLotCount = sumBy(lots, lot => lot.isObsolete ? 0 : 1);
    const biddingCount = sumBy(lots, lot => isBiddingOnLot(lot, bid.intentionStatusByLotId[lot._id]) ? 1 : 0);
    const hasLotWithNoResponse = lots.some(lot => (
      !lot.isObsolete &&
      (bid.intentionStatusByLotId[lot._id] || LotIntentionStatus.NO_RESPONSE) === LotIntentionStatus.NO_RESPONSE),
    );

    const areAllLotsInactive = inactiveLotCount === lots.length;

    return {
      inactiveLotCount,
      nonObsoleteLotCount,
      biddingCount,
      hasLotWithNoResponse,
      areAllLotsInactive,
    };
  }, [lots, bid]);
};
