import { useCallback } from 'react';

/**
 * Used to prevent form submissions by pressing Enter on non-button elements
 */
export const usePreventEnterKeyHandler = () => useCallback(
  (keyEvent) => {
    const isButton = ['button', 'submit'].includes(keyEvent.target.type);
    const isEnterKey = (keyEvent.charCode || keyEvent.keyCode) === 13;

    // Allow pressing Enter on buttons
    if (!isButton && isEnterKey) {
      keyEvent.preventDefault();
    }
  },
  [],
);
