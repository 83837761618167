import { CellProps } from 'react-table';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { ExchangeSnapshot } from './types';

export const DocumentExchangeLabelCell = ({
  cell: {
    value: label,
  },
  row: {
    original: exchange,
  },
}: CellProps<ExchangeSnapshot>) => (
  <>
    {exchange.hasLock ? (
      <IconText
        icon={exchange.isLocked ? 'lock' : 'unlock'}
        text={label}
        fontSize={2}
        gap={2}
      />
    ) : (
      label
    )}
  </>
);
