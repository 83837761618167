import * as React from 'react';
import { Flex } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { Button as LinkButton } from '@deepstream/ui-kit/elements/button/Button';
import { copyToClipboard } from '@deepstream/ui-utils/clipboard';
import { useToaster } from '../../../toast';
import * as Layout from '../../../Layout';

const PublicRequestPreviewBannerContext = React.createContext<{
  isVisible: boolean;
  setIsVisible:(isVisible: boolean) => void;
}>({
  setIsVisible: noop,
  isVisible: false,
});

export const PublicRequestPreviewBannerProvider = ({ children }: { children: React.ReactNode }) => {
  const [isVisible, setIsVisible] = React.useState(false);

  const value = React.useMemo(
    () => ({
      isVisible,
      setIsVisible,
    }),
    [isVisible],
  );

  return (
    <PublicRequestPreviewBannerContext.Provider value={value}>
      {children}
    </PublicRequestPreviewBannerContext.Provider>
  );
};

export const usePublicRequestPreviewBanner = () => React.useContext(PublicRequestPreviewBannerContext);

export const PublicRequestPreviewBanner = () => {
  const { t } = useTranslation('translation');
  const theme = useTheme();
  const toaster = useToaster();

  const copyLink = React.useCallback(
    () => {
      copyToClipboard(window.location.origin + window.location.pathname);
      toaster.success(t('request.intention.publicRequestLinkCopySuccess'));
    },
    [toaster, t],
  );

  return (
    <Layout.BannerContainer backgroundColor={theme.colors.primary}>
      <Flex alignItems="center" px="20px" width="100%" justifyContent="space-between">
        <Flex sx={{ columnGap: '16px' }}>
          <IconText
            icon="globe"
            text={t('request.intention.publicRequestPreview')}
            fontSize="18px"
            fontWeight={500}
            isIconRegular
          />
          <IconText
            icon="eye-slash"
            text={t('request.intention.publicRequestPreviewVisibility')}
          />
        </Flex>
        <LinkButton variant="primary" iconLeft="link" onClick={copyLink}>
          {t('copyLink', { ns: 'general' })}
        </LinkButton>
      </Flex>
    </Layout.BannerContainer>
  );
};
