import { Draft } from '@deepstream/common/rfq-utils';
import { RfxStructure } from '../../../types';

export enum SetupMultiStageResponsesFlowStepType {
  GUIDELINES = 'guidelines',
  REVIEW_STAGES = 'reviewStages',
  SELECT_SECTIONS = 'selectSections',
  CONFIGURE_SECTION = 'configureSection',
  ADD_SECTIONS = 'addSections',
  DELETE_SECTIONS = 'deleteSections',
  REVIEW_CHANGES = 'reviewChanges',
}

export type SetupMultiStageResponsesFlowStep =
| { type: SetupMultiStageResponsesFlowStepType.GUIDELINES }
| { type: SetupMultiStageResponsesFlowStepType.REVIEW_STAGES }
| { type: SetupMultiStageResponsesFlowStepType.SELECT_SECTIONS }
| { type: SetupMultiStageResponsesFlowStepType.CONFIGURE_SECTION, currentSectionIndex: number }
| { type: SetupMultiStageResponsesFlowStepType.ADD_SECTIONS }
| { type: SetupMultiStageResponsesFlowStepType.DELETE_SECTIONS, deletableSectionIds: string[] }
| { type: SetupMultiStageResponsesFlowStepType.REVIEW_CHANGES };

export type SetupMultiStageResponsesFlowData = {
  steps: SetupMultiStageResponsesFlowStep[];
  currentStep: SetupMultiStageResponsesFlowStep;
  initialStages: RfxStructure<Draft>['stages'];
  updatedStages: RfxStructure<Draft>['stages'];
  initialSectionIds: string[];
  selectedSectionIds: string[];
  addedSectionIds: string[];
};
