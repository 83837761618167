import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box } from 'rebass/styled-components';
import { useField } from 'formik';

import { YesNoOption } from '@deepstream/common/rfq-utils';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Radio } from '../../ui/Radio';
import { DisabledInputBox } from '../../ui/Input';
import { CheckboxFieldBase } from '../../form/CheckboxField';

export const YesNoField = ({
  fieldName,
  disabled,
}: {
  fieldName?: string,
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const allowCustomOptionFieldName = fieldName ? `${fieldName}.allowCustomOption` : 'allowCustomOption';
  const moreInformationOnFieldName = fieldName ? `${fieldName}.requireMoreInformationOn` : 'requireMoreInformationOn';
  const optionsFieldName = fieldName ? `${fieldName}.options` : 'options';
  const [{ value: allowCustomOptionValue }, , allowCustomOptionHelper] = useField<boolean>(allowCustomOptionFieldName);
  const [{ value: moreInformationOn }, , moreInformationOnHelper] = useField<YesNoOption[]>(moreInformationOnFieldName);
  const [{ value: optionsValue }] = useField<YesNoOption[]>(optionsFieldName);

  const moreInformationOnChange = React.useCallback(
    (option: YesNoOption) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        moreInformationOnHelper.setValue([...moreInformationOn, option]);
      } else {
        moreInformationOnHelper.setValue(moreInformationOn.filter((existingOption) => existingOption !== option));
      }
    },
    [moreInformationOn, moreInformationOnHelper],
  );

  return (
    <Box>
      <Stack gap={8}>
        {optionsValue.map((option) => (
          <Flex key={option} alignItems="center" sx={{ gap: 3 }}>
            <Flex alignItems="center" sx={{ flex: '1 1 50%' }}>
              <Radio disabled />
              <DisabledInputBox sx={{ flex: '1 1 auto' }}>
                {t(`request.question.predefinedOption.${option}`)}
              </DisabledInputBox>
            </Flex>
            <Box sx={{ flex: '1 1 50%' }}>
              <CheckboxFieldBase
                disabled={disabled}
                fieldLabel={t(`request.question.yesNo.requestSupportingInformation.${option}`)}
                value={moreInformationOn.includes(option)}
                onChange={moreInformationOnChange(option)}
              />
            </Box>
          </Flex>
        ))}
        {allowCustomOptionValue && (
          <Flex key="other" alignItems="center">
            <Radio disabled />
            <DisabledInputBox sx={{ marginRight: 8, flex: '1 1 auto' }}>
              {t('request.question.otherOption')}
            </DisabledInputBox>
            <Button
              variant="secondary-outline"
              width="40px"
              iconLeft="trash"
              sx={{ borderRadius: 4, width: '40px', height: '40px' }}
              onClick={() => allowCustomOptionHelper.setValue(false)}
            />
          </Flex>
        )}
      </Stack>
      <Flex sx={{ gap: 12, marginTop: 3 }}>
        <Button
          small
          iconLeft="plus"
          variant="secondary"
          disabled={allowCustomOptionValue || disabled}
          onClick={() => allowCustomOptionHelper.setValue(true)}
        >
          {t('request.question.addOther')}
        </Button>
      </Flex>
    </Box>
  );
};
