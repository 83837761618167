import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { map, values, filter } from 'lodash';
import { QuestionType } from '@deepstream/common/rfq-utils';

import { SelectFieldBase } from '../../form/SelectField';

const useQuestionTypeItems = ({
  filter: filterCb,
}: {
  filter?: (type: QuestionType) => boolean,
}) => {
  const { t } = useTranslation();

  return useMemo(() => {
    const allQuestionTypes = values(QuestionType);

    const questionTypesToList = typeof filter !== 'undefined'
      ? filter(allQuestionTypes, filterCb)
      : allQuestionTypes;

    return map(
      questionTypesToList,
      questionType => ({
        value: questionType,
        label: t(`request.question.questionType.${questionType}`),
      }),
    );
  }, [t, filterCb]);
};

export const QuestionTypeSelector = ({
  filter,
  disabled,
  required,
  value,
  onChange,
}: {
  filter?: (type: QuestionType) => boolean,
  disabled?: boolean;
  required?: boolean;
  value: QuestionType;
  onChange?: (e: QuestionType) => void;
}) => {
  const { t } = useTranslation();
  const questionTypeItems = useQuestionTypeItems({ filter });

  return (
    <SelectFieldBase
      label={t('general.type')}
      items={questionTypeItems}
      disabled={disabled}
      required={required}
      value={value}
      onChange={onChange}
    />
  );
};
