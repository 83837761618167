import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { Flex, Box } from 'rebass/styled-components';

import { CurrencySelectFieldBase } from '../../form/CurrencySelectField';
import { CheckboxFieldBase } from '../../form/CheckboxField';
import { DisabledInputBox } from '../../ui/Input';
import { DEFAULT_CURRENCY } from '../../ui/currencies';

export const PriceField = ({
  fieldName,
  disabled,
}: {
  fieldName?: string;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  const currenciesFieldName = fieldName ? `${fieldName}.currencies` : 'currencies';
  const [field,, formik] = useField<string[] | null>(currenciesFieldName);
  const shouldSpecifyCurrency = Boolean(field.value);

  // Although the exchange definition supports multiple currency selection, the UI isn't
  // exposing that (yet).
  const currencyCode = field.value?.[0];

  return (
    <Flex alignItems="center">
      <Box flex="0 1 auto" mr="10px">
        {shouldSpecifyCurrency ? (
          <CurrencySelectFieldBase
            hideLabel
            label={t('general.currency')}
            value={currencyCode}
            onChange={currencyCode => formik.setValue([currencyCode])}
            disabled={disabled}
          />
        ) : (
          <DisabledInputBox height="40px">
            {t('general.currency')}
          </DisabledInputBox>
        )}
      </Box>
      <DisabledInputBox flex="0 0 260px" height="40px" mr="10px">
        {shouldSpecifyCurrency && currencyCode ? (
          `${currencyCode} ${t('general.price')}`
        ) : (
          t('general.price')
        )}
      </DisabledInputBox>
      <Box flex="1 1 0">
        <CheckboxFieldBase
          fieldLabel={t('request.question.specifyCurrency')}
          value={shouldSpecifyCurrency}
          onChange={(event: any) => {
            if (event.target.checked) {
              formik.setValue([DEFAULT_CURRENCY]);
            } else {
              formik.setValue(null);
            }
          }}
          disabled={disabled}
        />
      </Box>
    </Flex>
  );
};
