import { useMemo } from 'react';
import { filter, find, first, fromPairs } from 'lodash';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
  LineItemsSection,
  isCurrencyExchangeDef,
  isLineItemExchangeDef,
  LineItemExchangeDefinition,
  DefinitionFieldConfig,
  isDefinitionField,
} from '@deepstream/common/rfq-utils';
import { PanelDivider, PanelPadding, ExpandablePanelSubSection } from '@deepstream/ui-kit/elements/Panel';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { EditableGridDataProvider, useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { assertDefined } from '@deepstream/utils';
import * as draft from './draft';
import * as rfx from '../rfx';
import { DraftPanel } from './DraftPanel';
import { CurrencyCodeProvider } from '../ui/Currency';
import { useError, Validation } from './validation';
import { LineItemExchangeDefsGrid } from '../ui/ExchangeDefsGrid/LineItemExchangeDefsGrid';
import { LineItemsSectionConfigIndicators } from './LineItemsSectionConfigIndicators';
import { SectionConfigHeading } from './SectionConfigHeading';
import { DraftSectionViewPanelHeader } from './DraftSectionViewPanelHeader';
import { useMultiStageBehaviorDescription } from './useMultiStageBehaviorDescription';
import { NO_LOT_PROXY_ID, useLotSelectItems } from './useLotSelectItems';

export const optionalBuyerFieldKeys = [
  'targetPrice',
  'evaluatorFieldCurrency',
];

export const useExchangeDefsValidationSchema = (exchangeDef?: LineItemExchangeDefinition) => {
  const { t } = useTranslation();

  return useMemo(() => {
    if (!exchangeDef) {
      return yup.mixed();
    }

    const exchangeDefShape = fromPairs(
      Object.values(exchangeDef.fields)
        .filter(field => isDefinitionField(field) && !optionalBuyerFieldKeys.includes(field._id))
        .map((field) => {
          let fieldSchema;

          switch (field.type) {
            case 'date':
              fieldSchema = yup.date().typeError(t('general.required')).required(t('general.required'));
              break;
            case 'boolean':
              fieldSchema = yup.bool().typeError(t('general.required')).required(t('general.required'));
              break;
            case 'string':
            case 'unspscCode':
              fieldSchema = yup.string().typeError(t('general.required')).required(t('general.required'));
              break;
            case 'price':
            case 'number':
              fieldSchema = yup.number().typeError(t('general.required')).required(t('general.required'));
              break;
            default:
              throw new Error(`Unsupported field type: ${field.type}`);
          }

          return [
            (field as DefinitionFieldConfig).source.key,
            fieldSchema,
          ];
        }),
      );

      return yup.array(
        yup.object()
          .shape(exchangeDefShape))
          .required()
          .min(1, t('request.review.errors.minimumOneRequired'),
      );
  }, [exchangeDef, t]);
};

const ViewSectionConfig = () => {
  const { settings } = rfx.useStructure();
  const isSender = rfx.useIsSender();
  const section = rfx.useSectionWithPosition<LineItemsSection>();
  const exchangeDefs = rfx.useSectionExchangeDefs();
  const modelExchangeDef = find(exchangeDefs, isLineItemExchangeDef);
  const currencyExchangeDef = find(exchangeDefs, isCurrencyExchangeDef);

  // Line item sections are expected to have a currency exchange definition
  // at all times.
  assertDefined(currencyExchangeDef, 'currencyExchangeDef');

  const showValidationErrors = draft.useShowValidationErrors();
  const { error: supplierCurrencyError } = useError('currencyExchangeDef.currencies');
  const { error: tagsError } = useError('section.responseTagConfig.tags');

  const hasError = supplierCurrencyError || tagsError;

  // @ts-expect-error ts(18048) FIXME: 'section.stages' is possibly 'undefined'.
  const stageId = section.stages[0];
  const multiStageBehavior = useMultiStageBehaviorDescription(section.responseTagConfig, stageId);

  const lotSelectItems = useLotSelectItems();
  const selectedLot = settings.areLotsEnabled
    ? find(lotSelectItems, { value: first(section.lotIds) || NO_LOT_PROXY_ID })
    : null;

  return (
    <ExpandablePanelSubSection
      heading={<SectionConfigHeading />}
      renderCollapsedContent={() => (
        <LineItemsSectionConfigIndicators
          isSender={isSender}
          multiStageBehavior={multiStageBehavior}
          locking={section.locking}
          providedBy={section.providedBy}
          modelExchangeDef={modelExchangeDef}
          currencyExchangeDef={currencyExchangeDef}
          supplierCurrencyError={showValidationErrors ? supplierCurrencyError : null}
          tagsError={showValidationErrors ? tagsError : null}
          selectedLot={selectedLot}
        />
      )}
      bg={showValidationErrors && hasError ? 'errorBackground' : 'lightGray3'}
    />
  );
};

export const LineItemsSectionViewPanelContent = () => {
  const { t } = useTranslation();
  const section = rfx.useSectionWithPosition<LineItemsSection>();
  const sectionExchangeDefs = rfx.useSectionExchangeDefs();
  const isSender = rfx.useIsSender();
  const { isEditingSupplierExchangeDefs } = rfx.useState();
  const { rowData: lineItemExchangeDefs } = useEditableGridData();
  const showValidationErrors = draft.useShowValidationErrors();

  const currencyExchangeDef = find(sectionExchangeDefs, isCurrencyExchangeDef)!;
  const firstExchangeDef = lineItemExchangeDefs[0];

  const exchangeDefsValidationSchema = useExchangeDefsValidationSchema(firstExchangeDef);

  return (
    <Validation
      values={{
        section,
        currencyExchangeDef,
        exchangeDefs: lineItemExchangeDefs,
      }}
      schema={showValidationErrors ? (
        yup.object().shape({
          section: yup.object().shape({
            name: yup.string().required(t('general.required')),
            responseTagConfig: yup.object().shape({
              tags: yup.array().min(2, t('request.multiStageBehavior.minimumTwoStagesRequired')),
            }).nullable(),
          }),
          currencyExchangeDef: yup.object().shape({
            currencies: yup.array().nullable().required(t('general.required')),
          }),
          exchangeDefs: exchangeDefsValidationSchema,
        })
      ) : (
        yup.mixed()
      )}
    >
      <DraftPanel panelId={section._id}>
        <DraftSectionViewPanelHeader icon="list-ul" intercomEditEventName="edit-line-items-section-opened" />
        <PanelDivider />
        <ViewSectionConfig />
        <PanelDivider />
        <CurrencyCodeProvider code={lineItemExchangeDefs[0]?.evaluatorFieldCurrency}>
          <PanelPadding>
            <LineItemExchangeDefsGrid
              viewportHeightDelta={400}
              isReadOnly
              isSender={isSender}
              isEditingSupplierExchangeDefs={isEditingSupplierExchangeDefs}
            />
          </PanelPadding>
        </CurrencyCodeProvider>
      </DraftPanel>
    </Validation>
  );
};

export const LineItemsSectionViewPanel = () => {
  const { isTemplate } = rfx.useState();
  const senderLineItemExchangeDefs = rfx.useSectionExchangeDefsByCreator({
    filter: isLineItemExchangeDef,
    group: 'sender',
  });
  const exchangeDefs = rfx.useSectionExchangeDefs();
  const allLineItemExchangeDefs = filter(exchangeDefs, isLineItemExchangeDef);

  return (
    <EditableGridDataProvider
      // template exchangeDefs don't have a creatorId so we can't use
      // `useSectionExchangeDefsByCreator`
      rowData={isTemplate ? allLineItemExchangeDefs : senderLineItemExchangeDefs}
      enableReinitialize
    >
      <GridIdPrefixProvider>
        <GridMenuStateProvider>
          <LineItemsSectionViewPanelContent />
        </GridMenuStateProvider>
      </GridIdPrefixProvider>
    </EditableGridDataProvider>
  );
};
