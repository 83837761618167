import { QuestionnaireExchangeDefinition } from '@deepstream/common/preQual';
import { diffArrayBy } from '@deepstream/utils';
import { Attachment } from '@deepstream/common/rfq-utils';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useMutation } from '../../../useMutation';
import { MutationArgs } from '../../../types';
import { useApi } from '../../../api';
import { useQuestionnaireTemplateMutationArgs } from './useQuestionnaireTemplateMutationArgs';
import { useQuestionnaireTemplateData, useQuestionnaireTemplateExchangeDefs, useQuestionnaireTemplateId, useQuestionnaireTemplateState } from './questionnaireTemplateUtils';
import { mapDiffToCommands } from '../../../ui/diff';

export const useShowValidationErrors = () => {
  // @ts-expect-error ts(2339) FIXME: Property 'isReview' does not exist on type 'QuestionnaireTemplateStateContextType | undefined'.
  const { isReview } = useQuestionnaireTemplateState();

  return isReview;
};

export const useUpdateSummary = (args: MutationArgs = {}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const templateId = useQuestionnaireTemplateId();
  const mutationArgs = useQuestionnaireTemplateMutationArgs({ name: 'changesSaved', ...args });

  return useMutation(
    ({ summary }) => api.updateQuestionnaireTemplateSummary({
      currentCompanyId,
      templateId,
      summary,
    }),
    mutationArgs,
  );
};

export const useUpdateExpiryAndRenewalConfig = (args: MutationArgs = {}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const templateId = useQuestionnaireTemplateId();
  const mutationArgs = useQuestionnaireTemplateMutationArgs({ name: 'changesSaved', ...args });

  return useMutation(
    ({ renewalConfig, expiryConfig }) => api.updateQuestionnaireTemplateExpiryAndRenewalConfig({
      currentCompanyId,
      templateId,
      renewalConfig,
      expiryConfig,
    }),
    mutationArgs,
  );
};

export const useUpdateInternalData = (args: MutationArgs = {}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const templateId = useQuestionnaireTemplateId();
  const mutationArgs = useQuestionnaireTemplateMutationArgs({ name: 'changesSaved', ...args });

  return useMutation(
    ({ internal }) => api.updateQuestionnaireTemplateInternalData({
      currentCompanyId,
      templateId,
      internal,
    }),
    mutationArgs,
  );
};

export const questionnaireTemplateMappings = {
  exchangeDef: {
    key: 'exchangeDef',
    addedType: 'addQuestionnaireTemplateExchangeDef',
    removedType: 'removeQuestionnaireTemplateExchangeDef',
    reorderedType: 'reorderQuestionnaireTemplateExchangeDefs',
  },
  instructions: {
    key: 'instructions',
    updatedType: 'updateQuestionnaireTemplateInstructions',
  },
  instructionsAttachments: {
    key: 'instructionsAttachments',
    updatedType: 'updateQuestionnaireTemplateInstructionsAttachments',
  },
};

export const getCommands = ({ next, previous, mapping }) =>
  mapDiffToCommands(
    diffArrayBy(next, previous, '_id'),
    questionnaireTemplateMappings[mapping],
  ) as any;

type SaveSectionPayload = {
  instructions?: string;
  instructionsAttachments: Attachment[];
  exchangeDefs: QuestionnaireExchangeDefinition[];
};

export const useSaveQuestionSection = () => {
  const exchangeDefs = useQuestionnaireTemplateExchangeDefs();
  const { instructions, instructionsAttachments } = useQuestionnaireTemplateData();

  return useSaveSection({
    getQuestionnaireTemplateCommands: (next: SaveSectionPayload) => ([
      ...getCommands({
        next: next.exchangeDefs,
        previous: exchangeDefs,
        mapping: 'exchangeDef',
      }).map(command => command.name === 'addQuestionnaireTemplateExchangeDef'
        ? {
          ...command,
          payload: {
            questionId: command.payload._id,
          },
        }
        : command,
      ),
      ...getCommands({
        next: [next.instructions],
        previous: [instructions],
        mapping: 'instructions',
      }).map(command => ({ ...command, payload: { instructions: next.instructions } })),
      ...getCommands({
        next: [next.instructionsAttachments],
        previous: [instructionsAttachments],
        mapping: 'instructionsAttachments',
      }).map(command => ({ ...command, payload: { attachments: next.instructionsAttachments } })),
    ]),
  });
};

export const useSaveSection = ({
  getQuestionnaireTemplateCommands,
  ...args
}: {
  getQuestionnaireTemplateCommands: any;
} & MutationArgs) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const templateId = useQuestionnaireTemplateId();
  const mutationArgs = useQuestionnaireTemplateMutationArgs({ name: 'changesSaved', ...args });

  return useMutation(
    (params) => api.updateQuestionnaireTemplate({
      currentCompanyId,
      templateId,
      commands: getQuestionnaireTemplateCommands(params),
    }),
    mutationArgs,
  );
};
