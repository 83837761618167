import * as React from 'react';
import { Text, Box, Flex, BoxProps } from 'rebass/styled-components';
import { isNil } from 'lodash';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Table, TableProps } from './Table';
import { BasicTableStyles, StaticTableStyles } from './TableStyles';
import { useDeviceSize } from './ui/useDeviceSize';
import { ExchangeSnapshot } from './types';
import { createTableRow } from './utils';

export type ExchangeFieldsRow = ReturnType<typeof createTableRow>;

const ExchangeFields = ({
  row,
  columns,
  vertical,
  small,
  ...props
}: any) => (
  <Flex flexDirection={vertical ? 'column' : ['column', 'row', null, null]} {...props}>
    {columns.map((column: any, index: number) => {
      const { Header, Cell, width, flex } = column;

      return (
        <Stack
          key={index}
          gap={small ? 1 : 2}
          flex={flex}
          width={width}
          ml={vertical ? 0 : [0, index === 0 ? 0 : 3]}
          mt={vertical ? (index === 0 ? 0 : 3) : [index === 0 ? 0 : 3, 0]}
        >
          <Box>
            {isNil(Header) ? (
              null
            ) : typeof Header === 'string' ? (
              <Text as="label" htmlFor={`field-${index}`} fontSize ={small ? 1 : 2} fontWeight={500}>
                {Header}
              </Text>
            ) : (
              <Header
                // Pass the row to the header, so we can use it to get the value.
                data={[row.original]}
                column={column}
              />
            )}
          </Box>
          <Box id={`field-${index}`}>
            <Cell
              cell={{ value: row.values[column.id ?? column.accessor] }}
              row={row}
              column={column}
            />
          </Box>
        </Stack>
      );
    })}
  </Flex>
);

/**
 * Renders the fields of a single exchange outside of the context
 * of an exchanges list or table.
 * The index of the `row` property passed to `Cell` components has
 * a fixed value of 0.
 */
export const StandaloneExchangeFields = React.memo(({
  exchange,
  columns,
  ...props
}: any) => {
  const row = createTableRow({ index: 0, data: exchange, columns });

  return (
    <RfxExchangeFields row={row} columns={columns} {...props} />
  );
});

const RfxExchangeFields = ({
  row,
  columns,
  ...props
}: any) => (
  <Flex flexDirection="column" {...props}>
    {columns.map((column: any, index: number) => {
      const { Header, Cell } = column;

      return (
        <Flex
          key={index}
          width="100%"
          flexDirection="row"
          alignItems="baseline"
          ml={0}
          mt={index === 0 ? 0 : 3}
        >
          <Box flex={1}>
            {isNil(Header) ? (
              null
            ) : typeof Header === 'string' ? (
              <Text as="label" htmlFor={`field-${index}`} fontSize={2} fontWeight={500}>
                {Header}
              </Text>
            ) : (
              // Pass the row to the header, so we can use it to get the value.
              <Header data={[row.original]} fontSize={2} />
            )}
          </Box>
          <Box id={`field-${index}`} flex={2}>
            <Cell
              cell={{ value: row.values[column.id ?? column.accessor] }}
              row={row}
              column={column}
            />
          </Box>
        </Flex>
      );
    })}
  </Flex>
);

export type ListItemProps = BoxProps & { row: ExchangeFieldsRow };

const DefaultListItem: React.FC<ListItemProps> = ({ row, ...props }) => (
  <Box as="li" {...props} />
);

export const ExchangeList = React.memo(({
  exchanges,
  columns,
  onRowClick,
  CustomListItem = DefaultListItem,
}: {
  exchanges: any[];
  columns: any[];
  onRowClick?: ((exchange: any) => void) | null;
  CustomListItem?: React.FC<ListItemProps>;
}) => {
  const rows = exchanges.map((exchange, index) =>
    createTableRow({ index, data: exchange, columns }));

  return (
    <Box
      as="ul"
      p={0}
      sx={{
        listStyle: 'none',
        '> * + *': {
          borderTop: 'lightGray',
        },
      }}
    >
      {rows.map(row => (
        <CustomListItem
          key={row.original._id}
          row={row}
          role={onRowClick ? 'button' : undefined}
          onClick={() => onRowClick?.(row.original)}
          sx={onRowClick ? {
            ':hover': {
              cursor: 'pointer',
              backgroundColor: '#00000005',
            },
          } : undefined}
        >
          <PanelPadding>
            <ExchangeFields
              row={row}
              columns={columns}
            />
          </PanelPadding>
        </CustomListItem>
      ))}
    </Box>
  );
});

type ExchangeTableBaseProps =
  Omit<TableProps, 'data'> &
  {
    exchanges: ExchangeSnapshot[];
    fixedRowHeight?: boolean;
  };

export const ExchangeTableBase = React.memo(({
  columns,
  exchanges,
  onRowClick,
  isPaginated,
  fixedRowHeight = true,
  ...props
}: ExchangeTableBaseProps) => {
  const { isExtraSmall } = useDeviceSize();
  const TableStyles: any = onRowClick ? BasicTableStyles : StaticTableStyles;

  return isExtraSmall ? (
    <ExchangeList
      columns={columns}
      exchanges={exchanges}
      onRowClick={onRowClick}
    />
  ) : (
    <TableStyles fixedRowHeight={fixedRowHeight}>
      <Table
        isSortable
        isPaginated={isPaginated}
        data={exchanges}
        columns={columns}
        onRowClick={onRowClick}
        autoResetPage={false}
        autoResetSortBy={false}
        {...props}
      />
    </TableStyles>
  );
});
