import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useField } from 'formik';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { DropdownMenuItem, EllipsisMenu } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { findIndex } from 'lodash';
import { useCurrentUser } from '../../../../useCurrentUser';
import { Bold } from '../../../../Bold';
import { UserDetails } from '../../../../UserDetails';
import { useModalState } from '../../../../ui/useModalState';
import { useCurrentCompanyId } from '../../../../currentCompanyId';
import { SenderTeamUserModal } from './SenderTeamUserModal';
import { SenderTeamMember } from './utils';

export const TeamMembersPanel = () => {
  const { t } = useTranslation(['preQualification', 'translation']);
  const currentUser = useCurrentUser();
  const [{ value: teamMembers },, teamMembersHelper] = useField('teamMembers');
  const teamMemberModal = useModalState();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const [selectedTeamMember, setSelectedTeamMember] = useState<SenderTeamMember | null>(null);

  const existingUserIds = useMemo(
    () => teamMembers.map(({ _id }) => _id),
    [teamMembers],
  );

  const onSaveTeamMember = useCallback(
    (teamMember: SenderTeamMember) => {
      const teamMemberIndex = findIndex(
        teamMembers,
        ({ _id }) => _id === teamMember._id,
      );

      if (teamMemberIndex > -1) {
        teamMembersHelper.setValue([
          ...teamMembers.slice(0, teamMemberIndex),
          teamMember,
          ...teamMembers.slice(teamMemberIndex + 1),
        ]);
        setSelectedTeamMember(null);
      } else {
        teamMembersHelper.setValue([...teamMembers, teamMember]);
      }

      teamMemberModal.close();
    },
    [teamMembersHelper, teamMembers, teamMemberModal],
  );

  const onRemoveTeamMember = useCallback(
    (userId: string) => {
      teamMembersHelper.setValue(teamMembers.filter(({ _id }) => _id !== userId));
      teamMemberModal.close();
      setSelectedTeamMember(null);
    },
    [teamMembersHelper, teamMembers, teamMemberModal],
  );

  return (
    <>
      <Panel>
        <Flex p="12px 20px" alignItems="center">
          <Box flex="1 1 auto">
            <Bold lineHeight="normal" mb={1}>{t('questionnaire.teamMembers')}</Bold>
            <Text color="subtext">
              {t('questionnaire.teamMembersDescription')}
            </Text>
          </Box>
          <Button
            small
            iconLeft="plus"
            onClick={teamMemberModal.open}
          >
            {t('questionnaire.team.addUser')}
          </Button>
        </Flex>
        <PanelDivider />
        <Box
          as="ul"
          sx={{
            listStyle: 'none',
            padding: 0,
            '> * + *': {
              borderTop: 'lightGray2',
            },
          }}
        >
          {teamMembers.map((teamMember) => (
            <Flex as="li" key={teamMember._id} alignItems="center" p="12px 20px" sx={{ gap: 2 }}>
              <Box flex="1 1 auto">
                <UserDetails user={teamMember} />
              </Box>
              {teamMember._id !== currentUser._id && (
                <EllipsisMenu small>
                  <DropdownMenuItem
                    icon="pencil"
                    onSelect={() => {
                      setSelectedTeamMember(teamMember);
                      teamMemberModal.open();
                    }}
                  >
                    {t('questionnaire.managePermissions')}
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    icon="trash"
                    color="danger"
                    onSelect={() => {
                      teamMembersHelper.setValue(teamMembers.filter(({ _id }) => _id !== teamMember._id));
                    }}
                  >
                    {t('questionnaire.removeUser')}
                  </DropdownMenuItem>
                </EllipsisMenu>
              )}
            </Flex>
          ))}
        </Box>
      </Panel>
      {teamMemberModal.isOpen && (
        <SenderTeamUserModal
          companyId={currentCompanyId}
          // @ts-expect-error ts(2322) FIXME: Type 'SenderTeamMember | null' is not assignable to type 'SenderTeamMember | undefined'.
          teamMember={selectedTeamMember}
          existingUserIds={existingUserIds}
          close={teamMemberModal.close}
          onSave={onSaveTeamMember}
          onRemove={onRemoveTeamMember}
        />
      )}
    </>
  );
};
