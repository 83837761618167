import * as Layout from '../../Layout';
import { ContractDraftHeader } from './ContractDraftHeader';
import { ContractDraftDetails } from './ContractDraftDetails';
import { useContractId } from './contract';

export const ContractDraftDetailsPagePageContent = ({
  pageId,
}: {
  pageId?: string;
}) => {
  const tabId = 'details';
  const contractId = useContractId();

  return (
    <Layout.HeaderAndContent
      header={
        <ContractDraftHeader
          key={contractId}
          contractId={contractId}
          selectedTabId={tabId}
        />
      }
      content={
        <ContractDraftDetails
          contractId={contractId}
          pageId={pageId}
        />
      }
    />
  );
};
