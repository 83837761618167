import { Box, Flex, Text } from 'rebass/styled-components';
import { localeFormatNumber } from '@deepstream/utils';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { NumberStepperField } from '../form/NumberStepperField';
import { useCurrentUserLocale } from '../useCurrentUser';

interface Props {
  name?: string;
  value?: number;
  onChange?: (value: number) => void;
  hideDescription?: boolean;
}

export const DecimalPlacesControl = ({ name, value, onChange, hideDescription }: Props) => {
  const { t } = useTranslation('request');
  const locale = useCurrentUserLocale();
  // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
  const [field] = useField({ name });

  return (
    <Flex alignItems="flex-start" justifyContent="space-between">
      {!hideDescription && (
        <Flex flexDirection="column" justifyContent="center" sx={{ gap: 2 }}>
          <Text>
            {t('lineItems.decimalPlaces.label')}
          </Text>
          <Text color="subtext" fontSize={1}>
            {t('lineItems.decimalPlaces.description')}
          </Text>
        </Flex>
      )}
      <Flex alignItems="center" sx={{ gap: 2 }}>
        <NumberStepperField
          // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
          name={name}
          onChange={onChange}
          increment={1}
          maxValue={10}
          initialValue={value}
        />
        <Box width="120px">
          {typeof field.value === 'number' && localeFormatNumber(0, { locale, decimalPlaces: field.value })}
        </Box>
      </Flex>
    </Flex>
  );
};
