import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { map, isEqual, isEmpty } from 'lodash';
import * as yup from 'yup';
import { Flex, Text, Box } from 'rebass/styled-components';
import { Attachment, QuestionExchangeDefinition } from '@deepstream/common/rfq-utils';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton, EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { PanelHeader, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { DraftPanel } from './DraftPanel';
import { usePreventEnterKeyHandler } from '../../../usePreventEnterKeyHandler';
import { LeavePageModal } from '../../../draft/LeavePageModal';
import { useQuestionnaireTemplateActions, useQuestionnaireTemplateData, useQuestionnaireTemplateExchangeDefs, useQuestionnaireTemplateState } from './questionnaireTemplateUtils';
import { DraftEditQuestion } from './DraftEditQuestion';
import * as draft from './draftQuestionnaireTemplate';
import { Validation } from '../../../draft/validation';
import { QuestionsActions } from './QuestionsActions';
import { TextField } from '../../../form/TextField';
import { QuestionExchangeDefsGrid } from './QuestionExchangeDefsGrid';
import { useDraftQuestionnaireTemplateNavigation } from '../../../appNavigation';
import { FilesField } from '../../../form/FilesField';
import { QuestionnaireTemplateAttachment } from './QuestionnaireTemplateAttachment';
import { LabelConfigProvider } from '../../../LabelConfigProvider';
import { useUserFlags } from '../../../UserFlagsContext';

const PANEL_ID = 'questions';

const labelStyle = {
  fontSize: 2,
};

const useIsEditingQuestions = () => {
  // @ts-expect-error ts(2339) FIXME: Property 'editingPanelId' does not exist on type 'QuestionnaireTemplateStateContextType | undefined'.
  const { editingPanelId } = useQuestionnaireTemplateState();
  return editingPanelId === PANEL_ID;
};

const SectionPanelHeader = () => {
  const { t } = useTranslation('preQualification');
  // @ts-expect-error ts(2339) FIXME: Property 'isReview' does not exist on type 'QuestionnaireTemplateStateContextType | undefined'.
  const { isReview, editingPanelId, isLive } = useQuestionnaireTemplateState();
  const { startEditing } = useQuestionnaireTemplateActions();
  const draftNavigation = useDraftQuestionnaireTemplateNavigation();
  const { hasManagePreQualPermission } = useUserFlags();

  const isEditing = editingPanelId && editingPanelId === PANEL_ID;

  return (
    <PanelHeader heading={t('question_other')}>
      {!hasManagePreQualPermission || isEditing || isLive ? (
        null
      ) : isReview ? (
        <EditButton
          small
          type="button"
          variant="primary"
          onClick={() => draftNavigation.navigateToQuestions()}
        />
      ) : (
        <EditButton
          small
          onClick={() => { startEditing(PANEL_ID); }}
          data-test="edit-questions-button"
        />
      )}
    </PanelHeader>
  );
};

export const QuestionsSectionPanel = () => {
  const { t } = useTranslation('preQualification');
  const { stopEditing } = useQuestionnaireTemplateActions();
  const isEditing = useIsEditingQuestions();
  const exchangeDefs = useQuestionnaireTemplateExchangeDefs();
  const questionsRefs = useRef<Array<HTMLDivElement | null>>(Array(exchangeDefs.length).fill(null));
  const { instructions, instructionsAttachments } = useQuestionnaireTemplateData();
  const [saveSection] = draft.useSaveQuestionSection();

  const onKeyDown = usePreventEnterKeyHandler();
  const showValidationErrors = draft.useShowValidationErrors();

  return isEditing ? (
    <Formik<{
      instructions?: string;
      instructionsAttachments: Attachment[];
      exchangeDefs: QuestionExchangeDefinition[];
    }>
      validateOnBlur
      enableReinitialize
      initialValues={{
        instructions,
        instructionsAttachments,
        exchangeDefs,
      }}
      validationSchema={
        yup.object().shape({
          exchangeDefs: yup.array().of(
            yup.object(),
          ),
        })
      }
      onSubmit={async ({ exchangeDefs, instructions, instructionsAttachments }) => {
        await saveSection({
          instructions,
          instructionsAttachments,
          exchangeDefs,
        }, {
          onSuccess: stopEditing,
        });
      }}
    >
      {({ values, isSubmitting, isValid, dirty, resetForm, setFieldValue }) => (
        <Form style={{ width: '100%' }} onKeyDown={onKeyDown}>
          <DraftPanel panelId={PANEL_ID} heading={t('question_other')}>
            <SectionPanelHeader />
            <PanelDivider />
            <PanelPadding>
              <Stack gap="20px">
                <LabelConfigProvider
                  style={{ instructions: labelStyle, instructionsAttachments: labelStyle }}
                >
                  <TextField
                    name="instructions"
                    label={t('questionnaireTemplate.instructions')}
                    isMultiLine
                    rows={4}
                    placeholder={t('questionnaireTemplate.instructionsPlaceholder')}
                  />
                  <Box width="310px">
                    <FilesField
                      name="instructionsAttachments"
                      label={t('questionnaireTemplate.optionalDocumentUpload')}
                      max={1}
                      purpose="questionnaire"
                    />
                  </Box>
                </LabelConfigProvider>
              </Stack>
            </PanelPadding>
            <PanelDivider />
            <PanelPadding>
              <Stack gap="20px">
                {values.exchangeDefs.map((exchangeDef, index) => (
                  <DraftEditQuestion
                    key={exchangeDef._id}
                    ref={(el) => { questionsRefs.current[index] = el; }}
                    scrollToQuestion={(index: number) => {
                      questionsRefs.current[index]?.scrollIntoView?.({ block: 'center' });
                    }}
                    index={index}
                    exchangeDef={exchangeDef}
                  />
                ))}
              </Stack>

              <QuestionsActions
                triggerSx={{ mt: values.exchangeDefs.length ? '20px' : 0 }}
                initialExchangeDefIds={map(values.exchangeDefs, (exchangeDef) => exchangeDef._id)}
                onAddQuestions={(questionsExchangeDef) => {
                  setFieldValue(
                    'exchangeDefs',
                    [
                      ...values.exchangeDefs,
                      ...questionsExchangeDef,
                    ],
                  );
                }}
              />
            </PanelPadding>
            <PanelDivider />
            <PanelPadding>
              <Flex justifyContent="flex-end">
                <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
                <SaveButton
                  disabled={
                    isSubmitting ||
                    !dirty ||
                    !isValid ||
                    isEqual(
                      [...map(values.exchangeDefs, '_id'), values.instructions, values.instructionsAttachments],
                      [...map(exchangeDefs, '_id'), instructions, instructionsAttachments],
                    )
                  }
                />
              </Flex>
            </PanelPadding>
            <LeavePageModal />
          </DraftPanel>
        </Form>
      )}
    </Formik>
  ) : (
    <Validation
      values={{
        exchangeDefs,
      }}
      schema={showValidationErrors ? (
        yup.object().shape({
          exchangeDefs: yup
            .array(yup.object())
            .required().min(1, t('review.errors.minimumOneRequired')),
        })
      ) : (
        yup.mixed()
      )}
    >
      <DraftPanel panelId={PANEL_ID} heading={t('question_other')}>
        <SectionPanelHeader />
        <PanelDivider />
        <PanelPadding>
          <Stack gap={2}>
            <Text color="lightNavy" fontWeight={500} fontSize={2}>
              {t('questionnaireTemplate.instructions')}
            </Text>
            {instructions ? (
              <Text color="darkGray2" fontSize={1}>{instructions}</Text>
            ) : isEmpty(instructionsAttachments) ? (
              <Text fontSize={1} color="subtext">{t('questionnaireTemplate.noInstructions')}</Text>
            ) : (
              null
            )}
            {instructionsAttachments.map((attachment) => (
              <QuestionnaireTemplateAttachment
                key={attachment._id}
                attachment={attachment}
              />
            ))}
          </Stack>
        </PanelPadding>
        <PanelDivider />
        <QuestionExchangeDefsGrid />
      </DraftPanel>
    </Validation>
  );
};
