import { Company, isNonLinkedEvaluationPage, Live, LotIntentionStatus, ScoringType, SectionType } from '@deepstream/common/rfq-utils';
import { compact, first, identity, isEmpty, mapValues, pick, some } from 'lodash';
import { useRef, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { InlineButton } from '@deepstream/ui-kit/elements/button/InlineButton';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { assignSums } from '@deepstream/utils';
import { ComparisonControlsPanel } from '../ComparisonControlsPanel';
import * as rfx from '../../../../rfx';
import { EmptyTableMessage } from '../../../../ui/EmptyTableMessage';
import { EmptyGridPanel } from '../EmptyGridPanel';
import { useSelectView, View } from '../useSelectView';
import { useExchangeModalState, UseExchangeModalStateProps } from '../../../../useExchangeModalState';
import { useGridState } from '../useGridState';
import { RecipientIdProvider, useRfqId } from '../../../../useRfq';
import { ExchangeDefFieldValueProvider } from '../../../../ExchangeDefFieldValueContext';
import { NotifyEvaluatorsButton } from '../../../NewEvaluation/NotifyEvaluatorsButton';
import { SwitchToExchangeSettableProvider } from '../../../../ExchangeModal/SwitchToExchange';
import { useFilteredRowData } from '../useFilteredRowData';
import { EmptyGridMessagePanel } from '../EmptyGridMessagePanel';
import { RequestHooksProvider } from '../../RequestHooksProvider';
import { RfxExchangeModal } from '../../../../ExchangeModal/RfxExchangeModal';
import { EvaluationComparisonAutoAdvancer } from '../../../../ExchangeModal/EvaluationComparisonGridAutoAdvancer';
import { useExchangeModalPage } from '../useExchangeModalPage';
import { ExchangeSnapshot } from '../../../../types';
import { EvaluationComparisonGrid, useRowData } from './EvaluationComparisonGrid';
import { useEvaluationStats } from './useEvaluationStats';
import { EvaluationComparisonStats, useEvaluationCombinedStats } from './EvaluationComparisonStats';

const useFilteredColumnData = (
  selectedRequirementGroupItems: { value: string }[],
  selectedBidStatusItems: { value: string }[],
): Company[] => {
  const structure = rfx.useStructure<Live>();
  const recipients = rfx.useRecipients();

  return useMemo(() => {
    const selectedRequirementGroup = first(selectedRequirementGroupItems)?.value;

    const filterByRequirementGroup = selectedRequirementGroup && selectedRequirementGroup !== 'general'
      ? recipients => recipients.filter(recipient => {
        return structure.bidById[recipient._id]?.intentionStatusByLotId[selectedRequirementGroup] === LotIntentionStatus.BIDDING;
      })
      : identity;

    const selectedBidStatuses = selectedBidStatusItems.map(({ value }) => value);

    const filterByBidStatus = !isEmpty(selectedBidStatuses)
      // @ts-expect-error ts(2345) FIXME: Argument of type 'import("/Users/ahlsen/dev/deepstream/libs/shared/common/src/rfq-utils/types").UniversalBidStatus | null' is not assignable to parameter of type 'string'.
      ? recipients => recipients.filter(recipient => selectedBidStatuses.includes(structure.bidById[recipient._id].universalStatus))
      : identity;

    return filterByBidStatus(filterByRequirementGroup(recipients));
  }, [structure, recipients, selectedRequirementGroupItems, selectedBidStatusItems]);
};

const useIsEvaluationPageHidden = () => {
  const structure = rfx.useStructure();
  const hiddenPageIds = rfx.useHiddenPageIds();

  const evaluationPageId = structure.pages.find(
    page => isNonLinkedEvaluationPage(page),
  )?._id;

  if (!evaluationPageId) {
    return false;
  }

  return hiddenPageIds.includes(evaluationPageId);
};

const NoEvaluationPanel = ({
  navigateToTeam,
}: {
  navigateToTeam: () => void;
}) => {
  const { t } = useTranslation();

  const isEvaluationPageHidden = useIsEvaluationPageHidden();

  return (
    <EmptyGridPanel>
      <PanelPadding>
        <EmptyTableMessage
          header={isEvaluationPageHidden ? (
            <>
              <Icon icon="eye-slash" mr={2} />
              {t('request.comparison.cantViewContentHeader')}
            </>
          ) : (
            t('request.comparison.noEvaluationToShow')
          )}
          body={isEvaluationPageHidden ? (
            <>
              <Box mb={1}>
                {t('request.comparison.cantViewContentBody1')}
              </Box>
              <Trans i18nKey="request.comparison.cantViewContentBody2">
                Permissions can be updated by an owner on
                the <InlineButton onClick={navigateToTeam}>Team</InlineButton> tab.
              </Trans>
            </>
          ) : (
            t('request.comparison.noEvaluationInRequest')
          )}
        />
      </PanelPadding>
    </EmptyGridPanel>
  );
};

/**
 * Renders a comparison grid with controls displaying the recipients,
 * evaluation exchange definitions and evaluation exchanges of a live
 * request to buyers.
 */
export const EvaluationComparison = ({
  navigateToTeam,
  availableViews,
  selectView,
  isExpandedView,
  setIsExpandedView,
  updateCachedExchange,
}: {
  navigateToTeam: () => void;
  availableViews: {
    evaluation: boolean;
    'line-items': boolean;
    questions: boolean;
  };
  selectView: ReturnType<typeof useSelectView>;
  isExpandedView: boolean;
  setIsExpandedView: (isExpandedView: boolean) => void;
  updateCachedExchange?: (exchange: ExchangeSnapshot) => void;
}) => {
  const { t } = useTranslation('translation');
  const rfqId = useRfqId();
  const structure = rfx.useStructure();
  const rowData = useRowData(structure);
  const [exchangeModalProps, setExchangeModalProps] = useState<UseExchangeModalStateProps>({});
  const exchangeModal = useExchangeModalState(exchangeModalProps);

  const exchangeModalPage = useExchangeModalPage(structure, exchangeModal?.exchangeId);

  const { canManageEvaluation } = rfx.useRfxPermissions();
  const scoringType = rfx.useEvaluationScoringType();
  const hasEvaluationSection = some(
    structure.sectionById,
    { type: SectionType.EVALUATION },
  );

  const availableRequirementGroupIds = useMemo(() => {
    return compact(rowData.map(row => row.lotId));
  }, [rowData]);

  const gridState = useGridState(rfqId, View.EVALUATION, availableRequirementGroupIds);

  const disabled = !hasEvaluationSection || isEmpty(rowData);

  const stats = useEvaluationStats();

  const lotStatsByRecipientId = useMemo(() => {
    if (isEmpty(gridState.selectRequirementGroups.selectedItems)) {
      return {
        canEvaluateLots: Object.values(stats.canEvaluateByLotId).some(identity),
        statsByRecipientId: mapValues(
          stats.statsByLotIdByRecipientId,
          statsByLotId => {
            const flattenedStats = Object.values(statsByLotId);

            return assignSums(flattenedStats);
          },
        ),
      };
    }

    const selectedRequirementGroupIds = gridState.selectRequirementGroups.selectedItems
      .map(item => item.value);

    return {
      canEvaluateLots: selectedRequirementGroupIds.some(lotId => stats.canEvaluateByLotId[lotId]),
      statsByRecipientId: mapValues(
        stats.statsByLotIdByRecipientId,
        statsByLotId => {
          const flattenedStats = Object.values(
            pick(statsByLotId, selectedRequirementGroupIds),
          );

          return assignSums(flattenedStats);
        },
      ),
    };
  }, [stats, gridState.selectRequirementGroups.selectedItems]);

  const FooterProgress = useMemo(() => {
    const { canEvaluateLots, statsByRecipientId } = lotStatsByRecipientId;

    const combinedStats = {
      numActions: 0,
      numOwnActions: 0,
      numPendingActions: 0,
      numPendingOwnActions: 0,
      ...assignSums(Object.values(statsByRecipientId || {})),
    };

    return () => (
      <Flex><EvaluationComparisonStats canEvaluate={canEvaluateLots} stats={combinedStats} /></Flex>
    );
  }, [lotStatsByRecipientId]);

  const { canEvaluateAnyLot, combinedStats } = useEvaluationCombinedStats();

  const filteredRowData = useFilteredRowData(rowData, gridState.selectRequirementGroups.selectedItems);

  const filteredColumnData = useFilteredColumnData(
    gridState.selectRequirementGroups.selectedItems,
    gridState.selectBidStatus.selectedItems,
  );

  const gridRef = useRef<any>();
  useEffect(() => {
    gridRef.current?.resetAfterRowIndex(0);
    gridRef.current?.resetAfterColumnIndex(0);
  }, [structure, lotStatsByRecipientId, filteredRowData]);

  const rowDataExchangeIds = useMemo(() => {
    return filteredRowData?.flatMap(({ subRows }) => subRows.filter(subRow => !subRow.isSubHeader).map(({ _id }) => ({ exchangeId: _id })));
  }, [filteredRowData]);

  return (
    <SwitchToExchangeSettableProvider
      verticalTargets={rowDataExchangeIds}
      switchToExchange={(target) => {
        // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
        exchangeModal.setExchange({ exchangeId: target.exchangeId, recipientId: target.recipientId });
      }}
    >
      <ComparisonControlsPanel
        availableViews={availableViews}
        selectView={selectView}
        selectRowHeight={gridState.selectRowHeight}
        selectBidStatus={gridState.selectBidStatus}
        selectRecipientOrder={gridState.selectRecipientOrder}
        isExpandedView={isExpandedView}
        setIsExpandedView={setIsExpandedView}
        disabled={disabled}
        selectRequirementGroups={gridState.selectRequirementGroups}
        additionalHeaderContentLeft={
          <EvaluationComparisonStats
            canEvaluate={canEvaluateAnyLot}
            stats={combinedStats}
          />
        }
        additionalHeaderContentCenter={scoringType === ScoringType.INDIVIDUAL_SCORES && canManageEvaluation ? (
          <Box mr={2}>
            <NotifyEvaluatorsButton disabled={combinedStats.numPendingActions - combinedStats.numPendingOwnActions < 1} />
          </Box>
        ) : (
          null
        )}
      />
      {!hasEvaluationSection ? (
        <NoEvaluationPanel navigateToTeam={navigateToTeam} />
      ) : isEmpty(rowData) ? (
        <EmptyGridMessagePanel
          header={t('request.comparison.noEvaluationToShow')}
          body={t('request.comparison.allEvaluationCriteriaObsolete')}
        />
      ) : isEmpty(filteredRowData) ? (
        <EmptyGridMessagePanel
          header={t('request.comparison.noEvaluationToShowForLot')}
        />
      ) : (
        <ExchangeDefFieldValueProvider>
          <EvaluationComparisonGrid
            gridRef={gridRef}
            rowData={filteredRowData}
            columnData={filteredColumnData}
            stats={lotStatsByRecipientId}
            subRowHeight={gridState.subRowHeight}
            selectedRecipientSort={gridState.selectRecipientOrder.selectedItems[0]}
            collapsedRowIds={gridState.collapsedRowIds}
            setCollapsedRowIds={gridState.setCollapsedRowIds}
            setExchangeModalProps={setExchangeModalProps}
          />
        </ExchangeDefFieldValueProvider>
      )}
      {exchangeModalPage && exchangeModal.recipientId && (
        <RecipientIdProvider recipientId={exchangeModal.recipientId}>
          <rfx.PageProvider page={exchangeModalPage}>
            <RequestHooksProvider>
              <RfxExchangeModal
                {...exchangeModal}
                showRecipient
                showFooter
                canAdvanceHorizontally
                FooterProgress={FooterProgress}
                FooterAutoAdvancer={EvaluationComparisonAutoAdvancer}
                close={() => setExchangeModalProps({})}
                onUpdate={updateCachedExchange}
              />
            </RequestHooksProvider>
          </rfx.PageProvider>
        </RecipientIdProvider>
      )}
    </SwitchToExchangeSettableProvider>
  );
};
