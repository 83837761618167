import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import * as yup from 'yup';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useState } from 'react';
import { RadioField, Label } from './form/Field';
import { useMutation } from './useMutation';
import { useCurrentCompanyId } from './currentCompanyId';
import { ModalForm, ModalFormProps } from './ModalForm';
import * as rfx from './rfx';
import { AsyncFileDownloadDialog } from './AsyncFileDownloadDialog';
import { useToaster } from './toast';
import { useApi } from './api';

enum DownloadOption {
  ALL_FILES = 'allFiles',
  SECTION_ONLY = 'sectionOnly',
}

type SectionFilesDownloadModalProps =
  Omit<ModalFormProps, 'heading' | 'initialValues' | 'validationSchema' | 'onSubmit'> &
  {
    rfqId: string;
    sectionId: string;
  };

export const SectionFilesDownloadModal = ({
  rfqId,
  sectionId,
  onCancel,
  ...props
}: SectionFilesDownloadModalProps) => {
  const { t } = useTranslation();
  const api = useApi();
  const toaster = useToaster();
  const stageId = rfx.useStageId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const [isSuccess, setIsSuccess] = useState(false);

  const [requestSenderFilesZip] = useMutation(
    api.requestSenderFilesZip,
    {
      onSuccess: () => setIsSuccess(true),
      onError: () => toaster.error(t('request.download.toaster.downloadError')),
    },
  );

  return isSuccess ? (
    <AsyncFileDownloadDialog
      isOpen={props.isOpen}
      onOk={onCancel}
    />
  ) : (
    <ModalForm
      heading={t('general.downloadFiles')}
      initialValues={{
        downloadType: DownloadOption.SECTION_ONLY,
      }}
      validationSchema={yup.object({
        downloadType: yup.string().required(),
      })}
      submitLabel={t('general.downloadFiles')}
      disableSubmitIfNotDirty={false}
      onCancel={onCancel}
      onSubmit={async ({ downloadType }, { setSubmitting }) => {
        try {
          await requestSenderFilesZip({
            currentCompanyId,
            rfqId,
            sectionId: downloadType === DownloadOption.SECTION_ONLY ? sectionId : null,
            stageId,
          });
        } catch (error) {
          Sentry.captureException(error);
          setSubmitting(false);
        }
      }}
      {...props}
    >
      <Label label={t('general.downloadFilesFrom')}>
        <Stack gap={1}>
          <RadioField name="downloadType" label={t('general.thisSectionOnly')} value={DownloadOption.SECTION_ONLY} />
          <RadioField name="downloadType" label={t('general.allFiles')} value={DownloadOption.ALL_FILES} />
        </Stack>
      </Label>

      <MessageBlock variant="info" mt={0}>
        {stageId ? (
          t('request.documents.download.senderStageWarning')
        ) : (
          t('request.documents.download.senderWarning')
        )}
      </MessageBlock>
    </ModalForm>
  );
};
