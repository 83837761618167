import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass/styled-components';
import { IconValue } from '@deepstream/common';
import { BorderedIcon } from '@deepstream/ui-kit/elements/icon/BorderedIcon';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { truncateStyle } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { ObsoleteIcon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useField } from 'formik';
import { DisabledInputBox, InputPrefix } from '../ui/Input';
import { FieldContainer } from '../form/FieldContainer';
import { TextField } from '../form/TextField';

export const ObsoleteTextField = ({ required, name, label, inputStyle, prefix = undefined }) => {
  const { t } = useTranslation('translation');
  const id = useUniqueId();
  const [{ value }] = useField(name);

  return (
    <>
      {prefix && (
        <InputPrefix
          minWidth={40}
          style={{
            height: '40px',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderRight: 'lightGray',
            marginRight: '-4px',
            zIndex: 100,
          }}
        >
          {prefix}
        </InputPrefix>
      )}
      <FieldContainer
        htmlFor={id}
        label={label}
        showAsterisk={required}
        sx={{ width: '100%' }}
      >
        <DisabledInputBox
          id={id}
          sx={{ ...truncateStyle, inputStyle }}
        >
          <Tooltip content={t('general.obsolete') as string}>
            <ObsoleteIcon mr={2} />
          </Tooltip>
          {value || t('general.untitled')}
        </DisabledInputBox>
      </FieldContainer>
    </>
  );
};

export const DraftSectionEditPanelHeader = ({
  icon,
  isSectionObsolete,
}: {
  icon?: IconValue,
  isSectionObsolete?: boolean;
}) => {
  const { t } = useTranslation();

  const TitleComponent = isSectionObsolete ? ObsoleteTextField : TextField;

  return (
    <PanelPadding>
      <Flex flexDirection="column" sx={{ gap: 2 }}>
        <Flex alignItems="flex-end">
          {icon && <BorderedIcon icon={icon} mr="8px" mb="5px" />}
          <TitleComponent
            required
            name="section.name"
            label={t('general.title')}
            // quick fix: we can remove setting the inputStyle height once a line height is
            // set for Input
            inputStyle={{ height: 40 }}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
        </Flex>

        <Box ml="36px">
          <TextField
            isMultiLine
            hasDynamicHeight
            hideLabel
            minHeight={38}
            maxHeight={200}
            fontSize={1}
            name="section.description"
            placeholder={t('general.descriptionOptional')}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.stopPropagation();
              }
            }}
            disabled={isSectionObsolete}
          />
        </Box>
      </Flex>
    </PanelPadding>
  );
};
