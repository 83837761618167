import { useCallback } from 'react';
import { Draft, Stage } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { Box, Flex, FlexProps, Heading } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { DateFormat } from '@deepstream/utils';
import { Bold } from '../Bold';
import { LegacyEditStageButton } from './LegacyEditStageButton';
import { useDeleteStage, useSwapConsecutiveStages } from './useStageMutations';
import { useDeviceSize } from '../ui/useDeviceSize';
import { useCurrentDraft } from './useCurrentDraft';
import { Datetime2 } from '../Datetime';
import * as rfx from '../rfx';
import { StageName } from '../StageName';

const StageDeadline = ({ stage, ...props }: FlexProps & { stage: Stage<Draft> }) => {
  const { t } = useTranslation();
  const deadline = stage.completionDeadline
    ? <Datetime2 value={stage.completionDeadline} format={DateFormat.DD_MMM_YYYY_HH_MM_A} />
    : t('general.notSpecified');

  return (
    <Flex alignItems="center" mr={3} {...props}>
      <Icon icon="clock" regular fontSize={1} mr={1} />
      {deadline}
    </Flex>
  );
};

const StageVisibility = ({ stage, ...props }: FlexProps & { stage: Stage<Draft> }) => {
  const { t } = useTranslation();

  return (
    <Flex alignItems="center" {...props}>
      <Icon icon={stage.isPrivate ? 'eye-slash' : 'eye'} regular fontSize={1} mr={1} />
      {stage.isPrivate ? (
        t('request.stages.hidden')
      ) : (
        t('request.stages.visible')
      )}
    </Flex>
  );
};

type LegacyStageHeaderProps = {
  stage: Stage<Draft>;
  stageIndex: number;
  canEdit: boolean;
  editStage: () => void;
};

export const LegacyStageHeader = ({
  stage,
  stageIndex,
  canEdit,
  editStage,
}: LegacyStageHeaderProps) => {
  const { isExtraSmall } = useDeviceSize();
  const { mutationPending } = useCurrentDraft();
  const swapConsecutiveStagesMutation = useSwapConsecutiveStages();
  const deleteStageMutation = useDeleteStage();
  const { stages } = rfx.useStructure();

  const swapConsecutiveStages = useCallback(
    async (firstIndex, secondIndex) => swapConsecutiveStagesMutation(firstIndex, secondIndex),
    [swapConsecutiveStagesMutation],
  );

  const moveUp = useCallback(
    async () => swapConsecutiveStages(stageIndex - 1, stageIndex),
    [stageIndex, swapConsecutiveStages],
  );

  const moveDown = useCallback(
    async () => swapConsecutiveStages(stageIndex, stageIndex + 1),
    [stageIndex, swapConsecutiveStages],
  );

  const deleteStage = useCallback(
    async () => deleteStageMutation(stage),
    [stage, deleteStageMutation],
  );

  return (
    <PanelPadding>
      <Flex>
        <Box flex={1}>
          <Heading as="h3" mr={2}>
            <Truncate>
              <Bold>
                <StageName index={stageIndex} stage={stage} withPrefix />
              </Bold>
            </Truncate>
          </Heading>
          <Flex flexDirection={isExtraSmall ? 'column' : 'row'} color="subtext" fontSize={2}>
            <StageDeadline stage={stage} mt={1} />
            {stages.length > 1 && <StageVisibility stage={stage} mt={1} />}
          </Flex>
        </Box>
        {canEdit && (
          <LegacyEditStageButton
            stage={stage}
            stageIndex={stageIndex}
            disabled={mutationPending}
            editStage={editStage}
            deleteStage={deleteStage}
            moveUp={moveUp}
            moveDown={moveDown}
          />
        )}
      </Flex>
    </PanelPadding>
  );
};
