import { useTranslation } from 'react-i18next';
import { findIndex } from 'lodash';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { useSwitchToExchange } from './SwitchToExchange';

export const ExchangeSwitcher = ({
  exchangeId,
}: {
  exchangeId: string;
}) => {
  const { t } = useTranslation();
  const { switchToExchange, verticalTargets } = useSwitchToExchange();

  if (!verticalTargets) {
    return null;
  }

  const exchangeIndex = exchangeId ? findIndex(verticalTargets, { exchangeId }) : -1;

  const previousTarget = verticalTargets[exchangeIndex - 1];
  const nextTarget = verticalTargets[exchangeIndex + 1];

  return (
    <>
      <Tooltip
        content={t('request.exchange.previousExchange') as string}
      >
        <IconButton
          icon="chevron-up"
          color="subtext"
          fontSize={3}
          onClick={() => switchToExchange(previousTarget)}
          mr={2}
          disabled={!previousTarget}
        />
      </Tooltip>
      <Tooltip
        content={t('request.exchange.nextExchange') as string}
      >
        <IconButton
          icon="chevron-down"
          color="subtext"
          fontSize={3}
          onClick={() => switchToExchange(nextTarget)}
          mr={3}
          disabled={!nextTarget}
        />
      </Tooltip>
    </>
  );
};
