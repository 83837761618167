import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text, Box, TextProps } from 'rebass/styled-components';
import { isNil, omit, omitBy, pick, reverse, sortBy, toPairs } from 'lodash';
import { DateFormat, localeFormatDate, localeFormatNumber } from '@deepstream/utils';
import { renderSectionName, renderStageName, isQuestionResponseField } from '@deepstream/common/rfq-utils';
import { RequirementGroup, renderRequirementGroup } from '@deepstream/common/rfq-utils/auditTrail';
import { truncateStyle } from '@deepstream/ui-kit/elements/text/Truncate2';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { BLANK, LOCKED, UNKNOWN } from '@deepstream/common/constants';
import { ErrorPanel } from './ui/ErrorMessage';
import { LoadingPanel } from './ui/Loading';
import { RfqIdProvider, useAuditTrail, useLiveRfqStructure, useRfqId } from './useRfq';
import { useCurrentCompanyId } from './currentCompanyId';
import { BasicTableStyles } from './TableStyles';
import { Table } from './Table';
import { Datetime2 } from './Datetime';
import { Bold } from './Bold';
import { Locked } from './lock';
import useDownload from './useDownload';
import { useCurrentUserLocale } from './useCurrentUser';
import { QuestionResponse } from './QuestionResponse';
import * as rfx from './rfx';

const FieldLabel = (props: TextProps) => (
  <Text fontSize={1} color="subtext" mr={1} {...props} />
);

const FieldValue = (props: TextProps) => (
  <Text fontSize={1} mr={2} {...props} />
);

const fieldsToTranslate = ['previousValue', 'currentValue', 'action', 'type', 'exchangeType', 'changeType', 'response',
  'allowSupplierDocumentUpload', 'option', 'isObsolete', 'previousAction', 'timeUnit', 'requestStatus', 'bidStatus'];

const shouldTranslate = (auditEvent: any, fieldKey: string) => {
  // `previousValue` and `updatedValue` refer to the `fieldUpdated` field, so we must check against it instead.
  // eg if `fieldUpdated` is `description` we don't need to translate the value, but for `option` we need to.
  if (auditEvent.type === 'exchange-updated' && ['previousValue', 'updatedValue'].includes(fieldKey)) {
    const { fieldUpdated } = auditEvent.fields;

    return fieldsToTranslate.includes(fieldUpdated);
  }

  if (auditEvent.type === 'auction-lot-bidding-extended' && fieldKey === 'source') {
    return true;
  }

  return fieldsToTranslate.includes(fieldKey);
};

const shouldShowRelativeDuration = (eventType: string, fieldKey: string) => {
  return eventType === 'auction-lot-bidding-extended' && ['previousDeadline', 'updatedDeadline'].includes(fieldKey);
};

const renderDurationSeconds = (value: number, locale: string) => {
  return localeFormatDate((value * 1000), DateFormat.MM_SS, { locale });
};

const renderDate = (value: string, locale: string) => {
  return localeFormatDate(new Date(value), DateFormat.DD_MMM_YYYY, { locale });
};

const CustomRowCells = ({
  row,
}) => {
  const { t } = useTranslation();
  const locale = useCurrentUserLocale();

  const { meta, fields, type, hiddenFields = [] } = row.original;

  const {
    fieldsPairs,
    replyFieldPairs,
  } = React.useMemo(() => {
    const hasLockedFieldResponse = (
      fields?.previousFieldResponse === LOCKED ||
      fields?.updatedFieldResponse === LOCKED ||
      fields?.fieldResponse === LOCKED
    );

    const keysToExpand = hasLockedFieldResponse
      ? []
      : ['previousFieldResponse', 'updatedFieldResponse', 'fieldResponse'];

    const fieldsPairs = toPairs(omit(fields, [...hiddenFields, ...keysToExpand])) as [string, any][];
    const replyFieldPairs = toPairs(pick(fields ?? {}, keysToExpand));

    return {
      fieldsPairs,
      replyFieldPairs,
    };
  }, [fields, hiddenFields]);

  return (
    <td style={{ height: 'auto' }}>
      <Box py="12px" height={!row.isExpanded ? '82px' : ''}>
        <Flex justifyContent="space-between">
          <Bold>
            {type !== 'cannot-view' ? (
              t(`request.audit.eventLabel.${type}`)
            ) : (
              <IconText
                icon="eye-slash"
                text={t(`request.audit.eventLabel.${type}`)}
                gap={1}
                color="subtext"
                iconFontWeight="normal"
              />
            )}
          </Bold>
          <Text fontSize={1} color={type !== 'cannot-view' ? 'text' : 'subtext'}>
            <Datetime2 value={meta.timestamp} format={DateFormat.DD_MMM_YYYY_HH_MM_A} />
          </Text>
        </Flex>
        {type !== 'cannot-view' ? (
          <>
            <Box mt={1} fontSize={1}>
              {meta.user.name ? (
                <>
                  {t('general.by')}
                  {' '}
                  <Bold>{meta.user.name}</Bold>
                </>
              ) : null}
              {meta.company.name ? (
                <>
                  {' '}
                  {t('general.at')}
                  {' '}
                  <Bold>{meta.company.name}</Bold>
                </>
              ) : null}
              {meta.role ? (
                <>
                  {' '}
                  {t('request.audit.asRole')}
                  {' '}
                  <Bold>{t(`request.audit.role.${meta.role}`)}</Bold>
                </>
              ) : null}
            </Box>
            <Flex alignItems="flex-end">
              {row.isExpanded ? (
                <Box flex={1} mt="3px" mr={3}>
                  {fieldsPairs.map(([key, value]) => {
                    const isAuctionLineItemKey = !!key.match(/lineItem\d+\w+/g)?.length;
                    // @ts-expect-error ts(2531) FIXME: Object is possibly 'null'.
                    const index = isAuctionLineItemKey ? key.match(/\d/g).join('') : null;
                    let tKey = key;

                    if (isAuctionLineItemKey) {
                      tKey = key.split(/\d+/).join('');
                    }
                    return (
                      <Flex key={key} alignItems="flex-start" mt={1} mb={0}>
                        <FieldLabel width="145px" mr="12px">
                          {isAuctionLineItemKey ? (
                            t(`request.audit.fieldLabel.${tKey}`, { index })
                          ) : key === 'description' && fields.descriptionLabel ? (
                            `${fields.descriptionLabel} (${t(`request.audit.fieldLabel.${key}`)})`
                          ) : (
                            t(`request.audit.fieldLabel.${key}`)
                          )}
                        </FieldLabel>
                        <FieldValue width="300px" sx={{ whiteSpace: 'pre-wrap' }}>
                          {value === LOCKED ? (
                            <Locked gap={1} isInline />
                          ) : value === BLANK ? (
                            `[${t('request.audit.blank')}]`
                          ) : value === UNKNOWN ? (
                            `[${t('request.audit.unknown')}]`
                          ) : key === 'sectionName' ? (
                            renderSectionName({ type: fields.sectionType, name: value }, t)
                          ) : key === 'stageName' ? (
                            renderStageName({ type: fields.stageType, name: value } as any, t)
                          ) : key === 'previousStageName' ? (
                            renderStageName({ type: fields.previousStageType, name: value } as any, t)
                          ) : key === 'newStageName' ? (
                            renderStageName({ type: fields.newStageType, name: value } as any, t)
                          ) : key === 'requirementGroup' ? (
                            renderRequirementGroup(value as RequirementGroup, t)
                          ) : ['previousLotIntention', 'updatedLotIntention'].includes(key) ? (
                            t(`request.lots.lotIntentionStatus.${value}.status`)
                          ) : key === 'fieldUpdated' ? (
                            // The `fieldUpdated` field is special because
                            // its value corresponds to the field labels
                            t(`request.audit.fieldLabel.${value.toString()}`)
                          ) : key === 'feeType' ? (
                            t(`request.vesselPricing.fees.feeType.${value.toString()}`)
                          ) : key === 'intervalType' ? (
                            t(`request.vesselPricing.intervalType.${value.toString()}`)
                          ) : key === 'exchangeSource' ? (
                            t(`request.chats.exchangeSource.${value}`)
                          ) : key === 'submissionMethod' ? (
                            t(`request.audit.submissionMethod.${value}`)
                          ) : shouldTranslate(row.original, key) ? (
                            t(`request.audit.types.${value.toString()}`)
                          ) : isQuestionResponseField(key) ? (
                            <QuestionResponse
                              response={value}
                              exchangeDef={fields.exchangeDef}
                              isMultiLine
                            />
                          ) : shouldShowRelativeDuration(type, key) ? (
                            renderDurationSeconds(value, locale)
                          ) : key === 'failureReason' ? (
                            t(`request.auction.bidRejectionReasons.${value.toString()}`)
                          ) : isAuctionLineItemKey && tKey === 'lineItemPrice' ? (
                            localeFormatNumber(value.price, { locale, decimalPlaces: value.decimalPlaces ?? 2 })
                          ) : (
                            value
                          )}
                        </FieldValue>
                      </Flex>
                    );
                  })}
                  {replyFieldPairs.map(([key, value]) => {
                    const fieldEntries = Object.entries<any>(value);

                    return fieldEntries.map(([fieldId, fieldValue], index) => {
                      const fieldLabel = fieldValue.label || t(`request.fields.predefinedFieldLabel.${fieldId.split(':')[0]}`);

                      return (
                        <Flex key={`${key}${index}`} alignItems="flex-start" mt={1} mb={0}>
                          <FieldLabel width="145px" mr="12px">
                            {key === 'previousFieldResponse' ? (
                              t('request.audit.previousLabel', { value: fieldLabel })
                            ) : key === 'updatedFieldResponse' ? (
                              t('request.audit.updatedLabel', { value: fieldLabel })
                            ) : (
                              fieldLabel
                            )}
                          </FieldLabel>
                          <FieldValue width="300px" sx={{ whiteSpace: 'pre-wrap' }}>
                            {fieldValue.value === BLANK ? (
                              t('request.audit.blank')
                            ) : fieldValue.type === 'date' ? (
                              renderDate(fieldValue.value, locale)
                            ) : fieldValue.type === 'price' ? (
                              localeFormatNumber(
                                fieldValue.value,
                                {
                                  locale,
                                  decimalPlaces: fieldValue.decimalPlaces ?? 2,
                                })
                            ) : (
                              fieldValue.value
                            )}
                          </FieldValue>
                        </Flex>
                      );
                    });
                  })}
                </Box>
              ) : (
                <Box flex={1} mr={3} style={truncateStyle}>
                  {fieldsPairs.map(([key, value]) => {
                    const isAuctionLineItemKey = !!key.match(/lineItem\d+\w+/g)?.length;
                    // @ts-expect-error ts(2531) FIXME: Object is possibly 'null'.
                    const index = isAuctionLineItemKey ? key.match(/\d/g).join('') : null;
                    let tKey = key;

                    if (isAuctionLineItemKey) {
                      tKey = key.split(/\d+/).join('');
                    }

                    return (
                      <React.Fragment key={key}>
                        <FieldLabel as="span">
                          {isAuctionLineItemKey ? (
                            t(`request.audit.fieldLabel.${tKey}`, { index })
                          ) : key === 'description' && fields.descriptionLabel ? (
                            `${fields.descriptionLabel} (${t(`request.audit.fieldLabel.${key}`)})`
                          ) : (
                            t(`request.audit.fieldLabel.${key}`)
                          )}
                        </FieldLabel>
                        <FieldValue as="span">
                          {value === LOCKED ? (
                            <Locked gap={1} isInline />
                          ) : value === BLANK ? (
                            `[${t('request.audit.blank')}]`
                          ) : value === UNKNOWN ? (
                            `[${t('request.audit.unknown')}]`
                          ) : key === 'sectionName' ? (
                            renderSectionName({ type: fields.sectionType, name: value } as any, t)
                          ) : key === 'stageName' ? (
                            renderStageName({ type: fields.stageType, name: value } as any, t)
                          ) : key === 'previousStageName' ? (
                            renderStageName({ type: fields.previousStageType, name: value } as any, t)
                          ) : key === 'newStageName' ? (
                            renderStageName({ type: fields.newStageType, name: value } as any, t)
                          ) : key === 'requirementGroup' ? (
                            renderRequirementGroup(value as RequirementGroup, t)
                          ) : ['previousLotIntention', 'updatedLotIntention'].includes(key) ? (
                            t(`request.lots.lotIntentionStatus.${value}.status`)
                          ) : key === 'fieldUpdated' ? (
                            // The `fieldUpdated` field is special because
                            // its value corresponds to the field labels
                            t(`request.audit.fieldLabel.${value.toString()}`)
                          ) : key === 'feeType' ? (
                            t(`request.vesselPricing.fees.feeType.${value.toString()}`)
                          ) : key === 'intervalType' ? (
                            t(`request.vesselPricing.intervalType.${value.toString()}`)
                          ) : key === 'submissionMethod' ? (
                            t(`request.audit.submissionMethod.${value}`)
                          ) : key === 'exchangeSource' ? (
                            t(`request.chats.exchangeSource.${value}`)
                          ) : shouldTranslate(row.original, key) ? (
                            t(`request.audit.types.${value.toString()}`)
                          ) : isQuestionResponseField(key) ? (
                            <QuestionResponse
                              response={value}
                              exchangeDef={fields.exchangeDef}
                            />
                          ) : shouldShowRelativeDuration(type, key) ? (
                            renderDurationSeconds(value, locale)
                          ) : key === 'failureReason' ? (
                            t(`request.auction.bidRejectionReasons.${value.toString()}`)
                          ) : isAuctionLineItemKey && tKey === 'lineItemPrice' ? (
                            localeFormatNumber(value.price, { locale, decimalPlaces: value.decimalPlaces ?? 2 })
                          ) : typeof value === 'boolean' ? (
                            value ? t('general.yes') : t('general.no')
                          ) : (
                            value
                          )}
                        </FieldValue>
                      </React.Fragment>
                    );
                  })}
                  {replyFieldPairs.map(([key, value]) => {
                    const fieldEntries = Object.entries<any>(value);

                    return fieldEntries.map(([fieldId, fieldValue], index) => {
                      const fieldLabel = fieldValue.label || t(`request.fields.predefinedFieldLabel.${fieldId.split(':')[0]}`);

                      return (
                        <React.Fragment key={`${key}${index}`}>
                          <FieldLabel as="span">
                            {key === 'previousFieldResponse' ? (
                              t('request.audit.previousLabel', { value: fieldLabel })
                            ) : key === 'updatedFieldResponse' ? (
                              t('request.audit.updatedLabel', { value: fieldLabel })
                            ) : (
                              fieldLabel
                            )}
                          </FieldLabel>
                          <FieldValue as="span">
                            {fieldValue.value === BLANK ? (
                              t('request.audit.blank')
                            ) : fieldValue.type === 'date' ? (
                              renderDate(fieldValue.value, locale)
                            ) : fieldValue.type === 'price' ? (
                              localeFormatNumber(
                                fieldValue.value,
                                {
                                  locale,
                                  decimalPlaces: fieldValue.decimalPlaces ?? 2,
                                })
                            ) : (
                              fieldValue.value
                            )}
                          </FieldValue>
                        </React.Fragment>
                      );
                    });
                  })}
                </Box>
              )}
              {Boolean(fieldsPairs.length) && (
                <Box flex="0 0 auto" mt="-6px">
                  <Button
                    small
                    variant="secondary-outline"
                    iconLeft={row.isExpanded ? 'chevron-up' : 'chevron-down'}
                    onClick={() => row.toggleRowExpanded()}
                  />
                </Box>
              )}
            </Flex>
          </>
        ) : (
          <Box mt={1}>
            <Text fontSize={1} color="subtext">
              {t('request.audit.pagePermissionPlaceholder')}
            </Text>
          </Box>
        )}
      </Box>
    </td>
  );
};

const AuditTrailTable = ({ audit }: { audit: any }) => {
  const sortedAudit = React.useMemo(
    () => reverse(sortBy(audit, 'meta.timestamp')),
    [audit],
  );

  const columns = React.useMemo(
    () => [{ id: 'custom' }],
    [],
  );

  return (
    <BasicTableStyles hoverBackgroundColor="lightGray6" hoverCursor="default">
      <Table
        columns={columns}
        data={sortedAudit}
        isPaginated
        initialPageSize={10}
        hideHeader
        CustomRowCells={CustomRowCells}
        hasStaticHeight={false}
        enforcePagination
        minCellHeight={58}
        smallPageControls
      />
    </BasicTableStyles>
  );
};

const AuditTrail = ({ audit, recipientId }: { audit: any; recipientId?: string }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const download = useDownload();
  const locale = useCurrentUserLocale();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqId = useRfqId({ required: true });
  const rfxPermissions = rfx.useRfxPermissions();

  const downloadAudit = React.useCallback(
    async () => {
      const queryParams = new URLSearchParams(omitBy(
        {
          recipientId,
          locale,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        } as Record<string, string>,
        isNil,
      ));

      setIsLoading(true);

      try {
        await download(`/download/company/${currentCompanyId}/rfq/${rfqId}/newAudit?${queryParams.toString()}`);
      } catch (error) {} // eslint-disable-line no-empty

      setIsLoading(false);
    },
    [currentCompanyId, rfqId, recipientId, download, locale],
  );

  return (
    <Panel mb="40px">
      <Flex alignItems="center" justifyContent="space-between" px="20px" py={3} height="60px">
        <Text fontSize={2} fontWeight={500} mb="1px">
          {audit.length}
          {' '}
          {audit.length === 1 ? (
            t('general.event', { count: 1 })
          ) : (
            t('general.event_other')
          )}
        </Text>
        {rfxPermissions.canDownloadReports && (
          <Button
            iconLeft="download"
            variant="secondary"
            small
            disabled={isLoading}
            onClick={downloadAudit}
          >
            {t('request.audit.downloadReportXlsx')}
          </Button>
        )}
      </Flex>
      <PanelDivider />
      <AuditTrailTable audit={audit} />
    </Panel>
  );
};

export const RfxAuditTrailContainer: React.FC<{
  rfqId: string;
  recipientId?: string;
}> = ({
  rfqId,
  recipientId,
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const {
    data: audit,
    isLoading: isLoadingAudit,
    isError: isErrorAudit,
    isSuccess: isSuccessAudit,
    isLocked: isLockedAudit,
  } = useAuditTrail({
    rfqId,
    currentCompanyId,
    recipientId,
  });

  const {
    data: structure,
    isLoading: isLoadingStructure,
    isError: isErrorStructure,
    isSuccess: isSuccessStructure,
  } = useLiveRfqStructure({
    rfqId,
    recipientId,
  });

  return (
    <RfqIdProvider rfqId={rfqId}>
      {isLoadingAudit || isLoadingStructure ? (
        <LoadingPanel />
      ) : isErrorAudit ? (
        isLockedAudit ? (
          <MessageBlock variant="info">
            {t('pageTemporarilyNotAvailable.auditTrailMessage')}
          </MessageBlock>
        ) : (
          <ErrorPanel error={t('request.errors.getAudit')} />
        )
      ) : isErrorStructure ? (
        <ErrorPanel error={t('request.errors.getRequest')} />
      ) : isSuccessAudit && isSuccessStructure && audit && structure ? (
        <rfx.StructureProvider structure={structure}>
          <AuditTrail audit={audit} recipientId={recipientId} />
        </rfx.StructureProvider>
      ) : (
        null
      )}
    </RfqIdProvider>
  );
};
