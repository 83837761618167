import { useTranslation } from 'react-i18next';
import { GridMenuItem, GridMenu } from '@deepstream/ui-kit/grid/EditableGrid/GridMenu';

export const QuestionGridMenu = ({
  onEdit,
  onArchive,
}: {
  onEdit: () => void;
  onArchive: () => void;
}) => {
  const { t } = useTranslation('preQualification');

  return (
    <GridMenu>
      <GridMenuItem
        icon="pencil"
        onSelect={onEdit}
      >
        {t('editQuestion')}
      </GridMenuItem>
      <GridMenuItem
        icon="folder-open"
        onSelect={onArchive}
        iconRegular
      >
        {t('questionsPanel.archiveQuestion')}
      </GridMenuItem>
    </GridMenu>
  );
};
