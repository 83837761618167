import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { ContractStatus } from '@deepstream/common/contract';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { noop } from 'lodash';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useLocalStorageState } from '../../useLocalStorageState';
import { ContractsReportingDataContext, useContractsReporting } from './useContractsReporting';
import { useCurrentUser } from '../../useCurrentUser';
import { useCompanyFeatureFlags } from '../../companyFeatureFlags';
import { HiddenPagePlaceholder } from '../../HiddenPagePlaceholder';
import * as dashboard from './dashboard';
import { CurrencySelectFieldBase } from '../../form/CurrencySelectField';
import { ContractStatusStatPanel } from './ContractStatusStatPanel';
import { TotalContractsByProductOrService } from './TotalContractsByProductOrService';
import { CurrencyCodeProvider } from '../../ui/Currency';
import { ContractsValueByProductOrService } from './ContractsValueByProductOrService';
import { CounterpartyStats } from './CounterpartyStats';
import { UserStats } from './UserStats';
import { BlurredPagePlaceholderModal } from './BlurredPagePlaceholderModal';
import { ExpiringContracts } from './ExpiringContracts';

export const ContractsReportingContent = ({ navigateToContract }) => {
  const { t } = useTranslation(['reporting', 'translation']);
  const currentUser = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const [currency, setCurrency] = useLocalStorageState<string>({
    key: `${currentCompanyId}.${currentUser._id}.reporting.currency`,
    defaultValue: 'USD',
  });

  const companyFeatureFlags = useCompanyFeatureFlags();
  const userHasContractsReportingAccess = currentUser.roles[currentCompanyId].accessReportingForContracts;
  const hasAccessToData = userHasContractsReportingAccess &&
    companyFeatureFlags.managementReportingEnabled &&
    companyFeatureFlags.contractManagementEnabled;
  const { data } = useContractsReporting(currentCompanyId, currency, hasAccessToData);

  const contextValue = useMemo(
    () => ({ data: data ?? {} }),
    [data],
  );

  return (
    <ContractsReportingDataContext.Provider value={contextValue}>
      <CurrencyCodeProvider code={currency}>
        <Stack gap={5} mb={4}>
          <dashboard.Section heading={t('contractVolume.heading')}>
            <Box
              width="100px"
              sx={{ position: 'absolute', right: 0, top: '5px' }}
              data-test="currency-dropdown"
            >
              <CurrencySelectFieldBase
                value={currency}
                onChange={currency => setCurrency(currency)}
              />
            </Box>
            {data && (
              <dashboard.Row sx={{ gap: '80px' }}>
                <dashboard.Column>
                  <dashboard.Row sx={{ gap: 3 }}>
                    <ContractStatusStatPanel type="volume" />
                    <ContractStatusStatPanel type="volume" status={ContractStatus.NEGOTIATION} />
                  </dashboard.Row>
                  <dashboard.Row sx={{ gap: 3 }}>
                    <ContractStatusStatPanel type="volume" status={ContractStatus.AGREED} />
                    <ContractStatusStatPanel type="volume" status={ContractStatus.ACTIVE} />
                  </dashboard.Row>
                </dashboard.Column>
                <dashboard.Column flex={1}>
                  <TotalContractsByProductOrService />
                </dashboard.Column>
              </dashboard.Row>
            )}
          </dashboard.Section>
          <dashboard.Section heading={t('contractValue.heading')}>
            {data && (
              <dashboard.Row sx={{ gap: '80px' }}>
                <dashboard.Column>
                  <dashboard.Row sx={{ gap: 3 }}>
                    <ContractStatusStatPanel type="value" />
                    <ContractStatusStatPanel type="value" status={ContractStatus.NEGOTIATION} />
                  </dashboard.Row>
                  <dashboard.Row sx={{ gap: 3 }}>
                    <ContractStatusStatPanel type="value" status={ContractStatus.AGREED} />
                    <ContractStatusStatPanel type="value" status={ContractStatus.ACTIVE} />
                  </dashboard.Row>
                </dashboard.Column>
                <dashboard.Column flex={1}>
                  <ContractsValueByProductOrService />
                </dashboard.Column>
              </dashboard.Row>
            )}
          </dashboard.Section>
          <dashboard.Section heading={t('contracts.teamProductivity')}>
            {data && (
              <dashboard.Row>
                <UserStats />
              </dashboard.Row>
            )}
          </dashboard.Section>
          <dashboard.Section heading={t('contracts.counterparty')}>
            {data && (
              <dashboard.Row>
                <CounterpartyStats />
              </dashboard.Row>
            )}
          </dashboard.Section>
          <dashboard.Section heading={t('contracts.expiringContracts')}>
            {data && (
              <dashboard.Row>
                <ExpiringContracts navigateToContract={navigateToContract} />
              </dashboard.Row>
            )}
          </dashboard.Section>
        </Stack>
        <Text fontSize={1} color="subtext" sx={{ textTransform: 'uppercase' }} mt={4}>
          {t('contractsTotalDisclaimer')}
        </Text>
      </CurrencyCodeProvider>
    </ContractsReportingDataContext.Provider>
  );
};

export const ContractsReporting = ({
  navigateToTeam,
  navigateToContract,
}: {
  navigateToTeam: () => void;
  navigateToContract: (contract: { _id: string }) => void;
}) => {
  const { t } = useTranslation(['reporting', 'translation']);
  const currentUser = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const companyFeatureFlags = useCompanyFeatureFlags();
  const hasAccessToReportingForContracts = currentUser.roles[currentCompanyId].accessReportingForContracts;

  const isReportingEnabled = companyFeatureFlags.managementReportingEnabled;
  const isContractManagementEnabled = companyFeatureFlags.contractManagementEnabled;

  if (!isContractManagementEnabled || !isReportingEnabled) {
    return (
      <>
        <ContractsReportingContent navigateToContract={noop} />
        <BlurredPagePlaceholderModal type="contracts" isOpen />
      </>
    );
  }

  if (!hasAccessToReportingForContracts) {
    return (
      <HiddenPagePlaceholder
        subtitle={t('insufficientCompanyPermissions')}
        hasCta={true}
        navigateToTeam={navigateToTeam}
      />
    );
  }

  return (
    <ContractsReportingContent navigateToContract={navigateToContract} />
  );
};
