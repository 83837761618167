import { useCallback } from 'react';

export const useIntercomTrackEvent = ({ eventName, showChat }: { eventName: string; showChat?: boolean }) => {
  return useCallback(() => {
    if (showChat) {
      (window as any).Intercom?.('show');
    }

    (window as any).Intercom?.('trackEvent', eventName);
  }, [eventName, showChat]);
};
