import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type MessageAction = 'sendGeneralMessage' | 'sendIndividualMessage' | 'sendNoMessage';

export const messageActions = [
  'sendGeneralMessage',
  'sendIndividualMessage',
  'sendNoMessage',
];

export const iconConfigByMessageAction = {
  sendGeneralMessage: {
    icon: 'envelope',
    regular: true,
  },
  sendIndividualMessage: {
    icon: 'pencil',
    regular: true,
  },
  sendNoMessage: {
    icon: 'xmark',
  },
};

export const useMessageActionOptions = () => {
  const { t } = useTranslation('translation');

  return useMemo(() => {
    return messageActions.map(action => ({
      value: action,
      label: (
        <>
          <Icon {...iconConfigByMessageAction[action]} mr={2} />
          {t(`request.awardFlow.steps.chooseMultiSupplierCustomMessageMethods.actions.${action}.label`)}
        </>
      ),
      description: t(`request.awardFlow.steps.chooseMultiSupplierCustomMessageMethods.actions.${action}.description`),
      hasSeparatorAbove: action === 'sendNoMessage',
    }));
  }, [t]);
};
