import { LineItemExchangeDefinition, getExchangeDefFieldSourceKey, getExchangeDefFieldValue, isDefinitionField, FieldConfig } from '@deepstream/common/rfq-utils';
import { useState } from 'react';
import * as React from 'react';
import { parse, ParseResult } from 'papaparse';
import { isEmpty, first, compact, set, cloneDeep, every, sumBy, omitBy, isUndefined, uniq, keyBy, assignWith } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Text } from 'rebass/styled-components';
import { FieldType } from '@deepstream/common/exchangesConfig';
import { useField } from 'formik';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { usePopover } from '@deepstream/ui-kit/elements/popup/usePopover';
import { Button, ButtonProps, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter } from '@deepstream/ui-kit/elements/popup/Modal';
import { Dialog, DialogProps } from '@deepstream/ui-kit/elements/popup/Dialog';
import { useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { ACTION_COLUMN_WIDTH, DEFAULT_EDITABLE_DATA_CELL_WIDTH, EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { ReadOnlyGrid } from '@deepstream/ui-kit/grid/EditableGrid/ReadOnlyGrid';
import { DefaultEditableGridStyles } from '@deepstream/ui-kit/grid/EditableGrid/EditableGridStyles';
import { withProps } from '@deepstream/ui-utils/withProps';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { FileList } from '../ui/FileList';
import { ValidationContext, ValidationResult } from './validation';
import { fastFakeUploadFn } from '../ui/fakeUploadFn';
import { ErrorMessage } from '../ui/ErrorMessage';
import { FieldContainer } from '../form/FieldContainer';
import { createLineItem } from './exchangeDefs';
import { useConfigurableExchangeDefFieldColumns } from '../ui/ExchangeDefsGrid/useConfigurableFieldColumns';
import { DisabledHeaderWithFieldTypeIcon, SimpleHeader } from '../ui/ExchangeDefsGrid/header';
import {
  BulkUploadDescriptionValueCell,
  BulkUploadDateValueCell,
  BulkUploadNumberValueCell,
  BulkUploadTextValueCell,
  BulkUploadUnspscCodeValueCell,
  RowNumberWarningCell,
} from '../ui/ExchangeDefsGrid/validationAwareValueCell';
import { parseCellValue } from '../ui/ExchangeDefsGrid/utils';
import { Switch } from '../ui/Switch';
import { useApi } from '../api';
import { BulkUploadFieldMappingPopover } from './BulkUploadFieldMappingPopover';
import { useConfirmDialog } from '../ui/useModalState';
import { useBulkUploadAutoFieldsMapping } from './useBulkUploadAutoFieldsMapping';
import { useModelSizeLimits } from '../modelSizeLimits';
import { BulkUploadDateFormatPopover, convertDateFormat } from './BulkUploadDateFormatPopover';

const isEditingSupplierExchangeDefs = false;
const isSender = true;

const frozenLeftColumnIds = ['rowNumber', 'description'];

const MapFieldsButton = React.forwardRef((props: ButtonProps, forwardedRef) => {
  const { t } = useTranslation('translation');

  return (
    <Button
      ref={forwardedRef}
      type="button"
      variant="secondary"
      small
      iconLeft="wrench"
      iconRight="caret-down"
      {...props}
    >
      {t('request.lineItems.bulkUploadModal.mapFields')}
    </Button>
  );
});

const DateFormatButton = React.forwardRef((props: ButtonProps, forwardedRef) => {
  const { t } = useTranslation('translation');

  return (
    <Button
      ref={forwardedRef}
      type="button"
      variant="secondary"
      small
      iconLeft="calendar"
      iconRight="caret-down"
      {...props}
    >
      {t('request.lineItems.bulkUploadModal.dateFormat')}
    </Button>
  );
});

const UploadButton = (props: ButtonProps) => {
  const { t } = useTranslation('translation');

  return (
    <Button
      type="button"
      {...props}
    >
      {t('request.lineItems.bulkUploadModal.upload')}
    </Button>
  );
};

const ImportButton = (props: ButtonProps) => {
  const { t } = useTranslation('translation');

  return (
    <Button
      type="submit"
      {...props}
    >
      {t('request.lineItems.bulkUploadModal.import')}
    </Button>
  );
};

const ConfirmCloseDialog = (props: Pick<DialogProps, 'onOk' | 'onCancel' | 'isOpen'>) => {
  const { t } = useTranslation();

  return (
    <Dialog
      heading={t('request.lineItems.bulkUploadModal.cancelImportTitle')}
      body={
        <MessageBlock variant="warn" my={2}>
          {t('request.lineItems.bulkUploadModal.yourDataWillBeDiscarded')}
        </MessageBlock>
      }
      style={{ content: { width: '500px' } }}
      okButtonText={t('request.lineItems.bulkUploadModal.cancelImport')}
      cancelButtonText={t('request.lineItems.bulkUploadModal.goBack')}
      showCloseIcon
      {...props}
    />
  );
};

const useModelExchangeDef = () => {
  const [{ value: responseTagConfig }] = useField('section.responseTagConfig');
  const {
    rowData: exchangeDefs,
  } = useEditableGridData<LineItemExchangeDefinition>();

  return React.useMemo(() => {
    return first(exchangeDefs) || createLineItem(responseTagConfig);
  }, [exchangeDefs, responseTagConfig]);
};

const useBulkUploadColumns = () => {
  const modelExchangeDef = useModelExchangeDef();

  const columnProps = React.useCallback((field: FieldConfig) => {
    if (isDefinitionField(field)) {
      let ValueCell;

      switch (field.type) {
        case 'string':
          ValueCell = BulkUploadTextValueCell;
          break;
        case 'number':
        case 'price':
          ValueCell = BulkUploadNumberValueCell;
          break;
        case 'date':
          ValueCell = BulkUploadDateValueCell;
          break;
        case 'unspscCode':
          ValueCell = BulkUploadUnspscCodeValueCell;
          break;
        default:
          ValueCell = BulkUploadTextValueCell;
      }
      return {
        Header: withProps(SimpleHeader, { showFieldTypeIcon: true }),
        // @ts-expect-error ts(2783) FIXME: 'ValueCell' is specified more than once, so this usage will be overwritten.
        ValueCell,
        // @ts-expect-error ts(2783) FIXME: 'width' is specified more than once, so this usage will be overwritten.
        width: field._id === 'description' ? 350 : DEFAULT_EDITABLE_DATA_CELL_WIDTH,
        ...(field._id === 'description' ? {
          ValueCell: BulkUploadDescriptionValueCell,
          width: 350,
        } : {
          width: DEFAULT_EDITABLE_DATA_CELL_WIDTH,
        }),
        required: false,
      };
    } else {
      return {
        Header: DisabledHeaderWithFieldTypeIcon,
        ValueCell: () => null,
        width: DEFAULT_EDITABLE_DATA_CELL_WIDTH,
        required: false,
      };
    }
  }, []);

  return useConfigurableExchangeDefFieldColumns({
    fields: modelExchangeDef.fields,
    orderedFieldIds: modelExchangeDef.orderedFieldIds,
    columnProps,
    isSender,
    isEditingSupplierExchangeDefs,
  });
};

const useParsedData = (rawData: unknown[], columns: EditableGridColumn[], dateFormat: string) => {
  const modelExchangeDef = useModelExchangeDef();

  return React.useMemo(() => {
    const errors = {};

    const postProcessingItems = [];

    const exchangeDefs = rawData.map((rowData, exchangeDefIndex) => {
      const updatedExchangeDef = omitBy({
        ...createLineItem(),
        fields: cloneDeep(modelExchangeDef.fields),
        orderedFieldIds: modelExchangeDef.orderedFieldIds,
        // @ts-expect-error ts(2345) FIXME: Argument of type 'string' is not assignable to parameter of type 'never'.
        evaluatorFieldCurrency: getExchangeDefFieldValue(modelExchangeDef, 'evaluatorFieldCurrency'),
      }, isUndefined) as LineItemExchangeDefinition;

      columns.forEach((column, index) => {
        const { disabled, isDisabled } = column;
        const field = modelExchangeDef.fields[column._id];

        if (!field || !isDefinitionField(field) || disabled || isDisabled?.(index)) {
          return;
        }

        // @ts-expect-error ts(18046) FIXME: 'rowData' is of type 'unknown'.
        const rawValue = rowData[index];

        const {
          canInsertValue,
          cellValue,
          isInputValid,
        } = parseCellValue(rawValue, field, dateFormat);

        if (canInsertValue) {
          set(updatedExchangeDef, field.source.key, cellValue);

          if (!isInputValid) {
            const accessorKey = getExchangeDefFieldSourceKey(modelExchangeDef, column._id);
            // @ts-expect-error ts(2769) FIXME: No overload matches this call.
            set(errors, [exchangeDefIndex, accessorKey], rawValue || '—');
          }

          if (field.type === 'unspscCode' && isInputValid && cellValue) {
            // @ts-expect-error ts(2345) FIXME: Argument of type '{ field: DefinitionFieldConfig; value: string | number; rawValue: any; exchangeDefIndex: number; }' is not assignable to parameter of type 'never'.
            postProcessingItems.push({
              field,
              value: cellValue,
              rawValue,
              exchangeDefIndex,
            });
          }
        }
      });

      return updatedExchangeDef;
    });

    return {
      validationResult: {
        errors: { exchangeDefs: errors },
        isValid: every(errors, isEmpty),
      },
      exchangeDefs,
      postProcessingItems,
    };
  }, [rawData, modelExchangeDef, columns, dateFormat]);
};

const PreviewStepContent = ({
  columns,
  validationResult,
  exchangeDefs,
}: {
  columns: EditableGridColumn[];
  validationResult: ValidationResult<{ exchangeDefs: Record<string, string> }>;
  exchangeDefs: LineItemExchangeDefinition[];
}) => {
  const { t } = useTranslation();

  const columnsWithErrors = React.useMemo(() => {
    const warningsByFieldKey = assignWith(
      {},
      // @ts-expect-error ts(2769) FIXME: No overload matches this call.
      ...Object.values(validationResult.errors.exchangeDefs),
      (a = 0) => a + 1,
    );

    return [
      {
        _id: 'rowNumber',
        accessorKey: 'rowNumber',
        Header: SimpleHeader,
        label: '',
        ValueCell: RowNumberWarningCell,
        width: ACTION_COLUMN_WIDTH,
        fixedWidth: true,
      },
      ...columns.map(column => {
        // @ts-expect-error ts(18046) FIXME: 'warningsByFieldKey' is of type 'unknown'.
        const warnings = warningsByFieldKey[column.accessorKey];

        return warnings
          ? { ...column, warnings }
          : column;
      }),
    ];
  }, [columns, validationResult]);

  return (
    <ValidationContext.Provider value={validationResult}>
      <GridIdPrefixProvider>
        <DefaultEditableGridStyles
          style={{ width: '100%', height: 'calc(100vh - 276px)' }}
          isReadOnly
        >
          {exchangeDefs.length > 0 ? (
            <ReadOnlyGrid
              frozenLeftColumnIds={frozenLeftColumnIds}
              columns={columnsWithErrors}
              rowData={exchangeDefs as any}
            />
          ) : (
            <Text color="subtext" fontSize={2} px="20px" py={3}>
              {t('request.lineItems.bulkUploadModal.noData')}
            </Text>
          )}
        </DefaultEditableGridStyles>
      </GridIdPrefixProvider>
    </ValidationContext.Provider>
  );
};

export const BulkUploadLineItemsModal = ({
  onImport,
  isOpen,
  onCancel,
}: {
  onImport?: (lineItems: LineItemExchangeDefinition[]) => void;
  onCancel?: () => void;
} & ModalProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [step, setStep] = useState<'upload' | 'preview'>('upload');
  const [rawData, setRawData] = useState<unknown[]>([]);
  const [excludeFirstRow, setExcludeFirstRow] = React.useState(true);
  const api = useApi();
  const columns = useBulkUploadColumns();
  const { confirm, ...confirmCloseDialogProps } = useConfirmDialog();
  const {
    maxExchangeDefCount,
  } = useModelSizeLimits();

  const fieldMappingPopover = usePopover({
    placement: 'left-end',
    strategy: 'fixed',
    overReferenceElement: true,
    skidding: -32,
  });

  const dateFormatPopover = usePopover({
    placement: 'right-end',
    strategy: 'fixed',
    overReferenceElement: true,
  });

  const showDateFormatButton = React.useMemo(() => columns.some(column => column.fieldType === FieldType.DATE), [columns]);

  // `excludeFirstRow` should be on by default. In the case of a single
  // row in `rawData`, we set that flag to false though and disable the
  // switch component that changes its value.
  React.useEffect(() => {
    setExcludeFirstRow(rawData.length > 1);
  }, [rawData, setExcludeFirstRow]);

  const initialFieldMapping = useBulkUploadAutoFieldsMapping(columns, rawData);

  const [fieldMapping, setFieldMapping] = React.useState(initialFieldMapping);
  const [dateFormat, setDateFormat] = React.useState('d MMM yyyy');

  React.useEffect(() => {
    setFieldMapping(initialFieldMapping);
  }, [initialFieldMapping, setFieldMapping]);

  const mappedRawData = React.useMemo(() => {
    return rawData.map(rawDataRow => {
      return fieldMapping.map(rawDataIndex => {
        return rawDataIndex === -1
          ? ''
          // @ts-expect-error ts(18046) FIXME: 'rawDataRow' is of type 'unknown'.
          : rawDataRow[rawDataIndex] || '';
      });
    });
  }, [rawData, fieldMapping]);

  const rawDataWithFirstRowAdjustment = React.useMemo(() => {
    return excludeFirstRow
      ? mappedRawData.slice(1)
      : mappedRawData;
  }, [mappedRawData, excludeFirstRow]);

  const toggleMapFieldsPopover = React.useCallback(() => {
    if (fieldMappingPopover.update) {
      fieldMappingPopover.update();
    }

    fieldMappingPopover.toggle();
  }, [fieldMappingPopover]);

  const toggleDateFormatPopover = React.useCallback(() => {
    if (dateFormatPopover.update) {
      dateFormatPopover.update();
    }

    dateFormatPopover.toggle();
  }, [dateFormatPopover]);

  const parseResult = useParsedData(rawDataWithFirstRowAdjustment, columns, convertDateFormat(dateFormat));

  const [gridData, setGridData] = React.useState({
    exchangeDefs: [],
    validationResult: { errors: { exchangeDefs: {} }, isValid: false },
  });

  React.useEffect(() => {
    if (isEmpty(parseResult.postProcessingItems)) {
      // @ts-expect-error ts(2345) FIXME: Argument of type '{ validationResult: { errors: { exchangeDefs: {}; }; isValid: boolean; }; exchangeDefs: LineItemExchangeDefinition[]; postProcessingItems: never[]; }' is not assignable to parameter of type 'SetStateAction<{ exchangeDefs: never[]; validationResult: { errors: { exchangeDefs: {}; }; isValid: boolean; }; }>'.
      setGridData(parseResult);
    } else {
      const postProcessItems = async () => {
        const codes = uniq(compact(
          // @ts-expect-error ts(2339) FIXME: Property 'value' does not exist on type 'never'.
          parseResult.postProcessingItems.map(item => item.value),
        ));

        let products;

        try {
          products = await api.searchProducts({ codes });
        } catch (err) {
          products = [];
        }

        const productById = keyBy(products, '_id');

        const updatedValidationResult = cloneDeep(parseResult.validationResult);
        const updatedExchangeDefs = cloneDeep(parseResult.exchangeDefs);

        for (const postProcessingItem of parseResult.postProcessingItems) {
          const { field, value, rawValue, exchangeDefIndex } = postProcessingItem;
          const product = productById[value];

          if (product) {
            updatedExchangeDefs[exchangeDefIndex].productOrService = cloneDeep(product);
          } else {
            // @ts-expect-error ts(2339) FIXME: Property 'source' does not exist on type 'never'.
            const accessorKey = field.source.key;
            updatedExchangeDefs[exchangeDefIndex][accessorKey] = null;
            set(
              updatedValidationResult.errors.exchangeDefs,
              // @ts-expect-error ts(2339) FIXME: Property '_id' does not exist on type 'never'.
              [exchangeDefIndex, field._id],
              rawValue,
            );
          }
        }

        setGridData({
          // @ts-expect-error ts(2322) FIXME: Type 'LineItemExchangeDefinition[]' is not assignable to type 'never[]'.
          exchangeDefs: updatedExchangeDefs,
          validationResult: updatedValidationResult,
        });
      };

      postProcessItems();
    }
  }, [api, parseResult, setGridData]);

  const {
    validationResult,
    exchangeDefs,
  } = gridData;

  const errorCount = React.useMemo(() => {
    return sumBy(
      Object.values(validationResult.errors.exchangeDefs),
      // @ts-expect-error ts(2769) FIXME: No overload matches this call.
      object => Object.keys(object).length,
    );
  }, [validationResult]);

  const handleFileUploadStart = (file: File) => {
    if (file.type !== 'text/csv') {
      setError(t('request.lineItems.bulkUploadModal.fileTypeError'));
      return;
    }

    parse(file, {
      preview: maxExchangeDefCount,
      transform(value: string) {
        return value.trim();
      },
      complete(results: ParseResult<string[]>) {
        if (!isEmpty(results.errors)) {
          setError(t('request.lineItems.bulkUploadModal.parseError'));
        } else {
          setRawData(results.data);
        }
      },
    });
  };

  const handleCloseClick = () => {
    if (step === 'preview') {
      confirm(() => {
        // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
        onCancel();
      });
    } else {
      // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
      onCancel();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen}>
        <ModalHeader onClose={handleCloseClick}>
          {{
            upload: t('request.lineItems.bulkUploadModal.importFromCsv'),
            preview: t('request.lineItems.bulkUploadModal.previewImport'),
          }[step]}
        </ModalHeader>
        <ModalBody
          width={{
            upload: '500px',
            preview: ['calc(100vw - 20px)', 'calc(100vw - 20px)', 'calc(100vw - 20px)', 'calc(100vw - 160px)'],
          }[step]}
          height={{
            upload: '124px',
            preview: 'calc(100vh - 32px)',
          }[step]}
        >
          {{
            upload: (
              <FieldContainer
                label={t('request.lineItems.bulkUploadModal.fieldLabel')}
                showAsterisk
              >
                <FileList
                  onUploadStart={handleFileUploadStart}
                  uploadFn={fastFakeUploadFn}
                  onChange={(attachments) => {
                    if (attachments.length === 0) {
                      setRawData([]);
                      setError('');
                    }
                  }}
                  limit={1}
                />
                {error ? <ErrorMessage error={error} /> : null}
              </FieldContainer>
            ),
            preview: (
              <>
                <PreviewStepContent
                  columns={columns}
                  validationResult={validationResult}
                  exchangeDefs={exchangeDefs}
                />
                <Flex
                  justifyContent="space-between"
                  mt={3}
                >
                  <MapFieldsButton
                    ref={fieldMappingPopover.setReferenceElement}
                    onClick={toggleMapFieldsPopover}
                  />
                  <Flex alignItems="center" sx={{ gap: 2 }}>
                    {showDateFormatButton && (
                      <DateFormatButton ref={dateFormatPopover.setReferenceElement} onClick={toggleDateFormatPopover} />
                    )}
                    <Switch
                      disabled={mappedRawData.length < 2}
                      checked={excludeFirstRow}
                      onChange={setExcludeFirstRow}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      width={42}
                    />
                    <Text ml={2} sx={{ lineHeight: 1.7 }}>
                      {t('request.lineItems.bulkUploadModal.excludeFirstRow')}
                    </Text>
                  </Flex>
                </Flex>
              </>
            ),
          }[step]}
        </ModalBody>
        <ModalFooter
          justifyContent={step === 'preview' ? 'space-between' : 'flex-end'}
          sx={{ height: '72px' }}
        >
          {step === 'preview' ? (
            <Box>
              {exchangeDefs.length > 0 ? (
                <Text lineHeight={1.5}>
                  <Icon icon="check-circle" color="success" mr={2} />
                  {t('request.lineItems.bulkUploadModal.importedLineItemCount', { count: exchangeDefs.length })}
                </Text>
              ) : (
                null
              )}
              {errorCount > 0 ? (
                <Text lineHeight={1.5}>
                  <Icon icon="warning" color="warning" mr={2} />
                  {t('request.lineItems.bulkUploadModal.valueWithErrorCount', { count: errorCount })}
                </Text>
              ) : (
                null
              )}
            </Box>
          ) : (
            null
          )}
          <Box>
            <CancelButton onClick={handleCloseClick} mr={2} />
            {{
              upload: (
                <UploadButton
                  disabled={!mappedRawData.length}
                  onClick={() => setStep('preview')}
                />
              ),
              preview: (
                <ImportButton
                  disabled={!exchangeDefs.length}
                  // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
                  onClick={() => onImport(exchangeDefs)}
                />
              ),
            }[step]}
          </Box>
        </ModalFooter>
      </Modal>
      {step === 'preview' ? (
        <>
          <BulkUploadFieldMappingPopover
            columns={columns}
            // @ts-expect-error ts(2322) FIXME: Type '(number | null | undefined)[]' is not assignable to type 'number[]'.
            initialFieldMapping={initialFieldMapping}
            // @ts-expect-error ts(2322) FIXME: Type '(number | null | undefined)[]' is not assignable to type 'number[]'.
            fieldMapping={fieldMapping}
            // @ts-expect-error ts(2322) FIXME: Type 'Dispatch<SetStateAction<(number | null | undefined)[]>>' is not assignable to type 'Dispatch<SetStateAction<number[]>>'.
            setFieldMapping={setFieldMapping}
            firstRowData={rawData[0] as string[]}
            popover={fieldMappingPopover}
            onCancel={fieldMappingPopover.close}
          />
          <BulkUploadDateFormatPopover
            dateFormat={dateFormat}
            setDateFormat={setDateFormat}
            popover={dateFormatPopover}
            onClose={dateFormatPopover.close}
          />
          <ConfirmCloseDialog {...confirmCloseDialogProps} />
        </>
      ) : (
        null
      )}
    </>
  );
};
