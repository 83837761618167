import { useCallback, useState, useMemo } from 'react';
import {
  PageType,
  isAuctionLineItemSection,
  ActionType,
  SectionType,
  AuctionBidFeedbackType,
  getAuctionLineItemExchangeDef,
  AuctionStatus,
  BidStatus,
  ExchangeType,
  getExchangeFieldValue,
  ExchangeId,
  RfxAuctionLineItemsSection,
  AuctionHistoryEvent, AuctionLineItemExchangeDefinition,
} from '@deepstream/common/rfq-utils';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { find, groupBy, isNaN, isNumber, keyBy, mapValues, sumBy, some, partition, fromPairs, isFinite, conforms } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Form, Formik, useField, useFormikContext } from 'formik';
import * as yup from 'yup';

import { WsStatus } from '@deepstream/message-service';
import { IconValue } from '@deepstream/common';
import { localeFormatFactorAsPercent, localeFormatPrice, roundToDecimals } from '@deepstream/utils';
import { useQueryClient } from 'react-query';
import { Icon, IconSquare } from '@deepstream/ui-kit/elements/icon/Icon';
import { OverflowTooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { IconText, IconTextProps } from '@deepstream/ui-kit/elements/text/IconText';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { InlineButton } from '@deepstream/ui-kit/elements/button/InlineButton';
import { Panel, PanelPadding, SidebarPanelHeading, PanelDivider, PanelProps } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Modal, ModalHeader, ModalFooter, ModalBody, CancelButton, SaveButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils/types';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { ErrorMessage } from '../../../ui/ErrorMessage';
import { DelayedSpinner, Loading } from '../../../ui/Loading';
import { RecipientIdProvider, RfqIdProvider, useLiveRfqStructure, useLiveRfqStructureQueryKey, useRecipientId, useRfqExchange, useRfqExchanges, useRfqId } from '../../../useRfq';
import * as rfx from '../../../rfx';
import { StaticTableStyles } from '../../../TableStyles';
import { Table } from '../../../Table';
import { nestCells } from '../../../nestCells';
import { TruncateCell } from '../../../TruncateCell';
import { MoneyField } from '../../../form/MoneyField';
import { CurrencyAmount, CurrencyCodeProvider, useCurrencyCode } from '../../../ui/Currency';
import { FixedFooter, FixedFooterButton } from '../../../FixedFooter';
import { useApi } from '../../../api';
import { useMutation } from '../../../useMutation';
import { useModalState } from '../../../ui/useModalState';
import { AuctionBidFeedback, AuctionInitialPricesType, AuctionLineItemsExchangeSnapshot, LineItemsExchangeSnapshot } from '../../../types';
import { HiddenRequestPagePlaceholder } from '../../../HiddenRequestPagePlaceholder';
import { AuctionCountdown } from '../Live/AuctionCountdown';
import { useInvalidateQueryOnMessage } from '../../../useInvalidateQueryOnMessage';
import { AuctionBulkReduceBid } from './AuctionBulkReduceBid';
import { usePreventEnterKeyHandler } from '../../../usePreventEnterKeyHandler';
import { useHandleWsMessage, useLatestWsStatusChange } from '../../../WsProvider';
import { AuctionRevisionChangesModal } from './AuctionRevisionChangesModal';
import { AuctionLineItemTotal } from '../../../draft/cell';
import { LinkedExchangeFieldCell } from '../../../LinkedExchangeDefFieldCell';
import { ExchangeDefFieldValueProvider } from '../../../ExchangeDefFieldValueContext';
import { Number as NumberValue } from '../../../ui/Number';
import { useCurrentUserLocale } from '../../../useCurrentUser';
import { AuctionBidObsoleteLineItems } from './AuctionBidObsoleteLineItems';
import { AuctionLineItemPriceCell } from './AuctionLineItemPriceCell';
import { AuctionOverviewButton } from '../Live/AuctionOverviewButton';
import { AuctionChatButton } from '../Live/AuctionChatButton';
import { useNotificationSubject } from '../../Notifications/useNotificationSubject';
import { Notification } from '../../Notifications/types';
import { RequestRecipientAuctionStageMessage } from '../Recipient/RequestRecipientStageMessage';
import { useEnv } from '../../../env';
import { CollapsibleHeaderContent } from '../../../CollapsibleHeaderContext';

const MAX_BID_PERCENTAGE_DIFFERENCE = 5;
const REDUCTION_PERCENT_DECIMAL_PLACES = 2;

const getBidPercentageDifference = (newBidTotal, submittedBidTotal) => {
  const relativeDifference = ((newBidTotal - submittedBidTotal) / submittedBidTotal);

  // Convert to human-readable percentage
  return relativeDifference * 100 * -1;
};

const AuctionLineItemPriceFieldCell = ({ row, column }) => {
  const section = rfx.useSection<RfxAuctionLineItemsSection>();
  const { auctionRules } = section;
  const exchangeDef = rfx.useAuctionLineItemExchangeDef(row.original.def);
  const hasSubmitAction = Boolean(find(row.original.actions, { type: ActionType.SUBMIT }));
  const formikContext = useFormikContext();

  return (
    <Box my={2} sx={{ width: 180 }} ml="auto">
      <MoneyField
        hideLabel
        disabled={!hasSubmitAction || column.disabled}
        name={`prices.${row.original.def._id}`}
        decimalPlaces={auctionRules.decimalPlaces}
        onKeyDown={() => {
          formikContext.setFieldValue('percentageStepperField', null);
          formikContext.setFieldValue('fixedAmountField', null);
        }}
        extendedInfo={price => (
          <AuctionLineItemTotal
            showCode
            price={isFinite(price) ? (price as number) : NaN}
            // @ts-expect-error ts(2322) FIXME: Type 'number | null' is not assignable to type 'number | undefined'.
            quantity={exchangeDef.quantity}
            textAlign="right"
          />
        )}
      />
    </Box>
  );
};

export const HeaderInfoBlock = ({
  label,
  content,
  ...props
}: {
  label: string | JSX.Element;
  content: string | JSX.Element;
} & Omit<PanelProps, 'content' | 'children'>) => {
  return (
    <Panel height={80} px="14px" mr={2} {...props}>
      <Flex flexDirection="column" justifyContent="center" height="100%">
        <SidebarPanelHeading fontWeight={400} fontSize={1}>
          {label}
        </SidebarPanelHeading>
        <Text fontWeight={500} fontSize={4} mt={1}>
          {content}
        </Text>
      </Flex>
    </Panel>
  );
};

const BidFeedback = ({
  type,
  rank,
  leadBidPrice,
  isInLead,
  isLoading,
}: AuctionBidFeedback & {
  type: AuctionBidFeedbackType;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Flex mt={2} width="100%">
      {type === AuctionBidFeedbackType.RANK_AND_LEAD && (
        <HeaderInfoBlock
          label={t('request.auction.bidFeedback.leadBid')}
          mr={2}
          flex={1}
          content={isLoading ? (
            <DelayedSpinner />
          ) : (
            <CurrencyAmount showCode value={leadBidPrice} />
          )}
        />
      )}
      <HeaderInfoBlock
        label={t('request.auction.bidFeedback.rank')}
        content={isLoading ? (
          <DelayedSpinner />
        ) : type === AuctionBidFeedbackType.LEADING ? (
          isInLead ? (
            t('request.auction.bidFeedback.inTheLead')
          ) : (
            t('request.auction.bidFeedback.notInTheLead')
          )
        ) : type === AuctionBidFeedbackType.RANK && rank ? (
          <>{t('request.auction.bidFeedback.ordinalPlace', { count: rank, ordinal: true })}</>
        ) : type === AuctionBidFeedbackType.RANK_AND_LEAD && rank ? (
          <>{t('request.auction.bidFeedback.ordinal', { count: rank, ordinal: true })}</>
        ) : (
          <EmDash />
        )}
      />
    </Flex>
  );
};

const eventIconColors = {
  'check': 'success',
  'info': 'info',
  'warning': 'warning',
  'exclamation': 'danger',
};

const successEventTypes = [
  'auction-bidder-agreement-accepted',
  'auction-lot-bid-accepted',
];

const warningEventTypes = [
  'auction-lot-lead-lost',
];

const errorEventTypes = [
  'auction-lot-bid-rejected',
];

const infoEventTypes = [
  'auction-lot-bid-retracted',
  'auction-started',
  'auction-lot-bidding-extended',
  'auction-cancelled',
  'auction-bidding-paused',
  'auction-bidding-resumed',
  'auction-lot-lead-bid-changed',
  'auction-lot-rank-changed',
  'auction-ended',
  'auction-revised',
];

const getAuctionEventIcon = ({ eventType }: { eventType: AuctionHistoryEvent['type']; }): IconValue => {
  if (successEventTypes.includes(eventType)) {
    return 'check';
  } else if (warningEventTypes.includes(eventType)) {
    return 'warning';
  } else if (errorEventTypes.includes(eventType)) {
    return 'exclamation';
  } else if (infoEventTypes.includes(eventType)) {
    return 'info';
  } else {
    // Default to info
    return 'info';
  }
};

const AuctionActivityLine = ({ event }: { event: AuctionHistoryEvent }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const revisionChangesModal = useModalState();
  const icon = getAuctionEventIcon({ eventType: event.type });

  const getEventText = () => {
    switch (event.type) {
      case 'auction-lot-bidding-extended': {
        return t(`request.auction.history.eventType.${event.type}`, {
          count: Math.round(event.remainingTimeSec / 60),
        });
      }
      case 'auction-lot-bid-accepted':
        return (
          <Trans ns="translation" i18nKey={`request.auction.history.eventType.${event.type}`}>
            Your bid of <CurrencyAmount showCode value={event.bid.price} /> was submitted successfully
          </Trans>
        );
      case 'auction-lot-bid-rejected':
        return (
          <Trans ns="translation" i18nKey={`request.auction.history.eventType.${event.type}`}>
            Your bid of <CurrencyAmount showCode value={event.bid.price} /> could not be submitted
          </Trans>
        );
      case 'auction-lot-bid-retracted':
        return (
          <Trans ns="translation" i18nKey={`request.auction.history.eventType.${event.type}`}>
            Your bid of <CurrencyAmount showCode value={event.bid.price} /> was retracted
          </Trans>
        );
      case 'auction-lot-lead-bid-changed':
        return (
          <Trans ns="translation" i18nKey={`request.auction.history.eventType.${event.type}`}>
            The lead bid has changed to <CurrencyAmount showCode value={event.bid.price} />
          </Trans>
        );
      case 'auction-lot-rank-changed':
        return t(`request.auction.history.eventType.${event.type}`, { rank: t('request.auction.bidFeedback.ordinal', { count: event.rank, ordinal: true }) });
      case 'auction-revised':
        return (
          <Text>
            {t(`request.auction.history.eventType.${event.type}`)}
            <InlineButton
              type="button"
              onClick={revisionChangesModal.open}
              style={{
                fontSize: theme.fontSizes[1],
                marginLeft: theme.space[1],
              }}
            >
              {t('general.seeDetails')}
            </InlineButton>
          </Text>
        );
      default:
        return t(`request.auction.history.eventType.${event.type}`);
    }
  };

  return (
    <>
      <Flex as="li" alignItems="center" px={2} py={2} maxWidth={400}>
        <Box mr={2} minWidth={22}>
          <IconSquare
            icon={icon}
            fontSize={0}
            size="22px"
            color={theme.colors[eventIconColors[icon]]}
          />
        </Box>
        <Box flexDirection="column">
          <Text fontSize={1}>
            {getEventText()}
          </Text>
          {'date' in event ? (
            <Text fontSize={0} color="subtext">
              {formatDistanceToNow(new Date(event.date), { addSuffix: true })}
            </Text>
          ) : null}
        </Box>
      </Flex>
      {revisionChangesModal.isOpen && 'date' in event && (
        <AuctionRevisionChangesModal
          revisionDate={event.date}
          isOpen
          onClose={revisionChangesModal.close}
        />
      )}
    </>
  );
};

const ConnectionStatusChip = ({
  tooltip,
  color,
  ...props
}: IconTextProps & {
  tooltip: string;
}) => (
  <OverflowTooltip content={tooltip}>
    <Box>
      <IconText
        // @ts-expect-error ts(2783) FIXME: 'text' is specified more than once, so this usage will be overwritten.
        text="Connected"
        color={color}
        fontSize="10px"
        sx={{
          userSelect: 'none',
          border: color,
          padding: '2.5px 10px',
          borderRadius: '20px',
        }}
        {...props}
      />
    </Box>
  </OverflowTooltip>
);

const WebsocketConnectionStatusChip = () => {
  const { t } = useTranslation();
  const { status } = useLatestWsStatusChange();

  return status === WsStatus.READY ? (
    <ConnectionStatusChip
      text={t('request.auction.connection.connected')}
      icon="cloud-check"
      isIconRegular
      iconFontSize={0}
      tooltip={t('request.auction.connection.receivingLiveUpdates')}
      color="success"
      fontWeight={500}
      backgroundColor="successBackground"
    />
  ) : (
    <ConnectionStatusChip
      text={t('request.auction.connection.tryingToConnect')}
      icon="cloud-slash"
      isIconRegular
      iconFontSize={0}
      tooltip={t('request.auction.connection.notReceivingLiveUpdates')}
      color="white"
      fontWeight={500}
      backgroundColor="warning"
    />
  );
};

const AuctionActivity = (props) => {
  const { t } = useTranslation();
  const { auction } = rfx.useStructure();

  return (
    <Panel {...props} height="100%">
      <Flex flexDirection="column" height="100%">
        <Flex p={3} alignItems="center" justifyContent="space-between">
          <SidebarPanelHeading fontWeight={400} fontSize={0}>
            {t('request.auction.history.panelTitle')}
          </SidebarPanelHeading>
          <WebsocketConnectionStatusChip />
        </Flex>
        <PanelDivider />
        {auction.history.length > 0 ? (
          <Flex
            as="ul"
            flex={1}
            flexDirection="column"
            style={{
              listStyle: 'none',
              padding: 0,
              margin: 0,
              overflowY: 'auto',
            }}
          >
            {auction.history.slice().reverse().map((event, index, array) => (
              <div key={`${event.type}_${index}`}>
                <AuctionActivityLine event={event} />
                {index < array.length - 1 ? <PanelDivider /> : null}
              </div>
            ))}
          </Flex>
        ) : (
          <Text px={2}>{t('request.auction.history.emptyActivity')}</Text>
        )}
      </Flex>
    </Panel>
  );
};

const ConfirmBidModal = ({
  isOpen,
  close,
  submittedBid,
  newBid,
}: {
  isOpen: boolean;
  close: () => void,
  submittedBid: number;
  newBid: number;
}) => {
  const { t } = useTranslation();
  const { submitForm } = useFormikContext();

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{t('request.auction.confirmBidModal.title')}</ModalHeader>
      <ModalBody>
        <Box
          as="ul"
          sx={{ listStyle: 'none', padding: 0 }}
        >
          <Flex as="li">
            <Text
              sx={{ width: 120 }}
              fontWeight={500}
            >
              {t('request.auction.confirmBidModal.submittedBid')}
            </Text>
            <CurrencyAmount showCode value={submittedBid} />
          </Flex>
          <Flex as="li">
            <Text
              sx={{ width: 120 }}
              fontWeight={500}
            >
              {t('request.auction.confirmBidModal.newBid')}
            </Text>
            <CurrencyAmount showCode value={newBid} />
          </Flex>
          <Flex as="li">
            <Text
              sx={{ width: 120 }}
              fontWeight={500}
            >
              {t('request.auction.confirmBidModal.reduction')}
            </Text>
            <NumberValue
              value={getBidPercentageDifference(newBid, submittedBid)}
              suffix="%"
              options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
            />
          </Flex>
        </Box>
        <MessageBlock variant="warn">
          {t('request.auction.confirmBidModal.reductionWarning', {
            maxPercentage: MAX_BID_PERCENTAGE_DIFFERENCE,
          })}
        </MessageBlock>
      </ModalBody>
      <ModalFooter>
        <CancelButton onClick={close} />
        <SaveButton
          label={t('request.auction.confirmBidModal.submitLabel')}
          onClick={callAll(submitForm, close)}
        />
      </ModalFooter>
    </Modal>
  );
};

const useBidSubmit = ({
  rfqId,
  recipientId,
}) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const { exchangeDefById, auction } = rfx.useStructure();
  const section = rfx.useSection<RfxAuctionLineItemsSection>();
  const structureQueryKey = useLiveRfqStructureQueryKey({
    rfqId,
    recipientId,
  });

  const [submitAuctionLotBid] = useMutation(api.submitAuctionLotBid, {
    onSuccess: callAll(
      () => queryClient.invalidateQueries(['exchanges', { rfqId }]),
      () => queryClient.invalidateQueries(structureQueryKey),
    ),
  });

  return useCallback(async ({ newBidTotal, prices, exchangeById }) => {
    await submitAuctionLotBid({
      rfqId,
      recipientId,
      auctionId: auction._id,
      lotId: section._id,
      price: newBidTotal,
      // @ts-expect-error ts(2322) FIXME: Type '{ [x: string]: { price: any; quantity: number | null; }; }' is not assignable to type 'Record<string, { price: number; quantity: number; }>'.
      breakdown: mapValues(
        prices,
        (price, lineItemId) => ({
          price,
          quantity: getAuctionLineItemExchangeDef(exchangeById[lineItemId].def, exchangeDefById).quantity,
        }),
      ),
    });
  }, [auction._id, exchangeDefById, recipientId, rfqId, section._id, submitAuctionLotBid]);
};

const AuctionLineItemBidSection = ({ isPreview }: { isPreview?: boolean }) => {
  const { t } = useTranslation();
  const rfqId = useRfqId({ required: true });
  const recipientId = useRecipientId({ required: true });
  const section = rfx.useSection<RfxAuctionLineItemsSection>();
  const { exchangeDefById, auction } = rfx.useStructure();
  const pagePermissions = rfx.usePagePermissions();
  const bid = rfx.useBid();
  const onKeyDown = usePreventEnterKeyHandler();
  const submitBid = useBidSubmit({
    rfqId,
    recipientId,
  });
  const confirmBidModal = useModalState();
  const [error, setError] = useState<string>('');

  if (!auction) {
    throw new Error('Missing auction');
  }

  const isAuctionPending = auction.status === AuctionStatus.PENDING;
  const isAuctionActive = auction.status === AuctionStatus.ACTIVE;
  const hasAuctionStarted = !isAuctionPending;
  const { auctionRules } = section;
  const { preBids } = auctionRules;
  const hasReachedNegativeOutcome = [BidStatus.NO_INTEND_TO_BID, BidStatus.WITHDRAWN, BidStatus.UNSUCCESSFUL].includes(bid.status);
  const isBiddingAllowed = !isPreview && !hasReachedNegativeOutcome && (isAuctionActive || (isAuctionPending && preBids));

  const { data: exchanges, isSuccess, isError, isLoading, queryKey } = useRfqExchanges({
    recipientId,
    sectionIds: [section?._id],
    enabled: !!section?._id,
  });

  const [allAuctionLineItemExchanges, linkedExchanges] = useMemo(
    () => partition(exchanges, exchange => exchange.def.type === ExchangeType.AUCTION_LINE_ITEM),
    [exchanges],
  ) as [AuctionLineItemsExchangeSnapshot[], LineItemsExchangeSnapshot[]];
  const [obsoleteAuctionLineItemExchanges, auctionLineItemExchanges] = partition(
    allAuctionLineItemExchanges,
    exchange => {
      const exchangeDef = exchangeDefById[exchange.def._id] as AuctionLineItemExchangeDefinition;
      const baseExchangeDef = getAuctionLineItemExchangeDef(exchangeDef, exchangeDefById);

      return exchangeDef.isObsolete || baseExchangeDef.isObsolete;
    },
  ) as [AuctionLineItemsExchangeSnapshot[], AuctionLineItemsExchangeSnapshot[]];
  useInvalidateQueryOnMessage(`rfx.${rfqId}`, queryKey);

  const hasSubmittedPrices = some(
    auctionLineItemExchanges,
    exchange => isNumber(getExchangeFieldValue(exchange, 'price')),
  );

  /** NaN if there is no previous bid total */
  const submittedBidTotal = sumBy(
    auctionLineItemExchanges,
    exchange => {
      const exchangeDef = getAuctionLineItemExchangeDef(exchange.def, exchangeDefById);

      // @ts-expect-error ts(18047) FIXME: 'exchangeDef.quantity' is possibly 'null'.
      return (getExchangeFieldValue(exchange, 'price') ?? NaN) * exchangeDef.quantity;
    },
  );

  const hasSubmittedBid = !Number.isNaN(submittedBidTotal);

  const exchangeById = useMemo(
    () => keyBy(auctionLineItemExchanges, exchange => exchange.def._id),
    [auctionLineItemExchanges],
  );

  const {
    initialValues,
    initialTouched,
  } = useMemo(() => {
    if (hasSubmittedPrices) {
      return {
        initialValues: {
          prices: mapValues(exchangeById, exchange => getExchangeFieldValue(exchange, 'price')),
        },
        initialTouched: undefined,
      };
    } else {
      return {
        initialValues: {
          prices: mapValues(exchangeById, exchange => {
            const exchangeDef = getAuctionLineItemExchangeDef(exchange.def, exchangeDefById);

            if (exchangeDef.type === ExchangeType.LINE_ITEM) {
              // @ts-expect-error ts(2345) FIXME: Argument of type 'LineItemsExchangeSnapshot | undefined' is not assignable to parameter of type '{ def: LineItemExchangeDefinition; latestReply: Record<string, unknown>; computedFormulas?: Record<string, number> | undefined; currency: string; } | { ...; } | { ...; }'.
              const linkedPrice = getExchangeFieldValue(linkedExchanges
                .find(exchange => exchange._id === exchangeDef._id), 'price');

              return isNumber(linkedPrice) ? linkedPrice : null;
            } else {
              return null;
            }
          }),
        },
        initialTouched: {
          prices: fromPairs(
            linkedExchanges
              .filter(exchange => isNumber(getExchangeFieldValue(exchange, 'price')))
              .map(exchange => {
                const linkedExchangeDef = Object.values(exchangeById).find(
                  auctionLineItemExchange => (
                    'linkedExchangeDefId' in auctionLineItemExchange.def &&
                    auctionLineItemExchange.def.linkedExchangeDefId === exchange.def._id
                  ),
                );
                return [linkedExchangeDef?.def._id, true];
              }),
          ),
        },
      };
    }
  }, [exchangeById, exchangeDefById, hasSubmittedPrices, linkedExchanges]);

  const getNewBidTotal = useCallback((prices) => {
    return sumBy(auctionLineItemExchanges, exchange => {
      const exchangeDef = getAuctionLineItemExchangeDef(exchange.def, exchangeDefById);

      // @ts-expect-error ts(18047) FIXME: 'exchangeDef.quantity' is possibly 'null'.
      return (prices[exchange.def._id] || 0) * exchangeDef.quantity;
    });
  }, [auctionLineItemExchanges, exchangeDefById]);

  const columns = useMemo(
    () => ([
      {
        id: 'description',
        Header: t('general.description'),
        accessor: 'def.fields.description',
        Cell: nestCells(TruncateCell, LinkedExchangeFieldCell),
      },
      {
        id: 'unit',
        Header: t('general.unit'),
        accessor: 'def.fields.unit',
        Cell: LinkedExchangeFieldCell,
        width: 100,
      },
      {
        id: 'quantity',
        Header: t('general.quantity'),
        accessor: 'def.fields.quantity',
        Cell: LinkedExchangeFieldCell,
        width: 100,
      },
      {
        id: 'previousPrice',
        Header: t('request.auction.submittedBid'),
        accessor: 'latestReply.price',
        textAlign: 'right',
        paddingRight: '16px',
        width: 150,
        Cell: AuctionLineItemPriceCell,
      },
      {
        id: 'newPrice',
        Header: t('request.auction.newBid'),
        accessor: 'latestReply.price',
        Cell: AuctionLineItemPriceFieldCell,
        disabled: isLoading || !pagePermissions.canRespond || !isBiddingAllowed,
        width: 220,
        textAlign: 'right',
      },
    ]),
    [isBiddingAllowed, isLoading, pagePermissions, t],
  );

  return isSuccess ? (
    <ExchangeDefFieldValueProvider>
      <Formik<{ prices: Record<string, number | null> }>
        validateOnBlur
        enableReinitialize
        initialValues={initialValues}
        initialTouched={initialTouched}
        validationSchema={
          yup.object().shape({
            prices: yup.object().shape(mapValues(
              initialValues.prices,
              (price) => yup
                .number()
                // Empty string results in NaN, which triggers a type error instead of required error
                .transform(value => isNaN(value) ? undefined : value)
                .max(hasAuctionStarted && isNumber(price) ? price : Infinity, t('request.auction.errors.mustBeLowerThanSubmittedBid'))
                .required(t('general.required')),
            )),
          })
        }
        onSubmit={async ({ prices }) => {
          const newBidTotal = getNewBidTotal(prices);
          await submitBid({
            newBidTotal,
            prices,
            exchangeById,
          });
        }}
      >
        {({ isSubmitting, values, submitForm }) => {
          const handleFormSubmit = () => {
            const newBidTotal = getNewBidTotal(values.prices);

            const percentageDifference = getBidPercentageDifference(newBidTotal, submittedBidTotal);

            const { minimumReduction, ceilingPrice } = auctionRules;

            if (submittedBidTotal === newBidTotal) {
              setError('duplicate-bid');
            } else if (ceilingPrice && newBidTotal > ceilingPrice.amount) {
              setError('exceeds-ceiling-price');
            } else if (
              hasAuctionStarted &&
              hasSubmittedBid &&
              minimumReduction.type === 'amount' &&
              newBidTotal > (submittedBidTotal - minimumReduction.value)
            ) {
              setError('less-than-min-reduction-amount');
            } else if (
              hasAuctionStarted &&
              hasSubmittedBid &&
              minimumReduction.type === 'percent' &&
              (
                roundToDecimals(newBidTotal, REDUCTION_PERCENT_DECIMAL_PLACES) >
                roundToDecimals((submittedBidTotal * (1 - (minimumReduction.value / 100))), REDUCTION_PERCENT_DECIMAL_PLACES)
              )
            ) {
              setError('less-than-min-reduction-percent');
            } else if (
              !auctionRules.tieBids &&
              auction.lots[0].feedback?.leadBidPrice &&
              (auction.lots[0].feedback.leadBidPrice === newBidTotal)
            ) {
              setError('tie-bids-not-allowed');
            } else if (percentageDifference > MAX_BID_PERCENTAGE_DIFFERENCE) {
              confirmBidModal.open();
            } else {
              submitForm();
            }
          };

          return (
            <Form onKeyDown={onKeyDown}>
              <Flex mb={3} height={160}>
                <Panel p={3} mr={2} minWidth={180}>
                  <AuctionCountdown
                    status={auction.status}
                    // @ts-expect-error ts(2769) FIXME: No overload matches this call.
                    pauseDate={new Date(auction.lots[0].pauseDate)}
                    endDate={hasAuctionStarted
                      ? new Date(auction.lots[0].endDate)
                      : new Date(auction.startDate)
                    }
                  />
                </Panel>
                <Box flex={1}>
                  <AuctionActivity mr={2} />
                </Box>
                <Flex flexDirection="column" width={260}>
                  <HeaderInfoBlock
                    label={hasAuctionStarted ? t('request.auction.headings.yourSubmittedBid') : t('request.auction.headings.yourSubmittedPreBid')}
                    content={isLoading ? (
                      <DelayedSpinner />
                    ) : hasSubmittedPrices ? (
                      <CurrencyAmount showCode value={submittedBidTotal} />
                    ) : (
                      <EmDash />
                    )}
                  />
                  {hasAuctionStarted && (
                    // @ts-expect-error ts(2322) FIXME: Type '{ rank?: number | null | undefined; isInLead?: boolean | undefined; leadBidPrice?: number | undefined; type: AuctionBidFeedbackType; isLoading: false; }' is not assignable to type 'AuctionBidFeedback'.
                    <BidFeedback
                      type={auctionRules.bidFeedback}
                      isLoading={isLoading}
                      {...auction.lots[0].feedback}
                    />
                  )}
                </Flex>

                {hasSubmittedBid && (
                  <AuctionBulkReduceBid
                    // @ts-expect-error ts(2322) FIXME: Type '{ [x: string]: any; } | { [x: string]: number | null; }' is not assignable to type 'AuctionInitialPricesType'.
                    initialPrices={initialValues.prices}
                    submittedBidTotal={submittedBidTotal}
                    setError={setError}
                    disabled={(
                      isLoading ||
                      !pagePermissions.canRespond ||
                      !isBiddingAllowed
                    )}
                  />
                )}

                <ConfirmBidModal
                  {...confirmBidModal}
                  submittedBid={submittedBidTotal}
                  newBid={getNewBidTotal(values.prices)}
                />
                <AuctionSubmitBid
                  handleFormSubmit={handleFormSubmit}
                  hasAuctionStarted={hasAuctionStarted}
                  exchanges={auctionLineItemExchanges}
                  values={values}
                  error={error}
                  setError={setError}
                  isSubmitting={isSubmitting}
                  isBiddingAllowed={isBiddingAllowed}
                  hasSubmittedBid={hasSubmittedBid}
                  // @ts-expect-error ts(2322) FIXME: Type '{ [x: string]: any; } | { [x: string]: number | null; }' is not assignable to type 'AuctionInitialPricesType'.
                  initialPrices={initialValues.prices}
                />
              </Flex>
              <Panel p={0}>
                {isLoading ? (
                  <PanelPadding>
                    <Loading />
                  </PanelPadding>
                ) : isError ? (
                  <PanelPadding>
                    <ErrorMessage error={t('request.lineItems.errors.couldNotGetLineItems')} />
                  </PanelPadding>
                ) : isSuccess ? (
                  <StaticTableStyles>
                    <Table
                      data={auctionLineItemExchanges}
                      columns={columns}
                    />
                  </StaticTableStyles>
                ) : (
                  null
                )}
              </Panel>
            </Form>
          );
        }}
      </Formik>
      {obsoleteAuctionLineItemExchanges.length > 0 && (
        <AuctionBidObsoleteLineItems lineItemExchanges={obsoleteAuctionLineItemExchanges} />
      )}
    </ExchangeDefFieldValueProvider>
  ) : (
    null
  );
};

const ReductionPercentage = ({
  exchanges,
  values,
  initialPrices,
}: {
  exchanges: AuctionLineItemsExchangeSnapshot[];
  values: {
    prices: Record<ExchangeId, number | null>
  };
  initialPrices: AuctionInitialPricesType;
}) => {
  const { t } = useTranslation();
  const { exchangeDefById } = rfx.useStructure();
  const locale = useCurrentUserLocale();

  const reductionFactor = 1 - (sumBy(
    exchanges,
    (exchange) => {
      const exchangeDef = getAuctionLineItemExchangeDef(exchange.def, exchangeDefById);

      // @ts-expect-error ts(18047) FIXME: 'exchangeDef.quantity' is possibly 'null'.
      return (values.prices[exchange.def._id] || 0) * exchangeDef.quantity;
    },
  ) / sumBy(
    exchanges,
    (exchange) => {
      const exchangeDef = getAuctionLineItemExchangeDef(exchange.def, exchangeDefById);

      // @ts-expect-error ts(18047) FIXME: 'exchangeDef.quantity' is possibly 'null'.
      return (initialPrices[exchange.def._id] || 0) * exchangeDef.quantity;
    },
  ));

  return reductionFactor > 0 ? (
    <Text color="subtext" sx={{ userSelect: 'none' }}>
      <Icon
        icon="arrow-down"
        light
        mr={1}
      />
      {t('request.auction.reduction', { value: localeFormatFactorAsPercent(reductionFactor, { locale }) })}
      <OverflowTooltip content={t('request.auction.reductionTooltip')}>
        <Icon
          icon="info-circle"
          regular
          ml={1}
        />
      </OverflowTooltip>
    </Text>
  ) : (
    null
  );
};

type AuctionSubmitBidProps = {
  isBiddingAllowed: boolean;
  handleFormSubmit: () => void;
  exchanges: AuctionLineItemsExchangeSnapshot[];
  error: string;
  setError: (message: string) => void;
  hasAuctionStarted: boolean;
  isSubmitting: boolean;
  hasSubmittedBid: boolean;
  values: {
    prices: Record<ExchangeId, number | null>
  };
  initialPrices: AuctionInitialPricesType;
};

export const AuctionSubmitBid = ({
  handleFormSubmit,
  isBiddingAllowed,
  exchanges,
  error,
  setError,
  hasAuctionStarted,
  isSubmitting,
  hasSubmittedBid,
  values,
  initialPrices,
}: AuctionSubmitBidProps) => {
  const { t } = useTranslation();
  const locale = useCurrentUserLocale();
  const section = rfx.useSection<RfxAuctionLineItemsSection>();
  const { auctionRules } = section;
  const { touched } = useFormikContext<{ prices: any[] }>();
  const auctionTermsExchangeDef = rfx.useAuctionTermsExchangeDef();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const [field, meta] = useField('prices');

  const currencyCode = useCurrencyCode();
  const { exchangeDefById } = rfx.useStructure();

  const { data: exchange } = useRfqExchange({
    // @ts-expect-error ts(18047) FIXME: 'auctionTermsExchangeDef' is possibly 'null'.
    exchangeId: auctionTermsExchangeDef._id,
    recipientId: currentCompanyId,
  });
  const hasAcceptedTerms = !!exchange?.isResolved;

  const areAllPricesFieldsTouched = touched.prices
    ? (Object.keys(touched.prices).length === Object.keys(field.value).length) &&
    (Object.keys(touched.prices).every(val => Object.keys(field.value).includes(val)))
    : false;

  const isInitialBidError = !hasSubmittedBid && areAllPricesFieldsTouched && Boolean(meta.error);
  const isSubmittedBidError = hasSubmittedBid && meta.touched && Boolean(meta.error);

  useWatchValue(
    field.value,
    () => setError(''),
  );

  const hasValues = some(values.prices, isNumber);

  return (
    <Panel width={220} py={24} px="14px">
      <Flex flexDirection="column" justifyContent="space-between" height="100%">
        <Box>
          <SidebarPanelHeading fontWeight={400} fontSize={1}>
            {hasAuctionStarted ? t('request.auction.headings.yourNewBid') : t('request.auction.headings.yourNewPreBid') }
          </SidebarPanelHeading>
          <Text
            mt="8px"
            fontWeight={500}
            fontSize="22px"
            color={error || isInitialBidError || isSubmittedBidError ? 'danger' : 'text'}
          >
            {hasValues ? (
              <CurrencyAmount
                showCode
                value={sumBy(
                  exchanges,
                  (exchange) => {
                    const exchangeDef = getAuctionLineItemExchangeDef(exchange.def, exchangeDefById);

                    // @ts-expect-error ts(18047) FIXME: 'exchangeDef.quantity' is possibly 'null'.
                    return (values.prices[exchange.def._id] || 0) * exchangeDef.quantity;
                  },
                )}
              />
            ) : (
              <EmDash />
            )}
          </Text>
          {hasValues && hasSubmittedBid && !(error || isInitialBidError || isSubmittedBidError) && (
            <ReductionPercentage
              exchanges={exchanges}
              values={values}
              initialPrices={initialPrices}
            />
          )}
          {(error || isInitialBidError || isSubmittedBidError) && (
            <Box>
              <IconText
                fontSize={1}
                lineHeight={1.25}
                icon="exclamation-circle"
                color="danger"
                text={isInitialBidError || isSubmittedBidError ? (
                  t('request.auction.errors.resolveLineItemErrors')
                ) : error === 'exceeds-ceiling-price' ? (
                  t('request.auction.errors.mustBeLowerThanCeilingPrice', {
                    // @ts-expect-error ts(18047) FIXME: 'auctionRules.ceilingPrice' is possibly 'null'.
                    ceilingPrice: localeFormatPrice(auctionRules.ceilingPrice.amount, currencyCode, { locale, showCode: true }),
                  })
                ) : error === 'duplicate-bid' ? (
                  hasAuctionStarted
                    ? t('request.auction.errors.mustBeLowerThanSubmittedBid')
                    : t('request.auction.errors.mustDeviateFromSubmittedBid')
                ) : error === 'less-than-min-reduction-percent' ? (
                  t('request.auction.errors.minReductionPercent', { amount: auctionRules.minimumReduction.value })
                ) : error === 'less-than-min-reduction-amount' ? (
                  t('request.auction.errors.minReductionAmount', {
                    // eslint-disable-next-line max-len
                    minPriceDifference: localeFormatPrice(auctionRules.minimumReduction.value, currencyCode, { locale, showCode: true }),
                  })
                ) : error === 'tie-bids-not-allowed' ? (
                  t('request.auction.errors.tieBidsNotAllowed')
                ) : null}
              />
            </Box>

          )}
        </Box>
        <Flex justifyContent="flex-end">
          <Button
            small
            type="button"
            onClick={handleFormSubmit}
            disabled={
              isSubmitting ||
              !isBiddingAllowed ||
              Boolean(error) ||
              isInitialBidError ||
              isSubmittedBidError ||
              !hasAcceptedTerms
            }
          >
            {hasAuctionStarted ? t('request.auction.buttons.placeNewBid') : t('request.auction.buttons.submitPreBid') }
          </Button>
        </Flex>
      </Flex>
    </Panel>
  );
};

const AuctionBid = ({ isPreview }: { isPreview?: boolean }) => {
  const { INTERCOM_APP_ID } = useEnv();
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfxStructure = rfx.useStructure();
  const auctionPage = rfx.usePage();
  const stageId = rfx.useStageId();
  const pagePermissions = rfx.usePagePermissions();

  const auctionSectionsByType = groupBy(
    // @ts-expect-error ts(18047) FIXME: 'auctionPage' is possibly 'null'.
    auctionPage.sections.map(sectionId => rfxStructure.sectionById[sectionId]),
    section => section.type,
  );

  const auctionLineItemSections = useMemo(
    // @ts-expect-error ts(18047) FIXME: 'auctionPage' is possibly 'null'.
    () => auctionPage.sections
      .map(sectionId => rfxStructure.sectionById[sectionId])
      .filter(isAuctionLineItemSection),
    [rfxStructure, auctionPage],
  );

  const chatSection = auctionSectionsByType[SectionType.CHAT]?.[0];
  const auctionTermsSection = auctionSectionsByType[SectionType.AUCTION_TERMS]?.[0];

  return pagePermissions.canRead ? (
    <CurrencyCodeProvider code={auctionLineItemSections[0].auctionRules.currency}>
      <CollapsibleHeaderContent>
        {stageId && (
          <RequestRecipientAuctionStageMessage />
        )}
        <Stack pb={5}>
          {auctionLineItemSections.map(section => (
            <rfx.SectionProvider key={section._id} section={section}>
              <AuctionLineItemBidSection isPreview={isPreview} />
            </rfx.SectionProvider>
          ))}
        </Stack>
      </CollapsibleHeaderContent>
      <FixedFooter>
        {/*
          FIXME: instead of using section provider, let's create useAuction hook
          that provides data in convenient shape for usage
        */}
        <rfx.SectionProvider section={auctionTermsSection}>
          <AuctionOverviewButton
            auctionRules={auctionLineItemSections[0].auctionRules}
          />
        </rfx.SectionProvider>
        <Box>
          {chatSection && (
            <rfx.SectionProvider section={chatSection}>
              <AuctionChatButton isSender={false} recipientId={currentCompanyId} disabled={isPreview} />
            </rfx.SectionProvider>
          )}
          {INTERCOM_APP_ID && (
            <FixedFooterButton
              id="auction-support-button"
              color="primary"
              icon="question-circle"
              sx={{ borderLeft: 'lightGray' }}
            >
              {t('request.auction.footer.support')}
            </FixedFooterButton>
          )}
        </Box>
      </FixedFooter>
    </CurrencyCodeProvider>
  ) : (
    <HiddenRequestPagePlaceholder />
  );
};

export const AuctionBidContainer = ({
  rfqId,
  recipientId,
  navigateToTeam,
  isPreview,
}: {
  rfqId: string;
  recipientId: string;
  navigateToTeam: () => void;
  isPreview?: boolean;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const queryClient = useQueryClient();

  const ref = useNotificationSubject({
    filter: conforms<Partial<Notification>>({
      domain: domain => domain === NotificationDomain.RFQ_RECEIVED,
      action: action => [
        NotificationAction.UPCOMING_AUCTION,
        NotificationAction.AUCTION_RESCHEDULED,
        NotificationAction.AUCTION_REVISED,
        NotificationAction.AUCTION_STARTED,
      ].includes(action),
      meta: meta => meta.rfqId === rfqId,
      to: to => to.companyId === currentCompanyId,
    }),
  });

  const { data: rfxStructure, isLoading, isError, isSuccess, queryKey } = useLiveRfqStructure({
    rfqId,
    recipientId,
  });

  const auctionPage = find(rfxStructure?.pages, { type: PageType.AUCTION });

  useInvalidateQueryOnMessage(`rfx.${rfqId}`, queryKey);

  // Hack: notifications are created after the rfx updated message is sent,
  // so we're delaying the refetching.
  const delayedInvalidateNotifications = useCallback(
    () => {
      setTimeout(() => queryClient.invalidateQueries('notifications'), 500);
    },
    [queryClient],
  );

  // Refetch notifications when the rfx was updated for faster feedback
  useHandleWsMessage(`rfx.${rfqId}`, delayedInvalidateNotifications, true, 'auction-bid');

  return isLoading ? (
    <PanelPadding>
      <Loading />
    </PanelPadding>
  ) : isError || (isSuccess && rfxStructure && !auctionPage) ? (
    <PanelPadding>
      <ErrorMessage error={t('errors.unexpected')} />
    </PanelPadding>
  ) : isSuccess && rfxStructure && auctionPage ? (
    <rfx.StateProvider isLive>
      <RfqIdProvider rfqId={rfqId}>
        <rfx.StructureProvider structure={rfxStructure}>
          <RecipientIdProvider recipientId={currentCompanyId}>
            <rfx.PageProvider page={auctionPage}>
              <div ref={ref}>
                <AuctionBid isPreview={isPreview} />
              </div>
            </rfx.PageProvider>
          </RecipientIdProvider>
        </rfx.StructureProvider>
      </RfqIdProvider>
    </rfx.StateProvider>
  ) : (
    null
  );
};
