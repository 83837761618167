import { useMemo } from 'react';
import { ActionType, ExchangeSource, RfqStatus, SectionType, isAuctionStage } from '@deepstream/common/rfq-utils';
import { conforms, find, some } from 'lodash';
import { useTranslation } from 'react-i18next';
import { chatExchangeTypes } from '@deepstream/common/exchangesConfig';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils';
import { HeaderToggleButton } from '../HeaderToggleButton';
import { useCurrentCompanyId } from '../currentCompanyId';
import { RequestStatusIndicators } from '../RequestStatusIndicators';
import { HeaderTab } from './PageHeaderTabs';
import { RfqIdProvider, useLiveRfqStructure } from '../useRfq';
import { RequestHeading } from './RequestHeading';
import { SentRequestOverview } from '../types';
import * as rfx from '../rfx';
import { requestEditSummaryRoute, requestSentSuppliersViewRoute } from '../AppRouting';
import { useRequestSentNavigation, useRequestEditNavigation } from '../appNavigation';
import * as Layout from '../Layout';
import { useNavigate } from '../tanstackRouter';
import { useDeviceSize } from '../ui/useDeviceSize';

export const RequestPageHeader = ({
  rfqId,
  selectedTabId,
}: {
  rfqId: string;
  selectedTabId: string;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const sentNavigation = useRequestSentNavigation();
  const editNavigation = useRequestEditNavigation();
  const { isExtraLarge, isLarge } = useDeviceSize();
  const navigate = useNavigate();
  const { data: structure } = useLiveRfqStructure({
    currentCompanyId,
    rfqId,
  });

  const messagesNotificationFilter = useMemo(() => {
    if (!structure?.meta.hasMessagesPage) {
      return {};
    }

    const clarificationsSection = find(
      structure.sectionById,
      section => section.type === SectionType.CLARIFICATIONS,
    );
    const chatSection = find(
      structure.sectionById,
      section => section.type === SectionType.CHAT,
    );

    const exchangeIds = [
      ...(clarificationsSection?.exchangeDefIds || []),
      ...(chatSection?.exchangeDefIds || []),
    ];

    return conforms({
      domain: domain => domain === NotificationDomain.RFQ_SENT,
      action: action => action === NotificationAction.EXCHANGE_REPLY_SENT,
      to: to => to.companyId === currentCompanyId,
      meta: meta => (
        meta.actionType === ActionType.NONE &&
        exchangeIds.includes(meta.exchangeId)
      ),
    });
  }, [structure, currentCompanyId]);

  const tabs: HeaderTab[] = useMemo(
    () => structure ? ([
      {
        id: 'suppliers',
        name: t('request.requestPageName.suppliers'),
        taskFilter: {
          type: 'decideApproval',
          data: { rfqId },
        },
        navigate: () => sentNavigation.navigateToSuppliers(),
      },
      {
        id: 'team',
        name: t('request.requestPageName.team'),
        navigate: () => sentNavigation.navigateToTeam(),
      },
      {
        id: 'details',
        name: t('request.requestPageName.details'),
        navigate: () => sentNavigation.navigateToDetails(),
      },
      {
        id: 'bulletin',
        name: t('request.requestPageName.bulletin'),
        taskFilter: {
          type: 'answerQuestion',
          data: { rfqId },
        },
        hide: structure.meta.hasBulletinsSection,
        navigate: () => sentNavigation.navigateToLegacyBulletins(),
      },
      {
        id: 'auction',
        name: t('request.requestPageName.auction'),
        hide: !some(structure.stages, isAuctionStage),
        navigate: () => sentNavigation.navigateToAuction(),
      },
      {
        id: 'comments',
        name: t('request.requestPageName.comments'),
        iconLeft: 'comment-o',
        navigate: () => sentNavigation.navigateToComments(),
      },
      {
        id: 'messages',
        name: t('request.requestPageName.messages'),
        iconLeft: 'comment-o',
        navigate: () => sentNavigation.navigateToMessages(),
        hide: !structure.meta.hasMessagesPage,
        notificationFilter: messagesNotificationFilter,
      },
      {
        id: 'vessel',
        name: t('request.requestPageName.vesselPricing'),
        navigate: () => sentNavigation.navigateToVesselPricing(),
        hide: !structure.meta.hasVesselPricingSection,
      },
      {
        id: 'comparison',
        name: t('request.requestPageName.comparison'),
        iconLeft: 'table',
        navigate: () => sentNavigation.navigateToComparison(),
      },
      {
        id: 'spend',
        name: t('request.spendAndSavings.spend'),
        iconLeft: 'circle-dollar',
        iconProps: { regular: true },
        navigate: () => sentNavigation.navigateToSpend(),
      },
      {
        id: 'audit',
        name: t('request.requestPageName.audit'),
        navigate: () => sentNavigation.navigateToAudit(),
      },
      // This tab was part of the old award UX, and must keep it around for
      // backwards compatibility with older requests
      {
        id: 'award',
        name: t('request.requestPageName.bidAwarded'),
        navigate: () => sentNavigation.navigateToAward(),
        hide: (
          structure.status !== RfqStatus.AWARDED ||
          // If there is an awarded chat exchange, we hide the tab
          some(structure.exchangeDefById, exchangeDef => (
            chatExchangeTypes.includes(exchangeDef.type) &&
            'source' in exchangeDef &&
            exchangeDef.source === ExchangeSource.BID_AWARDED
          )) ||
          // But if the awarded chat exchange is missing, it's either
          //    A) the new award UX, where the sender opted-out of including a message
          //    B) the old award UX, where chats didn't exist
          // Since it's ambiguous, we rely on the absence of the `message` key on the
          // `meta.awarded` object, which was only used for the old UX (probably). The
          // reliability of `message` is unclear, which is why we'll use this condition
          // as a last resort.
          !structure.meta.awarded?.hasOwnProperty('message')
        ),
      },
    ]) : [],
    [structure, t, rfqId, messagesNotificationFilter, sentNavigation]);

  const navigateToRequest = (request: SentRequestOverview) => {
    if (request.sentDashboard.status === 'draft') {
      navigate({
        to: requestEditSummaryRoute.to,
        params: { rfqId: request._id, currentCompanyId },
      });
    } else {
      navigate({
        to: requestSentSuppliersViewRoute.to,
        params: { rfqId: request._id, currentCompanyId },
      });
    }
  };

  return structure ? (
    <RfqIdProvider rfqId={rfqId}>
      <rfx.StructureProvider structure={structure}>
        <Layout.PageHeader
          tabs={structure.status !== RfqStatus.DELETED ? tabs : undefined}
          selectedTabId={selectedTabId}
          heading={() => (
            <RequestHeading
              isSender
              requestId={rfqId}
              subject={structure.summary.subject}
              navigateToRequest={navigateToRequest}
            />
          )}
        >
          <RequestStatusIndicators navigateToReviseRequest={editNavigation.navigateToSummary} />
          {selectedTabId === 'auction' && (isExtraLarge || isLarge) && (
            <HeaderToggleButton
              sx={{
                position: 'absolute',
                right: '15px',
                marginLeft: 'auto',
                bottom: '-14px',
                width: 'max-content',
                zIndex: 999,
              }}
            />
          )}
        </Layout.PageHeader>
      </rfx.StructureProvider>
    </RfqIdProvider>
  ) : (
    null
  );
};
