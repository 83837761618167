import * as React from 'react';
import { Form, Formik, useField, useFormikContext } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Text } from 'rebass/styled-components';
import { concat, difference, every, filter, intersection, isEmpty, map, reject, some, sortBy, toLower, uniq, values, without } from 'lodash';
import styled from 'styled-components';
import { diffArrayBy } from '@deepstream/utils';
import { QuestionnaireStatus } from '@deepstream/common/preQual';
import { Pictogram } from '@deepstream/ui-kit';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { withProps } from '@deepstream/ui-utils/withProps';
import { Chip } from '@deepstream/ui-kit/elements/Chip';
import { WrapperButton } from '@deepstream/ui-kit/elements/button/WrapperButton';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useModalState } from '../../ui/useModalState';
import { CompanyLogo } from '../../CompanyLogo';
import { SelectDropdownMenu } from '../../ui/MultiSelect';
import { RecipientQuestionnaires, useSentQuestionnairesByRecipient } from './useSentQuestionnairesByRecipient';
import { CompactTableStyles } from '../../TableStyles';
import { Table } from '../../Table';
import { TruncateCell } from '../../TruncateCell';
import { QuestionnaireStatusCell } from '../PreQualification/Questionnaire/QuestionnaireStatus';
import { UpgradeToUnlockPreQualDialog } from '../PreQualification/UpgradeToUnlockPreQualDialog';
import { useCompanyFeatureFlags } from '../../companyFeatureFlags';

const orderedQuestionnaireStatuses = [
  QuestionnaireStatus.SENT,
  QuestionnaireStatus.IN_PROGRESS,
  QuestionnaireStatus.PENDING_REVIEW,
  QuestionnaireStatus.APPROVED,
  QuestionnaireStatus.EXPIRED,
  QuestionnaireStatus.REJECTED,
  QuestionnaireStatus.DECLINED,
];

export const AddRecipientFromPreQualButton = ({
  recipientIds,
  addRecipients,
}: {
  recipientIds: string[];
  addRecipients: (companyIds: string[]) => void;
}) => {
  const { t } = useTranslation();
  const addFromPreQualModal = useModalState();
  const upgradeDialog = useModalState();
  const { status } = useSentQuestionnairesByRecipient();
  const companyFeatureFlags = useCompanyFeatureFlags();

  return (
    <>
      {companyFeatureFlags.preQualEnabled ? (
        <Button
          variant="secondary"
          iconLeft="file-check"
          iconLeftRegular
          disabled={status !== 'success'}
          onClick={addFromPreQualModal.open}
        >
          {t('request.preQualification')}
        </Button>
      ) : (
        <Button
          variant="secondary"
          iconLeft="lock"
          onClick={upgradeDialog.open}
        >
          {t('request.preQualification')}
        </Button>
      )}
      {addFromPreQualModal.isOpen && (
        <AddRecipientFromPreQualModal
          recipientIds={recipientIds}
          isOpen={addFromPreQualModal.isOpen}
          onCancel={addFromPreQualModal.close}
          onSave={addRecipients}
        />
      )}
      <UpgradeToUnlockPreQualDialog
        isOpen={upgradeDialog.isOpen}
        onCancel={upgradeDialog.close}
      />
    </>
  );
};

const Footer = ({ disabledCompanyIds, onCancel }: { disabledCompanyIds: string[]; onCancel: () => void }) => {
  const { t } = useTranslation();
  const { isSubmitting, isValid, dirty } = useFormikContext();
  const [{ value: selectedCompanyIds }] = useField('selectedCompanyIds');

  const newlySelectedCompanyIds = React.useMemo(
    () => difference(selectedCompanyIds, disabledCompanyIds),
    [selectedCompanyIds, disabledCompanyIds],
  );

  return (
    <ModalFooter justifyContent="space-between">
      <Text>
        {t(
          'request.addFromPreQualModal.selectedSupplierCount',
          {
            count: newlySelectedCompanyIds.length,
          },
        )}
      </Text>
      <Box>
        <CancelButton onClick={onCancel} />
        <Button type="submit" disabled={isSubmitting || !dirty || !isValid} width={70}>
          {t('general.add')}
        </Button>
      </Box>
    </ModalFooter>
  );
};

const Row = styled<any>(Flex)`
  padding: 0px ${props => props.theme.space[3]}px;
  border-top: ${props => props.theme.borders.lightGray2};
  border-bottom: ${props => props.isLast ? props.theme.borders.lightGray2 : undefined};
`;

const QuestionnairesTable = ({ questionnaires }: { questionnaires: RecipientQuestionnaires['questionnaires'] }) => {
  const { t } = useTranslation(['preQualification', 'general']);

  const columns = React.useMemo(
    () => [
      {
        id: 'name',
        Header: t('questionnaire', { count: 1 }),
        accessor: 'name',
        Cell: TruncateCell,
        flex: 3,
      },
      {
        id: 'status',
        Header: t('status', { ns: 'general' }),
        accessor: 'status',
        Cell: QuestionnaireStatusCell,
        width: '160px',
      },
      {
        id: 'version',
        Header: t('version'),
        accessor: 'meta.fromTemplateVersion',
        width: '100px',
      },
    ],
    [t],
  );

  return (
    <Box sx={{ border: 'lightGray2', fontWeight: '400' }}>
      <CompactTableStyles fixedRowHeight bordered>
        <Table
          columns={columns}
          data={questionnaires}
        />
      </CompactTableStyles>
    </Box>
  );
};

const RecipientCheckboxLabel = ({
  recipientQuestionnaires,
}: {
  recipientQuestionnaires: RecipientQuestionnaires;
}) => {
  const { t } = useTranslation('preQualification');
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <Box flex="1 1 auto" ml={3}>
      <Flex height="35px" alignItems="center" sx={{ gap: 2 }}>
        <Box flex="0 0 auto">
          <CompanyLogo companyId={recipientQuestionnaires._id} size="xs" />
        </Box>
        <Truncate flex="0 0 300px" fontSize={2} color="text" fontWeight={400}>
          {recipientQuestionnaires.name}
        </Truncate>
        <Text flex="1 1 auto" fontSize={1} color="subtext">
          {t('questionnaireCount', { count: recipientQuestionnaires.questionnaires.length })}
        </Text>
        <Box flex="0 0 auto">
          <IconButton
            icon={isExpanded ? 'chevron-up' : 'chevron-down'}
            color="subtext"
            fontSize={3}
            onClick={() => setIsExpanded(isExpanded => !isExpanded)}
          />
        </Box>
      </Flex>
      {isExpanded && (
        <Box py={2} width="600px">
          <QuestionnairesTable questionnaires={recipientQuestionnaires.questionnaires} />
        </Box>
      )}
    </Box>
  );
};

const ListCheckboxLabel = styled(Text)`
  padding-left: ${props => props.theme.space[3]}px;
  font-size: ${props => props.theme.fontSizes[2]}px;
  color: ${props => props.theme.colors.text};
  font-weight: 400;
`;

const RecipientCheckbox = ({
  recipientQuestionnaires,
  disabledCompanyIds,
}: {
  recipientQuestionnaires: RecipientQuestionnaires;
  disabledCompanyIds: string[];
}) => {
  const [{ value: selectedCompanyIds },, helper] = useField('selectedCompanyIds');

  const onToggleCompany = React.useCallback(
    (companyId) => {
      if (selectedCompanyIds.includes(companyId)) {
        helper.setValue(without(selectedCompanyIds, companyId));
      } else {
        helper.setValue([...selectedCompanyIds, companyId]);
      }
    },
    [selectedCompanyIds, helper],
  );

  const CheckboxLabel = React.useMemo(
    () => withProps(RecipientCheckboxLabel, { recipientQuestionnaires }),
    [recipientQuestionnaires],
  );

  return (
    <Checkbox
      label={recipientQuestionnaires.name}
      checked={selectedCompanyIds.includes(recipientQuestionnaires._id)}
      disabled={disabledCompanyIds.includes(recipientQuestionnaires._id)}
      onChange={() => onToggleCompany(recipientQuestionnaires._id)}
      style={{ width: '100%' }}
      CheckboxLabel={CheckboxLabel}
      styledCheckboxSx={{ marginTop: '9px' }}
    />
  );
};

const RecipientsList = ({
  disabledCompanyIds,
  templateFilters,
  statusFilters,
}: {
  disabledCompanyIds: string[];
  templateFilters: { value: string; label: string }[];
  statusFilters: { value: QuestionnaireStatus; label: string }[];
}) => {
  const { t } = useTranslation(['translation', 'general']);
  const { questionnairesByRecipient } = useSentQuestionnairesByRecipient();
  const [{ value: selectedCompanyIds },, helper] = useField('selectedCompanyIds');

  const visibleRecipientQuestionnaires = React.useMemo(
    () => filter(
      questionnairesByRecipient,
      recipientQuestionnaires => {
        const hasStatusFilter = statusFilters.length > 0;
        const hasTemplateFilter = templateFilters.length > 0;
        const filterTemplateIds = map(templateFilters, templateFilter => templateFilter.value);

        // There can only be one status filter
        const statusFilter = hasStatusFilter ? statusFilters[0].value : undefined;

        if (!hasStatusFilter && !hasTemplateFilter) {
          return true;
        } else if (!hasTemplateFilter) {
          return some(
            recipientQuestionnaires.questionnaires,
            questionnaire => questionnaire.status === statusFilter,
          );
        } else {
          return every(
            filterTemplateIds,
            filterTemplateId => some(
              recipientQuestionnaires.questionnaires,
              questionnaire => (
                questionnaire.meta.fromTemplateId === filterTemplateId &&
                (!hasStatusFilter || questionnaire.status === statusFilter)
              ),
            ),
          );
        }
      },
    ),
    [questionnairesByRecipient, templateFilters, statusFilters],
  );

  const visibleCompanyIds = React.useMemo(
    () => map(visibleRecipientQuestionnaires, recipientQuestionnaires => recipientQuestionnaires._id),
    [visibleRecipientQuestionnaires],
  );

  const visibleCompanyIdsDeselected = React.useMemo(
    () => difference(visibleCompanyIds, selectedCompanyIds),
    [visibleCompanyIds, selectedCompanyIds],
  );

  const visibleCompanyIdsSelected = React.useMemo(
    () => intersection(visibleCompanyIds, selectedCompanyIds),
    [visibleCompanyIds, selectedCompanyIds],
  );

  const allVisibleCompaniesSelected = visibleCompanyIdsDeselected.length === 0;

  const onToggleAll = React.useCallback(
    (event) => {
      if (event.target.checked) {
        helper.setValue(uniq([
          ...selectedCompanyIds,
          ...visibleCompanyIds,
        ]));
      } else {
        helper.setValue(difference(
          selectedCompanyIds,
          difference(visibleCompanyIds, disabledCompanyIds),
        ));
      }
    },
    [helper, visibleCompanyIds, selectedCompanyIds, disabledCompanyIds],
  );

  return !isEmpty(visibleRecipientQuestionnaires) ? (
    <Flex height="100%" flexDirection="column" overflowY="hidden">
      <Row flex="0 0 auto" height="45px" sx={{ borderBottom: 'lightGray2' }}>
        <Checkbox
          label={allVisibleCompaniesSelected ? t('deselectAll', { ns: 'general' }) : t('selectAll', { ns: 'general' })}
          indeterminate={!allVisibleCompaniesSelected && visibleCompanyIdsSelected.length > 0}
          checked={allVisibleCompaniesSelected}
          onChange={onToggleAll}
          style={{ alignItems: 'center' }}
          CheckboxLabel={ListCheckboxLabel}
        />
      </Row>
      <Box flex="1 1 auto" height="100%" overflowY="auto" my="-1px">
        {visibleRecipientQuestionnaires.map((recipientQuestionnaires, index) => (
          <Row
            key={recipientQuestionnaires._id}
            sx={{
              backgroundColor: disabledCompanyIds.includes(recipientQuestionnaires._id)
                ? 'disabledBackground'
                : undefined,
            }}
            isLast={index === visibleRecipientQuestionnaires.length - 1}
          >
            <RecipientCheckbox
              recipientQuestionnaires={recipientQuestionnaires}
              disabledCompanyIds={disabledCompanyIds}
            />
          </Row>
        ))}
      </Box>
    </Flex>
  ) : (
    <Box p={3} height="100%">
      <Pictogram
        variant="empty-state"
        label={t('request.addFromPreQualModal.noSuppliers')}
        labelPosition="right"
      />
    </Box>
  );
};

enum FilterType {
  TEMPLATE = 'template',
  STATUS = 'status',
}

type FilterChipData = {
  type: FilterType;
  value: string;
  label: string;
};

const FilterChip = ({
  filterChip,
  removeFilter,
}: {
  filterChip: FilterChipData;
  removeFilter: (filter: FilterChipData) => void;
}) => {
  const { t } = useTranslation('general');

  const buttonProps = React.useMemo(
    () => ({
      onClick: () => removeFilter(filterChip),
    }),
    [removeFilter, filterChip],
  );

  return (
    <Chip removeButtonProps={buttonProps}>
      {filterChip.type === FilterType.STATUS && (
        `${t('status')}: `
      )}
      {filterChip.label}
    </Chip>
  );
};

const TemplateFilterDropdown = ({
  templateFilters,
  setFilterChips,
  setTemplateFilters,
}: {
  templateFilters: { value: string; label: string }[];
  setFilterChips: React.Dispatch<React.SetStateAction<FilterChipData[]>>;
  setTemplateFilters: React.Dispatch<React.SetStateAction<{
    value: string;
    label: string;
  }[]>>;
}) => {
  const { t } = useTranslation();
  const { questionnairesByRecipient } = useSentQuestionnairesByRecipient();

  const templateItems = React.useMemo(
    () => {
      const templateById: Record<string, { _id: string; name: string }> = {};

      questionnairesByRecipient.forEach(recipientQuestionnaires => {
        recipientQuestionnaires.questionnaires.forEach(questionnaire => {
          if (!templateById[questionnaire.meta.fromTemplateId]) {
            templateById[questionnaire.meta.fromTemplateId] = {
              _id: questionnaire.meta.fromTemplateId,
              name: questionnaire.name, // Questionnaire name is the same as template name
            };
          }
        });
      });

      return map(
        sortBy(values(templateById), template => toLower(template.name)),
        template => ({
          value: template._id,
          label: template.name,
        }),
      );
    },
    [questionnairesByRecipient],
  );

  const toggleTemplateFilter = React.useCallback(
    (selectedTemplateFilters: { value: string; label: string }[]) => {
      const { added, removed } = diffArrayBy(selectedTemplateFilters, templateFilters, 'value');
      const removedTemplateIds = removed.map(filter => filter.value);

      setFilterChips(filterChips => concat(
        reject(filterChips, filterChip => removedTemplateIds.includes(filterChip.value)),
        map(
          added,
          templateFilter => ({
            type: FilterType.TEMPLATE,
            ...templateFilter,
          }),
        ),
      ));

      setTemplateFilters(selectedTemplateFilters);
    },
    [templateFilters, setFilterChips, setTemplateFilters],
  );

  return (
    <SelectDropdownMenu
      multi
      getButtonText={(selectedItems) =>
        selectedItems?.length >= 1
          ? t('request.addFromPreQualModal.selectedQuestionnaireCount', { count: selectedItems.length })
          : t('request.addFromPreQualModal.selectQuestionnaires')
      }
      menuWidth={300}
      truncate={false}
      menuZIndex={202}
      menuMaxHeightInItems={5.4}
      itemToString={(item: any) => item?.value}
      // @ts-expect-error ts(18047) FIXME: 'item' is possibly 'null'.
      renderItem={item => <Truncate>{item.label}</Truncate>}
      onChange={toggleTemplateFilter}
      items={templateItems}
      small={false}
      selectedItems={templateFilters}
    />
  );
};

const StatusFilterDropdown = ({
  statusFilters,
  setFilterChips,
  setStatusFilters,
}: {
  statusFilters: { value: QuestionnaireStatus; label: string }[];
  setFilterChips: React.Dispatch<React.SetStateAction<FilterChipData[]>>;
  setStatusFilters: React.Dispatch<React.SetStateAction<{
    value: QuestionnaireStatus;
    label: string;
  }[]>>;
}) => {
  const { t } = useTranslation(['translation', 'preQualification']);

  const statusItems = React.useMemo(
    () => map(
      orderedQuestionnaireStatuses,
      status => ({
        value: status,
        label: t(`questionnaireStatus.${status}`, { ns: 'preQualification' }),
      }),
    ),
    [t],
  );

  const toggleStatusFilter = React.useCallback(
    (selectedStatusFilters: { value: QuestionnaireStatus; label: string }[]) => {
      const { added, removed } = diffArrayBy(selectedStatusFilters, statusFilters, 'value');
      const removedStatuses = removed.map(filter => filter.value);

      setFilterChips(filterChips => concat(
        reject(filterChips, filterChip => removedStatuses.includes(filterChip.value)),
        map(
          added,
          statusFilter => ({
            type: FilterType.STATUS,
            ...statusFilter,
          }),
        ),
      ));

      setStatusFilters(selectedStatusFilters);
    },
    [statusFilters, setFilterChips, setStatusFilters],
  );

  return (
    <SelectDropdownMenu
      getButtonText={(selectedItems) =>
        selectedItems?.length >= 1
          ? t(`questionnaireStatus.${selectedItems[0].value}`, { ns: 'preQualification' })
          : t('request.addFromPreQualModal.selectStatus')
      }
      truncate={false}
      menuZIndex={202}
      itemToString={(item: any) => item?.value}
      // @ts-expect-error ts(18047) FIXME: 'item' is possibly 'null'.
      renderItem={item => <Truncate>{item.label}</Truncate>}
      onChange={toggleStatusFilter}
      items={statusItems}
      selectedItems={statusFilters}
      small={false}
      allowEmptySelection
      variant={statusFilters.length > 0 ? 'primary-outline' : 'secondary-outline'}
    />
  );
};

type Props =
  { recipientIds: string[]; onCancel: any; onSave: any } &
  ModalProps;

export const AddRecipientFromPreQualModal: React.FC<Props> = ({ recipientIds, onCancel, onSave, ...props }) => {
  const { t } = useTranslation(['translation', 'preQualification']);
  // Keeps both template and status filters in the order they were added. Used for the filter chips.
  const [filterChips, setFilterChips] = React.useState<FilterChipData[]>([]);
  const [templateFilters, setTemplateFilters] = React.useState<{ value: string; label: string }[]>([]);
  const [statusFilters, setStatusFilters] = React.useState<{ value: QuestionnaireStatus; label: string }[]>([]);

  const removeFilter = React.useCallback(
    (filter: FilterChipData) => {
      if (filter.type === FilterType.TEMPLATE) {
        setTemplateFilters(templateFilters => reject(
          templateFilters,
          templateFilter => templateFilter.value === filter.value,
        ));
      } else {
        setStatusFilters(statusFilters => reject(
          statusFilters,
          statusFilter => statusFilter.value === filter.value,
        ));
      }

      setFilterChips(filterChips => reject(filterChips, filterChip => filterChip.value === filter.value));
    },
    [],
  );

  const clearAllFilters = React.useCallback(
    () => {
      setFilterChips([]);
      setTemplateFilters([]);
      setStatusFilters([]);
    },
    [],
  );

  return (
    <Modal
      style={{ content: { width: '800px' } }}
      onRequestClose={onCancel}
      {...props}
    >
      <Formik
        initialValues={{
          selectedCompanyIds: recipientIds,
        }}
        validationSchema={
          yup.object().shape({
            selectedCompanyIds: yup.array().of(yup.string()).min(1),
          })
        }
        onSubmit={({ selectedCompanyIds }) => {
          const newlySelectedCompanyIds = difference(selectedCompanyIds, recipientIds);

          onSave(newlySelectedCompanyIds);
          onCancel();
        }}
      >
        <Form>
          <ModalHeader onClose={onCancel}>
            {t('request.preQualification')}
          </ModalHeader>
          <ModalBody p={0}>
            <Flex flexDirection="column" height="9999999px" maxHeight="inherit" overflowY="hidden">
              <Stack p={3} gap={3} flex="0 0 auto">
                <MessageBlock variant="info" mt={0}>
                  {t('request.addFromPreQualModal.info')}
                </MessageBlock>
                <Flex sx={{ gap: 2 }}>
                  <Box>
                    <TemplateFilterDropdown
                      templateFilters={templateFilters}
                      setFilterChips={setFilterChips}
                      setTemplateFilters={setTemplateFilters}
                    />
                  </Box>
                  <Box>
                    <StatusFilterDropdown
                      statusFilters={statusFilters}
                      setFilterChips={setFilterChips}
                      setStatusFilters={setStatusFilters}
                    />
                  </Box>
                </Flex>
                {filterChips.length > 0 && (
                  <Box>
                    {filterChips.map(filterChip => (
                      <FilterChip
                        key={filterChip.value}
                        filterChip={filterChip}
                        removeFilter={removeFilter}
                      />
                    ))}
                    <WrapperButton color="primary" fontWeight={500} p={1} onClick={clearAllFilters}>
                      {t('request.addFromPreQualModal.clearAll')}
                    </WrapperButton>
                  </Box>
                )}
              </Stack>
              <Box flex="1 1 auto" overflow="hidden">
                <RecipientsList
                  disabledCompanyIds={recipientIds}
                  templateFilters={templateFilters}
                  statusFilters={statusFilters}
                />
              </Box>
            </Flex>
          </ModalBody>
          <Footer disabledCompanyIds={recipientIds} onCancel={onCancel} />
        </Form>
      </Formik>
    </Modal>
  );
};
