import * as React from 'react';
import { useIsFetching, useIsMutating } from 'react-query';
import { findIndex } from 'lodash';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { useSwitchToExchange } from '../../../../ExchangeModal/SwitchToExchange';
import { useQuestionnaireData } from '../questionnaireUtils';

type ExchangeState = {
  isResolved: boolean;
  turn?: string[];
  currentCompanyId: string;
};

const isTurn = (exchange?: ExchangeState) =>
  !exchange?.turn || exchange.turn.includes(exchange.currentCompanyId);

export const BidAutoAdvancer = ({
  exchangeId,
  setIsLoading,
}: {
  exchangeId: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
  const questionnaire = useQuestionnaireData();
  const [watchExchangeStateChange, setWatchExchangeStateChange] = React.useState(false);
  const isMutating = useIsMutating(['sendQuestionnaireExchangeReply']);
  const { switchToExchange, verticalTargets } = useSwitchToExchange();

  const isFetchingExchange = useIsFetching({ queryKey: ['questionnaireExchange'] });
  const isFetchingQuestionnaire = useIsFetching({ queryKey: ['questionnaire'] });

  const isFetchingOrMutating = (
    isMutating ||
    isFetchingExchange ||
    isFetchingQuestionnaire
  );

  const exchange = questionnaire.exchangeById[exchangeId] as ExchangeState;

  useWatchValue(
    exchangeId,
    () => setWatchExchangeStateChange(false),
  );
  useWatchValue(
    isFetchingOrMutating,
    (isFetchingOrMutating, wasFetchingOrMutating) => {
      if (wasFetchingOrMutating && !isFetchingOrMutating) {
        setIsLoading(false);
        setWatchExchangeStateChange(false);
      }
    },
  );
  useWatchValue(
    isMutating,
    (isMutating, hasBeenMutating) => {
      if (isMutating) {
        setIsLoading(true);
      }

      if (isMutating && !hasBeenMutating) {
        setWatchExchangeStateChange(true);
      }
    },
  );
  useWatchValue(
    exchange,
    (exchange, previousExchange) => {
      if (
        watchExchangeStateChange && (
          (exchange?.isResolved && !previousExchange?.isResolved) ||
          (!isTurn(exchange) && isTurn(previousExchange))
        )
      ) {
        setWatchExchangeStateChange(false);

        const currentTargetIndex = findIndex(verticalTargets, { exchangeId });
        const nextTarget = [
          // @ts-expect-error ts(18048) FIXME: 'verticalTargets' is possibly 'undefined'.
          ...verticalTargets.slice(currentTargetIndex + 1),
          // @ts-expect-error ts(18048) FIXME: 'verticalTargets' is possibly 'undefined'.
          ...verticalTargets.slice(0, currentTargetIndex),
        ]
          .find(({ exchangeId: nextExchangeId }) => {
            // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
            const targetExchange = questionnaire.exchangeById[nextExchangeId] as ExchangeState;

            if (!targetExchange) {
              return false;
            }

            return isTurn(targetExchange) && !targetExchange.isResolved;
          });

        if (nextTarget) {
          switchToExchange(nextTarget);
        } else {
          switchToExchange(null);
        }
      }
    },
  );

  return null;
};
