import * as React from 'react';
import { keyBy, cloneDeep, uniqBy } from 'lodash';
import { StructuredTags } from './StructuredTags';
import { toTagTree, getAncestorsForBasicTag } from './StructuredTagPicker/utils';

type DeliveryCountriesProps = {
  deliveryCountries: string[];
  regionTags: any[];
};

export const DeliveryCountries: React.FC<DeliveryCountriesProps> = ({
  deliveryCountries,
  regionTags,
}) => {
  const tree = React.useMemo(
    () => {
      // We only need a subset of the entire tree
      const selectedTags = regionTags.filter(tag => deliveryCountries.includes(tag._id));
      const allTagsById = keyBy(regionTags, '_id');
      let allAncestors: any[] = [];

      // Get all ancestors for the selected tags; they are needed for display purposes
      for (const tag of selectedTags) {
        const ancestors = getAncestorsForBasicTag(allTagsById, tag);
        allAncestors = allAncestors.concat(ancestors);
      }

      const uniqueAncestors = uniqBy(allAncestors, '_id');
      const tags = [...selectedTags, ...uniqueAncestors];
      const selectedTagsById = keyBy(cloneDeep(tags), '_id');

      return toTagTree(selectedTagsById);
    },
    [regionTags, deliveryCountries],
  );

  return (
    <StructuredTags tags={tree} px={20} pt={20} />
  );
};
