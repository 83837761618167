import { useTranslation } from 'react-i18next';
import { ExchangeStatus, ExchangeType } from '@deepstream/common/rfq-utils';
import { IconValue, exchangeStatusConfig } from '@deepstream/common';
import { Text } from 'rebass/styled-components';
import { IconText, IconTextProps } from '@deepstream/ui-kit/elements/text/IconText';
import { withProps } from '@deepstream/ui-utils/withProps';
import { ExchangeSnapshot } from './types';
import { getExchangeSnapshotStatus } from './exchangeStatus';
import { useHooks } from './useHooks';

interface FieldStatus {
  icon: IconValue;
  text: string;
  color: string;
}

export const useExchangeFieldStatus = (exchange?: ExchangeSnapshot, shouldReply = true): FieldStatus | null => {
  const { usePagePermissions } = useHooks();
  const pagePermissions = usePagePermissions();

  if (!exchange || exchange.def.type === ExchangeType.INTERNAL_DOCUMENT) {
    return null;
  }

  const exchangeStatus = getExchangeSnapshotStatus(exchange, pagePermissions, shouldReply);

  if (exchangeStatus === ExchangeStatus.ACTION_REQUIRED) {
    return {
      icon: 'exclamation-circle',
      text: 'request.exchange.exchangeStatus.actionRequired',
      color: 'danger',
    };
  } else if (exchangeStatus === ExchangeStatus.WAITING_FOR_TEAM) {
    return {
      icon: 'exclamation-circle',
      text: 'request.exchange.exchangeStatus.waitingForTeam',
      color: 'subtext',
    };
  } else if ([
    ExchangeStatus.WAITING_FOR_SUPPLIER,
    ExchangeStatus.WAITING_FOR_BUYER,
    ExchangeStatus.WAITING_FOR_COUNTER_PARTY,
    ExchangeStatus.INCOMPLETE,
  ].includes(exchangeStatus as ExchangeStatus)) {
    return {
      icon: 'spinner',
      text: 'request.exchange.exchangeStatus.noResponse',
      color: 'subtext',
    };
  } else {
    return {
      icon: exchangeStatusConfig[exchangeStatus].buyer?.icon?.default?.value as IconValue ?? 'exclamation-circle',
      text: `request.exchange.exchangeStatus.${exchangeStatus}`,
      color: 'subtext',
    };
  }
};

export const NoResponseCell = ({
  exchange,
  shouldReply,
  showTooltip,
}: {
  exchange: ExchangeSnapshot;
  shouldReply?: boolean;
  showTooltip?: boolean;
}) => {
  const { t } = useTranslation();
  const exchangeFieldStatus = useExchangeFieldStatus(exchange, shouldReply);

  if (!exchangeFieldStatus) {
    return null;
  }

  const { icon, text, color } = exchangeFieldStatus;

  return (
    <IconText
      icon={icon as IconTextProps['icon']}
      text={t(text)}
      iconColor={color}
      color="subtext"
      showTooltip={showTooltip}
    />
  );
};

export const NoResponseCellShouldReply = withProps(NoResponseCell, { shouldReply: true });
export const NoResponseCellShouldNotReply = withProps(NoResponseCell, { shouldReply: false });

export const IncompleteCell = ({ exchange, shouldReply = true }: { exchange: ExchangeSnapshot; shouldReply: boolean }) => {
  const { t } = useTranslation();
  const exchangeFieldStatus = useExchangeFieldStatus(exchange, shouldReply);

  if (!exchangeFieldStatus) {
    return null;
  }

  const { icon, color } = exchangeFieldStatus;

  return (
    <IconText
      icon={icon as IconTextProps['icon']}
      text={t('request.exchange.exchangeStatus.incomplete')}
      iconColor={color}
      color="subtext"
    />
  );
};

export const IncompleteCellShouldReply = withProps(IncompleteCell, { shouldReply: true });
export const IncompleteCellShouldNotReply = withProps(IncompleteCell, { shouldReply: false });

export const PreviousStageEmptyResponseCell = () => {
  const { t } = useTranslation();

  return (
    <Text color="subtext">
      {t('request.exchange.exchangeStatus.noResponse')}
    </Text>
  );
};

export const PreviousStageIncompleteCell = () => {
  const { t } = useTranslation();

  return (
    <Text color="subtext">
      {t('request.exchange.exchangeStatus.incomplete')}
    </Text>
  );
};
