import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Panel, PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { useApi } from '../../api';
import { NotificationsTableDataProvider } from './NotificationsTableDataProvider';
import { NotificationsTableContent } from './NotificationsTableContent';
import { NotificationsTableHeader } from './NotificationsTableHeader';
import { useLocalStorageFilterProps } from '../../filtering';
import { BasicTableStyles } from '../../TableStyles';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useCurrentUser } from '../../useCurrentUser';

const statusItemMap = {
  'read': 'envelope-open-o',
  'unread': 'envelope',
};

const renderStatusItem = (item) => {
  if (!item) return '';

  return (
    <IconText
      mr={2}
      icon={statusItemMap[item.id]}
      text={item.name}
    />
  );
};

export const NotificationsTable = () => {
  const { t } = useTranslation();
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();

  const filterItems = useMemo(() => ([
    { name: t('notificationsPage.filter.read'), id: 'read' },
    { name: t('notificationsPage.filter.unread'), id: 'unread' },
  ]), [t]);

  const statusFilter = useLocalStorageFilterProps({
    storageKey: `${currentCompanyId}.${user._id}.notifications.status`,
    items: filterItems,
    renderItem: renderStatusItem,
    idProp: 'id',
    getQueryParam: (items) => {
      if (!items.length) return {};

      return { seen: items[0].id === 'read' };
    },
  });

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const handlePageChange = useCallback(({ pageIndex, pageSize }) => {
    setPagination({
      pageIndex,
      pageSize,
    });
  }, []);

  return (
    <NotificationsTableDataProvider
      queryTag="notificationsTable"
      queryFn={api.getNotifications}
      pageIndex={pagination.pageIndex}
      pageSize={pagination.pageSize}
      statusFilter={statusFilter}
      setPageControls={handlePageChange}
    >
      <Panel>
        <NotificationsTableHeader
          statusFilter={statusFilter}
        />
        <PanelDivider />
        <BasicTableStyles hoverBackgroundColor="lightGray6">
          <NotificationsTableContent />
        </BasicTableStyles>
      </Panel>
    </NotificationsTableDataProvider>
  );
};
