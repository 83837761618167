import * as React from 'react';
import styled from 'styled-components';
import { Text, ButtonProps as RebassButtonProps } from 'rebass/styled-components';
import { transparentize } from 'polished';
import { Icon, IconProps } from '../icon/Icon';
import { WrapperButton } from './WrapperButton';
import { ButtonVariant } from '../../theme/types';

export type TabButtonProps =
  Omit<RebassButtonProps, 'variant'> &
  {
    // type: 'button' | 'submit';
    small?: boolean;
    iconLeft?: IconProps['icon'];
    iconLeftRegular?: IconProps['regular'];
    iconRight?: IconProps['icon'];
    style?: React.CSSProperties;
    borderRadius?: number | string;
    variant?: ButtonVariant;
  };

const StyledTabButton = styled(WrapperButton)`
  background: transparent;
  border-radius: 0px;

  &:focus {
    background-color: ${props => transparentize(0.85, props.theme.colors.primary)};
    outline: none;
  }
`;

export const TabButton = ({
  children,
  iconLeft,
  fontWeight = 500,
  fontSize = 2,
  color = 'subtext',
  ...props
}: TabButtonProps) => (
  <StyledTabButton {...props}>
    <Text fontFamily="primary" color={color} fontSize={fontSize} fontWeight={fontWeight}>
      {iconLeft && <Icon icon={iconLeft} mr={children ? 1 : 0} />}
      {children}
    </Text>
  </StyledTabButton>
);
