import { useMemo } from 'react';
import { slugify } from '@deepstream/utils/slugify';
import { useRfqId } from '../../useRfq';
import * as rfx from '../../rfx';

export const useExternalPublicRequestUrl = () => {
  const rfqId = useRfqId();
  const structure = rfx.useStructure();

  return useMemo(() => {
    if (structure?.summary?.subject) {
      const slugifiedSubject = slugify(structure?.summary?.subject);

      return `${process.env.NX_PUBLIC_PAGES_URL}/requests/${rfqId}/${slugifiedSubject}`;
    } else {
      // This should not happen in theory, but to be on the safe side, we should  return a valid URL
      return `${process.env.NX_PUBLIC_PAGES_URL}/requests/${rfqId}`;
    }
  }, [rfqId, structure]);
};

export const useInternalPublicRequestUrl = () => {
  const rfqId = useRfqId();
  const structure = rfx.useStructure();

  return useMemo(() => {
    if (structure?.summary?.subject) {
      const slugifiedSubject = slugify(structure?.summary?.subject);

      return `${process.env.NX_APP_URL}/network/requests/${rfqId}/${slugifiedSubject}`;
    } else {
      // This should not happen in theory, but to be on the safe side, we should return a valid URL
      return `${process.env.NX_APP_URL}/network/requests/${rfqId}`;
    }
  }, [rfqId, structure]);
};
