import * as React from 'react';
import { Panel, PanelProps } from '@deepstream/ui-kit/elements/Panel';
import { useContractState } from './contract';

export const ContractDraftPanel: React.FC<PanelProps & { panelId: string; suppressBoxShadow?: boolean }> = ({
  panelId,
  suppressBoxShadow,
  ...props
}) => {
  // @ts-expect-error ts(2339) FIXME: Property 'editingPanelId' does not exist on type 'ContractStateContextType | undefined'.
  const { editingPanelId } = useContractState();

  const isEditing = Boolean(editingPanelId);
  const isEditingThisPanel = panelId === editingPanelId;

  const isEditingAnotherPanel = isEditing && !isEditingThisPanel;

  return (
    <Panel
      sx={{
        opacity: isEditingAnotherPanel ? 0.5 : 1,
        boxShadow: isEditingThisPanel && !suppressBoxShadow ? '0 0 8px 0 rgba(0, 0, 0, 0.3)' : '',
      }}
      {...props}
    />
  );
};
