import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';

import { Modal, ModalHeader, ModalBody, ModalFooter, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { useModalState } from '../../ui/useModalState';
import { useDocumentsDelete, QueryDocument } from './DocumentsTableDataProvider';

type DocumentDelete = Pick<QueryDocument, '_id' | 'name'>;

export const DocumentsDeleteModal = ({
  trigger,
  documents,
}: {
  trigger: ({ open } : { open: (() => void) }) => React.ReactNode;
  documents: DocumentDelete[];
}) => {
  const { t } = useTranslation();
  const { isOpen, open, close } = useModalState();
  const deleteDocuments = useDocumentsDelete();
  const ids = documents.map((item) => item._id);

  return (
    <>
      {trigger({ open })}
      <Modal
        isOpen={isOpen}
        shouldCloseOnEsc
      >
        <ModalHeader>
          {documents.length === 1 && documents[0]?.name ? (
            t('drivePage.warnings.deleteNamedDocumentTitle', { document: documents[0]?.name })
          ) : (
            t('drivePage.warnings.deleteDocumentTitle', { count: documents.length })
          )}
        </ModalHeader>
        <ModalBody>
          <MessageBlock variant="warn">
            {t('drivePage.warnings.deleteDocumentContent', { count: documents.length })}
          </MessageBlock>
        </ModalBody>
        <ModalFooter>
          <CancelButton onClick={close} />
          <Button
            variant="danger"
            onClick={async () => {
              await deleteDocuments(ids);
              close();
            }}
          >
            {t('general.delete') }
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
