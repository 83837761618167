import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider, useGridMenuState } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Pictogram } from '@deepstream/ui-kit';
import { GridMenu, GridMenuItem } from '@deepstream/ui-kit/grid/EditableGrid/GridMenu';
import { useCallback, useState } from 'react';
import { useApi, wrap } from '../../../api';
import { LoadingPanel } from '../../../ui/Loading';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { PreQualQuestion } from '../../../types';
import { PreQualQuestionsGrid } from '../PreQualQuestionsGrid';
import { useModalState } from '../../../ui/useModalState';
import { RestoreQuestionModal } from './RestoreQuestionModal';
import { useCompanyFeatureFlags } from '../../../companyFeatureFlags';

export const ArchiveQuestionGridMenu = ({
  onRestore,
}: {
  onRestore: () => void;
}) => {
  const { t } = useTranslation('preQualification');
  const companyFeatureFlags = useCompanyFeatureFlags();

  return (
    <GridMenu>
      <GridMenuItem
        icon="undo"
        onSelect={onRestore}
        disabled={!companyFeatureFlags.preQualEnabled}
      >
        {t('archive.restoreQuestion')}
      </GridMenuItem>
    </GridMenu>
  );
};

const ArchiveQuestionsPanel = ({
  questions,
}: {
  questions: PreQualQuestion[];
}) => {
  const { t } = useTranslation(['preQualification']);
  const [selectedQuestion, setSelectedQuestion] = useState<PreQualQuestion | null>(null);
  const restoreQuestionModal = useModalState();

  const { menuReferenceId, dataIndex } = useGridMenuState();

  const onRestoreQuestion = useCallback(() => {
    // @ts-expect-error ts(2538) FIXME: Type 'null' cannot be used as an index type.
    setSelectedQuestion(questions[dataIndex]);
    restoreQuestionModal.open();
  }, [questions, dataIndex, restoreQuestionModal]);

  return (
    <>
      <Panel sx={{ marginBottom: 4 }}>
        <PanelHeader heading={t('archive.archiveSection')} />
        <PanelDivider />
        {questions.length > 0 ? (
          <>
            <PanelPadding p={3}>
              <PreQualQuestionsGrid questions={questions} />
            </PanelPadding>
            {menuReferenceId && (
              <ArchiveQuestionGridMenu onRestore={onRestoreQuestion} />
            )}
          </>
        ) : (
          <PanelPadding p="12px 16px">
            <Pictogram
              variant="empty-state"
              label={t('archive.noArchivedQuestions')}
              labelPosition="right"
              labelSx={{ fontWeight: 'normal' }}
            />
          </PanelPadding>
        )}
      </Panel>
      {restoreQuestionModal.isOpen && (
        <RestoreQuestionModal
          // @ts-expect-error ts(2322) FIXME: Type 'PreQualQuestion | null' is not assignable to type 'PreQualQuestion'.
          question={selectedQuestion}
          close={restoreQuestionModal.close}
        />
      )}
    </>
  );
};

export const ArchiveQuestions = () => {
  const { t } = useTranslation();
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { data: archivedQuestions, isLoading, isError } = useQuery(
    ['archiveQuestions', { currentCompanyId }],
    wrap(api.getPreQualArchivedQuestions),
  );

  if (isLoading) {
    return <LoadingPanel />;
  }

  if (isError) {
    return <ErrorPanel error={t('errors.unexpected')} />;
  }

  return (
    <GridIdPrefixProvider>
      <GridMenuStateProvider>
        {/*
         // @ts-expect-error ts(2322) FIXME: Type 'PreQualQuestion[] | undefined' is not assignable to type 'PreQualQuestion[]'. */}
        <ArchiveQuestionsPanel questions={archivedQuestions} />
      </GridMenuStateProvider>
    </GridIdPrefixProvider>
  );
};
