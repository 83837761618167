import { useMemo } from 'react';
import * as Sentry from '@sentry/react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { CsvSeparator } from '@deepstream/common/user-utils';
import { DateFormat, localeFormatDate } from '@deepstream/utils';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { ModalForm, ModalFormProps } from '../../ModalForm';
import { PropertyList } from '../../PropertyList';
import { useModalState } from '../../ui/useModalState';
import { EditProperty } from './utils';
import { useCurrentUser, useCurrentUserLocale } from '../../useCurrentUser';
import { useUpdateUserProfile } from './useUserMutation';
import { SelectField } from '../../form/SelectField';
import { Datetime2, momentToDateFnsDateFormat } from '../../Datetime';

type EditPhoneNumberModalProps =
  Omit<ModalFormProps, 'heading' | 'initialValues' | 'validationSchema' | 'onSubmit'> &
  { dateFormat: string | undefined; onSuccess: any };

const EditDateFormatModal = ({ dateFormat, onSuccess, ...props }: EditPhoneNumberModalProps) => {
  const { t } = useTranslation();
  const fieldName = t('user.preferences.dateFormat');
  const locale = useCurrentUserLocale();
  const [updateUserProfile] = useUpdateUserProfile({
    fieldName,
    onSuccess,
  });

  const items = useMemo(() => {
    const date = new Date();

    return [
      { value: 'DD MMM YYYY', label: localeFormatDate(date, DateFormat.DD_MMM_YYYY, { locale }) },
      { value: 'DD-MM-YYYY', label: localeFormatDate(date, DateFormat.DD_MM_YYYY_DASH, { locale }) },
      { value: 'MM/DD/YYYY', label: localeFormatDate(date, DateFormat.MM_DD_YYYY_SLASH, { locale }) },
      { value: 'YYYY-MM-DD', label: localeFormatDate(date, DateFormat.YYYY_MM_DD_DASH, { locale }) },
    ];
  }, [locale]);

  return (
    <ModalForm
      heading={t('user.preferences.editDateFormat')}
      initialValues={{
        dateFormat,
      }}
      validationSchema={yup.object({
        dateFormat: yup.string().required(),
      })}
      onSubmit={async ({ dateFormat }, { setSubmitting }) => {
        try {
          await updateUserProfile({ preferences: { dateFormat } });
        } catch (error) {
          Sentry.captureException(error);
        } finally {
          setSubmitting(false);
        }
      }}
      {...props}
    >
      <SelectField
        required
        name="dateFormat"
        label={fieldName}
        items={items}
      />
    </ModalForm>
  );
};

type EditCsvSeparatorModalProps =
  Omit<ModalFormProps, 'heading' | 'initialValues' | 'validationSchema' | 'onSubmit'> &
  { csvSeparator?: string; onSuccess: any };

const EditCsvSeparatorModal = ({ csvSeparator, onSuccess, ...props }: EditCsvSeparatorModalProps) => {
  const { t } = useTranslation();
  const fieldName = t('user.preferences.csvSeparator');

  const [updateUserProfile] = useUpdateUserProfile({
    fieldName,
    onSuccess,
  });

  return (
    <ModalForm
      heading={t('user.preferences.editCsvSeparator')}
      initialValues={{
        csvSeparator,
      }}
      validationSchema={yup.object({
        csvSeparator: yup.string().required(),
      })}
      onSubmit={async ({ csvSeparator }, { setSubmitting }) => {
        try {
          await updateUserProfile({ preferences: { csvSeparator } });
        } catch (error) {
          Sentry.captureException(error);
        } finally {
          setSubmitting(false);
        }
      }}
      style={{ content: { maxWidth: '500px' } }}
      {...props}
    >
      <MessageBlock variant="info" mt={0}>
        {t('user.preferences.csvSeparatorInfo')}
      </MessageBlock>
      <SelectField
        required
        name="csvSeparator"
        label={fieldName}
        items={[
          { label: t(`user.preferences.${CsvSeparator.COMMA}`), value: CsvSeparator.COMMA },
          { label: t(`user.preferences.${CsvSeparator.SEMICOLON}`), value: CsvSeparator.SEMICOLON },
          { label: t(`user.preferences.${CsvSeparator.TAB}`), value: CsvSeparator.TAB },
        ]}
      />
    </ModalForm>
  );
};

export const FormattingPanel = () => {
  const user = useCurrentUser();
  const editDateFormatModal = useModalState();
  const editCsvSeparatorModal = useModalState();
  const { t } = useTranslation();

  return (
    <>
      <Panel heading={t('user.preferences.formatting')}>
        <PropertyList
          properties={[{
            name: t('user.preferences.dates'),
            value: user.preferences?.dateFormat,
            Component: ({ value }) => (
              <Datetime2 value={new Date()} format={momentToDateFnsDateFormat[value || 'DD MMM YYYY']} />
            ),
          }, {
            name: t('user.preferences.csvSeparator'),
            value: user.preferences?.csvSeparator,
            Component: ({ value }) => value ? (
              <>
                {t(`user.preferences.${value}`)}
              </>
            ) : (
              <EmDash />
            ),
          }]}
        >
          <EditProperty
            onClick={editDateFormatModal.open}
            data-test="edit-date-format"
          />
          <EditProperty
            onClick={editCsvSeparatorModal.open}
            data-test="edit-csv-separator"
          />
        </PropertyList>
      </Panel>
      <EditDateFormatModal
        dateFormat={user.preferences?.dateFormat}
        isOpen={editDateFormatModal.isOpen}
        onSuccess={editDateFormatModal.close}
        onCancel={editDateFormatModal.close}
      />
      <EditCsvSeparatorModal
        csvSeparator={user.preferences?.csvSeparator}
        isOpen={editCsvSeparatorModal.isOpen}
        onSuccess={editCsvSeparatorModal}
        onCancel={editCsvSeparatorModal.close}
      />
    </>
  );
};
