import { useEffect, useState, useMemo, useReducer, useCallback } from 'react';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { noop, map, mapValues, keyBy, find, keys, isEqual, differenceBy, sumBy } from 'lodash';
import { RecipientSource, RfqStatus, Sender } from '@deepstream/common/rfq-utils';
import { REQUEST_MAX_SUPPLIER_COUNT } from '@deepstream/common/constants';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import * as rfx from '../../rfx';
import { useApi, wrap } from '../../api';
import { CompanyRoles } from '../../types';

import { reducer } from './reducer';
import {
  SupplierState,
  CompanyProfile,
  User,
} from './types';
import { hasSizeRelevantExchangeType, useModelSizeLimits } from '../../modelSizeLimits';

const SuppliersContext = React.createContext<{
  isLive: boolean;
  rfqName?: string;
  suppliers: SupplierState[];
  senders: Sender[];
  addSupplier:(supplierId: string, source?: RecipientSource) => void;
  addSuppliersBulk: (supplierIds: string[], source?: RecipientSource) => void;
  removeSupplier: (supplierId: string) => void;
  addInvitations: (any) => void;
  addSupplierByUser: (userId: string, companyId: string, source?: RecipientSource) => void;
  updateUsersStatus: (supplierId: string, users: { [_id: string]: boolean }) => void;
  isLoading: boolean;
  isReadOnly: boolean;
  maxSupplierCount: number;
  hasChanges: boolean;
  addedCompaniesCount: number;
  addedUsersCount: number;
  excessSupplierCount: number;
}>({
  isLive: false,
  isReadOnly: false,
  rfqName: undefined,
  suppliers: [],
  senders: [],
  removeSupplier: noop,
  addSupplier: noop,
  addSuppliersBulk: noop,
  addInvitations: noop,
  addSupplierByUser: noop,
  updateUsersStatus: noop,
  isLoading: true,
  maxSupplierCount: 0,
  hasChanges: false,
  addedCompaniesCount: 0,
  addedUsersCount: 0,
  excessSupplierCount: 0,
});

export const useFetchSupplierCompany = () => {
  const api = useApi();
  const client = useQueryClient();

  return useCallback(async ({
    companyId,
    role,
    includeIfPending,
  }: {
    companyId: string;
    role: keyof CompanyRoles;
    includeIfPending?: string[];
  }): Promise<{
    company: CompanyProfile;
    users: User[]
  }> => {
    const companyQuery = client.fetchQuery<CompanyProfile>(
      ['supplierCompanyProfile', { companyId }],
      wrap(api.getPublicCompany),
    );

    const usersQuery = client.fetchQuery<User[]>(
      ['supplierCompanyUsersByRole', { companyId, role, includeIfPending }],
      wrap(api.getCompanyUsersByRole),
    );

    const [company, users] = await Promise.all([companyQuery, usersQuery]);

    return { company, users };
  }, [client, api]);
};

const useLoadRequestSuppliers = () => {
  const [existingSuppliers, setExistingSuppliers] = useState<SupplierState[]>([]);
  const structure = rfx.useStructure();
  const [isLoading, setIsLoading] = useState(false);
  const fetchSupplierCompany = useFetchSupplierCompany();

  useEffect(() => {
    setIsLoading(true);
    const fetchSuppliersData = async () => {
      const suppliers = await Promise.all(map(
        structure.recipients || [],
        async (recipient) => {
          const savedUserIds = keys(structure.teamById[recipient._id]?.users);

          const { company, users } = await fetchSupplierCompany({
            companyId: recipient._id,
            role: 'receiveRFQ',
            includeIfPending: savedUserIds,
          });

          return {
            company: {
              ...company,
              isFrozen: structure.status !== RfqStatus.DRAFT,
            },
            users: users.map((user) => ({
              ...user,
              // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
              selected: savedUserIds.includes(user._id),
            })),
          };
        },
      ));

      setExistingSuppliers(suppliers);
      setIsLoading(false);
    };

    fetchSuppliersData();

    return () => {
      setExistingSuppliers([]);
    };
  }, [structure, fetchSupplierCompany]);

  return { existingSuppliers, isLoading };
};

export const SuppliersProvider = ({
  children,
  rfqId,
  isReadOnly,
  onSuppliersChange = noop,
}: {
  children: React.ReactNode;
  rfqId: string;
  isReadOnly?: boolean,
  onSuppliersChange?: (suppliers: SupplierState[], initialSuppliers: SupplierState[]) => void;
}) => {
  const [suppliers, dispatch] = useReducer(reducer, []);
  const { existingSuppliers, isLoading } = useLoadRequestSuppliers();
  const structure = rfx.useStructure();
  const { startEditing, stopEditing } = rfx.useActions();
  const { maxComplexity } = useModelSizeLimits();
  const fetchSupplierCompany = useFetchSupplierCompany();

  const exchangeDefCountForSizeLimit = Object
    .values(structure.exchangeDefById)
    .filter(hasSizeRelevantExchangeType)
    .length;

  const maxSupplierCount = Math.min(
    REQUEST_MAX_SUPPLIER_COUNT,
    Math.floor(maxComplexity / exchangeDefCountForSizeLimit),
  );

  useEffect(() => {
    dispatch({
      type: 'add',
      payload: existingSuppliers,
    });

    return () => {
      dispatch({
        type: 'empty',
        payload: null,
      });
    };
  }, [existingSuppliers]);

  // TODO: We need to keep this until the live suppliers pages are consolidated to react
  useWatchValue(
    suppliers,
    (suppliers, previous) => {
      if (!isLoading) {
        onSuppliersChange(suppliers, existingSuppliers);
      }
    },
  );

  const removeSupplier = useCallback((supplierId: string) => {
    dispatch({
      type: 'remove',
      payload: supplierId,
    });
  }, []);

  const addSupplier = useCallback(async (supplierId: string, source?: RecipientSource) => {
    const { company, users: companyUsers } = await fetchSupplierCompany({
      companyId: supplierId,
      role: 'receiveRFQ',
    });

    const users = map(
      companyUsers,
      (user) => ({ ...user, selected: true }),
    );

    dispatch({
      type: 'add',
      payload: [{ company, users, source }],
    });
  }, [fetchSupplierCompany]);

  const addSupplierByUser = useCallback(async (userId: string, companyId: string, source?: RecipientSource) => {
    const { company, users: companyUsers } = await fetchSupplierCompany({
      companyId,
      role: 'receiveRFQ',
    });

    const users = map(
      companyUsers,
      user => ({ ...user, selected: user._id === userId }),
    );

    const supplier = find(
      suppliers,
      supplier => supplier.company._id === companyId,
    );

    if (!supplier) {
      dispatch({
        type: 'add',
        payload: [{ company, users, source }],
      });
    } else {
      // If the supplier is already added but the user is not selected we select it
      const currentSelection = mapValues(
        // @ts-expect-error ts(2339) FIXME: Property '_id' does not exist on type 'number | SupplierStateUser | (<U>(callbackfn: (value: SupplierStateUser, index: number, array: SupplierStateUser[]) => U, thisArg?: any) => U[]) | ... 67 more ... | ((index: number) => { ...; } | undefined)'.
        keyBy(supplier.users, (user) => user._id),
        // @ts-expect-error ts(2339) FIXME: Property 'selected' does not exist on type 'number | SupplierStateUser | (<U>(callbackfn: (value: SupplierStateUser, index: number, array: SupplierStateUser[]) => U, thisArg?: any) => U[]) | ... 67 more ... | ((index: number) => { ...; } | undefined)'.
        (user) => user.selected,
      );

      dispatch({
        type: 'updateUsersSelection',
        payload: {
          supplierId: supplier.company._id,
          users: { ...currentSelection, [userId]: true },
        },
      });
    }
  }, [fetchSupplierCompany, suppliers]);

  const addSuppliersBulk = useCallback(async (suppliersIds: string[], source?: RecipientSource) => {
    const addedSuppliersCompanies = await Promise.all(map(
      suppliersIds,
      async (companyId) => fetchSupplierCompany({ companyId, role: 'receiveRFQ' }),
    ));

    const payload = map(addedSuppliersCompanies, (supplier) => ({
      ...supplier,
      users: map(supplier.users, (user) => ({ ...user, selected: true })),
      source,
    }));

    dispatch({
      type: 'add',
      payload,
    });
  }, [fetchSupplierCompany]);

  const updateUsersStatus = useCallback((
    supplierId: string,
    users: { [_id: string]: boolean },
  ) => {
    dispatch({
      type: 'updateUsersSelection',
      payload: { supplierId, users },
    });
  }, []);

  const addInvitations = useCallback((invitations) => {
    dispatch({
      type: 'addInvitations',
      payload: invitations,
    });
  }, []);

  const hasChanges = !isEqual(suppliers, existingSuppliers);
  const addedCompanies = differenceBy(suppliers, existingSuppliers, supplier => supplier.company._id);
  const addedCompaniesCount = addedCompanies.length;
  const addedUsersCount = sumBy(addedCompanies, (company) => company?.users?.length);

  const excessSupplierCount = Math.max(0, suppliers.length - maxSupplierCount);

  useWatchValue(
    hasChanges,
    (hasChanges) => {
      if (!isLoading) {
        if (hasChanges) {
          startEditing('suppliers');
        } else {
          stopEditing();
        }
      }
    },
  );

  const value = useMemo(() => {
    const { senders, summary, status } = structure;
    const rfqName = (summary as any).liveVersion?.subject || summary.subject;
    const isLive = status !== RfqStatus.DRAFT;

    return {
      isLive,
      rfqName,
      senders,
      suppliers,
      isReadOnly,
      isLoading,
      maxSupplierCount,

      hasChanges,
      addedCompaniesCount,
      addedUsersCount,
      excessSupplierCount,

      // handlers
      addSupplier,
      addSupplierByUser,
      updateUsersStatus,
      addInvitations,
      addSuppliersBulk,
      removeSupplier,
    };
  }, [
    structure,
    suppliers,
    isReadOnly,
    isLoading,
    maxSupplierCount,
    hasChanges,
    addedCompaniesCount,
    addedUsersCount,
    excessSupplierCount,
    addSupplier,
    addSupplierByUser,
    updateUsersStatus,
    addInvitations,
    addSuppliersBulk,
    removeSupplier,
  ]);

  return (
    // @ts-expect-error ts(2322) FIXME: Type '{ isLive: boolean; rfqName: any; senders: Sender[]; suppliers: (SupplierState | { users: { selected: any; _id?: string; email?: string; firstName?: string; lastName?: string; name?: string; isPending?: boolean; }[]; company: CompanyProfile; source?: RecipientSource; })[]; ... 12 more ...; removeSupplier: (supplierId...' is not assignable to type '{ isLive: boolean; rfqName?: string | undefined; suppliers: SupplierState[]; senders: Sender[]; addSupplier: (supplierId: string, source?: RecipientSource | undefined) => void; ... 11 more ...; excessSupplierCount: number; }'.
    <SuppliersContext.Provider value={value}>
      {children}
    </SuppliersContext.Provider>
  );
};

export const useSuppliersContext = () => React.useContext(SuppliersContext);
