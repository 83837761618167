import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ExchangeStatus, Vessel, getCurrentCompanyGroup } from '@deepstream/common/rfq-utils';
import { useQueryClient } from 'react-query';
import { last } from 'lodash';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { callAll } from '@deepstream/utils/callAll';
import { Panel, PanelDivider, PanelHeader } from '@deepstream/ui-kit/elements/Panel';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { withProps } from '@deepstream/ui-utils/withProps';
import { useCurrentCompanyId } from './currentCompanyId';
import { ExchangeTableBase } from './ExchangeTable';
import { PreWrapCell } from './PreWrapCell';
import { ExchangeStatusIconText } from './ExchangeStatusCell';
import { EditVesselBidDeliveryAddressModal } from './EditVesselBidDeliveryAddress';
import { useModalState } from './ui/useModalState';
import { useLiveRfqStructureQueryKey, useRecipientId, useRfqId } from './useRfq';
import { Locked } from './lock';
import { useHaveDeadlinesPassed } from './deadline';
import * as rfx from './rfx';
import { useApi } from './api';
import { useToaster } from './toast';
import { useMutation } from './useMutation';
import { nestCells } from './nestCells';
import { AlwaysObsoleteCell } from './draft/cell';

const ReenableResponsesDialog = ({
  vessel,
  isOpen,
  close,
}: {
  vessel: Vessel;
  isOpen: boolean;
  close: () => void;
}) => {
  const { t } = useTranslation();
  const api = useApi();
  const recipientId = useRecipientId();
  const rfqId = useRfqId();
  const section = rfx.useSection();
  const queryClient = useQueryClient();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const toaster = useToaster();
  const sectionId = section?._id;
  const { stages } = rfx.useStructure();
  const activeStageId = rfx.useActiveStageId();

  const rfqStructureQueryKey = useLiveRfqStructureQueryKey({
    currentCompanyId,
    rfqId,
    recipientId,
  });

  const [reenableBidAssetResponses] = useMutation(
    api.reenableBidAssetResponses,
    {
      onSuccess: () => toaster.success(t('request.toaster.changesSavedSuccess')),
      onError: () => toaster.error(t('request.toaster.changesSavedError')),
      onSettled: callAll(
        () => queryClient.invalidateQueries(rfqStructureQueryKey),
        close,
      ),
    },
  );

  const reenableResponses = React.useCallback(() => {
    const activeStageIndex = stages.findIndex(stage => stage._id === activeStageId);

    if (activeStageIndex !== 0) {
      reenableBidAssetResponses({
        rfqId,
        recipientId,
        companyId: currentCompanyId,
        sectionId,
        assetId: vessel._id,
        assetName: vessel.name,
        extendedStageId: activeStageIndex === -1
          // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
          ? last(stages)._id
          : stages[activeStageIndex - 1]._id,
      });
    }
  }, [activeStageId, currentCompanyId, recipientId, rfqId, sectionId, stages, reenableBidAssetResponses, vessel]);

  return (
    <Dialog
      style={{ content: { maxWidth: '265px' } }}
      heading={t('request.exchange.dialog.reenableResponses.heading')}
      body={(
        <>
          {t('request.exchange.dialog.reenableResponses.body')}
        </>
      )}
      okButtonText={t('general.confirm')}
      okButtonVariant="primary"
      cancelButtonText={t('general.cancel')}
      cancelButtonVariant="secondary"
      isOpen={isOpen}
      onCancel={close}
      onOk={reenableResponses}
    />
  );
};

type DeliveryBidSectionProps = {
  vessel: Vessel;
  isLocked: boolean;
};

const StatusCell = ({ cell: { value: status } }, isRecipient) => {
  const currentCompanyGroup = getCurrentCompanyGroup(isRecipient);

  return (
    <ExchangeStatusIconText status={status} currentCompanyGroup={currentCompanyGroup} />
  );
};

export const DeliveryBidSection: React.FC<DeliveryBidSectionProps> = ({
  vessel,
  isLocked,
}) => {
  const { t } = useTranslation();
  const recipientId = useRecipientId();
  const isRecipient = useCurrentCompanyId() === recipientId;
  const section = rfx.useSection();
  const editDeliveryAddressModal = useModalState();
  const reenableResponsesModal = useModalState();
  const pagePermissions = rfx.usePagePermissions();
  const haveDeadlinesPassed = useHaveDeadlinesPassed();
  const canAccessReenableResponsesModal = (
    !isRecipient &&
    haveDeadlinesPassed &&
    !vessel.extendedStageId &&
    pagePermissions.canRespond &&
    !section.isObsolete
  );

  const LocationCell = isLocked ? () => <Locked /> : PreWrapCell;

  const columns = [
    {
      id: 'deliveryLocation',
      Header: t('request.vesselPricing.deliveryPort'),
      accessor: 'deliveryLocation.port',
      Cell: section.isObsolete
        ? nestCells(AlwaysObsoleteCell, LocationCell)
        : LocationCell,
      defaultValue: <EmDash />,
    },
    {
      id: 'redeliveryLocation',
      Header: t('request.vesselPricing.redeliveryPort'),
      accessor: 'redeliveryLocation.port',
      Cell: section.isObsolete
        ? nestCells(AlwaysObsoleteCell, LocationCell)
        : LocationCell,
      defaultValue: <EmDash />,
    },
    {
      id: 'status',
      Header: t('general.status'),
      accessor: ({ deliveryLocation, redeliveryLocation }) => {
        if (section.isObsolete) {
          return ExchangeStatus.OBSOLETE;
        }

        if (deliveryLocation?.port && redeliveryLocation?.port) {
          return ExchangeStatus.COMPLETE;
        }

        if (!isRecipient) {
          return ExchangeStatus.WAITING_FOR_SUPPLIER;
        }

        return pagePermissions.canRespond
          ? ExchangeStatus.ACTION_REQUIRED
          : ExchangeStatus.WAITING_FOR_TEAM;
      },
      Cell: section.isObsolete
        ? nestCells(AlwaysObsoleteCell, withProps(StatusCell, { isRecipient }))
        : withProps(StatusCell, { isRecipient }),
      width: 200,
    },
  ];

  return (
    <>
      <Panel>
        <PanelHeader heading={t('request.vesselPricing.deliveryAndRedelivery')} variant="subheader" />
        <PanelDivider />
        <ExchangeTableBase
          isPaginated={false}
          isSortable={false}
          columns={columns}
          exchanges={[vessel] as any[]}
          onRowClick={isRecipient ? (
            editDeliveryAddressModal.open
          ) : canAccessReenableResponsesModal ? (
            reenableResponsesModal.open
          ) : (
            undefined
          )}
        />
      </Panel>
      <EditVesselBidDeliveryAddressModal
        vessel={vessel}
        isOpen={editDeliveryAddressModal.isOpen}
        onClose={editDeliveryAddressModal.close}
      />
      <ReenableResponsesDialog
        vessel={vessel}
        isOpen={reenableResponsesModal.isOpen}
        close={reenableResponsesModal.close}
      />
    </>
  );
};
