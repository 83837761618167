import { TFunction } from 'i18next';

export type RequirementGroup =
  | { type: 'general' }
  | {
    type: 'lot',
    index: number,
    name: string
  };

export const renderRequirementGroup = (requirementGroup: RequirementGroup, t: TFunction) => {
  if (requirementGroup.type === 'general') {
    return t('request.generalRequirement', { count: 1 });
  } else {
    return `${requirementGroup.index + 1} – ${requirementGroup.name}`;
  }
};
