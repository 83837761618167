import { ContractDraftHeader } from './ContractDraftHeader';
import * as Layout from '../../Layout';
import { ContractDraftContract } from './ContractDraftContract';
import { useContractId } from './contract';

export const ContractDraftContractPageContent = () => {
  const tabId = 'contract';
  const contractId = useContractId();

  return (
    <Layout.HeaderAndContent
      header={
        <ContractDraftHeader
          key={contractId}
          contractId={contractId}
          selectedTabId={tabId}
        />
      }
      content={
        <ContractDraftContract
          contractId={contractId}
        />
      }
    />
  );
};
