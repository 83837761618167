import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { Form, Formik, FormikProps, useField } from 'formik';
import {
  AuctionLineItemExchangeDefinition,
  Draft,
  ExchangeChange,
  SectionType,
  ChangeType,
  RfxAuctionLineItemsSection,
} from '@deepstream/common/rfq-utils';
import { find, map, pick, set } from 'lodash';
import * as yup from 'yup';
import { DEFAULT_AUCTION_MAX_LINE_ITEM_COUNT } from '@deepstream/common/constants';
import { callAll } from '@deepstream/utils/callAll';
import { CancelButton, EditButton, SaveButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelHeader, PanelDivider, PanelPadding, ExpandablePanelSubSection } from '@deepstream/ui-kit/elements/Panel';
import * as rfx from '../rfx';
import { useCurrencySelectItems } from '../ui/currencies';
import { SelectField } from '../form/SelectField';
import { AuctionLineItemTable, AuctionLineItemTableField } from './AuctionLineItemTable';
import { getChanges, auctionLineItemExchangeDefKeys, auctionLineItemSectionKeys } from './draft';
import { ExchangeDefFieldValueProvider } from '../ExchangeDefFieldValueContext';
import { NumberStepperField } from '../form/NumberStepperField';
import { useModalState } from '../ui/useModalState';
import { ModelSizeLimitDialog, ModelSizeLimitMessages } from '../ModelSizeLimitDialog';
import { LeavePageModal } from './LeavePageModal';
import { SectionConfigHeading } from './SectionConfigHeading';
import { AuctionLineItemsSectionConfigIndicators } from './AuctionLineItemsSectionConfigIndicators';
import { DraftSectionLabelConfigProvider } from './DraftSectionLabelConfigProvider';
import { AuctionTabId } from './AuctionTabId';

const panelId = AuctionTabId.LINE_ITEMS;

const ViewSectionConfig = () => {
  const section = rfx.useSectionWithPosition<RfxAuctionLineItemsSection>();

  return (
    <ExpandablePanelSubSection
      heading={<SectionConfigHeading />}
      renderCollapsedContent={() => (
        <AuctionLineItemsSectionConfigIndicators
          decimalPlaces={section.auctionRules.decimalPlaces ?? 2}
          currency={section.auctionRules.currency}
        />
      )}
    />
  );
};

const CollapsedEditSectionConfig = () => {
  const [{ value: decimalPlaces }] = useField({ name: 'decimalPlaces' });
  const [{ value: currency }] = useField('currency');

  return (
    <AuctionLineItemsSectionConfigIndicators
      decimalPlaces={decimalPlaces ?? 2}
      currency={currency}
    />
  );
};

const ExpandedEditSectionConfig = () => {
  const { t } = useTranslation('translation');
  const currencySelectItems = useCurrencySelectItems({ short: true });

  return (
    <DraftSectionLabelConfigProvider>
      <PanelPadding>
        <NumberStepperField
          name="decimalPlaces"
          increment={1}
          maxValue={10}
          label={t('general.decimalPlaces')}
        />
      </PanelPadding>
      <PanelDivider />
      <PanelPadding>
        <SelectField
          hideError
          name="currency"
          label={t('general.currency')}
          items={currencySelectItems}
          buttonStyle={{ fontWeight: 500, width: 80 }}
        />
      </PanelPadding>
    </DraftSectionLabelConfigProvider>
  );
};

const EditSectionConfig = () => {
  return (
    <ExpandablePanelSubSection
      heading={<SectionConfigHeading />}
      renderCollapsedContent={() => <CollapsedEditSectionConfig />}
      renderExpandedContent={() => <ExpandedEditSectionConfig />}
    />
  );
};

const AuctionConfigLineItemsView = () => {
  const { t } = useTranslation();
  const pagePermissions = rfx.usePagePermissions();
  const { startEditing } = rfx.useActions();
  const isAuctionEditable = rfx.useIsAuctionEditable();

  return (
    <>
      <PanelHeader
        heading={t('request.auction.sectionTitle.lineItems')}
        description={t('request.auction.sectionDescription.lineItems')}
        py={1}
      >
        {(pagePermissions.canEdit && isAuctionEditable) && (
          <EditButton
            small
            onClick={() => startEditing(panelId)}
            ml={4}
          />
        )}
      </PanelHeader>
      <PanelDivider />
      <ViewSectionConfig />
      <PanelDivider />
      <ExchangeDefFieldValueProvider>
        <AuctionLineItemTable fieldName="lineItemExchangeDefs" />
      </ExchangeDefFieldValueProvider>
    </>
  );
};

const AuctionConfigLineItemsInnerForm = ({
  isSubmitting,
  isValid,
  dirty,
  resetForm,
}: FormikProps<{
  currency: string;
  decimalPlaces: number;
  lineItemExchangeDefs: AuctionLineItemExchangeDefinition<Draft>[];
}>) => {
  const { t } = useTranslation();
  const { stopEditing } = rfx.useActions();
  rfx.useFormResetOnAuctionStart();

  return (
    <Form style={{ width: '100%' }}>
      <PanelHeader
        heading={t('request.auction.sectionTitle.lineItems')}
        description={t('request.auction.sectionDescription.lineItems')}
        py={2}
      />
      <PanelDivider />
      <EditSectionConfig />
      <PanelDivider />
      <PanelPadding>
        <AuctionLineItemTableField fieldName="lineItemExchangeDefs" />
      </PanelPadding>
      <PanelDivider />
      <PanelPadding>
        <Flex justifyContent="flex-end">
          <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
          <SaveButton disabled={isSubmitting || !dirty || !isValid} />
        </Flex>
      </PanelPadding>
      <LeavePageModal />
    </Form>
  );
};

const AuctionConfigLineItemsForm = () => {
  const { t } = useTranslation();
  const { stopEditing } = rfx.useActions();
  const saveChanges = rfx.useSaveChanges();
  const modelSizeLimitModal = useModalState();
  const [modelSizeLimitMessages, setModelSizeLimitMessages] = useState<ModelSizeLimitMessages | null>(null);

  const section = rfx.useSectionWithPosition<RfxAuctionLineItemsSection>();
  const lineItemExchangeDefs = rfx.useSectionExchangeDefs() as AuctionLineItemExchangeDefinition<Draft>[];

  return section ? (
    <>
      <Formik<{
        currency: string;
        decimalPlaces: number;
        lineItemExchangeDefs: AuctionLineItemExchangeDefinition<Draft>[];
      }>
        validateOnBlur
        enableReinitialize
        initialValues={{
          currency: section.auctionRules.currency,
          decimalPlaces: section.auctionRules.decimalPlaces,
          lineItemExchangeDefs,
        }}
        validationSchema={
          yup.object().shape({
            decimalPlaces: yup.number()
              .transform((value) => Number.isNaN(value) ? null : value)
              .nullable()
              .min(0)
              .required(t('general.required')),
            lineItemExchangeDefs: yup.array().of(
              yup.object().shape({
                quantity: yup.number().min(1, t('errors.min1_short')),
              }),
            ),
          })
        }
        onSubmit={(values, { setSubmitting }) => {
          const auctionLineItemCount = values.lineItemExchangeDefs.length;

          if (auctionLineItemCount > DEFAULT_AUCTION_MAX_LINE_ITEM_COUNT) {
            setModelSizeLimitMessages({
              heading: t('request.dialog.requestSizeLimit.heading'),
              title: t('request.dialog.requestSizeLimit.saveAuctionLineItemsSection.title'),
              warning: t('request.dialog.requestSizeLimit.saveAuctionLineItemsSection.warning', { max: DEFAULT_AUCTION_MAX_LINE_ITEM_COUNT }),
              body: t('request.dialog.requestSizeLimit.saveAuctionLineItemsSection.body', {
                count: auctionLineItemCount - DEFAULT_AUCTION_MAX_LINE_ITEM_COUNT,
              }),
            });
            modelSizeLimitModal.open();
            setSubmitting(false);
          } else {
            return saveChanges({
              changes: [
                {
                  type: ChangeType.SECTION_UPDATED,
                  section: {
                    ...pick(section, auctionLineItemSectionKeys),
                    auctionRules: {
                      ...section.auctionRules,
                      currency: values.currency,
                      decimalPlaces: values.decimalPlaces,
                    },
                  },
                },
                ...getChanges<ExchangeChange>({
                  mapping: 'exchangeDef',
                  // Make sure the exchange definitions have the same stage
                  // visibility as the section
                  next: map(
                    values.lineItemExchangeDefs,
                    exchangeDef => set(exchangeDef, 'stages', section.stages),
                  ),
                  previous: lineItemExchangeDefs,
                  keys: auctionLineItemExchangeDefKeys,
                }).map(change => {
                  change.sectionName = section._id;
                  return change;
                }),
              ],
            }, {
              onSuccess: stopEditing,
            });
          }
        }}
        component={AuctionConfigLineItemsInnerForm}
      />
      <ModelSizeLimitDialog
        modal={modelSizeLimitModal}
        messages={modelSizeLimitMessages}
      />
    </>
  ) : (
    null
  );
};

export const AuctionConfigLineItemsPanel = () => {
  const { sectionById } = rfx.useStructure<Draft>();

  const section = find(sectionById, { type: SectionType.AUCTION_LINE_ITEMS });

  const { editingPanelId } = rfx.useState();

  const isEditingThisPanel = editingPanelId === panelId;

  return section ? (
    <rfx.SectionProvider section={section}>
      <Panel>
        {isEditingThisPanel ? (
          <AuctionConfigLineItemsForm />
        ) : (
          <AuctionConfigLineItemsView />
        )}
      </Panel>
    </rfx.SectionProvider>
  ) : (
    null
  );
};
