import { useQuery } from 'react-query';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useApi, wrap } from '../../api';
import * as Layout from '../../Layout';
import { useContractId } from './contract';

export const ContractTemplatePreviewHeader = () => {
  const currentCompanyId = useCurrentCompanyId();
  const templateId = useContractId();
  const api = useApi();

  const { data: template } = useQuery(
    ['contractTemplate', { currentCompanyId, templateId }],
    wrap(api.getContractTemplate),
  );

  return (
    <Layout.PageHeader
      icon="file-text-o"
      // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string | (() => ReactNode)'.
      heading={template?.meta.name}
    />
  );
};
