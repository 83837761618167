import * as React from 'react';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text, TextProps } from 'rebass/styled-components';
import { Interval, RequestsReportingDashboardData } from '@deepstream/common/reporting';
import { useNavigate } from '@tanstack/react-router';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { RequestResponseRateChart } from '../Reporting/RequestResponseRateChart';
import * as dashboard from '../Reporting/dashboard';

import { useRequestsReportingDashboardData, useRequestsReportingData } from '../Reporting/useRequestsReporting';
import { DashboardPanel } from './DashboardPanel';
import { DashboardNoCompanyAccess, DashboardNoTeamAccess } from './InfoBoxes';
import { SavingsScatterChart } from '../Reporting/SavingsScatterChart';
import { useCurrentUser } from '../../useCurrentUser';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useCompanyFeatureFlags } from '../../companyFeatureFlags';
import { useSystemFeatureFlags } from '../../systemFeatureFlags';
import { useRequestsReportingConfig } from '../Reporting/RequestsReportingConfig';
import { RequestsNavigationProvider } from '../../RequestsNavigationContext';
import { requestEditSpendRoute, requestSentSpendRoute } from '../../AppRouting';

const ChartTitle = ({
  text,
  ...props
}: {
  text: string;
} & TextProps) => {
  return (
    <Text
      mb={3}
      lineHeight={1}
      fontWeight={500}
      color="black"
      {...props}
    >
      {text}
    </Text>
  );
};

const ReportingPanel = ({
  onTeamManagementClick = noop,
  onGoToAllClick = noop,
  Component,
}: {
  onTeamManagementClick?: () => void;
  onGoToAllClick?: () => void;
  Component: ({ data }: { data: RequestsReportingDashboardData }) => React.ReactElement;
}) => {
  const { t } = useTranslation(['dashboard']);
  const navigate = useNavigate();

  const currentUser = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const companyFeatureFlags = useCompanyFeatureFlags();
  const systemFeatureFlags = useSystemFeatureFlags({ required: true });
  const { config } = useRequestsReportingConfig();

  const navigateToSenderRequest = React.useCallback(({ requestId, isDraft }: { requestId: string; isDraft?: boolean }) => {
    if (isDraft) {
      navigate({
        to: requestEditSpendRoute.to,
        params: { currentCompanyId, rfqId: requestId },
      });
    } else {
      navigate({
        to: requestSentSpendRoute.to,
        params: { currentCompanyId, rfqId: requestId },
      });
    }
  }, [currentCompanyId, navigate]);

  const userHasRequestsReportingAccess = currentUser.roles[currentCompanyId].accessReportingForRequests;
  const companyManagementReportingEnabled = companyFeatureFlags?.managementReportingEnabled;
  const systemManagementReportingEnabled = systemFeatureFlags?.managementReportingEnabled;

  const { data, isLoading, isError, isSuccess } = useRequestsReportingDashboardData<RequestsReportingDashboardData>(config);

  if (!systemManagementReportingEnabled) return null;

  return (
    <RequestsNavigationProvider
      navigateToSenderRequest={navigateToSenderRequest}
      navigateToReceivedRequest={noop}
      navigateToTemplatePreview={noop}
      navigateToTemplateEdit={noop}
      navigateToRequestsTab={noop}
    >
      <DashboardPanel
        icon="chart-bar"
        title={t('panels.reporting.heading')}
        isError={isError}
        isLoading={isLoading}
        goToAll={onGoToAllClick}
      >
        {!companyManagementReportingEnabled ? (
          <DashboardNoCompanyAccess
            message={t('panels.reporting.noCompanyAccess')}
          />
        ) : !userHasRequestsReportingAccess ? (
          <DashboardNoTeamAccess
            onTeamManagementClick={onTeamManagementClick}
            heading={t('panels.reporting.noTeamAccess.heading')}
            body={t('panels.reporting.noTeamAccess.body')}
          />
        ) : (
          <PanelPadding
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {data && isSuccess && (
              <Box sx={{ flex: '1 1 auto' }}>
                <Component data={data} />
              </Box>
            )}
          </PanelPadding>
        )}
      </DashboardPanel>
    </RequestsNavigationProvider>
  );
};

const HeadlineStatLabel = ({ children }) => {
  return (
    <Flex
      alignItems="center"
      fontSize={2}
      fontWeight={500}
    >
      {children}
    </Flex>
  );
};

const HeadlineStat = ({ interval, ...props }: {
  amount?: number;
  comparisonAmount?: number;
  currencyCode: string;
  interval: Interval;
  isGreenOnIncrease?: boolean;
}) => {
  const { t } = useTranslation('translation');

  return (
    <Box
      px="12px"
      sx={{
        border: 'lightGray2',
        borderRadius: '4px',
      }}
    >
      <Text
        color="subtext"
        mt="12px"
        fontSize="10px"
        fontWeight={500}
        letterSpacing="0.0833em"
        sx={{
          textTransform: 'uppercase',
        }}
      >
        {t(`request.spendAndSavings.this.${interval}`)}
      </Text>
      <dashboard.Price small {...props} />
    </Box>
  );
};

const HeadlineStatsContent = ({ data }: { data: RequestsReportingDashboardData }) => {
  const { t } = useTranslation('translation');

  return (
    <Box
      sx={{
        overflowWrap: 'normal',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '350px',
        display: 'grid',
        gridGap: 2,
        gridTemplateColumns: '1fr 3fr',
        gridTemplateRows: '80px 80px 80px',
      }}
    >
      <HeadlineStatLabel>
        {t('request.spendAndSavings.budget')}
      </HeadlineStatLabel>
      <HeadlineStat
        // @ts-expect-error ts(2322) FIXME: Type 'number | null' is not assignable to type 'number | undefined'.
        amount={data.totals.budget.current}
        // @ts-expect-error ts(2322) FIXME: Type 'number | null' is not assignable to type 'number | undefined'.
        comparisonAmount={data.totals.budget.previous}
        currencyCode={data.config.currency}
        interval={data.config.interval}
      />
      <HeadlineStatLabel>
        {t('request.spendAndSavings.value')}
      </HeadlineStatLabel>
      <HeadlineStat
        // @ts-expect-error ts(2322) FIXME: Type 'number | null' is not assignable to type 'number | undefined'.
        amount={data.totals.value.current}
        // @ts-expect-error ts(2322) FIXME: Type 'number | null' is not assignable to type 'number | undefined'.
        comparisonAmount={data.totals.value.previous}
        currencyCode={data.config.currency}
        interval={data.config.interval}
      />
      <HeadlineStatLabel>
        {t('general.savings')}
      </HeadlineStatLabel>
      <HeadlineStat
        // @ts-expect-error ts(2322) FIXME: Type 'number | null' is not assignable to type 'number | undefined'.
        amount={data.totals.savings.current}
        // @ts-expect-error ts(2322) FIXME: Type 'number | null' is not assignable to type 'number | undefined'.
        comparisonAmount={data.totals.savings.previous}
        currencyCode={data.config.currency}
        interval={data.config.interval}
        isGreenOnIncrease
      />
    </Box>
  );
};

export const RequestsReportingHeadlineStats = ({
  onGoToAllClick,
  onTeamManagementClick,
}: {
  onGoToAllClick?: () => void;
  onTeamManagementClick?: () => void;
}) => {
  return (
    <ReportingPanel
      onGoToAllClick={onGoToAllClick}
      onTeamManagementClick={onTeamManagementClick}
      Component={HeadlineStatsContent}
    />
  );
};

const SavingsPerIntervalContent = ({ data }: { data: RequestsReportingDashboardData }) => {
  const { t } = useTranslation('translation');

  return (
    <Box height="230px" mb="20px">
      <ChartTitle text={t(`request.spendAndSavings.savingsPer.${data.config.interval}`)} sx={{ mb: 0 }} />
      <SavingsScatterChart data={data} />
    </Box>
  );
};

export const RequestsReportingSavingsPerInterval = ({
  onGoToAllClick,
  onTeamManagementClick,
}: {
  onGoToAllClick?: () => void;
  onTeamManagementClick?: () => void;
}) => {
  return (
    <ReportingPanel
      onGoToAllClick={onGoToAllClick}
      onTeamManagementClick={onTeamManagementClick}
      Component={SavingsPerIntervalContent}
    />
  );
};

export const RequestResponseRate = ({
  onGoToAllClick,
  onTeamManagementClick,
}: {
  onGoToAllClick?: () => void;
  onTeamManagementClick?: () => void;
}) => {
  const { t } = useTranslation(['dashboard']);
  const {
    data,
    isLoading,
    isError,
    isSuccess,
    systemManagementReportingEnabled,
    companyManagementReportingEnabled,
    userHasRequestsReportingAccess,
  } = useRequestsReportingData();

  if (!systemManagementReportingEnabled) return null;

  return (
    <DashboardPanel
      icon="chart-bar"
      title={t('panels.reporting.heading')}
      isError={isError}
      isLoading={isLoading}
      goToAll={onGoToAllClick}
    >
      {!companyManagementReportingEnabled ? (
        <DashboardNoCompanyAccess
          message={t('panels.reporting.noCompanyAccess')}
        />
      ) : !userHasRequestsReportingAccess ? (
        <DashboardNoTeamAccess
          onTeamManagementClick={onTeamManagementClick}
          heading={t('panels.reporting.noTeamAccess.heading')}
          body={t('panels.reporting.noTeamAccess.body')}
        />
      ) : isSuccess && data ? (
        <PanelPadding
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box sx={{ flex: '1 1 auto' }}>
            <ChartTitle text={t('panels.reporting.chartTitle.requestResponseRate')} />
            <RequestResponseRateChart />
          </Box>
        </PanelPadding>
      ) : (
        null
      )}
    </DashboardPanel>
  );
};
