import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { usePreQualNavigation } from '../../AppRouting';
import * as Layout from '../../Layout';
import { useUserFlags } from '../../UserFlagsContext';
import { UpgradeToUnlockPreQualDialog } from './UpgradeToUnlockPreQualDialog';
import { useModalState } from '../../ui/useModalState';
import { useCompanyFeatureFlags } from '../../companyFeatureFlags';

export const PreQualificationLayout = ({ selectedTabId, children }) => {
  const { t } = useTranslation('preQualification');
  const {
    navigateToPreQualificationQuestionnaires,
    navigateToPreQualificationQuestions,
    navigateToPreQualificationSuppliers,
    navigateToPreQualificationArchive,
    navigateToSendQuestionnaire,
  } = usePreQualNavigation();
  const { belongsToPayingCompany, hasSendQuestionnairePermission } = useUserFlags();
  const companyFeatureFlags = useCompanyFeatureFlags();
  const upgradeDialog = useModalState();

  const tabs = useMemo(
    () => [
      {
        id: 'suppliers',
        name: t('supplier_other'),
        navigate: navigateToPreQualificationSuppliers,
      },
      {
        id: 'questionnaires',
        name: t('questionnaire_other'),
        navigate: navigateToPreQualificationQuestionnaires,
      },
      {
        id: 'questions',
        name: t('question_other'),
        navigate: navigateToPreQualificationQuestions,
      },
      {
        id: 'archive',
        name: t('archive.archiveSection'),
        navigate: navigateToPreQualificationArchive,
      },
    ],
    [
      navigateToPreQualificationQuestionnaires,
      navigateToPreQualificationQuestions,
      navigateToPreQualificationSuppliers,
      navigateToPreQualificationArchive,
      t,
    ],
  );

  return (
    <Layout.HeaderAndContent
      header={
        <Layout.PageHeader
          heading={t('preQualification')}
          icon="file-check"
          tabs={belongsToPayingCompany ? tabs : undefined}
          selectedTabId={belongsToPayingCompany ? selectedTabId : undefined}
        >
          {!belongsToPayingCompany || !companyFeatureFlags.preQualEnabled ? (
            <Button iconLeft="lock" small onClick={upgradeDialog.open}>
              {t('sendQuestionnaires')}
            </Button>
          ) : hasSendQuestionnairePermission ? (
            <Button iconLeft="envelope" small mt={2} onClick={() => navigateToSendQuestionnaire()}>
              {t('sendQuestionnaires')}
            </Button>
          ) : null}
          <UpgradeToUnlockPreQualDialog
            isOpen={upgradeDialog.isOpen}
            onCancel={upgradeDialog.close}
          />
        </Layout.PageHeader>
      }
      content={children}
    />
  );
};
