import { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getRecipientsWithAuctionAccess, Live, PageType, SectionType, RfxAuctionLineItemsSection } from '@deepstream/common/rfq-utils';
import { Box } from 'rebass/styled-components';
import { find, groupBy, isEmpty } from 'lodash';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useQueryClient } from 'react-query';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import { LoadingPanel } from '../../../ui/Loading';
import { RfqIdProvider, useLiveRfqStructure } from '../../../useRfq';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import * as rfx from '../../../rfx';
import { CurrencyCodeProvider } from '../../../ui/Currency';
import { FixedFooter, FixedFooterButton } from '../../../FixedFooter';
import { HiddenRequestPagePlaceholder } from '../../../HiddenRequestPagePlaceholder';
import { useAllExchanges } from '../../../useAllExchanges';
import { AuctionLineItemsComparison } from './AuctionLineItemsComparison';
import { AuctionGraphPanel } from './AuctionGraphPanel';
import { AuctionBuyerInfo } from './AuctionBuyerInfo';
import { EmptyGridPanel } from '../Comparison/EmptyGridPanel';
import { EmptyTableMessage } from '../../../ui/EmptyTableMessage';
import { useInvalidateQueryOnMessage } from '../../../useInvalidateQueryOnMessage';
import { useHandleWsMessage } from '../../../WsProvider';
import { AuctionObsoleteLineItems } from './AuctionObsoleteLineItems';
import { AuctionOverviewButton } from '../Live/AuctionOverviewButton';
import { AuctionChatButton } from '../Live/AuctionChatButton';
import { useEnv } from '../../../env';
import { CollapsibleHeaderContent } from '../../../CollapsibleHeaderContext';

const NoSuppliersPanel = () => {
  const { t } = useTranslation();

  return (
    <EmptyGridPanel
      sx={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      <PanelPadding>
        <EmptyTableMessage
          header={t('request.auction.noSuppliers')}
          body={t('request.auction.noSuppliersHaveBeenMovedIntoAuctionStage')}
        />
      </PanelPadding>
    </EmptyGridPanel>
  );
};

const RfxAuction = () => {
  const { t } = useTranslation();
  const { INTERCOM_APP_ID } = useEnv();
  const rfxStructure = rfx.useStructure<Live>();
  const auctionPage = rfx.usePage();
  const pagePermissions = rfx.usePagePermissions();
  // @ts-expect-error ts(2345) FIXME: Argument of type 'null' is not assignable to parameter of type '"notObsolete" | "obsolete" | (() => "notObsolete" | "obsolete")'.
  const [expandedView, setExpandedView] = useState<'notObsolete' | 'obsolete'>(null);

  const auctionSectionsByType = useMemo(() => {
    return groupBy(
      // @ts-expect-error ts(18047) FIXME: 'auctionPage' is possibly 'null'.
      auctionPage.sections.map(sectionId => rfxStructure.sectionById[sectionId]),
      section => section.type,
    );
  }, [rfxStructure, auctionPage]);

  const recipientsWithAuctionAccess = useMemo(
    () => getRecipientsWithAuctionAccess(rfxStructure),
    [rfxStructure],
  );

  const chatSection = auctionSectionsByType[SectionType.CHAT]?.[0];
  const auctionTermsSection = auctionSectionsByType[SectionType.AUCTION_TERMS]?.[0];
  const auctionLineItemsSection =
    auctionSectionsByType[SectionType.AUCTION_LINE_ITEMS]?.[0] as RfxAuctionLineItemsSection<Live> | undefined;
  const lot = auctionLineItemsSection
    ? rfxStructure.auction.lots.find(lot => lot._id === auctionLineItemsSection._id)
    : null;

  return pagePermissions.canRead ? (
    // @ts-expect-error ts(18048) FIXME: 'auctionLineItemsSection' is possibly 'undefined'.
    <CurrencyCodeProvider code={auctionLineItemsSection.auctionRules.currency}>
      {!auctionLineItemsSection ? (
        null
      ) : isEmpty(recipientsWithAuctionAccess) ? (
        <CollapsibleHeaderContent>
          <NoSuppliersPanel />
        </CollapsibleHeaderContent>
      ) : (
        <rfx.RecipientsProvider recipients={recipientsWithAuctionAccess}>
          <rfx.SectionProvider section={auctionLineItemsSection}>
            {/*
             // @ts-expect-error ts(2322) FIXME: Type 'AuctionLot | null | undefined' is not assignable to type 'AuctionLot'. */}
            <rfx.AuctionLotProvider lot={lot}>
              <CollapsibleHeaderContent>
                <Stack gap="20px" mb="64px">
                  <AuctionBuyerInfo />
                  <AuctionGraphPanel />
                  {(!expandedView || expandedView === 'notObsolete') && (
                    <AuctionLineItemsComparison
                      isExpandedView={expandedView === 'notObsolete'}
                      // @ts-expect-error ts(2345) FIXME: Argument of type '"notObsolete" | null' is not assignable to parameter of type 'SetStateAction<"notObsolete" | "obsolete">'.
                      setIsExpandedView={isExpandedView => setExpandedView(isExpandedView ? 'notObsolete' : null)}
                    />
                  )}
                </Stack>
                {(!expandedView || expandedView === 'obsolete') && (
                  <AuctionObsoleteLineItems
                    isExpandedView={expandedView === 'obsolete'}
                    // @ts-expect-error ts(2345) FIXME: Argument of type '"obsolete" | null' is not assignable to parameter of type 'SetStateAction<"notObsolete" | "obsolete">'.
                    setIsExpandedView={isExpandedView => setExpandedView(isExpandedView ? 'obsolete' : null)}
                  />
                )}
              </CollapsibleHeaderContent>
            </rfx.AuctionLotProvider>
          </rfx.SectionProvider>
        </rfx.RecipientsProvider>
      )}
      <FixedFooter>
        <rfx.SectionProvider section={auctionTermsSection}>
          {/*
           // @ts-expect-error ts(18048) FIXME: 'auctionLineItemsSection' is possibly 'undefined'. */}
          <AuctionOverviewButton auctionRules={auctionLineItemsSection.auctionRules} />
        </rfx.SectionProvider>
        <Box>
          {chatSection && (
            <rfx.SectionProvider section={chatSection}>
              <AuctionChatButton isSender />
            </rfx.SectionProvider>
          )}
          {INTERCOM_APP_ID && (
            <FixedFooterButton
              id="auction-support-button"
              color="primary"
              icon="question-circle"
              sx={{ borderLeft: 'lightGray' }}
            >
              {t('request.auction.footer.support')}
            </FixedFooterButton>
          )}
        </Box>
      </FixedFooter>
    </CurrencyCodeProvider>
  ) : (
    <HiddenRequestPagePlaceholder />
  );
};

export const RfxAuctionContainer = ({
  rfqId,
  navigateToTeam,
}: {
  rfqId: string;
  navigateToTeam: () => void;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const queryClient = useQueryClient();

  const { data: structure, status: structureStatus, queryKey: structureQueryKey } = useLiveRfqStructure({
    rfqId,
    currentCompanyId,
  });
  // TODO only request relevant exchanges
  const { data: exchanges, status: exchangesStatus, queryKey: exchangesQueryKey } = useAllExchanges({
    rfqId,
    currentCompanyId,
  });

  useInvalidateQueryOnMessage(`rfx.${rfqId}`, structureQueryKey);
  useInvalidateQueryOnMessage(`rfx.${rfqId}`, exchangesQueryKey);

  // Hack: notifications are created after the rfx updated message is sent,
  // so we're delaying the refetching.
  const delayedInvalidateNotifications = useCallback(
    () => {
      setTimeout(() => queryClient.invalidateQueries('notifications'), 500);
    },
    [queryClient],
  );

  // Refetch notifications when the rfx was updated for faster feedback
  useHandleWsMessage(`rfx.${rfqId}`, delayedInvalidateNotifications, true, 'auction');

  const auctionPage = find(structure?.pages, { type: PageType.AUCTION });

  return (
    <rfx.StateProvider isLive>
      <RfqIdProvider rfqId={rfqId}>
        {structureStatus === 'loading' || exchangesStatus === 'loading' ? (
          <LoadingPanel />
        ) : structureStatus === 'error' || exchangesStatus === 'error' ? (
          <ErrorPanel error={t('request.errors.getRequest')} />
        ) : structureStatus === 'success' && exchangesStatus === 'success' && auctionPage && exchanges ? (
          <RfqIdProvider rfqId={rfqId}>
            <rfx.StructureProvider structure={structure}>
              <rfx.ExchangesProvider exchanges={exchanges}>
                <rfx.PageProvider page={auctionPage}>
                  <RfxAuction />
                </rfx.PageProvider>
              </rfx.ExchangesProvider>
            </rfx.StructureProvider>
          </RfqIdProvider>
        ) : (
          null
        )}
      </RfqIdProvider>
    </rfx.StateProvider>
  );
};
