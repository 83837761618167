import * as React from 'react';
import { Box, BoxProps, Flex, Text } from 'rebass/styled-components';
import {
  Company,
  EvaluationCriterionExchangeDefinition,
  ExchangeStatus,
  RfqStatus,
  ScoringType,
  SectionType,
  canRespond,
  getAverageScore,
  getExchangeFieldValue,
  getPagesInDisplayOrder,
  getScore,
  getWeightedScore,
  hasIndividualScores,
  isLinkedEvaluationPage,
  RfxEvaluationSection,
  ExchangeProvider,
  ExchangeType,
  createFormatQuestionResponse,
  getFormattedFieldLabel,
  Attachment,
  isReplyField,
  ActionType,
  isFieldValueDefined,
  isLinkedEvaluationSection,
  LineItemExchangeDefinition,
  getFieldIdsInDefaultDisplayOrder,
} from '@deepstream/common/rfq-utils';
import { localeFormatFactorAsPercent, localeFormatPrice } from '@deepstream/utils';
import {
  find,
  findLast,
  flatMap,
  fromPairs,
  has,
  isEmpty,
  isEqual,
  isFinite,
  isNil,
  keyBy,
  mapValues,
  omit,
  pick,
  propertyOf,
  sortBy,
  sum,
  values,
} from 'lodash';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import styled from 'styled-components';
import { documentExchangeTypes } from '@deepstream/common/exchangesConfig';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { Clamp } from '@deepstream/ui-kit/elements/text/Clamp';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { KeyCode } from '@deepstream/ui-utils/KeyCode';
import { ComparisonGrid } from '@deepstream/ui-kit/grid/core/ComparisonGrid';
import { DefaultComparisonGridStyles } from '@deepstream/ui-kit/grid/core/ComparisonGridStyles';
import {
  ColumnData,
  DataCellProps,
  FrozenHeaderCellProps,
  GridDataAndCommands,
  isExpandable,
  RowData,
} from '@deepstream/ui-kit/grid/core/utils';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { stopEvent } from '@deepstream/ui-utils/domEvent';
import { DEFAULT_FROZEN_LEFT_COLUMN, DEFAULT_FROZEN_LEFT_COLUMN_IDS } from '@deepstream/ui-kit/grid/core/constants';
import { EvaluationExchangeSnapshot, ExchangeSnapshot, LineItemsExchangeSnapshot, RfxStructure } from '../../../../types';
import {
  exchangeDefLineClampByRowHeight,
  twoLinesRowLineClamp,
  RowHeight,
  rowHeightMeasurements,
} from '../rowHeight';
import { UseExchangeModalStateProps } from '../../../../useExchangeModalState';
import * as rfx from '../../../../rfx';
import { StatusIcon } from '../../../../ExchangeStatusCell';
import { CommentCount } from '../CommentCount';
import { createRecipientHeaderCell } from '../createRecipientHeaderCell';
import { GridSectionIcon } from '../GridSectionIcon';
import { InlineLabel } from '../InlineLabel';
import { useExchangeDefFieldValue } from '../../../../ExchangeDefFieldValueContext';
import { useCurrentCompanyId } from '../../../../currentCompanyId';
import { useCurrentUser, useCurrentUserLocale } from '../../../../useCurrentUser';
import { useRfqId } from '../../../../useRfq';
import { Number } from '../../../../ui/Number';
import { ProgressPercentage } from '../../../../ui/ProgressPercentage';
import { formatOptions } from '../../../../ui/formatOptions';
import { PreWrap } from '../../../../PreWrapCell';
import { useDownloadRfqAttachment } from '../../../../ExchangeModal/AttachmentLink';
import { NumberFormat } from '../../../../NumberFormat';
import { Datetime } from '../../../../Datetime';
import { useSwitchToExchange } from '../../../../ExchangeModal/SwitchToExchange';
import { RecipientOrderItem } from '../useSelectRecipientOrder';
import { useNavigate } from '../../../../tanstackRouter';
import { legacyRequestSentRecipientEvaluationIndexRoute, requestSentRecipientEvaluationIndexRoute } from '../../../../AppRouting';
import { requestBidStatusAllowsScoreSubmissions } from '../../../NewEvaluation/utils';
import type { EvaluationActionStats, SpecificEvaluationStats } from './useEvaluationStats';
import { OwnActionCount, TeamActionCount } from './EvaluationComparisonStats';

const SubHeaderRow = styled(Flex)`
  color: ${props => props.theme.colors.text}80;
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.0833em;
  height: 100%;
`;

const ScoreContainer = styled(Flex)<{ hideBorder?: boolean }>`
  border-right: 1px solid ${props => props.theme.colors.lightGray};
  ${props => props.hideBorder ? 'border-right: none' : ''}
`;

const staticGridCellWidthsMap = {
  score: 100,
  linkedResponse: 260,
  focusDescendant: 44,
};

const STATS_ID = 'stats';

type GridCriterionExchangeDef = EvaluationCriterionExchangeDefinition & {
  isSubHeader: boolean;
  noPadding?: boolean;
  counter: string;
  percent: number;
};

type GridEvaluationSection = RfxEvaluationSection & {
  isSubHeader: boolean;
  noPadding?: boolean;
  description: string;
  counter: string;
  percent: number;
  lotId?: string | null;
  lotName?: string | null;
  subRows: GridCriterionExchangeDef[];
};

type GridContentData = Record<
  string,
  {
    exchangeById: Record<string, EvaluationExchangeSnapshot>;
    weightedScoreByPageId: Record<string, number>;
    sectionDataById: Record<
      string,
      {
        scoreStatusByExchangeId: Record<string, ExchangeStatus>;
        weightedScoreByExchangeId: Record<string, number>;
        weightedTotalScore: number;
      }
    >;
    weightedTotalScore: number;
    canManageEvaluation: boolean;
    canEvaluateLots: boolean;
    stats: EvaluationActionStats;
  }
>;

const SharedScoreCriterion = ({
  exchange,
  scoreStatus,
  weightedScore,
  hasShortCellHeight,
}: {
  exchange: EvaluationExchangeSnapshot;
  scoreStatus: ExchangeStatus;
  weightedScore: number;
  hasShortCellHeight: boolean;
}) => {
  const { t } = useTranslation();

  const score = getAverageScore(exchange);
  const maxPoints = getExchangeFieldValue(exchange, 'maxPoints');

  return (
    <>
      <StatusIcon
        type={exchange.def.type}
        status={scoreStatus}
        currentCompanyGroup="buyer"
        sx={{ top: '1px' }}
        mr={2}
      />
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-end"
        flex={1}
        sx={{ height: '100%' }}
      >
        {isFinite(score) ? (
          <>
            <Truncate>
              {score} / {maxPoints}
            </Truncate>
            {hasShortCellHeight ? (
              null
            ) : (
              <Box
                fontSize="9px"
                fontWeight="normal"
                letterSpacing="0.3px"
              >
                <InlineLabel>{t('general.score')}</InlineLabel>
                {isFinite(weightedScore) ? (
                  <Number value={weightedScore} suffix="%" options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }} />
                ) : (
                  <EmDash />
                )}
              </Box>
            )}
          </>
        ) : (
          <Text color="subtext">
            {t('request.exchange.exchangeStatus.incomplete')}
          </Text>
        )}
      </Flex>
    </>
  );
};

const IndividualScoresCriterion = ({
  exchange,
  scoreStatus,
  weightedScore,
}: {
  exchange: EvaluationExchangeSnapshot;
  scoreStatus: ExchangeStatus;
  weightedScore: number;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <StatusIcon
        type={exchange.def.type}
        status={scoreStatus}
        currentCompanyGroup="buyer"
        sx={{ top: '1px' }}
        mr={2}
      />
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-end"
        flex={1}
        sx={{ height: '100%' }}
      >
        {isFinite(weightedScore) ? (
          <Truncate>
            <Number value={weightedScore} suffix="%" options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }} />
          </Truncate>
        ) : (
          <Text color="subtext">
            {t('request.exchange.exchangeStatus.incomplete')}
          </Text>
        )}
      </Flex>
    </>
  );
};

const TotalScore = ({
  value,
  label,
}: {
  value: number;
  label: string;
}) => (
  <Text fontSize={1} width="100%" pr="44px" textAlign="right">
    <InlineLabel mr="6px">{label}</InlineLabel>
    {isFinite(value) ? (
      <Number value={value} suffix="%" options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }} />
    ) : (
      <EmDash />
    )}
  </Text>
);

const InlineSubRow = (props: BoxProps) => (
  <Box
    p={2}
    alignItems="center"
    fontSize={1}
    height="32px"
    {...props}
  />
);

const RecipientSpecificStatsLeftColumn = ({
  stats,
}: {
  stats: SpecificEvaluationStats;
}) => {
  const { t } = useTranslation();
  const { canManageEvaluation } = rfx.useRfxPermissions();
  const scoringType = rfx.useEvaluationScoringType();

  const { canEvaluateLots } = stats;

  return (
    <Flex flexDirection="column" flex={1}>
      {canManageEvaluation || canEvaluateLots ? (
        <>
          <InlineSubRow>
            {t('request.evaluation.status.evaluationStatus')}
          </InlineSubRow>
          <InlineSubRow sx={{ borderTop: 'lightGray' }}>
            {t('request.evaluation.yourActions')}
          </InlineSubRow>
        </>
        ) : (
          null
        )}
      {canManageEvaluation && scoringType === ScoringType.INDIVIDUAL_SCORES && (
        <InlineSubRow sx={{ borderTop: 'lightGray' }}>
          {t('request.evaluation.teamActions')}
        </InlineSubRow>
        )}
    </Flex>
  );
};

const RecipientSpecificStats = ({
  canEvaluateLots,
  stats = {},
}: {
  canEvaluateLots: boolean;
  stats: EvaluationActionStats;
}) => {
  const { t } = useTranslation('evaluation');
  const { canManageEvaluation } = rfx.useRfxPermissions();
  const scoringType = rfx.useEvaluationScoringType();

  const {
    numActions = 0,
    numOwnActions = 0,
    numPendingActions = 0,
    numPendingOwnActions = 0,
  } = stats;

  return (
    <Flex flexDirection="column" flex={1} height="100%">
      {canManageEvaluation ? (
        <InlineSubRow>
          <ProgressPercentage
            progress={(numActions - numPendingActions) / numActions}
            appendix={` ${t('comparisonGrid.stats.completeSuffix')}`}
          />
        </InlineSubRow>
      ) : canEvaluateLots ? (
        <InlineSubRow>
          <ProgressPercentage
            progress={(numOwnActions - numPendingOwnActions) / numOwnActions}
            appendix={` ${t('comparisonGrid.stats.completeSuffix')}`}
          />
        </InlineSubRow>
      ) : (
        null
      )}
      {(canManageEvaluation || canEvaluateLots) && (
        <InlineSubRow sx={{ borderTop: 'lightGray' }}>
          <OwnActionCount
            count={numPendingOwnActions}
            max={numOwnActions}
            withSuffix
          />
        </InlineSubRow>
      )}
      {canManageEvaluation && scoringType === ScoringType.INDIVIDUAL_SCORES && (
        <InlineSubRow sx={{ borderTop: 'lightGray' }}>
          <TeamActionCount
            count={numPendingActions - numPendingOwnActions}
            max={numActions - numOwnActions}
            withSuffix
          />
        </InlineSubRow>
      )}
    </Flex>
  );
};

const createFrozenFooterCell = ({
  t,
  gridContentData,
}: {
  t: TFunction;
  gridContentData: GridContentData;
}) =>
  ({ column }: FrozenHeaderCellProps<Company>) => {
    const recipientId = column.original._id;
    const data = gridContentData[recipientId];

    if (!data) {
      return null;
    }

    return (
      <TotalScore
        label={t('request.evaluation.grandTotal')}
        value={data.weightedTotalScore}
      />
    );
  };

const createFirstColumnCell = (
  stats: SpecificEvaluationStats,
  t: TFunction,
) =>
  ({ row }: DataCellProps<Company, GridCriterionExchangeDef, GridEvaluationSection>) => {
    const { getFieldValue } = useExchangeDefFieldValue();
    const locale = useCurrentUserLocale();

    if (row.original.isSubHeader) {
      return (
        <Flex flexDirection="row" alignItems="center">
          <Truncate style={{ flex: 1 }}>
            {row.original.description}
          </Truncate>
        </Flex>
      );
    }

    // stats row
    if (isExpandable(row) && row.original._id === STATS_ID) {
      return (
        <RecipientSpecificStatsLeftColumn stats={stats} />
      );
    }

    // page row
    if (isExpandable(row) && isEmpty(row.original.subRows)) {
      return (
        <Flex alignItems="center" p={2}>
          <Flex flexDirection="column" flex={1}>
            <Truncate>
              {row.original.name}
            </Truncate>
            <Box
              fontSize="9px"
              fontWeight="normal"
              letterSpacing="0.3px"
              mt="3px"
            >
              <InlineLabel>{t('general.weight')}</InlineLabel>
              {localeFormatFactorAsPercent(row.original.percent, { locale })}
              {row.original.lotName && (
                <InlineLabel ml={3}>
                  <Icon icon="grid-2" regular mr={1} />
                  {row.original.lotName}
                </InlineLabel>
              )}
            </Box>
          </Flex>
        </Flex>
      );
    }

    // section row
    if (isExpandable(row)) {
      return (
        <Flex alignItems="center">
          <GridSectionIcon icon="balance-scale" />
          <Flex flexDirection="column" flex={1}>
            <Truncate>
              {row.original.counter} – {row.original.name}
            </Truncate>
            <Box
              fontSize="9px"
              fontWeight="normal"
              letterSpacing="0.3px"
              mt="3px"
            >
              <InlineLabel>{t('general.weight')}</InlineLabel>
              {localeFormatFactorAsPercent(row.original.percent, { locale })}
              {row.original.lotName && (
                <InlineLabel ml={3}>
                  <Icon icon="grid-2" regular mr={1} />
                  {row.original.lotName}
                </InlineLabel>
              )}
            </Box>
          </Flex>
        </Flex>
      );
    }

    // exchange row
    return (
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        sx={{ height: '100%' }}
        p={2}
      >
        <Clamp lines={twoLinesRowLineClamp[row.height]}>
          {row.original.counter} – {getFieldValue(row.original, 'description')}
        </Clamp>
        {row.height === rowHeightMeasurements[RowHeight.SHORT] ? (
          null
        ) : (
          <Box fontSize="9px" fontWeight="normal" letterSpacing="0.3px">
            <InlineLabel>{t('general.weight')}</InlineLabel>
            {localeFormatFactorAsPercent(row.original.percent, { locale })}
          </Box>
        )}
      </Flex>
    );
  };

const createDataCell =
  ({
    gridContentData,
    openExchangeModal,
    hasLinkedResponses,
    exchanges,
    t,
  }: {
    gridContentData: GridContentData;
    openExchangeModal: (
      row: RowData<GridCriterionExchangeDef, GridEvaluationSection>,
      column: ColumnData<Company>
    ) => void;
    t: TFunction;
    hasLinkedResponses: boolean;
    exchanges: ExchangeSnapshot[];
  }) =>
    ({
      row,
      column,
      isActive,
    }: DataCellProps<Company, GridCriterionExchangeDef, GridEvaluationSection>) => {
      const structure = rfx.useStructure();
      const recipientId = column.original._id;

      if (row.original.isSubHeader) {
        return (
          <SubHeaderRow flexDirection="row" alignItems="center">
            <ScoreContainer
              hideBorder={!hasLinkedResponses}
              px={2}
              alignItems="center"
              height="100%"
              flexBasis={`${staticGridCellWidthsMap.score}px`}
            >
              {t('general.score')}
            </ScoreContainer>
            {hasLinkedResponses && (
              <Text px={2} flexBasis={`${staticGridCellWidthsMap.linkedResponse}px`} pl={2}>
                {t('request.evaluation.linkedResponse')}
              </Text>
            )}
          </SubHeaderRow>
        );
      }

      if (isExpandable(row) && row.original._id === STATS_ID) {
        const { canEvaluateLots, stats } = gridContentData[recipientId];

        return (
          <RecipientSpecificStats
            canEvaluateLots={canEvaluateLots}
            stats={stats}
          />
        );
      }

      if (isExpandable(row) && isEmpty(row.original.subRows)) {
        const weightedPageScore = gridContentData[recipientId]?.weightedScoreByPageId[row.original._id];

        return (
          <TotalScore label={t('request.evaluation.totalScore')} value={weightedPageScore} />
        );
      }

      if (isExpandable(row)) {
        const sectionData = gridContentData[recipientId]?.sectionDataById[row.original._id];

        if (!sectionData) {
          return null;
        }

        return (
          <TotalScore label={t('request.evaluation.totalScore')} value={sectionData.weightedTotalScore} />
        );
      }

      const exchangeDefId = row.original._id;

      const exchange = gridContentData[recipientId]?.exchangeById[exchangeDefId];

      if (!exchange) {
        return null;
      }

      const sectionData = gridContentData[recipientId]?.sectionDataById[exchange.def.sectionId!];

      const weightedExchangeScore = sectionData?.weightedScoreByExchangeId[exchangeDefId];

      // When the bid is inactive, then the user cannot take any action;
      // in this case, we render the BLOCKED icon to indicate that the
      // user can no longer submit a score.
      // @ts-expect-error ts(2345) FIXME: Argument of type 'BidStatus | null' is not assignable to parameter of type 'BidStatus'.
      const scoreStatus = requestBidStatusAllowsScoreSubmissions(structure.bidById[recipientId].status)
        ? sectionData?.scoreStatusByExchangeId[exchangeDefId]
        : ExchangeStatus.BLOCKED;

      return (
        <Flex alignItems="stretch" height="100%">
          <ScoreContainer
            hideBorder={!hasLinkedResponses}
            justifyContent="space-between"
            alignItems="stretch"
            height="100%"
            width={hasLinkedResponses ? staticGridCellWidthsMap.score : 250}
            p={2}
            pr={1}
          >
            {hasIndividualScores(exchange.def) ? (
              <IndividualScoresCriterion
                exchange={exchange}
                scoreStatus={scoreStatus}
                weightedScore={weightedExchangeScore}
              />
            ) : (
              <SharedScoreCriterion
                exchange={exchange}
                scoreStatus={scoreStatus}
                weightedScore={weightedExchangeScore}
                hasShortCellHeight={row.height === rowHeightMeasurements[RowHeight.SHORT]}
              />
            )}
          </ScoreContainer>
          {hasLinkedResponses && (
            <Box flex={1} p={2}>
              <EvaluationLinkedResponseCell exchange={exchange} exchanges={exchanges} recipientId={recipientId} rowHeight={row.height} />
            </Box>
          )}
          <Box
            minWidth="28px"
            ml={3}
            textAlign="right"
            className="focus-descendant-primary"
            p={2}
          >
            {isActive ? (
              <Tooltip content={t('request.exchange.openExchange') as string}>
                <Icon
                  icon="expand"
                  sx={{ cursor: 'pointer', right: '2px' }}
                  onClick={() => openExchangeModal(row, column)}
                />
              </Tooltip>
            ) : (
              <CommentCount exchange={exchange} />
            )}
          </Box>
        </Flex>
      );
    };

export const useRowData = (structure: RfxStructure) => {
  const { t } = useTranslation();
  const {
    absolutePageWeightById,
    relativeSectionWeightById,
    absoluteExchangeDefWeightById,
  } = rfx.useEvaluationWeights();
  return React.useMemo(() => {
    const { pages, pageById, sectionById, lots, lotById, settings, exchangeDefById } = structure;
    const { areLotsEnabled } = settings;

    const nonObsoletePageIds = Object.keys(absolutePageWeightById);

    const orderedPages = getPagesInDisplayOrder(pages)
      .filter(page => nonObsoletePageIds.includes(page._id));

    return orderedPages.flatMap(page => {
      const sections = page.sections.map(propertyOf(sectionById));

      const sectionRows = sections
        .map((section, sectionIndex) => {
          const sectionExchangeDefs = section.exchangeDefIds.map(propertyOf(exchangeDefById)) as
            EvaluationCriterionExchangeDefinition[];

          const sectionExchangeDefsSubrows = sectionExchangeDefs
            .map((exchangeDef, exchangeDefIndex) =>
              exchangeDef.isObsolete
                ? null
                : {
                  ...exchangeDef,
                  counter: `${sectionIndex + 1}.${exchangeDefIndex + 1}`,
                  percent: absoluteExchangeDefWeightById[exchangeDef._id],
                  noPadding: true,
                },
            )
            .filter(
              (exchangeDef) => exchangeDef !== null,
            ) as GridCriterionExchangeDef[];
          const hasLinkedExchangeDefs = sectionExchangeDefsSubrows.some(exchangeDef => 'linkedExchangeDefId' in exchangeDef);

          const originalSection = isLinkedEvaluationSection(section)
            ? sectionById[section.linkedSectionId]
            : section;

          return {
            ...section,
            lotId: !areLotsEnabled
              ? null
              : originalSection.lotIds?.[0] || 'general',
            lotName: !areLotsEnabled ? (
              null
            ) : originalSection.lotIds?.[0] ? (
              `${t('request.lot', { count: 1 })} ${lots.findIndex(lot => lot._id === originalSection.lotIds[0]) + 1} – ${lotById[originalSection.lotIds[0]].name}`
            ) : (
              t('request.generalRequirement_other')
            ),
            counter: `${sectionIndex + 1}`,
            percent: relativeSectionWeightById[section._id],
            hasLinkedExchangeDefs,
            subRows: [
              {
                _id: `${section._id}_${ExchangeProvider.SUPPLIER}`,
                isSubHeader: true,
                sectionId: section._id,
                description: t('general.description'),
                lineItemsIds: sectionExchangeDefsSubrows.map(({ _id }) => _id),
                noPadding: true,
              },
              ...sectionExchangeDefsSubrows,
            ],
          };
        })
        .filter(({ subRows }) => !isEmpty(subRows));

      return isLinkedEvaluationPage(page)
       ? [
        {
          _id: page._id,
          name: pageById[page.linkedPageId].name,
          percent: absolutePageWeightById[page._id],
          subRows: [],
        },
        ...sectionRows,
       ]
       : sectionRows;
    });
  }, [
    t,
    absolutePageWeightById,
    structure,
    relativeSectionWeightById,
    absoluteExchangeDefWeightById,
  ]);
};

const useGridContentData = ({
  columnData,
  rowData,
  exchanges,
  stats,
}: {
  columnData: Company[];
  rowData: ReturnType<typeof useRowData>;
  exchanges: ExchangeSnapshot[];
  stats: SpecificEvaluationStats;
}) => {
  const { pageById, pages, teamById, senders } = rfx.useStructure();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const { canManageEvaluation } = rfx.useRfxPermissions();
  const pagePermissionsById = rfx.usePagesPermissions();

  const {
    absolutePageWeightById,
    absoluteExchangeDefWeightById,
  } = rfx.useEvaluationWeights();

  return React.useMemo(() => {
    const nonObsoletePageById = mapValues(
      absolutePageWeightById,
      (value, pageId) => pageById[pageId],
    );

    const exchangeIds = flatMap(rowData, ({ subRows }) =>
      subRows.map((subRow) => subRow._id),
    );

    const sectionById = keyBy(rowData.filter(row => row.type === SectionType.EVALUATION), '_id') as Record<string, GridEvaluationSection>;
    const recipientById = keyBy(columnData, '_id');

    return mapValues(recipientById, (_, recipientId) => {
      const recipientExchanges = exchanges.filter(
        (exchange) =>
          exchange.recipientId === recipientId &&
          exchangeIds.includes(exchange._id),
      ) as EvaluationExchangeSnapshot[];
      const recipientExchangesById = keyBy(recipientExchanges, '_id');

      const sectionDataById = mapValues(
        sectionById,
        (section: GridEvaluationSection) => {
          const page = pages.find(page => page.sections.includes(section._id));

          const isCurrentUserEvaluator = page ? pagePermissionsById[page._id].canRespond : false;

          const pageHasAnyEvaluators = page
            ? (
              isCurrentUserEvaluator ||
              senders.some(sender => {
                const team = teamById[sender._id];

                return Object.values(team.users).some(user => user.rfqRoles && canRespond(user.rfqRoles[page._id]));
              })
            )
            : false;

          const nonObsoleteExchangeDefs = section.subRows.filter(subRow => !subRow.isSubHeader);
          const nonObsoleteExchanges = nonObsoleteExchangeDefs.map(
            (exchangeDef) => recipientExchangesById[exchangeDef._id],
          );

          const individualScoreByExchangeId = fromPairs(
            nonObsoleteExchanges.map((exchange) => [
              exchange?._id,
              getScore(exchange, { companyId: currentCompanyId, userId: currentUser._id }),
            ]),
          );

          const scoreByExchangeId = canManageEvaluation
            ? fromPairs(
              nonObsoleteExchanges.map((exchange) => [
                exchange?._id,
                getAverageScore(exchange),
              ]),
            )
            : individualScoreByExchangeId;

          const scoreStatusByExchangeId = mapValues(
            scoreByExchangeId,
            (score, exchangeId) => {
              if (!isNil(score)) {
                return ExchangeStatus.COMPLETE;
              }

              if (
                (canManageEvaluation && !pageHasAnyEvaluators) ||
                (!canManageEvaluation && !has(recipientExchangesById[exchangeId].latestReply, ['scores', currentCompanyId, currentUser._id]))
              ) {
                return ExchangeStatus.BLOCKED;
              }

              if (isCurrentUserEvaluator && isNil(individualScoreByExchangeId[exchangeId])) {
                return ExchangeStatus.ACTION_REQUIRED;
              }

              return ExchangeStatus.WAITING_FOR_TEAM;
            },
          );

          const weightedScoreByExchangeId = fromPairs(
            nonObsoleteExchanges.map((exchange) => [
              exchange?._id,
              getWeightedScore(
                exchange,
                scoreByExchangeId[exchange._id],
                absoluteExchangeDefWeightById[exchange._id],
              ),
            ]),
          );

          const weightedExchangeScores = values(weightedScoreByExchangeId);

          return {
            scoreStatusByExchangeId,
            weightedScoreByExchangeId,
            weightedTotalScore: weightedExchangeScores.some(isNil) ? null : sum(weightedExchangeScores),
          };
        },
      );

      const weightedScoreByPageId = mapValues(
        nonObsoletePageById,
        page => {
          const pageSections = values(pick(sectionDataById, page.sections));
          const pageScores = pageSections.map(section => section.weightedTotalScore);

          return pageScores.some(isNil) ? null : sum(pageScores);
        },
      );

      const pageScores = values(weightedScoreByPageId);

      return {
        exchangeById: recipientExchangesById,
        weightedScoreByPageId,
        sectionDataById,
        weightedTotalScore: pageScores.some(isNil) ? null : sum(pageScores),
        canManageEvaluation,
        canEvaluateLots: stats.canEvaluateLots,
        stats: stats.statsByRecipientId[recipientId],
      };
    });
  }, [
    absolutePageWeightById,
    rowData,
    columnData,
    pageById,
    exchanges,
    canManageEvaluation,
    stats,
    pages,
    pagePermissionsById,
    senders,
    teamById,
    currentCompanyId,
    currentUser._id,
    absoluteExchangeDefWeightById,
  ]);
};

const FormulaFieldValue = ({ exchange, field }) => {
  const value = exchange.computedFormulas?.[field._id];
  const locale = useCurrentUserLocale();

  return isNil(value) || value === '' ? (
    <EmDash />
  ) : field.type === 'price' ? (
    localeFormatPrice(value, exchange.currency, { locale, showCode: true, decimalPlaces: 2 })
  ) : (
    value
  );
};

export const ReplyFieldValue = ({ exchange, field }) => {
  const { t } = useTranslation();
  const locale = useCurrentUserLocale();
  const value = field
    ? exchange.latestReply[field._id]
    : null;

  return isNil(value) || value === '' ? (
    <EmDash />
  ) : field._id === 'leadTime:submitter' ? (
    <NumberFormat
      displayType="text"
      // @ts-expect-error ts(2783) FIXME: 'thousandSeparator' is specified more than once, so this usage will be overwritten.
      thousandSeparator
      value={exchange.latestReply['leadTime:submitter'] as number}
      style={{ display: 'inline' }}
      {...formatOptions.integer}
    />
  ) : field.type === 'date' ? (
    <Datetime onlyDate value={value as Date} display="inline" />
  ) : field.type === 'boolean' ? (
    <>
      {value ? (
        t('request.lineItems.deliveryDate.accepted')
      ) : (
        t('request.lineItems.deliveryDate.rejected')
      )}
    </>
  ) : field.type === 'price' ? (
    localeFormatPrice(value, exchange.currency, { locale, showCode: true, decimalPlaces: field.decimalPlaces })
  ) : (
    value
  );
};

const EvaluationLinkedResponseCell = ({
  exchange,
  exchanges,
  recipientId,
  rowHeight,
}: {
  exchange: EvaluationExchangeSnapshot;
  exchanges: ExchangeSnapshot[];
  recipientId: string;
  rowHeight: number;
}) => {
  const { t } = useTranslation(['translation', 'general']);
  const [downloadAttachment, { isLoading: isDownloadAttachmentLoading }] = useDownloadRfqAttachment();
  const [isTextClamped, setIsTextClamped] = React.useState(false);
  const { exchangeDefById } = rfx.useStructure();
  const locale = useCurrentUserLocale();
  const linkedExchange = React.useMemo(() => {
    if (!(exchange.def && 'linkedExchangeDefId' in exchange.def)) {
      return null;
    } else {
      return find(exchanges, { _id: exchange.def.linkedExchangeDefId, recipientId });
    }
  }, [exchange.def, exchanges, recipientId]);

  const response = React.useMemo(() => {
    if (!linkedExchange) {
      return null;
    }

    if (linkedExchange.def.type === ExchangeType.QUESTION) {
      const formatQuestionResponse = createFormatQuestionResponse({
        t,
        locale,
      });
      const result = formatQuestionResponse({
        response: linkedExchange.latestResponse,
        exchangeDef: linkedExchange.def,
      });
      return result;
    }

    if (linkedExchange.def.type === ExchangeType.LINE_ITEM) {
      const linkedLineItemExchange = linkedExchange as LineItemsExchangeSnapshot;

      const filteredEntries = Object
        .entries({
          ...linkedLineItemExchange.latestReply,
          ...omit(linkedLineItemExchange.computedFormulas, 'targetTotalCost'),
        })
        .filter(([fieldId, value]) => {
          // @ts-expect-error ts(18048) FIXME: 'linkedExchange.def.fields' is possibly 'undefined'.
          const field = linkedExchange.def.fields[fieldId];

          return field && isFieldValueDefined(value, field.type);
        });

      const linkedExchangeDef = exchangeDefById[linkedExchange._id] as LineItemExchangeDefinition;
      const orderedFieldIds = (
        linkedExchangeDef.orderedFieldIds ||
        getFieldIdsInDefaultDisplayOrder(Object.keys(linkedExchangeDef.fields))
      );

      const sortedFilteredEntries = sortBy(
        filteredEntries,
        ([fieldId]) => orderedFieldIds.indexOf(fieldId),
      );

      const result = sortedFilteredEntries.map(([fieldId]) => {
        // @ts-expect-error ts(18048) FIXME: 'linkedExchange.def.fields' is possibly 'undefined'.
        const field = linkedExchange.def.fields[fieldId];
        const lineItemLabel = field ? getFormattedFieldLabel(field, t) : '';
        const lineItemValue = isReplyField(field)
          ? <ReplyFieldValue exchange={linkedExchange} field={field} />
          : <FormulaFieldValue exchange={linkedExchange} field={field} />;

        return (
          <Text key={fieldId}>
            <b>{lineItemLabel}:&nbsp;</b>
            {lineItemValue}
          </Text>
        );
      });
      return result.length ? result : null;
    }

    if (documentExchangeTypes.includes(linkedExchange.def.type)) {
      const attachment = linkedExchange.latestSupplierAttachment as Attachment;

      const recipientId = find(linkedExchange.companies, { group: 'recipient' })?._id;
      const latestSupplierComment = findLast(linkedExchange.history, (item) =>
        item.comment && ['submit', 'deviate'].includes(item.type) && item.companyId === recipientId)?.comment;
      const lastSupplierHistoryActionType = findLast(
        linkedExchange.history,
        ({ companyId, type }) => type !== ActionType.NONE && companyId === recipientId,
      )?.type as ActionType;
      const supplierAcceptance = [ActionType.ACCEPT, ActionType.REJECT].includes(lastSupplierHistoryActionType);

      if (!supplierAcceptance && !attachment?.name && !latestSupplierComment) {
        return null;
      }

      return (
        <Flex alignItems="baseline" sx={{ gap: 2 }}>
          {attachment?.name && (
            <Icon
              icon="file"
              color="subtext"
              regular
            />
          )}
          {supplierAcceptance ? (
            <IconText
              icon={lastSupplierHistoryActionType === ActionType.ACCEPT ? 'check' : 'times'}
              text={t(`request.documents.supplierResponseStatus.${lastSupplierHistoryActionType}`)}
              gap={2}
              fontSize={1}
            />
          ) : (
            <Clamp lines={exchangeDefLineClampByRowHeight[rowHeight]} style={{ flex: 1 }} setIsClamped={setIsTextClamped}>
              {attachment?.name || latestSupplierComment}
            </Clamp>
          )}
          {attachment?.name && (
            <IconButton
              fixedWidth
              icon="download"
              color="primary"
              onClick={event => {
                event.stopPropagation();
                downloadAttachment(attachment);
              }}
              disabled={isDownloadAttachmentLoading}
              ml={2}
            />
          )}
        </Flex>
      );
    }

    return null;
  }, [linkedExchange, t, locale, exchangeDefById, rowHeight, isDownloadAttachmentLoading, downloadAttachment]);

  if (!linkedExchange || (!response && linkedExchange.isResolved)) {
    return <>{t('general.notApplicableShort')}</>;
  }

  if (!response) {
    return (
      <Flex alignItems="center">
        <Icon icon="spinner" color="subtext" mr={2} />
        <EmDash />
      </Flex>
    );
  }

  let tooltip = '';

  if (linkedExchange.def.type === ExchangeType.LINE_ITEM) {
    tooltip = response;
  } else if (documentExchangeTypes.includes(linkedExchange.def.type)) {
    // @ts-expect-error ts(2322) FIXME: Type 'string | false | undefined' is not assignable to type 'string'.
    tooltip = linkedExchange.latestSupplierAttachment && 'name' in linkedExchange.latestSupplierAttachment && linkedExchange.latestSupplierAttachment.name;
  } else if (linkedExchange.def.type === ExchangeType.QUESTION) {
    tooltip = response;
  }

  return (
    <Flex flex={1} alignItems="flex-start">
      {linkedExchange.isResolved
        ? <Icon icon="check-circle" color="success" mr={2} />
        : <Icon icon="exclamation-circle" color="warning" mr={2} />}
      <Tooltip content={isTextClamped ? tooltip : null} placement="top">
        <PreWrap flex={1}>
          <Clamp
            lines={exchangeDefLineClampByRowHeight[rowHeight]} style={{ flex: 1 }}
            // @ts-expect-error ts(2322) FIXME: Type 'false | Dispatch<SetStateAction<boolean>>' is not assignable to type '((isClamped: boolean) => void) | undefined'.
            setIsClamped={!documentExchangeTypes.includes(linkedExchange.def.type) && setIsTextClamped}
          >
            {response}
          </Clamp>
        </PreWrap>
      </Tooltip>
    </Flex>
  );
};

export const EvaluationComparisonGrid = ({
  rowData,
  columnData,
  subRowHeight,
  selectedRecipientSort,
  collapsedRowIds,
  setCollapsedRowIds,
  setExchangeModalProps,
  stats,
  gridRef,
}: {
  rowData: ReturnType<typeof useRowData>;
  columnData: Company[];
  subRowHeight: number;
  collapsedRowIds: string[];
  selectedRecipientSort?: RecipientOrderItem;
  setCollapsedRowIds: React.Dispatch<React.SetStateAction<string[]>>;
  setExchangeModalProps: (props: UseExchangeModalStateProps) => void;
  stats: SpecificEvaluationStats;
  gridRef?: React.MutableRefObject<any>;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const navigate = useNavigate();
  const locale = useCurrentUserLocale();
  const rfqId = useRfqId();
  const structure = rfx.useStructure();
  const exchanges = rfx.useExchanges();
  const { canManageEvaluation } = rfx.useRfxPermissions();
  const scoringType = rfx.useEvaluationScoringType();
  const hasLinkedResponses = rowData.some(row => row.hasLinkedExchangeDefs);
  const gridContentData = useGridContentData({
    columnData,
    rowData,
    exchanges,
    stats,
  });
  const switchToExchange = useSwitchToExchange();
  const orderedColumnData = React.useMemo(() => {
    const defaultSort = columnData.slice().sort((a, b) => a.company.name.localeCompare(b.company.name, locale));
    switch (selectedRecipientSort) {
      case 'lowestTotalScore':
        return sortBy(defaultSort, (recipient) => gridContentData[recipient._id]?.weightedTotalScore ?? -1);
      case 'highestTotalScore':
        return sortBy(defaultSort, (recipient) => gridContentData[recipient._id]?.weightedTotalScore ?? -1).reverse();
      case 'alphabetical':
      default:
        return defaultSort;
    }
  }, [locale, columnData, gridContentData, selectedRecipientSort]);

  const recipientIds = React.useMemo(() => orderedColumnData.map(({ _id }) => ({ recipientId: _id })), [orderedColumnData]);

  React.useEffect(() => {
    if (!isEqual(switchToExchange.horizontalTargets, recipientIds)) {
      switchToExchange.setHorizontalTargets?.(recipientIds);
    }
  }, [recipientIds, switchToExchange]);

  const openExchangeModal = React.useCallback(
    (
      row: RowData<GridCriterionExchangeDef, GridEvaluationSection>,
      column: ColumnData<Company>,
    ) => {
      setExchangeModalProps({
        exchangeId: row.original._id,
        recipientId: column.original._id,
      });
    },
    [setExchangeModalProps],
  );

  const FrozenHeaderCell = React.useMemo(() => {
    return createRecipientHeaderCell({
      structure,
      navigateToBidPage: (recipientId: string) => {
        navigate({
          to: structure.newFeaturesDisabled
            ? legacyRequestSentRecipientEvaluationIndexRoute.to
            : requestSentRecipientEvaluationIndexRoute.to,
          params: { currentCompanyId, rfqId, recipientId },
        });
      },
    });
  }, [currentCompanyId, navigate, rfqId, structure]);

  const FrozenFooterCell = React.useMemo(
    // @ts-expect-error ts(2322) FIXME: Type '{ [x: string]: { exchangeById: Dictionary<EvaluationExchangeSnapshot>; weightedScoreByPageId: { [x: string]: number | null; }; sectionDataById: { ...; }; weightedTotalScore: number | null; canManageEvaluation: boolean; canEvaluateLots: boolean; stats: EvaluationActionStats; }; }' is not assignable to type 'GridContentData'.
    () => createFrozenFooterCell({ gridContentData, t }),
    [gridContentData, t],
  );

  const FirstColumnCell = React.useMemo(
    () => createFirstColumnCell(stats, t),
    [t, stats],
  );

  const DataCell = React.useMemo(
    () => createDataCell({
      // @ts-expect-error ts(2322) FIXME: Type '{ [x: string]: { exchangeById: Dictionary<EvaluationExchangeSnapshot>; weightedScoreByPageId: { [x: string]: number | null; }; sectionDataById: { ...; }; weightedTotalScore: number | null; canManageEvaluation: boolean; canEvaluateLots: boolean; stats: EvaluationActionStats; }; }' is not assignable to type 'GridContentData'.
      gridContentData,
      openExchangeModal,
      t,
      hasLinkedResponses,
      exchanges,
    }),
    [exchanges, hasLinkedResponses, gridContentData, openExchangeModal, t],
  );

  const handleDataCellKeyboardAction = React.useCallback((
    data: GridDataAndCommands<
      (typeof columnData)[number],
      (typeof rowData)[number]['subRows'][number],
      (typeof rowData)[number]
    >,
    event: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    // @ts-expect-error ts(18047) FIXME: 'data.activeCellIndices' is possibly 'null'.
    const row = data.rows[data.activeCellIndices.rowIndex];
    // @ts-expect-error ts(18047) FIXME: 'data.activeCellIndices' is possibly 'null'.
    const column = data.columns[data.activeCellIndices.columnIndex];

    if ([KeyCode.ENTER, KeyCode.SPACE].includes(event.code as KeyCode)) {
      stopEvent(event);

      // @ts-expect-error ts(2345) FIXME: Argument of type 'RowData<any, any> | null' is not assignable to parameter of type 'RowData<GridCriterionExchangeDef, GridEvaluationSection>'.
      openExchangeModal(row, column);
    }
  }, [openExchangeModal]);

  const {
    effectiveRowData,
    navigableRange,
  } = React.useMemo(() => {
    const inlineSubRowCount = canManageEvaluation && scoringType === ScoringType.INDIVIDUAL_SCORES
      ? 3
      : canManageEvaluation || stats.canEvaluateLots
        ? 2
        : 0;

    return inlineSubRowCount === 0 || structure.status === RfqStatus.AWARDED || structure.status === RfqStatus.CLOSED
      ? {
        effectiveRowData: rowData,
        navigableRange: { startRowIndex: 1, startColumnIndex: 1 },
      }
      : {
        effectiveRowData: [
          {
            _id: STATS_ID,
            rowType: 'stats',
            inlineSubRowCount,
            subRows: [],
          },
          ...rowData,
        ],
        navigableRange: { startRowIndex: 2, startColumnIndex: 1 },
      };
  }, [rowData, canManageEvaluation, scoringType, stats, structure]);

  const columnDataWithFirstColumn = React.useMemo(() => {
    return [
      DEFAULT_FROZEN_LEFT_COLUMN as any,
      ...orderedColumnData,
    ];
  }, [orderedColumnData]);

  const frozenLeftColumnIds = DEFAULT_FROZEN_LEFT_COLUMN_IDS;

  return (
    <DefaultComparisonGridStyles>
      <ComparisonGrid
        gridRef={gridRef}
        columnData={columnDataWithFirstColumn}
        rowData={effectiveRowData}
        subRowHeight={subRowHeight}
        collapsedRowIds={collapsedRowIds}
        setCollapsedRowIds={setCollapsedRowIds}
        onDataCellKeyboardAction={handleDataCellKeyboardAction}
        staticRowHeights
        FrozenHeaderCell={FrozenHeaderCell}
        FrozenFooterCell={FrozenFooterCell}
        FirstColumnCell={FirstColumnCell}
        DataCell={DataCell}
        navigableRange={navigableRange}
        defaultColumnWidth={hasLinkedResponses ? sum(values(staticGridCellWidthsMap)) : undefined}
        frozenLeftColumnIds={frozenLeftColumnIds}
      />
    </DefaultComparisonGridStyles>
  );
};
