import * as React from 'react';
import { map, sortBy, uniq } from 'lodash';
import { Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { ExchangeType, StageApprovalStatus, getApprovalRequestStatus } from '@deepstream/common/rfq-utils';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { SelectDropdownMenu } from '../../ui/MultiSelect';
import * as rfx from '../../rfx';
import { StageApprovalsTable } from './StageApprovalsTable';
import { StageApprovalExchangeSnapshot } from '../../types';
import { useCurrentUser } from '../../useCurrentUser';

type StageFilterItem = {
  value: string | undefined,
  label: string,
};

export type StageApprovalsPanelProps = {
  canRequestStageApproval: boolean;
  openRequestApprovalModal: () => void;
};

export const StageApprovalsPanel: React.FC<StageApprovalsPanelProps> = ({
  canRequestStageApproval,
  openRequestApprovalModal,
}) => {
  const { t } = useTranslation('translation');
  const currentUser = useCurrentUser();

  const allExchanges = rfx.useExchanges();
  const exchanges = React.useMemo(() => {
    return allExchanges.filter(
      (exchange) => exchange.def.type === ExchangeType.STAGE_APPROVAL,
    );
  }, [allExchanges]);

  const { canEditApprovals } = rfx.useRfxPermissions();
  const stages = rfx.useStages();

  const indexedApprovalRequests = React.useMemo(() => {
    const requests = (exchanges as StageApprovalExchangeSnapshot[]).map(
      (exchange) => {
        const { stageId } = exchange.def;
        const stageIndex = stages.findIndex((stage) => stage._id === stageId);

        return {
          ...exchange,
          stageIndex: stageIndex + 1,
        };
      },
    );

    if (canEditApprovals) {
      return requests;
    } else {
      return requests.filter((approvalRequest) =>
        approvalRequest.approvals.some(
          (approval) => approval.userId === currentUser._id,
        ),
      );
    }
  }, [exchanges, canEditApprovals, stages, currentUser._id]);

  const isApprover = React.useMemo(() => {
    return indexedApprovalRequests.some((approvalRequest) =>
      approvalRequest.approvals.some(
        (approval) => approval.userId === currentUser._id,
      ),
    );
  }, [indexedApprovalRequests, currentUser._id]);

  const { stageFilterItems, preSelectedItems } = React.useMemo(() => {
    const stageIds = uniq(indexedApprovalRequests.map(request => request.def.stageId));

    const sortedStageIds = sortBy(
      stageIds,
      stageId => stageId
        ? stages.findIndex((stage) => stage._id === stageId)
        // the draft stage should be first
        : -1,
    );

    const items = sortedStageIds.map((stageId) => {
      const stageIndex = stages.findIndex((stage) => stage._id === stageId);

      return {
        value: stageId,
        label: stageId
          ? `${t('general.stage', { count: 1 })} ${stageIndex + 1}`
          : t('request.stageApprovals.draftStage'),
      };
    });

    return {
      stageFilterItems: items,
      preSelectedItems: [...items],
    };
  }, [indexedApprovalRequests, stages, t]);

  const [selectedItems, setSelectedItems] = React.useState<StageFilterItem[]>(preSelectedItems ?? []);

  const stagesFilter = React.useMemo(() => {
    return {
      itemToString: (item: StageFilterItem | null) => item ? item.label : '',
      items: stageFilterItems,
      selectedItems,
      onChange: setSelectedItems,
    };
  }, [selectedItems, stageFilterItems]);

  React.useEffect(() => {
      setSelectedItems(preSelectedItems);
  }, [preSelectedItems]);

  const selectedApprovals = React.useMemo(() => {
    if (stagesFilter.selectedItems.length === 0) {
      return indexedApprovalRequests;
    } else {
      const selectedStages = map(stagesFilter.selectedItems, 'value');
      return indexedApprovalRequests.filter((approvalRequest) => selectedStages.includes(approvalRequest.def.stageId));
    }
  }, [indexedApprovalRequests, stagesFilter.selectedItems]);

  const pendingResponsesCount = React.useMemo(() => {
    return selectedApprovals.filter(
      (approvalRequest) =>
        getApprovalRequestStatus(approvalRequest.status) !== StageApprovalStatus.CANCELLED &&
        approvalRequest.approvals.some(
          (approval) =>
            approval.userId === currentUser._id && approval.status === 'pending',
        ),
    )?.length;
  }, [selectedApprovals, currentUser._id]);

  return (
    <Panel mb={20}>
      <PanelHeader
        heading={
          <Flex flexDirection="row" sx={{ columnGap: '32px' }}>
            <div>{t('request.stageApprovals.stageApproval_other')}</div>
            {stageFilterItems.length > 1 && (
              <SelectDropdownMenu
                multi
                getButtonText={(items) => {
                  if (items.length === stageFilterItems.length || items.length === 0) {
                    return t('request.stageApprovals.allStages');
                  } else if (items.length === 1) {
                    return items[0].label;
                  } else {
                    return t('stageCount', { count: items.length, ns: 'general' });
                  }
                }}
                buttonIcon="filter"
                menuWidth={180}
                menuZIndex={10}
                {...stagesFilter}
              />
            )}
          </Flex>
        }
        icon="circle-check"
        iconProps={{ regular: true }}
        justifyContent="space-between"
        flex="1"
      >
        <Flex sx={{ columnGap: '32px' }} alignItems="center">
          {pendingResponsesCount > 0 && (
            <Flex flexDirection="column" sx={{ rowGap: '2px' }}>
              <Text fontSize={1} fontWeight={500}>
                {t('response', { ns: 'general' })}
              </Text>
              <IconText
                text={t('request.stageApprovals.responseRequired', {
                  count: pendingResponsesCount,
                })}
                icon="exclamation-circle"
                iconColor="danger"
                textStyle={{ fontWeight: 500 }}
              />
            </Flex>
          )}
          {canRequestStageApproval && (
            <Button
              small
              iconLeft="plus"
              variant="secondary-outline"
              onClick={() => openRequestApprovalModal()}
            >
              {t('request.stageApprovals.newApproval')}
            </Button>
          )}
        </Flex>
      </PanelHeader>
      <PanelDivider />

      <PanelPadding>
        <StageApprovalsTable
          approvalRequests={selectedApprovals}
          currentUser={currentUser}
          canEditApprovals={canEditApprovals}
          canRequestStageApproval={canRequestStageApproval}
          isApprover={isApprover}
          openRequestApprovalModal={openRequestApprovalModal}
        />
      </PanelPadding>
    </Panel>
  );
};
