import { AwardDecision, CostAndSavings, AwardScenario } from '@deepstream/common/rfq-utils';
import { compact, first, flatten, isEmpty, sum } from 'lodash';
import { useMemo } from 'react';
import { filterCostAndSavingsForTotalCalculation } from '@deepstream/common/rfq-utils/spendAndSavings';
import { AwardFlowData } from './types';

export const getRequestAwardTotalAmount = (costAndSavings: CostAndSavings) => {
  const items = flatten(Object.values(filterCostAndSavingsForTotalCalculation(costAndSavings)));

  return isEmpty(items)
    ? null
    : sum(compact(items.map(item => item.valueB)));
};

// NB If this turns out to be too slow, calculate the totals for all exchanges
// in a single step and then query the result.
export const getLineAwardData = (
  costAndSavings: CostAndSavings,
  exchangeId: string,
) => {
  const items = flatten(Object.values(filterCostAndSavingsForTotalCalculation(costAndSavings, [exchangeId])));

  return items[0];
};

// NB If this turns out to be too slow, calculate the totals for all exchanges
// in a single step and then query the result.
export const getLineAwardTotalAmount = (
  costAndSavings: CostAndSavings,
  exchangeId: string,
) => {
  const items = flatten(Object.values(filterCostAndSavingsForTotalCalculation(costAndSavings, [exchangeId])));

  return items[0]?.valueB || null;
};

export const getLotAwardTotalAmount = (costAndSavings: CostAndSavings, exchangeIds: string[]) => {
  const items = flatten(Object.values(filterCostAndSavingsForTotalCalculation(costAndSavings, exchangeIds)));

  return isEmpty(items)
    ? null
    : sum(compact(items.map(item => item.valueB)));
};

export const useCalculatedTotalValue = (data: AwardFlowData) => {
  return useMemo(() => {
    switch (data.awardScenario) {
      case AwardScenario.REQUEST_LEVEL_AWARD: {
        return data.requestAward?.requestCurrencyAwardedCost || 0;
      }
      case AwardScenario.LINE_LEVEL_AWARD: {
        const { awardDecisionByExchangeId, splitDecisionByExchangeId } = data;

        // @ts-expect-error ts(2769) FIXME: No overload matches this call.
        const amounts = Object.entries(awardDecisionByExchangeId)
          // @ts-expect-error ts(18047) FIXME: 'awardDecision' is possibly 'null'.
          .filter(([, awardDecision]) => awardDecision.value === 'award')
          .map(([exchangeId, awardDecision]) => {
            const splitDecision = splitDecisionByExchangeId?.[exchangeId];

            if (splitDecision) {
              const splitDecisionAmounts = Object.values(splitDecision.decisionByRecipientId)
                .map(decision => decision.requestCurrencyAwardedCost || 0);

              return sum(splitDecisionAmounts) || 0;
            } else {
              // @ts-expect-error ts(2339) FIXME: Property 'requestCurrencyAwardedCost' does not exist on type 'Award | undefined'.
              const { requestCurrencyAwardedCost } = first((awardDecision as Extract<AwardDecision, { value: 'award' }>).awards);

              return requestCurrencyAwardedCost || 0;
            }
          });

        return sum(amounts) || 0;
      }
      case AwardScenario.LOT_LEVEL_AWARD: {
        const { awardDecisionByLotId } = data;

        // @ts-expect-error ts(2769) FIXME: No overload matches this call.
        const amounts = Object.entries(awardDecisionByLotId)
          // @ts-expect-error ts(18047) FIXME: 'awardDecision' is possibly 'null'.
          .filter(([, awardDecision]) => awardDecision.value === 'award')
          .map(([_, awardDecision]) => {
            // @ts-expect-error ts(2339) FIXME: Property 'requestCurrencyAwardedCost' does not exist on type 'Award | undefined'.
            const { requestCurrencyAwardedCost } = first((awardDecision as Extract<AwardDecision, { value: 'award' }>).awards);

            return requestCurrencyAwardedCost || 0;
          });

        return sum(amounts) || 0;
      }
      default:
        throw new Error(`Expected request, line or lot level award scenario but got ${data.awardScenario}`);
    }
  }, [data]);
};
