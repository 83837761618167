import { useState } from 'react';
import { first, last } from 'lodash';
import { useTranslation } from 'react-i18next';

import { EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';
import { ConfirmDeleteDialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import {
  EllipsisMenu,
  MoveUpMenuItem,
  MoveDownMenuItem,
  DeleteMenuItem,
  DuplicateMenuItem,
} from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { useConfirmDialog, useModalState } from '../../ui/useModalState';
import {
  getSectionExchangeDefs,
  useContractActions,
  useContractData,
  useContractPage,
  useContractSection,
  useContractState,
  useIsPendingCollaborator,
  usePagePermissions,
} from './contract';
import {
  useDuplicateContractSection,
  useIsMutationLoading,
  useMoveContractSection,
  useRemoveContractSection,
} from './draftContract';
import { getSizeRelevantExchangeDefCount, useModelSizeLimits } from '../../modelSizeLimits';
import { ModelSizeLimitDialog, ModelSizeLimitMessages } from '../../ModelSizeLimitDialog';
import { useDraftContractNavigation } from '../../appNavigation';

/**
 * Controls for performing section actions.
 */
export const ContractSectionActions = ({
  onEditClick,
}: {
  onEditClick?: () => void;
}) => {
  const { t } = useTranslation(['contracts', 'translation']);

  // @ts-expect-error ts(2339) FIXME: Property 'editingPanelId' does not exist on type 'ContractStateContextType | undefined'.
  const { editingPanelId, isReview } = useContractState();
  const section = useContractSection();
  const contract = useContractData();
  const { startEditing } = useContractActions();
  const isMutationLoading = useIsMutationLoading();
  const [duplicateSection] = useDuplicateContractSection();
  const [moveSection] = useMoveContractSection();
  const [removeSection] = useRemoveContractSection();
  const page = useContractPage();
  const { canEdit } = usePagePermissions();
  const { navigateToDetailsPage } = useDraftContractNavigation();
  const isPendingCollaborator = useIsPendingCollaborator();
  const { confirm, ...dialogProps } = useConfirmDialog();
  const { maxExchangeDefCount } = useModelSizeLimits();
  const modelSizeLimitModal = useModalState();
  const [modelSizeLimitMessages, setModelSizeLimitMessages] = useState<ModelSizeLimitMessages | null>(null);

  const handleDuplicateClick = () => {
    const totalExchangeDefCount = getSizeRelevantExchangeDefCount(contract.exchangeDefById);
    const sectionExchangeDefCount = getSizeRelevantExchangeDefCount(
      getSectionExchangeDefs(section, contract),
    );

    if (totalExchangeDefCount + sectionExchangeDefCount > maxExchangeDefCount) {
      setModelSizeLimitMessages({
        heading: t('dialog.contractSizeLimit.heading'),
        title: t('dialog.contractSizeLimit.duplicateSection.title'),
        warning: t('dialog.contractSizeLimit.duplicateSection.warning'),
        body: t('dialog.contractSizeLimit.duplicateSection.body', {
          count: totalExchangeDefCount + sectionExchangeDefCount - maxExchangeDefCount,
        }),
      });
      modelSizeLimitModal.open();
    } else {
      duplicateSection(section._id);
    }
  };

  const disabled = Boolean(editingPanelId || isMutationLoading);

  const isFirstSection = first(page.sections) === section._id;
  const isLastSection = last(page.sections) === section._id;

  if (!canEdit) return null;

  return !isPendingCollaborator ? (
    !isReview ? (
      <>
        <ButtonGroup marginBetween="-1px" ml={4}>
          <EditButton
            small
            onClick={() => {
              startEditing(section._id);
              onEditClick?.();
            }}
            disabled={disabled}
            data-test="edit-section-button"
          />
          <EllipsisMenu
            small
            disabled={disabled}
            data-test="more-section-actions-button"
            popoverProps={{
              'data-test': 'more-section-actions-menu',
            }}
          >
            <MoveUpMenuItem
              onSelect={() => moveSection(-1)}
              disabled={isFirstSection}
            />
            <MoveDownMenuItem
              onSelect={() => moveSection(+1)}
              disabled={isLastSection}
            />
            <DuplicateMenuItem
              onSelect={handleDuplicateClick}
              data-test="duplicate-section-button"
            />
            {!section.isLive ? (
              <DeleteMenuItem
                onSelect={() => confirm(() => removeSection({ _id: section._id }))}
              />
            ) : (
              null
            )}
          </EllipsisMenu>
        </ButtonGroup>
        <ConfirmDeleteDialog
          heading={t('dialog.confirmSectionDeletion.heading')}
          message={t('dialog.confirmSectionDeletion.message')}
          {...dialogProps}
        />
        <ModelSizeLimitDialog
          modal={modelSizeLimitModal}
          messages={modelSizeLimitMessages}
        />
      </>
    ) : (
      <EditButton
        small
        variant="primary"
        type="button"
        ml={4}
        onClick={() => navigateToDetailsPage(page._id)}
      />
    )
  ) : (
    null
  );
};
