import { useContext } from 'react';
import * as Sentry from '@sentry/react';
import { Flex } from 'rebass/styled-components';
import { UnexpectedStateError } from '@deepstream/errors';
import { QuestionType, DocumentQuestionExchangeDefinition, QuestionExchangeDefinition } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Radio } from '../../../ui/Radio';

export const Document = ({
  exchangeDef,
}: {
  exchangeDef: QuestionExchangeDefinition;
}) => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);

  const {
    options,
    questionType,
    allowCustomOption,
    requireDocument,
    requireExpiry,
  } = exchangeDef as DocumentQuestionExchangeDefinition;

  if (questionType !== QuestionType.DOCUMENT) {
    Sentry.captureException(new UnexpectedStateError('[Document] Wrong question type passed', { questionType }));
    return null;
  }

  return (
    <Flex>
      <Stack gap={2} sx={{ width: '100px' }}>
        {options.map((option) => (
          <Radio
            key={option}
            disabled
            label={t(`request.question.predefinedOption.${option}`)}
            useDefaultDisabledStyle={false}
            labelStyle={{ color: theme.colors.text, fontWeight: 400 }}
          />
        ))}
        {allowCustomOption && (
          <Radio
            disabled
            label={t('request.question.other')}
            useDefaultDisabledStyle={false}
            labelStyle={{ color: theme.colors.text, fontWeight: 400 }}
          />
        )}
      </Stack>
      <Flex>
        <Checkbox
          disabled
          checked={requireDocument}
          label={t('request.question.document.requireDocument')}
          useDefaultDisabledStyle={false}
        />
        <Checkbox
          style={{ marginLeft: '12px' }}
          disabled
          checked={requireExpiry}
          label={t('request.question.document.requireExpiry')}
          useDefaultDisabledStyle={false}
        />
      </Flex>
    </Flex>
  );
};
