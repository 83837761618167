import { useCallback } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { compact, isNil, isNumber } from 'lodash';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { RoundIconButton } from '@deepstream/ui-kit/elements/button/RoundIconButton';
import { FieldContainer } from './FieldContainer';
import { ErrorMessage, HelperText } from './Field';
import { FormGroup } from '../ui/FormGroup';
import { Input, InputSuffix } from '../ui/Input';

export const MinutesStepperField = ({
  name,
  label,
  description,
  hideLabel,
  helperText,
  required,
  gap,
  increment,
  disabled,
}: {
  name: string;
  label?: string;
  hideLabel?: boolean;
  description?: string;
  helperText?: string;
  required?: boolean;
  gap?: string | number;
  increment: number;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const id = useUniqueId();

  const descriptionId = description ? `${id}-description` : undefined;
  const helperTextId = helperText ? `${id}-description` : undefined;

  const describedBy = compact([descriptionId, helperTextId]).join(' ') || undefined;

  const [field, meta, formik] = useField({ name });
  const value = isNumber(field.value)
    ? field.value
    : null;

  const onValueChange = useCallback(
    (value) => {
      formik.setValue(isNumber(value) ? value : null);
    },
    [formik],
  );

  const error = meta.touched && meta.error ? meta.error : undefined;

  return (
    <FieldContainer
      name={name}
      htmlFor={id}
      label={label}
      description={description}
      descriptionId={descriptionId}
      hideLabel={hideLabel}
      showAsterisk={required}
      width="100%"
      gap={gap}
    >
      <Flex alignItems="center">
        <RoundIconButton
          icon="minus"
          disabled={!field.value || field.value <= increment || disabled}
          onClick={() => formik.setValue(Math.ceil((field.value ?? 0) / increment) * increment - increment)}
        />
        <FormGroup mx={2}>
          <Input
            id={id}
            type="text"
            format="integer.positive"
            name={name}
            value={isNil(value) ? '' : value}
            onChange={field.onChange}
            onValueChange={onValueChange}
            onBlur={field.onBlur}
            disabled={disabled}
            // quick fix: we can remove setting the inputStyle height once a line height is
            // set for Input
            style={{ textAlign: 'center', width: 72, height: 40 }}
            aria-describedby={describedBy}
          />
          <InputSuffix>{t('general.minuteShort', { count: value ?? 0 })}</InputSuffix>
        </FormGroup>
        <RoundIconButton
          icon="plus"
          disabled={disabled}
          onClick={() => formik.setValue(Math.floor((field.value ?? 0) / increment) * increment + increment)}
        />
      </Flex>
      {error ? (
        <Box sx={{ wordBreak: 'break-word', textAlign: 'left' }}>
          <ErrorMessage error={error} fontWeight="normal" />
        </Box>
      ) : helperText ? (
        <Flex justifyContent="space-between">
          {helperText && (
            <HelperText text={helperText} id={helperTextId} />
          )}
        </Flex>
      ) : (
        null
      )}
    </FieldContainer>
  );
};
