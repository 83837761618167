import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { useRouter } from '@tanstack/react-router';
import { ModalForm } from '../../ModalForm';
import { useSupplierListData } from './SupplierListProvider';
import { useUpdateSupplierList } from './useUpdateSupplierList';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useValidateUniqueName } from './useValidateUniqueName';
import { TextField } from '../../form/TextField';

export const EditListNameModal = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const supplierList = useSupplierListData();
  const [updateSupplierList] = useUpdateSupplierList();
  const validate = useValidateUniqueName({ listId: supplierList._id });
  const router = useRouter();

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      name: z
        .string({ message: t('supplierLists.configFlow.errors.requiredListName') })
        .trim()
        .min(1, { message: t('supplierLists.configFlow.errors.requiredListName') }),
      description: z.string().optional(),
    });

    return toFormikValidationSchema(Schema);
  }, [t]);

  return (
    <ModalForm
      heading={t('supplierLists.editListNameModal.heading')}
      isOpen={isOpen}
      onCancel={close}
      onSubmit={async ({ name }) => {
        await updateSupplierList({
          listId: supplierList._id,
          companyId: currentCompanyId,
          name: name.trim(),
        }, {
          onSettled: () => router.invalidate(),
        });

        close();
      }}
      initialValues={{
        name: supplierList.name,
      }}
      validationSchema={validationSchema}
      validate={validate}
      submitLabel={t('general.saveChanges')}
    >
      <TextField
        name="name"
        label={t('supplierLists.name')}
        required
      />
    </ModalForm>
  );
};
