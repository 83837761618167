import { QuestionnaireOverview } from '@deepstream/common/preQual';
import { CellProps } from 'react-table';
import { Box, Flex, Text } from 'rebass/styled-components';
import { ProgressBar } from './ui/ProgressBar';
import { PercentageFieldValue } from './FieldsCell';

export const CompletionCell = ({ value }: CellProps<QuestionnaireOverview, number>) => {
  return (
    <Flex alignItems="center">
      <Box flex="1 1 auto" maxWidth="150px">
        <ProgressBar variant="info" value={value * 100} showValue={false} />
      </Box>
      <Text flex="0 0 40px" ml={3} fontWeight={500} fontSize={2} lineHeight={1}>
        <PercentageFieldValue value={value} decimalPlaces={0} />
      </Text>
    </Flex>
  );
};
