import { useMemo } from 'react';
import { values, find, filter, sortBy } from 'lodash';
import { ExchangeType, Live, SectionType } from '@deepstream/common/rfq-utils';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';
import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelHeader, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { useModalState } from '../../../ui/useModalState';
import { ExchangeTableBase } from '../../../ExchangeTable';
import { useRfqId, useLiveRfqStructureQueryKey } from '../../../useRfq';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useExchangeModalState } from '../../../useExchangeModalState';
import { RfxExchangeModal } from '../../../ExchangeModal/RfxExchangeModal';
import * as rfx from '../../../rfx';
import { SwitchToExchangeProvider } from '../../../ExchangeModal/SwitchToExchange';
import { ExchangeDefFieldValueProvider } from '../../../ExchangeDefFieldValueContext';
import { useExchangeColumns } from '../../Exchange/columns';
import { RequestHooksProvider } from '../RequestHooksProvider';
import { NewInternalDocumentModal } from './NewInternalDocumentModal';

export const InternalDocumentsPanel = ({
  exchangeId,
  navigateToExchange,
}: {
  exchangeId?: string;
  navigateToExchange: (exchangeKey: { exchangeId?: string }) => void;
}) => {
  const { t } = useTranslation('translation');
  const rfqId = useRfqId();
  const queryClient = useQueryClient();
  const newInternalDocumentModal = useModalState();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const structure = rfx.useStructure<Live>();
  const exchangeColumns = useExchangeColumns();
  const liveStructureQueryKey = useLiveRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
  });
  const exchanges = rfx.useExchanges();

  const exchangeModal = useExchangeModalState({
    exchangeId,
  });

  const internalDocumentSection = useMemo(
    () => {
      const sections = values(structure.sectionById);
      return find(sections, { type: SectionType.INTERNAL_DOCUMENT }) || null;
    },
    [structure],
  );

  const {
    sortedExchanges,
    exchangeSwitcherTargets,
  } = useMemo(
    () => {
      const internalDocumentExchanges = filter(
        exchanges,
        exchange => exchange.def.type === ExchangeType.INTERNAL_DOCUMENT,
      );

      const sortedExchanges = sortBy(
        internalDocumentExchanges,
        exchange => {
          const stageId = exchange.def.stages![0];

          return structure.stages.findIndex(stage => stage._id === stageId);
        },
      );

      return {
        sortedExchanges,
        exchangeSwitcherTargets: sortedExchanges.map(exchange => ({ exchangeId: exchange._id })),
      };
    },
    [exchanges, structure.stages],
  );

  const columns = useMemo(() => {
    return exchangeColumns.internalDocument(structure);
  }, [exchangeColumns, structure]);

  return (
    <>
      <Panel>
        <PanelHeader heading={t('request.internalDocuments.internalDocuments')} icon="file-o">
          <Button small iconLeft="plus" variant="secondary-outline" onClick={newInternalDocumentModal.open}>
            {t('request.internalDocuments.addDocument')}
          </Button>
        </PanelHeader>
        <PanelDivider />
        {sortedExchanges.length ? (
          <ExchangeTableBase
            columns={columns}
            exchanges={sortedExchanges}
            onRowClick={(exchange) =>
              navigateToExchange({
                exchangeId: exchange._id,
              })
            }
            isPaginated
          />
        ) : (
          <PanelPadding>
            <Text color="subtext" fontSize={2} mb="10px">
              {t('request.internalDocuments.noDocumentsAdded')}
            </Text>
            <Button small iconLeft="plus" onClick={newInternalDocumentModal.open}>
              {t('request.internalDocuments.addDocument')}
            </Button>
          </PanelPadding>
        )}
      </Panel>
      <NewInternalDocumentModal
        rfqId={rfqId}
        isOpen={newInternalDocumentModal.isOpen}
        onCancel={newInternalDocumentModal.close}
        onSuccess={callAll(
          () => queryClient.invalidateQueries(liveStructureQueryKey),
          () => queryClient.invalidateQueries(['allExchanges', { rfqId, currentCompanyId }]),
          () => queryClient.invalidateQueries(['exchanges', { rfqId, currentCompanyId }]),
          () => queryClient.invalidateQueries(['statsByRecipientId', { rfqId, currentCompanyId }]),
          newInternalDocumentModal.close,
          navigateToExchange,
        )}
      />
      <rfx.SectionProvider section={internalDocumentSection}>
        <rfx.StructureProvider structure={structure}>
          <rfx.RecipientsProvider recipients={structure.recipients}>
            <RequestHooksProvider>
              <ExchangeDefFieldValueProvider>
                <SwitchToExchangeProvider
                  switchToExchange={(target) => {
                    if (target) {
                      exchangeModal.open({ exchangeId: target.exchangeId });
                      navigateToExchange({ exchangeId: target.exchangeId });
                    }
                  }}
                  verticalTargets={exchangeSwitcherTargets}
                >
                  <RfxExchangeModal
                    showExchangeSwitcher
                    {...exchangeModal}
                    close={() => navigateToExchange({})}
                  />
                </SwitchToExchangeProvider>
              </ExchangeDefFieldValueProvider>
            </RequestHooksProvider>
          </rfx.RecipientsProvider>
        </rfx.StructureProvider>
      </rfx.SectionProvider>
    </>
  );
};
