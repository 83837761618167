import { useMemo } from 'react';
import {
  approvalResponseStatus,
  pageStatusesConfig,
  stageApprovalStatuses,
} from '@deepstream/common/rfq-utils/statusConfigs';
import { ApprovalResponseStatus } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { mapValues } from 'lodash';

export const useLabeledPageStatusesConfig = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return mapValues(
      pageStatusesConfig,
      (value, key) => ({
        ...value,
        label: t(`request.pageStatus.${key}`),
      }),
    );
  }, [t]);
};

export const useLabeledStageApprovalsStatusesConfig = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return mapValues(
      stageApprovalStatuses,
      (value, key) => ({
        ...value,
        label: t(`request.stageApprovals.status.${key}`),
      }),
    );
  }, [t]);
};

// TODO refactor all the above hooks to avoid copy paste
export const useLabeledApprovalResponseConfig = (options?: {
  pendingStatusAlternative: 'required' | 'none';
}) => {
  const { t } = useTranslation('translation');
  const { pendingStatusAlternative } = options || {};

  return useMemo(() => {
    const alternativePendingConfigs = {
      required: {
        status: ApprovalResponseStatus.PENDING,
        label: t('request.stageApprovals.response.pending_alternative'),
        icon: {
          color: 'danger',
          value: 'exclamation-circle',
        },
      },
      none: {
        status: ApprovalResponseStatus.PENDING,
        label: t('request.stageApprovals.response.none'),
        icon: {
          color: 'secondary',
          value: 'circle',
          isRegular: true,
        },
      },
    };

    return mapValues(approvalResponseStatus, (value, key) => ({
      ...value,
      label: t(`request.stageApprovals.response.${key}`),
      ...(key === 'pending' && pendingStatusAlternative && alternativePendingConfigs[pendingStatusAlternative]),
    }));
  }, [pendingStatusAlternative, t]);
};
