import { reject, includes, filter, first, last, find, map, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Text, Box, Flex } from 'rebass/styled-components';
import { isAfter } from 'date-fns';
import { LockType } from '@deepstream/common/rfq-utils';

import { DateFormat, localeFormatDate } from '@deepstream/utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import * as rfx from '../../../rfx';
import { useRfqId } from '../../../useRfq';
import { useModalState } from '../../../ui/useModalState';
import { useCurrentUserLocale } from '../../../useCurrentUser';
import { UnlockBidsModal } from '../Sent/UnlockBidsModal';
import { useStatsByRecipientId } from '../../../useStatsByRecipientId';

const useLockedSenders = () => {
  const senders = rfx.useSenders();
  const section = rfx.useSection();

  if (!section.locking) return [];

  return reject(
    senders,
    // @ts-expect-error ts(18048) FIXME: 'section.locking' is possibly 'undefined'.
    (sender) => includes(section.locking.bypass, sender.company._id),
  );
};

const useUnlockSenders = () => {
  const senders = rfx.useSenders();
  const section = rfx.useSection();

  if (!section.locking) return [];

  return filter(
    senders,
    // @ts-expect-error ts(18048) FIXME: 'section.locking' is possibly 'undefined'.
    (sender) => includes(section.locking.keys, sender.company._id),
  );
};

const UnlockDeadline = () => {
  const { t } = useTranslation('request');
  const allStages = rfx.useStages();
  const section = rfx.useSection();
  const lockType = section?.locking?.type;
  // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
  const bidDeadline = last(allStages).completionDeadline;
  const locale = useCurrentUserLocale();
  // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
  const stageCompletionDeadline = find(
    allStages,
    (stage) => stage._id === first(section.stages),
  ).completionDeadline;

  let translationKey;
  let endDate;

  switch (lockType) {
    case LockType.STAGE_DEADLINE:
      translationKey = 'lockTypeStageDeadline';
      endDate = stageCompletionDeadline;
      break;
    case LockType.TEAM_MEMBER:
      endDate = '';
      translationKey = 'lockTypeTeamMember';
      break;
    case LockType.TEAM_MEMBER_AFTER_STAGE_DEADLINE:
      endDate = stageCompletionDeadline;
      translationKey = 'lockTypeTeamMemberAfterStageDeadline';
      break;
    case LockType.BID_DEADLINE:
      endDate = bidDeadline;
      translationKey = 'lockTypeBidDeadline';
      break;
    case LockType.TEAM_MEMBER_AFTER_BID_DEADLINE:
      endDate = bidDeadline;
      translationKey = 'lockTypeTeamMemberAfterBidDeadline';
      break;
    default:
      translationKey = '';
  }

  return (lockType && translationKey) && (
    <Text>
      {t(`vesselPricing.locked.${translationKey}`, {
        date: endDate && localeFormatDate(new Date(endDate), DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ, { locale }),
      })}
    </Text>
  );
};

const useCanUnlock = () => {
  const currentCompanyId = useCurrentCompanyId();
  const allStages = rfx.useStages();
  const section = rfx.useSection();
  // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
  const bidDeadline = last(allStages).completionDeadline;
  // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
  const stageCompletionDeadline = find(
    allStages,
    (stage) => stage._id === first(section.stages),
  ).completionDeadline;

  // @ts-expect-error ts(18048) FIXME: 'section.locking' is possibly 'undefined'.
  const canMemberUnlockAnytime = section?.locking.type === LockType.TEAM_MEMBER;
  const canMemberUnlockAfterStageDeadline =
    // @ts-expect-error ts(18048) FIXME: 'section.locking' is possibly 'undefined'.
    section?.locking.type === LockType.TEAM_MEMBER_AFTER_STAGE_DEADLINE &&
    stageCompletionDeadline &&
    isAfter(new Date(stageCompletionDeadline), new Date());
  const canMemberUnlockAfterBidDeadline =
    // @ts-expect-error ts(18048) FIXME: 'section.locking' is possibly 'undefined'.
    section?.locking.type === LockType.TEAM_MEMBER_AFTER_BID_DEADLINE &&
    bidDeadline &&
    isAfter(new Date(bidDeadline), new Date());

  const canBeUnlocked = canMemberUnlockAnytime ||
    canMemberUnlockAfterStageDeadline ||
    canMemberUnlockAfterBidDeadline;

  // @ts-expect-error ts(18048) FIXME: 'section.locking' is possibly 'undefined'.
  const canCompanyUnlock = includes(section?.locking.keys, currentCompanyId);

  return canBeUnlocked && canCompanyUnlock;
};

const CompanyUnlockButton = () => {
  const { t } = useTranslation('request');
  const unlockModal = useModalState();
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId();
  const section = rfx.useSection();
  const recipients = rfx.useRecipients();
  const canBeUnlocked = useCanUnlock();

  const { data: statsByRecipientId } = useStatsByRecipientId({
    rfqId,
    currentCompanyId,
  });

  // @ts-expect-error ts(18048) FIXME: 'section.locking' is possibly 'undefined'.
  const canCompanyUnlock = includes(section?.locking.keys, currentCompanyId);

  const hasUnlockableExchanges = recipients.some(recipient => {
    return !isEmpty(statsByRecipientId?.[recipient._id]?.unlockableExchangeIdsBySectionId);
  });

  return canBeUnlocked && canCompanyUnlock && hasUnlockableExchanges ? (
    <>
      <Box>
        <Button onClick={unlockModal.open}>
          {t('vesselPricing.locked.unlockVesselPricingLabel')}
        </Button>
      </Box>
      <UnlockBidsModal
        selectedRecipients={recipients}
        // @ts-expect-error ts(2322) FIXME: Type 'StatsByRecipientId | undefined' is not assignable to type 'StatsByRecipientId'.
        statsByRecipientId={statsByRecipientId}
        isOpen={unlockModal.isOpen}
        onCancel={unlockModal.close}
        onSave={unlockModal.close}
      />
    </>
  ) : (
    null
  );
};

export const VesselPricingLockedPanel = () => {
  const { t } = useTranslation('request');
  const lockedSenders = useLockedSenders();
  const unlockSenders = useUnlockSenders();

  return (
    <Stack gap={3}>
      <Flex alignItems="center">
        <Text>
          {t('vesselPricing.locked.lockedCompanies', {
            count: lockedSenders.length,
            companyName: lockedSenders[0].company.name,
          })}
        </Text>

        {lockedSenders.length > 1 && (
          <Tooltip
            content={(
              <Box as="ul" pl={3}>
                {map(lockedSenders, (sender) => (
                  <Box as="li" key={sender.company._id}>{sender.company.name}</Box>
                ))}
              </Box>
            )}
          >
            <Icon icon="info-circle" ml={2} />
          </Tooltip>
        )}
      </Flex>

      <UnlockDeadline />

      {!!unlockSenders.length && (
        <Flex alignItems="center">
          <Text>
            {t('vesselPricing.locked.unlockCompanies', {
              count: unlockSenders.length,
              companyName: unlockSenders[0].company.name,
            })}
          </Text>

          {unlockSenders.length > 1 && (
            <Tooltip
              content={unlockSenders.length > 1 && (
                <Box as="ul" pl={3}>
                  {map(unlockSenders, (sender) => (
                    <Box as="li" key={sender.company._id}>{sender.company.name}</Box>
                  ))}
                </Box>
              )}
            >
              <Icon icon="info-circle" ml={2} />
            </Tooltip>
          )}
        </Flex>
      )}

      <CompanyUnlockButton />
    </Stack>
  );
};
