import { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Link, Box, Text } from 'rebass/styled-components';
import { compact, filter, find, first, isEmpty, isNil, map, values } from 'lodash';
import { Form, Formik, useField, useFormikContext } from 'formik';
import * as yup from 'yup';
import { useQuery } from 'react-query';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { withProps } from '@deepstream/ui-utils/withProps';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton, Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { PropertyList, ValueOrEmDash } from '../../PropertyList';
import { Validation } from '../../draft/validation';
import { ValidationPropertyRow } from '../../draft/ValidationPropertyRow';
import { ValidationErrorValue } from '../../draft/ValidationErrorValue';
import { nestValues } from '../../nestValues';
import { useContractData, useContractActions, useContractState, useIsSender, useIsLinkedToRequest } from './contract';
import { useShowValidationErrors, useUpdateContractCounterParty } from './draftContract';
import { ContractSummaryPanelHeader } from './ContractSummaryPanelHeader';
import { FieldContainer } from '../../form/FieldContainer';
import { SwitchField } from '../../form/SwitchField';
import { SelectField } from '../../form/SelectField';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useApi, wrap } from '../../api';
import { AwardedRequestOverview } from '../../types';
import { CompanyFinderForm } from '../../CompanyFinderForm';
import { useConfirmDialog, useModalState } from '../../ui/useModalState';
import { SupplierModalForm } from '../RequestSuppliers/SuppliersPanel';
import { useFetchSupplierCompany } from '../RequestSuppliers/SuppliersContext';
import { CompanyProfile, SupplierStateUser } from '../RequestSuppliers/types';
import { CompanySelectField } from './CompanySelectField';
import { CompanyLogo } from '../../CompanyLogo';
import { LabelConfig, LabelConfigProvider } from '../../LabelConfigProvider';
import { SelectedUsersAvatarList } from '../../SelectedUsersAvatarList';
import { LeavePageModal } from '../../draft/LeavePageModal';
import { legacyRequestReceivedIndexRoute, requestSentSuppliersViewRoute } from '../../AppRouting';
import { useNavigate } from '../../tanstackRouter';

const panelId = 'counterparty';

const verticallyCenteredLabelStyle = {
  fontSize: 2,
  position: 'relative',
  top: '10px',
};

const RequestSwitchField = ({
  setAwardedRequest,
}: {
  setAwardedRequest: (awardedRequest: AwardedRequestOverview) => void;
}) => {
  const { t } = useTranslation(['contracts', 'general']);
  const [recipientField] = useField('recipient');
  const [enabledField] = useField('awardedRequestEnabled');
  const [,, requestIdFormik] = useField('awardedRequestId');
  const contract = useContractData();

  const hasSelectedRecipient = Boolean(recipientField.value?._id);

  let tooltipContent;

  if (contract.isLegacy) {
    tooltipContent = t('disabledForLegacyContracts');
  } else if (hasSelectedRecipient) {
    if (enabledField.value) {
      tooltipContent = t('removeSupplierBeforeDisabling');
    } else {
      tooltipContent = t('removeSupplierBeforeEnabling');
    }
  }

  const onChange = useCallback(
    (checked) => {
      // Reset the `awardedRequestId` field and the `selectedRequest` state when the switch is disabled
      if (!checked) {
        requestIdFormik.setValue(null);
        // @ts-expect-error ts(2345) FIXME: Argument of type 'null' is not assignable to parameter of type 'AwardedRequestOverview'.
        setAwardedRequest(null);
      }
    },
    [requestIdFormik, setAwardedRequest],
  );

  return (
    <SwitchField
      name="awardedRequestEnabled"
      switchTooltip={tooltipContent}
      label={t('linkToAwardedRequest')}
      disabled={hasSelectedRecipient || contract.isLegacy}
      useDefaultLabelConfig={false}
      onChange={onChange}
    />
  );
};

const RequestSelectField = ({
  selectedRequest,
  setAwardedRequest,
}: {
  selectedRequest: AwardedRequestOverview | null;
  setAwardedRequest: (awardedRequest: AwardedRequestOverview) => void,
}) => {
  const { t } = useTranslation('contracts');
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const [enabledField] = useField('awardedRequestEnabled');
  const [requestIdField,, requestIdFormik] = useField('awardedRequestId');
  const [recipientField] = useField('recipient');

  const { data: awardedRequests = [] } = useQuery(
    ['awardedRequestsSent', { companyId: currentCompanyId }],
    wrap(api.getAwardedRequestsSent),
    {
      enabled: enabledField.value,
    },
  );

  useEffect(
    () => {
      if (!isEmpty(awardedRequests) && requestIdField.value && !selectedRequest) {
        const awardedRequest = find(awardedRequests, { _id: requestIdField.value });

        // @ts-expect-error ts(2345) FIXME: Argument of type 'AwardedRequestOverview | undefined' is not assignable to parameter of type 'AwardedRequestOverview'.
        setAwardedRequest(awardedRequest);
      }
    },
    [awardedRequests, requestIdField.value, selectedRequest, setAwardedRequest],
  );

  const onSelectRequest = useCallback(
    (requestId) => {
      const awardedRequest = find(awardedRequests, { _id: requestId });

      // @ts-expect-error ts(2345) FIXME: Argument of type 'AwardedRequestOverview | undefined' is not assignable to parameter of type 'AwardedRequestOverview'.
      setAwardedRequest(awardedRequest);
      requestIdFormik.setValue(requestId);
    },
    [awardedRequests, setAwardedRequest, requestIdFormik],
  );

  const hasSelectedRecipient = Boolean(recipientField.value?._id);

  return (
    <SelectField
      name="awardedRequestId"
      required
      label={t('request')}
      placeholder={t('selectRequest')}
      items={awardedRequests.map(request => ({
        label: request.subject,
        value: request._id,
      }))}
      disabled={!awardedRequests.length || !enabledField.value || hasSelectedRecipient}
      maxHeight="140px"
      onChange={onSelectRequest}
    />
  );
};

const RecipientSelect = ({
  selectedRequest,
}: {
  selectedRequest: AwardedRequestOverview,
}) => {
  const { t } = useTranslation(['contracts', 'general']);
  const [enabledField] = useField('awardedRequestEnabled');
  const [recipientField,, recipientFormik] = useField('recipient');
  const [counter, setCounter] = useState(0);
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const items = useMemo(
    () => selectedRequest
      ? selectedRequest.awardedRecipients.map(recipient => ({
        label: recipient.name,
        value: recipient._id,
        address: recipient.address,
      }))
      : [],
    [selectedRequest],
  );

  // Adjust the `recipient` field to match what `CompanyFinderForm` expects (see comment below)
  useWatchValue(
    enabledField.value,
    (next, previous) => {
      if (previous && !next) {
        recipientFormik.setValue(null);
      }
    },
  );

  // Ugly hack - because SelectField needs the `recipient._id` field (string) and `CompanyFinderForm`
  // needs the `recipient` field (company object), we need to make make some adjustments when toggling
  // the "Awarded request" switch. These adjustments are not synchronous so the constant below reflects
  // the intermediate state while changing from the `recipient` field from `{ _id: null }` to `null`.
  // We need to keep rendering SelectField until the adjustments are done.
  const hasEmptyRecipient = recipientField.value && isNil(recipientField.value._id);

  // Reset select field when the recipient was reset
  useWatchValue(
    recipientField.value,
    (next, previous) => {
      if (previous && !next) {
        setCounter(counter + 1);
      }
    },
  );

  const disabledCompanies = useMemo(
    () => [{ _id: currentCompanyId }],
    [currentCompanyId],
  );

  return (enabledField.value || hasEmptyRecipient) ? (
    <CompanySelectField
      key={counter}
      name="recipient._id"
      hideLabel
      placeholder={t('selectAwardedSupplier')}
      items={items}
      disabled={!selectedRequest}
    />
  ) : (
    <CompanyFinderForm
      name="recipient"
      hideLabel
      required
      disabledCompanies={disabledCompanies}
    />
  );
};

const RecipientUsersField = () => {
  const { t } = useTranslation(['contracts', 'general', 'translation']);
  const [company, setCompany] = useState<CompanyProfile>();
  const [users, setUsers] = useState<SupplierStateUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { initialValues } = useFormikContext<{ awardedRequestId: string | null }>();
  const [usersField,, usersFormik] = useField('users');
  const [recipientField,, recipientFormik] = useField('recipient');
  const selectUsersModal = useModalState();
  const fetchSupplierCompany = useFetchSupplierCompany();

  useWatchValue(
    recipientField.value,
    async (next, previous) => {
      // Reset users when the recipient company is changed
      if (previous?._id !== !next?._id) {
        usersFormik.setValue([]);

        if (next?._id) {
          await fetchSupplier(next._id);

          selectUsersModal.open();
        }
      }
    },
  );

  const fetchSupplier = useCallback(
    async (recipientId: string) => {
      setIsLoading(true);

      try {
        const { company, users } = await fetchSupplierCompany({ companyId: recipientId, role: 'receiveContracts' });
        const selectedUserIds = map(usersField.value, user => user._id);

        const supplierUsers: SupplierStateUser[] = map(
          users,
          user => ({
            ...user,
            selected: selectedUserIds.includes(user._id),
          }),
        );

        setCompany(company);
        setUsers(supplierUsers);

        setIsLoading(false);
      } catch (error) {} // eslint-disable-line no-empty

      setIsLoading(false);
    },
    [setIsLoading, usersField.value, fetchSupplierCompany],
  );

  useEffect(
    () => {
      if (recipientField.value?._id) {
        fetchSupplier(recipientField.value._id);
      }
    },
    [recipientField.value, usersField.value, fetchSupplier],
  );

  return (
    <>
      <FieldContainer
        name="users"
        label={t('user_other', { ns: 'general' })}
        width="100%"
        showAsterisk
      >
        <Flex justifyContent="space-between">
          <SelectedUsersAvatarList users={usersField.value} withValidation={false} />
          {recipientField.value?._id && (
            <Button
              type="button"
              variant="secondary"
              onClick={selectUsersModal.open}
              disabled={isLoading}
            >
              {t('editUsers')}
            </Button>
          )}
        </Flex>
      </FieldContainer>
      {selectUsersModal.isOpen && (
        <SupplierModalForm
          company={company}
          users={users}
          isOpen
          heading={t('supplierUsersModal.supplier', { ns: 'translation' })}
          noEmptySelectionMessage={t('supplierUsersModal.validation.contract.noEmptySelection', { ns: 'translation' })}
          noNonInvitedUsersMessage={t('supplierUsersModal.validation.contract.noNonInvitedUsers', { ns: 'translation' })}
          onCancel={() => {
            selectUsersModal.close();

            // Reset recipient when the modal is closed without selecting any users
            if (isEmpty(usersField.value) && !initialValues.awardedRequestId) {
              recipientFormik.setValue(null);
            }
          }}
          onSubmit={(values) => {
            const selectedUsers = filter(values.users, user => user.selected);

            usersFormik.setValue(selectedUsers);
            selectUsersModal.close();
          }}
        />
      )}
    </>
  );
};

const UsersAvatarListValue = ({ value }) => (
  <SelectedUsersAvatarList users={value} withValidation={false} />
);

const RequestLink = ({ requestId, requestSubject }: { requestId: string; requestSubject: string }) => {
  const isSender = useIsSender();
  const navigate = useNavigate();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  return requestId ? (
    <Link
      href="#"
      onClick={(ev) => {
        ev.preventDefault();

        if (isSender) {
          navigate({
            to: requestSentSuppliersViewRoute.to,
            params: { currentCompanyId, rfqId: requestId },
          });
        } else {
          navigate({
            to: legacyRequestReceivedIndexRoute.to,
            params: { currentCompanyId, rfqId: requestId },
          });
        }
      }}
    >
      {requestSubject}
    </Link>
  ) : (
    <EmDash />
  );
};

const RequestLinkValue = ({ value }: { value: { requestId: string; requestSubject: string } }) => {
  const { requestId, requestSubject } = value;

  return <RequestLink requestId={requestId} requestSubject={requestSubject} />;
};

const YesNoValue = ({ value }: { value: boolean }) => {
  const { t } = useTranslation('general');

  return value ? t('yes') : t('no');
};

const ConfirmLinkModal = ({
  selectedRequest,
  recipientId,
  ...props
}: {
  selectedRequest: AwardedRequestOverview;
  recipientId: string;
  isOpen: boolean;
  onOk: () => void;
  onCancel?: () => void;
}) => {
  const { t } = useTranslation(['contracts', 'general']);

  const recipient = selectedRequest.awardedRecipients.find(
    awardedRecipient => awardedRecipient._id === recipientId,
  );

  return (
    <Dialog
      body={
        <Stack gap={3}>
          <MessageBlock variant="warn" mt={0}>
            {t('summary.linkConfirmationWarning')}
          </MessageBlock>
          <Box>
            <Text mb={1}>
              {t('summary.linkedRequest')}:
            </Text>
            <Truncate fontWeight={500}>
              {selectedRequest.subject}
            </Truncate>
          </Box>
          <Box>
            <Text mb={1}>
              {t('summary.counterParty')}:
            </Text>
            <Flex alignItems="center">
              <Box flex="0 0 auto">
                <CompanyLogo size="xs" companyId={recipientId} />
              </Box>
              <Truncate>
                {/*
                 // @ts-expect-error ts(18048) FIXME: 'recipient' is possibly 'undefined'. */}
                {recipient.name}
              </Truncate>
            </Flex>
          </Box>
        </Stack>
      }
      style={{ content: { minWidth: '390px', maxWidth: '390px' } }}
      okButtonText={t('confirm', { ns: 'general' })}
      okButtonVariant="danger"
      cancelButtonText={t('cancel', { ns: 'general' })}
      heading={t('summary.confirmLink')}
      {...props}
    />
  );
};

export const ContractCounterPartyPanel = () => {
  const { t } = useTranslation(['contracts', 'general']);
  const { stopEditing } = useContractActions();
  // @ts-expect-error ts(2339) FIXME: Property 'editingPanelId' does not exist on type 'ContractStateContextType | undefined'.
  const { editingPanelId, isAmending, isLive, isRevising } = useContractState();
  const contract = useContractData();
  const [updateContractCounterParty] = useUpdateContractCounterParty();
  const showValidationErrors = useShowValidationErrors();
  const [selectedRequest, setSelectedRequest] = useState<AwardedRequestOverview | null>(null);
  const isSender = useIsSender();
  const { confirm, ...dialogProps } = useConfirmDialog();
  const isLinkedToRequest = useIsLinkedToRequest();

  const isEditingOtherPanel = editingPanelId && editingPanelId !== panelId;
  const isEditingThisPanel = editingPanelId && editingPanelId === panelId;
  const recipientId = first(contract.recipients)?._id;
  const senderId = first(contract.senders)?._id;

  const recipientTeam = useMemo(
    () => recipientId ? contract.teamById[recipientId] : null,
    [contract, recipientId],
  );

  const recipientUsers = useMemo(
    () => recipientTeam
      ? values(recipientTeam.users)
      : [],
    [recipientTeam],
  );

  const senderUsers = useMemo(
    () => senderId
      ? values(contract.teamById[senderId].users)
      : [],
    [contract, senderId],
  );

  const recipientName = first(contract.recipients)?.name;
  const senderName = first(contract.senders)?.name;

  const properties = useMemo(() => {
    if (contract.isLegacy && !isAmending) {
      return [
        {
          fieldName: 'counterPartyName',
          name: t('summary.counterParty'),
          value: recipientName,
          Component: ({ value }) => value,
        },
      ];
    }

    return compact([
      !contract.fromTemplateId && {
        fieldName: 'awardedRequestEnabled',
        name: t('linkedToAwardedRequest'),
        value: Boolean(contract.requestId),
        Component: YesNoValue,
      },
      contract.requestId
        ? {
          fieldName: 'awardedRequestSubject',
          name: t('awardedRequest'),
          value: {
            requestId: contract.requestId,
            requestSubject: contract.requestSubject,
          },
          Component: RequestLinkValue,
        }
        : null,
      {
        fieldName: 'counterPartyName',
        name: t('summary.counterParty'),
        value: isSender ? recipientName : senderName,
        Component: nestValues(
          withProps(ValidationErrorValue, { useShowValidationErrors }),
          ValueOrEmDash,
        ),
      },
      {
        fieldName: 'counterPartyUsers',
        name: t('user_other', { ns: 'general' }),
        value: isSender ? recipientUsers : senderUsers,
        Component: nestValues(
          withProps(ValidationErrorValue, { useShowValidationErrors }),
          UsersAvatarListValue,
        ),
      },
    ]);
  }, [contract, isAmending, t, isSender, recipientName, senderName, recipientUsers, senderUsers]);

  const initialValues = {
    awardedRequestEnabled: Boolean(contract.requestId),
    awardedRequestId: contract.requestId,
    recipient: first(contract.recipients)?.isExternal
      ? null
      : first(contract.recipients),
    users: recipientUsers,
  };
  const heading = t('summary.counterParty');

  // Counterparty can be edited when amending legacy contracts
  const canEditCounterparty = !isLive && !isRevising && (!isAmending || contract.isLegacy);

  return (
    <Panel
      as="section"
      aria-label={heading}
      sx={{
        opacity: isEditingOtherPanel ? 0.5 : 1,
        boxShadow: isEditingThisPanel ? '0 0 8px 0 rgba(0, 0, 0, 0.3)' : '',
      }}
    >
      <ContractSummaryPanelHeader
        heading={heading}
        panelId={panelId}
        // @ts-expect-error ts(2322) FIXME: Type 'boolean | undefined' is not assignable to type 'boolean'.
        canEdit={canEditCounterparty}
      />
      <PanelDivider />
      {isEditingThisPanel ? (
        <Formik
          validateOnBlur
          enableReinitialize
          initialValues={initialValues}
          validationSchema={
            yup.object().shape({
              awardedRequestEnabled: yup.boolean(),
              awardedRequestId: yup.string().nullable(),
              recipient: yup.object().nullable(),
              users: yup
                .array()
                .of(yup.object().shape({
                  _id: yup.string(),
                  name: yup.string(),
                })),
            })
          }
          onSubmit={async ({ awardedRequestId, recipient, users }) => {
            const saveChanges = () => updateContractCounterParty(
              {
                recipient,
                users,
                requestId: awardedRequestId || null,
                requestSubject: awardedRequestId ? selectedRequest?.subject : null,
              },
              {
                onSuccess: () => stopEditing(),
              },
            );

            if (!isLinkedToRequest && awardedRequestId && recipient?._id) {
              confirm(saveChanges);
            } else {
              saveChanges();
            }
          }}
        >
          {({ isSubmitting, dirty, isValid, values, resetForm }) => (
            <Form>
              <Stack gap={20} m={20}>
                <LabelConfigProvider
                  variant={LabelConfig.LEFT}
                  width="250px"
                  style={{
                    awardedRequestEnabled: { fontSize: 2 },
                    awardedRequest: { fontSize: 2 },
                    awardedRequestId: verticallyCenteredLabelStyle,
                    counterParty: isLinkedToRequest
                      ? { fontSize: 2 }
                      : verticallyCenteredLabelStyle,
                    users: verticallyCenteredLabelStyle,
                  }}
                >
                  {isLinkedToRequest ? (
                    <FieldContainer
                      name="awardedRequest"
                      label={t('awardedRequest')}
                    >
                      <RequestLink
                        // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
                        requestId={contract.requestId}
                        // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
                        requestSubject={contract.requestSubject}
                      />
                    </FieldContainer>
                  ) : contract.fromTemplateId ? (
                    null
                  ) : (
                    <>
                      <RequestSwitchField setAwardedRequest={setSelectedRequest} />
                      {values.awardedRequestEnabled && (
                        <RequestSelectField
                          selectedRequest={selectedRequest}
                          setAwardedRequest={setSelectedRequest}
                        />
                      )}
                    </>
                  )}
                  {isLinkedToRequest ? (
                    <FieldContainer
                      name="counterParty"
                      label={t('summary.counterParty')}
                    >
                      {recipientName}
                    </FieldContainer>
                  ) : (
                    <FieldContainer name="counterParty" label={t('summary.counterParty')} showAsterisk>
                      {/*
                       // @ts-expect-error ts(2322) FIXME: Type 'AwardedRequestOverview | null' is not assignable to type 'AwardedRequestOverview'. */}
                      <RecipientSelect selectedRequest={selectedRequest} />
                    </FieldContainer>
                  )}
                  <RecipientUsersField />
                </LabelConfigProvider>
              </Stack>
              <PanelDivider />
              <PanelPadding>
                <Flex justifyContent="flex-end">
                  <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
                  <SaveButton disabled={isSubmitting || !dirty || !isValid} />
                </Flex>
              </PanelPadding>
              <LeavePageModal />
              {dialogProps.isOpen && (
                <ConfirmLinkModal
                  // @ts-expect-error ts(2322) FIXME: Type 'AwardedRequestOverview | null' is not assignable to type 'AwardedRequestOverview'.
                  selectedRequest={selectedRequest}
                  // @ts-expect-error ts(18049) FIXME: 'values.recipient' is possibly 'null' or 'undefined'.
                  recipientId={values.recipient._id}
                  {...dialogProps}
                />
              )}
            </Form>
          )}
        </Formik>
      ) : (
        <Validation
          values={{
            counterPartyName: isSender ? recipientName : senderName,
            counterPartyUsers: isSender ? recipientUsers : senderUsers,
          }}
          schema={showValidationErrors ? (
            yup.object().shape({
              counterPartyName: yup.string().required(t('required', { ns: 'general' })),
              counterPartyUsers: yup.array()
                .required(t('required', { ns: 'general' }))
                .min(1, t('required', { ns: 'general' })),
            })
          ) : (
            yup.mixed()
          )}
        >
          <PropertyList
            Row={withProps(ValidationPropertyRow, { useShowValidationErrors })}
            properties={properties}
          />
        </Validation>
      )}
    </Panel>
  );
};
