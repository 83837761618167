 import { find, values, conforms, isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Live, PageRole, SectionType } from '@deepstream/common/rfq-utils';
 import { useTranslation } from 'react-i18next';
import { isCompanySuperUser } from '@deepstream/common/user-utils';
import { useQueryClient } from 'react-query';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils';
import { RequestSentSuppliersPanel } from './RequestSentSuppliersPanel';
import { LoadingPanel } from '../../../ui/Loading';
import { RfqIdProvider, useLiveRfqStructure, useRfqExchanges } from '../../../useRfq';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import * as rfx from '../../../rfx';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useInvalidateQueryOnMessage } from '../../../useInvalidateQueryOnMessage';
import { useHandleWsMessage } from '../../../WsProvider';
import { useModalState } from '../../../ui/useModalState';
import { legacyRequestSentRecipientBidIndexRoute, requestSentRecipientIndexRoute, requestSentSuppliersViewRoute } from '../../../AppRouting';
import { useRequestSentNavigation } from '../../../appNavigation';
import { useNotificationSubject } from '../../Notifications/useNotificationSubject';
import { Notification } from '../../Notifications/types';
import { StageApprovalRequestModal } from '../../StageApprovals/StageApprovalRequestModal';
import { useCurrentUser } from '../../../useCurrentUser';
import { StageApprovalsPanel } from '../../StageApprovals/StageApprovalsPanel';
import { RequestVisibility } from '../../RequestVisibility/RequestVisibility';
import { PublicBidIntentions } from '../Public/PublicBidIntentions';
import { RequestHooksProvider } from '../RequestHooksProvider';
import { useNavigate } from '../../../tanstackRouter';
import { InternalDocumentsPanel } from './InternalDocumentsPanel';
import { useCanRequestApprovalByStageId } from '../../StageApprovals/useCanRequestApproval';

const LiveStageApprovalsPanel = () => {
  const structure = rfx.useStructure<Live>();
  const canRequestApprovalByStageId = useCanRequestApprovalByStageId();
  const approvalRequestModal = useModalState();

  const availableStageIds = canRequestApprovalByStageId
    ? structure.stages
      .filter(stage => canRequestApprovalByStageId[stage._id])
      .map(stage => stage._id)
    : null;

  return (
    <>
      <StageApprovalsPanel
        openRequestApprovalModal={approvalRequestModal.open}
        canRequestStageApproval={!isEmpty(availableStageIds)}
      />
      {approvalRequestModal.isOpen && availableStageIds && availableStageIds.length > 0 && (
        <StageApprovalRequestModal
          initialStageId={availableStageIds[0]}
          availableStageIds={availableStageIds}
          close={approvalRequestModal.close}
          isOpen={approvalRequestModal.isOpen}
        />
      )}
    </>
  );
};

export const RequestSentSuppliersPageContent = ({
  rfqId,
  exchangeId,
}: {
  rfqId: string;
  exchangeId?: string;
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const navigate = useNavigate();

  const notificationsRef = useNotificationSubject({
    filter: conforms<Partial<Notification>>({
      domain: domain => domain === NotificationDomain.RFQ_SENT,
      action: action => [
        NotificationAction.RFQ_AWARDED,
        NotificationAction.RFQ_CLOSED,
        NotificationAction.BIDS_REJECTED,
        NotificationAction.BIDS_REINSTATED,
        NotificationAction.BID_SENT,
        NotificationAction.INTENTION_TO_BID,
        NotificationAction.NEW_RECIPIENT,
      ].includes(action),
      meta: meta => meta.rfqId === rfqId,
      to: to => to.companyId === currentCompanyId,
    }),
  });

  const { data: structure, status: structureStatus, queryKey: structureQueryKey } = useLiveRfqStructure({
    rfqId,
    currentCompanyId,
  });

  const {
    internalDocumentsSection,
    nonEmptyInternalSectionIds,
  } = useMemo(
    () => {
      if (!structure) {
        return {
          internalDocumentsSection: null,
          nonEmptyInternalSectionIds: [],
        };
      }

      const sections = values(structure.sectionById);

      return {
        internalDocumentsSection: find(sections, { type: SectionType.INTERNAL_DOCUMENT }),
        nonEmptyInternalSectionIds: sections
          .filter(section => {
            return (
              [SectionType.INTERNAL_DOCUMENT, SectionType.STAGE_APPROVAL].includes(section.type) &&
              !isEmpty(section.exchangeDefIds)
            );
          })
          .map(section => section._id),
      };
    },
    [structure],
  );

  const { data: exchanges = [], status: exchangesStatus, queryKey: exchangesQueryKey } = useRfqExchanges({
    sectionIds: nonEmptyInternalSectionIds,
  });

  useInvalidateQueryOnMessage(`rfx.${rfqId}`, structureQueryKey);
  useInvalidateQueryOnMessage(`rfx.${rfqId}`, exchangesQueryKey);
  useInvalidateQueryOnMessage(`rfx.${rfqId}`, ['statsByRecipientId', { rfqId, currentCompanyId }]);

  // Hack: notifications are created after the rfx updated message is sent,
  // so we're delaying the refetching.
  const delayedInvalidateNotifications = useCallback(
    () => {
      setTimeout(() => queryClient.invalidateQueries('notifications'), 500);
    },
    [queryClient],
  );

  // Refetch notifications when the rfx was updated for faster feedback
  useHandleWsMessage(`rfx.${rfqId}`, delayedInvalidateNotifications, true, 'suppliers');

  const isSenderSuperUserOrOwner = useMemo(() => {
    if (!structure) {
      return false;
    }

    const isSuperUser = isCompanySuperUser(currentUser, currentCompanyId);

    const team = structure.teamById[currentCompanyId];

    return currentUser && team && (isSuperUser || team?.owners.includes(currentUser._id));
  }, [structure, currentUser, currentCompanyId]);

  const stageApprovalSection = useMemo(() => {
    if (!structure) {
      return;
    }

    const sections = values(structure.sectionById);
    return find(sections, { type: SectionType.STAGE_APPROVAL });
  }, [structure]);

  const hasPublicBidIntentionsSection = useMemo(() => {
    return structure?.meta.hasBeenPubliclyAvailable;
  }, [structure]);

  const navigation = useRequestSentNavigation();

  return (
    <RfqIdProvider rfqId={rfqId}>
      {structureStatus === 'loading' || exchangesStatus === 'loading' ? (
        <LoadingPanel />
      ) : structureStatus === 'error' || exchangesStatus === 'error' ? (
        <ErrorPanel error={t('request.errors.getRequest')} />
      ) : structure && exchanges ? (
        /* HACK: The internal documents section is a bit magical and doesn't have a
parent page, so we must simulate permissions */
        <rfx.PageProvider
          page={{ _id: 'internal-documents-dummy-page' } as any}
        >
          <rfx.OverridesProvider
            isOwner={isSenderSuperUserOrOwner}
            pageRole={PageRole.EDITOR}
          >
            <rfx.StructureProvider structure={structure}>
              <rfx.ExchangesProvider exchanges={exchanges}>
                <RequestHooksProvider>
                  <div ref={notificationsRef}>
                    <RequestSentSuppliersPanel
                      navigateToAddSuppliers={navigation.navigateToAddSuppliers}
                      navigateToSupplier={recipient => (
                        navigate({
                          from: requestSentSuppliersViewRoute.to,
                          to: structure.newFeaturesDisabled
                            ? legacyRequestSentRecipientBidIndexRoute.to
                            : requestSentRecipientIndexRoute.to,
                          params: params => ({ ...params, recipientId: recipient._id }),
                        })
                      )}
                    />
                  </div>
                  <RequestVisibility />
                  {hasPublicBidIntentionsSection && <PublicBidIntentions />}
                  {stageApprovalSection && (
                    <rfx.StateProvider isLive={true}>
                      <rfx.SectionProvider
                        key={stageApprovalSection._id}
                        section={stageApprovalSection}
                      >
                        <LiveStageApprovalsPanel />
                      </rfx.SectionProvider>
                    </rfx.StateProvider>
                  )}
                  {internalDocumentsSection && (
                    <InternalDocumentsPanel
                      exchangeId={exchangeId}
                      navigateToExchange={({ exchangeId }) =>
                        navigation.navigateToSuppliers({ exchangeId })
                      }
                    />
                  )}
                </RequestHooksProvider>
              </rfx.ExchangesProvider>
            </rfx.StructureProvider>
          </rfx.OverridesProvider>
        </rfx.PageProvider>
      ) : null}
    </RfqIdProvider>
  );
};
