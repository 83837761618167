import { useCallback, Fragment, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Popover, usePopover } from '@deepstream/ui-kit/elements/popup/usePopover';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { DropdownMenu, DropdownMenuItem, DropdownMenuList, InlineMenu, scrollIntoView } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { immutableSet } from '@deepstream/utils';
import { swap } from '@deepstream/utils/swap';
import { isEmpty, isNumber } from 'lodash';
import { Menu } from '@reach/menu-button';
import { useDropdownContext } from '@reach/dropdown';
import { KeyCode } from '@deepstream/ui-utils/KeyCode';
import { LabeledSortCriterion, LabeledSortDirection } from '@deepstream/ui-utils';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { SortProps2 } from '../sorting';
import { Counter } from './Badge';

// Manually passing the widths is a workaround for the fact that the `DropdownMenu` component
// is internally using the `Button` component, which is internally using a parent `Text` component
// which styles are not customizable
//
// Ideally, the dropdowns should have been insider a `Flex` row, with a gap of 10px,
// rendering the first dropdown with a `flex: 1` and the second with a fixed width
const CRITERIA_DROPDOWN_WIDTH = 224;
const DIRECTION_DROPDOWN_WIDTH = 139;

const SortDropdownMenu = ({
  width,
  items,
  selected,
  onSelect,
  getItemId,
  renderItem,
}: {
  width: number;
  items: any[];
  selected: any;
  onSelect: (item: any) => void;
  getItemId: (item: any) => string;
  renderItem: (item: any) => string | JSX.Element;
}) => {
  return (
    <DropdownMenu
      buttonText={
        <Flex
          sx={{ width }}
          alignItems="center"
          justifyContent="center"
        >
          <Text
            sx={{
              width: '100%',
              textAlign: 'start',
              fontWeight: 400,
              flex: 1,
            }}
          >
            {renderItem(selected)}
          </Text>
          <Icon icon="caret-down" ml={1} flex={0} />
        </Flex>
      }
      variant="secondary-outline"
      wrapperStyle={{
        // This is required because the inner implementation of popover
        // inside the @reach/dropdown doesn't support 'sameWidth' as a prop
        // so we need to keep track of the width ourselves
        width,
      }}
      menuZIndex={20}
      stopClickEvents
    >
      {items.map((item) => (
        <Fragment key={getItemId(item)}>
          <DropdownMenuItem
            style={{
              // This is required because the inner implementation of popover
              // inside the @reach/dropdown doesn't support 'sameWidth' as a prop
              // so we need to keep track of the width ourselves
              width,
            }}
            onSelect={() => {
              onSelect(item);
            }}
          >
            {renderItem(item)}
          </DropdownMenuItem>
        </Fragment>
      ))}
    </DropdownMenu>
  );
};

export const SortDropdown = ({
  renderCriteriaItem,
  renderDirectionItem,

  criteriaItems,
  directionItems,

  selectedSortCriterion,
  selectedSortDirection,

  onSortCriteriaChange,
  onSortDirectionChange,

  disabled = false,
}: SortProps2 & {
  disabled?: boolean;
}) => {
  const { t } = useTranslation('general');
  const theme = useTheme();

  const popover = usePopover({ placement: 'bottom-end' });
  const closePopover = useCallback(() => {
    popover.close();
  }, [popover]);

  return (
    <>
      <Button
        // @ts-expect-error ts(2322) FIXME: Type 'Dispatch<SetStateAction<HTMLElement | undefined>>' is not assignable to type 'LegacyRef<HTMLButtonElement> | undefined'.
        ref={popover.setReferenceElement}
        onClick={() => {
          popover.toggle();
          popover.update?.();
        }}
        iconLeft="sort"
        small
        variant="primary-outline"
        disabled={disabled}
      >
        {t('sort')}
        {!disabled && <Counter color="primary" count={1} ml={1} />}
      </Button>
      <Popover
        {...popover}
        onClickOutside={closePopover}
        sx={{
          marginLeft: 0,
          marginRight: 0,
          marginTop: '4px',
          marginBottom: 0,
          padding: '8px 12px',
          zIndex: 10,
          backgroundColor: theme.colors.white,
        }}
      >
        <Flex
          flexDirection="row"
          alignItems="center"
          sx={{
            padding: '6px 0px',
            columnGap: '10px',
          }}
        >
          <IconText
            icon="sort"
            text={t('sort')}
            gap={2}
            fontSize={2}
            fontWeight={500}
            sx={{ flex: 1 }}
          />
          <Counter color="primary" count={1} />
        </Flex>
        <Flex flexDirection="row" py={2} sx={{ columnGap: 3 }}>
          <SortDropdownMenu
            width={CRITERIA_DROPDOWN_WIDTH}
            items={criteriaItems}
            selected={selectedSortCriterion}
            onSelect={onSortCriteriaChange}
            getItemId={(item) => item.accessor}
            renderItem={renderCriteriaItem}
          />
          <SortDropdownMenu
            width={DIRECTION_DROPDOWN_WIDTH}
            items={directionItems}
            selected={selectedSortDirection}
            onSelect={onSortDirectionChange}
            getItemId={(item) => item.direction}
            renderItem={renderDirectionItem}
          />
        </Flex>
      </Popover>
    </>
  );
};

const excludeOtherSelectedSortCriteria = (
  allCriteria: LabeledSortCriterion[],
  selectedSorting: { criterion: LabeledSortCriterion }[],
  currentCriterion: { criterion: LabeledSortCriterion },
): LabeledSortCriterion[] => {
  return allCriteria.filter(({ accessor }) => {
    if (currentCriterion?.criterion?.accessor === accessor) {
      return true;
    }

    return !selectedSorting.some(sortingItem => {
      return sortingItem?.criterion?.accessor === accessor;
    });
  });
};

const SelectSortCriterionMenuContent = ({
  criteria,
  onSelect,
  close,
}: {
  criteria,
  onSelect,
  close,
}) => {
  const dropdownContext = useDropdownContext();

  useLayoutEffect(() => {
    const container = dropdownContext.dropdownRef.current;
    container?.focus({ preventScroll: true });
  }, [dropdownContext]);

  const handleKeyDown = useCallback((event) => {
    let nextIndex;

    switch (event.code) {
      case KeyCode.ARROW_UP:
        nextIndex = dropdownContext.state.selectionIndex - 1;
        break;
      case KeyCode.ARROW_DOWN:
        nextIndex = dropdownContext.state.selectionIndex + 1;
        break;
      case KeyCode.PAGE_UP:
      case KeyCode.HOME:
        nextIndex = 0;
        break;
      case KeyCode.PAGE_DOWN:
      case KeyCode.END:
        nextIndex = dropdownContext.selectCallbacks.current
          ? dropdownContext.selectCallbacks.current.length - 1
          : null;
        break;
      case KeyCode.ESCAPE:
        close();
        break;
      case KeyCode.ENTER:
      case KeyCode.SPACE:
        onSelect(criteria[dropdownContext.state.selectionIndex]);
        break;
      default:
        break;
    }

    if (!isNumber(nextIndex)) return;

    const container = dropdownContext.dropdownRef.current;
    if (!container) return;

    const element = container.children[nextIndex];
    if (!element) return;

    scrollIntoView(element, container);
  }, [dropdownContext, close, onSelect, criteria]);

  return (
    <DropdownMenuList style={{ maxHeight: '240px' }} onKeyDown={handleKeyDown}>
      {criteria.map(criterion => (
        <DropdownMenuItem
          key={criterion.accessor}
          onSelect={() => onSelect(criterion)}
        >
          {criterion.label}
        </DropdownMenuItem>
      ))}
    </DropdownMenuList>
  );
};

export type MultiSortDropdownConfig = {
  renderCriteriaItem: (item: LabeledSortCriterion | null) => string | JSX.Element;
  renderDirectionItem: (item: LabeledSortDirection | null) => '' | JSX.Element;
  criteriaItems: LabeledSortCriterion[];
  directionItems: LabeledSortDirection[];
  selectedSorting: {
    criterion: LabeledSortCriterion;
    direction: LabeledSortDirection;
  }[];
  onSortingChange: (sorting: {
    criterion: LabeledSortCriterion;
    direction: LabeledSortDirection;
  }[]) => void;
};

export const MultiSortDropdown = ({
  renderCriteriaItem,
  renderDirectionItem,

  criteriaItems,
  directionItems,

  selectedSorting,

  onSortingChange,
  id,
}: MultiSortDropdownConfig & { id?: string }) => {
  const { t } = useTranslation('general');
  const theme = useTheme();

  const popover = usePopover({ placement: 'bottom-end' });
  const closePopover = useCallback(() => {
    popover.close();
  }, [popover]);

  const unselectedCriteria = criteriaItems
    .filter(item => !selectedSorting.some(selectedItem => selectedItem.criterion?.accessor === item.accessor));

  // the length can affect the width of the button; updating
  // popper when the length changes makes sure that the
  // popup stays aligned with the edge of the button
  useWatchValue(
    selectedSorting?.length,
    () => popover.update?.(),
  );

  return (
    <>
      <Button
        id={id}
        // @ts-expect-error ts(2322) FIXME: Type 'Dispatch<SetStateAction<HTMLElement | undefined>>' is not assignable to type 'LegacyRef<HTMLButtonElement> | undefined'.
        ref={popover.setReferenceElement}
        onClick={() => {
          popover.toggle();
          popover.update?.();
        }}
        iconLeft="sort"
        small
        variant="secondary-outline"
      >
        {t('sort')}
        <Counter color="primary" count={selectedSorting.length} ml={1} />
      </Button>
      <Popover
        {...popover}
        onClickOutside={closePopover}
        py="8px"
        sx={{
          marginLeft: 0,
          marginRight: 0,
          marginTop: '4px',
          marginBottom: 0,
          zIndex: 10,
          backgroundColor: theme.colors.white,
        }}
      >
        <Flex
          flexDirection="row"
          alignItems="center"
          sx={{
            padding: '12px 12px 6px',
            columnGap: '10px',
          }}
        >
          <IconText
            icon="sort"
            text={t('sort')}
            gap={2}
            fontSize={2}
            fontWeight={500}
            sx={{ flex: 1 }}
          />
          <Counter color="primary" count={selectedSorting.length} />
        </Flex>
        {isEmpty(selectedSorting) ? (
          <InlineMenu
            close={closePopover}
            style={{ maxHeight: '240px' }}
          >
            {unselectedCriteria.map(criterion => (
              <DropdownMenuItem
                key={criterion.accessor}
                onSelect={() => {
                  const newSorting = [
                    {
                      criterion,
                      direction: directionItems[0],
                    },
                  ];
                  onSortingChange(newSorting);
                }}
              >
                {criterion.label}
              </DropdownMenuItem>
            ))}
          </InlineMenu>
        ) : (
          <Box px="12px" pb="10px">
            {selectedSorting.map((sorting, index, items) => {
              const availableItems = excludeOtherSelectedSortCriteria(criteriaItems, selectedSorting, sorting);

              return (
                <Flex flexDirection="row" mt={2} key={index}>
                  <Box mr={2}>
                    <SortDropdownMenu
                      width={230}
                      items={availableItems}
                      selected={sorting.criterion}
                      onSelect={item => {
                        const newSorting = immutableSet(
                          selectedSorting,
                          [index, 'criterion'],
                          item,
                        );
                        onSortingChange(newSorting);
                      }}
                      getItemId={(item) => item.accessor}
                      renderItem={renderCriteriaItem}
                    />
                  </Box>
                  <Box mr={items.length > 1 ? 3 : 2}>
                    <SortDropdownMenu
                      width={DIRECTION_DROPDOWN_WIDTH}
                      items={directionItems}
                      selected={sorting.direction}
                      onSelect={item => {
                        const newSorting = immutableSet(
                          selectedSorting,
                          [index, 'direction'],
                          item,
                        );
                        onSortingChange(newSorting);
                      }}
                      getItemId={(item) => item.direction}
                      renderItem={renderDirectionItem}
                    />
                  </Box>
                  {items.length > 1 && (
                    <Button
                      iconLeft="arrow-up"
                      variant="secondary-outline"
                      disabled={index === 0}
                      onClick={() => {
                        const newSorting = swap(selectedSorting, index - 1, index);
                        onSortingChange(newSorting);
                      }}
                      mr={1}
                    />
                  )}
                  {items.length > 1 && (
                    <Button
                      iconLeft="arrow-down"
                      variant="secondary-outline"
                      disabled={index === items.length - 1}
                      onClick={() => {
                        const newSorting = swap(selectedSorting, index, index + 1);
                        onSortingChange(newSorting);
                      }}
                      mr={3}
                    />
                  )}
                  <Button
                    iconLeft="xmark"
                    variant="secondary-outline"
                    onClick={() => {
                      const newSorting = [...selectedSorting];
                      newSorting.splice(index, 1);
                      onSortingChange(newSorting);
                      if (isEmpty(newSorting)) {
                        closePopover();
                      }
                    }}
                  />
                </Flex>
              );
            })}
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ padding: '8px 0px' }}
            >
              <DropdownMenu
                iconLeft="plus"
                small
                variant="secondary-outline"
                disabled={isEmpty(unselectedCriteria)}
                buttonText={t('addAnotherSort')}
                stopClickEvents
                menuListStyle={{ maxHeight: '240px' }}
              >
                {unselectedCriteria.map(criterion => (
                  <DropdownMenuItem
                    key={criterion.accessor}
                    onSelect={() => {
                      const newSorting = [
                        ...selectedSorting,
                        {
                          criterion,
                          direction: directionItems[0],
                        },
                      ];
                      onSortingChange(newSorting);
                    }}
                  >
                    {criterion.label}
                  </DropdownMenuItem>
                ))}
              </DropdownMenu>
              <Button
                iconLeft="xmark"
                small
                variant="secondary-outline"
                onClick={() => {
                  onSortingChange([]);
                  closePopover();
                }}
              >
                {t('clearAll')}
              </Button>
            </Flex>
          </Box>
        )}
      </Popover>
    </>
  );
};
