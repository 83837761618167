import { pickBy, sum, sumBy } from 'lodash';
import { BidIntentionStatus, Live, RequestInteractivityStatus } from '@deepstream/common/rfq-utils';
import { useMemo } from 'react';
import { isBiddingOnLot } from '@deepstream/common/rfq-utils/lot';
import { BidProgress } from '@deepstream/common/bidProgress';
import * as rfx from '../../../rfx';

export const useBuyerActionRequiredCount = (stageId?: string, requirementGroupId?: string) => {
  const { lotById } = rfx.useStructure<Live>();
  const bid = rfx.useBid();
  const requestInteractivityStatus = rfx.useRequestInteractivityStatus();
  const bidIntentionStatus = rfx.useBidIntentionStatus();

  return useMemo(() => {
    if (
      requestInteractivityStatus !== RequestInteractivityStatus.LIVE ||
      bidIntentionStatus !== BidIntentionStatus.BIDDING
    ) {
      return null;
    }

    const isProgressStageEntryIncluded = stageId
      ? (_, progressStageId: string) => progressStageId === stageId && bid.activatedStageIds.includes(progressStageId)
      : (_, progressStageId: string) => bid.activatedStageIds.includes(progressStageId);

    const filteredProgress = pickBy(bid.progressByPageIdByRequirementGroupIdByStageId, isProgressStageEntryIncluded);

    return sum(
      Object.values(filteredProgress)
        .flatMap(progressByPageIdByRequirementGroupId => {
          const filteredProgressItems = pickBy(
            // @ts-expect-error ts(2769) FIXME: No overload matches this call.
            progressByPageIdByRequirementGroupId,
            (_, progressRequirementGroupId) => {
              if (requirementGroupId && progressRequirementGroupId !== requirementGroupId) {
                return false;
              }

              if (progressRequirementGroupId === 'general') {
                return true;
              }

              const lot = lotById[progressRequirementGroupId];

              return isBiddingOnLot(lot, bid.intentionStatusByLotId[progressRequirementGroupId]);
            },
          );

          const flattenedProgressItems = Object.values(filteredProgressItems)
            // @ts-expect-error ts(2769) FIXME: No overload matches this call.
            .flatMap(progressByPageId => Object.values(progressByPageId));

          // @ts-expect-error ts(2345) FIXME: Argument of type 'unknown[]' is not assignable to parameter of type 'ArrayLike<BidProgress>'.
          return sumBy(flattenedProgressItems, (progress: BidProgress) => progress.awaitingSender || 0);
        }),
    );
  }, [requestInteractivityStatus, bidIntentionStatus, stageId, bid, requirementGroupId, lotById]);
};
