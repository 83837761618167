import { useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { isEmpty, noop } from 'lodash';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { MessageBlock3 } from '@deepstream/ui-kit/elements/MessageBlock';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData } from '../types';
import { RadioField } from '../../../../form/RadioField';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { Direction } from '../../../../ui/MultiStepFlow/types';

const useOptions = () => {
  const { t } = useTranslation('translation');

  return useMemo(() => {
    return ['yes', 'no'].map(value => ({
      value,
      label: (
        <Box fontSize={4} color="text">
          {t(`request.awardFlow.steps.chooseProvideBudget.options.${value}`)}
        </Box>
      ),
    }));
  }, [t]);
};

const getSubmissionDataFromFormValues = (
  { provideBudget }: { provideBudget: string },
  data: AwardFlowData,
): Partial<AwardFlowData> | null => {
  if (provideBudget === 'yes') {
    return {
      editBudget: true,
      spendAndSavings: {
        ...data.spendAndSavings,
        hasBudgetedTotalValue: true,
      },
    };
  } else if (provideBudget === 'no') {
    return {
      editBudget: false,
      spendAndSavings: {
        ...data.spendAndSavings,
        hasBudgetedTotalValue: false,
        budgetedTotalValue: null,
      },
    };
  } else {
    return null;
  }
};

export const ChooseProvideBudgetStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (data: Partial<AwardFlowData> | null, direction: Direction) => void,
}) => {
  const { t } = useTranslation('translation');
  const options = useOptions();
  const theme = useTheme();

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      provideBudget: z.string({ message: t('request.awardFlow.errors.selectProvideBudgetedValue') }),
    });

    return toFormikValidationSchema(Schema);
  }, [t]);

  return (
    <Formik
      validateOnBlur
      initialValues={{
        provideBudget: data.editBudget ? (
          'yes'
        ) : data.editBudget === false ? (
          'no'
        ) : (
          undefined
        ),
      }}
      validationSchema={validationSchema}
      onSubmit={noop}
    >
      {({ values, submitForm, validateForm, dirty }) => (
        <Form>
          <lotPagesLayout.ContentWrapper>
            <StepNavigation
              // @ts-expect-error ts(2345) FIXME: Argument of type '{ provideBudget: string | undefined; }' is not assignable to parameter of type '{ provideBudget: string; }'.
              onBackClick={() => submitAndNavigate(dirty ? getSubmissionDataFromFormValues(values, data) : null, Direction.BACK)}
              onContinueClick={async () => {
                const errors = await validateForm();

                await submitForm();

                if (isEmpty(errors)) {
                  // @ts-expect-error ts(2345) FIXME: Argument of type '{ provideBudget: string | undefined; }' is not assignable to parameter of type '{ provideBudget: string; }'.
                  submitAndNavigate(dirty ? getSubmissionDataFromFormValues(values, data) : null, Direction.FORWARD);
                }
              }}
            >
              <FormErrors />
              <lotPagesLayout.Section
                heading={t('request.awardFlow.steps.chooseProvideBudget.heading')}
              >
                <MessageBlock3 variant="warn" mt="36px">
                  {t('request.awardFlow.steps.chooseProvideBudget.warning')}
                </MessageBlock3>
                <Box mt="36px">
                  <RadioField
                    name="provideBudget"
                    label={t('request.awardFlow.steps.chooseProvideBudget.fieldLabel')}
                    fieldLabelStyle={{
                      fontSize: theme.fontSizes[4],
                      fontWeight: 500,
                      color: theme.colors.text,
                      marginBottom: '12px',
                    }}
                    gap="2px"
                    options={options}
                    showError
                    errorMessageStyle={{ fontSize: '14px', marginTop: '16px' }}
                  />
                </Box>
              </lotPagesLayout.Section>
            </StepNavigation>
          </lotPagesLayout.ContentWrapper>
        </Form>
      )}
    </Formik>
  );
};
