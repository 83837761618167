import { useLocalStorageState } from '../../../../useLocalStorageState';

export type SavingsDisplayMode = 'percent' | 'amount';

const selectSavingsDisplayModeItems = [
  {
    value: 'percent',
    label: '%',
    icon: 'percent' as const,
  }, {
    value: 'amount',
    label: '$',
    icon: 'dollar-sign' as const,
  },
];

export const useSelectSavingsDisplayMode = ({
  storageKey,
}: {
  storageKey: string;
}) => {
  const [value, setValue] = useLocalStorageState<SavingsDisplayMode>({
    key: storageKey,
    defaultValue: 'percent',
  });

  return {
    inputs: selectSavingsDisplayModeItems,
    value,
    onChange: setValue,
  };
};
