import { useMemo } from 'react';
import { ContractStatus } from '@deepstream/common/contract';
import { compact } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Text, Box } from 'rebass/styled-components';
import { IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { DateFormat } from '@deepstream/utils';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { Datetime2 } from '../../Datetime';
import { PageHeading } from '../../page-headers/PageHeading';
import { Badge } from '../../Badge';
import {
  ContractProvider,
  ContractStateProvider,
  useContractData,
} from './contract';
import { ContractActions } from './ContractActions';
import { LabeledContractStatus } from './ContractStatus';
import { useContract } from './useContract';
import { useSystemFeatureFlags } from '../../systemFeatureFlags';
import { useLiveContractNavigation } from '../../appNavigation';
import * as Layout from '../../Layout';

const actionRequiredProps: { iconRight: IconProps['icon']; iconProps: Partial<IconProps> } = {
  iconRight: 'exclamation-circle',
  iconProps: { color: 'danger' },
};

const ContractDate = () => {
  const contract = useContractData();
  const { t } = useTranslation('contracts');

  const date = useMemo(() => {
    if (contract.status === ContractStatus.AGREED) {
      return {
        label: t('summary.startDate'),
        value: contract.calculatedStartDate,
      };
    }

    if (contract.status === ContractStatus.ACTIVE) {
      return {
        label: t('summary.expiryDate'),
        value: contract.calculatedExpiryDate,
      };
    }

    return null;
  }, [contract, t]);

  if (!date) return null;

  return (
    <Box>
      <Text fontSize={1} fontWeight={500} mb={1}>
        {date.label}
      </Text>
      <Text fontWeight={500} fontSize={2}>
        {date.value ? (
          <Datetime2 format={DateFormat.DD_MMM_YYYY} value={date.value} />
        ) : t('summary.noExpiryDate')}
      </Text>
    </Box>
  );
};

const ContractLegacyStatus = () => {
  const { t } = useTranslation('contracts');

  return (
    <Badge mr={3}>{t('legacy.legacyLabel')}</Badge>
  );
};

export const ContractLiveHeader = ({
  contractId,
  selectedTabId,
}: {
  contractId: string;
  selectedTabId: string;
}) => {
  const { t } = useTranslation(['translation']);
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { data: contract } = useContract({
    contractId,
    currentCompanyId,
    scope: 'current',
  });
  // @ts-expect-error ts(2339) FIXME: Property 'contractAuditEnabled' does not exist on type 'SystemFeatureFlags | undefined'.
  const { contractAuditEnabled } = useSystemFeatureFlags();
  const liveContractNavigation = useLiveContractNavigation();

  const hasDetailsPage = contract && contract.pages.find(page => !page.type);
  const isRecipient = contract && !contract.isLegacy && contract.recipients[0]._id === currentCompanyId;
  const isNegotiating = contract?.status === ContractStatus.NEGOTIATION;

  const contractActionRequired = isNegotiating && (
    isRecipient
      ? contract.awaitingRecipientSignature
      : contract.awaitingSenderSignature
  );

  const detailsActionRequired = isNegotiating && (
    isRecipient
      ? !contract.recipientRequirementsResolved
      : contract.recipientRequirementsResolved && !contract.allRequirementsResolved
  );

  const tabs = useMemo(
    () => contract ? compact([
      {
        id: 'summary',
        name: t('general.summary', { ns: 'translation' }),
        navigate: () => liveContractNavigation.navigateToSummary(),
      },
      hasDetailsPage && {
        id: 'details',
        name: t('general.details', { ns: 'translation' }),
        navigate: () => liveContractNavigation.navigateToDetailsIndex(),
        ...(detailsActionRequired ? actionRequiredProps : {}),
      },
      {
        id: 'contract',
        name: t('general.contract', { count: 1, ns: 'translation' }),
        navigate: () => liveContractNavigation.navigateToContract(),
        ...(contractActionRequired ? actionRequiredProps : {}),
      },
      {
        id: 'team',
        name: t('general.team', { ns: 'translation' }),
        navigate: () => liveContractNavigation.navigateToTeam(),
        withDivider: true,
      },
      !isRecipient && !contract.isLegacy && {
        id: 'reminders',
        name: t('general.reminder_other', { ns: 'translation' }),
        navigate: () => liveContractNavigation.navigateToReminders(),
      },
      contractAuditEnabled && {
        id: 'audit',
        name: t('general.audit', { ns: 'translation' }),
        navigate: () => liveContractNavigation.navigateToAudit(),
      },
    ]) : [],
    [
      contract,
      hasDetailsPage,
      contractActionRequired,
      detailsActionRequired,
      contractAuditEnabled,
      isRecipient,
      liveContractNavigation,
      t,
    ],
  );

  const isSender = contract?.senders.some(sender => sender._id === currentCompanyId);
  const showContractActions = isSender && [
    ContractStatus.NEGOTIATION,
    ContractStatus.AGREED,
    ContractStatus.ACTIVE,
    ContractStatus.EXPIRED,
    ContractStatus.TERMINATED,
  // @ts-expect-error ts(2345) FIXME: Argument of type 'ContractStatus | undefined' is not assignable to parameter of type 'ContractStatus'.
  ].includes(contract?.status);

  return (
    <Layout.PageHeader
      tabs={tabs}
      selectedTabId={selectedTabId}
      heading={() => (
        // @ts-expect-error ts(18048) FIXME: 'contract' is possibly 'undefined'.
        <PageHeading icon="file-contract" text={contract.summary.name} />
      )}
    >
      {contract && (
        <ContractProvider contract={contract}>
          <ContractStateProvider isLive>
            {contract.isLegacy && (
              <ContractLegacyStatus />
            )}
            <ContractDate />
            <LabeledContractStatus status={contract.status} />
            {showContractActions && <ContractActions />}
          </ContractStateProvider>
        </ContractProvider>
      )}
    </Layout.PageHeader>
  );
};
