import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useQueryClient } from 'react-query';
import { ExchangeStatus } from '@deepstream/common/rfq-utils';
import { usePopover } from '@deepstream/ui-kit/elements/popup/usePopover';
import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ModalFooter } from '@deepstream/ui-kit/elements/popup/Modal';
import { ConfirmationPopover } from '@deepstream/ui-kit/elements/popup/ConfirmationPopover';
import { StageApprovalExchangeSnapshot } from '../../types';
import { StageApprovalBaseModal } from './StageApprovalBaseModal';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '../../ui/Tabs';
import { useStageWithIndex } from './useStageWithIndex';
import { StageApprovalDetails } from './StageApprovalDetails';
import { useDraftRfqStructureQueryKey, useLiveRfqStructureQueryKey, useRfqId } from '../../useRfq';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useApi } from '../../api';
import { useToaster } from '../../toast';
import { useMutation } from '../../useMutation';
import { ApprovalResponsesTable } from './ApprovalResponsesTable';
import { useExchange } from '../../useExchange';
import * as rfx from '../../rfx';

export type ViewApprovalModalProps = {
  currentUserId: string;
  handleEditResponse: () => void;
  close: any;
  isOpen: boolean;
  props?: any;
};

export const ViewApprovalModal: React.FC<ViewApprovalModalProps> = ({
  currentUserId,
  handleEditResponse,
  close,
  isOpen,
  ...props
}) => {
  const { t } = useTranslation('translation');
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const { canEditApprovals } = rfx.useRfxPermissions();

  const approvalRequest = useExchange() as StageApprovalExchangeSnapshot;
  const { stageId, recipientIds, message, attachments, date } = approvalRequest.def;
  const { stage, stageIndex } = useStageWithIndex(stageId);

  const canCancelApproval = (
    canEditApprovals &&
    approvalRequest.status !== ExchangeStatus.OBSOLETE &&
    approvalRequest.approvals.some(approval => approval.status === 'pending')
  );

  const popover = usePopover({
    placement: 'left-end',
    strategy: 'fixed',
    overReferenceElement: true,
  });

  const handleCancelApproval = React.useCallback(() => {
    if (popover.update) {
      popover.update();
    }
    popover.open();
  }, [popover]);

  const rfqId = useRfqId({ required: true });
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const liveStructureQueryKey = useLiveRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
  });
  const draftStructureQueryKey = useDraftRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
  });
  const [cancelStageApproval] = useMutation(
    api.cancelStageApproval,
    {
      onError: () => toaster.error(t('error.somethingWentWrong', { ns: 'supplierDiscovery' })), // FIXME: wrong i18n namespace?
      onSuccess: () => close(),
      onSettled: callAll(
        () => queryClient.invalidateQueries(liveStructureQueryKey),
        () => queryClient.invalidateQueries(['allExchanges', { rfqId, currentCompanyId }]),
        () => queryClient.invalidateQueries(['exchanges', { rfqId, currentCompanyId }]),
        () => queryClient.invalidateQueries(['statsByRecipientId', { rfqId, currentCompanyId }]),
        () => queryClient.invalidateQueries(draftStructureQueryKey),
        () => queryClient.invalidateQueries(['rfqApprovalExchanges', { rfqId, currentCompanyId }]),
      ),
    },
  );
  return (
    <>
      <StageApprovalBaseModal
        isOpen={isOpen}
        onClose={close}
        title={t('request.stageApprovals.stageApproval', { count: 1 })}
        withHeaderDivider={false}
        height="400px"
        {...props}
      >
        <Flex
          flexDirection="column"
          height="100%"
          justifyContent="space-between"
        >
          <Tabs>
            <TabList style={{ backgroundColor: 'white', padding: '0px 16px' }}>
              <Tab>{t('details', { ns: 'general' })}</Tab>
              <Tab>{t('response', { ns: 'general' })}</Tab>
            </TabList>
            <Box sx={{ padding: '16px' }}>
              <TabPanels>
                <TabPanel>
                  <StageApprovalDetails
                    stage={stage}
                    stageIndex={stageIndex}
                    recipientIds={recipientIds}
                    message={message}
                    attachments={attachments}
                    date={date.toString()}
                  />
                </TabPanel>
                <TabPanel>
                  <ApprovalResponsesTable approvalRequest={approvalRequest} />
                </TabPanel>
              </TabPanels>
            </Box>
          </Tabs>
          {/*
           // @ts-expect-error ts(2322) FIXME: Type 'Dispatch<SetStateAction<HTMLElement | undefined>>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'. */}
          <div ref={popover.setReferenceElement}>
            <ModalFooter
              justifyContent="space-between"
              flexDirection="row-reverse"
            >
              <Button variant="secondary" onClick={close}>
                {t('close', { ns: 'general' })}
              </Button>
              {canCancelApproval && (
                <Button
                  variant="danger-outline"
                  onClick={handleCancelApproval}
                  iconLeft="xmark"
                >
                  {t('request.stageApprovals.cancelApproval')}
                </Button>
              )}
            </ModalFooter>
          </div>
        </Flex>
      </StageApprovalBaseModal>
      <ConfirmationPopover
        width="100%"
        maxWidth="369px"
        popover={popover}
        heading={t('request.stageApprovals.cancelApprovalQuestion')}
        body={
          <Text mb={2}>
            {t('request.stageApprovals.cancelApprovalDescription')}
          </Text>
        }
        okButtonText={t('request.stageApprovals.cancelApproval')}
        okButtonVariant="danger"
        cancelButtonText={t('review.collaboratorInviteModal.goBackLabel', {
          ns: 'request',
        })}
        onOk={async () => {
          await cancelStageApproval({ rfqId, currentCompanyId, approvalRequestId: approvalRequest._id });
          popover.close();
        }}
        onCancel={popover.close}
      />
    </>
  );
};
