import { Draft } from '@deepstream/common/rfq-utils';
import { useCallback, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { isEmpty } from 'lodash';
import { MultiStageLineItemsEventType } from '@deepstream/common';
import { scrollToTop } from '@deepstream/ui-utils/scrollToTop';
import { LineItemsSectionEditPanel, LineItemsSectionEditPanelRef } from '../../../../draft/LineItemsSectionEditPanel';
import { EnableMultiStageResponsesSectionErrors } from '../../../../draft/useEnableMultiStageResponsesSectionErrors';
import * as rfx from '../../../../rfx';
import * as draft from '../../../../draft/draft';
import { FormErrorsLayout } from '../../../../ui/MultiStepFlow/FormErrors';
import { useMultiStepFlowData } from '../../../../ui/MultiStepFlow/MultiStepFlowContext';
import { StepNavigationBackLink } from '../../../../ui/MultiStepFlow/StepNavigation';
import { Direction } from '../../../../ui/MultiStepFlow/types';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import {
  SetupMultiStageResponsesFlowData,
  SetupMultiStageResponsesFlowStep,
  SetupMultiStageResponsesFlowStepType,
} from '../types';
import { useTrackSetupMultiStageResponsesEvent } from '../tracking';

export const ConfigureSection = () => {
  const { t } = useTranslation('translation');

  const structure = rfx.useStructure<Draft>();
  const pages = Object.values(structure.pageById);
  const isMutationLoading = draft.useIsMutationLoading();

  const pageIdBySectionId = pages.reduce((result, currentPage) => {
    const { _id: pageId, sections } = currentPage;
    sections.forEach((sectionId) => {
      result[sectionId] = pageId;
    });
    return result;
  }, {});

  const { data, submitAndNavigate } = useMultiStepFlowData<
    SetupMultiStageResponsesFlowStepType,
    SetupMultiStageResponsesFlowData
  >();
  const { selectedSectionIds } = data;
  const { currentSectionIndex } = data.currentStep as Extract<
    SetupMultiStageResponsesFlowStep,
    { type: SetupMultiStageResponsesFlowStepType.CONFIGURE_SECTION }
  >;
  const sectionId = selectedSectionIds[currentSectionIndex];
  const section = structure.sectionById[sectionId];

  const panelRef = useRef<LineItemsSectionEditPanelRef>(null);

  const onBackClick = useCallback(
    () => submitAndNavigate(null, Direction.BACK),
    [submitAndNavigate],
  );

  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const onValidationErrorsChange = useCallback((
    validationErrors: EnableMultiStageResponsesSectionErrors,
  ) => {
    const { responsePerStageRequired, twoStagesRequired, oneLineItemRequired } = validationErrors;
    const allErrors = [];
    if (responsePerStageRequired) {
      allErrors.push(t('request.setupMultiStageResponsesFlow.steps.configureSection.errors.responsePerStageRequiredFull'));
    }
    if (twoStagesRequired) {
      allErrors.push(t('request.setupMultiStageResponsesFlow.steps.configureSection.errors.twoStagesRequiredFull'));
    }
    if (oneLineItemRequired) {
      allErrors.push(t('request.setupMultiStageResponsesFlow.steps.configureSection.errors.oneLineItemRequiredFull'));
    }

    setErrorMessages(allErrors);
  }, [t]);

  const trackFlowEvent = useTrackSetupMultiStageResponsesEvent({
    eventType: MultiStageLineItemsEventType.CONFIGURE_SECTION_STEP_COMPLETED,
  });

  const [showValidationErrors, setShowValidationErrors] = useState<boolean>();
  const onContinueClick = useCallback(async () => {
    setShowValidationErrors(false);
    await panelRef?.current?.formRef?.current?.submitForm();
    setShowValidationErrors(true);

    if (isEmpty(errorMessages)) {
      await trackFlowEvent();
      submitAndNavigate(null, Direction.FORWARD);
    } else {
      scrollToTop('smooth');
    }
  }, [trackFlowEvent, panelRef, errorMessages, submitAndNavigate]);

  return (
    <lotPagesLayout.ContentWrapper2 maxWidth="initial">
      <StepNavigationBackLink onClick={onBackClick} />
      {showValidationErrors && <FormErrorsLayout errors={errorMessages} />}
      <Box maxWidth={lotPagesLayout.DEFAULT_SECTION_WIDTH}>
        <lotPagesLayout.Section2
          heading={t('request.setupMultiStageResponsesFlow.steps.configureSection.heading')}
        >
          <Text>
            {t(
              'request.setupMultiStageResponsesFlow.steps.configureSection.headingSubtext',
              {
                sectionIndex: currentSectionIndex + 1,
                sectionsCount: selectedSectionIds.length,
              },
            )}
          </Text>
          <lotPagesLayout.Subsection2
            heading={t('guidelines', { ns: 'general' })}
            mt="16px"
          >
            <Text>
              {t('request.setupMultiStageResponsesFlow.steps.configureSection.guidelines.description')}
            </Text>
            <lotPagesLayout.Ol style={{ marginTop: '12px' }}>
              <Trans
                i18nKey="request.setupMultiStageResponsesFlow.steps.configureSection.guidelines.body"
                ns="translation"
                components={{
                  li: <li />,
                  b: <b />,
                }}
              />
            </lotPagesLayout.Ol>
          </lotPagesLayout.Subsection2>
        </lotPagesLayout.Section2>
      </Box>
      <rfx.PageProvider
        page={structure.pageById[pageIdBySectionId[sectionId]]}
      >
        <rfx.SectionProvider section={section}>
          <LineItemsSectionEditPanel
            isEnableMultiStageResponsesFlow
            onEnableMultiStageResponsesErrorsChange={onValidationErrorsChange}
            panelRef={panelRef}
          />
          <Box>
            <Button onClick={onContinueClick} disabled={isMutationLoading}>
              {t('toolbar.saveAndContinue', { ns: 'request' })}
            </Button>
          </Box>
        </rfx.SectionProvider>
      </rfx.PageProvider>
    </lotPagesLayout.ContentWrapper2>
  );
};
