import { useEffect } from 'react';
import { useCurrentUser } from './useCurrentUser';
import { useEnv } from './env';
import { useCurrentCompanyId } from './currentCompanyId';

const FULLVIEW_SCRIPT_ID = 'fullview';

export const FullviewManager = () => {
  const { ENV, FULLVIEW_ORGANIZATION_ID } = useEnv();
  const user = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId();
  const companyRole = user.companyRoles.find(companyRole => companyRole._id === currentCompanyId);

  // Effect to append the fullview script when it's enabled for the environment
  useEffect(
    () => {
      // Bail out if there is no fullview org id for the environment
      if (!FULLVIEW_ORGANIZATION_ID) {
        return;
      }

      // Bail out if the script has already been appended
      if (document.getElementById(FULLVIEW_SCRIPT_ID)) {
        return;
      }

      const headScript = document.createElement('script');
      headScript.setAttribute('id', FULLVIEW_SCRIPT_ID);
      headScript.setAttribute('async', '');
      headScript.setAttribute('src', 'https://install.fullview.io');
      headScript.setAttribute('data-org', FULLVIEW_ORGANIZATION_ID);
      document.head.appendChild(headScript);
    },
    [FULLVIEW_ORGANIZATION_ID],
  );

  // Set the Fullview identity
  useEffect(
    () => {
      (window as any).$fvIdentity = {
        id: user._id,
        name: user.name,
        email: user.email,
        env: ENV,
        // @ts-expect-error ts(18048) FIXME: 'companyRole' is possibly 'undefined'.
        disableReplaysForUser: !companyRole.subscription,
        roles: [
          // @ts-expect-error ts(18048) FIXME: 'companyRole' is possibly 'undefined'.
          companyRole.roleRestrictionProfile, // eg: 'superUser'
          // @ts-expect-error ts(18048) FIXME: 'companyRole' is possibly 'undefined'.
          companyRole.group, // eg: 'supplier'
        ],
      };
    },
    [user, companyRole, ENV],
  );

  return null;
};
