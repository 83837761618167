import { keys, cloneDeep, sortBy } from 'lodash';
import * as React from 'react';
import { useField } from 'formik';
import { Box } from 'rebass/styled-components';
import { DropdownQuestionElement, QuestionElementOption } from '@deepstream/common/legacy-pre-q-utils';
import { useTranslation } from 'react-i18next';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { Select } from '../ui/Input';
import { Modify, ElementProps, ContainerLabel, LockedAnswer, NoAnswer } from './common';
import { useCountryOptions } from '../ui/countries';
import { useCurrencies } from '../ui/currencies';
import { ErrorMessage } from '../form/Field';

const EmptyOption: React.FC<{ visible?: boolean; label?: string }> = ({ visible, label }) => (
  <option value="" disabled={!visible} style={{ display: visible ? 'block' : 'none' }}>
    {label}
  </option>
);

type EditableProps = {
  id: string;
  name: string;
  element: any;
  disabled?: boolean;
  error?: any;
};

const Editable: React.FC<EditableProps> = ({ id, name, element, disabled, error }) => {
  const [field, meta] = useField({ name });

  const errorText = error || meta.error;

  return (
    <>
      <Select
        id={id}
        name={name}
        value={field.value || ''}
        onChange={field.onChange}
        onBlur={field.onBlur}
        disabled={disabled}
        color={field.value ? undefined : 'subtext'}
        sx={element.subtype === 'currency' ? { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } : {}}
      >
        <EmptyOption visible={false} label={element.placeholder} />
        {element.options!.map((option: any) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      {errorText && meta.touched ? (
        <ErrorMessage error={meta.error} fontWeight="normal" />
      ) : (
        null
      )}
    </>
  );
};

export const SelectElement: React.FC<Modify<ElementProps, { element: DropdownQuestionElement }>> = ({
  name,
  element: originalElement,
  answer,
  hideLabel,
  error,
  isReadOnly,
  isLocked,
  disabled,
}) => {
  const { t } = useTranslation('companyProfile');
  const id = useUniqueId();
  const countryOptions = useCountryOptions();
  const currencies = useCurrencies();

  const element = React.useMemo(
    () => {
      if (originalElement.subtype === 'country') {
        const clonedElement = cloneDeep(originalElement);
        const options = countryOptions.map((countryOption) => ({
          ...countryOption,
          key: countryOption.value,
        }));
        clonedElement.options = sortBy(options, 'label');

        return clonedElement;
      } else if (originalElement.subtype === 'currency') {
        const clonedElement = cloneDeep(originalElement);
        const options = keys(currencies).map((currencyCode: string) => ({
          key: currencyCode,
          value: currencyCode,
          label: `${currencyCode} – ${currencies[currencyCode].name}`,
        }));
        clonedElement.options = options;

        return clonedElement;
      } else if (originalElement.subtype === 'locationType') {
        const clonedElement = cloneDeep(originalElement);
        const options = ['manufacturingCenter', 'supportOffice'].map(value => ({
          value,
          key: value,
          label: t(`element.dropdown.option.${value}`),
        }));
        clonedElement.options = options as any[];

        return clonedElement;
      } else {
        throw new Error(`Invalid dropdown subtype ${originalElement.subtype}`);
      }
    },
    [originalElement, countryOptions, currencies, t],
  );

  const selectedOption = (element?.options as QuestionElementOption[] | undefined)?.find(option => option.value === answer);

  return (
    <ContainerLabel
      htmlFor={id}
      label={element.label || (element.labelId ? t(`element.label.${element.labelId}`) : '')}
      hideLabel={hideLabel}
      showAsterisk={element.validation?.required}
    >
      {isReadOnly ? (
        answer ? (
          isLocked ? (
            <LockedAnswer />
          ) : (
            <Box>{selectedOption?.label ?? answer}</Box>
          )
        ) : (
          <NoAnswer />
        )
      ) : (
        <Editable id={id} name={name} element={element} disabled={disabled} error={error} />
      )}
    </ContainerLabel>
  );
};
