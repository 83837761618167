import { useField } from 'formik';
import { Box } from 'rebass/styled-components';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { ErrorMessage, HelperText } from './Field';
import { FieldContainer } from './FieldContainer';
import { CheckboxList, CheckboxListProps } from '../CheckboxList';

export type CheckboxListFieldBaseProps = CheckboxListProps & {
  disabled?: boolean;
  error?: string;
  helperText?: string;
  hideLabel?: boolean;
  hideError?: boolean;
  label?: string;
  name?: string;
  description?: string;
  required?: boolean;
};

export const CheckboxListFieldBase = ({
  error,
  hideError,
  helperText,
  hideLabel,
  label,
  name,
  description,
  required,
  ...props
}: CheckboxListFieldBaseProps) => {
  const id = useUniqueId();

  return (
    <FieldContainer
      name={name}
      description={description}
      htmlFor={id}
      label={label}
      hideLabel={hideLabel}
      showAsterisk={required}
    >
      <CheckboxList
        key={id}
        {...props}
      />
      {error && !hideError ? (
        <Box sx={{ wordBreak: 'break-all', textAlign: 'left' }}>
          <ErrorMessage error={error} fontWeight="normal" />
        </Box>
      ) : helperText ? (
        <HelperText text={helperText} />
      ) : (
        null
      )}
    </FieldContainer>
  );
};

export interface CheckboxListFieldProps extends Omit<CheckboxListFieldBaseProps, 'onChange' | 'value'> {
  name?: string;
  fieldName?: string;
  onChange?: any;
  alwaysShowError?: boolean;
}

/**
 * Formik-aware checkbox list field component.
 *
 * If you pass a `fieldName` without a `name`, changing the value
 * won't update the corresponding value (this is useful for displaying
 * read-only derived values)
 */
export const CheckboxListField = ({
  name,
  fieldName,
  items,
  alwaysShowError,
  ...props
}: CheckboxListFieldProps) => {
  const resolvedFieldName = fieldName || name;

  if (!fieldName && !name) {
    throw new Error('`fieldName` is required if no `name` prop included');
  }

  const [field, meta, formik] = useField({ name: resolvedFieldName! });

  return (
    <CheckboxListFieldBase
      error={(meta.touched || alwaysShowError) && meta.error ? meta.error : undefined}
      name={name}
      onChange={value => { formik.setTouched(true); formik.setValue(value); }}
      onBlur={() => { formik.setTouched(true); }}
      value={field.value}
      items={items}
      {...props}
    />
  );
};
