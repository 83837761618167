import { DataCellProps } from '../core/utils';
import { EditableGridColumn } from './utils';

export const subHeaderOrItemCell = (
  SubHeaderCell: (props: DataCellProps<EditableGridColumn<unknown>, any, any>) => React.ReactElement | null,
  ItemCell: (props: DataCellProps<EditableGridColumn<unknown>, any, any>) => React.ReactElement | null,
) => {
  return (props: DataCellProps<EditableGridColumn<unknown>, any, any>) => {
    return props.row.original.isSubHeader ? (
      <SubHeaderCell {...props} />
    ) : (
      <ItemCell {...props} />
    );
  };
};
