import { useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';

import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useRfxValidation } from './validation';

export const RequestValidationErrorMessages = () => {
  const { t } = useTranslation('request');

  const { errorMessages } = useRfxValidation();

  if (errorMessages.length === 0) {
    return null;
  }

  return (
    <MessageBlock variant="error" mt={0}>
      <Text fontSize={4} fontWeight={500}>
        {t('validation.heading')}
      </Text>
      <Box as="ul" pl="20px" mt={1}>
        {errorMessages.map((error, i) => (
          <Box as="li" key={i}>
            {error}
          </Box>
        ))}
      </Box>
    </MessageBlock>
  );
};
