import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Layout from '../../Layout';
import { useSupplierListData } from './SupplierListProvider';
import { useSupplierListNavigation } from '../../appNavigation';

export const SupplierListHeader = () => {
  const { t } = useTranslation();
  const supplierList = useSupplierListData();
  const { getExternalNetworkLinkProps, getSupplierListsLinkProps } = useSupplierListNavigation();

  const breadcrumbs = useMemo(
    () => [{
      title: t('general.network'),
      linkProps: getExternalNetworkLinkProps(),
    }, {
      title: t('supplierLists.list', { count: 2 }),
      linkProps: getSupplierListsLinkProps(),
    }],
    [t, getExternalNetworkLinkProps, getSupplierListsLinkProps],
  );

  return (
    <Layout.PageHeaderWithBreadcrumbs
      heading={supplierList.name}
      breadcrumbs={breadcrumbs}
    />
  );
};
