export enum States {
  AWARDED_SUPPLIERS = 'awardedSuppliers',
  UNSUCCESSFUL_SUPPLIERS = 'unsuccessfulSuppliers',
  SUPPLIERS = 'suppliers',
  REVIEW = 'review',
  TOGGLE_SPEND_AND_SAVINGS = 'toggleSpendAndSavings',
  CONFIRM_BUDGET = 'confirmBudget',
  CONFIRM_VALUE = 'confirmValue',
  CONFIRM_SAVINGS = 'confirmSavings',
  PROCESSING = 'processing',
  SUCCESS = 'success',
  FAILURE = 'failure',
}
