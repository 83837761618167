import { Form, Formik } from 'formik';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { createRef, useMemo } from 'react';
import { isEmpty, noop } from 'lodash';
import { Box, Flex, FlexProps } from 'rebass/styled-components';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ListConfigFlowData, ListConfigFlowStepType } from '../types';
import * as layout from '../../../Request/Live/lotPagesLayout';
import { StepNavigation2 } from '../../../../ui/MultiStepFlow/StepNavigation';
import { useCurrentCompanyId } from '../../../../currentCompanyId';
import { SupplierSearchBox } from '../../../RequestSuppliers/SupplierSearchBox';
import { CompanyLogoAndName } from '../../../../CompanyLogo';
import { useMultiStepFlowData } from '../../../../ui/MultiStepFlow/MultiStepFlowContext';

const Row = (props: FlexProps) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      height="52px"
      py="12px"
      sx={{ borderBottom: 'secondary' }}
      {...props}
    />
  );
};

export const AddSuppliersStep = () => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const supplierSearchRef = createRef<HTMLInputElement>();
  const { data } = useMultiStepFlowData<ListConfigFlowStepType, ListConfigFlowData>();

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      suppliers: z.array(z.object({
        _id: z.string(),
        name: z.string(),
      })),
    });

    return toFormikValidationSchema(Schema);
  }, []);

  return (
    <Formik
      validateOnBlur
      initialValues={{
        suppliers: data.suppliers,
      }}
      validationSchema={validationSchema}
      onSubmit={noop}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <layout.ContentWrapper2>
            <StepNavigation2<ListConfigFlowStepType, ListConfigFlowData>
              showBackButton
              showContinueButton
            >
              <layout.Section2
                heading={data._id
                  ? t('supplierLists.configFlow.adjustSuppliers')
                  : t('supplierLists.configFlow.addSuppliersToList')}
              >
                <layout.Subsection2 heading={t('supplierLists.configFlow.searchForSuppliers')}>
                  <Stack gap="20px">
                    <Box width="400px">
                      <SupplierSearchBox
                        ref={supplierSearchRef}
                        companyIdsToExclude={[currentCompanyId]}
                        companyIdsAlreadySelected={values.suppliers.map((supplier) => supplier._id)}
                        placeholder={t('supplierLists.searchBySupplierName')}
                        onResultClick={(company) => {
                          setFieldValue(
                            'suppliers',
                            [
                              ...values.suppliers,
                              {
                                _id: company.value._id,
                                name: company.value.name,
                              },
                            ],
                          );

                          supplierSearchRef.current?.focus();
                        }}
                      />
                    </Box>
                    <layout.InfoText2>
                      {t('supplierLists.configFlow.addSuppliersInfo')}
                    </layout.InfoText2>
                  </Stack>
                </layout.Subsection2>
              </layout.Section2>
              {!isEmpty(values.suppliers) ? (
                <Box>
                  <Row>
                    <layout.H3>
                      {t('supplierLists.configFlow.supplierAddedCount', { count: values.suppliers.length })}
                    </layout.H3>
                    <Button small type="button" iconLeft="close" variant="secondary" onClick={() => setFieldValue('suppliers', [])}>
                      {t('supplierLists.configFlow.removeAllSuppliers')}
                    </Button>
                  </Row>
                  {values.suppliers.map((supplier) => (
                    <Row key={supplier._id}>
                      <CompanyLogoAndName size="xs" company={supplier} />
                      <Button
                        small
                        type="button"
                        iconLeft="close"
                        variant="secondary"
                        onClick={() => setFieldValue('suppliers', values.suppliers.filter(({ _id }) => _id !== supplier._id))}
                      >
                        {t('supplierLists.configFlow.removeSupplier')}
                      </Button>
                    </Row>
                  ))}
                </Box>
              ) : (
                <layout.H3>
                  {t('supplierLists.configFlow.noSuppliersAdded')}
                </layout.H3>
              )}
            </StepNavigation2>
          </layout.ContentWrapper2>
        </Form>
      )}
    </Formik>
  );
};
