import * as React from 'react';
import * as Sentry from '@sentry/react';
import { Flex, Text } from 'rebass/styled-components';
import { map } from 'lodash';
import { UnexpectedStateError } from '@deepstream/errors';
import { OptionQuestionExchangeDefinition, QuestionExchangeDefinition, QuestionType } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';

import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Radio } from '../../../ui/Radio';

export const Options = ({
  exchangeDef,
  fieldMaxWidth,
}: {
  exchangeDef: QuestionExchangeDefinition;
  fieldMaxWidth?: React.CSSProperties['maxWidth'];
}) => {
  const { t } = useTranslation();
  const { questionType, options, allowCustomOption } = exchangeDef as OptionQuestionExchangeDefinition;

  if (![QuestionType.MULTIPLE_CHOICE, QuestionType.CHECKBOXES].includes(questionType)) {
    Sentry.captureException(new UnexpectedStateError('[Options] Wrong question type passed', { questionType }));
    return null;
  }

  const allOptions = allowCustomOption ? [...options, t('request.question.other')] : options;

  return (
    <Stack gap={1}>
      {map(allOptions, (option, index) => (
        <Flex sx={{ alignItems: 'center' }} key={index}>
          {questionType === QuestionType.MULTIPLE_CHOICE ? (
            <Radio disabled />
          ) : (
            <Checkbox disabled />
          )}
          <Text maxWidth={fieldMaxWidth} sx={{ borderRadius: 'small', border: 'lightGray2', px: 2, py: 1, ml: 2, flex: '1 1 auto' }}>
            <Truncate>
              {option}
            </Truncate>
          </Text>
        </Flex>
      ))}
    </Stack>
  );
};
