import { useQuery } from 'react-query';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { useApi, wrap } from '../api';

export const UserEmailText = ({ id: userId }: { id: string }) => {
  const api = useApi();

  const { data: user } = useQuery(['user', { userId }], wrap(api.getUser), {
    staleTime: 60 * 60 * 1000,
  });

  return <Truncate>{user?.email}</Truncate>;
};
