import { useCallback, useMemo, Fragment } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { last } from 'lodash';
import { ActionType, ExtensionExpiry } from '@deepstream/common/rfq-utils';
import { Button, CancelButton, ConfirmButton } from '@deepstream/ui-kit/elements/button/Button';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import * as rfx from '../rfx';
import { useSendExchangeReplyContext } from './useSendExchangeReply';
import { useExchangeAction } from '../useExchange';
import { useIsEnabledAfterDeadline } from './useIsEnabledAfterDeadline';

export const useShouldShowReenableResponses = (disabledReason: string) => {
  const reenableResponsesAction = useExchangeAction(ActionType.REENABLE_RESPONSES);
  const pagePermissions = rfx.usePagePermissions();

  return (
    Boolean(reenableResponsesAction) &&
    ['stage-deadline-expired', 'bid-deadline-expired'].includes(disabledReason) &&
    pagePermissions.canRespond
  );
};

export const useShouldShowDisableResponses = () => {
  const pagePermissions = rfx.usePagePermissions();
  const isEnabledAfterDeadline = useIsEnabledAfterDeadline();
  const disableResponsesAction = useExchangeAction(ActionType.DISABLE_RESPONSES);

  return Boolean(disableResponsesAction) && isEnabledAfterDeadline && pagePermissions.canRespond;
};

export const ReenableResponsesDropdown = ({ small, useAutoPlacement }: { small?: boolean; useAutoPlacement?: boolean }) => {
  const { t } = useTranslation();
  const { stages } = rfx.useStructure();
  const activeStageId = rfx.useActiveStageId();
  const [sendExchangeReply, { isLoading }] = useSendExchangeReplyContext();

  const reenableResponses = useCallback(() => {
    const activeStageIndex = stages.findIndex(stage => stage._id === activeStageId);

    if (activeStageIndex !== 0) {
      sendExchangeReply({
        value: ActionType.REENABLE_RESPONSES,
        payload: {
          extendedStageId: activeStageIndex === -1
            // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
            ? last(stages)._id
            : stages[activeStageIndex - 1]._id,
        },
      });
    }
  }, [stages, activeStageId, sendExchangeReply]);

  return (
    <Box>
      <DropdownMenu
        buttonText={t('request.exchange.reenableResponses')}
        variant="secondary"
        wrapperStyle={{
          borderTopRightRadius: '4px',
          borderBottomRightRadius: '4px',
        }}
        menuZIndex={300}
        popoverProps={useAutoPlacement ? undefined : {
          portal: false,
          style: {
            position: 'absolute',
            right: '16px',
            bottom: '42px',
          },
        }}
        small={small}
        header={
          <Box width="265px" p={3} fontSize={2}>
            <Text fontWeight={500} mb={2}>
              {t('request.exchange.dialog.reenableResponses.heading')}
            </Text>
            <Text mb={3}>
              {t('request.exchange.dialog.reenableResponses.body')}
            </Text>
            <Flex justifyContent="flex-end">
              {/*
                FIXME: Rendering the CancelButton as a `div` element to make the popup
                close when 'Cancel' is clicked. (A <button> element in this place would
                catch the click event and prevent closing.
                We should obviously make this a button and provide the required
                functionality; since our DropdownMenu doesn't forward focus to any header
                items in the popup, keyboard navigation is broken (just like in the
                NavigationDropdown) and so the `div` hack doesn't impact the UX.
              */}
              <CancelButton
                as="div"
                mr={2}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                disabled={isLoading}
                small={small}
              />
              <ConfirmButton onClick={reenableResponses} disabled={isLoading} small={small} />
            </Flex>
          </Box>
        }
      />
    </Box>
  );
};

export const ReenableExchangeResponsesDropdown = () => {
  const { t } = useTranslation('translation');
  const { stages } = rfx.useStructure();
  const activeStageId = rfx.useActiveStageId();
  const [sendExchangeReply] = useSendExchangeReplyContext();

  const reenableResponses = useCallback((extensionExpiry: ExtensionExpiry) => {
    if (extensionExpiry === ExtensionExpiry.EXCHANGE_COMPLETION) {
      sendExchangeReply({
        value: ActionType.REENABLE_RESPONSES,
        payload: {
          extensionExpiry,
        },
      });
    } else {
      const activeStageIndex = stages.findIndex(stage => stage._id === activeStageId);

      if (activeStageIndex !== 0) {
        sendExchangeReply({
          value: ActionType.REENABLE_RESPONSES,
          payload: {
            extensionExpiry,
            extendedStageId: activeStageIndex === -1
              // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
              ? last(stages)._id
              : stages[activeStageIndex - 1]._id,
          },
        });
      }
    }
  }, [stages, activeStageId, sendExchangeReply]);

  const dropdownItems = useMemo(() => [
    {
      extensionExpiry: ExtensionExpiry.EXCHANGE_COMPLETION,
      label: t('request.exchange.reenableResponsesDropdown.untilCompleted'),
    },
    {
      extensionExpiry: ExtensionExpiry.NEXT_DEADLINE,
      label: t('request.exchange.reenableResponsesDropdown.untilNextDeadline'),
    },
  ], [t]);

  return (
    <Box>
      <DropdownMenu
        buttonText={t('request.exchange.reenableResponses')}
        variant="secondary-outline"
        menuZIndex={300}
        menuStyle={{
          padding: '8px 0',
        }}
        small
        rightAligned
        header={
          <Text
            color="subtext" sx={{
            width: '200px',
            fontSize: '12px',
            padding: '4px 16px',
          }}
          >
            {t('request.exchange.reenableResponsesDropdown.header')}
          </Text>
        }
      >
        {dropdownItems.map((item, i) => (
          <Fragment key={item.label}>
            <DropdownMenuItem
              onSelect={() => reenableResponses(item.extensionExpiry)}
              style={{
                padding: '8px 16px',
              }}
            >
              {item.label}
            </DropdownMenuItem>
          </Fragment>
        ))}
      </DropdownMenu>
    </Box>
  );
};

export const DisableExchangeResponsesButton = () => {
  const { t } = useTranslation('translation');
  const [sendExchangeReply] = useSendExchangeReplyContext();

  const disableResponses = useCallback(() => {
      sendExchangeReply({
        value: ActionType.DISABLE_RESPONSES,
      });
  }, [sendExchangeReply]);

  return (
    <Button
      variant="secondary-outline"
      small
      onClick={disableResponses}
    >
      {t('request.exchange.disableResponses')}
    </Button>
  );
};
