import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NumberFormat } from '../NumberFormat';

export const CompanySize: React.FC<{ employeesNb: string }> = ({ employeesNb }) => {
  const { t } = useTranslation('companyProfile');

  if (!employeesNb) {
    return null;
  }

  if (employeesNb === '10001') {
    return (
      <>
        <NumberFormat value={employeesNb} displayType="text" thousandSeparator />
        <span>+ {t('common.employee_other')}</span>
      </>
    );
  }

  const [from, to] = employeesNb.split('-');

  return (
    <>
      <NumberFormat value={from} displayType="text" thousandSeparator />
      <span>–</span>
      <NumberFormat value={to} displayType="text" thousandSeparator />
      <span> {t('common.employee_other')}</span>
    </>
  );
};
