import { useQueryClient } from 'react-query';
import { RfqEventChange } from '@deepstream/common/rfq-utils';
import { useRfqId, useLiveRfqStructureQueryKey, useRecipientId } from './useRfq';
import { useApi } from './api';
import { useCurrentCompanyId } from './currentCompanyId';
import { useMutation } from './useMutation';
import * as rfx from './rfx';

/*
 * Mutations
 */

/**
 * Mutation for updating recipient team in a request
 */
export const useSaveBidTeamChanges = <TGetChanges extends (args: any) => RfqEventChange[]> ({
  getChanges,
  onError,
  onSuccess,
  isSender,
}: {
  getChanges: TGetChanges;
  onError: any;
  onSuccess: any;
  isSender?: boolean;
}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const senders = rfx.useSenders();
  const recipientId = useRecipientId({ required: true });
  const rfqId = useRfqId();
  const liveStructureQueryKey = useLiveRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
    recipientId,
  });
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (params: Parameters<TGetChanges>[0]) => (
      api.updateBidTeam({
        rfqId,
        senderId: senders[0]._id,
        recipientId,
        changes: getChanges(params),
        isSender,
        currentCompanyId,
      })
    ),
    {
      mutationKey: liveStructureQueryKey,
      onError,
      onSuccess,
      onSettled: () => queryClient.invalidateQueries(liveStructureQueryKey),
    },
  );

  return mutation;
};
