import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGridMenuState } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { getCellValue } from '@deepstream/ui-kit/grid/EditableGrid/getCellValue';
import { OverflowTooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { ACTION_COLUMN_WIDTH } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { DefaultEditableGridStyles } from '@deepstream/ui-kit/grid/EditableGrid/EditableGridStyles';
import { EditableGridDataProvider } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { EditableGrid } from '@deepstream/ui-kit/grid/EditableGrid/EditableGrid';
import { BORDER_ADJUSTMENT, DEFAULT_FROZEN_HEADER_HEIGHT } from '@deepstream/ui-kit/grid/core/constants';
import { getScrollbarSize } from '@deepstream/ui-utils/getScrollbarSize';
import { NavigableRange } from '@deepstream/ui-kit/grid/core/utils';
import { SupplierListOverview, SupplierListType } from '@deepstream/common';
import { DateFormat } from '@deepstream/utils';
import { GridMenu, GridMenuItem } from '@deepstream/ui-kit/grid/EditableGrid/GridMenu';
import { SortConfigProvider } from '@deepstream/ui-kit/grid/EditableGrid/sortConfig';
import { useSortedGridData } from '@deepstream/ui-kit/grid/EditableGrid/useSortedGridData';
import { ConfirmDeleteDialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { RenderHoverableGridDataCell } from '@deepstream/ui-kit/grid/EditableGrid/RenderHoverableGridDataCell';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { compact } from 'lodash';
import { ReadOnlyGrid } from '@deepstream/ui-kit/grid/EditableGrid/ReadOnlyGrid';
import { SimpleHeader, SortableHeader } from '../../ui/ExchangeDefsGrid/header';
import { useConfirmDialog } from '../../ui/useModalState';
import { useDeleteSupplierList } from './useDeleteSupplierList';
import { useSupplierListNavigation } from '../../appNavigation';
import { listTypeIconProps } from './utils';
import { useIsAuthorizedStakeholder } from '../../useIsAuthorizedStakeholder';
import { DateOrDashCell } from '../../ui/ExchangeDefsGrid/nonValidationAwareValueCell';

const frozenLeftColumnIds = ['rowNumber'];
const readOnlyFrozenLeftColumnIds = ['nameAndDescription'];

const navigableRange: NavigableRange = {
  startColumnIndex: 0,
  startRowIndex: 1,
  endColumnIndex: 1,
};

const NameAndDescriptionValueCell = ({
  row,
  column,
}) => {
  const value = getCellValue(row.original, column.original);
  const { name, description } = row.original;

  return (
    <div className="cell-content">
      <Stack gap={1} py="10px">
        <OverflowTooltip content={value}>
          <Truncate>
            {name}
          </Truncate>
        </OverflowTooltip>
        <Truncate fontSize={1} color="subtext">
          {description}
        </Truncate>
      </Stack>
    </div>
  );
};

const ListTypeValueCell = ({
  row,
  column,
}) => {
  const { t } = useTranslation();

  const value = getCellValue(row.original, column.original);
  const iconProps = listTypeIconProps[value as SupplierListType];

  return (
    <div className="cell-content">
      <IconText
        icon={iconProps.icon}
        isIconRegular={iconProps.regular}
        text={t(`supplierLists.listType.${value}`)}
      />
    </div>
  );
};

const TotalSuppliersValueCell = ({
  row,
  column,
}) => {
  const { t } = useTranslation();

  const value = getCellValue(row.original, column.original);

  return (
    <div className="cell-content">
      <IconText
        icon="buildings"
        isIconRegular
        text={t('supplierLists.supplierCount', { count: value })}
      />
    </div>
  );
};

const ROW_HEIGHT = 58;

export const SupplierListsGrid = ({ supplierLists }: { supplierLists: SupplierListOverview[] }) => {
  const { t } = useTranslation();
  const { confirm, ...confirmDeleteDialogProps } = useConfirmDialog();
  const { toggleMenu, menuReferenceId, dataIndex } = useGridMenuState();
  const [deleteSupplierList] = useDeleteSupplierList();
  const { navigateToListSuppliers } = useSupplierListNavigation();
  const isAuthorizedStakeholder = useIsAuthorizedStakeholder();

  const [sortConfig, setSortConfig] = useState({
    columnId: null,
    direction: null,
  });

  const canEdit = !isAuthorizedStakeholder;

  const columns = useMemo(
    () => compact([
      canEdit && {
        _id: 'rowNumber',
        accessorKey: 'rowNumber',
        Header: SimpleHeader,
        label: '',
        ValueCell: () => null,
        toggleMenu,
        width: ACTION_COLUMN_WIDTH,
        fixedWidth: true,
      },
      {
        _id: 'nameAndDescription',
        accessorKey: 'name',
        Header: SortableHeader,
        label: t('supplierLists.nameAndDescription'),
        ValueCell: NameAndDescriptionValueCell,
        width: 487 + (!canEdit ? ACTION_COLUMN_WIDTH : 0),
      },
      {
        _id: 'listType',
        accessorKey: 'listType',
        Header: SortableHeader,
        label: t('supplierLists.type'),
        ValueCell: ListTypeValueCell,
        width: 200,
      },
      {
        _id: 'totalSuppliers',
        accessorKey: 'suppliers.length',
        Header: SortableHeader,
        label: t('supplierLists.totalSuppliers'),
        ValueCell: TotalSuppliersValueCell,
        width: 200,
      },
      {
        _id: 'lastUpdated',
        accessorKey: 'lastUpdated',
        Header: SortableHeader,
        label: t('supplierLists.lastUpdated'),
        ValueCell: DateOrDashCell,
        format: DateFormat.DD_MMM_YYYY,
        width: 200,
      },
    ]),
    [t, toggleMenu, canEdit],
  );

  // @ts-expect-error ts(2345) FIXME: Argument of type '({ _id: string; accessorKey: string; Header: ({ column, info, showFieldTypeIcon, truncate, }: FrozenHeaderCellProps<EditableGridColumn>) => Element; label: string; ValueCell: () => null; toggleMenu: (rowIndex: number, columnIndex: number) => void; width: number; format?: undefined; } | { ...; } | { ...; })[]' is not assignable to parameter of type 'EditableGridColumn<unknown>[]'.
  const sortedSupplierLists = useSortedGridData(sortConfig, supplierLists, columns);

  const onDelete = () => confirm(async () => {
    // @ts-expect-error ts(2538) FIXME: Type 'null' cannot be used as an index type.
    const supplierList = sortedSupplierLists[dataIndex];
    await deleteSupplierList(supplierList._id);
  });

  const maxGridHeight = (
    DEFAULT_FROZEN_HEADER_HEIGHT +
    BORDER_ADJUSTMENT +
    ROW_HEIGHT * (supplierLists.length || 0) +
    getScrollbarSize()
  );

  return (
    <SortConfigProvider sortConfig={sortConfig} setSortConfig={setSortConfig}>
      {canEdit ? (
        <DefaultEditableGridStyles
          highlightOnHover
          style={{ width: '100%', height: `min(100vh - 310px, ${maxGridHeight}px)` }}
        >
          <EditableGridDataProvider
            rowData={[]}
          >
            <EditableGrid
              frozenLeftColumnIds={frozenLeftColumnIds}
              // @ts-expect-error ts(2322) FIXME: Type '({ _id: string; accessorKey: string; Header: ({ column, info, showFieldTypeIcon, truncate, }: FrozenHeaderCellProps<EditableGridColumn>) => Element; label: string; ValueCell: () => null; toggleMenu: (rowIndex: number, columnIndex: number) => void; width: number; format?: undefined; } | { ...; } | { ...; })[]' is not assignable to type 'EditableGridColumn[]'.
              columns={columns}
              rowData={sortedSupplierLists as any}
              subRowHeight={ROW_HEIGHT}
              onGridClipboardEvent={() => {}}
              navigableRange={navigableRange}
              RenderDataCell={RenderHoverableGridDataCell}
              onRowClick={(supplierList) => navigateToListSuppliers(supplierList._id)}
            />
            {menuReferenceId && (
              <GridMenu>
                <GridMenuItem
                  icon="trash"
                  onSelect={onDelete}
                  color="danger"
                >
                  {t('supplierLists.deleteList')}
                </GridMenuItem>
              </GridMenu>
            )}
          </EditableGridDataProvider>
        </DefaultEditableGridStyles>
      ) : (
        <DefaultEditableGridStyles
          isReadOnly
          highlightOnHover
          style={{ width: '100%', height: `min(100vh - 300px, ${maxGridHeight}px)` }}
        >
          <ReadOnlyGrid
            frozenLeftColumnIds={readOnlyFrozenLeftColumnIds}
            // @ts-expect-error ts(2322) FIXME: Type '({ _id: string; accessorKey: string; Header: ({ column, info, showFieldTypeIcon, truncate, }: FrozenHeaderCellProps<EditableGridColumn>) => Element; label: string; ValueCell: () => null; toggleMenu: (rowIndex: number, columnIndex: number) => void; width: number; format?: undefined; } | { ...; } | { ...; })[]' is not assignable to type 'EditableGridColumn<unknown>[]'.
            columns={columns}
            rowData={sortedSupplierLists as any}
            subRowHeight={ROW_HEIGHT}
            RenderDataCell={RenderHoverableGridDataCell}
            onRowClick={(supplierList) => navigateToListSuppliers(supplierList._id)}
          />
        </DefaultEditableGridStyles>
      )}
      <ConfirmDeleteDialog
        heading={t('supplierLists.dialog.confirmDeleteList.heading')}
        message={t('supplierLists.dialog.confirmDeleteList.message')}
        {...confirmDeleteDialogProps}
      />
    </SortConfigProvider>
  );
};
