import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { ExtendedReceivedContractOverview } from '@deepstream/common/contract';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { Modal, ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import { Bold } from '../../Bold';

type CannotAccessContractDialogProps = {
  isOpen: boolean;
  onCancel: () => void;
  contract: ExtendedReceivedContractOverview | null;
};

export const CannotAccessContractDialog = ({
  isOpen,
  onCancel,
  contract,
}: CannotAccessContractDialogProps) => {
  const { t } = useTranslation(['contracts', 'general']);

  const heading = t('dialog.cannotAccess.heading', { ns: 'contracts' });

  return contract ? (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      contentLabel={heading}
      style={{ content: { width: '500px' } }}
    >
      <ModalHeader onClose={onCancel}>
        {heading}
      </ModalHeader>
      <Box p={3} fontSize={2}>
        <Text lineHeight={1.5}>
          <Bold>{t('contract', { ns: 'contracts' })}:</Bold> {contract.name}
        </Text>
        {contract.ownerNamesOrEmails ? (
          <>
            <Text lineHeight={1.5}>
              <Bold>{t('owner_other', { ns: 'contracts' })}:</Bold>
              {' '}
              {contract.ownerNamesOrEmails.join(', ')}
            </Text>
            <Text mt={3} mb={2} lineHeight={1.5}>
              {t('dialog.cannotAccess.notOnTeam', { ns: 'contracts' })}
            </Text>
            <Text mb={2} lineHeight={1.5}>
              {t('dialog.cannotAccess.askOwner', { ns: 'contracts' })}
            </Text>
          </>
        ) : contract.teamMemberNamesOrEmails ? (
          <>
            <Text lineHeight={1.5}>
              <Bold>{t('teamMember_other', { ns: 'contracts' })}:</Bold>
              {' '}
              {contract.teamMemberNamesOrEmails.join(', ')}
            </Text>
            <Text mt={3} mb={2} lineHeight={1.5}>
              {t('dialog.cannotAccess.notOnTeam', { ns: 'contracts' })}
            </Text>
            <Text mb={2} lineHeight={1.5}>
              {t('dialog.cannotAccess.askTeamMember', { ns: 'contracts' })}
            </Text>
          </>
        ) : (
          <Text mt={3} mb={2} lineHeight={1.5}>
            {t('dialog.cannotAccess.notOnTeam', { ns: 'contracts' })}
          </Text>
        )}
      </Box>
      <PanelDivider />
      <Flex p={3} justifyContent="flex-end">
        <Button variant="primary" onClick={onCancel} style={{ minWidth: 60 }}>
          {t('dismiss', { ns: 'general' })}
        </Button>
      </Flex>
    </Modal>
  ) : null;
};
