import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box } from 'rebass/styled-components';
import { CompanyMinimized } from '@deepstream/common/rfq-utils';
import { first, get, isEmpty, map, orderBy } from 'lodash';
import { LabeledSorting } from '@deepstream/ui-utils';
import { SortingDirection } from '@deepstream/common';
import { useQuery } from 'react-query';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelHeader, PanelDivider, PanelProps } from '@deepstream/ui-kit/elements/Panel';
import { useDeviceSize } from '../../../ui/useDeviceSize';
import { useModalState } from '../../../ui/useModalState';
import * as rfx from '../../../rfx';
import { renderLabel } from '../../../RequestsTable';
import { useSortProps } from '../../../sorting';
import { ClearFiltersButton, useFilterProps } from '../../../filtering';
import { SelectDropdownMenu } from '../../../ui/MultiSelect';
import { RequestTeamUsersTable } from './RequestTeamUsersTable';
import { useRequestRoleSelectItems } from './useRequestRoleSelectItems';
import { RequestTeamAddUserModal } from './RequestTeamAddUserModal';
import { RequestRole } from '../../../types';
import { useApi, wrap } from '../../../api';

const useSortItems = (): LabeledSorting[] => {
  const { t } = useTranslation();

  return useMemo(() => [
    { label: t('general.sorting.nameAscending'), accessor: 'name', direction: SortingDirection.ASC },
    { label: t('general.sorting.nameDescending'), accessor: 'name', direction: SortingDirection.DESC },
  ], [t]);
};

export const useRequestTeamUsers = (companyId: string) => {
  const api = useApi();
  const { teamById } = rfx.useStructure();

  const { data: usersForCompany } = useQuery(
    ['usersForCompany', { companyId }],
    wrap(api.getUsersForCompany),
  );

  return useMemo(() => Object.values(teamById[companyId].users)
    .map(user => {
      const userForCompany = usersForCompany?.find(
        userInCompany => userInCompany._id === user._id,
      );

      return {
        _id: user._id,
        name: user.name || userForCompany?.name,
        email: user.email || userForCompany?.email,
        requestRole: teamById[companyId].owners.includes(user._id)
          ? RequestRole.OWNER
          : RequestRole.TEAM_MEMBER,
        companyId,
        roleRestrictionProfile: userForCompany?.roleRestrictionProfiles?.[companyId] ?? null,
      };
    }), [usersForCompany, teamById, companyId]);
};

export const RequestTeamUsersPanel = ({
  company,
  ...props
}: {
  company: CompanyMinimized;
} & Omit<PanelProps, 'children'>) => {
  const { t } = useTranslation();

  const { isExtraSmall } = useDeviceSize();
  const { canEditTeam } = rfx.useRfxPermissions();
  const addUserModal = useModalState();
  const { editingPanelId, isTemplate } = rfx.useState();

  const isEditing = Boolean(editingPanelId);

  const users = useRequestTeamUsers(company._id);

  const roleFilterItems = useRequestRoleSelectItems();
  const roleFilter = useFilterProps({
    items: roleFilterItems,
    idProp: 'value',
    renderItem: renderLabel,
  });

  const sortItems = useSortItems();
  const sort = useSortProps({
    items: sortItems,
  });

  const selectedUsers = useMemo(() => {
    let usersClone = [...users];

    if (!isEmpty(roleFilter.selectedItems)) {
      const selectedRoles = map(roleFilter.selectedItems, 'value');
      usersClone = usersClone.filter(
        user => selectedRoles.includes(user.requestRole),
      );
    }

    if (!isEmpty(sort.selectedItems)) {
      const sorting = first(sort.selectedItems) as LabeledSorting;

      usersClone = orderBy(
        usersClone,
        [(user) => get(user, sorting.accessor, '').toLowerCase()],
        [sorting.direction],
      );
    }

    return usersClone;
  }, [users, roleFilter, sort]);

  const hasFilters = !isEmpty(roleFilter.selectedItems);

  return (
    <>
      <Panel {...props}>
        <PanelHeader heading={t('general.user_other')}>
          <Flex>
            {hasFilters && (
              <Box mr={2}>
                <ClearFiltersButton
                  onClick={() => roleFilter.onChange([])}
                />
              </Box>
            )}
            {!!users.length && (
              <>
                <Box mr={2}>
                  <SelectDropdownMenu
                    multi
                    buttonText={t('general.role')}
                    buttonIcon="filter"
                    menuWidth={180}
                    menuZIndex={10}
                    disabled={isEditing}
                    {...roleFilter}
                  />
                </Box>
                <Box>
                  <SelectDropdownMenu
                    rightAligned={!canEditTeam}
                    buttonText={t('general.sort')}
                    buttonIcon="sort"
                    menuWidth={160}
                    menuZIndex={10}
                    disabled={isEditing}
                    {...sort}
                  />
                </Box>
              </>
            )}
            {(canEditTeam && !isTemplate) && (
              <Button
                ml={isExtraSmall ? 3 : 4}
                variant="primary"
                iconLeft="plus"
                onClick={addUserModal.open}
                disabled={isEditing}
                small
              >
                {t('teamManagement.addUser')}
              </Button>
            )}
          </Flex>
        </PanelHeader>
        <PanelDivider />
        <RequestTeamUsersTable
          users={selectedUsers}
          canEditPermissions={canEditTeam && !isEditing}
        />
      </Panel>
      {addUserModal.isOpen && (
        <RequestTeamAddUserModal
          isOpen={addUserModal.isOpen}
          close={addUserModal.close}
          company={company}
        />
      )}
    </>
  );
};
