import React, { forwardRef, useState } from 'react';
import { SpaceProps } from 'styled-system';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useResizeObserver } from '@deepstream/ui-kit/hooks/useResizeObserver';
import { PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { ModalBody as ModalBodyBase, ModalHeader as ModalHeaderBase } from '@deepstream/ui-kit/elements/popup/Modal';

export const Body = (props) => {
  return (
    <ModalBodyBase
      p={0}
      flex={1}
      display="flex"
      overflow="hidden"
      sx={{ flexDirection: ['column', 'column', 'row'] }}
      {...props}
    />
  );
};

export const Fields = forwardRef<HTMLDivElement, any>((props, ref) => {
  return (
    <Box
      ref={ref}
      p={3}
      flex="1 0 50%"
      overflowY="auto"
      sx={{
        borderRightColor: 'lightGray',
        borderRightWidth: '1px',
        borderRightStyle: ['none', 'none', 'solid'],
        borderBottomColor: 'lightGray',
        borderBottomWidth: '1px',
        borderBottomStyle: ['solid', 'solid', 'none'],
        lineHeight: 1.4,
      }}
      {...props}
    />
  );
});

export const Timeline = forwardRef<HTMLDivElement, any>((props, ref) => {
  return (
    <Flex
      ref={ref}
      flexDirection="column"
      flex="1 0 50%"
      overflowY="auto"
      {...props}
    />
  );
});

export const TimelineHeader = ({ children }) => {
  return (
    <Box p={3} flex={0}>
      <Text
        color="subtext"
        fontSize={1}
        letterSpacing="1px"
        sx={{ textTransform: 'uppercase' }}
      >
        {children}
      </Text>
    </Box>
  );
};

export const TimelineLeaveComment = ({ children }) => {
  return (
    <Box
      p={3}
      sx={{
        borderTopColor: 'lightGray',
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
      }}
    >
      {children}
    </Box>
  );
};

export const SectionHeader = ({ children, pt = 3 }) => {
  return (
    <>
      <Box pb={3} pt={pt} flex={0}>
        <Text
          color="subtext"
          fontSize={1}
          letterSpacing="1px"
          sx={{ textTransform: 'uppercase' }}
        >
          {children}
        </Text>
      </Box>
      <PanelDivider />
    </>
  );
};

const AnimatedChevron = styled<any>(Icon)`
  cursor: ${props => props.disabled ? 'default' : 'pointer'};

  @keyframes rotate-right {
    from {
      transform: rotate(90deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  @keyframes rotate-down {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(90deg);
    }
  }

  animation: ${props => props.isOpen ? 'rotate-down 0.3s ease-in-out' : 'rotate-right 0.3s ease-in-out'};
  animation-fill-mode: forwards;
`;

export const ClosableSectionHeader = ({
  children,
  icon,
  isToggleDisabled = false,
  rightSide = null,
  isOpen,
  onToggle,
}: {
  children: React.ReactNode;
  icon: React.ReactNode;
  isToggleDisabled?: boolean;
  rightSide?: React.ReactNode;
  isOpen: boolean;
  onToggle: () => void;
}) => {
  return (
    <Flex px={3} flex={0} sx={{ gap: '8px' }} alignItems="center">
      <AnimatedChevron
        isOpen={isOpen}
        color="subtext"
        icon="chevron-right"
        disabled={isToggleDisabled}
        onClick={isToggleDisabled ? undefined : onToggle}
      />
      {icon}
      <Text
        color="subtext"
        fontSize={1}
        letterSpacing="1px"
        sx={{ textTransform: 'uppercase', flexGrow: 1 }}
      >
        {children}
      </Text>
      {rightSide}
    </Flex>
  );
};

const ClosableSectionBase = styled<any>(Box)`
  max-height: ${props => props.maxHeight};
  transition: max-height 0.3s ease-in-out;
`;

const ClosableSectionContainer = styled<any>(Box)`
  ${props => props.isHighlighted ? 'box-shadow: 0px 0px 16px 0px rgba(8, 35, 48, 0.24);' : ''}
  ${props => props.isBottomHighlighted ? 'box-shadow: 0px 8px 8px 0px rgba(8, 35, 48, 0.12);' : ''}
`;

export const ClosableSection = ({
  children,
  showHeader = true,
  header = null,
  headerIcon = null,
  rightHeaderSide = null,
  isHighlighted = false,
  isBottomHighlighted = false,
  px = 3,
  hasDividerAtStartOfContent = false,
}: {
  children: React.ReactNode;
  showHeader?: boolean;
  header?: React.ReactNode;
  headerIcon?: React.ReactNode;
  rightHeaderSide?: React.ReactNode;
  isHighlighted?: boolean;
  isBottomHighlighted?: boolean;
  px?: SpaceProps['px'];
  hasDividerAtStartOfContent?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const { ref: containerRef, dimensions } = useResizeObserver();

  const onToggle = () => setIsOpen(!isOpen);

  return (
    <ClosableSectionContainer flex={0} pt={3} isHighlighted={isHighlighted} isBottomHighlighted={isOpen ? isBottomHighlighted : false}>
      {showHeader && (
        <ClosableSectionHeader
          icon={headerIcon}
          isOpen={isOpen}
          onToggle={onToggle}
          rightSide={rightHeaderSide}
          isToggleDisabled={isHighlighted}
        >
          {header}
        </ClosableSectionHeader>
      )}
      <Box overflow="hidden" px={px}>
        <ClosableSectionBase isOpen={isOpen} maxHeight={isOpen ? (dimensions?.height ? `${dimensions.height}px` : 'auto') : 0}>
          <div ref={containerRef}>
            {children}
          </div>
        </ClosableSectionBase>
      </Box>
      <PanelDivider style={{ marginTop: !hasDividerAtStartOfContent || isOpen ? '16px' : 0 }} />
    </ClosableSectionContainer>
  );
};

export const Subsection = ({
  children,
  showHeader = true,
  header = null,
  headerIcon = null,
  rightHeaderSide = null,
  isHighlighted = false,
}) => {
  return (
    <>
      <PanelDivider style={{ marginTop: '16px' }} />
      <ClosableSectionContainer flex={0} pt={3} isHighlighted={isHighlighted}>
        {showHeader && (
          <Flex px={3} flex={0} sx={{ gap: '8px' }} alignItems="center">
            {headerIcon}
            <Text
              color="subtext"
              fontSize={1}
              letterSpacing="1px"
              sx={{ textTransform: 'uppercase', flexGrow: 1 }}
            >
              {header}
            </Text>
            {rightHeaderSide}
          </Flex>
        )}
        <Box px={3}>
          {children}
        </Box>
      </ClosableSectionContainer>
    </>
  );
};

export const Content = (props) => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="stretch"
      height="calc(100vh - 56px)"
      {...props}
    />
  );
};

export const Footer = ({ children }) => {
  return (
    <Box flex={0}>
      <PanelDivider />
      {children}
    </Box>
  );
};

export const Header = ModalHeaderBase;
