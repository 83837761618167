import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { useBlocker } from '@tanstack/react-router';
import { useFormikContext } from 'formik';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Modal, ModalHeader, ModalBody, ModalFooter, SaveButton, CancelButton, DiscardButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { useIsFormDirty } from '../pre-q/useIsFormDirty';
import { useHooks } from '../useHooks';

const headingId = 'leave-page-modal-heading';

export const LeavePageModalBase = ({
  hasChanges,
  isSaving,
  isValid,
  saveChanges,
  decideCanLeavePage,
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!hasChanges || isSaving) {
      decideCanLeavePage(true);
    } else if (!showModal) {
      setShowModal(true);
    }
  }, [decideCanLeavePage, showModal, hasChanges, isSaving]);

  const onDiscard = () => {
    if (decideCanLeavePage) {
      decideCanLeavePage(true);
    }
  };

  const onCancel = () => {
    if (decideCanLeavePage) {
      decideCanLeavePage(false);
    }
  };

  const onSave = async () => {
    if (isValid) {
      await saveChanges();
    }

    if (decideCanLeavePage) {
      decideCanLeavePage(true);
    }
  };

  return (
    <Modal
      shouldCloseOnEsc
      isOpen={showModal}
      onRequestClose={onCancel}
      aria={{ labelledby: headingId }}
    >
      <ModalHeader headingId={headingId} onClose={onCancel}>
        {t('request.dialog.leavePage.heading')}
      </ModalHeader>
      <ModalBody>
        <MessageBlock variant="info" mb={2}>
          {t('request.dialog.leavePage.body')}
        </MessageBlock>
      </ModalBody>
      <ModalFooter>
        <DiscardButton onClick={onDiscard} />
        <Flex flex={1} justifyContent="flex-end">
          <CancelButton onClick={onCancel} />
          <SaveButton onClick={onSave} disabled={!isValid} />
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

export const LeavePageModal = () => {
  const { isSubmitting, isValid, submitForm } = useFormikContext();
  const isDirty = useIsFormDirty();
  const { useActions } = useHooks();
  const { stopEditing } = useActions();
  const [reactDecideCanLeavePage, setReactDecideCanLeavePage] = useState<((decision: boolean) => void) | null>(null);

  const decideCanLeavePage = useCallback(
    (decision: boolean) => {
      if (decision === true) {
        stopEditing();
      }

      reactDecideCanLeavePage?.(decision);
    },
    [reactDecideCanLeavePage, stopEditing],
  );

  useBlocker({
    blockerFn: () => new Promise(resolve => {
      setReactDecideCanLeavePage(() => (decision: boolean) => {
        resolve(decision);
        setReactDecideCanLeavePage(null);
      });
    }),
    condition: isDirty,
  });

  return (
    reactDecideCanLeavePage ? (
      <LeavePageModalBase
        hasChanges={isDirty}
        isSaving={isSubmitting}
        isValid={isValid}
        saveChanges={submitForm}
        decideCanLeavePage={decideCanLeavePage}
      />
    ) : (
      null
    )
  );
};
