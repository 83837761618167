import { unary } from 'lodash';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useModalState } from '../../ui/useModalState';
import { User } from '../../types';
import { TextField } from '../../form/TextField';
import { ModalForm, ModalFormProps } from '../../ModalForm';
import { PropertyList } from '../../PropertyList';
import { useUpdateUserProfile } from './useUserMutation';
import { EditProperty, ValueOrNotSpecified } from './utils';
import { useCurrentUser } from '../../useCurrentUser';

const phoneNumberRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

type EditPhoneNumberModalProps =
  Omit<ModalFormProps, 'heading' | 'initialValues' | 'validationSchema' | 'onSubmit'> &
  { phoneNumber: User['phoneNumber']; onSuccess: any };

const EditPhoneNumberModal = ({
  phoneNumber,
  onSuccess,
  ...props
}: EditPhoneNumberModalProps) => {
  const { t } = useTranslation();
  const [updateUserProfile] = useUpdateUserProfile({
    fieldName: t('user.profile.phoneNumber'),
    onSuccess,
  });

  return (
    <ModalForm
      heading={t('user.profile.dialog.editPhoneNumber.heading')}
      initialValues={{
        phoneNumber,
      }}
      validationSchema={yup.object({
        phoneNumber: yup
          .string()
          .matches(phoneNumberRegExp, t('user.profile.errors.invalidPhoneNumber')),
      })}
      onSubmit={unary(updateUserProfile)}
      {...props}
    >
      <TextField name="phoneNumber" label={t('user.profile.directPhoneNumber')} inputType="tel" />
      <MessageBlock variant="info" mt={0}>
        {t('user.profile.dialog.editPhoneNumber.info')}
      </MessageBlock>
    </ModalForm>
  );
};

export const SupportContactPanel = () => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const editPhoneNumberModal = useModalState();

  return (
    <>
      <Panel heading={t('user.profile.supportContact')}>
        <PropertyList
          DefaultComponent={ValueOrNotSpecified}
          properties={[{
            name: t('user.profile.directPhoneNumber'),
            value: user.phoneNumber,
          }]}
        >
          <EditProperty
            onClick={editPhoneNumberModal.open}
            data-test="edit-phone-number"
          />
        </PropertyList>
      </Panel>
      <EditPhoneNumberModal
        phoneNumber={user.phoneNumber}
        isOpen={editPhoneNumberModal.isOpen}
        onSuccess={editPhoneNumberModal.close}
        onCancel={editPhoneNumberModal.close}
      />
    </>
  );
};
