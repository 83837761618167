import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EvaluationPage,
  getPagesInDisplayOrder,
  getPageWeightsTotal,
  isLinkedEvaluationPage,
} from '@deepstream/common/rfq-utils';
import { localeFormatFactorAsPercent } from '@deepstream/utils';
import * as yup from 'yup';
import { Form, Formik, useField } from 'formik';
import { Box, Flex } from 'rebass/styled-components';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { CancelButton, Modal, ModalBody, ModalFooter, ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import { useUpdatePagesWeight } from '../../draft/draft';
import { TextField, TextFieldBase } from '../../form/TextField';
import * as rfx from '../../rfx';
import { useCurrentUserLocale } from '../../useCurrentUser';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

const WeightField = ({
  name,
  label,
  hideLabel,
  inputStyle,
  index,
}) => {
  const { absolutePageWeightById } = rfx.useEvaluationWeights();
  const locale = useCurrentUserLocale();

  const [{ value: pages }] = useField<EvaluationPage[]>('pages');

  const nonObsoletePageIds = Object.keys(absolutePageWeightById);
  const nonObsoletePages = pages.filter(page => nonObsoletePageIds.includes(page._id));

  const pageWeightsTotal = getPageWeightsTotal(nonObsoletePages);

  const page = pages[index];

  return (
    <TextFieldBase
      name={name}
      label={label}
      hideLabel={hideLabel}
      inputStyle={inputStyle}
      hideError
      disabled
      value={nonObsoletePageIds.includes(page._id) ? (
        localeFormatFactorAsPercent((page.weight || 0) / pageWeightsTotal, { locale })
      ) : (
        '—'
      )}
    />
  );
};

const EditPageWeightHeader = () => {
  const { t } = useTranslation('evaluation');
  const { pageById } = rfx.useStructure();
  const [{ value: pages }] = useField<EvaluationPage[]>('pages');

  return (
    <>
      {pages.map((page, index) => (
        <Flex key={page._id} alignItems="flex-start" mb={3} flexDirection={['column', 'row']}>
          <Box flexGrow={1} ml="1px" mr="7px">
            <TextFieldBase
              disabled
              label={t('editPageWeightsTable.headers.page')}
              hideLabel={index > 0}
              name={`pages[${index}].name`}
              inputStyle={{ textAlign: 'left', height: 40 }}
              // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
              value={pageById[page.linkedPageId].name}
            />
          </Box>
          <Box width="80px" ml="1px" mr="7px">
            <TextField
              name={`pages[${index}].weight`}
              label={t('editPageWeightsTable.headers.weightNo')}
              hideLabel={index > 0}
              inputStyle={{ textAlign: 'right', height: 40 }}
              format="integer"
              convertNonNumbersToNull
            />
          </Box>
          <Box width="80px" ml="1px" mr="1px">
            <WeightField
              name={`pages[${index}].weight`}
              label={t('editPageWeightsTable.headers.weight')}
              hideLabel={index > 0}
              inputStyle={{ textAlign: 'right', height: 40 }}
              index={index}
            />
          </Box>
        </Flex>
      ))}
    </>
  );
};

export const EditPageWeightsModal = ({ isOpen, onSubmit, onCancel }: Props) => {
  const { t } = useTranslation('evaluation');
  const structure = rfx.useStructure();
  const pages = useMemo(
    () => getPagesInDisplayOrder(structure.pages).filter(isLinkedEvaluationPage),
    [structure],
  );

  const [updatePagesWeight] = useUpdatePagesWeight();

  return (
    <Modal style={{ content: { minWidth: '600px' } }} isOpen={isOpen} onRequestClose={onCancel} shouldCloseOnEsc>
      <Formik<{ pages: EvaluationPage[] }>
        validateOnBlur
        enableReinitialize
        initialValues={{ pages }}
        validationSchema={
          yup.object().shape({
            pages: yup.array().of(yup.object().shape({
              weight: yup.number().nullable().min(1, t('errors.weightMinValue')),
            })),
          })
        }
        onSubmit={async ({ pages }, { setSubmitting }) => {
          setSubmitting(true);
          await updatePagesWeight(pages);
          setSubmitting(false);
          onSubmit();
        }}
      >
        {({ dirty, isValid, isSubmitting }) => (
          <Form style={{ width: '100%' }}>
            <ModalHeader>
              {t('editPageWeightsModal.title')}
            </ModalHeader>
            <ModalBody>
              <EditPageWeightHeader />
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} />
              <Button type="submit" variant="primary" disabled={isSubmitting || !isValid || !dirty} >
                {t('editPageWeightsModal.submit')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
