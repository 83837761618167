import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { Illustration } from '@deepstream/ui-kit';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { useModalState } from './ui/useModalState';
import { UpgradeToUnlockDialog } from './UpgradeToUnlockDialog';
import { TemplateCount } from './TemplateCount';
import { MIN_CELL_HEIGHT } from './FieldsCell';

export const TemplatesNotPayingCompany = () => {
  const { t } = useTranslation('translation');
  const upgradeDialog = useModalState();

  return (
    <>
      <Panel>
        <PanelHeader
          height="60px"
          pt="1px"
          pb="2px"
          heading={<TemplateCount count={0} />}
          collapse={false}
        >
          <Button
            small
            variant="primary"
            iconLeft="lock"
            onClick={upgradeDialog.open}
          >
            {t('template.newTemplate')}
          </Button>
        </PanelHeader>
        <PanelDivider />
        <Flex
          height={MIN_CELL_HEIGHT * 5}
          flexDirection="column"
          justifyContent="center"
        >
          <PanelPadding>
            <Illustration
              variant="locked"
              label={t('requests.onlyPayingCompaniesCanUseTemplates')}
              labelSx={{
                fontWeight: 500,
              }}
            />
          </PanelPadding>
        </Flex>
      </Panel>
      <UpgradeToUnlockDialog
        isOpen={upgradeDialog.isOpen}
        onCancel={upgradeDialog.close}
      />
    </>
  );
};
