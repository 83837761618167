import { useMemo } from 'react';
import { cloneDeep, isEqual, omit } from 'lodash';
import { Draft, ExchangeDefinition, ExchangeType } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { isPast } from 'date-fns';
import { GridMenu, GridMenuItem } from '@deepstream/ui-kit/grid/EditableGrid/GridMenu';
import { useGridMenuState } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { compareLiveVersionIgnoreKeys } from '../../draft/ExchangeDefActions';
import { makeNonObsolete, makeObsolete } from './exchangeDefUpdaters';
import { useHooks } from '../../useHooks';

export const ExchangeDefsGridMenu = ({
  onObsoleteClick,
  canAddItems = true,
}: {
  onObsoleteClick?: (exchangeId: string) => void,
  canAddItems?: boolean,
}) => {
  const { t } = useTranslation();
  const { dataIndex } = useGridMenuState();
  const {
    rowData: draftExchangeDefs,
    moveRow,
    duplicateRow,
    removeRow,
    updateRowAtIndex,
  } = useEditableGridData<ExchangeDefinition<Draft>>();

  const { useExchangeDefById, useAuction } = useHooks();
  const auction = useAuction();
  const exchangeDefById = useExchangeDefById();
  // @ts-expect-error ts(2538) FIXME: Type 'null' cannot be used as an index type.
  const draftExchangeDef = draftExchangeDefs[dataIndex] as Partial<ExchangeDefinition<Draft>> & { _id: string };
  const exchangeId = draftExchangeDef._id;
  const exchangeDef = exchangeDefById[exchangeId] as ExchangeDefinition<Draft>;
  const linkedAuctionExchangeDef = Object.values(exchangeDefById).find(
    (linkedExchangeDef) => 'linkedExchangeDefId' in linkedExchangeDef &&
      linkedExchangeDef.linkedExchangeDefId === exchangeId &&
      linkedExchangeDef.type === ExchangeType.AUCTION_LINE_ITEM,
  );
  const canDuplicate = canAddItems && !exchangeDef?.isObsolete;
  const canChangeObsoleteState = (
    exchangeDef?.isLive &&
    !exchangeDef.liveVersion?.isObsolete &&
    !(auction && isPast(new Date(auction.startDate)) && linkedAuctionExchangeDef)
  );
  const canDelete = !exchangeDef?.isLive;

  const hasStagedChanges = useMemo(() => {
    if (
      !exchangeDef ||
      !exchangeDef.isLive ||
      !exchangeDef.liveVersion ||
      exchangeDef.isObsolete
    ) {
      return false;
    }

    return !isEqual(
      omit(draftExchangeDef, compareLiveVersionIgnoreKeys),
      omit(exchangeDef.liveVersion, compareLiveVersionIgnoreKeys),
    );
  }, [draftExchangeDef, exchangeDef]);

  return draftExchangeDef ? (
    <GridMenu
      popoverProps={{
        'data-test': 'more-exchange-actions-menu',
      }}
    >
      <GridMenuItem
        icon="arrow-up"
        // @ts-expect-error ts(2345) FIXME: Argument of type 'number | null' is not assignable to parameter of type 'number'.
        onSelect={() => moveRow(dataIndex, -1)}
        disabled={dataIndex === 0}
      >
        {t('general.moveUp')}
      </GridMenuItem>

      <GridMenuItem
        icon="arrow-down"
        // @ts-expect-error ts(2345) FIXME: Argument of type 'number | null' is not assignable to parameter of type 'number'.
        onSelect={() => moveRow(dataIndex, +1)}
        disabled={dataIndex === draftExchangeDefs.length - 1}
      >
        {t('general.moveDown')}
      </GridMenuItem>

      {canDuplicate && (
        <GridMenuItem
          icon="clone"
          // @ts-expect-error ts(2345) FIXME: Argument of type 'number | null' is not assignable to parameter of type 'number'.
          onSelect={() => duplicateRow(dataIndex, clonedSourceRow => ({
            ...clonedSourceRow,
            _id: uuid(),
            description: `${t('general.copyOf')} ${'description' in clonedSourceRow ? clonedSourceRow.description ?? '' : ''}`,
          } as ExchangeDefinition<Draft>))}
        >
          {t('general.duplicate')}
        </GridMenuItem>
      )}

      {canDelete && (
        <GridMenuItem
          icon="trash-o"
          color="danger"
          // @ts-expect-error ts(2345) FIXME: Argument of type 'number | null' is not assignable to parameter of type 'number'.
          onSelect={() => removeRow(dataIndex)}
        >
          {t('general.delete')}
        </GridMenuItem>
      )}

      {canChangeObsoleteState && !draftExchangeDef.isObsolete && (
        <GridMenuItem
          icon="ban"
          disabled={hasStagedChanges}
          color="danger"
          onSelect={
            onObsoleteClick
              ? () => onObsoleteClick(exchangeId)
              // @ts-expect-error ts(2345) FIXME: Argument of type 'number | null' is not assignable to parameter of type 'number'.
              : (() => updateRowAtIndex(dataIndex, makeObsolete))
          }
        >
          {t('general.makeObsolete')}
        </GridMenuItem>
      )}

      {canChangeObsoleteState && !draftExchangeDef.isObsolete && hasStagedChanges && (
        <GridMenuItem
          icon="times"
          // @ts-expect-error ts(2345) FIXME: Argument of type 'number | null' is not assignable to parameter of type 'number'.
          onSelect={() => updateRowAtIndex(dataIndex, (draftExchangeDef) => ({
            ...draftExchangeDef,
            ...cloneDeep(omit(exchangeDef.liveVersion, ['publishedAt', 'publisherId'])) as ExchangeDefinition,
          }))}
        >
          {t('general.discardChanges')}
        </GridMenuItem>
      )}

      {canChangeObsoleteState && draftExchangeDef.isObsolete && (
        <GridMenuItem
          icon="undo"
          // @ts-expect-error ts(2345) FIXME: Argument of type 'number | null' is not assignable to parameter of type 'number'.
          onSelect={() => updateRowAtIndex(dataIndex, makeNonObsolete)}
        >
          {t('general.makeNonObsolete')}
        </GridMenuItem>
      )}
    </GridMenu>
  ) : (
    null
  );
};
