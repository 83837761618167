import { uniqueId } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass/styled-components';
import { Attachment } from '@deepstream/common/rfq-utils';

import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { FilesFieldBase } from '../../form/FilesField';
import { useToaster } from '../../toast';
import { Input, TextArea } from '../../ui/Input';
import { useThreadPost } from './hooks';

export const AddThread = ({
  rfqId,
}: {
  rfqId: string;
}) => {
  const { t } = useTranslation('request');
  const [thread, setThread] = useState<{
    subject: string;
    message:string;
    attachments: Attachment[];
  }>({
    subject: '',
    message: '',
    attachments: [],
  });
  const isValid = thread.subject && thread.message;

  const toaster = useToaster();
  const [postThread, { isLoading }] = useThreadPost();

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: 'small',
        border: 'lightGray2',
        marginBottom: '20px',
      }}
    >
      <Box p="20px">
        <Input
          disabled={isLoading}
          sx={{
            fontSize: 4,
            fontWeight: 500,
            '::placeholder': {
              fontSize: 4,
              fontWeight: 500,
            },
          }}
          style={{
            background: 'transparent',
            border: 0,
            paddingTop: 0,
            paddingBottom: 0,
            marginBottom: 1,
          }}
          value={thread.subject}
          onChange={(event) => {
            setThread((prev) => ({
              ...prev,
              subject: event.target.value,
            }));
          }}
          placeholder={t('comments.addThread.subjectPlaceholder')}
        />
        <TextArea
          disabled={isLoading}
          style={{
            background: 'transparent',
            border: 0,
          }}
          value={thread.message}
          onChange={(event) => {
            setThread((prev) => ({
              ...prev,
              message: event.target.value,
            }));
          }}
          rows={3}
          placeholder={t('comments.addThread.messagePlaceholder')}
        />
      </Box>
      <Flex
        sx={{
          borderTop: 'lightGray2',
          padding: '20px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            maxWidth: 400,
            width: '100%',
          }}
        >
          <FilesFieldBase
            // Reset field values
            key={uniqueId('attachments-file-base')}
            disabled={isLoading}
            purpose="comment"
            truncateFileName
            value={thread.attachments}
            onChange={(attachments) => {
              setThread((prev) => ({
                ...prev,
                attachments,
              }));
            }}
          />
        </Box>
        <Button
          disabled={isLoading || !isValid}
          onClick={async () => {
            try {
              await postThread(rfqId, thread.subject, thread.message, thread.attachments);
              setThread({ subject: '', message: '', attachments: [] });
            } catch (e) {
              toaster.error(
                t('comments.addThread.postError', { subject: thread.subject }),
              );
            }
          }}
        >
          {t('comments.addThread.buttonLabel')}
        </Button>
      </Flex>
    </Box>
  );
};
