import {
  FieldConfig,
  LineItemExchangeFields,
  getFormattedFieldLabel,
  getFormattedFormula,
} from '@deepstream/common/rfq-utils';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useGetFormattedFieldLabel = () => {
  const { t } = useTranslation();

  return useCallback((field: FieldConfig) => {
    return getFormattedFieldLabel(field, t);
  }, [t]);
};

export const useFormattedTotalCostFormula = (fields?: LineItemExchangeFields) => {
  const { t } = useTranslation();

  return useMemo(() => {
    return getFormattedFormula(fields, 'totalCost', t);
  }, [fields, t]);
};

export const useGetFormattedFormula = (fields) => {
  const { t } = useTranslation();

  return useCallback((fieldId: string) => {
    return getFormattedFormula(fields, fieldId, t);
  }, [fields, t]);
};
