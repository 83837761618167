import { useState } from 'react';
import { first, isNumber, map, set, find } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Form, Formik, useField } from 'formik';
import * as yup from 'yup';
import { Flex } from 'rebass/styled-components';
import {
  isShortTextQuestion,
  QuestionExchangeDefinition,
  QuestionSection,
  Draft,
  ExchangeDefinition,
  isSectionLinkedFromEvaluation,
} from '@deepstream/common/rfq-utils';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton, ButtonProps, Button } from '@deepstream/ui-kit/elements/button/Button';
import { PanelDivider, PanelPadding, ExpandablePanelSubSection, PanelSubHeader } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import * as draft from './draft';
import * as rfx from '../rfx';
import { useStageName } from './useStageName';
import { useStageSelectItems } from './useStageSelectItems';
import { useModalState } from '../ui/useModalState';
import { SelectFieldBase } from '../form/SelectField';
import { DraftPanel } from './DraftPanel';
import { usePreventEnterKeyHandler } from '../usePreventEnterKeyHandler';
import { EditQuestions } from './EditQuestionPanel';
import { ModelSizeLimitDialog, ModelSizeLimitMessages } from '../ModelSizeLimitDialog';
import { getSizeRelevantExchangeDefCount, useModelSizeLimits } from '../modelSizeLimits';
import { SectionConfigHeading } from './SectionConfigHeading';
import { QuestionSectionConfigIndicators } from './QuestionSectionConfigIndicators';
import { DraftSectionLabelConfigProvider } from './DraftSectionLabelConfigProvider';
import { LockField } from './LockField';
import { DraftSectionEditPanelHeader } from './DraftSectionEditPanelHeader';
import { createQuestion, useExchangeDefsField } from './exchangeDefs';
import { useSystemFeatureFlags } from '../systemFeatureFlags';
import { useToaster } from '../toast';
import { BulkUploadQuestionsModal } from './BulkUploadQuestionsModal';
import { AddQuestionButton } from './AddQuestionButton';
import { LeavePageModal } from './LeavePageModal';
import { NO_LOT_PROXY_ID, useLotSelectItems } from './useLotSelectItems';
import { SectionConfigLotSelectField } from './SectionConfigLotSelectField';
import { useHandleQuestionSectionLotChange } from './useHandleQuestionSectionLotChange';

const sanitizeQuestionExchangeDefs = (exchangeDefs: QuestionExchangeDefinition<Draft>[]) =>
  exchangeDefs.map(exchangeDef => {
    // When a short text question exchange definition has `min` and `max`
    // specified but `min` is larger than `max`, flip the values.
    if (
      isShortTextQuestion(exchangeDef) &&
      exchangeDef.schema &&
      isNumber(exchangeDef.schema.min) &&
      isNumber(exchangeDef.schema.max) &&
      exchangeDef.schema.min > exchangeDef.schema.max
    ) {
      return set(exchangeDef, 'schema', {
        ...exchangeDef.schema,
        min: exchangeDef.schema.max,
        max: exchangeDef.schema.min,
      });
    } else {
      return exchangeDef;
    }
  });

const CollapsedEditSectionConfig = () => {
  const { settings } = rfx.useStructure<Draft>();
  const [{ value: stageIds }] = useField({ name: 'section.stages' });
  const [{ value: locking }] = useField('section.locking');
  const [{ value: lotIds }] = useField<string[] | null>('section.lotIds');
  const stageId = first<string>(stageIds);
  const stageName = useStageName(stageId);

  const lotSelectItems = useLotSelectItems();
  const selectedLot = settings.areLotsEnabled
    ? find(lotSelectItems, { value: first(lotIds) || NO_LOT_PROXY_ID })
    : null;

  return (
    <QuestionSectionConfigIndicators
      isSender
      // @ts-expect-error ts(2322) FIXME: Type 'string | number | null' is not assignable to type 'string | number | undefined'.
      stageName={stageName}
      locking={locking}
      // @ts-expect-error ts(2322) FIXME: Type 'LotSelectItem | null | undefined' is not assignable to type 'LotSelectItem | undefined'.
      selectedLot={selectedLot}
    />
  );
};

const ExpandedEditSectionConfig = () => {
  const { t } = useTranslation('translation');
  const { settings } = rfx.useStructure<Draft>();
  const [{ value: stageIds }, , stagesFormik] = useField({ name: 'section.stages' });
  const [{ value: isSectionObsolete }] = useField<boolean | null>('section.isObsolete');
  const [{ value: exchangeDefs }, , exchangeDefsFormik] = useField({ name: 'exchangeDefs' });
  const stageSelectItems = useStageSelectItems();
  const stageId = first<string>(stageIds);
  const section = rfx.useSection<QuestionSection>();
  const senders = rfx.useSenders();
  const allStages = rfx.useStages();
  const handleLotChange = useHandleQuestionSectionLotChange();

  return (
    <DraftSectionLabelConfigProvider>
      {settings.areLotsEnabled && (
        <>
          <PanelPadding>
            <SectionConfigLotSelectField
              name="section.lotIds"
              canSelectObsoleteItem={section.isLive}
              onChange={handleLotChange}
            />
          </PanelPadding>
          {!isSectionObsolete && (
            <PanelDivider />
          )}
        </>
      )}
      {!isSectionObsolete && stageSelectItems.length > 1 ? (
        <>
          <PanelPadding>
            <SelectFieldBase
              label={t('request.visibleFromStage')}
              description={t('request.visibleFromStageDescription')}
              items={stageSelectItems}
              value={stageId}
              disabled={section.isLive}
              onChange={stageId => {
                const allStageIds = map(allStages, '_id');
                const indexFrom = allStageIds.indexOf(stageId);
                const newStages = allStageIds.slice(indexFrom);

                const newExchangeDefs = map(
                  exchangeDefs,
                  exchangeDef => ({
                    ...exchangeDef,
                    stages: newStages,
                  }),
                );

                stagesFormik.setValue(newStages);
                exchangeDefsFormik.setValue(newExchangeDefs);
              }}
              buttonStyle={{ fontWeight: 500, maxWidth: 200 }}
            />
          </PanelPadding>
          <PanelDivider />
        </>
      ) : (
        null
      )}
      {!isSectionObsolete && (
        <PanelPadding>
          <LockField
            name="section.locking"
            label={t('request.lockResponses')}
            description={t('request.lockResponsesDescription')}
            senders={senders}
            isSectionLive={section.isLive}
          />
        </PanelPadding>
      )}
    </DraftSectionLabelConfigProvider>
  );
};

const EditSectionConfig = () => {
  return (
    <ExpandablePanelSubSection
      heading={<SectionConfigHeading />}
      renderCollapsedContent={() => <CollapsedEditSectionConfig />}
      renderExpandedContent={() => <ExpandedEditSectionConfig />}
    />
  );
};

const BulkUploadButton = ({
  onImport,
  ...props
}: ButtonProps & { onImport: (questions: Partial<QuestionExchangeDefinition<'draft'>>[]) => void }) => {
  const { isOpen, open, close } = useModalState();
  const { t } = useTranslation();
  const toaster = useToaster();

  const handleOnImport = (questions: Partial<QuestionExchangeDefinition<'draft'>>[]) => {
    onImport(questions);
    toaster.success(t('request.question.bulkUpload.success'));
    close();
  };

  return (
    <>
      <Button small variant="secondary-transparent-outline" iconLeft="upload" onClick={open} {...props}>
        {t('request.lineItems.importFromCsv')}
      </Button>

      {isOpen && (
        <BulkUploadQuestionsModal
          isOpen={isOpen}
          onCancel={close}
          onImport={handleOnImport}
        />
      )}
    </>
  );
};

const QuestionSectionEditButtons = ({ fieldName = 'exchangeDefs' }: { fieldName?: string }) => {
  const systemFeatureFlags = useSystemFeatureFlags();

  const { addExchangeDef, addExchangeDefs } = useExchangeDefsField(
    fieldName,
    createQuestion,
  );

  return (
    <>
      {/*
       // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'. */}
      {systemFeatureFlags.bulkImportEnabled && (
        <BulkUploadButton
          type="button"
          onImport={questions => addExchangeDefs(questions.map(question => ({ ...createQuestion(), ...question })))}
          mr={2}
        />
      )}
      <AddQuestionButton
        type="button"
        onClick={() => addExchangeDef()}
      />
    </>
  );
};

/**
 * Displays an editable panel for the current questions section
 */
export const QuestionSectionEditPanel = () => {
  const { t } = useTranslation();
  const { stopEditing } = rfx.useActions();
  const { sectionById, exchangeDefById, lotById } = rfx.useStructure<Draft>();
  const section = rfx.useSectionWithPosition<QuestionSection>();
  const initialExchangeDefs = rfx.useSectionExchangeDefs() as QuestionExchangeDefinition[];
  const [saveSection] = draft.useSaveQuestionSection();
  const onKeyDown = usePreventEnterKeyHandler();
  const { maxExchangeDefCount } = useModelSizeLimits();
  const modelSizeLimitModal = useModalState();
  const [modelSizeLimitMessages, setModelSizeLimitMessages] = useState<ModelSizeLimitMessages | null>(null);

  const handleSubmit = async ({ section, exchangeDefs: newExchangeDefs }, { setSubmitting }) => {
    const totalExchangeDefCount = getSizeRelevantExchangeDefCount(exchangeDefById);
    const previousSectionExchangeDefCount = getSizeRelevantExchangeDefCount(initialExchangeDefs);
    const newSectionExchangeDefCount = getSizeRelevantExchangeDefCount(newExchangeDefs);
    const addedCount = newSectionExchangeDefCount - previousSectionExchangeDefCount;
    const factor = isSectionLinkedFromEvaluation(section, sectionById) ? 2 : 1;

    if (
      newSectionExchangeDefCount > previousSectionExchangeDefCount &&
      totalExchangeDefCount + (addedCount * factor) > maxExchangeDefCount
    ) {
      setModelSizeLimitMessages({
        heading: t('request.dialog.requestSizeLimit.heading'),
        title: t('request.dialog.requestSizeLimit.saveQuestionsSection.title'),
        warning: t('request.dialog.requestSizeLimit.saveQuestionsSection.warning'),
        body: t('request.dialog.requestSizeLimit.saveQuestionsSection.body', {
          count: totalExchangeDefCount + (addedCount * factor) - maxExchangeDefCount,
        }),
      });
      modelSizeLimitModal.open();
      setSubmitting(false);
    } else {
      await saveSection({
        section,
        exchangeDefs: map(
          sanitizeQuestionExchangeDefs(newExchangeDefs),
          exchangeDef => set(exchangeDef, 'stages', section.stages),
        ) as ExchangeDefinition<'live'>[],
      }, {
        onSuccess: stopEditing,
        onError: () => setSubmitting(false),
      });
    }
  };

  return (
    <>
      <Formik<{
        section: QuestionSection;
        exchangeDefs: QuestionExchangeDefinition[];
      }>
        validateOnBlur
        enableReinitialize
        initialValues={{
          section,
          exchangeDefs: initialExchangeDefs,
        }}
        validationSchema={
          yup.object().shape({
            section: yup.object().shape({
              name: yup.string(),
              description: yup.string(),
            }),
            exchangeDefs: yup.array().of(
              yup.object(),
            ),
          })
        }
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, isValid, dirty, resetForm, values }) => {
          const isSectionObsolete = values.section.isObsolete;

          return (
            <Form style={{ width: '100%' }} onKeyDown={onKeyDown}>
              <DraftPanel panelId={section._id}>
                <DraftSectionEditPanelHeader icon="question" isSectionObsolete={isSectionObsolete} />
                <PanelDivider />
                {isSectionObsolete && (
                  <>
                    <MessageBlock variant="info" m="20px">
                      {t('request.sections.obsoleteSectionInfo')}
                    </MessageBlock>
                    <PanelDivider />
                  </>
                )}
                <EditSectionConfig />
                <PanelDivider />
                {!isSectionObsolete && (
                  <>
                    <PanelSubHeader height="52px">
                      <QuestionSectionEditButtons />
                    </PanelSubHeader>
                    <PanelDivider />
                  </>
                )}
                <PanelPadding>
                  <EditQuestions isSectionObsolete={isSectionObsolete} />
                </PanelPadding>
                <PanelDivider />
                <PanelPadding>
                  <Flex justifyContent="flex-end">
                    <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
                    <SaveButton disabled={isSubmitting || !dirty || !isValid} />
                  </Flex>
                </PanelPadding>
              </DraftPanel>
              <LeavePageModal />
            </Form>
          );
        }}
      </Formik>
      <ModelSizeLimitDialog
        modal={modelSizeLimitModal}
        messages={modelSizeLimitMessages}
      />
    </>
  );
};
