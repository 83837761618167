import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmActionDialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { useConfirmDialog } from '../../../ui/useModalState';
import { useUpdateQuestionnaire } from './questionnaireMutations';

export const RecipientQuestionnaireActions = () => {
  const { t } = useTranslation(['preQualification']);
  const { confirm: confirmUpdate, ...confirmUpdateDialogProps } = useConfirmDialog();
  const [updateQuestionnaire] = useUpdateQuestionnaire();

  const onUpdate = useCallback(
    () => confirmUpdate(updateQuestionnaire),
    [updateQuestionnaire, confirmUpdate],
  );

  return (
    <>
      <DropdownMenu
        variant="secondary"
        menuZIndex={101}
        buttonText={t('questionnaire.actions')}
        iconRight="caret-down"
      >
        <DropdownMenuItem icon="pencil" onSelect={onUpdate}>
          {t('questionnaire.update')}
        </DropdownMenuItem>
      </DropdownMenu>
      <ConfirmActionDialog
        heading={t('questionnaire.confirmUpdateHeader')}
        variant="info"
        message={t('questionnaire.confirmUpdateMessage')}
        okButtonText={t('confirm', { ns: 'general' })}
        okButtonVariant="primary"
        {...confirmUpdateDialogProps}
      />
    </>
  );
};
