import { useMemo } from 'react';
import { map, propertyOf } from 'lodash';
import { ExchangeSwitcherTarget } from '../../../ExchangeModal/SwitchToExchange';
import { useQuestionnaireData } from './questionnaireUtils';

/**
 * Returns exchangeId of all exchanges to which users can
 * navigate from an exchange modal.
 * The order of the returned objects matches the questions page.
 */
export const useExchangeSwitcherTargets = (): ExchangeSwitcherTarget[] => {
  const questionnaire = useQuestionnaireData();

  return useMemo(() => {
    const exchangeDefs = questionnaire.exchangeDefSequence
      .map(propertyOf(questionnaire.exchangeDefById));

    return map(
      exchangeDefs,
      exchangeDef => ({
        exchangeId: exchangeDef._id,
      }),
    );
  }, [questionnaire]);
};
