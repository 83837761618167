import { useContext } from 'react';
import { Flex } from 'rebass/styled-components';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';
import * as React from 'react';
import { BulkSubmitContext } from './BulkSubmitContext';
import { convertToBatchedExchangeReplyPayload, useSendBatchedExchangeReply } from '../../ExchangeModal/useSendBatchedExchangeReply';
import { useRfqId } from '../../useRfq';
import { useCurrentCompanyId } from '../../currentCompanyId';

export const BulkSubmitBottomBar = () => {
  const { lineItems } = useContext(BulkSubmitContext);
  const rfqId = useRfqId();
  const recipientId = useCurrentCompanyId();
  const [sendBatchedExchangeReply] = useSendBatchedExchangeReply({
    rfqId,
    recipientId: recipientId || '',
  });

  const handleSubmitClick = async () => {
    if (lineItems) {
      await sendBatchedExchangeReply(
        convertToBatchedExchangeReplyPayload(lineItems, recipientId || '', 'app'),
      );
    }
  };

  return (
    <Flex px="140px" py={3} alignItems="center" justifyContent="space-between" sx={{ gap: 2 }} bg="white" height="100%">
      <ButtonGroup marginBetween="-1px">
        <Button iconLeft="chevron-up" variant="secondary-outline" small />
        <Button iconLeft="chevron-down" variant="secondary-outline" small />
      </ButtonGroup>

      <Button variant="primary" onClick={handleSubmitClick}>
        Submit responses
      </Button>
    </Flex>
  );
};
