import { useCallback } from 'react';
import { identity, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Box } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { scrollToTop } from '@deepstream/ui-utils/scrollToTop';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { useMultiStepFlowData } from './MultiStepFlowContext';
import { Direction } from './types';

const StyledLink = styled.a`
  color: ${props => props.theme.colors.text};
  white-space: nowrap;
  cursor: pointer;
  text-decoration: underline;
  font-size: ${props => props.theme.fontSizes[2]}px;
  font-weight: 500;
  line-height: 1.3;

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;

export const StepNavigationBackLink = ({
  onClick,
}: {
  onClick: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <StyledLink onClick={onClick} tabIndex={0}>
        <Icon icon="chevron-left" mr="2px" fontWeight={400} />
        {t('back', { ns: 'general' })}
      </StyledLink>
    </Box>
  );
};

/**
 * @deprecated Use StepNavigation2 instead
 */
export const StepNavigation = ({
  continueButtonText,
  onBackClick,
  onContinueClick,
  children,
}: {
  continueButtonText?: string;
  onBackClick?: () => void;
  onContinueClick?: () => void;
  children: React.ReactNode;
}) => {
  const { t } = useTranslation(['translation', 'general']);

  return (
    <>
      {onBackClick && <StepNavigationBackLink onClick={onBackClick} />}
      {children}
      <Box>
        {onContinueClick && (
          <Button onClick={onContinueClick}>
            {continueButtonText || t('continue', { ns: 'general' })}
          </Button>
        )}
      </Box>
    </>
  );
};

// TODO: Replace StepNavigation with StepNavigation2
export const StepNavigation2 = <TStepType, TData, TValues = any>({
  continueButtonText,
  showBackButton = true,
  showContinueButton,
  mapValues = identity,
  children,
}: {
  continueButtonText?: string;
  showBackButton?: boolean;
  showContinueButton?: boolean;
  mapValues?: (values: TValues) => Partial<TData>;
  children: React.ReactNode;
}) => {
  const { t } = useTranslation(['translation', 'general']);
  const { submitAndNavigate } = useMultiStepFlowData<TStepType, TData>();
  const formikContext = useFormikContext<TValues>();

  const onBackClick = useCallback(
    () => formikContext
      ? submitAndNavigate(formikContext.dirty ? mapValues(formikContext.values) : null, Direction.BACK)
      : submitAndNavigate(null, Direction.BACK),
    [formikContext, submitAndNavigate, mapValues],
  );

  const onContinueClick = useCallback(
    async () => {
      if (formikContext) {
        const { values, dirty, validateForm, submitForm } = formikContext;

        const errors = await validateForm();

        await submitForm();

        if (isEmpty(errors)) {
          submitAndNavigate(dirty ? mapValues(values) as any : null, Direction.FORWARD);
        } else {
          scrollToTop('smooth');
        }
      } else {
        submitAndNavigate(null, Direction.FORWARD);
      }
    },
    [formikContext, submitAndNavigate, mapValues],
  );

  return (
    <>
      {showBackButton && <StepNavigationBackLink onClick={onBackClick} />}
      {children}
      <Box>
        {showContinueButton && (
          <Button onClick={onContinueClick}>
            {continueButtonText || t('continue', { ns: 'general' })}
          </Button>
        )}
      </Box>
    </>
  );
};
