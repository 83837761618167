import { Form, Formik } from 'formik';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { noop } from 'lodash';
import { Text } from 'rebass/styled-components';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { ListConfigFlowData, ListConfigFlowStepType } from '../types';
import * as layout from '../../../Request/Live/lotPagesLayout';
import { TextField } from '../../../../form/TextField';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { StepNavigation2 } from '../../../../ui/MultiStepFlow/StepNavigation';
import { Disclosure2 } from '../../../../ui/Disclosure';
import { useValidateUniqueName } from '../../useValidateUniqueName';
import { useMultiStepFlowData } from '../../../../ui/MultiStepFlow/MultiStepFlowContext';

type FormValues = {
  name: string;
  description: string;
};

const mapValues = (values: FormValues) => ({
  name: values.name.trim(),
  description: values.description?.trim(),
});

export const NameAndDescriptionStep = () => {
  const { t } = useTranslation();
  const { data } = useMultiStepFlowData<ListConfigFlowStepType, ListConfigFlowData>();
  const validate = useValidateUniqueName({ listId: data._id });

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      name: z
        .string({ message: t('supplierLists.configFlow.errors.requiredListName') })
        .trim()
        .min(1, { message: t('supplierLists.configFlow.errors.requiredListName') }),
      description: z.string().optional(),
    });

    return toFormikValidationSchema(Schema);
  }, [t]);

  return (
    <Formik<FormValues>
      validateOnBlur
      initialValues={{
        name: data.name,
        description: data.description,
      }}
      validationSchema={validationSchema}
      onSubmit={noop}
      validate={validate}
    >
      {() => (
        <Form>
          <layout.ContentWrapper2>
            <StepNavigation2<ListConfigFlowStepType, ListConfigFlowData>
              showContinueButton
              mapValues={mapValues}
            >
              <FormErrors />
              <layout.Section2
                heading={data._id
                  ? t('supplierLists.configFlow.editTheListName')
                  : t('supplierLists.configFlow.provideAListName')}
              >
                <TextField
                  name="name"
                  errorMessageStyle={{ fontSize: '14px', marginTop: '16px' }}
                />
              </layout.Section2>
              <layout.Section2
                heading={data._id
                  ? t('supplierLists.configFlow.editTheListDescription')
                  : t('supplierLists.configFlow.provideAListDescription')}
              >
                <TextField
                  name="description"
                  helperText={(
                    <IconText
                      icon="info-circle"
                      isIconRegular
                      text={t('supplierLists.configFlow.descriptionInfo')}
                      alignItems="start"
                      iconSx={{ position: 'relative', top: '3px' }}
                    />
                  )}
                  isMultiLine
                />
              </layout.Section2>
              {!data._id && (
                <Disclosure2
                  width="100%"
                  maxWidth="542px"
                  summary={t('supplierLists.configFlow.listsDisclosure.summary')}
                >
                  <Stack gap={2}>
                    <Text>{t('supplierLists.configFlow.listsDisclosure.p1')}</Text>
                    <Text>{t('supplierLists.configFlow.listsDisclosure.p2')}</Text>
                    <Text>{t('supplierLists.configFlow.listsDisclosure.p3')}</Text>
                  </Stack>
                </Disclosure2>
              )}
            </StepNavigation2>
          </layout.ContentWrapper2>
        </Form>
      )}
    </Formik>
  );
};
