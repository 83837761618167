import { Box, Flex, Text } from 'rebass/styled-components';
import { includes } from 'lodash';
import { AccordionItem, AccordionPanel, AccordionButton } from '@reach/accordion';
import { useTranslation } from 'react-i18next';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { PreQualQuestion } from '../../../../../types';
import { useImportQuestionsContext } from '../../ImportQuestionsProvider';
import { QuestionPreview } from '../../../QuestionPreview/QuestionPreview';

export const QuestionEntry = ({
  question,
  isExpanded,
}: {
  question: PreQualQuestion;
  isExpanded: boolean;
}) => {
  const { t } = useTranslation();
  const { toggleQuestions, selectedQuestionExchangeDefIds, initialExchangeDefIds } = useImportQuestionsContext();

  const { exchangeDef } = question.currentVersion;

  return (
    <AccordionItem
      as={Box}
      sx={{ overflow: 'auto', borderBottom: 'lightGray2' }}
    >
      <Flex sx={{ padding: '15px 30px', alignItems: 'center' }}>
        <Checkbox
          style={{ paddingRight: '8px' }}
          disabled={includes(initialExchangeDefIds, exchangeDef._id)}
          checked={includes(selectedQuestionExchangeDefIds, exchangeDef._id) || includes(initialExchangeDefIds, exchangeDef._id)}
          onChange={() => toggleQuestions([exchangeDef._id])}
        />
        <Truncate sx={{ flex: '1 1 auto' }}>
          <Text>{exchangeDef.description}</Text>
        </Truncate>

        <Truncate sx={{ marginLeft: '8px', flex: '0 0 130px' }}>
          <Text>{t(`request.question.questionType.${exchangeDef.questionType}`)}</Text>
        </Truncate>

        <AccordionButton
          as={Box}
          sx={{ marginLeft: 'auto', cursor: 'pointer', px: 2 }}
        >
          <Icon icon={isExpanded ? 'chevron-up' : 'chevron-down'} color="gray" />
        </AccordionButton>
      </Flex>

      <AccordionPanel as={Box} sx={{ padding: '0 45px 15px' }}>
        <QuestionPreview exchangeDef={question.currentVersion.exchangeDef} />
      </AccordionPanel>
    </AccordionItem>
  );
};
