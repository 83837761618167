import { groupBy, mapValues } from 'lodash';

export const groupBy2d = <
  TItem,
  TGetGroup1 extends (item: TItem) => string | number,
  TGetGroup2 extends (item: TItem) => string | number,
>(
  array: TItem[],
  getGroup1: TGetGroup1,
  getGroup2: TGetGroup2,
): Record<ReturnType<TGetGroup1>, Record<ReturnType<TGetGroup2>, TItem[]>> => {
  const group1 = groupBy(array, getGroup1);
  const group2 = mapValues(group1, items => groupBy(items, getGroup2));
  return group2 as any;
};
