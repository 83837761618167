import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { useTranslation } from 'react-i18next';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { ExchangeSnapshot } from '../../types';
import { Bold } from '../../Bold';
import { useIsSender } from './contract';

export const VerifiedSignersCell = ({ row, side }: CellProps<ExchangeSnapshot> & { side: 'sender' | 'recipient' }) => {
  const { t } = useTranslation(['contracts']);
  const isSender = useIsSender();

  const exchange = row.original;

  const signers = useMemo(
    () => side === 'sender'
      // @ts-expect-error ts(18049) FIXME: 'exchange.verified' is possibly 'null' or 'undefined'.
      ? exchange.verified.senderSigners
      // @ts-expect-error ts(18049) FIXME: 'exchange.verified' is possibly 'null' or 'undefined'.
      : exchange.verified.recipientSigners,
    [exchange, side],
  );

  const showStatus = side === 'sender'
    ? exchange.isAwaitingCounterESignature || exchange.isAwaitingApproval || exchange.isResolved
    // @ts-expect-error ts(18049) FIXME: 'exchange.verified' is possibly 'null' or 'undefined'.
    : Boolean(exchange.verified.envelopeId);
  const isSigningLocked = isSender && side === 'sender' && exchange.isCountersigningDisabled;

  return (
    <table width="100%">
      <tr>
        <th><Bold>{t('signature.signer.name')}</Bold></th>
        <th>
          {showStatus && <Bold>{t('signature.signer.status')}</Bold>}
        </th>
      </tr>
      {/*
       // @ts-expect-error ts(18048) FIXME: 'signers' is possibly 'undefined'. */}
      {signers.map((signer) => (
        <tr key={signer._id}>
          <td width="50%">
            {signer.name}
          </td>
          <td width="50%">
            {showStatus && (
              <IconText
                icon={isSigningLocked
                  ? 'lock'
                  : signer.hasSigned
                    ? 'circle-check'
                    : 'spinner'
                }
                iconColor={signer.hasSigned ? 'success' : 'text'}
                text={isSigningLocked
                  ? t('signature.locked')
                  : signer.hasSigned
                    ? t('signature.signed')
                    : t('signature.awaitingSignature')
                }
              />
            )}
          </td>
        </tr>
      ))}
    </table>
  );
};
