import * as React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Flex, Box } from 'rebass/styled-components';

export const FixedToolbar: React.FC<any> = ({ isOpen, children, height = 65, ...props }) => (
  <AnimatePresence>
    {isOpen && (
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 12, // to hide help button in angular client
        }}
      >
        <motion.div
          initial={{ y: '100%' }}
          animate={{ y: 0 }}
          exit={{ y: '100%' }}
          transition={{ ease: 'backInOut', duration: 0.5 }}
        >
          <Box
            key="background"
            width="100%"
            backgroundColor="white"
            height={height}
            sx={{
              boxShadow: `0px ${height}px 0px 0px white`,
            }}
          >
            <Flex
              alignItems="center"
              justifyContent="flex-end"
              sx={{
                height,
                borderTopWidth: 2,
                borderTopStyle: 'solid',
                borderTopColor: 'primary',
                backgroundColor: 'white',
              }}
              {...props}
            >
              {children}
            </Flex>
          </Box>
        </motion.div>
      </Box>
    )}
  </AnimatePresence>
);
