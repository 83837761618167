import { useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { isEmpty, noop } from 'lodash';
import { Form, Formik } from 'formik';
import { Live } from '@deepstream/common/rfq-utils';
import { useMemo } from 'react';
import * as rfx from '../../../../rfx';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData } from '../types';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { MoneyField } from '../../../../form/MoneyField';
import { CurrencyCodeProvider } from '../../../../ui/Currency';
import { Direction } from '../../../../ui/MultiStepFlow/types';

export const EditBudgetStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (data: Partial<AwardFlowData> | null, direction: Direction) => void,
}) => {
  const { t } = useTranslation('translation');
  const { currencyCode } = rfx.useStructure<Live>();

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      budgetedTotalValue: z
        .number({ message: t('request.awardFlow.errors.enterBudgetedTotalValue') })
        .gt(0, { message: t('request.awardFlow.errors.enterBudgetedTotalValue') }),
    });

    return toFormikValidationSchema(Schema);
  }, [t]);

  return (
    <Formik
      validateOnBlur
      initialValues={data.spendAndSavings}
      validationSchema={validationSchema}
      onSubmit={noop}
    >
      {({ values, submitForm, validateForm, dirty }) => (
        <Form>
          <lotPagesLayout.ContentWrapper>
            <StepNavigation
              onBackClick={() => submitAndNavigate(dirty ? { spendAndSavings: values } : null, Direction.BACK)}
              onContinueClick={async () => {
                const errors = await validateForm();

                await submitForm();

                if (isEmpty(errors)) {
                  submitAndNavigate(dirty ? { spendAndSavings: values } : null, Direction.FORWARD);
                }
              }}
            >
              <FormErrors />
              <lotPagesLayout.Section
                heading={t('request.awardFlow.steps.editBudget.heading')}
              >
                <Box mt="20px">
                  <CurrencyCodeProvider code={currencyCode}>
                    <MoneyField
                      required
                      name="budgetedTotalValue"
                      sx={{ width: 200 }}
                      // quick fix: we can remove setting the inputStyle height once a line height is
                      // set for Input
                      inputStyle={{ height: 40 }}
                      errorMessageStyle={{ fontSize: '14px', marginTop: '16px' }}
                    />
                  </CurrencyCodeProvider>
                </Box>
                <lotPagesLayout.InfoText mt="30px">
                  {t('request.awardFlow.steps.editBudget.info')}
                </lotPagesLayout.InfoText>
              </lotPagesLayout.Section>
            </StepNavigation>
          </lotPagesLayout.ContentWrapper>
        </Form>
      )}
    </Formik>
  );
};
