import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { QuestionnaireOverview, questionnaireStatusConfig } from '@deepstream/common/preQual';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { Bold } from '../../../Bold';

const questionnaireStatuses = Object.keys(questionnaireStatusConfig);

export const SuppliersStatsPanel = ({ questionnaires }: { questionnaires: QuestionnaireOverview[] }) => {
  const { t } = useTranslation('preQualification');

  const totalSuppliers = questionnaires?.length;

  const statusCountMap = useMemo(
    () => questionnaires
      .reduce((acc, { status }) => {
        acc[status] = (acc[status] || 0) + 1;
        return acc;
      }, {}),
    [questionnaires],
  );

  const stats = useMemo(
    () => questionnaireStatuses
      .map(
        status => ({
          icon: questionnaireStatusConfig[status].icon,
          name: t(`questionnaireStatus.${status}`),
          value: statusCountMap[status],
        }),
      ),
    [statusCountMap, t],
  );

  return (
    <Panel padded>
      <Flex justifyContent="space-between">
        <Box sx={{ textAlign: 'center' }}>
          <Text fontSize={1} fontWeight={500} color="navy" mb={1}>
            {t('questionnaireSuppliers.total')}
          </Text>
          <Flex alignItems="center" fontSize={2}>
            <Icon fixedWidth icon="person" mr={1} />
            <Bold color="navy">
              {totalSuppliers ?? 0}
            </Bold>
          </Flex>
        </Box>
        <Flex sx={{ columnGap: '24px' }}>
          {stats.map(stat => (
            <Box key={stat.name} sx={{ textAlign: 'center' }}>
              <Text fontSize={1} fontWeight={500} color="navy" mb={1}>
                {stat.name}
              </Text>
              <Flex alignItems="center" fontSize={2}>
                <Icon fixedWidth icon={stat.icon.value} color={stat.icon.color} mr={1} />
                <Bold color="navy">
                  {stat.value ?? 0}
                </Bold>
              </Flex>
            </Box>
          ))}
        </Flex>
      </Flex>
    </Panel>
  );
};
