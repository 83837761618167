import { noop } from 'lodash';
import { useEffect } from 'react';
import * as React from 'react';
import { useQuery } from 'react-query';
import { useCurrentUser } from './useCurrentUser';
import { useApi, wrap } from './api';
import { useParams } from './tanstackRouter';
import { useEnv } from './env';
import { CurrentCompanyIdContext, useCurrentCompanyId } from './currentCompanyId';
import { LoadingPanel } from './ui/Loading';

const SwitchCompanyContext = React.createContext<(companyId: string) => void>(noop);

const LOCAL_STORAGE_CURRENT_COMPANY_ID_KEY = 'ekCurrentCompanyId';

const currentCompanyIdCache = {
  get: () => localStorage.getItem(LOCAL_STORAGE_CURRENT_COMPANY_ID_KEY),
  set: (companyId: string) => localStorage.setItem(LOCAL_STORAGE_CURRENT_COMPANY_ID_KEY, companyId),
  clear: () => localStorage.removeItem(LOCAL_STORAGE_CURRENT_COMPANY_ID_KEY),
};

export const CurrentCompanyIdProvider = ({
  onSwitchCompanySuccess,
  onSwitchCompanyFailure,
  children,
}: {
  onSwitchCompanySuccess: ({ _id, isPending }: { _id: string; isPending: boolean }, isInitial: boolean) => void;
  onSwitchCompanyFailure: () => void;
  children: React.ReactNode;
}) => {
  const { ONBOARDING_URL } = useEnv();
  const api = useApi();
  const currentUser = useCurrentUser();
  const [currentCompanyId, setCurrentCompanyId] = React.useState(null);
  const [targetCompanyId, setTargetCompanyId] = React.useState(null);

  const routeParams = useParams({
    strict: false,
  });

  const switchCompany = React.useCallback(
    (companyId: string) => {
      const companyRole = currentUser
        .companyRoles
        .filter(companyRole => !companyRole.hasSentRequest)
        .find(companyRole => companyRole._id === companyId);

      if (companyRole) {
        // @ts-expect-error ts(2345) FIXME: Argument of type 'string' is not assignable to parameter of type 'SetStateAction<null>'.
        setTargetCompanyId(companyId);
      } else {
        if (currentCompanyIdCache.get() === companyId) {
          currentCompanyIdCache.clear();
        }

        onSwitchCompanyFailure(); // Call error handler if no company role exists
      }
    },
    [currentUser, onSwitchCompanyFailure],
  );

  // This effect attempts to determine the initial current company id and set it as the `targetCompanyId`
  useEffect(
    () => {
      if (currentUser && !targetCompanyId) {
        const initialCurrentCompanyId = (
          routeParams.currentCompanyId ||
          currentCompanyIdCache.get() ||
          currentUser.companyRoles.filter(companyRole => !companyRole.hasSentRequest)[0]?._id
        );

        if (initialCurrentCompanyId) {
          switchCompany(initialCurrentCompanyId);
        } else {
          onSwitchCompanyFailure(); // Call error handler if no initial company id
        }
      }
    },
    [currentUser, onSwitchCompanyFailure, routeParams, routeParams.currentCompanyId, switchCompany, targetCompanyId],
  );

  useQuery({
    queryKey: ['company', { companyId: targetCompanyId }],
    queryFn: wrap(api.getPublicCompany),
    enabled: Boolean(targetCompanyId),
    staleTime: 5 * 60 * 1000,
    retry: false,
    onSuccess: (company: any) => {
      // If no change in company id then do nothing
      if (company._id === currentCompanyId) {
        return;
      }

      /**
       * We need a buffer to ensure that the current company id is set after the user has been redirected
       * to the Dashboard page. Otherwise, the API requests from the current page will be refetched using
       * the new company id and throw errors.
       */
      setTimeout(() => {
        setCurrentCompanyId(company._id);
      }, 0);

      localStorage.setItem(LOCAL_STORAGE_CURRENT_COMPANY_ID_KEY, company._id);
      onSwitchCompanySuccess(company, !currentCompanyId);
    },
    onError: () => {
      onSwitchCompanyFailure();
    },
  });

  useQuery({
    queryKey: ['isCompanyProfileComplete', { companyId: currentCompanyId }],
    queryFn: wrap(api.getIsCompanyProfileComplete),
    enabled: Boolean(currentCompanyId),
    staleTime: 12 * 60 * 60 * 1000, // 12 hours
    onSuccess: (isComplete) => {
      if (!isComplete) {
        window.location.href = `${ONBOARDING_URL}/update-company/${currentCompanyId}`;
      }
    },
  });

  return (
    <CurrentCompanyIdContext.Provider value={currentCompanyId}>
      <SwitchCompanyContext.Provider value={switchCompany}>
        {currentCompanyId ? children : null}
      </SwitchCompanyContext.Provider>
    </CurrentCompanyIdContext.Provider>
  );
};

export const useSwitchCompany = () => {
  return React.useContext(SwitchCompanyContext);
};
