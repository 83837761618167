import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { PanelDivider, SidebarPanelHeading } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Row } from './ProfileLayout';
import { useHoursPassed } from '../useExternalSystems';

interface DetailsProps {
  label: string;
  isLast?: boolean;
  isTeamMember?: boolean;
  canEdit?: boolean;
  dataTest?: string;
  onEdit?: () => void;
  hasTooltip?: boolean;
  externalSystemName?: string;
  externalStatusesUpdatedAt?: Date;
  children: React.ReactNode;
}

export const Details = ({
  label,
  isLast,
  isTeamMember,
  canEdit,
  dataTest,
  onEdit,
  hasTooltip,
  externalSystemName,
  externalStatusesUpdatedAt,
  children,
}: DetailsProps) => {
  const { t } = useTranslation('integration');
  const hoursPassed = useHoursPassed({ externalStatusesUpdatedAt });

  return (
    <>
      <Stack gap={3} p={20} style={{ lineHeight: 'normal' }} data-test={dataTest}>
        <Row alignItems="center" justifyContent="space-between">
          <SidebarPanelHeading>
            {label}
          </SidebarPanelHeading>
          {hasTooltip && (
            <Tooltip content={t('externalStatus.statusHeaderTooltip', { externalSystemName, hoursPassed })}>
              <Icon icon="question-circle-o" fontSize={1} />
            </Tooltip>
          )}
          {(canEdit || isTeamMember) && (
            <EditButton small onClick={onEdit} disabled={!canEdit} />
          )}
        </Row>
        {children}
      </Stack>
      {!isLast && <PanelDivider />}
    </>);
};
