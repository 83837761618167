import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { map, pick, groupBy, keyBy, compact, first } from 'lodash';
import { Text } from 'rebass/styled-components';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { RfqEventChange, ExchangeChange, ExchangeDefinition, ExchangeProvider, DocumentExchangeDefinition, RfxOtherSection, Live } from '@deepstream/common/rfq-utils';
import { diffArrayBy } from '@deepstream/utils';
import { CancelButton, SaveButton, Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelHeader, PanelPadding, PanelSubHeader } from '@deepstream/ui-kit/elements/Panel';
import { Modal, ModalFooter, ModalBody, ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import { isBiddingOnLot } from '@deepstream/common/rfq-utils/lot';
import { useRfqExchanges, useRfqId, useRecipientId } from './useRfq';
import { useExchangeColumns } from './modules/Exchange/columns';
import * as rfx from './rfx';
import { Loading } from './ui/Loading';
import { ErrorMessage } from './ui/ErrorMessage';
import { useModalState } from './ui/useModalState';
import { useCurrentCompanyId } from './currentCompanyId';
import { mappings, mapDiffToChanges } from './ui/diff';
import { useToaster } from './toast';
import { Disclosure } from './ui/Disclosure';
import { useUpdateExchanges } from './useUpdateExchanges';
import { DocumentTableField } from './draft/DocumentTable';
import { SectionFilesDownloadModal } from './SectionFilesDownloadModal';
import { useExchangeNavigation } from './useExchangeModalState';
import { ExchangesGrid } from './modules/Request/Live/ExchangesGrid';
import { EditSupplierExchangeDefsButton } from './modules/Request/Live/EditSupplierExchangeDefsButton';

const sanitize = <T,> (items: T[], diffKeys: string[]) =>
  map(items, def => pick(def, diffKeys));

const getChanges = <TChange extends RfqEventChange> ({ next, previous, keys, mapping }): TChange[] =>
  mapDiffToChanges(
    diffArrayBy(sanitize(next, keys), sanitize(previous, keys), '_id'),
    mappings[mapping],
  ) as any;

const EditSupplierAddedExchangesModal = ({ isOpen, onSuccess, onCancel }) => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const section = rfx.useSection();
  const previousSupplierAddedDefs = rfx.useSectionExchangeDefsByCreator<DocumentExchangeDefinition>({
    group: 'recipient',
  });

  const [updateExchanges] = useUpdateExchanges({
    getChanges: (nextSupplierAddedDefs: ExchangeDefinition[]) =>
      getChanges<ExchangeChange>({
        mapping: 'exchangeDef',
        next: nextSupplierAddedDefs,
        previous: previousSupplierAddedDefs,
        keys: ['_id', 'type', 'category', 'attachments', 'isObsolete'],
      }).map(change => {
        change.sectionName = section._id;
        return change;
      }),
    onSuccess: () => toaster.success(t('request.documents.toaster.documentsUpdated')),
    onError: () => toaster.error(t('request.documents.toaster.couldNotUpdateDocuments')),
  });

  return (
    <Modal
      shouldCloseOnEsc
      isOpen={isOpen}
      onRequestClose={onCancel}
      style={{ content: { width: '810px' } }}
    >
      <Formik<{ exchangeDefs: DocumentExchangeDefinition[] }>
        validateOnBlur
        enableReinitialize
        initialValues={{
          exchangeDefs: previousSupplierAddedDefs,
        }}
        validationSchema={
          yup.object().shape({
            exchangeDefs: yup.array().of(
              yup.object().shape({
                category: yup.string().required(t('general.required')),
                attachments: yup.array().min(1).max(1).required(t('general.required')),
              }),
            ),
          })
        }
        onSubmit={async ({ exchangeDefs }, { setSubmitting }) => {
          await updateExchanges(exchangeDefs, {
            onSuccess,
            onSettled: () => setSubmitting(false),
          });
        }}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              {t('request.documents.supplierAddedDocuments')}
            </ModalHeader>
            <ModalBody>
              <DocumentTableField fieldName="exchangeDefs" />
            </ModalBody>
            <ModalFooter>
              <CancelButton type="button" onClick={onCancel} mr={2} />
              <SaveButton disabled={isSubmitting || !dirty || !isValid} />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const DownloadButton = (props) => {
  const { t } = useTranslation();

  return (
    <Button
      small
      variant="secondary-outline"
      iconLeft="download"
      {...props}
    >
      {t('general.downloadFiles')}
    </Button>
  );
};

export const DocumentBidSection = () => {
  const { t } = useTranslation();
  const rfqId = useRfqId();
  const recipientId = useRecipientId();
  const sections = rfx.useSections();
  const { exchangeDefById, lotById } = rfx.useStructure<Live>();
  const section = rfx.useSection<RfxOtherSection>();
  const editDocumentsModal = useModalState();
  const sectionFilesDownloadModal = useModalState();
  const isRecipient = useCurrentCompanyId() === recipientId;
  const stageId = rfx.useStageId();
  const bid = rfx.useBid();
  const requirementGroupId = rfx.useRequirementGroupId();
  const { openExchange } = useExchangeNavigation();
  const pagePermissions = rfx.usePagePermissions();
  // @ts-expect-error ts(2538) FIXME: Type 'null' cannot be used as an index type.
  const lot = lotById[requirementGroupId];

  const hasRecipientAddedSubSection = section.providedBy === ExchangeProvider.BOTH;

  const { data: exchanges = [], isSuccess, isError, isLoading } = useRfqExchanges({
    recipientId,
    sectionIds: sections.map(section => section._id),
    select: exchanges => {
      const exchangeById = keyBy(exchanges, 'def._id');
      return compact(map(section.exchangeDefIds, exchangeId => exchangeById[exchangeId]));
    },
  });

  const exchangesInitiatedBy = groupBy(
    exchanges,
    exchange => {
      const exchangeDef = exchangeDefById[exchange._id];
      // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
      return exchange.companies[exchangeDef.creatorId].group!;
    },
  );

  // in live context, we need to filter out exchangeDefs that belong
  // to other stages if stages are not defined on the section
  const filteredSenderExchanges = !first(section.stages) && stageId
    ? (exchangesInitiatedBy.sender || []).filter(exchange => {
      const exchangeDefStageId = first(exchange.def.stages);

      // in case the exchangeDef doesn't have a stage ID, it's an
      // 'information' exchange and we show it in all stages
      return !exchangeDefStageId || exchangeDefStageId === stageId;
    })
    : (exchangesInitiatedBy.sender || []);

  const hasNonObsoleteSenderUploadedDocuments = (
    (
      !requirementGroupId ||
      requirementGroupId === 'general' ||
      isBiddingOnLot(lot, bid.intentionStatusByLotId[requirementGroupId])
    ) &&
    filteredSenderExchanges.some(exchange => (
      !exchange.isDocumentRequest && !exchange.isObsolete
    ))
  );

  const exchangeColumns = useExchangeColumns();
  const {
    buyerProvidedColumns,
    supplierProvidedColumns,
  } = useMemo(() => ({
    buyerProvidedColumns: exchangeColumns.documents({ isProvidedBySupplier: false }),
    supplierProvidedColumns: exchangeColumns.documents({ isProvidedBySupplier: true }),
  }), [exchangeColumns]);

  // Render nothing if there are no exchanges to display
  if (!section.exchangeDefIds.length) {
    return null;
  }

  return (
    <>
      <Panel>
        <>
          <PanelHeader heading={section.name} description={section.description} icon="file-text-o" >
            {isRecipient && hasNonObsoleteSenderUploadedDocuments && <DownloadButton onClick={sectionFilesDownloadModal.open} />}
          </PanelHeader>
          <PanelDivider />
        </>
        {hasRecipientAddedSubSection ? (
          <>
            <PanelSubHeader heading={t('request.documents.buyerAddedDocuments')} />
            <PanelDivider />
          </>
        ) : null}
        {isLoading ? (
          <PanelPadding>
            <Loading />
          </PanelPadding>
        ) : isError ? (
          <PanelPadding>
            <ErrorMessage error={t('request.documents.couldNotGetDocuments')} />
          </PanelPadding>
        ) : isSuccess ? (
          filteredSenderExchanges?.length ? (
            <PanelPadding>
              <ExchangesGrid
                exchanges={filteredSenderExchanges as any}
                columns={buyerProvidedColumns}
                onRowClick={openExchange}
              />
            </PanelPadding>
          ) : (
            <PanelPadding>
              <Text color="subtext" fontSize={2}>
                {t('request.documents.noDocumentsAdded')}
              </Text>
            </PanelPadding>
          )
        ) : (
          null
        )}
        {hasRecipientAddedSubSection && (
          <>
            <PanelDivider />
            <PanelSubHeader heading={t('request.documents.supplierAddedDocuments')}>
              {isRecipient && pagePermissions.canEdit ? (
                <EditSupplierExchangeDefsButton onClick={editDocumentsModal.open} />
              ) : (
                null
              )}
            </PanelSubHeader>
            <PanelDivider />
            {isLoading ? (
              <PanelPadding>
                <Loading />
              </PanelPadding>
            ) : isError ? (
              <PanelPadding>
                <ErrorMessage error={t('request.documents.couldNotGetDocuments')} />
              </PanelPadding>
            ) : isSuccess ? (
              exchangesInitiatedBy.recipient?.length ? (
                <PanelPadding>
                  <ExchangesGrid
                    exchanges={exchangesInitiatedBy.recipient as any}
                    columns={supplierProvidedColumns}
                    onRowClick={openExchange}
                  />
                </PanelPadding>
              ) : (
                <PanelPadding>
                  <Text color="subtext" fontSize={2} mb={3}>
                    {t('request.documents.noDocumentsAdded')}
                  </Text>
                  {isRecipient ? (
                    <Disclosure summary={t('request.documents.supplierAddedDisclosure.recipient.summary')}>
                      {t('request.documents.supplierAddedDisclosure.recipient.content')}
                    </Disclosure>
                  ) : (
                    <Disclosure summary={t('request.documents.supplierAddedDisclosure.sender.bid.summary')}>
                      {t('request.documents.supplierAddedDisclosure.sender.bid.content')}
                    </Disclosure>
                  )}
                </PanelPadding>
              )
            ) : (
              null
            )}
          </>
        )}
      </Panel>
      <EditSupplierAddedExchangesModal
        isOpen={editDocumentsModal.isOpen}
        onSuccess={editDocumentsModal.close}
        onCancel={editDocumentsModal.close}
      />
      <SectionFilesDownloadModal
        rfqId={rfqId}
        sectionId={section?._id}
        isOpen={sectionFilesDownloadModal.isOpen}
        onCancel={sectionFilesDownloadModal.close}
      />
    </>
  );
};
