import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Clamp } from '@deepstream/ui-kit/elements/text/Clamp';
import { RequestTag } from '../../ui/types';
import { TabPanel } from '../../ui/TabPanel';
import { ReverseOrderIf } from '../../ReverseOrderIf';
import { DraftSentRequests, LiveSentRequests } from '../../SentRequests';
import { ReceivedRequests } from '../../ReceivedRequests';
import { useUserFlags } from '../../UserFlagsContext';
import RequestTagDropdown from './RequestTagDropdown';
import { getTreeIndexFromId, useCanEditRequestTags } from './utils';

const RequestTagPanel = ({ requestTag }: { requestTag: RequestTag }) => {
  const { t } = useTranslation('translation');
  const canEditRequestTags = useCanEditRequestTags();

  const {
    belongsToSupplierOnlyCompany,
  } = useUserFlags();

  return (
    // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    <TabPanel index={getTreeIndexFromId(requestTag._id)} tabIndex={getTreeIndexFromId(requestTag._id)}>
      <Flex justifyContent="space-between">
        <Text fontSize={6} fontWeight={500} display="flex" alignItems="center">
          <Icon icon="tag" mr={2} fixedWidth />
          <Clamp lines={2}>{requestTag.name}</Clamp>
        </Text>

        {canEditRequestTags && <RequestTagDropdown tag={requestTag} />}
      </Flex>

      <ReverseOrderIf condition={belongsToSupplierOnlyCompany}>
        <>
          <Box>
            <Text mt="24px" mb={2} fontSize={6}>
              <Icon icon="share" mr={2} fixedWidth />
              <Box as="span" fontWeight={500}>{t('requests.tags.sentRequestsHeading')}</Box>
            </Text>
            <LiveSentRequests selectedRequestTagId={requestTag._id} />
          </Box>
          <Box>
            <Text mt="24px" mb={2} fontSize={6}>
              <Icon icon="pencil" mr={2} fixedWidth />
              <Box as="span" fontWeight={500}>{t('requests.tags.draftRequestsHeading')}</Box>
            </Text>
            <DraftSentRequests selectedRequestTagId={requestTag._id} />
          </Box>
        </>
        <Box>
          <Text mt="24px" mb={2} fontSize={6}>
            <Icon icon="reply" mr={2} fixedWidth />
            <Box as="span" fontWeight={500}>{t('requests.tags.receivedRequestsHeading')}</Box>
          </Text>
          <ReceivedRequests selectedRequestTagId={requestTag._id} />
        </Box>
      </ReverseOrderIf>
    </TabPanel>
  );
};

export default RequestTagPanel;
