import * as React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';

export const PageFooter = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();

  return (
    <Box
      as="footer"
      bg="white"
      sx={{
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        borderTop: `3px solid ${theme.colors.primary}`,
        maxWidth: '100%',
        zIndex: 101,
      }}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        margin="auto"
        maxWidth="1200px"
        p="15px"
      >
        {children}
      </Flex>
    </Box>
  );
};
