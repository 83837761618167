import { SectionType } from '@deepstream/common/rfq-utils';
import { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Heading } from 'rebass/styled-components';
import * as yup from 'yup';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { ConfirmDeleteDialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { DropdownMenu, DropdownMenuItem, DuplicateMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { useIntercom } from 'react-use-intercom';
import { Bold } from '../../Bold';
import { TextField } from '../../form/TextField';
import { ModalForm } from '../../ModalForm';
import { Disclosure } from '../../ui/Disclosure';
import { useConfirmDialog, useModalState } from '../../ui/useModalState';
import { AddContractPageModal } from './AddContractPageModal';
import { AddContractSectionDropdown } from './AddContractSectionDropdown';
import {
  ContractSectionProvider,
  ContractSectionsProvider,
  useContractActions,
  useContractData,
  useContractPage,
  useContractSections,
  useContractState,
  useGeneralPages,
  useNextVisiblePage,
  usePreviousVisiblePage,
  usePagePermissions,
  useContractPermissions,
} from './contract';
import { ContractDocumentsSectionPanel } from './ContractDocumentsSectionPanel';
import { ContractLineItemsSectionPanel } from './ContractLineItemsSectionPanel';
import {
  useAddContractSection,
  useRemoveContractPage,
  useRenameContractPage,
} from './draftContract';
import { ReorderContractPagesModal } from './ReorderContractPagesModal';
import { getPageExchangeDefs, getSizeRelevantExchangeDefCount, useModelSizeLimits } from '../../modelSizeLimits';
import { ModelSizeLimitDialog, ModelSizeLimitMessages } from '../../ModelSizeLimitDialog';
import { useDraftContractNavigation } from '../../appNavigation';

const GeneralSectionDisclosure = () => {
  const { t } = useTranslation('contracts');

  return (
    <Disclosure
      summary={t('details.disclosure.section.summary')}
    >
      {t('details.disclosure.section.content')}
    </Disclosure>
  );
};

const SectionsPlaceholder = () => {
  const { t } = useTranslation('contracts');

  return (
    <Bold fontSize={4}>
      {t('details.noSectionsAdded')}
    </Bold>
  );
};

/**
 * High-level structure for an "general" page
 */
const DraftGeneralPage = () => {
  const intercom = useIntercom();
  // @ts-expect-error ts(2339) FIXME: Property 'editingPanelId' does not exist on type 'ContractStateContextType | undefined'.
  const { editingPanelId } = useContractState();
  const { startEditing } = useContractActions();
  const isEditingAnyPanel = Boolean(editingPanelId);
  const sections = useContractSections();
  const page = useContractPage();
  const { canEdit } = usePagePermissions();
  const [addContractSection, { isLoading }] = useAddContractSection({
    onSuccess: ({ _id, type }) => {
      startEditing(_id);

      if (type === SectionType.LINE_ITEMS) {
        intercom.trackEvent('edit-line-items-section-opened');
      }
    },
  });

  const addSection = useCallback(
    (sectionType: SectionType) => {
      addContractSection({
        type: sectionType,
        name: '',
        pageId: page._id,
      });
    },
    [page, addContractSection],
  );

  return (
    <Stack gap={20}>
      {sections.length ? (
        sections.map(section => (
          <ContractSectionProvider key={section._id} section={section}>
            {section.type === SectionType.LINE_ITEMS ? (
              <ContractLineItemsSectionPanel />
            ) : section.type === SectionType.DOCUMENT ? (
              <ContractDocumentsSectionPanel />
            ) : (
              null
            )}
          </ContractSectionProvider>
        ))
      ) : (
        <SectionsPlaceholder />
      )}
      {canEdit && (
        <Box mt={2}>
          <AddContractSectionDropdown
            disabled={isLoading || isEditingAnyPanel}
            onSelect={addSection}
          />
        </Box>
      )}
      <GeneralSectionDisclosure />
    </Stack>
  );
};

const RenamePageModal = ({
  isOpen,
  onCancel,
  onSuccess,
}: {
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
}) => {
  const { t } = useTranslation(['contracts', 'general']);
  const page = useContractPage();
  const [renamePage] = useRenameContractPage();

  return (
    <ModalForm
      heading={t('details.renamePage')}
      initialValues={{
        name: page.name,
      }}
      validationSchema={
        yup.object().shape({
          name: yup.string().trim().required(t('details.errors.nameRequired')),
        })
      }
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={async ({ name }) => {
        await renamePage({
          _id: page._id,
          name: name.trim(),
        }, {
          onSuccess,
        });
      }}
      submitLabel={t('save', { ns: 'general' })}
      style={{ content: { width: '500px' } }}
    >
      <TextField
        required
        name="name"
        label={t('name', { ns: 'general' })}
      />
    </ModalForm>
  );
};

const DraftPageHeader = () => {
  const { t } = useTranslation(['contracts', 'general', 'translation']);
  const generalPages = useGeneralPages();
  // @ts-expect-error ts(2339) FIXME: Property 'isRevising' does not exist on type 'ContractStateContextType | undefined'.
  const { isRevising, isAmending, editingPanelId } = useContractState();
  const reorderPagesModal = useModalState();
  const { sectionById, exchangeDefById } = useContractData();
  const renamePageModal = useModalState();
  const duplicatePageModal = useModalState();
  const { confirm, ...dialogProps } = useConfirmDialog();
  const [removePage] = useRemoveContractPage();
  const { maxExchangeDefCount } = useModelSizeLimits();
  const page = useContractPage();
  const { navigateToDetailsPage } = useDraftContractNavigation();
  const { canManagePages } = useContractPermissions();
  const modelSizeLimitModal = useModalState();
  const [modelSizeLimitMessages, setModelSizeLimitMessages] = useState<ModelSizeLimitMessages | null>(null);
  const previousPage = usePreviousVisiblePage(page._id);
  const nextPage = useNextVisiblePage(page._id);

  const isEditingAnyPanel = Boolean(editingPanelId);

  const handleDuplicateClick = () => {
    const totalExchangeDefCount = getSizeRelevantExchangeDefCount(exchangeDefById);
    const pageExchangeDefCount = getSizeRelevantExchangeDefCount(
      getPageExchangeDefs(sectionById, exchangeDefById, page),
    );

    if (totalExchangeDefCount + pageExchangeDefCount > maxExchangeDefCount) {
      setModelSizeLimitMessages({
        heading: t('dialog.contractSizeLimit.heading'),
        title: t('dialog.contractSizeLimit.duplicatePage.title'),
        warning: t('dialog.contractSizeLimit.duplicatePage.warning'),
        body: t('dialog.contractSizeLimit.duplicatePage.body', {
          count: totalExchangeDefCount + pageExchangeDefCount - maxExchangeDefCount,
        }),
      });
      modelSizeLimitModal.open();
    } else {
      duplicatePageModal.open();
    }
  };

  const onDelete = () => confirm(async () => {
    const redirectPage = previousPage ?? nextPage;

    await removePage({ _id: page._id });

    if (redirectPage) {
      navigateToDetailsPage(redirectPage._id);
    } else {
      navigateToDetailsPage('');
    }
  });

  const onDuplicatePageSuccess = useCallback(
    (pageId: string) => {
      navigateToDetailsPage(pageId);
      duplicatePageModal.close();
    },
    [duplicatePageModal, navigateToDetailsPage],
  );

  return (
    <>
      <Flex justifyContent="space-between" alignItems="flex-start" mb="20px" minHeight="40px">
        <Heading fontSize={6} fontWeight={500} mt="5px" mr={3} flex={1}>
          {page.name}
        </Heading>
        {canManagePages && (
          <DropdownMenu
            variant="secondary-outline"
            buttonText={t('details.pageActions')}
            rightAligned
            iconRight="caret-down"
            disabled={isEditingAnyPanel}
            data-test="page-actions-button"
            popoverProps={{
              'data-test': 'page-actions-menu',
            }}
          >
            <DropdownMenuItem
              icon="pencil"
              onSelect={renamePageModal.open}
            >
              {t('rename', { ns: 'general' })}
            </DropdownMenuItem>
            {!isRevising && !isAmending && (
              <DuplicateMenuItem
                onSelect={handleDuplicateClick}
                data-test="duplicate-page-button"
              />
            )}
            {generalPages.length > 1 && (
              <DropdownMenuItem
                icon="refresh"
                onSelect={reorderPagesModal.open}
              >
                {t('details.reorderPages')}
              </DropdownMenuItem>
            )}
            {!page.isLive && (
              <DropdownMenuItem
                icon="trash"
                onSelect={onDelete}
                color="danger"
              >
                {t('delete', { ns: 'general' })}
              </DropdownMenuItem>
            )}
          </DropdownMenu>
        )}
      </Flex>
      <ReorderContractPagesModal
        isOpen={reorderPagesModal.isOpen}
        onCancel={reorderPagesModal.close}
        onSuccess={reorderPagesModal.close}
      />
      <RenamePageModal
        isOpen={renamePageModal.isOpen}
        onCancel={renamePageModal.close}
        onSuccess={renamePageModal.close}
      />
      <AddContractPageModal
        isOpen={duplicatePageModal.isOpen}
        sourcePageId={page._id}
        onCancel={duplicatePageModal.close}
        onSuccess={onDuplicatePageSuccess}
      />
      <ConfirmDeleteDialog
        heading={t('dialog.confirmPageDeletion.heading')}
        message={t('dialog.confirmPageDeletion.message')}
        {...dialogProps}
      />
      <ModelSizeLimitDialog
        modal={modelSizeLimitModal}
        messages={modelSizeLimitMessages}
      />
    </>
  );
};

export const ContractDraftPage = () => {
  const { t } = useTranslation('contracts');
  const page = useContractPage();
  const contract = useContractData();
  // @ts-expect-error ts(2339) FIXME: Property 'isRevising' does not exist on type 'ContractStateContextType | undefined'.
  const { isRevising, isAmending } = useContractState();

  const sections = useMemo(
    () => page.sections.map(sectionId => contract.sectionById[sectionId]),
    [page, contract],
  );

  return (
    <Box py="20px">
      <DraftPageHeader />
      <ContractSectionsProvider sections={sections}>
        {isRevising && (
          <MessageBlock variant="info" mb="20px">
            {t('revisionInfo')}
          </MessageBlock>
        )}
        {isAmending && !contract.isLegacy && (
          <MessageBlock variant="info" mb="20px">
            {t('amendmentInfo')}
          </MessageBlock>
        )}
        <DraftGeneralPage />
      </ContractSectionsProvider>
    </Box>
  );
};
