import { Draft, SectionType } from '@deepstream/common/rfq-utils';
import { first, isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderAndContent } from '../../../Layout';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { ModelSizeLimitsProvider } from '../../../modelSizeLimits';
import { SetupMultiStageResponsesFlowHeader } from '../../../page-headers/RequestFlowHeader';
import * as rfx from '../../../rfx';
import { RfxStructure } from '../../../types';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import { LoadingPanel } from '../../../ui/Loading';
import { MultiStepFlow } from '../../../ui/MultiStepFlow/MultiStepFlow';
import { useDraftRfqStructure, useRfqId } from '../../../useRfq';
import { useRequestSizeLimits } from '../useRequestSizeLimits';
import { stepComponentByType } from './steps';
import {
  SetupMultiStageResponsesFlowData,
  SetupMultiStageResponsesFlowStep,
  SetupMultiStageResponsesFlowStepType,
} from './types';

const staticStartSteps : SetupMultiStageResponsesFlowStep[] = [
  { type: SetupMultiStageResponsesFlowStepType.GUIDELINES },
  { type: SetupMultiStageResponsesFlowStepType.REVIEW_STAGES },
  { type: SetupMultiStageResponsesFlowStepType.SELECT_SECTIONS },
];

const getConfigureSectionSteps = (data: SetupMultiStageResponsesFlowData) => {
  const { selectedSectionIds } = data;

  const configureSectionSteps : SetupMultiStageResponsesFlowStep[] = [];
  for (let index = 0; index < selectedSectionIds.length; index += 1) {
    configureSectionSteps.push({
      type: SetupMultiStageResponsesFlowStepType.CONFIGURE_SECTION,
      currentSectionIndex: index,
    });
  }

  return configureSectionSteps;
};

const getDeleteSectionsStep = (data: SetupMultiStageResponsesFlowData) : SetupMultiStageResponsesFlowStep[] => {
  const { initialSectionIds, selectedSectionIds, addedSectionIds } = data;

  const deletableSectionIds = initialSectionIds.filter((sectionId) => ![...selectedSectionIds, ...addedSectionIds].includes(sectionId));
  if (!isEmpty(deletableSectionIds)) {
    return [{ type: SetupMultiStageResponsesFlowStepType.DELETE_SECTIONS, deletableSectionIds }];
  } else {
    return [];
  }
};

const getAllSteps = (data: SetupMultiStageResponsesFlowData) => {
  return [
    ...staticStartSteps,
    ...getConfigureSectionSteps(data),
    ...[{ type: SetupMultiStageResponsesFlowStepType.ADD_SECTIONS }] as SetupMultiStageResponsesFlowStep[],
    ...getDeleteSectionsStep(data),
    ...[{ type: SetupMultiStageResponsesFlowStepType.REVIEW_CHANGES }] as SetupMultiStageResponsesFlowStep[],
  ];
};

const SetupMultiStageResponsesFlowContent = () => {
  const { t } = useTranslation('translation');
  const structure = rfx.useStructure<Draft>();

  const [initialStructure, setInitialStructure] = useState<RfxStructure<Draft> | null>(null);
  useEffect(() => {
    if (structure && !initialStructure) {
      setInitialStructure(structure);
    }
  }, [structure, initialStructure]);

  const initialData: SetupMultiStageResponsesFlowData | null = useMemo(() => {
    if (initialStructure !== null) {
      const { stages, sectionById } = initialStructure;
      const sections = Object.values(sectionById);

      const initialSectionIds : string[] = sections
        .filter((section) => section.type === SectionType.LINE_ITEMS)
        .map((section) => section._id);

      return {
        steps: staticStartSteps,
        currentStep: first(staticStartSteps)!,
        initialStages: stages,
        updatedStages: [],
        initialSectionIds,
        selectedSectionIds: [],
        addedSectionIds: [],
        currentSectionIndex: -1,
      };
    } else {
      return null;
    }
  }, [initialStructure]);

  const canLeaveFlow = useCallback(
    (data: SetupMultiStageResponsesFlowData) =>
      [
        SetupMultiStageResponsesFlowStepType.GUIDELINES,
        SetupMultiStageResponsesFlowStepType.REVIEW_CHANGES,
      ].includes(data.currentStep.type),
    [],
  );

  return initialData ? (
    <MultiStepFlow<
      SetupMultiStageResponsesFlowStepType,
      SetupMultiStageResponsesFlowStep,
      SetupMultiStageResponsesFlowData
    >
      initialData={initialData}
      stepComponentByType={stepComponentByType}
      getAllSteps={getAllSteps}
      canLeaveFlow={canLeaveFlow}
      leaveFlowModalProps={{
        heading: t('request.dialog.leaveSetupMultiStageResponsesFlow.heading'),
        warning: t('request.dialog.leaveSetupMultiStageResponsesFlow.warning'),
        okButtonText: t('request.dialog.leaveSetupMultiStageResponsesFlow.okButtonText'),
        cancelButtonText: t('request.dialog.leaveSetupMultiStageResponsesFlow.cancelButtonText'),
      }}
    />
  ) : (
    null
  );
};

const SetupMultiStageResponsesFlow = () => {
  const { t } = useTranslation('translation');
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { isTemplate } = rfx.useState();

  const { data: structure, isLoading, isError, isSuccess } = useDraftRfqStructure({
    rfqId,
    currentCompanyId,
    isTemplate,
  });

  const { data: sizeLimits, status: sizeLimitsStatus } = useRequestSizeLimits({
    rfqId,
    isTemplate,
  });

  return isLoading || sizeLimitsStatus === 'loading' ? (
    <LoadingPanel />
  ) : isError || sizeLimitsStatus === 'error' ? (
    <ErrorPanel error={t('errors.unexpected')} />
  ) : isSuccess && sizeLimitsStatus === 'success' && structure ? (
    <ModelSizeLimitsProvider {...sizeLimits}>
      <rfx.StructureProvider structure={structure}>
        <SetupMultiStageResponsesFlowContent />
      </rfx.StructureProvider>
    </ModelSizeLimitsProvider>
  ) : (
    null
  );
};

export const SetupMultiStageResponsesFlowPage = ({
  isRevising,
  isTemplate,
}: {
  isRevising?: boolean;
  isTemplate?: boolean;
}) => {
  return (
    <rfx.StateProvider isRevising={isRevising} isTemplate={isTemplate}>
      <HeaderAndContent
        header={<SetupMultiStageResponsesFlowHeader />}
        content={<SetupMultiStageResponsesFlow />}
      />
    </rfx.StateProvider>
  );
};
