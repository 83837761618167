import * as draft from './draft';
import { EvaluationSectionEditPanel } from './EvaluationSectionEditPanel';
import { EvaluationSectionViewPanel } from './EvaluationSectionViewPanel';

/**
 * Displays a panel for the current section
 */
export const EvaluationSectionPanel = () => {
  const isEditing = draft.useIsEditing();

  return isEditing ? (
    <EvaluationSectionEditPanel />
  ) : (
    <EvaluationSectionViewPanel />
  );
};
