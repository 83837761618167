import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';
import { v4 as uuid } from 'uuid';
import { get, isEmpty, set } from 'lodash';
import { Draft, Lot, getEmptyFieldValue, isFieldValueDefined } from '@deepstream/common/rfq-utils';
import { NonVirtualGrid } from '@deepstream/ui-kit/grid/core/NonVirtualGrid';
import { ColumnData, RowData, getGridContainerId } from '@deepstream/ui-kit/grid/core/utils';
import { EditableGrid } from '@deepstream/ui-kit/grid/EditableGrid/EditableGrid';
import { useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { DefaultEditableGridStyles } from '@deepstream/ui-kit/grid/EditableGrid/EditableGridStyles';
import { RenderTableDataCell } from '@deepstream/ui-kit/grid/EditableGrid/RenderTableDataCell';
import { useGridMenuState } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { ReadOnlyGrid } from '@deepstream/ui-kit/grid/EditableGrid/ReadOnlyGrid';
import { ACTION_COLUMN_WIDTH, EditableGridColumn, GridPasteMethod } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { useEditableGridActions } from '@deepstream/ui-kit/grid/EditableGrid/useEditableGridActions';
import { withProps } from '@deepstream/ui-utils/withProps';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { FieldType } from '@deepstream/common/exchangesConfig';
import {
  TextAreaInputCell,
} from './inputCell';
import {
  TextValueCell,
  ValidationAwareRowNumberCell,
} from './validationAwareValueCell';
import { LotsGridMenu } from './LotsGridMenu';
import { SimpleHeader } from './header';
import { useAddGridRowsState } from './useAddGridRowsState';
import { AddMoreItemsModal } from './AddMoreItemsModal';
import { useHandleGridClipboardEvent } from './useHandleGridClipboardEvent';
import { parseCellValue } from './utils';

const navigableRange = { startRowIndex: 1, startColumnIndex: 0 };
const selectableRange = { startColumnIndex: 1 };

export const AddLotButton = (props: ButtonProps) => {
  const { t } = useTranslation('translation');

  return (
    <Button small variant="secondary-outline" iconLeft="plus" {...props}>
      {t('request.lots.addLot')}
    </Button>
  );
};

const generateCsvCellValue = (columnConfig, lot) => lot[columnConfig._id];

const addCsvDataToRows = async ({ rows, data, affectedColumns }: {
  rows: any;
  data: any;
  affectedColumns: any;
}) => {
  let validCellCount = 0;

  const updatedRows = rows.map((lot, lotIndex) => {
    if (lot.isObsolete) {
      return lot;
    }

    const rowData = data[lotIndex] ?? [];
    const updatedLot = { ...lot };

    affectedColumns.forEach((column, index) => {
      const rawValue = rowData[index];

      const {
        canInsertValue,
        cellValue,
        isInputValid,
      } = parseCellValue(rawValue, { _id: column.original._id, type: column.original.fieldType });

      if (canInsertValue) {
        set(updatedLot, column.original.accessorKey, cellValue);
      }

      if (isInputValid) {
        validCellCount += 1;
      }
    });

    return updatedLot;
  });

  return {
    updatedRows,
    validCellCount,
  };
};

const createClearFieldsUpdater = ({
  startRowIndex,
  endRowIndex,
  affectedColumns,
}: { startRowIndex: number, endRowIndex: number, affectedColumns: { original: EditableGridColumn }[] }) =>
  (lot: Record<string, unknown>, index: number) => {
    if (!lot.isObsolete && index >= startRowIndex && index < endRowIndex) {
      const updatedRow = { ...lot };

      for (const column of affectedColumns) {
        const { accessorKey, fieldType } = column.original;

        const fieldValue = get(lot, accessorKey);

        // @ts-expect-error ts(2345) FIXME: Argument of type 'FieldType | undefined' is not assignable to parameter of type 'FieldType'.
        if (isFieldValueDefined(fieldValue, fieldType)) {
          // @ts-expect-error ts(2345) FIXME: Argument of type 'FieldType | undefined' is not assignable to parameter of type 'FieldType'.
          const newFieldValue = getEmptyFieldValue(fieldType);

          set(updatedRow, accessorKey, newFieldValue);
        }
      }

      return updatedRow;
    } else {
      return lot;
    }
  };

export const createLot = () => {
  return {
    _id: uuid(),
    name: '',
    description: '',
  };
};

export interface LotsGridProps {
  isReadOnly?: boolean;
}

export const LotsGrid = ({
  isReadOnly,
}: LotsGridProps) => {
  const { t } = useTranslation('translation');
  const handleGridClipboardEvent = useHandleGridClipboardEvent();
  const {
    pendingKeyboardEvent,
    rowData: lots,
    setEditedCell,
    appendRows,
  } = useEditableGridData<Lot<Draft>>();
  const { menuReferenceId } = useGridMenuState();

  const {
    addGridRowsModal,
    additionalRowCount,
    selectPasteMethod,
    resolveWithPasteMethod,
  } = useAddGridRowsState();

  const gridActions = useEditableGridActions({
    // @ts-expect-error ts(2322) FIXME: Type '(additionalRowCount: number) => Promise<GridPasteMethod | null>' is not assignable to type '(additionalRowCount: number) => Promise<GridPasteMethod>'.
    selectPasteMethod,
    createRow: createLot as any,
    generateCsvCellValue,
    addCsvDataToRows,
    // @ts-expect-error ts(2322) FIXME: Type '({ startRowIndex, endRowIndex, affectedColumns, }: { startRowIndex: number; endRowIndex: number; affectedColumns: { original: EditableGridColumn; }[]; }) => (lot: Record<string, unknown>, index: number) => Record<string, unknown>' is not assignable to type '({ startRowIndex, endRowIndex, affectedColumns, }: { startRowIndex: number; endRowIndex: number; affectedColumns: ({ original: any; } | null)[]; }) => (originalRowData: any, index: number) => unknown'.
    createClearFieldsUpdater,
  });

  const { toggleMenu } = useGridMenuState();

  const startEditingInputCell = React.useCallback((
    row: RowData<any, any>,
    column: ColumnData<EditableGridColumn>,
    event?: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (row.original.isObsolete) return;

    pendingKeyboardEvent.current = event ?? null;

    setEditedCell({
      rowId: row.original._id,
      columnId: column.original._id,
    });
  }, [setEditedCell, pendingKeyboardEvent]);

  const columns: EditableGridColumn[] = React.useMemo(() => {
    return [
      {
        _id: 'rowNumber',
        accessorKey: 'rowNumber',
        Header: SimpleHeader,
        label: '',
        ValueCell: withProps(ValidationAwareRowNumberCell, { fieldName: 'lots' }),
        toggleMenu: isReadOnly ? undefined : toggleMenu,
        width: isReadOnly ? ACTION_COLUMN_WIDTH : 2 * ACTION_COLUMN_WIDTH,
        fixedWidth: true,
      },
      {
        _id: 'name',
        accessorKey: 'name',
        fieldType: FieldType.STRING,
        Header: SimpleHeader,
        label: t('request.lots.lotName'),
        required: !isReadOnly,
        startEditingCell: startEditingInputCell,
        InputCell: TextAreaInputCell,
        ValueCell: withProps(TextValueCell, { truncate: false, fieldName: 'lots' }),
        width: '40%' as any,
        showIconWhenObsolete: true,
      },
      {
        _id: 'description',
        accessorKey: 'description',
        fieldType: FieldType.STRING,
        Header: SimpleHeader,
        label: t('request.lots.lotDescription'),
        required: !isReadOnly,
        startEditingCell: startEditingInputCell,
        InputCell: TextAreaInputCell,
        ValueCell: withProps(TextValueCell, { truncate: false, fieldName: 'lots' }),
      },
    ];
  }, [isReadOnly, toggleMenu, t, startEditingInputCell]);

  const FooterContent = React.useMemo(() => {
    if (isReadOnly) {
      return null;
    } else {
      return ({ idPrefix }) => {
        return (
          <AddLotButton
            type="button"
            onKeyDown={stopPropagation}
            onClick={() => {
              appendRows([createLot()]);

              // using setTimeout to make sure that the scroll action
              // happens after the addition of the new row got created
              setTimeout(() => {
                const gridContainerId = getGridContainerId(idPrefix);
                const gridContainer = document.getElementById(gridContainerId);

                // @ts-expect-error ts(18047) FIXME: 'gridContainer' is possibly 'null'.
                gridContainer.scrollTop = gridContainer.scrollHeight;
              }, 1);
            }}
          />
        );
      };
    }
  }, [appendRows, isReadOnly]);

  const bodyPaddingBottom = FooterContent ? 49 : 0;

  return (
    <>
      {addGridRowsModal.isOpen && (
        <AddMoreItemsModal
          {...addGridRowsModal}
          heading={t('request.lots.dialog.addMoreLotsModal.heading')}
          info={(
            <Trans
              i18nKey="request.lots.dialog.addMoreLotsModal.info"
              values={{ count: additionalRowCount }}
              components={{ b: <b /> }}
            />
          )}
          onSubmit={(pasteMethod: GridPasteMethod) => {
            if (resolveWithPasteMethod.current) {
              resolveWithPasteMethod.current(pasteMethod);
            }
            resolveWithPasteMethod.current = null;
            addGridRowsModal.close();
          }}
          onCancel={() => {
            if (resolveWithPasteMethod.current) {
              resolveWithPasteMethod.current(null);
            }
            resolveWithPasteMethod.current = null;
            addGridRowsModal.close();
          }}
        />
      )}
      {isEmpty(lots) ? (
        isReadOnly ? (
          <Text fontSize={2}>
            {t('request.lots.noLotsAdded')}
          </Text>
        ) : (
          <DefaultEditableGridStyles
            style={{ width: '100%', height: '100%' }}
          >
            <PanelPadding p="10px">
              <Text color="subtext" fontSize={2}>
                {t('request.lots.noLotsAdded')}
              </Text>
              {!isReadOnly && (
                <AddLotButton
                  mt="20px"
                  type="button"
                  onKeyDown={stopPropagation}
                  onClick={() => {
                    appendRows([createLot()]);
                  }}
                />
              )}
            </PanelPadding>
          </DefaultEditableGridStyles>
        )
      ) : (
        <DefaultEditableGridStyles
          omitLastRowBorderBottom={isReadOnly}
          isReadOnly={isReadOnly}
          cellContentCss="align-items: flex-start; padding: 10px"
          activeCellContentCss="padding: 8px"
        >
          {isReadOnly ? (
            <ReadOnlyGrid
              columns={columns}
              rowData={lots as any}
              GridComponent={NonVirtualGrid}
              RenderDataCell={RenderTableDataCell}
            />
          ) : (
            <EditableGrid
              columns={columns}
              rowData={lots as any}
              bodyPaddingBottom={bodyPaddingBottom}
              FooterContent={FooterContent}
              gridActions={gridActions}
              onGridClipboardEvent={handleGridClipboardEvent}
              GridComponent={NonVirtualGrid}
              RenderDataCell={RenderTableDataCell}
              frozenFooterHeight={54}
              submitEditedCellOnScroll={false}
              navigableRange={navigableRange}
              selectableRange={selectableRange}
              highlightSelectionInHeaders
            />
          )}
        </DefaultEditableGridStyles>
      )}
      {menuReferenceId ? (
        <LotsGridMenu />
      ) : (
        null
      )}
    </>
  );
};
