import { useState } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { RfxAuctionLineItemsSection } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import * as rfx from '../../../rfx';
import { useRowData } from './AuctionLineItemsComparisonGrid';
import { AuctionLineItemsComparison } from './AuctionLineItemsComparison';

interface Props {
  isExpandedView: boolean;
  setIsExpandedView: (isExpandedView: boolean) => void;
}

export const AuctionObsoleteLineItems = ({ isExpandedView, setIsExpandedView }: Props) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const structure = rfx.useStructure();
  const section = rfx.useSection() as RfxAuctionLineItemsSection;
  const rowData = useRowData(section, structure, true);

  if (!rowData.length) {
    return null;
  }

  return (
    <Stack gap="20px" mb="64px">
      {!isExpandedView && (
        <Flex alignItems="center" sx={{ gap: '16px' }}>
          <Text>{t('request.auction.obsoleteLineItems', { count: rowData.length })}</Text>
          <Button variant="secondary-outline" onClick={() => setIsVisible((isVisible) => !isVisible)}>
            {isVisible ? t('request.auction.hideItems', { count: rowData.length }) : t('request.auction.showItems', { count: rowData.length })}
          </Button>
        </Flex>
      )}
      {isVisible && (
        <>
          {!isExpandedView && (
            <MessageBlock variant="info" mt={0}>
              {t('request.auction.obsoleteLineItemsMessage', { count: rowData.length })}
            </MessageBlock>
          )}
          <AuctionLineItemsComparison onlyObsoleteExchangeDefs isExpandedView={isExpandedView} setIsExpandedView={setIsExpandedView} />
        </>
      )}
    </Stack>
  );
};
