import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { Box } from 'rebass/styled-components';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';

import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Modal, ModalHeader, ModalFooter, ModalBody, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { useToaster } from '../../../toast';
import { useListCategories, useDefaultCategory, useDeleteCategory, useListCategoriesQueryKey, useListQuestionsQueryKey, PreQualTab } from '../utils';
import { CategorySelectorField } from '../CategorySelectorField';
import { FieldContainer } from '../../../form/FieldContainer';
import { preQualificationQuestionsRoute, usePreQualNavigation } from '../../../AppRouting';

export const DeleteCategoryModal = ({
  id,
  close,
}: {
  id: string;
  close: () => void;
}) => {
  const { t } = useTranslation(['preQualification']);
  const { data, isLoading } = useListCategories({
    filter: { categoryIds: [id] },
  });
  const categoryToDelete = data[0];
  const hasQuestionsAttached = categoryToDelete?.questionIds.length > 0;
  const defaultCategory = useDefaultCategory({ enabled: hasQuestionsAttached });
  const [deleteCategory, { isLoading: isMutationLoading }] = useDeleteCategory();
  const queryClient = useQueryClient();
  const getListCategoriesQueryKey = useListCategoriesQueryKey();
  const getListQuestionsQueryKey = useListQuestionsQueryKey();
  const toaster = useToaster();
  const { tab } = preQualificationQuestionsRoute.useSearch();

  const {
    navigateToPreQualificationQuestions,
  } = usePreQualNavigation();

  if (isLoading) return null;

  return (
    <Modal isOpen style={{ content: { width: '400px' } }}>
      <Formik<{ replacementCategoryId: string | undefined }>
        validationSchema={
          hasQuestionsAttached
            ? yup.object({ replacementCategoryId: yup.string().required() })
            : undefined
        }
        initialValues={{
          // @ts-expect-error ts(18049) FIXME: 'defaultCategory' is possibly 'null' or 'undefined'.
          replacementCategoryId: hasQuestionsAttached ? defaultCategory._id : undefined,
        }}
        onSubmit={({ replacementCategoryId }) => {
          const categoryToRedirect = replacementCategoryId || PreQualTab.ALL_CATEGORIES;
          return deleteCategory(id, replacementCategoryId, {
            onSuccess: callAll(
              () => {
                const listCategoriesQueryKey = getListCategoriesQueryKey();
                queryClient.invalidateQueries(listCategoriesQueryKey);

                const listQuestionsQueryKey = getListQuestionsQueryKey(categoryToRedirect);
                queryClient.invalidateQueries(listQuestionsQueryKey);
              },
              () => {
                if (tab === id) {
                  navigateToPreQualificationQuestions(categoryToRedirect);
                }
              },
              () => close(),
              () => toaster.success(t('success.categoryDeleted')),
            ),
            onError: () => toaster.error(t('errors.categoryDeleteError')),
          });
        }}
      >
        {({ isValid }) => (
          <Form>
            <ModalHeader>{t('deleteCategoryModal.heading')}</ModalHeader>
            <ModalBody>
              <MessageBlock variant="warn" mt={0} mb={3}>
                {hasQuestionsAttached
                  ? t('deleteCategoryModal.infoAttachedQuestions')
                  : t('deleteCategoryModal.infoNoQuestions')
                }
              </MessageBlock>

              <FieldContainer label={t('deleteCategoryModal.categoryNameLabel')}>
                {categoryToDelete.name}
              </FieldContainer>

              {hasQuestionsAttached && (
                <Box mt={3}>
                  <CategorySelectorField
                    required
                    // @ts-expect-error ts(2322) FIXME: Type '(category: PreQualCategory) => false | { disabled: true; }' is not assignable to type '(category: PreQualCategory) => { label?: string | undefined; value?: string | undefined; disabled?: boolean | undefined; }'.
                    map={(category) => category._id === id && { disabled: true }}
                    fieldName="replacementCategoryId"
                  />
                </Box>
              )}

            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={close} disabled={isMutationLoading} />
              <Button type="submit" variant="danger" mr={2} disabled={!isValid || isMutationLoading}>
                {t('deleteCategoryModal.submitLabel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
