import { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AuctionLineItemExchangeDefinition,
  Draft,
  ExchangeType,
  LineItemExchangeDefinition,
  SectionType,
  StageType,
  isLineItemExchangeDef,
  isLinkedAuctionLineItemExchangeDef,
  getExchangeDefFieldValue,
} from '@deepstream/common/rfq-utils';
import { find, findIndex, first, flatMap, isEmpty, partition, propertyOf, sumBy } from 'lodash';
import { Box, Text } from 'rebass/styled-components';
import { useField } from 'formik';
import styled from 'styled-components';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { CancelButton, Modal, ModalBody, ModalFooter, ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import * as rfx from '../rfx';
import { createLinkedAuctionLineItem } from './exchangeDefs';
import { StaticTableStyles } from '../TableStyles';
import { Table } from '../Table';
import { TruncateCell } from '../TruncateCell';
import { nestCells } from '../nestCells';
import { ExchangeDefDescriptionCell, NumberCell, ObsoleteCell } from './cell';

import { ValueOrDashCell } from '../ValueOrDashCell';

const SectionRow = styled.td`
  background-color: ${props => props.theme.colors.lightGray3};
  font-size: 10px;
  color: ${props => props.theme.colors.subtext};
  letter-spacing: 0.0833em;
  text-transform: uppercase;
`;

const CustomRowCells = ({ row }) => row.original.type === SectionType.LINE_ITEMS ? (
  <SectionRow colSpan={5}>
    {row.original.name}
  </SectionRow>
) : (
  row.cells.map(cell => {
    const { key, ...props } = cell.getCellProps({
      style: {
        width: cell.column.width,
        textAlign: (cell.column as any).textAlign,
        verticalAlign: (cell.column as any).verticalAlign,
      },
    });
    return (
      <td key={key} {...props}>
        {cell.render('Cell')}
      </td>
    );
  })
);

const useColumns = () => {
  const { t } = useTranslation();
  const { stages } = rfx.useStructure<Draft>();

  return useMemo(() => [
    {
      accessor: 'description',
      Cell: nestCells(ObsoleteCell, ExchangeDefDescriptionCell),
      Header: t('general.description'),
    },
    {
      accessor: (exchangeDef: LineItemExchangeDefinition) =>
        findIndex(stages, { _id: first(exchangeDef.stages) }) + 1,
      Header: t('general.stage', { count: 1 }),
      Cell: nestCells(ObsoleteCell, TruncateCell),
      width: '16%',
    },
    {
      accessor: (exchangeDef: LineItemExchangeDefinition) => {
        if (exchangeDef.type !== ExchangeType.LINE_ITEM) {
          return null;
        }
        // @ts-expect-error ts(2345) FIXME: Argument of type 'string' is not assignable to parameter of type 'never'.
        return getExchangeDefFieldValue(exchangeDef, 'unit');
      },
      Header: t('general.unit'),
      Cell: nestCells(
        ObsoleteCell,
        nestCells(TruncateCell, ValueOrDashCell),
      ),
      width: '16%',
    },
    {
      accessor: (exchangeDef: LineItemExchangeDefinition) => {
        if (exchangeDef.type !== ExchangeType.LINE_ITEM) {
          return null;
        }
        // @ts-expect-error ts(2345) FIXME: Argument of type 'string' is not assignable to parameter of type 'never'.
        return getExchangeDefFieldValue(exchangeDef, 'quantity');
      },
      Header: t('general.quantity'),
      Cell: nestCells(ObsoleteCell, NumberCell),
      width: '16%',
    },
  ], [t, stages]);
};

const useData = () => {
  const { pages, stages, sectionById, exchangeDefById } = rfx.useStructure<Draft>();
  const [{ value: currency }] = useField<string>('currency');
  const [{ value: auctionLineItemExchangeDefs }] =
    useField<AuctionLineItemExchangeDefinition<Draft>[]>('lineItemExchangeDefs');

  return useMemo(
    () => {
      const auctionStageId = find(stages, { type: StageType.AUCTION })?._id;

      if (!auctionStageId) {
        return [];
      }

      const rfxLineItemExchangeIds = auctionLineItemExchangeDefs
        .filter(isLinkedAuctionLineItemExchangeDef)
        .map(({ linkedExchangeDefId }) => linkedExchangeDefId);

      const sectionIds = flatMap(
        pages,
        page => page.sections,
      );

      const lineItemSections = sectionIds
        .map(propertyOf(sectionById))
        .filter(section => (
          section.type === SectionType.LINE_ITEMS &&
          section.stages?.includes(auctionStageId)
        ));

      const sectionsWithExchanges = lineItemSections.map(section => {
        const sectionExchangeDefs = section.exchangeDefIds
          // omit line items that have already been added
          .filter(exchangeId => !rfxLineItemExchangeIds.includes(exchangeId))
          .map(propertyOf(exchangeDefById))
          .filter(exchangeDef => (
            exchangeDef.type === ExchangeType.LINE_ITEM
             ? (
               // prevent linking to previous line items
               // that don't have the required fields
                exchangeDef.fields.unit &&
                exchangeDef.fields.quantity &&
               exchangeDef.fields.price &&
               exchangeDef.fields.totalCost
             )
             : true
          ));

        const [lineItemExchangeDefs, currencyExchangeDefs] =
          partition(sectionExchangeDefs, isLineItemExchangeDef);

        return {
          section,
          currencyExchangeDefs,
          lineItemExchangeDefs,
        };
      });

      const filteredSectionsWithExchanges = sectionsWithExchanges
        .filter(({ currencyExchangeDefs, lineItemExchangeDefs }) => (
          // only include sections with matching currency
          (currencyExchangeDefs[0]?.currencies.length === 1 &&
          currencyExchangeDefs[0]?.currencies[0] === currency) &&
          // only include sections with at least one line item to add
          !isEmpty(lineItemExchangeDefs)
        ));

      return flatMap(
        filteredSectionsWithExchanges,
        ({ section, lineItemExchangeDefs }) => [section, ...lineItemExchangeDefs],
      );
    },
    [pages, stages, currency, exchangeDefById, auctionLineItemExchangeDefs, sectionById],
  );
};

export const AddPreviousLineItemsModal = ({
  isOpen,
  onCancel,
  onSuccess,
}: {
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const [{ value: auctionLineItemExchangeDefs },, formik] =
    useField<AuctionLineItemExchangeDefinition<Draft>[]>('lineItemExchangeDefs');

  const [selectedLineItems, setSelectedLineItems] =
    useState<LineItemExchangeDefinition[]>([]);

  const columns = useColumns();
  const data = useData();

  const setSelectedRows = useCallback(
    selectedRows => setSelectedLineItems(
      selectedRows.filter(row => row.type !== SectionType.LINE_ITEMS),
    ), []);

  const addSelectedLineItems = useCallback(() => {
    formik.setValue([
      ...auctionLineItemExchangeDefs,
      ...selectedLineItems.map(createLinkedAuctionLineItem),
    ]);
    onSuccess();
  }, [formik, auctionLineItemExchangeDefs, selectedLineItems, onSuccess]);

  const lineItemTotalCount = sumBy(data, item => item.type === ExchangeType.LINE_ITEM ? 1 : 0);

  return (
    <Modal
      shouldCloseOnEsc
      isOpen={isOpen}
      onRequestClose={onCancel}
      style={{ content: { width: '810px' } }}
    >
      <ModalHeader onClose={onCancel}>
        {t('request.auction.previousLineItems.label')}
      </ModalHeader>
      <ModalBody p={0} mt={3}>
        <StaticTableStyles
          headerColor="lightNavy"
          smallHeader
          stickyHeader
          fixedRowHeight={false}
          selectedColor="primaryBackground"
        >
          <Table
            columns={columns}
            data={data}
            // hide select-all checkbox in header when there's no data
            setSelectedRows={isEmpty(data) ? undefined : setSelectedRows}
            CustomRowCells={CustomRowCells}
            noDataPlaceholder={t('request.auction.noLineItemsToAdd')}
            selectionColumnWidth={35}
          />
        </StaticTableStyles>
      </ModalBody>
      <ModalFooter justifyContent="space-between">
        <Text fontSize={2} mb={1}>
          {isEmpty(data) ? (
            null
          ) : (selectedLineItems.length === lineItemTotalCount) ? (
            t('request.auction.allLineItemsSelected')
          ) : (
            t('request.auction.selectedLineItemCount', { count: selectedLineItems.length })
          )}
        </Text>
        <Box>
          <CancelButton type="button" onClick={onCancel} mr={2} />
          <Button
            type="submit"
            variant="primary"
            disabled={isEmpty(selectedLineItems)}
            onClick={addSelectedLineItems}
          >
            {t('request.auction.addSelection')}
          </Button>
        </Box>
      </ModalFooter>
    </Modal>
  );
};
