import { Box } from 'rebass/styled-components';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SelectDropdownMenu } from '../../../ui/MultiSelect';
import { SuppliersGridConfig } from './useSuppliersGridConfig';

const WhiteColorOnParentButtonHover = styled.span`
  color: ${props => props.theme.colors.subtext};
  transition: color 300ms;

  button:hover & {
    color: white;
  }
`;

export const SelectGridViewDropdown = ({
  selectSuppliersGridView,
}: {
  selectSuppliersGridView: SuppliersGridConfig['view'];
}) => {
  const { t } = useTranslation('translation');

  return (
    <SelectDropdownMenu
      buttonIcon="bars"
      buttonText={
        <>
          {t('request.suppliersTable.view_other')}
          <WhiteColorOnParentButtonHover>
            <Tooltip
              content={t('request.suppliersTable.viewsInfo')}
              placement="right-end"
            >
              <Icon icon="info-circle" regular ml={1} />
            </Tooltip>
          </WhiteColorOnParentButtonHover>
        </>
      }
      menuWidth={225}
      menuZIndex={151}
      menuMaxHeightInItems={20}
      neverShowCaret
      variant="secondary-outline"
      footer={
        <Box mt="10px" ml="30px" mr="10px" mb="4px" fontSize={1} color="subtext" lineHeight="1.33">
          <Icon icon="info-circle" regular mr={1} />
          {t('request.suppliersTable.viewsDropdownFooter')}
        </Box>
      }
      {...selectSuppliersGridView}
    />
  );
};
