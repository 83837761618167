import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { Text } from 'rebass/styled-components';
import { PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { first } from 'lodash';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { getItemLabelWithIcon, getItemLabelWithIconAndDescription, SelectField } from '../form/SelectField';
import { NO_LOT_PROXY_ID, useLotSelectItems } from './useLotSelectItems';
import { useConfirmDialog } from '../ui/useModalState';

export const NoLotsAddedFooter = () => {
  const { t } = useTranslation('translation');

  return (
    <>
      <PanelDivider />
      <Text px="14px" pt="10px" pb="3px" color="subtext">
        {t('request.lots.noLotsAdded')}
      </Text>
    </>
  );
};

export const SectionConfigLotSelectField = ({
  name,
  disabled,
  staticValue,
  isEvaluation,
  isLinkedSection,
  canSelectObsoleteItem,
  onChange,
}: {
  name: string;
  disabled?: boolean;
  staticValue?: string | null;
  isEvaluation?: boolean;
  isLinkedSection?: boolean;
  canSelectObsoleteItem?: boolean;
  onChange: (newValue: string[] | null, isNewValueObsolete: boolean, wasPreviousValueObsolete: boolean) => void;
}) => {
  const { t } = useTranslation('translation');
  const [{ value: lotIds }] = useField({ name });
  const lotSelectItems = useLotSelectItems({ isEvaluation });

  const adjustedLotSelectItems = useMemo(() => {
    return canSelectObsoleteItem
      ? lotSelectItems
      : lotSelectItems.map(item => item.isObsolete ? { ...item, disabled: true } : item);
  }, [canSelectObsoleteItem, lotSelectItems]);

  const { confirm, ...dialogProps } = useConfirmDialog();

  return (
    <>
      <SelectField
        name={name}
        label={t('request.lots.sectionLotConfiguration')}
        description={!isEvaluation ? (
          t('request.lots.sectionLotConfigurationDescription')
        ) : isLinkedSection ? (
          t('request.lots.linkedEvaluationSectionLotConfigurationDescription')
        ) : (
          t('request.lots.evaluationSectionLotConfigurationDescription')
        )}
        items={adjustedLotSelectItems}
        value={staticValue || first(lotIds) || NO_LOT_PROXY_ID}
        onChange={(newLotId: string) => {
          if (disabled) {
            // don't save when component is disabled
            return;
          }

          const currentLotValue = first(lotIds) || NO_LOT_PROXY_ID;
          const currentLotSelectItem = adjustedLotSelectItems.find(selectItem => selectItem.value === currentLotValue);
          if (!currentLotSelectItem) {
            throw new Error(`Unexpected. Could not find currentLotSelectItem for ${currentLotValue}`);
          }

          const newLotSelectItem = adjustedLotSelectItems.find(selectItem => selectItem.value === newLotId);
          if (!newLotSelectItem) {
            throw new Error(`Unexpected. Could not find newLotSelectItem for ${newLotId}`);
          }

          const isCurrentLotObsolete = currentLotSelectItem.isObsolete;
          const isNewLotObsolete = newLotSelectItem.isObsolete;

          const handleChange = async () => {
            const newValue = newLotId === NO_LOT_PROXY_ID
              ? null
              : [newLotId];

            // @ts-expect-error ts(2345) FIXME: Argument of type 'boolean | undefined' is not assignable to parameter of type 'boolean'.
            onChange(newValue, isNewLotObsolete, isCurrentLotObsolete);
          };

          if (isNewLotObsolete && !isCurrentLotObsolete) {
            confirm(handleChange);
          } else {
            handleChange();
          }
        }}
        disabled={disabled}
        helperText={first(lotIds) ? (
          isEvaluation
            ? t('request.lots.evaluationSectionLotConfigurationLotHelperText')
            : t('request.lots.sectionLotConfigurationLotHelperText')
        ) : (
          isEvaluation
            ? t('request.lots.evaluationSectionLotConfigurationNoLotHelperText')
            : t('request.lots.sectionLotConfigurationNoLotHelperText')
        )}
        getItemLabel={getItemLabelWithIconAndDescription}
        getButtonLabel={getItemLabelWithIcon}
        menuWidth={300}
        buttonStyle={{ fontWeight: 500, width: 300 }}
        renderPreItemContent={(_item, index) => index === 1 ? <PanelDivider /> : null}
        footer={adjustedLotSelectItems.length === 1 ? <NoLotsAddedFooter /> : undefined}
      />
      {dialogProps.isOpen && (
        <Dialog
          heading={t('request.lots.dialog.assignToObsoleteLotModal.heading')}
          body={
            <MessageBlock variant="warn" my={0} lineHeight={1.5}>
              <Text mb={2}>
                {t('request.lots.dialog.assignToObsoleteLotModal.warning1')}
              </Text>
              <Text>
                {t('request.lots.dialog.assignToObsoleteLotModal.warning2')}
              </Text>
            </MessageBlock>
          }
          style={{ content: { width: '500px' } }}
          okButtonText={t('request.lots.dialog.assignToObsoleteLotModal.okButtonText')}
          okButtonVariant="danger"
          cancelButtonText={t('request.lots.dialog.assignToObsoleteLotModal.cancelButtonText')}
          {...dialogProps}
        />
      )}
    </>
  );
};
