import { MultiStageLineItemsEventType } from '@deepstream/common/multiStageLineItemsTracking';
import * as rfx from '../../../rfx';
import { useRfqId } from '../../../useRfq';
import { useTracking } from '../../../useTracking';

export const useTrackSetupMultiStageResponsesEvent = ({
  eventType,
}: {
  eventType: MultiStageLineItemsEventType;
}) => {
  const { trackMultiStageLineItemsEvent } = useTracking();
  const rfqId = useRfqId();
  const { isTemplate } = rfx.useState();

  return () =>
    trackMultiStageLineItemsEvent({
      eventType,
      entityType: isTemplate ? 'requestTemplate' : 'request',
      entityId: rfqId,
    });
};
