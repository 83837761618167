import { useQueryClient } from 'react-query';
import { Action, RfqId, CompanyId, UserMinimized, ActionType } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { isNil, omitBy } from 'lodash';
import { callAll } from '@deepstream/utils/callAll';
import { useApi } from '../api';
import {
  useLiveRfqStructureQueryKey,
  useRfqExchangesQueryKey,
} from '../useRfq';
import { useMutation } from '../useMutation';
import * as rfx from '../rfx';
import { useToaster } from '../toast';
import { LineItemsExchangeSnapshot } from '../types';
import { useCurrentCompanyId } from '../currentCompanyId';

export type SendBatchedExchangeReplyPayload = {
  exchangeId: string;
  companyId: string;
  action: Omit<Action, 'companyId' | 'user' | 'date'> & {
    user?: UserMinimized;
    date?: Date;
  };
};

export const convertToBatchedExchangeReplyPayload = (
  lineItems: LineItemsExchangeSnapshot[],
  companyId: string,
  submissionMethod: 'app' | 'excel' = 'excel',
): SendBatchedExchangeReplyPayload[] => {
  return lineItems.map((lineItem) => ({
    exchangeId: lineItem._id,
    companyId,
    action: {
      value: ActionType.SUBMIT,
      submissionMethod,
      ...omitBy(lineItem.latestReply, isNil),
    },
  }));
};

export const useSendBatchedExchangeReply = ({
  rfqId,
  recipientId,
  sectionIds,
}: {
  rfqId: RfqId;
  recipientId?: CompanyId;
  sectionIds?: string[];
}) => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const api = useApi();
  const queryClient = useQueryClient();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const senders = rfx.useSenders();

  const rfqStructureQueryKey = useLiveRfqStructureQueryKey({
    currentCompanyId,
    rfqId,
    recipientId,
  });
  const exchangesQueryKey = useRfqExchangesQueryKey({
    sectionIds,
    recipientId,
  });

  return useMutation(
    async (actions: Array<SendBatchedExchangeReplyPayload>) => {
      const payload = {
        rfqId,
        senderId: senders[0]._id,
        recipientId,
        actions: actions.map((action) => ({
          exchangeId: action.exchangeId,
          companyId: currentCompanyId,
          action: {
            ...action.action,
            companyId: currentCompanyId,
          },
        })),
      };
      await api.sendBatchedExchangeReplies(payload);
    },
    {
      mutationKey: ['sendBatchedExchangeReply'],
      onSettled: callAll(
        () => queryClient.invalidateQueries(rfqStructureQueryKey),
        () => queryClient.invalidateQueries(exchangesQueryKey),
      ),
      onSuccess: () => toaster.success(t('request.toaster.actionSubmittedSuccess')),
      onError: () => toaster.error(t('request.toaster.actionSubmittedError')),
    },
  );
};
