import { Draft } from '@deepstream/common/rfq-utils';
import * as rfx from '../rfx';
import { SummaryTemplateDetailsPanel } from './SummaryTemplateDetailsPanel';
import { SummaryReferenceNumbersPanel } from './SummaryReferenceNumbersPanel';
import { SummaryBasicDetailsPanel } from './SummaryBasicDetailsPanel';
import { SummaryProductsAndServicesPanel } from './SummaryProductsAndServicesPanel';
import { SummaryCurrencyPanel } from './SummaryCurrencyPanel';
import { SummaryLotsPanel } from './SummaryLotsPanel';

export const SummaryPanels = ({
  hideCurrency,
}: {
  hideCurrency?: boolean;
}) => {
  const structure = rfx.useStructure<Draft>();
  const { isTemplate } = rfx.useState();
  const isSender = rfx.useIsSender();

  return (
    <>
      {isTemplate && <SummaryTemplateDetailsPanel />}
      <SummaryBasicDetailsPanel />
      <SummaryProductsAndServicesPanel />
      <SummaryReferenceNumbersPanel />
      {(isSender && !hideCurrency) && <SummaryCurrencyPanel />}
      {isSender && !structure.newFeaturesDisabled && <SummaryLotsPanel />}
    </>
  );
};
