import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';
import { usePopover } from '@deepstream/ui-kit/elements/popup/usePopover';
import { ConfirmationPopover } from '@deepstream/ui-kit/elements/popup/ConfirmationPopover';
import { Modal, ModalBody, ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';

export type StageApprovalBaseModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  needsCloseConfirmation?: () => boolean;
  withHeaderDivider?: boolean;
  height: string;
  props?: any;
  children: React.ReactNode;
};

export const StageApprovalBaseModal = ({
  isOpen,
  onClose,
  title,
  needsCloseConfirmation = () => false,
  withHeaderDivider = true,
  height,
  children,
  ...props
}: StageApprovalBaseModalProps) => {
  const { t } = useTranslation('general');

  const popover = usePopover({
    placement: 'right-start',
    strategy: 'fixed',
    overReferenceElement: true,
  });

  const handleClose = React.useCallback(() => {
    if (needsCloseConfirmation()) {
      if (popover.update) {
        popover.update();
      }
      popover.open();
    } else {
      onClose();
    }
  }, [needsCloseConfirmation, onClose, popover]);

  return (
    <>
      <Modal style={{ content: { width: '700px' } }} {...props} isOpen={isOpen}>
        {/*
         // @ts-expect-error ts(2322) FIXME: Type 'Dispatch<SetStateAction<HTMLElement | undefined>>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'. */}
        <div ref={popover.setReferenceElement}>
          <ModalHeader
            onClose={handleClose}
            divider={withHeaderDivider}
          >
            {title}
          </ModalHeader>
          <ModalBody
            height={height}
            maxHeight={height}
            overflowY="auto"
            p={0}
            style={{
              opacity: popover.isOpen ? 0.2 : 1,
              transition: 'opacity 0.4s ease-out',
              ...(popover.isOpen && {
                pointerEvents: 'none',
              }),
            }}
          >
            {children}
          </ModalBody>
        </div>
      </Modal>
      <ConfirmationPopover
        width="100%"
        maxWidth="265px"
        popover={popover}
        heading={t('areYouSure')}
        body={<Text mb={2}>{t('closeAndLoseDetails')}</Text>}
        okButtonText={t('close')}
        okButtonVariant="danger"
        cancelButtonText={t('review.collaboratorInviteModal.goBackLabel', {
          ns: 'request',
        })}
        onOk={onClose}
        onCancel={popover.close}
      />
    </>
  );
};
