import { Live, PageType } from '@deepstream/common/rfq-utils';
import { useState, useMemo } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { CancelButton, Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { CheckboxList } from '../../../CheckboxList';
import { Label } from '../../../form/Field';
import useDownload from '../../../useDownload';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useRfqId } from '../../../useRfq';
import * as rfx from '../../../rfx';
import { useCurrentUserLocale } from '../../../useCurrentUser';

enum DownloadStateEnum {
  HIDDEN = 'hidden',
  PREPARING = 'preparing',
  ERROR = 'error',
  SUCCESS = 'success',
}

const IconText = ({ text, icon, variant }) => (
  <Flex alignItems="center">
    <Icon icon={icon} mr={2} color={variant} />
    <Text color={variant}>{text}</Text>
  </Flex>
);

const downloadStateConfig = {
  [DownloadStateEnum.PREPARING]: { icon: 'spinner', variant: 'info' },
  [DownloadStateEnum.ERROR]: { icon: 'check', variant: 'success' },
  [DownloadStateEnum.SUCCESS]: { icon: 'exclamation-triangle', variant: 'danger' },
};

const DownloadState = ({ state }: { state: DownloadStateEnum }) => {
  const { t } = useTranslation();

  return state === DownloadStateEnum.HIDDEN ? (
    null
  ) : (
    <IconText
      text={t(`request.suppliersTable.downloadState.${state}`)}
      {...downloadStateConfig[state]}
    />
  );
};

export const DownloadRequestsFilesModal = ({
  recipientIds,
  onCancel,
  onSave,
  ...props
}: {
  recipientIds: string[];
  onCancel: () => void;
  onSave: () => void;
} & ModalProps) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const locale = useCurrentUserLocale();
  const rfqId = useRfqId({ required: true });
  const { pages } = rfx.useStructure<Live>();
  const [selectedIds, setSelectedIds] = useState([] as string[]);
  const download = useDownload();
  const [downloadState, setDownloadState] = useState(DownloadStateEnum.HIDDEN);

  const items = useMemo(() => {
    const filteredPages = pages
      // @ts-expect-error ts(2345) FIXME: Argument of type 'PageType | undefined' is not assignable to parameter of type 'PageType'.
      .filter(page => ![PageType.EVALUATION, PageType.AUCTION].includes(page.type));

    const sortedPages = sortBy(filteredPages, (page) => page.type === PageType.CHAT ? 1 : 0);

    return sortedPages.map(page => ({
      _id: page._id,
      name: page.type === PageType.CHAT
        ? t('request.pageName.messages')
        : page.name,
    }));
  }, [pages, t]);

  const downloadFiles = async () => {
    if (!recipientIds.length) return;
    setDownloadState(DownloadStateEnum.PREPARING);
    try {
      await download(
        `/download/company/${currentCompanyId}/rfq/${rfqId}/attachments`,
        'POST',
        { pageIds: selectedIds, recipientIds, locale, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone },
      );
      setDownloadState(DownloadStateEnum.SUCCESS);
      onSave();
    } catch (error) {
      setDownloadState(DownloadStateEnum.ERROR);
    }
  };

  return (
    <Modal
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={onCancel}
      style={{ content: { maxWidth: '500px' } }}
      {...props}
    >
      <ModalHeader onClose={onCancel}>
        {t('request.suppliersTable.downloadSupplierDocuments')}
      </ModalHeader>
      <ModalBody>
        <MessageBlock variant="info" mb={2} mt={0}>
          {t('request.suppliersTable.onlyUnlockedDocuments')}
        </MessageBlock>

        <Box mt={3}>
          <Label label={t('request.suppliersTable.selectPagesToDownload')} mb={3} />
          <CheckboxList
            value={selectedIds}
            items={items}
            onChange={setSelectedIds}
          />
        </Box>
      </ModalBody>
      <ModalFooter justifyContent="space-between">
        <Box>
          <DownloadState state={downloadState} />
        </Box>
        <Flex>
          <CancelButton onClick={onCancel} />
          <Button
            onClick={downloadFiles}
            disabled={!selectedIds.length || downloadState === DownloadStateEnum.PREPARING}
          >
            {t('general.download')}
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};
