import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import * as yup from 'yup';
import { callAll } from '@deepstream/utils/callAll';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useApi, wrap } from '../../api';
import { useToaster } from '../../toast';
import { useMutation } from '../../useMutation';
import { ModalForm } from '../../ModalForm';
import { TextField } from '../../form/TextField';
import { useNavigate } from '../../tanstackRouter';
import { contractTemplateSummaryRoute } from '../../AppRouting';

type NewBlankContractTemplateModalProps = {
  isOpen: boolean;
  close: any;
};

export const NewBlankContractTemplateModal: React.FC<NewBlankContractTemplateModalProps> = ({
  isOpen,
  close,
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: templates } = useQuery(
    ['contractTemplates', { currentCompanyId }],
    wrap(api.getContractTemplates),
  );

  const [createEmptyTemplate] = useMutation(
    api.createEmptyContractTemplate,
    {
      onSettled: callAll(
        () => queryClient.invalidateQueries(['contractTemplates', { currentCompanyId }]),
        close,
      ),
      onSuccess: callAll(
        () => toaster.success(t('requests.toaster.templateCreated.success')),
        (template) => {
          navigate({
            to: contractTemplateSummaryRoute.to,
            params: { currentCompanyId, contractId: template._id },
          });
        },
      ),
      onError: () => toaster.error(t('requests.toaster.templateCreated.failed')),
    },
  );

  const initialValues = { name: '' };

  const createTemplate = React.useCallback(
    ({ name }) => createEmptyTemplate({
      currentCompanyId,
      name,
    }),
    [currentCompanyId, createEmptyTemplate],
  );

  return templates ? (
    (
      <ModalForm
        heading={t('requests.dialog.newTemplate.heading')}
        initialValues={initialValues}
        validationSchema={
          yup.object().shape({
            name: yup.string()
              .required(t('general.required'))
              .test(
                'uniqueName',
                t('template.errors.duplicateTemplateName'),
                value => !templates.find(template =>
                  template.meta.name.toLowerCase() === value?.toLowerCase().trim(),
                ),
              ),
          })
        }
        disableSubmitIfNotDirty={true}
        isOpen={isOpen}
        onCancel={close}
        onSubmit={createTemplate}
        submitLabel={t('requests.dialog.newTemplate.okButtonText')}
      >
        <TextField name="name" label={t('template.templateName')} required />
      </ModalForm>
    )
  ) : null;
};
