import styled from 'styled-components';
import { Flex } from 'rebass/styled-components';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';

const Container = styled(Flex)`
  display: inline-flex;
  align-items: center;
  height: 16px;
  color: ${props => props.theme.colors.gray} !important;
  border: 1px solid ${props => props.theme.colors.gray};
  border-radius: 12px;
  padding: 8px;
  max-width: 100%;
  font-size: 12px;

  &:hover {
    cursor: pointer;
    color: ${props => props.theme.colors.primary} !important;
    border-color: ${props => props.theme.colors.primary};
    text-decoration: none;
  }
`;

interface Props {
  onClick: () => void;
  text: string;
  tooltip: string;
}

export const LinkChip = ({ onClick, tooltip, text }: Props) => {
  return (
    <Tooltip content={tooltip} placement="top">
      <div style={{ display: 'inline-block', marginLeft: '2px' }}>
        <Container role="button" onClick={onClick}>
          <IconText icon="link" text={text} />
        </Container>
      </div>
    </Tooltip>
  );
};
