import { RfxSection, Section } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { ErrorMessage } from '../ui/ErrorMessage';
import { SectionWithPosition } from '../rfx';
import * as draft from './draft';
import * as validation from './validation';
import { LinkChip } from '../ui/LinkChip';
import { useRequestEditNavigation } from '../appNavigation';
import * as rfx from '../rfx';

export const SectionPanelHeading = ({
  section,
  isHeadingNumbered,
}: {
  section: SectionWithPosition<Section | RfxSection>;
  isHeadingNumbered?: boolean;
}) => {
  const { t } = useTranslation();
  const showValidationErrors = draft.useShowValidationErrors();
  const { error } = validation.useError('section.name');
  const linkedSection = draft.useLinkedSection();
  const linkedSectionTarget = draft.useLinkedSectionTarget();
  const { navigateToDetailsPage, navigateToEvaluationPage } = useRequestEditNavigation();
  const { isTemplatePreview, isLive } = rfx.useState();

  const showLinkedSectionTarget = !isLive && !isTemplatePreview;

  return (
    <Flex sx={{ wordBreak: 'break-word', whiteSpace: 'normal', gap: '8px' }} alignItems="center">
      {isHeadingNumbered ? (
        <Text color="subtext" mr={2} flex="0 0 auto">
          {section.number}
        </Text>
      ) : (
        null
      )}
      {section.isObsolete && <Icon icon="ban" color="subtext" fixedWidth fontSize="inherit" />}
      {showValidationErrors && error ? (
        <ErrorMessage fontSize="18px" error={error} style={{ marginTop: '1px' }} />
      ) : (
        <Text color={section.isObsolete || !section.name ? 'subtext' : 'text'} mt="1px">
          {section.name || t('general.untitled')}
        </Text>
      )}
      {showLinkedSectionTarget && linkedSectionTarget && (
        <LinkChip
          onClick={() => {
            if (linkedSectionTarget.tab === 'evaluation') {
              navigateToEvaluationPage(linkedSectionTarget.pageId);
            } else {
              navigateToDetailsPage(linkedSectionTarget.pageId);
            }
          }}
          text={linkedSection ? t('general.details') : t('general.evaluation')}
          tooltip={t('linkedSectionTooltip', { ns: 'evaluation' })}
        />
      )}
    </Flex>
  );
};
