import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CollaboratorInviteStatus } from '@deepstream/common/rfq-utils';
import * as rfx from './rfx';
import { useCurrentCompanyId } from './currentCompanyId';
import { RfqIdProvider, useLiveRfqStructure } from './useRfq';
import { LoadingPanel } from './ui/Loading';
import { ErrorPanel } from './ui/ErrorMessage';
import { RequestTeam } from './modules/Request/Team/RequestTeam';
import { useSaveLiveTeamChanges } from './useSaveRequestTeamChanges';
import { requestSentTeamRoute } from './AppRouting';
import { useRequestSentNavigation } from './appNavigation';

const RequestTeamWrapper = () => {
  const [saveChanges] = useSaveLiveTeamChanges();
  const search = requestSentTeamRoute.useSearch();
  const { senders } = rfx.useStructure();
  const navigation = useRequestSentNavigation();
  const currentCompanyId = useCurrentCompanyId();

  const nonRejectedSenders = senders.filter(sender =>
    sender.inviteStatus !== CollaboratorInviteStatus.REJECTED,
  );

  const selectedCompanyIndex = nonRejectedSenders.findIndex(
    sender => sender._id === String(search.companyId),
  );

  useEffect(
    () => {
      if (selectedCompanyIndex === -1) {
        // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string | undefined'.
        navigation.navigateToTeam({ companyId: currentCompanyId }, true);
      }
    },
    [currentCompanyId, navigation, selectedCompanyIndex],
  );

  return (
    <rfx.SaveChangesProvider saveChanges={saveChanges}>
      <RequestTeam
        selectCompanyId={(companyId: string) => navigation.navigateToTeam({ companyId })}
        selectedCompanyIndex={selectedCompanyIndex}
      />
    </rfx.SaveChangesProvider>
  );
};

export type LiveTeamProps = {
  rfqId: string;
  navigateToCompanyTeam: () => void;
};

export const LiveTeam = ({ rfqId, navigateToCompanyTeam }: LiveTeamProps) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { data: rfxStructure, isLoading, isError } = useLiveRfqStructure({
    rfqId,
    currentCompanyId,
  });

  return (
    <RfqIdProvider rfqId={rfqId}>
      <rfx.StateProvider isLive>
        <rfx.CompanyTeamNavigationProvider navigateToCompanyTeam={navigateToCompanyTeam}>
          {isLoading ? (
            <LoadingPanel />
          ) : isError ? (
            <ErrorPanel error={t('errors.unexpected')} />
          ) : rfxStructure ? (
            <rfx.StructureProvider structure={rfxStructure}>
              <RequestTeamWrapper />
            </rfx.StructureProvider>
          ) : (
            null
          )}
        </rfx.CompanyTeamNavigationProvider>
      </rfx.StateProvider>
    </RfqIdProvider>
  );
};
