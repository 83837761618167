import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Flex, Text, Heading } from 'rebass/styled-components';
import styled from 'styled-components';
import { isFunction } from 'lodash';
import { IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { BreadcrumbProps, Breadcrumbs } from '@deepstream/ui-kit/elements/navigation/Breadcrumbs';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { usePrevious } from '@deepstream/ui-kit/hooks/usePrevious';
import { PageContentContainer } from './page-helpers';
import { BANNER_HEIGHT, NAVBAR_HEIGHT, SMALL_PAGE_HEADER_HEIGHT } from './constants';
import { useEnvironmentBanner } from './useEnvironmentBanner';
import { MainNav } from './page-helpers/MainNav';
import { PublicPagesMainNav } from './page-helpers/PublicPagesMainNav';
import { PageHeaderTabs } from './page-headers/PageHeaderTabs';
import { PageHeading } from './page-headers/PageHeading';
import { PublicRequestPreviewBanner, usePublicRequestPreviewBanner } from './modules/Request/Public/PublicRequestPreviewBanner';
import { useSwitchCompany } from './CurrentCompanyIdProvider';
import { useSession } from './auth';
import { CollapsibleHeaderContext, useCollapsibleHeader } from './CollapsibleHeaderContext';

export const BannerContainer = styled(Box)<{ backgroundColor: string }>`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: ${BANNER_HEIGHT}px;
  z-index: 102;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.theme.colors.white};
`;

export const EnvironmentBanner = ({
  environmentName,
}: {
  environmentName: string
}) => {
  const theme = useTheme();

  return (
    <BannerContainer backgroundColor={theme.colors.warning}>
      <Text fontWeight={700}>
        {environmentName.toUpperCase()} SYSTEM
      </Text>
    </BannerContainer>
  );
};

export const OuterContainer = styled(Box)`
  line-height: normal;
  z-index: 11;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
`;

const InnerContainer = styled.div`
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
`;

export const PageHeaderWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <OuterContainer>
      <InnerContainer>
        {children}
      </InnerContainer>
    </OuterContainer>
  );
};

export const PageHeaderWithBreadcrumbs = ({
  heading,
  breadcrumbs,
  children,
}: {
  heading: string;
  breadcrumbs: BreadcrumbProps[];
  children?: React.ReactNode;
}) => {
  return (
    <PageHeaderWrapper>
      <Flex
        pt="14px"
        pb="6px"
        px="1px"
        justifyContent="spaceBetween"
        alignItems="flex-start"
      >
        <Box flex={1} pb={2}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <Heading as="h1" fontSize={6} fontWeight={500} lineHeight={1.25} mt="3px">
            <Truncate>
              {heading}
            </Truncate>
          </Heading>
        </Box>
        {children}
      </Flex>
    </PageHeaderWrapper>
  );
};

type PageHeaderProps = {
  heading: string | (() => React.ReactNode);
  icon?: IconProps['icon'];
  iconProps?: Omit<IconProps, 'icon'>;
  tabs?: any[];
  selectedTabId?: string;
  headerDataTest?: string;
  children?: React.ReactNode;
};

export const PageHeader = React.memo(({
  heading,
  icon,
  iconProps,
  tabs,
  selectedTabId,
  headerDataTest,
  children,
}: PageHeaderProps) => {
  const withTabs = tabs && !!tabs.length;

  const headingStyle = useMemo(
    (): React.CSSProperties => {
      return {
        alignItems: 'center',
        justifyContent: 'space-between',
        height: SMALL_PAGE_HEADER_HEIGHT,
        paddingLeft: 1,
      };
    },
    [],
  );

  return (
    <PageHeaderWrapper>
      <Flex style={headingStyle}>
        {isFunction(heading) ? (
          heading()
        ) : (
          <PageHeading
            icon={icon}
            iconProps={iconProps}
            text={heading}
            dataTest={headerDataTest}
          />
        )}
        {children}
      </Flex>
      {withTabs && (
        <PageHeaderTabs tabs={tabs!} selectedTabId={selectedTabId} />
      )}
    </PageHeaderWrapper>
  );
});

const DEFAULT_CONTENT_PADDING_BOTTOM = '30px';
// 30px + 72px for bottom toolbar
export const BOTTOM_TOOLBAR_CONTENT_PADDING_BOTTOM = '102px';

export const useAppHeaderHeight = () => {
  const environmentBanner = useEnvironmentBanner();
  const publicRequestPreviewBanner = usePublicRequestPreviewBanner();

  const numBanners = [environmentBanner, publicRequestPreviewBanner]
    .filter(banner => banner.isVisible)
    .length;

  const headerHeight = (numBanners * BANNER_HEIGHT) + NAVBAR_HEIGHT;

  return headerHeight;
};

export const HeaderAndContent = ({
  header,
  content,
  contentPaddingTop = '30px',
  contentPaddingBottom = DEFAULT_CONTENT_PADDING_BOTTOM,
  isContentFullWidth,
  shouldHeaderCollapseCompletely,
}: {
  header: React.ReactNode;
  content: React.ReactNode;
  contentPaddingTop?: string;
  contentPaddingBottom?: string;
  isContentFullWidth?: boolean;
  shouldHeaderCollapseCompletely?: boolean
}) => {
  const [headerContainer, setHeaderContainer] = useState<HTMLDivElement | null>(null);
  const headerHeight = headerContainer?.getBoundingClientRect().bottom || 0;
  const previousHeaderHeight = usePrevious(headerHeight);

  const {
    isCollapsed,
    translatePx,
    setTranslatePx,
  } = useContext(CollapsibleHeaderContext);

  useEffect(
    () => {
      if (headerContainer && isCollapsed) {
        setTranslatePx?.(-headerHeight + (shouldHeaderCollapseCompletely ? 0 : 20));
      }
    },
    [headerContainer, headerHeight, isCollapsed, setTranslatePx, shouldHeaderCollapseCompletely],
  );

  const appHeaderHeight = useAppHeaderHeight();

  return (
    <>
      <Box
        ref={setHeaderContainer}
        sx={{
          top: appHeaderHeight,
          position: 'sticky',
          zIndex: 101,
          transform: isCollapsed ? `translateY(${translatePx}px)` : undefined,
          transition: 'all 0.3s ease-in-out',
          '& > * > * > * > **:not(.keep-visible)': {
            opacity: isCollapsed ? 0 : 1,
            pointerEvents: isCollapsed ? 'none' : 'auto',
            transition: 'all 0.4s ease-in-out',
          },
        }}
      >
        {header}
      </Box>

      <PageContentContainer
        style={{
          paddingTop: contentPaddingTop,
          paddingBottom: contentPaddingBottom,
          maxWidth: isContentFullWidth ? '100%' : undefined,
          paddingLeft: isContentFullWidth ? '0' : undefined,
          paddingRight: isContentFullWidth ? '0' : undefined,
          transform: isCollapsed ? `translateY(${translatePx}px)` : undefined,
          height: shouldHeaderCollapseCompletely
            ? (isCollapsed ? '100%' : `calc(100% - ${(previousHeaderHeight || headerHeight)}px)`)
            : undefined,
        }}
      >
        {content}
      </PageContentContainer>
    </>
  );
};

export const BasicHeader = ({
  heading,
  icon,
  headerDataTest,
  contentPaddingTop,
  children,
}: {
  heading: string;
  icon?: IconProps['icon'];
  headerDataTest?: string;
  contentPaddingTop?: string;
  children: React.ReactNode;
}) => {
  return (
    <HeaderAndContent
      header={<PageHeader heading={heading} icon={icon} headerDataTest={headerDataTest} />}
      content={children}
      contentPaddingTop={contentPaddingTop}
    />
  );
};

export const MainNavigation = ({
  children,
}: {
  children: React.ReactNode,
}) => {
  const { logout } = useSession();
  const switchCompany = useSwitchCompany();
  const { isCollapsed } = useCollapsibleHeader();
  const environmentBanner = useEnvironmentBanner();
  const publicRequestPreviewBanner = usePublicRequestPreviewBanner();

  return (
    <>
      <div
        style={{
          top: 0,
          position: 'sticky',
          zIndex: 102,
          transform: isCollapsed ? 'translateY(-100%)' : undefined,
          transition: 'all 0.3s ease-in-out',
        }}
      >
        {environmentBanner.isVisible && (
          <EnvironmentBanner environmentName={environmentBanner.text} />
        )}
        {publicRequestPreviewBanner.isVisible && (
          <PublicRequestPreviewBanner />
        )}
        {publicRequestPreviewBanner.isVisible ? (
          <PublicPagesMainNav />
        ) : (
          <MainNav
            switchCompany={switchCompany}
            logout={logout}
          />
        )}
      </div>
      {children}
    </>
  );
};
