import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { compact } from 'lodash';
import { QuestionnaireStatus } from '@deepstream/common/preQual';
import { withProps } from '@deepstream/ui-utils/withProps';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { DateFormat } from '@deepstream/utils';
import { useQuestionnaireData, useQuestionnaireState } from '../questionnaireUtils';
import { PropertyList } from '../../../../PropertyList';
import { Datetime } from '../../../../Datetime';
import { ExpiryAndRenewalConfiguration } from '../../ExpiryAndRenewalConfiguration';

export const ExpiryAndRenewalPanel = () => {
  const { t } = useTranslation(['preQualification']);
  const questionnaire = useQuestionnaireData();
  // @ts-expect-error ts(2339) FIXME: Property 'editingPanelId' does not exist on type 'QuestionnaireStateContextType | undefined'.
  const { editingPanelId } = useQuestionnaireState();

  const properties = useMemo(() => compact([
    {
      fieldName: 'configuration',
      name: t('questionnaireRenewal.configuration'),
      value: questionnaire,
      Component: ExpiryAndRenewalConfiguration,
    },
    questionnaire.renewalDate && {
      fieldName: 'renewalDate',
      name: t('questionnaireRenewal.resubmissionDate'),
      value: questionnaire.renewalDate,
      Component: withProps(Datetime, { format: DateFormat.DD_MMM_YYYY, onlyDate: true }),
    },
    questionnaire.expiryDate && {
      fieldName: 'expiryDate',
      name: t('questionnaireRenewal.expiryDate'),
      value: questionnaire.expiryDate,
      Component: withProps(Datetime, { format: DateFormat.DD_MMM_YYYY, onlyDate: true }),
    },
  ]), [t, questionnaire]);

  const heading = t('questionnaireRenewal.expirationAndRenewal');
  const isEditingOtherPanel = Boolean(editingPanelId);

  return (
    <Panel
      as="section"
      aria-label={heading}
      heading={heading}
      sx={{
        opacity: isEditingOtherPanel ? 0.5 : 1,
      }}
    >
      <PropertyList properties={properties} />
    </Panel>
  );
};
