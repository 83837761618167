import { useMemo } from 'react';
import { groupBy, map, filter, includes } from 'lodash';

import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { useExchangeNavigation } from '../../useExchangeModalState';
import { useContractData, useContractSection, useApprovalsContext, useApprovalExchange, useIsSectionDirty } from './contract';
import { ExchangeSnapshot } from '../../types';
import { useExchangeColumns } from '../Exchange/columns';
import { ExchangesGrid } from '../Request/Live/ExchangesGrid';

export const ContractDocumentBidSection = () => {
  const contract = useContractData();
  const section = useContractSection();
  const { openExchange } = useExchangeNavigation();
  const columns = useExchangeColumns();
  // @ts-expect-error ts(2339) FIXME: Property 'onlyDirtyExchanges' does not exist on type '{ onlyDirtyExchanges: boolean; toggleDirtyExchanges: () => void; removeFilter: () => void; } | null'.
  const { onlyDirtyExchanges } = useApprovalsContext();
  // @ts-expect-error ts(2339) FIXME: Property 'dirtyExchangeIds' does not exist on type 'ExchangeSnapshot'.
  const { dirtyExchangeIds } = useApprovalExchange();
  const isSectionDirty = useIsSectionDirty();

  const { exchangeById } = contract;

  const exchanges = map(
    section.exchangeDefIds,
    exchangeDefId => exchangeById[exchangeDefId] as ExchangeSnapshot,
  );

  const exchangesInitiatedBy = useMemo(
    () => groupBy(
      exchanges,
      exchange => {
        const exchangeDef = contract.exchangeDefById[exchange._id];
        // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
        return exchange.companies[exchangeDef.creatorId].group!;
      },
    ),
    [exchanges, contract.exchangeDefById],
  );

  const documentColumns = useMemo(
    () => columns.documents(),
    [columns],
  );

  const documentsToShow = useMemo(() => {
    const documents = exchangesInitiatedBy.sender ?? [];

    if (!onlyDirtyExchanges) return documents;

    return filter(
      documents,
      (document) => includes(dirtyExchangeIds, document._id),
    ) ?? [];
  }, [
    exchangesInitiatedBy.sender,
    onlyDirtyExchanges,
    dirtyExchangeIds,
  ]);

  // Hide pristine sections when verifying for approvals changes
  if (onlyDirtyExchanges && !isSectionDirty) return null;

  return (
    <Panel>
      <PanelHeader heading={section.name} description={section.description} icon="file-text-o" />
      <PanelDivider />
      <PanelPadding>
        <ExchangesGrid
          exchanges={documentsToShow}
          columns={documentColumns}
          onRowClick={openExchange}
        />
      </PanelPadding>
    </Panel>
  );
};
