import { isEmpty } from 'lodash';
import { CompanyMinimized } from '../rfq-utils';
import { AnswerSet, Answers, Answer } from './types';

export class PreQAnswerSet {
  answerSet: AnswerSet;

  constructor(answerSet) {
    this.answerSet = answerSet;
  }

  get company(): CompanyMinimized {
    return this.answerSet.company;
  }

  get answers(): Answers {
    return this.answerSet.answers;
  }

  get isEmpty() {
    return isEmpty(this.answers);
  }

  getAnswer(questionId): Answer {
    return this.answers[questionId] || {};
  }

  hasAnswer(questionId): boolean {
    return Boolean(this.answers[questionId]);
  }
}
