import * as React from 'react';
import { Panel, PanelProps } from '@deepstream/ui-kit/elements/Panel';

type EmptyGridPanelProps = Pick<PanelProps, 'sx' | 'children'>;

export const EmptyGridPanel = ({ children, sx }: EmptyGridPanelProps) => (
  <Panel
    flex={1}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      ...sx,
    }}
  >
    {children}
  </Panel>
);
