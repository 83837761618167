import {
  getExchangeFieldValue,
  isReplyField,
  isFormulaField,
  ExchangeRateDocument,
  canIncludeFieldInFormula,
  isDefinitionField,
  ExchangeStatus,
} from '@deepstream/common/rfq-utils';
import { mapValues, assign, pickBy, get, every, isNil } from 'lodash';

import { evaluate, formulaParser } from '@deepstream/formula';
import { createCurrencyConverter } from '@deepstream/common';
import { LineItemsExchangeSnapshot } from '../../../types';

const getFormulaContext = (
  exchange: LineItemsExchangeSnapshot,
  exchangeRates: ExchangeRateDocument | undefined,
  valuesMap: { [fieldId: string]: any },
) => {
  const targetCurrency = exchange.currency;
  const convertCurrency = createCurrencyConverter(targetCurrency, exchangeRates);

  return mapValues(
    pickBy(exchange.def.fields, canIncludeFieldInFormula),
    (field, fieldId) => {
      const fieldValue = isDefinitionField(field)
        ? get(exchange.def, field.source.key)
        : isReplyField(field)
          ? valuesMap[fieldId] as any[]
          : isFormulaField(field)
            ? field.source.formula
            : null;

      const fieldRole = fieldId?.split(':')[1];
      const fieldNeedsConversion = field.type === 'price' && fieldRole === 'evaluator';
      if (fieldNeedsConversion) {
        const converted = convertCurrency({ value: fieldValue, currencyCode: getExchangeFieldValue(exchange, 'evaluatorFieldCurrency') });
        return converted.value;
      }
      return fieldValue;
    },
  );
};

export const computeFormula = ({
  exchange,
  fieldId,
  valuesMap,
  exchangeRates,
}: {
  exchange: LineItemsExchangeSnapshot;
  fieldId: string;
  valuesMap: { [fieldId: string]: any };
  exchangeRates: ExchangeRateDocument | undefined;
}) => {
  const fieldSource = exchange.def.fields[fieldId]?.source;
  const formulaContext = getFormulaContext(exchange, exchangeRates, valuesMap);

  if (fieldSource.type !== 'formula') {
    return null;
  }

  try {
    const { expression } = formulaParser.parse(fieldSource.formula);
    return evaluate(expression, formulaContext);
  } catch (error) {
    return null;
  }
};

export const restorePreviousLineItemResponseStage = (
  exchange,
  responseTags,
  exchangeRates,
) => {
  if (exchange.supplierReplyByTag) {
    const supplierReplies = responseTags.map(responseTag => {
      return exchange.supplierReplyByTag?.[responseTag] || null;
    });
    const latestReply = assign({}, ...supplierReplies);
    const computedFormulas = mapValues(
      exchange.computedFormulas,
      (computedValue, fieldId) => {
        // The target total cost only gets calculated when it does not
        // depend on supplier-provided fields; thus, it's the same for
        // all supplier response stages and we can keep the value that
        // exists on the exchange.
        return fieldId === 'targetTotalCost'
          ? computedValue
          : computeFormula({
            exchange,
            fieldId,
            valuesMap: latestReply,
            exchangeRates,
          });
      },
    );

    return {
      ...exchange,
      latestReply,
      computedFormulas,
      status: exchange.status === ExchangeStatus.OBSOLETE
        ? ExchangeStatus.OBSOLETE
        : every(latestReply, value => !isNil(value))
          ? 'complete'
          : 'incomplete',
      isPreviousStage: true,
    };
  } else {
    return exchange;
  }
};
