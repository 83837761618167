import { compact, fromPairs, isEmpty, isFinite, isNumber, keyBy, mapValues, mergeWith, pick, pickBy, size, sumBy } from 'lodash';
import { averageObjectValue, minObjectValue } from '@deepstream/utils';
import { CostAndSavings, CostAndSavingsByRecipientId, RfxSpendAndSavings, Savings, SavingsBySavingsType, SavingsCalculationResultByRecipientId, SavingsCalculationResultByRecipientIdBySavingsType, SavingsType, TotalSavingsCalculationMethod } from './types';

export const spendAndSavingsInitialState: RfxSpendAndSavings = {
  enabled: true,
  hasBudgetedTotalValue: true,
  budgetedTotalValue: null,
  calculatedTotalValue: null,
  isCalculatedTotalValueAccurate: null,
  canProvideManualTotalValue: null,
  manualTotalValue: null,
  calculatedTotalValueNotAccurateReasons: null,
  calculatedTotalValueNotAccurateOtherReason: null,
  noLineItemsReasons: null,
  noLineItemsOtherReason: null,
  cannotProvideManualTotalValueReason: null,
  awardQuestionnaireComment: null,
  canProvideTotalSavings: true,
  cannotProvideTotalSavingsReason: null,
  totalSavingsCalculationMethod: null,
  manualTotalSavings: null,
  manualTotalSavingsDescription: null,
  areTotalSavingsAccurate: null,
  calculatedSpecificSavings: null,
  calculatedSavingsByType: null,
};

export const spendAndSavingsDisabledState: RfxSpendAndSavings = {
  ...spendAndSavingsInitialState,
  enabled: false,
  hasBudgetedTotalValue: false,
};

export type SpendSectionStatus = 'complete' | 'incomplete' | 'disabled';

export const getBudgetSectionStatus = (spendAndSavings?: RfxSpendAndSavings): SpendSectionStatus | undefined => {
  if (!spendAndSavings) return undefined;

  if (isFinite(spendAndSavings.budgetedTotalValue)) {
    return 'complete';
  } else if (spendAndSavings.hasBudgetedTotalValue) {
    return 'incomplete';
  } else {
    return 'disabled';
  }
};

export const getFinalValueSectionStatus = (spendAndSavings?: RfxSpendAndSavings): SpendSectionStatus | undefined => {
  if (!spendAndSavings) {
    return;
  }

  const {
    calculatedTotalValue,
    isCalculatedTotalValueAccurate,
    canProvideManualTotalValue,
    manualTotalValue,
  } = spendAndSavings;

  if (
    (isFinite(calculatedTotalValue) && isCalculatedTotalValueAccurate) ||
    isFinite(manualTotalValue)
  ) {
    return 'complete';
  } else if (canProvideManualTotalValue === false) {
    return 'disabled';
  } else {
    return 'incomplete';
  }
};

export const getTotalSavingsSectionStatus = (spendAndSavings?: RfxSpendAndSavings): SpendSectionStatus | undefined => {
  if (!spendAndSavings) {
    return;
  }

  const {
    canProvideTotalSavings,
    cannotProvideTotalSavingsReason,
    totalSavingsCalculationMethod,
    manualTotalSavings,
    manualTotalSavingsDescription,
    areTotalSavingsAccurate,
  } = spendAndSavings;

  if (canProvideTotalSavings) {
    switch (totalSavingsCalculationMethod) {
      case TotalSavingsCalculationMethod.MANUAL:
        return isFinite(manualTotalSavings) && manualTotalSavingsDescription && areTotalSavingsAccurate
          ? 'complete'
          : 'incomplete';
      case TotalSavingsCalculationMethod.BUDGET_FINAL_VALUE_DIFF:
      case TotalSavingsCalculationMethod.SUM_SPECIFIC_SAVINGS:
        return areTotalSavingsAccurate
          ? 'complete'
          : 'incomplete';
      default:
        return 'incomplete';
    }
  } else {
    return cannotProvideTotalSavingsReason
      ? 'disabled'
      : 'incomplete';
  }
};

export const getPossibleSavingsCalculationMethods = (
  spendAndSavings: RfxSpendAndSavings,
  calculatedTotalValue: number | null | undefined,
  calculatedSavingsByType: SavingsCalculationResultByRecipientIdBySavingsType,
) => {
  const hasBudgetedTotalValue = isFinite(spendAndSavings.budgetedTotalValue);
  const hasFinalValue = spendAndSavings.isCalculatedTotalValueAccurate === false
    ? isFinite(spendAndSavings.manualTotalValue)
    : isFinite(calculatedTotalValue);

  return compact([
    !isEmpty(calculatedSavingsByType) && TotalSavingsCalculationMethod.SUM_SPECIFIC_SAVINGS,
    hasBudgetedTotalValue && hasFinalValue && TotalSavingsCalculationMethod.BUDGET_FINAL_VALUE_DIFF,
    TotalSavingsCalculationMethod.MANUAL,
  ]);
};

export const calculateSpecificSavings = (savingsByRecipientId: SavingsCalculationResultByRecipientId) => {
  return sumBy(Object.values(savingsByRecipientId), recipientSavings => recipientSavings.result);
};

const getLinkedAuctionLineItemLatestResponseExchangeId = (
  { singleResponseLineItems, multiResponseLineItems }: CostAndSavings,
  auctionLineItem: CostAndSavings['auctionLineItems'][number],
) => {
  const multiResponseItem = multiResponseLineItems.find(
    multiResponseItem => multiResponseItem.exchangeId === auctionLineItem.linkedExchangeId,
  );

  if (multiResponseItem) {
    if ((multiResponseItem.valueBStageIndex || 0) > (auctionLineItem.valueBStageIndex || 0)) {
      // When the latest response stage of the multiResponseItem is after the auction,
      // the effective exchangeId is the multiResponseItem's, when the multiResponseItem
      // has a response or when both don't have a response.
      return isFinite(multiResponseItem.valueB) || !isFinite(auctionLineItem.valueB)
        ? multiResponseItem.exchangeId
        : auctionLineItem.exchangeId;
    } else {
      // When the latest response stage of the multiResponseItem is not after the auction,
      // the effective exchangeId is the auctions's, when the auction has a response or
      // when both don't have a response.
      return isFinite(auctionLineItem.valueB) || !isFinite(multiResponseItem.valueB)
        ? auctionLineItem.exchangeId
        : multiResponseItem.exchangeId;
    }
  } else {
    const singleResponseItem = singleResponseLineItems.find(
      multiResponseItem => multiResponseItem.exchangeId === auctionLineItem.linkedExchangeId,
    );

    return (
      !singleResponseItem ||
      // This is the same logic as for multi response line items where the latest response
      // stage of the multiResponseItem is not after the auction.
      // This logic applies because auction line items can only be linked to single response
      // line items of a previous stage, i.e. the response stage of a linked single response
      // line item always precedes the auction stage.
      isFinite(auctionLineItem.valueB) ||
      !isFinite(singleResponseItem.valueB)
    )
      ? auctionLineItem.exchangeId
      : singleResponseItem.exchangeId;
  }
};

/**
 * Filters the `costAndSavings` by
 *
 * 1) excluding items of a linked item pair that are are superseded
 * by the other item in the pair, and
 * 2) optionally excluding all items that don't match `exchangeIds`
 */
export const filterCostAndSavingsForTotalCalculation = (
  costAndSavings: CostAndSavings,
  /**
   * When `exchangeIds` are provided, we only return items matching one
   * of the `exchangeIds`.
   * Linked auction line items must be referred to by their `linkedExchangeId`.
   */
  exchangeIds?: string[],
) => {
  const { singleResponseLineItems, multiResponseLineItems, auctionLineItems } = costAndSavings;

  const filteredAuctionLineItems = auctionLineItems.filter(item => {
    const originalExchangeId = item.linkedExchangeId || item.exchangeId;

    if (exchangeIds && !exchangeIds.includes(originalExchangeId)) {
      return false;
    }

    if (!item.linkedExchangeId) {
      return true;
    }

    const latestResponseExchangeId = getLinkedAuctionLineItemLatestResponseExchangeId(costAndSavings, item);

    return latestResponseExchangeId === item.exchangeId;
  });

  const linkedLineItemIds = compact(filteredAuctionLineItems.map(item => item.linkedExchangeId));

  const exchangeLineItemResponseFilter = exchangeIds
    ? item => !linkedLineItemIds.includes(item.exchangeId) && exchangeIds.includes(item.exchangeId)
    : item => !linkedLineItemIds.includes(item.exchangeId);

  return {
    singleResponseLineItems: singleResponseLineItems.filter(exchangeLineItemResponseFilter),
    multiResponseLineItems: multiResponseLineItems.filter(exchangeLineItemResponseFilter),
    auctionLineItems: filteredAuctionLineItems,
  };
};

const getLatestResponseExchangeId = (
  costAndSavings: CostAndSavings,
  exchangeId: string,
) => {
  const { auctionLineItems } = costAndSavings;

  // handle standalone auction line item
  const standaloneAuctionLineItem = auctionLineItems.find(item => item.exchangeId === exchangeId);

  if (standaloneAuctionLineItem) {
    return exchangeId;
  }

  const auctionLineItem = auctionLineItems.find(item => item.linkedExchangeId === exchangeId);

  // handle linked auction line item
  if (auctionLineItem) {
    return getLinkedAuctionLineItemLatestResponseExchangeId(costAndSavings, auctionLineItem);
  }

  // handle standalone exchange line item
  return exchangeId;
};

/**
 * Returns for each of the provided recipientIds the exchange to which the
 * latest supplier response related to `exchangeId` has been submitted.
 *
 * In the case of a pair of linked auction / exchange stage line items,
 * `exchangeId` is expected to be the ID of the exchange stage line item.
 */
export const getLatestResponseExchangeIdByRecipientId = (
  costAndSavingsByRecipientId: CostAndSavingsByRecipientId,
  exchangeId: string,
  recipientIds: string[],
): Record<string, string> => {
  return fromPairs(
    recipientIds.map(recipientId => {
      const costAndSavings = costAndSavingsByRecipientId[recipientId];

      const latestResponseExchangeId = getLatestResponseExchangeId(costAndSavings, exchangeId);

      return [
        recipientId,
        latestResponseExchangeId,
      ];
    }),
  );
};

export const getSavings = ({
  costAndSavingsByRecipientId,
  awardedSupplierIds,
  savingsType,
}: {
  costAndSavingsByRecipientId: CostAndSavingsByRecipientId;
  awardedSupplierIds: string[];
  savingsType: SavingsType;
}) => {
  const filteredExchangeTotalCostMap = pickBy(
    costAndSavingsByRecipientId,
    (entry) => {
      const items = entry[savingsType];

      return (
        !isEmpty(items) &&
        items.every(({ valueA, valueB }) => isFinite(valueA) && isFinite(valueB))
      );
    },
  );

  const awardedCostAndSavingsByRecipientId = pick(filteredExchangeTotalCostMap, awardedSupplierIds);

  if (isEmpty(filteredExchangeTotalCostMap) || isEmpty(awardedCostAndSavingsByRecipientId)) {
    return null;
  }

  const valueAByRecipientId = mapValues(
    filteredExchangeTotalCostMap,
    (entry) => sumBy(Object.values(entry[savingsType]).map(item => item.valueA)),
  );

  const valueBByRecipientId = mapValues(
    awardedCostAndSavingsByRecipientId,
    (entry) => sumBy(Object.values(entry[savingsType]).map(item => item.valueB)),
  );

  return {
    type: savingsType,
    valueAByRecipientId,
    valueBByRecipientId,
  };
};

/**
 * Calculates savings for legacy requests that don't have the
 * required data for the more nuanced `calculateSavings` function.
 */
export const calculateLegacySavings = (
  costAndSavingsByRecipientId: Record<string, CostAndSavings>,
  awardedSupplierIds: string[],
): SavingsBySavingsType => {
  return keyBy(
    compact([
      getSavings({
        costAndSavingsByRecipientId,
        awardedSupplierIds,
        savingsType: SavingsType.SINGLE_RESPONSE_LINE_ITEMS,
      }),
      getSavings({
        costAndSavingsByRecipientId,
        awardedSupplierIds,
        savingsType: SavingsType.MULTI_RESPONSE_LINE_ITEMS,
      }),
      getSavings({
        costAndSavingsByRecipientId,
        awardedSupplierIds,
        savingsType: SavingsType.AUCTION_LINE_ITEMS,
      }),
    ]),
    savings => savings.type,
  );
};

export const calculateSavings = (
  costAndSavingsByRecipientId: Record<string, CostAndSavings>,
  awardedSupplierIds: string[],
  exchangeIds?: string[],
): SavingsBySavingsType => {
  const filteredCostAndSavingsByRecipientId = mapValues(
    costAndSavingsByRecipientId,
    costAndSavings => filterCostAndSavingsForTotalCalculation(costAndSavings, exchangeIds),
  );

  return keyBy(
    compact([
      getSavings({
        costAndSavingsByRecipientId: filteredCostAndSavingsByRecipientId,
        awardedSupplierIds,
        savingsType: SavingsType.SINGLE_RESPONSE_LINE_ITEMS,
      }),
      getSavings({
        costAndSavingsByRecipientId: filteredCostAndSavingsByRecipientId,
        awardedSupplierIds,
        savingsType: SavingsType.MULTI_RESPONSE_LINE_ITEMS,
      }),
      getSavings({
        costAndSavingsByRecipientId: filteredCostAndSavingsByRecipientId,
        awardedSupplierIds,
        savingsType: SavingsType.AUCTION_LINE_ITEMS,
      }),
    ]),
    savings => savings.type,
  );
};

const getSpecificSavingCalculationParts = (savings: Savings, awardedRecipientCount: number) => {
  const { type, valueAByRecipientId, valueBByRecipientId } = savings;

  const valueA = type === SavingsType.AUCTION_LINE_ITEMS
    ? minObjectValue(valueAByRecipientId)
    : averageObjectValue(valueAByRecipientId);

  // In the case of awarded line items split between multiple suppliers,
  // we consider the amounts awarded to the individual suppliers to sum
  // up to 100% of the total awarded amount.
  // `valueA` relates to the total awarded amount. We divide `valueA` by
  // the number of suppliers so that the savings for a supplier is calculated
  // using only a part of `valueA` (and the partial valueAs sum up to the
  // total valueA just like the awarded amounts sum up to the total awarded
  // amount).
  // TODO consider distributing valueA not equally, but based on the
  // percentage that each supplier's awarded amount contributes to the total
  // awarded amount.
  const partialValueA = valueA / awardedRecipientCount;

  return mapValues(
    valueBByRecipientId,
    valueB => ({
      valueA: partialValueA,
      valueB,
      result: partialValueA - valueB,
    }),
  );
};

export const getSavingsCalculationResultByRecipientIdBySavingsType = (
  items: SavingsBySavingsType[],
): SavingsCalculationResultByRecipientIdBySavingsType => {
  const parts = items.map(savingsBySavingsType => {
    // NB `savingsBySavingsType` holds the savings for
    // - the whole request, awarded to a single supplier, or
    // - a specific lot, awarded to a single supplier, or
    // - a specific line item, awarded to one or multiple suppliers

    const awardedRecipientCount = sumBy(
      Object.values(savingsBySavingsType),
      savings => size(savings.valueBByRecipientId),
    );

    return mapValues(
      savingsBySavingsType,
      savings => savings
        ? getSpecificSavingCalculationParts(savings, awardedRecipientCount)
        : undefined,
    );
  });

  return mergeWith(
    {},
    ...parts,
    (objValue, srcValue) => isNumber(objValue) ? objValue + (srcValue || 0) : undefined,
  );
};

/**
 * Calculates `calculatedSavingsByType` from `calculatedSpecificSavings` -- only used when
 * dealing with events in legacy request that contain `calculatedSpecificSavings` instead
 * of `calculatedSavingsByType`.
 */
export const transformLegacySpecificSavings = (spendAndSavings: Partial<RfxSpendAndSavings> = {}) => {
  if (spendAndSavings.calculatedSpecificSavings && !spendAndSavings.calculatedSavingsByType) {
    return {
      ...spendAndSavings,
      calculatedSpecificSavings: null,
      calculatedSavingsByType: getSavingsCalculationResultByRecipientIdBySavingsType([
        spendAndSavings.calculatedSpecificSavings,
      ]),
    };
  } else {
    return spendAndSavings;
  }
};

/**
 * Replaces for each awarded recipient the 'valueB' in the calculated
 * savings with the amount specified in the split decision step.
 */
export const setSplitDecisionAwardedCosts = (savings, splitDecisionByRecipientId) => {
  return mapValues(
    savings,
    savings => {
      return {
        ...savings,
        valueBByRecipientId: mapValues(
          savings.valueBByRecipientId,
          (amount, recipientId) => {
            if (splitDecisionByRecipientId[recipientId]) {
              return splitDecisionByRecipientId[recipientId].requestCurrencyAwardedCost || 0;
            } else {
              return amount;
            }
          },
        ),
      };
    },
  );
};
