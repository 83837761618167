import { useCallback, useEffect, useMemo } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { IntegrationMarketplaceEventType, IntegrationMarketplaceOrigin } from '@deepstream/common';

import { useNavigate, useRouterState, useSearch } from '../../tanstackRouter';
import {
  Tab,
  TabListPanel,
  TabPanels,
  TabPanel,
  Tabs,
} from '../../ui/TabsVertical';
import { SidebarLayout } from '../../ui/ProfileLayout';
import { SupplierMap } from './SupplierMap';
import { useDeviceSize } from '../../ui/useDeviceSize';
import { companySettingsRoute } from '../../AppRouting';
import { UserFlagsProvider } from '../../UserFlagsContext';
import { CompanySettingsTab, companySettingsTabs } from './companySettingsConstants';
import { Marketplace } from './Marketplace';
import { useSession } from '../../auth';
import { User } from '../../types';
import { useDefaultExternalSystem, useIntegrationData } from '../../useExternalSystems';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useTracking } from '../../useTracking';

// If a new tab is added to this component, then we also need to extend
// the origin tracking mechanism for the Marketplace tab
const CompanySettingsTabs = ({ currentUser }: { currentUser: User }) => {
  const { t } = useTranslation('integration');
  const { isExtraSmall, isSmall } = useDeviceSize();
  const search = useSearch({ from: companySettingsRoute.id });
  const navigate = useNavigate({ from: companySettingsRoute.id });
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { trackIntegrationMarketplaceEvent } = useTracking();
  const routerLocation = useRouterState();

  const isAdmin = currentUser.roles[currentCompanyId]?.admin;

  const { from } = routerLocation.resolvedLocation.state;

  const navigateToTab = useCallback(
    (tab: CompanySettingsTab, replace?: boolean) => navigate({
      search: { tab },
      replace,
      state: {
        from: search.tab ?? IntegrationMarketplaceOrigin.COMPANY_SETTINGS,
      },
    }),
    [navigate, search],
  );

  const externalSystem = useDefaultExternalSystem();

  const { data: integrationData, isLoading } = useIntegrationData({
    systemId: externalSystem?.systemId,
    currentCompanyId,
    enabled: isAdmin,
  });

  useEffect(() => {
    if (!search.tab) {
      navigateToTab(CompanySettingsTab.MARKETPLACE, true);
    } else if (search.tab !== CompanySettingsTab.SUPPLIER_MAP) {
      const originMap = {
        navbarDropdown: IntegrationMarketplaceOrigin.NAVBAR_DROPDOWN,
        companySettings: IntegrationMarketplaceOrigin.COMPANY_SETTINGS,
        'supplier-map': IntegrationMarketplaceOrigin.COMPANY_SETTINGS,
        external: IntegrationMarketplaceOrigin.EXTERNAL,
      };

      trackIntegrationMarketplaceEvent({
        eventType: IntegrationMarketplaceEventType.PAGE_OPENED,
        origin: originMap[from ?? 'external'],
      });
    }
  }, [navigateToTab, search.tab, from, trackIntegrationMarketplaceEvent]);

  const selectedTabIndex = search.tab ? companySettingsTabs.indexOf(search.tab) : -1;

  return (
    <Tabs index={selectedTabIndex} onChange={index => navigateToTab(companySettingsTabs[index])}>
      <SidebarLayout
        sidebar={
          <TabListPanel heading={t('integrationMarketplace.integrations')}>
            {!isLoading ? (
              <Tab data-test="marketplace-tab" key="marketplace">
                <Flex alignItems="center">
                  <Icon regular icon="store" mr={2} fixedWidth />
                  <Text flex={1}>{t('marketplace')}</Text>
                </Flex>
              </Tab>
            ) : (
              null
            )}
            {(integrationData && !isLoading) ? (
              <Tab data-test="supplier-map-tab" key="supplier-map">
                <Flex alignItems="center">
                  <Icon icon="arrow-right-arrow-left" mr={2} fixedWidth />
                  <Text flex={1}>{t('supplierMap')}</Text>
                </Flex>
              </Tab>
            ) : (
              null
            )}
          </TabListPanel>
        }
        main={
          <TabPanels>
            <TabPanel key="marketplace">
              <Marketplace />
            </TabPanel>
            <TabPanel key="supplier-map">
              <SupplierMap />
            </TabPanel>
          </TabPanels>
        }
        sidebarStyle={!isExtraSmall && !isSmall ? { maxWidth: '191px', flex: '0 0 auto' } : undefined}
        mainStyle={!isExtraSmall && !isSmall ? { flex: '1 1 auto' } : undefined}
      />
    </Tabs>
  );
};

export const CompanySettings = () => {
  const auth = useSession();

  return auth.user ? (
    <UserFlagsProvider>
      <CompanySettingsTabs currentUser={auth.user} />
    </UserFlagsProvider>
  ) : (
    null
  );
};
