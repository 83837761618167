import { useQueryClient } from 'react-query';
import { RfqEventChange } from '@deepstream/common/rfq-utils';
import { callAll } from '@deepstream/utils/callAll';
import { useRfqId, useLiveRfqStructureQueryKey, useRecipientId, doesExchangesQueryMatchSectionId } from './useRfq';
import * as rfx from './rfx';
import { useCurrentCompanyId } from './currentCompanyId';
import { useApi } from './api';
import { useMutation } from './useMutation';
import { useWaitForRfqUnlock } from './useWaitForUnlock';

export const useUpdateExchanges = <TGetChanges extends (args: any) => RfqEventChange[]> ({
  getChanges,
  onError,
  onSuccess,
}: {
  getChanges: TGetChanges;
  onError: any;
  onSuccess: any;
}) => {
  const queryClient = useQueryClient();
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqId = useRfqId();
  const recipientId = useRecipientId();
  const liveStructureQueryKey = useLiveRfqStructureQueryKey({});
  const section = rfx.useSection();
  const waitForRfqUnlock = useWaitForRfqUnlock();

  const mutation = useMutation(
    (payload: Parameters<TGetChanges>[0]) => waitForRfqUnlock({
      command: () => api.updateExchanges({
        currentCompanyId,
        rfqId,
        recipientId,
        changes: getChanges(payload),
      }),
    }),
    {
      onError,
      onSuccess,
      onSettled: callAll(
        () => queryClient.invalidateQueries(liveStructureQueryKey),
        () => queryClient.invalidateQueries({
          predicate: doesExchangesQueryMatchSectionId({
            rfqId,
            recipientId,
            sectionId: section._id,
          }),
        }),
        () => {
          if (currentCompanyId !== recipientId) {
            queryClient.invalidateQueries(['allExchanges', { rfqId, currentCompanyId }]);
            queryClient.invalidateQueries(['statsByRecipientId', { rfqId, currentCompanyId }]);
          }
        },
      ),
    },
  );

  return mutation;
};
