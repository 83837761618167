import { useMemo, useRef, useState, useLayoutEffect } from 'react';
import * as React from 'react';
import { groupBy, defaults } from 'lodash';
import { Text, Flex } from 'rebass/styled-components';
import { useInView } from 'react-intersection-observer';
import * as PreQ from '@deepstream/common/legacy-pre-q-utils';
import { useTranslation } from 'react-i18next';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { Details } from '../ui/Details';
import { useModalState } from '../ui/useModalState';
import { EditVisibilityModal } from './EditVisibilityModal';
import { SystemFeatureFlags } from '../types';

type OverviewProps = {
  answerSet: PreQ.AnswerSet;
  canEdit?: boolean;
  searchCompanies: any;
  approvePreQualAccess: any;
  denyPreQualAccess: any;
  revokePreQualAccess: any;
  systemFeatureFlags?: SystemFeatureFlags;
};

export const Overview: React.FC<OverviewProps> = ({
  answerSet,
  canEdit,
  searchCompanies,
  approvePreQualAccess,
  denyPreQualAccess,
  revokePreQualAccess,
  systemFeatureFlags,
}) => {
  const { t } = useTranslation('companyProfile');
  const editVisibilityModal = useModalState(false);

  const { approved, pending } = useMemo(
    () => defaults(
      groupBy(answerSet.accessRequests, 'status'),
      { approved: [], pending: [] },
    ),
    [answerSet],
  );

  return (
    <>
      <Panel>
        <Details label={t('questionnaire.visibility.visibility')} isTeamMember canEdit={canEdit} onEdit={editVisibilityModal.open} isLast>
          <>
            {approved.length ? (
              <IconText
                gap={2}
                icon="industry"
                iconColor="lightGray5"
                text={t('questionnaire.visibility.approvedCompanyCount', { count: approved.length })}
                fixedWidth
              />
            ) : (
              <IconText
                gap={2}
                icon="lock"
                iconColor="lightGray5"
                text={t('questionnaire.visibility.yourCompanyOnly')}
                fixedWidth
              />
            )}
            {/*
             // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'. */}
            {pending.length && !systemFeatureFlags.newPreQualEnabled ? (
              <IconText
                gap={2}
                icon="exclamation-circle"
                iconColor="danger"
                text={t('questionnaire.visibility.requestedAccessCount', { count: pending.length })}
                fixedWidth
              />
            ) : (
              null
            )}
          </>
        </Details>
      </Panel>
      <EditVisibilityModal
        answerSet={answerSet}
        searchCompanies={searchCompanies}
        approvePreQualAccess={approvePreQualAccess}
        denyPreQualAccess={denyPreQualAccess}
        revokePreQualAccess={revokePreQualAccess}
        isOpen={editVisibilityModal.isOpen}
        onClose={editVisibilityModal.close}
        systemFeatureFlags={systemFeatureFlags}
      />
    </>
  );
};

type AccessStatusProps = {
  preQualAccessStatus: 'approved' | 'denied' | 'pending' | null;
  requestPreQualAccess: any;
};

export const AccessStatus: React.FC<AccessStatusProps> = ({
  preQualAccessStatus,
  requestPreQualAccess,
}) => {
  const { t } = useTranslation('companyProfile');
  const boxRef = useRef<HTMLDivElement | null>(null);
  const [inViewRef, inView] = useInView();

  // Track max height of an element so that when it's content shrinks in
  // size, the element doesn't change size
  const [maxHeight, setMaxHeight] = useState<any>();

  useLayoutEffect(
    () => {
      if (boxRef.current) {
        const { height } = boxRef.current.getBoundingClientRect();

        if (!maxHeight || height > maxHeight) {
          setMaxHeight(height);
        }
      }
    },
    [inView, preQualAccessStatus, maxHeight],
  );

  return (
    <Panel ref={inViewRef} style={{ minHeight: maxHeight }}>
      <Details label={t('questionnaire.visibility.visibility')} isLast>
        <Text fontSize={2} color="text" mr={3}>
          {preQualAccessStatus === 'approved' ? (
            <IconText gap={2} icon="unlock" iconColor="lightGray5" text={t('questionnaire.visibility.answersAreUnlocked')} display="inline" />
          ) : (
            <IconText gap={2} icon="lock" iconColor="lightGray5" text={t('questionnaire.visibility.answersAreLocked')} display="inline" />
          )}
        </Text>
        {preQualAccessStatus === 'approved' ? (
          null
        ) : (
          <Flex ref={boxRef} minHeight={maxHeight} alignItems="center">
            {preQualAccessStatus === 'pending' ? (
              <IconText
                icon="reply"
                text={t('questionnaire.visibility.accessRequested')}
                color="subtext"
                fontWeight={500}
                gap={2}
              />
            ) : (
              <Button onClick={requestPreQualAccess}>
                {t('questionnaire.visibility.requestAccess')}
              </Button>
            )}
          </Flex>
        )}
      </Details>
    </Panel>
  );
};
