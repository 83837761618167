import * as React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { isEqual } from 'lodash';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Popover, usePopover } from '@deepstream/ui-kit/elements/popup/usePopover';
import { withProps } from '@deepstream/ui-utils/withProps';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { DisabledHeaderWithFieldTypeIcon } from '../ui/ExchangeDefsGrid/header';
import { Select, SelectProps } from '../ui/Select';
import { getItemLabel, getItemValue } from '../form/SelectField';

const ResetMappingButton = (props: ButtonProps) => {
  const { t } = useTranslation('translation');

  return (
    <Button
      type="button"
      variant="secondary-outline"
      small
      iconLeft="undo"
      {...props}
      mr={2}
    >
      {t('request.lineItems.bulkUploadModal.resetMapping')}
    </Button>
  );
};

const DoneButton = (props: ButtonProps) => {
  const { t } = useTranslation('general');

  return (
    <Button
      type="button"
      variant="secondary"
      small
      {...props}
    >
      {t('done')}
    </Button>
  );
};

const PopoverBackground = styled(Box)`
  background: white;
  border-radius: ${props => props.theme.radii.small};
  width: 100%;
`;

const RowContainer = withProps(Box, {
  pl: 1,
  pr: 3,
  py: 3,
});

const Row = styled(Flex)`
  align-items: center;
  justify-content: stretch;
`;

const ColumnHeader = styled(Text)`
  color: ${props => props.theme.colors.subtext};
  font-size: ${props => props.theme.fontSizes[2]};
  letter-spacing: 0.8px;
  text-transform: uppercase;
  padding: 0 10px;
`;

const ArrowColumn = ({ disabled }: { disabled?: boolean }) => (
  <Box flex="0 0 15px">
    {disabled ? (
      null
    ) : (
      <Icon icon="arrow-left" color="subtext" />
    )}
  </Box>
);

const HeaderRow = () => {
  const { t } = useTranslation('translation');

  return (
    <Row>
      <ColumnHeader sx={{ width: '200px' }}>
        {t('request.lineItems.bulkUploadModal.deepstreamField')}
      </ColumnHeader>
      <ArrowColumn />
      <ColumnHeader>
        {t('request.lineItems.bulkUploadModal.csvImportField')}
      </ColumnHeader>
    </Row>
  );
};

const BodyRow = ({
  column,
  options,
  selectedItem,
  onChange,
}: {
  column: EditableGridColumn;
  options: { value: string; label: string }[];
  /**
   * The type of `selectedItem`, just like `option.value`, is originally
   * a number but we need to convert it to a string because things
   * wouldn't work correctly with the number 0 as `selectedItem`
   * (due to its falsiness).
   */
  selectedItem: string,
  onChange: SelectProps['onChange'],
}) => {
  const { t } = useTranslation(['translation', 'general']);

  return (
    <Row my="12px" sx={{ height: '40px' }}>
      <Flex
        sx={{
          flex: '0 0 200px',
          position: 'relative',
          padding: '0 10px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <column.Header
          column={{ original: column } as any}
        />
      </Flex>
      <ArrowColumn disabled={column.Header === DisabledHeaderWithFieldTypeIcon} />
      {column.Header === DisabledHeaderWithFieldTypeIcon ? (
        <Text flex="1 1 auto" pl="10px" opacity={0.5}>
          {t('request.lineItems.bulkUploadModal.fieldTypeNotSupported')}
        </Text>
      ) : (
        <Box flex="1 1 auto" pl="10px">
          <Select
            menuZIndex={1000}
            selectedItem={selectedItem}
            onChange={onChange}
            items={options}
            itemToString={getItemValue}
            getItemLabel={getItemLabel}
          />
        </Box>
      )}
    </Row>
  );
};

export const BulkUploadFieldMappingPopover = ({
  columns,
  initialFieldMapping,
  fieldMapping,
  setFieldMapping,
  firstRowData,
  popover,
  onCancel,
  ...props
}: {
  columns: EditableGridColumn[];
  initialFieldMapping: number[];
  fieldMapping: number[];
  setFieldMapping: React.Dispatch<React.SetStateAction<number[]>>;
  firstRowData: string[];
  popover: ReturnType<typeof usePopover>;
  onCancel: () => void;
}) => {
  const options = React.useMemo(() => {
    return [
      {
        value: '-1',
        label: '\xA0',
      },
      ...firstRowData.map((str, index) => ({
        value: `${index}`,
        label: str,
      })),
    ];
  }, [firstRowData]);

  return (
    <Popover
      onClickOutside={onCancel}
      {...popover}
      width="100%"
      maxWidth="469px"
      {...props}
    >
      <PopoverBackground>
        <Box>
          <RowContainer>
            <HeaderRow />
          </RowContainer>
          <PanelDivider />
          <RowContainer sx={{ maxHeight: 'calc(100vh - 302px)', overflowY: 'scroll' }}>
            {columns.map((column, index) => (
              <BodyRow
                key={column._id}
                column={column}
                options={options}
                selectedItem={`${fieldMapping[index]}`}
                onChange={(changes) => {
                  setFieldMapping(fieldMapping => {
                    const newFieldMapping = [...fieldMapping];

                    newFieldMapping[index] = Number(changes.selectedItem.value);

                    return newFieldMapping;
                  });
                }}
              />
            ))}
          </RowContainer>
          <PanelDivider />
          <Flex p={3} justifyContent="space-between">
            <ResetMappingButton
              onClick={() => { setFieldMapping(initialFieldMapping); }}
              disabled={isEqual(initialFieldMapping, fieldMapping)}
            />
            <DoneButton
              onClick={() => { onCancel(); popover.close(); }}
            />
          </Flex>
        </Box>
      </PopoverBackground>
    </Popover>
  );
};
