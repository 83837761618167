import * as React from 'react';
import * as Sentry from '@sentry/react';
import { Box, Flex } from 'rebass/styled-components';
import { UnexpectedStateError } from '@deepstream/errors';
import { QuestionType, DocumentQuestionExchangeDefinition, QuestionExchangeDefinition } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { StyledRadio } from '../../../ui/Radio';
import { CheckboxFieldBase } from '../../../form/CheckboxField';
import { FieldContainer } from '../../../form/FieldContainer';
import { DisabledInputBox } from '../../../ui/Input';

export const DisabledDocumentField = ({
  exchangeDef,
  fieldMaxWidth,
}: {
  exchangeDef: QuestionExchangeDefinition;
  fieldMaxWidth?: React.CSSProperties['maxWidth'];
}) => {
  const { t } = useTranslation();

  const {
    options,
    questionType,
    allowCustomOption,
    requireDocument,
    requireExpiry,
  } = exchangeDef as DocumentQuestionExchangeDefinition;

  if (questionType !== QuestionType.DOCUMENT) {
    Sentry.captureException(new UnexpectedStateError('[Document] Wrong question type passed', { questionType }));
    return null;
  }

  return (
    <Flex>
      <Stack gap={1} flex={1} maxWidth={fieldMaxWidth}>
        {options.map((option) => (
          <Flex key={option} alignItems="flex-start">
            <Box sx={{ marginRight: '8px', flex: '0 0 auto' }}>
              <StyledRadio
                disabled
                style={{ background: 'transparent', marginTop: '12px' }}
              />
            </Box>
            <FieldContainer flex={1} showAsterisk>
              <DisabledInputBox>{t(`request.question.predefinedOption.${option}`)}</DisabledInputBox>
            </FieldContainer>
          </Flex>
        ))}
        {allowCustomOption && (
          <Flex alignItems="flex-start">
            <Box sx={{ marginRight: '8px', flex: '0 0 auto' }}>
              <StyledRadio
                disabled
                style={{ background: 'transparent', marginTop: '12px' }}
              />
            </Box>
            <FieldContainer flex={1} showAsterisk>
              <DisabledInputBox>{t('request.question.other')}</DisabledInputBox>
            </FieldContainer>
          </Flex>
        )}
      </Stack>
      <Box ml="24px">
        <CheckboxFieldBase
          disabled
          value={requireDocument}
          fieldLabel={t('request.question.document.requireDocument')}
        />
      </Box>
      <Box ml="24px">
        <CheckboxFieldBase
          checkboxStyle={{ marginLeft: '12px' }}
          disabled
          value={requireExpiry}
          fieldLabel={t('request.question.document.requireExpiry')}
        />
      </Box>
    </Flex>
  );
};
