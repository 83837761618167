import { useState, useMemo, useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { conforms, find, findIndex, isEmpty } from 'lodash';
import { documentExchangeTypes } from '@deepstream/common/exchangesConfig';
import {
  BulletinExchangeDefinition,
  ChatExchangeDefinition,
  DocumentExchangeDefinition,
  EvaluationCriterionExchangeDefinition,
  ExchangeSource,
  ExchangeStatus,
  ExchangeType,
  ScoringType,
  User,
} from '@deepstream/common/rfq-utils';
import { Box, Flex, Text } from 'rebass/styled-components';
import { exchangeStatusConfig, IconValue } from '@deepstream/common';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { EnDash } from '@deepstream/ui-kit/elements/text/EnDash';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils/types';
import { useCurrentCompanyId } from '../currentCompanyId';
import { useRecipientId, useRfqId } from '../useRfq';
import { StandaloneExchangeFields } from '../ExchangeTable';
import * as rfx from '../rfx';
import { useExchange } from '../useExchange';
import { and, isNotRole, isRole, Lock } from '../lock';
import { EvaluationCriterionNumber } from '../EvaluationCriterionDescriptionCell';
import { useCurrentUser } from '../useCurrentUser';
import { ExchangeTimeline } from './ExchangeTimeline';
import * as modal from './layout';
import { ActionNotificationSubject, useRfxActionNotificationSubject } from './useActionNotificationSubject';
import { useExchangeDefFieldValue } from '../ExchangeDefFieldValueContext';
import { EvaluationExchangeSnapshot, ExchangeHistoryAction, ExchangeSnapshot } from '../types';
import { useSendExchangeReplyContext } from './useSendExchangeReply';
import { SendMessageForm } from '../SendMessageForm';
import { ScrollToBottomHintButton, useScrollToBottomHint } from '../hooks/useScrollToBottomHint';
import { ExchangeSwitcher } from './ExchangeSwitcher';
import { MarkAsUnreadButton } from './MarkAsUnreadButton';
import { DownloadExchangeHistoryButton } from './DownloadExchangeHistoryButton';
import { InlineExchangeReply } from '../modules/Exchange/InlineExchangeReply';
import { useColumnById, useExchangeColumns } from '../modules/Exchange/columns';
import { usePossibleActions } from '../modules/Exchange/usePossibleActions';
import { BuyerFieldsSection } from './BuyerFieldsSection';
import { SupplierFieldsSection } from './SupplierFieldsSection';
import { FixedFieldsSection, FixedFieldsSectionProps } from './FixedFieldsSection';
import { useGetEvaluationExchangeStatus } from './useGetEvaluationExchangeStatus';
import { useHaveDeadlinesPassed } from '../deadline';
import { useIsEnabledAfterDeadline } from './useIsEnabledAfterDeadline';
import { useShouldShowDisableResponses, useShouldShowReenableResponses } from './ReenableResponsesDropdown';
import { useIsChatWithAllowedAttachments } from './useChatCanHaveAttachments';
import { getActionFilesAttachmentsLimit } from './exchangeReplyFormConfig';
import { useNotificationSubject } from '../modules/Notifications/useNotificationSubject';
import { Notification } from '../modules/Notifications/types';
import { requestAndLotBidStatusesAllowScoreSubmissions } from '../modules/NewEvaluation/utils';
import { TruncateCell } from '../TruncateCell';
import { useBulletinSection } from '../modules/RequestMessages/useBulletinSection';

const EvaluationExchangeFieldStatusIcon = ({ exchange }: { exchange: EvaluationExchangeSnapshot }) => {
  const structure = rfx.useStructure();
  const getStatus = useGetEvaluationExchangeStatus();

  const status = getStatus(exchange);

  // When the bid is inactive, then the user cannot take any action;
  // in this case, we render the BLOCKED icon to indicate that the
  // user can no longer submit a score.
  const effectiveStatus = (
    requestAndLotBidStatusesAllowScoreSubmissions(exchange, structure) ||
    status === ExchangeStatus.OBSOLETE
  )
    ? status
    : ExchangeStatus.BLOCKED;

  const { icon } = exchangeStatusConfig[effectiveStatus].buyer ?? {};
  const { value, color, isRegular } = icon?.[ExchangeType.EVALUATION_CRITERION] ?? icon?.default ?? {};

  return (
    <Icon
      regular={isRegular}
      icon={value as IconValue}
      color={color}
    />
  );
};

const EvaluationFieldsSection = ({
  exchange,
  recipientId,
}: {
  exchange: EvaluationExchangeSnapshot;
  recipientId?: string;
}) => {
  const { t } = useTranslation();
  const pageEvaluators = rfx.usePageEvaluators();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const scoringType = rfx.useEvaluationScoringType();
  const exchangeColumns = useExchangeColumns();
  const rfxPermissions = rfx.useRfxPermissions();
  const pagePermissions = rfx.usePagePermissions();
  const { getFieldValue } = useExchangeDefFieldValue();
  const { actions, clearResponse } = usePossibleActions(exchange);
  const [isReplyFormOpen, setIsReplyFormOpen] = useState<boolean | null>(null);
  const canTakeAction = actions.length > 0;
  const getStatus = useGetEvaluationExchangeStatus();
  const exchangeStatus = useMemo(
    () => getStatus(exchange),
    [exchange, getStatus],
  );
  const shouldOpenForm = canTakeAction && exchangeStatus === ExchangeStatus.ACTION_REQUIRED && isReplyFormOpen === null;
  const shouldCloseForm = !canTakeAction && isReplyFormOpen;

  useEffect(
    () => {
      setIsReplyFormOpen(null);
    },
    [exchange._id, recipientId, shouldCloseForm],
  );

  useEffect(
    () => {
      if (shouldOpenForm) {
        setIsReplyFormOpen(true);
      }
    },
    [shouldOpenForm],
  );

  const status = getStatus(exchange);
  const shouldShowEditMode = status === ExchangeStatus.COMPLETE || ExchangeStatus.WAITING_FOR_TEAM;

  // HACK: the evaluation criterion columns need access to the full list of
  // section exchange defs to calculate the weight as a percent. For now we'll
  // just grab the section exchange defs and only use them if necessary.
  const exchangeDefs = rfx.useSectionExchangeDefs();

  const columns = useMemo(() => {
    const columns = [];

    if (scoringType === ScoringType.SINGLE_SCORE) {
      // @ts-expect-error ts(2345) FIXME: Argument of type '{ id: string; Header: string; accessor: null; Cell: () => Element; flex: number; } | { id: string; Header: string; accessor: (exchange: any) => string; sortType: string; Cell: FC<CellProps<any>>; } | { ...; } | { ...; } | { ...; }' is not assignable to parameter of type 'never'.
      columns.push(...exchangeColumns.collaborativeEvaluationCriterionModal(
        {
          exchangeDefs: exchangeDefs as EvaluationCriterionExchangeDefinition[],
        },
        getFieldValue as any,
        pageEvaluators,
        { companyId: currentCompanyId, user: currentUser as unknown as User },
        pagePermissions.canRespond,
      ));
    } else if (rfxPermissions.canManageEvaluation) {
      // @ts-expect-error ts(2345) FIXME: Argument of type '{ id: string; Header: string; Cell: ({ row: { original: exchange }, }: CellProps<EvaluationExchangeSnapshot>) => Element; } | { id: string; Header: string; accessor: (exchange: any) => string; sortType: string; Cell: FC<...>; } | { ...; } | { ...; }' is not assignable to parameter of type 'never'.
      columns.push(...exchangeColumns.ownerEvaluationCriterionModal(
        {
          exchangeDefs: exchangeDefs as EvaluationCriterionExchangeDefinition[],
        },
        getFieldValue as any,
        pageEvaluators,
        { companyId: currentCompanyId, user: currentUser as unknown as User },
        pagePermissions.canRespond,
      ));
    } else {
      // @ts-expect-error ts(2345) FIXME: Argument of type '{ id: string; Header: string; accessor: null; Cell: () => Element; flex: number; } | { id: string; Header: string; accessor: (exchange: any) => string; sortType: string; Cell: FC<CellProps<any>>; } | { ...; } | { ...; } | { ...; }' is not assignable to parameter of type 'never'.
      columns.push(...exchangeColumns.teamMemberEvaluationCriterionModal(
        {
          exchangeDefs: exchangeDefs as EvaluationCriterionExchangeDefinition[],
        },
        getFieldValue as any,
        { companyId: currentCompanyId, user: currentUser as unknown as User },
        pagePermissions.canRespond,
      ));
    }

    return columns;
  }, [
    getFieldValue,
    exchangeColumns,
    currentCompanyId,
    currentUser,
    exchangeDefs,
    pageEvaluators,
    pagePermissions.canRespond,
    rfxPermissions.canManageEvaluation,
    scoringType,
  ]);

  const handleOnClose = () => {
    setIsReplyFormOpen(false);
  };

  const handleClearResponse = (selectedAction: ExchangeHistoryAction) => {
    setIsReplyFormOpen(false);
    clearResponse?.(selectedAction);
  };

  return (
    <modal.ClosableSection
      headerIcon={<EvaluationExchangeFieldStatusIcon exchange={exchange} />}
      header={t('request.lineItems.deliveryDate.evaluationFields')}
      rightHeaderSide={(
        <Text fontSize={1} color="subtext" mt={1}>
          <Icon icon="eye-slash" mr={1} />
          {t('request.exchange.internalOnly')}
        </Text>
      )}
      isHighlighted={!!isReplyFormOpen}
    >
      <Flex pt={3}>
        <Box flex={1}>
          {isReplyFormOpen ? (
            <InlineExchangeReply
              actions={actions}
              onClose={handleOnClose}
              clearResponse={clearResponse && handleClearResponse}
              columns={columns}
            />
          ) : (
            <StandaloneExchangeFields
              vertical
              small
              exchange={exchange}
              columns={columns}
            />
          )}
        </Box>
        {!isReplyFormOpen && (
          <Flex minWidth="100px" sx={{ gap: 2 }} justifyContent="flex-end">
            {canTakeAction && !shouldShowEditMode && (
              <Button
                sx={{ textTransform: 'capitalize' }}
                variant="primary"
                onClick={() => setIsReplyFormOpen(true)}
                small
                iconLeft="reply"
              >
                {t('respond', { ns: 'general' })}
              </Button>
            )}
            {canTakeAction && shouldShowEditMode && (
              <Button
                sx={{ textTransform: 'capitalize' }}
                variant="secondary-outline"
                onClick={() => setIsReplyFormOpen(true)}
                small
                iconLeft="pencil"
              >
                {t('edit', { ns: 'general' })}
              </Button>
            )}
          </Flex>
        )}
      </Flex>
    </modal.ClosableSection>
  );
};

const RecipientSection = ({
  exchange,
  recipientId,
  showRecipient,
}: {
  exchange: ExchangeSnapshot;
  recipientId?: string | null;
  showRecipient?: boolean;
}) => {
  const { recipients } = rfx.useStructure();
  const recipient = recipients.find(recipient => recipient._id === recipientId);
  const exchangeColumns = useExchangeColumns();
  const columns = useMemo(() => {
    if (showRecipient && recipient) {
      return exchangeColumns.recipientSection(recipient);
    } else {
      return [];
    }
  }, [showRecipient, recipient, exchangeColumns]);

  return isEmpty(columns) ? (
    null
  ) : (
    <modal.ClosableSection showHeader={false}>
      <StandaloneExchangeFields
        vertical
        small
        exchange={exchange}
        columns={columns}
      />
    </modal.ClosableSection>
  );
};

const RfxFixedFieldsSection = ({
  exchange,
  showRecipient,
} : FixedFieldsSectionProps) => {
  const haveDeadlinesPassed = useHaveDeadlinesPassed();
  const isEnabledAfterDeadline = useIsEnabledAfterDeadline();
  const isBidDeadlineExpired = haveDeadlinesPassed && !isEnabledAfterDeadline && !exchange.disabledReason;

  // When the deadline passes, it can take some time (up to a minute) for the
  // corresponding event to be added to the event log in the database. This results
  // in an awkward period of time where the deadline has passed, but the state
  // returned form the server doesn't reflect that, which leaves the exchange still
  // interactive. So we must enforce this deadline client-side (but not for internal
  // and message exchanges).
  //
  // Note: A user could circumvent this by changing their computer's clock.
  const reason = isBidDeadlineExpired ? 'bid-deadline-expired' : exchange.disabledReason;
  // @ts-expect-error ts(2345) FIXME: Argument of type 'string | null' is not assignable to parameter of type 'string'.
  const shouldShowReenableButton = useShouldShowReenableResponses(reason);
  const showDisableResponsesButton = useShouldShowDisableResponses();

  return (
    <FixedFieldsSection
      exchange={exchange}
      showRecipient={showRecipient}
      showReenableResponsesButton={shouldShowReenableButton}
      showDisableResponsesButton={showDisableResponsesButton}
    />
  );
};

export const RfxExchangeModalContent = memo(({
  close,
  showExchangeSwitcher,
  showFooter,
  showRecipient,
}: {
  close: (...args: any[]) => void;
  showExchangeSwitcher?: boolean;
  showFooter?: boolean;
  showRecipient?: boolean;
}) => {
  const { t } = useTranslation();
  const { setElement: setScrollToBottomRef, showHint, scrollToBottom } = useScrollToBottomHint();
  const rfqId = useRfqId();
  // The internal documents and buyer-side messages modals aren't wrapped with
  // a RecipientIdProvider so we need to set `required: false`
  const recipientId = useRecipientId({ required: false });
  const { newFeaturesDisabled, pages, bidById, sectionById } = rfx.useStructure();
  const column = useColumnById();
  const exchanges = rfx.useExchanges({ required: false });
  const exchange = useExchange();
  const linkedExchange = useMemo(() => {
    if (!('linkedExchangeDefId' in exchange.def)) {
      return null;
    } else {
      return find(exchanges, { _id: exchange.def.linkedExchangeDefId, recipientId }) as ExchangeSnapshot | undefined;
    }
  }, [exchanges, exchange, recipientId]);
  const linkedExchangeSection = linkedExchange?.def.sectionId
    ? sectionById[linkedExchange.def.sectionId]
    : null;
  const linkedExchangePage = useMemo(
    // @ts-expect-error ts(2339) FIXME: Property 'def' does not exist on type 'number | ExchangeSnapshot | { [x: number]: boolean | undefined; length?: boolean | undefined; toString?: boolean | undefined; toLocaleString?: boolean | undefined; ... 31 more ...; readonly [Symbol.unscopables]?: boolean | undefined; } | ... 11 more ... | ((searchElement: ExchangeSnapshot, fromIndex?: number | undef...'.
    () => linkedExchange && pages.find((page) => page.sections.includes(linkedExchange.def.sectionId)),
    [linkedExchange, pages],
  );
  const rfxPermissions = rfx.useRfxPermissions();
  const pagePermissions = rfx.usePagePermissions();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const isRecipient = currentCompanyId === recipientId;
  const isLineItem = exchange.def.type === ExchangeType.LINE_ITEM;
  const isBulletinExchange = exchange.def.type === ExchangeType.BULLETIN;
  const bulletinSection = useBulletinSection();
  const [sendExchangeReply] = useSendExchangeReplyContext();
  const { canPostComment } = usePossibleActions(exchange);
  const [timelineElement, setTimelineElement] = useState<HTMLDivElement | null>(null);
  const timelineElementHeight = useMemo(
    () => timelineElement ? timelineElement.getBoundingClientRect().height / 2 : undefined,
    [timelineElement],
  );
  const chatCanHaveAttachments = useIsChatWithAllowedAttachments();

  const { getFieldValue } = useExchangeDefFieldValue();

  const bulletinIndex = useMemo(() => {
    if (isBulletinExchange && bulletinSection) {
      return findIndex(
        bulletinSection.exchangeDefIds,
        exchangeDefId => exchangeDefId === exchange.def._id,
      );
    } else {
      return null;
    }
  }, [bulletinSection, exchange.def._id, isBulletinExchange]);

  const modalHeadingValue = useMemo(() => {
    if (exchange.def.type === ExchangeType.LINE_ITEM || exchange.def.type === ExchangeType.EVALUATION_CRITERION) {
      return getFieldValue(exchange.def, 'description');
    } else if (exchange.def.type === ExchangeType.INTERNAL_DOCUMENT || exchange.def.type === ExchangeType.CLARIFICATION) {
      return exchange.def.name;
    } else if (exchange.def.type === ExchangeType.QUESTION ||
      exchange.def.type === ExchangeType.INCLUSIONS ||
      exchange.def.type === ExchangeType.TERMS) {
      return exchange.def.description;
    } else if (documentExchangeTypes.includes(exchange.def.type)) {
      return (exchange.def as DocumentExchangeDefinition).category;
    } else if (exchange.isChat) {
      return (exchange.def as ChatExchangeDefinition).name;
    } else if (isBulletinExchange && bulletinIndex !== null) {
      return `${bulletinIndex + 1} – ${(exchange.def as BulletinExchangeDefinition).message}`;
    } else {
      return null;
    }
  }, [bulletinIndex, exchange.def, exchange.isChat, getFieldValue, isBulletinExchange]);

  const modalHeading = modalHeadingValue ?? <EmDash />;

  return (
    <>
      <modal.Content height={showFooter ? 'calc(100vh - 120px)' : 'calc(100vh - 56px)'}>
        <modal.Header
          onClose={close}
          leftActionComponent={showExchangeSwitcher && (
            <ExchangeSwitcher exchangeId={exchange._id} />
          )}
          rightActionComponent={
            <>
              {rfqId && exchange._id && (
                <MarkAsUnreadButton closeModal={close} />
              )}
              {rfqId && exchange._id && recipientId && !isRecipient && !isLineItem && !isBulletinExchange && (
                <DownloadExchangeHistoryButton
                  rfqId={rfqId}
                  recipientId={recipientId}
                  exchangeId={exchange._id}
                  disabled={!rfxPermissions.canDownloadReports || exchange.isLocked}
                />
              )}
            </>
          }
        >
          {exchange.def.type === ExchangeType.EVALUATION_CRITERION ? (
            <>
              <EvaluationCriterionNumber exchange={exchange} />{' '}
              <EnDash />{' '}
              {modalHeading}
            </>
          ) : exchange.isLocked ? (
            <Lock
              exchange={exchange}
              value={modalHeading}
              getIsLockableValue={and(isRole('evaluator'), isNotRole('initiator'))}
            >
              {modalHeading}
            </Lock>
          ) : (
            modalHeading
          )}
        </modal.Header>
        <modal.Body maxHeight="unset">
          {/*
           // @ts-expect-error ts(2322) FIXME: Type 'Dispatch<SetStateAction<HTMLDivElement | undefined>>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'. */}
          <modal.Fields p={0} flex={5} position="relative" ref={setScrollToBottomRef}>
            {exchange.def.type === ExchangeType.EVALUATION_CRITERION && (
              <>
                <RecipientSection
                  // @ts-expect-error ts(2322) FIXME: Type 'number | ExchangeSnapshot | { [x: number]: boolean | undefined; length?: boolean | undefined; toString?: boolean | undefined; toLocaleString?: boolean | undefined; ... 31 more ...; readonly [Symbol.unscopables]?: boolean | undefined; } | ... 13 more ... | undefined' is not assignable to type 'ExchangeSnapshot'.
                  exchange={linkedExchange}
                  recipientId={recipientId}
                  showRecipient={showRecipient}
                />
                <EvaluationFieldsSection
                  exchange={exchange as EvaluationExchangeSnapshot}
                  // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string | undefined'.
                  recipientId={recipientId}
                />
              </>
            )}
            {linkedExchange ? (
              // @ts-expect-error ts(2322) FIXME: Type '0 | Page | null | undefined' is not assignable to type 'Page'.
              <rfx.PageProvider page={linkedExchangePage}>
                <rfx.SectionProvider section={linkedExchangeSection}>
                  <FixedFieldsSection exchange={linkedExchange} isReadOnly />
                  <BuyerFieldsSection exchange={linkedExchange} />
                  <SupplierFieldsSection
                    exchange={linkedExchange}
                    activatedStageIds={recipientId ? bidById[recipientId]?.activatedStageIds : undefined}
                  />
                </rfx.SectionProvider>
              </rfx.PageProvider>
            ) : (
              <>
                {isBulletinExchange && (
                  <Box padding="16px 116px 0px 16px">
                    <StandaloneExchangeFields
                      vertical
                      small
                      exchange={{ ...exchange, bulletinIndex }}
                      columns={[{ ...column.bulletinNumber({ Header: t('request.bulletin.bulletinNumber', { ns: 'translation' }) }), Cell: TruncateCell }]}
                    />
                  </Box>
                )}
                <RfxFixedFieldsSection
                  exchange={exchange}
                  showRecipient={showRecipient}
                />
                <BuyerFieldsSection exchange={exchange} />
                {/*
                 // @ts-expect-error ts(2538) FIXME: Type 'null' cannot be used as an index type. */}
                <SupplierFieldsSection exchange={exchange} activatedStageIds={bidById[recipientId]?.activatedStageIds} />
              </>
            )}
            <ScrollToBottomHintButton sx={{ position: 'sticky', bottom: '10px', left: '50%', visibility: showHint ? 'visible' : 'hidden' }} onClick={scrollToBottom} />
          </modal.Fields>
          <modal.Timeline bg="lightGray3" flex={3} overflowY="hidden" ref={setTimelineElement}>
            <modal.TimelineHeader>
              {t('request.exchange.activityAndComments')}
            </modal.TimelineHeader>
            <ActionNotificationSubject.Provider value={useRfxActionNotificationSubject}>
              <ExchangeTimeline
                canRespond={pagePermissions.canRespond}
                canRenderLatestResponseStageLink={!newFeaturesDisabled}
              />
            </ActionNotificationSubject.Provider>
            {canPostComment && (
              <modal.TimelineLeaveComment>
                <SendMessageForm
                  onSubmit={sendExchangeReply}
                  placeholder={t('request.exchange.leaveAComment')}
                  buttonProps={{ variant: 'primary-outline' }}
                  showButtonOnFocus
                  canHaveAttachments={chatCanHaveAttachments}
                  actionFieldAttachmentsLimit={getActionFilesAttachmentsLimit(exchange)}
                  maxTextFieldHeight={timelineElementHeight}
                />
              </modal.TimelineLeaveComment>
            )}
          </modal.Timeline>
        </modal.Body>
      </modal.Content>
      {(
        isRecipient &&
        exchange.def.type === ExchangeType.CHAT_NO_RECEIVER_UPLOAD &&
        (exchange.def as any).source === ExchangeSource.BID_AWARDED
      ) && (
        <MarkAwardedMessageAsRead />
      )}
    </>
  );
});

const MarkAwardedMessageAsRead = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqId = useRfqId();

  const ref = useNotificationSubject({
    filter: conforms<Partial<Notification>>({
      domain: domain => domain === NotificationDomain.RFQ_RECEIVED,
      action: action => action === NotificationAction.RFQ_AWARDED,
      meta: meta => meta.rfqId === rfqId,
      to: to => to.companyId === currentCompanyId,
    }),
  });

  return (
    <div ref={ref} />
  );
};
