import { useMemo, useEffect, useState, useCallback, forwardRef, useImperativeHandle, useRef } from 'react';
import { filter, find, first, isEmpty, map, mapValues, noop, omit, set, size } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps, useField, useFormikContext } from 'formik';
import * as yup from 'yup';
import { Flex } from 'rebass/styled-components';
import {
  LineItemsSection,
  CurrencyExchangeDefinition,
  ExchangeProvider,
  LineItemExchangeDefinition,
  isCurrencyExchangeDef,
  isLineItemExchangeDef,
  getExchangeDefFieldValue,
  setExchangeDefFieldValue,
  isSectionLinkedFromEvaluation,
  isLinkedAuctionLineItemExchangeDef,
  hasSupplierPriceField,
  isSupplierReplyField,
  StageType,
  FieldScope,
  getStageIdFromTag,
  getTagFromStageId,
  ResponseTag,
  Draft,
  RfxOtherSection,
  RfxSection,
} from '@deepstream/common/rfq-utils';
import { isPast } from 'date-fns';
import { FieldType } from '@deepstream/common/exchangesConfig';
import { SaveButton, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { ExpandablePanelSubSection, PanelDivider, PanelPadding, PanelSubHeader } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog, DialogProps } from '@deepstream/ui-kit/elements/popup/Dialog';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { EditableGridDataProvider, useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { LegacyMultiStageLineItemsEventType } from '@deepstream/common/multiStageLineItemsTracking';
import { callAll } from '@deepstream/utils/callAll';
import { assertDefined } from '@deepstream/utils';
import * as draft from './draft';
import * as rfx from '../rfx';
import { useCurrencySelectItems } from '../ui/currencies';
import { useStageSelectItems } from './useStageSelectItems';
import { MappingSwitchField } from '../form/SwitchField';
import { useConfirmDialog, useModalState } from '../ui/useModalState';
import { getItemLabel, getItemLabelWithDescription, getItemValue, SelectField, SelectFieldBase } from '../form/SelectField';
import { DraftPanel } from './DraftPanel';
import { usePreventEnterKeyHandler } from '../usePreventEnterKeyHandler';
import { CurrencyCodeProvider } from '../ui/Currency';
import { useSystemFeatureFlags } from '../systemFeatureFlags';
import { GlobalDatePickerStyles } from '../ui/DatePicker';
import { LineItemExchangeDefsGrid, LineItemExchangeDefsGridProps } from '../ui/ExchangeDefsGrid/LineItemExchangeDefsGrid';
import { MultiSelectField } from '../form/MultiSelectField';
import { AddBuyerProvidedLineItemButton } from './AddLineItemButton';
import { AddLineItemFieldDropdown } from './AddLineItemFieldDropdown';
import { MoreActionsLineItemDropdown } from './MoreActionsLineItemDropdown';
import { ExpandViewButton } from '../modules/Request/Comparison/ExpandViewButton';
import { ModelSizeLimitDialog, ModelSizeLimitMessages } from '../ModelSizeLimitDialog';
import { getSizeRelevantExchangeDefCount, useModelSizeLimits } from '../modelSizeLimits';
import { LineItemsSectionConfigIndicators } from './LineItemsSectionConfigIndicators';
import { DraftSectionLabelConfigProvider } from './DraftSectionLabelConfigProvider';
import { LockField } from './LockField';
import { SectionConfigHeading } from './SectionConfigHeading';
import { DraftSectionEditPanelHeader } from './DraftSectionEditPanelHeader';
import { MultiStageBehavior, useMultiStageBehaviorSelectItems } from './useMultiStageBehaviorSelectItems';
import { DraftSectionNestedLabelConfigProvider } from './DraftSectionNestedLabelConfigProvider';
import { useFieldScopeSelectItems } from './useFieldScopeSelectItems';
import { ErrorMessage } from '../form/Field';
import { CheckboxListField } from '../form/CheckboxListField';
import { useMultiStageBehaviorDescription } from './useMultiStageBehaviorDescription';
import { LeavePageModal } from './LeavePageModal';
import { SectionConfigLotSelectField } from './SectionConfigLotSelectField';
import { NO_LOT_PROXY_ID, useLotSelectItems } from './useLotSelectItems';
import { useHandleGridLotChange } from './useHandleGridLotChange';
import { useRfqId } from '../useRfq';
import { useTracking } from '../useTracking';
import { EnableMultiStageResponsesSectionErrors, useEnableMultiStageResponsesSectionErrors } from './useEnableMultiStageResponsesSectionErrors';

const ConfirmDiscardCustomSelectionDialog = (props: Omit<DialogProps, 'heading' | 'body'>) => {
  const { t } = useTranslation();

  return (
    <Dialog
      heading={t('request.multiStageBehavior.confirmDiscardCustomSelectionDialog.heading')}
      body={
        <MessageBlock variant="warn" mt={3}>
          {t('request.multiStageBehavior.confirmDiscardCustomSelectionDialog.body')}
        </MessageBlock>
      }
      style={{ content: { width: '500px' } }}
      okButtonText={t('request.multiStageBehavior.confirmDiscardCustomSelectionDialog.okButtonText')}
      okButtonVariant="danger"
      cancelButtonText={t('request.multiStageBehavior.confirmDiscardCustomSelectionDialog.cancelButtonText')}
      {...props}
    />
  );
};

const ConfirmRemoveStageDialog = (props: Omit<DialogProps, 'heading' | 'body'>) => {
  const { t } = useTranslation();

  return (
    <Dialog
      heading={t('request.multiStageBehavior.confirmRemoveStageDialog.heading')}
      body={
        <MessageBlock variant="warn" mt={3}>
          {t('request.multiStageBehavior.confirmRemoveStageDialog.body')}
        </MessageBlock>
      }
      style={{ content: { width: '500px' } }}
      okButtonText={t('request.multiStageBehavior.confirmRemoveStageDialog.okButtonText')}
      okButtonVariant="danger"
      cancelButtonText={t('request.multiStageBehavior.confirmRemoveStageDialog.cancelButtonText')}
      {...props}
    />
  );
};

const CollapsedEditSectionConfig = () => {
  const { settings } = rfx.useStructure();
  const [{ value: responseTagConfig }] = useField({ name: 'section.responseTagConfig' });
  const [{ value: stageIds }] = useField({ name: 'section.stages' });
  const [{ value: locking }] = useField('section.locking');
  const [{ value: providedBy }] = useField('section.providedBy');
  const [{ value: lotIds }] = useField<string[] | null>('section.lotIds');
  const [{ value: currencyExchangeDef }] = useField({ name: 'currencyExchangeDef' });
  const { rowData: lineItemExchangeDefs } = useEditableGridData<LineItemExchangeDefinition>();

  const stageId = first<string>(stageIds);

  // We expect that there's always at least one selected stage.
  assertDefined(stageId, 'stageId');

  const modelExchangeDef = lineItemExchangeDefs[0];

  const multiStageBehavior = useMultiStageBehaviorDescription(responseTagConfig, stageId);
  const lotSelectItems = useLotSelectItems();
  const selectedLot = settings.areLotsEnabled
    ? find(lotSelectItems, { value: first(lotIds) || NO_LOT_PROXY_ID })
    : null;

  return (
    <LineItemsSectionConfigIndicators
      isSender
      multiStageBehavior={multiStageBehavior}
      locking={locking}
      providedBy={providedBy}
      modelExchangeDef={modelExchangeDef}
      currencyExchangeDef={currencyExchangeDef}
      selectedLot={selectedLot}
    />
  );
};

const useIsBeyondFirstResponseStage = () => {
  const { meta: { enteredRecipientStageIds } } = rfx.useStructure();
  const section = rfx.useSection<LineItemsSection>();

  return Boolean(
    section.isLive &&
    section.liveVersion &&
    section.liveVersion.responseTagConfig &&
    enteredRecipientStageIds &&
    enteredRecipientStageIds.includes(getStageIdFromTag(section.liveVersion.responseTagConfig.tags[1])),
  );
};

const ExpandedEditSectionConfig = ({ isEnableMultiStageResponsesFlow } : { isEnableMultiStageResponsesFlow?: boolean }) => {
  const { t } = useTranslation('translation');
  const [{ value: responseTagConfig }, , responseTagConfigFormik] = useField({ name: 'section.responseTagConfig' });
  const [, , tagsFormik] = useField({ name: 'section.responseTagConfig.tags' });
  const [{ value: stageIds }, , stagesFormik] = useField({ name: 'section.stages' });
  const [{ value: isSectionObsolete }] = useField<boolean | null>('section.isObsolete');
  const [{ value: currencyExchangeDef }, , currencyExchangeDefFormik] = useField({ name: 'currencyExchangeDef' });
  const { submitCount } = useFormikContext();
  const wasFormSubmitted = submitCount > 0;

  const {
    updateEachRowWith,
    rowData: lineItemExchangeDefs,
  } = useEditableGridData<LineItemExchangeDefinition>();
  const { confirm: confirmDiscardSelection, ...confirmDiscardSelectionDialogProps } = useConfirmDialog();
  const { confirm: confirmRemoveStage, ...confirmRemoveStageDialogProps } = useConfirmDialog();
  const handleLotChange = useHandleGridLotChange();

  const { meta: { enteredRecipientStageIds }, settings } = rfx.useStructure();
  const isBeyondFirstResponseStage = useIsBeyondFirstResponseStage();
  const stageSelectItems = useStageSelectItems();
  const multiStageBehaviorSelectItems = useMultiStageBehaviorSelectItems();
  const fieldScopeSelectItems = useFieldScopeSelectItems();
  const stageId = first<string>(stageIds);
  const allStages = rfx.useStages();
  const section = rfx.useSection<RfxOtherSection>();
  const currencySelectItems = useCurrencySelectItems();
  const senders = rfx.useSenders();
  const modelExchangeDef = lineItemExchangeDefs[0];
  const fields = modelExchangeDef?.fields;

  const showSelectBuyerCurrency = Boolean(fields?.evaluatorFieldCurrency);

  const {
    numExchangeStages,
    tagSelectItems,
    disabledTagSelectItems,
  } = useMemo(() => {
    const tagSelectItems = stageSelectItems.map(({ value, label }) => ({
      _id: getTagFromStageId(value),
      name: label,
    }));

    const previouslyEnteredStageIds = (enteredRecipientStageIds || []).slice(0, -1);

    // When we're beyond the first response stage, editing the field
    // configuration is not allowed but would be necessary a user reduces
    // the number of selected stages to 1. For this reason, we make sure
    // that the number of selected stages does not drop below 2.
    const preventDeselection = (
      isBeyondFirstResponseStage &&
      responseTagConfig &&
      size(responseTagConfig.tags) <= 2
    );

    const disabledTagSelectItems = stageSelectItems
      .filter(({ disabled, value }) => (
        disabled ||
        previouslyEnteredStageIds.includes(value) ||
        (preventDeselection && responseTagConfig.tags.includes(getTagFromStageId(value)))
      ))
      .map(({ value }) => getTagFromStageId(value));

    return {
      tagSelectItems,
      disabledTagSelectItems,
      numExchangeStages: tagSelectItems.length - disabledTagSelectItems.length,
    };
  }, [stageSelectItems, enteredRecipientStageIds, responseTagConfig, isBeyondFirstResponseStage]);

  const requiredFieldsHelperText = useMemo(() => {
    if (!fields || !responseTagConfig) {
      return null;
    }

    const includedFieldNames = Object.entries(fields)
      .filter(([, field]) => (
        isSupplierReplyField(field) &&
        size(field.responseTags) > 1
      ))
      .map(([fieldId, field]) => {
        const label = 'label' in field ? field.label : null;

        return `“${label || t(`request.fields.predefinedFieldLabel.${fieldId.split(':')[0]}`)}”`;
      })
      .join(', ');

    const textOrError = includedFieldNames || (
      // We don't show an error message when there are not enough tags, in which
      // case there can't be any field-specific tag information -- in this case,
      // the error should only show in the stage select component.
      responseTagConfig.tags.length > 1
        ? <ErrorMessage error={t('request.multiStageBehavior.minimumOneField')} fontWeight="normal" />
        : null
    );

    return responseTagConfig.fieldScope === FieldScope.CUSTOM
      ? (
        <>
          {textOrError}
          {includedFieldNames && <br />}
          {t('request.multiStageBehavior.useEditField')}
        </>
      )
      : textOrError;
  }, [fields, t, responseTagConfig]);

  const setFirstVisibleStage = (stageId: string) => {
    const allStageIds = map(allStages, '_id');
    const indexFrom = allStageIds.indexOf(stageId);
    const newStages = allStageIds.slice(indexFrom);

    const targetCurrencyExchangeDef = {
      ...currencyExchangeDef,
      stages: newStages,
    };

    stagesFormik.setValue(newStages);
    currencyExchangeDefFormik.setValue(targetCurrencyExchangeDef);

    updateEachRowWith(exchangeDef => ({ ...exchangeDef, stages: newStages }));
  };

  const setExchangeDefTags = (fieldScope: FieldScope, tags: ResponseTag[], preserveRequiredFieldsSettings?: boolean) => {
    const setTags = fieldScope === FieldScope.ALL_FIELDS ? (
      field => ({
        ...field,
        responseTags: [...tags],
      })
    ) : fieldScope === FieldScope.ALL_PRICE_FIELDS ? (
      field => ({
        ...field,
        responseTags: field.type === FieldType.PRICE ? [...tags] : tags.slice(0, 1),
      })
    ) : (
      field => ({
        ...field,
        responseTags: preserveRequiredFieldsSettings && size(field.responseTags) > 1
          ? [...tags]
          : tags.slice(0, 1),
      })
    );

    updateEachRowWith(exchangeDef => {
      return {
        ...exchangeDef,
        fields: mapValues(
          exchangeDef.fields,
          field => {
            if (isSupplierReplyField(field)) {
              return setTags(field);
            } else {
              return field;
            }
          },
        ),
      } as LineItemExchangeDefinition;
    });
  };

  const removeExchangeDefTags = () => {
    updateEachRowWith(exchangeDef => {
      return {
        ...exchangeDef,
        fields: mapValues(
          exchangeDef.fields,
          field => {
            if (isSupplierReplyField(field)) {
              return omit(field, 'exchangeDefTags');
            } else {
              return field;
            }
          },
        ),
      } as LineItemExchangeDefinition;
    });
  };

  // Multi-stage behaviour can only be edited for non-obsolete sections where
  // either more than 1 exchange stage is available for assignment as
  // multi-stage response stage, or where multi-stage responses are already
  // configured for the section.
  const canEditMultiStageBehavior = !isSectionObsolete && (numExchangeStages > 1 || responseTagConfig);

  return (
    <>
      <DraftSectionLabelConfigProvider>
        {settings.areLotsEnabled && (
          <>
            <PanelPadding>
              <SectionConfigLotSelectField
                name="section.lotIds"
                canSelectObsoleteItem={section.isLive}
                onChange={handleLotChange}
              />
            </PanelPadding>
            {!isSectionObsolete && (
              <PanelDivider />
            )}
          </>
        )}
        <Flex
          alignItems="flex-start"
          justifyContent="stretch"
          flexDirection={['column', null, null, 'row']}
          sx={{ width: '100%' }}
        >
          <PanelPadding flexShrink={0}>
            <SelectFieldBase
              label={t('request.multiStageBehavior.multiStageBehavior')}
              description={t('request.multiStageBehavior.multiStageBehaviorDescription')}
              items={multiStageBehaviorSelectItems}
              value={responseTagConfig ? MultiStageBehavior.PER_STAGE : MultiStageBehavior.SINGLE}
              onChange={newMultiStageBehavior => {
                // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
                setFirstVisibleStage(allStages.find(stage => stage.type !== StageType.AUCTION)._id);

                if (newMultiStageBehavior === MultiStageBehavior.PER_STAGE) {
                  responseTagConfigFormik.setValue({
                    fieldScope: FieldScope.ALL_PRICE_FIELDS,
                    tags: [],
                  });
                } else {
                  removeExchangeDefTags();
                  responseTagConfigFormik.setValue(null);
                }
              }}
              // The multi-stage behaviour of published sections can only be changed
              // from SINGLE_RESPONSE to PER_STAGE but not vice versa
              disabled={!canEditMultiStageBehavior || (section.isLive && Boolean(section.liveVersion?.responseTagConfig))}
              getItemLabel={getItemLabelWithDescription}
              getButtonLabel={getItemLabel}
              menuWidth={300}
              buttonStyle={{ fontWeight: 500, width: 200 }}
              error={
                isEnableMultiStageResponsesFlow && wasFormSubmitted && !responseTagConfig
                  ? t('request.setupMultiStageResponsesFlow.steps.configureSection.errors.responsePerStageRequiredInline')
                  : undefined
              }
            />
          </PanelPadding>
          {responseTagConfig ? (
            <PanelPadding mt="7px" sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <DraftSectionNestedLabelConfigProvider width="150px" defaultStyle={{ top: '2px' }}>
                <CheckboxListField
                  name="section.responseTagConfig.tags"
                  label={t('request.multiStageBehavior.selectedStages')}
                  items={tagSelectItems}
                  disabledItems={disabledTagSelectItems}
                  disabled={!canEditMultiStageBehavior}
                  preserveItemOrder
                  alwaysShowError
                  onChange={(selectedItems) => {
                    const action = () => {
                      const newFirstVisibleStage = isEmpty(selectedItems)
                        // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
                        ? allStages.find(stage => stage.type !== StageType.AUCTION)._id
                        : getStageIdFromTag(selectedItems[0]);
                      setFirstVisibleStage(newFirstVisibleStage);
                      setExchangeDefTags(responseTagConfig.fieldScope, selectedItems, true);

                      tagsFormik.setTouched(true);
                      tagsFormik.setValue(selectedItems);
                    };

                    // when reducing the number of stages to less than 2,
                    // ask for confirmation because in this case, per-field
                    // settings of the required fields for later stages
                    // would get removed when the field scope is FieldScope.CUSTOM.
                    if (
                      selectedItems.length < 2 &&
                      responseTagConfig.fieldScope === FieldScope.CUSTOM &&
                      fields &&
                      Object.values(fields).some(field => (
                        isSupplierReplyField(field) &&
                        size(field.responseTags) > 1
                      ))
                    ) {
                      confirmRemoveStage(action);
                    } else {
                      action();
                    }
                  }}
                />
                <SelectField
                  name="section.responseTagConfig.fieldScope"
                  label={t('request.multiStageBehavior.requiredFields')}
                  infoTooltip={t('request.multiStageBehavior.requiredFieldsInfo')}
                  items={fieldScopeSelectItems}
                  getItemLabel={getItemLabelWithDescription}
                  getButtonLabel={getItemLabel}
                  menuWidth={300}
                  buttonStyle={{ fontWeight: 500, width: 200 }}
                  helperText={requiredFieldsHelperText}
                  disabled={!canEditMultiStageBehavior || isBeyondFirstResponseStage}
                  onChange={(newFieldScope) => {
                    const action = () => {
                      responseTagConfigFormik.setValue({
                        fieldScope: newFieldScope,
                        tags: responseTagConfig.tags,
                      });

                      setExchangeDefTags(newFieldScope, responseTagConfig.tags);
                    };

                    if (
                      responseTagConfig.fieldScope === FieldScope.CUSTOM &&
                      fields &&
                      Object.values(fields).some(field => (
                        isSupplierReplyField(field) &&
                        size(field.responseTags) > 1
                      ))
                    ) {
                      confirmDiscardSelection(action);
                    } else {
                      action();
                    }
                  }}
                />
              </DraftSectionNestedLabelConfigProvider>
            </PanelPadding>
          ) : (
            <PanelPadding>
              <DraftSectionNestedLabelConfigProvider>
                <SelectFieldBase
                  label={t('request.visibleFromStage')}
                  items={stageSelectItems}
                  value={stageId}
                  disabled={!canEditMultiStageBehavior || section.isLive}
                  onChange={setFirstVisibleStage}
                  buttonStyle={{ fontWeight: 500, width: 200 }}
                />
              </DraftSectionNestedLabelConfigProvider>
            </PanelPadding>
          )}
        </Flex>
        <PanelDivider />
        {!isSectionObsolete && hasSupplierPriceField(modelExchangeDef?.fields) ? (
          <>
            <PanelPadding>
              <MultiSelectField
                name="currencyExchangeDef.currencies"
                label={t('request.lineItems.currency.supplierCurrency')}
                description={t('request.lineItems.currency.supplierCurrenciesDescription')}
                required
                disabled={isBeyondFirstResponseStage}
                variant="secondary-outline"
                items={currencySelectItems}
                itemToString={getItemValue}
                buttonWidth={200}
                menuWidth={270}
                placeholder={t('request.lineItems.currency.select')}
                getButtonText={(items) => items.length === 1 ? (
                  items[0].label
                ) : items.length > 1 ? (
                  items.map(item => item.value).join(', ')
                ) : null
                }
                alwaysShowCaret
              />
            </PanelPadding>
            <PanelDivider />
          </>
        ) : (
          null
        )}
        {!isSectionObsolete && showSelectBuyerCurrency ? (
          <>
            <PanelPadding>
              <SelectFieldBase
                label={t('request.lineItems.currency.buyerCurrency')}
                description={t('request.lineItems.currency.buyerCurrencyDescription')}
                items={currencySelectItems}
                // @ts-expect-error ts(2345) FIXME: Argument of type 'string' is not assignable to parameter of type 'never'.
                value={getExchangeDefFieldValue(modelExchangeDef, 'evaluatorFieldCurrency')}
                placeholder={t('request.lineItems.currency.select')}
                menuWidth={270}
                required
                disabled={isBeyondFirstResponseStage}
                onChange={currency => {
                  updateEachRowWith(exchangeDef =>
                    setExchangeDefFieldValue(exchangeDef, 'evaluatorFieldCurrency', currency || null),
                  );
                }}
                buttonStyle={{ fontWeight: 500, maxWidth: 200 }}
              />
            </PanelPadding>
            <PanelDivider />
          </>
        ) : (
          null
        )}
        {!isSectionObsolete && (
          <>
            <PanelPadding>
              <LockField
                name="section.locking"
                label={t('request.lockResponses')}
                description={t('request.lockResponsesDescription')}
                senders={senders}
                isSectionLive={section.isLive}
              />
            </PanelPadding>
            <PanelDivider />
            <PanelPadding>
              <MappingSwitchField
                name="section.providedBy"
                label={t('request.lineItems.supplierAddedLineItems')}
                description={t('request.lineItems.supplierAddedLineItemsDescription')}
                disabled={section.liveVersion?.providedBy === ExchangeProvider.BOTH}
                mapFromField={(value) => value === ExchangeProvider.BOTH}
                mapToField={(checked) => checked
                  ? ExchangeProvider.BOTH
                  : ExchangeProvider.BUYER}
                checkedIcon={false}
                uncheckedIcon={false}
                width={42}
                checkedText={t('general.abstractFeatureEnabled')}
                uncheckedText={t('general.abstractFeatureDisabled')}
                useDefaultLabelConfig={false}
              />
            </PanelPadding>
          </>
        )}
      </DraftSectionLabelConfigProvider>
      <ConfirmDiscardCustomSelectionDialog {...confirmDiscardSelectionDialogProps} />
      <ConfirmRemoveStageDialog {...confirmRemoveStageDialogProps} />
    </>
  );
};

const EditSectionConfig = ({ isEnableMultiStageResponsesFlow } : { isEnableMultiStageResponsesFlow?: boolean }) => {
  const { t } = useTranslation('general');

  const [isExpanded, setIsExpanded] = useState<boolean>();
  const onCollapsedCallback = useCallback((isExpanded) => setIsExpanded(isExpanded), []);

  const { submitCount } = useFormikContext();
  const errors = useEnableMultiStageResponsesSectionErrors();
  const error = useMemo(() => {
    const wasFormSubmitted = submitCount > 0;

    return isEnableMultiStageResponsesFlow &&
      wasFormSubmitted &&
      !isExpanded &&
      (errors?.responsePerStageRequired || errors?.twoStagesRequired)
      ? t('errorCount', { count: 1 })
      : undefined;
  }, [
    errors?.responsePerStageRequired,
    errors?.twoStagesRequired,
    isEnableMultiStageResponsesFlow,
    isExpanded,
    submitCount,
    t,
  ]);

  return (
    <ExpandablePanelSubSection
      heading={<SectionConfigHeading error={error} />}
      renderCollapsedContent={() => <CollapsedEditSectionConfig />}
      renderExpandedContent={() => <ExpandedEditSectionConfig isEnableMultiStageResponsesFlow={isEnableMultiStageResponsesFlow} />}
      onExpandedCallback={onCollapsedCallback}
    />
  );
};

const EditPanelFooter = () => {
  const {
    isSubmitting,
    isValid,
    dirty: isFormikDataDirty,
  } = useFormikContext();
  const { stopEditing } = rfx.useActions();
  const {
    isDirty: isGridDataDirty,
    editedCell,
  } = useEditableGridData<LineItemExchangeDefinition>();

  const isDirty = isGridDataDirty || isFormikDataDirty;
  const isEditingCell = Boolean(editedCell);

  return (
    <PanelPadding>
      <Flex justifyContent="flex-end">
        <CancelButton onClick={stopEditing} mr={2} />
        <SaveButton disabled={isEditingCell || isSubmitting || !isDirty || !isValid} />
      </Flex>
    </PanelPadding>
  );
};

const DeleteSectionButton = ({
  onDeleteSuccess,
}: {
  onDeleteSuccess: (sectionId: string) => void;
}) => {
  const { t } = useTranslation('translation');
  const section = rfx.useSection();
  const [removeSection] = draft.useRemoveSection({ onSuccessCallback: onDeleteSuccess });

  return (
    <PanelPadding>
      <SaveButton
        type="button"
        onClick={() => removeSection(section._id)}
        variant="danger-outline"
      >
        {t('request.deleteSection')}
      </SaveButton>
    </PanelPadding>
  );
};

const EnableMultiStageReponsesFlowErrorsWrapper = ({
  onEnableMultiStageResponsesErrorsChange,
}: {
  onEnableMultiStageResponsesErrorsChange: (
    errors: EnableMultiStageResponsesSectionErrors,
  ) => void;
}) => {
  const errors = useEnableMultiStageResponsesSectionErrors();

  useEffect(() => {
    onEnableMultiStageResponsesErrorsChange(errors);
  }, [errors, onEnableMultiStageResponsesErrorsChange]);

  return null;
};

const FormikLineItemExchangeDefsGrid = (props: LineItemExchangeDefsGridProps) => {
  const {
    rowData: lineItemExchangeDefs,
  } = useEditableGridData<LineItemExchangeDefinition>();
  const { setValues } = useFormikContext<{ section: LineItemsSection }>();

  useEffect(() => {
    setValues((values) => ({
      ...values,
      exchangeDefs: lineItemExchangeDefs,
    }));
  }, [lineItemExchangeDefs, setValues]);

  return (
    <LineItemExchangeDefsGrid
      {...props}
    />
  );
};

const LineItemsSectionEditPanelContent = forwardRef<LineItemsSectionEditPanelRef, LineItemsSectionEditPanelProps>(({
  isEnableMultiStageResponsesFlow,
  showDeleteButton,
  onSectionDeleted,
  onEnableMultiStageResponsesErrorsChange: onEnableMultiStageResponsesFlowErrorsChange = noop,
}, ref) => {
  const { t } = useTranslation('translation');
  const [isExpandedView, setIsExpandedView] = useState(false);
  const { stopEditing } = rfx.useActions();
  const section = rfx.useSectionWithPosition<LineItemsSection>();
  const exchangeDefs = rfx.useSectionExchangeDefs();
  const isSender = rfx.useIsSender();
  const { isEditingSupplierExchangeDefs, isTemplate } = rfx.useState();
  const [saveSection] = draft.useSaveLineItemsSection({ hideSuccessToaster: isEnableMultiStageResponsesFlow });
  const onKeyDown = usePreventEnterKeyHandler();
  const isBeyondFirstResponseStage = useIsBeyondFirstResponseStage();
  const {
    rowData: lineItemExchangeDefs,
  } = useEditableGridData<LineItemExchangeDefinition>();
  const currencyExchangeDef = find(exchangeDefs, isCurrencyExchangeDef)!;
  const initialLineItemExchangeDefs = filter(exchangeDefs, isLineItemExchangeDef);
  const getOtherSectionFieldLabelChanges = draft.useGetOtherSectionFieldLabelChanges();
  const otherSectionFields = rfx.useOtherSectionLineItemFields();
  const { exchangeDefById, sectionById } = rfx.useStructure<Draft>();
  const auction = rfx.useAuction();
  const systemFeatureFlags = useSystemFeatureFlags({ required: true });
  const { maxExchangeDefCount } = useModelSizeLimits();
  const modelSizeLimitModal = useModalState();
  const [modelSizeLimitMessages, setModelSizeLimitMessages] = useState<ModelSizeLimitMessages | null>(null);

  const {
    isExchangeDefLinkedToActiveAuction,
    areAnyExchangeDefsLinkedToAuction,
    areAnyExchangeDefsLinkedToActiveAuction,
  } = useMemo(() => {
    const isAuctionActive = auction && isPast(new Date(auction.startDate));

    const auctionLinkedExchangeDefIds = Object.values(exchangeDefById)
      .filter(isLinkedAuctionLineItemExchangeDef)
      .map((exchangeDef) => exchangeDef.linkedExchangeDefId);

    const isExchangeDefLinkedToActiveAuction = exchangeDef =>
      isAuctionActive && auctionLinkedExchangeDefIds.includes(exchangeDef._id);

    const areAnyExchangeDefsLinkedToAuction = exchangeDefs.some(exchangeDef => (
      auctionLinkedExchangeDefIds.includes(exchangeDef._id)
    ));

    const areAnyExchangeDefsLinkedToActiveAuction = (
      areAnyExchangeDefsLinkedToAuction &&
      isAuctionActive
    );

    return {
      isExchangeDefLinkedToActiveAuction,
      areAnyExchangeDefsLinkedToAuction,
      areAnyExchangeDefsLinkedToActiveAuction,
    };
  }, [auction, exchangeDefs, exchangeDefById]);

  const rfqId = useRfqId();
  const { trackMultiStageLineItemsEvent } = useTracking();
  const trackMultiStageLineItemsEnabled = useCallback((newSection: RfxSection) => {
    // We only track FEATURE_ENABLED for templates here -- for requests,
    // we track it when the request gets sent / the revision gets issued.
    if (isTemplate && !section.responseTagConfig && newSection.responseTagConfig) {
      trackMultiStageLineItemsEvent({
        eventType: LegacyMultiStageLineItemsEventType.FEATURE_ENABLED,
        entityType: 'requestTemplate',
        entityId: rfqId,
      });
    }
  }, [rfqId, trackMultiStageLineItemsEvent, section.responseTagConfig, isTemplate]);

  const handleSubmit = ({ section, currencyExchangeDef }, { setSubmitting }) => {
    const totalExchangeDefCount = getSizeRelevantExchangeDefCount(exchangeDefById);
    const previousSectionExchangeDefCount = getSizeRelevantExchangeDefCount(exchangeDefs);
    const newSectionExchangeDefCount = getSizeRelevantExchangeDefCount(lineItemExchangeDefs);
    const addedCount = newSectionExchangeDefCount - previousSectionExchangeDefCount;
    const factor = isSectionLinkedFromEvaluation(section, sectionById) ? 2 : 1;

    if (
      newSectionExchangeDefCount > previousSectionExchangeDefCount &&
      totalExchangeDefCount + (addedCount * factor) > maxExchangeDefCount
    ) {
      setModelSizeLimitMessages({
        heading: t('request.dialog.requestSizeLimit.heading'),
        title: t('request.dialog.requestSizeLimit.saveLineItemsSection.title'),
        warning: t('request.dialog.requestSizeLimit.saveLineItemsSection.warning'),
        body: t('request.dialog.requestSizeLimit.saveLineItemsSection.body', {
          count: totalExchangeDefCount + (addedCount * factor) - maxExchangeDefCount,
        }),
      });
      modelSizeLimitModal.open();
      setSubmitting(false);
    } else {
      // when there's no supplier price field, make sure that there's only
      // one currency so suppliers don't have to choose a currency in
      // this case
      const adjustedCurrencyExchangeDef = { ...currencyExchangeDef };
      if (
        adjustedCurrencyExchangeDef.currencies &&
        (
          isEmpty(lineItemExchangeDefs) ||
          !hasSupplierPriceField(lineItemExchangeDefs[0].fields)
        )
      ) {
        adjustedCurrencyExchangeDef.currencies = adjustedCurrencyExchangeDef.currencies.slice(0, 1);
      }

      if (adjustedCurrencyExchangeDef.currencies?.length === 1) {
        adjustedCurrencyExchangeDef.isFixed = true;
      } else {
        adjustedCurrencyExchangeDef.isFixed = false;
      }

      return saveSection({
        section,
        exchangeDefs: [
          adjustedCurrencyExchangeDef,
          // Make sure the exchange definitions have the same stage visibility as the section
          ...map(
            lineItemExchangeDefs,
            exchangeDef => set(exchangeDef, 'stages', section.stages),
          ),
        ],
        extraChanges: getOtherSectionFieldLabelChanges(section._id, lineItemExchangeDefs[0]?.fields),
      }, {
        onSuccess: callAll(
          stopEditing,
          () => trackMultiStageLineItemsEnabled(section),
        ),
      });
    }
  };

  const formRef = useRef<FormikProps<any>>(null);
  useImperativeHandle(ref, () => {
    return {
      formRef,
      lineItemExchangeDefs,
    };
  }, [formRef, lineItemExchangeDefs]);

  return (
    <>
      <Flex
        flexDirection="column"
        sx={isExpandedView ? ({
          padding: '20px',
          inset: 0,
          zIndex: 150,
          position: 'fixed',
          backgroundColor: 'lightGray3',
        }) : ({
          height: '100%',
        })}
      >
        <Formik<{
          section: LineItemsSection;
          currencyExchangeDef: CurrencyExchangeDefinition;
          exchangeDefs: LineItemExchangeDefinition[];
        }>
          innerRef={formRef}
          validateOnBlur
          enableReinitialize={!isEnableMultiStageResponsesFlow}
          initialValues={{
            section,
            currencyExchangeDef,
            exchangeDefs: initialLineItemExchangeDefs,
          }}
          validationSchema={
            yup.object().shape({
              section: yup.object().shape({
                name: yup.string(),
                description: yup.string(),
                responseTagConfig: yup.object().shape({
                  tags: yup.array().min(2, t('request.multiStageBehavior.minimumTwoStagesRequired')),
                }).nullable(),
              }),
            })
          }
          onSubmit={handleSubmit}
        >
          {({ values, submitCount, setFieldValue }) => {
            const isSectionObsolete = values.section.isObsolete;
            const wasFormSubmitted = submitCount > 0;

            return (
              <Form style={{ width: '100%' }} onKeyDown={onKeyDown}>
                {/*
                 // @ts-expect-error ts(2322) FIXME: Type 'string | null | undefined' is not assignable to type 'string'. */}
                <CurrencyCodeProvider code={lineItemExchangeDefs[0]?.evaluatorFieldCurrency}>
                  <DraftPanel panelId={section._id} suppressBoxShadow={isExpandedView}>
                    {!isExpandedView && (
                      <>
                        <DraftSectionEditPanelHeader icon="list-ul" isSectionObsolete={isSectionObsolete} />
                        <PanelDivider />
                        {isSectionObsolete && (
                          <>
                            <MessageBlock variant="info" m="20px">
                              {t('request.sections.obsoleteSectionInfo')}
                            </MessageBlock>
                            <PanelDivider />
                          </>
                        )}
                        <EditSectionConfig isEnableMultiStageResponsesFlow={isEnableMultiStageResponsesFlow} />
                        <PanelDivider />
                      </>
                    )}
                    {!isSectionObsolete && (
                      <>
                        <PanelSubHeader height="52px">
                          <ExpandViewButton
                            isExpandedView={isExpandedView}
                            setIsExpandedView={setIsExpandedView}
                            shrinkedVariant="secondary-transparent-outline"
                            type="button"
                            mr={3}
                          />
                          <MoreActionsLineItemDropdown
                            modelExchangeDef={lineItemExchangeDefs[0]}
                            isUploadDisabled={isBeyondFirstResponseStage}
                          />
                          {systemFeatureFlags?.lineItemFieldsEnabled ? (
                            <AddLineItemFieldDropdown
                              otherSectionFields={otherSectionFields}
                              disabled={areAnyExchangeDefsLinkedToActiveAuction || isBeyondFirstResponseStage}
                            />
                          ) : (
                            null
                          )}
                          <AddBuyerProvidedLineItemButton
                            modelExchangeDef={lineItemExchangeDefs[0]}
                            ml={2}
                            disabled={isBeyondFirstResponseStage}
                          />
                        </PanelSubHeader>
                        <PanelDivider />
                      </>
                    )}
                    <PanelPadding>
                      <FormikLineItemExchangeDefsGrid
                        viewportHeightDelta={400}
                        height={isExpandedView ? 'calc(100vh - 216px)' : undefined}
                        isEditingSupplierExchangeDefs={isEditingSupplierExchangeDefs}
                        otherSectionFields={otherSectionFields}
                        isSender={isSender}
                        isExchangeDefDisabled={isExchangeDefLinkedToActiveAuction}
                        areAnyExchangeDefsLinkedToAuction={areAnyExchangeDefsLinkedToAuction}
                        areAnyExchangeDefsLinkedToActiveAuction={areAnyExchangeDefsLinkedToActiveAuction}
                        isBeyondFirstResponseStage={isBeyondFirstResponseStage}
                        isReadOnly={isSectionObsolete}
                        showEmptyGridValidationError={isEnableMultiStageResponsesFlow && wasFormSubmitted}
                      />
                    </PanelPadding>
                    <PanelDivider />
                    {!isEnableMultiStageResponsesFlow && <EditPanelFooter />}
                    {showDeleteButton && onSectionDeleted && <DeleteSectionButton onDeleteSuccess={onSectionDeleted} />}
                    <LeavePageModal />
                  </DraftPanel>
                  {isEnableMultiStageResponsesFlow && (
                    <EnableMultiStageReponsesFlowErrorsWrapper
                      onEnableMultiStageResponsesErrorsChange={onEnableMultiStageResponsesFlowErrorsChange}
                    />
                  )}
                </CurrencyCodeProvider>
              </Form>
            );
          }}
        </Formik>
      </Flex>
      <ModelSizeLimitDialog
        modal={modelSizeLimitModal}
        messages={modelSizeLimitMessages}
      />
    </>
  );
});

export type LineItemsSectionEditPanelRef = {
  formRef: React.RefObject<FormikProps<any>>;
  lineItemExchangeDefs: LineItemExchangeDefinition[];
};

type LineItemsSectionEditPanelProps = {
  isEnableMultiStageResponsesFlow?: boolean;
  onEnableMultiStageResponsesErrorsChange?: (errors: EnableMultiStageResponsesSectionErrors) => void;
  showDeleteButton?: boolean;
  onSectionDeleted?: (sectionId: string) => void;
  panelRef?: React.ForwardedRef<LineItemsSectionEditPanelRef>;
};

export const LineItemsSectionEditPanel = ({
  panelRef,
  ...props
}: LineItemsSectionEditPanelProps) => {
  const exchangeDefs = rfx.useSectionExchangeDefs();
  const lineItemExchangeDefs = useMemo(
    () => filter(exchangeDefs, isLineItemExchangeDef),
    [exchangeDefs],
  );

  return (
    <EditableGridDataProvider
      rowData={lineItemExchangeDefs}
      setValueInRow={setExchangeDefFieldValue}
    >
      <GlobalDatePickerStyles />
      <GridIdPrefixProvider>
        <GridMenuStateProvider>
          <LineItemsSectionEditPanelContent {...props} ref={panelRef} />
        </GridMenuStateProvider>
      </GridIdPrefixProvider>
    </EditableGridDataProvider>
  );
};
