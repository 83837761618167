// Auto-generated file created by svgr-cli source svgr-config/indexTemplate.js
// Do not edit
import DraftRequest from './DraftRequest';
import EmptyState from './EmptyState';
import Locked from './Locked';
import SearchResults from './SearchResults';

export default {
  'draft-request': DraftRequest,
  'empty-state': EmptyState,
  locked: Locked,
  'search-results': SearchResults,
};
