import { useCallback } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';
import { omitBy } from 'lodash';
import { Pictogram } from '@deepstream/ui-kit';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { FlexPanelHeader, Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { createSolidButton } from '../../ClientThemeProvider';
import { SuppliersList } from './SuppliersList';
import { useApi, wrap } from '../../api';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { Pagination } from '../../Pagination';
import { preQualificationSuppliersRoute } from '../../AppRouting';
import { LoadingPanel } from '../../ui/Loading';
import { ErrorPanel } from '../../ui/ErrorMessage';
import { SearchForm } from '../../SearchForm';
import { SEARCH_INPUT_HEIGHT } from '../../ui/SearchInputCombobox';
import { EXPANDABLE_SUPPLIER_ROW_HEIGHT } from './utils';

export const PreQualificationSuppliersPage = () => {
  const { t } = useTranslation(['preQualification', 'translation']);
  const theme = useTheme();
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId();
  const navigate = useNavigate();
  const search = preQualificationSuppliersRoute.useSearch();
  const { text, pageIndex = 0, pageSize = 0 } = search;

  const { data, isLoading, isError } = useQuery(
    ['preQualSuppliers', { currentCompanyId, searchState: { pageSize, pageIndex, text } }],
    wrap(api.getPreQualSuppliers),
  );

  const {
    suppliers,
    totalItems = 0,
    pageCount = 0,
  } = data ?? {};

  const updateSearch = useCallback(
    (newSearch: Partial<typeof search>) => {
      navigate({
        // Drop empty strings from the search params so that the URL is cleaner (i.e. clearing search text)
        search: omitBy(
          { ...search, ...newSearch },
          value => value === '',
        ),
      });
    },
    [navigate, search],
  );

  const setPageIndex = useCallback((pageIndex: number) => {
    updateSearch({ pageIndex });
  }, [updateSearch]);

  const nextPage = useCallback(() => {
    if (typeof search.pageIndex !== 'undefined') {
      updateSearch({ pageIndex: search.pageIndex + 1 });
    }
  }, [search.pageIndex, updateSearch]);

  const previousPage = useCallback(() => {
    if (typeof search.pageIndex !== 'undefined') {
      updateSearch({ pageIndex: search.pageIndex - 1 });
    }
  }, [search.pageIndex, updateSearch]);

  const setPageSize = useCallback((pageSize: number) => {
    updateSearch({ pageSize, pageIndex: 0 });
  }, [updateSearch]);

  const updateText = useCallback((text: string) => {
    updateSearch({ text, pageIndex: 0 });
  }, [updateSearch]);

  if (isLoading) {
    return <LoadingPanel />;
  }

  if (isError) {
    return <ErrorPanel error={t('errors.unexpected', { ns: 'translation' })} />;
  }

  return (
    <Panel>
      <FlexPanelHeader
        heading={t('questionnaireSuppliers.supplierCount', { count: totalItems })}
        sx={{ height: '60px', alignItems: 'center' }}
      >
        <Box width="276px">
          <SearchForm
            placeholder={t('requests.searching.searchByName', { ns: 'translation' })}
            inputStyle={{
              minHeight: SEARCH_INPUT_HEIGHT,
              maxHeight: SEARCH_INPUT_HEIGHT,
              fontSize: `${theme.fontSizes[1]}px`,
              lineHeight: 1,
              border: theme.borders.lightGray2,
              borderRight: 'none',
            }}
            buttonProps={{
              width: SEARCH_INPUT_HEIGHT,
              height: SEARCH_INPUT_HEIGHT,
              variant: 'wrapper',
              color: 'lightGray5',
              sx: {
                ...createSolidButton(theme.colors.lightGray3),
                border: 'lightGray2',
              },
            }}
            text={text}
            onSubmit={updateText}
            disabled={isLoading}
          />
        </Box>
      </FlexPanelHeader>
      <PanelDivider />
      {totalItems > 0 ? (
        <>
          {/*
           // @ts-expect-error ts(2322) FIXME: Type 'PreQualSupplier[] | undefined' is not assignable to type 'PreQualSupplier[]'. */}
          <SuppliersList suppliers={suppliers} minHeight={EXPANDABLE_SUPPLIER_ROW_HEIGHT * pageSize} />
          <Pagination
            small
            pageIndex={pageIndex}
            pageSize={pageSize}
            canPreviousPage={pageIndex > 0}
            canNextPage={pageIndex < pageCount - 1}
            pageCount={pageCount}
            gotoPage={setPageIndex}
            nextPage={nextPage}
            previousPage={previousPage}
            setPageSize={setPageSize}
          />
        </>
      ) : (
        <PanelPadding height="350px">
          {text ? (
            <Pictogram
              variant="empty-search-companies-state"
              label={t('questionnaireSuppliers.noSuppliersFound')}
              labelPosition="right"
            />
          ) : (
            <Pictogram
              variant="empty-state"
              label={t('questionnaireSuppliers.noSuppliers')}
              labelPosition="right"
            />
          )}
        </PanelPadding>
      )}
    </Panel>
  );
};
