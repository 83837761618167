import { ContractHistoryEvent, START_DATE_MILESTONE, EXPIRY_DATE_MILESTONE, ReminderType } from '@deepstream/common/contract';
import { omit, orderBy, pick, toPairs, values, map, replace, lowerFirst, omitBy, isNil, isEmpty } from 'lodash';
import { useMemo, useState, useCallback, Fragment } from 'react';
import { DateFormat, localeFormatDate, localeFormatNumber } from '@deepstream/utils';
import { getTimeUnitTranslationKey } from '@deepstream/common/contract/contractDates';
import { Trans, useTranslation } from 'react-i18next';
import { Text, Flex, Box, TextProps } from 'rebass/styled-components';

import { truncateStyle } from '@deepstream/ui-kit/elements/text/Truncate2';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Button, DownloadCsvButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { BLANK, UNKNOWN } from '@deepstream/common/constants';
import { Bold } from '../../../Bold';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { Datetime2 } from '../../../Datetime';
import { Table } from '../../../Table';
import { BasicTableStyles } from '../../../TableStyles';
import { ErrorMessage } from '../../../ui/ErrorMessage';
import { Loading } from '../../../ui/Loading';
import { useCurrentUser, useCurrentUserLocale } from '../../../useCurrentUser';
import { ContractProvider, useContractData, useContractPermissions } from '../contract';
import { useContract, useContractAudit } from '../useContract';
import useDownload from '../../../useDownload';

const fieldsToTranslate = ['action', 'exchangeType', 'previousAction'];

const shouldTranslate = (fieldKey: string) => {
  return fieldsToTranslate.includes(fieldKey);
};

const isAuctionLineItemKey = (fieldKey: string) => {
  return !!fieldKey.match(/lineItem\d+\w+/g)?.length;
};

const getTKey = (fieldKey: string) => {
  return isAuctionLineItemKey(fieldKey) ? fieldKey.split(/\d+/).join('') : null;
};

const FieldLabel = (props: TextProps) => (
  <Text fontSize={1} color="subtext" mr={1} {...props} sx={{ 'flex': '0 0 auto' }} />
);

const DateField = ({
  fieldValue,
  ...rest
}: {
  fieldValue: string;
} & TextProps) => {
  const locale = useCurrentUserLocale();
  return (
    <Text fontSize={1} mr={2} {...rest}>
      {localeFormatDate(new Date(fieldValue), DateFormat.DD_MMM_YYYY, { locale })}
    </Text>
  );
};

const FieldValue = ({
  value,
  fieldKey,
  fields,
  groupPath,
  ...rest
}: {
  fieldKey: string;
  value: any;
  fields: Record<string, any>;
  groupPath?: string;
} & TextProps) => {
  const { t } = useTranslation(['translation', 'contracts', 'general']);
  const locale = useCurrentUserLocale();
  const tKey = getTKey(fieldKey);

  if (value === BLANK) {
    return (
      <Text fontSize={1} mr={2} {...rest}>
        [{t('audit.blank', { ns: 'contracts' })}]
      </Text>
    );
  }

  if (value === UNKNOWN) {
    return (
      <Text fontSize={1} mr={2} {...rest}>
        [{t('audit.unknown', { ns: 'contracts' })}]
      </Text>
    );
  }

  if (shouldTranslate(fieldKey)) {
    return (
      <Text fontSize={1} mr={2} {...rest}>
        {t(`audit.types.${value.toString()}`, { ns: 'contracts' })}
      </Text>
    );
  }

  // Specific fields
  if (tKey === 'lineItemPrice') {
    return (
      <Text fontSize={1} mr={2} {...rest}>
        {localeFormatNumber(value.price, { locale, decimalPlaces: value.decimalPlaces ?? 2 })}
      </Text>
    );
  }

  if (fieldKey === 'recipientCategories') {
    return (
      <Text fontSize={1} mr={2} {...rest}>
        {
          map(value, (recipientCategory) => {
            return t(`summary.recipientCategory.${recipientCategory}`, { ns: 'contracts' });
          }).join(', ')
        }
      </Text>
    );
  }

  if (fieldKey === 'occurrence') {
    // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
    if (fields?.reminderType === ReminderType.EXACT_DATE || fields?.[groupPath]?.reminderType === ReminderType.EXACT_DATE) {
      const { isRecurring, date, amount, unit } = value;
      let repeatsInterval;

      if (isRecurring) {
        repeatsInterval = unit === 'months'
          ? 'monthCount'
          : unit === 'days'
            ? 'dayCount'
            : 'yearCount';
      }

      return (
        <Text fontSize={1} mr={2} {...rest}>
          <Datetime2 format={DateFormat.DD_MMM_YYYY_HH_MM_A} value={date} />
          <Box as="span" ml={1}>
            {
              isRecurring ? (
                <>
                  {t('audit.fieldValue.reminderRepeatsEvery', { ns: 'contracts' })}
                  {' '}
                  {t(`general.${repeatsInterval}`, { count: amount })}
                </>
              ) : (
                <>
                  {t('audit.fieldValue.reminderDoesNotRepeat', { ns: 'contracts' })}
                </>
              )
            }
          </Box>
        </Text>
      );
    }

    // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
    if (fields?.reminderType === ReminderType.MILESTONE || fields?.[groupPath]?.reminderType === ReminderType.MILESTONE) {
      const { offsetType, unit, amount, milestoneName, milestoneDate, milestoneId } = value;
      const offsetInterval = unit === 'months'
        ? 'monthCount'
        : unit === 'days'
          ? 'dayCount'
          : 'yearCount';

      const name = milestoneName || t(`audit.fieldValue.milestone.${milestoneId}`, { ns: 'contracts' });
      const date = localeFormatDate(new Date(milestoneDate), DateFormat.DD_MMM_YYYY, { locale });

      return (
        <Text fontSize={1} mr={2} {...rest}>
          {typeof amount === 'number' && (
            <>
              {t(`general.${offsetInterval}`, { count: amount })}
              &nbsp;
            </>
          )}
          {t(`audit.fieldValue.offsetType.${offsetType}`, { ns: 'contracts' })}
          &nbsp;
          <strong>{name}</strong> <em>[{t('audit.fieldValue.milestone.milestoneDate', { ns: 'contracts', date })}]</em>
        </Text>
      );
    }
  }

  if (fieldKey === 'recipientUserNames') {
    return (
      <Text fontSize={1} mr={2} {...rest}>
        {value.length
          ? value.join(', ')
          : t('audit.fieldValue.internalRecipientsNone', { ns: 'contracts' })
        }
      </Text>
    );
  }

  if (fieldKey === 'milestoneName') {
    const milestoneName = [START_DATE_MILESTONE, EXPIRY_DATE_MILESTONE].includes(value)
      ? t(`audit.fieldValue.milestone.${value}`, { ns: 'contracts' })
      : value;

    return (
      <Text fontSize={1} mr={2} {...rest}>
        {milestoneName}
      </Text>
    );
  }

  if (fieldKey === 'reminderType') {
    return (
      <Text fontSize={1} mr={2} {...rest}>
        {t(`audit.fieldValue.reminderType.${value}`, { ns: 'contracts' })}
      </Text>
    );
  }

  if (fieldKey === 'status' || fieldKey === 'previousStatus') {
    return (
      <Text fontSize={1} mr={2} {...rest}>
        {t(`status.${value}`, { ns: 'contracts' })}
      </Text>
    );
  }

  if (fieldKey === 'startDate' || fieldKey === 'expiryDate') {
    return (
      <DateField fieldValue={value} {...rest} />
    );
  }

  if (fieldKey === 'startDateOffsetConfig') {
    const startDateOffsetKey = getTimeUnitTranslationKey(value.unit);
    return (
      <Text fontSize={1} mr={2} {...rest}>
        {/*
         // @ts-expect-error ts(2769) FIXME: No overload matches this call. */}
        {`${t(startDateOffsetKey, { count: value.amount, ns: 'general' })} ${t('summary.afterSignature', { ns: 'contracts' })}`}
      </Text>
    );
  }

  if (fieldKey === 'expiryDateOffsetConfig') {
    const expiryDateOffsetKey = getTimeUnitTranslationKey(value.unit);
    return (
      <Text fontSize={1} mr={2} {...rest}>
        {/*
         // @ts-expect-error ts(2769) FIXME: No overload matches this call. */}
        {`${t(expiryDateOffsetKey, { count: value.amount, ns: 'general' })} ${t('summary.afterStartDate', { ns: 'contracts' })}`}
      </Text>
    );
  }

  if (fieldKey === 'startDateType' || fieldKey === 'expiryDateType') {
    return (
      <Text fontSize={1} mr={2} {...rest}>
        {t(`summary.${fieldKey}.${value}`, { ns: 'contracts' })}
      </Text>
    );
  }

  // Line item date fields
  // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
  if (fields?.[groupPath]?.[fieldKey]?.type === 'date') {
    return (
      <DateField fieldValue={value} {...rest} />
    );
  }

  // Line item price fields
  // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
  if (fields?.[groupPath]?.[fieldKey]?.type === 'price') {
    return (
      <Text fontSize={1} mr={2} {...rest}>
        {localeFormatNumber(
          value,
          {
            locale,
            // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
            decimalPlaces: fields[groupPath][fieldKey].decimalPlaces ?? 2,
          },
        )}
      </Text>
    );
  }

  if (typeof value === 'boolean') {
    return (
      <Text fontSize={1} mr={2} {...rest}>
        {value ? t('general.yes') : t('general.no')}
      </Text>
    );
  }

  if (typeof value === 'object' && value !== null) {
    return (
      <Text fontSize={1} mr={2} {...rest}>
        {values(value).join(', ')}
      </Text>
    );
  }

  return (
    <Text fontSize={1} mr={2} {...rest}>
      {value}
    </Text>
  );
};

const CustomRowCells = ({
  row,
}) => {
  const { t } = useTranslation(['contracts', 'general', 'translation']);

  const {
    meta,
    fields,
    type,
    hiddenFields = [],
  } = row.original as ContractHistoryEvent & { fields: Record<string, any> | undefined };

  const {
    fieldsPairs,
    groupedFields,
  } = useMemo(() => {
    const keysToExpand = ['previousFieldResponse', 'updatedFieldResponse', 'updatedReminderFields', 'previousReminderFields'];

    const fieldsPairs = toPairs(omit(fields, [...hiddenFields, ...keysToExpand])) as [string, any][];
    const groupedFields = toPairs(omit(pick(fields ?? {}, keysToExpand), hiddenFields));

    return {
      fieldsPairs,
      groupedFields,
    };
  }, [fields, hiddenFields]);

  return (
    <td style={{ height: 'auto' }}>
      <Box py="12px" height={!row.isExpanded ? '82px' : ''}>
        <Flex justifyContent="space-between">
          <Bold>
            {type !== 'cannot-view' ? (
              t(`audit.eventLabel.${type}`)
            ) : (
              <IconText
                icon="eye-slash"
                text={t(`audit.eventLabel.${type}`)}
                gap={1}
                color="subtext"
                iconFontWeight="normal"
              />
            )}
          </Bold>
          <Text fontSize={1} color={type !== 'cannot-view' ? 'text' : 'subtext'}>
            <Datetime2 value={meta.timestamp} format={DateFormat.DD_MMM_YYYY_HH_MM_A} />
          </Text>
        </Flex>
        {type !== 'cannot-view' ? (
          <>
            <Box mt={1} fontSize={1}>
              {/*
               // @ts-expect-error ts(18048) FIXME: 'meta.user' is possibly 'undefined'. */}
              {meta.user.name ? (
                <>
                  {/*
                   // @ts-expect-error ts(18048) FIXME: 'meta.user' is possibly 'undefined'. */}
                  <Trans i18nKey="audit.byUserName" values={{ userName: meta.user.name }} ns="contracts">
                    {/*
                     // @ts-expect-error ts(18048) FIXME: 'meta.user' is possibly 'undefined'. */}
                    by <Bold>{meta.user.name}</Bold>
                  </Trans>
                  {' '}
                </>
              ) : null}
              {/*
               // @ts-expect-error ts(18048) FIXME: 'meta.company' is possibly 'undefined'. */}
              {meta.company.name ? (
                // @ts-expect-error ts(18048) FIXME: 'meta.company' is possibly 'undefined'.
                <Trans i18nKey="audit.atCompanyName" values={{ companyName: meta.company.name }} ns="contracts">
                  {/*
                   // @ts-expect-error ts(18048) FIXME: 'meta.company' is possibly 'undefined'. */}
                  at <Bold>{meta.company.name}</Bold>
                </Trans>
              ) : null}
              {meta.role ? (
                <>
                  {' '}
                  {t('request.audit.asRole', { ns: 'translation' })}
                  {' '}
                  <Bold>{t(`audit.role.${meta.role}`)}</Bold>
                </>
              ) : null}
            </Box>
            <Flex alignItems="flex-end">
              {row.isExpanded ? (
                <Box flex={1} mt="3px" mr={3}>
                  {fieldsPairs.map(([key, value]) => {
                    // @ts-expect-error ts(2531) FIXME: Object is possibly 'null'.
                    const index = isAuctionLineItemKey(key) ? key.match(/\d/g).join('') : null;
                    const tKey = getTKey(key);

                    return (
                      <Flex key={key} alignItems="flex-start" mt={1} mb={0}>
                        <FieldLabel width="180px" mr="12px">
                          {isAuctionLineItemKey(key) ? (
                            t(`audit.fieldLabel.${tKey}`, { index })
                          ) : key.startsWith('previous') ? (
                            t('audit.previousLabel', {
                              value: t(`audit.fieldLabel.${lowerFirst(replace(key, 'previous', ''))}`),
                              ns: 'contracts',
                            })
                          // @ts-expect-error ts(18048) FIXME: 'fields' is possibly 'undefined'.
                          ) : key === 'description' && fields.descriptionLabel ? (
                            // @ts-expect-error ts(18048) FIXME: 'fields' is possibly 'undefined'.
                            `${fields.descriptionLabel} (${t(`request.audit.fieldLabel.${key}`)})`
                          ) : (
                            t(`audit.fieldLabel.${key}`)
                          )}
                        </FieldLabel>

                        <FieldValue
                          width="350px"
                          sx={{ whiteSpace: 'pre-wrap' }}
                          fieldKey={key}
                          // @ts-expect-error ts(2322) FIXME: Type 'Record<string, any> | ({ userName: string; userId: string; counterpartyName: string; counterpartyId: string; } & Record<string, any>) | ({ message: string; } & Record<string, any>) | ... 10 more ... | undefined' is not assignable to type 'Record<string, any>'.
                          fields={fields}
                          value={value}
                        />
                      </Flex>
                    );
                  })}
                  {groupedFields.map(([key, value]) => {
                    // @ts-expect-error ts(2345) FIXME: Argument of type 'unknown' is not assignable to parameter of type 'ArrayLike<any> | { [s: string]: any; }'.
                    const fieldEntries = Object.entries<any>(value);

                    return fieldEntries.map(([fieldId, fieldValue], index) => {
                      let label;
                      let value;

                      if (['previousFieldResponse', 'updatedFieldResponse'].includes(key)) {
                        label = fieldValue.label || t(`request.fields.predefinedFieldLabel.${fieldId.split(':')[0]}`, { ns: 'translation' });
                        value = fieldValue.value;
                      } else {
                        label = t(`audit.fieldLabel.${fieldId}`, { ns: 'contracts' });
                        value = fieldValue;
                      }

                      return (
                        <Flex key={`${key}${index}`} alignItems="flex-start" mt={1} mb={0}>
                          <FieldLabel width="180px" mr="12px">
                            {key.startsWith('previous') ? (
                              t('audit.previousLabel', { value: label })
                            ) : key.startsWith('updated') ? (
                              t('audit.updatedLabel', { value: label })
                            ) : (
                              label
                            )}
                          </FieldLabel>
                          <FieldValue
                            width="300px"
                            sx={{ whiteSpace: 'pre-wrap' }}
                            fieldKey={fieldId}
                            // @ts-expect-error ts(2322) FIXME: Type 'Record<string, any> | ({ userName: string; userId: string; counterpartyName: string; counterpartyId: string; } & Record<string, any>) | ({ message: string; } & Record<string, any>) | ... 10 more ... | undefined' is not assignable to type 'Record<string, any>'.
                            fields={fields}
                            value={value}
                            groupPath={key}
                          />
                        </Flex>
                      );
                    });
                  })}
                </Box>
              ) : (
                <Box flex={1} mr={3} style={truncateStyle}>
                  {fieldsPairs.map(([key, value]) => {
                    // @ts-expect-error ts(2531) FIXME: Object is possibly 'null'.
                    const index = isAuctionLineItemKey(key) ? key.match(/\d/g).join('') : null;
                    const tKey = getTKey(key);

                    return (
                      <Fragment key={key}>
                        <FieldLabel as="span">
                          {isAuctionLineItemKey(key) ? (
                            t(`audit.fieldLabel.${tKey}`, { index })
                          ) : key.startsWith('previous') ? (
                            t('audit.previousLabel', {
                              value: t(`audit.fieldLabel.${lowerFirst(replace(key, 'previous', ''))}`),
                              ns: 'contracts',
                            })
                          // @ts-expect-error ts(18048) FIXME: 'fields' is possibly 'undefined'.
                          ) : key === 'description' && fields.descriptionLabel ? (
                            // @ts-expect-error ts(18048) FIXME: 'fields' is possibly 'undefined'.
                            `${fields.descriptionLabel} (${t(`request.audit.fieldLabel.${key}`)})`
                          ) : (
                            t(`audit.fieldLabel.${key}`)
                          )}
                        </FieldLabel>
                        <FieldValue
                          as="span"
                          fieldKey={key}
                          // @ts-expect-error ts(2322) FIXME: Type 'Record<string, any> | ({ userName: string; userId: string; counterpartyName: string; counterpartyId: string; } & Record<string, any>) | ({ message: string; } & Record<string, any>) | ... 10 more ... | undefined' is not assignable to type 'Record<string, any>'.
                          fields={fields}
                          value={value}
                        />
                      </Fragment>
                    );
                  })}
                  {groupedFields.map(([key, value]) => {
                    // @ts-expect-error ts(2345) FIXME: Argument of type 'unknown' is not assignable to parameter of type 'ArrayLike<any> | { [s: string]: any; }'.
                    const fieldEntries = Object.entries<any>(value);

                    return fieldEntries.map(([fieldId, fieldValue], index) => {
                      let label;
                      let value;

                      if (['previousFieldResponse', 'updatedFieldResponse'].includes(key)) {
                        label = fieldValue.label || t(`request.fields.predefinedFieldLabel.${fieldId.split(':')[0]}`, { ns: 'translation' });
                        value = fieldValue.value;
                      } else {
                        label = t(`audit.fieldLabel.${fieldId}`, { ns: 'contracts' });
                        value = fieldValue;
                      }

                      return (
                        <Fragment key={`${key}${index}`}>
                          <FieldLabel as="span">
                            {key.startsWith('previous') ? (
                              t('audit.previousLabel', { value: label })
                            ) : key.startsWith('updated') ? (
                              t('audit.updatedLabel', { value: label })
                            ) : (
                              label
                            )}
                          </FieldLabel>
                          <FieldValue
                            as="span"
                            fieldKey={fieldId}
                            // @ts-expect-error ts(2322) FIXME: Type 'Record<string, any> | ({ userName: string; userId: string; counterpartyName: string; counterpartyId: string; } & Record<string, any>) | ({ message: string; } & Record<string, any>) | ... 10 more ... | undefined' is not assignable to type 'Record<string, any>'.
                            fields={fields}
                            value={value}
                            groupPath={key}
                          />
                        </Fragment>
                      );
                    });
                  })}
                </Box>
              )}
              {(!isEmpty(fieldsPairs) || !isEmpty(groupedFields)) && (
                <Box flex="0 0 auto" mt="-6px">
                  <Button
                    small
                    variant="secondary-outline"
                    iconLeft={row.isExpanded ? 'chevron-up' : 'chevron-down'}
                    onClick={() => row.toggleRowExpanded()}
                  />
                </Box>
              )}
            </Flex>
          </>
        ) : (
          <Box mt={1}>
            <Text fontSize={1} color="subtext">
              {t('audit.pagePermissionPlaceholder')}
            </Text>
          </Box>
        )}
      </Box>
    </td>
  );
};

const AuditTable = ({ audit }: { audit: ContractHistoryEvent[] }) => {
  const sortedAudit = useMemo(
    () => orderBy(audit, 'meta.timestamp', 'desc'),
    [audit],
  );

  const columns = useMemo(
    () => [{ id: 'custom' }],
    [],
  );

  return (
    <BasicTableStyles hoverBackgroundColor="lightGray6" hoverCursor="default">
      <Table
        columns={columns}
        data={sortedAudit}
        isPaginated
        initialPageSize={10}
        hideHeader
        CustomRowCells={CustomRowCells}
        hasStaticHeight={false}
        enforcePagination
        minCellHeight={58}
        smallPageControls
      />
    </BasicTableStyles>
  );
};

const AuditPanel = ({ audit }: { audit: ContractHistoryEvent[] }) => {
  const { t } = useTranslation('general');
  const { canDownloadReports } = useContractPermissions();
  const [isLoading, setIsLoading] = useState(false);
  const download = useDownload();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();
  const locale = useCurrentUserLocale();
  const { _id: contractId } = useContractData();

  const downloadAudit = useCallback(
    async () => {
      const queryParams = new URLSearchParams(omitBy(
        {
          csvSeparator: user.preferences?.csvSeparator,
          locale,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        } as Record<string, string>,
        isNil,
      ));

      setIsLoading(true);

      try {
        await download(`/download/company/${currentCompanyId}/contract/${contractId}/audit?${queryParams.toString()}`);
      } catch (error) {} // eslint-disable-line no-empty

      setIsLoading(false);
    },
    [currentCompanyId, user, contractId, download, locale],
  );

  return (
    <Panel mb="40px">
      <Flex alignItems="center" justifyContent="space-between" px="20px" py={3} height="60px">
        <Text fontSize={2} fontWeight={500} mb="1px">
          {t('eventCount', { count: audit.length })}
        </Text>
        {canDownloadReports && (
          <DownloadCsvButton
            small
            disabled={isLoading}
            onClick={downloadAudit}
          />
        )}
      </Flex>
      <PanelDivider />
      <AuditTable audit={audit} />
    </Panel>
  );
};

export const ContractAudit = ({
  contractId,
}: {
  contractId: string;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const {
    data: contract,
    isLoading: isLoadingContract,
    isError: isErrorContract,
    isSuccess: isSuccessContract,
  } = useContract({
    contractId,
    currentCompanyId,
    scope: 'current',
  });

  const {
    data: audit,
    isLoading: isLoadingAudit,
    isError: isErrorAudit,
    isSuccess: isSuccessAudit,
  } = useContractAudit({ contractId, currentCompanyId });

  return (
    <>
      {isLoadingContract || isLoadingAudit ? (
        <PanelPadding>
          <Loading />
        </PanelPadding>
      ) : isErrorContract || isErrorAudit ? (
        <PanelPadding>
          <ErrorMessage error={t('errors.unexpected')} />
        </PanelPadding>
      ) : isSuccessContract && isSuccessAudit && contract && audit ? (
        <ContractProvider contract={contract}>
          <AuditPanel audit={audit} />
        </ContractProvider>
      ) : (
        null
      )}
    </>
  );
};
