import { Company } from '@deepstream/common/rfq-utils';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { CancelButton, Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { useApi } from '../../../api';
import { useToaster } from '../../../toast';
import { DescriptionItem, DescriptionList } from '../../../ui/DescriptionList';
import { useMutation } from '../../../useMutation';
import { useLiveRfqStructureQueryKey, useRfqId } from '../../../useRfq';
import { useCurrentCompanyId } from '../../../currentCompanyId';

type InviteRemindersModalProps = {
  selectedInvitedRecipients: Company[];
  onClose: () => void;
} & ModalProps;

export const InviteRemindersModal: React.FC<InviteRemindersModalProps> = ({
  onClose,
  selectedInvitedRecipients,
  ...props
}) => {
  const { t } = useTranslation();
  const api = useApi();
  const toaster = useToaster();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqId = useRfqId({ required: true });
  const queryClient = useQueryClient();
  const liveStructureQueryKey = useLiveRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
  });
  const hasRecipients = selectedInvitedRecipients.length > 0;

  const [sendInviteReminders, { isLoading }] = useMutation(
    api.sendInviteReminders,
    {
      onSuccess: () => {
        toaster.success(t('invite.reminders.notification.success'));
      },
      onError: () => {
        toaster.error(t('invite.reminders.notification.failed'));
      },
      onSettled: callAll(
        () => queryClient.invalidateQueries(liveStructureQueryKey),
        () => queryClient.invalidateQueries(['allExchanges', { rfqId, currentCompanyId }]),
        () => queryClient.invalidateQueries(['exchanges', { rfqId, currentCompanyId }]),
        () => queryClient.invalidateQueries(['statsByRecipientId', { rfqId, currentCompanyId }]),
        onClose,
      ),
    },
  );

  const sendReminders = async () => {
    await sendInviteReminders({
      rfqId,
      companyId: currentCompanyId,
      recipientIds: selectedInvitedRecipients.map((recipient) => recipient._id),
    });
  };

  return (
    <Modal
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      style={{ content: { maxWidth: '500px' } }}
      {...props}
    >
      <ModalHeader onClose={onClose}>
        {hasRecipients
          ? t('invite.reminders.header')
          : t('invite.reminders.noRecipientsHeader')
        }
      </ModalHeader>
      <ModalBody>
        {hasRecipients && (
          <>
            <DescriptionList>
              <DescriptionItem term={t('invite.invitedCompanies')}>
                <ul style={{ paddingLeft: 24, margin: 0 }}>
                  {selectedInvitedRecipients.map((recipient) => (
                    <li key={recipient._id}>
                      <Text>{recipient.company.name}</Text>
                    </li>
                  ))}
                </ul>
              </DescriptionItem>
            </DescriptionList>
            <MessageBlock variant="info" mt={3}>
              {t('invite.reminders.info')}
            </MessageBlock>
          </>
        )}
        {!selectedInvitedRecipients.length && (
          <MessageBlock variant="error" mt={0}>
            {t('invite.reminders.noInvitedCompanies')}
          </MessageBlock>
        )}
      </ModalBody>
      <ModalFooter>
        {hasRecipients ? (
          <>
            <CancelButton onClick={onClose} />
            <Button onClick={sendReminders} disabled={isLoading || !selectedInvitedRecipients.length}>{t('general.send')}</Button>
          </>
        ) : (
          <Button onClick={onClose}>{t('general.dismiss')}</Button>
        )}
      </ModalFooter>
    </Modal>
  );
};
