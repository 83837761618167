import { useMemo } from 'react';
import { isEmpty, constant, without } from 'lodash';

import { EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';

const getColumnTitles = (columns: EditableGridColumn[]) => {
  return columns.map(column => (
    column.description
      ? `${column.label} (${column.description})`
      : column.label
  ));
};

const adjustFieldMapping = ({
  fieldsMapping,
  internalColumnTitles,
  externalColumnTitles,
  isMatch,
}: {
  fieldsMapping: number[];
  internalColumnTitles: (string | null)[];
  externalColumnTitles: (string | null)[];
  isMatch: (internalColumnTitle: string, externalColumnTitle: string | null) => boolean;
}) => {
  const result = {
    fieldsMapping: [...fieldsMapping],
    internalColumnTitles: [...internalColumnTitles],
    externalColumnTitles: [...externalColumnTitles],
  };

  fieldsMapping.forEach((mappingValue, index) => {
    if (mappingValue === -1) {
      const internalColumnTitle = internalColumnTitles[index];

      if (internalColumnTitle !== null) {
        const newMappingValue = externalColumnTitles.findIndex(
          externalColumnTitle => isMatch(internalColumnTitle, externalColumnTitle),
        );

        if (newMappingValue !== -1) {
          result.fieldsMapping[index] = newMappingValue;
          result.internalColumnTitles[index] = null;
          result.externalColumnTitles[newMappingValue] = null;
        }
      }
    }
  });

  return result;
};

const trailingCurrencyCodeRegex = /\s*\[[A-Z]{3}\]$/;
const trailingDescriptionRegex = /\s*\([^)]+\)$/;

export const useBulkUploadAutoFieldsMapping = (
  columns: EditableGridColumn[],
  rawData: unknown[],
) => {
  return useMemo(() => {
    const internalColumnTitles = getColumnTitles(columns);

    const initialFieldsMapping = columns.map(constant(-1));

    if (!rawData[0]) {
      return initialFieldsMapping;
    }

    // compare exact strings
    const state0 = adjustFieldMapping({
      fieldsMapping: initialFieldsMapping,
      internalColumnTitles,
      externalColumnTitles: rawData[0] as string[],
      isMatch: (a, b) => a === b,
    });

    // compare without appended currencies, like "[USD]"
    const state1 = adjustFieldMapping({
      ...state0,
      externalColumnTitles: state0.externalColumnTitles.map(
        item => item === null ? item : item.replace(trailingCurrencyCodeRegex, ''),
      ),
      isMatch: (a, b) => a === b,
    });

    // compare without appended descriptions, like "(Buyer)"
    const state2 = adjustFieldMapping({
      ...state1,
      internalColumnTitles: state1.internalColumnTitles.map(
        item => item === null ? item : item.replace(trailingDescriptionRegex, ''),
      ),
      externalColumnTitles: state1.externalColumnTitles.map(
        item => item === null ? item : item.replace(trailingDescriptionRegex, ''),
      ),
      isMatch: (a, b) => a === b,
    });

    // check if internal column label is found anywhere in the full external column header
    const state3 = adjustFieldMapping({
      ...state2,
      externalColumnTitles: state2.externalColumnTitles.map(
        (item, index) => item === null ? item : (rawData[0] as string[])[index],
      ),
      // @ts-expect-error ts(2322) FIXME: Type 'boolean | undefined' is not assignable to type 'boolean'.
      isMatch: (a, b) => b?.includes(a),
    });

    const remainingExternalTitleIndices = without(
      state3.externalColumnTitles.map((title, index) => title === null ? null : index),
      null,
    );

    // map unmapped fields according to their position
    const result = state3.fieldsMapping.map(mappingValue => {
      if (mappingValue > -1 || isEmpty(remainingExternalTitleIndices)) {
        return mappingValue;
      }

      return remainingExternalTitleIndices.shift();
    });

    return result;
  }, [columns, rawData]);
};
