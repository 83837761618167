import { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { map, filter, some } from 'lodash';
import { useQuery } from 'react-query';
import { Formik, useField, useFormikContext } from 'formik';
import * as yup from 'yup';
import { Pictogram } from '@deepstream/ui-kit';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Modal, ModalHeader, ModalBody, ModalFooter, CancelButton, SaveButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { DropdownMenuItem, EllipsisMenu } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { useApi, wrap } from '../../../../api';
import { Loading } from '../../../../ui/Loading';
import { ErrorMessage } from '../../../../ui/ErrorMessage';
import { useCurrentCompanyId } from '../../../../currentCompanyId';
import { Bold } from '../../../../Bold';
import { useModalState } from '../../../../ui/useModalState';
import { TextFieldBase } from '../../../../form/TextField';
import { CheckboxField } from '../../../../form/CheckboxField';

type TemplateState = {
  _id: string;
  name: string;
  supplierIds: string[];
  selected: boolean;
  disabled: boolean;
};

const ModalContent = ({ isOpen, onCancel }: { isOpen: boolean; onCancel: () => void }) => {
  const { t } = useTranslation('preQualification');
  const { values: { templates }, setValues, handleSubmit } = useFormikContext<{ templates: TemplateState[] }>();
  const [filterText, setFilterText] = useState('');

  const selectedTemplates = filter(templates, (template) => template.selected);
  const selectedTemplatesCount = selectedTemplates.length;

  // Should always be false, but it's good to guard for this scenario anyway
  const hasNoTemplates = templates.length === 0;

  const filteredTemplates = useMemo(
    () => filter(
      templates,
      template => template.name.toLowerCase().includes(filterText.toLowerCase()),
    ),
    [templates, filterText],
  );

  // Keep track of initial indexes for showing their actual value when filtered
  const templateIndexById = useMemo(() => {
    return templates.reduce((acc, current, index) => ({ ...acc, [current._id]: index }), {});
  }, [templates]);

  const selectableTemplates = useMemo(
    () => filter(templates, template => !template.disabled),
    [templates],
  );

  const toggleAll = useCallback(() => {
    const newTemplates = templates.map((template) => ({
      ...template,
      selected: !template.disabled
        ? selectedTemplatesCount !== selectableTemplates.length
        : false,
    }));

    setValues({ templates: newTemplates });
  }, [setValues, selectedTemplatesCount, templates, selectableTemplates]);

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnEsc
    >
      <ModalHeader onClose={onCancel}>
        {t('questionnaire_other')}
      </ModalHeader>
      <ModalBody p={0} width="600px">
        <>
          <Box p={3} sx={{ backgroundColor: 'lightGray3' }}>
            <TextFieldBase
              placeholder={t('questionnaire.searchTemplatePlaceholder')}
              onChange={(event) => setFilterText(event.target.value)}
              suffix={<Icon icon="search" />}
            />
          </Box>
          <Box
            sx={{
              padding: [2, 3],
              backgroundColor: 'lightGray3',
              borderTop: 'lightGray2',
            }}
          >
            <Checkbox
              label={t('questionnaire.allTemplates')}
              onChange={() => toggleAll()}
              // @ts-expect-error ts(2322) FIXME: Type 'boolean | 0' is not assignable to type 'boolean | undefined'.
              indeterminate={selectedTemplatesCount && selectedTemplatesCount !== selectableTemplates.length}
              // @ts-expect-error ts(2322) FIXME: Type 'boolean | 0' is not assignable to type 'boolean | undefined'.
              checked={selectedTemplatesCount && selectedTemplatesCount === selectableTemplates.length}
            />
          </Box>
          <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
            {filteredTemplates.map((template) => {
              return (
                <Flex
                  alignItems="center"
                  p={3}
                  key={template._id}
                  sx={{ borderTop: 'lightGray2' }}
                >
                  <CheckboxField
                    name={`templates[${templateIndexById[template._id]}].selected`}
                    checkboxStyle={{ alignItems: 'center', marginRight: 2, padding: 0 }}
                    fieldLabel={(
                      <Tooltip content={template.disabled ? t('questionnaire.selectionDisabledTooltip') : null}>
                        <Truncate>{template.name}</Truncate>
                      </Tooltip>
                    )}
                    disabled={template.disabled}
                  />
                </Flex>
              );
            })}
            {hasNoTemplates ? (
              <Box padding={3} textAlign="center">
                <Text>{t('questionnaire.emptyState.noTemplatesAvailable')}</Text>
              </Box>
            ) : filteredTemplates.length === 0 && (
              <Box padding={3} textAlign="center">
                <Text>{t('questionnaire.emptyState.templateNotFound')}</Text>
              </Box>
            )}
          </Box>
        </>
      </ModalBody>
      <ModalFooter justifyContent="space-between" sx={{ height: '100%' }}>
        <Text>
          {t('questionnaire.templatesSelectedCount', {
            selected: selectedTemplatesCount,
            total: templates.length,
          })}
        </Text>
        <Flex>
          <CancelButton onClick={onCancel} />
          <SaveButton onClick={() => handleSubmit()} />
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

const SelectTemplatesModal = ({
  templates,
  isOpen,
  onCancel,
  onSubmit,
}: {
  templates: TemplateState[];
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (values: { templates: TemplateState[] }) => void;
}) => (
  <Formik
    enableReinitialize
    initialValues={{ templates }}
    validateOnMount
    validationSchema={yup.object().shape({
      templates: yup.array().of(
        yup.object().shape({
          selected: yup.boolean(),
        }),
      ),
    })}
    onSubmit={onSubmit}
  >
    <ModalContent isOpen={isOpen} onCancel={onCancel} />
  </Formik>
);

export const QuestionnaireTemplatesPanel = ({ initialTemplateIds }: { initialTemplateIds?: string[] }) => {
  const { t } = useTranslation(['preQualification', 'translation']);
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const [{ value: selectedTemplates },, selectedTemplatesHelper] = useField('templates');
  const [{ value: selectedSuppliers }] = useField('suppliers');
  const selectTemplatesModal = useModalState();
  const [areTemplatesInitialized, setAreTemplatesInitialized] = useState(false);

  const { data: activeTemplates, isLoading, isError, isFetching } = useQuery(
    ['activeQuestionnaireTemplates', { currentCompanyId }],
    wrap(api.getActiveQuestionnaireTemplates),
  );

  const allTemplateOverviews = useMemo(
    () => activeTemplates?.overviews || [],
    [activeTemplates?.overviews],
  );

  // Initialize the form with the templates that were passed in the query params
  useEffect(
    () => {
      if (!initialTemplateIds || isFetching || areTemplatesInitialized) return;

      const initialTemplates = map(
        filter(
          allTemplateOverviews,
          overview => initialTemplateIds.includes(overview._id),
        ),
        overview => ({
          _id: overview._id,
          name: overview.name,
          supplierIds: overview.supplierIds,
        }),
      );

      selectedTemplatesHelper.setValue(initialTemplates);
      setAreTemplatesInitialized(true);
    },
    [allTemplateOverviews, selectedTemplatesHelper, areTemplatesInitialized, isFetching, initialTemplateIds],
  );

  const selectedSupplierIds = useMemo(
    () => map(selectedSuppliers, supplier => supplier.company._id),
    [selectedSuppliers],
  );

  const templateSelectionStates = useMemo(
    () => !isLoading
      ? map(
        allTemplateOverviews,
        templateOverview => ({
          _id: templateOverview._id,
          name: templateOverview.name,
          supplierIds: templateOverview.supplierIds,
          selected: selectedTemplates.some(({ _id }) => _id === templateOverview._id),
          disabled: some(
            templateOverview.supplierIds,
            supplierId => selectedSupplierIds.includes(supplierId),
          ),
        }),
      )
      : [],
    [allTemplateOverviews, selectedTemplates, isLoading, selectedSupplierIds],
  );

  return (
    <>
      <Panel>
        <Flex p="12px 20px" alignItems="center">
          <Box flex="1 1 auto">
            <Bold lineHeight="normal" mb={1}>{t('questionnaire_other')}</Bold>
          </Box>
          <EditButton small onClick={selectTemplatesModal.open} />
        </Flex>
        <PanelDivider />
        {isLoading ? (
          <PanelPadding>
            <Loading />
          </PanelPadding>
        ) : isError ? (
          <PanelPadding>
            <ErrorMessage error={t('errors.unexpected', { ns: 'translation' })} />
          </PanelPadding>
        ) : selectedTemplates.length > 0 ? (
          <Box
            as="ul"
            sx={{
              listStyle: 'none',
              padding: 0,
              '> * + *': {
                borderTop: 'lightGray2',
              },
            }}
          >
            {selectedTemplates.map((template) => (
              <Flex as="li" key={template._id} alignItems="center" p="12px 20px" sx={{ gap: 2 }}>
                <Icon icon="circle" color="success" />
                <Text flex="1 1 auto">
                  <Truncate>
                    {template.name}
                  </Truncate>
                </Text>
                <EllipsisMenu small>
                  <DropdownMenuItem
                    icon="close"
                    color="danger"
                    onSelect={() => {
                      selectedTemplatesHelper.setValue(selectedTemplates.filter(({ _id }) => _id !== template._id));
                    }}
                  >
                    {t('questionnaire.removeTemplate')}
                  </DropdownMenuItem>
                </EllipsisMenu>
              </Flex>
            ))}
          </Box>
        ) : (
          <Box p={4}>
            <Pictogram variant="empty-state" label={t('questionnaire.emptyState.noTemplates')} />
          </Box>
        )}
      </Panel>
      {selectTemplatesModal.isOpen && (
        <SelectTemplatesModal
          templates={templateSelectionStates}
          isOpen
          onCancel={selectTemplatesModal.close}
          onSubmit={values => {
            const selectedTemplates = map(
              filter(values.templates, template => template.selected),
              ({ selected, ...template }) => template,
            );

            selectedTemplatesHelper.setValue(selectedTemplates);
            selectTemplatesModal.close();
          }}
        />
      )}
    </>
  );
};
