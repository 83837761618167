import { useMemo, useCallback } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { useApi, wrap } from '../../api';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { TableDataContext, TableData } from '../../TableDataContext';
import { useToaster } from '../../toast';
import { getContentDispositionFilename, downloadBlob } from '../../useDownload';
import { useMutation } from '../../useMutation';
import { useCurrentUserLocale } from '../../useCurrentUser';

type AuditTrailEvent = {
  date: string;
  documentType: string;
  documentName: string;
  documentId: string;
  documentExpiryDate: string;
  detail: string;
  action: string;
  user: string;
  documentVisibility: string;
};

export const useHistoryCSV = () => {
  const api = useApi();
  const { t } = useTranslation();
  const toaster = useToaster();
  const companyId = useCurrentCompanyId({ required: true });
  const locale = useCurrentUserLocale();
  const [getCSVDownloadUrl] = useMutation(api.getCompanyHistoryCSV);

  return useCallback(async (separator?: 'comma' | 'semicolon' | 'space' | 'tab') => {
    try {
      const response = await getCSVDownloadUrl({ companyId, separator, locale });
      const filename = getContentDispositionFilename(response);

      downloadBlob(response.data, filename);
    } catch {
      toaster.error(t('drivePage.toaster.historyDownloadedError'));
    }
  }, [getCSVDownloadUrl, companyId, locale, toaster, t]);
};

export const HistoryTableDataProvider = <QueryDataT extends AuditTrailEvent[]>({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement<TableData<QueryDataT>> => {
  const companyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const query = useQuery(
    ['documentsAllHistory', {
      companyId,
    }], wrap(api.getCompanyDocumentsHistory));

  const value = useMemo(() => ({
    companyId,
    data: query?.data ?? [],
    pageCount: 0,
    pageSize: 0,
    pageIndex: 0,
    setPageControls: () => null,
    query,
  }), [companyId, query]);

  return (
    <TableDataContext.Provider value={value}>
      {children}
    </TableDataContext.Provider>
  );
};
