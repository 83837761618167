import { useTranslation } from 'react-i18next';
import { Box, Text, Flex, FlexProps } from 'rebass/styled-components';
import { ExtendedSupplierListRule } from '@deepstream/common';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { useMemo } from 'react';
import { sortBy } from 'lodash';
import { localeSort } from '@deepstream/utils';
import { Bold } from '../../Bold';
import { QuestionnaireStatusesList } from './ListConfigFlow/QuestionnaireStatusesList';
import { useCurrentUserLocale } from '../../useCurrentUser';

const rowStyle = {
  borderBottom: 'secondary',
  padding: '12px 0px !important',
};

export const Row = (props: FlexProps) => (
  <Flex
    fontSize={2}
    width="100%"
    sx={rowStyle}
    {...props}
  />
);

export const QuestionnaireRules = ({ rules }: { rules: ExtendedSupplierListRule[] }) => {
  const { t } = useTranslation();
  const locale = useCurrentUserLocale();

  const orderedRules = useMemo(
    () => localeSort(
      rules,
      locale,
      rule => rule.questionnaireTemplateName,
    ),
    [rules, locale],
  );

  return (
    <Box>
      <Row>
        <Bold flex="1 0 50%">
          {t('questionnaire', { count: 1, ns: 'preQualification' })}
        </Bold>
        <Bold flex="1 0 50%">
          {t('supplierLists.configFlow.supplierStatus')}
        </Bold>
      </Row>
      {orderedRules.map(rule => (
        <Row key={rule.questionnaireTemplateId}>
          <Truncate flex="1 0 50%">
            {rule.questionnaireTemplateName}
          </Truncate>
          <Text flex="1 0 50%">
            <QuestionnaireStatusesList statuses={rule.statuses} />
          </Text>
        </Row>
      ))}
    </Box>
  );
};
