import { useEffect } from 'react';
import { Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { useTracking } from './useTracking';

const MEET_URL = 'https://www.deep.stream/contact';

const openMeetUrl = () => {
  const element = window.document.createElement('a');

  element.target = '_blank';
  element.href = MEET_URL;
  element.click();
};

export const UpgradeToUnlockDialog = ({ isOpen, onCancel }) => {
  const { t } = useTranslation();
  const { trackAccessRestricted, trackInformationRequested } = useTracking();

  useEffect(() => {
    if (isOpen) {
      trackAccessRestricted('createRequest');
    }
  }, [isOpen, trackAccessRestricted]);

  return (
    <Dialog
      style={{ content: { width: '500px' } }}
      heading={t('requests.dialog.upgradeToUnlock.heading')}
      body={(
        <>
          <Text mb={2} lineHeight={1.5}>{t('requests.dialog.upgradeToUnlock.body1')}</Text>
          <Text mb={2} lineHeight={1.5}>{t('requests.dialog.upgradeToUnlock.body2')}</Text>
        </>
      )}
      okButtonText={t('requests.dialog.upgradeToUnlock.okButtonText')}
      cancelButtonText={t('requests.dialog.upgradeToUnlock.cancelButtonText')}
      isOpen={isOpen}
      onOk={() => {
        trackInformationRequested('createRequest');
        openMeetUrl();
        onCancel();
      }}
      onCancel={onCancel}
      showCloseIcon
    />
  );
};
