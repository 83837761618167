import { Flex } from 'rebass/styled-components';
import styled from 'styled-components';

export const NavbarContainer = styled<any>(Flex)`
  flex: 1;
  height: 100%;
  max-width: 1200px;
  align-items: center;
  justify-content: space-between;
  font-size: ${props => props.theme.fontSizes[2]}px;
  font-weight: 500;
  line-height: 18px;
`;
