import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { isEqual, pickBy } from 'lodash';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { TextField, TextFieldBase } from '../../form/TextField';
import { PropertyList } from '../../PropertyList';
import { ContractSummaryPanelHeader } from './ContractSummaryPanelHeader';
import { useContractActions, useContractData, useContractState } from './contract';
import { useUpdateContractSummary } from './draftContract';
import { SummaryLabelConfigProvider } from '../../draft/SummaryLabelConfigProvider';
import { LeavePageModal } from '../../draft/LeavePageModal';

const panelId = 'referenceNumber';

export const ContractReferenceNumbersPanel = () => {
  const { t } = useTranslation(['contracts', 'general']);
  const { stopEditing } = useContractActions();
  // @ts-expect-error ts(2339) FIXME: Property 'editingPanelId' does not exist on type 'ContractStateContextType | undefined'.
  const { editingPanelId, isLive, isTemplatePreview } = useContractState();
  const contract = useContractData();
  const [updateContractSummary] = useUpdateContractSummary();

  const isEditingOtherPanel = editingPanelId && editingPanelId !== panelId;
  const isEditingThisPanel = editingPanelId && editingPanelId === panelId;

  const properties = useMemo(() => [
    { name: t('summary.automatedReference'), value: contract.autoReferenceNumber },
    { name: t('summary.additionalReference'), value: contract.summary.reference },
  ], [contract, t]);

  const initialValues = {
    'reference': contract.summary.reference,
  };

  const heading = t('summary.referenceNumbers');

  return (
    <SummaryLabelConfigProvider>
      <Panel
        as="section"
        aria-label={heading}
        sx={{
          opacity: isEditingOtherPanel ? 0.5 : 1,
          boxShadow: isEditingThisPanel ? '0 0 8px 0 rgba(0, 0, 0, 0.3)' : '',
        }}
      >
        <ContractSummaryPanelHeader
          heading={heading}
          panelId={panelId}
          canEdit={!isLive && !isTemplatePreview}
        />
        <PanelDivider />
        {isEditingThisPanel ? (
          <Formik
            validateOnBlur
            enableReinitialize
            initialValues={initialValues}
            validationSchema={
              yup.object().shape({
                'reference': yup.string(),
              })
            }
            onSubmit={async (values) => {
              await updateContractSummary(
                {
                  summary: pickBy(
                    values,
                    (value, key) => !isEqual(value, initialValues[key]),
                  ),
                },
                {
                  onSuccess: () => stopEditing(),
                },
              );
            }}
          >
            {({ isSubmitting, dirty, isValid, resetForm }) => (
              <Form>
                <Flex m={20}>
                  <TextFieldBase
                    label={t('summary.automatedReference')}
                    helperText={t('summary.automatedReferenceHelperText')}
                    disabled
                    value={contract.autoReferenceNumber ?? undefined}
                    name="autoReferenceNumber"
                    // quick fix: we can remove setting the inputStyle height once a line height is
                    // set for Input
                    inputStyle={{ height: 40 }}
                  />
                </Flex>
                <Flex mx={15} mb={20}>
                  <TextField
                    label={t('summary.additionalReference')}
                    helperText={t('summary.additionalReferenceHelperText')}
                    name="reference"
                    // quick fix: we can remove setting the inputStyle height once a line height is
                    // set for Input
                    inputStyle={{ height: 40 }}
                  />
                </Flex>
                <PanelDivider />
                <PanelPadding>
                  <Flex justifyContent="flex-end">
                    <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
                    <SaveButton disabled={isSubmitting || !dirty || !isValid} />
                  </Flex>
                </PanelPadding>
                <LeavePageModal />
              </Form>
            )}
          </Formik>
        ) : (
          <PropertyList properties={properties} />
        )}
      </Panel>
    </SummaryLabelConfigProvider>
  );
};
