import { useMemo } from 'react';
import { filter, find } from 'lodash';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { isLineItemExchangeDef } from '@deepstream/common/rfq-utils';
import { isCurrencyExchangeDef } from '@deepstream/common/contract/contract';
import { PanelDivider, PanelPadding, ExpandablePanelSubSection } from '@deepstream/ui-kit/elements/Panel';
import { EditableGridDataProvider, useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { ContractDraftPanel } from './ContractDraftPanel';
import { useError, Validation } from '../../draft/validation';
import { useShowValidationErrors } from './draftContract';
import { useContractSection, useIsSender, useSectionExchangeDefs } from './contract';
import { CurrencyCodeProvider } from '../../ui/Currency';
import { LineItemExchangeDefsGrid } from '../../ui/ExchangeDefsGrid/LineItemExchangeDefsGrid';
import { useExchangeDefsValidationSchema } from '../../draft/LineItemsSectionViewPanel';
import { LineItemsSectionConfigIndicators } from '../../draft/LineItemsSectionConfigIndicators';
import { SectionConfigHeading } from '../../draft/SectionConfigHeading';
import { ContractDraftSectionViewPanelHeader } from './ContractDraftSectionViewPanelHeader';

const ViewSectionConfig = () => {
  const isSender = useIsSender();
  const exchangeDefs = useSectionExchangeDefs();
  const modelExchangeDef = find(exchangeDefs, isLineItemExchangeDef);
  const currencyExchangeDef = find(exchangeDefs, isCurrencyExchangeDef);
  const showValidationErrors = useShowValidationErrors();
  const { error: supplierCurrencyError } = useError('currencyExchangeDef.currencies');

  return (
    <ExpandablePanelSubSection
      heading={<SectionConfigHeading />}
      renderCollapsedContent={() => (
        <LineItemsSectionConfigIndicators
          isContract
          isSender={isSender}
          modelExchangeDef={modelExchangeDef}
          // @ts-expect-error ts(2322) FIXME: Type 'CurrencyExchangeDefinition | undefined' is not assignable to type 'CurrencyExchangeDefinition'.
          currencyExchangeDef={currencyExchangeDef}
        />
      )}
      bg={showValidationErrors && supplierCurrencyError ? 'errorBackground' : 'lightGray3'}
    />
  );
};

/**
 * Displays a panel for the current line items section
 */
export const ViewPanelContent = () => {
  const { t } = useTranslation(['contracts', 'general']);

  const showValidationErrors = useShowValidationErrors();
  const section = useContractSection();
  const isSender = useIsSender();
  const exchangeDefs = useSectionExchangeDefs();
  const { rowData: lineItemExchangeDefs } = useEditableGridData();

  const currencyExchangeDef = exchangeDefs.find(isCurrencyExchangeDef);
  const firstExchangeDef = lineItemExchangeDefs[0];

  const exchangeDefsValidationSchema = useExchangeDefsValidationSchema(firstExchangeDef);

  return (
    <Validation
      values={{
        section,
        currencyExchangeDef,
        exchangeDefs: lineItemExchangeDefs,
      }}
      schema={showValidationErrors ? (
        yup.object().shape({
          section: yup.object().shape({
            name: yup.string().required(t('required', { ns: 'general' })),
          }),
          exchangeDefs: exchangeDefsValidationSchema,
        })
      ) : (
        yup.mixed()
      )}
    >
      <ContractDraftPanel panelId={section._id}>
        <ContractDraftSectionViewPanelHeader icon="list-ul" intercomEditEventName="edit-line-items-section-opened" />
        <PanelDivider />
        <ViewSectionConfig />
        <PanelDivider />
        <CurrencyCodeProvider code={lineItemExchangeDefs[0]?.evaluatorFieldCurrency}>
          <PanelPadding>
            <LineItemExchangeDefsGrid
              viewportHeightDelta={400}
              isSender={isSender}
              isReadOnly
            />
          </PanelPadding>
        </CurrencyCodeProvider>
      </ContractDraftPanel>
    </Validation>
  );
};

export const ContractLineItemsSectionViewPanel = () => {
  const exchangeDefs = useSectionExchangeDefs();
  const lineItemExchangeDefs = useMemo(
    () => filter(exchangeDefs, isLineItemExchangeDef),
    [exchangeDefs],
  );

  return (
    <EditableGridDataProvider
      rowData={lineItemExchangeDefs}
      enableReinitialize
    >
      <GridIdPrefixProvider>
        <GridMenuStateProvider>
          <ViewPanelContent />
        </GridMenuStateProvider>
      </GridIdPrefixProvider>
    </EditableGridDataProvider>
  );
};
