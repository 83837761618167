import { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { diffObject } from '@deepstream/utils';

export const useIsFormDirty = () => {
  const { values, initialValues } = useFormikContext();

  const isDirty = useMemo(
    () => {
      const diff = diffObject(values, initialValues);

      return diff.added.length || diff.removed.length || diff.updated.length;
    },
    [initialValues, values],
  );

  return isDirty;
};
