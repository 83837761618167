import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Award, AwardDecision, SplitDecision, Live, ExchangeType, isAuctionLineItemSection, getExchangeFieldValue, isAuctionLineItemExchangeDef } from '@deepstream/common/rfq-utils';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { fromPairs, isEmpty, isEqual, isFinite, isNil, noop, omitBy, sumBy } from 'lodash';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { immutableSet, roundToDecimals } from '@deepstream/utils';
import { EditableGridDataProvider, useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { FormikGridRowUpdater } from '@deepstream/ui-kit/grid/EditableGrid/FormikGridRowUpdater';
import { getLatestResponseExchangeIdByRecipientId } from '@deepstream/common/rfq-utils/spendAndSavings';
import { createCurrencyConverter } from '@deepstream/common';
import { evaluate, formulaParser } from '@deepstream/formula';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData, AwardFlowStep, AwardFlowStepType } from '../types';
import * as rfx from '../../../../rfx';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { SplitLineAwardGrid, SplitLineAwardGridRowData, getQuantityAmountFromQuantityPercentage, getQuantityPercentFromQuantityAmount } from '../../../../ui/ExchangeDefsGrid/SplitLineAwardGrid';
import { RequestHooksProvider } from '../../RequestHooksProvider';
import { getDefaultQuantitySplitMode, getExchangeIdsAwardedToMultipleSuppliers } from '../utils';
import { RadioButtonGroupField } from '../../../../form/RadioButtonGroupField';
import { useRfqExchangeByRecipient } from '../../../../useRfq';
import { AuctionLineItemsExchangeSnapshot, ExchangeSnapshot, LineItemsExchangeSnapshot } from '../../../../types';
import { LoadingPanel } from '../../../../ui/Loading';
import { ErrorPanel } from '../../../../ui/ErrorMessage';
import { ExchangeDefWithFields, useExchangeDefFieldValue } from '../../../../ExchangeDefFieldValueContext';
import { getFormulaContext } from '../../../../ExchangeModal/exchangeReplyFormConfig';
import { Bold } from '../../../../Bold';
import { Direction } from '../../../../ui/MultiStepFlow/types';

const AwardedQuantitySummary = ({
  rowData,
  quantitySplitMode,
}: {
  rowData: SplitLineAwardGridRowData[];
  quantitySplitMode: 'amount' | 'percentage';
}) => {
  const { t } = useTranslation('translation');

  const quantityRequested = rowData[0].originalQuantity;
  const quantityAwarded = quantitySplitMode === 'percentage'
    // @ts-expect-error ts(2345) FIXME: Argument of type '(row: any) => number | null' is not assignable to parameter of type 'string | ((value: SplitLineAwardGridRowData) => number) | undefined'.
    ? sumBy(rowData, getQuantityAmountFromQuantityPercentage) || 0
    // @ts-expect-error ts(2345) FIXME: Argument of type '(row: SplitLineAwardGridRowData) => number | undefined' is not assignable to parameter of type 'string | ((value: SplitLineAwardGridRowData) => number) | undefined'.
    : sumBy(rowData, row => row.awardedQuantityAmount) || 0;

  return (
    <>
      <Text mt="20px" sx={{ display: 'flex' }}>
        <Box as="span" mr="24px">
          {t('request.awardFlow.steps.splitLineAward.quantityRequested')}
          {' '}
          <Bold>{quantityRequested}</Bold>
        </Box>
        <Box as="span" mr="24px">
          {t('request.awardFlow.steps.splitLineAward.quantityAwarded')}
          {' '}
          <Bold>{quantityAwarded}</Bold>
        </Box>
        <Box as="span">
          {t('request.awardFlow.steps.splitLineAward.quantityRemaining')}
          {' '}
          {/*
           // @ts-expect-error ts(18048) FIXME: 'quantityRequested' is possibly 'undefined'. */}
          <Bold>{Math.max(0, quantityRequested - quantityAwarded)}</Bold>
        </Box>
      </Text>
      <Text mt="20px">
        {t('request.awardFlow.steps.splitLineAward.specifyAwardedQuantity')}
      </Text>
    </>
  );
};

const hasRowChanged = (gridRow: SplitLineAwardGridRowData, formikRow: SplitLineAwardGridRowData) =>
  !isEqual(gridRow.awardedQuantityAmount, formikRow.awardedQuantityAmount) ||
  !isEqual(gridRow.awardedQuantityPercentage, formikRow.awardedQuantityPercentage);

type GridRowBaseData = Omit<SplitLineAwardGridRowData, 'supplierCurrencyAwardedCost'>;

const useBaseRowData = (
  awards: Award[],
  exchangeByRecipientId: Record<string, ExchangeSnapshot> | null,
): GridRowBaseData[] | null => {
  const { recipients, sectionById, currencyCode: requestCurrencyCode } = rfx.useStructure<Live>();
  const { exchangeRates } = rfx.useCostAndSavingsData();
  const { getFieldValue } = useExchangeDefFieldValue();

  // @ts-expect-error ts(2322) FIXME: Type '{ _id: string; recipient: Company | undefined; exchange: LineItemsExchangeSnapshot | AuctionLineItemsExchangeSnapshot; ... 4 more ...; getSupplierCurrencyTotalCostForQuantityAmount: (quantityAmount?: number | undefined) => number | null; }[] | null' is not assignable to type 'GridRowBaseData[] | null'.
  return useMemo(() => {
    if (!exchangeByRecipientId) {
      return null;
    }

    const recipientIds = awards.map(award => award.recipientId);

    return recipientIds.map(recipientId => {
      const recipient = recipients.find(recipient => recipient._id === recipientId);
      const exchange = exchangeByRecipientId[recipientId] as LineItemsExchangeSnapshot | AuctionLineItemsExchangeSnapshot;
      const supplierCurrencyCode = exchange.def.type === ExchangeType.AUCTION_LINE_ITEM
        // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
        ? Object.values(sectionById).find(isAuctionLineItemSection).auctionRules.currency
        // In case the supplier had to choose a currency but hasn't done so, we'll be using
        // the request currency as supplier currency to enable the calculation of the total cost
        : getExchangeFieldValue(exchange, 'currency') || requestCurrencyCode;

      const requestCurrencyConverter = createCurrencyConverter(requestCurrencyCode, exchangeRates);

      const convertToRequestCurrency = (originalValue?: number) => {
        if (!originalValue) {
          return null;
        }

        const { value, currencyCode } = requestCurrencyConverter({
          value: originalValue,
          currencyCode: supplierCurrencyCode,
        });

        const truncatedValue = isFinite(value)
          ? roundToDecimals(value, 2)
          : null;

        return currencyCode === requestCurrencyCode
          ? truncatedValue
          : null;
      };

      const originalPricePerUnit = exchange.def.fields.price
        ? getExchangeFieldValue(exchange, 'price')
        : null;

      const originalQuantity = exchange.def.fields.quantity
        ? getFieldValue(exchange.def, 'quantity')
        : null;

      const parsedFormula = isAuctionLineItemExchangeDef(exchange.def)
        ? null
        : formulaParser.parse(exchange.def.fields.totalCost.source.formula);

      const getSupplierCurrencyTotalCostForQuantityAmount = isAuctionLineItemExchangeDef(exchange.def)
        ? (quantityAmount?: number) => {
          return quantityAmount
            ? quantityAmount * ((exchange as any).latestReply?.price ? ((exchange as any).latestReply.price as number) : NaN)
            : null;
        }
        : (() => {
          const formulaContext = getFormulaContext(exchange as any, exchangeRates, exchange.latestReply);

          return (quantityAmount?: number) => {
            if (!quantityAmount) {
              return null;
            }

            try {
              return evaluate(parsedFormula.expression, {
                ...formulaContext,
                quantity: quantityAmount,
              });
            } catch (error) {
              return null;
            }
          };
        })();

      return {
        _id: recipientId,
        recipient,
        exchange,
        supplierCurrencyCode,
        convertToRequestCurrency,
        originalPricePerUnit,
        originalQuantity,
        getSupplierCurrencyTotalCostForQuantityAmount,
      };
    });
  }, [awards, recipients, exchangeByRecipientId, sectionById, requestCurrencyCode, exchangeRates, getFieldValue]);
};

const useQuantitySplitModeOptions = () => {
  const { t } = useTranslation('translation');

  return useMemo(() => {
    return [
      {
        value: 'amount',
        label: t('general.amount'),
      },
      {
        value: 'percentage',
        label: t('request.awardFlow.steps.splitLineAward.percentage'),
      },
    ];
  }, [t]);
};

const getSubmissionDataFromFormValues = (
  {
    quantitySplitMode,
    rowData,
  }: {
    quantitySplitMode?: 'amount' | 'percentage' | null;
    rowData: SplitLineAwardGridRowData[];
  },
  data: AwardFlowData,
  exchangeId: string,
) => {
  const decisionByRecipientId = fromPairs(
    rowData.map(item => {
      const awardedQuantityAmount = quantitySplitMode === 'amount'
        ? item.awardedQuantityAmount
        : getQuantityAmountFromQuantityPercentage(item);
      const awardedQuantityPercentage = quantitySplitMode === 'amount'
        ? getQuantityPercentFromQuantityAmount(item)
        : item.awardedQuantityPercentage;

      return [
        item.recipient._id,
        {
          originalPricePerUnit: item.originalPricePerUnit,
          originalQuantity: item.originalQuantity,
          awardedQuantityAmount,
          awardedQuantityPercentage,

          supplierCurrencyCode: item.supplierCurrencyCode,
          // @ts-expect-error ts(2345) FIXME: Argument of type 'number | null | undefined' is not assignable to parameter of type 'number | undefined'.
          supplierCurrencyAwardedCost: item.getSupplierCurrencyTotalCostForQuantityAmount(awardedQuantityAmount),
          requestCurrencyAwardedCost: item.convertToRequestCurrency(
            // @ts-expect-error ts(2345) FIXME: Argument of type 'number | null | undefined' is not assignable to parameter of type 'number | undefined'.
            item.getSupplierCurrencyTotalCostForQuantityAmount(awardedQuantityAmount),
          ),
        },
      ];
    }),
  );

  return {
    splitDecisionByExchangeId: {
      ...data.splitDecisionByExchangeId,
      [exchangeId]: omitBy({
        splitBy: 'quantity',
        quantitySplitMode,
        decisionByRecipientId,
      }, isNil) as SplitDecision,
    },
  };
};

const StepInfo = ({ data }: { data: AwardFlowData }) => {
  const { t } = useTranslation('translation');
  const { exchangeDefById } = rfx.useStructure<Live>();
  const { getFieldValue } = useExchangeDefFieldValue();
  const { exchangeId } = data.currentStep as Extract<AwardFlowStep, { type: AwardFlowStepType.SPLIT_LINE_AWARD }>;
  const exchangeDef = exchangeDefById[exchangeId] as ExchangeDefWithFields;

  const {
    splitStepsTotal,
    currentSplitStepIndex,
  } = useMemo(() => {
    const exchangeIds = getExchangeIdsAwardedToMultipleSuppliers(data);

    return {
      splitStepsTotal: exchangeIds.length,
      currentSplitStepIndex: exchangeIds.indexOf(exchangeId),
    };
  }, [data, exchangeId]);

  return (
    <>
      <Text mt="12px" mb="24px">
        {t('request.awardFlow.steps.splitLineAward.info', { total: splitStepsTotal, current: currentSplitStepIndex + 1 })}
      </Text>
      <lotPagesLayout.H3 mt="40px" mb={3}>
        {getFieldValue(exchangeDef, 'description')}
      </lotPagesLayout.H3>
    </>
  );
};

const SplitLineAwardStepContent = ({
  data,
  submitAndNavigate,
  baseRowData,
  initialRowData,
}: {
  data: AwardFlowData,
  submitAndNavigate: (data: Partial<AwardFlowData> | null, direction: Direction) => void,
  baseRowData: GridRowBaseData[];
  initialRowData: SplitLineAwardGridRowData[];
}) => {
  const { t } = useTranslation('translation');
  const { currencyCode: requestCurrencyCode, exchangeDefById } = rfx.useStructure<Live>();
  const { setRowData } = useEditableGridData<SplitLineAwardGridRowData>();

  const { exchangeId } = data.currentStep as Extract<AwardFlowStep, { type: AwardFlowStepType.SPLIT_LINE_AWARD }>;
  const exchangeDef = exchangeDefById[exchangeId];

  // @ts-expect-error ts(18048) FIXME: 'exchangeDef.fields' is possibly 'undefined'.
  const hasQuantityField = Boolean(exchangeDef.fields.quantity || exchangeDef.fields['quantity:submitter']);

  const storedSplitDecision = data.splitDecisionByExchangeId?.[exchangeId];
  const quantitySplitModeOptions = useQuantitySplitModeOptions();

  const validationSchema = useMemo(() => {
    const AmountSchema = z.object({
      quantitySplitMode: z.literal('amount'),
      rowData: z.array(
        z.custom<SplitLineAwardGridRowData>().refine(
          rowData => Boolean(rowData.awardedQuantityAmount),
          rowData => ({
            message: rowData.awardedQuantityAmount === 0
              ? t('request.awardFlow.errors.enterPositiveAwardedQuantityAmount', { companyName: rowData.recipient.company.name || '' })
              : t('request.awardFlow.errors.enterAwardedQuantityAmount', { companyName: rowData.recipient.company.name || '' }),
          }),
        ),
      ),
    });

    const PercentageSchema = z.object({
      quantitySplitMode: z.literal('percentage'),
      rowData: z.array(
        z.custom<SplitLineAwardGridRowData>().refine(
          rowData => Boolean(rowData.awardedQuantityPercentage),
          rowData => ({
            message: rowData.awardedQuantityPercentage === 0
              ? t('request.awardFlow.errors.enterPositiveAwardedQuantityPercentage', { companyName: rowData.recipient.company.name || '' })
              : t('request.awardFlow.errors.enterAwardedQuantityPercentage', { companyName: rowData.recipient.company.name || '' }),
          }),
        ),
      ),
    });

    const Schema = z.discriminatedUnion('quantitySplitMode', [AmountSchema, PercentageSchema]);

    return toFormikValidationSchema(Schema);
  }, [t]);

  return (
    <Formik
      validateOnBlur
      initialValues={{
        quantitySplitMode: storedSplitDecision && 'quantitySplitMode' in storedSplitDecision
          ? storedSplitDecision.quantitySplitMode
          : getDefaultQuantitySplitMode(),
        rowData: initialRowData,
      }}
      validationSchema={validationSchema}
      onSubmit={noop}
    >
      {({ values, validateForm, submitForm, dirty }) => (
        <Form>
          <lotPagesLayout.ContentWrapper>
            <StepNavigation
              onBackClick={() => {
                submitAndNavigate(dirty ? getSubmissionDataFromFormValues(values, data, exchangeId) : null, Direction.BACK);
              }}
              onContinueClick={async () => {
                const errors = await validateForm();

                await submitForm();

                if (isEmpty(errors)) {
                  submitAndNavigate(dirty ? getSubmissionDataFromFormValues(values, data, exchangeId) : null, Direction.FORWARD);
                }
              }}
            >
              <FormErrors />
              <lotPagesLayout.Section heading={t('request.awardFlow.steps.splitLineAward.heading')} maxWidth="100%">
                <Box sx={{ maxWidth: lotPagesLayout.DEFAULT_SECTION_WIDTH }} mb="20px">
                  <StepInfo data={data} />
                  {hasQuantityField && (
                    <Flex alignItems="center">
                      {t('request.awardFlow.steps.splitLineAward.splitByQuantity')}
                      <Box ml="12px">
                        <RadioButtonGroupField
                          name="quantitySplitMode"
                          hideLabel
                          options={quantitySplitModeOptions}
                          value={values.quantitySplitMode}
                          onChange={() => {
                            setRowData(baseRowData);
                          }}
                          boxStyle={{
                            fontSize: '14px',
                            height: '40px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            marginBottom: 0,
                          }}
                        />
                      </Box>
                    </Flex>
                  )}
                  <AwardedQuantitySummary
                    rowData={values.rowData}
                    quantitySplitMode={values.quantitySplitMode}
                  />
                </Box>
                <FormikGridRowUpdater fieldName="rowData" hasRowChanged={hasRowChanged} />
                <SplitLineAwardGrid
                  key={values.quantitySplitMode}
                  requestCurrencyCode={requestCurrencyCode}
                  quantitySplitMode={values.quantitySplitMode}
                  viewportHeightDelta={200}
                />
              </lotPagesLayout.Section>
            </StepNavigation>
          </lotPagesLayout.ContentWrapper>
        </Form>
      )}
    </Formik>
  );
};

export const SplitLineAwardStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (data: Partial<AwardFlowData> | null, direction: Direction) => void,
}) => {
  const { t } = useTranslation('translation');
  const { costAndSavingsByRecipientId } = rfx.useCostAndSavingsData();

  const { exchangeId } = data.currentStep as Extract<AwardFlowStep, { type: AwardFlowStepType.SPLIT_LINE_AWARD }>;
  // @ts-expect-error ts(18048) FIXME: 'data.awardDecisionByExchangeId' is possibly 'undefined'.
  const { awards } = data.awardDecisionByExchangeId[exchangeId] as Extract<AwardDecision, { value: 'award' }>;

  const latestResponseExchangeIdByRecipientId = useMemo(() => {
    const awardedRecipientIds = awards.map(award => award.recipientId);

    return getLatestResponseExchangeIdByRecipientId(
      costAndSavingsByRecipientId,
      exchangeId,
      awardedRecipientIds,
    );
  }, [costAndSavingsByRecipientId, exchangeId, awards]);

  const { data: exchangeByRecipientId, isLoading, isError, isSuccess } =
    useRfqExchangeByRecipient({ exchangeIdByRecipientId: latestResponseExchangeIdByRecipientId });

  // @ts-expect-error ts(2345) FIXME: Argument of type 'Record<string, ExchangeSnapshot> | undefined' is not assignable to parameter of type 'Record<string, ExchangeSnapshot> | null'.
  const baseRowData = useBaseRowData(awards, exchangeByRecipientId);

  const initialRowData = useMemo(() => {
    if (!baseRowData) {
      return null;
    }

    const exchangeSplitDecision = data.splitDecisionByExchangeId?.[exchangeId];

    if (!exchangeSplitDecision) {
      return baseRowData;
    }

    return baseRowData.map(item => {
      const storedSplitDecision = exchangeSplitDecision.decisionByRecipientId?.[item._id];

      if (!storedSplitDecision) {
        return item;
      }

      if (exchangeSplitDecision.quantitySplitMode === 'amount') {
        return {
          ...item,
          awardedQuantityAmount: (storedSplitDecision as any)?.awardedQuantityAmount || null,
        };
      } else {
        return {
          ...item,
          awardedQuantityPercentage: (storedSplitDecision as any)?.awardedQuantityPercentage || null,
        };
      }
    }) as SplitLineAwardGridRowData[];
  }, [baseRowData, data.splitDecisionByExchangeId, exchangeId]);

  return isSuccess && baseRowData && initialRowData ? (
    <RequestHooksProvider>
      <rfx.StateProvider isLive>
        <GridIdPrefixProvider>
          <GridMenuStateProvider>
            <EditableGridDataProvider
              rowData={initialRowData}
              setValueInRow={immutableSet}
            >
              <SplitLineAwardStepContent
                data={data}
                submitAndNavigate={submitAndNavigate}
                baseRowData={baseRowData}
                initialRowData={initialRowData}
              />
            </EditableGridDataProvider>
          </GridMenuStateProvider>
        </GridIdPrefixProvider>
      </rfx.StateProvider>
    </RequestHooksProvider>
  ) : (
    <lotPagesLayout.ContentWrapper>
      <StepNavigation
        onBackClick={() => submitAndNavigate(null, Direction.BACK)}
      >
        <lotPagesLayout.Section heading={t('request.awardFlow.steps.splitLineAward.heading')} maxWidth="100%">
          <Box sx={{ maxWidth: lotPagesLayout.DEFAULT_SECTION_WIDTH }}>
            <StepInfo data={data} />
            {isLoading ? (
              <LoadingPanel />
            ) : isError ? (
              <ErrorPanel error={t('errors.unexpected')} />
            ) : (
              null
            )}
          </Box>
        </lotPagesLayout.Section>
      </StepNavigation>
    </lotPagesLayout.ContentWrapper>
  );
};
