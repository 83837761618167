import { Box, Flex } from 'rebass/styled-components';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';
import { useLiveRfqStructure, useRecipientId, useRfqId } from './useRfq';
import { useCurrentCompanyId } from './currentCompanyId';
import { requestSentComparisonRoute, legacyRequestSentRecipientEvaluationPageRoute, requestSentRecipientEvaluationPageRoute } from './AppRouting';
import { useNavigate } from './tanstackRouter';

const OuterContainer = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
`;

const InnerContainer = styled<any>(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding: 15px;
  height: 100%;
`;

export const EvaluationNavigation = ({ pageId }: { pageId: string }) => {
  const { t } = useTranslation('evaluation');
  const navigate = useNavigate();
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId();
  const recipientId = useRecipientId();
  const { data: structure } = useLiveRfqStructure({
    rfqId,
    // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string | undefined'.
    currentCompanyId,
    ignoreRecipientId: true,
  });

  const recipientIds = map(structure?.recipients, recipient => recipient._id);
  const recipientIndex = recipientIds.indexOf(recipientId);
  const prevRecipientId = recipientIds[recipientIndex - 1];
  const nextRecipientId = recipientIds[recipientIndex + 1];

  const navigateToRecipientEvaluationPage = recipientId => {
    navigate({
      // @ts-expect-error ts(18048) FIXME: 'structure' is possibly 'undefined'.
      to: structure.newFeaturesDisabled
        ? legacyRequestSentRecipientEvaluationPageRoute.to
        : requestSentRecipientEvaluationPageRoute.to,
      // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
      params: { currentCompanyId, rfqId, recipientId, pageId },
    });
  };

  return (
    <OuterContainer>
      <InnerContainer>
        <Flex alignItems="center" sx={{ gap: '8px' }}>
          <ButtonGroup marginBetween="-1px">
            <Button
              type="button"
              variant="secondary-outline"
              iconLeft="arrow-left"
              small
              disabled={!prevRecipientId}
              onClick={() => navigateToRecipientEvaluationPage(prevRecipientId)}
            />
            <Button
              type="button"
              variant="secondary-outline"
              iconLeft="arrow-right"
              small
              disabled={!nextRecipientId}
              onClick={() => navigateToRecipientEvaluationPage(nextRecipientId)}
            />
          </ButtonGroup>
          {t('navigation.currentSupplierPosition', { position: recipientIndex + 1, total: recipientIds.length })}
        </Flex>
        <Flex>
          {/* TODO: add indicators for status and actions after https://linear.app/deepstream/issue/S1-3506/add-indicators-for-status-and-actions-to-evaluation-comparison-view */}
        </Flex>
        <Button
          variant="secondary-outline"
          small
          iconLeft="table"
          onClick={() => {
            navigate({
              to: requestSentComparisonRoute.to,
              // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
              params: { currentCompanyId, rfqId },
            });
          }}
        >
          {t('navigation.compareAllSuppliers')}
        </Button>
      </InnerContainer>
    </OuterContainer>
  );
};
