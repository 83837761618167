import { useQueryClient } from 'react-query';
import { RfqId } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { useApi } from '../api';
import { useMutation } from '../useMutation';
import * as rfx from '../rfx';
import { useToaster } from '../toast';
import { useCurrentCompanyId } from '../currentCompanyId';

export const useBroadcastChatMessage = ({
  rfqId,
}: {
  rfqId: RfqId;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const toaster = useToaster();
  const api = useApi();
  const queryClient = useQueryClient();
  const senders = rfx.useSenders();
  const auctionId = rfx.useAuction()._id;

  return useMutation(
    async (message: string) => {
      await api.broadcastChatMessage({
        rfqId,
        message,
        auctionId,
        senderId: senders[0]._id,
        currentCompanyId,
      });
    },
    {
      onSettled: () => queryClient.invalidateQueries('liveRfqStructure', { refetchInactive: true, refetchActive: true }),
      onSuccess: () => toaster.success(t('request.toaster.actionSubmittedSuccess')),
      onError: () => toaster.error(t('request.toaster.actionSubmittedError')),
    },
  );
};
