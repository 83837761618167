import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box } from 'rebass/styled-components';
import { useQuery } from 'react-query';
import { CompanyMinimized } from '@deepstream/common/rfq-utils';
import { LabeledSorting } from '@deepstream/ui-utils';
import { SortingDirection } from '@deepstream/common';
import { first, get, isEmpty, map, orderBy } from 'lodash';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelHeader, PanelDivider, PanelProps } from '@deepstream/ui-kit/elements/Panel';
import { useDeviceSize } from '../../../ui/useDeviceSize';
import { useModalState } from '../../../ui/useModalState';
import { useApi, wrap } from '../../../api';
import * as rfx from '../../../rfx';
import { renderLabel } from '../../../RequestsTable';
import { useSortProps } from '../../../sorting';
import { ClearFiltersButton, useFilterProps } from '../../../filtering';
import { SelectDropdownMenu } from '../../../ui/MultiSelect';
import { RequestRole } from '../../../types';
import { InviteModal } from '../../../InviteModal';
import { RecipientTeamUsersTable } from './RecipientTeamUsersTable';
import { useRequestRoleSelectItems } from './useRequestRoleSelectItems';
import { RecipientTeamAddUserModal } from './RecipientTeamAddUserModal';

const useSortItems = (): LabeledSorting[] => {
  const { t } = useTranslation();

  return useMemo(() => [
    { label: t('general.sorting.nameAscending'), accessor: 'name', direction: SortingDirection.ASC },
    { label: t('general.sorting.nameDescending'), accessor: 'name', direction: SortingDirection.DESC },
  ], [t]);
};

type RecipientTeamUsersPanelProps = Omit<PanelProps, 'children'> & {
  company: CompanyMinimized;
  showAddUserButton?: boolean;
};

export const RecipientTeamUsersPanel = ({
  company,
  showAddUserButton = true,
  ...props
}: RecipientTeamUsersPanelProps) => {
  const { t } = useTranslation();
  const api = useApi();
  const { isExtraSmall } = useDeviceSize();
  const { teamById } = rfx.useStructure();
  const { canEditTeam } = rfx.useRfxPermissions();
  const addUserModal = useModalState();
  const { editingPanelId } = rfx.useState();
  const inviteCompaniesModalState = useModalState();

  const isEditing = Boolean(editingPanelId);

  const { data: usersForCompany } = useQuery(
    ['usersForCompany', { companyId: company._id }],
    wrap(api.getUsersForCompany),
  );

  const users = useMemo(() => Object.values(teamById[company._id].users)
    .map(user => {
      const userForCompany = usersForCompany?.find(
        userInCompany => userInCompany._id === user._id,
      );

      return {
        _id: user._id,
        name: user.name || userForCompany?.name,
        email: user.email || userForCompany?.email,
        requestRole: teamById[company._id].owners.includes(user._id)
          ? RequestRole.OWNER
          : RequestRole.TEAM_MEMBER,
        companyId: company._id,
        roleRestrictionProfile: userForCompany?.roleRestrictionProfiles?.[company._id] ?? null,
      };
    }), [usersForCompany, teamById, company._id]);

  const roleFilterItems = useRequestRoleSelectItems();
  const roleFilter = useFilterProps({
    items: roleFilterItems,
    idProp: 'value',
    renderItem: renderLabel,
  });

  const sortItems = useSortItems();
  const sort = useSortProps({
    items: sortItems,
  });

  const selectedUsers = useMemo(() => {
    let usersClone = [...users];

    if (!isEmpty(roleFilter.selectedItems)) {
      const selectedRoles = map(roleFilter.selectedItems, 'value');
      usersClone = usersClone.filter(
        user => selectedRoles.includes(user.requestRole),
      );
    }

    if (!isEmpty(sort.selectedItems)) {
      const sorting = first(sort.selectedItems) as LabeledSorting;

      usersClone = orderBy(
        usersClone,
        [(user) => get(user, sorting.accessor, '').toLowerCase()],
        [sorting.direction],
      );
    }

    return usersClone;
  }, [users, roleFilter, sort]);

  const hasFilters = !isEmpty(roleFilter.selectedItems);

  return (
    <>
      <Panel {...props}>
        <PanelHeader heading={t('general.user_other')}>
          <Flex>
            {hasFilters && (
              <Box mr={2}>
                <ClearFiltersButton
                  onClick={() => roleFilter.onChange([])}
                />
              </Box>
            )}
            <Box mr={2}>
              <SelectDropdownMenu
                multi
                buttonText={t('general.role')}
                buttonIcon="filter"
                menuWidth={180}
                menuZIndex={10}
                disabled={isEditing}
                {...roleFilter}
              />
            </Box>
            <Box>
              <SelectDropdownMenu
                rightAligned={!canEditTeam || !showAddUserButton}
                buttonText={t('general.sort')}
                buttonIcon="sort"
                menuWidth={160}
                menuZIndex={10}
                disabled={isEditing}
                {...sort}
              />
            </Box>
            {canEditTeam && showAddUserButton && (
              <Button
                ml={isExtraSmall ? 3 : 4}
                variant="primary"
                iconLeft="plus"
                onClick={addUserModal.open}
                disabled={isEditing}
                small
              >
                {t('teamManagement.addUser')}
              </Button>
            )}
          </Flex>
        </PanelHeader>
        <PanelDivider />
        <RecipientTeamUsersTable
          users={selectedUsers}
        />
      </Panel>
      {addUserModal.isOpen && (
        <RecipientTeamAddUserModal
          isOpen={addUserModal.isOpen}
          close={addUserModal.close}
          onInviteNewUserClick={() => {
            addUserModal.close();
            inviteCompaniesModalState.open();
          }}
          company={company}
        />
      )}

      <InviteModal
        isOpen={inviteCompaniesModalState.isOpen}
        close={inviteCompaniesModalState.close}
        customizedMessage={t('invite.customizedMessages.forNetwork')}
      />
    </>
  );
};
