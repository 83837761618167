import {
  useMemo,
  forwardRef,
  useCallback,
  useState,
  useRef,
  ForwardedRef,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { isEmpty, pick, values } from 'lodash';
import { useField } from 'formik';
import {
  Draft,
  isDateTimeQuestion,
  isOptionBasedQuestion,
  QuestionAddressField,
  QuestionExchangeDefinition,
  QuestionFormat,
  QuestionType,
} from '@deepstream/common/rfq-utils';
import { ObsoleteIcon } from '@deepstream/ui-kit/elements/icon/Icon';
import { truncateStyle } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { createQuestion, useExchangeDefsField } from './exchangeDefs';
import { TextField } from '../form/TextField';
import { ExchangeDefActions } from './ExchangeDefActions';
import { useDeviceSize } from '../ui/useDeviceSize';
import { DisabledInputBox } from '../ui/Input';
import { SwitchField } from '../form/SwitchField';
import { FieldContainer } from '../form/FieldContainer';
import { QuestionOptionField, AddressFields, LongTextField, ShortTextField, DateTimeField, QuestionTypeSelector, PriceField } from './QuestionFields';
import { Pagination } from '../Pagination';
import { AddQuestionButton } from './AddQuestionButton';

const RequiredInfoTooltip = () => {
  const { t } = useTranslation();

  return (
    <Box lineHeight={1.5}>
      <Text mb={3}>
        {t('request.question.requiredInfoTooltip1')}
      </Text>
      <Text>
        {t('request.question.requiredInfoTooltip2')}
      </Text>
    </Box>
  );
};

const SupplierResponse = ({
  exchangeFieldName,
}: {
  exchangeFieldName: string;
}) => {
  const { t } = useTranslation();
  const [{ value: exchangeDef }] = useField<QuestionExchangeDefinition>(exchangeFieldName);

  const { questionType } = exchangeDef;

  const isRequired = useMemo(
    () => [
      QuestionType.CHECKBOXES,
      QuestionType.MULTIPLE_CHOICE,
      QuestionType.ADDRESS,
    ].includes(questionType),
    [questionType],
  );

  return (
    <Box>
      {questionType && (
        <FieldContainer
          label={t('request.question.supplierResponse')}
          showAsterisk={isRequired}
        >
          {questionType === QuestionType.SHORT_TEXT ? (
            <ShortTextField fieldName={exchangeFieldName} disabled={exchangeDef.isObsolete} />
          ) : questionType === QuestionType.LONG_TEXT ? (
            <LongTextField />
          ) : questionType === QuestionType.PRICE ? (
            <PriceField fieldName={exchangeFieldName} disabled={exchangeDef.isObsolete} />
          ) : isOptionBasedQuestion(exchangeDef) ? (
            <QuestionOptionField type={exchangeDef.questionType} fieldName={exchangeFieldName} disabled={exchangeDef.isObsolete} />
          ) : questionType === QuestionType.ADDRESS ? (
            <AddressFields fieldName={exchangeFieldName} disabled={exchangeDef.isObsolete} />
          ) : questionType === QuestionType.DATE_TIME ? (
            <DateTimeField fieldName={exchangeFieldName} disabled={exchangeDef.isObsolete} />
          ) : (
            null
          )}
        </FieldContainer>
      )}
    </Box>
  );
};

export const EditQuestionPanel = forwardRef((
  { fieldName, index, isSectionObsolete }: { fieldName: string; index: number; isSectionObsolete?: boolean },
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { t } = useTranslation();
  const { isExtraSmall, isSmall } = useDeviceSize();
  const exchangeFieldName = `${fieldName}[${index}]`;
  const [{ value: exchangeDef },, helpers] = useField<QuestionExchangeDefinition<Draft>>(exchangeFieldName);

  const onQuestionTypeChange = useCallback(
    (questionType: QuestionType) => {
      const commonQuestionTypeKeys = ['_id', 'type', 'description', 'isRequired'] as const;

      // Any keys that are specific to that question type
      const keysByQuestionType = {
        [QuestionType.MULTIPLE_CHOICE]: ['options', 'allowCustomOption'],
        [QuestionType.CHECKBOXES]: ['options', 'allowCustomOption'],
        [QuestionType.PRICE]: ['currencies'],
        [QuestionType.ADDRESS]: ['visibleFields'],
        [QuestionType.DATE_TIME]: ['format'],
        [QuestionType.SHORT_TEXT]: ['schema'],
        [QuestionType.LONG_TEXT]: [],
      } as const;

      const newExchangeDef = {
        ...pick(exchangeDef, [...commonQuestionTypeKeys, ...keysByQuestionType[questionType]]),
        questionType,
      } as any;

      if (isOptionBasedQuestion(newExchangeDef) && isEmpty(newExchangeDef.options)) {
        newExchangeDef.options = [''];
      } else if (questionType === QuestionType.ADDRESS) {
        newExchangeDef.visibleFields = values(QuestionAddressField);
      } else if (questionType === QuestionType.PRICE) {
        newExchangeDef.currencies = null;
      } else if (questionType === QuestionType.SHORT_TEXT) {
        newExchangeDef.schema = undefined;
      } else if (isDateTimeQuestion(newExchangeDef)) {
        newExchangeDef.format = QuestionFormat.DATE;
      }

      helpers.setValue(newExchangeDef as QuestionExchangeDefinition);
    },
    [exchangeDef, helpers],
  );

  return (
    <Panel padded bg="lightGray3" ref={ref}>
      <Stack gap={3}>
        <Flex alignItems="flex-end">
          <Box maxWidth="60px" mr={2}>
            <TextField
              label={t('general.number')}
              name={`${exchangeFieldName}.index`}
              disabled
              // quick fix: we can remove setting the inputStyle height once a line height is
              // set for Input
              inputStyle={{ height: 40 }}
              value={index + 1}
            />
          </Box>
          <Box mr={2} flex={2}>
            {exchangeDef.isObsolete ? (
              <FieldContainer
                label={t('request.question.question', { count: 1 })}
                showAsterisk
              >
                <DisabledInputBox sx={truncateStyle}>
                  <Tooltip content={t('general.obsolete') as string}>
                    <ObsoleteIcon mr={2} />
                  </Tooltip>
                  {exchangeDef.description}
                </DisabledInputBox>
              </FieldContainer>
            ) : (
              <TextField
                label={t('request.question.question', { count: 1 })}
                name={`${exchangeFieldName}.description`}
                required
                // quick fix: we can remove setting the inputStyle height once a line height is
                // set for Input
                inputStyle={{ height: 40 }}
              />
            )}
          </Box>
          <Box
            mr={2}
            flex={1}
            minWidth={isSmall || isExtraSmall ? '20%' : '160px'}
            maxWidth="300px"
          >
            <QuestionTypeSelector
              value={exchangeDef.questionType}
              disabled={exchangeDef.isObsolete}
              onChange={onQuestionTypeChange}
              filter={(questionType) => ![
                QuestionType.DOCUMENT,
                QuestionType.GRID,
                QuestionType.YES_NO,
              ].includes(questionType)}
            />
          </Box>
          <Box mr={2} flex="0 0 auto">
            <SwitchField
              label={t('request.question.required')}
              name={`${exchangeFieldName}.isRequired`}
              disabled={exchangeDef.isObsolete}
              switchHeight="40px"
              useDefaultLabelConfig={false}
              infoTooltip={<RequiredInfoTooltip />}
            />
          </Box>
          {!isSectionObsolete && (
            <Box flex="0 0 auto">
              <ExchangeDefActions
                index={index}
                fieldName={fieldName}
              />
            </Box>
          )}
        </Flex>
        {exchangeDef.questionType && (
          <SupplierResponse
            exchangeFieldName={exchangeFieldName}
          />
        )}
      </Stack>
    </Panel>
  );
});

export const EditQuestions = ({
  fieldName = 'exchangeDefs',
  isSectionObsolete,
}: {
  fieldName?: string;
  isSectionObsolete?: boolean;
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [currentItem, setCurrentItem] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const questionsRefs = useRef<Array<HTMLDivElement>>(Array.from({ length: 50 }));
  const { t } = useTranslation();

  const { exchangeDefs, addExchangeDef } = useExchangeDefsField(
    fieldName,
    createQuestion,
  );

  const { items, pageCount } = useMemo(() => {
    const pageCount = Math.ceil(exchangeDefs.length / pageSize);
    const items = exchangeDefs.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
    return { items, pageCount };
  }, [currentPage, exchangeDefs, pageSize]);

  const handleGoToItem = useCallback(
    (index: number) => {
      setCurrentItem(index);
      setCurrentPage(Math.floor(index / pageSize));
    },
    [pageSize],
  );

  useEffect(() => {
    if (currentPage >= pageCount) {
      setCurrentPage(Math.max(0, pageCount - 1));
    }
  }, [currentPage, pageCount]);

  useEffect(() => {
    questionsRefs.current[currentItem % pageSize]?.scrollIntoView?.({ block: 'center' });
  }, [currentItem, pageSize]);

  return (
    <>
      {items.length > 0 ? (
        <Stack gap={3} mb={3}>
          {items.map((exchangeDef, index) => (
            <EditQuestionPanel
              // @ts-expect-error ts(2322) FIXME: Type 'HTMLDivElement | null' is not assignable to type 'HTMLDivElement'.
              ref={el => { questionsRefs.current[index] = el; }}
              key={exchangeDef._id}
              fieldName={fieldName}
              index={index + currentPage * pageSize}
              isSectionObsolete={isSectionObsolete}
            />
          ))}
          {exchangeDefs.length > pageSize && (
            <Pagination
              pageCount={pageCount}
              pageSize={pageSize}
              canNextPage={currentPage < pageCount - 1}
              canPreviousPage={currentPage > 0}
              goToItem={handleGoToItem}
              currentItem={currentItem}
              itemsCount={exchangeDefs.length}
              nextPage={() => setCurrentPage(currentPage + 1)}
              previousPage={() => setCurrentPage(currentPage - 1)}
              setPageSize={setPageSize}
              pageIndex={currentPage}
            />
          )}
        </Stack>
      ) : (
        <Text color="subtext" fontSize={2} mb={3}>
          {t('request.question.noQuestionsAdded')}
        </Text>
      )}
      {!isSectionObsolete && (
        <AddQuestionButton
          type="button"
          variant="secondary"
          mt={2}
          mr={2}
          onClick={() => addExchangeDef()}
        />
      )}
    </>
  );
};
