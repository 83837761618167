import * as Layout from '../../../Layout';
import { Header } from './Header';
import { TemplatePageTab } from './utils';
import { ActiveDetails } from './ActiveDetails';

export const QuestionnaireTemplateActiveDetailsPageContent = () => {
  const tabId = TemplatePageTab.DETAILS;

  return (
    <Layout.HeaderAndContent
      header={
        <Header selectedTabId={tabId} />
      }
      content={
        <ActiveDetails />
      }
    />
  );
};
