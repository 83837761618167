import * as React from 'react';
import { FontSizeProps } from 'styled-system';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { FormGroup } from './FormGroup';
import { Input } from './Input';

interface SearchInputProps extends FontSizeProps {
  searchQuery?: string;
  placeholder?: string;
  width?: number | string;
  height?: number | string;
  buttonVariant?: ButtonProps['variant'];
}

export const SearchInput: React.FC<SearchInputProps> = ({
  placeholder,
  searchQuery,
  buttonVariant = 'primary',
  fontSize = 4,
  width = '500px',
  height = '52px',
}) => (
  <form action="/companies/search" style={{ width: '100%', maxWidth: width, paddingLeft: 16, paddingRight: 16, marginLeft: 'auto', marginRight: 'auto' }}>
    <FormGroup mx="auto" mb={5} style={{ height }}>
      <Input
        type="text"
        name="searchQuery"
        fontSize={fontSize}
        bg="white"
        defaultValue={searchQuery}
        placeholder={placeholder}
        style={{ height }}
      />
      <Button
        type="submit"
        variant={buttonVariant}
        iconLeft="search"
        fontSize={fontSize}
        style={{ width: height, height }}
      />
    </FormGroup>
  </form>
);
