import { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useQuery, MutateOptions, useQueryClient, QueryKey } from 'react-query';
import { map, find } from 'lodash';
import { QuestionType, QuestionExchangeDefinition, ExchangeType } from '@deepstream/common/rfq-utils';
import { PreQualCategoryType } from '@deepstream/common/preQual';

import { PreQualCategory } from '../../types';
import { useMutation } from '../../useMutation';
import { useApi, wrap } from '../../api';
import { useCurrentCompanyId } from '../../currentCompanyId';

export type QuestionModalValues = {
  description: string;
  questionType: QuestionType;
  categoryId: string;
} & Record<string, unknown>; // Maybe extend this if worth it

export type CategoryModalValues = {
  name: string;
};

export const useQuestionForm = () => useFormikContext<QuestionModalValues>();
export const useCategoryForm = () => useFormikContext<CategoryModalValues>();

const QUESTIONS_LIST_KEY = 'preQualQuestionsList';
const CATEGORIES_LIST_KEY = 'preQualCategoriesList';
const CHECK_CATEGORY_NAME_KEY = 'preQualCheckCategoryName';

type ListCategoriesFilterParams = {
  categoryIds?: string[];
};

export const useListQuestionsQueryKey = () => {
  const currentCompanyId = useCurrentCompanyId();

  const getQueryKey = useCallback((categoryId?: string) => {
    return [QUESTIONS_LIST_KEY, { categoryId, currentCompanyId }];
  }, [currentCompanyId]);

  return getQueryKey;
};

export const useListQuestions = ({
  categoryId,
}: {
  categoryId?: string;
} = {}) => {
  const api = useApi();
  const getQueryKey = useListQuestionsQueryKey();

  return useQuery(
    getQueryKey(categoryId),
    wrap(api.getPreQualQuestionsList),
    { staleTime: 60 * 1000 },
  );
};

export const useListCategoriesQueryKey = () => {
  const currentCompanyId = useCurrentCompanyId();

  const getQueryKey = useCallback(({ filter }: { filter?: ListCategoriesFilterParams } = {}) => {
    return [CATEGORIES_LIST_KEY, { currentCompanyId, filter }];
  }, [currentCompanyId]);

  return getQueryKey;
};

export const useListCategories = ({
  filter,
  queryOptions,
}: {
  filter?: ListCategoriesFilterParams;
  queryOptions?: Parameters<typeof useQuery<PreQualCategory[], unknown, PreQualCategory[], QueryKey>>[2],
} = {}) => {
  const api = useApi();
  const { t } = useTranslation('preQualification');
  const getQueryKey = useListCategoriesQueryKey();

  const { data: rawData, ...rest } = useQuery(
    getQueryKey({ filter }),
    wrap(api.getPreQualCategoriesList),
    queryOptions,
  );

  const data = useMemo(() => {
    return map(rawData, (category) => {
      if (category.type === PreQualCategoryType.NO_CATEGORY) {
        return {
          ...category,
          name: t('categories.defaultCategory'),
        };
      }

      return category;
    });
  }, [t, rawData]);

  return { data, ...rest };
};

export const useDefaultCategory = ({
  enabled = true,
}: {
  enabled?: boolean;
} = {}) => {
  const { data, isError, isLoading } = useListCategories({
    queryOptions: {
      enabled,
    },
  });

  if (isError || isLoading) return null;

  return find(data, (category) => category.type === PreQualCategoryType.NO_CATEGORY);
};

const mapValuesToPayload = (values: QuestionModalValues) => {
  const { categoryId, _id: questionId, ...rest } = values;

  const exchangeDef = {
    _id: questionId || uuid(),
    type: ExchangeType.QUESTION,
    ...rest,
  } as QuestionExchangeDefinition;

  return {
    categoryId,
    exchangeDef,
  } as const;
};

export const useCreateQuestion = () => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId();
  const [createQuestion, ...rest] = useMutation(api.createPreQualQuestion);

  const questionMutation = useCallback((values: QuestionModalValues, options?: MutateOptions<
    { _id: string; },
    unknown,
    { categoryId: string; currentCompanyId: string; exchangeDef: QuestionExchangeDefinition; },
    unknown
  >) => {
    const variables = {
      currentCompanyId,
      ...mapValuesToPayload(values),
    } as const;

    // @ts-expect-error ts(2345) FIXME: Argument of type '{ readonly categoryId: string; readonly exchangeDef: QuestionExchangeDefinition; readonly currentCompanyId: string | null; }' is not assignable to parameter of type '{ currentCompanyId: string; categoryId: string; exchangeDef: QuestionExchangeDefinition; }'.
    return createQuestion(variables, options);
  }, [currentCompanyId, createQuestion]);

  return [questionMutation, ...rest] as const;
};

export const useUpdateQuestion = () => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId();
  const [updateQuestion, ...rest] = useMutation(api.updatePreQualQuestion);

  const questionMutation = useCallback((questionId: string, values: QuestionModalValues, options?: MutateOptions<
    { _id: string; },
    unknown,
    { categoryId: string; currentCompanyId: string; exchangeDef: QuestionExchangeDefinition; },
    unknown
  >) => {
    const variables = {
      questionId,
      currentCompanyId,
      ...mapValuesToPayload(values),
    } as const;

    // @ts-expect-error ts(2345) FIXME: Argument of type '{ readonly categoryId: string; readonly exchangeDef: QuestionExchangeDefinition; readonly questionId: string; readonly currentCompanyId: string | null; }' is not assignable to parameter of type '{ currentCompanyId: string; questionId: string; categoryId: string; exchangeDef: QuestionExchangeDefinition; }'.
    return updateQuestion(variables, options);
  }, [currentCompanyId, updateQuestion]);

  return [questionMutation, ...rest] as const;
};

export const useCreateCategory = () => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId();
  const [createCategory, ...rest] = useMutation(api.createPreQualCategory);

  const questionMutation = useCallback((values: CategoryModalValues, options?: MutateOptions<
    { _id: string; },
    unknown,
    { currentCompanyId: string; name: string; },
    unknown
  >) => {
    const variables = {
      currentCompanyId,
      ...values,
    } as const;

    // @ts-expect-error ts(2345) FIXME: Argument of type '{ readonly name: string; readonly currentCompanyId: string | null; }' is not assignable to parameter of type '{ currentCompanyId: string; name: string; }'.
    return createCategory(variables, options);
  }, [currentCompanyId, createCategory]);

  return [questionMutation, ...rest] as const;
};

export const useUpdateCategory = () => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId();
  const [updateCategory, ...rest] = useMutation(api.updatePreQualCategory);

  const questionMutation = useCallback((categoryId: string, values: CategoryModalValues, options?: MutateOptions<
    { _id: string; },
    unknown,
    { currentCompanyId: string; name: string; categoryId: string; },
    unknown
  >) => {
    const variables = {
      currentCompanyId,
      categoryId,
      ...values,
    } as const;

    // @ts-expect-error ts(2345) FIXME: Argument of type '{ readonly name: string; readonly currentCompanyId: string | null; readonly categoryId: string; }' is not assignable to parameter of type '{ currentCompanyId: string; categoryId: string; name: string; }'.
    return updateCategory(variables, options);
  }, [currentCompanyId, updateCategory]);

  return [questionMutation, ...rest] as const;
};

export const useDeleteCategory = () => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId();
  const [deleteCategory, ...rest] = useMutation(api.deletePreQualCategory);

  const mutation = useCallback((categoryId: string, replacementCategoryId?: string, options?: MutateOptions<
    boolean,
    unknown,
    { currentCompanyId: string; categoryId: string; replacementCategoryId?: string; },
    unknown
  >) => {
    const variables = {
      currentCompanyId,
      categoryId,
      replacementCategoryId,
    } as const;

    // @ts-expect-error ts(2345) FIXME: Argument of type '{ readonly currentCompanyId: string | null; readonly categoryId: string; readonly replacementCategoryId: string | undefined; }' is not assignable to parameter of type '{ currentCompanyId: string; categoryId: string; replacementCategoryId?: string | undefined; }'.
    return deleteCategory(variables, options);
  }, [currentCompanyId, deleteCategory]);

  return [mutation, ...rest] as const;
};

export const useCheckCategoryName = () => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId();
  const queryClient = useQueryClient();

  return useCallback((name: string) => {
    return queryClient.fetchQuery(
      [CHECK_CATEGORY_NAME_KEY, { currentCompanyId, name }],
      wrap(api.checkPreQualCategoryName),
    );
  }, [api, currentCompanyId, queryClient]);
};

export enum PreQualTab {
  ACTIVE_QUESTIONNAIRES = 'active',
  DRAFT_QUESTIONNAIRES = 'draft',
  RECEIVED_QUESTIONNAIRES = 'received',
  ALL_CATEGORIES = 'allCategories',
  ARCHIVE_QUESTIONS = 'archiveQuestions',
  ARCHIVE_QUESTIONNAIRE_TEMPLATES = 'archiveQuestionnaireTemplates',
}

export const QUESTIONNAIRE_TABS = [
  PreQualTab.ACTIVE_QUESTIONNAIRES,
  PreQualTab.DRAFT_QUESTIONNAIRES,
  PreQualTab.RECEIVED_QUESTIONNAIRES,
];

export const ARCHIVE_TABS = [
  PreQualTab.ARCHIVE_QUESTIONS,
  PreQualTab.ARCHIVE_QUESTIONNAIRE_TEMPLATES,
];

export const EXPANDABLE_SUPPLIER_ROW_HEIGHT = 74;
