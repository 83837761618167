import { first } from 'lodash';
import { useCallback, useMemo } from 'react';
import { ExtendedSupplierListOverview, SupplierListType } from '@deepstream/common';
import { stepComponentByType } from './steps';
import { ListConfigFlowData, ListConfigFlowStep, ListConfigFlowStepType } from './types';
import { MultiStepFlow } from '../../../ui/MultiStepFlow/MultiStepFlow';

const staticStartSteps = [
  { type: ListConfigFlowStepType.NAME_AND_DESCRIPTION },
  { type: ListConfigFlowStepType.LIST_TYPE },
];

const staticFinalSteps = [
  { type: ListConfigFlowStepType.REVIEW },
  { type: ListConfigFlowStepType.SUCCESS },
];

const getListTypeSteps = (listType: SupplierListType) => {
  switch (listType) {
    case SupplierListType.AUTOMATIC:
      return [
        { type: ListConfigFlowStepType.AUTOMATIC_LIST_RULES },
      ];
    case SupplierListType.MANUAL:
      return [
        { type: ListConfigFlowStepType.ADD_SUPPLIERS },
      ];
    default:
      return [];
  }
};

const getAllSteps = (data: ListConfigFlowData) => {
  return [
    ...staticStartSteps,
    ...getListTypeSteps(data.listType),
    ...staticFinalSteps,
  ];
};

export const ListConfigFlow = ({ supplierList }: { supplierList?: ExtendedSupplierListOverview }) => {
  // @ts-expect-error ts(2322) FIXME: Type '{ steps: { type: ListConfigFlowStepType; }[]; currentStep: { type: ListConfigFlowStepType; } | undefined; _id: string | undefined; name: string; description: string; listType: SupplierListType | null; rules: ExtendedSupplierListRule[]; suppliers: ExtendedSupplierListSupplier[]; }' is not assignable to type 'ListConfigFlowData'.
  const initialData: ListConfigFlowData = useMemo(() => ({
    steps: staticStartSteps,
    currentStep: first(staticStartSteps),
    _id: supplierList?._id,
    name: supplierList?.name || '',
    description: supplierList?.description || '',
    listType: supplierList?.listType || null,
    rules: supplierList?.rules || [],
    suppliers: supplierList?.suppliers || [],
  }), [supplierList]);

  const canLeaveFlow = useCallback(
    (data: ListConfigFlowData) => data.currentStep.type === ListConfigFlowStepType.SUCCESS,
    [],
  );

  return (
    <MultiStepFlow<
      ListConfigFlowStepType,
      ListConfigFlowStep,
      ListConfigFlowData
    >
      initialData={initialData}
      stepComponentByType={stepComponentByType}
      getAllSteps={getAllSteps}
      canLeaveFlow={canLeaveFlow}
    />
  );
};
