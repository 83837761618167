/* eslint-disable react-hooks/rules-of-hooks */

import { useCallback, useState, useEffect, useMemo, useRef } from 'react';

import * as React from 'react';
import { set, clone, mapValues, groupBy, map, defaults, sortBy, partition, without, compact, keyBy } from 'lodash';
import { Box, Flex } from 'rebass/styled-components';
import { Formik, Form, useFormikContext } from 'formik';
import * as yup from 'yup';
import { CompanyMinimized } from '@deepstream/common/rfq-utils';
import { AnswerSet, AccessRequest } from '@deepstream/common/legacy-pre-q-utils';
import { useTranslation } from 'react-i18next';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Modal, ModalHeader, ModalBody, ModalFooter, DoneButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Row } from '../ui/ProfileLayout';
import { Disclosure } from '../ui/Disclosure';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '../ui/Tabs';
import { ApprovedCompanyList } from './ApprovedCompanyList';
import { AccessRequestList } from './AccessRequestList';
import { AccessRequestsCounter } from './AccessRequestCounter';
import { CompanyFinderField } from '../form/CompanyFinderField';
import { SystemFeatureFlags } from '../types';
import { Bold } from '../Bold';

const SubmitButton = ({ label, disabled }) => {
  const approveButtonRef = useRef<HTMLButtonElement>(null);
  const { values: { company } } = useFormikContext<{ company: CompanyMinimized }>();

  // If the button is transitioning from disabled -> enabled after a company has been
  // selected we should focus the submit button.
  useWatchValue(
    disabled,
    (disabled) => {
      if (!disabled && company && approveButtonRef.current) {
        approveButtonRef.current.focus();
      }
    },
  );

  return (
    <Button type="submit" ref={approveButtonRef} iconLeft="check" disabled={disabled}>
      {label}
    </Button>
  );
};

const ApprovedCompanyDisclosure = () => {
  const { t } = useTranslation('companyProfile');

  return (
    <Disclosure summary={t('questionnaire.visibility.approvedCompanyDisclosure.label')}>
      {t('questionnaire.visibility.approvedCompanyDisclosure.description')}
    </Disclosure>
  );
};

type AccessRequestsByStatus = Record<AccessRequest['status'], AccessRequest[]>;

type AccessRequestDecision = 'approved' | 'denied';

type EditVisibilityModalProps = {
  answerSet: AnswerSet;
  isOpen: boolean;
  searchCompanies: any;
  onClose: any;
  approvePreQualAccess: any;
  denyPreQualAccess: any;
  revokePreQualAccess: any;
  systemFeatureFlags?: SystemFeatureFlags;
};

export const EditVisibilityModal: React.FC<EditVisibilityModalProps> = ({
  answerSet,
  searchCompanies,
  isOpen,
  onClose,
  approvePreQualAccess,
  denyPreQualAccess,
  revokePreQualAccess,
  systemFeatureFlags,
}) => {
  const { t } = useTranslation(['companyProfile', 'general']);

  // Approvals that have occurred while the modal has been open
  const [recentlyApprovedCompanyIds, setRecentlyApprovedCompanyIds] = useState<string[]>([]);

  // Contains decisions (ie: approved/denied) that have occurred for
  // *pending* access requests while the modal has been open
  const [accessRequestDecisions, setAccessRequestDecisions] =
    useState<Record<string, AccessRequestDecision>>({});

  // Reset state when modal is closed
  useEffect(
    () => {
      if (!isOpen) {
        setAccessRequestDecisions({});
        setRecentlyApprovedCompanyIds([]);
      }
    },
    [isOpen],
  );

  const companiesByStatus = useMemo(
    () => {
      const accessRequestsByStatus = defaults(
        groupBy(answerSet.accessRequests, 'status') as AccessRequestsByStatus,
        { approved: [], denied: [], pending: [] } as AccessRequestsByStatus,
      );

      return mapValues(
        accessRequestsByStatus,
        accessRequests => map(accessRequests, 'company'),
      );
    },
    [answerSet],
  );

  // Companies that have been approved within the lifetime of the modal should
  // appear at the top of the list
  const approvedCompaniesOrdered = useMemo(
    () => {
      const [recentlyApproved, previouslyApproved] = partition(
        companiesByStatus.approved,
        company => recentlyApprovedCompanyIds.includes(company._id),
      );

      const recentlyApprovedById = keyBy(recentlyApproved, '_id');

      return [
        ...compact(map(recentlyApprovedCompanyIds, companyId => recentlyApprovedById[companyId])),
        ...sortBy(previouslyApproved, company => company.name.toLowerCase()),
      ];
    },
    [companiesByStatus, recentlyApprovedCompanyIds],
  );

  const setAccessRequestDecision = useCallback(
    (company: CompanyMinimized, decision: AccessRequestDecision) => {
      setAccessRequestDecisions(decisions => set(clone(decisions), company._id, decision));
    },
    [setAccessRequestDecisions],
  );

  const approveCompany = useCallback(
    async (company: CompanyMinimized) => {
      // Important that we update recently approved ids before any async
      // logic or the list item entry animation will break. We need to
      // ensure that we've have recently approved id when the component
      // updates with a new `answerSet` (which is impossible to do with
      // the model alone).
      setRecentlyApprovedCompanyIds(ids => [company._id, ...ids]);

      if (answerSet.accessRequests[company._id]?.status === 'pending') {
        setAccessRequestDecision(company, 'approved');
      }

      await approvePreQualAccess(company._id);
    },
    [answerSet, approvePreQualAccess, setAccessRequestDecision],
  );

  const denyCompany = useCallback(
    async (company) => {
      if (answerSet.accessRequests[company._id]?.status === 'pending') {
        setAccessRequestDecision(company, 'denied');
      }

      await denyPreQualAccess(company._id);
    },
    [answerSet, denyPreQualAccess, setAccessRequestDecision],
  );

  const revokeCompanyAccess = useCallback(
    async (company) => {
      await revokePreQualAccess(company._id);

      setRecentlyApprovedCompanyIds(ids => without(ids, company._id));
    },
    [revokePreQualAccess],
  );

  return (
    <Modal isOpen={isOpen}>
      {/*
       // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'. */}
      <ModalHeader onClose={onClose} divider={systemFeatureFlags.newPreQualEnabled}>
        {t('questionnaire.visibility.visibility')}
      </ModalHeader>
      {/*
       // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'. */}
      {systemFeatureFlags.newPreQualEnabled ? (
        <ModalBody maxWidth={500}>
          <Stack gap={3}>
            <Bold>
              {t('questionnaire.visibility.approvedCompanies')}
              {' '}
              ({approvedCompaniesOrdered.length})
            </Bold>
            <ApprovedCompanyList
              approvedCompanies={approvedCompaniesOrdered}
              revokeCompanyAccess={revokeCompanyAccess}
            />
            <ApprovedCompanyDisclosure />
          </Stack>
        </ModalBody>
      ) : (
        <Tabs>
          <TabList style={{ backgroundColor: 'white' }}>
            <Tab>{t('questionnaire.visibility.approvedCompanies')}</Tab>
            <Tab>
              <Flex alignItems="center">
                {t('questionnaire.visibility.accessRequests')}
                <AccessRequestsCounter answerSet={answerSet} ml={1} />
              </Flex>
            </Tab>
          </TabList>
          <ModalBody p={0} maxWidth={500}>
            <TabPanels>
              <TabPanel>
                <Stack gap={3} p={3}>
                  <Formik<{ companyInput: string; company: CompanyMinimized | null }>
                    initialValues={{
                      companyInput: '',
                      company: null,
                    }}
                    validateOnBlur
                    validationSchema={
                      yup.object().shape({
                        companyInput: yup.string(),
                        company: yup.object().required(t('required', { ns: 'general' })).shape({
                          _id: yup.string().required(t('required', { ns: 'general' })),
                          name: yup.string().required(t('required', { ns: 'general' })),
                        }),
                      })
                    }
                    onSubmit={async ({ company }, { resetForm, setSubmitting }) => {
                      try {
                        await approveCompany(company!);
                        resetForm();
                      } finally {
                        setSubmitting(false);
                      }
                    }}
                  >
                    {({ isSubmitting, dirty, isValid }) => (
                      <Form>
                        <Row>
                          <Box flex={1} mr={2}>
                            <CompanyFinderField
                              name="company"
                              currentCompanyId={answerSet.company._id}
                              searchCompanies={searchCompanies}
                              disabledCompanies={companiesByStatus.approved}
                              placeholder={t('questionnaire.visibility.searchForACompany')}
                            />
                          </Box>
                          <SubmitButton
                            label={t('questionnaire.visibility.approve')}
                            disabled={isSubmitting || !isValid || !dirty}
                          />
                        </Row>
                      </Form>
                    )}
                  </Formik>
                  <ApprovedCompanyList
                    approvedCompanies={approvedCompaniesOrdered}
                    revokeCompanyAccess={revokeCompanyAccess}
                  />
                  <ApprovedCompanyDisclosure />
                </Stack>
              </TabPanel>
              <TabPanel>
                <Stack gap={3} p={3}>
                  <AccessRequestList
                    accessRequests={answerSet.accessRequests}
                    accessRequestDecisions={accessRequestDecisions}
                    approveCompany={approveCompany}
                    denyCompany={denyCompany}
                  />
                  <Disclosure summary={t('questionnaire.visibility.accessRequestDisclosure.label')}>
                    {t('questionnaire.visibility.accessRequestDisclosure.description')}
                  </Disclosure>
                </Stack>
              </TabPanel>
            </TabPanels>
          </ModalBody>
        </Tabs>
      )}
      <ModalFooter>
        <DoneButton onClick={onClose} />
      </ModalFooter>
    </Modal>
  );
};
