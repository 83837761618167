import { useState } from 'react';
import { Live } from '@deepstream/common/rfq-utils';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { DateFormat } from '@deepstream/utils';
import { useInterval } from '@deepstream/ui-kit/hooks/useInterval';
import { TFunction } from 'i18next';
import { ONE_DAY, ONE_HOUR, ONE_MINUTE, ONE_SECOND } from '@deepstream/common/constants';
import * as rfx from '../../../rfx';
import * as lotPagesLayout from '../Live/lotPagesLayout';
import { Datetime2 } from '../../../Datetime';
import { ProgressBar } from '../../../ui/ProgressBar';

const formatDuration = (durationMs: number, t: TFunction) => {
  const fullDays = Math.floor(durationMs / ONE_DAY);

  if (fullDays) {
    const remainingMs = durationMs - (fullDays * ONE_DAY);
    const fullHours = Math.floor(remainingMs / ONE_HOUR);

    return `${fullDays}${t('general.dayLetter')} ${fullHours}${t('general.hourLetter')}`;
  }

  const fullHours = Math.floor(durationMs / ONE_HOUR);

  if (fullHours) {
    const remainingMs = durationMs - (fullHours * ONE_HOUR);
    const fullMinutes = Math.floor(remainingMs / ONE_MINUTE);

    return `${fullHours}${t('general.hourLetter')} ${fullMinutes}${t('general.minuteLetter')}`;
  }

  const fullMinutes = Math.floor(durationMs / ONE_MINUTE);
  const remainingMs = durationMs - (fullMinutes * ONE_MINUTE);
  const fullSeconds = Math.floor(remainingMs / ONE_SECOND);

  return `${fullMinutes}${t('general.minuteLetter')} ${fullSeconds}${t('general.secondLetter')}`;
};

export const EnteredStageTimeline = () => {
  const { t } = useTranslation('translation');
  const { stageById } = rfx.useStructure<Live>();
  const stageId = rfx.useStageId();
  const { enterDateByStageId } = rfx.useBid();

  const enterDate = enterDateByStageId[stageId];
  // @ts-expect-error ts(2538) FIXME: Type 'null' cannot be used as an index type.
  const { completionDeadline } = stageById[stageId];

  const [now, setNow] = useState<Date>(new Date());

  useInterval(() => setNow(new Date()), 250);

  const totalMs = new Date(completionDeadline).valueOf() - new Date(enterDate).valueOf();
  const elapsedMs = Math.min(totalMs, now.valueOf() - new Date(enterDate).valueOf());
  const remainingMs = Math.max(0, totalMs - elapsedMs);

  const progress = totalMs ? elapsedMs / totalMs : 0;

  return (
    <Flex width="100%" justifyContent="stretch">
      <Box flex="0 0 auto">
        <Text fontWeight={500}>
          {t('request.stages.stageEntered')}
        </Text>
        <Text color="subtext" fontSize={1} lineHeight={1.25}>
          <Datetime2 value={enterDate} format={DateFormat.DD_MMM_YYYY} />
        </Text>
        <Text color="subtext" fontSize={1} lineHeight={1.25}>
          <Datetime2 value={enterDate} format={DateFormat.HH_MM_A_ZZZ} />
        </Text>
      </Box>
      <Box flex={1} mx={3} mt="7px">
        <ProgressBar value={progress * 100} variant="info" showValue={false} />
        <Flex width="100%" justifyContent="space-between" mt="7px" px={3}>
          <Box>
            {elapsedMs > 0 ? (
              t('request.stages.durationElapsed', {
                duration: formatDuration(elapsedMs, t),
              })
            ) : (
              null
            )}
          </Box>
          <Box
            color={remainingMs === 0 || remainingMs > ONE_DAY ? (
              null
            ) : remainingMs > ONE_HOUR ? (
              'warning'
            ) : (
              'danger'
            )}
          >
            {remainingMs === 0 ? (
              t('request.stages.status.deadlinePassed.status')
            ) : (
              t('request.stages.durationRemaining', {
                duration: formatDuration(remainingMs, t),
              })
            )}
          </Box>
        </Flex>
      </Box>
      <Box flex="0 0 auto" textAlign="right">
        <Text fontWeight={500}>
          {t('request.stages.stageDeadline')}
        </Text>
        <Text color="subtext" fontSize={1} lineHeight={1.25}>
          <Datetime2 value={completionDeadline} format={DateFormat.DD_MMM_YYYY} />
        </Text>
        <Text color="subtext" fontSize={1} lineHeight={1.25}>
          <Datetime2 value={completionDeadline} format={DateFormat.HH_MM_A_ZZZ} />
        </Text>
      </Box>
    </Flex>
  );
};

export const EnteredStageTimelineSection = () => {
  const { t } = useTranslation('translation');

  return (
    <lotPagesLayout.Section heading={t('general.timeline')}>
      <Box mt="12px">
        <EnteredStageTimeline />
      </Box>
    </lotPagesLayout.Section>
  );
};
