import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { withProps } from '@deepstream/ui-utils/withProps';
import { BasicTableStyles } from '../../../TableStyles';
import { Table } from '../../../Table';
import { DateFieldValue, FieldsCell, MIN_CELL_HEIGHT, PercentageFieldValue } from '../../../FieldsCell';
import { QuestionnaireStatusFieldValue, QuestionnaireStatusIconCell } from './QuestionnaireStatus';
import { useQuestionnaireNavigation } from '../../../appNavigation';

export const ReceivedQuestionnairesTable = () => {
  const { t } = useTranslation(['preQualification', 'general']);
  const questionnaireNavigation = useQuestionnaireNavigation();

  const onRowClick = useCallback((row) => {
    const id = row._id;
    questionnaireNavigation.navigateToQuestions(id);
  }, [questionnaireNavigation]);

  const columns = useMemo(
    () => [{ id: 'custom' }],
    [],
  );

  const CustomRowCells = useMemo(
    () => {
      const fields = [
        {
          label: t('questionnaire.buyer', { count: 1 }),
          accessor: 'senders.0.name',
        },
        {
          label: t('numberOfQuestions'),
          accessor: 'numRequirements',
        },
        {
          label: t('status', { ns: 'general' }),
          accessor: 'status',
          FieldValueComponent: withProps(QuestionnaireStatusFieldValue, { isRecipient: true }),
        },
        {
          label: t('questionnaire.completion'),
          accessor: 'completion',
          FieldValueComponent: withProps(PercentageFieldValue, { decimalPlaces: 0 }),
        },
        {
          label: t('questionnaireRenewal.renewal'),
          accessor: 'renewalDate',
          FieldValueComponent: DateFieldValue,
        },
        {
          label: t('questionnaireRenewal.expiry'),
          accessor: 'expiryDate',
          FieldValueComponent: DateFieldValue,
        },
      ];

      return withProps(
        FieldsCell,
        {
          fields,
          titleAccessor: 'name',
          RowIconComponent: QuestionnaireStatusIconCell,
        },
      );
    },
    [t],
  );

  return (
    <BasicTableStyles hoverBackgroundColor="lightGray6">
      <Table
        columns={columns}
        isPaginated
        pageSizes={[5, 10, 20, 50, 100]}
        hideHeader
        CustomRowCells={CustomRowCells}
        hasStaticHeight={false}
        enforcePagination
        minCellHeight={MIN_CELL_HEIGHT}
        smallPageControls
        onRowClick={onRowClick}
      />
    </BasicTableStyles>
  );
};
