import { useTranslation } from 'react-i18next';
import { IconText, IconTextProps } from '@deepstream/ui-kit/elements/text/IconText';

export const ConfirmFinalValuePlaceholder = (props: Partial<IconTextProps>) => {
  const { t } = useTranslation('translation');

  return (
    <IconText
      icon="exclamation-circle"
      text={t('request.spendAndSavings.confirmFinalValueFirst')}
      iconColor="danger"
      {...props}
    />
  );
};
