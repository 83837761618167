import * as React from 'react';
import * as Sentry from '@sentry/react';
import { Text } from 'rebass/styled-components';
import { QuestionType, DateTimeQuestionExchangeDefinition, QuestionExchangeDefinition } from '@deepstream/common/rfq-utils';
import { UnexpectedStateError } from '@deepstream/errors';
import { useTranslation } from 'react-i18next';

export const DateTime = ({
  exchangeDef,
  fieldMaxWidth,
}: {
  exchangeDef: QuestionExchangeDefinition;
  fieldMaxWidth?: React.CSSProperties['maxWidth'];
}) => {
  const { t } = useTranslation();
  const { questionType, format } = exchangeDef as DateTimeQuestionExchangeDefinition;

  if (questionType !== QuestionType.DATE_TIME) {
    Sentry.captureException(new UnexpectedStateError('[DateTime] Wrong question type passed', { questionType }));
    return null;
  }

  return (
    <Text maxWidth={fieldMaxWidth} sx={{ borderRadius: 'small', border: 'lightGray2', px: 2, py: 1, display: 'block' }}>
      {t(`request.question.datetimeFormat.${format}`)}
    </Text>
  );
};
