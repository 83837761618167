import { useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { noop } from 'lodash';
import { Form, Formik } from 'formik';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData } from '../types';
import { SwitchField } from '../../../../form/SwitchField';
import { Direction } from '../../../../ui/MultiStepFlow/types';

export const SpendAndSavingsStatusStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (data: Partial<AwardFlowData> | null, direction: Direction) => void,
}) => {
  const { t } = useTranslation('translation');

  return (
    <Formik
      initialValues={data.spendAndSavings}
      onSubmit={noop}
    >
      {({ values, dirty }) => (
        <Form>
          <lotPagesLayout.ContentWrapper>
            <StepNavigation
              onBackClick={() => submitAndNavigate(dirty ? { spendAndSavings: values } : null, Direction.BACK)}
              onContinueClick={() => submitAndNavigate(dirty ? { spendAndSavings: values } : null, Direction.FORWARD)}
            >
              <lotPagesLayout.Section heading={t('request.awardFlow.steps.spendAndSavingsStatus.heading')}>
                <Text mt="12px">
                  {t('request.awardFlow.steps.spendAndSavingsStatus.info')}
                </Text>
                <Box mt="20px">
                  <SwitchField
                    name="enabled"
                    hideLabel
                    aria-label={`${t('request.spendAndSavings.spendAndSavings')} - ${t('general.enabled')}?`}
                    checkedText={t('general.enabled')}
                    uncheckedText={t('general.disabled')}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    width={42}
                  />
                </Box>
              </lotPagesLayout.Section>
            </StepNavigation>
          </lotPagesLayout.ContentWrapper>
        </Form>
      )}
    </Formik>
  );
};
