import { FieldType } from '@deepstream/common/exchangesConfig';
import { useMemo, useCallback } from 'react';
import { filter, includes, map, isFunction, find, sortBy } from 'lodash';
import {
  FieldConfig,
  isDefinitionField,
  isReplyField, ExchangeType, isFieldOnlyVisibleToEvaluator, isFieldHiddenFromBuyerWhenLocked,
  getFieldIdsInDefaultDisplayOrder,
} from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { DateFormat } from '@deepstream/utils';
import { ContextType, useHooks } from '../../useHooks';
import { Datetime2Cell } from '../../DatetimeCell';
import { NumberCell2, TruncateOrDashCell, UnspscCodeCell, BooleanResponseCell } from '../../draft/cell';
import { BuyerOrSupplierCurrencyAmountCell } from '../Currency';
import { isRole } from '../../lock';

import {
  DateInputCell,
  TextInputCell,
  NumberInputCell, BooleanInputCell,
} from './inputCell';
import {
  DateValueCell,
  TextValueCell,
  NumberValueCell,
  DisabledCell,
  UnspscCodeValueCell,
  FormulaValueCell,
} from './validationAwareValueCell';
import {
  SupplierTextValueCell,
  SupplierProvidedValueCell,
  SupplierNumberValueCell,
} from './nonValidationAwareValueCell';
import { CurrencyExchangeSnapshot } from '../../types';
import { withLock } from '../../modules/Exchange/withLock';

export const hiddenFieldIds = [
  'currency',
  'evaluatorFieldCurrency',
];

const orderColumns = <TColumn extends { _id: string }>(columns: TColumn[], orderedFieldIds?: string[]): TColumn[] => {
  const orderedColumnIds = (
    orderedFieldIds ||
    getFieldIdsInDefaultDisplayOrder(columns.map(column => column._id))
  );

  return sortBy(
    columns,
    column => orderedColumnIds.indexOf(column._id),
  );
};

export const useSupplierCurrencyCode = () => {
  const { contextType, useExchanges, useSection } = useHooks();
  const section = useSection();
  const exchanges = useExchanges({ required: false });
  // The preview submitted value is available (and doable*) only for contracts
  // * Since there are multiple suppliers we are not able to show the submitted value from each supplier
  if (contextType !== ContextType.CONTRACT) return null;

  const currencyExchange = find(exchanges, (exchange): exchange is CurrencyExchangeSnapshot => {
    return exchange.def.type === ExchangeType.CURRENCY && exchange.def.sectionId === section._id;
  });

  return currencyExchange?.latestCurrency || '';
};

const useExchangeBidColumnGenerator = ({
  columnProps,
}: {
  columnProps?: ((field: FieldConfig) => object) | object;
}) => {
  const { t } = useTranslation();

  return useCallback((field: FieldConfig): EditableGridColumn => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { _id, type, label, decimalPlaces = 2, source } = field;
    const typeSpecificProps: Partial<EditableGridColumn> = {};
    const sourceSpecificProps: Partial<EditableGridColumn> = {};

    const [fieldsetId] = _id.split(':');

    switch (type) {
      case 'string':
        typeSpecificProps.InputCell = TextInputCell;
        typeSpecificProps.ValueCell = TextValueCell;

        break;

      case 'number':
        typeSpecificProps.InputCell = NumberInputCell;
        typeSpecificProps.ValueCell = NumberValueCell;
        break;

      case 'price':
        typeSpecificProps.InputCell = NumberInputCell;
        typeSpecificProps.ValueCell = NumberValueCell;
        typeSpecificProps.format = 'money.positive';
        typeSpecificProps.decimalPlaces = decimalPlaces;
        break;

      case 'date':
        typeSpecificProps.InputCell = DateInputCell;
        typeSpecificProps.ValueCell = DateValueCell;
        typeSpecificProps.format = DateFormat.DD_MMM_YYYY;
        break;

      case 'unspscCode':
        typeSpecificProps.ValueCell = UnspscCodeValueCell;
        break;

      case 'boolean':
        typeSpecificProps.InputCell = BooleanInputCell;
        typeSpecificProps.ValueCell = BooleanInputCell;
        break;

      default:
        typeSpecificProps.InputCell = TextInputCell;
        typeSpecificProps.ValueCell = TextValueCell;
        break;
    }

    if (isDefinitionField(field)) {
      sourceSpecificProps.disabled = true;
      sourceSpecificProps.accessorKey = 'key' in field.source ? `def.${field.source.key}` : undefined;

      if (type === FieldType.PRICE) {
        sourceSpecificProps.prefix = (exchangeDef) => exchangeDef.evaluatorFieldCurrency;
      }
    } else if (source.type === 'reply') {
      sourceSpecificProps.accessorKey = 'key' in field.source ? `latestReply.${field.source.key}` : undefined;
    } else if (source.type === 'formula') {
      sourceSpecificProps.accessorKey = 'formula' in field.source ? `def.fields.${field._id}.source.formula` : undefined;
      sourceSpecificProps.disabled = true;
      sourceSpecificProps.ValueCell = FormulaValueCell;
    }

    return {
      _id,
      accessorKey: 'key' in field.source ? field.source.key : undefined,
      fieldType: source.type === 'formula' ? FieldType.FORMULA : field.type,
      label: label || t(`request.fields.predefinedFieldLabel.${fieldsetId}`),
      ...typeSpecificProps,
      ...sourceSpecificProps,
      ...(isFunction(columnProps) ? columnProps(field) : columnProps || {}),
    } as EditableGridColumn;
  }, [t, columnProps]);
};

export const useConfigurableExchangeBidFieldColumns = ({
  fields,
  orderedFieldIds,
  columnProps,
}: {
  fields?: Record<string, FieldConfig>;
  orderedFieldIds?: string[];
  columnProps?: ((field: FieldConfig) => object) | object;
}) => {
  const exchangeDefColumnGenerator = useExchangeBidColumnGenerator({ columnProps });

  return useMemo(() => {
    const filteredFields = filter(fields, (field, key) => (
      !includes(hiddenFieldIds, key) &&
      !isFieldOnlyVisibleToEvaluator(field)
    ));

    const columns = map(filteredFields, exchangeDefColumnGenerator);

    return orderColumns(columns, orderedFieldIds);
  }, [
    exchangeDefColumnGenerator,
    fields,
    orderedFieldIds,
  ]);
};

const useExchangeDefColumnGenerator = ({
  columnProps,
  isEditingSupplierExchangeDefs,
}: {
  columnProps?: ((field: FieldConfig) => object) | object;
  isEditingSupplierExchangeDefs?: boolean,
}) => {
  const { t } = useTranslation();
  const { contextType } = useHooks();
  const supplierCurrencyCode = useSupplierCurrencyCode();

  return useCallback((field: FieldConfig): EditableGridColumn => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { _id, type, label, decimalPlaces = 2, source, required } = field;
    const typeSpecificProps: Partial<EditableGridColumn> = {};
    const sourceSpecificProps: Partial<EditableGridColumn> = {};

    const [fieldsetId] = _id.split(':');

    switch (type) {
      case 'string':
        typeSpecificProps.InputCell = TextInputCell;
        typeSpecificProps.ValueCell = isEditingSupplierExchangeDefs
          ? SupplierTextValueCell
          : TextValueCell;

        break;

      case 'number':
        typeSpecificProps.InputCell = NumberInputCell;
        typeSpecificProps.ValueCell = isEditingSupplierExchangeDefs
          ? SupplierNumberValueCell
          : NumberValueCell;
        break;

      case 'price':
        typeSpecificProps.InputCell = NumberInputCell;
        typeSpecificProps.ValueCell = NumberValueCell;
        typeSpecificProps.format = 'money.positive';
        typeSpecificProps.decimalPlaces = decimalPlaces;
        break;

      case 'date':
        typeSpecificProps.InputCell = DateInputCell;
        typeSpecificProps.ValueCell = DateValueCell;
        typeSpecificProps.format = DateFormat.DD_MMM_YYYY;
        break;

      case 'unspscCode':
        typeSpecificProps.ValueCell = UnspscCodeValueCell;
        break;

      default:
        typeSpecificProps.InputCell = TextInputCell;
        typeSpecificProps.ValueCell = isEditingSupplierExchangeDefs
          ? SupplierTextValueCell
          : TextValueCell;
        break;
    }

    if (isDefinitionField(field)) {
      sourceSpecificProps.required = required;

      if (isFieldOnlyVisibleToEvaluator(field)) {
        sourceSpecificProps.description = t('request.lineItems.internalOnly');
        sourceSpecificProps.descriptionIcon = <Icon icon="eye-slash" mr={1} />;
      } else {
        sourceSpecificProps.description = isEditingSupplierExchangeDefs
          ? t('supplier', { count: 1, ns: 'general' })
          : t('buyer', { ns: 'general' });
      }

      if (type === FieldType.PRICE) {
        sourceSpecificProps.prefix = (exchangeDef) => exchangeDef.evaluatorFieldCurrency;
      }
    } else if (isReplyField(field)) {
      sourceSpecificProps.ValueCell = DisabledCell;
      if (field.source.role === 'submitter') {
        // We need to show supplier submitted value only on contract
        if (contextType === ContextType.CONTRACT) {
          sourceSpecificProps.ValueCell = SupplierProvidedValueCell;
          if (type === 'price') {
            sourceSpecificProps.prefix = supplierCurrencyCode;
          }
        } else {
          sourceSpecificProps.staticValue = type === 'boolean'
            ? t('request.lineItems.supplierToAcceptOrReject')
            : t('request.lineItems.supplierToProvide');
          sourceSpecificProps.ValueCell = DisabledCell;
        }

        sourceSpecificProps.description = t('supplier', { count: 1, ns: 'general' });
      } else {
        sourceSpecificProps.staticValue = t('request.lineItems.buyerToProvide');
        sourceSpecificProps.description = t('buyer', { ns: 'general' });
      }
      sourceSpecificProps.startEditingCell = null;
    } else if (source.type === 'formula') {
      if (contextType === ContextType.CONTRACT) {
        sourceSpecificProps.ValueCell = SupplierProvidedValueCell;
        sourceSpecificProps.prefix = supplierCurrencyCode;
      } else {
        sourceSpecificProps.ValueCell = DisabledCell;
        sourceSpecificProps.staticValue = t('request.lineItems.formulaResult');
      }
    }

    return {
      _id,
      accessorKey: 'key' in field.source ? field.source.key : undefined,
      fieldType: source.type === 'formula' ? FieldType.FORMULA : field.type,
      label: label || t(`request.fields.predefinedFieldLabel.${fieldsetId}`),
      ...typeSpecificProps,
      ...sourceSpecificProps,
      ...(isFunction(columnProps) ? columnProps(field) : columnProps || {}),
    } as EditableGridColumn;
  }, [t, columnProps, isEditingSupplierExchangeDefs, contextType, supplierCurrencyCode]);
};

export const useConfigurableExchangeDefFieldColumns = ({
  fields,
  orderedFieldIds,
  columnProps,
  isSender,
  isEditingSupplierExchangeDefs,
}: {
  fields?: Record<string, FieldConfig>;
  orderedFieldIds?: string[];
  columnProps?: ((field: FieldConfig) => object) | object;
  isSender?: boolean,
  isEditingSupplierExchangeDefs?: boolean,
}) => {
  const exchangeDefColumnGenerator = useExchangeDefColumnGenerator({ columnProps, isEditingSupplierExchangeDefs });

  return useMemo(() => {
    const filteredFields = filter(fields, (field, key) => (
      !includes(hiddenFieldIds, key) &&
      !(isFieldOnlyVisibleToEvaluator(field) && !isSender)
    ));

    const columns = map(filteredFields, exchangeDefColumnGenerator);

    return orderColumns(columns, orderedFieldIds);
  }, [
    exchangeDefColumnGenerator,
    isSender,
    fields,
    orderedFieldIds,
  ]);
};

const useExchangeColumnGenerator = ({
  columnProps,
}: {
  columnProps?: ((field: FieldConfig) => object) | object;
}) => {
  return useCallback((field: FieldConfig) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { _id, type, decimalPlaces = 2 } = field;
    const typeSpecificProps: any = {};

    switch (type) {
      case 'string':
        typeSpecificProps.sortType = 'caseInsensitive';
        typeSpecificProps.Cell = TruncateOrDashCell;
        break;

      case 'number':
        typeSpecificProps.textAlign = 'right';
        typeSpecificProps.Cell = NumberCell2;
        break;

      case 'price':
        typeSpecificProps.Cell = BuyerOrSupplierCurrencyAmountCell;
        typeSpecificProps.textAlign = 'right';
        typeSpecificProps.decimalPlaces = decimalPlaces;
        break;

      case 'date':
        typeSpecificProps.sortType = 'datetime';
        typeSpecificProps.Cell = Datetime2Cell;

        typeSpecificProps.format = DateFormat.DD_MMM_YYYY;
        break;

      case 'boolean':
        typeSpecificProps.Cell = BooleanResponseCell;
        break;

      case 'unspscCode':
        typeSpecificProps.Cell = UnspscCodeCell;
        break;

      default:
        typeSpecificProps.Cell = TruncateOrDashCell;
        break;
    }

    return {
      _id,
      id: _id,
      accessorKey: 'key' in field.source ? field.source.key : undefined,
      fieldType: field.source.type === 'formula' ? FieldType.FORMULA : field.type,
      ...typeSpecificProps,
      ...(isFunction(columnProps) ? columnProps(field) : columnProps || {}),
    };
  }, [columnProps]);
};

const addLock = (column, field: FieldConfig) => {
  return isFieldHiddenFromBuyerWhenLocked(field) ? (
    withLock(column, { when: isRole('evaluator') })
  ) : (
    column
  );
};

export const useConfigurableExchangeFieldColumns = ({
  fields,
  orderedFieldIds,
  columnProps,
  fieldsFilter,
  addLocks,
}: {
  fields?: Record<string, FieldConfig>;
  orderedFieldIds?: string[];
  columnProps?: ((field: FieldConfig) => object) | object;
  fieldsFilter?: ((field: FieldConfig, key: string) => boolean) | null;
  addLocks?: boolean;
}) => {
  const exchangeColumnGenerator = useExchangeColumnGenerator({ columnProps });

  return useMemo(() => {
    const isFilteredField = fieldsFilter
      ? (field: FieldConfig, key: string) => fieldsFilter(field, key)
      : () => true;

    const genericFields = filter(
      fields,
      (field, key) => {
        return !includes(hiddenFieldIds, key) && isFilteredField(field, key);
      },
    );

    const columns = map(genericFields, exchangeColumnGenerator);

    const columnsWithLocks = addLocks
      ? columns.map((column, index) => {
        const field = genericFields[index];

        return addLock(column, field);
      })
      : columns;

    return orderColumns(columnsWithLocks, orderedFieldIds);
  }, [
    exchangeColumnGenerator,
    fields,
    fieldsFilter,
    addLocks,
    orderedFieldIds,
  ]);
};
