import { DraftStageApprovals } from '../StageApprovals/DraftStageApprovals';
import { DraftHeader } from './DraftHeader';
import * as Layout from '../../Layout';

export const DraftApprovalsPageContent = ({ rfqId }: { rfqId: string }) => {
  const tabId = 'approvals';

  return (
    <Layout.HeaderAndContent
      header={
        <DraftHeader
          rfqId={rfqId}
          isRevising={false}
          isTemplate={false}
          selectedTabId={tabId}
        />
      }
      content={
        <DraftStageApprovals rfqId={rfqId} />
      }
    />
  );
};
