import * as React from 'react';
import { useCurrentUserLocale } from '../useCurrentUser';

const startQuoteByLocale = {
  'en-GB': '“',
  'es-ES': '“',
  'pt-PT': '“',
  'fr-FR': '« ',
  'zh-CN': '“',
};

const endQuoteByLocale = {
  'en-GB': '”',
  'es-ES': '”',
  'pt-PT': '”',
  'fr-FR': ' »',
  'zh-CN': '”',
};

export const Quote = ({ children }: { children?: React.ReactNode }) => {
  const locale = useCurrentUserLocale();

  return (
    <>
      {startQuoteByLocale[locale]}
      {children}
      {endQuoteByLocale[locale]}
    </>
  );
};
