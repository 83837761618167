import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import { useNavigate } from '@tanstack/react-router';
import { noop } from 'lodash';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useCompanyFeatureFlags } from '../../companyFeatureFlags';
import { HiddenPagePlaceholder } from '../../HiddenPagePlaceholder';
import { useCurrentUser } from '../../useCurrentUser';
import { useLocalStorageState } from '../../useLocalStorageState';
import { useDeviceSize } from '../../ui/useDeviceSize';
import {
  reportingRequestsRoute,
  requestEditSpendRoute,
  requestSentSpendRoute,
  teamManagementRoute,
} from '../../AppRouting';
import {
  Tab,
  TabListPanel,
  TabPanels,
  TabPanel,
  Tabs,
} from '../../ui/TabsVertical';
import { SidebarLayout } from '../../ui/ProfileLayout';
import { AuctionsTabContent } from './AuctionsTabContent';
import { SavingsCards } from './SavingsCards';
import { TeamProductivityTabContent } from './TeamProductivityTabContent';
import { SupplierEngagementTabContent } from './SupplierEngagementTabContent';
import { RequestsReportingTab, tabsWithAuctions, tabsWithoutAuctions } from './requestsReportingTabs';
import { BudgetCards } from './BudgetCards';
import { ValueCards } from './ValueCards';
import * as dashboard from './dashboard';
import { RequestsReportingConfigControls, useRequestsReportingConfig } from './RequestsReportingConfig';
import { CurrencySelectFieldBase } from '../../form/CurrencySelectField';
import { BudgetTablePanel } from './BudgetTablePanel';
import { ValueTablePanel } from './ValueTablePanel';
import { SavingsTablePanel } from './SavingsTablePanel';
import { BlurredPagePlaceholderModal } from './BlurredPagePlaceholderModal';
import { useSystemFeatureFlags } from '../../systemFeatureFlags';
import { RequestsNavigationProvider } from '../../RequestsNavigationContext';

export const RequestsReportingTabs = ({ selectedTabId }: { selectedTabId?: string }) => {
  const { t } = useTranslation(['translation', 'reporting']);
  const { isExtraSmall, isSmall } = useDeviceSize();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const companyFeatureFlags = useCompanyFeatureFlags();
  const navigate = useNavigate();
  const { config, setters } = useRequestsReportingConfig();

  const tabs = companyFeatureFlags.auctionsEnabled
    ? tabsWithAuctions
    : tabsWithoutAuctions;

  const navigateToSenderRequest = useCallback(({ requestId, isDraft }: { requestId: string; isDraft?: boolean }) => {
    if (isDraft) {
      navigate({
        to: requestEditSpendRoute.to,
        params: { currentCompanyId, rfqId: requestId },
      });
    } else {
      navigate({
        to: requestSentSpendRoute.to,
        params: { currentCompanyId, rfqId: requestId },
      });
    }
  }, [currentCompanyId, navigate]);

  const navigateToTab = useCallback((tab: string, replace?: boolean) => {
    navigate({
      to: reportingRequestsRoute.to,
      params: { currentCompanyId },
      search: tab ? { tab } : undefined,
      replace,
    });
  }, [currentCompanyId, navigate]);

  const [lastActiveTab, setLastActiveTab] = useLocalStorageState<string>({
    key: `${currentCompanyId}.${currentUser._id}.requestsReporting.lastActiveTab`,
    defaultValue: tabs?.[0],
  });

  useEffect(
    () => {
      if (selectedTabId && selectedTabId !== lastActiveTab) {
        setLastActiveTab(selectedTabId);
      }
    },
    [selectedTabId, lastActiveTab, setLastActiveTab],
  );

  useEffect(
    () => {
      if (!selectedTabId) {
        navigateToTab(lastActiveTab || tabs[0], true);
      }
    },
    [tabs, selectedTabId, lastActiveTab, navigateToTab],
  );

  const onTabsChange = useCallback(
    (index) => {
      navigateToTab(tabs[index]);
    },
    [tabs, navigateToTab],
  );

  const selectedTabIndex = selectedTabId
    ? (tabs as string[]).indexOf(selectedTabId)
    : -1;

  return (
    <RequestsNavigationProvider
      navigateToSenderRequest={navigateToSenderRequest}
      navigateToReceivedRequest={noop}
      navigateToTemplatePreview={noop}
      navigateToTemplateEdit={noop}
      navigateToRequestsTab={noop}
    >
      <Tabs index={selectedTabIndex} onChange={onTabsChange}>
        <SidebarLayout
          sidebar={
            <>
              <TabListPanel heading={t('request.spendAndSavings.spendEtSavings')}>
                <Tab data-test="budget-tab" key={RequestsReportingTab.BUDGET}>
                  <Flex alignItems="center">
                    <Text flex={1}>{t('request.spendAndSavings.budget')}</Text>
                  </Flex>
                </Tab>
                <Tab data-test="value-tab" key={RequestsReportingTab.VALUE}>
                  <Flex alignItems="center">
                    <Text flex={1}>{t('request.spendAndSavings.value')}</Text>
                  </Flex>
                </Tab>
                <Tab data-test="savings-tab" key={RequestsReportingTab.SAVINGS}>
                  <Flex alignItems="center">
                    <Text flex={1}>{t('general.savings')}</Text>
                  </Flex>
                </Tab>
              </TabListPanel>
              <TabListPanel heading={t('request.spendAndSavings.other')} panelStyle={{ marginBottom: 3 }}>
                <Tab data-test="sent-tab" key={RequestsReportingTab.TEAM_PRODUCTIVITY}>
                  <Flex alignItems="center">
                    <Text flex={1}>{t('teamProductivity.heading', { ns: 'reporting' })}</Text>
                  </Flex>
                </Tab>
                <Tab data-test="received-tab" key={RequestsReportingTab.SUPPLIER_ENGAGEMENT}>
                  <Flex alignItems="center">
                    <Text flex={1}>{t('supplierEngagement.heading', { ns: 'reporting' })}</Text>
                  </Flex>
                </Tab>
                {companyFeatureFlags.auctionsEnabled && (
                  <Tab data-test="public-tab" key={RequestsReportingTab.AUCTIONS}>
                    <Flex alignItems="center">
                      <Text flex={1}>{t('auctions.heading', { ns: 'reporting' })}</Text>
                    </Flex>
                  </Tab>
                )}
              </TabListPanel>
            </>
          }
          main={
            // below, we render the tab contents only for the active
            // tabs to avoid data reloading in all tabs when the
            // user changes the reporting config
            <TabPanels>
              <TabPanel key={RequestsReportingTab.BUDGET}>
                {selectedTabId === RequestsReportingTab.BUDGET && (
                  <dashboard.Section
                    heading={t('request.spendAndSavings.budget')}
                    infoTooltip={t('request.spendAndSavings.budgetInfo')}
                    controls={<RequestsReportingConfigControls config={config} setters={setters} />}
                  >
                    <BudgetCards config={config} />
                    <BudgetTablePanel config={config} />
                  </dashboard.Section>
                )}
              </TabPanel>
              <TabPanel key={RequestsReportingTab.VALUE}>
                {selectedTabId === RequestsReportingTab.VALUE && (
                  <dashboard.Section
                    heading={t('request.spendAndSavings.value')}
                    infoTooltip={t('request.spendAndSavings.valueInfo')}
                    controls={<RequestsReportingConfigControls config={config} setters={setters} />}
                  >
                    <ValueCards config={config} />
                    <ValueTablePanel config={config} />
                  </dashboard.Section>
                )}
              </TabPanel>
              <TabPanel key={RequestsReportingTab.SAVINGS}>
                {selectedTabId === RequestsReportingTab.SAVINGS && (
                  <dashboard.Section
                    heading={t('general.savings')}
                    infoTooltip={t('request.spendAndSavings.savingsInfo')}
                    controls={<RequestsReportingConfigControls config={config} setters={setters} />}
                  >
                    <SavingsCards config={config} />
                    <SavingsTablePanel config={config} />
                  </dashboard.Section>
                )}
              </TabPanel>
              <TabPanel key={RequestsReportingTab.TEAM_PRODUCTIVITY}>
                {selectedTabId === RequestsReportingTab.TEAM_PRODUCTIVITY && (
                  <dashboard.Section
                    heading={t('teamProductivity.heading', { ns: 'reporting' })}
                    headingSx={{ marginBottom: '20px' }}
                  >
                    <TeamProductivityTabContent config={config} />
                  </dashboard.Section>
                )}
              </TabPanel>
              <TabPanel key={RequestsReportingTab.SUPPLIER_ENGAGEMENT}>
                {selectedTabId === RequestsReportingTab.SUPPLIER_ENGAGEMENT && (
                  <dashboard.Section
                    heading={t('supplierEngagement.heading', { ns: 'reporting' })}
                    headingSx={{ marginBottom: '20px' }}
                  >
                    <SupplierEngagementTabContent config={config} />
                  </dashboard.Section>
                )}
              </TabPanel>
              {companyFeatureFlags.auctionsEnabled && (
                <TabPanel key={RequestsReportingTab.AUCTIONS}>
                  {selectedTabId === RequestsReportingTab.AUCTIONS && (
                    <dashboard.Section
                      heading={t('auctions.heading', { ns: 'reporting' })}
                      controls={(
                        <CurrencySelectFieldBase
                          data-test="currency-dropdown"
                          label={t('general.currency')}
                          value={config.currency}
                          onChange={setters.setCurrency}
                        />
                      )}
                    >
                      <AuctionsTabContent config={config} />
                    </dashboard.Section>
                  )}
                </TabPanel>
              )}
            </TabPanels>
          }
          sidebarStyle={!isExtraSmall && !isSmall ? { maxWidth: '240px', flex: '0 0 auto' } : undefined}
          mainStyle={!isExtraSmall && !isSmall ? { flex: '1 1 auto' } : undefined}
        />
      </Tabs>
    </RequestsNavigationProvider>
  );
};

export const RequestsReporting = ({ selectedTabId }: { selectedTabId?: string }) => {
  const { t } = useTranslation('reporting');
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const systemFeatureFlags = useSystemFeatureFlags();
  const companyFeatureFlags = useCompanyFeatureFlags();
  const userHasRequestsReportingAccess = currentUser.roles[currentCompanyId].accessReportingForRequests;
  const companyManagementReportingEnabled = companyFeatureFlags?.managementReportingEnabled;
  const systemManagementReportingEnabled = systemFeatureFlags?.managementReportingEnabled;

  if (!companyManagementReportingEnabled || !systemManagementReportingEnabled) {
    return (
      <>
        <RequestsReportingTabs selectedTabId={RequestsReportingTab.SAVINGS} />
        <BlurredPagePlaceholderModal type="reporting" isOpen />
      </>
    );
  }

  if (!userHasRequestsReportingAccess) {
    return (
      <HiddenPagePlaceholder
        subtitle={t('insufficientCompanyPermissions')}
        hasCta={true}
        navigateToTeam={() => {
          navigate({
            to: teamManagementRoute.to,
            params: { currentCompanyId },
          });
        }}
      />
    );
  }

  return (
    <RequestsReportingTabs selectedTabId={selectedTabId} />
  );
};
