import { ScoringType, User } from '@deepstream/common/rfq-utils';
import { isEmpty } from 'lodash';
import * as rfx from '../rfx';
import { useCurrentCompanyId } from '../currentCompanyId';
import { useCurrentUser } from '../useCurrentUser';
import { getEvaluationExchangeSnapshotStatus, getTeamMemberEvaluationExchangeSnapshotStatus } from '../exchangeStatus';

export const useGetEvaluationExchangeStatus = () => {
  const pageEvaluators = rfx.usePageEvaluators();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const scoringType = rfx.useEvaluationScoringType();
  const pagePermissions = rfx.usePagePermissions();
  const rfxPermissions = rfx.useRfxPermissions();

  return scoringType === ScoringType.SINGLE_SCORE || rfxPermissions.canManageEvaluation
    ? getEvaluationExchangeSnapshotStatus(
      {
        companyId: currentCompanyId,
        user: currentUser as unknown as User,
      },
      pagePermissions.canRespond,
      !isEmpty(pageEvaluators),
    )
    : getTeamMemberEvaluationExchangeSnapshotStatus(
      {
        companyId: currentCompanyId,
        user: currentUser as unknown as User,
      },
    );
};
