import { Flex, FlexProps } from 'rebass/styled-components';
import { StageType } from '@deepstream/common/rfq-utils';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import DeepStreamIcon from '../DeepStreamIcon';

export const StageIcon = ({
  stageType,
  iconProps = {},
  iconWidth,
  iconHeight,
  sx = {},
  ...props
}: FlexProps & {
  stageType?: StageType;
  iconProps?: Omit<IconProps, 'icon'>;
  iconWidth?: number | string;
  iconHeight?: number | string;
}) => {
  const theme = useTheme();

  return (
    <Flex
      backgroundColor="white"
      color="subtext"
      height="39px"
      width="39px"
      justifyContent="center"
      alignItems="center"
      sx={{
        border: 'lightGray2',
        borderRadius: '3px',
        ...sx,
      }}
      {...props}
    >
      {stageType === StageType.AUCTION ? (
        <Icon
          icon="gavel"
          fontSize={3}
          mb="1px"
          {...iconProps}
        />
      ) : (
        <DeepStreamIcon
          color={theme.colors.subtext}
          width={iconWidth}
          height={iconHeight}
        />
      )}
    </Flex>
  );
};
