import { CellProps } from 'react-table';
import { Button } from '@deepstream/ui-kit/elements/button/Button';

type ToggleRowExpandedCellProps = CellProps<{ value: string }> & {
  expandedText: string;
  collapsedText: string;
};

export const ToggleRowExpandedCell = ({
  row,
  expandedText,
  collapsedText,
}: ToggleRowExpandedCellProps) => (
  <Button
    small
    variant="primary-outline"
    onClick={() => row.toggleRowExpanded()}
    iconRight={row.isExpanded ? 'chevron-up' : 'chevron-down'}
  >
    {row.isExpanded ? expandedText : collapsedText}
  </Button>
);
