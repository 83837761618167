import { useField } from 'formik';
import * as React from 'react';
import { ControlledRadioGroupProps } from '../ui/Radio';
import { ErrorMessage } from './Field';
import { FieldContainer } from './FieldContainer';
import { ControlledRadioButtonGroup } from '../ui/RadioButton';

interface RadioButtonGroupFieldProps {
  /**
   * Form field name
   */
  name: string;
  value?: ControlledRadioGroupProps['value'];
  label?: string;
  description?: string;
  hideLabel?: boolean;
  variant?: 'inline' | 'stacked';
  options: any;
  required?: boolean;
  showError?: boolean;
  onContainerBlur?: (event: React.FocusEvent<HTMLDivElement, any>) => void;
  onChange?: any;
  disabled?: boolean;
  error?: string;
  gap?: number | string;
  labelStyle?: React.CSSProperties;
  autoFocus?: boolean;
  boxStyle?: React.CSSProperties;

  onKeyDown?: (event: any) => void;
  errorMessageStyle?: React.CSSProperties;
}

export const RadioButtonGroupField = ({
  name,
  label,
  description,
  hideLabel,
  options,
  required,
  onChange: onChangeProp,
  showError,
  onKeyDown,
  errorMessageStyle,
  ...props
}: RadioButtonGroupFieldProps) => {
  const [field, meta, helper] = useField({ name });

  const onChange = React.useCallback(
    (value) => {
      if (onChangeProp) {
        onChangeProp(value);
      }

      helper.setValue(value);
      field.onChange(value);
    },
    [field, helper, onChangeProp],
  );

  return (
    <FieldContainer
      name={name}
      label={label}
      description={description}
      hideLabel={hideLabel}
      showAsterisk={required}
      onKeyDown={onKeyDown}
    >
      <ControlledRadioButtonGroup
        name={name}
        inputs={options}
        value={field.value}
        onChange={onChange}
        onContainerBlur={showError ? (
          (event: React.FocusEvent<HTMLDivElement, any>) => {
            if (event.relatedTarget?.name !== name) {
              helper.setTouched(true);
            }
          }
        ) : (
          undefined
        )}
        {...props}
      />
      {showError && meta.touched && meta.error ? (
        <ErrorMessage error={meta.error} fontWeight="normal" style={errorMessageStyle} />
      ) : (
        null
      )}
    </FieldContainer>
  );
};
