import { useCallback } from 'react';
import styled from 'styled-components';
import { Text, Flex, Box } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { DeadlineCountdown } from '../../../DeadlineCountdown';
import { useModalState } from '../../../ui/useModalState';
import * as rfx from '../../../rfx';
import { useSession } from '../../../auth';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useRfqId } from '../../../useRfq';
import { useApi } from '../../../api';
import { useMutation } from '../../../useMutation';
import { useToaster } from '../../../toast';
import { useInternalPublicRequestUrl } from '../../RequestVisibility/usePublicRequestUrl';
import { PublicBidIntention } from '../../../types';
import { useAppHeaderHeight } from '../../../Layout';
import { LARGE_PAGE_HEADER_HEIGHT } from '../../../constants';

const Heading = styled(Text)`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
`;

const SubText = styled(Text)`
  color: ${props => props.theme.colors.subtext};
`;

export const PublicRequestBidIntentionPanel = ({
  deadline,
  publicBidIntention,
  isPublicRoute,
  navigateToRequestReceivedDetails,
}: {
  deadline: Date;
  publicBidIntention?: PublicBidIntention;
  isPublicRoute?: boolean;
  navigateToRequestReceivedDetails?: () => void;
}) => {
  const { t } = useTranslation(['translation', 'general']);

  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const internalPublicRequestUrl = useInternalPublicRequestUrl();

  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId();

  const intentionToBidModal = useModalState();
  const { isAuthenticated } = useSession();
  const { isPublicRequestPreview } = rfx.useState();

  const isSender = rfx.useIsSender();
  const structure = rfx.useStructure();
  const { isRecipient } = structure;

  const [sendPublicBidIntention] = useMutation(api.sendPublicBidIntention, {
    onSuccess: () => {
      toaster.success(t('request.intention.intentionToBidSuccess'));
      queryClient.invalidateQueries(['publicBidIntention', { rfqId, currentCompanyId }]);
    },
    onError: () => toaster.error(t('request.intention.intentionToBidError')),
  });

  const handleIntentionOkClick = useCallback(() => {
    if (!isSender && !isPublicRequestPreview) {
      // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
      sendPublicBidIntention({ rfqId, currentCompanyId });
    }
    intentionToBidModal.close();
  }, [
    rfqId,
    currentCompanyId,
    isSender,
    isPublicRequestPreview,
    intentionToBidModal,
    sendPublicBidIntention,
  ]);

  const headerHeight = useAppHeaderHeight() + LARGE_PAGE_HEADER_HEIGHT + 30;

  const isRenderingPublicState = isPublicRoute || !isAuthenticated || isPublicRequestPreview;

  return (
    <>
      <Panel
        mb="20px"
        py={3}
        px="20px"
        sx={{
          boxShadow: '0px 8px 16px rgba(8, 35, 48, 0.2)',
          position: 'sticky',
          top: headerHeight,
          zIndex: 1,
        }}
      >
        <Flex
          alignItems={['flex-start', null, 'center']}
          flexDirection={['column', null, 'row']}
        >
          <Box flex={1} mb={[3, null, 0]} mr={[0, null, '30px']}>
            <Heading>
              {isRenderingPublicState ? (
                t('request.intention.continueToDeepstream')
              ) : isRecipient ? (
                t('request.intention.companyAlreadyAddedToRequest')
              ) : (
                t('request.intention.doYouIntendToBid')
              )}
            </Heading>
            <SubText>
              {isRenderingPublicState ? (
                t('request.intention.signupBeforeDeadline')
              ) : (
                t('request.intention.submitBidBeforeDeadline')
              )}
            </SubText>
          </Box>
          <Box mb={[3, null, 0]} mr={[0, null, '30px']}>
            <Text fontSize={1} fontWeight={500} lineHeight={1.5}>
              {t('general.deadline')}
            </Text>
            <DeadlineCountdown
              deadline={deadline}
              fontSize={2}
              iconStyle={{ fontSize: 2 }}
              isDeadlineAvailable={Boolean(deadline)}
            />
          </Box>
          <Box>
            {publicBidIntention && !isRecipient ? (
              <IconText
                icon="check"
                text={t('request.intention.bidIntentionSubmitted')}
              />
            ) : (
              <Button
                onClick={() => {
                  if (!isRenderingPublicState) {
                    if (isRecipient) {
                      navigateToRequestReceivedDetails?.();
                    } else {
                      intentionToBidModal.open();
                    }
                  } else {
                    window.open(internalPublicRequestUrl, '_blank');
                  }
                }}
              >
                {!isRenderingPublicState
                  ? isRecipient
                    ? t('request.intention.openReceivedRequests')
                    : t('request.intention.sendIntention')
                  : t('continue', { ns: 'general' })}
              </Button>
            )}
          </Box>
        </Flex>
      </Panel>
      <Dialog
        heading={
          isPublicRequestPreview
            ? t('information', { ns: 'general' })
            : t('request.intention.confirmYouIntendToBid')
        }
        body={
          <MessageBlock variant={isSender ? 'error' : 'info'} mt={0}>
            {isSender
              ? t('request.intention.cannotSendBidIntentionDescription')
              : isPublicRequestPreview
              ? t('request.intention.signupToBidDescription')
              : t('request.intention.confirmBidIntentionDescription')}
          </MessageBlock>
        }
        okButtonText={t('ok', { ns: 'general' })}
        isOpen={intentionToBidModal.isOpen}
        onOk={handleIntentionOkClick}
        // @ts-expect-error ts(2322) FIXME: Type '(() => void) | null' is not assignable to type '(() => void) | undefined'.
        onCancel={
          !(isSender || isPublicRequestPreview)
            ? intentionToBidModal.close
            : null
        }
        showCloseIcon
        style={{ content: { maxWidth: '500px' } }}
      />
    </>
  );
};
