import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';

export const AddCriterionButton = (props: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button small variant="secondary-transparent-outline" iconLeft="plus" {...props}>
      {t('request.evaluation.addCriterion')}
    </Button>
  );
};
