import * as React from 'react';
import styled from 'styled-components';
import { SpaceProps, WidthProps } from 'styled-system';
import { Card, Image, CardProps, Flex } from 'rebass/styled-components';
import { UrlContext } from './UrlProvider';

const SquareCard = styled(Card)`
  display: flex;
  align-items: center;
  height: ${props => props.width};
`;

type CompanyLogoProps = { src: string } & WidthProps & SpaceProps & CardProps;

export const CompanyLogo: React.FC<CompanyLogoProps> = ({ src, width, ...props }) => (
  <SquareCard
    bg="white"
    p={4}
    width={width}
    sx={{
      borderRadius: 'large',
      boxShadow: 'large',
      overflow: 'hidden',
    }}
    {...props}
  >
    <Image src={src} />
  </SquareCard>
);

export const CompanyLogo2: React.FC<{ companyId: string; width: number }> = ({ companyId, width }) => {
  const urls = React.useContext(UrlContext);

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      width={width}
      height={width}
      bg="white"
      sx={{
        borderRadius: '3px',
        border: 'lightGray',
        overflow: 'hidden',
      }}
    >
      <Image src={`${urls.api}/download/logo/${companyId}`} />
    </Flex>
  );
};
