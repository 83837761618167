import { useTranslation } from 'react-i18next';
import { isNil, omitBy } from 'lodash';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import useDownload from '../useDownload';
import { useCurrentCompanyId } from '../currentCompanyId';
import { useCurrentUser, useCurrentUserLocale } from '../useCurrentUser';
import { useMutation } from '../useMutation';

const useDownloadExchangeHistory = ({ rfqId, recipientId, exchangeId }) => {
  const download = useDownload();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();
  const locale = useCurrentUserLocale();

  const queryParams = new URLSearchParams(
    omitBy({
      csvSeparator: user.preferences?.csvSeparator,
      locale,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }, isNil) as Record<string, string>,
  );

  return useMutation(
    () => download(`/download/company/${currentCompanyId}/rfq/${rfqId}/recipient/${recipientId}/exchange/${exchangeId}?${queryParams.toString()}`),
  );
};

export const DownloadExchangeHistoryButton = ({
  rfqId,
  recipientId,
  exchangeId,
  disabled,
}: {
  rfqId: string;
  recipientId: string;
  exchangeId: string;
  disabled: boolean;
}) => {
  const [downloadExchangeHistory] = useDownloadExchangeHistory({ rfqId, recipientId, exchangeId });
  const { t } = useTranslation();

  return (
    <Tooltip content={t('request.exchange.exportHistory') as string}>
      <IconButton
        icon="download"
        color="primary"
        fontSize={3}
        onClick={() => downloadExchangeHistory(undefined)}
        mr={3}
        disabled={disabled}
      />
    </Tooltip>
  );
};
