import { memo, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { get, isEmpty, isString } from 'lodash';
import { Box } from 'rebass/styled-components';
import {
  ExchangeType,
  LineItemExchangeDefinition,
  isDefinitionField,
  isSupplierReplyField,
  isBuyerReplyField,
} from '@deepstream/common/rfq-utils';
import { documentExchangeTypes } from '@deepstream/common/exchangesConfig';
import { isLegacyContractExchangeDef } from '@deepstream/common/contract';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { StandaloneExchangeFields } from '../../ExchangeTable';
import { useExchange } from '../../useExchange';
import { ExchangeSwitcher } from '../../ExchangeModal/ExchangeSwitcher';
import { ExchangeTimeline } from '../../ExchangeModal/ExchangeTimeline';
import { ContractExchangeModalFooter } from './ContractExchangeModalFooter';
import * as modal from '../../ExchangeModal/layout';
import { useContractData, useContractSection, useIsRecipient, usePagePermissions } from './contract';
import { ActionNotificationSubject } from '../../ExchangeModal/useActionNotificationSubject';
import { useContractActionNotificationSubject } from './useContractActionNotificationSubject';
import { useExchangeColumns } from '../Exchange/columns';
import { useLineItemExchangeModalColumns } from '../Exchange/useLineItemExchangeModalColumns';

export const ContractExchangeModalContent = memo(({
  close,
  showExchangeSwitcher,
  showFooter,
}: {
  close: (...args: any[]) => void;
  showExchangeSwitcher?: boolean;
  showFooter?: boolean;
}) => {
  const { t } = useTranslation(['contracts', 'translation']);
  const { exchangeDefById } = useContractData();
  const exchange = useExchange();
  const isRecipient = useIsRecipient();
  const isLineItem = exchange.def.type === ExchangeType.LINE_ITEM;
  const [hideModalHeading, setHideModalHeading] = useState(true);
  const pagePermissions = usePagePermissions();
  const section = useContractSection();

  const handleExchangeFieldsScroll = useCallback(
    (element) => setHideModalHeading(element.target.scrollTop === 0),
    [setHideModalHeading],
  );

  const configurableColumns = useLineItemExchangeModalColumns({
    exchangeDef: exchange.def,
    orderedFieldIds: (exchangeDefById[exchange.def._id] as LineItemExchangeDefinition | undefined)?.orderedFieldIds,
  });

  const {
    genericLineItemBuyerReplyColumns,
    genericLineItemSupplierReplyColumns,
  } = useMemo(() => {
    const { fields } = exchange.def;

    if (!fields) {
      return {
        genericLineItemBuyerReplyColumns: [],
        genericLineItemSupplierReplyColumns: [],
      };
    }
    return {
      genericLineItemBuyerReplyColumns: configurableColumns.filter(column => isBuyerReplyField(fields[column._id])),
      genericLineItemSupplierReplyColumns: configurableColumns.filter(column => isSupplierReplyField(fields[column._id])),
    };
  }, [configurableColumns, exchange.def]);

  const exchangeColumns = useExchangeColumns();

  const isDocumentExchange = documentExchangeTypes.includes(exchange.def.type);

  const columns = useMemo(() => {
    if ([ExchangeType.CONTRACT, ExchangeType.LEGACY_CONTRACT].includes(exchange.def.type)) {
      return exchangeColumns.contractModal({
        showContractType: section.exchangeDefIds.length !== 1 || isLegacyContractExchangeDef(exchange.def),
      });
    } else if (exchange.def.type === ExchangeType.LINE_ITEM) {
      return configurableColumns.filter(column => {
        // @ts-expect-error ts(18048) FIXME: 'exchange.def.fields' is possibly 'undefined'.
        const field = exchange.def.fields[column._id];

        return isDefinitionField(field) && field._id !== 'targetPrice';
      });
    } else if (isDocumentExchange) {
      return exchangeColumns.documentModal();
    } else {
      return [];
    }
  }, [exchange.def, isDocumentExchange, exchangeColumns, section.exchangeDefIds.length, configurableColumns]);

  const headingAccessor = columns[0]?.accessor;

  const modalHeading = headingAccessor
    ? isString(headingAccessor)
      ? get(exchange, headingAccessor)
      : headingAccessor(exchange as any)
    : null;

  return (
    <modal.Content height={showFooter ? 'calc(100vh - 120px)' : 'calc(100vh - 56px)'}>
      <modal.Header
        onClose={close}
        leftActionComponent={showExchangeSwitcher && (
          <ExchangeSwitcher exchangeId={exchange._id} />
        )}
      >
        {hideModalHeading ? (
          null
        ) : (
          modalHeading
        )}
      </modal.Header>
      <modal.Body onScroll={handleExchangeFieldsScroll}>
        <modal.Fields onScroll={handleExchangeFieldsScroll}>
          <StandaloneExchangeFields
            vertical
            small
            exchange={exchange}
            columns={columns}
          />
          {isLineItem && (
            <>
              {!isEmpty(genericLineItemBuyerReplyColumns) && (
                <>
                  <modal.SectionHeader pt={4}>
                    {isRecipient ? t('request.lineItems.deliveryDate.buyerResponse', { ns: 'translation' }) : t('request.lineItems.deliveryDate.yourResponse', { ns: 'translation' })}
                  </modal.SectionHeader>
                  <Box pt={3}>
                    <StandaloneExchangeFields
                      vertical
                      small
                      exchange={exchange}
                      columns={genericLineItemBuyerReplyColumns}
                    />
                  </Box>
                </>
              )}
              <modal.SectionHeader pt={4}>
                {isRecipient ? t('request.lineItems.deliveryDate.yourResponse', { ns: 'translation' }) : t('request.lineItems.deliveryDate.supplierResponse', { ns: 'translation' })}
              </modal.SectionHeader>
              <Box pt={3}>
                <StandaloneExchangeFields
                  vertical
                  small
                  exchange={exchange}
                  columns={genericLineItemSupplierReplyColumns}
                />
              </Box>
              {!isRecipient && (exchange.def as LineItemExchangeDefinition).fields.targetPrice && (
                <>
                  <modal.SectionHeader pt={4}>
                    <Icon icon="eye-slash" mr={2} /> {t('request.exchange.internalFields', { ns: 'translation' })}
                  </modal.SectionHeader>
                  <Box pt={3}>
                    <StandaloneExchangeFields
                      vertical
                      small
                      exchange={exchange}
                      columns={
                        exchangeColumns.lineItemModalInternalSection({ showTargetPrice: true })
                      }
                    />
                  </Box>
                </>
              )}
            </>
          )}
        </modal.Fields>
        <modal.Timeline>
          <modal.TimelineHeader>
            {t('exchange.activityAndComments')}
          </modal.TimelineHeader>
          {/*
           // @ts-expect-error ts(2322) FIXME: Type '({ exchange, action, }: { exchange: ExchangeSnapshot; action: any; }) => null' is not assignable to type 'ActionNotificationSubjectFn'. */}
          <ActionNotificationSubject.Provider value={useContractActionNotificationSubject}>
            <ExchangeTimeline canRespond={pagePermissions.canRespond} />
          </ActionNotificationSubject.Provider>
        </modal.Timeline>
      </modal.Body>
      <modal.Footer>
        <ContractExchangeModalFooter />
      </modal.Footer>
    </modal.Content>
  );
});
