import { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PageType } from '@deepstream/common/rfq-utils';
import { ContractStatus } from '@deepstream/common/contract';
import { InlineButton } from '@deepstream/ui-kit/elements/button/InlineButton';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useContract } from './useContract';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { Loading } from '../../ui/Loading';
import { ErrorMessage } from '../../ui/ErrorMessage';
import {
  ContractProvider,
  ContractPageProvider,
  useIsRecipient,
  useContractData,
  usePagePermissions,
} from './contract';
import { ContractLiveSections } from './BidSections';
import { Bold } from '../../Bold';
import { NoPageAccess } from './Permissions/NoPageAccess';
import { useLiveContractNavigation } from '../../appNavigation';
import { useSearch } from '../../tanstackRouter';

const ContractApprovalInfo = () => {
  const { t } = useTranslation('contracts');
  const contract = useContractData();
  const { navigateToDetailsIndex } = useLiveContractNavigation();

  return (
    <MessageBlock variant={contract.allRequirementsResolved ? 'success' : 'error'} mt={0} mb="20px">
      <Bold>
        {contract.allRequirementsResolved ? (
          t('approvalsCompleteMessage')
        ) : (
          <Trans i18nKey="approvalsIncompleteMessage" ns="contracts">
            All pages in <InlineButton onClick={navigateToDetailsIndex}>Details</InlineButton> must
            be approved before submitting your signed contract
          </Trans>
        )}
      </Bold>
    </MessageBlock>
  );
};

const LiveContractContent = () => {
  const isRecipient = useIsRecipient();
  const contract = useContractData();
  const { canRead } = usePagePermissions();
  const search = useSearch({ strict: false }) as { exchangeId?: string; from?: 'verified' };
  const navigation = useLiveContractNavigation();

  const hasDetailsPage = Boolean(contract.pages.find(page => !page.type));

  return canRead ? (
    <>
      {!isRecipient && hasDetailsPage && contract.status === ContractStatus.NEGOTIATION && (
        <ContractApprovalInfo />
      )}
      <ContractLiveSections
        exchangeId={search.exchangeId}
        navigateToExchange={({ recipientId, exchangeId }) =>
          navigation.navigateToContract({ recipientId, exchangeId, from: search.from })
        }
      />
    </>
  ) : (
    <NoPageAccess />
  );
};

export const ContractLiveContract = ({
  contractId,
}: {
  contractId: string;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { data: contract, isLoading, isError, isSuccess } = useContract({
    contractId,
    currentCompanyId,
    scope: 'current',
  });

  const contractPage = useMemo(
    () => contract
      ? contract.pages.find(page => page.type === PageType.CONTRACT)
      : null,
    [contract],
  );

  return (
    <>
      {isLoading ? (
        <PanelPadding>
          <Loading />
        </PanelPadding>
      ) : isError ? (
        <PanelPadding>
          <ErrorMessage error={t('errors.unexpected')} />
        </PanelPadding>
      ) : isSuccess && contract ? (
        <ContractProvider contract={contract}>
          {/*
           // @ts-expect-error ts(2322) FIXME: Type 'ContractPage | null | undefined' is not assignable to type 'Page'. */}
          <ContractPageProvider page={contractPage}>
            <LiveContractContent />
          </ContractPageProvider>
        </ContractProvider>
      ) : (
        null
      )}
    </>
  );
};
