import { Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { identity } from 'lodash';

import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { CategoriesList } from './CategoriesList';
import { useUserFlags } from '../../../UserFlagsContext';

export const CategoriesPanel = ({
  onClickAdd = identity,
  onClickDelete = identity,
  onClickEdit = identity,
}: {
  onClickAdd?: () => void;
  onClickDelete?: (id: string) => void;
  onClickEdit?: (id: string) => void;
}) => {
  const { t } = useTranslation('preQualification');
  const { hasManagePreQualPermission } = useUserFlags();

  return (
    <Panel>
      <PanelHeader
        heading={(
          <Text sx={{ textTransform: 'uppercase', color: 'subtext', fontSize: 2 }}>
            {t('categories.heading')}
          </Text>
        )}
      >
        {hasManagePreQualPermission && (
          <Button small iconLeft="plus" variant="primary-transparent-outline" onClick={onClickAdd} />
        )}
      </PanelHeader>

      <PanelPadding pt={0}>
        <CategoriesList
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
        />
      </PanelPadding>
    </Panel>
  );
};
