import { useCallback, useMemo } from 'react';
import * as React from 'react';
import { first } from 'lodash';
import { Flex, SxStyleProp } from 'rebass/styled-components';
import { Attachment } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { CancelButton, RetryButton } from '@deepstream/ui-kit/elements/button/IconTextButton';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useUpload } from '../uploads/useUploads';
import { openFilePicker } from '../uploads/openFilePicker';
import { UploadFn } from './types';
import { ProgressBarObsolete } from './ProgressBar';
import { ErrorMessage } from './ErrorMessage';
import { Row } from './ProfileLayout';

const coverStyle: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

export type ImageUploadOverlayProps = {
  initialAttachment?: Attachment;
  onChange: (attachment: Attachment) => void;
  uploadFn: UploadFn;
  overlayStyle?: SxStyleProp;
  editButtonStyle?: SxStyleProp;
  containerStyle?: SxStyleProp;
};

export const ImageUploadOverlay: React.FC<ImageUploadOverlayProps> = ({
  initialAttachment,
  uploadFn,
  onChange,
  overlayStyle = {},
  editButtonStyle = {},
  containerStyle = {},
}) => {
  const { t } = useTranslation();
  const upload = useUpload({ initialAttachment, onChange, uploadFn });

  const getFile = useCallback(
    async () => first(await openFilePicker({ multiple: false })) as File,
    [],
  );

  const start = useCallback(
    async () => upload.start(await getFile()),
    [upload, getFile],
  );

  const replace = useCallback(
    async () => upload.replace(await getFile()),
    [upload, getFile],
  );

  const Overlay = useCallback(
    () => (
      <Flex sx={{ ...coverStyle, ...overlayStyle, backgroundColor: 'white', opacity: 0.93 }} />
    ),
    [overlayStyle],
  );

  const content = useMemo(
    () => {
      switch (upload.status) {
        case 'idle':
        case 'completed':
          return (
            <Button
              small
              variant="primary-outline"
              iconLeft="pencil"
              sx={{ position: 'absolute', zIndex: 10, ...editButtonStyle }}
              onClick={upload.status === 'idle' ? start : replace}
            >
              {t('general.edit')}
            </Button>
          );
        case 'uploading':
          return (
            <Stack gap={2}>
              <Overlay />
              <ProgressBarObsolete percentage={upload.progress * 100} width="110px" height="8px" />
              <Row justifyContent="center" sx={{ zIndex: 1 }}>
                <CancelButton onClick={upload.cancel} />
              </Row>
            </Stack>
          );
        case 'failed':
          return (
            <Stack gap={2}>
              <Overlay />
              <Row alignItems="center" justifyContent="center" sx={{ zIndex: 1 }}>
                <ErrorMessage error={t('errors.uploadFailed')} />
              </Row>
              <Row alignItems="center" justifyContent="center" sx={{ zIndex: 1 }}>
                <RetryButton onClick={upload.retry} mr={2} />
                <CancelButton onClick={upload.cancel} />
              </Row>
            </Stack>
          );
        default:
          return null;
      }
    },
    [upload, replace, start, editButtonStyle, Overlay, t],
  );

  const coverContainerStyle = useMemo(
    () => ({ ...coverStyle, ...containerStyle }),
    [containerStyle],
  );

  return (
    <Flex sx={coverContainerStyle}>
      {content}
    </Flex>
  );
};
