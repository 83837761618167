import { useMemo } from 'react';
import { ExchangeStatus, ExchangeType, Live, isDraftStageApprovalExchangeDef } from '@deepstream/common/rfq-utils';
import { mapValues } from 'lodash';
import { StageApprovalExchangeSnapshot } from '../../types';
import * as rfx from '../../rfx';

/**
 * Determines whether the current user can request a draft stage approval.
 */
export const useCanRequestDraftStageApproval = (): boolean => {
  const allExchanges = rfx.useExchanges();

  const { canEditApprovals } = rfx.useRfxPermissions();

  return useMemo(() => {
    if (!canEditApprovals) {
      return false;
    }

    return allExchanges
      .filter((exchange) => isDraftStageApprovalExchangeDef(exchange.def))
      .every((exchange) => exchange.status !== ExchangeStatus.INCOMPLETE);
  }, [allExchanges, canEditApprovals]);
};

/**
 * Returns a map that indicates for each stageId if the current user can
 * request a live stage approval.
 * If the current user doesn't have the permission to request a stage
 * approval, returns `null`.
 */
export const useCanRequestApprovalByStageId = (): Record<string, boolean> | null => {
  const { stageById } = rfx.useStructure<Live>();
  const allExchanges = rfx.useExchanges();

  const { canEditApprovals } = rfx.useRfxPermissions();

  return useMemo(() => {
    if (!canEditApprovals) {
      return null;
    }

    const exchanges = allExchanges.filter(
      (exchange) => exchange.def.type === ExchangeType.STAGE_APPROVAL,
    );

    return mapValues(
      stageById,
      stage => {
        return exchanges
          .filter((exchange) => (
            (exchange as StageApprovalExchangeSnapshot).def.stageId === stage._id
          ))
          .every((exchange) => exchange.status !== ExchangeStatus.INCOMPLETE);
      },
    );
  }, [stageById, allExchanges, canEditApprovals]);
};
