import { keyBy, mapValues, clone, get, cloneDeep, filter, template, without, map } from 'lodash';
import { useMemo, useState } from 'react';
import * as React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Box, Flex } from 'rebass/styled-components';
import { ListQuestionElement, QuestionElementType } from '@deepstream/common/legacy-pre-q-utils';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { IconTextButton } from '@deepstream/ui-kit/elements/button/IconTextButton';
import { Panel, PanelDivider, PanelText } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Row } from '../ui/ProfileLayout';
import { ElementProps, Modify, NoAnswer } from './common';
import { Elements } from './Elements'; // eslint-disable-line import/no-cycle
import { useCountryOptions } from '../ui/countries';

const isEmptyFieldValue = (value: any) => [undefined, null, ''].includes(value);

const getTemplateData = (elements: any[], answer: any) => {
  answer = cloneDeep(answer);

  // Get all dropdowns that have options defined on the element config
  const dropdowns = filter(
    elements,
    element => element.type === QuestionElementType.DROPDOWN && Boolean(element.options),
  );

  // Replace dropdown values with their respective labels
  for (const dropdown of dropdowns) {
    const selectedOption = dropdown.options.find((option: any) => option.value === answer[dropdown.key]);

    if (selectedOption) {
      answer[dropdown.key] = selectedOption.label;
    }
  }

  return answer;
};

const getListItemTitle = (element: any, listItem: any, t: TFunction) => {
  // Extract the named fields from the template, eg: '{{city}}'
  const templateFields = element.template.match(/{{([\s\S]+?)}}/g) || [];

  // Isolate the name by dropping the curly bracket notation
  const requiredFieldsForTitle = templateFields.map((field: any) => field.replace('{{', '').replace('}}', ''));

  // If we don't have all those fields, just use the item name as the title
  if (requiredFieldsForTitle.some((field: any) => isEmptyFieldValue(listItem[field]))) {
    return t(`element.list.itemName.${element.itemNameId}`);
  }

  // If we've made it this far, then we have all required fields to render the item's title
  const renderTemplate = template(element.template, {
    interpolate: /{{([\s\S]+?)}}/,
  });

  const data = getTemplateData(element.elements, listItem);

  return renderTemplate(data);
};

const PanelHeader = (props: { children: React.ReactNode }) => (
  <Row
    alignItems="center"
    justifyContent="space-between"
    backgroundColor="lightGray3"
    py={3}
    px={3}
    sx={{ lineHeight: 'normal', height: 64, borderRadius: 'inherit' }}
    {...props}
  />
);

type ExpandablePanelProps = {
  isOpen: boolean;
  onClick: any;
  heading: any;
  buttons: any;
  children: React.ReactNode;
};

const ExpandablePanel = ({ isOpen, onClick, heading, buttons, children, ...props }: ExpandablePanelProps) => (
  <Panel {...props}>
    <PanelHeader>
      <Box flex={1} mr={2}>
        <IconTextButton
          fixedWidth
          truncate
          onClick={onClick}
          icon={isOpen ? 'chevron-down' : 'chevron-right'}
          color="text"
          fontSize={2}
          fontWeight={500}
          lineHeight="normal"
          gap={1}
          sx={{ maxWidth: '100%' }}
        >
          {heading}
        </IconTextButton>
      </Box>
      {buttons && (
        <Flex>{buttons}</Flex>
      )}
    </PanelHeader>
    {isOpen && (
      <>
        <PanelDivider />
        <PanelText>{children}</PanelText>
      </>
    )}
  </Panel>
);

const removeIndex = (index: number) => (indexes: number[]) =>
  without(indexes, index);

const swapPositions = (fromIndex: number, toIndex: number) => (indexes: number[]) => {
  const fromOpen = indexes.includes(fromIndex);
  const toOpen = indexes.includes(toIndex);

  if (fromOpen && !toOpen) {
    return [...without(indexes, fromIndex), toIndex];
  } else if (!fromOpen && toOpen) {
    return [...without(indexes, toIndex), fromIndex];
  } else {
    // Do nothing. They are either both closed or both
    // open. So no state swapping is necessary.
    return indexes;
  }
};

const toggleIndex = (index: number) => (indexes: number[]) => {
  if (indexes.includes(index)) {
    return without(indexes, index);
  } else {
    return [...indexes, index];
  }
};

export const ListElement: React.FC<Modify<ElementProps, { element: ListQuestionElement }>> = ({
  name,
  element: originalElement,
  answer,
  isReadOnly,
  isLocked,
  upload,
}) => {
  const { t } = useTranslation('companyProfile');
  const { values } = useFormikContext();
  const answerPending = get(values, name, []);
  const [openPanelIndexes, setOpenPanelIndexes] = useState<number[]>([]);
  const countryOptions = useCountryOptions();

  const element = React.useMemo(
    () => {
      const clonedElement = cloneDeep(originalElement);

      clonedElement.elements.forEach(element => {
        if (element.type === QuestionElementType.DROPDOWN) {
          // Populate country dropdown elements with the available options
          if (element.subtype === 'country') {
            element.options = countryOptions.map((countryOption) => ({
              ...countryOption,
              key: countryOption.value,
            }));
          } else if (element.subtype === 'locationType') {
            element.options = ['manufacturingCenter', 'supportOffice'].map(value => ({
              value,
              key: value,
              label: t(`element.dropdown.option.${value}`),
            }));
          }
        }
      });

      return clonedElement;
    },
    [originalElement, countryOptions, t],
  );

  const emptyListItem = useMemo(
    () => mapValues(keyBy(element.elements, 'key'), () => ''),
    [element.elements],
  );

  const listItems = isReadOnly ? answer : answerPending;

  if (isReadOnly && !answer?.length) {
    return <NoAnswer />;
  }

  return (
    <FieldArray name={name}>
      {({ unshift, swap, remove }) => (
        <Stack gap={3}>
          {!isReadOnly && (
            <Box mt={1}>
              <Button
                small
                type="button"
                variant="primary-outline"
                iconLeft="plus"
                onClick={() => {
                  // Add a new item to the beginning of the list
                  unshift(clone(emptyListItem));
                  // The new item is open by default, and since it's prepended to the
                  // list, we need to shift all open indexes by 1
                  setOpenPanelIndexes(indexes => [0, ...map(indexes, index => index + 1)]);
                }}
              >
                {t(`element.list.addButtonLabel.${element.addButtonLabelId}`)}
              </Button>
            </Box>
          )}
          {listItems?.map((listItem: any, index: number) => (
            <ExpandablePanel
              key={index}
              heading={isLocked ? t(`element.list.itemName.${element.itemNameId}`) : getListItemTitle(element, listItem, t)}
              isOpen={openPanelIndexes.includes(index)}
              onClick={() => {
                setOpenPanelIndexes(toggleIndex(index));
              }}
              buttons={!isReadOnly && (
                <>
                  {index > 0 && (
                    <IconButton
                      mr={2}
                      fixedWidth
                      icon="arrow-up"
                      onClick={() => {
                        const fromIndex = index;
                        const toIndex = index - 1;
                        swap(fromIndex, toIndex);
                        setOpenPanelIndexes(swapPositions(fromIndex, toIndex));
                      }}
                    />
                  )}
                  {index < listItems.length - 1 && (
                    <IconButton
                      mr={2}
                      fixedWidth
                      icon="arrow-down"
                      onClick={() => {
                        const fromIndex = index;
                        const toIndex = index + 1;
                        swap(fromIndex, toIndex);
                        setOpenPanelIndexes(swapPositions(fromIndex, toIndex));
                      }}
                    />
                  )}
                  <IconButton
                    icon="trash"
                    fixedWidth
                    onClick={() => {
                      remove(index);
                      setOpenPanelIndexes(removeIndex(index));
                    }}
                  />
                </>
              )}
            >
              <Stack gap={3}>
                <Elements
                  key={listItem.id}
                  parentName={`${name}[${index}]`}
                  elements={element.elements}
                  answer={listItem}
                  isReadOnly={isReadOnly}
                  isLocked={isLocked}
                  upload={upload}
                />
              </Stack>
            </ExpandablePanel>
          ))}
        </Stack>
      )}
    </FieldArray>
  );
};
