export const defaultExchangeRates = {
  'date': '2018-06-23',
  'base': 'USD',
  'rates': {
    'AED': 3.673181,
    'AFN': 71.540544,
    'ALL': 108.7,
    'AMD': 482.051304,
    'ANG': 1.814839,
    'AOA': 245.945,
    'ARS': 26.997,
    'AUD': 1.343725,
    'AWG': 1.789006,
    'AZN': 1.7025,
    'BAM': 1.679693,
    'BBD': 2,
    'BDT': 84.48985,
    'BGN': 1.677605,
    'BHD': 0.379089,
    'BIF': 1785.5,
    'BMD': 1,
    'BND': 1.510744,
    'BOB': 6.90915,
    'BRL': 3.7861,
    'BSD': 1,
    'BTC': 0.000162004865,
    'BTN': 67.784292,
    'BWP': 10.319131,
    'BYN': 1.994021,
    'BZD': 2.009754,
    'CAD': 1.327669,
    'CDF': 1615,
    'CHF': 0.988395,
    'CLF': 0.02327,
    'CLP': 638.3,
    'CNH': 6.509173,
    'CNY': 6.5052,
    'COP': 2916,
    'CRC': 567.027559,
    'CUC': 1,
    'CUP': 25.5,
    'CVE': 95.1655,
    'CZK': 22.114559,
    'DJF': 178.05,
    'DKK': 6.393615,
    'DOP': 49.46,
    'DZD': 117.304515,
    'EGP': 17.8505,
    'ERN': 15.108767,
    'ETB': 27.59,
    'EUR': 0.857155,
    'FJD': 2.098997,
    'FKP': 0.75391,
    'GBP': 0.75391,
    'GEL': 2.454644,
    'GGP': 0.75391,
    'GHS': 4.7288,
    'GIP': 0.75391,
    'GMD': 47.275,
    'GNF': 9030,
    'GTQ': 7.494131,
    'GYD': 209.128931,
    'HKD': 7.84785,
    'HNL': 23.924338,
    'HRK': 6.3303,
    'HTG': 66.573476,
    'HUF': 278.52,
    'IDR': 14044.474311,
    'ILS': 3.605665,
    'IMP': 0.75391,
    'INR': 67.865,
    'IQD': 1188.5,
    'IRR': 42517.832245,
    'ISK': 108.37594,
    'JEP': 0.75391,
    'JMD': 131.26005,
    'JOD': 0.710306,
    'JPY': 109.96,
    'KES': 100.921188,
    'KGS': 68.23968,
    'KHR': 4060.5,
    'KMF': 422.35,
    'KPW': 900,
    'KRW': 1112.05,
    'KWD': 0.302542,
    'KYD': 0.833295,
    'KZT': 339.98,
    'LAK': 8415,
    'LBP': 1511,
    'LKR': 158.99,
    'LRD': 146.925,
    'LSL': 13.445,
    'LYD': 1.355,
    'MAD': 9.51,
    'MDL': 16.972261,
    'MGA': 3325.75,
    'MKD': 52.895,
    'MMK': 1386.8,
    'MNT': 2438.98461,
    'MOP': 8.080238,
    'MRO': 356,
    'MRU': 35.75,
    'MUR': 34.902611,
    'MVR': 15.400126,
    'MWK': 755.088333,
    'MXN': 20.006002,
    'MYR': 4.002003,
    'MZN': 59.600582,
    'NAD': 13.435,
    'NGN': 360,
    'NIO': 31.71,
    'NOK': 8.094218,
    'NPR': 108.430495,
    'NZD': 1.4478,
    'OMR': 0.385026,
    'PAB': 1,
    'PEN': 3.268,
    'PGK': 3.2785,
    'PHP': 53.205644,
    'PKR': 121.6,
    'PLN': 3.707686,
    'PYG': 5696.35,
    'QAR': 3.6411,
    'RON': 3.997694,
    'RSD': 101.295,
    'RUB': 62.975,
    'RWF': 860.5,
    'SAR': 3.75015,
    'SBD': 7.88375,
    'SCR': 13.541191,
    'SDG': 18.01,
    'SEK': 8.8545,
    'SGD': 1.3583,
    'SHP': 0.75391,
    'SLL': 6305.033977,
    'SOS': 581,
    'SRD': 7.468,
    'SSP': 130.2634,
    'STD': 21066.506883,
    'STN': 21.14,
    'SVC': 8.749479,
    'SYP': 515.09999,
    'SZL': 13.445,
    'THB': 32.903,
    'TJS': 9.105951,
    'TMT': 3.499986,
    'TND': 2.5964,
    'TOP': 2.294006,
    'TRY': 4.67697,
    'TTD': 6.73915,
    'TWD': 30.313,
    'TZS': 2275.1,
    'UAH': 26.2325,
    'UGX': 3864.55,
    'USD': 1,
    'UYU': 31.683589,
    'UZS': 7890,
    'VEF': 79900,
    'VND': 22870.715684,
    'VUV': 109.247826,
    'WST': 2.582007,
    'XAF': 562.256822,
    'XAG': 0.06077975,
    'XAU': 0.00078776,
    'XCD': 2.70255,
    'XDR': 0.708473,
    'XOF': 562.256822,
    'XPD': 0.001042,
    'XPF': 102.2858,
    'XPT': 0.00113897,
    'YER': 250.3,
    'ZAR': 13.432905,
    'ZMW': 10.074,
    'ZWL': 322.355011,
  },
  'timestamp': 0,
};
