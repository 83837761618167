import { useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { find, first } from 'lodash';
import { useQuery, useQueryClient } from 'react-query';
import { useField } from 'formik';
import { Text, Box, Flex } from 'rebass/styled-components';
import { BidStatus } from '@deepstream/common/rfq-utils';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { callAll } from '@deepstream/utils/callAll';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { ModalForm } from '../../ModalForm';
import { useModalState } from '../../ui/useModalState';
import { useStructure } from '../../rfx';
import { useRfqId } from '../../useRfq';
import { CompanyLogo } from '../../CompanyLogo';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useApi, wrap } from '../../api';
import { CompanySelectField } from './CompanySelectField';
import { AwardedRequestOverview } from '../../types';
import { SelectField } from '../../form/SelectField';
import { useMutation } from '../../useMutation';
import { useToaster } from '../../toast';
import { UpgradeToUnlockContractsDialog } from './UpgradeToUnlockContractsDialog';
import { useCompanyFeatureFlags } from '../../companyFeatureFlags';
import { useCurrentUserLocale } from '../../useCurrentUser';

type CreateContractFromRequestButtonProps = ButtonProps & {
  navigateToSenderDraftContract: (contractId: string) => void;
};

export const CreateContractFromRequestButton = ({
  navigateToSenderDraftContract,
  ...props
}: CreateContractFromRequestButtonProps) => {
  const { t } = useTranslation('contracts');
  const modal = useModalState();
  const rfqId = useRfqId();
  const structure = useStructure();
  const companyFeatureFlags = useCompanyFeatureFlags();

  const request = useMemo(
    () => ({
      _id: rfqId,
      subject: structure.summary.subject,
    }),
    [rfqId, structure],
  );

  const awardedRecipients = useMemo(
    () => structure.recipients.filter(
      recipient => structure.bidById[recipient._id].status === BidStatus.AWARDED,
    ),
    [structure],
  );

  const hasSingleRecipient = awardedRecipients.length === 1;

  return (
    <>
      <Button
        variant="secondary"
        iconLeft={companyFeatureFlags.contractManagementEnabled ? 'file-contract' : 'lock'}
        onClick={modal.open}
        {...props}
      >
        {t('createContract')}
      </Button>
      {companyFeatureFlags.contractManagementEnabled ? (
        <CreateFromRequestModal
          isOpen={modal.isOpen}
          // @ts-expect-error ts(2322) FIXME: Type '{ _id: string; subject: string | undefined; }' is not assignable to type '{ _id: string; subject: string; }'.
          request={request}
          // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
          recipient={hasSingleRecipient ? first(awardedRecipients).company : undefined}
          heading={t('createContract')}
          onCancel={modal.close}
          navigateToSenderDraftContract={navigateToSenderDraftContract}
        />
      ) : (
        <UpgradeToUnlockContractsDialog
          isOpen={modal.isOpen}
          onCancel={modal.close}
        />
      )}
    </>
  );
};

const RequestSelectField = ({
  awardedRequests,
  setSelectedRequest,
}: {
  awardedRequests: AwardedRequestOverview[];
  setSelectedRequest: (awardedRequest: AwardedRequestOverview) => void,
}) => {
  const { t } = useTranslation('contracts');
  const [,, requestIdFormik] = useField('awardedRequestId');
  const [,, recipientIdFieldFormik] = useField('recipientId');

  const onSelectRequest = useCallback(
    (requestId) => {
      const awardedRequest = find(awardedRequests, { _id: requestId });

      // @ts-expect-error ts(2345) FIXME: Argument of type 'AwardedRequestOverview | undefined' is not assignable to parameter of type 'AwardedRequestOverview'.
      setSelectedRequest(awardedRequest);
      requestIdFormik.setValue(requestId);

      // @ts-expect-error ts(18048) FIXME: 'awardedRequest' is possibly 'undefined'.
      if (awardedRequest.awardedRecipients.length === 1) {
        // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
        recipientIdFieldFormik.setValue(first(awardedRequest.awardedRecipients)._id);
      } else {
        recipientIdFieldFormik.setValue('');
      }
    },
    [awardedRequests, setSelectedRequest, requestIdFormik, recipientIdFieldFormik],
  );

  return (
    <SelectField
      name="awardedRequestId"
      hideLabel
      placeholder={t('selectPreviouslyAwardedRequest')}
      items={awardedRequests.map(request => ({
        label: request.subject,
        value: request._id,
      }))}
      disabled={!awardedRequests.length}
      maxHeight="140px"
      onChange={onSelectRequest}
    />
  );
};

const RecipientSelect = ({ awardedRequest }: { awardedRequest: AwardedRequestOverview }) => {
  const { t } = useTranslation(['contracts', 'general']);

  const items = useMemo(
    () => awardedRequest
      ? awardedRequest.awardedRecipients.map(recipient => ({
        label: recipient.name,
        value: recipient._id,
        address: recipient.address,
      }))
      : [],
    [awardedRequest],
  );

  return (
    <CompanySelectField
      name="recipientId"
      hideLabel
      placeholder={t('select1AwardedSupplier')}
      items={items}
      disabled={!awardedRequest}
    />
  );
};

export const CreateFromRequestModal = ({
  isOpen,
  request,
  recipient,
  heading,
  onCancel,
  navigateToSenderDraftContract,
}: {
  isOpen: boolean;
  request?: {
    _id: string;
    subject: string;
  };
  recipient?: {
    _id: string;
    name: string;
  };
  heading: string;
  onCancel: () => void;
  navigateToSenderDraftContract: (contractId: string) => void;
}) => {
  const { t } = useTranslation(['contracts', 'general']);
  const api = useApi();
  const toaster = useToaster();
  const locale = useCurrentUserLocale();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const queryClient = useQueryClient();
  const [selectedRequest, setSelectedRequest] = useState<AwardedRequestOverview | null>(null);

  const [createContractFromRequest] = useMutation(
    api.createContractFromRequest,
    {
      onSettled: () => queryClient.invalidateQueries(['draftContracts', { currentCompanyId }]),
      onSuccess: callAll(
        (contract) => navigateToSenderDraftContract(contract._id),
        () => toaster.success(t('toaster.contractCreated.success')),
      ),
      onError: () => toaster.error(t('toaster.contractCreated.failed')),
    },
  );

  const { data: awardedRequests = [] } = useQuery(
    ['awardedRequestsSent', { companyId: currentCompanyId }],
    wrap(api.getAwardedRequestsSent),
    {
      enabled: !request,
    },
  );

  const { data: awardedRequest } = useQuery(
    ['awardedRequestSent', { companyId: currentCompanyId, requestId: request?._id }],
    wrap(api.getAwardedRequestSent),
    {
      enabled: Boolean(request?._id && !recipient),
    },
  );

  const selectedRequestHasSingleRecipient = selectedRequest?.awardedRecipients.length === 1;
  const onlyRecipient = recipient || (selectedRequestHasSingleRecipient
      ? first(selectedRequest?.awardedRecipients)
      : undefined);

  return (
    <ModalForm
      heading={heading}
      initialValues={{
        awardedRequestId: request ? request._id : '',
        recipientId: recipient ? recipient._id : '',
      }}
      validationSchema={
        yup.object().shape({
          awardedRequestId: yup.string().required(t('required', { ns: 'general' })),
          recipientId: selectedRequestHasSingleRecipient
            ? yup.string()
            : yup.string().required(t('required', { ns: 'general' })),
        })
      }
      disableSubmitIfNotDirty={false}
      disableSubmitIfInvalid={false}
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={async ({ awardedRequestId, recipientId }) => {
        await createContractFromRequest({
          companyId: currentCompanyId,
          requestId: awardedRequestId,
          recipientId: onlyRecipient ? onlyRecipient._id : recipientId,
          locale,
        });
      }}
      submitLabel={t('createContract')}
      style={{ content: { maxWidth: '390px', minWidth: '390px' } }}
    >
      <Box>
        <Text mb={1}>
          {t('request')}:
        </Text>
        {request ? (
          <Truncate fontWeight={500}>
            {request.subject}
          </Truncate>
        ) : (
          <RequestSelectField
            awardedRequests={awardedRequests}
            setSelectedRequest={setSelectedRequest}
          />
        )}
      </Box>
      {request || selectedRequest ? (
        <>
          <Box>
            <Text mb={1}>
              {t('awardedSupplier')}:
            </Text>
            {onlyRecipient ? (
              <Flex alignItems="center">
                <Box flex="0 0 auto">
                  <CompanyLogo size="xs" companyId={onlyRecipient._id} />
                </Box>
                <Truncate>
                  {onlyRecipient.name}
                </Truncate>
              </Flex>
            ) : (
              // @ts-expect-error ts(2322) FIXME: Type 'AwardedRequestOverview | null | undefined' is not assignable to type 'AwardedRequestOverview'.
              <RecipientSelect awardedRequest={request ? awardedRequest : selectedRequest} />
            )}
          </Box>
          <MessageBlock variant="warn" mt={0}>
            {t('createFromRequestWarning')}
          </MessageBlock>
        </>
      ) : (
        null
      )}
    </ModalForm>
  );
};
