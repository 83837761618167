import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass/styled-components';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { OverflowTooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { Avatar } from '../Avatar';

export const IndividualExchangeScoreHeader = ({ column: { user } }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box mb="3px">
        {t('request.evaluation.scorePoints')}
      </Box>
      <Flex maxWidth="100%" alignItems="center">
        <Box flex="0 0 auto" mr={1}>
          <Avatar userId={user._id} width="xxs" />
        </Box>
        <OverflowTooltip content={user.name}>
          <Truncate>{user.name}</Truncate>
        </OverflowTooltip>
      </Flex>
    </Box>
  );
};

export const IndividualExchangeScoreDescription = ({ user }) => {
  return (
    <Flex maxWidth="100%" alignItems="center">
      <Box flex="0 0 auto" mr={1}>
        <Avatar userId={user._id} width="xxs" />
      </Box>
      <OverflowTooltip content={user.name}>
        <Truncate>{user.name}</Truncate>
      </OverflowTooltip>
    </Flex>
  );
};
