import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Text } from 'rebass/styled-components';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { copyToClipboard } from '@deepstream/ui-utils/clipboard';
import * as rfx from '../../rfx';
import { useExternalPublicRequestUrl, useInternalPublicRequestUrl } from './usePublicRequestUrl';
import { useToaster } from '../../toast';

export enum RequestVisibility {
  Private = 'private',
  Public = 'public',
}

export const VisibilityLabel = ({
    isPubliclyAvailable,
}: {
    isPubliclyAvailable: boolean;
}) => {
  const { t } = useTranslation('translation');

  return (
    <IconText
      icon={isPubliclyAvailable ? 'globe' : 'eye-slash'}
      isIconLight={true}
      text={isPubliclyAvailable ? (
        t('request.visibility.publicVisibility')
      ) : (
        t('request.visibility.privateVisibility')
      )}
    />
  );
};

export const VisibilityDescription = ({
    isPubliclyAvailable,
}: {
    isPubliclyAvailable: boolean;
}) => {
  const { t } = useTranslation('translation');
  const toaster = useToaster();
  const { isLive, isTemplate } = rfx.useState();
  const internalPublicRequestUrl = useInternalPublicRequestUrl();
  const externalPublicRequestUrl = useExternalPublicRequestUrl();

  const copyLink = useCallback(
    () => {
      copyToClipboard(externalPublicRequestUrl);
      toaster.success(t('request.intention.publicRequestLinkCopySuccess'));
    },
    [externalPublicRequestUrl, toaster, t],
  );

  return isPubliclyAvailable ? (
    <Text color="subtext" fontSize={1}>
      {t('request.visibility.publicVisibilityDescription')}
      {!isTemplate && (
        <>
          {' '}
          <Link
            href={
              isLive
                ? externalPublicRequestUrl
                : `${internalPublicRequestUrl}?isPreview=true`
            }
            target="_blank"
            display="inline-block"
          >
            <IconText
              icon="external-link"
              isIconLight={true}
              text={
                isLive
                  ? t('openPage', { ns: 'general' })
                  : t('preview', { ns: 'general' })
              }
              fontSize={1}
              fontWeight={500}
            />
          </Link>{' '}
          <Link variant="primary" onClick={copyLink} display="inline-block">
            <IconText
              icon="link"
              isIconLight={true}
              text={t('copyLink', { ns: 'general' })}
              fontSize={1}
              fontWeight={500}
            />
          </Link>
        </>
      )}
    </Text>
  ) : (
    <>{t('request.visibility.privateVisibilityDescription')}</>
  );
};

export const RequestVisibilityBlock = ({
    isPubliclyAvailable,
}: {
    isPubliclyAvailable: boolean;
}) => {
  return (
    <Stack gap={1}>
      <VisibilityLabel isPubliclyAvailable={isPubliclyAvailable} />
      <VisibilityDescription isPubliclyAvailable={isPubliclyAvailable} />
    </Stack>
  );
};

export const requestVisibilityOptions = [
  {
    value: RequestVisibility.Private,
    label: <VisibilityLabel isPubliclyAvailable={false} />,
    description: <VisibilityDescription isPubliclyAvailable={false} />,
  },
  {
    value: RequestVisibility.Public,
    label: <VisibilityLabel isPubliclyAvailable={true} />,
    description: <VisibilityDescription isPubliclyAvailable={true} />,
  },
];
