import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { withProps } from '@deepstream/ui-utils';
import { callAll } from '@deepstream/utils/callAll';
import { filter, find } from 'lodash';
import { ExchangeSnapshot } from '../../types';
import { useModalState } from '../../ui/useModalState';
import { useExchangeNavigation } from '../../useExchangeModalState';
import * as rfx from '../../rfx';
import { Disclosure2 } from '../../ui/Disclosure';
import { NewClarificationModal } from './NewClarificationModal';
import { ClarificationUploadsConfigModal } from './ClarificationUploadsConfigModal';
import { Bold } from '../../Bold';
import { RequestMessagesGrid } from './RequestMessagesGrid';
import { SortableHeader } from '../../ui/ExchangeDefsGrid/header';
import { getExchangeSnapshotStatus } from '../../exchangeStatus';
import { ExchangeStatusValueCell } from '../Request/Live/ExchangeStatusValueCell';
import { TextOrDashCell } from '../../ui/ExchangeDefsGrid/nonValidationAwareValueCell';
import { NumCommentsValueCell } from '../Request/Live/NumCommentsValueCell';

const NewClarificationButton = (props) => {
  const { t } = useTranslation('translation');

  return (
    <Button small iconLeft="plus" {...props}>
      {t('request.clarifications.newClarification')}
    </Button>
  );
};

type ClarificationsProps = {
  exchanges: ExchangeSnapshot[];
  sectionId: string;
};

export const Clarifications = ({ exchanges, sectionId }: ClarificationsProps) => {
  const { t } = useTranslation(['translation', 'general']);
  const newClarificationModal = useModalState();
  const clarificationUploadsConfigModal = useModalState();
  const { navigateToExchange, openExchange } = useExchangeNavigation();
  const pagePermissions = rfx.usePagePermissions({ required: true });
  const { settings } = rfx.useStructure();

  const columns: EditableGridColumn<ExchangeSnapshot>[] = useMemo(
    () => ([
      {
        _id: 'name',
        accessorKey: 'def.name',
        Header: SortableHeader,
        label: t('subject', { ns: 'general' }),
        width: 335,
        ValueCell: withProps(TextOrDashCell, { truncate: true }),
      },
      {
        _id: 'recipientName',
        accessorKey: 'recipientName',
        accessorFn: (exchange: ExchangeSnapshot) => (
          find(exchange.companies, { group: 'recipient' })?.name
        ),
        Header: SortableHeader,
        label: t('supplier', { ns: 'general', count: 1 }),
        width: 253,
        ValueCell: withProps(TextOrDashCell, { truncate: true }),
      },
      {
        _id: 'status',
        accessorKey: 'status',
        accessorFn: (exchange: ExchangeSnapshot) => getExchangeSnapshotStatus(exchange, pagePermissions),
        Header: SortableHeader,
        label: t('general.status'),
        width: 166,
        ValueCell: withProps(ExchangeStatusValueCell, { truncate: true }),
      },
      {
        _id: 'comments',
        accessorKey: 'comments',
        accessorFn: (exchange: ExchangeSnapshot) => filter(exchange.history, 'comment').length,
        Header: SortableHeader,
        label: t('comment', { ns: 'general', count: 2 }),
        width: 141,
        ValueCell: NumCommentsValueCell,
      },
    ]),
    [pagePermissions, t],
  );

  return (
    <>
      <Panel>
        <PanelHeader
          heading={t('request.clarifications.clarification_other')}
          icon="question"
        >
          <Flex>
            {pagePermissions.canEdit && (
              <Flex flexDirection="column" alignItems="flex-start" mr="32px">
                <Text fontSize={1} fontWeight={500}>
                  {t('request.clarifications.supplierFileUploads')}
                </Text>
                <Flex sx={{ columnGap: '6px' }}>
                  <Text fontSize="14px" fontWeight={500}>
                    {settings.allowClarificationAttachments ? t('general.enabled') : t('general.disabled')}
                  </Text>
                  <IconButton
                    icon="pencil"
                    color="primary"
                    fontSize="14px"
                    fontWeight={500}
                    onClick={clarificationUploadsConfigModal.open}
                  />
                </Flex>
              </Flex>
            )}
            <Flex alignItems="center">
              <NewClarificationButton
                onClick={newClarificationModal.open}
                disabled={!pagePermissions.canEdit}
              />
            </Flex>
          </Flex>
        </PanelHeader>
        <PanelDivider />
        {exchanges.length ? (
          <PanelPadding>
            <GridIdPrefixProvider>
              <GridMenuStateProvider>
                <RequestMessagesGrid data={exchanges} columns={columns} onRowClick={openExchange} />
              </GridMenuStateProvider>
            </GridIdPrefixProvider>
          </PanelPadding>
        ) : (
          <Flex width="100%" height="200px" justifyContent="center" alignItems="center">
            <Bold color="darkGray2">
              {t('request.clarifications.noClarifications')}
            </Bold>
          </Flex>
        )}
      </Panel>
      <Text mt={3}>
        <Disclosure2
          width="100%"
          summary={t('request.clarifications.disclosure.heading')}
        >
          {t('request.clarifications.disclosure.body.sender')}
        </Disclosure2>
      </Text>
      <NewClarificationModal
        sectionId={sectionId}
        isOpen={newClarificationModal.isOpen}
        onCancel={newClarificationModal.close}
        onSuccess={callAll(newClarificationModal.close, navigateToExchange)}
      />
      <ClarificationUploadsConfigModal
        isOpen={clarificationUploadsConfigModal.isOpen}
        onCancel={clarificationUploadsConfigModal.close}
        onSuccess={clarificationUploadsConfigModal.close}
      />
    </>
  );
};
