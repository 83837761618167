import { useCallback, useMemo } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { differenceInCalendarDays } from 'date-fns';

import { TableDataContext, TableData } from '../../TableDataContext';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useApi, wrap } from '../../api';
import { useToaster } from '../../toast';
import { useMutation } from '../../useMutation';
import { downloadUrl, downloadBlob, getContentDispositionFilename } from '../../useDownload';
import { useCurrentUserLocale } from '../../useCurrentUser';

export enum DocumentVisibility {
  INTERNAL = 'internal',
  PUBLIC = 'public',
}

export type Attachment = {
  _id: string;
  name: string;
  companyId: string;
  mimetype: string;
  purpose: string;
  size: number;
  unconfirmed: boolean;
  url: string;
  userId: string;
};

export type AuditTrailEvent = {
  date: string;
  documentType: string;
  documentName: string;
  documentId: string;
  documentExpiryDate: string | null;
  detail: string;
  action: string;
  user: string;
  documentVisibility: DocumentVisibility;
};

export type QueryDocument = {
  _id: string;
  visibility: DocumentVisibility;
  uploadDate: string;
  type: string;
  name: string;
  lastUpdatedDate: string;
  lastUpdatedBy: { _id: string; name: string; };
  isDeleted: boolean;
  expiryDate: string | null;
  description: string;
  companyId: string;
  auditTrail: AuditTrailEvent[];
  attachment: Attachment;
};

const NEARLY_EXPIRED_DAYS = 14;

export const useDocumentExpiryDate = (expiryDate: string | null) => {
  if (!expiryDate) return { isExpired: null, isNearlyExpired: null };

  const today = new Date();

  const daysUntilExpiry = differenceInCalendarDays(new Date(expiryDate), today);
  const isNearlyExpired = daysUntilExpiry > 0 && daysUntilExpiry <= NEARLY_EXPIRED_DAYS;
  const isExpired = daysUntilExpiry <= 0;

  return { isExpired, isNearlyExpired };
};

export const useDocumentsDelete = () => {
  const api = useApi();
  const { t } = useTranslation();
  const companyId = useCurrentCompanyId({ required: true });
  const queryClient = useQueryClient();
  const toaster = useToaster();

  const [mutate] = useMutation(api.deleteCompanyDocument);

  return useCallback(async (ids: string[]) => {
    try {
      const response = await Promise.all(
        ids.map(async (documentId) => mutate({ companyId, documentId })),
      );

      queryClient.invalidateQueries(['companyAllDocuments', { companyId }]);
      toaster.success(t('drivePage.toaster.deletedSuccess', { count: ids.length }));

      return response;
    } catch {
      toaster.error(t('drivePage.toaster.deletedError'));

      return null;
    }
  }, [
    queryClient,
    companyId,
    toaster,
    mutate,
    t,
  ]);
};

export const useDocumentDownload = () => {
  const api = useApi();
  const { t } = useTranslation();
  const toaster = useToaster();
  const companyId = useCurrentCompanyId({ required: true });
  const [getDownloadUrl] = useMutation(api.getDocumentDownloadUrl);

  return useCallback(async (documentId: string, documentName: string) => {
    try {
      const { url } = await getDownloadUrl({
        documentId,
        currentCompanyId: companyId,
        targetCompanyId: companyId,
      });

      downloadUrl(url, documentName);
    } catch {
      toaster.error(t('drivePage.toaster.downloadedError'));
    }
  }, [
    companyId,
    getDownloadUrl,
    toaster,
    t,
  ]);
};

export const useDocumentsBatchDownload = () => {
  const api = useApi();
  const { t } = useTranslation();
  const toaster = useToaster();
  const companyId = useCurrentCompanyId({ required: true });
  const locale = useCurrentUserLocale();
  const [getBatchDownloadURL] = useMutation(api.getDocumentsBatchDownloadUrl);

  return useCallback(async (documentIds: string[]) => {
    try {
      const response = await getBatchDownloadURL({
        documentIds,
        companyId,
        locale,
      });
      const filename = getContentDispositionFilename(response);

      downloadBlob(response.data, filename);
    } catch {
      toaster.error(t('drivePage.toaster.downloadedError'));
    }
  }, [getBatchDownloadURL, companyId, locale, toaster, t]);
};

export const DocumentsTableDataProvider = <QueryDataT extends QueryDocument[]>({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement<TableData<QueryDataT>> => {
  const companyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const query = useQuery(
    ['companyAllDocuments', { companyId }],
    wrap<(...args) => Promise<QueryDocument[]>>(api.getCompanyDocuments),
  );

  const value = useMemo(() => ({
    companyId,
    data: query?.data ?? [],
    pageCount: 0,
    pageSize: 0,
    pageIndex: 0,
    setPageControls: () => null,
    query,
  }), [companyId, query]);

  return (
    <TableDataContext.Provider value={value}>
      {children}
    </TableDataContext.Provider>
  );
};
