import { useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { fromPairs, isEmpty, isEqual, noop, pick, sumBy } from 'lodash';
import { Form, Formik } from 'formik';
import { immutableSet, immutableUpdate } from '@deepstream/utils';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { EditableGridDataProvider } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { FormikGridRowUpdater } from '@deepstream/ui-kit/grid/EditableGrid/FormikGridRowUpdater';
import { useMemo } from 'react';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData, AwardFlowStep, AwardFlowStepType, MessageConfig } from '../types';
import * as rfx from '../../../../rfx';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { ChooseMessageActionGrid, ChooseMessageActionGridRowData } from '../../../../ui/ExchangeDefsGrid/ChooseMessageActionGrid';
import { MessageAction, useMessageActionOptions } from '../useMessageActionOptions';
import { getEmptyMessageConfig } from '../utils';
import { Direction } from '../../../../ui/MultiStepFlow/types';

const hasRowChanged = (gridRow: ChooseMessageActionGridRowData, formikRow: ChooseMessageActionGridRowData) =>
  !isEqual(gridRow.action, formikRow.action);

const getSubmissionDataFromFormValues = ({ rowData }: { rowData: ChooseMessageActionGridRowData[] }, data: AwardFlowData) => {
  const { supplierGroup } =
    data.currentStep as Extract<AwardFlowStep, { type: AwardFlowStepType.ENTER_MULTI_SUPPLIER_INDIVIDUAL_MESSAGES }>;

  const excludedSupplierIds = rowData
    .filter(row => row.action === 'sendNoMessage')
    .map(row => row._id);
  const generalMessageSupplierIds = rowData
    .filter(row => row.action === 'sendGeneralMessage')
    .map(row => row._id);
  const individualMessageSupplierIds = rowData
    .filter(row => row.action === 'sendIndividualMessage')
    .map(row => row._id);

  return {
    messageConfigByGroup: immutableUpdate(
      data.messageConfigByGroup,
      supplierGroup,
      (config: MessageConfig) => ({
        ...config,
        generalMessageSupplierIds,
        excludedSupplierIds,
        messageBySupplierId: {
          ...fromPairs(
            individualMessageSupplierIds.map(supplierId => [
              supplierId,
              getEmptyMessageConfig(),
            ]),
          ),
          ...pick(config.messageBySupplierId, individualMessageSupplierIds),
        },
      }),
    ),
  };
};

export const ChooseMultiSupplierCustomMessageMethodsStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (data: Partial<AwardFlowData> | null, direction: Direction) => void,
}) => {
  const { t } = useTranslation('translation');
  const { supplierGroup } = data.currentStep as Extract<AwardFlowStep, { type: AwardFlowStepType.CHOOSE_MULTI_SUPPLIER_MESSAGE_METHOD }>;
  const supplierIds = data.supplierIdsByGroup[supplierGroup];
  const suppliers = rfx.useSortedRecipients(supplierIds);
  const messageActionOptions = useMessageActionOptions();

  const messageConfig = data.messageConfigByGroup[supplierGroup];

  const initialRowData = suppliers.map(supplier => ({
    _id: supplier._id,
    supplier,
    action: (
      messageConfig.excludedSupplierIds?.includes(supplier._id) ? (
        'sendNoMessage'
      ) : messageConfig.generalMessageSupplierIds?.includes(supplier._id) ? (
        'sendGeneralMessage'
      ) : Object.keys(messageConfig.messageBySupplierId || {}).includes(supplier._id) ? (
        'sendIndividualMessage'
      ) : (
        null
      )
    ) as MessageAction | null,
  }));

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      rowData: z
        .array(
          z.custom<ChooseMessageActionGridRowData>().refine(
            value => Boolean(value?.action),
            value => ({ message: t('request.awardFlow.errors.selectMessageAction', { supplierName: value.supplier.company.name }) }),
          ),
        )
        .refine(
          rowData => {
            const generalMessageActionCount = sumBy(rowData, (row: ChooseMessageActionGridRowData) => row.action === 'sendGeneralMessage' ? 1 : 0);

            return generalMessageActionCount !== 1;
          },
          rowData => ({
            message: t('request.awardFlow.errors.exactlyOneGeneralMessageAction', {
              supplierName: rowData.find((row: ChooseMessageActionGridRowData) => row.action === 'sendGeneralMessage')?.supplier.company.name,
            }),
          }),
        ),
    });

    return toFormikValidationSchema(Schema);
  }, [t]);

  return (
    <Formik
      validateOnBlur
      initialValues={{
        rowData: initialRowData,
      }}
      validationSchema={validationSchema}
      onSubmit={noop}
    >
      {({ values, submitForm, validateForm, dirty }) => (
        <Form>
          <lotPagesLayout.ContentWrapper>
            <StepNavigation
              onBackClick={() => submitAndNavigate(dirty ? getSubmissionDataFromFormValues(values, data) : null, Direction.BACK)}
              onContinueClick={async () => {
                const errors = await validateForm();

                await submitForm();

                if (isEmpty(errors)) {
                  submitAndNavigate(dirty ? getSubmissionDataFromFormValues(values, data) : null, Direction.FORWARD);
                }
              }}
            >
              <FormErrors />
              <lotPagesLayout.Section
                heading={t('request.awardFlow.steps.chooseMultiSupplierCustomMessageMethods.heading')}
              >
                <Box mb="40px">
                  <Text mt="12px" mb="24px">
                    {t('request.awardFlow.steps.chooseMultiSupplierCustomMessageMethods.info1')}
                  </Text>
                  <lotPagesLayout.DescriptionListContainer style={{ marginTop: '8px' }}>
                    {messageActionOptions.map(({ value, label, description }) => (
                      <lotPagesLayout.DescriptionListItem
                        key={value}
                        label={label}
                        description={description}
                      />
                    ))}
                  </lotPagesLayout.DescriptionListContainer>
                  <Text mt="24px">
                    {t('request.awardFlow.steps.chooseMultiSupplierCustomMessageMethods.info2')}
                  </Text>
                </Box>

                <Box maxWidth="531px">
                  <GridIdPrefixProvider>
                    <GridMenuStateProvider>
                      <EditableGridDataProvider
                        rowData={initialRowData}
                        setValueInRow={immutableSet}
                      >
                        <FormikGridRowUpdater fieldName="rowData" hasRowChanged={hasRowChanged} />
                        <ChooseMessageActionGrid
                          viewportHeightDelta={200}
                        />
                      </EditableGridDataProvider>
                    </GridMenuStateProvider>
                  </GridIdPrefixProvider>
                </Box>
              </lotPagesLayout.Section>
            </StepNavigation>
          </lotPagesLayout.ContentWrapper>
        </Form>
      )}
    </Formik>
  );
};
