import * as React from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';

export const ProcessingLoader: React.FC<any> = () => {
  const { t } = useTranslation('general');

  return (
    <Flex flexDirection="column" justifyContent="center">
      <Text fontWeight={500} fontSize={6}>
        {t('processing')}
      </Text>
      <Flex justifyContent="center" mt={3}>
        <Icon icon="spinner" spin fontSize={5} />
      </Flex>
    </Flex>
  );
};
