import { ValidationRowCells } from '../../draft/ValidationRowCells';
import { useShowValidationErrors } from './draftContract';

export const ContractExchangeDefRowCells = ({ row, fieldName = 'exchangeDefs' }) => (
  <ValidationRowCells
    row={row}
    fieldName={fieldName}
    cellStyle={{
      opacity: row.original.isObsolete
        ? 0.4
        : undefined,
    }}
    useShowValidationErrors={useShowValidationErrors}
  />
);
