import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';
import { CancelButton, Modal, ModalBody, ModalFooter, ModalHeader, ModalProps, SaveButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useApi } from '../../../api';
import { TextField } from '../../../form/TextField';
import { useToaster } from '../../../toast';
import { useMutation } from '../../../useMutation';

type EditModalProps =
  { company: any; onCancel: any; onSave: any } &
  ModalProps;

export const EditRegisteredInformationModal = ({
  company,
  onCancel,
  onSave,
  ...props
}: EditModalProps) => {
  const { t } = useTranslation();
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const [updateCompany] = useMutation(
    api.updateCompany,
    {
      onSuccess: () => toaster.success(t('company.toaster.updateCompanyProfileSuccess')),
      onError: () => toaster.error(t('company.toaster.updateCompanyProfileError')),
      onSettled: () => queryClient.invalidateQueries(['publicCompany', { companyId: company._id }]),
    },
  );

  return (
    <Modal onRequestClose={onCancel} {...props}>
      <Formik
        initialValues={{
          registeredName: company.registeredName,
          number: company.number,
          DUNSNb: company.DUNSNb,
        }}
        validationSchema={
          yup.object().shape({
            registeredName: yup.string(),
            number: yup.string(),
            DUNSNb: yup.string(),
          })
        }
        onSubmit={({ registeredName, number, DUNSNb }) =>
          updateCompany({
            companyId: company._id,
            companyPatch: {
              registeredName,
              number,
              DUNSNb,
            },
          }, {
            onSuccess: onSave,
          })
        }
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              {t('company.dialog.editRegisteredInformation.heading')}
            </ModalHeader>
            <ModalBody>
              <Stack gap={3}>
                <TextField name="registeredName" label={t('company.dialog.editRegisteredInformation.fields.registeredName')} />
                <TextField name="number" label={t('company.dialog.editRegisteredInformation.fields.number')} />
                <TextField name="DUNSNb" label={t('company.dialog.editRegisteredInformation.fields.DUNSNb')} />
              </Stack>
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} />
              <SaveButton disabled={isSubmitting || !dirty} />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
