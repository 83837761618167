import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';

import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { AddThread } from './AddThread';
import { Thread } from './Thread';

import { useRequestComments } from './hooks';

export const RequestComments = ({
  rfqId,
}: {
  rfqId: string;
}) => {
  const { t } = useTranslation('request');
  const { threads } = useRequestComments(rfqId);

  return (
    <>
      <MessageBlock variant="info" mb="20px" mt={0}>
        {t('comments.info.privacy')}
      </MessageBlock>
      <AddThread rfqId={rfqId} />
      {!threads?.length ? (
        <Box
          sx={{
            padding: '20px',
            background: 'white',
            borderRadius: 'small',
            border: 'lightGray2',
            textAlign: 'center',
          }}
        >
          <IconText
            fontSize={2}
            color="subtext"
            icon="comment-o"
            gap={2}
            text={t('comments.info.noComments')}
          />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              height: 2,
              borderRadius: 'small',
              backgroundColor: 'lightGray2',
              marginBottom: '20px',
            }}
          />
          {map(threads, (thread) => (
            <Thread
              key={thread._id}
              thread={thread}
            />
          ))}
        </>
      )}
    </>
  );
};
