import { useState, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Live } from '@deepstream/common/rfq-utils';
import { Box, Flex, Text } from 'rebass/styled-components';
import { withProps } from '@deepstream/ui-utils/withProps';
import { WrapperButton } from '@deepstream/ui-kit/elements/button/WrapperButton';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { DateFormat } from '@deepstream/utils';
import * as lotPagesLayout from '../Live/lotPagesLayout';
import * as rfx from '../../../rfx';
import { Datetime2 } from '../../../Datetime';

const ALWAYS_VISIBLE_ROW_COUNT = 4;

const RowsExpandButton = withProps(WrapperButton, {
  sx: {
    padding: '5px 0',
    color: 'primary',
    textAlign: 'left',
    fontSize: 2,
    fontFamily: 'primary',
    fontWeight: 500,
    lineHeight: 1.3,
  },
});

const RowExpandButton = withProps(WrapperButton, {
  sx: {
    mt: '2px',
    padding: 0,
    color: 'primary',
    width: '100%',
    textAlign: 'left',
    fontSize: 2,
    fontFamily: 'primary',
    fontWeight: 500,
    lineHeight: 1.3,
  },
});

export const LotRevisionsSection = () => {
  const { t } = useTranslation(['translation', 'general']);
  const { lotsRevisionHistory } = rfx.useStructure<Live>();

  const [showAllRows, setShowAllRows] = useState(false);

  const [isRowExpandedById, setIsRowExpandedById] = useState<Record<string, boolean>>({});

  const rows = useMemo(() => {
    return [...lotsRevisionHistory.reverse()].flatMap((revisionEvent) => {
      const timestamp = new Date(revisionEvent.timestamp);

      return [
        {
          type: revisionEvent.type,
          id: `${timestamp.valueOf()}`,
          changeCount: revisionEvent.changes.length,
          timestamp,
        },
        ...revisionEvent.changes.map((change, index) => ({
          id: `${timestamp.valueOf()}-${index}`,
          ...change,
        })),
      ];
    });
  }, [lotsRevisionHistory]);

  const canExpandRows = rows.length > ALWAYS_VISIBLE_ROW_COUNT;

  const visibleRows = showAllRows || !canExpandRows
    ? rows
    : rows.slice(0, ALWAYS_VISIBLE_ROW_COUNT);

  return (
    <lotPagesLayout.Section heading={t('request.lots.lotRevisions')}>
      <Text mt="12px" mb="12px">
        {t('request.lots.lotRevisionsDescription')}
      </Text>

      <lotPagesLayout.OrderedListContainer>
        {visibleRows.map(row => {
          if (row.type === 'lot-revised') {
            return (
              <lotPagesLayout.TitledListItem
                key={row.id}
                pt="11px"
                pb="9px"
                titleProps={{ fontSize: 'inherit' }}
                title={
                  <>
                    <Datetime2 value={row.timestamp} format={DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ} />
                    <Box ml={2} as="span" color="subtext" fontWeight={400}>
                      {t('general.revisionCount', { count: row.changeCount })}
                    </Box>
                  </>
                }
              />
            );
          }

          const isRowExpanded = isRowExpandedById[row.id];

          return (
            <lotPagesLayout.TitledListItem
              key={row.id}
              pt="11px"
              pb="9px"
              titleProps={{ fontSize: 'inherit', fontWeight: 400 }}
              title={t(`request.lots.revisionChange.${row.type}.title`, { change: row })}
              body={isRowExpanded ? (
                <lotPagesLayout.Ul style={{ marginTop: '10px' }}>
                  <Trans
                    i18nKey={`request.lots.revisionChange.${row.type}.details`}
                    ns="translation"
                    components={{ li: <li /> }}
                  />
                </lotPagesLayout.Ul>
              ) : (
                null
              )}
              contentRight={
                <Flex flexDirection="column" ml={2}>
                  {isRowExpanded ? (
                    <RowExpandButton onClick={() => setIsRowExpandedById(state => ({ ...state, [row.id]: false }))}>
                      <Icon icon="caret-up" mr={2} />
                      {t('seeLess', { ns: 'general' })}
                    </RowExpandButton>
                  ) : (
                    <RowExpandButton onClick={() => setIsRowExpandedById(state => ({ ...state, [row.id]: true }))}>
                      <Icon icon="caret-down" mr={2} />
                      {t('seeMore', { ns: 'general' })}
                    </RowExpandButton>
                  )}
                </Flex>
              }
            />
          );
        })}
      </lotPagesLayout.OrderedListContainer>

      {!canExpandRows ? (
        null
      ) : showAllRows ? (
        <Box mt="6px">
          <RowsExpandButton onClick={() => setShowAllRows(false)}>
            <Icon icon="caret-up" mr={2} />
            {t('request.lots.seeFewerRows')}
          </RowsExpandButton>
        </Box>
      ) : (
        <Box mt="6px">
          <RowsExpandButton onClick={() => setShowAllRows(true)}>
            <Icon icon="caret-down" mr={2} />
            {t('request.lots.seeMoreRows', { count: rows.length - ALWAYS_VISIBLE_ROW_COUNT })}
          </RowsExpandButton>
        </Box>
      )}
    </lotPagesLayout.Section>
  );
};
