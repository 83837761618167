import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Live, StageStatus } from '@deepstream/common/rfq-utils';
import { flatMap, pick, values } from 'lodash';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { assignSums } from '@deepstream/utils';
import { BidProgress, emptyBidProgressWithPreviousStageResponses, getCompleteResponseItemCount, getTotalResponseItemCount } from '@deepstream/common/bidProgress';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { isBiddingOnLot } from '@deepstream/common/rfq-utils/lot';
import * as rfx from '../../../rfx';
import * as lotPagesLayout from '../Live/lotPagesLayout';
import { Bold } from '../../../Bold';
import { iconPropsByStageStatus } from '../Live/iconPropsByStageStatus';
import { ProgressPercentage } from '../../../ui/ProgressPercentage';

export const RequestRecipientStageStatusTable = () => {
  const { t } = useTranslation('translation');
  const theme = useTheme();
  const currentCompanyGroup = rfx.useCurrentCompanyGroup();
  const bid = rfx.useBid();
  const { lotById } = rfx.useStructure<Live>();
  const stageId = rfx.useStageId();
  // @ts-expect-error ts(2345) FIXME: Argument of type 'string | null' is not assignable to parameter of type 'string'.
  const isStageActive = rfx.useIsEnteredStageActive(stageId);

  const columns = useMemo(() => {
    return [
      {
        id: 'name',
        title: t('request.statusItem.name'),
        width: '20%',
      },
      {
        id: 'status',
        title: t('request.statusItem.status'),
        width: '20%',
      },
      {
        id: 'description',
        title: t('request.statusItem.description'),
      },
    ];
  }, [t]);

  const rows = useMemo(() => {
    const stageStatus = isStageActive ? StageStatus.LIVE : StageStatus.DEADLINE_PASSED;

    const includedRequirementGroupIds = [
      'general',
      ...Object.keys(lotById).filter(requirementGroupId => {
        const lot = lotById[requirementGroupId];

        return isBiddingOnLot(lot, bid.intentionStatusByLotId[requirementGroupId]);
      }),
    ];

    const progress = assignSums([
      { ...emptyBidProgressWithPreviousStageResponses },
      ...flatMap(
        pick(bid.progressByPageIdByRequirementGroupIdByStageId[stageId], includedRequirementGroupIds),
        progressByPageIdByRequirementGroupId => values(progressByPageIdByRequirementGroupId),
      ),
    ]) as BidProgress;

    const totalResponseItemCount = getTotalResponseItemCount(progress);
    const completeResponseItemCount = getCompleteResponseItemCount(progress);

    return [
      {
        id: 'stageStatus',
        name: t('request.stages.stageStatus'),
        status: (
          <>
            <Icon fixedWidth mr={1} {...iconPropsByStageStatus[stageStatus]} />
            <Bold>{t(`request.stages.status.${stageStatus}.status`)}</Bold>
          </>
        ),
        description: t(`request.stages.status.${stageStatus}.description.${currentCompanyGroup}`),
      },
      {
        id: 'stageCompletion',
        name: t('request.stages.stageCompletion'),
        status: totalResponseItemCount === 0 ? (
          <Bold>{t('general.notApplicableShort')}</Bold>
        ) : (
          <Bold>
            <ProgressPercentage
              width={15}
              progress={completeResponseItemCount / totalResponseItemCount}
              colorPrimary={theme.colors.success}
            />
          </Bold>
        ),
        description: totalResponseItemCount === 0
          ? t('request.stages.noRequirementsForCompletion')
          : t(`request.stages.stageCompletionDescription.${currentCompanyGroup}`),
      },
    ];
  }, [isStageActive, bid, lotById, stageId, t, theme.colors.success, currentCompanyGroup]);

  return (
    <lotPagesLayout.Table
      columns={columns}
      rows={rows}
    />
  );
};
