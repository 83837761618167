import { find } from 'lodash';
import { useMemo, useCallback, useContext } from 'react';
import * as React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ThemeContext } from 'styled-components';
import { transparentize } from 'polished';
import { Box, Flex, SxStyleProp } from 'rebass/styled-components';
import { CompanyMinimized } from '@deepstream/common/rfq-utils';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Row } from './ProfileLayout';
import { CompanyLogo2 as CompanyLogo } from './CompanyLogo';

type ListProps = {
  style?: React.CSSProperties;
  sx?: SxStyleProp;
  children: React.ReactNode;
};

const List = ({ style, sx, ...props }: ListProps) => (
  <Box
    as="ul"
    style={style}
    sx={{
      position: 'relative',
      listStyle: 'none',
      padding: 0,
      border: 'lightGray',
      overflow: 'hidden',
      '> * + *': {
        borderTop: 'lightGray',
      },
      ...sx,
    }}
    {...props}
  />
);

const LIST_ITEM_HEIGHT = 45;

export const DisabledOverlay = () => (
  <motion.div
    animate={{ opacity: 0.70, transition: { delay: 0 } }}
    style={{
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: 'white',
      opacity: 0,
    }}
  />
);

type CompanyListProps = {
  companies: CompanyMinimized[];
  disabledCompanies?: CompanyMinimized[];
  pendingRemovalCompanies?: CompanyMinimized[];
  maxVisibleItems?: number;
  renderActions?: any;
};

export const CompanyList: React.FC<CompanyListProps> = ({
  companies,
  pendingRemovalCompanies = [],
  maxVisibleItems,
  renderActions,
}) => {
  const theme = useContext(ThemeContext);

  const listStyle: React.CSSProperties | undefined = useMemo(
    () => maxVisibleItems
      ? { maxHeight: maxVisibleItems * LIST_ITEM_HEIGHT, overflowY: 'auto' }
      : undefined,
    [maxVisibleItems],
  );

  const isPendingRemoval = useCallback(
    (company) => find(pendingRemovalCompanies, { _id: company._id }),
    [pendingRemovalCompanies],
  );

  return (
    <List style={listStyle}>
      <AnimatePresence initial={false}>
        {companies.map((company, index) => (
          <motion.li
            key={company._id}
            layout
            style={{ backgroundColor: 'white', height: LIST_ITEM_HEIGHT, width: '100%', zIndex: index + 1 }}
          >
            <motion.div
              initial={{
                backgroundColor: transparentize(0.9, theme.colors.primary),
                boxShadow: `0px 15px 0px 0px ${transparentize(0.9, theme.colors.primary)}`,
              }}
              animate={isPendingRemoval(company) ? ({
                backgroundColor: transparentize(0.8, theme.colors.danger),
                transition: { delay: 0 },
              }) : ({
                backgroundColor: transparentize(0.9, theme.colors.white),
                boxShadow: `0px 15px 0px 0px ${transparentize(0.9, theme.colors.white)}`,
                transition: { delay: 1 },
              })}
              exit={{
                opacity: 0,
                transition: { delay: 0 },
              }}
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              <Row
                alignItems="center"
                justifyContent="space-between"
                height="100%"
                px={3}
                sx={{ position: 'relative' }}
              >
                {/* {isDisabled(company) && (
                  <DisabledOverlay />
                )} */}
                <Flex alignItems="center" flex={1}>
                  <Box mr={2}>
                    <CompanyLogo companyId={company._id} width={25} />
                  </Box>
                  <Box flex={1}>
                    <Truncate>
                      {company.name}
                    </Truncate>
                  </Box>
                </Flex>
                {renderActions && (
                  <Flex ml={2}>
                    {renderActions(company)}
                  </Flex>
                )}
              </Row>
            </motion.div>
          </motion.li>
        ))}
      </AnimatePresence>
    </List>
  );
};
