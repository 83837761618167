import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionnaireStatus, QuestionnaireTemplateStatus } from '@deepstream/common/preQual';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { isNil, omitBy } from 'lodash';
import { ConfirmActionDialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useQuestionnaireData, useQuestionnaireId, useQuestionnairePermissions } from './questionnaireUtils';
import useDownload from '../../../useDownload';
import { useCurrentUserLocale } from '../../../useCurrentUser';
import { useConfirmDialog } from '../../../ui/useModalState';
import { useReopenQuestionnaire, useMarkQuestionnaireAsExpired, useMarkQuestionnaireAsObsolete } from './questionnaireMutations';
import { useQuestionnaireTemplateData } from '../QuestionnaireTemplate/questionnaireTemplateUtils';

export const SenderQuestionnaireActions = () => {
  const questionnaireId = useQuestionnaireId({ required: true });
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const download = useDownload();
  const { t } = useTranslation(['preQualification', 'general']);
  const locale = useCurrentUserLocale();
  const template = useQuestionnaireTemplateData();
  const { confirm: confirmReopen, ...confirmReopenDialogProps } = useConfirmDialog();
  const { confirm: confirmMarkAsExpired, ...confirmMarkAsExpiredDialogProps } = useConfirmDialog();
  const { confirm: confirmMarkAsObsolete, ...confirmMarkAsObsoleteDialogProps } = useConfirmDialog();

  const questionnairePermissions = useQuestionnairePermissions();
  const questionnaire = useQuestionnaireData();

  const [reopenQuestionnaire] = useReopenQuestionnaire();
  const [markQuestionnaireAsExpired] = useMarkQuestionnaireAsExpired();
  const [markQuestionnaireAsObsolete] = useMarkQuestionnaireAsObsolete();

  const onReopen = useCallback(
    () => confirmReopen(reopenQuestionnaire),
    [confirmReopen, reopenQuestionnaire],
  );

  const onMarkAsExpired = useCallback(
    () => confirmMarkAsExpired(markQuestionnaireAsExpired),
    [confirmMarkAsExpired, markQuestionnaireAsExpired],
  );

  const onMarkAsObsolete = useCallback(
    () => confirmMarkAsObsolete(markQuestionnaireAsObsolete),
    [confirmMarkAsObsolete, markQuestionnaireAsObsolete],
  );

  const showReopenButton =
    [
      QuestionnaireStatus.APPROVED,
      QuestionnaireStatus.REJECTED,
      QuestionnaireStatus.EXPIRED,
      QuestionnaireStatus.OBSOLETE,
    ].includes(questionnaire.status) &&
    template.status !== QuestionnaireTemplateStatus.ARCHIVED &&
    questionnairePermissions.canReopenQuestionnaire;
  const showMarkAsExpiredButton = questionnaire.status === QuestionnaireStatus.APPROVED &&
    questionnairePermissions.canMarkAsExpired;
  const showMarkAsObsoleteButton = questionnaire.status !== QuestionnaireStatus.OBSOLETE &&
    questionnairePermissions.canMarkAsObsolete;

  const queryParams = new URLSearchParams(
    omitBy({
      locale,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }, isNil) as Record<string, string>,
  );

  const downloadReport = async (report: string) => {
    await download(`/download/company/${currentCompanyId}/questionnaire/${questionnaireId}/${report}?${queryParams.toString()}`);
  };

  return (
    <>
      <DropdownMenu
        variant="secondary"
        iconRight="caret-down"
        buttonText={t('questionnaireActions')}
      >
        {showReopenButton && (
          <DropdownMenuItem icon="undo" onSelect={onReopen}>
            {t('reopenQuestionnaire')}
          </DropdownMenuItem>
        )}
        {showMarkAsExpiredButton && (
          <DropdownMenuItem icon="clock" isIconRegular onSelect={onMarkAsExpired}>
            {t('questionnaire.markQuestionnaireAsExpired')}
          </DropdownMenuItem>
        )}
        {showMarkAsObsoleteButton && (
          <DropdownMenuItem icon="ban" isIconRegular onSelect={onMarkAsObsolete}>
            {t('questionnaire.markQuestionnaireAsObsolete')}
          </DropdownMenuItem>
        )}
        <DropdownMenuItem icon="download" onSelect={() => downloadReport('response')}>
          {t('download.responses')}
        </DropdownMenuItem>
      </DropdownMenu>
      <ConfirmActionDialog
        heading={t('confirmation', { ns: 'general' })}
        variant="info"
        message={questionnaire.status === QuestionnaireStatus.OBSOLETE ? t('questionnaire.confirmRestoreMessage') : t('questionnaire.confirmReopenMessage')}
        okButtonText={t('confirm', { ns: 'general' })}
        okButtonVariant="primary"
        {...confirmReopenDialogProps}
      />
      <ConfirmActionDialog
        heading={t('questionnaire.markAsExpired')}
        variant="warn"
        message={t('questionnaire.confirmMarkAsExpiredMessage')}
        okButtonText={t('confirm', { ns: 'general' })}
        okButtonVariant="primary"
        {...confirmMarkAsExpiredDialogProps}
      />
      <ConfirmActionDialog
        heading={t('questionnaire.markAsObsolete')}
        variant="warn"
        message={t('questionnaire.confirmMarkAsObsoleteMessage')}
        okButtonText={t('confirm', { ns: 'general' })}
        okButtonVariant="primary"
        {...confirmMarkAsObsoleteDialogProps}
      />
    </>
  );
};
