import { useMemo } from 'react';
import { filter } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { SupplierStateUser } from './modules/RequestSuppliers/types';
import { UsersAvatarList } from './UsersAvatarList';

export const SelectedUsersAvatarList = ({
  users,
  withValidation = true,
  isQuestionnaire,
}: {
  users: SupplierStateUser[];
  withValidation?: boolean;
  isQuestionnaire?: boolean;
}) => {
  const { t } = useTranslation();

  const hasOnlyInvitedUsers = filter(users, (user) => user?.isPending).length === users.length;

  const errorText = useMemo(() => {
    const isEmptySelection = filter(users, (user) => user?.selected).length === 0;

    if (isEmptySelection) return t('supplierUsersModal.validation.request.noEmptySelection');
    if (hasOnlyInvitedUsers && !isQuestionnaire) return t('supplierUsersModal.validation.request.noNonInvitedUsers');
  }, [users, t, hasOnlyInvitedUsers, isQuestionnaire]);

  return errorText && withValidation ? (
    <Flex alignItems="center" py={3}>
      <IconText
        icon="exclamation-circle"
        text={errorText}
        color="danger"
      />
    </Flex>
  ) : (
    <UsersAvatarList users={users} />
  );
};
