import { CellProps } from 'react-table';
import { QuestionnaireOverview } from '@deepstream/common/preQual';
import { Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';

export const QuestionnaireVersionCell = ({ value, row }: CellProps<QuestionnaireOverview, number>) => {
  const { t } = useTranslation('general');

  return (
    <Text>
      {t('countOfTotal', { count: value, total: row.original.latestTemplateVersion })}
    </Text>
  );
};
