import { useMemo } from 'react';
import { PageType, PageRole } from '@deepstream/common/rfq-utils';
import { find } from 'lodash';
import { Box, Text } from 'rebass/styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { InlineButton } from '@deepstream/ui-kit/elements/button/InlineButton';
import { useInvalidateQueryOnMessage } from '../useInvalidateQueryOnMessage';
import { AuctionConfigPanels } from './AuctionConfigPanels';
import * as rfx from '../rfx';
import { useCurrentCompanyId } from '../currentCompanyId';
import { useDraftRfqStructure, useRfqId } from '../useRfq';
import { Loading } from '../ui/Loading';
import { ErrorMessage } from '../ui/ErrorMessage';
import * as draft from './draft';
import { useToaster } from '../toast';
import { Bold } from '../Bold';
import { DraftToolbar } from '../modules/Request/DraftToolbar';
import { useRequestEditNavigation } from '../appNavigation';

const DraftAuctionConfigToolbar = () => {
  const rfqId = useRfqId();
  const { editingPanelId, isTemplate, isRevising } = rfx.useState();
  const isSuperUserOrOwner = rfx.useIsSuperUserOrOwner();
  const isEditing = Boolean(editingPanelId);

  if (isEditing) {
    return null;
  }

  return (
    <DraftToolbar
      rfqId={rfqId}
      tabId="auction"
      hasUnsavedChanges={false}
      showSubmitButton={false}
      isSuperUserOrOwner={isSuperUserOrOwner}
      isTemplate={isTemplate}
      isRevising={isRevising}
      isRequestValid={true}
      excessSupplierCount={0}
    />
  );
};

const HiddenAuctionPlaceholder = ({
  navigateToTeam,
}: {
  navigateToTeam: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Bold as="div" fontSize={5} mb={3}>
        <Icon icon="eye-slash" mr={2} />
        {t('request.pages.pageNotVisible')}
      </Bold>
      <Text mb={1}>
        {t('request.pages.pageNotVisible1')}
      </Text>
      <Text>
        <Trans i18nKey="request.pages.permissionsCanBeUpdatedByRequestOwner">
          Permissions can be updated by a request owner on
          the <InlineButton onClick={navigateToTeam}>Team</InlineButton> tab.
        </Trans>
      </Text>
    </>
  );
};

const AuctionConfigPanelsWrapper = ({
  navigateToTeam,
}: {
  navigateToTeam: () => void;
}) => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const pagePermissions = rfx.usePagePermissions();

  const [saveChanges] = draft.useSaveDraftChanges({
    getChanges: ({ changes }) => changes,
    onSuccess: () => toaster.success(t('request.toaster.changesSavedSuccess')),
    onError: () => toaster.error(t('request.toaster.changesSavedError')),
  });

  return pagePermissions.canRead ? (
    <rfx.SaveChangesProvider saveChanges={saveChanges}>
      <AuctionConfigPanels />
    </rfx.SaveChangesProvider>
  ) : (
    <HiddenAuctionPlaceholder navigateToTeam={navigateToTeam} />
  );
};

export const AuctionConfig = ({
  isRevising,
  isTemplate,
}: {
  isRevising?: boolean;
  isTemplate?: boolean;
}) => {
  const { t } = useTranslation();
  const rfqId = useRfqId({ required: true });
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { navigateToTeam } = useRequestEditNavigation();
  const { queryKey } = useDraftRfqStructure({
    rfqId,
    currentCompanyId,
    isTemplate,
  });

  useInvalidateQueryOnMessage(`rfx.${rfqId}`, queryKey);

  // TODO why do we have two useDraftRfqStructure() calls?
  const { data: rfxStructure, isLoading, isError, isSuccess } = useDraftRfqStructure({
    rfqId,
    currentCompanyId,
    isTemplate,
  });

  // Templates should be editable by all members of a company but the template
  // structure doesn't contain ownership / team membership information in
  // `teamById` which we use to determine page permissions so we provide
  // overrides here
  const rfxOverrides = useMemo(
    (): rfx.UserOverrides => isTemplate ? { isOwner: true, pageRole: PageRole.EDITOR } : {},
    [isTemplate],
  );

  const auctionPage = find(rfxStructure?.pages, { type: PageType.AUCTION });
  return (
    <rfx.StateProvider
      isRevising={isRevising}
      isTemplate={isTemplate}
    >
      {isLoading ? (
        <Box p="20px">
          <Loading />
        </Box>
      ) : isError || (isSuccess && rfxStructure && !auctionPage) ? (
        <Box p="20px">
          <ErrorMessage error={t('errors.unexpected')} />
        </Box>
      ) : isSuccess && rfxStructure && auctionPage ? (
        <rfx.OverridesProvider {...rfxOverrides}>
          <rfx.StructureProvider structure={rfxStructure}>
            <rfx.PageProvider page={auctionPage}>
              <AuctionConfigPanelsWrapper navigateToTeam={navigateToTeam} />
              <DraftAuctionConfigToolbar />
            </rfx.PageProvider>
          </rfx.StructureProvider>
        </rfx.OverridesProvider>
      ) : (
        null
      )}
    </rfx.StateProvider>
  );
};
