import { useTranslation } from 'react-i18next';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { useContract } from './useContract';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { Loading } from '../../ui/Loading';
import { ErrorMessage } from '../../ui/ErrorMessage';
import { ContractProvider } from './contract';
import { ContractDetails } from './ContractDetails';
import { ModelSizeLimitsProvider } from '../../modelSizeLimits';

export const ContractLiveDetails = ({
  contractId,
  pageId,
}: {
  contractId: string;
  pageId?: string;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { data: contract, isLoading, isError, isSuccess } = useContract({
    contractId,
    currentCompanyId,
    scope: 'current',
  });

  return (
    <>
      {isLoading ? (
        <PanelPadding>
          <Loading />
        </PanelPadding>
      ) : isError ? (
        <PanelPadding>
          <ErrorMessage error={t('errors.unexpected')} />
        </PanelPadding>
      ) : isSuccess && contract ? (
        // TODO instead of providing dummy model size limits here, better
        // refactor document (and other?) section panel components to only
        // depend on model sizes in edit mode
        <ModelSizeLimitsProvider maxComplexity={0} maxExchangeDefCount={0}>
          <ContractProvider contract={contract}>
            {/*
             // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'. */}
            <ContractDetails pageId={pageId} />
          </ContractProvider>
        </ModelSizeLimitsProvider>
      ) : (
        null
      )}
    </>
  );
};
