import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import { callAll } from '@deepstream/utils/callAll';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Modal, ModalHeader, ModalFooter, ModalBody, CancelButton, SaveButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { useToaster } from '../../../toast';
import { TextField } from '../../../form/TextField';
import {
  useListCategoriesQueryKey,
  useListCategories,
  useCreateCategory,
  CategoryModalValues,
  useCheckCategoryName,
  useUpdateCategory,
} from '../utils';
import { usePreQualNavigation } from '../../../AppRouting';

export const CategoryModal = ({
  id,
  close,
}: {
  id?: string;
  close: () => void;
}) => {
  const { t } = useTranslation(['preQualification']);
  const queryClient = useQueryClient();
  const getListCategoriesQueryKey = useListCategoriesQueryKey();
  const isEditMode = !!id;
  const { data, isLoading: isCategoryLoading } = useListCategories({
    // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
    filter: { categoryIds: [id] },
    queryOptions: { enabled: isEditMode },
  });
  const categoryToEdit = isEditMode ? data[0] : null;
  const [createCategory, { isLoading: isCreateLoading }] = useCreateCategory();
  const [updateCategory, { isLoading: isUpdateLoading }] = useUpdateCategory();
  const checkCategoryName = useCheckCategoryName();
  const toaster = useToaster();
  const { navigateToPreQualificationQuestions } = usePreQualNavigation();

  if (isCategoryLoading) return null;

  return (
    <Modal isOpen style={{ content: { width: '400px' } }}>
      <Formik<CategoryModalValues>
        initialValues={{
          // @ts-expect-error ts(18047) FIXME: 'categoryToEdit' is possibly 'null'.
          name: isEditMode ? categoryToEdit.name : '',
        }}
        validationSchema={
          yup.object().shape({
            name: yup.string().required(t('required', { ns: 'general' })).trim().min(1)
              .test('checkDuplicates', t('errors.categoryNameExists'), async (value) => {
                if (!value) return true;

                const isValid = await checkCategoryName(value);

                return isValid;
              }),
          })
        }
        validateOnMount
        onSubmit={(values) => {
          const opts = {
            onSuccess: callAll(
              () => {
                const listCategoriesQueryKey = getListCategoriesQueryKey();

                queryClient.invalidateQueries(listCategoriesQueryKey);
              },
              () => {
                // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
                const listCategoryQueryKey = getListCategoriesQueryKey({ filter: { categoryIds: [id] } });

                queryClient.invalidateQueries(listCategoryQueryKey);
              },
              () => {
                const listAllCategoriesQueryKey = getListCategoriesQueryKey({ filter: {} });

                queryClient.invalidateQueries(listAllCategoriesQueryKey);
              },
              () => close(),
              () => toaster.success(isEditMode ? t('success.categoryUpdated') : t('success.categoryCreated')),
              ({ _id: newCategoryId }) => {
                // If a new category was added, navigate to the new category tab
                if (!id) {
                  navigateToPreQualificationQuestions(newCategoryId);
                }
              },
            ),
            onError: () => toaster.error(t('errors.addCategoryNetwork')),
          };

          if (isEditMode) return updateCategory(id, values, opts);

          return createCategory(values, opts);
        }}
      >
        {({ isValid }) => {
          return (
            <Form>
              <ModalHeader>
                {isEditMode
                  ? t('categoryModal.headingEdit')
                  : t('categoryModal.headingAdd')
                }
              </ModalHeader>
              <ModalBody>
                {isEditMode && (
                  <MessageBlock variant="info" mb={4}>
                    {t('categoryModal.editInfo')}
                  </MessageBlock>
                )}
                <TextField
                  label={t('categoryModal.categoryNameField')}
                  placeholder={t('categoryModal.categoryNamePlaceholder')}
                  required
                  name="name"
                />
              </ModalBody>
              <ModalFooter>
                <CancelButton onClick={close} />
                <SaveButton
                  disabled={!isValid || isUpdateLoading || isCreateLoading}
                  // @ts-expect-error ts(2322) FIXME: Type 'string | false | undefined' is not assignable to type 'string | undefined'.
                  label={isEditMode && t('categoryModal.editCategorySubmitLabel')}
                />
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
