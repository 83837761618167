import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';
import { useField } from 'formik';

import { callAll } from '@deepstream/utils/callAll';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { ModalForm } from '../../ModalForm';
import { SwitchField } from '../../form/SwitchField';
import { TextField } from '../../form/TextField';
import { useApi } from '../../api';
import { useMutation } from '../../useMutation';
import { useToaster } from '../../toast';
import { useContractId } from './contract';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useContractQueryKey } from './useContract';
import { useCurrentUserLocale } from '../../useCurrentUser';
import { useLiveContractNavigation } from '../../appNavigation';

const IssueRevisionForm = () => {
  const { t } = useTranslation(['contracts', 'general']);
  const [{ value: notifyCounterparty }] = useField('notifyCounterparty');

  return (
    <Stack gap={3}>
      <SwitchField
        name="notifyCounterparty"
        text={t('review.notifyCounterparty')}
      />
      {notifyCounterparty ? (
        <TextField
          name="message"
          isMultiLine
          label={t('message', { ns: 'general' })}
          placeholder={t('review.revisionMessagePlaceholder')}
          required
        />
      ) : (
        <MessageBlock variant="warn" mt={0}>
          {t('review.revisionWarning')}
        </MessageBlock>
      )}
    </Stack>
  );
};

export const IssueRevisionModal = ({
  isOpen,
  onCancel,
}: {
  isOpen: boolean;
  onCancel: () => void;
}) => {
  const { t } = useTranslation(['contracts', 'general']);
  const api = useApi();
  const toaster = useToaster();
  const { navigateToSummary } = useLiveContractNavigation();
  const contractId = useContractId();
  const currentCompanyId = useCurrentCompanyId();
  const queryClient = useQueryClient();
  const liveContractQueryKey = useContractQueryKey({ scope: 'current' });
  const draftContractQueryKey = useContractQueryKey({ scope: 'draft' });
  const locale = useCurrentUserLocale();

  const [reviseContract] = useMutation(
    api.reviseContract,
    {
      onSuccess: callAll(
        () => queryClient.invalidateQueries(liveContractQueryKey),
        () => queryClient.invalidateQueries(draftContractQueryKey),
        () => toaster.success(t('toaster.contractRevised.success')),
        () => navigateToSummary(),
      ),
      onError: () => toaster.error(t('toaster.contractRevised.failed')),
    },
  );

  return (
    <ModalForm
      heading={t('issueRevision')}
      initialValues={{
        notifyCounterparty: true,
        message: '',
      }}
      validationSchema={
        yup.object().shape({
          notifyCounterparty: yup.boolean(),
          message: yup
            .string()
            .trim()
            .when('notifyCounterparty', {
              is: true,
              then: schema => schema.required(t('required', { ns: 'general' })),
              otherwise: schema => schema.nullable(),
            }),
        })
      }
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={async ({ notifyCounterparty, message }) => {
        await reviseContract({
          contractId,
          // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
          currentCompanyId,
          message: notifyCounterparty ? message : '',
          locale,
        });
      }}
      submitLabel={t('issueRevision')}
      style={{ content: { width: '380px' } }}
    >
      <IssueRevisionForm />
    </ModalForm>
  );
};
