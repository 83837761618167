import { ContractDraftHeader } from './ContractDraftHeader';
import * as Layout from '../../Layout';
import { ContractDraftReview } from './ContractDraftReview';
import { ContractTemplatePreviewControls } from './ContractTemplatePreviewControls';
import { ContractTemplatePreviewHeader } from './ContractTemplatePreviewHeader';
import { useContractId, useContractState } from './contract';

export const ContractDraftReviewPageContent = () => {
  const tabId = 'review';
  // @ts-expect-error ts(2339) FIXME: Property 'isTemplatePreview' does not exist on type 'ContractStateContextType | undefined'.
  const { isTemplatePreview } = useContractState();
  const contractId = useContractId();

  return (
    <Layout.HeaderAndContent
      header={
        isTemplatePreview ? (
          <ContractTemplatePreviewHeader />
        ) : (
          <ContractDraftHeader
            key={contractId}
            contractId={contractId}
            selectedTabId={tabId}
          />
        )
      }
      content={
        <>
          {isTemplatePreview && (
            <ContractTemplatePreviewControls templateId={contractId} />
          )}
          <ContractDraftReview
            contractId={contractId}
          />
        </>
      }
    />
  );
};
