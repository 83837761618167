import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { Flex, Box } from 'rebass/styled-components';
import { compact } from 'lodash';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { withProps } from '@deepstream/ui-utils/withProps';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { TextField } from '../../form/TextField';
import { PropertyList, ValueOrEmDash } from '../../PropertyList';
import { SummaryLabelConfigProvider } from '../../draft/SummaryLabelConfigProvider';
import { ValidationErrorValue } from '../../draft/ValidationErrorValue';
import { nestValues } from '../../nestValues';
import { useContractActions, useContractState, useContractId } from './contract';
import { useShowValidationErrors } from './draftContract';
import { ContractSummaryPanelHeader } from './ContractSummaryPanelHeader';
import { LeavePageModal } from '../../draft/LeavePageModal';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useApi, wrap } from '../../api';
import { useToaster } from '../../toast';
import { useMutation } from '../../useMutation';

const panelId = 'templateDetails';

export const ContractTemplateDetailsPanel = () => {
  const { t } = useTranslation(['contracts', 'general', 'translation']);
  const { stopEditing } = useContractActions();
  // @ts-expect-error ts(2339) FIXME: Property 'editingPanelId' does not exist on type 'ContractStateContextType | undefined'.
  const { editingPanelId, isTemplatePreview } = useContractState();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractId = useContractId();
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();

  const isEditingOtherPanel = editingPanelId && editingPanelId !== panelId;
  const isEditingThisPanel = editingPanelId && editingPanelId === panelId;

  const { data: existingTemplates } = useQuery(
    ['contractTemplates', { currentCompanyId }],
    wrap(api.getContractTemplates),
  );

  const { data: currentTemplate } = useQuery(
    ['contractTemplate', { currentCompanyId, templateId: contractId }],
    wrap(api.getContractTemplate),
  );

  const properties = useMemo(() => compact([
    {
      fieldName: 'name',
      name: t('summary.templateName'),
      value: currentTemplate?.meta.name,
      Component: nestValues(
        withProps(ValidationErrorValue, { useShowValidationErrors }),
        ValueOrEmDash,
      ),
    },
  ]), [currentTemplate?.meta.name, t]);

  const [updateContractTemplateField] = useMutation(
    api.updateContractTemplateField,
    {
      onSettled: callAll(
        () => queryClient.invalidateQueries(['contractTemplate', { currentCompanyId, templateId: contractId }]),
        () => queryClient.invalidateQueries(['contractTemplates', { currentCompanyId }]),
      ),
      onSuccess: stopEditing,
      onError: () => toaster.error(t('request.toaster.changesSavedError', { ns: 'translation' })),
    },
  );

  const updateTemplateName = useCallback(
    ({ name }) => updateContractTemplateField({
      companyId: currentCompanyId,
      templateId: contractId,
      fieldName: 'name',
      fieldValue: name,
    }),
    [currentCompanyId, contractId, updateContractTemplateField],
  );

  const initialValues = useMemo(() => ({ 'name': currentTemplate?.meta.name }), [currentTemplate?.meta.name]);

  const heading = t('summary.templateDetails');

  return (
    <Panel
      as="section"
      aria-label={heading}
      sx={{
        opacity: isEditingOtherPanel ? 0.5 : 1,
        boxShadow: isEditingThisPanel ? '0 0 8px 0 rgba(0, 0, 0, 0.3)' : '',
      }}
    >
      <ContractSummaryPanelHeader heading={heading} panelId={panelId} canEdit={!isTemplatePreview} />
      <PanelDivider />
      {isEditingThisPanel ? (
        <Formik
          validateOnBlur
          enableReinitialize
          initialValues={initialValues}
          validationSchema={
            yup.object().shape({
              'name': yup.string()
                .test(
                  'uniqueName',
                  t('template.errors.duplicateTemplateName', { ns: 'translation' }),
                  value => !existingTemplates?.find(template =>
                    template.meta.name.toLowerCase() === value?.toLowerCase().trim(),
                  ),
                )
                .required(t('required', { ns: 'general' })),
            })
          }
          onSubmit={updateTemplateName}
        >
          {({ isSubmitting, dirty, isValid, resetForm }) => (
            <Form>
              <SummaryLabelConfigProvider>
                <Box m={20}>
                  <TextField
                    label={t('summary.templateName')}
                    name="name"
                    required
                    // quick fix: we can remove setting the inputStyle height once a line height is
                    // set for Input
                    inputStyle={{ height: 40 }}
                  />
                </Box>
              </SummaryLabelConfigProvider>
              <PanelDivider />
              <PanelPadding>
                <Flex justifyContent="flex-end">
                  <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
                  <SaveButton disabled={isSubmitting || !dirty || !isValid} />
                </Flex>
              </PanelPadding>
              <LeavePageModal />
            </Form>
          )}
        </Formik>
      ) : (
        // no validation here because field cannot be submitted
        // when invalid
        <PropertyList properties={properties} />
      )}
    </Panel>
  );
};
