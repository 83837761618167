import { createContext, useMemo, useContext, useState, useCallback, useEffect } from 'react';
import { callAll } from '@deepstream/utils/callAll';
import { useModalState } from './ui/useModalState';
import { ExchangeSnapshot } from './types';

/*
 * Context that holds a function to navigate to an exchange's modal
 */

type NavigateToExchange = ({ exchangeId, recipientId }: { exchangeId?: string; recipientId?: string }) => void;

type ExchangeNavigation = {
  navigateToExchange: NavigateToExchange;
  openExchange: (exchange: ExchangeSnapshot) => void;
  closeExchange: () => void;
};

const ExchangeNavigationContext = createContext<ExchangeNavigation>({
  navigateToExchange: () => {},
  openExchange: () => {},
  closeExchange: () => {},
});

export const ExchangeNavigationProvider = ({ navigateToExchange, ...props }) => {
  const exchangeNavigation = useMemo(
    () => ({
      navigateToExchange,
      openExchange: exchange => navigateToExchange({
        exchangeId: exchange._id,
        recipientId: exchange.recipientId,
      }),
      closeExchange: () => navigateToExchange({}),
    }),
    [navigateToExchange],
  );

  return (
    <ExchangeNavigationContext.Provider value={exchangeNavigation} {...props} />
  );
};

export const useExchangeNavigation = () => useContext(ExchangeNavigationContext);

export type UseExchangeModalStateProps = {
  exchangeId?: string;
  recipientId?: string;
};

/**
 * Manages the exchange modal's open state
 */
export const useExchangeModalState = ({ exchangeId, recipientId }: UseExchangeModalStateProps = {}) => {
  // FIXME: rename "exchangeKey" to something better
  const initialExchangeKey = exchangeId ? { exchangeId, recipientId } : null;

  const [exchangeKey, setExchange] = useState<typeof initialExchangeKey>(initialExchangeKey);
  const { isOpen, open, close } = useModalState(Boolean(exchangeKey));

  const clearExchange = useCallback(
    () => setExchange(null),
    [setExchange],
  );

  const openExchange = useMemo(
    () => callAll(setExchange, open),
    [setExchange, open],
  );

  const closeExchange = useMemo(
    () => callAll(close, clearExchange),
    [clearExchange, close],
  );

  useEffect(
    () => {
      if (exchangeId && !isOpen) {
        openExchange({ exchangeId, recipientId });
      } else if (!exchangeId) {
        closeExchange();
      }
    },
    [exchangeId, openExchange, closeExchange, recipientId, isOpen],
  );

  return useMemo(
    () => ({
      isOpen,
      ...exchangeKey,
      setExchange,
      open: openExchange,
      close: closeExchange,
    }),
    [exchangeKey, isOpen, openExchange, closeExchange],
  );
};
