import { useMemo } from 'react';
import { isEqual, omit } from 'lodash';
import { Flex } from 'rebass/styled-components';
import { Draft, ExchangeDefinition, LineItemExchangeDefinition, SectionType } from '@deepstream/common/rfq-utils';
import {
  DeleteMenuItem,
  DiscardChangesMenuItem,
  EllipsisMenu,
  MakeNonObsoleteMenuItem,
  MakeObsoleteMenuItem,
  MoveUpMenuItem,
  MoveDownMenuItem,
  DuplicateMenuItem,
} from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import * as rfx from '../rfx';
import { useExchangeDefsField } from './exchangeDefs';

export const compareLiveVersionIgnoreKeys = [
  'isLive',
  'isObsolete',
  'liveVersion',
  'creatorId',
  '_id',
  'type',
  'user',
  'sectionId',
  'publishedAt',
  'publisherId',
  // `productOrService` is added on the server side to the root of exchangeDefs
  // to provide clients with details about an exchangeDef's `unspscCode`. It's not
  // part of the events in the rfqEnvelope.
  'productOrService',
];

/**
 * Renders a dropdown menu with the actions for each exchange definition
 */
export const ExchangeDefActions = ({
  index,
  fieldName = 'exchangeDefs',
  allowObsoleteStateChange = true,
}: {
  index: number;
  fieldName: string;
  allowObsoleteStateChange?: boolean;
}) => {
  const structure = rfx.useStructure<Draft>();
  const section = rfx.useSection();
  const {
    exchangeDefs,
    moveExchangeDef,
    removeExchangeDef,
    setExchangeDefIsObsolete,
    resetToLiveVersion,
    duplicateExchangeDef,
  } = useExchangeDefsField(fieldName, undefined, structure);

  const { exchangeDefById } = structure;
  const draftExchangeDef = exchangeDefs[index] as Partial<ExchangeDefinition<Draft>> & { _id: string };
  const exchangeId = draftExchangeDef._id;
  const exchangeDef = exchangeDefById[exchangeId];
  const rfxLineItemExchangeDef = exchangeDef && 'linkedExchangeDefId' in exchangeDef
    ? exchangeDefById[exchangeDef.linkedExchangeDefId] as LineItemExchangeDefinition | undefined
    : null;

  const hasStagedChanges = useMemo(() => {
    if (
      !exchangeDef ||
      !exchangeDef.isLive ||
      !exchangeDef.liveVersion ||
      exchangeDef.isObsolete
    ) {
      return false;
    }

    return !isEqual(
      omit(draftExchangeDef, compareLiveVersionIgnoreKeys),
      omit(exchangeDef.liveVersion, compareLiveVersionIgnoreKeys),
    );
  }, [draftExchangeDef, exchangeDef]);

  const isVesselPricingSection = section.type === SectionType.VESSEL_PRICING;

  return (
    <Flex height="40px" width="40px" justifyContent="flex-end" alignItems="center">
      <EllipsisMenu
        variant="secondary-outline"
        menuZIndex={202}
        width="40px"
        data-test="more-exchange-actions-button"
        popoverProps={{
          'data-test': 'more-exchange-actions-menu',
        }}
      >
        <MoveUpMenuItem
          onSelect={() => moveExchangeDef(index, -1)}
          disabled={index === 0}
        />
        <MoveDownMenuItem
          onSelect={() => moveExchangeDef(index, +1)}
          disabled={index === exchangeDefs.length - 1}
        />
        {!isVesselPricingSection && !exchangeDef?.isObsolete ? (
          <DuplicateMenuItem
            onSelect={() => duplicateExchangeDef(index)}
            data-test="duplicate-exchange-button"
          />
        ) : (
          null
        )}
        {exchangeDef?.isLive ? (
          (!allowObsoleteStateChange || exchangeDef.liveVersion?.isObsolete || rfxLineItemExchangeDef?.isObsolete) ? (
            null // if the live exchange def is obsolete, we can't change the state any further
          ) : draftExchangeDef.isObsolete ? (
            <MakeNonObsoleteMenuItem
              onSelect={() => setExchangeDefIsObsolete(exchangeId, false)}
            />
          ) : (
            <>
              <MakeObsoleteMenuItem
                disabled={hasStagedChanges}
                onSelect={() => setExchangeDefIsObsolete(exchangeId, true)}
              />
              {hasStagedChanges && (
                <DiscardChangesMenuItem
                  onSelect={() => resetToLiveVersion(exchangeDef.liveVersion!)}
                />
              )}
            </>
          )
        ) : (
          <DeleteMenuItem onSelect={() => removeExchangeDef(index)} />
        )}
      </EllipsisMenu>
    </Flex>
  );
};
