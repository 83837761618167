import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { PanelHeader } from '@deepstream/ui-kit/elements/Panel';
import { QuestionnaireTemplateStatus } from '@deepstream/common/preQual';
import { useQuestionnaireTemplateActions, useQuestionnaireTemplateData, useQuestionnaireTemplateState } from './questionnaireTemplateUtils';
import { LabeledValue } from '../../../draft/LabeledValue';
import { useDraftQuestionnaireTemplateNavigation } from '../../../appNavigation';
import { useUserFlags } from '../../../UserFlagsContext';

type PanelHeaderProps = {
  heading: string;
  panelId: string;
  canEdit: boolean;
  onEdit?: () => void;
  isInternal?: boolean;
};

export const SummaryPanelHeader = ({
  heading,
  panelId,
  canEdit,
  isInternal,
}: PanelHeaderProps) => {
  const { t } = useTranslation('preQualification');

  const { startEditing } = useQuestionnaireTemplateActions();
  // @ts-expect-error ts(2339) FIXME: Property 'editingPanelId' does not exist on type 'QuestionnaireTemplateStateContextType | undefined'.
  const { editingPanelId, isReview } = useQuestionnaireTemplateState();
  const template = useQuestionnaireTemplateData();
  const draftNavigation = useDraftQuestionnaireTemplateNavigation();
  const { hasManagePreQualPermission } = useUserFlags();

  const isEditingAnyPanel = Boolean(editingPanelId);
  const isEditingThisPanel = editingPanelId && editingPanelId === panelId;

  return (
    <PanelHeader heading={heading}>
      <Flex alignItems="center">
        {isInternal && (
          <LabeledValue
            label={t('questionnaireTemplate.supplierVisibility')}
            value={<IconText icon="eye-slash" text={t('questionnaireTemplate.hidden')} />}
            mr={4}
          />
        )}
        {isEditingThisPanel || !canEdit || !hasManagePreQualPermission || template.status === QuestionnaireTemplateStatus.ARCHIVED ? (
          null
        ) : isReview ? (
          <EditButton
            small
            type="button"
            variant="primary"
            onClick={() => draftNavigation.navigateToSummary()}
          />
        ) : (
          <EditButton
            small
            type="button"
            onClick={() => startEditing(panelId)}
            disabled={isEditingAnyPanel}
          />
        )}
      </Flex>
    </PanelHeader>
  );
};
