import {
  ActionType,
} from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';

import { usePopover, Popover } from '@deepstream/ui-kit/elements/popup/usePopover';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils/types';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useRfqId } from '../../../useRfq';
import * as rfx from '../../../rfx';
import { FixedFooterButton } from '../../../FixedFooter';
import { AuctionChat } from './AuctionChat';
import { useNotifications } from '../../Notifications';

type AuctionChatButtonProps =
  | { isSender: true; recipientId?: undefined; disabled?: boolean }
  | { isSender: false; recipientId: string; disabled?: boolean };

export const AuctionChatButton = ({ isSender, recipientId, disabled }: AuctionChatButtonProps) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const auctionPage = rfx.usePage();

  const popover = usePopover({
    placement: 'top-end',
    alignToWindowEdge: 'right',
  });

  const notifications = useNotifications({
    domain: isSender ? NotificationDomain.RFQ_SENT : NotificationDomain.RFQ_RECEIVED,
    action: NotificationAction.EXCHANGE_REPLY_SENT,
    to: {
      companyId: currentCompanyId,
    },
    meta: {
      rfqId: useRfqId(),
      actionType: ActionType.NONE,
      // @ts-expect-error ts(18047) FIXME: 'auctionPage' is possibly 'null'.
      pageId: auctionPage._id,
    },
  });

  return (
    <>
      <FixedFooterButton
        icon="comment"
        sx={{ borderLeft: 'lightGray' }}
        onClick={popover.toggle}
        // @ts-expect-error ts(2322) FIXME: Type 'Dispatch<SetStateAction<HTMLElement | undefined>>' is not assignable to type 'LegacyRef<HTMLButtonElement> | undefined'.
        ref={popover.setReferenceElement}
        badgeCount={notifications.length}
        disabled={disabled}
      >
        {t('request.auction.footer.chat')}
      </FixedFooterButton>
      <Popover width="100%" maxWidth={376} height={700} {...popover}>
        <AuctionChat
          isSender={isSender}
          recipientId={recipientId}
          onClose={popover.close}
        />
      </Popover>
    </>
  );
};
