import { useTranslation } from 'react-i18next';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog, DialogProps } from '@deepstream/ui-kit/elements/popup/Dialog';

type ConfirmChangeCurrencyDialogProps = Omit<DialogProps, 'heading' | 'body'>;

export const ConfirmChangeCurrencyDialog = ({ ...props }: ConfirmChangeCurrencyDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      heading={t('request.lineItems.currency.changeHeading')}
      body={
        <MessageBlock variant="error" my={3}>
          {t('request.lineItems.currency.changeWarning')}
        </MessageBlock>
      }
      style={{ content: { width: '500px' } }}
      okButtonText={t('general.confirm')}
      okButtonVariant="danger"
      cancelButtonText={t('general.cancel')}
      showCloseIcon
      {...props}
    />
  );
};
