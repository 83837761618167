import { Box, Flex, Text } from 'rebass/styled-components';
import {
  getExchangeDefFieldValue,
  getExchangeFieldValue,
  isReplyField,
} from '@deepstream/common/rfq-utils';
import { isNumber, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Clamp } from '@deepstream/ui-kit/elements/text/Clamp';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import {
  RowData,
} from '@deepstream/ui-kit/grid/core/utils';
import {
  exchangeLineClampByRowHeight,
  twoLinesRowLineClamp,
  RowHeight,
  rowHeightMeasurements,
} from '../rowHeight';
import { InlineLabel } from '../InlineLabel';
import { ConvertedCurrency } from '../../../../ui/Currency';
import { getUnspscCodeTooltipContent } from '../../../../ui/ExchangeDefsGrid/validationAwareValueCell';
import { NumberFormat } from '../../../../NumberFormat';
import { Datetime } from '../../../../Datetime';
import { SubcolumnCellContainer } from './SubcolumnCellContainer';
import { ExtendedLineItemExchangeSnapshot, GridLineItemsSection, LineItemSubRowData, LineItemsSubcolumnConfig } from './types';

const BuyerReplyFieldValue = ({ exchangeDef, exchange, field, lines }) => {
  const { t } = useTranslation('translation');
  const value = exchange.latestReply[field._id];

  return isNil(value) || value === '' ? (
    ['number', 'price'].includes(field.type) ? (
      <Truncate sx={{ textAlign: 'right' }}>
        <EmDash />
      </Truncate>
    ) : (
      <EmDash />
    )
  ) : field.type === 'number' ? (
    <Truncate sx={{ textAlign: 'right' }}>
      <NumberFormat
        displayType="text"
        thousandSeparator
        value={value}
      />
    </Truncate>
  ) : field.type === 'date' ? (
    <Datetime onlyDate value={value as Date} />
  ) : field.type === 'boolean' ? (
    <>
      {value ? (
        t('request.lineItems.deliveryDate.accepted')
      ) : (
        t('request.lineItems.deliveryDate.rejected')
      )}
    </>
  ) : field.type === 'price' ? (
    <Truncate sx={{ textAlign: 'right' }}>
      <ConvertedCurrency
        value={value}
        // @ts-expect-error ts(2345) FIXME: Argument of type 'string' is not assignable to parameter of type 'never'.
        currencyCode={getExchangeDefFieldValue(exchangeDef, 'evaluatorFieldCurrency')}
        decimalPlaces={field.decimalPlaces}
      />
    </Truncate>
  ) : (
    <Clamp lines={lines}>{value}</Clamp>
  );
};

const DefinitionFieldValue = ({ exchangeDef, field, lines }) => {
  const { t } = useTranslation();
  const value = exchangeDef[field.source.key];

  return isNil(value) || value === '' ? (
    ['number', 'price'].includes(field.type) ? (
      <Truncate sx={{ textAlign: 'right' }}>
        <EmDash />
      </Truncate>
    ) : (
      <EmDash />
    )
  ) : field.type === 'number' ? (
    <Truncate sx={{ textAlign: 'right' }}>
      <NumberFormat
        displayType="text"
        thousandSeparator
        value={value}
      />
    </Truncate>
  ) : field.type === 'date' ? (
    <Datetime onlyDate value={value as Date} />
  ) : field.type === 'boolean' ? (
    <>
      {value ? (
        t('request.lineItems.deliveryDate.accepted')
      ) : (
        t('request.lineItems.deliveryDate.rejected')
      )}
    </>
  ) : field.type === 'price' ? (
    <Truncate sx={{ textAlign: 'right' }}>
      <ConvertedCurrency
        value={value}
        // @ts-expect-error ts(2345) FIXME: Argument of type 'string' is not assignable to parameter of type 'never'.
        currencyCode={getExchangeDefFieldValue(exchangeDef, 'evaluatorFieldCurrency')}
        decimalPlaces={field.decimalPlaces}
      />
    </Truncate>
  ) : (
    <Clamp lines={lines}>{value}</Clamp>
  );
};

const TargetPriceFieldValue = ({ row, exchange }) => {
  const { t } = useTranslation();

  const extendedExchangeDef = row.original;
  const field = extendedExchangeDef.fields.targetPrice;
  const isReply = isReplyField(field);

  if (isReply && !exchange) {
    return (
      <>{t('general.notApplicableShort')}</>
    );
  }

  const value = isReply
    ? getExchangeFieldValue(exchange, field._id)
    // @ts-expect-error ts(2345) FIXME: Argument of type 'any' is not assignable to parameter of type 'never'.
    : getExchangeDefFieldValue(extendedExchangeDef, field._id);

  // @ts-expect-error ts(2345) FIXME: Argument of type 'string' is not assignable to parameter of type 'never'.
  const currency = getExchangeDefFieldValue(extendedExchangeDef, 'evaluatorFieldCurrency');

  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      alignItems="flex-end"
      sx={{ height: '100%', width: '100%' }}
    >
      {!isNumber(value) ? (
        <EmDash />
      ) : (
        <>
          <Text width="100%" textAlign="right" fontWeight="normal">
            <Truncate textAlign="right">
              <ConvertedCurrency value={value} currencyCode={currency} decimalPlaces={field.decimalPlaces} />
            </Truncate>
          </Text>
          {row.height !== rowHeightMeasurements[RowHeight.SHORT] ? (
            <Box
              fontSize="9px"
              fontWeight="normal"
              letterSpacing="0.3px"
              width="100%"
              textAlign="right"
            >
              <Truncate textAlign="right">
                <InlineLabel ml={2}>{t('general.total')}</InlineLabel>
                {isNumber(exchange?.computedFormulas?.targetTotalCost) ? (
                  <ConvertedCurrency value={exchange?.computedFormulas?.targetTotalCost} currencyCode={currency} />
                ) : (
                  <EmDash />
                )}
              </Truncate>
            </Box>
          ) : (
            null
          )}
        </>
      )}
    </Flex>
  );
};

const UnspscCodeFieldValue = ({ row, exchange }) => {
  const { t } = useTranslation();

  const extendedExchangeDef = row.original;
  const field = extendedExchangeDef.fields.unspscCode;
  const isReply = isReplyField(field);

  if (isReply && !exchange) {
    return (
      <>{t('general.notApplicableShort')}</>
    );
  }

  const productOrService = isReply
    ? exchange.productOrService
    : extendedExchangeDef.productOrService;

  return !productOrService ? (
    <EmDash />
  ) : (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      sx={{ height: '100%', width: '100%' }}
      fontWeight="normal"
    >
      <Tooltip
        content={productOrService ? (
          getUnspscCodeTooltipContent(productOrService, t)
        ) : (
          undefined
        )}
      >
        <Clamp lines={twoLinesRowLineClamp[row.height]} >
          {productOrService.title}
        </Clamp>
      </Tooltip>
      {row.height !== rowHeightMeasurements[RowHeight.SHORT] ? (
        <Truncate fontSize="9px" fontWeight="normal" letterSpacing="0.3px">
          <InlineLabel>{t('productsAndServices.code')}</InlineLabel>
          {productOrService._id}
          <InlineLabel ml={2}>{t('general.type')}</InlineLabel>
          {t(`productsAndServices.type.${productOrService.type}`)}
        </Truncate>
      ) : (
        null
      )}
    </Flex>
  );
};

export const BuyerDataCellWithSubcolumns = ({
  exchange,
  subcolumnConfig,
  isSupplierExchange,
  row,
}: {
  exchange: ExtendedLineItemExchangeSnapshot;
  subcolumnConfig: LineItemsSubcolumnConfig[];
  isSupplierExchange: boolean;
  row: RowData<LineItemSubRowData, GridLineItemsSection>;
}) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="row" alignItems="center" height="100%" width="100%">
      {subcolumnConfig.map((config, index) => {
        const field = exchange.def.fields[config._id];

        return (
          <SubcolumnCellContainer
            key={config._id}
            hideBorder={index === 0}
            width={config.width}
          >
            {!field ? (
              <>
                {t('general.notApplicableShort')}
              </>
            ) : field._id === 'targetPrice' ? (
              <TargetPriceFieldValue
                row={row}
                // Reading the target total cost from the first supplier exchanges is OK because
                // we make sure in `computeTargetTotalCost()` that a target total cost only
                // gets calculated when all referenced fields are not provided by suppliers, i.e.
                // when it's guaranteed that the target total cost will be the same for all
                // suppliers.
                exchange={exchange}
              />
            ) : field._id === 'unspscCode' ? (
              <UnspscCodeFieldValue
                row={row}
                exchange={isSupplierExchange && exchange}
              />
            ) : isSupplierExchange && isReplyField(field) ? (
              <BuyerReplyFieldValue
                exchangeDef={exchange.def}
                exchange={exchange}
                field={field}
                lines={exchangeLineClampByRowHeight[row.height]}
              />
            ) : (
              <DefinitionFieldValue
                exchangeDef={exchange.def}
                field={field}
                lines={exchangeLineClampByRowHeight[row.height]}
              />
            )}
          </SubcolumnCellContainer>
        );
      })}
    </Flex>
  );
};
