import { Draft, LineItemExchangeDefinition, isLinkedAuctionLineItemExchangeDef } from '@deepstream/common/rfq-utils';
import { ValidationRowCells } from './ValidationRowCells';
import * as rfx from '../rfx';

export const ExchangeDefRowCells = ({ row, fieldName = 'exchangeDefs' }) => {
  const { exchangeDefById } = rfx.useStructure<Draft>();
  const exchangeDef = row.original;

  const rfxLineItemExchangeDef = isLinkedAuctionLineItemExchangeDef(exchangeDef)
    ? exchangeDefById[exchangeDef.linkedExchangeDefId] as LineItemExchangeDefinition | undefined
    : null;

  return (
    <ValidationRowCells
      row={row}
      fieldName={fieldName}
      cellStyle={{
        opacity: row.original.isObsolete || rfxLineItemExchangeDef?.isObsolete
          ? 0.4
          : undefined,
      }}
    />
  );
};
