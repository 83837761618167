import { isEmpty, mapValues } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { Avatar, AvatarContainer, AvatarProps, AvatarWidth, sizes, sizeToInitialsFontSize } from './Avatar';

const MAX_AVATARS_SHOWN = 2;

const offsetFactor = 1 / 3;

const sizeToOffset = mapValues(sizes, size => `${size * offsetFactor}px`);

export const sizeToLabelFontSize = {
  xxs: 1,
  xs: 2,
  sm: 4,
  md: 6,
  lg: 8,
  xl: 10,
} as const;

export type AvatarGroupProps = Omit<AvatarProps, 'userId' | 'avatarId' | 'width'> & {
  width: AvatarWidth;
  users: { _id: string; name: string }[],
};

export const AvatarGroup = ({ users, ...props }: AvatarGroupProps) => {
  const { t } = useTranslation();
  const offset = sizeToOffset[props.width];

  const userIdsWithAvatar = useMemo(() => {
    return users.slice(0, MAX_AVATARS_SHOWN).map(user => user._id);
  }, [users]);

  return isEmpty(users) ? (
    <Flex alignItems="center" sx={{ userSelect: 'none' }}>
      <AvatarContainer
        width={props.width}
        fontSize={sizeToInitialsFontSize[props.width]}
        color="white"
        bg="subtext"
        sx={{ border: 'white', boxShadow: null }}
      >
        <Icon icon="user" />
      </AvatarContainer>
      <Text
        ml="2px"
        fontSize={sizeToLabelFontSize[props.width]}
        fontWeight={500}
      >
        {t('general.none')}
      </Text>
    </Flex>
  ) : (
    <Tooltip content={users.map(user => <Box key={user._id}>{user.name}</Box>)}>
      <Flex pl={offset} sx={{ userSelect: 'none' }}>
        {userIdsWithAvatar.map(userId => (
          <Box key={userId} flex="0 0 auto" ml={`-${offset}`}>
            <Avatar userId={userId} {...props} />
          </Box>
        ))}
        {users.length > MAX_AVATARS_SHOWN && (
          <Box flex="0 0 auto" ml={`-${offset}`}>
            <AvatarContainer
              width={props.width}
              fontSize={sizeToInitialsFontSize[props.width]}
              fontWeight={400}
              color="text"
              bg="lightGray2"
              sx={{ border: 'transparent', boxShadow: null }}
            >
              +{users.length - MAX_AVATARS_SHOWN}
            </AvatarContainer>
          </Box>
        )}
      </Flex>
    </Tooltip>
  );
};
