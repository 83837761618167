import { useTranslation } from 'react-i18next';
import { Box, Text, Flex } from 'rebass/styled-components';
import { CellProps } from 'react-table';
import {
  ContractStatus, contractStatusConfig, ExtendedReceivedContractOverview, ExtendedSentContractOverview, SentContractOverview,
} from '@deepstream/common/contract';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Bold } from '../../Bold';

export const ContractStatusFieldValue = ({ value }) => {
  const { t } = useTranslation('contracts');

  return t(`status.${value}`);
};

export const ContractStatusIcon = ({ row }:
  CellProps<ExtendedSentContractOverview | ExtendedReceivedContractOverview>,
) => {
  const value = row.original.status;
  const { icon } = contractStatusConfig[value];

  return icon ? (
    <Box alignSelf="flex-start" flex="0 0 auto" mr="8px" mt="2px">
      <Icon
        fixedWidth
        color={icon.color}
        icon={icon.value as IconProps['icon']}
        regular={icon.isRegular}
      />
    </Box>
  ) : (
    null
  );
};

export const LabeledContractStatus = ({ status }: { status: ContractStatus }) => {
  const { t } = useTranslation(['contracts', 'general']);
  const { icon } = contractStatusConfig[status];

  return (
    <Box ml={4}>
      <Text fontSize={1} fontWeight={500} mb={1}>
        {t('status', { ns: 'general' })}
      </Text>
      <Flex alignItems="center" fontSize={2}>
        <Icon
          fixedWidth
          color={icon.color}
          icon={icon.value as IconProps['icon']}
          regular={icon.isRegular}
          mr={1}
        />
        <Bold>{t(`status.${status}`)}</Bold>
      </Flex>
    </Box>
  );
};

export const ContractStatusCell = ({ value }: CellProps<SentContractOverview, ContractStatus>) => {
  const { t } = useTranslation('contracts');
  const icon = contractStatusConfig[value]?.icon;

  return (
    <IconText
      icon={icon.value as IconProps['icon']}
      iconColor={icon.color}
      text={t(`status.${value}`)}
    />
  );
};
