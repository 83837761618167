import { Trans, useTranslation } from 'react-i18next';
import { REMINDERS_PAGE_ID, ContractStatus } from '@deepstream/common/contract';
import { InlineButton } from '@deepstream/ui-kit/elements/button/InlineButton';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { CollapsibleMilestonesPanel } from './CollapsibleMilestonesPanel';
import { useContractState, useIsSender, usePagesPermissions, useContractData } from './contract';
import { ContractRemindersPanel } from './ContractRemindersPanel';
import { NoPageAccess } from './Permissions/NoPageAccess';
import { useDraftContractNavigation } from '../../appNavigation';

export const ContractReminders = () => {
  const { t } = useTranslation('contracts');
  // @ts-expect-error ts(2339) FIXME: Property 'isDraft' does not exist on type 'ContractStateContextType | undefined'.
  const { isDraft, isAmending, isRevising, isTemplate } = useContractState();
  const { status } = useContractData();
  const isSender = useIsSender();
  const permissionsByPageId = usePagesPermissions();
  const { navigateToSummary } = useDraftContractNavigation();
  const { canRead } = permissionsByPageId[REMINDERS_PAGE_ID];

  // Sanity check
  if (!isSender || isAmending || isRevising) {
    return null;
  }

  return canRead ? (
    <Stack gap={20}>
      {isDraft && (
        isTemplate ? (
          <MessageBlock variant="info" mt={0}>
            {t('editTemplateRemindersInfo')}
          </MessageBlock>
        ) : (
          <MessageBlock variant="info" mt={0}>
            <Trans i18nKey="editDraftRemindersInfo" ns="contracts">
              To edit your milestones go to the <InlineButton onClick={() => navigateToSummary()}>summary tab</InlineButton>.
              Once this contract is under negotiation, reminders will only be editable in a live state.
            </Trans>
          </MessageBlock>
        )
      )}
      {status === ContractStatus.NEGOTIATION && (
        <MessageBlock variant="info" mt={0}>
          <Trans i18nKey="editRevisingRemindersInfo" ns="contracts">
            You can only edit your milestones whilst revising your contract.
            To do so, go to the <InlineButton onClick={() => navigateToSummary()}>summary tab</InlineButton>.
          </Trans>
        </MessageBlock>
      )}
      {[ContractStatus.AGREED, ContractStatus.ACTIVE, ContractStatus.EXPIRED].includes(status) && (
        <MessageBlock variant="info" mt={0}>
          <Trans i18nKey="editAmendingContractRemindersInfo" ns="contracts">
            You can only edit your milestones whilst amending your contract.
            To do so, go to the <InlineButton onClick={() => navigateToSummary()}>summary tab</InlineButton>.
          </Trans>
        </MessageBlock>
      )}
      <CollapsibleMilestonesPanel />
      <ContractRemindersPanel />
    </Stack>
  ) : (
    <NoPageAccess />
  );
};
