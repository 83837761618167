import { useFormikContext } from 'formik';
import { flatMap } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { Modal, ModalHeader, ModalBody, ModalFooter, CancelButton, SaveButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Bold } from '../../../../Bold';
import { SelectedUsersAvatarList } from '../../../../SelectedUsersAvatarList';
import { SupplierCompanyDetails } from '../../../RequestSuppliers/SuppliersPanel';
import { FormValues, getSelectedSupplierUsers } from './utils';

export const ReviewModal = ({
  isOpen,
  onCancel,
}: {
  isOpen: boolean;
  onCancel: () => void;
}) => {
  const { t } = useTranslation(['preQualification', 'general']);
  const { values, handleSubmit, isSubmitting } = useFormikContext<FormValues>();
  const { templates, teamMembers, suppliers } = values;

  const supplierUsers = useMemo(
    () => flatMap(suppliers, getSelectedSupplierUsers),
    [suppliers],
  );

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnEsc
    >
      <ModalHeader onClose={onCancel}>
        {t('questionnaire.reviewAndSend')}
      </ModalHeader>
      <ModalBody p={0} width="500px">
        <Stack p="20px 20px 4px" gap="20px">
          <Box>
            <Text mb={2} sx={{ textTransform: 'uppercase' }}>
              {t('questionnaire_other')}
            </Text>
            <Stack gap={1}>
              {templates.map((template) => (
                <Flex key={template._id} mb={1} alignItems="center">
                  <Icon icon="circle" color="success" mr={2} />
                  <Text flex="1 1 auto">
                    <Truncate>
                      <Bold>{template.name}</Bold>
                    </Truncate>
                  </Text>
                </Flex>
              ))}
            </Stack>
          </Box>
          <Box>
            <Text mb={2} sx={{ textTransform: 'uppercase' }}>
              {t('supplier_other')}
            </Text>
            <Box
              sx={{
                '> * + *': {
                  borderTop: 'lightGray2',
                },
              }}
            >
              {suppliers.map((supplier) => (
                <Flex key={supplier.company._id} alignItems="center" sx={{ gap: 2 }} py={2}>
                  <Box flex="1 1 auto">
                    <SupplierCompanyDetails company={supplier.company} />
                  </Box>
                  <Box flex="0 0 auto">
                    {/*
                     // @ts-expect-error ts(2322) FIXME: Type '(number | SupplierStateUser | (() => IterableIterator<SupplierStateUser>) | { [x: number]: boolean | undefined; length?: boolean | undefined; ... 33 more ...; readonly [Symbol.unscopables]?: boolean | undefined; } | ... 31 more ... | ((index: number) => SupplierStateUser | undefined))[]' is not assignable to type 'SupplierStateUser[]'. */}
                    <SelectedUsersAvatarList users={getSelectedSupplierUsers(supplier)} isQuestionnaire />
                  </Box>
                </Flex>
              ))}
            </Box>
          </Box>
        </Stack>
        <Box sx={{ borderTop: 'lightGray2' }} />
        <Box p="20px">
          <Text mb={2} sx={{ textTransform: 'uppercase' }}>
            {t('summary')}
          </Text>
          <Stack gap={1}>
            <Bold>{t('questionnaireCount', { count: templates.length })}</Bold>
            <Bold>
              {t('supplierCount', { ns: 'general', count: suppliers.length })}
              {', '}
              {t('userCount', { ns: 'general', count: supplierUsers.length })}
            </Bold>
            <Bold>{t('questionnaire.teamMemberCount', { count: teamMembers.length })}</Bold>
          </Stack>
        </Box>
      </ModalBody>
      <ModalFooter>
        <CancelButton onClick={onCancel} />
        <SaveButton
          label={t('questionnaire.send')}
          onClick={() => handleSubmit()}
          disabled={isSubmitting}
        />
      </ModalFooter>
    </Modal>
  );
};
