import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { map, identity, sum } from 'lodash';
import { Flex, Box } from 'rebass/styled-components';
import { PreQualCategoryType } from '@deepstream/common/preQual';

import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { useHover } from '@deepstream/ui-kit/hooks/useHover';
import { useMediaQueries } from '@deepstream/ui-kit/hooks/useMediaQueries';
import { EllipsisMenu, DeleteMenuItem, EditMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { Tab, TabList } from '../../../ui/TabsVertical';
import { Loading } from '../../../ui/Loading';
import { ErrorMessage } from '../../../ui/ErrorMessage';
import { useListCategories } from '../utils';
import { PreQualCategory } from '../../../types';
import { useUserFlags } from '../../../UserFlagsContext';

const CategoryItem = ({
  category,
  onClickDelete,
  onClickEdit,
}: {
  category: PreQualCategory;
  onClickEdit?: (id: string) => void;
  onClickDelete?: (id: string) => void;
}) => {
  const [itemRef, itemHovered] = useHover();
  const [isDropdownExpanded, setIsDropdownExpanded] = useState(false);
  const { canHover: deviceCanHover } = useMediaQueries();
  const { hasManagePreQualPermission } = useUserFlags();

  const showDropdown = (
    hasManagePreQualPermission &&
    (!deviceCanHover || itemHovered || isDropdownExpanded) &&
    category.type !== PreQualCategoryType.NO_CATEGORY
  );

  return (
    <Flex ref={itemRef} alignItems="center" justifyContent="space-between">
      <Tab key={category._id} style={{ overflow: 'hidden' }}>
        <Flex sx={{ gap: 2 }} alignItems="center" height="28px">
          <Flex flex="1 1 auto" sx={{ gap: 1 }}>
            <Truncate flex="1 1 auto">{category.name}</Truncate>
            <Box flex="0 0 auto">({category.questionIds.length})</Box>
          </Flex>
          {showDropdown && (
            <Box flex="0 0 auto" onClick={event => { event.stopPropagation(); }}>
              <EllipsisMenu small onExpandedStateChange={setIsDropdownExpanded}>
                {/*
                 // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'. */}
                <EditMenuItem onSelect={() => onClickEdit(category._id)} />
                {/*
                 // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'. */}
                <DeleteMenuItem onSelect={() => onClickDelete(category._id)} />
              </EllipsisMenu>
            </Box>
          )}
        </Flex>
      </Tab>
    </Flex>
  );
};

export const CategoriesList = ({
  onClickEdit = identity,
  onClickDelete = identity,
}: {
  onClickEdit?: (id: string) => void;
  onClickDelete?: (id: string) => void;
}) => {
  const { data, isError, isLoading } = useListCategories();
  const { t } = useTranslation('preQualification');

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorMessage error={t('errors.loadingCategories')} />;
  }

  const numQuestions = sum(map(data, category => category.questionIds.length));

  return (
    <TabList>
      <Tab key="allCategories">
        <Flex sx={{ gap: 1 }} height="28px" alignItems="center">
          <Truncate flex="1 1 auto">{t('categories.all')}</Truncate>
          <Box flex="0 0 auto">({numQuestions})</Box>
        </Flex>
      </Tab>
      {map(data, (category) => (
        <CategoryItem
          key={category._id}
          category={category}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
        />
      ))}
    </TabList>
  );
};
