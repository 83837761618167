import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { PreWrapValueOrEmDash, PropertyList } from '../../../../PropertyList';
import { useQuestionnaireData, useQuestionnaireState } from '../questionnaireUtils';

export const PurposePanel = () => {
  const { t } = useTranslation('preQualification');
  const questionnaire = useQuestionnaireData();
  // @ts-expect-error ts(2339) FIXME: Property 'editingPanelId' does not exist on type 'QuestionnaireStateContextType | undefined'.
  const { editingPanelId } = useQuestionnaireState();

  const properties = useMemo(() => ([
    {
      fieldName: 'purpose',
      name: t('questionnairePurpose.overview'),
      value: questionnaire.internal.purpose,
      Component: PreWrapValueOrEmDash,
      heightAuto: true,
    },
  ]), [t, questionnaire]);

  const heading = t('questionnairePurpose.purpose');
  const isEditingOtherPanel = Boolean(editingPanelId);

  return (
    <Panel
      as="section"
      aria-label={heading}
      heading={heading}
      sx={{
        opacity: isEditingOtherPanel ? 0.5 : 1,
      }}
    >
      <PropertyList properties={properties} />
    </Panel>
  );
};
