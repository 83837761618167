import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link, LinkProps } from '@tanstack/react-router';
import { Tooltip } from '../popup/Tooltip';
import { Icon } from '../icon/Icon';

const BreadcrumbOl = styled.ol`
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const BreadcrumbLi = styled.li`
  display: inline-block;

  a {
    color: ${props => props.theme.colors.text} !important;
    white-space: nowrap;
    cursor: pointer;
    text-decoration: underline !important;
    font-size: ${props => props.theme.fontSizes[2]}px;
    font-weight: 500;
    line-height: 1.3;
  
    &:hover {
      color: ${props => props.theme.colors.primary} !important;
    }
  }
`;

export type BreadcrumbProps = {
  title?: string;
  linkProps: LinkProps;
  fullLength?: boolean;
};

const MAX_LINK_TITLE_LENGTH = 30;

const Breadcrumb = (breadcrumb: BreadcrumbProps) => {
  const { t } = useTranslation('general');
  const title: string = breadcrumb.title || t('untitled');

  return breadcrumb.fullLength ? (
    <Link {...breadcrumb.linkProps as any} style={{ whiteSpace: 'normal' }}>
      {title}
    </Link>
  ) : title.length > MAX_LINK_TITLE_LENGTH ? (
    <Tooltip content={title}>
      <Link {...breadcrumb.linkProps as any}>
        {title.slice(0, MAX_LINK_TITLE_LENGTH - 3)}...
      </Link>
    </Tooltip>
  ) : (
    <Link {...breadcrumb.linkProps as any}>
      {title}
    </Link>
  );
};

export const Breadcrumbs = ({
  breadcrumbs,
}: {
  breadcrumbs: BreadcrumbProps[];
}) => {
  const { t } = useTranslation('general');

  return (
    <nav aria-label={t('breadcrumbNavigation')}>
      <BreadcrumbOl style={{ display: 'inline-block' }}>
        {breadcrumbs.map((breadcrumb, index) => {
          return (
            <BreadcrumbLi key={index}>
              <Breadcrumb {...breadcrumb} />
              {index !== breadcrumbs.length - 1 && (
                <Icon mx={2} icon="chevron-right" fontWeight={400} fontSize={1} />
              )}
            </BreadcrumbLi>
          );
        })}
      </BreadcrumbOl>
    </nav>
  );
};
