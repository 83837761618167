import { useTranslation } from 'react-i18next';
import { Box, BoxProps } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Counter } from '../../../ui/Badge';

const counterSx = {
  height: '14px',
  minWidth: '14px',
  position: 'relative',
  top: '-2px',
};

export const UnreadComments = ({ count, ...props }: BoxProps & { count?: number }) => {
  const { t } = useTranslation('translation');

  return count ? (
    <Box as="span" {...props}>
      <Icon icon="comment" regular mr={1} />
      {t('request.suppliersTable.unreadComments')}
      <Counter count={count} ml={1} sx={counterSx} />
    </Box>
  ) : (
    null
  );
};
