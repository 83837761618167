import { useMemo, useState, useCallback } from 'react';
import { Formik, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Text, FlexProps } from 'rebass/styled-components';
import * as yup from 'yup';
import { noop, mapValues, keyBy, filter, compact } from 'lodash';

import { getRegionName } from '@deepstream/common';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { withProps } from '@deepstream/ui-utils/withProps';
import { Button, EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';
import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Panel, PanelHeader, PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  SaveButton,
  ModalBody,
  CancelButton,
} from '@deepstream/ui-kit/elements/popup/Modal';
import { DropdownMenuItem, EllipsisMenu } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { Avatar } from '../../Avatar';
import { CompanyInvitedBadge, Badge } from '../../Badge';
import { CompanyLogo } from '../../CompanyLogo';
import { CheckboxField } from '../../form/CheckboxField';
import { TextFieldBase } from '../../form/TextField';
import { SendInviteButton } from '../../InviteModal';
import { nestCells } from '../../nestCells';
import { RightAlignedCell } from '../../RightAlignedCell';
import { Table } from '../../Table';
import { BasicTableStyles } from '../../TableStyles';
import { Counter } from '../../ui/Badge';
import { Loading } from '../../ui/Loading';
import { useModalState } from '../../ui/useModalState';
import { AddSuppliersPanel } from './AddSuppliersPanel';
import { CompanyProfile, SupplierStateUser, SupplierState } from './types';
import { useSuppliersContext } from './SuppliersContext';
import { useCurrentUserLocale } from '../../useCurrentUser';
import { SelectedUsersAvatarList } from '../../SelectedUsersAvatarList';
import * as rfx from '../../rfx';
import { useExternalSystemWithExternalStatus, useSupplierIntegrationMap } from '../../useExternalSystems';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { ValueOrNotMappedCellWithTooltip } from '../../ValueOrNotMappedCell';
import { ExternalSupplierStatusHeader } from '../../ExternalSupplierStatusHeader';
import { InviteModalResponse } from '../../types';
import { useHoveredTableRow } from '../../TableHoveredRowContext';

const CompanySupplierHeader = ({
  rows,
}: {
  rows: { _id: string; company: CompanyProfile; users: SupplierStateUser[] }[]
}) => {
  const { t } = useTranslation('request');
  const maxSupplierCount = useSuppliersContext()?.maxSupplierCount;
  const exceedsMaxSupplierCount = maxSupplierCount && rows.length > maxSupplierCount;
  return (
    <Box alignItems="center" sx={{ mr: 2, display: 'inline-flex' }}>
      <Text mr={1}>{t('suppliers.table.heading.company')}</Text>
      <Counter
        count={rows.length}
        sx={{
          backgroundColor: exceedsMaxSupplierCount ? 'danger' : 'lightGray2',
          color: exceedsMaxSupplierCount ? 'white' : 'gray',
          fontWeight: 500,
        }}
      />
    </Box>
  );
};

export const SupplierCompanyDetails = ({ company, ...props }: FlexProps & { company: CompanyProfile }) => {
  const { t } = useTranslation();
  const locale = useCurrentUserLocale();

  return (
    <Flex alignItems="center" {...props}>
      <Box flex="0 0 auto">
        <CompanyLogo size="md" companyId={company._id} />
      </Box>
      <Box sx={{ lineHeight: 1.5 }}>
        <Truncate fontWeight={500}>{company.name}</Truncate>
        {company.address?.country && (
          <Text color="grey">
            <Icon icon="map-marker" mr={1} />
            {getRegionName(company.address.country, locale)}
          </Text>
        )}
      </Box>
      {company.isPending && (
        <Badge ml={2} tooltip={t('general.thisCompanyHasntSignedUpYet')}>
          {t('invite.status.invited')}
        </Badge>
      )}
    </Flex>
  );
};

const CompanySupplierCell = ({
  row,
}: {
  row: { original: { company: CompanyProfile; } }
}) => (
  <SupplierCompanyDetails company={row.original.company} py={3} />
);

const TemplateSupplierModal = ({
  isOpen,
  company,
  users,
  onCancel,
  canSendInvites,
  onInvitesSent,
  isRequest,
  isQuestionnaire,
  heading,
}: {
  isOpen: boolean;
  onCancel: () => void;
  company?: CompanyProfile;
  users: SupplierStateUser[];
  canSendInvites?: boolean;
  onInvitesSent?: (successfulInvites: InviteModalResponse[]) => void;
  isRequest?: boolean;
  isQuestionnaire?: boolean;
  heading: string;
}) => {
  const { t } = useTranslation('translation');
  const locale = useCurrentUserLocale();
  const { isLive } = useSuppliersContext();
  const [usersFilter, setUsersFilter] = useState('');
  const hasNoUsers = users.length === 0;
  const filteredUsers = filter(users, (user) => {
    // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
    return (user.name || user.email).toLowerCase().includes(usersFilter.toLowerCase());
  });

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnEsc
    >
      <ModalHeader onClose={onCancel}>
        {heading}
      </ModalHeader>
      <ModalBody px={3} width="600px">
        {company && (
          <Flex alignItems="center" py="12px">
            <CompanyLogo size="md" companyId={company._id} />
            <Box sx={{ lineHeight: 1.5 }}>
              <Text fontWeight={500}>{company.name}</Text>
              {company.address?.country && (
                <Text color="grey">
                  <Icon icon="map-marker" mr={1} />
                  {getRegionName(company.address.country, locale)}
                </Text>
              )}
            </Box>
          </Flex>
        )}
        <MessageBlock variant="info" mt={0}>
          {t('suppliers.templateSuppliersModalHeading', { ns: 'request' })}
        </MessageBlock>
        <Flex py={3} sx={{ backgroundColor: 'lightGray3' }}>
          <Box sx={{ flex: '1 1 300px' }} mr={2}>
            <TextFieldBase
              placeholder={t('supplierUsersModal.usersSearchPlaceholder')}
              disabled={hasNoUsers}
              onChange={(event) => setUsersFilter(event.target.value)}
              suffix={<Icon icon="search" />}
            />
          </Box>
          {canSendInvites && (
            <SendInviteButton
              isRequest={isRequest}
              isLive={isLive}
              isQuestionnaire={isQuestionnaire}
              // @ts-expect-error ts(2322) FIXME: Type '((successfulInvites: InviteModalResponse[]) => void) | undefined' is not assignable to type '(successfulInvites: InviteModalResponse[]) => void'.
              onInvitesSent={onInvitesSent}
            />
          )}
        </Flex>
        <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
          {filteredUsers.map((user) => {
            return (
              <Flex
                alignItems="center"
                key={user._id}
                sx={{
                  padding: '12px 0',
                  borderTop: 'lightGray2',
                  opacity: user?.isPending ? '0.5' : '1',
                }}
              >
                <Flex alignItems="center">
                  {/*
                   // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'. */}
                  <Avatar userId={user._id} sx={{ flex: '0 0 auto' }} />
                  <Text ml={3}>
                    <Truncate>{user.name || user.email}</Truncate>
                  </Text>
                </Flex>
                {user?.isPending && <CompanyInvitedBadge />}
              </Flex>
            );
          })}
          {hasNoUsers && (
            <Box py={3} textAlign="center">
              <Text>{t('supplierUsersModal.emptyUsersList')}</Text>
            </Box>
          )}
          {(filteredUsers.length === 0 && !hasNoUsers) && (
            <Box py={3} textAlign="center">
              <Text>{t('supplierUsersModal.usersFilterNotFound')}</Text>
            </Box>
          )}
        </Box>
      </ModalBody>
      <ModalFooter justifyContent="space-between" sx={{ height: '100%' }}>
        <Text>
          {t('userCount_other', {
            count: users.length,
            ns: 'general',
          })}
        </Text>
        <Flex>
          <CancelButton label={t('general.close')} onClick={onCancel} />
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

const SupplierModal = ({
  isOpen,
  company,
  onCancel,
  readOnly,
  canSendInvites,
  onInvitesSent,
  isRequest,
  isQuestionnaire,
  heading,
  disabledUserIds,
}: {
  isOpen: boolean;
  readOnly: boolean;
  onCancel: () => void;
  company?: CompanyProfile;
  canSendInvites?: boolean;
  onInvitesSent?: (successfulInvites: InviteModalResponse[]) => void;
  isRequest?: boolean;
  isQuestionnaire?: boolean;
  heading: string;
  disabledUserIds: string[];
}) => {
  const { t } = useTranslation('translation');
  const locale = useCurrentUserLocale();
  const { isLive } = useSuppliersContext();
  const { values, handleSubmit, setValues, errors, isValid } = useFormikContext<{
    users: SupplierStateUser[]
  }>();
  const { users } = values;
  const [usersFilter, setUsersFilter] = useState('');
  const selectedUsers = filter(users, (user) => user.selected);
  const selectedUsersCount = selectedUsers.length;
  const hasNoUsers = users.length === 0;
  const filteredUsers = filter(users, (user) => {
    // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
    return (user.name || user.email).toLowerCase().includes(usersFilter.toLowerCase());
  });

  // Keep track of initial indexes for showing their actual value when filtered
  const mapUserIndex = useMemo(() => {
    // @ts-expect-error ts(2464) FIXME: A computed property name must be of type 'string', 'number', 'symbol', or 'any'.
    return users.reduce((acc, current, index) => ({ ...acc, [current._id]: index }), {});
  }, [users]);

  const toggleAll = useCallback(() => {
    const selected = selectedUsersCount !== users.length;

    const newUsers = users.map((user) => ({
      ...user,
      // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
      selected: disabledUserIds.includes(user._id) ? user.selected : selected,
    }));

    setValues({ users: newUsers });
  }, [setValues, selectedUsersCount, users, disabledUserIds]);

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnEsc
    >
      <ModalHeader onClose={onCancel}>
        {heading}
      </ModalHeader>
      <ModalBody p={0} width="600px">
        {company && (
          <Flex alignItems="center" p={3} sx={{ borderBottom: 'lightGray2' }}>
            <CompanyLogo size="md" companyId={company._id} />
            <Box sx={{ lineHeight: 1.5 }}>
              <Text fontWeight={500}>{company.name}</Text>
              {company.address?.country && (
                <Text color="grey">
                  <Icon icon="map-marker" mr={1} />
                  {getRegionName(company.address.country, locale)}
                </Text>
              )}
            </Box>
          </Flex>
        )}
        <Flex p={3} sx={{ backgroundColor: 'lightGray3' }}>
          <Box sx={{ flex: '1 1 300px' }} mr={2}>
            <TextFieldBase
              placeholder={t('supplierUsersModal.usersSearchPlaceholder')}
              disabled={hasNoUsers}
              onChange={(event) => setUsersFilter(event.target.value)}
              suffix={<Icon icon="search" />}
            />
          </Box>
          {canSendInvites && !readOnly && (
            <SendInviteButton
              isRequest={isRequest}
              isLive={isLive}
              isQuestionnaire={isQuestionnaire}
              // @ts-expect-error ts(2322) FIXME: Type '((successfulInvites: InviteModalResponse[]) => void) | undefined' is not assignable to type '(successfulInvites: InviteModalResponse[]) => void'.
              onInvitesSent={onInvitesSent}
            />
          )}
        </Flex>
        <Box
          sx={{
            padding: [2, 3],
            backgroundColor: 'lightGray3',
            borderTop: 'lightGray2',
          }}
        >
          <Checkbox
            label={t('supplierUsersModal.usersAllCheckboxesHeading')}
            disabled={readOnly || hasNoUsers}
            onChange={() => toggleAll()}
            // @ts-expect-error ts(2322) FIXME: Type 'boolean | 0' is not assignable to type 'boolean | undefined'.
            indeterminate={selectedUsersCount && selectedUsersCount !== users.length}
            // @ts-expect-error ts(2322) FIXME: Type 'boolean | 0' is not assignable to type 'boolean | undefined'.
            checked={selectedUsersCount && selectedUsersCount === users.length}
          />
        </Box>
        <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
          {filteredUsers.map((user) => {
            return (
              <Flex
                alignItems="center"
                key={user._id}
                sx={{
                  padding: 3,
                  borderTop: 'lightGray2',
                  opacity: user?.isPending ? '0.5' : '1',
                }}
              >
                <CheckboxField
                  // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
                  name={`users[${mapUserIndex[user._id]}].selected`}
                  checkboxStyle={{ alignItems: 'center', marginRight: 2, padding: 0 }}
                  // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
                  disabled={readOnly || disabledUserIds.includes(user._id)}
                  fieldLabel={(
                    <Flex alignItems="center">
                      {/*
                       // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'. */}
                      <Avatar userId={user._id} sx={{ flex: '0 0 auto' }} />
                      <Text ml={3}>
                        <Truncate>
                          {user.name || user.email}
                        </Truncate>
                      </Text>
                    </Flex>
                  )}
                />
                {user?.isPending && (
                  <CompanyInvitedBadge />
                )}
              </Flex>
            );
          })}
          {hasNoUsers && (
            <Box padding={3} textAlign="center">
              <Text>{t('supplierUsersModal.emptyUsersList')}</Text>
            </Box>
          )}
          {(filteredUsers.length === 0 && !hasNoUsers) && (
            <Box padding={3} textAlign="center">
              <Text>{t('supplierUsersModal.usersFilterNotFound')}</Text>
            </Box>
          )}
        </Box>
        {(errors.users && !hasNoUsers) && (
          <MessageBlock
            variant="warn"
            margin={3}
            maxWidth="fit-content"
          >
            {errors.users as string}
          </MessageBlock>
        )}
      </ModalBody>
      <ModalFooter justifyContent="space-between" sx={{ height: '100%' }}>
        <Text>
          {t('supplierUsersModal.usersSelectedCount', {
            selected: selectedUsersCount,
            total: users.length,
          })}
        </Text>
        {!readOnly && (
          <Flex>
            <CancelButton onClick={onCancel} />
            <SaveButton
              disabled={!isValid}
              onClick={() => handleSubmit()}
            />
          </Flex>
        )}
      </ModalFooter>
    </Modal>
  );
};

export const SupplierModalForm = ({
  company,
  users,
  isOpen,
  onCancel,
  onSubmit,
  readOnly,
  canSendInvites,
  onInvitesSent,
  isRequest,
  isQuestionnaire,
  allowOnlyInvitedUsers,
  heading,
  noEmptySelectionMessage,
  noNonInvitedUsersMessage,
  disabledUserIds = [],
}: {
  company?: CompanyProfile;
  users: SupplierStateUser[];
  readOnly?: boolean;
  isOpen: boolean;
  canSendInvites?: boolean;
  onInvitesSent?: (successfulInvites: InviteModalResponse[]) => void;
  isRequest?: boolean;
  isQuestionnaire?: boolean;
  allowOnlyInvitedUsers?: boolean;
  heading: string;
  noEmptySelectionMessage?: string;
  noNonInvitedUsersMessage?: string;
  disabledUserIds?: string[];
  onCancel: () => void;
  onSubmit: (values: { users: SupplierStateUser[] }) => void;
}) => {
  const { t } = useTranslation('translation');

  const defaultNoEmptySelectionMessage = t('supplierUsersModal.validation.noEmptySelection');
  const defaultNoNonInvitedUsersMessage = t('supplierUsersModal.validation.noNonInvitedUsers');

  return (
    <Formik
      enableReinitialize
      initialValues={{ users }}
      validateOnMount
      validationSchema={
        yup.object().shape({
          users: yup
            .array()
              .of<{ selected: boolean; isPending: boolean; }>(
                yup.object().shape({
                  selected: yup.boolean(),
                  isPending: yup.boolean(),
                }),
              )
              .compact(value => !value.selected)
              .test(
                'at-least-one-user',
                () => noEmptySelectionMessage || defaultNoEmptySelectionMessage,
                (users) => {
                  return users.length !== 0;
                },
              )
              .test(
                'at-least-one-non-invited-is-selected',
                () => noNonInvitedUsersMessage || defaultNoNonInvitedUsersMessage,
                (users) => {
                  if (allowOnlyInvitedUsers) {
                    return true;
                  }

                  const pendingUsersCount = users.filter((value) => value?.isPending).length;

                  return users.length > 0 && users.length !== pendingUsersCount;
                },
              ),
        })
      }
      onSubmit={readOnly ? noop : onSubmit}
    >
      <SupplierModal
        isOpen={isOpen}
        company={company}
        heading={heading}
        onCancel={onCancel}
        // @ts-expect-error ts(2322) FIXME: Type 'boolean | undefined' is not assignable to type 'boolean'.
        readOnly={readOnly}
        canSendInvites={canSendInvites}
        onInvitesSent={onInvitesSent}
        isRequest={isRequest}
        isQuestionnaire={isQuestionnaire}
        disabledUserIds={disabledUserIds}
      />
    </Formik>
  );
};

const TemplateRowActionsCell = ({
  row,
  canSendInvites,
  onInvitesSent,
  isRequest,
  isQuestionnaire,
  removeSupplier,
  isTemplatePreview,
}: {
  row: {
    original: { users: SupplierStateUser[]; company: CompanyProfile; }
    index: number;
  };
  canSendInvites?: boolean;
  onInvitesSent?: (successfulInvites: InviteModalResponse[]) => void;
  isRequest?: boolean;
  isQuestionnaire?: boolean;
  isTemplatePreview?: boolean;
  removeSupplier: (supplierId: string) => void;
}) => {
  const { t } = useTranslation(['request', 'general', 'translation']);
  const supplierModal = useModalState();
  const { company } = row.original;
  const { hoveredRowIndex } = useHoveredTableRow();
  const isRowHovered = hoveredRowIndex === row.index;

  return (
    <>
      {isRowHovered ? (
        <Flex sx={{ columnGap: 2 }}>
          <Button
            iconLeft="users"
            variant="secondary-outline"
            color="black"
            small
            onClick={() => supplierModal.open()}
          >
            {t('suppliers.table.actions.viewCurrentUsers')}
          </Button>
          {!isTemplatePreview && (
            <Button
              variant="secondary-outline"
              iconLeft="times"
              color="danger"
              onClick={() => removeSupplier(company._id)}
              small
            >
              {t('suppliers.table.actions.remove')}
            </Button>
          )}
        </Flex>
      ) : null}

      {/* Prevent rendering all modal forms if they are not open */}
      {supplierModal.isOpen && (
        <TemplateSupplierModal
          company={row.original.company}
          users={row.original.users}
          canSendInvites={canSendInvites && !isTemplatePreview}
          onInvitesSent={onInvitesSent}
          isRequest={isRequest}
          isQuestionnaire={isQuestionnaire}
          heading={t('supplierUsersModal.company', { ns: 'translation' })}
          onCancel={supplierModal.close}
          isOpen
        />
      )}
    </>
  );
};

const RowActionsCell = ({
  row,
  isReadOnly,
  canSendInvites,
  onInvitesSent,
  isRequest,
  isQuestionnaire,
  isTemplate,
  removeSupplier,
  updateUsersSelection,
}: {
  row: {
    original: { users: SupplierStateUser[]; company: CompanyProfile; }
    index: number;
  };
  isReadOnly?: boolean;
  canSendInvites?: boolean;
  onInvitesSent?: (successfulInvites: InviteModalResponse[]) => void;
  isRequest?: boolean;
  isQuestionnaire?: boolean;
  isTemplate?: boolean;
  removeSupplier: (supplierId: string) => void;
  updateUsersSelection: (supplierId: string, users: { [_id: string]: boolean }) => void;
}) => {
  const { t } = useTranslation(['request', 'general', 'translation']);
  const supplierModal = useModalState();
  const { company } = row.original;
  const isFrozenCompany = company?.isFrozen;
  const { hoveredRowIndex } = useHoveredTableRow();
  const isRowHovered = hoveredRowIndex === row.index;

  return (
    <>
      {isTemplate ? (
        isRowHovered ? (
          <Flex sx={{ columnGap: 2 }}>
            <Button
              iconLeft="users"
              variant="secondary-outline"
              color="black"
              small
              onClick={() => supplierModal.open()}
            >
              {t('suppliers.table.actions.viewCurrentUsers')}
            </Button>
            <Button
              variant="secondary-outline"
              iconLeft="times"
              color="danger"
              onClick={() => removeSupplier(company._id)}
              small
            >
              {t('suppliers.table.actions.remove')}
            </Button>
          </Flex>
        ) : null
      ) : isFrozenCompany || isReadOnly ? (
        <ButtonGroup marginBetween="-1px">
          <Button
            iconLeft="users"
            variant="primary-outline"
            small
            onClick={() => {
              supplierModal.open();
            }}
          >
            {t('suppliers.table.actions.view')}
          </Button>
        </ButtonGroup>
      ) : (
        <ButtonGroup marginBetween="-1px">
          <Button
            iconLeft="pencil"
            variant="primary-outline"
            small
            onClick={() => {
              supplierModal.open();
            }}
          >
            {t('edit', { ns: 'general' })}
          </Button>
          <EllipsisMenu small>
            <DropdownMenuItem
              icon="times"
              color="danger"
              onSelect={() => removeSupplier(company._id)}
            >
              {t('suppliers.table.actions.remove')}
            </DropdownMenuItem>
          </EllipsisMenu>
        </ButtonGroup>
      )}

      {/* Prevent rendering all modal forms if they are not open */}
      {supplierModal.isOpen && (
        <SupplierModalForm
          company={row.original.company}
          users={row.original.users}
          readOnly={isFrozenCompany || isReadOnly}
          canSendInvites={canSendInvites}
          onInvitesSent={onInvitesSent}
          isRequest={isRequest}
          isQuestionnaire={isQuestionnaire}
          heading={t('supplierUsersModal.company', { ns: 'translation' })}
          noEmptySelectionMessage={t('supplierUsersModal.validation.request.noEmptySelection', { ns: 'translation' })}
          noNonInvitedUsersMessage={t('supplierUsersModal.validation.request.noNonInvitedUsers', { ns: 'translation' })}
          onCancel={supplierModal.close}
          onSubmit={(values) => {
            const selectedUsersById = mapValues(
              keyBy(values.users, user => user._id),
              // @ts-expect-error ts(2339) FIXME: Property 'selected' does not exist on type 'number | SupplierStateUser | (() => IterableIterator<SupplierStateUser>) | { [x: number]: boolean | undefined; length?: boolean | undefined; ... 33 more ...; readonly [Symbol.unscopables]?: boolean | undefined; } | ... 31 more ... | ((index: number) => SupplierStateUser | undefined)'.
              (user) => user.selected,
            );

            updateUsersSelection(company._id, selectedUsersById);
            supplierModal.close();
          }}
          isOpen
          allowOnlyInvitedUsers={isQuestionnaire}
        />
      )}
    </>
  );
};

const CompanyUsersCell = ({
  row,
  isQuestionnaire,
  isTemplate,
}: {
  row: { original: { users: SupplierStateUser[]; company: CompanyProfile; } };
  isQuestionnaire?: boolean;
  isTemplate?: boolean;
}) => {
  const { t } = useTranslation('request');
  const { users: allUsers } = row.original;
  const users = allUsers.filter(({ selected }) => selected);

  return (
    <Box py={3}>
      {isTemplate ? (
        <Tooltip content={t('suppliers.table.templateUsersTooltip') as string} >
          <Box>
            <IconText
              icon="question-circle-o"
              text={t('suppliers.table.templateUsersPlaceholder')}
              iconPosition="right"
              variant="secondary"
              color="gray"
            />
          </Box>
        </Tooltip>
      ) : (
        <SelectedUsersAvatarList
          users={users}
          isQuestionnaire={isQuestionnaire}
        />
      )}
    </Box>
  );
};

export const SuppliersTable = ({
  suppliers,
  isReadOnly,
  canSendInvites,
  onInvitesSent,
  isRequest,
  isQuestionnaire,
  isTemplate,
  isTemplatePreview,
  removeSupplier,
  updateUsersSelection,
}: {
  suppliers: SupplierState[];
  isReadOnly?: boolean;
  canSendInvites?: boolean;
  onInvitesSent?: (successfulInvites: InviteModalResponse[]) => void;
  isRequest?: boolean;
  isQuestionnaire?: boolean;
  isTemplate?: boolean;
  isTemplatePreview?: boolean;
  removeSupplier: (supplierId: string) => void;
  updateUsersSelection: (supplierId: string, users: { [_id: string]: boolean }) => void;
}) => {
  const { t } = useTranslation('request');
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const externalSystem = useExternalSystemWithExternalStatus({ currentCompanyId });
  const suppliersIntegrationMap = useSupplierIntegrationMap({ currentCompanyId, systemId: externalSystem?.systemId });

  const columns = useMemo(
    () =>
      compact([
        {
          id: 'supplier-company-name',
          Header: CompanySupplierHeader,
          accessor: 'company.name',
          sort: 'name',
          Cell: CompanySupplierCell,
        },
        externalSystem?.externalData?.hasSupplierStatuses ? {
          id: 'supplier-status',
          Header: withProps(
            ExternalSupplierStatusHeader,
            { externalSystemName: externalSystem.name, externalStatusesUpdatedAt: externalSystem.externalStatusesUpdatedAt },
          ),
          accessor: (supplier) => {
            return suppliersIntegrationMap?.[supplier.company._id] && t(`externalStatus.externalStatus.${suppliersIntegrationMap?.[supplier.company._id]}`, { ns: 'integration' });
          },
          Cell: withProps(ValueOrNotMappedCellWithTooltip, { externalSystemName: externalSystem.name }),
        } : null,
        {
          id: 'supplier-company-users',
          Header: t('suppliers.table.heading.user'),
          Cell: withProps(CompanyUsersCell, { isQuestionnaire, isTemplate }),
          ...(isTemplate && { width: 320 }),
        }, {
          id: 'supplier-row-actions',
          Header: ' ',
          width: isTemplate ? 320 : 160,
          Cell: nestCells(
            RightAlignedCell,
            isTemplate
              ? withProps(TemplateRowActionsCell, {
                  canSendInvites,
                  onInvitesSent,
                  isRequest,
                  isQuestionnaire,
                  removeSupplier,
                  isTemplatePreview,
                })
              : withProps(RowActionsCell, {
                  isReadOnly,
                  canSendInvites,
                  onInvitesSent,
                  isRequest,
                  isQuestionnaire,
                  isTemplate,
                  removeSupplier,
                  updateUsersSelection,
                }),
          ),
        },
      ]),
    [
      externalSystem,
      t,
      isQuestionnaire,
      isTemplate,
      canSendInvites,
      onInvitesSent,
      isRequest,
      removeSupplier,
      isTemplatePreview,
      isReadOnly,
      updateUsersSelection,
      suppliersIntegrationMap,
    ],
  );

  return (
    <BasicTableStyles hoverBackgroundColor="lightGray6">
      <Table
        isSortable
        isPaginated
        enforcePagination
        smallPageControls
        hasStaticHeight={false}
        minCellHeight={75}
        columns={columns}
        data={suppliers}
        pageSizes={[5, 10, 20, 50, 100]}
      />
    </BasicTableStyles>
  );
};

export const SuppliersPanel = ({
  onClickEdit,
}: {
  onClickEdit?: () => void;
}) => {
  const {
    suppliers,
    isLoading,
    isReadOnly,
    removeSupplier,
    updateUsersStatus,
    addInvitations,
  } = useSuppliersContext();
  const { t } = useTranslation('request');
  const { isRevising, isReview, isTemplate, isTemplatePreview } = rfx.useState();

  return isRevising ? (
    isReview ? (null) : (
      <MessageBlock variant="info" mb="20px">
        {t('request.visibility.howToAddSuppliersInfo', { ns: 'translation' })}
      </MessageBlock>
    )
  ) : (
    <Panel>
      <PanelHeader
        heading={t('suppliers.heading')}
        description={isReadOnly ? undefined : t('suppliers.description')}
      >
        {isReadOnly && typeof onClickEdit === 'function' && (
          <EditButton small variant="primary" onClick={onClickEdit} />
        )}

        {!isReadOnly && <AddSuppliersPanel />}
      </PanelHeader>
      <PanelDivider />

      {isLoading ? (
        <Box px={4} py={3}>
          <Loading />
        </Box>
      ) : !suppliers.length ? (
        <Text color="gray" p={4}>
          {t('suppliers.table.noEntries')}
        </Text>
      ) : (
        <SuppliersTable
          suppliers={suppliers}
          isReadOnly={isReadOnly}
          isRequest
          canSendInvites
          onInvitesSent={addInvitations}
          removeSupplier={removeSupplier}
          updateUsersSelection={updateUsersStatus}
          isTemplate={isTemplate}
          isTemplatePreview={isTemplatePreview}
        />
      )}
    </Panel>
  );
};
