import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Flex } from 'rebass/styled-components';
import { SUMMARY_PAGE_ID } from '@deepstream/common/contract';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { PanelHeader } from '@deepstream/ui-kit/elements/Panel';
import { useApi, wrap } from '../../api';
import { LabeledValue } from '../../draft/LabeledValue';
import {
  useContractActions,
  useContractData,
  useContractState,
  useIsLinkedToRequest,
  useIsPendingCollaborator,
  usePagesPermissions,
} from './contract';
import { ImportTagsButton } from './ImportTagsButton';
import { useDraftContractNavigation } from '../../appNavigation';

type PanelHeaderProps = {
  heading: string;
  panelId: string;
  canEdit: boolean;
  onEdit?: () => void;
};

export const ContractSummaryPanelHeader = ({
  heading,
  panelId,
  canEdit,
  onEdit: onEditProp,
}: PanelHeaderProps) => {
  const { t } = useTranslation('contracts');
  const api = useApi();
  const { startEditing } = useContractActions();
  // @ts-expect-error ts(2339) FIXME: Property 'editingPanelId' does not exist on type 'ContractStateContextType | undefined'.
  const { editingPanelId, isReview } = useContractState();
  const isPendingCollaborator = useIsPendingCollaborator();
  const draftContractNavigation = useDraftContractNavigation();
  const isLinkedToRequest = useIsLinkedToRequest();
  const contract = useContractData();
  const permissionsByPageId = usePagesPermissions();
  const { canEdit: canEditSummaryPage } = permissionsByPageId[SUMMARY_PAGE_ID];

  const { data, isSuccess } = useQuery(
    ['hasProductsAndServices', { rfqId: contract.requestId }],
    wrap(api.hasProductsAndServices),
    {
      enabled: isLinkedToRequest,
    },
  );

  const isEditingAnyPanel = Boolean(editingPanelId);
  const isEditingThisPanel = editingPanelId && editingPanelId === panelId;
  const canImportTags = panelId === 'productsAndServices' && isLinkedToRequest && isSuccess &&
    data.hasProductsAndServices;

  const onEdit = onEditProp
    ? () => onEditProp()
    : () => startEditing(panelId);

  return (
    <PanelHeader heading={heading}>
      <Flex alignItems="center">
        {panelId === 'spendData' && (
          <LabeledValue
            label={t('summary.counterpartyVisibility')}
            value={<IconText icon="eye-slash" text={t('counterparty.hidden')} />}
            mr={3}
          />
        )}
        {isPendingCollaborator || isEditingThisPanel || !canEdit || !canEditSummaryPage ? (
          null
        ) : isReview ? (
          <EditButton
            small
            type="button"
            variant="primary"
            onClick={() => draftContractNavigation.navigateToSummary()}
          />
        ) : (
          <>
            {canImportTags && (
              <ImportTagsButton mr={2} disabled={contract.hasImportedProductsAndServices} />
            )}
            <EditButton
              small
              type="button"
              onClick={onEdit}
              disabled={isEditingAnyPanel}
            />
          </>
        )}
      </Flex>
    </PanelHeader>
  );
};
