import { Box, Flex } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';

import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { PanelHeader } from '@deepstream/ui-kit/elements/Panel';
import { useQueryClient } from 'react-query';
import { SelectDropdownMenu } from '../../ui/MultiSelect';
import { ClearFiltersButton } from '../../filtering';
import { useMarkAllNotificationsAsRead, useUnreadNotificationsCount } from './NotificationsProvider';

export const NotificationsTableHeader = ({
  statusFilter,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [markAllAsRead] = useMarkAllNotificationsAsRead();
  const unreadItems = useUnreadNotificationsCount();
  const hasFilters = statusFilter.selectedItems.length !== 0;

  return (
    <PanelHeader
      height="60px"
      pt="1px"
      pb="2px"
      heading={t('notificationsPage.unreadCount', { count: unreadItems })}
      collapse={false}
    >
      <Flex>
        <Box mr={2}>
          {
            hasFilters && (
              <ClearFiltersButton
                onClick={() => {
                  statusFilter.onChange([]);
                }}
              />
            )
          }
        </Box>
        <Box mr={2}>
          <SelectDropdownMenu
            allowEmptySelection
            buttonText={t('notificationsPage.filter.status')}
            buttonIcon="filter"
            menuWidth={180}
            menuZIndex={10}
            {...statusFilter}
          />
        </Box>
        <Box mr={2}>
          <Button
            small
            variant="secondary-outline"
            iconLeft="envelope-open-o"
            disabled={unreadItems === 0}
            onClick={() => {
              markAllAsRead(undefined, {
                onSuccess: () => queryClient.invalidateQueries('notifications'),
              });
            }}
          >
            {t('notificationsPage.actions.markAllAsRead')}
          </Button>
        </Box>
      </Flex>
    </PanelHeader>
  );
};
