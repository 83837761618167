import { Flex, Heading } from 'rebass/styled-components';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Bold } from '../Bold';

export const PageHeading = ({
  text,
  icon,
  dataTest,
  iconProps = {},
}: {
  text?: string;
  icon?: IconProps['icon'];
  dataTest?: string;
  iconProps?: Omit<IconProps, 'icon'>;
}) => (
  <Flex alignItems="center" flex={1}>
    {icon && (
      <Icon
        icon={icon}
        mr={2}
        flex={0}
        color="subtext"
        fontSize={5}
        {...iconProps}
      />
    )}
    <Heading as="h1" fontSize={5} lineHeight="normal" data-test={dataTest}>
      <Truncate style={{ flex: 1 }}>
        <Bold>
          {text}
        </Bold>
      </Truncate>
    </Heading>
  </Flex>
);
