import { useEffect, useMemo } from 'react';
import * as React from 'react';
import * as Sentry from '@sentry/browser';
import {
  BidOutcomeStatus,
  BidStatus,
  CollaboratorInviteStatus,
  getBidOutcomeStatus,
  getPagesInDisplayOrder,
  isAuctionStage,
  isEvaluationPage,
  isLinkedEvaluationPage,
  Live,
  MessagesSectionType,
  PageType,
  renderStageName,
  RfqStatus,
  SectionType,
  StageType,
} from '@deepstream/common/rfq-utils';
import { QueryClient, QueryKey, FetchQueryOptions, useQueryClient } from 'react-query';
import { last, isEmpty, noop, matches, conforms, findLast, first, omitBy, identity, isUndefined } from 'lodash';
import { TFunction, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Box, Flex } from 'rebass/styled-components';
import { ContractStatus } from '@deepstream/common/contract';
import { assertDefined, omitNil } from '@deepstream/utils';
import { type OpenApprovalParam } from '@deepstream/common';
import { DsError, LockedModelError, ModelStatusMismatchError } from '@deepstream/errors';
import { withProps } from '@deepstream/ui-utils/withProps';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils/types';
import { getArrayQueryParam } from '@deepstream/ui-utils/queryParams';
import { useIntercom } from 'react-use-intercom';
import { NodeEnv } from '@deepstream/environment';
import {
  Outlet,
  Navigate,
  useNavigate,
  useSearch,
  createRoute,
  createRouter,
  createRootRouteWithContext,
  useChildMatches,
  useMatches,
  useRouter,
  useScrollRestoration,
} from './tanstackRouter';

import { useApi, wrap } from './api';
import { ApiClient } from './apiClient';
import { getDraftRfqStructureQueryKey, getLiveRfqStructureQueryKey, RecipientIdProvider, RfqIdProvider, useRecipientId, useRfqId } from './useRfq';

import { DraftSummaryPageContent } from './modules/Request/DraftSummaryPage';
import { DraftStagesPageContent } from './modules/Request/DraftStagesPage';
import { DraftTeamPageContent } from './modules/Request/DraftTeamPage';
import { DraftDetailsPagePageContent } from './modules/Request/DraftDetailsPagePage';
import { DraftAuctionConfigPageContent } from './modules/Request/DraftAuctionConfigPage';
import { DraftEvaluationPagePageContent } from './modules/Request/DraftEvaluationPagePage';
import { DraftSuppliersPageContent } from './modules/Request/DraftSuppliersPage';
import { DraftReviewPageContent } from './modules/Request/DraftReviewPage';
import { DraftCommentsPageContent } from './modules/Request/DraftCommentsPage';
import { DraftApprovalsPageContent } from './modules/Request/DraftApprovalsPage';
import { useToaster } from './toast';
import { useCurrentCompanyId } from './currentCompanyId';
import { CurrentCompanyIdProvider } from './CurrentCompanyIdProvider';
import { ModelDeleted } from './ModelDeleted';
import { AuctionTabId } from './draft/AuctionTabId';
import { DriveHistory, DriveDocuments, DriveLayout } from './modules/Drive';
import { ReportingLayout } from './modules/Reporting/ReportingLayout';
import { RequestsReporting } from './modules/Reporting/RequestsReporting';
import { ContractsReporting } from './modules/Reporting/ContractsReporting';
import { TabId, TeamManagement } from './modules/Company/TeamManagement';
import { SentInvitesPage } from './SentInvites';
import { DiscoverySearch } from './modules/AI';
import { PageTemporarilyNotAvailable } from './PageTemporarilyNotAvailable';
import { CompanyProfileContainer } from './modules/Company/Profile/CompanyProfileContainer';
import { useCurrentUser } from './useCurrentUser';
import { APP_ADMIN_COMPANY_ID } from './constants';
import { NotificationsTable } from './modules/Notifications/NotificationsTable';
import { NotificationsProvider } from './modules/Notifications';
import { Notification } from './modules/Notifications/types';
import { useNotificationSubject } from './modules/Notifications/useNotificationSubject';
import { DashboardPage } from './modules/Dashboard/DashboardPage';
import { AuditTrail } from './AuditTrail';
import { getContractQueryKey } from './modules/Contracts/useContract';
import { ContractIdProvider, ContractStateProvider, useContractId } from './modules/Contracts/contract';
import { ContractDraftSummaryPageContent } from './modules/Contracts/ContractDraftSummaryPage';
import { RequestsPage } from './modules/Requests/RequestsPage';
import { RequestsTab } from './requestsConstants';
import { PageNotFound } from './PageNotFound';
import * as Layout from './Layout';
import { ContractDraftDetailsPagePageContent } from './modules/Contracts/ContractDraftDetailsPagePage';
import { ContractDraftContractPageContent } from './modules/Contracts/ContractDraftContractPage';
import { ContractDraftTeamPageContent } from './modules/Contracts/ContractDraftTeamPage';
import { ContractDraftRemindersPageContent } from './modules/Contracts/ContractDraftRemindersPage';
import { ContractDraftReviewPageContent } from './modules/Contracts/ContractDraftReviewPage';
import { ContractLiveSummaryPageContent } from './modules/Contracts/ContractLiveSummaryPage';
import { ContractLiveDetailsPagePageContent } from './modules/Contracts/ContractLiveDetailsPagePage';
import { ContractLiveContractPageContent } from './modules/Contracts/ContractLiveContractPage';
import { ContractLiveTeamPageContent } from './modules/Contracts/ContractLiveTeamPage';
import { ContractLiveRemindersPageContent } from './modules/Contracts/ContractLiveRemindersPage';
import { ContractHooksProvider } from './modules/Contracts/ContractHooksProvider';
import { ContractLiveAuditPageContent } from './modules/Contracts/ContractLiveAuditPage';
import { PreQualificationQuestionnairesPage } from './modules/PreQualification/PreQualificationQuestionnairesPage';
import { PreQualificationQuestionsPage } from './modules/PreQualification/PreQualificationQuestionsPage';
import { PreQualificationLayout } from './modules/PreQualification/PreQualificationLayout';
import { PreQualificationSuppliersPage } from './modules/PreQualification/PreQualificationSuppliersPage';
import { UserFlagsProvider, useUserFlags } from './UserFlagsContext';
import { RequestPageHeader } from './page-headers/RequestPageHeader';
import { RequestSentSuppliersPageContent } from './modules/Request/Sent/RequestSentSuppliersPageContent';
import { RequestSuppliers } from './modules/RequestSuppliers';
import { RequestMessages } from './modules/RequestMessages/RequestMessages';
import * as rfx from './rfx';
import { LiveTeamPageContent } from './modules/Request/LiveTeamPage';
import { LiveAuctionPageContent } from './modules/Request/LiveAuctionPage';
import { Comparison } from './modules/Request/Comparison/Comparison';
import { RequestComments } from './modules/RequestComments';
import { RfxAuditTrailContainer } from './RfxAuditTrail';
import { VesselPricingReport } from './modules/Request/VesselPricingReport';
import { BidAward } from './BidAward';
import { BidSectionsContainer } from './BidSections';
import { BidIntentionPanel } from './BidIntentionPanel';
import { SenderBidPageHeader } from './page-headers/SenderBidPageHeader';
import { EvaluationContainer } from './EvaluationContainer';
import { SupplierBidTeam } from './SupplierBidTeam';
import { ErrorPanel } from './ui/ErrorMessage';
import { ActiveSessionProvider, DisabledActiveSessionProvider, useTrackActiveSession } from './modules/ActiveSession/ActiveSessionContext';
import { ModelType, Operator, RfxStructure } from './types';
import { SearchCompanies } from './SearchCompanies';
import { LegacyRecipientPageHeader } from './page-headers/LegacyRecipientPageHeader';
import { BidTeam } from './BidTeam';
import { AuctionBidContainer } from './modules/Request/Received/AuctionBid';
import { BidProgressPanel } from './BidProgressPanel';
import { ARCHIVE_TABS, PreQualTab, QUESTIONNAIRE_TABS } from './modules/PreQualification/utils';
import { CompanySettingsTab } from './modules/CompanySettings/companySettingsConstants';
import { CompanySettings } from './modules/CompanySettings';
import { LiveSpend } from './modules/Request/Spend/Spend';
import { DraftSpendPageContent } from './modules/Request/DraftSpendPage';
import { QuestionnaireTemplateIdProvider, QuestionnaireTemplateStateProvider, useQuestionnaireTemplateId } from './modules/PreQualification/QuestionnaireTemplate/questionnaireTemplateUtils';
import { getQuestionnaireTemplateQueryKey } from './modules/PreQualification/QuestionnaireTemplate/useQuestionnaireTemplate';
import { QuestionnaireTemplateDraftSummaryPageContent } from './modules/PreQualification/QuestionnaireTemplate/DraftSummaryPage';
import { QuestionnaireTemplateDraftQuestionsPageContent } from './modules/PreQualification/QuestionnaireTemplate/DraftQuestionsPage';
import { QuestionnaireTemplateDraftReviewPageContent } from './modules/PreQualification/QuestionnaireTemplate/DraftReviewPage';
import { QuestionnaireTemplateActiveSuppliersPageContent } from './modules/PreQualification/QuestionnaireTemplate/ActiveSuppliersPage';
import { QuestionnaireTemplateActiveQuestionsPageContent } from './modules/PreQualification/QuestionnaireTemplate/ActiveQuestionsPage';
import { QuestionnaireTemplateActiveDetailsPageContent } from './modules/PreQualification/QuestionnaireTemplate/ActiveDetailsPage';
import { QuestionnaireTemplateHooksProvider } from './modules/PreQualification/QuestionnaireTemplate/QuestionnaireTemplateHooksProvider';
import { QuestionnaireIdProvider, QuestionnaireStateProvider, useQuestionnaireId } from './modules/PreQualification/Questionnaire/questionnaireUtils';
import { QuestionnaireHooksProvider } from './modules/PreQualification/Questionnaire/QuestionnaireHooksProvider';
import { getQuestionnaireQueryKey } from './modules/PreQualification/Questionnaire/useQuestionnaire';
import { QuestionnaireTeamPageContent } from './modules/PreQualification/Questionnaire/QuestionnaireTeamPage';
import { QuestionnaireQuestionsPageContent } from './modules/PreQualification/Questionnaire/QuestionnaireQuestionsPage';
import { QuestionnaireDetailsPageContent } from './modules/PreQualification/Questionnaire/QuestionnaireDetailsPage';
import { SendQuestionnaire } from './modules/PreQualification/Questionnaire/SendQuestionnaire/SendQuestionnaire';
import { RequestHooksProvider } from './modules/Request/RequestHooksProvider';
import { UserProfilePageContent } from './modules/UserProfile/UserProfilePage';
import { UserProfileTabId } from './modules/UserProfile/UserProfile';
import { PublicRequestPage } from './modules/Request';
import { LatestVersionChecker } from './hooks/useIsLatestVersion';
import { QuestionnaireAuditPageContent } from './modules/PreQualification/Questionnaire/QuestionnaireAuditPage';
import { GlobalProcessingModal } from './GlobalProcessingProvider';
import { RequestRecipientIndex } from './modules/Request/Recipient/RequestRecipientIndex';
import { RequestRecipientBidIndex } from './modules/Request/Recipient/RequestRecipientBidIndex';
import { RequestRecipientBidStagePageInstance } from './modules/Request/Recipient/RequestRecipientBidStagePageInstance';
import { RequestSentDetails } from './modules/Request/Sent/RequestSentDetails';
import { LegacyRequestReceivedDetails } from './modules/Request/Received/LegacyRequestReceivedDetails';
import { RequestRecipientBidStageIndex } from './modules/Request/Recipient/RequestRecipientBidStageIndex';
import { RecipientPageHeader } from './page-headers/RecipientPageHeader';
import { TasksProvider } from './TasksProvider';
import { ChatbotStateProvider } from './modules/AI/chatbot/ChatbotSubjectContext';
import { FullviewManager } from './FullviewManager';
import { useAppInitRedirects } from './useAppInitRedirects';
import { useEnv } from './env';
import { NetworkStatusChecker } from './NetworkStatusChecker';
import { PreQualificationArchivePage } from './modules/PreQualification/PreQualificationArchivePage';
import { useSession } from './auth';
import { ContractsPage } from './modules/Contracts/ContractsPage';
import { DeviceContextProvider } from './ui/useDeviceSize';
import { CollapsibleHeaderContextProvider } from './CollapsibleHeaderContext';
import { AwardFlow } from './modules/Request/AwardFlow/AwardFlow';
import { SetupMultiStageResponsesFlowPage } from './modules/Request/SetupMultiStageResponsesFlow/SetupMultiStageResponsesFlow';
import { AwardFlowHeader } from './page-headers/RequestFlowHeader';
import { NetworkLayout } from './modules/Network/NetworkLayout';
import { ActiveQuestionnaireTemplateNavigationContext, DraftContractNavigationContext, DraftQuestionnaireTemplateNavigationContext, LiveContractNavigationContext, QuestionnaireNavigationContext, RequestEditNavigationContext, RequestRecipientNavigationContext, RequestSentNavigationContext, SupplierListNavigationContext, useRequestEditNavigation, useRequestRecipientNavigation, useRequestSentNavigation } from './appNavigation';
import { RequestRecipientAwardSummary } from './modules/Request/Recipient/RequestRecipientAwardSummary';
import { SupplierListsPageContent } from './modules/SupplierLists/SupplierListsPage';
import { getSupplierListQueryKey } from './modules/SupplierLists/useSupplierList';
import { SupplierListIdProvider, SupplierListProvider, useSupplierListId } from './modules/SupplierLists/SupplierListProvider';
import { SupplierListSuppliersPageContent } from './modules/SupplierLists/SupplierListSuppliersPage';
import { SupplierListSettingsPageContent } from './modules/SupplierLists/SupplierListSettingsPage';
import { CreateSupplierListPageContent } from './modules/SupplierLists/CreateSupplierListPage';
import { SupplierListEditPageContent } from './modules/SupplierLists/SupplierListEditPage';
import { useIsAuthorizedStakeholder } from './useIsAuthorizedStakeholder';
import { BulkSubmitPage } from './ui/BulkSubmit/BulkSubmitPage';

const TanStackRouterDevtools =
  process.env.NODE_ENV === NodeEnv.PRODUCTION
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then(res => ({
          default: res.TanStackRouterDevtools,
        })),
      );

type RouterContext = {
  api: ApiClient;
  queryClient: QueryClient;
  getTitle?: (t: TFunction, ...args: any[]) => string;
};

const createRootRoute = createRootRouteWithContext<RouterContext>();

const rootRoute = createRootRoute({
  component: function Root() {
    const intercom = useIntercom();
    const { ONBOARDING_URL, INTERCOM_APP_ID } = useEnv();
    const queryClient = useQueryClient();
    const router = useRouter();
    const navigate = useNavigate();
    const matches = useMatches();
    const session = useSession();
    const isTrackingDisabled = matches.some(match => match.staticData.isTrackingDisabled);
    const isRootRoute = matches.length === 1;
    const ResolvedActiveSessionProvider = isTrackingDisabled ? DisabledActiveSessionProvider : ActiveSessionProvider;

    useScrollRestoration();

    useAppInitRedirects();

    useEffect(
      () => {
        if (INTERCOM_APP_ID) {
          intercom.boot({
            customLauncherSelector: '#auction-support-button',
          });
        }
      },
      [INTERCOM_APP_ID, intercom],
    );

    const redirectToOnboardingVerification = React.useCallback(
      () => {
        window.location.href = `${ONBOARDING_URL}/verification`;
      },
      [ONBOARDING_URL],
    );

    const handleSwitchCompanySuccess = React.useCallback(
      async (company: { _id: string; isPending: boolean }, isInitialSwitch: boolean) => {
        if (company.isPending) {
          redirectToOnboardingVerification();
        }

        // We automatically navigate to the dashboard if we're switching between companies
        // or we're at the root route
        if (isRootRoute || !isInitialSwitch) {
          // HACK: sometimes this dashboard navigation doesn't work and this seems to fix
          // it but I'm not sure why...
          navigate({ to: dashboardRoute.to, params: { currentCompanyId: company._id } });
        }

        // If we're switching from one company to the next (rather than the very first
        // loaded company) let's invalidate everything
        if (!isInitialSwitch) {
          await queryClient.invalidateQueries();
          await router.invalidate();
        }
      },
      [isRootRoute, redirectToOnboardingVerification, queryClient, router, navigate],
    );

    if (!session.user) {
      return null;
    }

    return (
      <DeviceContextProvider>
        <CurrentCompanyIdProvider
          onSwitchCompanySuccess={handleSwitchCompanySuccess}
          onSwitchCompanyFailure={redirectToOnboardingVerification}
        >
          <UserFlagsProvider>
            <ResolvedActiveSessionProvider appName="app" useApiHook={useApi}>
              <NotificationsProvider>
                <TasksProvider>
                  <CollapsibleHeaderContextProvider>
                    <Layout.MainNavigation>
                      <Outlet />
                      <GlobalProcessingModal />
                      <NetworkStatusChecker />
                      <LatestVersionChecker appName="app" />
                      <FullviewManager />
                      <TanStackRouterDevtools />
                    </Layout.MainNavigation>
                  </CollapsibleHeaderContextProvider>
                </TasksProvider>
              </NotificationsProvider>
            </ResolvedActiveSessionProvider>
          </UserFlagsProvider>
        </CurrentCompanyIdProvider>
      </DeviceContextProvider>
    );
  },

  errorComponent: function ErrorComponent({ error }) {
    useEffect(() => {
      Sentry.captureException(error);
    }, [error]);

    return <TanStackRouterDevtools />;
  },

  notFoundComponent: PageNotFound,
});

export const userProfileRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'user',
  validateSearch: (search: Record<string, unknown>): { tab?: UserProfileTabId } => ({
    tab: search.tab as UserProfileTabId | undefined,
  }),
  component: function UserProfileRoute() {
    return <UserProfilePageContent />;
  },
});

const companyRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '$currentCompanyId',
  component: function CompanyRoute() {
    return (
      <ChatbotStateProvider>
        <Outlet />
      </ChatbotStateProvider>
    );
  },
});

export const dashboardRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: 'dashboard',
  component: () => <DashboardPage />,
});

export const requestsRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: 'requests',
  beforeLoad: () => {
    return {
      getTitle: (t: TFunction) => {
        return t('pageHeader.requests', { ns: 'translation' });
      },
    };
  },
});

export const requestsIndexRoute = createRoute({
  getParentRoute: () => requestsRoute,
  path: '/',

  validateSearch: (search: Record<string, unknown>): {
    tab?: string;
    systemId?: string;
    requestName?: string;
    supplierIds?: string[];
    requestOverview?: string;
  } => ({
    tab: search.tab ? String(search.tab) : undefined,

    // The following parameters are only used when `tab` is 'templates'
    systemId: search.systemId as string,
    requestName: search.requestName as string,
    // supplierIds: search.supplierIds ? castArray(search.supplierIds) as string[] : undefined,
    supplierIds: getArrayQueryParam<string>(search.supplierIds),
    requestOverview: search.requestOverview as string,
  }),

  component: function RequestsRoute() {
    const search = requestsIndexRoute.useSearch();

    return <RequestsPage selectedTabId={search.tab} />;
  },
});

export const legacySentRequestsRedirectRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'rfq/sent/$currentCompanyId',
  component: () => (
    <Navigate
      from={legacySentRequestsRedirectRoute.to}
      to={requestsIndexRoute.to}
      params={params => params}
      search={{ tab: RequestsTab.sent }}
      replace={true}
    />
  ),
});

export const legacyReceivedRequestsRedirectRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'rfq/received/$currentCompanyId',
  component: () => (
    <Navigate
      from={legacyReceivedRequestsRedirectRoute.to}
      to={requestsIndexRoute.to}
      params={params => params}
      search={{ tab: RequestsTab.received }}
      replace={true}
    />
  ),
});

export const contractsRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: 'contracts',

  validateSearch: (search: Record<string, unknown>): { tab?: string } => ({
    tab: search.tab ? String(search.tab) : undefined,
  }),

  component: function ContractsRoute() {
    const search = contractsRoute.useSearch();

    return <ContractsPage selectedTabId={search.tab} />;
  },
});

const ensureQueryData = async <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryClient: QueryClient,
  options: FetchQueryOptions<TQueryFnData, TError, TData, TQueryKey> & { queryKey: QueryKey },
): Promise<TData> => {
  const state = queryClient.getQueryState<TData>(options.queryKey);

  if (state && state.data && !state.isInvalidated) {
    return state.data;
  }

  const data = await queryClient.fetchQuery(options);

  return data;
};

const draftRequestLoader = async ({
  params: { currentCompanyId, rfqId },
  context: { api, queryClient, isTemplate },
}: {
  params: { currentCompanyId: string; rfqId: string }
  context: RouterContext & { isTemplate: boolean },
}) => {
  const structure = await ensureQueryData(queryClient, {
    queryKey: getDraftRfqStructureQueryKey({ currentCompanyId, rfqId, isTemplate }),
    queryFn: wrap(api.getRfqDraftStructure),
  });

  return {
    structure,
    isRevising: structure.version > -1,
  };
};

const liveRequestLoader = async ({
  params: { currentCompanyId, rfqId, recipientId },
  context: { api, queryClient, isSender },
}: {
  params: { currentCompanyId: string; rfqId: string; recipientId?: string }
  context: RouterContext & { isSender?: boolean },
}) => {
  const structure = await ensureQueryData(queryClient, {
    queryKey: getLiveRfqStructureQueryKey({
      rfqId,
      currentCompanyId,
      recipientId: isSender ? recipientId : currentCompanyId,
    }),
    queryFn: wrap(api.getRfqBidStructure),
  });

  return { structure };
};

const draftContractLoader = async ({
  params: { currentCompanyId, contractId },
  context: { api, queryClient, isTemplate },
}: {
  params: { currentCompanyId: string; contractId: string }
  context: RouterContext & { isTemplate: boolean },
}) => {
  const contract = await ensureQueryData(queryClient, {
    queryKey: getContractQueryKey({ currentCompanyId, contractId, scope: 'draft', isTemplate }),
    queryFn: wrap(api.getContract),
  });

  return {
    contract,
    isRevising: contract.version > -1 && contract.status === ContractStatus.NEGOTIATION,
    isAmending: contract.version > -1 && contract.status !== ContractStatus.NEGOTIATION,
  };
};

const liveContractLoader = async ({
  params: { currentCompanyId, contractId },
  context: { api, queryClient },
}: {
  params: { currentCompanyId: string; contractId: string }
  context: RouterContext,
}) => {
  const contract = await ensureQueryData(queryClient, {
    queryKey: getContractQueryKey({ currentCompanyId, contractId, scope: 'current' }),
    queryFn: wrap(api.getContract),
  });

  return {
    contract,
  };
};

const draftQuestionnaireTemplateLoader = async ({
  params: { currentCompanyId, templateId },
  context: { api, queryClient },
}: {
  params: { currentCompanyId: string; templateId: string }
  context: RouterContext,
}) => {
  const questionnaireTemplate = await ensureQueryData(queryClient, {
    queryKey: getQuestionnaireTemplateQueryKey({ currentCompanyId, templateId, scope: 'draft' }),
    queryFn: wrap(api.getQuestionnaireTemplate),
  });

  return {
    template: questionnaireTemplate,
    isRevising: questionnaireTemplate.version > 0,
  };
};

const activeQuestionnaireTemplateLoader = async ({
  params: { currentCompanyId, templateId },
  context: { api, queryClient },
}: {
  params: { currentCompanyId: string; templateId: string }
  context: RouterContext,
}) => {
  const questionnaireTemplate = await ensureQueryData(queryClient, {
    queryKey: getQuestionnaireTemplateQueryKey({ currentCompanyId, templateId, scope: 'current' }),
    queryFn: wrap(api.getQuestionnaireTemplate),
  });

  return {
    template: questionnaireTemplate,
  };
};

const questionnaireLoader = async ({
  params: { currentCompanyId, questionnaireId },
  context: { api, queryClient },
}: {
  params: { currentCompanyId: string; questionnaireId: string }
  context: RouterContext,
}) => {
  const questionnaire = await ensureQueryData(queryClient, {
    queryKey: getQuestionnaireQueryKey({ currentCompanyId, questionnaireId }),
    queryFn: wrap(api.getQuestionnaire),
  });

  return {
    questionnaire,
  };
};

const supplierListLoader = async ({
  params: { currentCompanyId, supplierListId },
  context: { api, queryClient },
}: {
  params: { currentCompanyId: string; supplierListId: string }
  context: RouterContext,
}) => {
  const supplierList = await ensureQueryData(queryClient, {
    queryKey: getSupplierListQueryKey(currentCompanyId, supplierListId),
    queryFn: wrap(api.getSupplierList),
  });

  return {
    supplierList,
  };
};

const NavigateToDashboard = ({
  toastMessage,
}: {
  toastMessage: string | null;
}) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const toaster = useToaster();

  useEffect(() => {
    if (toastMessage) {
      toaster.error(toastMessage);
    }
  }, [toaster, currentCompanyId, toastMessage]);

  return (
    <Navigate to={dashboardRoute.to} params={{ currentCompanyId }} />
  );
};

const NavigateToRequestEditRoute = ({ rfqId }: { rfqId: string }) => {
  const navigate = useNavigate();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  useEffect(
    () => {
      navigate({
        to: requestEditSummaryRoute.to,
        params: { currentCompanyId, rfqId },
      });
    },
    [currentCompanyId, navigate, rfqId],
  );

  return null;
};

function ModelErrorPage({
  model,
  canRedirectToEditRoute,
  error,
}: {
  model: ModelType;
  canRedirectToEditRoute?: boolean;
  error?: any;
}): React.JSX.Element {
  const { t } = useTranslation(['translation', 'general', 'contracts', 'preQualification']);
  const matches = useMatches();
  const lastMatch = last(matches);

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  const lastErroredMatch = findLast(matches, match => Boolean(match.error));

  const errorResponse = (lastErroredMatch?.error as any)?.response;
  const errorCode = errorResponse?.status;
  const errorData = errorResponse?.data;

  return (
    <>
      {!lastMatch ? (
        null
      ) : ['request', 'template'].includes(model) && errorCode === LockedModelError.code ? (
        <PageTemporarilyNotAvailable model={model as 'request' | 'template'} redirectUrl={lastMatch.pathname} />
      ) : errorCode === ModelStatusMismatchError.code && errorData.actualStatus === RfqStatus.DELETED ? (
        <Layout.BasicHeader heading={t('error', { ns: 'general' })} icon="xmark">
          <ModelDeleted model={model} />
        </Layout.BasicHeader>
      ) : (
        canRedirectToEditRoute &&
        errorCode === ModelStatusMismatchError.code &&
        errorData.expectedStatus === RfqStatus.DRAFT &&
        'rfqId' in lastMatch.params
      ) ? (
        <NavigateToRequestEditRoute rfqId={lastMatch.params.rfqId} />
      ) : lastErroredMatch?.error ? (
        <NavigateToDashboard
          toastMessage={!errorResponse ? (
            t('errors.unexpected')
          ) : model === 'request' ? (
            t('request.errors.getRequest')
          ) : model === 'template' ? (
            t('request.errors.getTemplate')
          ) : model === 'contract' ? (
            t('errors.getContract', { ns: 'contracts' })
          ) : model === 'questionnaireTemplate' || model === 'questionnaire' ? (
            t('errors.getQuestionnaire', { ns: 'preQualification' })
          ) : model === 'supplierList' ? (
            t('supplierLists.errors.getSupplierList')
          ) : (
            null
          )}
        />
      ) : (
        null
      )}
      <TanStackRouterDevtools />
    </>
  );
}

// #region Public request

export const publicRequestRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'requests/$rfqId',
  validateSearch: (search: Record<string, unknown>): { isPreview?: boolean } => ({
    isPreview: search.isPreview as boolean,
  }),
  staticData: {
    isTrackingDisabled: true,
  },
  component: function PublicRequestRoute() {
    const { rfqId } = publicRequestRoute.useParams();
    const currentCompanyId = useCurrentCompanyId({ required: true });

    return (
      <PublicRequestPage
        currentCompanyId={currentCompanyId}
        rfqId={rfqId}
      />
    );
  },
});

export const publicRequestSlugRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'requests/$rfqId/$slug',
  validateSearch: (search: Record<string, unknown>): { isPreview?: boolean } => ({
    isPreview: search.isPreview as boolean,
  }),
  staticData: {
    isTrackingDisabled: true,
  },
  component: function PublicRequestSlugRoute() {
    const { rfqId, slug } = publicRequestSlugRoute.useParams();
    const currentCompanyId = useCurrentCompanyId({ required: true });

    return (
      <PublicRequestPage
        currentCompanyId={currentCompanyId}
        rfqId={rfqId}
        rfqSlug={slug}
      />
    );
  },
});

// #endregion

// #region Live Request (Sender)

/* ------ Live Request (Sender) ------ */

export const requestSentRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: '/request/sent/$rfqId',
  beforeLoad: () => ({ isSender: true }),
  loader: options => liveRequestLoader(options),
  component: function RequestSentRoute() {
    const { rfqId, currentCompanyId } = requestSentRoute.useParams();
    const { structure } = requestSentRoute.useLoaderData();

    const sender = structure.senders.find(sender => sender._id === currentCompanyId);

    assertDefined(sender, 'sender');

    if (sender.inviteStatus === CollaboratorInviteStatus.PENDING) {
      // In theory, a request can't be sent without all the collaborator invitations
      // being resolved but we'll include this just to be safe
      return (
        <Navigate
          to={requestEditReviewRoute.to}
          params={{ rfqId, currentCompanyId }}
          replace={true}
        />
      );
    } else if (sender.inviteStatus === CollaboratorInviteStatus.REJECTED) {
      return (
        <Navigate
          to={requestsIndexRoute.to}
          params={{ currentCompanyId }}
          search={{ tab: RequestsTab.sent }}
          replace={true}
        />
      );
    }

    if (structure.status === RfqStatus.DRAFT) {
      return <Navigate to={requestEditSummaryRoute.to} params={{ rfqId, currentCompanyId }} />;
    }

    return (
      <RfqIdProvider rfqId={rfqId}>
        <RequestSentNavigationProvider structure={structure}>
          <RequestEditNavigationProvider isRevising={true} isTemplate={false}>
            <RequestHooksProvider>
              <Outlet key={rfqId} />
            </RequestHooksProvider>
          </RequestEditNavigationProvider>
        </RequestSentNavigationProvider>
      </RfqIdProvider>
    );
  },

  errorComponent: withProps(ModelErrorPage, { model: 'request', canRedirectToEditRoute: true }),
});

export const requestSentIndexRoute = createRoute({
  getParentRoute: () => requestSentRoute,
  path: '/',
  component: () => (
    <Navigate
      from={requestSentIndexRoute.to}
      to={requestSentSuppliersIndexRoute.to}
      params={params => params}
      replace={true}
    />
  ),
});

export const requestSentDetailsRoute = createRoute({
  getParentRoute: () => requestSentRoute,
  path: 'details',
  component: RequestSentDetails,
});

export const requestSentAuctionRoute = createRoute({
  getParentRoute: () => requestSentRoute,
  path: 'auction',
  component: LiveAuctionPageContent,
});

export const requestSentLegacyBulletinsRoute = createRoute({
  getParentRoute: () => requestSentRoute,
  path: 'bulletins',
});

export const requestSentLegacyBulletinsIndexRoute = createRoute({
  getParentRoute: () => requestSentLegacyBulletinsRoute,
  path: '/',
  component: () => (
    <Navigate
      from={requestSentLegacyBulletinsIndexRoute.to}
      to={requestSentLegacyBulletinsListRoute.to}
      params={params => params}
      replace={true}
    />
  ),
});

export const requestSentLegacyBulletinsListRoute = createRoute({
  getParentRoute: () => requestSentLegacyBulletinsRoute,
  path: 'clarifications',
  component: function RequestSentLegacyBulletinsListRoute() {
    const { rfqId } = requestSentLegacyBulletinsListRoute.useParams();
    const { t } = useTranslation();

    // TODO: convert to `useNotificationSubject` if we decide to preserve the legacy bulletin notifications
    //  const query = {
    //   'domain': NotificationDomain.RFQ_SENT,
    //   'action': NotificationAction.BULLETIN_SIMPLE_CLARIFICATION,
    //   'meta.rfqId': this.rfqEnvelope._id,
    //   'to.companyId': this.currentCompanyId,
    //   'to.userId': this.ekCurUser.get()._id,
    // };

    return (
      <Layout.HeaderAndContent
        header={
          <RequestPageHeader selectedTabId="bulletin" rfqId={rfqId} />
        }
        content={
          <ErrorPanel error={t('errors.unexpected')} />
        }
      />
    );
  },
});

export const requestSentLegacyBulletinQuestionsRoute = createRoute({
  getParentRoute: () => requestSentLegacyBulletinsRoute,
  path: 'questions',
  component: function RequestSentLegacyBulletinQuestionsRoute() {
    const { rfqId } = requestSentLegacyBulletinsListRoute.useParams();
    const { t } = useTranslation();

    // TODO: convert to `useNotificationSubject` if we decide to preserve the legacy bulletin notifications
    // const query = {
    //   'domain': NotificationDomain.RFQ_SENT,
    //   'action': { $in: [NotificationAction.BULLETIN_QUESTION_POSTED, NotificationAction.BULLETIN_ANSWER_TO_QUESTION_POSTED] },
    //   'meta.rfqId': this.rfqEnvelope._id,
    //   'to.companyId': this.currentCompanyId,
    //   'to.userId': this.ekCurUser.get()._id,
    // };

    return (
      <Layout.HeaderAndContent
        header={
          <RequestPageHeader selectedTabId="bulletin" rfqId={rfqId} />
        }
        content={
          <ErrorPanel error={t('errors.unexpected')} />
        }
      />
    );
  },
});

export const requestSentMessagesRoute = createRoute({
  getParentRoute: () => requestSentRoute,
  path: 'messages',
  validateSearch: (search: Record<string, unknown>): {
    sectionType?: MessagesSectionType;
    exchangeId?: string;
    recipientId?: string;
  } => ({
    recipientId: search.recipientId?.toString(),
    exchangeId: search.exchangeId as string,
    sectionType: search.sectionType as MessagesSectionType,
  }),
  component: function RequestSentMessagesRoute() {
    const { rfqId } = requestSentMessagesRoute.useParams();
    const { recipientId, exchangeId, sectionType } = requestSentMessagesRoute.useSearch();

    return (
      <Layout.HeaderAndContent
        header={
          <RequestPageHeader selectedTabId="messages" rfqId={rfqId} />
        }
        content={
          <RequestMessages
            recipientId={recipientId}
            exchangeId={exchangeId}
            sectionType={sectionType}
          />
        }
      />
    );
  },
});

export const requestSentTeamRoute = createRoute({
  getParentRoute: () => requestSentRoute,
  path: 'team',
  validateSearch: (search: Record<string, unknown>): { companyId: string } => ({
    companyId: search.companyId as string,
  }),
  component: LiveTeamPageContent,
});

export const requestSentSuppliersRoute = createRoute({
  getParentRoute: () => requestSentRoute,
  path: 'suppliers',
  beforeLoad: () => {
    return {
      getTitle: (t: TFunction, structure: RfxStructure<Live>) => {
        return structure.summary.subject;
      },
    };
  },
});

export const requestSentSuppliersIndexRoute = createRoute({
  getParentRoute: () => requestSentSuppliersRoute,
  path: '/',
  component: () => (
    <Navigate
      from={requestSentSuppliersIndexRoute.to}
      to={requestSentSuppliersViewRoute.to}
      params={params => params}
      replace={true}
    />
  ),
});

export const requestSentSuppliersViewRoute = createRoute({
  getParentRoute: () => requestSentSuppliersRoute,
  path: 'view',

  validateSearch: (search: Record<string, unknown>): {
    exchangeId?: string;
    openApprovalModal?: OpenApprovalParam;
    approvalRequestId?: string;
  } => ({
    exchangeId: search.exchangeId as string,
    openApprovalModal: search.openApprovalModal as OpenApprovalParam,
    approvalRequestId: search.approvalRequestId as string,
  }),

  component: function RequestSentSuppliersViewRoute() {
    const { rfqId } = requestSentSuppliersViewRoute.useParams();
    const { exchangeId } = requestSentSuppliersViewRoute.useSearch();

    return (
      <Layout.HeaderAndContent
        header={
          <RequestPageHeader selectedTabId="suppliers" rfqId={rfqId} />
        }
        content={
          <RequestSentSuppliersPageContent rfqId={rfqId} exchangeId={exchangeId} />
        }
      />
    );
  },
});

export const requestSentSuppliersAddRoute = createRoute({
  getParentRoute: () => requestSentSuppliersRoute,
  path: 'edit',
  component: function RequestSentSuppliersAddRoute() {
    const { rfqId, currentCompanyId } = requestSentSuppliersAddRoute.useParams();

    return (
      <Layout.HeaderAndContent
        header={
          <RequestPageHeader selectedTabId="suppliers" rfqId={rfqId} />
        }
        content={
          <RequestSuppliers
            rfqId={rfqId}
            currentCompanyId={currentCompanyId}
            isLive={true}
            isRevising={false}
            isTemplate={false}
            isReadOnly={false}
          />
        }
        contentPaddingBottom={Layout.BOTTOM_TOOLBAR_CONTENT_PADDING_BOTTOM}
      />
    );
  },
});

const desktopThresholdWidth = '1201px'; // Should be the same as bootstrap's xl breakpoint

const ComparisonTabContentStyle = styled.div`
  .comparison-grid {
    line-height: normal;
    height: calc(100vh - 210px);

    @media screen and (min-width: ${desktopThresholdWidth}) {
      height: calc(100vh - 236px);
    }
  }
`;

export const requestSentComparisonRoute = createRoute({
  getParentRoute: () => requestSentRoute,
  path: 'comparison',
  component: function RequestSentComparisonRoute() {
    const { rfqId, currentCompanyId } = requestSentComparisonRoute.useParams();
    const navigation = useRequestSentNavigation();

    return (
      <Layout.HeaderAndContent
        header={
          <RequestPageHeader selectedTabId="comparison" rfqId={rfqId} />
        }
        content={
          <ComparisonTabContentStyle>
            <Flex flexDirection="column" className="comparison-grid">
              <Box height="100%">
                <Comparison
                  rfqId={rfqId}
                  navigateToTeam={() => navigation.navigateToTeam({ companyId: currentCompanyId })}
                />
              </Box>
            </Flex>
          </ComparisonTabContentStyle>
        }
      />
    );
  },
});

export const requestSentSpendRoute = createRoute({
  getParentRoute: () => requestSentRoute,
  path: 'spend',
  component: function LiveSpendRoute() {
    const { rfqId, currentCompanyId } = requestSentSpendRoute.useParams();
    const navigation = useRequestSentNavigation();

    return (
      <Layout.HeaderAndContent
        header={
          <RequestPageHeader selectedTabId="spend" rfqId={rfqId} />
        }
        content={
          <LiveSpend
            navigateToTeam={() => navigation.navigateToTeam({ companyId: currentCompanyId })}
          />
        }
      />
    );
  },
});

export const requestSentCommentsRoute = createRoute({
  getParentRoute: () => requestSentRoute,
  path: 'comments',
  component: function RequestSentCommentsRoute() {
    const { rfqId, currentCompanyId } = requestSentCommentsRoute.useParams();

    const ref = useNotificationSubject({
      filter: matches({
        domain: NotificationDomain.RFQ_SENT,
        action: NotificationAction.RFQ_COMMENT_ADDED,
        meta: { rfqId },
        to: { companyId: currentCompanyId },
      }),
    });

    return (
      <Layout.HeaderAndContent
        header={
          <RequestPageHeader selectedTabId="comments" rfqId={rfqId} />
        }
        content={
          <div ref={ref}>
            <RequestComments rfqId={rfqId} />
          </div>
        }
      />
    );
  },
});

export const requestSentAuditRoute = createRoute({
  getParentRoute: () => requestSentRoute,
  path: 'audit',
  component: function RequestSentAuditRoute() {
    const { rfqId } = requestSentAuditRoute.useParams();

    return (
      <Layout.HeaderAndContent
        header={
          <RequestPageHeader selectedTabId="audit" rfqId={rfqId} />
        }
        content={
          <RfxAuditTrailContainer rfqId={rfqId} />
        }
      />
    );
  },
});

export const requestSentVesselPricingRoute = createRoute({
  getParentRoute: () => requestSentRoute,
  path: 'vessels',
  component: function RequestSentVesselPricingRoute() {
    const { rfqId } = requestSentVesselPricingRoute.useParams();

    return (
      <Layout.HeaderAndContent
        header={
          <RequestPageHeader selectedTabId="vessel" rfqId={rfqId} />
        }
        content={
          <VesselPricingReport rfqId={rfqId} />
        }
      />
    );
  },
});

export const requestSentAwardRoute = createRoute({
  getParentRoute: () => requestSentRoute,
  path: 'award',
  component: function RequestSentAwardRoute() {
    const { rfqId } = requestSentAwardRoute.useParams();
    const { structure } = requestSentRoute.useLoaderData();

    return (
      <Layout.HeaderAndContent
        header={
          <RequestPageHeader selectedTabId="award" rfqId={rfqId} />
        }
        content={
          <BidAward
            rfqId={rfqId}
            isBuyer={true}
            attachments={structure.meta.awarded?.attachments}
            message={structure.meta.awarded?.message}
          />
        }
      />
    );
  },
});

export const requestSentAwardFlowRoute = createRoute({
  getParentRoute: () => requestSentRoute,
  path: 'award-flow',
  component: function RequestSentAwardFlowRoute() {
    const { structure } = requestSentRoute.useLoaderData();
    const { rfqId, currentCompanyId } = requestSentAwardFlowRoute.useParams();

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    if (structure.stages.some(stage => rfx.isStageActive(stage, structure.auction))) {
      throw new Error('Award flow requires all stages to be inactive');
    }

    const isAwardRequestDisabled = !structure.recipients.some(recipient => {
      const bidStatus = structure.bidById[recipient._id].status;

      return bidStatus !== BidStatus.UNSUCCESSFUL;
    });

    // We redirect instead of throwing an error in below cases because
    // those cases might apply in the last step of the award flow, where
    // we show a success message after the request has been awarded / closed.
    // Reloading the tab in that state shouldn't cause an error.
    if (
      isAwardRequestDisabled ||
      (structure.extendedStatus && [RfqStatus.AWARDED, RfqStatus.CLOSED].includes(structure.extendedStatus))
    ) {
      return (
        <Navigate
          from={requestSentAwardFlowRoute.to}
          to={requestSentSuppliersViewRoute.to}
          params={{ rfqId, currentCompanyId }}
        />
      );
    }

    return (
      <Layout.HeaderAndContent
        header={<AwardFlowHeader />}
        content={<AwardFlow />}
      />
    );
  },
});

export const requestSentRecipientRoute = createRoute({
  getParentRoute: () => requestSentSuppliersRoute,
  path: '$recipientId',
  beforeLoad: () => {
    return {
      getTitle: (t: TFunction, structure: RfxStructure<Live>, recipientId: string) => {
        return structure.recipients.find(recipient => recipient._id === recipientId)?.company.name;
      },
    };
  },
  // @ts-expect-error ts(2345) FIXME: Argument of type 'LoaderFnContext<Route<Route<Route<RootRoute<undefined, RouterContext, AnyContext, AnyContext, {}, undefined, unknown>, "$currentCompanyId", "/$currentCompanyId", ... 9 more ..., unknown>, ... 11 more ..., unknown>, ... 11 more ..., unknown>, ... 4 more ..., () => { ...; }>' is not assignable to parameter of type '{ params: { currentCompanyId: string; rfqId: string; recipientId?: string | undefined; }; context: RouterContext & { isSender?: boolean | undefined; }; }'.
  loader: options => liveRequestLoader(options),
  component: function RequestSentRecipientRoute() {
    const { structure } = requestSentRecipientRoute.useLoaderData();
    const { rfqId, recipientId } = requestSentRecipientRoute.useParams();
    const intercom = useIntercom();

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    useEffect(() => {
      intercom.trackEvent('new-buyer-request-ux-opened');
    }, [intercom]);

    return (
      <RecipientIdProvider recipientId={recipientId}>
        <RequestSentRecipientNavigationProvider>
          <Outlet key={rfqId} />
        </RequestSentRecipientNavigationProvider>
      </RecipientIdProvider>
    );
  },

  errorComponent: withProps(ModelErrorPage, { model: 'request' }),
});

export const requestSentRecipientIndexRoute = createRoute({
  getParentRoute: () => requestSentRecipientRoute,
  path: '/',
  component: function RequestSentRecipientIndexRoute() {
    const { structure } = requestSentRecipientRoute.useLoaderData();

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    return (
      <Layout.HeaderAndContent
        header={<RecipientPageHeader />}
        content={<RequestRecipientIndex />}
      />
    );
  },
});

export const requestSentRecipientBidRoute = createRoute({
  getParentRoute: () => requestSentRecipientRoute,
  path: 'bid',
  beforeLoad: () => {
    return {
      getTitle: (t: TFunction) => {
        return t('request.recipientPage.bid.title.buyer', { ns: 'translation' });
      },
    };
  },
});

export const requestSentRecipientBidExchangeRoute = createRoute({
  getParentRoute: () => requestSentRecipientBidRoute,
  path: 'exchange',
  validateSearch: (search: Record<string, unknown>): {
    pageId: string;
    exchangeId?: string;
  } => ({
    pageId: search.pageId as string,
    exchangeId: search.exchangeId as string,
  }),
  component: function RequestSentRecipientBidExchangeRoute() {
    const { rfqId, currentCompanyId, recipientId } = requestSentRecipientBidExchangeRoute.useParams();
    const { structure } = requestSentRecipientRoute.useLoaderData();
    const search = requestSentRecipientBidExchangeRoute.useSearch();

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    const page = structure.pages.find(page => page._id === search.pageId);

    if (!page) {
      return (
        <Navigate
          from={requestSentRecipientBidExchangeRoute.to}
          to={requestSentRecipientBidRoute.to}
          replace={true}
        />
      );
    }

    const isMessagesPage = page.type === PageType.CHAT;

    if (isMessagesPage) {
      return (
        <Navigate
          from={requestSentRecipientBidExchangeRoute.to}
          to={requestSentRecipientMessagesRoute.to}
          params={{ rfqId, currentCompanyId, recipientId }}
          search={search.exchangeId ? { exchangeId: search.exchangeId } : undefined}
          replace={true}
        />
      );
    }

    const exchangeDef = search.exchangeId
      ? structure.exchangeDefById[search.exchangeId]
      : null;
    const section = exchangeDef
      ? structure.sectionById[exchangeDef.sectionId!]
      : null;

    const bid = structure.bidById[recipientId];

    if (!bid) {
      throw new Error(`No bid found for recipient ${recipientId}`);
    }

    // @ts-expect-error ts(2345) FIXME: Argument of type 'ExchangeDefinition | null' is not assignable to parameter of type 'ExchangeDefinition | undefined'.
    const stageId = rfx.getExchangeStageId(exchangeDef, section, bid);

    // when we couldn't find a stage ID, navigate to the bid page
    if (!stageId) {
      return (
        <Navigate
          from={requestSentRecipientBidExchangeRoute.to}
          to={requestSentRecipientBidIndexRoute.to}
          params={{ rfqId, currentCompanyId, recipientId }}
          replace={true}
        />
      );
    }

    if (structure.settings.areLotsEnabled) {
      // @ts-expect-error ts(18047) FIXME: 'section' is possibly 'null'.
      const requirementGroupId = first(section.lotIds) || 'general';

      return (
        <Navigate
          from={requestSentRecipientBidExchangeRoute.to}
          to={requestSentRecipientBidStageRequirementPageInstanceRoute.to}
          params={{ rfqId, currentCompanyId, recipientId, pageId: search.pageId, stageId, requirementGroupId }}
          search={search.exchangeId ? { exchangeId: search.exchangeId } : undefined}
          replace={true}
        />
      );
    } else {
      return (
        <Navigate
          from={requestSentRecipientBidExchangeRoute.to}
          to={requestSentRecipientBidStagePageInstanceRoute.to}
          params={{ rfqId, currentCompanyId, recipientId, pageId: search.pageId, stageId }}
          search={search.exchangeId ? { exchangeId: search.exchangeId } : undefined}
          replace={true}
        />
      );
    }
  },
});

export const requestSentRecipientBidIndexRoute = createRoute({
  getParentRoute: () => requestSentRecipientBidRoute,
  path: '/',
  component: function RequestSentRecipientBidIndexRoute() {
    const { structure } = requestSentRecipientRoute.useLoaderData();

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    return (
      <Layout.HeaderAndContent
        header={<RecipientPageHeader />}
        content={<RequestRecipientBidIndex />}
      />
    );
  },
});

export const requestSentRecipientBidStageRoute = createRoute({
  getParentRoute: () => requestSentRecipientBidRoute,
  path: 'stage/$stageId',
  beforeLoad: ({ params }) => {
    return {
      getTitle: (t: TFunction, structure: RfxStructure<Live>) => {
        const stage = structure.stageById[params.stageId];
        const stageIndex = structure.stages.findIndex(stage => stage._id === params.stageId);

        return renderStageName(stage, t, stageIndex, true);
      },
    };
  },
});

export const requestSentRecipientBidStageIndexRoute = createRoute({
  getParentRoute: () => requestSentRecipientBidStageRoute,
  path: '/',
  component: function RequestSentRecipientBidStageIndexRoute() {
    const { rfqId, currentCompanyId, recipientId, stageId } = requestSentRecipientBidStageRoute.useParams();
    const { structure } = requestSentRecipientRoute.useLoaderData();

    const stage = structure.stageById[stageId];
    const bid = structure.bidById[recipientId];

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    if (!bid) {
      throw new Error(`No bid found for recipient ${recipientId}`);
    }

    if (!stage) {
      throw new Error(`Stage ${stageId} not found`);
    }

    if (!bid.enteredStageIds.includes(stageId)) {
      throw new Error(`Stage ${stageId} has not been entered`);
    }

    const hasLots = !isEmpty(structure.lots);

    const isAuctionStageRoute = isAuctionStage(stage);

    return isAuctionStageRoute ? (
      <Navigate
        from={requestSentRecipientBidStageIndexRoute.to}
        to={requestSentAuctionRoute.to}
        params={{ rfqId, currentCompanyId }}
        replace={true}
      />
    ) : hasLots ? (
      <Layout.HeaderAndContent
        header={<RecipientPageHeader />}
        content={
          <rfx.StageIdProvider stageId={stage._id}>
            <RequestRecipientBidStageIndex />
          </rfx.StageIdProvider>
        }
      />
    ) : (
      <Navigate
        from={requestSentRecipientBidStageIndexRoute.to}
        to={requestSentRecipientBidStagePageIndexRoute.to}
        replace={true}
      />
    );
  },
});

export const requestSentRecipientBidStagePageRoute = createRoute({
  getParentRoute: () => requestSentRecipientBidStageRoute,
  path: 'page',
});

export const requestSentRecipientBidStagePageIndexRoute = createRoute({
  getParentRoute: () => requestSentRecipientBidStagePageRoute,
  path: '/',
  component: function RequestSentRecipientBidStagePageIndexRoute() {
    const { stageId, recipientId } = requestSentRecipientBidStagePageRoute.useParams();
    const { structure } = requestSentRecipientRoute.useLoaderData();
    // @ts-expect-error ts(18048) FIXME: 'structure.enteredPageIdsByRequirementGroupIdByStageId' is possibly 'undefined'.
    const defaultPageId = first(structure.enteredPageIdsByRequirementGroupIdByStageId[stageId]?.general);

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    if (!defaultPageId) {
      throw new Error(`Could not find default page ID for recipient ${recipientId}, stage ID ${stageId} and requirement 'general'`);
    }

    return (
      <Navigate
        from={requestSentRecipientBidStagePageIndexRoute.to}
        to={requestSentRecipientBidStagePageInstanceRoute.to}
        params={params => ({ ...params, pageId: defaultPageId })}
        replace={true}
      />
    );
  },
});

export const requestSentRecipientBidStagePageInstanceRoute = createRoute({
  getParentRoute: () => requestSentRecipientBidStagePageRoute,
  path: '$pageId',
  validateSearch: (search: Record<string, unknown>): {
    exchangeId?: string;
    recipientId?: string;
    sectionId?: string;
  } => ({
    exchangeId: search.exchangeId as string,
    recipientId: search.recipientId as string,
    sectionId: search.sectionId as string,
  }),
  component: function RequestSentRecipientBidStagePageInstanceRoute() {
    const { recipientId, pageId, stageId } = requestSentRecipientBidStagePageInstanceRoute.useParams();
    const { structure } = requestSentRecipientRoute.useLoaderData();
    const search = requestSentRecipientBidStagePageInstanceRoute.useSearch();
    const stage = structure.stageById[stageId];
    const bid = structure.bidById[recipientId];
    const page = structure.pages.find(page => page._id === pageId);

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    if (!bid) {
      throw new Error(`No bid found for recipient ${recipientId}`);
    }

    if (!stage) {
      throw new Error(`Stage ${stageId} not found`);
    }

    if (!bid.enteredStageIds.includes(stageId)) {
      throw new Error(`Stage ${stageId} has not been entered`);
    }

    if (!page) {
      throw new Error(`Page ${pageId} not found`);
    }

    // @ts-expect-error ts(18048) FIXME: 'structure.enteredPageIdsByRequirementGroupIdByStageId' is possibly 'undefined'.
    if (!structure.enteredPageIdsByRequirementGroupIdByStageId[stageId].general.includes(pageId)) {
      throw new Error(`Page ${pageId} is not part of enteredPageIdsByRequirementGroupIdByStageId`);
    }

    const isMessagesPage = page.type === PageType.CHAT;

    if (isMessagesPage) {
      throw new Error('Cannot render messages page at stage route');
    }

    return (
      <Layout.HeaderAndContent
        header={<RecipientPageHeader />}
        content={
          <rfx.StageIdProvider stageId={stage._id}>
            <RequestRecipientBidStagePageInstance
              pageId={pageId}
              sectionId={search.sectionId}
              exchangeId={search.exchangeId}
            />
          </rfx.StageIdProvider>
        }
      />
    );
  },
});

export const requestSentRecipientBidStageRequirementPageRoute = createRoute({
  getParentRoute: () => requestSentRecipientBidStageRoute,
  path: 'requirement/$requirementGroupId/page',
});

export const requestSentRecipientBidStageRequirementPageIndexRoute = createRoute({
  getParentRoute: () => requestSentRecipientBidStageRequirementPageRoute,
  path: '/',
  component: function RequestSentRecipientBidStageRequirementPageIndexRoute() {
    const { recipientId, stageId, requirementGroupId } = requestSentRecipientBidStageRequirementPageRoute.useParams();
    const { structure } = requestSentRecipientRoute.useLoaderData();
    // @ts-expect-error ts(18048) FIXME: 'structure.enteredPageIdsByRequirementGroupIdByStageId' is possibly 'undefined'.
    const defaultPageId = first(structure.enteredPageIdsByRequirementGroupIdByStageId[stageId]?.[requirementGroupId]);

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    if (!defaultPageId) {
      throw new Error(`Could not find default page ID for recipient ${recipientId}, stage ID ${stageId} and requirement ${requirementGroupId}`);
    }

    return (
      <Navigate
        from={requestSentRecipientBidStageRequirementPageIndexRoute.to}
        to={requestSentRecipientBidStageRequirementPageInstanceRoute.to}
        params={params => ({ ...params, pageId: defaultPageId })}
        replace={true}
      />
    );
  },
});

export const requestSentRecipientBidStageRequirementPageInstanceRoute = createRoute({
  getParentRoute: () => requestSentRecipientBidStageRequirementPageRoute,
  path: '$pageId',
  validateSearch: (search: Record<string, unknown>): {
    exchangeId?: string;
    recipientId?: string;
    sectionId?: string;
  } => ({
    exchangeId: search.exchangeId as string,
    recipientId: search.recipientId as string,
    sectionId: search.sectionId as string,
  }),
  beforeLoad: ({ params }) => {
    return {
      getTitle: (t: TFunction, structure: RfxStructure<Live>) => {
        if (params.requirementGroupId === 'general') {
          return t('request.generalRequirement_other', { ns: 'translation' });
        } else {
          return `${t('request.lot', { count: 1, ns: 'translation' })} ${structure.lots.findIndex(lot => lot._id === params.requirementGroupId) + 1} – ${structure.lotById[params.requirementGroupId]?.name}`;
        }
      },
    };
  },
  component: function RequestSentRecipientBidStageRequirementPageInstanceRoute() {
    const { recipientId, pageId, stageId, requirementGroupId } = requestSentRecipientBidStageRequirementPageInstanceRoute.useParams();
    const { structure } = requestSentRecipientRoute.useLoaderData();
    const search = requestSentRecipientBidStageRequirementPageInstanceRoute.useSearch();
    const stage = structure.stageById[stageId];
    const bid = structure.bidById[recipientId];
    const page = structure.pages.find(page => page._id === pageId);

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    if (!bid) {
      throw new Error(`No bid found for recipient ${recipientId}`);
    }

    if (!stage) {
      throw new Error(`Stage ${stageId} not found`);
    }

    if (!bid.enteredStageIds.includes(stageId)) {
      throw new Error(`Stage ${stageId} has not been entered`);
    }

    if (!page) {
      throw new Error(`Page ${pageId} not found`);
    }

    if (requirementGroupId !== 'general' && !structure.lotById[requirementGroupId]) {
      throw new Error(`Requirement group ${requirementGroupId} not found`);
    }

    // @ts-expect-error ts(18048) FIXME: 'structure.enteredPageIdsByRequirementGroupIdByStageId' is possibly 'undefined'.
    if (!structure.enteredPageIdsByRequirementGroupIdByStageId[stageId][requirementGroupId].includes(pageId)) {
      throw new Error(`Page ${pageId} is not part of enteredPageIdsByRequirementGroupIdByStageId`);
    }

    const isMessagesPage = page.type === PageType.CHAT;

    if (isMessagesPage) {
      throw new Error('Cannot render messages page at stage route');
    }

    return (
      <Layout.HeaderAndContent
        header={<RecipientPageHeader />}
        content={
          <rfx.StageIdProvider stageId={stage._id}>
            <rfx.RequirementGroupIdProvider requirementGroupId={requirementGroupId}>
              <RequestRecipientBidStagePageInstance
                pageId={pageId}
                sectionId={search.sectionId}
                exchangeId={search.exchangeId}
              />
            </rfx.RequirementGroupIdProvider>
          </rfx.StageIdProvider>
        }
      />
    );
  },
});

export const requestSentRecipientAwardSummaryRoute = createRoute({
  getParentRoute: () => requestSentRecipientRoute,
  path: 'award-summary',
  beforeLoad: () => {
    return {
      getTitle: (t: TFunction) => {
        return t('request.recipientPage.awardSummary.title', { ns: 'translation' });
      },
    };
  },
  component: function RequestSentRecipientAwardSummaryRoute() {
    const { rfqId, recipientId } = requestSentRecipientAwardSummaryRoute.useParams();
    const { structure } = requestSentRecipientRoute.useLoaderData();

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    const bid = structure.bidById[recipientId];

    if (!bid) {
      throw new DsError('Could not find recipient bid', { rfqId, recipientId });
    }

    const bidOutcomeStatus = getBidOutcomeStatus(bid);
    const isAwarded = bidOutcomeStatus === BidOutcomeStatus.AWARDED;

    if (!isAwarded) {
      throw new DsError('Expected bid outcome status "awarded"', { rfqId, recipientId, bidOutcomeStatus });
    }

    return (
      <Layout.HeaderAndContent
        header={<RecipientPageHeader />}
        content={<RequestRecipientAwardSummary />}
      />
    );
  },
});

export const requestSentRecipientMessagesRoute = createRoute({
  getParentRoute: () => requestSentRecipientRoute,
  path: 'messages',
  beforeLoad: () => {
    return {
      getTitle: (t: TFunction) => {
        return t('request.recipientPage.messages.title', { ns: 'translation' });
      },
    };
  },
  validateSearch: (search: Record<string, unknown>): {
    exchangeId?: string;
    recipientId?: string;
    sectionId?: string;
  } => ({
    exchangeId: search.exchangeId as string,
    recipientId: search.recipientId as string,
    sectionId: search.sectionId as string,
  }),
  component: function RequestSentRecipientMessagesRoute() {
    const { rfqId, recipientId } = requestSentRecipientMessagesRoute.useParams();
    const { structure } = requestSentRecipientRoute.useLoaderData();
    const search = requestSentRecipientMessagesRoute.useSearch();

    const messagesPage = structure.pages.find(page => page.type === PageType.CHAT);

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    if (!messagesPage) {
      throw new Error('Could not find messages page');
    }

    return (
      <Layout.HeaderAndContent
        header={<RecipientPageHeader />}
        content={
          <BidSectionsContainer
            rfqId={rfqId}
            recipientId={recipientId}
            pageId={messagesPage._id}
            sectionId={search.sectionId}
            exchangeId={search.exchangeId}
          />
        }
      />
    );
  },
});

export const requestSentRecipientTeamRoute = createRoute({
  getParentRoute: () => requestSentRecipientRoute,
  path: 'team',
  beforeLoad: () => {
    return {
      getTitle: (t: TFunction) => {
        return t('request.recipientPage.team.title', { ns: 'translation' });
      },
    };
  },
  component: function RequestSentRecipientTeamRoute() {
    const { rfqId, recipientId } = requestSentRecipientTeamRoute.useParams();
    const { structure } = requestSentRecipientRoute.useLoaderData();

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    return (
      <Layout.HeaderAndContent
        header={<RecipientPageHeader />}
        content={
          <SupplierBidTeam rfqId={rfqId} recipientId={recipientId} />
        }
      />
    );
  },
});

export const requestSentRecipientAuditRoute = createRoute({
  getParentRoute: () => requestSentRecipientRoute,
  path: 'history',
  beforeLoad: () => {
    return {
      getTitle: (t: TFunction) => {
        return t('request.recipientPage.audit.title', { ns: 'translation' });
      },
    };
  },
  component: function RequestSentRecipientAuditRoute() {
    const { rfqId, recipientId } = requestSentRecipientAuditRoute.useParams();
    const { structure } = requestSentRecipientRoute.useLoaderData();

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    return (
      <Layout.HeaderAndContent
        header={<RecipientPageHeader />}
        content={
          <RfxAuditTrailContainer
            rfqId={rfqId}
            recipientId={recipientId}
          />
        }
      />
    );
  },
});

export const requestSentRecipientEvaluationRoute = createRoute({
  getParentRoute: () => requestSentRecipientRoute,
  path: 'evaluation',
});

export const requestSentRecipientEvaluationIndexRoute = createRoute({
  getParentRoute: () => requestSentRecipientEvaluationRoute,
  path: '/',
  component: function RequestSentRecipientEvaluationIndexRoute() {
    const { recipientId } = requestSentRecipientEvaluationIndexRoute.useParams();
    const { structure } = requestSentRecipientRoute.useLoaderData();
    const defaultPage = getPagesInDisplayOrder(structure.pages).find(isEvaluationPage);

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    if (!defaultPage) {
      throw new Error(`Could not find default evaluation page for recipient ${recipientId}'`);
    }

    return (
      <Navigate
        from={requestSentRecipientEvaluationIndexRoute.to}
        to={requestSentRecipientEvaluationPageRoute.to}
        params={params => ({ ...params, pageId: defaultPage._id })}
        replace={true}
      />
    );
  },
});

export const requestSentRecipientEvaluationPageRoute = createRoute({
  getParentRoute: () => requestSentRecipientEvaluationRoute,
  path: '$pageId',
  validateSearch: (search: Record<string, unknown>): { exchangeId?: string; recipientId?: string } => ({
    exchangeId: search.exchangeId as string,
    recipientId: search.recipientId as string,
  }),
  beforeLoad: () => {
    return {
      getTitle: (t: TFunction) => t('general.evaluation'),
    };
  },
  component: function RequestSentRecipientEvaluationPageRoute() {
    const { rfqId, recipientId, pageId } = requestSentRecipientEvaluationPageRoute.useParams();
    const { exchangeId } = requestSentRecipientEvaluationPageRoute.useSearch();
    const { structure } = requestSentRecipientRoute.useLoaderData();

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    return (
      <Layout.HeaderAndContent
        header={<RecipientPageHeader />}
        content={
          <EvaluationContainer
            rfqId={rfqId}
            pageId={pageId}
            recipientId={recipientId}
            exchangeId={exchangeId}
          />
        }
      />
    );
  },
});

export const legacyRequestSentRecipientRoute = createRoute({
  getParentRoute: () => requestSentRoute,
  path: 'recipient/$recipientId',
  loader: options => liveRequestLoader(options),
  component: function LegacyRequestSentRecipientRoute() {
    const { recipientId } = legacyRequestSentRecipientRoute.useParams();
    const { structure } = legacyRequestSentRecipientRoute.useLoaderData();

    return (
      <RecipientIdProvider recipientId={recipientId}>
        <RequestSentNavigationProvider recipientId={recipientId} structure={structure}>
          <Outlet />
        </RequestSentNavigationProvider>
      </RecipientIdProvider>
    );
  },
});

export const legacyRequestSentRecipientBidRoute = createRoute({
  getParentRoute: () => legacyRequestSentRecipientRoute,
  path: 'bid',
});

export const legacyRequestSentRecipientBidIndexRoute = createRoute({
  getParentRoute: () => legacyRequestSentRecipientBidRoute,
  path: '/',
  component: function LegacyRequestSentRecipientBidIndexRoute() {
    const { recipientId } = legacyRequestSentRecipientBidIndexRoute.useParams();
    const { structure } = legacyRequestSentRecipientRoute.useLoaderData();

    if (!structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be disabled');
    }

    const bid = structure.bidById[recipientId];

    if (!bid) {
      throw new Error(`No bid found for recipient ${recipientId}`);
    }

    const defaultPageId = first(structure.enteredBidPageIds);

    if (!defaultPageId) {
      throw new Error(`Could not find default page ID for recipient ${recipientId}`);
    }

    return (
      <Navigate
        from={legacyRequestSentRecipientBidIndexRoute.to}
        to={legacyRequestSentRecipientBidPageRoute.to}
        params={params => ({ ...params, pageId: defaultPageId })}
        replace={true}
      />
    );
  },
});

export const legacyRequestSentRecipientBidPageRoute = createRoute({
  getParentRoute: () => legacyRequestSentRecipientBidRoute,
  path: '$pageId',
  validateSearch: (search: Record<string, unknown>): { exchangeId?: string; sectionId?: string } => ({
    exchangeId: search.exchangeId as string,
    sectionId: search.sectionId as string,
  }),
  component: function LegacyRequestSentRecipientBidPageRoute() {
    const { rfqId, currentCompanyId, recipientId, pageId } = legacyRequestSentRecipientBidPageRoute.useParams();
    const { sectionId, exchangeId } = legacyRequestSentRecipientBidPageRoute.useSearch();
    const { structure } = legacyRequestSentRecipientRoute.useLoaderData();

    if (!structure.newFeaturesDisabled) {
      return (
        <Navigate
          from={legacyRequestSentRecipientBidPageRoute.to}
          to={requestSentRecipientBidExchangeRoute.to}
          params={{ currentCompanyId, rfqId, recipientId }}
          search={{ pageId, exchangeId }}
          replace={true}
        />
      );
    }

    return (
      <Layout.HeaderAndContent
        header={
          <SenderBidPageHeader selectedTabId="bid" selectedPageId={pageId} />
        }
        content={
          <BidSectionsContainer
            rfqId={rfqId}
            recipientId={recipientId}
            pageId={pageId}
            sectionId={sectionId}
            exchangeId={exchangeId}
          />
        }
      />
    );
  },
});

export const legacyRequestSentRecipientBidExchangeRoute = createRoute({
  getParentRoute: () => legacyRequestSentRecipientBidRoute,
  path: 'exchange/$exchangeId',
  validateSearch: (search: Record<string, unknown>): {
    pageId: string;
  } => ({
    pageId: search.pageId as string,
  }),
  component: function LegacyRequestSentRecipientBidExchangeRoute() {
    const { rfqId, currentCompanyId, recipientId, exchangeId } = legacyRequestSentRecipientBidExchangeRoute.useParams();
    const { pageId } = legacyRequestSentRecipientBidExchangeRoute.useSearch();
    const { structure } = legacyRequestSentRecipientRoute.useLoaderData();

    if (!structure.newFeaturesDisabled) {
      return (
        <Navigate
          from={legacyRequestSentRecipientBidExchangeRoute.to}
          to={requestSentRecipientBidExchangeRoute.to}
          params={{ currentCompanyId, rfqId, recipientId }}
          search={omitBy({ pageId, exchangeId }, isUndefined) as { exchangeId?: string; pageId: string }}
          replace={true}
        />
      );
    }

    return (
      <Navigate
        from={legacyRequestSentRecipientBidExchangeRoute.to}
        to={legacyRequestSentRecipientBidPageRoute.to}
        params={{ rfqId, currentCompanyId, pageId, recipientId }}
        search={{ exchangeId }}
        replace={true}
      />
    );
  },
});

export const legacyRequestSentRecipientEvaluationRoute = createRoute({
  getParentRoute: () => legacyRequestSentRecipientRoute,
  path: 'evaluation',
});

export const legacyRequestSentRecipientEvaluationIndexRoute = createRoute({
  getParentRoute: () => legacyRequestSentRecipientEvaluationRoute,
  path: '/',
  component: function LegacyRequestSentRecipientEvaluationIndexRoute() {
    const { structure } = legacyRequestSentRecipientRoute.useLoaderData();
    const defaultPage = structure.pages.find(page => page.type === PageType.EVALUATION);

    if (!structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be disabled');
    }

    return (
      <Navigate
        from={legacyRequestSentRecipientEvaluationIndexRoute.to}
        to={legacyRequestSentRecipientEvaluationPageRoute.to}
        // @ts-expect-error ts(18048) FIXME: 'defaultPage' is possibly 'undefined'.
        params={params => ({ ...params, pageId: defaultPage._id })}
        replace={true}
      />
    );
  },
});

export const legacyRequestSentRecipientEvaluationPageRoute = createRoute({
  getParentRoute: () => legacyRequestSentRecipientEvaluationRoute,
  path: '$pageId',
  validateSearch: (search: Record<string, unknown>): { exchangeId?: string; recipientId?: string } => ({
    exchangeId: search.exchangeId as string,
    recipientId: search.recipientId as string,
  }),
  component: function LegacyRequestSentRecipientEvaluationPageRoute() {
    const { currentCompanyId, rfqId, recipientId, pageId } = legacyRequestSentRecipientEvaluationPageRoute.useParams();
    const { exchangeId } = legacyRequestSentRecipientEvaluationPageRoute.useSearch();
    const { structure } = legacyRequestSentRecipientRoute.useLoaderData();

    if (!structure.newFeaturesDisabled) {
      return (
        <Navigate
          from={legacyRequestSentRecipientEvaluationPageRoute.to}
          to={requestSentRecipientEvaluationPageRoute.to}
          params={{ currentCompanyId, rfqId, pageId, recipientId }}
          search={{ exchangeId }}
          replace={true}
        />
      );
    }

    return (
      <Layout.HeaderAndContent
        header={
          <SenderBidPageHeader selectedTabId="evaluation" />
        }
        content={
          <EvaluationContainer
            rfqId={rfqId}
            pageId={pageId}
            recipientId={recipientId}
            exchangeId={exchangeId}
            renderOnLegacyBidPages
          />
        }
      />
    );
  },
});

export const legacyRequestSentRecipientTeamRoute = createRoute({
  getParentRoute: () => legacyRequestSentRecipientRoute,
  path: 'team',
  component: function LegacyRequestSentRecipientTeamRoute() {
    const { rfqId, recipientId } = legacyRequestSentRecipientTeamRoute.useParams();
    const { structure } = legacyRequestSentRecipientRoute.useLoaderData();

    if (!structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be disabled');
    }

    return (
      <Layout.HeaderAndContent
        header={
          <SenderBidPageHeader selectedTabId="team" />
        }
        content={
          <SupplierBidTeam rfqId={rfqId} recipientId={recipientId} />
        }
      />
    );
  },
});

export const legacyRequestSentRecipientAuditRoute = createRoute({
  getParentRoute: () => legacyRequestSentRecipientRoute,
  path: 'history',
  component: function LegacyRequestSentRecipientAuditRoute() {
    const { rfqId, recipientId } = legacyRequestSentRecipientAuditRoute.useParams();
    const { structure } = legacyRequestSentRecipientRoute.useLoaderData();

    if (!structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be disabled');
    }

    return (
      <Layout.HeaderAndContent
        header={
          <SenderBidPageHeader selectedTabId="history" />
        }
        content={
          <RfxAuditTrailContainer rfqId={rfqId} recipientId={recipientId} />
        }
      />
    );
  },
});

// #endregion

// #region Live Request (Recipient))

/* ------ Live Request (Recipient) ------ */

export const requestReceivedRoute = createRoute({
  getParentRoute: () => requestsRoute,
  path: 'received/$rfqId',
  beforeLoad: () => {
    return {
      getTitle: (t: TFunction, structure: RfxStructure<Live>) => {
        return structure.summary.subject;
      },
    };
  },
  // @ts-expect-error ts(2345) FIXME: Argument of type 'LoaderFnContext<Route<Route<RootRoute<undefined, RouterContext, AnyContext, AnyContext, {}, undefined, unknown>, "$currentCompanyId", "/$currentCompanyId", ... 9 more ..., unknown>, ... 11 more ..., unknown>, ... 4 more ..., () => { ...; }>' is not assignable to parameter of type '{ params: { currentCompanyId: string; rfqId: string; recipientId?: string | undefined; }; context: RouterContext & { isSender?: boolean | undefined; }; }'.
  loader: options => liveRequestLoader(options),
  component: function RequestReceivedRoute() {
    const { rfqId, currentCompanyId } = requestReceivedRoute.useParams();
    const { structure } = requestReceivedRoute.useLoaderData();

    const intercom = useIntercom();

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    useEffect(() => {
      intercom.trackEvent('new-supplier-request-ux-opened');
    }, [intercom]);

    return (
      <RfqIdProvider rfqId={rfqId}>
        <RecipientIdProvider recipientId={currentCompanyId}>
          <RequestRecipientNavigationProvider structure={structure}>
            <RequestHooksProvider>
              <Outlet key={rfqId} />
            </RequestHooksProvider>
          </RequestRecipientNavigationProvider>
        </RecipientIdProvider>
      </RfqIdProvider>
    );
  },

  errorComponent: withProps(ModelErrorPage, { model: 'request' }),
});

export const legacyRequestReceivedRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: '/request/received/$rfqId',
  loader: options => liveRequestLoader(options),
  component: function LegacyRequestReceivedRoute() {
    const { rfqId, currentCompanyId } = legacyRequestReceivedRoute.useParams();
    const { structure } = legacyRequestReceivedRoute.useLoaderData();

    return (
      <RfqIdProvider rfqId={rfqId}>
        <RecipientIdProvider recipientId={currentCompanyId}>
          <RequestRecipientNavigationProvider structure={structure}>
            <RequestHooksProvider>
              <Outlet key={rfqId} />
            </RequestHooksProvider>
          </RequestRecipientNavigationProvider>
        </RecipientIdProvider>
      </RfqIdProvider>
    );
  },

  errorComponent: withProps(ModelErrorPage, { model: 'request' }),
});

export const requestReceivedIndexRoute = createRoute({
  getParentRoute: () => requestReceivedRoute,
  path: '/',
  component: function RequestReceivedIndexRoute() {
    const { structure } = requestReceivedRoute.useLoaderData();

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    return (
      <Layout.HeaderAndContent
        header={<RecipientPageHeader />}
        content={<RequestRecipientIndex />}
      />
    );
  },
});

export const requestReceivedBidRoute = createRoute({
  getParentRoute: () => requestReceivedRoute,
  path: 'bid',
  beforeLoad: () => {
    return {
      getTitle: (t: TFunction) => {
        return t('request.recipientPage.bid.title.supplier', { ns: 'translation' });
      },
    };
  },
});

export const legacyRequestReceivedIndexRoute = createRoute({
  getParentRoute: () => legacyRequestReceivedRoute,
  path: '/',
  component: function LegacyRequestReceivedIndexRoute() {
    const { currentCompanyId } = legacyRequestReceivedIndexRoute.useParams();
    const { structure } = legacyRequestReceivedRoute.useLoaderData();

    if (!structure.newFeaturesDisabled) {
      return (
        <Navigate
          from={legacyRequestReceivedIndexRoute.to}
          to={requestReceivedIndexRoute.to}
          params={params => params}
          replace={true}
        />
      );
    }

    const bid = structure.bidById[currentCompanyId];

    if (!bid) {
      throw new Error(`No bid found for recipient ${currentCompanyId}`);
    }

    const isAtFirstStage = bid.stageId === first(bid.enteredStageIds);
    const hasActivatedAnyStage = bid.activatedStageIds.length > 0;

    const hasBidTab = !isAtFirstStage || hasActivatedAnyStage;

    if (hasBidTab) {
      return (
        <Navigate
          from={legacyRequestReceivedIndexRoute.to}
          to={legacyRequestReceivedBidIndexRoute.to}
          params={params => params}
          replace={true}
        />
      );
    }

    return (
      <Navigate
        from={legacyRequestReceivedIndexRoute.to}
        to={legacyRequestReceivedDetailsRoute.to}
        params={params => params}
        replace={true}
      />
    );
  },
});

export const legacyRequestReceivedBidRoute = createRoute({
  getParentRoute: () => legacyRequestReceivedRoute,
  path: 'bid',
});

export const requestReceivedBidExchangeRoute = createRoute({
  getParentRoute: () => requestReceivedBidRoute,
  path: 'exchange/$exchangeId',
  validateSearch: (search: Record<string, unknown>): {
    pageId: string;
  } => ({
    pageId: search.pageId as string,
  }),
  component: function RequestReceivedBidExchangeRoute() {
    const { rfqId, currentCompanyId, exchangeId } = requestReceivedBidExchangeRoute.useParams();
    const { structure } = requestReceivedRoute.useLoaderData();
    const search = requestReceivedBidExchangeRoute.useSearch();

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    const page = structure.pages.find(page => page._id === search.pageId);

    assertDefined(page, 'page');

    const isMessagesPage = page.type === PageType.CHAT;

    if (isMessagesPage) {
      return (
        <Navigate
          from={requestReceivedBidExchangeRoute.to}
          to={requestReceivedMessagesRoute.to}
          params={{ rfqId, currentCompanyId }}
          search={exchangeId ? { exchangeId } : undefined}
          replace={true}
        />
      );
    }

    const exchangeDef = structure.exchangeDefById[exchangeId];
    const section = exchangeDef
      // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
      ? structure.sectionById[exchangeDef.sectionId]
      : null;

    const bid = structure.bidById[currentCompanyId];

    if (!bid) {
      throw new Error(`No bid found for recipient ${currentCompanyId}`);
    }

    const stageId = rfx.getExchangeStageId(exchangeDef, section, bid);

    // when we couldn't find a stage ID, navigate to the bid page
    if (!stageId) {
      return (
        <Navigate
          from={requestReceivedBidExchangeRoute.to}
          to={requestReceivedBidIndexRoute.to}
          params={{ rfqId, currentCompanyId }}
          replace={true}
        />
      );
    }

    if (structure.settings.areLotsEnabled) {
      const requirementGroupId = first(section.lotIds) || 'general';

      return (
        <Navigate
          from={requestReceivedBidExchangeRoute.to}
          to={requestReceivedBidStageRequirementPageInstanceRoute.to}
          // @ts-expect-error ts(2322) FIXME: Type '{}' is not assignable to type 'string'.
          params={{ rfqId, currentCompanyId, pageId: search.pageId, stageId, requirementGroupId }}
          search={exchangeId ? { exchangeId } : undefined}
          replace={true}
        />
      );
    } else {
      return (
        <Navigate
          from={requestReceivedBidExchangeRoute.to}
          to={requestReceivedBidStagePageInstanceRoute.to}
          params={{ rfqId, currentCompanyId, pageId: search.pageId, stageId }}
          search={exchangeId ? { exchangeId } : undefined}
          replace={true}
        />
      );
    }
  },
});

export const requestReceivedBidIndexRoute = createRoute({
  getParentRoute: () => requestReceivedBidRoute,
  path: '/',
  component: function RequestReceivedBidIndexRoute() {
    const { structure } = requestReceivedRoute.useLoaderData();

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    return (
      <Layout.HeaderAndContent
        header={<RecipientPageHeader />}
        content={<RequestRecipientBidIndex />}
      />
    );
  },
});

export const legacyRequestReceivedBidIndexRoute = createRoute({
  getParentRoute: () => legacyRequestReceivedBidRoute,
  path: '/',
  component: function LegacyRequestReceivedBidIndexRoute() {
    const { currentCompanyId, rfqId } = legacyRequestReceivedBidIndexRoute.useParams();
    const { structure } = legacyRequestReceivedRoute.useLoaderData();

    if (!structure.newFeaturesDisabled) {
      return (
        <Navigate
          from={legacyRequestReceivedBidIndexRoute.to}
          to={requestReceivedBidIndexRoute.to}
          params={params => params}
          replace={true}
        />
      );
    }

    const bid = structure.bidById[currentCompanyId];

    if (!bid) {
      throw new Error(`No bid found for recipient ${currentCompanyId}`);
    }

    const isAtFirstStage = bid.stageId === first(bid.enteredStageIds);
    const hasActivatedAnyStage = bid.activatedStageIds.length > 0;

    const hasBidTab = !isAtFirstStage || hasActivatedAnyStage;

    if (!hasBidTab) {
      return (
        <Navigate
          from={legacyRequestReceivedBidIndexRoute.to}
          to={legacyRequestReceivedDetailsRoute.to}
          params={{ rfqId, currentCompanyId }}
          replace={true}
        />
      );
    }

    const defaultPageId = first(structure.enteredBidPageIds);

    if (!defaultPageId) {
      throw new Error(`Could not find default page ID for recipient ${currentCompanyId}`);
    }

    return (
      <Navigate
        from={legacyRequestReceivedBidIndexRoute.to}
        to={legacyRequestReceivedBidPageRoute.to}
        params={params => ({ ...params, pageId: defaultPageId })}
        replace={true}
      />
    );
  },
});

export const requestReceivedBidStageRoute = createRoute({
  getParentRoute: () => requestReceivedBidRoute,
  path: 'stage/$stageId',
  beforeLoad: ({ params }) => {
    return {
      getTitle: (t: TFunction, structure: RfxStructure<Live>) => {
        const stage = structure.stageById[params.stageId];
        const stageIndex = structure.stages.findIndex(stage => stage._id === params.stageId);

        return renderStageName(stage, t, stageIndex, true);
      },
    };
  },
});

export const requestReceivedBidStageIndexRoute = createRoute({
  getParentRoute: () => requestReceivedBidStageRoute,
  path: '/',
  component: function RequestReceivedBidStageIndexRoute() {
    const intercom = useIntercom();
    const { rfqId, currentCompanyId, stageId } = requestReceivedBidStageRoute.useParams();
    const { structure } = requestReceivedRoute.useLoaderData();
    const navigation = useRequestRecipientNavigation();

    const stage = structure.stageById[stageId];
    const bid = structure.bidById[currentCompanyId];

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    if (!bid) {
      throw new Error(`No bid found for recipient ${currentCompanyId}`);
    }

    if (!stage) {
      throw new Error(`Stage ${stageId} not found`);
    }

    if (!bid.enteredStageIds.includes(stageId)) {
      throw new Error(`Stage ${stageId} has not been entered`);
    }

    const hasLots = !isEmpty(structure.lots);

    const isAuctionStageRoute = isAuctionStage(stage);

    useEffect(
      () => {
        if (isAuctionStageRoute) {
          intercom.update({
            hideDefaultLauncher: true,
            verticalPadding: 72,
          });
        }

        return () => {
          if (isAuctionStageRoute) {
            intercom.update({
              hideDefaultLauncher: false,
            });
          }
        };
      },
      [isAuctionStageRoute, intercom],
    );

    const hasStageBeenActivated = bid.activatedStageIds.includes(stageId);

    return isAuctionStageRoute ? (
      <Layout.HeaderAndContent
        header={<RecipientPageHeader isHeaderCollapsible />}
        content={
          <rfx.StageIdProvider stageId={stage._id}>
            <AuctionBidContainer
              rfqId={rfqId}
              recipientId={currentCompanyId}
              navigateToTeam={() => navigation.navigateToTeam()}
              isPreview={!hasStageBeenActivated}
            />
          </rfx.StageIdProvider>
        }
      />
    ) : hasLots ? (
      <Layout.HeaderAndContent
        header={<RecipientPageHeader />}
        content={
          <rfx.StageIdProvider stageId={stage._id}>
            <RequestRecipientBidStageIndex />
          </rfx.StageIdProvider>
        }
      />
    ) : (
      <Navigate
        from={requestReceivedBidStageIndexRoute.to}
        to={requestReceivedBidStagePageIndexRoute.to}
        replace={true}
      />
    );
  },
});

export const requestReceivedBidStagePageRoute = createRoute({
  getParentRoute: () => requestReceivedBidStageRoute,
  path: 'page',
});

export const requestReceivedBidStagePageIndexRoute = createRoute({
  getParentRoute: () => requestReceivedBidStagePageRoute,
  path: '/',
  component: function RequestReceivedBidStagePageIndexRoute() {
    const { stageId, currentCompanyId } = requestReceivedBidStagePageRoute.useParams();
    const { structure } = requestReceivedRoute.useLoaderData();
    // @ts-expect-error ts(18048) FIXME: 'structure.enteredPageIdsByRequirementGroupIdByStageId' is possibly 'undefined'.
    const defaultPageId = first(structure.enteredPageIdsByRequirementGroupIdByStageId[stageId]?.general);

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    if (!defaultPageId) {
      throw new Error(`Could not find default page ID for recipient ${currentCompanyId}, stage ID ${stageId} and requirement 'general'`);
    }

    return (
      <Navigate
        from={requestReceivedBidStagePageIndexRoute.to}
        to={requestReceivedBidStagePageInstanceRoute.to}
        params={params => ({ ...params, pageId: defaultPageId })}
        replace={true}
      />
    );
  },
});

export const requestReceivedBidStagePageInstanceRoute = createRoute({
  getParentRoute: () => requestReceivedBidStagePageRoute,
  path: '$pageId',
  validateSearch: (search: Record<string, unknown>): {
    exchangeId?: string;
    recipientId?: string;
    sectionId?: string;
  } => ({
    exchangeId: search.exchangeId as string,
    recipientId: search.recipientId as string,
    sectionId: search.sectionId as string,
  }),
  component: function RequestReceivedBidStagePageInstanceRoute() {
    const { currentCompanyId, pageId, stageId } = requestReceivedBidStagePageInstanceRoute.useParams();
    const { structure } = requestReceivedRoute.useLoaderData();
    const search = requestReceivedBidStagePageInstanceRoute.useSearch();
    const stage = structure.stageById[stageId];
    const bid = structure.bidById[currentCompanyId];
    const page = structure.pages.find(page => page._id === pageId);

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    if (!bid) {
      throw new Error(`No bid found for recipient ${currentCompanyId}`);
    }

    if (!stage) {
      throw new Error(`Stage ${stageId} not found`);
    }

    if (!bid.enteredStageIds.includes(stageId)) {
      throw new Error(`Stage ${stageId} has not been entered`);
    }

    if (!page) {
      throw new Error(`Page ${pageId} not found`);
    }

    // @ts-expect-error ts(18048) FIXME: 'structure.enteredPageIdsByRequirementGroupIdByStageId' is possibly 'undefined'.
    if (!structure.enteredPageIdsByRequirementGroupIdByStageId[stageId].general.includes(pageId)) {
      throw new Error(`Page ${pageId} is not part of enteredPageIdsByRequirementGroupIdByStageId`);
    }

    const isMessagesPage = page.type === PageType.CHAT;

    if (isMessagesPage) {
      throw new Error('Cannot render messages page at stage route');
    }

    return (
      <Layout.HeaderAndContent
        header={<RecipientPageHeader />}
        content={
          <rfx.StageIdProvider stageId={stage._id}>
            <RequestRecipientBidStagePageInstance
              pageId={pageId}
              sectionId={search.sectionId}
              exchangeId={search.exchangeId}
            />
          </rfx.StageIdProvider>
        }
      />
    );
  },
});

export const requestReceivedBidStagePageInstanceSectionRoute = createRoute({
  getParentRoute: () => requestReceivedBidStagePageRoute,
  path: '$pageId/section/$sectionId',
  beforeLoad: ({ params }) => {
    return {
      getTitle: (t: TFunction, structure: RfxStructure<Live>) => {
        const section = structure.sectionById[params.sectionId];

        return section.name;
      },
    };
  },
  component: function RequestReceivedBidStagePageInstanceSectionRoute() {
    const { currentCompanyId, pageId, stageId, sectionId, rfqId } = requestReceivedBidStagePageInstanceSectionRoute.useParams();
    const { structure } = requestReceivedRoute.useLoaderData();
    const stage = structure.stageById[stageId];
    const bid = structure.bidById[currentCompanyId];
    const page = structure.pages.find(page => page._id === pageId);
    const section = structure.sectionById[sectionId];

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    if (!bid) {
      throw new Error(`No bid found for recipient ${currentCompanyId}`);
    }

    if (!stage) {
      throw new Error(`Stage ${stageId} not found`);
    }

    if (!bid.enteredStageIds?.includes(stageId)) {
      throw new Error(`Stage ${stageId} has not been entered`);
    }

    if (!page) {
      throw new Error(`Page ${pageId} not found`);
    }

    if (!section) {
      throw new Error(`Section ${sectionId} not found`);
    }

    // @ts-expect-error ts(18048) FIXME: 'structure.enteredPageIdsByRequirementGroupIdByStageId' is possibly 'undefined'.
    if (!structure.enteredPageIdsByRequirementGroupIdByStageId[stageId].general.includes(pageId)) {
      throw new Error(`Page ${pageId} is not part of enteredPageIdsByRequirementGroupIdByStageId`);
    }

    const isMessagesPage = page.type === PageType.CHAT;

    if (isMessagesPage) {
      throw new Error('Cannot render messages page at stage route');
    }

    if (section.type !== SectionType.LINE_ITEMS) {
      throw new Error(`Can only render line items section, got ${section.type}`);
    }

    return (
      <Layout.HeaderAndContent
        isContentFullWidth
        shouldHeaderCollapseCompletely
        header={<RecipientPageHeader />}
        content={
          <RfqIdProvider rfqId={rfqId}>
            <rfx.StructureProvider structure={structure}>
              <rfx.StageIdProvider stageId={stage._id}>
                <rfx.PageProvider page={page}>
                  <rfx.SectionProvider section={section}>
                    <BulkSubmitPage />
                  </rfx.SectionProvider>
                </rfx.PageProvider>
              </rfx.StageIdProvider>
            </rfx.StructureProvider>
          </RfqIdProvider>
        }
        contentPaddingBottom="0px"
        contentPaddingTop="0px"
      />
    );
  },
});

export const requestReceivedBidStageRequirementPageRoute = createRoute({
  getParentRoute: () => requestReceivedBidStageRoute,
  path: 'requirement/$requirementGroupId/page',
});

export const requestReceivedBidStageRequirementPageIndexRoute = createRoute({
  getParentRoute: () => requestReceivedBidStageRequirementPageRoute,
  path: '/',
  component: function RequestReceivedBidStageRequirementPageIndexRoute() {
    const { currentCompanyId, stageId, requirementGroupId } = requestReceivedBidStageRequirementPageRoute.useParams();
    const { structure } = requestReceivedRoute.useLoaderData();
    // @ts-expect-error ts(18048) FIXME: 'structure.enteredPageIdsByRequirementGroupIdByStageId' is possibly 'undefined'.
    const defaultPageId = first(structure.enteredPageIdsByRequirementGroupIdByStageId[stageId]?.[requirementGroupId]);

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    if (!defaultPageId) {
      throw new Error(`Could not find default page ID for recipient ${currentCompanyId}, stage ID ${stageId} and requirement ${requirementGroupId}`);
    }

    return (
      <Navigate
        from={requestReceivedBidStageRequirementPageIndexRoute.to}
        to={requestReceivedBidStageRequirementPageInstanceRoute.to}
        params={params => ({ ...params, pageId: defaultPageId })}
        replace={true}
      />
    );
  },
});

export const requestReceivedBidStageRequirementPageInstanceRoute = createRoute({
  getParentRoute: () => requestReceivedBidStageRequirementPageRoute,
  path: '$pageId',
  beforeLoad: ({ params }) => {
    return {
      getTitle: (t: TFunction, structure: RfxStructure<Live>) => {
        if (params.requirementGroupId === 'general') {
          return t('request.generalRequirement_other', { ns: 'translation' });
        } else {
          return `${t('request.lot', { count: 1, ns: 'translation' })} ${structure.lots.findIndex(lot => lot._id === params.requirementGroupId) + 1} – ${structure.lotById[params.requirementGroupId]?.name}`;
        }
      },
    };
  },
});

export const requestReceivedBidStageRequirementPageInstanceIndexRoute = createRoute({
  getParentRoute: () => requestReceivedBidStageRequirementPageInstanceRoute,
  path: '/',
  validateSearch: (search: Record<string, unknown>): {
    exchangeId?: string;
    recipientId?: string;
    sectionId?: string;
  } => ({
    exchangeId: search.exchangeId as string,
    recipientId: search.recipientId as string,
    sectionId: search.sectionId as string,
  }),
  beforeLoad: ({ params }) => {
    return {
      getTitle: (t: TFunction, structure: RfxStructure<Live>) => {
        if (params.requirementGroupId === 'general') {
          return t('request.generalRequirement_other', { ns: 'translation' });
        } else {
          return `${t('request.lot', { count: 1, ns: 'translation' })} ${structure.lots.findIndex(lot => lot._id === params.requirementGroupId) + 1} – ${structure.lotById[params.requirementGroupId]?.name}`;
        }
      },
    };
  },
  component: function RequestReceivedBidStageRequirementPageInstanceRoute() {
    const { currentCompanyId, pageId, stageId, requirementGroupId } = requestReceivedBidStageRequirementPageInstanceIndexRoute.useParams();
    const { structure } = requestReceivedRoute.useLoaderData();
    const search = requestReceivedBidStageRequirementPageInstanceIndexRoute.useSearch();
    const stage = structure.stageById[stageId];
    const bid = structure.bidById[currentCompanyId];
    const page = structure.pages.find(page => page._id === pageId);

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    if (!bid) {
      throw new Error(`No bid found for recipient ${currentCompanyId}`);
    }

    if (!stage) {
      throw new Error(`Stage ${stageId} not found`);
    }

    if (!bid.enteredStageIds.includes(stageId)) {
      throw new Error(`Stage ${stageId} has not been entered`);
    }

    if (!page) {
      throw new Error(`Page ${pageId} not found`);
    }

    if (requirementGroupId !== 'general' && !structure.lotById[requirementGroupId]) {
      throw new Error(`Requirement group ${requirementGroupId} not found`);
    }

    // @ts-expect-error ts(18048) FIXME: 'structure.enteredPageIdsByRequirementGroupIdByStageId' is possibly 'undefined'.
    if (!structure.enteredPageIdsByRequirementGroupIdByStageId[stageId][requirementGroupId].includes(pageId)) {
      throw new Error(`Page ${pageId} is not part of enteredPageIdsByRequirementGroupIdByStageId`);
    }

    const isMessagesPage = page.type === PageType.CHAT;

    if (isMessagesPage) {
      throw new Error('Cannot render messages page at stage route');
    }

    return (
      <Layout.HeaderAndContent
        header={<RecipientPageHeader />}
        content={
          <rfx.StageIdProvider stageId={stage._id}>
            <rfx.RequirementGroupIdProvider requirementGroupId={requirementGroupId}>
              <RequestRecipientBidStagePageInstance
                pageId={pageId}
                sectionId={search.sectionId}
                exchangeId={search.exchangeId}
              />
            </rfx.RequirementGroupIdProvider>
          </rfx.StageIdProvider>
        }
      />
    );
  },
});

export const requestReceivedBidStageRequirementPageInstanceSectionRoute = createRoute({
  getParentRoute: () => requestReceivedBidStageRequirementPageInstanceRoute,
  path: 'section/$sectionId',
  beforeLoad: ({ params }) => {
    return {
      getTitle: (t: TFunction, structure: RfxStructure<Live>) => {
        const section = structure.sectionById[params.sectionId];

        return section.name;
      },
    };
  },
  component: function RequestReceivedBidStageRequirementPageInstanceRoute() {
    const { currentCompanyId, pageId, stageId, requirementGroupId, sectionId } = requestReceivedBidStageRequirementPageInstanceSectionRoute.useParams();
    const { structure } = requestReceivedRoute.useLoaderData();
    const stage = structure.stageById[stageId];
    const bid = structure.bidById[currentCompanyId];
    const page = structure.pages.find(page => page._id === pageId);
    const section = structure.sectionById[sectionId];

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    if (!bid) {
      throw new Error(`No bid found for recipient ${currentCompanyId}`);
    }

    if (!stage) {
      throw new Error(`Stage ${stageId} not found`);
    }

    if (!bid.enteredStageIds.includes(stageId)) {
      throw new Error(`Stage ${stageId} has not been entered`);
    }

    if (!page) {
      throw new Error(`Page ${pageId} not found`);
    }

    if (!section) {
      throw new Error(`Section ${sectionId} not found`);
    }

    if (requirementGroupId !== 'general' && !structure.lotById[requirementGroupId]) {
      throw new Error(`Requirement group ${requirementGroupId} not found`);
    }

    // @ts-expect-error ts(18048) FIXME: 'structure.enteredPageIdsByRequirementGroupIdByStageId' is possibly 'undefined'.
    if (!structure.enteredPageIdsByRequirementGroupIdByStageId[stageId][requirementGroupId].includes(pageId)) {
      throw new Error(`Page ${pageId} is not part of enteredPageIdsByRequirementGroupIdByStageId`);
    }

    const isMessagesPage = page.type === PageType.CHAT;

    if (isMessagesPage) {
      throw new Error('Cannot render messages page at stage route');
    }

    if (section.type !== SectionType.LINE_ITEMS) {
      throw new Error(`Can only render line items section, got ${section.type}`);
    }

    return (
      <Layout.HeaderAndContent
        header={<RecipientPageHeader />}
        content={
          <rfx.StageIdProvider stageId={stage._id}>
            <rfx.RequirementGroupIdProvider requirementGroupId={requirementGroupId}>
              Requirements Line Items Section
            </rfx.RequirementGroupIdProvider>
          </rfx.StageIdProvider>
        }
      />
    );
  },
});

export const legacyRequestReceivedBidPageRoute = createRoute({
  getParentRoute: () => legacyRequestReceivedBidRoute,
  path: '$pageId',
  validateSearch: (search: Record<string, unknown>): {
    exchangeId?: string;
    recipientId?: string;
    sectionId?: string;
  } => ({
    exchangeId: search.exchangeId as string,
    recipientId: search.recipientId as string,
    sectionId: search.sectionId as string,
  }),
  component: function LegacyRequestReceivedBidPageRoute() {
    const { rfqId, currentCompanyId, pageId } = legacyRequestReceivedBidPageRoute.useParams();
    const { structure } = legacyRequestReceivedRoute.useLoaderData();
    const search = legacyRequestReceivedBidPageRoute.useSearch();
    const bid = structure.bidById[currentCompanyId];
    const page = structure.pages.find(page => page._id === pageId);

    assertDefined(page, 'page');

    const isMessagesPage = page.type === PageType.CHAT;
    const isAtFirstStage = bid.enteredStageIds.length === 1;
    const hasIntention = bid.stageId ? bid.activatedStageIds.includes(bid.stageId) : false;
    const isBidOutcomeDecided = bid.status === BidStatus.AWARDED || bid.status === BidStatus.UNSUCCESSFUL;
    const isBidWithdrawn = bid.status === BidStatus.WITHDRAWN;
    const enteredStageNumber = bid?.enteredStageIds.length;
    const activatedStageNumber = bid?.activatedStageIds.length;

    if (!structure.newFeaturesDisabled) {
      if (isMessagesPage) {
        return (
          <Navigate
            from={legacyRequestReceivedBidPageRoute.to}
            to={requestReceivedMessagesRoute.to}
            params={{ rfqId, currentCompanyId }}
            search={omitBy({
              exchangeId: search.exchangeId,
              sectionId: search.sectionId,
              recipientId: search.recipientId,
            }, value => !value)}
            replace={true}
          />
        );
      } else {
        return (
          <Navigate
            from={legacyRequestReceivedBidPageRoute.to}
            to={requestReceivedBidExchangeRoute.to}
            // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
            params={{ currentCompanyId, rfqId, exchangeId: search.exchangeId }}
            // @ts-expect-error ts(2322) FIXME: Type '{ pageId: string; } | undefined' is not assignable to type '{ pageId: string; } | ParamsReducerFn<Router<RootRoute<undefined, RouterContext, AnyContext, AnyContext, {}, undefined, readonly [Route<RootRoute<undefined, RouterContext, ... 4 more ..., unknown>, ... 11 more ..., unknown>, ... 4 more ..., Route<...>]>, TrailingSlashOption, Record<...>, Record<...>>, "SEARCH", "/$c...'.
            search={pageId ? { pageId } : undefined}
            replace={true}
          />
        );
      }
    }

    if (isAtFirstStage && !hasIntention && !isMessagesPage) {
      return (
        <Navigate
          to={legacyRequestReceivedDetailsRoute.to}
          params={{ rfqId, currentCompanyId }}
          replace={true}
        />
      );
    }

    const showBidIntentionPanel = (
      !isMessagesPage &&
      !isBidOutcomeDecided &&
      (isBidWithdrawn || enteredStageNumber !== activatedStageNumber)
    );

    return (
      <Layout.HeaderAndContent
        header={
          <LegacyRecipientPageHeader
            selectedTabId={isMessagesPage ? 'messages' : 'bid'}
            selectedPageId={pageId}
            rfqId={rfqId}
            recipientId={currentCompanyId}
          />
        }
        content={
          <>
            {showBidIntentionPanel ? (
              <BidIntentionPanel
                rfqId={rfqId}
                recipientId={currentCompanyId}
              />
            ) : !isMessagesPage ? (
              <BidProgressPanel rfqId={rfqId} />
            ) : (
              null
            )}
            <BidSectionsContainer
              rfqId={rfqId}
              recipientId={currentCompanyId}
              pageId={pageId}
              sectionId={search.sectionId}
              exchangeId={search.exchangeId}
            />
          </>
        }
      />
    );
  },
});

export const requestReceivedAwardSummaryRoute = createRoute({
  getParentRoute: () => requestReceivedRoute,
  path: 'award-summary',
  beforeLoad: () => {
    return {
      getTitle: (t: TFunction) => {
        return t('request.recipientPage.awardSummary.title', { ns: 'translation' });
      },
    };
  },
  component: function RequestReceivedAwardSummaryRoute() {
    const { rfqId, currentCompanyId } = requestReceivedAwardSummaryRoute.useParams();
    const { structure } = requestReceivedRoute.useLoaderData();

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    const bid = structure.bidById[currentCompanyId];

    if (!bid) {
      throw new DsError('Could not find recipient bid', { rfqId, recipientId: currentCompanyId });
    }

    const bidOutcomeStatus = getBidOutcomeStatus(bid);
    const isAwarded = bidOutcomeStatus === BidOutcomeStatus.AWARDED;

    if (!isAwarded) {
      throw new DsError('Expected bid outcome status "awarded"', { rfqId, recipientId: currentCompanyId, bidOutcomeStatus });
    }

    return (
      <Layout.HeaderAndContent
        header={<RecipientPageHeader />}
        content={<RequestRecipientAwardSummary />}
      />
    );
  },
});

export const requestReceivedMessagesRoute = createRoute({
  getParentRoute: () => requestReceivedRoute,
  path: 'messages',
  beforeLoad: () => {
    return {
      getTitle: (t: TFunction) => {
        return t('request.recipientPage.messages.title', { ns: 'translation' });
      },
    };
  },
  validateSearch: (search: Record<string, unknown>): {
    exchangeId?: string;
    recipientId?: string;
    sectionId?: string;
  } => ({
    exchangeId: search.exchangeId as string,
    recipientId: search.recipientId as string,
    sectionId: search.sectionId as string,
  }),
  component: function RequestReceivedMessagesRoute() {
    const { rfqId, currentCompanyId } = requestReceivedMessagesRoute.useParams();
    const { structure } = requestReceivedRoute.useLoaderData();
    const search = requestReceivedMessagesRoute.useSearch();

    const messagesPage = structure.pages.find(page => page.type === PageType.CHAT);

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    if (!messagesPage) {
      throw new Error('Could not find messages page');
    }

    return (
      <Layout.HeaderAndContent
        header={<RecipientPageHeader />}
        content={
          <BidSectionsContainer
            rfqId={rfqId}
            recipientId={currentCompanyId}
            pageId={messagesPage._id}
            sectionId={search.sectionId}
            exchangeId={search.exchangeId}
          />
        }
      />
    );
  },
});

/**
 * Only used for links to chat and clarification exchanges in emails
 */
export const legacyRequestReceivedBidPageExchangeRoute = createRoute({
  getParentRoute: () => legacyRequestReceivedBidPageRoute,
  path: 'exchange/$exchangeId',
  component: function LegacyRequestReceivedBidExchangeRoute() {
    const { rfqId, currentCompanyId, exchangeId, pageId } = legacyRequestReceivedBidPageExchangeRoute.useParams();
    const { structure } = legacyRequestReceivedRoute.useLoaderData();

    if (!structure.newFeaturesDisabled) {
      return (
        <Navigate
          from={legacyRequestReceivedBidPageExchangeRoute.to}
          to={requestReceivedMessagesRoute.to}
          params={{ rfqId, currentCompanyId }}
          search={{ exchangeId }}
          replace={true}
        />
      );
    }

    return (
      <Navigate
        from={legacyRequestReceivedBidPageExchangeRoute.to}
        to={legacyRequestReceivedBidPageRoute.to}
        params={{ rfqId, currentCompanyId, pageId }}
        search={{ exchangeId }}
        replace={true}
      />
    );
  },
});

export const legacyRequestReceivedDetailsRoute = createRoute({
  getParentRoute: () => legacyRequestReceivedRoute,
  path: 'details',
  component: function LegacyRequestReceivedDetailsRoute() {
    const { rfqId, currentCompanyId } = legacyRequestReceivedDetailsRoute.useParams();
    const { structure } = legacyRequestReceivedRoute.useLoaderData();

    if (!structure.newFeaturesDisabled) {
      return (
        <Navigate
          to={requestReceivedIndexRoute.to}
          params={{ rfqId, currentCompanyId }}
          replace={true}
        />
      );
    }

    return (
      <Layout.HeaderAndContent
        header={
          <LegacyRecipientPageHeader
            selectedTabId="details"
            rfqId={rfqId}
            recipientId={currentCompanyId}
          />
        }
        content={
          <LegacyRequestReceivedDetails />
        }
      />
    );
  },
});

export const legacyRequestReceivedAuctionRoute = createRoute({
  getParentRoute: () => legacyRequestReceivedRoute,
  path: 'auction',
  component: function LegacyRequestReceivedAuctionRoute() {
    const intercom = useIntercom();
    const { rfqId, currentCompanyId } = legacyRequestReceivedAuctionRoute.useParams();
    const { structure } = legacyRequestReceivedRoute.useLoaderData();
    const navigation = useRequestRecipientNavigation();

    useEffect(() => {
      if (structure.newFeaturesDisabled) {
        intercom.update({
          hideDefaultLauncher: true,
          verticalPadding: 72,
        });
      }

      return () => {
        if (structure.newFeaturesDisabled) {
          intercom.update({
            hideDefaultLauncher: false,
          });
        }
      };
    }, [structure.newFeaturesDisabled, intercom]);

    const { bidById, stages } = structure;

    const bid = bidById[currentCompanyId];
    const auctionStageId = stages.find(stage => stage.type === StageType.AUCTION)?._id;

    if (!structure.newFeaturesDisabled) {
      return (
        <Navigate
          to={requestReceivedBidStageIndexRoute.to}
          // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
          params={{ rfqId, currentCompanyId, stageId: auctionStageId }}
          replace={true}
        />
      );
    }

    if (!bid || !auctionStageId || !bid.activatedStageIds.includes(auctionStageId)) {
      return (
        <Navigate
          to={legacyRequestReceivedBidIndexRoute.to}
          params={{ rfqId, currentCompanyId }}
          replace={true}
        />
      );
    }

    return (
      <Layout.HeaderAndContent
        header={
          <LegacyRecipientPageHeader
            selectedTabId="auction"
            rfqId={rfqId}
            recipientId={currentCompanyId}
          />
        }
        content={
          <AuctionBidContainer
            rfqId={rfqId}
            recipientId={currentCompanyId}
            navigateToTeam={() => navigation.navigateToTeam()}
          />
        }
      />
    );
  },
});

export const requestReceivedLegacyBulletinsRoute = createRoute({
  getParentRoute: () => requestReceivedRoute,
  path: 'bulletins',
});

export const requestReceivedLegacyBulletinsIndexRoute = createRoute({
  getParentRoute: () => requestReceivedLegacyBulletinsRoute,
  path: '/',
  component: () => (
    <Navigate
      from={requestReceivedLegacyBulletinsIndexRoute.to}
      to={requestReceivedLegacyBulletinsListRoute.to}
      params={params => params}
      replace={true}
    />
  ),
});

export const requestReceivedLegacyBulletinsListRoute = createRoute({
  getParentRoute: () => requestReceivedLegacyBulletinsRoute,
  path: 'clarifications',
  beforeLoad: () => {
    return {
      getTitle: (t: TFunction) => {
        return t('request.recipientPage.bulletins.title', { ns: 'translation' });
      },
    };
  },
  component: function RequestReceivedLegacyBulletinsListRoute() {
    const { rfqId, currentCompanyId } = requestReceivedLegacyBulletinsListRoute.useParams();
    const { structure } = requestReceivedRoute.useLoaderData();
    const { t } = useTranslation();

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    const ref = useNotificationSubject({
      filter: conforms<Partial<Notification>>({
        domain: domain => domain === NotificationDomain.RFQ_RECEIVED,
        action: action => action === NotificationAction.BULLETIN_SIMPLE_CLARIFICATION,
        meta: meta => meta.rfqId === rfqId,
        to: to => to.companyId === currentCompanyId,
      }),
    });

    return (
      <Layout.HeaderAndContent
        header={<RecipientPageHeader />}
        content={
          // TODO implement content if we decide to preserve the legacy bulletin notifications
          <div ref={ref}>
            <ErrorPanel error={t('errors.unexpected')} />
          </div>
        }
      />
    );
  },
});

export const requestReceivedLegacyBulletinQuestionsRoute = createRoute({
  getParentRoute: () => requestReceivedLegacyBulletinsRoute,
  path: 'questions',
  beforeLoad: () => {
    return {
      getTitle: (t: TFunction) => {
        return t('request.recipientPage.bulletins.title', { ns: 'translation' });
      },
    };
  },
  component: function RequestReceivedLegacyBulletinQuestionsRoute() {
    const { rfqId, currentCompanyId } = requestReceivedLegacyBulletinQuestionsRoute.useParams();
    const { structure } = requestReceivedRoute.useLoaderData();
    const { t } = useTranslation();

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    const ref = useNotificationSubject({
      filter: conforms<Partial<Notification>>({
        domain: domain => domain === NotificationDomain.RFQ_RECEIVED,
        action: action => [
          NotificationAction.BULLETIN_QUESTION_POSTED,
          NotificationAction.BULLETIN_ANSWER_TO_QUESTION_POSTED,
          NotificationAction.BULLETIN_CLARIFICATION_TO_QUESTION_POSTED,
        ].includes(action),
        meta: meta => meta.rfqId === rfqId,
        to: to => to.companyId === currentCompanyId,
      }),
    });

    return (
      <Layout.HeaderAndContent
        header={<RecipientPageHeader />}
        content={
          // TODO implement content if we decide to preserve the legacy bulletin notifications
          <div ref={ref}>
            <ErrorPanel error={t('errors.unexpected')} />
          </div>
        }
      />
    );
  },
});

export const legacyRequestReceivedLegacyBulletinsRoute = createRoute({
  getParentRoute: () => legacyRequestReceivedRoute,
  path: 'bulletins',
});

export const legacyRequestReceivedLegacyBulletinsIndexRoute = createRoute({
  getParentRoute: () => legacyRequestReceivedLegacyBulletinsRoute,
  path: '/',
  component: () => (
    <Navigate
      from={legacyRequestReceivedLegacyBulletinsIndexRoute.to}
      to={legacyRequestReceivedLegacyBulletinsListRoute.to}
      params={params => params}
      replace={true}
    />
  ),
});

export const legacyRequestReceivedLegacyBulletinsListRoute = createRoute({
  getParentRoute: () => legacyRequestReceivedLegacyBulletinsRoute,
  path: 'clarifications',
  component: function LegacyRequestReceivedLegacyBulletinsListRoute() {
    const { rfqId, currentCompanyId } = legacyRequestReceivedLegacyBulletinsListRoute.useParams();
    const { structure } = legacyRequestReceivedRoute.useLoaderData();
    const { t } = useTranslation();

    const ref = useNotificationSubject({
      filter: conforms<Partial<Notification>>({
        domain: domain => domain === NotificationDomain.RFQ_RECEIVED,
        action: action => action === NotificationAction.BULLETIN_SIMPLE_CLARIFICATION,
        meta: meta => meta.rfqId === rfqId,
        to: to => to.companyId === currentCompanyId,
      }),
    });

    if (!structure.newFeaturesDisabled) {
      return (
        <Navigate
          from={legacyRequestReceivedLegacyBulletinsListRoute.to}
          to={requestReceivedLegacyBulletinsListRoute.to}
          params={params => params}
          replace={true}
        />
      );
    }

    return (
      <Layout.HeaderAndContent
        header={
          <LegacyRecipientPageHeader
            selectedTabId="bulletin"
            rfqId={rfqId}
            recipientId={currentCompanyId}
          />
        }
        content={
          // TODO implement content if we decide to preserve the legacy bulletin notifications
          <div ref={ref}>
            <ErrorPanel error={t('errors.unexpected')} />
          </div>
        }
      />
    );
  },
});

export const legacyRequestReceivedLegacyBulletinQuestionsRoute = createRoute({
  getParentRoute: () => legacyRequestReceivedLegacyBulletinsRoute,
  path: 'questions',
  component: function LegacyRequestReceivedLegacyBulletinQuestionsRoute() {
    const { rfqId, currentCompanyId } = legacyRequestReceivedLegacyBulletinQuestionsRoute.useParams();
    const { structure } = legacyRequestReceivedRoute.useLoaderData();
    const { t } = useTranslation();

    const ref = useNotificationSubject({
      filter: conforms<Partial<Notification>>({
        domain: domain => domain === NotificationDomain.RFQ_RECEIVED,
        action: action => [
          NotificationAction.BULLETIN_QUESTION_POSTED,
          NotificationAction.BULLETIN_ANSWER_TO_QUESTION_POSTED,
          NotificationAction.BULLETIN_CLARIFICATION_TO_QUESTION_POSTED,
        ].includes(action),
        meta: meta => meta.rfqId === rfqId,
        to: to => to.companyId === currentCompanyId,
      }),
    });

    if (!structure.newFeaturesDisabled) {
      return (
        <Navigate
          from={legacyRequestReceivedLegacyBulletinQuestionsRoute.to}
          to={requestReceivedLegacyBulletinQuestionsRoute.to}
          params={params => params}
          replace={true}
        />
      );
    }

    return (
      <Layout.HeaderAndContent
        header={
          <LegacyRecipientPageHeader
            selectedTabId="bulletin"
            rfqId={rfqId}
            recipientId={currentCompanyId}
          />
        }
        content={
          // TODO implement content if we decide to preserve the legacy bulletin notifications
          <div ref={ref}>
            <ErrorPanel error={t('errors.unexpected')} />
          </div>
        }
      />
    );
  },
});

export const requestReceivedTeamRoute = createRoute({
  getParentRoute: () => requestReceivedRoute,
  path: 'team',
  beforeLoad: () => {
    return {
      getTitle: (t: TFunction) => {
        return t('request.recipientPage.team.title', { ns: 'translation' });
      },
    };
  },
  component: function RequestReceivedTeamRoute() {
    const { rfqId, currentCompanyId } = requestReceivedTeamRoute.useParams();
    const { structure } = requestReceivedRoute.useLoaderData();

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    const ref = useNotificationSubject({
      filter: conforms<Partial<Notification>>({
        domain: domain => domain === NotificationDomain.RFQ_RECEIVED,
        action: action => [NotificationAction.NEW_MEMBER, NotificationAction.RFQ_MEMBER_ROLES_UPDATED].includes(action),
        meta: meta => meta.rfqId === rfqId,
        to: to => to.companyId === currentCompanyId,
      }),
    });

    return (
      <Layout.HeaderAndContent
        header={<RecipientPageHeader />}
        content={
          <div ref={ref}>
            <BidTeam rfqId={rfqId} recipientId={currentCompanyId} />
          </div>
        }
      />
    );
  },
});

export const legacyRequestReceivedTeamRoute = createRoute({
  getParentRoute: () => legacyRequestReceivedRoute,
  path: 'team',
  component: function LegacyRequestReceivedTeamRoute() {
    const { rfqId, currentCompanyId } = legacyRequestReceivedTeamRoute.useParams();
    const { structure } = legacyRequestReceivedRoute.useLoaderData();

    const ref = useNotificationSubject({
      filter: conforms<Partial<Notification>>({
        domain: domain => domain === NotificationDomain.RFQ_RECEIVED,
        action: action => [NotificationAction.NEW_MEMBER, NotificationAction.RFQ_MEMBER_ROLES_UPDATED].includes(action),
        meta: meta => meta.rfqId === rfqId,
        to: to => to.companyId === currentCompanyId,
      }),
    });

    if (!structure.newFeaturesDisabled) {
      return (
        <Navigate
          from={legacyRequestReceivedTeamRoute.to}
          to={requestReceivedTeamRoute.to}
          params={params => params}
          replace={true}
        />
      );
    }

    return (
      <Layout.HeaderAndContent
        header={
          <LegacyRecipientPageHeader
            selectedTabId="team"
            rfqId={rfqId}
            recipientId={currentCompanyId}
          />
        }
        content={
          <div ref={ref}>
            <BidTeam rfqId={rfqId} recipientId={currentCompanyId} />
          </div>
        }
      />
    );
  },
});

export const requestReceivedAuditRoute = createRoute({
  getParentRoute: () => requestReceivedRoute,
  path: 'history',
  beforeLoad: () => {
    return {
      getTitle: (t: TFunction) => {
        return t('request.recipientPage.audit.title', { ns: 'translation' });
      },
    };
  },
  component: function RequestReceivedAuditRoute() {
    const { rfqId, currentCompanyId } = requestReceivedAuditRoute.useParams();
    const { structure } = requestReceivedRoute.useLoaderData();

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    return (
      <Layout.HeaderAndContent
        header={<RecipientPageHeader />}
        content={
          <RfxAuditTrailContainer
            rfqId={rfqId}
            recipientId={currentCompanyId}
          />
        }
      />
    );
  },
});

export const legacyRequestReceivedAuditRoute = createRoute({
  getParentRoute: () => legacyRequestReceivedRoute,
  path: 'history',
  component: function LegacyRequestReceivedAuditRoute() {
    const { rfqId, currentCompanyId } = legacyRequestReceivedAuditRoute.useParams();
    const { structure } = legacyRequestReceivedRoute.useLoaderData();

    if (!structure.newFeaturesDisabled) {
      return (
        <Navigate
          from={legacyRequestReceivedAuditRoute.to}
          to={requestReceivedAuditRoute.to}
          params={params => params}
          replace={true}
        />
      );
    }

    return (
      <Layout.HeaderAndContent
        header={
          <LegacyRecipientPageHeader
            selectedTabId="history"
            rfqId={rfqId}
            recipientId={currentCompanyId}
          />
        }
        content={
          <RfxAuditTrailContainer
            rfqId={rfqId}
            recipientId={currentCompanyId}
          />
        }
      />
    );
  },
});

export const requestReceivedAwardRoute = createRoute({
  getParentRoute: () => requestReceivedRoute,
  path: 'award',
  beforeLoad: () => {
    return {
      getTitle: (t: TFunction) => {
        return t('request.bidAward.bidAward', { ns: 'translation' });
      },
    };
  },
  component: function RequestReceivedAwardRoute() {
    const { rfqId, currentCompanyId } = requestReceivedAwardRoute.useParams();
    const { structure } = requestReceivedRoute.useLoaderData();

    if (structure.newFeaturesDisabled) {
      throw new Error('Route requires lots feature to be enabled');
    }

    const ref = useNotificationSubject({
      filter: matches({
        domain: NotificationDomain.RFQ_RECEIVED,
        action: NotificationAction.RFQ_AWARDED,
        meta: { rfqId },
        to: { companyId: currentCompanyId },
      }),
    });

    if (structure.meta.outcomeDetails?.awardScenario) {
      return (
        <Navigate
          to={requestReceivedAwardSummaryRoute.to}
          params={{ currentCompanyId, rfqId }}
          replace={true}
        />
      );
    }

    if (!structure.meta.awarded) {
      return (
        <Navigate
          to={requestReceivedBidIndexRoute.to}
          params={{ currentCompanyId, rfqId }}
          replace={true}
        />
      );
    }

    return (
      <Layout.HeaderAndContent
        header={<RecipientPageHeader />}
        content={
          <div ref={ref}>
            <BidAward
              rfqId={rfqId}
              isBuyer={false}
              attachments={structure.meta.awarded?.attachments}
              message={structure.meta.awarded?.message}
            />
          </div>
        }
      />
    );
  },
});

export const legacyRequestReceivedAwardRoute = createRoute({
  getParentRoute: () => legacyRequestReceivedRoute,
  path: 'award',
  component: function LegacyRequestReceivedAwardRoute() {
    const { rfqId, currentCompanyId } = legacyRequestReceivedAwardRoute.useParams();
    const { structure } = legacyRequestReceivedRoute.useLoaderData();

    const ref = useNotificationSubject({
      filter: matches({
        domain: NotificationDomain.RFQ_RECEIVED,
        action: NotificationAction.RFQ_AWARDED,
        meta: { rfqId },
        to: { companyId: currentCompanyId },
      }),
    });

    if (!structure.newFeaturesDisabled) {
      return (
        <Navigate
          from={legacyRequestReceivedAwardRoute.to}
          to={requestReceivedAwardRoute.to}
          params={params => params}
          replace={true}
        />
      );
    }

    if (!structure.meta.awarded) {
      return (
        <Navigate
          to={legacyRequestReceivedBidIndexRoute.to}
          params={{ currentCompanyId, rfqId }}
          replace={true}
        />
      );
    }

    return (
      <Layout.HeaderAndContent
        header={
          <LegacyRecipientPageHeader
            selectedTabId="award"
            rfqId={rfqId}
            recipientId={currentCompanyId}
          />
        }
        content={
          <div ref={ref}>
            <BidAward
              rfqId={rfqId}
              isBuyer={false}
              attachments={structure.meta.awarded?.attachments}
              message={structure.meta.awarded?.message}
            />
          </div>
        }
      />
    );
  },
});

// #endregion

// #region Request draft

/* ------ Initial draft, Revisions ------ */

export const requestEditRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: '/request/edit/$rfqId',
  beforeLoad: () => ({ isTemplate: false }),
  loader: options => draftRequestLoader(options),
  component: function RequestEditRoute() {
    const intercom = useIntercom();
    const { structure, isRevising } = requestEditRoute.useLoaderData();
    const { rfqId } = requestEditRoute.useParams();
    const currentCompanyId = useCurrentCompanyId({ required: true });

    useTrackActiveSession(React.useMemo(() => ({
      type: 'entity',
      entityId: rfqId,
      entityType: 'request',
      requestStatus: structure.extendedStatus,
      scope: ['sender', isRevising ? 'revision' : 'draft'],
    }), [rfqId, structure.extendedStatus, isRevising]));

    useEffect(
      () => {
        intercom.update({ verticalPadding: 80 });

        return () => {
          intercom.update({ verticalPadding: 20 });
        };
      },
      [intercom],
    );

    const sender = structure.senders.find(sender => sender._id === currentCompanyId);

    assertDefined(sender, 'sender');

    // If the collaborator hasn't accepted the invite, always render the review page
    // which will prompt them to respond to the invite
    if (sender.inviteStatus === CollaboratorInviteStatus.PENDING) {
      if (isRevising) {
        throw new Error(`A collaborator company invite (${currentCompanyId}) should not be pending after the request has been sent`);
      }

      return (
        <DraftReviewPageContent
          rfqId={rfqId}
          isRevising={false}
          isTemplate={false}
          isTemplatePreview={false}
        />
      );
    } else if (sender.inviteStatus === CollaboratorInviteStatus.REJECTED) {
      return (
        <Navigate
          to={requestsIndexRoute.to}
          params={{ currentCompanyId }}
          search={{ tab: RequestsTab.sent }}
          replace={true}
        />
      );
    }

    return (
      <RfqIdProvider rfqId={rfqId}>
        <RequestEditNavigationProvider isTemplate={false} isRevising={isRevising}>
          <RequestHooksProvider>
            <Outlet key={rfqId} />
          </RequestHooksProvider>
        </RequestEditNavigationProvider>
      </RfqIdProvider>
    );
  },

  errorComponent: withProps(ModelErrorPage, { model: 'request' }),
});

export const requestEditSummaryRoute = createRoute({
  getParentRoute: () => requestEditRoute,
  path: 'summary',
  component: function RequestEditSummaryRoute() {
    const { rfqId } = requestEditSummaryRoute.useParams();
    const { isRevising } = requestEditRoute.useLoaderData();

    return (
      <DraftSummaryPageContent
        rfqId={rfqId}
        isRevising={isRevising}
        isTemplate={false}
      />
    );
  },
});

export const requestEditStagesRoute = createRoute({
  getParentRoute: () => requestEditRoute,
  path: 'stages',
  component: function RequestEditStagesRoute() {
    const { rfqId } = requestEditStagesRoute.useParams();
    const { isRevising } = requestEditRoute.useLoaderData();

    return (
      <DraftStagesPageContent
        rfqId={rfqId}
        isRevising={isRevising}
        isTemplate={false}
      />
    );
  },
});

const requestEditDetailsRoute = createRoute({
  getParentRoute: () => requestEditRoute,
  path: 'details',
});

export const requestEditDetailsIndexRoute = createRoute({
  getParentRoute: () => requestEditDetailsRoute,
  path: '/',
  component: function RequestEditDetailsIndexRoute() {
    const { rfqId } = requestEditDetailsIndexRoute.useParams();
    const { structure, isRevising } = requestEditRoute.useLoaderData();

    const firstVisiblePage = structure.pages.find(page =>
      !page.isHiddenWhileEditing &&
      page.type !== PageType.AUCTION,
    );

    if (firstVisiblePage) {
      return (
        <Navigate
          from={requestEditDetailsIndexRoute.fullPath}
          to={requestEditDetailsPageRoute.to}
          params={prev => ({ ...prev, pageId: firstVisiblePage._id })}
          replace={true}
        />
      );
    }

    return (
      <DraftDetailsPagePageContent
        rfqId={rfqId}
        pageId={undefined}
        isRevising={isRevising}
        isTemplate={false}
      />
    );
  },
});

export const requestEditDetailsPageRoute = createRoute({
  getParentRoute: () => requestEditDetailsRoute,
  path: '$pageId',

  component: function RequestEditDetailsPageRoute() {
    const { rfqId, pageId } = requestEditDetailsPageRoute.useParams();
    const { isRevising } = requestEditRoute.useLoaderData();

    return (
      <DraftDetailsPagePageContent
        rfqId={rfqId}
        pageId={pageId}
        isRevising={isRevising}
        isTemplate={false}
      />
    );
  },
});

export const requestEditAuctionRoute = createRoute({
  getParentRoute: () => requestEditRoute,
  path: 'auction',

  validateSearch: (search: Record<string, unknown>): { tab: AuctionTabId } => ({
    tab: search.tab as AuctionTabId,
  }),

  component: function RequestEditAuctionRoute() {
    const { rfqId } = requestEditAuctionRoute.useParams();
    const { isRevising } = requestEditRoute.useLoaderData();

    return (
      <DraftAuctionConfigPageContent
        rfqId={rfqId}
        isRevising={isRevising}
        isTemplate={false}
      />
    );
  },
});

const requestEditEvaluationRoute = createRoute({
  getParentRoute: () => requestEditRoute,
  path: 'evaluation',
});

export const requestEditEvaluationIndexRoute = createRoute({
  getParentRoute: () => requestEditEvaluationRoute,
  path: '/',
  component: () => {
    const { rfqId } = requestEditEvaluationIndexRoute.useParams();
    const { structure, isRevising } = requestEditRoute.useLoaderData();

    const evaluationPageIds = getPagesInDisplayOrder(structure.pages)
      .filter(isLinkedEvaluationPage)
      .map(page => page._id);

    const availablePageIds = [
      ...(isEmpty(evaluationPageIds) ? ['no-pages'] : evaluationPageIds),
      'settings',
    ];

    if (structure.settings.isEvaluationEnabled) {
      return (
        <Navigate
          from={requestEditEvaluationIndexRoute.fullPath}
          to={requestEditEvaluationPageRoute.to}
          params={prev => ({ ...prev, pageId: availablePageIds[0] })}
          replace={true}
        />
      );
    }

    return (
      <DraftEvaluationPagePageContent
        rfqId={rfqId}
        pageId={undefined}
        isRevising={isRevising}
        isTemplate={false}
      />
    );
  },
});

export const requestEditEvaluationPageRoute = createRoute({
  getParentRoute: () => requestEditEvaluationRoute,
  path: '$pageId',

  component: function RequestEditEvaluationPageRoute() {
    const { rfqId, pageId } = requestEditEvaluationPageRoute.useParams();
    const { isRevising } = requestEditRoute.useLoaderData();

    return (
      <DraftEvaluationPagePageContent
        rfqId={rfqId}
        pageId={pageId}
        isRevising={isRevising}
        isTemplate={false}
      />
    );
  },
});

export const requestEditSpendRoute = createRoute({
  getParentRoute: () => requestEditRoute,
  path: 'spend',
  component: function DraftSpendPage() {
    const { rfqId, currentCompanyId } = requestEditSpendRoute.useParams();
    const { isRevising } = requestEditRoute.useLoaderData();
    const navigation = useRequestEditNavigation();

    return (
      <DraftSpendPageContent
        rfqId={rfqId}
        isRevising={isRevising}
        isTemplate={false}
        navigateToTeam={() => navigation.navigateToTeam(currentCompanyId)}
      />
    );
  },
});

export const requestEditSuppliersRoute = createRoute({
  getParentRoute: () => requestEditRoute,
  path: 'suppliers',
  component: function RequestEditSuppliersRoute() {
    const { rfqId } = requestEditSuppliersRoute.useParams();
    const { isRevising } = requestEditRoute.useLoaderData();

    return (
      <DraftSuppliersPageContent
        rfqId={rfqId}
        isRevising={isRevising}
        isTemplate={false}
      />
    );
  },
});

export const requestEditTeamRoute = createRoute({
  getParentRoute: () => requestEditRoute,
  path: 'team',

  validateSearch: (search: Record<string, unknown>): { companyId: string } => ({
    companyId: search.companyId as string,
  }),

  component: function RequestEditTeamRoute() {
    const { rfqId } = requestEditTeamRoute.useParams();
    const { isRevising } = requestEditRoute.useLoaderData();

    return (
      <DraftTeamPageContent
        rfqId={rfqId}
        isRevising={isRevising}
        isTemplate={false}
      />
    );
  },
});

export const requestEditApprovalsRoute = createRoute({
  getParentRoute: () => requestEditRoute,
  path: 'approvals',
  validateSearch: (search: Record<string, unknown>): {
    openApprovalModal?: OpenApprovalParam;
    approvalRequestId?: string;
  } => ({
    openApprovalModal: search.openApprovalModal as OpenApprovalParam,
    approvalRequestId: search.approvalRequestId as string,
  }),
  component: function RequestEditApprovalsRoute() {
    const { rfqId } = requestEditApprovalsRoute.useParams();

    return (
      <DraftApprovalsPageContent rfqId={rfqId} />
    );
  },
});

export const requestEditReviewRoute = createRoute({
  getParentRoute: () => requestEditRoute,
  path: 'review',
  component: function RequestEditReviewRoute() {
    const { rfqId } = requestEditReviewRoute.useParams();
    const { isRevising } = requestEditRoute.useLoaderData();

    return (
      <DraftReviewPageContent
        rfqId={rfqId}
        isRevising={isRevising}
        isTemplate={false}
      />
    );
  },
});

export const requestEditCommentsRoute = createRoute({
  getParentRoute: () => requestEditRoute,
  path: 'comments',
  component: function RequestEditCommentsRoute() {
    const { rfqId } = requestEditCommentsRoute.useParams();

    return (
      <DraftCommentsPageContent rfqId={rfqId} />
    );
  },
});

export const requestEditSetupMultiStageResponsesFlowRoute = createRoute({
  getParentRoute: () => requestEditRoute,
  path: 'setup-multi-stage-responses-flow',
  component: function RequestSetupMultiStageLineItemsFlowRoute() {
    const { rfqId } = requestEditRoute.useParams();
    const { structure } = requestEditRoute.useLoaderData();
    const { teamById } = structure;

    const overrides = rfx.useUserOverrides();
    const currentUser = useCurrentUser();
    const currentCompanyId = useCurrentCompanyId({ required: true });
    const team = teamById[currentCompanyId];
    const rfxPermissions = rfx.getRfxPermissions({ currentUser, overrides, currentCompanyId, team });

    const { hasMultiStageResponses, canEnableMultiStageResponses } = rfx.checkMultiStageBannerEligibility({ structure, rfxPermissions });

    return !hasMultiStageResponses && canEnableMultiStageResponses ? (
      <SetupMultiStageResponsesFlowPage isRevising={true} />
    ) : (
      <Navigate
        to={requestEditReviewRoute.to}
        params={{ rfqId, currentCompanyId }}
        replace={true}
      />
    );
  },
});

// #endregion

// #region Templates

/* ------ Templates -------- */

export const templateEditRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: '/template/edit/$rfqId',
  beforeLoad: () => ({ isTemplate: true }),
  loader: options => draftRequestLoader(options),
  component: function TemplateRootPage() {
    const intercom = useIntercom();
    const { rfqId } = templateEditRoute.useParams();

    useTrackActiveSession(React.useMemo(() => ({
      type: 'entity',
      entityId: rfqId,
      entityType: 'requestTemplate',
    }), [rfqId]));

    useEffect(
      () => {
        intercom.update({ verticalPadding: 80 });

        return () => {
          intercom.update({ verticalPadding: 20 });
        };
      },
      [intercom],
    );

    return (
      <RfqIdProvider rfqId={rfqId}>
        <RequestEditNavigationProvider isTemplate={true} isRevising={false}>
          <RequestHooksProvider>
            <Outlet />
          </RequestHooksProvider>
        </RequestEditNavigationProvider>
      </RfqIdProvider>
    );
  },

  errorComponent: withProps(ModelErrorPage, { model: 'template' }),
});

export const templateSummaryRoute = createRoute({
  getParentRoute: () => templateEditRoute,
  path: 'summary',
  component: function DraftSummaryPage() {
    const { rfqId } = templateSummaryRoute.useParams();

    return (
      <DraftSummaryPageContent
        rfqId={rfqId}
        isRevising={false}
        isTemplate={true}
      />
    );
  },
});

export const templateStagesRoute = createRoute({
  getParentRoute: () => templateEditRoute,
  path: 'stages',
  component: function DraftStagesPage() {
    const { rfqId } = templateStagesRoute.useParams();

    return (
      <DraftStagesPageContent
        rfqId={rfqId}
        isRevising={false}
        isTemplate={true}
      />
    );
  },
});

export const templateTeamRoute = createRoute({
  getParentRoute: () => templateEditRoute,
  path: 'team',

  validateSearch: (search: Record<string, unknown>): { companyId: string } => ({
    companyId: search.companyId as string,
  }),

  component: function DraftTeamPage() {
    const { rfqId } = templateTeamRoute.useParams();

    return (
      <DraftTeamPageContent
        rfqId={rfqId}
        isRevising={false}
        isTemplate={true}
      />
    );
  },
});

export const templateSuppliersRoute = createRoute({
  getParentRoute: () => templateEditRoute,
  path: 'suppliers',

  validateSearch: (search: Record<string, unknown>): { companyId: string } => ({
    companyId: search.companyId as string,
  }),

  loader: options => draftRequestLoader(options),
  component: function RequestEditSuppliersRoute() {
    const { rfqId } = templateSuppliersRoute.useParams();

    return (
      <DraftSuppliersPageContent
        rfqId={rfqId}
        isRevising={false}
        isTemplate={true}
      />
    );
  },
});

export const templateSpendRoute = createRoute({
  getParentRoute: () => templateEditRoute,
  path: 'spend',
  component: function DraftSpendPage() {
    const { rfqId } = templateSpendRoute.useParams();
    const { isRevising } = templateEditRoute.useLoaderData();

    return (
      <DraftSpendPageContent
        rfqId={rfqId}
        isRevising={isRevising}
        isTemplate={true}
        navigateToTeam={noop}
      />
    );
  },
});

const templateDetailsRoute = createRoute({
  getParentRoute: () => templateEditRoute,
  path: 'details',
});

export const templateDetailsIndexRoute = createRoute({
  getParentRoute: () => templateDetailsRoute,
  path: '/',
  component: function DraftDetailsIndexPage() {
    const { rfqId } = templateDetailsIndexRoute.useParams();
    const { structure } = templateEditRoute.useLoaderData();

    const firstVisiblePage = structure.pages.find(page =>
      !page.isHiddenWhileEditing &&
      page.type !== PageType.AUCTION,
    );

    if (firstVisiblePage) {
      return (
        <Navigate
          from={templateDetailsIndexRoute.fullPath}
          to={templateDetailsPageRoute.to}
          params={prev => ({ ...prev, pageId: firstVisiblePage._id })}
          replace={true}
        />
      );
    }

    return (
      <DraftDetailsPagePageContent
        rfqId={rfqId}
        pageId={undefined}
        isRevising={false}
        isTemplate={true}
      />
    );
  },
});

export const templateDetailsPageRoute = createRoute({
  getParentRoute: () => templateDetailsRoute,
  path: '$pageId',

  component: function TemplateDetailsPage() {
    const { rfqId, pageId } = templateDetailsPageRoute.useParams();

    return (
      <DraftDetailsPagePageContent
        rfqId={rfqId}
        pageId={pageId}
        isRevising={false}
        isTemplate={true}
      />
    );
  },
});

export const templateAuctionRoute = createRoute({
  getParentRoute: () => templateEditRoute,
  path: 'auction',

  validateSearch: (search: Record<string, unknown>): { tab: AuctionTabId } => ({
    tab: search.tab as AuctionTabId,
  }),

  component: function TemplateAuctionPage() {
    const { rfqId } = templateAuctionRoute.useParams();

    return (
      <DraftAuctionConfigPageContent
        rfqId={rfqId}
        isRevising={false}
        isTemplate={true}
      />
    );
  },
});

const templateEvaluationRoute = createRoute({
  getParentRoute: () => templateEditRoute,
  path: 'evaluation',
});

export const templateEvaluationIndexRoute = createRoute({
  getParentRoute: () => templateEvaluationRoute,
  path: '/',
  component: () => {
    const { rfqId } = templateEvaluationIndexRoute.useParams();
    const { structure } = templateEditRoute.useLoaderData();

    const evaluationPageIds = getPagesInDisplayOrder(structure.pages)
      .filter(isLinkedEvaluationPage)
      .map(page => page._id);

    const availablePageIds = [
      ...(isEmpty(evaluationPageIds) ? ['no-pages'] : evaluationPageIds),
      'settings',
    ];

    if (structure.settings.isEvaluationEnabled) {
      return (
        <Navigate
          from={templateEvaluationIndexRoute.fullPath}
          to={templateEvaluationPageRoute.to}
          params={prev => ({ ...prev, pageId: availablePageIds[0] })}
          replace={true}
        />
      );
    }

    return (
      <DraftEvaluationPagePageContent
        rfqId={rfqId}
        pageId={undefined}
        isRevising={false}
        isTemplate={true}
      />
    );
  },
});

export const templateEvaluationPageRoute = createRoute({
  getParentRoute: () => templateEvaluationRoute,
  path: '$pageId',

  component: function DraftEvaluationPage() {
    const { rfqId, pageId } = templateEvaluationPageRoute.useParams();

    return (
      <DraftEvaluationPagePageContent
        rfqId={rfqId}
        pageId={pageId}
        isRevising={false}
        isTemplate={true}
      />
    );
  },
});

export const templateReviewRoute = createRoute({
  getParentRoute: () => templateEditRoute,
  path: 'review',
  component: function TemplateReviewPage() {
    const { rfqId } = templateReviewRoute.useParams();

    return (
      <DraftReviewPageContent
        rfqId={rfqId}
        isRevising={false}
        isTemplate={true}
        isTemplatePreview={false}
      />
    );
  },
});

export const templatePreviewRoute = createRoute({
  getParentRoute: () => templateEditRoute,
  path: 'preview',
  component: function TemplatePreviewPage() {
    const { rfqId } = templatePreviewRoute.useParams();

    return (
      <DraftReviewPageContent
        rfqId={rfqId}
        isRevising={false}
        isTemplate={true}
        isTemplatePreview={true}
      />
    );
  },
});

export const templateSetupMultiStageResponsesFlowRoute = createRoute({
  getParentRoute: () => templateEditRoute,
  path: 'setup-multi-stage-responses-flow',
  component: function TemplateSetupMultiStageLineItemsFlowRoute() {
    const { rfqId } = templateEditRoute.useParams();
    const { structure } = templateEditRoute.useLoaderData();
    const { teamById } = structure;

    const overrides = rfx.useUserOverrides();
    const currentUser = useCurrentUser();
    const currentCompanyId = useCurrentCompanyId({ required: true });
    const team = teamById[currentCompanyId];
    const rfxPermissions = rfx.getRfxPermissions({ currentUser, overrides, currentCompanyId, team });

    const { hasMultiStageResponses, canEnableMultiStageResponses } = rfx.checkMultiStageBannerEligibility({ structure, rfxPermissions });

    return !hasMultiStageResponses && canEnableMultiStageResponses ? (
      <SetupMultiStageResponsesFlowPage isTemplate={true} />
    ) : (
      <Navigate
        to={requestEditReviewRoute.to}
        params={{ rfqId, currentCompanyId }}
        replace={true}
      />
    );
  },
});

// #endregion

// #region Misc routes

export const useReportingNavigation = () => {
  const navigate = useNavigate();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  return useMemo(
    () => ({
      navigateToRequestReportings: () => {
        navigate({
          to: reportingRequestsRoute.to,
          params: { currentCompanyId },
        });
      },
      navigateToContractsReporting: () => {
        navigate({
          to: reportingContractsRoute.to,
          params: { currentCompanyId },
        });
      },
    }),
    [currentCompanyId, navigate],
  );
};

export const reportingRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: 'reporting',

  component: function ReportingRoute() {
    const matches = useChildMatches();
    const selectedTabId = matches.some(match => match.routeId === reportingContractsRoute.id)
      ? 'contracts'
      : 'requests';

    return (
      <ReportingLayout selectedTabId={selectedTabId}>
        <Outlet />
      </ReportingLayout>
    );
  },
});

export const reportingIndexRoute = createRoute({
  getParentRoute: () => reportingRoute,
  path: '/',
  component: () => (
    <Navigate
      from={reportingIndexRoute.to}
      to={reportingRequestsRoute.to}
      replace={true}
    />
  ),
});

export const reportingRequestsRoute = createRoute({
  getParentRoute: () => reportingRoute,
  path: 'requests',

  validateSearch: (search: Record<string, unknown>): { tab?: string } => ({
    tab: search.tab ? String(search.tab) : undefined,
  }),

  component: () => {
    const search = reportingRequestsRoute.useSearch();

    return <RequestsReporting selectedTabId={search.tab} />;
  },
});

export const reportingContractsRoute = createRoute({
  getParentRoute: () => reportingRoute,
  path: 'contracts',
  component: function ReportingContractsRoute() {
    const { currentCompanyId } = reportingContractsRoute.useParams();
    const navigate = useNavigate();

    return (
      <ContractsReporting
        navigateToTeam={() => {
          navigate({
            from: reportingContractsRoute.to,
            to: teamManagementRoute.to,
            params: { currentCompanyId },
          });
        }}
        navigateToContract={contract => {
          navigate({
            to: contractLiveSummaryRoute.to,
            params: { currentCompanyId, contractId: contract._id },
          });
        }}
      />
    );
  },
});

export const useDriveNavigation = () => {
  const navigate = useNavigate();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  return useMemo(
    () => ({
      navigateToDocuments: () => {
        navigate({
          to: driveDocumentsRoute.to,
          params: { currentCompanyId },
        });
      },
      navigateToHistory: () => {
        navigate({
          to: driveHistoryRoute.to,
          params: { currentCompanyId },
        });
      },
    }),
    [currentCompanyId, navigate],
  );
};

export const driveRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: 'documents',
  component: function DriveRoute() {
    const matches = useChildMatches();
    const selectedTabId = matches.some(match => match.routeId === driveHistoryRoute.id)
      ? 'history'
      : 'documents';

    return (
      <DriveLayout selectedTabId={selectedTabId}>
        <Outlet />
      </DriveLayout>
    );
  },
});

export const driveIndexRoute = createRoute({
  getParentRoute: () => driveRoute,
  path: '/',
  component: () => (
    <Navigate
      from={driveIndexRoute.to}
      to={driveDocumentsRoute.to}
      replace={true}
    />
  ),
});

export const driveDocumentsRoute = createRoute({
  getParentRoute: () => driveRoute,
  path: 'drive',
  component: () => <DriveDocuments />,
});

export const driveHistoryRoute = createRoute({
  getParentRoute: () => driveRoute,
  path: 'history',
  component: () => <DriveHistory />,
});

export const teamManagementRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: 'team-management',

  validateSearch: (search: Record<string, unknown>): { tab?: TabId } => ({
    tab: search.tab as TabId,
  }),

  component: function TeamManagementPage() {
    const { t } = useTranslation();

    return (
      <Layout.BasicHeader icon="users" heading={t('pageHeader.teamManagement')}>
        <TeamManagement />
      </Layout.BasicHeader>
    );
  },
});

export const teamManagementRequestsRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: 'team-management-requests',
  component: () => (
    <Navigate
      from={teamManagementRequestsRoute.to}
      to={teamManagementRoute.to}
      search={{ tab: 'requests-to-join' }}
    />
  ),
});

type CompanySearchParams = {
  text: string;
  pageIndex: number;
  pageSize: number;
  supplierListIds: string[];
  deliveryCountries: string[];
  products: string[];
  productsOperator: Operator;
  // deprecated product tags
  productTags: string[];
};

export const useNetworkNavigation = () => {
  const navigate = useNavigate();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  return useMemo(
    () => ({
      navigateToInternalNetwork: () => {
        navigate({
          to: internalNetworkRoute.to,
          params: { currentCompanyId },
        });
      },
      navigateToExternalNetwork: () => {
        navigate({
          to: externalNetworkRoute.to,
          params: { currentCompanyId },
        });
      },
    }),
    [currentCompanyId, navigate],
  );
};

export const networkRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: 'browse',
  component: function NetworkRoute() {
    const matches = useChildMatches();
    const selectedTabId = matches.some(match => match.routeId === internalNetworkRoute.id)
      ? 'internal'
      : matches.some(match => match.routeId === externalNetworkRoute.id)
        ? 'external'
        : undefined;

    return (
      <SupplierListNavigationProvider>
        <NetworkLayout selectedTabId={selectedTabId}>
          <Outlet />
        </NetworkLayout>
      </SupplierListNavigationProvider>
    );
  },
});

export const networkIndexRoute = createRoute({
  getParentRoute: () => networkRoute,
  path: '/',
  component: function NetworkIndexRoute() {
    const { belongsToPayingCompany } = useUserFlags();

    return (
      <Navigate
        from={networkIndexRoute.to}
        to={belongsToPayingCompany ? internalNetworkRoute.to : externalNetworkRoute.to}
        replace={true}
      />
    );
  },
});

const validateNetworkSearch = (search: Record<string, unknown>): Partial<CompanySearchParams> => ({
  text: search.text as string ?? '',
  pageIndex: Number(search.pageIndex as number ?? 0),
  pageSize: Number(search.pageSize as number ?? 20),
  supplierListIds: getArrayQueryParam<string>(search.supplierListIds),
  deliveryCountries: getArrayQueryParam<string>(search.deliveryCountries),
  products: getArrayQueryParam<string>(search.products),
  productsOperator: (search.productsOperator ?? Operator.AND) as Operator,
  // deprecated product tags
  productTags: getArrayQueryParam<string>(search.productTags),
});

export const internalNetworkRoute = createRoute({
  getParentRoute: () => networkRoute,
  path: 'internal',
  validateSearch: validateNetworkSearch,
  component: function InternalNetworkRoute() {
    const { belongsToPayingCompany } = useUserFlags();
    const { currentCompanyId } = networkRoute.useParams();
    const navigate = useNavigate();

    if (!belongsToPayingCompany) {
      return (
        <Navigate
          from={internalNetworkRoute.to}
          to={externalNetworkRoute.to}
          replace={true}
        />
      );
    }

    return (
      <SearchCompanies
        isInternalNetwork
        navigateToCompany={companyId => navigate({
          to: companyProfileRoute.to,
          params: { currentCompanyId, targetCompanyId: companyId },
        })}
        navigateToSentInvites={() => navigate({
          to: sentInvitesRoute.to,
          params: { currentCompanyId },
        })}
      />
    );
  },
});

export const externalNetworkRoute = createRoute({
  getParentRoute: () => networkRoute,
  path: 'external',
  validateSearch: validateNetworkSearch,
  component: function ExternalNetworkRoute() {
    const { currentCompanyId } = networkRoute.useParams();
    const navigate = useNavigate();

    return (
      <SearchCompanies
        navigateToCompany={companyId => navigate({
          to: companyProfileRoute.to,
          params: { currentCompanyId, targetCompanyId: companyId },
        })}
        navigateToSentInvites={() => navigate({
          to: sentInvitesRoute.to,
          params: { currentCompanyId },
        })}
      />
    );
  },
});

export const networkRedirectRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: 'marketplace',
  component: () => <Navigate from={networkRedirectRoute.to} to={networkIndexRoute.to} />,
});

export const sentInvitesRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: 'invitations',
  component: () => <SentInvitesPage />,
});

export const notificationsRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: 'notifications',
  component: function NotificationsRoute() {
    const { t } = useTranslation();

    return (
      <Layout.BasicHeader heading={t('pageHeader.notifications')} icon="bell-o">
        <NotificationsTable />
      </Layout.BasicHeader>
    );
  },
});

export const supplierListsRoute = createRoute({
  getParentRoute: () => companyRoute,
  validateSearch: (search: Record<string, unknown>): { from?: 'externalNetwork' | 'internalNetwork' } => ({
    from: search.from as 'externalNetwork' | 'internalNetwork' | undefined,
  }),
  path: 'supplier-lists',

  component: function SupplierListsPage() {
    return (
      <SupplierListNavigationProvider>
        <SupplierListsPageContent />
      </SupplierListNavigationProvider>
    );
  },
});

export const supplierListRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: 'supplier-list/$supplierListId',
  loader: options => supplierListLoader(options),

  component: function SupplierListRootPage() {
    const { supplierList } = supplierListRoute.useLoaderData();

    return (
      <SupplierListIdProvider supplierListId={supplierList._id}>
        <SupplierListProvider supplierList={supplierList}>
          <SupplierListNavigationProvider>
            <Outlet key={supplierList._id} />
          </SupplierListNavigationProvider>
        </SupplierListProvider>
      </SupplierListIdProvider>
    );
  },

  errorComponent: withProps(ModelErrorPage, { model: 'supplierList' }),
});

export const supplierListIndexRoute = createRoute({
  getParentRoute: () => supplierListRoute,
  path: '/',
  // @ts-expect-error ts(2741) FIXME: Property 'params' is missing in type '{ to: "/$currentCompanyId/supplier-list/$supplierListId/suppliers"; replace: true; }' but required in type 'MakeRequiredPathParams<Router<RootRoute<undefined, RouterContext, AnyContext, AnyContext, {}, undefined, readonly [Route<RootRoute<undefined, RouterContext, AnyContext, AnyContext, {}, undefined, unknown>, ... 11 more ..., unknown>, ... 4 more ..., Route<...>]>, TrailingSlashOption, Record<...>, Record<...>>, string...'.
  component: () => <Navigate to={supplierListSuppliersRoute.to} replace={true} />,
});

export const supplierListSuppliersRoute = createRoute({
  getParentRoute: () => supplierListRoute,
  path: 'suppliers',
  component: function SupplierListSuppliersPage() {
    return (
      <SupplierListSuppliersPageContent />
    );
  },
});

export const supplierListSettingsRoute = createRoute({
  getParentRoute: () => supplierListRoute,
  path: 'settings',
  component: function SupplierListSettingsPage() {
    return (
      <SupplierListSettingsPageContent />
    );
  },
});

export const supplierListEditRoute = createRoute({
  getParentRoute: () => supplierListRoute,
  path: 'edit',
  component: function SupplierListEditPage() {
    const { currentCompanyId, supplierListId } = supplierListEditRoute.useParams();
    const isAuthorizedStakeholder = useIsAuthorizedStakeholder();

    if (isAuthorizedStakeholder) {
      return (
        <Navigate
          to={supplierListSettingsRoute.to}
          params={{ currentCompanyId, supplierListId }}
          replace={true}
        />
      );
    }

    return (
      <SupplierListNavigationProvider>
        <SupplierListEditPageContent />
      </SupplierListNavigationProvider>
    );
  },
});

export const createSupplierListRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: 'create-supplier-list',
  component: function CreateSupplierListPage() {
    const isAuthorizedStakeholder = useIsAuthorizedStakeholder();
    const currentCompanyId = useCurrentCompanyId({ required: true });

    if (isAuthorizedStakeholder) {
      return (
        <Navigate
          to={supplierListsRoute.to}
          params={{ currentCompanyId }}
          replace={true}
        />
      );
    }

    return (
      <SupplierListNavigationProvider>
        <CreateSupplierListPageContent />
      </SupplierListNavigationProvider>
    );
  },
});

/**
 * This route handles rendering of two things:
 *   - company profile
 *   - legacy pre-qualification questionnaire
 */
export const companyProfileRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: 'company-profile/$targetCompanyId',

  validateSearch: (search: Record<string, unknown>): { show?: 'pre-qualification' | 'profile', tab?: 'general' | 'activity' } => ({
    show: search.show as 'pre-qualification' | 'profile',
    tab: search.tab as 'general' | 'activity',
  }),

  loader: ({
    context: { queryClient, api },
    params: { targetCompanyId },
  }) => {
    return ensureQueryData(queryClient, {
      queryKey: ['publicCompany', { companyId: targetCompanyId }],
      queryFn: wrap(api.getPublicCompany),
    });
  },

  component: function CompanyProfilePage() {
    const { t } = useTranslation();
    const { currentCompanyId, targetCompanyId } = companyProfileRoute.useParams();
    const search = companyProfileRoute.useSearch();
    const navigate = useNavigate();
    const { roles } = useCurrentUser();

    const hasAppAdminRole = roles?.[APP_ADMIN_COMPANY_ID]?.admin;
    const hasCompanyEditorRole = roles?.[targetCompanyId]?.editor;

    const canEdit = (
      hasAppAdminRole ||
      (currentCompanyId === targetCompanyId && hasCompanyEditorRole)
    );

    return (
      <Layout.BasicHeader
        heading={t('pageHeader.profile')}
        icon="building-o"
        contentPaddingTop={search.show === 'pre-qualification' ? '30px' : '0px'}
      >
        <CompanyProfileContainer
          companyId={targetCompanyId}
          currentCompanyId={currentCompanyId}
          canEdit={canEdit}
          navigateToAuditTrail={() => {
            navigate({
              to: legacyPreQualificationAuditRoute.to,
              params: { currentCompanyId },
            });
          }}
          navigateToDocumentLibrary={() => {
            navigate({
              to: driveDocumentsRoute.to,
              params: { currentCompanyId },
            });
          }}
          bannerStyle={{ width: 'auto !important' }}
          // @ts-expect-error ts(2322) FIXME: Type '"pre-qualification" | "profile" | undefined' is not assignable to type '"pre-qualification" | "profile"'.
          show={search.show}
          tab={search.tab || 'general'}
        />
      </Layout.BasicHeader>
    );
  },
});

export const legacyPreQualificationAuditRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: 'audit',
  component: function LegacyPreQualificationAuditRoute() {
    const { t } = useTranslation();
    const { currentCompanyId } = legacyPreQualificationAuditRoute.useParams();
    const navigate = useNavigate();

    return (
      <Layout.BasicHeader heading={t('pageHeader.profile')} icon="building-o">
        <AuditTrail
          companyId={currentCompanyId}
          navigateToPreQualification={() =>
            navigate({
              to: companyProfileRoute.to,
              params: { currentCompanyId, targetCompanyId: currentCompanyId },
              search: { show: 'pre-qualification' },
            })
          }
        />
      </Layout.BasicHeader>
    );
  },
});

export const modelDeletedRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: 'deleted',

  validateSearch: (search: Record<string, unknown>): { model: 'request' | 'template' } => ({
    model: search.model as 'request' | 'template',
  }),

  component: function ModelDeletedRoute() {
    const { model } = modelDeletedRoute.useSearch();
    const { t } = useTranslation('general');

    return (
      <Layout.BasicHeader heading={t('error')} icon="xmark">
        <ModelDeleted model={model} />
      </Layout.BasicHeader>
    );
  },
});

export const supplierDiscoveryRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: 'discovery',
  validateSearch: (search: Record<string, unknown>): { queryId?: string } => ({
    queryId: search.queryId as string,
  }),
  component: function DiscoveryPage() {
    const { t } = useTranslation();
    const { queryId } = supplierDiscoveryRoute.useSearch();
    const navigate = useNavigate({ from: supplierDiscoveryRoute.id });

    return (
      <Layout.BasicHeader heading={t('pageHeader.supplierDiscovery')} icon="crosshairs">
        <DiscoverySearch
          queryId={queryId}
          navigateToDiscoveryQuery={queryId => navigate({ search: { queryId } })}
        />
      </Layout.BasicHeader>
    );
  },
});

export const companySettingsRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: 'company-settings',
  validateSearch: (search: Record<string, unknown>): { tab?: CompanySettingsTab } => ({
    tab: search.tab as CompanySettingsTab,
  }),
  component: function CompanySettingsPage() {
    const { t } = useTranslation();

    return (
      <Layout.BasicHeader heading={t('pageHeader.companySettings')} icon="building-o">
        <CompanySettings />
      </Layout.BasicHeader>
    );
  },
});

export const usePreQualNavigation = () => {
  const navigate = useNavigate();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const { belongsToPayingCompany } = useUserFlags();

  return useMemo(
    () => ({
      navigateToPreQualificationQuestionnaires: (tab, replace = false) => {
        const lastActiveQuestionnairesTab = localStorage.getItem(`${currentCompanyId}.${currentUser._id}.preQualificationQuestionnairesPage.lastActiveTab`);
        const defaultQuestionnairesTab = belongsToPayingCompany
          ? PreQualTab.ACTIVE_QUESTIONNAIRES
          : PreQualTab.RECEIVED_QUESTIONNAIRES;

        navigate({
          to: preQualificationQuestionnairesRoute.to,
          params: { currentCompanyId },
          search: { tab: tab || lastActiveQuestionnairesTab || defaultQuestionnairesTab },
          replace,
        });
      },
      navigateToPreQualificationQuestions: (tab, replace = false) => {
        const lastActiveQuestionsTab = identity(
          JSON.parse(
            // @ts-expect-error ts(2345) FIXME: Argument of type 'string | null' is not assignable to parameter of type 'string'.
            localStorage.getItem(`${currentCompanyId}.${currentUser._id}.preQualificationQuestionsPage.lastActiveTab`),
          ),
        );
        const defaultQuestionsTab = PreQualTab.ALL_CATEGORIES;

        navigate({
          to: preQualificationQuestionsRoute.to,
          params: { currentCompanyId },
          search: { tab: tab || lastActiveQuestionsTab || defaultQuestionsTab },
          replace,
        });
      },
      navigateToPreQualificationArchive: (tab, replace = false) => {
        const lastActiveArchiveTab = localStorage.getItem(`${currentCompanyId}.${currentUser._id}.preQualificationArchivePage.lastActiveTab`);
        const defaultArchiveTab = PreQualTab.ARCHIVE_QUESTIONS;

        navigate({
          to: preQualificationArchiveRoute.to,
          params: { currentCompanyId },
          search: { tab: tab || lastActiveArchiveTab || defaultArchiveTab },
          replace,
        });
      },
      navigateToPreQualificationSuppliers: (replace?: boolean) => {
        navigate({
          to: preQualificationSuppliersRoute.to,
          params: { currentCompanyId },
          replace,
        });
      },
      navigateToSendQuestionnaire: (query?: Record<string, string | string[]>) => {
        navigate({
          to: sendQuestionnaireRoute.to,
          params: { currentCompanyId },
          search: query,
        });
      },
    }),
    [currentCompanyId, currentUser, belongsToPayingCompany, navigate],
  );
};

export const preQualificationRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: 'pre-qualification',

  component: function PreQualificationRoute() {
    const { belongsToPayingCompany } = useUserFlags();
    const matches = useChildMatches();
    const selectedTabId = matches.some(match => match.routeId === preQualificationQuestionsRoute.id)
      ? 'questions'
      : matches.some(match => match.routeId === preQualificationQuestionnairesRoute.id)
        ? 'questionnaires'
        : matches.some(match => match.routeId === preQualificationArchiveRoute.id)
          ? 'archive'
          : 'suppliers';

    if (selectedTabId !== 'questionnaires' && !belongsToPayingCompany) {
      return (
        <Navigate
          from={preQualificationRoute.to}
          to={preQualificationQuestionnairesRoute.to}
          replace={true}
        />
      );
    }

    return (
      <QuestionnaireNavigationProvider>
        <ActiveQuestionnaireTemplateNavigationProvider>
          <DraftQuestionnaireTemplateNavigationProvider>
            <PreQualificationLayout selectedTabId={selectedTabId}>
              <Outlet />
            </PreQualificationLayout>
          </DraftQuestionnaireTemplateNavigationProvider>
        </ActiveQuestionnaireTemplateNavigationProvider>
      </QuestionnaireNavigationProvider>
    );
  },
});

export const preQualificationIndexRoute = createRoute({
  getParentRoute: () => preQualificationRoute,
  path: '/',
  component: function PreQualificationIndexRoute() {
    const { belongsToPayingCompany } = useUserFlags();

    const redirectUrl = belongsToPayingCompany
      ? preQualificationSuppliersRoute.to
      : preQualificationQuestionnairesRoute.to;

    return (
      <Navigate
        from={preQualificationIndexRoute.to}
        to={redirectUrl}
        replace={true}
      />
    );
  },
});

export const preQualificationQuestionnairesRoute = createRoute({
  getParentRoute: () => preQualificationRoute,
  path: 'questionnaires',

  validateSearch: (search: Record<string, unknown>): { tab?: string } => ({
    tab: (QUESTIONNAIRE_TABS.includes(search.tab as PreQualTab) ? search.tab : '') as string,
  }),

  component: PreQualificationQuestionnairesPage,
});

export const preQualificationQuestionsRoute = createRoute({
  getParentRoute: () => preQualificationRoute,
  path: 'questions',

  validateSearch: (search: Record<string, unknown>): { tab: string } => ({
    tab: search.tab as string,
  }),

  component: PreQualificationQuestionsPage,
});

export const preQualificationSuppliersRoute = createRoute({
  getParentRoute: () => preQualificationRoute,
  path: 'suppliers',

  validateSearch: (search: Record<string, unknown>): { text?: string, pageIndex?: number, pageSize?: number } => ({
    text: search.text as string ?? '',
    pageIndex: Number(search.pageIndex as number ?? 0),
    pageSize: Number(search.pageSize as number ?? 10),
  }),

  component: PreQualificationSuppliersPage,
});

export const preQualificationArchiveRoute = createRoute({
  getParentRoute: () => preQualificationRoute,
  path: 'archive',

  validateSearch: (search: Record<string, unknown>): { tab: string } => ({
    tab: (ARCHIVE_TABS.includes(search.tab as PreQualTab) ? search.tab : '') as string,
  }),

  component: PreQualificationArchivePage,
});

// #endregion

// #region Contracts Draft

/* ------ Contracts - Initial draft, Revisions, Amendments ------ */

export const contractEditRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: '/contract/edit/$contractId',
  beforeLoad: () => ({ isTemplate: false }),
  loader: options => draftContractLoader(options),
  component: function ContractDraftRootPage() {
    const intercom = useIntercom();
    const { contractId } = contractEditRoute.useParams();
    const { isRevising, isAmending } = contractEditRoute.useLoaderData();

    const childMatches = useChildMatches();
    const lastMatch = last(childMatches);

    const isReviewPage = lastMatch?.routeId === contractDraftReviewRoute.id;

    useTrackActiveSession(React.useMemo(() => ({
      type: 'entity',
      entityId: contractId,
      entityType: 'contract',
      scope: ['sender', isRevising ? 'revision' : isAmending ? 'amendment' : 'draft'],
    }), [contractId, isRevising, isAmending]));

    useEffect(
      () => {
        intercom.update({ verticalPadding: 80 });

        return () => {
          intercom.update({ verticalPadding: 20 });
        };
      },
      [intercom],
    );

    return (
      <ContractIdProvider contractId={contractId}>
        <ContractStateProvider
          isRevising={isRevising}
          isAmending={isAmending}
          isReview={isReviewPage}
        >
          <DraftContractNavigationProvider>
            <LiveContractNavigationProvider>
              <ContractHooksProvider>
                <Outlet key={contractId} />
              </ContractHooksProvider>
            </LiveContractNavigationProvider>
          </DraftContractNavigationProvider>
        </ContractStateProvider>
      </ContractIdProvider>
    );
  },

  errorComponent: withProps(ModelErrorPage, { model: 'contract' }),
});

export const contractDraftIndexRoute = createRoute({
  getParentRoute: () => contractEditRoute,
  path: '/',
  component: () => (
    <Navigate
      from={contractDraftIndexRoute.to}
      to={contractDraftSummaryRoute.to}
      replace={true}
    />
  ),
});

export const contractDraftSummaryRoute = createRoute({
  getParentRoute: () => contractEditRoute,
  path: 'summary',
  component: ContractDraftSummaryPageContent,
});

const contractDraftDetailsRoute = createRoute({
  getParentRoute: () => contractEditRoute,
  path: 'details',
});

export const contractDraftDetailsIndexRoute = createRoute({
  getParentRoute: () => contractDraftDetailsRoute,
  path: '/',
  component: function ContractDraftDetailsIndexPage() {
    const { contract } = contractEditRoute.useLoaderData();

    const firstVisiblePage = contract.pages.find(page =>
      page.type !== PageType.CONTRACT,
    );

    if (firstVisiblePage) {
      return (
        <Navigate
          from={contractDraftDetailsIndexRoute.fullPath}
          to={contractDraftDetailsPageRoute.to}
          params={prev => ({ ...prev, pageId: firstVisiblePage._id })}
          replace={true}
        />
      );
    }

    return <ContractDraftDetailsPagePageContent pageId={undefined} />;
  },
});

export const contractDraftDetailsPageRoute = createRoute({
  getParentRoute: () => contractDraftDetailsRoute,
  path: '$pageId',
  component: function ContractDraftDetailsPage() {
    const { pageId } = contractDraftDetailsPageRoute.useParams();

    return <ContractDraftDetailsPagePageContent pageId={pageId} />;
  },
});

export const contractDraftContractRoute = createRoute({
  getParentRoute: () => contractEditRoute,
  path: 'contract',
  component: ContractDraftContractPageContent,
});

export const contractDraftTeamRoute = createRoute({
  getParentRoute: () => contractEditRoute,
  path: 'team',
  validateSearch: (search: Record<string, unknown>): { companyId: string } => ({
    companyId: search.companyId as string,
  }),
  component: ContractDraftTeamPageContent,
});

export const contractDraftRemindersRoute = createRoute({
  getParentRoute: () => contractEditRoute,
  path: 'reminders',
  component: ContractDraftRemindersPageContent,
});

export const contractDraftReviewRoute = createRoute({
  getParentRoute: () => contractEditRoute,
  path: 'review',
  component: ContractDraftReviewPageContent,
});

// #endregion

// #region Contracts Live

/* ------ Contracts - Live ------ */

export const contractLiveRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: '/contract/$contractId',
  loader: options => liveContractLoader(options),
  component: function ContractLiveRootPage() {
    const { contractId, currentCompanyId } = contractLiveRoute.useParams();
    const { contract } = contractLiveRoute.useLoaderData();

    if (contract.status === ContractStatus.DRAFT) {
      return <Navigate to={contractDraftSummaryRoute.to} params={{ contractId, currentCompanyId }} />;
    }

    return (
      <ContractIdProvider contractId={contractId}>
        <ContractStateProvider isLive>
          <LiveContractNavigationProvider>
            <DraftContractNavigationProvider>
              <ContractHooksProvider>
                <Outlet key={contractId} />
              </ContractHooksProvider>
            </DraftContractNavigationProvider>
          </LiveContractNavigationProvider>
        </ContractStateProvider>
      </ContractIdProvider>
    );
  },

  errorComponent: withProps(ModelErrorPage, { model: 'contract' }),
});

export const contractLiveIndexRoute = createRoute({
  getParentRoute: () => contractLiveRoute,
  path: '/',
  component: () => (
    <Navigate
      from={contractLiveIndexRoute.to}
      to={contractLiveSummaryRoute.to}
      replace={true}
    />
  ),
});

export const contractLiveSummaryRoute = createRoute({
  getParentRoute: () => contractLiveRoute,
  path: 'summary',
  component: ContractLiveSummaryPageContent,
});

const contractLiveDetailsRoute = createRoute({
  getParentRoute: () => contractLiveRoute,
  path: 'details',
});

export const contractLiveDetailsIndexRoute = createRoute({
  getParentRoute: () => contractLiveDetailsRoute,
  path: '/',
  component: function ContractLiveDetailsIndexPage() {
    const { contract } = contractLiveRoute.useLoaderData();

    const firstVisiblePage = contract.pages.find(page =>
      page.type !== PageType.CONTRACT,
    );

    if (firstVisiblePage) {
      return (
        <Navigate
          from={contractLiveDetailsIndexRoute.fullPath}
          to={contractLiveDetailsPageRoute.to}
          params={prev => ({ ...prev, pageId: firstVisiblePage._id })}
          replace={true}
        />
      );
    }

    return <ContractLiveDetailsPagePageContent pageId={undefined} />;
  },
});

export const contractLiveDetailsPageRoute = createRoute({
  getParentRoute: () => contractLiveDetailsRoute,
  path: '$pageId',
  validateSearch: (search: Record<string, unknown>): {
    exchangeId?: string;
    recipientId?: string;
  } => ({
    exchangeId: search.exchangeId as string,
    recipientId: search.recipientId as string,
  }),
  component: function ContractLiveDetailsPage() {
    const { pageId } = contractLiveDetailsPageRoute.useParams();

    return <ContractLiveDetailsPagePageContent pageId={pageId} />;
  },
});

export const contractLiveContractRoute = createRoute({
  getParentRoute: () => contractLiveRoute,
  path: 'contract',
  validateSearch: (search: Record<string, unknown>): {
    exchangeId?: string;
    recipientId?: string;
    /** Used when we redirect back to the app from Verified when user e-signs (or rejects signing) */
    from?: 'verified';
  } => ({
    exchangeId: search.exchangeId as string,
    recipientId: search.recipientId as string,
    from: search.from as 'verified',
  }),
  component: ContractLiveContractPageContent,
});

export const contractLiveTeamRoute = createRoute({
  getParentRoute: () => contractLiveRoute,
  path: 'team',
  component: ContractLiveTeamPageContent,
});

export const contractLiveRemindersRoute = createRoute({
  getParentRoute: () => contractLiveRoute,
  path: 'reminders',
  component: ContractLiveRemindersPageContent,
});

export const contractLiveAuditRoute = createRoute({
  getParentRoute: () => contractLiveRoute,
  path: 'audit',
  component: ContractLiveAuditPageContent,
});

// #endregion

// #region Contract Templates Edit

/* ------ Contract templates edit ------ */

export const contractTemplateEditRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: '/contractTemplate/edit/$contractId',
  beforeLoad: () => ({ isTemplate: true }),
  loader: options => draftContractLoader(options),
  component: function ContractTemplateEditRootPage() {
    const intercom = useIntercom();
    const { contractId } = contractTemplateEditRoute.useParams();

    useTrackActiveSession(React.useMemo(() => ({
      type: 'entity',
      entityId: contractId,
      entityType: 'contractTemplate',
    }), [contractId]));

    useEffect(
      () => {
        intercom.update({ verticalPadding: 80 });

        return () => {
          intercom.update({ verticalPadding: 20 });
        };
      },
      [intercom],
    );

    const childMatches = useChildMatches();
    const lastMatch = last(childMatches);

    const isReviewPage = lastMatch?.routeId === contractTemplateReviewRoute.id;
    const isTemplatePreviewPage = lastMatch?.routeId === contractTemplatePreviewRoute.id;

    return (
      <ContractIdProvider contractId={contractId}>
        <ContractStateProvider
          isReview={isReviewPage}
          isTemplate
          isTemplatePreview={isTemplatePreviewPage}
        >
          <LiveContractNavigationProvider>
            <DraftContractNavigationProvider isTemplate={true}>
              <ContractHooksProvider>
                <Outlet key={contractId} />
              </ContractHooksProvider>
            </DraftContractNavigationProvider>
          </LiveContractNavigationProvider>
        </ContractStateProvider>
      </ContractIdProvider>
    );
  },

  errorComponent: withProps(ModelErrorPage, { model: 'contractTemplate' }),
});

export const contractTemplateEditIndexRoute = createRoute({
  getParentRoute: () => contractTemplateEditRoute,
  path: '/',
  component: () => (
    <Navigate
      from={contractTemplateEditIndexRoute.to}
      to={contractTemplateSummaryRoute.to}
      replace={true}
    />
  ),
});

export const contractTemplateSummaryRoute = createRoute({
  getParentRoute: () => contractTemplateEditRoute,
  path: 'summary',
  component: ContractDraftSummaryPageContent,
});

const contractTemplateDetailsRoute = createRoute({
  getParentRoute: () => contractTemplateEditRoute,
  path: 'details',
});

export const contractTemplateDetailsIndexRoute = createRoute({
  getParentRoute: () => contractTemplateDetailsRoute,
  path: '/',
  component: function ContractTemplateDetailsIndexPage() {
    const { contract } = contractTemplateEditRoute.useLoaderData();

    const firstVisiblePage = contract.pages.find(page =>
      page.type !== PageType.CONTRACT,
    );

    if (firstVisiblePage) {
      return (
        <Navigate
          from={contractTemplateDetailsIndexRoute.fullPath}
          to={contractTemplateDetailsPageRoute.to}
          params={prev => ({ ...prev, pageId: firstVisiblePage._id })}
          replace={true}
        />
      );
    }

    return <ContractDraftDetailsPagePageContent pageId={undefined} />;
  },
});

export const contractTemplateDetailsPageRoute = createRoute({
  getParentRoute: () => contractTemplateDetailsRoute,
  path: '$pageId',
  component: function ContractTemplateDetailsPage() {
    const { pageId } = contractTemplateDetailsPageRoute.useParams();

    return <ContractDraftDetailsPagePageContent pageId={pageId} />;
  },
});

export const contractTemplateContractRoute = createRoute({
  getParentRoute: () => contractTemplateEditRoute,
  path: 'contract',
  component: ContractDraftContractPageContent,
});

export const contractTemplateRemindersRoute = createRoute({
  getParentRoute: () => contractTemplateEditRoute,
  path: 'reminders',
  component: ContractDraftRemindersPageContent,
});

export const contractTemplateReviewRoute = createRoute({
  getParentRoute: () => contractTemplateEditRoute,
  path: 'review',
  component: ContractDraftReviewPageContent,
});

export const contractTemplatePreviewRoute = createRoute({
  getParentRoute: () => contractTemplateEditRoute,
  path: 'preview',
  component: ContractDraftReviewPageContent,
});

// #endregion

// #region Questionnaire Templates Draft

/* ------ Questionnaire templates - Draft ------ */

export const questionnaireTemplateEditRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: '/questionnaireTemplate/edit/$templateId',
  loader: options => draftQuestionnaireTemplateLoader(options),
  component: function QuestionnaireTemplateDraftRootPage() {
    const intercom = useIntercom();
    const { templateId } = questionnaireTemplateEditRoute.useParams();
    const { isRevising } = questionnaireTemplateEditRoute.useLoaderData();

    useEffect(
      () => {
        intercom.update({ verticalPadding: 80 });

        return () => {
          intercom.update({ verticalPadding: 20 });
        };
      },
      [intercom],
    );

    const childMatches = useChildMatches();
    const lastMatch = last(childMatches);

    const isReviewPage = lastMatch?.routeId === questionnaireTemplateDraftReviewRoute.id;

    return (
      <QuestionnaireTemplateIdProvider templateId={templateId}>
        <DraftQuestionnaireTemplateNavigationProvider>
          <ActiveQuestionnaireTemplateNavigationProvider>
            <QuestionnaireTemplateStateProvider
              isRevising={isRevising}
              isReview={isReviewPage}
            >
              <QuestionnaireTemplateHooksProvider>
                <Outlet key={templateId} />
              </QuestionnaireTemplateHooksProvider>
            </QuestionnaireTemplateStateProvider>
          </ActiveQuestionnaireTemplateNavigationProvider>
        </DraftQuestionnaireTemplateNavigationProvider>
      </QuestionnaireTemplateIdProvider>
    );
  },

  errorComponent: withProps(ModelErrorPage, { model: 'questionnaireTemplate' }),
});

export const questionnaireTemplateDraftIndexRoute = createRoute({
  getParentRoute: () => questionnaireTemplateEditRoute,
  path: '/',
  component: () => (
    <Navigate
      from={questionnaireTemplateDraftIndexRoute.to}
      to={questionnaireTemplateDraftSummaryRoute.to}
      replace={true}
    />
  ),
});

export const questionnaireTemplateDraftSummaryRoute = createRoute({
  getParentRoute: () => questionnaireTemplateEditRoute,
  path: 'summary',
  component: function QuestionnaireTemplateDraftSummaryPage() {
    return (
      <QuestionnaireTemplateDraftSummaryPageContent />
    );
  },
});

export const questionnaireTemplateDraftQuestionsRoute = createRoute({
  getParentRoute: () => questionnaireTemplateEditRoute,
  path: 'questions',
  component: function QuestionnaireTemplateDraftQuestionsPage() {
    return (
      <QuestionnaireTemplateDraftQuestionsPageContent />
    );
  },
});

export const questionnaireTemplateDraftReviewRoute = createRoute({
  getParentRoute: () => questionnaireTemplateEditRoute,
  path: 'review',
  component: function QuestionnaireTemplateDraftReviewPage() {
    return (
      <QuestionnaireTemplateDraftReviewPageContent />
    );
  },
});

// #endregion

// #region Questionnaire Templates Active

/* ------ Questionnaire templates - Active ------ */

export const questionnaireTemplateActiveRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: '/questionnaireTemplate/$templateId',
  loader: options => activeQuestionnaireTemplateLoader(options),
  component: function QuestionnaireTemplateActiveRootPage() {
    const { templateId } = questionnaireTemplateActiveRoute.useParams();

    return (
      <QuestionnaireNavigationProvider>
        <QuestionnaireTemplateIdProvider templateId={templateId}>
          <QuestionnaireTemplateStateProvider isLive>
            <QuestionnaireTemplateHooksProvider>
              <DraftQuestionnaireTemplateNavigationProvider>
                <ActiveQuestionnaireTemplateNavigationProvider>
                  <Outlet key={templateId} />
                </ActiveQuestionnaireTemplateNavigationProvider>
              </DraftQuestionnaireTemplateNavigationProvider>
            </QuestionnaireTemplateHooksProvider>
          </QuestionnaireTemplateStateProvider>
        </QuestionnaireTemplateIdProvider>
      </QuestionnaireNavigationProvider>
    );
  },

  errorComponent: withProps(ModelErrorPage, { model: 'questionnaireTemplate' }),
});

export const questionnaireTemplateActiveIndexRoute = createRoute({
  getParentRoute: () => questionnaireTemplateActiveRoute,
  path: '/',
  component: () => (
    <Navigate
      from={questionnaireTemplateActiveIndexRoute.to}
      to={questionnaireTemplateActiveSuppliersRoute.to}
      replace={true}
    />
  ),
});

export const questionnaireTemplateActiveSuppliersRoute = createRoute({
  getParentRoute: () => questionnaireTemplateActiveRoute,
  path: 'suppliers',
  component: function QuestionnaireTemplateActiveSuppliersPage() {
    return (
      <QuestionnaireTemplateActiveSuppliersPageContent />
    );
  },
});

export const questionnaireTemplateActiveQuestionsRoute = createRoute({
  getParentRoute: () => questionnaireTemplateActiveRoute,
  path: 'questions',
  component: function QuestionnaireTemplateActiveQuestionsPage() {
    return (
      <QuestionnaireTemplateActiveQuestionsPageContent />
    );
  },
});

export const questionnaireTemplateActiveDetailsRoute = createRoute({
  getParentRoute: () => questionnaireTemplateActiveRoute,
  path: 'details',
  component: function QuestionnaireTemplateActiveDetailsPage() {
    return (
      <QuestionnaireTemplateActiveDetailsPageContent />
    );
  },
});

// #endregion

// #region Questionnaires

/* ------ Questionnaires ------ */

export const questionnaireRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: '/questionnaire/$questionnaireId',
  loader: options => questionnaireLoader(options),
  component: function QuestionnaireRootPage() {
    const { questionnaireId } = questionnaireRoute.useParams();

    return (
      <QuestionnaireIdProvider questionnaireId={questionnaireId}>
        <ActiveQuestionnaireTemplateNavigationProvider>
          <QuestionnaireNavigationProvider>
            <QuestionnaireHooksProvider>
              <QuestionnaireStateProvider>
                <Outlet key={questionnaireId} />
              </QuestionnaireStateProvider>
            </QuestionnaireHooksProvider>
          </QuestionnaireNavigationProvider>
        </ActiveQuestionnaireTemplateNavigationProvider>
      </QuestionnaireIdProvider>
    );
  },

  errorComponent: withProps(ModelErrorPage, { model: 'questionnaire' }),
});

export const questionnaireIndexRoute = createRoute({
  getParentRoute: () => questionnaireRoute,
  path: '/',
  component: () => (
    <Navigate
      from={questionnaireIndexRoute.to}
      to={questionnaireDetailsRoute.to}
      replace={true}
    />
  ),
});

export const questionnaireDetailsRoute = createRoute({
  getParentRoute: () => questionnaireRoute,
  path: 'details',
  component: function QuestionnaireDetailsPage() {
    return (
      <QuestionnaireDetailsPageContent />
    );
  },
});

export const questionnaireQuestionsRoute = createRoute({
  getParentRoute: () => questionnaireRoute,
  path: 'questions',
  validateSearch: (search: Record<string, unknown>): { exchangeId?: string; recipientId?: string } => ({
    exchangeId: search.exchangeId as string,
    recipientId: search.recipientId as string,
  }),
  component: function QuestionnaireQuestionsPage() {
    const { exchangeId } = questionnaireQuestionsRoute.useSearch();

    return (
      <QuestionnaireQuestionsPageContent exchangeId={exchangeId} />
    );
  },
});

export const questionnaireTeamRoute = createRoute({
  getParentRoute: () => questionnaireRoute,
  path: 'team',
  component: function QuestionnaireTeamPage() {
    return (
      <QuestionnaireTeamPageContent />
    );
  },
});

export const questionnaireAuditRoute = createRoute({
  getParentRoute: () => questionnaireRoute,
  path: 'audit',
  loader: options => questionnaireLoader(options),
  component: function QuestionnaireAuditPage() {
    return (
      <QuestionnaireAuditPageContent />
    );
  },
});

// #endregion

export const sendQuestionnaireRoute = createRoute({
  getParentRoute: () => companyRoute,
  path: '/send-questionnaire',
  validateSearch: (search: Record<string, unknown>): {
    from?: string;
    templateIds?: string[];
  } => ({
    from: search.from as string,
    // templateIds: search.templateIds ? castArray(search.templateIds) as string[] : undefined,
    templateIds: getArrayQueryParam<string>(search.templateIds),
  }),
  component: function SendQuestionnairePage() {
    const intercom = useIntercom();
    const { t } = useTranslation('preQualification');
    const search = sendQuestionnaireRoute.useSearch();

    useEffect(
      () => {
        intercom.update({ verticalPadding: 80 });

        return () => {
          intercom.update({ verticalPadding: 20 });
        };
      },
      [intercom],
    );

    return (
      <ActiveQuestionnaireTemplateNavigationProvider>
        <Layout.BasicHeader icon="file-text-o" heading={t('preQualification')}>
          <SendQuestionnaire
            from={search.from}
            templateIds={search.templateIds}
          />
        </Layout.BasicHeader>
      </ActiveQuestionnaireTemplateNavigationProvider>
    );
  },
});

export const routeTree = rootRoute.addChildren([
  legacySentRequestsRedirectRoute,
  legacyReceivedRequestsRedirectRoute,
  userProfileRoute,
  publicRequestRoute,
  publicRequestSlugRoute,
  companyRoute.addChildren([
    dashboardRoute,
    requestsRoute.addChildren([
      requestsIndexRoute,
      requestReceivedRoute.addChildren([
        requestReceivedIndexRoute,
        requestReceivedBidRoute.addChildren([
          requestReceivedBidExchangeRoute,
          requestReceivedBidIndexRoute,
          requestReceivedBidStageRoute.addChildren([
            requestReceivedBidStageIndexRoute,
            requestReceivedBidStagePageRoute.addChildren([
              requestReceivedBidStagePageIndexRoute,
              requestReceivedBidStagePageInstanceRoute,
              requestReceivedBidStagePageInstanceSectionRoute,
            ]),
            requestReceivedBidStageRequirementPageRoute.addChildren([
              requestReceivedBidStageRequirementPageIndexRoute,
              requestReceivedBidStageRequirementPageInstanceRoute.addChildren([
                requestReceivedBidStageRequirementPageInstanceIndexRoute,
                requestReceivedBidStageRequirementPageInstanceSectionRoute,
              ]),
            ]),
          ]),
        ]),
        requestReceivedAwardSummaryRoute,
        requestReceivedMessagesRoute,
        requestReceivedLegacyBulletinsRoute.addChildren([
          requestReceivedLegacyBulletinsIndexRoute,
          requestReceivedLegacyBulletinsListRoute,
          requestReceivedLegacyBulletinQuestionsRoute,
        ]),
        requestReceivedTeamRoute,
        requestReceivedAuditRoute,
        requestReceivedAwardRoute,
      ]),
    ]),
    contractsRoute,
    requestEditRoute.addChildren([
      requestEditSummaryRoute,
      requestEditStagesRoute,
      requestEditTeamRoute,
      requestEditDetailsRoute.addChildren([
        requestEditDetailsIndexRoute,
        requestEditDetailsPageRoute,
      ]),
      requestEditAuctionRoute,
      requestEditEvaluationRoute.addChildren([
        requestEditEvaluationIndexRoute,
        requestEditEvaluationPageRoute,
      ]),
      requestEditSpendRoute,
      requestEditSuppliersRoute,
      requestEditApprovalsRoute,
      requestEditCommentsRoute,
      requestEditSetupMultiStageResponsesFlowRoute,
      requestEditReviewRoute,
    ]),
    requestSentRoute.addChildren([
      requestSentIndexRoute,
      requestSentSuppliersRoute.addChildren([
        requestSentSuppliersIndexRoute,
        requestSentSuppliersViewRoute,
        requestSentSuppliersAddRoute,

        requestSentRecipientRoute.addChildren([
          requestSentRecipientIndexRoute,
          requestSentRecipientBidRoute.addChildren([
            requestSentRecipientBidExchangeRoute,
            requestSentRecipientBidIndexRoute,
            requestSentRecipientBidStageRoute.addChildren([
              requestSentRecipientBidStageIndexRoute,
              requestSentRecipientBidStagePageRoute.addChildren([
                requestSentRecipientBidStagePageIndexRoute,
                requestSentRecipientBidStagePageInstanceRoute,
              ]),
              requestSentRecipientBidStageRequirementPageRoute.addChildren([
                requestSentRecipientBidStageRequirementPageIndexRoute,
                requestSentRecipientBidStageRequirementPageInstanceRoute,
              ]),
            ]),
          ]),
          requestSentRecipientAwardSummaryRoute,
          requestSentRecipientMessagesRoute,
          requestSentRecipientTeamRoute,
          requestSentRecipientAuditRoute,
          requestSentRecipientEvaluationRoute.addChildren([
            requestSentRecipientEvaluationIndexRoute,
            requestSentRecipientEvaluationPageRoute,
          ]),
        ]),
      ]),
      requestSentTeamRoute,
      requestSentDetailsRoute,
      requestSentLegacyBulletinsRoute.addChildren([
        requestSentLegacyBulletinsIndexRoute,
        requestSentLegacyBulletinsListRoute,
        requestSentLegacyBulletinQuestionsRoute,
      ]),
      requestSentAuctionRoute,
      requestSentCommentsRoute,
      requestSentMessagesRoute,
      requestSentVesselPricingRoute,
      requestSentComparisonRoute,
      requestSentSpendRoute,
      requestSentAuditRoute,
      requestSentAwardRoute,
      requestSentAwardFlowRoute,
      legacyRequestSentRecipientRoute.addChildren([
        legacyRequestSentRecipientBidRoute.addChildren([
          legacyRequestSentRecipientBidIndexRoute,
          legacyRequestSentRecipientBidPageRoute,
          legacyRequestSentRecipientBidExchangeRoute,
        ]),
        legacyRequestSentRecipientEvaluationRoute.addChildren([
          legacyRequestSentRecipientEvaluationIndexRoute,
          legacyRequestSentRecipientEvaluationPageRoute,
        ]),
      ]),
      legacyRequestSentRecipientTeamRoute,
      legacyRequestSentRecipientAuditRoute,
    ]),
    legacyRequestReceivedRoute.addChildren([
      legacyRequestReceivedIndexRoute,
      legacyRequestReceivedBidRoute.addChildren([
        legacyRequestReceivedBidIndexRoute,
        legacyRequestReceivedBidPageRoute,
        legacyRequestReceivedBidPageExchangeRoute,
      ]),
      legacyRequestReceivedDetailsRoute,
      legacyRequestReceivedAuctionRoute,
      legacyRequestReceivedLegacyBulletinsRoute.addChildren([
        legacyRequestReceivedLegacyBulletinsIndexRoute,
        legacyRequestReceivedLegacyBulletinsListRoute,
        legacyRequestReceivedLegacyBulletinQuestionsRoute,
      ]),
      legacyRequestReceivedTeamRoute,
      legacyRequestReceivedAuditRoute,
      legacyRequestReceivedAwardRoute,
    ]),
    templateEditRoute.addChildren([
      templateSummaryRoute,
      templateStagesRoute,
      templateTeamRoute,
      templateSuppliersRoute,
      templateDetailsRoute.addChildren([
        templateDetailsIndexRoute,
        templateDetailsPageRoute,
      ]),
      templateAuctionRoute,
      templateEvaluationRoute.addChildren([
        templateEvaluationIndexRoute,
        templateEvaluationPageRoute,
      ]),
      templateSpendRoute,
      templateReviewRoute,
      templatePreviewRoute,
      templateSetupMultiStageResponsesFlowRoute,
    ]),
    driveRoute.addChildren([
      driveIndexRoute,
      driveDocumentsRoute,
      driveHistoryRoute,
    ]),
    reportingRoute.addChildren([
      reportingIndexRoute,
      reportingRequestsRoute,
      reportingContractsRoute,
    ]),
    teamManagementRoute,
    teamManagementRequestsRoute,
    networkRoute.addChildren([
      networkIndexRoute,
      internalNetworkRoute,
      externalNetworkRoute,
    ]),
    networkRedirectRoute,
    supplierListsRoute,
    createSupplierListRoute,
    supplierListRoute.addChildren([
      supplierListSuppliersRoute,
      supplierListSettingsRoute,
      supplierListEditRoute,
    ]),
    sentInvitesRoute,
    notificationsRoute,
    supplierDiscoveryRoute,
    companySettingsRoute,
    companyProfileRoute,
    modelDeletedRoute,
    preQualificationRoute.addChildren([
      preQualificationIndexRoute,
      preQualificationQuestionnairesRoute,
      preQualificationQuestionsRoute,
      preQualificationSuppliersRoute,
      preQualificationArchiveRoute,
    ]),
    legacyPreQualificationAuditRoute,
    contractEditRoute.addChildren([
      contractDraftIndexRoute,
      contractDraftSummaryRoute,
      contractDraftDetailsRoute.addChildren([
        contractDraftDetailsIndexRoute,
        contractDraftDetailsPageRoute,
      ]),
      contractDraftContractRoute,
      contractDraftTeamRoute,
      contractDraftRemindersRoute,
      contractDraftReviewRoute,
    ]),
    contractLiveRoute.addChildren([
      contractLiveIndexRoute,
      contractLiveSummaryRoute,
      contractLiveDetailsRoute.addChildren([
        contractLiveDetailsIndexRoute,
        contractLiveDetailsPageRoute,
      ]),
      contractLiveContractRoute,
      contractLiveTeamRoute,
      contractLiveRemindersRoute,
      contractLiveAuditRoute,
    ]),
    contractTemplateEditRoute.addChildren([
      contractTemplateEditIndexRoute,
      contractTemplateSummaryRoute,
      contractTemplateDetailsRoute.addChildren([
        contractTemplateDetailsIndexRoute,
        contractTemplateDetailsPageRoute,
      ]),
      contractTemplateContractRoute,
      contractTemplateRemindersRoute,
      contractTemplateReviewRoute,
      contractTemplatePreviewRoute,
    ]),
    questionnaireTemplateEditRoute.addChildren([
      questionnaireTemplateDraftIndexRoute,
      questionnaireTemplateDraftSummaryRoute,
      questionnaireTemplateDraftQuestionsRoute,
      questionnaireTemplateDraftReviewRoute,
    ]),
    questionnaireTemplateActiveRoute.addChildren([
      questionnaireTemplateActiveIndexRoute,
      questionnaireTemplateActiveSuppliersRoute,
      questionnaireTemplateActiveQuestionsRoute,
      questionnaireTemplateActiveDetailsRoute,
    ]),
    questionnaireRoute.addChildren([
      questionnaireIndexRoute,
      questionnaireDetailsRoute,
      questionnaireQuestionsRoute,
      questionnaireTeamRoute,
      questionnaireAuditRoute,
    ]),
    sendQuestionnaireRoute,
  ]),
]);

export const useTeamRouteSearch = ({ isTemplate }: { isTemplate: boolean }) => {
  return useSearch({
    from: isTemplate ? templateTeamRoute.id : requestEditTeamRoute.id,
  });
};

export const useAuctionRouteSearch = ({ isTemplate }: { isTemplate: boolean }) => {
  return useSearch({
    from: isTemplate ? templateAuctionRoute.to : requestEditAuctionRoute.to,
  });
};

// #region nav

const RequestEditNavigationProvider = ({
  isTemplate,
  isRevising,
  children,
}: {
  isRevising: boolean;
  isTemplate: boolean;
  children: React.ReactNode;
}) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqId = useRfqId();
  const navigate = useNavigate();

  const navigationFunctions = React.useMemo(
    () => ({
      getReviewLinkProps: () => ({
        to: isTemplate ? templateReviewRoute.to : requestEditReviewRoute.to,
        params: { rfqId, currentCompanyId },
      }),
      getSummaryLinkProps: () => ({
        to: isTemplate ? templateSummaryRoute.to : requestEditSummaryRoute.to,
        params: { rfqId, currentCompanyId },
      }),
      navigateToSummary: () => {
        navigate({
          to: isTemplate ? templateSummaryRoute.to : requestEditSummaryRoute.to,
          params: { rfqId, currentCompanyId },
        });
      },
      navigateToStages: () => {
        navigate({
          to: isTemplate ? templateStagesRoute.to : requestEditStagesRoute.to,
          params: { rfqId, currentCompanyId },
        });
      },
      navigateToDetailsIndex: () => {
        navigate({
          to: isTemplate ? templateDetailsIndexRoute.to : requestEditDetailsIndexRoute.to,
          params: { rfqId, currentCompanyId },
        });
      },
      navigateToDetailsPage: (pageId: string) => {
        navigate({
          to: isTemplate ? templateDetailsPageRoute.to : requestEditDetailsPageRoute.to,
          params: { rfqId, currentCompanyId, pageId },
        });
      },
      navigateToAuction: (tab?: AuctionTabId, replace?: boolean) => {
        navigate({
          to: isTemplate ? templateAuctionRoute.to : requestEditAuctionRoute.to,
          params: { rfqId, currentCompanyId },
          // @ts-expect-error ts(2322) FIXME: Type '{ tab: AuctionTabId; } | undefined' is not assignable to type '{ tab: AuctionTabId; } | { tab: AuctionTabId; } | ParamsReducerFn<Router<RootRoute<undefined, RouterContext, AnyContext, AnyContext, {}, undefined, readonly [...]>, TrailingSlashOption, Record<...>, Record<...>>, "SEARCH", string, "/$currentCompanyId/request/edit/$rfqId/auction" | "/$currentCompanyId/template/edit/$...'.
          search: tab ? { tab } : undefined,
          replace,
        });
      },
      navigateToEvaluationIndex: () => {
        navigate({
          to: isTemplate ? templateEvaluationIndexRoute.to : requestEditEvaluationIndexRoute.to,
          params: { rfqId, currentCompanyId },
        });
      },
      navigateToEvaluationPage: (pageId: string) => {
        navigate({
          to: isTemplate ? templateEvaluationPageRoute.to : requestEditEvaluationPageRoute.to,
          params: { rfqId, currentCompanyId, pageId },
        });
      },
      navigateToSpend: () => {
        navigate({
          to: isTemplate ? templateSpendRoute.to : requestEditSpendRoute.to,
          params: { rfqId, currentCompanyId },
        });
      },
      navigateToTeam: (companyId: string, replace?: boolean) => {
        navigate({
          to: isTemplate ? templateTeamRoute.to : requestEditTeamRoute.to,
          params: { rfqId, currentCompanyId },
          // @ts-expect-error ts(2322) FIXME: Type '{ companyId: string; } | undefined' is not assignable to type '{ companyId: string; } | { companyId: string; } | ParamsReducerFn<Router<RootRoute<undefined, RouterContext, AnyContext, AnyContext, {}, undefined, readonly [Route<RootRoute<undefined, ... 5 more ..., unknown>, ... 11 more ..., unknown>, ... 4 more ..., Route<...>]>, TrailingSlashOption, Record<...>, Record<...>>, "...'.
          search: companyId ? { companyId } : undefined,
          replace,
        });
      },
      navigateToSuppliers: () => {
        navigate({
          to: isTemplate ? templateSuppliersRoute.to : requestEditSuppliersRoute.to,
          params: { rfqId, currentCompanyId },
        });
      },
      navigateToReview: () => {
        navigate({
          to: isTemplate ? templateReviewRoute.to : requestEditReviewRoute.to,
          params: { rfqId, currentCompanyId },
        });
      },
      navigateToApprovals: () => {
        if (isRevising || isTemplate) {
          throw new Error('No approvals route for revisions or templates');
        }

        navigate({
          to: requestEditApprovalsRoute.to,
          params: { rfqId, currentCompanyId },
        });
      },
      navigateToComments: () => {
        if (isRevising || isTemplate) {
          throw new Error('No comments route for revisions or templates');
        }

        navigate({
          to: requestEditCommentsRoute.to,
          params: { rfqId, currentCompanyId },
        });
      },
      navigateToSetupMultiStageResponsesFlow: () => {
        navigate({
          to: isTemplate ? templateSetupMultiStageResponsesFlowRoute.to : requestEditSetupMultiStageResponsesFlowRoute.to,
          params: { rfqId, currentCompanyId },
        });
      },
    }),
    [currentCompanyId, isRevising, isTemplate, navigate, rfqId],
  );

  return (
    // @ts-expect-error ts(2322) FIXME: Type '{ navigateToSummary: () => void; navigateToStages: () => void; navigateToDetailsIndex: () => void; navigateToDetailsPage: (pageId: string) => void; navigateToAuction: (tab?: AuctionTabId, replace?: boolean) => void; ... 7 more ...; navigateToComments: () => void; }' is not assignable to type '{ navigateToSummary: () => void; navigateToStages: () => void; navigateToDetailsIndex: () => void; navigateToDetailsPage: (pageId: string) => void; navigateToAuction: (tab?: AuctionTabId | undefined, replace?: boolean | undefined) => void; ... 7 more ...; navigateToComments: () => void; }'.
    <RequestEditNavigationContext.Provider value={navigationFunctions}>
      {children}
    </RequestEditNavigationContext.Provider>
  );
};

const DraftContractNavigationProvider = ({
  isTemplate = false,
  children,
}: {
  isTemplate?: boolean;
  children: React.ReactNode;
}) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractId = useContractId();
  const navigate = useNavigate();

  const navigationFunctions = React.useMemo(
    () => ({
      navigateToSummary: () => {
        navigate({
          to: isTemplate ? contractTemplateSummaryRoute.to : contractDraftSummaryRoute.to,
          params: { contractId, currentCompanyId },
        });
      },
      navigateToDetailsIndex: () => {
        navigate({
          to: isTemplate ? contractTemplateDetailsIndexRoute.to : contractDraftDetailsIndexRoute.to,
          params: { contractId, currentCompanyId },
        });
      },
      navigateToDetailsPage: (pageId: string) => {
        navigate({
          to: isTemplate ? contractTemplateDetailsPageRoute.to : contractDraftDetailsPageRoute.to,
          params: { contractId, currentCompanyId, pageId },
        });
      },
      navigateToContract: () => {
        navigate({
          to: isTemplate ? contractTemplateContractRoute.to : contractDraftContractRoute.to,
          params: { contractId, currentCompanyId },
        });
      },
      navigateToTeam: (companyId: string, replace?: boolean) => {
        navigate({
          to: contractDraftTeamRoute.to,
          params: { contractId, currentCompanyId },
          // @ts-expect-error ts(2322) FIXME: Type '{ companyId: string; } | undefined' is not assignable to type '{ companyId: string; } | ParamsReducerFn<Router<RootRoute<undefined, RouterContext, AnyContext, AnyContext, {}, undefined, readonly [Route<RootRoute<undefined, RouterContext, ... 4 more ..., unknown>, ... 11 more ..., unknown>, ... 4 more ..., Route<...>]>, TrailingSlashOption, Record<...>, Record<...>>, "SEARCH", s...'.
          search: companyId ? { companyId } : undefined,
          replace,
        });
      },
      navigateToReminders: () => {
        navigate({
          to: isTemplate ? contractTemplateRemindersRoute.to : contractDraftRemindersRoute.to,
          params: { contractId, currentCompanyId },
        });
      },
      navigateToReview: () => {
        navigate({
          to: isTemplate ? contractTemplateReviewRoute.to : contractDraftReviewRoute.to,
          params: { contractId, currentCompanyId },
        });
      },
    }),
    [navigate, isTemplate, contractId, currentCompanyId],
  );

  return (
    // @ts-expect-error ts(2322) FIXME: Type '{ navigateToSummary: () => void; navigateToDetailsIndex: () => void; navigateToDetailsPage: (pageId: string) => void; navigateToContract: () => void; navigateToTeam: (companyId: string, replace?: boolean) => void; navigateToReminders: () => void; navigateToReview: () => void; }' is not assignable to type '{ navigateToSummary: () => void; navigateToDetailsIndex: () => void; navigateToDetailsPage: (pageId: string) => void; navigateToContract: (tab?: string | undefined, replace?: boolean | undefined) => void; navigateToTeam: (companyId?: string | undefined, replace?: boolean | undefined) => void; navigateToReminders: ()...'.
    <DraftContractNavigationContext.Provider value={navigationFunctions}>
      {children}
    </DraftContractNavigationContext.Provider>
  );
};

const LiveContractNavigationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contractId = useContractId();
  const navigate = useNavigate();

  const navigationFunctions = React.useMemo(
    () => ({
      navigateToSummary: () => {
        navigate({
          to: contractLiveSummaryRoute.to,
          params: { contractId, currentCompanyId },
        });
      },
      navigateToDetailsIndex: () => {
        navigate({
          to: contractLiveDetailsIndexRoute.to,
          params: { contractId, currentCompanyId },
        });
      },
      navigateToDetailsPage: (pageId: string, search?: any) => {
        navigate({
          to: contractLiveDetailsPageRoute.to,
          params: { contractId, currentCompanyId, pageId },
          search: omitNil(search),
        });
      },
      navigateToContract: (search?: Record<string, unknown>) => {
        navigate({
          to: contractLiveContractRoute.to,
          params: { contractId, currentCompanyId },
          // @ts-expect-error ts(2345) FIXME: Argument of type 'Record<string, unknown> | undefined' is not assignable to parameter of type 'Record<string, any>'.
          search: omitNil(search),
        });
      },
      navigateToTeam: (companyId: string, replace?: boolean) => {
        navigate({
          to: contractLiveTeamRoute.to,
          params: { contractId, currentCompanyId },
          search: companyId ? { companyId } : undefined,
          replace,
        });
      },
      navigateToReminders: () => {
        navigate({
          to: contractLiveRemindersRoute.to,
          params: { contractId, currentCompanyId },
        });
      },
      navigateToAudit: () => {
        navigate({
          to: contractLiveAuditRoute.to,
          params: { contractId, currentCompanyId },
        });
      },
    }),
    [navigate, contractId, currentCompanyId],
  );

  return (
    // @ts-expect-error ts(2322) FIXME: Type '{ navigateToSummary: () => void; navigateToDetailsIndex: () => void; navigateToDetailsPage: (pageId: string, search?: any) => void; navigateToContract: (search?: Record<string, unknown>) => void; navigateToTeam: (companyId: string, replace?: boolean) => void; navigateToReminders: () => void; navigateToAudit: () => v...' is not assignable to type '{ navigateToSummary: () => void; navigateToDetailsIndex: () => void; navigateToDetailsPage: (pageId: string, search?: Record<string, unknown> | undefined) => void; navigateToContract: (search?: Record<...> | undefined) => void; navigateToTeam: (companyId?: string | undefined, replace?: boolean | undefined) => void; ...'.
    <LiveContractNavigationContext.Provider value={navigationFunctions}>
      {children}
    </LiveContractNavigationContext.Provider>
  );
};

const DraftQuestionnaireTemplateNavigationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contextTemplateId = useQuestionnaireTemplateId({ required: false });
  const navigate = useNavigate();

  const navigationFunctions = React.useMemo(
    () => ({
      navigateToSummary: (templateId?: string) => {
        navigate({
          to: questionnaireTemplateDraftSummaryRoute.to,
          params: {
            // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
            templateId: templateId || contextTemplateId,
            currentCompanyId,
          },
        });
      },
      navigateToQuestions: (templateId?: string) => {
        navigate({
          to: questionnaireTemplateDraftQuestionsRoute.to,
          params: {
            // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
            templateId: templateId || contextTemplateId,
            currentCompanyId,
          },
        });
      },
      navigateToReview: (templateId?: string) => {
        navigate({
          to: questionnaireTemplateDraftReviewRoute.to,
          params: {
            // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
            templateId: templateId || contextTemplateId,
            currentCompanyId,
          },
        });
      },
    }),
    [navigate, contextTemplateId, currentCompanyId],
  );

  return (
    <DraftQuestionnaireTemplateNavigationContext.Provider value={navigationFunctions}>
      {children}
    </DraftQuestionnaireTemplateNavigationContext.Provider>
  );
};

const ActiveQuestionnaireTemplateNavigationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contextTemplateId = useQuestionnaireTemplateId({ required: false });
  const navigate = useNavigate();

  const navigationFunctions = React.useMemo(
    () => ({
      navigateToSuppliers: (templateId?: string) => {
        navigate({
          to: questionnaireTemplateActiveSuppliersRoute.to,
          params: {
            // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
            templateId: templateId || contextTemplateId,
            currentCompanyId,
          },
        });
      },
      navigateToQuestions: (templateId?: string) => {
        navigate({
          to: questionnaireTemplateActiveQuestionsRoute.to,
          params: {
            // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
            templateId: templateId || contextTemplateId,
            currentCompanyId,
          },
        });
      },
      navigateToDetails: (templateId?: string) => {
        navigate({
          to: questionnaireTemplateActiveDetailsRoute.to,
          params: {
            // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
            templateId: templateId || contextTemplateId,
            currentCompanyId,
          },
        });
      },
    }),
    [navigate, contextTemplateId, currentCompanyId],
  );

  return (
    <ActiveQuestionnaireTemplateNavigationContext.Provider value={navigationFunctions}>
      {children}
    </ActiveQuestionnaireTemplateNavigationContext.Provider>
  );
};

const QuestionnaireNavigationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contextQuestionnaireId = useQuestionnaireId({ required: false });
  const navigate = useNavigate();
  const { from } = useSearch({ strict: false }) as { from?: 'template' | 'suppliers' };

  const navigationFunctions = React.useMemo(
    () => ({
      navigateToDetails: (questionnaireId?: string) => {
        navigate({
          to: questionnaireDetailsRoute.to,
          params: {
            // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
            questionnaireId: questionnaireId || contextQuestionnaireId,
            currentCompanyId,
          },
          search: omitNil({ from }),
        });
      },
      navigateToQuestions: (questionnaireId?: string, query?: Record<string, string>) => {
        navigate({
          to: questionnaireQuestionsRoute.to,
          params: {
            // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
            questionnaireId: questionnaireId || contextQuestionnaireId,
            currentCompanyId,
          },
          search: omitNil({ from, ...query }),
        });
      },
      navigateToTeam: (questionnaireId?: string) => {
        navigate({
          to: questionnaireTeamRoute.to,
          params: {
            // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
            questionnaireId: questionnaireId || contextQuestionnaireId,
            currentCompanyId,
          },
          search: omitNil({ from }),
        });
      },
      navigateToAudit: (questionnaireId?: string) => {
        navigate({
          to: questionnaireAuditRoute.to,
          params: {
            // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
            questionnaireId: questionnaireId || contextQuestionnaireId,
            currentCompanyId,
          },
          search: omitNil({ from }),
        });
      },
    }),
    [navigate, contextQuestionnaireId, from, currentCompanyId],
  );

  return (
    <QuestionnaireNavigationContext.Provider value={navigationFunctions}>
      {children}
    </QuestionnaireNavigationContext.Provider>
  );
};

const SupplierListNavigationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const contextSupplierListId = useSupplierListId({ required: false });
  const navigate = useNavigate();

  const navigationFunctions = React.useMemo(
    () => ({
      navigateToLists: (search: { from: 'externalNetwork' | 'internalNetwork' } = { from: 'externalNetwork' }) => {
        navigate({
          to: supplierListsRoute.to,
          params: { currentCompanyId },
          search,
        });
      },
      navigateToListSuppliers: (supplierListId?: string) => {
        navigate({
          to: supplierListSuppliersRoute.to,
          params: {
            // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
            supplierListId: supplierListId || contextSupplierListId,
            currentCompanyId,
          },
        });
      },
      navigateToListSettings: (supplierListId?: string) => {
        navigate({
          to: supplierListSettingsRoute.to,
          params: {
            // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
            supplierListId: supplierListId || contextSupplierListId,
            currentCompanyId,
          },
        });
      },
      navigateToListEdit: (supplierListId?: string) => {
        navigate({
          to: supplierListEditRoute.to,
          params: {
            // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
            supplierListId: supplierListId || contextSupplierListId,
            currentCompanyId,
          },
        });
      },
      navigateToCreateList: () => {
        navigate({
          to: createSupplierListRoute.to,
          params: {
            currentCompanyId,
          },
        });
      },
      getInternalNetworkLinkProps: () => ({
        to: internalNetworkRoute.to,
        params: { currentCompanyId },
      }),
      getExternalNetworkLinkProps: () => ({
        to: externalNetworkRoute.to,
        params: { currentCompanyId },
      }),
      getSupplierListsLinkProps: () => ({
        to: supplierListsRoute.to,
        params: { currentCompanyId },
      }),
      getSupplierListSettingsLinkProps: (supplierListId: string) => ({
        to: supplierListSettingsRoute.to,
        params: { currentCompanyId, supplierListId },
      }),
    }),
    [navigate, contextSupplierListId, currentCompanyId],
  );

  return (
    <SupplierListNavigationContext.Provider value={navigationFunctions}>
      {children}
    </SupplierListNavigationContext.Provider>
  );
};

// #endregion

const RequestSentNavigationProvider = ({
  recipientId,
  structure,
  children,
}: {
  recipientId?: string;
  structure: RfxStructure;
  children: React.ReactNode;
}) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqId = useRfqId();
  const navigate = useNavigate();

  const navigationFunctions = React.useMemo(() => {
    const getSuppliersLinkProps = ({ exchangeId }: { exchangeId?: string } = {}) => {
      return {
        to: requestSentSuppliersViewRoute.to,
        params: { rfqId, currentCompanyId },
        search: exchangeId ? { exchangeId } : undefined,
      };
    };

    return {
      getSuppliersLinkProps,
      navigateToSuppliers: (params: { exchangeId?: string } = {}) => {
        navigate(getSuppliersLinkProps(params));
      },
      navigateToAddSuppliers: () => {
        navigate({
          to: requestSentSuppliersAddRoute.to,
          params: { rfqId, currentCompanyId },
        });
      },
      navigateToTeam: ({ companyId }: { companyId?: string } = {}, replace = false) => {
        navigate({
          to: requestSentTeamRoute.to,
          params: { rfqId, currentCompanyId },
          // @ts-expect-error ts(2322) FIXME: Type '{ companyId: string; } | undefined' is not assignable to type '{ companyId: string; } | ParamsReducerFn<Router<RootRoute<undefined, RouterContext, AnyContext, AnyContext, {}, undefined, readonly [Route<RootRoute<undefined, RouterContext, ... 4 more ..., unknown>, ... 11 more ..., unknown>, ... 4 more ..., Route<...>]>, TrailingSlashOption, Record<...>, Record<...>>, "SEARCH", s...'.
          search: companyId ? { companyId } : undefined,
          replace,
        });
      },
      navigateToDetails: () => {
        navigate({
          to: requestSentDetailsRoute.to,
          params: { rfqId, currentCompanyId },
        });
      },
      navigateToLegacyBulletins: () => {
        navigate({
          to: requestSentLegacyBulletinsIndexRoute.to,
          params: { rfqId, currentCompanyId },
        });
      },
      navigateToAuction: () => {
        navigate({
          to: requestSentAuctionRoute.to,
          params: { rfqId, currentCompanyId },
        });
      },
      navigateToMessages: (sectionType?: MessagesSectionType, replace?: boolean) => {
        navigate({
          to: requestSentMessagesRoute.to,
          params: { rfqId, currentCompanyId },
          search: sectionType ? { sectionType } : undefined,
          replace,
        });
      },
      navigateToComments: () => {
        navigate({
          to: requestSentCommentsRoute.to,
          params: { rfqId, currentCompanyId },
        });
      },
      navigateToComparison: () => {
        navigate({
          to: requestSentComparisonRoute.to,
          params: { rfqId, currentCompanyId },
        });
      },
      navigateToSpend: () => {
        navigate({
          to: requestSentSpendRoute.to,
          params: { rfqId, currentCompanyId },
        });
      },
      navigateToAudit: () => {
        navigate({
          to: requestSentAuditRoute.to,
          params: { rfqId, currentCompanyId },
        });
      },
      navigateToVesselPricing: () => {
        navigate({
          to: requestSentVesselPricingRoute.to,
          params: { rfqId, currentCompanyId },
        });
      },
      navigateToAward: () => {
        navigate({
          to: requestSentAwardRoute.to,
          params: { rfqId, currentCompanyId },
        });
      },
      navigateToAwardFlow: () => {
        navigate({
          to: requestSentAwardFlowRoute.to,
          params: { rfqId, currentCompanyId },
        });
      },
      navigateToSupplierBid: (pageId?: string, exchangeId?: string) => {
        if (!structure.newFeaturesDisabled) {
          throw new Error('navigateToSupplierBid can only be called when lots are not enabled');
        }

        if (!recipientId) {
          throw new Error('Cannot navigate to supplier bid routes without a recipientId');
        }

        if (pageId) {
          navigate({
            to: legacyRequestSentRecipientBidPageRoute.to,
            params: { rfqId, currentCompanyId, recipientId, pageId },
            search: exchangeId ? { exchangeId } : undefined,
          });
        } else {
          navigate({
            to: legacyRequestSentRecipientBidIndexRoute.to,
            params: { rfqId, currentCompanyId, recipientId },
          });
        }
      },
      navigateToSupplierBidEvaluation: (pageId?: string, exchangeId?: string) => {
        if (!structure.newFeaturesDisabled) {
          throw new Error('navigateToSupplierBidEvaluation can only be called when lots are not enabled');
        }

        if (!recipientId) {
          throw new Error('Cannot navigate to supplier bid routes without a recipientId');
        }

        if (pageId) {
          navigate({
            to: legacyRequestSentRecipientEvaluationPageRoute.to,
            params: { rfqId, currentCompanyId, recipientId, pageId },
            search: exchangeId ? { exchangeId, recipientId } : undefined,
          });
        } else {
          navigate({
            to: legacyRequestSentRecipientEvaluationIndexRoute.to,
            params: { rfqId, currentCompanyId, recipientId },
          });
        }
      },
      navigateToSupplierTeam: () => {
        if (!structure.newFeaturesDisabled) {
          throw new Error('navigateToSupplierTeam can only be called when lots are not enabled');
        }

        if (!recipientId) {
          throw new Error('Cannot navigate to supplier bid routes without a recipientId');
        }

        navigate({
          to: legacyRequestSentRecipientTeamRoute.to,
          params: { rfqId, currentCompanyId, recipientId },
        });
      },
      navigateToSupplierAudit: () => {
        if (!structure.newFeaturesDisabled) {
          throw new Error('navigateToSupplierAudit can only be called when lots are not enabled');
        }

        if (!recipientId) {
          throw new Error('Cannot navigate to supplier bid routes without a recipientId');
        }

        navigate({
          to: legacyRequestSentRecipientAuditRoute.to,
          params: { rfqId, currentCompanyId, recipientId },
        });
      },
    };
  }, [currentCompanyId, navigate, recipientId, rfqId, structure.newFeaturesDisabled]);

  return (
    <RequestSentNavigationContext.Provider value={navigationFunctions}>
      {children}
    </RequestSentNavigationContext.Provider>
  );
};

const RequestRecipientNavigationProvider = ({ structure, children }: { structure: RfxStructure; children: React.ReactNode }) => {
  const navigate = useNavigate();
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const value = React.useMemo(() => {
    const getBidLinkProps = (
      stageId?: string | null,
      requirementGroupId?: string | null,
      pageId?: string,
      exchangeId?: string,
      sectionId?: string,
      replace = false,
    ) => {
      if (pageId) {
        if (!structure.newFeaturesDisabled) {
          if (stageId) {
            if (requirementGroupId) {
              if (sectionId) {
                return {
                  to: requestReceivedBidStageRequirementPageInstanceSectionRoute.to,
                  params: { rfqId, currentCompanyId, pageId, stageId, requirementGroupId, sectionId },
                  search: exchangeId ? { exchangeId } : undefined,
                  replace,
                };
              } else {
                return {
                  to: requestReceivedBidStageRequirementPageInstanceRoute.to,
                  params: { rfqId, currentCompanyId, pageId, stageId, requirementGroupId },
                  search: exchangeId ? { exchangeId } : undefined,
                  replace,
                };
              }
            } else {
              // Keeping this lonely if to make the distinction between lots and non-lots routes clearer
              // eslint-disable-next-line no-lonely-if
              if (sectionId) {
                return {
                  to: requestReceivedBidStagePageInstanceSectionRoute.to,
                  params: { rfqId, currentCompanyId, pageId, stageId, requirementGroupId, sectionId },
                  search: exchangeId ? { exchangeId } : undefined,
                  replace,
                };
              } else {
                return {
                  to: requestReceivedBidStagePageInstanceRoute.to,
                  params: { rfqId, currentCompanyId, pageId, stageId },
                  search: exchangeId ? { exchangeId } : undefined,
                  replace,
                };
              }
            }
          } else {
            return {
              to: requestReceivedMessagesRoute.to,
              params: { rfqId, currentCompanyId },
              search: exchangeId ? { exchangeId } : undefined,
              replace,
            };
          }
        } else {
          return {
            to: legacyRequestReceivedBidPageRoute.to,
            params: { rfqId, currentCompanyId, pageId },
            search: exchangeId ? { exchangeId } : undefined,
            replace,
          };
        }
      } else if (stageId) {
        if (requirementGroupId) {
          return {
            to: requestReceivedBidStageRequirementPageRoute.to,
            params: { rfqId, currentCompanyId, stageId, requirementGroupId },
          };
        } else {
          return {
            to: requestReceivedBidStageRoute.to,
            params: { rfqId, currentCompanyId, stageId },
            replace,
          };
        }
      } else {
        return {
          to: structure.newFeaturesDisabled
            ? legacyRequestReceivedBidIndexRoute.to
            : requestReceivedBidIndexRoute.to,
          params: { rfqId, currentCompanyId },
          replace,
        };
      }
    };
    const getAwardSummaryLinkProps = () => {
      if (structure.newFeaturesDisabled) {
        throw new Error('navigateToMessages can only be called when lots are enabled');
      }

      return {
        to: requestReceivedAwardSummaryRoute.to,
        params: { currentCompanyId, rfqId },
      } as const;
    };
    const getEvaluationLinkProps = () => {
      throw new Error('evaluation link props cannot be generated on supplier side');
    };
    const getMessagesLinkProps = (sectionId?: string) => {
      if (structure.newFeaturesDisabled) {
        throw new Error('navigateToMessages can only be called when lots are enabled');
      }

      return {
        to: requestReceivedMessagesRoute.to,
        params: { currentCompanyId, rfqId },
        search: sectionId ? { sectionId } : undefined,
      } as const;
    };
    const getTeamLinkProps = (companyId?: string, replace?: boolean) => {
      return {
        to: structure.newFeaturesDisabled
          ? legacyRequestReceivedTeamRoute.to
          : requestReceivedTeamRoute.to,
        params: { rfqId, currentCompanyId },
        search: companyId ? { companyId } : undefined,
        replace,
      } as const;
    };
    const getLegacyBulletinsLinkProps = () => {
      return {
        to: structure.newFeaturesDisabled
          ? legacyRequestReceivedLegacyBulletinsIndexRoute.to
          : requestReceivedLegacyBulletinsIndexRoute.to,
        params: { rfqId, currentCompanyId },
      } as const;
    };
    const getAuditLinkProps = () => {
      return {
        to: structure.newFeaturesDisabled
          ? legacyRequestReceivedAuditRoute.to
          : requestReceivedAuditRoute.to,
        params: { rfqId, currentCompanyId },
      } as const;
    };

    return {
      getBidLinkProps,
      getAwardSummaryLinkProps,
      getEvaluationLinkProps,
      getMessagesLinkProps,
      getTeamLinkProps,
      getLegacyBulletinsLinkProps,
      getAuditLinkProps,

      navigateToBid: (
        stageId?: string | null,
        requirementGroupId?: string | null,
        pageId?: string,
        exchangeId?: string,
        sectionId?: string,
        replace = false,
      ) => {
        navigate(getBidLinkProps(
          stageId,
          requirementGroupId,
          pageId,
          exchangeId,
          sectionId,
          replace,
        ));
      },
      navigateToEvaluation: () => {
        throw new Error('evaluation link props cannot be generated on supplier side');
      },
      navigateToMessages: (sectionId?: string) => {
        navigate(getMessagesLinkProps(sectionId));
      },
      navigateToDetails: () => {
        navigate({
          to: structure.newFeaturesDisabled
            ? legacyRequestReceivedDetailsRoute.to
            : requestReceivedIndexRoute.to,
          params: { currentCompanyId, rfqId },
        });
      },
      navigateToAuction: () => {
        navigate({
          to: legacyRequestReceivedAuctionRoute.to,
          params: { rfqId, currentCompanyId },
        });
      },
      navigateToTeam: (companyId?: string, replace?: boolean) => {
        navigate(getTeamLinkProps(companyId, replace));
      },
      navigateToLegacyBulletins: () => {
        navigate(getLegacyBulletinsLinkProps());
      },
      navigateToAudit: () => {
        navigate(getAuditLinkProps());
      },
      navigateToAward: () => {
        navigate({
          to: structure.newFeaturesDisabled
            ? legacyRequestReceivedAwardRoute.to
            : requestReceivedAwardRoute.to,
          params: { rfqId, currentCompanyId },
        });
      },
    };
  }, [currentCompanyId, navigate, rfqId, structure.newFeaturesDisabled]);

  return (
    <RequestRecipientNavigationContext.Provider value={value}>
      {children}
    </RequestRecipientNavigationContext.Provider>
  );
};

const RequestSentRecipientNavigationProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const recipientId = useRecipientId();

  const value = React.useMemo(() => {
    const getBidLinkProps = (
      stageId?: string | null,
      requirementGroupId?: string | null,
      pageId?: string,
      exchangeId?: string,
      sectionId?: string,
      replace = false,
    ) => {
      if (pageId) {
        if (stageId) {
          if (requirementGroupId) {
            return {
              to: requestSentRecipientBidStageRequirementPageInstanceRoute.to,
              params: { currentCompanyId, rfqId, recipientId, pageId, stageId, requirementGroupId },
              search: exchangeId ? { exchangeId } : undefined,
              replace,
            };
          } else {
            return {
              to: requestSentRecipientBidStagePageInstanceRoute.to,
              params: { currentCompanyId, rfqId, recipientId, pageId, stageId },
              search: exchangeId ? { exchangeId } : undefined,
              replace,
            };
          }
        } else {
          return {
            to: requestSentRecipientMessagesRoute.to,
            params: { currentCompanyId, rfqId, recipientId },
            search: exchangeId ? { exchangeId } : undefined,
            replace,
          };
        }
      } else if (stageId) {
        if (requirementGroupId) {
          return {
            to: requestSentRecipientBidStageRequirementPageRoute.to,
            params: { currentCompanyId, rfqId, recipientId, stageId, requirementGroupId },
          };
        } else {
          return {
            to: requestSentRecipientBidStageRoute.to,
            params: { currentCompanyId, rfqId, recipientId, stageId },
            replace,
          };
        }
      } else {
        return {
          to: requestSentRecipientBidIndexRoute.to,
          params: { currentCompanyId, rfqId, recipientId },
          replace,
        };
      }
    };
    const getAwardSummaryLinkProps = () => {
      return {
        to: requestSentRecipientAwardSummaryRoute.to,
        params: { currentCompanyId, rfqId, recipientId },
      } as const;
    };
    const getEvaluationLinkProps = (pageId?: string, exchangeId?: string) => {
      if (!recipientId) {
        throw new Error('Cannot navigate to supplier bid routes without a recipientId');
      }

      if (pageId) {
        return {
          to: requestSentRecipientEvaluationPageRoute.to,
          params: { rfqId, currentCompanyId, recipientId, pageId },
          search: exchangeId ? { exchangeId, recipientId } : undefined,
        };
      } else {
        return {
          to: requestSentRecipientEvaluationIndexRoute.to,
          params: { rfqId, currentCompanyId, recipientId },
        };
      }
    };
    const getMessagesLinkProps = (sectionId?: string) => {
      return {
        to: requestSentRecipientMessagesRoute.to,
        params: { currentCompanyId, rfqId, recipientId },
        search: sectionId ? { sectionId } : undefined,
      } as const;
    };
    const getTeamLinkProps = (companyId?: string, replace?: boolean) => {
      return {
        to: requestSentRecipientTeamRoute.to,
        params: { currentCompanyId, rfqId, recipientId },
        search: companyId ? { companyId } : undefined,
        replace,
      } as const;
    };
    const getLegacyBulletinsLinkProps = () => {
      throw new Error('Sender routes don\'t include recipient-specific legacy bulletings route');
    };
    const getAuditLinkProps = () => {
      return {
        to: requestSentRecipientAuditRoute.to,
        params: { currentCompanyId, rfqId, recipientId },
      } as const;
    };

    return {
      getBidLinkProps,
      getAwardSummaryLinkProps,
      getEvaluationLinkProps,
      getMessagesLinkProps,
      getTeamLinkProps,
      getLegacyBulletinsLinkProps,
      getAuditLinkProps,

      navigateToBid: (
        stageId?: string | null,
        requirementGroupId?: string | null,
        pageId?: string,
        exchangeId?: string,
        sectionId?: string,
        replace = false,
      ) => {
        navigate(getBidLinkProps(
          stageId,
          requirementGroupId,
          pageId,
          exchangeId,
          sectionId,
          replace,
        ));
      },
      navigateToEvaluation: (pageId?: string, exchangeId?: string) => {
        navigate(getEvaluationLinkProps(pageId, exchangeId));
      },
      navigateToMessages: (sectionId?: string) => {
        navigate(getMessagesLinkProps(sectionId));
      },
      navigateToDetails: () => {
        throw new Error('Sender routes don\'t include recipient-specific details route');
      },
      navigateToAuction: () => {
        navigate({
          to: requestSentAuctionRoute.to,
          params: { rfqId, currentCompanyId },
        });
      },
      navigateToTeam: (companyId?: string, replace?: boolean) => {
        navigate(getTeamLinkProps(companyId, replace));
      },
      navigateToLegacyBulletins: () => {
        throw new Error('Sender routes don\'t include recipient-specific legacy bulletings route');
      },
      navigateToAudit: () => {
        navigate(getAuditLinkProps());
      },
      navigateToAward: () => {
        throw new Error('Sender routes don\'t include recipient-specific award route');
      },
    };
  }, [currentCompanyId, recipientId, navigate, rfqId]);

  return (
    <RequestRecipientNavigationContext.Provider value={value}>
      {children}
    </RequestRecipientNavigationContext.Provider>
  );
};

export const router = createRouter({
  routeTree,
  context: {
    queryClient: {} as QueryClient,
    api: {} as ApiClient,
  },
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
  interface HistoryState {
    from?: string;
  }
  interface StaticDataRouteOption {
    isTrackingDisabled?: boolean;
  }
}
