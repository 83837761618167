import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { useNetworkNavigation } from '../../AppRouting';
import * as Layout from '../../Layout';
import { useUserFlags } from '../../UserFlagsContext';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useApi, wrap } from '../../api';

export const NetworkLayout = ({ selectedTabId, children }) => {
  const { t } = useTranslation(['translation', 'general']);
  const { navigateToInternalNetwork, navigateToExternalNetwork } = useNetworkNavigation();
  const { belongsToPayingCompany } = useUserFlags();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const api = useApi();

  const { data: company } = useQuery(
    ['publicCompany', { companyId: currentCompanyId }],
    wrap(api.getPublicCompany),
  );

  const tabs = useMemo(
    () => [{
      id: 'internal',
      name: (
        <>
          {t('network.companyNetwork', { companyName: company?.name || '' })}
          <Tooltip content={t('network.internalNetworkTooltip')}>
            <Icon ml={2} icon="info-circle" />
          </Tooltip>
        </>
      ),
      navigate: () => navigateToInternalNetwork(),
    }, {
      id: 'external',
      name: (
        <>
          {t('network.companyNetwork', { companyName: 'DeepStream' })}
          <Tooltip content={t('network.externalNetworkTooltip')}>
            <Icon ml={2} icon="info-circle" />
          </Tooltip>
        </>
      ),
      navigate: () => navigateToExternalNetwork(),
    }],
    [company, navigateToInternalNetwork, navigateToExternalNetwork, t],
  );

  return (
    <Layout.HeaderAndContent
      header={
        <Layout.PageHeader
          heading={t('network', { ns: 'general' })}
          icon="sitemap"
          tabs={belongsToPayingCompany ? tabs : undefined}
          selectedTabId={belongsToPayingCompany ? selectedTabId : undefined}
        />
      }
      content={children}
    />
  );
};
