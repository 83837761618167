/**
 * Creates an array of pairs of elements from the given array. [1, 2, 3] -> [[1, 2], [2, 3]]
 */
export function overlappingPairs<T>(array: T[]) {
  const pairs: [T, T][] = [];

  for (let i = 0; i < array.length - 1; i += 1) {
    pairs.push([array[i], array[i + 1]]);
  }

  return pairs;
}
