import * as Sentry from '@sentry/react';
import { get, find } from 'lodash';
import { useState } from 'react';
import * as yup from 'yup';
import { EmailNotificationCategory, EmailNotificationOption, EmailNotificationMap } from '@deepstream/common/user-utils';
import { Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useModalState } from '../../ui/useModalState';
import { RadioField, Fieldset, Legend } from '../../form/Field';
import { ModalForm, ModalFormProps } from '../../ModalForm';
import { PropertyList, Skip } from '../../PropertyList';
import { EditProperty, ValueOrNotSpecified } from './utils';
import { useCurrentUser } from '../../useCurrentUser';
import { useUpdateUserProfile } from './useUserMutation';
import { useSystemFeatureFlags } from '../../systemFeatureFlags';

interface Subscription {
  category: EmailNotificationCategory;
  name: string;
  description: string;
  status: EmailNotificationOption;
}

const defaultEmailNotifications = {
  [EmailNotificationCategory.ACCOUNT]: EmailNotificationOption.ALL,
  [EmailNotificationCategory.COMPANY]: EmailNotificationOption.ALL,
  [EmailNotificationCategory.CONTRACTS]: EmailNotificationOption.ALL,
  [EmailNotificationCategory.FEEDBACK]: EmailNotificationOption.ALL,
  [EmailNotificationCategory.PRE_QUALIFICATION]: EmailNotificationOption.ALL,
  [EmailNotificationCategory.REQUESTS]: EmailNotificationOption.ALL,
  [EmailNotificationCategory.SUPPORT]: EmailNotificationOption.ALL,
};

type ToggleSubscriptionPreferenceModalProps =
  Omit<ModalFormProps, 'heading' | 'initialValues' | 'validationSchema' | 'onSubmit'> &
  {
    subscription: Subscription | undefined;
    emailNotifications: EmailNotificationMap;
    onSuccess: any;
  };

const ToggleSubscriptionPreferenceModal = ({
  subscription,
  emailNotifications,
  onSuccess,
  ...props
}: ToggleSubscriptionPreferenceModalProps) => {
  const { t } = useTranslation();

  const [updateUserProfile] = useUpdateUserProfile({
    fieldName: t('user.notifications.modal.fieldName'),
    onSuccess,
  });

  if (!subscription) return null;

  return (
    <ModalForm
      heading={t('user.notifications.modal.header')}
      initialValues={{
        emailCategory: subscription.category,
        status: subscription.status,
      }}
      validationSchema={yup.object({
        emailCategory: yup.string().required(),
        status: yup.string().required(),
      })}
      onSubmit={async ({ emailCategory, status }, { setSubmitting }) => {
        try {
          await updateUserProfile({
            preferences: {
              emailNotifications: {
                ...emailNotifications,
                [emailCategory]: status,
              },
            },
          });
        } catch (error) {
          Sentry.captureException(error);
        } finally {
          setSubmitting(false);
        }
      }}
      {...props}
    >
      <Fieldset>
        <Legend mb={3}>{subscription.name}</Legend>
        <Stack gap={2}>
          <RadioField
            name="status"
            label={t('general.on')}
            description={t('user.notifications.description.on')}
            value={EmailNotificationOption.ALL}
          />
          <RadioField
            name="status"
            label={t('general.off')}
            description={t('user.notifications.description.off')}
            value={EmailNotificationOption.NONE}
          />
        </Stack>
      </Fieldset>
    </ModalForm>
  );
};

const SubscriptionBody = ({ description, isActive }) => {
  const { t } = useTranslation();

  return (
    <Stack gap={1}>
      <Flex alignItems="center">
        <Icon icon={isActive ? 'check' : 'times'} mr={2} />
        <Text>{isActive ? t('general.on') : t('general.off')}</Text>
      </Flex>

      <Text fontSize={1} color="subtext">{description}</Text>
    </Stack>
  );
};

export const EmailNotificationsPanel = () => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const toggleSubscriptionPreferenceModal = useModalState();
  const systemFeatureFlags = useSystemFeatureFlags({ required: true });

  const emailNotifications = get(user, 'preferences.emailNotifications', defaultEmailNotifications);

  const emailSubscriptions: Subscription[] = Object
    .values(EmailNotificationCategory)
    .filter(category =>
      category !== EmailNotificationCategory.CONTRACTS || systemFeatureFlags.contractManagementEnabled,
    )
    .map(category => ({
      category,
      name: t(`user.notifications.${category}`),
      description: t(`user.notifications.description.${category}`),
      status: emailNotifications
        ? emailNotifications[category] ?? EmailNotificationOption.ALL
        : EmailNotificationOption.ALL,
    }));

  const [selectedSubscription, setSelectedSubscription] = useState(emailSubscriptions[0]);

  const openEditModal = (category) => {
    const subscription = find(emailSubscriptions, { category });
    if (!subscription) return;
    setSelectedSubscription(subscription);
    toggleSubscriptionPreferenceModal.open();
  };

  return (
    <>
      <Panel heading={t('general.email')}>
        <PropertyList
          DefaultComponent={ValueOrNotSpecified}
          properties={emailSubscriptions.map(subscription => ({
            name: t(`user.notifications.${subscription.category}`),
            value: <SubscriptionBody description={subscription.description} isActive={subscription.status === EmailNotificationOption.ALL} />,
            heightAuto: true,
          }))}
        >
          <Skip />
          <EditProperty mt={3} onClick={() => openEditModal(EmailNotificationCategory.COMPANY)} />
          {systemFeatureFlags.contractManagementEnabled && (
            <EditProperty mt={3} onClick={() => openEditModal(EmailNotificationCategory.CONTRACTS)} />
          )}
          <EditProperty mt={3} onClick={() => openEditModal(EmailNotificationCategory.FEEDBACK)} />
          <EditProperty mt={3} onClick={() => openEditModal(EmailNotificationCategory.PRE_QUALIFICATION)} />
          <EditProperty mt={3} onClick={() => openEditModal(EmailNotificationCategory.REQUESTS)} />
          <EditProperty mt={3} onClick={() => openEditModal(EmailNotificationCategory.SUPPORT)} />
        </PropertyList>
      </Panel>
      <ToggleSubscriptionPreferenceModal
        subscription={selectedSubscription}
        emailNotifications={emailNotifications}
        isOpen={toggleSubscriptionPreferenceModal.isOpen}
        onSuccess={toggleSubscriptionPreferenceModal.close}
        onCancel={toggleSubscriptionPreferenceModal.close}
      />
    </>
  );
};
