import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Flex, Text, Box } from 'rebass/styled-components';
import { HirePeriod } from '@deepstream/common/rfq-utils';
import { ObsoleteIcon } from '@deepstream/ui-kit/elements/icon/Icon';
import { truncateStyle } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { PanelHeader, Panel, PanelDivider, PanelPadding, PanelSubHeader, PanelGroupHeader } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import * as draft from './draft';
import { TextField } from '../form/TextField';
import { CommencementWindowTable, CommencementWindowTableField } from './CommencementWindowTable';
import { DayRatesTable, DayRatesTableField } from './DayRatesTable';
import { HirePeriodPanelActions } from './HirePeriodPanelActions';
import { useHirePeriodsField } from './hirePeriods';
import { DisabledInputBox } from '../ui/Input';
import { FieldContainer } from '../form/FieldContainer';
import * as validation from './validation';
import * as rfx from '../rfx';
import { ErrorMessage } from '../ui/ErrorMessage';

const ViewHirePeriodPanelHeader = ({
  hirePeriod,
  fieldName,
}: {
  hirePeriod: HirePeriod;
  fieldName: string;
}) => {
  const { t } = useTranslation();
  const showValidationErrors = draft.useShowValidationErrors();
  const { error } = validation.useError(`${fieldName}.name`);

  return (
    <PanelHeader
      heading={
        <Box
          style={{ opacity: hirePeriod.isObsolete ? 0.4 : undefined }}
          sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
        >
          {hirePeriod.isObsolete ? (
            <Tooltip content={t('general.obsolete') as string}>
              <ObsoleteIcon mr={2} />
            </Tooltip>
          ) : null}
          {showValidationErrors && error ? (
            <ErrorMessage fontSize="18px" error={error} />
          ) : hirePeriod.name ? (
            <Text as="span" color="text">{hirePeriod.name}</Text>
          ) : (
            <Text as="span" color="subtext">{t('general.untitled')}</Text>
          )}
        </Box>
      }
      bg={showValidationErrors && error ? 'errorBackground' : 'lightGray3'}
    />
  );
};

const ObsoleteTitle = ({ title }) => {
  const { t } = useTranslation();
  const id = useUniqueId();

  return (
    <FieldContainer
      htmlFor={id}
      label={t('general.title')}
      showAsterisk
    >
      <DisabledInputBox
        id={id}
        // quick fix: we can remove setting the height once a line height is
        // set for Input
        sx={{ ...truncateStyle, height: 40 }}
      >
        <Tooltip content={t('general.obsolete') as string}>
          <ObsoleteIcon mr={2} />
        </Tooltip>
        {title}
      </DisabledInputBox>
    </FieldContainer>
  );
};

const EditHirePeriodPanelHeader = ({
  hirePeriod,
  index,
  fieldName,
  isSectionObsolete,
}: {
  hirePeriod: HirePeriod;
  index: number;
  fieldName: string;
  isSectionObsolete?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <PanelPadding bg="lightGray3">
      <Flex alignItems="flex-end" flexDirection="row">
        <Box ml="1px" mr="1px" flex={1}>
          {isSectionObsolete || hirePeriod.isObsolete ? (
            <ObsoleteTitle title={hirePeriod.name} />
          ) : (
            <TextField
              name={`${fieldName}.name`}
              label={t('general.title')}
              required
              // quick fix: we can remove setting the inputStyle height once a line height is
              // set for Input
              inputStyle={{ height: 40 }}
            />
          )}
        </Box>
        {!isSectionObsolete && (
          <Box ml="7px" mr="1px">
            <HirePeriodPanelActions hirePeriod={hirePeriod} index={index} />
          </Box>
        )}
      </Flex>
    </PanelPadding>
  );
};

const AddHirePeriodButton = (props: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button small variant="secondary" iconLeft="plus" {...props}>
      {t('request.vesselPricing.hirePeriods.addHirePeriod')}
    </Button>
  );
};

export const ViewDraftHirePeriodSection = ({
  hirePeriod,
  fieldName,
}: {
  hirePeriod: HirePeriod;
  fieldName: string;
}) => {
  const { t } = useTranslation();

  return (
    <Panel>
      <ViewHirePeriodPanelHeader hirePeriod={hirePeriod} fieldName={fieldName} />
      <PanelDivider />
      <PanelSubHeader heading={t('request.vesselPricing.hirePeriods.commencementWindow')} />
      <PanelDivider />
      <CommencementWindowTable hirePeriod={hirePeriod} fieldName={fieldName} />
      <PanelDivider />
      <PanelSubHeader heading={t('request.vesselPricing.hirePeriods.dayRate_other')} />
      <PanelDivider />
      <DayRatesTable hirePeriodId={hirePeriod._id} />
    </Panel>
  );
};

export const ViewDraftHirePeriodSections = () => {
  const { t } = useTranslation();
  const hirePeriods = rfx.useHirePeriods();
  const showValidationErrors = draft.useShowValidationErrors();
  const { error } = validation.useError('hirePeriods');

  return (
    <Box>
      <PanelGroupHeader
        heading={t('request.vesselPricing.hirePeriods.hirePeriod_other')}
      />
      {showValidationErrors && error ? (
        <PanelPadding bg="errorBackground" py="16px">
          <ErrorMessage fontSize={2} error={error} />
        </PanelPadding>
      ) : isEmpty(hirePeriods) ? (
        <Text color="subtext" fontSize={2} mt={1} mb={1}>
          {t('request.vesselPricing.hirePeriods.noHirePeriodsAdded')}
        </Text>
      ) : (
        <Stack gap="20px">
          {hirePeriods.map((hirePeriod, index) => (
            <ViewDraftHirePeriodSection
              key={hirePeriod._id}
              hirePeriod={hirePeriod}
              fieldName={`hirePeriods[${index}]`}
            />
          ))}
        </Stack>
      )}
    </Box>
  );
};

export const EditDraftHirePeriodSections = ({ isSectionObsolete }: { isSectionObsolete?: boolean }) => {
  const { t } = useTranslation();
  const { isTemplate } = rfx.useState();

  const { hirePeriods, addHirePeriod } = useHirePeriodsField(
    'hirePeriods',
    'intervalsByHirePeriodId',
  );

  return (
    <Box>
      <PanelGroupHeader
        heading={t('request.vesselPricing.hirePeriods.hirePeriod_other')}
      />
      {isEmpty(hirePeriods) ? (
        <Text color="subtext" fontSize={2} mt={1} mb={1}>
          {t('request.vesselPricing.hirePeriods.noHirePeriodsAdded')}
        </Text>
      ) : (
        <Stack gap="20px">
          {(hirePeriods as HirePeriod[]).map((hirePeriod, index) => (
            <Panel key={hirePeriod._id}>
              <EditHirePeriodPanelHeader
                hirePeriod={hirePeriod}
                index={index}
                fieldName={`hirePeriods[${index}]`}
                isSectionObsolete={isSectionObsolete}
              />
              <PanelDivider />
              <PanelSubHeader heading={t('request.vesselPricing.hirePeriods.commencementWindow')} />
              <PanelDivider />
              <PanelPadding style={{ maxWidth: 570 }}>
                <CommencementWindowTableField
                  hirePeriod={hirePeriod}
                  fieldName={`hirePeriods[${index}]`}
                  isTemplate={isTemplate}
                  isSectionObsolete={isSectionObsolete}
                />
              </PanelPadding>
              <PanelDivider />
              <PanelSubHeader heading={t('request.vesselPricing.hirePeriods.dayRate_other')} />
              <PanelDivider />
              <PanelPadding style={{ maxWidth: 570 }}>
                <DayRatesTableField
                  hirePeriod={hirePeriod}
                  fieldName={`intervalsByHirePeriodId.${hirePeriod._id}`}
                  isSectionObsolete={isSectionObsolete}
                />
              </PanelPadding>
            </Panel>
          ))}
        </Stack>
      )}
      {!isSectionObsolete && (
        <AddHirePeriodButton
          type="button"
          onClick={addHirePeriod}
          mt={3}
        />
      )}
    </Box>
  );
};
