import { useApprovalExchange } from '../contract';

import { ApprovalReady } from './ApprovalReady';
import { ApprovalDone } from './ApprovalDone';
import { ApprovalBlocked } from './ApprovalBlocked';
import { ApprovalReview } from './ApprovalReview';
import { ApprovalBlockedDirty } from './ApprovalBlockedDirty';

export const ApprovalPanel = () => {
  // @ts-expect-error ts(2339) FIXME: Property 'isApproved' does not exist on type 'ExchangeSnapshot'.
  const { isApproved, isBlocked, canApprove, needsReview, dirtyExchangeIds } = useApprovalExchange();

  return isApproved ? (
    <ApprovalDone />
  ) : isBlocked ? (
    dirtyExchangeIds.length > 0 ? (
      <ApprovalBlockedDirty />
    ) : (
      <ApprovalBlocked />
    )
  ) : canApprove ? (
    needsReview ? (
      <ApprovalReview />
    ) : (
      <ApprovalReady />
    )
  ) : (
    null
  );
};
