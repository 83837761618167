import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useGridMenuState } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { getCellValue } from '@deepstream/ui-kit/grid/EditableGrid/getCellValue';
import { ACTION_COLUMN_WIDTH } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { DefaultEditableGridStyles } from '@deepstream/ui-kit/grid/EditableGrid/EditableGridStyles';
import { EditableGridDataProvider } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { EditableGrid } from '@deepstream/ui-kit/grid/EditableGrid/EditableGrid';
import { NavigableRange } from '@deepstream/ui-kit/grid/core/utils';
import { DateFormat } from '@deepstream/utils';
import { GridMenu, GridMenuItem } from '@deepstream/ui-kit/grid/EditableGrid/GridMenu';
import { SortConfigProvider } from '@deepstream/ui-kit/grid/EditableGrid/sortConfig';
import { useSortedGridData } from '@deepstream/ui-kit/grid/EditableGrid/useSortedGridData';
import { compact, identity, isEmpty } from 'lodash';
import { SupplierListTriggerSource } from '@deepstream/common';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { ReadOnlyGrid } from '@deepstream/ui-kit/grid/EditableGrid/ReadOnlyGrid';
import { useQueryClient } from 'react-query';
import { useRouter } from '@tanstack/react-router';
import { callAll } from '@deepstream/utils/callAll';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { withProps } from '@deepstream/ui-utils/withProps';
import { SimpleHeader, SortableHeader } from '../../ui/ExchangeDefsGrid/header';
import { useSupplierListData } from './SupplierListProvider';
import { CompanyLogoAndNameCell, DateOrDashCell } from '../../ui/ExchangeDefsGrid/nonValidationAwareValueCell';
import { useMutation } from '../../useMutation';
import { useApi } from '../../api';
import { useToaster } from '../../toast';
import { getExtendedSupplierListsQueryKey, getSupplierListsQueryKey } from './useSupplierLists';
import { getSupplierListQueryKey } from './useSupplierList';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useIsAuthorizedStakeholder } from '../../useIsAuthorizedStakeholder';

const automaticFrozenLeftColumnIds = ['rowNumber'];
const manualFrozenLeftColumnIds = ['name'];

const navigableRange: NavigableRange = {
  startColumnIndex: 0,
  startRowIndex: 1,
  endColumnIndex: 1,
};

const AddedByValueCell = ({
  row,
  column,
}) => {
  const { t } = useTranslation();
  const value = getCellValue(row.original, column.original);

  const { triggerSource } = row.original;

  return (
    <div className="cell-content" style={{ cursor: 'default' }}>
      {triggerSource === SupplierListTriggerSource.USER ? (
        <IconText icon="user" isIconRegular text={value} />
      ) : triggerSource === SupplierListTriggerSource.SYSTEM ? (
        <IconText icon="bolt-auto" isIconRegular text={t('supplierLists.system')} />
      ) : (
        <EmDash />
      )}
    </div>
  );
};

export const SuppliersGrid = ({
  pageIndex,
  pageSize,
  maxGridHeight,
}: {
  pageIndex: number;
  pageSize: number;
  maxGridHeight: number;
}) => {
  const { t } = useTranslation();
  const { toggleMenu, menuReferenceId, dataIndex } = useGridMenuState();
  const { _id: supplierListId, suppliers, rules } = useSupplierListData();
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const router = useRouter();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const isAuthorizedStakeholder = useIsAuthorizedStakeholder();

  const [sortConfig, setSortConfig] = useState({
    columnId: null,
    direction: null,
  });

  const [removeSupplierListCompany] = useMutation(api.removeSupplierListCompany, {
    onSuccess: () => toaster.success(t('supplierLists.toaster.removeSupplierSuccess')),
    onError: () => toaster.error(t('supplierLists.toaster.removeSupplierError')),
    onSettled: callAll(
      () => queryClient.invalidateQueries(getSupplierListsQueryKey(currentCompanyId)),
      () => queryClient.invalidateQueries(getExtendedSupplierListsQueryKey(currentCompanyId)),
      () => queryClient.invalidateQueries(getSupplierListQueryKey(currentCompanyId, supplierListId)),
      () => router.invalidate(),
    ),
  });

  const isManual = isEmpty(rules);
  const canEdit = isManual && !isAuthorizedStakeholder;

  const columns = useMemo(
    () => compact([
      canEdit && {
        _id: 'rowNumber',
        accessorKey: 'rowNumber',
        Header: SimpleHeader,
        label: '',
        ValueCell: () => null,
        toggleMenu,
        width: ACTION_COLUMN_WIDTH,
        fixedWidth: true,
      },
      {
        _id: 'name',
        accessorKey: 'name',
        accessorFn: identity,
        sortAccessorFn: supplier => supplier.name,
        Header: SortableHeader,
        label: t('supplierLists.supplier', { count: 1 }),
        ValueCell: withProps(CompanyLogoAndNameCell, { hasDefaultCursor: true }),
        width: 927 - (canEdit ? ACTION_COLUMN_WIDTH : 0) - (isManual ? 200 : 0),
      },
      isManual && {
        _id: 'addedBy',
        accessorKey: 'addedBy.name',
        Header: SortableHeader,
        label: t('supplierLists.addedBy'),
        ValueCell: AddedByValueCell,
        width: 200,
      },
      {
        _id: 'addedAt',
        accessorKey: 'addedAt',
        Header: SortableHeader,
        label: t('supplierLists.dateAdded'),
        ValueCell: withProps(DateOrDashCell, { style: { cursor: 'default' } }),
        format: DateFormat.DD_MMM_YYYY,
        width: 200,
      },
    ]),
    [t, toggleMenu, canEdit, isManual],
  );

  // @ts-expect-error ts(2345) FIXME: Argument of type '({ _id: string; accessorKey: string; Header: ({ column, info, showFieldTypeIcon, truncate, }: FrozenHeaderCellProps<EditableGridColumn>) => Element; label: string; ValueCell: () => null; ... 4 more ...; format?: undefined; } | { ...; } | { ...; } | { ...; })[]' is not assignable to parameter of type 'EditableGridColumn<any>[]'.
  const sortedSuppliers = useSortedGridData(sortConfig, suppliers, columns);

  const visibleSuppliers = useMemo(
    () => sortedSuppliers.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize),
    [pageIndex, pageSize, sortedSuppliers],
  );

  const onRemove = useCallback(() => {
    // @ts-expect-error ts(2538) FIXME: Type 'null' cannot be used as an index type.
    const supplier = sortedSuppliers[dataIndex];

    removeSupplierListCompany({
      companyId: currentCompanyId,
      supplierListId,
      removedCompanyId: supplier._id,
    });
  }, [currentCompanyId, supplierListId, sortedSuppliers, dataIndex, removeSupplierListCompany]);

  return (
    <SortConfigProvider sortConfig={sortConfig} setSortConfig={setSortConfig}>
      {canEdit ? (
        <DefaultEditableGridStyles
          style={{ width: '100%', height: `min(100vh - 300px, ${maxGridHeight}px)` }}
        >
          <EditableGridDataProvider rowData={[]}>
            <EditableGrid
              frozenLeftColumnIds={automaticFrozenLeftColumnIds}
              // @ts-expect-error ts(2322) FIXME: Type '({ _id: string; accessorKey: string; Header: ({ column, info, showFieldTypeIcon, truncate, }: FrozenHeaderCellProps<EditableGridColumn>) => Element; label: string; ValueCell: () => null; ... 4 more ...; format?: undefined; } | { ...; } | { ...; } | { ...; })[]' is not assignable to type 'EditableGridColumn[]'.
              columns={columns}
              rowData={visibleSuppliers as any}
              onGridClipboardEvent={() => {}}
              navigableRange={navigableRange}
            />
            {menuReferenceId && (
              <GridMenu>
                <GridMenuItem
                  icon="trash"
                  onSelect={onRemove}
                >
                  {t('supplierLists.removeFromList')}
                </GridMenuItem>
              </GridMenu>
            )}
          </EditableGridDataProvider>
        </DefaultEditableGridStyles>
      ) : (
        <DefaultEditableGridStyles
          isReadOnly
          style={{ width: '100%', height: `min(100vh - 300px, ${maxGridHeight}px)` }}
        >
          <ReadOnlyGrid
            frozenLeftColumnIds={manualFrozenLeftColumnIds}
            // @ts-expect-error ts(2322) FIXME: Type '({ _id: string; accessorKey: string; Header: ({ column, info, showFieldTypeIcon, truncate, }: FrozenHeaderCellProps<EditableGridColumn>) => Element; label: string; ValueCell: () => null; ... 4 more ...; format?: undefined; } | { ...; } | { ...; } | { ...; })[]' is not assignable to type 'EditableGridColumn<any>[]'.
            columns={columns}
            rowData={visibleSuppliers as any}
          />
        </DefaultEditableGridStyles>
      )}
    </SortConfigProvider>
  );
};
