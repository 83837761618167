import { Flex } from 'rebass/styled-components';

export const LockedCellContainer = ({
  children,
  width,
}: {
  children: React.ReactNode;
  width: number;
}) => {
  return (
    <Flex
      sx={{ height: '100%', borderRight: 'lightGray', width: `${width}px` }}
      p={2}
    >
      {children}
    </Flex>
  );
};
