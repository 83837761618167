import { useRef, useCallback, useEffect } from 'react';
import { filter, transform, findIndex, find } from 'lodash';
import { Formik, Form, useField, useFormikContext, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import { Text, Flex, Box, SxStyleProp } from 'rebass/styled-components';
import { Milestone, isMilestoneReminder } from '@deepstream/common/contract';

import { IconText, IconTextProps } from '@deepstream/ui-kit/elements/text/IconText';
import { Button, SaveButton, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { DateFormat } from '@deepstream/utils';
import { Datetime2 } from '../../Datetime';
import { LABEL_WIDTH } from '../../draft/SummaryLabelConfigProvider';
import { PropertyList } from '../../PropertyList';

import { useModalState } from '../../ui/useModalState';
import { useContractState, useContractData, useContractActions, useMilestonesList } from './contract';
import { ContractSummaryPanelHeader } from './ContractSummaryPanelHeader';
import { useUpdateCustomMilestones } from './draftContract';
import { MilestoneModal } from './MilestoneModal';
import { LeavePageModal } from '../../draft/LeavePageModal';
import { Bold } from '../../Bold';

const PANEL_ID = 'customMilestones';

const useAssociatedReminders = (milestoneId: string | undefined) => {
  const contract = useContractData();
  const milestoneReminders = filter(
    contract.reminders,
    isMilestoneReminder,
  );

  return transform(
    milestoneReminders,
    (accumulator, reminder) => {
      if (reminder.milestoneId === milestoneId) accumulator.push(reminder._id);
    },
    [] as string[],
  );
};

const InternalOnly = ({
  sx,
  ...rest
}: {
  sx?: SxStyleProp | undefined;
} & Partial<IconTextProps>) => {
  const { t } = useTranslation('general');

  return (
    <IconText
      icon="eye-slash"
      color="subtext"
      text={t('internalOnly')}
      sx={sx}
      {...rest}
    />
  );
};

const NoMilestones = () => {
  const { t } = useTranslation('contracts');

  return (
    <PanelPadding>
      <Text color="subtext">
        {t('customMilestones.emptyList')}
      </Text>
    </PanelPadding>
  );
};

const ContractCustomMilestoneReadView = () => {
  const contract = useContractData();
  // @ts-expect-error ts(2339) FIXME: Property 'isTemplate' does not exist on type 'ContractStateContextType | undefined'.
  const { isTemplate } = useContractState();
  const entries = useMilestonesList({
    milestones: contract.customMilestones,
    Component: (props) => (
      <Flex alignItems="center">
        <Text sx={{ width: LABEL_WIDTH }}>
          <Datetime2 value={props.value} format={DateFormat.DD_MMM_YYYY} />
        </Text>

        {props.internalOnly && (
          <InternalOnly sx={{ width: LABEL_WIDTH }} />
        )}
      </Flex>
    ),
  });

  if (!entries.length) {
    return (
      <NoMilestones />
    );
  }

  return (
    isTemplate ? (
      <PanelPadding>
        {entries.map(milestone => (
          <Box key={milestone._id}>
            <Bold>{milestone.name}</Bold>
            {milestone.internalOnly && <InternalOnly fontSize={1} />}
          </Box>
        ))}
      </PanelPadding>
    ) : (
      <PropertyList properties={entries} />
    )
  );
};

const ContractCustomMilestoneEntries = ({
  milestones,
  onEdit,
}: {
  milestones: Milestone[]
  onEdit: (id: string) => void;
}) => {
  const { t } = useTranslation('contracts');
  // @ts-expect-error ts(2339) FIXME: Property 'isTemplate' does not exist on type 'ContractStateContextType | undefined'.
  const { isTemplate } = useContractState();

  if (!milestones.length) {
    return (
      <NoMilestones />
    );
  }

  return (
    <>
      {milestones.map((milestone) => (
        <Flex alignItems="center" sx={{ gap: 2, padding: '10px 20px' }} key={milestone._id}>
          {isTemplate ? (
            <Box>
              <Bold>{milestone.name}</Bold>
              {milestone.isHidden && <InternalOnly fontSize={1} />}
            </Box>
          ) : (
            <>
              <Box sx={{ width: LABEL_WIDTH }}>
                <Text fontWeight={500}>{milestone.name}</Text>
              </Box>
              <Text sx={{ width: LABEL_WIDTH }}>
                <Datetime2 value={milestone.date} format={DateFormat.DD_MMM_YYYY} />
              </Text>
              {milestone.isHidden && (
                <InternalOnly sx={{ width: LABEL_WIDTH }} />
              )}
            </>
          )}
          <Button
            sx={{ marginLeft: 'auto' }}
            type="button"
            variant="secondary"
            onClick={() => {
              onEdit(milestone._id);
            }}
          >
            {t('customMilestones.editMilestone')}
          </Button>
        </Flex>
      ))}
    </>
  );
};

const ContractCustomMilestoneFieldArray = ({
  milestones,
  onAdd,
  onEdit,
  onRemove,
}: {
  milestones: Milestone[];
  onAdd: (milestone: Milestone) => void;
  onRemove: (index: number) => Milestone;
  onEdit: (index: number, milestone: Milestone) => void;
}) => {
  const { t } = useTranslation('contracts');
  const milestoneModal = useModalState();
  const milestoneEditId = useRef<string | undefined>(undefined);
  const isEditingMilestone = !!milestoneEditId.current;
  const milestoneToEdit = find(
    milestones,
    (entry) => entry?._id === milestoneEditId.current,
  );
  const associatedReminders = useAssociatedReminders(milestoneEditId.current);

  const handleMilestoneDelete = useCallback(() => {
    const removeIndex = findIndex(
      milestones,
      (entry) => entry._id === milestoneEditId.current,
    );
    onRemove(removeIndex);

    milestoneEditId.current = undefined;
  }, [
    milestones,
    onRemove,
  ]);

  const handleMilestoneSave = useCallback((milestone: Milestone) => {
    if (isEditingMilestone) {
      const indexToEdit = findIndex(
        milestones,
        (entry) => entry._id === milestone._id,
      );
      onEdit(indexToEdit, milestone);
    } else {
      onAdd(milestone);
    }
  }, [
    isEditingMilestone,
    milestones,
    onAdd,
    onEdit,
  ]);

  return (
    <>
      <ContractCustomMilestoneEntries
        milestones={milestones}
        onEdit={(id) => {
          milestoneEditId.current = id;
          milestoneModal.open();
        }}
      />
      <PanelDivider />
      <PanelPadding>
        <Button
          small
          type="button"
          iconLeft="plus"
          variant="secondary"
          onClick={milestoneModal.open}
        >
          {t('customMilestones.addNewMilestone')}
        </Button>
      </PanelPadding>

      <MilestoneModal
        hasAssociatedReminders={!!associatedReminders.length}
        milestone={isEditingMilestone ? milestoneToEdit : undefined}
        isOpen={milestoneModal.isOpen}
        close={() => {
          milestoneModal.close();
          milestoneEditId.current = undefined;
        }}
        onDelete={handleMilestoneDelete}
        onSave={handleMilestoneSave}
      />
    </>
  );
};

const ContractCustomMilestoneEditActions = () => {
  const { isSubmitting, dirty, isValid } = useFormikContext();
  const { stopEditing } = useContractActions();

  return (
    <PanelPadding>
      <Flex justifyContent="flex-end">
        <CancelButton onClick={stopEditing} mr={2} />
        <SaveButton disabled={isSubmitting || !dirty || !isValid} />
      </Flex>
      <LeavePageModal />
    </PanelPadding>
  );
};

const MilestonesFormContainer = () => {
  const [{ value: milestones }] = useField('milestones');

  useEffect(() => {
    milestones.sort((a, b) => (a.date > b.date ? 1 : -1));
  }, [milestones]);

  return (
    <Form>
      <FieldArray
        name="milestones"
        render={({ push, replace, remove }) => (
          <ContractCustomMilestoneFieldArray
            milestones={milestones}
            onEdit={replace}
            onAdd={push}
            // @ts-expect-error ts(2322) FIXME: Type '<X = any>(index: number) => X | undefined' is not assignable to type '(index: number) => Milestone'.
            onRemove={remove}
          />
        )}
      />
      <PanelDivider />
      <ContractCustomMilestoneEditActions />
    </Form>
  );
};

const ContractCustomMilestoneEditView = () => {
  const contract = useContractData();
  const { stopEditing } = useContractActions();
  const [updateCustomMilestones] = useUpdateCustomMilestones();

  const handleOnFormSubmit = useCallback(
    async ({ milestones }) => {
      await updateCustomMilestones({
        customMilestones: milestones,
      }, {
        onSuccess: () => stopEditing(),
      });
    },
    [updateCustomMilestones, stopEditing],
  );

  return (
    <Formik
      initialValues={{ milestones: contract.customMilestones }}
      onSubmit={handleOnFormSubmit}
    >
      <MilestonesFormContainer />
    </Formik>
  );
};

export const ContractCustomMilestonePanel = () => {
  const { t } = useTranslation('contracts');
  const heading = t('customMilestones.heading');
  // @ts-expect-error ts(2339) FIXME: Property 'editingPanelId' does not exist on type 'ContractStateContextType | undefined'.
  const { editingPanelId, isLive, isTemplatePreview } = useContractState();

  const isEditingOtherPanel = editingPanelId && editingPanelId !== PANEL_ID;
  const isEditingThisPanel = editingPanelId && editingPanelId === PANEL_ID;

  return (
    <Panel
      as="section"
      aria-label={heading}
      sx={{
        opacity: isEditingOtherPanel ? 0.5 : 1,
        boxShadow: isEditingThisPanel ? '0 0 8px 0 rgba(0, 0, 0, 0.3)' : '',
      }}
    >
      <ContractSummaryPanelHeader panelId={PANEL_ID} heading={heading} canEdit={!isLive && !isTemplatePreview} />
      <PanelDivider />
      {isEditingThisPanel ? (
        <ContractCustomMilestoneEditView />
      ) : (
        <ContractCustomMilestoneReadView />
      )}
    </Panel>
  );
};
