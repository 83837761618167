import {
  Action,
  ActionType,
  BidStatus,
  ExchangeStatus,
  ExchangeType,
  getEmptyResponseValue,
  getExchangeFieldValue,
  getStageIdFromTag,
  isBuyerReplyField,
  isEmptyResponse,
  isFieldDisabled,
  isSupplierReplyField,
  QuestionExchangeDefinition,
  ReplyFieldConfig,
} from '@deepstream/common/rfq-utils';
import { useCallback, useMemo } from 'react';
import { filter, find, findLast, isNil } from 'lodash';
import { isBiddingOnLot } from '@deepstream/common/rfq-utils/lot';
import { chatOrClarificationExchangeTypes } from '@deepstream/common/exchangesConfig';
import { EvaluationExchangeSnapshot, ExchangeHistoryAction, ExchangeSnapshot, LineItemsExchangeSnapshot } from '../../types';
import { useHaveDeadlinesPassed } from '../../deadline';
import { useIsEnabledAfterDeadline } from '../../ExchangeModal/useIsEnabledAfterDeadline';
import * as rfx from '../../rfx';
import { ContextType, useHooks } from '../../useHooks';
import { useSendExchangeReplyContext } from '../../ExchangeModal/useSendExchangeReply';
import { requestAndLotBidStatusesAllowScoreSubmissions } from '../NewEvaluation/utils';

const separateActions = [
  ActionType.NONE,
  ActionType.RESOLVE,
  ActionType.CLOSE,
];

const useExchangeRespondingShouldBeDisabled = (exchange: ExchangeSnapshot) => {
  const haveDeadlinesPassed = useHaveDeadlinesPassed();
  const isEnabledAfterDeadline = useIsEnabledAfterDeadline();
  const structure = rfx.useStructure();
  const stageId = rfx.useStageId();
  const requirementGroupId = rfx.useRequirementGroupId();
  const bid = rfx.useBid({ required: false });
  // @ts-expect-error ts(2538) FIXME: Type 'null' cannot be used as an index type.
  const lot = structure.lotById[requirementGroupId];

  return (
    (
      stageId &&
      (exchange as LineItemsExchangeSnapshot).responseTag &&
      // @ts-expect-error ts(2345) FIXME: Argument of type '`stage:${string}` | null | undefined' is not assignable to parameter of type '`stage:${string}`'.
      getStageIdFromTag((exchange as LineItemsExchangeSnapshot).responseTag) !== stageId
    ) ||
    (
      requirementGroupId &&
      requirementGroupId !== 'general' &&
      !isBiddingOnLot(lot, bid.intentionStatusByLotId[requirementGroupId])
    ) ||
    (haveDeadlinesPassed && !isEnabledAfterDeadline) ||
    exchange.disabledReason ||
    (
      exchange.def.type === ExchangeType.EVALUATION_CRITERION &&
      !requestAndLotBidStatusesAllowScoreSubmissions(exchange as EvaluationExchangeSnapshot, structure)
    )
  );
};

const useExchangeBidStatus = (exchange: ExchangeSnapshot) => {
  const { bidById } = rfx.useStructure();
  // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
  return bidById[exchange.recipientId]?.status;
};

const shouldHideContractApproveAction = (exchange: ExchangeSnapshot, action: ExchangeHistoryAction, isSuperUserOrOwner: boolean) => {
  return (
    exchange.def.type === ExchangeType.CONTRACT &&
    action.type === ActionType.APPROVE &&
    !isSuperUserOrOwner
  );
};

export const usePossibleActions = (exchange: ExchangeSnapshot) => {
  const { actions } = exchange;
  const { contextType, usePagePermissions, useIsSuperUserOrOwner } = useHooks();
  const pagePermissions = usePagePermissions();
  const [sendExchangeReply] = useSendExchangeReplyContext();
  const isSuperUserOrOwner = useIsSuperUserOrOwner();
  const otherActions = filter(
    actions,
    action => (
      !separateActions.includes(action.type) &&
      !action.hidden &&
      // Hide the "Approve" action for contract exchanges if the user is not a super user or an owner
      !shouldHideContractApproveAction(exchange, action, isSuperUserOrOwner)
    ),
  );
  const markAsResolvedAction = find(actions, { type: ActionType.RESOLVE });
  const markAsAgreedAction = find(actions, { type: ActionType.MARK_AS_AGREED });
  const closeAction = find(actions, { type: ActionType.CLOSE });
  const obsoleteTargetAction = exchange.obsoleteTarget && findLast(exchange.history, { date: exchange.obsoleteTarget.date });

  const useRespondingShouldBeDisabled = useCallback(
    (exchange) => {
      return contextType === ContextType.RFX
        // TODO don't call hook conditionally
        // eslint-disable-next-line react-hooks/rules-of-hooks
        ? useExchangeRespondingShouldBeDisabled(exchange)
        : false;
    },
    [contextType],
  );

  const useIsRequestClosedOrAwarded = useCallback(
    (exchange) => {
      return contextType === ContextType.RFX
        // TODO don't call hook conditionally
        // eslint-disable-next-line react-hooks/rules-of-hooks
        ? [BidStatus.AWARDED, BidStatus.UNSUCCESSFUL].includes(useExchangeBidStatus(exchange))
        : false;
    },
    [contextType],
  );

  const respondingShouldBeDisabled = useRespondingShouldBeDisabled(exchange);
  const isRequestClosedOrAwarded = useIsRequestClosedOrAwarded(exchange);

  // In case of comments we check if hidden is false due to backwards compatibility reasons
  // Hidden = hidden action in the old reply form UI which no longer applies
  const canPostComment = (
    pagePermissions.canComment &&
    find(actions, action => action.type === ActionType.NONE && !action.hidden) &&
    (
      chatOrClarificationExchangeTypes.includes(exchange.def.type) ||
      !isRequestClosedOrAwarded
    )
  );

  const canClearResponseWithObsoleteAction = (
    exchange.obsoleteTarget &&
    !exchange.isObsolete &&
    pagePermissions.canRespond &&
    !respondingShouldBeDisabled
  );

  const canUndoAction = (
    pagePermissions.canRespond &&
    !exchange.isObsolete &&
    obsoleteTargetAction?.canObsolete &&
    separateActions.includes(obsoleteTargetAction.type)
  );

  const clearResponseWithObsoleteAction = useCallback(
    async () => {
      await sendExchangeReply({ value: ActionType.OBSOLETE_ACTION } as Action);
    },
    [sendExchangeReply],
  );

  const clearResponse = useCallback(
    async (selectedAction: ExchangeHistoryAction) => {
      if (exchange.def.type === ExchangeType.QUESTION) {
        await sendExchangeReply({
          value: selectedAction.type,
          response: {
            noAnswer: false,
            value: getEmptyResponseValue(exchange.def as unknown as QuestionExchangeDefinition),
          },
          ...selectedAction.payload,
        });
      } else if (exchange.def.type === ExchangeType.LINE_ITEM) {
        const entries = Object
          .values(exchange.def.fields)
          .filter(exchange.roles.includes('submitter')
            ? ((field): field is ReplyFieldConfig =>
              isSupplierReplyField(field) && !isFieldDisabled(field, exchange as LineItemsExchangeSnapshot))
            : isBuyerReplyField,
          )
          .map(field => field.source.key)
          .map(key => [key, null]);

        await sendExchangeReply({
          value: selectedAction.type,
          ...Object.fromEntries(entries),
          ...selectedAction.payload,
        });
      }
    },
    [exchange, sendExchangeReply],
  );

  const canClearResponse = useMemo(() => {
    if (respondingShouldBeDisabled) {
      return false;
    }
    if (exchange.def.type === ExchangeType.QUESTION) {
      return (
        exchange.status === ExchangeStatus.COMPLETE &&
        exchange.roles.includes('submitter')
      );
    } else if (exchange.def.type === ExchangeType.LINE_ITEM) {
      const isSupplier = exchange.roles.includes('submitter');

      if (isSupplier) {
        return Object
          .values(exchange.def.fields)
          .filter((field): field is ReplyFieldConfig =>
            isSupplierReplyField(field) && !isFieldDisabled(field, exchange as LineItemsExchangeSnapshot))
          .some(field => {
            const value = getExchangeFieldValue(exchange as any, field._id);

            return !isNil(value) && value !== '';
          });
      } else {
        return Object.values(exchange.def.fields)
          .filter(isBuyerReplyField)
          .some(field => {
            const value = getExchangeFieldValue(exchange as any, field._id);

            return !isNil(value) && value !== '';
          });
      }
    } else {
      return false;
    }
  }, [exchange, respondingShouldBeDisabled]);

  const markAsResolved = useCallback(() => {
    if (!markAsResolvedAction) return;

    return sendExchangeReply({
      value: markAsResolvedAction.type,
      ...markAsResolvedAction.payload,
    });
  }, [sendExchangeReply, markAsResolvedAction]);

  const closeChat = useCallback(() => {
    if (!closeAction) return;

    return sendExchangeReply({
      value: closeAction.type,
      ...closeAction.payload,
    });
  }, [sendExchangeReply, closeAction]);

  const markAsAgreed = useCallback(() => {
    if (!markAsAgreedAction) return;

    return sendExchangeReply({
      value: markAsAgreedAction.type,
      ...markAsAgreedAction.payload,
    });
  }, [sendExchangeReply, markAsAgreedAction]);

  const canReusePreviousResponse = useMemo(() => {
    const { status, def, latestNonEmptyResponse, history } = exchange;
    const lastAction = findLast(
      history,
      action => action.type !== ActionType.NONE,
    );
    const isComplete = status === ExchangeStatus.COMPLETE;
    const isQuestion = def.type === ExchangeType.QUESTION;
    const hasImportedSplitQuantity = lastAction.type === 'exchange-imported' && history.some(action => action.type === 'exchange-award-split');

    if (hasImportedSplitQuantity) {
      return true;
    }

    if (contextType !== ContextType.QUESTIONNAIRE || !isQuestion || isComplete || !latestNonEmptyResponse) {
      return false;
    }

    const supplierClearedResponse = lastAction.type === ActionType.SUBMIT && isEmptyResponse(lastAction.response, def);
    const buyerRequiredMoreInfo = lastAction.type === ActionType.REQUIRE_MORE_INFO;

    return supplierClearedResponse || buyerRequiredMoreInfo;
  }, [exchange, contextType]);

  return {
    actions: respondingShouldBeDisabled || !pagePermissions?.canRespond
      ? []
      : otherActions,
    clearResponseWithObsoleteAction: canClearResponseWithObsoleteAction ? clearResponseWithObsoleteAction : undefined,
    clearResponse: canClearResponse ? clearResponse : undefined,
    canReusePreviousResponse,
    markAsResolved: markAsResolvedAction ? markAsResolved : undefined,
    canPostComment,
    closeChat: closeAction ? closeChat : undefined,
    undoAction: canUndoAction ? clearResponseWithObsoleteAction : undefined,
    markAsAgreed: markAsAgreedAction ? markAsAgreed : undefined,
  };
};
