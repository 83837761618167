import { useMemo, memo } from 'react';
import { filter } from 'lodash';
import { Box } from 'rebass/styled-components';
import { ActionType } from '@deepstream/common/rfq-utils';
import { isDocumentExchangeDefinition } from '@deepstream/common/contract';
import { ContractExchangeReplyForm } from '../../ExchangeModal/ContractExchangeReplyForm';
import { useExchange } from '../../useExchange';
import { usePagePermissions, useContractPermissions } from './contract';
import { NoExchangePermissions } from './Permissions/NoExchangePermissions';

const isVisibleAction = (action) => Boolean(action.hidden) === false;

const useExchangeActionsFilter = () => {
  const exchange = useExchange();
  const { canComment, canRespond } = usePagePermissions();
  const { canCounterSign } = useContractPermissions();

  // We must further determine which actions can be selected from within the the form. The returned
  // actions are not representative of *every* enabled action in the exchange. For example, the
  // `obsolete-action` could be enabled, but it is is only submitted by an "undo" button that
  // corresponds with an action (ie: it's not available in the footer).
  return useMemo(() => {
    // Get the initial set of actions that can be selected, before permissions are applied
    const visibleActions = filter(exchange.actions, isVisibleAction);

    // Further refine the actions based on the current user's permissions
    const allowedActions = filter(
      visibleActions,
      (action) => action.type === ActionType.NONE
        ? canComment
        : action.type === ActionType.COUNTERSIGN
          ? canCounterSign
          : canRespond,
    );

    const canClearResponseWithObsoleteAction = (
      exchange.obsoleteTarget &&
      isDocumentExchangeDefinition(exchange.def) &&
      !exchange.isObsolete &&
      canRespond
    );

    return { visibleActions, allowedActions, canClearResponseWithObsoleteAction };
  }, [exchange, canComment, canRespond, canCounterSign]);
};

export const ContractExchangeModalFooter = memo(({ hideReplyForm }: { hideReplyForm?: boolean }) => {
  const { visibleActions, allowedActions, canClearResponseWithObsoleteAction } = useExchangeActionsFilter();

  if (!visibleActions.length) {
    return null;
  }

  if (!allowedActions.length) {
    return (
      <Box px={4} py={3}>
        <NoExchangePermissions />
      </Box>
    );
  }

  if (hideReplyForm) {
    return null;
  }

  return (
    <>
      {visibleActions.length !== allowedActions.length && (
        <Box px={4} py={3}>
          <NoExchangePermissions />
        </Box>
      )}
      <ContractExchangeReplyForm
        actions={allowedActions}
        canClearResponseWithObsoleteAction={canClearResponseWithObsoleteAction}
      />
    </>
  );
});
