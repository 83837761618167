import * as React from 'react';
import { Form, Formik, Field, FieldProps } from 'formik';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { SaveButton, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter } from '@deepstream/ui-kit/elements/popup/Modal';
import { StructuredTagPicker } from '../../../ui/StructuredTagPicker';
import { useToaster } from '../../../toast';
import { useApi } from '../../../api';
import { useMutation } from '../../../useMutation';
import { useGetRegionTags } from '../../../useRegionTags';

type EditModalProps =
  { company: any; onCancel: any; onSave: any } &
  ModalProps;

export const EditLocationsModal: React.FC<EditModalProps> = ({ company, onCancel, onSave, ...props }) => {
  const { t } = useTranslation();
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const getRegionTags = useGetRegionTags();

  const [updateCompany] = useMutation(
    api.updateCompany,
    {
      onSettled: () => queryClient.invalidateQueries(['publicCompany', { companyId: company._id }]),
    },
  );

  return (
    <Modal
      onRequestClose={onCancel}
      style={{ content: { width: '810px' } }}
      {...props}
    >
      <Formik
        initialValues={{
          deliveryCountries: company.deliveryCountries || [],
        }}
        validationSchema={
          yup.object().shape({
            deliveryCountries: yup.array(),
          })
        }
        onSubmit={async ({ deliveryCountries }, { setSubmitting }) => {
          try {
            await updateCompany({
              companyId: company._id,
              companyPatch: {
                deliveryCountries,
              },
            });
            toaster.success(t('company.dialog.editLocationsModal.toaster.profileUpdateSuccess'));
            setSubmitting(false);
            onSave();
          } catch (error) {
            toaster.error(t('company.dialog.editLocationsModal.toaster.profileUpdateError'));
            setSubmitting(false);
            throw error;
          }
        }}
      >
        {({ isSubmitting, touched }) => (
          <Form>
            <ModalHeader onClose={onCancel}>{t('company.dialog.editLocationsModal.heading')}</ModalHeader>
            <ModalBody>
              <Field name="deliveryCountries">
                {({ field, form }: FieldProps) => (
                  <StructuredTagPicker
                    tags={field.value}
                    getTags={getRegionTags}
                    onChange={tags => {
                      const tagIds = tags
                        .filter(tag => tag.level === 3)
                        .map(tag => tag._id);
                      form.setTouched({ [field.name]: true });
                      form.setFieldValue(field.name, tagIds);
                    }}
                    isCaseSensitive
                    searchLabel={t('company.dialog.editLocationsModal.fields.searchLabel')}
                    filterLabel={t('company.dialog.editLocationsModal.fields.filterLabel')}
                    allLevel1CategoryLabel={t('company.dialog.editLocationsModal.fields.allLevel1CategoryLabel')}
                    placeholder={t('company.dialog.editLocationsModal.fields.placeholder')}
                    noResultsText={t('company.dialog.editLocationsModal.fields.noResultsText')}
                  />
                )}
              </Field>
            </ModalBody>
            <ModalFooter>
              <CancelButton mr={2} onClick={onCancel} />
              <SaveButton disabled={isSubmitting || !touched.deliveryCountries} />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
