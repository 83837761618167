import { useTranslation } from 'react-i18next';
import {
  ChangeType,
  SenderAddedChange,
  SenderUpdatedChange,
  SenderRemovedChange,
  SenderChange,
  FieldUpdatedChange,
  CollaboratorInviteStatus,
} from '@deepstream/common/rfq-utils';
import { omit, pick } from 'lodash';
import { diffArrayBy, diffObject } from '@deepstream/utils';
import { callAll } from '@deepstream/utils/callAll';
import { useToaster } from '../toast';
import * as draft from './draft';

const getFieldChanges = (values: any, initialValues: any): FieldUpdatedChange[] => {
  const diffUpdateFields = diffObject(values, initialValues);

  const updatedFieldChanges: FieldUpdatedChange[] = diffUpdateFields.updated
    .filter(item => item.key !== 'senders')
    .map((item) => ({
      type: ChangeType.FIELD_UPDATED,
      fieldName: item.key,
      value: item.value,
    }));

  return updatedFieldChanges;
};

const getSenderChanges = (values, initialValues): SenderChange[] => {
  const diffSenders = diffArrayBy(values, initialValues, 'company._id');

  const sendersAdded: SenderAddedChange[] = diffSenders.added.map((item) => ({
    type: ChangeType.SENDER_ADDED,
    sender: {
      _id: item.company._id,
      role: item.role,
      company: pick(item.company, ['_id', 'name']),
      inviteStatus: CollaboratorInviteStatus.PENDING,
      users: [],
    },
  }));

  const sendersUpdated: SenderUpdatedChange[] = diffSenders.updated.map((item) => ({
    type: ChangeType.SENDER_UPDATED,
    sender: {
      _id: item.company._id,
      role: item.role,
    },
  }));

  const sendersRemoved: SenderRemovedChange[] = diffSenders.removed.map((item) => ({
    type: ChangeType.SENDER_REMOVED,
    senderId: item.company._id,
  }));

  const changes = [...sendersAdded, ...sendersUpdated, ...sendersRemoved];
  return changes;
};

type SummaryMutationArgs = {
  onSuccess?: (...args: any[]) => void;
  onError?: (...args: any[]) => void;
  onSettled?: (...args: any[]) => void;
};

export const useUpdateSummary = ({ onSuccess, onError }: SummaryMutationArgs = {}) => {
  const toaster = useToaster();
  const { t } = useTranslation();

  return draft.useSaveDraftChanges({
    getChanges: ({ values, initialValues }) => [
      ...getFieldChanges(
        omit(values, 'senders'),
        omit(initialValues, 'senders'),
      ),
      ...getSenderChanges(
        values.senders,
        initialValues.senders,
      ),
    ],
    onSuccess: callAll(
      onSuccess,
      () => toaster.success(t('request.toaster.changesSavedSuccess')),
    ),
    onError: callAll(
      onError,
      () => toaster.error(t('request.toaster.changesSavedError')),
    ),
  });
};
