export enum Actions {
  INIT = 'init',
  RESET = 'reset',
  UPDATE_GROUP = 'updateGroup',
  BACK = 'back',
  NEXT = 'next',
  RESOLVE = 'resolve',
  REJECT = 'reject',
  UPDATE_STEPS = 'updateSteps',
}
