import { useTranslation } from 'react-i18next';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { TabListPanel } from '../../ui/TabsVertical';
import RequestTagsTree from './RequestTagsTree';
import RequestTagModal from './RequestTagModal';
import { useModalState } from '../../ui/useModalState';
import { useCanEditRequestTags } from './utils';

const RequestTagsPanel = () => {
  const { t } = useTranslation();
  const addTagModal = useModalState();
  const canEditRequestTags = useCanEditRequestTags();

  const handleNewTagClick = () => {
    addTagModal.open();
  };

  return (
    <>
      <TabListPanel
        heading={t('requests.tags.title')}
        headerButton={
          canEditRequestTags && (
            <Button
              variant="primary-outline"
              iconLeft="plus"
              small
              onClick={handleNewTagClick}
            >
              {t('requests.tags.dropdownNewTag')}
            </Button>
          )
        }
      >
        <RequestTagsTree />
      </TabListPanel>
      <RequestTagModal onClose={addTagModal.close} isOpen={addTagModal.isOpen} />
    </>
  );
};

export default RequestTagsPanel;
