import { useTranslation } from 'react-i18next';
import * as Layout from '../../Layout';
import { CreateContractButton } from './CreateContractButton';

export const ContractsLayout = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <Layout.HeaderAndContent
      header={
        <Layout.PageHeader
          heading={t('pageHeader.contracts')}
          icon="file-contract"
        >
          <CreateContractButton />
        </Layout.PageHeader>
      }
      content={children}
    />
  );
};
