import { useMemo } from 'react';
import { isEmpty, isNil, omitBy, toPairs } from 'lodash';
import { useQueryClient } from 'react-query';
import { useApi } from '@deepstream/ui/api';
import { useSession } from '@deepstream/ui/auth';
import { FullScreenLoading } from '@deepstream/ui/ui/FullScreenLoading';
import { RouterProvider } from '@tanstack/react-router';
import { router } from '@deepstream/ui/AppRouting';

export const Router = () => {
  const { hasCheckedAuth } = useSession();
  const queryClient = useQueryClient();
  const api = useApi();

  const context = useMemo(
    () => ({ queryClient, api }),
    [queryClient, api],
  );

  if (!hasCheckedAuth) {
    return <FullScreenLoading />;
  }

  return (
    <RouterProvider
      basepath="network"
      router={router}
      context={context}
      // We're using custom query params parsing to avoid the out-of-the-box behavior of quoting
      // our company ids (which are integer strings -- presumably to ensure they don't get parsed
      // as numbers?) in the query params (eg: ?companyId="123" vs ?companyId=123) which
      // breaks some components that rely on our custom query params parsing hooks
      stringifySearch={search => {
        const filteredSearch = omitBy(
          search,
          value => Array.isArray(value) ? isEmpty(value) : isNil(value),
        );

        if (isEmpty(filteredSearch)) {
          return '';
        }

        const params = new URLSearchParams();

        for (const [key, value] of toPairs(filteredSearch)) {
          if (Array.isArray(value)) {
            value.forEach(value => params.append(key, value));
          } else {
            params.append(key, value);
          }
        }

        const searchString = `?${params.toString()}`;
        return searchString;
      }}
      parseSearch={search => {
        const urlSearchParams = new URLSearchParams(search);

        const searchObject = {};
        for (const key of urlSearchParams.keys()) {
          const values = urlSearchParams.getAll(key);

          searchObject[key] = values.length > 1
            ? values
            : values[0];
        }

        return searchObject;
      }}
    />
  );
};
