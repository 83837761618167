import * as React from 'react';
import { useQuery } from 'react-query';
import { isEmpty, reject } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { PRect } from '@reach/rect';
import { universalSupplierBidStatuses, supplierBidStatuses, requestStatusesConfig } from '@deepstream/common/rfq-utils/statusConfigs';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { withProps } from '@deepstream/ui-utils/withProps';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { DropdownMenu, DropdownMenuHeader, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { DateFormat } from '@deepstream/utils';
import { useCurrentCompanyId } from '../currentCompanyId';
import { useApi, wrap } from '../api';
import { PageHeading } from './PageHeading';
import { ReceivedRequestOverview, SentRequestOverview } from '../types';
import { useDeviceSize } from '../ui/useDeviceSize';
import { Datetime2 } from '../Datetime';
import { useSystemFeatureFlags } from '../systemFeatureFlags';

const DROPDOWN_WIDTH = 317;
const MAX_DROPDOWN_HEIGHT = 258;

const CaretIcon = () => (
  <Icon
    icon="caret-down"
    ml={2}
    flex={0}
    color="subtext"
    fontSize={5}
  />
);

const useCalculateDropdownPosition = () => {
  const theme = useTheme();

  return React.useMemo(() => {
    return {
      calculateDropdownPositionSmallScreen: (
        targetRect?: PRect | null,
      ): React.CSSProperties => {
        if (!targetRect) {
          return {};
        }

        return {
          position: 'fixed',
          left: theme.space[3],
          width: document.body.clientWidth - 2 * theme.space[3],
          top: targetRect.bottom,
        };
      },

      calculateDropdownPositionLargeScreen: (
        targetRect?: PRect | null,
      ): React.CSSProperties => {
        if (!targetRect) {
          return {};
        }

        // left-align when the targetRect would exceed the left margin
        // of the page, otherwise right-align
        return targetRect.right - theme.space[3] < DROPDOWN_WIDTH
          ? {
            position: 'fixed',
            left: targetRect.left,
            top: targetRect.bottom,
          } : {
            position: 'fixed',
            right: document.body.clientWidth - targetRect.right,
            top: targetRect.bottom,
          };
      },
    };
  }, [theme]);
};

const SentRequestDropdownItem = ({
  request,
  navigateToRequest,
}: {
  request: SentRequestOverview;
  navigateToRequest: (request: SentRequestOverview) => void;
}) => {
  const { t } = useTranslation();

  const { extendedStatus } = request.meta;
  const icon = requestStatusesConfig[extendedStatus]?.icon;

  return (
    <DropdownMenuItem onSelect={() => navigateToRequest(request)}>
      <Flex flexDirection="column">
        <Truncate fontWeight={500} mb="2px" fontSize={2}>
          {request.sentDashboard?.subject || t('requests.untitledRequest')}
        </Truncate>
        <Flex fontSize={1} alignItems="center">
          {icon && <Icon fixedWidth color={icon.color} icon={icon.value} mr={1} />}
          <Text mr="12px" color="subtext">
            {t(`request.status.${extendedStatus}`)}
          </Text>
          <Text color="subtext">
            <Datetime2
              value={request.meta.createdAt}
              format={DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ}
            />
          </Text>
        </Flex>
      </Flex>
    </DropdownMenuItem>
  );
};

const ReceivedRequestDropdownItem = ({
  request,
  navigateToRequest,
}: {
  request: ReceivedRequestOverview;
  navigateToRequest: (request: ReceivedRequestOverview) => void;
}) => {
  const systemFeatureFlags = useSystemFeatureFlags({ required: true });
  const { t } = useTranslation();

  const { bidStatusLabel, icon } = React.useMemo(() => {
    const { bidStatus, universalBidStatus } = request.receivedDashboard;

    return systemFeatureFlags.universalBidStatusEnabled
      ? {
        bidStatusLabel: t(`request.universalSupplierBidStatus.${universalBidStatus}`),
        icon: universalSupplierBidStatuses[universalBidStatus]?.icon,
      }
      : {
        bidStatusLabel: t(`request.supplierBidStatus.${bidStatus}`),
        icon: supplierBidStatuses[bidStatus]?.icon,
      };
  }, [systemFeatureFlags, request, t]);

  return (
    <DropdownMenuItem onSelect={() => navigateToRequest(request)}>
      <Flex flexDirection="column">
        <Truncate fontWeight={500} mb="2px" fontSize={2}>
          {request.receivedDashboard?.subject || t('requests.untitledRequest')}
        </Truncate>
        <Flex fontSize={1} alignItems="center">
          {icon && (
            <Icon fixedWidth color={icon.color} icon={icon.value as IconProps['icon']} mr={1} />
          )}
          <Text mr="12px" color="subtext">
            {bidStatusLabel}
          </Text>
          <Text color="subtext">
            <Datetime2
              value={request.receivedDashboard.issueDate}
              format={DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ}
            />
          </Text>
        </Flex>
      </Flex>
    </DropdownMenuItem>
  );
};

const RequestsDropdown = <T extends { _id: string }>({
  subject,
  requests,
  DropdownItem,
}: {
  subject?: string;
  requests: T[];
  DropdownItem: React.ComponentType<{ request: T }>;
}) => {
  const { t } = useTranslation();
  const { isSmall, isExtraSmall } = useDeviceSize();

  const {
    calculateDropdownPositionSmallScreen,
    calculateDropdownPositionLargeScreen,
  } = useCalculateDropdownPosition();

  return (
    <Box flex={1} ml="-12px">
      <DropdownMenu
        variant="secondary-subtle"
        buttonText={
          <>
            <PageHeading icon="file-text-o" text={subject} />
            <CaretIcon />
          </>
        }
        header={
          <DropdownMenuHeader>
            {t('request.switchRequest')}
          </DropdownMenuHeader>
        }
        menuStyle={{
          padding: '8px 0px',
          width: isSmall || isExtraSmall ? undefined : DROPDOWN_WIDTH,
          maxHeight: MAX_DROPDOWN_HEIGHT,
        }}
        menuZIndex={102}
        sx={{ maxWidth: '100%', alignItems: 'center' }}
        wrapperStyle={{
          maxWidth: '100%',
          flex: '1 1 auto',
        }}
        position={isSmall || isExtraSmall ? (
          calculateDropdownPositionSmallScreen
        ) : (
          calculateDropdownPositionLargeScreen
        )}
      >
        {requests.map(request => (
          <DropdownItem key={request._id} request={request} />
        ))}
      </DropdownMenu>
    </Box>
  );
};

export const RequestHeading = ({
  requestId,
  subject,
  isSender,
  navigateToRequest,
}: {
  requestId: string;
  subject?: string;
  isSender: boolean;
  navigateToRequest: (request: any) => void;
}) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const queryTag = isSender
    ? 'sentUserDashboardRequests'
    : 'receivedUserDashboardRequests';
  const queryFn = (
    isSender
      ? api.getSentUserDashboardRequests
      : api.getReceivedUserDashboardRequests
  ) as ((...args: any[]) => Promise<any[]>);

  const { data: requests, status } = useQuery(
    [queryTag, { currentCompanyId }],
    wrap(queryFn),
  );

  const filteredRequests = React.useMemo(
    () => requests
      ? reject(requests, { _id: requestId })
      : null,
    [requests, requestId],
  );

  const DropdownItem = withProps(
    isSender ? SentRequestDropdownItem : ReceivedRequestDropdownItem,
    { navigateToRequest },
  );

  return status === 'loading' ? (
    // spacer to keep the rest of the header row
    // on the right as long as we're loading
    <Box flex={1} />
  ) : !filteredRequests || isEmpty(filteredRequests) ? (
    <PageHeading icon="file-text-o" text={subject} />
  ) : (
    <RequestsDropdown
      subject={subject}
      requests={filteredRequests}
      DropdownItem={DropdownItem}
    />
  );
};
