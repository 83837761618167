import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useDatetime } from '../../../useDate';
import { useApprovalExchange } from '../contract';
import { ApprovalBox } from './ApprovalBox';
import { RevokeApprovalButton } from './RevokeApprovalButton';

export const ApprovalDone = () => {
  const { t } = useTranslation('contracts');
  // @ts-expect-error ts(2339) FIXME: Property 'acceptance' does not exist on type 'ExchangeSnapshot'.
  const { acceptance } = useApprovalExchange();
  const datetime = useDatetime(acceptance.date);

  return (
    <ApprovalBox
      heading={(
        <>
          <Icon icon="check" color="success" mr={2} />
          {t('approval.done.heading')}
        </>
      )}
      description={t('approval.done.description', {
        user: acceptance.userName,
        date: datetime,
      })}
      action={<RevokeApprovalButton />}
    />
  );
};
