import { AttachmentsQuestionElement } from '@deepstream/common/legacy-pre-q-utils';
import { useField } from 'formik';
import { isEqual } from 'lodash';
import { useCallback } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { FileList } from '../ui/FileList';
import { ContainerLabel, ElementProps, LockedAnswer, Modify } from './common';

export const AttachmentsElement: React.FC<Modify<ElementProps, { element: AttachmentsQuestionElement }>> = ({
  name,
  element,
  answer,
  hideLabel,
  isReadOnly,
  isLocked,
  upload,
  download,
}) => {
  const { t } = useTranslation('companyProfile');
  const [field,, formik] = useField({ name }); // eslint-disable-line no-unused-vars

  const handleChange = useCallback(
    value => {
      if (!isEqual(value, field.value)) {
        formik.setValue(value);
      }
    },
    [formik, field],
  );

  return (
    <ContainerLabel
      label={element.label || (element.labelId ? t(`element.label.${element.labelId}`) : '')}
      hideLabel={hideLabel}
      showAsterisk={element.validation?.required}
    >
      {isReadOnly ? (
        answer?.length ? (
          isLocked ? (
            <LockedAnswer />
          ) : (
            <FileList
              key="readonly"
              initialAttachments={answer}
              isReadOnly={true}
              uploadFn={upload!}
              downloadFn={download}
            />
          )
        ) : (
          <IconText icon="file" text={t('element.attachment.noFilesProvided')} color="gray" gap={2} />
        )
      ) : (
        <FileList
          key="editable"
          initialAttachments={field.value}
          onChange={handleChange}
          isReadOnly={false}
          uploadFn={upload!}
          limit={element.limit}
          downloadFn={download}
        />
      )}
    </ContainerLabel>
  );
};
