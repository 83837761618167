import { ContractLineItemsSectionEditPanel } from './ContractLineItemsSectionEditPanel';
import { ContractLineItemsSectionViewPanel } from './ContractLineItemsSectionViewPanel';
import { useIsEditing } from './draftContract';

/**
 * Displays a panel for the current line items section
 */
export const ContractLineItemsSectionPanel = () => {
  const isEditing = useIsEditing();

  return isEditing ? (
    <ContractLineItemsSectionEditPanel />
  ) : (
    <ContractLineItemsSectionViewPanel />
  );
};
