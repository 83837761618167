import * as React from 'react';
import { ContextType, HooksContext } from '../../../useHooks';
import {
  useExchangeDefById,
  useExchanges,
  usePagePermissions,
  useQuestionnaireData,
  useQuestionnaireId,
  useSummary,
  useStatus,
  useIsSuperUserOrOwner,
  useQuestionnaireActions,
} from './questionnaireUtils';

export const QuestionnaireHooksProvider = ({ children }: { children: React.ReactNode }) => {
  const contextValue = React.useMemo(
    () => ({
      contextType: ContextType.QUESTIONNAIRE,
      useStatus,
      useContextId: useQuestionnaireId,
      useSummary,
      useExchangeDefById,
      useExchanges,
      useShowValidationErrors: () => false,
      useSection: () => null,
      useAuction: () => null,
      usePagePermissions,
      useBidProgress: () => null,
      useCommentNotifications: () => [],
      useRecipients: () => [useQuestionnaireData().recipient],
      useAvailableBulletins: () => [],
      useUpdateBulletin: () => null,
      useActions: useQuestionnaireActions,
      useESign: () => null,
      useIsSuperUserOrOwner,
    }),
    [],
  );

  return (
    // @ts-expect-error ts(2322) FIXME: Type '{ contextType: ContextType; useStatus: () => QuestionnaireStatus; useContextId: { (a: { required: true; }): string; (): string; (a: { required: false; }): string | null; }; ... 14 more ...; useIsSuperUserOrOwner: () => boolean; }' is not assignable to type 'Hooks'.
    <HooksContext.Provider value={contextValue}>
      {children}
    </HooksContext.Provider>
  );
};
