import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { values } from 'lodash';
import { assertDefined, assignSums } from '@deepstream/utils';
import { BidProgress, emptyBidProgressWithPreviousStageResponses, getCompleteResponseItemCount, getTotalResponseItemCount } from '@deepstream/common/bidProgress';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import * as rfx from '../../../rfx';
import * as lotPagesLayout from '../Live/lotPagesLayout';
import { Bold } from '../../../Bold';
import { ProgressPercentage } from '../../../ui/ProgressPercentage';

export const RequestRecipientLotStatusTable = () => {
  const { t } = useTranslation('translation');
  const theme = useTheme();
  const bid = rfx.useBid();
  const stageId = rfx.useStageId();
  const requirementGroupId = rfx.useRequirementGroupId();
  const currentCompanyGroup = rfx.useCurrentCompanyGroup();

  // this component is meant to only be rendered
  // on routes that provide the requirementGroupId
  assertDefined(requirementGroupId, 'requirementGroupId');

  const columns = useMemo(() => {
    return [
      {
        id: 'name',
        title: t('request.statusItem.name'),
        width: '20%',
      },
      {
        id: 'status',
        title: t('request.statusItem.status'),
        width: '20%',
      },
      {
        id: 'description',
        title: t('request.statusItem.description'),
      },
    ];
  }, [t]);

  const rows = useMemo(() => {
    const progress = assignSums([
      { ...emptyBidProgressWithPreviousStageResponses },
      ...values(bid.progressByPageIdByRequirementGroupIdByStageId[stageId]?.[requirementGroupId]),
    ]) as BidProgress;

    const totalResponseItemCount = getTotalResponseItemCount(progress);
    const completeResponseItemCount = getCompleteResponseItemCount(progress);

    return [
      {
        id: 'completion',
        name: requirementGroupId === 'general'
         ? t('request.lots.generalRequirementsCompletion')
         : t('request.lots.lotCompletion'),
        status: totalResponseItemCount === 0 ? (
          <Bold>{t('general.notApplicableShort')}</Bold>
        ) : (
          <Bold>
            <ProgressPercentage
              width={15}
              progress={completeResponseItemCount / totalResponseItemCount}
              colorPrimary={theme.colors.success}
            />
          </Bold>
        ),
        description: totalResponseItemCount === 0
          ? (
            requirementGroupId === 'general'
              ? t('request.lots.noGeneralRequirementsForCompletion')
              : t('request.lots.noRequirementsForCompletion')
          )
          : (
            requirementGroupId === 'general'
              ? t(`request.lots.generalRequirementsCompletionDescription.${currentCompanyGroup}`)
              : t(`request.lots.lotCompletionDescription.${currentCompanyGroup}`)
          ),
      },
    ];
  }, [bid, requirementGroupId, stageId, t, theme.colors.success, currentCompanyGroup]);

  return (
    <lotPagesLayout.Table
      columns={columns}
      rows={rows}
    />
  );
};
