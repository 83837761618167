import * as Layout from '../../../Layout';
import { Header } from './Header';
import { QuestionnaireQuestions } from './QuestionnaireQuestions';
import { QuestionnairePageTab } from './utils';

export const QuestionnaireQuestionsPageContent = ({ exchangeId }: { exchangeId?: string }) => {
  const tabId = QuestionnairePageTab.QUESTIONS;

  return (
    <Layout.HeaderAndContent
      header={
        <Header selectedTabId={tabId} />
      }
      content={
        <QuestionnaireQuestions exchangeId={exchangeId} />
      }
    />
  );
};
