import { noop } from 'lodash';
import * as React from 'react';
import { ButtonProps, Flex, SxStyleProp, Text } from 'rebass/styled-components';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { WrapperButton } from '@deepstream/ui-kit/elements/button/WrapperButton';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { commonButtonProps } from './ClientThemeProvider';
import { Counter } from './ui/Badge';

export const FixedFooter = ({ children }) => {
  return (
    <Flex
      as="footer"
      bg="white"
      justifyContent="space-between"
      sx={{
        position: 'fixed',
        boxShadow: 'large',
        left: 0,
        right: 0,
        bottom: 0,
        height: 64,
        borderTop: 'lightGray2',
      }}
    >
      {children}
    </Flex>
  );
};

type FixedFooterButtonProps = ButtonProps & {
  color?: 'text' | 'primary';
  icon: IconProps['icon'];
  iconRegular?: IconProps['regular'];
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: any;
  sx?: SxStyleProp;
  badgeCount?: number;
  actionNeeded?: boolean;
};

export const FixedFooterButton = React.forwardRef<HTMLButtonElement, FixedFooterButtonProps>(({
  color = 'text',
  icon,
  iconRegular = true,
  disabled,
  children,
  onClick = noop,
  sx,
  badgeCount,
  actionNeeded,
  ...props
}, ref) => {
  const theme = useTheme();

  return (
    <WrapperButton
      ref={ref}
      onClick={onClick}
      height="100%"
      disabled={disabled}
      sx={{
        borderRadius: 0,
        '&:hover': disabled
          ? undefined
          : {
            backgroundColor: 'rgba(52, 152, 219, 0.05)',
          },
        // @ts-expect-error ts(2783) FIXME: 'fontFamily' is specified more than once, so this usage will be overwritten.
        fontFamily: theme.fonts.primary,
        ...commonButtonProps,
        ...(disabled ? { cursor: 'default' } : {}),
        ...sx,
      }}
      {...props}
    >
      <Flex
        height="100%"
        alignItems="center"
        px={3}
        sx={{
          ':active:not(:disabled)': {
            transform: !disabled ? 'translateY(1px)' : undefined,
          },
        }}
      >
        <Text fontWeight={500} color={disabled ? 'disabledText' : color} fontSize={2}>
          <Icon icon={icon} regular={iconRegular} mr={2} />
          {children}
        </Text>
        {actionNeeded ? (
          <Icon icon="exclamation-circle" color="danger" ml={2} />
        ) : (
          <Counter count={badgeCount} ml={2} />
        )}
      </Flex>
    </WrapperButton>
  );
});
