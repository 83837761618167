import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { LabeledNumber, LabeledValue } from './LabeledValue';
import { LotSelectItem } from './useLotSelectItems';

export const EvaluationSectionConfigIndicators = ({
  criterionCount,
  relativeSectionWeight,
  selectedLot,
}: {
  criterionCount: number;
  relativeSectionWeight: number;
  selectedLot?: LotSelectItem;
}) => {
  const { t } = useTranslation();

  return (
    <Flex alignItems="center" sx={{ gap: 3, color: selectedLot?.isObsolete ? 'subtext' : 'text' }}>
      {selectedLot && (
        <LabeledValue
          label={t('request.lots.sectionLotConfiguration')}
          value={
            <>
              {selectedLot.isObsolete && <Icon icon="ban" fixedWidth fontSize="inherit" mr="2px" />}
              {selectedLot?.label}
            </>
          }
          small
        />
      )}
      {!selectedLot?.isObsolete && (
        <>
          <LabeledNumber
            label={t('general.criterion_other')}
            number={criterionCount}
            small
          />
          <LabeledNumber
            label={t('general.weight')}
            number={relativeSectionWeight}
            format="percent"
            small
          />
        </>
      )}
    </Flex>
  );
};
