import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { CancelButton, ModalBody, ModalFooter, ModalHeader, SaveButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { QuestionnaireTemplateOverview } from '@deepstream/common/preQual';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { QuestionModalValues } from '../../utils';
import { QuestionnaireTemplatesList } from '../QuestionnaireTemplatesList';

export const ConfirmationModalContent = ({
  templatesWithQuestion,
  onCancel,
}: {
  templatesWithQuestion: QuestionnaireTemplateOverview[];
  onCancel: () => void;
}) => {
  const { t } = useTranslation(['preQualification', 'general']);
  const { isSubmitting, submitForm } = useFormikContext<QuestionModalValues>();

  return (
    <>
      <ModalHeader>
        {t('dialog.updateQuestion.header')}
      </ModalHeader>
      <ModalBody>
        <Stack gap={3}>
          <MessageBlock variant="info" mt={0}>
            {t('dialog.updateQuestion.body')}
          </MessageBlock>
          <QuestionnaireTemplatesList templates={templatesWithQuestion} />
        </Stack>
      </ModalBody>
      <ModalFooter>
        <CancelButton onClick={onCancel} />
        <SaveButton
          label={t('update')}
          disabled={isSubmitting}
          onClick={submitForm}
        />
      </ModalFooter>
    </>
  );
};
