import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { filter } from 'lodash';
import { useSupplierLists } from './useSupplierLists';

export const useValidateUniqueName = ({ listId }: { listId?: string }) => {
  const { t } = useTranslation();
  const { supplierLists } = useSupplierLists();

  return useCallback(
    (values) => {
      const otherLists = filter(supplierLists, list => !listId || list._id !== listId);
      const listNames = otherLists.map(list => list.name.trim().toLowerCase());

      if (listNames.includes(values.name?.trim().toLowerCase())) {
        return {
          name: t('supplierLists.configFlow.errors.uniqueListName'),
        };
      }

      return {};
    },
    [supplierLists, listId, t],
  );
};
