import { Draft, Stage } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { Text, TextProps } from 'rebass/styled-components';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { Bold } from '../Bold';

enum Visibility {
  HIDDEN = 'hidden',
  VISIBLE = 'visible',
}

const StageVisibility = ({ visibility }: { visibility: Visibility }) => {
  const { t } = useTranslation();

  return (
    <>
      {visibility === Visibility.HIDDEN ? t('request.stages.hidden') : t('request.stages.visible')}
    </>
  );
};

interface StageWillRemainProps extends TextProps {
  stageNumber: number;
  visibility: Visibility;
}

const StageWillRemain = ({ stageNumber, visibility, ...props }: StageWillRemainProps) => {
  const { t } = useTranslation();

  return (
    <Text {...props}>
      {t('request.stages.stageWillRemain', { stageNumber })}
      {' '}
      <Bold>
        <StageVisibility visibility={visibility} />
      </Bold>
    </Text>
  );
};

interface StageWillChangeProps extends TextProps {
  stageNumber: number;
  from: Visibility;
  to: Visibility;
}

const StageWillChange = ({ stageNumber, from, to, ...props }: StageWillChangeProps) => {
  const { t } = useTranslation();

  return (
    <Text {...props}>
      {t('request.stages.stageWillChange', { stageNumber })}
      {' '}
      {t('general.from')} <Bold><StageVisibility visibility={from} /></Bold>
      {' '}
      {t('general.to')} <Bold><StageVisibility visibility={to} /></Bold>
    </Text>
  );
};

interface ConfirmMoveStageDialogProps {
  stage: Stage<Draft>;
  stageIndex: number;
  affectedStageIndex: number;
  isOpen: boolean;
  onOk: () => void;
  onCancel?: () => void;
}

export const ConfirmMoveStageDialog = ({
  stage,
  stageIndex,
  affectedStageIndex,
  isOpen,
  onOk,
  onCancel,
}: ConfirmMoveStageDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      heading={t('request.stages.dialog.moveStageConfirmation.heading')}
      body={(
        <MessageBlock variant="info" mt={0} maxWidth="470px">
          {stageIndex === 0 ? (
            <>
              <StageWillRemain stageNumber={stageIndex + 1} visibility={Visibility.VISIBLE} mb={1} />
              <StageWillChange
                stageNumber={affectedStageIndex + 1}
                from={Visibility.HIDDEN}
                to={Visibility.VISIBLE}
              />
            </>
          ) : affectedStageIndex === 0 ? (
            <>
              <StageWillChange
                stageNumber={stageIndex + 1}
                from={Visibility.HIDDEN}
                to={Visibility.VISIBLE}
                mb={1}
              />
              <StageWillRemain stageNumber={affectedStageIndex + 1} visibility={Visibility.VISIBLE} />
            </>
          ) : stage.isPrivate ? (
            <>
              <StageWillRemain stageNumber={stageIndex + 1} visibility={Visibility.HIDDEN} mb={1} />
              <StageWillChange
                stageNumber={affectedStageIndex + 1}
                from={Visibility.VISIBLE}
                to={Visibility.HIDDEN}
              />
            </>
          ) : (
            <>
              <StageWillChange
                stageNumber={stageIndex + 1}
                from={Visibility.VISIBLE}
                to={Visibility.HIDDEN}
                mb={1}
              />
              <StageWillRemain stageNumber={affectedStageIndex + 1} visibility={Visibility.HIDDEN} />
            </>
          )}
        </MessageBlock>
      )}
      okButtonText={t('request.stages.moveStage')}
      okButtonVariant="primary"
      cancelButtonText={t('general.cancel')}
      cancelButtonVariant="secondary"
      isOpen={isOpen}
      onOk={onOk}
      onCancel={onCancel}
    />
  );
};
