import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ExchangeType, Live } from '@deepstream/common/rfq-utils';
import { isEmpty } from 'lodash';
import { Flex, Box } from 'rebass/styled-components';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useExchangeColumns } from '../modules/Exchange/columns';
import { usePossibleActions } from '../modules/Exchange/usePossibleActions';
import { ExchangeSnapshot } from '../types';
import * as modal from './layout';
import * as rfx from '../rfx';
import { StandaloneExchangeFields } from '../ExchangeTable';
import { InlineExchangeReply } from '../modules/Exchange/InlineExchangeReply';
import { DisableExchangeResponsesButton, ReenableExchangeResponsesDropdown } from './ReenableResponsesDropdown';
import { ContextType, useHooks } from '../useHooks';

export type FixedFieldsSectionProps = {
  exchange: ExchangeSnapshot;
  showRecipient?: boolean;
  isReadOnly?: boolean;
  showReenableResponsesButton?: boolean;
  showDisableResponsesButton?: boolean;
};

export const FixedFieldsSection = ({
  exchange,
  showRecipient,
  isReadOnly,
  showReenableResponsesButton,
  showDisableResponsesButton,
}: FixedFieldsSectionProps) => {
  const { t } = useTranslation(['translation', 'general', 'exchangeActionLabel']);
  const [isReplyFormOpen, setIsReplyFormOpen] = useState(false);
  const { actions, markAsResolved, closeChat, undoAction } = usePossibleActions(exchange);
  const { contextType } = useHooks();
  const exchangeColumns = useExchangeColumns();
  const structure = contextType === ContextType.RFX ? rfx.useStructure<Live>() : null;
  const columns = useMemo(() => {
    if (exchange.def.type === ExchangeType.EVALUATION_CRITERION) {
      return [];
    } else if (exchange.def.type === ExchangeType.INTERNAL_DOCUMENT) {
      return structure ? exchangeColumns.internalDocumentModal(structure) : [];
    } else if (exchange.isChat) {
      return exchangeColumns.chatModal({ showRecipient });
    } else if (exchange.def.type === ExchangeType.BULLETIN) {
      return exchangeColumns.bulletinModal({ showAttachments: exchange.def.attachments && !isEmpty(exchange.def.attachments) });
    } else {
      return exchangeColumns.recipientAndStatusSection({ showRecipient });
    }
  }, [exchange.def, exchange.isChat, exchangeColumns, showRecipient, structure]);
  const canTakeAction = actions.length > 0 && [ExchangeType.INTERNAL_DOCUMENT, ExchangeType.BULLETIN].includes(exchange.def.type);

  const handleOnClose = () => {
    setIsReplyFormOpen(false);
  };

  return isEmpty(columns) ? (
    null
  ) : (
    <modal.ClosableSection showHeader={false}>
      <Flex>
        <Box flex={1}>
          {isReplyFormOpen ? (
            <InlineExchangeReply
              actions={actions}
              onClose={handleOnClose}
              isBulletinExchange={exchange.def.type === ExchangeType.BULLETIN}
              columns={columns}
            />
          ) : (
            <StandaloneExchangeFields
              vertical
              small
              exchange={exchange}
              columns={columns}
            />
          )}
        </Box>
        <Flex minWidth="100px" sx={{ gap: 2 }} justifyContent="flex-end">
          {isReadOnly ? (
            null
          ) : (
            <>
              {markAsResolved && !isReplyFormOpen && (
                <Button
                  variant="primary-outline"
                  onClick={markAsResolved}
                  small
                >
                  {t('common.markAsResolved', { ns: 'exchangeActionLabel' })}
                </Button>
              )}
              {closeChat && !isReplyFormOpen && (
                <Button
                  variant="primary-outline"
                  onClick={closeChat}
                  small
                >
                  {t('chat.closeChat', { ns: 'exchangeActionLabel' })}
                </Button>
              )}
              {undoAction && !isReplyFormOpen && (
                <Button
                  variant="primary-outline"
                  onClick={undoAction}
                  small
                >
                  {exchange.isChat
                    ? t('chat.reopenChat', { ns: 'exchangeActionLabel' })
                    : t('undo', { ns: 'general' })}
                </Button>
              )}
              {canTakeAction && !isReplyFormOpen && (
                <Button
                  sx={{ textTransform: 'capitalize' }}
                  variant="secondary-outline"
                  onClick={() => setIsReplyFormOpen(true)}
                  small
                  iconLeft="pencil"
                >
                  {t('edit', { ns: 'general' })}
                </Button>
              )}
              {showReenableResponsesButton && (<ReenableExchangeResponsesDropdown />) }
              {showDisableResponsesButton && (<DisableExchangeResponsesButton />)}
            </>
          )}
        </Flex>
      </Flex>
    </modal.ClosableSection>
  );
};
