import { BidStatus, Live } from '@deepstream/common/rfq-utils';
import { useMachine } from '@xstate/react';
import { map, size } from 'lodash';
import { useEffect } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { callAll } from '@deepstream/utils/callAll';
import { ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { useApi } from '../../../api';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useMutation } from '../../../useMutation';
import * as rfx from '../../../rfx';
import { useRfqId } from '../../../useRfq';
import { useToaster } from '../../../toast';
import { useWaitForRfqUnlock } from '../../../useWaitForUnlock';
import { defaultReinstateRequestValue, twoStepSelectSupplierMachine } from './twoStepSelectSupplierMachine';
import { RequestOutcomeModal } from './RequestOutcomeModal';
import { useReinstateBidsStateToComponent } from './RequestOutcomeModalStates';
import { Actions } from './Actions';

type ReinstateBidsModalProps = {
  recipientsIds: string[];
  onClose: (shouldRefetchRfq: boolean) => void;
} & ModalProps;

const reinstateBidsMachine = twoStepSelectSupplierMachine.withContext(defaultReinstateRequestValue);

export const ReinstateBidsModal: React.FC<ReinstateBidsModalProps> = ({
  onClose,
  recipientsIds,
  ...props
}) => {
  const { t } = useTranslation();
  const api = useApi();
  const toaster = useToaster();
  const reinstateBidsStateToComponent = useReinstateBidsStateToComponent();
  const waitForRfqUnlock = useWaitForRfqUnlock();
  const [reinstateBidsApi] = useMutation(
    (payload) => waitForRfqUnlock({
      command: () => api.reinstateBids(payload),
    }),
    {
      onSuccess: callAll(
        (data, variables) => toaster.success(t('request.suppliersTable.toaster.reinstateBidSuccess', { count: size(variables.outcome.recipientIds) })),
        () => onClose(true),
      ),
      onError: (data, variables) => toaster.error(t('request.suppliersTable.toaster.reinstateBidError', { count: size(variables.outcome.recipientIds) })),
    },
  );
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqId = useRfqId({ required: true });
  const { recipients, bidById } = rfx.useStructure<Live>();

  const reinstateBids = (context: any) => {
    const params = {
      rfqId: context.rfqId,
      companyId: context.companyId,
      outcome: {
        recipientIds: map(context.outcome.suppliers, '_id'),
        message: context.outcome.hasMessage
          ? context.outcome.message
          : undefined,
        attachments: context.outcome.attachments,
      },
    };

    return reinstateBidsApi(params);
  };

  const [current, send] = useMachine(
    reinstateBidsMachine,
    {
      services: { onConfirm: reinstateBids },
    },
  );

  useEffect(
    () => {
      const recipientCompanies = recipients.map(recipient => recipient.company);

      const unsuccessfulSuppliers = recipientCompanies.filter(recipient => (
        bidById[recipient._id]?.status === BidStatus.UNSUCCESSFUL
      ));

      const selectedActiveSuppliers = unsuccessfulSuppliers
        .filter(recipient => recipientsIds.includes(recipient._id));

      send(Actions.INIT, {
        rfqId,
        companyId: currentCompanyId,
        activeSuppliers: unsuccessfulSuppliers,
        inactiveSuppliers: [],
        selectedActiveSuppliers,
        outcome: {},
      });
    },
    [currentCompanyId, send, props.isOpen, recipientsIds, rfqId, recipients, bidById],
  );

  return (
    <RequestOutcomeModal
      modalTitle={t('request.suppliersTable.reinstateBid')}
      isOpen={props.isOpen}
      stateComponents={reinstateBidsStateToComponent}
      send={send}
      state={current}
      onClose={onClose}
    />
  );
};
