import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { callAll } from '@deepstream/utils/callAll';
import { useRfqId, useDraftRfqStructureQueryKey, useLiveRfqStructureQueryKey } from '../../../useRfq';
import { useApi } from '../../../api';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useToaster } from '../../../toast';
import * as rfx from '../../../rfx';
import { useMutation } from '../../../useMutation';

export const useUpdateSpendAndSavings = () => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { isTemplate } = rfx.useState();
  const rfqId = useRfqId();
  const draftStructureQueryKey = useDraftRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
    isTemplate,
  });
  const liveRfqStructureQueryKey = useLiveRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
  });
  const queryClient = useQueryClient();

  return useMutation(
    (spendAndSavings) => {
      return api.updateSpendAndSavings({
        currentCompanyId,
        rfqId,
        isTemplate,
        spendAndSavings,
      });
    },
    {
      mutationKey: draftStructureQueryKey,
      onSuccess: (_, { enabled }) => enabled === true ? (
        toaster.success(t('request.spendAndSavings.toaster.enableSpendAndSavingsSuccess'))
      ) : enabled === false ? (
        toaster.success(t('request.spendAndSavings.toaster.disableSpendAndSavingsSuccess'))
      ) : (
        toaster.success(t('request.toaster.changesSavedSuccess'))
      ),
      onError: (_, { enabled }) => enabled === true ? (
        toaster.error(t('request.spendAndSavings.toaster.enableSpendAndSavingsError'))
      ) : enabled === false ? (
        toaster.error(t('request.spendAndSavings.toaster.disableSpendAndSavingsError'))
      ) : (
        toaster.error(t('request.toaster.changesSavedError'))
      ),
      onSettled: callAll(
        () => queryClient.invalidateQueries(draftStructureQueryKey),
        () => queryClient.invalidateQueries(liveRfqStructureQueryKey),
      ),
    },
  );
};
