import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass/styled-components';
import { isEqual, map, without } from 'lodash';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { CancelButton, Modal, ModalBody, ModalFooter, ModalHeader, SaveButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { swap } from '@deepstream/utils/swap';
import { useContractData, useGeneralPages } from './contract';
import { useReorderContractPages } from './draftContract';

export const ReorderContractPagesModal = ({
  isOpen,
  onCancel,
  onSuccess,
}: {
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
}) => {
  const { t } = useTranslation('contracts');
  const { pageById, pages } = useContractData();
  const generalPages = useGeneralPages();
  const [reorderPages, { isLoading }] = useReorderContractPages();

  const generalPageIds = useMemo(
    () => map(generalPages, '_id'),
    [generalPages],
  );

  const otherPageIds = useMemo(
    () => {
      const pageIds = map(pages, '_id');

      return without(pageIds, ...generalPageIds);
    },
    [pages, generalPageIds],
  );

  const [pageIds, setPageIds] = useState(generalPageIds);

  useWatchValue(
    generalPageIds,
    setPageIds,
  );

  const movePage = (index, delta) => {
    const newPageIds = swap(pageIds, index, index + delta);
    setPageIds(newPageIds);
  };

  const resetPageOrder = () => setPageIds(generalPageIds);

  const orderHasChanged = useMemo(
    () => !isEqual(generalPageIds, pageIds),
    [generalPageIds, pageIds],
  );

  return isOpen ? (
    <Modal
      shouldCloseOnEsc
      isOpen={isOpen}
      onRequestClose={onCancel}
      style={{ content: { maxWidth: '500px' } }}
    >
      <ModalHeader onClose={onCancel}>
        {t('details.reorderPages')}
      </ModalHeader>
      <ModalBody p={0}>
        <Box
          as="ul"
          p={0}
          sx={{
            listStyle: 'none',
            '> * + *': {
              borderTop: 'lightGray',
            },
          }}
        >
          {pageIds.map((pageId, index) => (
            <Flex
              as="li"
              key={pageId}
              p="12px 16px"
              alignItems="center"
            >
              <Box mr={2} flex={1}>
                <Truncate>
                  {pageById[pageId].name}
                </Truncate>
              </Box>
              <Button
                small
                variant="secondary-outline"
                iconLeft="arrow-up"
                mr={2}
                disabled={index === 0}
                onClick={() => movePage(index, -1)}
              />
              <Button
                small
                variant="secondary-outline"
                iconLeft="arrow-down"
                disabled={index === pageIds.length - 1}
                onClick={() => movePage(index, +1)}
              />
            </Flex>
          ))}
        </Box>
      </ModalBody>
      <ModalFooter>
        <CancelButton
          onClick={() => {
            resetPageOrder();
            onCancel();
          }}
        />
        <SaveButton
          onClick={async () => {
            await reorderPages([...pageIds, ...otherPageIds]);
            onSuccess();
          }}
          disabled={!orderHasChanged || isLoading}
        />
      </ModalFooter>
    </Modal>
  ) : (
    null
  );
};
