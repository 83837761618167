import { Box } from 'rebass/styled-components';
import { ProgressCircle, ProgressCircleProps } from '@deepstream/ui-kit/elements/ProgressCircle';
import { Number } from './Number';

export const ProgressPercentage = ({
  progress,
  width = 12,
  appendix,
  ...props
}: Omit<ProgressCircleProps, 'width'> & {
  width?: number;
  appendix?: string;
}) => {
  return (
    <Box>
      <Box as="span" sx={{ top: '-1px', position: 'relative' }} mr={1}>
        <ProgressCircle width={width} progress={progress} {...props} />
      </Box>
      <Number value={Math.round(100 * progress)} suffix="%" />
      {appendix}
    </Box>
  );
};
