import { Company, Live, LotIntentionStatus, PageType, SectionType } from '@deepstream/common/rfq-utils';
import { compact, first, intersection, isEmpty, some } from 'lodash';
import { useRef, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { InlineButton } from '@deepstream/ui-kit/elements/button/InlineButton';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { ComparisonControlsPanel } from '../ComparisonControlsPanel';
import { QuestionsComparisonGrid, useRowData } from './QuestionsComparisonGrid';
import * as rfx from '../../../../rfx';
import { EmptyTableMessage } from '../../../../ui/EmptyTableMessage';
import { EmptyGridPanel } from '../EmptyGridPanel';
import { useSelectView, View } from '../useSelectView';
import { UseExchangeModalStateProps, useExchangeModalState } from '../../../../useExchangeModalState';
import { useGridState } from '../useGridState';
import { RecipientIdProvider, useRfqId } from '../../../../useRfq';
import { useFilteredRowData } from '../useFilteredRowData';
import { EmptyGridMessagePanel } from '../EmptyGridMessagePanel';
import { useExchangeModalPage } from '../useExchangeModalPage';
import { RequestHooksProvider } from '../../RequestHooksProvider';
import { RfxExchangeModal } from '../../../../ExchangeModal/RfxExchangeModal';
import { ExchangeSnapshot } from '../../../../types';

const useFilteredColumnData = (selectedRequirementGroups: { value: string }[]): Company[] => {
  const structure = rfx.useStructure<Live>();
  const recipients = rfx.useRecipients();

  return useMemo(() => {
    const selectedRequirementGroup = first(selectedRequirementGroups)?.value;

    if (!selectedRequirementGroup || selectedRequirementGroup === 'general') {
      return recipients;
    }

    return recipients.filter(recipient => {
      return structure.bidById[recipient._id]?.intentionStatusByLotId[selectedRequirementGroup] === LotIntentionStatus.BIDDING;
    });
  }, [structure, recipients, selectedRequirementGroups]);
};

const useRequestContainsHiddenRegularPage = () => {
  const structure = rfx.useStructure();
  const hiddenPageIds = rfx.useHiddenPageIds();

  const bidPageIds = structure.pages
    .filter(page => (
      page.type !== PageType.CHAT &&
      page.type !== PageType.EVALUATION
    ))
    .map(page => page._id);

  return !isEmpty(intersection(hiddenPageIds, bidPageIds));
};

const NoQuestionsPanel = ({
  navigateToTeam,
}: {
  navigateToTeam: () => void;
}) => {
  const { t } = useTranslation();

  // Hidden sections are currently not part of live structures. Therefore,
  // we can't determine whether the request contains a question section
  // that is hidden from the user.
  // Since we want to combine the comparison grids into a single grid,
  // this issue will soon disappear. For now, as an approximation, we
  // determine whether the request contains a hidden regular page (which
  // might or might not contain questions) and adjust the message for the
  // user accordingly.
  const requestContainsHiddenRegularPage = useRequestContainsHiddenRegularPage();

  return (
    <EmptyGridPanel>
      <PanelPadding>
        <EmptyTableMessage
          header={requestContainsHiddenRegularPage ? (
            <>
              <Icon icon="eye-slash" mr={2} />
              {t('request.comparison.cantViewContentHeader')}
            </>
          ) : (
            t('request.comparison.noQuestionsToShow')
          )}
          body={requestContainsHiddenRegularPage ? (
            <>
              <Box mb={1}>
                {t('request.comparison.cantViewContentBody1')}
              </Box>
              <Trans i18nKey="request.comparison.cantViewContentBody2">
                Permissions can be updated by an owner on
                the <InlineButton onClick={navigateToTeam}>Team</InlineButton> tab.
              </Trans>
            </>
          ) : (
            t('request.comparison.noQuestionsInRequest')
          )}
        />
      </PanelPadding>
    </EmptyGridPanel>
  );
};

/**
 * Renders a comparison grid with controls displaying the recipients, question
 * exchange definitions and question exchanges of a live request to buyers.
 */
export const QuestionsComparison = ({
  navigateToTeam,
  availableViews,
  selectView,
  isExpandedView,
  setIsExpandedView,
  updateCachedExchange,
}: {
  navigateToTeam: () => void;
  availableViews: {
    evaluation: boolean;
    'line-items': boolean;
    questions: boolean;
  };
  selectView: ReturnType<typeof useSelectView>;
  isExpandedView: boolean;
  setIsExpandedView: (isExpandedView: boolean) => void;
  updateCachedExchange?: (exchange: ExchangeSnapshot) => void;
}) => {
  const { t } = useTranslation('translation');
  const rfqId = useRfqId();
  const structure = rfx.useStructure();
  const rowData = useRowData(structure);
  const [exchangeModalProps, setExchangeModalProps] = useState<UseExchangeModalStateProps>({});
  const exchangeModal = useExchangeModalState(exchangeModalProps);

  const exchangeModalPage = useExchangeModalPage(structure, exchangeModal?.exchangeId);

  const availableRequirementGroupIds = useMemo(() => {
    return compact(rowData.map(row => row.lotId));
  }, [rowData]);

  const gridState = useGridState(rfqId, View.QUESTIONS, availableRequirementGroupIds);

  const hasQuestionSection = some(
    structure.sectionById,
    { type: SectionType.QUESTION },
  );

  const filteredRowData = useFilteredRowData(rowData, gridState.selectRequirementGroups.selectedItems);
  const filteredColumnData = useFilteredColumnData(gridState.selectRequirementGroups.selectedItems);

  const gridRef = useRef<any>();
  useEffect(() => {
    gridRef.current?.resetAfterRowIndex(0);
    gridRef.current?.resetAfterColumnIndex(0);
  }, [structure, filteredRowData, filteredColumnData]);

  return (
    <>
      <ComparisonControlsPanel
        availableViews={availableViews}
        selectView={selectView}
        selectRowHeight={gridState.selectRowHeight}
        isExpandedView={isExpandedView}
        setIsExpandedView={setIsExpandedView}
        selectRequirementGroups={gridState.selectRequirementGroups}
        disabled={!hasQuestionSection || isEmpty(rowData)}
      />
      {!hasQuestionSection ? (
        <NoQuestionsPanel navigateToTeam={navigateToTeam} />
      ) : isEmpty(rowData) ? (
        <EmptyGridMessagePanel
          header={t('request.comparison.noQuestionsToShow')}
          body={t('request.comparison.allQuestionsObsolete')}
        />
      ) : isEmpty(filteredRowData) ? (
        <EmptyGridMessagePanel
          header={t('request.comparison.noQuestionsToShowForLot')}
        />
      ) : (
        <QuestionsComparisonGrid
          gridRef={gridRef}
          rowData={filteredRowData}
          columnData={filteredColumnData}
          subRowHeight={gridState.subRowHeight}
          collapsedRowIds={gridState.collapsedRowIds}
          setCollapsedRowIds={gridState.setCollapsedRowIds}
          setExchangeModalProps={setExchangeModalProps}
        />
      )}
      {exchangeModalPage && exchangeModal.recipientId && (
        <RecipientIdProvider recipientId={exchangeModal.recipientId}>
          <rfx.PageProvider page={exchangeModalPage}>
            <RequestHooksProvider>
              <RfxExchangeModal
                {...exchangeModal}
                showRecipient
                close={() => setExchangeModalProps({})}
                onUpdate={updateCachedExchange}
              />
            </RequestHooksProvider>
          </rfx.PageProvider>
        </RecipientIdProvider>
      )}
    </>
  );
};
