import { Contracts } from './Contracts';
import { ContractsLayout } from './ContractsLayout';

export const ContractsPage = ({ selectedTabId }: { selectedTabId?: string }) => {
  return (
    <ContractsLayout>
      <Contracts selectedTabId={selectedTabId} />
    </ContractsLayout>
  );
};
