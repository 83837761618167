import * as React from 'react';
import { useField } from 'formik';
import { Box } from 'rebass/styled-components';
import { DateTimeQuestionElement } from '@deepstream/common/legacy-pre-q-utils';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { DateFormat } from '@deepstream/utils';
import { Input } from '../ui/Input';
import { ElementProps, Modify, ContainerLabel, LockedAnswer, NoAnswer } from './common';
import { useDeviceSize } from '../ui/useDeviceSize';
import { DatePicker } from '../ui/DatePicker';
import { ErrorMessage, HelperText } from '../form/Field';
import { Datetime2 } from '../Datetime';

type DatetimeElementProps =
  Modify<ElementProps, { element: DateTimeQuestionElement }> &
  {
    showAsterisk?: boolean;
    checkMinDateOnOpen?: boolean;
  };

export const DatetimeElement: React.FC<DatetimeElementProps> = ({
  name,
  element,
  answer,
  hideLabel,
  error,
  showAsterisk,
  isReadOnly,
  isLocked,
  checkMinDateOnOpen,
}) => {
  const { t } = useTranslation('companyProfile');
  const id = useUniqueId();
  const [field,, helpers] = useField({ name }); // eslint-disable-line no-unused-vars
  const { isExtraSmall } = useDeviceSize();
  const dateFormat = element.dateFormat || DateFormat.DD_MMM_YYYY;
  const dateValue = field.value ? new Date(field.value) : field.value;

  // The native date input only accepts these formats
  const nativeInputFormat = React.useMemo(
    () => element.hasTime ? 'yyyy-MM-ddTHH:mm' : 'yyyy-MM-dd',
    [element.hasTime],
  );

  const nativeInputValue = React.useMemo(
    () => field.value
      ? format(new Date(field.value), nativeInputFormat)
      : '',
    [field.value, nativeInputFormat],
  );

  const nativeInputMinValue = element.validation?.min
    ? element.validation.min === 'now'
      ? format(new Date(), nativeInputFormat)
      : format(new Date(element.validation.min), nativeInputFormat)
    : undefined;

  const minDate = element.validation?.min
    ? element.validation.min === 'now'
      ? new Date()
      : new Date(element.validation?.min)
    : undefined;

  const nativeInputMaxValue = element.validation?.max
    ? format(new Date(element.validation.max), nativeInputFormat)
    : undefined;

  const maxDate = element.validation?.max
    ? new Date(element.validation?.max)
    : undefined;

  return (
    <ContainerLabel
      name={name}
      htmlFor={id}
      label={element.label || (element.labelId ? t(`element.label.${element.labelId}`) : '')}
      hideLabel={hideLabel}
      showAsterisk={showAsterisk ?? element.validation?.required}
      width={element.fullWidth ? '100%' : '250px'}
    >
      {isReadOnly ? (
        answer ? (
          isLocked ? (
            <LockedAnswer />
          ) : (
            <Box>
              <Datetime2 value={answer} format={dateFormat as any} />
            </Box>
          )
        ) : (
          <NoAnswer />
        )
      ) : (
        <>
          {isExtraSmall ? (
            <Input
              id={id}
              type={element.hasTime ? 'datetime-local' : 'date'}
              width={element.fullWidth ? '100%' : '250px'}
              disabled={element.disabled}
              min={nativeInputMinValue}
              max={nativeInputMaxValue}
              {...field}
              value={nativeInputValue}
            />
          ) : (
            <DatePicker
              value={dateValue}
              dateFormat={dateFormat}
              onChange={value => {
                const newValue = value
                  ? (value as Date).toISOString()
                  : value;

                helpers.setValue(newValue);
              }}
              disabled={element.disabled}
              showTimeSelect={element.hasTime}
              minDate={minDate}
              maxDate={maxDate}
              checkMinDateOnOpen={checkMinDateOnOpen}
            />
          )}
          {error ? (
            <ErrorMessage error={error} />
          ) : element.helperText ? (
            <HelperText text={element.helperText} />
          ) : (
            null
          )}
        </>
      )}
    </ContainerLabel>
  );
};
