import { useMemo } from 'react';
import { groupBy, mapValues, sum, values, reject, sumBy, pick, isNil, first } from 'lodash';
import { useTranslation } from 'react-i18next';
import { EvaluationCriterionExchangeDefinition, getWeightedAverageScore, isLinkedEvaluationPage, ScoringType, User, RfxEvaluationSection, Live } from '@deepstream/common/rfq-utils';
import { Flex, Heading, Text } from 'rebass/styled-components';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { LabeledNumber, LabeledValue } from './draft/LabeledValue';
import { useExchangeNavigation } from './useExchangeModalState';
import { useExchangeColumns } from './modules/Exchange/columns';
import * as rfx from './rfx';
import { EvaluationExchangeSnapshot, ExchangeSnapshot } from './types';
import { HiddenRequestPagePlaceholder } from './HiddenRequestPagePlaceholder';
import { ExchangeDefFieldValueProvider, useExchangeDefFieldValue } from './ExchangeDefFieldValueContext';
import { useCurrentCompanyId } from './currentCompanyId';
import { useCurrentUser } from './useCurrentUser';
import { ExchangesGrid } from './modules/Request/Live/ExchangesGrid';
import { RequestHooksProvider } from './modules/Request/RequestHooksProvider';
import { useLinkedSection } from './draft/draft';

const EvaluationBidSection = ({
  index,
  exchanges,
}: {
  index: number;
  exchanges: ExchangeSnapshot[];
  exchangesBySectionId: { [key: string]: any[] };
}) => {
  const { t } = useTranslation();
  const { settings, lots, lotById } = rfx.useStructure<Live>();
  const { openExchange } = useExchangeNavigation();
  const section = rfx.useSectionWithPosition<RfxEvaluationSection>();
  const linkedSection = useLinkedSection();
  const exchangeDefs = rfx.useSectionExchangeDefs() as EvaluationCriterionExchangeDefinition[];
  const { getFieldValue } = useExchangeDefFieldValue();
  const { canManageEvaluation } = rfx.useRfxPermissions();
  const scoringType = rfx.useEvaluationScoringType();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const isEvaluator = rfx.usePagePermissions().canRespond;

  const nonObsoleteExchangeDefs = reject(exchangeDefs, 'isObsolete');
  const areSectionExchangeDefsObsolete = exchangeDefs.length > 0 && nonObsoleteExchangeDefs.length === 0;
  const {
    relativeSectionWeightById,
  } = rfx.useEvaluationWeights();
  const pageEvaluators = rfx.usePageEvaluators();

  const maxSectionPoints = sumBy(nonObsoleteExchangeDefs, exchangeDef => exchangeDef.maxPoints);

  const exchangeColumns = useExchangeColumns();
  const columns = useMemo(() => {
    const rowNumberPrefix = `${section.index + 1}.`;

    if (scoringType === ScoringType.SINGLE_SCORE) {
      return exchangeColumns.collaborativeEvaluationGrid(
        exchangeDefs,
        getFieldValue as any,
        pageEvaluators,
        { companyId: currentCompanyId, user: currentUser as unknown as User },
        isEvaluator,
        rowNumberPrefix,
      );
    } else if (canManageEvaluation) {
      return exchangeColumns.ownerEvaluationGrid(
        exchangeDefs,
        getFieldValue as any,
        pageEvaluators,
        { companyId: currentCompanyId, user: currentUser as unknown as User },
        isEvaluator,
        rowNumberPrefix,
      );
    } else {
      return exchangeColumns.teamMemberEvaluationGrid(
        exchangeDefs,
        getFieldValue as any,
        { companyId: currentCompanyId, user: currentUser as unknown as User },
        isEvaluator,
        rowNumberPrefix,
      );
    }
  }, [
    canManageEvaluation,
    exchangeDefs,
    getFieldValue,
    pageEvaluators,
    scoringType,
    currentCompanyId,
    currentUser,
    isEvaluator,
    exchangeColumns,
    section.index,
  ]);

  const lotId = settings.areLotsEnabled
    ? first((linkedSection || section).lotIds)
    : null;

  return (
    <rfx.SectionProvider key={section._id} section={section}>
      <Panel key={section._id}>
        <PanelHeader
          icon="balance-scale"
          heading={
            <Flex>
              <Text color="subtext" mr={2}>
                {index + 1}
              </Text>
              <Text color="text">
                {section.name}
              </Text>
            </Flex>
          }
          description={linkedSection?.description || section.description}
        >
          <Flex alignItems="center">
            {settings.areLotsEnabled && (
              <LabeledValue
                label={t('request.lot', { count: 1 })}
                value={lotId ? (
                  `${lots.findIndex(lot => lot._id === lotId) + 1} – ${lotById[lotId]?.name}`
                ) : (
                  t('request.generalRequirement_other')
                )}
                mr={3}
              />
            )}
            <LabeledNumber
              label={t('general.criterion_other')}
              number={nonObsoleteExchangeDefs.length}
              mr={3}
            />
            <LabeledNumber
              label={t('general.point_other')}
              number={maxSectionPoints}
              mr={3}
            />
            <LabeledNumber
              label={t('general.weight')}
              number={!areSectionExchangeDefsObsolete ? relativeSectionWeightById[section._id] : NaN}
              format="percent"
            />
          </Flex>
        </PanelHeader>
        <PanelDivider />
        <PanelPadding>
          <ExchangesGrid
            exchanges={exchanges as any}
            columns={columns}
            onRowClick={openExchange}
          />
        </PanelPadding>
      </Panel>
    </rfx.SectionProvider>
  );
};

export const EvaluationBidSections = () => {
  const { t } = useTranslation();
  const { pageById } = rfx.useStructure();
  const exchanges = rfx.useExchanges() as EvaluationExchangeSnapshot[];
  const page = rfx.usePage();
  const sections = rfx.usePageSections() as RfxEvaluationSection[];
  const exchangeDefs = rfx.usePageExchangeDefs();
  const {
    absolutePageWeightById,
    relativeSectionWeightById,
    absoluteExchangeDefWeightById,
  } = rfx.useEvaluationWeights();
  const { canManageEvaluation } = rfx.useRfxPermissions();

  const totalCriteria = useMemo(
    () => reject(exchangeDefs, 'isObsolete').length,
    [exchangeDefs],
  );

  const exchangesBySectionId = useMemo(() => {
    const exchangesBySectionId = groupBy(exchanges, 'def.sectionId');

    return pick(exchangesBySectionId, sections.map(section => section._id));
  }, [exchanges, sections]);

  const scoreBySectionId = useMemo(
    () => mapValues(
      exchangesBySectionId,
      exchanges => {
        const nonObsoleteExchanges = reject(exchanges, 'isObsolete');

        const averageScores = nonObsoleteExchanges.map(exchange =>
          getWeightedAverageScore(exchange, absoluteExchangeDefWeightById[exchange._id]),
        );

        return averageScores.some(isNil)
          ? null
          : sum(averageScores);
      },
    ),
    [exchangesBySectionId, absoluteExchangeDefWeightById],
  );

  const totalScore = useMemo(
    () => {
      const scores = values(scoreBySectionId);

      return scores.some(score => isNil(score))
        ? null
        : sum(scores);
    },
    [scoreBySectionId],
  );

  // @ts-expect-error ts(2345) FIXME: Argument of type 'Page | null' is not assignable to parameter of type '{ type?: PageType | undefined; linkedPageId?: string | undefined; }'.
  const pageTitle = isLinkedEvaluationPage(page)
    // @ts-expect-error ts(2339) FIXME: Property 'linkedPageId' does not exist on type 'never'.
    ? pageById[page.linkedPageId].name
    : t('general.summary');

  const allNonObsoleteSectionIds = Object.keys(relativeSectionWeightById);
  const nonObsoleteSections = sections.filter(
    section => allNonObsoleteSectionIds.includes(section._id),
  );

  return (
    <RequestHooksProvider>
      <ExchangeDefFieldValueProvider>
        <Stack gap={4}>
          <Flex justifyContent="space-between">
            <Heading as="h2" fontSize={6} lineHeight="normal" fontWeight="500">
              {pageTitle}
            </Heading>
            <Flex alignItems="center">
              {/*
               // @ts-expect-error ts(2345) FIXME: Argument of type 'Page | null' is not assignable to parameter of type '{ type?: PageType | undefined; linkedPageId?: string | undefined; }'. */}
              {isLinkedEvaluationPage(page) ? (
                <LabeledNumber
                  label={t('general.weight')}
                  // @ts-expect-error ts(2339) FIXME: Property '_id' does not exist on type 'never'.
                  number={absolutePageWeightById[page._id]}
                  format="percent"
                  mr={3}
                />
              ) : (
                null
              )}
              <LabeledNumber
                label={t('general.section_other')}
                number={nonObsoleteSections.length}
                mr={3}
              />
              <LabeledNumber
                label={t('general.criterion_other')}
                number={totalCriteria}
                mr={canManageEvaluation ? 3 : undefined}
              />
              {canManageEvaluation && (
                <LabeledNumber
                  label={t('general.totalScore')}
                  format="score"
                  number={totalScore}
                />
              )}
            </Flex>
          </Flex>
          {sections.map((section, index) => (
            <rfx.SectionProvider key={section._id} section={section}>
              <EvaluationBidSection
                index={index}
                exchanges={exchangesBySectionId[section._id] ?? []}
                exchangesBySectionId={exchangesBySectionId}
              />
            </rfx.SectionProvider>
          ))}
        </Stack>
      </ExchangeDefFieldValueProvider>
    </RequestHooksProvider>
  );
};

export const EvaluationBidPage = () => {
  const pagePermissions = rfx.usePagePermissions();

  return pagePermissions.canRead ? (
    <EvaluationBidSections />
  ) : (
    <HiddenRequestPagePlaceholder />
  );
};
