import { Form, Formik } from 'formik';
import { get } from 'lodash';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { Flex } from 'rebass/styled-components';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { CancelButton, Modal, ModalBody, ModalFooter, ModalHeader, ModalProps, SaveButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useApi } from '../../../api';
import { TextField } from '../../../form/TextField';
import { useToaster } from '../../../toast';
import { useCountryOptions } from '../../../ui/countries';
import { useMutation } from '../../../useMutation';
import { SelectField } from '../../../form/SelectField';
import { phoneNumberRegExp, websiteRegExp } from '../../../utils';

type EditModalProps =
{ company: any; onCancel: any; onSave: any } &
ModalProps;

export const EditContactDetailsModal: React.FC<EditModalProps> = ({ company, onCancel, onSave, ...props }) => {
  const { t } = useTranslation();
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const countryOptions = useCountryOptions();

  const [updateCompany] = useMutation(
    api.updateCompany,
    {
      onSuccess: () => toaster.success(t('company.toaster.updateCompanyProfileSuccess')),
      onError: () => toaster.error(t('company.toaster.updateCompanyProfileError')),
      onSettled: () => queryClient.invalidateQueries(['publicCompany', { companyId: company._id }]),
    },
  );

  return (
    <Modal onRequestClose={onCancel} {...props}>
      <Formik
        initialValues={{
          web: company.web,
          email: company.email,
          telephone: company.telephone,
          lineOne: get(company, 'address.lineOne'),
          lineTwo: get(company, 'address.lineTwo'),
          postcode: get(company, 'address.postcode'),
          city: get(company, 'address.city'),
          state: get(company, 'address.state'),
          country: get(company, 'address.country'),
        }}
        validationSchema={
          yup.object().shape({
            web: yup.string().trim().matches(websiteRegExp, t('company.errors.invalidWebsite')).required(t('general.required')),
            email: yup.string().trim().email(t('company.errors.invalidEmail')).required(t('general.required')),
            telephone: yup.string().matches(phoneNumberRegExp, t('company.errors.invalidPhoneNumber')),
            lineOne: yup.string().trim().required(t('general.required')),
            lineTwo: yup.string(),
            postcode: yup.string().trim().required(t('general.required')),
            city: yup.string().trim().required(t('general.required')),
            state: yup.string(),
            country: yup.string().trim().required(t('general.required')),
          })
        }
        onSubmit={({ web, email, telephone, ...address }) =>
          updateCompany({
            companyId: company._id,
            companyPatch: {
              web: web.toLowerCase(),
              email,
              telephone,
              address,
            },
          }, {
            onSuccess: onSave,
          })
        }
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              {t('company.dialog.editContactDetails.heading')}
            </ModalHeader>
            <ModalBody>
              <Stack gap={3}>
                <TextField name="web" label={t('company.dialog.editContactDetails.fields.web')} required autoCapitalize="off" />
                <TextField name="email" label={t('company.dialog.editContactDetails.fields.email')} inputType="email" required />
                <TextField name="telephone" label={t('company.dialog.editContactDetails.fields.telephone')} inputType="tel" />
                <TextField name="lineOne" label={t('company.dialog.editContactDetails.fields.lineOne')} required />
                <TextField name="lineTwo" label={t('company.dialog.editContactDetails.fields.lineTwo')} />
                <Flex>
                  <TextField name="city" label={t('company.dialog.editContactDetails.fields.city')} required sx={{ mr: 2 }} />
                  <TextField name="state" label={t('company.dialog.editContactDetails.fields.state')} sx={{ mr: 2 }} />
                  <TextField name="postcode" label={t('company.dialog.editContactDetails.fields.postcode')} required />
                </Flex>
                <SelectField
                  required
                  name="country"
                  placeholder={t('company.dialog.editContactDetails.placeholders.country')}
                  label={t('company.dialog.editContactDetails.fields.country')}
                  items={countryOptions}
                  placement="top"
                />
              </Stack>
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} />
              <SaveButton disabled={isSubmitting || !dirty} />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
