import { useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { noop } from 'lodash';
import { Form, Formik } from 'formik';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData } from '../types';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { TextField } from '../../../../form/TextField';
import { Direction } from '../../../../ui/MultiStepFlow/types';

export const AdditionalCommentsStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (data: Partial<AwardFlowData> | null, direction: Direction) => void,
}) => {
  const { t } = useTranslation('translation');

  return (
    <Formik
      validateOnBlur
      initialValues={data.spendAndSavings}
      onSubmit={noop}
    >
      {({ values, dirty }) => (
        <Form>
          <lotPagesLayout.ContentWrapper>
            <StepNavigation
              onBackClick={() => submitAndNavigate(dirty ? { spendAndSavings: values } : null, Direction.BACK)}
              onContinueClick={async () => submitAndNavigate(dirty ? { spendAndSavings: values } : null, Direction.FORWARD)}
            >
              <FormErrors />
              <lotPagesLayout.Section
                heading={t('request.awardFlow.steps.additionalComments.heading')}
              >
                <Text mt="20px">
                  {t('request.awardFlow.steps.additionalComments.description')}
                </Text>
                <Box mt="20px">
                  <TextField
                    name="awardQuestionnaireComment"
                    isMultiLine
                    hideLabel
                    errorMessageStyle={{ fontSize: '14px', marginTop: '16px' }}
                  />
                </Box>
              </lotPagesLayout.Section>
            </StepNavigation>
          </lotPagesLayout.ContentWrapper>
        </Form>
      )}
    </Formik>
  );
};
