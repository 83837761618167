import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import { Form, Formik, FormikProps } from 'formik';
import { AuctionBidFeedbackType, ChangeType, Draft, SectionType, RfxAuctionLineItemsSection } from '@deepstream/common/rfq-utils';
import * as yup from 'yup';
import styled from 'styled-components';
import { find, pick } from 'lodash';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { callAll } from '@deepstream/utils/callAll';
import { CancelButton, EditButton, SaveButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelHeader, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import * as rfx from '../rfx';
import { RadioField } from '../form/RadioField';
import { auctionLineItemSectionKeys } from './draft';
import { LeavePageModal } from './LeavePageModal';
import { AuctionTabId } from './AuctionTabId';

const panelId = AuctionTabId.BID_FEEDBACK;

const BidFeedbackLabel = styled(Text)`
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 18px;
  font-weight: normal;
  color: ${props => props.theme.colors.text};
`;

const BidFeedbackDescription = styled(Text)`
  font-size: ${props => props.theme.fontSizes[1]}px;
  line-height: 15px;
  font-weight: normal;
  color: ${props => props.theme.colors.subtext};
  white-space: pre-wrap;
  margin-top: ${props => props.theme.space[1]}px;
`;

const useBidFeedbackOptions = () => {
  const { t } = useTranslation();

  return useMemo(() => Object.values(AuctionBidFeedbackType).map(type => ({
    value: type,
    label: t(`request.auction.bidFeedback.label.${type}`),
    description: t(`request.auction.bidFeedback.description.${type}`),
  })), [t]);
};

const AuctionConfigBidFeedbackView = () => {
  const { t } = useTranslation();
  const pagePermissions = rfx.usePagePermissions();
  const { startEditing } = rfx.useActions();
  const options = useBidFeedbackOptions();
  const isAuctionEditable = rfx.useIsAuctionEditable();
  const section = rfx.useSection<RfxAuctionLineItemsSection<Draft>>();

  const { auctionRules } = section;
  const selectedOption = options.find(option => option.value === auctionRules.bidFeedback);

  return (
    <>
      <PanelHeader
        heading={t('request.auction.sectionTitle.bidFeedback')}
        description={t('request.auction.sectionDescription.bidFeedback')}
        py={1}
      >
        {(pagePermissions.canEdit && isAuctionEditable) && (
          <EditButton
            small
            onClick={() => startEditing(panelId)}
          />
        )}
      </PanelHeader>
      <PanelDivider />
      <PanelPadding sx={{ whiteSpace: 'pre-wrap' }}>
        {selectedOption ? (
          <>
            <BidFeedbackLabel>{selectedOption.label}</BidFeedbackLabel>
            <BidFeedbackDescription>{selectedOption.description}</BidFeedbackDescription>
          </>
        ) : (
          <Text>
            <EmDash />
          </Text>
        )}
      </PanelPadding>
    </>
  );
};

const AuctionConfigBidFeedbackInnerForm = ({
  isSubmitting,
  isValid,
  dirty,
  resetForm,
}: FormikProps<{ bidFeedback: AuctionBidFeedbackType }>) => {
  const theme = useTheme();
  const { stopEditing } = rfx.useActions();
  const { t } = useTranslation();
  const options = useBidFeedbackOptions();
  rfx.useFormResetOnAuctionStart();

  return (
    <Form style={{ width: '100%' }}>
      <PanelHeader
        heading={t('request.auction.sectionTitle.bidFeedback')}
        description={t('request.auction.sectionDescription.bidFeedback')}
        py={1}
      />
      <PanelDivider />
      <PanelPadding>
        <RadioField
          name="bidFeedback"
          options={options}
          gap={3}
          labelStyle={{
            fontWeight: 'normal',
            color: theme.colors.text,
            whiteSpace: 'pre-wrap',
            lineHeight: '15px',
          }}
        />
      </PanelPadding>
      <PanelDivider />
      <PanelPadding>
        <Flex justifyContent="flex-end">
          <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
          <SaveButton disabled={isSubmitting || !dirty || !isValid} />
        </Flex>
      </PanelPadding>
      <LeavePageModal />
    </Form>
  );
};

const AuctionConfigBidFeedbackForm = () => {
  const { stopEditing } = rfx.useActions();
  const saveChanges = rfx.useSaveChanges();

  const section = rfx.useSection<RfxAuctionLineItemsSection<Draft>>();
  const { auctionRules } = section;

  return (
    <Formik<{
      bidFeedback: AuctionBidFeedbackType;
    }>
      validateOnBlur
      enableReinitialize
      initialValues={{
        bidFeedback: auctionRules.bidFeedback,
      }}
      validationSchema={
        yup.object().shape({
          bidFeedback: yup.string().oneOf(Object.values(AuctionBidFeedbackType)),
        })
      }
      onSubmit={({ bidFeedback }) => saveChanges({
        changes: [{
          type: ChangeType.SECTION_UPDATED,
          section: {
            ...pick(section, auctionLineItemSectionKeys),
            auctionRules: {
              ...section.auctionRules,
              bidFeedback,
            },
          },
        }],
      }, {
        onSuccess: stopEditing,
      })}
      component={AuctionConfigBidFeedbackInnerForm}
    />
  );
};

export const AuctionConfigBidFeedbackPanel = () => {
  const { sectionById } = rfx.useStructure<Draft>();

  const section = find(sectionById, { type: SectionType.AUCTION_LINE_ITEMS });

  const { editingPanelId } = rfx.useState();

  const isEditingThisPanel = editingPanelId === panelId;

  return section ? (
    <rfx.SectionProvider section={section}>
      <Panel>
        {isEditingThisPanel ? (
          <AuctionConfigBidFeedbackForm />
        ) : (
          <AuctionConfigBidFeedbackView />
        )}
      </Panel>
    </rfx.SectionProvider>
  ) : (
    null
  );
};
