import { useMemo, useState, useCallback, Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Flex, Text, Box, TextProps } from 'rebass/styled-components';

import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { QuestionnaireHistoryEvent, getInstructionValue } from '@deepstream/common/preQual';
import { Panel, PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { isEmpty, isNil, omit, omitBy, toPairs } from 'lodash';
import { DateFormat } from '@deepstream/utils';
import { truncateStyle } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Button, DownloadCsvButton } from '@deepstream/ui-kit/elements/button/Button';
import { BLANK } from '@deepstream/common/constants';
import { QuestionType, isQuestionResponseField } from '@deepstream/common/rfq-utils';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { LoadingPanel } from '../../../ui/Loading';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import { useQuestionnaire, useQuestionnaireAudit } from './useQuestionnaire';
import { QuestionnaireProvider, useQuestionnaireId } from './questionnaireUtils';
import { QuestionnairePagePanels } from './QuestionnairePagePanels';
import { BasicTableStyles } from '../../../TableStyles';
import { Table } from '../../../Table';
import { Bold } from '../../../Bold';
import { Datetime2 } from '../../../Datetime';
import { QuestionResponse } from '../../../QuestionResponse';
import { RenewalFrequency } from '../RenewalFrequency';
import useDownload from '../../../useDownload';
import { useCurrentUser, useCurrentUserLocale } from '../../../useCurrentUser';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { ExpiryOffset } from '../ExpiryOffset';

const fieldsToTranslate = ['action', 'questionType', 'previousStatus', 'updatedStatus'];

const isRenewalConfigField = auditTrailFieldKey => ['previousRenewalConfig', 'updatedRenewalConfig'].includes(auditTrailFieldKey);
const isExpiryConfigField = auditTrailFieldKey => ['previousExpiryConfig', 'updatedExpiryConfig', 'expiryConfig'].includes(auditTrailFieldKey);
const isDateField = auditTrailFieldKey => ['approvalDate', 'expiryDate'].includes(auditTrailFieldKey);

const FieldLabel = (props: TextProps) => (
  <Text fontSize={1} color="subtext" mr={1} {...props} sx={{ 'flex': '0 0 auto' }} />
);

const FieldValue = ({
  value,
  fieldKey,
  fields,
  groupPath,
  isMultiLine,
  ...rest
}: {
  fieldKey: string;
  value: any;
  fields: Record<string, any>;
  groupPath?: string;
  isMultiLine?: boolean;
} & TextProps) => {
  const { t } = useTranslation(['preQualification', 'translation']);

  if (value === BLANK) {
    return (
      <Text fontSize={1} mr={2} {...rest}>
        [{t('audit.blank')}]
      </Text>
    );
  }

  if (fieldsToTranslate.includes(fieldKey)) {
    return (
      <Text fontSize={1} mr={2} {...rest}>
        {fieldKey === 'questionType' ? (
          t(`request.question.questionType.${value.toString()}`, { ns: 'translation' })
        ) : ['previousStatus', 'updatedStatus'].includes(fieldKey) ? (
          t(`questionnaireStatus.${value.toString()}`)
        ) : (
          t(`audit.types.${value.toString()}`)
        )}
      </Text>
    );
  }

  if (isQuestionResponseField(fieldKey)) {
    return (
      <Text fontSize={1} mr={2} {...rest}>
        {fields.exchangeDef.questionType === QuestionType.GRID ? (
          t('audit.seeExchange')
        ) : (
          <QuestionResponse
            response={value}
            exchangeDef={fields.exchangeDef}
            isMultiLine={isMultiLine}
          />
        )}
      </Text>
    );
  }

  if (isRenewalConfigField(fieldKey)) {
    return (
      <Text fontSize={1} mr={2} {...rest}>
        <RenewalFrequency
          value={value}
          onlyFrequency
        />
      </Text>
    );
  }

  if (isExpiryConfigField(fieldKey)) {
    return (
      <Text fontSize={1} mr={2} {...rest}>
        <ExpiryOffset value={value} />
      </Text>
    );
  }

  if (['previousInstructions', 'updatedInstructions'].includes(fieldKey)) {
    const instructionsValue = getInstructionValue(value.instructions, value.attachments, t);

    return (
      <Text fontSize={1} mr={2} {...rest}>
        {instructionsValue || <EmDash />}
      </Text>
    );
  }

  if (typeof value === 'boolean') {
    return (
      <Text fontSize={1} mr={2} {...rest}>
        {value ? (
          t('general.yes', { ns: 'translation' })
         ) : (
          t('general.no', { ns: 'translation' })
        )}
      </Text>
    );
  }

  if (isDateField(fieldKey)) {
    return (
      <Text fontSize={1} mr={2} {...rest}>
        {value ? (
          <Datetime2 value={new Date(value)} format={DateFormat.DD_MMM_YYYY} />
        ) : (
          <EmDash />
        )}
      </Text>
    );
  }

  return (
    <Text fontSize={1} mr={2} {...rest}>
      {isNil(value) || value === '' ? <EmDash /> : value}
    </Text>
  );
};

const CustomRowCells = ({
  row,
}) => {
  const { t } = useTranslation(['preQualification', 'general', 'translation']);

  const {
    meta,
    fields,
    type,
    hiddenFields = [],
  } = row.original as QuestionnaireHistoryEvent;

  const visibleFieldsPairs = useMemo(() => {
    return toPairs(omit(fields, [...hiddenFields])) as [string, any][];
  }, [fields, hiddenFields]);

  return (
    <td style={{ height: 'auto' }}>
      <Box py="12px" height={!row.isExpanded ? '82px' : ''}>
        <Flex justifyContent="space-between">
          <Bold>
            {t(`audit.eventLabel.${type}`)}
          </Bold>
          <Text fontSize={1} color="text">
            <Datetime2 value={meta.timestamp} format={DateFormat.DD_MMM_YYYY_HH_MM_A} />
          </Text>
        </Flex>
        <Box mt={1} fontSize={1}>
          {/*
           // @ts-expect-error ts(18048) FIXME: 'meta.user' is possibly 'undefined'. */}
          {meta.user.name && meta.user.name !== 'system' ? (
            <>
              {/*
               // @ts-expect-error ts(18048) FIXME: 'meta.user' is possibly 'undefined'. */}
              <Trans i18nKey="audit.byUserName" values={{ userName: meta.user.name }} ns="preQualification">
                {/*
                 // @ts-expect-error ts(18048) FIXME: 'meta.user' is possibly 'undefined'. */}
                by <Bold>{meta.user.name}</Bold>
              </Trans>
              {' '}
            </>
          ) : null}
          {/*
           // @ts-expect-error ts(18048) FIXME: 'meta.company' is possibly 'undefined'. */}
          {meta.company.name ? (
            // @ts-expect-error ts(18048) FIXME: 'meta.company' is possibly 'undefined'.
            <Trans i18nKey="audit.atCompanyName" values={{ companyName: meta.company.name }} ns="preQualification">
              {/*
               // @ts-expect-error ts(18048) FIXME: 'meta.company' is possibly 'undefined'. */}
              at <Bold>{meta.company.name}</Bold>
            </Trans>
          ) : null}
        </Box>
        <Flex alignItems="flex-end">
          {row.isExpanded ? (
            <Box flex={1} mt="3px" mr={3}>
              {visibleFieldsPairs.map(([key, value]) => (
                <Flex key={key} alignItems="flex-start" mt={1} mb={0}>
                  <FieldLabel width="180px" mr="12px">
                    {t(`audit.fieldLabel.${key}`)}
                  </FieldLabel>

                  <FieldValue
                    width="350px"
                    sx={{ whiteSpace: 'pre-wrap' }}
                    fieldKey={key}
                    // @ts-expect-error ts(2322) FIXME: Type 'Record<string, any> | (Record<string, any> & { userName: string; userId: string; recipientName: string; recipientId: string; }) | (Record<string, any> & { ...; }) | ... 8 more ... | undefined' is not assignable to type 'Record<string, any>'.
                    fields={fields}
                    value={value}
                    isMultiLine
                  />
                </Flex>
              ))}
            </Box>
          ) : (
            <Box flex={1} mr={3} style={truncateStyle}>
              {visibleFieldsPairs.map(([key, value]) => (
                <Fragment key={key}>
                  <FieldLabel as="span">
                    {t(`audit.fieldLabel.${key}`)}
                  </FieldLabel>
                  <FieldValue
                    as="span"
                    fieldKey={key}
                    // @ts-expect-error ts(2322) FIXME: Type 'Record<string, any> | (Record<string, any> & { userName: string; userId: string; recipientName: string; recipientId: string; }) | (Record<string, any> & { ...; }) | ... 8 more ... | undefined' is not assignable to type 'Record<string, any>'.
                    fields={fields}
                    value={value}
                  />
                </Fragment>
              ))}
            </Box>
          )}
          {!isEmpty(visibleFieldsPairs) && (
            <Box flex="0 0 auto" mt="-6px">
              <Button
                small
                variant="secondary-outline"
                iconLeft={row.isExpanded ? 'chevron-up' : 'chevron-down'}
                onClick={() => row.toggleRowExpanded()}
              />
            </Box>
          )}
        </Flex>
      </Box>
    </td>
  );
};

const AuditTable = ({ audit }: { audit: QuestionnaireHistoryEvent[] }) => {
  const columns = useMemo(
    () => [{ id: 'custom' }],
    [],
  );

  return (
    <BasicTableStyles hoverBackgroundColor="lightGray6" hoverCursor="default">
      <Table
        columns={columns}
        data={audit}
        isPaginated
        initialPageSize={10}
        hideHeader
        CustomRowCells={CustomRowCells}
        hasStaticHeight={false}
        enforcePagination
        minCellHeight={58}
        smallPageControls
      />
    </BasicTableStyles>
  );
};

const AuditPanel = ({ audit }: { audit: QuestionnaireHistoryEvent[] }) => {
  const { t } = useTranslation('general');
  const download = useDownload();
  const [isLoading, setIsLoading] = useState(false);
  const user = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const questionnaireId = useQuestionnaireId({ required: true });
  const locale = useCurrentUserLocale();

  const downloadAudit = useCallback(
    async () => {
      const queryParams = new URLSearchParams(omitBy(
        {
          csvSeparator: user.preferences?.csvSeparator,
          locale,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        } as Record<string, string>,
        isNil,
      ));

      setIsLoading(true);

      try {
        await download(`/download/company/${currentCompanyId}/questionnaire/${questionnaireId}/audit?${queryParams.toString()}`);
      } catch (error) {} // eslint-disable-line no-empty

      setIsLoading(false);
    },
    [currentCompanyId, user, questionnaireId, download, locale],
  );

  return (
    <Panel mb="40px">
      <Flex alignItems="center" justifyContent="space-between" px="20px" py={3} height="60px">
        <Text fontSize={2} fontWeight={500} mb="1px">
          {t('eventCount', { count: audit.length })}
        </Text>
        <DownloadCsvButton
          small
          disabled={isLoading}
          onClick={downloadAudit}
        />
      </Flex>
      <PanelDivider />
      <AuditTable audit={audit} />
    </Panel>
  );
};

export const QuestionnaireAudit = () => {
  const { t } = useTranslation();
  const { data: questionnaire, isLoading, isError, isSuccess } = useQuestionnaire();
  const {
    data: audit,
    isLoading: isLoadingAudit,
    isError: isErrorAudit,
    isSuccess: isSuccessAudit,
  } = useQuestionnaireAudit();

  return isLoading || isLoadingAudit ? (
    <LoadingPanel />
  ) : isError || isErrorAudit ? (
    <ErrorPanel error={t('errors.unexpected')} />
  ) : isSuccess && isSuccessAudit && questionnaire && audit ? (
    <QuestionnaireProvider questionnaire={questionnaire}>
      <Stack gap="24px">
        <QuestionnairePagePanels />
        <AuditPanel audit={audit} />
      </Stack>
    </QuestionnaireProvider>
  ) : (
    null
  );
};
