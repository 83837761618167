import { useMemo, useEffect } from 'react';
import { PageRole } from '@deepstream/common/rfq-utils';
import { pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Text } from 'rebass/styled-components';
import { QUESTIONS_PAGE_ID, QuestionnaireRoles } from '@deepstream/common/preQual';
import { RoleRestrictionProfile } from '@deepstream/common/user-utils';
import { useFormikContext } from 'formik';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ModalForm } from '../../../../ModalForm';
import { UserSelectField } from '../../../Request/Team/UserSelectField';
import { RequestRole } from '../../../../types';
import { UserDetails } from '../../../../UserDetails';
import { SelectField, getItemLabelWithDescription } from '../../../../form/SelectField';
import { useCurrentUser } from '../../../../useCurrentUser';
import { useQuestionnaireRoleSelectItems } from '../Team/useQuestionnaireRoleSelectItems';
import { TeamUserPagesPermissions } from '../Team/TeamUserPagesPermissions';
import { SenderTeamMember } from './utils';

type UserModalFields = {
  selectedUser?: {
    _id: string;
    name: string;
    email: string;
    roleRestrictionProfile?: RoleRestrictionProfile;
  };
  role: RequestRole;
  pagesPermissions?: QuestionnaireRoles;
};

const useFormInitialValues = ({
  teamMember,
}: {
  teamMember?: SenderTeamMember;
}) => {
  return useMemo((): UserModalFields => {
    // Edit mode
    if (teamMember) {
      return {
        // Map `SenderTeamMember` to the format of `UserSelectField`
        selectedUser: pick(teamMember, ['_id', 'name', 'email', 'roleRestrictionProfile']),
        role: teamMember.role,
        pagesPermissions: teamMember.pagesPermissions,
      };
    }

    // New user mode
    return {
      selectedUser: undefined,
      role: RequestRole.OWNER,
      pagesPermissions: {
        [QUESTIONS_PAGE_ID]: PageRole.RESPONDER,
      },
    };
  }, [
    teamMember,
  ]);
};

const TeamUserRole = () => {
  const { t } = useTranslation(['preQualification', 'translation']);
  const roles = useQuestionnaireRoleSelectItems();
  const currentUser = useCurrentUser();
  const { values } = useFormikContext<UserModalFields>();
  const isAuthorizedStakeholder = values?.selectedUser?.roleRestrictionProfile === RoleRestrictionProfile.AUTHORIZED_STAKEHOLDER;
  const isSuperUser = values?.selectedUser?.roleRestrictionProfile === RoleRestrictionProfile.SUPER_USER;
  const isEditSelf = currentUser._id === values?.selectedUser?._id;

  return (
    <>
      <SelectField
        items={roles}
        name="role"
        getButtonLabel={item => item?.label ?? ''}
        getItemLabel={getItemLabelWithDescription}
        disabled={isSuperUser || isAuthorizedStakeholder || isEditSelf}
      />
      {isAuthorizedStakeholder && (
        <MessageBlock variant="info" mt={0}>
          <Text>
            {t('questionnaire.team.pagePermissionsTable.stakeholdersLimitsInfo')}
          </Text>
        </MessageBlock>
      )}
      {isSuperUser && (
        <Text color="subtext" fontSize={0} mt={-2}>
          <Icon
            style={{ fontSize: '12px', marginRight: 4 }}
            icon="lock"
            light
          />
          {t('teamManagement.cannotBeEditedForSuperUser', { ns: 'translation' })}
        </Text>
      )}
    </>
  );
};

const TeamUserFields = ({
  companyId,
  teamMember,
  existingUserIds,
}: {
  companyId: string;
  teamMember?: SenderTeamMember;
  existingUserIds: string[];
}) => {
  const { values, setFieldValue } = useFormikContext<UserModalFields>();

  useEffect(() => {
    const isAuthorizedStakeholder = values?.selectedUser?.roleRestrictionProfile === RoleRestrictionProfile.AUTHORIZED_STAKEHOLDER;
    const isSuperUser = values?.selectedUser?.roleRestrictionProfile === RoleRestrictionProfile.SUPER_USER;

    if (isAuthorizedStakeholder) {
      setFieldValue('role', RequestRole.TEAM_MEMBER);
    }
    if (isSuperUser) {
      setFieldValue('role', RequestRole.OWNER);
    }
  }, [values, setFieldValue]);

  return (
    <>
      {teamMember ? (
        <UserDetails user={teamMember} />
      ) : (
        <UserSelectField companyId={companyId} existingUserIds={existingUserIds} />
      )}
      <TeamUserRole />
      <TeamUserPagesPermissions />
    </>
  );
};

export const SenderTeamUserModal = ({
  companyId,
  teamMember,
  existingUserIds,
  close,
  onSave,
  onRemove,
}: {
  companyId: string;
  teamMember?: SenderTeamMember;
  existingUserIds: string[];
  close: () => void;
  onSave: (teamMember: SenderTeamMember) => void;
  onRemove: (userId: string) => void;
}) => {
  const { t } = useTranslation(['preQualification', 'general']);
  const currentUser = useCurrentUser();
  const initialValues = useFormInitialValues({ teamMember });

  const modalHeading = teamMember ? t('questionnaire.team.editUser') : t('questionnaire.team.addUser');
  const submitLabel = teamMember ? t('saveChanges', { ns: 'general' }) : t('questionnaire.team.addUser');

  return (
    <ModalForm
      style={{ content: { maxWidth: '400px', minWidth: '400px' } }}
      heading={modalHeading}
      initialValues={initialValues}
      validationSchema={
        yup.object().shape({
          selectedUser: yup.mixed().required(t('required', { ns: 'general' })),
          role: yup.string().oneOf(Object.values(RequestRole)),
          pagesPermissions: yup.object().required(),
        })
      }
      disableSubmitIfNotDirty={true}
      disableSubmitIfInvalid={true}
      isOpen
      onCancel={close}
      onSubmit={async ({ selectedUser, role, pagesPermissions }) => {
        const isOwner = role === RequestRole.OWNER;

        onSave({
          ...selectedUser,
          role,
          pagesPermissions: isOwner
            ? { [QUESTIONS_PAGE_ID]: PageRole.RESPONDER }
            : pagesPermissions,
        });
      }}
      submitLabel={submitLabel}
      footerLeftButton={teamMember && currentUser._id !== teamMember._id && (
        <Button
          variant="danger-outline"
          type="button"
          onClick={() => onRemove(teamMember._id)}
        >
          {t('questionnaire.team.removeUser')}
        </Button>
      )}
    >
      <TeamUserFields
        companyId={companyId}
        teamMember={teamMember}
        existingUserIds={existingUserIds}
      />
    </ModalForm>
  );
};
