import { useCallback, useMemo } from 'react';
import { first } from 'lodash';
import { getTopLevelStates } from '@deepstream/utils/getTopLevelStates';
import { ModalHeader, Modal, ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { Actions } from './Actions';
import { States } from './States';

type RequestOutcomeModalProps = {
  onClose: any;
  stateComponents: any;
  modalTitle: string;
  state: any;
  send: any;
} & ModalProps;

export const RequestOutcomeModal = ({
  onClose,
  stateComponents,
  modalTitle,
  state,
  send,
  ...props
}: RequestOutcomeModalProps) => {
  const topLevelState = first(getTopLevelStates(state.value)) as States;
  const CurrentState = useMemo(() => stateComponents[topLevelState], [topLevelState, stateComponents]);

  const close = useCallback(
    (shouldRefetchRfq = false) => {
      onClose(shouldRefetchRfq);
      send(Actions.RESET);
    },
    [onClose, send],
  );

  return (
    <Modal style={{ content: { minWidth: '800px' } }} {...props}>
      <ModalHeader onClose={() => close(false)}>{modalTitle}</ModalHeader>
      <CurrentState state={state} send={send} onClose={close} />
    </Modal>
  );
};
