import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Text } from 'rebass/styled-components';
import { QuestionnaireStatus } from '@deepstream/common/preQual';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { ConfirmActionDialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { Bold } from '../../../Bold';
import { useConfirmDialog } from '../../../ui/useModalState';
import { useIsRecipient, useQuestionnaireData, useQuestionnairePermissions } from './questionnaireUtils';
import { useAcceptQuestionnaire, useDeclineQuestionnaire } from './questionnaireMutations';

export const useShowAcceptDeclinePanel = () => {
  const questionnaire = useQuestionnaireData();
  const isRecipient = useIsRecipient();
  const questionnairePermissions = useQuestionnairePermissions();

  return (
    questionnaire.status === QuestionnaireStatus.SENT &&
    isRecipient &&
    questionnairePermissions.canAcceptOrDecline
  );
};

export const AcceptDeclinePanel = () => {
  const { t } = useTranslation(['preQualification', 'general']);
  const questionnaire = useQuestionnaireData();
  const { confirm: confirmAccept, ...confirmAcceptDialogProps } = useConfirmDialog();
  const { confirm: confirmDecline, ...confirmDeclineDialogProps } = useConfirmDialog();

  const [acceptQuestionnaire] = useAcceptQuestionnaire();
  const [declineQuestionnaire] = useDeclineQuestionnaire();

  const onAccept = useCallback(
    () => confirmAccept(acceptQuestionnaire),
    [acceptQuestionnaire, confirmAccept],
  );

  const onDecline = useCallback(
    () => confirmDecline(declineQuestionnaire),
    [declineQuestionnaire, confirmDecline],
  );

  return (
    <>
      <Panel
        padded
        sx={{
          position: 'sticky',
          top: '180px',
          zIndex: 1,
        }}
      >
        <Flex alignItems="center">
          <Box flex="1 1 auto">
            <Text fontSize={4} lineHeight="normal" mb={1}>
              <Bold>
                {t('questionnaire.acceptDecline.header', { companyName: questionnaire.senders[0].name })}
              </Bold>
            </Text>
            <Text color="subtext">
              {t('questionnaire.acceptDecline.description')}
            </Text>
          </Box>
          <Box flex="0 0 auto">
            <Button mr={2} variant="danger" onClick={onDecline}>
              {t('questionnaire.acceptDecline.decline')}
            </Button>
            <Button variant="success" onClick={onAccept}>
              {t('questionnaire.acceptDecline.accept')}
            </Button>
          </Box>
        </Flex>
      </Panel>
      <ConfirmActionDialog
        heading={t('confirmation', { ns: 'general' })}
        variant="info"
        message={t('questionnaire.acceptDecline.confirmAcceptMessage')}
        okButtonText={t('questionnaire.acceptDecline.accept')}
        okButtonVariant="success"
        {...confirmAcceptDialogProps}
      />
      <ConfirmActionDialog
        heading={t('confirmation', { ns: 'general' })}
        variant="info"
        message={t('questionnaire.acceptDecline.confirmDeclineMessage')}
        okButtonText={t('questionnaire.acceptDecline.decline')}
        okButtonVariant="danger"
        {...confirmDeclineDialogProps}
      />
    </>
  );
};
