import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import * as yup from 'yup';
import { Box, Text } from 'rebass/styled-components';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { callAll } from '@deepstream/utils/callAll';
import { useApi, wrap } from '../../../api';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { PreQualQuestion } from '../../../types';
import { useMutation } from '../../../useMutation';
import { useToaster } from '../../../toast';
import { ModalForm } from '../../../ModalForm';
import { useDefaultCategory, useListCategoriesQueryKey, useListQuestionsQueryKey } from '../utils';
import { Bold } from '../../../Bold';
import { CategorySelectorField } from '../CategorySelectorField';

export const RestoreQuestionModal = ({
  question,
  close,
}: {
  question: PreQualQuestion;
  close: () => void;
}) => {
  const { t } = useTranslation('preQualification');
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const [restoreQuestion] = useMutation(api.restorePreQualQuestion);
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const getListCategoriesQueryKey = useListCategoriesQueryKey();
  const getListQuestionsQueryKey = useListQuestionsQueryKey();
  const defaultCategory = useDefaultCategory();

  const questionId = question._id;

  const { data: category, isSuccess } = useQuery(
    ['archivedQuestionCategory', { questionId, currentCompanyId }],
    wrap(api.getArchivedQuestionCategory),
  );

  if (!isSuccess) {
    return null;
  }

  return (
    <ModalForm
      heading={t('archive.restoreQuestion')}
      isOpen
      initialValues={{
        categoryId: category?._id || defaultCategory?._id,
      }}
      validationSchema={yup.object().shape({
        categoryId: yup.string().required(),
      })}
      style={{ content: { width: '500px' } }}
      submitLabel={t('archive.restore')}
      submitVariant="primary"
      disableSubmitIfNotDirty={false}
      onSubmit={async ({ categoryId }) => {
        await restoreQuestion(
          { questionId: question._id, currentCompanyId, categoryId },
          {
            onSuccess: callAll(
              () => {
                // Reset all `categories` query to properly update the sidebar count
                const listCategoriesQueryKey = getListCategoriesQueryKey();
                const listQuestionsInCategoryQueryKey = getListQuestionsQueryKey(categoryId);

                queryClient.invalidateQueries(listCategoriesQueryKey);
                queryClient.invalidateQueries(listQuestionsInCategoryQueryKey);
                queryClient.invalidateQueries(['archiveQuestions']);
              },
              close,
              () => toaster.success(t('toaster.questionRestored.success')),
            ),
            onError: () => toaster.error(t('toaster.questionRestored.failed')),
          },
        );
      }}
      onCancel={close}
    >
      <Stack gap={3}>
        <MessageBlock variant="info" mt={0}>
          {t('archive.restoreQuestionInfo')}
        </MessageBlock>
        <Stack gap={1}>
          <Bold>{t('question', { count: 1 })}:</Bold>
          <Text>{question.currentVersion.exchangeDef.description}</Text>
        </Stack>
        <Box sx={{ maxWidth: '200px' }}>
          <CategorySelectorField fieldName="categoryId" required />
        </Box>
      </Stack>
    </ModalForm>
  );
};
