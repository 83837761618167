import { BidStatus, LotIntentionStatus, isBidActive, isLinkedEvaluationCriterionExchangeDef } from '@deepstream/common/rfq-utils';
import { first } from 'lodash';
import { EvaluationExchangeSnapshot, RfxStructure } from '../../types';

export const requestBidStatusAllowsScoreSubmissions = (status: BidStatus) => (
  isBidActive(status) &&
  ![BidStatus.AWARDED, BidStatus.UNSUCCESSFUL].includes(status)
);

export const requestAndLotBidStatusesAllowScoreSubmissions = (
  exchange: EvaluationExchangeSnapshot,
  structure: RfxStructure,
) => {
  // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const bid = structure.bidById[exchange.recipientId];

  if (!requestBidStatusAllowsScoreSubmissions(bid.status)) {
    return false;
  }

  const originalExchangeDef = isLinkedEvaluationCriterionExchangeDef(exchange.def)
    ? structure.exchangeDefById[exchange.def.linkedExchangeDefId]
    : exchange.def;

  // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const section = structure.sectionById[originalExchangeDef.sectionId];

  const lotId = first(section.lotIds);

  return lotId
    ? bid.intentionStatusByLotId[lotId] === LotIntentionStatus.BIDDING
    : true;
};
