import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';

import { callAll } from '@deepstream/utils/callAll';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { ModalForm } from '../../ModalForm';
import { TextField } from '../../form/TextField';
import { useApi } from '../../api';
import { useMutation } from '../../useMutation';
import { useToaster } from '../../toast';
import { useContractId } from './contract';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useContractQueryKey } from './useContract';
import { useCurrentUserLocale } from '../../useCurrentUser';
import { useLiveContractNavigation } from '../../appNavigation';

export const IssueAmendmentModal = ({
  isOpen,
  onCancel,
}: {
  isOpen: boolean;
  onCancel: () => void;
}) => {
  const { t } = useTranslation(['contracts', 'general']);
  const api = useApi();
  const toaster = useToaster();
  const contractId = useContractId();
  const currentCompanyId = useCurrentCompanyId();
  const queryClient = useQueryClient();
  const liveContractQueryKey = useContractQueryKey({ scope: 'current' });
  const draftContractQueryKey = useContractQueryKey({ scope: 'draft' });
  const locale = useCurrentUserLocale();
  const { navigateToSummary } = useLiveContractNavigation();

  const [amendContract] = useMutation(
    api.amendContract,
    {
      onSuccess: callAll(
        () => queryClient.invalidateQueries(liveContractQueryKey),
        () => queryClient.invalidateQueries(draftContractQueryKey),
        () => toaster.success(t('toaster.contractAmended.success')),
        () => navigateToSummary(),
      ),
      onError: () => toaster.error(t('toaster.contractAmended.failed')),
    },
  );

  return (
    <ModalForm
      heading={t('issueAmendment')}
      initialValues={{
        message: '',
      }}
      validationSchema={
        yup.object().shape({
          message: yup
            .string()
            .trim()
            .required(t('required', { ns: 'general' })),
        })
      }
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={async ({ message }) => {
        await amendContract({
          contractId,
          // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
          currentCompanyId,
          message,
          locale,
        });
      }}
      submitLabel={t('issueAmendment')}
      style={{ content: { width: '380px' } }}
    >
      <Stack gap={3}>
        <MessageBlock variant="info" mt={0}>
          {t('review.amendmentInfo')}
        </MessageBlock>
        <TextField
          name="message"
          isMultiLine
          label={t('message', { ns: 'general' })}
          placeholder={t('review.amendmentMessagePlaceholder')}
          required
        />
      </Stack>
    </ModalForm>
  );
};
