import { useMemo } from 'react';
import * as React from 'react';
import { useField } from 'formik';
import { Box } from 'rebass/styled-components';
import {
  QuestionElementType,
  TextboxQuestionElement,
  DropdownQuestionElement,
  MoneyQuestionElement,
} from '@deepstream/common/legacy-pre-q-utils';
import { getCurrencySymbol } from '@deepstream/utils';
import { useTranslation } from 'react-i18next';
import { Row } from '../ui/ProfileLayout';
import { CurrencyFormat } from '../ui/Currency';
import { Modify, ElementProps, ContainerLabel, LockedAnswer, NoAnswer } from './common';
import { Element } from './Element'; // eslint-disable-line import/no-cycle
import { TextboxElement } from './TextboxElement';

export const MoneyElement: React.FC<Modify<ElementProps, { element: MoneyQuestionElement }>> = ({
  name,
  element,
  answer,
  hideLabel,
  error,
  isReadOnly,
  isLocked,
}) => {
  const { t } = useTranslation(['companyProfile', 'general']);
  const currencyCodeName = `${name}.currencyCode`;
  const amountName = `${name}.amount`;
  const [currencyField] = useField(currencyCodeName);

  const [currencyCodeElement, amountElement] = useMemo(
    () => [
      {
        key: 'currencyCode',
        type: QuestionElementType.DROPDOWN,
        subtype: 'currency',
        label: t('currency', { ns: 'general' }),
      } as DropdownQuestionElement,
      {
        key: 'amount',
        type: QuestionElementType.TEXTBOX,
        inputType: 'number',
        label: t('amount', { ns: 'general' }),
      } as TextboxQuestionElement,
    ],
    [t],
  );

  return (
    <ContainerLabel
      label={element.label || (element.labelId ? t(`element.label.${element.labelId}`) : '')}
      hideLabel={hideLabel}
    >
      {isReadOnly ? (
        answer?.currencyCode && answer?.amount ? (
          isLocked ? (
            <LockedAnswer />
          ) : (
            <CurrencyFormat currency={answer.currencyCode} amount={answer.amount} showCode />
          )
        ) : (
          <NoAnswer />
        )
      ) : (
        <>
          <Row>
            <Box width="150px" mr={3}>
              <Element
                element={currencyCodeElement}
                name={currencyCodeName}
                answer={answer?.currencyCode}
                error={error?.currencyCode}
                isReadOnly={isReadOnly}
              />
            </Box>
            <Box width="250px">
              <TextboxElement
                element={amountElement}
                name={amountName}
                answer={answer?.amount}
                error={error?.amount}
                isReadOnly={isReadOnly}
                disabled={!currencyField.value}
                prefix={getCurrencySymbol(currencyField.value)}
                format="money"
              />
            </Box>
          </Row>
          {error}
        </>
      )}
    </ContainerLabel>
  );
};
