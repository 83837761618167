import * as React from 'react';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { ALL_DASHBOARD_ROLES, DashboardRoleFilter, FilterProps, useDashboardRoleFilterItems } from '../filtering';
import {
  MultiSelectDropdownPage,
  MultiSelectDropdownPageProps,
} from './FilterDropdownPages/MultiSelectDropdownPage';
import { HorizontalDivider } from './HorizontalDivider';

const CheckboxText = styled(Text)`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.colors.text};
  font-size: ${props => props.theme.fontSizes[2]}px;
  font-weight: 500;
  line-height: 18px;
  padding-left: ${props => props.theme.space[2]}px;
`;

const renderPreItemContent = (item: DashboardRoleFilter) =>
  item._id === 'requestCreator' ? <HorizontalDivider my={2} /> : null;

export const CheckboxList = ({
  items,
  selectedItems,
  onChange,
  isListDisabled,
}: {
  items: DashboardRoleFilter[];
  selectedItems: DashboardRoleFilter[];
  onChange: React.Dispatch<React.SetStateAction<DashboardRoleFilter[]>>;
  isListDisabled: boolean;
}) => {
  const selectedIds = React.useMemo(() => map(selectedItems, '_id'),
    [selectedItems],
  );

  const handleChange = React.useCallback(
    (item: DashboardRoleFilter) => {
      if (selectedIds.includes(item._id)) {
        onChange(selectedItems.filter((selectedItem) => selectedItem._id !== item._id));
      } else {
        onChange(selectedItems.concat(item));
      }
    },
    [selectedItems, onChange, selectedIds],
  );

  return (
    <ul style={{ margin: 0, padding: 0, listStyle: 'none', lineHeight: 1.3 }}>
      {items.map((item) => (
        <li key={item._id}>
          {renderPreItemContent(item)}
          <Checkbox
            disabled={isListDisabled}
            label={item.name}
            checked={selectedIds.includes(item._id)}
            onChange={() => handleChange(item)}
            style={{ padding: '4px 12px', fontWeight: 500 }}
            CheckboxLabel={CheckboxText}
          />
        </li>
      ))}
    </ul>
  );
};

type RequestUserFilterProps<EntityFilterItemT extends Record<string, unknown>> =
  MultiSelectDropdownPageProps<EntityFilterItemT> & {
    roles: FilterProps<DashboardRoleFilter>['items'];
    selectedRoles: FilterProps<DashboardRoleFilter>['selectedItems'];
    onRolesChange: FilterProps<DashboardRoleFilter>['onChange'];
  };

export const RequestUsersFilter = ({
  items,
  selectedItems,
  renderItem,
  idProp,
  filterItems,
  onChange,
  roles,
  selectedRoles,
  onRolesChange,
}: RequestUserFilterProps<Record<string, unknown>>) => {
  const { t } = useTranslation('translation');

  return (
    <>
      <MultiSelectDropdownPage
        filterItems={filterItems}
        filterPlaceholder={t('userSelect.searchByNameOrEmail')}
        items={items}
        onChange={onChange}
        renderItem={renderItem}
        selectedItems={selectedItems}
        idProp={idProp}
      />
      <HorizontalDivider />
      <Flex flexDirection="column" marginTop={2}>
        <Text
          color="gray"
          sx={{ textTransform: 'uppercase', padding: '8px 12px', letterSpacing: '1px' }}
          fontSize={1}
          fontWeight={500}
        >
          {t('requests.filtering.requestTeamRole')}
        </Text>
        <CheckboxList
          items={roles}
          selectedItems={selectedRoles}
          onChange={onRolesChange}
          isListDisabled={selectedItems.length === 0}
        />
      </Flex>
    </>
  );
};
