import { Flex } from 'rebass/styled-components';
import { isFinite } from 'lodash';
import { localeFormatFactorAsPercent } from '@deepstream/utils';
import { diffFactor } from '@deepstream/utils/math';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { CurrencyAmount } from '../../../ui/Currency';
import { LabeledValue } from '../../../draft/LabeledValue';
import { useCurrentUserLocale } from '../../../useCurrentUser';

const valuePlaceholder = '____';

export const SavingsDiffCalculation = ({
  value: { valueA, valueB },
  labelA,
  labelB,
}: { value: { valueA?: number; valueB?: number }, labelA: string, labelB: string }) => {
  const locale = useCurrentUserLocale();

  const hasValues = isFinite(valueA) && isFinite(valueB);

  const factor = hasValues
    // @ts-expect-error ts(2345) FIXME: Argument of type 'number | undefined' is not assignable to parameter of type 'number'.
    ? diffFactor(valueA, valueB)
    : null;

  const fontWeight = hasValues ? 500 : 400;

  return (
    <Flex alignItems="center">
      <LabeledValue
        label={labelA}
        value={hasValues ? <CurrencyAmount showCode value={valueA} /> : valuePlaceholder}
        valueProps={{ fontWeight }}
        align="left"
        mr={3}
      />
      –
      <LabeledValue
        label={labelB}
        value={hasValues ? <CurrencyAmount showCode value={valueB} /> : valuePlaceholder}
        valueProps={{ fontWeight }}
        align="left"
        mx={3}
      />
      =
      <Flex ml={3} alignItems="center" fontWeight={fontWeight}>
        {/*
         // @ts-expect-error ts(18048) FIXME: 'valueA' is possibly 'undefined'. */}
        {hasValues ? <CurrencyAmount showCode value={valueA - valueB} /> : valuePlaceholder}
        {isFinite(factor) && (
          <IconText
            ml={2}
            gap="2px"
            iconFontWeight={400}
            fontWeight={400}
            // @ts-expect-error ts(18047) FIXME: 'factor' is possibly 'null'.
            color={factor > 0 ? 'danger' : 'success'}
            // @ts-expect-error ts(18047) FIXME: 'factor' is possibly 'null'.
            icon={factor > 0 ? 'arrow-up' : 'arrow-down'}
            // @ts-expect-error ts(2345) FIXME: Argument of type 'number | null' is not assignable to parameter of type 'number'.
            text={localeFormatFactorAsPercent(Math.abs(factor), { locale, decimalPlaces: 1 })}
          />
        )}
      </Flex>
    </Flex>
  );
};
