import { useMemo } from 'react';
import { ContractDocumentExchangeDefinition, ContractSection, ContractProvidedBy, ContractSignatureType } from '@deepstream/common/contract/contract';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import * as yup from 'yup';
import { values } from 'lodash';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton, EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { PanelHeader, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Validation, useError } from '../../draft/validation';
import { usePreventEnterKeyHandler } from '../../usePreventEnterKeyHandler';
import {
  useContractActions,
  useContractSection,
  useContractState,
  useSectionExchangeDefs,
  usePagePermissions,
} from './contract';
import { ContractDraftPanel } from './ContractDraftPanel';
import { ContractSectionPanelHeading } from './ContractSectionPanelHeading';
import { useIsEditing, useSaveContractsSection, useShowValidationErrors } from './draftContract';
import { ContractTable, ContractTableField } from './ContractTable';
import { useDraftContractNavigation } from '../../appNavigation';
import { LeavePageModal } from '../../draft/LeavePageModal';

/**
 * Header component for the current draft contract section
 */
const SectionPanelHeader = () => {
  const showValidationErrors = useShowValidationErrors();
  const { error } = useError('section.name');
  const section = useContractSection();
  // @ts-expect-error ts(2339) FIXME: Property 'isDraft' does not exist on type 'ContractStateContextType | undefined'.
  const { isDraft, isReview, editingPanelId, isTemplatePreview } = useContractState();
  const { startEditing } = useContractActions();
  const draftNavigation = useDraftContractNavigation();
  const { canEdit } = usePagePermissions();
  const isEditing = Boolean(editingPanelId);

  return (
    <PanelHeader
      heading={<ContractSectionPanelHeading />}
      bg={showValidationErrors && error ? 'errorBackground' : 'white'}
      data-test="view-section-header"
    >
      {isDraft && !isTemplatePreview ? (
        (isReview && canEdit) ? (
          <EditButton
            small
            variant="primary"
            type="button"
            ml={4}
            onClick={() => draftNavigation.navigateToContract()}
          />
        ) : (!isEditing && canEdit) ? (
          <EditButton
            small
            onClick={() => startEditing(section._id)}
            data-test="edit-section-button"
            ml={4}
          />
        ) : (
          null
        )
      ) : (
        null
      )}
    </PanelHeader>
  );
};

/**
 * Displays a panel for the current draft contract section
 */
export const ContractSectionPanel = () => {
  const { t } = useTranslation(['general', 'contracts']);
  const { stopEditing } = useContractActions();
  const isEditing = useIsEditing();
  const showValidationErrors = useShowValidationErrors();
  const section = useContractSection();
  const exchangeDefs = useSectionExchangeDefs();
  const [saveSection] = useSaveContractsSection();
  const onKeyDown = usePreventEnterKeyHandler();

  const initialValues = useMemo(
    () => ({
      section,
      exchangeDefs,
    }),
    [exchangeDefs, section],
  );

  return isEditing ? (
    <Formik<{
      section: ContractSection;
      exchangeDefs: ContractDocumentExchangeDefinition[];
    }>
      validateOnBlur
      enableReinitialize
      initialValues={initialValues as any}
      validationSchema={
        yup.object().shape({
          section: yup.object().shape({
            name: yup.string(),
          }),
        })
      }
      onSubmit={async ({ exchangeDefs }) => {
        await saveSection({
          section,
          exchangeDefs,
        }, {
          onSuccess: stopEditing,
        });
      }}
    >
      {({ isSubmitting, dirty, resetForm }) => (
        <Form style={{ width: '100%' }} onKeyDown={onKeyDown}>
          <ContractDraftPanel panelId={section._id}>
            <SectionPanelHeader />
            <PanelDivider />
            <PanelPadding>
              <ContractTableField fieldName="exchangeDefs" />
            </PanelPadding>
            <PanelDivider />
            <PanelPadding>
              <Flex justifyContent="flex-end">
                <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
                <SaveButton disabled={isSubmitting || !dirty} />
              </Flex>
            </PanelPadding>
          </ContractDraftPanel>
          <LeavePageModal />
        </Form>
      )}
    </Formik>
  ) : (
    <Validation
      values={{
        section,
        exchangeDefs,
      }}
      schema={showValidationErrors ? (
        yup.object().shape({
          section: yup.object().shape({
            name: yup.string().required(t('required')),
          }),
          exchangeDefs: yup.array(
            yup.object().shape({
              description: yup.string().required(t('required')),
              attachments: yup
                .array(yup.object().required())
                .when(
                  'providedBy',
                  {
                    is: ContractProvidedBy.BUYER,
                    then: schema => schema.min(1, t('required')).required(t('required')),
                    otherwise: schema => schema.nullable(),
                  },
                ),
              providedBy: yup
                .string()
                .oneOf(values(ContractProvidedBy))
                .required(t('required')),
              signatureType: yup
                .string()
                .oneOf(values(ContractSignatureType))
                .required(t('required')),
              requireApprovalBeforeCountersigning: yup.boolean(),
              senderSigners: yup
                .array()
                .when(
                  'signatureType',
                  {
                    is: ContractSignatureType.VERIFIED,
                    then: schema => schema.min(1, t('review.errors.minimumOneRequired', { ns: 'contracts' })),
                    otherwise: schema => schema.nullable(),
                  },
                ),
            }),
          ).required().min(1, t('review.errors.minimumOneRequired', { ns: 'contracts' })),
        })
      ) : (
        yup.mixed()
      )}
    >
      <ContractDraftPanel panelId={section._id}>
        <SectionPanelHeader />
        <PanelDivider />
        <ContractTable />
      </ContractDraftPanel>
    </Validation>
  );
};
