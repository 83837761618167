import { matches } from 'lodash';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils';
import { useRfqId } from '../../../useRfq';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import * as Layout from '../../../Layout';
import { useNotificationSubject } from '../../Notifications/useNotificationSubject';
import { RequestPageHeader } from '../../../page-headers/RequestPageHeader';
import { RequestLiveDetails } from '../Live/Details';

export const RequestSentDetails = () => {
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId();

  const ref = useNotificationSubject({
    filter: matches({
      domain: NotificationDomain.RFQ_SENT,
      action: NotificationAction.RFQ_CREATED,
      meta: { rfqId },
      to: { companyId: currentCompanyId },
    }),
  });

  return (
    <Layout.HeaderAndContent
      header={
        <RequestPageHeader selectedTabId="details" rfqId={rfqId} />
      }
      content={
        <div ref={ref}>
          <RequestLiveDetails rfqId={rfqId} />
        </div>
      }
    />
  );
};
