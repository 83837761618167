import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { Box, Flex } from 'rebass/styled-components';
import { Company, LockType, Sender } from '@deepstream/common/rfq-utils';
import { isEmpty } from 'lodash';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useIntercom } from 'react-use-intercom';
import { LockModal } from '../LockModal';
import { useModalState } from '../ui/useModalState';
import { FieldContainer } from '../form/FieldContainer';

const getSenderNamesOrEmDash = (senders: Sender[]) => {
  if (isEmpty(senders)) {
    return ['—'];
  } else {
    return senders.map(sender => sender.company.name);
  }
};

const LockInfo = ({ lock, senders }) => {
  const { t } = useTranslation();
  const lockedSenders = senders.filter(sender => !lock?.bypass?.includes(sender._id));
  const sendersWithKey = senders.filter(sender => lock?.keys?.includes(sender._id));

  return lock && lock.type ? (
    <>
      {t('request.lock.lockedForCompanies', { companyNames: getSenderNamesOrEmDash(lockedSenders) })}
      <br />
      {lock.type === LockType.BID_DEADLINE ? (
        t('request.lock.willUnlockAfterFinalDeadline')
      ) : lock.type === LockType.STAGE_DEADLINE ? (
        t('request.lock.willUnlockAfterStageDeadline')
      ) : (
        <>
          {isEmpty(sendersWithKey) ? (
            lock.type === LockType.TEAM_MEMBER ? (
              t('request.lock.canBeUnlockedAtAnyTime')
            ) : lock.type === LockType.TEAM_MEMBER_AFTER_STAGE_DEADLINE ? (
              t('request.lock.canBeUnlockedAfterStageDeadline')
            ) : lock.type === LockType.TEAM_MEMBER_AFTER_BID_DEADLINE ? (
              t('request.lock.canBeUnlockedAfterFinalDeadline')
            ) : (
              null
            )
          ) : (
            lock.type === LockType.TEAM_MEMBER ? (
              t('request.lock.canBeUnlockedAtAnyTimeBy', { companyNames: getSenderNamesOrEmDash(sendersWithKey) })
            ) : lock.type === LockType.TEAM_MEMBER_AFTER_STAGE_DEADLINE ? (
              t('request.lock.canBeUnlockedAfterStageDeadlineBy', { companyNames: getSenderNamesOrEmDash(sendersWithKey) })
            ) : lock.type === LockType.TEAM_MEMBER_AFTER_BID_DEADLINE ? (
              t('request.lock.canBeUnlockedAfterFinalDeadlineBy', { companyNames: getSenderNamesOrEmDash(sendersWithKey) })
            ) : (
              t('request.lock.canBeUnlockedBy', { companyNames: getSenderNamesOrEmDash(sendersWithKey) })
            )
          )}
        </>
      )}
    </>
  ) : (
    <>{t('request.lock.noLockCriteriaSet')}</>
  );
};

export const LockField = ({
  name,
  label,
  description,
  senders,
  isSectionLive,
  hideLabel,
  required,
  infoTooltip,
}: {
  name: string;
  label: string;
  description?: string;
  senders: Company[];
  isSectionLive?: boolean;
  hideLabel?: boolean;
  required?: boolean;
  infoTooltip?: string;
}) => {
  const intercom = useIntercom();
  const id = useUniqueId();
  const { t } = useTranslation();
  const [{ value: locking }, , lockFormik] = useField(name);
  const lockModal = useModalState();

  return (
    <>
      <FieldContainer
        name={name}
        htmlFor={id}
        label={label}
        description={description}
        hideLabel={hideLabel}
        showAsterisk={required}
        infoTooltip={infoTooltip}
      >
        <Flex mt="2px" alignItems="flex-start">
          <Flex alignItems="center" flex="0 0 auto">
            <IconText
              icon={locking ? 'lock' : 'unlock'}
              text={locking ? t('general.lockSet') : t('general.noLockSet')}
              mr={3}
            />
            <Button
              id={id}
              type="button"
              iconLeft="pencil"
              variant="secondary-outline"
              fontSize={2}
              onClick={() => {
                lockModal.open();

                if (!isSectionLive) {
                  intercom.trackEvent('locking-modal-opened');
                }
              }}
            >
              {t('general.edit')}
            </Button>
          </Flex>
          {locking && (
            <Box ml={4} flex={1}>
              <LockInfo lock={locking} senders={senders} />
            </Box>
          )}
        </Flex>
      </FieldContainer>
      <LockModal
        isOpen={lockModal.isOpen}
        close={lockModal.close}
        lock={locking}
        isReadOnly={isSectionLive}
        onSubmit={lockFormik.setValue}
      />
    </>
  );
};
