import { Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { CancelButton, Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { RequestTag } from '../../ui/types';
import { useApiRemoveRequestTag } from './RequestTagsContext';

type Props = {
  tag?: RequestTag;
  onCancel: () => void;
  onDone: () => void;
} & ModalProps;

const RemoveTagModal = ({ tag, onCancel, onDone, ...props }: Props) => {
  const { t } = useTranslation();
  // @ts-expect-error ts(18048) FIXME: 'tag' is possibly 'undefined'.
  const { removeTag } = useApiRemoveRequestTag(tag.companyId);

  const handleRemove = async () => {
    // @ts-expect-error ts(18048) FIXME: 'tag' is possibly 'undefined'.
    await removeTag(tag._id);
    onDone();
  };

  return (
    <Modal style={{ content: { width: '500px' } }} {...props}>
      <ModalHeader onClose={onCancel}>
        {t('requests.tags.removeTagTitle')}
      </ModalHeader>
      <ModalBody>
        <Text>
          {/*
           // @ts-expect-error ts(18048) FIXME: 'tag' is possibly 'undefined'. */}
          {!isEmpty(tag.requestIds) && !isEmpty(tag.contractIds) ? (
            t(
              'requests.tags.removeTagInfoRequestsAndContracts',
              // @ts-expect-error ts(18048) FIXME: 'tag' is possibly 'undefined'.
              { tagName: tag.name, numRequests: tag.requestIds.length, numContracts: tag.contractIds.length },
            )
          // @ts-expect-error ts(18048) FIXME: 'tag' is possibly 'undefined'.
          ) : isEmpty(tag.requestIds) && isEmpty(tag.contractIds) ? (
            // @ts-expect-error ts(18048) FIXME: 'tag' is possibly 'undefined'.
            t('requests.tags.removeUnusedTagInfo', { tagName: tag.name })
          // @ts-expect-error ts(18048) FIXME: 'tag' is possibly 'undefined'.
          ) : isEmpty(tag.requestIds) ? (
            // @ts-expect-error ts(18048) FIXME: 'tag' is possibly 'undefined'.
            t('requests.tags.removeTagInfoContracts', { tagName: tag.name, count: tag.contractIds.length })
          ) : (
            // @ts-expect-error ts(18048) FIXME: 'tag' is possibly 'undefined'.
            t('requests.tags.removeTagInfoRequests', { tagName: tag.name, count: tag.requestIds.length })
          )}
        </Text>

        <MessageBlock variant="warn" my={3}>
          {t('requests.tags.removeTagWarning')}
        </MessageBlock>
      </ModalBody>

      <ModalFooter>
        <CancelButton onClick={onCancel} />
        <Button type="button" onClick={handleRemove} variant="danger">
          {t('requests.tags.removeTagConfirm')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RemoveTagModal;
