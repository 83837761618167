import * as React from 'react';
import { useQuery } from 'react-query';
import { isEmpty, isEqual, omitBy, uniq } from 'lodash';
import { useCurrentCompanyId } from './currentCompanyId';
import { wrap } from './api';
import { TableData, TableDataContext } from './TableDataContext';
import { ActiveStageLabeledFilter, DashboardRoleFilter, FilterProps, FinalDeadlineLabeledFilter } from './filtering';
import { SortProps2 } from './sorting';
import { ReceivedRequestOverviews, SentRequestOverviews } from './types';

export type PageControls = {
  pageIndex: number;
  pageSize: number;
};

const combineFilterQueryParams = <
  StatusFilterItemT extends Record<string, unknown>,
  RoleFilterItemT extends Record<string, unknown>,
  EntityFilterItemT extends Record<string, unknown>,
  RequestTagsFilterItemT extends Record<string, unknown>,
  CompanyFilterItemT extends Record<string, unknown>,
  ActiveStageFilterItemT extends Record<string, unknown>,
  FinalDeadlineFilterItemT extends Record<string, unknown>,
>({
  statusFilter,
  roleFilter,
  entityFilter,
  requestTagsFilter,
  companyFilter,
  staticStatusFilterItems,
  activeStageFilter,
  finalDeadlineFilter,
}: {
  roleFilter: FilterProps<RoleFilterItemT>;
  entityFilter: FilterProps<EntityFilterItemT>;
  requestTagsFilter?: FilterProps<RequestTagsFilterItemT>;
  statusFilter?: FilterProps<StatusFilterItemT>;
  staticStatusFilterItems?: Record<string, unknown>;
  companyFilter?: FilterProps<CompanyFilterItemT>;
  activeStageFilter?: FilterProps<ActiveStageFilterItemT>;
  finalDeadlineFilter?: FilterProps<FinalDeadlineFilterItemT>;
}) =>
  omitBy(
    {
      ...(statusFilter ? statusFilter.getQueryParam(statusFilter.selectedItems) : staticStatusFilterItems),
      ...roleFilter.getQueryParam(roleFilter.selectedItems),
      ...entityFilter.getQueryParam(entityFilter.selectedItems),
      ...requestTagsFilter?.getQueryParam(requestTagsFilter.selectedItems),
      ...companyFilter?.getQueryParam(companyFilter.selectedItems),
      ...activeStageFilter?.getQueryParam(activeStageFilter.selectedItems),
      ...finalDeadlineFilter?.getQueryParam(finalDeadlineFilter.selectedItems),
    },
    isEmpty,
  );

/**
 * Provides a TableDataContext for requests tables.
 */
export const RequestsTableDataProvider = <
  QueryDataT extends SentRequestOverviews | ReceivedRequestOverviews,
  StatusFilterItemT extends Record<string, unknown>,
  RoleFilterItemT extends Record<string, unknown>,
  EntityFilterItemT extends Record<string, unknown>,
  CompanyFilterItemT extends Record<string, unknown>,
  RequestTagFilterItemT extends Record<string, unknown>,
  ActiveStageFilterItemT extends Record<string, unknown>,
  FinalDeadlineFilterItemT extends Record<string, unknown>,
>({
    queryTag,
    queryFn,
    statusFilter,
    roleFilter,
    entityFilter,
    companyFilter,
    requestTagsFilter,
    activeStageFilter,
    finalDeadlineFilter,
    preselectedTag,
    pageSizeState,
    searchTextState,
    sort,
    children,
  }: {
  queryTag: string;
  queryFn: (...params: any[]) => Promise<QueryDataT>;
  statusFilter: FilterProps<StatusFilterItemT>;
  roleFilter: FilterProps<RoleFilterItemT>;
  entityFilter: FilterProps<EntityFilterItemT>;
  companyFilter?: FilterProps<CompanyFilterItemT>;
  requestTagsFilter: FilterProps<RequestTagFilterItemT>;
  activeStageFilter?: FilterProps<ActiveStageFilterItemT>;
  finalDeadlineFilter?: FilterProps<FinalDeadlineFilterItemT>;
  preselectedTag?: string;
  pageSizeState: [number, (pageSize: number) => void];
  searchTextState?: [string, (searchText: string) => void];
  children: React.ReactElement;
  sort: SortProps2;
}): React.ReactElement<TableData<QueryDataT>> => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const [selectedItemsIds, setSelectedItemsIds] = React.useState<string[]>([]);
  const [areAllItemsSelected, setAreAllItemsSelected] = React.useState(false);
  const [pageSize, setPageSize] = pageSizeState;
  const [searchText, setSearchText] = searchTextState ?? [undefined, () => { }];
  const [pageIndex, setPageIndex] = React.useState<number>(0);
  const setPageControls = React.useCallback((pageControls: PageControls) => {
    setPageSize(pageControls.pageSize);
    setPageIndex(pageControls.pageIndex);
    setSelectedItemsIds([]);
    setAreAllItemsSelected(false);
  }, [setPageIndex, setPageSize]);

  const [queryParams, setQueryParams] = React.useState(() => ({
    currentCompanyId,
    pageSize,
    pageIndex,
    sort: sort.getQueryParam(sort.selectedSortCriterion, sort.selectedSortDirection),
    filter: combineFilterQueryParams({
      statusFilter,
      roleFilter,
      entityFilter,
      requestTagsFilter,
      companyFilter,
      activeStageFilter,
      finalDeadlineFilter,
    }),
    ...(searchText && {
      search: {
        text: searchText,
      },
    }),
  }));

  const query = useQuery(
    [queryTag, queryParams],
    wrap(queryFn),
  );

  React.useEffect(() => {
    const newQueryParams = {
      currentCompanyId,
      pageSize,
      pageIndex,
      sort: sort.getQueryParam(sort.selectedSortCriterion, sort.selectedSortDirection),
      filter: combineFilterQueryParams({
        statusFilter,
        roleFilter,
        entityFilter,
        requestTagsFilter,
        companyFilter,
        activeStageFilter,
        finalDeadlineFilter,
      }),
      ...(searchText && {
        search: {
          text: searchText,
        },
      }),
    };
    newQueryParams.filter.requestTags = uniq([
      ...(newQueryParams.filter.requestTags ? Object.values(newQueryParams.filter.requestTags) : []),
      ...(preselectedTag ? [preselectedTag] : []),
    ]);

    if (!isEqual(queryParams, newQueryParams)) {
      setQueryParams(newQueryParams);
    }
  }, [
    currentCompanyId,
    pageSize,
    pageIndex,
    sort,
    statusFilter,
    entityFilter,
    companyFilter,
    activeStageFilter,
    finalDeadlineFilter,
    queryParams,
    requestTagsFilter,
    preselectedTag,
    roleFilter,
    searchText,
  ]);

  const selectItem = React.useCallback((id: string) => {
    setSelectedItemsIds((selectedRequestsIds) => uniq([...selectedRequestsIds, id]));
  }, []);

  const unselectItem = React.useCallback((id: string) => {
    setSelectedItemsIds((selectedRequestsIds) => selectedRequestsIds.filter((selectedRequestId) => selectedRequestId !== id));
    setAreAllItemsSelected(false);
  }, []);

  const selectAllItems = React.useCallback(() => {
    setAreAllItemsSelected(true);
  }, []);

  const unselectAllItems = React.useCallback(() => {
    setAreAllItemsSelected(false);
    setSelectedItemsIds([]);
  }, []);

  const selectAllItemsOnPage = React.useCallback(() => {
    setSelectedItemsIds((selectedRequestsIds) => {
      const newSelectedRequestsIds = query.data?.rfqs.map((item) => item._id) || [];
      return uniq([...selectedRequestsIds, ...newSelectedRequestsIds]);
    });
  }, [query.data]);

  const value = React.useMemo(() => ({
    data: query?.data?.rfqs ?? [],
    pageCount: Math.ceil((query?.data?.totalItems ?? 0) / queryParams.pageSize),
    pageSize: queryParams.pageSize,
    pageIndex: query?.data?.pageIndex ?? 0,
    query,
    statusFilter,
    roleFilter,
    entityFilter,
    companyFilter,
    requestTagsFilter,
    activeStageFilter,
    finalDeadlineFilter,
    selectItem,
    unselectItem,
    selectAllItems,
    unselectAllItems,
    selectAllItemsOnPage,
    selectedItemsIds,
    areAllItemsSelected,
    sort,
    setPageControls,
    searchText: queryParams.search?.text,
    setSearchText,
    queryParams,
  }), [
    query,
    statusFilter,
    roleFilter,
    entityFilter,
    companyFilter,
    requestTagsFilter,
    activeStageFilter,
    finalDeadlineFilter,
    selectItem,
    unselectItem,
    selectAllItems,
    unselectAllItems,
    selectAllItemsOnPage,
    selectedItemsIds,
    areAllItemsSelected,
    sort,
    setPageControls,
    setSearchText,
    queryParams,
  ]);

  return (
    <TableDataContext.Provider value={value}>
      {children}
    </TableDataContext.Provider>
  );
};

export type RequestsTableData<T> = TableData<T> & {
  statusFilter?: FilterProps<Record<string, unknown>>;
  staticStatusFilterItems?: Record<string, unknown>;
  roleFilter: FilterProps<DashboardRoleFilter>;
  entityFilter: FilterProps<Record<string, unknown>>;
  requestTagsFilter: FilterProps<Record<string, unknown>>;
  companyFilter: FilterProps<Record<string, unknown>>;
  activeStageFilter: FilterProps<ActiveStageLabeledFilter>;
  finalDeadlineFilter: FilterProps<FinalDeadlineLabeledFilter>;
  sort: SortProps2;
  searchText: string;
  setSearchText: (searchText: string) => void;
  queryParams: any;
};
