import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Button } from '@deepstream/ui-kit/elements/button/Button';

export const PageTemporarilyNotAvailable = ({
  model,
  redirectUrl,
}: {
  model: 'request' | 'template';
  redirectUrl: string
}) => {
  const { t } = useTranslation();

  return (
    <Box width="100%" textAlign="center" mt={6}>
      <Icon icon="clock" color="info" regular fontSize={8} />
      <Box as="h1" mt={3}>
        {t('pageTemporarilyNotAvailable.heading')}
      </Box>
      <Box as="h3" mt={2}>
        {model === 'request' ? (
          t('pageTemporarilyNotAvailable.requestMessage')
        ) : model === 'template' ? (
          t('pageTemporarilyNotAvailable.templateMessage')
        ) : (
          null
        )}
        <br />
        {model && t('pageTemporarilyNotAvailable.processingMayTakeSeveralMinutes')}
        <br />
        <br />
        {t('pageTemporarilyNotAvailable.pleaseCheckAgainLater')}
        {redirectUrl && (
          <Flex justifyContent="center" mt={4}>
            <Button onClick={() => { window.location.href = redirectUrl; }}>
              {t('pageTemporarilyNotAvailable.tryAgain')}
            </Button>
          </Flex>
        )}
      </Box>
    </Box>
  );
};
