import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export enum MultiStageBehavior {
  SINGLE = 'single',
  PER_STAGE = 'perStage',
}

export const useMultiStageBehaviorSelectItems = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return Object.values(MultiStageBehavior).map(value => ({
      value,
      label: t(`request.multiStageBehavior.${value}.label`),
      description: t(`request.multiStageBehavior.${value}.description`),
    }));
  }, [t]);
};
