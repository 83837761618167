import { useMemo, useEffect, useCallback } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { matches } from 'lodash';
import { useNavigate } from '@tanstack/react-router';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils';
import { Tab, TabListPanel, TabPanels, TabPanel, Tabs } from '../../ui/TabsVertical';
import { SidebarLayout } from '../../ui/ProfileLayout';
import { useDeviceSize } from '../../ui/useDeviceSize';
import { ErrorPanel } from '../../ui/ErrorMessage';
import { LoadingPanel } from '../../ui/Loading';
import { LiveSentContracts, DraftSentContracts } from './SentContracts';
import { ReceivedContracts } from './ReceivedContracts';
import { UserFlagsProvider, useUserFlags } from '../../UserFlagsContext';
import { useCurrentUser } from '../../useCurrentUser';
import { ReverseOrderIf } from '../../ReverseOrderIf';
import { RequestTagsProvider, useRequestTagsContext } from '../RequestTags/RequestTagsContext';
import { getTagIdFromIndex, isTagIndex, getTreeIndexFromId } from '../RequestTags/utils';
import RequestTagsPanel from '../RequestTags/RequestTagsPanel';
import { ContractsTagPanel } from './ContractsTagPanel';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useLocalStorageState } from '../../useLocalStorageState';
import { useNotificationSubject } from '../Notifications/useNotificationSubject';
import { contractsRoute } from '../../AppRouting';
import { ContractTemplates } from './ContractTemplates';
import { useSystemFeatureFlags } from '../../systemFeatureFlags';

const supplierTabs = ['received', 'sent', 'draft', 'templates'];
const buyerOrBothTabs = ['sent', 'received', 'draft', 'templates'];

export const ContractTabs = ({
  selectedTabId,
  navigateToContractsTab,
}: {
  selectedTabId?: string;
  navigateToContractsTab: (tab?: string, replace?: boolean) => void;
}) => {
  const { t } = useTranslation(['general', 'company']);
  const { isExtraSmall, isSmall } = useDeviceSize();
  const { tags, isLoading: areTagsLoading } = useRequestTagsContext();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const { hasSendContractPermission } = useUserFlags();
  const systemFeatureFlags = useSystemFeatureFlags();

  const {
    belongsToSupplierOnlyCompany,
    status: userFlagsStatus,
  } = useUserFlags();

  const contractTabs = userFlagsStatus === 'success' ? (
    belongsToSupplierOnlyCompany ? supplierTabs : buyerOrBothTabs
  ) : undefined;

  const tagsTabs = useMemo(
    () => tags.map(tag => tag._id),
    [tags],
  );

  const tabs = useMemo(
    // @ts-expect-error ts(2769) FIXME: No overload matches this call.
    () => (contractTabs ?? [])?.concat(tagsTabs),
    [contractTabs, tagsTabs],
  );

  const [lastActiveTab, setLastActiveTab] = useLocalStorageState<string>({
    key: `${currentCompanyId}.${currentUser._id}.contractsPage.lastActiveTab`,
    // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string | (() => string)'.
    defaultValue: contractTabs?.[0],
  });

  useEffect(
    () => {
      if (selectedTabId && selectedTabId !== lastActiveTab) {
        setLastActiveTab(selectedTabId);
      }
    },
    [selectedTabId, lastActiveTab, setLastActiveTab],
  );

  useEffect(
    () => {
      if (contractTabs && !selectedTabId) {
        navigateToContractsTab(lastActiveTab || contractTabs[0], true);
      }
    },
    [contractTabs, selectedTabId, lastActiveTab, navigateToContractsTab],
  );

  const onTabsChange = useCallback(
    (index) => {
      if (tabs && !isTagIndex(index)) {
        navigateToContractsTab(tabs[index]);
      }
      if (isTagIndex(index)) {
        navigateToContractsTab(getTagIdFromIndex(index));
      }
    },
    [tabs, navigateToContractsTab],
  );

  const selectedTabIndex = !selectedTabId ? (
    -1
  ) : tagsTabs.includes(selectedTabId) ? (
    getTreeIndexFromId(selectedTabId)
  ) : (
    tabs?.indexOf(selectedTabId)
  );

  // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'.
  const showContractTemplates = hasSendContractPermission && systemFeatureFlags.contractTemplatesEnabled;

  return userFlagsStatus === 'loading' || areTagsLoading ? (
    <LoadingPanel />
  ) : userFlagsStatus === 'error' ? (
    <ErrorPanel error={t('errors.getCompany', { ns: 'company' })} />
  ) : (
    <Tabs key={tabs.length} index={selectedTabIndex} onChange={onTabsChange}>
      <SidebarLayout
        sidebar={
          <>
            <TabListPanel>
              <ReverseOrderIf condition={belongsToSupplierOnlyCompany}>
                <Tab data-test="sent-tab" key="sent">
                  <Flex alignItems="center">
                    <Icon icon="share" mr={2} fixedWidth />
                    <Text flex={1}>{t('sent_other', { ns: 'contracts' })}</Text>
                  </Flex>
                </Tab>
                <Tab data-test="received-tab" key="received">
                  <Flex alignItems="center">
                    <Icon icon="reply" mr={2} fixedWidth />
                    <Text flex={1}>{t('received_other', { ns: 'contracts' })}</Text>
                  </Flex>
                </Tab>
              </ReverseOrderIf>
              <Tab data-test="draft-tab" key="draft">
                <Flex alignItems="center">
                  <Icon icon="pencil" mr={2} fixedWidth />
                  <Text flex={1}>{t('draft', { ns: 'contracts' })}</Text>
                </Flex>
              </Tab>
              {showContractTemplates && (
                <Tab data-test="templates-tab" key="templates">
                  <Flex alignItems="center">
                    <Icon icon="files-o" mr={2} fixedWidth />
                    <Text flex={1}>{t('template_other', { ns: 'contracts' })}</Text>
                  </Flex>
                </Tab>
              )}
            </TabListPanel>
            <RequestTagsPanel />
          </>
        }
        main={
          <TabPanels>
            <ReverseOrderIf condition={belongsToSupplierOnlyCompany}>
              <TabPanel key="sent">
                <LiveSentContracts />
              </TabPanel>
              <TabPanel key="received">
                <ReceivedContracts />
              </TabPanel>
            </ReverseOrderIf>
            <TabPanel key="draft">
              <DraftSentContracts />
            </TabPanel>
            {showContractTemplates && (
              <TabPanel key="templates">
                <ContractTemplates />
              </TabPanel>
            )}
            {tags?.map(tag => (
              <ContractsTagPanel requestTag={tag} key={tag._id} />
            ))}
          </TabPanels>
        }
        sidebarStyle={!isExtraSmall && !isSmall ? { maxWidth: '350px', flex: '0 0 auto' } : undefined}
        mainStyle={!isExtraSmall && !isSmall ? { flex: '1 1 auto' } : undefined}
      />
    </Tabs>
  );
};

export const Contracts = ({ selectedTabId }: { selectedTabId?: string }) => {
  const currentCompanyId = useCurrentCompanyId();
  const user = useCurrentUser();
  const navigate = useNavigate();

  // When a user enters the 'sent' or 'received' tab, mark all sent or received
  // notifications about the user having been removed from a contract as read.
  // This is not perfectly accurate since the user initially only sees the
  // first page of the contracts table, and thus notifications might get marked as
  // read that are not visible to the user.
  const notificationsFilter = useMemo(
    () => {
      switch (selectedTabId) {
        case 'sent':
          return matches({
            domain: NotificationDomain.CONTRACT_SENT,
            action: NotificationAction.REMOVED_MEMBER,
            to: { companyId: currentCompanyId },
          });
        case 'received':
          return matches({
            domain: NotificationDomain.CONTRACT_RECEIVED,
            action: NotificationAction.REMOVED_MEMBER,
            to: { companyId: currentCompanyId },
          });
        default:
          return () => false;
      }
    },
    [currentCompanyId, selectedTabId],
  );

  const notificationsRef = useNotificationSubject({ filter: notificationsFilter });

  const navigateToContractsTab = useCallback(
    (tab?: string, replace?: boolean) => {
      navigate({
        to: contractsRoute.to,
        // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
        params: { currentCompanyId },
        search: tab ? { tab } : undefined,
        replace,
      });
    },
    [currentCompanyId, navigate],
  );

  return user ? (
    <UserFlagsProvider>
      <RequestTagsProvider>
        <Box ref={notificationsRef}>
          <ContractTabs
            selectedTabId={selectedTabId}
            navigateToContractsTab={navigateToContractsTab}
          />
        </Box>
      </RequestTagsProvider>
    </UserFlagsProvider>
  ) : (
    null
  );
};
