import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';
import * as yup from 'yup';
import { Formik, useFormikContext, Form } from 'formik';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Modal, ModalBody, ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useRejectQuestionnaire } from './questionnaireMutations';
import { TextField } from '../../../form/TextField';
import { StepFooter } from '../../../StepFooter';
import { CompanyLogo } from '../../../CompanyLogo';
import { useQuestionnaireData } from './questionnaireUtils';

const rejectionModalSteps = {
  form: 'form',
  confirmation: 'confirmation',
};

const RejectionForm = ({
  onBack,
  onChangeStep,
}: {
  onBack: () => void;
  onChangeStep: (step: 'form' | 'confirmation') => void;
}) => {
  const { t } = useTranslation(['preQualification', 'general']);
  const { isValid, dirty, isSubmitting } = useFormikContext();

  return (
    <Form>
      <PanelPadding>
        <TextField
          name="rejectionReason"
          isMultiLine
          label={t('questionnaire.rejectionModal.explanation')}
          required
        />
      </PanelPadding>
      <StepFooter
        areStepsVisible
        steps={rejectionModalSteps}
        activeState="form"
        currentStepName={t('questionnaire.rejectionModal.footer.form')}
        isBackVisible
        backButtonText={t('cancel', { ns: 'general' })}
        onBack={onBack}
        onContinue={() => onChangeStep('confirmation')}
        isNextDisabled={isSubmitting || !dirty || !isValid}
      />
    </Form>
  );
};

const ConfirmationModalContent = ({
  onBack,
}: {
  onBack: () => void;
}) => {
  const { t } = useTranslation(['preQualification', 'general']);
  const { isValid, isSubmitting, values: { rejectionReason } } = useFormikContext<{ rejectionReason: string; }>();
  const questionnaire = useQuestionnaireData();

  const [rejectQuestionnaire] = useRejectQuestionnaire();

  return (
    <>
      <PanelPadding>
        <Stack gap={3}>
          <Box>
            <Text fontSize={2} fontWeight={500} mb={2}>
              {t('supplier', { count: 1, ns: 'general' })}
            </Text>
            <Flex alignItems="center">
              <CompanyLogo companyId={questionnaire.recipient._id} />
              <Text>{questionnaire.recipient.name}</Text>
            </Flex>
          </Box>
          <Box>
            <Text fontSize={2} fontWeight={500} mb={1}>
              {t('questionnaire.rejectionModal.rejectionReason')}
            </Text>
            <Text>
              {rejectionReason}
            </Text>
          </Box>
        </Stack>
      </PanelPadding>
      <StepFooter
        areStepsVisible
        steps={rejectionModalSteps}
        activeState="confirmation"
        currentStepName={t('questionnaire.rejectionModal.footer.confirmation')}
        isBackVisible
        onBack={onBack}
        onContinue={async () => { await rejectQuestionnaire({ rejectionReason }); }}
        isNextDisabled={isSubmitting || !isValid}
      />
    </>
  );
};

export const RejectQuestionnaireModal = ({
  close,
}: {
  close: () => void;
}) => {
  const { t } = useTranslation(['preQualification', 'general']);
  const [step, setStep] = useState<'form' | 'confirmation'>('form');

  return (
    <Modal style={{ content: { width: '585px' } }} isOpen>
      <ModalHeader onClose={close}>
        {t('questionnaire.rejectionModal.heading')}
      </ModalHeader>
      <ModalBody p={0}>
        <PanelPadding pb={0}>
          <MessageBlock variant="info" mt={0}>
            {t(`questionnaire.rejectionModal.disclaimer.${step}`)}
          </MessageBlock>
        </PanelPadding>
        <Formik
          initialValues={{
            rejectionReason: '',
          }}
          validateOnBlur
          validationSchema={
            yup.object().shape({
              rejectionReason: yup
                .string()
                .trim()
                .required(t('required', { ns: 'general' })),
            })
          }
          onSubmit={noop}
        >
          {step === 'form' ? (
            <RejectionForm
              onBack={close}
              onChangeStep={setStep}
            />
          ) : (
            <ConfirmationModalContent
              onBack={() => setStep('form')}
            />
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};
