import { useCallback } from 'react';
import * as React from 'react';
import { first } from 'lodash';
import { Flex, Box } from 'rebass/styled-components';
import { Attachment } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps, CancelUploadButton, RetryUploadButton } from '@deepstream/ui-kit/elements/button/Button';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useUpload } from '../uploads/useUploads';
import { openFilePicker } from '../uploads/openFilePicker';
import { UploadFn } from './types';
import { ProgressBarObsolete } from './ProgressBar';
import { ErrorMessage } from './ErrorMessage';
import { Row } from './ProfileLayout';

const coverStyle: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  alignItems: 'center',
  justifyContent: 'center',
};

const Overlay = (props) => (
  <Flex sx={{ ...coverStyle, backgroundColor: 'white', opacity: 0.93 }} {...props} />
);

const Cover = (props) => (
  <Flex sx={coverStyle} {...props} />
);

const RemovePhoto = (props: ButtonProps) => {
  const { t } = useTranslation('general');

  return (
    <Button small variant="secondary" type="button" iconLeft="trash" data-test="remove-photo" {...props}>
      {t('removePhoto')}
    </Button>
  );
};

const UploadPhoto = (props: ButtonProps) => {
  const { t } = useTranslation('general');

  return (
    <Button small variant="primary" type="button" iconLeft="upload" data-test="upload-photo" {...props}>
      {t('uploadPhoto')}
    </Button>
  );
};

const ReplacePhoto = (props: ButtonProps) => {
  const { t } = useTranslation('general');

  return (
    <Button small variant="primary" type="button" iconLeft="upload" data-test="replace-photo" {...props}>
      {t('replacePhoto')}
    </Button>
  );
};

export type ImageUploadControlsProps = {
  initialAttachment?: Attachment;
  onChange: (attachment: Attachment | null) => void;
  uploadFn: UploadFn;
  children: React.ReactNode;
};

export const ImageUploadControls = ({
  initialAttachment,
  uploadFn,
  onChange,
  children,
}: ImageUploadControlsProps) => {
  const { t } = useTranslation();
  const upload = useUpload({ initialAttachment, onChange, uploadFn });

  const getFile = useCallback(
    async () => first(await openFilePicker({ multiple: false })) as File,
    [],
  );

  const start = useCallback(
    async () => upload.start(await getFile()),
    [upload, getFile],
  );

  const replace = useCallback(
    async () => upload.replace(await getFile()),
    [upload, getFile],
  );

  const sanitizedError = upload.error?.replace('Error: ', '');

  return (
    <Stack gap={3}>
      <Box sx={{ position: 'relative' }}>
        {children}
        {upload.status === 'uploading' ? (
          <Cover>
            <Overlay />
            <Box sx={{ zIndex: 1 }}>
              <ProgressBarObsolete percentage={upload.progress * 100} width="110px" height="8px" />
            </Box>
          </Cover>
        ) : upload.status === 'failed' ? (
          <Cover>
            <Overlay />
            <Box sx={{ zIndex: 1 }}>
              <ErrorMessage error={sanitizedError || t('errors.uploadFailed')} data-test="upload-error" />
            </Box>
          </Cover>
        ) : (
          null
        )}
      </Box>
      <Row justifyContent="center">
        {upload.status === 'uploading' ? (
          <CancelUploadButton small onClick={upload.cancel} />
        ) : upload.status === 'failed' ? (
          <>
            <CancelUploadButton small onClick={upload.cancel} />
            {upload.canRetry && (
              <RetryUploadButton small onClick={upload.retry} ml={2} />
            )}
          </>
        ) : upload.attachment ? (
          <>
            <RemovePhoto onClick={upload.clear} mr={2} />
            <ReplacePhoto onClick={replace} />
          </>
        ) : (
          <UploadPhoto onClick={start} />
        )}
      </Row>
    </Stack>
  );
};
