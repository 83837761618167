import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { LoadingPanel } from '../../../ui/Loading';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import {
  useQuestionnaireTemplateId,
} from './questionnaireTemplateUtils';
import { DraftPageContent } from './DraftPageContent';
import { SuppliersPanel } from './SuppliersPanel';
import { useApi, wrap } from '../../../api';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { SuppliersStatsPanel } from './SuppliersStatsPanel';

export const ActiveSuppliers = () => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const templateId = useQuestionnaireTemplateId();
  const api = useApi();

  const { data: questionnaires, isLoading, isError } = useQuery(
    [
      'sentQuestionnaires',
      {
        currentCompanyId,
        filter: {
          fromTemplateId: templateId,
        },
      },
    ],
    wrap(api.getSentQuestionnaires),
  );

  const sentQuestionnaires = questionnaires?.overviews || [];

  if (isLoading) {
    return <LoadingPanel />;
  }

  if (isError) {
    return <ErrorPanel error={t('errors.unexpected')} />;
  }

  return (
    <DraftPageContent>
      <Stack gap={4}>
        <SuppliersStatsPanel questionnaires={sentQuestionnaires} />
        <SuppliersPanel questionnaires={sentQuestionnaires} />
      </Stack>
    </DraftPageContent>
  );
};
