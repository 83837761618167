import { DocumentsTable } from './DocumentsTable';
import { DocumentsTableDataProvider } from './DocumentsTableDataProvider';
import { ChatbotChat } from '../AI/chatbot/ChatbotChat';
import { useCompanyFeatureFlags } from '../../companyFeatureFlags';

export const Documents = () => {
  const companyFeatureFlags = useCompanyFeatureFlags();

  return (
    <DocumentsTableDataProvider>
      <DocumentsTable />
      {companyFeatureFlags?.driveChatbotEnabled && (
        <ChatbotChat showErrorDisclosures={false} />
      )}
    </DocumentsTableDataProvider>
  );
};
