import { useTranslation } from 'react-i18next';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { LoadingPanel } from '../../ui/Loading';
import { ErrorPanel } from '../../ui/ErrorMessage';
import { ContractTeam } from './Team/ContractTeam';
import { ContractProvider, useContractState } from './contract';
import { ContractDraftPageContent } from './ContractDraftPageContent';
import { useContract } from './useContract';
import { DraftContractFooter } from './DraftContractFooter';
import { useDraftContractNavigation } from '../../appNavigation';

const DraftTeamFooter = () => {
  const { navigateToReview, navigateToContract, navigateToReminders } = useDraftContractNavigation();
  // @ts-expect-error ts(2339) FIXME: Property 'isRevising' does not exist on type 'ContractStateContextType | undefined'.
  const { isRevising, isAmending } = useContractState();

  return (
    <DraftContractFooter
      onBack={navigateToContract}
      onContinue={isAmending || isRevising ? navigateToReview : navigateToReminders}
    />
  );
};

export const ContractDraftTeam = ({
  contractId,
}: {
  contractId: string;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { data: contract, isLoading, isError, isSuccess } = useContract({
    contractId,
    currentCompanyId,
    scope: 'draft',
  });

  return (
    <>
      {isLoading ? (
        <LoadingPanel />
      ) : isError ? (
        <ErrorPanel error={t('errors.unexpected')} />
      ) : isSuccess && contract ? (
        <ContractProvider contract={contract}>
          <ContractDraftPageContent>
            <ContractTeam />
          </ContractDraftPageContent>
          <DraftTeamFooter />
        </ContractProvider>
      ) : (
        null
      )}
    </>
  );
};
