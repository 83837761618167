import { useMemo, useState, useCallback } from 'react';
import { Accordion, AccordionItem, AccordionPanel, AccordionButton } from '@reach/accordion';
import { includes, filter, map, difference } from 'lodash';
import { Text, Box, Flex } from 'rebass/styled-components';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { Counter } from '../../../ui/Badge';
import * as rfx from '../../../rfx';
import {
  useUpdateExchangesBulk,
  useSectionExchanges,
  usePageExchanges,
  usePageVisibleSectionsIds,
  useAvailableExchangesIdToImport,
} from './utils';

import { ImportDetailsExchangesList } from './ImportDetailsExchangesList';

const ImportDetailsSectionHeader = ({
  sectionName,
  isActive,
  exchangesIds,
  selectedIds,
}: {
  exchangesIds: string[];
  selectedIds: string[];
  sectionName: string;
  isActive: boolean,
}) => {
  const bulkUpdate = useUpdateExchangesBulk();
  const sectionExchangesIdToImport = useAvailableExchangesIdToImport(exchangesIds);

  const allChecked = selectedIds.length === exchangesIds.length;
  const noneChecked = !selectedIds.length;
  const allSectionExchangesImported = sectionExchangesIdToImport.length === 0;

  const handleCheckAll = useCallback(() => {
    const value = difference(sectionExchangesIdToImport, selectedIds).length === sectionExchangesIdToImport.length;

    bulkUpdate(sectionExchangesIdToImport, value);
  }, [bulkUpdate, selectedIds, sectionExchangesIdToImport]);

  return (
    <Flex
      sx={{
        backgroundColor: 'lightGray3',
        padding: '15px',
        borderBottom: 'lightGray2',
        alignItems: 'center',
      }}
    >
      <Checkbox
        onChange={handleCheckAll}
        indeterminate={!noneChecked && !allChecked}
        checked={allChecked}
        disabled={allSectionExchangesImported}
      />
      <Truncate sx={{ mx: 2 }}>
        <Text>{sectionName}</Text>
      </Truncate>
      <Counter
        disabled
        color="secondary"
        count={exchangesIds.length}
        sx={{ fontSize: '10px', width: '20px', height: '20px' }}
      />
      <AccordionButton
        as={Box}
        sx={{ marginLeft: 'auto', cursor: 'pointer', px: 2 }}
      >
        <Icon icon={isActive ? 'chevron-up' : 'chevron-down'} color="gray" />
      </AccordionButton>
    </Flex>
  );
};

const ImportDetailsSection = ({
  isActive,
  sectionId,
}: {
  isActive: boolean;
  sectionId: string;
}) => {
  const { sectionById } = rfx.useStructure();
  const { name } = sectionById[sectionId];
  const { exchanges, exchangesIds, selectedIds } = useSectionExchanges(sectionId);

  return (
    <AccordionItem key={sectionId} as={Box} sx={{ overflow: 'auto' }}>
      <ImportDetailsSectionHeader
        selectedIds={selectedIds}
        exchangesIds={exchangesIds}
        sectionName={name}
        isActive={isActive}
      />

      <AccordionPanel as={Box}>
        <ImportDetailsExchangesList exchanges={exchanges} />
      </AccordionPanel>
    </AccordionItem>
  );
};

export const ImportDetailsSectionsList = ({
  selectedPageId,
}: {
  selectedPageId: string;
}) => {
  const sections = usePageVisibleSectionsIds(selectedPageId);
  const { pageById } = rfx.useStructure();
  const { selectedIds, exchangesIds } = usePageExchanges(selectedPageId);
  const bulkUpdate = useUpdateExchangesBulk();
  const pageExchangesIdToImport = useAvailableExchangesIdToImport(exchangesIds);

  const allPageExchangesImported = pageExchangesIdToImport.length === 0;
  const allChecked = selectedIds.length === exchangesIds.length;
  const noneChecked = !selectedIds.length;

  const pageName = useMemo(() => {
    return pageById[selectedPageId].name;
  }, [pageById, selectedPageId]);

  const [activeSections, setActiveSections] = useState<number[]>([
    ...Array(sections.length).keys(),
  ]);

  const handleAccordionChange = useCallback((index: number) => {
    setActiveSections((prev) => {
      if (includes(prev, index)) {
        return filter(prev, (value) => value !== index);
      }

      return [...prev, index];
    });
  }, []);

  const handleCheckAll = useCallback(() => {
    const value = difference(pageExchangesIdToImport, selectedIds).length === pageExchangesIdToImport.length;

    bulkUpdate(pageExchangesIdToImport, value);
  }, [bulkUpdate, pageExchangesIdToImport, selectedIds]);

  return (
    <>
      <Checkbox
        indeterminate={!noneChecked && !allChecked}
        checked={allChecked}
        disabled={allPageExchangesImported}
        onChange={handleCheckAll}
        label={(
          <Text sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
            {pageName}
          </Text>
        )}
        style={{
          borderBottom: '1px solid #e2e8ef',
          padding: '15px',
        }}
      />
      <Accordion
        multiple
        collapsible
        defaultIndex={activeSections}
        onChange={handleAccordionChange}
        as={Box}
        sx={{ height: '600px', overflow: 'hidden' }}
      >
        {map(sections, (sectionId, index) => {
          return (
            <ImportDetailsSection
              key={sectionId}
              sectionId={sectionId}
              isActive={includes(activeSections, index)}
            />
          );
        })}
      </Accordion>
    </>
  );
};
