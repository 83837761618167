import { useMemo } from 'react';

import { IntegrationMarketplaceEventType, IntegrationMarketplaceOrigin, ExcelFlowTrackingEventType, MultiStageLineItemsEventType, LegacyMultiStageLineItemsEventType } from '@deepstream/common';

import { TrackedFeature } from '@deepstream/common/featureTrackingEvent';
import { useMutation } from './useMutation';
import { useApi } from './api';
import { useCurrentCompanyId } from './currentCompanyId';

export const useTracking = () => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const [trackEvent] = useMutation(api.trackEvent);

  return useMemo(() => ({
    trackAccessRestricted: (feature: TrackedFeature) => {
      trackEvent({
        currentCompanyId,
        eventType: 'accessRestricted',
        data: {
          feature,
          companyId: currentCompanyId,
        },
      });
    },
    trackInformationRequested: (feature: TrackedFeature) => {
      trackEvent({
        currentCompanyId,
        eventType: 'informationRequested',
        data: {
          feature,
          companyId: currentCompanyId,
        },
      });
    },
    trackIntegrationMarketplaceEvent: ({
      eventType,
      selectedIntegrations,
      searchQuery,
      searchResults,
      integrationName,
      origin,
    }: {
      eventType: IntegrationMarketplaceEventType,
      selectedIntegrations?: string[],
      searchQuery?: string,
      searchResults?: string[],
      integrationName? :string,
      origin?: IntegrationMarketplaceOrigin,
    }) => {
      trackEvent({
        currentCompanyId,
        eventType,
        data: {
          selectedIntegrations,
          searchQuery,
          searchResults,
          integrationName,
          origin,
        },
      });
    },
    trackExcelSubmitEvent: ({
      eventType,
      requestId,
      requestStageNumber,
      requestStageName,
      totalLineItems,
      lineItemsSubmitted,
      lineItemsFailed,
      lineItemsExcluded,
    }: {
      eventType: ExcelFlowTrackingEventType,
      requestId: string,
      requestStageNumber: number,
      requestStageName: string,
      totalLineItems?: number,
      lineItemsSubmitted?: number,
      lineItemsFailed?: number,
      lineItemsExcluded?: number
    }) => {
      trackEvent({
        currentCompanyId,
        eventType,
        data: {
          companyId: currentCompanyId,
          requestId,
          requestStageNumber,
          requestStageName,
          totalLineItems,
          // @ts-expect-error ts(2322) FIXME: Type 'number | undefined' is not assignable to type 'number'.
          lineItemsSubmitted,
          // @ts-expect-error ts(2322) FIXME: Type 'number | undefined' is not assignable to type 'number'.
          lineItemsFailed,
          // @ts-expect-error ts(2322) FIXME: Type 'number | undefined' is not assignable to type 'number'.
          lineItemsExcluded,
        },
      });
    },
    trackMultiStageLineItemsEvent: ({
      eventType,
      entityType,
      entityId,
    }: {
      eventType: MultiStageLineItemsEventType | LegacyMultiStageLineItemsEventType,
      entityType: 'request' | 'requestTemplate',
      entityId: string,
    }) => {
      trackEvent({
        currentCompanyId,
        eventType,
        data: {
          entityType,
          entityId,
        },
      });
    },
  }), [currentCompanyId, trackEvent]);
};
