import {
  RfxSection,
  ChangeType,
  ExchangeUpdatedChange,
  Stage,
  ExchangeRemovedChange,
  SectionRemovedChange,
  SectionUpdatedChange,
  PageUpdatedChange,
  DetailsChange,
  StageUpdatedChange,
  Section,
  Draft,
  ExchangeDefinition,
  isLineItemExchangeDef,
  FieldConfig,
  getTagFromStageId,
  ResponseTag,
} from '@deepstream/common/rfq-utils';
import { addMinutes } from 'date-fns';
import { cloneDeep, find, first, intersection, isNil, mapValues, omit, values, without } from 'lodash';
import { immutableUpdate } from '@deepstream/utils';
import { isAuctionStage, sanitizePublishableEntity } from '../utils';
import { RfxStructure, StructureStage } from '../types';
import * as rfx from '../rfx';

const sanitizeSection = (section: RfxSection) =>
  omit(section, ['isLive', 'liveVersion', 'creatorId', 'exchangeDefIds', 'hirePeriodIds']) as Section;

/**
 * When the provided stage is private, returns changes to make all subsequent
 * not-private stages private.
 * Otherwise, returns an empty array.
 */
export const getStageUpdatedRelatedChanges = (
  stage: StructureStage<Draft>,
  stageIndex: number,
  rfxStructure: RfxStructure<Draft>,
): DetailsChange[] => {
  const { stages } = rfxStructure;

  if (!stage.isPrivate) return [];

  const stagesToUpdate = stages
    .slice(stageIndex + 1)
    .filter(stage => !stage.isPrivate);

  return stagesToUpdate.map(stage => ({
    type: ChangeType.STAGE_UPDATED,
    stage: {
      ...sanitizePublishableEntity(stage),
      isPrivate: true,
    } as Stage<Draft>,
  }));
};

const removeTagFromSection = (section: RfxSection, tag: ResponseTag) => {
  if (section.responseTagConfig) {
    return immutableUpdate(
      section,
      'responseTagConfig.tags',
      tags => tags.filter(sectionTag => sectionTag !== tag),
    );
  } else {
    return section;
  }
};

const removeTagFromFields = (exchangeDef: ExchangeDefinition, tag: ResponseTag) => {
  if (isLineItemExchangeDef(exchangeDef) && exchangeDef.fields) {
    return immutableUpdate(
      exchangeDef,
      'fields',
      (fields) => mapValues(
        fields,
        (field: FieldConfig) => {
          return field.responseTags
            ? {
              ...field,
              responseTags: without(field.responseTags, tag),
            }
            : field;
        },
      ),
    );
  } else {
    return exchangeDef;
  }
};

const reorderSectionTags = (section: RfxSection, availableTags: ResponseTag[]) => {
  if (section.responseTagConfig) {
    return immutableUpdate(
      section,
      'responseTagConfig.tags',
      tags => intersection(availableTags, tags),
    );
  } else {
    return section;
  }
};

const reorderFieldTags = (exchangeDef: ExchangeDefinition, availableTags: ResponseTag[]) => {
  if (isLineItemExchangeDef(exchangeDef) && exchangeDef.fields) {
    return immutableUpdate(
      exchangeDef,
      'fields',
      (fields) => mapValues(
        fields,
        (field: FieldConfig) => {
          return field.responseTags
            ? {
              ...field,
              responseTags: intersection(availableTags, field.responseTags),
            }
            : field;
        },
      ),
    );
  } else {
    return exchangeDef;
  }
};
export const getStageRemovedRelatedChanges = (
  stage: Stage<Draft>,
  rfxStructure: RfxStructure<Draft>,
): DetailsChange[] => {
  const { stages, sectionById, exchangeDefById, pages } = rfxStructure;

  const exchangeRemovedChanges: ExchangeRemovedChange[] = [];
  const exchangeUpdatedChanges: ExchangeUpdatedChange[] = [];
  const sectionRemovedChanges: SectionRemovedChange[] = [];
  const sectionUpdatedChanges: SectionUpdatedChange[] = [];
  const pageUpdatedChanges: PageUpdatedChange[] = [];
  const stageUpdatedChanges: StageUpdatedChange[] = [];
  const removedSectionIds: string[] = [];

  // If there will be only one stage left we need to reset its visibility
  if (stages.length === 2) {
    const remainingStage = stages.find(rfqStage => rfqStage._id !== stage._id)!;

    stageUpdatedChanges.push({
      type: ChangeType.STAGE_UPDATED,
      stage: {
        ...sanitizePublishableEntity(remainingStage),
        isPrivate: false,
      } as Stage<Draft>,
    });
  }

  for (const section of values(sectionById)) {
    if (section.stages) {
      if (first(section.stages) === stage._id) {
        removedSectionIds.push(section._id);
        sectionRemovedChanges.push({
          type: ChangeType.SECTION_REMOVED,
          sectionId: section._id,
        });
      } else if (section.stages.includes(stage._id)) {
        sectionUpdatedChanges.push({
          type: ChangeType.SECTION_UPDATED,
          section: {
            ...sanitizeSection(removeTagFromSection(section, getTagFromStageId(stage._id))),
            stages: section.stages.filter(stageId => stageId !== stage._id),
          },
        });
      }
    }

    for (const exchangeDefId of section.exchangeDefIds) {
      const exchangeDef = exchangeDefById[exchangeDefId];

      if (first(exchangeDef.stages) === stage._id) {
        exchangeRemovedChanges.push({
          type: ChangeType.EXCHANGE_REMOVED,
          sectionName: section._id,
          docXDefId: exchangeDef._id,
        });
      } else if (exchangeDef.stages?.includes(stage._id)) {
        exchangeUpdatedChanges.push({
          type: ChangeType.EXCHANGE_UPDATED,
          sectionName: section._id,
          docXDef: {
            ...sanitizePublishableEntity(removeTagFromFields(exchangeDef, getTagFromStageId(stage._id))),
            stages: exchangeDef.stages?.filter(stageId => stageId !== stage._id),
          } as ExchangeDefinition,
        });
      }
    }
  }

  for (const sectionId of removedSectionIds) {
    const page = find(
      pages,
      page => page.sections.includes(sectionId),
    );

    if (!page) {
      throw new Error(`Section ${sectionId} could not be found in any page.`);
    }

    const pageAlreadyUpdated = Boolean(find(
      pageUpdatedChanges,
      change => change.page._id === page._id,
    ));

    if (!pageAlreadyUpdated) {
      pageUpdatedChanges.push({
        type: ChangeType.PAGE_UPDATED,
        page: {
          ...page,
          sections: without(page.sections, ...removedSectionIds),
        },
      });
    }
  }

  return [
    ...stageUpdatedChanges,
    ...exchangeRemovedChanges,
    ...exchangeUpdatedChanges,
    ...sectionRemovedChanges,
    ...sectionUpdatedChanges,
    ...pageUpdatedChanges,
  ];
};

export const getStagesReorderedRelatedChanges = (
  newStageIds: string[],
  lastAffectedStageId: string,
  rfxStructure: RfxStructure<Draft>,
): DetailsChange[] => {
  const { sectionById, exchangeDefById } = rfxStructure;

  const exchangeUpdatedChanges: ExchangeUpdatedChange[] = [];
  const sectionUpdatedChanges: SectionUpdatedChange[] = [];
  const sections = values(sectionById);

  for (const section of sections) {
    if (section.stages?.includes(lastAffectedStageId)) {
      const firstVisibleStageId = first(section.stages)!;
      const newIndex = newStageIds.indexOf(firstVisibleStageId);
      const newSectionStageIds = newStageIds.slice(newIndex);

      sectionUpdatedChanges.push({
        type: ChangeType.SECTION_UPDATED,
        section: {
          ...sanitizeSection(
            reorderSectionTags(section, newSectionStageIds.map(stageId => getTagFromStageId(stageId))),
          ),
          stages: newSectionStageIds,
        },
      });
    }

    for (const exchangeDefId of section.exchangeDefIds) {
      const exchangeDef = exchangeDefById[exchangeDefId];

      if (exchangeDef.stages?.includes(lastAffectedStageId)) {
        const firstVisibleStageId = first(exchangeDef.stages)!;
        const newIndex = newStageIds.indexOf(firstVisibleStageId);
        const newExchangeStageIds = newStageIds.slice(newIndex);

        exchangeUpdatedChanges.push({
          type: ChangeType.EXCHANGE_UPDATED,
          sectionName: section._id,
          docXDef: {
            ...sanitizePublishableEntity(
              reorderFieldTags(exchangeDef, newExchangeStageIds.map(stageId => getTagFromStageId(stageId))),
            ) as ExchangeDefinition,
            stages: newExchangeStageIds,
          },
        });
      }
    }
  }

  return [
    ...exchangeUpdatedChanges,
    ...sectionUpdatedChanges,
  ];
};

export const getStageAddedRelatedChanges = (newStageId: string, rfxStructure: RfxStructure<Draft>) => {
  const { sectionById, exchangeDefById } = rfxStructure;
  const exchangeUpdatedChanges: ExchangeUpdatedChange[] = [];
  const sectionUpdatedChanges: SectionUpdatedChange[] = [];
  const sections = values(sectionById);

  for (const section of sections) {
    if (section.stages) {
      const newSection = cloneDeep(section);
      newSection.stages!.push(newStageId);

      sectionUpdatedChanges.push({
        type: ChangeType.SECTION_UPDATED,
        section: sanitizeSection(newSection),
      });
    }

    for (const exchangeDefId of section.exchangeDefIds) {
      const exchangeDef = exchangeDefById[exchangeDefId];

      if (exchangeDef.stages) {
        const newExchangeDef = sanitizePublishableEntity(cloneDeep(exchangeDef)) as ExchangeDefinition;
        newExchangeDef.stages!.push(newStageId);

        exchangeUpdatedChanges.push({
          type: ChangeType.EXCHANGE_UPDATED,
          sectionName: section._id,
          docXDef: newExchangeDef,
        });
      }
    }
  }

  return [
    ...exchangeUpdatedChanges,
    ...sectionUpdatedChanges,
  ];
};

export const getLowerBound = (stage: StructureStage<Draft> | null) => {
  if (!stage) {
    return null;
  }

  return isAuctionStage(stage)
    ? stage.startDate
    : stage.completionDeadline;
};

export const getUpperBound = (
  sectionById: RfxStructure<Draft>['sectionById'],
  stage: StructureStage<Draft> | null,
) => {
  if (!stage) {
    return null;
  }

  if (isAuctionStage(stage)) {
    const { startDate } = stage;

    const duration = rfx.getAuctionLineItemsSection(sectionById, stage._id)?.auctionRules.duration;

    if (isNil(startDate) || isNil(duration)) {
      return null;
    }

    return addMinutes(new Date(startDate), duration);
  } else {
    return stage.completionDeadline;
  }
};
