import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';
import { get, isEmpty, keyBy, noop, set, size, uniq } from 'lodash';
import { Company, LotIntentionStatus, getEmptyFieldValue, isFieldValueDefined } from '@deepstream/common/rfq-utils';
import { ColumnData, RowData } from '@deepstream/ui-kit/grid/core/utils';
import { EditableGrid } from '@deepstream/ui-kit/grid/EditableGrid/EditableGrid';
import { useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { DefaultEditableGridStyles } from '@deepstream/ui-kit/grid/EditableGrid/EditableGridStyles';
import { useGridMenuState } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { EditableGridColumn, calculateMaxGridHeight } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { useEditableGridActions } from '@deepstream/ui-kit/grid/EditableGrid/useEditableGridActions';
import { withProps } from '@deepstream/ui-utils/withProps';
import { LotsGridMenu } from './LotsGridMenu';
import { SimpleHeader } from './header';
import { useHandleGridClipboardEvent } from './useHandleGridClipboardEvent';
import { AwardDecisionLabelCell, AwardedSupplierValueCell } from './nonValidationAwareValueCell';
import { AwardedSupplierInputCell } from './inputCell/AwardSupplierInputCell';
import { CompanyLogoAndName } from '../../CompanyLogo';
import { GridAwardDecision } from '../../modules/Request/AwardFlow/types';

const frozenLeftColumnIds = ['label', 'awardDecision'];
const navigableRange = { startRowIndex: 1, startColumnIndex: 0 };
const selectableRange = { startColumnIndex: 1, endColumnIndex: 2 };

// used only in the context of copy-pasting
const VALUE_SEPARATOR = '|';
// used only in the context of copy-pasting
const NO_AWARD_PLACEHOLDER = 'noAward';
// used only in the context of copy-pasting
const MARK_AS_OBSOLETE_PLACEHOLDER = 'markAsObsolete';
// used only in the context of copy-pasting
const NO_SELECTION_PLACEHOLDER = '-';

export type AwardDecisionGridRowData = {
  _id: string;
  label: string;
  supportsAwardToMultipleSuppliers?: boolean;
  awardDecision: GridAwardDecision | null;
  lotIntentionStatusByRecipientId?: Record<string, LotIntentionStatus>;
  latestResponseExchangeIdByRecipientId?: Record<string, string>;
  bidAmountByRecipientId: Record<string, number | null>;
  awardableRecipientIds: string[];
  canObsolete?: boolean;
  isSubHeader?: boolean;
};

const generateCsvCellValue = (columnConfig, awardDecisionGridRowData) => {
  const awardDecision = awardDecisionGridRowData[columnConfig._id];

  if (!awardDecision) {
    return NO_SELECTION_PLACEHOLDER;
  } else if (awardDecision.value === 'noAward') {
    return NO_AWARD_PLACEHOLDER;
  } else if (awardDecision.value === 'markAsObsolete') {
    return MARK_AS_OBSOLETE_PLACEHOLDER;
  } else {
    return awardDecision.awardedSupplierIds.join(VALUE_SEPARATOR);
  }
};

const checkIsInputValid = (
  parsedValue: GridAwardDecision | null,
  canSelectMultipleSuppliers: boolean,
  { awardableRecipientIds, canObsolete, isSubHeader, supportsAwardToMultipleSuppliers }: AwardDecisionGridRowData,
) => {
  if (isSubHeader) {
    return false;
  }

  if (!parsedValue || parsedValue.value === 'noAward') {
    return true;
  }

  if (parsedValue.value === 'markAsObsolete') {
    return Boolean(canObsolete);
  }

  const { awardedSupplierIds } = parsedValue;

  if (isEmpty(awardedSupplierIds)) {
    return false;
  }

  if ((!canSelectMultipleSuppliers || !supportsAwardToMultipleSuppliers) && size(awardedSupplierIds) > 1) {
    return false;
  }

  return awardedSupplierIds.every(recipientId => awardableRecipientIds.includes(recipientId));
};

const useAddCsvDataToRows = (canSelectMultipleSuppliers: boolean) => {
  return React.useCallback(async ({ rows, data, affectedColumns }: {
    rows: any;
    data: any;
    affectedColumns: any;
  }) => {
    let validCellCount = 0;

    const updatedRows = rows.map((awardDecisionGridRowData: AwardDecisionGridRowData, lotIndex: number) => {
      const rowData = data[lotIndex] ?? [];
      const updatedLot = { ...awardDecisionGridRowData };

      affectedColumns.forEach((column, index) => {
        const rawValue = rowData[index];

        const parsedValue = !rawValue || rawValue === NO_SELECTION_PLACEHOLDER ? (
          null
        ) : rawValue === NO_AWARD_PLACEHOLDER ? ({
          value: 'noAward',
        } as const) : rawValue === MARK_AS_OBSOLETE_PLACEHOLDER ? ({
          value: 'markAsObsolete',
        } as const) : ({
          value: 'award',
          awardedSupplierIds: uniq<string>(rawValue.split(VALUE_SEPARATOR)),
        } as const);

        const isInputValid = checkIsInputValid(
          parsedValue,
          canSelectMultipleSuppliers,
          awardDecisionGridRowData,
        );
        const canInsertValue = isInputValid;

        if (canInsertValue) {
          set(updatedLot, column.original.accessorKey, parsedValue);
        }

        if (isInputValid) {
          validCellCount += 1;
        }
      });

      return updatedLot;
    });

    return {
      updatedRows,
      validCellCount,
      ignoreCroppedRows: true,
    };
  }, [canSelectMultipleSuppliers]);
};

const createClearFieldsUpdater = ({
  startRowIndex,
  endRowIndex,
  affectedColumns,
}: { startRowIndex: number, endRowIndex: number, affectedColumns: { original: EditableGridColumn }[] }) =>
  (lot: Record<string, unknown>, index: number) => {
    if (!lot.isObsolete && index >= startRowIndex && index < endRowIndex) {
      const updatedRow = { ...lot };

      for (const column of affectedColumns) {
        const { accessorKey, fieldType } = column.original;

        const fieldValue = get(lot, accessorKey);

        // @ts-expect-error ts(2345) FIXME: Argument of type 'FieldType | undefined' is not assignable to parameter of type 'FieldType'.
        if (isFieldValueDefined(fieldValue, fieldType)) {
          // @ts-expect-error ts(2345) FIXME: Argument of type 'FieldType | undefined' is not assignable to parameter of type 'FieldType'.
          const newFieldValue = getEmptyFieldValue(fieldType);

          set(updatedRow, accessorKey, newFieldValue);
        }
      }

      return updatedRow;
    } else {
      return lot;
    }
  };

export interface EditAwardDecisionGridProps {
  recipients: Company[];
  currencyCode: string;
  showTotalCost?: boolean;
  /**
   * Determines the maximum height of the grid.
   *
   * `maxGridHeight = 100vh - viewportHeightDelta`
   */
  viewportHeightDelta?: number;
  labelColumnHeader: string;
  canSelectMultipleSuppliers?: boolean;
  RecipientCell;
}

export const EditAwardDecisionGrid = ({
  recipients,
  currencyCode,
  viewportHeightDelta,
  showTotalCost,
  labelColumnHeader,
  canSelectMultipleSuppliers,
  RecipientCell,
}: EditAwardDecisionGridProps) => {
  const { t } = useTranslation('translation');
  const handleGridClipboardEvent = useHandleGridClipboardEvent('request.awardFlow.awardDecisionCellNotPasted');
  const {
    pendingKeyboardEvent,
    rowData,
    setEditedCell,
  } = useEditableGridData<AwardDecisionGridRowData>();
  const { menuReferenceId } = useGridMenuState();

  // @ts-expect-error ts(2345) FIXME: Argument of type 'boolean | undefined' is not assignable to parameter of type 'boolean'.
  const addCsvDataToRows = useAddCsvDataToRows(canSelectMultipleSuppliers);

  const gridActions = useEditableGridActions({
    createRow: noop,
    generateCsvCellValue,
    addCsvDataToRows,
    // @ts-expect-error ts(2322) FIXME: Type '({ startRowIndex, endRowIndex, affectedColumns, }: { startRowIndex: number; endRowIndex: number; affectedColumns: { original: EditableGridColumn; }[]; }) => (lot: Record<string, unknown>, index: number) => Record<string, unknown>' is not assignable to type '({ startRowIndex, endRowIndex, affectedColumns, }: { startRowIndex: number; endRowIndex: number; affectedColumns: ({ original: any; } | null)[]; }) => (originalRowData: any, index: number) => unknown'.
    createClearFieldsUpdater,
  });

  const startEditingAwardedSupplier = React.useCallback((
    row: RowData<any, any>,
    column: ColumnData<EditableGridColumn>,
    event?: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (isEmpty(row.original.awardableRecipientIds)) {
      return;
    }

    pendingKeyboardEvent.current = event ?? null;

    setEditedCell({
      rowId: row.original._id,
      columnId: column.original._id,
    });
  }, [setEditedCell, pendingKeyboardEvent]);

  const columns: EditableGridColumn[] = React.useMemo(() => {
    const recipientById = keyBy(recipients, '_id');

    return [
      {
        _id: 'label',
        accessorKey: 'label',
        Header: SimpleHeader,
        label: labelColumnHeader,
        ValueCell: AwardDecisionLabelCell,
        width: 300,
      },
      {
        _id: 'awardDecision',
        accessorKey: 'awardDecision',
        Header: SimpleHeader,
        label: t('request.awardFlow.awardDecision'),
        startEditingCell: startEditingAwardedSupplier,
        InputCell: withProps(AwardedSupplierInputCell, { recipientById, canSelectMultipleSuppliers }),
        ValueCell: withProps(AwardedSupplierValueCell, { recipientById }),
        width: 200,
      },
      ...recipients.flatMap(recipient => ({
        _id: recipient._id,
        accessorKey: recipient._id,
        Header: withProps(SimpleHeader, { truncate: false }),
        label: <CompanyLogoAndName company={recipient.company} size="xxs" /> as any,
        description: showTotalCost
          ? t('request.fields.predefinedFieldLabel.totalCost')
          : t('request.awardFlow.steps.chooseLotLevelAwardSuppliers.lotStatus'),
        descriptionSx: { fontSize: 1, letterSpacing: '0.083333em', textTransform: 'uppercase', fontWeight: 500 },
        ValueCell: withProps(RecipientCell, { currencyCode, showTotalCost }),
        width: 175,
      })),
    ];
  }, [
    t,
    startEditingAwardedSupplier,
    recipients,
    currencyCode,
    showTotalCost,
    labelColumnHeader,
    canSelectMultipleSuppliers,
    RecipientCell,
  ]);

  const maxGridHeight = calculateMaxGridHeight(rowData.length);

  return (
    <>
      {isEmpty(rowData) ? (
        <Text fontSize={2}>
          {t('table.noDataToDisplay')}
        </Text>
      ) : (
        <DefaultEditableGridStyles
          style={{
            width: '100%',
            height: `min(100vh - ${viewportHeightDelta}px, ${maxGridHeight}px)`,
          }}
          cellContentCss="align-items: flex-start; padding: 10px"
          activeCellContentCss="padding: 8px"
        >
          <EditableGrid
            columns={columns}
            rowData={rowData as any}
            gridActions={gridActions}
            onGridClipboardEvent={handleGridClipboardEvent}
            submitEditedCellOnScroll={false}
            navigableRange={navigableRange}
            selectableRange={selectableRange}
            highlightSelectionInHeaders
            frozenLeftColumnIds={frozenLeftColumnIds}
          />
        </DefaultEditableGridStyles>
      )}
      {menuReferenceId ? (
        <LotsGridMenu />
      ) : (
        null
      )}
    </>
  );
};
