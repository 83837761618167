import { useTranslation } from 'react-i18next';
import { some } from 'lodash';
import { SectionType } from '@deepstream/common/rfq-utils';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import * as rfx from '../rfx';

interface AddSectionDropdownProps{
  disabled: boolean;
  onSelect: (sectionType: SectionType) => void;
}

export const AddSectionDropdown = ({ disabled, onSelect }: AddSectionDropdownProps) => {
  const { t } = useTranslation();
  const { sectionById } = rfx.useStructure();

  const hasVesselPricing = some(sectionById, { type: SectionType.VESSEL_PRICING });

  return (
    <DropdownMenu
      disabled={disabled}
      variant="primary"
      buttonText={t('request.addSection')}
      iconLeft="plus"
      iconRight="caret-down"
    >
      <DropdownMenuItem icon="file-text-o" onSelect={() => onSelect(SectionType.DOCUMENT)}>
        {t('request.documents.document_other')}
      </DropdownMenuItem>
      <DropdownMenuItem icon="list-ul" onSelect={() => onSelect(SectionType.LINE_ITEMS)}>
        {t('request.lineItems.lineItem_other')}
      </DropdownMenuItem>
      <DropdownMenuItem icon="question" onSelect={() => onSelect(SectionType.QUESTION)}>
        {t('request.question.question_other')}
      </DropdownMenuItem>
      <DropdownMenuItem
        disabled={hasVesselPricing}
        tooltip={hasVesselPricing ? t('request.vesselPricing.onlyOneVesselPricing') : undefined}
        icon="ship"
        onSelect={() => onSelect(SectionType.VESSEL_PRICING)}
      >
        {t('request.vesselPricing.vesselPricing')}
      </DropdownMenuItem>
    </DropdownMenu>
  );
};
