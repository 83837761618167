import * as React from 'react';
import { useDeviceSize } from '../ui/useDeviceSize';
import { LabelConfig, LabelConfigProvider, LabelConfigProviderProps } from '../LabelConfigProvider';

export const draftSectionLabelStyle: Record<string, React.CSSProperties> = {
  default: {
    fontSize: 2,
    position: 'relative',
    color: 'text',
    marginBottom: '6px',
  },
};

export const DraftSectionLabelConfigProvider = (props: LabelConfigProviderProps) => {
  const { isExtraSmall } = useDeviceSize();

  return (
    <LabelConfigProvider
      variant={isExtraSmall ? LabelConfig.ABOVE : LabelConfig.LEFT}
      width="290px"
      style={isExtraSmall ? {} : draftSectionLabelStyle}
      gap={isExtraSmall ? 2 : 4}
      {...props}
    />
  );
};
