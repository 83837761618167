import { Trans, useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData } from '../types';
import { OpenRequestInNewTabLink } from '../OpenRequestInNewTabLink';
import { Direction } from '../../../../ui/MultiStepFlow/types';

export const GuidelinesStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (data: Partial<AwardFlowData> | null, direction: Direction) => void,
}) => {
  const { t } = useTranslation('translation');

  return (
    <lotPagesLayout.ContentWrapper>
      <StepNavigation
        continueButtonText={t('request.awardFlow.startNow')}
        onContinueClick={() => submitAndNavigate(null, Direction.FORWARD)}
      >
        <lotPagesLayout.Section heading={t('guidelines', { ns: 'general' })}>
          <Text mt="12px">
            {t('request.awardFlow.steps.guidelines.description')}
          </Text>
          <lotPagesLayout.Subsection heading={t('request.awardFlow.steps.guidelines.actions.heading')} mt="16px">
            <lotPagesLayout.Ul style={{ marginTop: '12px' }}>
              <Trans
                i18nKey="request.awardFlow.steps.guidelines.actions.body"
                ns="translation"
                components={{
                  li: <li />,
                }}
              />
            </lotPagesLayout.Ul>
          </lotPagesLayout.Subsection>
          <lotPagesLayout.Subsection heading={t('request.awardFlow.steps.guidelines.things.heading')} mt="16px">
            <lotPagesLayout.Ul style={{ marginTop: '12px' }}>
              <Trans
                i18nKey="request.awardFlow.steps.guidelines.things.body"
                ns="translation"
                components={{
                  li: <li />,
                  a: <OpenRequestInNewTabLink />,
                }}
              />
            </lotPagesLayout.Ul>
          </lotPagesLayout.Subsection>
        </lotPagesLayout.Section>
      </StepNavigation>
    </lotPagesLayout.ContentWrapper>
  );
};
