import * as React from 'react';
import { noop } from 'lodash';

export type ExchangeSwitcherTarget = { exchangeId?: string; pageId?: string; recipientId?: string; };

type SwitchToExchangeType = {
  switchToExchange: (target: ExchangeSwitcherTarget | null) => void;
  verticalTargets?: ExchangeSwitcherTarget[];
  horizontalTargets?: ExchangeSwitcherTarget[];
  setHorizontalTargets?: (targets: ExchangeSwitcherTarget[]) => void;
};

const SwitchToExchangeContext = React.createContext<SwitchToExchangeType>({
  switchToExchange: noop,
});

export const SwitchToExchangeProvider = ({
  switchToExchange,
  verticalTargets,
  horizontalTargets,
  setHorizontalTargets,
  ...props
}: SwitchToExchangeType & { children: React.ReactNode }) => {
  const contextValue = React.useMemo(
    () => ({ switchToExchange, verticalTargets, horizontalTargets, setHorizontalTargets }),
    [switchToExchange, verticalTargets, horizontalTargets, setHorizontalTargets],
  );

  return (
    <SwitchToExchangeContext.Provider value={contextValue} {...props} />
  );
};

export const SwitchToExchangeSettableProvider = ({
  verticalTargets,
  switchToExchange,
  children,
}: SwitchToExchangeType & { children: React.ReactNode }) => {
  const [horizontalTargets, setHorizontalTargets] = React.useState<ExchangeSwitcherTarget[]>([]);
  const contextValue = React.useMemo(
      () => ({ switchToExchange, verticalTargets, horizontalTargets, setHorizontalTargets }),
      [switchToExchange, verticalTargets, horizontalTargets],
  );

  return (
    <SwitchToExchangeContext.Provider value={contextValue}>
      {children}
    </SwitchToExchangeContext.Provider>
  );
};

export const useSwitchToExchange = () => {
  const context = React.useContext(SwitchToExchangeContext);
  if (!context) {
    throw Error('no exchange switcher context found');
  }
  return context;
};
