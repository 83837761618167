import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';
import { compact, get, isEmpty, isFinite, noop, set } from 'lodash';
import { Company, getEmptyFieldValue, isFieldValueDefined } from '@deepstream/common/rfq-utils';
import { ColumnData, RowData } from '@deepstream/ui-kit/grid/core/utils';
import { EditableGrid } from '@deepstream/ui-kit/grid/EditableGrid/EditableGrid';
import { useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { DefaultEditableGridStyles } from '@deepstream/ui-kit/grid/EditableGrid/EditableGridStyles';
import { useGridMenuState } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { EditableGridColumn, calculateMaxGridHeight } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { useEditableGridActions } from '@deepstream/ui-kit/grid/EditableGrid/useEditableGridActions';
import { withProps } from '@deepstream/ui-utils/withProps';
import { FieldType } from '@deepstream/common/exchangesConfig';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { LotsGridMenu } from './LotsGridMenu';
import { SimpleHeader } from './header';
import { useHandleGridClipboardEvent } from './useHandleGridClipboardEvent';
import { CompanyLogoAndNameCell, CurrencyAmountOrDashCell, NumberOrDashCell, PercentOrDashCell } from './nonValidationAwareValueCell';
import { AuctionLineItemsExchangeSnapshot, LineItemsExchangeSnapshot } from '../../types';
import { parseCellValue } from './utils';
import { NumberInputCell } from './inputCell';
import { NumberValueCell } from './validationAwareValueCell';

const frozenLeftColumnIds = ['awardedSupplier'];
const navigableRange = { startRowIndex: 1, startColumnIndex: 0 };
const selectableRange = { startColumnIndex: 0 };

export type SplitLineAwardGridRowData = {
  _id: string;
  recipient: Company;
  exchange: LineItemsExchangeSnapshot | AuctionLineItemsExchangeSnapshot;
  /**
   * The supplier currency (gets set to the request currency when not
   * specified).
   */
  supplierCurrencyCode: string;

  originalPricePerUnit?: number;
  originalQuantity?: number;
  awardedQuantityAmount?: number;
  awardedQuantityPercentage?: number;

  convertToRequestCurrency: (value?: number | null) => number | null;
  getSupplierCurrencyTotalCostForQuantityAmount: (quantityAmount?: number) => number;
};

export const getQuantityAmountFromQuantityPercentage = (row: any) => {
  return isFinite(row.originalQuantity) && isFinite(row.awardedQuantityPercentage)
    ? row.originalQuantity * (row.awardedQuantityPercentage / 100)
    : null;
};

export const getQuantityPercentFromQuantityAmount = (row: any) => {
  return isFinite(row.awardedQuantityAmount) && isFinite(row.originalQuantity)
    ? (row.awardedQuantityAmount / row.originalQuantity) * 100
    : null;
};

export const getQuantityFactorFromQuantityAmount = (row: any) => {
  return isFinite(row.awardedQuantityAmount) && isFinite(row.originalQuantity)
    ? row.awardedQuantityAmount / row.originalQuantity
    : null;
};

const generateCsvCellValue = (columnConfig, row) => {
  // In the case of the awarded supplier column, we want
  // to support pasting the company name to the clipboard;
  // this is currently specific to the split line award
  // grid, so we handle this here instead of adding
  // a dedicated field type.
  if (columnConfig._id === 'awardedSupplier') {
    return get(row, ['recipient', 'company', 'name']);
  }

  if (columnConfig.accessorFn) {
    return columnConfig.accessorFn(row);
  } else {
    return row[columnConfig._id];
  }
};

const addCsvDataToRows = async ({ rows, data, affectedColumns }: {
  rows: any;
  data: any;
  affectedColumns: any;
}) => {
  let validCellCount = 0;

  const updatedRows = rows.map((row, rowIndex) => {
    const rowData = data[rowIndex] ?? [];
    const updatedRow = { ...row };

    affectedColumns.forEach((column, index) => {
      const rawValue = rowData[index];

      // we only want users to paste content to editable cells
      if (column.original.startEditingCell) {
        const {
          canInsertValue,
          cellValue,
          isInputValid,
        } = parseCellValue(rawValue, { _id: column.original._id, type: column.original.fieldType });

        if (canInsertValue) {
          set(updatedRow, column.original.accessorKey, cellValue);
        }

        if (isInputValid) {
          validCellCount += 1;
        }
      }
    });

    return updatedRow;
  });

  return {
    updatedRows,
    validCellCount,
  };
};

const createClearFieldsUpdater = ({
  startRowIndex,
  endRowIndex,
  affectedColumns,
}: { startRowIndex: number, endRowIndex: number, affectedColumns: { original: EditableGridColumn }[] }) =>
  (row: Record<string, unknown>, index: number) => {
    if (index >= startRowIndex && index < endRowIndex) {
      const updatedRow = { ...row };

      for (const column of affectedColumns) {
        const { accessorKey, fieldType, startEditingCell } = column.original;

        const fieldValue = get(row, accessorKey);

        // we only want users to clear content of editable cells
        // @ts-expect-error ts(2345) FIXME: Argument of type 'FieldType | undefined' is not assignable to parameter of type 'FieldType'.
        if (startEditingCell && isFieldValueDefined(fieldValue, fieldType)) {
          // @ts-expect-error ts(2345) FIXME: Argument of type 'FieldType | undefined' is not assignable to parameter of type 'FieldType'.
          const newFieldValue = getEmptyFieldValue(fieldType);

          set(updatedRow, accessorKey, newFieldValue);
        }
      }

      return updatedRow;
    } else {
      return row;
    }
  };

export interface SplitLineAwardGridProps {
  requestCurrencyCode: string;
  quantitySplitMode?: 'amount' | 'percentage';
  /**
   * Determines the maximum height of the grid.
   *
   * `maxGridHeight = 100vh - viewportHeightDelta`
   */
  viewportHeightDelta?: number;
}

export const SplitLineAwardGrid = ({
  requestCurrencyCode,
  quantitySplitMode,
  viewportHeightDelta,
}: SplitLineAwardGridProps) => {
  const { t } = useTranslation('translation');
  const handleGridClipboardEvent = useHandleGridClipboardEvent();
  const {
    pendingKeyboardEvent,
    rowData,
    setEditedCell,
  } = useEditableGridData<SplitLineAwardGridRowData>();
  const { menuReferenceId } = useGridMenuState();

  const gridActions = useEditableGridActions({
    createRow: noop,
    generateCsvCellValue,
    addCsvDataToRows,
    // @ts-expect-error ts(2322) FIXME: Type '({ startRowIndex, endRowIndex, affectedColumns, }: { startRowIndex: number; endRowIndex: number; affectedColumns: { original: EditableGridColumn; }[]; }) => (row: Record<string, unknown>, index: number) => Record<string, unknown>' is not assignable to type '({ startRowIndex, endRowIndex, affectedColumns, }: { startRowIndex: number; endRowIndex: number; affectedColumns: ({ original: any; } | null)[]; }) => (originalRowData: any, index: number) => unknown'.
    createClearFieldsUpdater,
  });

  const startEditingInputCell = React.useCallback((
    row: RowData<any, any>,
    column: ColumnData<EditableGridColumn>,
    event?: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (!column.original.InputCell) return;

    pendingKeyboardEvent.current = event ?? null;

    setEditedCell({
      rowId: row.original._id,
      columnId: column.original._id,
    });
  }, [setEditedCell, pendingKeyboardEvent]);

  const needsConversionFromSupplierToRequestCurrency = rowData.some(item => item.supplierCurrencyCode !== requestCurrencyCode);
  const hasPriceField = rowData.some(item => item.exchange.def.fields.price);
  const hasSupplierQuantityField = rowData.some(item => item.exchange.def.fields['quantity:submitter']);

  const columns: EditableGridColumn[] = React.useMemo(() => {
    const awardedSupplierField = {
      _id: 'awardedSupplier',
      accessorKey: 'awardedSupplier',
      accessorFn: row => get(row, 'recipient.company'),
      Header: SimpleHeader,
      label: t('request.awardFlow.steps.splitLineAward.awardedSupplier'),
      ValueCell: withProps(CompanyLogoAndNameCell, { hasDefaultCursor: true }),
      width: 300,
    };

    const optionalSupplierReplyFields = [
      hasPriceField && {
        _id: 'price',
        accessorKey: 'price',
        accessorFn: row => get(row, ['exchange', 'latestReply', 'price']),
        fieldType: FieldType.PRICE,
        Header: SimpleHeader,
        label: t('request.lineItems.pricePerUnit'),
        description: t('general.supplier', { count: 1 }),
        ValueCell: withProps(CurrencyAmountOrDashCell, {
          getCurrencyCode: row => row.supplierCurrencyCode,
          hasDefaultCursor: true,
        }),
        width: 175,
      },
      hasSupplierQuantityField && {
        _id: 'supplierQuantity',
        accessorKey: 'supplierQuantity',
        accessorFn: row => get(row, ['exchange', 'latestReply', 'quantity:submitter']),
        fieldType: FieldType.PRICE,
        Header: SimpleHeader,
        label: t('general.quantity'),
        description: t('general.supplier', { count: 1 }),
        ValueCell: withProps(NumberOrDashCell, { hasDefaultCursor: true }),
        width: 175,
      },
    ];

    if (quantitySplitMode === 'amount') {
      // split by quantity; quantitySplitMode 'amount'
      return compact([
        awardedSupplierField,
        ...optionalSupplierReplyFields,
        {
          _id: 'awardedQuantityAmount',
          accessorKey: 'awardedQuantityAmount',
          fieldType: FieldType.NUMBER,
          Header: withProps(SimpleHeader, { truncate: false }),
          label: t('request.awardFlow.steps.splitLineAward.awardedQuantityAmount'),
          startEditingCell: startEditingInputCell,
          InputCell: NumberInputCell,
          ValueCell: NumberValueCell,
          format: 'number.positive',
          width: 175,
        },
        {
          _id: 'awardedQuantityPercentage',
          accessorKey: 'awardedQuantityPercentage',
          accessorFn: getQuantityFactorFromQuantityAmount,
          fieldType: FieldType.NUMBER,
          Header: withProps(SimpleHeader, { truncate: false }),
          label: t('request.awardFlow.steps.splitLineAward.awardedQuantityPercentage'),
          ValueCell: PercentOrDashCell,
          suffix: (
            <Icon
              sx={{
                top: '2px',
              }}
              icon="percent"
              color="subtext50"
              fixedWidth
              fontSize={2}
            />
          ) as any,
          format: 'number.positive',
          width: 175,
          disabled: true,
        },
        {
          _id: 'supplierCurrencyAwardedCost',
          accessorKey: 'supplierCurrencyAwardedCost',
          accessorFn: (row: any) => row.getSupplierCurrencyTotalCostForQuantityAmount(row.awardedQuantityAmount),
          fieldType: FieldType.PRICE,
          Header: SimpleHeader,
          label: t('request.awardFlow.steps.splitLineAward.awardedCost'),
          ValueCell: withProps(CurrencyAmountOrDashCell, {
            getCurrencyCode: row => row.supplierCurrencyCode,
            highlighted: true,
            hasDefaultCursor: true,
          }),
          width: 175,
        },
        needsConversionFromSupplierToRequestCurrency
          ? {
            _id: 'requestCurrencyAwardedCost',
            accessorKey: 'requestCurrencyAwardedCost',
            accessorFn: (row: any) => {
              const awardedCostInSupplierCurrency = row.getSupplierCurrencyTotalCostForQuantityAmount(row.awardedQuantityAmount);

              return row.convertToRequestCurrency(awardedCostInSupplierCurrency);
            },
            fieldType: FieldType.PRICE,
            Header: SimpleHeader,
            label: t('request.awardFlow.steps.splitLineAward.awardedCostCurrency', { currencyCode: requestCurrencyCode }),
            ValueCell: withProps(CurrencyAmountOrDashCell, {
              getCurrencyCode: () => requestCurrencyCode,
              highlighted: true,
              hasDefaultCursor: true,
            }),
            width: 175,
          }
          : null,
      ]);
    } else {
      // split by quantity; quantitySplitMode 'percentage'
      return compact([
        awardedSupplierField,
        ...optionalSupplierReplyFields,
        {
          _id: 'awardedQuantityAmount',
          accessorKey: 'awardedQuantityAmount',
          accessorFn: getQuantityAmountFromQuantityPercentage,
          fieldType: FieldType.NUMBER,
          Header: withProps(SimpleHeader, { truncate: false }),
          label: t('request.awardFlow.steps.splitLineAward.awardedQuantityAmount'),
          ValueCell: NumberOrDashCell,
          format: 'number.positive',
          width: 175,
          disabled: true,
        },
        {
          _id: 'awardedQuantityPercentage',
          accessorKey: 'awardedQuantityPercentage',
          fieldType: FieldType.NUMBER,
          Header: withProps(SimpleHeader, { truncate: false }),
          label: t('request.awardFlow.steps.splitLineAward.awardedQuantityPercentage'),
          startEditingCell: startEditingInputCell,
          InputCell: NumberInputCell,
          ValueCell: NumberValueCell,
          suffix: (
            <Icon
              sx={{
                top: '2px',
              }}
              icon="percent"
              color="subtext50"
              fixedWidth
              fontSize={2}
            />
          ) as any,
          format: 'number.positive',
          width: 175,
        },
        {
          _id: 'supplierCurrencyAwardedCost',
          accessorKey: 'supplierCurrencyAwardedCost',
          accessorFn: (row: any) => row.getSupplierCurrencyTotalCostForQuantityAmount(getQuantityAmountFromQuantityPercentage(row)),
          fieldType: FieldType.PRICE,
          Header: SimpleHeader,
          label: t('request.awardFlow.steps.splitLineAward.awardedCost'),
          ValueCell: withProps(CurrencyAmountOrDashCell, {
            getCurrencyCode: row => row.supplierCurrencyCode,
            highlighted: true,
            hasDefaultCursor: true,
          }),
          width: 175,
        },
        needsConversionFromSupplierToRequestCurrency
          ? {
            _id: 'requestCurrencyAwardedCost',
            accessorKey: 'requestCurrencyAwardedCost',
            accessorFn: (row: any) => {
              const awardedCostInSupplierCurrency =
                row.getSupplierCurrencyTotalCostForQuantityAmount(getQuantityAmountFromQuantityPercentage(row));

              return row.convertToRequestCurrency(awardedCostInSupplierCurrency);
            },
            fieldType: FieldType.PRICE,
            Header: SimpleHeader,
            label: t('request.awardFlow.steps.splitLineAward.awardedCostCurrency', { currencyCode: requestCurrencyCode }),
            ValueCell: withProps(CurrencyAmountOrDashCell, {
              getCurrencyCode: () => requestCurrencyCode,
              highlighted: true,
              hasDefaultCursor: true,
            }),
            width: 175,
          }
          : null,
      ]);
    }
  }, [
    t,
    needsConversionFromSupplierToRequestCurrency,
    hasPriceField,
    hasSupplierQuantityField,
    requestCurrencyCode,
    startEditingInputCell,
    quantitySplitMode,
  ]);

  const maxGridHeight = calculateMaxGridHeight(rowData.length);

  return (
    <>
      {isEmpty(rowData) ? (
        <Text fontSize={2}>
          {t('table.noDataToDisplay')}
        </Text>
      ) : (
        <DefaultEditableGridStyles
          style={{
            width: '100%',
            height: `min(100vh - ${viewportHeightDelta}px, ${maxGridHeight}px)`,
          }}
          cellContentCss="justify-content: flex-start;"
          activeCellContentCss="padding: 8px"
          cellContentWithSuffixCss="padding-top: 0;padding-bottom: 0;padding-right:0;padding-left: 0"
          activeCellContentWithSuffixCss="padding-top: 0;padding-bottom: 0;padding-right: 0;padding-left: 0;"
          suffixCss="align-items: flex-start;padding-top: 11px; padding-bottom: 11px; flex: 0 0 auto;"
          activeSuffixCss="align-items: flex-start;padding-top: 9px; padding-bottom: 9px; flex: 0 0 auto;"
        >
          <EditableGrid
            columns={columns}
            rowData={rowData as any}
            gridActions={gridActions}
            onGridClipboardEvent={handleGridClipboardEvent}
            submitEditedCellOnScroll={false}
            navigableRange={navigableRange}
            selectableRange={selectableRange}
            highlightSelectionInHeaders
            frozenLeftColumnIds={frozenLeftColumnIds}
          />
        </DefaultEditableGridStyles>
      )}
      {menuReferenceId ? (
        <LotsGridMenu />
      ) : (
        null
      )}
    </>
  );
};
