import { memo, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionExchangeDefinition } from '@deepstream/common/rfq-utils';
import { useExchange } from '../../../../useExchange';
import { ExchangeTimeline } from '../../../../ExchangeModal/ExchangeTimeline';
import * as modal from '../../../../ExchangeModal/layout';
import { ActionNotificationSubject } from '../../../../ExchangeModal/useActionNotificationSubject';
import { useActionNotificationSubject } from './useActionNotificationSubject';
import { useSendExchangeReplyContext } from '../../../../ExchangeModal/useSendExchangeReply';
import { SendMessageForm } from '../../../../SendMessageForm';
import { ScrollToBottomHintButton, useScrollToBottomHint } from '../../../../hooks/useScrollToBottomHint';
import { ExchangeSwitcher } from '../../../../ExchangeModal/ExchangeSwitcher';
import { usePossibleActions } from '../../../Exchange/usePossibleActions';
import { usePagePermissions, useQuestionnaireData } from '../questionnaireUtils';
import { BuyerFieldsSection } from '../../../../ExchangeModal/BuyerFieldsSection';
import { SupplierFieldsSection } from '../../../../ExchangeModal/SupplierFieldsSection';
import { FixedFieldsSection } from '../../../../ExchangeModal/FixedFieldsSection';

export const QuestionnaireExchangeModalContent = memo(({
  close,
  showExchangeSwitcher,
  showFooter,
  showRecipient,
}: {
  close: (...args: any[]) => void;
  showExchangeSwitcher?: boolean;
  showFooter?: boolean;
  showRecipient?: boolean;
}) => {
  const { t } = useTranslation();
  const { setElement: setScrollToBottomRef, showHint, scrollToBottom } = useScrollToBottomHint();
  const exchange = useExchange();
  const { exchangeDefSequence } = useQuestionnaireData();
  const pagePermissions = usePagePermissions();
  const [sendExchangeReply] = useSendExchangeReplyContext();
  const { canPostComment } = usePossibleActions(exchange);
  // @ts-expect-error ts(2345) FIXME: Argument of type 'null' is not assignable to parameter of type 'HTMLDivElement | (() => HTMLDivElement)'.
  const [timelineElement, setTimelineElement] = useState<HTMLDivElement>(null);
  const timelineElementHeight = useMemo(
    () => timelineElement ? timelineElement.getBoundingClientRect().height / 2 : undefined,
    [timelineElement],
  );

  const questionIndex = useMemo(() => {
    return exchangeDefSequence.findIndex(exchangeDefId => exchangeDefId === exchange.def._id) + 1;
  }, [exchange.def._id, exchangeDefSequence]);

  return (
    <modal.Content height={showFooter ? 'calc(100vh - 120px)' : 'calc(100vh - 56px)'}>
      <modal.Header
        onClose={close}
        leftActionComponent={showExchangeSwitcher && (
          <ExchangeSwitcher exchangeId={exchange._id} />
        )}
      >
        {`${questionIndex}. ${(exchange.def as QuestionExchangeDefinition).description}`}
      </modal.Header>
      <modal.Body maxHeight="unset">
        {/*
         // @ts-expect-error ts(2322) FIXME: Type 'Dispatch<SetStateAction<HTMLDivElement | undefined>>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'. */}
        <modal.Fields p={0} flex={5} position="relative" ref={setScrollToBottomRef}>
          <FixedFieldsSection
            exchange={exchange}
            showRecipient={showRecipient}
          />
          <BuyerFieldsSection exchange={exchange} />
          <SupplierFieldsSection exchange={exchange} />
          <ScrollToBottomHintButton
            sx={{ position: 'sticky', bottom: '10px', left: '50%', visibility: showHint ? 'visible' : 'hidden' }}
            onClick={scrollToBottom}
          />
        </modal.Fields>
        {/*
         // @ts-expect-error ts(2322) FIXME: Type 'Dispatch<SetStateAction<HTMLDivElement>>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'. */}
        <modal.Timeline bg="lightGray3" flex={3} overflowY="hidden" ref={setTimelineElement}>
          <modal.TimelineHeader>
            {t('request.exchange.activityAndComments')}
          </modal.TimelineHeader>
          {/*
           // @ts-expect-error ts(2322) FIXME: Type '({ exchange, action, }: { exchange: ExchangeSnapshot; action: any; }) => null' is not assignable to type 'ActionNotificationSubjectFn'. */}
          <ActionNotificationSubject.Provider value={useActionNotificationSubject}>
            <ExchangeTimeline canRespond={pagePermissions.canRespond} />
          </ActionNotificationSubject.Provider>
          {canPostComment && (
            <modal.TimelineLeaveComment>
              <SendMessageForm
                onSubmit={sendExchangeReply}
                placeholder={t('request.exchange.leaveAComment')}
                buttonProps={{ variant: 'primary-outline' }}
                showButtonOnFocus
                maxTextFieldHeight={timelineElementHeight}
              />
            </modal.TimelineLeaveComment>
          )}
        </modal.Timeline>
      </modal.Body>
    </modal.Content>
  );
});
