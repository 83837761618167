import { cloneDeep, compact, fromPairs, map, partition } from 'lodash';
import { assign, createMachine } from 'xstate';
import { isBidActive } from '@deepstream/common/rfq-utils';
import { States } from './States';
import { Actions } from './Actions';

// TODO consider refactoring:
// We keep track of the available steps in the context but handle
// the step transition logic as state transitions in the machine;
// it would be much simpler to stop doing the latter and calculate
// the previous and next steps from the available steps.
// Refactoring in this way would require changes to the
// consuming components, which would have to read the current step
// from the context instead of the machine state.
const getAwardRequestSteps = ({
  canToggleSpendAndSavings,
  showSpendAndSavingsDetails,
}: {
  canToggleSpendAndSavings?: boolean,
  showSpendAndSavingsDetails?: boolean,
}) => {
  return fromPairs(compact([
    [States.AWARDED_SUPPLIERS, 'suppliersToAward'],
    [States.UNSUCCESSFUL_SUPPLIERS, 'suppliersToReject'],
    canToggleSpendAndSavings && [States.TOGGLE_SPEND_AND_SAVINGS, 'toggleSpendAndSavings'],
    showSpendAndSavingsDetails && [States.CONFIRM_BUDGET, 'confirmBudget'],
    showSpendAndSavingsDetails && [States.CONFIRM_VALUE, 'confirmValue'],
    showSpendAndSavingsDetails && [States.CONFIRM_SAVINGS, 'confirmSavings'],
    [States.REVIEW, 'reviewAndConfirm'],
  ]));
};

export const defaultAwardRequestValue = {
  rfqId: undefined,
  companyId: undefined,
  suppliers: [],
  steps: getAwardRequestSteps({}),
  awarded: {
    suppliers: [],
    hasMessage: true,
    message: '',
    attachments: [],
  },
  unsuccessful: {
    suppliers: [],
    hasMessage: true,
    message: '',
    attachments: [],
  },
  inactive: {
    suppliers: [],
  },
  canToggleSpendAndSavings: false,
  spendAndSavings: null,
  calculatedData: {},
};

export const awardRequestMachine = createMachine<any>({
  id: 'awardRequestMachine',
  predictableActionArguments: true,
  initial: States.AWARDED_SUPPLIERS,
  context: cloneDeep(defaultAwardRequestValue),
  states: {
    [States.AWARDED_SUPPLIERS]: {
      initial: 'idle',
      states: {
        idle: {
          on: {
            [Actions.NEXT]: {
              target: 'fetching',
              actions: ['setLosers'],
            },
          },
        },
        fetching: {
          invoke: {
            id: 'fetchCalculatedData',
            src: 'fetchCalculatedData',
            onDone: {
              target: '#unsuccessfulSuppliersStep',
              actions: assign((_, event) => {
                return {
                  calculatedData: event.data,
                };
              }),
            },
            onError: {
              target: '#unsuccessfulSuppliersStep',
              actions: assign(() => {
                return {
                  calculatedData: {},
                };
              }),
            },
          },
        },
      },
    },
    [States.UNSUCCESSFUL_SUPPLIERS]: {
      id: 'unsuccessfulSuppliersStep',
      on: {
        [Actions.BACK]: States.AWARDED_SUPPLIERS,
        [Actions.NEXT]: [
          {
            cond: 'hasToggleSpendAndSavingsStep',
            target: States.TOGGLE_SPEND_AND_SAVINGS,
          },
          {
            cond: 'hasSpendAndSavingsDetailsSteps',
            target: States.CONFIRM_BUDGET,
          },
          {
            target: States.REVIEW,
          },
        ],
      },
    },
    [States.TOGGLE_SPEND_AND_SAVINGS]: {
      on: {
        [Actions.BACK]: States.UNSUCCESSFUL_SUPPLIERS,
        [Actions.NEXT]: [
          {
            cond: 'hasSpendAndSavingsDetailsSteps',
            target: States.CONFIRM_BUDGET,
          },
          {
            target: States.REVIEW,
          },
        ],
      },
    },
    [States.CONFIRM_BUDGET]: {
      on: {
        [Actions.BACK]: [
          {
            cond: 'hasToggleSpendAndSavingsStep',
            target: States.TOGGLE_SPEND_AND_SAVINGS,
          },
          {
            target: States.UNSUCCESSFUL_SUPPLIERS,
          },
        ],
        [Actions.NEXT]: States.CONFIRM_VALUE,
      },
    },
    [States.CONFIRM_VALUE]: {
      on: {
        [Actions.BACK]: States.CONFIRM_BUDGET,
        [Actions.NEXT]: States.CONFIRM_SAVINGS,
      },
    },
    [States.CONFIRM_SAVINGS]: {
      on: {
        [Actions.BACK]: States.CONFIRM_VALUE,
        [Actions.NEXT]: States.REVIEW,
      },
    },
    [States.REVIEW]: {
      on: {
        [Actions.BACK]: [
          {
            cond: 'hasSpendAndSavingsDetailsSteps',
            target: States.CONFIRM_SAVINGS,
          },
          {
            cond: 'hasToggleSpendAndSavingsStep',
            target: States.TOGGLE_SPEND_AND_SAVINGS,
          },
          {
            target: States.UNSUCCESSFUL_SUPPLIERS,
          },
        ],
        [Actions.NEXT]: States.PROCESSING,
      },
    },
    [States.PROCESSING]: {
      invoke: {
        id: 'onConfirm',
        src: 'onConfirm',
        onDone: {
          target: States.SUCCESS,
        },
        onError: {
          target: States.FAILURE,
        },
      },
    },
    [States.SUCCESS]: {},
    [States.FAILURE]: {},
  },
  on: {
    [Actions.INIT]: {
      target: States.AWARDED_SUPPLIERS,
      actions: assign((context, event) => {
        const [activeSuppliers, inactiveSuppliers] = partition(
          event.suppliers,
          (supplier) => isBidActive(supplier.bidStatus),
        );

        return {
          rfqId: event.rfqId,
          companyId: event.companyId,
          suppliers: activeSuppliers,
          awarded: { ...defaultAwardRequestValue.awarded, ...event.awarded },
          unsuccessful: { ...defaultAwardRequestValue.unsuccessful, ...event.unsuccessful },
          inactive: { ...defaultAwardRequestValue.inactive, suppliers: inactiveSuppliers },
          spendAndSavings: event.spendAndSavings,
          canToggleSpendAndSavings: event.canToggleSpendAndSavings,
          steps: getAwardRequestSteps({
            canToggleSpendAndSavings: event.canToggleSpendAndSavings,
            showSpendAndSavingsDetails: event.spendAndSavings.enabled,
          }),
        };
      }),
    },
    [Actions.RESET]: {
      target: States.AWARDED_SUPPLIERS,
      actions: assign(cloneDeep(defaultAwardRequestValue)),
    },
    [Actions.UPDATE_GROUP]: {
      actions: assign(
        (context, event) => ({
          [event.group]: { ...context[event.group], ...event.updatedProperties },
        }),
      ),
    },
    [Actions.UPDATE_STEPS]: {
      actions: assign((context) => {
        return {
          steps: getAwardRequestSteps({
            canToggleSpendAndSavings: context.canToggleSpendAndSavings,
            showSpendAndSavingsDetails: context.spendAndSavings.enabled,
          }),
        };
      }),
    },
  },
}, {
  actions: {
    setLosers: assign((context) => {
      // set unsuccessful suppliers
      const winnerIds = map(context.awarded.suppliers, '_id');
      const unsuccessful = context.suppliers.filter(supplier => !winnerIds.includes(supplier._id) && isBidActive(supplier.bidStatus));

      return ({
        unsuccessful: {
          ...defaultAwardRequestValue.unsuccessful,
          suppliers: unsuccessful,
        },
      });
    }),
  },
  guards: {
    hasToggleSpendAndSavingsStep: (context) => Boolean(context.steps[States.TOGGLE_SPEND_AND_SAVINGS]),
    hasSpendAndSavingsDetailsSteps: (context) => Boolean(context.steps[States.CONFIRM_BUDGET]),
  },
});
