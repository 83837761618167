import { useState, useCallback, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { useBlocker } from '@tanstack/react-router';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Modal, ModalHeader, ModalBody, ModalFooter, CancelButton, DiscardButton } from '@deepstream/ui-kit/elements/popup/Modal';

const headingId = 'leave-flow-modal-heading';

export type LeaveFlowModalProps = {
  content?: ReactNode;
  heading?: string;
  warning?: string;
  okButtonText?: string;
  cancelButtonText?: string;
};

const LeaveFlowModalBase = ({
  decideCanLeaveFlow,
  content,
  heading,
  warning,
  okButtonText,
  cancelButtonText,
}: LeaveFlowModalProps & { decideCanLeaveFlow: (decision: boolean) => void }) => {
  const { t } = useTranslation('translation');

  const onDiscard = () => {
    if (decideCanLeaveFlow) {
      decideCanLeaveFlow(true);
    }
  };

  const onCancel = () => {
    if (decideCanLeaveFlow) {
      decideCanLeaveFlow(false);
    }
  };

  return (
    <Modal
      shouldCloseOnEsc
      isOpen
      onRequestClose={onCancel}
      aria={{ labelledby: headingId }}
      style={{ content: { width: '450px' } }}
    >
      <ModalHeader headingId={headingId} onClose={onCancel}>
        {heading || t('request.dialog.leaveAwardFlow.heading')}
      </ModalHeader>
      <ModalBody>
        <MessageBlock variant="warn" mt={0} mb="12px">
          {warning || t('request.dialog.leaveAwardFlow.warning')}
        </MessageBlock>
        {content}
      </ModalBody>
      <ModalFooter>
        <Flex flex={1} justifyContent="flex-end">
          <DiscardButton onClick={onDiscard} label={okButtonText || t('request.dialog.leaveAwardFlow.okButtonText')} />
          <CancelButton onClick={onCancel} label={cancelButtonText || t('request.dialog.leaveAwardFlow.cancelButtonText')} mr={0} />
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

export const LeaveFlowModal = (props: LeaveFlowModalProps) => {
  const [reactDecideCanLeaveFlow, setReactDecideCanLeaveFlow] = useState<((decision: boolean) => void) | null>(null);

  const decideCanLeaveFlow = useCallback(
    (decision: boolean) => {
      reactDecideCanLeaveFlow?.(decision);
    },
    [reactDecideCanLeaveFlow],
  );

  useBlocker({
    blockerFn: () => new Promise(resolve => {
      setReactDecideCanLeaveFlow(() => (decision: boolean) => {
        resolve(decision);
        setReactDecideCanLeaveFlow(null);
      });
    }),
    condition: true,
  });

  return (
    reactDecideCanLeaveFlow ? (
      <LeaveFlowModalBase
        decideCanLeaveFlow={decideCanLeaveFlow}
        {...props}
      />
    ) : (
      null
    )
  );
};
