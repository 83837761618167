import * as React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { filter, sortBy, find, compact } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ModalProps, Modal, ModalFooter, ModalHeader, ModalBody, DoneButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { useToaster } from './toast';
import { CompanyLogo } from './CompanyLogo';
import { useCurrentCompanyId } from './currentCompanyId';
import { APP_ADMIN_COMPANY_ID } from './constants';

const LINE_ITEM_HEIGHT = 45;

const StyledListItem = styled(Flex)`
  align-items: center;
  height: ${LINE_ITEM_HEIGHT}px;
  padding: 0px 12px;

  :not(:last-child) {
    border-bottom: ${props => props.theme.borders.lightGray};
  }
`;

type ListItemProps = {
  role: any;
  currentCompanyId: string;
  onSwitchCompany: any;
};

const ListItem: React.FC<ListItemProps> = ({ role, currentCompanyId, onSwitchCompany }) => {
  const { t } = useTranslation('general');

  return (
    <StyledListItem as="li">
      <CompanyLogo size="xs" companyId={role._id} />
      <Box flex={1}>
        <Truncate>
          {role.name}
        </Truncate>
      </Box>
      {role._id === currentCompanyId ? (
        <Text color="subtext">{t('activeCompany')}</Text>
      ) : (
        <Button
          small
          variant="primary-outline"
          iconRight="arrow-right"
          onClick={() => onSwitchCompany(role)}
          ml={2}
        >
          {t('switch')}
        </Button>
      )}
    </StyledListItem>
  );
};

const List = styled(Box)`
  list-style: none;
  height: ${3.6 * LINE_ITEM_HEIGHT}px;
  overflow-y: auto;
  border: ${props => props.theme.borders.lightGray};
  padding: 0px;
`;

type Props = ModalProps &
  {
    companyRoles: any[];
    onClose: () => void;
    switchCompany: (companyId: string) => void;
  };

export const SwitchCompanyModal: React.FC<Props> = ({ companyRoles, onClose, switchCompany, ...props }) => {
  const { t } = useTranslation('general');
  const toaster = useToaster();
  const currentCompanyId = useCurrentCompanyId()!;

  const sortedRoles = React.useMemo(
    () => {
      if (!companyRoles.length) return [];

      // Excludes APP_ADMIN_COMPANY_ID and current company ID
      const otherRoles = sortBy(
        filter(companyRoles, role => ![APP_ADMIN_COMPANY_ID, currentCompanyId].includes(role._id)),
        role => role.name.toLowerCase(),
      );

      const currentRole = find(
        companyRoles,
        { _id: currentCompanyId },
      );

      return compact([currentRole, ...otherRoles]);
    },
    [companyRoles, currentCompanyId],
  );

  const onSwitchCompany = (companyRole) => {
    toaster.success(t('companySwitchedToName', { name: companyRole.name }));
    onClose();
    switchCompany(companyRole._id);
  };

  return (
    <Modal style={{ content: { width: '500px' } }} {...props}>
      <ModalHeader onClose={onClose}>{t('switchCompany')}</ModalHeader>
      <ModalBody>
        <List as="ul">
          {sortedRoles.map(companyRole => (
            <ListItem
              key={companyRole._id}
              role={companyRole}
              currentCompanyId={currentCompanyId}
              onSwitchCompany={onSwitchCompany}
            />
          ))}
        </List>
      </ModalBody>
      <ModalFooter>
        <DoneButton onClick={onClose} />
      </ModalFooter>
    </Modal>
  );
};
