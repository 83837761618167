import { compact } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { CellProps } from 'react-table';
import { Flex, Text } from 'rebass/styled-components';
import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { CompanyLogoAndName } from '../../../CompanyLogo';
import { BasicTableStyles } from '../../../TableStyles';
import { useApi, wrap } from '../../../api';
import { useToaster } from '../../../toast';
import { useLiveRfqStructureQueryKey, useRfqId } from '../../../useRfq';

import { useCurrentCompanyId } from '../../../currentCompanyId';
import { DatetimeCell } from '../../../DatetimeCell';
import { Table } from '../../../Table';
import { PublicBidIntention, PublicBidIntentionStatus } from '../../../types';
import { UserEmailText } from '../../../ui/UserNameEmail';
import { useModalState } from '../../../ui/useModalState';
import { useMutation } from '../../../useMutation';
import { Loading } from '../../../ui/Loading';
import { ErrorMessage } from '../../../ui/ErrorMessage';

export const CompanyLogoAndNameCell: React.FC<CellProps<any>> = ({ row, cell }) => {
  const api = useApi();

  const { data: company } = useQuery(
    ['publicCompany', { companyId: cell.value }],
    wrap(api.getPublicCompany),
  );

  return company ? <CompanyLogoAndName company={company} size="xs" /> : null;
};

export const PublicBidIntentions = (
) => {
  const { t } = useTranslation('translation');
  const toaster = useToaster();
  const api = useApi();
  const queryClient = useQueryClient();
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId();
  const liveStructureQueryKey = useLiveRfqStructureQueryKey({
    rfqId,
    // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string | undefined'.
    currentCompanyId,
  });

  const [isApprovingSelectedRows, setIsApprovingSelectedRows] = React.useState<boolean>();
  const [selectedRows, setSelectedRows] = React.useState<PublicBidIntention[]>([]);

  const {
    data: publicBidIntentions,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [
      'publicBidIntentions',
      {
        rfqId,
        currentCompanyId,
        filter: {
          status: [PublicBidIntentionStatus.SUBMITTED],
        },
      },
    ],
    wrap(api.getPublicBidIntentions),
  );

  const decideIntentionsModal = useModalState();
  const [decidePublicBidIntentions] = useMutation(
    api.decidePublicBidIntentions,
    {
      onSuccess: () =>
        toaster.success(
          isApprovingSelectedRows
            ? t('request.publicBidIntentions.addSuppliersToRequestSuccess')
            : t('request.publicBidIntentions.ignoreSuppliersSuccess'),
        ),
      onError: () =>
        toaster.error(
          isApprovingSelectedRows
            ? t('request.publicBidIntentions.addSuppliersToRequestError')
            : t('request.publicBidIntentions.ignoreSuppliersError'),
        ),
      onSettled: callAll(
        () => queryClient.invalidateQueries(['publicBidIntentions', { rfqId }]),
        () => queryClient.invalidateQueries(liveStructureQueryKey),
        () => queryClient.invalidateQueries(['allExchanges', { rfqId, currentCompanyId }]),
        () => queryClient.invalidateQueries(['exchanges', { rfqId, currentCompanyId }]),
        () => queryClient.invalidateQueries(['statsByRecipientId', { rfqId, currentCompanyId }]),
      ),
    },
  );

  const handleDecideIntentions = React.useCallback(() => {
    decidePublicBidIntentions({
      rfqId,
      // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
      currentCompanyId,
      // @ts-expect-error ts(2322) FIXME: Type 'boolean | undefined' is not assignable to type 'boolean'.
      isApproved: isApprovingSelectedRows,
      recipientIds: selectedRows.map((row) => row.recipientId),
    });
    decideIntentionsModal.close();
  }, [
    rfqId,
    currentCompanyId,
    decideIntentionsModal,
    decidePublicBidIntentions,
    selectedRows,
    isApprovingSelectedRows,
  ]);

  const columns = React.useMemo(() => {
    return compact([
      {
        Header: t('general.company'),
        accessor: 'recipientId',
        Cell: CompanyLogoAndNameCell,
      },
      {
        Header: t('user', { count: 1, ns: 'general' }),
        accessor: 'submittedByUserId',
        Cell: ({ value }) => <UserEmailText id={value} />,
      },
      {
        Header: t('request.publicBidIntentions.intentionToBidDateReceived'),
        accessor: 'createdAt',
        Cell: DatetimeCell,
      },
    ]);
  }, [
    t,
  ]);

  return (
    <>
      <Panel mb={20}>
        <PanelHeader
          heading={
            <Flex flexDirection="row" sx={{ columnGap: '32px' }}>
              <div>{t('request.publicBidIntentions.header')}</div>
            </Flex>
          }
          icon="globe"
          iconProps={{ light: true }}
          justifyContent="space-between"
          flex="1"
        >
          <Flex sx={{ columnGap: '8px' }}>
            <Button
              small
              iconLeft="xmark"
              variant="secondary-outline"
              disabled={selectedRows.length === 0}
              onClick={() => {
                setIsApprovingSelectedRows(false);
                decideIntentionsModal.open();
              }}
            >
              {t('request.publicBidIntentions.ignoreSelectedSuppliers')}
            </Button>
            <Button
              small
              iconLeft="plus"
              variant="primary-outline"
              disabled={selectedRows.length === 0}
              onClick={() => {
                setIsApprovingSelectedRows(true);
                decideIntentionsModal.open();
              }}
            >
              {t('request.publicBidIntentions.addSelectedSuppliers')}
            </Button>
          </Flex>
        </PanelHeader>
        <PanelDivider />

        {isLoading ? (
          <PanelPadding>
            <Loading />
          </PanelPadding>
        ) : isError ? (
          <PanelPadding>
            <ErrorMessage error={t('general.couldNotGetData')} />
          </PanelPadding>
        ) : isSuccess ? (
          publicBidIntentions?.length > 0 ? (
            <BasicTableStyles hoverCursor="default">
              <Table
                setSelectedRows={setSelectedRows}
                columns={columns}
                data={publicBidIntentions}
                isSortable
              />
            </BasicTableStyles>
          ) : (
            <PanelPadding>
              <Text color="subtext" fontSize={2} mb="10px">
                {t('request.auction.noSuppliers')}
              </Text>
            </PanelPadding>
          )
        ) : null}
      </Panel>
      <Dialog
        heading={
          isApprovingSelectedRows
            ? t('request.publicBidIntentions.addSuppliersToRequest')
            : t('request.publicBidIntentions.ignoreSuppliers')
        }
        body={
          <MessageBlock variant="info" mt={0}>
            {isApprovingSelectedRows
              ? t('request.publicBidIntentions.addSuppliersDescription')
              : t('request.publicBidIntentions.ignoreSuppliersDescription')}
          </MessageBlock>
        }
        okButtonText={
          isApprovingSelectedRows
            ? t('request.suppliersTable.addSuppliers')
            : t('request.publicBidIntentions.ignoreSuppliers')
        }
        isOpen={decideIntentionsModal.isOpen}
        onOk={handleDecideIntentions}
        onCancel={decideIntentionsModal.close}
        showCloseIcon
        style={{ content: { maxWidth: '500px' } }}
      />
    </>
  );
};
