import { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { isEmpty } from 'lodash';
import { RequestsReportingConfig } from '@deepstream/common/reporting';
import { RfqStatus } from '@deepstream/common/rfq-utils';
import { getScrollbarSize } from '@deepstream/ui-utils/getScrollbarSize';
import { BORDER_ADJUSTMENT, DEFAULT_FROZEN_HEADER_HEIGHT } from '@deepstream/ui-kit/grid/core/constants';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';
import { PanelPadding, PanelText } from '@deepstream/ui-kit/elements/Panel';
import { DEFAULT_ROW_HEIGHT, EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { RenderHoverableGridDataCell } from '@deepstream/ui-kit/grid/EditableGrid/RenderHoverableGridDataCell';
import { DefaultEditableGridStyles } from '@deepstream/ui-kit/grid/EditableGrid/EditableGridStyles';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { ReadOnlyGrid } from '@deepstream/ui-kit/grid/EditableGrid/ReadOnlyGrid';
import { SortConfigProvider } from '@deepstream/ui-kit/grid/EditableGrid/sortConfig';
import { useSortedGridData } from '@deepstream/ui-kit/grid/EditableGrid/useSortedGridData';
import { useRequestsReportingTableData } from './useRequestsReporting';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useCurrentUser } from '../../useCurrentUser';
import { useLocalStorageState } from '../../useLocalStorageState';
import { PageInfo, PageSizeSelect } from '../../Pagination';
import { Bold } from '../../Bold';
import * as dashboard from './dashboard';
import { useRequestsNavigation } from '../../RequestsNavigationContext';

const pageSizes = [5, 10, 20, 30, 40, 50];

export const FROZEN_LEFT_COLUMN_WIDTH = 400;
const frozenLeftColumnIds = ['rowNumber', 'subject'];
const viewportHeightDelta = 86;

type TableConfig = {
  startIndex: number;
  pageSize: number;
};

const defaultCollapsedTableConfig = {
  startIndex: 0,
  pageSize: 5,
};

const expandedTableConfig = {
  startIndex: 0,
  pageSize: Number.MAX_SAFE_INTEGER / 2,
};

const RequestsReportingTable = ({
  data,
  columns,
  section,
}: {
  data;
  columns;
  section: 'budget' | 'value' | 'savings';
}) => {
  const { t } = useTranslation('translation');
  const currentUser = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { navigateToSenderRequest } = useRequestsNavigation();
  const [isExpandedView, setIsExpandedView] = useState(false);

  const [collapsedTableConfig, setCollapsedTableConfig] = useLocalStorageState<TableConfig>({
    key: `${currentCompanyId}.${currentUser._id}.reporting.requests.${section}.table`,
    defaultValue: defaultCollapsedTableConfig,
  });

  const [sortConfig, setSortConfig] = useState({
    columnId: null,
    direction: null,
  });

  const sortedData = useSortedGridData(sortConfig, data, columns);

  const currentPageIndex = Math.floor(collapsedTableConfig.startIndex / collapsedTableConfig.pageSize);
  const maxPageIndex = Math.floor(Math.max(0, sortedData.length - 1) / collapsedTableConfig.pageSize);

  // Prevent case where the start index in the cached table config would cause an empty
  // page to render because the number of requests has descreased (which can be the case,
  // for example, when a user gets downgraded from a super user to a full user) by
  // updating the table config to show the last page for the current data.
  useEffect(() => {
    if (currentPageIndex > maxPageIndex) {
      setCollapsedTableConfig({
        startIndex: maxPageIndex * collapsedTableConfig.pageSize,
        pageSize: collapsedTableConfig.pageSize,
      });
    }
  }, [collapsedTableConfig.pageSize, currentPageIndex, maxPageIndex, setCollapsedTableConfig]);

  const tableConfig = isExpandedView
    ? expandedTableConfig
    : collapsedTableConfig;

  const dataToShow = useMemo(() => {
    return sortedData.slice(tableConfig.startIndex, tableConfig.startIndex + tableConfig.pageSize);
  }, [sortedData, tableConfig]);

  const navigateToRequest = useCallback((request) => navigateToSenderRequest({
    requestId: request._id,
    isDraft: request.extendedStatus === RfqStatus.DRAFT,
  }), [navigateToSenderRequest]);

  const collapsedGridHeight = (
    DEFAULT_FROZEN_HEADER_HEIGHT +
    BORDER_ADJUSTMENT +
    DEFAULT_ROW_HEIGHT * collapsedTableConfig.pageSize +
    getScrollbarSize()
  );

  return (
    <Box
      sx={isExpandedView ? ({
        py: '20px',
        inset: 0,
        zIndex: 150,
        position: 'fixed',
        backgroundColor: 'lightGray3',
      }) : ({
        height: collapsedGridHeight + 67,
      })}
    >
      <Flex px="20px" justifyContent="space-between" alignItems="center">
        <Text fontSize={1}>
          {t('request.spendAndSavings.showingFromToOfTotal', {
            from: tableConfig.startIndex + 1,
            to: Math.min(tableConfig.startIndex + tableConfig.pageSize, data.length),
            total: data.length,
          })}
        </Text>
        <Flex sx={{ gap: 3 }}>
          {!isExpandedView && (
            <>
              <PageSizeSelect
                small
                pageSize={collapsedTableConfig.pageSize}
                pageSizes={pageSizes}
                setPageSize={(pageSize) => setCollapsedTableConfig({
                  startIndex: Math.floor(collapsedTableConfig.startIndex / pageSize) * pageSize,
                  pageSize,
                })}
                getButtonLabel={item => <Bold as="span">{item.label}</Bold>}
                menuWidth={150}
                translationKey="table.pagination.perPage"
              />
              <PageInfo
                pageIndex={currentPageIndex}
                maxPageIndex={maxPageIndex}
                setPageIndex={pageIndex => {
                  setCollapsedTableConfig({
                    startIndex: pageIndex * collapsedTableConfig.pageSize,
                    pageSize: collapsedTableConfig.pageSize,
                  });
                }}
              />
              <ButtonGroup marginBetween="-1px">
                <Button
                  variant="secondary-outline"
                  small
                  iconLeft="chevron-left"
                  disabled={collapsedTableConfig.startIndex === 0}
                  onClick={() => setCollapsedTableConfig({
                    startIndex: collapsedTableConfig.startIndex - collapsedTableConfig.pageSize,
                    pageSize: collapsedTableConfig.pageSize,
                  })}
                  sx={{ width: '28px' }}
                />
                <Button
                  variant="secondary-outline"
                  small
                  iconLeft="chevron-right"
                  disabled={collapsedTableConfig.startIndex + collapsedTableConfig.pageSize > data.length}
                  onClick={() => setCollapsedTableConfig({
                    startIndex: collapsedTableConfig.startIndex + collapsedTableConfig.pageSize,
                    pageSize: collapsedTableConfig.pageSize,
                  })}
                  sx={{ width: '28px' }}
                />
              </ButtonGroup>
            </>
          )}
          <Button
            variant="secondary-outline"
            small
            iconLeft={isExpandedView ? 'compress' : 'expand'}
            onClick={() => setIsExpandedView(!isExpandedView)}
            sx={{ width: '28px' }}
          />
        </Flex>
      </Flex>
      <PanelPadding>
        <SortConfigProvider sortConfig={sortConfig} setSortConfig={setSortConfig}>
          <GridIdPrefixProvider>
            <DefaultEditableGridStyles
              style={{
                width: '100%',
                height: isExpandedView
                  ? `calc(100vh - ${viewportHeightDelta}px)`
                  : collapsedGridHeight,
              }}
              isReadOnly
              highlightOnHover
            >
              <ReadOnlyGrid
                frozenLeftColumnIds={frozenLeftColumnIds}
                columns={columns}
                rowData={dataToShow as any}
                RenderDataCell={RenderHoverableGridDataCell}
                onRowClick={navigateToRequest}
              />
            </DefaultEditableGridStyles>
          </GridIdPrefixProvider>
        </SortConfigProvider>
      </PanelPadding>
    </Box>
  );
};

export const RequestsReportingTablePanel = ({
  config,
  columns,
  section,
  useData = useRequestsReportingTableData,
}: {
  config: RequestsReportingConfig;
  columns: EditableGridColumn[];
  section: 'budget' | 'value' | 'savings';
  useData?: typeof useRequestsReportingTableData;
}) => {
  const { t } = useTranslation('translation');

  const { data, isLoading, isError, isSuccess } = useData(section);

  return (
    <dashboard.Card isLoading={isLoading} isError={isError} sx={{ height: '100%', minHeight: '373px' }}>
      {isSuccess && data ? (
        isEmpty(data) ? (
          <>
            <dashboard.CardHeading p="20px 20px 16px" heading={t('general.request_other')} />
            <PanelText pt={0}>{t('table.noDataToDisplay')}</PanelText>
          </>
        ) : (
          <>
            <dashboard.CardHeading p="20px 20px 16px" heading={t('general.request_other')} />
            <RequestsReportingTable data={data} columns={columns} section={section} />
          </>
        )
      ) : (
        null
      )}
    </dashboard.Card>
  );
};
