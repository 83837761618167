import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Text, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { Bold } from '../../../Bold';

const ErrorPanel = styled(Panel)`
  position: relative;

  &:before {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    width: 5px;
    background-color: ${props => props.theme.colors.danger};
    border-radius: 4px 0 0 4px;
    content: '';
  }
`;

export const ReviewErrorsPanel = ({ errors }: { errors: string[] }) => {
  const { t } = useTranslation('preQualification');

  return !isEmpty(errors) ? (
    <ErrorPanel padded>
      <Flex alignItems="center" sx={{ gap: 3 }}>
        <Icon icon="times-circle" color="danger" fontSize={3} fixedWidth />
        <Box>
          <Bold>
            {t('review.issuesBeforePublishing')}
          </Bold>
          <Box as="ul" pl="20px">
            {errors.map((error, index) => <Text key={index} as="li">{error}</Text>)}
          </Box>
        </Box>
      </Flex>
    </ErrorPanel>
  ) : (
    null
  );
};
