import { useMemo } from 'react';
import { Box, Text, Flex } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { intersection } from 'lodash';

import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { PreQualCategory } from '../../../../../types';
import { useImportQuestionsContext } from '../../ImportQuestionsProvider';
import { CategoryQuestionsCheckbox } from './CategoryQuestionsCheckbox';

export const CategoryEntry = ({
  category,
}: {
  category: PreQualCategory,
}) => {
  const { t } = useTranslation('preQualification');
  const {
    handleChangeCategory,
    activeCategoryId,
    selectedQuestionExchangeDefIds,
    categoriesById,
    initialExchangeDefIds,
  } = useImportQuestionsContext();

  const selectedQuestionsInfo = useMemo(() => {
    const count = intersection(
      categoriesById[category._id].questionIds,
      [...selectedQuestionExchangeDefIds, ...initialExchangeDefIds],
    ).length;
    const total = categoriesById[category._id].questionIds.length;

    return {
      total,
      count,
    } as const;
  }, [categoriesById, category._id, selectedQuestionExchangeDefIds, initialExchangeDefIds]);

  const isEmptyCategory = category.questionIds.length === 0;

  return (
    <Flex
      key={category._id}
      onClick={() => handleChangeCategory(category._id)}
      sx={{
        borderBottom: 'lightGray2',
        alignItems: 'flex-start',
        cursor: 'pointer',
        backgroundColor: activeCategoryId === category._id ? 'rgba(52, 152, 219, 0.1)' : 'transparent',
        padding: '15px 15px 15px 30px',
      }}
    >
      <CategoryQuestionsCheckbox style={{ paddingRight: '8px' }} categoryId={category._id} />
      <Box>
        <Text fontWeight={500} title={category.name}>
          <Truncate>
            {category.name}
          </Truncate>
        </Text>
        <Text fontSize={1} color="subtext">
          {isEmptyCategory
            ? t('importQuestions.modal.categoryItemsEmpty')
            : t('importQuestions.modal.categoryItemsSelected', { ...selectedQuestionsInfo })
          }
        </Text>
      </Box>
    </Flex>
  );
};
