import { AnyScope, Stage, Attachment } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import { isEmpty } from 'lodash';
import { FieldContainer } from '../../form/FieldContainer';
import { LabelConfig, LabelConfigProvider } from '../../LabelConfigProvider';
import { FileList } from '../../ui/FileList';
import { useStageApprovalLabelStyle } from './useStageApprovalLabelStyle';
import { SuppliersToApprove } from './SuppliersToApprove';
import { Approvers } from './Approvers';
import { useDatetime } from '../../useDate';
import { ApprovalStageName } from './ApprovalStageName';
import { useDownloadRfqAttachment } from '../../ExchangeModal/AttachmentLink';
import { useOwnDownload } from '../../uploads/useOwnDownload';

export type StageApprovalDetailsProps = {
  stage?: Stage<AnyScope>;
  stageIndex?: number;
  recipientIds: string[];
  message: string;
  attachments: Attachment[];
  isApprovalSubmitted?: boolean;
  approverIds?: string[];
  date?: string;
};

export const StageApprovalDetails = ({
  stage,
  stageIndex,
  recipientIds,
  message,
  attachments,
  approverIds,
  date,
  isApprovalSubmitted,
}: StageApprovalDetailsProps) => {
  const { t } = useTranslation('translation');
  const labelStyle = useStageApprovalLabelStyle();
  const [download] = useDownloadRfqAttachment();
  const [ownDownload] = useOwnDownload();

  // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string | Date'.
  const datetime = useDatetime(date);

  return (
    <LabelConfigProvider
      variant={LabelConfig.LEFT}
      width="180px"
      style={{
        stageName: labelStyle,
        suppliersToApprove: labelStyle,
        approvers: labelStyle,
        message: labelStyle,
        attachments: labelStyle,
        issued: labelStyle,
      }}
    >
      <Flex
        flexDirection="column"
        sx={{
          rowGap: '16px',
        }}
      >
        <FieldContainer
          name="stageName"
          label={t('request.stageApprovals.approvalStage')}
        >
          <ApprovalStageName stage={stage} index={stageIndex} />
        </FieldContainer>

        <FieldContainer
          name="suppliersToApprove"
          label={t('request.stageApprovals.suppliersToApprove')}
        >
          <SuppliersToApprove companyIds={recipientIds} />
        </FieldContainer>

        <FieldContainer name="message" label={t('general.description')}>
          <Text>{message}</Text>
        </FieldContainer>

        {!isEmpty(attachments) && (
          <FieldContainer
            name="attachments"
            label={t('file_other', { ns: 'general' })}
          >
            <FileList
              initialAttachments={attachments}
              downloadFn={isApprovalSubmitted ? download : ownDownload}
              isReadOnly
            />
          </FieldContainer>
        )}
        {approverIds && (
          <FieldContainer
            name="approvers"
            label={t('request.stageApprovals.approver_other')}
          >
            <Flex>
              <Approvers approverIds={approverIds} />
            </Flex>
          </FieldContainer>
        )}

        {date && (
          <FieldContainer
            name="issued"
            label={t('requests.issued')}
          >
            <Text>{datetime}</Text>
          </FieldContainer>
        )}
      </Flex>
    </LabelConfigProvider>
  );
};
