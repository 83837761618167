import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { ExtendedSupplierListOverview } from '@deepstream/common';
import { useApi, wrap } from '../../api';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useSupplierListData } from './SupplierListProvider';

export const getSupplierListQueryKey = (companyId: string, supplierListId: string) => ['supplierList', { companyId, supplierListId }];

export const useSupplierListQueryKey = ({ supplierListId: supplierListIdProp }: { supplierListId?: string } = {}) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const supplierList = useSupplierListData({ required: false });
  const supplierListId = supplierListIdProp || supplierList?._id;

  if (!supplierListId) {
    throw new Error('An `supplierListId` is required');
  }

  return useMemo(
    () => getSupplierListQueryKey(currentCompanyId, supplierListId),
    [currentCompanyId, supplierListId],
  );
};

export const useSupplierList = () => {
  const api = useApi();
  const supplierListQueryKey = useSupplierListQueryKey();

  const { data: supplierList, status, refetch: refetchSupplierList } = useQuery<ExtendedSupplierListOverview>(
    supplierListQueryKey,
    wrap(api.getSupplierList),
    {
      staleTime: 100,
    },
  );

  return {
    supplierList,
    status,
    refetchSupplierList,
  };
};
