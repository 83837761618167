import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { LoadingPanel } from '../../../ui/Loading';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import { useQuestionnaire } from './useQuestionnaire';
import { QuestionnaireProvider, useIsRecipient, useQuestionnaireData } from './questionnaireUtils';
import { QuestionsBidSection } from './QuestionsBidSection';
import { QuestionnairePagePanels } from './QuestionnairePagePanels';
import { ExchangeNavigationProvider, useExchangeModalState } from '../../../useExchangeModalState';
import { SwitchToExchangeProvider } from '../../../ExchangeModal/SwitchToExchange';
import { QuestionnaireExchangeModal } from './ExchangeModal/QuestionnaireExchangeModal';
import { PageFooterBidProgress } from '../../../ExchangeModal/PageFooterBidProgress';
import { useQuestionnaireNavigation } from '../../../appNavigation';
import { useExchangeSwitcherTargets } from './useExchangeSwitcherTargets';
import { BidAutoAdvancer } from './ExchangeModal/BidAutoAdvancer';

const QuestionnaireQuestionsPageContent = ({ exchangeId }: { exchangeId?: string }) => {
  const { _id: questionnaireId, recipient } = useQuestionnaireData();
  const isRecipient = useIsRecipient();
  const navigation = useQuestionnaireNavigation();

  const navigateToExchange = useCallback(
    ({ recipientId, exchangeId }) => navigation.navigateToQuestions(questionnaireId, { recipientId, exchangeId }),
    [navigation, questionnaireId],
  );

  const recipientId = recipient._id;

  const exchangeModal = useExchangeModalState({
    exchangeId,
    recipientId,
  });

  const exchangeSwitcherTargets = useExchangeSwitcherTargets();

  return (
    <ExchangeNavigationProvider navigateToExchange={navigateToExchange}>
      <Stack gap="24px">
        <QuestionnairePagePanels />
        <QuestionsBidSection />
      </Stack>
      <SwitchToExchangeProvider
        switchToExchange={(target) => {
          if (target) {
            exchangeModal.open({ exchangeId: target.exchangeId, recipientId });
            navigateToExchange({ exchangeId: target.exchangeId, recipientId });
          } else {
            exchangeModal.close();
            navigateToExchange({ exchangeId: undefined, recipientId: undefined });
          }
        }}
        verticalTargets={exchangeSwitcherTargets}
      >
        <QuestionnaireExchangeModal
          showExchangeSwitcher={!isRecipient}
          showFooter={isRecipient}
          FooterAutoAdvancer={BidAutoAdvancer}
          FooterProgress={PageFooterBidProgress}
          {...exchangeModal as any}
          close={() => navigateToExchange({ exchangeId: undefined, recipientId: undefined })}
        />
      </SwitchToExchangeProvider>
    </ExchangeNavigationProvider>
  );
};

export const QuestionnaireQuestions = ({ exchangeId }: { exchangeId?: string }) => {
  const { t } = useTranslation();
  const { data: questionnaire, isLoading, isError } = useQuestionnaire();

  return isLoading ? (
    <LoadingPanel />
  ) : isError ? (
    <ErrorPanel error={t('errors.unexpected')} />
  ) : (
    // @ts-expect-error ts(2322) FIXME: Type 'Questionnaire | undefined' is not assignable to type 'Questionnaire'.
    <QuestionnaireProvider questionnaire={questionnaire}>
      <QuestionnaireQuestionsPageContent exchangeId={exchangeId} />
    </QuestionnaireProvider>
  );
};
