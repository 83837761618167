import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { Pictogram } from '@deepstream/ui-kit';
import { QuestionnaireOverview } from '@deepstream/common/preQual';
import { Panel, PanelHeader, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { TruncateCell } from '../../../TruncateCell';
import { QuestionnaireStatusCell } from '../Questionnaire/QuestionnaireStatus';
import { BasicTableStyles } from '../../../TableStyles';
import { Table } from '../../../Table';
import { CompletionCell } from '../../../CompletionCell';
import { QuestionnaireExpiryAndRenewalDateCell } from '../Questionnaire/QuestionnaireExpiryAndRenewalDateCell';
import { useQuestionnaireNavigation } from '../../../appNavigation';

export const SuppliersPanel = ({ questionnaires }: { questionnaires: QuestionnaireOverview[] }) => {
  const { t } = useTranslation('preQualification');
  const questionnaireNavigation = useQuestionnaireNavigation();

  const totalItems = questionnaires.length;

  const columns = useMemo(
    () => [
      {
        id: 'name',
        Header: t('questionnaireSuppliers.company'),
        accessor: 'recipient.name',
        Cell: TruncateCell,
      },
      {
        id: 'status',
        Header: t('questionnaireSuppliers.status'),
        accessor: 'status',
        Cell: QuestionnaireStatusCell,
      },
      {
        id: 'completion',
        Header: t('questionnaireSuppliers.completion'),
        accessor: 'completion',
        width: 260,
        Cell: CompletionCell,
      },
      {
        id: 'renewalDate',
        Header: t('questionnaireSuppliers.expiryDate'),
        accessor: questionnaire => questionnaire.renewalDate || questionnaire.expiryDate,
        Cell: QuestionnaireExpiryAndRenewalDateCell,
        width: 245,
      },
      {
        id: 'version',
        Header: t('questionnaireSuppliers.version'),
        accessor: 'meta.fromTemplateVersion',
      },
    ],
    [t],
  );

  return (
    <Panel>
      <PanelHeader heading={t('supplier_other')} />
      <PanelDivider />
      {totalItems > 0 ? (
        <BasicTableStyles>
          <Table
            columns={columns}
            data={questionnaires}
            initialPageSize={totalItems > 5 ? 10 : 5}
            pageSizes={[5, 10, 20, 50, 100]}
            isPaginated
            enforcePagination
            isSortable
            smallPageControls
            onRowClick={questionnaire => questionnaireNavigation.navigateToQuestions(questionnaire._id, { from: 'template' })}
          />
        </BasicTableStyles>
      ) : (
        <Flex height="350px">
          <PanelPadding>
            <Pictogram
              variant="empty-state"
              label={t('questionnaireSuppliers.noSuppliers')}
              labelPosition="right"
            />
          </PanelPadding>
        </Flex>
      )}
    </Panel>
  );
};
