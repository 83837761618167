/* eslint-disable consistent-return, default-case */

import * as React from 'react';
import { useQuery } from 'react-query';
import { ErrorPanel } from '../../ui/ErrorMessage';
import { LoadingPanel } from '../../ui/Loading';
import { Company } from '../../ui/types';
import { useApi, wrap } from '../../api';

/**
 * An experiment with render functions
 */

type RenderFunction = (a: Company[]) => React.ReactNode | React.ReactNode[];

// TODO: make this generic
export const GetMyCompanies = ({
  children,
  LoadingComponent = LoadingPanel,
  ErrorComponent = ErrorPanel,
}: {
  LoadingComponent?: any;
  ErrorComponent?: any;
  children: RenderFunction;
}) => {
  const query = useQuery(
    'getMyCompanies',
    wrap(useApi().getMyCompanies),
  );

  switch (query.status) {
    case 'loading': return <LoadingComponent />;
    case 'error': return <ErrorComponent error={query.error} />;
    case 'success': return <>{children(query.data!)}</>;
    // eslint-disable-next-line react/jsx-no-useless-fragment
    default: return <></>;
  }
};
