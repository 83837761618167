import { DateFormat, localeFormatDateTz } from '@deepstream/utils';
import { Attachment, BasicEvent } from './rfq-utils';

export enum DocumentEventType {
  ADDED = 'added',
  EDITED = 'edited',
  DELETED = 'deleted',
  DOWNLOADED = 'downloaded',
}

interface DocumentAddedEvent extends BasicEvent {
  type: DocumentEventType.ADDED;
  documentId: string;
  companyId: string;
  name: string;
  description: string;
  expiryDate: Date;
  attachment: Attachment;
  visibility: string;
}
interface DocumentEditedEvent extends BasicEvent {
  type: DocumentEventType.EDITED;
  name: string;
  description: string;
  expiryDate: Date;
  attachment: Attachment;
  visibility: string;
}
interface DocumentDeletedEvent extends BasicEvent {
  type: DocumentEventType.DELETED;
}
interface DocumentDownloadedEvent extends BasicEvent {
  type: DocumentEventType.DOWNLOADED;
}

export type DocumentEvent = DocumentAddedEvent
  | DocumentEditedEvent
  | DocumentDeletedEvent
  | DocumentDownloadedEvent;

export enum DocumentAuditTrailActionType {
  ADD = 'add',
  EDIT_NAME = 'editName',
  REPLACE_FILE = 'replaceFile',
  EDIT_DESCRIPTION = 'editDescription',
  EDIT_EXPIRY_DATE = 'editExpiryDate',
  EDIT_VISIBILITY = 'editVisibility',
  DELETE = 'delete',
  DOWNLOAD = 'download',
}

export const formatDocumentAuditTrailActionDetail = ({
  event,
  t,
  locale,
  timeZone,
}) => {
  switch (event.actionType) {
    case DocumentAuditTrailActionType.ADD:
    case DocumentAuditTrailActionType.REPLACE_FILE:
      return [
        t('drivePage.auditTrail.actionDetail.uploadedFile'),
        ': ',
        event.attachmentName,
      ].join('');
    case DocumentAuditTrailActionType.EDIT_NAME:
      return [
        event.previousDocumentName,
        ' → ',
        event.documentName,
      ].join('');
    case DocumentAuditTrailActionType.EDIT_DESCRIPTION:
      return event.previousDocumentDescription ? (
        [
          event.previousDocumentDescription,
          ' → ',
          event.documentDescription || `(${t('drivePage.auditTrail.actionDetail.removed')})`,
        ].join('')
      ) : (
        `${t('drivePage.auditTrail.actionDetail.descriptionAdded')}: ${event.documentDescription}`
      );
    case DocumentAuditTrailActionType.EDIT_EXPIRY_DATE:
      return event.previousDocumentExpiryDate ? (
        [
          localeFormatDateTz(new Date(event.previousDocumentExpiryDate), DateFormat.D_MMM_YYYY, { locale, timeZone }),
          ' → ',
          event.documentExpiryDate ? (
            localeFormatDateTz(new Date(event.documentExpiryDate), DateFormat.D_MMM_YYYY, { locale, timeZone })
          ) : (
            `(${t('drivePage.auditTrail.actionDetail.removed')})`
          ),
        ].join('')
      ) : (
        [
          t('drivePage.auditTrail.actionDetail.expiryDateAdded'),
          ': ',
          localeFormatDateTz(new Date(event.documentExpiryDate), DateFormat.D_MMM_YYYY, { locale, timeZone }),
        ]
      );
    case DocumentAuditTrailActionType.EDIT_VISIBILITY:
      return (
        [
          t(`drivePage.documentForm.fields.visibilityOpts.${event.previousDocumentVisibility}.label`),
          ' → ',
          t(`drivePage.documentForm.fields.visibilityOpts.${event.documentVisibility}.label`),
        ]
      );
    case DocumentAuditTrailActionType.DELETE:
    case DocumentAuditTrailActionType.DOWNLOAD:
      return null;
    default:
      throw new Error(`Unknown action type ${event.actionType}`);
  }
};
