import { DraftStages } from '../../draft/DraftStages';
import { DraftHeader } from './DraftHeader';
import * as Layout from '../../Layout';

export const DraftStagesPageContent = ({
  rfqId,
  isRevising,
  isTemplate,
}) => {
  const tabId = 'stages';

  return (
    <Layout.HeaderAndContent
      header={
        <DraftHeader
          rfqId={rfqId}
          isRevising={isRevising}
          isTemplate={isTemplate}
          selectedTabId={tabId}
        />
      }
      content={
        <DraftStages
          rfqId={rfqId}
          isRevising={isRevising}
          isTemplate={isTemplate}
          mx="auto"
        />
      }
      contentPaddingBottom={Layout.BOTTOM_TOOLBAR_CONTENT_PADDING_BOTTOM}
    />
  );
};
