import { clone, stubTrue } from 'lodash';
import { nestCells } from '../../nestCells';
import { LockedCell } from '../../lock';
import { ExchangeSnapshot } from '../../types';

type WithLockParams = {
  when?: (exchange: ExchangeSnapshot) => boolean;
  alwaysShowIcon?: boolean;
  lockEmptyValue?: boolean;
};

export const withLock = (column, {
  when,
  lockEmptyValue,
}: WithLockParams = {}) => {
  const copy = clone(column);
  copy.wrappedCell = copy.Cell;
  copy.Cell = nestCells(LockedCell, copy.Cell);
  copy.getIsLockableValue = when ?? stubTrue;
  copy.lockEmptyValue = lockEmptyValue;
  return copy;
};
