import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@deepstream/ui-kit/elements/popup/Modal';
import { ErrorPanel } from '../../../../ui/ErrorMessage';
import { LoadingPanel } from '../../../../ui/Loading';
import { ExchangeRefetchProvider } from '../../../../rfx';
import { ExchangeSnapshot } from '../../../../types';
import { CurrencyCodeProvider } from '../../../../ui/Currency';
import { SendExchangeReply } from '../../../../ExchangeModal/useSendExchangeReply';
import { useSendQuestionnaireExchangeReply } from './useSendQuestionnaireExchangeReply';
import { ExchangeProvider } from '../../../../useExchange';
import { QuestionnaireExchangeModalContent } from './QuestionnaireExchangeModalContent';
import { PageFooter } from '../../../../ExchangeModal/PageFooter';
import { PageFooterBidAutoAdvance } from '../../../../ExchangeModal/PageFooterBidAutoAdvance';
import { useQuestionnaireData } from '../questionnaireUtils';
import { useQuestionnaireExchange } from '../useQuestionnaire';

export const QuestionnaireExchangeModal = React.memo(({
  close,
  exchangeId,
  isOpen,
  showExchangeSwitcher,
  showFooter,
  FooterAutoAdvancer,
  FooterProgress,
  onUpdate,
}: {
  exchangeId?: string;
  isOpen: boolean;
  showExchangeSwitcher?: boolean;
  showFooter?: boolean;
  FooterAutoAdvancer: React.FC<{
    exchangeId: string;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  }>
  FooterProgress?: React.FC;
  close: () => void;
  onUpdate?: (exchange: ExchangeSnapshot) => void;
}) => {
  const { t } = useTranslation('preQualification');
  const { _id: questionnaireId } = useQuestionnaireData();
  const { data: exchange, refetch, error, isLoading } = useQuestionnaireExchange({
    // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
    exchangeId,
  });

  const refetchExchange = React.useCallback(async () => {
    const { data: exchange, isSuccess } = await refetch();

    if (exchange && isSuccess && onUpdate) {
      onUpdate(exchange);
    }
  }, [onUpdate, refetch]);

  const sendExchangeReplyMutation = useSendQuestionnaireExchangeReply({
    questionnaireId,
    // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
    exchangeId: exchange?._id,
  });

  return (
    <>
      <Modal
        shouldCloseOnEsc
        onRequestClose={close}
        isOpen={isOpen}
        panelStyle={{
          margin: 'auto',
          maxWidth: '90%',
          width: 1400,
        }}
      >
        {isLoading ? (
          <LoadingPanel />
        ) : error ? (
          <ErrorPanel error={t('errors.getExchange')} />
        ) : exchange ? (
          <SendExchangeReply.Provider value={sendExchangeReplyMutation}>
            <ExchangeRefetchProvider refetch={refetchExchange}>
              <ExchangeProvider exchange={exchange}>
                <CurrencyCodeProvider code={(exchange as any).currency ?? ''}>
                  <QuestionnaireExchangeModalContent
                    showExchangeSwitcher={showExchangeSwitcher}
                    close={close}
                    showFooter={showFooter}
                  />
                </CurrencyCodeProvider>
              </ExchangeProvider>
            </ExchangeRefetchProvider>
          </SendExchangeReply.Provider>
        ) : (
          null
        )}
      </Modal>
      {showFooter && isOpen ? (
        <PageFooter
          // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
          exchangeId={exchangeId}
          autoAdvance={FooterAutoAdvancer ? (
            <PageFooterBidAutoAdvance
              // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
              exchangeId={exchangeId}
              contextId={questionnaireId}
              AutoAdvancer={FooterAutoAdvancer}
            />
          ) : (
            null
          )}
          progress={FooterProgress ? (
            <FooterProgress />
          ) : (
            null
          )}
        />
      ) : (
        null
      )}
    </>
  );
});
