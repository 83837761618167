import { useMemo } from 'react';
import { findIndex } from 'lodash';
import { Text } from 'rebass/styled-components';
import { useBulletins } from '../modules/RequestMessages/useBulletins';
import { useRfqId } from '../useRfq';
import { useCurrentCompanyId } from '../currentCompanyId';

export const BulletinPost = ({ bulletinId }) => {
  const bulletins = useBulletins({
    rfqId: useRfqId(),
    currentCompanyId: useCurrentCompanyId()!,
  });

  const bulletinIndex = useMemo(
    () => findIndex(bulletins, { _id: bulletinId }),
    [bulletins, bulletinId],
  );

  if (bulletinIndex === -1) {
    return null;
  }

  return (
    <Text fontSize={1} lineHeight={1.4} mt={1}>
      #{bulletinIndex + 1} {bulletins[bulletinIndex].message}
    </Text>
  );
};
