import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box } from 'rebass/styled-components';
import { useQuery } from 'react-query';
import { first, get, isEmpty, map, orderBy } from 'lodash';
import { LabeledSorting } from '@deepstream/ui-utils';
import { SortingDirection } from '@deepstream/common';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelHeader, PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { QuestionnaireStatus } from '@deepstream/common/preQual';
import { useDeviceSize } from '../../../../ui/useDeviceSize';
import { useModalState } from '../../../../ui/useModalState';
import { useApi, wrap } from '../../../../api';
import { TeamUsersTable } from './TeamUsersTable';
import { useSortProps } from '../../../../sorting';
import { SelectDropdownMenu } from '../../../../ui/MultiSelect';
import { TeamUser, TeamUserModal } from './TeamUserModal';
import { useQuestionnaireData, useQuestionnairePermissions } from '../questionnaireUtils';
import { useCurrentCompanyId } from '../../../../currentCompanyId';

const useSortItems = (): LabeledSorting[] => {
  const { t } = useTranslation('general');

  return useMemo(() => [
    { label: t('sorting.nameAscending'), accessor: 'name', direction: SortingDirection.ASC },
    { label: t('sorting.nameDescending'), accessor: 'name', direction: SortingDirection.DESC },
  ], [t]);
};

export const TeamUsersPanel = () => {
  const { t } = useTranslation(['preQualification', 'general']);
  const api = useApi();
  const { isExtraSmall } = useDeviceSize();
  const { teamById, status } = useQuestionnaireData();
  const addUserModal = useModalState();
  const { canEditTeam } = useQuestionnairePermissions();
  const companyId = useCurrentCompanyId({ required: true });

  const { data: usersForCompany } = useQuery(
    ['usersForCompany', { companyId }],
    wrap(api.getUsersForCompany),
  );

  // @ts-expect-error ts(2322) FIXME: Type '{ _id: string; name: string; email: string | undefined; companyId: string; roleRestrictionProfiles: Record<string, RoleRestrictionProfile> | undefined; }[]' is not assignable to type 'TeamUser[]'.
  const users: TeamUser[] = useMemo(
    () => usersForCompany
      ? map(
        Object.values(teamById[companyId].users),
        user => {
          const companyUser = usersForCompany.find(
            userInCompany => userInCompany._id === user._id,
          );

          return {
            _id: user._id,
            name: companyUser?.name || user.name,
            email: companyUser?.email,
            companyId,
            roleRestrictionProfiles: companyUser?.roleRestrictionProfiles,
          };
        },
      )
      : [],
    [usersForCompany, teamById, companyId],
  );

  const sortItems = useSortItems();
  const sort = useSortProps({
    items: sortItems,
  });

  const sortedUsers = useMemo(() => {
    let usersClone = [...users];

    if (!isEmpty(sort.selectedItems)) {
      const sorting = first(sort.selectedItems);

      usersClone = orderBy(
        usersClone,
        // @ts-expect-error ts(18048) FIXME: 'sorting' is possibly 'undefined'.
        [(user) => get(user, sorting.accessor, '').toLowerCase()],
        // @ts-expect-error ts(18048) FIXME: 'sorting' is possibly 'undefined'.
        [sorting.direction],
      );
    }

    return usersClone;
  }, [users, sort]);

  return (
    <>
      <Panel>
        <PanelHeader heading={t('user_other', { ns: 'general' })}>
          <Flex>
            {!!users.length && (
              <Box>
                <SelectDropdownMenu
                  buttonText={t('sort', { ns: 'general' })}
                  buttonIcon="sort"
                  menuWidth={160}
                  menuZIndex={10}
                  {...sort}
                />
              </Box>
            )}
            {canEditTeam && status !== QuestionnaireStatus.OBSOLETE && (
              <Button
                ml={isExtraSmall ? 3 : '24px'}
                variant="primary"
                iconLeft="plus"
                onClick={addUserModal.open}
                small
              >
                {t('questionnaire.team.addUser')}
              </Button>
            )}
          </Flex>
        </PanelHeader>
        <PanelDivider />
        <TeamUsersTable users={sortedUsers} />
      </Panel>
      {addUserModal.isOpen && (
        <TeamUserModal
          close={addUserModal.close}
          companyId={companyId}
        />
      )}
    </>
  );
};
