import { Tab, TabListPanel } from '../../ui/TabsVertical';

export const MockedSidebar = () => {
  return (
    <TabListPanel heading="Categories">
      <Tab key="category1">
        General (3)
      </Tab>
      <Tab key="category2">
        Legal (0)
      </Tab>
      <Tab key="category3">
        No category (0)
      </Tab>
    </TabListPanel>
  );
};
