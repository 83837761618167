import React from 'react';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { EmptyGridPanel } from './EmptyGridPanel';
import { EmptyTableMessage } from '../../../ui/EmptyTableMessage';

export const EmptyGridMessagePanel = ({
  header,
  body,
}: {
  header: string;
  body?: string;
}) => {
  return (
    <EmptyGridPanel>
      <PanelPadding>
        <EmptyTableMessage
          header={header}
          body={body || ''}
        />
      </PanelPadding>
    </EmptyGridPanel>
  );
};
