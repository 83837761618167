import { useMemo, useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { Box } from 'rebass/styled-components';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { callAll } from '@deepstream/utils/callAll';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { useApi } from '../../api';
import { TextField } from '../../form/TextField';
import { ModalForm, ModalFormProps } from '../../ModalForm';
import { PropertyList } from '../../PropertyList';
import { useModalState } from '../../ui/useModalState';
import { User } from '../../types';
import { useToaster } from '../../toast';
import { useUserMutation } from './useUserMutation';
import { EditProperty, ValueOrNotSpecified } from './utils';
import { useCurrentUser } from '../../useCurrentUser';
import { useMutation } from '../../useMutation';

type UseRequestUserEmailUpdate = {
  onSuccess?: any;
  onError?: any;
};

const useRequestUserEmailUpdate = ({
  onSuccess,
  onError,
}: UseRequestUserEmailUpdate = {}) => {
  const { t } = useTranslation();
  const api = useApi();
  const toaster = useToaster();

  return useUserMutation({
    fn: api.requestUserEmailUpdate,
    onSuccess: callAll(
      () => toaster.success(t('user.profile.toaster.requestUserEmailUpdateSuccess')),
      onSuccess,
    ),
    onError: callAll(
      () => toaster.error(t('user.profile.toaster.requestUserEmailUpdateError')),
      onError,
    ),
  });
};

const useResendVerificationEmail = ({
  onSuccess,
  onError,
}: UseRequestUserEmailUpdate = {}) => {
  const { t } = useTranslation();
  const api = useApi();
  const toaster = useToaster();

  return useUserMutation({
    fn: (email: string) => api.requestUserEmailUpdate({ email }),
    onSuccess: callAll(
      () => toaster.success(t('user.profile.toaster.resendVerificationEmailSuccess')),
      onSuccess,
    ),
    onError: callAll(
      () => toaster.error(t('user.profile.toaster.resendVerificationEmailError')),
      onError,
    ),
  });
};

const useCancelUserEmailUpdate = ({
  onSuccess,
  onError,
}: UseRequestUserEmailUpdate = {}) => {
  const { t } = useTranslation();
  const api = useApi();
  const toaster = useToaster();

  return useUserMutation({
    fn: api.cancelUserEmailUpdate,
    onSuccess: callAll(
      () => toaster.success(t('user.profile.toaster.cancelEmailUpdateSuccess')),
      onSuccess,
    ),
    onError: callAll(
      () => toaster.error(t('user.profile.toaster.cancelEmailUpdateError')),
      onError,
    ),
  });
};

const PendingEmail = ({ value: pendingEmail }) => {
  const { t } = useTranslation();

  return (
    <Stack gap={3}>
      <Box>{pendingEmail}</Box>
      <MessageBlock variant="info" mt={0}>
        {t('user.profile.pendingEmailInfo')}
      </MessageBlock>
    </Stack>
  );
};

type EditEmailModalProps =
  Omit<ModalFormProps, 'heading' | 'initialValues' | 'validationSchema' | 'onSubmit'> &
  { user: User; onSuccess: any };

const EditEmailModal = ({ user, onSuccess, ...props }: EditEmailModalProps) => {
  const { t } = useTranslation();
  const [requestUserEmailUpdate] = useRequestUserEmailUpdate({ onSuccess });

  return (
    <ModalForm
      heading={t('user.profile.dialog.editEmail.heading')}
      initialValues={{}}
      validationSchema={yup.object({
        email: yup
          .string()
          .email(t('user.profile.errors.invalidEmail'))
          .required(t('general.required'))
          .notOneOf([user.email], t('user.profile.errors.isCurrentEmail')),
      })}
      onSubmit={({ email }, { setErrors }) =>
        requestUserEmailUpdate({
          email,
        }, {
          onError: (error: any) => {
            if (error?.response?.status === 400) {
              setErrors({ email: t('user.profile.errors.emailAlreadyInUse') });
            } else {
              Sentry.captureException(error);
            }
          },
        })
      }
      style={{ content: { width: '500px' } }}
      {...props}
    >
      <TextField
        name="email"
        label={t('user.profile.dialog.editEmail.fields.email')}
        inputType="email"
        required
      />
      <MessageBlock variant="info" mt={0}>
        {t('user.profile.dialog.editEmail.info')}
      </MessageBlock>
    </ModalForm>
  );
};

export const AccountPanel = () => {
  const { t } = useTranslation();
  const api = useApi();
  const user = useCurrentUser();
  const toaster = useToaster();
  const editEmailModal = useModalState();
  const [cancelEmailUpdate, { status: cancelEmailUpdateStatus }] = useCancelUserEmailUpdate();
  const [resendVerificationEmail, { status: verificationEmailStatus }] = useResendVerificationEmail();

  const [updatePassword, { isLoading }] = useMutation(
    api.updateUserPassword,
    {
      onError: () => toaster.error(t('user.profile.toaster.passwordUpdateError')),
    },
  );

  const properties = useMemo(
    () => [
      {
        name: t('user.profile.emailAddress'),
        value: user.email,
      },
      {
        name: t('user.profile.pendingEmailAddress'),
        value: user.emailPending,
        Component: PendingEmail,
        heightAuto: true,
        hidden: !user.emailPending,
      },
      {
        name: t('user.profile.password'),
        value: null,
        Component: () => user.auth0IsFederated ? t('user.profile.isFederated') : null,
      },
    ],
    [user, t],
  );

  const triggerPasswordUpdate = useCallback(
    async () => {
      const { ticket } = await updatePassword({});

      window.location.href = ticket;
    },
    [updatePassword],
  );

  return (
    <>
      <Panel heading={t('user.profile.account')}>
        <PropertyList properties={properties} DefaultComponent={ValueOrNotSpecified}>
          <EditProperty
            onClick={editEmailModal.open}
            disabled={user.auth0IsFederated || Boolean(user.emailPending)}
            data-test="edit-email"
          />
          {user.emailPending ? (
            <Box mt="12px">
              <DropdownMenu
                small
                variant="primary-outline"
                buttonText=""
                iconLeft="ellipsis-h"
                menuStyle={{ width: 220 }}
                data-test="pending-email-dropdown-button"
              >
                <DropdownMenuItem
                  icon="envelope"
                  onSelect={() => resendVerificationEmail(user.emailPending!)}
                  disabled={verificationEmailStatus === 'loading'}
                  data-test="resend-verification-email"
                >
                  {t('user.profile.resendVerificationEmail')}
                </DropdownMenuItem>
                <DropdownMenuItem
                  icon="times"
                  onSelect={() => cancelEmailUpdate({})}
                  disabled={cancelEmailUpdateStatus === 'loading'}
                  data-test="cancel-email-change"
                >
                  {t('user.profile.cancelEmailChange')}
                </DropdownMenuItem>
              </DropdownMenu>
            </Box>
          ) : (
            null
          )}
          <EditProperty
            onClick={triggerPasswordUpdate}
            disabled={user.auth0IsFederated || isLoading}
            data-test="edit-password"
          />
        </PropertyList>
      </Panel>
      <EditEmailModal
        user={user}
        isOpen={editEmailModal.isOpen}
        onSuccess={editEmailModal.close}
        onCancel={editEmailModal.close}
      />
    </>
  );
};
