import { BidStatus, isBidActive } from '@deepstream/common/rfq-utils';
import { useMachine } from '@xstate/react';
import { useEffect } from 'react';
import * as React from 'react';
import { last, partition, unary } from 'lodash';
import { isPast } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { useApi } from '../../../api';
import { useMutation } from '../../../useMutation';
import * as rfx from '../../../rfx';
import { useWaitForRfqUnlock } from '../../../useWaitForUnlock';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useRfqId } from '../../../useRfq';
import { defaultRejectBidsValue, twoStepSelectSupplierMachine } from './twoStepSelectSupplierMachine';
import { RequestOutcomeModal } from './RequestOutcomeModal';
import { useCloseRequestStateToComponent } from './RequestOutcomeModalStates';
import { CannotEndRequestModal } from './CannotEndRequestModal';
import { Actions } from './Actions';

type CloseRequestModalProps = {
  onClose: (shouldRefetchRfq?: boolean) => void;
} & ModalProps;

const closeRequestMachine = twoStepSelectSupplierMachine.withContext(defaultRejectBidsValue);

export const CloseRequestModal: React.FC<CloseRequestModalProps> = ({
  onClose,
  isOpen,
}) => {
  const { t } = useTranslation();
  const { bidById, recipients } = rfx.useStructure();
  const rfqId = useRfqId();
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { stages } = rfx.useStructure();
  // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
  const lastStageDeadline = rfx.useStageDeadline(last(stages)._id);
  const closeRequestStateToComponent = useCloseRequestStateToComponent();
  const waitForRfqUnlock = useWaitForRfqUnlock();

  const [closeRequest] = useMutation(
    ({ rfqId, companyId, outcome }: any) => waitForRfqUnlock({
      command: () => api.closeRequest({
        rfqId,
        companyId,
        message: outcome.hasMessage ? outcome.message : undefined,
        attachments: outcome.attachments,
      }),
    }),
  );

  const [current, send] = useMachine(
    closeRequestMachine,
    {
      services: {
        onConfirm: unary(closeRequest),
      },
    },
  );

  useEffect(
    () => {
      const remainingSuppliers = recipients.filter(recipient => {
        const bidStatus = bidById[recipient._id].status;

        return bidStatus !== BidStatus.UNSUCCESSFUL;
      });

      const [activeSuppliers, inactiveSuppliers] = partition(
        remainingSuppliers,
        (recipient) => {
          const bidStatus = bidById[recipient._id].status;

          // @ts-expect-error ts(2345) FIXME: Argument of type 'BidStatus | null' is not assignable to parameter of type 'BidStatus'.
          return isBidActive(bidStatus);
        },
      );

      send(Actions.INIT, {
        rfqId,
        companyId: currentCompanyId,
        activeSuppliers,
        inactiveSuppliers,
        selectedActiveSuppliers: activeSuppliers,
        outcome: {
          hasMessage: !!remainingSuppliers.length,
        },
      });
    },
    [bidById, recipients, rfqId, currentCompanyId, send, isOpen],
  );

  return (
    // @ts-expect-error ts(2345) FIXME: Argument of type 'Date | null' is not assignable to parameter of type 'number | Date'.
    isPast(lastStageDeadline) ? (
      <RequestOutcomeModal
        modalTitle={t('request.closeRequest')}
        isOpen={isOpen}
        stateComponents={closeRequestStateToComponent}
        send={send}
        state={current}
        onClose={onClose}
      />
    ) : (
      <CannotEndRequestModal
        isOpen={isOpen}
        onClose={onClose}
      />
    )
  );
};
