import { useMemo, useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { get, isEmpty, map, orderBy, reject, size, sortBy, uniqBy } from 'lodash';
import { LabeledSortCriterion } from '@deepstream/ui-utils';
import { SortingAccessor } from '@deepstream/common';

import { Illustration } from '@deepstream/ui-kit';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { useCurrentCompanyId } from './currentCompanyId';
import { useApi, wrap } from './api';
import { NewTemplateModal } from './NewTemplateModal';
import { NewBlankTemplateModal } from './NewBlankTemplateModal';
import { Loading } from './ui/Loading';
import { ErrorMessage } from './ui/ErrorMessage';
import { TemplatesTable } from './TemplatesTable';
import { MIN_CELL_HEIGHT } from './FieldsCell';
import { useModalState } from './ui/useModalState';
import { DeleteTemplateDialog } from './DeleteTemplateDialog';
import { DashboardTemplate } from './types';
import { TemplateCount } from './TemplateCount';
import { SelectDropdownMenu } from './ui/MultiSelect';
import { useCurrentUser } from './useCurrentUser';
import { renderName } from './RequestsTable';
import { useLocalStorageSortProps2, useSortDirectionItems } from './sorting';
import { ClearFiltersButton, useLocalStorageFilterProps } from './filtering';
import { SortDropdown } from './ui/SortDropdown';

const useCreatorFilterItems = (templates: DashboardTemplate[] | undefined, userId: string) => {
  const { t } = useTranslation();

  return useMemo(() => {
    if (!templates) {
      return;
    }

    const items = templates.map(template => ({
      name: template.meta.createdBy.name,
      _id: template.meta.createdBy._id,
    }));

    const uniqueItems = sortBy(
      uniqBy(items, '_id'),
      'name',
    );

    const itemsWithoutMe = reject(uniqueItems, { _id: userId });

    if (size(itemsWithoutMe) !== size(uniqueItems)) {
      return [
        // Add 'me' as first item
        { _id: userId, name: t('requests.filtering.me') },
        ...itemsWithoutMe,
      ];
    } else {
      return itemsWithoutMe;
    }
  }, [t, templates, userId]);
};

const SORT_FIELD_BY_ACCESSOR = {
  [SortingAccessor.creationDate]: 'meta.createdAt',
  [SortingAccessor.updateDate]: 'meta.lastEditedAt',
  [SortingAccessor.templateName]: 'meta.name',
};

const useSortCriteriaItems = (): LabeledSortCriterion[] => {
  const { t } = useTranslation('translation');

  return useMemo(() => [
    { label: t('requests.sorting.creationDate'), accessor: SortingAccessor.creationDate },
    { label: t('requests.sorting.updateDate'), accessor: SortingAccessor.updateDate },
    { label: t('requests.sorting.templateName'), accessor: SortingAccessor.templateName },
  ], [t]);
};

export const Templates = () => {
  const { t } = useTranslation('translation');
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();
  const api = useApi();
  const newTemplateModal = useModalState();
  const newBlankTemplateModal = useModalState();
  const deleteTemplateModal = useModalState();
  const [selectedTemplate, setSelectedTemplate] = useState<DashboardTemplate | null>(null);

  const { data: templates, status } = useQuery(
    ['templates', { companyId: currentCompanyId }],
    wrap(api.getTemplates),
  );

  const openDeleteModal = useCallback(
    (template) => {
      setSelectedTemplate(template);
      deleteTemplateModal.open();
    },
    [setSelectedTemplate, deleteTemplateModal],
  );

  const creatorFilterItems = useCreatorFilterItems(templates, user._id);
  const creatorFilterProps = useLocalStorageFilterProps({
    storageKey: `${currentCompanyId}.${user._id}.templates.creatorFilter`,
    items: creatorFilterItems,
    idProp: '_id',
    renderItem: renderName,
  });

  const criteriaItems = useSortCriteriaItems();
  const directionItems = useSortDirectionItems();
  const sortProps = useLocalStorageSortProps2({
    storageKey: `${currentCompanyId}.${user._id}.templates.sort`,
    criteriaItems,
    directionItems,
  });

  // TODO - filtering and sorting logic should happen on the server side
  const selectedTemplates = useMemo(() => {
    if (!templates) {
      return null;
    }

    let templatesClone = [...templates];

    if (!isEmpty(creatorFilterProps.selectedItems)) {
      const selectedIds = map(creatorFilterProps.selectedItems, '_id');
      templatesClone = templatesClone.filter(
        template => selectedIds.includes(template.meta.createdBy._id),
      );
    }

    const sortField = SORT_FIELD_BY_ACCESSOR[sortProps.selectedSortCriterion.accessor];
    const sortingDirection = sortProps.selectedSortDirection.direction;
    templatesClone = orderBy(
      templatesClone,
      [(template: DashboardTemplate) =>
        get(template, sortField, '').toLowerCase()],
      [sortingDirection],
    );

    return templatesClone;
  }, [templates, creatorFilterProps, sortProps]);

  const hasFilters = !isEmpty(creatorFilterProps.selectedItems);

  return (
    <>
      <Panel>
        <PanelHeader
          height="60px"
          pt="1px"
          pb="2px"
          heading={selectedTemplates ? (
            <TemplateCount count={size(selectedTemplates)} />
          ) : null}
          collapse={false}
        >
          <Flex>
            {status === 'success' && hasFilters && (
              <Box mr={2}>
                <ClearFiltersButton
                  onClick={() => creatorFilterProps.onChange([])}
                />
              </Box>
            )}
            <Box mr={2}>
              <SelectDropdownMenu
                multi
                buttonText={t('requests.creator')}
                buttonIcon="filter"
                menuWidth={180}
                menuZIndex={10}
                disabled={status !== 'success'}
                {...creatorFilterProps}
              />
            </Box>
            <Box mr="24px">
              <SortDropdown
                disabled={status !== 'success' || (!!selectedTemplates && isEmpty(selectedTemplates))}
                {...sortProps}
              />
            </Box>
            <DropdownMenu
              rightAligned
              disabled={!templates}
              small
              variant="primary"
              buttonText={t('template.newTemplate')}
              iconLeft="plus"
              iconRight="caret-down"
              menuZIndex={10}
            >
              <DropdownMenuItem icon="file-o" onSelect={newBlankTemplateModal.open}>
                {t('requests.blankTemplate')}
              </DropdownMenuItem>
              <DropdownMenuItem icon="files-o" onSelect={newTemplateModal.open}>
                {t('requests.basedOnExistingRequest')}
              </DropdownMenuItem>
            </DropdownMenu>
          </Flex>
        </PanelHeader>
        <PanelDivider />
        {status === 'success' && templates && selectedTemplates && !isEmpty(templates) ? (
          <TemplatesTable
            templates={selectedTemplates}
            openDeleteModal={openDeleteModal}
          />
        ) : (
          <Flex
            height={MIN_CELL_HEIGHT * 5}
            flexDirection="column"
            justifyContent="center"
          >
            <PanelPadding>
              {status === 'loading' ? (
                <Loading fontSize={4} fontWeight={400} />
              ) : status === 'error' ? (
                <ErrorMessage fontSize={3} error={t('template.errors.getTemplate')} />
              ) : hasFilters ? (
                <Illustration
                  variant="search-results"
                  label={
                    <Flex flexDirection="column" sx={{ gap: 2 }}>
                      <Text>
                        {t('requests.noRequestsWithSearchOrFilter')}
                      </Text>
                      <ClearFiltersButton
                        onClick={() => creatorFilterProps.onChange([])}
                        alignSelf="center"
                      />
                    </Flex>
                  }
                  labelSx={{
                    fontWeight: 500,
                  }}
                />
              ) : (
                <Illustration
                  variant="empty-state"
                  label={t('requests.companyHasNotCreatedTemplates')}
                  labelSx={{
                    fontWeight: 500,
                  }}
                />
              )}
            </PanelPadding>
          </Flex>
        )}
      </Panel>
      {newBlankTemplateModal.isOpen && (
        <NewBlankTemplateModal
          isOpen={newBlankTemplateModal.isOpen}
          close={newBlankTemplateModal.close}
        />
      )}
      {newTemplateModal.isOpen && (
        <NewTemplateModal
          isOpen={newTemplateModal.isOpen}
          close={newTemplateModal.close}
        />
      )}
      {selectedTemplate && (
        <DeleteTemplateDialog
          {...deleteTemplateModal}
          rfqId={selectedTemplate._id}
          rfqName={selectedTemplate.meta.name}
          onSettled={() => {
            setSelectedTemplate(null);
            deleteTemplateModal.close();
          }}
        />
      )}
    </>
  );
};
