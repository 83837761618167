import * as React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import {
  AuctionLineItemExchangeDefinition,
  Company,
  ExchangeDefinition,
  getAuctionLineItemExchangeDef,
 RfxAuctionLineItemsSection } from '@deepstream/common/rfq-utils';
import {
  findIndex,
  isNumber,
  last,
  propertyOf,
} from 'lodash';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { ObsoleteIcon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { Clamp } from '@deepstream/ui-kit/elements/text/Clamp';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { ComparisonGrid } from '@deepstream/ui-kit/grid/core/ComparisonGrid';
import { DefaultComparisonGridStyles } from '@deepstream/ui-kit/grid/core/ComparisonGridStyles';
import {
  DataCellProps,
  FrozenHeaderCellProps,
} from '@deepstream/ui-kit/grid/core/utils';
import { DEFAULT_FROZEN_LEFT_COLUMN, DEFAULT_FROZEN_LEFT_COLUMN_IDS } from '@deepstream/ui-kit/grid/core/constants';
import { AuctionLot, RfxStructure } from '../../../types';
import {
  exchangeDefLineClampByRowHeight, RowHeight, rowHeightMeasurements,
} from '../Comparison/rowHeight';
import * as rfx from '../../../rfx';
import { InlineLabel } from '../Comparison/InlineLabel';
import { CurrencyAmount } from '../../../ui/Currency';
import { CompanyLogo } from '../../../CompanyLogo';

type LineItemSubRowData = AuctionLineItemExchangeDefinition;

type GridLineItemsSection = AuctionLineItemExchangeDefinition & {
  subRows: LineItemSubRowData[];
};

function AuctionLineItemBid({ lineItemBid, hasShortCellHeight, t }: {
  lineItemBid?: { price: number; quantity: number; };
  hasShortCellHeight: boolean;
  t: TFunction;
}) {
  const section = rfx.useSection() as RfxAuctionLineItemsSection;
  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      alignItems="flex-end"
      flex={1}
      sx={{ height: '100%' }}
    >
      {isNumber(lineItemBid?.price) ? (
        <>
          <Truncate>
            <CurrencyAmount showCode value={lineItemBid.price} decimalPlaces={section.auctionRules.decimalPlaces} />
          </Truncate>
          {hasShortCellHeight ? (
            null
          ) : (
            <Box
              fontSize="9px"
              fontWeight="normal"
              letterSpacing="0.3px"
            >
              <InlineLabel>{t('general.total')}</InlineLabel>
              <CurrencyAmount showCode value={lineItemBid.price * lineItemBid.quantity} decimalPlaces={2} />
            </Box>
          )}
        </>
      ) : (
        <EmDash />
      )}
    </Flex>
  );
}

const Rank = ({ rank }: { rank: number }) => {
  const { t } = useTranslation();

  return (
    <Box as="span" fontSize="9px" mt="2px" letterSpacing="0.3px">
      <InlineLabel>{t('request.auction.rank')}</InlineLabel>
      {rank > -1 ? t('request.auction.bidFeedback.ordinal', { count: rank + 1, ordinal: true }) : <EmDash />}
    </Box>
  );
};

export const createFrozenHeaderCell = (lot: AuctionLot, onlyObsoleteExchangeDefs?: boolean) =>
  ({ column }: FrozenHeaderCellProps<Company>) => {
    if (column.index === 0) {
      return null;
    }

    const companyId = column.original._id;
    const rank = findIndex(
      lot.rankedBids,
      bid => bid.bidderId === companyId,
    );

    return (
      <>
        <Box minWidth="32px">
          <CompanyLogo companyId={companyId} size="xs" backgroundColor="white" />
        </Box>
        <Flex flexDirection="column">
          <Truncate fontWeight={500}>
            {column.original.company.name}
          </Truncate>
          {onlyObsoleteExchangeDefs ? null : <Rank rank={rank} />}
        </Flex>
      </>
    );
  };

function TotalPrice({
  t,
  value,
}: {
  t: TFunction;
  value: number;
}) {
  return (
    <Text fontSize={1} width="100%" textAlign="right">
      <InlineLabel mr="6px">{t('general.total')}</InlineLabel>
      <CurrencyAmount showCode value={value} />
    </Text>
  );
}

const createFrozenFooterCell = ({
  t,
  lot,
}: {
  t: TFunction;
  lot: AuctionLot;
}) =>
  ({ column }: FrozenHeaderCellProps<Company>) => {
    const recipientId = column.original._id;
    const price = last(lot.bidsByBidderId[recipientId])?.price ?? null;

    return (
      // @ts-expect-error ts(2322) FIXME: Type 'number | null' is not assignable to type 'number'.
      <TotalPrice t={t} value={price} />
    );
  };

const createFirstColumnCell = ({
  exchangeDefById,
  t,
}: {
  exchangeDefById: Record<string, ExchangeDefinition<'live'>>,
  t: TFunction
}) =>
  ({ row }: DataCellProps<Company, LineItemSubRowData, GridLineItemsSection>) => {
    const exchangeDef = getAuctionLineItemExchangeDef(row.original, exchangeDefById);
    const isObsolete = row.original.isObsolete || exchangeDef?.isObsolete;

    return exchangeDef ? (
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        sx={{ height: '100%', opacity: isObsolete ? 0.4 : undefined }}
      >
        <Flex alignItems="center" sx={{ gap: 2 }}>
          {isObsolete ? <ObsoleteIcon /> : null}
          <Clamp lines={exchangeDefLineClampByRowHeight[row.height]}>
            {exchangeDef.description}
          </Clamp>
        </Flex>
        {row.height === rowHeightMeasurements[RowHeight.SHORT] ? (
          null
        ) : (
          <Box fontSize="9px" fontWeight="normal" letterSpacing="0.3px">
            <InlineLabel>{t('general.unit')}</InlineLabel>
            {exchangeDef.unit}
            <InlineLabel ml={2}>{t('general.quantity')}</InlineLabel>
            {exchangeDef.quantity}
          </Box>
        )}
      </Flex>
    ) : (
      null
    );
  };

const createDataCell = ({ lot, t }: { lot: AuctionLot; t: TFunction }) => ({
  row,
  column,
}: DataCellProps<Company, LineItemSubRowData, GridLineItemsSection>) => {
  const recipientId = column.original._id;
  const exchangeDefId = row.original._id;

  const lastBid = last(lot.bidsByBidderId[recipientId]);
  const lineItemBid = lastBid?.breakdown[exchangeDefId];

  return (
    <Flex justifyContent="space-between" alignItems="stretch" height="100%">
      <AuctionLineItemBid
        lineItemBid={lineItemBid}
        hasShortCellHeight={row.height === rowHeightMeasurements[RowHeight.SHORT]}
        t={t}
      />
    </Flex>
  );
};

export const useRowData = (
  section: RfxAuctionLineItemsSection | undefined,
  structure: RfxStructure,
  onlyObsoleteExchangeDefs?: boolean,
) => React.useMemo(() => {
  if (!section) {
    return [];
  }

  const { exchangeDefById } = structure;

  const selectedExchangeDefs = section.exchangeDefIds
    .map(propertyOf(exchangeDefById))
    .filter((exchangeDef): exchangeDef is AuctionLineItemExchangeDefinition => {
      const baseExchangeDef = getAuctionLineItemExchangeDef(exchangeDef, exchangeDefById);

      return onlyObsoleteExchangeDefs
        ? exchangeDef.isObsolete || baseExchangeDef.isObsolete
        : !exchangeDef.isObsolete && !baseExchangeDef.isObsolete;
    });

  return selectedExchangeDefs;
}, [onlyObsoleteExchangeDefs, section, structure]);

export const AuctionLineItemsComparisonGrid = ({
  columnData,
  rowData,
  subRowHeight,
  onlyObsoleteExchangeDefs,
  gridRef,
}: {
  columnData: Company[];
  rowData: ReturnType<typeof useRowData>;
  subRowHeight: number;
  onlyObsoleteExchangeDefs?: boolean;
  gridRef?: React.MutableRefObject<any>;
}) => {
  const { t } = useTranslation();
  const structure = rfx.useStructure();
  const lot = rfx.useAuctionLot();

  const { exchangeDefById } = structure;

  const FrozenHeaderCell = React.useMemo(
    () => createFrozenHeaderCell(lot, onlyObsoleteExchangeDefs),
    [onlyObsoleteExchangeDefs, lot],
  );

  const FrozenFooterCell = React.useMemo(
    () => createFrozenFooterCell({ lot, t }),
    [lot, t],
  );

  const FirstColumnCell = React.useMemo(
    () => createFirstColumnCell({
      exchangeDefById,
      t,
    }),
    [exchangeDefById, t],
  );

  const DataCell = React.useMemo(
    () => createDataCell({ lot, t }),
    [lot, t],
  );

  const columnDataWithFirstColumn = React.useMemo(() => {
    return [
      DEFAULT_FROZEN_LEFT_COLUMN as any,
      ...columnData,
    ];
  }, [columnData]);

  const frozenLeftColumnIds = DEFAULT_FROZEN_LEFT_COLUMN_IDS;

  return rowData ? (
    <DefaultComparisonGridStyles
      noBodyPadding
      style={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      <ComparisonGrid
        gridRef={gridRef}
        columnData={columnDataWithFirstColumn}
        rowData={rowData as any}
        subRowHeight={subRowHeight}
        staticRowHeights
        FrozenHeaderCell={FrozenHeaderCell}
        // @ts-expect-error ts(2322) FIXME: Type '(({ column }: FrozenHeaderCellProps<Company>) => Element) | null' is not assignable to type 'FunctionComponent<FrozenHeaderCellProps<Company>> | undefined'.
        FrozenFooterCell={onlyObsoleteExchangeDefs ? null : FrozenFooterCell}
        FirstColumnCell={FirstColumnCell}
        DataCell={DataCell}
        bodyPaddingLeft={0}
        bodyPaddingRight={0}
        bodyPaddingBottom={0}
        defaultColumnWidth={180}
        frozenLeftColumnIds={frozenLeftColumnIds}
      />
    </DefaultComparisonGridStyles>
  ) : (
    null
  );
};
