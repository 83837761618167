export enum RowHeight {
  SHORT = 'short',
  MEDIUM = 'medium',
  TALL = 'tall',
  EXTRA_TALL = 'extraTall',
}

export const rowHeightMeasurements = {
  [RowHeight.SHORT]: 32,
  [RowHeight.MEDIUM]: 48,
  [RowHeight.TALL]: 78,
  [RowHeight.EXTRA_TALL]: 108,
};

export const exchangeDefLineClampByRowHeight = {
  [rowHeightMeasurements[RowHeight.SHORT]]: 1,
  [rowHeightMeasurements[RowHeight.MEDIUM]]: 2,
  [rowHeightMeasurements[RowHeight.TALL]]: 4,
  [rowHeightMeasurements[RowHeight.EXTRA_TALL]]: 6,
};

export const twoLinesRowLineClamp = {
  [rowHeightMeasurements[RowHeight.SHORT]]: 1,
  [rowHeightMeasurements[RowHeight.MEDIUM]]: 1,
  [rowHeightMeasurements[RowHeight.TALL]]: 3,
  [rowHeightMeasurements[RowHeight.EXTRA_TALL]]: 5,
};

export const exchangeLineClampByRowHeight = {
  [rowHeightMeasurements[RowHeight.SHORT]]: 1,
  [rowHeightMeasurements[RowHeight.MEDIUM]]: 2,
  [rowHeightMeasurements[RowHeight.TALL]]: 4,
  [rowHeightMeasurements[RowHeight.EXTRA_TALL]]: 6,
};

export const exchangeLineClampByActiveRowHeight = {
  [rowHeightMeasurements[RowHeight.SHORT]]: 2,
  [rowHeightMeasurements[RowHeight.MEDIUM]]: 3,
  [rowHeightMeasurements[RowHeight.TALL]]: 5,
  [rowHeightMeasurements[RowHeight.EXTRA_TALL]]: 7,
};
