import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { CancelButton, Modal, ModalBody, ModalFooter, ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import { useApi } from '../../api';
import { useMutation } from '../../useMutation';
import * as rfx from '../../rfx';
import { useDraftRfqStructureQueryKey, useRfqId } from '../../useRfq';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useToaster } from '../../toast';
import { useWaitForRfqUnlock } from '../../useWaitForUnlock';

export const DisableEvaluationModal = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const { t } = useTranslation('evaluation');
  const api = useApi();
  const rfqId = useRfqId();
  const queryClient = useQueryClient();
  const { isTemplate, isRevising } = rfx.useState();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqQueryKey = useDraftRfqStructureQueryKey({ rfqId, currentCompanyId, isTemplate });
  const toaster = useToaster();
  const waitForRfqUnlock = useWaitForRfqUnlock();

  const [updateRequestSettings, { isLoading }] = useMutation(
    (payload) => waitForRfqUnlock({
      isTemplate,
      command: () => api.updateRequestSettings(payload),
    }),
    {
      onSuccess: callAll(
        () => toaster.success(t('disableModal.toaster.success')),
        close,
      ),
      onError: () => toaster.error(t('disableModal.toaster.error')),
      onSettled: () => queryClient.invalidateQueries(rfqQueryKey),
    },
  );

  const handleDisableEvaluationClicked = () => {
    updateRequestSettings({
      rfqId,
      companyId: currentCompanyId,
      settings: { isEvaluationEnabled: false },
      isTemplate,
      isRevising,
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      shouldCloseOnEsc
      style={{ content: { width: '500px', minWidth: 'min(500px, 100vw - 32px)' } }}
    >
      <ModalHeader>
        {t('disableModal.title')}
      </ModalHeader>
      <ModalBody>
        <MessageBlock variant="warn" mt={0}>
          {t('disableModal.warning')}
        </MessageBlock>
      </ModalBody>
      <ModalFooter>
        <CancelButton onClick={close} />
        <Button variant="danger" onClick={handleDisableEvaluationClicked} disabled={isLoading}>
          {t('disableModal.submitButton')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
