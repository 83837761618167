import { Box, Text, Flex } from 'rebass/styled-components';
import * as Sentry from '@sentry/react';
import { UnexpectedStateError } from '@deepstream/errors';
import { PriceQuestionExchangeDefinition, QuestionExchangeDefinition, QuestionType } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

export const Price = ({
  exchangeDef,
}: {
  exchangeDef: QuestionExchangeDefinition
}) => {
  const { t } = useTranslation();
  const { questionType, currencies } = exchangeDef as PriceQuestionExchangeDefinition;

  if (questionType !== QuestionType.PRICE) {
    Sentry.captureException(new UnexpectedStateError('[Price] Wrong question type passed', { questionType }));
    return null;
  }

  return (
    <Box>
      <Text sx={{ display: 'block' }}>
        {t(`request.question.questionType.${questionType}`)}
      </Text>
      {!isEmpty(currencies) && (
        <Flex mt={2}>
          <Text>{t('general.currency')}</Text>
          {/* Although the exchange definition supports multiple currency selection, the UI isn't exposing that (yet). */}
          {/*
           // @ts-expect-error ts(18047) FIXME: 'currencies' is possibly 'null'. */}
          <Text ml={3}>{currencies[0]}</Text>
        </Flex>
      )}
    </Box>
  );
};
