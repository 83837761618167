import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { callAll } from '@deepstream/utils/callAll';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useMutation } from '../../../useMutation';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useApi } from '../../../api';
import { useToaster } from '../../../toast';
import { useQuestionnaireTemplateQueryKey } from './useQuestionnaireTemplate';

export const RestoreQuestionnaireTemplateDialog = ({
  templateId,
  onSuccess,
  onError,
  isOpen,
  close,
}: {
  templateId: string;
  onSuccess?: () => void;
  onError?: () => void;
  isOpen: boolean;
  close: () => void;
}) => {
  const { t } = useTranslation(['preQualification', 'general']);
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const templateQueryKey = useQuestionnaireTemplateQueryKey({
    templateId,
    currentCompanyId,
    scope: 'current',
  });

  const [restoreTemplateMutation] = useMutation(
    api.restoreQuestionnaireTemplate,
    {
      onSettled: callAll(
        () => queryClient.invalidateQueries(['activeQuestionnaireTemplates', { currentCompanyId }]),
        () => queryClient.invalidateQueries(['archiveQuestionnaireTemplates', { currentCompanyId }]),
        () => queryClient.invalidateQueries(['sentQuestionnaires', { currentCompanyId, filter: { fromTemplateId: templateId } }]),
        () => queryClient.invalidateQueries(templateQueryKey),
      ),
      onSuccess: callAll(
        () => toaster.success(t('toaster.templateRestored.success')),
        onSuccess,
      ),
      onError: callAll(
        () => toaster.error(t('toaster.templateRestored.failed')),
        onError,
      ),
    },
  );

  const restoreTemplate = () => restoreTemplateMutation({
    currentCompanyId,
    templateId,
  });

  return (
    <Dialog
      heading={t('confirmation', { ns: 'general' })}
      body={(
        <MessageBlock variant="warn" mt={0}>
          {t('dialog.confirmTemplateRestoration.body')}
        </MessageBlock>
      )}
      okButtonText={t('dialog.confirmTemplateRestoration.okButtonText')}
      okButtonVariant="primary"
      style={{ content: { width: '375px', minWidth: '375px' } }}
      isOpen={isOpen}
      onOk={restoreTemplate}
      onCancel={close}
      showCloseIcon
    />
  );
};
