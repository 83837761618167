import { first, values, some, isNil, find } from 'lodash';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
  QuestionAddressField,
  QuestionExchangeDefinition,
  QuestionSection,
  QuestionType,
  ShortTextSchemaType,
  Draft,
  QuestionFormat,
} from '@deepstream/common/rfq-utils';
import { PanelDivider, ExpandablePanelSubSection } from '@deepstream/ui-kit/elements/Panel';
import * as draft from './draft';
import * as rfx from '../rfx';
import { useStageName } from './useStageName';
import { DraftPanel } from './DraftPanel';
import { QuestionExchangeDefsGrid } from './QuestionExchangeDefsGrid';
import { Validation } from './validation';
import { SectionConfigHeading } from './SectionConfigHeading';
import { QuestionSectionConfigIndicators } from './QuestionSectionConfigIndicators';
import { DraftSectionViewPanelHeader } from './DraftSectionViewPanelHeader';
import { NO_LOT_PROXY_ID, useLotSelectItems } from './useLotSelectItems';

const ViewSectionConfig = () => {
  const { settings } = rfx.useStructure<Draft>();
  const section = rfx.useSectionWithPosition();
  const stageId = section.stages?.[0];
  const stageName = useStageName(stageId);
  const isSender = rfx.useIsSender();

  const lotSelectItems = useLotSelectItems();
  const selectedLot = settings.areLotsEnabled
    ? find(lotSelectItems, { value: first(section.lotIds) || NO_LOT_PROXY_ID })
    : null;

  return (
    <ExpandablePanelSubSection
      heading={<SectionConfigHeading />}
      renderCollapsedContent={() => (
        <QuestionSectionConfigIndicators
          isSender={isSender}
          // @ts-expect-error ts(2322) FIXME: Type 'string | number | null' is not assignable to type 'string | number | undefined'.
          stageName={stageName}
          locking={section.locking}
          // @ts-expect-error ts(2322) FIXME: Type 'LotSelectItem | null | undefined' is not assignable to type 'LotSelectItem | undefined'.
          selectedLot={selectedLot}
        />
      )}
    />
  );
};

/**
 * Displays a read-only panel for the current questions section
 */
export const QuestionSectionViewPanel = () => {
  const { t } = useTranslation();
  const showValidationErrors = draft.useShowValidationErrors();
  const section = rfx.useSectionWithPosition<QuestionSection>();
  const initialExchangeDefs = rfx.useSectionExchangeDefs() as QuestionExchangeDefinition[];

  return (
    <Validation
      values={{
        section,
        exchangeDefs: initialExchangeDefs,
      }}
      schema={showValidationErrors ? (
        yup.object().shape({
          section: yup.object().shape({
            name: yup.string().required(t('general.required')),
          }),
          exchangeDefs: yup.array(
            yup.object().shape({
              description: yup.string().required(t('general.required')),
              questionType: yup.string().oneOf(values(QuestionType)).required(t('general.required')),
              options: yup
                .array(yup.string())
                .when('questionType', {
                  is: questionType => [QuestionType.CHECKBOXES, QuestionType.MULTIPLE_CHOICE].includes(questionType),
                  then: schema => schema
                    .test('unique', '', list => list.length === new Set(list).size)
                    .test('nonEmpty', t('general.required'), list => !some(list, (option) => isNil(option) || option === ''))
                    .min(1, t('request.review.errors.minimumOne')),
                  otherwise: schema => schema.nullable(),
                }),
              visibleFields: yup
                .array(yup.string().oneOf(values(QuestionAddressField)))
                .when('questionType', {
                  is: QuestionType.ADDRESS,
                  then: schema => schema.required().min(1),
                  otherwise: schema => schema.nullable(),
                }),
              format: yup
                .string().oneOf(values(QuestionFormat))
                .when('questionType', {
                  is: QuestionType.DATE_TIME,
                  then: schema => schema.required().test(
                    'has-one-set',
                    () => {},
                    (format) => format !== QuestionFormat.INVALID,
                  ),
                  otherwise: schema => schema.nullable(),
                }),
              schema: yup.object().shape({
                type: yup.string().oneOf(values(ShortTextSchemaType)).required(),
                min: yup.number().required(),
                max: yup.number().required().notOneOf([yup.ref('min')]),
              }).default(undefined),
              isRequired: yup.boolean(),
              allowCustomOption: yup.boolean(),
              currencies: yup.array(yup.string()).min(1).nullable(),
            }),
          ).required().min(1, t('request.review.errors.minimumOneRequired')),
        })
      ) : (
        yup.mixed()
      )}
    >
      <DraftPanel panelId={section._id}>
        <DraftSectionViewPanelHeader icon="question" />
        <PanelDivider />
        <ViewSectionConfig />
        <PanelDivider />
        <QuestionExchangeDefsGrid />
      </DraftPanel>
    </Validation>
  );
};
