import { useTranslation } from 'react-i18next';
import { QuestionnaireExpiryConfig, timeUnitToAfterTranslationKey, timeUnitToExpireAfterTranslationKey } from '@deepstream/common/preQual';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';

export const ExpiryOffset = ({ value, onlyOffset }: { value: QuestionnaireExpiryConfig; onlyOffset?: boolean }) => {
  const { t } = useTranslation('preQualification');
  const { doesExpire, offset } = value;
  const { unit, amount } = offset;

  return doesExpire && unit && amount ? (
    <>
      {onlyOffset ? (
        t(timeUnitToAfterTranslationKey[unit], { count: amount })
      ) : (
        t(timeUnitToExpireAfterTranslationKey[unit], { count: amount })
      )}
    </>
  ) : (
    <EmDash />
  );
};
