import { useQueryClient } from 'react-query';
import { Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { callAll } from '@deepstream/utils/callAll';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { useCurrentCompanyId } from './currentCompanyId';
import { useApi } from './api';
import { useToaster } from './toast';
import { useMutation } from './useMutation';

export const DeleteTemplateDialog = ({
  rfqId,
  rfqName,
  onSuccess,
  onSettled,
  onError,
  isOpen,
  open,
  close,
  toggle,
  hideRfqName,
}: {
  rfqId: string;
  rfqName?: string;
  onSuccess?: () => void;
  onSettled?: () => void;
  onError?: () => void;
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
  hideRfqName?: boolean;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const [deleteTemplateMutation] = useMutation(
    api.deleteTemplate,
    {
      onSettled: callAll(
        () => queryClient.invalidateQueries(['templates', { companyId: currentCompanyId }]),
        onSettled,
      ),
      onSuccess: callAll(
        () => toaster.success(t('requests.toaster.templateDeleted.success')),
        onSuccess,
      ),
      onError: callAll(
        () => toaster.error(t('requests.toaster.templateDeleted.failed')),
        onError,
      ),
    },
  );

  const deleteTemplate = () => deleteTemplateMutation({
    companyId: currentCompanyId,
    templateId: rfqId,
  });

  return (
    <Dialog
      heading={t('requests.dialog.confirmTemplateDeletion.heading')}
      body={(
        <>
          {hideRfqName ? (
            null
          ) : (
            <>
              <Text mb={2}>{t('requests.dialog.confirmTemplateDeletion.body')}:</Text>
              <Text fontWeight={500}>{rfqName || t('requests.untitledTemplate')}</Text>
            </>
          )}
          <MessageBlock variant="warn" mt={3}>
            {t('requests.dialog.confirmTemplateDeletion.warning')}
          </MessageBlock>
        </>
      )}
      okButtonText={t('requests.dialog.confirmTemplateDeletion.okButtonText')}
      okButtonVariant="danger"
      isOpen={isOpen}
      onOk={deleteTemplate}
      onCancel={close}
      showCloseIcon
    />
  );
};
