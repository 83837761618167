import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import { Popover } from '@deepstream/ui-kit/elements/popup/usePopover';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { TextField } from '../../form/TextField';
import { FieldContainer } from '../../form/FieldContainer';
import { useToaster } from '../../toast';
import { LabelConfig, LabelConfigProvider } from '../../LabelConfigProvider';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useApi } from '../../api';
import { useMutation } from '../../useMutation';

type DiscoveryFeedbackModalProps = {
  queryId?: string;
  popover: any;
  initialRating?: number;
};

export const DiscoveryFeedbackPopover = ({
  queryId,
  initialRating,
  popover,
}: DiscoveryFeedbackModalProps) => {
  const api = useApi();
  const toaster = useToaster();
  const { t } = useTranslation(['supplierDiscovery', 'general']);
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const [sendDiscoveryFeedback] = useMutation(api.sendDiscoveryFeedback);

  return (
    <Popover
      width={500}
      height={395}
      onClickOutside={popover.close}
      sx={{
        bottom: 55,
        right: 70,
        position: 'fixed',
      }}
      isOpen
    >
      <Formik
        initialValues={{
          feedback: '',
          rating: initialRating ?? null,
        }}
        validationSchema={
          yup.object().shape({
            feedback: yup.string().max(2500, t('feedbackModal.error.lte2500Chars')),
            rating: yup.number().oneOf([1, 0, -1]).required(t('feedbackModal.error.ratingRequired')),
          })
        }
        onSubmit={async (values) => {
          await sendDiscoveryFeedback({
            feedback: values.feedback,
            // @ts-expect-error ts(2322) FIXME: Type 'number | null' is not assignable to type 'number'.
            rating: values.rating,
            companyId: currentCompanyId,
            // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
            queryId,
          }, {
            onSuccess: callAll(
              () => toaster.success(t('results.toaster.submitFeedbackSuccess')),
              popover.close,
            ),
            onError: () => toaster.error(t('error.somethingWentWrong')),
          });
        }}
      >
        {({ values, isSubmitting, isValid, setFieldValue }) => (
          <Form>
            <Panel>
              <PanelHeader heading={t('feedbackModal.header')} />
              <PanelDivider />
              <PanelPadding>
                <Stack gap={3}>
                  <MessageBlock variant="info" mt={0}>
                    {t('feedbackModal.infoMessage')}
                  </MessageBlock>
                  <LabelConfigProvider
                    variant={LabelConfig.LEFT}
                    width="auto"
                  >
                    <FieldContainer label={t('feedbackModal.yourRating')}>
                      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                      <Text
                        as="span"
                        ml={3}
                        sx={{
                          cursor: 'pointer',
                          opacity: values.rating === null || values.rating === 1 ? 1 : 0.5,
                        }}
                        role="img"
                        aria-label={t('results.smileEmoji')}
                        onClick={() => values.rating === 1 ? setFieldValue('rating', null) : setFieldValue('rating', 1)}
                      >
                        😀
                      </Text>
                      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                      <Text
                        as="span"
                        ml={3}
                        sx={{
                          cursor: 'pointer',
                          opacity: values.rating === null || values.rating === 0 ? 1 : 0.5,
                        }}
                        role="img"
                        aria-label={t('results.straightFaceEmoji')}
                        onClick={() => values.rating === 0 ? setFieldValue('rating', null) : setFieldValue('rating', 0)}
                      >
                        😐
                      </Text>
                      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                      <Text
                        as="span"
                        ml={3}
                        sx={{
                          cursor: 'pointer',
                          opacity: values.rating === null || values.rating === -1 ? 1 : 0.5,
                        }}
                        role="img"
                        aria-label={t('results.disappointedEmoji')}
                        onClick={() => values.rating === -1 ? setFieldValue('rating', null) : setFieldValue('rating', -1)}
                      >
                        😞
                      </Text>
                    </FieldContainer>
                  </LabelConfigProvider>
                  <TextField
                    label={t('feedbackModal.feedback')}
                    name="feedback"
                    isMultiLine
                    rows={4}
                  />
                </Stack>
              </PanelPadding>
              <PanelDivider />
              <PanelPadding>
                <Flex justifyContent="flex-end">
                  <CancelButton mr={2} onClick={popover.close} />
                  <SaveButton disabled={isSubmitting || !isValid}>
                    {t('submit', { ns: 'general' })}
                  </SaveButton>
                </Flex>
              </PanelPadding>
            </Panel>
          </Form>
        )}
      </Formik>
    </Popover>
  );
};
