import { DateFormat, localeFormatDate } from '@deepstream/utils';
import { useCurrentUserLocale } from './useCurrentUser';

export const useDatetime = (value: string | Date) => {
  const locale = useCurrentUserLocale();

  return value
    ? localeFormatDate(new Date(value), DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ, { locale })
    : null;
};
