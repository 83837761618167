import { useCallback } from 'react';
import { Flex, Box, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { includes, filter, map } from 'lodash';
import { QuestionExchangeDefinition } from '@deepstream/common/rfq-utils';
import { Illustration } from '@deepstream/ui-kit';

import { Modal, ModalHeader, ModalBody, ModalFooter, CancelButton, SaveButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { CategoriesList } from './CategoriesList';
import { QuestionsPanel } from './QuestionsPanel';
import { useImportQuestionsContext } from '../ImportQuestionsProvider';

export const ImportQuestionsModal = ({
  close,
  onAddQuestions,
}: {
  onAddQuestions: (questions: QuestionExchangeDefinition[]) => void;
  close: () => void;
}) => {
  const { t } = useTranslation('preQualification');
  const {
    selectedQuestionExchangeDefIds,
    questions,
    allQuestionsCount,
    activeCategoryId,
  } = useImportQuestionsContext();

  const noQuestionsAdded = questions.length === 0;

  const handleOnSave = useCallback(() => {
    const questionsToSave = filter(questions, (question) => {
      return includes(selectedQuestionExchangeDefIds, question.currentVersion.exchangeDef._id);
    });

    const exchangeDefsToSave = map(questionsToSave, (question) => {
      return question.currentVersion.exchangeDef;
    });

    close();
    return onAddQuestions(exchangeDefsToSave);
  }, [selectedQuestionExchangeDefIds, questions, onAddQuestions, close]);

  return (
    <Modal isOpen>
      <ModalHeader>{t('importQuestions.modal.heading')}</ModalHeader>
      <ModalBody sx={{ width: '900px' }} p={0}>
        {noQuestionsAdded ? (
          <Box sx={{ height: '600px' }}>
            <Illustration
              variant="empty-state"
              label={t('importQuestions.modal.noQuestionsAvailable')}
              labelSx={{
                textTransform: 'uppercase',
                fontSize: 1,
                fontWeight: 500,
              }}
            />
          </Box>
        ) : (
          <Flex sx={{ alignItems: 'stretch' }}>
            <Box sx={{ flex: '0 0 30%', borderRight: 'lightGray2' }}>
              <CategoriesList />
            </Box>
            <Box sx={{ flex: '0 0 70%' }}>
              <QuestionsPanel key={activeCategoryId} />
            </Box>
          </Flex>
        )}
      </ModalBody>
      <ModalFooter>
        {!noQuestionsAdded && (
          <Text fontSize={1} color="subtext">
            {t('importQuestions.modal.totalItemsSelected', {
              count: selectedQuestionExchangeDefIds.length,
              total: allQuestionsCount,
            })}
          </Text>
        )}
        <Box sx={{ ml: 'auto' }}>
          <CancelButton onClick={close} />
          <SaveButton
            type="button"
            label={t('importQuestions.modal.submitLabel')}
            disabled={!selectedQuestionExchangeDefIds.length}
            onClick={handleOnSave}
          />
        </Box>
      </ModalFooter>
    </Modal>
  );
};
