import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGridMenuState } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { getCellValue } from '@deepstream/ui-kit/grid/EditableGrid/getCellValue';
import { OverflowTooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { ACTION_COLUMN_WIDTH, DEFAULT_ROW_HEIGHT } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { DefaultEditableGridStyles } from '@deepstream/ui-kit/grid/EditableGrid/EditableGridStyles';
import { EditableGridDataProvider } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { EditableGrid } from '@deepstream/ui-kit/grid/EditableGrid/EditableGrid';
import { BORDER_ADJUSTMENT, DEFAULT_FROZEN_HEADER_HEIGHT } from '@deepstream/ui-kit/grid/core/constants';
import { getScrollbarSize } from '@deepstream/ui-utils/getScrollbarSize';
import { NavigableRange } from '@deepstream/ui-kit/grid/core/utils';
import { SimpleRowNumberCell } from '../../ui/ExchangeDefsGrid/nonValidationAwareValueCell';
import { SimpleHeader } from '../../ui/ExchangeDefsGrid/header';
import { useUserFlags } from '../../UserFlagsContext';
import { useGetSupplierResponseCellValue } from '../../draft/cell';
import { PreQualQuestion } from '../../types';

const frozenLeftColumnIds = ['rowNumber', 'description'];

const navigableRange: NavigableRange = {
  startColumnIndex: 0,
  startRowIndex: 1,
  endColumnIndex: 1,
};

const QuestionDescriptionValueCell = ({
  row,
  column,
}) => {
  const value = getCellValue(row.original, column.original);

  return (
    <div className="cell-content" style={{ cursor: 'default' }}>
      <OverflowTooltip content={value}>
        <Truncate>
          {value}
        </Truncate>
      </OverflowTooltip>
    </div>
  );
};

const QuestionTypeValueCell = ({
  row,
  column,
}) => {
  const { t } = useTranslation();

  const value = getCellValue(row.original, column.original);

  return (
    <div className="cell-content" style={{ cursor: 'default' }}>
      <Truncate>
        {t(`request.question.questionType.${value}`)}
      </Truncate>
    </div>
  );
};

const QuestionSupplierResponseValueCell = ({
  row,
}) => {
  const getSupplierResponseCellValue = useGetSupplierResponseCellValue();

  const value = getSupplierResponseCellValue(row.original.currentVersion.exchangeDef);

  return (
    <div className="cell-content" style={{ cursor: 'default' }}>
      <Truncate>
        {value}
      </Truncate>
    </div>
  );
};

export const PreQualQuestionsGrid = ({ questions }: { questions: PreQualQuestion[] }) => {
  const { t } = useTranslation(['translation', 'preQualification']);
  const { hasManagePreQualPermission } = useUserFlags();

  const { toggleMenu } = useGridMenuState();

  const columns = useMemo(
    () => [
      {
        _id: 'rowNumber',
        accessorKey: 'rowNumber',
        Header: SimpleHeader,
        label: '',
        ValueCell: SimpleRowNumberCell,
        toggleMenu: hasManagePreQualPermission ? toggleMenu : undefined,
        width: hasManagePreQualPermission ? ACTION_COLUMN_WIDTH * 2 : ACTION_COLUMN_WIDTH,
        fixedWidth: true,
      },
      {
        _id: 'description',
        accessorKey: 'currentVersion.exchangeDef.description',
        Header: SimpleHeader,
        label: t('request.question.question', { count: 1 }),
        description: t('buyer', { ns: 'general' }),
        ValueCell: QuestionDescriptionValueCell,
        showIconWhenObsolete: true,
        width: 350,
      },
      {
        _id: 'options',
        accessorKey: 'options',
        Header: SimpleHeader,
        label: t('request.question.response', { count: 1 }),
        description: t('supplier', { count: 1, ns: 'general' }),
        ValueCell: QuestionSupplierResponseValueCell,
        width: 330,
      },
      {
        _id: 'questionType',
        accessorKey: 'currentVersion.exchangeDef.questionType',
        Header: SimpleHeader,
        label: t('general.type'),
        ValueCell: QuestionTypeValueCell,
        width: 170,
      },
    ],
    [hasManagePreQualPermission, t, toggleMenu],
  );

  const maxGridHeight = (
    DEFAULT_FROZEN_HEADER_HEIGHT +
    BORDER_ADJUSTMENT +
    DEFAULT_ROW_HEIGHT * (questions?.length || 0) +
    getScrollbarSize()
  );

  return (
    <DefaultEditableGridStyles
      style={{ width: '100%', height: `min(100vh - 300px, ${maxGridHeight}px)` }}
    >
      <EditableGridDataProvider
        rowData={[]}
      >
        <EditableGrid
          frozenLeftColumnIds={frozenLeftColumnIds}
          columns={columns}
          rowData={questions as any}
          onGridClipboardEvent={() => {}}
          navigableRange={navigableRange}
        />
      </EditableGridDataProvider>
    </DefaultEditableGridStyles>
  );
};
