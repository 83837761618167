import styled from 'styled-components';
import { Text, Flex, Box } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { useBidProgress, useContractData } from './contract';
import { BidProgressDetails } from '../../BidProgressDetails';

const Heading = styled(Text)`
  font-size: ${props => props.theme.fontSizes[4]}px;
  font-weight: 500;
  line-height: normal;
`;

const SubText = styled(Text)`
  color: ${props => props.theme.colors.subtext};
  font-size: ${props => props.theme.fontSizes[2]}px;
`;

export const RequirementsProgressPanel = () => {
  const { t } = useTranslation(['contracts', 'general']);
  const contract = useContractData();
  const progress = useBidProgress();

  return (
    <Panel mb="20px" p="20px">
      <Flex
        alignItems={['flex-start', null, 'center']}
        flexDirection={['column', null, 'row']}
      >
        <Box flex="1 1 auto" mb={[3, null, 0]} mr={[0, null, '30px']}>
          <Flex alignItems="center" mb={1}>
            <Icon
              icon={contract.recipientRequirementsResolved ? 'check-circle' : 'exclamation-triangle'}
              color={contract.recipientRequirementsResolved ? 'success' : 'warning'}
              fontSize={3}
              mr={1}
            />
            <Heading>
              {contract.recipientRequirementsResolved ? (
                t('details.progress.complete')
              ) : (
                t('details.progress.incomplete')
              )}
            </Heading>
          </Flex>
          <SubText>
            {contract.recipientRequirementsResolved ? (
              t('details.progress.completeDescription')
            ) : (
              t('details.progress.incompleteDescription')
            )}
          </SubText>
        </Box>
        {progress && (
          <BidProgressDetails progress={progress} />
        )}
      </Flex>
    </Panel>
  );
};
