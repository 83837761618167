import { Company, Live } from '@deepstream/common/rfq-utils';
import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { SuppliersGridConfig } from './useSuppliersGridConfig';
import { ColumnId, useAvailableFilterIds, viewConfigById } from './requestSentSuppliersViews';
import * as rfx from '../../../rfx';
import { GridFilterConfig } from '../../../ui/ExchangeDefsGrid/GridFilterDropdown';

export const useSuppliersGridFilters = (
  suppliersGridConfig: SuppliersGridConfig,
  recipients: Company[],
  columnConfigById: Record<ColumnId, GridFilterConfig<Company> | null>,
  hasExternalSupplierStatuses: boolean,
) => {
  const structure = rfx.useStructure<Live>();
  const filterIds = useAvailableFilterIds(hasExternalSupplierStatuses);

  const gridFiltersBase = useMemo(() => {
    const gridFilters = filterIds.flatMap(filterId => {
      const gridFilterConfig = columnConfigById[filterId];

      if (!gridFilterConfig) {
        return [];
      }

      const groupIds = ['lotIntentionStatus', 'nonLotViewLotIntentionStatus'].includes(filterId)
        ? structure.lots.filter(lot => !lot.isObsolete).map(lot => lot._id)
        : filterId === 'stageNCompletion'
          ? structure.stages.map(stage => stage._id)
          : [null];

      return groupIds.map(groupId => {
        const selectableItems = gridFilterConfig.getFilterItems(recipients, groupId);

        const pageId = groupId ? `${filterId}:${groupId}` : filterId;

        return {
          columnId: filterId,
          groupId,
          pageId,

          config: gridFilterConfig,
          items: selectableItems,
        };
      });
    });

    return gridFilters.filter(gridFilter => !isEmpty(gridFilter.items));
  }, [columnConfigById, recipients, structure.lots, structure.stages, filterIds]);

  return useMemo(() => {
    const selectedViewId = suppliersGridConfig.view.selectedItems[0]!.value;

    const { excludedFilterIds } = viewConfigById[selectedViewId];

    return gridFiltersBase
      .filter(({ columnId }) => !excludedFilterIds.includes(columnId))
      .map(gridFilter => ({
        ...gridFilter,
        filters: suppliersGridConfig.filters,
      }));
  }, [suppliersGridConfig.view.selectedItems, suppliersGridConfig.filters, gridFiltersBase]);
};
