import { forwardRef, ForwardedRef } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionExchangeDefinition } from '@deepstream/common/rfq-utils';
import { Flex, Box } from 'rebass/styled-components';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { DraftQuestionActions } from './DraftQuestionActions';
import { QuestionFieldPreview } from '../QuestionPreview/QuestionFieldPreview';
import { QuestionTypeSelector } from '../../../draft/QuestionFields';
import { useDeviceSize } from '../../../ui/useDeviceSize';
import { FieldContainer } from '../../../form/FieldContainer';
import { DisabledInputBox } from '../../../ui/Input';

export const DraftEditQuestion = forwardRef(({
  index,
  exchangeDef,
  scrollToQuestion,
}: {
  index: number;
  exchangeDef: QuestionExchangeDefinition;
  scrollToQuestion: (index: number) => void;
}, ref: ForwardedRef<HTMLDivElement>) => {
  const { t } = useTranslation(['preQualification', 'general']);
  const { isExtraSmall, isSmall } = useDeviceSize();

  return (
    <Panel ref={ref} padded bg="lightGray3">
      <Stack gap={3}>
        <Flex alignItems="flex-end">
          <Box mr={2}>
            <FieldContainer label={t('number', { ns: 'general' })}>
              <DisabledInputBox>{index + 1}</DisabledInputBox>
            </FieldContainer>
          </Box>
          <Box mr={2} flex={2}>
            <FieldContainer label={t('question', { count: 1 })} showAsterisk>
              <DisabledInputBox>
                {exchangeDef.description}
              </DisabledInputBox>
            </FieldContainer>
          </Box>
          <Box
            mr={2}
            flex={1}
            minWidth={isSmall || isExtraSmall ? '20%' : '160px'}
            maxWidth="300px"
          >
            <QuestionTypeSelector disabled required value={exchangeDef.questionType} />
          </Box>
          <Box flex="0 0 auto">
            <DraftQuestionActions index={index} scrollToQuestion={scrollToQuestion} />
          </Box>
        </Flex>
        <QuestionFieldPreview exchangeDef={exchangeDef} />
      </Stack>
    </Panel>
  );
});
