import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Text } from 'rebass/styled-components';
import { QuestionnaireOverview, QuestionnaireStatus, questionnaireStatusConfig } from '@deepstream/common/preQual';
import { Pictogram } from '@deepstream/ui-kit';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { DateFormat } from '@deepstream/utils';
import { TruncateCell } from '../../../TruncateCell';
import { Bold } from '../../../Bold';
import { BasicTableStyles } from '../../../TableStyles';
import { Table } from '../../../Table';
import { QuestionnaireStatusCell } from '../../PreQualification/Questionnaire/QuestionnaireStatus';
import { CompletionCell } from '../../../CompletionCell';
import { QuestionnaireVersionCell } from '../../PreQualification/Questionnaire/QuestionnaireVersionCell';
import { QuestionnaireRenewalDateCell } from '../../PreQualification/Questionnaire/QuestionnaireRenewalDateCell';
import { useNavigate } from '../../../tanstackRouter';
import { questionnaireQuestionsRoute } from '../../../AppRouting';
import { QuestionnaireExpiryDateCell } from '../../PreQualification/Questionnaire/QuestionnaireExpiryDateCell';

const displayedStatuses = [
  QuestionnaireStatus.SENT,
  QuestionnaireStatus.IN_PROGRESS,
  QuestionnaireStatus.PENDING_REVIEW,
  QuestionnaireStatus.APPROVED,
  QuestionnaireStatus.EXPIRED,
  QuestionnaireStatus.REJECTED,
];

export const CompanyProfilePreQualificationActivity = ({
  questionnaires,
  currentCompanyId,
}: {
  questionnaires: QuestionnaireOverview[],
  currentCompanyId: string,
}) => {
  const { t } = useTranslation(['preQualification', 'companyProfile']);
  const navigate = useNavigate();

  const totalItems = questionnaires.length;

  const statusCountMap = useMemo(
    () => questionnaires
      .reduce((acc, { status }) => {
        acc[status] = (acc[status] || 0) + 1;
        return acc;
      }, {}),
    [questionnaires],
  );

  const stats = useMemo(
    () => displayedStatuses
      .map(
        status => ({
          icon: questionnaireStatusConfig[status].icon,
          name: t(`questionnaireStatus.${status}`),
          value: statusCountMap[status],
        }),
      ),
    [statusCountMap, t],
  );

  const columns = useMemo(
    () => [
      {
        id: 'name',
        Header: t('activity.name', { ns: 'companyProfile' }),
        accessor: 'name',
        Cell: TruncateCell,
      },
      {
        id: 'status',
        Header: t('activity.status', { ns: 'companyProfile' }),
        accessor: 'status',
        Cell: QuestionnaireStatusCell,
      },
      {
        id: 'completion',
        Header: t('activity.completion', { ns: 'companyProfile' }),
        accessor: 'completion',
        Cell: CompletionCell,
      },
      {
        id: 'renewalDate',
        Header: t('questionnaireSuppliers.renewalDate'),
        accessor: 'renewalDate',
        Cell: QuestionnaireRenewalDateCell,
        format: DateFormat.DD_MMM_YYYY,
        width: '150px',
      },
      {
        id: 'expiryDate',
        Header: t('questionnaireSuppliers.expiryDate'),
        accessor: 'expiryDate',
        Cell: QuestionnaireExpiryDateCell,
        format: DateFormat.DD_MMM_YYYY,
        width: '150px',
      },
      {
        id: 'version',
        Header: t('activity.version', { ns: 'companyProfile' }),
        accessor: 'meta.fromTemplateVersion',
        Cell: QuestionnaireVersionCell,
        width: '120px',
      },
    ],
    [t],
  );

  return (
    <Panel>
      <PanelHeader
        heading={
          <Flex alignItems="center">
            <Text>{t('activity.preQualification', { ns: 'companyProfile' })}</Text>
            <Tooltip content={t('activity.tooltip.preQualification', { ns: 'companyProfile' })}>
              <Icon icon="info-circle" mx={2} fontSize={2} />
            </Tooltip>
          </Flex>
        }
      >
        <Flex sx={{ columnGap: '24px' }}>
          {stats.map(stat => (
            <Box key={stat.name} sx={{ textAlign: 'center' }}>
              <Text fontSize={1} fontWeight={500} mb={1}>
                {stat.name}
              </Text>
              <Flex alignItems="center" fontSize={2}>
                <Icon fixedWidth icon={stat.icon.value} color={stat.icon.color} mr={1} />
                <Bold>
                  {stat.value ?? 0}
                </Bold>
              </Flex>
            </Box>
            ))}
        </Flex>
      </PanelHeader>
      <PanelDivider />
      {totalItems > 0 ? (
        <BasicTableStyles>
          <Table
            columns={columns}
            data={questionnaires}
            isPaginated
            enforcePagination
            isSortable
            smallPageControls
            onRowClick={questionnaire => {
              navigate({
                to: questionnaireQuestionsRoute.to,
                params: {
                  currentCompanyId,
                  questionnaireId: questionnaire._id,
                },
              });
            }}
          />
        </BasicTableStyles>
      ) : (
        <Flex height="350px">
          <PanelPadding>
            <Pictogram
              variant="empty-state"
              label={t('activity.noSentQuestionnaires', { ns: 'companyProfile' })}
              labelPosition="right"
            />
          </PanelPadding>
        </Flex>
      )}
    </Panel>
  );
};
