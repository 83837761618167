import { useCompanyFeatureFlags } from '../../companyFeatureFlags';
import { useSystemFeatureFlags } from '../../systemFeatureFlags';

export const useIsESignatureEnabled = () => {
  const systemFeatureFlags = useSystemFeatureFlags();
  const companyFeatureFlags = useCompanyFeatureFlags();

  // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'.
  return systemFeatureFlags.contractESignatureEnabled && companyFeatureFlags.contractESignatureEnabled;
};
