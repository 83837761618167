import { useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';
import { omitNil } from '@deepstream/utils';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { useModalState } from '../../ui/useModalState';
import { RequestTag } from '../../ui/types';
import RequestTagModal from './RequestTagModal';
import RemoveTagModal from './RemoveTagModal';
import { useNavigate } from '../../tanstackRouter';
import { RequestsTab } from '../../requestsConstants';

const RequestTagDropdown = ({ tag }: { tag: RequestTag }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const removeTagModal = useModalState();
  const editTagModal = useModalState();

  const handleRemoveDone = () => {
    navigate({
      search: search => omitNil({ ...search, tab: RequestsTab.sent }),
      replace: true,
    });

    removeTagModal.close();
  };

  return (
    <Box flexShrink={0}>
      <DropdownMenu
        rightAligned
        variant="secondary"
        buttonText={t('requests.tags.tagActions')}
        iconRight="caret-down"
        menuZIndex={10}
      >
        <DropdownMenuItem
          icon="pencil"
          onSelect={() => editTagModal.open()}
        >
          {t('requests.tags.editTagEdit')}
        </DropdownMenuItem>
        <DropdownMenuItem icon="trash" color="danger" onSelect={() => removeTagModal.open()}>
          {t('requests.tags.editTagRemove')}
        </DropdownMenuItem>
      </DropdownMenu>
      <RequestTagModal
        tag={tag}
        isOpen={editTagModal.isOpen}
        onClose={editTagModal.close}
      />
      <RemoveTagModal
        tag={tag}
        isOpen={removeTagModal.isOpen}
        onCancel={removeTagModal.close}
        onDone={handleRemoveDone}
      />
    </Box>
  );
};

export default RequestTagDropdown;
