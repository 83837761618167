import * as React from 'react';
import { groupBy, first, keyBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import { RfxVesselPricingSection, Vessel } from '@deepstream/common/rfq-utils';
import { ObsoleteIcon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { groupBy2d } from '@deepstream/utils/groupBy2d';
import { PanelSubHeader, PanelDivider, Panel, PanelHeader } from '@deepstream/ui-kit/elements/Panel';
import { useRecipientId } from './useRfq';
import * as rfx from './rfx';
import { CurrencyCodeProvider } from './ui/Currency';
import { ExchangeTableBase } from './ExchangeTable';
import { useExchangeColumns } from './modules/Exchange/columns';
import { EditHirePeriodRatesModal, ViewHirePeriodRatesModal } from './EditHirePeriodRatesModal';
import { useModalState } from './ui/useModalState';
import { useCurrentCompanyId } from './currentCompanyId';
import { ExchangeSnapshot } from './types';
import { Switch } from './ui/Switch';
import { useHaveDeadlinesPassed } from './deadline';
import { BidCommencementWindowTable } from './draft/CommencementWindowTable';
import { ExchangeProvider } from './useExchange';

const HirePeriodHeading = ({ hirePeriod }) => {
  const { t } = useTranslation();

  return (
    <>
      {hirePeriod.isObsolete ? (
        <Tooltip content={t('general.obsolete') as string}>
          <ObsoleteIcon mr={2} />
        </Tooltip>
      ) : null}
      {hirePeriod.name}
    </>
  );
};

const HirePeriod = ({ hirePeriod, vessel, intervals, openExchange }) => {
  const { t } = useTranslation(['translation', 'general']);
  const [showContent, setShowContent] = React.useState(!hirePeriod.isObsolete);

  const exchangeColumns = useExchangeColumns();
  const hirePeriodIntervalColumns = React.useMemo(
    () => exchangeColumns.hirePeriodIntervals(vessel._id),
    [vessel._id, exchangeColumns],
  );

  return (
    <Panel key={hirePeriod._id} sx={hirePeriod.isObsolete ? { opacity: 1 } : {}}>
      <PanelHeader heading={<HirePeriodHeading hirePeriod={hirePeriod} />} variant="subheader">
        {hirePeriod.isObsolete && (
          <Switch
            text={t('request.vesselPricing.hirePeriods.hideObsoleteData')}
            checked={!showContent}
            onChange={checked => setShowContent(!checked)}
          />
        )}
      </PanelHeader>
      {showContent && (
        <>
          <PanelDivider />
          <PanelSubHeader heading={t('request.vesselPricing.hirePeriods.commencementWindow')} />
          <PanelDivider />
          <BidCommencementWindowTable hirePeriod={hirePeriod} />
          <PanelDivider />
          <PanelSubHeader heading={t('request.vesselPricing.hirePeriods.dayRate_other')} />
          <PanelDivider />
          <ExchangeTableBase
            isPaginated={false}
            isSortable={false}
            columns={hirePeriodIntervalColumns}
            exchanges={intervals}
            onRowClick={openExchange}
            bodyCellVerticalAlign="top"
            fixedRowHeight={false}
          />
        </>
      )}
    </Panel>
  );
};

type HirePeriodBidSectionProps = {
  vessel: Vessel;
  exchanges: ExchangeSnapshot[];
};

export const HirePeriods: React.FC<HirePeriodBidSectionProps> = ({
  vessel,
  exchanges,
}) => {
  const section = rfx.useSection<RfxVesselPricingSection>();
  const structure = rfx.useStructure();
  const recipientId = useRecipientId();
  const isRecipient = useCurrentCompanyId() === recipientId;
  const editRatesBidModal = useModalState();
  const haveDeadlinesPassed = useHaveDeadlinesPassed();
  const pagePermissions = rfx.usePagePermissions();
  const [selectedExchangeId, setSelectedExchangeId] = React.useState<string | null>(null);
  const isBidding = rfx.useIsBidding();

  const openExchange = React.useCallback(
    exchange => {
      setSelectedExchangeId(exchange._id);
      editRatesBidModal.open();
    },
    [editRatesBidModal],
  );

  const {
    exchangeById,
    intervalsByHirePeriodId,
    currencyCode,
  } = React.useMemo(() => {
    const exchangesInitiatedBy = groupBy2d(
      exchanges,
      exchange => {
        const exchangeDef = structure.exchangeDefById[exchange._id];
        // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
        return exchange.companies[exchangeDef.creatorId].group!;
      },
      exchange => exchange.def.type,
    );

    return {
      exchangeById: keyBy(exchanges, exchange => exchange._id),
      intervalsByHirePeriodId: groupBy(exchangesInitiatedBy.sender.hirePeriod, 'def.hirePeriodId'),
      currencyCode: first(exchangesInitiatedBy.sender?.currency as any[])?.latestCurrency ?? '',
    };
  }, [exchanges, structure.exchangeDefById]);

  // @ts-expect-error ts(2538) FIXME: Type 'null' cannot be used as an index type.
  const selectedExchange = exchangeById[selectedExchangeId];

  return (
    <CurrencyCodeProvider code={currencyCode}>
      {section.hirePeriodIds.map(hirePeriodId => (
        <HirePeriod
          key={hirePeriodId}
          vessel={vessel}
          hirePeriod={structure.hirePeriodById[hirePeriodId]}
          intervals={intervalsByHirePeriodId[hirePeriodId] ?? []}
          openExchange={openExchange}
        />
      ))}
      {selectedExchange && (
        <ExchangeProvider exchange={selectedExchange}>
          {(
            !isRecipient ||
            !pagePermissions.canEdit ||
            selectedExchange.isObsolete ||
            (haveDeadlinesPassed && !selectedExchange.extendedDeadline) ||
            selectedExchange.disabledReason ||
            !isBidding
          ) ? (
            <ViewHirePeriodRatesModal
              vesselId={vessel._id}
              hirePeriodExchange={selectedExchange}
              isOpen={editRatesBidModal.isOpen}
              onClose={editRatesBidModal.close}
              isRecipient={isRecipient}
            />
          ) : (
            <EditHirePeriodRatesModal
              vesselId={vessel._id}
              hirePeriodExchange={selectedExchange}
              isOpen={editRatesBidModal.isOpen}
              onCancel={editRatesBidModal.close}
              onSuccess={editRatesBidModal.close}
            />
          )}
        </ExchangeProvider>
      )}
    </CurrencyCodeProvider>
  );
};
