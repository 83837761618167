import * as React from 'react';
import { useField } from 'formik';
import { TextboxQuestionElement } from '@deepstream/common/legacy-pre-q-utils';
import { Flex, Box } from 'rebass/styled-components';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { NumberFormat } from '../NumberFormat';
import { Input, InputProps, TextArea, InputPrefix, InputSuffix } from '../ui/Input';
import { ElementProps, Modify, ContainerLabel, LockedAnswer, NoAnswer } from './common';
import { FormGroup } from '../ui/FormGroup';
import { ErrorMessage, HelperText } from '../form/Field';
import { formatOptions } from '../ui/formatOptions';

const formatOptionKeys = Object.keys(formatOptions);
const isFormatOption = (string): string is keyof typeof formatOptions =>
  formatOptionKeys.includes(string);

type TextboxElementProps =
  Modify<ElementProps, { element: TextboxQuestionElement }> &
  {
    prefix?: string | React.ReactNode;
    suffix?: string | React.ReactNode;
    format?: InputProps['format'];
    placeholder?: string;
    EmptyAnswerComponent?: any;
    AnswerComponent?: any;
    inputStyle?: any;
    hideError?: boolean;
    showAsterisk?: boolean;
  };

export const TextboxElement: React.FC<TextboxElementProps> = ({
  name,
  element,
  answer,
  hideLabel,
  isReadOnly,
  isLocked,
  disabled,
  prefix,
  suffix,
  hideError,
  showAsterisk,
  format: formatProp,
  placeholder,
  AnswerComponent,
  EmptyAnswerComponent = NoAnswer,
  inputStyle = {},
}) => {
  const id = useUniqueId();
  const [field, meta, formik] = useField({ name }); // eslint-disable-line no-unused-vars
  const { t } = useTranslation(['companyProfile', 'translation']);

  const numCharactersRemaining = element.validation?.maxLength
    ? element.validation.maxLength - field.value.length
    : null;

  // The textbox mask format can either come from the `formatProp` or the
  // `element.validation.format` but if both are provided the `formatProp`
  // is chosen.
  const format = (
    formatProp ||
    (isFormatOption(element?.validation?.format) ? element?.validation?.format : undefined)
  );

  return (
    <ContainerLabel
      name={name}
      htmlFor={id}
      label={element.label || (element.labelId ? t(`element.label.${element.labelId}`) : '')}
      hideLabel={hideLabel}
      showAsterisk={showAsterisk ?? element.validation?.required}
    >
      {isReadOnly ? (
        answer ? (
          isLocked ? (
            <LockedAnswer />
          ) : format ? (
            <NumberFormat
              {...formatOptions[format as keyof typeof formatOptions]}
              displayType="text"
              value={answer}
            />
          ) : AnswerComponent ? (
            <AnswerComponent answer={answer} />
          ) : (
            <>{answer}</>
          )
        ) : (
          <EmptyAnswerComponent />
        )
      ) : (
        <>
          {element.isMultiline ? (
            <TextArea
              id={id}
              name={name}
              value={field.value || ''}
              rows={element.rows || 4}
              onChange={field.onChange}
              onBlur={field.onBlur}
              disabled={disabled}
              placeholder={placeholder}
            />
          ) : (
            <FormGroup>
              {prefix && <InputPrefix>{prefix}</InputPrefix>}
              <Input
                id={id}
                type={element.inputType || 'text'}
                format={format}
                name={name}
                value={isNil(field.value) ? '' : field.value}
                onValueChange={format ? (values) => formik.setValue(values) : undefined}
                onChange={format ? undefined : field.onChange}
                onBlur={field.onBlur}
                disabled={disabled}
                style={{ textAlign: 'inherit', ...inputStyle }}
                maxLength={element.validation?.maxLength}
                min={typeof element.validation?.min === 'number' ? element.validation?.min : undefined}
                max={typeof element.validation?.max === 'number' ? element.validation?.max : undefined}
              />
              {suffix && <InputSuffix>{suffix}</InputSuffix>}
            </FormGroup>
          )}
          {meta.error && meta.touched && !hideError ? (
            <Box sx={{ wordBreak: 'break-all', textAlign: 'left' }}>
              <ErrorMessage error={meta.error} fontWeight="normal" />
            </Box>
          ) : element.helperText || element.validation?.maxLength ? (
            <Flex justifyContent="space-between">
              {element.helperText && (
                <HelperText
                  text={element.helperText}
                  mr={element.validation?.maxLength ? 2 : 0}
                />
              )}
              {element.validation?.maxLength && (
                <HelperText
                  text={t('form.charactersRemaining', { numChars: numCharactersRemaining, ns: 'translation' })}
                  sx={{ whiteSpace: 'nowrap' }}
                />
              )}
            </Flex>
          ) : (
            null
          )}
        </>
      )}
    </ContainerLabel>
  );
};
