import { ActionType, ExchangeDefinition, ExchangeStatus, ExchangeType, getScore, PagePermissions, User } from '@deepstream/common/rfq-utils';
import { isNil } from 'lodash';
import { documentExchangeTypes } from '@deepstream/common/exchangesConfig';
import { ContractStatus } from '@deepstream/common/contract';
import { CurrentUser, EvaluationExchangeSnapshot, ExchangeSnapshot } from './types';
import { canESignContract } from './modules/Contracts/contract';

export const getExchangeSnapshotStatus = (
  {
    status,
    currentCompanyId,
    turn,
    recipientId,
    isPreviousStage,
  }: ExchangeSnapshot & { isPreviousStage?: boolean },
  pagePermissions?: PagePermissions | null,
  shouldReply = true,
) => {
  if (status === ExchangeStatus.INCOMPLETE) {
    if (isPreviousStage) {
      return ExchangeStatus.PREVIOUS_STAGE_INCOMPLETE;
    }

    const isRecipient = currentCompanyId === recipientId;
    const isMyTurn = turn ? turn.includes(currentCompanyId) : isRecipient;

    if (isMyTurn && shouldReply) {
      if (pagePermissions?.canRespond) {
        return ExchangeStatus.ACTION_REQUIRED;
      } else {
        return ExchangeStatus.WAITING_FOR_TEAM;
      }
    } else if (isRecipient) {
      return ExchangeStatus.WAITING_FOR_BUYER;
    } else {
      return ExchangeStatus.WAITING_FOR_SUPPLIER;
    }
  } else {
    return status;
  }
};

const canApproveContract = (exchange: ExchangeSnapshot, isSuperUserOrOwner: boolean) => {
  return (
    exchange.def.type === ExchangeType.CONTRACT &&
    exchange.actions.some(action => action.type === ActionType.APPROVE) &&
    isSuperUserOrOwner
  );
};

export const getContractExchangeSnapshotStatus = (
  exchange: ExchangeSnapshot,
  currentCompanyId: string,
  exchangeDefById: Record<string, ExchangeDefinition>,
  currentUserId: string,
  contractStatus: ContractStatus,
  isSuperUserOrOwner: boolean,
) => {
  const {
    companies,
    status,
    turn,
    numTurns = 0,
    def,
    isAwaitingEnvelopePublishing,
    isAwaitingSubmitterESignature,
    isAwaitingCounterESignature,
    isAwaitingApproval,
    isCountersigningDisabled,
  } = exchange;

  if (status === 'incomplete') {
    const isRecipient = companies[currentCompanyId].group === 'recipient';
    const isSender = companies[currentCompanyId].group === 'sender';
    const exchangeDef = exchangeDefById[def._id];
    const isSupplierAddedDocumentExchange = documentExchangeTypes.includes(exchangeDef.type) &&
      // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
      companies[exchangeDef.creatorId].group === 'recipient';
    const isMyTurn = turn
      ? turn.includes(currentCompanyId)
      : isSupplierAddedDocumentExchange
        ? isSender
        : isRecipient;
    const isFirstTurn = !numTurns;
    const canRespond = isAwaitingSubmitterESignature || isAwaitingCounterESignature
      ? canESignContract(currentUserId, exchange, contractStatus)
      : isAwaitingApproval
        ? canApproveContract(exchange, isSuperUserOrOwner)
        : true;

    if (isAwaitingEnvelopePublishing && isRecipient) {
      return ExchangeStatus.SETTING_UP_E_SIGNATURE;
    } else if (isMyTurn) {
      if (isSender && isCountersigningDisabled) {
        return ExchangeStatus.LOCKED;
      } else if (canRespond) {
        return ExchangeStatus.ACTION_REQUIRED;
      } else {
        return ExchangeStatus.WAITING_FOR_TEAM;
      }
    } else if (isFirstTurn) {
      return ExchangeStatus.INCOMPLETE;
    } else {
      return ExchangeStatus.WAITING_FOR_COUNTER_PARTY;
    }
  } else {
    return status;
  }
};

export const getEvaluationExchangeSnapshotStatus = (
  { companyId, user }: { companyId: string, user: User | CurrentUser },
  isEvaluator: boolean,
  pageHasAnyEvaluators: boolean,
) =>
  (exchange: EvaluationExchangeSnapshot) => {
    if (
      exchange.status === ExchangeStatus.OBSOLETE
    ) {
      return exchange.status;
    } else if (!pageHasAnyEvaluators) {
      return ExchangeStatus.BLOCKED;
    } else if (
      exchange.status === ExchangeStatus.COMPLETE
    ) {
      return exchange.status;
    } else if (
      isEvaluator &&
      isNil(getScore(exchange, { companyId, userId: user._id }))
    ) {
      return ExchangeStatus.ACTION_REQUIRED;
    } else if (isEvaluator) {
      return ExchangeStatus.WAITING_FOR_TEAM;
    } else {
      return ExchangeStatus.INCOMPLETE;
    }
  };

export const getTeamMemberEvaluationExchangeSnapshotStatus = (
  { companyId, user }: { companyId: string, user: User | CurrentUser },
) =>
  (exchange: EvaluationExchangeSnapshot) => {
    if (
      exchange.status === ExchangeStatus.OBSOLETE
    ) {
      return exchange.status;
    } else if (
      isNil(getScore(exchange, { companyId, userId: user._id }))
    ) {
      return ExchangeStatus.ACTION_REQUIRED;
    } else {
      return ExchangeStatus.COMPLETE;
    }
  };
