import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { callAll } from '@deepstream/utils/callAll';
import { ModalForm } from '../../ModalForm';
import { RadioField } from '../../form/RadioField';
import { LabelConfigProvider } from '../../LabelConfigProvider';
import { useApi } from '../../api';
import { useMutation } from '../../useMutation';
import * as rfx from '../../rfx';
import { useDraftRfqStructureQueryKey, useRfqId } from '../../useRfq';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useToaster } from '../../toast';
import { useScoringTypeOptions } from './useScoringTypeOptions';
import { useWaitForRfqUnlock } from '../../useWaitForUnlock';

const labelStyles = {
  scoringType: {
    mb: '10px',
  },
};

export const EnableEvaluationModal = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const { t } = useTranslation('evaluation');
  const theme = useTheme();
  const api = useApi();
  const options = useScoringTypeOptions();
  const rfqId = useRfqId();
  const queryClient = useQueryClient();
  const { isTemplate, isRevising } = rfx.useState();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqQueryKey = useDraftRfqStructureQueryKey({ rfqId, currentCompanyId, isTemplate });
  const toaster = useToaster();
  const waitForRfqUnlock = useWaitForRfqUnlock();

  const [updateRequestSettings] = useMutation(
    (payload) => waitForRfqUnlock({
      isTemplate,
      command: () => api.updateRequestSettings(payload),
    }),
    {
      onSuccess: callAll(
        () => toaster.success(t('enableModal.toaster.success')),
        close,
      ),
      onError: () => toaster.error(t('enableModal.toaster.error')),
      onSettled: () => queryClient.invalidateQueries(rfqQueryKey),
    },
  );

  return (
    <LabelConfigProvider style={labelStyles}>
      <ModalForm
        heading={t('enableModal.title')}
        isOpen={isOpen}
        onCancel={close}
        onSubmit={async ({ scoringType }) => updateRequestSettings({
          rfqId,
          companyId: currentCompanyId,
          settings: {
            isEvaluationEnabled: true,
            scoringType,
          },
          isTemplate,
          isRevising,
        })}
        initialValues={{}}
        validationSchema={yup.mixed()}
        style={{ content: { width: '450px', minWidth: 'min(400px, 100vw - 32px)' } }}
      >
        <RadioField
          label={t('fields.scoringType.label')}
          required
          name="scoringType"
          options={options}
          gap="12px"
          labelStyle={{
            fontWeight: 'normal',
            color: theme.colors.text,
            whiteSpace: 'pre-wrap',
            lineHeight: '15px',
          }}
        />
      </ModalForm>
    </LabelConfigProvider>
  );
};
