import { useTranslation } from 'react-i18next';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Flex } from 'rebass';

export const SectionConfigHeading = ({ error }: { error?: string }) => {
  const { t } = useTranslation('translation');

  return (
    <Flex sx={{ columnGap: 3 }}>
      <IconText
        icon="cog"
        fontSize={1}
        text={t('request.sections.sectionConfiguration')}
      />
      {error && (
        <IconText
          fontSize={2}
          fontWeight={400}
          color="danger"
          icon="info-circle"
          text={error}
          sx={{
            textTransform: 'initial',
            letterSpacing: 'initial',
          }}
        />
      )}
    </Flex>
  );
};
