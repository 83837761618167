import styled from 'styled-components';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';

const SectionIconWrapper = styled.div`
  border: 1px solid ${props => props.theme.colors.lightGray2};
  border-radius: 4px;
  height: 24px;
  width: 24px;
  text-align: center;
  margin-left: 4px;
  margin-right: 6px;
`;

export const GridSectionIcon = ({ icon }: { icon: IconProps['icon'] }) => (
  <SectionIconWrapper>
    <Icon icon={icon} color="text" fixedWidth fontSize={2} sx={{ top: '2px' }} />
  </SectionIconWrapper>
);
