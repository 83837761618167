import { useCallback, useMemo } from 'react';
import { ExchangeType, PageType } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { filter, last, some, values } from 'lodash';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useContract } from './useContract';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { ErrorMessage } from '../../ui/ErrorMessage';
import { Loading } from '../../ui/Loading';
import {
  ContractProvider,
  ContractPageProvider,
  ContractSectionProvider,
  useGeneralPages,
  useContractState,
  useContractPage,
  useContractData,
  usePagePermissions,
} from './contract';
import { ContractDraftPageContent } from './ContractDraftPageContent';
import { ContractSectionPanel } from './ContractSectionPanel';
import { DraftContractFooter } from './DraftContractFooter';
import { NoEditPermissions } from './Permissions/NoEditPermissions';
import { NoPageAccess } from './Permissions/NoPageAccess';
import { useDraftContractNavigation } from '../../appNavigation';

const ContractFooter = () => {
  const { navigateToDetailsIndex, navigateToDetailsPage, navigateToTeam, navigateToReminders } = useDraftContractNavigation();
  // @ts-expect-error ts(2339) FIXME: Property 'isTemplate' does not exist on type 'ContractStateContextType | undefined'.
  const { isTemplate } = useContractState();
  const generalPages = useGeneralPages();

  const onBack = useCallback(
    () => {
      const lastPage = last(generalPages);

      if (!lastPage) {
        navigateToDetailsIndex();
      } else {
        navigateToDetailsPage(lastPage._id);
      }
    },
    [generalPages, navigateToDetailsIndex, navigateToDetailsPage],
  );

  return (
    <DraftContractFooter
      onBack={onBack}
      onContinue={isTemplate ? navigateToReminders : navigateToTeam}
    />
  );
};

const DraftContractPageContent = () => {
  const { t } = useTranslation('contracts');
  // @ts-expect-error ts(2339) FIXME: Property 'isRevising' does not exist on type 'ContractStateContextType | undefined'.
  const { isRevising, isAmending } = useContractState();
  const contractPage = useContractPage();
  const contract = useContractData();
  const { canRead, canEdit } = usePagePermissions();

  const contractSection = useMemo(
    () => contract.sectionById[contractPage.sections[0]],
    [contract, contractPage],
  );

  const hasAddedNewContract = useMemo(
    () => {
      const exchangeDefs = filter(
        values(contract.exchangeDefById),
        exchangeDef => exchangeDef.type === ExchangeType.CONTRACT,
      );

      return some(exchangeDefs, exchangeDef => !exchangeDef.isLive);
    },
    [contract],
  );

  if (!canRead) {
    return <NoPageAccess />;
  }

  return (
    <>
      <ContractDraftPageContent>
        <ContractSectionProvider section={contractSection}>
          <Stack gap="20px">
            {isRevising && (
              <MessageBlock variant="info" mt={0}>
                {t('revisionInfo')}
              </MessageBlock>
            )}
            {isAmending && !hasAddedNewContract && (
              <MessageBlock variant="info" mt={0}>
                {t('amendmentContractInfo')}
              </MessageBlock>
            )}
            {!canEdit && (
              <NoEditPermissions mb={3} />
            )}
            <ContractSectionPanel />
          </Stack>
        </ContractSectionProvider>
      </ContractDraftPageContent>
      <ContractFooter />
    </>
  );
};

export const ContractDraftContract = ({
  contractId,
}: {
  contractId: string;
}) => {
  const { t } = useTranslation(['contracts', 'translation']);
  const currentCompanyId = useCurrentCompanyId({ required: true });
  // @ts-expect-error ts(2339) FIXME: Property 'isTemplate' does not exist on type 'ContractStateContextType | undefined'.
  const { isTemplate } = useContractState();

  const { data: contract, isLoading, isError, isSuccess } = useContract({
    contractId,
    currentCompanyId,
    scope: 'draft',
    isTemplate,
  });

  const contractPage = useMemo(
    () => contract
      ? values(contract.pageById).find(page => page.type === PageType.CONTRACT)
      : null,
    [contract],
  );

  return (
    <>
      {isLoading ? (
        <PanelPadding>
          <Loading />
        </PanelPadding>
      ) : isError ? (
        <PanelPadding>
          <ErrorMessage error={t('errors.unexpected', { ns: 'translation' })} />
        </PanelPadding>
      ) : isSuccess && contract ? (
        <ContractProvider contract={contract}>
          {/*
           // @ts-expect-error ts(2322) FIXME: Type 'ContractPage | null | undefined' is not assignable to type 'Page'. */}
          <ContractPageProvider page={contractPage}>
            <DraftContractPageContent />
          </ContractPageProvider>
        </ContractProvider>
      ) : (
        null
      )}
    </>
  );
};
