import { useQuery } from 'react-query';
import { useApi, wrap } from './api';
import { StatsByRecipientId } from './types';

export const useStatsByRecipientId = ({ rfqId, currentCompanyId }) => {
  const api = useApi();

  return useQuery<StatsByRecipientId>(
    ['statsByRecipientId', { rfqId, currentCompanyId }],
    wrap(api.getStatsByRecipientId),
    {
      staleTime: 10 * 1000,
    },
  );
};
