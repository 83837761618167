import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { filter, findLast } from 'lodash';
import { Flex, Text } from 'rebass/styled-components';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { RfxOtherSection } from '@deepstream/common/rfq-utils';
import { callAll } from '@deepstream/utils/callAll';
import { withProps } from '@deepstream/ui-utils';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { useRfqExchanges, useRecipientId } from '../../useRfq';
import * as rfx from '../../rfx';
import { Loading } from '../../ui/Loading';
import { ErrorMessage } from '../../ui/ErrorMessage';
import { useModalState } from '../../ui/useModalState';
import { Disclosure2 } from '../../ui/Disclosure';
import { NewClarificationModal } from './NewClarificationModal';
import { useExchangeNavigation } from '../../useExchangeModalState';
import { Bold } from '../../Bold';
import { RequestMessagesGrid } from './RequestMessagesGrid';
import { ExchangeSnapshot } from '../../types';
import { SortableHeader } from '../../ui/ExchangeDefsGrid/header';
import { getExchangeSnapshotStatus } from '../../exchangeStatus';
import { TextOrDashCell } from '../../ui/ExchangeDefsGrid/nonValidationAwareValueCell';
import { ExchangeStatusValueCell } from '../Request/Live/ExchangeStatusValueCell';
import { NumCommentsValueCell } from '../Request/Live/NumCommentsValueCell';
import { useCurrentCompanyId } from '../../currentCompanyId';

export const ClarificationBidSection = () => {
  const { t } = useTranslation(['translation', 'general']);
  const recipientId = useRecipientId();
  const section = rfx.useSection<RfxOtherSection>();
  const newClarificationModal = useModalState();
  const isRecipient = useCurrentCompanyId() === recipientId;
  const { navigateToExchange, openExchange } = useExchangeNavigation();
  const pagePermissions = rfx.usePagePermissions();

  const { data: exchanges = [], isError, isLoading } = useRfqExchanges({
    recipientId,
    sectionIds: [section?._id],
    enabled: Boolean(section) && Boolean(recipientId),
  });

  const columns: EditableGridColumn<ExchangeSnapshot>[] = useMemo(
    () => ([
      {
        _id: 'name',
        accessorKey: 'def.name',
        Header: SortableHeader,
        label: t('subject', { ns: 'general' }),
        width: 335,
        ValueCell: withProps(TextOrDashCell, { truncate: true }),
      },
      {
        _id: 'latestMessage',
        accessorKey: 'latestMessage',
        accessorFn: (exchange: ExchangeSnapshot) =>
          findLast(exchange.history, (item) => item.comment)?.comment,
        Header: SortableHeader,
        label: t('latestMessage', { ns: 'general' }),
        width: 253,
        ValueCell: withProps(TextOrDashCell, { truncate: true }),
      },
      {
        _id: 'status',
        accessorKey: 'status',
        accessorFn: (exchange) => getExchangeSnapshotStatus(exchange, pagePermissions),
        Header: SortableHeader,
        label: t('general.status'),
        width: 166,
        ValueCell: withProps(ExchangeStatusValueCell, { truncate: true }),
      },
      {
        _id: 'comments',
        accessorKey: 'comments',
        accessorFn: (exchange: ExchangeSnapshot) => filter(exchange.history, 'comment').length,
        Header: SortableHeader,
        label: t('comment', { ns: 'general', count: 2 }),
        width: 141,
        ValueCell: NumCommentsValueCell,
      },
    ]),
    [pagePermissions, t],
  );

  return (
    <>
      <Panel>
        <PanelHeader heading={t('request.clarifications.clarification_other')} collapse={false} icon="question" >
          {pagePermissions.canEdit && (
            <Button small iconLeft="plus" onClick={newClarificationModal.open}>
              {t('request.clarifications.newClarification')}
            </Button>
          )}
        </PanelHeader>
        <PanelDivider />
        {isLoading ? (
          <PanelPadding>
            <Loading />
          </PanelPadding>
        ) : isError ? (
          <PanelPadding>
            <ErrorMessage error={t('request.clarifications.couldNotGetClarifications')} />
          </PanelPadding>
        ) : (
          exchanges.length ? (
            <PanelPadding>
              <GridIdPrefixProvider>
                <GridMenuStateProvider>
                  <RequestMessagesGrid data={exchanges} columns={columns} onRowClick={openExchange} />
                </GridMenuStateProvider>
              </GridIdPrefixProvider>
            </PanelPadding>
          ) : (
            <Flex width="100%" height="200px" justifyContent="center" alignItems="center">
              <Bold color="darkGray2">
                {t('request.clarifications.noClarifications')}
              </Bold>
            </Flex>
          )
        )}
      </Panel>
      <Text mt={3}>
        <Disclosure2
          width="100%"
          summary={t('request.clarifications.disclosure.heading')}
        >
          {isRecipient ? t('request.clarifications.disclosure.body.recipient') : t('request.clarifications.disclosure.body.sender')}
        </Disclosure2>
      </Text>
      <NewClarificationModal
        sectionId={section._id}
        recipientId={recipientId}
        isOpen={newClarificationModal.isOpen}
        onCancel={newClarificationModal.close}
        onSuccess={callAll(
          newClarificationModal.close,
          navigateToExchange,
        )}
      />
    </>
  );
};
