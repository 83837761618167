import { useTranslation } from 'react-i18next';
import { useNavigate } from '../../tanstackRouter';
import { useCurrentCompanyId } from '../../currentCompanyId';
import {
  requestEditSummaryRoute,
  requestsIndexRoute,
} from '../../AppRouting';
import * as Layout from '../../Layout';
import { CreateRequestButton } from '../../CreateRequestButton';

export const RequestsLayout = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentCompanyId = useCurrentCompanyId();

  return (
    <Layout.HeaderAndContent
      header={
        <Layout.PageHeader heading={t('pageHeader.requests')} icon="file-text-o">
          <CreateRequestButton
            onRequestCreated={({ id: rfqId }) => {
              navigate({
                to: requestEditSummaryRoute.to,
                // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
                params: { currentCompanyId, rfqId },
              });
            }}
            navigateToTemplates={() => {
              navigate({
                to: requestsIndexRoute.to,
                // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
                params: { currentCompanyId },
                search: { tab: 'templates' },
              });
            }}
          />
        </Layout.PageHeader>
      }
      content={children}
    />
  );
};
