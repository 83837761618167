import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { first, sumBy, reject, values, filter, groupBy, includes } from 'lodash';
import { Flex, Box } from 'rebass/styled-components';
import {
  ActionType,
  ExchangeType,
  LineItemExchangeDefinition,
  hasSupplierPriceField,
} from '@deepstream/common/rfq-utils';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { LabeledValue, LabeledNumber } from '../../draft/LabeledValue';
import { CurrencyCodeProvider } from '../../ui/Currency';
import { useExchangeNavigation } from '../../useExchangeModalState';
import {
  useContractData,
  useContractSection,
  useIsSender,
  useApprovalsContext,
  useApprovalExchange,
  useIsSectionDirty,
} from './contract';
import { CurrencyExchangeSnapshot, ExchangeSnapshot, LineItemsExchangeSnapshot } from '../../types';
import { ExchangesGrid } from '../Request/Live/ExchangesGrid';
import { useLineItemExchangesGridColumns } from '../Exchange/useLineItemExchangesGridColumns';
import { SelectFieldBase } from '../../form/SelectField';
import { useCurrencySelectItems } from '../../ui/currencies';
import { useConfirmDialog } from '../../ui/useModalState';
import { useSendContractExchangeReply } from './useSendContractExchangeReply';
import { ConfirmChangeCurrencyDialog } from '../../ConfirmChangeCurrencyDialog';

// For stable reference
const EMPTY_ARRAY = [];

export const ContractLineItemBidSection = () => {
  const { t } = useTranslation();
  const contract = useContractData();
  const section = useContractSection();
  const { openExchange } = useExchangeNavigation();
  const isSender = useIsSender();
  // @ts-expect-error ts(2339) FIXME: Property 'onlyDirtyExchanges' does not exist on type '{ onlyDirtyExchanges: boolean; toggleDirtyExchanges: () => void; removeFilter: () => void; } | null'.
  const { onlyDirtyExchanges } = useApprovalsContext();
  // @ts-expect-error ts(2339) FIXME: Property 'dirtyExchangeIds' does not exist on type 'ExchangeSnapshot'.
  const { dirtyExchangeIds } = useApprovalExchange();
  const isSectionDirty = useIsSectionDirty();
  const currencySelectItems = useCurrencySelectItems();
  const { confirm, ...dialogProps } = useConfirmDialog();

  const { exchangeById, exchangeDefById } = contract;

  const exchanges = filter(
    values(exchangeById) as ExchangeSnapshot[],
    exchange => section.exchangeDefIds.includes(exchange._id),
  );

  const exchangesByType = useMemo(
    () => groupBy(
      exchanges,
      exchange => exchange.def.type,
    ),
    [exchanges],
  );

  const currencyExchange = first(exchangesByType.currency) as CurrencyExchangeSnapshot;
  const currencyCode = currencyExchange.latestCurrency ?? '';
  const hasSingleCurrency = currencyExchange.def.currencies.length === 1;

  const [latestCurrency, setLatestCurrency] = useState(currencyExchange.latestCurrency);

  useEffect(() => {
    setLatestCurrency(currencyExchange.latestCurrency);
  }, [currencyExchange.latestCurrency]);

  const sectionTotal = sumBy(
    reject(exchangesByType.lineItem as LineItemsExchangeSnapshot[], 'isObsolete') ?? [],
    (exchange: LineItemsExchangeSnapshot) => exchange.computedFormulas?.totalCost || 0,
  );

  const hideSectionTotal = !sectionTotal && section.exchangeDefIds.some(exchangeDefId => {
    const exchangeDef = contract.exchangeDefById[exchangeDefId];

    return (
      exchangeDef?.type === ExchangeType.LINE_ITEM &&
      !exchangeDef.fields.totalCost
    );
  });

  const lineItemExchanges = exchangesByType[ExchangeType.LINE_ITEM];
  const firstExchange = first(lineItemExchanges as any[]);
  const firstExchangeDef = firstExchange
    ? exchangeDefById[firstExchange.def._id] as LineItemExchangeDefinition
    : undefined;
  const fields = firstExchangeDef?.fields;

  const columns = useLineItemExchangesGridColumns({
    isRecipient: !isSender,
    fields,
    orderedFieldIds: firstExchangeDef?.orderedFieldIds,
  });

  const exchangesToShow = useMemo(() => {
    const lineItems = exchangesByType.lineItem ?? EMPTY_ARRAY;

    if (!onlyDirtyExchanges || !dirtyExchangeIds?.length) return lineItems;

    return filter(
      exchangesByType.lineItem,
      (exchange) => includes(dirtyExchangeIds, exchange._id),
    ) ?? EMPTY_ARRAY;
  }, [exchangesByType.lineItem, dirtyExchangeIds, onlyDirtyExchanges]);

  const [sendExchangeReply] = useSendContractExchangeReply({
    contractId: contract._id,
    exchangeId: currencyExchange._id,
  });

  const isCurrencySelectDisabled = (
    hasSingleCurrency ||
    isSender ||
    exchanges.some(exchange => exchange.disabledReason) ||
    currencyExchange.isObsolete
  );

  // Hide pristine sections when verifying for approvals changes
  if (onlyDirtyExchanges && !isSectionDirty) return null;

  return (
    <CurrencyCodeProvider code={currencyCode}>
      <Panel>
        <PanelHeader heading={section.name} description={section.description} icon="list-ul">
          <Flex alignItems="flex-start">
            {!hasSupplierPriceField(fields) ? (
              null
            ) : !hasSingleCurrency ? (
              <Box mr={hideSectionTotal ? 0 : 3} sx={{ lineHeight: 1 }}>
                <SelectFieldBase
                  label={t('general.currency')}
                  items={filter(currencySelectItems, item =>
                    currencyExchange.def.currencies.includes(item.value),
                  )}
                  value={latestCurrency || currencyExchange.latestCurrency}
                  onChange={async value => {
                    if (currencyExchange.latestCurrency) {
                      return confirm(async () => {
                        setLatestCurrency(value);
                        await sendExchangeReply({
                          value: ActionType.SUBMIT,
                          currency: value,
                        });
                      });
                    }

                    setLatestCurrency(value);
                    await sendExchangeReply({
                      value: ActionType.SUBMIT,
                      currency: value,
                    });
                  }}
                  disabled={isCurrencySelectDisabled}
                  buttonStyle={{ fontWeight: 500, width: 180, marginTop: '4px' }}
                  placeholder={t('request.lineItems.currency.select')}
                />
              </Box>
            ) : (
              <LabeledValue
                label={t('general.currency')}
                value={currencyCode || <EmDash />}
              />
            )}
            {!hideSectionTotal && (
              <LabeledNumber
                ml={3}
                label={t('request.lineItems.sectionTotal')}
                number={sectionTotal}
                format="money"
              />
            )}
          </Flex>
        </PanelHeader>
        <PanelDivider />
        <PanelPadding>
          <ExchangesGrid
            exchanges={exchangesToShow}
            columns={columns}
            onRowClick={latestCurrency ? openExchange : null}
          />
        </PanelPadding>
      </Panel>
      <ConfirmChangeCurrencyDialog
        {...dialogProps}
      />
    </CurrencyCodeProvider>
  );
};
