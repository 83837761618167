import { useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { AwardDecision, ExchangeType, Live, LotIntentionStatus } from '@deepstream/common/rfq-utils';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { first, groupBy, isEmpty, isEqual, keyBy, mapValues, noop, partition } from 'lodash';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { immutableSet } from '@deepstream/utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { EditableGridDataProvider } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { FormikGridRowUpdater } from '@deepstream/ui-kit/grid/EditableGrid/FormikGridRowUpdater';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData, GridAwardDecision } from '../types';
import * as rfx from '../../../../rfx';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { AwardDecisionGridRowData, EditAwardDecisionGrid } from '../../../../ui/ExchangeDefsGrid/EditAwardDecisionGrid';
import { RequestHooksProvider } from '../../RequestHooksProvider';
import { AwardLotsRecipientCell } from '../../../../ui/ExchangeDefsGrid/nonValidationAwareValueCell';
import { getLotAwardTotalAmount } from '../useCalculatedTotalValue';
import { Direction } from '../../../../ui/MultiStepFlow/types';

const hasRowChanged = (gridRow: AwardDecisionGridRowData, formikRow: AwardDecisionGridRowData) =>
  !isEqual(gridRow.awardDecision, formikRow.awardDecision);

const useInitialLotRowData = () => {
  const { lots, sectionById, exchangeDefById, bidById } = rfx.useStructure<Live>();
  const { costAndSavingsByRecipientId } = rfx.useCostAndSavingsData();

  return useMemo(() => {
    const lineItemExchangeDefs = Object.values(exchangeDefById)
      .filter(exchangeDef => (
        !exchangeDef.isObsolete &&
        [ExchangeType.LINE_ITEM, ExchangeType.AUCTION_LINE_ITEM].includes(exchangeDef.type)
      ));

    // we don't access `section.exchangeDefIds` for this because it doesn't
    // include IDs of supplier-provided exchanges, which we want to include
    // here
    const lineItemExchangeDefsByLotId = groupBy(
      lineItemExchangeDefs,
      // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
      exchangeDef => first(sectionById[exchangeDef.sectionId]?.lotIds),
    );

    const nonObsoleteLots = lots.filter(lot => !lot.isObsolete);

    return nonObsoleteLots.map(lot => {
      const lotIntentionStatusByRecipientId = mapValues(
        bidById,
        bid => bid.intentionStatusByLotId[lot._id] || LotIntentionStatus.NO_RESPONSE,
      );
      const exchangeIdsWithTotalCost = (lineItemExchangeDefsByLotId[lot._id] || [])
        // @ts-expect-error ts(18048) FIXME: 'exchangeDef.fields' is possibly 'undefined'.
        .filter(exchangeDef => exchangeDef.fields.totalCost)
        .map(exchangeDef => exchangeDef._id);
      const hasTotalCost = !isEmpty(exchangeIdsWithTotalCost);

      return {
        _id: lot._id,
        label: `${lots.findIndex(({ _id }) => _id === lot._id) + 1} – ${lot.name}`,
        lotIntentionStatusByRecipientId,
        hasTotalCost,
        bidAmountByRecipientId: mapValues(
          lotIntentionStatusByRecipientId,
          (lotIntentionStatus, recipientId) => {
            // We show the bid amount in the UI only when the recipient is
            // bidding so we only calculate it in that case.
            return lotIntentionStatus === LotIntentionStatus.BIDDING && hasTotalCost
              ? getLotAwardTotalAmount(costAndSavingsByRecipientId[recipientId], exchangeIdsWithTotalCost)
              : null;
          },
        ),
        awardableRecipientIds: Object.entries(lotIntentionStatusByRecipientId)
          .filter(([, lotIntentionStatus]) => lotIntentionStatus === LotIntentionStatus.BIDDING)
          .map(([recipientId]) => recipientId),
      };
    });
  }, [bidById, exchangeDefById, lots, sectionById, costAndSavingsByRecipientId]);
};

const getSubmissionDataFromFormValues = ({
  lotRowDataWithTotalCost,
  lotRowDataWithoutTotalCost,
}: {
  lotRowDataWithTotalCost: AwardDecisionGridRowData[];
  lotRowDataWithoutTotalCost: AwardDecisionGridRowData[];
}) => {
  const allRowDataByLotId = {
    ...keyBy(lotRowDataWithTotalCost, '_id'),
    ...keyBy(lotRowDataWithoutTotalCost, '_id'),
  };

  return {
    awardDecisionByLotId: mapValues(
      allRowDataByLotId,
      ({ awardDecision, bidAmountByRecipientId }) => {
        if (awardDecision?.value === 'award') {
          return {
            value: 'award',
            awards: (awardDecision as any).awardedSupplierIds.map(recipientId => ({
              recipientId,
              requestCurrencyAwardedCost: bidAmountByRecipientId[recipientId],
            })),
          } as Extract<AwardDecision, { value: 'award' }>;
        } else {
          return awardDecision;
        }
      },
    ),
  };
};

const LotsWithoutTotalCostInfo = () => {
  const { t } = useTranslation('translation');

  return (
    <Box sx={{ maxWidth: lotPagesLayout.DEFAULT_SECTION_WIDTH }} mt="40px" mb="20px">
      <lotPagesLayout.H3>
        {t('request.awardFlow.steps.chooseLotLevelAwardSuppliers.lotsWithoutTotalCost')}
      </lotPagesLayout.H3>
      <Text mt="12px">
        {t('request.awardFlow.steps.chooseLotLevelAwardSuppliers.lotsWithoutTotalCostDescription1')}
      </Text>
      <Text mt={2}>
        {t('request.awardFlow.steps.chooseLotLevelAwardSuppliers.lotsWithoutTotalCostDescription2')}
      </Text>
    </Box>
  );
};

export const ChooseLotLevelAwardSuppliersStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (data: Partial<AwardFlowData> | null, direction: Direction) => void,
}) => {
  const { t } = useTranslation('translation');
  const { currencyCode } = rfx.useStructure<Live>();
  const initialLotRowData = useInitialLotRowData();
  const recipients = rfx.useSortedBiddingRecipients({ excludeUnsuccessful: true });

  const initialLotRowDataWithAwardDecisions = initialLotRowData.map(item => {
    const storedAwardDecision = data.awardDecisionByLotId?.[item._id];

    return {
      ...item,
      awardDecision: storedAwardDecision
        ? (
          storedAwardDecision.value === 'award'
            ? {
              value: 'award',
              awardedSupplierIds: storedAwardDecision.awards.map(award => award.recipientId),
            }
            : storedAwardDecision
        ) as GridAwardDecision
        : (
          // when no supplier can be selected, set 'no award' decision
          // as a static value in initial data
          isEmpty(item.awardableRecipientIds) ? { value: 'noAward' } : null
        ) as GridAwardDecision,
    };
  });

  const [
    initialLotRowDataWithTotalCost,
    initialLotRowDataWithoutTotalCost,
  ] = partition(initialLotRowDataWithAwardDecisions, item => item.hasTotalCost);

  const validationSchema = useMemo(() => {
    const RowDataArraySchema = z.array(
      z.custom<AwardDecisionGridRowData>().refine(
        value => Boolean(value.awardDecision),
        value => ({ message: t('request.awardFlow.errors.selectLotAwardDecision', { lotName: value.label }) }),
      ),
    );

    const Schema = z.object({
      lotRowDataWithTotalCost: RowDataArraySchema,
      lotRowDataWithoutTotalCost: RowDataArraySchema,
    });

    return toFormikValidationSchema(Schema);
  }, [t]);

  return (
    <RequestHooksProvider>
      <rfx.StateProvider isLive>
        <Formik
          validateOnBlur
          initialValues={{
            lotRowDataWithTotalCost: initialLotRowDataWithTotalCost,
            lotRowDataWithoutTotalCost: initialLotRowDataWithoutTotalCost,
          }}
          validationSchema={validationSchema}
          onSubmit={noop}
        >
          {({ values, validateForm, submitForm, dirty }) => (
            <Form>
              <lotPagesLayout.ContentWrapper>
                <StepNavigation
                  onBackClick={() => submitAndNavigate(dirty ? getSubmissionDataFromFormValues(values) : null, Direction.BACK)}
                  onContinueClick={async () => {
                    const errors = await validateForm();

                    await submitForm();

                    if (isEmpty(errors)) {
                      submitAndNavigate(dirty ? getSubmissionDataFromFormValues(values) : null, Direction.FORWARD);
                    }
                  }}
                >
                  <FormErrors />
                  <lotPagesLayout.Section heading={t('request.awardFlow.steps.chooseLotLevelAwardSuppliers.heading')} maxWidth="100%">
                    <Box sx={{ maxWidth: lotPagesLayout.DEFAULT_SECTION_WIDTH }} mb="40px">
                      <Text mt="12px">
                        {t('request.awardFlow.steps.chooseLotLevelAwardSuppliers.info')}
                      </Text>
                      <lotPagesLayout.DescriptionListContainer style={{ marginTop: '8px' }}>
                        <lotPagesLayout.DescriptionListItem
                          label={
                            <>
                              <Icon icon="trophy" regular mr={1} />
                              {t('request.awardFlow.steps.chooseLotLevelAwardSuppliers.awardTo')}
                            </>
                          }
                          description={t('request.awardFlow.steps.chooseLotLevelAwardSuppliers.awardToDescription')}
                        />
                        <lotPagesLayout.DescriptionListItem
                          label={
                            <>
                              <Icon icon="circle-xmark" regular mr={1} />
                              {t('request.awardFlow.steps.chooseLotLevelAwardSuppliers.noAward')}
                            </>
                          }
                          description={t('request.awardFlow.steps.chooseLotLevelAwardSuppliers.noAwardDescription')}
                        />
                      </lotPagesLayout.DescriptionListContainer>
                    </Box>

                    {!isEmpty(initialLotRowDataWithTotalCost) && (
                      <GridIdPrefixProvider>
                        <GridMenuStateProvider>
                          <EditableGridDataProvider
                            rowData={initialLotRowDataWithTotalCost}
                            setValueInRow={immutableSet}
                          >
                            <FormikGridRowUpdater fieldName="lotRowDataWithTotalCost" hasRowChanged={hasRowChanged} />
                            <EditAwardDecisionGrid
                              recipients={recipients}
                              currencyCode={currencyCode}
                              showTotalCost
                              labelColumnHeader={t('request.lot', { count: 1 })}
                              RecipientCell={AwardLotsRecipientCell}
                              viewportHeightDelta={200}
                            />
                          </EditableGridDataProvider>
                        </GridMenuStateProvider>
                      </GridIdPrefixProvider>
                    )}

                    {!isEmpty(initialLotRowDataWithTotalCost) && !isEmpty(initialLotRowDataWithoutTotalCost) && (
                      <LotsWithoutTotalCostInfo />
                    )}

                    {!isEmpty(initialLotRowDataWithoutTotalCost) && (
                      <GridIdPrefixProvider>
                        <GridMenuStateProvider>
                          <EditableGridDataProvider
                            rowData={initialLotRowDataWithoutTotalCost}
                            setValueInRow={immutableSet}
                          >
                            <FormikGridRowUpdater fieldName="lotRowDataWithoutTotalCost" hasRowChanged={hasRowChanged} />
                            <EditAwardDecisionGrid
                              recipients={recipients}
                              currencyCode={currencyCode}
                              labelColumnHeader={t('request.lot', { count: 1 })}
                              RecipientCell={AwardLotsRecipientCell}
                              viewportHeightDelta={200}
                            />
                          </EditableGridDataProvider>
                        </GridMenuStateProvider>
                      </GridIdPrefixProvider>
                    )}
                  </lotPagesLayout.Section>
                </StepNavigation>
              </lotPagesLayout.ContentWrapper>
            </Form>
          )}
        </Formik>
      </rfx.StateProvider>
    </RequestHooksProvider>
  );
};
