import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Live, LotIntentionStatus } from '@deepstream/common/rfq-utils';
import { callAll } from '@deepstream/utils/callAll';
import { useApi } from '../../../api';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useMutation } from '../../../useMutation';
import { useToaster } from '../../../toast';
import {
  useRfqId,
  useRecipientId,
  useLiveRfqStructureQueryKey,
} from '../../../useRfq';
import * as rfx from '../../../rfx';
import { useWaitForRfqUnlock } from '../../../useWaitForUnlock';

export const useSendLotIntention = () => {
  const { t } = useTranslation('translation');
  const api = useApi();
  const { lots } = rfx.useStructure<Live>();
  const rfqId = useRfqId({ required: true });
  const recipientId = useRecipientId({ required: true });
  const waitForRfqUnlock = useWaitForRfqUnlock();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const liveStructureQueryKey = useLiveRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
    recipientId,
  });

  return useMutation(
    ({ intention, lotId }: { intention: LotIntentionStatus; lotId: string }) => waitForRfqUnlock({
      command: () => api.sendLotIntention({
        rfqId,
        recipientId,
        lotId,
        intention,
      }),
    }),
    {
      onSuccess: callAll(
        (_, { lotId, intention }) => toaster.success(t(`request.lots.toaster.updateIntentionSuccess.${intention}`, {
          lotNumber: lots.findIndex(lot => lot._id === lotId) + 1,
        })),
        () => queryClient.invalidateQueries(liveStructureQueryKey),
        () => queryClient.invalidateQueries(['exchanges', { rfqId }]),
      ),
      onError: (_, { lotId }) => toaster.error(t('request.lots.toaster.updateIntentionError', {
        lotNumber: lots.findIndex(lot => lot._id === lotId) + 1,
      })),
    },
  );
};
