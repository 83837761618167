import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ContractStatus, ExtendedSentContractOverview, SentContractOverviews } from '@deepstream/common/contract';
import { withProps } from '@deepstream/ui-utils/withProps';
import { BasicTableStyles } from '../../TableStyles';
import { Table } from '../../Table';
import { Badge } from '../../Badge';
import { DateFieldValue, NameArrayFieldValue, FieldsCell, MIN_CELL_HEIGHT, CurrencyAmountValue } from '../../FieldsCell';
import { SentContractActionButtons } from './SentContractActionButtons';
import { ContractStatusFieldValue, ContractStatusIcon } from './ContractStatus';
import { useTableData } from '../../TableDataContext';
import { ContractsTableData } from './ContractsTableDataProvider';
import { useNavigate } from '../../tanstackRouter';
import { contractDraftSummaryRoute, contractLiveSummaryRoute } from '../../AppRouting';
import { useCurrentCompanyId } from '../../currentCompanyId';

const TitleComponent = ({ row }) => {
  const { t } = useTranslation('contracts');

  const { name, isLegacy } = row.original;

  if (!name) return t('untitledContract');

  return (
    <>
      {name}
      {isLegacy && (
        <Badge
          sx={{
            display: 'inline-block',
            verticalAlign: 'middle',
            ml: 2,
          }}
        >
          {t('legacy.legacyLabel')}
        </Badge>
      )}
    </>
  );
};

export const SentContractsTable = ({
  openDeleteModal,
}: {
  openDeleteModal: (contract: ExtendedSentContractOverview) => void;
}) => {
  const { t } = useTranslation(['contracts', 'general', 'company', 'translation']);
  const { selectedItemsIds, selectItem, unselectItem } = useTableData<ContractsTableData<SentContractOverviews>>({ required: true });
  const navigate = useNavigate();
  const currentCompanyId = useCurrentCompanyId();

  const navigateToContract = useCallback(
    contract => {
      const isDraft = contract.status === ContractStatus.DRAFT;

      navigate({
        to: isDraft ? contractDraftSummaryRoute.to : contractLiveSummaryRoute.to,
        // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
        params: { currentCompanyId, contractId: contract._id },
      });
    },
    [currentCompanyId, navigate],
  );

  const columns = useMemo(
    () => [{ id: 'custom' }],
    [],
  );

  const CustomRowCells = useMemo(
    () => {
      const fields = [
        {
          label: t('status', { ns: 'general' }),
          accessor: 'status',
          FieldValueComponent: ContractStatusFieldValue,
        },
        {
          label: t('summary.counterParty'),
          accessor: (row) => row.recipient?.name,
        },
        {
          label: t('yourRole', { ns: 'company' }),
          accessor: (request) => t(`requests.dashboardRole.${request.role}`, { ns: 'translation' }),
        },
        {
          label: t('owner_other'),
          singularLabel: t('owner', { count: 1 }),
          accessor: 'ownerNamesOrEmails',
          FieldValueComponent: NameArrayFieldValue,
        },
        {
          label: t('value'),
          accessor: 'spendData',
          FieldValueComponent: CurrencyAmountValue,
        },
        {
          label: t('expiry'),
          accessor: 'expiryDate',
          FieldValueComponent: DateFieldValue,
        },
      ];

      const selectField = {
        onSelect: (row) => {
          const overview = row.original;
          if (selectedItemsIds?.includes(overview._id)) {
            // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
            unselectItem(overview._id);
          } else {
            // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
            selectItem(overview._id);
          }
        },
        isSelected: (row) => selectedItemsIds?.includes(row.original._id),
      };

      return withProps(
        FieldsCell,
        {
          fields,
          titleAccessor: 'name',
          TitleComponent,
          RowIconComponent: ContractStatusIcon,
          actionColumnWidth: 100,
          ActionsComponent: withProps(
            SentContractActionButtons,
            {
              openDeleteModal,
            },
          ),
          ...selectField,
        },
      );
    },
    [t, openDeleteModal, selectItem, unselectItem, selectedItemsIds],
  );

  return (
    <BasicTableStyles hoverBackgroundColor="lightGray6">
      <Table
        columns={columns}
        isPaginated
        pageSizes={[5, 10, 20, 50, 100]}
        hideHeader
        CustomRowCells={CustomRowCells}
        hasStaticHeight={false}
        enforcePagination
        minCellHeight={MIN_CELL_HEIGHT}
        smallPageControls
        onRowClick={navigateToContract}
      />
    </BasicTableStyles>
  );
};
