import { useTranslation } from 'react-i18next';
import { isEmpty, isNil, omitBy } from 'lodash';
import { Box } from 'rebass/styled-components';
import { useQuery } from 'react-query';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { QuestionnaireTemplateStatus } from '@deepstream/common/preQual';
import { useQuestionnaireTemplateData, useQuestionnaireTemplateId } from './questionnaireTemplateUtils';
import { usePreQualNavigation } from '../../../AppRouting';
import { useDraftQuestionnaireTemplateNavigation } from '../../../appNavigation';
import { useCurrentUser, useCurrentUserLocale } from '../../../useCurrentUser';
import useDownload from '../../../useDownload';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useApi, wrap } from '../../../api';
import { useUserFlags } from '../../../UserFlagsContext';
import { ArchiveQuestionnaireTemplateDialog } from './ArchiveQuestionnaireTemplateDialog';
import { RestoreQuestionnaireTemplateDialog } from './RestoreQuestionnaireTemplateDialog';
import { useModalState } from '../../../ui/useModalState';
import { PreQualTab } from '../utils';
import { useCompanyFeatureFlags } from '../../../companyFeatureFlags';
import { useMutation } from '../../../useMutation';
import { useToaster } from '../../../toast';
import { AsyncFileDownloadDialog } from '../../../AsyncFileDownloadDialog';

// Threshold for downloading the supplier responses and audit trail reports synchronously versus asynchronously (as a UserReport)
const NUM_SUPPLIERS_THRESHOLD = 25;

export const QuestionnaireTemplateActions = () => {
  const { t } = useTranslation(['preQualification', 'translation']);
  const templateId = useQuestionnaireTemplateId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const locale = useCurrentUserLocale();
  const download = useDownload();
  const currentUser = useCurrentUser();
  const api = useApi();
  const { hasSendQuestionnairePermission, hasManagePreQualPermission } = useUserFlags();
  const companyFeatureFlags = useCompanyFeatureFlags();
  const archiveTemplateModal = useModalState();
  const restoreTemplateModal = useModalState();
  const asyncFileDownloadDialog = useModalState();
  const toaster = useToaster();

  const draftNavigation = useDraftQuestionnaireTemplateNavigation();
  const {
    navigateToSendQuestionnaire,
    navigateToPreQualificationArchive,
    navigateToPreQualificationQuestionnaires,
  } = usePreQualNavigation();

  const template = useQuestionnaireTemplateData();
  const isActive = template.status === QuestionnaireTemplateStatus.ACTIVE;
  const isArchived = template.status === QuestionnaireTemplateStatus.ARCHIVED;

  const [requestQuestionnairesResponsesReport] = useMutation(
    api.requestQuestionnairesResponsesReport,
    {
      onSuccess: asyncFileDownloadDialog.open,
      onError: () => toaster.error(t('request.download.toaster.downloadError')),
    },
  );

  const [requestQuestionnairesAuditReport] = useMutation(
    api.requestQuestionnairesAuditReport,
    {
      onSuccess: asyncFileDownloadDialog.open,
      onError: () => toaster.error(t('request.download.toaster.downloadError')),
    },
  );

  const { data: questionnaires } = useQuery(
    [
      'sentQuestionnaires',
      {
        currentCompanyId,
        filter: {
          fromTemplateId: templateId,
        },
      },
    ],
    wrap(api.getSentQuestionnaires),
  );

  const sentQuestionnaires = questionnaires?.overviews || [];

  const noQuestionnairesSent = isEmpty(sentQuestionnaires);

  const queryParams = new URLSearchParams(
    omitBy({
      locale,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      csvSeparator: currentUser.preferences?.csvSeparator,
    }, isNil) as Record<string, string>,
  );

  const downloadReport = async (report: string) => {
    await download(`/download/company/${currentCompanyId}/questionnaireTemplate/${templateId}/${report}?${queryParams.toString()}`);
  };

  return (
    <Box ml={4}>
      <DropdownMenu
        variant="secondary"
        buttonText={t('questionnaireActions')}
        iconRight="caret-down"
        menuStyle={{ maxWidth: '260px' }}
      >
        {hasSendQuestionnairePermission && (
          <DropdownMenuItem
            icon="paper-plane-top"
            onSelect={() => navigateToSendQuestionnaire({ templateIds: [templateId], from: 'template' })}
            disabled={isArchived}
          >
            {t('questionnaireTemplate.sendQuestionnaire')}
          </DropdownMenuItem>
        )}
        {hasManagePreQualPermission && (
          <>
            <DropdownMenuItem icon="pencil" onSelect={() => draftNavigation.navigateToSummary()} disabled={isArchived}>
              {t('questionnaireTemplate.reviseQuestionnaire')}
            </DropdownMenuItem>
            {isActive && (
              <DropdownMenuItem icon="archive" onSelect={archiveTemplateModal.open}>
                {t('archive.archiveQuestionnaire')}
              </DropdownMenuItem>
            )}
            {isArchived && (
              <DropdownMenuItem icon="undo" onSelect={restoreTemplateModal.open} disabled={!companyFeatureFlags.preQualEnabled}>
                {t('archive.restoreQuestionnaire')}
              </DropdownMenuItem>
            )}
          </>
        )}
        <DropdownMenuItem icon="download" onSelect={() => downloadReport('overview')} disabled={noQuestionnairesSent}>
          {t('download.overview')}
        </DropdownMenuItem>
        <DropdownMenuItem
          icon="download"
          // @ts-expect-error ts(18048) FIXME: 'questionnaires' is possibly 'undefined'.
          onSelect={() => questionnaires.totalItems > NUM_SUPPLIERS_THRESHOLD
            ? requestQuestionnairesResponsesReport({ currentCompanyId, questionnaireTemplateId: templateId })
            : downloadReport('supplierResponses')
          }
          disabled={noQuestionnairesSent}
        >
          {t('download.supplierResponses')}
        </DropdownMenuItem>
        <DropdownMenuItem
          icon="download"
          // @ts-expect-error ts(18048) FIXME: 'questionnaires' is possibly 'undefined'.
          onSelect={() => questionnaires.totalItems > NUM_SUPPLIERS_THRESHOLD
            ? requestQuestionnairesAuditReport({ currentCompanyId, questionnaireTemplateId: templateId })
            : downloadReport('auditTrail')}
          disabled={noQuestionnairesSent}
        >
          {t('download.supplierAuditTrail')}
        </DropdownMenuItem>
      </DropdownMenu>
      <ArchiveQuestionnaireTemplateDialog
        {...archiveTemplateModal}
        templateId={templateId}
        onSuccess={() => {
          archiveTemplateModal.close();
          navigateToPreQualificationArchive(PreQualTab.ARCHIVE_QUESTIONNAIRE_TEMPLATES);
        }}
        onError={archiveTemplateModal.close}
      />
      <RestoreQuestionnaireTemplateDialog
        {...restoreTemplateModal}
        templateId={templateId}
        onSuccess={() => {
          restoreTemplateModal.close();
          navigateToPreQualificationQuestionnaires(PreQualTab.ACTIVE_QUESTIONNAIRES);
        }}
        onError={restoreTemplateModal.close}
      />
      <AsyncFileDownloadDialog
        isOpen={asyncFileDownloadDialog.isOpen}
        onOk={asyncFileDownloadDialog.close}
      />
    </Box>
  );
};
