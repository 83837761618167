import { useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { RequestNotAccurateReason } from '@deepstream/common/rfq-utils';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { isEmpty, noop } from 'lodash';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData } from '../types';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { CheckboxFieldArray } from '../../../../form/CheckboxField';
import { TextField } from '../../../../form/TextField';
import { Direction } from '../../../../ui/MultiStepFlow/types';

export const CalculatedValueNotAccurateReasonsStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (data: Partial<AwardFlowData> | null, direction: Direction) => void,
}) => {
  const { t } = useTranslation('translation');

  const noLineItemsReasonOptions = useMemo(() => {
    return Object.values(RequestNotAccurateReason).map(reason => ({
      value: reason,
      label: t(`request.spendAndSavings.calculatedTotalValueNotAccurateReason.${reason}`),
    }));
  }, [t]);

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      calculatedTotalValueNotAccurateReasons: z.array(z.nativeEnum(RequestNotAccurateReason))
        .min(1, { message: t('request.awardFlow.errors.selectCalculatedValueNotAccurateReason') }),
      calculatedTotalValueNotAccurateOtherReason: z.string().nullable().optional(),
    }).refine(
      // When `calculatedTotalValueNotAccurateReasons` includes RequestNotAccurateReason.OTHER,
      // `calculatedTotalValueNotAccurateOtherReason` must be truthy
      value => (
        !value.calculatedTotalValueNotAccurateReasons.includes(RequestNotAccurateReason.OTHER) ||
        Boolean(value.calculatedTotalValueNotAccurateOtherReason)
      ),
      {
        message: t('request.awardFlow.errors.enterOtherOptionValue'),
        path: ['calculatedTotalValueNotAccurateOtherReason'],
      },
    );

    return toFormikValidationSchema(Schema);
  }, [t]);

  return (
    <Formik
      validateOnBlur
      initialValues={{
        ...data.spendAndSavings,
        calculatedTotalValueNotAccurateReasons: data.spendAndSavings.calculatedTotalValueNotAccurateReasons || [],
      }}
      validationSchema={validationSchema}
      onSubmit={noop}
    >
      {({ values, submitForm, validateForm, setFieldValue, dirty }) => (
        <Form>
          <lotPagesLayout.ContentWrapper>
            <StepNavigation
              onBackClick={() => {
                submitAndNavigate(dirty ? { spendAndSavings: values } : null, Direction.BACK);
              }}
              onContinueClick={async () => {
                const errors = await validateForm();

                await submitForm();

                if (isEmpty(errors)) {
                  submitAndNavigate(dirty ? { spendAndSavings: values } : null, Direction.FORWARD);
                }
              }}
            >
              <FormErrors />
              <lotPagesLayout.Section
                heading={t('request.awardFlow.steps.calculatedValueNotAccurateReasons.heading')}
              >
                <Text my={3}>
                  {t('request.spendAndSavings.calculatedTotalValueNotAccurateReasonsDescription')}
                </Text>
                <CheckboxFieldArray
                  name="calculatedTotalValueNotAccurateReasons"
                  hideLabel
                  flexDirection="column"
                  options={noLineItemsReasonOptions}
                  onChange={(event) => {
                    if (event.target.getAttribute('data-value') === RequestNotAccurateReason.OTHER && !event.target.checked) {
                      setFieldValue('calculatedTotalValueNotAccurateOtherReason', undefined);
                    }
                  }}
                  errorMessageStyle={{ fontSize: '14px', marginTop: '16px' }}
                />
                {values.calculatedTotalValueNotAccurateReasons?.includes(RequestNotAccurateReason.OTHER) && (
                  <Box mt={2}>
                    <TextField
                      name="calculatedTotalValueNotAccurateOtherReason"
                      hideLabel
                      placeholder={t('request.spendAndSavings.pleaseSpecify')}
                      errorMessageStyle={{ fontSize: '14px', marginTop: '16px' }}
                    />
                  </Box>
                )}
              </lotPagesLayout.Section>
            </StepNavigation>
          </lotPagesLayout.ContentWrapper>
        </Form>
      )}
    </Formik>
  );
};
