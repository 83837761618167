import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { v4 as uuid } from 'uuid';
import { canAddEvaluationForPage, getPagesInDisplayOrder, isLinkedEvaluationPage } from '@deepstream/common/rfq-utils';
import { first, without } from 'lodash';
import { Text, Flex, Box } from 'rebass/styled-components';
import { Form, Formik } from 'formik';
import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { Modal, ModalHeader, ModalBody, ModalFooter, SaveButton, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import * as draft from '../../draft/draft';
import * as rfx from '../../rfx';
import { CheckboxFieldArray } from '../../form/CheckboxField';

export const AddEvaluationPageModal = ({
  onCancel,
  onSuccess,
}: {
  onCancel: () => void;
  onSuccess: (pageId: string) => void;
}) => {
  const { t } = useTranslation(['evaluation', 'translation', 'general']);
  const { pages } = rfx.useStructure();
  const [addLinkedEvaluationPages] = draft.useAddLinkedEvaluationPages();

  const {
    linkedRfxPageIds,
    options,
  } = useMemo(() => {
    const pagesInDisplayOrder = getPagesInDisplayOrder(pages);

    const linkedEvaluationPages = pagesInDisplayOrder.filter(isLinkedEvaluationPage);

    const linkableRfxPages = pagesInDisplayOrder.filter(canAddEvaluationForPage);

    const linkedRfxPageIds = linkableRfxPages
      .map(page => page._id)
      .filter(pageId =>
        linkedEvaluationPages.some(evaluationPage => evaluationPage.linkedPageId === pageId),
      );

    const options = linkableRfxPages.map(page => ({
      value: page._id,
      label: page.name,
      isDisabled: linkedEvaluationPages.some(evaluationPage => evaluationPage.linkedPageId === page._id),
    }));

    return {
      linkedEvaluationPages,
      linkedRfxPageIds,
      linkableRfxPages,
      options,
    };
  }, [pages]);

  return (
    <Modal
      isOpen
      style={{ content: { width: '500px' } }}
    >
      <Formik
        initialValues={{
          pageIds: linkedRfxPageIds,
        }}
        validateOnBlur
        validationSchema={
          yup.object().shape({
            pageIds: yup.array().of(yup.string()),
          })
        }
        onSubmit={async ({ pageIds }, { setSubmitting }) => {
          const rfxPageIdsToLink = without(pageIds, ...linkedRfxPageIds);

          const newEvaluationPages = rfxPageIdsToLink.map(pageId => ({
            _id: uuid(),
            linkedPageId: pageId,
          }));

          await addLinkedEvaluationPages(
            {
              pages: newEvaluationPages,
            },
            {
              // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
              onSuccess: () => onSuccess(first(newEvaluationPages)._id),
              onError: () => setSubmitting(false),
            },
          );
        }}
      >
        {({ isSubmitting, dirty, isValid, values, setFieldValue }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              {t('request.pages.addPage_other', { ns: 'translation' })}
            </ModalHeader>
            <ModalBody>
              <Text mb={3}>
                {t('addPagesModal.hintText1')}
                <br />
                {t('addPagesModal.hintText2')}
              </Text>
              <Checkbox
                label={t('selectAll', { ns: 'general' })}
                checked={values.pageIds.length > 0 && values.pageIds.length === options.length}
                indeterminate={values.pageIds.length > 0 && values.pageIds.length !== options.length}
                onChange={(event) => {
                  if ((event.target as HTMLInputElement).checked) {
                    setFieldValue('pageIds', options.map(option => option.value));
                  } else {
                    setFieldValue('pageIds', []);
                  }
                }}
              />
              <CheckboxFieldArray
                mt={1}
                name="pageIds"
                hideLabel
                options={options}
                flexDirection="column"
              />
            </ModalBody>

            <ModalFooter justifyContent="space-between" sx={{ gap: 3 }}>
              <Box>
                {t('addPagesModal.selectedPageCount', { count: without(values.pageIds, ...linkedRfxPageIds).length })}
              </Box>
              <Flex>
                <CancelButton onClick={onCancel} />
                <SaveButton
                  label={t('general.add', { ns: 'translation' })}
                  disabled={isSubmitting || !dirty || !isValid}
                />
              </Flex>
            </ModalFooter>

          </Form>
        )}
      </Formik>
    </Modal>
  );
};
