import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { getActionDisplayProps } from '@deepstream/common/rfq-utils/exchangeAction';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { ExchangeSnapshot } from '../types';

export const usePopulateExchangeActionDisplayProps = () => {
  const { t } = useTranslation('exchangeAction');
  const theme = useTheme();

  return useCallback(
    (exchange: ExchangeSnapshot, recipientId: string) => ({
      ...exchange,
      history: exchange.history.map(action => {
        let displayProps;

        try {
          displayProps = getActionDisplayProps(action, exchange.def, t, theme.colors, recipientId);
        } catch (error) {
          displayProps = { description: `[${exchange.def.type}:${action.type}:${action.subtype || '-'}]` };
          Sentry.captureException(error);
        }

        return {
          ...action,
          ...displayProps,
        };
      }),
    }),
    [t, theme],
  );
};
