import * as React from 'react';
import * as Sentry from '@sentry/react';
import { Box, Flex } from 'rebass/styled-components';
import { UnexpectedStateError } from '@deepstream/errors';
import { QuestionType, QuestionExchangeDefinition, YesNoQuestionExchangeDefinition } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { StyledRadio } from '../../../ui/Radio';
import { CheckboxFieldBase } from '../../../form/CheckboxField';
import { FieldContainer } from '../../../form/FieldContainer';
import { DisabledInputBox } from '../../../ui/Input';

export const DisabledYesNoField = ({
  exchangeDef,
  fieldMaxWidth,
}: {
  exchangeDef: QuestionExchangeDefinition;
  fieldMaxWidth?: React.CSSProperties['maxWidth'];
}) => {
  const { t } = useTranslation();

  const {
    options,
    questionType,
    allowCustomOption,
    requireMoreInformationOn,
  } = exchangeDef as YesNoQuestionExchangeDefinition;

  if (questionType !== QuestionType.YES_NO) {
    Sentry.captureException(new UnexpectedStateError('[YesNo] Wrong question type passed', { questionType }));
    return null;
  }

  return (
    <Flex>
      <Stack gap={2} flex={1}>
        {options.map((option) => (
          <Flex key={option} alignItems="center">
            <Flex flex={1} mr="24px" maxWidth={fieldMaxWidth}>
              <Box sx={{ marginRight: '8px', flex: '0 0 auto' }}>
                <StyledRadio
                  disabled
                  style={{ background: 'transparent', marginTop: '12px' }}
                />
              </Box>
              <FieldContainer flex="1 1 auto" showAsterisk>
                <DisabledInputBox>{t(`request.question.predefinedOption.${option}`)}</DisabledInputBox>
              </FieldContainer>
            </Flex>
            <Box flex={1}>
              <CheckboxFieldBase
                disabled
                value={requireMoreInformationOn.includes(option)}
                fieldLabel={t(`request.question.yesNo.requestSupportingInformation.${option}`)}
              />
            </Box>
          </Flex>
        ))}
        {allowCustomOption && (
          <Flex alignItems="flex-start">
            <Box sx={{ marginRight: '8px', flex: '0 0 auto' }}>
              <StyledRadio
                disabled
                style={{ background: 'transparent', marginTop: '12px' }}
              />
            </Box>
            <FieldContainer flex={1} showAsterisk>
              <DisabledInputBox>{t('request.question.other')}</DisabledInputBox>
            </FieldContainer>
          </Flex>
        )}
      </Stack>
    </Flex>
  );
};
