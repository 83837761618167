import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { Text, Box } from 'rebass/styled-components';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';

export const ValueOrNotMappedCell = ({ cell: { value } }: CellProps<any>) => {
  const { t } = useTranslation('integration');

  return (
    <>
      {value || <Text color="subtext">{t('notMapped')}</Text>}
    </>
  );
};

export const ValueOrNotMappedCellWithTooltip = ({ cell: { value }, externalSystemName }) => {
  const { t } = useTranslation('integration');

  return (
    <>
      {value || (
        <Box alignItems="center" sx={{ mr: 2, display: 'inline-flex' }}>
          <Tooltip content={t('externalStatus.notMappedTooltip', { externalSystemName })}>
            <Icon icon="exclamation-circle" color="danger" mr={1} fixedWidth fontSize={1} />
          </Tooltip>
          <Text>{t('notMapped')}</Text>
        </Box>)
      }
    </>
  );
};
