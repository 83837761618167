import { useTranslation } from 'react-i18next';
import { AnyScope, Stage } from '@deepstream/common/rfq-utils';
import { StageName } from '../../StageName';

export const ApprovalStageName = ({
  index,
  stage,
}: {
  index?: number;
  stage?: Stage<AnyScope>;
}) => {
  const { t } = useTranslation('translation');

  return stage ? (
    <StageName stage={stage} index={index} />
  ) : (
    <>{t('request.stageApprovals.draftStage')}</>
  );
};
