import * as React from 'react';
import { compact } from 'lodash';
import { CellProps } from 'react-table';
import { Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import {
  Approval,
  ApprovalRequest,
  StageApprovalStatus,
  getApprovalRequestStatus,
} from '@deepstream/common/rfq-utils';
import {
  OpenApprovalParamValue,
  OpenApprovalParam,
} from '@deepstream/common/stageApprovals';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { withProps } from '@deepstream/ui-utils/withProps';
import { ProgressCircle } from '@deepstream/ui-kit/elements/ProgressCircle';
import { Button, RespondButton } from '@deepstream/ui-kit/elements/button/Button';
import { StageApprovalExchangeSnapshot } from '../../types';
import { BasicTableStyles } from '../../TableStyles';
import { Table } from '../../Table';
import { useLabeledApprovalResponseConfig, useLabeledStageApprovalsStatusesConfig } from '../../status';
import { TruncateCell } from '../../TruncateCell';
import { TextCell } from '../../TextCell';
import { StatusIconText } from '../../ExchangeStatusCell';
import { Avatar } from '../../Avatar';
import { useModalState } from '../../ui/useModalState';
import { Approvers } from './Approvers';
import { SuppliersToApprove } from './SuppliersToApprove';
import { ViewResponseModal } from './ViewResponseModal';
import { useCurrentUserApproval } from './useCurrentUserApproval';
import { useResponsesCountConfig } from './useResponsesCountConfig';
import { ViewApprovalModal } from './ViewApprovalModal';
import { ExchangeProvider } from '../../useExchange';
import { SubmitResponseModal } from './SubmitResponseModal';
import { useToaster } from '../../toast';

const StageApprovalModalsContext = React.createContext(null as any);

const StageApprovalStatusCell: React.FC<CellProps<ApprovalRequest, StageApprovalStatus>> = ({
  cell: { value },
  config,
}) => {
  const { label, icon } = React.useMemo(() => config[value], [config, value]);

  return <StatusIconText label={label} icon={icon} />;
};

const SuppliersToApproveCell: React.FC<CellProps<ApprovalRequest, string[]>> =
  ({ cell: { value: companyIds } }) => {
    return <SuppliersToApprove companyIds={companyIds} />;
  };

export const ApproversCell: React.FC<CellProps<ApprovalRequest, Approval[]>> = ({
  cell: { value: approvals },
}) => {
  return <Approvers approverIds={approvals.map((approval) => approval.userId)} />;
};

const ResponsesCell: React.FC<CellProps<ApprovalRequest, Approval[]>> = ({
  cell: { value: approvals },
}) => {
  const responsesCountConfig = useResponsesCountConfig(approvals);

  const responsesCount = responsesCountConfig.approved.count + responsesCountConfig.rejected.count;
  const approvedPercentage = responsesCount / approvals.length;

  return (
    <Flex sx={{ columnGap: '24px' }}>
      <Flex sx={{ columnGap: '6px' }}>
        <Flex
          minWidth="18px"
          height="18px"
          justifyContent="center"
          alignItems="center"
        >
          <ProgressCircle progress={approvedPercentage} width={18} />
        </Flex>

        <Text>
          {responsesCount}/{approvals.length}
        </Text>
      </Flex>

      <Flex sx={{ columnGap: '10px' }}>
        {
          Object.values(responsesCountConfig).map((config, index) => (
            <StatusIconText
              key={index}
              label={String(config.count)}
              icon={config.icon}
            />
          ))
        }
      </Flex>
    </Flex>
  );
};

const CurrentUserApprovalCell: React.FC<
  CellProps<StageApprovalExchangeSnapshot, StageApprovalExchangeSnapshot & { status: StageApprovalStatus }>
> = ({ cell: { value: approvalRequest }, currentUser }) => {
  const { t } = useTranslation();

  const { approval: approvalResponse } = useCurrentUserApproval(approvalRequest, currentUser._id);
  const { submitResponseModalState, viewResponseModalState, setSelectedExchange } = React.useContext(StageApprovalModalsContext);

  const approvalCancelledWithoutResponse = approvalResponse?.status === 'pending' && approvalRequest.status === StageApprovalStatus.CANCELLED;
  const approvalResponseStatusConfig = useLabeledApprovalResponseConfig({
    pendingStatusAlternative: approvalCancelledWithoutResponse ? 'none' : 'required',
  });

  return approvalResponse ? (
    <Flex justifyContent="space-between">
      <StatusIconText
        label={approvalResponseStatusConfig[approvalResponse.status].label}
        icon={approvalResponseStatusConfig[approvalResponse.status].icon}
      />
      {!approvalCancelledWithoutResponse &&
        (approvalResponse.status === 'pending' ? (
          <RespondButton
            small
            onClick={(event) => {
              setSelectedExchange(approvalRequest);
              submitResponseModalState.open();
              event.stopPropagation();
            }}
          />
        ) : (
          <Button
            small
            variant="secondary-outline"
            onClick={(event) => {
              setSelectedExchange(approvalRequest);
              viewResponseModalState.open();
              event.stopPropagation();
            }}
          >
            {t('general.open')}
          </Button>
        ))}
    </Flex>
  ) : (
    <Text color="subtext">{t('general.notApplicableShort')}</Text>
  );
};

export type StageApprovalsTableProps = {
  approvalRequests: StageApprovalExchangeSnapshot[];
  currentUser: { _id: string };
  canEditApprovals: boolean;
  canRequestStageApproval: boolean;
  isApprover: boolean;
  openRequestApprovalModal: () => void;
  showApprovalModal?: boolean;
};

export const StageApprovalsTable: React.FC<StageApprovalsTableProps> = ({
  approvalRequests,
  currentUser,
  canEditApprovals,
  canRequestStageApproval,
  isApprover,
  openRequestApprovalModal,
}) => {
  const { t } = useTranslation('translation');
  const theme = useTheme();
  const toaster = useToaster();
  const navigate = useNavigate();

  const labeledStageApprovalsStatusesConfig =
    useLabeledStageApprovalsStatusesConfig();

  const [selectedExchange, setSelectedExchange] = React.useState<StageApprovalExchangeSnapshot | null>(null);

  const submitResponseModalState = useModalState();
  const viewResponseModalState = useModalState();
  const viewApprovalModalState = useModalState();
  const stageApprovalModalsContext = React.useMemo(() => {
    return {
      setSelectedExchange,
      submitResponseModalState,
      viewResponseModalState,
      viewApprovalModalState,
    };
  }, [submitResponseModalState, viewResponseModalState, viewApprovalModalState]);

  const handleRowClick = React.useCallback(
    (approvalRequest: StageApprovalExchangeSnapshot) => {
      setSelectedExchange(approvalRequest);

      if (canEditApprovals) {
        viewApprovalModalState.open();
      } else {
        const currentUserApproval = approvalRequest.approvals.find(
          (approval) => approval.userId === currentUser._id,
        );
        if (currentUserApproval?.status === 'pending') {
          submitResponseModalState.open();
        } else {
          viewResponseModalState.open();
        }
      }
    },
    [
      canEditApprovals,
      currentUser._id,
      viewApprovalModalState,
      viewResponseModalState,
      submitResponseModalState,
    ],
  );

  const { openApprovalModal, approvalRequestId } = useSearch({ strict: false }) as {
    openApprovalModal?: OpenApprovalParam;
    approvalRequestId?: string;
  };

  React.useEffect(() => {
    if (openApprovalModal && approvalRequestId && approvalRequests) {
      const approvalRequest = approvalRequests.find(
        (approvalRequest) => approvalRequest._id === approvalRequestId,
      );
      // @ts-expect-error ts(18048) FIXME: 'approvalRequest' is possibly 'undefined'.
      const approval = approvalRequest.approvals.find(
        (approval) => approval.userId === currentUser._id,
      );

      if (!approvalRequest || (!approval && openApprovalModal === OpenApprovalParamValue.submitResponse)) {
        toaster.error(t('error.somethingWentWrong', { ns: 'supplierDiscovery' }));
      } else {
        setSelectedExchange(approvalRequest);
        if (openApprovalModal === OpenApprovalParamValue.submitResponse) {
          submitResponseModalState.open();
        } else if (openApprovalModal === OpenApprovalParamValue.viewApproval) {
          viewApprovalModalState.open();
        }
      }
      navigate({ search: {} });
    }
  }, [
    openApprovalModal,
    approvalRequestId,
    approvalRequests,
    submitResponseModalState,
    viewApprovalModalState,
    currentUser._id,
    toaster,
    t,
    navigate,
  ]);

  const columns = React.useMemo(() => {
    return compact([
      {
        Header: t('general.description'),
        accessor: 'def.message',
        Cell: TruncateCell,
        width: 170,
        minWidth: 130,
        maxWidth: 400,
      },
      {
        Header: t('general.stage', { count: 1 }),
        accessor: (approval: StageApprovalExchangeSnapshot & { stageIndex: number }) =>
          approval.def.stageId ? approval.stageIndex : t('request.stageApprovals.draftStage'),
        Cell: TextCell,
        width: 60,
      },
      {
        Header: t('general.status'),
        accessor: (approval: StageApprovalExchangeSnapshot) =>
          getApprovalRequestStatus(approval.status),
        Cell: withProps(StageApprovalStatusCell, {
          config: labeledStageApprovalsStatusesConfig,
        }),
        width: 130,
      },
      {
        Header: t('request.stageApprovals.suppliersToApprove'),
        accessor: 'def.recipientIds',
        Cell: SuppliersToApproveCell,
        width: 170,
      },
      {
        Header: t('request.stageApprovals.approver_other'),
        accessor: 'approvals',
        Cell: ApproversCell,
        width: 170,
      },
      {
        id: 'responses',
        Header: t('request.stageApprovals.responses'),
        accessor: 'approvals',
        Cell: ResponsesCell,
        width: 200,
      },
      {
        id: 'currentUserApproval',
        Header: (
          <Flex sx={{ columnGap: '8px' }} alignItems="center">
            <Avatar
              userId={currentUser._id}
              width="xs"
              style={{ boxShadow: 'none' }}
            />
            <Text>
              {t('request.stageApprovals.yourResponse')}
            </Text>
          </Flex>
        ),
        accessor: (approval: StageApprovalExchangeSnapshot) => {
          return {
            ...approval,
            status: getApprovalRequestStatus(approval.status),
          };
        },
        Cell: withProps(CurrentUserApprovalCell, {
          currentUser,
        }),
        width: 200,
      },
    ]);
  }, [t, labeledStageApprovalsStatusesConfig, currentUser]);

  const emptyTableContent = canRequestStageApproval ? (
    <>
      <Text color="subtext" fontSize={2} mb="10px">
        {t('request.stageApprovals.requestApprovalDescription')}
      </Text>
      <Button small iconLeft="plus" onClick={() => openRequestApprovalModal()}>
        {t('request.stageApprovals.newApproval')}
      </Button>
    </>
  ) : (
    <Text color="subtext" fontSize={2} mb="10px">
      {t('request.stageApprovals.noApprovals')}
    </Text>
  );

  return approvalRequests.length ? (
    <ExchangeProvider exchange={selectedExchange}>
      {submitResponseModalState.isOpen && (
        <SubmitResponseModal
          currentUserId={currentUser._id}
          close={submitResponseModalState.close}
          isOpen={submitResponseModalState.isOpen}
        />
      )}
      {viewResponseModalState.isOpen && (
        <ViewResponseModal
          currentUserId={currentUser._id}
          handleEditResponse={() => {
            viewResponseModalState.close();
            submitResponseModalState.open();
          }}
          close={viewResponseModalState.close}
          isOpen={viewResponseModalState.isOpen}
        />
      )}
      {viewApprovalModalState.isOpen && (
        <ViewApprovalModal
          currentUserId={currentUser._id}
          handleEditResponse={() => {
            viewResponseModalState.close();
            submitResponseModalState.open();
          }}
          close={viewApprovalModalState.close}
          isOpen={viewApprovalModalState.isOpen}
        />
      )}
      <BasicTableStyles
        headerTextColor={theme.colors.lightNavy}
        headerBackgroundColor={theme.colors.lightGray3}
        customRowHeight="40px"
        cellPaddingX="10px"
        bordered
      >
        <StageApprovalModalsContext.Provider value={stageApprovalModalsContext}>
          <Table
            columns={columns}
            data={approvalRequests}
            onRowClick={handleRowClick}
            hiddenColumns={
              !canEditApprovals
                ? ['approvals']
                : !isApprover
                ? ['currentUserApproval']
                : []
            }
          />
        </StageApprovalModalsContext.Provider>
      </BasicTableStyles>
    </ExchangeProvider>
  ) : (
    emptyTableContent
  );
};
