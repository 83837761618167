import { compact } from 'lodash';
import { ExchangeType } from '@deepstream/common/rfq-utils';
import { useCurrentCompanyId } from '../currentCompanyId';
import { useRecipientId } from '../useRfq';
import { useExchange } from '../useExchange';
import * as rfx from '../rfx';

export const useIsChatWithAllowedAttachments = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const recipientId = useRecipientId({ required: false });
  const isRecipient = currentCompanyId === recipientId;
  const exchange = useExchange();
  const { settings } = rfx.useStructure();

  return isRecipient
    ? (
        compact([
          ExchangeType.CHAT_ONLY_RECEIVER_UPLOAD,
          ExchangeType.CHAT,
          settings.allowClarificationAttachments
            ? ExchangeType.CLARIFICATION
            : null,
        ]) as Array<ExchangeType>
      ).includes(exchange.def.type)
    : [
        ExchangeType.CHAT_NO_RECEIVER_UPLOAD,
        ExchangeType.CHAT,
        ExchangeType.CLARIFICATION,
      ].includes(exchange.def.type);
};
