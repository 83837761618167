import { useMemo, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { filter, first, isEqual, keys, map, pick, values } from 'lodash';
import { Flex, Text } from 'rebass/styled-components';
import { Form, Formik, useField } from 'formik';
import * as yup from 'yup';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { QUESTIONS_PAGE_ID, QuestionnaireStatus } from '@deepstream/common/preQual';
import { Button, CancelButton, EditButton, SaveButton } from '@deepstream/ui-kit/elements/button/Button';
import { callAll } from '@deepstream/utils/callAll';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { PageRole } from '@deepstream/common/rfq-utils';
import { PropertyList, ValueOrEmDash } from '../../../../PropertyList';
import { SelectedUsersAvatarList } from '../../../../SelectedUsersAvatarList';
import { useQuestionnaireData, useIsSender, useQuestionnaireState, useQuestionnairePermissions, useQuestionnaireActions } from '../questionnaireUtils';
import { LeavePageModal } from '../../../../draft/LeavePageModal';
import { LabelConfig, LabelConfigProvider } from '../../../../LabelConfigProvider';
import { FieldContainer } from '../../../../form/FieldContainer';
import { useFetchSupplierCompany } from '../../../RequestSuppliers/SuppliersContext';
import { useConfirmDialog, useModalState } from '../../../../ui/useModalState';
import { SupplierModalForm } from '../../../RequestSuppliers/SuppliersPanel';
import { CompanyProfile, SupplierStateUser } from '../../../RequestSuppliers/types';
import { Avatar } from '../../../../Avatar';
import { Bold } from '../../../../Bold';
import { useAddRecipientTeamMembers } from '../questionnaireMutations';

const panelId = 'supplier';

const verticallyCenteredLabelStyle = {
  fontSize: 2,
  position: 'relative',
  top: '10px',
};

const ConfirmSendQuestionnaireModal = ({
  ...props
}: {
  isOpen: boolean;
  onOk: () => void;
  onCancel?: () => void;
}) => {
  const { t } = useTranslation(['preQualification', 'general']);
  const { recipient, teamById } = useQuestionnaireData();
  const [{ value: selectedUsers }] = useField('users');

  const newUsers = useMemo(
    () => {
      const recipientUserIds = keys(teamById[recipient._id].users);
      return filter(selectedUsers, user => !recipientUserIds.includes(user._id));
    },
    [teamById, recipient._id, selectedUsers],
  );

  return (
    <Dialog
      body={
        <Stack gap={3}>
          <MessageBlock variant="info" mt={0}>
            {t('questionnaire.confirmSendQuestionnaireModal.info')}
          </MessageBlock>
          <Bold>
            {t('questionnaire.confirmSendQuestionnaireModal.supplierUsers')}
          </Bold>
          {newUsers.map(user => (
            <Flex key={user._id} alignItems="center">
              <Avatar userId={user._id} sx={{ flex: '0 0 auto' }} />
              <Text ml={3}>
                <Truncate>
                  {user.name || user.email}
                </Truncate>
              </Text>
            </Flex>
          ))}
        </Stack>
      }
      style={{ content: { minWidth: '390px', maxWidth: '390px' } }}
      maxBodyHeight="500px"
      okButtonText={t('questionnaireTemplate.sendQuestionnaire')}
      okButtonVariant="primary"
      cancelButtonText={t('cancel', { ns: 'general' })}
      heading={t('questionnaire.confirmSendQuestionnaireModal.heading')}
      {...props}
    />
  );
};

const RecipientUsersField = () => {
  const { t } = useTranslation(['preQualification', 'general', 'translation']);
  const [company, setCompany] = useState<CompanyProfile>();
  const [users, setUsers] = useState<SupplierStateUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [usersField,, usersFormik] = useField('users');
  const selectUsersModal = useModalState();
  const fetchSupplierCompany = useFetchSupplierCompany();
  const { recipient, teamById } = useQuestionnaireData();

  const existingUserIds = useMemo(
    () => keys(teamById[recipient._id].users),
    [teamById, recipient._id],
  );

  const fetchSupplier = useCallback(
    async (recipientId: string) => {
      setIsLoading(true);

      try {
        const { company, users } = await fetchSupplierCompany({ companyId: recipientId, role: 'receiveQuestionnaires' });
        const selectedUserIds = map(usersField.value, user => user._id);

        const supplierUsers: SupplierStateUser[] = map(
          users,
          user => ({
            ...user,
            selected: selectedUserIds.includes(user._id),
          }),
        );

        setCompany(company);
        setUsers(supplierUsers);

        setIsLoading(false);
      } catch (error) {} // eslint-disable-line no-empty

      setIsLoading(false);
    },
    [setIsLoading, usersField.value, fetchSupplierCompany],
  );

  useEffect(
    () => {
      fetchSupplier(recipient._id);
    },
    [fetchSupplier, recipient._id],
  );

  return (
    <>
      <FieldContainer
        name="users"
        label={t('user_other', { ns: 'general' })}
        width="100%"
        showAsterisk
      >
        <Flex justifyContent="space-between">
          <SelectedUsersAvatarList users={usersField.value} withValidation={false} />
          <Button
            type="button"
            variant="secondary"
            onClick={selectUsersModal.open}
            disabled={isLoading}
          >
            {t('questionnaire.editUsers')}
          </Button>
        </Flex>
      </FieldContainer>
      {selectUsersModal.isOpen && (
        <SupplierModalForm
          company={company}
          users={users}
          disabledUserIds={existingUserIds}
          isOpen
          heading={t('supplierUsersModal.supplier', { ns: 'translation' })}
          onCancel={() => {
            selectUsersModal.close();
          }}
          onSubmit={(values) => {
            const selectedUsers = filter(values.users, user => user.selected);

            usersFormik.setValue(selectedUsers);
            selectUsersModal.close();
          }}
        />
      )}
    </>
  );
};

const UsersAvatarListValue = ({ value }) => (
  <SelectedUsersAvatarList users={value} withValidation={false} />
);

export const QuestionnaireCompanyPanel = () => {
  const { t } = useTranslation(['preQualification', 'general']);
  const questionnaire = useQuestionnaireData();
  const isSender = useIsSender();
  // @ts-expect-error ts(2339) FIXME: Property 'editingPanelId' does not exist on type 'QuestionnaireStateContextType | undefined'.
  const { editingPanelId } = useQuestionnaireState();
  const { startEditing, stopEditing } = useQuestionnaireActions();
  const { canEditSupplierUsers } = useQuestionnairePermissions();
  const { confirm, ...dialogProps } = useConfirmDialog();
  const [addTeamMembers] = useAddRecipientTeamMembers({
    onSettled: stopEditing,
  });

  const isEditingOtherPanel = editingPanelId && editingPanelId !== panelId;
  const isEditingThisPanel = editingPanelId && editingPanelId === panelId;
  const isEditingAnyPanel = Boolean(editingPanelId);

  const { _id: recipientId, name: recipientName } = questionnaire.recipient;
  // @ts-expect-error ts(2339) FIXME: Property '_id' does not exist on type '{ _id: string; name: string; } | undefined'.
  const { _id: senderId, name: senderName } = first(questionnaire.senders);

  const recipientUsers = useMemo(
    () => values(questionnaire.teamById[recipientId].users),
    [questionnaire, recipientId],
  );

  const recipientUserIds = useMemo(
    () => map(recipientUsers, user => user._id),
    [recipientUsers],
  );

  const senderUsers = useMemo(
    () => values(questionnaire.teamById[senderId].users),
    [questionnaire, senderId],
  );

  const properties = useMemo(() => {
    return [
      {
        fieldName: 'companyName',
        name: t('company', { ns: 'general' }),
        value: isSender ? recipientName : senderName,
        Component: ValueOrEmDash,
      },
      {
        fieldName: 'users',
        name: t('user_other', { ns: 'general' }),
        value: isSender ? recipientUsers : senderUsers,
        Component: UsersAvatarListValue,
      },
    ];
  }, [
    t,
    recipientUsers,
    recipientName,
    senderName,
    senderUsers,
    isSender,
  ]);

  const heading = isSender ? t('supplier', { count: 1 }) : t('questionnaire.buyer', { count: 1 });

  const canEdit = canEditSupplierUsers && questionnaire.status === QuestionnaireStatus.SENT;

  return (
    <Panel
      as="section"
      aria-label={heading}
      sx={{
        opacity: isEditingOtherPanel ? 0.5 : 1,
        boxShadow: isEditingThisPanel ? '0 0 8px 0 rgba(0, 0, 0, 0.3)' : '',
      }}
    >
      <PanelHeader heading={heading}>
        {isEditingThisPanel || !canEdit ? (
          null
        ) : (
          <EditButton
            small
            type="button"
            onClick={() => startEditing(panelId)}
            disabled={isEditingAnyPanel}
          />
        )}
      </PanelHeader>
      <PanelDivider />
      {isEditingThisPanel ? (
        <Formik
          validateOnBlur
          enableReinitialize
          initialValues={{
            users: recipientUsers,
          }}
          validationSchema={
            yup.object().shape({
              users: yup
                .array()
                .of(yup.object().shape({
                  _id: yup.string(),
                  name: yup.string(),
                })),
            })
          }
          onSubmit={async ({ users }) => {
            confirm(() => {
              const existingUserIds = map(recipientUsers, user => user._id);

              const payload = map(
                filter(users, user => !existingUserIds.includes(user._id)),
                user => ({
                  ...pick(user, ['_id', 'name']),
                  isOwner: true,
                  roles: {
                    [QUESTIONS_PAGE_ID]: PageRole.RESPONDER,
                  },
                }),
              );

              return addTeamMembers({
                users: payload,
                companyId: recipientId,
              });
            });
          }}
        >
          {({ isSubmitting, dirty, isValid, values, resetForm }) => (
            <Form>
              <Stack gap={20} m={20}>
                <LabelConfigProvider
                  variant={LabelConfig.LEFT}
                  width="200px"
                  style={{
                    supplier: { fontSize: 2 },
                    users: verticallyCenteredLabelStyle,
                  }}
                >
                  <FieldContainer
                    name="supplier"
                    label={t('company', { ns: 'general' })}
                  >
                    {recipientName}
                  </FieldContainer>
                  <RecipientUsersField />
                </LabelConfigProvider>
              </Stack>
              <PanelDivider />
              <PanelPadding>
                <Flex justifyContent="flex-end">
                  <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
                  <SaveButton
                    disabled={(
                      isSubmitting || !dirty || !isValid ||
                      isEqual(map(values.users, user => user._id), recipientUserIds)
                    )}
                  />
                </Flex>
              </PanelPadding>
              <LeavePageModal />
              {dialogProps.isOpen && (
                <ConfirmSendQuestionnaireModal {...dialogProps} />
              )}
            </Form>
          )}
        </Formik>
      ) : (
        <PropertyList properties={properties} />
      )}
    </Panel>
  );
};
