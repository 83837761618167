import { isDocumentExchangeDefinition } from '@deepstream/common/contract/contract';
import {
  SectionType,
  isLineItemExchangeDef,
  LineItemExchangeDefinition,
  DocumentExchangeDefinition,
  isAuctionLineItemExchangeDef,
  AuctionLineItemExchangeDefinition,
  ExchangeType, isLinkedAuctionLineItemExchangeDef,
  AwardDecision,
  AwardScenario,
} from '@deepstream/common/rfq-utils';
import { useFormikContext } from 'formik';
import { pickBy, reduce, find, includes, keys, filter, difference, identity, first, groupBy, uniq, intersection, isEmpty } from 'lodash';
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { callAll } from '@deepstream/utils/callAll';
import { useApi } from '../../../api';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import * as rfx from '../../../rfx';
import { useToaster } from '../../../toast';

import { useMutation } from '../../../useMutation';
import { useContractData } from '../contract';
import { useContractQueryKey } from '../useContract';

export type ImportFormValues = Record<string, boolean>;

export type ExchangeDefsToImport = LineItemExchangeDefinition | DocumentExchangeDefinition | AuctionLineItemExchangeDefinition;

const useAwardedExchangeIds = () => {
  const rfxStructure = rfx.useStructure();
  const { awardScenario, awardDecisionByLotId, awardDecisionByExchangeId } = rfxStructure.meta.outcomeDetails || {};
  const { linkedItemsPairs } = useLinkedLineItems();

  const { recipients } = useContractData();
  // @ts-expect-error ts(2339) FIXME: Property '_id' does not exist on type 'ContractRecipient | undefined'.
  const { _id: recipientId } = first(recipients);

  const lineItemExchangeDefs = Object.values(rfxStructure.exchangeDefById)
    .filter((exchangeDef) =>
      [ExchangeType.LINE_ITEM, ExchangeType.AUCTION_LINE_ITEM].includes(exchangeDef.type) && !exchangeDef.isObsolete,
    );

  return useMemo(() => {
    switch (awardScenario) {
      case AwardScenario.LINE_LEVEL_AWARD: {
        // @ts-expect-error ts(2769) FIXME: No overload matches this call.
        return Object.values(awardDecisionByExchangeId)
          // @ts-expect-error ts(18047) FIXME: 'awardDecision' is possibly 'null'.
          .filter(awardDecision => awardDecision.value === 'award')
          .reduce((exchangeIds: string[], awardDecision) => {
            const award = find(
              (awardDecision as Extract<AwardDecision, { value: 'award' }>).awards,
              award => award.recipientId === recipientId,
            );
            if (award) {
              // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
              exchangeIds.push(award.awardedExchangeId);
              // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
              const linkedLineItemId = linkedItemsPairs.linkedLineItemToAuctionDefIdsMap[award.awardedExchangeId];
              if (linkedLineItemId) {
                exchangeIds.push(linkedLineItemId);
              }
            }
            return exchangeIds;
          }, []);
      }
      case AwardScenario.LOT_LEVEL_AWARD: {
        // @ts-expect-error ts(2769) FIXME: No overload matches this call.
        return Object.entries(awardDecisionByLotId)
          // @ts-expect-error ts(18047) FIXME: 'awardDecision' is possibly 'null'.
          .filter(([, awardDecision]) => awardDecision.value === 'award')
          .reduce((exchangeIds: string[], [lotId, awardDecision]) => {
            const award = first((awardDecision as Extract<AwardDecision, { value: 'award' }>).awards);
            // @ts-expect-error ts(18048) FIXME: 'award' is possibly 'undefined'.
            if (award.recipientId === recipientId) {
              // we don't access `section.exchangeDefIds` for this because it doesn't
              // include IDs of supplier-provided exchanges, which we want to include
              // here
              const lineItemExchangeDefsByLotId = groupBy(
                lineItemExchangeDefs,
                // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
                exchangeDef => first(rfxStructure.sectionById[exchangeDef.sectionId].lotIds),
              );
              const awardedLotExchangeDefIds = lineItemExchangeDefsByLotId[lotId].map(exchangeDef => exchangeDef._id);
              exchangeIds.push(...awardedLotExchangeDefIds);
            }
            return exchangeIds;
          }, []);
      }
      case AwardScenario.REQUEST_LEVEL_AWARD:
      default: {
        return lineItemExchangeDefs.map(exchangeDef => exchangeDef._id);
      }
    }
  }, [awardDecisionByExchangeId, awardDecisionByLotId, awardScenario, lineItemExchangeDefs, linkedItemsPairs, recipientId, rfxStructure]);
};

const useIsAwardedExchangeDef = () => {
  const awardedExchangeIds = useAwardedExchangeIds();

  return useCallback((exchangeDef: ExchangeDefsToImport) => {
    return awardedExchangeIds.includes(exchangeDef._id);
  }, [awardedExchangeIds]);
};

const useIsGeneralRequirement = () => {
  const rfxStructure = rfx.useStructure();

  return useCallback((exchangeDef: ExchangeDefsToImport) => {
    // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
    const section = rfxStructure.sectionById[exchangeDef.sectionId];
    return rfxStructure.settings.areLotsEnabled && !section.lotIds;
  }, [rfxStructure.sectionById, rfxStructure.settings.areLotsEnabled]);
};

export const useExchangeDefsToPick = () => {
  const rfxStructure = rfx.useStructure();
  const isAwardedExchangeDef = useIsAwardedExchangeDef();
  const isGeneralRequirement = useIsGeneralRequirement();

  return useCallback((
    filter: ((exchangeDef: ExchangeDefsToImport) => boolean) = identity,
  ) => {
    const { exchangeDefById } = rfxStructure;

    return pickBy(exchangeDefById, (exchangeDef): exchangeDef is ExchangeDefsToImport => {
      return !exchangeDef.isObsolete && (
        isDocumentExchangeDefinition(exchangeDef) ||
        (isLineItemExchangeDef(exchangeDef) && (isAwardedExchangeDef(exchangeDef) || isGeneralRequirement(exchangeDef))) ||
        (isAuctionLineItemExchangeDef(exchangeDef) && (isAwardedExchangeDef(exchangeDef)))
      ) && filter(exchangeDef);
    });
  }, [isAwardedExchangeDef, isGeneralRequirement, rfxStructure]);
};

export const usePageExchanges = (pageId: string) => {
  const { values } = useFormikContext<ImportFormValues>();
  const rfxStructure = rfx.useStructure();
  const pickExchangeDefs = useExchangeDefsToPick();

  return useMemo(() => {
    const { pages } = rfxStructure;
    // @ts-expect-error ts(2339) FIXME: Property 'sections' does not exist on type 'Page | undefined'.
    const { sections } = find(pages, (page) => page._id === pageId);

    const exchanges = pickExchangeDefs(
      (exchangeDef) => includes(sections, exchangeDef.sectionId),
    );
    const exchangesIds = keys(exchanges);

    const selectedExchanges = pickBy(values, (value, exchangeId) => (
      includes(exchangesIds, exchangeId) && value
    ));
    const selectedIds = keys(selectedExchanges);

    return { exchangesIds, selectedIds, exchanges } as const;
  }, [rfxStructure, pageId, values, pickExchangeDefs]);
};

export const useSectionExchanges = (
  sectionId: string,
) => {
  const { values } = useFormikContext<ImportFormValues>();
  const pickExchangeDefs = useExchangeDefsToPick();

  return useMemo(() => {
    const exchanges = pickExchangeDefs(
      (exchangeDef) => exchangeDef.sectionId === sectionId,
    );
    const exchangesIds = keys(exchanges);

    const selectedExchanges = pickBy(values, (value, exchangeId) => (
      includes(exchangesIds, exchangeId) && value
    ));
    const selectedIds = keys(selectedExchanges);

    return { exchangesIds, selectedIds, exchanges } as const;
  }, [sectionId, values, pickExchangeDefs]);
};

export const useExchanges = () => {
  const { values } = useFormikContext<ImportFormValues>();
  const pickExchangeDefs = useExchangeDefsToPick();

  return useMemo(() => {
    const exchanges = pickExchangeDefs();
    const exchangesIds = keys(exchanges);

    const selectedExchanges = pickBy(values, (value, exchangeId) => (
      includes(exchangesIds, exchangeId) && value
    ));
    const selectedIds = keys(selectedExchanges);

    return { exchangesIds, selectedIds, exchanges } as const;
  }, [values, pickExchangeDefs]);
};

export const useUpdateExchangesBulk = () => {
  const { setValues } = useFormikContext<ImportFormValues>();

  return useCallback((exchangesIds: string[], value: boolean) => {
    const updated = reduce(exchangesIds, (accumulator, exchangeId) => ({
      ...accumulator,
      [exchangeId]: value,
    }), {} as ImportFormValues);

    setValues((prev) => ({
      ...prev,
      ...updated,
    }));
  }, [setValues]);
};

const useVisibleSectionIds = () => {
  const pickExchangeDefs = useExchangeDefsToPick();

  return useMemo(() => {
    const exchanges = pickExchangeDefs();
    return uniq(Object.values(exchanges).map(exchange => exchange.sectionId));
  }, [pickExchangeDefs]);
};

export const useVisiblePages = () => {
  const { pageById } = rfx.useStructure();
  const visibleSectionIds = useVisibleSectionIds();

  return useMemo(() => {
    return filter(pageById, (page) => !isEmpty(intersection(page.sections, visibleSectionIds)));
  }, [pageById, visibleSectionIds]);
};

export const usePageVisibleSectionsIds = (pageId: string) => {
  const rfxStructure = rfx.useStructure();
  const visibleSectionIds = useVisibleSectionIds();

  return useMemo(() => {
    const { sections } = rfxStructure.pageById[pageId];

    return filter(sections, (sectionId) => {
      const section = rfxStructure.sectionById[sectionId];

      return (
        includes([SectionType.DOCUMENT, SectionType.LINE_ITEMS, SectionType.AUCTION_LINE_ITEMS], section.type) &&
        includes(visibleSectionIds, sectionId)
      );
    });
  }, [rfxStructure.pageById, rfxStructure.sectionById, pageId, visibleSectionIds]);
};

export const useLinkedLineItems = () => {
  const { exchangeDefById } = rfx.useStructure();

  return useMemo(() => {
    const linkedItemsPairs = reduce(exchangeDefById, (accumulator, exchangeDef) => {
      if (isLinkedAuctionLineItemExchangeDef(exchangeDef)) {
        accumulator.linkedAuctionToLineItemDefIdsMap = {
          ...accumulator.linkedAuctionToLineItemDefIdsMap,
          [exchangeDef.linkedExchangeDefId]: exchangeDef._id,
        };

        accumulator.linkedLineItemToAuctionDefIdsMap = {
          ...accumulator.linkedLineItemToAuctionDefIdsMap,
          [exchangeDef._id]: exchangeDef.linkedExchangeDefId,
        };
      }

      return accumulator;
    }, {
      // Auction Line Item Def Id => Line Item Def Id
      linkedAuctionToLineItemDefIdsMap: {},

      // Line Item Def Id => Auction Line Item Def Id
      linkedLineItemToAuctionDefIdsMap: {},
    } as {
      linkedAuctionToLineItemDefIdsMap: Record<string, string>;
      linkedLineItemToAuctionDefIdsMap: Record<string, string>,
    });

    return {
      linkedItemsPairs,
      linkedAuctionToLineItemDefIds: keys(linkedItemsPairs.linkedAuctionToLineItemDefIdsMap),
      linkedLineItemToAuctionDefIds: keys(linkedItemsPairs.linkedLineItemToAuctionDefIdsMap),
    } as const;
  }, [exchangeDefById]);
};

export const useImportInitialValues = (): ImportFormValues => {
  const request = rfx.useStructure();
  const { importedExchangesIdsMap } = useContractData();
  const { linkedAuctionToLineItemDefIds } = useLinkedLineItems();
  const isAwardedExchangeDef = useIsAwardedExchangeDef();
  const isGeneralRequirement = useIsGeneralRequirement();

  const { exchangeDefById } = request;

  const exchanges = pickBy(exchangeDefById, (exchangeDef): exchangeDef is ExchangeDefsToImport => (
    isDocumentExchangeDefinition(exchangeDef) ||
    (isLineItemExchangeDef(exchangeDef) && (isAwardedExchangeDef(exchangeDef) || isGeneralRequirement(exchangeDef))) ||
    (isAuctionLineItemExchangeDef(exchangeDef) && isAwardedExchangeDef(exchangeDef))
  ));

  const hasImportedExchanges = keys(importedExchangesIdsMap).length;

  return reduce(exchanges, (accumulator, exchange) => {
    if (hasImportedExchanges) {
      return {
        ...accumulator,
        [exchange._id]: !!importedExchangesIdsMap[exchange._id],
      };
    }

    return {
      ...accumulator,
      [exchange._id]: !includes(linkedAuctionToLineItemDefIds, exchange._id),
    };
  }, {} as ImportFormValues);
};

export const useImportDetails = () => {
  const { t } = useTranslation('contracts');
  const toaster = useToaster();
  const api = useApi();
  const queryClient = useQueryClient();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { _id: contractId } = useContractData();
  const contractQueryKey = useContractQueryKey({
    contractId,
    currentCompanyId,
    scope: 'draft',
  });

  const [importDetailsFromRequest] = useMutation(api.importDetailsFromRequest);

  return useCallback(({
    exchangeIds,
    onSettled = [],
  }: {
    exchangeIds: string[],
    onSettled?: (() => void)[]
  }) => {
    return importDetailsFromRequest({
      contractId,
      currentCompanyId,
      exchangeIds,
    }, {
      onSuccess: () => toaster.success(t('toaster.contentImported.success')),
      onError: () => toaster.error(t('toaster.contentImported.failed')),
      onSettled: callAll(
        ...onSettled,
        () => queryClient.invalidateQueries(contractQueryKey),
      ),
    });
  }, [
    importDetailsFromRequest,
    contractId,
    currentCompanyId,
    toaster,
    t,
    queryClient,
    contractQueryKey,
  ]);
};

export const useAvailableExchangesIdToImport = (exchangesToCheck: string[]) => {
  const { importedExchangesIdsMap } = useContractData();
  const { selectedIds } = useExchanges();
  const { linkedItemsPairs } = useLinkedLineItems();

  return useMemo(() => {
    const importedExchangesIds = keys(importedExchangesIdsMap);
    const exchangesWithLinkedSelected = filter(exchangesToCheck, (exchangeIdToCheck) => {
      const linkedLineItem = linkedItemsPairs.linkedAuctionToLineItemDefIdsMap[exchangeIdToCheck] ||
        linkedItemsPairs.linkedLineItemToAuctionDefIdsMap[exchangeIdToCheck];

      return includes(selectedIds, linkedLineItem);
    });

    return difference(exchangesToCheck, [...importedExchangesIds, ...exchangesWithLinkedSelected]);
  }, [importedExchangesIdsMap, exchangesToCheck, linkedItemsPairs, selectedIds]);
};

export const useIsLineItemDefLinkedToAuction = () => {
  const { linkedAuctionToLineItemDefIds } = useLinkedLineItems();

  return useCallback((exchangeDef: ExchangeDefsToImport) => {
    return exchangeDef.type === ExchangeType.LINE_ITEM && linkedAuctionToLineItemDefIds.includes(exchangeDef._id);
  }, [linkedAuctionToLineItemDefIds]);
};

export const useIsLinkedItemSelected = () => {
  const { selectedIds } = useExchanges();
  const { linkedItemsPairs } = useLinkedLineItems();

  return useCallback((exchangeDef: ExchangeDefsToImport) => {
    const linkedItemId = linkedItemsPairs.linkedAuctionToLineItemDefIdsMap[exchangeDef._id] ||
      linkedItemsPairs.linkedLineItemToAuctionDefIdsMap[exchangeDef._id];

    return includes(selectedIds, linkedItemId);
  }, [linkedItemsPairs, selectedIds]);
};
