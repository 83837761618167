import * as React from 'react';
import { useIsFetching, useIsMutating } from 'react-query';
import { find, findIndex } from 'lodash';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import * as rfx from '../rfx';
import { useSwitchToExchange } from './SwitchToExchange';

export const RfxBidAutoAdvancer = ({
  exchangeId,
  setIsLoading,
}: {
  exchangeId: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
  const bid = rfx.useBid();
  const structure = rfx.useStructure();
  const [watchExchangeStateChange, setWatchExchangeStateChange] = React.useState(false);
  const isMutating = useIsMutating(['sendRfxExchangeReply']);
  const { switchToExchange, verticalTargets } = useSwitchToExchange();

  const isFetchingExchange = useIsFetching({ queryKey: ['exchange'] });
  const isFetchingExchanges = useIsFetching({ queryKey: ['exchanges'] });
  const isFetchingStructure = useIsFetching({ queryKey: ['liveRfqStructure'] });

  const isFetchingOrMutating = (
    isMutating ||
    isFetchingExchange ||
    isFetchingExchanges ||
    isFetchingStructure
  );

  const section = find(
    structure.sectionById,
    section => section.exchangeDefIds.includes(exchangeId),
  );

  const exchangeState = !isFetchingOrMutating && bid.sectionById[section?._id]?.exchangeStateById[exchangeId];

  useWatchValue(
    exchangeId,
    () => setWatchExchangeStateChange(false),
  );
  useWatchValue(
    isFetchingOrMutating,
    (isFetchingOrMutating, wasFetchingOrMutating) => {
      if (wasFetchingOrMutating && !isFetchingOrMutating) {
        setIsLoading(false);
        setWatchExchangeStateChange(false);
      }
    },
  );
  useWatchValue(
    isMutating,
    (isMutating, hasBeenMutating) => {
      if (isMutating) {
        setIsLoading(true);
      }

      if (isMutating && !hasBeenMutating) {
        setWatchExchangeStateChange(true);
      }
    },
  );
  useWatchValue(
    exchangeState,
    (exchangeState, previousExchangeState) => {
      if (
        watchExchangeStateChange && (
          (exchangeState?.isResolved && !previousExchangeState?.isResolved) ||
          (!exchangeState?.isRecipientsTurn && previousExchangeState?.isRecipientsTurn)
        )
      ) {
        setWatchExchangeStateChange(false);

        const currentTargetIndex = findIndex(verticalTargets, { exchangeId });
        const nextTarget = [
          // @ts-expect-error ts(18048) FIXME: 'verticalTargets' is possibly 'undefined'.
          ...verticalTargets.slice(currentTargetIndex + 1),
          // @ts-expect-error ts(18048) FIXME: 'verticalTargets' is possibly 'undefined'.
          ...verticalTargets.slice(0, currentTargetIndex),
        ]
          .find(({ exchangeId: nextExchangeId }) => {
            const section = find(
              structure.sectionById,
              // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
              section => section.exchangeDefIds.includes(nextExchangeId),
            );
            if (!section) {
              return false;
            }

            const exchangeState = nextExchangeId
              ? bid.sectionById[section?._id]?.exchangeStateById[nextExchangeId]
              : null;

            if (!exchangeState) {
              return false;
            }

            return exchangeState.isRecipientsTurn && !exchangeState.isResolved;
          });

        if (nextTarget) {
          switchToExchange(nextTarget);
        }
      }
    },
  );

  return null;
};
