import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { compact, difference, first, isEmpty, omit, pickBy } from 'lodash';
import { immutableSet, immutableUpdate } from '@deepstream/utils';
import { SortingDirection } from '@deepstream/common';
import { useLocalStorageState } from '../../../useLocalStorageState';
import { ColumnId, ViewId, useAvailableViewIds, viewConfigById } from './requestSentSuppliersViews';
import { renderLabel } from '../../../RequestsTable';
import { renderTruncatedLabeledSortCriterion, renderLabeledSortDirection, useSortDirectionItems } from '../../../sorting';
import { MultiSortDropdownConfig } from '../../../ui/SortDropdown';

const ignoredColumnIds = [
  'supplier',
] as ColumnId[];

type StoredFilters = Record<string, unknown[]>;

type StoredViewConfig = {
  columnIds: string[];
  sorting: {
    columnId: string;
    direction: SortingDirection;
  }[];
  filters: StoredFilters;
  columnWidths: Record<string, number>;
};

export type StoredSuppliersGridConfig = {
  viewIds: ViewId[];
  configByViewId: Record<Partial<ViewId>, StoredViewConfig>;
};

export type SelectViewItem = { value: ViewId; label: string };
export type SelectColumnItem = { _id: string; label: string };

export const useSuppliersGridConfig = ({
  storageKey,
  availableColumnIds,
  columnConfigById,
}: {
  storageKey: string;
  availableColumnIds: string[];
  columnConfigById: Record<string, { _id: string; label: string } | null>;
}): SuppliersGridConfig => {
  const { t } = useTranslation();
  const availableViewIds = useAvailableViewIds();

  const viewItems = useMemo(() => {
    return availableViewIds.map(viewId => ({
      value: viewId,
      label: t(`request.suppliersTable.views.${viewId}`),
    }));
  }, [t, availableViewIds]);

  const columnItems = useMemo(() => {
    return difference(availableColumnIds, ignoredColumnIds)
      .map(columnId => ({
        _id: columnId,
        label: columnConfigById[columnId]!.label,
      }));
  }, [availableColumnIds, columnConfigById]);

  const sortDirectionItems = useSortDirectionItems(true);

  const [config, setConfig] = useLocalStorageState<StoredSuppliersGridConfig>({
    key: storageKey,
    defaultValue: {
      viewIds: viewItems.slice(0, 1).map(({ value }) => value),
      configByViewId: {} as Record<Partial<ViewId>, StoredViewConfig>,
    },
    mapInitialValue: (initialConfig) => {
      const initialViewId = first(initialConfig?.viewIds);
      const initialConfigByViewId = initialConfig?.configByViewId || {};

      return {
        viewIds: initialViewId && viewItems.some(({ value }) => value === initialViewId) ? (
          [initialViewId]
        ) : !isEmpty(viewItems) ? (
          [first(viewItems)!.value]
        ) : (
          []
        ),
        configByViewId: pickBy(
          initialConfigByViewId,
          (_value, key) => viewItems.some(({ value }) => value === key),
        ) as Record<Partial<ViewId>, StoredViewConfig>,
      };
    },
  });

  return useMemo(() => {
    const selectedViewId = first(config.viewIds)!;

    const viewConfig = selectedViewId
      ? config.configByViewId[selectedViewId]
      : null;

    const availableColumnItems = columnItems.filter(item => (
      !selectedViewId ||
      !viewConfigById[selectedViewId].excludedColumnIds.includes(item._id as ColumnId)
    ));

    const selectedColumnIds = selectedViewId
      ? viewConfig?.columnIds || viewConfigById[selectedViewId].defaultColumnIds
      : [];

    const selectedColumnItems = availableColumnItems.filter(item =>
      selectedColumnIds.includes(item._id as string),
    );

    const sortCriteriaItems = [
      {
        _id: columnConfigById.supplier!._id,
        label: columnConfigById.supplier!.label,
      },
      ...selectedColumnItems,
    ].map(item => ({
      accessor: item._id,
      label: item.label,
    }));

    const selectedSorting = viewConfig?.sorting
      ? viewConfig.sorting
        .map(({ columnId, direction }) => ({
          criterion: sortCriteriaItems.find(item => item.accessor === columnId)!,
          direction: sortDirectionItems.find(item => item.direction === direction)!,
        }))
        .filter(item => item.criterion && item.direction)
      : [];

    return {
      view: {
        itemToString: (item: SelectViewItem | null) => item ? item.label : '',
        items: viewItems,
        selectedItems: compact(config.viewIds.map(viewId => viewItems.find(item => item.value === viewId))),
        onChange: viewItems => setConfig(previousConfig => ({
          ...previousConfig,
          viewIds: viewItems.map(({ value }) => value),
        })),
      },
      columns: {
        itemToString: (item: SelectColumnItem | null) => item ? item._id : '',
        renderItem: renderLabel,
        items: availableColumnItems,
        selectedItems: selectedColumnItems,
        onChange: (columnItems) => {
          if (selectedViewId) {
            setConfig(previousConfig => {
              return immutableSet(
                previousConfig,
                ['configByViewId', selectedViewId, 'columnIds'],
                columnItems.map(({ _id }) => _id),
              );
            });
          }
        },
        idProp: '_id',
      },
      sorting: {
        renderCriteriaItem: renderTruncatedLabeledSortCriterion,
        renderDirectionItem: renderLabeledSortDirection,
        criteriaItems: sortCriteriaItems,
        directionItems: sortDirectionItems,
        selectedSorting,
        onSortingChange: (sorting) => {
          setConfig(previousConfig => {
            return immutableSet(
              previousConfig,
              ['configByViewId', selectedViewId, 'sorting'],
              sorting.map(item => ({
                columnId: item.criterion.accessor,
                direction: item.direction.direction,
              })),
            );
          });
        },
      },
      filters: {
        data: viewConfig?.filters || {},
        update: (filterId, newFilters) => {
          setConfig(previousConfig => {
            if (isEmpty(newFilters)) {
              return immutableUpdate(
                previousConfig,
                ['configByViewId', selectedViewId, 'filters'],
                previousFilters => omit(previousFilters, [filterId]),
              );
            } else {
              return immutableSet(
                previousConfig,
                ['configByViewId', selectedViewId, 'filters', filterId],
                newFilters,
              );
            }
          });
        },
        clear: () => {
          setConfig(previousConfig => {
            return immutableSet(
              previousConfig,
              ['configByViewId', selectedViewId, 'filters'],
              {},
            );
          });
        },
      },
      columnWidths: viewConfig?.columnWidths,
      onColumnResize: (column) => {
        setConfig(previousConfig => {
          return immutableSet(
            previousConfig,
            ['configByViewId', selectedViewId, 'columnWidths', column.id],
            column.width,
          );
        });
      },
    };
  }, [config, columnItems, columnConfigById, viewItems, sortDirectionItems, setConfig]);
};

export type SuppliersGridConfig = {
  view: {
    itemToString: (item: SelectViewItem | null) => string;
    items: SelectViewItem[];
    selectedItems: SelectViewItem[];
    onChange: (items: SelectViewItem[]) => void;
  };
  columns: {
    itemToString: (item: SelectColumnItem | null) => string;
    renderItem: (item: { label: string } | null) => string,
    items: SelectColumnItem[];
    selectedItems: SelectColumnItem[];
    onChange: (items: SelectColumnItem[]) => void;
    idProp: string;
  };
  sorting: MultiSortDropdownConfig;
  filters: {
    data: StoredFilters;
    update: (filterId: string, newFilters: unknown[]) => void;
    clear: () => void;
  };
  columnWidths?: Record<string, number>;
  onColumnResize?: (column: { id: string; width: number }) => void;
};
