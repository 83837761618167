import * as React from 'react';

/**
 * @deprecated
 */

export type CurrentDraft = {
  rfqId: string;
  isTemplate?: boolean;
  isRevising?: boolean;
  mutationPending: boolean;
  setMutationPending: (pending: boolean) => void;
};

const CurrentDraftContext = React.createContext<CurrentDraft | null>(null);

export const CurrentDraftProvider = ({
  currentDraft,
  ...props
}: {
  currentDraft: CurrentDraft;
  children: React.ReactNode;
}) => (
  <CurrentDraftContext.Provider value={currentDraft} {...props} />
);

export const useCurrentDraft = () => {
  const currentDraft = React.useContext(CurrentDraftContext);

  if (!currentDraft) {
    throw new Error('Can only be used when currentDraft exists');
  }

  return currentDraft;
};
