import { useMemo } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { ExchangeStatus, ExchangeType, RfqStatus, ScoringType } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { identity } from 'lodash';
import { assignSums } from '@deepstream/utils';
import * as rfx from '../../../../rfx';
import { LabeledValue } from '../../../../draft/LabeledValue';
import { StatusIcon } from '../../../../ExchangeStatusCell';
import { ProgressPercentage } from '../../../../ui/ProgressPercentage';
import { EvaluationActionStats, useEvaluationStats } from './useEvaluationStats';

const getStatus = (count: number, max: number) => {
  if (max === 0) {
    return ExchangeStatus.BLOCKED;
  }

  return count === 0
    ? ExchangeStatus.COMPLETE
    : ExchangeStatus.ACTION_REQUIRED;
};

export const OwnActionCount = ({ count, max, withSuffix }: { count: number; max: number; withSuffix?: boolean }) => {
  const { t } = useTranslation(['evaluation', 'translation']);

  return (
    <Flex alignItems="center">
      <StatusIcon
        type={ExchangeType.EVALUATION_CRITERION}
        status={getStatus(count, max)}
        currentCompanyGroup="buyer"
        sx={{ top: '-5px' }}
        fixedWidth
        mr={3}
        size={1}
      />
      {count === 0 ? (
        <Text>
          {withSuffix ? (
            t('request.progress.noActions', { ns: 'translation' })
          ) : (
            t('comparisonGrid.stats.none')
          )}
        </Text>
      ) : withSuffix ? (
        t('comparisonGrid.stats.actionCount', { count })
      ) : (
        count
      )}
    </Flex>
  );
};

export const TeamActionCount = ({ count, max, withSuffix }: { count: number; max: number; withSuffix?: boolean }) => {
  const { t } = useTranslation(['evaluation', 'translation']);

  return (
    <Flex alignItems="center">
      <StatusIcon
        type={ExchangeType.EVALUATION_CRITERION}
        status={getStatus(count, max)}
        currentCompanyGroup="buyer"
        sx={{ top: '-5px' }}
        fixedWidth
        mr={3}
        size={1}
      />
      {count === 0 ? (
        <Text>
          {withSuffix ? (
            t('request.progress.noActions', { ns: 'translation' })
          ) : (
            t('comparisonGrid.stats.none')
          )}
        </Text>
      ) : withSuffix ? (
        t('comparisonGrid.stats.actionCount', { count })
      ) : (
        count
      )}
    </Flex>
  );
};

const labelAdjustments = {
  align: 'left',
  gap: 0,
  height: '34px',
  labelProps: { color: 'subtext', fontSize: '9px', fontWeight: 400, marginTop: '3px', letterSpacing: '0.0833em' },
  valueProps: { fontSize: 1, fontWeight: 400 },
} as const;

export type CombinedEvaluationStats = {
  canEvaluateAnyLot: boolean;
  combinedStats: Required<EvaluationActionStats>;
};

export const useEvaluationCombinedStats = (): CombinedEvaluationStats => {
  const stats = useEvaluationStats();

  return useMemo(() => {
    const { canEvaluateByLotId, statsByLotIdByRecipientId } = stats;

    const flattenedStats = Object.values(statsByLotIdByRecipientId)
      .flatMap(statsByLotId => Object.values(statsByLotId));

    return {
      canEvaluateAnyLot: Object.values(canEvaluateByLotId).some(identity),
      combinedStats: {
        numActions: 0,
        numOwnActions: 0,
        numPendingActions: 0,
        numPendingOwnActions: 0,
        ...assignSums(flattenedStats),
      },
    };
  }, [stats]);
};

export const EvaluationComparisonStats = ({
  canEvaluate,
  stats,
}: {
  canEvaluate: boolean;
  stats: Required<EvaluationActionStats>;
}) => {
  const { t } = useTranslation('evaluation');
  const { canManageEvaluation } = rfx.useRfxPermissions();
  const scoringType = rfx.useEvaluationScoringType();
  const structure = rfx.useStructure();

  const {
    numActions,
    numOwnActions,
    numPendingActions,
    numPendingOwnActions,
  } = stats;

  return structure.status === RfqStatus.AWARDED || structure.status === RfqStatus.CLOSED ? (
    null
  ) : (
    <>
      {numActions === 0 ? (
        null
      ) : canManageEvaluation ? (
        <LabeledValue
          label={t('comparisonGrid.stats.completion.label')}
          value={<ProgressPercentage progress={(numActions - numPendingActions) / numActions} />}
          ml={4}
          {...labelAdjustments}
        />
      ) : canEvaluate ? (
        <LabeledValue
          label={t('comparisonGrid.stats.completion.label')}
          value={<ProgressPercentage progress={(numOwnActions - numPendingOwnActions) / numOwnActions} />}
          ml={4}
          {...labelAdjustments}
        />
      ) : (
        null
      )}
      {canManageEvaluation || canEvaluate ? (
        <LabeledValue
          label={t('comparisonGrid.stats.yourActions.label')}
          value={(
            <OwnActionCount count={numPendingOwnActions} max={numOwnActions} />
          )}
          ml={canManageEvaluation ? 3 : 4}
          {...labelAdjustments}
        />
      ) : (
        null
      )}
      {canManageEvaluation && scoringType === ScoringType.INDIVIDUAL_SCORES && (
        <LabeledValue
          label={t('comparisonGrid.stats.teamActions.label')}
          value={(
            <TeamActionCount
              count={numPendingActions - numPendingOwnActions}
              max={numActions - numOwnActions}
            />
          )}
          ml={3}
          {...labelAdjustments}
        />
      )}
    </>
  );
};
