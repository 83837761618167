import { useTranslation } from 'react-i18next';
import { SectionType } from '@deepstream/common/rfq-utils';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';

interface AddContractSectionDropdownProps{
  disabled: boolean;
  onSelect: (sectionType: SectionType) => void;
}

export const AddContractSectionDropdown = ({ disabled, onSelect }: AddContractSectionDropdownProps) => {
  const { t } = useTranslation('contracts');

  return (
    <DropdownMenu
      disabled={disabled}
      variant="primary"
      buttonText={t('details.addSection')}
      iconLeft="plus"
      iconRight="caret-down"
    >
      <DropdownMenuItem icon="file-text-o" onSelect={() => onSelect(SectionType.DOCUMENT)}>
        {t('document_other')}
      </DropdownMenuItem>
      <DropdownMenuItem icon="list-ul" onSelect={() => onSelect(SectionType.LINE_ITEMS)}>
        {t('lineItem_other')}
      </DropdownMenuItem>
    </DropdownMenu>
  );
};
