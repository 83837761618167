import { first, isEmpty, propertyOf } from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { isBidActive, SectionType, RfxSection } from '@deepstream/common/rfq-utils';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { assertUnreachable } from '@deepstream/utils/assertUnreachable';
import { Panel, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { QuestionsComparison } from './QuestionsComparison/QuestionsComparison';
import { LineItemsComparison } from './LineItemsComparison/LineItemsComparison';
import { Loading } from '../../../ui/Loading';
import { ErrorMessage } from '../../../ui/ErrorMessage';
import { EmptyTableMessage } from '../../../ui/EmptyTableMessage';
import * as rfx from '../../../rfx';
import { RfqIdProvider, useLiveRfqStructure } from '../../../useRfq';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useAllExchanges } from '../../../useAllExchanges';
import { View, useSelectView } from './useSelectView';
import { EvaluationComparison } from './EvaluationComparison/EvaluationComparison';
import { EmptyGridPanel } from './EmptyGridPanel';
import { RfxStructure } from '../../../types';
import { ExpandViewButton } from './ExpandViewButton';
import { ExchangeDefFieldValueProvider } from '../../../ExchangeDefFieldValueContext';

const useAvailableViews = (structure: RfxStructure) => useMemo(() => {
  if (!structure) {
    return {
      [View.EVALUATION]: false,
      [View.LINE_ITEMS]: false,
      [View.QUESTIONS]: false,
    };
  }

  const sections: RfxSection[] = Object.values(structure.sectionById);

  return {
    [View.EVALUATION]: sections.some(section => (
      section.type === SectionType.EVALUATION &&
      section.exchangeDefIds
        .map(propertyOf(structure.exchangeDefById))
        .some(exchangeDef => exchangeDef && !exchangeDef.isObsolete)
    )),
    [View.LINE_ITEMS]: sections.some(section => (
      section.type === SectionType.LINE_ITEMS &&
      section.exchangeDefIds
        .map(propertyOf(structure.exchangeDefById))
        .some(exchangeDef => exchangeDef && !exchangeDef.isObsolete)
    )),
    [View.QUESTIONS]: sections.some(section => (
      section.type === SectionType.QUESTION &&
      section.exchangeDefIds
        .map(propertyOf(structure.exchangeDefById))
        .some(exchangeDef => exchangeDef && !exchangeDef.isObsolete)
    )),
  };
}, [structure]);

const NoContentControlsPanel = ({
  isExpandedView,
  setIsExpandedView,
}: {
  isExpandedView: boolean;
  setIsExpandedView: (isExpandedView: boolean) => void;
}) => (
  <Panel flex={0} mb="20px">
    <PanelHeader
      height="56px"
      pt="1px"
      pb="2px"
      heading={null}
      collapse={false}
    >
      <ExpandViewButton
        isExpandedView={isExpandedView}
        setIsExpandedView={setIsExpandedView}
      />
    </PanelHeader>
  </Panel>
);

/**
 * Renders the contents of the comparison page, displaying comparison grids
 * of a request's evaluation, line items and questions to buyer of a live
 * request.
 */
export const Comparison = ({
  rfqId,
  navigateToTeam,
}: {
  /**
   * The ID of the request.
   */
  rfqId: string;
  /**
   * Callback to navigate to the team tab.
   */
  navigateToTeam: () => void;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const [isExpandedView, setIsExpandedView] = useState(false);

  const { data: structure, status: structureStatus } = useLiveRfqStructure({
    rfqId,
    currentCompanyId,
  });
  const { data: exchanges, status: exchangesStatus, updateCachedExchange } = useAllExchanges({
    rfqId,
    currentCompanyId,
  });

  const recipientsWithActiveBid = useMemo(() => {
    if (!structure) {
      return [];
    }

    return structure.recipients.filter(recipient => {
      const bid = structure.bidById[recipient._id];

      return bid?.status && isBidActive(bid.status);
    });
  }, [structure]);

  // @ts-expect-error ts(2345) FIXME: Argument of type 'RfxStructure | undefined' is not assignable to parameter of type 'RfxStructure'.
  const availableViews = useAvailableViews(structure);

  const initialViewIndex = useMemo(() => {
    if (!structure) {
      return 0;
    }

    if (availableViews[View.EVALUATION]) {
      return 0;
    }

    if (availableViews[View.LINE_ITEMS]) {
      return 1;
    }

    if (availableViews[View.QUESTIONS]) {
      return 2;
    }

    return 0;
  }, [availableViews, structure]);

  const selectView = useSelectView(initialViewIndex);

  const view = first(selectView.selectedItems)?.value ?? View.EVALUATION;

  useWatchValue(
    initialViewIndex,
    initialViewIndex => {
      selectView.onChange([
        selectView.items[initialViewIndex],
      ]);
    },
  );

  const isAnyViewAvailable = Object.values(availableViews).some(available => available);

  return (
    <RfqIdProvider rfqId={rfqId}>
      <Flex
        flexDirection="column"
        sx={isExpandedView ? ({
          padding: '20px',
          inset: 0,
          zIndex: 150,
          position: 'fixed',
          backgroundColor: 'lightGray3',
        }) : ({
          height: '100%',
        })}
      >
        {structureStatus === 'success' && exchangesStatus === 'success' && structure && exchanges && isAnyViewAvailable && !isEmpty(recipientsWithActiveBid) ? (
          <rfx.StructureProvider structure={structure}>
            <ExchangeDefFieldValueProvider>
              <rfx.ExchangesProvider exchanges={exchanges}>
                <rfx.RecipientsProvider recipients={recipientsWithActiveBid}>
                  {view === View.QUESTIONS ? (
                    <QuestionsComparison
                      navigateToTeam={navigateToTeam}
                      availableViews={availableViews}
                      selectView={selectView}
                      isExpandedView={isExpandedView}
                      setIsExpandedView={setIsExpandedView}
                      updateCachedExchange={updateCachedExchange}
                    />
                  ) : view === View.LINE_ITEMS ? (
                    <LineItemsComparison
                      navigateToTeam={navigateToTeam}
                      availableViews={availableViews}
                      selectView={selectView}
                      isExpandedView={isExpandedView}
                      setIsExpandedView={setIsExpandedView}
                      updateCachedExchange={updateCachedExchange}
                    />
                  ) : view === View.EVALUATION ? (
                    <rfx.EvaluationWeightsProvider>
                      <EvaluationComparison
                        navigateToTeam={navigateToTeam}
                        availableViews={availableViews}
                        selectView={selectView}
                        isExpandedView={isExpandedView}
                        setIsExpandedView={setIsExpandedView}
                        updateCachedExchange={updateCachedExchange}
                      />
                    </rfx.EvaluationWeightsProvider>
                  ) : (
                    assertUnreachable(view)
                  )}
                </rfx.RecipientsProvider>
              </rfx.ExchangesProvider>
            </ExchangeDefFieldValueProvider>
          </rfx.StructureProvider>
        ) : (
          <>
            <NoContentControlsPanel
              isExpandedView={isExpandedView}
              setIsExpandedView={setIsExpandedView}
            />
            <EmptyGridPanel>
              <PanelPadding>
                {structureStatus === 'loading' || exchangesStatus === 'loading' ? (
                  <Loading fontSize={4} fontWeight={400} />
                ) : structureStatus === 'error' || exchangesStatus === 'error' ? (
                  <ErrorMessage
                    fontSize={3}
                    error={t('request.comparison.errors.getComparison')}
                  />
                ) : !isAnyViewAvailable ? (
                  <EmptyTableMessage
                    header={t('request.comparison.noContentToShow')}
                    body={t('request.comparison.noSectionsForComparisonView')}
                  />
                ) : isEmpty(recipientsWithActiveBid) ? (
                  <EmptyTableMessage
                    header={t('request.comparison.noBids')}
                    body={t('request.comparison.noSuppliersHaveSubmittedABid')}
                  />
                ) : (
                  null
                )}
              </PanelPadding>
            </EmptyGridPanel>
          </>
        )}
      </Flex>
    </RfqIdProvider>
  );
};
