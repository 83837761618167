import { useMemo } from 'react';
import { noop, uniqBy, concat, slice } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { RfqStatus, BidStatus, UniversalBidStatus } from '@deepstream/common/rfq-utils';
import {
  supplierBidStatuses,
  universalBidStatusesConfig,
  requestStatusesConfig,
} from '@deepstream/common/rfq-utils/statusConfigs';
import { IconValue } from '@deepstream/common';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { useNavHistory } from '../../hooks/useNavHistory';
import { useApi, wrap } from '../../api';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { LineClamp } from '../../LineClamp';
import { useSystemFeatureFlags } from '../../systemFeatureFlags';

import { DashboardNoData } from './InfoBoxes';
import { DashboardListItem } from './DashboardListItem';
import { DashboardPanel } from './DashboardPanel';

const useFetchRequests = (ids?: string[]) => {
  const requestsToShow = 5;

  const api = useApi();
  const currentCompanyId = useCurrentCompanyId();
  const {
    data: recentlyVisitedRfqs,
    isLoading: recentlyVisitedRfqsLoading,
    isError: recentlyVisitedRfqsError,
  } = useQuery(
    ['recentlyVisitedRequests', {
      currentCompanyId,
      filter: { ids },
      pageSize: requestsToShow,
    }],
    wrap(api.getUserRequestsList),
    {
      enabled: !!ids?.length,
    },
  );

  const {
    data: recentlyCreatedRfqs,
    isLoading: recentlyCreatedRfqsLoading,
    isError: recentlyCreatedRfqsError,
  } = useQuery(
    ['recentlyCreatedRequests', {
      currentCompanyId,
      sort: { accessor: 'issueDate', direction: 'desc' },
      pageSize: requestsToShow,
    }],
    wrap(api.getUserRequestsList),
    {
      enabled: (ids?.length || 0) < requestsToShow,
    },
  );
  const rfqs = uniqBy(
    concat([], recentlyVisitedRfqs?.rfqs || [], recentlyCreatedRfqs?.rfqs || []),
    (request) => request._id,
  );

  return {
    rfqs: slice(rfqs, 0, requestsToShow),
    isLoading: recentlyVisitedRfqsLoading || recentlyCreatedRfqsLoading,
    isError: recentlyVisitedRfqsError || recentlyCreatedRfqsError,
  };
};

const StatusIcon = ({
  universalBidStatus,
  bidStatus,
  status,
  type,
}: {
  universalBidStatus?: UniversalBidStatus,
  bidStatus?: BidStatus;
  status: RfqStatus;
  type: 'received' | 'sent';
}) => {
  const systemFeatureFlags = useSystemFeatureFlags();
  const { t } = useTranslation();

  const statusData = useMemo(() => {
    if (type === 'sent') {
      const icon = requestStatusesConfig[status]?.icon;

      return {
        icon: { icon: icon?.value as IconValue, color: icon?.color },
        label: t(`request.status.${status}`),
      };
    }

    // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'.
    if (systemFeatureFlags.universalBidStatusEnabled && universalBidStatus) {
      const { icon } = universalBidStatusesConfig[universalBidStatus];

      return {
        // @ts-expect-error ts(18048) FIXME: 'icon' is possibly 'undefined'.
        icon: { icon: icon.value as IconValue, color: icon?.color },
        label: t(`request.universalSupplierBidStatus.${universalBidStatus}`),
      };
    }

    // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
    const { icon } = supplierBidStatuses[bidStatus] ?? {};

    return {
      icon: { icon: icon?.value as IconValue, color: icon?.color },
      label: t(`request.supplierBidStatus.${bidStatus}`),
    };
  }, [
    status,
    bidStatus,
    universalBidStatus,
    // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'.
    systemFeatureFlags.universalBidStatusEnabled,
    type,
    t,
  ]);

  return (
    <Tooltip content={statusData.label}>
      <Icon
        mr={2}
        mt="4px"
        sx={{ fontSize: 1 }}
        fixedWidth
        {...statusData.icon}
      />
    </Tooltip>
  );
};

export const RecentRequests = ({
  onItemClick,
  onGoToAllClick = noop,
}: {
  onItemClick: (data: {
    _id: string;
    status: RfqStatus;
    bidStatus?: BidStatus;
    type: 'sent' | 'received';
    subject?: string;
  }) => void;
  onGoToAllClick?: () => void;
}) => {
  const { t } = useTranslation(['dashboard']);
  const [getNavHistory] = useNavHistory();
  const visitedRequestIds = getNavHistory('request');
  const { rfqs, isLoading, isError } = useFetchRequests(visitedRequestIds);

  return (
    <DashboardPanel
      icon="file-alt"
      title={t('panels.recentRequests.heading')}
      isLoading={isLoading}
      isError={isError}
      goToAll={onGoToAllClick}
    >
      {rfqs.length === 0 ? (
        <DashboardNoData message={t('panels.recentRequests.empty')} />
      ) : rfqs.map((item) => (
        <DashboardListItem
          key={item._id}
          onClick={() => onItemClick(item)}
        >
          <StatusIcon
            status={item.type === 'sent' ? item.extendedStatus : item.status}
            type={item.type}
            bidStatus={item?.bidStatus}
            universalBidStatus={item?.universalBidStatus}
          />
          <LineClamp lines={2}>
            {item.subject || t('panels.recentRequests.itemNoSubject')}
          </LineClamp>
        </DashboardListItem>
      ))}
    </DashboardPanel>
  );
};
