import { useCallback, useEffect } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { isEmpty, size } from 'lodash';
import { useQuery } from 'react-query';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { Tab, TabListPanel, TabPanels, TabPanel, Tabs } from '../../../ui/TabsVertical';
import { SidebarLayout } from '../../../ui/ProfileLayout';
import { useDeviceSize } from '../../../ui/useDeviceSize';
import { Counter } from '../../../ui/Badge';
import { useSession } from '../../../auth';
import { useApi, wrap } from '../../../api';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { UserFlagsProvider } from '../../../UserFlagsContext';
import { TeamUsers } from './TeamUsers';
import { TeamMembershipRequests } from './TeamMembershipRequests';
import { teamManagementRoute } from '../../../AppRouting';
import { useCurrentUser } from '../../../useCurrentUser';

const tabs = ['users', 'requests-to-join'] as const;

export type TabId = (typeof tabs)[number];

const TeamManagementTabs = () => {
  const { t } = useTranslation();
  const { isExtraSmall, isSmall } = useDeviceSize();
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const search = useSearch({ from: teamManagementRoute.id });
  const navigate = useNavigate({ from: teamManagementRoute.id });

  const isAdmin = currentUser.roles[currentCompanyId]?.admin;

  const { data: membershipRequests = [] } = useQuery(
    ['membershipRequests', { companyId: currentCompanyId }],
    wrap(api.getMembershipRequests),
    {
      enabled: isAdmin,
    },
  );

  const navigateToTab = useCallback(
    (tab: typeof tabs[number], replace?: boolean) => navigate({ search: { tab }, replace }),
    [navigate],
  );

  useEffect(
    () => {
      if (!search.tab || (search.tab === tabs[1] && !isAdmin)) {
        navigateToTab('users', true);
      }
    },
    [isAdmin, navigateToTab, search.tab],
  );

  const selectedTabIndex = tabs.findIndex(tab => search.tab === tab);

  return (
    <Tabs index={selectedTabIndex} onChange={index => navigateToTab(tabs[index])}>
      <SidebarLayout
        sidebar={
          <TabListPanel>
            <Tab data-test="users-tab" key="users">
              <Flex alignItems="center">
                <Icon icon="users" mr={2} fixedWidth />
                <Text flex={1}>{t('general.user_other')}</Text>
              </Flex>
            </Tab>
            <Tab data-test="requests-to-join-tab" key="requests-to-join" disabled={!isAdmin}>
              <Tooltip content={!isAdmin && `${t('general.adminOnly')}`}>
                <Flex alignItems="center">
                  <Icon icon="question" mr={2} fixedWidth />
                  <Text flex={1}>{t('teamManagement.requestsToJoin')}</Text>
                  {!isAdmin && (
                    <Icon icon="lock" mr={2} fixedWidth />
                  )}
                  {isAdmin && !isEmpty(membershipRequests) && (
                    <Counter count={size(membershipRequests)} ml={1} />
                  )}
                </Flex>
              </Tooltip>
            </Tab>
          </TabListPanel>
        }
        main={
          <TabPanels>
            <TabPanel key="users">
              <TeamUsers />
            </TabPanel>
            <TabPanel key="requests-to-join">
              <TeamMembershipRequests />
            </TabPanel>
          </TabPanels>
        }
        sidebarStyle={!isExtraSmall && !isSmall ? { maxWidth: '232px', flex: '0 0 auto' } : undefined}
        mainStyle={!isExtraSmall && !isSmall ? { flex: '1 1 auto' } : undefined}
      />
    </Tabs>
  );
};

export const TeamManagement = () => {
  const auth = useSession();

  return auth.user ? (
    <UserFlagsProvider>
      <TeamManagementTabs />
    </UserFlagsProvider>
  ) : (
    null
  );
};
