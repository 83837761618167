import { useTranslation } from 'react-i18next';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog, DialogProps } from '@deepstream/ui-kit/elements/popup/Dialog';

export const ConfirmTotalSpendUpdateDialog = (dialogProps: Omit<DialogProps, 'heading' | 'body'>) => {
  const { t } = useTranslation(['translation', 'general']);

  return (
    <Dialog
      heading={t('request.spendAndSavings.dialog.confirmTotalSpendUpdate.heading')}
      body={
        <MessageBlock variant="warn" my={2}>
          {t('request.spendAndSavings.dialog.confirmTotalSpendUpdate.message')}
        </MessageBlock>
      }
      style={{ content: { width: '500px' } }}
      okButtonText={t('general.save')}
      cancelButtonText={t('cancel', { ns: 'general' })}
      {...dialogProps}
    />
  );
};
