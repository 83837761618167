import { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';
import { QuestionnaireOverview, QuestionnaireStatus, QuestionnaireTemplateChangeType, QuestionnaireTemplateVersion, getExpiryDate, getQuestionnaireTemplateChanges, getRenewalDate } from '@deepstream/common/preQual';
import { isPast } from 'date-fns';
import { Dialog, DialogProps } from '@deepstream/ui-kit/elements/popup/Dialog';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { ControlledRadioGroup } from '../../../ui/Radio';
import { CompactTableStyles } from '../../../TableStyles';
import { SMALL_ROW_HEIGHT, Table } from '../../../Table';
import { QuestionnaireStatusCell } from '../Questionnaire/QuestionnaireStatus';
import { TruncateCell } from '../../../TruncateCell';
import { useQuestionnaireTemplateData } from './questionnaireTemplateUtils';

const MAX_NUM_ROWS = 5;

type UpdateSentQuestionnairesDialogProps = Omit<DialogProps, 'heading' | 'body' | 'onOk'> & {
  sentQuestionnaires: QuestionnaireOverview[];
  templateVersions: QuestionnaireTemplateVersion[];
  onOk: (shouldUpdateQuestionnaires: boolean) => void;
};

export const UpdateSentQuestionnairesDialog = ({
  sentQuestionnaires,
  templateVersions,
  onOk,
  ...props
}: UpdateSentQuestionnairesDialogProps) => {
  const { t } = useTranslation(['preQualification', 'general']);
  const [shouldUpdateQuestionnaires, setShouldUpdateQuestionnaires] = useState(true);

  const template = useQuestionnaireTemplateData();

  const inputs = useMemo(
    () => [{
      label: t('questionnaireTemplate.confirmationDialogWithQuestionnaires.yesLabel'),
      description: t('questionnaireTemplate.confirmationDialogWithQuestionnaires.yesDescription'),
      value: 'true',
    }, {
      label: t('questionnaireTemplate.confirmationDialogWithQuestionnaires.noLabel'),
      description: t('questionnaireTemplate.confirmationDialogWithQuestionnaires.noDescription'),
      value: 'false',
    }],
    [t],
  );

  const onChange = useCallback(
    (value) => {
      setShouldUpdateQuestionnaires(value === 'true');
    },
    [setShouldUpdateQuestionnaires],
  );

  const columns = useMemo(
    () => [
      {
        id: 'supplier',
        Header: t('supplier', { count: 1 }),
        accessor: 'recipient.name',
        Cell: TruncateCell,
      },
      {
        id: 'status',
        Header: t('questionnaireTemplate.currentStatus'),
        accessor: 'status',
        Cell: QuestionnaireStatusCell,
        width: '160px',
      },
      {
        id: 'newStatus',
        Header: t('newStatus'),
        accessor: 'newStatus',
        Cell: QuestionnaireStatusCell,
        width: '160px',
      },
    ],
    [t],
  );

  // Determine new status for each sent questionnaire if the template is revised
  const sentQuestionnairesWithNewStatus = useMemo(
    () => sentQuestionnaires.map(questionnaire => {
      // Compare the draft with the current version of each sent questionnaire
      const templateChanges = getQuestionnaireTemplateChanges(template, templateVersions[questionnaire.meta.fromTemplateVersion - 1]);

      // Check if new questions were added to the template
      const newQuestionsAdded = !!templateChanges.find(
        change => change.type === QuestionnaireTemplateChangeType.QUESTIONS_ADDED,
      );

      /**
       * Check if any questions were updated, but take into consideration only effective changes.
       * Meaning, we exclude the cases in which the question has been changed multiple times,
       * but the latest version is the same as the initial version.
       */
      const questionsUpdated = !!templateChanges.find(
        change =>
          change.type === QuestionnaireTemplateChangeType.QUESTIONS_UPDATED &&
          change.updates.find(
            update => update.hasOnlyQuestionVersionChanged === false,
          ),
      );

      const expiryAndRenewalChange: any = templateChanges.find(
        change => change.type === QuestionnaireTemplateChangeType.EXPIRY_AND_RENEWAL_CONFIG_UPDATED,
      );

      const newRenewalDate = expiryAndRenewalChange && questionnaire.approvalDate
        ? getRenewalDate(new Date(questionnaire.approvalDate), expiryAndRenewalChange.nextRenewalConfig)
        : null;

      const newExpiryDate = expiryAndRenewalChange && questionnaire.approvalDate
        ? getExpiryDate(new Date(questionnaire.approvalDate), expiryAndRenewalChange.nextExpiryConfig)
        : null;

      const shouldChangeDueToNewRequirements = (
        (newQuestionsAdded || questionsUpdated) &&
        [QuestionnaireStatus.APPROVED, QuestionnaireStatus.PENDING_REVIEW].includes(questionnaire.status)
      );
      const shouldChangeDueToRenewal = (
        !shouldChangeDueToNewRequirements &&
        newRenewalDate &&
        isPast(newRenewalDate)
      );
      const shouldChangeDueToExpiry = (
        !shouldChangeDueToNewRequirements &&
        newExpiryDate &&
        isPast(newExpiryDate)
      );

      /**
       * Sent questionnaire status should change only when:
       * 1) new supplier actions are required (see the conditions above) and only when the questionnaire
       * is `Approved` or `Pending Review`.
       * 2) the questionnaire is `Approved`, the renewalConfig was updated and the new renewalDate is in the past.
       */
      const newStatus = shouldChangeDueToNewRequirements || shouldChangeDueToRenewal
        ? QuestionnaireStatus.IN_PROGRESS
        : shouldChangeDueToExpiry
          ? QuestionnaireStatus.EXPIRED
          : questionnaire.status;

      return { ...questionnaire, newStatus };
    }),
    [template, templateVersions, sentQuestionnaires],
  );

  const numRows = Math.min(MAX_NUM_ROWS, sentQuestionnaires.length);
  const tableHeight = `${(numRows + 1) * SMALL_ROW_HEIGHT}px`;

  const onConfirm = useCallback(
    () => onOk(shouldUpdateQuestionnaires),
    [onOk, shouldUpdateQuestionnaires],
  );

  return (
    <Dialog
      heading={t('questionnaireTemplate.confirmationDialogWithQuestionnaires.header')}
      body={(
        <Stack gap={4}>
          <ControlledRadioGroup
            inputs={inputs}
            value={shouldUpdateQuestionnaires.toString()}
            onChange={onChange}
          />
          {shouldUpdateQuestionnaires ? (
            <Box
              sx={{
                border: 'lightGray2',
                height: (numRows + 1) * SMALL_ROW_HEIGHT,
              }}
            >
              <CompactTableStyles fixedRowHeight stickyHeader>
                <Box sx={{ height: tableHeight, overflow: 'auto' }}>
                  <Table
                    columns={columns}
                    data={sentQuestionnairesWithNewStatus}
                  />
                </Box>
              </CompactTableStyles>
            </Box>
          ) : (
            <div style={{ height: tableHeight }} />
          )}
        </Stack>
      )}
      style={{ content: { width: '500px', minWidth: '500px' } }}
      cancelButtonText={t('cancel', { ns: 'general' })}
      okButtonText={t('questionnaireTemplate.publishChanges')}
      okButtonVariant="primary"
      onOk={onConfirm}
      {...props}
    />
  );
};
