import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { MultiStageLineItemsEventType } from '@deepstream/common/multiStageLineItemsTracking';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { OpenDraftRequestInNewTabLink } from '../OpenDraftRequestInNewTabLink';
import { Direction } from '../../../../ui/MultiStepFlow/types';
import { useMultiStepFlowData } from '../../../../ui/MultiStepFlow/MultiStepFlowContext';
import { SetupMultiStageResponsesFlowData, SetupMultiStageResponsesFlowStepType } from '../types';
import { useTrackSetupMultiStageResponsesEvent } from '../tracking';

export const Guidelines = () => {
  const { t } = useTranslation('translation');

  const { submitAndNavigate } = useMultiStepFlowData<
    SetupMultiStageResponsesFlowStepType,
    SetupMultiStageResponsesFlowData
  >();

  const trackFlowEvent = useTrackSetupMultiStageResponsesEvent({
    eventType: MultiStageLineItemsEventType.GUIDELINES_STEP_COMPLETED,
  });

  const onContinueClick = useCallback(async () => {
    await trackFlowEvent();
    submitAndNavigate(null, Direction.FORWARD);
  }, [submitAndNavigate, trackFlowEvent]);

  return (
    <lotPagesLayout.ContentWrapper2>
      <lotPagesLayout.Section2 heading={t('guidelines', { ns: 'general' })}>
        <Flex flexDirection="column" sx={{ rowGap: '6px', marginTop: '12px' }}>
          <Text >
            {t('request.setupMultiStageResponsesFlow.steps.guidelines.description1')}
          </Text>
          <Text>
            {t('request.setupMultiStageResponsesFlow.steps.guidelines.description2')}
          </Text>
          <Text>
            {t('request.setupMultiStageResponsesFlow.steps.guidelines.description3')}
          </Text>
        </Flex>
        <lotPagesLayout.Subsection2 heading={t('request.setupMultiStageResponsesFlow.steps.guidelines.actions.heading')} mt="16px">
          <lotPagesLayout.Ul style={{ marginTop: '12px' }}>
            <Trans
              i18nKey="request.setupMultiStageResponsesFlow.steps.guidelines.actions.body"
              ns="translation"
              components={{
                li: <li />,
              }}
            />
          </lotPagesLayout.Ul>
        </lotPagesLayout.Subsection2>
        <lotPagesLayout.Subsection2 heading={t('request.setupMultiStageResponsesFlow.steps.guidelines.things.heading')} mt="16px">
          <lotPagesLayout.Ul style={{ marginTop: '12px' }}>
            <Trans
              i18nKey="request.setupMultiStageResponsesFlow.steps.guidelines.things.body"
              ns="translation"
              components={{
                li: <li />,
                a: <OpenDraftRequestInNewTabLink />,
              }}
            />
          </lotPagesLayout.Ul>
        </lotPagesLayout.Subsection2>
      </lotPagesLayout.Section2>
      <Box>
        <Button
          onClick={onContinueClick}
          sx={{ alignSelf: 'flex-start' }}
        >
          {t('request.awardFlow.startNow')}
        </Button>
      </Box>
    </lotPagesLayout.ContentWrapper2>
  );
};
