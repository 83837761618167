import { MutationOptions, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { callAll } from '@deepstream/utils/callAll';
import { useApi } from '../../api';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useMutation } from '../../useMutation';
import { useToaster } from '../../toast';
import { getExtendedSupplierListsQueryKey, getSupplierListsQueryKey } from './useSupplierLists';

export const useDeleteSupplierList = (options: MutationOptions = {}) => {
  const { t } = useTranslation();
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const queryClient = useQueryClient();
  const toaster = useToaster();

  return useMutation(
    (supplierListId: string) => api.deleteSupplierList({
      companyId: currentCompanyId,
      supplierListId,
    }),
    {
      onSuccess: callAll(
        () => toaster.success(t('supplierLists.toaster.deleteListSuccess')),
        options.onSuccess,
      ),
      onError: callAll(
        () => toaster.error(t('supplierLists.toaster.deleteListError')),
        options.onError,
      ),
      onSettled: callAll(
        () => queryClient.invalidateQueries(getSupplierListsQueryKey(currentCompanyId)),
        () => queryClient.invalidateQueries(getExtendedSupplierListsQueryKey(currentCompanyId)),
        options.onSettled,
      ),
    },
  );
};
