import { isFunction } from 'lodash';
import * as React from 'react';
import { useField, useFormikContext } from 'formik';
import { BaseQuestionElement } from '@deepstream/common/legacy-pre-q-utils';
import { Modify, ElementProps } from './common';

type HiddenElementProps = Modify<
  ElementProps,
  { element: BaseQuestionElement & { type: 'hidden'; value: any } }
>;

export const HiddenElement: React.FC<HiddenElementProps> = ({
  name,
  element,
}) => {
  const { values } = useFormikContext();
  const [field, , { setValue }] = useField({ name });

  React.useEffect(
    () => {
      const value = isFunction(element.value)
        ? element.value(values)
        : element.value;

      if (field.value !== value) {
        setValue(value);
      }
    },
    [values, element, field.value, setValue],
  );

  return null;
};
