import { IconValue } from '@deepstream/common';
import { PanelHeader } from '@deepstream/ui-kit/elements/Panel';
import { useIntercom } from 'react-use-intercom';
import { useError } from '../../draft/validation';
import { useShowValidationErrors } from './draftContract';
import { useContractSection, useContractState } from './contract';
import { ContractSectionPanelHeading } from './ContractSectionPanelHeading';
import { ContractSectionActions } from './ContractSectionActions';

export const ContractDraftSectionViewPanelHeader = ({
  icon,
  intercomEditEventName,
}: {
  icon?: IconValue,
  intercomEditEventName?: string
}) => {
  const intercom = useIntercom();
  const showValidationErrors = useShowValidationErrors();
  const section = useContractSection();
  const { error: sectionNameError } = useError('section.name');
  // @ts-expect-error ts(2339) FIXME: Property 'isDraft' does not exist on type 'ContractStateContextType | undefined'.
  const { isDraft, isTemplatePreview } = useContractState();

  return (
    <PanelHeader
      icon={icon}
      heading={<ContractSectionPanelHeading />}
      description={section.description}
      bg={showValidationErrors && sectionNameError ? 'errorBackground' : 'white'}
      data-test="view-section-header"
    >
      {isDraft && !isTemplatePreview && (
        <ContractSectionActions
          onEditClick={intercomEditEventName ? (
            () => { intercom.trackEvent(intercomEditEventName); }
          ) : (
            undefined
          )}
        />
      )}
    </PanelHeader>
  );
};
