import { useMemo } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { AuctionStatus, ExchangeType } from '@deepstream/common/rfq-utils';

import { compact, first, groupBy, isEmpty, last, min, minBy, partition, sum, values } from 'lodash';
import { Color } from '@deepstream/ui-kit/theme/types';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Panel, SidebarPanelHeading } from '@deepstream/ui-kit/elements/Panel';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { LineItemsExchangeSnapshot } from '../../../types';
import * as rfx from '../../../rfx';
import { CurrencyAmount } from '../../../ui/Currency';
import { useLocalStorageState } from '../../../useLocalStorageState';
import { useCurrentUser } from '../../../useCurrentUser';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { AuctionCountdown } from '../Live/AuctionCountdown';

type BuyerInfoOption = {
  label: string;
  value: string;
};

const BuyerInfoBlock = ({
  label,
  options,
  onSelect,
  content,
  status,
  textColor = 'text',
}: {
  label: string;
  options?: BuyerInfoOption[];
  onSelect?: (label: string) => void;
  content: string | JSX.Element;
  status: AuctionStatus;
  textColor?: Color;
}) => {
  return (
    <Panel px={3} flex={1} ml={2}>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        opacity={[AuctionStatus.PAUSED, AuctionStatus.CANCELLED].includes(status) ? '0.4' : '1'}
      >
        <Box>
          {options?.length ? (
            <DropdownMenu
              variant="secondary-subtle"
              small
              px={0}
              buttonText={(
                <SidebarPanelHeading fontWeight={400} fontSize={1} lineHeight="1.5">
                  {label}
                  <Icon
                    icon="caret-down"
                    ml={2}
                    flex={0}
                    color="subtext"
                    fontSize={5}
                  />
                </SidebarPanelHeading>
              )}
            >
              {options.map(({ label, value }) => (
                // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
                <DropdownMenuItem key={label} onSelect={() => onSelect(value)}>
                  {label}
                </DropdownMenuItem>
              ))}
            </DropdownMenu>
          ) : (
            <SidebarPanelHeading fontWeight={400} fontSize={1} lineHeight="1.5">
              {label}
            </SidebarPanelHeading>
          )}
          <Text fontWeight={500} fontSize={6} mt={1} color={textColor}>
            {content}
          </Text>
        </Box>
      </Flex>
    </Panel>
  );
};

const useLowestRequestBidPrice = () => {
  const structure = rfx.useStructure();
  const exchanges = rfx.useExchanges();

  return useMemo(() => {
    const auctionExchangeDefs = values(structure.exchangeDefById).filter(
      exchangeDef => exchangeDef.type === ExchangeType.AUCTION_LINE_ITEM && !exchangeDef.isObsolete,
    );
    const linkedLineItemsDefs = compact(auctionExchangeDefs.map(
      (exchangeDef) => 'linkedExchangeDefId' in exchangeDef && structure.exchangeDefById[exchangeDef.linkedExchangeDefId],
    ))
      .filter(exchangeDef => exchangeDef.type === ExchangeType.LINE_ITEM && !exchangeDef.isObsolete);

    if (auctionExchangeDefs.length !== linkedLineItemsDefs.length) {
      return null;
    }

    const rfxBidsByRecipient = linkedLineItemsDefs.reduce((final, exchangeDef) => {
      const exchangeDefExchanges = exchanges.filter(exchange => exchange.def._id === exchangeDef._id) as LineItemsExchangeSnapshot[];
      const groupedExchanges = groupBy(exchangeDefExchanges, 'recipientId');
      Object.entries(groupedExchanges).forEach(([recipientId, exchanges]) => {
        // initially the value is undefined
        if (final[recipientId] === undefined) {
          final[recipientId] = 0;
        }

        // if value is null it means the recipient doesn't have a complete bid
        if (final[recipientId] === null) {
          return;
        }

        const exchangesTotals = compact(
          exchanges
            .map(exchange => exchange.computedFormulas?.totalCost),
        );
        // if the recipient doesn't have a total for this line item we ignore it from now on
        // because it doesn't have a complete bid
        if (!exchangesTotals.length) {
          final[recipientId] = null;
          return;
        }

        final[recipientId] += sum(exchangesTotals);
      });
      return final;
    }, {}) as Record<string, number>;

    return min(compact(Object.values(rfxBidsByRecipient))) || undefined;
  }, [structure, exchanges]);
};

const useSavingData = () => {
  const lot = rfx.useAuctionLot();
  const lowestRequestBidPrice = useLowestRequestBidPrice();

  return useMemo(() => {
    const bidderBids = Object.values(lot.bidsByBidderId);

    const startingBids = compact(bidderBids.map(first));
    const [preBids, postStartBids] = partition(
      startingBids,
      bid => new Date(bid.date) < new Date(lot.startDate),
    );
    const lowestStartingBidPrice = isEmpty(preBids)
      ? minBy(postStartBids, bid => new Date(bid.date).valueOf())?.price
      : minBy(preBids, bid => bid.price)?.price;

    const finalBidPrices = compact(bidderBids.map(bids => last(bids)?.price));
    const lowestFinalBidPrice = isEmpty(finalBidPrices) ? null : Math.min(...finalBidPrices);

    const priceSaving = lowestStartingBidPrice && lowestFinalBidPrice
      ? (lowestStartingBidPrice - lowestFinalBidPrice)
      : null;

    const requestBidPriceSavings = lowestRequestBidPrice && lowestFinalBidPrice
      ? (lowestRequestBidPrice - lowestFinalBidPrice)
      : null;

    return {
      lowestStartingBidPrice,
      lowestFinalBidPrice,
      priceSaving,
      lowestRequestBidPrice,
      requestBidPriceSavings,
    };
  }, [lot, lowestRequestBidPrice]);
};

const startingBidsTypes = ['lowestStartingBid', 'lowestRfxStartingBid'];

export const AuctionBuyerInfo = () => {
  const { t } = useTranslation();
  const {
    lowestStartingBidPrice,
    lowestFinalBidPrice,
    priceSaving,
    lowestRequestBidPrice,
    requestBidPriceSavings,
  } = useSavingData();
  const currentUser = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId();
  const { auction } = rfx.useStructure();
  const [startingBidType, setStartingBidType] = useLocalStorageState<string>({
    key: `${currentUser?._id}.${currentCompanyId}.${auction._id}.auctionBuyerInfoStartingBid`,
    defaultValue: () => lowestRequestBidPrice ? 'lowestRfxStartingBid' : 'lowestStartingBid',
  });
  const hasAuctionStarted = auction.status !== AuctionStatus.PENDING;

  return (
    <Flex flexDirection="row" justifyContent="flex-end" alignItems="stretch">
      <Panel width={180} p={3}>
        <AuctionCountdown
          status={auction.status}
          // @ts-expect-error ts(2769) FIXME: No overload matches this call.
          pauseDate={new Date(auction.lots[0].pauseDate)}
          endDate={
            hasAuctionStarted
              ? new Date(auction.lots[0].endDate)
              : new Date(auction.startDate)
          }
        />
      </Panel>
      <BuyerInfoBlock
        label={t(`request.auction.${startingBidType}`)}
        options={lowestRequestBidPrice ? startingBidsTypes.map(type => ({
          label: t(`request.auction.${type}`),
          value: type,
        })) : undefined}
        onSelect={lowestRequestBidPrice ? (label => setStartingBidType(label)) : undefined}
        content={<CurrencyAmount showCode value={startingBidType === 'lowestRfxStartingBid' && lowestRequestBidPrice ? lowestRequestBidPrice : lowestStartingBidPrice} />}
        status={auction.status}
      />
      <BuyerInfoBlock
        label={t('request.auction.lowestFinalBid')}
        content={<CurrencyAmount showCode value={lowestFinalBidPrice} />}
        status={auction.status}
      />
      <BuyerInfoBlock
        label={t('request.auction.priceSaving')}
        content={<CurrencyAmount showCode value={startingBidType === 'lowestRfxStartingBid' && lowestRequestBidPrice ? requestBidPriceSavings : priceSaving} />}
        status={auction.status}
        textColor="success"
      />
    </Flex>
  );
};
