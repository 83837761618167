import { useMemo, useState, useEffect } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@reach/accordion';
import { noop } from 'lodash';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { useRequestTagsContext } from './RequestTagsContext';
import { getRequestTagsTreeItems, getTreeIndexFromId, TreeItem } from './utils';
import { Tab } from '../../ui/TabsVertical';
import { useCurrentUserLocale } from '../../useCurrentUser';
import { useSearch } from '../../tanstackRouter';

type Props = {
  item: TreeItem;
  tagsTree: Record<string, TreeItem>;
  openTagIds: string[]
};

const RequestTagsTreeItem = ({ item, tagsTree, openTagIds }: Props) => {
  const shouldItemBeOpen = useMemo(
    () => item.isFolder &&
      // @ts-expect-error ts(18048) FIXME: 'item.children' is possibly 'undefined'.
      (openTagIds.includes(item.index) || item.children.some(child => openTagIds.includes(child))),
    [item.isFolder, item.index, item.children, openTagIds],
  );
  const [isOpen, setIsOpen] = useState(shouldItemBeOpen);

  useEffect(() => {
    if (!shouldItemBeOpen) {
      setIsOpen(false);
    }
  }, [shouldItemBeOpen]);

  if (item.isFolder) {
    return (
      <Accordion collapsible multiple index={isOpen ? 0 : -1} onChange={noop}>
        <AccordionItem>
          <AccordionButton as={Tab} index={getTreeIndexFromId(item.index)} key={getTreeIndexFromId(item.index)}>
            <Flex alignItems="center" onClick={() => setIsOpen(!isOpen)}>
              <Icon icon={isOpen ? 'chevron-down' : 'chevron-right'} size={16} mr={1} />
              <Icon icon="tag" mr={2} fixedWidth />
              <Text flex={1}><Truncate>{item.data.name}</Truncate></Text>
            </Flex>
          </AccordionButton>
          <AccordionPanel as={Box} ml={4}>
            {/*
             // @ts-expect-error ts(18048) FIXME: 'item.children' is possibly 'undefined'. */}
            {item.children.map((child) => (
              <RequestTagsTreeItem key={child} item={tagsTree[child]} tagsTree={tagsTree} openTagIds={openTagIds} />
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  }

  return (
    <Tab index={getTreeIndexFromId(item.index)} key={getTreeIndexFromId(item.index)}>
      <Flex alignItems="center">
        <Icon icon="tag" mr={2} fixedWidth />
        <Text flex={1}><Truncate>{item.data.name}</Truncate></Text>
      </Flex>
    </Tab>
  );
};

const RequestTagsTree = () => {
  const { tab } = useSearch({ strict: false }) as { tab };
  const { tags } = useRequestTagsContext();
  const locale = useCurrentUserLocale();
  const tagsTree = useMemo(
    () => getRequestTagsTreeItems(tags, locale),
    [tags, locale],
  );
  const rootTagsIds = tagsTree.root.children;
  const openTagsIds = useMemo(
    () => {
      const openTagsIds = [];

      let currentTagId = tab;
      do {
        // eslint-disable-next-line @typescript-eslint/no-loop-func,eqeqeq
        const openTag = tags.find(tag => tag._id == currentTagId);
        if (openTag) {
          // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'never'.
          openTagsIds.push(openTag._id);
        }
        currentTagId = openTag?.parentTagId;
      } while (currentTagId);

      return openTagsIds;
    },
    [tags, tab],
  );

  return (
    <>
      {/*
       // @ts-expect-error ts(18048) FIXME: 'rootTagsIds' is possibly 'undefined'. */}
      {rootTagsIds.map(tagId => (
        <RequestTagsTreeItem
          key={tagId}
          item={tagsTree[tagId]}
          openTagIds={openTagsIds}
          tagsTree={tagsTree}
        />
      ))}
    </>
  );
};

export default RequestTagsTree;
