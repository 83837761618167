import { Flex } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Illustration } from '@deepstream/ui-kit';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useApi } from '../../../api';
import { Loading } from '../../../ui/Loading';
import { ErrorMessage } from '../../../ui/ErrorMessage';
import { MIN_CELL_HEIGHT } from '../../../FieldsCell';
import { useTableData } from '../../../TableDataContext';
import { useCurrentUser } from '../../../useCurrentUser';
import { useLocalStorageState } from '../../../useLocalStorageState';
import { QuestionnairesTableDataProvider, QuestionnairesTableData } from './QuestionnairesTableDataProvider';
import { ReceivedQuestionnairesTable } from './ReceivedQuestionnairesTable';

const ReceivedQuestionnairesContent = () => {
  const { t } = useTranslation(['preQualification']);
  const {
    query,
    pageSize,
  } = useTableData<QuestionnairesTableData>({ required: true });

  const { status, data } = query;
  const {
    totalItems = 0,
  } = data ?? {};

  return (
    <Panel>
      <PanelHeader
        heading={t('receivedQuestionnaires')}
        sx={{ height: '60px' }}
      />
      <PanelDivider />
      {status === 'success' && totalItems > 0 ? (
        <ReceivedQuestionnairesTable />
      ) : (
        <Flex
          height={status === 'loading' ? MIN_CELL_HEIGHT * pageSize : '455px'}
          flexDirection="column"
          justifyContent="center"
        >
          <PanelPadding>
            {status === 'loading' ? (
              <Loading fontSize={4} fontWeight={400} />
            ) : status === 'error' ? (
              <ErrorMessage fontSize={3} error={t('errors.getReceivedQuestionnaires')} />
            ) : (
              <Illustration
                variant="empty-state"
                label={t('questionnaire.companyHasNotReceivedQuestionnaires')}
              />
            )}
          </PanelPadding>
        </Flex>
      )}
    </Panel>
  );
};

export const ReceivedQuestionnaires = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();
  const api = useApi();

  const pageSizeState = useLocalStorageState<number>({
    key: `${currentCompanyId}.${user._id}.receivedQuestionnaires.pageSize`,
    defaultValue: 10,
  });

  return (
    <QuestionnairesTableDataProvider
      queryTag="receivedQuestionnaires"
      queryFn={api.getReceivedQuestionnaires}
      pageSizeState={pageSizeState}
    >
      <ReceivedQuestionnairesContent />
    </QuestionnairesTableDataProvider>
  );
};
