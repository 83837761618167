import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { isEqual, pickBy } from 'lodash';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { withProps } from '@deepstream/ui-utils/withProps';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { LeavePageModal } from '../../../draft/LeavePageModal';
import { TextField } from '../../../form/TextField';
import { PreWrapValueOrEmDash, PropertyList } from '../../../PropertyList';
import { SummaryLabelConfigProvider } from '../../../draft/SummaryLabelConfigProvider';
import { Validation } from '../../../draft/validation';
import { ValidationPropertyRow } from '../../../draft/ValidationPropertyRow';
import { ValidationErrorValue } from '../../../draft/ValidationErrorValue';
import { nestValues } from '../../../nestValues';
import { useQuestionnaireTemplateActions, useQuestionnaireTemplateData, useQuestionnaireTemplateState } from './questionnaireTemplateUtils';
import { SummaryPanelHeader } from './SummaryPanelHeader';
import { useShowValidationErrors, useUpdateInternalData } from './draftQuestionnaireTemplate';
import { DraftPanel } from './DraftPanel';

const PANEL_ID = 'purpose';

export const PurposePanel = () => {
  const { t } = useTranslation(['preQualification', 'general']);
  const { stopEditing } = useQuestionnaireTemplateActions();
  // @ts-expect-error ts(2339) FIXME: Property 'editingPanelId' does not exist on type 'QuestionnaireTemplateStateContextType | undefined'.
  const { editingPanelId, isLive } = useQuestionnaireTemplateState();
  const template = useQuestionnaireTemplateData();
  const [updateInternal] = useUpdateInternalData();
  const showValidationErrors = useShowValidationErrors();

  const isEditingThisPanel = editingPanelId && editingPanelId === PANEL_ID;

  const properties = useMemo(() => ([
    {
      fieldName: 'purpose',
      name: t('questionnairePurpose.overview'),
      value: template.internal.purpose,
      Component: nestValues(
        withProps(ValidationErrorValue, { useShowValidationErrors }),
        PreWrapValueOrEmDash,
      ),
      heightAuto: true,
    },
  ]), [t, template]);

  const initialValues = template.internal;

  const heading = t('questionnairePurpose.purpose');

  return (
    <DraftPanel panelId={PANEL_ID} heading={heading}>
      <SummaryPanelHeader heading={heading} panelId={PANEL_ID} canEdit={!isLive} isInternal />
      <PanelDivider />
      {isEditingThisPanel ? (
        <Formik
          validateOnBlur
          enableReinitialize
          initialValues={initialValues}
          validationSchema={
            yup.object().shape({
              purpose: isLive
                ? yup.string().required(t('required', { ns: 'general' }))
                : yup.string().nullable(),
            })
          }
          onSubmit={async (values) => {
            const newInternal = pickBy(
              values,
              (value, key) => !isEqual(value, initialValues[key]),
            );

            await updateInternal(
              { internal: newInternal },
              { onSuccess: () => stopEditing() },
            );
          }}
        >
          {({ isSubmitting, dirty, isValid, resetForm }) => (
            <Form>
              <SummaryLabelConfigProvider>
                <Stack gap={20} m={20}>
                  <TextField
                    name="purpose"
                    isMultiLine
                    rows={9}
                    label={t('questionnairePurpose.overview')}
                    placeholder={t('questionnairePurpose.overviewPlaceholder')}
                    required
                  />
                </Stack>
              </SummaryLabelConfigProvider>
              <PanelDivider />
              <PanelPadding>
                <Flex justifyContent="flex-end">
                  <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
                  <SaveButton disabled={isSubmitting || !dirty || !isValid} />
                </Flex>
              </PanelPadding>
              <LeavePageModal />
            </Form>
          )}
        </Formik>
      ) : (
        <Validation
          values={template.internal}
          schema={showValidationErrors ? (
            yup.object().shape({
              purpose: yup.string().nullable().required(t('required', { ns: 'general' })),
            })
          ) : (
            yup.mixed()
          )}
        >
          <PropertyList
            Row={withProps(ValidationPropertyRow, { useShowValidationErrors })}
            properties={properties}
          />
        </Validation>
      )}
    </DraftPanel>
  );
};
