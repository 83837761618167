import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import * as Layout from '../../Layout';
import { useSupplierListNavigation } from '../../appNavigation';
import { ListConfigFlow } from './ListConfigFlow/ListConfigFlow';

export const CreateSupplierListPageContent = () => {
  const { t } = useTranslation();
  const { navigateToLists, getExternalNetworkLinkProps, getSupplierListsLinkProps } = useSupplierListNavigation();

  const breadcrumbs = useMemo(
    () => [{
      title: t('general.network'),
      linkProps: getExternalNetworkLinkProps(),
    }, {
      title: t('supplierLists.list', { count: 2 }),
      linkProps: getSupplierListsLinkProps(),
    }],
    [t, getExternalNetworkLinkProps, getSupplierListsLinkProps],
  );

  return (
    <Layout.HeaderAndContent
      header={
        <Layout.PageHeaderWithBreadcrumbs
          heading={t('supplierLists.createNewList', { count: 2 })}
          breadcrumbs={breadcrumbs}
        >
          <Button small variant="danger-outline" mt={1} onClick={() => navigateToLists()}>
            {t('general.exitProcess')}
          </Button>
        </Layout.PageHeaderWithBreadcrumbs>
      }
      content={<ListConfigFlow />}
    />
  );
};
