import * as Layout from '../../../Layout';
import { Header } from './Header';
import { TemplatePageTab } from './utils';
import { DraftReview } from './DraftReview';

export const QuestionnaireTemplateDraftReviewPageContent = () => {
  const tabId = TemplatePageTab.REVIEW;

  return (
    <Layout.HeaderAndContent
      header={
        <Header selectedTabId={tabId} />
      }
      content={
        <DraftReview />
      }
    />
  );
};
