import * as React from 'react';
import { Draft } from '@deepstream/common/rfq-utils';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Flex, Heading } from 'rebass/styled-components';
import { first, some, values, isNumber } from 'lodash';
import { DateFormat, localeFormatNumber } from '@deepstream/utils';
import { truncateStyle } from '@deepstream/ui-kit/elements/text/Truncate2';
import { useHover } from '@deepstream/ui-kit/hooks/useHover';
import { useMediaQueries } from '@deepstream/ui-kit/hooks/useMediaQueries';
import { EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { MoveUpMenuItem, MoveDownMenuItem, DeleteMenuItem, EllipsisMenu } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Bold } from '../Bold';
import { useModalState } from '../ui/useModalState';
import { useDeleteStage, useSwapConsecutiveStages } from './useStageMutations';
import { useDeviceSize } from '../ui/useDeviceSize';
import { useCurrentDraft } from './useCurrentDraft';
import { Datetime2 } from '../Datetime';
import * as rfx from '../rfx';
import { StageIcon } from './StageIcon';
import { StageName } from '../StageName';
import { FieldLabel } from '../FieldsCell';
import { isAuctionStage } from '../utils';
import { StructureAuctionStage, StructureGeneralStage, StructureStage } from '../types';
import { ConfirmMoveStageDialog } from './ConfirmMoveStageDialog';
import { StageVisibility } from '../StageVisibility';
import { useCurrentUserLocale } from '../useCurrentUser';

const Field = ({ label, children }: { label: string; children: React.ReactNode }) => (
  <Flex alignItems="center" mr={3} mt="6px">
    <FieldLabel>{label}</FieldLabel>
    {children}
  </Flex>
);

const StageStart = ({ stage }: { stage: StructureAuctionStage<Draft> }) => {
  const { t } = useTranslation();

  return (
    <Field label={t('general.start')}>
      {stage.startDate ? (
        <Datetime2 value={stage.startDate} format={DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ} />
      ) : (
        t('general.notSpecified')
      )}
    </Field>
  );
};

const StageDuration = ({ stage }: { stage: StructureAuctionStage<Draft> & { duration?: number | null } }) => {
  const { t } = useTranslation();
  const locale = useCurrentUserLocale();

  return (
    <Field label={t('general.duration')}>
      {isNumber(stage.duration) ? (
        `${localeFormatNumber(stage.duration, { locale })}\u2006${t('general.minuteShort', { count: stage.duration })}`
      ) : (
        t('general.notSpecified')
      )}
    </Field>
  );
};

const StageDeadline = ({ stage }: { stage: StructureGeneralStage<Draft> }) => {
  const { t } = useTranslation();

  return (
    <Field label={t('general.deadline')}>
      {stage.completionDeadline ? (
        <Datetime2 value={stage.completionDeadline} format={DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ} />
      ) : (
        t('general.notSpecified')
      )}
    </Field>
  );
};

type StageHeaderProps = {
  stage: StructureStage<Draft> & { duration?: number | null };
  stageIndex: number;
  canEdit: boolean;
  editStage: () => void;
  showStageNameValidation: boolean;
};

export const StageHeader = ({
  stage,
  stageIndex,
  canEdit,
  editStage,
  showStageNameValidation,
}: StageHeaderProps) => {
  const { t } = useTranslation('translation');
  const { isExtraSmall } = useDeviceSize();
  const { mutationPending } = useCurrentDraft();
  const swapConsecutiveStagesMutation = useSwapConsecutiveStages();
  const deleteStageMutation = useDeleteStage();
  const { exchangeDefById, stages } = rfx.useStructure<Draft>();
  const [hoverRef, hover] = useHover();
  const { canHover: deviceCanHover } = useMediaQueries();
  const [isDropdownExpanded, setIsDropdownExpanded] = React.useState(false);
  const [affectedStageIndex, setAffectedStageIndex] = React.useState<number | null>(null);
  const deleteConfirmationDialog = useModalState();
  const moveConfirmationDialog = useModalState();

  const swapConsecutiveStages = React.useCallback(
    async (firstIndex, secondIndex) => swapConsecutiveStagesMutation(firstIndex, secondIndex),
    [swapConsecutiveStagesMutation],
  );

  const moveUp = React.useCallback(
    async () => swapConsecutiveStages(stageIndex - 1, stageIndex),
    [stageIndex, swapConsecutiveStages],
  );

  const moveDown = React.useCallback(
    async () => swapConsecutiveStages(stageIndex, stageIndex + 1),
    [stageIndex, swapConsecutiveStages],
  );

  const deleteStage = React.useCallback(
    async () => deleteStageMutation(stage),
    [stage, deleteStageMutation],
  );

  const hasRelatedExchanges = React.useMemo(
    () => some(
      values(exchangeDefById),
      exchangeDef => first(exchangeDef.stages) === stage._id,
    ),
    [exchangeDefById, stage],
  );

  const showOverflowActions = React.useMemo(
    () => stages.length > 1 && !stage.isLive,
    [stages, stage],
  );

  const canMoveUp = React.useMemo(
    () => {
      const previousStage = stages[stageIndex - 1];

      return !stage.isLive && stageIndex > 0 && !previousStage.isLive;
    },
    [stageIndex, stages, stage],
  );

  const canMoveDown = React.useMemo(
    () => !stage.isLive && stageIndex !== stages.length - 1,
    [stageIndex, stages, stage],
  );

  const onDelete = React.useCallback(
    () => {
      if (hasRelatedExchanges) {
        deleteConfirmationDialog.open();
      } else {
        deleteStage();
      }
    },
    [hasRelatedExchanges, deleteStage, deleteConfirmationDialog],
  );

  const onConfirmDelete = React.useCallback(
    () => {
      deleteStage();
      deleteConfirmationDialog.close();
    },
    [deleteStage, deleteConfirmationDialog],
  );

  const shouldEditVisibility = React.useCallback(
    (firstStage, secondStage) => secondStage.isPrivate && !firstStage.isPrivate,
    [],
  );

  const onMoveUp = React.useCallback(
    () => {
      const previousStage = stages[stageIndex - 1];
      const currentStage = stages[stageIndex];

      if (shouldEditVisibility(previousStage, currentStage)) {
        setAffectedStageIndex(stageIndex - 1);
        moveConfirmationDialog.open();
      } else {
        moveUp();
      }
    },
    [stages, stageIndex, moveConfirmationDialog, moveUp, shouldEditVisibility],
  );

  const onMoveDown = React.useCallback(
    () => {
      const currentStage = stages[stageIndex];
      const nextStage = stages[stageIndex + 1];

      if (shouldEditVisibility(currentStage, nextStage)) {
        setAffectedStageIndex(stageIndex + 1);
        moveConfirmationDialog.open();
      } else {
        moveDown();
      }
    },
    [stages, stageIndex, moveConfirmationDialog, moveDown, shouldEditVisibility],
  );

  const onConfirmMove = React.useCallback(
    () => {
      if (stageIndex < affectedStageIndex!) {
        moveDown();
      } else {
        moveUp();
      }

      setAffectedStageIndex(null);
      moveConfirmationDialog.close();
    },
    [stageIndex, affectedStageIndex, moveConfirmationDialog, moveUp, moveDown],
  );

  const onCancelMove = React.useCallback(
    () => {
      moveConfirmationDialog.close();
      setAffectedStageIndex(null);
    },
    [moveConfirmationDialog],
  );

  return (
    <>
      <Box p="12px" ref={hoverRef}>
        <Flex alignItems="center">
          <StageIcon stageType={stage.type} />
          <Box flex={1} ml="12px">
            <Heading as="h3" fontSize={2} mr={2}>
              <Flex flexDirection="row" sx={{ truncateStyle }}>
                <Bold>
                  <StageName index={stageIndex} stage={stage} withPrefix />
                </Bold>
                {showStageNameValidation && !stage.name && (
                  <>
                    <Bold>&nbsp;–&nbsp;</Bold>
                    <IconText
                      fontSize={1}
                      fontWeight={500}
                      color="danger"
                      icon="info-circle"
                      text={t('validation.provideAName')}
                    />
                  </>
                )}
              </Flex>
            </Heading>
            <Flex flexDirection={isExtraSmall ? 'column' : 'row'} color="text" fontSize={1}>
              {isAuctionStage(stage) ? (
                <>
                  <StageStart stage={stage} />
                  <StageDuration stage={stage} />
                </>
              ) : (
                <StageDeadline stage={stage} />
              )}
              {stages.length > 1 && <StageVisibility stage={stage} mt="6px" />}
            </Flex>
          </Box>
          <Flex justifyContent="flex-end" alignItems="center">
            {canEdit && (!deviceCanHover || hover || isDropdownExpanded) && (
              <Flex alignItems="center">
                <ButtonGroup marginBetween="-1px">
                  <EditButton small onClick={editStage} disabled={mutationPending} />
                  {showOverflowActions && (
                    <EllipsisMenu
                      small
                      disabled={mutationPending}
                      onExpandedStateChange={setIsDropdownExpanded}
                    >
                      <MoveUpMenuItem onSelect={onMoveUp} disabled={!canMoveUp} />
                      <MoveDownMenuItem onSelect={onMoveDown} disabled={!canMoveDown} />
                      <DeleteMenuItem onSelect={onDelete} />
                    </EllipsisMenu>
                  )}
                </ButtonGroup>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Box>
      <Dialog
        heading={t('request.stages.dialog.deleteStageConfirmation.heading', { stageNumber: stageIndex + 1 })}
        body={(
          <MessageBlock variant="warn" mt={0} maxWidth="470px">
            {isAuctionStage(stage) ? (
              <Trans i18nKey="request.stages.dialog.deleteStageConfirmation.auctionBody">
                This stage contains content on the <Bold>Auction</Bold> tab which will also be deleted. This cannot be undone.
              </Trans>
            ) : (
              <Trans i18nKey="request.stages.dialog.deleteStageConfirmation.generalBody">
                This stage contains content on the <Bold>Details</Bold> tab which will also be deleted. This cannot be undone.
              </Trans>
            )}
          </MessageBlock>
        )}
        okButtonText={t('general.delete')}
        okButtonVariant="danger"
        cancelButtonText={t('general.cancel')}
        cancelButtonVariant="secondary"
        isOpen={deleteConfirmationDialog.isOpen}
        onOk={onConfirmDelete}
        onCancel={deleteConfirmationDialog.close}
      />
      <ConfirmMoveStageDialog
        stage={stage}
        stageIndex={stageIndex}
        affectedStageIndex={affectedStageIndex!}
        isOpen={moveConfirmationDialog.isOpen}
        onOk={onConfirmMove}
        onCancel={onCancelMove}
      />
    </>
  );
};
