import { useState, useMemo, useEffect } from 'react';
import * as React from 'react';
import { compact, identity, isEmpty, map, reduce, trim } from 'lodash';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Flex, Text, Box } from 'rebass/styled-components';

import { ExchangeType } from '@deepstream/common/rfq-utils';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { CancelButton, Button, SaveButton } from '@deepstream/ui-kit/elements/button/Button';
import { IconTextButton } from '@deepstream/ui-kit/elements/button/IconTextButton';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { ModalHeader, ModalBody, ModalFooter, Modal } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Validation, useErrors, useError } from '../../draft/validation';
import { Table } from '../../Table';
import { BulkImportTableStyle } from '../../TableStyles';
import { TextCell } from '../../TextCell';
import { TruncateCell } from '../../TruncateCell';
import { fastFakeUploadFn } from '../fakeUploadFn';
import { FileList } from '../FileList';
import { useModalState } from '../useModalState';
import { useCurrentUserLocale } from '../../useCurrentUser';
import useDownload from '../../useDownload';

import {
  useImportFileParser,
  ImportFileParserProps,
  ImportFileParserSuccess, ImportFileParserErrors,
} from './useImportFileParser';
import { fullSchemaGenerator } from './validators';

const BulkImportCell = (props) => {
  const { error } = useError(`data[${props.row.index}][${props.cell.column.id}]`);

  if (error) {
    return (
      <Flex sx={{ alignItems: 'center', height: '35px', lineHeight: '35px' }}>
        <Tooltip content={error}>
          <Icon color="danger" icon="exclamation-circle" mr={2} />
        </Tooltip>
        <TruncateCell {...props} sx={{ fontWeight: 500, color: 'danger', px: 2 }} />
      </Flex>
    );
  }
  return <TextCell {...props} sx={{ px: 2 }} />;
};

const BulkImportPreview = <T, >({
  data,
  columns: rawColumns,
  onValidation,
  getHeaderLabel,
}: {
  columns: string[];
  data: T[];
  onValidation?: (hasErrors: boolean) => void;
  getHeaderLabel: (header: string) => string;
}) => {
  const { errors } = useErrors();
  const tableColumns = useMemo(() => {
    const columns = compact(rawColumns);
    const fileColumns = columns.map((column) => ({
      Header: getHeaderLabel(column),
      accessor: column,
      id: column,
      Cell: BulkImportCell,
    }));

    return [
      {
        Header: ' ',
        id: 'index',
        accessor: (_, i) => i + 1,
      },
      ...fileColumns,
    ];
  }, [rawColumns, getHeaderLabel]);

  useEffect(() => {
    const hasErrors = !isEmpty(errors.data) || !isEmpty(errors.columns);

    // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
    onValidation(hasErrors);
  }, [errors.data, errors.columns, onValidation]);

  return (
    <>
      {(typeof errors.columns === 'object' && Object.keys(errors.columns).length) && (
        <MessageBlock variant="error" mb={3}>
          <Text fontSize={0} fontWeight="500">
            {map(errors.columns, (error, i) => (
              <Text key={i}>{error}</Text>
            ))}
          </Text>
        </MessageBlock>
      )}

      {errors.columns && typeof errors.columns === 'string' && (
        <MessageBlock variant="error" mb={3}>
          <Text fontSize={0} fontWeight="500">
            <Text>{errors.columns}</Text>
          </Text>
        </MessageBlock>
      )}

      <BulkImportTableStyle stickyHeader fixedRowHeight px="4px" py="4px">
        <Table
          columns={tableColumns}
          data={data}
        />
      </BulkImportTableStyle>
    </>
  );
};

const BulkImportUpload = <T, >({
  onParseComplete,
  onParseError,
  getHeaderKey,
}: {
  onParseComplete: ImportFileParserProps<T>['onSuccess'];
  onParseError: ImportFileParserProps<T>['onError'];
  getHeaderKey: (label: string) => string;
}) => {
  const [parseErrors, setParseErrors] = useState<null | ImportFileParserErrors<T>>(null);

  const parse = useImportFileParser<T>({
    onSuccess: (response) => {
      setParseErrors(null);
      // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
      onParseError(null);
      // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
      onParseComplete(response);
    },
    onError: (errors) => {
      setParseErrors(errors);
      // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
      onParseError(errors);
    },
    transformHeader: (header) => {
      return getHeaderKey(trim(header)) || trim(header);
    },
  });

  return (
    <>
      <FileList
        uploadFn={fastFakeUploadFn}
        limit={1}
        accept=".csv"
        onUploadStart={parse}
        onChange={(file) => {
          if (isEmpty(file)) {
            // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
            onParseComplete(null);
            // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
            onParseError(null);
            setParseErrors(null);
          }
        }}
      />
      {!isEmpty(parseErrors) && (
        <MessageBlock variant="error">
          {/*
           // @ts-expect-error ts(18047) FIXME: 'parseErrors' is possibly 'null'. */}
          {parseErrors.map((error, index) => {
            return (
              <Text key={index} fontSize={0} fontWeight="500">
                {error.message}
              </Text>
            );
          })}
        </MessageBlock>
      )}
    </>
  );
};

const DownloadSampleButton = ({
  format,
}: {
  format: 'csv' | 'xlsx';
}) => {
  const { t } = useTranslation('general');
  const locale = useCurrentUserLocale();
  const download = useDownload();

  const downloadContractSampleFiles = React.useCallback(
    async () => {
      const queryParams = new URLSearchParams({ locale, type: ExchangeType.CONTRACT, fileType: format });
      const url = `/ajax/sampleFiles?${queryParams.toString()}`;

      await download(url);
    },
    [download, locale, format],
  );

  return (
    <IconTextButton
      p={2}
      type="button"
      variant="secondary-subtle"
      icon="download"
      onClick={downloadContractSampleFiles}
    >
      {format === 'csv' ? t('downloadSampleCSV') : t('downloadSampleXLSX')}
    </IconTextButton>
  );
};

export const BulkImportModal = <T, >({
  description,
  heading,
  getHeaderLabel,
  getHeaderKey,
  validateAsync,

  onClose,
  onSave,

  validationSchema,
}: {
  description?: React.ReactNode;
  heading: React.ReactNode;

  onClose: () => void;
  onSave: (options: { data: T[]; columns: string[]; }) => void;
  getHeaderLabel: (header: string) => string;
  getHeaderKey: (label: string) => string;
  validateAsync?: (data: T[], context: yup.TestContext) => Promise<boolean>;

  validationSchema: yup.ObjectSchema<{ columns: object, data: object }>;
}) => {
  const { t } = useTranslation('general');
  const [viewMode, setViewMode] = useState<'upload' | 'preview'>('upload');
  const [parsedContent, setParsedContent] = useState<null | ImportFileParserSuccess<T>>(null);
  const [parsedError, setParsedError] = useState<null | ImportFileParserErrors<T>>(null);
  const [hasValidationErrors, setHasValidationErrors] = useState<boolean>(false);

  // Transform the array of columns into an object of columns for flexibility
  const columnsToValidate = reduce(parsedContent?.columns, (accumulator, column) => ({
    ...accumulator,
    [column]: true,
  }), {});

  return (
    <Modal isOpen style={{ content: { maxWidth: 850 } }}>
      <ModalHeader>{heading}</ModalHeader>
      <ModalBody>
        {viewMode === 'upload' && (
          <>
            {description && (
              <Text mb={3} fontSize={2}>
                {description}
              </Text>
            )}
            <BulkImportUpload
              onParseComplete={setParsedContent}
              onParseError={setParsedError}
              getHeaderKey={getHeaderKey}
            />
            <Stack gap={1} mt={3}>
              <Box>
                <DownloadSampleButton format="csv" />
              </Box>
              <Box>
                <DownloadSampleButton format="xlsx" />
              </Box>
            </Stack>
          </>
        )}
        {viewMode === 'preview' && (
          <Validation
            schema={fullSchemaGenerator(validationSchema, t, validateAsync)}
            values={{
              // @ts-expect-error ts(18047) FIXME: 'parsedContent' is possibly 'null'.
              data: parsedContent.data,
              columns: columnsToValidate,
          }}
          >
            <BulkImportPreview
              // @ts-expect-error ts(18047) FIXME: 'parsedContent' is possibly 'null'.
              data={parsedContent.data}
              // @ts-expect-error ts(18047) FIXME: 'parsedContent' is possibly 'null'.
              columns={parsedContent.columns}
              onValidation={setHasValidationErrors}
              getHeaderLabel={getHeaderLabel}
            />
          </Validation>
        )}
      </ModalBody>
      <ModalFooter>
        <CancelButton
          onClick={onClose}
          mr={2}
        />
        {viewMode === 'upload' && (
          <Button
            onClick={() => setViewMode('preview')}
            disabled={isEmpty(parsedContent) || !isEmpty(parsedError)}
          >
            {t('next')}
          </Button>
        )}
        {viewMode === 'preview' && (
          <SaveButton
            disabled={hasValidationErrors}
            onClick={async () => {
              // @ts-ignore ts(2345) FIXME: Argument of type 'ImportFileParserSuccess<T> | null' is not assignable to parameter of type '{ data: T[]; columns: string[]; }'.
              await onSave(parsedContent);
              onClose();
            }}
          />
        )}
      </ModalFooter>
    </Modal>
  );
};
