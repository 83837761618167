import { TFunction } from 'react-i18next';
import { Company,
  Live,
  getBidIntentionStatus,
  getBidOutcomeStatus,
  BidIntentionStatus,
  renderStageName,
  BidOutcomeStatus,
} from '@deepstream/common/rfq-utils';
import { IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { RfxStructure } from '../../../types';
import { iconPropsByBidIntentionStatus } from '../Live/iconPropsByBidIntentionStatus';
import { iconPropsByBidOutcomeStatus } from '../Live/iconPropsByBidOutcomeStatus';
import { GroupingCriterion } from './requestSentSuppliersViews';

export const suppliersGridGroupConfig: Record<GroupingCriterion, {
  getGroupIds: (structure: RfxStructure<Live>) => string[];
  isRecipientInGroup: (groupId: string, recipient: Company, structure: RfxStructure<Live>) => boolean;
  getGroupLabel: (groupId: string, structure: RfxStructure<Live>, t: TFunction) => string;
  getGroupIconProps?: (groupId: string, structure: RfxStructure<Live>) => IconProps;
  showEmptyGroupHeader: (groupId: string, structure: RfxStructure<Live>) => boolean;
}> = {
  stageId: {
    getGroupIds: (structure) => structure.stages.map(stage => stage._id),
    isRecipientInGroup: (groupId, recipient, structure) => {
      return structure.bidById[recipient._id].stageId === groupId;
    },
    getGroupLabel: (groupId, structure, t) => {
      const stage = structure.stageById[groupId];
      const index = structure.stages.findIndex(stage => stage._id === groupId);

      return renderStageName(stage, t, index, true) || '';
    },
    showEmptyGroupHeader: () => true,
  },
  lotId: {
    getGroupIds: (structure) => {
      return structure.lots.filter(lot => !lot.isObsolete).map(lot => lot._id);
    },
    isRecipientInGroup: () => true,
    getGroupLabel: (groupId, structure, t) => {
      const lot = structure.lotById[groupId];
      const index = structure.lots.findIndex(lot => lot._id === groupId);

      return `${t('request.lot', { count: 1 })} ${index + 1} – ${lot.name}`;
    },
    showEmptyGroupHeader: () => true,
  },
  bidIntentionStatus: {
    getGroupIds: () => [
      BidIntentionStatus.BIDDING,
      BidIntentionStatus.NO_RESPONSE,
      BidIntentionStatus.BID_WITHDRAWN,
      BidIntentionStatus.DECLINED_TO_BID,
    ],
    isRecipientInGroup: (groupId, recipient, structure) => {
      return getBidIntentionStatus(structure.bidById[recipient._id]) === groupId;
    },
    getGroupLabel: (groupId, _structure, t) => {
      return t(`request.bidIntentionStatus.${groupId}.status`);
    },
    getGroupIconProps: (groupId) => ({
      fixedWidth: true,
      mr: 1,
      ...iconPropsByBidIntentionStatus.buyer[groupId],
    }),
    showEmptyGroupHeader: () => false,
  },
  bidOutcomeStatus: {
    getGroupIds: () => [
      BidOutcomeStatus.AWARDED,
      BidOutcomeStatus.UNSUCCESSFUL,
      BidOutcomeStatus.PENDING,
    ],
    isRecipientInGroup: (groupId, recipient, structure) => {
      return getBidOutcomeStatus(structure.bidById[recipient._id]) === groupId;
    },
    getGroupLabel: (groupId, _structure, t) => {
      return t(`request.bidOutcomeStatus.${groupId}.status`);
    },
    getGroupIconProps: (groupId) => ({
      fixedWidth: true,
      mr: 1,
      ...iconPropsByBidOutcomeStatus[groupId],
    }),
    showEmptyGroupHeader: () => false,
  },
};
