import * as React from 'react';
import { Flex } from 'rebass/styled-components';
import { size, isEmpty, first, get, orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { LabeledSorting } from '@deepstream/ui-utils';
import { SortingDirection } from '@deepstream/common';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils/types';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useModalState } from '../../../ui/useModalState';
import { Loading } from '../../../ui/Loading';
import { useLocalStorageSortProps } from '../../../sorting';
import { SelectDropdownMenu } from '../../../ui/MultiSelect';
import { RequestCount } from '../../../RequestCount';
import { MIN_CELL_HEIGHT } from '../../../FieldsCell';
import { User } from '../../../types';
import { ErrorMessage } from '../../../ui/ErrorMessage';
import { EmptyTableMessage } from '../../../ui/EmptyTableMessage';
import { useApi, wrap } from '../../../api';
import { useNotificationSubject } from '../../Notifications/useNotificationSubject';
import { TeamMembershipRequestsTable } from './TeamMembershipRequestsTable';
import { TeamMembershipDecisionModal } from './TeamMembershipDecisionModal';
import { useCurrentUser } from '../../../useCurrentUser';

const useSortItems = (): LabeledSorting[] => {
  const { t } = useTranslation();

  return React.useMemo(() => [
    { label: t('general.sorting.nameAscending'), accessor: 'name', direction: SortingDirection.ASC },
    { label: t('general.sorting.nameDescending'), accessor: 'name', direction: SortingDirection.DESC },
  ], [t]);
};

export const TeamMembershipRequests = () => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const api = useApi();
  const teamMembershipDecisionModal = useModalState();
  const [selectedUser, setSelectedUser] = React.useState<User | null>(null);

  const isAdmin = currentUser.roles[currentCompanyId].admin;

  const { data: users, status } = useQuery(
    ['membershipRequests', { companyId: currentCompanyId }],
    wrap(api.getMembershipRequests),
    {
      enabled: isAdmin,
    },
  );

  const openTeamMembershipDecisionModal = React.useCallback(
    async (user) => {
      setSelectedUser(user);
      teamMembershipDecisionModal.open();
    },
    [teamMembershipDecisionModal, setSelectedUser],
  );

  const requestsNotificationsRef = useNotificationSubject({
    filter: {
      domain: NotificationDomain.TEAM_MANAGEMENT,
      action: NotificationAction.REQUESTED_TO_JOIN,
      to: {
        companyId: currentCompanyId,
        userId: currentUser._id,
      },
    },
  });

  const sortItems = useSortItems();
  const sortProps = useLocalStorageSortProps({
    storageKey: `${currentCompanyId}.${currentUser._id}.teamMembershipRequests.sort`,
    items: sortItems,
  });

  const sortedUsers = React.useMemo(() => {
    if (!users) {
      return null;
    }

    let usersClone = [...users];

    if (!isEmpty(sortProps.selectedItems)) {
      const sorting = first(sortProps.selectedItems) as LabeledSorting;

      usersClone = orderBy(
        usersClone,
        [(user: User) =>
          get(user, sorting.accessor, '').toLowerCase()],
        [sorting.direction],
      );
    }

    return usersClone;
  }, [users, sortProps.selectedItems]);

  return (
    <>
      <Panel ref={requestsNotificationsRef}>
        <PanelHeader
          height="60px"
          pt="1px"
          pb="2px"
          heading={sortedUsers ? (
            <RequestCount count={size(sortedUsers)} />
          ) : null}
          collapse={false}
        >
          <SelectDropdownMenu
            rightAligned
            buttonText={t('general.sort')}
            buttonIcon="sort"
            menuWidth={160}
            menuZIndex={10}
            disabled={status !== 'success' || (!!sortedUsers && isEmpty(sortedUsers))}
            {...sortProps}
          />
        </PanelHeader>
        <PanelDivider />
        {status === 'success' && sortedUsers && !isEmpty(sortedUsers) ? (
          <TeamMembershipRequestsTable
            users={sortedUsers}
            canEditPermissions={isAdmin}
            openTeamMembershipDecisionModal={openTeamMembershipDecisionModal}
          />
        ) : (
          <Flex
            height={MIN_CELL_HEIGHT * 5}
            flexDirection="column"
            justifyContent="center"
          >
            <PanelPadding>
              {status === 'loading' ? (
                <Loading fontSize={4} fontWeight={400} />
              ) : status === 'error' ? (
                <ErrorMessage
                  fontSize={3}
                  error={t('teamManagement.errors.getRequestsToJoin')}
                />
              ) : (
                <EmptyTableMessage
                  header={t('teamManagement.noRequests')}
                  body={t('teamManagement.noRequestsToJoin')}
                />
              )}
            </PanelPadding>
          </Flex>
        )}
      </Panel>
      <TeamMembershipDecisionModal
        user={selectedUser}
        companyId={currentCompanyId}
        isOpen={teamMembershipDecisionModal.isOpen}
        close={teamMembershipDecisionModal.close}
      />
    </>
  );
};
