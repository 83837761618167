import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { isEmpty } from 'lodash';
import { Form, Formik, useField } from 'formik';
import * as yup from 'yup';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelPadding, PanelText } from '@deepstream/ui-kit/elements/Panel';
import { EditProductsPanel } from '../EditProductsPanel';
import * as rfx from '../rfx';
import { ViewProductsPanel } from '../ViewProductsPanel';
import { useUpdateSummary } from './useUpdateSummary';
import { SummaryPanelHeader } from './SummaryPanelHeader';
import { LeavePageModal } from './LeavePageModal';

const panelId = 'productsAndServices';

const EditProductsField = () => {
  const [field,, formik] = useField({ name: 'productsAndServices' });

  return (
    <EditProductsPanel value={field.value} onChange={formik.setValue} />
  );
};

export const SummaryProductsAndServicesPanel = () => {
  const { t } = useTranslation();
  const { stopEditing } = rfx.useActions();
  const { editingPanelId } = rfx.useState();
  const structure = rfx.useStructure();
  const [updateDraft] = useUpdateSummary();

  const isEditingOtherPanel = editingPanelId && editingPanelId !== panelId;
  const isEditingThisPanel = editingPanelId && editingPanelId === panelId;

  const { productsAndServices } = structure.summary;

  const initialValues = {
    productsAndServices: structure.summary.productsAndServices ?? [],
  };

  return (
    <Panel
      sx={{
        opacity: isEditingOtherPanel ? 0.5 : 1,
        boxShadow: isEditingThisPanel ? '0 0 8px 0 rgba(0, 0, 0, 0.3)' : '',
      }}
      mb={20}
    >
      <SummaryPanelHeader
        heading={t('productsAndServices.productsAndServices')}
        panelId={panelId}
      />
      <PanelDivider />
      {isEditingThisPanel ? (
        <Formik
          validateOnBlur
          enableReinitialize
          initialValues={initialValues}
          validationSchema={
            yup.object().shape({
              productsAndServices: yup.array().of(yup.object()),
            })
          }
          onSubmit={async (values) => {
            await updateDraft({
              values,
              initialValues,
            }, {
              onSuccess: () => stopEditing(),
            });
          }}
        >
          {({ isSubmitting, dirty, isValid, resetForm }) => (
            <Form>
              <EditProductsField />
              <PanelDivider />
              <PanelPadding>
                <Flex justifyContent="flex-end">
                  <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
                  <SaveButton disabled={isSubmitting || !dirty || !isValid} />
                </Flex>
              </PanelPadding>
              <LeavePageModal />
            </Form>
          )}
        </Formik>
      ) : isEmpty(productsAndServices) ? (
        <PanelText p={3} color="darkGray">
          <EmDash />
        </PanelText>
      ) : (
        <PanelPadding height="428px" overflowY="auto" fontSize={2}>
          <ViewProductsPanel productsAndServices={productsAndServices!} />
        </PanelPadding>
      )}
    </Panel>
  );
};
