import { useTranslation } from 'react-i18next';
import * as rfx from './rfx';
import { useCurrentCompanyId } from './currentCompanyId';
import { RecipientIdProvider, RfqIdProvider, useLiveRfqStructure, useRecipientId } from './useRfq';
import { LoadingPanel } from './ui/Loading';
import { ErrorPanel } from './ui/ErrorMessage';
import { RequestTeamUsersPanel } from './modules/Request/Team/RequestTeamUsersPanel';
import { useSaveBidTeamChanges } from './useSaveRequestTeamChanges';

const RequestTeamUsersPanelWrapper = () => {
  const [saveChanges] = useSaveBidTeamChanges();
  const recipientId = useRecipientId();
  const structure = rfx.useStructure();

  const recipient = structure.recipients.find(recipient => recipient._id === recipientId);

  return recipient ? (
    <rfx.SaveChangesProvider saveChanges={saveChanges}>
      <RequestTeamUsersPanel
        mt={1}
        company={recipient.company}
      />
    </rfx.SaveChangesProvider>
  ) : (
    null
  );
};

export type BidTeamProps = {
  rfqId: string;
  recipientId: string;
};

export const BidTeam = ({ rfqId, recipientId }: BidTeamProps) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { data: rfxStructure, isLoading, isError } = useLiveRfqStructure({
    rfqId,
    currentCompanyId,
    recipientId,
  });

  return (
    <RfqIdProvider rfqId={rfqId}>
      <RecipientIdProvider recipientId={recipientId}>
        <rfx.StateProvider isLive>
          {isLoading ? (
            <LoadingPanel />
          ) : isError ? (
            <ErrorPanel error={t('errors.unexpected')} />
          ) : rfxStructure ? (
            <rfx.StructureProvider structure={rfxStructure}>
              <RequestTeamUsersPanelWrapper />
            </rfx.StructureProvider>
          ) : (
            null
          )}
        </rfx.StateProvider>
      </RecipientIdProvider>
    </RfqIdProvider>
  );
};
