import { useTranslation } from 'react-i18next';
import {
  QuestionExchangeDefinition,
  QuestionResponseByType,
  QuestionType,
  createFormatQuestionResponse,
} from '@deepstream/common/rfq-utils';
import { Box } from 'rebass/styled-components';
import { amendQuestionType } from '@deepstream/common/rfq-utils/exchangeAction';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { withProps } from '@deepstream/ui-utils/withProps';
import { isPlainObject } from 'lodash';
import { PreWrap } from './PreWrapCell';
import { useCurrentUserLocale } from './useCurrentUser';

interface QuestionResponseProps {
  response:
    QuestionResponseByType<QuestionType.SHORT_TEXT> |
    QuestionResponseByType<QuestionType.LONG_TEXT> |
    QuestionResponseByType<QuestionType.MULTIPLE_CHOICE> |
    QuestionResponseByType<QuestionType.CHECKBOXES> |
    QuestionResponseByType<QuestionType.PRICE> |
    QuestionResponseByType<QuestionType.ADDRESS> |
    QuestionResponseByType<QuestionType.DATE_TIME> |
    QuestionResponseByType<QuestionType.DOCUMENT> |
    QuestionResponseByType<QuestionType.GRID> |
    QuestionResponseByType<QuestionType.YES_NO>;
  exchangeDef: QuestionExchangeDefinition;
  isMultiLine?: boolean;
  isInline?: boolean;
  historySlice?: any[];
}

export function QuestionResponse({
  response,
  exchangeDef,
  isMultiLine,
  isInline = true,
  historySlice,
}: QuestionResponseProps) {
  const { t } = useTranslation(['translation', 'general']);
  const locale = useCurrentUserLocale();

  const Wrapper = isMultiLine
    ? PreWrap
    : isInline ? withProps(Box, { display: 'inline' }) : Truncate;

  const effectiveExchangeDef = historySlice
    ? amendQuestionType(exchangeDef, historySlice)
    : exchangeDef;

  const formatQuestionResponse = createFormatQuestionResponse({
    t,
    locale,
  });
  const formattedResponse = formatQuestionResponse({
    response,
    exchangeDef: effectiveExchangeDef,
    isMultiLine,
  });

  // TODO how should we handle grid responses in places where we render
  // the QuestionResponse component (for example, in the exchange history)?
  // Below is a workaround that renders nothing to prevent the app from
  // crashing (`formattedResponse` is currently an object
  // in the case of a grid response)
  return isPlainObject(formattedResponse) ? (
    null
  ) : (
    <Wrapper>
      {formattedResponse}
    </Wrapper>
  );
}
