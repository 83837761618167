import { unary } from 'lodash';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { callAll } from '@deepstream/utils/callAll';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { useModalState } from '../../ui/useModalState';
import { User } from '../../types';
import { useApi } from '../../api';
import { CurrentAvatar } from '../../CurrentAvatar';
import { TextField } from '../../form/TextField';
import { AvatarField } from '../../form/Field';
import { ModalForm, ModalFormProps } from '../../ModalForm';
import { PropertyList } from '../../PropertyList';
import { useCurrentUser } from '../../useCurrentUser';
import { useUpdateUserProfile } from './useUserMutation';
import { EditProperty, ValueOrNotSpecified } from './utils';
import { useMutation } from '../../useMutation';

type EditNameModalProps =
  Omit< ModalFormProps, 'heading' | 'initialValues' | 'validationSchema' | 'onSubmit'> &
  { user: User; onSuccess: any };

const EditNameModal = ({ user, onSuccess, ...props }: EditNameModalProps) => {
  const { t } = useTranslation();

  const [updateUserProfile] = useUpdateUserProfile({
    fieldName: t('general.name'),
    onSuccess,
  });

  return (
    <ModalForm
      heading={t('user.profile.dialog.editName.heading')}
      initialValues={{
        firstName: user.firstName,
        lastName: user.lastName,
      }}
      validationSchema={yup.object({
        firstName: yup.string().required(t('general.required')),
        lastName: yup.string().required(t('general.required')),
      })}
      onSubmit={unary(updateUserProfile)}
      {...props}
    >
      <TextField
        name="firstName"
        label={t('general.firstName')}
        required
        data-test="firstName"
      />
      <TextField
        name="lastName"
        label={t('general.lastName')}
        required
        data-test="lastName"
      />
    </ModalForm>
  );
};

const EditPhotoModal = ({ user, onSuccess, ...props }: EditNameModalProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const api = useApi();
  const [uploadAvatar] = useMutation(
    api.uploadAvatar, {
      onSuccess: callAll(
        () => queryClient.invalidateQueries(['avatar']),
        () => queryClient.invalidateQueries(['userAvatar', { userId: user._id }]),
      ),
    });

  const [updateUserProfile] = useUpdateUserProfile({
    fieldName: t('user.profile.photo'),
    onSuccess: callAll(
      () => queryClient.invalidateQueries(['avatar']),
      () => queryClient.invalidateQueries(['userAvatar', { userId: user._id }]),
      onSuccess,
    ),
  });

  return (
    <ModalForm
      heading={t('user.profile.dialog.editPhoto.heading')}
      initialValues={{
        avatarId: user.avatarId,
      }}
      validationSchema={yup.object({
        avatarId: yup.string().nullable(),
      })}
      onSubmit={unary(updateUserProfile)}
      {...props}
    >
      <AvatarField
        name="avatarId"
        userId={user._id}
        uploadFn={uploadAvatar}
      />
    </ModalForm>
  );
};

export const ProfilePanel = () => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const editNameModal = useModalState();
  const editPhotoModal = useModalState();

  return (
    <>
      <Panel heading={t('general.profile')}>
        <PropertyList
          properties={[
            { name: t('general.firstName'), value: user.firstName },
            { name: t('general.lastName'), value: user.lastName },
            { name: t('user.profile.photo'), value: null, Component: () => <CurrentAvatar /> },
          ]}
          DefaultComponent={ValueOrNotSpecified}
        >
          <EditProperty onClick={editNameModal.open} rows={2} data-test="edit-name" />
          <EditProperty onClick={editPhotoModal.open} data-test="edit-photo" />
        </PropertyList>
      </Panel>
      <EditNameModal
        user={user}
        isOpen={editNameModal.isOpen}
        onSuccess={editNameModal.close}
        onCancel={editNameModal.close}
      />
      <EditPhotoModal
        user={user}
        isOpen={editPhotoModal.isOpen}
        onSuccess={editPhotoModal.close}
        onCancel={editPhotoModal.close}
      />
    </>
  );
};
