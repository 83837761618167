import { useContext } from 'react';
import * as Sentry from '@sentry/react';
import { Flex, Box } from 'rebass/styled-components';
import { UnexpectedStateError } from '@deepstream/errors';
import { QuestionType, QuestionExchangeDefinition, YesNoQuestionExchangeDefinition } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Radio } from '../../../ui/Radio';

export const YesNo = ({
  exchangeDef,
}: {
  exchangeDef: QuestionExchangeDefinition;
}) => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);

  const {
    options,
    questionType,
    allowCustomOption,
    requireMoreInformationOn,
  } = exchangeDef as YesNoQuestionExchangeDefinition;

  if (questionType !== QuestionType.YES_NO) {
    Sentry.captureException(new UnexpectedStateError('[YesNo] Wrong question type passed', { questionType }));
    return null;
  }

  return (
    <Stack gap={2}>
      {options.map((option) => (
        <Flex key={option} alignItems="center">
          <Box sx={{ width: '100px' }}>
            <Radio
              disabled
              label={t(`request.question.predefinedOption.${option}`)}
              useDefaultDisabledStyle={false}
              labelStyle={{ color: theme.colors.text, fontWeight: 400 }}
            />
          </Box>
          <Box>
            <Checkbox
              disabled
              checked={requireMoreInformationOn?.includes(option)}
              label={t(`request.question.yesNo.requestSupportingInformation.${option}`)}
              useDefaultDisabledStyle={false}
            />
          </Box>
        </Flex>
      ))}
      {allowCustomOption && (
        <Radio
          disabled
          label={t('request.question.other')}
          useDefaultDisabledStyle={false}
          labelStyle={{ color: theme.colors.text, fontWeight: 400 }}
        />
      )}
    </Stack>
  );
};
