import * as Layout from '../../Layout';
import { SupplierListHeader } from './SupplierListHeader';
import { SuppliersTab } from './SuppliersTab';

export const SupplierListSuppliersPageContent = () => {
  return (
    <Layout.HeaderAndContent
      header={<SupplierListHeader />}
      content={<SuppliersTab />}
      contentPaddingTop="20px"
    />
  );
};
