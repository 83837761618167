import { useCallback } from 'react';
import { Draft, RfxOtherSection } from '@deepstream/common/rfq-utils';
import { useField } from 'formik';
import { compact } from 'lodash';
import * as rfx from '../rfx';

export const useHandleQuestionSectionLotChange = () => {
  const section = rfx.useSection<RfxOtherSection>();
  const { exchangeDefById } = rfx.useStructure<Draft>();
  const [{ value: formSection }, , sectionFormik] = useField({ name: 'section' });
  const [{ value: formExchangeDefs }, , exchangeDefsFormik] = useField({ name: 'exchangeDefs' });

  return useCallback((newLotIds: string[] | null, isNewValueObsolete: boolean, wasPreviousValueObsolete: boolean) => {
    if (isNewValueObsolete && !wasPreviousValueObsolete) {
      sectionFormik.setValue({
        ...section.liveVersion,
        isLive: formSection.isLive,
        liveVersion: formSection.liveVersion,
        lotIds: newLotIds,
        isObsolete: true,
      });

      exchangeDefsFormik.setValue(
        compact(
          // @ts-expect-error ts(18048) FIXME: 'section.liveVersion' is possibly 'undefined'.
          section.liveVersion.exchangeDefIds.map(exchangeDefId => {
            const exchangeDef = exchangeDefById[exchangeDefId];

            if (!exchangeDef?.isLive) {
              return false;
            }

            return {
              ...exchangeDef.liveVersion,
              isLive: exchangeDef.isLive,
              liveVersion: exchangeDef.liveVersion,
              isObsolete: true,
            };
          }),
        ),
      );
    } else if (!isNewValueObsolete && wasPreviousValueObsolete) {
      sectionFormik.setValue({
        ...formSection,
        lotIds: newLotIds,
        isObsolete: false,
      });

      exchangeDefsFormik.setValue(
        formExchangeDefs.map(exchangeDef => {
          return exchangeDef.liveVersion.isObsolete
            // don't set `isObsolete` to false when the live version
            // of the exchangeDef is obsolete
            ? exchangeDef
            : {
              ...exchangeDef,
              isObsolete: false,
            };
        }),
      );
    } else {
      sectionFormik.setValue({
        ...formSection,
        lotIds: newLotIds,
      });
    }
  }, [sectionFormik, section.liveVersion, formSection, exchangeDefsFormik, exchangeDefById, formExchangeDefs]);
};
