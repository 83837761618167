import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useRfqId, useDraftRfqStructureQueryKey } from '../useRfq';
import { useApi } from '../api';
import { useCurrentCompanyId } from '../currentCompanyId';
import { useToaster } from '../toast';
import * as rfx from '../rfx';
import { useMutation } from '../useMutation';

export const useUpdateRequestCurrency = () => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { isTemplate } = rfx.useState();
  const rfqId = useRfqId();
  const draftStructureQueryKey = useDraftRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
    isTemplate,
  });
  const queryClient = useQueryClient();

  return useMutation(
    ({ currencyCode }: { currencyCode: string }) => api.updateRequestCurrency({
      currentCompanyId,
      rfqId,
      isTemplate,
      currencyCode,
    }),
    {
      mutationKey: draftStructureQueryKey,
      onSuccess: () => toaster.success(t('request.toaster.changesSavedSuccess')),
      onError: () => toaster.error(t('request.toaster.changesSavedError')),
      onSettled: () => queryClient.invalidateQueries(draftStructureQueryKey),
    },
  );
};
