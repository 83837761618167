import * as Sentry from '@sentry/react';
import * as yup from 'yup';
import { Box, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { TextField } from '../../form/TextField';
import { ModalForm, ModalFormProps } from '../../ModalForm';
import { PropertyList } from '../../PropertyList';
import { useModalState } from '../../ui/useModalState';
import { Company } from '../../ui/types';
import { useUpdateUserProfile } from './useUserMutation';
import { Label } from './Label';
import { EditProperty, ValueOrNotSpecified } from './utils';
import { useCurrentUser } from '../../useCurrentUser';
import { User } from '../../types';

type EditJobTitleModalProps =
  Omit<ModalFormProps, 'heading' | 'initialValues' | 'validationSchema' | 'onSubmit'> &
  { user: User; company: Company; onSuccess: any };

const EditJobTitleModal = ({ user, company, onSuccess, ...props }: EditJobTitleModalProps) => {
  const { t } = useTranslation('company');
  const [updateUserProfile] = useUpdateUserProfile({
    fieldName: t('role'),
    onSuccess,
  });

  return (
    <ModalForm
      heading={t('editRole')}
      initialValues={{
        role: user.jobTitles?.[company._id],
      }}
      validationSchema={yup.object({
        role: yup.string(),
      })}
      onSubmit={async ({ role }, { setSubmitting }) => {
        try {
          await updateUserProfile({
            jobTitles: {
              [company._id]: role.trim(),
            },
          });
        } catch (error) {
          Sentry.captureException(error);
        } finally {
          setSubmitting(false);
        }
      }}
      {...props}
    >
      <Box>
        <Label label={t('company')} htmlFor="companyName" />
        <Text id="companyName">
          {company.name}
        </Text>
      </Box>
      <TextField name="role" label={t('role')} />
    </ModalForm>
  );
};

export const CompanyPanel = ({ company }) => {
  const { t } = useTranslation('company');
  const user = useCurrentUser();
  const editJobTitleModal = useModalState();

  return (
    <>
      <Panel heading={company.name}>
        <PropertyList
          properties={[{
            name: t('yourRole'),
            value: user.jobTitles?.[company._id],
          }]}
          DefaultComponent={ValueOrNotSpecified}
        >
          <EditProperty
            onClick={editJobTitleModal.open}
            data-test={`edit-job-title-${company._id}`}
          />
        </PropertyList>
      </Panel>
      <EditJobTitleModal
        user={user}
        company={company}
        isOpen={editJobTitleModal.isOpen}
        onSuccess={editJobTitleModal.close}
        onCancel={editJobTitleModal.close}
      />
    </>
  );
};
