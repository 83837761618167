import { useTranslation } from 'react-i18next';
import { PanelHeader, Panel, PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { DeliveryTable } from './DeliveryTable';

export const DraftDeliverySection = () => {
  const { t } = useTranslation();

  return (
    <Panel>
      <PanelHeader
        heading={t('request.vesselPricing.deliveryAndRedelivery')}
        bg="lightGray3"
      />
      <PanelDivider />
      <DeliveryTable />
    </Panel>
  );
};
