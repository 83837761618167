import { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box } from 'rebass/styled-components';
import { useQuery, useQueryClient } from 'react-query';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { differenceBy, first, some } from 'lodash';
import { ChangeType, Vessel, RfxVesselPricingSection } from '@deepstream/common/rfq-utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { groupBy2d } from '@deepstream/utils/groupBy2d';
import { callAll } from '@deepstream/utils/callAll';
import { Button, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelHeader, SidebarPanelHeading } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { Dialog, DialogProps } from '@deepstream/ui-kit/elements/popup/Dialog';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import * as rfx from './rfx';
import { LabeledValue, LabeledNumber } from './draft/LabeledValue';
import { CurrencyCodeProvider } from './ui/Currency';
import { useApi, wrap } from './api';
import { useCurrentCompanyId } from './currentCompanyId';
import { Tab, TabListPanel, TabPanel, TabPanels, Tabs } from './ui/TabsVertical';
import { useRfqId, useRfqExchanges, useRecipientId, useLiveRfqStructureQueryKey } from './useRfq';
import { useConfirmDialog, useModalState } from './ui/useModalState';
import { useToaster } from './toast';
import { TextField } from './form/TextField';
import { useMutation } from './useMutation';
import { Label } from './ui/Label';
import { InclusionsBidSection } from './InclusionsBidSection';
import { TermsBidSection } from './TermsBidSection';
import { VesselBidTab } from './VesselBidTab';
import { useHaveDeadlinesPassed } from './deadline';
import { Locked } from './lock';
import { SidebarLayout } from './ui/ProfileLayout';
import { useDeviceSize } from './ui/useDeviceSize';
import { Loading } from './ui/Loading';

type ConfirmationDialogProps = Omit<DialogProps, 'heading' | 'body'> & {
  vesselName: string;
};

const ConfirmationDialog = ({ vesselName, ...props }: ConfirmationDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      body={
        <>
          <Label label={t('request.vesselPricing.vessel', { count: 1 })}>
            {vesselName}
          </Label>
          <MessageBlock variant="warn" mt={3}>
            {t('request.vesselPricing.deleteVesselConfirmation')}
          </MessageBlock>
        </>
      }
      style={{ content: { width: '500px' } }}
      okButtonText={t('request.vesselPricing.deleteVessel')}
      okButtonVariant="danger"
      cancelButtonText={t('general.cancel')}
      heading={t('request.vesselPricing.deleteVesselConfirmationHeader')}
      {...props}
    />
  );
};

interface AddVesselArgs {
  rfqId: string;
  recipientId: string;
  sectionId: string;
  companyId: string;
  onSuccess?: () => void;
  onError?: () => void;
  onSettled?: () => void;
}

const useAddVessel = ({ rfqId, recipientId, sectionId, companyId }: AddVesselArgs) => {
  const toaster = useToaster();
  const { t } = useTranslation();
  const api = useApi();
  const queryClient = useQueryClient();
  const rfqStructureQueryKey = useLiveRfqStructureQueryKey({
    currentCompanyId: companyId,
    rfqId,
    recipientId,
  });

  return useMutation(
    vessel => api.updateBidAssets({
      rfqId,
      recipientId,
      companyId,
      sectionId,
      changes: [{
        type: ChangeType.ASSET_ADDED,
        asset: vessel,
        sectionName: sectionId,
        companyId,
      }],
    }), {
      onError: () => toaster.error(t('request.vesselPricing.toaster.vesselAddedError')),
      onSuccess: callAll(
        () => toaster.success(t('request.vesselPricing.toaster.vesselAddedSuccess')),
        () => queryClient.invalidateQueries(rfqStructureQueryKey),
      ),
    },
  );
};

type AddVesselModalProps = ModalProps & {
  savedVessels: any[];
  onSuccess: () => void;
  onCancel: () => void;
};

const CreateVesselModal = ({ isOpen, onCancel, onSuccess, savedVessels }: AddVesselModalProps) => {
  const { t } = useTranslation();
  const api = useApi();
  const queryClient = useQueryClient();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const section = rfx.useSection();
  const toaster = useToaster();
  const rfqId = useRfqId({ required: true });
  const recipientId = useRecipientId();

  const [createAsset] = useMutation(
    api.createAsset,
    {
      onError: () => toaster.error(t('request.vesselPricing.toaster.vesselCreatedError')),
      onSuccess: () => queryClient.invalidateQueries(['assets', { companyId: currentCompanyId, type: 'vessel' }]),
    },
  );

  const [addVessel] = useAddVessel({
    rfqId,
    recipientId,
    companyId: currentCompanyId,
    sectionId: section._id,
  });

  return (
    <Modal
      shouldCloseOnEsc
      isOpen={isOpen}
      onRequestClose={onCancel}
      style={{ content: { width: '500px' } }}
    >
      <Formik<{ name: string }>
        validateOnBlur
        initialValues={{
          name: '',
        }}
        validationSchema={
          yup.object().shape({
            name: yup.string()
              .test(
                'uniqueName',
                t('request.vesselPricing.errors.duplicateVesselName'),
                value => !savedVessels.find(({ name }) =>
                  name.toLowerCase().trim() === value?.toLowerCase().trim(),
                ),
              )
              .required(t('request.vesselPricing.errors.nameRequired')),
          })
        }
        onSubmit={async ({ name }, { setSubmitting }) => {
          const vessel = await createAsset({
            companyId: currentCompanyId,
            name,
            type: 'vessel',
          }, {
            onError: () => setSubmitting(false),
          });

          await addVessel(
            vessel,
            {
              onSettled: () => setSubmitting(false),
              onSuccess,
            },
          );
        }}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              {t('request.vesselPricing.addNewVessel')}
            </ModalHeader>
            <ModalBody>
              <TextField
                required
                name="name"
                label={t('general.name')}
              />
            </ModalBody>
            <ModalFooter>
              <CancelButton type="button" onClick={onCancel} mr={2} />
              <Button variant="primary" disabled={isSubmitting || !dirty || !isValid}>
                {t('request.vesselPricing.addVessel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export const VesselPricingBidSection = () => {
  const { t } = useTranslation();
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqId = useRfqId({ required: true });
  const recipientId = useRecipientId();
  const structure = rfx.useStructure();
  const section = rfx.useSection<RfxVesselPricingSection>();
  const isRecipient = currentCompanyId === recipientId;
  const createVesselModal = useModalState();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const { confirm, ...dialogProps } = useConfirmDialog();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const haveDeadlinesPassed = useHaveDeadlinesPassed();
  const isBidding = rfx.useIsBidding();
  const { isExtraSmall, isSmall } = useDeviceSize();
  const { vessels: addedVessels = [] } = structure.bidById[recipientId];
  const pagePermissions = rfx.usePagePermissions();
  const rfqStructureQueryKey = useLiveRfqStructureQueryKey({
    currentCompanyId,
    rfqId,
    recipientId,
  });

  const { data: exchanges = [], isLoading } = useRfqExchanges({
    recipientId,
    sectionIds: [section._id],
    enabled: Boolean(section) && Boolean(recipientId),
  });

  const exchangesInitiatedBy = groupBy2d(
    exchanges,
    exchange => {
      const exchangeDef = structure.exchangeDefById[exchange._id];

      // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
      return exchange.companies[exchangeDef.creatorId].group!;
    },
    exchange => exchange.def.type,
  );

  const { data: savedVessels = [] } = useQuery(
    ['assets', { companyId: currentCompanyId, type: 'vessel' }],
    wrap(api.getAssets),
  );

  const availableVessels = useMemo(
    () => differenceBy<Vessel, Vessel>(savedVessels, addedVessels, '_id'),
    [savedVessels, addedVessels],
  );

  const [updateBidAssets, { isLoading: updatingBidAssets }] = useMutation(
    api.updateBidAssets,
    {
      onSuccess: () => queryClient.invalidateQueries(rfqStructureQueryKey),
    },
  );

  const removeVessel = useCallback(
    (vesselId) => {
      updateBidAssets({
        rfqId,
        recipientId,
        companyId: currentCompanyId,
        sectionId: section._id,
        changes: [{
          type: ChangeType.ASSET_REMOVED,
          assetId: vesselId,
          companyId: currentCompanyId,
          sectionName: section._id,
        }],
      }, {
        onError: () => toaster.error(t('request.vesselPricing.toaster.vesselRemovedError')),
        onSuccess: callAll(
          () => toaster.success(t('request.vesselPricing.toaster.vesselRemovedSuccess')),
          () => setSelectedTabIndex(0),
        ),
      });
    },
    [section._id, currentCompanyId, rfqId, recipientId, updateBidAssets, t, toaster],
  );

  const [addVessel] = useAddVessel({
    rfqId,
    recipientId,
    companyId: currentCompanyId,
    sectionId: section._id,
  });

  const onTabsChange = useCallback(
    (index) => setSelectedTabIndex(index),
    [],
  );

  const hasBuyerAddedInclusions = exchangesInitiatedBy.sender?.inclusionsAndExclusions?.length > 0;
  const hasBuyerAddedTerms = exchangesInitiatedBy.sender?.additionalTerms?.length > 0;
  const showGeneralTab = (
    hasBuyerAddedInclusions ||
    hasBuyerAddedTerms ||
    section.allowSuppliersToAddAdditionalTerms ||
    section.allowSuppliersToAddInclusionsAndExclusions
  );

  const tabIndexOffset = showGeneralTab ? 1 : 0;

  const onCreateVesselSuccess = useCallback(
    () => {
      createVesselModal.close();
      setSelectedTabIndex(addedVessels.length + tabIndexOffset);
    },
    [createVesselModal, addedVessels.length, tabIndexOffset],
  );

  const currencyCode = first(exchangesInitiatedBy.sender?.currency as any[])?.latestCurrency ?? '';
  const actionsDisabled = (
    updatingBidAssets ||
    haveDeadlinesPassed ||
    !pagePermissions.canRespond ||
    !isBidding ||
    section.isObsolete
  );

  const shouldLockVesselNames = some(
    exchanges,
    exchange => exchange.isLocked && exchange.roles.includes('evaluator'),
  );

  const tabsKey = `${isLoading}-${showGeneralTab}-${addedVessels.map(vessel => vessel._id).join('-')}`;

  return (
    <CurrencyCodeProvider code={currencyCode}>
      <>
        <Panel>
          <PanelHeader heading={section.name} description={section.description} icon="ship">
            <Flex>
              <LabeledValue
                label={t('general.currency')}
                value={currencyCode || <EmDash />}
                mr={3}
              />
              <LabeledNumber
                label={t('request.vesselPricing.vessel_other')}
                number={addedVessels.length}
              />
            </Flex>
          </PanelHeader>
          <PanelDivider />
          <Tabs key={tabsKey} index={selectedTabIndex} onChange={onTabsChange}>
            <Box p={20}>
              <SidebarLayout
                sidebar={
                  <TabListPanel panelStyle={{ height: 'fit-content' }}>
                    {showGeneralTab && (
                      <>
                        <Tab data-test="general-tab">
                          {t('request.vesselPricing.general')}
                        </Tab>
                        <Box py="12px" mx="-12px">
                          <PanelDivider />
                        </Box>
                      </>
                    )}
                    <Flex justifyContent="space-between" alignItems="center" p={2}>
                      <SidebarPanelHeading>
                        {t('request.vesselPricing.vessel_other')}
                      </SidebarPanelHeading>
                      {isRecipient ? (
                        availableVessels.length ? (
                          <DropdownMenu
                            iconLeft="plus"
                            small
                            buttonText={t('request.vesselPricing.addVessel')}
                            variant={addedVessels.length ? 'secondary-outline' : 'primary-outline'}
                            menuStyle={{ width: '144px' }}
                            rightAligned
                            menuListStyle={{ maxHeight: '138px', overflowY: 'auto' }}
                            disabled={actionsDisabled}
                            menuZIndex={10}
                            header={(
                              <Box p="12px 12px 8px">
                                <SidebarPanelHeading>
                                  {t('request.vesselPricing.savedVessels')}
                                </SidebarPanelHeading>
                              </Box>
                            )}
                            footerMenuItem={(
                              <DropdownMenuItem onSelect={createVesselModal.open}>
                                <IconText icon="plus" text={t('request.vesselPricing.addNewVessel')} />
                              </DropdownMenuItem>
                            )}
                          >
                            {availableVessels.map(vessel => (
                              <DropdownMenuItem
                                key={vessel._id}
                                onSelect={() => addVessel(
                                  vessel,
                                  {
                                    onSuccess: () => setSelectedTabIndex(addedVessels.length + tabIndexOffset),
                                  },
                                )}
                              >
                                <Flex alignItems="center">
                                  <Icon icon="ship" mr={1} />
                                  <Truncate>{vessel.name}</Truncate>
                                </Flex>
                              </DropdownMenuItem>
                            ))}
                          </DropdownMenu>
                        ) : (
                          <Button
                            iconLeft="plus"
                            small
                            variant={addedVessels.length ? 'secondary-outline' : 'primary-outline'}
                            onClick={createVesselModal.open}
                            disabled={actionsDisabled}
                          >
                            {t('request.vesselPricing.addVessel')}
                          </Button>
                        )
                      ) : (
                        null
                      )}
                    </Flex>
                    {isLoading ? (
                      <Loading />
                    ) : addedVessels.length > 0 ? (
                      addedVessels.map((vessel, index) => (
                        <Tab key={vessel._id} style={{ display: 'flex', alignItems: 'center', height: '32px' }}>
                          {shouldLockVesselNames ? (
                            <Locked />
                          ) : (
                            <Flex alignItems="center" justifyContent="space-between">
                              <Truncate>
                                {vessel.name}
                              </Truncate>
                              {isRecipient && selectedTabIndex === index + tabIndexOffset && (
                                <Button
                                  small
                                  iconLeft="trash"
                                  variant="danger-outline"
                                  ml={2}
                                  sx={{ background: 'transparent' }}
                                  onClick={() => confirm(() => removeVessel(vessel._id))}
                                  disabled={actionsDisabled}
                                />
                              )}
                            </Flex>
                          )}
                        </Tab>
                      ))
                    ) : (
                      <Box p={2}>
                        <IconText
                          color="lightGray5"
                          iconColor="danger"
                          icon="exclamation-circle"
                          text={t('request.vesselPricing.noVessels')}
                          fontWeight={500}
                        />
                      </Box>
                    )}
                  </TabListPanel>
                }
                main={
                  <TabPanels>
                    {showGeneralTab && (
                      <TabPanel>
                        <Stack gap="20px">
                          <InclusionsBidSection />
                          <TermsBidSection />
                        </Stack>
                      </TabPanel>
                    )}
                    {addedVessels.map(vessel => (
                      <TabPanel key={vessel._id}>
                        <VesselBidTab vessel={vessel} isLocked={shouldLockVesselNames} />
                      </TabPanel>
                    ))}
                  </TabPanels>
                }
                sidebarStyle={!isExtraSmall && !isSmall ? { maxWidth: '220px', flex: '0 0 auto', pr: '20px !important' } : undefined}
                mainStyle={!isExtraSmall && !isSmall ? { flex: '1 1 auto' } : undefined}
              />
            </Box>
          </Tabs>
        </Panel>
        <CreateVesselModal
          isOpen={createVesselModal.isOpen}
          onSuccess={onCreateVesselSuccess}
          onCancel={createVesselModal.close}
          savedVessels={savedVessels}
        />
      </>
      <ConfirmationDialog
        vesselName={addedVessels[selectedTabIndex - tabIndexOffset]?.name}
        {...dialogProps}
      />
    </CurrencyCodeProvider>
  );
};
