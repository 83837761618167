import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { v4 as uuid } from 'uuid';
import { noop } from 'lodash';
import { ActionType, Attachment, ExchangeType } from '@deepstream/common/rfq-utils';
import { callAll } from '@deepstream/utils/callAll';
import { useApi } from './api';
import { useCurrentCompanyId } from './currentCompanyId';
import { useSession } from './auth';
import { useMutation } from './useMutation';
import * as rfx from './rfx';
import { useLiveRfqStructureQueryKey, useRfqExchangesQueryKey } from './useRfq';

type InitiateChatLikeExchangeParams = {
  type:
    | ExchangeType.CLARIFICATION
    | ExchangeType.CHAT
    | ExchangeType.CHAT_NO_RECEIVER_UPLOAD
    | ExchangeType.CHAT_ONLY_RECEIVER_UPLOAD;
  recipientId: string;
  subject: string;
  message: string;
  attachments?: Attachment[];
};

export const useStartChatLikeExchange = ({
  rfqId,
  recipientId,
  sectionId,
  onSettled = noop,
  onError = noop,
  onSuccess = noop,
}) => {
  const { user } = useSession()!;
  const currentCompanyId = useCurrentCompanyId()!;
  const api = useApi();
  const queryClient = useQueryClient();
  const senders = rfx.useSenders();

  const exchangesQueryKey = useRfqExchangesQueryKey({
    sectionIds: [sectionId],
    recipientId,
  });

  const liveRfqStructureQueryKey = useLiveRfqStructureQueryKey({
    currentCompanyId,
    rfqId,
    recipientId,
  });

  return useMutation(
    useCallback(
      async ({
        type,
        recipientId,
        subject,
        message,
        attachments = [],
      }: InitiateChatLikeExchangeParams) => {
        const basePayload = {
          rfqId,
          senderId: senders[0]._id,
          recipientId,
          currentCompanyId,
        };

        const exchangeId = uuid();

        await api.initiateExchanges({
          ...basePayload,
          sectionId,
          exchangeDefs: [{
            _id: exchangeId,
            type,
            name: subject.trim(),
          }],
        });

        await api.sendExchangeReply({
          ...basePayload,
          exchangeId,
          action: {
            value: ActionType.NONE,
            comment: message,
            attachments,
            companyId: currentCompanyId,
            date: Date.now() as any,
            user: { _id: user?._id, name: user?.name } as any,
          },
        });

        return { exchangeId, recipientId };
      },
      [rfqId, senders, currentCompanyId, api, sectionId, user],
    ),
    {
      onError,
      onSuccess,
      onSettled: callAll(
        onSettled,
        () => queryClient.invalidateQueries(liveRfqStructureQueryKey),
        () => queryClient.invalidateQueries(exchangesQueryKey),
        () => queryClient.invalidateQueries(['allExchanges', { rfqId, currentCompanyId }]),
        () => queryClient.invalidateQueries(['statsByRecipientId', { rfqId, currentCompanyId }]),
      ),
    },
  );
};
