import { v4 as uuid } from 'uuid';
import { Box, Flex, FlexProps, Heading, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { EvaluationPage, LinkedEvaluationSection, PageType } from '@deepstream/common/rfq-utils';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { EvaluationSectionPanel } from '../../draft/EvaluationSectionPanel';
import { Disclosure } from '../../ui/Disclosure';
import * as rfx from '../../rfx';
import * as draft from '../../draft/draft';
import { HiddenRequestPagePlaceholder } from '../../HiddenRequestPagePlaceholder';
import { SectionsPlaceholder } from '../../draft/DraftPage';
import { useModalState } from '../../ui/useModalState';
import { DraftEvaluationPageHeader } from './DraftEvaluationPageHeader';
import { AddLinkedSectionsModal } from './AddLinkedSectionsModal';

type SectionType = 'separate' | 'linked';

const SectionIcon = ({
  sectionType,
  iconProps = {},
  iconWidth,
  iconHeight,
  sx = {},
  ...props
}: FlexProps & {
  sectionType?: SectionType;
  iconProps?: Omit<IconProps, 'icon'>;
  iconWidth?: number | string;
  iconHeight?: number | string;
}) => (
  <Flex
    backgroundColor="white"
    color="subtext"
    height="39px"
    width="39px"
    justifyContent="center"
    alignItems="center"
    sx={{
      border: 'lightGray2',
      borderRadius: '3px',
      ...sx,
    }}
    {...props}
  >
    <Icon
      icon={sectionType === 'linked' ? 'link' : 'plus'}
      fontSize={3}
      mb="1px"
      {...iconProps}
    />
  </Flex>
);

const AddSectionDropdown = ({
  onSelect,
  onExpandedStateChange,
  ...props
}: Omit<ButtonProps, 'onSelect'> & {
  onSelect: (sectionType: SectionType) => void;
  onExpandedStateChange?: (isExpanded: boolean) => void;
}) => {
  const { t } = useTranslation('evaluation');
  const rfxStructure = rfx.useStructure();
  const page = rfx.usePage() as EvaluationPage;
  // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const linkedPage = rfxStructure.pageById[page.linkedPageId];
  const linkedPageIsAuctionPage = linkedPage.type === PageType.AUCTION;

  return (
    <Box>
      <DropdownMenu
        buttonText={t('addSectionDropdown.button')}
        iconLeft="plus"
        iconRight="caret-down"
        onExpandedStateChange={onExpandedStateChange}
        menuZIndex={2}
        menuStyle={{ width: '324px' }}
        {...props}
      >
        {/* TODO: remove this when we will support linked auction sections */}
        {!linkedPageIsAuctionPage && (
          <DropdownMenuItem onSelect={() => onSelect('linked')}>
            <Flex pr={1}>
              <Box pt="6px" pl={1} pr={3} flexShrink={0}>
                <SectionIcon sectionType="linked" />
              </Box>
              <Box>
                <Text lineHeight="18px" color="text">
                  {t('addSectionDropdown.linked.title')}
                </Text>
                <Text fontSize={1} lineHeight="15px" color="subtext" mt="2px">
                  {t('addSectionDropdown.linked.description')}
                </Text>
              </Box>
            </Flex>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem onSelect={() => onSelect('separate')}>
          <Flex pr={1}>
            <Box pt="6px" pl={1} pr={3} flexShrink={0}>
              <SectionIcon sectionType="separate" />
            </Box>
            <Box>
              <Text lineHeight="18px" color="text">
                {t('addSectionDropdown.separate.title')}
              </Text>
              <Text fontSize={1} lineHeight="15px" color="subtext" mt="2px">
                {t('addSectionDropdown.separate.description')}
              </Text>
            </Box>
          </Flex>
        </DropdownMenuItem>
      </DropdownMenu>
    </Box>
  );
};

const DraftEvaluationPage = () => {
  const { t } = useTranslation(['evaluation', 'translation']);
  const { startEditing } = rfx.useActions();
  const { editingPanelId, isRevising } = rfx.useState();
  const sections = rfx.useSections() as LinkedEvaluationSection[];
  const pagePermissions = rfx.usePagePermissions();
  const isEditingAnyPanel = Boolean(editingPanelId);
  const addLinkedSectionsModalState = useModalState();
  const [addEvaluationSection, { isLoading }] = draft.useAddEvaluationSection();
  const [addEvaluationSections, { isLoading: isLoadingAddSections }] = draft.useAddEvaluationSections();

  const handleSectionAdd = (sectionType: SectionType) => {
    if (sectionType === 'separate') {
      const sectionId = uuid();

      addEvaluationSection({
        _id: sectionId,
        name: '',
        weight: 1,
      }, {
        onSuccess: () => startEditing(sectionId),
      });
    } else {
      addLinkedSectionsModalState.open();
    }
  };

  const handleAddLinkedSections = async (linkedSectionIds: string[]) => {
    await addEvaluationSections(
      linkedSectionIds.map((sectionId) => ({
        _id: uuid(),
        name: '',
        weight: 1,
        linkedSectionId: sectionId,
      })),
    );

    addLinkedSectionsModalState.close();
  };

  return (
    <>
      <Stack gap={20}>
        <DraftEvaluationPageHeader />
        {isRevising && (
          <MessageBlock variant="info" mb="20px">
            {t('request.revisionInfo', { ns: 'translation' })}
          </MessageBlock>
        )}
        {sections.length ? (
          <>
            {sections.map((section) => (
              <rfx.SectionProvider key={section._id} section={section}>
                <EvaluationSectionPanel />
              </rfx.SectionProvider>
            ))}
          </>
        ) : (
          <SectionsPlaceholder />
        )}
        {pagePermissions.canEdit ? (
          <AddSectionDropdown
            disabled={isLoading || isLoadingAddSections || isEditingAnyPanel}
            onSelect={handleSectionAdd}
          />
        ) : (
          null
        )}
        <Disclosure summary={t('disclosure.summary')}>
          {t('disclosure.content')}
        </Disclosure>
      </Stack>
      <AddLinkedSectionsModal
        isOpen={addLinkedSectionsModalState.isOpen}
        onCancel={addLinkedSectionsModalState.close}
        onSubmit={handleAddLinkedSections}
      />
    </>
  );
};

export const DraftEvaluationTab = () => {
  const rfxStructure = rfx.useStructure();
  const page = rfx.usePage() as EvaluationPage;
  const pagePermissions = rfx.usePagePermissions();
  const sections = rfx.usePageSections();

  return pagePermissions.canRead ? (
    <rfx.SectionsProvider sections={sections}>
      <DraftEvaluationPage />
    </rfx.SectionsProvider>
  ) : (
    <Stack gap={20}>
      <Heading as="h1" fontSize={6} lineHeight="normal" fontWeight="500">
        {/*
         // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type. */}
        {rfxStructure.pageById[page.linkedPageId].name}
      </Heading>
      <HiddenRequestPagePlaceholder />
    </Stack>
  );
};
