import { Flex } from 'rebass/styled-components';

export const SubcolumnCellContainer = ({
  children,
  width,
  hideBorder,
}: {
  children: React.ReactNode;
  width: number;
  hideBorder?: boolean;
}) => {
  return (
    <Flex
      sx={{ height: '100%', borderLeft: hideBorder ? undefined : 'lightGray', width: `${width}px` }}
      fontWeight="normal"
      p={2}
    >
      {children}
    </Flex>
  );
};
