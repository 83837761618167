import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';
import { getRequirementFromExchangeType } from '@deepstream/common/rfq-utils';
import { compact } from 'lodash';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { withProps } from '@deepstream/ui-utils/withProps';
import { FormTableStyles } from '../TableStyles';
import { Table } from '../Table';
import { Required } from '../Required';
import { createEmptyDocument, createInformationDocument, useExchangeDefsField } from './exchangeDefs';
import * as rfx from '../rfx';
import {
  ExchangeDefActionsCell,
  ExchangeDefDescriptionFieldCell,
  DocumentExchangeFileFieldCell,
  RequirementFieldCell,
  LockFieldCell,
  StageFieldCell,
} from './cell';
import { WordWrap } from '../WordWrap';
import { AddDocumentButton } from './AddDocumentButton';
import { useDefaultStageIds } from '../ui/ExchangeDefsGrid/DocumentExchangeDefsGrid';

const FieldColumnHeader = ({ column }) => (
  <WordWrap pr={column.headerPaddingRight}>
    {column.label}
    {column.required && <Required />}
    {column.infoTooltip && (
      <Tooltip
        content={column.infoTooltip}
        placement="top"
      >
        <Icon
          color="gray"
          ml={1}
          icon="info-circle"
        />
      </Tooltip>
    )}
  </WordWrap>
);

// This component is used for supplier-added documents
export const DocumentTableField = ({
  fieldName = 'exchangeDefs',
  showExchangeDefVisibility,
}: {
  fieldName?: string;
  showExchangeDefVisibility?: boolean;
}) => {
  const { t } = useTranslation('translation');
  const isSender = rfx.useIsSender();
  const defaultStageIds = useDefaultStageIds();

  const { exchangeDefs, addExchangeDef } = useExchangeDefsField(
    fieldName,
    isSender
      ? () => createEmptyDocument(defaultStageIds)
      : createInformationDocument,
  );

  const columns = useMemo(
    () => compact([
      isSender ? {
        id: 'requirement',
        Header: FieldColumnHeader,
        label: t('request.documents.requirement', { count: 1 }),
        accessor: getRequirementFromExchangeType,
        Cell: RequirementFieldCell,
        required: true,
        width: 300,
        infoTooltip: t('request.documents.requirementTooltip'),
      } : (
        null
      ),
      {
        id: 'attachments',
        Header: FieldColumnHeader,
        label: t('general.document'),
        accessor: 'attachments',
        Cell: DocumentExchangeFileFieldCell,
        required: true,
        width: 330,
      },
      {
        id: 'category',
        Header: FieldColumnHeader,
        label: t('general.description'),
        accessor: 'category',
        Cell: ExchangeDefDescriptionFieldCell,
        required: true,
        hideNumbering: true,
      },
      isSender && showExchangeDefVisibility ? {
        id: 'stages',
        Header: FieldColumnHeader,
        label: t('request.visibleFromStage'),
        accessor: 'stages',
        Cell: StageFieldCell,
        required: true,
        width: 155,
      } : (
        null
      ),
      isSender ? {
        id: 'lock',
        Header: FieldColumnHeader,
        label: t('request.lockResponses'),
        accessor: 'locking',
        Cell: LockFieldCell,
        headerPaddingRight: 10,
        infoTooltip: t('request.documents.lockTooltip'),
      } : (
        null
      ),
      {
        id: 'menu',
        Header: () => '',
        accessor: null,
        width: 48,
        Cell: withProps(ExchangeDefActionsCell, { fieldName }),
      },
    ]),
    [isSender, t, showExchangeDefVisibility, fieldName],
  );

  return (
    <>
      {exchangeDefs.length > 0 ? (
        <FormTableStyles>
          <Table columns={columns} data={exchangeDefs} />
        </FormTableStyles>
      ) : (
        <Text color="subtext" fontSize={2} mb={2}>
          {t('request.documents.noDocumentsAdded')}
        </Text>
      )}
      <AddDocumentButton
        type="button"
        mt={3}
        variant="secondary"
        onClick={() => addExchangeDef()}
      />
    </>
  );
};
