import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compact, first, groupBy, isEmpty, sumBy, values } from 'lodash';
import { BidIntentionStatus, Live, RequestInteractivityStatus } from '@deepstream/common/rfq-utils';
import { Flex, Text } from 'rebass/styled-components';
import { getCompleteResponseItemCount, getTotalResponseItemCount } from '@deepstream/common/bidProgress';
import { isLotInactive } from '@deepstream/common/rfq-utils/lot';
import { NavigationMenu, NavigationMenuItem } from './NavigationMenu';
import * as rfx from '../../../rfx';
import * as lotPagesLayout from '../Live/lotPagesLayout';
import { PercentRequirementsComplete } from './RequestRecipientStagesNavigation';
import { ProgressByPageIdByRequirementGroupIdByStageId } from '../../../types';
import { InactiveLotsToggle } from './InactiveLotsToggle';
import { useRequestRecipientNavigation } from '../../../appNavigation';
import { BuyerActionsRequiredIconAndText } from './BuyerActionsRequiredIconAndText';
import { useBidCommentNotifications } from './useBidCommentNotifications';
import { UnreadComments } from './UnreadComments';

const LotRequirementsCompletion = ({
  progressByPageId,
}: {
  progressByPageId?: ProgressByPageIdByRequirementGroupIdByStageId[string][string];
}) => {
  const {
    total,
    complete,
  } = useMemo(() => {
    const flattenedProgressItems = values(progressByPageId);

    return {
      total: sumBy(flattenedProgressItems, getTotalResponseItemCount),
      complete: sumBy(flattenedProgressItems, getCompleteResponseItemCount),
    };
  }, [progressByPageId]);

  return (
    <Text mb="2px">
      <PercentRequirementsComplete factor={total === 0 ? 1 : complete / total} />
    </Text>
  );
};

export const RequestRecipientRequirementsMenuSection = () => {
  const { t } = useTranslation('translation');
  const { lots, enteredPageIdsByRequirementGroupIdByStageId, exchangeDefById, sectionById } = rfx.useStructure<Live>();
  const currentCompanyGroup = rfx.useCurrentCompanyGroup();
  const bid = rfx.useBid();
  const stageId = rfx.useStageId();
  const requestInteractivityStatus = rfx.useRequestInteractivityStatus();
  const bidIntentionStatus = rfx.useBidIntentionStatus();
  const navigation = useRequestRecipientNavigation();
  const bidCommentNotifications = useBidCommentNotifications();

  const notificationsByRequirementGroupId = useMemo(() => {
    return groupBy(
      bidCommentNotifications,
      notification => {
        const { exchangeId } = notification.meta;
        const exchangeDef = exchangeDefById[exchangeId];
        if (!exchangeDef) {
          return 'ignore';
        }
        // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
        const section = sectionById[exchangeDef.sectionId];
        if (!section) {
          return 'ignore';
        }

        const exchangeStageId = rfx.getExchangeStageId(exchangeDef, section, bid);

        if (exchangeStageId !== stageId) {
          return 'ignore';
        }

        return first(section.lotIds) || 'general';
      },
    );
  }, [bid, bidCommentNotifications, exchangeDefById, sectionById, stageId]);

  const [showInactiveLots, setShowInactiveLots] = useState<boolean>(false);

  const hasActivatedStage = bid.activatedStageIds.includes(stageId);

  const requirementGroups = useMemo(() => {
    // @ts-expect-error ts(18048) FIXME: 'enteredPageIdsByRequirementGroupIdByStageId' is possibly 'undefined'.
    const stageRequirementGroupIds = Object.keys(enteredPageIdsByRequirementGroupIdByStageId[stageId]);

    const hasGeneralRequirementsGroup = stageRequirementGroupIds.includes('general');

    return compact([
      hasGeneralRequirementsGroup
        ? {
          _id: 'general',
          name: t('request.generalRequirement_other'),
          isRequirementOfCurrentStage: true,
          isInactive: false,
        }
        : null,
      ...lots
        .map((lot, index) => {
          const isRequirementOfCurrentStage = stageRequirementGroupIds.includes(lot._id);

          return {
            _id: lot._id,
            name: `${t('request.lot', { count: 1 })} ${index + 1} – ${lot.name}`,
            isRequirementOfCurrentStage,
            isInactive: isLotInactive(lot, bid.intentionStatusByLotId[lot._id]),
          };
        }),
    ]);
  }, [lots, stageId, t, enteredPageIdsByRequirementGroupIdByStageId, bid]);

  const inactiveLotCount = sumBy(requirementGroups, requirementGroup => Number(requirementGroup.isInactive));

  const filteredRequirementGroups = showInactiveLots
    ? requirementGroups
    : requirementGroups.filter(requirementGroup => !requirementGroup.isInactive);

  return (
    <lotPagesLayout.Section heading={t('request.requirement_other')}>
      {!isEmpty(filteredRequirementGroups) && (
        <Text mt="12px">
          {(
            hasActivatedStage &&
            bidIntentionStatus === BidIntentionStatus.BIDDING &&
            requestInteractivityStatus === RequestInteractivityStatus.LIVE
          ) ? (
            t(`request.lots.selectAnOptionToViewAndComplete.${currentCompanyGroup}`)
          ) : (
            t('request.lots.selectAnOptionToView')
          )}
        </Text>
      )}
      <NavigationMenu heading={t('request.requirement_other')}>
        {filteredRequirementGroups.map(requirementGroup => {
          const numComments = notificationsByRequirementGroupId[requirementGroup._id]?.length;

          const secondLineContent = !requirementGroup.isRequirementOfCurrentStage ? (
            <lotPagesLayout.InfoText mt={0}>
              {t('request.lots.noRequirementsInLotForStage')}
            </lotPagesLayout.InfoText>
          ) : (
            null
          );

          return (
            <NavigationMenuItem
              key={requirementGroup._id}
              title={requirementGroup.name}
              titleColor={requirementGroup.isInactive ? 'subtext' : 'primary'}
              disabledTitleColor={requirementGroup.isInactive ? 'subtext' : 'text'}
              body={
                <>
                  {hasActivatedStage && requirementGroup.isRequirementOfCurrentStage && !requirementGroup.isInactive && (
                    <>
                      <LotRequirementsCompletion
                        progressByPageId={bid.progressByPageIdByRequirementGroupIdByStageId[stageId]?.[requirementGroup._id]}
                      />
                      {currentCompanyGroup === 'buyer' && (
                        <Text mt="2px">
                          {/*
                           // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string | undefined'. */}
                          <BuyerActionsRequiredIconAndText stageId={stageId} requirementGroupId={requirementGroup._id} />
                        </Text>
                      )}
                    </>
                  )}
                  {secondLineContent ? (
                    <Flex mt="2px">
                      {secondLineContent}
                      <UnreadComments count={numComments} ml={3} />
                    </Flex>
                  ) : numComments ? (
                    <Text mt="2px">
                      <UnreadComments count={numComments} />
                    </Text>
                  ) : (
                    null
                  )}
                </>
              }
              disabled={!requirementGroup.isRequirementOfCurrentStage}
              linkProps={navigation.getBidLinkProps(stageId, requirementGroup._id)}
              alignItems="center"
            />
          );
        })}
      </NavigationMenu>
      {inactiveLotCount > 0 && (
        <InactiveLotsToggle
          inactiveLotCount={inactiveLotCount}
          setShowInactiveLots={setShowInactiveLots}
          showInactiveLots={showInactiveLots}
        />
      )}
    </lotPagesLayout.Section>
  );
};
