import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { documentExchangeTypes } from '@deepstream/common/exchangesConfig';
import { last } from 'lodash';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { callAll } from '@deepstream/utils/callAll';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils/types';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { useQueryClient } from 'react-query';
import { useApi } from '../api';
import { useToaster } from '../toast';
import { useNotifications, useReadNotifications } from '../modules/Notifications';
import { useRecipientId, useRfqId } from '../useRfq';
import { useExchange } from '../useExchange';
import { useCurrentCompanyId } from '../currentCompanyId';
import { useMutation } from '../useMutation';

export const MarkAsUnreadButton = ({ closeModal }: { closeModal: () => void }) => {
  const { t } = useTranslation();
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const rfqId = useRfqId();
  // The internal documents and buyer-side messages modals aren't wrapped with
  // a RecipientIdProvider so we need to set `required: false`
  const recipientId = useRecipientId({ required: false });
  const exchange = useExchange();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const isRecipient = currentCompanyId === recipientId;

  const notificationFilter = useMemo(
    () => ({
      domain: isRecipient ? NotificationDomain.RFQ_RECEIVED : NotificationDomain.RFQ_SENT,
      action: NotificationAction.EXCHANGE_REPLY_SENT,
      to: {
        companyId: currentCompanyId,
      },
      meta: {
        rfqId,
        exchangeId: exchange._id,
        ...(exchange.recipientId ? { recipientId: exchange.recipientId } : {}),
      },
    }),
    [rfqId, isRecipient, currentCompanyId, exchange],
  );

  const readNotifications = useReadNotifications(notificationFilter);
  const unreadNotifications = useNotifications(notificationFilter);

  const exchangeLabel = useMemo(
    () => {
      if (documentExchangeTypes.includes(exchange.def.type)) {
        return t('request.exchange.documentExchange');
      }

      return t(`request.exchangeType.${exchange.def.type}`);
    },
    [exchange.def.type, t],
  );

  const [markNotificationsAsUnread, { isLoading }] = useMutation(
    api.markNotificationsAsUnread,
    {
      onSuccess: callAll(
        () => toaster.success(t('request.exchange.toaster.markAsUnreadSuccess', { exchangeLabel })),
        () => queryClient.invalidateQueries('notifications'),
        closeModal,
      ),
      onError: () => toaster.error(t('request.exchange.toaster.markAsUnreadError', { exchangeLabel })),
    },
  );

  if (!readNotifications.length && !unreadNotifications.length) {
    return null;
  }

  const lastReadNotificationId = last<any>(readNotifications)?._id;

  return (
    <Tooltip content={t('request.exchange.markAsUnread') as string}>
      <IconButton
        icon="envelope"
        color="primary"
        fontSize={3}
        onClick={() => {
          markNotificationsAsUnread({ notificationIds: [lastReadNotificationId!] });
        }}
        mr={3}
        disabled={unreadNotifications.length > 0 || isLoading}
      />
    </Tooltip>
  );
};
