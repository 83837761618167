import styled from 'styled-components';

import { NAVBAR_HEIGHT } from '../constants';

// TODO: To be extended with breakpoints
export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
`;

export const PageContentContainer = styled(Container)<{ sidebar?: boolean; }>`
  margin: 0 auto;

  ${(props) => {
    if (!props.sidebar) return false;

    return `
      display: 'flex';
      alignItems: 'flex-start';
      justifyContent: 'space-between'
    `;
  }}
`;

export const MainNavWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: sticky;
  left: 0;
  right: 0;
  z-index: 102;
  height: ${NAVBAR_HEIGHT}px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.darkGray};
`;
