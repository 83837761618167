import { some } from 'lodash';
import { RoleRestrictionProfile, Roles } from './user-utils';

export const ValidationStatus = {
  DUPLICATE_MEMBERSHIP: 'duplicate-membership',
  DUPLICATE_INVITE: 'duplicate-invite',
};

export const HistoryInviteStatus = {
  INVITED: 'Invited',
  CONFIRMED: 'Confirmed',
};

export enum ClaimStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

export const isCompanyPending = company => !some(company.claims, { status: 'accepted' });
export const hasAcceptedClaim = (company) => some(company.claims, { status: ClaimStatus.ACCEPTED });

export type EventSourcedCompany = {
  _id: string;
};

export type CompanyUserData = {
  roleRestrictionProfile: RoleRestrictionProfile;
  roles: Partial<Roles>;
};

export type OnboardingCompanyInformation = {
  _id?: string;
  address?: any;
  email?: any;
  telephone?: any;
  employeesNb?: any;
  logo?: any;
  web?: any;
  name?: any;
  number?: any;
  registeredName?: any;
  statement?: any;
  group?: any;
  productsAndServices?: any;
};
