import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';

import { useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { LineItemsExchangeSnapshot } from '../../types';
import { countErrors, ValidationErrors } from '../../draft/validation';
import { Switch } from '../Switch';
import { EditLineItemsGrid } from './EditLineItemsGrid';

export interface ExcelBidSubmitLineItemsGridProps {
  /**
   * Determines the maximum height of the grid when `height` is not set.
   *
   * `maxGridHeight = 100vh - viewportHeightDelta`
   */
  viewportHeightDelta?: number;
  height?: string;
  onValidChange?: (isValid: boolean) => void;
  onExchangesChange?: (exchanges: LineItemsExchangeSnapshot[]) => void;
}

export const ExcelBidSubmitLineItemsGrid = ({
  viewportHeightDelta,
  height,
  onValidChange,
  onExchangesChange,
}: ExcelBidSubmitLineItemsGridProps) => {
  const { t } = useTranslation('translation');
  const [showOnlyInvalidRows, setShowOnlyInvalidRows] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [errorsCount, setErrorsCount] = React.useState(0);

  const {
    rowData: exchanges,
  } = useEditableGridData<LineItemsExchangeSnapshot>();

  const onValidationErrorsChange = (validationErrors: ValidationErrors<{ exchangeDefs: LineItemsExchangeSnapshot[] }>) => {
    const errorsCount = countErrors(validationErrors);
    setIsValid(errorsCount === 0);
    setErrorsCount(errorsCount);
  };

  React.useEffect(() => {
    if (onValidChange) {
      onValidChange(isValid);
    }
  }, [isValid, onValidChange]);

  return (
    exchanges.length > 0 ? (
      <>
        <EditLineItemsGrid
          viewportHeightDelta={viewportHeightDelta}
          height={height}
          showOnlyInvalidRows={showOnlyInvalidRows}
          onValidationErrorsChange={onValidationErrorsChange}
          onExchangesChange={onExchangesChange}
        />

        <Flex justifyContent="space-between" my={3}>
          <Switch
            appendedText={t('excelBid.steps.reviewImportedSectionData.errors.onlyRowsWithErrors', { ns: 'request' })}
            checkedIcon={false}
            uncheckedIcon={false}
            disabled={!errorsCount}
            checked={showOnlyInvalidRows}
            onChange={() => setShowOnlyInvalidRows(prev => !prev)}
            width={42}
          />

          {errorsCount > 0 && (
            <IconText
              gap={2}
              icon="exclamation-circle"
              color="danger"
              text={t('excelBid.steps.reviewImportedSectionData.errors.fixToContinue', { count: errorsCount, ns: 'request' })}
            />
          )}
        </Flex>
      </>
    ) : (
      <Text color="subtext" fontSize={2} mb={2}>
        {t('request.lineItems.noLineItemsAdded')}
      </Text>
    )
  );
};
