import {
  AuctionRules,
  AuctionBidFeedbackType,
  isAuctionStage,
  ExchangeType,
  ActionType,
  AutoExtensionTrigger,
  AuctionStatus,
  BidIntentionStatus,
  Live,
} from '@deepstream/common/rfq-utils';
import { Trans, useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { find, keyBy, last } from 'lodash';
import * as yup from 'yup';
import { Box, Flex, Heading, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { useQueryClient } from 'react-query';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { assertDefined } from '@deepstream/utils';
import * as rfx from '../../../rfx';
import { CurrencyAmount } from '../../../ui/Currency';
import { Bold } from '../../../Bold';
import { CheckboxField } from '../../../form/CheckboxField';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useRfqExchange, useRfqId } from '../../../useRfq';
import { useSendExchangeReply } from '../../../ExchangeModal/useSendExchangeReply';
import { DelayedSpinner } from '../../../ui/Loading';
import { useDatetime } from '../../../useDate';
import { PreWrap } from '../../../PreWrapCell';
import { Number } from '../../../ui/Number';
import { StaticFormErrorMessage, RequestRecipientAuctionStageMessage } from '../Recipient/RequestRecipientStageMessage';
import { StructureAuctionStage } from '../../../types';

const List = styled.ul`
  padding-left: 1em;
  margin-bottom: 0;

  > * + * {
    margin-top: 4px;
  }
`;

export const BidFeedback = ({ bidFeedback }: { bidFeedback: AuctionRules['bidFeedback'] }) => {
  const { t } = useTranslation();

  return (
    <>
      <Bold>{t(`request.auction.bidFeedback.label.${bidFeedback}`)}</Bold>
      {' '}–{' '}
      {t(`request.auction.bidFeedback.overviewDescription.${bidFeedback}`)}
    </>
  );
};

export const ExampleFeedback = ({ bidFeedback }: { bidFeedback: AuctionRules['bidFeedback'] }) => {
  const { t } = useTranslation();

  return (
    <>
      <Bold>{t('request.auction.overview.exampleFeedback')}</Bold>
      {' '}–{' '}
      {bidFeedback === AuctionBidFeedbackType.RANK_AND_LEAD ? (
        <Trans
          i18nKey={`request.auction.bidFeedback.overviewExample.${bidFeedback}`}
          components={{ b: <Bold /> }}
        />
      ) : bidFeedback === AuctionBidFeedbackType.RANK ? (
        <Trans
          i18nKey={`request.auction.bidFeedback.overviewExample.${bidFeedback}`}
          components={{ b: <Bold /> }}
        />
      ) : bidFeedback === AuctionBidFeedbackType.LEADING ? (
        t(`request.auction.bidFeedback.overviewExample.${bidFeedback}`)
      ) : (
        null
      )}
    </>
  );
};

export const MinimumReduction = ({ minimumReduction }: { minimumReduction: AuctionRules['minimumReduction'] }) => {
  const { t } = useTranslation();

  return (
    <>
      <Bold>{t('request.auction.bidRules.minimumReduction.label')}</Bold>
      {' '}–{' '}
      {t('request.auction.overview.minimumReductionDescription')}
      {' '}
      <Bold>
        {minimumReduction.type === 'amount' ? (
          <CurrencyAmount showCode value={minimumReduction.value} />
        ) : (
          <Number value={minimumReduction.value} suffix="%" />
        )}
      </Bold>
    </>
  );
};

export const TieBids = ({ enabled }: { enabled: boolean }) => {
  const { t } = useTranslation();

  return (
    <>
      <Bold>{t('request.auction.bidRules.tieBids.label')}</Bold>
      {' '}–{' '}
      {enabled ? (
        t('request.auction.overview.tieBidsEnabledDescription')
      ) : (
        <Trans
          i18nKey="request.auction.overview.tieBidsDisabledDescription"
          ns="translation"
          t={t}
          components={{ b: <Bold /> }}
        />
      )}
    </>
  );
};

export const CeilingPrice = ({ ceilingPrice }: { ceilingPrice?: AuctionRules['ceilingPrice'] }) => {
  const { t } = useTranslation();

  return (
    <>
      <Bold>{t('request.auction.bidRules.ceilingPrice.label')}</Bold>
      {' '}–{' '}
      {ceilingPrice ? (
        <>
          {t('request.auction.overview.ceilingPriceEnabledDescription')}
          {' '}
          <Bold><CurrencyAmount showCode value={ceilingPrice.amount} /></Bold>
        </>
      ) : (
        <Trans
          i18nKey="request.auction.overview.ceilingPriceDisabledDescription"
          ns="translation"
          t={t}
          components={{ b: <Bold /> }}
        />
      )}
    </>
  );
};

// @ts-expect-error ts(2339) FIXME: Property 'trigger' does not exist on type '{ trigger: AutoExtensionTrigger; timeToRespond: number; } | null'.
export const AutoExtension = ({ trigger, timeToRespond }: AuctionRules['autoExtension']) => {
  const { t } = useTranslation();

  return (
    <>
      <Bold>{t('request.auction.timingRules.autoExtension.label')}</Bold>
      {' '}–{' '}
      {trigger === AutoExtensionTrigger.ANY_BID ? (
        <Trans
          i18nKey="request.auction.overview.autoExtensionDescription.anyBid"
          count={timeToRespond}
          components={{ b: <Bold /> }}
        />
      ) : trigger === AutoExtensionTrigger.NEW_LEAD_BID ? (
        <Trans
          i18nKey="request.auction.overview.autoExtensionDescription.newLeadBid"
          count={timeToRespond}
          components={{ b: <Bold /> }}
        />
      ) : (
        <Trans
          i18nKey="request.auction.overview.autoExtensionDescription.none"
          count={timeToRespond}
          components={{ b: <Bold /> }}
        />
      )}
    </>
  );
};

export const PreBids = ({ enabled }: { enabled: boolean }) => {
  const { t } = useTranslation();

  return (
    <>
      <Bold>{t('request.auction.timingRules.preBids.label')}</Bold>
      {' '}–{' '}
      {enabled ? (
        t('request.auction.overview.preBidsEnabledDescription')
      ) : (
        <Trans
          i18nKey="request.auction.overview.preBidsDisabledDescription"
          components={{ 1: <Bold /> }}
        />
      )}
    </>
  );
};

export const AuctionStart = ({ date }: { date: string | Date }) => {
  const datetime = useDatetime(date);

  return (
    <Trans
      i18nKey="request.auction.overview.auctionStart"
      values={{ datetime }}
      components={{ b: <Bold /> }}
    />
  );
};

export const AuctionDuration = ({ duration }: { duration: number }) => (
  <Trans
    i18nKey="request.auction.overview.auctionDuration"
    count={duration}
    components={{ b: <Bold /> }}
  />
);

const AcceptTermsForm = ({ onClose }: { onClose: () => void }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const rfqId = useRfqId();
  const stageId = rfx.useStageId();
  const { senders, recipients } = rfx.useStructure();
  const auctionTermsExchangeDef = rfx.useAuctionTermsExchangeDef();
  const pagePermissions = rfx.usePagePermissions();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  // @ts-expect-error ts(2339) FIXME: Property 'company' does not exist on type 'Company | undefined'.
  const { company } = [...senders, ...recipients].find(({ _id }) => _id === currentCompanyId);
  const bid = rfx.useBid();
  const bidIntentionStatus = rfx.useBidIntentionStatus();

  const { data: exchange, isLoading, isSuccess } = useRfqExchange({
    // @ts-expect-error ts(18047) FIXME: 'auctionTermsExchangeDef' is possibly 'null'.
    exchangeId: auctionTermsExchangeDef._id,
    recipientId: currentCompanyId,
  });

  if (isSuccess && exchange?.def.type !== ExchangeType.AUCTION_TERMS) {
    throw new Error('Invalid exchange type');
  }

  const [sendExchangeReply] = useSendExchangeReply({
    rfqId,
    // @ts-expect-error ts(18047) FIXME: 'auctionTermsExchangeDef' is possibly 'null'.
    exchangeId: auctionTermsExchangeDef._id,
    recipientId: currentCompanyId,
  });

  const acceptedDatetime = exchange?.isResolved && last(exchange.history)?.date;
  const formattedDatetime = useDatetime(acceptedDatetime);

  const showStaticFormError = Boolean(stageId) && (
    bidIntentionStatus !== BidIntentionStatus.BIDDING ||
    !bid.activatedStageIds.includes(stageId)
  );

  return (
    isLoading ? (
      <DelayedSpinner />
    // @ts-expect-error ts(18048) FIXME: 'exchange' is possibly 'undefined'.
    ) : exchange.isResolved ? (
      <>
        <Flex>
          <Box mr={3}>
            <Icon icon="check" color="success" />
          </Box>
          <Text>
            <Trans
              i18nKey="request.auction.overview.bidderAgreementAccepted"
              values={{
                name: last(exchange!.history)?.user.name,
                datetime: formattedDatetime,
              }}
              components={{ b: <Bold /> }}
            />
          </Text>
        </Flex>
        <Flex justifyContent="flex-end" mt={3}>
          <Button onClick={onClose}>
            {t('request.auction.overview.closeOverview')}
          </Button>
        </Flex>
      </>
    ) : (
      <Formik<{ acceptsTerms: boolean }>
        validateOnBlur
        validateOnMount
        initialValues={{
          acceptsTerms: false,
        }}
        initialTouched={{
          acceptsTerms: true,
        }}
        validationSchema={
          yup.object().shape({
            acceptsTerms: yup
              .boolean()
              .oneOf([true], t('request.auction.overview.errors.requiredToParticipate'))
              .required(),
          })
        }
        onSubmit={async ({ acceptsTerms }) => {
          if (acceptsTerms) {
            await sendExchangeReply({
              value: ActionType.ACCEPT,
              comment: '',
            }, {
              // FIXME: this is a bit of a sledge hammer approach... ideally we'd identify the
              // dependent auction line item exchanges and only invalidate the `exchanges` query
              // that contains any of them
              onSuccess: () => queryClient.invalidateQueries('exchanges'),
            });
          }
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <Box flex={1}>
              <CheckboxField
                hideLabel
                hideError={showStaticFormError}
                name="acceptsTerms"
                fieldLabel={
                  <Trans
                    i18nKey="request.auction.overview.acceptAgreementLabel"
                    values={{ companyName: company.name }}
                    components={{ b: <Bold /> }}
                  />
                }
                disabled={isSubmitting || !pagePermissions.canRespond || showStaticFormError}
              />
            </Box>
            {showStaticFormError && (
              <RequestRecipientAuctionStageMessage MessageComponent={StaticFormErrorMessage} />
            )}
            <Flex justifyContent="flex-end">
              <Button type="submit" disabled={!isValid || isSubmitting || showStaticFormError}>
                {t('general.submit')}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    )
  );
};

export const AuctionOverview = ({
  onClose,
  auctionRules,
}: {
  onClose: () => void;
  auctionRules: AuctionRules;
}) => {
  const { t } = useTranslation();
  const stages = rfx.useStages();
  const auction = rfx.useAuction();
  const isSender = rfx.useIsSender();
  const exchangeDefByType: any = keyBy(rfx.useSectionExchangeDefs(), exchangeDef => exchangeDef.type);
  const auctionStage = find(stages, isAuctionStage) as StructureAuctionStage<Live> | undefined;

  assertDefined(auctionStage, 'auctionStage');

  const auctionTermsExchangeDef = exchangeDefByType[ExchangeType.AUCTION_TERMS];
  const auctionInformationExchangeDef = exchangeDefByType[ExchangeType.AUCTION_INFORMATION];

  const canViewTermsForm = !isSender &&
    ![AuctionStatus.CANCELLED, AuctionStatus.ENDED].includes(auction.status);

  const {
    duration,
    bidFeedback,
    minimumReduction,
    preBids,
    tieBids,
    autoExtension,
    ceilingPrice,
  } = auctionRules;

  return (
    <Panel height="100%">
      <Flex height="100%" flexDirection="column">
        <ModalHeader onClose={onClose}>
          {t('request.auction.overview.auctionOverview')}
        </ModalHeader>
        <Stack p={3} gap={3} sx={{ flex: 1, overflow: 'auto' }}>
          <Stack gap={2}>
            <Heading as="h3" fontSize={2}>
              {t('request.auction.overview.schedule')}
            </Heading>
            <List>
              <li>
                <AuctionStart date={auctionStage.startDate} />
              </li>
              <li>
                <AuctionDuration duration={duration} />
              </li>
            </List>
          </Stack>
          <Stack gap={2}>
            <Heading as="h3" fontSize={2}>
              {t('request.auction.sectionTitle.awardingPrinciples')}
            </Heading>
            <Text>
              <PreWrap>
                {auctionInformationExchangeDef.text}
              </PreWrap>
            </Text>
          </Stack>
          <Stack gap={2}>
            <Heading as="h3" fontSize={2}>
              {t('request.auction.sectionTitle.bidderAgreement')}
            </Heading>
            <Text>
              <PreWrap>
                {auctionTermsExchangeDef.text}
              </PreWrap>
            </Text>
          </Stack>
          <Stack gap={2}>
            <Heading as="h3" fontSize={2}>
              {t('request.auction.sectionTitle.bidFeedback')}
            </Heading>
            <List>
              <li><BidFeedback bidFeedback={bidFeedback} /></li>
              <li><ExampleFeedback bidFeedback={bidFeedback} /></li>
            </List>
          </Stack>
          <Stack gap={2}>
            <Heading as="h3" fontSize={2}>
              {t('request.auction.sectionTitle.bidRules')}
            </Heading>
            <List>
              <li><MinimumReduction minimumReduction={minimumReduction} /></li>
              <li><TieBids enabled={tieBids} /></li>
              <li><CeilingPrice ceilingPrice={ceilingPrice} /></li>
            </List>
          </Stack>
          <Stack gap={2}>
            <Heading as="h3" fontSize={2}>
              {t('request.auction.sectionTitle.timingRules')}
            </Heading>
            <List>
              {/*
               // @ts-expect-error ts(2322) FIXME: Type '{ trigger?: AutoExtensionTrigger | undefined; timeToRespond?: number | undefined; }' is not assignable to type '{ trigger: AutoExtensionTrigger; timeToRespond: number; }'. */}
              <li><AutoExtension {...autoExtension} /></li>
              <li><PreBids enabled={preBids} /></li>
            </List>
          </Stack>
        </Stack>
        {canViewTermsForm ? (
          <>
            <PanelDivider />
            <Box p={3}>
              <AcceptTermsForm onClose={onClose} />
            </Box>
          </>
        ) : (
          null
        )}
      </Flex>
    </Panel>
  );
};
