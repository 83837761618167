import { useTranslation } from 'react-i18next';
import { Box, Text, Flex, BoxProps } from 'rebass/styled-components';
import { CellProps } from 'react-table';
import { QuestionnaireOverview, QuestionnaireStatus, questionnaireStatusConfig } from '@deepstream/common/preQual';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Bold } from '../../../Bold';

export const useQuestionnaireStatusLabel = ({
  status,
  isRecipient,
}: {
  status: QuestionnaireStatus;
  isRecipient?: boolean;
}) => {
  const { t } = useTranslation('preQualification');
  const isSent = status === QuestionnaireStatus.SENT;

  // Override the status text for the recipient side from Sent to Received
  return isSent && isRecipient
    ? t('questionnaireStatus.received')
    : t(`questionnaireStatus.${status}`);
};

export const QuestionnaireStatusFieldValue = ({ value, isRecipient }) => {
  const statusLabel = useQuestionnaireStatusLabel({ status: value, isRecipient });

  return <>{statusLabel}</>;
};

export const QuestionnaireStatusIcon = ({ status }: { status: QuestionnaireStatus }) => {
  const { icon } = questionnaireStatusConfig[status] ?? {};

  return icon ? (
    <Box alignSelf="flex-start" flex="0 0 auto" mr="8px" mt="2px">
      <Icon
        fixedWidth
        color={icon.color}
        icon={icon.value}
        regular={icon.isRegular}
      />
    </Box>
  ) : (
    null
  );
};

export const QuestionnaireStatusIconCell = ({ row }: CellProps<QuestionnaireOverview>) => {
  const value = row.original.status;

  return <QuestionnaireStatusIcon status={value} />;
};

export const LabeledQuestionnaireStatus = ({
  status,
  isRecipient,
  ...props
}: BoxProps & {
  status: QuestionnaireStatus;
  isRecipient: boolean;
}) => {
  const { t } = useTranslation(['general']);
  const statusText = useQuestionnaireStatusLabel({ status, isRecipient });
  const { icon } = questionnaireStatusConfig[status];

  return (
    <Box {...props}>
      <Text fontSize={1} fontWeight={500} mb={1}>
        {t('status')}
      </Text>
      <Flex alignItems="center" fontSize={2}>
        <Icon
          fixedWidth
          color={icon.color}
          icon={icon.value as IconProps['icon']}
          regular={icon.isRegular}
          mr={1}
        />
        <Bold>{statusText}</Bold>
      </Flex>
    </Box>
  );
};

export const QuestionnaireStatusCell = ({ value, isRecipient }: CellProps<QuestionnaireOverview, QuestionnaireStatus>) => {
  return (
    <Flex alignItems="center" fontSize={2}>
      <QuestionnaireStatusIcon status={value} />
      <Truncate>
        <QuestionnaireStatusFieldValue value={value} isRecipient={isRecipient} />
      </Truncate>
    </Flex>
  );
};
