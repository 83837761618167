import { useTranslation } from 'react-i18next';
import {
  Live, ResponseTag, getStageIdFromTag,
} from '@deepstream/common/rfq-utils';
import { InlineButton } from '@deepstream/ui-kit/elements/button/InlineButton';
import { last } from 'lodash';
import * as rfx from '../../../rfx';
import { useRequestRecipientNavigation } from '../../../appNavigation';
import * as lotPagesLayout from '../Live/lotPagesLayout';

export const LineItemLatestResponseStageInfo = ({ stageId }: { stageId: string }) => {
  const { t } = useTranslation('translation');
  const { stages } = rfx.useStructure<Live>();
  const requirementGroupId = rfx.useRequirementGroupId();
  const page = rfx.usePage();
  const navigation = useRequestRecipientNavigation();

  const stageIndex = stages.findIndex(stage => stage._id === stageId);

  return (
    <lotPagesLayout.InfoText>
      {t('request.lineItems.newResponseRequired', { stageNumber: stageIndex + 1 })}
      {' '}
      <InlineButton
        style={{ fontWeight: 400, textDecoration: 'underline' }}
        onClick={() => {
          // @ts-expect-error ts(18047) FIXME: 'page' is possibly 'null'.
          navigation.navigateToBid(stageId, requirementGroupId, page._id);
        }}
      >
        {t('request.lineItems.openStage', { stageNumber: stageIndex + 1 })}
      </InlineButton>
    </lotPagesLayout.InfoText>
  );
};

export const LineItemModalLatestResponseStageInfo = ({
  stageId,
  exchangeId,
} : {
  stageId: string;
  exchangeId: string;
}) => {
  const { t } = useTranslation('translation');
  const { activatedStageIds } = rfx.useBid();
  const currentStageId = rfx.useStageId();
  const requirementGroupId = rfx.useRequirementGroupId();
  const page = rfx.usePage();
  const navigation = useRequestRecipientNavigation();
  const { stages } = rfx.useStructure<Live>();

  const lastActivatedStageId = last(activatedStageIds);

  const stageIndex = stages.findIndex(stage => stage._id === stageId);

  return stageId === lastActivatedStageId && lastActivatedStageId !== currentStageId ? (
    <InlineButton
      style={{ fontWeight: 400, textDecoration: 'underline', marginTop: '4px' }}
      onClick={() => {
        // @ts-expect-error ts(18047) FIXME: 'page' is possibly 'null'.
        navigation.navigateToBid(stageId, requirementGroupId, page._id, exchangeId);
      }}
    >
      {t('request.lineItems.openRequirementInStage', { stageNumber: stageIndex + 1 })}
    </InlineButton>
  ) : (
    null
  );
};
