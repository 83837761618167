import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useFormikContext } from 'formik';
import { compact, isArray, isEmpty, isPlainObject, isString } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';

const getFlattenedTouchedErrors = (value, touched) => {
  if (isString(value)) {
    return touched ? [value] : [];
  } else if (isArray(value)) {
    return value.flatMap((item, index) => getFlattenedTouchedErrors(item, touched?.[index]));
  } else if (isPlainObject(value)) {
    return Object.entries(value).flatMap(([key, item]) => getFlattenedTouchedErrors(item, touched?.[key]));
  } else {
    return [];
  }
};

export const FormErrorsLayout = ({ errors }: { errors: any[] }) => {
  const { t } = useTranslation('translation');

  return isEmpty(errors) ? (
    null
  ) : (
    <MessageBlock variant="error" mt={0}>
      <Text fontSize={4} fontWeight={500} mb={1}>
        {t('request.awardFlow.thereAreErrors')}
      </Text>
      {errors.map((error: any, index) => (
        <Text color="danger" key={`${error}-${index}`}>{error}</Text>
      ))}
    </MessageBlock>
  );
};

export const FormErrors = ({ transformErrors }: { transformErrors?: any }) => {
  const { errors, touched } = useFormikContext();

  const transformedErrors = transformErrors ? transformErrors(errors) : errors;

  const flattenedTouchedErrors = compact(getFlattenedTouchedErrors(transformedErrors, touched));

  return <FormErrorsLayout errors={flattenedTouchedErrors} />;
};
