import {
  AuctionLineItemExchangeDefinition,
  EvaluationCriterionExchangeDefinition,
  LineItemExchangeDefinition,
  LinkedEvaluationCriterionExchangeDefinition,
  getExchangeDefFieldValue,
  resolveExchangeDefFieldValue,
} from '@deepstream/common/rfq-utils';
import * as React from 'react';
import * as rfx from './rfx';

export type ExchangeDefWithFields =
  | LineItemExchangeDefinition
  | AuctionLineItemExchangeDefinition
  | EvaluationCriterionExchangeDefinition
  | LinkedEvaluationCriterionExchangeDefinition;

type ExchangeDefFieldValue = {
  getFieldValue: (exchangeDef: ExchangeDefWithFields, fieldId: string) => any;
};

const defaultValue: ExchangeDefFieldValue = {
  // @ts-expect-error ts(2322) FIXME: Type '(exchangeDef: ExchangeDefinition, fieldId: keyof ExchangeDefinition["fields"]) => any' is not assignable to type '(exchangeDef: ExchangeDefWithFields, fieldId: string) => any'.
  getFieldValue: getExchangeDefFieldValue,
};

const ExchangeDefFieldValueContext = React.createContext<ExchangeDefFieldValue | null>(defaultValue);

export const ExchangeDefFieldValueProvider = ({ children }: { children: React.ReactNode }) => {
  const rfxStructure = rfx.useStructure();

  const getFieldValue = React.useCallback(
    (exchangeDef: ExchangeDefWithFields, fieldId: string) => {
      return resolveExchangeDefFieldValue(rfxStructure.exchangeDefById, exchangeDef, fieldId);
    },
    [rfxStructure.exchangeDefById],
  );

  const contextValue = React.useMemo(() => ({
    getFieldValue,
  }), [getFieldValue]);

  return (
    <ExchangeDefFieldValueContext.Provider value={contextValue}>
      {children}
    </ExchangeDefFieldValueContext.Provider>
  );
};

export const useExchangeDefFieldValue = () => {
  const fieldValue = React.useContext(ExchangeDefFieldValueContext);
  if (!fieldValue) throw new Error('no fieldValue found');
  return fieldValue;
};
