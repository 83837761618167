import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Text } from 'rebass/styled-components';
import { compact, filter, isEmpty, isEqual, pick, pickBy, values } from 'lodash';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { ContractStatus, EXPIRY_DATE_MILESTONE, ExpiryDateType, StartDateType, isMilestoneReminder } from '@deepstream/common/contract';
import { TimeUnit } from '@deepstream/common/rfq-utils';
import { withProps } from '@deepstream/ui-utils/withProps';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { DateFormat } from '@deepstream/utils';
import { TextField } from '../../form/TextField';
import { PreWrapValueOrEmDash, PropertyList, ValueOrEmDash } from '../../PropertyList';
import { SummaryLabelConfigProvider } from '../../draft/SummaryLabelConfigProvider';
import { Validation } from '../../draft/validation';
import { ValidationPropertyRow } from '../../draft/ValidationPropertyRow';
import { ValidationErrorValue } from '../../draft/ValidationErrorValue';
import { nestValues } from '../../nestValues';
import { useContractData, useContractActions, useContractState } from './contract';
import { useUpdateContractSummary, useShowValidationErrors } from './draftContract';
import { ContractSummaryPanelHeader } from './ContractSummaryPanelHeader';
import { DatetimeField } from '../../form/DatetimeField';
import { useConfirmDialog } from '../../ui/useModalState';
import { SelectField, SelectFieldBase } from '../../form/SelectField';
import { FieldContainer } from '../../form/FieldContainer';
import { Datetime2 } from '../../Datetime';
import { LeavePageModal } from '../../draft/LeavePageModal';

const panelId = 'basicDetails';

const StartDateTypeLabel = ({ value }) => {
  const { t } = useTranslation('contracts');
  return (
    <>
      {value ? t(`summary.startDateType.${value}`) : <EmDash />}
    </>
  );
};

const ExpiryDateTypeLabel = ({ value }) => {
  const { t } = useTranslation('contracts');
  return (
    <>
      {value ? t(`summary.expiryDateType.${value}`) : <EmDash />}
    </>
  );
};

export const ContractBasicDetailsPanel = () => {
  const { t } = useTranslation(['contracts', 'general']);
  const { stopEditing } = useContractActions();
  // @ts-expect-error ts(2339) FIXME: Property 'editingPanelId' does not exist on type 'ContractStateContextType | undefined'.
  const { editingPanelId, isLive, isDraft, isTemplate, isTemplatePreview } = useContractState();
  const contract = useContractData();
  const [updateContractSummary] = useUpdateContractSummary();
  const showValidationErrors = useShowValidationErrors();
  const { confirm, ...dialogProps } = useConfirmDialog();

  const isEditingOtherPanel = editingPanelId && editingPanelId !== panelId;
  const isEditingThisPanel = editingPanelId && editingPanelId === panelId;

  const isContractSigned = [
    ContractStatus.AGREED,
    ContractStatus.ACTIVE,
    ContractStatus.EXPIRED,
    ContractStatus.TERMINATED,
  ].includes(contract.status);

  const isExactStartDateSet = contract.summary.startDateType === StartDateType.EXACT_DATE && contract.summary.startDate;
  const isExactExpiryDateSet = contract.summary.expiryDateType === ExpiryDateType.EXACT_DATE && contract.summary.expiryDate;

  const noExpiryDate = contract.summary.expiryDateType === ExpiryDateType.NO_EXPIRY_DATE;

  const properties = useMemo(() => compact([
    {
      fieldName: 'name',
      name: t('summary.contractName'),
      value: contract.summary.name,
      Component: nestValues(
        withProps(ValidationErrorValue, { useShowValidationErrors }),
        ValueOrEmDash,
      ),
    },
    {
      fieldName: 'description',
      name: t('summary.contractDescription'),
      value: contract.summary.description,
      Component: nestValues(
        withProps(ValidationErrorValue, { useShowValidationErrors }),
        PreWrapValueOrEmDash,
      ),
      heightAuto: true,
    },
    // For the `startDate` property we will display either:
    // a. the start date type - if we are in drafting mode (except for EXACT_DATE that have already been set)
    //  or it hasn't been set or calculated yet
    // b. the start date - if it has been calculated (on signature), or the user has selected an exact date (on summary update)
    (!isContractSigned || isDraft) && !isExactStartDateSet ? {
      fieldName: 'isStartDateComplete',
      name: t('summary.startDate'),
      value: contract.summary.startDateType,
      Component: nestValues(
        withProps(ValidationErrorValue, { useShowValidationErrors }),
        StartDateTypeLabel,
      ),
    } : {
      fieldName: 'startDate',
      name: t('summary.startDate'),
      value: isExactStartDateSet ? contract.summary.startDate : contract.calculatedStartDate,
      Component: withProps(Datetime2, { format: DateFormat.DD_MMM_YYYY }),
    },
    // For the `expiryDate` property we will display either:
    // a. the expiry date type - if we are in drafting mode (except for EXACT_DATE that have already been set)
    //  or it hasn't been set or calculated yet
    // b. the expiry date - if it has been calculated (on signature), or the user has selected an exact date (on summary update)
    // c. `No expiry date` label - if we are in live mode, the contract is signed and the NO_EXPIRY_DATE type has been selected
    (!isContractSigned || isDraft) && !isExactExpiryDateSet ? {
      fieldName: 'isExpiryDateComplete',
      name: t('summary.expiryDate'),
      value: contract.summary.expiryDateType,
      Component: nestValues(
        withProps(ValidationErrorValue, { useShowValidationErrors }),
        ExpiryDateTypeLabel,
      ),
    } : {
      fieldName: 'expiryDate',
      name: t('summary.expiryDate'),
      value: noExpiryDate
        ? contract.summary.expiryDateType
        : isExactExpiryDateSet
          ? contract.summary.expiryDate
          : contract.calculatedExpiryDate,
      Component: noExpiryDate
        ? ExpiryDateTypeLabel
        : withProps(Datetime2, { format: DateFormat.DD_MMM_YYYY }),
    },
  ]), [
    t,
    contract.summary,
    contract.calculatedStartDate,
    contract.calculatedExpiryDate,
    isContractSigned,
    isDraft,
    isExactStartDateSet,
    isExactExpiryDateSet,
    noExpiryDate,
  ]);

  const initialValues = pick(
    contract.summary,
    // Adding `reminders` in this form because they can be updated as a side effect when removing milestones
    ['name', 'description', 'startDateType', 'startDateOffsetConfig', 'startDate', 'expiryDateType', 'expiryDateOffsetConfig', 'expiryDate'],
  );

  const heading = t('summary.basicDetails');

  const dateSchema = yup
    .date()
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr)
    .required(t('required', { ns: 'general' }));

  const startDateTypeSchema = yup
    .string()
    .oneOf(values(StartDateType))
    .required();

  const startDateOffsetConfigSchema = yup
    .object()
    .nullable()
    .when('startDateType', {
      is: StartDateType.TIME_BASED,
      then: yup.object().shape({
        unit: yup.string().required(),
        amount: yup.number().min(1).required(),
      }).required(),
    });

  const startDateSchema = contract.summary.startDateType !== StartDateType.EXACT_DATE
    ? yup.date().nullable()
    : contract.summary.expiryDate
      ? dateSchema.max(contract.summary.expiryDate, t('review.errors.mustBeBeforeExpiryDate'))
      : dateSchema;

  const expiryDateTypeSchema = yup
    .string()
    .oneOf(values(ExpiryDateType))
    .required();

  const expiryDateOffsetConfigSchema = yup
    .object()
    .nullable()
    .when('expiryDateType', {
      is: ExpiryDateType.TIME_BASED,
      then: yup.object().shape({
        unit: yup.string().required(),
        amount: yup.number().min(1).required(),
      }).required(),
    });

  const expiryDateSchema = contract.summary.expiryDateType !== ExpiryDateType.EXACT_DATE
    ? yup.date().nullable()
    : contract.summary.startDate
      ? dateSchema.min(contract.summary.startDate, t('review.errors.mustBeAfterStartDate'))
      : dateSchema;

  const getStartDateTypeItems = useCallback(
    expiryDateType => ([
      {
        value: StartDateType.EXACT_DATE,
        label: t(`summary.startDateType.${StartDateType.EXACT_DATE}`),
        disabled: isTemplate,
      },
      {
        value: StartDateType.TIME_BASED,
        label: t(`summary.startDateType.${StartDateType.TIME_BASED}`),
        disabled: expiryDateType === ExpiryDateType.EXACT_DATE,
      },
      {
        value: StartDateType.ON_SIGNATURE,
        label: t(`summary.startDateType.${StartDateType.ON_SIGNATURE}`),
        disabled: expiryDateType === ExpiryDateType.EXACT_DATE,
      },
    ]),
    [isTemplate, t],
  );

  const getExpiryDateTypeItems = useCallback(
    startDateType => ([
      {
        value: ExpiryDateType.EXACT_DATE,
        label: t(`summary.expiryDateType.${ExpiryDateType.EXACT_DATE}`),
        disabled: startDateType !== StartDateType.EXACT_DATE || isTemplate,
      },
      { value: ExpiryDateType.TIME_BASED, label: t(`summary.expiryDateType.${ExpiryDateType.TIME_BASED}`) },
      { value: ExpiryDateType.NO_EXPIRY_DATE, label: t(`summary.expiryDateType.${ExpiryDateType.NO_EXPIRY_DATE}`) },
    ]),
    [isTemplate, t],
  );

  const unitItems = useMemo(
    () => [
      { value: TimeUnit.DAYS, label: t('timeUnit.days', { ns: 'general' }) },
      { value: TimeUnit.MONTHS, label: t('timeUnit.months', { ns: 'general' }) },
      { value: TimeUnit.YEARS, label: t('timeUnit.years', { ns: 'general' }) },
    ],
    [t],
  );

  return (
    <Panel
      as="section"
      aria-label={heading}
      sx={{
        opacity: isEditingOtherPanel ? 0.5 : 1,
        boxShadow: isEditingThisPanel ? '0 0 8px 0 rgba(0, 0, 0, 0.3)' : '',
      }}
    >
      <ContractSummaryPanelHeader heading={heading} panelId={panelId} canEdit={!isLive && !isTemplatePreview} />
      <PanelDivider />
      {isEditingThisPanel ? (
        <Formik
          validateOnBlur
          enableReinitialize
          initialValues={initialValues}
          validationSchema={
            yup.object().shape({
              name: yup.string().nullable(),
              description: yup.string().nullable(),
              startDateType: yup.string().nullable().oneOf([...values(StartDateType), null]),
              startDateOffsetConfig: yup.object().nullable().when('startDateType', {
                is: StartDateType.TIME_BASED,
                then: yup.object().shape({
                  unit: yup.string(),
                  amount: yup.number().min(1),
                }),
              }),
              startDate: yup.date().nullable().when('startDateType', {
                is: StartDateType.EXACT_DATE,
                then: yup.date(),
              }),
              expiryDateType: yup.string().nullable().oneOf([...values(ExpiryDateType), null]),
              expiryDateOffsetConfig: yup.object().nullable().when('expiryDateType', {
                is: ExpiryDateType.TIME_BASED,
                then: yup.object().shape({
                  unit: yup.string(),
                  amount: yup.number().min(1),
                }),
              }),
              expiryDate: yup.date().nullable(),
            })
          }
          onSubmit={async (values) => {
            const newSummary = pickBy(
              values,
              (value, key) => !isEqual(value, initialValues[key]),
            );

            const userTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

            // We need to know the user's time zone in order to accurately calculate the time based dates on the server
            if (newSummary.startDateType === StartDateType.TIME_BASED || newSummary.startDateOffsetConfig) {
              newSummary.startDateTz = userTz;
            } else if (newSummary.startDateType) {
              // If the start date type changes to something else, clear the saved time zone
              newSummary.startDateTz = null;
            }

            if (newSummary.expiryDateType === ExpiryDateType.TIME_BASED || newSummary.expiryDateOffsetConfig) {
              newSummary.expiryDateTz = userTz;
            } else if (newSummary.expiryDateType) {
              // If the expiry date type changes to something else, clear the saved time zone
              newSummary.expiryDateTz = null;
            }

            const expiryDateReminders = filter(
              contract.reminders,
              reminder => isMilestoneReminder(reminder) && reminder.milestoneId === EXPIRY_DATE_MILESTONE,
            );

            if (!isEmpty(expiryDateReminders) && newSummary.expiryDateType === ExpiryDateType.NO_EXPIRY_DATE) {
              // This action will remove the reminders associated with the expiry date,
              // so we need to ask for confirmation
              confirm(async () => {
                await updateContractSummary(
                  { summary: newSummary },
                  { onSuccess: () => stopEditing() },
                );
              });
            } else {
              await updateContractSummary(
                { summary: newSummary },
                { onSuccess: () => stopEditing() },
              );
            }
          }}
        >
          {({ isSubmitting, dirty, isValid, resetForm, values, setFieldValue }) => (
            <Form>
              <SummaryLabelConfigProvider>
                <Stack gap={20} m={20}>
                  <TextField
                    label={t('summary.contractName')}
                    name="name"
                    required
                    // quick fix: we can remove setting the inputStyle height once a line height is
                    // set for Input
                    inputStyle={{ height: 40 }}
                  />
                  <TextField
                    name="description"
                    isMultiLine
                    rows={9}
                    label={t('summary.contractDescription')}
                    helperText={t('summary.descriptionHelperText')}
                    placeholder={t('summary.descriptionPlaceholder')}
                    required
                  />
                  <FieldContainer
                    label={t('summary.startDate')}
                    showAsterisk
                    labelStyle={{ fontSize: 2, fontWeight: 500 }}
                  >
                    <Flex sx={{ gap: 3 }}>
                      <SelectFieldBase
                        name="startDateType"
                        items={getStartDateTypeItems(values.expiryDateType)}
                        buttonStyle={{ width: '220px' }}
                        menuWidth="220px"
                        value={values.startDateType}
                        onChange={value => {
                          setFieldValue('startDateType', value);

                          if (value === StartDateType.TIME_BASED) {
                            setFieldValue('startDateOffsetConfig', { unit: TimeUnit.DAYS });
                          } else if (values.startDateOffsetConfig) {
                            setFieldValue('startDateOffsetConfig', null);
                          }

                          if (value !== StartDateType.EXACT_DATE && values.startDate) {
                            setFieldValue('startDate', null);
                          }
                        }}
                      />
                      {values.startDateType === StartDateType.EXACT_DATE && (
                        <Box width="350px">
                          <DatetimeField
                            required
                            name="startDate"
                            hideLabel
                            dateFormat="dd MMM yyyy"
                            useStartOfDay
                            max={values.expiryDate ? new Date(values.expiryDate) : undefined}
                          />
                        </Box>
                      )}
                      {values.startDateType === StartDateType.TIME_BASED && (
                        <Flex sx={{ gap: 3, alignItems: 'center' }}>
                          <Flex sx={{ gap: 2 }}>
                            <Box width="40px">
                              <TextField
                                name="startDateOffsetConfig.amount"
                                inputType="number"
                                format="integer.positive"
                                hideError
                              />
                            </Box>
                            <Box width="100px">
                              <SelectField name="startDateOffsetConfig.unit" items={unitItems} />
                            </Box>
                          </Flex>
                          <Text>{t('summary.afterSignature')}</Text>
                        </Flex>
                      )}
                    </Flex>
                  </FieldContainer>
                  <FieldContainer
                    label={t('summary.expiryDate')}
                    showAsterisk
                    labelStyle={{ fontSize: 2, fontWeight: 500 }}
                  >
                    <Flex sx={{ gap: 3 }}>
                      <SelectFieldBase
                        name="expiryDateType"
                        items={getExpiryDateTypeItems(values.startDateType)}
                        buttonStyle={{ width: '220px' }}
                        menuWidth="220px"
                        value={values.expiryDateType}
                        onChange={value => {
                          setFieldValue('expiryDateType', value);

                          if (value === ExpiryDateType.TIME_BASED) {
                            setFieldValue('expiryDateOffsetConfig', { unit: TimeUnit.DAYS });
                          } else if (values.expiryDateOffsetConfig) {
                            setFieldValue('expiryDateOffsetConfig', null);
                          }

                          if (value !== ExpiryDateType.EXACT_DATE && values.expiryDate) {
                            setFieldValue('expiryDate', null);
                          }
                        }}
                      />
                      {values.expiryDateType === ExpiryDateType.EXACT_DATE && (
                        <Box width="350px">
                          <DatetimeField
                            required
                            name="expiryDate"
                            hideLabel
                            dateFormat="dd MMM yyyy"
                            fullWidth
                            min={values.startDate ? new Date(values.startDate) : undefined}
                            useEndOfDay
                          />
                        </Box>
                      )}
                      {values.expiryDateType === ExpiryDateType.TIME_BASED && (
                        <Flex sx={{ gap: 3, alignItems: 'center' }}>
                          <Flex sx={{ gap: 2 }}>
                            <Box width="40px">
                              <TextField
                                name="expiryDateOffsetConfig.amount"
                                inputType="number"
                                format="integer.positive"
                                hideError
                              />
                            </Box>
                            <Box width="100px">
                              <SelectField name="expiryDateOffsetConfig.unit" items={unitItems} />
                            </Box>
                          </Flex>
                          <Text>{t('summary.afterStartDate')}</Text>
                        </Flex>
                      )}
                    </Flex>
                  </FieldContainer>
                </Stack>
              </SummaryLabelConfigProvider>
              <PanelDivider />
              <PanelPadding>
                <Flex justifyContent="flex-end">
                  <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
                  <SaveButton disabled={isSubmitting || !dirty || !isValid} />
                </Flex>
              </PanelPadding>
              <LeavePageModal />
            </Form>
          )}
        </Formik>
      ) : (
        <Validation
          values={contract.summary}
          schema={showValidationErrors ? (
            yup.object().shape({
              name: yup.string().nullable().required(t('required', { ns: 'general' })),
              description: yup.string().nullable().required(t('required', { ns: 'general' })),
              startDateType: startDateTypeSchema,
              startDateOffsetConfig: startDateOffsetConfigSchema,
              startDate: startDateSchema,
              expiryDateType: yup.string().oneOf(values(ExpiryDateType)).required(),
              expiryDateOffsetConfig: expiryDateOffsetConfigSchema,
              expiryDate: contract.summary.expiryDateType !== ExpiryDateType.EXACT_DATE
                ? yup.date().nullable()
                : contract.summary.startDate
                  ? dateSchema.min(contract.summary.startDate, t('review.errors.mustBeAfterStartDate'))
                  : dateSchema,
            }).test({
              name: 'isStartDateComplete',
              async test({ startDateType, startDate, startDateOffsetConfig }) {
                const completeStartDateSchema = yup.object({
                  startDateType: startDateTypeSchema,
                  startDateOffsetConfig: startDateOffsetConfigSchema,
                  startDate: startDateSchema,
                });

                const isValid = await completeStartDateSchema.isValid({
                  startDateType,
                  startDateOffsetConfig,
                  startDate,
                });

                if (isValid) return true;
                else {
                  return this.createError({
                    path: 'isStartDateComplete',
                    message: t('required', { ns: 'general' }),
                  });
                }
              },
            }).test({
              name: 'isExpiryDateComplete',
              async test({ expiryDateType, expiryDate, expiryDateOffsetConfig }) {
                const completeExpiryDateSchema = yup.object({
                  expiryDateType: expiryDateTypeSchema,
                  expiryDateOffsetConfig: expiryDateOffsetConfigSchema,
                  expiryDate: expiryDateSchema,
                });

                const isValid = await completeExpiryDateSchema.isValid({
                  expiryDateType,
                  expiryDateOffsetConfig,
                  expiryDate,
                });

                if (isValid) return true;
                else {
                  return this.createError({
                    path: 'isExpiryDateComplete',
                    message: t('required', { ns: 'general' }),
                  });
                }
              },
            })
          ) : (
            yup.mixed()
          )}
        >
          <PropertyList
            Row={withProps(ValidationPropertyRow, { useShowValidationErrors })}
            properties={properties}
          />
        </Validation>
      )}
      <Dialog
        heading={t('dialog.confirmExpiryDateChange.heading')}
        body={
          <MessageBlock variant="warn" my={2}>
            {t('dialog.confirmExpiryDateChange.message')}
          </MessageBlock>
        }
        style={{ content: { width: '500px' } }}
        okButtonText={t('confirm', { ns: 'general' })}
        okButtonVariant="danger"
        cancelButtonText={t('cancel', { ns: 'general' })}
        {...dialogProps}
      />
    </Panel>
  );
};
