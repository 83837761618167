import { Trans } from 'react-i18next';
import { BoxProps } from 'rebass/styled-components';

import { InlineButton } from '@deepstream/ui-kit/elements/button/InlineButton';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useDraftContractNavigation } from '../../../appNavigation';

export const NoEditPermissions = (styleBoxProps?: Omit<BoxProps, 'variant'>) => {
  const { navigateToTeam } = useDraftContractNavigation();

  return (
    <MessageBlock mt={0} variant="info" {...styleBoxProps}>
      <Trans i18nKey="permissions.noEditPermissions" ns="contracts">
        Your permissions for this contract do not allow you to edit this page.
        Permissions can be updated by a contract owner in the <InlineButton onClick={() => navigateToTeam()}>Team</InlineButton> tab
      </Trans>
    </MessageBlock>
  );
};
