import { useMemo, useCallback } from 'react';
import { EvaluationPage, PageRole, PageType } from '@deepstream/common/rfq-utils';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { compact } from 'lodash';
import { useField } from 'formik';
import { RoleRestrictionProfile } from '@deepstream/common/user-utils';
import { CellProps } from 'react-table';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Clamp } from '@deepstream/ui-kit/elements/text/Clamp';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { DropdownMenuDivider } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { PagePermissionTableStyles } from '../../../TableStyles';
import { Table } from '../../../Table';
import { User } from '../../../types';
import { Label } from '../../../ui/Label';
import {
  getItemLabelWithIcon,
  getItemLabelWithIconAndDescription,
  SelectField,
} from '../../../form/SelectField';
import * as rfx from '../../../rfx';
import { Bold } from '../../../Bold';
import { Avatar } from '../../../Avatar';
import { usePagePermissionOptions } from './utils';

type PermissionRow = {
  companyId: string;
  user: User;
};

export const HIDDEN_ITEM_VALUE = '__hidden__';

type PermissionCellProps = CellProps<PermissionRow> & {
  column: {
    label: string;
    pageId: string;
    companyUserById: Record<string, Record<string, User>>;
  }
};

const PagePermissionCell = ({ row, column }: PermissionCellProps) => {
  const { companyId, user } = row.original;

  const {
    id: columnId,
    pageId,
    companyUserById,
  } = column;

  const { teamById, pageById, pages } = rfx.useStructure();
  const team = teamById[companyId];

  const page = pageById[pageId];

  const isOwner = team.owners.includes(user._id);
  const isAuthorizedStakeholderSender = (
    companyUserById[companyId][user._id]?.roleRestrictionProfiles?.[companyId] === RoleRestrictionProfile.AUTHORIZED_STAKEHOLDER
  );

  const [{ value: rfqRoles },, formik] = useField(`${companyId}.${user._id}`);

  const pageType = page.type;

  const options = usePagePermissionOptions({
    pageType,
    isAuthorizedStakeholderSender,
    isSender: true,
    isOwner,
  });

  const handlePageRoleChange = useCallback((newPageRole) => {
    const linkedEvaluationPage = columnId === 'page' && newPageRole === PageRole.NONE
      ? pages.find(page => (page as EvaluationPage).linkedPageId === pageId)
      : null;

    if (linkedEvaluationPage) {
      formik.setValue({
        ...rfqRoles,
        [pageId]: newPageRole,
        [linkedEvaluationPage._id]: PageRole.NONE,
      });
    } else {
      formik.setValue({
        ...rfqRoles,
        [pageId]: newPageRole,
      });
    }
  }, [columnId, pages, formik, rfqRoles, pageId]);

  const isDisabled = (
    // We disable editing the page role only when an owner doesn't
    // have the expected EDITOR page role (which can be the case
    // in legacy requests).
    columnId === 'page' &&
    isOwner &&
    rfqRoles[pageId] === PageRole.EDITOR
  ) || (
    // When the user doesn't have read access to the page linked by
    // an evaluation page, the user cannot act as an evaluator.
    columnId === 'linkedEvaluationPage' &&
    // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
    rfqRoles[(page as EvaluationPage).linkedPageId] === PageRole.NONE
  );

  return (
    <SelectField
      name={`${companyId}.${user._id}.${pageId}`}
      items={options}
      disabled={isDisabled}
      buttonStyle={{
        width: '180px',
        borderRadius: 0,
        height: '40px',
        borderColor: 'transparent',
        borderTop: isDisabled ? 0 : 'lightGray2',
        borderBottom: isDisabled ? 0 : 'lightGray2',
        borderLeft: isDisabled ? 0 : 'lightGray2',
        borderRight: isDisabled ? 'subtext' : 'lightGray2',
      }}
      getButtonLabel={getItemLabelWithIcon}
      getItemLabel={getItemLabelWithIconAndDescription}
      placement="bottom-end"
      menuWidth={220}
      onChange={handlePageRoleChange}
      footer={columnId === 'linkedEvaluationPage' ? (
        <EvaluationPageRoleSelectFooter />
      ) : (
        undefined
      )}
    />
  );
};

const EvaluationPageRoleSelectFooter = () => {
  return (
    <Box pt={1}>
      <DropdownMenuDivider />
      <Text fontSize={0} color="subtext" padding="10px 10px 7px">
        <Icon icon="info-circle" mr={2} />
        <Trans i18nKey="request.team.evaluationPageRoleSelectFooter">
          {' '}
          <Bold />
          {' '}
        </Trans>
      </Text>
    </Box>
  );
};

export const RequestTeamEditUsersFields = ({
  companyUserById,
}: {
  companyUserById: Record<string, Record<string, User>>;
}) => {
  const { t } = useTranslation();
  const { pageById } = rfx.useStructure();
  const page = rfx.usePage() as EvaluationPage;
  const { teamById, senders } = rfx.useStructure();
  const senderIds = senders.map(sender => sender._id);

  const usersWithCompanyId = senderIds.flatMap(senderId => Object.values(teamById[senderId].users).map(user => ({
    companyId: senderId,
    user,
  })));

  const evaluationPageId = page._id;
  const detailsPageId = page.linkedPageId;

  const columns = useMemo(() => compact([
    {
      id: 'user',
      Header: t('general.user', { count: 1 }),
      Cell: ({ row: { original: { companyId, user } } }) => (
        <Flex>
          <Avatar width="xxs" userId={user._id} sx={{ mr: 1 }} />
          <Text width="100%">
            <Clamp lines={1}>
              {user.name || companyUserById[companyId][user._id]?.name}
            </Clamp>
          </Text>
        </Flex>
      ),
      verticalAlign: 'middle',
    },
    {
      id: 'page',
      Header: t('general.details'),
      Cell: PagePermissionCell,
      width: 180,
      pageId: detailsPageId,
      companyUserById,
    },
    {
      id: 'linkedEvaluationPage',
      Header: t('request.evaluation.evaluation'),
      Cell: PagePermissionCell,
      width: 180,
      pageId: evaluationPageId,
      companyUserById,
    },
  ]), [t, companyUserById, evaluationPageId, detailsPageId]);

  return (
    <Box>
      <Label label={t('request.page', { count: 1 })} color="lightNavy" />
      <Box mb={3}>
        {/*
         // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type. */}
        {pageById[page.linkedPageId].name}
      </Box>
      <Label label={t('teamManagement.permission_other')} color="lightNavy" />
      <Panel maxWidth="600px">
        <PagePermissionTableStyles px="10px">
          <Table columns={columns} data={usersWithCompanyId} />
        </PagePermissionTableStyles>
      </Panel>
      <Box width="600px" mt={3}>
        <MessageBlock variant="info" mt={0}>
          <Trans i18nKey="request.team.canAddUsersFromTeamTab">
            {' '}
            <Bold />
            {' '}
          </Trans>
        </MessageBlock>
      </Box>
    </Box>
  );
};
