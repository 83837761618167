import { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { withProps } from '@deepstream/ui-utils/withProps';
import { EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { BasicTableStyles, StaticTableStyles } from '../../../TableStyles';
import { Table } from '../../../Table';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useLocalStorageState } from '../../../useLocalStorageState';
import { FieldsCell, FieldsCellField, MIN_CELL_HEIGHT } from '../../../FieldsCell';
import { UserAvatar } from '../../../UserAvatar';
import { TeamUserCellTitle } from '../../../TeamUserCellTitle';
import { useConfirmDialog, useModalState } from '../../../ui/useModalState';
import { useCurrentUser } from '../../../useCurrentUser';
import { ContractTeamUser } from '../../../types';
import { useContractData, useContractPermissions, useContractState } from '../contract';
import { useRemoveContractTeamMember } from '../draftContract';
import { ContractTeamUserModal } from './ContractTeamUserModal';
import { RemoveSelfDialog, RemoveTeamMemberDialog } from '../../../RemoveTeamMemberDialog';

const TeamUsersActionButtons = ({
  row,
  openRemoveUserModal,
  onExpandedStateChange,
  openEditUserModal,
}: CellProps<any> & {
  openRemoveUserModal: (user: ContractTeamUser) => void;
  onExpandedStateChange?: (isExpanded: boolean) => void;
  openEditUserModal: (user: ContractTeamUser) => void;
}) => {
  const { t } = useTranslation('contracts');
  const { teamById } = useContractData();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = row.original;

  const team = teamById[currentCompanyId];
  const isSoleContractOwner = team?.owners.length === 1 && team?.owners[0] === user._id;

  return (
    <ButtonGroup marginBetween="-1px">
      <EditButton small onClick={() => openEditUserModal(user)} />
      <DropdownMenu
        small
        onExpandedStateChange={onExpandedStateChange}
        variant="primary-outline"
        iconLeft="ellipsis-h"
        menuStyle={{ width: '150px' }}
        menuZIndex={10}
      >
        <DropdownMenuItem
          icon="times"
          color="danger"
          onSelect={() => openRemoveUserModal(user)}
          disabled={isSoleContractOwner}
        >
          {t('team.removeUser')}
        </DropdownMenuItem>
      </DropdownMenu>
    </ButtonGroup>
  );
};

export const ContractTeamUsersTable = ({
  users,
}: {
  users: ContractTeamUser[];
}) => {
  const { t } = useTranslation(['contracts', 'general']);
  const currentUser = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const [selectedUser, setSelectedUser] = useState<ContractTeamUser | null>();
  // @ts-expect-error ts(2339) FIXME: Property 'isLive' does not exist on type 'ContractStateContextType | undefined'.
  const { isLive, isReview } = useContractState();
  const { canEditTeam } = useContractPermissions();

  // Remove user
  const { confirm, ...confirmDialogProps } = useConfirmDialog();
  const [removeTeamMember] = useRemoveContractTeamMember();
  const removeSelfDialog = useModalState();

  // Edit user
  const editUserModal = useModalState();

  const openRemoveUserModal = useCallback((user: ContractTeamUser) => {
    const removeSelf = currentUser._id === user._id;
    setSelectedUser(user);

    if (removeSelf) {
      removeSelfDialog.open();
    } else {
      confirm(async () => {
        await removeTeamMember({ userId: user._id });
        setSelectedUser(null);
      });
    }
  }, [confirm, removeSelfDialog, removeTeamMember, currentUser._id]);

  const openEditUserModal = useCallback((user: ContractTeamUser) => {
    setSelectedUser(user);
    editUserModal.open();
  }, [editUserModal]);

  const columns = useMemo(
    () => [{ id: 'custom' }],
    [],
  );

  const CustomRowCells = useMemo(
    () => {
      const fields: FieldsCellField[] = [
        {
          label: t('email', { ns: 'general' }),
          accessor: 'email',
          // when there's no user name, the email is displayed in the cell's
          // title and thus shouldn't be repeated here
          condition: (user) => !!user.name,
        },
      ];

      return withProps(
        FieldsCell,
        {
          fields,
          TitleComponent: TeamUserCellTitle,
          RowIconComponent: UserAvatar,
          actionColumnWidth: 100,
          ActionsComponent: canEditTeam
            ? withProps(
              TeamUsersActionButtons,
              { openRemoveUserModal, openEditUserModal },
            )
            : () => null,
        },
      );
    },
    [canEditTeam, t, openRemoveUserModal, openEditUserModal],
  );

  const [initialPageSize, setInitialPageSize] = useLocalStorageState<number>({
    key: `${currentCompanyId}.${currentUser._id}.contractTeamUsers.pageSize`,
    defaultValue: 10,
  });

  const TableStyles = (isLive || isReview || !canEditTeam)
    ? StaticTableStyles
    : withProps(
      BasicTableStyles,
      { hoverBackgroundColor: 'lightGray6', hoverCursor: 'default' },
    );

  return (
    <>
      <TableStyles>
        <Table
          columns={columns}
          data={users}
          initialPageSize={initialPageSize}
          onPageSizeChange={setInitialPageSize}
          hideHeader
          CustomRowCells={CustomRowCells}
          hasStaticHeight={false}
          minCellHeight={MIN_CELL_HEIGHT}
          smallPageControls
          noDataPlaceholder={t('team.noUsersAdded')}
        />
      </TableStyles>

      {editUserModal.isOpen && (
        <ContractTeamUserModal
          companyId={currentCompanyId}
          // @ts-expect-error ts(2322) FIXME: Type 'ContractTeamUser | null | undefined' is not assignable to type 'ContractTeamUser | undefined'.
          user={selectedUser}
          close={() => {
            editUserModal.close();
            setSelectedUser(null);
          }}
        />
      )}

      {selectedUser && (
        <RemoveSelfDialog
          user={selectedUser}
          isOpen={removeSelfDialog.isOpen}
          close={() => {
            removeSelfDialog.close();
            setSelectedUser(null);
          }}
        />
      )}

      {selectedUser && (
        <RemoveTeamMemberDialog
          user={selectedUser}
          {...confirmDialogProps}
        />
      )}
    </>
  );
};
