import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AuctionStage, AuctionStatus, BidIntentionStatus, Live, RequestInteractivityStatus, RfxAuctionLineItemsSection, SectionType, Stage, StageStatus, isAuctionStage, renderStageName } from '@deepstream/common/rfq-utils';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Box, Flex, Text } from 'rebass/styled-components';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { DateFormat, localeFormatNumber } from '@deepstream/utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { ONE_DAY, ONE_HOUR } from '@deepstream/common/constants';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { groupBy, pickBy, sumBy } from 'lodash';
import { ProgressCircle } from '@deepstream/ui-kit/elements/ProgressCircle';
import { getCompleteResponseItemCount, getTotalResponseItemCount } from '@deepstream/common/bidProgress';
import { isBiddingOnLot } from '@deepstream/common/rfq-utils/lot';
import { NavigationMenu, NavigationMenuItem } from './NavigationMenu';
import * as rfx from '../../../rfx';
import { getFormattedDate } from '../../../Datetime';
import { useRecipientId } from '../../../useRfq';
import { Bold } from '../../../Bold';
import { useCurrentUserLocale } from '../../../useCurrentUser';
import { Deadline, useDeadline } from '../../../deadline';
import { ProgressByPageIdByRequirementGroupIdByStageId, RfxStructure } from '../../../types';
import { iconPropsByStageStatus } from '../Live/iconPropsByStageStatus';
import { useRequestRecipientNavigation } from '../../../appNavigation';
import { BuyerActionsRequiredIconAndText } from './BuyerActionsRequiredIconAndText';
import { useBidCommentNotifications } from './useBidCommentNotifications';
import { UnreadComments } from './UnreadComments';

const CountdownLabel: React.FC<any> = ({ color, iconStyle, text }) => {
  return (
    <Box as="span" ml={3} color={color}>
      <Icon
        icon="clock"
        regular
        mr={1}
        sx={iconStyle}
      />
      {text}
    </Box>
  );
};

const StageDeadlineCountdown = ({ deadline, isAuction }: { deadline: Deadline; isAuction?: boolean }) => {
  const { t } = useTranslation('translation');
  const theme = useTheme();
  const { remainingMs, countdown } = deadline;
  const { seconds, minutes, hours } = countdown;

  const translationKey = isAuction
    ? 'request.stages.durationRemaining'
    : 'request.stages.durationUntilDeadline';

  return (
    <>
      {remainingMs / ONE_HOUR < 1 ? (
        <CountdownLabel
          color={theme.colors.danger}
          text={t(translationKey, {
            duration: `${minutes}${t('general.minuteLetter')} ${seconds}${t('general.secondLetter')}`,
          })}
        />
      ) : remainingMs / ONE_HOUR < 24 ? (
        <CountdownLabel
          color={theme.colors.warning}
          text={t(translationKey, {
            duration: `${hours}${t('general.hourLetter')} ${minutes}${t('general.minuteLetter')}`,
          })}
        />
      ) : (
        <CountdownLabel
          text={t(translationKey, {
            duration: `${Math.floor(remainingMs / ONE_DAY)}${t('general.dayLetter')} ${hours}${t('general.hourLetter')}`,
          })}
        />
      )}
    </>
  );
};

export const PercentRequirementsComplete = ({
  factor,
}: {
  factor: number;
}) => {
  const locale = useCurrentUserLocale();
  const theme = useTheme();

  const percent = Math.round(100 * factor);

  return (
    <Box>
      <Box as="span" sx={{ top: '-2px', position: 'relative' }} mr={1}>
        <ProgressCircle width={14} progress={factor} colorPrimary={theme.colors.success} />
      </Box>
      <Trans
        i18nKey="request.percentRequirementsComplete"
        ns="translation"
        values={{ percent: localeFormatNumber(percent, { locale }) }}
        components={{ b: <b /> }}
      />
    </Box>
  );
};

const ExchangeStageRequirementsCompletion = ({
  progressByPageIdByRequirementGroupId,
}: {
  progressByPageIdByRequirementGroupId: ProgressByPageIdByRequirementGroupIdByStageId[string];
}) => {
  const { lotById } = rfx.useStructure<Live>();
  const bid = rfx.useBid();

  const {
    total,
    complete,
  } = React.useMemo(() => {
    const filteredProgressItems = pickBy(
      progressByPageIdByRequirementGroupId,
      (_, requirementGroupId) => {
        if (requirementGroupId === 'general') {
          return true;
        }

        const lot = lotById[requirementGroupId];

        return isBiddingOnLot(lot, bid.intentionStatusByLotId[requirementGroupId]);
      },
    );

    const flattenedProgressItems = Object.values(filteredProgressItems)
      .flatMap(progressByPageId => Object.values(progressByPageId));

    return {
      total: sumBy(flattenedProgressItems, getTotalResponseItemCount),
      complete: sumBy(flattenedProgressItems, getCompleteResponseItemCount),
    };
  }, [progressByPageIdByRequirementGroupId, bid, lotById]);

  return (
    <Text>
      <PercentRequirementsComplete factor={total === 0 ? 1 : complete / total} />
    </Text>
  );
};

const ExchangeStageNavigationMenuItem = ({
  stage,
  name,
  requestInteractivityStatus,
  bidIntentionStatus,
  numComments,
}: {
  stage: Stage;
  name: string;
  requestInteractivityStatus: RequestInteractivityStatus;
  bidIntentionStatus: BidIntentionStatus;
  numComments: number;
}) => {
  const { t } = useTranslation('translation');
  const locale = useCurrentUserLocale();
  const bid = rfx.useBid();
  const navigation = useRequestRecipientNavigation();
  const currentCompanyGroup = rfx.useCurrentCompanyGroup();

  const date = stage.completionDeadline;

  const hasBeenMovedToStage = bid.enteredStageIds.includes(stage._id);
  const hasActivatedStage = bid.activatedStageIds.includes(stage._id);

  const deadline = useDeadline({ deadline: date });

  const hasDeadlinePassed = deadline.hasPassed;

  const status = !hasBeenMovedToStage ? (
    StageStatus.PENDING
  ) : hasDeadlinePassed ? (
    StageStatus.DEADLINE_PASSED
  ) : (
    StageStatus.LIVE
  );

  const secondLineContent = !hasBeenMovedToStage ? (
    <IconText
      color="subtext"
      icon="lock"
      isIconRegular
      text={t(`request.stages.canOpenWhenMoved.${currentCompanyGroup}`)}
    />
  ) : !hasActivatedStage ? (
    requestInteractivityStatus === RequestInteractivityStatus.LIVE ? (
      <IconText
        color="subtext"
        icon="info-circle"
        isIconRegular
        text={bidIntentionStatus === BidIntentionStatus.BIDDING ? (
          t(`request.stages.mustClickContinue.${currentCompanyGroup}`)
        ) : (
          t(`request.stages.mustClickStartBidding.${currentCompanyGroup}`)
        )}
      />
    ) : (
      null
    )
  ) : (
    <>
      <ExchangeStageRequirementsCompletion
        progressByPageIdByRequirementGroupId={bid.progressByPageIdByRequirementGroupIdByStageId[stage._id]}
      />
      {hasDeadlinePassed && requestInteractivityStatus === RequestInteractivityStatus.LIVE && (
        <IconText
          mt="2px"
          color="subtext"
          icon="info-circle"
          isIconRegular
          text={t(`request.stages.canStillRespond.${currentCompanyGroup}`)}
        />
      )}
    </>
  );

  const body = (
    <>
      <Text>
        <Icon {...iconPropsByStageStatus[status]} mr={1} />
        <Bold mr={3}>
          {t(`request.stages.status.${status}.status`)}
        </Bold>
        {t('request.stages.deadlineOnDateTime', {
          dateTime: getFormattedDate(date, DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ, locale),
        })}
        {hasBeenMovedToStage && !hasDeadlinePassed && (
          <StageDeadlineCountdown deadline={deadline} />
        )}
      </Text>
      {secondLineContent ? (
        <Flex mt="2px">
          {secondLineContent}
          <UnreadComments count={numComments} ml={3} />
        </Flex>
      ) : numComments ? (
        <Text mt="2px">
          <UnreadComments count={numComments} />
        </Text>
      ) : (
        null
      )}
      {currentCompanyGroup === 'buyer' && (
        <Text mt="2px">
          <BuyerActionsRequiredIconAndText stageId={stage._id} />
        </Text>
      )}
    </>
  );

  return (
    <NavigationMenuItem
      key={stage._id}
      title={<Truncate>{name}</Truncate>}
      body={body}
      linkProps={navigation.getBidLinkProps(stage._id)}
      alignItems="center"
      disabled={!hasBeenMovedToStage}
    />
  );
};

const AuctionDeadlineCountdown = ({ auction }: { auction: RfxStructure['auction'] }) => {
  const deadlineConfig = React.useMemo(() => {
    return {
      deadline: new Date(auction.endDate),
      referenceDate: auction.status === AuctionStatus.PAUSED && auction.pauseDate
        ? new Date(auction.pauseDate)
        : null,
    };
  }, [auction]);

  const deadline = useDeadline(deadlineConfig);

  return deadline.hasPassed ? (
    null
  ) : (
    <StageDeadlineCountdown
      deadline={deadline}
      isAuction
    />
  );
};

const AuctionStageNavigationMenuItem = ({
  stage,
  name,
  requestInteractivityStatus,
  bidIntentionStatus,
  numComments,
}: {
  stage: AuctionStage;
  name: string;
  requestInteractivityStatus: RequestInteractivityStatus;
  bidIntentionStatus: BidIntentionStatus;
  numComments: number;
}) => {
  const { t } = useTranslation('translation');
  const recipientId = useRecipientId();
  const locale = useCurrentUserLocale();
  const { auction, bidById, sectionById } = rfx.useStructure();
  const currentCompanyGroup = rfx.useCurrentCompanyGroup();
  const navigation = useRequestRecipientNavigation();

  const auctionSection = Object.values(sectionById)
    .find(section => section.type === SectionType.AUCTION_LINE_ITEMS) as RfxAuctionLineItemsSection;

  const bid = bidById[recipientId];

  const hasBeenMovedToStage = bid.enteredStageIds.includes(stage._id);
  const hasActivatedStage = bid.activatedStageIds.includes(stage._id);

  const status = !hasBeenMovedToStage ? (
    StageStatus.PENDING
  ) : auction.status === AuctionStatus.CANCELLED ? (
    StageStatus.CANCELLED
  ) : auction.status === AuctionStatus.PAUSED ? (
    StageStatus.PAUSED
  ) : auction.status === AuctionStatus.ENDED ? (
    StageStatus.ENDED
  ) : auction.status === AuctionStatus.PENDING ? (
    StageStatus.SCHEDULED
  ) : (
    StageStatus.LIVE
  );

  // we're reading this from the stage because structure.auction is
  // only available when the supplier has been moved into the auction
  // stage but in this case (status === 'pending'), we still need to
  // render the start date
  const auctionStartDate = stage.startDate;

  const hasAcceptedBidderAgreement = auction?.bidderIds?.includes(recipientId);

  const secondLineContent = !hasBeenMovedToStage ? (
    <IconText
      icon="lock"
      color="subtext"
      isIconRegular
      text={t(`request.stages.canOpenWhenMoved.${currentCompanyGroup}`)}
    />
  ) : [StageStatus.ENDED, StageStatus.CANCELLED].includes(status) ? (
    null
  ) : !hasActivatedStage ? (
    requestInteractivityStatus === RequestInteractivityStatus.LIVE ? (
      <IconText
        icon="info-circle"
        color="subtext"
        isIconRegular
        text={bidIntentionStatus === BidIntentionStatus.BIDDING ? (
          t(`request.stages.mustClickContinue.${currentCompanyGroup}`)
        ) : (
          t(`request.stages.mustClickStartBidding.${currentCompanyGroup}`)
        )}
      />
    ) : (
      null
    )
  ) : status === StageStatus.SCHEDULED ? (
    hasAcceptedBidderAgreement ? (
      currentCompanyGroup === 'buyer' ? (
        <IconText
          icon="circle-check"
          iconColor="success"
          text={t('request.auction.participate.buyer')}
        />
      ) : (
        <IconText
          icon="circle-check"
          iconColor="success"
          text={auctionSection.auctionRules.preBids ? (
            t('request.auction.youAreReadyPreBid')
          ) : (
            t('request.auction.youAreReady')
          )}
        />
      )
    ) : (
      currentCompanyGroup === 'buyer' ? (
        <IconText
          icon="circle-dashed"
          iconColor="subtext"
          text={t('request.auction.acceptBidderAgreement.buyer')}
        />
      ) : (
        <IconText
          icon="exclamation-circle"
          iconColor="danger"
          text={t('request.auction.acceptBidderAgreement.supplier')}
        />
      )
    )
  ) : (
    currentCompanyGroup === 'buyer' ? (
      <IconText
        icon="circle-check"
        iconColor="success"
        text={t('request.auction.participate.buyer')}
      />
    ) : (
      <IconText
        icon="exclamation-circle"
        iconColor="danger"
        text={t('request.auction.participate.supplier')}
      />
    )
  );

  const body = (
    <>
      <Text>
        <Bold mr={3}>
          <Icon {...iconPropsByStageStatus[status]} mr={1} />
          {t(`request.stages.status.${status}.status`)}
        </Bold>
        {[StageStatus.PENDING, StageStatus.SCHEDULED].includes(status) ? t('request.auction.startsOnDateTime', {
          dateTime: getFormattedDate(new Date(auctionStartDate), DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ, locale),
        }) : [StageStatus.PAUSED, StageStatus.LIVE].includes(status) ? t('request.auction.startedOnDateTime', {
          dateTime: getFormattedDate(new Date(auctionStartDate), DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ, locale),
        }) : status === StageStatus.ENDED ? t('request.auction.endedOnDateTime', {
          dateTime: getFormattedDate(new Date(auction.endDate), DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ, locale),
        }) : status === StageStatus.CANCELLED ? t('request.auction.cancelledOnDateTime', {
          // @ts-expect-error ts(2769) FIXME: No overload matches this call.
          dateTime: getFormattedDate(new Date(auction.cancellationDate), DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ, locale),
        }) : (
          null
        )}
        {[StageStatus.PAUSED, StageStatus.LIVE].includes(status) && (
          <AuctionDeadlineCountdown auction={auction} />
        )}
      </Text>
      {secondLineContent ? (
        <Flex mt="2px">
          {secondLineContent}
          <UnreadComments count={numComments} ml={3} />
        </Flex>
      ) : numComments ? (
        <Text mt="2px">
          <UnreadComments count={numComments} />
        </Text>
      ) : (
        null
      )}
      {currentCompanyGroup === 'buyer' && hasBeenMovedToStage && (
        <Text mt="2px">
          <IconText
            icon="info-circle"
            color="subtext"
            isIconRegular
            text={t('request.auction.buyerInfo')}
          />
        </Text>
      )}
    </>
  );

  return (
    <NavigationMenuItem
      key={stage._id}
      title={<Truncate>{name}</Truncate>}
      body={body}
      linkProps={navigation.getBidLinkProps(stage._id)}
      alignItems="center"
      disabled={!hasBeenMovedToStage}
    />
  );
};

export const RequestRecipientStagesNavigation = () => {
  const { t } = useTranslation('translation');
  const { stages, exchangeDefById, sectionById } = rfx.useStructure();
  const bid = rfx.useBid();
  const requestInteractivityStatus = rfx.useRequestInteractivityStatus();
  const bidIntentionStatus = rfx.useBidIntentionStatus();
  const bidCommentNotifications = useBidCommentNotifications();

  const notificationsByStageId = React.useMemo(() => {
    return groupBy(
      bidCommentNotifications,
      notification => {
        const { exchangeId } = notification.meta;
        const exchangeDef = exchangeDefById[exchangeId];
        if (!exchangeDef) {
          return 'ignore';
        }
        // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
        const section = sectionById[exchangeDef.sectionId];
        if (!section) {
          return 'ignore';
        }

        const exchangeStageId = rfx.getExchangeStageId(exchangeDef, section, bid);

        return exchangeStageId || 'ignore';
      },
    );
  }, [bid, bidCommentNotifications, exchangeDefById, sectionById]);

  return (
    <NavigationMenu heading={t('general.menu')}>
      {stages.map((stage, index) => {
        const numComments = notificationsByStageId[stage._id]?.length || 0;

        return isAuctionStage(stage) ? (
          <AuctionStageNavigationMenuItem
            key={stage._id}
            stage={stage}
            // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
            name={renderStageName(stage, t, index, true)}
            requestInteractivityStatus={requestInteractivityStatus}
            bidIntentionStatus={bidIntentionStatus}
            numComments={numComments}
          />
        ) : (
          <ExchangeStageNavigationMenuItem
            key={stage._id}
            stage={stage}
            // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
            name={renderStageName(stage, t, index, true)}
            requestInteractivityStatus={requestInteractivityStatus}
            bidIntentionStatus={bidIntentionStatus}
            numComments={numComments}
          />
        );
      })}
    </NavigationMenu>
  );
};
