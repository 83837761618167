import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { filter, find } from 'lodash';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Flex, Text } from 'rebass/styled-components';
import { callAll } from '@deepstream/utils/callAll';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { withProps } from '@deepstream/ui-utils';
import { ExchangeSnapshot } from '../../types';
import { useModalState } from '../../ui/useModalState';
import { useRfqId } from '../../useRfq';
import { useExchangeNavigation } from '../../useExchangeModalState';
import * as rfx from '../../rfx';
import { NewChatModal } from './NewChatModal';
import { RequestMessagesGrid } from './RequestMessagesGrid';
import { Bold } from '../../Bold';
import { Disclosure2 } from '../../ui/Disclosure';
import { SortableHeader } from '../../ui/ExchangeDefsGrid/header';
import { TextOrDashCell } from '../../ui/ExchangeDefsGrid/nonValidationAwareValueCell';
import { getExchangeSnapshotStatus } from '../../exchangeStatus';
import { ExchangeStatusValueCell } from '../Request/Live/ExchangeStatusValueCell';
import { NumCommentsValueCell } from '../Request/Live/NumCommentsValueCell';

const NewChatButton = (props) => {
  const { t } = useTranslation('translation');

  return (
    <Button small iconLeft="plus" {...props}>
      {t('request.chats.newChat')}
    </Button>
  );
};

type ChatsProps = {
  exchanges: ExchangeSnapshot[];
  sectionId: string;
};

export const Chats = ({ exchanges, sectionId }: ChatsProps) => {
  const { t } = useTranslation();
  const newChatModal = useModalState();
  const rfqId = useRfqId();
  const { navigateToExchange, openExchange } = useExchangeNavigation();
  const pagePermissions = rfx.usePagePermissions({ required: true });

    // @ts-expect-error ts(2304) FIXME: Cannot find name 'EditableGridColumn'.
    const columns: EditableGridColumn<ExchangeSnapshot>[] = useMemo(
      () => ([
        {
          _id: 'name',
          accessorKey: 'def.name',
          Header: SortableHeader,
          label: t('subject', { ns: 'general' }),
          width: 335,
          ValueCell: withProps(TextOrDashCell, { truncate: true }),
        },
        {
          _id: 'recipientName',
          accessorKey: 'recipientName',
          accessorFn: (exchange: ExchangeSnapshot) => (
            find(exchange.companies, { group: 'recipient' })?.name
          ),
          Header: SortableHeader,
          label: t('supplier', { ns: 'general', count: 1 }),
          width: 253,
          ValueCell: withProps(TextOrDashCell, { truncate: true }),
        },
        {
          _id: 'status',
          accessorKey: 'status',
          accessorFn: (exchange: ExchangeSnapshot) => getExchangeSnapshotStatus(exchange, pagePermissions),
          Header: SortableHeader,
          label: t('general.status'),
          width: 166,
          ValueCell: withProps(ExchangeStatusValueCell, { truncate: true }),
        },
        {
          _id: 'comments',
          accessorKey: 'comments',
          accessorFn: (exchange: ExchangeSnapshot) => filter(exchange.history, 'comment').length,
          Header: SortableHeader,
          label: t('comment', { ns: 'general', count: 2 }),
          width: 141,
          ValueCell: NumCommentsValueCell,
        },
      ]),
      [pagePermissions, t],
    );

  return (
    <>
      <Panel>
        <PanelHeader heading={t('request.chats.chat')} collapse={false} icon="comment-o">
          <NewChatButton onClick={newChatModal.open} disabled={!pagePermissions.canEdit} />
        </PanelHeader>
        <PanelDivider />
        {exchanges.length ? (
          <PanelPadding>
            <GridIdPrefixProvider>
              <GridMenuStateProvider>
                <RequestMessagesGrid data={exchanges} columns={columns} onRowClick={openExchange} />
              </GridMenuStateProvider>
            </GridIdPrefixProvider>
          </PanelPadding>
        ) : (
          <Flex width="100%" height="200px" justifyContent="center" alignItems="center">
            <Bold color="darkGray2">
              {t('request.chats.noChats')}
            </Bold>
          </Flex>
        )}
      </Panel>
      <Text mt={3}>
        <Disclosure2
          width="100%"
          summary={t('request.chats.disclosure.heading')}
        >
          {t('request.chats.disclosure.body.sender')}
        </Disclosure2>
      </Text>
      <NewChatModal
        rfqId={rfqId}
        sectionId={sectionId}
        isOpen={newChatModal.isOpen}
        onCancel={newChatModal.close}
        onSuccess={callAll(
          newChatModal.close,
          navigateToExchange,
        )}
      />
    </>
  );
};
