import { AuctionConfig } from '../../draft/AuctionConfig';
import { DraftHeader } from './DraftHeader';
import * as Layout from '../../Layout';

export const DraftAuctionConfigPageContent = ({
  rfqId,
  isRevising,
  isTemplate,
}) => {
  const tabId = 'auction';

  return (
    <Layout.HeaderAndContent
      header={
        <DraftHeader
          rfqId={rfqId}
          isRevising={isRevising}
          isTemplate={isTemplate}
          selectedTabId={tabId}
        />
      }
      content={
        <AuctionConfig
          isRevising={isRevising}
          isTemplate={isTemplate}
        />
      }
      contentPaddingBottom={Layout.BOTTOM_TOOLBAR_CONTENT_PADDING_BOTTOM}
    />
  );
};
