import { useMemo, useState } from 'react';
import { LockType } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';
import { useField } from 'formik';
import { compact, isEqual } from 'lodash';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { RadioFieldBase } from './form/RadioField';
import { SelectFieldBase } from './form/SelectField';
import { LabelConfig, LabelConfigProvider } from './LabelConfigProvider';

export const isTeamMemberLockType = (lockType: LockType) =>
  [
    LockType.TEAM_MEMBER,
    LockType.TEAM_MEMBER_AFTER_STAGE_DEADLINE,
    LockType.TEAM_MEMBER_AFTER_BID_DEADLINE,
  ].includes(lockType);

type UnlockCriteria = 'manual' | 'stage-deadline' | 'bid-deadline';
type ManualUnlockConstraint = 'none' | 'stage-deadline' | 'bid-deadline';

const fromLockType = (lockType: LockType): { unlockCriteria: UnlockCriteria; manualUnlockConstraint?: ManualUnlockConstraint } => {
  switch (lockType) {
    case LockType.BID_DEADLINE:
      return { unlockCriteria: 'bid-deadline' };
    case LockType.STAGE_DEADLINE:
      return { unlockCriteria: 'stage-deadline' };
    case LockType.TEAM_MEMBER:
      return { unlockCriteria: 'manual', manualUnlockConstraint: 'none' };
    case LockType.TEAM_MEMBER_AFTER_BID_DEADLINE:
      return { unlockCriteria: 'manual', manualUnlockConstraint: 'bid-deadline' };
    case LockType.TEAM_MEMBER_AFTER_STAGE_DEADLINE:
      return { unlockCriteria: 'manual', manualUnlockConstraint: 'stage-deadline' };
    default:
      throw new Error('Unknown lock type');
  }
};

const toLockType = (unlockCriteria: UnlockCriteria, manualUnlockConstraint: ManualUnlockConstraint) => {
  switch (unlockCriteria) {
    case 'bid-deadline':
      return LockType.BID_DEADLINE;
    case 'stage-deadline':
      return LockType.STAGE_DEADLINE;
    case 'manual':
      switch (manualUnlockConstraint) {
        case 'none':
          return LockType.TEAM_MEMBER;
        case 'stage-deadline':
          return LockType.TEAM_MEMBER_AFTER_STAGE_DEADLINE;
        case 'bid-deadline':
          return LockType.TEAM_MEMBER_AFTER_BID_DEADLINE;
      }
  }
};

/**
 * Applies styles to label for a "conversational" label (ie: label that appears
 * inline  with the corresponding input with the baselines aligned)
 */
const ConversationalLabelConfig = ({ name, children }) => (
  <LabelConfigProvider
    variant={LabelConfig.LEFT}
    width="auto"
    style={{
      [name]: {
        fontSize: 2,
        fontWeight: 'normal',
        transform: 'translateY(9px)',
        color: 'text',
        marginLeft: 24,
        marginRight: 1,
      },
    }}
  >
    {children}
  </LabelConfigProvider>
);

const UnlockCriteriaRadioGroup = ({
  value,
  disabled,
  onChange,
  showDeprecatedOptions,
}: {
  value: UnlockCriteria;
  disabled?: boolean;
  showDeprecatedOptions: boolean;
  onChange: (value: UnlockCriteria) => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const options = useMemo(
    () => compact([
      {
        label: showDeprecatedOptions ? t('request.lock.automaticStageDeadline') : t('request.lock.automatic'),
        value: 'stage-deadline',
        description: t('request.lock.automaticStageDeadlineDescription'),
        descriptionTestAttribute: 'unlock-by-stage-deadline-description',
      },
      showDeprecatedOptions && {
        label: t('request.lock.automaticFinalDeadline'),
        value: 'bid-deadline',
        description: t('request.lock.automaticFinalDeadlineDescription'),
      },
      {
        label: t('request.lock.manual'),
        value: 'manual',
        description: t('request.lock.manualDescription'),
      },
    ]) as Array<{
      label: string;
      value: UnlockCriteria;
      description: string
    }>,
    [t, showDeprecatedOptions],
  );

  return (
    <RadioFieldBase
      required
      label={t('request.lock.unlockCriteria')}
      value={value}
      disabled={disabled}
      options={options}
      onChange={onChange}
      gap={1}
      // override global CSS styling of <label> in the Angular client
      labelStyle={{ fontWeight: 'normal', color: theme.colors.text }}
      showError
    />
  );
};

const ManualUnlockConstraintSelect = ({
  value,
  disabled,
  showDeprecatedOptions,
  onChange,
}: {
  value: ManualUnlockConstraint;
  disabled?: boolean;
  showDeprecatedOptions: boolean;
  onChange: (value: ManualUnlockConstraint) => void;
}) => {
  const { t } = useTranslation();

  const items = useMemo(
    () => compact([
      { label: t('request.lock.atAnyTime'), value: 'none' },
      { label: t('request.lock.whenStageDeadlineHasPassed'), value: 'stage-deadline' },
      showDeprecatedOptions
        ? { label: t('request.lock.whenFinalDeadlineHasPassed'), value: 'bid-deadline' }
        : null,
    ]) as Array<{
      label: string;
      value: ManualUnlockConstraint;
    }>,
    [t, showDeprecatedOptions],
  );

  return (
    <ConversationalLabelConfig name="manualUnlockConstraint">
      <SelectFieldBase
        name="manualUnlockConstraint"
        label={t('request.lock.teamMemberCanUnlock')}
        items={items}
        value={value}
        disabled={disabled}
        onChange={onChange}
        buttonStyle={{ width: 250 }}
      />
    </ConversationalLabelConfig>
  );
};

/**
 * A formik-connected field component for selecting a `LockType`. The emitted value is derived
 * from on a combination of inputs (radio + select).
 */
export const LockTypeField = ({ name, disabled }: { name: string; disabled?: boolean }) => {
  const [{ value: lockType },, formik] = useField(name);

  // This must be set with useState, because the initial lockType will determine whether
  // show the deprecated options while the modal is opened (ie: we don't want the deprecated
  // options to disappear when the user selects a non-deprecated lock type).
  const [showDeprecatedOptions] = useState(
    [LockType.BID_DEADLINE, LockType.TEAM_MEMBER_AFTER_BID_DEADLINE].includes(lockType),
  );

  // Derive and set the initial input values from the lock type
  const initial = fromLockType(lockType);
  const [unlockCriteria, setUnlockCriteria] = useState<UnlockCriteria>(initial.unlockCriteria);
  // @ts-expect-error ts(2345) FIXME: Argument of type 'ManualUnlockConstraint | undefined' is not assignable to parameter of type 'ManualUnlockConstraint | (() => ManualUnlockConstraint)'.
  const [manualUnlockConstraint, setManualUnlockConstraint] = useState<ManualUnlockConstraint>(initial.manualUnlockConstraint);

  useWatchValue(
    lockType,
    (next) => {
      const { unlockCriteria, manualUnlockConstraint } = fromLockType(next);
      setUnlockCriteria(unlockCriteria);
      // @ts-expect-error ts(2345) FIXME: Argument of type 'ManualUnlockConstraint | undefined' is not assignable to parameter of type 'SetStateAction<ManualUnlockConstraint>'.
      setManualUnlockConstraint(manualUnlockConstraint);
    },
  );

  useWatchValue(
    { unlockCriteria, manualUnlockConstraint },
    ({ unlockCriteria, manualUnlockConstraint }) => formik.setValue(toLockType(unlockCriteria, manualUnlockConstraint)),
    isEqual,
  );

  return (
    <>
      <UnlockCriteriaRadioGroup
        value={unlockCriteria}
        disabled={disabled}
        showDeprecatedOptions={showDeprecatedOptions}
        onChange={(unlockCriteria: UnlockCriteria) => {
          setUnlockCriteria(unlockCriteria);

          if (unlockCriteria === 'manual') {
            setManualUnlockConstraint('none');
          }
        }}
      />
      {unlockCriteria === 'manual' && (
        <Box mt={2}>
          <ManualUnlockConstraintSelect
            value={manualUnlockConstraint}
            disabled={disabled}
            showDeprecatedOptions={showDeprecatedOptions}
            onChange={manualUnlockConstraint => setManualUnlockConstraint(manualUnlockConstraint)}
          />
        </Box>
      )}
    </>
  );
};
