import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Illustration } from '@deepstream/ui-kit';
import { useApi } from '../../../api';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { QuestionnaireTemplatesTableData, QuestionnaireTemplatesTableDataProvider } from '../QuestionnaireTemplate/QuestionnaireTemplatesTableDataProvider';
import { useCurrentUser } from '../../../useCurrentUser';
import { useLocalStorageState } from '../../../useLocalStorageState';
import { useTableData } from '../../../TableDataContext';
import { LiveQuestionnaireTemplatesTable } from '../QuestionnaireTemplate/LiveQuestionnaireTemplatesTable';
import { Loading } from '../../../ui/Loading';
import { ErrorMessage } from '../../../ui/ErrorMessage';
import { MIN_CELL_HEIGHT } from '../../../FieldsCell';
import { useModalState } from '../../../ui/useModalState';
import { RestoreQuestionnaireTemplateDialog } from '../QuestionnaireTemplate/RestoreQuestionnaireTemplateDialog';

const ArchiveTemplatesContent = () => {
  const { t } = useTranslation('preQualification');
  const restoreTemplateModal = useModalState();
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);

  const {
    query,
    pageSize,
  } = useTableData<QuestionnaireTemplatesTableData>({ required: true });

  const { status, data } = query;
  const {
    totalItems = 0,
  } = data ?? {};

  const openRestoreModal = useCallback(
    (templateId) => {
      setSelectedTemplateId(templateId);
      restoreTemplateModal.open();
    },
    [restoreTemplateModal],
  );

  return (
    <Panel>
      <PanelHeader
        heading={t('archivedQuestionnaires')}
        sx={{ height: '60px', alignItems: 'center' }}
      />
      <PanelDivider />
      {status === 'success' && totalItems > 0 ? (
        <LiveQuestionnaireTemplatesTable isArchive openRestoreModal={openRestoreModal} />
      ) : (
        <Flex
          height={status === 'loading' ? MIN_CELL_HEIGHT * pageSize : '455px'}
          flexDirection="column"
          justifyContent="center"
        >
          <PanelPadding>
            {status === 'loading' ? (
              <Loading fontSize={4} fontWeight={400} />
            ) : status === 'error' ? (
              <ErrorMessage fontSize={3} error={t('errors.getArchivedQuestionnaireTemplates')} />
            ) : (
              <Illustration
                variant="empty-state"
                label={t('questionnaireTemplate.companyHasNotArchivedTemplates')}
              />
            )}
          </PanelPadding>
        </Flex>
      )}
      {selectedTemplateId && (
        <RestoreQuestionnaireTemplateDialog
          {...restoreTemplateModal}
          templateId={selectedTemplateId}
          onSuccess={restoreTemplateModal.close}
          onError={restoreTemplateModal.close}
        />
      )}
    </Panel>
  );
};

export const ArchiveQuestionnaireTemplates = () => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();

  const pageSizeState = useLocalStorageState<number>({
    key: `${currentCompanyId}.${user._id}.archiveQuestionnaireTemplates.pageSize`,
    defaultValue: 10,
  });

  return (
    <QuestionnaireTemplatesTableDataProvider
      queryTag="archiveQuestionnaireTemplates"
      queryFn={api.getArchivedQuestionnaireTemplates}
      pageSizeState={pageSizeState}
    >
      <ArchiveTemplatesContent />
    </QuestionnaireTemplatesTableDataProvider>
  );
};
