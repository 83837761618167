import { get, isEqual } from 'lodash';
import { ContractDocumentExchangeDefinition } from './contract';
import { Field, FieldType } from './exchangesConfig';
import { ExchangeDefinition, DefinitionFieldConfig } from './rfq-utils';

export const checkFieldValuesEqual = (type: FieldType, current: any, previous: any) => {
  switch (type) {
    case FieldType.STRING:
    case FieldType.NUMBER:
    case FieldType.PRICE:
    case FieldType.DATE:
    case FieldType.BOOLEAN:
    case FieldType.UNSPSC_CODE:
      return current === previous;
    case FieldType.ATTACHMENT:
      return current?._id === previous?._id;
    case FieldType.ARRAY:
    case FieldType.ADDRESS:
    case FieldType.OBJECT:
      return isEqual(current, previous);
    default:
      throw new Error(`The provided type ${type} does not have an associated equality check.`);
  }
};

/**
 * This only works for definition fields
 */
export const areFieldValuesEqual = (
  previous: ExchangeDefinition | ContractDocumentExchangeDefinition | undefined,
  current: ExchangeDefinition | ContractDocumentExchangeDefinition | undefined,
  field: Field | DefinitionFieldConfig,
) => {
  const fieldKey = (field as Field).key ?? (field as DefinitionFieldConfig).source.key;
  const previousValue = get(previous, fieldKey);
  const currentValue = get(current, fieldKey);

  return checkFieldValuesEqual(
    field.type as unknown as FieldType,
    previousValue,
    currentValue,
  );
};
