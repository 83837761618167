import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Formik, useFormikContext } from 'formik';
import { useQueryClient } from 'react-query';
import { map } from 'lodash';
import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { AsyncActionDialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Box } from 'rebass/styled-components';
import { QUESTIONS_PAGE_ID } from '@deepstream/common/preQual';
import { PageRole } from '@deepstream/common/rfq-utils';
import { PageFooter } from '../../../../PageFooter';
import { QuestionnaireTemplatesPanel } from './QuestionnaireTemplatesPanel';
import { useCurrentUser } from '../../../../useCurrentUser';
import { TeamMembersPanel } from './TeamMembersPanel';
import { SuppliersPanel } from './SuppliersPanel';
import { ReviewModal } from './ReviewModal';
import { useModalState } from '../../../../ui/useModalState';
import { useMutation } from '../../../../useMutation';
import { useApi } from '../../../../api';
import { useToaster } from '../../../../toast';
import { useCurrentCompanyId } from '../../../../currentCompanyId';
import { FormValues, getSelectedSupplierUsers } from './utils';
import { usePreQualNavigation } from '../../../../AppRouting';
import { useActiveQuestionnaireTemplateNavigation } from '../../../../appNavigation';
import { PreQualTab } from '../../utils';
import { useUserFlags } from '../../../../UserFlagsContext';
import { RequestRole } from '../../../../types';

const mapFormValuesToPayload = ({ templates, teamMembers, suppliers }: FormValues) => ({
  questionnaireTemplateIds: map(templates, template => template._id),
  senderTeamMembers: teamMembers.map((teamMember) => ({
    _id: teamMember._id,
    name: teamMember.name,
    email: teamMember.email,
    isOwner: teamMember.role === RequestRole.OWNER,
    roles: teamMember.pagesPermissions,
  })),
  recipients: suppliers.map((supplier) => ({
    _id: supplier.company._id,
    userIds: map(
      getSelectedSupplierUsers(supplier),
      // @ts-expect-error ts(2339) FIXME: Property '_id' does not exist on type 'number | SupplierStateUser | (() => IterableIterator<SupplierStateUser>) | { [x: number]: boolean | undefined; length?: boolean | undefined; ... 33 more ...; readonly [Symbol.unscopables]?: boolean | undefined; } | ... 31 more ... | ((index: number) => SupplierStateUser | undefined)'.
      (user) => user._id,
    ),
  })),
});

const Footer = ({ onCancel, onSend }: { onCancel: () => void; onSend: () => void }) => {
  const { t } = useTranslation(['preQualification', 'general']);
  const { isValid } = useFormikContext();
  const { hasSendQuestionnairePermission } = useUserFlags();

  return (
    <PageFooter>
      {/* empty box to ensure right aligned buttons */}
      <Box />
      <Box>
        <Button
          type="button"
          variant="secondary"
          onClick={onCancel}
          mr={2}
        >
          {t('cancel', { ns: 'general' })}
        </Button>
        <Button
          variant="success"
          iconRight="arrow-right"
          onClick={onSend}
          disabled={!isValid || !hasSendQuestionnairePermission}
        >
          {t('sendQuestionnaires')}
        </Button>
      </Box>
    </PageFooter>
  );
};

export const SendQuestionnaire = ({ from, templateIds }: { from?: string, templateIds?: string[] }) => {
  const { t } = useTranslation(['preQualification', 'general']);
  const currentUser = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const reviewModal = useModalState();
  const questionnairesSentDialog = useModalState();
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const preQualNavigation = usePreQualNavigation();
  const activeTemplateNavigation = useActiveQuestionnaireTemplateNavigation();

  const [sendQuestionnaires] = useMutation(
    api.sendQuestionnaires,
    {
      onSuccess: callAll(
        reviewModal.close,
        () => toaster.success(t('toaster.questionnairesSent.success')),
        questionnairesSentDialog.open,
      ),
      onError: () => toaster.error(t('toaster.questionnairesSent.failed')),
      onSettled: callAll(
        () => queryClient.invalidateQueries(['sentQuestionnaires']),
      ),
    },
  );

  const goBack = useCallback(
    () => {
      if (from === 'template') {
        // @ts-expect-error ts(18048) FIXME: 'templateIds' is possibly 'undefined'.
        activeTemplateNavigation.navigateToSuppliers(templateIds[0]);
      } else {
        preQualNavigation.navigateToPreQualificationQuestionnaires(PreQualTab.ACTIVE_QUESTIONNAIRES);
      }
    },
    [activeTemplateNavigation, from, preQualNavigation, templateIds],
  );

  const handleDone = useCallback(
    () => {
      questionnairesSentDialog.close();
      goBack();
    },
    [questionnairesSentDialog, goBack],
  );

  const initialTeamMember = useMemo(
    () => ({
      _id: currentUser._id,
      name: currentUser.name,
      email: currentUser.email,
      role: RequestRole.OWNER,
      // @ts-expect-error ts(18048) FIXME: 'currentUser.roleRestrictionProfiles' is possibly 'undefined'.
      roleRestrictionProfile: currentUser.roleRestrictionProfiles[currentCompanyId],
      pagesPermissions: {
        [QUESTIONS_PAGE_ID]: PageRole.RESPONDER,
      },
    }),
    [currentCompanyId, currentUser],
  );

  return (
    <Formik<FormValues>
      initialValues={{
        templates: [],
        teamMembers: [initialTeamMember],
        suppliers: [],
      }}
      validationSchema={
        yup.object().shape({
          templates: yup.array().of(
            yup.object().shape({
              _id: yup.string().required(),
              name: yup.string().required(),
            }),
          ).min(1),
          teamMembers: yup.array().of(yup.object()).min(1),
          suppliers: yup.array().of(yup.object().shape({
            company: yup.object(),
            users: yup.array().of(yup.object()).min(1),
          })).min(1),
        })
      }
      onSubmit={async (values) => {
        await sendQuestionnaires({
          currentCompanyId,
          payload: mapFormValuesToPayload(values),
        });
      }}
    >
      <>
        <Stack mb="73px" gap="24px">
          <QuestionnaireTemplatesPanel initialTemplateIds={templateIds} />
          <TeamMembersPanel />
          <SuppliersPanel />
        </Stack>
        <Footer
          onCancel={goBack}
          onSend={reviewModal.open}
        />
        {reviewModal.isOpen && (
          <ReviewModal isOpen onCancel={reviewModal.close} />
        )}
        <AsyncActionDialog
          heading={t('questionnaire.questionnairesSentDialog.header')}
          message={t('questionnaire.questionnairesSentDialog.body')}
          onOk={handleDone}
          isOpen={questionnairesSentDialog.isOpen}
        />
      </>
    </Formik>
  );
};
