import { useMemo } from 'react';
import { get, isNil, sortBy } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { getFieldIdsInDefaultDisplayOrder, type FieldConfig, type LineItemExchangeDefinition } from '@deepstream/common/rfq-utils';
import { DynamicFieldData, getDynamicFieldsRevisionChangesTableData } from '@deepstream/common/rfq-utils/exchangeAction';
import { DateFormat, localeFormatNumber } from '@deepstream/utils';
import { ExchangeSnapshot } from '../types';
import { getFormattedDate, momentToDateFnsDateFormat } from '../Datetime';
import { useCurrentUserLocale } from '../useCurrentUser';
import { useSession } from '../auth';
import { useHooks } from '../useHooks';

const InlineHighlightedText = ({ children }: any) => <Text fontSize={1} sx={{ display: 'inline' }} color="text">{children}</Text>;

const Change = (props: DynamicFieldData) => {
  const {
    type,
    label,
    hasAddedField,
    hasRemovedField,
    previousValue,
    newValue,
    previousLabel,
    newLabel,
  } = props;
  const locale = useCurrentUserLocale();
  const { user } = useSession();
  // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const dateFormat = momentToDateFnsDateFormat[get(user, 'preferences.dateFormat')] || DateFormat.DD_MM_YYYY_SLASH;
  const isFieldValueChanged = ('previousValue' in props && 'newValue' in props) || ('previousDecimalPlaces' in props && 'newDecimalPlaces' in props);
  const isLabelChanged = 'previousLabel' in props && 'newLabel' in props;
  const isFieldRemoved = hasRemovedField;
  const isFieldAddedWithoutValue = hasAddedField && !('newValue' in props);
  const isFieldAddedWithValue = hasAddedField && 'newValue' in props;

  const getValue = (value: any) => isNil(value) || value === '' ? (
    '—'
  ) : (type === 'date') ? (
    getFormattedDate(value, dateFormat, locale)
  ) : (type === 'price' && (!('source' in props) || props.source.type !== 'formula')) ? (
    // TODO this should include the currency
    localeFormatNumber(value, { locale, decimalPlaces: 2 })
  ) : (
    value
  );

  return (
    <Box mt={1}>
      {isFieldAddedWithoutValue && (
        <Text fontSize={1} lineHeight="normal" color="subtext">
          <Trans
            i18nKey="request.exchange.fieldAddedWithoutValue"
            values={{ field: label }}
            components={{ b: <InlineHighlightedText /> }}
          />
        </Text>
      )}
      {isFieldAddedWithValue && (
        <Text fontSize={1} lineHeight="normal" color="subtext">
          <Trans
            i18nKey="request.exchange.fieldAddedWithValue"
            values={{ field: label, value: getValue(newValue) }}
            components={{ b: <InlineHighlightedText /> }}
          />
        </Text>
      )}
      {isLabelChanged && (
        <Text fontSize={1} lineHeight="normal" color="subtext">
          <Trans
            i18nKey="request.exchange.labelChanged"
            values={{ previousLabel, newLabel }}
            components={{ b: <InlineHighlightedText /> }}
          />
        </Text>
      )}
      {isFieldValueChanged && (
        <Text fontSize={1} lineHeight="normal" color="subtext">
          <Trans
            i18nKey="request.exchange.fieldValueChanged"
            values={{ field: label, previousValue: getValue(previousValue), newValue: getValue(newValue) }}
            components={{ b: <InlineHighlightedText /> }}
          />
        </Text>
      )}
      {isFieldRemoved && (
        <Text fontSize={1} lineHeight="normal" color="subtext">
          <Trans
            i18nKey="request.exchange.fieldWasRemoved"
            values={{ field: label }}
            components={{ b: <InlineHighlightedText /> }}
          />
        </Text>
      )}
    </Box>
  );
};

export const DynamicFieldsRevisionChangesTable = ({
  action,
  exchange,
  changedFields,
}: {
  action: any;
  exchange: ExchangeSnapshot;
  changedFields: FieldConfig[];
}) => {
  const { t } = useTranslation();
  const { useExchangeDefById } = useHooks();
  const exchangeDefById = useExchangeDefById();

  const data: DynamicFieldData[] = useMemo(() => {
    const exchangeDef = exchangeDefById[exchange._id] as LineItemExchangeDefinition;
    const orderedFieldIds = (
      exchangeDef.orderedFieldIds ||
      getFieldIdsInDefaultDisplayOrder(Object.keys(exchangeDef.fields))
    );

    const sortedFields = sortBy(
      changedFields,
      field => orderedFieldIds.indexOf(field._id),
    );

    return getDynamicFieldsRevisionChangesTableData(action, exchange, sortedFields, t);
  }, [action, changedFields, exchange, exchangeDefById, t]);

  return (
    <Box>
      {data.map((props, index) => (
        <Change {...props} isFirst={index === 0} key={String(index)} />
      ))}
    </Box>
  );
};
