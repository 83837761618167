import { useMemo } from 'react';
import { PageType, ActionType, SectionType, StageType, AuctionStatus } from '@deepstream/common/rfq-utils';
import { find, conforms, overEvery, matches, overSome, first, some, compact } from 'lodash';
import { useTranslation } from 'react-i18next';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils';
import { useCurrentCompanyId } from '../currentCompanyId';
import * as rfx from '../rfx';
import { useRfqExchange, useRfqId } from '../useRfq';
import { PageHeaderTabs, HeaderTab } from './PageHeaderTabs';
import { getExchangeIdsForPage } from './BidPageSenderTabs';
import { useRequestRecipientNavigation } from '../appNavigation';

const bulletinNotificationActions = [
  NotificationAction.BULLETIN_QUESTION_POSTED,
  NotificationAction.BULLETIN_SIMPLE_CLARIFICATION,
  NotificationAction.BULLETIN_ANSWER_TO_QUESTION_POSTED,
];

/**
 * Tabs when viewing the bid page as a recipient
 */
export const BidPageRecipientTabs = ({
  selectedTabId,
}: {
  selectedTabId?: string;
}) => {
  const { t } = useTranslation();
  const rfqId = useRfqId({ required: true });
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { pages, sectionById, stages, auction } = rfx.useStructure();
  const visiblePages = rfx.useVisiblePages();
  const bid = rfx.useBid();
  const auctionTermsExchangeDef = rfx.useAuctionTermsExchangeDef();
  const navigation = useRequestRecipientNavigation();

  const hasAccessToAuction = some(visiblePages, page => page.type === PageType.AUCTION);

  const isAtFirstStage = bid.stageId === first(bid.enteredStageIds);
  const hasActivatedAnyStage = bid.activatedStageIds.length > 0;

  const showAllTabs = !isAtFirstStage || hasActivatedAnyStage;

  const isAuctionTabVisible = useMemo(
    () => {
      const auctionStage = find(stages, { type: StageType.AUCTION });

      return auctionStage && bid.activatedStageIds.includes(auctionStage._id);
    },
    [stages, bid],
  );

  const { data: auctionTermsExchange, isSuccess } = useRfqExchange({
    exchangeId: auctionTermsExchangeDef?._id,
    recipientId: currentCompanyId,
    enabled: isAuctionTabVisible && hasAccessToAuction,
  });

  const hasToAcceptBidderAgreement = useMemo(
    () => isSuccess && !auctionTermsExchange.isResolved &&
      ![AuctionStatus.CANCELLED, AuctionStatus.ENDED].includes(auction?.status),
    [auction, isSuccess, auctionTermsExchange],
  );

  const messageNotificationsFilter = useMemo(
    () => {
      const chatPage = find(pages, { type: PageType.CHAT });

      // Very old requests don't have a chat page
      if (!chatPage) return {};

      const exchangeIds = getExchangeIdsForPage(chatPage, bid);

      return overEvery([
        matches({
          domain: NotificationDomain.RFQ_RECEIVED,
          to: { companyId: currentCompanyId },
          meta: { rfqId },
        }),
        overSome([
          conforms({
            action: action => action === NotificationAction.EXCHANGE_REPLY_SENT,
            meta: meta => meta.actionType === ActionType.NONE && exchangeIds.includes(meta.exchangeId),
          }),
          conforms({
            action: action => [NotificationAction.REQUEST_BULLETIN_ADDED, NotificationAction.REQUEST_BULLETIN_UPDATED].includes(action),
          }),
        ]),
      ]);
    },
    [pages, bid, currentCompanyId, rfqId],
  );

  const auctionNotificationsFilter = useMemo(
    () => {
      const auctionPage = find(pages, { type: PageType.AUCTION });

      if (!auctionPage) return {};

      return {
        domain: NotificationDomain.RFQ_RECEIVED,
        to: { companyId: currentCompanyId },
        action: NotificationAction.EXCHANGE_REPLY_SENT,
        meta: {
          rfqId,
          actionType: ActionType.NONE,
          pageId: auctionPage._id,
        },
      };
    },
    [pages, currentCompanyId, rfqId],
  );

  const hasBulletinSection = some(sectionById, { type: SectionType.BULLETINS });

  const messagesPageId = find(pages, { type: PageType.CHAT })?._id;

  const detailsTab: HeaderTab = useMemo(
    () => ({
      id: 'details',
      name: t('request.pageName.details'),
      navigate: () => navigation.navigateToDetails(),
    }),
    [navigation, t],
  );

  const messagesTab: HeaderTab = useMemo(
    () => ({
      id: 'messages',
      name: t('request.pageName.messages'),
      navigate: () => navigation.navigateToBid(null, null, messagesPageId),
      hide: !messagesPageId,
      notificationFilter: messageNotificationsFilter,
    }),
    [t, messageNotificationsFilter, navigation, messagesPageId],
  );

  const bulletinTab: HeaderTab = useMemo(
    () => ({
      id: 'bulletin',
      name: t('request.pageName.bulletin'),
      notificationFilter: bulletinNotificationActions.map(action => ({
        domain: NotificationDomain.RFQ_RECEIVED,
        action,
        meta: { rfqId },
        to: { companyId: currentCompanyId },
      })),
      hide: hasBulletinSection,
      navigate: () => navigation.navigateToLegacyBulletins(),
    }),
    [currentCompanyId, hasBulletinSection, navigation, rfqId, t],
  );

  const teamTab: HeaderTab = useMemo(
    () => ({
      id: 'team',
      name: t('request.pageName.team'),
      navigate: () => navigation.navigateToTeam(),
    }),
    [navigation, t],
  );

  const auditTab: HeaderTab = useMemo(
    () => ({
      id: 'history',
      name: t('request.pageName.history'),
      navigate: () => navigation.navigateToAudit(),
    }),
    [navigation, t],
  );

  const auctionTab: HeaderTab = useMemo(
    () => ({
      id: 'auction',
      name: t('request.pageName.auction'),
      notificationFilter: auctionNotificationsFilter,
      iconRight: hasToAcceptBidderAgreement
        ? 'exclamation-circle'
        : undefined,
      iconProps: { color: 'danger' },
      navigate: () => navigation.navigateToAuction(),
    }),
    [t, auctionNotificationsFilter, hasToAcceptBidderAgreement, navigation],
  );

  const tabs: HeaderTab[] = useMemo(
    () => showAllTabs ? (
      compact([
        {
          id: 'bid',
          name: t('request.pageName.bid'),
          navigate: () => navigation.navigateToBid(),
        },
        isAuctionTabVisible
          ? auctionTab
          : null,
        messagesTab,
        bulletinTab,
        teamTab,
        detailsTab,
        auditTab,
      ])
    ) : [
      detailsTab,
      messagesTab,
      bulletinTab,
      teamTab,
      auditTab,
    ],
    [showAllTabs, t, isAuctionTabVisible, auctionTab, messagesTab, bulletinTab, teamTab, detailsTab, auditTab, navigation],
  );

  return (
    <PageHeaderTabs tabs={tabs} selectedTabId={selectedTabId} />
  );
};
