import { Trans } from 'react-i18next';
import { Box } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Bold } from '../../../Bold';
import { useBuyerActionRequiredCount } from './useBuyerActionRequiredCount';

export const BuyerActionsRequiredIconAndText = ({ stageId, requirementGroupId }: { stageId?: string; requirementGroupId?: string }) => {
  const buyerActionRequiredCount = useBuyerActionRequiredCount(stageId, requirementGroupId);

  return buyerActionRequiredCount ? (
    <>
      <Icon icon="exclamation-circle" color="danger" />
      {buyerActionRequiredCount && (
        <Box as="span" ml={1}>
          <Trans
            i18nKey="request.actionRequiredCount"
            ns="translation"
            components={{
              b: <Bold />,
            }}
            count={buyerActionRequiredCount}
          />
        </Box>
      )}
    </>
  ) : (
    null
  );
};
