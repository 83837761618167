import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Link } from '@tanstack/react-router';
import { FeedbackContext, FeedbackEntityType, RatingType } from '@deepstream/common';
import { stopEvent } from '@deepstream/ui-utils/domEvent';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { useRequestSentNavigation } from '../../../../appNavigation';
import { FivePointScaleFeedbackForm } from '../../Sent/FivePointScaleFeedbackForm';
import { useApi } from '../../../../api';
import { useMutation } from '../../../../useMutation';
import { useRfqId } from '../../../../useRfq';
import { useCurrentCompanyId } from '../../../../currentCompanyId';

const HEADER_HEIGHT_AND_PADDING = 140 + 20;
const FEEDBACK_SECTION_ID = 'requestAwardFeedback';

export const SubmittedSuccessfullyStep = () => {
  const { t } = useTranslation(['translation', 'request']);
  const { navigateToSuppliers, getSuppliersLinkProps } = useRequestSentNavigation();
  const api = useApi();
  const [createFeedback] = useMutation(api.createUserFeedback);
  const [updateFeedback, { isSuccess, isError }] = useMutation(
    api.updateUserFeedback,
  );
  const rfxId = useRfqId();
  const companyId = useCurrentCompanyId({ required: true });
  const [userFeedbackId, setUserFeedbackId] = useState();

  useEffect(() => {
    if (isSuccess) {
      navigateToSuppliers();
    }
  }, [isSuccess, navigateToSuppliers]);

  // A feedback request is registered when this section of the page loads for the first time
  useEffect(() => {
    createFeedback({
      companyId,
      entityType: FeedbackEntityType.RFX,
      feedbackContext: FeedbackContext.AWARD_FLOW_BUYER_FEEDBACK,
      meta: {
        rfxId,
      },
    }, {
      onSuccess: setUserFeedbackId,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <lotPagesLayout.ContentWrapper>
      <lotPagesLayout.Section display="flex" flexDirection="column" sx={{ rowGap: '20px' }}>
        <lotPagesLayout.H2Success>
          {t('request.awardFlow.steps.submittedSuccessfully.heading')}
        </lotPagesLayout.H2Success>
        <Box>
          <Text mb="6px">
            {t('request.awardFlow.steps.submittedSuccessfully.info1')}
          </Text>
          <Text>
            <lotPagesLayout.TanstackInlineLink
              href={`${FEEDBACK_SECTION_ID}`}
              onClick={(event) => {
                const element = document.getElementById(FEEDBACK_SECTION_ID);
                if (element) {
                  const y = element.getBoundingClientRect().top + window.scrollY;
                  const rootElement = document.getElementById('root');
                  rootElement!.scroll({
                    top: y - HEADER_HEIGHT_AND_PADDING,
                    behavior: 'smooth',
                  });
                }
                stopEvent(event);
              }}
            >
              {t('request.awardFlow.steps.submittedSuccessfully.info2')}
            </lotPagesLayout.TanstackInlineLink>
            <span>
              {' '}
              {t('request.awardFlow.steps.submittedSuccessfully.info3')}
            </span>
          </Text>
        </Box>
        <Box mb="20px">
          <Link
            {...getSuppliersLinkProps()}
            tabIndex={-1}
          >
            <Button
              type="button"
              onClick={() => {}}
            >
              {t('request.awardFlow.exitProcess')}
            </Button>
          </Link>
        </Box>
        <lotPagesLayout.Subsection id={FEEDBACK_SECTION_ID}>
          <FivePointScaleFeedbackForm
            titleText={t('request.awardFlow.steps.submittedSuccessfully.info2')}
            questionText={t(
              'request.awardFlow.steps.submittedSuccessfully.feedbackQuestion',
            )}
            labels={{
              additionalCommentsTitle: t(
                'request.awardFlow.steps.submittedSuccessfully.feedbackAditionalCommmentsTitle',
              ),
              noFeedbackSelectedError: t(
                'request.awardFlow.steps.submittedSuccessfully.noFeedbackSelectedError',
              ),
              submitButton: t(
                'request.awardFlow.steps.submittedSuccessfully.submitButtonText',
              ),
            }}
            isError={isError}
            onSubmit={async ({ satisfactionRating, additionalComments }) =>
              updateFeedback({
                // @ts-expect-error ts(2322) FIXME: Type 'undefined' is not assignable to type 'string'.
                userFeedbackId,
                ratingType: RatingType.FIVE_POINT_LIKERT_SCALE,
                ratingScore: satisfactionRating,
                comments: additionalComments,
              }, {
                onSuccess: setUserFeedbackId,
              })
            }
            rowGap="20px"
          />
        </lotPagesLayout.Subsection>
      </lotPagesLayout.Section>
    </lotPagesLayout.ContentWrapper>
  );
};
