import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { Form, Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { RfxSpendAndSavings, TotalSavingsCalculationMethod } from '@deepstream/common/rfq-utils';
import { getBudgetSectionStatus } from '@deepstream/common/rfq-utils/spendAndSavings';
import { isFinite } from 'lodash';
import { withProps } from '@deepstream/ui-utils/withProps';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { MoneyField } from '../../../form/MoneyField';
import { CurrencyAmount } from '../../../ui/Currency';
import { PropertyList, YesNoWithIcon } from '../../../PropertyList';
import * as rfx from '../../../rfx';
import { nestValues } from '../../../nestValues';
import { ValidationErrorValue } from '../../../draft/ValidationErrorValue';
import * as draft from '../../../draft/draft';
import { Validation } from '../../../draft/validation';
import { DraftSectionLabelConfigProvider } from '../../../draft/DraftSectionLabelConfigProvider';
import { ValidationPropertyRow } from '../../../draft/ValidationPropertyRow';
import { YesNoRadioField } from '../../../form/RadioField';
import { useUpdateSpendAndSavings } from './useUpdateSpendAndSavings';
import { SpendPanelHeader } from './SpendPanelHeader';
import { useConfirmDialog } from '../../../ui/useModalState';
import { ConfirmTotalSpendUpdateDialog } from './ConfirmTotalSpendUpdateDialog';
import { LeavePageModal } from '../../../draft/LeavePageModal';

const panelId = 'budget';

const BudgetPanelViewContent = () => {
  const { t } = useTranslation();
  const { spendAndSavings } = rfx.useStructure();
  const showValidationErrors = draft.useShowValidationErrors();

  const properties = React.useMemo(() => {
    return [
      {
        fieldName: 'hasBudgetedTotalValue',
        name: t('request.spendAndSavings.hasBudgetedTotalValue'),
        // @ts-expect-error ts(18049) FIXME: 'spendAndSavings' is possibly 'null' or 'undefined'.
        value: spendAndSavings.hasBudgetedTotalValue,
        Component: YesNoWithIcon,
        labelWidth: 290,
      },
      {
        fieldName: 'budgetedTotalValue',
        name: t('request.spendAndSavings.budgetedTotalValue'),
        // @ts-expect-error ts(18049) FIXME: 'spendAndSavings' is possibly 'null' or 'undefined'.
        value: spendAndSavings.budgetedTotalValue,
        Component: nestValues(ValidationErrorValue, withProps(CurrencyAmount, { showCode: true })),
        labelWidth: 290,
      },
    ];
  }, [spendAndSavings, t]);

  return (
    <Validation
      values={{
        // @ts-expect-error ts(18049) FIXME: 'spendAndSavings' is possibly 'null' or 'undefined'.
        budgetedTotalValue: spendAndSavings.budgetedTotalValue,
      }}
      schema={showValidationErrors ? (
        yup.object().shape({
          budgetedTotalValue: yup.number()
            .nullable()
            .transform((curr, orig) => orig === '' ? null : curr)
            .required(t('general.required'))
            .moreThan(0, t('errors.greaterThan', { min: 0 })),
        })
      ) : (
        yup.mixed()
      )}
    >
      <PropertyList Row={ValidationPropertyRow} properties={properties} />
    </Validation>
  );
};

export const BudgetFields = ({
  values,
  setFieldValue,
  validateForm,
  Padding = PanelPadding,
  Divider = PanelDivider,
}: {
  Padding?: (props: { children: React.ReactNode }) => React.ReactNode;
  Divider?: React.FC;
} & FormikProps<Partial<RfxSpendAndSavings>>) => {
  const { t } = useTranslation();

  return (
    <>
      <Padding>
        <YesNoRadioField
          name="hasBudgetedTotalValue"
          label={t('request.spendAndSavings.hasBudgetedTotalValue')}
          required
          variant="inline"
          showError
          onChange={async (value: boolean) => {
            if (!value) {
              await setFieldValue('budgetedTotalValue', null);
            }
            await validateForm();
          }}
          fieldLabelStyle={{ marginBottom: 0 }}
          helperText={values.hasBudgetedTotalValue ? (
            null
          ) : (
            <MessageBlock variant="warn">
              {t('request.spendAndSavings.noBudgetedValueWarning')}
            </MessageBlock>
          )}
        />
      </Padding>
      {values.hasBudgetedTotalValue && (
        <>
          <Divider />
          <Padding>
            <MoneyField
              required
              label={t('request.spendAndSavings.budgetedTotalValue')}
              description={t('request.spendAndSavings.budgetedTotalValueDescription')}
              name="budgetedTotalValue"
              sx={{ width: 200 }}
                // quick fix: we can remove setting the inputStyle height once a line height is
                // set for Input
              inputStyle={{ height: 40 }}
            />
          </Padding>
        </>
      )}
    </>
  );
};

const BudgetPanelEditContent = () => {
  const { t } = useTranslation();
  const { spendAndSavings } = rfx.useStructure();
  const { stopEditing } = rfx.useActions();
  const { isLive, isRevising } = rfx.useState();
  const [updateSpendAndSavings] = useUpdateSpendAndSavings();
  const { confirm, ...dialogProps } = useConfirmDialog();

  const initialValues = {
    // @ts-expect-error ts(18049) FIXME: 'spendAndSavings' is possibly 'null' or 'undefined'.
    hasBudgetedTotalValue: spendAndSavings.hasBudgetedTotalValue,
    // @ts-expect-error ts(18049) FIXME: 'spendAndSavings' is possibly 'null' or 'undefined'.
    budgetedTotalValue: spendAndSavings.budgetedTotalValue,
  };

  return (
    <>
      <Formik
        validateOnBlur
        enableReinitialize
        initialValues={initialValues}
        validationSchema={isLive || isRevising ? (
          yup.object().shape({
            budgetedTotalValue: yup.number()
              .nullable()
              .transform((curr, orig) => orig === '' ? null : curr)
              .when('hasBudgetedTotalValue', {
                is: hasBudgetedTotalValue => hasBudgetedTotalValue === true,
                then: schema => schema
                  .required(t('general.required'))
                  .moreThan(0, t('errors.greaterThan', { min: 0 })),
              }),
            })
        ) : (
          yup.object().shape({
            budgetedTotalValue: yup.number()
              .nullable()
              .transform((curr, orig) => orig === '' ? null : curr),
          })
        )}
        onSubmit={async (values) => {
          if (
            !isFinite(values.budgetedTotalValue) &&
            // @ts-expect-error ts(18049) FIXME: 'spendAndSavings' is possibly 'null' or 'undefined'.
            spendAndSavings.totalSavingsCalculationMethod === TotalSavingsCalculationMethod.BUDGET_FINAL_VALUE_DIFF
          ) {
            confirm(async () => {
              await updateSpendAndSavings({
                ...values,
                totalSavingsCalculationMethod: null,
                areTotalSavingsAccurate: null,
              }, {
                onSuccess: () => stopEditing(),
              });
            });
          } else {
            await updateSpendAndSavings(values, {
              onSuccess: () => stopEditing(),
            });
          }
        }}
      >
        {(props) => (
          <Form>
            <BudgetFields {...props as any} />
            <PanelDivider />
            <PanelPadding>
              <Flex justifyContent="flex-end">
                <CancelButton onClick={callAll(props.resetForm, stopEditing)} mr={2} />
                <SaveButton disabled={props.isSubmitting || !props.dirty || !props.isValid} />
              </Flex>
            </PanelPadding>
            <LeavePageModal />
          </Form>
        )}
      </Formik>
      <ConfirmTotalSpendUpdateDialog {...dialogProps} />
    </>
  );
};

export const BudgetPanel = () => {
  const { t } = useTranslation();
  const { spendAndSavings } = rfx.useStructure();
  const { editingPanelId } = rfx.useState();

  const isEditingOtherPanel = editingPanelId && editingPanelId !== panelId;
  const isEditingThisPanel = editingPanelId && editingPanelId === panelId;

  const heading = t('request.spendAndSavings.budget');

  return (
    <DraftSectionLabelConfigProvider>
      <Panel
        as="section"
        aria-label={heading}
        mb={20}
        sx={{
          opacity: isEditingOtherPanel ? 0.5 : 1,
          boxShadow: isEditingThisPanel ? '0 0 8px 0 rgba(0, 0, 0, 0.3)' : '',
        }}
      >
        <SpendPanelHeader
          heading={heading}
          panelId={panelId}
          // @ts-expect-error ts(2345) FIXME: Argument of type 'RfxSpendAndSavings | null | undefined' is not assignable to parameter of type 'RfxSpendAndSavings | undefined'.
          status={getBudgetSectionStatus(spendAndSavings)}
        />
        <PanelDivider />
        {isEditingThisPanel ? (
          <BudgetPanelEditContent />
        ) : (
          <BudgetPanelViewContent />
        )}
      </Panel>
    </DraftSectionLabelConfigProvider>
  );
};
