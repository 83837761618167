import { useTranslation } from 'react-i18next';
import { Draft } from '@deepstream/common/rfq-utils';

import { Box, Flex, Heading, Text } from 'rebass/styled-components';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import * as rfx from '../../../rfx';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { RfqIdProvider, useDraftRfqStructure, useLiveRfqStructure, useRfqId } from '../../../useRfq';
import { LoadingPanel } from '../../../ui/Loading';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import { SpendPanels } from './SpendPanels';
import { HiddenRequestPagePlaceholder } from '../../../HiddenRequestPagePlaceholder';
import { useModalState } from '../../../ui/useModalState';
import { DraftToolbar } from '../DraftToolbar';
import { useUpdateSpendAndSavings } from './useUpdateSpendAndSavings';
import { DisableSpendAndSavingsModal } from './DisableSpendAndSavingsModal';
import { SpendAndSavingsStatus } from './SpendAndSavingsStatus';

const DraftSpendToolbar = () => {
  const rfqId = useRfqId();
  const { editingPanelId, isTemplate, isRevising } = rfx.useState();
  const isSuperUserOrOwner = rfx.useIsSuperUserOrOwner();
  const isEditingAnyPanel = Boolean(editingPanelId);

  if (isEditingAnyPanel) {
    return null;
  }

  return (
    <DraftToolbar
      rfqId={rfqId}
      tabId="spend"
      hasUnsavedChanges={false}
      showSubmitButton={false}
      isSuperUserOrOwner={isSuperUserOrOwner}
      isTemplate={isTemplate}
      isRevising={isRevising}
      isRequestValid={true}
      excessSupplierCount={0}
    />
  );
};

const RevisionSpendContent = () => {
  const { t } = useTranslation('translation');

  return (
    <MessageBlock variant="info" mt={0} mb="20px">
      {t('request.spendAndSavings.revisionInfo')}
    </MessageBlock>
  );
};

const EnabledSpendContent = () => {
  const { t } = useTranslation('translation');
  const disableSpendAndSavingsModal = useModalState();
  const { editingPanelId } = rfx.useState();
  const isEditingAnyPanel = Boolean(editingPanelId);

  return (
    <>
      <Flex justifyContent="space-between">
        <Box mb={3}>
          <Heading as="h1" fontSize={6} lineHeight="normal" fontWeight="500">
            {t('request.spendAndSavings.spendAndSavings')}
          </Heading>
          <Text color="subtext" mt={1}>
            {t('request.spendAndSavings.spendAndSavingsSubtitle')}
          </Text>
        </Box>
        <Flex alignItems="center">
          <SpendAndSavingsStatus />
          <Button
            ml={3}
            sx={{ width: 'fit-content' }}
            variant="danger-outline"
            iconLeft="xmark"
            onClick={disableSpendAndSavingsModal.open}
            disabled={isEditingAnyPanel}
          >
            {t('request.spendAndSavings.disable')}
          </Button>
        </Flex>
      </Flex>
      <SpendPanels />
      <DisableSpendAndSavingsModal {...disableSpendAndSavingsModal} />
    </>
  );
};

const DisabledSpendContent = () => {
  const { t } = useTranslation('translation');
  const [updateSpendAndSavings] = useUpdateSpendAndSavings();

  const enableSpendAndSavings = () => updateSpendAndSavings({ enabled: true });

  return (
    <Flex flexDirection="column" height="100%">
      <Heading as="h1" fontSize={6} lineHeight="normal" fontWeight="500" mb={4}>
        {t('request.spendAndSavings.spendAndSavings')}
      </Heading>
      <Text mb={4} sx={{ maxWidth: '500px' }}>{t('request.spendAndSavings.disabledContent')}</Text>
      <Button sx={{ width: 'fit-content' }} variant="primary" onClick={enableSpendAndSavings}>
        {t('request.spendAndSavings.enableSpendAndSavings')}
      </Button>
    </Flex>
  );
};

const SpendContent = ({ navigateToTeam }: { navigateToTeam: () => void }) => {
  const { spendAndSavings } = rfx.useStructure<Draft>();
  const { isTemplate, isRevising } = rfx.useState();
  const isSuperUserOrOwner = rfx.useIsSuperUserOrOwner();

  return isRevising ? (
    <RevisionSpendContent />
  ) : !isTemplate && !isSuperUserOrOwner ? (
    <HiddenRequestPagePlaceholder accessRequiresOwnerRole />
  ) : spendAndSavings ? (
    <EnabledSpendContent />
  ) : (
    <DisabledSpendContent />
  );
};

export type DraftSpendProps = {
  rfqId: string;
  isRevising?: boolean;
  isTemplate?: boolean;
  navigateToTeam: () => void;
};

export const DraftSpend = ({
  rfqId,
  isRevising,
  isTemplate,
  navigateToTeam,
}: DraftSpendProps) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { data: rfxStructure, isLoading, isError } = useDraftRfqStructure({
    rfqId,
    currentCompanyId,
    isTemplate,
  });

  return (
    <RfqIdProvider rfqId={rfqId}>
      <rfx.StateProvider
        isRevising={isRevising}
        isTemplate={isTemplate}
      >
        {isLoading ? (
          <LoadingPanel />
          ) : isError ? (
            <ErrorPanel error={t('errors.unexpected')} />
          ) : rfxStructure ? (
            <rfx.StructureProvider structure={rfxStructure}>
              <SpendContent navigateToTeam={navigateToTeam} />
              <DraftSpendToolbar />
            </rfx.StructureProvider>
          ) : (
            null
          )}
      </rfx.StateProvider>
    </RfqIdProvider>
  );
};

export const LiveSpend = ({
  navigateToTeam,
}: {
  navigateToTeam: () => void;
}) => {
  const { t } = useTranslation('translation');
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { data: rfxStructure, isLoading, isError } = useLiveRfqStructure({
    rfqId,
    currentCompanyId,
  });

  return (
    <rfx.StateProvider isLive>
      {isLoading ? (
        <LoadingPanel />
        ) : isError ? (
          <ErrorPanel error={t('errors.unexpected')} />
        ) : rfxStructure ? (
          <rfx.StructureProvider structure={rfxStructure}>
            <SpendContent navigateToTeam={navigateToTeam} />
          </rfx.StructureProvider>
        ) : (
          null
        )}
    </rfx.StateProvider>
  );
};
