import { Flex, Text, Box, Image } from 'rebass/styled-components';
import styled from 'styled-components';

import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { Clamp } from '@deepstream/ui-kit/elements/text/Clamp';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';

const IntegrationLogoContainer = styled(Flex)`
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  overflow: hidden;
  flex-shrink: 0;
`;

type IntegrationLogoProps = {
  logo: string;
  mr?: number;
};

const IntegrationLogo = ({ logo, mr }: IntegrationLogoProps) => {
  return logo ? (
    <IntegrationLogoContainer mr={mr}>
      <Image src={logo} />
    </IntegrationLogoContainer>
  ) : (
    null
  );
};

const IntegrationIcon = ({ icon, mr }) => {
  return icon ? (
    <IntegrationLogoContainer mr={mr} >
      <Icon fontSize={6} light icon={icon} />
    </IntegrationLogoContainer>
  ) : (
    null
  );
};

type IntegrationCardProps = {
  name: string;
  description: string;
  selected: boolean;
  onClick: (selected) => void;
  icon?: string;
  logo?: string;
};

export const IntegrationCard = ({ name, description, selected, onClick, icon, logo }: IntegrationCardProps) => {
  return (
    <Flex
      sx={{
        border: selected ? 'primary' : 'lightGray2',
        borderRadius: '4px',
        minHeight: '140px',
        background: 'white',
        backgroundColor: selected ? 'blueLight' : 'white',
        cursor: 'pointer',
        ':hover': { backgroundColor: 'blueLight' },
      }}
      p="20px 20px 16px"
      onClick={() => onClick(!selected)}
      justifyContent="stretch"
      flexDirection="column"
    >
      <Flex flexDirection="column" flexGrow={1}>
        <Flex sx={{ columnGap: '4px', mb: 2 }} alignItems="center">
          {logo ? (
            <IntegrationLogo logo={logo} mr={2} />
          ) : (
            <IntegrationIcon icon={icon} mr={2} />
          )}
          <Text fontWeight={500} fontSize={4} lineHeight={1.25}>
            <Clamp lines={2}>{name}</Clamp>
          </Text>
        </Flex>
        <Box color="subtext" lineHeight={1.5}>
          {description}
        </Box>
      </Flex>
      <Flex justifyContent="flex-end" flexGrow={0}>
        <Checkbox checked={selected} onChange={() => onClick(!selected)} onClick={(e) => e.stopPropagation()} />
      </Flex>
    </Flex>
  );
};
