import * as PreQ from '@deepstream/common/legacy-pre-q-utils';
import * as React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { QuestionnaireOverview } from '@deepstream/common/preQual';
import { SentContractOverview } from '@deepstream/common/contract';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { IconTextButton } from '@deepstream/ui-kit/elements/button/IconTextButton';
import { UppercaseText } from '@deepstream/ui-kit/elements/text/UppercaseText';
import { Panel, PanelText } from '@deepstream/ui-kit/elements/Panel';
import { ConfirmDeleteDialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { Loading } from '../../../ui/Loading';
import { Questionnaire } from '../../../pre-q/Questionnaire';
import { CompanyProfileGeneralContent } from './CompanyProfileGeneralContent';
import { CompanyProfileActivityContent } from './CompanyProfileActivityContent';
import { CompanyProfileHeader } from './CompanyProfileHeader';
import { MainColumn, Row, SidebarColumn } from '../../../ui/ProfileLayout';
import { CompanyProfileOverview } from './CompanyProfileOverview';
import { SearchInput } from '../../../ui/SearchInput';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '../../../ui/Tabs';
import { Company, Document, DownloadFn, UploadFn } from '../../../ui/types';
import { UrlProvider } from '../../../ui/UrlProvider';
import { CountriesProvider } from '../../../ui/countries';
import { ReactQueryProvider } from '../../../ReactQueryProvider';
import { useConfirmDialog } from '../../../ui/useModalState';
import { SentRequestOverview, SystemFeatureFlags } from '../../../types';
import { ClientThemeProvider } from '../../../ClientThemeProvider';

const Provider = ({
  isLoggedIn,
  countryCodes,
  ...props
}: {
  isLoggedIn?: boolean;
  countryCodes?: string[];
  children: React.ReactNode;
}) => {
  return isLoggedIn ? (
    props.children
  ) : (
    <CountriesProvider countryCodes={countryCodes} {...props} />
  );
};

const GeneralContent = ({
  currentCompanyId,
  company,
  documents,
  isLoggedIn,
  downloadDocument,
  canEdit,
  numMembers,
  openBasicInformationModal,
  openRegisteredInformationModal,
  openContactDetailsModal,
  openDescriptionModal,
  openProductsModal,
  openDeprecatedProductsModal,
  openLocationsModal,
  navigateToDocumentLibrary,
  regionTags,
  navigateToLegacyPreQual,
  preQualAccessStatus,
  questionnaire,
  answerSet,
  systemFeatureFlags,
}: {
  currentCompanyId: string;
  company: Company;
  documents: Document[];
  isLoggedIn?: boolean;
  canEdit?: boolean;
  numMembers?: number;
  downloadDocument?: (document: Document) => void;
  openBasicInformationModal?: () => void;
  openRegisteredInformationModal?: () => void;
  openContactDetailsModal?: () => void;
  openDescriptionModal?: () => void;
  openProductsModal?: () => void;
  openDeprecatedProductsModal?: () => void;
  openLocationsModal?: () => void;
  navigateToDocumentLibrary?: () => void;
  regionTags: any[];
  navigateToLegacyPreQual?: () => void;
  preQualAccessStatus?: 'approved' | 'denied' | 'pending' | null;
  questionnaire: PreQ.Questionnaire;
  answerSet: PreQ.AnswerSet;
  systemFeatureFlags?: SystemFeatureFlags;
}) => {
  const { t } = useTranslation('companyProfile');
  const isTeamMember = currentCompanyId === company._id;
  const hasAccess = preQualAccessStatus === 'approved';

  const numCompletedQuestions = React.useMemo(
    () => {
      if (!questionnaire || !answerSet) return 0;

      const answeredQuestionnaire = new PreQ.AnsweredPreQQuestionnaire(
        new PreQ.PreQQuestionnaire(questionnaire),
        new PreQ.PreQAnswerSet(answerSet),
      );

      return answeredQuestionnaire.numCompletedQuestions;
    },
    [questionnaire, answerSet],
  );

  const canViewOwnLegacyPreQual = isTeamMember && numCompletedQuestions > 0;

  return (
    <Box sx={{ backgroundColor: isLoggedIn ? 'lightGray3' : 'white' }}>
      <Row mx={0} mt={20}>
        <SidebarColumn>
          <CompanyProfileOverview
            company={company}
            currentCompanyId={currentCompanyId}
            canEdit={canEdit}
            numMembers={numMembers}
            openBasicInformationModal={openBasicInformationModal}
            openRegisteredInformationModal={openRegisteredInformationModal}
            openContactDetailsModal={openContactDetailsModal}
          />
          {systemFeatureFlags?.newPreQualEnabled && (canViewOwnLegacyPreQual || hasAccess) && (
            <Panel padded>
              <Flex alignItems="center">
                <UppercaseText
                  fontWeight={500}
                  style={{ letterSpacing: '0.083333em' }}
                  flex="1 1 auto"
                >
                  {t('questionnaire.legacyPreQualification')}
                </UppercaseText>
                <Button
                  small
                  variant="primary-outline"
                  iconLeft="folder-open"
                  onClick={navigateToLegacyPreQual}
                >
                  {t('questionnaire.open')}
                </Button>
              </Flex>
            </Panel>
          )}
        </SidebarColumn>
        <MainColumn>
          <CompanyProfileGeneralContent
            company={company}
            currentCompanyId={currentCompanyId}
            documents={documents}
            isLoggedIn={isLoggedIn}
            canEdit={canEdit}
            downloadDocument={downloadDocument}
            openDescriptionModal={openDescriptionModal}
            openProductsModal={openProductsModal}
            openDeprecatedProductsModal={openDeprecatedProductsModal}
            openLocationsModal={openLocationsModal}
            navigateToDocumentLibrary={navigateToDocumentLibrary}
            regionTags={regionTags}
          />
        </MainColumn>
      </Row>
    </Box>
  );
};

export interface CompanyProfileProps {
  currentCompanyId: string;
  company: Company;
  documents: Document[];
  apiUrl: string;
  publicPagesBaseUrl: string;
  signupUrl?: string;
  isLoggedIn?: boolean;
  canEdit?: boolean;
  numMembers?: number;
  bannerStyle?: any;
  questionnaire?: PreQ.Questionnaire;
  answerSet?: PreQ.AnswerSet;
  downloadDocument?: (document: Document) => void;
  openBasicInformationModal?: () => void;
  openRegisteredInformationModal?: () => void;
  openContactDetailsModal?: () => void;
  openDescriptionModal?: () => void;
  openProductsModal?: () => void;
  openDeprecatedProductsModal?: () => void;
  openLocationsModal?: () => void;
  navigateToDocumentLibrary?: () => void;
  updateCompanyLogo?: () => Promise<void>;
  updateCompanyBanner?: () => Promise<void>;
  savePreQualAnswers?: (answers: any) => Promise<boolean>;
  uploadPreQualDocument?: UploadFn;
  uploadCompanyBanner?: UploadFn;
  uploadCompanyLogo?: UploadFn;
  downloadPreQualDocument?: DownloadFn;
  navigateToAuditTrail?: () => void;
  searchCompanies?: any;
  approvePreQualAccess?: any;
  denyPreQualAccess?: any;
  revokePreQualAccess?: any;
  requestPreQualAccess: any;
  preQualAccessStatus: any;
  regionTags: any[];
  countryCodes?: string[];
  toaster?: any;
  navigateToLegacyPreQual?: () => void;
  navigateToProfile?: () => void;
  switchTab?: (tabIndex: number) => void;
  show?: string;
  tab?: 'general' | 'activity',
  isSuperUser?: boolean;
  deleteLegacyPreQual?: any;
  systemFeatureFlags?: SystemFeatureFlags;
  belongsToPayingCompany?: boolean;
  questionnaires?: QuestionnaireOverview[];
  requests?: SentRequestOverview[];
  contracts?: SentContractOverview[];
}

export const CompanyProfile: React.FC<CompanyProfileProps> = ({
  currentCompanyId,
  company,
  documents,
  signupUrl,
  apiUrl,
  publicPagesBaseUrl,
  isLoggedIn,
  bannerStyle,
  downloadDocument,
  canEdit,
  numMembers,
  answerSet,
  questionnaire,
  openBasicInformationModal,
  openRegisteredInformationModal,
  openContactDetailsModal,
  openDescriptionModal,
  openProductsModal,
  openDeprecatedProductsModal,
  openLocationsModal,
  navigateToDocumentLibrary,
  updateCompanyLogo,
  updateCompanyBanner,
  savePreQualAnswers,
  uploadPreQualDocument,
  uploadCompanyBanner,
  uploadCompanyLogo,
  downloadPreQualDocument,
  navigateToAuditTrail,
  searchCompanies,
  requestPreQualAccess,
  approvePreQualAccess,
  denyPreQualAccess,
  revokePreQualAccess,
  preQualAccessStatus,
  regionTags,
  countryCodes,
  toaster,
  navigateToLegacyPreQual,
  navigateToProfile,
  switchTab,
  show = 'profile',
  tab = 'general',
  systemFeatureFlags,
  isSuperUser,
  deleteLegacyPreQual,
  belongsToPayingCompany,
  questionnaires,
  requests,
  contracts,
}) => {
  const { t } = useTranslation(['companyProfile', 'general']);
  const { confirm, ...dialogProps } = useConfirmDialog();
  const theme = useTheme();

  const tabListStyle = React.useMemo(() => ({
    display: 'flex',
    justifyContent: 'center',
    height: '38px',
    backgroundColor: '#ffffff',
    borderLeft: theme.borders.lightGray,
    borderRight: theme.borders.lightGray,
    borderBottom: theme.borders.lightGray,
    borderRadius: '0px 0px 4px 4px',
  }), [theme]);

  const defaultTabIndex = tab === 'activity' ? 1 : 0;

  React.useEffect(
    () => {
      if (window.location.search && !systemFeatureFlags?.newPreQualEnabled) {
        window.history.replaceState({}, document.title, window.location.pathname);
      }
    },
    [systemFeatureFlags],
  );

  const isTeamMember = currentCompanyId === company._id;

  const showActivity = belongsToPayingCompany && isLoggedIn && !isTeamMember;

  return (
    <UrlProvider signup={signupUrl} api={apiUrl} publicPages={publicPagesBaseUrl}>
      <ClientThemeProvider>
        <ReactQueryProvider>
          <Provider isLoggedIn={isLoggedIn} countryCodes={countryCodes}>
            <div style={{ maxWidth: '1158px', position: 'relative', margin: '0px auto' }}>
              {!systemFeatureFlags?.newPreQualEnabled || show === 'profile' ? (
                <>
                  {!isLoggedIn && (
                    <Box mx="auto" pr={[3, null, null, 4]} mt={4} width="100%" style={{ position: 'absolute', zIndex: 1 }}>
                      <Row justifyContent="flex-end">
                        <Box width="250px">
                          <SearchInput
                            placeholder={t('search.placeholder')}
                            buttonVariant="secondary"
                            fontSize={2}
                            height="40px"
                          />
                        </Box>
                      </Row>
                    </Box>
                  )}
                  <CompanyProfileHeader
                    company={company}
                    showPublicProfilePanel={isTeamMember}
                    apiUrl={apiUrl}
                    canEdit={canEdit}
                    bannerStyle={bannerStyle}
                    uploadCompanyBanner={uploadCompanyBanner}
                    uploadCompanyLogo={uploadCompanyLogo}
                    updateCompanyBanner={updateCompanyBanner}
                    updateCompanyLogo={updateCompanyLogo}
                    toaster={toaster}
                    noTabsAfter={!showActivity || !isLoggedIn}
                  />
                  {showActivity ? (
                    <Tabs key={tab} defaultIndex={defaultTabIndex} onChange={switchTab}>
                      <TabList style={tabListStyle}>
                        <Tab data-test="general-tab">
                          {t('common.general')}
                        </Tab>
                        <Tab data-test="activity-tab">
                          <Flex alignItems="center">
                            {t('common.activity')}
                          </Flex>
                        </Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel>
                          <GeneralContent
                            currentCompanyId={currentCompanyId}
                            company={company}
                            canEdit={canEdit}
                            documents={documents}
                            isLoggedIn={isLoggedIn}
                            numMembers={numMembers}
                            downloadDocument={downloadDocument}
                            navigateToDocumentLibrary={navigateToDocumentLibrary}
                            navigateToLegacyPreQual={navigateToLegacyPreQual}
                            openBasicInformationModal={openBasicInformationModal}
                            openRegisteredInformationModal={openRegisteredInformationModal}
                            openContactDetailsModal={openContactDetailsModal}
                            openDescriptionModal={openDescriptionModal}
                            openDeprecatedProductsModal={openDeprecatedProductsModal}
                            openProductsModal={openProductsModal}
                            openLocationsModal={openLocationsModal}
                            regionTags={regionTags}
                            // @ts-expect-error ts(2322) FIXME: Type 'Questionnaire | undefined' is not assignable to type 'Questionnaire'.
                            questionnaire={questionnaire}
                            // @ts-expect-error ts(2322) FIXME: Type 'AnswerSet | undefined' is not assignable to type 'AnswerSet'.
                            answerSet={answerSet}
                            systemFeatureFlags={systemFeatureFlags}
                          />
                        </TabPanel>
                        <TabPanel>
                          <CompanyProfileActivityContent
                            // @ts-expect-error ts(2322) FIXME: Type 'QuestionnaireOverview[] | undefined' is not assignable to type 'QuestionnaireOverview[]'.
                            questionnaires={questionnaires}
                            // @ts-expect-error ts(2322) FIXME: Type 'SentContractOverview[] | undefined' is not assignable to type 'SentContractOverview[]'.
                            contracts={contracts}
                            // @ts-expect-error ts(2322) FIXME: Type 'SentRequestOverview[] | undefined' is not assignable to type 'SentRequestOverview[]'.
                            requests={requests}
                            currentCompanyId={currentCompanyId}
                            companyId={company._id}
                          />
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  ) : (
                    <GeneralContent
                      currentCompanyId={currentCompanyId}
                      company={company}
                      canEdit={canEdit}
                      documents={documents}
                      isLoggedIn={isLoggedIn}
                      numMembers={numMembers}
                      downloadDocument={downloadDocument}
                      navigateToDocumentLibrary={navigateToDocumentLibrary}
                      openBasicInformationModal={openBasicInformationModal}
                      openRegisteredInformationModal={openRegisteredInformationModal}
                      openContactDetailsModal={openContactDetailsModal}
                      openDescriptionModal={openDescriptionModal}
                      openDeprecatedProductsModal={openDeprecatedProductsModal}
                      openProductsModal={openProductsModal}
                      openLocationsModal={openLocationsModal}
                      regionTags={regionTags}
                      navigateToLegacyPreQual={navigateToLegacyPreQual}
                      preQualAccessStatus={preQualAccessStatus}
                      // @ts-expect-error ts(2322) FIXME: Type 'Questionnaire | undefined' is not assignable to type 'Questionnaire'.
                      questionnaire={questionnaire}
                      // @ts-expect-error ts(2322) FIXME: Type 'AnswerSet | undefined' is not assignable to type 'AnswerSet'.
                      answerSet={answerSet}
                      systemFeatureFlags={systemFeatureFlags}
                    />
                  )}
                </>
              ) : show === 'pre-qualification' ? (
                <>
                  <Flex justifyContent="space-between">
                    <IconTextButton
                      icon="chevron-left"
                      fontSize={3}
                      onClick={navigateToProfile}
                    >
                      {t('questionnaire.backToProfile')}
                    </IconTextButton>
                    {isTeamMember && isSuperUser && (
                      <Button
                        small
                        variant="danger"
                        ml={4}
                        flex="0 0 auto"
                        onClick={() => confirm(() => deleteLegacyPreQual({ currentCompanyId }))}
                      >
                        {t('questionnaire.deletePreQual')}
                      </Button>
                    )}
                  </Flex>
                  {questionnaire && answerSet ? (
                    <Questionnaire
                      companyId={company._id}
                      currentCompanyId={currentCompanyId}
                      questionnaire={questionnaire}
                      answerSet={answerSet}
                      isLoggedIn={isLoggedIn}
                      canEdit={canEdit}
                      isLocked={!canEdit && currentCompanyId !== company._id && preQualAccessStatus !== 'approved'}
                      save={savePreQualAnswers}
                      upload={uploadPreQualDocument}
                      download={downloadPreQualDocument}
                      searchCompanies={searchCompanies}
                      approvePreQualAccess={approvePreQualAccess}
                      denyPreQualAccess={denyPreQualAccess}
                      revokePreQualAccess={revokePreQualAccess}
                      requestPreQualAccess={requestPreQualAccess}
                      preQualAccessStatus={preQualAccessStatus}
                      mt={3}
                      navigateToAuditTrail={navigateToAuditTrail}
                      navigateToProfile={navigateToProfile}
                      systemFeatureFlags={systemFeatureFlags}
                    />
                  ) : (
                    <Panel mt={3}>
                      <PanelText>
                        <Loading />
                      </PanelText>
                    </Panel>
                  )}
                </>
              ) : (
                null
              )}
              <ConfirmDeleteDialog
                heading={t('questionnaire.confirmDeleteModal.header')}
                message={t('questionnaire.confirmDeleteModal.body')}
                okButtonText={t('confirm', { ns: 'general' })}
                {...dialogProps}
              />
            </div>
          </Provider>
        </ReactQueryProvider>
      </ClientThemeProvider>
    </UrlProvider>
  );
};
