import { useCallback } from 'react';
import { RfxOtherSection } from '@deepstream/common/rfq-utils';
import { useField } from 'formik';
import { compact, mapValues } from 'lodash';
import * as rfx from '../rfx';

export const useHandleVesselPricingSectionLotChange = () => {
  const section = rfx.useSection<RfxOtherSection>();
  const [{ value: formSection }, , sectionFormik] = useField({ name: 'section' });
  const [{ value: currencyExchangeDef }, , currencyExchangeDefFormik] = useField({ name: 'currencyExchangeDef' });
  const [{ value: inclusionExchangeDefs }, , inclusionExchangeDefsFormik] = useField({ name: 'inclusionExchangeDefs' });
  const [{ value: termExchangeDefs }, , termExchangeDefsFormik] = useField({ name: 'termExchangeDefs' });
  const [{ value: hirePeriods }, , hirePeriodsFormik] = useField({ name: 'hirePeriods' });
  const [{ value: feeExchangeDefs }, , feeExchangeDefsFormik] = useField({ name: 'feeExchangeDefs' });
  const [{ value: intervalsByHirePeriodId }, , intervalsByHirePeriodIdFormik] = useField({ name: 'intervalsByHirePeriodId' });

  return useCallback((newLotIds: string[] | null, isNewValueObsolete: boolean, wasPreviousValueObsolete: boolean) => {
    if (isNewValueObsolete && !wasPreviousValueObsolete) {
      sectionFormik.setValue({
        ...section.liveVersion,
        isLive: formSection.isLive,
        liveVersion: formSection.liveVersion,
        lotIds: newLotIds,
        isObsolete: true,
      });

      if (currencyExchangeDef?.isLive) {
        currencyExchangeDefFormik.setValue({
          ...currencyExchangeDef.liveVersion,
          isLive: currencyExchangeDef.isLive,
          liveVersion: currencyExchangeDef.liveVersion,
          isObsolete: true,
        });
      }

      [
        [inclusionExchangeDefs, inclusionExchangeDefsFormik],
        [termExchangeDefs, termExchangeDefsFormik],
        [hirePeriods, hirePeriodsFormik],
        [feeExchangeDefs, feeExchangeDefsFormik],
      ].forEach(([defs, formik]) => {
        formik.setValue(
          compact(
            defs.map(def => {
              if (!def?.isLive) {
                return false;
              }

              return {
                ...def.liveVersion,
                isLive: def.isLive,
                liveVersion: def.liveVersion,
                isObsolete: true,
              };
            }),
          ),
        );
      });

      intervalsByHirePeriodIdFormik.setValue(
        mapValues(
          intervalsByHirePeriodId,
          (defs) => {
            return compact(
              defs.map(def => {
                if (!def?.isLive) {
                  return false;
                }

                return {
                  ...def.liveVersion,
                  isLive: def.isLive,
                  liveVersion: def.liveVersion,
                  isObsolete: true,
                };
              }),
            );
          },
        ),
      );
    } else if (!isNewValueObsolete && wasPreviousValueObsolete) {
      sectionFormik.setValue({
        ...formSection,
        lotIds: newLotIds,
        isObsolete: false,
      });

      if (!currencyExchangeDef.liveVersion?.isObsolete) {
        currencyExchangeDefFormik.setValue({
          ...currencyExchangeDef,
          isObsolete: false,
        });
      }

      [
        [inclusionExchangeDefs, inclusionExchangeDefsFormik],
        [termExchangeDefs, termExchangeDefsFormik],
        [hirePeriods, hirePeriodsFormik],
        [feeExchangeDefs, feeExchangeDefsFormik],
      ].forEach(([defs, formik]) => {
        formik.setValue(
          defs.map(def => {
            return def.liveVersion.isObsolete
              // don't set `isObsolete` to false when the live version
              // of the def is obsolete
              ? def
              : {
                ...def,
                isObsolete: false,
              };
          }),
        );
      });

      intervalsByHirePeriodIdFormik.setValue(
        mapValues(
          intervalsByHirePeriodId,
          (defs) => {
            return defs.map(def => {
              return def.liveVersion.isObsolete
                // don't set `isObsolete` to false when the live version
                // of the def is obsolete
                ? def
                : {
                  ...def,
                  isObsolete: false,
                };
            });
          },
        ),
      );
    } else {
      sectionFormik.setValue({
        ...formSection,
        lotIds: newLotIds,
      });
    }
  }, [
    sectionFormik,
    section.liveVersion,
    formSection,
    currencyExchangeDefFormik,
    currencyExchangeDef,
    inclusionExchangeDefs,
    inclusionExchangeDefsFormik,
    termExchangeDefs,
    termExchangeDefsFormik,
    hirePeriods,
    hirePeriodsFormik,
    feeExchangeDefs,
    feeExchangeDefsFormik,
    intervalsByHirePeriodId,
    intervalsByHirePeriodIdFormik,
  ]);
};
