import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { UnexpectedStateError } from '@deepstream/errors';
import { LongTextQuestionExchangeDefinition, QuestionExchangeDefinition, QuestionType } from '@deepstream/common/rfq-utils';
import { DisabledInputBox } from '../../../ui/Input';

export const DisabledLongTextField = ({
  exchangeDef,
}: {
  exchangeDef: QuestionExchangeDefinition;
}) => {
  const { t } = useTranslation();
  const { questionType } = exchangeDef as LongTextQuestionExchangeDefinition;

  if (questionType !== QuestionType.LONG_TEXT) {
    Sentry.captureException(new UnexpectedStateError('[LongTextField] Wrong question type passed', { questionType }));
    return null;
  }

  return (
    <DisabledInputBox sx={{ height: '100px', maxWidth: '500px' }}>
      {t('request.question.questionType.longText')}
    </DisabledInputBox>
  );
};
