import { ExchangeSnapshot } from '../../types';

/**
 * Mark the action's corresponding notification as read when
 * the element comes into view.
 */
 export const useContractActionNotificationSubject = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  exchange,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action,
}: {
  exchange: ExchangeSnapshot;
  action: any;
}) => {
  return null;
};
