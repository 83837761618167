import { useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import { useCurrentUser } from '../../useCurrentUser';

const EmojiWrapper = styled.div`
  @keyframes wave-animation {
    0% { transform: rotate(0.0deg) }
    10% { transform: rotate(17.0deg) }
    20% { transform: rotate(-10.0deg) }
    30% { transform: rotate(15.0deg) }
    40% { transform: rotate(-2.0deg) }
    50% { transform: rotate(10.0deg) }
    100% { transform: rotate(0.0deg) }
  }

  transform-origin: 70% 70%;
  animation-duration: 3s;
  animation-iteration-count: 3;
  animation-name: wave-animation;

  margin-left: 10px;
  display: inline-block;
`;

export const UserPanel = () => {
  const { t } = useTranslation(['dashboard']);
  const currentUser = useCurrentUser();

  return (
    <Box mb={4}>
      <Text
        fontSize={6}
        fontWeight={500}
      >
        {t('userPanel.welcome', {
          user: currentUser.firstName || currentUser.name || currentUser.email,
        })}
        <EmojiWrapper>
          &#128075;
        </EmojiWrapper>
      </Text>
    </Box>
  );
};
