import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { useApi, wrap } from '../../api';
import { Avatar } from '../../Avatar';
import { UserNamesTooltip } from '../../ui/TooltipList';

export const Approver: React.FC<{ userId: string }> = ({ userId }) => {
    const api = useApi();

    const { data: user } = useQuery(
      ['user', { userId }],
      wrap(api.getUser),
      {
        staleTime: 60 * 60 * 1000,
      },
    );

    return (
      <Flex sx={{ columnGap: '4px' }} alignItems="center">
        <Avatar
          userId={userId}
          width="xs"
          style={{ boxShadow: 'none' }}
        />
        <Truncate>{user?.name || user?.email}</Truncate>
      </Flex>
    );
  };

const ApproversGroup: React.FC<{ approverIds: string[] }> = ({
  approverIds,
}) => {
  const { t } = useTranslation();

  return (
    <Flex sx={{ columnGap: '4px' }} alignItems="center">
      <Box display="grid" sx={{ gridTemplateColumns: 'repeat(3, 18px) 1fr' }}>
        {approverIds.slice(0, 4).map((approverId, index) => (
          <Box key={index}>
            <Avatar
              key={index}
              userId={approverId}
              width="xs"
              style={{ boxShadow: 'none' }}
            />
          </Box>
        ))}
      </Box>
      {approverIds.length > 4 && (
        <Text>
          +
          {t('request.stageApprovals.otherApproverCount', {
            count: approverIds.length - 4,
          })}
        </Text>
      )}
    </Flex>
  );
};

export const Approvers: React.FC<{ approverIds: string[] }> = ({
  approverIds,
}) => {
  const { t } = useTranslation('translation');

  return approverIds.length === 1 ? (
    <Approver userId={approverIds[0]} />
  ) : (
    <Tooltip
      content={
        <UserNamesTooltip
          title={t('request.stageApprovals.approver_other')}
          userIds={approverIds}
        />
      }
    >
      <Box>
        <ApproversGroup approverIds={approverIds} />
      </Box>
    </Tooltip>
  );
};
