import { useState, useCallback, useMemo } from 'react';
import { ChangeType } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { withProps } from '@deepstream/ui-utils/withProps';
import { assertUnreachable } from '@deepstream/utils/assertUnreachable';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { BasicTableStyles, StaticTableStyles } from '../../../TableStyles';
import { Table } from '../../../Table';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useLocalStorageState } from '../../../useLocalStorageState';
import { FieldsCell, FieldsCellField, MIN_CELL_HEIGHT } from '../../../FieldsCell';
import { TeamUsersActionButtons } from '../../../TeamUsersActionButtons';
import { UserAvatar } from '../../../UserAvatar';
import { RequestRole, RequestTeamUser } from '../../../types';
import { TeamUserCellTitle } from '../../../TeamUserCellTitle';
import { useConfirmDialog, useModalState } from '../../../ui/useModalState';
import { UserDetails } from '../../../UserDetails';
import { useCurrentUser } from '../../../useCurrentUser';
import * as rfx from '../../../rfx';
import { ConfirmRemoveUserDialog } from './ConfirmRemoveUserDialog';
import { RequestTeamEditUserModal } from './RequestTeamEditUserModal';

const RoleFieldValue = ({ value }: { value: RequestRole }) => {
  const { t } = useTranslation();

  switch (value) {
    case RequestRole.OWNER:
      return <>{t('request.team.requestRole.owner')}</>;
    case RequestRole.TEAM_MEMBER:
      return <>{t('request.team.requestRole.teamMember')}</>;
    default:
      return assertUnreachable(value);
  }
};

export const RequestTeamUsersTable = ({
  users,
  canEditPermissions,
}: {
  users: RequestTeamUser[];
  canEditPermissions?: boolean;
  rfqStructureQueryKey?: any;
}) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const isSender = rfx.useIsSender();
  const [infoModalText, setInfoModalText] = useState({
    heading: t('request.team.dialog.cannotRemoveUser.heading'),
    body: t('request.team.dialog.cannotRemoveUser.body'),
  });
  const [modalUser, setModalUser] = useState<RequestTeamUser>();
  const [selectedUser, setSelectedUser] = useState<RequestTeamUser>();
  const { isLive, isReview } = rfx.useState();

  const saveChanges = rfx.useSaveChanges();

  const { confirm, ...dialogProps } = useConfirmDialog();

  const infoModal = useModalState();
  const editUserModal = useModalState();

  const openEditPermissionsModal = useCallback(
    (user: RequestTeamUser) => {
      if (!isSender && user._id === currentUser._id && user.companyId === currentCompanyId) {
        setInfoModalText({
          heading: t('request.team.dialog.cannotEditUser.heading'),
          body: t('request.team.dialog.cannotEditUser.body'),
        });
        setModalUser(user);
        infoModal.open();
      } else {
        setSelectedUser(user);
        editUserModal.open();
      }
    }, [t, currentUser, currentCompanyId, editUserModal, infoModal, setSelectedUser, isSender]);

  const openRemoveUserModal = useCallback(
    (user: RequestTeamUser) => {
      if (user._id === currentUser._id && user.companyId === currentCompanyId) {
        setInfoModalText({
          heading: t('request.team.dialog.cannotRemoveUser.heading'),
          body: t('request.team.dialog.cannotRemoveUser.body'),
        });
        setModalUser(user);
        infoModal.open();
      } else {
        setModalUser(user);
        confirm(async () => saveChanges({
          changes: [{
            type: ChangeType.TEAM_MEMBER_REMOVED,
            userId: user._id,
            name: user.name || user.email,
            companyId: user.companyId,
          }],
        }));
      }
    }, [t, confirm, currentUser, currentCompanyId, infoModal, saveChanges]);

  const columns = useMemo(
    () => [{ id: 'custom' }],
    [],
  );

  const CustomRowCells = useMemo(
    () => {
      const fields: FieldsCellField[] = [
        {
          label: t('general.email'),
          accessor: 'email',
          // when there's no user name, the email is displayed in the cell's
          // title and thus shouldn't be repeated here
          condition: (user) => !!user.name,
        },
        {
          label: t('general.role'),
          accessor: 'requestRole',
          FieldValueComponent: RoleFieldValue,
        },
      ];

      return withProps(
        FieldsCell,
        {
          fields,
          TitleComponent: TeamUserCellTitle,
          RowIconComponent: UserAvatar,
          actionColumnWidth: 100,
          ActionsComponent: canEditPermissions
            ? withProps(
              TeamUsersActionButtons,
              { openEditPermissionsModal, openRemoveUserModal },
            )
            : () => null,
        },
      );
    },
    [t, canEditPermissions, openEditPermissionsModal, openRemoveUserModal],
  );

  const [initialPageSize, setInitialPageSize] = useLocalStorageState<number>({
    key: `${currentCompanyId}.${currentUser._id}.teamUsers.pageSize`,
    defaultValue: 10,
  });

  const TableStyles = isLive || isReview
    ? StaticTableStyles
    : withProps(
      BasicTableStyles,
      { hoverBackgroundColor: 'lightGray6', hoverCursor: 'default' },
    );

  return (
    <>
      <TableStyles>
        <Table
          columns={columns}
          data={users}
          initialPageSize={initialPageSize}
          onPageSizeChange={setInitialPageSize}
          hideHeader
          CustomRowCells={CustomRowCells}
          hasStaticHeight={false}
          minCellHeight={MIN_CELL_HEIGHT}
          smallPageControls
          noDataPlaceholder={t('request.team.noUsersAdded')}
        />
      </TableStyles>

      {modalUser && (
        <ConfirmRemoveUserDialog user={modalUser} {...dialogProps} />
      )}

      {selectedUser && editUserModal.isOpen && (
        <RequestTeamEditUserModal
          key={selectedUser._id}
          user={selectedUser}
          isOpen={editUserModal.isOpen}
          close={editUserModal.close}
        />
      )}

      <Dialog
        heading={infoModalText.heading}
        body={(
          <>
            <UserDetails user={modalUser} />
            <MessageBlock variant="error" mt={3}>
              {infoModalText.body}
            </MessageBlock>
          </>
        )}
        okButtonText={t('general.ok')}
        okButtonVariant="primary"
        isOpen={infoModal.isOpen}
        onOk={infoModal.close}
      />
    </>
  );
};
