import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ExchangeStatus, HirePeriod } from '@deepstream/common/rfq-utils';
import { isEmpty, pick } from 'lodash';
import { Row } from 'react-table';
import { ThemeContext } from 'styled-components';
import { Box } from 'rebass/styled-components';
import { withProps } from '@deepstream/ui-utils/withProps';
import { Required } from '../Required';
import { DatetimeCell } from '../DatetimeCell';
import { Table } from '../Table';
import { ExchangeList, ListItemProps } from '../ExchangeTable';
import { FormTableStyles, StaticTableStyles } from '../TableStyles';
import { useDeviceSize } from '../ui/useDeviceSize';
import { nestCells } from '../nestCells';
import { DatetimeField } from '../form/DatetimeField';
import { ExchangeStatusIconText } from '../ExchangeStatusCell';
import * as draft from './draft';
import * as validation from './validation';
import { ErrorMessage } from '../ui/ErrorMessage';
import { ObsoleteCell } from './cell';
import * as rfx from '../rfx';

const CommencementFieldCell = ({ row, column, fieldName = 'exchangeDefs' }) => (
  <DatetimeField
    name={`${fieldName}.${column.id}`}
    hasTime={false}
    fullWidth={true}
    disabled={row.original.isObsolete || column.disabled}
    min={column.getMin ? column.getMin(row) : undefined}
    max={column.getMax ? column.getMax(row) : undefined}
  />
);

const FieldColumnHeader = ({ column }) => (
  <>
    {column.label}
    {column.required && <Required />}
  </>
);

const CommencementWindowStatusCell = ({ cell: { value: status }, row: { original: exchange } }) => {
  const isRecipient = exchange.recipientId === exchange.currentCompanyId;
  const currentCompanyGroup = isRecipient ? 'supplier' : 'buyer';

  return (
    <ExchangeStatusIconText status={status} currentCompanyGroup={currentCompanyGroup} />
  );
};

const CommencementWindowValidationErrorCell = ({ cell: { value }, column, fieldName }) => {
  const showValidationErrors = draft.useShowValidationErrors();
  const { error } = validation.useError(`${fieldName}.${column.id}`);

  return showValidationErrors && error ? (
    <ErrorMessage fontSize={2} error={error} />
  ) : (
    value
  );
};

export const CommencementWindowListItem: React.FC<ListItemProps & { fieldName: string }> = ({ row, fieldName, ...props }) => {
  const showValidationErrors = draft.useShowValidationErrors();
  const { errors } = validation.useErrors(fieldName);

  const isValid = isEmpty(pick(errors, ['fromDate', 'toDate']));

  return (
    <Box
      as="li"
      {...props}
      sx={{
        ...props.sx,
        backgroundColor: showValidationErrors && !isValid
          ? 'errorBackground'
          : undefined,
        opacity: row.original.isObsolete ? 0.4 : undefined,
      }}
    />
  );
};

const CommencementWindowRowCells = ({ row, fieldName }) => {
  const theme = React.useContext(ThemeContext);
  const showValidationErrors = draft.useShowValidationErrors();
  const { errors } = validation.useErrors(fieldName);

  const isValid = isEmpty(pick(errors, ['fromDate', 'toDate']));

  return (
    <>
      {row.cells.map(cell => {
        const { key, ...props } = cell.getCellProps({
          style: {
            width: cell.column.width,
            textAlign: (cell.column as any).textAlign,
            verticalAlign: (cell.column as any).verticalAlign,
            backgroundColor: showValidationErrors && !isValid
              ? theme.colors.errorBackground
              : undefined,
            opacity: row.original.isObsolete ? 0.4 : undefined,
          },
        });

        return (
          <td key={key} {...props}>
            {cell.render('Cell')}
          </td>
        );
      })}
    </>
  );
};

export const BidCommencementWindowTable = ({
  hirePeriod,
}: {
  hirePeriod: HirePeriod;
}) => {
  const { t } = useTranslation();
  const { isExtraSmall } = useDeviceSize();

  const columns = React.useMemo(
    () => [
      {
        id: 'fromDate',
        Header: t('request.vesselPricing.hirePeriods.startDate'),
        accessor: 'fromDate',
        Cell: nestCells(ObsoleteCell, DatetimeCell),
        onlyDate: true,
      },
      {
        id: 'toDate',
        Header: t('request.vesselPricing.hirePeriods.endDate'),
        accessor: 'toDate',
        Cell: nestCells(ObsoleteCell, DatetimeCell),
        onlyDate: true,
      },
      {
        id: 'status',
        Header: t('general.status'),
        accessor: ({ isObsolete }) => isObsolete
          ? ExchangeStatus.OBSOLETE
          : ExchangeStatus.INFORMATION_ONLY,
        Cell: nestCells(ObsoleteCell, CommencementWindowStatusCell),
      },
    ],
    [t],
  );

  return isExtraSmall ? (
    <ExchangeList
      columns={columns}
      exchanges={[hirePeriod]}
      onRowClick={null}
    />
  ) : (
    <StaticTableStyles>
      <Table
        columns={columns}
        data={[hirePeriod]}
        isSortable={false}
      />
    </StaticTableStyles>
  );
};

export const CommencementWindowTable = ({
  hirePeriod,
  fieldName,
}: {
  hirePeriod: HirePeriod;
  fieldName?: string;
}) => {
  const { t } = useTranslation();
  const { isExtraSmall } = useDeviceSize();

  const { CommencementWindowDateCell, CustomListItem, CustomRowCells } = React.useMemo(() => ({
    CommencementWindowDateCell: nestCells(
      withProps(CommencementWindowValidationErrorCell, { fieldName }),
      DatetimeCell,
    ),
    CustomListItem: withProps(CommencementWindowListItem, { fieldName }),
    CustomRowCells: withProps(CommencementWindowRowCells, { fieldName }),
  }), [fieldName]);

  const columns = React.useMemo(
    () => [
      {
        id: 'fromDate',
        Header: t('request.vesselPricing.hirePeriods.startDate'),
        accessor: 'fromDate',
        Cell: CommencementWindowDateCell,
        onlyDate: true,
        isCondensed: false,
      },
      {
        id: 'toDate',
        Header: t('request.vesselPricing.hirePeriods.endDate'),
        accessor: 'toDate',
        Cell: CommencementWindowDateCell,
        onlyDate: true,
        isCondensed: false,
      },
    ],
    [t, CommencementWindowDateCell],
  );

  return isExtraSmall ? (
    <ExchangeList
      columns={columns}
      exchanges={[hirePeriod]}
      onRowClick={null}
      CustomListItem={CustomListItem}
    />
  ) : (
    <StaticTableStyles>
      <Table
        columns={columns}
        data={[hirePeriod]}
        isSortable={false}
        CustomRowCells={CustomRowCells}
      />
    </StaticTableStyles>
  );
};

export const CommencementWindowTableField = ({
  hirePeriod,
  fieldName = 'exchangeDefs',
  isTemplate,
  isSectionObsolete,
}: {
  hirePeriod: HirePeriod,
  fieldName: string,
  isTemplate?: boolean,
  isSectionObsolete?: boolean;
}) => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        id: 'fromDate',
        Header: FieldColumnHeader,
        label: t('request.vesselPricing.hirePeriods.startDate'),
        accessor: 'fromDate',
        Cell: withProps(CommencementFieldCell, { fieldName, isFirst: true }),
        onlyDate: true,
        isCondensed: false,
        required: true,
        getMin: () => 'now',
        getMax: (row: Row<HirePeriod>) => row.original.toDate,
        disabled: isTemplate || isSectionObsolete,
      },
      {
        id: 'toDate',
        Header: FieldColumnHeader,
        label: t('request.vesselPricing.hirePeriods.endDate'),
        accessor: 'toDate',
        Cell: withProps(CommencementFieldCell, { fieldName }),
        onlyDate: true,
        isCondensed: false,
        required: true,
        getMin: (row: Row<HirePeriod>) => row.original.fromDate ?? 'now',
        disabled: isTemplate || isSectionObsolete,
      },
    ],
    [t, fieldName, isTemplate, isSectionObsolete],
  );

  return (
    <FormTableStyles>
      <Table columns={columns} data={[hirePeriod]} />
    </FormTableStyles>
  );
};
