import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { filter, isEmpty } from 'lodash';
import { isAuctionLineItemExchangeDef } from '@deepstream/common/rfq-utils';
import { withProps } from '@deepstream/ui-utils/withProps';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { StaticTableStyles, FormTableStyles } from '../TableStyles';
import { Table } from '../Table';
import { Required } from '../Required';
import { useExchangeDefsField, createStandaloneAuctionLineItem } from './exchangeDefs';
import * as rfx from '../rfx';
import {
  ExchangeDefActionsCell,
  AuctionExchangeDefDescriptionFieldCell,
  ValidationErrorCell,
  AuctionTextFieldCell,
  AuctionNumberFieldCell,
  AuctionExchangeDefDescriptionCell,
  NumberCell,
  TruncateOrDashCell,
} from './cell';
import { nestCells } from '../nestCells';
import { ErrorMessage } from '../ui/ErrorMessage';
import * as draft from './draft';
import * as validation from './validation';
import { ExchangeDefRowCells } from './ExchangeDefRowCells';
import { AddAuctionLineItemDropdown } from './AddAuctionLineItemDropdown';
import { useModalState } from '../ui/useModalState';
import { AddPreviousLineItemsModal } from './AddPreviousLineItemsModal';
import { ExchangeDefFieldValueProvider, useExchangeDefFieldValue } from '../ExchangeDefFieldValueContext';
import { LinkedExchangeDefFieldCell } from '../LinkedExchangeDefFieldCell';

const FieldColumnHeader = ({ column }) => (
  <>
    {column.label}
    {column.required && <Required />}
  </>
);

export const AuctionLineItemTable = ({ fieldName = 'exchangeDefs' }: { fieldName?: string }) => {
  const { t } = useTranslation();
  const exchangeDefs = rfx.useSectionExchangeDefs();
  const showValidationErrors = draft.useShowValidationErrors();
  const { error } = validation.useError(fieldName);
  const { getFieldValue } = useExchangeDefFieldValue();

  const lineItemExchangeDefs = useMemo(
    () => filter(exchangeDefs, isAuctionLineItemExchangeDef),
    [exchangeDefs],
  );

  const columns = useMemo(
    () => [
      {
        id: 'description',
        Header: t('general.description'),
        accessor: 'fields.description',
        Cell: nestCells(
          ValidationErrorCell,
          nestCells(AuctionExchangeDefDescriptionCell, LinkedExchangeDefFieldCell),
        ),
      },
      {
        id: 'unit',
        Header: t('general.unit'),
        accessor: 'fields.unit',
        Cell: nestCells(
          ValidationErrorCell,
          nestCells(TruncateOrDashCell, LinkedExchangeDefFieldCell),
        ),
        width: '20%',
      },
      {
        id: 'quantity',
        Header: t('general.quantity'),
        accessor: (exchangeDef) => getFieldValue(exchangeDef, 'quantity'),
        Cell: nestCells(
          ValidationErrorCell,
          NumberCell,
        ),
        format: 'integer.positive',
        width: '20%',
      },
    ],
    [getFieldValue, t],
  );

  return showValidationErrors && error ? (
    <PanelPadding bg="errorBackground" py="16px">
      <ErrorMessage fontSize={2} error={error} />
    </PanelPadding>
  ) : isEmpty(lineItemExchangeDefs) ? (
    <PanelPadding>
      <Text color="subtext" fontSize={2}>
        {t('request.lineItems.noLineItemsAdded')}
      </Text>
    </PanelPadding>
  ) : (
    <StaticTableStyles headerColor="lightNavy" smallHeader py="15px">
      <Table
        columns={columns}
        data={lineItemExchangeDefs}
        CustomRowCells={ExchangeDefRowCells}
      />
    </StaticTableStyles>
  );
};

export const AuctionLineItemTableField = ({ fieldName = 'exchangeDefs' }: { fieldName?: string }) => {
  const { t } = useTranslation();
  const addPreviousLineItemsModal = useModalState();

  const { exchangeDefs, addExchangeDef } = useExchangeDefsField(
    fieldName,
    createStandaloneAuctionLineItem,
  );

  const columns = useMemo(
    () => [
      {
        id: 'description',
        Header: FieldColumnHeader,
        label: t('general.description'),
        accessor: 'fields.description',
        Cell: nestCells(
          withProps(AuctionExchangeDefDescriptionFieldCell, { fieldName }),
          LinkedExchangeDefFieldCell,
        ),
        hideNumbering: true,
        required: true,
      },
      {
        id: 'unit',
        Header: FieldColumnHeader,
        label: t('general.unit'),
        accessor: 'fields.unit',
        Cell: nestCells(
          withProps(AuctionTextFieldCell, { fieldName }),
          LinkedExchangeDefFieldCell,
        ),
        required: true,
        width: '20%',
      },
      {
        id: 'quantity',
        Header: FieldColumnHeader,
        label: t('general.quantity'),
        accessor: 'fields.quantity',
        Cell: nestCells(
          withProps(AuctionNumberFieldCell, { fieldName }),
          LinkedExchangeDefFieldCell,
        ),
        format: 'integer.positive',
        minValue: 1,
        required: true,
        width: '20%',
      },
      {
        id: 'menu',
        Header: () => '',
        accessor: null,
        width: 48,
        Cell: withProps(ExchangeDefActionsCell, { fieldName }),
      },
    ],
    [t, fieldName],
  );

  return (
    <>
      {exchangeDefs.length > 0 ? (
        <ExchangeDefFieldValueProvider>
          <FormTableStyles>
            <Table columns={columns} data={exchangeDefs} />
          </FormTableStyles>
        </ExchangeDefFieldValueProvider>
      ) : (
        <Text color="subtext" fontSize={2} mb="14px">
          {t('request.lineItems.noLineItemsAdded')}
        </Text>
      )}
      <Box mt="10px">
        <AddAuctionLineItemDropdown
          onSelect={(previous: boolean) => {
            if (previous) {
              addPreviousLineItemsModal.open();
            } else {
              addExchangeDef();
            }
          }}
        />
      </Box>
      {addPreviousLineItemsModal.isOpen && (
        <AddPreviousLineItemsModal
          isOpen={addPreviousLineItemsModal.isOpen}
          onSuccess={addPreviousLineItemsModal.close}
          onCancel={addPreviousLineItemsModal.close}
        />
      )}
    </>
  );
};
