import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';
import { useField } from 'formik';
import { CellProps } from 'react-table';
import { findLast, findIndex, isUndefined } from 'lodash';
import { informationRequestExchangeTypes } from '@deepstream/common/exchangesConfig';
import { ExchangeDefinition, ExchangeType } from '@deepstream/common/rfq-utils';
import {
  ContractDocumentExchangeDefinition,
  ContractProvidedBy,
  LegacyContractExchangeDefinition,
  isContractExchangeDef,
  isLegacyContractExchangeDef,
} from '@deepstream/common/contract';
import { ObsoleteIcon } from '@deepstream/ui-kit/elements/icon/Icon';
import { truncateStyle } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { ContractAttachment } from './ContractAttachment';
import { getDocumentExchangeDescriptionFromFile } from '../../utils';
import { FileField } from '../../form/FilesField';
import { DisabledInputBox } from '../../ui/Input';
import { TextField, TextFieldBase } from '../../form/TextField';
import { ContractExchangeDefActions } from './ContractExchangeDefActions';
import { SelectFieldBase } from '../../form/SelectField';
import { ContractType } from '../../types';
import { useContractState } from './contract';

const isDocumentRequest = (exchangeType: ExchangeType) => informationRequestExchangeTypes.includes(exchangeType);

export const AttachmentCell = ({ cell: { value: attachment }, row }) => {
  const { t } = useTranslation('contracts');

  const isProvidedBySupplier = row.original.providedBy === ContractProvidedBy.SUPPLIER;

  return attachment ? (
    <ContractAttachment truncate attachment={attachment} />
  ) : isDocumentRequest(row.original.type) ? (
    <Text color="subtext">
      {t('details.supplierToUpload')}
    </Text>
  ) : isProvidedBySupplier ? (
    <Text color="subtext">
      {t('providedBy.supplier')}
    </Text>
  ) : (
    <EmDash />
  );
};

export const ContractExchangeFileFieldCell = ({
  row,
  column,
  descriptionFieldName = 'category',
  fieldName = 'exchangeDefs',
  acceptedFileTypes,
}) => {
  const { t } = useTranslation('contracts');
  const [{ value: exchangeDefs }] = useField<ExchangeDefinition[]>(fieldName);
  // We can't rely on `row.index` because we render a table with a single row for each exchange.
  const index = findIndex(exchangeDefs, exchangeDef => exchangeDef._id === row.original._id);
  const [{ value: description },, helpers] = useField(`${fieldName}[${index}].${descriptionFieldName}`);
  const [{ value: exchangeType }] = useField(`${fieldName}[${index}].type`);
  const [{ value: providedBy }] = useField(`${fieldName}[${index}].providedBy`);
  // @ts-expect-error ts(2339) FIXME: Property 'isLive' does not exist on type 'ContractStateContextType | undefined'.
  const { isLive } = useContractState();

  const isDocumentRequest = informationRequestExchangeTypes.includes(exchangeType);
  const isContractExchange = exchangeType === ExchangeType.CONTRACT;
  const isLegacyContractExchange = exchangeType === ExchangeType.LEGACY_CONTRACT;
  const isDisabled = isContractExchange
    ? row.original.isLive
    : isLegacyContractExchange
      ? !isLive // Legacy contract documents can be updated when the contract is live
      : false;
  const isProvidedBySupplier = providedBy === ContractProvidedBy.SUPPLIER;

  return (isDocumentRequest || isProvidedBySupplier) ? (
    <TextFieldBase
      value={isDocumentRequest ? t('details.supplierToUpload') : t('providedBy.supplier')}
      disabled
    />
  ) : (
    <FileField
      required
      hideLabel
      name={`${fieldName}[${index}].${column.id}`}
      label={column.label}
      disabled={row.original.isObsolete || column.disabled || isDisabled}
      purpose="contract"
      initialFile={row.original.file}
      truncateFileName
      onUploadStart={file => {
        // When the exchange def doesn't have a description, derive it from the file name
        if (!description) {
          helpers.setValue(getDocumentExchangeDescriptionFromFile(file));
        }
      }}
      accept={acceptedFileTypes}
    />
  );
};

export const ContractExchangeDefProviderFieldCell = ({ row, column, fieldName = 'exchangeDefs' }) => {
  const { t } = useTranslation(['general', 'contracts']);
  const [{ value: exchangeDefs }] = useField<ExchangeDefinition[]>(fieldName);
  // We can't rely on `row.index` because we render a table with a single row for each exchange.
  const index = findIndex(exchangeDefs, exchangeDef => exchangeDef._id === row.original._id);
  const [{ value: providedBy },, providedByFormik] = useField(`${fieldName}[${index}].${column.id}`);
  const [{ value: attachments },, attachmentsFormik] = useField(`${fieldName}[${index}].attachments`);

  const { isLive } = row.original;

  const options = useMemo(
    () => [
      {
        value: ContractProvidedBy.BUYER,
        label: t('providedBy.buyer', { ns: 'contracts' }),
      },
      {
        value: ContractProvidedBy.SUPPLIER,
        label: t('providedBy.supplier', { ns: 'contracts' }),
      },
    ],
    [t],
  );

  const onProvidedByChange = useCallback(
    (providedBy: ContractProvidedBy) => {
      // Clear uploaded attachments
      if (providedBy === ContractProvidedBy.SUPPLIER) {
        attachmentsFormik.setValue([]);
      }
      providedByFormik.setValue(providedBy);
    },
    [attachmentsFormik, providedByFormik],
  );

  return (
    <SelectFieldBase
      name={`${fieldName}[${index}].${column.id}`}
      items={options}
      required={column.required}
      value={providedBy}
      disabled={isLive || column.disabled}
      onChange={onProvidedByChange}
    />
  );
};

export const ExchangeDefDescriptionFieldCell = ({ row, column, fieldName = 'exchangeDefs' }) => {
  const { t } = useTranslation('general');
  const { isObsolete } = row.original;
  const [{ value: exchangeDefs }] = useField<ExchangeDefinition[]>(fieldName);
  // We can't rely on `row.index` because we render a table with a single row for each exchange.
  const index = findIndex(exchangeDefs, exchangeDef => exchangeDef._id === row.original._id);
  const [{ value: description }] = useField(`${fieldName}[${index}].${column.id}`);

  return isObsolete ? (
    <DisabledInputBox sx={truncateStyle}>
      <Tooltip content={t('obsolete') as string}>
        <ObsoleteIcon mr={2} />
      </Tooltip>
      {description}
    </DisabledInputBox>
  ) : (
    <TextField
      required
      hideLabel
      name={`${fieldName}[${index}].${column.id}`}
      label={column.label}
      disabled={column.disabled}
    />
  );
};

/**
 * Contains a menu with the actions for each exchange definition row
 */
 export const ContractExchangeDefActionsCell = ({
  row,
  fieldName = 'exchangeDefs',
  allowObsoleteStateChange,
  onlyAllowRemoval,
}: CellProps<ExchangeDefinition>) => {
  const [{ value: exchangeDefs }] = useField<ExchangeDefinition[]>(fieldName);
  // We can't rely on `row.index` because we render a table with a single row for each exchange.
  const index = findIndex(exchangeDefs, exchangeDef => exchangeDef._id === row.original._id);

  return (
    <ContractExchangeDefActions
      index={index}
      fieldName={fieldName}
      allowObsoleteStateChange={allowObsoleteStateChange}
      onlyAllowRemoval={onlyAllowRemoval}
    />
  );
};

const getContractType = (exchangeDef: ContractDocumentExchangeDefinition | LegacyContractExchangeDefinition) => {
  if (isLegacyContractExchangeDef(exchangeDef)) {
    return ContractType.LEGACY_CONTRACT;
  } else if (exchangeDef.isAddendum) {
    return ContractType.ADDENDUM;
  } else if (exchangeDef.isAddendum === false) {
    return ContractType.PRIMARY_CONTRACT;
  }
};

/**
 * Select component that maps the `isAddendum` flag to the contract type. (ie Contract or Addendum).
 * When `isAddendum` is true, a `primaryContractId` is set to reference the contract
 * that this addendum is for.
 */
export const ContractTypeFieldCell = ({ row, column, fieldName = 'exchangeDefs' }) => {
  const { t } = useTranslation('contracts');
  const [{ value: exchangeDefs }] = useField<ExchangeDefinition[]>({ name: fieldName });
  // We can't rely on `row.index` because we render a table with a single row for each exchange.
  const index = findIndex(exchangeDefs, exchangeDef => exchangeDef._id === row.original._id);
  const [,, isAddendumFormik] = useField({ name: `${fieldName}[${index}].isAddendum` });
  const [,, primaryContractIdFormik] = useField({
    name: `${fieldName}[${index}].primaryContractId`,
  });

  const lastPrimaryContract = useMemo(
    () => findLast(
      exchangeDefs,
      exchangeDef => isContractExchangeDef(exchangeDef) && !exchangeDef.isAddendum,
    ),
    [exchangeDefs],
  );

  const { type, isObsolete, isLive, isAddendum } = row.original;

  const options = useMemo(
    () => type === ExchangeType.CONTRACT
      ? [{
        value: ContractType.PRIMARY_CONTRACT,
        label: t('contract'),
      },
      {
        value: ContractType.ADDENDUM,
        label: t('addendum'),
      }]
      : [{
        value: ContractType.LEGACY_CONTRACT,
        label: t('legacyContract'),
      }],
    [type, t],
  );

  const onTypeChange = useCallback(
    (type: ContractType) => {
      const isAddendum = type === ContractType.ADDENDUM;

      if (!isAddendum) {
        // Reset the primaryContractId when the type is changed to contract
        primaryContractIdFormik.setValue(undefined);
      } else {
        // Set the primaryContractId to the last primary contract in the list
        // @ts-expect-error ts(18048) FIXME: 'lastPrimaryContract' is possibly 'undefined'.
        primaryContractIdFormik.setValue(lastPrimaryContract._id);
      }

      isAddendumFormik.setValue(isAddendum);
    },
    [isAddendumFormik, primaryContractIdFormik, lastPrimaryContract],
  );

  // Live contracts that don't have an `isAddendum` flag are primary contracts, so we
  // should override the value to be `ContractType.PRIMARY_CONTRACT`
  const shouldOverrideValue = isContractExchangeDef(row.original) && isUndefined(isAddendum) && isLive;

  return (
    <SelectFieldBase
      items={options}
      required={column.required}
      value={shouldOverrideValue
        ? ContractType.PRIMARY_CONTRACT
        : getContractType(row.original)
      }
      disabled={isObsolete || isLive || isLegacyContractExchangeDef(row.original) || column.disabled}
      onChange={onTypeChange}
      placeholder={t('contractTypePlaceholder')}
    />
  );
};

export const ContractTypeCell = ({ row }) => {
  const { t } = useTranslation('contracts');
  // @ts-expect-error ts(2339) FIXME: Property 'isLive' does not exist on type 'ContractStateContextType | undefined'.
  const { isLive } = useContractState();

  const exchangeDef = row.original.def || row.original;
  const { isAddendum } = exchangeDef;

  const shouldOverrideValue = isContractExchangeDef(exchangeDef) && isUndefined(isAddendum) && (isLive || exchangeDef.isLive);

  return isLegacyContractExchangeDef(exchangeDef) ? (
    <Text>
      {t('legacyContract')}
    </Text>
  ) : isAddendum ? (
    <Text>
      {t('addendum')}
    </Text>
  ) : (isAddendum === false || shouldOverrideValue) ? (
    <Text>
      {t('contract')}
    </Text>
  ) : (
    <EmDash />
  );
};

export const SignatureTypeCell = ({ cell: { value }, row }) => {
  const { t } = useTranslation(['contracts', 'general']);

  return (
    <>
      {value ? (
        t(`signature.type.${value}`)
      ) : (
        t('notApplicable', { ns: 'general' })
      )}
    </>
  );
};
