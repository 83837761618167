import { useMemo } from 'react';
import { Attachment, Bulletin, ChangeType, ExchangeAddedChange, ExchangeType } from '@deepstream/common/rfq-utils';
import { noop } from 'lodash';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { ModalForm } from '../../ModalForm';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { TextField } from '../../form/TextField';
import { FileField } from '../../form/FilesField';
import { CHANGE_EXCHANGE_MAX_ATTACHMENTS } from '../../ExchangeModal/exchangeReplyFormConfig';
import { useChangeBulletinExchanges } from './useChangeBulletinExchanges';

type NewChatModalProps = {
  rfqId: string;
  sectionId: string;
  bulletin?: Bulletin;
  isOpen: boolean;
  onClose: any;
};

export const AddBulletinModal = ({
  rfqId,
  sectionId,
  bulletin,
  isOpen,
  onClose = noop,
}: NewChatModalProps) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const [changeExchanges] = useChangeBulletinExchanges({ isBulletinUpdate: false });

  const getCreateChange = (message: string, attachments: Attachment[]): ExchangeAddedChange => ({
    type: ChangeType.EXCHANGE_ADDED,
    sectionName: sectionId,
    companyId: currentCompanyId,
    docXDef: {
      _id: uuid(),
      type: ExchangeType.BULLETIN,
      message,
      attachments,
    },
  });

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      message: z.string({ message: t('general.required') }),
      attachments: z.array(z.object({}).optional()),
    });

    return toFormikValidationSchema(Schema);
  }, [t]);

  return (
    <ModalForm
      heading={t('request.bulletin.newBulletin')}
      initialValues={{
        message: bulletin?.message,
        attachments: [],
      }}
      validationSchema={validationSchema}
      isOpen={isOpen}
      onCancel={onClose}
      onSubmit={({ message, attachments }) =>
        changeExchanges({
          rfqId,
          currentCompanyId,
          changes: [getCreateChange(message, attachments)],
        }, {
          onSuccess: onClose,
        })
      }
      submitLabel={t('request.bulletin.newBulletin')}
    >
      <Stack gap={3}>
        <TextField
          isMultiLine
          required
          name="message"
          label={t('general.message')}
          rows={8}
        />
        <FileField name="attachments" label={t('request.bulletin.files')} purpose="rfq" max={CHANGE_EXCHANGE_MAX_ATTACHMENTS} />
        <MessageBlock variant="info" mt={0}>
          {t('request.bulletin.create.visibilityInfo')}
        </MessageBlock>
      </Stack>
    </ModalForm>
  );
};
