import * as React from 'react';
import { Box, BoxProps, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';
import { PreQualSupplier, QuestionnaireOverview } from '@deepstream/common/preQual';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { withProps } from '@deepstream/ui-utils/withProps';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { CompanyLogo } from '../../CompanyLogo';
import { companyProfileRoute } from '../../AppRouting';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { CompactTableStyles } from '../../TableStyles';
import { Table } from '../../Table';
import { TruncateCell } from '../../TruncateCell';
import { QuestionnaireStatusCell } from './Questionnaire/QuestionnaireStatus';
import { PercentageFieldValue } from '../../FieldsCell';
import { QuestionnaireVersionCell } from './Questionnaire/QuestionnaireVersionCell';
import { EXPANDABLE_SUPPLIER_ROW_HEIGHT } from './utils';
import { useQuestionnaireNavigation } from '../../appNavigation';

const QuestionnairesTable = ({ questionnaires }: { questionnaires: QuestionnaireOverview[] }) => {
  const { t } = useTranslation(['preQualification', 'general']);
  const questionnaireNavigation = useQuestionnaireNavigation();

  const columns = React.useMemo(
    () => [
      {
        id: 'name',
        Header: t('questionnaire', { count: 1 }),
        accessor: 'name',
        Cell: TruncateCell,
        flex: 3,
      },
      {
        id: 'status',
        Header: t('status', { ns: 'general' }),
        accessor: 'status',
        Cell: QuestionnaireStatusCell,
        width: '240px',
      },
      {
        id: 'completion',
        Header: t('questionnaire.completion'),
        accessor: 'completion',
        Cell: withProps(PercentageFieldValue, { decimalPlaces: 0 }),
        width: '140px',
      },
      {
        id: 'version',
        Header: t('version'),
        accessor: 'meta.fromTemplateVersion',
        Cell: QuestionnaireVersionCell,
        width: '140px',
      },
    ],
    [t],
  );

  return (
    <Box sx={{ border: 'lightGray2' }}>
      <CompactTableStyles fixedRowHeight bordered headerBackgroundColor="lightGray3">
        <Table
          columns={columns}
          data={questionnaires}
          onRowClick={questionnaire => questionnaireNavigation.navigateToQuestions(questionnaire._id, { from: 'suppliers' })}
        />
      </CompactTableStyles>
    </Box>
  );
};

const SupplierListItem = ({ supplier }: { supplier: PreQualSupplier }) => {
  const navigate = useNavigate();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { t } = useTranslation('preQualification');
  const [isExpanded, setIsExpanded] = React.useState(false);

  const navigateToSupplier = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      navigate({
        to: companyProfileRoute.to,
        params: { currentCompanyId, targetCompanyId: supplier._id },
        search: { tab: 'activity' },
      });
    },
    [currentCompanyId, navigate, supplier._id],
  );

  return (
    <Box as="li" sx={{ borderBottom: 'lightGray2' }}>
      <Flex height={EXPANDABLE_SUPPLIER_ROW_HEIGHT} p={20} alignItems="center" sx={{ gap: 2 }}>
        <Box flex="0 0 auto">
          <CompanyLogo companyId={supplier._id} size="md" />
        </Box>
        <Truncate flex="0 0 200px" fontSize={2} color="text" fontWeight={400} sx={{ cursor: 'pointer' }} onClick={navigateToSupplier}>
          {supplier.name}
        </Truncate>
        <Text flex="1 1 auto" fontSize={1} color="subtext">
          {t('questionnaireCount', { count: supplier.questionnaires.length })}
        </Text>
        <Box flex="0 0 auto">
          <IconButton
            icon={isExpanded ? 'chevron-up' : 'chevron-down'}
            color="subtext"
            fontSize={3}
            onClick={() => setIsExpanded(isExpanded => !isExpanded)}
          />
        </Box>
      </Flex>
      {isExpanded && (
        <Box p="0 20px 20px" width="858px">
          <QuestionnairesTable questionnaires={supplier.questionnaires} />
        </Box>
      )}
    </Box>
  );
};

export const SuppliersList = ({ suppliers, ...props }: BoxProps & { suppliers: PreQualSupplier[] }) => {
  return (
    <Box as="ul" p={0} style={{ listStyle: 'none' }} {...props}>
      {suppliers.map(supplier => (
        <SupplierListItem
          key={supplier._id}
          supplier={supplier}
        />
      ))}
    </Box>
  );
};
