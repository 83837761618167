import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ExchangeType } from '@deepstream/common/rfq-utils';
import { Modal } from '@deepstream/ui-kit/elements/popup/Modal';
import { ErrorPanel } from '../../ui/ErrorMessage';
import { LoadingPanel } from '../../ui/Loading';
import { ExchangeRefetchProvider } from '../../rfx';
import { ExchangeSnapshot } from '../../types';
import { CurrencyCodeProvider } from '../../ui/Currency';
import { SendExchangeReply } from '../../ExchangeModal/useSendExchangeReply';
import { useSendContractExchangeReply } from './useSendContractExchangeReply';
import { ExchangeProvider } from '../../useExchange';
import { ContractExchangeModalContent } from './ContractExchangeModalContent';
import { ContractSectionProvider, useContractData } from './contract';
import { useContractExchange } from './useContract';
import { ContractExchangeModalContentNew } from './ContractExchangeModalContentNew';
import { PageFooter } from '../../ExchangeModal/PageFooter';
import { PageFooterBidAutoAdvance } from '../../ExchangeModal/PageFooterBidAutoAdvance';
import { useTrackActiveSession } from '../ActiveSession/ActiveSessionContext';
import { useCurrentCompanyId } from '../../currentCompanyId';

export const ContractExchangeModal = React.memo(({
  close,
  exchangeId,
  isOpen,
  showExchangeSwitcher,
  showFooter,
  FooterAutoAdvancer,
  FooterProgress,
  onUpdate,
}: {
  exchangeId?: string;
  isOpen: boolean;
  showExchangeSwitcher?: boolean;
  showFooter?: boolean;
  FooterAutoAdvancer: React.FC<{
    exchangeId: string;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  }>
  FooterProgress?: React.FC;
  close: () => void;
  onUpdate?: (exchange: ExchangeSnapshot) => void;
}) => {
  const { t } = useTranslation('contracts');
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { _id: contractId, sectionById, pageById, status } = useContractData();
  const { data: exchange, refetch, error, isLoading } = useContractExchange({
    // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
    exchangeId,
  });
  const section = sectionById[exchange?.def.sectionId || ''];
  const isSender = currentCompanyId !== exchange?.recipientId;

  // @ts-expect-error ts(2345) FIXME: Argument of type '{ type: "entity"; entityId: string; entityType: "contract"; sectionId: string | undefined; sectionType: SectionType; sectionName: string; pageId: string; pageType: PageType | undefined; ... 5 more ...; scope: ("live" | ... 2 more ... | "exchangeModal")[]; } | null' is not assignable to parameter of type 'SegmentContext'.
  useTrackActiveSession(React.useMemo(() => !isOpen ? null : ({
    type: 'entity',
    entityId: contractId,
    entityType: 'contract',
    sectionId: exchange?.def.sectionId,
    sectionType: section?.type,
    sectionName: section?.name,
    pageId: section?.pageId,
    pageType: pageById[section?.pageId || '']?.type,
    pageName: pageById[section?.pageId || '']?.name,
    exchangeId,
    exchangeType: exchange?.def.type,
    contractStatus: status,
    recipientId: exchange?.recipientId,
    scope: [isSender ? 'sender' : 'recipient', 'live', 'exchangeModal'],
  }),
    [
      isOpen,
      contractId,
      exchange?.def.sectionId,
      exchange?.def.type,
      exchange?.recipientId,
      section?.type,
      section?.name,
      section?.pageId,
      pageById,
      exchangeId,
      status,
      isSender,
    ]));

  const refetchExchange = React.useCallback(async () => {
    const { data: exchange, isSuccess } = await refetch();

    if (exchange && isSuccess && onUpdate) {
      onUpdate(exchange);
    }
  }, [onUpdate, refetch]);

  const sendExchangeReplyMutation = useSendContractExchangeReply({
    contractId,
    // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
    exchangeId: exchange?._id,
  });

  const shouldShowNewModal = exchange?.def.type !== ExchangeType.LEGACY_CONTRACT;

  return (
    <>
      <Modal
        shouldCloseOnEsc
        onRequestClose={close}
        isOpen={isOpen}
        panelStyle={{
          margin: 'auto',
          maxWidth: '90%',
          width: shouldShowNewModal ? 1400 : 910,
        }}
      >
        {isLoading ? (
          <LoadingPanel />
        ) : error ? (
          <ErrorPanel error={t('errors.getExchange')} />
        ) : exchange ? (
          <SendExchangeReply.Provider value={sendExchangeReplyMutation}>
            <ContractSectionProvider section={section}>
              <ExchangeRefetchProvider refetch={refetchExchange}>
                <ExchangeProvider exchange={exchange}>
                  <CurrencyCodeProvider code={(exchange as any).currency ?? ''}>
                    {shouldShowNewModal ? (
                      <ContractExchangeModalContentNew
                        showExchangeSwitcher={showExchangeSwitcher}
                        close={close}
                        showFooter={showFooter}
                      />
                    ) : (
                      <ContractExchangeModalContent
                        close={close}
                        showExchangeSwitcher={showExchangeSwitcher}
                        showFooter={showFooter}
                      />
                    )}
                  </CurrencyCodeProvider>
                </ExchangeProvider>
              </ExchangeRefetchProvider>
            </ContractSectionProvider>
          </SendExchangeReply.Provider>
        ) : (
          null
        )}
      </Modal>
      {showFooter && isOpen ? (
        <PageFooter
          // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
          exchangeId={exchangeId}
          autoAdvance={FooterAutoAdvancer ? (
            <PageFooterBidAutoAdvance
              // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
              exchangeId={exchangeId}
              contextId={contractId}
              AutoAdvancer={FooterAutoAdvancer}
            />
          ) : (
            null
          )}
          progress={FooterProgress ? (
            <FooterProgress />
          ) : (
            null
          )}
        />
      ) : (
        null
      )}
    </>
  );
});
