import { useEffect } from 'react';
import { Text, Box } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { useTracking } from '../../useTracking';

export const UpgradeToUnlockPreQualDialog = ({
  isOpen,
  onCancel,
}: {
  isOpen: boolean;
  onCancel: () => void;
}) => {
  const intercom = useIntercom();
  const { t } = useTranslation(['preQualification', 'general']);
  const { trackAccessRestricted, trackInformationRequested } = useTracking();

  useEffect(() => {
    if (isOpen) {
      trackAccessRestricted('preQualification');
    }
  }, [isOpen, trackAccessRestricted]);

  return (
    <Dialog
      style={{ content: { width: '800px' } }}
      heading={t('contactToUpgrade', { ns: 'general' })}
      shouldCloseOnEsc
      body={(
        <Box fontSize={4} lineHeight={1.4} p={3}>
          <Text as="div" mb={3} sx={{ whiteSpace: 'pre' }}>
            {t('dialog.unlockPreQual.body')}
          </Text>
        </Box>
      )}
      okButtonText={t('dialog.unlockPreQual.okButtonText')}
      isOpen={isOpen}
      onCancel={onCancel}
      onOk={() => {
        trackInformationRequested('preQualification');
        intercom.show();
        intercom.trackEvent('preQualification:modal:upgrade');
        onCancel();
      }}
      showCloseIcon
      showCancelButton={false}
    />
  );
};
