import { useMemo } from 'react';
import { StageApprovalExchangeSnapshot } from '../../types';

export const useCurrentUserApproval = (
  approvalRequest: StageApprovalExchangeSnapshot,
  currentUserId: string,
) => {
  return useMemo(() => {
    const approval = approvalRequest.approvals.find(
      (approval) => approval.userId === currentUserId,
    );
    const pendingApprovals = approvalRequest.approvals.filter(
      (approval) => approval.status === 'pending',
    );
    const isLastPendingApproval = pendingApprovals.length === 1 && approval?.status === 'pending';
    return {
      approval,
      isLastPendingApproval,
    };
  }, [approvalRequest, currentUserId]);
};
