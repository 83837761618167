import { map, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { Page, PageType } from '@deepstream/common/rfq-utils';

import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';

import { usePageExchanges, useVisiblePages } from './utils';

const ImportDetailsPageEntry = ({
  page,
  onClick,
  selectedPageId,
}: {
  page: Page;
  onClick: (id: string) => void;
  selectedPageId: string;
}) => {
  const { t } = useTranslation('contracts');
  const { exchangesIds, selectedIds } = usePageExchanges(page._id);

  return (
    <Box
      key={page._id}
      as="span"
      sx={{
        display: 'block',
        borderBottom: 'lightGray2',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: selectedPageId === page._id ? 'rgba(52, 152, 219, 0.1)' : 'transparent',
        padding: '15px 15px 15px 30px',
      }}
      onClick={() => onClick(page._id)}
    >
      <Truncate>
        <Text fontWeight={500}>{page.name}</Text>
      </Truncate>
      <Text fontSize={1} color="subtext">
        {t('details.importRequestContent.pageItemsSelected', {
          count: selectedIds.length,
          total: exchangesIds.length,
        })}
      </Text>
    </Box>
  );
};

export const ImportDetailsPagesList = ({
  selectedPageId,
  onPageChange,
}: {
  selectedPageId: string;
  onPageChange: (id: string) => void;
}) => {
  const pages = useVisiblePages();
  const sortedPages = sortBy(pages, (page) => page.type === PageType.AUCTION ? 1 : 0);

  return (
    <Box>
      {map(sortedPages, (page) => (
        <ImportDetailsPageEntry
          key={page._id}
          page={page}
          onClick={onPageChange}
          selectedPageId={selectedPageId}
        />
      ))}
    </Box>
  );
};
