import { useTranslation } from 'react-i18next';
import { UserProfile } from './UserProfile';
import * as Layout from '../../Layout';

export const UserProfilePageContent = () => {
  const { t } = useTranslation();

  return (
    <Layout.BasicHeader heading={t('pageHeader.userSettings')} icon="user">
      <UserProfile />
    </Layout.BasicHeader>
  );
};
