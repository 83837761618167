import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { useNavigate } from '@tanstack/react-router';
import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';
import { IconTextButton } from '@deepstream/ui-kit/elements/button/IconTextButton';
import { EllipsisMenu, EditMenuItem, DeleteMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { useApi, wrap } from '../../../api';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useMutation } from '../../../useMutation';
import { useToaster } from '../../../toast';
import { useModalState } from '../../../ui/useModalState';
import { DeleteTemplateDialog } from '../../../DeleteTemplateDialog';
import { waitForUnlock } from '../../../useWaitForUnlock';
import { useGlobalProcessing } from '../../../GlobalProcessingProvider';
import { requestEditSummaryRoute, requestsIndexRoute, templateSummaryRoute } from '../../../AppRouting';
import { RequestsTab } from '../../../requestsConstants';

export const PreviewControls = ({
  templateId,
}: {
  templateId: string;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const toaster = useToaster();
  const deleteTemplateModal = useModalState();
  const { setProcessingContext } = useGlobalProcessing();
  const navigate = useNavigate();

  const navigateToTemplates = () => {
    navigate({
      to: requestsIndexRoute.to,
      params: { currentCompanyId },
      search: { tab: RequestsTab.templates },
    });
  };

  const navigateToEditTemplate = () => {
    navigate({
      to: templateSummaryRoute.to,
      params: { currentCompanyId, rfqId: templateId },
    });
  };

  const { data: template } = useQuery(
    ['template', { currentCompanyId, templateId }],
    wrap(api.getTemplate),
  );

  const [createRequestFromTemplate, { isLoading }] = useMutation(
    async ({ currentCompanyId, templateId }) => {
      const rfqId = await api.createEventlessRequestFromTemplate({ currentCompanyId, templateId });

      await waitForUnlock({
        command: () => api.addRequestCreatedEventFromTemplate({
          currentCompanyId,
          rfqId,
          templateId,
        }),
        getLockState: () => api.getRfqLockState({
          currentCompanyId,
          rfqId,
        }),
        // @ts-expect-error ts(2322) FIXME: Type '((isProcessing: ProcessingContext) => void) | undefined' is not assignable to type '((processingContext: ProcessingContext | null) => void) | undefined'.
        setIsProcessingModalVisible: setProcessingContext,
      });

      return rfqId;
    },
    {
      onSuccess: callAll(
        requestId => {
          navigate({
            to: requestEditSummaryRoute.to,
            params: { currentCompanyId, rfqId: requestId },
          });
        },
        () => {
          toaster.success(t(
            'requests.toaster.createRequestFromTemplate.success',
            { name: template?.meta.name },
          ));
        },
      ),
      onError: () => toaster.error(t('requests.toaster.createRequestFromTemplate.failed')),
    },
  );

  return template ? (
    <>
      <Flex width="100%" justifyContent="space-between" alignItems="center" mb={3}>
        <IconTextButton icon="chevron-left" mb="3px" fontSize={1} onClick={navigateToTemplates}>
          {t('request.backToTemplates')}
        </IconTextButton>

        <ButtonGroup marginBetween="1px">
          <Button
            type="button"
            variant="secondary"
            iconLeft="plus"
            disabled={isLoading}
            onClick={() => createRequestFromTemplate({
              currentCompanyId,
              templateId,
            })}
          >
            {t('requests.newRequest')}
          </Button>
          <EllipsisMenu variant="secondary" menuZIndex={202}>
            <EditMenuItem onSelect={navigateToEditTemplate} />
            <DeleteMenuItem
              onSelect={deleteTemplateModal.open}
              disabled={isLoading}
            />
          </EllipsisMenu>
        </ButtonGroup>
      </Flex>
      <DeleteTemplateDialog
        {...deleteTemplateModal}
        rfqId={template._id}
        rfqName={template.meta.name}
        onSuccess={navigateToTemplates}
        onError={deleteTemplateModal.close}
      />
    </>
  ) : (
    null
  );
};
