import { useCallback } from 'react';
import { clone, compact, isEmpty, keyBy, set, uniq } from 'lodash';
import { LineItemExchangeDefinition, isDefinitionField } from '@deepstream/common/rfq-utils';
import { parseCellValue } from './utils';
import { useApi } from '../../api';

export const useAddCsvDataToExchangeDefs = () => {
  const api = useApi();

  return useCallback(async ({
    rows,
    data,
    affectedColumns,
  }) => {
    let validCellCount = 0;

    const postProcessingItems = [];

    const updatedRows = rows.map((exchangeDef, exchangeDefIndex) => {
      if (exchangeDef.isObsolete) {
        return exchangeDef;
      }

      const rowData = data[exchangeDefIndex] ?? [];
      const updatedExchangeDef = { ...exchangeDef };

      affectedColumns.forEach((column, index) => {
        const { disabled, isDisabled } = column.original;
        const field = exchangeDef.fields[column.original._id];

        if (!field || !isDefinitionField(field) || disabled || isDisabled?.(index)) {
          return;
        }

        const rawValue = rowData[index];

        const {
          canInsertValue,
          cellValue,
          isInputValid,
        } = parseCellValue(rawValue, field);

        if (canInsertValue) {
          set(updatedExchangeDef, field.source.key, cellValue);

          if (field.type === 'unspscCode' && isInputValid && cellValue) {
            // @ts-expect-error ts(2345) FIXME: Argument of type '{ field: DefinitionFieldConfig; value: string | number; rawValue: any; exchangeDefIndex: any; }' is not assignable to parameter of type 'never'.
            postProcessingItems.push({
              field,
              value: cellValue,
              rawValue,
              exchangeDefIndex,
            });
          }
        }

        if (isInputValid) {
          validCellCount += 1;
        }
      });

      return updatedExchangeDef;
    });

    if (!isEmpty(postProcessingItems)) {
      const codes = uniq(compact(
        // @ts-expect-error ts(2339) FIXME: Property 'value' does not exist on type 'never'.
        postProcessingItems.map(item => item.value),
      ));

      let products;

      try {
        products = await api.searchProducts({ codes });
      } catch (err) {
        products = [];
      }

      const productById = keyBy(products, '_id');

      for (const postProcessingItem of postProcessingItems) {
        const { field, value, exchangeDefIndex } = postProcessingItem;
        const product = productById[value];

        if (product) {
          (updatedRows[exchangeDefIndex] as LineItemExchangeDefinition).productOrService = clone(product);
        } else {
          // @ts-expect-error ts(2339) FIXME: Property 'source' does not exist on type 'never'.
          const sourceKey = field.source.key;
          updatedRows[exchangeDefIndex][sourceKey] = null;
          validCellCount -= 1;
        }
      }
    }

    return {
      updatedRows,
      validCellCount,
    };
  }, [api]);
};
