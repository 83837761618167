import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';

import { LabeledValue } from './draft/LabeledValue';

type CompaniesDetailsProps = {
  externalSystemName: string;
  companies: any;
};

export const UnmappedExternalSystemCompanies = ({ externalSystemName, companies }: CompaniesDetailsProps) => {
  const { t } = useTranslation('integration');

  return (
    <Flex alignItems="center" style={{ overflow: 'hidden' }}>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <LabeledValue
          label={t('externalSystem')}
          value={<Text fontWeight={400}>{externalSystemName}</Text>}
          align="left"
          mb={16}
        />
        <LabeledValue
          label={t('unmappedSuppliers')}
          value={
            <Box as="ul" sx={{ listStyle: 'inside', padding: 1, margin: 0, fontWeight: 400 }}>
              {companies.map((company) => (
                <li key={company._id}>{company.name}</li>
              ))}
            </Box>
          }
          align="left"
        />
      </Flex>
    </Flex>
  );
};
