import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import { LiveSettings } from '@deepstream/common/rfq-utils';
import { callAll } from '@deepstream/utils/callAll';
import { ModalForm } from '../../ModalForm';
import { useApi } from '../../api';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { SwitchField } from '../../form/SwitchField';
import { useToaster } from '../../toast';
import { useMutation } from '../../useMutation';
import { useLiveRfqStructureQueryKey, useRfqId } from '../../useRfq';
import { useWaitForRfqUnlock } from '../../useWaitForUnlock';
import * as rfx from '../../rfx';

export const ClarificationUploadsConfigModal = ({
  isOpen,
  onCancel,
  onSuccess = noop,
  onError = noop,
} : {
  isOpen: boolean;
  onCancel: any;
  onError?: any;
  onSuccess?: any;
}) => {
  const { t } = useTranslation('translation');
  const toaster = useToaster();
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId();
  const api = useApi();
  // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string | undefined'.
  const rfqQueryKey = useLiveRfqStructureQueryKey({ rfqId, currentCompanyId });
  const queryClient = useQueryClient();
  const waitForRfqUnlock = useWaitForRfqUnlock();
  const { settings } = rfx.useStructure();

  const [updateRequestLiveSettings] = useMutation(
    (payload: {
      companyId: string;
      rfqId: string;
      settings: Partial<LiveSettings>;
    }) => waitForRfqUnlock({
      command: () => api.updateRequestLiveSettings(payload),
    }),
    {
      onSuccess,
      onError: callAll(
        () => toaster.error(t('request.clarifications.newClarificationFailed')),
        onError,
      ),
      onSettled: () => queryClient.invalidateQueries(rfqQueryKey),
    },
  );

  return (
    <ModalForm
      heading={t('request.clarifications.editSupplierFileUploads')}
      initialValues={{
        allowClarificationAttachments: Boolean(settings.allowClarificationAttachments),
      }}
      validationSchema={yup.object().shape({
        allowClarificationAttachments: yup
          .boolean()
          .required(t('general.required')),
      })}
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={({ allowClarificationAttachments }) =>
        updateRequestLiveSettings({
          // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
          companyId: currentCompanyId,
          rfqId,
          settings: {
            allowClarificationAttachments,
          },
        })
      }
      submitLabel={t('saveChanges', { ns: 'general' })}
      style={{ content: { width: '500px' } }}
    >
      <SwitchField
        name="allowClarificationAttachments"
        hideLabel
        aria-label={`${t('request.lot_other')} - ${t('general.enabled')}?`}
        checkedText={t('general.enabled')}
        uncheckedText={t('general.disabled')}
        checkedIcon={false}
        uncheckedIcon={false}
        width={42}
      />
      <Text>{t('request.clarifications.supplierFileUploadsDescription.paragraphOne')}</Text>
      <Text>{t('request.clarifications.supplierFileUploadsDescription.paragraphTwo')}</Text>
    </ModalForm>
  );
};
