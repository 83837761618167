import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { InlineButton } from '@deepstream/ui-kit/elements/button/InlineButton';
import { Bold } from '../../../Bold';
import { useLiveContractNavigation, useDraftContractNavigation } from '../../../appNavigation';
import { useContractState } from '../contract';

export const NoPageAccess = () => {
  const { t } = useTranslation('contracts');
  const draftNavigation = useDraftContractNavigation();
  const liveNavigation = useLiveContractNavigation();
  // @ts-expect-error ts(2339) FIXME: Property 'isLive' does not exist on type 'ContractStateContextType | undefined'.
  const { isLive } = useContractState();

  const navigateToTeamTab = useCallback(
    () => {
      if (isLive) {
        liveNavigation.navigateToTeam();
      } else {
        draftNavigation.navigateToTeam();
      }
    },
    [liveNavigation, draftNavigation, isLive],
  );

  return (
    <>
      <Bold as="div" fontSize={5} mt={1} mb={3}>
        <Icon icon="eye-slash" mr={2} />
        {t('permissions.noPageAccess.heading')}
      </Bold>
      <Text mb={1}>
        {t('permissions.noPageAccess.body')}
      </Text>
      <Text>
        <Trans i18nKey="permissions.noPageAccess.helper" ns="contracts">
          Permissions can be updated by a contract owner on the <InlineButton onClick={navigateToTeamTab}>Team</InlineButton> tab.
        </Trans>
      </Text>
    </>
  );
};
