import { TabPanels } from '@reach/tabs';
import { Text, Box } from 'rebass/styled-components';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelHeader, PanelHeading } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { TabPanel } from '../../ui/TabPanel';
import { Bold } from '../../Bold';

export const MockedMainContent = () => (
  <TabPanels>
    <TabPanel key="questions">
      <Stack gap={3}>
        <Panel>
          <PanelHeader heading="Questions">
            <Button small iconLeft="plus">
              New question
            </Button>
          </PanelHeader>
        </Panel>
        <Panel>
          <PanelHeading>
            General (3)
          </PanelHeading>
          <Box p="0 20px 20px">
            <Stack gap={3}>
              <Panel padded pt={0}>
                <Bold>What countries do you operate in?</Bold>
                <Text mt={2}>Short answer</Text>
              </Panel>
              <Panel padded pt={0}>
                <Bold>What is your HQ location?</Bold>
                <Text mt={2}>Location / Address</Text>
              </Panel>
              <Panel padded pt={0}>
                <Bold>Are you compliant with the Modern Slavery Act?</Bold>
                <Text mt={2}>Document </Text>
              </Panel>
            </Stack>
          </Box>
        </Panel>
      </Stack>
    </TabPanel>
  </TabPanels>
);
