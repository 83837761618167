import { useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import { BidIntentionStatus, ExchangeType, Live, LotIntentionStatus, SectionType, getTagFromStageId, isDocumentRequestExchange } from '@deepstream/common/rfq-utils';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { compact, first, isEmpty, last, some } from 'lodash';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import * as lotPagesLayout from '../Live/lotPagesLayout';
import * as rfx from '../../../rfx';
import { ExcelBidFlowModal } from '../../Excel/ExcelBidFlowModal';
import { useSystemFeatureFlags } from '../../../systemFeatureFlags';
import { useModalState } from '../../../ui/useModalState';
import { useRecipientId, useRfqId } from '../../../useRfq';
import { useToaster } from '../../../toast';
import { useMutation } from '../../../useMutation';
import { useApi } from '../../../api';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { AsyncFileDownloadDialog } from '../../../AsyncFileDownloadDialog';

export const RequestRecipientToolsSection = () => {
  const { t } = useTranslation('translation');
  const rfqId = useRfqId();
  const api = useApi();
  const systemFeatureFlags = useSystemFeatureFlags();
  const excelBidModalState = useModalState();
  const recipientId = useRecipientId();
  const { sectionById, exchangeDefById, pages } = rfx.useStructure<Live>();
  const bid = rfx.useBid();
  const stageId = rfx.useStageId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const isAnyEnteredStageActive = rfx.useIsAnyEnteredStageActive();
  const permissionsByPageId = rfx.usePagesPermissions();
  const bidIntentionStatus = rfx.useBidIntentionStatus();
  const asyncFileDownloadDialog = useModalState();
  const toaster = useToaster();

  const [requestSenderFilesZip] = useMutation(
    api.requestSenderFilesZip,
    {
      onSuccess: asyncFileDownloadDialog.open,
      onError: () => toaster.error(t('request.download.toaster.downloadError')),
    },
  );

  const toolsConfig = useMemo(() => {
    const hasActivatedStage = bid.activatedStageIds.includes(stageId);

    const canSubmitLineItems = (
      hasActivatedStage &&
      bidIntentionStatus === BidIntentionStatus.BIDDING &&
      systemFeatureFlags?.downloadBidAsXlsxEnabled &&
      some(pages, page => {
        if (!permissionsByPageId[page._id]?.canRespond) {
          return false;
        }

        return some(page.sections, sectionId => {
          const section = sectionById[sectionId];

          if (section?.type !== SectionType.LINE_ITEMS || section.isObsolete) {
            return false;
          }

          const lotId = first(section.lotIds);

          if (lotId && bid.intentionStatusByLotId[lotId] !== LotIntentionStatus.BIDDING) {
            return false;
          }

          return some(section.exchangeDefIds, exchangeId => {
            const exchangeDef = exchangeDefById[exchangeId];

            if (exchangeDef?.type !== ExchangeType.LINE_ITEM || exchangeDef.isObsolete) {
              return false;
            }

            // we don't support submitting supplier-provided line items via Excel
            if (exchangeDef.publisherId === recipientId) {
              return false;
            }

            if (section.responseTagConfig) {
              // buyer-provided line items in multi stage response sections can
              // only be submitted in the latest activated stage
              return (
                // @ts-expect-error ts(2345) FIXME: Argument of type 'string | null' is not assignable to parameter of type 'string'.
                section.responseTagConfig.tags.includes(getTagFromStageId(stageId)) &&
                stageId === last(bid.activatedStageIds)
              );
            } else {
              // single response line items can be submitted in their first stage
              return first(section.stages || exchangeDef.stages) === stageId;
            }
          });
        });
      })
    );

    const canDownloadDocuments = some(pages, page => {
      if (!permissionsByPageId[page._id]?.canRead) {
        return false;
      }

      return some(page.sections, sectionId => {
        const section = sectionById[sectionId];

        if (section?.type !== SectionType.DOCUMENT || section.isObsolete) {
          return false;
        }

        const lotId = first(section.lotIds);

        if (lotId && bid.intentionStatusByLotId[lotId] !== LotIntentionStatus.BIDDING) {
          return false;
        }

        return some(section.exchangeDefIds, exchangeId => {
          const exchangeDef = exchangeDefById[exchangeId];

          if (!exchangeDef || isDocumentRequestExchange(exchangeDef.type) || exchangeDef.isObsolete) {
            return false;
          }

          if (exchangeDef.publisherId === recipientId) {
            return false;
          }

          const exchangeDefStageId = first(section.stages || exchangeDef.stages);

          return !exchangeDefStageId || exchangeDefStageId === stageId;
        });
      });
    });

    return compact([
      canSubmitLineItems
        ? {
          id: 'submitLineItems',
          action: () => excelBidModalState.open(),
          iconConfig: {
            icon: 'file-spreadsheet' as const,
            regular: true,
          },
          isDisabled: !isAnyEnteredStageActive,
          disabledReason: t('request.receivedTools.submitLineItems.disabledReason'),
        }
        : null,
      canDownloadDocuments && {
        id: 'downloadBuyerFiles',
        action: () => {
          requestSenderFilesZip({
            currentCompanyId,
            rfqId,
            sectionId: null,
            stageId,
          })
          .catch(error => {
            Sentry.captureException(error);
          });
        },
        iconConfig: {
          icon: 'file-zipper' as const,
          regular: true,
        },
      },
    ]);
  }, [
    bid,
    stageId,
    bidIntentionStatus,
    systemFeatureFlags,
    pages,
    isAnyEnteredStageActive,
    t,
    permissionsByPageId,
    sectionById,
    exchangeDefById,
    recipientId,
    excelBidModalState,
    requestSenderFilesZip,
    currentCompanyId,
    rfqId,
  ]);

  return isEmpty(toolsConfig) ? (
    null
  ) : (
    <>
      <lotPagesLayout.Section heading={t('request.receivedTools.tools')}>
        <lotPagesLayout.OrderedListContainer>
          {toolsConfig.map(({ id, action, iconConfig, isDisabled, disabledReason }) => (
            <lotPagesLayout.TitledListItem
              key={id}
              title={
                <>
                  <Icon mr={2} {...iconConfig} />
                  {t(`request.receivedTools.${id}.title`)}
                </>
              }
              body={
                <Flex flexDirection="column" sx={{ gap: 2 }}>
                  <Text>
                    {t(`request.receivedTools.${id}.description`)}
                  </Text>
                  {isDisabled && <IconText icon="info-circle" color="subtext" text={disabledReason} />}
                </Flex>
              }
              pt={3}
              pb={3}
              contentRight={
                <Flex flexDirection="column" mt={1} ml={4}>
                  <Button
                    small
                    variant="secondary"
                    onClick={action}
                    disabled={isDisabled}
                  >
                    {t(`request.receivedTools.${id}.buttonText`)}
                  </Button>
                </Flex>
              }
            />
          ))}
        </lotPagesLayout.OrderedListContainer>
      </lotPagesLayout.Section>
      {excelBidModalState.isOpen ? (
        <ExcelBidFlowModal isOpen={excelBidModalState.isOpen} close={excelBidModalState.close} />
      ) : (
        null
      )}
      <AsyncFileDownloadDialog
        isOpen={asyncFileDownloadDialog.isOpen}
        onOk={asyncFileDownloadDialog.close}
      />
    </>
  );
};
