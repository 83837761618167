import * as draft from './draft';
import { LineItemsSectionEditPanel } from './LineItemsSectionEditPanel';
import { LineItemsSectionViewPanel } from './LineItemsSectionViewPanel';

/**
 * Displays a panel for the current line items section
 */
export const LineItemsSectionPanel = () => {
  const isEditing = draft.useIsEditing();

  return isEditing ? (
    <LineItemsSectionEditPanel />
  ) : (
    <LineItemsSectionViewPanel />
  );
};
