import * as Sentry from '@sentry/react';
import { Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { UnexpectedStateError } from '@deepstream/errors';
import { LongTextQuestionExchangeDefinition, QuestionExchangeDefinition, QuestionType } from '@deepstream/common/rfq-utils';

export const LongText = ({
  exchangeDef,
}: {
  exchangeDef: QuestionExchangeDefinition;
}) => {
  const { t } = useTranslation();
  const { questionType } = exchangeDef as LongTextQuestionExchangeDefinition;

  if (questionType !== QuestionType.LONG_TEXT) {
    Sentry.captureException(new UnexpectedStateError('[LongText] Wrong question type passed', { questionType }));
    return null;
  }

  return (
    <Text>{t('request.question.questionType.longText')}</Text>
  );
};
