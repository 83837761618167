// Auto-generated file created by svgr-cli source svgr-config/template.js
// Do not edit
import type { SVGProps } from 'react';

const SvgDraftRequest = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={188}
    height={188}
    fill="none"
    {...props}
  >
    <path
      fill="#F7F9FB"
      stroke="#E2E8EF"
      strokeWidth={0.75}
      d="M185.143 94.024c0 50.035-40.804 90.601-91.143 90.601S2.857 144.059 2.857 94.024C2.857 43.988 43.661 3.422 94 3.422s91.143 40.566 91.143 90.602Z"
    />
    <path
      fill="#F7F9FB"
      stroke="#E2E8EF"
      strokeWidth={0.75}
      d="M94 185.375c50.749 0 91.893-40.897 91.893-91.351S144.749 2.672 94 2.672 2.107 43.57 2.107 94.024 43.251 185.375 94 185.375Z"
    />
    <mask
      id="draft-request_svg__a"
      width={184}
      height={184}
      x={2}
      y={2}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path
        fill="#F9FAFB"
        stroke="#DDE4EE"
        strokeWidth={0.75}
        d="M185.125 93.75c0 50.327-40.798 91.125-91.125 91.125S2.875 144.077 2.875 93.75 43.673 2.625 94 2.625s91.125 40.798 91.125 91.125Z"
      />
    </mask>
    <g mask="url(#draft-request_svg__a)">
      <mask id="draft-request_svg__b" fill="#fff">
        <path
          fillRule="evenodd"
          d="M-4.412 123c-5.295 0-9.588 4.293-9.588 9.588v45.324c0 5.295 4.293 9.588 9.588 9.588H75.13c6.48 0 12.694 2.574 17.276 7.156a1.917 1.917 0 0 0 2.712 0 24.43 24.43 0 0 1 17.276-7.156h80.019c5.295 0 9.588-4.293 9.588-9.588v-45.324c0-5.295-4.293-9.588-9.588-9.588z"
          clipRule="evenodd"
        />
      </mask>
      <path
        fill="#F7F9FB"
        fillRule="evenodd"
        d="M-4.412 123c-5.295 0-9.588 4.293-9.588 9.588v45.324c0 5.295 4.293 9.588 9.588 9.588H75.13c6.48 0 12.694 2.574 17.276 7.156a1.917 1.917 0 0 0 2.712 0 24.43 24.43 0 0 1 17.276-7.156h80.019c5.295 0 9.588-4.293 9.588-9.588v-45.324c0-5.295-4.293-9.588-9.588-9.588z"
        clipRule="evenodd"
      />
      <path
        fill="#DDE4EE"
        d="M-13.372 132.588a8.96 8.96 0 0 1 8.96-8.96v-1.256c-5.642 0-10.215 4.574-10.215 10.216zm0 45.324v-45.324h-1.255v45.324zm8.96 8.961a8.96 8.96 0 0 1-8.96-8.961h-1.255c0 5.642 4.573 10.216 10.215 10.216zm79.541 0H-4.41v1.255h79.54zm19.545 7.339a1.29 1.29 0 0 1-1.825 0l-.888.888a2.545 2.545 0 0 0 3.6 0zm97.738-7.339h-80.019v1.255h80.019zm8.96-8.961a8.96 8.96 0 0 1-8.96 8.961v1.255c5.642 0 10.216-4.574 10.216-10.216zm0-45.324v45.324h1.256v-45.324zm-8.96-8.96a8.96 8.96 0 0 1 8.96 8.96h1.256c0-5.642-4.574-10.216-10.216-10.216zm-196.824 0h196.824v-1.256H-4.412zM95.561 195.1a23.8 23.8 0 0 1 16.832-6.972v-1.255c-6.646 0-13.02 2.64-17.72 7.339zm-20.432-6.972a23.8 23.8 0 0 1 16.832 6.972l.888-.888a25.06 25.06 0 0 0-17.72-7.339z"
        mask="url(#draft-request_svg__b)"
      />
      <path
        fill="#fff"
        stroke="#E2E8EF"
        strokeWidth={0.75}
        d="M42.292 52.125h103.916c3.138 0 5.667 2.487 5.667 5.538v124.174c0 3.051-2.529 5.538-5.667 5.538H42.292c-3.138 0-5.667-2.487-5.667-5.538V57.663c0-3.051 2.53-5.538 5.667-5.538Z"
      />
      <path
        fill="#E2E8EF"
        d="M123.056 84.75a3.7 3.7 0 0 0-.631 0c-4.557-.144-8.175-3.662-8.175-7.991 0-4.42 3.791-8.009 8.5-8.009 4.69 0 8.5 3.59 8.5 8.009-.019 4.33-3.637 7.847-8.194 7.991M112.843 90.456c-4.791 3.194-4.791 8.399 0 11.573 5.443 3.628 14.371 3.628 19.814 0 4.791-3.194 4.791-8.399 0-11.573-5.423-3.608-14.351-3.608-19.814 0M79.696 61.75H47.958c-2.023 0-3.662 1.49-3.662 3.328 0 1.839 1.64 3.329 3.662 3.329h31.738c2.023 0 3.662-1.49 3.662-3.329 0-1.838-1.64-3.328-3.662-3.328M94.07 74.75H47.43c-1.756 0-3.18 1.567-3.18 3.5s1.424 3.5 3.18 3.5h46.64c1.756 0 3.18-1.567 3.18-3.5s-1.424-3.5-3.18-3.5M79.696 90.596H47.958c-2.023 0-3.662 1.242-3.662 2.774s1.64 2.774 3.662 2.774h31.738c2.023 0 3.662-1.242 3.662-2.774s-1.64-2.774-3.662-2.774M96.89 103.75H47.61c-1.856 0-3.36 1.567-3.36 3.5s1.504 3.5 3.36 3.5h49.28c1.856 0 3.36-1.567 3.36-3.5s-1.504-3.5-3.36-3.5M79.696 119.75H47.958c-2.023 0-3.662 1.242-3.662 2.774s1.64 2.773 3.662 2.773h31.738c2.023 0 3.662-1.241 3.662-2.773s-1.64-2.774-3.662-2.774"
      />
      <path
        fill="#E2E8EF"
        d="M107.65 119.75H75.912c-2.022 0-3.662 1.242-3.662 2.774s1.64 2.773 3.662 2.773h31.738c2.023 0 3.662-1.241 3.662-2.773s-1.639-2.774-3.662-2.774"
      />
      <path
        fill="#E2E8EF"
        d="M127.65 119.75H95.912c-2.022 0-3.662 1.242-3.662 2.774s1.64 2.773 3.662 2.773h31.738c2.023 0 3.662-1.241 3.662-2.773s-1.639-2.774-3.662-2.774M101.997 133.064H47.979c-2.034 0-3.683 1.49-3.683 3.328s1.649 3.328 3.683 3.328h54.018c2.034 0 3.683-1.49 3.683-3.328s-1.649-3.328-3.683-3.328"
      />
      <path
        fill="#E2E8EF"
        d="M133.951 133.064H79.933c-2.034 0-3.683 1.49-3.683 3.328s1.649 3.328 3.683 3.328h54.018c2.034 0 3.683-1.49 3.683-3.328s-1.649-3.328-3.683-3.328M79.696 148.904H47.958c-2.023 0-3.662 1.242-3.662 2.773s1.64 2.774 3.662 2.774h31.738c2.023 0 3.662-1.242 3.662-2.774 0-1.531-1.64-2.773-3.662-2.773"
      />
      <path
        fill="#E2E8EF"
        d="M107.65 148.904H75.912c-2.022 0-3.662 1.242-3.662 2.773s1.64 2.774 3.662 2.774h31.738c2.023 0 3.662-1.242 3.662-2.774 0-1.531-1.639-2.773-3.662-2.773"
      />
      <path
        fill="#E2E8EF"
        d="M119.65 148.904H87.912c-2.022 0-3.662 1.242-3.662 2.773s1.64 2.774 3.662 2.774h31.738c2.023 0 3.662-1.242 3.662-2.774 0-1.531-1.639-2.773-3.662-2.773"
      />
      <path
        fill="#E2E8EF"
        d="M139.65 148.904h-31.738c-2.022 0-3.662 1.242-3.662 2.773s1.64 2.774 3.662 2.774h31.738c2.023 0 3.662-1.242 3.662-2.774 0-1.531-1.639-2.773-3.662-2.773M101.997 162.217H47.979c-2.034 0-3.683 1.491-3.683 3.329s1.649 3.328 3.683 3.328h54.018c2.034 0 3.683-1.49 3.683-3.328s-1.649-3.329-3.683-3.329"
      />
    </g>
  </svg>
);
export default SvgDraftRequest;
