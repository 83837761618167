import { ActionType } from '@deepstream/common/rfq-utils';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useApprovalExchange, useApprovalExchangeReply, useApprovalsContext } from '../contract';
import { useContract } from '../useContract';

export const AcceptApprovalButton = ({ label }: { label: string }) => {
  // @ts-expect-error ts(2339) FIXME: Property 'removeFilter' does not exist on type '{ onlyDirtyExchanges: boolean; toggleDirtyExchanges: () => void; removeFilter: () => void; } | null'.
  const { removeFilter } = useApprovalsContext();
  const [acceptApproval, { isLoading }] = useApprovalExchangeReply(ActionType.ACCEPT, {
    onSettled: removeFilter,
  });
  const approvalExchange = useApprovalExchange();
  const { isFetching } = useContract({ scope: 'current' });
  const canAccept = Boolean(approvalExchange.actions.find(action => action.type === ActionType.ACCEPT));

  return (
    <Button
      onClick={acceptApproval}
      variant="success"
      iconLeft="check"
      disabled={isLoading || isFetching || !canAccept}
    >
      {label}
    </Button>
  );
};
