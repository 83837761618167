import { Trans } from 'react-i18next';
import { Text } from 'rebass/styled-components';
import styled from 'styled-components';

const Link = styled.a`
  color: ${props => props.theme.colors.primary};
  text-decoration: underline;
  font-weight: 500;
`;

export const SavingsNotAccurate = ({ onClick }) => {
  return (
    <Text mt="20px">
      <Trans
        i18nKey="request.awardFlow.savingsNotAccurate"
        ns="translation"
        components={{
          a: <Link onClick={onClick} />,
        }}
      />
    </Text>
  );
};
