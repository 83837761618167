import { RfqStatus, requestStatusesConfig } from '@deepstream/common/rfq-utils';
import { Box, Text, Flex, BoxProps } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Bold } from './Bold';

export const RequestStatus = ({ status, ...props }: { status: RfqStatus } & BoxProps) => {
  const { t } = useTranslation();
  const icon = requestStatusesConfig[status]?.icon;

  return (
    <Box {...props}>
      <Text fontSize={1} fontWeight={500} mb={1}>
        {t('general.status')}
      </Text>
      <Flex alignItems="center" fontSize={2}>
        {icon && <Icon fixedWidth color={icon.color} icon={icon.value} mr={1} />}
        <Bold>{t(`request.status.${status}`)}</Bold>
      </Flex>
    </Box>
  );
};
