import * as Layout from '../../Layout';
import { SettingsTab } from './SettingsTab';
import { SupplierListHeader } from './SupplierListHeader';

export const SupplierListSettingsPageContent = () => {
  return (
    <Layout.HeaderAndContent
      header={<SupplierListHeader />}
      content={<SettingsTab />}
      contentPaddingTop="20px"
    />
  );
};
