import * as React from 'react';
import { Panel, PanelProps } from '@deepstream/ui-kit/elements/Panel';
import * as rfx from '../rfx';

export const DraftPanel: React.FC<PanelProps & { panelId: string; suppressBoxShadow?: boolean }> = ({
  panelId,
  suppressBoxShadow,
  ...props
}) => {
  const { editingPanelId } = rfx.useState();

  const isEditing = Boolean(editingPanelId);
  const isEditingThisPanel = panelId === editingPanelId;

  const isEditingAnotherPanel = isEditing && !isEditingThisPanel;

  return (
    <Panel
      sx={{
        opacity: isEditingAnotherPanel ? 0.5 : 1,
        boxShadow: isEditingThisPanel && !suppressBoxShadow ? '0 0 8px 0 rgba(0, 0, 0, 0.3)' : '',
      }}
      {...props}
    />
  );
};
