import * as React from 'react';
import { useDeviceSize } from '../ui/useDeviceSize';
import { LabelConfig, LabelConfigProvider, LabelConfigProviderProps } from '../LabelConfigProvider';

export interface DraftSectionNestedLabelConfigProviderProps extends LabelConfigProviderProps {
  defaultStyle?: React.CSSProperties;
  width?: string;
}

export const DraftSectionNestedLabelConfigProvider =
  ({ defaultStyle, ...props }: DraftSectionNestedLabelConfigProviderProps) => {
    const { isExtraSmall } = useDeviceSize();

    return (
      <LabelConfigProvider
        variant={isExtraSmall ? LabelConfig.ABOVE : LabelConfig.LEFT}
        width="auto"
        style={isExtraSmall ? (
          {}
        ) : (
          {
            default: {
              fontSize: 2,
              position: 'relative',
              color: 'text',
              top: '9px',
              fontWeight: 400,
              ...defaultStyle,
            },
          }
        )}
        gap={isExtraSmall ? 2 : 3}
        {...props}
      />
    );
  };
