import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';

import { QuestionnaireStatus } from '@deepstream/common/preQual';
import { filter, isEmpty, map } from 'lodash';
import { Pictogram } from '@deepstream/ui-kit';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useExchanges, useIsSender, useQuestionnaireData } from './questionnaireUtils';
import { useExchangeNavigation } from '../../../useExchangeModalState';
import { useExchangeColumns } from '../../Exchange/columns';
import { ExchangesGrid } from '../../Request/Live/ExchangesGrid';
import { SwitchFieldBase } from '../../../form/SwitchField';
import { CheckedIcon, UncheckedIcon } from '../../../ui/Switch';
import { QuestionnaireAttachment } from './QuestionnaireAttachment';

export const QuestionsBidSection = () => {
  const { t } = useTranslation(['preQualification', 'general']);
  const columns = useExchangeColumns();
  const exchanges = useExchanges();
  const questionnaire = useQuestionnaireData();
  const { openExchange } = useExchangeNavigation();
  const [showChanges, setShowChanges] = useState(false);
  const isSender = useIsSender();

  const questionnaireAccepted = ![QuestionnaireStatus.SENT, QuestionnaireStatus.DECLINED].includes(questionnaire.status);
  const showDocumentNearExpiry = [
    QuestionnaireStatus.IN_PROGRESS,
    QuestionnaireStatus.PENDING_REVIEW,
    QuestionnaireStatus.APPROVED,
  ].includes(questionnaire.status);

  const questionsColumns = useMemo(
    () => columns.questions({ onlyShowNumberAndDescription: !questionnaireAccepted, showDocumentNearExpiry }),
    [columns, questionnaireAccepted, showDocumentNearExpiry],
  );

  const showSwitch = (
    isSender &&
    questionnaire.status === QuestionnaireStatus.PENDING_REVIEW &&
    questionnaire.exchangeIdsUpdatedSinceLastReview
  );

  const exchangesWithIndexes = useMemo(
    () => map(
      exchanges,
      (exchange, index) => ({
        ...exchange,
        index,
      }),
    ),
    [exchanges],
  );

  const visibleExchanges = useMemo(
    () => showChanges
      ? filter(
        exchangesWithIndexes,
        (exchange) => questionnaire.exchangeIdsUpdatedSinceLastReview?.includes(exchange._id),
      )
      : exchangesWithIndexes,
    [exchangesWithIndexes, showChanges, questionnaire],
  );

  return (
    <Panel>
      <PanelHeader heading={t('question_other')} icon="question">
        {showSwitch ? (
          <Flex alignItems="center" sx={{ gap: '12px' }}>
            <SwitchFieldBase
              value={showChanges}
              onChange={setShowChanges}
              checkedIcon={<CheckedIcon label={t('on', { ns: 'general' })} />}
              uncheckedIcon={<UncheckedIcon label={t('off', { ns: 'general' })} />}
            />
            <Text>
              {t('questionnaire.showChangesOnly')}
            </Text>
          </Flex>
        ) : (
          null
        )}
      </PanelHeader>
      {(questionnaire.instructions || !isEmpty(questionnaire.instructionsAttachments)) && (
        <PanelPadding pt="0">
          <Stack gap={3}>
            <Stack gap={1}>
              <Text fontSize={2} fontWeight={500} color="lightNavy">
                {t('questionnaireTemplate.instructions')}
              </Text>
              <Text fontSize={1} color="darkGray2">
                {questionnaire.instructions}
              </Text>
            </Stack>
            {questionnaire.instructionsAttachments.map(attachment => (
              <QuestionnaireAttachment key={attachment._id} attachment={attachment} />
            ))}
          </Stack>
        </PanelPadding>
      )}
      <PanelDivider />
      <Box width={!questionnaireAccepted ? '545px' : undefined}>
        {showChanges && isEmpty(visibleExchanges) ? (
          <PanelPadding>
            <Pictogram
              variant="empty-state"
              label={t('questionnaire.emptyState.noChanges')}
            />
          </PanelPadding>
        ) : (
          <PanelPadding>
            <ExchangesGrid
              columns={questionsColumns}
              exchanges={visibleExchanges as any}
              onRowClick={questionnaireAccepted ? openExchange : undefined}
            />
          </PanelPadding>
        )}
      </Box>
    </Panel>
  );
};
