import { Company, ExchangeDefinition, Live, RfxSection } from '@deepstream/common/rfq-utils';
import { Form, Formik } from 'formik';
import { assign, compact, filter, flatMap, flatten, get, intersection, isEmpty, map, mapValues, partition, pick, uniq } from 'lodash';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { Box, Text } from 'rebass/styled-components';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { CancelButton, Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { useApi } from '../../../api';
import { CheckboxField, CheckboxFieldArray, Label } from '../../../form/Field';
import { useToaster } from '../../../toast';
import { useMutation } from '../../../useMutation';
import { useLiveRfqStructureQueryKey, useRfqId } from '../../../useRfq';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import * as rfx from '../../../rfx';
import { useWaitForRfqUnlock } from '../../../useWaitForUnlock';
import { StatsByRecipientId } from '../../../types';

export const TextList = ({
  label,
  items,
  value,
}: {
  label: string;
  items: { _id: string }[];
  value: string;
}) => (
  <Box>
    <Text mb={1} sx={{ fontWeight: 500, fontSize: 1 }}>{label}</Text>
    <ul style={{ margin: 0 }}>
      {items.map(item => (
        <li key={item._id}>{get(item, value)}</li>
      ))}
    </ul>
  </Box>
);

const getExchangeDefs = (
  sections: RfxSection<'live'>[],
  unlockableExchangeIdsBySectionId: Record<string, string[]>,
  exchangeDefById: Record<string, ExchangeDefinition>,
): ExchangeDefinition[] => {
  const sectionIds = sections.map(section => section._id);

  const exchangeIds = flatten(Object.values(pick(unlockableExchangeIdsBySectionId, sectionIds)));

  return compact(exchangeIds.map(exchangeId => exchangeDefById[exchangeId]));
};

export const UnlockBidsModal = ({
  selectedRecipients,
  statsByRecipientId,
  onCancel,
  onSave,
  ...props
}: {
  selectedRecipients: Company[];
  statsByRecipientId: StatsByRecipientId;
  onCancel: () => void;
  onSave: () => void;
} & ModalProps) => {
  const { t } = useTranslation();
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqId = useRfqId({ required: true });
  const liveStructureQueryKey = useLiveRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
  });
  const { sectionById, exchangeDefById } = rfx.useStructure<Live>();
  const waitForRfqUnlock = useWaitForRfqUnlock();

  const {
    filteredRecipients,
    unlockableExchangeIdsBySectionId,
    selectableSections,
  } = useMemo(() => {
    const selectedRecipientIds = selectedRecipients.map(recipient => recipient._id);
    const exchangeRecipientIds = Object.keys(statsByRecipientId)
      .filter(recipientId => !isEmpty(statsByRecipientId[recipientId].unlockableExchangeIdsBySectionId));

    const recipientIds = intersection(selectedRecipientIds, exchangeRecipientIds);

    const filteredRecipients = filter(
      selectedRecipients,
      recipient => recipientIds.includes(recipient._id),
    );

    const unlockableItemsByFilteredRecipientId = mapValues(
      pick(statsByRecipientId, recipientIds),
      stats => stats.unlockableExchangeIdsBySectionId,
    );

    const sectionIds = uniq(
      flatMap(
        unlockableItemsByFilteredRecipientId,
        unlockableItemsBySectionId => Object.keys(unlockableItemsBySectionId),
      ),
    );

    return {
      filteredRecipients,
      unlockableExchangeIdsBySectionId: assign(
        {},
        ...Object.values(unlockableItemsByFilteredRecipientId),
      ),
      selectableSections: map(
        pick(sectionById, sectionIds),
        section => ({ value: section, label: section.name }),
      ),
    };
  }, [selectedRecipients, sectionById, statsByRecipientId]);

  const [unlockItems] = useMutation(
    (payload) => waitForRfqUnlock({
      command: () => api.unlockItems(payload),
    }),
    {
      onSuccess: () => toaster.success(t('request.suppliersTable.toaster.itemsUnlockedSuccess')),
      onError: () => toaster.error(t('request.suppliersTable.toaster.itemsUnlockedError')),
      onSettled: callAll(
        () => queryClient.invalidateQueries(liveStructureQueryKey),
        () => queryClient.invalidateQueries(['allExchanges', { rfqId, currentCompanyId }]),
        () => queryClient.invalidateQueries(['exchanges', { rfqId, currentCompanyId }]),
        () => queryClient.invalidateQueries(['statsByRecipientId', { rfqId, currentCompanyId }]),
      ),
    },
  );

  return (
    <Modal
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={onCancel}
      style={{ content: { maxWidth: '500px' } }}
      {...props}
    >
      <Formik
        initialValues={{
          hasConfirmed: false,
          selectedSections: [],
        }}
        validationSchema={
          yup.object().shape({
            selectedSections: yup.array().min(1, t('request.suppliersTable.selectAtLeastOneItem')),
            hasConfirmed: yup.boolean().required(t('general.required')),
          })
        }
        onSubmit={async ({ selectedSections }, { setSubmitting }) => {
          const [sectionsWithLocking, sectionsWithoutLocking] = partition(
            selectedSections,
            // @ts-expect-error ts(2339) FIXME: Property 'locking' does not exist on type 'never'.
            section => section.locking,
          );

          const lockedExchangeDefs = getExchangeDefs(
            sectionsWithoutLocking,
            unlockableExchangeIdsBySectionId,
            exchangeDefById,
          );

          try {
            await unlockItems({
              companyId: currentCompanyId,
              rfqId,
              recipientIds: filteredRecipients.map(recipient => recipient._id),
              // @ts-expect-error ts(2339) FIXME: Property '_id' does not exist on type 'never'.
              sectionIds: sectionsWithLocking.map(section => section._id),
              exchangeIds: lockedExchangeDefs.map(exchange => exchange._id),
            });
            onSave();
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, dirty, values: { hasConfirmed, selectedSections } }) => {
          const [sectionsWithLocking, sectionsWithoutLocking] = partition(
            selectedSections,
            // @ts-expect-error ts(2339) FIXME: Property 'locking' does not exist on type 'never'.
            section => section.locking,
          );

          const lockedExchangeDefs = getExchangeDefs(
            sectionsWithoutLocking,
            unlockableExchangeIdsBySectionId,
            exchangeDefById,
          );

          return (
            <Form>
              <ModalHeader onClose={onCancel}>
                {t('request.suppliersTable.unlockBid')}
              </ModalHeader>
              <ModalBody>
                {!!selectableSections.length && (
                  <MessageBlock variant="warn" mt={2} mb={3}>
                    {t('request.suppliersTable.unlockBidsWarning')}
                  </MessageBlock>
                )}

                <TextList
                  label={t('general.supplier_other')}
                  items={filteredRecipients}
                  value="company.name"
                />

                {selectableSections.length ? (
                  <>
                    <Label label={t('request.suppliersTable.selectSectionsToUnlock')} mt={3} />
                    <CheckboxFieldArray
                      name="selectedSections"
                      options={selectableSections}
                      keyProperty="_id"
                      flexDirection="column"
                      ml={3}
                    />
                  </>
                ) : (
                  <MessageBlock variant="info" mt={3}>
                    {t('request.suppliersTable.thereIsNothingToUnlock')}
                  </MessageBlock>
                )}

                {lockedExchangeDefs.length > 0 && (
                  <TextList
                    label={t('request.suppliersTable.documentsToUnlock')}
                    items={lockedExchangeDefs}
                    value="category"
                  />
                )}
                {selectedSections.length > 0 && (
                  <Box mt={4} ml={3}>
                    <CheckboxField
                      name="hasConfirmed"
                      label={sectionsWithLocking.length && lockedExchangeDefs.length ? (
                        t('request.suppliersTable.unlockSectionsAndDocumentsApproval', { count: filteredRecipients.length })
                      ) : sectionsWithLocking.length ? (
                        t('request.suppliersTable.unlockSectionsApproval', { count: filteredRecipients.length })
                      ) : (
                        t('request.suppliersTable.unlockDocumentsApproval', { count: filteredRecipients.length })
                      )}
                    />
                  </Box>
                )}
              </ModalBody>
              <ModalFooter>
                <CancelButton onClick={onCancel} />
                <Button type="submit" disabled={isSubmitting || !dirty || !hasConfirmed}>
                  {t('request.suppliersTable.unlock')}
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
