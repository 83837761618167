import { useMemo } from 'react';
import { compact, find } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocalStorageState } from '../../../useLocalStorageState';
import { useCurrencySelectItems } from '../../../ui/currencies';

type SelectCurrencyItem = { value: string; label: string };

export const useSelectCurrency = ({
  storageKey,
}: {
  storageKey: string;
}) => {
  const { t } = useTranslation();
  const currencySelectItems = useCurrencySelectItems();

  const sourceCurrencySelectItem = useMemo(() => {
    return { value: null, label: t('request.lineItems.currency.sourceCurrency') };
  }, [t]);

  const extendedCurrencySelectItems = useMemo(() => {
    return [
      sourceCurrencySelectItem,
      ...currencySelectItems,
    ];
  }, [currencySelectItems, sourceCurrencySelectItem]);

  const [selectedItems, setSelectedItems] = useLocalStorageState<SelectCurrencyItem[]>({
    key: storageKey,
    // @ts-expect-error ts(2322) FIXME: Type '{ value: null; label: string; }' is not assignable to type 'SelectCurrencyItem'.
    defaultValue: [sourceCurrencySelectItem],
    // @ts-expect-error ts(2322) FIXME: Type '(initialItems: SelectCurrencyItem[]) => ({ value: null; label: string; } | { value: string; label: string; })[]' is not assignable to type '(value: SelectCurrencyItem[]) => SelectCurrencyItem[]'.
    mapInitialValue: (initialItems) => compact(
      initialItems.map(({ value }) => find(extendedCurrencySelectItems, { value })),
    ),
  });

  return {
    itemToString: (item: SelectCurrencyItem | null) => item ? item.label : '',
    items: extendedCurrencySelectItems,
    selectedItems,
    onChange: setSelectedItems,
  };
};
