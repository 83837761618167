import { useQueryClient } from 'react-query';
import { Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { callAll } from '@deepstream/utils/callAll';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { useCurrentCompanyId } from './currentCompanyId';
import { useApi } from './api';
import { useToaster } from './toast';
import { useMutation } from './useMutation';
import { SentRequestsQueryTags } from './requestsConstants';

export const DeleteRequestDialog = ({
  rfqId,
  rfqName,
  onSuccess,
  onSettled,
  onError,
  isOpen,
  open,
  close,
  toggle,
  hideRfqName,
}: {
  rfqId: string;
  rfqName?: string;
  onSuccess?: () => void;
  onSettled?: () => void;
  onError?: () => void;
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
  hideRfqName?: boolean;
}) => {
  const { t } = useTranslation('translation');
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const [deleteRequestMutation] = useMutation(
    api.deleteRequest,
    {
      onSettled: callAll(
        () => queryClient.invalidateQueries([SentRequestsQueryTags.live, { currentCompanyId }]),
        () => queryClient.invalidateQueries([`${SentRequestsQueryTags.live}FilterItems`, { currentCompanyId }]),
        () => queryClient.invalidateQueries([SentRequestsQueryTags.draft, { currentCompanyId }]),
        () => queryClient.invalidateQueries([`${SentRequestsQueryTags.draft}FilterItems`, { currentCompanyId }]),
        onSettled,
      ),
      onSuccess: callAll(
        () => toaster.success(t('requests.toaster.requestDeleted.success')),
        onSuccess,
      ),
      onError: callAll(
        () => toaster.error(t('requests.toaster.requestDeleted.failed')),
        onError,
      ),
    },
  );

  const deleteRequest = () => deleteRequestMutation({
    companyId: currentCompanyId,
    requestId: rfqId,
  });

  return (
    <Dialog
      heading={t('requests.dialog.confirmRequestDeletion.heading')}
      body={(
        <>
          {hideRfqName ? (
            null
          ) : (
            <>
              <Text mb={2}>{t('requests.dialog.confirmRequestDeletion.body')}:</Text>
              <Text fontWeight={500}>{rfqName || t('requests.untitledRequest')}</Text>
            </>
          )}
          <MessageBlock variant="warn" mt={hideRfqName ? 0 : 3}>
            {t('requests.dialog.confirmRequestDeletion.warning')}
          </MessageBlock>
        </>
      )}
      okButtonText={t('requests.dialog.confirmRequestDeletion.okButtonText')}
      okButtonVariant="danger"
      isOpen={isOpen}
      onOk={deleteRequest}
      onCancel={close}
      showCloseIcon
    />
  );
};
