import { values } from 'lodash';

// We cannot rely on translation strings to map the headers
// as these might change overtime without warning and would break the bulk upload mechanism.
const headerMap = [
  {
    key: 'name',
    headers: {
      'en-GB': 'Name',
      'es-ES': 'Nombre',
      'fr-FR': 'Nom',
      'pt-PT': 'Nome',
      'zh-CN': '名称',
    },
  },
  {
    key: 'description',
    headers: {
      'en-GB': 'Description',
      'es-ES': 'Descripción',
      'fr-FR': 'Description',
      'pt-PT': 'Descrição',
      'zh-CN': '描述',
    },
  },
  {
    key: 'startDate',
    headers: {
      'en-GB': 'Start Date',
      'es-ES': 'Fecha de inicio',
      'fr-FR': 'Date de début',
      'pt-PT': 'Data de início',
      'zh-CN': '开始日期',
    },
  },
  {
    key: 'expiryDate',
    headers: {
      'en-GB': 'Expiry Date',
      'es-ES': 'Fecha de vencimiento',
      'fr-FR': 'Date d’expiration',
      'pt-PT': 'Data de vencimento',
      'zh-CN': '到期日期',
    },
  },
  {
    key: 'currencyCode',
    headers: {
      'en-GB': 'Contract Currency',
      'es-ES': 'Moneda del contrato',
      'fr-FR': 'Devise du contrat',
      'pt-PT': 'Moeda do contrato',
      'zh-CN': '合同货币',
    },
  },
  {
    key: 'amount',
    headers: {
      'en-GB': 'Contract Value',
      'es-ES': 'Valor del contrato',
      'fr-FR': 'Valeur du contrat',
      'pt-PT': 'Valor do contrato',
      'zh-CN': '合同价值',
    },
  },
  {
    key: 'counterpartyId',
    headers: {
      'en-GB': 'Counterparty (linked)',
      'es-ES': 'Contraparte (vinculada)',
      'fr-FR': 'Contrepartie (associée)',
      'pt-PT': 'Contraparte (associada)',
      'zh-CN': '对手方（已关联）',
    },
  },
  {
    key: 'counterpartyName',
    headers: {
      'en-GB': 'Counterparty (unlinked)',
      'es-ES': 'Contraparte (no vinculada)',
      'fr-FR': 'Contrepartie (non associée)',
      'pt-PT': 'Contraparte (não associada)',
      'zh-CN': '对手方（未关联）',
    },
  },
  {
    key: 'reference',
    headers: {
      'en-GB': 'Additional Reference Number',
      'es-ES': 'Número de referencia adicional',
      'fr-FR': 'Numéro de référence complémentaire',
      'pt-PT': 'Número de referência adicional',
      'zh-CN': '附加参考编号',
    },
  },
  {
    key: 'contractOwnerEmail',
    headers: {
      'en-GB': 'Contract Owner',
      'es-ES': 'Titular del contrato',
      'fr-FR': 'Propriétaire du contrat',
      'pt-PT': 'Proprietário do contrato',
      'zh-CN': '合同所有者',
    },
  },
];

export const getHeaderKey = (header: string) => headerMap.find(item => values(item.headers).includes(header))?.key;

export const getHeaderLabel = (key: string, locale) => headerMap.find(item => item.key === key)?.headers[locale];
