import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass/styled-components';
import { CollaboratorInviteStatus, PageRole } from '@deepstream/common/rfq-utils';

import { find } from 'lodash';
import { SummaryPanels } from '../../../draft/SummaryPanels';

import { ReadOnlyStages } from '../../../ReadOnlyStages';
import { SummaryNavigationProvider } from '../../../rfx';

import { ErrorPanel } from '../../../ui/ErrorMessage';
import { LoadingPanel } from '../../../ui/Loading';

import { useCurrentCompanyId } from '../../../currentCompanyId';
import * as rfx from '../../../rfx';
import { RfqIdProvider, useDraftRfqStructure, useRfqId } from '../../../useRfq';
import { RequestSenderTeams } from './RequestSenderTeams';
import { RequestSuppliers } from '../../RequestSuppliers';
import { RequestPages } from '../RequestPages';
import { RequestInvite } from './RequestInvite';
import { RfxValidationProvider, useRfxValidation } from './validation';
import { RequestValidationErrorMessages } from './RequestValidationErrorMessages';
import { ModelSizeLimitsProvider } from '../../../modelSizeLimits';
import { useRequestSizeLimits } from '../useRequestSizeLimits';
import { DraftToolbar } from '../DraftToolbar';
import { MultiStageResponsesInfoBanner } from './MultiStageLineItemInfoBanner';

const DraftReviewToolbar = () => {
  const rfqId = useRfqId();
  const { editingPanelId, isTemplate, isRevising } = rfx.useState();
  const isSuperUserOrOwner = rfx.useIsSuperUserOrOwner();
  const isEditing = Boolean(editingPanelId);
  const { isValid } = useRfxValidation();

  if (isEditing) {
    return null;
  }

  return (
    <DraftToolbar
      rfqId={rfqId}
      tabId="review"
      hasUnsavedChanges={false}
      showSubmitButton={true}
      isSuperUserOrOwner={isSuperUserOrOwner}
      isTemplate={isTemplate}
      isRevising={isRevising}
      isRequestValid={isValid}
      excessSupplierCount={0}
    />
  );
};

export const ReviewTab = ({
  rfqId,
  isTemplate,
  isRevising,
  isTemplatePreview,

  onClickTeamEdit,
  onClickStagesEdit,
  onClickSummaryEdit,
  onClickSuppliersEdit,
}: {
  rfqId: string;
  isTemplate?: boolean;
  isRevising?: boolean;
  isTemplatePreview?: boolean;

  onClickTeamEdit?: (teamId: string) => void;
  onClickStagesEdit?: () => void;
  onClickSummaryEdit?: () => void;
  onClickSuppliersEdit?: () => void;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId();

  const { data: rfxStructure, status: structureStatus } = useDraftRfqStructure({
    rfqId,
    // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string | undefined'.
    currentCompanyId,
    isTemplate,
  });

  const { data: sizeLimits, status: sizeLimitsStatus } = useRequestSizeLimits({
    rfqId,
    // @ts-expect-error ts(2322) FIXME: Type 'boolean | undefined' is not assignable to type 'boolean'.
    isTemplate,
  });

  // Templates should be editable by all members of a company but the template
  // structure doesn't contain ownership / team membership information in
  // `teamById` which we use to determine page permissions so we provide
  // overrides here
  const rfxPermissions = useMemo(
    () => isTemplate ? { isOwner: true, pageRole: PageRole.EDITOR } : {},
    [isTemplate],
  );

  const companyAsSender = find(
    rfxStructure?.senders,
    (sender) => sender.company._id === currentCompanyId,
  );

  const isCollaboratorInvitePending = companyAsSender?.inviteStatus === CollaboratorInviteStatus.PENDING;

  return (
    <RfqIdProvider rfqId={rfqId}>
      <rfx.StateProvider
        isTemplate={isTemplate}
        isTemplatePreview={isTemplatePreview}
        isRevising={isRevising}
        isReview
      >
        {structureStatus === 'loading' || sizeLimitsStatus === 'loading' ? (
          <LoadingPanel />
        ) : structureStatus === 'error' || sizeLimitsStatus === 'error' ? (
          <ErrorPanel error={t('errors.unexpected')} />
        ) : rfxStructure && sizeLimits ? (
          <ModelSizeLimitsProvider {...sizeLimits}>
            <RfqIdProvider rfqId={rfqId}>
              <rfx.StructureProvider structure={rfxStructure}>
                {/*
                 // @ts-expect-error ts(2322) FIXME: Type 'boolean | undefined' is not assignable to type 'boolean'. */}
                <RfxValidationProvider draftStructure={rfxStructure} isRevising={isRevising}>
                  <rfx.OverridesProvider {...rfxPermissions}>
                    {isTemplatePreview ? (
                      null
                    ) : isTemplate ? (
                      <Box mb="20px">
                        <MultiStageResponsesInfoBanner />
                      </Box>
                    ) : (
                      <Flex
                        flexDirection="column"
                        sx={{
                          rowGap: '20px',
                          marginBottom: '20px',
                        }}
                      >
                        {isCollaboratorInvitePending ? (
                          <RequestInvite />
                        ) : (
                          <>
                            <MultiStageResponsesInfoBanner />
                            <RequestValidationErrorMessages />
                          </>
                        )}
                      </Flex>
                    )}
                    {/*
                     // @ts-expect-error ts(2322) FIXME: Type '(() => void) | undefined' is not assignable to type '() => void'. */}
                    <SummaryNavigationProvider navigateToSummary={onClickSummaryEdit}>
                      <SummaryPanels />
                    </SummaryNavigationProvider>
                    <Box mb="20px">
                      <ReadOnlyStages
                        rfqId={rfqId}
                        isReview
                        isTemplatePreview={isTemplatePreview}
                        isTemplate={isTemplate}
                        // @ts-expect-error ts(2322) FIXME: Type '(() => void) | undefined' is not assignable to type '() => void'.
                        navigateToDraftStages={onClickStagesEdit}
                      />
                    </Box>
                    <RequestPages />
                    <RequestSenderTeams
                      rfqId={rfqId}
                      isTemplatePreview={isTemplatePreview}
                      // @ts-expect-error ts(2322) FIXME: Type '((teamId: string) => void) | undefined' is not assignable to type '(companyId: string) => any'.
                      navigateToDraftTeam={onClickTeamEdit}
                      isTemplate={isTemplate}
                      isReview
                    />
                    <Box mt="20px">
                      <RequestSuppliers
                        // @ts-expect-error ts(2322) FIXME: Type '(() => void) | null | undefined' is not assignable to type '(() => void) | undefined'.
                        onClickEdit={isTemplatePreview ? null : onClickSuppliersEdit}
                        // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
                        currentCompanyId={currentCompanyId}
                        rfqId={rfqId}
                        isRevising={isRevising}
                        isTemplate={isTemplate}
                        isTemplatePreview={isTemplatePreview}
                        isReadOnly
                        isReview
                      />
                    </Box>
                    {isCollaboratorInvitePending || isTemplatePreview ? (
                      null
                    ) : (
                      <DraftReviewToolbar />
                    )}
                  </rfx.OverridesProvider>
                </RfxValidationProvider>
              </rfx.StructureProvider>
            </RfqIdProvider>
          </ModelSizeLimitsProvider>
        ) : (
          null
        )}
      </rfx.StateProvider>
    </RfqIdProvider>
  );
};
