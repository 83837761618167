import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { useApi, wrap } from '../../../api';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useQuestionnaireTemplateId } from './questionnaireTemplateUtils';

export const getQuestionnaireTemplateQueryKey = ({
  templateId,
  currentCompanyId,
  scope,
}: {
  templateId: string;
  currentCompanyId: string;
  scope: 'draft' | 'current';
}) => [
  'questionnaireTemplate',
  { templateId, currentCompanyId, scope },
];

export const useQuestionnaireTemplateQueryKey = ({
  templateId: templateIdProp,
  currentCompanyId: currentCompanyIdProp,
  scope,
}: {
  templateId?: string;
  currentCompanyId?: string;
  scope: 'draft' | 'current';
}) => {
  const providedCurrentCompanyId = useCurrentCompanyId({ required: false });
  const currentCompanyId = currentCompanyIdProp || providedCurrentCompanyId;

  if (!currentCompanyId) {
    throw new Error('A `currentCompanyId` is required');
  }

  const providedTemplateId = useQuestionnaireTemplateId({ required: false });
  const templateId = templateIdProp || providedTemplateId;

  if (!templateId) {
    throw new Error('A `templateId` is required');
  }

  const queryKey = useMemo(
    () => getQuestionnaireTemplateQueryKey({ templateId, currentCompanyId, scope }),
    [templateId, currentCompanyId, scope],
  );

  return queryKey;
};

export const useQuestionnaireTemplate = ({
  templateId,
  currentCompanyId,
  scope,
  enabled = true,
}: {
  templateId?: string;
  currentCompanyId?: string;
  scope: 'draft' | 'current';
  enabled?: boolean;
}) => {
  const queryKey = useQuestionnaireTemplateQueryKey({
    currentCompanyId,
    templateId,
    scope,
  });

  const api = useApi();
  const query = useQuery(
    queryKey,
    wrap(api.getQuestionnaireTemplate),
    {
      enabled,
      staleTime: 60 * 1000,
    },
  );

  return useMemo(
    () => ({ ...query, queryKey }),
    [query, queryKey],
  );
};
