import * as React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { Modal, ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import { Bold } from './Bold';
import { ExtendedReceivedRequestOverview } from './types';

type CannotAccessRequestDialogProps = {
  isOpen: boolean;
  onCancel: () => any;
  request: ExtendedReceivedRequestOverview | null;
};

export const CannotAccessRequestDialog: React.FC<CannotAccessRequestDialogProps> = ({
  isOpen,
  onCancel,
  request,
}) => {
  const { t } = useTranslation();

  const heading = t('requests.dialog.cannotAccess.heading');

  return request ? (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      contentLabel={heading}
      style={{ content: { width: '500px' } }}
    >
      <ModalHeader onClose={onCancel}>
        {heading}
      </ModalHeader>
      <Box p={3} fontSize={2}>
        <Text lineHeight={1.5}>
          <Bold>{t('requests.request')}:</Bold> {request.receivedDashboard.subject}
        </Text>
        {request.receivedDashboard.ownerNamesOrEmails ? (
          <>
            <Text lineHeight={1.5}>
              <Bold>{t('requests.owner_other')}:</Bold> {request.receivedDashboard.ownerNamesOrEmails.join(', ')}
            </Text>
            <Text mt={3} mb={2} lineHeight={1.5}>{t('requests.dialog.cannotAccess.notOnTeam')}</Text>
            <Text mb={2} lineHeight={1.5}>{t('requests.dialog.cannotAccess.askOwner')}</Text>
          </>
        ) : request.receivedDashboard.teamMemberNamesOrEmails ? (
          <>
            <Text lineHeight={1.5}>
              <Bold>{t('requests.teamMember_other')}:</Bold> {request.receivedDashboard.teamMemberNamesOrEmails.join(', ')}
            </Text>
            <Text mt={3} mb={2} lineHeight={1.5}>{t('requests.dialog.cannotAccess.notOnTeam')}</Text>
            <Text mb={2} lineHeight={1.5}>{t('requests.dialog.cannotAccess.askTeamMember')}</Text>
          </>
        ) : (
          <Text mt={3} mb={2} lineHeight={1.5}>{t('requests.dialog.cannotAccess.notOnTeam')}</Text>
        )}
      </Box>
      <PanelDivider />
      <Flex p={3} justifyContent="flex-end">
        <Button variant="primary" onClick={onCancel} style={{ minWidth: 60 }}>
          {t('general.dismiss')}
        </Button>
      </Flex>
    </Modal>
  ) : null;
};
