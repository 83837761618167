import { noop, first, unary } from 'lodash';
import { Attachment, Live } from '@deepstream/common/rfq-utils';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { callAll } from '@deepstream/utils/callAll';
import { ModalForm } from '../../../ModalForm';
import { useToaster } from '../../../toast';
import { useApi } from '../../../api';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useMutation } from '../../../useMutation';
import * as rfx from '../../../rfx';
import { FileField } from '../../../form/FilesField';
import { TextField } from '../../../form/TextField';
import { useStageSelectItems } from '../../../draft/useStageSelectItems';
import { SelectField } from '../../../form/SelectField';

const useAddNewInternalDocument = ({
  rfqId,
  onSuccess,
  onError,
  onSettled,
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const toaster = useToaster();
  const api = useApi();

  return useMutation(
    async ({ stageId, attachments, name, comment }: { stageId: string; attachments: Attachment[]; name: string; comment: string }) => {
      const { _id: exchangeId } = await api.addInternalDocument({
        rfqId,
        stageId,
        companyId: currentCompanyId,
        document: {
          name,
          comment,
          attachment: first(attachments)!,
        },
      });

      return { exchangeId };
    },
    {
      onSettled,
      onSuccess,
      onError: callAll(
        () => toaster.error(t('request.internalDocuments.errors.addInternalDocument')),
        onError,
      ),
    },
  );
};

type NewInternalDocumentModalProps = {
  rfqId: string;
  isOpen: boolean;
  onCancel: any;
  onError?: any;
  onSuccess?: any;
  onSettled?: any;
};

export const NewInternalDocumentModal = ({
  rfqId,
  isOpen,
  onCancel,
  onSuccess = noop,
  onError = noop,
  onSettled = noop,
}: NewInternalDocumentModalProps) => {
  const { t } = useTranslation('translation');
  const { stages } = rfx.useStructure<Live>();
  const stageSelectItems = useStageSelectItems();

  const [addInternalDocument] = useAddNewInternalDocument({
    rfqId,
    onSuccess,
    onError,
    onSettled,
  });

  return (
    <ModalForm
      heading={t('request.internalDocuments.addInternalDocument')}
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={unary(addInternalDocument)}
      submitLabel={t('request.internalDocuments.addDocument')}
      initialValues={{
        stageId: stages[0]._id,
        attachments: [],
        name: '',
        comment: '',
      }}
      validationSchema={
        yup.object().shape({
          attachments: yup
            .array(yup.object({ _id: yup.string().required() }))
            .max(1)
            .min(1),
          name: yup.string().required(t('general.required')),
          comment: yup.string(),
        })
      }
    >
      {stages.length > 1 && (
        <SelectField
          name="stageId"
          label={t('request.internalDocuments.requestStage')}
          items={stageSelectItems}
          helperText={t('request.internalDocuments.requestStageHelperText')}
        />
      )}
      <FileField
        name="attachments"
        label={t('request.internalDocuments.file')}
        purpose="rfq"
        required
      />
      <TextField name="name" label={t('general.name')} required />
      <TextField name="comment" label={t('general.comment_other')} isMultiLine />
    </ModalForm>
  );
};
