/* eslint-disable react-hooks/rules-of-hooks */

import { useCallback, useMemo } from 'react';

import * as React from 'react';
import { map, filter, sortBy } from 'lodash';
import { Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useMutation } from '../useMutation';
import { CompanyList, DisabledOverlay } from '../ui/CompanyList';
import { Spinner } from '../ui/Loading';

const Actions: React.FC<{ company: any; approveCompany: any; denyCompany: any }> = ({
  company,
  approveCompany,
  denyCompany,
}) => {
  const { t } = useTranslation('companyProfile');
  const [approve, { status: approveStatus }] = useMutation(approveCompany);
  const [deny, { status: denyStatus }] = useMutation(denyCompany);

  const isLoading = approveStatus === 'loading' || denyStatus === 'loading';

  return (
    isLoading ? (
      <Spinner fontSize={3} />
    ) : approveStatus === 'success' ? (
      <>
        <DisabledOverlay />
        <Text fontSize={2}>{t('questionnaire.visibility.approved')}</Text>
      </>
    ) : denyStatus === 'success' ? (
      <>
        <DisabledOverlay />
        <Text fontSize={2}>{t('questionnaire.visibility.denied')}</Text>
      </>
    ) : (
      <>
        <Button small key="deny" iconLeft="times" variant="secondary-outline" onClick={() => deny(company)} mr={3}>
          {t('questionnaire.visibility.deny')}
        </Button>
        <Button small key="accept" iconLeft="check" variant="primary-outline" onClick={() => approve(company)}>
          {t('questionnaire.visibility.approve')}
        </Button>
      </>
    )
  );
};

type AccessRequestListProps = {
  accessRequests: any;
  accessRequestDecisions: any;
  approveCompany: any;
  denyCompany: any;
};

// This list displays companies with pending access requests AND access requests that have been
// approved/denied during the lifetime of the modal. This makes the list more complex than if
// it only contained items for pending requests.
export const AccessRequestList: React.FC<AccessRequestListProps> = ({
  accessRequests,
  accessRequestDecisions,
  approveCompany,
  denyCompany,
}) => {
  const { t } = useTranslation('companyProfile');
  // To derive this list of access requests, we get all pending requests *AND* the non-pending
  // requests that have been recently decided. The final list is sorted by the date of the
  // request to ensure that list items don't jump around in the UI.
  const visibleAccessRequestCompanies = useMemo(
    () => {
      const visibleAccessRequests = filter(
        accessRequests,
        ({ companyId, status }) => Boolean(status === 'pending' || accessRequestDecisions[companyId]),
      );

      return sortBy(map(visibleAccessRequests, 'company'), 'timestamp');
    },
    [accessRequests, accessRequestDecisions],
  );

  const renderActions = useCallback(
    (company) => (
      <Actions company={company} approveCompany={approveCompany} denyCompany={denyCompany} />
    ),
    [approveCompany, denyCompany],
  );

  return (
    visibleAccessRequestCompanies.length ? (
      <CompanyList companies={visibleAccessRequestCompanies} renderActions={renderActions} />
    ) : (
      <Text color="subtext">{t('questionnaire.visibility.noPendingAccessRequests')}</Text>
    )
  );
};
