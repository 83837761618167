import { isEqual } from 'lodash';
import { useCallback, useState, useEffect } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';
import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { useApi } from '../../api';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { DatetimeField } from '../../form/DatetimeField';

import { FileField } from '../../form/FilesField';
import { RadioField } from '../../form/RadioField';
import { TextField } from '../../form/TextField';
import { ModalForm } from '../../ModalForm';
import { useToaster } from '../../toast';
import { useMutation } from '../../useMutation';
import { DocumentsDeleteModal } from './DocumentsDeleteModal';

import {
  DocumentVisibility,
  Attachment,
  useDocumentExpiryDate,
  useDocumentDownload,
  QueryDocument,
} from './DocumentsTableDataProvider';

type DocumentFormData = {
  attachments: Attachment[];
} & Pick<QueryDocument, 'name' | 'description' | 'expiryDate' | 'visibility'>;

type DocumentPayload = {
  attachmentId: string;
} & Pick<QueryDocument, 'name' | 'description' | 'expiryDate' | 'visibility'>;

const mapDataToPayload = (formData: DocumentFormData) => {
  const { name, description, expiryDate, visibility, attachments } = formData;

  return {
    name,
    description,
    expiryDate,
    visibility,
    attachmentId: attachments && attachments.length ? attachments[0]._id : undefined,
  } as DocumentPayload;
};

const DocumentForm = ({
  id,
  heading,
  isOpen,
  initialValues,
  onSubmit,
  onCancel,
  initialFile,
  footerLeftButton,
}: {
  id?: string;
  heading: string;
  isOpen: boolean;
  initialValues: DocumentFormData;
  onSubmit: any;
  onCancel: any;
  initialFile?: File;
  footerLeftButton?: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [visibility, setVisibility] = useState<DocumentVisibility>(initialValues.visibility);
  const [expiryDate, setExpiryDate] = useState<string | null>(initialValues.expiryDate);
  const { isExpired, isNearlyExpired } = useDocumentExpiryDate(expiryDate);
  const downloadDocument = useDocumentDownload();

  return (
    <ModalForm
      isOpen={isOpen}
      heading={heading}
      onCancel={onCancel}
      onSubmit={onSubmit}
      validationSchema={
        yup.object().shape({
          attachments: yup
            .array(yup.object({ _id: yup.string().required() }))
            .max(1)
            .min(1),
          name: yup.string().required(t('general.required')),
          visibility: yup
            .mixed()
            .oneOf([DocumentVisibility.INTERNAL, DocumentVisibility.PUBLIC])
            .required(),
        })
      }
      panelStyle={{
        width: 'min(500px, 100vw - 32px)',
      }}
      initialValues={initialValues}
      footerLeftButton={footerLeftButton}
    >
      {isNearlyExpired && (
        <MessageBlock variant="warn">
          {t('drivePage.warnings.expiresSoon')}
        </MessageBlock>
      )}
      {isExpired && (
        <MessageBlock variant="error">
          {t('drivePage.warnings.expired')}
        </MessageBlock>
      )}
      {visibility === DocumentVisibility.PUBLIC && (
        <MessageBlock variant="warn">
          {t('drivePage.warnings.publicVisibility')}
        </MessageBlock>
      )}
      <FileField
        purpose="document"
        required
        label={t('drivePage.documentForm.fields.file')}
        fieldName="attachments"
        initialFile={initialFile}
        disabled={!id}
        download={id ? ({ name }) => downloadDocument(id, name) : undefined}
      />
      <TextField
        label={t('drivePage.documentForm.fields.name')}
        required
        name="name"
      />
      <TextField
        label={t('drivePage.documentForm.fields.description')}
        name="description"
        isMultiLine
        rows={4}
      />
      <DatetimeField
        label={t('drivePage.documentForm.fields.expiryDate')}
        name="expiryDate"
        onChange={setExpiryDate}
      />
      <RadioField
        name="visibility"
        label={t('drivePage.documentForm.fields.visibility')}
        required
        onChange={setVisibility}
        gap={1}
        options={[{
          label: t('drivePage.documentForm.fields.visibilityOpts.internal.label'),
          value: DocumentVisibility.INTERNAL,
          description: t('drivePage.documentForm.fields.visibilityOpts.internal.description'),
        }, {
          label: t('drivePage.documentForm.fields.visibilityOpts.public.label'),
          value: DocumentVisibility.PUBLIC,
          description: t('drivePage.documentForm.fields.visibilityOpts.public.description'),
        }]}
        // override global CSS styling of <label> in the Angular client
        labelStyle={{ fontWeight: 'normal', color: theme.colors.text }}
        showError
      />
    </ModalForm>
  );
};

export const DocumentModalEdit = ({
  id,
  initialValues,
  isOpen,
  closeModal,
}: {
  id: string;
  initialValues: DocumentFormData;
  isOpen: boolean;
  closeModal: () => void;
}) => {
  const { t } = useTranslation();
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const companyId = useCurrentCompanyId({ required: true });
  const [update] = useMutation(api.updateCompanyDocument, {
    onSuccess: callAll(
      () => queryClient.invalidateQueries(['companyAllDocuments', { companyId }]),
      () => toaster.success(t('drivePage.toaster.changesSavedSuccess')),
    ),
    onError: () => toaster.error(t('drivePage.toaster.changesSavedError')),
  });

  const handleFormSubmit = useCallback((values) => {
    const payload = Object.entries(values).reduce((acc, [key, value]) => {
      const changed = !isEqual(initialValues[key], value);

      if (changed) return { ...acc, [key]: value };

      return acc;
    }, {} as DocumentFormData);

    return update({
      companyId,
      documentId: id,
      payload: mapDataToPayload(payload),
    }, {
      onSuccess: closeModal,
    });
  }, [
    id,
    initialValues,
    closeModal,
    companyId,
    update,
  ]);

  return (
    <DocumentForm
      id={id}
      onCancel={closeModal}
      onSubmit={handleFormSubmit}
      heading={t('drivePage.documentForm.titleEdit')}
      isOpen={isOpen}
      initialValues={initialValues}
      footerLeftButton={(
        <DocumentsDeleteModal
          trigger={({ open }) => (
            <Button
              onClick={open}
              variant="danger-outline"
              iconLeft="trash"
              type="button"
            >
              {t('general.delete')}
            </Button>
          )}
          documents={[{ _id: id, name: initialValues.name }]}
        />
      )}
    />
  );
};

export const DocumentsModalAdd = ({
  files,
  onAllDone,
}: {
  files: FileList;
  onAllDone: () => void;
}) => {
  const api = useApi();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const [activeFileForm, setActiveFileForm] = useState(0);
  const companyId = useCurrentCompanyId({ required: true });
  const [create] = useMutation(api.createCompanyDocument, {
    onSuccess: callAll(
      () => queryClient.invalidateQueries(['companyAllDocuments', { companyId }]),
      (data, variables) => toaster.success(
        t('drivePage.toaster.addedSuccess', { document: variables.payload.name }),
      ),
    ),
    onError: (data, variables) => toaster.error(
      t('drivePage.toaster.addedError', { document: variables.payload.name }),
    ),
  });

  const initialValues = {
    attachments: [],
    name: '',
    description: '',
    expiryDate: null,
    visibility: DocumentVisibility.INTERNAL,
  };

  const handleFormSubmit = useCallback((values) => {
    create({
      companyId,
      payload: mapDataToPayload(values),
    }, {
      onSuccess: () => setActiveFileForm(activeFileForm + 1),
    });
  }, [
    activeFileForm,
    companyId,
    create,
  ]);

  const handleFormCancel = useCallback(() => {
    setActiveFileForm(activeFileForm + 1);
  }, [activeFileForm]);

  useEffect(() => {
    if (activeFileForm === files.length) onAllDone();
  }, [activeFileForm, files, onAllDone]);

  return (
    <>
      {
        Array.from(files).reverse().map((file, index) => {
          const heading = t('drivePage.documentForm.titleAdd', {
            current: activeFileForm + 1,
            count: files.length,
          });

          return (
            <DocumentForm
              key={index}
              onCancel={handleFormCancel}
              onSubmit={handleFormSubmit}
              heading={heading}
              isOpen={activeFileForm === index}
              initialValues={initialValues}
              initialFile={file}
            />
          );
        })
      }
    </>
  );
};
