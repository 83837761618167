import { useMemo } from 'react';
import { Live } from '@deepstream/common/rfq-utils';
import { propertyOf, values } from 'lodash';
import { Box, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { BidProgress, getExchangesProgressItems } from '@deepstream/common/bidProgress';
import { assignSums } from '@deepstream/utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import * as rfx from '../../../rfx';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { BidSectionsContainer } from '../../../BidSections';
import * as lotPagesLayout from '../Live/lotPagesLayout';
import { ModelSizeLimitsProvider } from '../../../modelSizeLimits';
import { PageSections } from '../RequestPages';
import { RequestHooksProvider } from '../RequestHooksProvider';
import { LoadingPanel } from '../../../ui/Loading';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import { useLiveRfqStructure, useRecipientId, useRfqId } from '../../../useRfq';
import { RequestRecipientExchangeStageMessage } from './RequestRecipientStageMessage';
import { EnteredStageTimelineSection } from './EnteredStageTimelineSection';
import { RequestRecipientStageStatusTable } from './RequestRecipientStageStatusTable';
import { RequestRecipientLotStatusTable } from './RequestRecipientLotStatusTable';
import { RequestRecipientToolsSection } from './RequestRecipientToolsSection';
import { Disclosure2 } from '../../../ui/Disclosure';
import { iconPropsByExchangesProgressId } from '../Live/iconPropsByExchangesProgressId';
import { Bold } from '../../../Bold';

const StageStatusSection = () => {
  const { t } = useTranslation('translation');

  return (
    <lotPagesLayout.Section heading={t('general.status')}>
      <Box mt="6px">
        <RequestRecipientStageStatusTable />
      </Box>
    </lotPagesLayout.Section>
  );
};

const LotStatusSection = () => {
  const { t } = useTranslation('translation');

  return (
    <lotPagesLayout.Section heading={t('general.status')}>
      <Box mt="6px">
        <RequestRecipientLotStatusTable />
      </Box>
    </lotPagesLayout.Section>
  );
};

const ExchangesStatusBar = () => {
  const { t } = useTranslation('translation');
  const bid = rfx.useBid();
  const stageId = rfx.useStageId();
  const recipientId = useRecipientId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const requirementGroupId = rfx.useRequirementGroupId();

  const isRecipient = currentCompanyId === recipientId;

  const progressItems = useMemo(() => {
    const progressByPageId = bid.progressByPageIdByRequirementGroupIdByStageId[stageId]?.[requirementGroupId || 'general'];

    const progress = assignSums(values(progressByPageId)) as BidProgress;

    return getExchangesProgressItems(progress, isRecipient);
  }, [bid.progressByPageIdByRequirementGroupIdByStageId, requirementGroupId, stageId, isRecipient]);

  return (
    <Box mt="20px" mb="24px">
      {progressItems.map(({ id, value }) => (
        <Box as="span" key={id} mr="24px">
          {iconPropsByExchangesProgressId[id] && (
            <Icon fixedWidth mr={1} {...iconPropsByExchangesProgressId[id]} />
          )}
          {t(`request.progress.${id}`)}
          <Bold ml={2}>{value}</Bold>
        </Box>
      ))}
    </Box>
  );
};

const RequirementsSection = ({
  pageId,
  sectionId,
  exchangeId,
}: {
  pageId: string;
  sectionId?: string;
  exchangeId?: string;
}) => {
  const { t } = useTranslation('translation');
  const recipientId = useRecipientId();
  const structure = rfx.useStructure<Live>();
  const currentCompanyGroup = rfx.useCurrentCompanyGroup();
  const bid = rfx.useBid();
  const rfqId = useRfqId();
  const stageId = rfx.useStageId();
  const requirementGroupId = rfx.useRequirementGroupId();
  const isLotObsolete = rfx.useIsLotObsolete();

  const hasActivatedStage = bid.activatedStageIds.includes(stageId);

  // @ts-expect-error ts(18048) FIXME: 'structure.enteredPageIdsByRequirementGroupIdByStageId' is possibly 'undefined'.
  const pages = structure.enteredPageIdsByRequirementGroupIdByStageId[stageId][requirementGroupId || 'general']
    .map(propertyOf(structure.pageById));

  return (
    <lotPagesLayout.Section heading={t('request.requirement_other')} maxWidth="100%">
      {hasActivatedStage ? (
        <>
          {isLotObsolete ? (
            <Box mt="20px" />
          ) : (
            <>
              {currentCompanyGroup === 'supplier' && (
                <Text mt="12px" pb="6px" maxWidth={lotPagesLayout.DEFAULT_SECTION_WIDTH}>
                  <Disclosure2
                    width="100%"
                    summary={t('request.guidance.heading')}
                  >
                    <lotPagesLayout.Ul>
                      <li>{t('request.guidance.item1')}</li>
                      <li>{t('request.guidance.item2')}</li>
                      <li>{t('request.guidance.item3')}</li>
                    </lotPagesLayout.Ul>
                  </Disclosure2>
                </Text>
              )}
              <ExchangesStatusBar />
            </>
          )}
          <BidSectionsContainer
            rfqId={rfqId}
            recipientId={recipientId}
            pageId={pageId}
            sectionId={sectionId}
            exchangeId={exchangeId}
            // @ts-expect-error ts(2322) FIXME: Type 'boolean | "" | null | undefined' is not assignable to type 'boolean | undefined'.
            hideActionCount={isLotObsolete}
          />
        </>
      ) : (
        <Box mt="24px">
          <RequestHooksProvider>
            <rfx.StateProvider isLive>
              {/* TODO instead of providing dummy model size limits here, better
              refactor document (and other?) section panel components to only
              depend on model sizes in edit mode */}
              <ModelSizeLimitsProvider maxComplexity={0} maxExchangeDefCount={0}>
                {pages.map((page) => (
                  <rfx.PageProvider page={page} key={page._id}>
                    <PageSections />
                  </rfx.PageProvider>
                ))}
              </ModelSizeLimitsProvider>
            </rfx.StateProvider>
          </RequestHooksProvider>
        </Box>
      )}
    </lotPagesLayout.Section>
  );
};

const RequestRecipientBidStagePageInstanceContent = ({
  pageId,
  sectionId,
  exchangeId,
}: {
  pageId: string;
  sectionId?: string;
  exchangeId?: string;
}) => {
  const currentCompanyGroup = rfx.useCurrentCompanyGroup();
  const bid = rfx.useBid();
  const stageId = rfx.useStageId();
  const requirementGroupId = rfx.useRequirementGroupId();
  const isLotObsolete = rfx.useIsLotObsolete();

  const hasActivatedStage = bid.activatedStageIds.includes(stageId);

  return (
    <lotPagesLayout.ContentWrapper>
      <RequestRecipientExchangeStageMessage />
      {!requirementGroupId && (
        <EnteredStageTimelineSection />
      )}
      {!hasActivatedStage || isLotObsolete ? (
        null
      ) : requirementGroupId ? (
        <LotStatusSection />
      ) : (
        <StageStatusSection />
      )}
      <RequirementsSection
        pageId={pageId}
        sectionId={sectionId}
        exchangeId={exchangeId}
      />
      {/* When this is a level-4 page, we render the tools section not here but
      on level 3 */}
      {currentCompanyGroup === 'supplier' && !requirementGroupId && (
        <RequestRecipientToolsSection />
      )}
    </lotPagesLayout.ContentWrapper>
  );
};

/**
 * Recipient-specific request pages, level 3 (in requests without lots) or
 * level 4 (in requests with lots).
 *
 * Contains the bid pages on which suppliers can submit their bids.
 */
export const RequestRecipientBidStagePageInstance = ({
  pageId,
  sectionId,
  exchangeId,
}: {
  pageId: string;
  sectionId?: string;
  exchangeId?: string;
}) => {
  const { t } = useTranslation('translation');
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const recipientId = useRecipientId();
  const rfqId = useRfqId();

  const { data: structure, isLoading, isError, isSuccess } =
    useLiveRfqStructure({ rfqId, recipientId, currentCompanyId });

  return isLoading ? (
    <LoadingPanel />
  ) : isError ? (
    <ErrorPanel error={t('errors.unexpected')} />
  ) : isSuccess && structure ? (
    <rfx.StructureProvider structure={structure}>
      <RequestRecipientBidStagePageInstanceContent
        pageId={pageId}
        sectionId={sectionId}
        exchangeId={exchangeId}
      />
    </rfx.StructureProvider>
  ) : (
    null
  );
};
