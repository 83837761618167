import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Box } from 'rebass/styled-components';
import { v4 as uuid } from 'uuid';
import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { useApi } from './api';
import { useCurrentCompanyId } from './currentCompanyId';
import { SentRequestsQueryTags } from './requestsConstants';
import { useToaster } from './toast';
import { useModalState } from './ui/useModalState';
import { UpgradeToUnlockDialog } from './UpgradeToUnlockDialog';
import { useMutation } from './useMutation';
import { useUserFlags } from './UserFlagsContext';

export const CreateRequestButton = ({
  onRequestCreated,
  navigateToTemplates,
}: {
  onRequestCreated: ({ id, isDraft }: { id: string; isDraft: boolean }) => void;
  navigateToTemplates: () => void;
}) => {
  const { t } = useTranslation(['translation', 'company']);
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const upgradeDialog = useModalState();
  const { hasSendRfqPermission, belongsToPayingCompany } = useUserFlags();

  const [createEmptyRequest] = useMutation(api.createEmptyRequest, {
    onSettled: callAll(
      () => queryClient.invalidateQueries([SentRequestsQueryTags.live, { currentCompanyId }]),
      () => queryClient.invalidateQueries([`${SentRequestsQueryTags.live}FilterItems`, { currentCompanyId }]),
      () => queryClient.invalidateQueries([SentRequestsQueryTags.draft, { currentCompanyId }]),
      () => queryClient.invalidateQueries([`${SentRequestsQueryTags.draft}FilterItems`, { currentCompanyId }]),
    ),
    onSuccess: callAll(
      (requestId) =>
        onRequestCreated({
          id: requestId,
          isDraft: true,
        }),
      () => toaster.success(t('requests.toaster.requestCreated.success')),
    ),
    onError: () => toaster.error(t('requests.toaster.requestCreated.failed')),
  });

  return !hasSendRfqPermission ? null : belongsToPayingCompany ? (
    <Box m={1} ml="20px">
      <DropdownMenu
        rightAligned
        small
        variant="primary"
        buttonText={t('requests.newRequest')}
        iconLeft="plus"
        iconRight="caret-down"
        menuZIndex={102}
      >
        <DropdownMenuItem
          icon="file-o"
          onSelect={() => {
            const stages = [
              {
                _id: uuid(),
                name: '',
                intentionDeadline: null,
                completionDeadline: null,
                isPrivate: false,
              },
            ];

            createEmptyRequest({
              companyId: currentCompanyId,
              stages,
            });
          }}
        >
          {t('requests.blankRequest')}
        </DropdownMenuItem>
        <DropdownMenuItem icon="files-o" onSelect={navigateToTemplates}>
          {t('requests.fromTemplate')}
        </DropdownMenuItem>
      </DropdownMenu>
    </Box>
  ) : (
    <>
      <Box m={1}>
        <Button
          small
          variant="primary"
          iconLeft="lock"
          onClick={upgradeDialog.open}
        >
          {t('requests.newRequest')}
        </Button>
      </Box>
      <UpgradeToUnlockDialog
        isOpen={upgradeDialog.isOpen}
        onCancel={upgradeDialog.close}
      />
    </>
  );
};
