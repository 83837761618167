import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';
import { get, isEmpty, noop, set } from 'lodash';
import { Company, getEmptyFieldValue, isFieldValueDefined } from '@deepstream/common/rfq-utils';
import { ColumnData, RowData } from '@deepstream/ui-kit/grid/core/utils';
import { EditableGrid } from '@deepstream/ui-kit/grid/EditableGrid/EditableGrid';
import { useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { DefaultEditableGridStyles } from '@deepstream/ui-kit/grid/EditableGrid/EditableGridStyles';
import { useGridMenuState } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { EditableGridColumn, calculateMaxGridHeight } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { useEditableGridActions } from '@deepstream/ui-kit/grid/EditableGrid/useEditableGridActions';
import { withProps } from '@deepstream/ui-utils/withProps';
import { LotsGridMenu } from './LotsGridMenu';
import { SimpleHeader } from './header';
import { useHandleGridClipboardEvent } from './useHandleGridClipboardEvent';
import { SelectValueCell, CompanyLogoAndNameCell } from './nonValidationAwareValueCell';
import { MessageAction, messageActions, useMessageActionOptions } from '../../modules/Request/AwardFlow/useMessageActionOptions';
import { SelectInputCell } from './inputCell';

const frozenLeftColumnIds = [];
const navigableRange = { startRowIndex: 1, startColumnIndex: 0 };
const selectableRange = { startColumnIndex: 1, endColumnIndex: 2 };

// used only in the context of copy-pasting
const NO_SELECTION_PLACEHOLDER = '-';

export type ChooseMessageActionGridRowData = {
  _id: string;
  supplier: Company;
  action?: MessageAction | null;
};

const generateCsvCellValue = (columnConfig, gridRowData) => {
  const action = gridRowData[columnConfig._id];

  return action || NO_SELECTION_PLACEHOLDER;
};

const useAddCsvDataToRows = () => {
  return React.useCallback(async ({ rows, data, affectedColumns }: {
    rows: any;
    data: any;
    affectedColumns: any;
  }) => {
    let validCellCount = 0;

    const updatedRows = rows.map((gridRowData: ChooseMessageActionGridRowData, lotIndex: number) => {
      const rowData = data[lotIndex] ?? [];
      const updatedLot = { ...gridRowData };

      affectedColumns.forEach((column, index) => {
        const rawValue = rowData[index];

        const parsedValue = !rawValue || rawValue === NO_SELECTION_PLACEHOLDER
          ? null
          : rawValue;

        const isInputValid = !parsedValue || messageActions.includes(parsedValue);
        const canInsertValue = isInputValid;

        if (canInsertValue) {
          set(updatedLot, column.original.accessorKey, parsedValue);
        }

        if (isInputValid) {
          validCellCount += 1;
        }
      });

      return updatedLot;
    });

    return {
      updatedRows,
      validCellCount,
      ignoreCroppedRows: true,
    };
  }, []);
};

const createClearFieldsUpdater = ({
  startRowIndex,
  endRowIndex,
  affectedColumns,
}: { startRowIndex: number, endRowIndex: number, affectedColumns: { original: EditableGridColumn }[] }) =>
  (lot: Record<string, unknown>, index: number) => {
    if (!lot.isObsolete && index >= startRowIndex && index < endRowIndex) {
      const updatedRow = { ...lot };

      for (const column of affectedColumns) {
        const { accessorKey, fieldType } = column.original;

        const fieldValue = get(lot, accessorKey);

        // @ts-expect-error ts(2345) FIXME: Argument of type 'FieldType | undefined' is not assignable to parameter of type 'FieldType'.
        if (isFieldValueDefined(fieldValue, fieldType)) {
          // @ts-expect-error ts(2345) FIXME: Argument of type 'FieldType | undefined' is not assignable to parameter of type 'FieldType'.
          const newFieldValue = getEmptyFieldValue(fieldType);

          set(updatedRow, accessorKey, newFieldValue);
        }
      }

      return updatedRow;
    } else {
      return lot;
    }
  };

export interface ChooseMessageActionGridProps {
  /**
   * Determines the maximum height of the grid.
   *
   * `maxGridHeight = 100vh - viewportHeightDelta`
   */
  viewportHeightDelta?: number;
}

export const ChooseMessageActionGrid = ({
  viewportHeightDelta,
}: ChooseMessageActionGridProps) => {
  const { t } = useTranslation('translation');
  const handleGridClipboardEvent = useHandleGridClipboardEvent('request.awardFlow.awardDecisionCellNotPasted');
  const {
    pendingKeyboardEvent,
    rowData,
    setEditedCell,
  } = useEditableGridData<ChooseMessageActionGridRowData>();
  const { menuReferenceId } = useGridMenuState();
  const messageActionOptions = useMessageActionOptions();

  const addCsvDataToRows = useAddCsvDataToRows();

  const gridActions = useEditableGridActions({
    createRow: noop,
    generateCsvCellValue,
    addCsvDataToRows,
    // @ts-expect-error ts(2322) FIXME: Type '({ startRowIndex, endRowIndex, affectedColumns, }: { startRowIndex: number; endRowIndex: number; affectedColumns: { original: EditableGridColumn; }[]; }) => (lot: Record<string, unknown>, index: number) => Record<string, unknown>' is not assignable to type '({ startRowIndex, endRowIndex, affectedColumns, }: { startRowIndex: number; endRowIndex: number; affectedColumns: ({ original: any; } | null)[]; }) => (originalRowData: any, index: number) => unknown'.
    createClearFieldsUpdater,
  });

  const startEditingCell = React.useCallback((
    row: RowData<any, any>,
    column: ColumnData<EditableGridColumn>,
    event?: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    pendingKeyboardEvent.current = event ?? null;

    setEditedCell({
      rowId: row.original._id,
      columnId: column.original._id,
    });
  }, [setEditedCell, pendingKeyboardEvent]);

  const columns: EditableGridColumn[] = React.useMemo(() => {
    return [
      {
        _id: 'supplier',
        accessorKey: 'supplier',
        accessorFn: row => get(row, 'supplier.company'),
        Header: SimpleHeader,
        label: t('general.supplier', { count: 1 }),
        ValueCell: withProps(CompanyLogoAndNameCell, { hasDefaultCursor: true }),
        width: 300,
      },
      {
        _id: 'action',
        accessorKey: 'action',
        Header: SimpleHeader,
        label: t('request.awardFlow.steps.chooseMultiSupplierCustomMessageMethods.messageAction'),
        startEditingCell,
        InputCell: withProps(SelectInputCell, {
          options: messageActionOptions,
          placeholder: t('request.awardFlow.steps.chooseMultiSupplierCustomMessageMethods.selectAction'),
        }),
        ValueCell: withProps(SelectValueCell, {
          options: messageActionOptions,
          placeholder: t('request.awardFlow.steps.chooseMultiSupplierCustomMessageMethods.selectAction'),
        }),
        width: 230,
      },
    ];
  }, [t, startEditingCell, messageActionOptions]);

  const maxGridHeight = calculateMaxGridHeight(rowData.length);

  return (
    <>
      {isEmpty(rowData) ? (
        <Text fontSize={2}>
          {t('table.noDataToDisplay')}
        </Text>
      ) : (
        <DefaultEditableGridStyles
          style={{
            width: '100%',
            height: `min(100vh - ${viewportHeightDelta}px, ${maxGridHeight}px)`,
          }}
          cellContentCss="justify-content: flex-start;"
          activeCellContentCss="padding: 8px"
        >
          <EditableGrid
            columns={columns}
            rowData={rowData as any}
            gridActions={gridActions}
            onGridClipboardEvent={handleGridClipboardEvent}
            submitEditedCellOnScroll={false}
            navigableRange={navigableRange}
            selectableRange={selectableRange}
            highlightSelectionInHeaders
            frozenLeftColumnIds={frozenLeftColumnIds}
          />
        </DefaultEditableGridStyles>
      )}
      {menuReferenceId ? (
        <LotsGridMenu />
      ) : (
        null
      )}
    </>
  );
};
