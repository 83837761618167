import { useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';
import { Live, CostAndSavingsByRecipientId, Company } from '@deepstream/common/rfq-utils';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { fromPairs, isEmpty, isFinite, noop } from 'lodash';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { localeFormatPrice } from '@deepstream/utils';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData } from '../types';
import * as rfx from '../../../../rfx';
import { RadioField } from '../../../../form/RadioField';
import { CompanyLogo } from '../../../../CompanyLogo';
import { useCurrentUserLocale } from '../../../../useCurrentUser';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { getRequestAwardTotalAmount } from '../useCalculatedTotalValue';
import { Direction } from '../../../../ui/MultiStepFlow/types';

const useBidTotalByRecipientId = (
  recipients: Company[],
  costAndSavingsByRecipientId: CostAndSavingsByRecipientId,
) => {
  return useMemo(() => {
    return fromPairs(
      recipients.map(recipient => {
        const costAndSavings = costAndSavingsByRecipientId?.[recipient._id];

        return [
          recipient._id,
          costAndSavings ? getRequestAwardTotalAmount(costAndSavings) : null,
        ];
      }),
    );
  }, [recipients, costAndSavingsByRecipientId]);
};

const useSupplierOptions = (
  recipients: Company[],
  bidTotalByRecipientId: Record<string, number | null>,
) => {
  const { t } = useTranslation('translation');
  const locale = useCurrentUserLocale();
  const { currencyCode } = rfx.useStructure<Live>();

  return useMemo(() => {
    return recipients
      .map(recipient => {
        const bidTotalAmount = bidTotalByRecipientId[recipient._id];

        const formattedBidTotalAmount = isFinite(bidTotalAmount)
          // @ts-expect-error ts(2345) FIXME: Argument of type 'number | null' is not assignable to parameter of type 'number'.
          ? localeFormatPrice(bidTotalAmount, currencyCode, { locale, showCode: true })
          : null;

        const description = formattedBidTotalAmount
          ? (
            <Box as="span" fontSize={2}>
              {t('request.awardFlow.steps.chooseRequestLevelAwardSupplier.bidTotal', { bidTotal: formattedBidTotalAmount })}
            </Box>
          )
          : null;

        return {
          value: recipient._id,
          label: (
            <Box fontSize={4} color="text" mt={description ? '-5px' : undefined}>
              <Box as="span" sx={{ position: 'relative', top: '-1px' }}>
                <CompanyLogo size="xs" companyId={recipient._id} />
              </Box>
              {recipient.company.name}
            </Box>
          ),
          description,
        };
      });
  }, [recipients, bidTotalByRecipientId, currencyCode, locale, t]);
};

const getSubmissionDataFromFormValues = ({
  awardedSupplierId,
}: {
  awardedSupplierId?: string;
}, bidTotalByRecipientId: Record<string, number | null>) => {
  return {
    requestAward: awardedSupplierId
      ? {
        recipientId: awardedSupplierId,
        requestCurrencyAwardedCost: bidTotalByRecipientId[awardedSupplierId],
      }
      : null,
  };
};

export const ChooseRequestLevelAwardSupplierStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (data: Partial<AwardFlowData> | null, direction: Direction) => void,
}) => {
  const { t } = useTranslation('translation');
  const { costAndSavingsByRecipientId } = rfx.useCostAndSavingsData();
  const recipients = rfx.useSortedBiddingRecipients({ excludeUnsuccessful: true });
  const bidTotalByRecpientId = useBidTotalByRecipientId(recipients, costAndSavingsByRecipientId);
  const options = useSupplierOptions(recipients, bidTotalByRecpientId);

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      awardedSupplierId: z.string({ message: t('request.awardFlow.errors.selectSupplier') }),
    });

    return toFormikValidationSchema(Schema);
  }, [t]);

  return (
    <Formik
      validateOnBlur
      initialValues={{
        awardedSupplierId: data.requestAward?.recipientId,
      }}
      validationSchema={validationSchema}
      onSubmit={noop}
    >
      {({ values, submitForm, validateForm, dirty }) => (
        <Form>
          <lotPagesLayout.ContentWrapper>
            <StepNavigation
              onBackClick={() => {
                // @ts-expect-error ts(2345) FIXME: Argument of type '{ requestAward: { recipientId: string; requestCurrencyAwardedCost: number | null; } | null; } | null' is not assignable to parameter of type 'Partial<AwardFlowData> | null'.
                submitAndNavigate(dirty ? getSubmissionDataFromFormValues(values, bidTotalByRecpientId) : null, Direction.BACK);
              }}
              onContinueClick={async () => {
                const errors = await validateForm();

                await submitForm();

                if (isEmpty(errors)) {
                  // @ts-expect-error ts(2345) FIXME: Argument of type '{ requestAward: { recipientId: string; requestCurrencyAwardedCost: number | null; } | null; } | null' is not assignable to parameter of type 'Partial<AwardFlowData> | null'.
                  submitAndNavigate(dirty ? getSubmissionDataFromFormValues(values, bidTotalByRecpientId) : null, Direction.FORWARD);
                }
              }}
            >
              <FormErrors />
              <lotPagesLayout.Section heading={t('request.awardFlow.steps.chooseRequestLevelAwardSupplier.heading')}>
                <Box mt="20px">
                  <RadioField
                    name="awardedSupplierId"
                    options={options}
                    showError
                    gap="20px"
                    errorMessageStyle={{ fontSize: '14px', marginTop: '16px' }}
                  />
                </Box>
                <lotPagesLayout.InfoText mt="20px">
                  {t('request.awardFlow.steps.chooseRequestLevelAwardSupplier.info')}
                </lotPagesLayout.InfoText>
              </lotPagesLayout.Section>
            </StepNavigation>
          </lotPagesLayout.ContentWrapper>
        </Form>
      )}
    </Formik>
  );
};
