import { QuestionnaireOverview } from '@deepstream/common/preQual';
import { useQuery } from 'react-query';
import { useApi, wrap } from '../../api';
import { useCompanyFeatureFlags } from '../../companyFeatureFlags';
import { useCurrentCompanyId } from '../../currentCompanyId';

export type RecipientQuestionnaires = {
  _id: string;
  name: string;
  questionnaires: Pick<QuestionnaireOverview, '_id' | 'name' | 'meta' | 'status' | 'recipient'>[];
};

export const useSentQuestionnairesByRecipient = () => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const companyFeatureFlags = useCompanyFeatureFlags();

  const { data: questionnairesByRecipient = [], status } = useQuery<RecipientQuestionnaires[]>(
    ['sentQuestionnairesByRecipient', { currentCompanyId }],
    wrap(api.getSentQuestionnairesByRecipient),
    {
      enabled: companyFeatureFlags.preQualEnabled,
    },
  );

  return {
    questionnairesByRecipient,
    status,
  };
};
