import { useMemo } from 'react';
import { Draft, StageType } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { compact } from 'lodash';
import * as rfx from '../rfx';

export const useStageSelectItems = (stageIds?: string[]) => {
  const { t } = useTranslation();
  const { stages } = rfx.useStructure<Draft>();

  return useMemo(
    () => {
      return compact(stages.map((stage, index) => {
        if (stageIds && !stageIds.includes(stage._id)) {
          return null;
        }

        const label = stage.type === StageType.AUCTION
          ? t('general.auction')
          : stage.name;

        return {
          value: stage._id,
          label: label
            ? `${index + 1} – ${label}`
            : `${index + 1}`,
          type: stage.type,
          disabled: stage.type === StageType.AUCTION,
        };
      }));
    },
    [stages, t, stageIds],
  );
};
