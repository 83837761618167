import { noop, cloneDeep, pick, flatMap, map } from 'lodash';
import { useMemo, useCallback } from 'react';
import * as React from 'react';
import { Formik } from 'formik';
import { Text, FlexProps, Flex } from 'rebass/styled-components';
import * as PreQ from '@deepstream/common/legacy-pre-q-utils';
import { useTranslation } from 'react-i18next';
import { diffObject } from '@deepstream/utils';
import { EditButton, Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelText, PanelHeader, PanelDivider, SidebarPanelHeading } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useIntercom } from 'react-use-intercom';
import { SidebarColumn, MainColumn, Row } from '../ui/ProfileLayout';
import { FixedToolbar } from '../ui/FixedToolbar';
import { UploadFn } from '../ui/types';
import { Progress } from './Progress';
import { Question } from './Question';
import { FormButtons } from './FormButtons';
import { useDistanceFromWindow } from './useDistanceFromWindow';
import * as Visibility from './Visibility';
import { IsLoggedInProvider } from './IsLoggedInProvider';
import { Tabs, TabListPanel, TabPanels, Tab, TabPanel } from '../ui/TabsVertical';
import { SystemFeatureFlags } from '../types';
import { CallToActionPanel } from '../ui/CallToActionPanel';

type QuestionnaireProps = {
  companyId: string;
  currentCompanyId: string;
  preQualAccessStatus: 'approved' | 'denied' | 'pending' | null;
  questionnaire: PreQ.Questionnaire;
  answerSet: PreQ.AnswerSet;
  isReadOnly?: boolean;
  disabled?: boolean;
  isLocked?: boolean;
  canEdit?: boolean;
  isLoggedIn?: boolean;
  save?: any;
  upload?: UploadFn;
  download?: (...args: any[]) => void;
  searchCompanies?: any;
  approvePreQualAccess?: any;
  denyPreQualAccess?: any;
  revokePreQualAccess?: any;
  requestPreQualAccess?: any;
  navigateToAuditTrail?: () => void;
  navigateToProfile?: () => void;
  systemFeatureFlags?: SystemFeatureFlags;
} & FlexProps;

export const Questionnaire: React.FC<QuestionnaireProps> = ({
  questionnaire,
  answerSet,
  disabled,
  isLoggedIn,
  isLocked,
  canEdit,
  save = noop,
  upload,
  download,
  searchCompanies,
  approvePreQualAccess,
  denyPreQualAccess,
  revokePreQualAccess,
  requestPreQualAccess,
  preQualAccessStatus,
  currentCompanyId,
  companyId,
  navigateToAuditTrail,
  navigateToProfile,
  systemFeatureFlags,
  ...props
}) => {
  const intercom = useIntercom();
  const { t } = useTranslation(['companyProfile', 'general']);

  const isTeamMember = currentCompanyId === companyId;
  const [isReadOnly, setReadOnly] = React.useState(true);

  const allQuestionIds = useMemo(
    () => flatMap(questionnaire.categories, category => map(category.questions, 'key')),
    [questionnaire],
  );

  const initialValues = useMemo(
    () => cloneDeep(pick(answerSet.answers, allQuestionIds)),
    [answerSet.answers, allQuestionIds],
  );

  const categorySummaries = useMemo(
    () => {
      const answeredQuestionnaire = new PreQ.AnsweredPreQQuestionnaire(
        new PreQ.PreQQuestionnaire(questionnaire),
        new PreQ.PreQAnswerSet(answerSet),
      );

      return answeredQuestionnaire.categorySummaries;
    },
    [questionnaire, answerSet],
  );

  const saveChanges = useCallback(
    async values => {
      const { added, updated } = diffObject(values, initialValues);
      const changedQuestionIds = map([...added, ...updated], 'key');
      const changedValues = pick(values, changedQuestionIds);

      await save(changedValues);

      setReadOnly(true);
    },
    [save, initialValues, setReadOnly],
  );

  const [toolbarPaddingRight, ref] = useDistanceFromWindow({
    edge: 'right',
    when: !isReadOnly,
  });

  React.useEffect(
    () => {
      intercom.update({
        verticalPadding: isReadOnly ? 20 : 80,
      });
    },
    [intercom, isReadOnly],
  );

  React.useEffect(
    () => {
      // Users who don't have access aren't supposed to be able to see the questionnaire. If they manipulate the URL
      // to get here, we should redirect them to the profile page.
      if (systemFeatureFlags?.newPreQualEnabled && !isTeamMember && preQualAccessStatus !== 'approved') {
        // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
        navigateToProfile();
      }
    },
    [systemFeatureFlags, isTeamMember, preQualAccessStatus, navigateToProfile],
  );

  return (
    <Tabs>
      <Row mx={0} {...props}>
        <SidebarColumn mb={6}>
          {isTeamMember ? (
            <Visibility.Overview
              answerSet={answerSet}
              canEdit={canEdit}
              searchCompanies={searchCompanies}
              approvePreQualAccess={approvePreQualAccess}
              denyPreQualAccess={denyPreQualAccess}
              revokePreQualAccess={revokePreQualAccess}
              systemFeatureFlags={systemFeatureFlags}
            />
          ) : (
            <Visibility.AccessStatus
              preQualAccessStatus={preQualAccessStatus}
              requestPreQualAccess={requestPreQualAccess}
            />
          )}
          <TabListPanel heading={t('questionnaire.categories')}>
            {questionnaire.categories.map(({ key }) => (
              <Tab key={key}>
                <Flex justifyContent="space-between">
                  <Text>{t(`questionnaire.category.${key}`)}</Text>
                  <Text>{categorySummaries[key].numCompleted}</Text>
                </Flex>
              </Tab>
            ))}
          </TabListPanel>
          {!systemFeatureFlags?.newPreQualEnabled && (
            <Progress
              questionnaire={questionnaire}
              answerSet={answerSet}
            />
          )}
          {isTeamMember && (
            <Panel padded>
              <Flex alignItems="center" justifyContent="space-between">
                <SidebarPanelHeading>
                  {t('auditTrail.auditTrail')}
                </SidebarPanelHeading>
                <Button small variant="primary-outline" onClick={navigateToAuditTrail}>
                  {t('open', { ns: 'general' })}
                </Button>
              </Flex>
            </Panel>
          )}
        </SidebarColumn>
        <MainColumn ref={ref} mb={6}>
          {!isLoggedIn && (
            <CallToActionPanel heading={t('questionnaire.cta.heading')} mb={20}>
              <Text fontSize={2}>
                {t('questionnaire.cta.body')}
              </Text>
            </CallToActionPanel>
          )}
          <IsLoggedInProvider isLoggedIn={!!isLoggedIn}>
            <Formik initialValues={initialValues} onSubmit={saveChanges}>
              {({ handleSubmit, resetForm }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <TabPanels>
                    {questionnaire.categories.map(({ key, questions }, index) => (
                      <TabPanel tabIndex={index} key={key}>
                        <Panel>
                          <PanelHeader heading={t(`questionnaire.category.${key}`)}>
                            {/*
                             // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'. */}
                            {isReadOnly && (canEdit || isTeamMember) && !systemFeatureFlags.newPreQualEnabled && (
                              <EditButton small disabled={!canEdit} onClick={() => setReadOnly(false)} />
                            )}
                          </PanelHeader>
                          <PanelDivider />
                          <PanelText lineHeight={1}>
                            <Stack gap={20}>
                              {questions.map(question => (
                                <Question
                                  key={question.key}
                                  question={question}
                                  answer={answerSet?.answers?.[question.key]}
                                  isReadOnly={isReadOnly}
                                  isLocked={isLocked}
                                  upload={upload}
                                  download={download}
                                />
                              ))}
                            </Stack>
                            <FixedToolbar isOpen={!isReadOnly} pr={toolbarPaddingRight}>
                              <FormButtons
                                disabled={disabled}
                                onCancel={() => {
                                  resetForm();
                                  setReadOnly(true);
                                }}
                              />
                            </FixedToolbar>
                          </PanelText>
                        </Panel>
                      </TabPanel>
                    ))}
                  </TabPanels>
                </form>
              )}
            </Formik>
          </IsLoggedInProvider>
        </MainColumn>
      </Row>
    </Tabs>
  );
};
