import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box } from 'rebass/styled-components';
import { useQuery } from 'react-query';
import { QuestionnaireStatus } from '@deepstream/common/preQual';
import { IconTextButton } from '@deepstream/ui-kit/elements/button/IconTextButton';
import { compact } from 'lodash';
import { isCompanySuperUser } from '@deepstream/common/user-utils';
import * as Layout from '../../../Layout';
import { PageHeading } from '../../../page-headers/PageHeading';
import { useApi, wrap } from '../../../api';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { QuestionnaireProvider, useQuestionnaireData, useQuestionnaireId, useQuestionnairePermissions } from './questionnaireUtils';
import { useQuestionnaire } from './useQuestionnaire';
import { LabeledValue } from '../../../draft/LabeledValue';
import { ProgressPercentage } from '../../../ui/ProgressPercentage';
import { LabeledQuestionnaireStatus } from './QuestionnaireStatus';
import { SubmitQuestionnaireButton } from './SubmitQuestionnaireButton';
import { RecipientQuestionnaireActions } from './RecipientQuestionnaireActions';
import { usePreQualNavigation } from '../../../AppRouting';
import { QuestionnairePageTab } from './utils';
import { useSearch } from '../../../tanstackRouter';
import { useSystemFeatureFlags } from '../../../systemFeatureFlags';
import { useCurrentUser } from '../../../useCurrentUser';
import { SenderQuestionnaireActions } from './SenderQuestionnaireActions';
import { QuestionnaireTemplateProvider } from '../QuestionnaireTemplate/questionnaireTemplateUtils';
import { useActiveQuestionnaireTemplateNavigation, useQuestionnaireNavigation } from '../../../appNavigation';

const labelProps = { color: 'text' };

const HeadingButtons = () => {
  const questionnaire = useQuestionnaireData();
  const { canSubmitQuestionnaire, canUpdateQuestionnaire } = useQuestionnairePermissions();

  const isInProgress = questionnaire.status === QuestionnaireStatus.IN_PROGRESS;
  const isPendingOrApproved = [QuestionnaireStatus.PENDING_REVIEW, QuestionnaireStatus.APPROVED].includes(questionnaire.status);

  return (
    <>
      {canSubmitQuestionnaire && isInProgress && <SubmitQuestionnaireButton />}
      {canUpdateQuestionnaire && isPendingOrApproved && <RecipientQuestionnaireActions />}
    </>
  );
};

type HeaderProps = {
  selectedTabId: string;
};

export const Header = ({ selectedTabId }: HeaderProps) => {
  const { t } = useTranslation(['preQualification', 'general', 'translation']);
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const questionnaireId = useQuestionnaireId({ required: true });
  const activeNavigation = useActiveQuestionnaireTemplateNavigation();
  const questionnaireNavigation = useQuestionnaireNavigation();
  const { navigateToPreQualificationSuppliers } = usePreQualNavigation();
  const api = useApi();
  const search = useSearch({ strict: false }) as { from?: 'template' | 'suppliers' };
  const systemFeatureFlags = useSystemFeatureFlags();
  const isSuperUser = isCompanySuperUser(currentUser, currentCompanyId);

  const { data: questionnaire } = useQuestionnaire({
    questionnaireId,
  });

  const templateId = questionnaire?.meta?.questionnaireTemplateId;
  const isRecipient = questionnaire?.recipient?._id === currentCompanyId;

  const { data: questionnaireTemplate } = useQuery(
    ['questionnaireTemplate', {
      currentCompanyId,
      templateId,
      scope: 'current',
    }],
    wrap(api.getQuestionnaireTemplate),
    { enabled: Boolean(templateId) && !isRecipient },
  );

  const tabs = useMemo(
    () => compact([
      {
        id: QuestionnairePageTab.QUESTIONS,
        name: t('question_other'),
        navigate: () => questionnaireNavigation.navigateToQuestions(),
      },
      {
        id: QuestionnairePageTab.DETAILS,
        name: t('general.details', { ns: 'translation' }),
        navigate: () => questionnaireNavigation.navigateToDetails(),
      },
      {
        id: QuestionnairePageTab.TEAM,
        name: t('general.team', { ns: 'translation' }),
        navigate: () => questionnaireNavigation.navigateToTeam(),
      },
      // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'.
      systemFeatureFlags.questionnaireAuditEnabled && {
        id: QuestionnairePageTab.AUDIT,
        name: t('general.audit', { ns: 'translation' }),
        navigate: () => questionnaireNavigation.navigateToAudit(),
      },
    ]),
    // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'.
    [questionnaireNavigation, systemFeatureFlags.questionnaireAuditEnabled, t],
  );

  const { numRequirements, numResolvedRequirements } = questionnaire || {};

  const isTeamMember = questionnaire
    ? Boolean(questionnaire.teamById[currentCompanyId].users[currentUser._id])
    : false;

  return (
    <Layout.PageHeader
      tabs={tabs}
      selectedTabId={selectedTabId}
      heading={() => questionnaire && (
        <Box>
          {/*
           // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'. */}
          {['template', 'suppliers'].includes(search?.from) ? (
            <IconTextButton
              icon="chevron-left"
              color="info"
              fontSize={2}
              fontWeight={400}
              mb={1}
              onClick={() => search?.from === 'suppliers' ? navigateToPreQualificationSuppliers() : activeNavigation.navigateToSuppliers(templateId)}
            >
              {t('questionnaire.backToSuppliers')}
            </IconTextButton>
          ) : null}
          <PageHeading
            icon="file-check"
            iconProps={{ regular: true }}
            text={isRecipient ? questionnaire.summary.name : t('supplierName', { supplierName: questionnaire.recipient.name })}
          />
        </Box>
      )}
    >
      {questionnaire && (
        <QuestionnaireProvider questionnaire={questionnaire}>
          <Flex flex="0 0 auto" sx={{ gap: 4 }} ml={4}>
            {!isRecipient && (
              <LabeledValue
                align="left"
                label={t('questionnaire.progress.complete')}
                value={(
                  // @ts-expect-error ts(18048) FIXME: 'numResolvedRequirements' is possibly 'undefined'.
                  <ProgressPercentage progress={numResolvedRequirements / numRequirements} width={14} />
                )}
                labelProps={labelProps}
              />
            )}
            <LabeledQuestionnaireStatus
              status={questionnaire.status}
              isRecipient={isRecipient}
            />
            {!isRecipient && (
              <LabeledValue
                align="left"
                label={t('version')}
                value={(
                  questionnaireTemplate?.version
                    ? t('countOfTotal', {
                        count: questionnaire.meta.questionnaireTemplateVersion,
                        total: questionnaireTemplate?.version,
                        ns: 'general' })
                    : questionnaire.meta.questionnaireTemplateVersion
                )}
                labelProps={labelProps}
              />
            )}
            <HeadingButtons />
            {!isRecipient && (isSuperUser || isTeamMember) && questionnaireTemplate && (
              <QuestionnaireTemplateProvider template={questionnaireTemplate}>
                <SenderQuestionnaireActions />
              </QuestionnaireTemplateProvider>
            )}
          </Flex>
        </QuestionnaireProvider>
      )}
    </Layout.PageHeader>
  );
};
