import { useContext, useCallback, useMemo } from 'react';
import { ThemeContext } from 'styled-components';
import { Box, Flex } from 'rebass/styled-components';
import { find, mapValues, filter, conforms, values, flatMap, keys } from 'lodash';
import { PageType, Page, isEvaluationPage, renderPageName, Live } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils';
import { Tabs, TabList, Tab, dividerTabStyle, TabDivider } from '../ui/Tabs';
import { useCurrentCompanyId } from '../currentCompanyId';
import { NotificationCounter } from '../modules/Notifications/NotificationCounter';
import { Counter } from '../ui/Badge';
import * as rfx from '../rfx';
import { useRfqId } from '../useRfq';
import { RfxStructure } from '../types';
import { useRequestSentNavigation } from '../appNavigation';

export const getExchangeIdsForPage = (page: Page, bid: RfxStructure['bidById'][string]) =>
  flatMap(
    page.sections,
    sectionId => keys(bid.sectionById[sectionId].exchangeStateById),
  );

export const getExchangeStatesForPage = (page: Page, bid: RfxStructure['bidById'][string]) =>
  flatMap(
    page.sections,
    sectionId => values(bid.sectionById[sectionId].exchangeStateById),
  );

type TabsProps = {
  selectedPageId?: string;
  selectedTabId?: string;
};

export const BidPageSenderTabs = ({
  selectedPageId,
  selectedTabId,
}: TabsProps) => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);

  const rfqId = useRfqId({ required: true });
  const currentCompanyId = useCurrentCompanyId()!;
  const { pages, pageById, enteredBidPageIds } = rfx.useStructure<Live>();
  const bid = rfx.useBid();
  const navigation = useRequestSentNavigation();

  const chatPage = find(pages, { type: PageType.CHAT });

  const tabbedPages = pages.filter(page => enteredBidPageIds!.includes(page._id)).concat(chatPage ?? []);

  const hasEvaluationPage = Boolean(find(pages, isEvaluationPage));

  const selectedPage = pages.find(page => page._id === selectedPageId);
  const selectedPageIndex = tabbedPages.findIndex(page => page._id === selectedPageId);

  // If there is no selectedPageId it means we are on the Evaluation, Team or Audit page
  const extraTabs = ['evaluation', 'team', 'history'];
  // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
  const extraTabIndex = tabbedPages.length + extraTabs.indexOf(selectedTabId);
  const selectedTabIndex = selectedPageId && selectedPage?.type !== PageType.EVALUATION
    ? selectedPageIndex
    : hasEvaluationPage ? extraTabIndex : extraTabIndex - 1;

  const onTabsChange = useCallback(
    (index) => {
      const selectedPage = tabbedPages[index];
      const isEvaluationTab = hasEvaluationPage && index === tabbedPages.length;
      const isTeamTab = hasEvaluationPage ? index === tabbedPages.length + 1 : index === tabbedPages.length;
      const isAuditTab = hasEvaluationPage
        ? index === tabbedPages.length + 2
        : index === tabbedPages.length + 1;

      if (isEvaluationTab) {
        navigation.navigateToSupplierBidEvaluation();
      } else if (isAuditTab) {
        navigation.navigateToSupplierAudit();
      } else if (isTeamTab) {
        navigation.navigateToSupplierTeam();
      } else {
        navigation.navigateToSupplierBid(selectedPage._id);
      }
    },
    [tabbedPages, hasEvaluationPage, navigation],
  );

  const messagesNotificationFilter = useMemo(
    () => {
      // Very old requests don't have a chat page
      if (!chatPage) return {};

      const exchangeIds = getExchangeIdsForPage(chatPage, bid);

      return conforms({
        to: to => to.companyId === currentCompanyId,
        domain: domain => domain === NotificationDomain.RFQ_SENT,
        action: action => action === NotificationAction.EXCHANGE_REPLY_SENT,
        meta: meta => meta.rfqId === rfqId && exchangeIds.includes(meta.exchangeId),
      });
    },
    [chatPage, bid, currentCompanyId, rfqId],
  );

  const numActionsRequiredByPageId = useMemo(
    () => mapValues(
      pageById,
      page => filter(
        getExchangeStatesForPage(page, bid),
        exchangeState => !exchangeState.isResolved && !exchangeState.isRecipientsTurn,
      ).length,
    ),
    [pageById, bid],
  );

  return (
    <Box sx={{ maxWidth: '1200px', margin: '0px auto' }}>
      <Tabs index={selectedTabIndex} canOverflow onChange={onTabsChange}>
        <TabList
          style={{
            backgroundColor: theme.colors.white,
            fontSize: theme.fontSizes[2],
            border: 'none',
          }}
        >
          {tabbedPages.map((page) => (
            <Tab key={page._id} style={{ padding: '8px 12px' }}>
              <Flex alignItems="center">
                {page.type === PageType.CHAT && <Icon icon="comment-o" height="13px" mr={1} />}
                {renderPageName(page, t)}
                {page.type === PageType.CHAT ? (
                  <NotificationCounter filter={messagesNotificationFilter} ml={1} />
                ) : page.type === PageType.AUCTION ? (
                  null
                ) : (
                  <Counter count={numActionsRequiredByPageId[page._id]} ml={1} />
                )}
              </Flex>
            </Tab>
          ))}
          {hasEvaluationPage && (
            <Tab style={{ padding: '8px 12px', ...dividerTabStyle }}>
              {/* Render the Divider as part of a Tab and position it absolutely, otherwise it messes up the tab HighlightBar */}
              <TabDivider />
              {t('request.evaluation.evaluation')}
            </Tab>
          )}
          <Tab style={{ padding: '8px 12px', ...(!hasEvaluationPage ? dividerTabStyle : {}) }}>
            {/* Render the Divider as part of a Tab and position it absolutely, otherwise it messes up the tab HighlightBar */}
            {!hasEvaluationPage && <TabDivider />}
            {t('request.team.team')}
          </Tab>
          <Tab style={{ padding: '8px 12px' }}>
            {t('request.audit.audit')}
          </Tab>
        </TabList>
      </Tabs>
    </Box>
  );
};
