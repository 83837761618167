import { useMemo } from 'react';
import { ExchangeType, LotIntentionStatus, getScore, getScores, isEvaluationPage, isLinkedEvaluationSection } from '@deepstream/common/rfq-utils';
import { first, groupBy, isNil, mapValues, update } from 'lodash';
import { useCurrentCompanyId } from '../../../../currentCompanyId';
import { useCurrentUser } from '../../../../useCurrentUser';
import * as rfx from '../../../../rfx';
import { EvaluationExchangeSnapshot } from '../../../../types';
import { requestBidStatusAllowsScoreSubmissions } from '../../../NewEvaluation/utils';

export type EvaluationActionStats = {
  numActions?: number;
  numOwnActions?: number;
  numPendingActions?: number;
  numPendingOwnActions?: number;
};

export type EvaluationStats = {
  canEvaluateByLotId: Record<string, boolean>;
  statsByLotIdByRecipientId: Record<string, Record<string, EvaluationActionStats>>;
};

/**
 * Evaluation stats for a specific selection of lots.
 */
export type SpecificEvaluationStats = {
  canEvaluateLots: boolean;
  statsByRecipientId: Record<string, EvaluationActionStats>;
};

export const useEvaluationStats = (): EvaluationStats => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const structure = rfx.useStructure();
  const permissionsByPageId = rfx.usePagesPermissions();
  const exchanges = rfx.useExchanges();

  return useMemo(() => {
    const nonObsoleteEvaluationExchanges = exchanges.filter(exchange =>
      exchange.def.type === ExchangeType.EVALUATION_CRITERION &&
      !exchange.isObsolete,
    ) as EvaluationExchangeSnapshot[];

    const canEvaluateByLotId: Record<string, boolean> = {};

    const statsByLotIdByRecipientId: Record<string, Record<string, EvaluationActionStats>> = {};

    const evaluationExchangeBySectionId = groupBy(
      nonObsoleteEvaluationExchanges,
      exchange => exchange.def.sectionId,
    );

    const evaluationExchangeByRecipientIdBySectionId = mapValues(
      evaluationExchangeBySectionId,
      exchanges => groupBy(
        exchanges,
        exchange => exchange.recipientId,
      ),
    );

    for (const page of structure.pages) {
      if (isEvaluationPage(page)) {
        const isEvaluator = permissionsByPageId[page._id].canRespond;

        for (const sectionId of page.sections) {
          const section = structure.sectionById[sectionId];
          const originalSection = isLinkedEvaluationSection(section)
            ? structure.sectionById[section.linkedSectionId]
            : section;
          const lotId = first(originalSection.lotIds);

          const exchangesByRecipientId = evaluationExchangeByRecipientIdBySectionId[sectionId] ?? [];

          for (const [recipientId, exchanges] of Object.entries(exchangesByRecipientId)) {
            const bid = structure.bidById[recipientId];

            // @ts-expect-error ts(2345) FIXME: Argument of type 'BidStatus | null' is not assignable to parameter of type 'BidStatus'.
            if (!requestBidStatusAllowsScoreSubmissions(bid.status)) {
              continue;
            }

            if (lotId && bid.intentionStatusByLotId[lotId] !== LotIntentionStatus.BIDDING) {
              continue;
            }

            for (const exchange of exchanges) {
              if (isEvaluator) {
                if (!canEvaluateByLotId[lotId || 'general']) {
                  canEvaluateByLotId[lotId || 'general'] = true;
                }

                update(
                  statsByLotIdByRecipientId,
                  [recipientId, lotId || 'general', 'numOwnActions'],
                  (actions = 0) => actions + 1,
                );

                if (isNil(getScore(exchange, { companyId: currentCompanyId, userId: currentUser._id }))) {
                  update(
                    statsByLotIdByRecipientId,
                    [recipientId, lotId || 'general', 'numPendingOwnActions'],
                    (actions = 0) => actions + 1,
                  );
                }
              }

              const scores = getScores(exchange);

              update(
                statsByLotIdByRecipientId,
                [recipientId, lotId || 'general', 'numActions'],
                (actions = 0) => actions + scores.length,
              );

              update(
                statsByLotIdByRecipientId,
                [recipientId, lotId || 'general', 'numPendingActions'],
                (actions = 0) => actions + scores.filter(isNil).length,
              );
            }
          }
        }
      }
    }

    return {
      canEvaluateByLotId,
      statsByLotIdByRecipientId,
    };
  }, [currentCompanyId, currentUser._id, exchanges, permissionsByPageId, structure]);
};
