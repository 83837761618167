import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AuctionStatus, Draft, StageType, renderStageName } from '@deepstream/common/rfq-utils';
import * as yup from 'yup';
import { compact, findIndex, isFunction, isNil, isNumber, some, values } from 'lodash';
import { CellProps } from 'react-table';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { withProps } from '@deepstream/ui-utils/withProps';
import { EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelHeader } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import * as rfx from './rfx';
import * as draft from './draft/draft';
import { Validation } from './draft/validation';
import { ErrorPanel } from './ui/ErrorMessage';
import { LoadingPanel } from './ui/Loading';
import { RfqIdProvider, useDraftRfqStructure, useLiveRfqStructure } from './useRfq';
import { useCurrentCompanyId } from './currentCompanyId';
import { useDeviceSize } from './ui/useDeviceSize';
import { nestCells } from './nestCells';
import { StaticTableStyles } from './TableStyles';
import { Table } from './Table';
import { ValidationErrorCell } from './draft/cell';
import { StageIcon } from './draft/StageIcon';
import { createTableRow, isAuctionStage } from './utils';
import { Datetime } from './Datetime';
import { useSystemFeatureFlags } from './systemFeatureFlags';
import { ValidationRowCells } from './draft/ValidationRowCells';
import { useCellValidationError } from './draft/useCellValidationError';
import { StageVisibility } from './StageVisibility';
import { getLowerBound, getUpperBound } from './draft/stageUtils';

const fieldName = 'stages';

const getStageDateField = (stage) => isAuctionStage(stage)
  ? 'startDate'
  : 'completionDeadline';

const StageNameCell = ({ row, cell, column }: CellProps<any>) => {
  const { t } = useTranslation();
  const systemFeatureFlags = useSystemFeatureFlags();
  const { auction, stages } = rfx.useStructure();
  const nameError = useCellValidationError({ fieldName, row, column });

  const stage = row.original;

  return (
    <Flex alignItems="center">
      {systemFeatureFlags?.auctionsEnabled && (
        <Box flex="0 0 auto">
          <StageIcon
            stageType={stage.type}
            width="20px"
            height="20px"
            iconWidth="100%"
            iconHeight="14px"
            sx={{ borderRadius: '4px' }}
            mr={2}
            p={1}
            iconProps={{
              fontSize: '10px',
            }}
          />
        </Box>
      )}
      <Text mr={2} sx={{ overflowWrap: 'normal' }}>
        {row.index + 1}.
      </Text>
      {nameError ? (
        cell.value
      ) : (
        <Truncate>
          {stage.name || isAuctionStage(stage) ? (
            renderStageName(stage, t)
          ) : stages.length > 1 ? (
            <EmDash />
          ) : (
            t('request.stages.singleStage')
          )}
          {systemFeatureFlags?.auctionsEnabled && isAuctionStage(stage) && auction?.status === AuctionStatus.CANCELLED ? (
            <>
              {' '}
              [{t('auctionCountdown.statusLabel.cancelled')}]
            </>
          ) : (
            null
          )}
        </Truncate>
      )}
    </Flex>
  );
};

const StageDateCell = ({ cell, row, column }: CellProps<any>) => {
  const { t } = useTranslation();
  const { isSmall } = useDeviceSize();
  const dateError = useCellValidationError({
    fieldName,
    row,
    column,
    customFieldKey: getStageDateField,
  });

  const stage = row.original;
  const date = isAuctionStage(stage) ? stage.startDate : stage.completionDeadline;

  return (
    <Flex>
      <Text flex="0 0 auto" width="62px" mr={2} color="subtext">
        {isAuctionStage(stage) ? t('general.start') : t('general.deadline')}
      </Text>
      {dateError ? (
        cell.value
      ) : (
        <Truncate>
          <Datetime value={date} isCondensed={isSmall} />
        </Truncate>
      )}
    </Flex>
  );
};

const StageDurationCell = ({ cell, row, column }: CellProps<any>) => {
  const { t } = useTranslation();
  const { sectionById } = rfx.useStructure<Draft>();
  const durationError = useCellValidationError({ fieldName, row, column });

  const stage = row.original;

  const duration = rfx.getAuctionLineItemsSection(sectionById, stage._id)?.auctionRules.duration;

  return isAuctionStage(stage) ? (
    <Flex>
      <Text flex="0 0 auto" width="62px" mr={2} color="subtext">
        {t('general.duration')}
      </Text>
      {durationError ? (
        cell.value
      ) : !isNil(duration) ? (
        <Truncate>
          {t('general.minuteShortCount', { count: duration })}
        </Truncate>
      ) : (
        <EmDash />
      )}
    </Flex>
  ) : (
    null
  );
};

const StageVisibilityCell = ({ row }: CellProps<any>) => (
  <StageVisibility stage={row.original} />
);

const StageFields = ({
  row,
  columns,
  ...props
}: any) => (
  <Stack gap={2} {...props}>
    {columns.map((column: any, index: number) => {
      const { Cell } = column;

      return isFunction(column.hideField) && column.hideField(row.original) ? (
        null
      ) : (
        <Box key={index}>
          <Cell
            cell={{ value: row.values[column.id ?? column.accessor] }}
            row={row}
            column={column}
          />
        </Box>
      );
    })}
  </Stack>
);

const StagesList = ({ columns }: { columns: any[] }) => {
  const { stages } = rfx.useStructure();

  const rows = stages.map((stage, index) => createTableRow({ index, data: stage, columns }));

  return (
    <Box
      as="ul"
      p={0}
      fontSize={2}
      sx={{
        listStyle: 'none',
        '> * + *': {
          borderTop: 'lightGray',
        },
      }}
    >
      {rows.map(row => (
        <Box key={row.original._id} as="li" p="12px 20px">
          <StageFields
            row={row}
            columns={columns}
          />
        </Box>
      ))}
    </Box>
  );
};

export const StagesTable = () => {
  const { isExtraSmall, isSmall } = useDeviceSize();
  const isSender = rfx.useIsSender();
  const { stages } = rfx.useStructure();

  const hasAuctionStage = useMemo(
    () => some(stages, { type: StageType.AUCTION }),
    [stages],
  );

  const columns = useMemo(
    () => compact([
      {
        id: 'name',
        Header: '',
        accessor: 'name',
        Cell: nestCells(StageNameCell, withProps(ValidationErrorCell, { fieldName })),
      },
      {
        id: 'date',
        Header: '',
        accessor: stage => stage[getStageDateField(stage)],
        Cell: nestCells(
          StageDateCell,
          withProps(
            ValidationErrorCell,
            {
              fieldName,
              customFieldKey: getStageDateField,
            },
          ),
        ),
        onlyDate: true,
      },
      hasAuctionStage
        ? {
          id: 'duration',
          accessor: 'duration',
          Header: '',
          Cell: nestCells(StageDurationCell, withProps(ValidationErrorCell, { fieldName })),
          // Hide the `duration` field in the StageFields for general stages (on mobile layout)
          hideField: stage => !isAuctionStage(stage),
        }
        : null,
      isSender
        ? {
          id: 'isPrivate',
          accessor: 'isPrivate',
          Header: '',
          Cell: StageVisibilityCell,
          width: '15%',
        }
        : null,
    ]),
    [hasAuctionStage, isSender],
  );

  return isExtraSmall || isSmall ? (
    <StagesList columns={columns} />
  ) : (
    <StaticTableStyles>
      <Table
        columns={columns}
        data={stages}
        CustomRowCells={withProps(ValidationRowCells, { fieldName })}
        hideHeader
      />
    </StaticTableStyles>
  );
};

const StagesPanel = ({
  navigateToDraftStages,
} : {
  navigateToDraftStages: () => void;
}) => {
  const { t } = useTranslation();
  const { sectionById } = rfx.useStructure<Draft>();
  const isPendingCollaborator = rfx.useIsPendingCollaborator();
  const { isReview, isTemplatePreview } = rfx.useState();
  const showValidationErrors = draft.useShowValidationErrors();
  const { stages } = rfx.useStructure();

  return (
    <Validation
      values={{
        stages,
      }}
      schema={showValidationErrors ? (
        yup.object().shape({
          stages: yup.array(
            yup
              .object()
              .shape({
                type: yup.string().oneOf(values(StageType)),
                name: stages.length === 1
                  ? yup.string()
                  : yup.string().required(t('general.required')),
                completionDeadline: yup
                  .date()
                  .nullable()
                  .transform((curr, orig) => orig === '' ? null : curr)
                  .when('type', {
                    is: type => type !== StageType.AUCTION,
                    then: schema => schema.required(t('general.required')),
                    otherwise: schema => schema.nullable(),
                  }),
                startDate: yup
                  .date()
                  .nullable()
                  .transform((curr, orig) => orig === '' ? null : curr)
                  .when('type', {
                    is: type => type === StageType.AUCTION,
                    then: schema => schema.required(t('general.required')),
                    otherwise: schema => schema.nullable(),
                  }),
                duration: yup
                  .mixed()
                  .when('type', {
                    is: type => type === StageType.AUCTION,
                    then: schema => schema.test({
                      name: 'required',
                      message: t('general.required'),
                      // eslint-disable-next-line object-shorthand
                      test: function() {
                        const duration = rfx.getAuctionLineItemsSection(sectionById, this.parent._id)?.auctionRules.duration;
                        return isNumber(duration);
                      },
                    }),
                    otherwise: schema => schema.nullable(),
                  }),
              })
              .test(
                'stageDeadlineOrder',
                'deadlineError',
                (stage) => {
                  const stageIndex = findIndex(stages, { _id: stage._id });
                  const previousStage = stages[stageIndex - 1];
                  const previousDeadline = getUpperBound(sectionById, previousStage);
                  const currentDeadline = getLowerBound(stage);

                  return !previousDeadline || !currentDeadline
                    ? true
                    : new Date(previousDeadline).getTime() < new Date(currentDeadline).getTime();
                },
              ),
          ),
        })
      ) : (
        yup.mixed()
      )}
    >
      <Panel>
        <PanelHeader heading={t('general.stage_other')} collapse={false}>
          {isReview && !isPendingCollaborator && !isTemplatePreview && (
            <EditButton
              small
              variant="primary"
              onClick={navigateToDraftStages}
            />
          )}
        </PanelHeader>
        <PanelDivider />
        <StagesTable />
      </Panel>
    </Validation>
  );
};

export const ReadOnlyStages = ({
  rfqId,
  recipientId,
  isReview,
  isRevising,
  isTemplate,
  isTemplatePreview,
  isLive,
  navigateToDraftStages,
}: {
  rfqId: string;
  recipientId?: string;
  isReview?: boolean;
  isRevising?: boolean;
  isTemplate?: boolean;
  isTemplatePreview?: boolean;
  isLive?: boolean;
  navigateToDraftStages: () => void;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const {
    data: rfxDraftStructure,
    isLoading: isLoadingDraft,
    isError: isErrorDraft,
  } = useDraftRfqStructure({
    rfqId,
    currentCompanyId,
    enabled: !isLive,
    isTemplate,
  });

  const {
    data: rfxLiveStructure,
    isLoading: isLoadingLive,
    isError: isErrorLive,
  } = useLiveRfqStructure({
    rfqId,
    currentCompanyId,
    recipientId,
    enabled: Boolean(isLive),
  });

  const isLoading = isLoadingDraft || isLoadingLive;
  const isError = isErrorDraft || isErrorLive;
  const rfxStructure = rfxDraftStructure || rfxLiveStructure;

  return isLoading ? (
    <LoadingPanel />
  ) : isError ? (
    <ErrorPanel error={t('errors.unexpected')} />
  ) : rfxStructure ? (
    <rfx.StateProvider
      isRevising={isRevising}
      isTemplate={isTemplate}
      isTemplatePreview={isTemplatePreview}
      isReview={isReview}
      isLive={isLive}
    >
      <RfqIdProvider rfqId={rfqId}>
        <rfx.StructureProvider structure={rfxStructure}>
          <StagesPanel navigateToDraftStages={navigateToDraftStages} />
        </rfx.StructureProvider>
      </RfqIdProvider>
    </rfx.StateProvider>
  ) : (
    null
  );
};
