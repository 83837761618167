import { ListConfigFlowStepType } from '../types';
import { ListTypeStep } from './ListTypeStep';
import { NameAndDescriptionStep } from './NameAndDescriptionStep';
import { ListRulesStep } from './ListRulesStep';
import { AddSuppliersStep } from './AddSuppliersStep';
import { ReviewStep } from './ReviewStep';
import { SuccessStep } from './SuccessStep';

export const stepComponentByType = {
  [ListConfigFlowStepType.NAME_AND_DESCRIPTION]: NameAndDescriptionStep,
  [ListConfigFlowStepType.LIST_TYPE]: ListTypeStep,
  [ListConfigFlowStepType.AUTOMATIC_LIST_RULES]: ListRulesStep,
  [ListConfigFlowStepType.ADD_SUPPLIERS]: AddSuppliersStep,
  [ListConfigFlowStepType.REVIEW]: ReviewStep,
  [ListConfigFlowStepType.SUCCESS]: SuccessStep,
};
