import { AuctionStatus, StageType, RfqStatus, BidStatus, Live } from '@deepstream/common/rfq-utils';
import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { Box, Flex } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil, last, omitBy } from 'lodash';
import { isPast } from 'date-fns';
import { DropdownMenu, DropdownMenuItem, DropdownMenuHeader, DropdownMenuDivider } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { AwardRequestModal } from './modules/Request/OutcomeModal/AwardRequestModal';
import { CloseRequestModal } from './modules/Request/OutcomeModal/CloseRequestModal';
import { useCurrentCompanyId } from './currentCompanyId';
import { RequestStages } from './RequestStages';
import { RequestStatus } from './RequestStatus';
import { DeadlineCountdown } from './DeadlineCountdown';
import { useDeviceSize } from './ui/useDeviceSize';
import { useConfirmDialog, useModalState } from './ui/useModalState';
import useDownload from './useDownload';
import { getRfqCostAndSavingsDataQueryKey, useDraftRfqStructureQueryKey, useLiveRfqStructureQueryKey, useRfqId } from './useRfq';
import { useCurrentUser, useCurrentUserLocale } from './useCurrentUser';
import { UnmappedSuppliersWarningDialog } from './UnmappedSuppliersWarningDialog';
import * as rfx from './rfx';
import { CreateContractFromRequestButton } from './modules/Contracts/CreateFromRequestModal';
import { useSystemFeatureFlags } from './systemFeatureFlags';
import { useUserFlags } from './UserFlagsContext';
import { useDefaultExternalSystem } from './useExternalSystems';
import { useApi, wrap } from './api';
import { useToaster } from './toast';
import { contractDraftSummaryRoute } from './AppRouting';
import { useRequestSentNavigation } from './appNavigation';
import { useNavigate } from './tanstackRouter';
import { CannotEndRequestModal } from './modules/Request/OutcomeModal/CannotEndRequestModal';
import { LockedExchangesModal } from './modules/Request/OutcomeModal/LockedExchangesModal';

const ReportsDropdown = () => {
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const download = useDownload();
  const { t } = useTranslation();
  const user = useCurrentUser();
  const locale = useCurrentUserLocale();

  const queryParams = new URLSearchParams(
    omitBy({
      csvSeparator: user.preferences?.csvSeparator,
      locale,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }, isNil) as Record<string, string>,
  );

  const downloadReport = async (report: string) => {
    await download(`/download/company/${currentCompanyId}/rfq/${rfqId}/${report}?${queryParams.toString()}`);
  };

  return (
    <DropdownMenu
      variant="secondary"
      iconLeft="file-alt"
      width="40px"
      header={<DropdownMenuHeader>{t('request.csvReports')}</DropdownMenuHeader>}
      style={{ justifyContent: 'center' }}
      menuStyle={{ padding: '8px 0px' }}
      rightAligned
    >
      <DropdownMenuItem onSelect={() => downloadReport('suppliers')}>
        {t('request.download.supplierStatusTable')}
      </DropdownMenuItem>
      <DropdownMenuItem onSelect={() => downloadReport('newAudit')}>
        {t('request.download.supplierAuditTrail')}
      </DropdownMenuItem>
      <DropdownMenuItem onSelect={() => downloadReport('emails')}>
        {t('request.download.emailDeliveryStatus')}
      </DropdownMenuItem>
      <DropdownMenuItem onSelect={() => downloadReport('approvals')}>
        {t('request.download.stageApprovals')}
      </DropdownMenuItem>
    </DropdownMenu>
  );
};

const RequestActionsDropdown = ({
  navigateToReviseRequest,
  awardRequestModal,
  closeRequestModal,
}: {
  navigateToReviseRequest: (rfqId: string) => void;
  awardRequestModal: ReturnType<typeof useModalState>;
  closeRequestModal: ReturnType<typeof useModalState>;
}) => {
  const { t } = useTranslation();
  const rfqId = useRfqId();
  const api = useApi();
  const queryClient = useQueryClient();
  const { bidById, recipients, stages, auction } = rfx.useStructure<Live>();
  const toaster = useToaster();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const { canAwardOrCloseRfx } = rfx.useRfxPermissions();
  const structure = rfx.useStructure<Live>();
  const cannotEndRequestModal = useModalState();
  const lockedExchangesModal = useModalState();
  const { confirm, ...unmappedSuppliersWarningDialog } = useConfirmDialog();
  const [unmappedSupplierIds, setUnmappedSupplierIds] = React.useState<string[] | null>(null);
  const sentNavigation = useRequestSentNavigation();
  const draftStructureQueryKey = useDraftRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
    isTemplate: false,
  });

  const defaultExternalSystem = useDefaultExternalSystem();

  const isAwardRequestDisabled = !recipients.some(recipient => {
    const bidStatus = bidById[recipient._id].status;

    return bidStatus !== BidStatus.UNSUCCESSFUL;
  });

  const isAdmin = currentUser.roles[currentCompanyId]?.admin;

  const handleAwardClick = async () => {
    try {
      let supplierIds;

      if (defaultExternalSystem?.systemId) {
        supplierIds = await api.getUnmappedCompanies({
          companyId: currentCompanyId,
          companyIds: recipients.map(recipient => recipient._id),
          systemId: defaultExternalSystem.systemId,
        });
      }

      if (!isEmpty(supplierIds)) {
        setUnmappedSupplierIds(supplierIds);
        confirm(() => awardRequestModal.open());
      } else {
        awardRequestModal.open();
      }
    } catch (err) {
      toaster.error(t('errors.unexpected'));
    }
  };

  const handleAwardOrCloseClick = async () => {
    if (stages.some(stage => rfx.isStageActive(stage, auction))) {
      cannotEndRequestModal.open();
    } else {
      try {
        const { isLocked } = await queryClient.fetchQuery({
          queryKey: getRfqCostAndSavingsDataQueryKey({ currentCompanyId, rfqId }),
          queryFn: wrap(api.getRfqCostAndSavingsData),
        });

        if (isLocked) {
          lockedExchangesModal.open();
        } else {
          let supplierIds;

          if (defaultExternalSystem?.systemId) {
            supplierIds = await api.getUnmappedCompanies({
              companyId: currentCompanyId,
              companyIds: recipients.map(recipient => recipient._id),
              systemId: defaultExternalSystem.systemId,
            });
          }

          if (isEmpty(supplierIds)) {
            sentNavigation.navigateToAwardFlow();
          } else {
            setUnmappedSupplierIds(supplierIds);
            confirm(() => sentNavigation.navigateToAwardFlow());
          }
        }
      } catch (error) {
        toaster.error(t('errors.unexpected'));
      }
    }
  };

  return (
    <>
      <Box mr={2}>
        <DropdownMenu
          variant="secondary"
          buttonText={t('request.requestActions')}
          iconRight="caret-down"
        >
          {canAwardOrCloseRfx && structure.newFeaturesDisabled && (
            <>
              <DropdownMenuItem
                icon="trophy"
                onSelect={handleAwardClick}
                disabled={isAwardRequestDisabled}
              >
                {t('request.awardRequest')}
              </DropdownMenuItem>
              <DropdownMenuItem icon="times" onSelect={closeRequestModal.open}>
                {t('request.closeRequest')}
              </DropdownMenuItem>
              <DropdownMenuDivider />
            </>
          )}
          {canAwardOrCloseRfx && !structure.newFeaturesDisabled && (
            <DropdownMenuItem
              icon="trophy"
              onSelect={handleAwardOrCloseClick}
              disabled={isAwardRequestDisabled}
            >
              {t('request.awardOrCloseRequest')}
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            icon="pencil"
            onSelect={() => {
              queryClient.invalidateQueries(draftStructureQueryKey);
              navigateToReviseRequest(rfqId);
            }}
          >
            {t('request.reviseRequest')}
          </DropdownMenuItem>
        </DropdownMenu>

        {defaultExternalSystem && unmappedSupplierIds && unmappedSuppliersWarningDialog.isOpen && (
          <UnmappedSuppliersWarningDialog
            externalSystem={defaultExternalSystem}
            companyIds={unmappedSupplierIds}
            isAdmin={isAdmin}
            {...unmappedSuppliersWarningDialog}
          />
        )}
      </Box>
      {cannotEndRequestModal.isOpen && (
        <CannotEndRequestModal
          {...cannotEndRequestModal}
          onClose={cannotEndRequestModal.close}
        />
      )}
      {lockedExchangesModal.isOpen && (
        <LockedExchangesModal
          {...lockedExchangesModal}
          onClose={lockedExchangesModal.close}
        />
      )}
    </>
  );
};

const RequestIndicators = () => {
  const { extendedStatus, stages, auction } = rfx.useStructure();

  const lastStage = last(stages);
  const activeStageId = rfx.useActiveStageId();
  const activeStageIndex = stages.findIndex(stage => stage._id === activeStageId);
  // @ts-expect-error ts(2345) FIXME: Argument of type 'string | null' is not assignable to parameter of type 'string'.
  const activeStageDeadline = rfx.useStageDeadline(activeStageId);
  // @ts-expect-error ts(18048) FIXME: 'lastStage' is possibly 'undefined'.
  const lastStageDeadline = rfx.useStageDeadline(lastStage._id);
  const currentDeadline = activeStageDeadline ?? lastStageDeadline;

  // @ts-expect-error ts(2538) FIXME: Type 'null' cannot be used as an index type.
  const currentStage = stages[activeStageId] || lastStage;

  const isCancelledAuctionStage = (
    currentStage?.type === StageType.AUCTION &&
    auction?.status === AuctionStatus.CANCELLED
  );
  const isPausedAuctionStage = (
    currentStage?.type === StageType.AUCTION &&
    auction?.status === AuctionStatus.CANCELLED
  );

  const auctionPauseDate = rfx.useAuctionPauseDate(currentStage?._id);

  const [deadline, setDeadline] = useState(currentDeadline);

  const updateCurrentDeadline = useCallback(
    () => setDeadline(currentDeadline),
    [currentDeadline],
  );

  useEffect(
    () => {
      updateCurrentDeadline();
    },
    [activeStageId, updateCurrentDeadline],
  );

  // @ts-expect-error ts(18048) FIXME: 'lastStage' is possibly 'undefined'.
  const isAtLastStage = lastStage._id === currentStage._id;

  // show RfqStatus.DEADLINES_PASSED as soon as the deadline has
  // passed to avoid LIVE status when deadline has passed but
  // the status in the rfx structure isn't updated yet
  const status = (
    isAtLastStage &&
    extendedStatus === RfqStatus.LIVE &&
    !isPausedAuctionStage &&
    deadline &&
    isPast(deadline)
  )
    ? RfqStatus.DEADLINES_PASSED
    : extendedStatus;

  return (
    <>
      {/*
       // @ts-expect-error ts(2322) FIXME: Type 'RfqStatus | null' is not assignable to type 'RfqStatus'. */}
      <RequestStatus status={status} mr={4} />
      {status === RfqStatus.LIVE && (
        <>
          <RequestStages activeStageIndex={activeStageIndex} stages={stages} />
          <DeadlineCountdown
            withLabel
            // @ts-expect-error ts(2322) FIXME: Type 'Date | null' is not assignable to type 'Date'.
            deadline={deadline}
            isFinalDeadline={activeStageIndex === stages.length - 1}
            isDeadlineAvailable={!isCancelledAuctionStage}
            referenceDate={auctionPauseDate}
            onDeadlinePassed={updateCurrentDeadline}
            iconStyle={{ fontSize: 2 }}
            mr={4}
          />
        </>
      )}
    </>
  );
};

export const RequestStatusIndicators = ({
  navigateToReviseRequest,
}: {
  navigateToReviseRequest: (rfqId: string) => void;
}) => {
  const { isSmall, isExtraSmall } = useDeviceSize();
  const structure = rfx.useStructure();
  const currentUser = useCurrentUser();
  const sentNavigation = useRequestSentNavigation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const systemFeatureFlags = useSystemFeatureFlags({ required: true });
  const { hasSendContractPermission } = useUserFlags();
  const isSuperUserOrOwner = rfx.useIsSuperUserOrOwner();
  const queryClient = useQueryClient();
  const awardRequestModal = useModalState();
  const closeRequestModal = useModalState();
  const navigate = useNavigate();
  const rfqId = useRfqId();
  const liveStructureQueryKey = useLiveRfqStructureQueryKey({
    currentCompanyId,
    rfqId,
  });

  const navigateToSenderDraftContract = React.useCallback(
    (contractId: string) => {
      navigate({
        to: contractDraftSummaryRoute.to,
        params: { contractId, currentCompanyId },
      });
    },
    [currentCompanyId, navigate],
  );

  const closeRequestOutcomeModal = useCallback(
    (shouldRefetchRfq?: boolean) => {
      const isAwardRequest = awardRequestModal.isOpen;

      if (shouldRefetchRfq) {
        queryClient.invalidateQueries(liveStructureQueryKey);
        queryClient.invalidateQueries(['allExchanges', { rfqId, currentCompanyId }]);
        queryClient.invalidateQueries(['exchanges', { rfqId, currentCompanyId }]);
        queryClient.invalidateQueries(['statsByRecipientId', { rfqId, currentCompanyId }]);
      }

      awardRequestModal.close();
      closeRequestModal.close();

      if (isAwardRequest && shouldRefetchRfq) {
        sentNavigation.navigateToSpend();
      }
    },
    [awardRequestModal, closeRequestModal, currentCompanyId, queryClient, rfqId, liveStructureQueryKey, sentNavigation],
  );

  return currentUser ? (
    <Flex alignItems="center" ml={3}>
      {!isSmall && !isExtraSmall && (
        <Flex ml={3}>
          <RequestIndicators />
        </Flex>
      )}
      {structure.status !== RfqStatus.AWARDED && structure.status !== RfqStatus.CLOSED ? (
        <RequestActionsDropdown
          navigateToReviseRequest={navigateToReviseRequest}
          awardRequestModal={awardRequestModal}
          closeRequestModal={closeRequestModal}
        />
      ) : null}
      {structure.status === RfqStatus.AWARDED && systemFeatureFlags.contractManagementEnabled && hasSendContractPermission ? (
        <CreateContractFromRequestButton
          navigateToSenderDraftContract={navigateToSenderDraftContract}
          mr={3}
        />
      ) : null}
      {isSuperUserOrOwner ? <ReportsDropdown /> : null}

      {awardRequestModal.isOpen && (
        <AwardRequestModal
          isOpen={awardRequestModal.isOpen}
          onClose={closeRequestOutcomeModal}
          isAwarded={structure.status === RfqStatus.AWARDED}
        />
      )}

      <CloseRequestModal
        isOpen={closeRequestModal.isOpen}
        onClose={closeRequestOutcomeModal}
      />
    </Flex>
  ) : (
    null
  );
};
