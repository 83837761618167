import { useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';
import { QuestionnaireStatus } from '@deepstream/common/preQual';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { useIsRecipient, useQuestionnaireData } from './questionnaireUtils';
import { Bold } from '../../../Bold';

export const useShowPendingReviewPanel = () => {
  const questionnaire = useQuestionnaireData();
  const isRecipient = useIsRecipient();

  return isRecipient && questionnaire.status === QuestionnaireStatus.PENDING_REVIEW;
};

export const QuestionnairePendingReviewPanel = () => {
  const { t } = useTranslation('preQualification');
  const questionnaire = useQuestionnaireData();

  return (
    <Panel padded>
      <Text fontSize={4} lineHeight="normal" mb={1}>
        <Bold>{t('questionnaire.pendingReview')}</Bold>
      </Text>
      <Text color="subtext">
        {t('questionnaire.pendingReviewDescription', { companyName: questionnaire.senders[0].name })}
      </Text>
    </Panel>
  );
};
