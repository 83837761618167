import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash';
import { withProps } from '@deepstream/ui-utils/withProps';
import { BulkImportTableStyle } from '../TableStyles';
import { Table } from '../Table';
import { TextCell } from '../TextCell';
import { nestCells } from '../nestCells';
import { ImportValidationErrorCell } from './cell';
import { ImportValidationRowCells } from './ValidationRowCells';
import { ParsedQuestion } from './bulk-upload-questions-utils';

type Props = {
  parsedQuestions: ParsedQuestion[];
};

export const BulkImportQuestionsTable = ({ parsedQuestions }: Props) => {
  const { t } = useTranslation();
  const columns = useMemo(
    () => {
      const allFields = uniq(parsedQuestions.flatMap((question) => Object.keys(question)));
      return [
        {
          Header: ' ',
          id: 'index',
          accessor: (_, i) => i + 1,
        },
        ...allFields.map((field) => ({
          Header: t(`request.question.bulkUpload.parsedHeaders.${field}`),
          accessor: field,
          Cell: nestCells(
            withProps(ImportValidationErrorCell, {
              fieldName: field,
            }), TextCell),
        })),
      ];
    },
    [parsedQuestions, t],
  );

  return (
    <BulkImportTableStyle stickyHeader fixedRowHeight px="4px" py="4px">
      <Table
        columns={columns}
        data={parsedQuestions}
        CustomRowCells={ImportValidationRowCells}
      />
    </BulkImportTableStyle>
  );
};
