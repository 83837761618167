import { identity, pickBy, omit, isEmpty } from 'lodash';
import { ExchangeDefinition, ExchangeType } from './types';

// Utility to guard against empty line items and payment stages
export const isEmptyExchangeDef = (exchangeDef: ExchangeDefinition): boolean => {
  // TODO: pick fields based on exchangesConfig
  const pickTruthyFields = docXDef => pickBy(omit(docXDef, ['_id', 'type']), identity);

  switch (exchangeDef.type) {
    case ExchangeType.LINE_ITEM:
    case ExchangeType.PAYMENT_STAGE:
    case ExchangeType.TERMS:
    case ExchangeType.INCLUSIONS:
    case ExchangeType.FEES:
    case ExchangeType.HIRE_PERIOD:
      return isEmpty(pickTruthyFields(exchangeDef));

    default:
      return false;
  }
};
