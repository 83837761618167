import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ExchangeChange, ExchangeDefinition, ExchangeType, InclusionOption, InclusionsExchangeDefinition, RfqEventChange, RfxVesselPricingSection } from '@deepstream/common/rfq-utils';
import { map, pick } from 'lodash';
import { Text } from 'rebass/styled-components';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { diffArrayBy } from '@deepstream/utils';
import { groupBy2d } from '@deepstream/utils/groupBy2d';
import { CancelButton, SaveButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelHeader, PanelPadding, PanelSubHeader } from '@deepstream/ui-kit/elements/Panel';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import { useCurrentCompanyId } from './currentCompanyId';
import { useExchangeColumns } from './modules/Exchange/columns';
import { ExchangeTableBase } from './ExchangeTable';
import * as rfx from './rfx';
import { ErrorMessage } from './ui/ErrorMessage';
import { Loading } from './ui/Loading';
import { useModalState } from './ui/useModalState';
import { useRfqExchanges, useRecipientId } from './useRfq';
import { Disclosure } from './ui/Disclosure';
import { useToaster } from './toast';
import { useUpdateExchanges } from './useUpdateExchanges';
import { mapDiffToChanges, mappings } from './ui/diff';
import { InclusionTableField } from './draft/InclusionTable';
import { useExchangeNavigation } from './useExchangeModalState';
import { EditSupplierExchangeDefsButton } from './modules/Request/Live/EditSupplierExchangeDefsButton';

const sanitize = <T,> (items: T[], diffKeys: string[]) =>
  map(items, def => pick(def, diffKeys));

const getChanges = <TChange extends RfqEventChange> ({ next, previous, keys, mapping }): TChange[] =>
  mapDiffToChanges(
    diffArrayBy(sanitize(next, keys), sanitize(previous, keys), '_id'),
    mappings[mapping],
  ) as any;

const EditSupplierAddedExchangesModal = ({ isOpen, onSuccess, onCancel }) => {
  const { t } = useTranslation();
  const section = rfx.useSection();
  const toaster = useToaster();

  const previousSupplierAddedDefs = rfx.useSectionExchangeDefsByCreator<InclusionsExchangeDefinition>({
    group: 'recipient',
    filter: exchangeDef => exchangeDef.type === ExchangeType.INCLUSIONS,
  });

  const [updateExchanges] = useUpdateExchanges({
    getChanges: (nextSupplierAddedDefs: ExchangeDefinition[]) =>
      getChanges<ExchangeChange>({
        mapping: 'exchangeDef',
        next: nextSupplierAddedDefs,
        previous: previousSupplierAddedDefs,
        keys: ['_id', 'type', 'description', 'option', 'isObsolete'],
      }).map(change => {
        change.sectionName = section._id;
        return change;
      }),
    onSuccess: () => toaster.success(t('request.vesselPricing.inclusions.toaster.inclusionsUpdatedSuccess')),
    onError: () => toaster.error('request.vesselPricing.inclusions.toaster.inclusionsUpdatedError'),
  });

  return (
    <Modal
      shouldCloseOnEsc
      isOpen={isOpen}
      onRequestClose={onCancel}
      style={{ content: { width: '810px' } }}
    >
      <Formik<{ exchangeDefs: InclusionsExchangeDefinition[] }>
        validateOnBlur
        enableReinitialize
        initialValues={{
          exchangeDefs: previousSupplierAddedDefs,
        }}
        validationSchema={
          yup.object().shape({
            exchangeDefs: yup.array().of(
              yup.object().shape({
                description: yup.string().required(t('request.vesselPricing.inclusions.errors.descriptionRequired')),
                option: yup
                  .string()
                  .oneOf([InclusionOption.INCLUDED, InclusionOption.EXCLUDED])
                  .required(t('request.vesselPricing.inclusions.errors.optionRequired')),
              }),
            ),
          })
        }
        onSubmit={async ({ exchangeDefs }, { setSubmitting }) => {
          await updateExchanges(exchangeDefs, {
            onSuccess,
            onSettled: () => setSubmitting(false),
          });
        }}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              {t('request.vesselPricing.inclusions.editSupplierInclusions')}
            </ModalHeader>
            <ModalBody>
              <InclusionTableField />
            </ModalBody>
            <ModalFooter>
              <CancelButton type="button" onClick={onCancel} mr={2} />
              <SaveButton disabled={isSubmitting || !dirty || !isValid} />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export const InclusionsBidSection = () => {
  const { t } = useTranslation();
  const recipientId = useRecipientId();
  const { exchangeDefById } = rfx.useStructure();
  const section = rfx.useSection<RfxVesselPricingSection>();
  const isRecipient = useCurrentCompanyId() === recipientId;
  const editInclusionsModal = useModalState();
  const { openExchange } = useExchangeNavigation();
  const pagePermissions = rfx.usePagePermissions();

  const { data: exchanges = [], isSuccess, isError, isLoading } = useRfqExchanges({
    recipientId,
    sectionIds: [section._id],
  });

  const exchangesInitiatedBy = groupBy2d(
    exchanges,
    exchange => {
      const exchangeDef = exchangeDefById[exchange._id];
      // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
      return exchange.companies[exchangeDef.creatorId].group!;
    },
    exchange => exchange.def.type,
  );

  const hasRecipientAddedSubSection = section.allowSuppliersToAddInclusionsAndExclusions;

  const exchangeColumns = useExchangeColumns();
  const inclusionColumns = useMemo(
    () => exchangeColumns.inclusions(),
    [exchangeColumns],
  );

  const hasBuyerAddedSubSection = exchangesInitiatedBy.sender?.inclusionsAndExclusions?.length > 0;

  if (!hasBuyerAddedSubSection && !hasRecipientAddedSubSection) {
    return null;
  }

  return (
    <Panel>
      <PanelHeader heading={t('request.vesselPricing.inclusions.inclusion_other')} bg="lightGray3" />
      {hasBuyerAddedSubSection && (
        <>
          <PanelDivider />
          {hasRecipientAddedSubSection && (
            <>
              <PanelSubHeader heading={t('request.vesselPricing.inclusions.buyerAddedInclusions')} />
              <PanelDivider />
            </>
          )}
          {isLoading ? (
            <PanelPadding>
              <Loading />
            </PanelPadding>
          ) : isError ? (
            <PanelPadding>
              <ErrorMessage error={t('request.vesselPricing.inclusions.toaster.getInclusionsError')} />
            </PanelPadding>
          ) : isSuccess ? (
            <ExchangeTableBase
              isPaginated={false}
              columns={inclusionColumns}
              exchanges={exchangesInitiatedBy.sender?.inclusionsAndExclusions ?? []}
              onRowClick={openExchange}
            />
          ) : (
            null
          )}
        </>
      )}
      {hasRecipientAddedSubSection && (
        <>
          <PanelDivider />
          <PanelSubHeader heading={t('request.vesselPricing.inclusions.supplierAddedInclusions')}>
            {isRecipient && pagePermissions.canEdit ? (
              <EditSupplierExchangeDefsButton onClick={editInclusionsModal.open} />
            ) : (
              null
            )}
          </PanelSubHeader>
          <PanelDivider />
          {isLoading ? (
            <PanelPadding>
              <Loading />
            </PanelPadding>
          ) : isError ? (
            <PanelPadding>
              <ErrorMessage error={t('request.vesselPricing.inclusions.toaster.getInclusionsError')} />
            </PanelPadding>
          ) : isSuccess ? (
            exchangesInitiatedBy.recipient?.inclusionsAndExclusions?.length ? (
              <ExchangeTableBase
                isPaginated={false}
                columns={inclusionColumns}
                exchanges={exchangesInitiatedBy.recipient.inclusionsAndExclusions}
                onRowClick={openExchange}
              />
            ) : (
              <PanelPadding>
                <Text color="subtext" fontSize={2} mb={3}>
                  {t('request.vesselPricing.inclusions.noInclusionsAdded')}
                </Text>
                {isRecipient ? (
                  <Disclosure summary={t('request.vesselPricing.inclusions.supplierAddedDisclosure.recipient.summary')}>
                    {t('request.vesselPricing.inclusions.supplierAddedDisclosure.recipient.content')}
                  </Disclosure>
                ) : (
                  <Disclosure summary={t('request.vesselPricing.inclusions.supplierAddedDisclosure.sender.summary')}>
                    {t('request.vesselPricing.inclusions.supplierAddedDisclosure.sender.content')}
                  </Disclosure>
                )}
              </PanelPadding>
            )
          ) : (
            null
          )}
        </>
      )}
      <EditSupplierAddedExchangesModal
        isOpen={editInclusionsModal.isOpen}
        onSuccess={editInclusionsModal.close}
        onCancel={editInclusionsModal.close}
      />
    </Panel>
  );
};
