import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SUMMARY_PAGE_ID } from '@deepstream/common/contract';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { ContractCustomMilestonePanel } from './ContractCustomMilestonePanel';
import { NoEditPermissions } from './Permissions/NoEditPermissions';
import { NoPageAccess } from './Permissions/NoPageAccess';
import { useContract } from './useContract';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { Loading } from '../../ui/Loading';
import { ErrorMessage } from '../../ui/ErrorMessage';
import { ContractProvider, usePagesPermissions, useContractState } from './contract';
import { DraftContractFooter } from './DraftContractFooter';
import { ContractBasicDetailsPanel } from './ContractBasicDetailsPanel';
import { ContractCounterPartyPanel } from './ContractCounterPartyPanel';
import { ContractReferenceNumbersPanel } from './ContractReferenceNumbersPanel';
import { ContractSpendDataPanel } from './ContractSpendDataPanel';
import { ContractDraftPageContent } from './ContractDraftPageContent';
import { ContractProductsAndServicesPanel } from './ContractProductsAndServicesPanel';
import { useDraftContractNavigation } from '../../appNavigation';
import { ContractTemplateDetailsPanel } from './ContractTemplateDetailsPanel';

const DraftSummaryFooter = () => {
  const draftContractNavigation = useDraftContractNavigation();

  const navigateToDraftDetails = useCallback(
    () => draftContractNavigation.navigateToDetailsIndex(),
    [draftContractNavigation],
  );

  return (
    <DraftContractFooter onContinue={navigateToDraftDetails} />
  );
};

const DraftSummaryPageContent = () => {
  const permissionsByPageId = usePagesPermissions();
  const { canRead, canEdit } = permissionsByPageId[SUMMARY_PAGE_ID];
  const { t } = useTranslation('contracts');
  // @ts-expect-error ts(2339) FIXME: Property 'isRevising' does not exist on type 'ContractStateContextType | undefined'.
  const { isRevising, isAmending, isTemplate } = useContractState();

  if (!canRead) {
    return <NoPageAccess />;
  }

  return (
    <>
      <ContractDraftPageContent>
        <Stack gap="20px">
          {isRevising && (
            <MessageBlock variant="info" mt={0}>
              {t('revisionInfo')}
            </MessageBlock>
          )}
          {isAmending && (
            <MessageBlock variant="info" mt={0}>
              {t('amendmentInfo')}
            </MessageBlock>
          )}
          {isTemplate && (
            <MessageBlock variant="info" mt={0}>
              {t('templateChangesInfo')}<br />
              {t('templateFieldsInfo')}
            </MessageBlock>
          )}
          {!canEdit && (
            <NoEditPermissions />
          )}
          {isTemplate && <ContractTemplateDetailsPanel />}
          <ContractBasicDetailsPanel />
          <ContractCustomMilestonePanel />
          {!isTemplate && <ContractCounterPartyPanel />}
          <ContractSpendDataPanel />
          <ContractProductsAndServicesPanel />
          <ContractReferenceNumbersPanel />
        </Stack>
      </ContractDraftPageContent>
      <DraftSummaryFooter />
    </>
  );
};

export const ContractDraftSummary = ({
  contractId,
}: {
  contractId: string;
}) => {
  const { t } = useTranslation(['contracts', 'translation']);
  const currentCompanyId = useCurrentCompanyId({ required: true });
  // @ts-expect-error ts(2339) FIXME: Property 'isTemplate' does not exist on type 'ContractStateContextType | undefined'.
  const { isTemplate } = useContractState();

  const { data: contract, isLoading, isError, isSuccess } = useContract({
    contractId,
    currentCompanyId,
    scope: 'draft',
    isTemplate,
  });

  return (
    <>
      {isLoading ? (
        <PanelPadding>
          <Loading />
        </PanelPadding>
      ) : isError ? (
        <PanelPadding>
          <ErrorMessage error={t('errors.unexpected', { ns: 'translation' })} />
        </PanelPadding>
      ) : isSuccess && contract ? (
        <ContractProvider contract={contract}>
          <DraftSummaryPageContent />
        </ContractProvider>
      ) : (
        null
      )}
    </>
  );
};
