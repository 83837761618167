import { memo, useMemo, Suspense } from 'react';
import { IntercomProvider } from 'react-use-intercom';
import { ApiProvider } from '@deepstream/ui/api';
import { Env, EnvProvider } from '@deepstream/ui/env';
import { PublicRequestPreviewBannerProvider } from '@deepstream/ui/modules/Request/Public/PublicRequestPreviewBanner';
import { EnvironmentBannerContext } from '@deepstream/ui/useEnvironmentBanner';
import { AuthBridge } from '@deepstream/ui/local-auth/AuthBridge';
import { getAuthProvider } from '@deepstream/ui/auth/getAuthProvider';
import { UrlProvider } from '@deepstream/ui/ui/UrlProvider';
import { GlobalProcessingProvider } from '@deepstream/ui/GlobalProcessingProvider';
import { SessionProvider } from '@deepstream/ui/auth';
import { ReactQueryProvider } from '@deepstream/ui/ReactQueryProvider';
import { SystemFeatureFlagsProvider } from '@deepstream/ui/systemFeatureFlags';
import { CountriesProviderWrapper } from '@deepstream/ui/CountriesProviderWrapper';
import { CurrenciesProvider } from '@deepstream/ui/ui/currencies';
import { WebSocketProvider } from '@deepstream/ui/WsProvider';
import { ClientThemeProvider } from '@deepstream/ui/ClientThemeProvider';
import { ToastProvider } from '@deepstream/ui/toast';
import { RedirectIfSessionRejected } from '@deepstream/ui/RedirectIfSessionRejected';
import { useTranslation } from 'react-i18next';
import { Router } from './Router';

export const App = memo(() => {
  const env: Env = {
    ENV: process.env.NX_ENV,
    API_URL: process.env.NX_API_URL,
    HELP_CENTER_URL: process.env.NX_HELP_CENTER_URL,
    ONBOARDING_URL: process.env.NX_ONBOARDING_URL,
    AUTH0_DOMAIN: process.env.NX_AUTH0_DOMAIN,
    AUTH0_CLIENT_ID: process.env.NX_AUTH0_CLIENT_ID,
    AUTH0_AUDIENCE: process.env.NX_AUTH0_AUDIENCE,
    API_GATEWAY_ENDPOINT: process.env.NX_API_GATEWAY_ENDPOINT,
    AUTH: process.env.NX_AUTH,
    PUBLIC_PAGES_URL: process.env.NX_PUBLIC_PAGES_URL,
    FULLVIEW_ORGANIZATION_ID: process.env.NX_FULLVIEW_ORGANIZATION_ID,
    INTERCOM_APP_ID: process.env.NX_INTERCOM_APP_ID,
    INTERCOM_BASE_URL: process.env.NX_INTERCOM_BASE_URL,
    HUBSPOT_URL: process.env.NX_HUBSPOT_URL,
    PRISMATIC_URL: process.env.NX_PRISMATIC_URL,
  };
  const { t } = useTranslation();

  const AuthProvider = getAuthProvider(env.AUTH);

  const bannerContext = useMemo(
    () => ({ text: env.ENV, isVisible: env.ENV === 'pilot' }),
    [env.ENV],
  );

  // Special case route for the auth bridge -- this is used by the onboarding app to save
  // a JWT into localstorage via an iframe
  if (window.location.pathname.match('auth-bridge')) {
    return <AuthBridge />;
  }

  return (
    <EnvProvider env={env}>
      <IntercomProvider
        appId={env.INTERCOM_APP_ID!}
        apiBase={env.INTERCOM_BASE_URL}
        shouldInitialize={Boolean(env.INTERCOM_APP_ID)}
      >
        <AuthProvider>
          <ApiProvider baseUrl={env.API_URL}>
            <UrlProvider api={env.API_URL} publicPages={env.PUBLIC_PAGES_URL}>
              <ReactQueryProvider>
                <ClientThemeProvider>
                  <SessionProvider>
                    <RedirectIfSessionRejected>
                      <SystemFeatureFlagsProvider>
                        <CountriesProviderWrapper>
                          <CurrenciesProvider>
                            <WebSocketProvider>
                              <EnvironmentBannerContext.Provider value={bannerContext}>
                                <PublicRequestPreviewBannerProvider>
                                  <GlobalProcessingProvider>
                                    <ToastProvider>
                                      <Suspense fallback={null}>
                                        <Router />
                                      </Suspense>
                                    </ToastProvider>
                                  </GlobalProcessingProvider>
                                </PublicRequestPreviewBannerProvider>
                              </EnvironmentBannerContext.Provider>
                            </WebSocketProvider>
                          </CurrenciesProvider>
                        </CountriesProviderWrapper>
                      </SystemFeatureFlagsProvider>
                    </RedirectIfSessionRejected>
                  </SessionProvider>
                </ClientThemeProvider>
              </ReactQueryProvider>
            </UrlProvider>
          </ApiProvider>
        </AuthProvider>
      </IntercomProvider>
    </EnvProvider>
  );
});
