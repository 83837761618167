import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { RfxVesselPricingSection, Vessel } from '@deepstream/common/rfq-utils';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { ErrorMessage } from './ui/ErrorMessage';
import { DeliveryBidSection } from './DeliveryBidSection';
import { HirePeriods } from './HirePeriodBidSection';
import { FeeBidSection } from './FeeBidSection';
import { useCurrentCompanyId } from './currentCompanyId';
import { useRfqExchanges, useRecipientId } from './useRfq';
import * as rfx from './rfx';
import { Loading } from './ui/Loading';

type VesselBidTabProps = {
  vessel: Vessel;
  isLocked: boolean;
};

export const VesselBidTab: React.FC<VesselBidTabProps> = ({
  vessel,
  isLocked = false,
}) => {
  const { t } = useTranslation();
  const recipientId = useRecipientId();
  const section = rfx.useSection<RfxVesselPricingSection>();
  const isRecipient = useCurrentCompanyId() === recipientId;
  const pagePermissions = rfx.usePagePermissions();

  const { data: exchanges, isSuccess, isError, isLoading, refetch } = useRfqExchanges({
    recipientId,
    sectionIds: [section?._id],
    enabled: Boolean(section) && Boolean(recipientId),
  });

  return isLoading ? (
    <PanelPadding>
      <Loading />
    </PanelPadding>
  ) : isError ? (
    <PanelPadding>
      <ErrorMessage error={t('general.couldNotGetData')} />
    </PanelPadding>
  ) : isSuccess ? (
    <rfx.ExchangeRefetchProvider refetch={refetch}>
      <Stack gap={3}>
        <DeliveryBidSection vessel={vessel} isLocked={isLocked} />
        <HirePeriods vessel={vessel} exchanges={exchanges} />
        <FeeBidSection
          vessel={vessel}
          hasEditPermissions={pagePermissions.canEdit}
          isRecipient={isRecipient}
          exchanges={exchanges}
        />
      </Stack>
    </rfx.ExchangeRefetchProvider>
  ) : (
    null
  );
};
