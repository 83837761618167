import { useState, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { findIndex, first, isEmpty, map } from 'lodash';
import { SortingAccessor } from '@deepstream/common';
import { LabeledSortCriterion } from '@deepstream/ui-utils';
import { RfqStatus } from '@deepstream/common/rfq-utils/types';
import { requestStatusesConfig } from '@deepstream/common/rfq-utils/statusConfigs';
import { Illustration } from '@deepstream/ui-kit';
import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { FlexPanelHeader, Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { useCurrentCompanyId } from './currentCompanyId';
import { useApi, wrap } from './api';
import { NewTemplateModal } from './NewTemplateModal';
import { Loading } from './ui/Loading';
import { ErrorMessage } from './ui/ErrorMessage';
import { MIN_CELL_HEIGHT } from './FieldsCell';
import { useModalState } from './ui/useModalState';
import { SentRequestsTable } from './SentRequestsTable';
import { RecipientFilterItem, ExtendedSentRequestOverview, RequestTagFilterItem, SentRequestOverviews, UserFilterItem } from './types';
import { RequestCount } from './RequestCount';
import { useRequestsNavigation } from './RequestsNavigationContext';
import { useTableData } from './TableDataContext';
import { renderName } from './RequestsTable';
import { useLocalStorageSortProps2, useSortDirectionItems } from './sorting';
import { ClearFiltersButton, useLocalStorageFilterProps, useStatusFilterProps, DashboardRoleFilter, useDashboardRoleFilterItems, ALL_DASHBOARD_ROLES, useActiveStageFilterProps, useFinalDeadlineFilterProps } from './filtering';
import { RequestsTableData, RequestsTableDataProvider } from './RequestsTableDataProvider';
import { useCurrentUser, useCurrentUserLocale } from './useCurrentUser';
import { useLocalStorageState } from './useLocalStorageState';
import { useRequestTagsContext } from './modules/RequestTags/RequestTagsContext';
import { EditRequestsTagsDropdown } from './modules/RequestTags/EditRequestTagsDropdown';
import { renderRequestTagLabel } from './modules/RequestTags/RequestTagLabel';
import { DeleteRequestDialog } from './DeleteRequestDialog';
import { SearchRequests } from './SearchRequests';
import { SentRequestsQueryTags } from './requestsConstants';
import { MultiSelectDropdownPage } from './ui/FilterDropdownPages/MultiSelectDropdownPage';
import { FilterDropdownPage, FilterDropdownPanelConfig, FilterDropdownPages } from './ui/FilterDropdownPages/FilterDropdownPages';
import { UserDetails } from './UserDetails';
import { filterBySearchText } from './searchUtils';
import { RequestUsersFilter } from './ui/RequestUsersFilter';
import { CompanyLogoWithCountry } from './CompanyLogo';
import { ActiveStageFilter, FinalDeadlineFilter } from './ui/ComparisonFilter';
import { SortDropdown } from './ui/SortDropdown';
import { HorizontalDivider } from './ui/HorizontalDivider';

const filterPageIds = {
  user: 'user',
  tags: 'tags',
  status: 'status',
  suppliers: 'suppliers',
  activeStage: 'activeStage',
  finalDeadline: 'finalDeadline',
};

const SentRequestsContent = ({ selectedRequestTagId, isDraftRequests }: { selectedRequestTagId?: string, isDraftRequests: boolean }) => {
  const { t } = useTranslation(['translation', 'company']);
  const api = useApi();
  const newTemplateModal = useModalState();
  const deleteRequestModal = useModalState();
  const [selectedRequest, setSelectedRequest] = useState<ExtendedSentRequestOverview | null>(null);
  const { navigateToSenderRequest } = useRequestsNavigation();
  const {
    statusFilter,
    roleFilter,
    entityFilter,
    companyFilter,
    requestTagsFilter,
    activeStageFilter,
    finalDeadlineFilter,
    searchText,
    setSearchText,
    sort,
    query,
    pageSize,
    selectedItemsIds,
    selectAllItemsOnPage,
    unselectAllItems,
  } = useTableData<RequestsTableData<SentRequestOverviews>>({ required: true });

  const { status, data, refetch } = query;
  const {
    rfqs: requests,
    totalItems = 0,
    companyHasNoLiveRequests,
    companyHasNoDraftRequests,
  } = data ?? {};

  const openDeleteModal = useCallback(
    (request) => {
      setSelectedRequest(request);
      deleteRequestModal.open();
    },
    [setSelectedRequest, deleteRequestModal],
  );

  const openTemplateFromRequestModal = useCallback(
    (request) => {
      setSelectedRequest(request);
      newTemplateModal.open();
    },
    [setSelectedRequest, newTemplateModal],
  );

  const handleTagsChange = () => {
    // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
    unselectAllItems();
    refetch();
  };

  const hasFiltersOrSearch = (
    // @ts-expect-error ts(18048) FIXME: 'statusFilter' is possibly 'undefined'.
    (!isDraftRequests && !isEmpty(statusFilter.selectedItems)) ||
    !isEmpty(roleFilter.selectedItems) ||
    !isEmpty(entityFilter.selectedItems) ||
    !isEmpty(companyFilter.selectedItems) ||
    !isEmpty(requestTagsFilter.selectedItems) ||
    !isEmpty(activeStageFilter.selectedItems) ||
    !isEmpty(finalDeadlineFilter.selectedItems) ||
    searchText
  );

  // @ts-expect-error ts(18048) FIXME: 'selectedItemsIds' is possibly 'undefined'.
  const showEditTagsButton = selectedItemsIds.length > 0;
  const disableTableControls = showEditTagsButton || status !== 'success';

  const emptyTableLabel = useMemo(() => {
    if (selectedRequestTagId) {
      return t('requests.tags.noRequests');
    } else if (isDraftRequests) {
      return companyHasNoDraftRequests
        ? t('requests.companyHasNoDraftRequests')
        : t('requests.notOnTeamForAnyDraftRequests');
    } else {
      return companyHasNoLiveRequests
        ? t('requests.companyHasNotIssuedRequests')
        : t('requests.notOnTeamForAnyRequests');
    }
  }, [
    t,
    selectedRequestTagId,
    isDraftRequests,
    companyHasNoDraftRequests,
    companyHasNoLiveRequests,
  ]);

  const filtersConfig: Record<string, FilterDropdownPanelConfig> = {
    [filterPageIds.user]: {
      pageId: filterPageIds.user,
      title: t('general.user', { count: 1 }),
      icon: 'user',
    },
    [filterPageIds.tags]: {
      pageId: filterPageIds.tags,
      title: t('general.tag'),
      icon: 'tag',
    },
    ...(!isDraftRequests && {
      [filterPageIds.status]: {
        pageId: filterPageIds.status,
        title: t('general.status'),
        icon: 'circle-dashed',
      },
    }),
    [filterPageIds.suppliers]: {
      pageId: filterPageIds.suppliers,
      title: t('general.supplier', { count: 1 }),
      icon: 'building',
      isIconRegular: true,
    },
    [filterPageIds.suppliers]: {
      pageId: filterPageIds.suppliers,
      title: t('general.supplier', { count: 1 }),
      icon: 'building',
      isIconRegular: true,
    },
    ...(!isDraftRequests && {
      [filterPageIds.activeStage]: {
        pageId: filterPageIds.activeStage,
        title: t('activeStage', { ns: 'general' }),
        icon: 'list-ol',
      },
    }),
    [filterPageIds.finalDeadline]: {
      pageId: filterPageIds.finalDeadline,
      title: t('requests.finalDeadline'),
      icon: 'clock-o',
    },
  };

  return (
    <>
      <Panel>
        <FlexPanelHeader
          heading={
            // Keep the header in the same position while loading the results with search/sort/filter
            // @ts-expect-error ts(18048) FIXME: 'selectedItemsIds' is possibly 'undefined'.
            <Box {...(!selectedItemsIds.length && { width: '130px' })}>
              {requests ? (
                <Flex alignItems="center">
                  {requests.length > 0 && (
                    <Checkbox
                      style={{ padding: '0 12px 0 0' }}
                      checked={
                        // @ts-expect-error ts(18048) FIXME: 'selectedItemsIds' is possibly 'undefined'.
                        selectedItemsIds.length > 0 &&
                        // @ts-expect-error ts(18048) FIXME: 'selectedItemsIds' is possibly 'undefined'.
                        selectedItemsIds.length === requests.length
                      }
                      indeterminate={
                        // @ts-expect-error ts(18048) FIXME: 'selectedItemsIds' is possibly 'undefined'.
                        selectedItemsIds.length > 0 &&
                        // @ts-expect-error ts(18048) FIXME: 'selectedItemsIds' is possibly 'undefined'.
                        selectedItemsIds.length !== requests.length
                      }
                      onChange={(event) => {
                        if ((event.target as HTMLInputElement).checked) {
                          // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
                          selectAllItemsOnPage();
                        } else {
                          // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
                          unselectAllItems();
                        }
                      }}
                    />
                  )}
                  <RequestCount
                    // @ts-expect-error ts(18048) FIXME: 'selectedItemsIds' is possibly 'undefined'.
                    selectedCount={selectedItemsIds.length}
                    count={totalItems}
                  />
                </Flex>
              ) : null}
            </Box>
          }
        >
          {showEditTagsButton ? (
            <EditRequestsTagsDropdown
              // @ts-expect-error ts(2322) FIXME: Type 'string[] | undefined' is not assignable to type 'string[]'.
              requestIds={selectedItemsIds}
              onSave={handleTagsChange}
            />
          ) : (
            <Flex
              flexWrap="wrap"
              alignItems="center"
              justifyContent="flex-end"
              sx={{ columnGap: 1 }}
              data-test="requests-table-actions"
            >
              <SearchRequests<ExtendedSentRequestOverview>
                queryFn={isDraftRequests ? api.getDraftRequests : api.getSentRequests}
                getResultText={(request: ExtendedSentRequestOverview) =>
                  request.sentDashboard.subject
                }
                onResultClick={(request: ExtendedSentRequestOverview) =>
                  navigateToSenderRequest({
                    requestId: request._id,
                    isDraft: request.sentDashboard.status === RfqStatus.DRAFT,
                  })
                }
                disabled={disableTableControls}
              />
              <FilterDropdownPages config={filtersConfig}>
                <FilterDropdownPage
                  pageId={filterPageIds.user}
                  onChange={entityFilter.onChange}
                  selectedItems={entityFilter.selectedItems}
                >
                  <RequestUsersFilter
                    filterItems={(value) =>
                      filterBySearchText(value, entityFilter.items, [
                        'name',
                        'email',
                      ])
                    }
                    items={entityFilter.items}
                    onChange={entityFilter.onChange}
                    renderItem={entityFilter.renderItem}
                    selectedItems={entityFilter.selectedItems}
                    idProp={entityFilter.idProp}
                    roles={roleFilter.items as DashboardRoleFilter[]}
                    selectedRoles={
                      roleFilter.selectedItems as DashboardRoleFilter[]
                    }
                    onRolesChange={roleFilter.onChange}
                  />
                </FilterDropdownPage>

                <FilterDropdownPage
                  pageId={filterPageIds.suppliers}
                  onChange={companyFilter.onChange}
                  selectedItems={companyFilter.selectedItems}
                >
                  <MultiSelectDropdownPage
                    filterItems={(value) =>
                      filterBySearchText(value, companyFilter.items, [
                        'name',
                      ])
                    }
                    filterPlaceholder={t('requests.searching.searchByName')}
                    items={companyFilter.items}
                    onChange={companyFilter.onChange}
                    renderItem={companyFilter.renderItem}
                    selectedItems={companyFilter.selectedItems}
                    idProp={companyFilter.idProp}
                  />
                </FilterDropdownPage>

                <FilterDropdownPage
                  pageId={filterPageIds.tags}
                  onChange={requestTagsFilter.onChange}
                  selectedItems={requestTagsFilter.selectedItems}
                >
                  <MultiSelectDropdownPage
                    filterItems={(value) =>
                      filterBySearchText(value, requestTagsFilter.items, [
                        'name',
                        'email',
                      ])
                    }
                    filterPlaceholder={t('requests.searching.searchByTagName')}
                    renderPreItemContent={(tag, index) => !tag.parentTagId && index > 0 ? <HorizontalDivider my={2} /> : null}
                    items={requestTagsFilter.items}
                    onChange={requestTagsFilter.onChange}
                    renderItem={requestTagsFilter.renderItem}
                    selectedItems={requestTagsFilter.selectedItems}
                    idProp={requestTagsFilter.idProp}
                  />
                </FilterDropdownPage>

                {!isDraftRequests && (
                  <FilterDropdownPage
                    pageId={filterPageIds.status}
                    // @ts-expect-error ts(18048) FIXME: 'statusFilter' is possibly 'undefined'.
                    onChange={statusFilter.onChange}
                    // @ts-expect-error ts(18048) FIXME: 'statusFilter' is possibly 'undefined'.
                    selectedItems={statusFilter.selectedItems}
                  >
                    <MultiSelectDropdownPage
                      // @ts-expect-error ts(18048) FIXME: 'statusFilter' is possibly 'undefined'.
                      items={statusFilter.items}
                      // @ts-expect-error ts(18048) FIXME: 'statusFilter' is possibly 'undefined'.
                      onChange={statusFilter.onChange}
                      // @ts-expect-error ts(18048) FIXME: 'statusFilter' is possibly 'undefined'.
                      renderItem={statusFilter.renderItem}
                      // @ts-expect-error ts(18048) FIXME: 'statusFilter' is possibly 'undefined'.
                      selectedItems={statusFilter.selectedItems}
                      // @ts-expect-error ts(18048) FIXME: 'statusFilter' is possibly 'undefined'.
                      idProp={statusFilter.idProp}
                    />
                  </FilterDropdownPage>
                )}

                {!isDraftRequests && (
                  <FilterDropdownPage
                    pageId={filterPageIds.activeStage}
                    onChange={activeStageFilter.onChange}
                    selectedItems={activeStageFilter.selectedItems}
                  >
                    <ActiveStageFilter
                      items={activeStageFilter.items}
                      onChange={activeStageFilter.onChange}
                      selectedItems={activeStageFilter.selectedItems}
                      renderItem={activeStageFilter.renderItem}
                    />
                  </FilterDropdownPage>
                )}

                <FilterDropdownPage
                  pageId={filterPageIds.finalDeadline}
                  onChange={finalDeadlineFilter.onChange}
                  selectedItems={finalDeadlineFilter.selectedItems}
                >
                  <FinalDeadlineFilter
                    items={finalDeadlineFilter.items}
                    onChange={finalDeadlineFilter.onChange}
                    selectedItems={finalDeadlineFilter.selectedItems}
                    renderItem={finalDeadlineFilter.renderItem}
                  />
                </FilterDropdownPage>
              </FilterDropdownPages>

              <SortDropdown
                disabled={
                  disableTableControls || (requests && totalItems === 0)
                }
                {...sort}
              />
            </Flex>
          )}
        </FlexPanelHeader>
        <PanelDivider />
        {status === 'success' && totalItems > 0 ? (
          <SentRequestsTable
            openDeleteModal={openDeleteModal}
            openTemplateFromRequestModal={openTemplateFromRequestModal}
          />
        ) : (
          <Flex
            height={MIN_CELL_HEIGHT * (status === 'loading' ? pageSize : 5)}
            flexDirection="column"
            justifyContent="center"
          >
            <PanelPadding>
              {status === 'loading' ? (
                <Loading fontSize={4} fontWeight={400} />
              ) : status === 'error' ? (
                <ErrorMessage
                  fontSize={3}
                  error={t('requests.errors.getRequests')}
                />
              ) : hasFiltersOrSearch ? (
                <Illustration
                  variant="search-results"
                  label={
                    <Flex flexDirection="column" sx={{ gap: 2 }}>
                      <Text>{t('requests.noRequestsWithSearchOrFilter')}</Text>
                      <ClearFiltersButton
                        onClick={() => {
                          roleFilter.onChange([]);
                          entityFilter.onChange([]);
                          companyFilter.onChange([]);
                          requestTagsFilter.onChange([]);
                          activeStageFilter.onChange([]);
                          finalDeadlineFilter.onChange([]);
                          setSearchText('');
                        }}
                        alignSelf="center"
                      />
                    </Flex>
                  }
                  labelSx={{
                    fontWeight: 500,
                  }}
                />
              ) : (
                <Illustration
                  variant={isDraftRequests ? 'draft-request' : 'empty-state'}
                  label={emptyTableLabel}
                  labelSx={{
                    fontWeight: 500,
                  }}
                />
              )}
            </PanelPadding>
          </Flex>
        )}
      </Panel>
      {newTemplateModal.isOpen && (
        <NewTemplateModal
          isOpen={newTemplateModal.isOpen}
          close={newTemplateModal.close}
          initialRequest={selectedRequest}
        />
      )}
      {selectedRequest && (
        <DeleteRequestDialog
          {...deleteRequestModal}
          rfqId={selectedRequest._id}
          rfqName={selectedRequest?.sentDashboard.subject}
          onSettled={() => {
            setSelectedRequest(null);
            deleteRequestModal.close();
          }}
        />
      )}
    </>
  );
};

type UserFilter = {
  name: string;
  _id: string;
};

const useStatusFilterItems = (statuses: string[] | undefined) => {
  const { t } = useTranslation();

  return useMemo(() => {
    if (!statuses) {
      return;
    }

    return statuses.map(status => ({
      ...requestStatusesConfig[status],
      label: t(`request.status.${status}`),
    }));
  }, [t, statuses]);
};

const useUserFilterItems = (users: UserFilterItem[] | undefined) => {
  const { t } = useTranslation();
  const user = useCurrentUser();

  return useMemo(() => {
    if (!users) {
      return;
    }

    const indexOfMe = findIndex(users, { _id: user._id });

    if (indexOfMe === -1) {
      return users;
    } else {
      const itemsClone = [...users];
      const removed = itemsClone.splice(indexOfMe, 1);
      const me = {
        ...first(removed),
        name: t('requests.filtering.me'),
      } as UserFilterItem;
      itemsClone.unshift(me);
      return itemsClone;
    }
  }, [t, user._id, users]);
};

const useSortCriteriaItems = (): LabeledSortCriterion[] => {
  const { t } = useTranslation('translation');

  return useMemo(() => [
    { label: t('requests.sorting.creationDate'), accessor: SortingAccessor.creationDate },
    { label: t('requests.sorting.issueDate'), accessor: SortingAccessor.issueDate },
    { label: t('requests.sorting.finalDeadline'), accessor: SortingAccessor.finalDeadline },
    { label: t('requests.sorting.automatedReference'), accessor: SortingAccessor.automatedReference },
    { label: t('requests.sorting.requestName'), accessor: SortingAccessor.requestName },
  ], [t]);
};

const SentRequests = ({
  queryTag,
  queryFn,
  selectedRequestTagId,
}: {
  queryTag: string;
  queryFn: (...params: any[]) => Promise<SentRequestOverviews>;
  selectedRequestTagId?: string;
}) => {
  const { tags, tagById } = useRequestTagsContext();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();
  const api = useApi();
  const locale = useCurrentUserLocale();

  const filterItems = useQuery(
    [`${queryTag}FilterItems`, { currentCompanyId }],
    wrap(api.getSentRequestFilterItems),
  );

  const dashboardRoleFilterItems = useDashboardRoleFilterItems(ALL_DASHBOARD_ROLES);

  const userFilterItems = useUserFilterItems(
    filterItems.data?.users,
  );

  const statusFilterItems = useStatusFilterItems(
    filterItems.data?.statuses,
  );

  const requestTagsFilterItems = useMemo(
    () => tags.filter(({ _id }) => _id !== selectedRequestTagId),
    [tags, selectedRequestTagId],
  ) as RequestTagFilterItem[];

  // @ts-expect-error ts(2345) FIXME: Argument of type 'any[] | undefined' is not assignable to parameter of type 'StatusFilter[]'.
  const statusFilterProps = useStatusFilterProps('sentRequests', statusFilterItems);

  const recipientFilterItems = useMemo(
    () => filterItems.data?.recipients.sort((a, b) => a.name.localeCompare(b.name, locale)),
    [filterItems.data?.recipients, locale],
  );

  const roleFilterProps = useLocalStorageFilterProps({
    storageKey: `${currentCompanyId}.${user._id}.${queryTag}.roleFilter`,
    items: dashboardRoleFilterItems,
    idProp: '_id',
    renderItem: renderName,
    getQueryParam: (selectedItems: DashboardRoleFilter[]) => ({
      roles: map(selectedItems, '_id').sort(),
    }),
  });

  const userFilterProps = useLocalStorageFilterProps({
    storageKey: `${currentCompanyId}.${user._id}.${queryTag}.userFilter`,
    items: userFilterItems,
    idProp: '_id',
    renderItem: (item) => (<UserDetails user={item} />),
    getQueryParam: (selectedItems: UserFilter[]) => ({
      // sort so the same set of IDs always results in the
      // same query key irrespective of the order of selection
      users: map(selectedItems, '_id').sort(),
    }),
  });

  const recipientFilterProps = useLocalStorageFilterProps({
    storageKey: `${currentCompanyId}.${user._id}.${queryTag}.supplierFilter`,
    items: recipientFilterItems,
    idProp: '_id',
    renderItem: (item) => (
      <CompanyLogoWithCountry
        // @ts-expect-error ts(2322) FIXME: Type 'RecipientFilterItem | null' is not assignable to type 'Company | CompanyMinimized'.
        company={item}
        // @ts-expect-error ts(18047) FIXME: 'item' is possibly 'null'.
        address={item.address}
        size="xs"
        textStyle={{ fontWeight: 500 }}
        sx={{ columnGap: 2 }}
      />
    ),
    getQueryParam: (selectedItems: RecipientFilterItem[]) => ({
      // sort so the same set of IDs always results in the
      // same query key irrespective of the order of selection
      recipientIds: map(selectedItems, '_id').sort(),
    }),
  });

  const requestTagsFilterProps = useLocalStorageFilterProps({
    storageKey: `${currentCompanyId}.${user._id}.${queryTag}.requestTagsFilter`,
    items: requestTagsFilterItems,
    idProp: '_id',
    // @ts-expect-error ts(2345) FIXME: Argument of type 'RequestTagFilterItem | null' is not assignable to parameter of type 'RequestTag'.
    renderItem: (requestTag) => renderRequestTagLabel(requestTag, tagById),
    getQueryParam: (selectedItems: RequestTagFilterItem[]) => ({
      // sort so the same set of IDs always results in the
      // same query key irrespective of the order of selection
      requestTags: map(selectedItems, '_id').sort(),
    }),
  });

  const activeStageFilterProps = useActiveStageFilterProps(queryTag);
  const finalDeadlineFilterProps = useFinalDeadlineFilterProps(queryTag);

  const criteriaItems = useSortCriteriaItems();
  const directionItems = useSortDirectionItems();
  const sortProps = useLocalStorageSortProps2({
    storageKey: `${currentCompanyId}.${user._id}.${queryTag}.sort`,
    criteriaItems,
    directionItems,
  });

  const pageSizeState = useLocalStorageState<number>({
    key: `${currentCompanyId}.${user._id}.${queryTag}.pageSize`,
    defaultValue: 10,
  });

  const searchTextState = useLocalStorageState<string>({
    key: `${currentCompanyId}.${user._id}.${queryTag}.searchText`,
    defaultValue: '',
  });

  return (
    <RequestsTableDataProvider
      queryTag={queryTag}
      queryFn={queryFn}
      statusFilter={statusFilterProps}
      roleFilter={roleFilterProps}
      entityFilter={userFilterProps}
      companyFilter={recipientFilterProps}
      requestTagsFilter={requestTagsFilterProps}
      activeStageFilter={activeStageFilterProps}
      finalDeadlineFilter={finalDeadlineFilterProps}
      preselectedTag={selectedRequestTagId}
      sort={sortProps}
      pageSizeState={pageSizeState}
      searchTextState={searchTextState}
    >
      <SentRequestsContent
        selectedRequestTagId={selectedRequestTagId}
        isDraftRequests={queryTag === SentRequestsQueryTags.draft}
      />
    </RequestsTableDataProvider>
  );
};

export const LiveSentRequests = ({
  selectedRequestTagId,
}: {
  selectedRequestTagId?: string;
}) => {
  const api = useApi();

  return (
    <SentRequests
      queryTag={SentRequestsQueryTags.live}
      queryFn={api.getSentRequests}
      selectedRequestTagId={selectedRequestTagId}
    />
  );
};

export const DraftSentRequests = ({ selectedRequestTagId }: { selectedRequestTagId?: string }) => {
  const api = useApi();

  return (
    <SentRequests
      queryTag={SentRequestsQueryTags.draft}
      queryFn={api.getDraftRequests}
      selectedRequestTagId={selectedRequestTagId}
    />
  );
};
