import { useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { isEmpty, isFinite, noop } from 'lodash';
import { Form, Formik } from 'formik';
import { Live } from '@deepstream/common/rfq-utils';
import { localeFormatPrice } from '@deepstream/utils';
import { useMemo } from 'react';
import * as rfx from '../../../../rfx';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData, AwardFlowStepType } from '../types';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { CheckboxFieldBase } from '../../../../form/CheckboxField';
import { SavingsNotAccurate } from '../SavingsNotAccurate';
import { useCurrentUserLocale } from '../../../../useCurrentUser';
import { SavingsDiffCalculation } from '../../Spend/SavingsDiffCalculation';
import { CurrencyCodeProvider } from '../../../../ui/Currency';
import { Direction } from '../../../../ui/MultiStepFlow/types';

export const ConfirmBudgetFinalValueDiffSavingsStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (
    newData: Partial<AwardFlowData> | null,
    direction: Direction | null,
    targetStep?: AwardFlowStepType,
  ) => void,
}) => {
  const { t } = useTranslation('translation');
  const locale = useCurrentUserLocale();
  const { currencyCode } = rfx.useStructure<Live>();
  const { spendAndSavings } = data;

  const hasManualTotalValue = isFinite(spendAndSavings.manualTotalValue);
  const finalValue = hasManualTotalValue
    ? spendAndSavings.manualTotalValue
    : spendAndSavings.calculatedTotalValue ?? undefined;
  // @ts-expect-error ts(18047) FIXME: 'spendAndSavings.budgetedTotalValue' is possibly 'null'.
  const budgetFinalValueSavings = spendAndSavings.budgetedTotalValue - finalValue;

  const formattedSavings = isFinite(budgetFinalValueSavings)
    ? localeFormatPrice(budgetFinalValueSavings, currencyCode, { locale, showCode: true })
    : null;

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      areTotalSavingsAccurate: z.literal(true, { errorMap: () => ({ message: t('request.awardFlow.errors.confirmCalculatedSavings') }) }),
    });

    return toFormikValidationSchema(Schema);
  }, [t]);

  return (
    <Formik
      validateOnBlur
      initialValues={data.spendAndSavings}
      validationSchema={validationSchema}
      onSubmit={noop}
    >
      {({ values, errors, submitForm, validateForm, setFieldValue, setFieldTouched, dirty }) => (
        <Form>
          <lotPagesLayout.ContentWrapper>
            <StepNavigation
              onBackClick={() => submitAndNavigate(dirty ? { spendAndSavings: values } : null, Direction.BACK)}
              onContinueClick={async () => {
                const errors = await validateForm();

                await submitForm();

                if (isEmpty(errors)) {
                  submitAndNavigate(dirty ? { spendAndSavings: values } : null, Direction.FORWARD);
                }
              }}
            >
              <FormErrors />
              <lotPagesLayout.Section
                heading={t('request.awardFlow.steps.confirmBudgetFinalValueDiffSavings.heading')}
              >
                <Text mt="12px">
                  {t('request.awardFlow.steps.chooseSavingsMethod.options.budgetFinalValueDiff.description')}
                </Text>
                <Box mt="20px">
                  <CurrencyCodeProvider code={currencyCode}>
                    <SavingsDiffCalculation
                      labelA={t('request.spendAndSavings.budgetedTotalValue')}
                      labelB={t('request.spendAndSavings.finalTotalValue')}
                      value={{
                        // @ts-expect-error ts(2322) FIXME: Type 'number | null' is not assignable to type 'number | undefined'.
                        valueA: spendAndSavings.budgetedTotalValue,
                        // @ts-expect-error ts(2322) FIXME: Type 'number | null | undefined' is not assignable to type 'number | undefined'.
                        valueB: finalValue,
                      }}
                    />
                  </CurrencyCodeProvider>
                </Box>
              </lotPagesLayout.Section>
              <lotPagesLayout.Section
                heading={t('request.awardFlow.confirmationHeading')}
              >
                <Box mt="12px">
                  <CheckboxFieldBase
                    fieldLabel={(
                      <Text
                        as="span"
                        fontSize={4}
                        fontWeight={500}
                        lineHeight={1.4}
                        sx={{
                          position: 'relative',
                          top: '-4px',
                        }}
                      >
                        {t('request.awardFlow.steps.confirmBudgetFinalValueDiffSavings.confirmCheckboxLabel', {
                          savings: formattedSavings,
                        })}
                      </Text>
                    )}
                    name="areTotalSavingsAccurate"
                    // @ts-expect-error ts(2322) FIXME: Type 'boolean | null' is not assignable to type 'boolean | undefined'.
                    value={values.areTotalSavingsAccurate}
                    error={errors?.areTotalSavingsAccurate as string | undefined}
                    onChange={async (event: any) => {
                      await setFieldValue('areTotalSavingsAccurate', Boolean(event.target.checked));
                      await setFieldTouched('areTotalSavingsAccurate', true);
                    }}
                    errorMessageStyle={{ fontSize: '14px', marginTop: '16px', marginLeft: '-24px' }}
                  />
                </Box>
                <SavingsNotAccurate
                  onClick={() => {
                    submitAndNavigate(dirty ? { spendAndSavings: values } : null, null, AwardFlowStepType.CHOOSE_SAVINGS_METHOD);
                  }}
                />
              </lotPagesLayout.Section>
            </StepNavigation>
          </lotPagesLayout.ContentWrapper>
        </Form>
      )}
    </Formik>
  );
};
