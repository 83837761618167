import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ChangeType, CompanyMinimized, PageRole, PageType, renderPageName } from '@deepstream/common/rfq-utils';
import { pick, mapValues, keyBy, reject } from 'lodash';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ModalForm } from '../../../ModalForm';
import * as rfx from '../../../rfx';
import { UserSelectField } from './UserSelectField';

export const RecipientTeamAddUserModal = ({
  isOpen,
  close,
  company,
  onInviteNewUserClick,
}: {
  isOpen: boolean;
  close: any;
  company: CompanyMinimized;
  onInviteNewUserClick: () => void;
}) => {
  const { t } = useTranslation();
  const structure = rfx.useStructure();
  const saveChanges = rfx.useSaveChanges();

  const existingUserIds = Object.keys(structure.teamById[company._id].users);

  const pagePermissions = useMemo(
    () =>
      reject(structure.pages, ['type', PageType.EVALUATION])
        .map((page) => ({
          _id: page._id,
          name: renderPageName(page, t),
          type: page.type,
          role: PageRole.NONE,
        })),
    [structure.pages, t],
  );
  const rfqRoles = useMemo(
    () => mapValues(keyBy(pagePermissions, '_id'), 'role'),
    [pagePermissions],
  );

  return (
    <ModalForm
      heading={t('teamManagement.addUser')}
      initialValues={{
        selectedUser: undefined,
      }}
      validationSchema={
        yup.object().shape({
          selectedUser: yup.mixed().required(t('general.required')),
        })
      }
      disableSubmitIfNotDirty={true}
      disableSubmitIfInvalid={true}
      isOpen={isOpen}
      onCancel={close}
      footerLeftButton={(
        <Button type="button" variant="secondary" onClick={onInviteNewUserClick}>
          {t('requests.inviteNewUser')}
        </Button>
      )}
      onSubmit={async (values) => {
        const user = {
          ...pick(values.selectedUser, ['_id', 'name', 'email']),
          approvingPages: [],
          isOwner: false,
          company,
          rfqRoles,
        };

        const changes = [{
          type: ChangeType.TEAM_MEMBER_ADDED,
          user,
          companyId: company._id,
        }];

        await saveChanges({ changes }, { onSettled: close });
      }}
      submitLabel={t('general.saveChanges')}
    >
      <UserSelectField companyId={company._id} existingUserIds={existingUserIds} />
    </ModalForm>
  );
};
