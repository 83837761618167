import * as React from 'react';
import { CellProps } from 'react-table';
import { useTranslation } from 'react-i18next';
import { ToggleRowExpandedCell } from './ToggleRowExpandedCell';

export const AuditEventCell: React.FC<CellProps<{ value: string }>> = (props) => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-underscore-dangle
  if (props.cell.value?._type === 'answers-changed') {
    return (
      <ToggleRowExpandedCell
        expandedText={t('request.exchange.hideChanges')}
        collapsedText={t('request.exchange.showChanges')}
        {...props}
      />
    );
  }

  // Simple text cell
  return props.cell.value;
};
