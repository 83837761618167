import { HistoryTableDataProvider } from './HistoryTableDataProvider';
import { HistoryTable } from './HistoryTable';
import { ChatbotChat } from '../AI/chatbot/ChatbotChat';

export const History = () => {
  return (
    <HistoryTableDataProvider>
      <HistoryTable />
      <ChatbotChat showErrorDisclosures={false} />
    </HistoryTableDataProvider>
  );
};
