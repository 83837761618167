import * as React from 'react';
import * as Sentry from '@sentry/react';
import { Flex } from 'rebass/styled-components';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';

import { UnexpectedStateError } from '@deepstream/errors';
import { GridQuestionColumn, GridQuestionExchangeDefinition, QuestionExchangeDefinition, QuestionType } from '@deepstream/common/rfq-utils';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { FieldType, fieldIconByType } from '@deepstream/common/exchangesConfig';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { DisabledInputBox, ReadOnlyTextAreaBox } from '../../../ui/Input';
import { FormTableStyles } from '../../../TableStyles';
import { Table } from '../../../Table';
import { Bold } from '../../../Bold';
import { RowsConfig } from '../../../draft/QuestionFields/GridField';

const TextAreaBoxCell = ({ value }: { value: string }) => (
  <ReadOnlyTextAreaBox>
    <Truncate>
      {value}
    </Truncate>
  </ReadOnlyTextAreaBox>
);

const DisabledInputBoxCell = ({ value }: { value: string }) => (
  <DisabledInputBox>
    <Truncate>
      {value}
    </Truncate>
  </DisabledInputBox>
);

const ColumnTypeCell = ({ cell: { value: columnType } }: CellProps<GridQuestionColumn, FieldType>) => {
  const { t } = useTranslation();

  return (
    <ReadOnlyTextAreaBox>
      <IconText
        gap={2}
        // @ts-expect-error ts(2322) FIXME: Type '"function" | "sort" | "filter" | "download" | "search" | "link" | "columns" | "angle-left" | "angle-right" | "archive" | "arrow-down" | "arrow-down-short-wide" | "arrow-left" | ... 190 more ... | undefined' is not assignable to type '"function" | "sort" | "filter" | "download" | "search" | "link" | "columns" | "angle-left" | "angle-right" | "archive" | "arrow-down" | "arrow-down-short-wide" | "arrow-left" | ... 189 more ... | "person-circle-check"'.
        icon={fieldIconByType[columnType]}
        iconColor="subtext"
        text={t(`request.question.grid.columnType.${columnType}`)}
        fixedWidth
      />
    </ReadOnlyTextAreaBox>
  );
};

const DisabledColumnTypeCell = ({ cell: { value: columnType } }: CellProps<GridQuestionColumn, FieldType>) => {
  const { t } = useTranslation();

  return (
    <DisabledInputBox>
      <IconText
        gap={2}
        // @ts-expect-error ts(2322) FIXME: Type '"function" | "sort" | "filter" | "download" | "search" | "link" | "columns" | "angle-left" | "angle-right" | "archive" | "arrow-down" | "arrow-down-short-wide" | "arrow-left" | ... 190 more ... | undefined' is not assignable to type '"function" | "sort" | "filter" | "download" | "search" | "link" | "columns" | "angle-left" | "angle-right" | "archive" | "arrow-down" | "arrow-down-short-wide" | "arrow-left" | ... 189 more ... | "person-circle-check"'.
        icon={fieldIconByType[columnType]}
        iconColor="subtext"
        text={t(`request.question.grid.columnType.${columnType}`)}
        fixedWidth
      />
    </DisabledInputBox>
  );
};

export const ReadOnlyGridColumnTable = ({
  exchangeDef,
  isDisabled,
}: {
  exchangeDef: GridQuestionExchangeDefinition;
  isDisabled?: boolean;
}) => {
  const { t } = useTranslation();

  const tableColumns = React.useMemo(
    () => [
      {
        id: 'name',
        Header: t('request.question.grid.columnName'),
        accessor: 'name',
        Cell: isDisabled ? DisabledInputBoxCell : TextAreaBoxCell,
      },
      {
        id: 'type',
        Header: t('request.question.grid.type'),
        accessor: 'type',
        Cell: isDisabled ? DisabledColumnTypeCell : ColumnTypeCell,
        width: 250,
      },
    ],
    [isDisabled, t],
  );

  return (
    <FormTableStyles>
      <Table columns={tableColumns} data={exchangeDef.columns} />
    </FormTableStyles>
  );
};

export const Grid = ({
  exchangeDef,
  fieldMaxWidth,
}: {
  exchangeDef: QuestionExchangeDefinition;
  fieldMaxWidth?: React.CSSProperties['maxWidth'];
}) => {
  const { t } = useTranslation(['translation', 'general']);
  const { questionType, rowsConfig, currencies } = exchangeDef as GridQuestionExchangeDefinition;

  if (questionType !== QuestionType.GRID) {
    Sentry.captureException(new UnexpectedStateError('[Grid] Wrong question type passed', { questionType }));
    return null;
  }

  return (
    <Stack gap="20px" width="85%">
      <ReadOnlyGridColumnTable exchangeDef={exchangeDef as GridQuestionExchangeDefinition} />
      {!isEmpty(currencies) && (
        <Flex alignItems="center" sx={{ gap: 3 }}>
          <Bold>
            {t('currency', { ns: 'general' })}
          </Bold>
          <ReadOnlyTextAreaBox maxWidth="140px">
            <Truncate>
              {/*
               // @ts-expect-error ts(18048) FIXME: 'currencies' is possibly 'undefined'. */}
              {currencies.join(', ')}
            </Truncate>
          </ReadOnlyTextAreaBox>
        </Flex>
      )}
      <RowsConfig rowsConfig={rowsConfig} isReadOnly />
    </Stack>
  );
};
