import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useMemo } from 'react';
import { stopEvent } from '@deepstream/ui-utils/domEvent';
import { compact, isEmpty, update, isFinite, sum, sumBy, first, noop } from 'lodash';
import { Attachment, Live, TotalSavingsCalculationMethod, AwardScenario, Message, MessageAndSupplierIds } from '@deepstream/common/rfq-utils';
import { localeFormatPrice } from '@deepstream/utils';
import { Clamp2 } from '@deepstream/ui-kit/elements/text/Clamp';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData, AwardFlowStepType } from '../types';
import * as rfx from '../../../../rfx';
import { useCurrentUserLocale } from '../../../../useCurrentUser';
import { PreWrap } from '../../../../PreWrapCell';
import { useMessagesBySupplierGroup } from '../useMessagesBySupplierGroup';
import { useWaitForRfqUnlock } from '../../../../useWaitForUnlock';
import { useApi } from '../../../../api';
import { useMutation } from '../../../../useMutation';
import { useCurrentCompanyId } from '../../../../currentCompanyId';
import { useRfqId } from '../../../../useRfq';
import { Direction } from '../../../../ui/MultiStepFlow/types';

const ACCEPT_AND_SUBMIT_ID = 'acceptAndSubmit';
const HEADER_HEIGHT_AND_PADDING = 140 + 20;

const OverallOutcomeContent = ({ data }: { data: AwardFlowData }) => {
  const { t } = useTranslation('translation');
  const getSortedRecipients = rfx.useGetSortedRecipients();

  const items = useMemo(() => {
    return [
      {
        key: 'awardScenario',
        label: t('request.awardFlow.steps.reviewAndSubmit.awardScenario'),
        value: t(`request.awardScenarios.awardScenarios.${data.awardScenario}.label`),
      },
      ...compact([
        'awarded',
        'unsuccessful',
        'previouslyUnsuccessful',
        'nonBidding',
      ].map(supplierGroup => {
        const supplierIds = data.supplierIdsByGroup[supplierGroup];

        return isEmpty(supplierIds)
          ? null
          : {
            key: supplierGroup,
            label: t(`request.awardFlow.steps.chooseSupplierTypesToMessage.options.${supplierGroup}.label`),
            value: getSortedRecipients(supplierIds)
              .map(recipient => recipient.company.name)
              .join(', '),
          };
      })),
    ];
  }, [t, data, getSortedRecipients]);

  return (
    <lotPagesLayout.DescriptionListContainer style={{ marginTop: '8px', gridTemplateColumns: '3fr 8fr' }}>
      {items.map(({ key, value, label }) => (
        <lotPagesLayout.DescriptionListItem
          key={key}
          label={label}
          description={value}
        />
      ))}
    </lotPagesLayout.DescriptionListContainer>
  );
};

const LotOutcomesContent = ({ data }: { data: AwardFlowData }) => {
  const { t } = useTranslation('translation');
  const { lots, recipients } = rfx.useStructure<Live>();

  const items = useMemo(() => {
    return compact(lots.map((lot, index) => {
      // @ts-expect-error ts(18048) FIXME: 'data.awardDecisionByLotId' is possibly 'undefined'.
      const awardDecision = data.awardDecisionByLotId[lot._id];

      if (!awardDecision || !['award', 'noAward'].includes(awardDecision.value)) {
        return null;
      }

      const key = lot._id;
      const label = `${t('request.lot', { count: 1, ns: 'translation' })} ${index + 1} – ${lot.name}`;

      if (awardDecision.value === 'award') {
        const { recipientId } = awardDecision.awards[0];
        // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
        const supplierName = recipients.find(recipient => recipient._id === recipientId).company.name;

        return {
          key,
          label,
          value: t('request.awardFlow.steps.reviewAndSubmit.awardedTo', { supplierName }),
        };
      } else {
        return {
          key,
          label,
          value: t('request.awardFlow.steps.reviewAndSubmit.notAwarded'),
        };
      }
    }));
  }, [lots, data.awardDecisionByLotId, t, recipients]);

  return (
    <lotPagesLayout.DescriptionListContainer style={{ marginTop: '8px', gridTemplateColumns: '3fr 8fr' }}>
      {items.map(({ key, value, label }) => (
        <lotPagesLayout.DescriptionListItem
          key={key}
          label={label}
          description={value}
        />
      ))}
    </lotPagesLayout.DescriptionListContainer>
  );
};

const LineItemOutcomesContent = ({ data }: { data: AwardFlowData }) => {
  const { t } = useTranslation('translation');
  const locale = useCurrentUserLocale();
  const getSortedRecipients = rfx.useGetSortedRecipients();

  const items = useMemo(() => {
    const listFormatter = new Intl.ListFormat(locale, {
      style: 'long',
      type: 'conjunction',
    });

    const awardedCountsBySupplierId = {};

    // @ts-expect-error ts(2769) FIXME: No overload matches this call.
    for (const awardDecision of Object.values(data.awardDecisionByExchangeId)) {
      // @ts-expect-error ts(18047) FIXME: 'awardDecision' is possibly 'null'.
      if (awardDecision.value === 'award') {
        // @ts-expect-error ts(18047) FIXME: 'awardDecision' is possibly 'null'.
        const isSplitAward = awardDecision.awards.length > 1;

        // @ts-expect-error ts(18047) FIXME: 'awardDecision' is possibly 'null'.
        for (const award of awardDecision.awards) {
          const path = isSplitAward
            ? [award.recipientId, 'partial']
            : [award.recipientId, 'full'];

          update(awardedCountsBySupplierId, path, (count = 0) => count + 1);
        }
      }
    }

    const recipients = getSortedRecipients(Object.keys(awardedCountsBySupplierId));

    return recipients.map(recipient => {
      const { full, partial } = awardedCountsBySupplierId[recipient._id];

      const listItems = compact<string>([
        full && t('request.awardFlow.steps.reviewAndSubmit.lineItemAwardedCount', { count: full }),
        partial && t('request.awardFlow.steps.reviewAndSubmit.lineItemPartiallyAwardedCount', { count: partial }),
      ]);

      return {
        key: recipient._id,
        label: recipient.company.name,
        value: `${listFormatter.format(listItems)}.`,
      };
    });
  }, [locale, getSortedRecipients, data.awardDecisionByExchangeId, t]);

  return (
    <lotPagesLayout.DescriptionListContainer style={{ marginTop: '8px', gridTemplateColumns: '3fr 8fr' }}>
      {items.map(({ key, value, label }) => (
        <lotPagesLayout.DescriptionListItem
          key={key}
          label={label}
          description={value}
        />
      ))}
    </lotPagesLayout.DescriptionListContainer>
  );
};

const SpendAndSavingsContent = ({ data }: { data: AwardFlowData }) => {
  const { t } = useTranslation('translation');
  const locale = useCurrentUserLocale();
  const { currencyCode } = rfx.useStructure<Live>();

  const items = useMemo(() => {
    const { spendAndSavings } = data;

    const totalValue = isFinite(spendAndSavings.manualTotalValue)
      ? spendAndSavings.manualTotalValue
      : spendAndSavings.calculatedTotalValue ?? undefined;

    const totalSavings = spendAndSavings.totalSavingsCalculationMethod === TotalSavingsCalculationMethod.SUM_SPECIFIC_SAVINGS ? (
      sum(
        // @ts-expect-error ts(2769) FIXME: No overload matches this call.
        Object.values(spendAndSavings.calculatedSavingsByType).map(item => sumBy(Object.values(item), item => item.result)),
      )
    ) : spendAndSavings.totalSavingsCalculationMethod === TotalSavingsCalculationMethod.BUDGET_FINAL_VALUE_DIFF ? (
      // @ts-expect-error ts(18047) FIXME: 'spendAndSavings.budgetedTotalValue' is possibly 'null'.
      spendAndSavings.budgetedTotalValue - totalValue
    ) : spendAndSavings.totalSavingsCalculationMethod === TotalSavingsCalculationMethod.MANUAL ? (
      spendAndSavings.manualTotalSavings
    ) : (
      null
    );

    return compact([
      {
        key: 'budgetedTotalValue',
        label: t('request.spendAndSavings.budgetedTotalValue'),
        value: isFinite(spendAndSavings.budgetedTotalValue)
          // @ts-expect-error ts(2345) FIXME: Argument of type 'number | null' is not assignable to parameter of type 'number'.
          ? localeFormatPrice(spendAndSavings.budgetedTotalValue, currencyCode, { locale, showCode: true })
          : t('request.spendAndSavings.noBudgetedValue'),
      },
      {
        key: 'totalValue',
        label: t('request.spendAndSavings.totalValue'),
        value: isFinite(totalValue)
          // @ts-expect-error ts(2345) FIXME: Argument of type 'number | null | undefined' is not assignable to parameter of type 'number'.
          ? localeFormatPrice(totalValue, currencyCode, { locale, showCode: true })
          : t('request.spendAndSavings.cannotBeProvided'),
      },
      spendAndSavings.totalSavingsCalculationMethod && {
        key: 'savingsMethod',
        label: t('request.spendAndSavings.savingsMethod'),
        value: t(`request.spendAndSavings.totalSavingsCalculationMethods.${spendAndSavings.totalSavingsCalculationMethod}`),
      },
      {
        key: 'totalSavings',
        label: t('request.spendAndSavings.totalSavings'),
        value: isFinite(totalSavings)
          // @ts-expect-error ts(2345) FIXME: Argument of type 'number | null' is not assignable to parameter of type 'number'.
          ? localeFormatPrice(totalSavings, currencyCode, { locale, showCode: true })
          : t('request.spendAndSavings.cannotBeProvided'),
      },
      spendAndSavings.totalSavingsCalculationMethod === TotalSavingsCalculationMethod.MANUAL && {
        key: 'savingsMethod',
        label: t('request.spendAndSavings.savingsDescription'),
        value: (
          <PreWrap>
            <Clamp2 lines={1}>
              {spendAndSavings.manualTotalSavingsDescription}
            </Clamp2>
          </PreWrap>
        ),
      },
    ]);
  }, [data, t, currencyCode, locale]);

  return (
    <lotPagesLayout.DescriptionListContainer style={{ marginTop: '8px', gridTemplateColumns: '3fr 8fr' }}>
      {items.map(({ key, value, label }) => (
        <lotPagesLayout.DescriptionListItem
          key={key}
          label={label}
          description={value}
        />
      ))}
    </lotPagesLayout.DescriptionListContainer>
  );
};

const renderAttachmentList = (attachments: Attachment[]) => {
  return attachments.map(attachment => `\n  •  ${attachment.name}`).join('\n');
};

const MessageDetails = ({ message }: { message: Message }) => {
  const { t } = useTranslation('translation');

  return (
    <PreWrap>
      <Clamp2 lines={1}>
        {message.text}
        {isEmpty(message.attachments) ? (
          null
        ) : (
          `\n\n${t('request.awardFlow.steps.reviewAndSubmit.attachedFiles')}${renderAttachmentList(message.attachments!)}`
        )}
      </Clamp2>
    </PreWrap>
  );
};

const MessagesContent = ({
  messagesAndSupplierIds,
}: {
  messagesAndSupplierIds: MessageAndSupplierIds[],
}) => {
  const { t } = useTranslation('translation');
  const { recipients } = rfx.useStructure<Live>();
  const getSortedRecipients = rfx.useGetSortedRecipients();

  const items = useMemo(() => {
    return messagesAndSupplierIds.flatMap(({ message, supplierIds }, index) => {
      if (supplierIds.length > 1) {
        return [
          {
            key: `${index}-1`,
            label: t('request.awardFlow.steps.reviewAndSubmit.generalMessage'),
            value: <MessageDetails message={message} />,
          },
          {
            key: `${index}-2`,
            label: t('request.awardFlow.steps.reviewAndSubmit.generalMessageRecipients'),
            value: getSortedRecipients(supplierIds)
              .map(recipient => recipient.company.name)
              .join(', '),
          },
        ];
      } else {
        return [
          {
            key: `${index}`,
            label: t('request.awardFlow.steps.reviewAndSubmit.individualMessageTo', {
              supplierName: recipients.find(recipient => recipient._id === first(supplierIds))?.company.name,
            }),
            value: <MessageDetails message={message} />,
          },
        ];
      }
    });
  }, [messagesAndSupplierIds, t, getSortedRecipients, recipients]);

  return (
    <lotPagesLayout.DescriptionListContainer style={{ marginTop: '8px', gridTemplateColumns: '3fr 8fr' }}>
      {items.map(({ key, value, label }) => (
        <lotPagesLayout.DescriptionListItem
          key={key}
          label={label}
          description={value}
        />
      ))}
    </lotPagesLayout.DescriptionListContainer>
  );
};

const IndexListItem = ({ id, heading }: { id: string; heading: React.ReactNode }) => {
  return (
    <li key={id}>
      <lotPagesLayout.TanstackInlineLink
        href={`#${id}`}
        onClick={(event) => {
          const element = document.getElementById(id);
          if (element) {
            const y = element.getBoundingClientRect().top + window.scrollY;
            const rootElement = document.getElementById('root');
            rootElement!.scroll({
              top: y - HEADER_HEIGHT_AND_PADDING,
              behavior: 'smooth',
            });
          }

          stopEvent(event);
        }}
      >
        {heading}
      </lotPagesLayout.TanstackInlineLink>
    </li>
  );
};

export const ReviewAndSubmitStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (
    newData: Partial<AwardFlowData> | null,
    direction: Direction | null,
    targetStep?: AwardFlowStepType,
  ) => void,
}) => {
  const { t } = useTranslation(['translation', 'general']);
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const messagesBySupplierGroup = useMessagesBySupplierGroup(data);
  const waitForRfqUnlock = useWaitForRfqUnlock();

  const [awardOrCloseRequest, { isLoading, isSuccess, isError }] = useMutation(
    ({ rfqId, currentCompanyId, payload }: any) => waitForRfqUnlock({
      command: () => api.awardOrCloseRequest({
        rfqId,
        currentCompanyId,
        payload,
      }),
    }),
  );

  const subsections = useMemo(() => {
    return compact([
      {
        id: 'overallOutcome',
        heading: t('request.awardFlow.steps.reviewAndSubmit.overallOutcome'),
        content: <OverallOutcomeContent data={data} />,
      },
      data.awardScenario === AwardScenario.LOT_LEVEL_AWARD && ({
        id: 'lotOutcomes',
        heading: t('request.awardFlow.steps.reviewAndSubmit.lotOutcomes'),
        content: <LotOutcomesContent data={data} />,
      }),
      data.awardScenario === AwardScenario.LINE_LEVEL_AWARD && ({
        id: 'lineItemOutcomes',
        heading: t('request.awardFlow.steps.reviewAndSubmit.lineItemsAwardedPerSupplier'),
        content: <LineItemOutcomesContent data={data} />,
      }),
      data.awardScenario !== AwardScenario.NO_AWARD && {
        id: 'spendAndSavings',
        heading: t('request.spendAndSavings.spendAndSavings'),
        content: data.spendAndSavings.enabled
          ? <SpendAndSavingsContent data={data} />
          : <Text mt={3}>{t('request.spendAndSavings.disabled')}</Text>,
      },
      ...isEmpty(messagesBySupplierGroup)
        ? [{
          id: 'noMessages',
          heading: t('request.messages'),
          content: <Text mt={3}>{t('request.awardFlow.steps.reviewAndSubmit.noMessagesSent')}</Text>,
        }]
        : Object.entries(messagesBySupplierGroup).map(([supplierGroup, messagesAndSupplierIds]) => ({
          id: `messages-${supplierGroup}`,
          heading: t(`request.awardFlow.steps.reviewAndSubmit.messagesTo.${supplierGroup}`),
          content: <MessagesContent messagesAndSupplierIds={messagesAndSupplierIds} />,
        })),
    ]);
  }, [t, data, messagesBySupplierGroup]);

  return (
    <lotPagesLayout.ContentWrapper sx={{ scrollMarginTop: '60px' }}>
      <StepNavigation
        onBackClick={isLoading || isSuccess ? undefined : () => submitAndNavigate(null, Direction.BACK)}
      >
        <lotPagesLayout.Section heading={t('request.awardFlow.steps.reviewAndSubmit.heading')}>
          <Stack gap="40px" mt="40px">
            <lotPagesLayout.Subsection heading={t('request.awardFlow.steps.reviewAndSubmit.contents')}>
              <lotPagesLayout.Ul style={{ marginTop: '12px' }} listStyleType="—  ">
                {subsections.map(({ id, heading }) => (
                  <IndexListItem key={id} id={id} heading={heading} />
                ))}
                <IndexListItem
                  id={ACCEPT_AND_SUBMIT_ID}
                  heading={t('request.awardFlow.steps.reviewAndSubmit.acceptAndSubmit')}
                />
              </lotPagesLayout.Ul>
            </lotPagesLayout.Subsection>
            {subsections.map(({ id, heading, content }) => (
              <lotPagesLayout.Subsection key={id} id={id} heading={heading}>
                {content}
              </lotPagesLayout.Subsection>
            ))}
            <lotPagesLayout.Subsection
              id={ACCEPT_AND_SUBMIT_ID}
              heading={t('request.awardFlow.steps.reviewAndSubmit.acceptAndSubmit')}
            >
              <Text mt={3} mb="20px">
                {t('request.awardFlow.steps.reviewAndSubmit.submitInfo')}
              </Text>
              <Flex alignItems="center">
                <Button
                  type="button"
                  onClick={() => awardOrCloseRequest({
                    rfqId,
                    currentCompanyId,
                    payload: {
                      messagesBySupplierGroup,
                      supplierIdsByGroup: data.supplierIdsByGroup,
                      awardScenario: data.awardScenario,
                      requestAward: data.requestAward,
                      awardDecisionByLotId: data.awardDecisionByLotId,
                      awardDecisionByExchangeId: data.awardDecisionByExchangeId,
                      splitDecisionByExchangeId: data.splitDecisionByExchangeId,
                      spendAndSavings: data.awardScenario === AwardScenario.NO_AWARD
                        ? undefined
                        : data.spendAndSavings,
                    },
                  }, {
                    onSuccess: () => submitAndNavigate(null, null, AwardFlowStepType.SUBMITTED_SUCCESSFULLY),
                  }).catch(noop)}
                  disabled={isLoading}
                >
                  {t('request.awardFlow.steps.reviewAndSubmit.acceptAndSubmit')}
                </Button>
                {isLoading && (
                  <>
                    <Icon icon="spinner" spin ml={4} />
                    <Text ml="6px">
                      {t('processing', { ns: 'general' })}
                    </Text>
                  </>
                )}
              </Flex>
              {isError && (
                <MessageBlock variant="error" mt="20px">
                  {t('request.awardFlow.errors.couldNotSubmit')}
                </MessageBlock>
              )}
            </lotPagesLayout.Subsection>
          </Stack>
        </lotPagesLayout.Section>
      </StepNavigation>
    </lotPagesLayout.ContentWrapper>
  );
};
