import { useFormikContext } from 'formik';
import { map, Dictionary } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Text, Flex } from 'rebass/styled-components';
import {
  LineItemExchangeDefinition,
  DocumentExchangeDefinition,
  AuctionLineItemExchangeDefinition,
  isLinkedAuctionLineItemExchangeDef,
  isAuctionLineItemExchangeDef,
} from '@deepstream/common/rfq-utils';
import { isDocumentExchangeDefinition } from '@deepstream/common/contract/contract';

import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { CheckboxField } from '../../../form/CheckboxField';
import { useContractData } from '../contract';
import {
  ImportFormValues,
  useExchanges,
  useIsLineItemDefLinkedToAuction,
  useIsLinkedItemSelected,
} from './utils';

export const ImportDetailsExchangesList = ({
  exchanges,
}: {
  exchanges: Dictionary<LineItemExchangeDefinition | DocumentExchangeDefinition | AuctionLineItemExchangeDefinition>;
}) => {
  const { t } = useTranslation('contracts');
  const { values } = useFormikContext<ImportFormValues>();
  const { importedExchangesIdsMap } = useContractData();
  const { exchanges: allExchanges } = useExchanges();
  const isLineItemExchangeDefInAuction = useIsLineItemDefLinkedToAuction();
  const isLinkedItemSelected = useIsLinkedItemSelected();

  return (
    <>
      {map(exchanges, (exchange) => {
        const alreadyImported = !!importedExchangesIdsMap?.[exchange._id];
        // Check if the adjacent (linked) is selected so we are not allow to select this one
        const linkedItemSelected = isLinkedItemSelected(exchange);
        const isDisabled = alreadyImported || linkedItemSelected;

        return (
          <Flex
            key={exchange._id}
            sx={{
              alignItems: 'center',
              borderBottom: 'lightGray2',
              padding: '0 30px',
              backgroundColor: values[exchange._id] && !importedExchangesIdsMap[exchange._id]
                ? 'rgba(52, 152, 219, 0.1)'
                : 'transparent',
            }}
          >
            <CheckboxField
              key={exchange._id}
              name={exchange._id}
              disabled={isDisabled}
              checkboxStyle={{
                padding: '20px 0',
              }}
              fieldLabel={(
                <Truncate sx={{ flex: '1 1 70%' }}>
                  {isDocumentExchangeDefinition(exchange) ? (
                    <Text>{exchange.category}</Text>
                  ) : isLinkedAuctionLineItemExchangeDef(exchange) ? (
                    <Text>{(allExchanges[exchange.linkedExchangeDefId] as LineItemExchangeDefinition).description}</Text>
                  ) : (
                    <Text>{exchange.description}</Text>
                  )}
                </Truncate>
              )}
            />
            {
              isLinkedAuctionLineItemExchangeDef(exchange) ? (
                <IconText
                  text={t('details.importRequestContent.links.lineItem')}
                  icon="link"
                  iconPosition="right"
                  color="primary"
                  sx={{ width: '150px' }}
                  textStyle={{ textAlign: 'right' }}
                />
              ) : isLineItemExchangeDefInAuction(exchange) ? (
                <IconText
                  text={t('details.importRequestContent.links.auction')}
                  icon="link"
                  iconPosition="right"
                  color="primary"
                  sx={{ width: '150px' }}
                  textStyle={{ textAlign: 'right' }}
                />
              ) : (
                <Text
                  sx={{
                    width: '150px',
                    textAlign: 'right',
                    opacity: importedExchangesIdsMap?.[exchange._id] ? 0.5 : 1,
                  }}
                >
                  {
                    isAuctionLineItemExchangeDef(exchange)
                      ? t('details.importRequestContent.type.auctionLineItem')
                      : isDocumentExchangeDefinition(exchange)
                        ? t('details.importRequestContent.type.document')
                        : t('details.importRequestContent.type.lineItem')
                  }
                </Text>
              )
            }

          </Flex>
        );
      })}
    </>
  );
};
