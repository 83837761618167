import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';
import * as Layout from '../../../Layout';
import { PageHeading } from '../../../page-headers/PageHeading';
import { EditStatusIndicator } from '../../../EditStatus';
import { QuestionnaireTemplateProvider, useQuestionnaireTemplateState } from './questionnaireTemplateUtils';
import { TemplatePageTab } from './utils';
import { useQuestionnaireTemplate } from './useQuestionnaireTemplate';
import { LabeledQuestionnaireTemplateStatus } from './QuestionnaireTemplateStatus';
import { QuestionnaireTemplateActions } from './QuestionnaireTemplateActions';
import { useDraftQuestionnaireTemplateNavigation, useActiveQuestionnaireTemplateNavigation } from '../../../appNavigation';

type HeaderProps = {
  selectedTabId: string;
};

export const Header = ({ selectedTabId }: HeaderProps) => {
  const { t } = useTranslation(['preQualification', 'translation']);
  // @ts-expect-error ts(2339) FIXME: Property 'isLive' does not exist on type 'QuestionnaireTemplateStateContextType | undefined'.
  const { isLive, isDraft, isRevising } = useQuestionnaireTemplateState();
  const draftNavigation = useDraftQuestionnaireTemplateNavigation();
  const activeNavigation = useActiveQuestionnaireTemplateNavigation();

  const { data: template } = useQuestionnaireTemplate({
    scope: isDraft ? 'draft' : 'current',
  });

  const tabs = useMemo(
    () => isLive
      ? [
        {
          id: TemplatePageTab.SUPPLIERS,
          name: t('general.supplier_other', { ns: 'translation' }),
          navigate: activeNavigation.navigateToSuppliers,
        },
        {
          id: TemplatePageTab.QUESTIONS,
          name: t('question_other'),
          navigate: activeNavigation.navigateToQuestions,
        },
        {
          id: TemplatePageTab.DETAILS,
          name: t('general.details', { ns: 'translation' }),
          navigate: activeNavigation.navigateToDetails,
        },
      ] : [
        {
          id: TemplatePageTab.SUMMARY,
          name: t('general.summary', { ns: 'translation' }),
          navigate: draftNavigation.navigateToSummary,
        },
        {
          id: TemplatePageTab.QUESTIONS,
          name: t('question_other'),
          navigate: draftNavigation.navigateToQuestions,
        },
        {
          id: TemplatePageTab.REVIEW,
          name: t('general.reviewAndPublish', { ns: 'translation' }),
          navigate: draftNavigation.navigateToReview,
        },
      ],
    [isLive, draftNavigation, activeNavigation, t],
  );

  const editStatus = isRevising ? 'revision' : 'draft';

  const defaultHeading = t('untitledTemplate');

  return (
    <Layout.PageHeader
      tabs={tabs}
      selectedTabId={selectedTabId}
      heading={() => (
        <PageHeading
          icon="file-check"
          iconProps={{ regular: true }}
          text={template?.summary.name || defaultHeading}
        />
      )}
    >
      {isDraft ? (
        <Box flex="0 0 auto" ml={4}>
          <EditStatusIndicator editStatus={editStatus} />
        </Box>
      ) : template ? (
        <LabeledQuestionnaireTemplateStatus status={template.status} />
      ) : (
        null
      )}
      {isLive && (
        // @ts-expect-error ts(2322) FIXME: Type 'QuestionnaireTemplate | undefined' is not assignable to type 'QuestionnaireTemplate'.
        <QuestionnaireTemplateProvider template={template}>
          <QuestionnaireTemplateActions />
        </QuestionnaireTemplateProvider>
      )}
    </Layout.PageHeader>
  );
};
