import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass/styled-components';
import { IconValue } from '@deepstream/common';

import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelHeader, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { ErrorMessage } from '../../ui/ErrorMessage';
import { Spinner } from '../../ui/Loading';

export const DashboardPanel = ({
  icon,
  title,
  isLoading,
  isError,
  children,
  goToAll,
}: {
  icon: IconValue;
  title: string;
  children: React.ReactNode;
  isLoading?: boolean;
  isError?: boolean;
  goToAll?: () => void;
}) => {
  const { t } = useTranslation(['general', 'dashboard']);

  return (
    <Panel sx={{ display: 'flex', flexDirection: 'column' }}>
      <PanelHeader icon={icon} heading={title}>
        <Button
          small
          variant="secondary-outline"
          onClick={goToAll}
        >
          {t('actions.seeAll', { ns: 'dashboard' })}
        </Button>
      </PanelHeader>
      <PanelDivider />
      {isLoading ? (
        <PanelPadding sx={{ textAlign: 'center' }}>
          <Spinner />
        </PanelPadding>
      ) : isError ? (
        <Flex p="20px" justifyContent="center">
          <ErrorMessage fontSize={2} error={t('couldNotGetData', { ns: 'general' })} />
        </Flex>
      ) : (
        <Box sx={{ flex: '1 1 auto', overflow: 'hidden' }}>{children}</Box>
      )}
    </Panel>
  );
};
