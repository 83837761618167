import { useMemo } from 'react';
import { RfqStatus } from '@deepstream/common/rfq-utils';
import { getBudgetSectionStatus, getFinalValueSectionStatus, getTotalSavingsSectionStatus } from '@deepstream/common/rfq-utils/spendAndSavings';
import { useTranslation } from 'react-i18next';
import { countBy } from 'lodash';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import * as rfx from '../../../rfx';
import { spendSectionStatusIconProps } from './spendSectionStatus';
import { LabeledValue } from '../../../draft/LabeledValue';

export const SpendAndSavingsStatus = () => {
  const { t } = useTranslation('translation');
  const structure = rfx.useStructure();

  const statusCounts = useMemo(() => {
    const isAwarded = structure.status === RfqStatus.AWARDED;

    const statuses = isAwarded
      ? [
        // @ts-expect-error ts(2345) FIXME: Argument of type 'RfxSpendAndSavings | null | undefined' is not assignable to parameter of type 'RfxSpendAndSavings | undefined'.
        getBudgetSectionStatus(structure.spendAndSavings),
        // @ts-expect-error ts(2345) FIXME: Argument of type 'RfxSpendAndSavings | null | undefined' is not assignable to parameter of type 'RfxSpendAndSavings | undefined'.
        getFinalValueSectionStatus(structure.spendAndSavings),
        // @ts-expect-error ts(2345) FIXME: Argument of type 'RfxSpendAndSavings | null | undefined' is not assignable to parameter of type 'RfxSpendAndSavings | undefined'.
        getTotalSavingsSectionStatus(structure.spendAndSavings),
      ]
      : [
        // @ts-expect-error ts(2345) FIXME: Argument of type 'RfxSpendAndSavings | null | undefined' is not assignable to parameter of type 'RfxSpendAndSavings | undefined'.
        getBudgetSectionStatus(structure.spendAndSavings),
      ];

    return countBy(statuses);
  }, [structure]);

  return (
    <>
      {['incomplete', 'complete', 'disabled'].map(status => (
        statusCounts[status] ? (
          <LabeledValue
            key={status}
            label={t(`request.spendAndSavings.sectionStatus.${status}`)}
            value={(
              <IconText
                {...spendSectionStatusIconProps[status]}
                text={statusCounts[status]}
              />
            )}
            align="left"
            mr={3}
          />
        ) : (
          null
        )
      ))}
    </>
  );
};
