import * as React from 'react';
import { findIndex, isEmpty } from 'lodash';
import { Flex, Text } from 'rebass/styled-components';
import { CollaboratorInviteStatus, Sender } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { PropertyList, ValueOrEmDash } from '../../../PropertyList';
import { ValidationPropertyRow } from '../../../draft/ValidationPropertyRow';
import { ValidationErrorValue } from '../../../draft/ValidationErrorValue';
import { nestValues } from '../../../nestValues';
import * as rfx from '../../../rfx';

const CollaboratorStatus = ({ inviteStatus }: { inviteStatus: CollaboratorInviteStatus }) => {
  const { t } = useTranslation();
  return (
    <Flex alignItems="baseline">
      <Icon
        fontSize={2}
        icon={inviteStatus === CollaboratorInviteStatus.ACCEPTED ? 'check' : 'envelope'}
        regular={inviteStatus !== CollaboratorInviteStatus.ACCEPTED}
        mr={2}
      />
      <Text fontSize={2}>
        {inviteStatus === CollaboratorInviteStatus.ACCEPTED ? (
          t('teamManagement.collaboratorStatus.accepted')
        ) : (
          t('teamManagement.collaboratorStatus.invited')
        )}
      </Text>
    </Flex>
  );
};

export const ReadOnlySenderTeamDetails = ({ sender }: { sender: Sender }) => {
  const { t } = useTranslation();
  const { teamById, senders } = rfx.useStructure();

  const isCollaborator = React.useMemo(
    () => findIndex(senders, { _id: sender._id }) > 0,
    [senders, sender],
  );

  const properties = React.useMemo(
    () => {
      const propertyListItems = [{
        name: t('general.companyRole'),
        value: sender.company.role,
        fieldName: 'sender.company.role',
        Component: nestValues(ValidationErrorValue, ValueOrEmDash),
      }] as {
        name: string;
        fieldName: string;
        value: React.ReactNode;
        Component?: (props: any) => React.ReactNode;
      }[];

      const companyHasAnyUser = !isEmpty(teamById[sender._id].users);

      if (isCollaborator && companyHasAnyUser) {
        const collaboratorStatus = (
          // @ts-expect-error ts(2322) FIXME: Type 'CollaboratorInviteStatus | undefined' is not assignable to type 'CollaboratorInviteStatus'.
          <CollaboratorStatus inviteStatus={sender.inviteStatus} />
        );

        propertyListItems.push({
          name: t('teamManagement.collaboratorStatus.collaboratorStatus'),
          value: collaboratorStatus,
          fieldName: 'sender.inviteStatus',
        });
      }

      return propertyListItems;
    },
    [sender, isCollaborator, teamById, t],
  );

  return (
    <PropertyList Row={ValidationPropertyRow} properties={properties} />
  );
};
