import { useTranslation } from 'react-i18next';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';

export const ActionRequiredPlaceholder = () => {
  const { t } = useTranslation('translation');

  return (
    <IconText
      icon="exclamation-circle"
      text={t('request.progress.actionRequired')}
      iconColor="danger"
    />
  );
};
