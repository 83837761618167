import * as lotPagesLayout from '../Live/lotPagesLayout';
import { useRequestSentNavigation } from '../../../appNavigation';

export const OpenRequestInNewTabLink = ({ children = undefined }) => {
  const navigation = useRequestSentNavigation();

  return (
    <lotPagesLayout.TanstackInlineLink
      target="_blank"
      {...navigation.getSuppliersLinkProps()}
    >
      {children}
    </lotPagesLayout.TanstackInlineLink>
  );
};
