import { useCurrentCompanyId } from '../../currentCompanyId';
import { DraftEvaluationPage } from '../NewEvaluation/DraftEvaluationPage';
import { useRequestEditNavigation } from '../../appNavigation';
import { DraftHeader } from './DraftHeader';
import * as Layout from '../../Layout';

export const DraftEvaluationPagePageContent = ({
  rfqId,
  pageId,
  isRevising,
  isTemplate,
}) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { navigateToEvaluationPage, navigateToTeam } = useRequestEditNavigation();

  const tabId = 'evaluation';

  return (
    <Layout.HeaderAndContent
      header={
        <DraftHeader
          rfqId={rfqId}
          isRevising={isRevising}
          isTemplate={isTemplate}
          selectedTabId={tabId}
        />
      }
      content={
        <DraftEvaluationPage
          rfqId={rfqId}
          pageId={pageId}
          isRevising={isRevising}
          isTemplate={isTemplate}
          navigateToPage={navigateToEvaluationPage}
          navigateToTeam={() => navigateToTeam(currentCompanyId)}
        />
      }
      contentPaddingBottom={Layout.BOTTOM_TOOLBAR_CONTENT_PADDING_BOTTOM}
    />
  );
};
