import { StageStatus } from '@deepstream/common/rfq-utils';

export const iconPropsByStageStatus = {
  [StageStatus.LIVE]: {
    icon: 'circle',
    color: 'success',
  },
  [StageStatus.DEADLINE_PASSED]: {
    icon: 'clock',
    regular: true,
    color: 'danger',
  },
  [StageStatus.PENDING]: {
    icon: 'circle-dashed',
    color: 'subtext',
  },
  [StageStatus.SCHEDULED]: {
    icon: 'calendar',
    regular: true,
    color: 'primary',
  },
  [StageStatus.PAUSED]: {
    icon: 'pause',
    color: 'subtext',
  },
  [StageStatus.ENDED]: {
    icon: 'square',
    color: 'subtext',
  },
  [StageStatus.CANCELLED]: {
    icon: 'circle-xmark',
    regular: true,
    color: 'subtext',
  },
} as const;
