import { Form, Formik, useField } from 'formik';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { memo, useCallback, useMemo } from 'react';
import { findIndex, isArray, isEmpty, keyBy, map, noop, sortBy } from 'lodash';
import { Text, Flex, Box } from 'rebass/styled-components';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { ExtendedSupplierListRule, SupplierListQuestionnaireRule, SupplierListRuleType } from '@deepstream/common';
import { QuestionnaireStatus, QuestionnaireTemplateOverview, questionnaireStatusConfig } from '@deepstream/common/preQual';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { stopEvent } from '@deepstream/ui-utils/domEvent';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { localeSort } from '@deepstream/utils';
import { ListConfigFlowData, ListConfigFlowStepType } from '../types';
import * as layout from '../../../Request/Live/lotPagesLayout';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { StepNavigation2 } from '../../../../ui/MultiStepFlow/StepNavigation';
import { useCurrentCompanyId } from '../../../../currentCompanyId';
import { useApi, wrap } from '../../../../api';
import { Bold } from '../../../../Bold';
import { CheckboxFieldArray } from '../../../../form/CheckboxField';
import { MultiSelectField } from '../../../../form/MultiSelectField';
import { getItemValue } from '../../../../form/SelectField';
import { QuestionnaireStatusesList } from '../QuestionnaireStatusesList';
import { useMultiStepFlowData } from '../../../../ui/MultiStepFlow/MultiStepFlowContext';
import { useCurrentUserLocale } from '../../../../useCurrentUser';

type FormValues = {
  rules: ExtendedSupplierListRule[];
};

const orderedQuestionnaireStatuses = [
  QuestionnaireStatus.SENT,
  QuestionnaireStatus.DECLINED,
  QuestionnaireStatus.IN_PROGRESS,
  QuestionnaireStatus.PENDING_REVIEW,
  QuestionnaireStatus.APPROVED,
  QuestionnaireStatus.REJECTED,
  QuestionnaireStatus.EXPIRED,
  QuestionnaireStatus.OBSOLETE,
];

const RuleRow = memo(({ templateOverview }: { templateOverview: QuestionnaireTemplateOverview }) => {
  const { t } = useTranslation();
  const [{ value: rules }] = useField<SupplierListQuestionnaireRule[]>('rules');

  const index = findIndex(rules, rule => rule.questionnaireTemplateId === templateOverview._id);
  const isSelected = index > -1;

  const questionnaireStatusItems = useMemo(
    () => orderedQuestionnaireStatuses.map(status => ({
      value: status,
      label: (
        <IconText
          icon={questionnaireStatusConfig[status].icon.value}
          isIconRegular={questionnaireStatusConfig[status].icon.isRegular}
          iconColor={questionnaireStatusConfig[status].icon.color}
          text={t(`questionnaireStatus.${status}`, { ns: 'preQualification' })}
        />
      ),
    })),
    [t],
  );

  const selectedStatuses = useMemo(() => {
    if (!isSelected) {
      return [];
    }

    return rules[index].statuses;
  }, [isSelected, rules, index]);

  return (
    <Flex width="100%" sx={{ gap: 2 }}>
      <Bold flex={isSelected ? '1 0 50%' : '1 1 auto'} fontSize={4} lineHeight="23px" py="12px">
        <Truncate>
          {templateOverview.name}
        </Truncate>
      </Bold>
      {isSelected && (
        <Stack
          flex="1 0 50%"
          gap={2}
          onClick={stopEvent}
          sx={{ cursor: 'default' }}
          py="12px"
        >
          <MultiSelectField
            small
            variant="secondary"
            name={`rules.${index}.statuses`}
            items={questionnaireStatusItems}
            itemToString={getItemValue}
            withRegularButton
            getButtonText={() => t('supplierLists.configFlow.selectStatuses')}
            menuZIndex={202}
            menuWidth={228}
            menuMaxHeightInItems={5.5}
            alwaysShowCaret
            errorMessageStyle={{ fontSize: '14px', marginTop: '8px' }}
          />
          {selectedStatuses.length > 0 ? (
            <QuestionnaireStatusesList statuses={selectedStatuses} />
          ) : (
            <Text fontWeight={400} fontSize={2}>
              {t('supplierLists.configFlow.noStatusesSelected')}
            </Text>
          )}
        </Stack>
      )}
    </Flex>
  );
});

const CheckboxLabel = styled(Text)`
  color: ${props => props.theme.colors.text};
  padding-left: ${props => props.theme.space[2]}px;
  flex: 1 1 auto;
`;

export const ListRulesStep = () => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const theme = useTheme();
  const { data } = useMultiStepFlowData<ListConfigFlowStepType, ListConfigFlowData>();
  const locale = useCurrentUserLocale();

  const { data: activeTemplates } = useQuery(
    ['activeQuestionnaireTemplates', { currentCompanyId }],
    wrap(api.getActiveQuestionnaireTemplates),
  );

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      rules: z.array(z.object({
        type: z.literal(SupplierListRuleType.QUESTIONNAIRE),
        questionnaireTemplateId: z.string(),
        questionnaireTemplateName: z.string(),
        statuses: z
          .array(z.nativeEnum(QuestionnaireStatus))
          .min(1, { message: t('supplierLists.configFlow.errors.requiredStatuses') }),
      })).min(1, { message: t('supplierLists.configFlow.errors.requiredRules') }),
    });

    return toFormikValidationSchema(Schema);
  }, [t]);

  const activeTemplateOptions = useMemo(() => {
    return map(
      localeSort(activeTemplates?.overviews || [], locale, templateOverview => templateOverview.name),
      templateOverview => ({
        value: {
          type: SupplierListRuleType.QUESTIONNAIRE,
          questionnaireTemplateId: templateOverview._id,
          questionnaireTemplateName: templateOverview.name,
          statuses: [],
        },
        label: <RuleRow templateOverview={templateOverview} />,
      }),
    );
  }, [activeTemplates, locale]);

  const activeTemplateById = useMemo(
    () => keyBy(activeTemplates?.overviews, templateOverview => templateOverview._id),
    [activeTemplates],
  );

  // Overwrite the generic error messages from the schema with more specific ones,
  // by giving the user more context about which rules are missing statuses.
  const transformErrors = useCallback(
    (values: FormValues) => (errors) => {
      if (isArray(errors.rules)) {
        const rulesWithoutStatuses = values.rules.filter(rule => isEmpty(rule.statuses));

        return {
          ...errors,
          rules: errors.rules.map((error, index) => {
            if (error?.statuses) {
              const templateOverview = activeTemplateById[rulesWithoutStatuses[index]?.questionnaireTemplateId];

              return templateOverview
                ? {
                  ...error,
                  statuses: t('supplierLists.configFlow.errors.requiredStatusesForQuestionnaire', { templateName: templateOverview?.name }),
                }
                : error;
            }

            return error;
          }),
        };
      }

      return errors;
    },
    [t, activeTemplateById],
  );

  return (
    <Formik<FormValues>
      validateOnBlur
      initialValues={{
        rules: data.rules,
      }}
      validationSchema={validationSchema}
      onSubmit={noop}
    >
      {({ values, errors }) => (
        <Form>
          <layout.ContentWrapper2>
            <StepNavigation2<ListConfigFlowStepType, ListConfigFlowData>
              showBackButton
              showContinueButton
            >
              <FormErrors transformErrors={transformErrors(values)} />
              {data._id && (
                <Flex maxWidth={layout.DEFAULT_SECTION_WIDTH}>
                  <Icon
                    flex="0 0 auto"
                    icon="info-circle"
                    fontSize={6}
                    color="primary"
                    mr={2}
                    mt="2px"
                  />
                  <Bold fontSize={4}>
                    {t('supplierLists.configFlow.adjustRulesInfo')}
                  </Bold>
                </Flex>
              )}
              <layout.Section2
                heading={data._id
                  ? t('supplierLists.configFlow.adjustRules')
                  : t('supplierLists.configFlow.setAutomaticListRules')}
              >
                <Stack gap="40px">
                  <Text>{t('supplierLists.configFlow.automaticRulesDescription')}</Text>
                  <layout.Subsection2 heading={t('supplierLists.configFlow.selectQuestionnairesAndStatuses')}>
                    <Stack gap={1} mb="20px">
                      <Text>{t('supplierLists.configFlow.questionnaireRulesDescription1')}</Text>
                      <Text>{t('supplierLists.configFlow.questionnaireRulesDescription2')}</Text>
                    </Stack>
                    <Box px={1}>
                      <CheckboxFieldArray
                        name="rules"
                        hideLabel
                        // We don't want to show the low-level errors here, only the high-level one
                        hideError={isArray(errors.rules)}
                        keyProperty="questionnaireTemplateId"
                        flexDirection="column"
                        options={activeTemplateOptions}
                        errorMessageStyle={{ fontSize: '14px', marginTop: '16px' }}
                        checkboxStyle={{ borderBottom: theme.borders.secondary, padding: 0 }}
                        styledCheckboxSx={{ position: 'relative', top: '16px' }}
                        CheckboxLabel={CheckboxLabel}
                      />
                    </Box>
                  </layout.Subsection2>
                </Stack>
              </layout.Section2>
            </StepNavigation2>
          </layout.ContentWrapper2>
        </Form>
      )}
    </Formik>
  );
};
