import { Trans } from 'react-i18next';
import { BoxProps } from 'rebass/styled-components';

import { InlineButton } from '@deepstream/ui-kit/elements/button/InlineButton';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useLiveContractNavigation } from '../../../appNavigation';

export const NoExchangePermissions = (styleBoxProps?: Omit<BoxProps, 'variant'>) => {
  const { navigateToTeam } = useLiveContractNavigation();

  return (
    <MessageBlock mt={0} variant="info" {...styleBoxProps}>
      <Trans i18nKey="permissions.noExchangeActionPermissions" ns="contracts">
        Your permissions for this contract do not allow you to perform some actions on this exchange.
        Permissions can be updated by a contract owner in the <InlineButton onClick={() => navigateToTeam()}>Team</InlineButton> tab
      </Trans>
    </MessageBlock>
  );
};
