import { CellProps } from 'react-table';
import { Flex } from 'rebass/styled-components';
import { Attachment } from '@deepstream/common/rfq-utils';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { ExchangeSnapshot } from '../../types';
import { useDownloadRfqAttachment } from '../../ExchangeModal/AttachmentLink';

export const RfqAttachmentsCell = ({
  cell: { value: attachments },
}: CellProps<ExchangeSnapshot, Attachment[]>) => {
  const [download, { isLoading }] = useDownloadRfqAttachment();

  return (
    <Stack gap={1}>
      {attachments.map((attachment) => (
        <Flex
          key={attachment._id}
          alignItems="center"
          sx={{ cursor: 'pointer', pointerEvents: isLoading ? 'none' : 'auto' }}
          onClick={event => {
            event.stopPropagation();
            download(attachment);
          }}
        >
          <IconText
            isIconRegular
            gap={3}
            mr={3}
            icon="file"
            text={attachment.name}
            truncate
          />
          <Icon icon="download" color="primary" />
        </Flex>
      ))}
    </Stack>
  );
};
