import { RequestInteractivityStatus } from '@deepstream/common/rfq-utils';

export const iconPropsByRequestInteractivityStatus = {
  [RequestInteractivityStatus.LIVE]: {
    icon: 'circle',
    color: 'success',
  },
  [RequestInteractivityStatus.PENDING]: {
    icon: 'circle-dashed',
    color: 'subtext',
  },
  [RequestInteractivityStatus.ENDED]: {
    icon: 'square',
    color: 'subtext',
  },
} as const;
