import { useMemo } from 'react';
import { get } from 'lodash';
import { EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { DefaultEditableGridStyles } from '@deepstream/ui-kit/grid/EditableGrid/EditableGridStyles';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { DataCellProps } from '@deepstream/ui-kit/grid/core/utils';
import { ReadOnlyGrid } from '@deepstream/ui-kit/grid/EditableGrid/ReadOnlyGrid';
import { getCellValue } from '@deepstream/ui-kit/grid/EditableGrid/getCellValue';
import { NonVirtualGrid } from '@deepstream/ui-kit/grid/core/NonVirtualGrid';
import { RenderTableDataCell } from '@deepstream/ui-kit/grid/EditableGrid/RenderTableDataCell';
import { useTranslation } from 'react-i18next';
import { Clamp } from '@deepstream/ui-kit/elements/text/Clamp';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { SimpleHeader } from './header';
import { ExchangeSnapshot } from '../../types';

const ClampOrDashCell = ({ row, column }: DataCellProps<EditableGridColumn, any, any>) => {
  const value = getCellValue(row.original, column.original);

  return (
    <div className="cell-content">
      <Clamp lines={2}>
        {value || <EmDash />}
      </Clamp>
    </div>
  );
};

export const AwardedMessageGrid = ({
  rows,
  onRowClick,
}: {
  rows: ExchangeSnapshot[];
  onRowClick;
}) => {
  const { t } = useTranslation('translation');
  const theme = useTheme();

  const columns: EditableGridColumn<ExchangeSnapshot>[] = useMemo(() => {
    return [
      {
        _id: 'subject',
        accessorKey: 'subject',
        accessorFn: exchange => get(exchange, ['def', 'name']),
        Header: SimpleHeader,
        label: t('general.subject'),
        width: '33%' as any,
        ValueCell: ClampOrDashCell,
      },
      {
        _id: 'message',
        accessorKey: 'message',
        accessorFn: exchange => exchange.history.find(action => action.comment)?.comment,
        Header: SimpleHeader,
        label: t('general.message'),
        width: '67%' as any,
        ValueCell: ClampOrDashCell,
      },
    ];
  }, [t]);

  return (
    <DefaultEditableGridStyles
      style={{ height: 'auto' }}
      isReadOnly
      cellContentCss="align-items: flex-start; padding: 10px"
      overrides={`[role=gridcell] { background-color: inherit } tbody > tr { background-color: white } tbody > tr:hover { background-color: ${theme.colors.lightPrimary4} }`}
      omitLastRowBorderBottom
    >
      <GridIdPrefixProvider>
        <ReadOnlyGrid
          columns={columns}
          rowData={rows as any}
          GridComponent={NonVirtualGrid}
          RenderDataCell={RenderTableDataCell}
          onRowClick={onRowClick}
        />
      </GridIdPrefixProvider>
    </DefaultEditableGridStyles>
  );
};
