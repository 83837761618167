import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { RfqStatus, StageType } from '@deepstream/common/rfq-utils';

import { SortingAccessor } from '@deepstream/common';
import { withProps } from '@deepstream/ui-utils/withProps';
import { BasicTableStyles } from './TableStyles';
import { Table } from './Table';
import { ExtendedSentRequestOverview } from './types';
import { RequestStatusFieldValue,
  DateFieldValue,
  NameArrayFieldValue,
  FieldsCell,
  MIN_CELL_HEIGHT,
  DeadlineFieldValue,
} from './FieldsCell';
import { SentRequestActionButtons } from './SentRequestActionButtons';
import { SentRequestStatus } from './SentRequestStatus';
import { useTableData } from './TableDataContext';
import { RequestsTableData } from './RequestsTableDataProvider';
import { useRequestsNavigation } from './RequestsNavigationContext';
import { getActiveStageId } from './rfx';

const dateFieldLabelTranslation = {
  [SortingAccessor.creationDate]: 'requests.created',
  [SortingAccessor.issueDate]: 'requests.issued',
  [SortingAccessor.finalDeadline]: 'requests.finalDeadline',
};

const dateFieldLabelAccessor = {
  [SortingAccessor.creationDate]: 'meta.createdAt',
  [SortingAccessor.issueDate]: 'sentDashboard.issueDate',
  [SortingAccessor.finalDeadline]: 'sentDashboard.bidDeadline',
};

export const SentRequestsTable = ({
  openDeleteModal,
  openTemplateFromRequestModal,
}: {
  openDeleteModal: (request: ExtendedSentRequestOverview) => void;
  openTemplateFromRequestModal: (request: ExtendedSentRequestOverview) => void;
}) => {
  const { t } = useTranslation(['translation', 'company']);
  const tableData = useTableData({ required: true });
  const { selectedItemsIds, selectItem, unselectItem } = useTableData<RequestsTableData<ExtendedSentRequestOverview>>({ required: true });
  const sortAccessor = get(tableData, ['sort', 'criteriaItems', 0, 'accessor']);

  const { navigateToSenderRequest } = useRequestsNavigation();
  const onRowClick = useCallback((row) => {
    const id = row._id;
    const isDraft = row.sentDashboard.status === RfqStatus.DRAFT;

    navigateToSenderRequest({ requestId: id, isDraft });
  }, [navigateToSenderRequest]);

  const columns = useMemo(
    () => [{ id: 'custom' }],
    [],
  );

  const CustomRowCells = useMemo(
    () => {
      const fields = [
        {
          label: t('general.status'),
          accessor: 'meta.extendedStatus',
          FieldValueComponent: RequestStatusFieldValue,
        },
        {
          label: t('requests.ref_short'),
          accessor: 'sentDashboard.autoReferenceNumber',
        },
        {
          label: t('activeStage', { ns: 'general' }),
          accessor: (request) => {
            const activeStage = getActiveStageId(request);
            if (activeStage) {
              const { index, name } = activeStage;
              return name ? `${index} – ${name}` : index;
            }
          },
          condition: (request) => Boolean(getActiveStageId(request)),
        },
        {
          label: t('requests.currentDeadline'),
          accessor: (request) => {
            const activeStage = getActiveStageId(request);
            if (activeStage) {
              const { type, completionDeadline } = activeStage;
              if (type === StageType.AUCTION) {
                const { auction } = request;
                return auction?.endDate;
              } else {
                return completionDeadline;
              }
            }
          },
          FieldValueComponent: DeadlineFieldValue,
          condition: (request) => Boolean(getActiveStageId(request)),
        },
        {
          label: t('yourRole', { ns: 'company' }),
          accessor: (request) => t(`requests.dashboardRole.${request.role}`),
        },
        {
          label: t('requests.owner_other'),
          singularLabel: t('request.team.requestRole.owner'),
          accessor: 'sentDashboard.ownerNamesOrEmails',
          FieldValueComponent: NameArrayFieldValue,
        },
        {
          label: t('requests.issuedBy'),
          accessor: 'sentDashboard.issuedBy',
        },
        {
          label: t(dateFieldLabelTranslation[sortAccessor]),
          accessor: dateFieldLabelAccessor[sortAccessor],
          FieldValueComponent: DateFieldValue,
        },
        {
          label: t('general.supplier_other'),
          accessor: 'sentDashboard.suppliersInRequest',
        },
      ];

      const selectField = {
        onSelect: (row) => {
          const request = row.original;
          if (selectedItemsIds?.includes(request._id)) {
            // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
            unselectItem(request._id);
          } else {
            // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
            selectItem(request._id);
          }
        },
        isSelected: (row) => selectedItemsIds?.includes(row.original._id),
      };

      return withProps(
        FieldsCell,
        {
          fields,
          titleAccessor: 'sentDashboard.subject',
          defaultTitle: t('requests.untitledRequest'),
          RowIconComponent: SentRequestStatus,
          actionColumnWidth: 30,
          ActionsComponent: withProps(
            SentRequestActionButtons,
            {
              openDeleteModal,
              openTemplateFromRequestModal,
            },
          ),
          ...selectField,
        },
      );
    },
    [t, sortAccessor, openDeleteModal, openTemplateFromRequestModal, selectedItemsIds, unselectItem, selectItem],
  );

  return (
    <BasicTableStyles hoverBackgroundColor="lightGray6">
      <Table
        columns={columns}
        isPaginated
        pageSizes={[5, 10, 20, 50, 100]}
        hideHeader
        CustomRowCells={CustomRowCells}
        hasStaticHeight={false}
        enforcePagination
        minCellHeight={MIN_CELL_HEIGHT}
        smallPageControls
        onRowClick={onRowClick}
      />
    </BasicTableStyles>
  );
};
