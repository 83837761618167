import { Form, Formik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import z from 'zod';
import { Box, Text } from 'rebass/styled-components';

import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { withProps } from '@deepstream/ui-utils/withProps';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';

import * as lotPagesLayout from '../Live/lotPagesLayout';
import { LabelConfig, LabelConfigProvider } from '../../../LabelConfigProvider';
import { RadioField } from '../../../form/RadioField';
import { TextField } from '../../../form/TextField';
import { FormErrors } from '../../../ui/MultiStepFlow/FormErrors';

const Label = withProps(Box, { as: 'span', color: 'text', fontWeight: 400 });

export const FivePointScaleFeedbackForm = ({
  titleText,
  questionText,
  onSubmit,
  isSuccess,
  isError,
  labels,
  rowGap,
}: {
  onSubmit: (options: {
    satisfactionRating: number;
    additionalComments: string;
  }) => void;
  isSuccess?: boolean;
  isError?: boolean;
  titleText: string;
  questionText: string;
  rowGap?: string | number;
  labels?: {
    additionalCommentsTitle?: string;
    additionalCommentsDescription?: string;
    noFeedbackSelectedError?: string;
    submitButton?: string;
  }
}) => {
  const { t } = useTranslation('request');
  const theme = useTheme();
  const options = [
    { value: '5', label: <Label>{t('requestFeedback.feedbackForm.question.options.verySatisfied')}</Label> },
    { value: '4', label: <Label>{t('requestFeedback.feedbackForm.question.options.satisfied')}</Label> },
    { value: '3', label: <Label>{t('requestFeedback.feedbackForm.question.options.neutral')}</Label> },
    { value: '2', label: <Label>{t('requestFeedback.feedbackForm.question.options.dissatisfied')}</Label> },
    { value: '1', label: <Label>{t('requestFeedback.feedbackForm.question.options.veryDissatisfied')}</Label> },
  ];

  const { additionalCommentsTitle, additionalCommentsDescription, noFeedbackSelectedError, submitButtonText } = useMemo(() => {
    // @ts-expect-error ts(2339) FIXME: Property 'additionalCommentsTitle' does not exist on type '{ additionalCommentsTitle?: string | undefined; additionalCommentsDescription?: string | undefined; noFeedbackSelectedError?: string | undefined; submitButton?: string | undefined; } | undefined'.
    const { additionalCommentsTitle, additionalCommentsDescription, noFeedbackSelectedError, submitButton } = labels;

    return {
      additionalCommentsTitle: additionalCommentsTitle ?? t('requestFeedback.feedbackForm.additionalComments.title'),
      additionalCommentsDescription,
      noFeedbackSelectedError: noFeedbackSelectedError ?? t(
        'requestFeedback.feedbackForm.errorsDescription',
      ),
      submitButtonText: submitButton ?? t('requestFeedback.feedbackForm.submit'),
    };
  }, [labels, t]);

  const transformErrors = useCallback(
    (errors) => {
      if (errors.satisfactionRating) {
        return {
          satisfactionRating: noFeedbackSelectedError,
        };
      } else {
        return errors;
      }
    },
    [noFeedbackSelectedError],
  );

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      satisfactionRating: z.string({ message: t('requestFeedback.feedbackForm.question.error') }),
      additionalComments: z.string().optional(),
    });

    return toFormikValidationSchema(Schema);
  }, [t]);

  return isSuccess ? (
    <lotPagesLayout.Section>
      <lotPagesLayout.H2Success>
        {t('requestFeedback.feedbackForm.success.title')}
      </lotPagesLayout.H2Success>
      <Text fontSize={2} mt="20px">
        {t('requestFeedback.feedbackForm.success.line1')}
      </Text>
      <Text fontSize={2} mt="3px">
        {t('requestFeedback.feedbackForm.success.line2')}
      </Text>
    </lotPagesLayout.Section>
  ) : (
    <Formik
      validateOnBlur
      initialValues={{
        satisfactionRating: undefined,
        additionalComments: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async ({ satisfactionRating, additionalComments }) =>
        onSubmit({
          satisfactionRating: Number(satisfactionRating),
          additionalComments,
        })
      }
    >
      {({ isSubmitting, isValid, submitCount }) => (
        <Form>
          <Box display="flex" flexDirection="column" sx={{ rowGap: rowGap ?? '40px' }}>
            <FormErrors transformErrors={transformErrors} />
            <lotPagesLayout.H2>{titleText}</lotPagesLayout.H2>
            <LabelConfigProvider variant={LabelConfig.ABOVE}>
              <RadioField
                name="satisfactionRating"
                label={questionText}
                fieldLabelStyle={{
                  fontSize: 4,
                  color: 'text',
                  marginBottom: '12px',
                }}
                options={options}
                gap={1}
                showError={submitCount > 0 && !isValid}
                errorPosition="top"
                errorMessageStyle={{ fontSize: '14px', marginBottom: '8px' }}
                required
              />
              <TextField
                name="additionalComments"
                label={additionalCommentsTitle}
                labelStyle={{ fontSize: 4, color: 'text', marginBottom: 2 }}
                isMultiLine
                {...(additionalCommentsDescription && {
                  description: additionalCommentsDescription,
                  descriptionStyle: {
                    fontSize: '14px!important',
                    marginBottom: 2,
                    color: `${theme.colors.text}!important`,
                  },
                })}
              />
              <Box>
                <Button
                  type="submit"
                  width="fit-content"
                  disabled={isSubmitting}
                >
                  {submitButtonText}
                </Button>
                {isError && (
                  <MessageBlock variant="error" mt="20px">
                    {t('requestFeedback.feedbackForm.errorCouldNotSubmit')}
                  </MessageBlock>
                )}
              </Box>
            </LabelConfigProvider>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
