import { useField } from 'formik';
import { noop } from 'lodash';
import { Box } from 'rebass/styled-components';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { ContainerLabelProps, FieldContainer } from './FieldContainer';
import { FilterMultiSelectCombobox, FilterMultiSelectComboboxProps } from '../ui/FilterMultiSelectCombobox';
import { ErrorMessage } from './Field';

interface ItemWithId {
  _id: string;
}

export type MultiSelectFinderFieldProps<T extends ItemWithId> = Pick<
  ContainerLabelProps,
  'name' | 'label' | 'hideLabel' | 'required'
> &
  Omit<
    FilterMultiSelectComboboxProps<T>,
    | 'onChange'
    | 'onTouched'
    | 'onFocus'
    | 'controlled'
    | 'labelId'
    | 'isDisabled'
    | 'getId'
    | 'selected'
  > & {
    fieldName?: string;
    showValidationError?: boolean;
    onSelect?: (item?: T[]) => void;
    disabledItems?: { _id: string }[];
  };

/**
 * Formik-aware field for select multiple items, with an included filter.
 *
 * If you want formik to also manage the input text, use the `inputFieldName` prop
 * to bind the input text to formik.
 */
export const MultiSelectFinderField = <T extends ItemWithId,>({
  name,
  fieldName,
  required,
  showValidationError = true,
  onSelect = noop,
  label,
  hideLabel,
  disabledItems,
  maxItems,
  ...props
} : MultiSelectFinderFieldProps<T>) => {
  const id = useUniqueId();

  const resolvedFieldName = fieldName || name;

  if (!fieldName && !name) {
    throw new Error('`fieldName` is required if no `name` prop included');
  }

  const [itemsField, meta, formik] = useField({ name: resolvedFieldName! });

  return (
    <FieldContainer
      htmlFor={id}
      name={name}
      label={label}
      hideLabel={hideLabel}
      showAsterisk={required}
      width="100%"
    >
      <FilterMultiSelectCombobox<T>
        onChange={(selected) => {
          onSelect(selected);
          formik.setValue(selected);
        }}
        onTouched={() => {
          formik.setTouched(true);
        }}
        onFocus={() => {
          // When the input is focused and the selected item is cleared, the `inputError`
          // will be set. To avoid this, we mark the field as untouched.
          if (itemsField.value) {
            formik.setTouched(false);
          }
        }}
        controlled
        labelId={id}
        isDisabled={(item) =>
            !!disabledItems?.find(
              (disabled) => disabled?._id === item?._id,
            )
          }
        getId={(item: T) => item._id}
        selected={itemsField.value ?? undefined}
        maxItems={maxItems ?? 4}
        {...props}
      />
      {showValidationError && meta.touched && meta.error && (
        <Box sx={{ wordBreak: 'break-word', textAlign: 'left' }}>
          <ErrorMessage error={meta.error} fontWeight="normal" />
        </Box>
      )}
    </FieldContainer>
  );
};
