import { useState, useMemo, useEffect } from 'react';
import { Flex, FlexProps } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';

import { useInvalidateQueryOnMessage } from '../useInvalidateQueryOnMessage';
import { Disclosure } from '../ui/Disclosure';
import { DraftStage } from './DraftStage';
import { RfqIdProvider, useDraftRfqStructure, useRfqId } from '../useRfq';
import { LoadingPanel } from '../ui/Loading';
import { ErrorPanel } from '../ui/ErrorMessage';
import { CurrentDraftProvider, CurrentDraft } from './useCurrentDraft';
import { useCurrentCompanyId } from '../currentCompanyId';
import * as rfx from '../rfx';
import { DraftToolbar } from '../modules/Request/DraftToolbar';

const DraftStagesToolbar = () => {
  const rfqId = useRfqId();
  const { editingPanelId, isTemplate, isRevising } = rfx.useState();
  const isSuperUserOrOwner = rfx.useIsSuperUserOrOwner();
  const isEditing = Boolean(editingPanelId);

  if (isEditing) {
    return null;
  }

  return (
    <DraftToolbar
      rfqId={rfqId}
      tabId="stages"
      hasUnsavedChanges={false}
      showSubmitButton={false}
      isSuperUserOrOwner={isSuperUserOrOwner}
      isTemplate={isTemplate}
      isRevising={isRevising}
      isRequestValid={true}
      excessSupplierCount={0}
    />
  );
};

export const DraftStages = ({
  rfqId,
  isRevising,
  isTemplate,
  onEditingStageIdChange,
  showDraftingToolbar = true,
  showDisclosure = true,
  canAddAuctionStage = true,
  showStageNameValidation = false,
  ...props
}: {
  rfqId: string;
  isRevising?: boolean;
  isTemplate?: boolean;
  showDraftingToolbar?: boolean;
  showDisclosure?: boolean;
  showStageNameValidation?: boolean;
  canAddAuctionStage?: boolean;
  onEditingStageIdChange?: (editingStageId: string | null) => void;
} & FlexProps) => {
  const { t } = useTranslation('translation');
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const [editingStageId, setEditingStageId] = useState<string | null>(null);
  const [mutationPending, setMutationPending] = useState(false);

  const { data: rfxStructure, isLoading, isError, isSuccess, queryKey } = useDraftRfqStructure({
    rfqId,
    currentCompanyId,
    isTemplate,
  });

  useInvalidateQueryOnMessage(`rfx.${rfqId}`, queryKey);

  const currentDraft: CurrentDraft = useMemo(
    () => ({
      rfqId,
      isTemplate,
      isRevising,
      mutationPending,
      setMutationPending,
    }),
    [rfqId, isTemplate, isRevising, mutationPending],
  );

  useEffect(() => {
    if (onEditingStageIdChange) {
      onEditingStageIdChange(editingStageId);
    }
  }, [editingStageId, onEditingStageIdChange]);

  return (
    <RfqIdProvider rfqId={rfqId}>
      <rfx.StateProvider
        isTemplate={isTemplate}
        isRevising={isRevising}
      >
        {/* TODO: remove CurrentDraftProvider */}
        <CurrentDraftProvider currentDraft={currentDraft}>
          <Flex flexDirection="column" alignItems="center" maxWidth="624px" {...props}>
            {isLoading ? (
              <LoadingPanel style={{ width: '100%' }} />
            ) : isError ? (
              <ErrorPanel
                error={isTemplate ? t('template.errors.getTemplate') : t('request.errors.getRequest')}
                style={{ width: '100%' }}
              />
            ) : isSuccess && rfxStructure ? (
              <rfx.StructureProvider structure={rfxStructure}>
                <>
                  {rfxStructure.stages.map((stage, stageIndex) => (
                    <DraftStage
                      key={stage._id}
                      stage={stage}
                      stageIndex={stageIndex}
                      editingStageId={editingStageId}
                      setEditingStageId={setEditingStageId}
                      canAddAuctionStage={canAddAuctionStage}
                      showStageNameValidation={showStageNameValidation}
                    />
                  ))}
                  {showDraftingToolbar && <DraftStagesToolbar />}
                </>
              </rfx.StructureProvider>
            ) : (
              null
            )}
            {showDisclosure && (
              <Disclosure
                summary={t('request.stages.disclosure.summary')}
                mt={20}
                sx={{ textAlign: 'center' }}
              >
                {t('request.stages.disclosure.content')}
              </Disclosure>
            )}
          </Flex>
        </CurrentDraftProvider>
      </rfx.StateProvider>
    </RfqIdProvider>
  );
};
