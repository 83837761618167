import * as Sentry from '@sentry/react';
import { QuestionExchangeDefinition, QuestionType } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { UnexpectedStateError } from '@deepstream/errors';
import { FieldContainer } from '../../../form/FieldContainer';
import { DisabledLongTextField } from './DisabledLongTextField';
import { DisabledShortTextField } from './DisabledShortTextField';
import { DisabledOptionsField } from './DisabledOptionsField';
import { DisabledDateTimeField } from './DisabledDateTimeField';
import { DisabledAddressFields } from './DisabledAddressFields';
import { DisabledDocumentField } from './DisabledDocumentField';
import { DisabledGridField } from './DisabledGridField';
import { DisabledYesNoField } from './DisabledYesNoField';

const QUESTION_TYPE_MAP = {
  [QuestionType.SHORT_TEXT]: DisabledShortTextField,
  [QuestionType.LONG_TEXT]: DisabledLongTextField,
  [QuestionType.CHECKBOXES]: DisabledOptionsField,
  [QuestionType.MULTIPLE_CHOICE]: DisabledOptionsField,
  [QuestionType.ADDRESS]: DisabledAddressFields,
  [QuestionType.DATE_TIME]: DisabledDateTimeField,
  [QuestionType.DOCUMENT]: DisabledDocumentField,
  [QuestionType.GRID]: DisabledGridField,
  [QuestionType.YES_NO]: DisabledYesNoField,
} as const;

export const QuestionFieldPreview = ({
  exchangeDef,
}: {
  exchangeDef: QuestionExchangeDefinition;
}) => {
  const { t } = useTranslation();
  const { questionType } = exchangeDef;

  if (!QUESTION_TYPE_MAP[questionType]) {
    Sentry.captureException(new UnexpectedStateError(`[QuestionFieldPreview] No question preview available for question type ${questionType}`, { questionType }));
    return null;
  }

  const Component = QUESTION_TYPE_MAP[questionType];

  const isGridQuestion = questionType === QuestionType.GRID;
  const fieldMaxWidth = isGridQuestion ? '85%' : '440px';

  return (
    <FieldContainer label={!isGridQuestion ? t('request.question.supplierResponse') : undefined} showAsterisk>
      <Component exchangeDef={exchangeDef} fieldMaxWidth={fieldMaxWidth} />
    </FieldContainer>
  );
};
