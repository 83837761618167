import { useMemo, useCallback } from 'react';
import { Lock, LockType } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { Flex, Box } from 'rebass/styled-components';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { every, filter, map, values } from 'lodash';
import { Button, CancelButton, SaveButton } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { DoneButton, Modal, ModalBody, ModalFooter, ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import * as rfx from './rfx';
import { LockStatus, LockPermission } from './types';
import { LockTableField } from './LockTableField';
import { isTeamMemberLockType, LockTypeField } from './LockTypeField';

type SenderLockConfig = {
  _id: string;
  name: string;
  role: string;
  status: LockStatus;
  permission: LockPermission;
};

const getLock = ({
  lockType,
  sendersLockConfig,
}: {
  lockType: LockType;
  sendersLockConfig: SenderLockConfig[];
}): Lock => {
  const hasCollaborators = sendersLockConfig.length > 1;

  const keys = isTeamMemberLockType(lockType)
    ? hasCollaborators
      ? map(filter(sendersLockConfig, { permission: LockPermission.CAN_UNLOCK }), '_id')
      : [sendersLockConfig[0]._id]
    : [];

  const bypass = map(
    filter(sendersLockConfig, { status: LockStatus.UNLOCKED }),
    '_id',
  );

  return {
    type: lockType,
    keys,
    bypass,
  };
};

export const LockModal = ({
  isOpen,
  close,
  lock,
  isReadOnly,
  onSubmit,
}: {
  isOpen: boolean;
  close: () => void;
  lock?: Lock;
  isReadOnly?: boolean;
  onSubmit: (lock: Lock | null) => void;
}) => {
  const { t } = useTranslation();
  const senders = rfx.useSenders();
  const hasCollaborators = senders.length > 1;

  const initialValues = useMemo(
    () => lock ? ({
      lockType: lock.type,
      sendersLockConfig: map(
        senders,
        sender => ({
          _id: sender._id,
          name: sender.company.name,
          role: sender.company.role,
          status: lock.bypass?.includes(sender._id)
            ? LockStatus.UNLOCKED
            : LockStatus.LOCKED,
          permission: lock.keys?.includes(sender._id)
            ? LockPermission.CAN_UNLOCK
            : LockPermission.CANNOT_UNLOCK,
        } as SenderLockConfig),
      ),
    }) : ({
      lockType: LockType.STAGE_DEADLINE,
      sendersLockConfig: map(
        senders,
        sender => ({
          _id: sender._id,
          name: sender.company.name,
          role: sender.company.role,
          status: LockStatus.LOCKED,
          permission: hasCollaborators
            ? LockPermission.CANNOT_UNLOCK
            : LockPermission.CAN_UNLOCK,
        } as SenderLockConfig),
      ),
    }),
    [lock, senders, hasCollaborators],
  );

  const validateForm = useCallback(
    values => {
      const errors: any = {};
      const { lockType, sendersLockConfig } = values;
      const hasCollaborators = sendersLockConfig.length > 1;

      const isUnlockedForAllSenders = every(
        sendersLockConfig,
        lockConfig => lockConfig.status === LockStatus.UNLOCKED,
      );

      const nobodyCanUnlock = isTeamMemberLockType(lockType) && every(
        sendersLockConfig,
        lockConfig => lockConfig.permission === LockPermission.CANNOT_UNLOCK,
      );

      if (isUnlockedForAllSenders) {
        errors.status = t('request.lock.errors.mustBeLocked');
      }

      // For requests with no collaborators the correct `keys` will be set on save
      if (hasCollaborators && nobodyCanUnlock) {
        errors.permission = t('request.lock.errors.mustBeAbleToUnlock');
      }

      return errors;
    },
    [t],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      shouldCloseOnEsc
      style={{ content: { maxWidth: '656px', minWidth: 'min(656px, 100vw)' } }}
    >
      <Formik
        initialValues={initialValues}
        validateOnBlur
        validationSchema={
          yup.object().shape({
            lockType: yup.string().oneOf(values(LockType)),
            sendersLockConfig: yup.array(
              yup
                .object()
                .shape({
                  _id: yup.string(),
                  name: yup.string(),
                  role: yup.string(),
                  status: yup.string().oneOf(values(LockStatus)),
                  permission: yup.string().oneOf(values(LockPermission)),
                }),
            ),
          })
        }
        validate={validateForm}
        onSubmit={values => {
          onSubmit(getLock(values));
          close();
        }}
      >
        {({ isValid }) => (
          <Form>
            <ModalHeader onClose={close}>
              {isReadOnly ? (
                t('request.lock.viewLockCriteria')
              ) : (
                t('request.lock.setLockCriteria')
              )}
            </ModalHeader>
            <ModalBody>
              <Box mb={3}>
                <LockTypeField
                  name="lockType"
                  disabled={isReadOnly}
                />
              </Box>
              {hasCollaborators && (
                <LockTableField
                  name="sendersLockConfig"
                  lockTypeName="lockType"
                  disabled={isReadOnly}
                />
              )}
              {isReadOnly ? (
                <MessageBlock variant="info" mt={3} mb={2}>
                  {t('request.lock.cannotChangeReadOnly')}
                </MessageBlock>
              ) : (
                <MessageBlock variant="warn" mt={3} mb={2}>
                  {t('request.lock.cannotChange')}
                </MessageBlock>
              )}
            </ModalBody>
            <ModalFooter justifyContent={isReadOnly ? 'flex-end' : 'space-between'}>
              {isReadOnly ? (
                <DoneButton onClick={close} />
              ) : (
                <>
                  <Button
                    type="button"
                    variant="secondary"
                    disabled={!lock}
                    onClick={() => {
                      onSubmit(null);
                      close();
                    }}
                  >
                    {t('request.lock.removeLock')}
                  </Button>
                  <Flex>
                    <CancelButton onClick={close} mr={2} />
                    <SaveButton disabled={!isValid} />
                  </Flex>
                </>
              )}
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
