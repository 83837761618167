import { useState, useEffect } from 'react';
import { ButtonProps } from 'rebass/styled-components';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';

export const ScrollToBottomHintButton = (props: ButtonProps) => {
  return <IconButton {...props} icon="arrow-down" color="white" backgroundColor="primary" px={2} py={1} />;
};

export const useScrollToBottomHint = () => {
  const [element, setElement] = useState<HTMLDivElement>();
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  useEffect(() => {
    const canScroll = (element: HTMLDivElement) => {
      if (element) {
        const { scrollTop, clientHeight, scrollHeight } = element;
        return Math.ceil(scrollTop + clientHeight) < Math.ceil(scrollHeight);
      }
      return false;
    };

    const checkScrollPosition = () => {
      // @ts-expect-error ts(2345) FIXME: Argument of type 'HTMLDivElement | undefined' is not assignable to parameter of type 'HTMLDivElement'.
      setIsScrolledToBottom(!canScroll(element));
    };

    if (element) {
      checkScrollPosition();
      element.addEventListener('scroll', checkScrollPosition);
      return () => element.removeEventListener('scroll', checkScrollPosition);
    }
  }, [element]);

  const scrollToBottom = () => {
    if (element) {
      element.scrollTo({
        top: element.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  return {
    setElement,
    showHint: !isScrolledToBottom,
    scrollToBottom,
  };
};
