import { TemplatePreviewHeader } from './TemplatePreviewHeader';
import { useRequestEditNavigation } from '../../appNavigation';
import { PreviewControls } from './Review/PreviewControls';
import { ReviewTab } from './Review/ReviewTab';
import { DraftHeader } from './DraftHeader';
import * as Layout from '../../Layout';
import { RequestHooksProvider } from './RequestHooksProvider';

export const DraftReviewPageContent = ({
  rfqId,
  isRevising,
  isTemplate,
  isTemplatePreview,
}: {
  rfqId: string;
  isRevising?: boolean;
  isTemplate?: boolean;
  isTemplatePreview?: boolean;
}) => {
  const tabId = 'review';

  const {
    navigateToTeam,
    navigateToStages,
    navigateToSummary,
    navigateToSuppliers,
  } = useRequestEditNavigation();

  return (
    <Layout.HeaderAndContent
      header={
        isTemplatePreview ? (
          <TemplatePreviewHeader rfqId={rfqId} />
        ) : (
          <DraftHeader
            rfqId={rfqId}
            isRevising={isRevising}
            isTemplate={isTemplate}
            selectedTabId={tabId}
          />
        )
      }
      content={
        <>
          {isTemplatePreview && (
            <PreviewControls templateId={rfqId} />
          )}
          <RequestHooksProvider>
            <ReviewTab
              rfqId={rfqId}
              isRevising={isRevising}
              isTemplate={isTemplate}
              isTemplatePreview={isTemplatePreview}
              onClickTeamEdit={navigateToTeam}
              onClickStagesEdit={navigateToStages}
              onClickSummaryEdit={navigateToSummary}
              onClickSuppliersEdit={navigateToSuppliers}
            />
          </RequestHooksProvider>
        </>
      }
      contentPaddingBottom={Layout.BOTTOM_TOOLBAR_CONTENT_PADDING_BOTTOM}
    />
  );
};
