import * as React from 'react';
import { ContextType, HooksContext } from '../../../useHooks';
import { useExchangeDefById, useQuestionnaireTemplateId, useSummary, useQuestionnaireTemplateActions, useStatus } from './questionnaireTemplateUtils';

export const QuestionnaireTemplateHooksProvider = ({ children }: { children: React.ReactNode }) => {
  const contextValue = React.useMemo(
    () => ({
      contextType: ContextType.QUESTIONNAIRE_TEMPLATE,
      useStatus,
      useContextId: useQuestionnaireTemplateId,
      useSummary,
      useExchangeDefById,
      useExchanges: () => [],
      useShowValidationErrors: () => false,
      useSection: () => null,
      useAuction: () => null,
      usePagePermissions: () => null,
      useBidProgress: () => null,
      useCommentNotifications: () => [],
      useRecipients: () => null,
      useAvailableBulletins: () => [],
      useUpdateBulletin: () => null,
      useActions: useQuestionnaireTemplateActions,
      useESign: () => null,
      useIsSuperUserOrOwner: () => false,
    }),
    [],
  );

  return (
    // @ts-expect-error ts(2322) FIXME: Type '{ contextType: ContextType; useStatus: () => QuestionnaireTemplateStatus; useContextId: { (a: { required: true; }): string; (): string; (a: { required: false; }): string | null; }; ... 14 more ...; useIsSuperUserOrOwner: () => false; }' is not assignable to type 'Hooks'.
    <HooksContext.Provider value={contextValue}>
      {children}
    </HooksContext.Provider>
  );
};
