import { useTranslation } from 'react-i18next';
import { Text, Box } from 'rebass/styled-components';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { useHoursPassed } from './useExternalSystems';

export const ExternalSupplierStatusHeader = ({ externalSystemName, externalStatusesUpdatedAt }) => {
  const { t } = useTranslation('integration');
  const hoursPassed = useHoursPassed({ externalStatusesUpdatedAt });

  return (
    <Box alignItems="center" sx={{ mr: 2, display: 'inline-flex' }}>
      <Text>{t('status', { ns: 'general' })} ({externalSystemName})</Text>
      <Tooltip content={t('externalStatus.statusHeaderTooltip', { externalSystemName, hoursPassed })}>
        <Icon icon="question-circle-o" ml={2} fixedWidth fontSize={1} />
      </Tooltip>
    </Box>
  );
};
