import React, { useCallback, useState } from 'react';
import { DsError } from '@deepstream/errors';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { scrollToTop } from '@deepstream/ui-utils/scrollToTop';
import { identity, isEqual } from 'lodash';
import { LeaveFlowModal, LeaveFlowModalProps } from './LeaveFlowModal';
import { Direction, FlowStep } from './types';
import { MultiStepFlowProvider } from './MultiStepFlowContext';

export const MultiStepFlow = <
  TStepType extends string,
  TStep extends { type: TStepType },
  TData extends FlowStep<TStep>,
>({
  initialData,
  stepComponentByType,
  context,
  canLeaveFlow,
  getAllSteps,
  sanitizeData = identity,
  leaveFlowModalProps,
}: {
  initialData: TData;
  stepComponentByType: Record<TStepType, any>;
  context?: Record<string, any>;
  canLeaveFlow: (data: TData) => boolean;
  getAllSteps: (data: TData) => TStep[];
  sanitizeData?: (newData: TData, previousData: TData, context: Record<string, any>) => TData;
  leaveFlowModalProps?: LeaveFlowModalProps;
}) => {
  const [data, setData] = useState<TData>(initialData);

  useWatchValue(
    data.currentStep,
    () => {
      scrollToTop('smooth');
    },
  );

  const StepComponent = stepComponentByType[data.currentStep.type];

  const submitAndNavigate = useCallback((
    newData: Partial<TData> | null,
    direction: Direction | null,
    targetStep?: TStepType,
  ) => {
    setData(previousData => {
      const updatedData: TData = newData
        // @ts-expect-error ts(2345) FIXME: Argument of type 'Record<string, any> | undefined' is not assignable to parameter of type 'Record<string, any>'.
        ? sanitizeData({ ...previousData, ...newData }, previousData, context)
        : previousData;

      const newSteps = getAllSteps(updatedData);
      const currentStepIndex = newSteps.findIndex(step => isEqual(step, previousData.currentStep));

      const newCurrentStep = direction
        ? (
          direction === Direction.BACK
            ? newSteps[currentStepIndex - 1]
            : newSteps[currentStepIndex + 1]
        )
        : targetStep;

      if (!newCurrentStep) {
        const stepInfo = {
          previousSteps: previousData.steps,
          newSteps,
          previousStep: previousData.currentStep,
          direction,
        };
        throw new DsError('Step does not exist', stepInfo);
      }

      return {
        ...updatedData,
        steps: newSteps,
        currentStep: newCurrentStep,
      };
    });
  }, [context, getAllSteps, sanitizeData]);

  return (
    <MultiStepFlowProvider data={data} submitAndNavigate={submitAndNavigate}>
      <StepComponent
        // The key is required to make sure we don't keep form state
        // when switching between subsequent steps that render the
        // same component (like split line award steps)
        key={JSON.stringify(data.currentStep)}
      />
      {!canLeaveFlow(data) && (
        <LeaveFlowModal {...leaveFlowModalProps} />
      )}
    </MultiStepFlowProvider>
  );
};
