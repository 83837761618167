import { isEmpty } from 'lodash';
import { useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RfxAuctionLineItemsSection } from '@deepstream/common/rfq-utils';
import { Flex } from 'rebass/styled-components';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { AuctionComparisonControlsPanel } from './AuctionComparisonControlsPanel';
import { AuctionLineItemsComparisonGrid, useRowData } from './AuctionLineItemsComparisonGrid';
import * as rfx from '../../../rfx';
import { EmptyTableMessage } from '../../../ui/EmptyTableMessage';
import { EmptyGridPanel } from '../Comparison/EmptyGridPanel';
import { useRfqId } from '../../../useRfq';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { rowHeightMeasurements } from '../Comparison/rowHeight';
import { useSelectRowHeight } from '../Comparison/useSelectRowHeight';
import { useCurrentUser } from '../../../useCurrentUser';
import { useLocalStorageFilterProps } from '../../../filtering';
import { renderName } from '../../../RequestsTable';

export const useSupplierFilterItems = () => {
  const recipientsWithAuctionAccess = rfx.useRecipients();

  return useMemo(() => {
    return recipientsWithAuctionAccess.map(recipient => ({
      _id: recipient._id,
      name: recipient.company.name,
    }));
  }, [recipientsWithAuctionAccess]);
};

const AllLineItemsObsoletePanel = () => {
  const { t } = useTranslation();

  return (
    <EmptyGridPanel
      sx={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      <PanelPadding>
        <EmptyTableMessage
          header={t('request.comparison.noLineItemsToShow')}
          body={t('request.comparison.allLineItemsObsolete')}
        />
      </PanelPadding>
    </EmptyGridPanel>
  );
};

interface Props {
  onlyObsoleteExchangeDefs?: boolean;
  isExpandedView: boolean;
  setIsExpandedView: (isExpandedView: boolean) => void;
}

export const AuctionLineItemsComparison = ({
  onlyObsoleteExchangeDefs,
  isExpandedView,
  setIsExpandedView,
}: Props) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const rfqId = useRfqId();
  const structure = rfx.useStructure();
  const recipientsWithAuctionAccess = rfx.useRecipients();
  const section = rfx.useSection() as RfxAuctionLineItemsSection;
  const rowData = useRowData(section, structure, onlyObsoleteExchangeDefs);

  const supplierFilterItems = useSupplierFilterItems();
  const supplierFilter = useLocalStorageFilterProps({
    storageKey: `${currentCompanyId}.${currentUser._id}.${rfqId}.${section._id}.suppliers`,
    items: supplierFilterItems,
    idProp: '_id',
    renderItem: renderName,
  });

  const selectedSuppliers = useMemo(() => {
    if (isEmpty(supplierFilter.selectedItems)) {
      return recipientsWithAuctionAccess;
    } else {
      const selectedIds = supplierFilter.selectedItems.map(item => item._id);
      return recipientsWithAuctionAccess.filter(recipient => selectedIds.includes(recipient._id));
    }
  }, [recipientsWithAuctionAccess, supplierFilter]);

  const selectRowHeight = useSelectRowHeight({
    storageKey: `${currentCompanyId}.${currentUser._id}.${rfqId}.${section._id}.rowHeight`,
  });

  const subRowHeight = rowHeightMeasurements[selectRowHeight.selectedItems[0].value];

  const gridRef = useRef<any>();
  useEffect(() => {
    gridRef.current?.resetAfterRowIndex(0);
    gridRef.current?.resetAfterColumnIndex(0);
  }, [structure]);

  return (
    <Flex
      flexDirection="column"
      sx={isExpandedView ? ({
        padding: '20px',
        inset: 0,
        zIndex: 150,
        position: 'fixed',
        backgroundColor: 'lightGray3',
      }) : ({
        height: '400px',
      })}
    >
      <AuctionComparisonControlsPanel
        supplierFilter={supplierFilter}
        selectRowHeight={selectRowHeight}
        isExpandedView={isExpandedView}
        setIsExpandedView={setIsExpandedView}
        disabled={isEmpty(recipientsWithAuctionAccess) || isEmpty(rowData)}
        onlyObsoleteExchangeDefs={onlyObsoleteExchangeDefs}
      />
      {isEmpty(rowData) ? (
        <AllLineItemsObsoletePanel />
      ) : (
        <AuctionLineItemsComparisonGrid
          gridRef={gridRef}
          columnData={selectedSuppliers}
          rowData={rowData}
          subRowHeight={subRowHeight}
          onlyObsoleteExchangeDefs={onlyObsoleteExchangeDefs}
        />
      )}
    </Flex>
  );
};
