import { useMemo } from 'react';
import { isEqual, omit } from 'lodash';
import { Flex } from 'rebass/styled-components';
import { Draft, ExchangeDefinition, ExchangeType } from '@deepstream/common/rfq-utils';
import {
  DeleteMenuItem,
  DiscardChangesMenuItem,
  EllipsisMenu,
  MakeNonObsoleteMenuItem,
  MakeObsoleteMenuItem,
  MoveUpMenuItem,
  MoveDownMenuItem,
  DuplicateMenuItem,
} from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { useExchangeDefsField } from '../../draft/exchangeDefs';
import { useContractData } from './contract';

const compareLiveVersionIgnoreKeys = [
  'isLive', 'isObsolete', 'liveVersion', '_id', 'type', 'user', 'sectionId', 'publishedAt', 'publisherId', 'creatorId',
];

/**
 * Renders a dropdown menu with the actions for each exchange definition
 */
export const ContractExchangeDefActions = ({
  index,
  fieldName = 'exchangeDefs',
  allowObsoleteStateChange = true,
  onlyAllowRemoval,
}: {
  index: number;
  fieldName: string;
  allowObsoleteStateChange?: boolean;
  onlyAllowRemoval?: boolean;
}) => {
  const contract = useContractData();
  const {
    exchangeDefs,
    moveExchangeDef,
    removeExchangeDef,
    setExchangeDefIsObsolete,
    resetToLiveVersion,
    duplicateExchangeDef,
  } = useExchangeDefsField(fieldName);

  const { exchangeDefById } = contract;
  const draftExchangeDef = exchangeDefs[index] as Partial<ExchangeDefinition<Draft>> & { _id: string };
  const exchangeId = draftExchangeDef._id;
  const exchangeDef = exchangeDefById[exchangeId];

  const hasStagedChanges = useMemo(() => {
    if (
      !exchangeDef ||
      !exchangeDef.isLive ||
      !exchangeDef.liveVersion ||
      exchangeDef.isObsolete
    ) {
      return false;
    }

    return !isEqual(
      omit(draftExchangeDef, compareLiveVersionIgnoreKeys),
      omit(exchangeDef.liveVersion, compareLiveVersionIgnoreKeys),
    );
  }, [draftExchangeDef, exchangeDef]);

  const isLatestContractExchange = (
    draftExchangeDef.type === ExchangeType.CONTRACT &&
    index === exchangeDefs.length - 1
  );

  const isEmpty = draftExchangeDef.type === ExchangeType.CONTRACT && exchangeDef?.isLive && (
    (!allowObsoleteStateChange || exchangeDef?.liveVersion?.isObsolete) ||
    (!draftExchangeDef.isObsolete && isLatestContractExchange && !hasStagedChanges)
  );

  return (
    <Flex height="40px" width="40px" justifyContent="flex-end" alignItems="center">
      <EllipsisMenu
        variant="secondary-outline"
        menuZIndex={202}
        width="40px"
        data-test="more-exchange-actions-button"
        popoverProps={{
          'data-test': 'more-exchange-actions-menu',
        }}
        disabled={isEmpty}
      >
        {onlyAllowRemoval ? (
          <DeleteMenuItem onSelect={() => removeExchangeDef(index)} />
        ) : (
          <>
            {/*
             // @ts-expect-error ts(2345) FIXME: Argument of type 'ExchangeType | undefined' is not assignable to parameter of type 'ExchangeType'. */}
            {![ExchangeType.CONTRACT, ExchangeType.LEGACY_CONTRACT].includes(draftExchangeDef.type) && (
              <>
                <MoveUpMenuItem
                  onSelect={() => moveExchangeDef(index, -1)}
                  disabled={index === 0}
                />
                <MoveDownMenuItem
                  onSelect={() => moveExchangeDef(index, +1)}
                  disabled={index === exchangeDefs.length - 1}
                />
                {!exchangeDef?.isObsolete ? (
                  <DuplicateMenuItem
                    onSelect={() => duplicateExchangeDef(index)}
                    data-test="duplicate-exchange-button"
                  />
                ) : (
                  null
                )}
              </>
            )}
            {exchangeDef?.isLive ? (
              (!allowObsoleteStateChange || exchangeDef.liveVersion?.isObsolete) ? (
                null // if the live exchange def is obsolete, we can't change the state any further
              ) : draftExchangeDef.isObsolete ? (
                <MakeNonObsoleteMenuItem
                  onSelect={() => setExchangeDefIsObsolete(exchangeId, false)}
                />
              ) : (
                <>
                  {!isLatestContractExchange && ( // The latest contract exchange cannot be marked as obsolete.
                    <MakeObsoleteMenuItem
                      disabled={hasStagedChanges}
                      onSelect={() => setExchangeDefIsObsolete(exchangeId, true)}
                    />
                  )}
                  {hasStagedChanges && (
                    <DiscardChangesMenuItem
                      onSelect={() => resetToLiveVersion(exchangeDef.liveVersion!)}
                    />
                  )}
                </>
              )
            ) : (
              <DeleteMenuItem onSelect={() => removeExchangeDef(index)} />
            )}
          </>
        )}
      </EllipsisMenu>
    </Flex>
  );
};
