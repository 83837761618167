import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { ConfirmActionDialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { useConfirmDialog } from '../../../ui/useModalState';
import { useSubmitQuestionnaire } from './questionnaireMutations';
import { useQuestionnaireData } from './questionnaireUtils';

export const SubmitQuestionnaireButton = (props: ButtonProps) => {
  const { t } = useTranslation(['preQualification', 'general']);
  const questionnaire = useQuestionnaireData();
  const { confirm: confirmSubmit, ...confirmSubmitDialogProps } = useConfirmDialog();

  const [submitQuestionnaire] = useSubmitQuestionnaire();

  const onSubmit = useCallback(
    () => confirmSubmit(submitQuestionnaire),
    [submitQuestionnaire, confirmSubmit],
  );

  const isQuestionnaireComplete = questionnaire.numUnresolvedRequirements === 0;

  return (
    <>
      <Tooltip content={!isQuestionnaireComplete ? t('questionnaire.submitDisabledTooltip') : null}>
        <span>
          <Button
            type="button"
            variant="success"
            disabled={!isQuestionnaireComplete}
            onClick={onSubmit}
            {...props}
          >
            {t('submit', { ns: 'general' })}
          </Button>
        </span>
      </Tooltip>
      <ConfirmActionDialog
        heading={t('confirmation', { ns: 'general' })}
        variant="info"
        message={t('questionnaire.confirmSubmitMessage')}
        okButtonText={t('submit', { ns: 'general' })}
        okButtonVariant="success"
        {...confirmSubmitDialogProps}
      />
    </>
  );
};
