import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { find, values } from 'lodash';
import { CompanyMinimized, Draft, SectionType } from '@deepstream/common/rfq-utils';
import * as rfx from '../../rfx';
import { ErrorPanel } from '../../ui/ErrorMessage';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { LoadingPanel } from '../../ui/Loading';
import { useModalState } from '../../ui/useModalState';
import { StageApprovalRequestModal } from './StageApprovalRequestModal';
import { RfqIdProvider, useDraftRfqStructure, useRfqStageApprovalExchanges } from '../../useRfq';
import { StageApprovalsPanel } from './StageApprovalsPanel';
import { useCanRequestDraftStageApproval } from './useCanRequestApproval';

const DraftStageApprovalsPanel = () => {
  const canRequestDraftStageApproval = useCanRequestDraftStageApproval();
  const approvalRequestModal = useModalState();

  return (
    <>
      <StageApprovalsPanel
        openRequestApprovalModal={approvalRequestModal.open}
        canRequestStageApproval={canRequestDraftStageApproval}
      />
      {approvalRequestModal.isOpen && (
        <StageApprovalRequestModal
          close={approvalRequestModal.close}
          isOpen={approvalRequestModal.isOpen}
        />
      )}
    </>
  );
};

export const DraftStageApprovals = ({ rfqId }: { rfqId: string }) => {
  const { t } = useTranslation('translation');
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { data: structure, status: structureStatus } = useDraftRfqStructure({
    rfqId,
    currentCompanyId,
  });
  const { data: exchanges, status: exchangesStatus } = useRfqStageApprovalExchanges({
    rfqId,
    currentCompanyId,
  });

  const stageApprovalSection = useMemo(() => {
    const sections = values(structure?.sectionById);
    return find(sections, { type: SectionType.STAGE_APPROVAL }) || null;
  }, [structure]);

  return structureStatus === 'loading' || exchangesStatus === 'loading' ? (
    <LoadingPanel />
  ) : structureStatus === 'error' || exchangesStatus === 'error' ? (
    <ErrorPanel error={t('request.errors.getRequest')} />
  ) : structure && exchanges ? (
    <RfqIdProvider rfqId={rfqId}>
      <rfx.StructureProvider structure={structure}>
        <rfx.ExchangesProvider exchanges={exchanges}>
          <rfx.StateProvider isLive={false}>
            <rfx.SectionProvider
              key={stageApprovalSection?._id}
              section={stageApprovalSection}
            >
              <DraftStageApprovalsPanel />
            </rfx.SectionProvider>
          </rfx.StateProvider>
        </rfx.ExchangesProvider>
      </rfx.StructureProvider>
    </RfqIdProvider>
  ) : null;
};
