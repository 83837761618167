import { Company, Live, renderStageName } from '@deepstream/common/rfq-utils';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { callAll } from '@deepstream/utils/callAll';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { isEmpty } from 'lodash';
import { Clamp2 } from '@deepstream/ui-kit/elements/text/Clamp';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { useApi } from '../../../api';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useToaster } from '../../../toast';
import { DescriptionItem, DescriptionList } from '../../../ui/DescriptionList';
import { useMutation } from '../../../useMutation';
import { useDraftRfqStructureQueryKey, useLiveRfqStructureQueryKey, useRfqId } from '../../../useRfq';
import * as rfx from '../../../rfx';
import { useWaitForRfqUnlock } from '../../../useWaitForUnlock';
import { Bold } from '../../../Bold';
import { ModalForm } from '../../../ModalForm';
import { CheckboxField } from '../../../form/CheckboxField';
import { useCurrentUserLocale } from '../../../useCurrentUser';

const Schema = z.object({
  isConfirmed: z.literal(true),
});

type MoveToNextStageModalProps = {
  recipients: Company[];
  onClose: () => void;
  onSuccess: () => void;
} & ModalProps;

export const MoveToNextStageModal: React.FC<MoveToNextStageModalProps> = ({
  recipients,
  onClose,
  onSuccess,
  ...props
}) => {
  const { t } = useTranslation();
  const api = useApi();
  const toaster = useToaster();
  const locale = useCurrentUserLocale();
  const queryClient = useQueryClient();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqId = useRfqId({ required: true });
  const { stages, bidById } = rfx.useStructure<Live>();
  const liveStructureQueryKey = useLiveRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
  });
  const draftStructureQueryKey = useDraftRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
    isTemplate: false,
  });
  const waitForRfqUnlock = useWaitForRfqUnlock();

  const [moveRecipientsToNextStage] = useMutation(
    (payload) => waitForRfqUnlock({
      command: () => api.moveRecipientsToNextStage(payload),
    }),
    {
      onSuccess: callAll(
        () => toaster.success(t('request.suppliersTable.toaster.moveSupplierToStageSuccess',
          { count: recipients.length },
        )),
        onSuccess,
      ),
      onError: () => toaster.error(t('request.suppliersTable.toaster.moveSupplierToStageError',
        { count: recipients.length },
      )),
      onSettled: callAll(
        () => queryClient.invalidateQueries(liveStructureQueryKey),
        () => queryClient.invalidateQueries(draftStructureQueryKey),
        () => queryClient.invalidateQueries(['allExchanges', { rfqId, currentCompanyId }]),
        () => queryClient.invalidateQueries(['exchanges', { rfqId, currentCompanyId }]),
        () => queryClient.invalidateQueries(['statsByRecipientId', { rfqId, currentCompanyId }]),
        onClose,
      ),
    },
  );

  const moveToNextStage = async () => {
    await moveRecipientsToNextStage({
      companyId: currentCompanyId,
      rfqId,
      payload: recipients.map(recipient => {
        const currentStageId = bidById[recipient._id].stageId;
        const currentStageIndex = stages.findIndex(stage => stage._id === currentStageId);

        return {
          recipientId: recipient._id,
          nextStageId: stages[currentStageIndex + 1]._id,
        };
      }),
    });
  };

  return (
    <ModalForm
      heading={t('request.suppliersTable.moveSupplierToNextStage', { count: recipients.length })}
      initialValues={{
        isConfirmed: false,
      }}
      validationSchema={toFormikValidationSchema(Schema)}
      onSubmit={async (_values, { setSubmitting }) => {
        try {
          await moveToNextStage();
        } catch (error) {
          setSubmitting(false);
          throw error;
        }
      }}
      onCancel={onClose}
      submitLabel={t('request.suppliersTable.moveSupplier', { count: recipients.length })}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      style={{ content: { maxWidth: '500px' } }}
      {...props}
    >
      <DescriptionList gap={2}>
        {stages.map((stage, index) => {
          const recipientsInStage = recipients
            .filter(recipient => (
              bidById[recipient._id].stageId === stage._id
            ))
            .sort((a, b) => a.company.name.localeCompare(b.company.name, locale));

          return isEmpty(recipientsInStage) ? (
            null
          ) : (
            <DescriptionItem
              key={stage._id}
              term={
                <Trans
                  t={t}
                  i18nKey="request.suppliersTable.supplierWillBeMoved"
                  ns="translation"
                  count={recipientsInStage.length}
                  components={{ b: <Bold /> }}
                  values={{
                    currentStage: renderStageName(stage, t, index, true),
                    nextStage: renderStageName(stages[index + 1], t, index + 1, true),
                  }}
                />
              }
            >
              <Clamp2 lines={1}>
                {recipientsInStage.map(recipient => recipient.company.name).join(', ')}
              </Clamp2>
            </DescriptionItem>
          );
        })}
      </DescriptionList>
      <MessageBlock variant="warn" mt={0}>
        {t('request.suppliersTable.suppliersWillBeNotified')}
      </MessageBlock>
      <CheckboxField
        fieldLabel={t('request.suppliersTable.confirmMoveSuppliers')}
        name="isConfirmed"
      />
    </ModalForm>
  );
};
