import * as rfx from './rfx';

/**
 * Needs a SectionProvider and a PageProvider
 */
export const ExchangeSectionCell = () => {
  const section = rfx.useSectionWithPosition();

  return (
    <>
      {section.number} – {section.name}
    </>
  );
};
