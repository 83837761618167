import { Award, AwardDecision, AwardScenario, Message, RfxSpendAndSavings, SplitDecision, SupplierGroup } from '@deepstream/common/rfq-utils';

export enum AwardFlowStepType {
  GUIDELINES = 'guidelines',
  CHOOSE_AWARD_SCENARIO = 'chooseAwardScenario',
  CHOOSE_REQUEST_LEVEL_AWARD_SUPPLIER = 'chooseRequestLevelAwardSupplier',
  CHOOSE_LINE_LEVEL_AWARD_SUPPLIERS = 'chooseLineLevelAwardSupplier',
  SPLIT_LINE_AWARD = 'splitLineAward',
  CHOOSE_LOT_LEVEL_AWARD_SUPPLIERS = 'chooseLotLevelAwardSuppliers',
  SPEND_AND_SAVINGS_STATUS = 'spendAndSavingsStatus',
  CONFIRM_PREVIOUS_BUDGET = 'confirmPreviousBudget',
  CHOOSE_PROVIDE_BUDGET = 'chooseProvideBudget',
  EDIT_BUDGET = 'editBudget',
  NO_CALCULATED_TOTAL_VALUE_REASONS = 'noCalculatedTotalCostReasons',
  CONFIRM_TOTAL_VALUE = 'confirmTotalValue',
  CALCULATED_VALUE_NOT_ACCURATE_REASONS = 'calculatedValueNotAccurateReasons',
  ENTER_MANUAL_TOTAL_VALUE = 'enterManualTotalValue',
  CANNOT_PROVIDE_MANUAL_TOTAL_VALUE_REASON = 'cannotProvideManualTotalValueReason',
  ADDITIONAL_COMMENTS = 'additionalComments',
  CHOOSE_SAVINGS_METHOD = 'chooseSavingsMethod',
  CANNOT_PROVIDE_TOTAL_SAVINGS_REASON = 'cannotProvideTotalSavingsReason',
  CONFIRM_SUM_SPECIFIC_SAVINGS = 'confirmSumSpecificSavings',
  CONFIRM_BUDGET_FINAL_VALUE_DIFF_SAVINGS = 'confirmBudgetFinalValueDiffSavings',
  ENTER_MANUAL_SAVINGS = 'enterManualSavings',
  CHOOSE_SUPPLIER_TYPES_TO_MESSAGE = 'chooseSupplierTypesToMessage',
  ENTER_SINGLE_SUPPLIER_MESSAGE = 'enterSingleSupplierMessage',
  CHOOSE_MULTI_SUPPLIER_MESSAGE_METHOD = 'chooseMultiSupplierMessageMethod',
  CHOOSE_MULTI_SUPPLIER_CUSTOM_MESSAGE_METHODS = 'chooseMultiSupplierCustomMessageMethods',
  ENTER_MULTI_SUPPLIER_GENERAL_MESSAGE = 'enterMultiSupplierGeneralMessage',
  ENTER_MULTI_SUPPLIER_INDIVIDUAL_MESSAGES = 'enterMultiSupplierIndividualMessages',
  REVIEW_AND_SUBMIT = 'reviewAndSubmit',
  SUBMITTED_SUCCESSFULLY = 'submittedSuccessfully',
}

export type AwardFlowStep =
  | { type: AwardFlowStepType.GUIDELINES }
  | { type: AwardFlowStepType.CHOOSE_AWARD_SCENARIO }
  | { type: AwardFlowStepType.CHOOSE_REQUEST_LEVEL_AWARD_SUPPLIER }
  | { type: AwardFlowStepType.CHOOSE_LINE_LEVEL_AWARD_SUPPLIERS }
  | { type: AwardFlowStepType.SPLIT_LINE_AWARD; exchangeId: string }
  | { type: AwardFlowStepType.CHOOSE_LOT_LEVEL_AWARD_SUPPLIERS }
  | { type: AwardFlowStepType.SPEND_AND_SAVINGS_STATUS }
  | { type: AwardFlowStepType.CONFIRM_PREVIOUS_BUDGET }
  | { type: AwardFlowStepType.CHOOSE_PROVIDE_BUDGET }
  | { type: AwardFlowStepType.EDIT_BUDGET }
  | { type: AwardFlowStepType.NO_CALCULATED_TOTAL_VALUE_REASONS }
  | { type: AwardFlowStepType.CONFIRM_TOTAL_VALUE }
  | { type: AwardFlowStepType.CALCULATED_VALUE_NOT_ACCURATE_REASONS }
  | { type: AwardFlowStepType.ENTER_MANUAL_TOTAL_VALUE }
  | { type: AwardFlowStepType.CANNOT_PROVIDE_MANUAL_TOTAL_VALUE_REASON }
  | { type: AwardFlowStepType.ADDITIONAL_COMMENTS }
  | { type: AwardFlowStepType.CHOOSE_SAVINGS_METHOD }
  | { type: AwardFlowStepType.CANNOT_PROVIDE_TOTAL_SAVINGS_REASON }
  | { type: AwardFlowStepType.CONFIRM_SUM_SPECIFIC_SAVINGS }
  | { type: AwardFlowStepType.CONFIRM_BUDGET_FINAL_VALUE_DIFF_SAVINGS }
  | { type: AwardFlowStepType.ENTER_MANUAL_SAVINGS }
  | { type: AwardFlowStepType.CHOOSE_SUPPLIER_TYPES_TO_MESSAGE }
  | {
    type: AwardFlowStepType.ENTER_SINGLE_SUPPLIER_MESSAGE;
    supplierGroup: SupplierGroup;
    supplierId: string;
  }
  | {
    type: AwardFlowStepType.CHOOSE_MULTI_SUPPLIER_MESSAGE_METHOD;
    supplierGroup: SupplierGroup;
  }
  | {
    type: AwardFlowStepType.CHOOSE_MULTI_SUPPLIER_CUSTOM_MESSAGE_METHODS;
    supplierGroup: SupplierGroup;
  }
  | {
    type: AwardFlowStepType.ENTER_MULTI_SUPPLIER_GENERAL_MESSAGE;
    supplierGroup: SupplierGroup;
  }
  | {
    type: AwardFlowStepType.ENTER_MULTI_SUPPLIER_INDIVIDUAL_MESSAGES;
    supplierGroup: SupplierGroup;
  }
  | { type: AwardFlowStepType.REVIEW_AND_SUBMIT }
  | { type: AwardFlowStepType.SUBMITTED_SUCCESSFULLY };

// TODO remove and instead use AwardDecision in grids!?
/**
 * Temporary representation of award decisions
 * used in the grids.
 */
export type GridAwardDecision =
  | {
    value: 'award';
    awardedSupplierIds: string[];
  }
  | {
    value: 'noAward';
  }
  | {
    value: 'markAsObsolete';
  };

export enum MessageMethod {
  GENERAL = 'general',
  BY_SUPPLIER = 'bySupplier',
  CUSTOM = 'custom',
}

export type MessageConfig = {
  messageMethod: MessageMethod | null;
  generalMessage: Message | null;
  /**
   * This property is only considered with MessageMethod.CUSTOM; when the
   * message method is MessageMethod.GENERAL, all suppliers in this group
   * receive the general message, irrespective of `generalMessageSupplierIds`.
   */
  generalMessageSupplierIds: string[];
  excludedSupplierIds: string[];
  messageBySupplierId: Record<string, Message>;
};

export type AwardFlowData = {
  steps: AwardFlowStep[];
  currentStep: AwardFlowStep;
  hasLineItemsWithTotalCost: boolean;
  editBudget?: boolean;
  supplierIdsByGroup: {
    awarded: string[];
    unsuccessful: string[];
    previouslyUnsuccessful: string[];
    nonBidding: string[];
  };
  supplierGroupsToMessage: {
    awarded?: boolean;
    unsuccessful?: boolean;
    previouslyUnsuccessful?: boolean;
    nonBidding?: boolean;
    none?: boolean;
  };
  messageConfigByGroup: {
    awarded: MessageConfig;
    unsuccessful: MessageConfig;
    previouslyUnsuccessful: MessageConfig;
    nonBidding: MessageConfig;
  };

  awardScenario?: AwardScenario;
  requestAward?: Award;
  awardDecisionByLotId?: Record<string, AwardDecision | null>;
  awardDecisionByExchangeId?: Record<string, AwardDecision | null>;
  splitDecisionByExchangeId?: Record<string, SplitDecision | null>;
  spendAndSavings: RfxSpendAndSavings;
};
