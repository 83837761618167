import { useMemo } from 'react';
import { ACTION_COLUMN_WIDTH, EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { subHeaderOrItemCell } from '@deepstream/ui-kit/grid/EditableGrid/subHeaderOrItemCell';
import { withProps } from '@deepstream/ui-utils/withProps';
import { compact, sumBy } from 'lodash';
import {
  LabelNumItemsSubHeaderCell,
  RowSelectionCheckboxCell,
} from '../../../ui/ExchangeDefsGrid/nonValidationAwareValueCell';
import { RowSelectionCheckboxHeader } from '../../../ui/ExchangeDefsGrid/header';
import { SuppliersGridConfig } from './useSuppliersGridConfig';
import { SuppliersGridColumnHeader } from './SuppliersGridColumnHeader';
import { SuppliersGridRow } from './useSuppliersGridColumnConfigById';

export const defaultColumnWidth = 150;

export const useSuppliersGridColumns = ({
  suppliersGridConfig,
  columnConfigById,
}: {
  suppliersGridConfig: SuppliersGridConfig;
  columnConfigById: Record<string, Omit<EditableGridColumn<SuppliersGridRow>, 'Header'> | null>;
}): EditableGridColumn<SuppliersGridRow>[] => {
  return useMemo(() => {
    const selectedColumns = suppliersGridConfig.columns.selectedItems;

    const SupplierColumnHeader = withProps(SuppliersGridColumnHeader, {
      suppliersGridConfig,
      canHideColumn: false,
    });

    const DynamicColumnHeader = withProps(SuppliersGridColumnHeader, {
      suppliersGridConfig,
      canHideColumn: true,
    });

    const dynamicColumns = compact(selectedColumns.map(selectedColumn => {
      const column = columnConfigById[selectedColumn._id];

      return column
        ? {
          ...column,
          width: suppliersGridConfig.columnWidths?.[selectedColumn._id] || column.width,
          Header: DynamicColumnHeader,
        }
        : null;
    })) as EditableGridColumn<SuppliersGridRow>[];

    const dynamicColumnsWidth = sumBy(dynamicColumns, column => column.width || defaultColumnWidth);

    const supplierColumnWidth = suppliersGridConfig.columnWidths?.supplier || (columnConfigById as any).supplier.width;

    return [
      {
        _id: 'rowSelectionCheckbox',
        accessorKey: 'rowSelectionCheckbox',
        Header: RowSelectionCheckboxHeader,
        label: '',
        ValueCell: subHeaderOrItemCell(
          withProps(LabelNumItemsSubHeaderCell, {
            totalWidth: ACTION_COLUMN_WIDTH + supplierColumnWidth + dynamicColumnsWidth,
          }),
          RowSelectionCheckboxCell,
        ),
        width: ACTION_COLUMN_WIDTH,
        fixedWidth: true,
      },
      {
        ...columnConfigById.supplier,
        width: supplierColumnWidth,
        Header: SupplierColumnHeader,
      } as EditableGridColumn<SuppliersGridRow>,
      ...dynamicColumns,
    ];
  }, [suppliersGridConfig, columnConfigById]);
};
