import { useState, useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { ContractStatus, contractStatusConfig, ExtendedSentContractOverview, SentContractOverviews } from '@deepstream/common/contract';
import { LabeledSorting } from '@deepstream/ui-utils';
import { SortingDirection } from '@deepstream/common';
import { isEmpty, map } from 'lodash';
import { callAll } from '@deepstream/utils/callAll';
import { Illustration } from '@deepstream/ui-kit';
import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { FlexPanelHeader, Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useApi, wrap } from '../../api';
import { Loading } from '../../ui/Loading';
import { ErrorMessage } from '../../ui/ErrorMessage';
import { MIN_CELL_HEIGHT } from '../../FieldsCell';
import { useModalState } from '../../ui/useModalState';
import { useToaster } from '../../toast';
import { useMutation } from '../../useMutation';
import { SentContractsTable } from './SentContractsTable';
import { useTableData } from '../../TableDataContext';
import { useCurrentUser } from '../../useCurrentUser';
import { useLocalStorageState } from '../../useLocalStorageState';
import { ContractsTableData, ContractsTableDataProvider } from './ContractsTableDataProvider';
import { SelectDropdownMenu } from '../../ui/MultiSelect';
import { useLocalStorageSortProps } from '../../sorting';
import { ClearFiltersButton, DashboardRoleFilter, useDashboardRoleFilterItems, useLocalStorageFilterProps, useRecipientFilterProps, useStatusFilterProps } from '../../filtering';
import { RequestTagFilterItem } from '../../types';
import { renderName } from '../../RequestsTable';
import { useRequestTagsContext } from '../RequestTags/RequestTagsContext';
import { renderRequestTagLabel } from '../RequestTags/RequestTagLabel';
import { EditContractTagsDropdown } from '../RequestTags/EditContractTagsDropdown';
import { useNavigate } from '../../tanstackRouter';
import { contractDraftSummaryRoute, contractLiveSummaryRoute } from '../../AppRouting';
import { SearchContracts } from './SearchContracts';
import { FilterDropdownPage, FilterDropdownPages, FilterDropdownPanelConfig } from '../../ui/FilterDropdownPages/FilterDropdownPages';
import { MultiSelectDropdownPage } from '../../ui/FilterDropdownPages/MultiSelectDropdownPage';
import { filterBySearchText } from '../../searchUtils';
import { HorizontalDivider } from '../../ui/HorizontalDivider';

const filterPageIds = {
  tags: 'tags',
  status: 'status',
  recipient: 'recipient',
  role: 'role',
};

const SentContractsContent = ({
  selectedRequestTagId,
  isDraftContracts,
}: {
  selectedRequestTagId?: string;
  isDraftContracts?: boolean;
}) => {
  const { t } = useTranslation(['contracts', 'general', 'translation', 'company']);
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const deleteContractModal = useModalState();
  const [selectedContract, setSelectedContract] = useState<ExtendedSentContractOverview | null>(null);
  const navigate = useNavigate();
  const {
    legacyFilter,
    recipientFilter,
    statusFilter,
    roleFilter,
    requestTagsFilter,
    searchText,
    setSearchText,
    sort,
    query,
    pageSize,
    selectedItemsIds,
    selectAllItemsOnPage,
    unselectAllItems,
  } = useTableData<ContractsTableData<SentContractOverviews>>({ required: true });

  const { status, data, refetch } = query;
  const {
    overviews,
    totalItems = 0,
    companyHasNoLiveContracts,
    companyHasNoDraftContracts,
  } = data ?? {};

  const [deleteContractMutation] = useMutation(
    api.deleteContract,
    {
      onSettled: callAll(
        () => queryClient.invalidateQueries([SentContractsQueryTags.live, { currentCompanyId }]),
        () => queryClient.invalidateQueries([`${SentContractsQueryTags.live}FilterItems`, { currentCompanyId }]),
        () => queryClient.invalidateQueries([SentContractsQueryTags.draft, { currentCompanyId }]),
        () => queryClient.invalidateQueries([`${SentContractsQueryTags.draft}FilterItems`, { currentCompanyId }]),
        deleteContractModal.close,
        () => setSelectedContract(null),
      ),
      onSuccess: () => toaster.success(t('toaster.contractDeleted.success')),
      onError: () => toaster.error(t('toaster.contractDeleted.failed')),
    },
  );

  const openDeleteModal = useCallback(
    (contract: ExtendedSentContractOverview) => {
      setSelectedContract(contract);
      deleteContractModal.open();
    },
    [setSelectedContract, deleteContractModal],
  );

  const deleteContract = () => deleteContractMutation({
    companyId: currentCompanyId,
    // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
    contractId: selectedContract!._id,
  });

  const handleTagsChange = () => {
    // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
    unselectAllItems();
    refetch();
  };

  const hasFiltersOrSearch = (
    (!isDraftContracts && !isEmpty(statusFilter.selectedItems)) ||
    !isEmpty(roleFilter.selectedItems) ||
    !isEmpty(recipientFilter.selectedItems) ||
    !isEmpty(legacyFilter.selectedItems) ||
    !isEmpty(requestTagsFilter.selectedItems) ||
    searchText
  );

  const emptyTableLabel = useMemo(() => {
    if (selectedRequestTagId) {
      return t('requests.tags.noContracts', { ns: 'translation' });
    } else if (isDraftContracts) {
      return companyHasNoDraftContracts
        ? t('companyHasNoDraftContracts')
        : t('notOnTeamForAnyDraftContracts');
    } else {
      return companyHasNoLiveContracts
        ? t('companyHasNotIssuedContracts')
        : t('notOnTeamForAnyContracts');
    }
  }, [selectedRequestTagId, isDraftContracts, t, companyHasNoDraftContracts, companyHasNoLiveContracts]);

  const filtersConfig: Record<string, FilterDropdownPanelConfig> = useMemo(() => (
    {
      [filterPageIds.tags]: {
        pageId: filterPageIds.tags,
        title: t('general.tag', { ns: 'translation' }),
        icon: 'tag',
      },
      ...(!isDraftContracts && {
        [filterPageIds.status]: {
          pageId: filterPageIds.status,
          title: t('general.status', { ns: 'translation' }),
          icon: 'circle-dashed',
        },
      }),
      [filterPageIds.recipient]: {
        pageId: filterPageIds.recipient,
        title: t('summary.counterParty'),
        icon: 'building',
        isIconRegular: true,
      },
      [filterPageIds.role]: {
        pageId: filterPageIds.role,
        title: t('yourRole', { ns: 'company' }),
        icon: 'user',
      },
    }
  ), [isDraftContracts, t]);

  // @ts-expect-error ts(18048) FIXME: 'selectedItemsIds' is possibly 'undefined'.
  const showEditTagsButton = selectedItemsIds.length > 0;
  const disableTableControls = showEditTagsButton || status !== 'success';

  return (
    <>
      <Panel>
        <FlexPanelHeader
          heading={overviews ? (
            <Flex alignItems="center">
              {overviews.length > 0 && (
                <Checkbox
                  style={{ paddingRight: '12px' }}
                  // @ts-expect-error ts(18048) FIXME: 'selectedItemsIds' is possibly 'undefined'.
                  checked={selectedItemsIds.length > 0 && selectedItemsIds.length === overviews.length}
                  // @ts-expect-error ts(18048) FIXME: 'selectedItemsIds' is possibly 'undefined'.
                  indeterminate={selectedItemsIds.length > 0 && selectedItemsIds.length !== overviews.length}
                  onChange={(event) => {
                    if ((event.target as HTMLInputElement).checked) {
                      // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
                      selectAllItemsOnPage();
                    } else {
                      // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
                      unselectAllItems();
                    }
                  }}
                />
              )}
              <Text fontSize={2}>
                {/*
                 // @ts-expect-error ts(18048) FIXME: 'selectedItemsIds' is possibly 'undefined'. */}
                {selectedItemsIds.length > 0
                  // @ts-expect-error ts(18048) FIXME: 'selectedItemsIds' is possibly 'undefined'.
                  ? t('contractCountSelected', { count: totalItems, selectedCount: selectedItemsIds.length })
                  : t('contractCount', { count: totalItems })}
              </Text>
            </Flex>
          ) : null}
          childrenContainerProps={{ 'data-test': 'contracts-table-actions' }}
        >
          {showEditTagsButton ? (
            // @ts-expect-error ts(2322) FIXME: Type 'string[] | undefined' is not assignable to type 'string[]'.
            <EditContractTagsDropdown contractIds={selectedItemsIds} onSave={handleTagsChange} />
          ) : (
            <Flex
              flexWrap="wrap"
              alignItems="center"
              justifyContent="flex-end"
              sx={{ columnGap: 1 }}
            >
              {/* Temporarily removing Legacy filter until we find a more scalable way to display filters */}
              {/* <Box m={1}>
                <SelectDropdownMenu
                  buttonText={t('filtering.legacy.title')}
                  buttonIcon="filter"
                  allowEmptySelection
                  menuWidth={240}
                  menuZIndex={10}
                  disabled={status !== 'success'}
                  itemHeight={52}
                  {...legacyFilter}
                />
              </Box> */}
              <SearchContracts<ExtendedSentContractOverview>
                queryFn={isDraftContracts ? api.getDraftContracts : api.getSentContracts}
                getResultText={(contract: ExtendedSentContractOverview) => contract.name}
                onResultClick={(contract: ExtendedSentContractOverview) => contract.status === ContractStatus.DRAFT
                  ? navigate({
                    to: contractDraftSummaryRoute.to,
                    // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
                    params: { currentCompanyId, contractId: contract._id },
                  })
                  : navigate({
                    to: contractLiveSummaryRoute.to,
                    // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
                    params: { currentCompanyId, contractId: contract._id },
                  })
                }
                disabled={disableTableControls}
              />

              <FilterDropdownPages config={filtersConfig}>
                <FilterDropdownPage
                  pageId={filterPageIds.tags}
                  onChange={requestTagsFilter.onChange}
                  selectedItems={requestTagsFilter.selectedItems}
                >
                  <MultiSelectDropdownPage
                    filterItems={(value) =>
                      filterBySearchText(value, requestTagsFilter.items, [
                        'name',
                        'email',
                      ])
                    }
                    filterPlaceholder={t('requests.searching.searchByTagName', { ns: 'translation' })}
                    renderPreItemContent={(tag, index) => !tag.parentTagId && index > 0 ? <HorizontalDivider my={2} /> : null}
                    items={requestTagsFilter.items}
                    onChange={requestTagsFilter.onChange}
                    renderItem={requestTagsFilter.renderItem}
                    selectedItems={requestTagsFilter.selectedItems}
                    idProp={requestTagsFilter.idProp}
                  />
                </FilterDropdownPage>

                <FilterDropdownPage
                  pageId={filterPageIds.recipient}
                  onChange={recipientFilter.onChange}
                  selectedItems={recipientFilter.selectedItems}
                >
                  <MultiSelectDropdownPage
                    filterItems={(value) =>
                      filterBySearchText(value, recipientFilter.items, [
                        'name',
                      ])
                    }
                    filterPlaceholder={t('requests.searching.searchByName', { ns: 'translation' })}
                    items={recipientFilter.items}
                    onChange={recipientFilter.onChange}
                    renderItem={recipientFilter.renderItem}
                    selectedItems={recipientFilter.selectedItems}
                    idProp={recipientFilter.idProp}
                  />
                </FilterDropdownPage>

                {!isDraftContracts && (
                  <FilterDropdownPage
                    pageId={filterPageIds.status}
                    onChange={statusFilter.onChange}
                    selectedItems={statusFilter.selectedItems}
                  >
                    <MultiSelectDropdownPage
                      items={statusFilter.items}
                      onChange={statusFilter.onChange}
                      renderItem={statusFilter.renderItem}
                      selectedItems={statusFilter.selectedItems}
                      idProp={statusFilter.idProp}
                    />
                  </FilterDropdownPage>
                )}

                <FilterDropdownPage
                  pageId={filterPageIds.role}
                  onChange={roleFilter.onChange}
                  selectedItems={roleFilter.selectedItems}
                >
                  <MultiSelectDropdownPage
                    items={roleFilter.items}
                    onChange={roleFilter.onChange}
                    renderItem={roleFilter.renderItem}
                    selectedItems={roleFilter.selectedItems}
                    idProp={roleFilter.idProp}
                  />
                </FilterDropdownPage>
              </FilterDropdownPages>

              <SelectDropdownMenu
                buttonText={t('sort', { ns: 'general' })}
                buttonIcon="sort"
                menuWidth={220}
                menuZIndex={10}
                disabled={status !== 'success' || (overviews && totalItems === 0)}
                truncate={false}
                {...sort}
              />
            </Flex>
          )}
        </FlexPanelHeader>
        <PanelDivider />
        {status === 'success' && totalItems > 0 ? (
          <SentContractsTable openDeleteModal={openDeleteModal} />
        ) : (
          <Flex
            height={MIN_CELL_HEIGHT * (status === 'loading' ? pageSize : 5)}
            flexDirection="column"
            justifyContent="center"
          >
            <PanelPadding>
              {status === 'loading' ? (
                <Loading fontSize={4} fontWeight={400} />
              ) : status === 'error' ? (
                <ErrorMessage fontSize={3} error={t('errors.getContracts')} />
              ) : hasFiltersOrSearch ? (
                <Illustration
                  variant="search-results"
                  label={
                    <Flex flexDirection="column" sx={{ gap: 2 }}>
                      <Text>{t('noResultsMatchYourFilters', { ns: 'general' })}</Text>
                      <ClearFiltersButton
                        onClick={() => {
                          statusFilter.onChange([]);
                          roleFilter.onChange([]);
                          recipientFilter.onChange([]);
                          legacyFilter.onChange([]);
                          requestTagsFilter.onChange([]);
                          setSearchText('');
                        }}
                        alignSelf="center"
                      />
                    </Flex>
                  }
                />
              ) : (
                <Illustration
                  variant={isDraftContracts ? 'draft-request' : 'empty-state'}
                  label={emptyTableLabel}
                />
              )}
            </PanelPadding>
          </Flex>
        )}
      </Panel>
      <Dialog
        heading={t('dialog.confirmContractDeletion.heading')}
        body={(
          <>
            <Text mb={2}>{t('dialog.confirmContractDeletion.body')}:</Text>
            <Text fontWeight={500}>{selectedContract?.name}</Text>
            <MessageBlock variant="warn" mt={3}>
              {t('dialog.confirmContractDeletion.warning')}
            </MessageBlock>
          </>
        )}
        okButtonText={t('dialog.confirmContractDeletion.okButtonText')}
        okButtonVariant="danger"
        isOpen={deleteContractModal.isOpen}
        onOk={deleteContract}
        onCancel={deleteContractModal.close}
        showCloseIcon
      />
    </>
  );
};

type LegacyFilter = {
  label: string;
  value: boolean;
};

const useStatusFilterItems = (statuses: string[] | undefined) => {
  const { t } = useTranslation('contracts');

  return useMemo(() => {
    if (!statuses) {
      return;
    }

    return statuses.map(status => ({
      ...contractStatusConfig[status],
      label: t(`status.${status}`),
    }));
  }, [t, statuses]);
};

const useLegacyFilterItems = () => {
  const { t } = useTranslation('contracts');

  return useMemo(() => {
    return [{
      label: t('filtering.legacy.onlyLegacy'),
      value: true,
    }, {
      label: t('filtering.legacy.excludeLegacy'),
      value: false,
    }] as LegacyFilter[];
  }, [t]);
};

const useSortItems = (): LabeledSorting[] => {
  const { t } = useTranslation('contracts');

  return useMemo(() => [
    { label: t('sorting.mostRecentlyCreated'), accessor: 'meta.createdAt', direction: SortingDirection.DESC },
    { label: t('sorting.leastRecentlyCreated'), accessor: 'meta.createdAt', direction: SortingDirection.ASC },
    { label: t('sorting.newestStartDate'), accessor: 'startDate', direction: SortingDirection.DESC },
    { label: t('sorting.newestExpiryDate'), accessor: 'expiryDate', direction: SortingDirection.DESC },
    { label: t('sorting.oldestExpiryDate'), accessor: 'expiryDate', direction: SortingDirection.ASC },
    { label: t('sorting.oldestStartDate'), accessor: 'startDate', direction: SortingDirection.DESC },
    { label: t('sorting.highestContractValue'), accessor: 'spendData.amount', direction: SortingDirection.DESC },
    { label: t('sorting.lowestContractValue'), accessor: 'spendData.amount', direction: SortingDirection.ASC },
  ], [t]);
};

const SentContracts = ({
  queryTag,
  queryFn,
  selectedRequestTagId,
}: {
  queryTag: string;
  queryFn: (...params: any[]) => Promise<SentContractOverviews>;
  selectedRequestTagId?: string;
}) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();
  const api = useApi();
  const { tags, tagById } = useRequestTagsContext();

  const filterItems = useQuery(
    [`${queryTag}FilterItems`, { currentCompanyId }],
    wrap(api.getSentContractsFilterItems),
  );

  const statusFilterItems = useStatusFilterItems(filterItems.data?.statuses);
  const dashboardRoleFilterItems = useDashboardRoleFilterItems(
    filterItems.data?.roles,
  );
  const legacyFilterItems = useLegacyFilterItems();

  const requestTagsFilterItems = useMemo(
    () => tags.filter(({ _id }) => _id !== selectedRequestTagId),
    [tags, selectedRequestTagId],
  ) as RequestTagFilterItem[];

  const legacyFilterProps = useLocalStorageFilterProps({
    storageKey: `${currentCompanyId}.${user._id}.${queryTag}.legacyFilter`,
    items: legacyFilterItems,
    idProp: 'value',
    // @ts-expect-error ts(2322) FIXME: Type '(item: LegacyFilter) => string' is not assignable to type '(item: LegacyFilter | null) => string | Element'.
    renderItem: (item: LegacyFilter) => item.label,
    // @ts-expect-error ts(2322) FIXME: Type '(selectedItems: LegacyFilter[]) => { isLegacy: boolean; } | undefined' is not assignable to type '(selectedItems: LegacyFilter[]) => Record<string, unknown>'.
    getQueryParam: (selectedItems: LegacyFilter[]) => {
      if (!selectedItems?.length) return;

      return { isLegacy: selectedItems[0].value };
    },
  });

  // @ts-expect-error ts(2345) FIXME: Argument of type 'RecipientFilterItem[] | undefined' is not assignable to parameter of type 'RecipientFilterItem[]'.
  const recipientFilterProps = useRecipientFilterProps(queryTag, filterItems.data?.recipients);
  // @ts-expect-error ts(2345) FIXME: Argument of type '{ label: string; status: ContractStatus; icon: Icon; }[] | undefined' is not assignable to parameter of type 'StatusFilter[]'.
  const statusFilterProps = useStatusFilterProps('sentContracts', statusFilterItems);

  const roleFilterProps = useLocalStorageFilterProps({
    storageKey: `${currentCompanyId}.${user._id}.${queryTag}.roleFilter`,
    items: dashboardRoleFilterItems,
    idProp: '_id',
    renderItem: renderName,
    getQueryParam: (selectedItems: DashboardRoleFilter[]) => ({
      roles: map(selectedItems, '_id').sort(),
    }),
  });

  const requestTagsFilterProps = useLocalStorageFilterProps({
    storageKey: `${currentCompanyId}.${user._id}.${queryTag}.requestTagsFilter`,
    items: requestTagsFilterItems,
    idProp: '_id',
    // @ts-expect-error ts(2345) FIXME: Argument of type 'RequestTagFilterItem | null' is not assignable to parameter of type 'RequestTag'.
    renderItem: (requestTag) => renderRequestTagLabel(requestTag, tagById),
    getQueryParam: (selectedItems: RequestTagFilterItem[]) => ({
      // sort so the same set of IDs always results in the
      // same query key irrespective of the order of selection
      requestTags: map(selectedItems, '_id').sort(),
    }),
  });

  const sortItems = useSortItems();
  const sortProps = useLocalStorageSortProps({
    storageKey: `${currentCompanyId}.${user._id}.${queryTag}.sort`,
    items: sortItems,
  });

  const pageSizeState = useLocalStorageState<number>({
    key: `${currentCompanyId}.${user._id}.${queryTag}.pageSize`,
    defaultValue: 10,
  });

  const searchTextState = useLocalStorageState<string>({
    key: `${currentCompanyId}.${user._id}.${queryTag}.searchText`,
    defaultValue: '',
  });

  return (
    <ContractsTableDataProvider
      queryTag={queryTag}
      queryFn={queryFn}
      statusFilter={statusFilterProps}
      roleFilter={roleFilterProps}
      recipientFilter={recipientFilterProps}
      legacyFilter={legacyFilterProps}
      requestTagsFilter={requestTagsFilterProps}
      preselectedTag={selectedRequestTagId}
      sort={sortProps}
      pageSizeState={pageSizeState}
      searchTextState={searchTextState}
    >
      <SentContractsContent
        selectedRequestTagId={selectedRequestTagId}
        isDraftContracts={queryTag === SentContractsQueryTags.draft}
      />
    </ContractsTableDataProvider>
  );
};

export const SentContractsQueryTags = {
  live: 'sentContracts',
  draft: 'draftContracts',
};

export const LiveSentContracts = ({
  selectedRequestTagId,
}: {
  selectedRequestTagId?: string;
}) => {
  const api = useApi();

  return (
    <SentContracts
      queryTag={SentContractsQueryTags.live}
      queryFn={api.getSentContracts}
      selectedRequestTagId={selectedRequestTagId}
    />
  );
};

export const DraftSentContracts = ({ selectedRequestTagId }: { selectedRequestTagId?: string }) => {
  const api = useApi();

  return (
    <SentContracts
      queryTag={SentContractsQueryTags.draft}
      queryFn={api.getDraftContracts}
      selectedRequestTagId={selectedRequestTagId}
    />
  );
};
