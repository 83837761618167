import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';
import { CollaboratorInviteStatus, PageRole } from '@deepstream/common/rfq-utils';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import * as rfx from '../../../rfx';
import { RfqIdProvider, useDraftRfqStructure, useLiveRfqStructure } from '../../../useRfq';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import { LoadingPanel } from '../../../ui/Loading';
import { RequestTeamSection } from './RequestTeamSection';

export const RequestSenderTeams = ({
  rfqId,
  recipientId,
  isReview,
  isRevising,
  isTemplate,
  isTemplatePreview,
  isLive,
  navigateToDraftTeam,
}: {
  rfqId: string;
  recipientId?: string;
  isReview?: boolean;
  isRevising?: boolean;
  isTemplate?: boolean;
  isTemplatePreview?: boolean;
  isLive?: boolean;
  navigateToDraftTeam: (companyId: string) => any;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const {
    data: rfxDraftStructure,
    isLoading: isLoadingDraft,
    isError: isErrorDraft,
  } = useDraftRfqStructure({
    rfqId,
    currentCompanyId,
    enabled: !isLive,
    isTemplate,
  });

  const {
    data: rfxLiveStructure,
    isLoading: isLoadingLive,
    isError: isErrorLive,
  } = useLiveRfqStructure({
    rfqId,
    currentCompanyId,
    recipientId,
    enabled: Boolean(isLive),
  });

  const isLoading = isLoadingDraft || isLoadingLive;
  const isError = isErrorDraft || isErrorLive;
  const rfxStructure = rfxDraftStructure || rfxLiveStructure;

  const rfxOverrides = useMemo(
    (): rfx.UserOverrides => isTemplate ? { isOwner: true, pageRole: PageRole.EDITOR } : {},
    [isTemplate],
  );

  return isLoading ? (
    <LoadingPanel />
  ) : isError ? (
    <ErrorPanel error={t('errors.unexpected')} />
  ) : rfxStructure ? (
    <rfx.StateProvider
      isRevising={isRevising}
      isTemplate={isTemplate}
      isTemplatePreview={isTemplatePreview}
      isReview={isReview}
      isLive={isLive}
    >
      <RfqIdProvider rfqId={rfqId}>
        <rfx.StructureProvider structure={rfxStructure}>
          <rfx.SaveChangesProvider saveChanges={noop}>
            <rfx.OverridesProvider {...rfxOverrides}>
              <Stack gap="20px">
                {rfxStructure.senders
                  .filter(sender => sender.inviteStatus !== CollaboratorInviteStatus.REJECTED)
                  .map(sender => (
                    <RequestTeamSection
                      key={sender.company._id}
                      sender={sender}
                      navigateToDraftTeam={navigateToDraftTeam}
                    />
                  ))}
              </Stack>
            </rfx.OverridesProvider>
          </rfx.SaveChangesProvider>
        </rfx.StructureProvider>
      </RfqIdProvider>
    </rfx.StateProvider>
  ) : (
    null
  );
};
