import * as Layout from '../../Layout';
import { ContractLiveHeader } from './ContractLiveHeader';
import { ContractAudit } from './Audit/ContractAudit';
import { useContractId } from './contract';

export const ContractLiveAuditPageContent = () => {
  const tabId = 'audit';
  const contractId = useContractId();

  return (
    <Layout.HeaderAndContent
      header={
        <ContractLiveHeader
          key={contractId}
          contractId={contractId}
          selectedTabId={tabId}
        />
      }
      content={
        <ContractAudit
          contractId={contractId}
        />
      }
    />
  );
};
