import { useTranslation } from 'react-i18next';
import { ActionType } from '@deepstream/common/rfq-utils';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useApprovalExchange, useApprovalExchangeReply, useApprovalsContext } from '../contract';
import { useContract } from '../useContract';

export const RevokeApprovalButton = () => {
  const { t } = useTranslation('contracts');
  const { isFetching } = useContract({ scope: 'current' });
  // @ts-expect-error ts(2339) FIXME: Property 'removeFilter' does not exist on type '{ onlyDirtyExchanges: boolean; toggleDirtyExchanges: () => void; removeFilter: () => void; } | null'.
  const { removeFilter } = useApprovalsContext();
  const approvalExchange = useApprovalExchange();
  const [revokeApproval, { isLoading }] = useApprovalExchangeReply(ActionType.REJECT, {
    onSettled: removeFilter,
  });

  const canRevoke = Boolean(approvalExchange.actions.find(action => action.type === ActionType.REJECT));

  return (
    <Button
      onClick={revokeApproval}
      variant="danger"
      iconLeft="times"
      disabled={isLoading || isFetching || !canRevoke}
    >
      {t('approval.done.action')}
    </Button>
  );
};
