import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getInviteMessage } from '@deepstream/common/user-utils';
import { withProps } from '@deepstream/ui-utils/withProps';
import { BasicTableStyles } from '../../../TableStyles';
import { Table } from '../../../Table';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useLocalStorageState } from '../../../useLocalStorageState';
import { FieldsCell, MIN_CELL_HEIGHT } from '../../../FieldsCell';
import { UserAvatar } from '../../../UserAvatar';
import { User } from '../../../types';
import { TeamUserCellTitle } from '../../../TeamUserCellTitle';
import { TeamMembershipRequestActionButton } from './TeamMembershipRequestActionButton';
import { useCurrentUser } from '../../../useCurrentUser';

export const TeamMembershipRequestsTable = ({
  users,
  canEditPermissions,
  openTeamMembershipDecisionModal,
}: {
  users: User[];
  canEditPermissions: boolean;
  openTeamMembershipDecisionModal: (user: User) => void;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();

  const columns = useMemo(
    () => [{ id: 'custom' }],
    [],
  );

  const CustomRowCells = useMemo(
    () => {
      const fields = [
        {
          label: t('general.email'),
          accessor: 'email',
          // when there's no user name, the email is displayed in the cell's
          // title and thus shouldn't be repeated here
          condition: (user) => !!user.name,
        },
        {
          label: t('general.message'),
          accessor: (user) => getInviteMessage(user.requestedRoles[currentCompanyId], t),
        },
      ];

      return withProps(
        FieldsCell,
        {
          fields,
          TitleComponent: TeamUserCellTitle,
          RowIconComponent: UserAvatar,
          actionColumnWidth: 100,
          ActionsComponent: canEditPermissions
            ? withProps(
              TeamMembershipRequestActionButton,
              { openTeamMembershipDecisionModal },
            )
            : () => null,
        },
      );
    },
    [t, currentCompanyId, canEditPermissions, openTeamMembershipDecisionModal],
  );

  const [initialPageSize, setInitialPageSize] = useLocalStorageState<number>({
    key: `${currentCompanyId}.${currentUser._id}.teamMembershipRequests.pageSize`,
    defaultValue: 10,
  });

  return (
    <BasicTableStyles hoverBackgroundColor="lightGray6" hoverCursor="default">
      <Table
        columns={columns}
        data={users}
        isPaginated
        pageSizes={[5, 10, 20, 50, 100]}
        initialPageSize={initialPageSize}
        onPageSizeChange={setInitialPageSize}
        hideHeader
        CustomRowCells={CustomRowCells}
        hasStaticHeight={false}
        enforcePagination
        minCellHeight={MIN_CELL_HEIGHT}
        smallPageControls
      />
    </BasicTableStyles>
  );
};
