import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { z } from 'zod';
import { Flex } from 'rebass/styled-components';
import { Popover, usePopover } from '@deepstream/ui-kit/elements/popup/usePopover';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { QuestionExchangeDefinition } from '@deepstream/common/rfq-utils';
import {
  DeleteMenuItem,
  EllipsisMenu,
  MoveUpMenuItem,
  MoveDownMenuItem,
  DropdownMenuItem,
} from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { useExchangeDefsField } from '../../../draft/exchangeDefs';
import { NumberStepperField } from '../../../form/NumberStepperField';

const MoveToPositionMenuItem = ({
  onSelect,
}: {
  onSelect: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <DropdownMenuItem
      icon="shuffle"
      onSelect={onSelect}
    >
      {t('general.moveToPosition')}
    </DropdownMenuItem>
  );
};

/**
 * Renders a dropdown menu with the actions for each question exchange definition
 */
export const DraftQuestionActions = ({
  index,
  fieldName = 'exchangeDefs',
  scrollToQuestion,
}: {
  index: number;
  fieldName?: string;
  scrollToQuestion: (index: number) => void;
}) => {
  const { t } = useTranslation();
  const popover = usePopover({
    placement: 'top',
    overReferenceElement: true,
  });
  const [movedItemIndex, setMovedItemIndex] = useState<number | null>(null);

  const {
    exchangeDefs,
    moveExchangeDef,
    moveExchangeDefToPosition,
    removeExchangeDef,
  } = useExchangeDefsField<QuestionExchangeDefinition, any>(fieldName);

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      position: z.number({ message: t('general.required') })
        .min(1, { message: t('request.question.minimumValue', { value: 1 }) })
        .max(exchangeDefs.length, { message: t('request.question.maximumValue', { value: exchangeDefs.length }) }),
    });

    return toFormikValidationSchema(Schema);
  }, [exchangeDefs.length, t]);

  // Ensure that the scrolling action occurs only after the state has been updated and the component has re-rendered
  useEffect(() => {
    if (movedItemIndex !== null) {
      scrollToQuestion(movedItemIndex);
    }
  }, [movedItemIndex, scrollToQuestion]);

  return (
    <Flex flex="0 0 auto" justifyContent="flex-end" alignItems="center">
      <EllipsisMenu
        variant="secondary-outline"
        menuZIndex={202}
        width="40px"
        data-test="question-actions-button"
        popoverProps={{
          'data-test': 'question-actions-menu',
        }}
      >
        <MoveUpMenuItem
          onSelect={() => moveExchangeDef(index, -1)}
          disabled={index === 0}
        />
        <MoveDownMenuItem
          onSelect={() => moveExchangeDef(index, +1)}
          disabled={index === exchangeDefs.length - 1}
        />
        {/*
         // @ts-expect-error ts(2322) FIXME: Type 'Dispatch<SetStateAction<HTMLElement | undefined>>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'. */}
        <div ref={popover.setReferenceElement} />
        <MoveToPositionMenuItem
          onSelect={() => {
            if (popover.update) {
              popover.update();
            }
            popover.toggle();
          }}
        />
        <DeleteMenuItem onSelect={() => removeExchangeDef(index)} />
      </EllipsisMenu>
      <Popover width={200} onClickOutside={popover.close} {...popover}>
        <Formik
          initialValues={{
            position: index + 1,
          }}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={({ position }) => {
            moveExchangeDefToPosition(index, position - 1);
            setMovedItemIndex(position - 1);
            popover.close();
          }}
        >
          {({ isSubmitting, dirty, resetForm }) => (
            <Form>
              <Flex
                flexDirection="column"
                height="100%"
                width="100%"
                p={3}
                justifyContent="space-between"
                bg="white"
                sx={{ gap: '8px' }}
              >
                <IconText icon="shuffle" text={t('general.moveToPosition')} color="subtext" gap={2} />
                <NumberStepperField
                  name="position"
                  increment={1}
                  minValue={1}
                  maxValue={exchangeDefs.length}
                />
                <Flex sx={{ gap: '8px' }} alignSelf="flex-end">
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => {
                      popover.close();
                      resetForm();
                    }}
                  >
                    {t('general.cancel')}
                  </Button>
                  <Button type="submit" disabled={isSubmitting || !dirty}>
                    {t('general.move')}
                  </Button>
                </Flex>
              </Flex>
            </Form>
          )}
        </Formik>
      </Popover>
    </Flex>
  );
};
