import { useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { isEmpty, noop } from 'lodash';
import { Form, Formik } from 'formik';
import { Live } from '@deepstream/common/rfq-utils';
import { MessageBlock3 } from '@deepstream/ui-kit/elements/MessageBlock';
import { useMemo } from 'react';
import * as rfx from '../../../../rfx';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData } from '../types';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { MoneyField } from '../../../../form/MoneyField';
import { CurrencyCodeProvider } from '../../../../ui/Currency';
import { CheckboxFieldBase } from '../../../../form/CheckboxField';
import { Direction } from '../../../../ui/MultiStepFlow/types';

const getSubmissionDataFromFormValues = (
  { formData }: { formData: { manualTotalValue?: number; cannotProvideManualTotalValue?: boolean } },
  data: AwardFlowData,
): Partial<AwardFlowData> | null => {
  const { manualTotalValue, cannotProvideManualTotalValue } = formData;

  if (manualTotalValue) {
    return {
      spendAndSavings: {
        ...data.spendAndSavings,
        manualTotalValue,
        canProvideManualTotalValue: true,
      },
    };
  } else {
    return {
      spendAndSavings: {
        ...data.spendAndSavings,
        manualTotalValue: null,
        canProvideManualTotalValue: cannotProvideManualTotalValue ? (
          false
        ) : cannotProvideManualTotalValue === false ? (
          true
        ) : (
          null
        ),
      },
    };
  }
};

export const EnterManualTotalValueStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (data: Partial<AwardFlowData> | null, direction: Direction) => void,
}) => {
  const { t } = useTranslation('translation');
  const { currencyCode } = rfx.useStructure<Live>();

  const initialValues = {
    formData: {
      manualTotalValue: data.spendAndSavings.manualTotalValue,
      cannotProvideManualTotalValue: data.spendAndSavings.canProvideManualTotalValue ? (
        false
      ) : data.spendAndSavings.canProvideManualTotalValue === false ? (
        true
      ) : (
        null
      ),
    },
  };

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      formData: z.custom<{ manualTotalValue: number | null; cannotProvideManualTotalValue: boolean | null }>().refine(
        value => Boolean(value.manualTotalValue || value.cannotProvideManualTotalValue),
        { message: t('request.awardFlow.errors.enterManualTotalValue') },
      ),
    });

    return toFormikValidationSchema(Schema);
  }, [t]);

  return (
    <Formik
      validateOnBlur
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={noop}
    >
      {({ values, submitForm, validateForm, setFieldValue, dirty }) => (
        <Form>
          <lotPagesLayout.ContentWrapper>
            <StepNavigation
              // @ts-expect-error ts(2345) FIXME: Argument of type '{ formData: { manualTotalValue: number | null; cannotProvideManualTotalValue: boolean | null; }; }' is not assignable to parameter of type '{ formData: { manualTotalValue?: number | undefined; cannotProvideManualTotalValue?: boolean | undefined; }; }'.
              onBackClick={() => submitAndNavigate(dirty ? getSubmissionDataFromFormValues(values, data) : null, Direction.BACK)}
              onContinueClick={async () => {
                const errors = await validateForm();

                await submitForm();

                if (isEmpty(errors)) {
                  // @ts-expect-error ts(2345) FIXME: Argument of type '{ formData: { manualTotalValue: number | null; cannotProvideManualTotalValue: boolean | null; }; }' is not assignable to parameter of type '{ formData: { manualTotalValue?: number | undefined; cannotProvideManualTotalValue?: boolean | undefined; }; }'.
                  submitAndNavigate(dirty ? getSubmissionDataFromFormValues(values, data) : null, Direction.FORWARD);
                }
              }}
            >
              <FormErrors />
              <lotPagesLayout.Section
                heading={t('request.awardFlow.steps.enterManualTotalValue.heading')}
              >
                <Box mt="20px">
                  <CurrencyCodeProvider code={currencyCode}>
                    <MoneyField
                      required
                      // @ts-expect-error ts(2322) FIXME: Type 'boolean | null' is not assignable to type 'boolean | undefined'.
                      disabled={values.formData.cannotProvideManualTotalValue}
                      name="formData.manualTotalValue"
                      sx={{ width: 200 }}
                      // quick fix: we can remove setting the inputStyle height once a line height is
                      // set for Input
                      inputStyle={{ height: 40 }}
                    />
                  </CurrencyCodeProvider>
                </Box>
                <lotPagesLayout.InfoText mt="10px" mb="40px">
                  {t('request.awardFlow.steps.enterManualTotalValue.info')}
                </lotPagesLayout.InfoText>
                <CheckboxFieldBase
                  fieldLabel={t('request.awardFlow.steps.enterManualTotalValue.cannotProvide')}
                  name="formData.cannotProvideManualTotalValue"
                  // @ts-expect-error ts(2322) FIXME: Type 'boolean | null' is not assignable to type 'boolean | undefined'.
                  value={values.formData.cannotProvideManualTotalValue}
                  onChange={async (event: any) => {
                    if (event.target.checked) {
                      await setFieldValue('formData.cannotProvideManualTotalValue', true);
                      await setFieldValue('formData.manualTotalValue', null);
                      await validateForm();
                    } else {
                      setFieldValue('formData.cannotProvideManualTotalValue', false);
                    }
                  }}
                />
                {values.formData.cannotProvideManualTotalValue && (
                  <MessageBlock3 variant="warn" mt="20px">
                    {t('request.awardFlow.steps.enterManualTotalValue.cannotProvideWarning')}
                  </MessageBlock3>
                )}
              </lotPagesLayout.Section>
            </StepNavigation>
          </lotPagesLayout.ContentWrapper>
        </Form>
      )}
    </Formik>
  );
};
