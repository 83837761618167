import { useCallback, useEffect } from 'react';
import { cloneDeep, findIndex } from 'lodash';
import { OBJECT_ID_REGEX } from '@deepstream/common/constants';
import { useCurrentCompanyId } from '../currentCompanyId';
import { useCurrentUser } from '../useCurrentUser';

import { useLocalStorageState } from '../useLocalStorageState';

type NavHistoryEntities = 'request' | 'contract';

type UserNavHistory = {
  [k in NavHistoryEntities]: string[];
};

const MAX_HISTORY = 5;
const SUFFIX = 'navHistory';

const getEntityPath = (path: string): null | { entity: NavHistoryEntities, _id: string } => {
  const parts = path?.split('/');

  for (const entity of ['contract', 'request'] as const) {
    const index = parts.findIndex((part) => part === entity);
    if (index === -1) continue;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const _id = parts
      .slice(index)
      .find((part) => OBJECT_ID_REGEX.test(part));

    if (_id) {
      return {
        entity,
        _id,
      };
    }
  }

  return null;
};

const getStorageKey = (companyId: string, userId: string) => `${companyId}.${userId}.${SUFFIX}`;

export const useNavHistory = () => {
  const companyId = useCurrentCompanyId({ required: false });
  const user = useCurrentUser();

  const [value, setValue] = useLocalStorageState<UserNavHistory>({
    // @ts-expect-error ts(2345) FIXME: Argument of type 'string | null' is not assignable to parameter of type 'string'.
    key: getStorageKey(companyId, user._id),
    defaultValue: {
      request: [],
      contract: [],
    },
  });

  const getEntityHistory = useCallback((entity: NavHistoryEntities) => {
    return value?.[entity] ?? [];
  }, [value]);

  const setEntityHistory = useCallback((
    entity: NavHistoryEntities,
    value: string,
  ) => {
    setValue((prev) => {
      const newHistory = cloneDeep(prev);

      const stack = newHistory[entity];
      const index = findIndex(stack, (id) => id === value);

      if (index !== -1) stack.splice(index, 1);
      if (stack.length >= MAX_HISTORY) stack.pop();

      stack.unshift(value);

      return newHistory;
    });
  }, [setValue]);

  return [getEntityHistory, setEntityHistory] as const;
};

export const usePathWatch = (): void => {
  const [, setHistory] = useNavHistory();

  useEffect(() => {
    const path = getEntityPath(window.location.pathname);

    if (path === null) return;

    setHistory(path.entity, path._id);
  }, [setHistory]);
};

export const PathWatcher = () => {
  usePathWatch();

  return null;
};
