import { useTranslation } from 'react-i18next';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { useContract } from './useContract';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { Loading } from '../../ui/Loading';
import { ErrorMessage } from '../../ui/ErrorMessage';
import { ContractProvider } from './contract';
import { ContractReminders } from './ContractReminders';

export const ContractLiveReminders = ({
  contractId,
}: {
  contractId: string;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { data: contract, isLoading, isError, isSuccess } = useContract({
    contractId,
    currentCompanyId,
    scope: 'current',
  });

  return (
    <>
      {isLoading ? (
        <PanelPadding>
          <Loading />
        </PanelPadding>
      ) : isError ? (
        <PanelPadding>
          <ErrorMessage error={t('errors.unexpected')} />
        </PanelPadding>
      ) : isSuccess && contract ? (
        <ContractProvider contract={contract}>
          <ContractReminders />
        </ContractProvider>
      ) : (
        null
      )}
    </>
  );
};
