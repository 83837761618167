import * as Layout from '../../../Layout';
import { Header } from './Header';
import { TemplatePageTab } from './utils';
import { DraftSummary } from './DraftSummary';

export const QuestionnaireTemplateDraftSummaryPageContent = () => {
  const tabId = TemplatePageTab.SUMMARY;

  return (
    <Layout.HeaderAndContent
      header={
        <Header selectedTabId={tabId} />
      }
      content={
        <DraftSummary />
      }
    />
  );
};
