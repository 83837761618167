import { useMemo } from 'react';
import { fromPairs } from 'lodash';
import { useDeviceSize } from '../ui/useDeviceSize';
import { LabelConfig, LabelConfigProvider, LabelConfigProviderProps } from '../LabelConfigProvider';

const labelStyle = {
  color: 'text',
  fontSize: 2,
  lineHeight: '18px',
  mt: '-1px',
  mr: 4,
  mb: 2,
};

export interface AuctionConfigLabelConfigProviderProps extends LabelConfigProviderProps {
  fieldNames: string[]
}

export const AuctionConfigLabelConfigProvider =
  ({ fieldNames, ...props }: AuctionConfigLabelConfigProviderProps) => {
    const { isExtraSmall, isSmall } = useDeviceSize();

    const labelStyles = useMemo(
      () => fromPairs(fieldNames.map(fieldName => [fieldName, labelStyle])),
      [fieldNames],
    );

    return (
      <LabelConfigProvider
        variant={isExtraSmall || isSmall ? LabelConfig.ABOVE : LabelConfig.LEFT}
        width="33%"
        gap={isExtraSmall || isSmall ? 3 : 4}
        style={labelStyles}
        {...props}
      />
    );
  };
