import { cloneDeep } from 'lodash';
import { assign, createMachine } from 'xstate';
import { States } from './States';
import { Actions } from './Actions';

export const twoStepSelectSupplierContext = {
  rfqId: undefined,
  companyId: undefined,
  suppliers: [],
  outcome: {
    suppliers: [],
    hasMessage: true,
    message: '',
    attachments: [],
  },
  inactive: {
    suppliers: [],
  },
};

const rejectBidsSteps = {
  [States.SUPPLIERS]: 'suppliersToReject',
  [States.REVIEW]: 'reviewAndConfirm',
};

export const defaultRejectBidsValue = {
  ...twoStepSelectSupplierContext,
  steps: rejectBidsSteps,
};

const reinstateRequestSteps = {
  [States.SUPPLIERS]: 'suppliersToReinstate',
  [States.REVIEW]: 'reviewAndConfirm',
};

export const defaultReinstateRequestValue = {
  ...twoStepSelectSupplierContext,
  steps: reinstateRequestSteps,
};

export const twoStepSelectSupplierMachine = createMachine<any>({
  id: 'twoStepSelectSupplierMachine',
  predictableActionArguments: true,
  initial: States.SUPPLIERS,
  // context: cloneDeep(defaultCloseRequestValue),
  states: {
    [States.SUPPLIERS]: {
      on: {
        [Actions.NEXT]: States.REVIEW,
      },
    },
    [States.REVIEW]: {
      on: {
        [Actions.BACK]: States.SUPPLIERS,
        [Actions.NEXT]: States.PROCESSING,
      },
    },
    [States.PROCESSING]: {
      invoke: {
        id: 'onConfirm',
        src: 'onConfirm',
        onDone: {
          target: States.SUCCESS,
        },
        onError: {
          target: States.FAILURE,
        },
      },
    },
    [States.SUCCESS]: {},
    [States.FAILURE]: {},
  },
  on: {
    [Actions.INIT]: {
      target: States.SUPPLIERS,
      actions: assign((_, event) => ({
        rfqId: event.rfqId,
        companyId: event.companyId,
        suppliers: event.activeSuppliers,
        outcome: {
          ...defaultRejectBidsValue.outcome,
          ...event.outcome,
          suppliers: event.selectedActiveSuppliers,
        },
        inactive: {
          ...defaultRejectBidsValue.inactive,
          suppliers: event.inactiveSuppliers,
        },
      })),
    },
    [Actions.RESET]: {
      target: States.SUPPLIERS,
      actions: assign((context) => cloneDeep({ twoStepSelectSupplierContext, steps: context.steps })),
    },
    [Actions.UPDATE_GROUP]: {
      actions: assign(
        (context, event) => ({
          [event.group]: { ...context[event.group], ...event.updatedProperties },
        }),
      ),
    },
  },
});
