import { useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';
import { ModelType } from './types';

export const ModelDeleted = ({
  model,
}: {
  model: ModelType;
}) => {
  const { t } = useTranslation(['translation', 'contracts', 'preQualification']);

  return model === 'request' ? (
    <Box width="100%">
      <Box as="h3" mt={3}>
        {t('request.deleted.heading')}
      </Box>
      <Box as="p" mt={2}>
        {t('request.deleted.body1')}
      </Box>
      <Box as="p" mt={2}>
        {t('request.deleted.body2')}
      </Box>
    </Box>
  ) : model === 'template' ? (
    <Box width="100%">
      <Box as="h3" mt={3}>
        {t('template.deleted.heading')}
      </Box>
      <Box as="p" mt={2}>
        {t('template.deleted.body1')}
      </Box>
      <Box as="p" mt={2}>
        {t('template.deleted.body2')}
      </Box>
    </Box>
  ) : model === 'contract' ? (
    <Box width="100%">
      <Box as="h3" mt={3}>
        {t('deleted.heading', { ns: 'contracts' })}
      </Box>
      <Box as="p" mt={2}>
        {t('deleted.body1', { ns: 'contracts' })}
      </Box>
      <Box as="p" mt={2}>
        {t('deleted.body2', { ns: 'contracts' })}
      </Box>
    </Box>
  ) : model === 'contractTemplate' ? (
    <Box width="100%">
      <Box as="h3" mt={3}>
        {t('templateDeleted.heading', { ns: 'contracts' })}
      </Box>
      <Box as="p" mt={2}>
        {t('templateDeleted.body1', { ns: 'contracts' })}
      </Box>
      <Box as="p" mt={2}>
        {t('templateDeleted.body2', { ns: 'contracts' })}
      </Box>
    </Box>
  ) : model === 'questionnaireTemplate' ? (
    <Box width="100%">
      <Box as="h3" mt={3}>
        {t('questionnaireTemplate.deleted.heading', { ns: 'preQualification' })}
      </Box>
      <Box as="p" mt={2}>
        {t('questionnaireTemplate.deleted.body1', { ns: 'preQualification' })}
      </Box>
      <Box as="p" mt={2}>
        {t('questionnaireTemplate.deleted.body2', { ns: 'preQualification' })}
      </Box>
    </Box>
  ) : model === 'supplierList' ? (
    <Box width="100%">
      <Box as="h3" mt={3}>
        {t('supplierLists.listDeleted.heading')}
      </Box>
      <Box as="p" mt={2}>
        {t('supplierLists.listDeleted.body1')}
      </Box>
      <Box as="p" mt={2}>
        {t('supplierLists.listDeleted.body2')}
      </Box>
    </Box>
  ) : (
    null
  );
};
