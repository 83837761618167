import * as React from 'react';
import { CellProps } from 'react-table';
import { Box, Flex } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { Popover, usePopover } from '@deepstream/ui-kit/elements/popup/usePopover';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';

const BORDER_WIDTH = 2;

export const ExpandableTruncateCell: React.FC<CellProps<any>> = ({
  cell,
  cellPaddingX,
}) => {
  const theme = useTheme();

  const textRef = React.createRef<HTMLDivElement>();
  const expandedRef = React.createRef<HTMLDivElement>();
  const [rowRef, setRowRef] = React.useState<HTMLDivElement>();

  const [isTextOverflowed, setIsTextOverflowed] = React.useState<boolean>();

  const [textHeight, setTextHeight] = React.useState<number>();
  const [rowHeight, setRowHeight] = React.useState<number>();
  const [rowWidth, setRowWidth] = React.useState<number>();

  const popover = usePopover({
    placement: 'top-start',
    strategy: 'fixed',
    overReferenceElement: true,
  });

  React.useLayoutEffect(() => {
    // @ts-expect-error ts(18048) FIXME: 'textRef.current.clientWidth' is possibly 'undefined'.
    if (textRef.current?.clientWidth < textRef.current?.scrollWidth) {
      setIsTextOverflowed(true);
    }
    // @ts-expect-error ts(18048) FIXME: 'textRef.current.clientHeight' is possibly 'undefined'.
    if (textRef.current?.clientHeight > 0) {
      setTextHeight(textRef.current?.clientHeight);
    }
  }, [textRef]);

  React.useLayoutEffect(() => {
    if (rowRef?.clientHeight) {
      setRowHeight(rowRef.clientHeight);
    }
    if (rowRef?.clientWidth) {
      setRowWidth(rowRef.clientWidth);
    }
  }, [rowRef?.clientHeight, rowRef?.clientWidth]);

  return (
    <>
      <Popover
        onClickOutside={() => popover.close()}
        {...popover}
      >
        <Box
          ref={expandedRef}
          sx={{
            // @ts-expect-error ts(18048) FIXME: 'rowWidth' is possibly 'undefined'.
            width: `${rowWidth + 2 * cellPaddingX}px`,
            // @ts-expect-error ts(18048) FIXME: 'rowHeight' is possibly 'undefined'.
            padding: `${(rowHeight - textHeight) / 2 - BORDER_WIDTH}px ${cellPaddingX}px`,
            marginLeft: `-${cellPaddingX}px`,
            backgroundColor: theme.colors.white,
            border: `${BORDER_WIDTH}px solid ${theme.colors.primary}`,
            boxShadow: '0px 4px 8px 0px rgba(8, 35, 48, 0.24)',
            wordWrap: 'break-word',
          }}
        >
          {cell.value}
        </Box>
      </Popover>
      <Flex
        alignItems="center"
        sx={{ columnGap: '4px', height: '100%' }}
        ref={(element: HTMLDivElement) => {
          setRowRef(element);
          popover.setReferenceElement(element);
        }}
      >
        <Truncate
          ref={textRef}
          sx={{
            ...(popover.isOpen && {
              visibility: 'hidden',
            }),
          }}
        >
          {cell.value}
        </Truncate>
        {isTextOverflowed && (
          <Icon
            icon="up-right-and-down-left-from-center"
            color="primary"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              if (popover.update) {
                popover.update();
              }
              popover.open();
            }}
          />
        )}
      </Flex>
    </>
  );
};
