// Auto-generated file created by svgr-cli source svgr-config/template.js
// Do not edit
import type { SVGProps } from 'react';

const SvgEmptyState = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={188}
    height={188}
    fill="none"
    {...props}
  >
    <path
      fill="#F7F9FB"
      stroke="#E2E8EF"
      strokeWidth={0.75}
      d="M184.893 94.274c0 50.035-40.804 90.601-91.143 90.601S2.607 144.309 2.607 94.274c0-50.036 40.804-90.602 91.143-90.602s91.143 40.566 91.143 90.602Z"
    />
    <path
      fill="#F7F9FB"
      stroke="#E2E8EF"
      strokeWidth={0.75}
      d="M93.75 185.625c50.749 0 91.893-40.897 91.893-91.351S144.499 2.922 93.75 2.922 1.857 43.82 1.857 94.274s41.144 91.351 91.893 91.351Z"
    />
    <mask
      id="empty-state_svg__a"
      width={184}
      height={184}
      x={2}
      y={2}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path
        fill="#F9FAFB"
        stroke="#DDE4EE"
        strokeWidth={0.75}
        d="M184.875 94c0 50.327-40.798 91.125-91.125 91.125S2.625 144.327 2.625 94 43.423 2.875 93.75 2.875 184.875 43.673 184.875 94Z"
      />
    </mask>
    <g mask="url(#empty-state_svg__a)">
      <path
        fill="#fff"
        stroke="#E2E8EF"
        strokeWidth={0.75}
        d="M41.76 97.149h103.98c1.604 0 3.04.441 4.064 1.136 1.026.696 1.606 1.618 1.606 2.589v86.104c0 .971-.58 1.893-1.606 2.589-1.024.695-2.46 1.136-4.064 1.136H41.76c-1.605 0-3.04-.441-4.065-1.136-1.026-.696-1.606-1.618-1.606-2.589v-86.104c0-.971.58-1.893 1.606-2.589 1.024-.695 2.46-1.136 4.064-1.136Z"
      />
    </g>
    <path
      fill="#E2E8EF"
      d="M81.16 111.197H49.422c-2.023 0-3.662 1.49-3.662 3.328s1.64 3.329 3.662 3.329H81.16c2.023 0 3.662-1.491 3.662-3.329s-1.64-3.328-3.662-3.328M103.461 124.51H49.443c-2.034 0-3.683 1.49-3.683 3.329 0 1.838 1.649 3.328 3.683 3.328h54.018c2.034 0 3.683-1.49 3.683-3.328 0-1.839-1.649-3.329-3.683-3.329M81.16 140.043H49.422c-2.023 0-3.662 1.242-3.662 2.774 0 1.531 1.64 2.773 3.662 2.773H81.16c2.023 0 3.662-1.242 3.662-2.773s-1.64-2.774-3.662-2.774M103.461 153.357H49.443c-2.034 0-3.683 1.49-3.683 3.328s1.649 3.328 3.683 3.328h54.018c2.034 0 3.683-1.49 3.683-3.328s-1.649-3.328-3.683-3.328"
    />
    <path
      fill="#3498DB"
      d="M146.221 40.19H41.281c-3.37 0-6.101 2.716-6.101 6.066v36.39c0 3.35 2.731 6.066 6.1 6.066h104.941c3.37 0 6.102-2.716 6.102-6.066v-36.39c0-3.35-2.732-6.065-6.102-6.065"
    />
    <path
      fill="#fff"
      d="M81.547 53.534H49.821c-2.022 0-3.66 1.63-3.66 3.64 0 2.009 1.638 3.638 3.66 3.638h31.726c2.022 0 3.66-1.63 3.66-3.639s-1.638-3.639-3.66-3.639M103.511 69.303h-53.69c-2.022 0-3.66 1.63-3.66 3.64s1.638 3.638 3.66 3.638h53.69c2.022 0 3.661-1.629 3.661-3.639s-1.639-3.639-3.661-3.639"
    />
  </svg>
);
export default SvgEmptyState;
