import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CollaboratorInviteStatus, Sender } from '@deepstream/common/rfq-utils';
import * as yup from 'yup';
import { isEmpty } from 'lodash';
import { Text } from 'rebass/styled-components';
import { EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelHeader, PanelPadding, PanelSubHeader } from '@deepstream/ui-kit/elements/Panel';
import * as rfx from '../../../rfx';
import * as draft from '../../../draft/draft';
import { Validation, useError } from '../../../draft/validation';
import { RequestRole, RequestTeamUser } from '../../../types';
import { ErrorMessage } from '../../../ui/ErrorMessage';
import { ReadOnlySenderTeamDetails } from '../Team/ReadOnlySenderTeamDetails';
import { RequestTeamUsersTable } from '../Team/RequestTeamUsersTable';

const RequestTeamUsers = ({ users }: { users: RequestTeamUser[] }) => {
  const { t } = useTranslation();
  const showValidationErrors = draft.useShowValidationErrors();
  const { error } = useError('users');

  return showValidationErrors && error ? (
    <PanelPadding bg="errorBackground" py="16px">
      <ErrorMessage fontSize={2} error={error} />
    </PanelPadding>
  ) : !isEmpty(users) ? (
    <RequestTeamUsersTable users={users} />
  ) : (
    <PanelPadding py="16px">
      <Text color="subtext">
        {t('teamManagement.noUsersAdded')}
      </Text>
    </PanelPadding>
  );
};

export const RequestTeamSection = ({
  sender,
  navigateToDraftTeam,
}: {
  sender: Sender;
  navigateToDraftTeam: (companyId: string) => any;
}) => {
  const { t } = useTranslation();
  const { isLive, isTemplate, isTemplatePreview } = rfx.useState();
  const { canEditTeam } = rfx.useRfxPermissions();
  const { teamById } = rfx.useStructure();
  const showValidationErrors = draft.useShowValidationErrors();

  const users = useMemo(
    () => Object.values(teamById[sender._id].users)
      .map(user => ({
        _id: user._id,
        name: user.name,
        email: user.email,
        requestRole: teamById[sender._id].owners.includes(user._id)
          ? RequestRole.OWNER
          : RequestRole.TEAM_MEMBER,
        companyId: sender._id,
      })),
    [teamById, sender],
  );

  return (
    <Validation
      values={{
        sender,
        users,
      }}
      schema={showValidationErrors ? (
        yup.object().shape({
          sender: yup.object().shape({
            company: yup.object().shape({
              role: yup.string().required(t('general.required')),
            }),
            inviteStatus: yup.string().oneOf([CollaboratorInviteStatus.ACCEPTED]),
          }),
          users: yup.array().of(yup.mixed()).min(1, t('request.review.errors.minimumOneUser')),
        })
      ) : (
        yup.mixed()
      )}
    >
      <Panel>
        <PanelHeader heading={`${t('general.team')} — ${sender.company.name}`} collapse={false}>
          {(canEditTeam && !isLive && !isTemplatePreview) && (
            <EditButton
              small
              variant="primary"
              onClick={() => navigateToDraftTeam(sender.company._id)}
            />
          )}
        </PanelHeader>
        <PanelDivider />
        <PanelSubHeader heading={t('general.details')} />
        <PanelDivider />
        <ReadOnlySenderTeamDetails sender={sender} />
        {!isTemplate && (
          <>
            <PanelDivider />
            <PanelSubHeader heading={t('general.user_other')} />
            <PanelDivider />
            <RequestTeamUsers users={users} />
          </>
        )}
      </Panel>
    </Validation>
  );
};
