import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Text, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { Bold } from '../../Bold';
import { useContractState } from './contract';

const ErrorPanel = styled(Panel)`
  position: relative;

  &:before {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    width: 5px;
    background-color: ${props => props.theme.colors.danger};
    border-radius: 4px 0 0 4px;
    content: '';
  }
`;

export const ContractErrorsPanel = ({ errors }: { errors: string[] }) => {
  const { t } = useTranslation('contracts');
  // @ts-expect-error ts(2339) FIXME: Property 'isAmending' does not exist on type 'ContractStateContextType | undefined'.
  const { isAmending, isRevising } = useContractState();

  return !isEmpty(errors) ? (
    <ErrorPanel padded>
      <Flex alignItems="center" sx={{ gap: 3 }}>
        <Icon icon="times-circle" color="danger" fontSize={3} fixedWidth />
        <Box>
          <Bold>
            {isRevising ? (
              t('review.issuesBeforeRevising')
            ) : isAmending ? (
              t('review.issuesBeforeAmending')
            ) : (
              t('review.issuesBeforeSending')
            )}
          </Bold>
          <Box as="ul" pl="20px">
            {errors.map((error, index) => <Text key={index} as="li">{error}</Text>)}
          </Box>
        </Box>
      </Flex>
    </ErrorPanel>
  ) : (
    null
  );
};
