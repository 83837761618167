import { AcceptDeclinePanel, useShowAcceptDeclinePanel } from './AcceptDeclinePanel';
import { DecisionPanel, useShowDecisionPanel } from './DecisionPanel';
import { QuestionnaireDocumentUpdatingPanel, useShowDocumentUpdatingPanel } from './QuestionnaireDocumentUpdatingPanel';
import { QuestionnairePendingReviewPanel, useShowPendingReviewPanel } from './QuestionnairePendingReviewPanel';
import { QuestionnaireProgressPanel, useShowProgressPanel } from './QuestionnaireProgressPanel';

export const QuestionnairePagePanels = () => {
  const showAcceptDeclinePanel = useShowAcceptDeclinePanel();
  const showProgressPanel = useShowProgressPanel();
  const showPendingReviewPanel = useShowPendingReviewPanel();
  const showDecisionPanel = useShowDecisionPanel();
  const showDocumentUpdatingPanel = useShowDocumentUpdatingPanel();

  return (
    <>
      {showAcceptDeclinePanel && (
        <AcceptDeclinePanel />
      )}
      {showProgressPanel && (
        <QuestionnaireProgressPanel />
      )}
      {showPendingReviewPanel && (
        <QuestionnairePendingReviewPanel />
      )}
      {showDecisionPanel && (
        <DecisionPanel />
      )}
      {showDocumentUpdatingPanel && (
        <QuestionnaireDocumentUpdatingPanel />
      )}
    </>
  );
};
