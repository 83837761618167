import { first, find, flatMap, map, remove, cloneDeep } from 'lodash';
import { Questionnaire, Category, Question, QuestionElement } from './types';

export class PreQQuestionnaire {
  questionnaire: Questionnaire;

  constructor(questionnaire) {
    this.questionnaire = questionnaire;
  }

  get categories() {
    return this.questionnaire.categories;
  }

  get questions() {
    return flatMap(this.categories, 'questions');
  }

  get numQuestions() {
    return this.questions.length;
  }

  get firstCategory() {
    return first(this.categories);
  }

  get firstCategoryId() {
    return this.firstCategory?.key;
  }

  getCategory(categoryId): Category {
    const category = find(this.categories, category => category.key === categoryId);

    if (!category) {
      throw new Error(`Could not find category with id ${categoryId}`);
    }

    return category;
  }

  getCategoryQuestions(categoryId): Question[] {
    return this.getCategory(categoryId).questions;
  }

  getQuestionCategory(questionId): Category | undefined {
    for (const category of this.categories) {
      if (find(category.questions, { key: questionId })) {
        return category;
      }
    }
  }

  getQuestion(categoryId, questionId): Question {
    const questions = this.getCategoryQuestions(categoryId);
    const question = find(questions, question => question.key === questionId);

    if (!question) {
      throw new Error(`Could not find question with id ${questionId} in the category ${categoryId}`);
    }

    return question;
  }

  getQuestionById(questionId) {
    const category = this.getQuestionCategory(questionId);
    return this.getQuestion(category?.key, questionId);
  }

  getQuestionElement(questionId, elementId): QuestionElement | undefined {
    return find(this.getQuestionById(questionId).elements, element => element.key === elementId);
  }

  getQuestions(categoryId, questionIds): Question[] {
    return map(questionIds, questionId => this.getQuestion(categoryId, questionId));
  }

  createSubset(questionIds): PreQQuestionnaire {
    const questionnaireCloned = cloneDeep(this.questionnaire);

    for (const category of questionnaireCloned.categories) {
      remove(category.questions, question => !questionIds.includes(question.key));
    }

    remove(questionnaireCloned.categories, category => !category.questions.length);

    return new PreQQuestionnaire(questionnaireCloned);
  }

  createSubsets(questionIdGroups) {
    return map(questionIdGroups, group => this.createSubset(group));
  }
}
