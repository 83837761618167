/* eslint-disable react-hooks/rules-of-hooks,@typescript-eslint/comma-dangle */
import { first, isBoolean, isEmpty, isFinite, isNil, noop, orderBy, pick } from 'lodash';
import { useCallback, useMemo } from 'react';
import * as React from 'react';
import { Box, Flex, Heading, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { NoLineItemsReason, RequestNotAccurateReason, RfxSpendAndSavings, TotalSavingsCalculationMethod } from '@deepstream/common/rfq-utils';
import { spendAndSavingsInitialState } from '@deepstream/common/rfq-utils/spendAndSavings';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { getTopLevelStates } from '@deepstream/utils/getTopLevelStates';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { withProps } from '@deepstream/ui-utils/withProps';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { ModalBody, ModalFooter } from '@deepstream/ui-kit/elements/popup/Modal';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import * as rfx from '../../../rfx';
import { useApi } from '../../../api';
import { Bold } from '../../../Bold';
import { Label } from '../../../form/Field';
import { ProcessingLoader } from '../../../ProcessingLoader';
import { useToaster } from '../../../toast';
import { FileList } from '../../../ui/FileList';
import { TextArea } from '../../../ui/Input';
import { MultiSelect } from '../../../ui/MultiSelect';
import { Switch } from '../../../ui/Switch';
import useDownload from '../../../useDownload';
import { uploadFile } from '../../../utils';
import { useMutation } from '../../../useMutation';
import { StepProgress } from '../../../ui/StepProgress';
import { LabelConfig, LabelConfigProvider } from '../../../LabelConfigProvider';
import { CurrencyCodeProvider } from '../../../ui/Currency';
import { SwitchFieldBase } from '../../../form/SwitchField';
import { BudgetFields } from '../Spend/BudgetPanel';
import { Actions } from './Actions';
import { States } from './States';
import { TotalSavingsFields } from '../Spend/TotalSavingsPanel';
import { useSavingsCalculationMethodSelectItems } from '../Spend/useSavingsCalculationMethodSelectItems';
import { FinalValueFields } from '../Spend/FinalValuePanel';

const getCompanyName = (company: { name?: string, company?: { name?: string } }) =>
  company?.name ?? company?.company?.name;

type FooterProps = {
  activeState: States;
  steps: any;
  onContinue: () => any;
  onBack?: () => any;
  isBackVisible?: boolean;
  areStepsVisible?: boolean;
  primaryButtonText?: string;
  primaryButtonVariant?: ButtonProps['variant'];
  isBackDisabled?: boolean;
  isNextDisabled?: boolean;
};

const Footer: React.FC<FooterProps> = ({
  activeState,
  steps,
  onContinue,
  onBack,
  isBackVisible = true,
  areStepsVisible = true,
  primaryButtonText,
  primaryButtonVariant = 'primary',
  isBackDisabled = false,
  isNextDisabled = false,
}) => {
  const { t } = useTranslation('general');
  const currentStepName = t(`modalStep.${steps[activeState]}`, { ns: 'translation' });
  const currentStepIndex = Object.keys(steps).indexOf(activeState);
  const stepsCount = Object.keys(steps).length;

  return (
    <ModalFooter justifyContent="space-between">
      <Box>
        {areStepsVisible && (
          <StepProgress
            currentStepIndex={currentStepIndex}
            totalStepCount={stepsCount}
            currentStepName={currentStepName}
          />
        )}
      </Box>
      <Flex>
        {isBackVisible && (
          <Button variant="secondary" onClick={onBack} mr={2} disabled={isBackDisabled}>{t('back')}</Button>
        )}
        <Button variant={primaryButtonVariant} onClick={onContinue} disabled={isNextDisabled}>
          {primaryButtonText || t('continue')}
        </Button>
      </Flex>
    </ModalFooter>
  );
};

const ContentWrapper = styled(Flex)`
  min-height: 400px;
`;

type MessageAndDocumentsProps = {
  state: any;
  send: any;
  group: any;
  canSelect: boolean;
  isBackVisible: boolean;
  suppliersLabel: string;
  sendMessageText: string;
  noSupplierMessage: string;
};

const MessageAndDocuments: React.FC<MessageAndDocumentsProps> = ({
  state,
  send,
  group,
  canSelect = false,
  suppliersLabel,
  sendMessageText,
  noSupplierMessage,
  isBackVisible = false,
}) => {
  const { t } = useTranslation();
  const { context } = state;
  const topLevelState = first(getTopLevelStates(state.value)) as States;
  const { hasMessage, message, attachments, suppliers } = context[group];
  const updateGroup = (updatedProperties: any) => send(Actions.UPDATE_GROUP, { group, updatedProperties });
  const toaster = useToaster();
  const api = useApi();
  const [upload] = useMutation(api.upload);
  const download = useDownload();

  const isNextDisabled = useMemo(
    () => (
      (hasMessage && !message && suppliers.length) ||
      (canSelect && !suppliers.length)
    ),
    [canSelect, hasMessage, message, suppliers],
  );

  const supplierNames = suppliers.length
    ? suppliers.map((supplier: any) => getCompanyName(supplier)).join(', ')
    : t('general.none');

  const updateFile = useCallback(
    async ({ file, onProgress }) => {
      const errorMessage = t('request.outcomeModal.errors.errorUploadingDocument');
      const purpose = 'rfq';
      const uploadedFile = await uploadFile(file, upload, context.companyId, purpose, onProgress, toaster, errorMessage);
      return uploadedFile;
    },
    [context.companyId, toaster, upload, t],
  );

  const downloadFile = useCallback(
    async (file) => {
      await download(file.url);
    },
    [download],
  );

  return (
    <>
      <ModalBody>
        <ContentWrapper flexDirection="column">
          <Box>
            <Label label={suppliersLabel || t('request.outcomeModal.suppliersToAward')} />
            {!canSelect ? (
              <Text>{supplierNames}</Text>
            ) : (
              <MultiSelect
                onChange={(selectedItems: any[] | null) => {
                  updateGroup({ suppliers: selectedItems });
                }}
                // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
                items={orderBy(context.suppliers, [supplier => getCompanyName(supplier).toLowerCase()], ['asc'])}
                // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
                itemToString={item => item ? getCompanyName(item) : ''}
                selectedItems={suppliers}
                buttonWidth="100%"
                withChips
                placeholder={t('request.outcomeModal.selectOneOrMoreSuppliers')}
                menuWidth={766}
                menuZIndex={202}
              />
            )}
          </Box>

          {!suppliers.length && !canSelect ? (
            <MessageBlock variant="info">
              {noSupplierMessage || t('request.outcomeModal.noSuppliersToAward')}
            </MessageBlock>
          ) : (
            <>
              <Flex mt={3}>
                <Text mr={3}>
                  {sendMessageText || t('request.outcomeModal.sendAMessageToAwardedSuppliers')}
                </Text>
                <Switch
                  checked={hasMessage}
                  name="sendMessage"
                  onChange={(checked: boolean) => updateGroup({ hasMessage: checked })}
                />
              </Flex>
              {hasMessage ? (
                <>
                  <Box mt={3}>
                    <Label label={t('general.message')} required={hasMessage}>
                      <TextArea
                        type="text"
                        height="150px"
                        defaultValue={message}
                        disabled={!hasMessage}
                        onChange={(event: any) => { updateGroup({ message: event.target.value }); }}
                      />
                    </Label>
                  </Box>
                  <Box mt={3} width="400px">
                    <Label label={t('request.outcomeModal.attachFiles')} />
                    <FileList
                      initialAttachments={attachments}
                      onChange={(uploadedFiles: any) => { updateGroup({ attachments: uploadedFiles }); }}
                      downloadFn={downloadFile}
                      uploadFn={updateFile}
                    />
                  </Box>
                </>
              ) : (
                <MessageBlock variant="warn">
                  {t('request.outcomeModal.warning')}
                </MessageBlock>
              )}
            </>
          )}
        </ContentWrapper>
      </ModalBody>
      <Footer
        steps={context.steps}
        activeState={topLevelState}
        isBackVisible={isBackVisible}
        onBack={() => { send(Actions.BACK); }}
        onContinue={() => { send(Actions.NEXT); }}
        isNextDisabled={isNextDisabled}
      />
    </>
  );
};

const truncateList = (list: string[], maxVisibleChars = 50) => {
  const itemsLength = list.map((item: any) => item.length);

  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < itemsLength.length; index++) {
    const size = itemsLength[index];
    maxVisibleChars -= size;
    if (maxVisibleChars < 0) {
      const hidden = list.splice(index + 1);
      return {
        visible: list,
        hidden,
      };
    }
  }

  return {
    visible: list,
    hidden: [],
  };
};

const SuppliersGroup: React.FC<any> = ({ suppliers = [], icon, text, hasError, maxVisibleChars = 50 }) => {
  const { visible, hidden } = truncateList(
    suppliers.map((supplier: any) => getCompanyName(supplier)),
    maxVisibleChars,
  );

  const { t } = useTranslation();
  const theme = useTheme();

  const getText = () => text instanceof Function ? text(t, suppliers.length) : text;

  const label = `${t('general.supplierCount', { count: suppliers.length })} ${getText()}`;

  return (
    <Flex alignItems="center" mb={3}>
      <Box width="35px">
        <Icon icon={icon} fontSize={4} color={theme.colors.subtext} />
      </Box>
      <Box style={{ flexGrow: 1 }}>
        <Bold>{label}</Bold>
        <Flex>
          {!suppliers.length ? (
            <Text color={theme.colors.subtext}>—</Text>
          ) : (
            <Text color={theme.colors.subtext} maxWidth="435px">
              <Truncate>{visible.join(', ')}</Truncate>
            </Text>
          )}
          {hidden.length > 0 && (
            <Text color={theme.colors.subtext} ml={1}>
              {t('invite.plusXMore', { count: hidden.length })}
            </Text>
          )}
        </Flex>
      </Box>
      {!isNil(hasError) && (
        <Box ml={3}>
          {!hasError ? (
            <Icon color={theme.colors.success} fontSize={4} icon="check-circle" />
          ) : (
            <Icon color={theme.colors.danger} fontSize={4} icon="exclamation-circle" />
          )}
        </Box>
      )}
    </Flex>
  );
};

const ToggleSpendAndSavings: React.FC<any> = React.memo(({ state: { context }, send, group }) => {
  const { t } = useTranslation();
  const updateGroup = (updatedProperties: any) => send(Actions.UPDATE_GROUP, { group, updatedProperties });

  return (
    <>
      <ModalBody maxWidth="800px">
        <ContentWrapper flexDirection="column">
          <Heading as="h3">
            {t('modalStep.toggleSpendAndSavings')}
          </Heading>
          <Text my={2}>
            {t('request.spendAndSavings.dialog.award.toggleDescription')}
          </Text>
          <SwitchFieldBase
            name="enabled"
            hideLabel
            aria-label={`${t('request.spendAndSavings.spendAndSavings')} - ${t('general.enabled')}?`}
            checkedIcon={false}
            uncheckedIcon={false}
            checkedText={t('general.enabled')}
            uncheckedText={t('general.disabled')}
            width={42}
            value={context[group]?.enabled}
            onChange={(enabled: boolean) => {
              if (enabled) {
                updateGroup({ enabled: true });
              } else {
                updateGroup({
                  ...spendAndSavingsInitialState,
                  enabled: false,
                });
              }
              send(Actions.UPDATE_STEPS);
            }}
          />
        </ContentWrapper>
      </ModalBody>
      <Footer
        steps={context.steps}
        activeState={States.TOGGLE_SPEND_AND_SAVINGS}
        onBack={() => {
          send(Actions.BACK);
        }}
        onContinue={() => {
          send(Actions.NEXT);
        }}
      />
    </>
  );
});

const VerticalSpacing = () => {
  return (
    <Box mt="12px" />
  );
};

const AwardRequestLabelConfigProvider = ({ children }) => {
  return (
    <LabelConfigProvider
      variant={LabelConfig.LEFT}
      width="325px"
      gap={2}
      style={{
        default: {
          fontSize: 2,
          marginBottom: 1,
          fontWeight: 'normal',
          color: 'text',
        },
      }}
    >
      {children}
    </LabelConfigProvider>
  );
};

const ConfirmBudget: React.FC<any> = React.memo(({ state: { context }, send, group }) => {
  const { t } = useTranslation();
  const { currencyCode } = rfx.useStructure();
  const { spendAndSavings } = context;
  const updateGroup = (updatedProperties: Partial<RfxSpendAndSavings>) =>
    send(Actions.UPDATE_GROUP, { group, updatedProperties });

  const submitValues = (values: Partial<RfxSpendAndSavings>) => {
    if (
      !isFinite(values.budgetedTotalValue) &&
      spendAndSavings.totalSavingsCalculationMethod === TotalSavingsCalculationMethod.BUDGET_FINAL_VALUE_DIFF
    ) {
      updateGroup({
        ...values,
        totalSavingsCalculationMethod: null,
        areTotalSavingsAccurate: null,
      });
    } else {
      updateGroup(values);
    }
  };

  const initialValues = {
    hasBudgetedTotalValue: spendAndSavings.hasBudgetedTotalValue,
    budgetedTotalValue: spendAndSavings.budgetedTotalValue,
  };

  return (
    <Formik
      validateOnBlur
      validateOnMount
      enableReinitialize
      initialValues={initialValues}
      validationSchema={yup.object().shape({
        budgetedTotalValue: yup.number()
          .nullable()
          .transform((curr, orig) => orig === '' ? null : curr)
          .when('hasBudgetedTotalValue', {
            is: hasBudgetedTotalValue => hasBudgetedTotalValue === true,
            then: schema => schema
              .required(t('general.required'))
              .moreThan(0, t('errors.greaterThan', { min: 0 })),
          }),
      })}
      onSubmit={noop}
    >
      {(props) => (
        <Form>
          <ModalBody maxWidth="800px">
            <AwardRequestLabelConfigProvider>
              <CurrencyCodeProvider code={currencyCode}>
                <ContentWrapper flexDirection="column">
                  <BudgetFields Padding={Box} Divider={VerticalSpacing} {...props as any} />
                </ContentWrapper>
              </CurrencyCodeProvider>
            </AwardRequestLabelConfigProvider>
          </ModalBody>
          <Footer
            steps={context.steps}
            activeState={States.CONFIRM_BUDGET}
            onBack={() => {
              submitValues(props.values);
              send(Actions.BACK);
            }}
            onContinue={() => {
              submitValues(props.values);
              send(Actions.NEXT);
            }}
            isNextDisabled={!props.isValid}
          />
        </Form>
      )}
    </Formik>
  );
});

const ConfirmValue: React.FC<any> = React.memo(({ state: { context }, send, group }) => {
  const { t } = useTranslation();
  const { currencyCode } = rfx.useStructure();
  const { spendAndSavings, calculatedData } = context;
  const updateGroup = (updatedProperties: Partial<RfxSpendAndSavings>) =>
    send(Actions.UPDATE_GROUP, { group, updatedProperties });

  const hasLineItemsWithTotalCost = rfx.useHasLineItemsWithTotalCost();

  const requestHasCalculatedTotalValue = isFinite(spendAndSavings.calculatedTotalValue);

  const calculatedTotalValue = requestHasCalculatedTotalValue
    ? spendAndSavings.calculatedTotalValue
    : calculatedData?.totalRequestValue;

  const hasCalculatedTotalValue = isFinite(calculatedTotalValue);

  const submitValues = (values: Partial<RfxSpendAndSavings>) => {
    const payload: Partial<RfxSpendAndSavings> = { ...values };
    // When the calculated total value has been confirmed as accurate/inaccurate
    // but is not yet stored in the request, include it in the update
    if (
      isBoolean(values.isCalculatedTotalValueAccurate) &&
      !requestHasCalculatedTotalValue &&
      isFinite(calculatedTotalValue)
    ) {
      payload.calculatedTotalValue = calculatedTotalValue;
    }

    if (isEmpty(payload.calculatedTotalValueNotAccurateReasons)) {
      payload.calculatedTotalValueNotAccurateReasons = null;
    }

    if (isEmpty(payload.noLineItemsReasons)) {
      payload.noLineItemsReasons = null;
    }

    const hasFinalValue = values.isCalculatedTotalValueAccurate === false
      ? isFinite(values.manualTotalValue)
      : isFinite(calculatedTotalValue);

    if (
      !hasFinalValue &&
      spendAndSavings.totalSavingsCalculationMethod === TotalSavingsCalculationMethod.BUDGET_FINAL_VALUE_DIFF
    ) {
      updateGroup({
        ...payload,
        totalSavingsCalculationMethod: null,
        areTotalSavingsAccurate: null,
      });
    } else {
      updateGroup(payload);
    }
  };

  const initialValues = React.useMemo(() => {
    const initialValues = pick(spendAndSavings, [
      'isCalculatedTotalValueAccurate',
      'canProvideManualTotalValue',
      'manualTotalValue',
      'calculatedTotalValueNotAccurateReasons',
      'calculatedTotalValueNotAccurateOtherReason',
      'noLineItemsReasons',
      'noLineItemsOtherReason',
      'cannotProvideManualTotalValueReason',
      'awardQuestionnaireComment'
    ]);

    if (!initialValues.calculatedTotalValueNotAccurateReasons) {
      initialValues.calculatedTotalValueNotAccurateReasons = [];
    }

    if (!initialValues.noLineItemsReasons) {
      initialValues.noLineItemsReasons = [];
    }

    return initialValues;
  }, [spendAndSavings]);

  return (
    <Formik
      validateOnBlur
      validateOnMount
      enableReinitialize
      initialValues={initialValues}
      validationSchema={yup.object().shape({
        isCalculatedTotalValueAccurate: hasCalculatedTotalValue
          ? yup.boolean().required(t('general.required'))
          : yup.boolean().nullable(),
        canProvideManualTotalValue: hasCalculatedTotalValue
          ? yup.boolean()
            .nullable()
            .when('isCalculatedTotalValueAccurate', {
                is: false,
                then: schema => schema.required(t('general.required')),
            })
          : yup.boolean().required(t('general.required')),
        calculatedTotalValueNotAccurateReasons: hasCalculatedTotalValue
          ? yup.array()
            .nullable()
            .when('isCalculatedTotalValueAccurate', {
              is: false,
              then: schema => schema.required(t('general.required')),
            })
          : yup.array(),
        calculatedTotalValueNotAccurateOtherReason: yup.string()
          .nullable()
          .when('calculatedTotalValueNotAccurateReasons', {
            is: (value: any) => value?.includes(RequestNotAccurateReason.OTHER),
            then: schema => schema.required(t('general.required')),
          }),
        noLineItemsReasons: !hasCalculatedTotalValue && !hasLineItemsWithTotalCost
          ? yup.array()
            .nullable()
            .required(t('general.required'))
          : yup.array(),
        noLineItemsOtherReason: yup.string()
          .nullable()
          .when('noLineItemsReasons', {
            is: (value: any) => value?.includes(NoLineItemsReason.OTHER),
            then: schema => schema.required(t('general.required')),
          }),
        manualTotalValue: yup.number()
          .transform((curr, orig) => orig === '' ? null : curr)
          .nullable()
          .when('canProvideManualTotalValue', {
            is: true,
            then: schema => schema
              .moreThan(0, t('errors.greaterThan', { min: 0 }))
              .required(t('general.required')),
          }),
        cannotProvideManualTotalValueReason: yup.string()
          .nullable()
          .when('canProvideManualTotalValue', {
            is: false,
            then: schema => schema.required(t('general.required')),
          }),
      })}
      onSubmit={noop}
    >
      {(props) => (
        <Form>
          <ModalBody maxWidth="800px">
            <AwardRequestLabelConfigProvider>
              <CurrencyCodeProvider code={currencyCode}>
                <ContentWrapper flexDirection="column">
                  <FinalValueFields
                    calculatedTotalValue={calculatedTotalValue}
                    Padding={Box}
                    Divider={VerticalSpacing}
                    isModal
                    hasLineItemsWithTotalCost={hasLineItemsWithTotalCost}
                    {...props as any}
                  />
                </ContentWrapper>
              </CurrencyCodeProvider>
            </AwardRequestLabelConfigProvider>
          </ModalBody>
          <Footer
            steps={context.steps}
            activeState={States.CONFIRM_VALUE}
            onBack={() => {
              submitValues(props.values);
              send(Actions.BACK);
            }}
            onContinue={() => {
              submitValues(props.values);
              send(Actions.NEXT);
            }}
            isNextDisabled={!props.isValid}
          />
        </Form>
      )}
    </Formik>
  );
});

const ConfirmSavings: React.FC<any> = React.memo(({ state: { context }, send, group }) => {
  const { t } = useTranslation();
  const { currencyCode } = rfx.useStructure();
  const { spendAndSavings, calculatedData } = context;
  const updateGroup = (updatedProperties: Partial<RfxSpendAndSavings>) =>
    send(Actions.UPDATE_GROUP, { group, updatedProperties });

  const requestHasCalculatedSavingsByType = Boolean(spendAndSavings.calculatedSavingsByType);
  const requestHasCalculatedTotalValue = isFinite(spendAndSavings.calculatedTotalValue);

  const calculatedTotalValue = requestHasCalculatedTotalValue
    ? spendAndSavings.calculatedTotalValue
    : calculatedData?.totalRequestValue;

  const calculatedSavingsByType = requestHasCalculatedSavingsByType
    ? spendAndSavings.calculatedSavingsByType
    : calculatedData?.calculatedSavingsByType || {};

  const methodSelectItems = useSavingsCalculationMethodSelectItems(
    spendAndSavings,
    calculatedTotalValue,
    calculatedSavingsByType,
  );

  const submitValues = (values: Partial<RfxSpendAndSavings>) => {
    const payload: Partial<RfxSpendAndSavings> = { ...values };
    // When specific savings are available but not yet stored in
    // the request, include it in the update
    if (!requestHasCalculatedSavingsByType && calculatedSavingsByType) {
      payload.calculatedSavingsByType = calculatedSavingsByType;
    }

    updateGroup(payload);
  };

  const initialValues = React.useMemo(() => {
    const initialValues = pick(spendAndSavings, [
      'canProvideTotalSavings',
      'cannotProvideTotalSavingsReason',
      'totalSavingsCalculationMethod',
      'manualTotalSavings',
      'manualTotalSavingsDescription',
      'areTotalSavingsAccurate',
    ]);

    return spendAndSavings.totalSavingsCalculationMethod === null
      ? {
        ...initialValues,
        totalSavingsCalculationMethod: methodSelectItems[0].value,
      }
      : initialValues;
  }, [spendAndSavings, methodSelectItems]);

  return (
    <Formik
      validateOnBlur
      validateOnMount
      enableReinitialize
      initialValues={initialValues}
      validationSchema={yup.object().shape({
        canProvideTotalSavings: yup.boolean().required(t('general.required')),
        cannotProvideTotalSavingsReason: yup.string()
          .nullable()
          .when('canProvideTotalSavings', {
            is: false,
            then: schema => schema.required(t('general.required')),
          }),
        totalSavingsCalculationMethod: yup.string()
          .nullable()
          .when('canProvideTotalSavings', {
            is: true,
            then: schema => schema.required(t('general.required')),
          }),
        manualTotalSavings: yup.number()
          .transform((curr, orig) => orig === '' ? null : curr)
          .nullable()
          .when('totalSavingsCalculationMethod', {
            is: TotalSavingsCalculationMethod.MANUAL,
            then: schema => schema
              .moreThan(0, t('errors.greaterThan', { min: 0 }))
              .required(t('general.required')),
          }),
        manualTotalSavingsDescription: yup.string()
          .nullable()
          .when('totalSavingsCalculationMethod', {
            is: TotalSavingsCalculationMethod.MANUAL,
            then: schema => schema.required(t('general.required')),
          }),
        areTotalSavingsAccurate: yup.boolean()
          .nullable()
          .when('canProvideTotalSavings', {
            is: true,
            then: schema => schema.required(t('general.required')),
          }),
      })}
      onSubmit={noop}
    >
      {(props) => (
        <Form>
          <ModalBody maxWidth="800px">
            <AwardRequestLabelConfigProvider>
              <CurrencyCodeProvider code={currencyCode}>
                <ContentWrapper flexDirection="column">
                  <TotalSavingsFields
                    methodSelectItems={methodSelectItems}
                    spendAndSavings={spendAndSavings}
                    calculatedTotalValue={calculatedTotalValue}
                    calculatedSavingsByType={calculatedSavingsByType}
                    Padding={Box}
                    Divider={VerticalSpacing}
                    showSpecificSavingsTooltips
                    {...props as any}
                  />
                </ContentWrapper>
              </CurrencyCodeProvider>
            </AwardRequestLabelConfigProvider>
          </ModalBody>
          <Footer
            steps={context.steps}
            activeState={States.CONFIRM_SAVINGS}
            onBack={() => {
              submitValues(props.values);
              send(Actions.BACK);
            }}
            onContinue={() => {
              submitValues(props.values);
              send(Actions.NEXT);
            }}
            isNextDisabled={!props.isValid}
          />
        </Form>
      )}
    </Formik>
  );
});

const Review: React.FC<any> = ({ state: { context }, send, isProcessing, message, messageVariant = 'warn', groups = [] }) => {
  const { t } = useTranslation('general');

  return (
    <>
      <ModalBody>
        <ContentWrapper alignItems="center" justifyContent="center">
          {isProcessing ? (
            <ProcessingLoader />
          ) : (
            <Box>
              {groups.map((group) => (
                <SuppliersGroup
                  key={group.key}
                  suppliers={context[group.key].suppliers}
                  icon={group.icon} text={group.text}
                />
              ))}
              {message && (
                <Flex justifyContent="center">
                  <MessageBlock variant={messageVariant} mt={4} maxWidth="300px">
                    {message}
                  </MessageBlock>
                </Flex>
              )}
            </Box>
          )}
        </ContentWrapper>
      </ModalBody>
      <Footer
        steps={context.steps}
        activeState={States.REVIEW}
        onBack={() => { send(Actions.BACK); }}
        onContinue={() => { send(Actions.NEXT); }}
        isNextDisabled={isProcessing}
        isBackDisabled={isProcessing}
        primaryButtonVariant="success"
        primaryButtonText={t('confirm')}
      />
    </>
  );
};

const Outcome: React.FC<any> = ({ state: { context }, onClose, hasError, groups }) => {
  const { t } = useTranslation();

  return (
    <>
      <ModalBody>
        <ContentWrapper alignItems="center" justifyContent="center">
          <Box>
            {groups.map((group) => (
              <SuppliersGroup
                key={group.key}
                suppliers={context[group.key].suppliers}
                icon={group.icon} text={group.text}
                hasError={hasError}
              />
            ))}

            {hasError && (
              <MessageBlock variant="info" mt={4}>
                {t('request.outcomeModal.pleaseContactCustomerSupport')}
              </MessageBlock>
            )}
          </Box>
        </ContentWrapper>
      </ModalBody>
      <Footer
        steps={context.steps}
        activeState={States.REVIEW}
        isBackVisible={false}
        areStepsVisible={false}
        onContinue={() => { onClose(true); }}
        primaryButtonText={t('general.done')}
      />
    </>
  );
};

export const useAwardStateToComponent = () => {
  const { t } = useTranslation();

  return React.useMemo(() => ({
    [States.AWARDED_SUPPLIERS]: withProps(MessageAndDocuments, {
      group: 'awarded',
      canSelect: true,
      isBackVisible: false,
      noSupplierMessage: t('request.outcomeModal.noSuppliersToAward'),
    }),
    [States.UNSUCCESSFUL_SUPPLIERS]: withProps(MessageAndDocuments, {
      group: 'unsuccessful',
      canSelect: false,
      suppliersLabel: t('request.outcomeModal.suppliersToReject'),
      sendMessageText: t('request.outcomeModal.sendAMessageToRejectedSuppliers'),
      noSupplierMessage: t('request.outcomeModal.noSuppliersToReject'),
      isBackVisible: true,
    }),
    [States.TOGGLE_SPEND_AND_SAVINGS]: withProps(ToggleSpendAndSavings, {
      group: 'spendAndSavings',
    }),
    [States.CONFIRM_BUDGET]: withProps(ConfirmBudget, {
      group: 'spendAndSavings',
    }),
    [States.CONFIRM_VALUE]: withProps(ConfirmValue, {
      group: 'spendAndSavings',
    }),
    [States.CONFIRM_SAVINGS]: withProps(ConfirmSavings, {
      group: 'spendAndSavings',
    }),
    [States.REVIEW]: withProps(Review, {
      groups: [
        { key: 'awarded', icon: 'trophy', text: (t: TFunction, count: number) => t('request.outcomeModal.awarded', { count }) },
        { key: 'unsuccessful', icon: 'times', text: (t: TFunction, count: number) => t('request.outcomeModal.rejected', { count }) },
        {
          key: 'inactive',
          icon: 'times',
          text: (t: TFunction, count: number) => t('request.outcomeModal.inactiveBid', { count }),
        },
      ],
      isProcessing: false,
      message: t('request.outcomeModal.onceConfirmedNoActions'),
    }),
    [States.PROCESSING]: withProps(Review, {
      isProcessing: true,
    }),
    [States.SUCCESS]: withProps(Outcome, {
      hasError: false,
      groups: [
        { key: 'awarded', icon: 'trophy', text: (t: TFunction, count: number) => t('request.outcomeModal.awarded', { count }) },
        { key: 'unsuccessful', icon: 'times', text: (t: TFunction, count: number) => t('request.outcomeModal.rejected', { count }) },
        {
          key: 'inactive',
          icon: 'times',
          text: (t: TFunction, count: number) => t('request.outcomeModal.pastInactiveBid', { count }),
        },
      ],
    }),
    [States.FAILURE]: withProps(Outcome, {
      hasError: true,
      groups: [
        { key: 'awarded', icon: 'trophy', text: (t: TFunction, count: number) => t('request.outcomeModal.couldNotBeAwarded', { count }) },
        { key: 'unsuccessful', icon: 'times', text: (t: TFunction, count: number) => t('request.outcomeModal.couldNotBeRejected', { count }) },
        {
          key: 'inactive',
          icon: 'times',
          text: (t: TFunction, count: number) => t('request.outcomeModal.pastInactiveBid', { count }),
        },
      ],
    }),
  } as Record<any, any>), [t]);
};

export const useCloseRequestStateToComponent = () => {
  const { t } = useTranslation();

  return React.useMemo(() => ({
    [States.SUPPLIERS]: withProps(MessageAndDocuments, {
      group: 'outcome',
      suppliersLabel: t('request.outcomeModal.suppliersToReject'),
      sendMessageText: t('request.outcomeModal.sendAMessageToRejectedSuppliers'),
      noSupplierMessage: t('request.outcomeModal.noSuppliersToReject'),
      isBackVisible: false,
    }),
    [States.REVIEW]: withProps(Review, {
      isProcessing: false,
      message: t('request.outcomeModal.onceConfirmedNoActions'),
      groups: [
        { key: 'outcome', icon: 'times', text: (t: TFunction, count: number) => t('request.outcomeModal.willBeRejected', { count }) },
        {
          key: 'inactive',
          icon: 'times',
          text: (t: TFunction, count: number) => t('request.outcomeModal.inactiveBid', { count }),
        },
      ],
    }),
    [States.PROCESSING]: withProps(Review, { isProcessing: true }),
    [States.SUCCESS]: withProps(Outcome, {
      hasError: false,
      groups: [
        { key: 'outcome', icon: 'times', text: (t: TFunction, count: number) => t('request.outcomeModal.rejected', { count }) },
        {
          key: 'inactive',
          icon: 'times',
          text: (t: TFunction, count: number) => t('request.outcomeModal.pastInactiveBid', { count }),
        },
      ],
    }),
    [States.FAILURE]: withProps(Outcome, {
      hasError: true,
      groups: [
        { key: 'outcome', icon: 'times', text: (t: TFunction, count: number) => t('request.outcomeModal.couldNotBeRejected', { count }) },
        {
          key: 'inactive',
          icon: 'times',
          text: (t: TFunction, count: number) => t('request.outcomeModal.pastInactiveBid', { count }),
        },
      ],
    }),
  } as Record<any, any>), [t]);
};

export const useRejectBidsStateToComponent = () => {
  const { t } = useTranslation();

  return React.useMemo(() => ({
    [States.SUPPLIERS]: withProps(MessageAndDocuments, {
      group: 'outcome',
      suppliersLabel: t('request.outcomeModal.suppliersToReject'),
      sendMessageText: t('request.outcomeModal.sendAMessageToRejectedSuppliers'),
      noSupplierMessage: t('request.outcomeModal.noSuppliersToReject'),
      isBackVisible: false,
      canSelect: true,
    }),
    [States.REVIEW]: withProps(Review, {
      groups: [{ key: 'outcome', icon: 'times', text: (t: TFunction, count: number) => t('request.outcomeModal.willBeRejected', { count }) }],
      isProcessing: false,
      messageVariant: 'info',
      message: t('request.outcomeModal.rejectedSuppliersWillNotBeNotified'),
    }),
    [States.PROCESSING]: withProps(Review, { isProcessing: true }),
    [States.SUCCESS]: withProps(Outcome, {
      hasError: false,
      groups: [{ key: 'outcome', icon: 'times', text: (t: TFunction, count: number) => t('request.outcomeModal.rejected', { count }) }],
    }),
    [States.FAILURE]: withProps(Outcome, {
      hasError: true,
      groups: [{ key: 'outcome', icon: 'times', text: (t: TFunction, count: number) => t('request.outcomeModal.couldNotBeRejected', { count }) }],
    }),
  } as Record<any, any>), [t]);
};

export const useReinstateBidsStateToComponent = () => {
  const { t } = useTranslation();

  return React.useMemo(() => ({
    [States.SUPPLIERS]: withProps(MessageAndDocuments, {
      group: 'outcome',
      suppliersLabel: t('request.outcomeModal.suppliersToReinstate'),
      sendMessageText: t('request.outcomeModal.sendAMessageToReinstatedSuppliers'),
      noSupplierMessage: t('request.outcomeModal.noSuppliersToReinstate'),
      isBackVisible: false,
      canSelect: true,
    }),
    [States.REVIEW]: withProps(Review, {
      groups: [{ key: 'outcome', icon: 'redo', text: (t: TFunction, count: number) => t('request.outcomeModal.willBeReinstated', { count }) }],
      isProcessing: false,
      messageVariant: 'info',
      message: t('request.outcomeModal.reinstatedSuppliersWillBeNotified'),
    }),
    [States.PROCESSING]: withProps(Review, { isProcessing: true }),
    [States.SUCCESS]: withProps(Outcome, {
      hasError: false,
      groups: [{ key: 'outcome', icon: 'redo', text: (t: TFunction, count: number) => t('request.outcomeModal.reinstated', { count }) }],
    }),
    [States.FAILURE]: withProps(Outcome, {
      hasError: true,
      groups: [{ key: 'outcome', icon: 'redo', text: (t: TFunction, count: number) => t('request.outcomeModal.couldNotBeReinstated', { count }) }],
    }),
  } as Record<any, any>), [t]);
};
