import { useField } from 'formik';
import { LineItemExchangeDefinition } from '@deepstream/common/rfq-utils';
import { useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { useMemo } from 'react';

export type EnableMultiStageResponsesSectionErrors = {
  responsePerStageRequired?: boolean;
  twoStagesRequired?: boolean;
  oneLineItemRequired?: boolean;
};

export const useEnableMultiStageResponsesSectionErrors = (): EnableMultiStageResponsesSectionErrors => {
  const [{ value: responseTagConfig }] = useField({ name: 'section.responseTagConfig' });
  const [, { error: tagsError }] = useField({ name: 'section.responseTagConfig.tags' });
  const { rowData } = useEditableGridData<LineItemExchangeDefinition>();

  const errors = useMemo(() => {
    return {
      responsePerStageRequired: !responseTagConfig,
      twoStagesRequired: Boolean(tagsError),
      oneLineItemRequired: rowData.length === 0,
    };
  }, [responseTagConfig, rowData.length, tagsError]);

  return errors;
};
