import { useMemo, useCallback } from 'react';
import * as React from 'react';
import { useField } from 'formik';
import { Box, Text } from 'rebass/styled-components';
import { RadioQuestionElement } from '@deepstream/common/legacy-pre-q-utils';
import { Trans, useTranslation } from 'react-i18next';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { GuardedRadioGroup } from '../ui/Radio';
import { ElementProps, Modify, ContainerLabel, LockedAnswer, NoAnswer } from './common';

const MemoizedRadioGroup = React.memo(GuardedRadioGroup);

MemoizedRadioGroup.displayName = 'MemoRadioGroup';

export const RadioElement: React.FC<Modify<ElementProps, { element: RadioQuestionElement; variant: 'stacked' | 'inline' }>> = ({
  name,
  element,
  answer,
  error,
  isReadOnly,
  isLocked,
  hideLabel,
  guardedValues,
  dependentElements,
  variant,
}) => {
  const { t } = useTranslation(['companyProfile', 'general']);
  const [field, , formik] = useField({ name });

  const handleChange = useCallback(
    value => {
      if (value !== field.value) {
        formik.setValue(value);
      }
    },
    [field, formik],
  );

  const options = React.useMemo(() => {
    return element.options.map(option => ({
      ...option,
      key: option.value,
      label: t(`element.radio.option.${option.value}`),
    }));
  }, [element, t]);

  const selectedOption = useMemo(
    () => options.find((option: any) => option.value === answer as any)!,
    [options, answer],
  );

  const getDialogBody = useCallback(
    ({ label = '' } = {}) => (
      <Stack gap={2}>
        <Box>
          <Trans i18nKey="element.radio.dialogMessage" values={{ label }}>
            {' '}
            <Text as="span" fontWeight={500} />
            {' '}
          </Trans>
        </Box>
        <Stack as="ul" gap={1} p={0} style={{ listStylePosition: 'inside' }}>
          {dependentElements?.map(element => (
            <Box key={element.key} as="li">
              {element.label || (element.labelId ? t(`element.label.${element.labelId}`) : '')}
            </Box>
          ))}
        </Stack>
      </Stack>
    ),
    [dependentElements, t],
  );

  return (
    <ContainerLabel
      name={name}
      label={element.label || (element.labelId ? t(`element.label.${element.labelId}`) : '')}
      hideLabel={hideLabel}
      showAsterisk={element.validation?.required}
    >
      {isReadOnly ? (
        answer ? (
          isLocked ? (
            <LockedAnswer />
          ) : (
            selectedOption ? (
              selectedOption.value === 'yes' ? (
                <IconText gap={2} icon="check" text={selectedOption.label} />
              ) : selectedOption.value === 'no' ? (
                <IconText gap={2} icon="times" text={selectedOption.label} />
              ) : (
                <>{selectedOption.label}</>
              )
            ) : (
              // This should never happen because every answer
              // should correspond to a radio option
              answer
            )
          )
        ) : (
          <NoAnswer />
        )
      ) : (
        <>
          <MemoizedRadioGroup
            invertGuards
            name={name}
            inputs={options}
            disabled={element.disabled}
            guardedValues={guardedValues}
            heading={t('element.radio.removeRelatedAnswers')}
            body={getDialogBody}
            okButtonText={t('delete', { ns: 'general' })}
            okButtonVariant="danger"
            defaultValue={field.value}
            onChange={handleChange}
            variant={variant}
          />
          {error}
        </>
      )}
    </ContainerLabel>
  );
};
