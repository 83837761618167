import { useMemo, useCallback } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelHeader } from '@deepstream/ui-kit/elements/Panel';
import { copyToClipboard } from '@deepstream/ui-utils/clipboard';
import * as rfx from '../../rfx';
import { useExternalPublicRequestUrl } from './usePublicRequestUrl';
import { RequestStatus } from '../../RequestStatus';
import { RequestVisibilityStatus } from './RequestVisibilityStatus';
import { useToaster } from '../../toast';
import { useApi, wrap } from '../../api';
import { useRfqId } from '../../useRfq';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { PublicBidIntentionStatus } from '../../types';
import { Loading } from '../../ui/Loading';
import { ErrorMessage } from '../../ui/ErrorMessage';

const VisibilityMetrics = ({
  title,
  count,
  isLoading,
  isError,
}: {
  title: string;
  count: number;
  isLoading: boolean;
  isError: boolean;
}) => {
  const { t } = useTranslation('general');

  return (
    <Box px={3}>
      <Text fontSize={1} fontWeight={500} mb={1}>
        {title}
      </Text>
      {isLoading ? (
        <Loading />
      ) : isError ? (
        <ErrorMessage error={t('error')} />
      ) : (
        <Text>{count}</Text>
      )}
    </Box>
  );
};

export const RequestVisibility = (
) => {
  const { t } = useTranslation('translation');
  const toaster = useToaster();
  const api = useApi();
  const externalPublicRequestUrl = useExternalPublicRequestUrl();

  const structure = rfx.useStructure();
  const {
    settings: { isPubliclyAvailable },
    extendedStatus,
  } = structure;

  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId();

  const {
    data: publicBidIntentions,
    isLoading,
    isError,
  } = useQuery(
    [
      'publicBidIntentions',
      {
        rfqId,
        currentCompanyId,
      },
    ],
    wrap(api.getPublicBidIntentions),
  );

  const { totalItentionsToBid, totalSuppliersAdded } = useMemo(() => {
    let totalItentionsToBid = 0;
    let totalSuppliersAdded = 0;

    if (publicBidIntentions) {
      totalItentionsToBid = publicBidIntentions.length;
      totalSuppliersAdded = publicBidIntentions
        .filter((intention) => intention.status === PublicBidIntentionStatus.APPROVED)
        .length;
    }

    return {
      totalItentionsToBid,
      totalSuppliersAdded,
    };
  },
  [publicBidIntentions]);

  const copyLink = useCallback(() => {
    copyToClipboard(externalPublicRequestUrl);
    toaster.success(t('request.intention.publicRequestLinkCopySuccess'));
  }, [externalPublicRequestUrl, toaster, t]);

  return (
    <Panel mb={20}>
      <PanelHeader
        heading={
          <Flex flexDirection="row" sx={{ columnGap: '32px' }}>
            <div>{t('visibility', { ns: 'general' })}</div>
          </Flex>
        }
        icon="eye"
        iconProps={{ regular: true }}
        justifyContent="space-between"
        flex="1"
      >
        <Flex sx={{ columnGap: '16px' }} alignItems="center">
          <Flex sx={{ columnGap: '8px' }}>
            <RequestVisibilityStatus
              isPubliclyAvailable={isPubliclyAvailable}
              px={3}
            />
            {/*
             // @ts-expect-error ts(2322) FIXME: Type 'RfqStatus | null' is not assignable to type 'RfqStatus'. */}
            {isPubliclyAvailable && <RequestStatus status={extendedStatus} px={3} />}
            <VisibilityMetrics title={t('request.publicBidIntentions.intentionsToBid')} count={totalItentionsToBid} isLoading={isLoading} isError={isError} />
            <VisibilityMetrics title={t('request.publicBidIntentions.suppliersAdded')} count={totalSuppliersAdded} isLoading={isLoading} isError={isError} />
          </Flex>
          {isPubliclyAvailable && (
            <Flex sx={{ columnGap: '8px' }}>
              <Button
                small
                iconLeft="link"
                variant="secondary-outline"
                onClick={copyLink}
              >
                {t('copyLink', { ns: 'general' })}
              </Button>
              <Button
                small
                iconLeft="link"
                variant="secondary-outline"
                onClick={() => window.open(externalPublicRequestUrl, '_blank')}
              >
                {t('viewPage', { ns: 'general' })}
              </Button>
            </Flex>
          )}
        </Flex>
      </PanelHeader>
    </Panel>
  );
};

export const RequestLiveVisibility = () => {
  const isSender = rfx.useIsSender();

  return isSender ? <RequestVisibility /> : null;
};
