import { Checkbox, CheckboxProps } from '@deepstream/ui-kit/elements/input/Checkbox';
import { RequestTag } from '../../ui/types';
import RequestTagLabel from './RequestTagLabel';
import { useRequestTagsContext } from './RequestTagsContext';

type RequestTagCheckboxProps = {
  tag: RequestTag;
} & Omit<CheckboxProps, 'ref'>;

const RequestTagCheckbox = ({ tag, ...checkboxProps }: RequestTagCheckboxProps) => {
  const { tagById } = useRequestTagsContext();

  return (
    <Checkbox
      key={tag._id}
      label={<RequestTagLabel tag={tag} tagById={tagById} />}
      {...checkboxProps}
      style={{ ...checkboxProps.style, maxWidth: '500px', minWidth: '300px' }}
    />
  );
};

export default RequestTagCheckbox;
