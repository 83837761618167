import { Draft, Page, hasSupplierPriceField, isLinkedEvaluationSection } from '@deepstream/common/rfq-utils';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { flatten, has, isEmpty, map, omit, pickBy, set } from 'lodash';
import { RefObject, createRef, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';
import { Box, Text } from 'rebass/styled-components';
import { v4 as uuid } from 'uuid';
import { MultiStageLineItemsEventType } from '@deepstream/common';
import { scrollToTop } from '@deepstream/ui-utils/scrollToTop';
import { LineItemsSectionEditPanel, LineItemsSectionEditPanelRef } from '../../../../draft/LineItemsSectionEditPanel';
import * as draft from '../../../../draft/draft';
import { EnableMultiStageResponsesSectionErrors } from '../../../../draft/useEnableMultiStageResponsesSectionErrors';
import * as rfx from '../../../../rfx';
import { FormErrorsLayout } from '../../../../ui/MultiStepFlow/FormErrors';
import { useMultiStepFlowData } from '../../../../ui/MultiStepFlow/MultiStepFlowContext';
import { StepNavigationBackLink } from '../../../../ui/MultiStepFlow/StepNavigation';
import { Direction } from '../../../../ui/MultiStepFlow/types';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import {
  SetupMultiStageResponsesFlowData,
  SetupMultiStageResponsesFlowStepType,
} from '../types';
import { useTrackSetupMultiStageResponsesEvent } from '../tracking';
import { useModalState } from '../../../../ui/useModalState';
import { ModelSizeLimitDialog, ModelSizeLimitMessages } from '../../../../ModelSizeLimitDialog';
import { getSizeRelevantExchangeDefCount, useModelSizeLimits } from '../../../../modelSizeLimits';
import { SaveSectionState } from '../../../../draft/draft';

type SectionsState = {
  errorsBySectionId: Record<string, EnableMultiStageResponsesSectionErrors>;
  panelRefsBySectionId: Record<string, RefObject<LineItemsSectionEditPanelRef>>;
};

const initialState = {
  errorsBySectionId: {},
  panelRefsBySectionId: {},
};

enum ActionType {
  SECTION_ADDED = 'section-added',
  SECTION_DELETED = 'section-deleted',
  SECTION_ERRORS_CHANGED = 'section-errors-changed',
}

type Action =
  | { type: ActionType.SECTION_ADDED; sectionId: string; panelRef: RefObject<LineItemsSectionEditPanelRef> }
  | { type: ActionType.SECTION_DELETED; sectionId: string }
  | {
      type: ActionType.SECTION_ERRORS_CHANGED;
      sectionId: string;
      errors: EnableMultiStageResponsesSectionErrors;
    };

const sectionsReducer = (state: SectionsState, action: Action): SectionsState => {
  switch (action.type) {
    case ActionType.SECTION_ADDED: {
      const { sectionId, panelRef } = action;
      return {
        ...state,
        panelRefsBySectionId: {
          ...state.panelRefsBySectionId,
          [sectionId]: panelRef,
        },
      };
    }
    case ActionType.SECTION_DELETED: {
      const { sectionId } = action;

      return {
        ...state,
        panelRefsBySectionId: omit(state.panelRefsBySectionId, sectionId),
        errorsBySectionId: omit(state.errorsBySectionId, sectionId),
      };
    }
    case ActionType.SECTION_ERRORS_CHANGED: {
      const { sectionId, errors } = action;
      return {
        ...state,
        errorsBySectionId: {
          ...state.errorsBySectionId,
          [sectionId]: errors,
        },
      };
    }
    default:
      return state;
  }
};

const SectionContent = ({
  sectionId,
  panelRef,
  onSectionErrorsChange,
  onSectionDeleted,
}: {
  sectionId: string;
  panelRef?: RefObject<LineItemsSectionEditPanelRef>;
  onSectionErrorsChange: (
    sectionId: string,
    errors: EnableMultiStageResponsesSectionErrors,
  ) => void;
  onSectionDeleted: (sectionId: string) => void;
}) => {
  const onEnableMultiStageResponsesErrorsChange = useCallback((errors: EnableMultiStageResponsesSectionErrors) => {
    onSectionErrorsChange(sectionId, errors);
  }, [onSectionErrorsChange, sectionId]);

  return (
    <LineItemsSectionEditPanel
      onEnableMultiStageResponsesErrorsChange={onEnableMultiStageResponsesErrorsChange}
      panelRef={panelRef}
      onSectionDeleted={onSectionDeleted}
      isEnableMultiStageResponsesFlow
      showDeleteButton
    />
  );
};

const PageContent = ({
  page,
  onNewSectionAdded,
  onSectionDeleted,
  onSectionErrorsChange,
  panelRefsBySectionId,
}: {
  page: Page;
  onNewSectionAdded: (sectionId: string, panelRef: RefObject<LineItemsSectionEditPanelRef>) => void;
  onSectionDeleted: (sectionId: string) => void;
  onSectionErrorsChange: (sectionId: string, errors: EnableMultiStageResponsesSectionErrors) => void;
  panelRefsBySectionId: Record<string, RefObject<LineItemsSectionEditPanelRef>>;
}) => {
  const { t } = useTranslation('translation');
  const intercom = useIntercom();

  const structure = rfx.useStructure<Draft>();

  const isMutationLoading = draft.useIsMutationLoading();
  const [addLineItemsSection] = draft.useAddLineItemsSection();
  const addSection = useCallback(() => {
    const sectionId = uuid();

    addLineItemsSection(
        {
          _id: sectionId,
        },
        {
          onSuccess: () => {
            const newRef = createRef<LineItemsSectionEditPanelRef>();
            onNewSectionAdded(sectionId, newRef);
            intercom.trackEvent('edit-line-items-section-opened');
          },
        },
      );
    },
    [intercom, addLineItemsSection, onNewSectionAdded],
  );

  return (
    <lotPagesLayout.Subsection2 heading={page.name}>
      <Button
        variant="secondary"
        small
        iconLeft="plus"
        onClick={addSection}
        disabled={isMutationLoading}
        mb={20}
      >
        {t(
          'request.setupMultiStageResponsesFlow.steps.addSections.addLineItemsSection',
        )}
      </Button>
      <Stack gap={20}>
        {Object.entries(panelRefsBySectionId).map(([sectionId, panelRef]) => {
          return (
            <rfx.SectionProvider
              section={structure.sectionById[sectionId]}
              key={sectionId}
            >
              <SectionContent
                sectionId={sectionId}
                onSectionErrorsChange={onSectionErrorsChange}
                onSectionDeleted={onSectionDeleted}
                panelRef={panelRef}
              />
            </rfx.SectionProvider>
          );
        })}
      </Stack>
    </lotPagesLayout.Subsection2>
  );
};

export const AddSections = () => {
  const { t } = useTranslation('translation');

  const structure = rfx.useStructure<Draft>();
  const generalPages = draft.useGeneralDraftPages();
  const isMutationLoading = draft.useIsMutationLoading();

  const { submitAndNavigate } = useMultiStepFlowData<
    SetupMultiStageResponsesFlowStepType,
    SetupMultiStageResponsesFlowData
  >();

  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [showValidationErrors, setShowValidationErrors] = useState<boolean>();
  const [state, dispatch] = useReducer(sectionsReducer, initialState);
  const { errorsBySectionId, panelRefsBySectionId } = state;

  const { maxExchangeDefCount } = useModelSizeLimits();
  const modelSizeLimitModal = useModalState();
  const [modelSizeLimitMessages, setModelSizeLimitMessages] = useState<ModelSizeLimitMessages | null>(null);

  const { exchangeDefById, sectionById } = structure;
  const [saveSections] = draft.useSaveLineItemsSections({ hideSuccessToaster: true });

  const getOtherSectionFieldLabelChanges = draft.useGetOtherSectionFieldLabelChanges();

  const handleSubmit = useCallback(() => {
    setModelSizeLimitMessages(null);

    const totalExchangeDefCount = getSizeRelevantExchangeDefCount(exchangeDefById);
    let previousExchangeDefsCount = 0;
    let newExchangeDefsCount = 0;
    let factoredAddedCount = 0;
    const changes : SaveSectionState[] = [];

    Object.values(panelRefsBySectionId).forEach((ref) => {
      const formRef = ref.current?.formRef?.current;
      const lineItemExchangeDefs = ref.current?.lineItemExchangeDefs;

      if (!formRef || !lineItemExchangeDefs) {
        return;
      }

      const { section, currencyExchangeDef } = formRef.values;
      const exchangeDefs = rfx.getSectionExchangeDefs(section, structure);
      const previousSectionExchangeDefCount = getSizeRelevantExchangeDefCount(exchangeDefs);
      const newSectionExchangeDefCount = getSizeRelevantExchangeDefCount(lineItemExchangeDefs);
      const addedCount = newSectionExchangeDefCount - previousSectionExchangeDefCount;
      const isSectionLinkedFromEvaluation = Object.values(sectionById).some(
        (section) =>
          isLinkedEvaluationSection(section) &&
          section.linkedSectionId === section._id,
      );
      const factor = isSectionLinkedFromEvaluation ? 2 : 1;

      previousExchangeDefsCount += previousSectionExchangeDefCount;
      newExchangeDefsCount += newSectionExchangeDefCount;
      factoredAddedCount += addedCount * factor;

      const adjustedCurrencyExchangeDef = { ...currencyExchangeDef };
      if (
        adjustedCurrencyExchangeDef.currencies &&
        (isEmpty(lineItemExchangeDefs) ||
          !hasSupplierPriceField(lineItemExchangeDefs[0].fields))
      ) {
        adjustedCurrencyExchangeDef.currencies =
          adjustedCurrencyExchangeDef.currencies.slice(0, 1);
      }

      if (adjustedCurrencyExchangeDef.currencies?.length === 1) {
        adjustedCurrencyExchangeDef.isFixed = true;
      } else {
        adjustedCurrencyExchangeDef.isFixed = false;
      }

      changes.push({
        section,
        exchangeDefs: [
          adjustedCurrencyExchangeDef,
          // Make sure the exchange definitions have the same stage visibility as the section
          ...map(lineItemExchangeDefs, (exchangeDef) =>
            set(exchangeDef, 'stages', section.stages),
          ),
        ],
        extraChanges: getOtherSectionFieldLabelChanges(
          section._id,
          lineItemExchangeDefs[0]?.fields,
        ),
      });
    });

    if (
      newExchangeDefsCount > previousExchangeDefsCount &&
      totalExchangeDefCount + factoredAddedCount > maxExchangeDefCount
    ) {
      setModelSizeLimitMessages({
        heading: t('request.dialog.requestSizeLimit.heading'),
        title: t(
          'request.dialog.requestSizeLimit.saveLineItemsSections.title',
        ),
        warning: t(
          'request.dialog.requestSizeLimit.saveLineItemsSections.warning',
        ),
        body: t('request.dialog.requestSizeLimit.saveLineItemsSections.body', {
          count:
            totalExchangeDefCount + factoredAddedCount - maxExchangeDefCount,
        }),
      });
      modelSizeLimitModal.open();
      return null;
    } else {
      return saveSections(changes);
    }
  }, [
    exchangeDefById,
    panelRefsBySectionId,
    getOtherSectionFieldLabelChanges,
    maxExchangeDefCount,
    modelSizeLimitModal,
    saveSections,
    sectionById,
    structure,
    t,
  ]);

  const onNewSectionAdded = useCallback((sectionId: string, panelRef: RefObject<LineItemsSectionEditPanelRef>) => {
    dispatch({ type: ActionType.SECTION_ADDED, sectionId, panelRef });
  }, [dispatch]);

  const onSectionDeleted = useCallback((sectionId: string) => {
    dispatch({ type: ActionType.SECTION_DELETED, sectionId });
  }, [dispatch]);

  const onSectionErrorsChange = useCallback((sectionId: string, errors: EnableMultiStageResponsesSectionErrors) => {
    dispatch({ type: ActionType.SECTION_ERRORS_CHANGED, sectionId, errors });
  }, [dispatch]);

  const onBackClick = useCallback(
    () => submitAndNavigate(null, Direction.BACK),
    [submitAndNavigate],
  );

  const trackFlowEvent = useTrackSetupMultiStageResponsesEvent({
    eventType: MultiStageLineItemsEventType.ADD_SECTIONS_STEP_COMPLETED,
  });
  const onContinueClick = useCallback(async () => {
    setShowValidationErrors(false);
    await handleSubmit();
    setShowValidationErrors(true);
    if (isEmpty(errorMessages) && isEmpty(modelSizeLimitMessages)) {
      await trackFlowEvent();
      submitAndNavigate({ addedSectionIds: Object.keys(panelRefsBySectionId) }, Direction.FORWARD);
    } else if (isEmpty(modelSizeLimitMessages)) {
      scrollToTop('smooth');
    }
  },
  [panelRefsBySectionId, errorMessages, submitAndNavigate, handleSubmit, modelSizeLimitMessages, trackFlowEvent],
  );

  const getSectionErrors = useCallback((sectionId: string) => {
    const sectionName = structure.sectionById[sectionId].name || t('general.untitled');
    const { responsePerStageRequired, twoStagesRequired, oneLineItemRequired } = errorsBySectionId[sectionId];

    const errors = [];
    if (responsePerStageRequired) {
      errors.push(t('request.setupMultiStageResponsesFlow.steps.addSections.errors.responsePerStageRequiredFull', { sectionName }));
    }
    if (twoStagesRequired) {
      errors.push(t('request.setupMultiStageResponsesFlow.steps.addSections.errors.twoStagesRequiredFull', { sectionName }));
    }
    if (oneLineItemRequired) {
      errors.push(t('request.setupMultiStageResponsesFlow.steps.addSections.errors.oneLineItemRequiredFull', { sectionName }));
    }
    return errors;
  }, [errorsBySectionId, structure.sectionById, t]);

  useEffect(() => {
    // Iterage using pages to render sections errors
    // in the same order as they appear on the pages
    const allErrors = flatten(
      generalPages.map((page) => {
        return flatten(
          page.sections
            // Filter the newly added pages
            .filter((sectionId) => has(errorsBySectionId, sectionId))
            .map((sectionId) => getSectionErrors(sectionId)),
        );
      }),
    );

    setErrorMessages(allErrors);
  }, [
    errorsBySectionId,
    panelRefsBySectionId,
    generalPages,
    getSectionErrors,
    structure,
  ]);

  const hasChanges = useMemo(() => {
    return !isEmpty(panelRefsBySectionId);
  }, [panelRefsBySectionId]);

  const panelRefsBySectionIdByPageId = useMemo((): Record<
    string,
    Record<string, RefObject<LineItemsSectionEditPanelRef>>
  > => {
    return generalPages.reduce((result, page) => {
      const currentPageSectionIds = page.sections.filter((sectionId) =>
        has(panelRefsBySectionId, sectionId),
      );

      result[page._id] = pickBy(panelRefsBySectionId, (_, sectionId) =>
        currentPageSectionIds.includes(sectionId),
      );

      return result;
    }, {});
  }, [generalPages, panelRefsBySectionId]);

  return (
    <>
      <lotPagesLayout.ContentWrapper2 maxWidth="initial" overflow="initial">
        <StepNavigationBackLink onClick={onBackClick} />
        {showValidationErrors && <FormErrorsLayout errors={errorMessages} /> }
        <Box maxWidth={lotPagesLayout.DEFAULT_SECTION_WIDTH}>
          <lotPagesLayout.Section2
            heading={t('request.setupMultiStageResponsesFlow.steps.addSections.heading')}
          >
            <Text mb="6px">
              {t('request.setupMultiStageResponsesFlow.steps.addSections.description1')}
            </Text>
            <Text>
              {t('request.setupMultiStageResponsesFlow.steps.addSections.description2')}
            </Text>
          </lotPagesLayout.Section2>
        </Box>
        {generalPages.map((page) => (
          <rfx.PageProvider key={page._id} page={page}>
            <PageContent
              key={page._id}
              page={page}
              onNewSectionAdded={onNewSectionAdded}
              onSectionDeleted={onSectionDeleted}
              onSectionErrorsChange={onSectionErrorsChange}
              panelRefsBySectionId={panelRefsBySectionIdByPageId[page._id]}
            />
          </rfx.PageProvider>
        ))}
        <Box>
          <Button
            onClick={onContinueClick}
            disabled={isMutationLoading}
            sx={{ alignSelf: 'flex-start' }}
          >
            {hasChanges
              ? t('toolbar.saveAndContinue', { ns: 'request' })
              : t('continue', { ns: 'general' })}
          </Button>
        </Box>
      </lotPagesLayout.ContentWrapper2>
      <ModelSizeLimitDialog
        modal={modelSizeLimitModal}
        messages={modelSizeLimitMessages}
      />
    </>
  );
};
