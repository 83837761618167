import { useState } from 'react';
import { ReadOnlyGrid } from '@deepstream/ui-kit/grid/EditableGrid/ReadOnlyGrid';
import { DefaultEditableGridStyles } from '@deepstream/ui-kit/grid/EditableGrid/EditableGridStyles';
import { useSortedGridData } from '@deepstream/ui-kit/grid/EditableGrid/useSortedGridData';
import { SortConfigProvider } from '@deepstream/ui-kit/grid/EditableGrid/sortConfig';
import { NonVirtualGrid } from '@deepstream/ui-kit/grid/core/NonVirtualGrid';
import { RenderHoverableTableDataCell } from '@deepstream/ui-kit/grid/EditableGrid/RenderHoverableTableDataCell';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';

export const RequestMessagesGrid = ({
  data,
  columns,
  onRowClick,
}: {
  data,
  columns,
  onRowClick,
}) => {
  const theme = useTheme();

  const [sortConfig, setSortConfig] = useState({
    columnId: null,
    direction: null,
  });

  const sortedData = useSortedGridData(sortConfig, data, columns);

  return (
    <DefaultEditableGridStyles
      style={{ height: 'auto' }}
      isReadOnly
      highlightOnHover
      cellContentCss="align-items: flex-start; padding: 10px"
      activeCellContentCss="padding: 8px"
      cellContentWithPrefixCss="padding-top: 0;padding-bottom: 0;padding-left:0;"
      activeCellContentWithPrefixCss="padding-top: 0;padding-bottom: 0;padding-left: 10px;padding-right: 0;"
      cellContentWithSuffixCss="padding-top: 0;padding-bottom: 0;padding-right:0;padding-left: 0"
      activeCellContentWithSuffixCss="padding-top: 0;padding-bottom: 0;padding-right: 0;padding-left: 0;"
      suffixCss="align-items: flex-start;padding-top: 11px; padding-bottom: 11px; flex: 0 0 auto;"
      activeSuffixCss="align-items: flex-start;padding-top: 9px; padding-bottom: 9px; flex: 0 0 auto;"
      overrides={`[role=gridcell] { background-color: inherit } tbody > tr { background-color: white } tbody > tr:hover { background-color: ${theme.colors.lightPrimary4} }`}
      omitLastRowBorderBottom
    >
      <SortConfigProvider sortConfig={sortConfig} setSortConfig={setSortConfig}>
        <ReadOnlyGrid
          columns={columns}
          rowData={sortedData}
          RenderDataCell={RenderHoverableTableDataCell}
          GridComponent={NonVirtualGrid}
          canScrollHorizontally
          onRowClick={onRowClick}
        />
      </SortConfigProvider>
    </DefaultEditableGridStyles>
  );
};
