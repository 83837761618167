import { useRef, useCallback, useLayoutEffect } from 'react';
import { useWatchValueLayout } from '@deepstream/ui-kit/hooks/useWatchValue';

export const useScrollToBottom = () => {
  const ref = useRef<HTMLElement>();

  const scrollToBottom = useCallback(
    () => {
      if (ref.current) {
        ref.current.scrollTop = ref.current.scrollHeight;
      }
    },
    [],
  );

  useLayoutEffect(
    () => {
      if (ref.current) {
        // HACK: sometimes we need to delay the scrolling because there might be some
        // pending UI recalculations (e.g. the Modal component recalculates its height
        // after the initial rendering and it affects the scroll position)
        setTimeout(() => {
          if (!ref.current) return;
          ref.current.scrollTop = ref.current.scrollHeight;
        }, 0);
      }
    },
    [ref],
  );

  return [ref, scrollToBottom];
};

export const useScrollToBottomOnChange = (value, isEqual?) => {
  const [bodyRef, scrollToBottom] = useScrollToBottom();

  useWatchValueLayout(value, scrollToBottom, isEqual);

  return bodyRef;
};
