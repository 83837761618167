import { useTranslation } from 'react-i18next';
import { omitBy, isNil } from 'lodash';
import * as yup from 'yup';
import { Text } from 'rebass/styled-components';
import { ModalForm } from '../../ModalForm';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { RadioField } from '../../form/RadioField';
import useDownload from '../../useDownload';
import { useCurrentUserLocale } from '../../useCurrentUser';
import { useMutation } from '../../useMutation';

const useDownloadReport = ({ rfqId }) => {
  const { t } = useTranslation();
  const download = useDownload({
    successMessage: t('messagesReport.reportIsBeingGenerated', { ns: 'request' }),
    errorMessage: t('messagesReport.reportGenerationError', { ns: 'request' }),
  });
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const locale = useCurrentUserLocale();

  const queryParams = new URLSearchParams(
    omitBy({
      locale,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }, isNil) as Record<string, string>,
  );

  return useMutation(
    (report: string) => download(`/download/company/${currentCompanyId}/rfq/${rfqId}/${report}?${queryParams.toString()}`),
  );
};

export const MessagesReportModal = ({
  rfqId,
  isOpen,
  onCancel,
}) => {
  const { t } = useTranslation();

  const [downloadReport] = useDownloadReport({
    rfqId,
  });

  return (
    <ModalForm
      heading={t('messagesReport.title', { ns: 'request' })}
      initialValues={{
        type: 'consolidated',
      }}
      validationSchema={
        yup.object().shape({
          type: yup.string().oneOf(['consolidated', 'separate']).required(t('general.required')),
        })
      }
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={({ type }) => downloadReport(`messages-report/${type}`)}
      disableSubmitIfNotDirty={false}
      disableSubmitIfInvalid={false}
      submitLabel={t('messagesReport.downloadReport', { ns: 'request' })}
      style={{ content: { width: '500px' } }}
    >
      <Text>
        {t('messagesReport.description', { ns: 'request' })}
      </Text>

      <RadioField
        label={t('messagesReport.reportFormat', { ns: 'request' })}
        fieldLabelStyle={{ textTransform: 'uppercase', fontSize: 2, color: '#7E8890' }}
        name="type"
        options={[
          {
            value: 'consolidated',
            label: t('messagesReport.consolidatedTitle', { ns: 'request' }),
            description: t('messagesReport.consolidatedDescription', { ns: 'request' }),
          },
          {
            value: 'separate',
            label: t('messagesReport.separateTitle', { ns: 'request' }),
            description: t('messagesReport.separateDescription', { ns: 'request' }),
          },
        ]}
        gap={3}
      />
    </ModalForm>
  );
};
