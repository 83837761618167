import { useTranslation } from 'react-i18next';
import { Flex, FlexProps } from 'rebass/styled-components';
import { Draft } from '@deepstream/common/rfq-utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { StructureStage } from './types';

export const StageVisibility = ({ stage, ...props }: FlexProps & { stage: StructureStage<Draft> }) => {
  const { t } = useTranslation();

  return (
    <Flex alignItems="center" {...props}>
      <Icon
        icon={stage.isPrivate ? 'eye-slash' : 'eye'}
        regular
        fontSize={1}
        mr={1}
        color="subtext"
      />
      {stage.isPrivate ? (
        t('request.stages.hidden')
      ) : (
        t('request.stages.visible')
      )}
    </Flex>
  );
};
