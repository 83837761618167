import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LotIntentionStatus } from '@deepstream/common/rfq-utils';
import { IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { iconPropsByLotIntentionStatus } from '../Live/iconPropsByLotIntentionStatus';

export type LotIntentionStatusFilter = {
  value: LotIntentionStatus;
  label: string;
  iconProps: {
    icon: IconProps['icon'];
    color: string;
    regular?: boolean;
  };
};

export const useLotIntentionStatusFilterItems = (): LotIntentionStatusFilter[] => {
  const { t } = useTranslation('translation');

  return useMemo(() => {
    return [
      LotIntentionStatus.BIDDING,
      LotIntentionStatus.NO_RESPONSE,
      LotIntentionStatus.BID_WITHDRAWN,
      LotIntentionStatus.DECLINED_TO_BID,
    ].map(lotIntentionStatus => ({
      value: lotIntentionStatus,
      label: t(`request.lots.lotIntentionStatus.${lotIntentionStatus}.status`),
      iconProps: iconPropsByLotIntentionStatus.buyer[lotIntentionStatus],
    }));
  }, [t]);
};
