import { first, groupBy, mapValues, pickBy } from 'lodash';
import { AwardDecision, AwardScenario, ExchangeType, Live, LotIntentionStatus, SavingsBySavingsType, SavingsCalculationResultByRecipientIdBySavingsType } from '@deepstream/common/rfq-utils';
import { useMemo } from 'react';
import { calculateSavings, getSavingsCalculationResultByRecipientIdBySavingsType, setSplitDecisionAwardedCosts } from '@deepstream/common/rfq-utils/spendAndSavings';
import * as rfx from '../../../rfx';
import { AwardFlowData } from './types';

export const useCalculatedSavingsByType = (data: AwardFlowData): SavingsCalculationResultByRecipientIdBySavingsType => {
  const { exchangeDefById, sectionById, bidById } = rfx.useStructure<Live>();
  const { costAndSavingsByRecipientId } = rfx.useCostAndSavingsData();

  return useMemo(() => {
    switch (data.awardScenario) {
      case AwardScenario.REQUEST_LEVEL_AWARD: {
        return getSavingsCalculationResultByRecipientIdBySavingsType([
          calculateSavings(
            costAndSavingsByRecipientId,
            [data.requestAward!.recipientId],
          ),
        ]);
      }
      case AwardScenario.LINE_LEVEL_AWARD: {
        const { awardDecisionByExchangeId, splitDecisionByExchangeId } = data;

        // @ts-expect-error ts(2769) FIXME: No overload matches this call.
        const specificSavingsPerLineItem: SavingsBySavingsType[] = Object.entries(awardDecisionByExchangeId)
          // @ts-expect-error ts(18047) FIXME: 'awardDecision' is possibly 'null'.
          .filter(([, awardDecision]) => awardDecision.value === 'award')
          .map(([exchangeId, awardDecision]) => {
            const splitDecision = splitDecisionByExchangeId?.[exchangeId];

            if (splitDecision) {
              const recipientIds = Object.keys(splitDecision.decisionByRecipientId);

              const savings = calculateSavings(
                costAndSavingsByRecipientId,
                recipientIds,
                [exchangeId],
              );

              const savingsWithSplitDecisionAwardedCosts = setSplitDecisionAwardedCosts(savings, splitDecision.decisionByRecipientId);

              return savingsWithSplitDecisionAwardedCosts;
            } else {
              // when the line item award is not split, the awarded amount in the award decision
              // matches `costAndSavingsByRecipientId`, so we can just calculate the savings
              // as usual.
              // @ts-expect-error ts(2339) FIXME: Property 'recipientId' does not exist on type 'Award | undefined'.
              const { recipientId } = first((awardDecision as Extract<AwardDecision, { value: 'award' }>).awards);

              return calculateSavings(
                costAndSavingsByRecipientId,
                [recipientId],
                [exchangeId],
              );
            }
          });

        return getSavingsCalculationResultByRecipientIdBySavingsType(specificSavingsPerLineItem);
      }
      case AwardScenario.LOT_LEVEL_AWARD: {
        const lineItemExchangeDefs = Object.values(exchangeDefById)
          .filter(exchangeDef => (
            !exchangeDef.isObsolete &&
            [ExchangeType.LINE_ITEM, ExchangeType.AUCTION_LINE_ITEM].includes(exchangeDef.type)
          ));

        // we don't access `section.exchangeDefIds` for this because it doesn't
        // include IDs of supplier-provided exchanges, which we want to include
        // here
        const lineItemExchangeDefsByLotId = groupBy(
          lineItemExchangeDefs,
          // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
          exchangeDef => first(sectionById[exchangeDef.sectionId]?.lotIds),
        );

        // @ts-expect-error ts(2769) FIXME: No overload matches this call.
        const specificSavingsPerLot: SavingsBySavingsType[] = Object.entries(data.awardDecisionByLotId)
          // @ts-expect-error ts(18047) FIXME: 'awardDecision' is possibly 'null'.
          .filter(([, awardDecision]) => awardDecision.value === 'award')
          .map(([lotId, awardDecision]) => {
            // @ts-expect-error ts(2339) FIXME: Property 'recipientId' does not exist on type 'Award | undefined'.
            const { recipientId } = first((awardDecision as Extract<AwardDecision, { value: 'award' }>).awards);

            const lotIntentionStatusByRecipientId = mapValues(
              bidById,
              bid => bid.intentionStatusByLotId[lotId] || LotIntentionStatus.NO_RESPONSE,
            );
            const exchangeIdsWithTotalCost = (lineItemExchangeDefsByLotId[lotId] || [])
              // @ts-expect-error ts(18048) FIXME: 'exchangeDef.fields' is possibly 'undefined'.
              .filter(exchangeDef => exchangeDef.fields.totalCost)
              .map(exchangeDef => exchangeDef._id);

            return calculateSavings(
              pickBy(
                costAndSavingsByRecipientId,
                (_, recipientId) => lotIntentionStatusByRecipientId[recipientId] === LotIntentionStatus.BIDDING,
              ),
              [recipientId],
              exchangeIdsWithTotalCost,
            );
          });

        return getSavingsCalculationResultByRecipientIdBySavingsType(specificSavingsPerLot);
      }
      default:
        throw new Error(`Cannot calculate savings for award scenario ${data.awardScenario}`);
    }
  }, [data, costAndSavingsByRecipientId, exchangeDefById, sectionById, bidById]);
};
