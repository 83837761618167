import { AuctionBid, Company } from '@deepstream/common/rfq-utils';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, FlexProps, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { CompanyLogo } from '../../../CompanyLogo';
import * as rfx from '../../../rfx';
import { CurrencyAmount } from '../../../ui/Currency';
import { generateColor, lighten30, sortByName } from '../../../utils';

const LeaderboardTitle = () => {
  const { t } = useTranslation();

  return (
    <Box as="h2" fontSize={4} color="navy" mb="20px" fontWeight={500}>
      {t('request.auction.leaderboard')}
    </Box>
  );
};

const LeaderboardList = styled(Box)`
  border: ${props => props.theme.borders.lightGray2};
  padding: 0;
  overflow-y: auto;
  width: 100%;
  max-height: 100%;

  & > li {
    padding: 10px 16px;
    font-size: ${props => props.theme.fontSizes[1]}px;
    border-bottom: ${props => props.theme.borders.lightGray2};

    &:last-child {
      border-bottom-width: 0;
    }

    &.disabled {
      opacity: 0.4;
    }

    &:hover:not(.disabled) {
      background-color: ${props => props.theme.colors.lightGray3};
    }
  }
`;

const Rank = ({ rank, bidderId, ...props }: { rank: number; bidderId: string } & FlexProps) => {
  const { t } = useTranslation();
  const color = generateColor(bidderId);
  const backgroundColor = lighten30(color);

  return (
    <Flex
      fontWeight={500}
      justifyContent="center"
      alignItems="center"
      sx={{ width: '38px', height: '23px', borderRadius: '4px' }}
      color={color}
      backgroundColor={backgroundColor}
      {...props}
    >
      {t('request.auction.bidFeedback.ordinal', { count: rank, ordinal: true })}
    </Flex>
  );
};

const LeaderboardItem = ({
  bid,
  recipient,
  rank,
  ...props
}: {
  bid?: AuctionBid;
  recipient: Company;
  rank?: number;
} & FlexProps) => {
  const recipientId = bid?.bidderId || recipient._id;
  const isLeadBid = rank === 1;
  const disabled = !bid;

  return (
    <Flex
      as="li"
      flexDirection="row"
      className={clsx({ disabled })}
      {...props}
    >
      <Box sx={{ width: '54px' }}>
        {bid && (
          // @ts-expect-error ts(2322) FIXME: Type 'number | undefined' is not assignable to type 'number'.
          <Rank rank={rank} bidderId={bid.bidderId} />
        )}
      </Box>
      <CompanyLogo companyId={recipientId} size="xs" />
      <Box ml="3px" sx={{ width: '168px' }}>
        <Text color="text" fontSize={isLeadBid ? 4 : undefined}>
          <Truncate>
            {recipient.company.name}
          </Truncate>
        </Text>
        <Text color="subtext" mt="3px" fontSize={isLeadBid ? 2 : undefined}>
          <CurrencyAmount showCode value={bid?.price} />
        </Text>
      </Box>
    </Flex>
  );
};

export const AuctionLeaderboard = ({
  setActiveBidderId,
}: {
  setActiveBidderId: (bidderId: string) => void;
}) => {
  const lot = rfx.useAuctionLot();
  const recipients = rfx.useRecipients();

  const unrankedRecipients = useMemo(() => {
    const rankedRecipientIds = lot.rankedBids.map(bid => bid.bidderId);

    const filteredRecipients = recipients.filter(
      recipient => !rankedRecipientIds.includes(recipient._id),
    );

    return sortByName(filteredRecipients);
  }, [recipients, lot.rankedBids]);

  return (
    <Box ml="13px" height="100%">
      <LeaderboardTitle />
      <LeaderboardList as="ul">
        {lot.rankedBids.map((bid, index) => {
          const recipient = recipients.find(recipient => recipient._id === bid.bidderId);
          return (
            <LeaderboardItem
              key={bid.bidderId}
              bid={bid}
              // @ts-expect-error ts(2322) FIXME: Type 'Company | undefined' is not assignable to type 'Company'.
              recipient={recipient}
              rank={index + 1}
              onMouseEnter={() => setActiveBidderId(bid.bidderId)}
              // @ts-expect-error ts(2345) FIXME: Argument of type 'null' is not assignable to parameter of type 'string'.
              onMouseLeave={() => setActiveBidderId(null)}
            />
          );
        })}
        {unrankedRecipients.map((recipient) => (
          <LeaderboardItem
            key={recipient._id}
            recipient={recipient}
          />
        ))}
      </LeaderboardList>
    </Box>
  );
};
