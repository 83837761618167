import { useCallback } from 'react';
import { without } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass/styled-components';
import { useField } from 'formik';
import { QuestionAddressField } from '@deepstream/common/rfq-utils';

import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { DisabledInputBox } from '../../ui/Input';
import { CheckboxFieldBase } from '../../form/CheckboxField';
import { ErrorMessage } from '../../form/Field';

import { addressFields, contactFields } from './utils';

const AddressField = ({
  addressFieldName,
  onChange,
  disabled,
  isChecked,
}: {
  addressFieldName: QuestionAddressField;
  onChange: (event: any) => void;
  disabled?: boolean;
  isChecked?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Flex alignItems="center">
      <DisabledInputBox flex={1} height="40px" mr="24px">
        {t(`request.question.addressField.${addressFieldName}`)}
      </DisabledInputBox>
      <Box flex="0 0 auto">
        <CheckboxFieldBase
          name={addressFieldName}
          value={isChecked}
          fieldLabel={t('general.visible')}
          onChange={onChange}
          disabled={disabled}
        />
      </Box>
    </Flex>
  );
};

export const AddressFields = ({
  fieldName,
  disabled,
}: {
  fieldName?: string
  disabled?: boolean;
}) => {
  const visibleFieldsFieldName = fieldName ? `${fieldName}.visibleFields` : 'visibleFields';
  const [{ value }, fieldProps, helper] = useField<QuestionAddressField[]>(visibleFieldsFieldName);

  const onVisibilityChange = useCallback((event) => {
    const newFields = event.target.checked
      ? [...value, event.target.name]
      : without(value, event.target.name);

    helper.setValue(newFields);
  }, [value, helper]);

  const checkIsChecked = useCallback((field) => {
    return (value || []).includes(field);
  }, [value]);

  return (
    <Flex flexDirection={['column', 'row']} flexWrap="wrap">
      <Box flex={1} mr={['0px', '40px']} mb={[4, 0]}>
        <Stack gap={1}>
          {addressFields.map(addressFieldName => (
            <AddressField
              key={addressFieldName}
              addressFieldName={addressFieldName}
              onChange={onVisibilityChange}
              disabled={disabled}
              isChecked={checkIsChecked(addressFieldName)}
            />
          ))}
        </Stack>
      </Box>
      <Box flex={1}>
        <Stack gap={1}>
          {contactFields.map(addressFieldName => (
            <AddressField
              key={addressFieldName}
              disabled={disabled}
              addressFieldName={addressFieldName}
              onChange={onVisibilityChange}
              isChecked={checkIsChecked(addressFieldName)}
            />
          ))}
        </Stack>
      </Box>
      {fieldProps.error && (
        <Box sx={{ wordBreak: 'break-word', textAlign: 'left', flexBasis: '100%', mt: 3 }}>
          <ErrorMessage error={fieldProps.error} fontWeight="normal" />
        </Box>
      )}
    </Flex>
  );
};
