import {
  CollaboratorInviteStatus,
  LockType,
  SectionType,
  isAuctionStage,
  Stage,
  AuctionLineItemExchangeDefinition,
  StandaloneAuctionLineItemExchangeDefinition,
  isLinkedAuctionLineItemExchangeDef,
  AuctionTermsExchangeDefinition,
  AuctionInformationExchangeDefinition,
  LinkedAuctionLineItemExchangeDefinition,
  EvaluationCriterionExchangeDefinition,
  DefinitionFieldConfig,
  isLinkedEvaluationPage,
  isLinkedEvaluationSection,

  DocumentExchangeDefinition,
  ExchangeDefinition,
  ExchangeType,
  LineItemExchangeDefinition,
  QuestionExchangeDefinition,
  OptionQuestionExchangeDefinition,
  isOptionBasedQuestion,
  isAddressQuestion,
  isShortTextQuestion,
  isDateTimeQuestion,
  QuestionFormat,
  Draft,
  getExchangeDefFieldValue,
  isDefinitionField,
  isGeneralStage,
  renderStageName,
  renderSectionName,
  RfxSection,
  isEmptyExchangeDef,
  RfxAuctionLineItemsSection,
  isCurrencyExchangeDef,
  Live,
  isSupplierReplyField,
  isLineItemExchangeDef,
  getStageIdFromTag,
  isStageResponseTag,
  isLinkedEvaluationCriterionExchangeDef,
} from '@deepstream/common/rfq-utils';
import { overlappingPairs } from '@deepstream/utils';
import { exchangesConfig } from '@deepstream/common';
import { filter, isEmpty, isNil, some, intersection, uniq, isNumber, reject, size, trim, partition, difference, first, compact, map, isFinite } from 'lodash';
import { informationRequestExchangeTypes } from '@deepstream/common/exchangesConfig';
import { addMinutes, isAfter, isBefore, isPast } from 'date-fns';
import { TFunction } from 'i18next';
import { DEFAULT_AUCTION_MAX_LINE_ITEM_COUNT, REQUEST_MAX_SUPPLIER_COUNT } from '@deepstream/common/constants';
import { optionalBuyerFieldKeys } from '../../draft/LineItemsSectionViewPanel';
import { RfxStructure, StructureAuctionStage } from '../../types';
import { orderedAwardScenarios } from '../../draft/SummaryLotsPanel';

const getStageDisplayName = (stage, index, t: TFunction) => (
  stage.name
    ? `${index + 1} – ${renderStageName(stage, t)}`
    : `${index + 1}`
);

const hasSelectedLockType = (exchangeDef) => (
  exchangeDef.locking &&
  exchangeDef.locking.type &&
  // Non-deadline locks require at least 1 company with a key
  ([LockType.BID_DEADLINE, LockType.STAGE_DEADLINE].includes(exchangeDef.locking.type) || !isEmpty(exchangeDef.locking.keys))
);

const hasExchangeLock = (exchangeType) => {
  const typeConfig = exchangesConfig[exchangeType];
  return typeConfig && typeConfig.hasLock;
};

const shouldHaveAttachment = (exchangeDef: DocumentExchangeDefinition) => {
  return exchangeDef.type && !informationRequestExchangeTypes.includes(exchangeDef.type);
};

// We should allow a value of `0` for an option
const optionIsEmpty = (option: string) => isNil(option) || option === '';

const hasDuplicateOptions = (exchangeDef: OptionQuestionExchangeDefinition) => {
  const validOptions = reject(exchangeDef.options, optionIsEmpty);
  const uniqueOptions = uniq(validOptions);

  return size(validOptions) > size(uniqueOptions);
};

const getLowerStageBound = (stage: Stage<Draft> | null) => {
  if (!stage) {
    return null;
  }

  return isAuctionStage(stage)
    ? stage.startDate
    : stage.completionDeadline;
};

const getUpperStageBound = (
  stage: Stage<Draft> | null,
  structure: RfxStructure<Draft>,
) => {
  if (!stage) {
    return null;
  }

  if (isAuctionStage(stage)) {
    const { startDate } = stage;

    const auctionLineItemsSection = Object
      .values(structure.sectionById)
      .filter(section => section.type === SectionType.AUCTION_LINE_ITEMS)
      ?.[0] as RfxAuctionLineItemsSection || undefined;

    if (!auctionLineItemsSection) {
      throw new Error('Cannot validate request. Encountered auction stage without auction line items section');
    }

    const { duration } = auctionLineItemsSection.auctionRules;

    if (isNil(startDate) || isNil(duration)) {
      // it's fine to return null and thus stop validating because we've got dedicated
      // validation checks for the existence of startDate and duration.
      return null;
    }

    return addMinutes(new Date(startDate), duration);
  } else {
    return stage.completionDeadline;
  }
};

// Moved RFQ validation in here to separate out validation from the editor.

// TODO: Refactor into smaller parts

export class RfxDraftValidator {
  constructor(
    private draft: RfxStructure<Draft>,
  ) {}

  get creatorId() {
    // Is this always right?
    return this.draft.senders[0]._id;
  }

  get collaborators() {
    return reject(this.draft.senders, sender => sender._id === this.creatorId);
  }

  get emptyStageIndexes() {
    const { stages, exchangeDefById } = this.draft;

    const exchangeDefStartStages = Object
      .values(exchangeDefById)
      .flatMap(exchangeDef => {
        if (isLineItemExchangeDef(exchangeDef)) {
          const fieldTags = Object.values(exchangeDef.fields)
            .flatMap(field => isSupplierReplyField(field) ? field.responseTags || [] : [])
            .filter(isStageResponseTag);

          const stageIds = compact(fieldTags).map(getStageIdFromTag);

          if (!isEmpty(stageIds)) {
            return stageIds;
          }
        }

        return first(exchangeDef.stages || []) || [];
      });

    return reject(
      stages.map((stage, index) => !exchangeDefStartStages.includes(stage._id) ? index : undefined),
      isNil,
    );
  }

  getValidationErrors(sectionId, t: TFunction) {
    const errors = [];

    switch (sectionId) {
      // These sections can be empty

      case 'pages': {
        const { pages, settings } = this.draft;
        const visiblePages = pages.filter(page => !page.isHiddenWhileEditing);

        if (!visiblePages.length) {
          errors.push(t('request.validation.minOnePage'));
        }

        if (pages.some(page => !isLinkedEvaluationPage(page) && isEmpty(page.sections))) {
          errors.push(t('request.validation.minOneSection'));
        }

        const linkedEvaluationPages = pages.filter(isLinkedEvaluationPage);

        if (linkedEvaluationPages.some(page => isEmpty(page.sections))) {
          errors.push(t('request.validation.minOneEvaluationSection'));
        }

        if (linkedEvaluationPages.length > 1 && linkedEvaluationPages.some(page => isNil(page.weight))) {
          errors.push(t('request.validation.ensureWeight'));
        }

        if (settings.isEvaluationEnabled && isEmpty(linkedEvaluationPages)) {
          errors.push(t('request.validation.minOneEvaluationPage'));
        }

        return errors;
      }

      case 'summary': {
        const { summary, spendAndSavings, settings, lots } = this.draft;

        if (!summary.subject) {
          errors.push(t('request.validation.missingName'));
        }

        if (!summary.description) {
          errors.push(t('request.validation.missingOverview'));
        }

        if (
          spendAndSavings?.hasBudgetedTotalValue &&
          // @ts-expect-error ts(18047) FIXME: 'spendAndSavings.budgetedTotalValue' is possibly 'null'.
          (!isFinite(spendAndSavings.budgetedTotalValue) || spendAndSavings.budgetedTotalValue <= 0)
        ) {
          errors.push(t('request.validation.missingBudgetedTotalValue'));
        }

        if (
          this.draft.senders.length > 0 &&
          some(this.draft.senders, sender => !sender.company.role || !sender.company.role.trim())
        ) {
          errors.push(t('request.validation.missingRole'));
        }

        if (this.collaborators.length > 0) {
          if (some(this.collaborators, collaborator => collaborator.inviteStatus !== CollaboratorInviteStatus.ACCEPTED)) {
            errors.push(t('request.validation.collaboratorInviteNotAccepted'));
          }
          if (some(this.collaborators, collaborator => !collaborator.company._id)) {
            errors.push(t('request.validation.invalidCollaboratorCompany'));
          }
          if (some(
            this.collaborators,
            collaborator => Object.values(this.draft.teamById[collaborator._id].users).length === 0,
          )) {
            errors.push(t('request.validation.missingCollaboratorTeamMember'));
          }
        }

        if (settings.areLotsEnabled) {
          if (isEmpty(lots)) {
            errors.push(t('request.validation.minOneLot'));
          } else {
            if (lots.every(lot => lot.isObsolete)) {
              errors.push(t('request.validation.minOneNonObsoleteLot'));
            }

            lots.forEach((lot, index) => {
              if (!lot.name || !lot.description) {
                errors.push(t('request.validation.lotNameAndDescriptionRequired', { lotNumber: index + 1 }));
              }
            });

            const lotSectionIds = uniq(
              Object.values(this.draft.sectionById)
                .filter(section => section.type !== SectionType.EVALUATION)
                .flatMap(section => section.lotIds || []),
            );

            lots.forEach((lot, index) => {
              if (!lot.isObsolete) {
                const isSectionAssignedToLot = lotSectionIds.includes(lot._id);

                if (!isSectionAssignedToLot) {
                  errors.push(t('request.validation.lotSectionAssignmentRequired', { lotNumber: index + 1 }));
                }
              }
            });
          }
        }

        if (!orderedAwardScenarios.some(awardScenario => settings.awardScenarios[awardScenario])) {
          errors.push(t('request.validation.minOneAwardScenario'));
        }

        return errors;
      }

      case 'stages': {
        const { stages } = this.draft;

        if (stages.length > 1 && some(stages, stage => !stage.name)) {
          errors.push(t('request.validation.missingStageName'));
        }

        if (some(stages, stage => !stage.completionDeadline && !isAuctionStage(stage))) {
          errors.push(t('request.validation.missingStageDeadline'));
        }

        if (some(stages, stage => isAuctionStage(stage) && !stage.startDate)) {
          errors.push(t('request.validation.missingAuctionStart'));
        }

        stages.forEach((stage, index) => {
          const liveStages = compact(map(this.draft.stages, stage => stage.liveVersion));
          const liveStage = liveStages.find(liveStage => liveStage._id === stage._id);

          // For auction stages, make sure the start date is at least 3 minutes in the future
          if (isAuctionStage(stage) && stage.startDate) {
            if (!liveStage || (liveStage as StructureAuctionStage<Live>).startDate !== stage.startDate) {
              const threeMinutesFromNow = addMinutes(new Date(), 3);

              if (isBefore(new Date(stage.startDate), threeMinutesFromNow)) {
                errors.push(t('request.validation.auctionStartDateTooEarly', { stageName: getStageDisplayName(stage, index, t) }));
              }
            }
          }

          // For general stages, make sure the completion deadline is in the future
          if (isGeneralStage(stage) && stage.completionDeadline) {
            if (!liveStage || liveStage.completionDeadline !== stage.completionDeadline) {
              if (isPast(new Date(stage.completionDeadline))) {
                errors.push(t('request.validation.completionDeadlineTooEarly', { stageName: getStageDisplayName(stage, index, t) }));
              }
            }
          }
        });

        for (const [stageA, stageB] of overlappingPairs(stages)) {
          const stageAUpperBound = getUpperStageBound(stageA, this.draft);
          const stageBLowerBound = getLowerStageBound(stageB);

          if (stageAUpperBound && stageBLowerBound && isAfter(new Date(stageAUpperBound), new Date(stageBLowerBound))) {
            const stageAIndex = stages.findIndex(stage => stage._id === stageA._id);
            const stageBIndex = stages.findIndex(stage => stage._id === stageB._id);

            if (isAuctionStage(stageA)) {
              if (isAuctionStage(stageB)) {
                errors.push(t('request.validation.endDateMustBeBeforeStartDate', { firstStageName: getStageDisplayName(stageA, stageAIndex, t), secondStageName: getStageDisplayName(stageB, stageBIndex, t) }));
              } else {
                errors.push(t('request.validation.endDateMustBeBeforeCompletionDeadline', { firstStageName: getStageDisplayName(stageA, stageAIndex, t), secondStageName: getStageDisplayName(stageB, stageBIndex, t) }));
              }
            } else {
              // eslint-disable-next-line no-lonely-if
              if (isAuctionStage(stageB)) {
                errors.push(t('request.validation.completionDeadlineMustBeBeforeStartDate', { firstStageName: getStageDisplayName(stageA, stageAIndex, t), secondStageName: getStageDisplayName(stageB, stageBIndex, t) }));
              } else {
                errors.push(t('request.validation.completionDeadlineMustBeBeforeCompletionDeadline', { firstStageName: getStageDisplayName(stageA, stageAIndex, t), secondStageName: getStageDisplayName(stageB, stageBIndex, t) }));
              }
            }
          }
        }

        const emptyStageIndices = this.emptyStageIndexes;
        if (emptyStageIndices.length) {
          // @ts-expect-error ts(18048) FIXME: 'index' is possibly 'undefined'.
          const emptyStageNumbers = emptyStageIndices.map(index => `${index + 1}`);

          errors.push(t('request.validation.noDocumentOrSectionVisibleFromStage', {
            stageNums: emptyStageNumbers,
            count: emptyStageIndices.length,
          }));
        }

        return errors;
      }

      case 'suppliers': {
        const { settings, senders, recipients } = this.draft;

        if (isEmpty(this.draft.recipients) && !settings.isPubliclyAvailable) {
          errors.push(t('request.validation.minOneSupplierOrPublicRequest'));
        }

        const someSendersAreRecipients = intersection(
          senders.map(sender => sender._id),
          recipients.map(recipient => recipient._id),
        ).length;

        if (someSendersAreRecipients) {
          errors.push(t('request.validation.supplierIsSender'));
        }

        if (recipients.length > REQUEST_MAX_SUPPLIER_COUNT) {
          errors.push(t('request.validation.maxSupplierCountExceeded', { supplierCount: REQUEST_MAX_SUPPLIER_COUNT }));
        }

        return errors;
      }

      case 'permissions': {
        return errors;
      }

      case 'owner': {
        return errors;
      }

      default: {
        return this.getSectionValidationErrors(sectionId, t);
      }
    }
  }

  getSectionValidationErrors(sectionId, t: TFunction) {
    let errors: string[] = [];
    const section: RfxSection<Draft> = this.draft.sectionById[sectionId];
    const exchangeDefs = section.exchangeDefIds.map(defId => this.draft.exchangeDefById[defId] as ExchangeDefinition<Draft>);
    const isLinkedSection = isLinkedEvaluationSection(section);

    if (!isLinkedSection && !section.name.trim()) {
      if (section.type === SectionType.EVALUATION) {
        errors.push(t('request.validation.missingEvaluationSectionTitle'));
      } else {
        errors.push(t('request.validation.missingSectionTitle'));
      }
    }

    switch (section.type) {
      case SectionType.EVALUATION: {
        if (!section.weight) {
          errors.push(t('request.validation.missingEvaluationSectionWeight'));
        }

        if (!exchangeDefs.length) {
          errors.push(t('request.validation.minOneEvaluationCriterion'));
        }

        if ((exchangeDefs as EvaluationCriterionExchangeDefinition[]).some((exchangeDef) => {
          if (isLinkedEvaluationCriterionExchangeDef(exchangeDef)) {
            const originalExchangeDef = this.draft.exchangeDefById[exchangeDef._id];

            if (originalExchangeDef.isObsolete) {
              return false;
            }
          }

          if (!isLinkedSection && !exchangeDef.description.trim()) {
            return true;
          }

          if (!exchangeDef.maxPoints || !exchangeDef.weight) {
            return true;
          }

          return false;
        })) {
          errors.push(t('request.validation.missingEvaluationFieldValue'));
        }

        return errors;
      }

      case SectionType.DOCUMENT: {
        // in revisions, we don't require document sections that are already live to
        // have at least one item in order to avoid breaking existing requests
        if (isEmpty(exchangeDefs) && !section.isLive) {
          errors.push(t('request.validation.minOneDocumentPerSection', { sectionName: renderSectionName(section, t) }));
        }

        if (
          exchangeDefs.some(exchangeDef =>
            !(<DocumentExchangeDefinition> exchangeDef).category ||
            !(<DocumentExchangeDefinition> exchangeDef).category.trim(),
          )
        ) {
          errors.push(t('request.validation.missingDocumentDescription', { sectionName: renderSectionName(section, t) }));
        }

        if (exchangeDefs.some(exchangeDef => !exchangeDef.type || !exchangeDef.type.trim())) {
          errors.push(t('request.validation.missingDocumentRequirement', { sectionName: renderSectionName(section, t) }));
        }

        if ((exchangeDefs as DocumentExchangeDefinition[]).some(
          (exchangeDef) => (
            hasExchangeLock(exchangeDef.type) &&
            !hasSelectedLockType(exchangeDef)
          ),
        )) {
          errors.push(t('request.validation.missingLockingType', { sectionName: renderSectionName(section, t) }));
        }

        if (exchangeDefs.some(exchangeDef => !exchangeDef.stages?.length)) {
          errors.push(t('request.validation.missingStageVisibility', { sectionName: renderSectionName(section, t) }));
        }

        if ((exchangeDefs as DocumentExchangeDefinition[]).some(
          (exchangeDef) => (
            shouldHaveAttachment(exchangeDef) &&
            !exchangeDef.attachments.length
          ),
        )) {
          errors.push(t('request.validation.missingFile', { sectionName: renderSectionName(section, t) }));
        }

        return errors;
      }

      case SectionType.LINE_ITEMS: {
        const lineItemSectionDefs = reject(exchangeDefs, isEmptyExchangeDef) as ExchangeDefinition<'draft'>[];
        const lineItemDefs = lineItemSectionDefs.filter(
          (def): def is LineItemExchangeDefinition => def.type === ExchangeType.LINE_ITEM,
        );

        if (isEmpty(lineItemDefs)) {
          errors.push(t('request.validation.minOneLineItemPerSection', { sectionName: renderSectionName(section, t) }));
        }

        // @ts-expect-error ts(18047) FIXME: 'lineItemDef.quantity' is possibly 'null'.
        if (lineItemDefs.some(lineItemDef => lineItemDef.fields.quantity && lineItemDef.quantity <= 0)) {
          errors.push(t('request.validation.lineItemQuantityTooLow', { sectionName: renderSectionName(section, t) }));
        }

        if (lineItemDefs.some(lineItemDef => {
          if (lineItemDef.isObsolete) {
            return false;
          }

          const fields = Object.values(lineItemDef.fields)
            .filter(field => isDefinitionField(field) && !optionalBuyerFieldKeys.includes(field._id)) as DefinitionFieldConfig[];

          return fields.some((field) => {
            // @ts-expect-error ts(2345) FIXME: Argument of type 'string' is not assignable to parameter of type 'never'.
            const value = getExchangeDefFieldValue(lineItemDef, field._id);
            switch (field.type) {
              case 'date':
              case 'boolean':
              case 'string':
              case 'unspscCode':
                return !value;
              case 'price':
              case 'number':
                return !isNumber(value);
              default:
                throw new Error(`Unsupported field type: ${field.type}`);
            }
          });
        })) {
          errors.push(t('request.validation.missingLineItemBuyerValue', { sectionName: renderSectionName(section, t) }));
        }

        if (section.responseTagConfig) {
          const tagCount = size(section.responseTagConfig.tags);

          if (tagCount < 2) {
            errors.push(t('request.validation.minTwoMultiStageResponseStages', { sectionName: renderSectionName(section, t) }));
          } else {
            const fields = lineItemDefs[0]?.fields;

            if (
              fields &&
              !Object.values(fields).some(field => (
                isSupplierReplyField(field) && size(field.responseTags) > 1),
              )
            ) {
              errors.push(t('request.validation.minOneMultiStageResponseField', { sectionName: renderSectionName(section, t) }));
            }
          }
        }

        if (lineItemDefs.some(lineItemDef => {
          if (lineItemDef.isObsolete) {
            return false;
          }

          const needsEvaluatorFieldCurrency = some(
            lineItemDef.fields,
            field => {
              if (!isDefinitionField(field) || field.type !== 'price') {
                return false;
              }

              return field._id === 'targetPrice'
                // In the case of the (optional) target price, a currency is
                // only required if a target price has been set
                // @ts-expect-error ts(2345) FIXME: Argument of type 'string' is not assignable to parameter of type 'never'.
                ? isNumber(getExchangeDefFieldValue(lineItemDef, 'targetPrice'))
                : true;
            },
          );

          return (
            needsEvaluatorFieldCurrency &&
            // @ts-expect-error ts(2345) FIXME: Argument of type 'string' is not assignable to parameter of type 'never'.
            !getExchangeDefFieldValue(lineItemDef, 'evaluatorFieldCurrency')
          );
        })) {
          errors.push(t('request.validation.missingBuyerCurrency', { sectionName: renderSectionName(section, t) }));
        }

        if (!isEmpty(lineItemDefs)) {
          const fields = lineItemDefs[0]?.fields;
          Object.values(fields)
            .forEach(field => {
              if (field.source.type === 'formula') {
                const { formula } = field.source;
                const referencedFieldIds = [...formula.matchAll(/\{([^}]+)\}/g)].map(result => result[1]);

                if (!isEmpty(difference(referencedFieldIds, Object.keys(fields)))) {
                  errors.push(t('request.validation.invalidFormulaFieldReference', {
                    fieldName: 'label' in field ? field.label : t(`request.fields.predefinedFieldLabel.${field._id.split(':')[0]}`, { ns: 'translation' }),
                    sectionName: renderSectionName(section, t),
                  }));
                }
              }
            });
        }

        return errors;
      }

      case SectionType.VESSEL_PRICING: {
        const { hirePeriodIds, isObsolete } = section;
        const hirePeriods = hirePeriodIds.map(hirePeriodId => this.draft.hirePeriodById[hirePeriodId]);

        if (!isObsolete && hirePeriods.every(hirePeriod => hirePeriod.isObsolete)) {
          errors.push(t('request.validation.minOneNonObsoleteHirePeriod'));
        }

        for (const hirePeriod of hirePeriods) {
          if (!hirePeriod.toDate || !hirePeriod.fromDate) {
            errors.push(t('request.validation.missingHirePeriodStartOrEndDate', { hirePeriodName: hirePeriod.name }));
          }
          if (filter(exchangeDefs, { type: ExchangeType.HIRE_PERIOD, hirePeriodId: hirePeriod._id }).length < 1) {
            errors.push(t('request.validation.minOneHirePeriodDayRate', { hirePeriodName: hirePeriod.name }));
          }
        }

        const exchangeDefErrors: string[] = uniq(this.getExchangeDefinitionValidationErrors(exchangeDefs, t));
        errors = errors.concat(exchangeDefErrors);

        return errors;
      }

      case SectionType.QUESTION: {
        const exchangeDefs = section.exchangeDefIds.map(defId =>
          this.draft.exchangeDefById[defId] as QuestionExchangeDefinition<Draft>,
        );

        const shortTextQuestionDefs = filter(exchangeDefs, isShortTextQuestion);
        const questionDefsWithOptions = filter(exchangeDefs, isOptionBasedQuestion);
        const addressQuestionDefs = filter(exchangeDefs, isAddressQuestion);
        const dateTimeQuestionDefs = filter(exchangeDefs, isDateTimeQuestion);

        if (isEmpty(exchangeDefs)) {
          errors.push(t('request.validation.minOneQuestionPerSecdtion', { sectionName: renderSectionName(section, t) }));
        }

        if (exchangeDefs.some(({ description, questionType }) => !description || !questionType)) {
          errors.push(t('request.validation.incompleteQuestion', { sectionName: renderSectionName(section, t) }));
        }

        if (shortTextQuestionDefs.some(({ schema }) => (
          schema && (!isNumber(schema.min) || !isNumber(schema.max))
        ))) {
          errors.push(t('request.validation.missingMinMax', { sectionName: renderSectionName(section, t) }));
        }

        if (shortTextQuestionDefs.some(({ schema }) => (
          schema && isNumber(schema.min) && isNumber(schema.max) && schema.min === schema.max
        ))) {
          errors.push(t('request.validation.matchingMinMax', { sectionName: renderSectionName(section, t) }));
        }

        if (questionDefsWithOptions.some(({ options }) => isEmpty(options))) {
          errors.push(t('request.validation.minOneQuestionOption', { sectionName: renderSectionName(section, t) }));
        }

        if (questionDefsWithOptions.some(({ options }) => options && options.some(optionIsEmpty))) {
          errors.push(t('request.validation.incompleteQuestionOptions', { sectionName: renderSectionName(section, t) }));
        }

        if (questionDefsWithOptions.some(hasDuplicateOptions)) {
          errors.push(t('request.validation.duplicateQuestionOptions', { sectionName: renderSectionName(section, t) }));
        }

        if (addressQuestionDefs.some(({ visibleFields }) => isEmpty(visibleFields))) {
          errors.push(t('request.validation.minOneVisibleAddressField', { sectionName: renderSectionName(section, t) }));
        }

        if (dateTimeQuestionDefs.some(({ format }) => format === QuestionFormat.INVALID)) {
          errors.push(t('request.validation.neitherDateNorTimeSelected', { sectionName: renderSectionName(section, t) }));
        }

        return errors;
      }

      case SectionType.AUCTION_TERMS: {
        const exchangeDefErrors: string[] = this.getExchangeDefinitionValidationErrors(exchangeDefs, t);
        errors = errors.concat(exchangeDefErrors);

        return errors;
      }

      case SectionType.AUCTION_LINE_ITEMS: {
        const { auctionRules } = section as RfxAuctionLineItemsSection<Draft>;

        if (!isNumber(auctionRules.duration)) {
          errors.push(t('request.validation.missingAuctionDuration'));
        }
        if (!isNumber(auctionRules.minimumReduction.value)) {
          errors.push(t('request.validation.missingMinimumReductionValue'));
        }
        if (auctionRules.ceilingPrice && !isNumber(auctionRules.ceilingPrice.amount)) {
          errors.push(t('request.validation.missingCeilingPrice'));
        }
        if (auctionRules.autoExtension && !isNumber(auctionRules.autoExtension.timeToRespond)) {
          errors.push(t('request.validation.missingAutoExtensionTime'));
        }

        const auctionLineItemDefs = exchangeDefs as AuctionLineItemExchangeDefinition[];

        if (auctionLineItemDefs.length > DEFAULT_AUCTION_MAX_LINE_ITEM_COUNT) {
          errors.push(t('request.validation.maxAuctionLineItemCountExceeded', { limit: DEFAULT_AUCTION_MAX_LINE_ITEM_COUNT }));
        }

        const [
          linkedAuctionLineItemsExchangeDefs,
          standaloneLineItemsExchangeDefs,
        ] = partition(auctionLineItemDefs, isLinkedAuctionLineItemExchangeDef) as [
          LinkedAuctionLineItemExchangeDefinition[],
          StandaloneAuctionLineItemExchangeDefinition[],
        ];

        const rfxLineItemExchangeDefs = linkedAuctionLineItemsExchangeDefs.map(exchangeDef =>
          this.draft.exchangeDefById[exchangeDef.linkedExchangeDefId],
        );

        if (isEmpty(auctionLineItemDefs)) {
          errors.push(t('request.validation.minOneAuctionLineItem'));
        } else if (
          standaloneLineItemsExchangeDefs.every(exchangeDef => exchangeDef.isObsolete) &&
          linkedAuctionLineItemsExchangeDefs.every((exchangeDef, index) =>
            exchangeDef.isObsolete || rfxLineItemExchangeDefs[index].isObsolete,
          )
        ) {
          errors.push(t('request.validation.minOneNonObsoleteAuctionLineItem'));
        }

        if (standaloneLineItemsExchangeDefs.some(lineItemDef => (
          !lineItemDef.description ||
          !lineItemDef.quantity ||
          (!isNil(lineItemDef.unit) && !lineItemDef.unit.length)
        ))) {
          errors.push(t('request.validation.missingAuctionLineItemFieldValue'));
        }

        if (standaloneLineItemsExchangeDefs.some(lineItemDef => lineItemDef.quantity <= 0)) {
          errors.push(t('request.validation.auctionLineItemQuantityTooLow'));
        }

        if (some(rfxLineItemExchangeDefs, exchangeDef => (
          // @ts-expect-error ts(18048) FIXME: 'exchangeDef.fields' is possibly 'undefined'.
          !exchangeDef.fields.unit ||
          // @ts-expect-error ts(18048) FIXME: 'exchangeDef.fields' is possibly 'undefined'.
          !exchangeDef.fields.quantity ||
          // @ts-expect-error ts(18048) FIXME: 'exchangeDef.fields' is possibly 'undefined'.
          !exchangeDef.fields.price
        ))) {
          errors.push(t('request.validation.auctionLineItemFieldsMismatch'));
        }

        const rfxLineItemSections = rfxLineItemExchangeDefs.map(exchangeDef =>
          // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
          this.draft.sectionById[exchangeDef.sectionId],
        );

        const rfxLineItemCurrencyDefs = rfxLineItemSections.map(section =>
          section.exchangeDefIds
            .map(defId => this.draft.exchangeDefById[defId])
            .find(isCurrencyExchangeDef),
        );

        if (some(rfxLineItemCurrencyDefs, currencyDef => currencyDef.currencies?.length > 1)) {
          errors.push(t('request.validation.linkedAuctionLineItemCurrencyMismatch'));
        }

        if (some(rfxLineItemCurrencyDefs, currencyDef => currencyDef.currencies?.[0] !== auctionRules.currency)) {
          errors.push(t('request.validation.auctionLineItemCurrencyMismatch'));
        }

        // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
        const auctionStageId = (section as RfxAuctionLineItemsSection).stages[0];
        // @ts-expect-error ts(18048) FIXME: 'exchangeDef.stages' is possibly 'undefined'.
        if (some(rfxLineItemExchangeDefs, exchangeDef => !exchangeDef.stages.includes(auctionStageId))) {
          errors.push(t('request.validation.previousLineItemsNotInPrecedingStage'));
        }

        return errors;
      }

      default: {
        return errors;
      }
    }
  }

  getExchangeDefinitionValidationErrors(defs: ExchangeDefinition<Draft>[], t: TFunction) {
    const errors = [];

    for (const def of defs) {
      switch (def.type) {
        case ExchangeType.HIRE_PERIOD:

          if (!def.amount || def.amount <= 0) {
            errors.push(t('request.validation.hirePeriodNotGreaterNull'));
          }

          if (!def.quantity || def.quantity <= 0) {
            errors.push(t('request.validation.hirePeriodQuantityNotGreaterNull'));
          }
          break;

        case ExchangeType.FEES:
          if (!def.description.trim()) {
            errors.push(t('request.validation.missingFeeDescription'));
          }
          if (!def.feeType) {
            errors.push(t('request.validation.missingFeeType'));
          }
          break;

        case ExchangeType.INCLUSIONS:
          if (!def.description.trim()) {
            errors.push(t('request.validation.missingInclusionDescription'));
          }
          if (!def.option) {
            errors.push(t('request.validation.missingRatesIncluded'));
          }
          break;

        case ExchangeType.TERMS:
          if (!def.description || !def.description.trim()) {
            errors.push(t('request.validation.missingTermsDescription'));
          }
          break;

        case ExchangeType.CURRENCY:
          if (isEmpty(def.currencies)) {
            errors.push(t('request.validation.missingCurrency'));
          }
          break;

        case ExchangeType.AUCTION_INFORMATION:
          if (!trim((def as AuctionInformationExchangeDefinition).text)) {
            errors.push(t('request.validation.missingAwardingPrinciples'));
          }
          break;

        case ExchangeType.AUCTION_TERMS:
          if (!trim((def as AuctionTermsExchangeDefinition).text)) {
            errors.push(t('request.validation.missingBidderAgreement'));
          }
          break;

        default:
          break;
      }
    }

    return errors;
  }
}
