import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Flex, Text, Box } from 'rebass/styled-components';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Button, ContactToUpgradeButton } from '@deepstream/ui-kit/elements/button/Button';
import { useIntercomTrackEvent } from '../../ui/useIntercomTrackEvent';

export const DashboardNoData = ({
  message,
}: {
  message: string;
}) => {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '20px',
      }}
    >
      <Text color="subtext" fontSize={3} as="span">
        {message}
      </Text>
    </Box>
  );
};

export const DashboardNoTeamAccess = ({
  heading,
  body,
  onTeamManagementClick = noop,
}: {
  heading: string;
  body: string;
  onTeamManagementClick?: () => void;
}) => {
  const { t } = useTranslation(['dashboard']);
  return (
    <Flex
      alignItems="center"
      p="20px"
      sx={{ height: '100%' }}
    >
      <Box>
        <Text
          fontSize={3}
          fontWeight="500"
          mb={2}
        >
          <Icon icon="lock" mr={2} />
          {heading}
        </Text>
        <Text
          as="p"
          mb={2}
        >
          {body}
        </Text>

        <Button
          onClick={onTeamManagementClick}
          small
        >
          {t('actions.openTeamManagement')}
        </Button>
      </Box>
    </Flex>
  );
};

export const DashboardNoCompanyAccess = ({
  message,
}: {
  message: string;
}) => {
  const handleUpgradeClick = useIntercomTrackEvent({ eventName: 'dashboard:reporting:upgrade', showChat: true });

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: '20px',
    }}
    >
      <Box>
        <Text
          as="p"
          mb={3}
        >
          {message}
        </Text>

        <ContactToUpgradeButton
          small
          onClick={handleUpgradeClick}
        />
      </Box>
    </Box>
  );
};
