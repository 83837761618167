import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';
import {
  ExtendedReceivedContractOverview, ExtendedSentContractOverview, SentContractOverviews,
} from '@deepstream/common/contract';
import { ContractsTableData } from './ContractsTableDataProvider';
import { useTableData } from '../../TableDataContext';
import { SearchCombobox, SearchComboboxProps } from '../../ui/SearchCombobox';
import { useMutation } from '../../useMutation';

export const SEARCH_RESULTS_LIMIT = 20;

type ContractsOverviewT = ExtendedReceivedContractOverview | ExtendedSentContractOverview;

export type SearchContractsProps<T extends ContractsOverviewT> = {
  queryFn: (...params: any[]) => Promise<{ overviews :T[] }>;
  getResultText: (request: T) => string;
  onResultClick: (request: T) => void;
  disabled?: SearchComboboxProps<T>['disabled'];
};

export const SearchContracts = <T extends ContractsOverviewT>({
  queryFn,
  getResultText,
  onResultClick,
  disabled,
}: SearchContractsProps<T>) => {
  const { t } = useTranslation('contracts');
  const { searchText, setSearchText, queryParams } = useTableData<
    ContractsTableData<SentContractOverviews>
  >({ required: true });

  const [search, { data, isLoading }] = useMutation(queryFn);
  const { overviews } = data ?? {};

  const searchContracts = useCallback(
    (text) => {
      if (text) {
        search({
          ...queryParams,
          // Search results limit is always the same, regardless the page size
          // set by the user for the requests table
          pageSize: SEARCH_RESULTS_LIMIT,
          pageIndex: 0,
          search: {
            text,
          },
        });
      }
    },
    [search, queryParams],
  );

  return (
    <Box width="300px" disabled>
      <SearchCombobox<T>
        initialValue={searchText}
        results={overviews}
        // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
        getResultId={(contract) => contract._id}
        getResultText={getResultText}
        textPlaceholder={t('searching.searchByContractName')}
        noResultsMessage={t('searching.noResults')}
        onResultClick={onResultClick}
        onSubmit={setSearchText}
        onChange={searchContracts}
        isLoading={isLoading}
        disabled={disabled}
      />
    </Box>
  );
};
