import { useMemo } from 'react';
import { find, first, flatMap, isEmpty, isFinite, map, propertyOf, values } from 'lodash';
import {
  ExchangeDefinition,
  ExchangeType,
  getPagesInDisplayOrder,
  isEvaluationPage,
  Page,
  PageType, RfxSection,
  SectionType,
} from '@deepstream/common/rfq-utils';
import * as rfx from '../rfx';
import { useRecipientId } from '../useRfq';
import { PublishedExchangeDefinition, RfxStructure } from '../types';
import { ExchangeSwitcherTarget } from './SwitchToExchange';
import { isSectionVisibleInStage } from '../draft/section';

const orderCreatorLast = (creatorId: string | null) =>
  (left: PublishedExchangeDefinition, right: PublishedExchangeDefinition) => {
    const leftMatchesPublisher = left.creatorId === creatorId;
    const rightMatchesPublisher = right.creatorId === creatorId;

    if (leftMatchesPublisher === rightMatchesPublisher) {
      return 0;
    } else if (rightMatchesPublisher) {
      return -1;
    } else {
      return 1;
    }
  };

const orderTypeLastThenCreatorLast = (type: ExchangeType, creatorId: string | null) =>
  (left: PublishedExchangeDefinition, right: PublishedExchangeDefinition) => {
    const leftMatchesType = left.type === type;
    const rightMatchesType = right.type === type;

    if (leftMatchesType === rightMatchesType) {
      return orderCreatorLast(creatorId)(left, right);
    } else if (rightMatchesType) {
      return -1;
    } else {
      return 1;
    }
  };

const getExchangesFromFirstMatchingPage = (
  pageType: PageType,
  visiblePages: Page[],
  structure: RfxStructure,
) => {
  const page = find(visiblePages, { type: pageType });

  if (!page) {
    return [];
  }

  const sections = map(
    page.sections,
    propertyOf(structure.sectionById),
  );

  const exchangeDefs: ExchangeDefinition[] = map(
    flatMap(sections, 'exchangeDefIds') as string[],
    propertyOf(structure.exchangeDefById),
  );

  return map(exchangeDefs, exchangeDef => ({
    exchangeId: exchangeDef._id,
    pageId: page._id,
  }));
};

const getEvaluationTargets = (pages: Page[], structure: RfxStructure) => {
  const orderedPages = getPagesInDisplayOrder(pages).filter(isEvaluationPage);

  return orderedPages.flatMap(page => {
    const sections = page.sections.map(propertyOf(structure.sectionById));

    return sections.flatMap(section => {
      return section.exchangeDefIds.map(exchangeDefId => ({
        exchangeId: exchangeDefId,
        pageId: page._id,
      }));
    });
  });
};

/**
 * Returns exchangeId and pageId of all exchanges to which users can
 * navigate from a bid exchange modal (including modals on the bid-specific
 * 'Messages' page).
 * The order of the returned objects matches the request's page,
 * page sections and section exchanges structure.
 */
export const useBidExchangeSwitcherTargets = (isMessagesPage: boolean): ExchangeSwitcherTarget[] => {
  const structure = rfx.useStructure();
  const pages = rfx.useVisiblePages();
  const recipientId = useRecipientId();
  const contextStageId = rfx.useStageId();
  const requirementGroupId = rfx.useRequirementGroupId();

  return useMemo(
    () => {
      if (isMessagesPage) {
        return getExchangesFromFirstMatchingPage(PageType.CHAT, pages, structure);
      }

      const pageIds = !contextStageId ? (
        structure.enteredBidPageIds!
      ) : (
        structure.enteredPageIdsByRequirementGroupIdByStageId![contextStageId]?.[requirementGroupId || 'general'] || []
      );

      const filteredPages = pages.filter(page => pageIds.includes(page._id));

      const sections: RfxSection[] = map(
        flatMap(filteredPages, 'sections') as string[],
        propertyOf(structure.sectionById),
      );

      const sectionFilterPredicate = !contextStageId ? (
        null
      ) : requirementGroupId === 'general' ? (
        (section: RfxSection) =>
          isSectionVisibleInStage(section, structure.exchangeDefById, contextStageId) && isEmpty(section.lotIds)
      ) : requirementGroupId ? (
        (section: RfxSection) =>
          isSectionVisibleInStage(section, structure.exchangeDefById, contextStageId) && first(section.lotIds) === requirementGroupId
      ) : (
        (section: RfxSection) =>
          isSectionVisibleInStage(section, structure.exchangeDefById, contextStageId)
      );

      const filteredSections = sectionFilterPredicate
        ? sections.filter(sectionFilterPredicate)
        : sections;

      return flatMap(
        filteredSections,
        (section) => {
          const sectionExchangeDefs = section.exchangeDefIds
            .map(propertyOf(structure.exchangeDefById));

          const isSectionCurrencyMissing = section.type === SectionType.LINE_ITEMS && !find(
            values(structure.bidById[recipientId]?.sectionById[section._id]?.exchangeStateById),
            { type: ExchangeType.CURRENCY },
          )?.isResolved;

          const sectionStageId = first(section.stages);

          const filteredSectionExchangeDefs = isSectionCurrencyMissing
            ? []
            : sectionExchangeDefs.filter((exchangeDef: ExchangeDefinition) => {
              if ([
                ExchangeType.CURRENCY,
                ExchangeType.FEES,
                ExchangeType.HIRE_PERIOD,
              ].includes(exchangeDef.type)) {
                return false;
              }

              // Omit supplier-provided line item exchanges with a missing unit or quantity
              // value; the supplier needs to provide those values first in the 'edit' modal
              // before we show the repective exchange modal.
              if (exchangeDef.type === ExchangeType.LINE_ITEM && exchangeDef.creatorId === recipientId) {
                if (
                  (exchangeDef.fields.unit && !exchangeDef.unit) ||
                  (exchangeDef.fields.quantity && !isFinite(exchangeDef.quantity))
                ) {
                  return false;
                }
              }

              // we've already checked sections above, so can just return
              // `true` when there's a section ID.
              if (sectionStageId || !contextStageId) {
                return true;
              }

              return !exchangeDef.stages || first(exchangeDef.stages) === contextStageId;
            });

          if (section.type === SectionType.VESSEL_PRICING) {
            filteredSectionExchangeDefs.sort(orderTypeLastThenCreatorLast(ExchangeType.TERMS, recipientId));
          } else {
            filteredSectionExchangeDefs.sort(orderCreatorLast(recipientId));
          }

          // TODO also account for stageless sections here!

          return map(filteredSectionExchangeDefs, exchangeDef => ({
            exchangeId: exchangeDef._id,
            pageId: find(
              filteredPages,
              page => page.sections.includes(exchangeDef.sectionId as string),
            )!._id,
          }));
        },
      );
    },
    [isMessagesPage, pages, structure, recipientId, contextStageId, requirementGroupId],
  );
};

/**
 * Returns exchangeId and pageId of all exchanges to which users can
 * navigate from an evaluation exchange modal.
 * The order of the returned objects matches the request's page,
 * page sections and section exchanges structure.
 */
export const useEvaluationExchangeSwitcherTargets = (): ExchangeSwitcherTarget[] => {
  const structure = rfx.useStructure();
  const pages = rfx.useVisiblePages();

  return useMemo(
    () => {
      return getEvaluationTargets(pages, structure);
    },
    [pages, structure],
  );
};
