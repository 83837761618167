import { useMemo } from 'react';
import { RfxSpendAndSavings, SavingsCalculationResultByRecipientIdBySavingsType } from '@deepstream/common/rfq-utils';
import { getPossibleSavingsCalculationMethods } from '@deepstream/common/rfq-utils/spendAndSavings';
import { useTranslation } from 'react-i18next';

export const useSavingsCalculationMethodSelectItems = (
  spendAndSavings: RfxSpendAndSavings,
  calculatedTotalValue: number | null | undefined,
  calculatedSavingsByType: SavingsCalculationResultByRecipientIdBySavingsType,
) => {
  const { t } = useTranslation('translation');

  return useMemo(() => {
    const methods = getPossibleSavingsCalculationMethods(
      spendAndSavings,
      calculatedTotalValue,
      calculatedSavingsByType,
    );

    return methods.map(method => ({
      value: method,
      label: t(`request.spendAndSavings.totalSavingsCalculationMethods.${method}`),
    }));
  }, [spendAndSavings, calculatedTotalValue, calculatedSavingsByType, t]);
};
