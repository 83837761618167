import { RfqStatus } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { BudgetPanel } from './BudgetPanel';
import * as rfx from '../../../rfx';
import { CurrencyCodeProvider } from '../../../ui/Currency';
import { FinalValuePanel } from './FinalValuePanel';
import { SpecificSavingsPanels } from './SpecificSavingsPanels';
import { TotalSavingsPanel } from './TotalSavingsPanel';

const ClosedInfo = () => {
  const { t } = useTranslation('translation');

  return (
    <MessageBlock variant="info" mt={0} mb="20px">
      {t('request.spendAndSavings.closedInfo')}
    </MessageBlock>
  );
};

const NotAwardedInfo = () => {
  const { t } = useTranslation('translation');

  return (
    <MessageBlock variant="info" mt={0} mb="20px">
      {t('request.spendAndSavings.notAwardedInfo')}
    </MessageBlock>
  );
};

export const SpendPanels = () => {
  const { extendedStatus, currencyCode } = rfx.useStructure();

  return (
    <CurrencyCodeProvider code={currencyCode}>
      <BudgetPanel />
      {extendedStatus === RfqStatus.CLOSED ? (
        <ClosedInfo />
      ) : (
        <>
          {extendedStatus !== RfqStatus.AWARDED && (
            <NotAwardedInfo />
          )}
          <FinalValuePanel />
          <TotalSavingsPanel />
          <SpecificSavingsPanels />
        </>
      )}
    </CurrencyCodeProvider>
  );
};
