import { Text, Flex } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { BidProgress } from '@deepstream/common/bidProgress';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { LabeledValue } from './draft/LabeledValue';
import { ProgressPercentage } from './ui/ProgressPercentage';

const valueProps = { fontWeight: 400 };

/**
 * Renders details about the *recipient-side* bid progress.
 */
export const BidProgressDetails = ({ progress }: { progress: BidProgress }) => {
  const { t } = useTranslation();

  const total = progress.awaitingRecipient + progress.awaitingSender + progress.complete + progress.awaitingTeam;

  return (
    <Flex sx={{ gap: 3 }}>
      {progress.awaitingRecipient >= 0 && (
        <LabeledValue
          align="left"
          label={t('request.progress.complete')}
          value={(
            <ProgressPercentage progress={progress.complete / total} width={14} />
          )}
          valueProps={valueProps}
        />
      )}
      {progress.awaitingRecipient >= 0 && (
        <LabeledValue
          align="left"
          label={t('request.progress.yourActions')}
          value={
            progress.awaitingRecipient === 0 ? (
              <IconText
                text={t('general.none')}
                icon="check"
                iconColor="subtext"
              />
            ) : (
              <IconText
                text={progress.awaitingRecipient}
                icon="exclamation-circle"
                iconColor="danger"
              />
            )
          }
          valueProps={valueProps}
        />
      )}
      {progress.awaitingTeam > 0 && (
        <LabeledValue
          align="left"
          label={t('request.progress.teamActions')}
          value={
            <Flex alignItems="center" sx={{ gap: 2 }}>
              <Icon
                color="gray"
                ml={1}
                icon="exclamation-circle"
              />
              <Text>
                {progress.awaitingTeam}
              </Text>
            </Flex>
          }
          valueProps={valueProps}
        />
      )}
    </Flex>
  );
};
