import { useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { Company } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { useHover } from '@deepstream/ui-kit/hooks/useHover';
import { FrozenHeaderCellProps } from '@deepstream/ui-kit/grid/core/utils';
import { useMediaQueries } from '@deepstream/ui-kit/hooks/useMediaQueries';
import { DropdownMenuItem, EllipsisMenu } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { DEFAULT_FROZEN_HEADER_HEIGHT } from '@deepstream/ui-kit/grid/core/constants';
import { isNil } from 'lodash';
import { OverflowTooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { RfxStructure } from '../../../../types';
import { CompanyLogo } from '../../../../CompanyLogo';
import { SubcolumnCellContainer } from './SubcolumnCellContainer';
import { LineItemsSubcolumnConfig } from './types';
import { LabeledUniversalBidStatus } from '../createRecipientHeaderCell';

const lineItemsHeaderFirstLineHeight = 48;
const lineItemsHeaderSecondLineHeight = 30;

export const lineItemsHeaderHeight = lineItemsHeaderFirstLineHeight + lineItemsHeaderSecondLineHeight;

 const SubcolumnCell = ({ subcolumnConfig }: { subcolumnConfig: { _id: string; label?: any; } }) => {
    const { t } = useTranslation();

    const label = !isNil(subcolumnConfig.label) ? subcolumnConfig.label : t(`request.fields.predefinedFieldLabel.${subcolumnConfig._id.split(':')[0]}`);

    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        fontSize={0}
        fontWeight={500}
        color="gray"
        sx={{ textTransform: 'uppercase', letterSpacing: '0.083333em' }}
      >
        <OverflowTooltip content={label}>
          <Truncate>{label}</Truncate>
        </OverflowTooltip>
      </Flex>
    );
  };

export const createLineItemRecipientHeaderCell = ({
  structure,
  navigateToBidPage,
  showBidStatus,
  evaluatorSubcolumnConfig,
  submitterSubcolumnConfig,
}: {
  structure: RfxStructure;
  navigateToBidPage: (companyId: string) => void;
  showBidStatus?: boolean;
  evaluatorSubcolumnConfig: LineItemsSubcolumnConfig[];
  submitterSubcolumnConfig: LineItemsSubcolumnConfig[];
}) =>
  ({ column }: FrozenHeaderCellProps<Company>) => {
    const [cellRef, cellHovered] = useHover<HTMLTableCellElement>();
    const [isDropdownExpanded, setIsDropdownExpanded] = useState(false);
    const { canHover: deviceCanHover } = useMediaQueries();
    const { t } = useTranslation('translation');
    const companyId = column.original._id;

    if (column.index === 0) {
      return (
        <Flex flexDirection="column" width="100%">
          <Flex
            alignItems="center"
            justifyContent="stretch"
            width="100%"
            ref={cellRef}
            sx={{ padding: '8px', height: lineItemsHeaderFirstLineHeight }}
          />
          <Box
            sx={{
              height: lineItemsHeaderSecondLineHeight,
              borderTop: 'lightGray',
            }}
          >
            <Flex
              sx={{ height: '100%', width: '100%' }}
              fontWeight="normal"
              p={2}
              pl="20px"
            >
              <SubcolumnCell subcolumnConfig={{ _id: 'description' }} />
            </Flex>
          </Box>
        </Flex>
      );
    }

    const isBuyerFieldsColumn = structure.senders.some((sender) => sender._id === companyId);

    return (
      <Flex flexDirection="column" width="100%">
        <Flex
          alignItems="center"
          justifyContent="stretch"
          width="100%"
          ref={cellRef}
          sx={{ padding: '8px', height: DEFAULT_FROZEN_HEADER_HEIGHT }}
        >
          {!isBuyerFieldsColumn && (
            <Box width="32px">
              <CompanyLogo companyId={companyId} size="xs" backgroundColor="white" />
            </Box>
          )}
          <Flex flexDirection="column" flex={1}>
            <Truncate fontWeight={500}>
              {isBuyerFieldsColumn ? t('request.lineItems.buyerProvidedFields') : column.original.company.name}
            </Truncate>
            {!isBuyerFieldsColumn && showBidStatus && structure.bidById[companyId] ? (
              <LabeledUniversalBidStatus
                universalBidStatus={structure.bidById[companyId].universalStatus}
              />
            ) : (
              null
            )}
          </Flex>
          {!isBuyerFieldsColumn && (
            <Box width="28px">
              {(!deviceCanHover || cellHovered || isDropdownExpanded) && (
                <EllipsisMenu
                  small
                  variant="secondary-outline"
                  menuZIndex={202}
                  width="28px"
                  onExpandedStateChange={setIsDropdownExpanded}
                >
                  <DropdownMenuItem
                    icon="external-link"
                    iconColor="primary"
                    onSelect={() => navigateToBidPage(companyId)}
                  >
                    {t('request.comparison.openBidPage')}
                  </DropdownMenuItem>
                </EllipsisMenu>
              )}
            </Box>
          )}
        </Flex>
        <Box
          sx={{
            height: lineItemsHeaderSecondLineHeight,
            borderTop: 'lightGray',
          }}
        >
          <Flex flexDirection="row" alignItems="center" height="100%" width="100%">
            {(isBuyerFieldsColumn ? evaluatorSubcolumnConfig : submitterSubcolumnConfig).map((subcolumnConfig, index) => {
              return (
                <SubcolumnCellContainer
                  key={subcolumnConfig._id}
                  width={subcolumnConfig.width}
                  hideBorder={index === 0}
                >
                  {subcolumnConfig._id === 'exchangeStatus' ? (
                    null
                  ) : (
                    <SubcolumnCell subcolumnConfig={subcolumnConfig} />
                  )}
                </SubcolumnCellContainer>
              );
            })}
          </Flex>
        </Box>
      </Flex>
    );
  };
