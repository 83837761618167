import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';

import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { PanelHeader } from '@deepstream/ui-kit/elements/Panel';
import {
  DeleteMenuItem,
  DropdownMenu,
  DropdownMenuHeader,
  DropdownMenuItem,
} from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { DocumentsBatchDownload } from './DocumentsBatchDownload';
import { DocumentsDeleteModal } from './DocumentsDeleteModal';
import { useTableData } from '../../TableDataContext';
import { DocumentsModalAdd } from './DocumentModal';
import { openFilePicker } from '../../uploads/openFilePicker';

const AddDocuments = () => {
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>();

  const handleOnClick = useCallback(async () => {
    const files = await openFilePicker({ multiple: true });

    setSelectedFiles(files);
  }, []);

  return (
    <>
      {selectedFiles && selectedFiles.length && (
        <DocumentsModalAdd
          files={selectedFiles}
          onAllDone={() => {
            setSelectedFiles(null);
          }}
        />
      )}
      <Button
        small
        iconLeft="plus"
        onClick={handleOnClick}
        data-test="add-documents-button"
      >
        {t('drivePage.actions.addDocuments')}
      </Button>
    </>
  );
};

export const DocumentsTableHeader = ({
  selection,
}) => {
  const { t } = useTranslation();
  const { data } = useTableData();
  const hasSelection = selection.length !== 0;
  const documentsCount = data.length;
  const heading = documentsCount === 0
    ? t('drivePage.documentsTableTitleEmpty')
    : t('drivePage.documentsTableTitle', { count: documentsCount });

  return (
    <PanelHeader
      heading={
        <Text fontSize={2} fontWeight={500}>
          {heading}
        </Text>
      }
    >
      <Flex>
        <Box mr={2}>
          {!!documentsCount && (
            <DropdownMenu
              rightAligned
              small
              iconRight="caret-down"
              variant="secondary"
              buttonText={t('general.moreActions')}
              menuStyle={{ width: '160px' }}
              header={!hasSelection ? (
                <DropdownMenuHeader>
                  {t('drivePage.actions.selectionWarning')}
                </DropdownMenuHeader>
              ) : undefined}
            >
              <DocumentsBatchDownload
                documents={selection}
                trigger={({ download }) => (
                  <DropdownMenuItem
                    disabled={!hasSelection}
                    icon="download"
                    onSelect={download}
                  >
                    {t('general.download')}
                  </DropdownMenuItem>
                )}
              />
              <DocumentsDeleteModal
                documents={selection}
                trigger={({ open: openDeleteModal }) => (
                  <DeleteMenuItem
                    disabled={!hasSelection}
                    onSelect={openDeleteModal}
                  />
                )}
              />
            </DropdownMenu>
          )}
        </Box>
        <AddDocuments />
      </Flex>
    </PanelHeader>
  );
};
