import * as React from 'react';
import { useIsFetching, useIsMutating } from 'react-query';
import { find, findIndex } from 'lodash';
import { ExchangeStatus } from '@deepstream/common/rfq-utils';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import * as rfx from '../rfx';
import { useSwitchToExchange } from './SwitchToExchange';
import { useCurrentUser } from '../useCurrentUser';
import { EvaluationExchangeSnapshot } from '../types';
import { useCurrentCompanyId } from '../currentCompanyId';
import { useGetEvaluationExchangeStatus } from './useGetEvaluationExchangeStatus';

export const EvaluationComparisonAutoAdvancer = ({
  exchangeId,
  recipientId,
  setIsLoading,
  advanceMode = 'vertical',
}: {
  exchangeId: string;
  recipientId?: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  advanceMode?: 'vertical' | 'horizontal';
}) => {
  const structure = rfx.useStructure();
  const exchanges = rfx.useExchanges();
  const currentUser = useCurrentUser();
  const companyId = useCurrentCompanyId();
  // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const bid = structure.bidById[recipientId];
  const [watchExchangeStateChange, setWatchExchangeStateChange] = React.useState(false);
  const isMutating = useIsMutating(['sendRfxExchangeReply']);
  const { switchToExchange, verticalTargets, horizontalTargets } = useSwitchToExchange();
  const getStatus = useGetEvaluationExchangeStatus();

  const isFetchingExchange = useIsFetching({ queryKey: ['exchange'] });

  const isFetchingOrMutating = (
    isMutating ||
    isFetchingExchange
  );

  const exchange = React.useMemo(() =>
      find(exchanges, { _id: exchangeId, recipientId }) as EvaluationExchangeSnapshot,
    [exchanges, exchangeId, recipientId],
  );
  const status = getStatus(exchange);
  const isResolved = status !== ExchangeStatus.ACTION_REQUIRED;

  const combinedOrderedTargets = React.useMemo(() => {
    if (!verticalTargets || !horizontalTargets) {
      return [];
    }

    if (advanceMode === 'vertical') {
      return horizontalTargets.flatMap(horizontalTarget => verticalTargets.map((verticalTarget) => ({
        ...verticalTarget,
        ...horizontalTarget,
      })));
    }

    if (advanceMode === 'horizontal') {
      return verticalTargets.flatMap(verticalTarget => horizontalTargets.map((horizontalTarget) => ({
        ...verticalTarget,
        ...horizontalTarget,
      })));
    }
  }, [advanceMode, horizontalTargets, verticalTargets]);

  useWatchValue(
    exchangeId,
    () => setWatchExchangeStateChange(false),
  );
  useWatchValue(
    recipientId,
    () => setWatchExchangeStateChange(false),
  );
  useWatchValue(
    isFetchingOrMutating,
    (isFetchingOrMutating, wasFetchingOrMutating) => {
      if (wasFetchingOrMutating && !isFetchingOrMutating) {
        setIsLoading(false);
      }

      if (isFetchingOrMutating && !wasFetchingOrMutating) {
        setIsLoading(true);
      }
    },
  );
  useWatchValue(
    isMutating,
    (isMutating, wasMutating) => {
      if (wasMutating && !isMutating) {
        setWatchExchangeStateChange(true);
      }
    },
  );

  React.useEffect(
    () => {
      if (!combinedOrderedTargets?.length) {
        return;
      }

      if (
        watchExchangeStateChange && isResolved
      ) {
        setWatchExchangeStateChange(false);

        const currentTargetIndex = findIndex(combinedOrderedTargets, { exchangeId, recipientId });
        const nextTarget = [
          ...combinedOrderedTargets.slice(currentTargetIndex + 1),
          ...combinedOrderedTargets.slice(0, currentTargetIndex),
        ]
          .find(({ exchangeId: nextExchangeId, recipientId: nextRecipientId }) => {
            const nextExchange = find(exchanges, { _id: nextExchangeId, recipientId: nextRecipientId }) as EvaluationExchangeSnapshot;
            const nextExchangeStatus = getStatus(nextExchange);
            const isNextExchangeResolved = nextExchangeStatus !== ExchangeStatus.ACTION_REQUIRED;

            return !isNextExchangeResolved;
          });

        if (nextTarget) {
          switchToExchange(nextTarget);
        }
      }
    },
    [
      advanceMode,
      bid.sectionById,
      companyId,
      currentUser._id,
      exchangeId,
      exchanges,
      horizontalTargets,
      isResolved,
      structure.sectionById,
      verticalTargets,
      watchExchangeStateChange,
      switchToExchange,
      recipientId,
      combinedOrderedTargets,
      structure.bidById,
      getStatus,
    ],
  );

  return null;
};
