import { useMemo } from 'react';
import { noop } from 'lodash';
import { Box } from 'rebass/styled-components';

import { useSystemFeatureFlags } from '../../systemFeatureFlags';
import { useDeviceSize } from '../../ui/useDeviceSize';
import { RequestsReportingDataProvider } from '../Reporting/useRequestsReporting';

import { UserPanel } from './UserPanel';

import { RecentContracts } from './RecentContracts';
import { RecentRequests } from './RecentRequests';
import { UnreadNotifications } from './UnreadNotifications';

import { RequestsReportingHeadlineStats, RequestsReportingSavingsPerInterval, RequestResponseRate } from './ReportingPanels';
import { SelectedCompanyIdsContext } from '../../selectedCompanyIds';
import { useCurrentCompanyId } from '../../currentCompanyId';

export const Dashboard = ({
  onItemClick,
  onTeamManagementClick = noop,
  onGoToAllClick = noop,
}: {
  onItemClick: (type: 'request' | 'contract' | 'notification', data: any) => void;
  onTeamManagementClick: () => void;
  onGoToAllClick?: (type: 'requests' | 'contracts' | 'notifications' | 'report') => void;
}) => {
  const { isExtraLarge, isExtraSmall, isSmall } = useDeviceSize();
  const systemFeatureFlags = useSystemFeatureFlags();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const gridStyle = useMemo(() => {
    let columns = 2;

    if (isExtraLarge) columns = 3;
    if (isSmall || isExtraSmall) columns = 1;

    const gridTemplateColumns = `repeat(${columns}, 1fr)`;
    const gridTemplateRows = isExtraLarge ? 358 : 'auto';
    const gridAutoRows = isExtraLarge ? 358 : 'auto';

    return {
      gridTemplateColumns,
      gridTemplateRows,
      gridAutoRows,
    };
  }, [isExtraLarge, isSmall, isExtraSmall]);

  const selectedCompanyIds = useMemo(() => [currentCompanyId], [currentCompanyId]);

  return (
    <SelectedCompanyIdsContext.Provider value={selectedCompanyIds}>
      <RequestsReportingDataProvider>
        <>
          <UserPanel />
          <Box
            sx={{
              display: 'grid',
              gap: 20,
              flexWrap: 'wrap',
              ...gridStyle,
            }}
          >
            <RecentRequests
              onItemClick={(data) => onItemClick('request', data)}
              onGoToAllClick={() => onGoToAllClick('requests')}
            />
            {/*
            // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'. */}
            {systemFeatureFlags.contractManagementEnabled && (
              <RecentContracts
                onItemClick={(data) => onItemClick('contract', data)}
                onGoToAllClick={() => onGoToAllClick('contracts')}
              />
            )}
            <RequestsReportingHeadlineStats
              onGoToAllClick={() => onGoToAllClick('report')}
              onTeamManagementClick={onTeamManagementClick}
            />
            <RequestsReportingSavingsPerInterval
              onGoToAllClick={() => onGoToAllClick('report')}
              onTeamManagementClick={onTeamManagementClick}
            />
            <UnreadNotifications
              onItemClick={(data) => onItemClick('notification', data)}
              onGoToAllClick={() => onGoToAllClick('notifications')}
            />
            <RequestResponseRate
              onGoToAllClick={() => onGoToAllClick('report')}
              onTeamManagementClick={onTeamManagementClick}
            />
          </Box>
        </>
      </RequestsReportingDataProvider>
    </SelectedCompanyIdsContext.Provider>
  );
};
