import { useTranslation } from 'react-i18next';

import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { LoadingPanel } from '../../../ui/Loading';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import { useQuestionnaire } from './useQuestionnaire';
import { QuestionnaireProvider, useQuestionnaireId } from './questionnaireUtils';
import { QuestionnaireBasicDetailsPanel } from './Details/QuestionnaireBasicDetailsPanel';
import { QuestionnairePagePanels } from './QuestionnairePagePanels';
import { QuestionnaireCompanyPanel } from './Details/QuestionnaireCompanyPanel';
import { ExpiryAndRenewalPanel } from './Details/ExpiryAndRenewalPanel';
import { PurposePanel } from './Details/PurposePanel';
import { useCurrentCompanyId } from '../../../currentCompanyId';

export const QuestionnaireDetails = () => {
  const { t } = useTranslation();
  const questionnaireId = useQuestionnaireId({ required: true });
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { data: questionnaire, isLoading, isError } = useQuestionnaire({
    questionnaireId,
  });

  if (isLoading) {
    return <LoadingPanel />;
  }

  if (isError) {
    return <ErrorPanel error={t('errors.unexpected')} />;
  }

  // @ts-expect-error ts(2339) FIXME: Property 'renewalConfig' does not exist on type 'Questionnaire | undefined'.
  const { renewalConfig, expiryConfig, senderIds } = questionnaire;
  const isSender = senderIds.includes(currentCompanyId);

  return (
    // @ts-expect-error ts(2322) FIXME: Type 'Questionnaire | undefined' is not assignable to type 'Questionnaire'.
    <QuestionnaireProvider questionnaire={questionnaire}>
      <Stack gap="24px">
        <QuestionnairePagePanels />
        <QuestionnaireBasicDetailsPanel />
        {isSender && <PurposePanel />}
        <QuestionnaireCompanyPanel />
        {(renewalConfig.isRecurring || expiryConfig.doesExpire) && <ExpiryAndRenewalPanel />}
      </Stack>
    </QuestionnaireProvider>
  );
};
