import { Form, Formik } from 'formik';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { noop } from 'lodash';
import { Box } from 'rebass/styled-components';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { SupplierListType } from '@deepstream/common';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useQuery } from 'react-query';
import { ListConfigFlowData, ListConfigFlowStepType } from '../types';
import * as layout from '../../../Request/Live/lotPagesLayout';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { StepNavigation2 } from '../../../../ui/MultiStepFlow/StepNavigation';
import { RadioField } from '../../../../form/RadioField';
import { listTypeIconProps } from '../../utils';
import { useCompanyFeatureFlags } from '../../../../companyFeatureFlags';
import { useUserFlags } from '../../../../UserFlagsContext';
import { useCurrentCompanyId } from '../../../../currentCompanyId';
import { useApi, wrap } from '../../../../api';
import { useMultiStepFlowData } from '../../../../ui/MultiStepFlow/MultiStepFlowContext';

export const ListTypeStep = () => {
  const { t } = useTranslation();
  const { belongsToPayingCompany } = useUserFlags();
  const { preQualEnabled } = useCompanyFeatureFlags();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const { data } = useMultiStepFlowData<ListConfigFlowStepType, ListConfigFlowData>();

  const { data: activeTemplates } = useQuery(
    ['activeQuestionnaireTemplates', { currentCompanyId }],
    wrap(api.getActiveQuestionnaireTemplates),
  );

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      listType: z.nativeEnum(SupplierListType, { message: t('supplierLists.configFlow.errors.requiredListType') }),
    });

    return toFormikValidationSchema(Schema);
  }, [t]);

  const hasPreQualEnabled = belongsToPayingCompany && preQualEnabled;
  // @ts-expect-error ts(18048) FIXME: 'activeTemplates.totalItems' is possibly 'undefined'.
  const hasActiveTemplates = activeTemplates?.totalItems > 0;

  const options = useMemo(
    () => [SupplierListType.MANUAL, SupplierListType.AUTOMATIC].map(listType => ({
      value: listType,
      label: (
        <Box fontSize={4} mt="-5px" color="text">
          <Icon {...listTypeIconProps[listType]} mr="6px" mt="2px" />
          {t(`supplierLists.listType.${listType}`)}
        </Box>
      ),
      description: (
        <Box as="span" fontSize={2}>
          {listType === SupplierListType.AUTOMATIC && !hasPreQualEnabled ? (
            t('supplierLists.configFlow.preQualDisabledDescription')
          ) : listType === SupplierListType.AUTOMATIC && !hasActiveTemplates ? (
            t('supplierLists.configFlow.noActiveTemplatesDescription')
          ) : (
            t(`supplierLists.configFlow.listTypeDescription.${listType}`)
          )}
        </Box>
      ),
      disabled: listType === SupplierListType.AUTOMATIC && (!hasPreQualEnabled || !hasActiveTemplates),
    })),
    [hasPreQualEnabled, hasActiveTemplates, t],
  );

  return (
    <Formik
      validateOnBlur
      initialValues={{
        listType: data.listType,
      }}
      validationSchema={validationSchema}
      onSubmit={noop}
    >
      {() => (
        <Form>
          <layout.ContentWrapper2>
            <StepNavigation2<ListConfigFlowStepType, ListConfigFlowData>
              showBackButton
              showContinueButton
            >
              <FormErrors />
              <layout.Section2
                heading={data._id
                  ? t('supplierLists.configFlow.adjustListType')
                  : t('supplierLists.configFlow.chooseListType')}
              >
                <Stack gap="20px" px={1}>
                  <RadioField
                    name="listType"
                    options={options}
                    showError
                    gap="20px"
                    errorMessageStyle={{ fontSize: '14px', marginTop: '16px' }}
                  />
                  <layout.InfoText2>
                    {t('supplierLists.configFlow.listTypeInfo')}
                  </layout.InfoText2>
                </Stack>
              </layout.Section2>
            </StepNavigation2>
          </layout.ContentWrapper2>
        </Form>
      )}
    </Formik>
  );
};
