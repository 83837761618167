import { RequestComments } from '../RequestComments';
import { DraftHeader } from './DraftHeader';
import * as Layout from '../../Layout';

export const DraftCommentsPageContent = ({ rfqId }: { rfqId: string }) => {
  const tabId = 'comments';

  return (
    <Layout.HeaderAndContent
      header={
        <DraftHeader
          rfqId={rfqId}
          isRevising={false}
          isTemplate={false}
          selectedTabId={tabId}
        />
      }
      content={
        <RequestComments rfqId={rfqId} />
      }
    />
  );
};
