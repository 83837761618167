import { useState } from 'react';
import { Draft, StageType } from '@deepstream/common/rfq-utils';
import styled from 'styled-components';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { useHover } from '@deepstream/ui-kit/hooks/useHover';
import { useMediaQueries } from '@deepstream/ui-kit/hooks/useMediaQueries';
import { Button, ButtonProps, ContactToUpgradeButton } from '@deepstream/ui-kit/elements/button/Button';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useCompanyFeatureFlags } from '../companyFeatureFlags';
import { StageTimer } from './StageTimer';
import * as rfx from '../rfx';
import { useSystemFeatureFlags } from '../systemFeatureFlags';
import { StageIcon } from './StageIcon';
import { StructureStage } from '../types';
import { getLowerBound, getUpperBound } from './stageUtils';
import { useIntercomTrackEvent } from '../ui/useIntercomTrackEvent';

const VerticalSeparator = styled(Box)`
  width: 2px;
  background-color: ${props => props.theme.colors.lightGray2};
`;

const AddStageDropdown = ({
  onSelect,
  onExpandedStateChange,
  canAddAuctionStage,
  ...props
}: Omit<ButtonProps, 'onSelect'> & {
  onSelect: (stageType: StageType) => void;
  onExpandedStateChange?: (isExpanded: boolean) => void;
  canAddAuctionStage: boolean;
}) => {
  const { t } = useTranslation('request');
  const { stages, settings } = rfx.useStructure<Draft>();
  const systemFeatureFlags = useSystemFeatureFlags();
  const companyFeatureFlags = useCompanyFeatureFlags();
  const handleUpgradeClick = useIntercomTrackEvent({ eventName: 'stage:add-auction:upgrade', showChat: true });

  const isAuctionDropdownMenuDisabled = (
    canAddAuctionStage &&
    companyFeatureFlags?.auctionsEnabled &&
    !settings.areLotsEnabled &&
    !stages.some((stage) => stage.type === StageType.AUCTION)
  );

  return (canAddAuctionStage && systemFeatureFlags?.auctionsEnabled) ? (
    <Box>
      <DropdownMenu
        buttonText={t('request.stages.addStage', { ns: 'translation' })}
        small
        iconLeft="plus"
        iconRight="caret-down"
        onExpandedStateChange={onExpandedStateChange}
        menuZIndex={2}
        menuStyle={{ width: '324px' }}
        {...props}
      >
        <DropdownMenuItem onSelect={() => onSelect(StageType.GENERAL)}>
          <Flex pr={1} alignItems="center">
            <Box pt="6px" pl={1} pr={3} flexShrink={0}>
              <StageIcon stageType={StageType.GENERAL} />
            </Box>
            <Box>
              <Text lineHeight="18px" color="text" width="220px">
                {t('request.stages.stageType.general', { ns: 'translation' })}
              </Text>
              <Text fontSize={1} lineHeight="15px" color="subtext" mt="2px" width="220px">
                {t('request.stages.stageTypeDescription.general', { ns: 'translation' })}
              </Text>
            </Box>
          </Flex>
        </DropdownMenuItem>
        <DropdownMenuItem onSelect={() => onSelect(StageType.AUCTION)} disabled={!isAuctionDropdownMenuDisabled}>
          <Flex pr={1}>
            <Box pt="6px" pl={1} pr={3} flexShrink={0}>
              <StageIcon stageType={StageType.AUCTION} />
            </Box>
            <Box>
              <Text lineHeight="18px" color="text" width="220px">
                {t('request.stages.stageType.auction', { ns: 'translation' })}
              </Text>
              <Text fontSize={1} lineHeight="15px" color="subtext" mt="2px" width="220px">
                {t('request.stages.stageTypeDescription.auction', { ns: 'translation' })}
              </Text>
            </Box>
          </Flex>
        </DropdownMenuItem>
        {!companyFeatureFlags?.auctionsEnabled ? (
          <Box sx={{ borderTop: 'secondary', p: 3, pb: 2 }}>
            <Text fontSize={1}>
              {t('stages.info.companyAuctionDisabled', { ns: 'request' })}
            </Text>
            <ContactToUpgradeButton small mt={2} onClick={handleUpgradeClick} />
          </Box>
        ) : settings.areLotsEnabled ? (
          <Box sx={{ borderTop: 'secondary', p: 3, pb: 2 }}>
            <Text fontSize={1} color="subtext">
              <Icon icon="info-circle" regular mr={1} />
              {t('stages.info.cannotAddAuction', { ns: 'request' })}
            </Text>
          </Box>
        ) : (
          null
        )}
      </DropdownMenu>
    </Box>
  ) : (
    <Button
      small
      iconLeft="plus"
      onClick={() => onSelect(StageType.GENERAL)}
      {...props}
    >
      {t('request.stages.addStage', { ns: 'translation' })}
    </Button>
  );
};

type HoverableButtonContainerProps = {
  canAddStage?: boolean;
  addIntermediateStage: (type: StageType) => void;
  canAddAuctionStage: boolean;
};

const HoverableButtonContainer = ({ canAddStage, addIntermediateStage, canAddAuctionStage }: HoverableButtonContainerProps) => {
  const [hoverRef, hover] = useHover();
  const { canHover: deviceCanHover } = useMediaQueries();
  const [isDropdownExpanded, setIsDropdownExpanded] = useState(false);

  return (
    <Flex
      ref={hoverRef}
      justifyContent="center"
      minWidth="130px"
      sx={{ position: 'absolute', top: 0, bottom: 0, left: 'calc(50% - 65px)' }}
    >
      {!deviceCanHover || isDropdownExpanded || hover ? (
        <AddStageDropdown
          variant="secondary"
          mt="10px"
          disabled={!canAddStage}
          onExpandedStateChange={setIsDropdownExpanded}
          onSelect={addIntermediateStage}
          canAddAuctionStage={canAddAuctionStage}
        />
      ) : (
        null
      )}
    </Flex>
  );
};

type StageDelimiterProps = {
  stage: StructureStage<Draft>;
  stageIndex: number;
  canAddStage?: boolean;
  editMode?: boolean;
  addStage: (type: StageType, index?: number) => void;
  canAddAuctionStage: boolean;
};

export const StageDelimiter = ({ stage, stageIndex, canAddStage, editMode, addStage, canAddAuctionStage }: StageDelimiterProps) => {
  const { sectionById, stages } = rfx.useStructure<Draft>();

  const isLastStage = stageIndex === stages.length - 1;
  const nextStage = !isLastStage
    ? stages[stageIndex + 1]
    : null;

  const currentDate = getUpperBound(sectionById, stage);
  const nextDate = getLowerBound(nextStage);

  return isLastStage ? (
    <Flex flexDirection="column" alignItems="center">
      <VerticalSeparator height="20px" />
      <AddStageDropdown
        variant={stages.length > 1 ? 'secondary' : 'primary'}
        disabled={!canAddStage}
        onSelect={type => addStage(type)}
        canAddAuctionStage={canAddAuctionStage}
      />
    </Flex>
  ) : (
    <Flex justifyContent="space-between" alignItems="center" sx={{ position: 'relative', width: '100%' }}>
      <VerticalSeparator height="48px" ml="50%" />
      {currentDate && nextDate && (
        <StageTimer
          currentDate={currentDate}
          nextDate={nextDate}
          pr="20px"
          stageIndex={stageIndex}
        />
      )}
      {!nextStage?.isLive && !editMode && (
        <HoverableButtonContainer
          canAddStage={canAddStage}
          canAddAuctionStage={canAddAuctionStage}
          addIntermediateStage={type => addStage(type, stageIndex + 1)}
        />
      )}
    </Flex>
  );
};
