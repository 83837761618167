import { useCallback } from 'react';
import { isDefinitionField, isFieldValueDefined } from '@deepstream/common/rfq-utils';
import { DateFormat, localeFormatDate, localeFormatFactorAsPercent } from '@deepstream/utils';
import { useTranslation } from 'react-i18next';
import { SupplierProvidedValueCell, getSupplierProvidedValueCell } from './nonValidationAwareValueCell';
import { useCurrentUserLocale } from '../../useCurrentUser';
import { ExchangeDefWithFields } from '../../ExchangeDefFieldValueContext';

export const useGetExchangeDefCsvCellValue = ({
  getFieldValue,
}: {
  getFieldValue: (exchangeDef: ExchangeDefWithFields, fieldId: string) => any;
}) => {
  const { t } = useTranslation('translation');
  const locale = useCurrentUserLocale();

  return useCallback((
    columnConfig,
    exchangeDef,
  ) => {
    const { staticValue, accessorFn, format } = columnConfig;

    if (staticValue) {
      return staticValue;
    }

    const field = exchangeDef.fields[columnConfig._id];

    if (columnConfig.ValueCell === SupplierProvidedValueCell) {
      const cell = getSupplierProvidedValueCell({
        exchangeDef,
        columnConfig,
        locale,
        t,
      });

      return cell.value ?? '';
    }

    if (!field || !isDefinitionField(field)) {
      return '';
    }

    const fieldValue = accessorFn
      ? accessorFn(exchangeDef)
      : getFieldValue(exchangeDef, field._id);

    if (!isFieldValueDefined(fieldValue, field.type)) {
      return '';
    }

    switch (field.type) {
      case 'date': {
        const dateFormat = locale === 'zh-CN'
          ? DateFormat.YYYY_MM_DD_SLASH
          : DateFormat.DD_MMM_YYYY;
        return localeFormatDate(new Date(fieldValue as string | number | Date), dateFormat, { locale });
      }
      case 'number':
        if (format === 'percent') {
          return localeFormatFactorAsPercent(fieldValue as number, { locale });
        } else {
          return fieldValue;
        }
      case 'price':
        return (fieldValue as number).toFixed(field.decimalPlaces ?? 2);
      case 'unspscCode': {
        const product = fieldValue as { _id: string; title: string };

        return `[${product._id}] ${product.title}`;
      }
      default:
        return fieldValue;
    }
  }, [t, locale, getFieldValue]);
};
