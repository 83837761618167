import * as React from 'react';
import * as Sentry from '@sentry/react';
import { Box, Flex } from 'rebass/styled-components';
import { QuestionType, DateTimeQuestionExchangeDefinition, QuestionExchangeDefinition, QuestionFormat } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { UnexpectedStateError } from '@deepstream/errors';
import { DisabledInputBox } from '../../../ui/Input';
import { DateTimePlaceholderText } from '../../../DateTimePlaceholderText';
import { CheckboxFieldBase } from '../../../form/CheckboxField';

export const DisabledDateTimeField = ({
  exchangeDef,
  fieldMaxWidth,
}: {
  exchangeDef: QuestionExchangeDefinition;
  fieldMaxWidth?: React.CSSProperties['maxWidth'];
}) => {
  const { t } = useTranslation();
  const { questionType, format } = exchangeDef as DateTimeQuestionExchangeDefinition;

  if (questionType !== QuestionType.DATE_TIME) {
    Sentry.captureException(new UnexpectedStateError('[DateTime] Wrong question type passed', { questionType }));
    return null;
  }

  return (
    <Flex maxWidth={fieldMaxWidth} alignItems="center">
      <DisabledInputBox flex={1}>
        <DateTimePlaceholderText format={format} />
      </DisabledInputBox>
      <Box ml="24px">
        <CheckboxFieldBase
          disabled
          value={[QuestionFormat.DATE, QuestionFormat.DATETIME].includes(format)}
          fieldLabel={t('request.question.date')}
        />
      </Box>
      <Box ml="24px">
        <CheckboxFieldBase
          disabled
          value={[QuestionFormat.TIME, QuestionFormat.DATETIME].includes(format)}
          fieldLabel={t('request.question.time')}
        />
      </Box>
    </Flex>
  );
};
