import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box } from 'rebass/styled-components';
import { useQuery } from 'react-query';
import { CompanyMinimized } from '@deepstream/common/rfq-utils';
import { first, get, isEmpty, orderBy } from 'lodash';
import { LabeledSorting } from '@deepstream/ui-utils';
import { SortingDirection } from '@deepstream/common';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelHeader, PanelDivider, PanelProps } from '@deepstream/ui-kit/elements/Panel';
import { useDeviceSize } from '../../../ui/useDeviceSize';
import { useModalState } from '../../../ui/useModalState';
import { useApi, wrap } from '../../../api';
import { ContractTeamUsersTable } from './ContractTeamUsersTable';
import { useSortProps } from '../../../sorting';
import { SelectDropdownMenu } from '../../../ui/MultiSelect';
import { useContractData, useContractPermissions } from '../contract';
import { ContractTeamUserModal } from './ContractTeamUserModal';

const useSortItems = (): LabeledSorting[] => {
  const { t } = useTranslation('general');

  return useMemo(() => [
    { label: t('sorting.nameAscending'), accessor: 'name', direction: SortingDirection.ASC },
    { label: t('sorting.nameDescending'), accessor: 'name', direction: SortingDirection.DESC },
  ], [t]);
};

type ContractTeamUsersProps = Omit<PanelProps, 'children'> & {
  company: CompanyMinimized;
};

export const ContractTeamUsersPanel = ({
  company,
  ...props
}: ContractTeamUsersProps) => {
  const { t } = useTranslation(['contracts', 'general']);
  const api = useApi();
  const { isExtraSmall } = useDeviceSize();
  const { teamById } = useContractData();
  const addUserModal = useModalState();
  const { canEditTeam } = useContractPermissions();

  const { data: usersForCompany } = useQuery(
    ['usersForCompany', { companyId: company._id }],
    wrap(api.getUsersForCompany),
  );

  const users = useMemo(() => Object.values(teamById[company._id].users)
    .map(user => {
      const userForCompany = usersForCompany?.find(
        userInCompany => userInCompany._id === user._id,
      );

      return {
        _id: user._id,
        name: user.name || userForCompany?.name,
        email: user.email || userForCompany?.email,
        companyId: company._id,
        roleRestrictionProfiles: userForCompany?.roleRestrictionProfiles,
      };
    }), [usersForCompany, teamById, company._id]);

  const sortItems = useSortItems();
  const sort = useSortProps({
    items: sortItems,
  });

  const sortedUsers = useMemo(() => {
    let usersClone = [...users];

    if (!isEmpty(sort.selectedItems)) {
      const sorting = first(sort.selectedItems);

      usersClone = orderBy(
        usersClone,
        // @ts-expect-error ts(18048) FIXME: 'sorting' is possibly 'undefined'.
        [(user) => get(user, sorting.accessor, '').toLowerCase()],
        // @ts-expect-error ts(18048) FIXME: 'sorting' is possibly 'undefined'.
        [sorting.direction],
      );
    }

    return usersClone;
  }, [users, sort]);

  return (
    <>
      <Panel {...props}>
        <PanelHeader heading={t('user_other', { ns: 'general' })}>
          <Flex>
            {!!users.length && (
              <Box>
                <SelectDropdownMenu
                  buttonText={t('sort', { ns: 'general' })}
                  buttonIcon="sort"
                  menuWidth={160}
                  menuZIndex={10}
                  {...sort}
                />
              </Box>
            )}
            {canEditTeam && (
              <Button
                ml={isExtraSmall ? 3 : '24px'}
                variant="primary"
                iconLeft="plus"
                onClick={addUserModal.open}
                small
              >
                {t('team.addUser')}
              </Button>
            )}
          </Flex>
        </PanelHeader>
        <PanelDivider />
        <ContractTeamUsersTable users={sortedUsers} />
      </Panel>
      {addUserModal.isOpen && (
        <ContractTeamUserModal
          close={addUserModal.close}
          companyId={company._id}
        />
      )}
    </>
  );
};
