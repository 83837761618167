import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { ContactToUpgradeButton } from '@deepstream/ui-kit/elements/button/Button';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { useIntercomTrackEvent } from '../../ui/useIntercomTrackEvent';

const Overlay = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  backdrop-filter: blur(8px);
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.space[3]}px;
`;

const Content = styled(Box)`
  border: ${props => props.theme.borders.lightGray2};
  background-color: white;
  max-width: 573px;
  padding: 20px;
  line-height: 24px;
  border-radius: 4px;
`;

export const BlurredPagePlaceholderModal = ({
  isOpen,
  type,
}: {
  isOpen?: boolean;
  type: 'reporting' | 'contracts';
}) => {
  const { t } = useTranslation('reporting');
  const handleUpgradeClick = useIntercomTrackEvent({ eventName: 'reporting:modal:upgrade', showChat: true });

  return isOpen ? (
    <Overlay onClick={stopPropagation}>
      <Content>
        <Text fontSize={5} fontWeight={500} lineHeight="1.4">
          {t(`upgradeSubscription.${type}`)}
        </Text>
        <ContactToUpgradeButton
          mt={3}
          onClick={handleUpgradeClick}
        />
      </Content>
    </Overlay>
  ) : (
    null
  );
};
