import * as Sentry from '@sentry/react';
import * as yup from 'yup';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import i18next from 'i18next';
import { getPrimarySubtag, localeCapitalize } from '@deepstream/utils';
import { Panel, PanelDivider, PanelHeading } from '@deepstream/ui-kit/elements/Panel';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useIntercom } from 'react-use-intercom';
import { ModalForm, ModalFormProps } from '../../ModalForm';
import { PropertyList } from '../../PropertyList';
import { useModalState } from '../../ui/useModalState';
import { EditProperty } from './utils';
import { useCurrentUser, useCurrentUserLocale } from '../../useCurrentUser';
import { useUpdateUserProfile } from './useUserMutation';
import { SelectField } from '../../form/SelectField';
import { useApi, wrap } from '../../api';

type EditLocaleModalProps =
  Omit<ModalFormProps, 'heading' | 'initialValues' | 'validationSchema' | 'onSubmit'> &
  { locale: string | undefined; onSuccess: any };

const EditLocaleModal = ({ locale = 'en-GB', onSuccess, ...props }: EditLocaleModalProps) => {
  const intercom = useIntercom();
  const { t } = useTranslation();
  const api = useApi();
  const fieldName = t('user.preferences.locale');

  const [updateUserProfile] = useUpdateUserProfile({
    fieldName,
    onSuccess,
  });

  const { data: enabledLanguages } = useQuery(
    ['enabledLanguages'],
    wrap(api.getEnabledLanguages),
  );

  return (
    <ModalForm
      heading={t('user.preferences.editLocale')}
      initialValues={{
        locale,
      }}
      validationSchema={yup.object({
        locale: yup.string().required(),
      })}
      onSubmit={async ({ locale }, { setSubmitting }) => {
        try {
          await updateUserProfile({ preferences: { locale } });

          const t = await i18next.changeLanguage(locale);
          document.title = `DeepStream | ${t('documentTitle.defaultSlogan')}`;

          intercom.update({
            customAttributes: { 'DeepStream locale': locale },
          });
        } catch (error) {
          Sentry.captureException(error);
        } finally {
          setSubmitting(false);
        }
      }}
      {...props}
    >
      <SelectField
        required
        name="locale"
        label={fieldName}
        items={
          enabledLanguages
            ? enabledLanguages
                .map((language) => {
                  const subTag = getPrimarySubtag(language.code);
                  // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
                  const displayNameLocale = localeCapitalize(new Intl.DisplayNames([locale], { type: 'language' }).of(subTag), locale);
                  // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
                  const displayName = localeCapitalize(new Intl.DisplayNames([language.code], { type: 'language' }).of(subTag), locale);

                  return {
                    label: locale !== language.code ? `${displayNameLocale} (${displayName})` : displayName,
                    value: language.code,
                  };
                })
                .sort((a, b) => a.label.localeCompare(b.label, locale))
            : []
        }
      />
    </ModalForm>
  );
};

export const LanguageAndRegionPanel = () => {
  const locale = useCurrentUserLocale();
  const user = useCurrentUser();
  const editLocaleModal = useModalState();
  const { t } = useTranslation();
  const languageNames = new Intl.DisplayNames([locale], { type: 'language' });

  return (
    <>
      <Panel>
        <PanelHeading>
          <Icon icon="globe" regular fontSize={4} color="text" mr={2} />
          {t('user.preferences.languageAndRegion')}
        </PanelHeading>
        <PanelDivider />
        <PropertyList
          properties={[{
            name: t('user.preferences.locale'),
            value: user.preferences?.locale,
            Component: ({ value }) => (
              <>
                {/*
                 // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'. */}
                {localeCapitalize(languageNames.of(getPrimarySubtag(value || 'en-GB')), locale)}
              </>
            ),
          }]}
        >
          <EditProperty
            onClick={editLocaleModal.open}
            data-test="edit-locale"
          />
        </PropertyList>
      </Panel>
      <EditLocaleModal
        locale={user.preferences?.locale}
        isOpen={editLocaleModal.isOpen}
        onSuccess={editLocaleModal.close}
        onCancel={editLocaleModal.close}
      />
    </>
  );
};
