import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelHeader } from '@deepstream/ui-kit/elements/Panel';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { callAll } from '@deepstream/utils/callAll';
import { QuestionModal } from './QuestionModal/QuestionModal';
import { QuestionsList } from './QuestionsList/QuestionsList';

import { useModalState } from '../../../ui/useModalState';
import { useCompanyFeatureFlags } from '../../../companyFeatureFlags';
import { UpgradeToUnlockPreQualDialog } from '../UpgradeToUnlockPreQualDialog';
import { PreQualQuestion } from '../../../types';
import { useUserFlags } from '../../../UserFlagsContext';
import { useApi, wrap } from '../../../api';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { QuestionnaireTemplatesList } from './QuestionnaireTemplatesList';
import { useMutation } from '../../../useMutation';
import { useListCategoriesQueryKey } from '../utils';
import { useToaster } from '../../../toast';

const ConfirmQuestionArchiveDialog = ({
  questionId,
  close,
}: {
  questionId: string;
  close: () => void;
}) => {
  const { t } = useTranslation('preQualification');
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const api = useApi();
  const [archiveQuestion] = useMutation(api.archivePreQualQuestion);
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const getListCategoriesQueryKey = useListCategoriesQueryKey();

  const { data: templatesWithQuestion, isSuccess } = useQuery(
    ['questionnaireTemplatesWithQuestion', { questionId, currentCompanyId }],
    wrap(api.getQuestionnaireTemplatesWithQuestion),
  );

  // @ts-expect-error ts(18048) FIXME: 'templatesWithQuestion.length' is possibly 'undefined'.
  const isUsedInTemplates = templatesWithQuestion?.length > 0;

  const onArchive = useCallback(
    () => {
      archiveQuestion(
        { questionId, currentCompanyId },
        {
          onSuccess: callAll(
            () => {
              // Reset all `categories` query to properly update the sidebar count
              const listCategoriesQueryKey = getListCategoriesQueryKey();

              queryClient.invalidateQueries(listCategoriesQueryKey);
              queryClient.invalidateQueries(['preQualQuestionsList']);

              if (isUsedInTemplates) {
                // Invalidate caches for all draft questionnaire templates because they might get updated with the new question.
                queryClient.invalidateQueries(['questionnaireTemplate', { scope: 'draft' }]);
              }
            },
            close,
            () => toaster.success(t('toaster.questionArchived.success')),
          ),
          onError: () => toaster.error(t('toaster.questionArchived.failed')),
        },
      );
    },
    [
      archiveQuestion,
      questionId,
      currentCompanyId,
      close,
      toaster,
      t,
      isUsedInTemplates,
      queryClient,
      getListCategoriesQueryKey,
    ],
  );

  if (!isSuccess) {
    return null;
  }

  const message = isUsedInTemplates
    ? t('questionsPanel.archiveQuestionWithQuestionnaires')
    : t('questionsPanel.archiveQuestionWithoutQuestionnaires');

  return (
    <Dialog
      heading={t('questionsPanel.archiveQuestion')}
      body={
        <Stack gap={3}>
          <MessageBlock variant="info" mt={0}>
            {message}
          </MessageBlock>
          {isUsedInTemplates && (
            <QuestionnaireTemplatesList templates={templatesWithQuestion} />
          )}
        </Stack>
      }
      isOpen
      style={{ content: { width: '500px' } }}
      okButtonText={t('questionsPanel.archiveAction')}
      okButtonVariant="primary"
      onOk={onArchive}
      onCancel={close}
    />
  );
};

export const Questions = ({
  categoryId,
}: {
  categoryId?: string;
}) => {
  const questionModal = useModalState();
  const [selectedQuestion, setSelectedQuestion] = useState<PreQualQuestion | null>(null);
  const [selectedQuestionCategoryId, setSelectedQuestionCategoryId] = useState<string | null>(null);
  const [selectedQuestionId, setSelectedQuestionId] = useState<string | null>(null);
  const { t } = useTranslation('preQualification');
  const companyFeatureFlags = useCompanyFeatureFlags();
  const upgradeDialog = useModalState();
  const archiveDialog = useModalState();
  const { hasManagePreQualPermission } = useUserFlags();

  const openUpdateModal = useCallback((question: PreQualQuestion, categoryId) => {
    setSelectedQuestion(question);
    setSelectedQuestionCategoryId(categoryId);
    questionModal.open();
  }, [questionModal]);

  const onCloseModal = useCallback(() => {
    setSelectedQuestion(null);
    setSelectedQuestionCategoryId(null);
    questionModal.close();
  }, [questionModal]);

  const onQuestionArchive = useCallback(
    async (questionId: string) => {
      setSelectedQuestionId(questionId);
      archiveDialog.open();
    },
    [archiveDialog],
  );

  return (
    <>
      <Panel sx={{ marginBottom: 4 }}>
        <PanelHeader heading={t('questionsPanel.heading')}>
          {!companyFeatureFlags.preQualEnabled ? (
            <Button
              small
              variant="primary"
              iconLeft="lock"
              onClick={upgradeDialog.open}
            >
              {t('questionModal.triggerLabel')}
            </Button>
          ) : hasManagePreQualPermission ? (
            <Button small iconLeft="plus" onClick={questionModal.open}>
              {t('questionModal.triggerLabel')}
            </Button>
          ) : (
            null
          )}
        </PanelHeader>
      </Panel>

      <QuestionsList
        categoryId={categoryId}
        onQuestionUpdate={openUpdateModal}
        onQuestionArchive={onQuestionArchive}
      />

      <UpgradeToUnlockPreQualDialog
        isOpen={upgradeDialog.isOpen}
        onCancel={upgradeDialog.close}
      />

      {questionModal.isOpen && (
        <QuestionModal
          // @ts-expect-error ts(2322) FIXME: Type 'PreQualQuestion | null' is not assignable to type 'PreQualQuestion | undefined'.
          question={selectedQuestion}
          // @ts-expect-error ts(2322) FIXME: Type 'string | null | undefined' is not assignable to type 'string | undefined'.
          categoryId={selectedQuestion ? selectedQuestionCategoryId : categoryId}
          close={onCloseModal}
        />
      )}

      {archiveDialog.isOpen && (
        <ConfirmQuestionArchiveDialog
          // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
          questionId={selectedQuestionId}
          close={archiveDialog.close}
        />
      )}
    </>
  );
};
