import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';
import { useRouter } from '@tanstack/react-router';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { ModalForm } from '../../ModalForm';
import { useSupplierListData } from './SupplierListProvider';
import { useUpdateSupplierList } from './useUpdateSupplierList';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { TextField } from '../../form/TextField';

const Schema = z.object({
  description: z.string().optional(),
});

const validationSchema = toFormikValidationSchema(Schema);

export const EditListDescriptionModal = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const supplierList = useSupplierListData();
  const [updateSupplierList] = useUpdateSupplierList();
  const router = useRouter();

  return (
    <ModalForm
      heading={t('supplierLists.editListDescriptionModal.heading')}
      isOpen={isOpen}
      onCancel={close}
      onSubmit={async ({ description }) => {
        await updateSupplierList({
          listId: supplierList._id,
          companyId: currentCompanyId,
          description: description.trim(),
        }, {
          onSettled: () => router.invalidate(),
        });

        close();
      }}
      initialValues={{
        description: supplierList.description,
      }}
      validationSchema={validationSchema}
      style={{ content: { width: '450px' } }}
      submitLabel={t('general.saveChanges')}
    >
      <TextField
        name="description"
        label={t('supplierLists.editListDescriptionModal.descriptionOptional')}
        helperText={(
          <IconText
            icon="info-circle"
            isIconRegular
            text={t('supplierLists.configFlow.descriptionInfo')}
            alignItems="start"
            iconSx={{ position: 'relative', top: '3px' }}
          />
        )}
        isMultiLine
      />
    </ModalForm>
  );
};
