import { Flex } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { TabButtonProps, TabButton } from '@deepstream/ui-kit/elements/button/TabButton';

export const buttonTabStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const AddPageButton = ({ children, ...props }: TabButtonProps) => {
  const { t } = useTranslation();

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      height="37px"
    >
      <TabButton iconLeft="plus" height="100%" py={0} px={3} {...props}>
        {t('request.pages.addPage', { count: 1 })}
      </TabButton>
    </Flex>
  );
};
