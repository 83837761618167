export const REQUESTS_TAB_PARAM = 'tab';

export const RequestsTab = {
  received: 'received',
  sent: 'sent',
  public: 'public',
  drafts: 'drafts',
  templates: 'templates',
};

export const supplierTabs = [
  RequestsTab.received,
  RequestsTab.sent,
  RequestsTab.public,
  RequestsTab.drafts,
  RequestsTab.templates,
];
export const buyerOrBothTabs = [
  RequestsTab.sent,
  RequestsTab.received,
  RequestsTab.public,
  RequestsTab.drafts,
  RequestsTab.templates,
];

export const SentRequestsQueryTags = {
  live: 'sentRequests',
  draft: 'draftRequests',
};
