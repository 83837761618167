import { useState, useCallback, useMemo } from 'react';
import * as React from 'react';
import { noop, map, filter, includes, isEmpty } from 'lodash';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useQuery } from 'react-query';
import { Flex, Box, Text } from 'rebass/styled-components';
import { useDebounce } from 'use-debounce';
import { getRegionName } from '@deepstream/common';
import { Icon, BorderedIcon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { mergeRefs, usePopover } from '@deepstream/ui-kit/elements/popup/usePopover';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useOnClickOutsidePopper } from '@deepstream/ui-kit/hooks/useOnClickOutsidePopper';
import { Avatar } from '../../Avatar';
import { CompanyLogo } from '../../CompanyLogo';
import { useApi, wrap } from '../../api';
import { TextFieldBase } from '../../form/TextField';
import { useCurrentUserLocale } from '../../useCurrentUser';

enum SearchType {
  COMPANY = 'company',
  EMAIL = 'email',
}

type CompanyResult = {
  _id: string;
  name: string;
  address: {
    country?: string;
  };
  [key: string]: any;
};

type UserResult = {
  _id: string;
  name: string;
  companyName: string;
  companyId: string;
  receiveRFQ?: boolean;
  [key: string]: any;
};

const isEmail = (value: string) => !!value && yup.string().email().isValidSync(value);

const ResultsEntry = ({
  onClick = noop,
  children,
  isAlreadySelected,
  disabled,
  disablePointerEvents,
  disabledResultTooltip,
}: {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children: React.ReactNode;
  isAlreadySelected?: boolean;
  disabled?: boolean;
  disablePointerEvents?: boolean;
  disabledResultTooltip?: string;
}) => {
  return (
    <Tooltip content={disabled && disabledResultTooltip ? disabledResultTooltip : null}>
      <Flex
        role="button"
        as="li"
        alignItems="center"
        py={2}
        px={3}
        sx={{
          pointerEvents: (disablePointerEvents || isAlreadySelected) ? 'none' : 'auto',
          cursor: disabled ? 'default' : 'pointer',
          borderBottom: 'lightGray2',
          lineHeight: '18px',
          backgroundColor: disabled || isAlreadySelected ? 'lightGray3' : 'transparent',
          height: 52,
          color: disabled ? 'subtext' : 'text',
          ':hover': { backgroundColor: 'blueLight' },
          ':last-of-type': { borderBottom: 0 },
        }}
        onClick={!disabled ? onClick : noop}
      >
        {children}
        {isAlreadySelected && (
          <Icon
            fontSize={0}
            icon="check"
            sx={{ marginLeft: 'auto', color: 'gray' }}
          />
        )}
      </Flex>
    </Tooltip>
  );
};

const ResultsItems = ({
  items,
  onItemClick,
  searchType,
  disabledResultTooltip,
}: {
  items: (CompanyResult & {
    disabled?: boolean;
    isAlreadySelected?: boolean;
  })[] | (UserResult & {
    disabled?: boolean;
    isAlreadySelected?: boolean;
  })[];
  onItemClick: (e: { type: 'company', value: CompanyResult }
    | { type: 'user', value: UserResult }
  ) => void;
  searchType: SearchType;
  disabledResultTooltip?: string;
}) => {
  const locale = useCurrentUserLocale();

  return (
    <>
      {searchType === SearchType.COMPANY
        ? (items as CompanyResult[]).map((company) => (
          <ResultsEntry
            key={company._id}
            isAlreadySelected={company.isAlreadySelected}
            disabled={company.disabled}
            onClick={() => onItemClick({ type: 'company', value: company })}
            disabledResultTooltip={disabledResultTooltip}
          >
            <Box sx={{ flex: '0 0 auto' }}>
              <CompanyLogo companyId={company._id} />
            </Box>
            <Box ml={2}>
              <Truncate>
                {company.name}
              </Truncate>
              {company.address?.country && (
                <Text fontSize={1} color="gray">
                  {getRegionName(company.address.country, locale)}
                </Text>
              )}
            </Box>
          </ResultsEntry>
        ))
        : (items as UserResult[]).map((user) => (
          <ResultsEntry
            key={user._id}
            isAlreadySelected={user?.isAlreadySelected}
            onClick={() => onItemClick({ type: 'user', value: user })}
          >
            <Avatar sx={{ flex: '0 0 auto' }} userId={user._id} />
            <Box ml={2}>
              <Truncate>
                {user.name || user.email}
              </Truncate>
              <Truncate fontSize={1} color="gray">
                {user?.companyName}
              </Truncate>
            </Box>
          </ResultsEntry>
        ))
      }
    </>
  );
};

export const SupplierSearchBox = React.forwardRef(({
  companyIdsAlreadySelected,
  companyIdsToExclude,
  companyIdsDisabled = [],
  placeholder,
  canSearchByEmail,
  canSendInvites,
  requiredRole,
  selectedUserIdsByCompanyId = {},
  disabledResultTooltip,
  onResultClick,
  onInviteClick,
}: {
  companyIdsToExclude: string[];
  companyIdsAlreadySelected: string[];
  companyIdsDisabled?: string[];
  placeholder: string;
  canSearchByEmail?: boolean;
  canSendInvites?: boolean;
  requiredRole?: string;
  selectedUserIdsByCompanyId?: Record<string, string[]>;
  disabledResultTooltip?: string;
  onResultClick: (e: { type: 'company', value: CompanyResult }
    | { type: 'user', value: UserResult }
  ) => void;
  onInviteClick?: () => void;
}, ref) => {
  const { t } = useTranslation(['request', 'general']);
  const [searchQuery, setSearchQuery] = useState({ text: '' });
  const api = useApi();
  const [debouncedQuery] = useDebounce(searchQuery, 500);
  const popperRef = React.useRef<HTMLElement | null>(null);
  const {
    isOpen,
    open,
    close,
    styles,
    attributes,
    referenceElement,
    setReferenceElement,
    setPopperElement,
  } = usePopover({
    placement: 'bottom',
    sameWidth: true,
  });

  // @ts-expect-error ts(2345) FIXME: Argument of type 'HTMLElement | undefined' is not assignable to parameter of type 'HTMLElement'.
  useOnClickOutsidePopper(popperRef, referenceElement, close);

  const searchType = canSearchByEmail && isEmail(debouncedQuery.text) ? SearchType.EMAIL : SearchType.COMPANY;

  const companySearchQuery = useQuery<{ companies: CompanyResult[]; [k: string]: any }>(
    ['searchCompany', debouncedQuery],
    wrap(api.searchCompanies),
    {
      enabled: !!debouncedQuery.text && searchType === SearchType.COMPANY,
      onSuccess: open,
    },
  );

  const userSearchQuery = useQuery<UserResult[]>(
    ['searchUser', debouncedQuery.text],
    wrap(api.searchUserByEmail),
    {
      enabled: !!debouncedQuery.text && searchType === SearchType.EMAIL,
      onSuccess: open,
    },
  );

  const handleOnChange = useCallback((event) => {
    const text = event.target.value;

    if (text === '') close();

    setSearchQuery((prev) => ({ ...prev, text }));
  }, [close]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Escape') {
      setSearchQuery({ text: '' });
      close();
    }
  }, [close]);

  const items = useMemo(() => {
    if (searchType === SearchType.COMPANY) {
      // Do not show excluded companies
      const companies = filter(
        companySearchQuery?.data?.companies,
        company => !includes(companyIdsToExclude, company._id),
      );

      return map(companies, (company) => ({
        ...company,
        isAlreadySelected: includes(companyIdsAlreadySelected, company._id),
        disabled: includes(companyIdsDisabled, company._id),
      }));
    }

    if (searchType === SearchType.EMAIL) {
      // Do not show users from an excluded company (but show the other entries if a user is part of multiple companies)
      const filteredUsers = filter(
        userSearchQuery?.data,
        (userResult) => (
          !includes(companyIdsToExclude, userResult.companyId) && requiredRole && userResult[requiredRole]
        ),
      ) as UserResult[];

      return map(filteredUsers, (user) => {
        if (includes(selectedUserIdsByCompanyId?.[user.companyId], user._id)) {
          return { ...user, isAlreadySelected: true };
        }

        return user;
      });
    }
  }, [
    userSearchQuery,
    companySearchQuery,
    searchType,
    companyIdsAlreadySelected,
    companyIdsToExclude,
    selectedUserIdsByCompanyId,
    requiredRole,
    companyIdsDisabled,
  ]);

  const isLoading = userSearchQuery.isLoading || companySearchQuery.isLoading;
  const showInviteRow = canSendInvites && (
    searchType === SearchType.COMPANY || (isEmpty(items) && searchType === SearchType.EMAIL)
  );

  return (
    <>
      <TextFieldBase
        // @ts-ignore ts(2322) FIXME: Type 'Dispatch<SetStateAction<HTMLElement | undefined>>' is not assignable to type 'LegacyRef<HTMLInputElement> | undefined'.
        ref={ref ? mergeRefs([setReferenceElement, ref]) : setReferenceElement}
        placeholder={placeholder}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        value={searchQuery.text}
        suffix={(
          debouncedQuery.text && isLoading
            ? <Icon icon="spinner" spin />
            : <Icon icon="search" />
        )}
        autoComplete="off"
      />

      {isOpen && createPortal(
        <Box
          style={styles.popper}
          sx={{
            zIndex: 99,
            backgroundColor: 'background',
            boxShadow: 'small',
          }}
          ref={mergeRefs([setPopperElement, popperRef])}
          {...attributes.popper}
        >
          <Box
            as="ul"
            sx={{
              listStyle: 'none',
              margin: 0,
              padding: 0,
              maxHeight: 350,
              overflow: 'auto',
              borderBottom: 'lightGray2',
            }}
          >
            {isLoading ? (
              <ResultsEntry disablePointerEvents>
                <Text>{t('loading', { ns: 'general' })}</Text>
              </ResultsEntry>
            ) : (!items || items.length === 0) ? (
              <ResultsEntry disablePointerEvents>
                <Text>{t('noResults', { ns: 'general' })}</Text>
              </ResultsEntry>
            ) : (
              <ResultsItems
                items={items}
                searchType={searchType}
                disabledResultTooltip={disabledResultTooltip}
                onItemClick={(item) => {
                  onResultClick(item);

                  setSearchQuery((prev) => ({ ...prev, text: '' }));
                  close();
                }}
              />
            )}
          </Box>

          {showInviteRow && (
            <Stack
              gap={2}
              pt={3}
            >
              <Text
                color="subtextLight"
                fontSize={1}
                px={2}
                letterSpacing="1px"
                style={{ textTransform: 'uppercase' }}
              >
                {searchType === SearchType.COMPANY
                  ? t('suppliers.addSearchPanel.inviteCompany')
                  : t('suppliers.addSearchPanel.inviteUser')
                }
              </Text>
              <Flex
                // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
                onClick={() => onInviteClick()}
                alignItems="center"
                px={3}
                py={2}
                sx={{
                  cursor: 'pointer',
                  lineHeight: '18px',
                  ':hover': { backgroundColor: 'blueLight' },
                }}
              >
                <BorderedIcon icon="plus" />
                <Text ml={2}>{debouncedQuery.text}</Text>
              </Flex>
            </Stack>
          )}
        </Box>,
        document.body,
      )}
    </>
  );
});
