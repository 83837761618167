import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { QuestionnaireTemplateOverview } from '@deepstream/common/preQual';
import { withProps } from '@deepstream/ui-utils/withProps';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { BasicTableStyles } from '../../../TableStyles';
import { Table } from '../../../Table';
import { DateFieldValue, FieldsCell, MIN_CELL_HEIGHT } from '../../../FieldsCell';
import { QuestionnaireTemplateStatusIcon } from './QuestionnaireTemplateStatus';
import { RenewalFrequency } from '../RenewalFrequency';
import { usePreQualNavigation } from '../../../AppRouting';
import { useUserFlags } from '../../../UserFlagsContext';
import { ExpiryOffset } from '../ExpiryOffset';
import { useCompanyFeatureFlags } from '../../../companyFeatureFlags';
import { useDraftQuestionnaireTemplateNavigation, useActiveQuestionnaireTemplateNavigation } from '../../../appNavigation';

type ActionButtonsProps = CellProps<QuestionnaireTemplateOverview> & {
  onExpandedStateChange?: (isExpanded: boolean) => void;
  isArchive: boolean;
  openArchiveModal?: (templateId: string) => void;
  openRestoreModal?: (templateId: string) => void;
};

const ActionButtons = ({
  row,
  onExpandedStateChange,
  isArchive,
  openArchiveModal,
  openRestoreModal,
}: ActionButtonsProps) => {
  const { t } = useTranslation('preQualification');
  const draftTemplateNavigation = useDraftQuestionnaireTemplateNavigation();
  const { navigateToSendQuestionnaire } = usePreQualNavigation();
  const { hasManagePreQualPermission } = useUserFlags();
  const companyFeatureFlags = useCompanyFeatureFlags();

  const template = row.original;

  return hasManagePreQualPermission ? (
    <DropdownMenu
      small
      variant="primary-outline"
      iconLeft="ellipsis-h"
      menuStyle={{ width: '200px' }}
      menuZIndex={10}
      onExpandedStateChange={onExpandedStateChange}
      onClick={stopPropagation}
      stopClickEvents
    >
      {isArchive ? (
        <DropdownMenuItem
          icon="undo"
          // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
          onSelect={() => openRestoreModal(template._id)}
          disabled={!companyFeatureFlags.preQualEnabled}
        >
          {t('archive.restoreQuestionnaire')}
        </DropdownMenuItem>
      ) : (
        <>
          <DropdownMenuItem
            icon="paper-plane-top"
            onSelect={() => navigateToSendQuestionnaire({ templateIds: [template._id] })}
          >
            {t('questionnaireTemplate.sendQuestionnaire')}
          </DropdownMenuItem>
          <DropdownMenuItem
            icon="pencil"
            onSelect={() => {
              draftTemplateNavigation.navigateToQuestions(template._id);
            }}
          >
            {t('questionnaireTemplate.reviseQuestionnaire')}
          </DropdownMenuItem>
          <DropdownMenuItem
            icon="archive"
            // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
            onSelect={() => openArchiveModal(template._id)}
          >
            {t('archive.archiveQuestionnaire')}
          </DropdownMenuItem>
        </>
      )}
    </DropdownMenu>
  ) : (
    null
  );
};

export const LiveQuestionnaireTemplatesTable = ({
  isArchive = false,
  openArchiveModal,
  openRestoreModal,
}: {
  isArchive?: boolean;
  openArchiveModal?: (templateId: string) => void;
  openRestoreModal?: (templateId: string) => void;
}) => {
  const { t } = useTranslation('preQualification');
  const activeTemplateNavigation = useActiveQuestionnaireTemplateNavigation();

  const onRowClick = useCallback((row) => {
    const id = row._id;

    activeTemplateNavigation.navigateToSuppliers(id);
  }, [activeTemplateNavigation]);

  const columns = useMemo(
    () => [{ id: 'custom' }],
    [],
  );

  const CustomRowCells = useCallback(
    ({ row }) => {
      const isFirstVersion = row.original.version === 1;

      const fields = [
        {
          label: t('supplier_other'),
          accessor: (row: QuestionnaireTemplateOverview) => row.supplierIds.length,
        },
        {
          label: t('numberOfQuestions'),
          accessor: 'numQuestions',
        },
        {
          label: t('questionnaireRenewal.renewal'),
          accessor: 'renewalConfig',
          FieldValueComponent: withProps(RenewalFrequency, { onlyFrequency: true }),
        },
        {
          label: t('questionnaireRenewal.expiry'),
          accessor: 'expiryConfig',
          FieldValueComponent: withProps(ExpiryOffset, { onlyOffset: true }),
        },
        {
          label: t('version'),
          accessor: 'version',
        },
        isFirstVersion ? {
          label: t('createdOn'),
          accessor: 'meta.createdAt',
          FieldValueComponent: DateFieldValue,
        } : {
          label: t('lastUpdated'),
          accessor: 'meta.lastPublishedAt',
          FieldValueComponent: DateFieldValue,
        },
      ];

      const Comp = withProps(
        FieldsCell,
        {
          fields,
          row,
          titleAccessor: 'name',
          RowIconComponent: QuestionnaireTemplateStatusIcon,
          actionColumnWidth: 100,
          ActionsComponent: withProps(ActionButtons, { isArchive, openArchiveModal, openRestoreModal }),
        },
      );

      return <Comp />;
    },
    [isArchive, openArchiveModal, openRestoreModal, t],
  );

  return (
    <BasicTableStyles hoverBackgroundColor="lightGray6">
      <Table
        columns={columns}
        isPaginated
        pageSizes={[5, 10, 20, 50, 100]}
        hideHeader
        CustomRowCells={CustomRowCells}
        hasStaticHeight={false}
        enforcePagination
        minCellHeight={MIN_CELL_HEIGHT}
        smallPageControls
        onRowClick={onRowClick}
      />
    </BasicTableStyles>
  );
};
