import {
  canIncludeFieldInFormula,
  ExchangeRateDocument,
  getExchangeFieldValue,
  isDefinitionField,
  isFormulaField,
  isReplyField,
} from '@deepstream/common/rfq-utils';
import { evaluate, formulaParser } from '@deepstream/formula';
import { createCurrencyConverter } from '@deepstream/common';
import { get, mapValues, pickBy } from 'lodash';
import { LineItemsExchangeSnapshot } from '../../types';

const getFormulaContext = (
  exchange: LineItemsExchangeSnapshot,
  exchangeRates: ExchangeRateDocument | undefined,
  valuesMap: { [fieldId: string]: any },
) => {
  const targetCurrency = exchange.currency;
  const convertCurrency = createCurrencyConverter(targetCurrency, exchangeRates);

  return mapValues(
    pickBy(exchange.def.fields, canIncludeFieldInFormula),
    (field, fieldId) => {
      const fieldValue = isDefinitionField(field)
        ? get(exchange.def, field.source.key)
        : isReplyField(field)
          ? valuesMap[fieldId] as any[]
          : isFormulaField(field)
            ? field.source.formula
            : null;

      const fieldRole = fieldId?.split(':')[1];
      const fieldNeedsConversion = field.type === 'price' && fieldRole === 'evaluator';
      if (fieldNeedsConversion) {
        const converted = convertCurrency({ value: fieldValue, currencyCode: getExchangeFieldValue(exchange, 'evaluatorFieldCurrency') });
        return converted.value;
      }
      return fieldValue;
    },
  );
};

export const computeFormulaValue = ({
  exchange,
  formula,
  valuesMap,
  exchangeRates,
}: {
  exchange: LineItemsExchangeSnapshot;
  formula: string;
  valuesMap: { [fieldId: string]: any };
  exchangeRates: ExchangeRateDocument | undefined;
}) => {
  const formulaContext = getFormulaContext(exchange, exchangeRates, valuesMap);

  try {
    const { expression } = formulaParser.parse(formula);
    return evaluate(expression, formulaContext);
  } catch (error) {
    return null;
  }
};

export const computeFormula = ({
  exchange,
  fieldId,
  valuesMap,
  exchangeRates,
}: {
  exchange: LineItemsExchangeSnapshot;
  fieldId: string;
  valuesMap: { [fieldId: string]: any };
  exchangeRates: ExchangeRateDocument | undefined;
}) => {
  const fieldSource = exchange.def.fields[fieldId]?.source;

  if (fieldSource.type !== 'formula') {
    return null;
  }

  return computeFormulaValue({
    exchange,
    formula: fieldSource.formula,
    valuesMap,
    exchangeRates,
  });
};
