import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { QuestionnaireTemplateOverview } from '@deepstream/common/preQual';
import { Bold } from '../../../Bold';

export const QuestionnaireTemplatesList = ({ templates }: { templates: QuestionnaireTemplateOverview[] }) => {
  const { t } = useTranslation('preQualification');

  return (
    <Stack gap={2}>
      <Bold>{t('questionnaire', { count: 2 })}</Bold>
      <Stack as="ul" gap={1} pl="20px">
        {templates.map(template => (
          <Text key={template._id} as="li">
            {template.name || t('untitledTemplate')}
          </Text>
        ))}
      </Stack>
    </Stack>
  );
};
