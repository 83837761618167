import { isEmpty, castArray } from 'lodash';
import { useMemo } from 'react';
import * as React from 'react';
import { Box } from 'rebass/styled-components';
import {
  QuestionElementType,
  QuestionElement,
  AddressQuestionElement,
} from '@deepstream/common/legacy-pre-q-utils';
import { getRegionName } from '@deepstream/common';
import { useTranslation } from 'react-i18next';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Row } from '../ui/ProfileLayout';
import { ElementProps, Modify, ContainerLabel, LockedAnswer, NoAnswer } from './common';
import { Element } from './Element'; // eslint-disable-line import/no-cycle
import { useCurrentUserLocale } from '../useCurrentUser';

const required = {
  validation: {
    required: true,
  },
};

export const AddressElement: React.FC<Modify<ElementProps, { element: AddressQuestionElement }>> = ({
  name,
  element,
  answer,
  hideLabel,
  error,
  isReadOnly,
  isLocked,
}) => {
  const { t } = useTranslation('companyProfile');
  const locale = useCurrentUserLocale();
  const rows = useMemo(
    () => [
      { type: QuestionElementType.TEXTBOX, key: 'lineOne', label: t('element.address.lineOne'), ...required },
      { type: QuestionElementType.TEXTBOX, key: 'lineTwo', label: t('element.address.lineTwo') },
      [
        { type: QuestionElementType.TEXTBOX, key: 'city', label: t('element.address.city'), ...required },
        { type: QuestionElementType.TEXTBOX, key: 'state', label: t('element.address.state') },
        { type: QuestionElementType.TEXTBOX, key: 'postcode', label: t('element.address.postcode'), ...required },
      ],
      { type: QuestionElementType.DROPDOWN, subtype: 'country', key: 'country', label: t('element.address.country'), ...required },
    ].map(castArray) as QuestionElement[][],
    [t],
  );

  return (
    <ContainerLabel label={element.label || (element.labelId ? t(`element.label.${element.labelId}`) : '')} hideLabel={hideLabel}>
      {isReadOnly ? (
        isEmpty(answer) ? (
          <NoAnswer />
        ) : (
          isLocked ? (
            <LockedAnswer />
          ) : (
            <>
              <Box>{answer?.lineOne}</Box>
              <Box>{answer?.lineTwo}</Box>
              <Box>{answer?.city}, {answer?.state} {answer?.postcode}</Box>
              <Box>
                {getRegionName(answer?.country, locale)}
              </Box>
            </>
          )
        )
      ) : (
        <Stack gap={2}>
          {rows.map((elements, index) => (
            <Row key={index} justifyContent="space-between">
              {elements.map((element, index) => (
                <Box key={element.key} width={1 / elements.length} pr={index < elements.length - 1 ? 3 : 0}>
                  <Element
                    name={`${name}.${element.key}`}
                    element={element}
                    answer={answer?.[element.key]}
                    error={error?.[element.key]}
                  />
                </Box>
              ))}
            </Row>
          ))}
          {error}
        </Stack>
      )}
    </ContainerLabel>
  );
};
