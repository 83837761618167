import { Box } from 'rebass/styled-components';
import { RfxAuctionLineItemsSection } from '@deepstream/common/rfq-utils';
import * as rfx from '../../../rfx';
import { CurrencyAmount } from '../../../ui/Currency';
import { AuctionLineItemTotal } from '../../../draft/cell';

export const AuctionLineItemPriceCell = ({ row, cell: { value: previousPrice } }) => {
  const section = rfx.useSection<RfxAuctionLineItemsSection>();
  const { auctionRules } = section;
  const exchangeDef = rfx.useAuctionLineItemExchangeDef(row.original.def);

  return (
    <Box textAlign="right">
      <CurrencyAmount showCode value={previousPrice} decimalPlaces={auctionRules?.decimalPlaces} />
      <Box mt={1}>
        <AuctionLineItemTotal
          showCode
          price={previousPrice}
          // @ts-expect-error ts(2322) FIXME: Type 'number | null' is not assignable to type 'number | undefined'.
          quantity={exchangeDef.quantity}
          textAlign="right"
        />
      </Box>
    </Box>
  );
};
