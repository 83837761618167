import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import { isEmpty, size } from 'lodash';
import { useQuery } from 'react-query';
import { ContractTemplate } from '@deepstream/common/contract/contractTemplate';
import { Illustration } from '@deepstream/ui-kit';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { FlexPanelHeader, Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { MIN_CELL_HEIGHT } from '../../FieldsCell';
import { ErrorMessage } from '../../ui/ErrorMessage';
import { useApi, wrap } from '../../api';
import { useCurrentUser } from '../../useCurrentUser';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { ContractTemplatesTable } from './ContractTemplatesTable';
import { useLocalStorageState } from '../../useLocalStorageState';
import { Loading } from '../../ui/Loading';
import { useModalState } from '../../ui/useModalState';
import { NewBlankContractTemplateModal } from './NewBlankContractTemplateModal';
import { DeleteContractTemplateDialog } from './DeleteContractTemplateDialog';
import { useCompanyFeatureFlags } from '../../companyFeatureFlags';
import { useUserFlags } from '../../UserFlagsContext';
import { UpgradeToUnlockContractsDialog } from './UpgradeToUnlockContractsDialog';

export const ContractTemplates = () => {
  const { t } = useTranslation(['translation', 'contracts']);
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();
  const api = useApi();
  const newBlankTemplateModal = useModalState();
  const deleteTemplateModal = useModalState();
  const upgradeDialog = useModalState();
  const [selectedTemplate, setSelectedTemplate] = useState<ContractTemplate | null>(null);
  const companyFeatureFlags = useCompanyFeatureFlags();
  const { belongsToPayingCompany } = useUserFlags();

  const { data: templates, status } = useQuery(
    ['contractTemplates', { currentCompanyId }],
    wrap(api.getContractTemplates),
  );

  const openDeleteModal = useCallback(
    (template) => {
      setSelectedTemplate(template);
      deleteTemplateModal.open();
    },
    [setSelectedTemplate, deleteTemplateModal],
  );

  const [pageSize] = useLocalStorageState<number>({
    key: `${currentCompanyId}.${user._id}.contractTemplates.pageSize`,
    defaultValue: 10,
  });

  return (
    <>
      <Panel>
        <FlexPanelHeader
          heading={templates ? (
            <Text fontSize={2}>
              {t('requests.templateCount', { count: size(templates) })}
            </Text>
          ) : null}
        >
          {companyFeatureFlags.contractManagementEnabled ? (
            <DropdownMenu
              rightAligned
              disabled={!templates}
              small
              variant="primary"
              buttonText={t('template.newTemplate')}
              iconLeft="plus"
              iconRight="caret-down"
              menuZIndex={10}
            >
              <DropdownMenuItem icon="file-o" onSelect={newBlankTemplateModal.open}>
                {t('requests.blankTemplate')}
              </DropdownMenuItem>
            </DropdownMenu>
          ) : (
            <Button
              small
              variant="primary"
              iconLeft="lock"
              onClick={upgradeDialog.open}
            >
              {t('template.newTemplate')}
            </Button>
          )}
        </FlexPanelHeader>
        <PanelDivider />
        {status === 'success' && !isEmpty(templates) ? (
          <ContractTemplatesTable
            templates={templates}
            openDeleteModal={openDeleteModal}
          />
        ) : (
          <Flex
            height={MIN_CELL_HEIGHT * (status === 'loading' ? pageSize : 5)}
            flexDirection="column"
            justifyContent="center"
          >
            <PanelPadding>
              {!belongsToPayingCompany ? (
                <Illustration
                  variant="locked"
                  label={t('requests.onlyPayingCompaniesCanUseTemplates')}
                />
              ) : status === 'loading' ? (
                <Loading fontSize={4} fontWeight={400} />
              ) : status === 'error' ? (
                <ErrorMessage fontSize={3} error={t('errors.getTemplates', { ns: 'contracts' })} />
              ) : (
                <Illustration
                  variant="empty-state"
                  label={t('requests.companyHasNotCreatedTemplates')}
                />
              )}
            </PanelPadding>
          </Flex>
        )}
      </Panel>
      <UpgradeToUnlockContractsDialog
        isOpen={upgradeDialog.isOpen}
        onCancel={upgradeDialog.close}
        unlockTemplates
      />
      {newBlankTemplateModal.isOpen && (
        <NewBlankContractTemplateModal
          isOpen={newBlankTemplateModal.isOpen}
          close={newBlankTemplateModal.close}
        />
      )}
      {selectedTemplate && (
        <DeleteContractTemplateDialog
          {...deleteTemplateModal}
          templateId={selectedTemplate._id}
          templateName={selectedTemplate.meta.name}
          onSuccess={deleteTemplateModal.close}
          onError={deleteTemplateModal.close}
        />
      )}
    </>
  );
};
