import { isString } from 'lodash';
import { StateValue } from 'xstate';

/**
 * Returns the top-level states from an xstate StateValue.
 */
export const getTopLevelStates = (stateValue: StateValue): string[] => {
  return isString(stateValue)
    ? [stateValue]
    : Object.keys(stateValue);
};
