import * as React from 'react';
import { CellProps } from 'react-table';
import { ContractStatus, ExtendedSentContractOverview } from '@deepstream/common/contract';
import { isCompanySuperUser } from '@deepstream/common/user-utils';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { DeleteMenuItem, DropdownMenu } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useCurrentUser } from '../../useCurrentUser';

export type SentContractActionButtonsProps = CellProps<ExtendedSentContractOverview> & {
  openDeleteModal: (contract: ExtendedSentContractOverview) => void;
  onExpandedStateChange?: (isExpanded: boolean) => void;
};

export const SentContractActionButtons: React.FC<SentContractActionButtonsProps> = ({
  row,
  openDeleteModal,
  onExpandedStateChange,
}) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const isSuperUser = isCompanySuperUser(currentUser, currentCompanyId);
  const contract = row.original;
  const isDraft = contract.status === ContractStatus.DRAFT;
  const showDelete = (isSuperUser || contract.isOwner) && isDraft;

  return showDelete ? (
    <DropdownMenu
      small
      variant="primary-outline"
      iconLeft="ellipsis-h"
      menuStyle={{ width: '177px' }}
      onExpandedStateChange={onExpandedStateChange}
      menuZIndex={10}
      onClick={stopPropagation}
    >
      <DeleteMenuItem onSelect={() => openDeleteModal(contract)} />
    </DropdownMenu>
  ) : null;
};
