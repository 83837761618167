import { Trans, useTranslation } from 'react-i18next';
import { difference, first, intersection, isEmpty, isEqual, isFinite, isNil, mapValues, omitBy, partition, pick, uniq } from 'lodash';
import { useCallback, useState } from 'react';
import { Text } from 'rebass/styled-components';
import { DsError } from '@deepstream/errors';
import { BidIntentionStatus, BidStatus, ExchangeType, Live, TotalSavingsCalculationMethod, getBidIntentionStatus, isStandaloneAuctionLineItemExchangeDef, AwardScenario, AwardDecision } from '@deepstream/common/rfq-utils';
import { spendAndSavingsDisabledState } from '@deepstream/common/rfq-utils/spendAndSavings';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useLiveRfqStructure, useRfqCostAndSavingsData, useRfqId } from '../../../useRfq';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import * as rfx from '../../../rfx';
import { LoadingPanel } from '../../../ui/Loading';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import { HiddenRequestPagePlaceholder } from '../../../HiddenRequestPagePlaceholder';
import { stepComponentByType } from './steps';
import { AwardFlowData, AwardFlowStepType, AwardFlowStep, MessageMethod } from './types';
import { getEmptyMessageConfig, getExchangeIdsAwardedToMultipleSuppliers } from './utils';
import { ExchangeDefFieldValueProvider } from '../../../ExchangeDefFieldValueContext';
import { RfxStructure } from '../../../types';
import { LeaveFlowModal } from '../../../ui/MultiStepFlow/LeaveFlowModal';
import { OpenRequestInNewTabLink } from './OpenRequestInNewTabLink';
import { Direction } from '../../../ui/MultiStepFlow/types';

const staticStartSteps: AwardFlowStep[] = [
  { type: AwardFlowStepType.GUIDELINES },
  { type: AwardFlowStepType.CHOOSE_AWARD_SCENARIO },
];

const staticFinalSteps: AwardFlowStep[] = [
  { type: AwardFlowStepType.REVIEW_AND_SUBMIT },
  { type: AwardFlowStepType.SUBMITTED_SUCCESSFULLY },
];

const getAwardDecisionSteps = (data: AwardFlowData): AwardFlowStep[] => {
  switch (data.awardScenario) {
    case AwardScenario.REQUEST_LEVEL_AWARD:
      return [
        { type: AwardFlowStepType.CHOOSE_REQUEST_LEVEL_AWARD_SUPPLIER },
      ];
    case AwardScenario.LINE_LEVEL_AWARD:
      return [
        { type: AwardFlowStepType.CHOOSE_LINE_LEVEL_AWARD_SUPPLIERS },
        ...getExchangeIdsAwardedToMultipleSuppliers(data)
          .map(exchangeId => ({
            type: AwardFlowStepType.SPLIT_LINE_AWARD,
            exchangeId,
          })),
      ] as AwardFlowStep[];
    case AwardScenario.LOT_LEVEL_AWARD:
      return [
        { type: AwardFlowStepType.CHOOSE_LOT_LEVEL_AWARD_SUPPLIERS },
      ];
    case AwardScenario.NO_AWARD:
    default:
      return [];
  }
};

const getSpendAndSavingsSteps = (data: AwardFlowData, structure: RfxStructure<Live>): AwardFlowStep[] => {
  if (data.awardScenario === AwardScenario.NO_AWARD) {
    return [];
  }

  const steps: AwardFlowStep[] = [];

  // The spend and savings status step only gets added when spend and savings
  // are not already enabled.
  if (!structure.spendAndSavings?.enabled) {
    steps.push({ type: AwardFlowStepType.SPEND_AND_SAVINGS_STATUS });
  }

  // `data.spendAndSavings.enabled` is true either when spend and savings have
  // already been enabled when starting the award flow or if the user has
  // enabled them in the spend and savings status step.
  if (data.spendAndSavings.enabled) {
    if (structure.spendAndSavings?.hasBudgetedTotalValue && isFinite(structure.spendAndSavings?.budgetedTotalValue)) {
      steps.push({ type: AwardFlowStepType.CONFIRM_PREVIOUS_BUDGET });
    } else {
      steps.push({ type: AwardFlowStepType.CHOOSE_PROVIDE_BUDGET });
    }
    if (data.editBudget) {
      steps.push({ type: AwardFlowStepType.EDIT_BUDGET });
    }

    if (data.hasLineItemsWithTotalCost) {
      steps.push({ type: AwardFlowStepType.CONFIRM_TOTAL_VALUE });
    } else {
      steps.push({ type: AwardFlowStepType.NO_CALCULATED_TOTAL_VALUE_REASONS });
    }

    if (data.spendAndSavings.isCalculatedTotalValueAccurate === false || !data.hasLineItemsWithTotalCost) {
      steps.push(
        { type: AwardFlowStepType.CALCULATED_VALUE_NOT_ACCURATE_REASONS },
        { type: AwardFlowStepType.ENTER_MANUAL_TOTAL_VALUE },
      );

      if (data.spendAndSavings.canProvideManualTotalValue === false) {
        steps.push({ type: AwardFlowStepType.CANNOT_PROVIDE_MANUAL_TOTAL_VALUE_REASON });
      }

      steps.push({ type: AwardFlowStepType.ADDITIONAL_COMMENTS });
    }

    steps.push({ type: AwardFlowStepType.CHOOSE_SAVINGS_METHOD });

    if (data.spendAndSavings.canProvideTotalSavings) {
      switch (data.spendAndSavings.totalSavingsCalculationMethod) {
        case TotalSavingsCalculationMethod.SUM_SPECIFIC_SAVINGS:
          steps.push({ type: AwardFlowStepType.CONFIRM_SUM_SPECIFIC_SAVINGS });
          break;
        case TotalSavingsCalculationMethod.BUDGET_FINAL_VALUE_DIFF:
          steps.push({ type: AwardFlowStepType.CONFIRM_BUDGET_FINAL_VALUE_DIFF_SAVINGS });
          break;
        case TotalSavingsCalculationMethod.MANUAL:
          steps.push({ type: AwardFlowStepType.ENTER_MANUAL_SAVINGS });
          break;
      }
    } else {
      steps.push({ type: AwardFlowStepType.CANNOT_PROVIDE_TOTAL_SAVINGS_REASON });
    }
  }

  return steps;
};

const getMessagingSteps = (data: AwardFlowData) => {
  const steps: AwardFlowStep[] = [
    { type: AwardFlowStepType.CHOOSE_SUPPLIER_TYPES_TO_MESSAGE },
  ];

  for (const supplierGroup of ['awarded', 'unsuccessful', 'previouslyUnsuccessful', 'nonBidding'] as const) {
    if (data.supplierGroupsToMessage[supplierGroup]) {
      const supplierIdsInGroup = data.supplierIdsByGroup[supplierGroup];

      if (isEmpty(supplierIdsInGroup)) {
        // do nothing
      } else if (supplierIdsInGroup.length === 1) {
        steps.push({
          type: AwardFlowStepType.ENTER_SINGLE_SUPPLIER_MESSAGE,
          supplierGroup,
          // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
          supplierId: first(supplierIdsInGroup),
        });
      } else {
        steps.push({
          type: AwardFlowStepType.CHOOSE_MULTI_SUPPLIER_MESSAGE_METHOD,
          supplierGroup,
        });

        const messageConfig = data.messageConfigByGroup[supplierGroup];

        switch (messageConfig.messageMethod) {
          case MessageMethod.GENERAL: {
            steps.push({
              type: AwardFlowStepType.ENTER_MULTI_SUPPLIER_GENERAL_MESSAGE,
              supplierGroup,
            });
            break;
          }
          case MessageMethod.BY_SUPPLIER: {
            steps.push({
              type: AwardFlowStepType.ENTER_MULTI_SUPPLIER_INDIVIDUAL_MESSAGES,
              supplierGroup,
            });
            break;
          }
          case MessageMethod.CUSTOM: {
            steps.push({
              type: AwardFlowStepType.CHOOSE_MULTI_SUPPLIER_CUSTOM_MESSAGE_METHODS,
              supplierGroup,
            });

            if (!isEmpty(messageConfig.generalMessageSupplierIds)) {
              steps.push({
                type: AwardFlowStepType.ENTER_MULTI_SUPPLIER_GENERAL_MESSAGE,
                supplierGroup,
              });
            }

            if (!isEmpty(messageConfig.excludedSupplierIds) || !isEmpty(messageConfig.messageBySupplierId)) {
              steps.push({
                type: AwardFlowStepType.ENTER_MULTI_SUPPLIER_INDIVIDUAL_MESSAGES,
                supplierGroup,
              });
            }

            break;
          }
          default: break;
        }
      }
    }
  }

  return steps;
};

const getAllSteps = (data: AwardFlowData, structure: RfxStructure<Live>): AwardFlowStep[] => {
  return [
    ...staticStartSteps,
    ...getAwardDecisionSteps(data),
    ...getSpendAndSavingsSteps(data, structure),
    ...getMessagingSteps(data),
    ...staticFinalSteps,
  ];
};

const getAwardedRecipientIds = (data: AwardFlowData) => {
  const awardedRecipientIds: string[] = [];

  if (data.requestAward) {
    awardedRecipientIds.push(data.requestAward.recipientId);
  }
  if (data.awardDecisionByLotId) {
    for (const awardDecision of Object.values(data.awardDecisionByLotId)) {
      if (awardDecision?.value === 'award') {
        awardedRecipientIds.push(awardDecision.awards[0].recipientId);
      }
    }
  }
  if (data.awardDecisionByExchangeId) {
    for (const awardDecision of Object.values(data.awardDecisionByExchangeId)) {
      if (awardDecision?.value === 'award') {
        awardedRecipientIds.push(...awardDecision.awards.map(award => award.recipientId));
      }
    }
  }

  return awardedRecipientIds;
};

const getSupplierIdsByGroup = (data: AwardFlowData, structure: RfxStructure) => {
  const { bidById } = structure;

  const bids = Object.values(bidById);

  const [previouslyUnsuccessfulBids, otherBids] = partition(
    bids,
    bid => bid.status === BidStatus.UNSUCCESSFUL,
  );
  const [biddingBids, nonBiddingBids] = partition(
    otherBids,
    bid => getBidIntentionStatus(bid) === BidIntentionStatus.BIDDING,
  );
  const biddingRecipientIds = biddingBids.map(bid => bid.recipientId);

  const awardedRecipientIds = getAwardedRecipientIds(data);

  const unsuccessfulRecipientIds = difference(biddingRecipientIds, awardedRecipientIds);

  return {
    awarded: uniq(awardedRecipientIds),
    unsuccessful: uniq(unsuccessfulRecipientIds),
    previouslyUnsuccessful: uniq(previouslyUnsuccessfulBids.map(bid => bid.recipientId)),
    nonBidding: uniq(nonBiddingBids.map(bid => bid.recipientId)),
  };
};

const sanitizeData = (newData: AwardFlowData, previousData: AwardFlowData, structure: RfxStructure) => {
  const sanitizedData = { ...newData };

  // 1. ADJUST AWARD DECISIONS

  // Remove all award decisions that doesn't match the current award scenario.
  if (newData.awardScenario !== AwardScenario.REQUEST_LEVEL_AWARD) {
    delete sanitizedData.requestAward;
  }
  if (newData.awardScenario !== AwardScenario.LINE_LEVEL_AWARD) {
    delete sanitizedData.awardDecisionByExchangeId;
    delete sanitizedData.splitDecisionByExchangeId;
  }
  if (newData.awardScenario !== AwardScenario.LOT_LEVEL_AWARD) {
    delete sanitizedData.awardDecisionByLotId;
  }

  // When details of the line level award decision have changed,
  // make sure that the corresponding split decisions are in sync.
  if (
    newData.awardScenario === AwardScenario.LINE_LEVEL_AWARD &&
    sanitizedData.awardDecisionByExchangeId &&
    sanitizedData.splitDecisionByExchangeId &&
    sanitizedData.awardDecisionByExchangeId !== previousData.awardDecisionByExchangeId
  ) {
    const exchangeIds = getExchangeIdsAwardedToMultipleSuppliers(newData);

    // @ts-expect-error ts(2322) FIXME: Type 'Dictionary<{ decisionByRecipientId: any; splitBy?: "quantity" | undefined; quantitySplitMode?: "amount" | "percentage" | undefined; } | null>' is not assignable to type 'Record<string, { splitBy: "quantity"; quantitySplitMode: "amount" | "percentage"; decisionByRecipientId: Record<string, { originalPricePerUnit?: number | undefined; originalQuantity?: number | undefined; ... 4 more ...; requestCurrencyAwardedCost?: number | undefined; }>; } | null>'.
    sanitizedData.splitDecisionByExchangeId = omitBy(mapValues(
      sanitizedData.splitDecisionByExchangeId,
      (splitDecisions, exchangeId) => {
        if (!exchangeIds.includes(exchangeId)) {
          return null;
        }

        // @ts-expect-error ts(18048) FIXME: 'sanitizedData.awardDecisionByExchangeId' is possibly 'undefined'.
        const { awards } = (sanitizedData.awardDecisionByExchangeId[exchangeId]) as Extract<AwardDecision, { value: 'award' }>;

        const awardedSupplierIds = awards.map(award => award.recipientId);

        return {
          ...splitDecisions,
          // @ts-expect-error ts(18047) FIXME: 'splitDecisions' is possibly 'null'.
          decisionByRecipientId: pick(splitDecisions.decisionByRecipientId, awardedSupplierIds) as any,
        };
      },
    ), isNil);
  }

  // 2. ADJUST SAVINGS

  // When spend and savings got disabled, reset all spend and savings to the initial
  // state.
  if (!newData.spendAndSavings.enabled && previousData.spendAndSavings.enabled) {
    sanitizedData.spendAndSavings = spendAndSavingsDisabledState;
    delete sanitizedData.editBudget;
  }

  // When the award scenario or award decision details have changed,
  // 1) recalculate `supplierIdsByGroup`,
  // 2) reset all dependent spend and savings data.
  if (
    newData.awardScenario !== previousData.awardScenario ||
    !isEqual(newData.requestAward, previousData.requestAward) ||
    !isEqual(newData.awardDecisionByLotId, previousData.awardDecisionByLotId) ||
    !isEqual(newData.awardDecisionByExchangeId, previousData.awardDecisionByExchangeId) ||
    !isEqual(newData.splitDecisionByExchangeId, previousData.splitDecisionByExchangeId)
  ) {
    sanitizedData.supplierIdsByGroup = getSupplierIdsByGroup(sanitizedData, structure);

    sanitizedData.spendAndSavings = {
      ...sanitizedData.spendAndSavings,
      calculatedTotalValue: null,
      isCalculatedTotalValueAccurate: null,
      canProvideManualTotalValue: null,
      calculatedTotalValueNotAccurateReasons: null,
      calculatedTotalValueNotAccurateOtherReason: null,
      calculatedSavingsByType: null,
      areTotalSavingsAccurate: null,
    };
  }

  // When `spendAndSavings.isCalculatedTotalValueAccurate` has changed, reset all dependent
  // spend and savings data.
  if (newData.spendAndSavings.isCalculatedTotalValueAccurate !== previousData.spendAndSavings.isCalculatedTotalValueAccurate) {
    sanitizedData.spendAndSavings = {
      ...sanitizedData.spendAndSavings,
      canProvideManualTotalValue: null,
      calculatedTotalValueNotAccurateReasons: null,
      calculatedTotalValueNotAccurateOtherReason: null,
    };
  }

  if (sanitizedData.spendAndSavings.isCalculatedTotalValueAccurate) {
    // When `spendAndSavings.isCalculatedTotalValueAccurate` is truthy, reset `awardQuestionnaireComment`.
    sanitizedData.spendAndSavings = {
      ...sanitizedData.spendAndSavings,
      awardQuestionnaireComment: null,
    };
  }

  if (sanitizedData.spendAndSavings.canProvideManualTotalValue) {
    // When `spendAndSavings.canProvideManualTotalValue` is truthy, reset `cannotProvideManualTotalValueReason`.
    sanitizedData.spendAndSavings = {
      ...sanitizedData.spendAndSavings,
      cannotProvideManualTotalValueReason: null,
    };
  } else {
    // When `spendAndSavings.canProvideManualTotalValue` is falsy, reset `manualTotalValue` and `awardQuestionnaireComment`.
    sanitizedData.spendAndSavings = {
      ...sanitizedData.spendAndSavings,
      manualTotalValue: null,
    };
  }

  // When the selected savings method is BUDGET_FINAL_VALUE_DIFF but the preconditions
  // for that savings method (that both a budgeted and a total value are specified),
  // reset affected savings data.
  if (
    sanitizedData.spendAndSavings.totalSavingsCalculationMethod === TotalSavingsCalculationMethod.BUDGET_FINAL_VALUE_DIFF &&
    (
      !isFinite(sanitizedData.spendAndSavings.budgetedTotalValue) ||
      (
        !isFinite(sanitizedData.spendAndSavings.manualTotalValue) &&
        !isFinite(sanitizedData.spendAndSavings.calculatedTotalValue)
      )
    )
  ) {
    sanitizedData.spendAndSavings = {
      ...sanitizedData.spendAndSavings,
      canProvideTotalSavings: null,
      totalSavingsCalculationMethod: null,
      areTotalSavingsAccurate: null,
    };
  }

  // When the total savings method is not MANUAL, reset manual savings data.
  if (
    sanitizedData.spendAndSavings.totalSavingsCalculationMethod !== TotalSavingsCalculationMethod.MANUAL) {
    sanitizedData.spendAndSavings = {
      ...sanitizedData.spendAndSavings,
      manualTotalSavings: null,
      manualTotalSavingsDescription: null,
    };
  }

  // When `canProvideTotalSavings` is no longer `false`, reset `cannotProvideTotalSavingsReason`
  // (which only applies when `canProvideTotalSavings` is `false`).
  if (
    sanitizedData.spendAndSavings.canProvideTotalSavings !== false &&
    sanitizedData.spendAndSavings.cannotProvideTotalSavingsReason
  ) {
    sanitizedData.spendAndSavings = {
      ...sanitizedData.spendAndSavings,
      cannotProvideTotalSavingsReason: null,
    };
  }

  // Whenever the savings method changes, reset `areTotalSavingsAccurate` so the user
  // needs to reconfirm.
  if (
    sanitizedData.spendAndSavings.canProvideTotalSavings !== previousData.spendAndSavings.canProvideTotalSavings ||
    sanitizedData.spendAndSavings.totalSavingsCalculationMethod !== previousData.spendAndSavings.totalSavingsCalculationMethod
  ) {
    sanitizedData.spendAndSavings = {
      ...sanitizedData.spendAndSavings,
      areTotalSavingsAccurate: null,
    };
  }

  // 3. ADJUST MESSAGES

  // We only need to check the supplier groups 'awarded' and 'unsuccessful'
  // because the contents of the other supplier groups don't change in the
  // award flow.
  for (const supplierGroup of ['awarded', 'unsuccessful']) {
    const supplierCount = sanitizedData.supplierIdsByGroup[supplierGroup].length;
    if (
      sanitizedData.supplierGroupsToMessage[supplierGroup] &&
      supplierCount === 0
    ) {
      sanitizedData.supplierGroupsToMessage[supplierGroup] = false;
    }

    if (supplierCount === 1 && sanitizedData.messageConfigByGroup[supplierGroup].messageMethod !== MessageMethod.BY_SUPPLIER) {
      sanitizedData.messageConfigByGroup[supplierGroup].messageMethod = MessageMethod.BY_SUPPLIER;
    }
  }

  for (const supplierGroup of Object.keys(sanitizedData.messageConfigByGroup)) {
    if (!sanitizedData.supplierGroupsToMessage[supplierGroup]) {
      sanitizedData.messageConfigByGroup[supplierGroup] = getEmptyMessageConfig();
    } else {
      const { messageMethod } = sanitizedData.messageConfigByGroup[supplierGroup];

      const canHaveGeneralMessageSupplierIds = messageMethod === MessageMethod.CUSTOM;

      if (canHaveGeneralMessageSupplierIds) {
        const supplierIdsInGroup = sanitizedData.supplierIdsByGroup[supplierGroup];

        sanitizedData.messageConfigByGroup[supplierGroup].generalMessageSupplierIds = intersection(
          sanitizedData.messageConfigByGroup[supplierGroup].generalMessageSupplierIds,
          supplierIdsInGroup,
        );
      } else {
        sanitizedData.messageConfigByGroup[supplierGroup].generalMessageSupplierIds = [];
      }

      const canHaveMessagesBySupplier = messageMethod === MessageMethod.BY_SUPPLIER || messageMethod === MessageMethod.CUSTOM;

      if (canHaveMessagesBySupplier) {
        const supplierIdsInGroup = sanitizedData.supplierIdsByGroup[supplierGroup];

        sanitizedData.messageConfigByGroup[supplierGroup].messageBySupplierId = pick(
          sanitizedData.messageConfigByGroup[supplierGroup].messageBySupplierId,
          supplierIdsInGroup,
        );

        if (supplierIdsInGroup.length > 1) {
          sanitizedData.messageConfigByGroup[supplierGroup].excludedSupplierIds = intersection(
            sanitizedData.messageConfigByGroup[supplierGroup].excludedSupplierIds,
            supplierIdsInGroup,
          );
        } else {
          sanitizedData.messageConfigByGroup[supplierGroup].excludedSupplierIds = [];
        }
      } else {
        sanitizedData.messageConfigByGroup[supplierGroup].excludedSupplierIds = [];
        sanitizedData.messageConfigByGroup[supplierGroup].messageBySupplierId = {};
      }

      const canHaveGeneralMessage = messageMethod === MessageMethod.GENERAL || (
        messageMethod === MessageMethod.CUSTOM && !isEmpty(sanitizedData.messageConfigByGroup[supplierGroup].generalMessageSupplierIds)
      );

      if (!canHaveGeneralMessage) {
        sanitizedData.messageConfigByGroup[supplierGroup].generalMessage = null;
      }
    }
  }

  return sanitizedData;
};

const AwardFlowContent = () => {
  const structure = rfx.useStructure<Live>();
  // @ts-expect-error ts(2345) FIXME: Argument of type '() => { steps: AwardFlowStep[]; currentStep: AwardFlowStep | undefined; hasLineItemsWithTotalCost: boolean; supplierIdsByGroup: { awarded: string[]; unsuccessful: string[]; previouslyUnsuccessful: string[]; nonBidding: string[]; }; supplierGroupsToMessage: {}; spendAndSavings: RfxSpendAndSavings; messageConfigByGrou...' is not assignable to parameter of type 'AwardFlowData | (() => AwardFlowData)'.
  const [data, setData] = useState<AwardFlowData>(() => ({
    steps: staticStartSteps,
    currentStep: first(staticStartSteps),

    hasLineItemsWithTotalCost: Object.values(structure.exchangeDefById).some(exchangeDef => (
      !exchangeDef.isObsolete &&
      (
        (exchangeDef.type === ExchangeType.LINE_ITEM && exchangeDef.fields.totalCost) ||
        isStandaloneAuctionLineItemExchangeDef(exchangeDef)
      )
    )),
    supplierIdsByGroup: getSupplierIdsByGroup({} as any, structure),
    supplierGroupsToMessage: {},
    spendAndSavings: structure.spendAndSavings || spendAndSavingsDisabledState,
    messageConfigByGroup: {
      awarded: getEmptyMessageConfig(),
      unsuccessful: getEmptyMessageConfig(),
      previouslyUnsuccessful: getEmptyMessageConfig(),
      nonBidding: getEmptyMessageConfig(),
    },
  }));

  const StepComponent = stepComponentByType[data.currentStep.type];

  const submitAndNavigate = useCallback((
    newData: Partial<AwardFlowData> | null,
    direction: Direction | null,
    targetStep?: AwardFlowStepType,
  ) => {
    setData(previousData => {
      const updatedData = newData
        ? sanitizeData({ ...previousData, ...newData }, previousData, structure)
        : previousData;

      const newSteps = getAllSteps(updatedData, structure);

      const currentStepIndex = newSteps.findIndex(step => isEqual(step, previousData.currentStep));

      const newCurrentStep = direction
        ? (
          direction === Direction.BACK
            ? newSteps[currentStepIndex - 1]
            : newSteps[currentStepIndex + 1]
        )
        : newSteps.find(step => step.type === targetStep);

      if (!newCurrentStep) {
        throw new DsError('Step does not exist', {
          previousSteps: previousData.steps,
          newSteps,
          currentStep: previousData.currentStep,
          direction,
        });
      }

      return {
        ...updatedData,
        steps: newSteps,
        currentStep: newCurrentStep,
      };
    });
  }, [structure]);

  return (
    <>
      <StepComponent
        // The key is required to make sure we don't keep form state
        // when switching between subsequent steps that render the
        // same component (like split line award steps)
        key={JSON.stringify(data.currentStep)}
        data={data}
        submitAndNavigate={submitAndNavigate}
      />
      {data.currentStep.type !== AwardFlowStepType.SUBMITTED_SUCCESSFULLY && (
        <LeaveFlowModal
          content={(
            <Text>
              <Trans
                i18nKey="request.dialog.leaveAwardFlow.info"
                ns="translation"
                components={{ a: <OpenRequestInNewTabLink /> }}
              />
            </Text>
          )}
        />
      )}
    </>
  );
};

const AwardFlowGuard = () => {
  const { t } = useTranslation('translation');
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqId = useRfqId();
  const { canAwardOrCloseRfx } = rfx.useRfxPermissions();

  const { data, isLoading, isError, isSuccess } = useRfqCostAndSavingsData({
    rfqId,
    currentCompanyId,
    enabled: canAwardOrCloseRfx,
  });

  return !canAwardOrCloseRfx ? (
    <rfx.StateProvider isLive>
      <HiddenRequestPagePlaceholder accessRequiresOwnerRole />
    </rfx.StateProvider>
  ) : isLoading ? (
    <LoadingPanel />
  ) : isError ? (
    <ErrorPanel error={t('errors.unexpected')} />
  ) : data?.isLocked === true ? (
    <MessageBlock variant="error">
      <Text fontSize={4} fontWeight={500} mb={1}>
        {t('requests.dialog.lockedExchanges.heading')}
      </Text>
      <Text>
        {t('requests.dialog.lockedExchanges.body')}
      </Text>
    </MessageBlock>
  ) : data && isSuccess ? (
    <rfx.CostAndSavingsDataProvider data={data}>
      <ExchangeDefFieldValueProvider>
        <AwardFlowContent />
      </ExchangeDefFieldValueProvider>
    </rfx.CostAndSavingsDataProvider>
  ) : (
    null
  );
};

export const AwardFlow = () => {
  const { t } = useTranslation('translation');
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const rfqId = useRfqId();

  const { data: structure, isLoading, isError, isSuccess } =
    useLiveRfqStructure({ rfqId, currentCompanyId });

  return isLoading ? (
    <LoadingPanel />
  ) : isError ? (
    <ErrorPanel error={t('errors.unexpected')} />
  ) : isSuccess && structure ? (
    <rfx.StructureProvider structure={structure}>
      <AwardFlowGuard />
    </rfx.StructureProvider>
  ) : (
    null
  );
};
