import { Box, Flex } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { IconTextButton } from '@deepstream/ui-kit/elements/button/IconTextButton';
import { useCurrentCompanyId } from '../currentCompanyId';
import { useDeviceSize } from '../ui/useDeviceSize';
import { Bold } from '../Bold';
import { BidStatusIndicators } from '../BidStatus';
import { ErrorMessage } from '../ui/ErrorMessage';
import { BidPageSenderTabs } from './BidPageSenderTabs';
import * as rfx from '../rfx';
import { RecipientIdProvider, useLiveRfqStructure, RfqIdProvider, useRecipientId, useRfqId } from '../useRfq';
import { Loading } from '../ui/Loading';
import { HeadingRow } from './HeadingRow';
import { PageHeading } from './PageHeading';
import * as Layout from '../Layout';
import { useRequestSentNavigation } from '../appNavigation';

const BackToSuppliers = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();

  return (
    <IconTextButton icon="chevron-left" mb="3px" fontSize={1} onClick={onClick}>
      {t('request.backToSuppliers')}
    </IconTextButton>
  );
};

const BidHeading = () => {
  const { t } = useTranslation();
  const recipient = rfx.useRecipient();

  return (
    <Flex>
      <Bold color="subtext" mr={2} fontSize={5} sx={{ flexShrink: 0 }}>
        {t('general.bid')}
      </Bold>
      <PageHeading text={recipient.company.name} />
    </Flex>
  );
};

export const SenderBidPageHeader = ({
  selectedPageId,
  selectedTabId,
}: {
  selectedPageId?: string;
  selectedTabId?: string;
}) => {
  const rfqId = useRfqId();
  const recipientId = useRecipientId();
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { isExtraSmall } = useDeviceSize();

  const { data: structure, isLoading, isError, isSuccess } =
    useLiveRfqStructure({ rfqId, recipientId, currentCompanyId });

  const navigation = useRequestSentNavigation();

  return (
    <RfqIdProvider rfqId={rfqId}>
      <RecipientIdProvider recipientId={recipientId}>
        <Layout.PageHeaderWrapper>
          {isLoading ? (
            <Loading />
          ) : isError ? (
            <ErrorMessage
              fontSize={2}
              lineHeight={1}
              error={t('request.errors.couldNotGetBidStatus')}
            />
          ) : isSuccess && structure ? (
            <rfx.StructureProvider structure={structure}>
              <HeadingRow>
                <Box flex={1}>
                  <BackToSuppliers onClick={() => navigation.navigateToSuppliers()} />
                  <BidHeading />
                </Box>
                {!isExtraSmall && (
                  <BidStatusIndicators />
                )}
              </HeadingRow>
              <BidPageSenderTabs
                selectedPageId={selectedPageId}
                selectedTabId={selectedTabId}
              />
            </rfx.StructureProvider>
          ) : (
            null
          )}
        </Layout.PageHeaderWrapper>
      </RecipientIdProvider>
    </RfqIdProvider>
  );
};
