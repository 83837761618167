import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { compact } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useContract } from './useContract';
import * as Layout from '../../Layout';
import { useDraftContractNavigation } from '../../appNavigation';
import { PageHeading } from '../../page-headers/PageHeading';
import { EditStatusIndicator } from '../../EditStatus';
import { useContractState } from './contract';
import { useApi, wrap } from '../../api';

type ContractDraftHeaderProps = {
  contractId: string;
  selectedTabId: string;
};

export const ContractDraftHeader = ({ contractId, selectedTabId }: ContractDraftHeaderProps) => {
  const { t } = useTranslation(['contracts', 'translation']);
  const currentCompanyId = useCurrentCompanyId();
  const draftContractNavigation = useDraftContractNavigation();
  // @ts-expect-error ts(2339) FIXME: Property 'isRevising' does not exist on type 'ContractStateContextType | undefined'.
  const { isRevising, isAmending, isTemplate } = useContractState();
  const api = useApi();

  const { data: contract } = useContract({
    contractId,
    // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string | undefined'.
    currentCompanyId,
    scope: 'draft',
    isTemplate,
    enabled: !isTemplate,
  });

  const { data: currentTemplate } = useQuery(
    ['contractTemplate', { currentCompanyId, templateId: contractId }],
    wrap(api.getContractTemplate),
    {
      enabled: isTemplate,
    },
  );

  const heading = isTemplate ? currentTemplate?.meta.name : contract?.summary.name;

  const tabs = useMemo(
    () => compact([
      {
        id: 'summary',
        name: t('general.summary', { ns: 'translation' }),
        navigate: () => draftContractNavigation.navigateToSummary(),
      },
      {
        id: 'details',
        name: t('general.details', { ns: 'translation' }),
        navigate: () => draftContractNavigation.navigateToDetailsIndex(),
      },
      {
        id: 'contract',
        name: t('general.contract', { count: 1, ns: 'translation' }),
        navigate: () => draftContractNavigation.navigateToContract(),
      },
      !isTemplate && {
        id: 'team',
        name: t('general.team', { ns: 'translation' }),
        navigate: () => draftContractNavigation.navigateToTeam(),
      },
      !isRevising && !isAmending && {
        id: 'reminders',
        name: t('general.reminder_other', { ns: 'translation' }),
        navigate: () => draftContractNavigation.navigateToReminders(),
      },
      {
        id: 'review',
        name: t(`general.${isTemplate ? 'reviewAndExit' : 'reviewAndSend'}`, { ns: 'translation' }),
        navigate: () => draftContractNavigation.navigateToReview(),
      },
    ]),
    [t, isTemplate, isRevising, isAmending, draftContractNavigation],
  );

  let editStatus;

  if (isRevising) {
    editStatus = 'revision';
  } else if (isAmending) {
    editStatus = 'amendment';
  } else if (isTemplate) {
    editStatus = 'template';
  } else {
    editStatus = 'draft';
  }

  return (
    <Layout.PageHeader
      tabs={tabs}
      selectedTabId={selectedTabId}
      heading={() => (
        <PageHeading
          icon="file-contract"
          text={heading || t('untitledContract', { ns: 'contracts' })}
        />
      )}
    >
      <Box ml={4} flex="0 0 auto">
        <EditStatusIndicator isContract editStatus={editStatus} />
      </Box>
    </Layout.PageHeader>
  );
};
