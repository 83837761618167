import {
  BidStatus, Company, ExternalSystem, isBidActive,
  Live,
} from '@deepstream/common/rfq-utils';
import {
  compact,
  identity,
  isEmpty,
  last,
  noop,
  uniq,
  values,
} from 'lodash';
import { useMemo, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useContextMenu } from '@deepstream/ui-kit/elements/popup/useContextMenu';
import { Panel, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridSelectionProvider, GridSelectionState, useGridSelection } from '@deepstream/ui-kit/grid/EditableGrid/GridSelectionContext';
import { useMultiSortGridRows } from '@deepstream/ui-kit/grid/EditableGrid/useMultiSortGridRows';
import { assertDefined } from '@deepstream/utils';
import { DownloadRequestsFilesModal } from './DownloadRequestsFilesModal';
import { MoveToNextStageModal } from './MoveToNextStageModal';
import { ReinstateBidsModal } from '../OutcomeModal/ReinstateBidsModal';
import { RejectBidsModal } from '../OutcomeModal/RejectBidsModal';
import { SuppliersTableHeader } from './SuppliersTableHeader';
import { useModalState } from '../../../ui/useModalState';
import { UnlockBidsModal } from './UnlockBidsModal';
import { useLiveRfqStructureQueryKey, useRfqId } from '../../../useRfq';
import { InviteRemindersModal } from './InviteRemindersModal';
import { DeclineToBidModal } from './DeclineToBidModal';
import * as rfx from '../../../rfx';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useCurrentUser } from '../../../useCurrentUser';
import { GROUPED_ROW_SEPARATOR, SuppliersGrid } from '../../../ui/ExchangeDefsGrid/SuppliersGrid';
import { Loading } from '../../../ui/Loading';
import { ErrorMessage } from '../../../ui/ErrorMessage';
import { useStatsByRecipientId } from '../../../useStatsByRecipientId';
import { useSuppliersGridColumns } from './useSuppliersGridColumns';
import { useSuppliersGridColumnConfigById } from './useSuppliersGridColumnConfigById';
import { useAvailableColumnIds } from './requestSentSuppliersViews';
import { useExternalSystems } from '../../../useExternalSystems';
import { StatsByRecipientId } from '../../../types';
import { useSuppliersGridConfig } from './useSuppliersGridConfig';
import { SuppliersContextMenu } from './SuppliersContextMenu';
import { useSuppliersGridActionConfig } from './useSuppliersGridActionConfig';
import { useCanRequestApprovalByStageId } from '../../StageApprovals/useCanRequestApproval';
import { StageApprovalRequestModal } from '../../StageApprovals/StageApprovalRequestModal';
import { useSuppliersGridFilters } from './useSuppliersGridFilters';

const RequestSentSuppliersPanelContent = ({
  navigateToAddSuppliers = noop,
  navigateToSupplier = noop,
  recipients,
  externalSystem,
  statsByRecipientId,
}: {
  navigateToAddSuppliers?: () => void;
  navigateToSupplier?: (supplierRow: Company) => void;
  recipients: Company[];
  externalSystem?: ExternalSystem;
  statsByRecipientId: StatsByRecipientId;
}) => {
  const structure = rfx.useStructure<Live>();
  const rfqId = useRfqId({ required: true });
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();
  const downloadFilesModal = useModalState();
  const unlockBidsModal = useModalState();
  const rejectBidsModal = useModalState();
  const reinstateBidsModal = useModalState();
  const moveToNextStageModal = useModalState();
  const inviteRemindersModal = useModalState();
  const declineToBidModal = useModalState();
  const approvalRequestModal = useModalState();
  const { selectedRowIds, allRowIds, setGridSelectionState, toggle } = useGridSelection();

  const getContextMenuData = useCallback((event) => {
    const cellElement = event.target.closest('div[role="gridcell"]');

    const rowIndex = cellElement?.getAttribute('aria-rowindex');

    // subtract 1 to exclude the header row and 1 because
    // aria-rowindex is 1-based
    const rowId = allRowIds[rowIndex - 2];

    if (rowId) {
      if (!selectedRowIds.includes(rowId)) {
        toggle(rowId);
      }
    }

    return Boolean(rowId);
  }, [allRowIds, selectedRowIds, toggle]);

  const contextMenu = useContextMenu(getContextMenuData);
  const liveStructureQueryKey = useLiveRfqStructureQueryKey({
    rfqId,
    currentCompanyId,
  });
  const { bidById } = structure;

  const hasExternalSupplierStatuses = Boolean(externalSystem?.externalData?.hasSupplierStatuses);

  const availableColumnIds = useAvailableColumnIds(hasExternalSupplierStatuses);

  const columnConfigById = useSuppliersGridColumnConfigById(
    statsByRecipientId,
    externalSystem,
  );

  const {
    selectedSupplierStageIds,
    visibleRecipientIds,
    selectedRecipientIds,
    selectedRecipients,
    selectedRecipientCompanies,
    selectedActiveRecipientsNotInFinalStage,
    selectedInvitedRecipients,
    selectedRecipientsWithLockedItems,
    selectedUnresponsiveRecipients,
    areActiveBidsSelected,
    hasUnlockableExchanges,
  } = useMemo(() => {
    const visibleRecipientIds = compact(uniq(allRowIds.map(rowId => rowId ? rowId.split(GROUPED_ROW_SEPARATOR)[0] : null)));
    const selectedRecipientIds = uniq(selectedRowIds.map(rowId => rowId.split(GROUPED_ROW_SEPARATOR)[0]));

    const selectedRecipients = recipients.filter(recipient => (
      selectedRecipientIds.includes(recipient._id)
    ));

    const lastStageId = last(structure.stages)?._id;

    const selectedActiveRecipientsNotInFinalStage = selectedRecipients.filter(recipient => {
      const bid = bidById[recipient._id];

      return (
        bidById[recipient._id]?.status !== BidStatus.UNSUCCESSFUL &&
        bid.stageId !== lastStageId
      );
    });

    return {
      selectedSupplierStageIds: compact(uniq(
        selectedRecipients.map(recipient => bidById[recipient._id].stageId),
      )),
      visibleRecipientIds,
      selectedRecipientIds,
      selectedRecipients,
      selectedRecipientCompanies: selectedRecipients.map(row => row.company),
      selectedActiveRecipientsNotInFinalStage,
      selectedInvitedRecipients: selectedRecipients.filter(recipient => (
        recipient.company.isPending
      )),
      selectedRecipientsWithLockedItems: statsByRecipientId
        ? selectedRecipients.filter(recipient => (
          statsByRecipientId[recipient._id]?.numLockedItems > 0
        ))
        : [],
      selectedUnresponsiveRecipients: selectedRecipients.filter(recipient => (
        bidById[recipient._id]?.status === BidStatus.NO_RESPONSE
      )),
      areActiveBidsSelected: selectedRecipientIds.some(
        rowId => {
          const bidStatus = bidById[rowId]?.status;

          return bidStatus && isBidActive(bidStatus);
        },
      ),
      hasUnlockableExchanges: selectedRecipients.some(recipient => {
        return !isEmpty(statsByRecipientId?.[recipient._id]?.unlockableExchangeIdsBySectionId);
      }),
    };
  }, [allRowIds, selectedRowIds, recipients, statsByRecipientId, bidById, structure.stages]);

  const closeBidActionsModal = useCallback(
    (shouldRefetchRfq: boolean) => {
      if (shouldRefetchRfq) {
        queryClient.invalidateQueries(liveStructureQueryKey);
        queryClient.invalidateQueries(['allExchanges', { rfqId, currentCompanyId }]);
        queryClient.invalidateQueries(['exchanges', { rfqId, currentCompanyId }]);
        queryClient.invalidateQueries(['statsByRecipientId', { rfqId, currentCompanyId }]);
      }
      rejectBidsModal.close();
      reinstateBidsModal.close();
    },
    [rejectBidsModal, reinstateBidsModal, queryClient, rfqId, currentCompanyId, liveStructureQueryKey],
  );

  const suppliersGridConfig = useSuppliersGridConfig({
    storageKey: `${currentCompanyId}.${currentUser._id}.${rfqId}.suppliersGrid.config`,
    availableColumnIds,
    columnConfigById,
  });

  const view = last(suppliersGridConfig.view.selectedItems);

  const gridFilters = useSuppliersGridFilters(
    suppliersGridConfig,
    recipients,
    columnConfigById,
    hasExternalSupplierStatuses,
  );

  const filterRecipients = useCallback((recipients, groupId) => {
    return recipients.filter(recipient => {
      return gridFilters.every(gridFilter => gridFilter.config.matchesFilter(recipient, gridFilter, groupId));
    });
  }, [gridFilters]);

  assertDefined(view, 'view');

  const viewId = view.value;

  const columns = useSuppliersGridColumns({
    suppliersGridConfig,
    columnConfigById,
  });

  const sortRows = useMultiSortGridRows(
    columns,
    suppliersGridConfig.sorting.selectedSorting,
  );

  const canRequestApprovalByStageId = useCanRequestApprovalByStageId();

  const canRequestStageApprovalForAnyStage = values(canRequestApprovalByStageId).some(identity);

  const suppliersGridActionConfig = useSuppliersGridActionConfig({
    numSelectedRecipients: selectedRecipients.length,
    numVisibleRecipients: visibleRecipientIds.length,
    isUnlockVisible: hasUnlockableExchanges,
    isMoveToNextStageDisabled: isEmpty(selectedActiveRecipientsNotInFinalStage),
    areActiveBidsSelected,
    areUnresponsiveBidsSelected: !isEmpty(selectedUnresponsiveRecipients),
    canRequestApprovalByStageId,
    selectedSupplierStageIds,
    hasSelectedInvitedRecipients: !isEmpty(selectedInvitedRecipients),
    hasSelectedActiveRecipientsNotInFinalStage: !isEmpty(selectedActiveRecipientsNotInFinalStage),
    requestApproval: approvalRequestModal.open,
    unlockBids: unlockBidsModal.open,
    rejectBids: rejectBidsModal.open,
    reinstateBids: reinstateBidsModal.open,
    moveToNextStage: moveToNextStageModal.open,
    downloadFiles: downloadFilesModal.open,
    onDeclineToBid: declineToBidModal.open,
    sendInviteReminders: inviteRemindersModal.open,
  });

  return (
    <Panel mb={20}>
      <SuppliersTableHeader
        numSelectedRecipients={selectedRecipients.length}
        numVisibleRecipients={visibleRecipientIds.length}
        navigateToAddSuppliers={navigateToAddSuppliers}
        canRequestStageApproval={canRequestStageApprovalForAnyStage}
        suppliersGridConfig={suppliersGridConfig}
        suppliersGridActionConfig={suppliersGridActionConfig}
        gridFilters={gridFilters}
      />

      <PanelPadding onContextMenu={contextMenu.onContextMenu}>
        <GridIdPrefixProvider>
          <SuppliersGrid
            columns={columns}
            recipients={recipients}
            navigateToSupplier={navigateToSupplier}
            viewId={viewId}
            sortRows={sortRows}
            filterRecipients={filterRecipients}
            clearFilters={suppliersGridConfig.filters.clear}
            onColumnResize={suppliersGridConfig.onColumnResize}
          />
        </GridIdPrefixProvider>
        <SuppliersContextMenu
          contextMenu={contextMenu}
          suppliersGridActionConfig={suppliersGridActionConfig}
        />
      </PanelPadding>

      <DownloadRequestsFilesModal
        recipientIds={selectedRecipientIds}
        isOpen={downloadFilesModal.isOpen}
        onCancel={downloadFilesModal.close}
        onSave={downloadFilesModal.close}
      />

      {hasUnlockableExchanges && (
        <UnlockBidsModal
          selectedRecipients={selectedRecipientsWithLockedItems}
          statsByRecipientId={statsByRecipientId}
          isOpen={unlockBidsModal.isOpen}
          onCancel={unlockBidsModal.close}
          onSave={unlockBidsModal.close}
        />
      )}

      <RejectBidsModal
        loserIds={selectedRecipientIds}
        isOpen={rejectBidsModal.isOpen}
        onClose={closeBidActionsModal}
      />

      <ReinstateBidsModal
        recipientsIds={selectedRecipientIds}
        isOpen={reinstateBidsModal.isOpen}
        onClose={closeBidActionsModal}
      />

      <MoveToNextStageModal
        isOpen={moveToNextStageModal.isOpen}
        onClose={moveToNextStageModal.close}
        recipients={selectedActiveRecipientsNotInFinalStage}
        // We're resetting the grid selection state because
        // the IDs of grouped grids can be composed of `[stageId]-[recipientId]`
        // -- when recipients are moved to other stages, these IDs no longer
        // match the actual IDs in the grid so the grid selection state would be
        // out of sync.
        onSuccess={() => setGridSelectionState(GridSelectionState.NONE)}
      />

      <InviteRemindersModal
        isOpen={inviteRemindersModal.isOpen}
        onClose={inviteRemindersModal.close}
        selectedInvitedRecipients={selectedInvitedRecipients}
      />

      <DeclineToBidModal
        hasSelectionWarning={selectedUnresponsiveRecipients.length !== selectedRecipients.length}
        recipients={selectedUnresponsiveRecipients}
        isOpen={declineToBidModal.isOpen}
        onClose={declineToBidModal.close}
      />

      {approvalRequestModal.isOpen && canRequestStageApprovalForAnyStage && selectedSupplierStageIds[0] && (
        <StageApprovalRequestModal
          initialStageId={selectedSupplierStageIds[0]}
          initialRecipients={selectedRecipientCompanies}
          close={approvalRequestModal.close}
          isOpen={approvalRequestModal.isOpen}
        />
      )}
    </Panel>
  );
};

// Custom toggleRow function that enables / disables rows with
// the same prefix as the provided rowId
const toggleRow = (rowId: string, rowIds: string[], allRowIds: (string | null)[]): string[] => {
  const rowIdPrefix = rowId.split(GROUPED_ROW_SEPARATOR)[0];

  if (rowIds.includes(rowId)) {
    return rowIds.filter(rowId => rowId.split(GROUPED_ROW_SEPARATOR)[0] !== rowIdPrefix);
  } else {
    return [
      ...rowIds,
      ...allRowIds.filter(rowId => rowId && rowId.split(GROUPED_ROW_SEPARATOR)[0] === rowIdPrefix) as string[],
    ];
  }
};

export const RequestSentSuppliersPanel = ({
  navigateToAddSuppliers = noop,
  navigateToSupplier = noop,
}: {
  navigateToAddSuppliers?: () => void;
  navigateToSupplier?: (supplierRow: Company) => void;
}) => {
  const { t } = useTranslation('translation');
  const rfqId = useRfqId();
  const structure = rfx.useStructure<Live>();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { data: externalSystems, status: externalSystemsStatus } = useExternalSystems();

  const { data: statsByRecipientId, status: statsByRecipientIdStatus } = useStatsByRecipientId({
    rfqId,
    currentCompanyId,
  });

  const { recipients } = structure;

  const recipientIds = recipients.map(recipient => recipient._id);

  return statsByRecipientIdStatus === 'loading' || externalSystemsStatus === 'loading' ? (
    <PanelPadding>
      <Loading fontWeight={400} />
    </PanelPadding>
  ) : statsByRecipientIdStatus === 'error' || externalSystemsStatus === 'error' ? (
    <PanelPadding>
      <ErrorMessage fontSize={2} error={t('errors.unexpected')} />
    </PanelPadding>
  ) : statsByRecipientId ? (
    <GridSelectionProvider initialRowIds={recipientIds} toggleRow={toggleRow}>
      <RequestSentSuppliersPanelContent
        navigateToAddSuppliers={navigateToAddSuppliers}
        navigateToSupplier={navigateToSupplier}
        recipients={recipients}
        externalSystem={externalSystems?.find(externalSystem => externalSystem.externalData?.hasSupplierStatuses)}
        statsByRecipientId={statsByRecipientId}
      />
    </GridSelectionProvider>
  ) : (
    null
  );
};
