import { filter } from 'lodash';
import { RoleRestrictionProfile } from '@deepstream/common/user-utils';
import { QuestionnaireRoles } from '@deepstream/common/preQual';
import { SupplierState } from '../../../RequestSuppliers/types';
import { RequestRole } from '../../../../types';

export const getSelectedSupplierUsers = (supplier: SupplierState) => filter(
  supplier.users,
  user => user.selected,
);

export type FormValues = {
  templates: {
    _id: string;
    name: string;
  }[];
  teamMembers: SenderTeamMember[];
  suppliers: SupplierState[];
};

export type SenderTeamMember = {
  _id: string;
  name: string;
  email: string;
  role: RequestRole;
  roleRestrictionProfile?: RoleRestrictionProfile;
  pagesPermissions: QuestionnaireRoles;
};
