import { RfxEvaluationSection } from '@deepstream/common/rfq-utils';
import { findIndex } from 'lodash';
import * as React from 'react';
import { CellProps } from 'react-table';
import { ExchangeSnapshot } from './types';
import * as rfx from './rfx';

export const EvaluationCriterionNumber = ({ exchange }: { exchange: ExchangeSnapshot }) => {
  const section = rfx.useSectionWithPosition<RfxEvaluationSection>();

  const exchangeIndex = findIndex(
    section.exchangeDefIds,
    exchangeDefId => exchangeDefId === exchange._id,
  );

  return (
    <>
      {section.number}.{exchangeIndex + 1}
    </>
  );
};

export const EvaluationCriterionDescriptionCell: React.FC<CellProps<any>> = (props) => (
  <>
    <EvaluationCriterionNumber exchange={props.row.original} /> – {props.cell.value}
  </>
);
