import { noop } from 'lodash';
import * as React from 'react';
import { Box, BoxProps, Flex, SxStyleProp, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { ONE_DAY, ONE_HOUR } from '@deepstream/common/constants';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { DateFormat } from '@deepstream/utils';
import { Bold } from './Bold';
import { useDeadline, WAYYYYYY_THE_FUCK_IN_THE_FUTURE } from './deadline';
import { Datetime2 } from './Datetime';

const CountdownLabel: React.FC<any> = ({ color, iconStyle, children }) => (
  <Flex alignItems="center" fontSize={2}>
    <Icon
      icon="clock"
      regular
      fontSize={iconStyle?.fontSize || 1}
      mr={1}
      sx={iconStyle}
    />
    <Bold color={color}>
      {children}
    </Bold>
  </Flex>
);

const DeadlineTooltip = ({ deadline, children }: { deadline: Date; children: React.ReactElement }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      content={
        <Box m={0}>
          <Text>{t('general.deadline')}:{' '}</Text>
          <Text><Datetime2 value={deadline} format={DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ} /></Text>
        </Box>
      }
    >
      {children}
    </Tooltip>
  );
};

type DeadlineCountdownProps = BoxProps & {
  deadline: Date;
  isFinalDeadline?: boolean;
  onDeadlinePassed?: any;
  isDeadlineAvailable?: boolean;
  referenceDate?: Date | null;
  iconStyle?: SxStyleProp;
  labelStyle?: SxStyleProp;
  withLabel?: boolean;
};

export const DeadlineCountdown: React.FC<DeadlineCountdownProps> = ({
  deadline,
  isFinalDeadline,
  isDeadlineAvailable = true,
  referenceDate,
  withLabel,
  iconStyle,
  labelStyle,
  onDeadlinePassed = noop,
  ...props
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { remainingMs, countdown, hasPassed } = useDeadline({
    deadline: isDeadlineAvailable ? deadline : WAYYYYYY_THE_FUCK_IN_THE_FUTURE,
    referenceDate,
    onPassed: onDeadlinePassed,
  });

  const { seconds, minutes, hours } = countdown;

  return (
    <DeadlineTooltip deadline={deadline}>
      <Box {...props}>
        {withLabel && (
          <Text
            sx={{
              fontSize: 1,
              fontWeight: 500,
              mb: 1,
              ...labelStyle,
            }}
          >
            {isFinalDeadline || hasPassed ? (
              t('general.deadline')
            ) : (
              t('request.deadline.nextDeadline')
            )}
          </Text>
        )}
        {!isDeadlineAvailable ? (
          <Bold fontSize={2} mt="1px">
            {t('general.notApplicableShort')}
          </Bold>
        ) : hasPassed ? (
          <CountdownLabel iconStyle={iconStyle}>
            {t('request.deadline.passed')}
          </CountdownLabel>
        ) : remainingMs / ONE_HOUR < 1 ? (
          <CountdownLabel color={theme.colors.danger} iconStyle={iconStyle}>
            {minutes}{t('general.minuteLetter')} {seconds}{t('general.secondLetter')}
          </CountdownLabel>
        ) : remainingMs / ONE_HOUR < 24 ? (
          <CountdownLabel color={theme.colors.warning} iconStyle={iconStyle}>
            {hours}{t('general.hourLetter')} {minutes}{t('general.minuteLetter')}
          </CountdownLabel>
        ) : (
          <CountdownLabel iconStyle={iconStyle}>
            {Math.floor(remainingMs / ONE_DAY)}{t('general.dayLetter')} {hours}{t('general.hourLetter')}
          </CountdownLabel>
        )}
      </Box>
    </DeadlineTooltip>
  );
};
