import { useRef, useState, useEffect } from 'react';
import { Box } from 'rebass/styled-components';
import { Chart as ChartJS } from 'chart.js';
import { useTranslation } from 'react-i18next';
import { Panel, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { AuctionGraph, deactivateDataset, activateDataset } from './AuctionGraph';
import { AuctionLeaderboard } from './AuctionLeaderboard';

const GRAPH_MIN_HEIGHT = 320;
const GRAPH_VERTICAL_SUBTRAHEND = 468;
const GRAPH_LEADERBOARD_OFFSET = 48;

export const AuctionGraphPanel = () => {
  const { t } = useTranslation();
  const chartRef = useRef<ChartJS>(null);

  const [activeBidderId, setActiveBidderId] = useState<string | null>(null);

  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      const activeIndex = chart.data.datasets.findIndex(
        dataset => dataset.label === activeBidderId,
      );

      chart.data.datasets.forEach((dataset, index) => {
        if (activeIndex > -1 && index !== activeIndex) {
          deactivateDataset(dataset);
        } else {
          activateDataset(dataset);
        }
      });

      chart.update();
    }
  }, [activeBidderId]);

  return (
    <Panel>
      <PanelPadding
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box flex={1}>
          <div
            style={{
              position: 'relative',
              height: `calc(100vh - ${GRAPH_VERTICAL_SUBTRAHEND}px)`,
              minHeight: GRAPH_MIN_HEIGHT,
              width: '100%',
            }}
          >
            <AuctionGraph
              ref={chartRef}
              description={t('request.auction.bidProgressChartDescription')}
            />
          </div>
        </Box>
        <Box
          sx={{ minWidth: '334px', flex: 0 }}
          style={{
            height: `calc(100vh - ${GRAPH_VERTICAL_SUBTRAHEND + GRAPH_LEADERBOARD_OFFSET}px)`,
            minHeight: GRAPH_MIN_HEIGHT - GRAPH_LEADERBOARD_OFFSET,
            width: '100%',
          }}
        >
          <AuctionLeaderboard setActiveBidderId={setActiveBidderId} />
        </Box>
      </PanelPadding>
    </Panel>
  );
};
