import * as React from 'react';
import styled from 'styled-components';
import { Text, Box, Card, Heading, Flex } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { PanelProps } from '@deepstream/ui-kit/elements/Panel';
import { SignUpLink } from './SignUpLink';

// Text that looks like a button
const ButtonText = styled(Text)`
  border-radius: 100px;
  border: 1px solid white;
  background-color: transparent;
  height: 36px;
  width: 90px;
`;

export const CallToActionPanel: React.FC<PanelProps> = ({ heading = '', children, ...props }) => {
  const { t } = useTranslation('onboarding');

  return (
    <Card variant="blue" p={20} {...props}>
      <Flex>
        <Box style={{ flex: 1 }}>
          <Heading fontFamily="primary" as="h2" fontSize={6} fontWeight={400} mb={2}>
            {heading}
          </Heading>
          {children}
        </Box>
        <Flex ml={20} alignItems="center">
          <SignUpLink data-test="sign-up">
            <ButtonText
              color="white"
              textAlign="center"
              fontSize={2}
              fontWeight={400}
              py={2}
              px={3}
            >
              {t('general.signUp')}
            </ButtonText>
          </SignUpLink>
        </Flex>
      </Flex>
    </Card>
  );
};
