import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, SxStyleProp } from 'rebass/styled-components';
import { QuestionExchangeDefinition } from '@deepstream/common/rfq-utils';

import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useModalState } from '../../../ui/useModalState';
import { ImportQuestionsModal } from '../ImportQuestions/ImportQuestionsModal/ImportQuestionsModal';
import { ImportQuestionsProvider } from '../ImportQuestions/ImportQuestionsProvider';
import { QuestionModal } from '../Questions/QuestionModal/QuestionModal';

export const QuestionsActions = ({
  onAddQuestions,
  initialExchangeDefIds,
  triggerSx,
}: {
  initialExchangeDefIds?: string[];
  onAddQuestions: (questionsExchangeDef: QuestionExchangeDefinition[]) => void;
  triggerSx?: SxStyleProp;
}) => {
  const importQuestionsModal = useModalState();
  const createQuestionModal = useModalState();

  const { t } = useTranslation('preQualification');

  const onAddQuestion = useCallback(
    questionExchangeDef => onAddQuestions([questionExchangeDef]),
    [onAddQuestions],
  );

  return (
    <>
      <Flex>
        <Button
          small
          type="button"
          iconLeft="plus"
          variant="primary"
          onClick={() => importQuestionsModal.open()}
          sx={triggerSx}
        >
          {t('importQuestions.modalTrigger')}
        </Button>
        <Button
          small
          type="button"
          iconLeft="pencil"
          ml={3}
          variant="primary"
          onClick={() => createQuestionModal.open()}
          sx={triggerSx}
        >
          {t('questionModal.createNewQuestion')}
        </Button>
      </Flex>

      {importQuestionsModal.isOpen && (
        // @ts-expect-error ts(2322) FIXME: Type 'string[] | undefined' is not assignable to type 'string[]'.
        <ImportQuestionsProvider initialExchangeDefIds={initialExchangeDefIds}>
          <ImportQuestionsModal
            close={importQuestionsModal.close}
            onAddQuestions={onAddQuestions}
          />
        </ImportQuestionsProvider>
      )}
      {createQuestionModal.isOpen && (
        <QuestionModal
          isCreatedFromQuestionnaireTemplate
          close={() => createQuestionModal.close()}
          onAddQuestion={onAddQuestion}
        />
      )}
    </>
  );
};
