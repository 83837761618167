import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import { IconProps, Icon } from '@deepstream/ui-kit/elements/icon/Icon';

export enum View {
  QUESTIONS = 'questions',
  LINE_ITEMS = 'line-items',
  EVALUATION = 'evaluation',
}

const selectViewItemData = [
  { value: View.EVALUATION, icon: 'balance-scale', translationKey: 'request.evaluation.evaluation' },
  { value: View.LINE_ITEMS, icon: 'list-ul', translationKey: 'request.lineItems.lineItem_other' },
  { value: View.QUESTIONS, icon: 'question', translationKey: 'request.question.question_other' },
] as const;

const useSelectViewItems = () => {
  const { t } = useTranslation();

  return useMemo(() => (
    selectViewItemData.map(({ value, icon, translationKey }) => ({
      value,
      icon,
      label: t(translationKey),
    }))
  ), [t]);
};

type SelectViewItem = {
  value: View;
  icon: IconProps['icon'];
  label: string;
};

const renderViewIconText = (
  item: SelectViewItem | null,
) => (
  item ? (
    <Flex fontSize={2} alignItems="center">
      <Icon icon={item.icon} color="text" fixedWidth mr={1} />
      <Text color="text">
        {item.label}
      </Text>
    </Flex>
  ) : (
    ''
  )
);

export const useSelectView = (initialIndex: number) => {
  const selectViewItems = useSelectViewItems();

  const [selectedItems, setSelectedItems] = useState([selectViewItems[initialIndex]]);

  return {
    itemToString: (item: SelectViewItem | null) => item ? item.value : '',
    renderItem: renderViewIconText,
    items: selectViewItems,
    selectedItems,
    onChange: setSelectedItems,
  };
};
