import { Flex, Box } from 'rebass/styled-components';
import { IconTextButton } from '@deepstream/ui-kit/elements/button/IconTextButton';
import { useTranslation } from 'react-i18next';
import { Section2, Subsection2 } from '../../modules/Request/Live/lotPagesLayout';
import { LineItemsExchangeSnapshot } from '../../types';
import { ActionNotificationSubject, useRfxActionNotificationSubject } from '../../ExchangeModal/useActionNotificationSubject';
import { ExchangeTimeline } from '../../ExchangeModal/ExchangeTimeline';
import * as rfx from '../../rfx';
import { useExchange } from '../../useExchange';
import { SendMessageForm } from '../../SendMessageForm';
import { getActionFilesAttachmentsLimit } from '../../ExchangeModal/exchangeReplyFormConfig';
import { usePossibleActions } from '../../modules/Exchange/usePossibleActions';
import { useSendExchangeReplyContext } from '../../ExchangeModal/useSendExchangeReply';
import { useIsChatWithAllowedAttachments } from '../../ExchangeModal/useChatCanHaveAttachments';

type Props = {
  setIsCollapsed: (isCollapsed: boolean) => void;
};

export const BulkSubmitActivityPanel = ({ setIsCollapsed }: Props) => {
  const { t } = useTranslation('translation');
  const pagePermissions = rfx.usePagePermissions();
  const { newFeaturesDisabled } = rfx.useStructure();
  const exchange = useExchange<LineItemsExchangeSnapshot>();
  const { canPostComment } = usePossibleActions(exchange);
  const [sendExchangeReply] = useSendExchangeReplyContext();
  const chatCanHaveAttachments = useIsChatWithAllowedAttachments();

  return (
    <Flex flexDirection="column" pt="20px" bg="lightGray3" height="100%">
      <Flex justifyContent="flex-start" alignItems="center" mb={3} px={3}>
        <IconTextButton
          fontSize={2}
          icon="chevron-right"
          color="primary"
          onClick={() => setIsCollapsed(true)}
          iconSx={{ position: 'static' }}
          iconPosition="right"
        >
          Hide sidebar
        </IconTextButton>
      </Flex>
      <Section2 heading="Activity and comments" headingProps={{ px: 3 }} overflowY="hidden" display="flex" flexDirection="column" flex={1}>
        <Subsection2 heading="Requirement" headingMargin="8px" mb={3} px={3}>
          {exchange?.def.description}
        </Subsection2>

        <Box overflowY="auto" flex={1}>
          <ActionNotificationSubject.Provider value={useRfxActionNotificationSubject}>
            <ExchangeTimeline
              canRespond={pagePermissions.canRespond}
              canRenderLatestResponseStageLink={!newFeaturesDisabled}
            />
          </ActionNotificationSubject.Provider>
        </Box>

        {canPostComment && (
          <Box px="12px" py="16px" bg="lightGray3" sx={{ borderTop: '1px solid #E2E8EF' }}>
            <SendMessageForm
              onSubmit={sendExchangeReply}
              placeholder={t('request.exchange.leaveAComment')}
              buttonProps={{ variant: 'primary-outline' }}
              showButtonOnFocus
              canHaveAttachments={chatCanHaveAttachments}
              actionFieldAttachmentsLimit={getActionFilesAttachmentsLimit(exchange)}
            />
          </Box>
        )}
      </Section2>
    </Flex>
  );
};
