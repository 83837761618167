import * as React from 'react';
import { noop } from 'lodash';
import { Flex, Text } from 'rebass/styled-components';
import { transparentize } from 'polished';
import { useDeviceSize } from '../../ui/useDeviceSize';

export const DashboardListItem = ({
  children,
  onClick = noop,
}: {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent) => void;
}) => {
  const { isExtraLarge } = useDeviceSize();

  return (
    <Flex
      role="link"
      px="20px"
      py="11px"
      sx={{
        lineHeight: 1.3,
        overflow: 'hidden',
        cursor: 'pointer',
        height: '58px',
        borderBottom: 'lightGray',
        '&:hover': {
          backgroundColor: (theme) => transparentize(0.95, theme.colors.primary),
        },
        '&:last-child': {
          borderBottom: !isExtraLarge ? 0 : 'lightGray',
        },
        '&:nth-child(5)': {
          borderBottom: 0,
        },
      }}
      onClick={onClick}
      alignItems="flex-start"
    >
      {children}
    </Flex>
  );
};
