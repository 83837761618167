import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { clone, compact, filter, find, findLast, get, isEmpty, isFinite, isFunction, isNil, noop, reject, sumBy, update } from 'lodash';
import { Box, Flex, Text } from 'rebass/styled-components';
import {
  ActionType,
  Company,
  createFormatQuestionResponse,
  EvaluationCriterionExchangeDefinition,
  ExchangeDefinition,
  ExchangeStatus,
  ExchangeType,
  getAverageScore,
  getExchangeFieldValue,
  getScore,
  HirePeriodExchangeDefinition,
  InclusionOption,
  isBuyerReplyField,
  isDefinitionField,
  isFormulaField,
  isSupplierReplyField,
  User,
  getRequirementFromExchangeType,
  Requirement,
  Live,
  renderStageName,
  LineItemExchangeDefinition,
} from '@deepstream/common/rfq-utils';
import { documentExchangeTypes } from '@deepstream/common/exchangesConfig';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { withProps } from '@deepstream/ui-utils/withProps';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { DateFormat } from '@deepstream/utils';
import { ACTION_COLUMN_WIDTH, DEFAULT_EDITABLE_DATA_CELL_WIDTH, EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { ExpandOverlay, SimpleRowNumberCell } from '../../ui/ExchangeDefsGrid/nonValidationAwareValueCell';
import { useCurrentUser, useCurrentUserLocale } from '../../useCurrentUser';
import { useHooks } from '../../useHooks';
import { useContractData } from '../Contracts/contract';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { TruncateCell } from '../../TruncateCell';
import { LatestBuyerDocumentCell, LatestSupplierDocumentCell, LatestDocumentCell } from '../../LatestDocumentCell';
import { ExchangeSnapshot, RfxStructure } from '../../types';
import {
  ExchangeDefNumberCell,
  ExchangeModalCompanyCell,
  ObsoleteCell,
  PercentCell,
  TargetPriceWithTotalCell,
} from '../../draft/cell';
import {
  BuyerDeviationResponseStatusCell,
  ContractBuyerResponseStatusCell,
  ContractSupplierResponseStatusCell,
  EvaluationExchangeStatusCell,
  ExchangeStatusCell,
  SupplierDeviationResponseStatusCell,
} from '../../ExchangeStatusCell';
import { ExchangeCommentIndicatorCell, GridNumCommentsCell, NumCommentsCell, NumResponsesCell } from './ChatCell';
import { ExchangeTypeCell } from '../../ExchangeTypeCell';
import { nestCells } from '../../nestCells';
import { ValueOrDashCell, ValueOrDashCellWithTooltip } from '../../ValueOrDashCell';
import { NoResponseCell } from '../../NoResponseCell';
import { ContractTypeCell } from '../Contracts/contractDraftCell';
import { Datetime2Cell } from '../../DatetimeCell';
import { DocumentExchangeLabelCell } from '../../DocumentExchangeLabelCell';
import { BulletinCell } from '../RequestMessages/BulletinCell';
import {
  getContractExchangeSnapshotStatus,
  getEvaluationExchangeSnapshotStatus,
  getExchangeSnapshotStatus,
  getTeamMemberEvaluationExchangeSnapshotStatus,
} from '../../exchangeStatus';
import { PreWrapCell, PreWrapCellLarge } from '../../PreWrapCell';
import { EvaluationCriterionDescriptionCell } from '../../EvaluationCriterionDescriptionCell';
import {
  AverageExchangeScoreCell,
  CollaborativeExchangeScoreCell,
  EvaluatorScoresCell,
  IndividualExchangeScoreCell,
} from '../../ExchangeScoreCell';
import { IndividualExchangeScoreDescription, IndividualExchangeScoreHeader } from '../../draft/header';
import { ExchangeSectionCell } from '../../ExchangeSectionCell';
import { TextCell } from '../../TextCell';
import { CurrencyAmount, CurrencyAmountCell } from '../../ui/Currency';
import { and, GridLockedCell, isNotRole, isRole, LockStateCell } from '../../lock';
import { useLineItemExchangeModalColumns } from './useLineItemExchangeModalColumns';
import { useRecipientId } from '../../useRfq';
import { withLock } from './withLock';
import { QuestionDocumentCell } from '../../QuestionDocumentCell';
import { QuestionResponseCellWithUpcomingExpiry } from '../../QuestionResponseCellWithUpcomingExpiry';
import { VerifiedSignersCell } from '../Contracts/VerifiedSignersCell';
import { QuestionGridResponseCell } from '../../QuestionGridResponseCell';
import { SortableHeader } from '../../ui/ExchangeDefsGrid/header';
import { BulletinMessageCell } from '../../ui/ExchangeDefsGrid/BulletinMessageCell';
import { RfqAttachmentsCell } from '../RequestMessages/RfqAttachmentsCell';

export const useColumnById = () => {
  const { t } = useTranslation(['general', 'contracts', 'translation']);
  const { usePagePermissions, useCommentNotifications, useIsSuperUserOrOwner } = useHooks();
  const pagePermissions = usePagePermissions();
  const contract = useContractData({ required: false });
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const locale = useCurrentUserLocale();
  const currentUser = useCurrentUser();
  const isSuperUserOrOwner = useIsSuperUserOrOwner();

  return useMemo(
    () => ({
      averageScore: ({ width, textAlign }: { width?: number; textAlign?: string }) => ({
        id: 'averageScore',
        Header: t('request.evaluation.totalScorePoints', { ns: 'translation' }),
        accessor: getAverageScore,
        Cell: AverageExchangeScoreCell,
        width,
        disableSortBy: true,
        textAlign,
      }),

      bulletinReference: ({ bulletinId, width }: { bulletinId?: string; width?: number | string; }) => ({
        id: 'bulletin',
        Header: t('request.exchange.bulletinReference', { ns: 'translation' }),
        Cell: withProps(BulletinCell, { bulletinId }),
        width,
      }),

      category: ({ width, description }: { width?: number; description?: string } = {}) => ({
        id: 'category',
        Header: t('description'),
        description,
        accessor: 'def.category',
        sortType: 'caseInsensitive',
        Cell: TruncateCell,
        width,
      }),

      collaborativeScore: ({ width, textAlign }: { width?: number; textAlign?: string } = {}) => ({
        id: 'score',
        Header: t('request.evaluation.scorePoints', { ns: 'translation' }),
        accessor: getAverageScore,
        Cell: CollaborativeExchangeScoreCell,
        width,
        disableSortBy: true,
        textAlign,
      }),

      commentIndicator: {
        id: 'commentIndicator',
        Header: () => null,
        accessor: 'comments.length',
        Cell: ExchangeCommentIndicatorCell,
        width: 60,
        disableSortBy: true,
      },

      comments: {
        id: 'comments',
        Header: t('comment_other'),
        accessor: (snapshot: ExchangeSnapshot) => filter(snapshot.history, 'comment').length,
        Cell: withProps(NumCommentsCell, { useCommentNotifications }),
        width: 150,
      },

      commentsGrid: {
        id: 'comments',
        Header: (
          <>
            <Icon regular color="subtext" icon="comment" mr="6px" />
            {t('comment_other')}
          </>
        ),
        accessor: (exchange: ExchangeSnapshot) => filter(exchange.history, 'comment').length,
        Cell: withProps(GridNumCommentsCell, { useCommentNotifications }),
        width: 140,
      },

      contractResponseStatus: ({
        width,
        iconFontSize,
        section = 'supplier',
      }: {
        width?: number | string;
        iconFontSize?: number | string;
        section?: 'buyer' | 'supplier';
      } = {}) => ({
        id: 'status',
        Header: t('response'),
        accessor: (exchange) => getContractExchangeSnapshotStatus(
          exchange,
          currentCompanyId,
          // @ts-expect-error ts(18047) FIXME: 'contract' is possibly 'null'.
          contract.exchangeDefById,
          currentUser._id,
          // @ts-expect-error ts(18047) FIXME: 'contract' is possibly 'null'.
          contract.status,
          isSuperUserOrOwner,
        ),
        Cell: withProps(section === 'supplier' ? ContractSupplierResponseStatusCell : ContractBuyerResponseStatusCell, { iconFontSize }),
        width,
      }),

      contractStatus: ({ width, iconFontSize }: { width?: number | string; iconFontSize?: number | string } = {}) => ({
        id: 'status',
        Header: t('status'),
        accessor: exchange => getContractExchangeSnapshotStatus(
          exchange,
          currentCompanyId,
          // @ts-expect-error ts(18047) FIXME: 'contract' is possibly 'null'.
          contract.exchangeDefById,
          currentUser._id,
          // @ts-expect-error ts(18047) FIXME: 'contract' is possibly 'null'.
          contract.status,
          isSuperUserOrOwner,
        ),
        Cell: withProps(ExchangeStatusCell, {
          iconFontSize,
          contract,
        }),
        width,
      }),

      contractType: {
        id: 'contractType',
        Header: t('type'),
        accessor: exchange => Boolean(exchange.def.isAddendum),
        Cell: ContractTypeCell,
        width: 150,
      },

      dateAdded: {
        id: 'dateAdded',
        Header: t('dateAdded', { ns: 'contracts' }),
        accessor: exchange => exchange.def.publishedAt,
        Cell: Datetime2Cell,
        width: 150,
        format: DateFormat.D_MMM_YYYY,
      },

      bulletinNumber: ({ Header }: { Header?: string } = {}) => ({
        id: 'bulletinNumber',
        Header: Header || t('number_short'),
        accessor: (exchange) => exchange.bulletinIndex + 1,
        Cell: withProps(TruncateCell, { sx: { py: 2 } }),
        width: 68,
        verticalAlign: 'top',
      }),

      defMessage: {
        id: 'message',
        Header: t('message'),
        accessor: 'def.message',
        sortType: 'caseInsensitive',
        Cell: withProps(BulletinMessageCell, { sx: { py: 2 } }),
        width: 420,
        verticalAlign: 'top',
      },

      defName: (Header) => ({
        id: 'name',
        Header: Header || t('name'),
        accessor: (exchange) => {
          if (exchange.def.source) {
            return t(`request.chats.exchangeSource.${exchange.def.source}`, { ns: 'translation' });
          } else {
            return exchange.def.name;
          }
        },
        Cell: TruncateCell,
      }),

      defPostedBy: {
        id: 'postedBy',
        Header: t('request.bulletin.postedBy', { ns: 'translation' }),
        accessor: 'def.user.name',
        sortType: 'caseInsensitive',
        Cell: withProps(TruncateCell, { sx: { py: 2 } }),
        width: 200,
        verticalAlign: 'top',
      },

      description: ({ label, description, fieldType } : { label?: string; description?: string; fieldType?: string } = {}) => ({
        id: 'description',
        fieldType,
        Header: label || t('description'),
        description,
        accessor: 'def.description',
        sortType: 'caseInsensitive',
        Cell: TruncateCell,
        width: 200,
      }),

      deviationStatus: ({
        width,
        iconFontSize,
        section = 'buyer',
      }: {
        width?: number | string;
        iconFontSize?: number | string;
        section?: 'buyer' | 'supplier';
      } = {}) => ({
        id: 'status',
        Header: t('request.exchange.deviationResponse', { ns: 'translation' }),
        accessor: exchange => getExchangeSnapshotStatus(exchange, pagePermissions),
        Cell: withProps(section === 'buyer' ? BuyerDeviationResponseStatusCell : SupplierDeviationResponseStatusCell, { iconFontSize }),
        width,
      }),

      evaluationCriterionDescription: (valueAccessor: (exchangeDef: ExchangeDefinition, fieldId: keyof ExchangeDefinition['fields']) => string) => ({
        id: 'evaluationCriterionDescription',
        Header: t('description'),
        // @ts-expect-error ts(2345) FIXME: Argument of type 'string' is not assignable to parameter of type 'never'.
        accessor: exchange => valueAccessor(exchange.def, 'description'),
        sortType: 'caseInsensitive',
        Cell: nestCells(
          PreWrapCell,
          EvaluationCriterionDescriptionCell,
        ),
      }),

      evaluationDescriptionGrid: (
        valueAccessor: (exchangeDef: ExchangeDefinition, fieldId: keyof ExchangeDefinition['fields']) => string,
      ) => ({
        id: 'evaluationDescription',
        Header: t('description'),
        // @ts-expect-error ts(2345) FIXME: Argument of type 'string' is not assignable to parameter of type 'never'.
        accessor: exchange => valueAccessor(exchange.def, 'description'),
        sortType: 'caseInsensitive',
        Cell: TruncateCell,
        width: 200,
      }),

      evaluationStatusGrid: (
        { companyId, user }: { companyId: string, user: User },
        { isEvaluator, pageHasAnyEvaluators, isTeamMember }: {
          isEvaluator: boolean;
          pageHasAnyEvaluators?: boolean;
          isTeamMember: boolean;
        },
        { iconFontSize }: { iconFontSize?: number | string } = {},
      ) => ({
        _id: 'status',
        Header: t('status'),
        accessor: isTeamMember
          ? getTeamMemberEvaluationExchangeSnapshotStatus({ companyId, user })
          // @ts-expect-error ts(2345) FIXME: Argument of type 'boolean | undefined' is not assignable to parameter of type 'boolean'.
          : getEvaluationExchangeSnapshotStatus({ companyId, user }, isEvaluator, pageHasAnyEvaluators),
        Cell: withProps(EvaluationExchangeStatusCell, { iconFontSize }),
        width: 165,
      }),

      evaluationStatus: (
        { companyId, user }: { companyId: string, user: User },
        {
          isEvaluator,
          pageHasAnyEvaluators,
          isTeamMember,
        }: {
          isEvaluator: boolean;
          pageHasAnyEvaluators?: boolean;
          isTeamMember: boolean;
        },
        { width, iconFontSize }: { width?: number | string; iconFontSize?: number | string } = {},
      ) => ({
        id: 'status',
        Header: t('status'),
        accessor: isTeamMember
          ? getTeamMemberEvaluationExchangeSnapshotStatus({
            companyId,
            user,
          })
          : getEvaluationExchangeSnapshotStatus({
            companyId,
            user,
          // @ts-expect-error ts(2345) FIXME: Argument of type 'boolean | undefined' is not assignable to parameter of type 'boolean'.
          }, isEvaluator, pageHasAnyEvaluators),
        Cell: withProps(EvaluationExchangeStatusCell, { iconFontSize }),
        width,
      }),

      evaluatorScores: {
        id: 'evaluatorScores',
        Header: t('request.evaluation.evaluatorScores', { ns: 'translation' }),
        Cell: EvaluatorScoresCell,
      },

      exchangeLabel: {
        id: 'exchangeLabel',
        Header: t('type'),
        accessor: 'label',
        Cell: DocumentExchangeLabelCell,
      },

      exchangeType: ({ Header, width, description } : { Header?: any; width?: number; description?: string } = {}) => ({
        id: 'exchangeType',
        Header: Header || t('type'),
        description,
        accessor: 'def.type',
        Cell: ExchangeTypeCell,
        width,
      }),

      feePrice: (vesselId) => ({
        id: 'price',
        Header: t('price'),
        accessor: `latestPriceByVesselId.${vesselId}`,
        Cell: CurrencyAmountCell,
        textAlign: 'right',
        width: 150,
      }),
      feeStatus: (vesselId) => ({
        id: 'feeStatus',
        Header: t('status'),
        accessor: (exchange) => {
          const {
            latestPriceByVesselId,
            status,
          } = exchange;

          return status === ExchangeStatus.OBSOLETE
            ? ExchangeStatus.OBSOLETE
            : isNil(latestPriceByVesselId[vesselId])
              ? ExchangeStatus.ACTION_REQUIRED
              : ExchangeStatus.COMPLETE;
        },
        Cell: ExchangeStatusCell,
        width: 175,
      }),
      feeType: {
        id: 'feeType',
        Header: t('type'),
        accessor: (exchange) => t(`request.vesselPricing.fees.feeType.${get(exchange, 'def.feeType')}`, { ns: 'translation' }),
        Cell: TruncateCell,
        width: 150,
      },

      hirePeriodStatus: (vesselId) => ({
        id: 'hirePeriodStatus',
        Header: t('status'),
        accessor: (exchange) => {
          const {
            latestPricesByVesselId,
            status,
          } = exchange;

          return status === ExchangeStatus.OBSOLETE
            ? ExchangeStatus.OBSOLETE
            : (
              !latestPricesByVesselId[vesselId]?.length ||
              latestPricesByVesselId[vesselId].some(isNil)
            ) ? ExchangeStatus.ACTION_REQUIRED
              : ExchangeStatus.COMPLETE;
        },
        Cell: ExchangeStatusCell,
        width: 175,
      }),

      included: (width?: number) => ({
        id: 'included',
        Header: t('request.vesselPricing.inclusions.includedInRates', { ns: 'translation' }),
        accessor: (exchange) => {
          switch (exchange.option) {
            case InclusionOption.EXCLUDED:
              return t('request.vesselPricing.inclusions.notIncluded', { ns: 'translation' });
            case InclusionOption.INCLUDED:
              return t('request.vesselPricing.inclusions.included', { ns: 'translation' });
            default:
              return null;
          }
        },
        sortType: 'caseInsensitive',
        Cell: ValueOrDashCell,
        EmptyCell: NoResponseCell,
        width,
      }),

      individualScore: (
        {
          companyId,
          user,
        }: { companyId: string, user: User },
        {
          width,
          iconFontSize,
          header,
        }: { width?: number; iconFontSize?: number, header?: string },
      ) => ({
        id: `score:${companyId}:${user._id}`,
        Header: header || IndividualExchangeScoreHeader,
        accessor: (exchange) => getScore(exchange, {
          companyId,
          userId: user._id,
        }),
        Cell: IndividualExchangeScoreCell,
        width,
        disableSortBy: true,
        user,
        companyId,
        iconFontSize,
      }),

      individualScoreGrid: ({ companyId, user }: { companyId: string, user: User }) => ({
        id: `score:${companyId}:${user._id}`,
        Header: t('request.evaluation.scorePoints', { ns: 'translation' }),
        description: (
          <IndividualExchangeScoreDescription user={user} />
        ),
        accessor: (exchange) => getScore(exchange, { companyId, userId: user._id }),
        Cell: withProps(IndividualExchangeScoreCell, {
          column: {
            user,
            companyId,
            iconFontSize: 2,
            showTooltip: true,
            truncate: true,
          },
        }),
        width: 180,
        disableSortBy: true,
        textAlign: 'right',
      }),

      intervalDayRates: (vesselId) => ({
        id: 'dayRates',
        Header: t('request.vesselPricing.hirePeriods.dayRate', { count: 1, ns: 'translation' }),
        accessor: exchange => {
          const rates = exchange.latestPricesByVesselId[vesselId];

          return rates?.length
            ? rates
            : Array.from({ length: exchange.def.quantity }, () => null);
        },
        Cell: ({ cell: { value } }) => (
          <Stack gap={1}>
            {value.map((rate, index) => (
              <Flex key={index} justifyContent="space-between">
                <Text>{index + 1}.</Text>
                <Text><CurrencyAmount value={rate} /></Text>
              </Flex>
            ))}
          </Stack>
        ),
        textAlign: 'right',
        width: 150,
      }),

      intervalPeriodWithQuantity: () => ({
        id: 'intervalPeriod',
        Header: t('request.vesselPricing.hirePeriods.period', { ns: 'translation' }),
        accessor: (exchange: ExchangeSnapshot) => {
          const {
            quantity,
            amount,
            unit,
          } = exchange.def as HirePeriodExchangeDefinition;
          return `${quantity} × ${t(`${unit}Count`, {
            count: amount,
            ns: 'general',
          })}`;
        },
        Cell: TruncateCell,
      }),

      intervalType: {
        id: 'intervalType',
        Header: t('type'),
        accessor: (exchange) => t(`request.vesselPricing.intervalType.${get(exchange, 'def.intervalType')}`, { ns: 'translation' }),
        Cell: TruncateCell,
      },

      latestBuyerComment: (Header, type?: string) => ({
        id: 'latestBuyerComment',
        Header: Header || t('latestComment'),
        accessor: (exchange: ExchangeSnapshot) =>
          findLast(
            exchange.history,
            item => (
              item.comment && (!type || type === item.type) && item.companyId === (exchange.def.publisherId || exchange.def.creatorId)
            ),
          )?.comment,
        Cell: nestCells(PreWrapCell, ValueOrDashCell),
      }),

      latestBuyerDocument: ({
        Header = t('document', { ns: 'general' }),
        width,
        truncate,
      }: { Header?: string; width?: number | string; truncate?: boolean } = {}) => ({
        id: 'latestBuyerDocument',
        Header,
        accessor: 'latestBuyerAttachment.name',
        Cell: withProps(LatestBuyerDocumentCell, { truncate }),
        width,
        sortType: 'caseInsensitive',
      }),

      latestComment: (Header, type?: string) => ({
        id: 'latestComment',
        Header: Header || t('latestComment'),
        accessor: (exchange: ExchangeSnapshot) =>
          findLast(exchange.history, (item) => item.comment && (!type || type === item.type))?.comment,
        Cell: nestCells(PreWrapCell, ValueOrDashCell),
      }),

      latestDocument: ({
        width,
        truncate,
        header,
      }: {
        width?: number | string;
        truncate?: boolean;
        header?: string;
      } = {}) => ({
        id: 'latestDocument',
        Header: header || t('document', { count: 1, ns: 'contracts' }),
        accessor: 'latestAttachment.name',
        Cell: withProps(LatestDocumentCell, { truncate }),
        width,
        sortType: 'caseInsensitive',
      }),

      latestSupplierComment: (Header, type?: string) => ({
        id: 'latestSupplierComment',
        Header: Header || t('latestComment'),
        accessor: (exchange: ExchangeSnapshot) => {
          const recipientId = find(exchange.companies, { group: 'recipient' })?._id;
          return findLast(exchange.history, (item) =>
            item.comment && (!type || type === item.type) && item.companyId === recipientId)?.comment;
        },
        Cell: nestCells(PreWrapCell, ValueOrDashCell),
      }),

      latestSupplierDocument: ({
        Header = t('document', { ns: 'general' }),
        width,
        truncate,
      }: { Header?: string; width?: number | string; truncate?: boolean } = {}) => ({
        id: 'latestSupplierDocument',
        Header,
        accessor: 'latestSupplierAttachment.name',
        Cell: withProps(LatestSupplierDocumentCell, { truncate }),
        width,
        sortType: 'caseInsensitive',
      }),

      gridRowNumberWithHoverState: (rowNumberPrefix?: string) => ({
        id: 'rowNumber',
        accessorKey: 'rowNumber',
        Header: '',
        Cell: withProps(SimpleRowNumberCell, { HoverOverlay: ExpandOverlay, rowNumberPrefix }),
        width: ACTION_COLUMN_WIDTH,
        disableSortBy: true,
      }),

      questionDocument: {
        id: 'questionDocument',
        Header: t('general.document', { ns: 'translation' }),
        accessor: 'latestResponse.value.attachments[0].name',
        Cell: QuestionDocumentCell,
        sortType: 'caseInsensitive',
      },

      questionYesNoDocument: {
        id: 'questionYesNoDocument',
        Header: t('request.question.yesNo.supportingDocument', { ns: 'translation' }),
        accessor: 'latestResponse.value.attachments[0].name',
        Cell: QuestionDocumentCell,
        sortType: 'caseInsensitive',
      },

      questionMoreInformation: {
        id: 'questionMoreInformation',
        Header: t('request.question.yesNo.supportingInformation', { ns: 'translation' }),
        accessor: 'latestResponse.value.moreInformation',
        Cell: PreWrapCell,
        sortType: 'caseInsensitive',
      },

      questionExpiryDate: {
        id: 'questionExpiryDate',
        Header: t('request.question.document.expiryDate', { ns: 'translation' }),
        accessor: 'latestResponse.value.expiryDate',
        Cell: Datetime2Cell,
        format: DateFormat.DD_MMM_YYYY,
      },

      defAttachments: {
        id: 'attachments',
        Header: t('file', { count: 2 }),
        accessor: 'def.attachments',
        Cell: RfqAttachmentsCell,
      },

      name: {
        id: 'name',
        Header: t('name'),
        accessor: 'def.name',
        sortType: 'caseInsensitive',
        Cell: TruncateCell,
      },

      number: {
        id: 'number',
        Header: t('number'),
        accessor: (_, index) => index + 1,
        Cell: TruncateCell,
        width: 100,
      },

      position: {
        id: 'position',
        Header: t('number_short'),
        accessor: (exchange, index) => index + 1,
        Cell: ExchangeDefNumberCell,
        width: 100,
      },

      question: {
        id: 'question',
        Header: t('request.question.question', { count: 1, ns: 'translation' }),
        description: t('buyer', { ns: 'general' }),
        accessor: 'def.description',
        sortType: 'caseInsensitive',
        Cell: TruncateCell,
        flex: 1,
      },

      recipientName: {
        id: 'recipientName',
        Header: t('supplier', { count: 1 }),
        accessor: (exchange: ExchangeSnapshot) => (
          find(exchange.companies, { group: 'recipient' })?.name
        ),
        sortType: 'caseInsensitive',
        Cell: TruncateCell,
      },

      recipientNameAndLogo: {
        id: 'recipientNameAndLogo',
        Header: t('supplier', { count: 1 }),
        accessor: (exchange: ExchangeSnapshot) => (
          find(exchange.companies, { group: 'recipient' })
        ),
        sortType: 'caseInsensitive',
        Cell: ExchangeModalCompanyCell,
      },

      exchangeStatus: ({ width, iconFontSize }: { width?: number | string; iconFontSize?: number | string; } = {}) => ({
        id: 'status',
        Header: t('status'),
        accessor: (exchange) => contract
          ? getContractExchangeSnapshotStatus(
            exchange,
            currentCompanyId,
            contract.exchangeDefById,
            currentUser._id,
            contract.status,
            isSuperUserOrOwner,
          )
          : getExchangeSnapshotStatus(exchange, pagePermissions),
        Cell: withProps(ExchangeStatusCell, { iconFontSize, contract }),
        width,
      }),

      requestStage: (structure: RfxStructure<Live>) => ({
        id: 'requestStage',
        Header: t('request.internalDocuments.requestStage', { ns: 'translation' }),
        accessor: (exchange) => {
          const stageId = exchange.def.stages[0];

          const stageIndex = structure.stages.findIndex(stage => stage._id === stageId);

          return isFinite(stageIndex) ? stageIndex + 1 : null;
        },
        Cell: TruncateCell,
      }),

      requestStageWithName: (structure: RfxStructure<Live>) => ({
        id: 'requestStage',
        Header: t('request.internalDocuments.requestStage', { ns: 'translation' }),
        accessor: (exchange) => {
          const stageId = exchange.def.stages[0];

          const stageIndex = structure.stages.findIndex(stage => stage._id === stageId);
          const stage = structure.stages[stageIndex];

          return renderStageName(stage, t, stageIndex);
        },
        Cell: TruncateCell,
      }),

      response: (props: {
        isMultiLine: boolean;
        width?: number,
        hideExpiryDate?: boolean;
        hideMoreInformation?: boolean;
        showDocumentNearExpiry?: boolean;
        showGridPlaceholder?: boolean;
        isExpandedView?: boolean;
        setIsExpandedView?: (isExpandedView: boolean) => void;
      }) => ({
        id: 'response',
        Header: t('request.question.response', { count: 1, ns: 'translation' }),
        description: t('supplier', { count: 1 }),
        accessor: (exchange: ExchangeSnapshot) => {
          const { isMultiLine, hideExpiryDate, hideMoreInformation } = props;
          const {
            latestResponse,
            def: exchangeDef,
          } = exchange;

          const formatQuestionResponse = createFormatQuestionResponse({
            t,
            locale,
          });
          return formatQuestionResponse({
            response: latestResponse,
            exchangeDef,
            isMultiLine,
            hideExpiryDate,
            hideMoreInformation,
          });
        },
        sortType: 'caseInsensitive',
        Cell: props.isMultiLine ? (
          withProps(QuestionGridResponseCell, {
            showGridPlaceholder: props.showGridPlaceholder,
            NonGridCell: nestCells(PreWrapCell, ValueOrDashCell),
            isExpandedView: props.isExpandedView,
            setIsExpandedView: props.setIsExpandedView,
          })
        ) : props.showDocumentNearExpiry ? (
          withProps(QuestionGridResponseCell, {
            showGridPlaceholder: props.showGridPlaceholder,
            NonGridCell: nestCells(QuestionResponseCellWithUpcomingExpiry, ValueOrDashCellWithTooltip),
            isExpandedView: props.isExpandedView,
            setIsExpandedView: props.setIsExpandedView,
          })
        ) : (
          withProps(QuestionGridResponseCell, {
            showGridPlaceholder: props.showGridPlaceholder,
            NonGridCell: ValueOrDashCellWithTooltip,
            isExpandedView: props.isExpandedView,
            setIsExpandedView: props.setIsExpandedView,
          })
        ),
        EmptyCell: NoResponseCell,
        flex: 1,
        width: props.width,
      }),

      responseStatus: ({ width, iconFontSize }: { width?: number | string; iconFontSize?: number | string; } = {}) => ({
        id: 'status',
        Header: t('response'),
        accessor: (exchange) => getExchangeSnapshotStatus(exchange, pagePermissions),
        Cell: withProps(SupplierDeviationResponseStatusCell, { iconFontSize }),
        width,
      }),

      responses: {
        id: 'responses',
        Header: t('request.question.response_other', { ns: 'translation' }),
        accessor: (exchange: ExchangeSnapshot) => {
          const numComments = filter(exchange.history, 'comment').length;
          const numBulletinReferences = filter(exchange.history, { value: ActionType.REFER_TO_BULLETIN }).length;

          return numComments + numBulletinReferences;
        },
        Cell: NumResponsesCell,
        width: 150,
      },

      section: {
        id: 'section',
        Header: t('section', { count: 1 }),
        accessor: null,
        Cell: ExchangeSectionCell,
        flex: 2,
      },

      separator: () => ({
        id: 'separator',
        accessor: '_id',
        Header: null,
        Cell: () => (
          <Box as="hr" bg="lightGray2" mt={2} mb={2} />
        ),
      }),

      requireMoreInformationResponse: {
        id: 'requireMoreInformationResponse',
        Header: t('response'),
        Cell: () => <>{t('questionnaire.moreInformationRequired', { ns: 'preQualification' })}</>,
      },

      staticRecipientNameAndLogo: (company: Company) => ({
        id: 'staticRecipientNameAndLogo',
        Header: t('supplier', { count: 1 }),
        accessor: () => company.company,
        sortType: 'caseInsensitive',
        Cell: ExchangeModalCompanyCell,
      }),

      subject: {
        id: 'subject',
        Header: t('subject'),
        accessor: 'name',
        Cell: TruncateCell,
      },

      targetPriceWithTotal: (width?: number) => ({
        id: 'targetPriceWithTotal',
        Header: t('request.lineItems.targetPrice', { ns: 'translation' }),
        accessor: (exchange) => getExchangeFieldValue(exchange, 'targetPrice'),
        Cell: TargetPriceWithTotalCell,
        textAlign: 'right',
        width,
      }),

      term: {
        id: 'term',
        Header: t('request.vesselPricing.terms.term', { count: 1, ns: 'translation' }),
        accessor: 'def.description',
        sortType: 'caseInsensitive',
        Cell: withProps(TextCell, { sx: { py: 2 } }),
        flex: 2,
      },

      weightPercent: (exchangeDefs: EvaluationCriterionExchangeDefinition[], width?: number) => ({
        id: 'weightPercent',
        Header: t('weight'),
        accessor: (exchange: ExchangeSnapshot) => {
          if (exchange.isObsolete) return NaN;

          const totalWeight = sumBy(
            reject(exchangeDefs, 'isObsolete'),
            'weight',
          );

          return (exchange.def as EvaluationCriterionExchangeDefinition).weight / totalWeight;
        },
        Cell: PercentCell,
        width,
        disableSortBy: true,
      }),

      verifiedSigners: ({ side }: { side: 'sender' | 'recipient' }) => ({
        id: 'verifiedSigners',
        Header: t('signature.signer_other', { ns: 'contracts' }),
        Cell: withProps(VerifiedSignersCell, { side }),
      }),

      configuration: ({ side }: { side: 'sender' | 'recipient' }) => ({
        id: 'configuration',
        Header: t('exchange.configuration', { ns: 'contracts' }),
        accessor: () => t(`exchange.configurationPlaceholder.${side}`, { ns: 'contracts' }),
        Cell: TruncateCell,
      }),
    }),
    [t, useCommentNotifications, pagePermissions, contract, currentCompanyId, locale, currentUser, isSuperUserOrOwner],
  );
};

const withLockState = (column) => update(
  clone(column),
  'Cell',
  Cell => nestCells(LockStateCell, Cell),
);

const withObsoleteStyles = (column) => update(
  clone(column),
  'Cell',
  Cell => nestCells(ObsoleteCell, Cell),
);

export const useExchangeColumns = () => {
  const column = useColumnById();
  const { t } = useTranslation(['general', 'contracts', 'translation']);

  return useMemo(() => {
    return {
      chatModal: ({ showRecipient = false }) =>
        compact([
          { ...column.defName(t('subject')), Cell: PreWrapCell },
          showRecipient ? { ...column.recipientName, Cell: PreWrapCell } : null,
          column.exchangeStatus({ iconFontSize: 2 }),
        ]),

      clarificationModal: (bulletins) =>
        bulletins.map(bulletin => column.bulletinReference({ bulletinId: bulletin.bulletinId })),

      internalDocumentModal: (structure: RfxStructure<Live>) => [
        column.requestStageWithName(structure),
        { ...column.name, Cell: PreWrapCell },
        column.latestDocument(),
      ],

      bulletinModal: ({ showAttachments }) =>
        compact([
          { ...column.defMessage, Cell: PreWrapCell },
          showAttachments && column.defAttachments,
        ]),

      collaborativeEvaluationCriterionModal: (
        { exchangeDefs }: { exchangeDefs: EvaluationCriterionExchangeDefinition[] },
        valueAccessor: (exchangeDef: ExchangeDefinition, fieldId: keyof ExchangeDefinition['fields']) => string,
        pageEvaluators: { companyId: string, user: User }[],
        { companyId, user }: { companyId: string; user: User },
        isEvaluator: boolean,
      ) => compact([
        column.evaluationCriterionDescription(valueAccessor),
        column.evaluationStatus(
          { companyId, user },
          { isEvaluator, isTeamMember: false, pageHasAnyEvaluators: !isEmpty(pageEvaluators) },
          { iconFontSize: 2 },
        ),
        column.section,
        column.weightPercent(exchangeDefs),
        column.collaborativeScore(),
      ]),

      ownerEvaluationCriterionModal: (
        { exchangeDefs }: { exchangeDefs: EvaluationCriterionExchangeDefinition[] },
        valueAccessor: (exchangeDef: ExchangeDefinition, fieldId: keyof ExchangeDefinition['fields']) => string,
        pageEvaluators: { companyId: string, user: User }[],
        { companyId, user }: { companyId: string; user: User },
        isEvaluator: boolean,
      ) => compact([
        column.evaluationCriterionDescription(valueAccessor),
        column.evaluationStatus(
          { companyId, user },
          { isEvaluator, isTeamMember: false, pageHasAnyEvaluators: !isEmpty(pageEvaluators) },
          { iconFontSize: 2 },
        ),
        column.section,
        column.weightPercent(exchangeDefs),
        column.averageScore({ width: 150 }),
        isEmpty(pageEvaluators) ? null : column.evaluatorScores,
      ]),

      teamMemberEvaluationCriterionModal: (
        { exchangeDefs }: { exchangeDefs: EvaluationCriterionExchangeDefinition[] },
        valueAccessor: (exchangeDef: ExchangeDefinition, fieldId: keyof ExchangeDefinition['fields']) => string,
        { companyId, user }: { companyId: string; user: User },
        isEvaluator: boolean,
      ) => compact([
        column.evaluationCriterionDescription(valueAccessor),
        column.evaluationStatus(
          { companyId, user },
          { isEvaluator, isTeamMember: true },
          { iconFontSize: 2 },
        ),
        column.section,
        column.weightPercent(exchangeDefs),
        column.individualScore({ companyId, user }, { width: 150, iconFontSize: 2, header: t('request.evaluation.scorePoints', { ns: 'translation' }) }),
      ]),

      documents: ({ isProvidedBySupplier }: { isProvidedBySupplier?: boolean } = {}) => [
        column.gridRowNumberWithHoverState(),
        {
          ...column.category({
            width: 380,
            description: isProvidedBySupplier ? t('supplier', { count: 1 }) : t('buyer', { ns: 'general' }),
          }),
        },
        column.exchangeStatus({ iconFontSize: 2, width: 165 }),
        withLockState(column.exchangeType({
          Header: t('request.documents.requirement', { count: 1, ns: 'translation' }),
          width: 230,
          description: isProvidedBySupplier ? t('supplier', { count: 1 }) : t('buyer', { ns: 'general' }),
        })),
        withLock(
          column.latestDocument({ width: 242, header: t('latestDocument', { ns: 'contracts' }) }),
          { when: and(isNotRole('submitter'), exchange => Boolean(exchange.latestAttachment?.isLocked)) },
        ),
        column.commentsGrid,
      ],

      documentModal: () => [
        { ...column.category(), Cell: PreWrapCell },
        withLockState(column.exchangeType({
          Header: t('request.documents.requirement', { count: 1, ns: 'translation' }),
        })),
        withLock(
          column.latestDocument({ truncate: false }),
          { when: and(isNotRole('submitter'), exchange => Boolean(exchange.latestAttachment?.isLocked)) },
        ),
      ],

      buyerSectionAcceptDocumentModal: () => [
        { ...column.category(), Cell: PreWrapCell },
        withLockState(column.exchangeType({
          Header: t('request.documents.requirement', { count: 1, ns: 'translation' }),
        })),
        column.latestBuyerDocument({ truncate: false }),
      ],

      buyerSectionUploadDocumentModal: () => [
        { ...column.category(), Cell: PreWrapCell },
        withLockState(column.exchangeType({
          Header: t('request.documents.requirement', { count: 1, ns: 'translation' }),
        })),
      ],

      buyerSectionAcceptCompleteDeviateDocumentModal: (
        { isResolved, isDeviationOngoing }: { isResolved: boolean; isDeviationOngoing: boolean },
      ) => compact([
        { ...column.category(), Cell: PreWrapCell },
        withLockState(column.exchangeType({
          Header: t('request.documents.requirement', { count: 1, ns: 'translation' }),
        })),
        column.latestBuyerDocument({ truncate: false }),
        isDeviationOngoing && column.deviationStatus({ iconFontSize: 2 }),
        !isResolved && column.latestBuyerComment(t('comment', { count: 1 })),
        isResolved && column.latestBuyerComment(t('resolutionComment'), 'resolve'),
      ]),

      supplierSectionAcceptDocumentModal: () => [
        column.responseStatus({ iconFontSize: 2 }),
        withLock(
          column.latestSupplierComment(t('comment', { count: 1 })),
          { when: isNotRole('submitter') },
        ),
      ],

      supplierSectionAcceptDeviateDocumentModal:
        ({ hasSupplierAttachment, isCompletedDocument }: { hasSupplierAttachment: boolean, isCompletedDocument: boolean }) => compact([
          column.responseStatus({ iconFontSize: 2 }),
          hasSupplierAttachment && withLock(
            column.latestSupplierDocument({ Header: t(isCompletedDocument ? 'request.exchange.completedDocument' : 'request.exchange.alternativeDocument', { ns: 'translation' }), truncate: false }),
            { when: and(isNotRole('submitter'), exchange => Boolean(exchange.latestSupplierAttachment?.isLocked)) },
          ),
          withLock(
            column.latestSupplierComment(t('comment', { count: 1 })),
            { when: isNotRole('submitter') },
          ),
        ]),

      supplierSectionCompleteDocumentModal: () => [
        column.responseStatus({ iconFontSize: 2 }),
        withLock(
          column.latestSupplierDocument({ Header: t('request.exchange.completedDocument', { ns: 'translation' }), truncate: false }),
          { when: and(isNotRole('submitter'), exchange => Boolean(exchange.latestSupplierAttachment?.isLocked)) },
        ),
        withLock(
          column.latestSupplierComment(t('comment', { count: 1 })),
          { when: isNotRole('submitter') },
        ),
      ],

      supplierSectionUploadDocumentModal: () => [
        column.responseStatus({ iconFontSize: 2 }),
        withLock(
          column.latestSupplierDocument({ Header: t('document', { ns: 'general' }), truncate: false }),
          { when: and(isNotRole('submitter'), exchange => Boolean(exchange.latestSupplierAttachment?.isLocked)) },
        ),
        withLock(
          column.latestSupplierComment(t('comment', { count: 1 })),
          { when: isNotRole('submitter') },
        ),
      ],

      internalDocument: (structure: RfxStructure<Live>) => [
        column.requestStage(structure),
        column.name,
        column.latestDocument(),
        column.comments,
      ],

      collaborativeEvaluationGrid: (
        exchangeDefs: EvaluationCriterionExchangeDefinition[],
        valueAccessor: (exchangeDef: ExchangeDefinition, fieldId: keyof ExchangeDefinition['fields']) => string,
        pageEvaluators: { companyId: string, user: User }[],
        { companyId, user }: { companyId: string; user: User },
        isEvaluator: boolean,
        rowNumberPrefix: string,
      ) => [
        column.gridRowNumberWithHoverState(rowNumberPrefix),
        column.evaluationDescriptionGrid(valueAccessor),
        column.evaluationStatus(
          { companyId, user },
          { isEvaluator, isTeamMember: false, pageHasAnyEvaluators: !isEmpty(pageEvaluators) },
          { iconFontSize: 2 },
        ),
        column.weightPercent(exchangeDefs, 80),
        column.collaborativeScore({ width: 180, textAlign: 'right' }),
        column.commentsGrid,
      ],

      ownerEvaluationGrid: (
        exchangeDefs: EvaluationCriterionExchangeDefinition[],
        valueAccessor: (exchangeDef: ExchangeDefinition, fieldId: keyof ExchangeDefinition['fields']) => string,
        pageEvaluators: { companyId: string, user: User }[],
        { companyId, user }: { companyId: string; user: User },
        isEvaluator: boolean,
        rowNumberPrefix: string,
      ) => [
        column.gridRowNumberWithHoverState(rowNumberPrefix),
        column.evaluationDescriptionGrid(valueAccessor),
        column.evaluationStatus(
          { companyId, user },
          { isEvaluator, isTeamMember: false, pageHasAnyEvaluators: !isEmpty(pageEvaluators) },
          { iconFontSize: 2 },
        ),
        column.weightPercent(exchangeDefs, 80),
        column.averageScore({ width: 180, textAlign: 'right' }),
        ...pageEvaluators.map(evaluator => column.individualScoreGrid(evaluator)),
        column.commentsGrid,
      ],

      teamMemberEvaluationGrid: (
        exchangeDefs: EvaluationCriterionExchangeDefinition[],
        valueAccessor: (exchangeDef: ExchangeDefinition, fieldId: keyof ExchangeDefinition['fields']) => string,
        { companyId, user }: { companyId: string, user: User },
        isEvaluator: boolean,
        rowNumberPrefix: string,
      ) => [
        column.gridRowNumberWithHoverState(rowNumberPrefix),
        column.evaluationDescriptionGrid(valueAccessor),
        column.evaluationStatus(
          { companyId, user },
          { isEvaluator, isTeamMember: true },
          { iconFontSize: 2 },
        ),
        column.weightPercent(exchangeDefs, 80),
        column.individualScoreGrid({ companyId, user }),
      ],

      fees: (vesselId) => [
        withLock(withLockState(column.description()), { when: and(isRole('evaluator'), isNotRole('initiator')) }),
        withLock(column.feeType, { when: and(isRole('evaluator'), isNotRole('initiator')) }),
        withLock(column.feePrice(vesselId), { when: isRole('evaluator') }),
        column.feeStatus(vesselId),
      ].map(withObsoleteStyles),

      hirePeriodIntervals: (vesselId) => [
        withLockState(column.intervalType),
        column.intervalPeriodWithQuantity(),
        withLock(column.intervalDayRates(vesselId), { when: isRole('evaluator') }),
        column.hirePeriodStatus(vesselId),
      ].map(withObsoleteStyles),

      inclusions: () => [
        withLock(withLockState(column.description()), { when: and(isRole('evaluator'), isNotRole('initiator')) }),
        withLock(column.included(), { when: and(isRole('evaluator'), isNotRole('initiator')) }),
        column.exchangeStatus({ width: 200 }),
        column.comments,
      ].map(withObsoleteStyles),

      inclusionModalDefinition: () => [
        { ...column.description(), Cell: PreWrapCell },
        withLockState(column.exchangeType()),
      ],

      inclusionModalReply: () => [
        withLock(column.included(), { when: and(isRole('evaluator'), isNotRole('initiator')) }),
      ],

      inclusionModalDefinitionReply: () => [
        { ...column.description(), Cell: PreWrapCell },
        withLockState(column.exchangeType()),
        withLock(column.included(), { when: and(isRole('evaluator'), isNotRole('initiator')) }),
      ],

      terms: () => [
        withLock(withLockState(column.term), { when: and(isRole('evaluator'), isNotRole('initiator')) }),
        column.exchangeStatus({ width: 200 }),
        column.comments,
      ].map(withObsoleteStyles),

      termModal: () => [
        { ...column.term, Cell: PreWrapCell },
        withLockState(column.exchangeType()),
      ],

      questions: ({
        onlyShowNumberAndDescription,
        showDocumentNearExpiry,
      }: {
        onlyShowNumberAndDescription?: boolean;
        showDocumentNearExpiry?: boolean;
      } = {}) => [
        column.gridRowNumberWithHoverState(),
        {
          ...withLockState(column.question),
        },
        column.exchangeStatus({ iconFontSize: 2, width: 165 }),
        ...(!onlyShowNumberAndDescription
          ? [
            withLock(
              column.response({ isMultiLine: false, width: 472, showDocumentNearExpiry, showGridPlaceholder: true }),
              { when: isRole('evaluator') },
            ),
            column.commentsGrid,
          ]
          : []
        ),
      ],

      questionModal: ({ moreInfoRequired }: { moreInfoRequired?: boolean } = {}) => [
        { ...column.question, Cell: PreWrapCell },
        ...(moreInfoRequired
            ? [
              column.requireMoreInformationResponse,
              column.latestBuyerComment(t('comment', { count: 1 }), ActionType.REQUIRE_MORE_INFO),
            ]
            : []
        ),
      ],

      questionReplyModal: ({
        showDocument = false,
        showExpiryDate = false,
        showMoreInformation = false,
        showYesNoDocument = false,
        isExpandedView = false,
        setIsExpandedView = noop,
      } = {}) => compact([
        withLock(
          // We don't want to include `expiryDate` and `moreInformation` in the `response` because we display it as a separate field below
          column.response({
            isMultiLine: true,
            hideExpiryDate: showExpiryDate,
            hideMoreInformation: showMoreInformation,
            isExpandedView,
            setIsExpandedView,
          }),
          { when: isRole('evaluator') },
        ),
        showMoreInformation && withLock(column.questionMoreInformation, { when: isRole('evaluator') }),
        showYesNoDocument && withLock(column.questionYesNoDocument, { when: isRole('evaluator') }),
        showDocument && withLock(column.questionDocument, { when: isRole('evaluator') }),
        showExpiryDate && withLock(column.questionExpiryDate, { when: isRole('evaluator') }),
        withLock(column.latestSupplierComment(t('comment', { count: 1 })), { when: isRole('evaluator'), lockEmptyValue: true }),
      ]),

      recipientSection: (recipient: Company) => [
        column.staticRecipientNameAndLogo(recipient),
      ],

      recipientAndStatusSection: ({ showRecipient = false }: { showRecipient?: boolean; }) => compact([
        showRecipient ? column.recipientNameAndLogo : null,
        column.exchangeStatus({ iconFontSize: 2 }),
      ]),

      contract: ({ showContractType }: { showContractType?: boolean }) => compact([
        showContractType ? column.dateAdded : null,
        showContractType ? column.contractType : null,
        column.description(),
        column.latestDocument(),
        column.contractStatus(),
        column.comments,
      ]).map(withObsoleteStyles),

      contractModal: ({ showContractType }: { showContractType?: boolean }) => compact([
        showContractType ? column.contractType : null,
        { ...column.description(), Cell: PreWrapCellLarge },
        column.contractStatus({ iconFontSize: 2 }),
        column.latestDocument({ truncate: false, header: t('exchange.currentDocument', { ns: 'contracts' }) }),
      ]),

      lineItemModalInternalSection: ({
        showTargetPrice = false,
      }: {
        showTargetPrice?: boolean;
      }) => compact([
        showTargetPrice ? { ...column.targetPriceWithTotal(), textAlign: 'left' } : null,
      ]),

      buyerSectionContractModal: ({
        isDeviationOngoing,
        showResponseStatus,
        showSigners,
        showConfiguration,
        hasBuyerAttachment,
        isRecipient,
      }: {
        isDeviationOngoing: boolean;
        showResponseStatus: boolean;
        showSigners: boolean;
        showConfiguration: boolean;
        hasBuyerAttachment: boolean;
        isRecipient: boolean;
      }) => compact([
        showResponseStatus && column.contractResponseStatus({ iconFontSize: 2, section: 'buyer' }),
        ...(showConfiguration
          ? [column.configuration({ side: isRecipient ? 'recipient' : 'sender' })]
          : compact([
            { ...column.description(), Cell: PreWrapCell },
            hasBuyerAttachment && column.latestBuyerDocument({ truncate: false }),
          ])
        ),
        isDeviationOngoing && column.deviationStatus({ iconFontSize: 2 }),
        showSigners && column.verifiedSigners({ side: 'sender' }),
      ]),

      supplierSectionContractModal: ({
        showResponseStatus,
        isDeviationOngoing,
        hasSupplierAttachment,
        showSigners,
      }: {
        showResponseStatus: boolean;
        isDeviationOngoing: boolean;
        hasSupplierAttachment: boolean;
        showSigners: boolean;
      }) => compact([
        showResponseStatus && column.contractResponseStatus({ iconFontSize: 2, section: 'supplier' }),
        hasSupplierAttachment && column.latestSupplierDocument({ Header: t('document', { count: 1, ns: 'contracts' }), truncate: false }),
        isDeviationOngoing && column.deviationStatus({ iconFontSize: 2, section: 'supplier' }),
        showSigners && column.verifiedSigners({ side: 'recipient' }),
      ]),

      finalSectionContractModal: () => [
        column.latestBuyerDocument({ Header: t('document', { count: 1, ns: 'contracts' }), truncate: false }),
      ],
    } as const;
  }, [column, t]);
};

export const useLineItemExchangeModalSectionColumns = (exchange: ExchangeSnapshot, panelSection: 'buyer' | 'supplier') => {
  const { useRecipients, useExchangeDefById } = useHooks();
  const exchangeDefById = useExchangeDefById();
  const column = useColumnById();
  const recipients = useRecipients();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const recipientId = useRecipientId({ required: false });
  const isRecipient = currentCompanyId === recipientId;

  const isBuyerAdded = !recipients.some(recipient => recipient._id === exchange.def.publisherId);
  const isSupplierAdded = !isBuyerAdded;

  const configurableColumns = useLineItemExchangeModalColumns({
    isRecipient,
    exchangeDef: exchange.def,
    orderedFieldIds: (exchangeDefById[exchange.def._id] as LineItemExchangeDefinition | undefined)?.orderedFieldIds,
    setEmptyCell: true,
    addLocks: true,
  });

  return useMemo(() => {
    const fields = exchange.def?.fields;

    if (exchange.def.type !== ExchangeType.LINE_ITEM || !fields) {
      return [];
    }

    const [descriptionColumn, ...otherColumns] = configurableColumns;

    if (panelSection === 'buyer') {
      if (isBuyerAdded) {
        return [
          descriptionColumn,
          column.exchangeType(),
          ...otherColumns.filter(column => {
            const field = fields[column._id];
            return isDefinitionField(field) || isBuyerReplyField(field);
          }),
        ];
      } else {
        return [
          ...otherColumns.filter(column => isBuyerReplyField(fields[column._id])),
        ];
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (isSupplierAdded) {
        return [
          descriptionColumn,
          column.exchangeType(),
          ...otherColumns.filter(column => {
            const field = fields[column._id];
            return isDefinitionField(field) || isSupplierReplyField(field) || isFormulaField(field);
          }),
        ];
      } else {
        return [
          ...otherColumns.filter(column => {
            const field = fields[column._id];
            return isSupplierReplyField(field) || isFormulaField(field);
          }),
        ];
      }
    }
  }, [column, configurableColumns, exchange.def?.fields, exchange.def.type, isBuyerAdded, isSupplierAdded, panelSection]);
};

export const useBuyerDocumentModalColumns = (exchange: ExchangeSnapshot) => {
  const { useRecipients, usePagePermissions } = useHooks();
  const exchangeColumns = useExchangeColumns();
  const recipients = useRecipients();
  const pagePermissions = usePagePermissions();
  const isBuyerAdded = !recipients.some(recipient => recipient._id === exchange.def.publisherId);
  const exchangeStatus = useMemo(
    () => getExchangeSnapshotStatus(exchange, pagePermissions),
    [exchange, pagePermissions],
  );

  if (!documentExchangeTypes.includes(exchange.def.type)) {
    return [];
  }

  const columns = [];
  const requirement = getRequirementFromExchangeType(exchange.def);
  // @ts-expect-error ts(2345) FIXME: Argument of type 'Requirement | undefined' is not assignable to parameter of type 'Requirement'.
  if ([Requirement.ACCEPT_OR_DEVIATE, Requirement.COMPLETE_OR_DEVIATE].includes(requirement)) {
    const isResolved = exchangeStatus === ExchangeStatus.RESOLVED;
    const lastSupplierActionType = findLast(
      exchange.history,
      ({ companyId, type }) => type !== ActionType.NONE && companyId === exchange.recipientId,
    )?.type as ActionType;
    const lastBuyerActionType = findLast(
      exchange.history,
      ({ companyId, type }) => type !== ActionType.NONE && companyId !== exchange.def.publisherId,
    )?.type as ActionType;
    const isDeviationOngoing = [lastSupplierActionType, lastBuyerActionType].includes(ActionType.DEVIATE);
    // @ts-expect-error ts(2345) FIXME: Argument of type 'any' is not assignable to parameter of type 'never'.
    columns.push(...exchangeColumns.buyerSectionAcceptCompleteDeviateDocumentModal({ isResolved, isDeviationOngoing }));
  } else if (requirement === Requirement.REQUEST) {
    // @ts-expect-error ts(2345) FIXME: Argument of type 'any' is not assignable to parameter of type 'never'.
    columns.push(...exchangeColumns.buyerSectionUploadDocumentModal());
  } else if (isBuyerAdded) {
    // @ts-expect-error ts(2345) FIXME: Argument of type 'any' is not assignable to parameter of type 'never'.
    columns.push(...exchangeColumns.buyerSectionAcceptDocumentModal());
  }

  return columns;
};

export const useSupplierDocumentModalColumns = (exchange: ExchangeSnapshot) => {
  const { useRecipients, usePagePermissions } = useHooks();
  const exchangeColumns = useExchangeColumns();
  const recipients = useRecipients();
  const pagePermissions = usePagePermissions();
  const isSupplierAdded = recipients.some(recipient => recipient._id === exchange.def.publisherId);
  const exchangeStatus = useMemo(
    () => getExchangeSnapshotStatus(exchange, pagePermissions),
    [exchange, pagePermissions],
  );

  if (!documentExchangeTypes.includes(exchange.def.type)) {
    return [];
  }

  const columns = [];
  const requirement = getRequirementFromExchangeType(exchange.def);
  // @ts-expect-error ts(2345) FIXME: Argument of type 'Requirement | undefined' is not assignable to parameter of type 'Requirement'.
  if ([Requirement.ACCEPT_OR_DEVIATE, Requirement.COMPLETE_OR_DEVIATE].includes(requirement)) {
    const lastSupplierActionType = findLast(
      exchange.history,
      ({ companyId, type }) => type !== ActionType.NONE && companyId === exchange.recipientId,
    )?.type as ActionType;
    const isCompletedDocument = exchangeStatus === ExchangeStatus.COMPLETE && lastSupplierActionType === ActionType.SUBMIT;
    // @ts-expect-error ts(2345) FIXME: Argument of type 'any' is not assignable to parameter of type 'never'.
    columns.push(...exchangeColumns.supplierSectionAcceptDeviateDocumentModal({
      hasSupplierAttachment: !!exchange.latestSupplierAttachment,
      isCompletedDocument,
    }));
  } else if (requirement === Requirement.COMPLETE) {
    // @ts-expect-error ts(2345) FIXME: Argument of type 'any' is not assignable to parameter of type 'never'.
    columns.push(...exchangeColumns.supplierSectionCompleteDocumentModal());
  } else if (requirement === Requirement.REQUEST) {
    // @ts-expect-error ts(2345) FIXME: Argument of type 'any' is not assignable to parameter of type 'never'.
    columns.push(...exchangeColumns.supplierSectionUploadDocumentModal());
  } else if (exchange.def.type !== ExchangeType.INFORMATION) {
    // @ts-expect-error ts(2345) FIXME: Argument of type 'any' is not assignable to parameter of type 'never'.
    columns.push(...exchangeColumns.supplierSectionAcceptDocumentModal());
  } else if (isSupplierAdded) {
    // @ts-expect-error ts(2345) FIXME: Argument of type 'any' is not assignable to parameter of type 'never'.
    columns.push(...exchangeColumns.documentModal());
  }

  return columns;
};

/**
 * Transforms column config created for our old table component to grid column config.
 */
export const useTransformedColumns = (columns, modelExchange): EditableGridColumn[] => {
  return useMemo(() => {
    return columns.map((column, index) => {
      const Cell = column.wrappedCell
        ? nestCells(GridLockedCell, column.wrappedCell)
        : column.Cell;

      return {
        _id: column.id,
        Header: withProps(SortableHeader, {
          info: modelExchange && column.headerInfoAccessor
            ? column.headerInfoAccessor(modelExchange)
            : null,
          showFieldTypeIcon: true,
        }),
        accessorKey: isFunction(column.accessor) ? undefined : column.accessor,
        accessorFn: isFunction(column.accessor) ? column.accessor : undefined,
        accessor: column.accessor,
        label: column.Header,
        description: column.description,
        width: index === 1
          ? 300
          : column.width || DEFAULT_EDITABLE_DATA_CELL_WIDTH,
        format: column.format,
        fieldType: column.fieldType,
        disableSortBy: column.disableSortBy,
        sortType: column.sortType,
        ValueCell: (props) => {
          const value = isFunction(column.accessor)
            ? column.accessor(props.row.original)
            : get(props.row.original, column.accessor);

          const textAlign = isFunction(column.textAlign)
            ? column.textAlign(props.row.original)
            : column.textAlign;

          const verticalAlign = isFunction(column.verticalAlign)
            ? column.verticalAlign(props.row.original)
            : column.verticalAlign;

          return (
            <Flex
              px="10px"
              sx={{
                width: '100%',
                height: '100%',
                opacity: props.row.original.isObsolete ? 0.4 : undefined,
              }}
              justifyContent={textAlign === 'right' ? 'flex-end' : undefined}
              alignItems={verticalAlign === 'top' ? 'flex-start' : 'center'}
            >
              <Cell
                cell={{ value }}
                row={{
                  ...props.row,
                  values: {
                    [column.id]: value,
                  },
                }}
                column={{
                  ...props.column,
                  textAlign,
                  id: column.id,
                  format: column.format,
                  EmptyCell: column.EmptyCell,
                  getIsLockableValue: column.getIsLockableValue,
                  lockEmptyValue: column.lockEmptyValue,
                  decimalPlaces: column.decimalPlaces,
                  showTooltip: true,
                  truncate: true,
                }}
              />
            </Flex>
          );
        },
      };
    });
  }, [columns, modelExchange]);
};
