import { useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { NoLineItemsReason } from '@deepstream/common/rfq-utils';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';

import { isEmpty, noop } from 'lodash';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import { StepNavigation } from '../../../../ui/MultiStepFlow/StepNavigation';
import { AwardFlowData } from '../types';
import { FormErrors } from '../../../../ui/MultiStepFlow/FormErrors';
import { CheckboxFieldArray } from '../../../../form/CheckboxField';
import { TextField } from '../../../../form/TextField';
import { Direction } from '../../../../ui/MultiStepFlow/types';

export const NoCalculatedTotalValueReasonsStep = ({
  data,
  submitAndNavigate,
}: {
  data: AwardFlowData,
  submitAndNavigate: (data: Partial<AwardFlowData> | null, direction: Direction) => void,
}) => {
  const { t } = useTranslation('translation');

  const noLineItemsReasonOptions = useMemo(() => {
    return Object.values(NoLineItemsReason).map(reason => ({
      value: reason,
      label: t(`request.spendAndSavings.noLineItemsReason.${reason}`),
    }));
  }, [t]);

  const validationSchema = useMemo(() => {
    const Schema = z.object({
      noLineItemsReasons: z.array(z.nativeEnum(NoLineItemsReason))
        .min(1, { message: t('request.awardFlow.errors.selectNoTotalCostReason') }),
      noLineItemsOtherReason: z.string().nullable().optional(),
    }).refine(
      // When `noLineItemsReasons` includes NoLineItemsReason.OTHER,
      // `noLineItemsOtherReason` must be truthy
      value => (
        !value.noLineItemsReasons.includes(NoLineItemsReason.OTHER) ||
        Boolean(value.noLineItemsOtherReason)
      ),
      {
        message: t('request.awardFlow.errors.enterOtherOptionValue'),
        path: ['noLineItemsOtherReason'],
      },
    );

    return toFormikValidationSchema(Schema);
  }, [t]);

  return (
    <Formik
      validateOnBlur
      initialValues={{
        ...data.spendAndSavings,
        noLineItemsReasons: data.spendAndSavings.noLineItemsReasons || [],
      }}
      validationSchema={validationSchema}
      onSubmit={noop}
    >
      {({ values, submitForm, validateForm, setFieldValue, dirty }) => (
        <Form>
          <lotPagesLayout.ContentWrapper>
            <StepNavigation
              onBackClick={() => {
                submitAndNavigate(dirty ? { spendAndSavings: values } : null, Direction.BACK);
              }}
              onContinueClick={async () => {
                const errors = await validateForm();

                await submitForm();

                if (isEmpty(errors)) {
                  submitAndNavigate(dirty ? { spendAndSavings: values } : null, Direction.FORWARD);
                }
              }}
            >
              <FormErrors />
              <lotPagesLayout.Section
                heading={t('request.awardFlow.steps.noCalculatedTotalCostReasons.heading')}
              >
                <Text mt="12px" mb="40px">
                  {t('request.awardFlow.steps.noCalculatedTotalCostReasons.info')}
                </Text>
                <lotPagesLayout.H3>
                  {t('request.spendAndSavings.noLineItemsReasons')}
                </lotPagesLayout.H3>
                <Text my={3}>
                  {t('request.spendAndSavings.noLineItemsReasonsDescription')}
                </Text>
                <CheckboxFieldArray
                  name="noLineItemsReasons"
                  hideLabel
                  flexDirection="column"
                  options={noLineItemsReasonOptions}
                  onChange={(event) => {
                    if (event.target.getAttribute('data-value') === NoLineItemsReason.OTHER && !event.target.checked) {
                      setFieldValue('noLineItemsOtherReason', undefined);
                    }
                  }}
                  errorMessageStyle={{ fontSize: '14px', marginTop: '16px' }}
                />
                {values.noLineItemsReasons?.includes(NoLineItemsReason.OTHER) && (
                  <Box mt={2}>
                    <TextField
                      name="noLineItemsOtherReason"
                      hideLabel
                      placeholder={t('request.spendAndSavings.pleaseSpecify')}
                      errorMessageStyle={{ fontSize: '14px', marginTop: '16px' }}
                    />
                  </Box>
                )}
              </lotPagesLayout.Section>
            </StepNavigation>
          </lotPagesLayout.ContentWrapper>
        </Form>
      )}
    </Formik>
  );
};
