import * as React from 'react';
import { Form, Formik, Field, FieldProps } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { SaveButton, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter } from '@deepstream/ui-kit/elements/popup/Modal';
import { StructuredTagPicker } from '../../ui/StructuredTagPicker';
import { useGetRegionTags } from '../../useRegionTags';

type EditModalProps =
  { onCancel: any; onSave: any; onSubmit: any; currentLocations: Array<string>; } &
  ModalProps;

export const LocationPickerModal: React.FC<EditModalProps> = ({ currentLocations, onCancel, onSave, onSubmit, ...props }) => {
  const { t } = useTranslation('supplierDiscovery');
  const getRegionTags = useGetRegionTags();

  return (
    <Modal
      onRequestClose={onCancel}
      style={{ content: { width: '810px' } }}
      {...props}
    >
      <Formik
        initialValues={{
          locations: currentLocations || [],
        }}
        validationSchema={
          yup.object().shape({
            locations: yup.array(),
          })
        }
        onSubmit={values => onSubmit(values)}
      >
        {({ isSubmitting, touched }) => (
          <Form>
            <ModalHeader onClose={onCancel}>{t('locationPicker.heading')}</ModalHeader>
            <ModalBody>
              <Field name="locations">
                {({ field, form }: FieldProps) => (
                  <StructuredTagPicker
                    tags={field.value}
                    getTags={getRegionTags}
                    onChange={tags => {
                      const tagIds = tags
                        .filter(tag => tag.level === 3)
                        .map(tag => tag._id);
                      form.setTouched({ [field.name]: true });
                      form.setFieldValue(field.name, tagIds);
                    }}
                    isCaseSensitive
                    searchLabel={t('locationPicker.searchLabel')}
                    filterLabel={t('locationPicker.filterLabel')}
                    allLevel1CategoryLabel={t('locationPicker.allLevel1CategoryLabel')}
                    placeholder={t('locationPicker.placeholder')}
                    noResultsText={t('locationPicker.noResultsText')}
                  />
                )}
              </Field>
            </ModalBody>
            <ModalFooter>
              <CancelButton mr={2} onClick={onCancel} />
              <SaveButton disabled={isSubmitting || !touched.locations} />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
