import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { noop, sortBy, capitalize } from 'lodash';
import * as yup from 'yup';
import { ExchangeType, Company } from '@deepstream/common/rfq-utils';
import { callAll } from '@deepstream/utils/callAll';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useRfqId } from '../../useRfq';
import { ModalForm } from '../../ModalForm';
import { useStartChatLikeExchange } from '../../useStartChatLikeExchange';
import { useToaster } from '../../toast';
import * as rfx from '../../rfx';
import { HiddenField } from '../../form/HiddenField';
import { SelectField } from '../../form/SelectField';
import { TextField } from '../../form/TextField';
import { CHANGE_EXCHANGE_MAX_ATTACHMENTS } from '../../ExchangeModal/exchangeReplyFormConfig';
import { FileField } from '../../form/FilesField';

const sortByName = (recipients: Company[]) =>
  sortBy(recipients, recipient => recipient.company.name.toLowerCase());

type NewClarificationModalProps = {
  sectionId: string;
  recipientId?: string;
  isOpen: boolean;
  onCancel: any;
  onError?: any;
  onSuccess?: any;
};

export const NewClarificationModal: React.FC<NewClarificationModalProps> = ({
  sectionId,
  recipientId,
  isOpen,
  onCancel,
  onSuccess = noop,
  onError = noop,
}) => {
  const { t } = useTranslation('translation');
  const recipients = rfx.useRecipients();
  const toaster = useToaster();
  const rfqId = useRfqId();
  const { settings } = rfx.useStructure();

  const [startClarificationExchange] = useStartChatLikeExchange({
    rfqId,
    recipientId,
    sectionId,
    onSuccess,
    onError: callAll(
      () => toaster.error(t('request.clarifications.newClarificationFailed')),
      onError,
    ),
  });

  const canUploadFiles = recipientId
    ? settings.allowClarificationAttachments
    : true;

  return (
    <ModalForm
      heading={t('request.clarifications.newClarification')}
      initialValues={{
        // If there's only 1 recipient, initialize the form with its id. There
        // is no need to make the user select from a list of with a single item.
        recipientId: !recipientId && recipients.length === 1
          ? recipients[0]._id
          : '',
        subject: '',
        message: '',
        attachments: [],
      }}
      validationSchema={
        yup.object().shape({
          recipientId: yup.string().required(t('general.required')),
          subject: yup.string().required(t('general.required')),
          message: yup.string().required(t('general.required')),
          attachments: yup.array(yup.object({ _id: yup.string().required() })).max(CHANGE_EXCHANGE_MAX_ATTACHMENTS),
        })
      }
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={values => startClarificationExchange({
        ...values,
        type: ExchangeType.CLARIFICATION,
      })}
      submitLabel={t('request.clarifications.openClarification')}
      style={{ content: { width: '500px' } }}
    >
      {recipientId ? (
        <HiddenField name="recipientId" value={recipientId} />
      ) : (
        <SelectField
          required
          name="recipientId"
          label={capitalize(t('general.to'))}
          placeholder={t('general.chooseASupplier')}
          items={sortByName(recipients).map(({ company }) => ({
            label: company.name,
            value: company._id,
          }))}
        />
      )}
      <TextField name="subject" label={t('general.subject')} required />
      <TextField name="message" label={t('general.message')} isMultiLine required />
      {canUploadFiles && <FileField name="attachments" label={t('attachment_other', { ns: 'general' })} purpose="rfq" max={CHANGE_EXCHANGE_MAX_ATTACHMENTS} />}
      <MessageBlock variant="info" mt={0}>
        {t('request.clarifications.openSeparateClarifications')}
      </MessageBlock>
    </ModalForm>
  );
};
