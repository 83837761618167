import * as React from 'react';
import * as yup from 'yup';
import { Form, Formik, useField, useFormikContext } from 'formik';
import { Box, Flex } from 'rebass/styled-components';
import { useMachine } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import { isEqual, noop } from 'lodash';
import { transparentize } from 'polished';
import { useQueryClient } from 'react-query';
import { IconValue } from '@deepstream/common/icons';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { ModalFooter } from '@deepstream/ui-kit/elements/popup/Modal';
import { StageApprovalExchangeSnapshot } from '../../types';
import { StageApprovalBaseModal } from './StageApprovalBaseModal';
import { useDraftRfqStructureQueryKey, useLiveRfqStructureQueryKey, useRfqId } from '../../useRfq';
import { StageApprovalResponseAction, StageApprovalResponseContext, stageApprovalResponseInitialContext, stageApprovalResponseMachine, StageApprovalResponseStates } from './submitResponseMachine';
import { useApi } from '../../api';
import { LabelConfig, LabelConfigProvider } from '../../LabelConfigProvider';
import { FieldContainer } from '../../form/FieldContainer';
import { StepFooter } from '../../StepFooter';
import { StageApprovalDetails } from './StageApprovalDetails';
import { useStageApprovalLabelStyle } from './useStageApprovalLabelStyle';
import { TextField } from '../../form/TextField';
import { useToaster } from '../../toast';
import { useMutation } from '../../useMutation';
import { ApprovalResponseDetails } from './ApprovalResponseDetails';
import { useIsFormDirty } from '../../pre-q/useIsFormDirty';
import { ErrorMessage } from '../../form/Field';
import { Approvers } from './Approvers';
import { useCurrentUserApproval } from './useCurrentUserApproval';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useTasks } from '../../TasksProvider';
import { useExchange } from '../../useExchange';
import { useStageWithIndex } from './useStageWithIndex';

const modalSteps = {
  stageApprovalViewDetails: 'stageApprovalViewDetails',
  stageAprovalEnterResponse: 'stageAprovalEnterResponse',
  stageApprovalReviewAndSubmit: 'stageApprovalReviewAndSubmit',
};

const ResponseApprovalMachineContext = React.createContext<{
  context: StageApprovalResponseContext;
  service: any;
}
>(null as any);

type StageApprovalResponseModalProps = {
  currentUserId: string;
  close: any;
  isOpen: boolean;
  props?: any;
};

const ViewDetails: React.FC = () => {
  const { t } = useTranslation();

  const { service, context } = React.useContext(ResponseApprovalMachineContext);
  const { approvalRequest, stage, stageIndex } = context;
  const { recipientIds, message, attachments } = approvalRequest.def;

  return (
    <Flex flexDirection="column" height="100%" justifyContent="space-between">
      <Box
        sx={{
          padding: '16px 16px 0 16px',
        }}
      >
        <StageApprovalDetails
          stage={stage}
          stageIndex={stageIndex}
          recipientIds={recipientIds}
          message={message}
          attachments={attachments}
        />
      </Box>
      <StepFooter
        steps={modalSteps}
        activeState={modalSteps.stageApprovalViewDetails}
        onContinue={() => service.send(StageApprovalResponseAction.CONTINUE)}
        primaryButtonText={t('general.next')}
        isBackVisible={false}
      />
    </Flex>
  );
};

type ApprovalResponseButtonProps = {
  color: string;
  text: string;
  icon: IconValue;
  iconColor: string;
  isIconRegular?: boolean;
  isSelected: boolean;
  buttonPosition: 'left' | 'right'
} & ButtonProps;

const ApprovalResponseButton: React.FC<ApprovalResponseButtonProps> = ({
  color,
  iconColor,
  icon,
  text,
  isIconRegular = false,
  buttonPosition,
  isSelected,
  sx,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Button
      small
      variant="secondary-outline"
      sx={{
        ...sx,
        ':hover:not(:disabled):not(:focus), :hover:not(:disabled):not(.disabled)':
          {
            color: 'initial',
            borderColor: theme.colors.secondary,
            backgroundColor: transparentize(0.95, color),
          },
        ...(isSelected && {
          color: 'initial !important',
          borderColor: `${color} !important`,
          backgroundColor: `${transparentize(0.85, color)} !important`,
        }),
        ...(buttonPosition === 'left'
          ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 }
          : { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }),
      }}
      {...props}
    >
      <IconText
        icon={icon}
        iconColor={iconColor}
        isIconRegular={isIconRegular}
        text={text}
      />
    </Button>
  );
};

const EnterDetailsForm: React.FC = () => {
  const { t } = useTranslation('translation');
  const theme = useTheme();

  const labelStyle = useStageApprovalLabelStyle();
  const { service, context } = React.useContext(ResponseApprovalMachineContext);
  const { currentUserId } = context;

  const isDirty = useIsFormDirty();
  React.useEffect(() => {
    service.send(StageApprovalResponseAction.SET_IS_DIRTY, { data: { isDirty } });
  }, [isDirty, service]);

  const { values, setValues } =
    useFormikContext<{ response?: string; message: string }>();
  const { response } = values;

  const [, meta] = useField({ name: 'response' });

  return (
    <Form
      style={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <LabelConfigProvider
        variant={LabelConfig.LEFT}
        width="180px"
        style={{
          approver: labelStyle,
          response: labelStyle,
          message: labelStyle,
        }}
      >
        <Flex
          flexDirection="column"
          sx={{
            rowGap: '16px',
            padding: '16px 16px 0 16px',
          }}
        >
          <FieldContainer
            name="approver"
            label={t('request.stageApprovals.approver', { count: 1 })}
          >
            <Flex>
              <Approvers approverIds={[currentUserId]} />
            </Flex>
          </FieldContainer>

          <FieldContainer
            name="response"
            label={t('response', { ns: 'general' })}
            showAsterisk
          >
            <Flex>
              <ApprovalResponseButton
                color={theme.colors.success}
                iconColor="success"
                icon="check-circle"
                text={t('request.stageApprovals.response.approved')}
                isIconRegular
                buttonPosition="left"
                onClick={(event) => {
                  event.preventDefault();
                  setValues({ ...values, response: 'approved' });
                }}
                sx={
                  response === 'approved'
                    ? {
                        marginRight: '0px',
                        zIndex: 2,
                      }
                    : {
                        marginRight: '-1px',
                        zIndex: 1,
                      }
                }
                isSelected={response === 'approved'}
              />
              <ApprovalResponseButton
                color={theme.colors.danger}
                iconColor="danger"
                icon="circle-xmark"
                text={t('request.stageApprovals.response.rejected')}
                isIconRegular
                buttonPosition="right"
                onClick={(event) => {
                  event.preventDefault();
                  setValues({ ...values, response: 'rejected' });
                }}
                sx={
                  response === 'approved'
                    ? {
                        marginLeft: '-1px',
                        zIndex: 1,
                      }
                    : {
                        marginLeft: '0px',
                        zIndex: 2,
                      }
                }
                isSelected={response === 'rejected'}
              />
            </Flex>
            {meta.touched && meta.error && (
              <Box sx={{ wordBreak: 'break-word', textAlign: 'left' }}>
                <ErrorMessage error={meta.error} fontWeight="normal" />
              </Box>
            )}
          </FieldContainer>

          <FieldContainer
            name="message"
            label={t('comment_other', { ns: 'general' })}
          >
            <TextField
              name="message"
              placeholder={t(
                'request.stageApprovals.submitApprovalMessagePlaceholder',
              )}
              isMultiLine
            />
          </FieldContainer>
        </Flex>
      </LabelConfigProvider>
      <StepFooter
        steps={modalSteps}
        activeState={modalSteps.stageAprovalEnterResponse}
        onContinue={noop}
        onBack={() => service.send(StageApprovalResponseAction.BACK)}
        primaryButtonText={t('general.next')}
        isBackVisible
      />
    </Form>
  );
};

const EnterDetails: React.FC = () => {
  const { t } = useTranslation();

  const { service, context } = React.useContext(ResponseApprovalMachineContext);
  const { response, message } = context;

  return (
    <Flex flexDirection="column" height="100%" justifyContent="space-between">
      <Formik
        validateOnBlur
        enableReinitialize
        initialValues={
          {
            response,
            message,
          }
        }
        validationSchema={yup.object().shape({
          response: yup.string().required(t('general.required')),
          message: yup.string(),
        })}
        onSubmit={(values) => {
          service.send(StageApprovalResponseAction.ADD_RESPONSE_DETAILS, {
            data: values,
          });
        }}
      >
        <EnterDetailsForm />
      </Formik>
    </Flex>
  );
};

type ReviewAndSubmitProps = {
  handleClose: () => void;
};

const ReviewAndSubmit: React.FC<ReviewAndSubmitProps> = ({ handleClose }) => {
  const { t } = useTranslation();
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const { removeTasks } = useTasks();

  const { service, context } = React.useContext(ResponseApprovalMachineContext);
  const { rfqId, currentUserId, response, message, approvalRequest } = context;
  const currentCompanyId = useCurrentCompanyId();
  const liveStructureQueryKey = useLiveRfqStructureQueryKey({
    rfqId,
    // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string | undefined'.
    currentCompanyId,
  });
  const draftStructureQueryKey = useDraftRfqStructureQueryKey({
    rfqId,
    // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string | undefined'.
    currentCompanyId,
  });
  const [submitStageApprovalResponse, { isLoading, isSuccess }] = useMutation(
    api.submitStageApprovalResponse,
    {
      onError: () => toaster.error(t('error.somethingWentWrong', { ns: 'supplierDiscovery' })),
      onSuccess: () => service.send(StageApprovalResponseAction.SUBMIT_SUCCESS),
      onSettled: () => {
        queryClient.invalidateQueries(liveStructureQueryKey);
        queryClient.invalidateQueries(['allExchanges', { rfqId, currentCompanyId }]);
        queryClient.invalidateQueries(['exchanges', { rfqId, currentCompanyId }]);
        queryClient.invalidateQueries(['statsByRecipientId', { rfqId, currentCompanyId }]);
        queryClient.invalidateQueries(draftStructureQueryKey);
        queryClient.invalidateQueries(['rfqApprovalExchanges', { rfqId, currentCompanyId }]);
        removeTasks({
          type: 'decideApproval',
          data: {
            rfqId,
            stageId: approvalRequest.def.stageId,
          },
        });
      },
    },
  );

  const { isLastPendingApproval } = useCurrentUserApproval(
    approvalRequest,
    currentUserId,
  );

  return (
    <Flex flexDirection="column" height="100%" justifyContent="space-between">
      <Flex
        flexDirection="column"
        sx={{
          rowGap: '16px',
          padding: '16px 16px 0 16px',
        }}
      >
        <MessageBlock
          variant={
            isSuccess ? 'success' : isLastPendingApproval ? 'warn' : 'info'
          }
          mt={0}
          px="20px"
          py="14px"
        >
          {isSuccess
            ? t('request.stageApprovals.responseSubmittedSuccessfully')
            : isLastPendingApproval
            ? t('request.stageApprovals.responsePreSubmitWarning')
            : t('request.stageApprovals.responsePreSubmitInfo')}
        </MessageBlock>
        <ApprovalResponseDetails
          // @ts-expect-error ts(2322) FIXME: Type '"pending" | "approved" | "rejected" | undefined' is not assignable to type '"pending" | "approved" | "rejected"'.
          approval={{ userId: currentUserId, status: response, message }}
        />
      </Flex>
      {!isLoading ? (
        isSuccess ? (
          <ModalFooter alignItems="flex-end">
            <Button variant="primary" onClick={() => handleClose()}>
              {t('close', { ns: 'general' })}
            </Button>
          </ModalFooter>
        ) : (
          <StepFooter
            steps={modalSteps}
            activeState={modalSteps.stageApprovalReviewAndSubmit}
            isBackVisible={true}
            onContinue={async () => {
              const { rfqId, message, response } = context;
              await submitStageApprovalResponse({
                rfqId,
                approvalRequestId: approvalRequest._id,
                // @ts-expect-error ts(18048) FIXME: 'response' is possibly 'undefined'.
                isApproved: response.toLowerCase() === 'approved',
                message,
                // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
                currentCompanyId,
              });
            }}
            onBack={() => {
              service.send(StageApprovalResponseAction.BACK);
            }}
            primaryButtonText={t('submit', { ns: 'general' })}
          />
        )
      ) : null}
    </Flex>
  );
};

export const SubmitResponseModal : React.FC<StageApprovalResponseModalProps> = ({
  currentUserId,
  close,
  isOpen,
  ...props
}) => {
  const { t } = useTranslation('translation');
  const rfqId = useRfqId({ required: true });

  const approvalRequest = useExchange() as StageApprovalExchangeSnapshot;
  const { stage, stageIndex } = useStageWithIndex(approvalRequest.def.stageId);

  const initialMachineContext = React.useMemo(() => {
    return {
      ...stageApprovalResponseInitialContext,
      rfqId,
      stage,
      stageIndex,
      currentUserId,
      approvalRequest,
    };
  }, [rfqId, stage, stageIndex, currentUserId, approvalRequest]);
  const [currentState, , service] = useMachine(
    stageApprovalResponseMachine.withContext(initialMachineContext),
  );

  const { context } = currentState;
  const needsCloseConfirmation = React.useCallback(() => {
    return !currentState.matches('submitSuccess') && (context.isDirty || !isEqual(context, initialMachineContext));
  }, [context, currentState, initialMachineContext]);

  const responseApprovalMachineContext = React.useMemo(() => {
    return { service, context };
  }, [service, context]);

  return (
    <StageApprovalBaseModal
      isOpen={isOpen}
      onClose={close}
      title={t('request.stageApprovals.submitApprovalResponse')}
      needsCloseConfirmation={needsCloseConfirmation}
      height="350px"
      {...props}
    >
      <ResponseApprovalMachineContext.Provider
        value={responseApprovalMachineContext}
      >
        {currentState.matches(StageApprovalResponseStates.viewDetails) ? (
          <ViewDetails />
        ) : currentState.matches(StageApprovalResponseStates.enterResponse) ? (
          <EnterDetails />
        // eslint-disable-next-line max-len
        ) : currentState.matches(StageApprovalResponseStates.reviewAndSubmit) || currentState.matches(StageApprovalResponseStates.submitSuccess) ? (
          <ReviewAndSubmit handleClose={close} />
        ) : null}
      </ResponseApprovalMachineContext.Provider>
    </StageApprovalBaseModal>
  );
};
