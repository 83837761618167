import { conforms } from 'lodash';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils';
import { useRfqId } from '../../useRfq';
import { useCurrentCompanyId } from '../../currentCompanyId';
import * as Layout from '../../Layout';
import { Notification } from '../Notifications/types';
import { useNotificationSubject } from '../Notifications/useNotificationSubject';
import { RequestPageHeader } from '../../page-headers/RequestPageHeader';
import { LiveTeam } from '../../LiveTeam';
import { teamManagementRoute } from '../../AppRouting';
import { useNavigate } from '../../tanstackRouter';

export const LiveTeamPageContent = () => {
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId();
  const navigate = useNavigate();

  const ref = useNotificationSubject({
    filter: conforms<Partial<Notification>>({
      domain: domain => domain === NotificationDomain.RFQ_SENT,
      action: action => [NotificationAction.NEW_MEMBER, NotificationAction.RFQ_MEMBER_ROLES_UPDATED].includes(action),
      to: to => to.companyId === currentCompanyId,
    }),
  });

  return (
    <Layout.HeaderAndContent
      header={
        <RequestPageHeader selectedTabId="team" rfqId={rfqId} />
      }
      content={
        <div ref={ref}>
          <LiveTeam
            rfqId={rfqId}
            navigateToCompanyTeam={() =>
              navigate({
                to: teamManagementRoute.to,
                // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
                params: { currentCompanyId },
              })
            }
          />
        </div>
      }
    />
  );
};
