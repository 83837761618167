import { useState, useMemo } from 'react';
import {
  CurrencyExchangeDefinition,
  DocumentExchangeDefinition,
  DocumentSection,
  Draft,
  ExchangeProvider,
  isSectionLinkedFromEvaluation,
} from '@deepstream/common/rfq-utils';
import { ExpandablePanelSubSection, PanelDivider, PanelPadding, PanelSubHeader } from '@deepstream/ui-kit/elements/Panel';
import { Button, ButtonProps, CancelButton, SaveButton } from '@deepstream/ui-kit/elements/button/Button';
import { EditableGridDataProvider, useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { Form, Formik, useField, useFormikContext } from 'formik';
import { find, first, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import * as yup from 'yup';
import { immutableSet } from '@deepstream/utils';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { ModelSizeLimitDialog, ModelSizeLimitMessages } from '../ModelSizeLimitDialog';
import { SelectFieldBase } from '../form/SelectField';
import { MappingSwitchField } from '../form/SwitchField';
import { getSizeRelevantExchangeDefCount, useModelSizeLimits } from '../modelSizeLimits';
import * as rfx from '../rfx';
import { useSystemFeatureFlags } from '../systemFeatureFlags';
import { useModalState } from '../ui/useModalState';
import { openFilePicker } from '../uploads/openFilePicker';
import { usePreventEnterKeyHandler } from '../usePreventEnterKeyHandler';
import { AddDocumentButton } from './AddDocumentButton';
import { DocumentsSectionConfigIndicators } from './DocumentsSectionConfigIndicators';
import { DraftPanel } from './DraftPanel';
import { DraftSectionEditPanelHeader } from './DraftSectionEditPanelHeader';
import { DraftSectionLabelConfigProvider } from './DraftSectionLabelConfigProvider';
import { LeavePageModal } from './LeavePageModal';
import { SectionConfigHeading } from './SectionConfigHeading';
import * as draft from './draft';
import { createEmptyDocument, createInformationDocument } from './exchangeDefs';
import { useStageName } from './useStageName';
import { useStageSelectItems } from './useStageSelectItems';
import { ExchangeDefFieldValueProvider } from '../ExchangeDefFieldValueContext';
import { DocumentExchangeDefsGrid, useDefaultStageIds } from '../ui/ExchangeDefsGrid/DocumentExchangeDefsGrid';
import { NO_LOT_PROXY_ID, useLotSelectItems } from './useLotSelectItems';
import { SectionConfigLotSelectField } from './SectionConfigLotSelectField';
import { useHandleGridLotChange } from './useHandleGridLotChange';

const CollapsedEditSectionConfig = ({ showSectionVisibility }: { showSectionVisibility: boolean }) => {
  const { settings } = rfx.useStructure<Draft>();
  const [{ value: providedBy }] = useField('section.providedBy');
  const [{ value: stageIds }] = useField({ name: 'section.stages' });
  const [{ value: exchangeDefs }] = useField({ name: 'exchangeDefs' });
  const [{ value: lotIds }] = useField<string[] | null>('section.lotIds');
  const stageId = stageIds?.length ? first<string>(stageIds) : first<string>(exchangeDefs[0]?.stages);
  const stageName = useStageName(stageId);

  const lotSelectItems = useLotSelectItems();
  const selectedLot = settings.areLotsEnabled
    ? find(lotSelectItems, { value: first(lotIds) || NO_LOT_PROXY_ID })
    : null;

  return (
    <DocumentsSectionConfigIndicators
      isSender
      // @ts-expect-error ts(2322) FIXME: Type 'string | number | null' is not assignable to type 'string | number | undefined'.
      stageName={showSectionVisibility ? stageName : null}
      providedBy={providedBy}
      // @ts-expect-error ts(2322) FIXME: Type 'LotSelectItem | null | undefined' is not assignable to type 'LotSelectItem | undefined'.
      selectedLot={selectedLot}
    />
  );
};

const ExpandedEditSectionConfig = ({ showSectionVisibility }: { showSectionVisibility: boolean }) => {
  const { t } = useTranslation('translation');
  const { settings } = rfx.useStructure<Draft>();
  const [{ value: stageIds }, , stagesFormik] = useField({ name: 'section.stages' });
  const [{ value: isSectionObsolete }] = useField<boolean | null>('section.isObsolete');
  const {
    updateEachRowWith,
    rowData: exchangeDefs,
  } = useEditableGridData<DocumentExchangeDefinition>();
  const stageSelectItems = useStageSelectItems();
  const stageId = stageIds?.length ? first<string>(stageIds) : first<string>(exchangeDefs[0]?.stages);
  const allStages = rfx.useStages();
  const section = rfx.useSection<DocumentSection>();
  const handleLotChange = useHandleGridLotChange();

  const showStageSelect = showSectionVisibility && stageSelectItems.length > 1;

  const setFirstVisibleStage = (stageId: string) => {
    const allStageIds = map(allStages, '_id');
    const indexFrom = allStageIds.indexOf(stageId);
    const newStages = allStageIds.slice(indexFrom);

    stagesFormik.setValue(newStages);

    updateEachRowWith(exchangeDef => ({ ...exchangeDef, stages: newStages }));
  };

  return (
    <DraftSectionLabelConfigProvider>
      {settings.areLotsEnabled && (
        <>
          <PanelPadding>
            <SectionConfigLotSelectField
              name="section.lotIds"
              canSelectObsoleteItem={section.isLive}
              onChange={handleLotChange}
            />
          </PanelPadding>
          {!isSectionObsolete && (
            <PanelDivider />
          )}
        </>
      )}
      {!isSectionObsolete && showStageSelect ? (
        <>
          <PanelPadding>
            <SelectFieldBase
              label={t('request.visibleFromStage')}
              description={t('request.visibleFromStageDescription')}
              items={stageSelectItems}
              value={stageId}
              disabled={section.isLive}
              onChange={setFirstVisibleStage}
              buttonStyle={{ fontWeight: 500, maxWidth: 200 }}
            />
          </PanelPadding>
          <PanelDivider />
        </>
      ) : (
        null
      )}
      {!isSectionObsolete && (
        <PanelPadding>
          <MappingSwitchField
            name="section.providedBy"
            label={t('request.documents.supplierAddedDocuments')}
            description={t('request.documents.supplierAddedDocumentsDescription')}
            disabled={section.liveVersion?.providedBy === ExchangeProvider.BOTH}
            mapFromField={(value) => value === ExchangeProvider.BOTH}
            mapToField={(checked) => checked
              ? ExchangeProvider.BOTH
              : ExchangeProvider.BUYER}
            checkedIcon={false}
            uncheckedIcon={false}
            width={42}
            checkedText={t('general.abstractFeatureEnabled')}
            uncheckedText={t('general.abstractFeatureDisabled')}
            useDefaultLabelConfig={false}
          />
        </PanelPadding>
      )}
    </DraftSectionLabelConfigProvider>
  );
};

const EditSectionConfig = ({ showSectionVisibility }: { showSectionVisibility: boolean }) => {
  return (
    <ExpandablePanelSubSection
      heading={<SectionConfigHeading />}
      renderCollapsedContent={() => <CollapsedEditSectionConfig showSectionVisibility={showSectionVisibility} />}
      renderExpandedContent={() => <ExpandedEditSectionConfig showSectionVisibility={showSectionVisibility} />}
    />
  );
};

const BulkUploadButton = (props: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button small variant="secondary-transparent-outline" iconLeft="upload" {...props}>
      {t('request.question.bulkUpload.btnLabel')}
    </Button>
  );
};

const DocumentSectionEditButtons = ({ fieldName = 'exchangeDefs' }: { fieldName?: string }) => {
  const isSender = rfx.useIsSender();
  const systemFeatureFlags = useSystemFeatureFlags();
  const defaultStageIds = useDefaultStageIds();

  const addExchangeDef = () => {
    const document = isSender
      ? createEmptyDocument(defaultStageIds)
      : createInformationDocument();

    appendRows([document]);
  };

  const {
    appendRows,
  } = useEditableGridData<Partial<DocumentExchangeDefinition>>();

  const handleImportClick = async () => {
    const files = await openFilePicker({ multiple: true });

    // HACK: Set each file on the corresponding document exchange def
    // so that they can be threaded through the table component into the
    // `initialFiles` props of each `FilesFieldCell` and immediately start
    // uploading. Probably the easiest way to do it but not very pretty.
    const exchangeDefs = Array
      .from(files)
      .map(file => ({
        ...(isSender
          ? createEmptyDocument(defaultStageIds)
          : createInformationDocument()
        ),
        file,
      }));

    appendRows(exchangeDefs);
  };

  return (
    <>
      {/*
       // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'. */}
      {systemFeatureFlags.bulkImportEnabled && (
        <BulkUploadButton
          type="button"
          onClick={handleImportClick}
          mr={2}
        />
      )}
      <AddDocumentButton
        type="button"
        onClick={() => addExchangeDef()}
      />
    </>
  );
};

const setDocumentExchangeDefFieldValue = (
  exchangeDef: DocumentExchangeDefinition,
  fieldId: string,
  value: any,
) => {
  return immutableSet(exchangeDef, fieldId, value);
};

const EditPanelFooter = () => {
  const {
    isSubmitting,
    isValid,
    dirty: isFormikDataDirty,
  } = useFormikContext();
  const { stopEditing } = rfx.useActions();
  const {
    isDirty: isGridDataDirty,
    editedCell,
  } = useEditableGridData<DocumentExchangeDefinition>();

  const isDirty = isGridDataDirty || isFormikDataDirty;
  const isEditingCell = Boolean(editedCell);

  return (
    <PanelPadding>
      <Flex justifyContent="flex-end">
        <CancelButton onClick={stopEditing} mr={2} />
        <SaveButton disabled={isEditingCell || isSubmitting || !isDirty || !isValid} />
      </Flex>
    </PanelPadding>
  );
};

export const DocumentsSectionEditPanelContent = () => {
  const { t } = useTranslation('translation');

  const { stopEditing } = rfx.useActions();
  const section = rfx.useSectionWithPosition<DocumentSection>();
  const { sectionById, exchangeDefById } = rfx.useStructure<Draft>();
  const initialExchangeDefs = rfx.useSectionExchangeDefs();
  const { showSectionVisibility, showExchangeDefVisibility } = rfx.useDocumentSectionVisibilitySettings(initialExchangeDefs);

  const {
    rowData: exchangeDefs,
  } = useEditableGridData<DocumentExchangeDefinition>();

  const [saveSection] = draft.useSaveDocumentsSection();
  const onKeyDown = usePreventEnterKeyHandler();
  const { maxExchangeDefCount } = useModelSizeLimits();
  const modelSizeLimitModal = useModalState();
  const [modelSizeLimitMessages, setModelSizeLimitMessages] = useState<ModelSizeLimitMessages | null>(null);

  const initialValues = useMemo(
    () => ({
      section,
      exchangeDefs: initialExchangeDefs,
    }),
    [initialExchangeDefs, section],
  );

  const handleSubmit = async ({ section }, { setSubmitting }) => {
    const totalExchangeDefCount = getSizeRelevantExchangeDefCount(exchangeDefById);

    const previousSectionExchangeDefCount = getSizeRelevantExchangeDefCount(initialExchangeDefs);
    const newSectionExchangeDefCount = getSizeRelevantExchangeDefCount(exchangeDefs);
    const addedCount = newSectionExchangeDefCount - previousSectionExchangeDefCount;
    const factor = isSectionLinkedFromEvaluation(section, sectionById) ? 2 : 1;

    if (
      newSectionExchangeDefCount > previousSectionExchangeDefCount &&
      totalExchangeDefCount + (addedCount * factor) > maxExchangeDefCount
    ) {
      setModelSizeLimitMessages({
        heading: t('request.dialog.requestSizeLimit.heading'),
        title: t('request.dialog.requestSizeLimit.saveDocumentsSection.title'),
        warning: t('request.dialog.requestSizeLimit.saveDocumentsSection.warning'),
        body: t('request.dialog.requestSizeLimit.saveDocumentsSection.body', {
          count: totalExchangeDefCount + (addedCount * factor) - maxExchangeDefCount,
        }),
      });
      modelSizeLimitModal.open();
      setSubmitting(false);
    } else {
      await saveSection({
        section,
        exchangeDefs,
      }, {
        onSuccess: stopEditing,
        onError: () => setSubmitting(false),
      });
    }
  };

  return (
    <>
      <Formik<{
        section: DocumentSection;
        currencyExchangeDef: CurrencyExchangeDefinition;
        exchangeDefs: DocumentExchangeDefinition[];
      }>
        validateOnBlur
        enableReinitialize
        initialValues={initialValues as any}
        validationSchema={yup.object().shape({
          section: yup.object().shape({
            name: yup.string(),
            description: yup.string(),
          }),
        })}
        onSubmit={handleSubmit}
      >
        {({ values }) => {
          const isSectionObsolete = values.section?.isObsolete;

          return (
            <Form style={{ width: '100%' }} onKeyDown={onKeyDown}>
              <DraftPanel panelId={section._id}>
                <DraftSectionEditPanelHeader icon="file-text-o" isSectionObsolete={isSectionObsolete} />
                <PanelDivider />
                {isSectionObsolete && (
                  <>
                    <MessageBlock variant="info" m="20px">
                      {t('request.sections.obsoleteSectionInfo')}
                    </MessageBlock>
                    <PanelDivider />
                  </>
                )}
                <EditSectionConfig
                  showSectionVisibility={showSectionVisibility}
                />
                <PanelDivider />
                {!isSectionObsolete && (
                  <>
                    <PanelSubHeader height="52px">
                      <DocumentSectionEditButtons />
                    </PanelSubHeader>
                    <PanelDivider />
                  </>
                )}
                <DocumentExchangeDefsGrid
                  showExchangeDefVisibility={showExchangeDefVisibility}
                  isReadOnly={isSectionObsolete}
                />
                <PanelDivider />
                <EditPanelFooter />
              </DraftPanel>
              <LeavePageModal />
            </Form>
          );
        }}
      </Formik>
      <ModelSizeLimitDialog
        modal={modelSizeLimitModal}
        messages={modelSizeLimitMessages}
      />
    </>
  );
};

export const DocumentsSectionEditPanel = () => {
  const senderDocumentExchangeDefs = rfx.useSectionExchangeDefsByCreator({
    group: 'sender',
  });

  const { isTemplate } = rfx.useState();
  const exchangeDefs = rfx.useSectionExchangeDefs();

  // template exchangeDefs don't have a creatorId so we can't use
  // `useSectionExchangeDefsByCreator`
  const documentExchangeDefs = isTemplate ? exchangeDefs : senderDocumentExchangeDefs;

  return (
    <ExchangeDefFieldValueProvider>
      <EditableGridDataProvider
        rowData={documentExchangeDefs}
        setValueInRow={setDocumentExchangeDefFieldValue}
      >
        <GridIdPrefixProvider>
          <GridMenuStateProvider>
            <DocumentsSectionEditPanelContent />
          </GridMenuStateProvider>
        </GridIdPrefixProvider>
      </EditableGridDataProvider>
    </ExchangeDefFieldValueProvider>
  );
};
