import { noop, uniqBy, concat, slice } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import {
  contractStatusConfig,
  ContractStatus,
} from '@deepstream/common/contract';
import { IconValue } from '@deepstream/common';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { useApi, wrap } from '../../api';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useNavHistory } from '../../hooks/useNavHistory';
import { LineClamp } from '../../LineClamp';

import { DashboardNoData } from './InfoBoxes';
import { DashboardListItem } from './DashboardListItem';
import { DashboardPanel } from './DashboardPanel';

const useFetchContracts = (ids?: string[]) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId();
  const contractsToShow = 5;

  const {
    data: recentlyVisitedContracts,
    isLoading: recentlyVisitedContractsLoading,
    isError: recentlyVisitedContractsError,
  } = useQuery(
    ['recentlyVisitedContracts', {
      currentCompanyId,
      filter: { ids },
      pageSize: contractsToShow,
    }],
    wrap(api.getUserContractsList),
    {
      enabled: !!ids?.length,
    },
  );

  const {
    data: recentlyCreatedContracts,
    isLoading: recentlyCreatedContractsLoading,
    isError: recentlyCreatedContractsError,
  } = useQuery(
    ['recentlyCreatedContracts', {
      currentCompanyId,
      pageSize: contractsToShow,
    }],
    wrap(api.getUserContractsList),
    {
      enabled: (ids?.length || 0) < contractsToShow,
    },
  );
  const contracts = uniqBy(
    concat([], recentlyVisitedContracts?.overviews || [], recentlyCreatedContracts?.overviews || []),
    (contract) => contract._id,
  );

  return {
    contracts: slice(contracts, 0, contractsToShow),
    isLoading: recentlyVisitedContractsLoading || recentlyCreatedContractsLoading,
    isError: recentlyVisitedContractsError || recentlyCreatedContractsError,
  };
};

const StatusIcon = ({
  status,
}: {
  status: ContractStatus
}) => {
  const { t } = useTranslation(['contracts']);
  const { icon } = contractStatusConfig[status] ?? {};

  return (
    <Tooltip content={t(`status.${status}`)}>
      <Icon
        mr={2}
        mt="4px"
        sx={{ fontSize: 1 }}
        fixedWidth
        icon={icon.value as IconValue}
        color={icon.color}
      />
    </Tooltip>
  );
};

export const RecentContracts = ({
  onItemClick,
  onGoToAllClick = noop,
}: {
  onItemClick: (data: { _id: string; status: ContractStatus }) => void;
  onGoToAllClick?: () => void;
}) => {
  const { t } = useTranslation(['dashboard']);
  const [getNavHistory] = useNavHistory();
  const visitedContractsIds = getNavHistory('contract');
  const { contracts, isLoading, isError } = useFetchContracts(visitedContractsIds);

  return (
    <DashboardPanel
      icon="file-contract"
      title={t('panels.recentContracts.heading')}
      isLoading={isLoading}
      isError={isError}
      goToAll={onGoToAllClick}
    >
      {contracts.length === 0 ? (
        <DashboardNoData message={t('panels.recentContracts.empty')} />
      ) : contracts.map((item) => (
        <DashboardListItem
          key={item._id}
          onClick={() => onItemClick(item)}
        >
          <StatusIcon status={item.status} />
          <LineClamp lines={2}>
            {item.name || t('panels.recentContracts.itemNoSubject')}
          </LineClamp>
        </DashboardListItem>
      ))}
    </DashboardPanel>
  );
};
