import { useMemo, useEffect, useCallback } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { canAddEvaluationForPage, getPagesInDisplayOrder, isLinkedEvaluationPage } from '@deepstream/common/rfq-utils';
import { isEmpty } from 'lodash';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { InlineButton } from '@deepstream/ui-kit/elements/button/InlineButton';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { TabList, Tab, TabPanel, Tabs, TabPanels } from '../../ui/Tabs';
import * as rfx from '../../rfx';
import { DraftEvaluationTab } from './DraftEvaluationTab';
import { DraftEvaluationSettingsTab } from './DraftEvaluationSettingsTab';
import { AddPageButton, buttonTabStyle } from '../../draft/AddPageButton';
import { useModalState } from '../../ui/useModalState';
import { AddEvaluationPageModal } from './AddEvaluationPageModal';
import { DisableEvaluationModal } from './DisableEvaluationModal';
import { getUuidV5 } from '../../utils';
import { useRequestEditNavigation } from '../../appNavigation';
import { useRfqId } from '../../useRfq';
import { useCurrentCompanyId } from '../../currentCompanyId';

type Props = {
  pageId?: string;
};

export const DraftEvaluationEnabled = ({ pageId }: Props) => {
  const { t } = useTranslation('evaluation');
  const theme = useTheme();
  const currentCompanyId = useCurrentCompanyId();
  const rfqId = useRfqId();
  const structure = rfx.useStructure();
  const addEvaluationPageModal = useModalState();
  const disableEvaluationModalState = useModalState();
  const { canManageEvaluation } = rfx.useRfxPermissions();
  const { editingPanelId } = rfx.useState();
  const isEditingAnyPanel = Boolean(editingPanelId);
  const { navigateToEvaluationPage, navigateToDetailsIndex } = useRequestEditNavigation();

  const {
    linkedEvaluationPages,
    tabIds,
    tabIdsHash,
    unlinkedRfxPages,
    canDisableEvaluation,
  } = useMemo(() => {
    const pagesInDisplayOrder = getPagesInDisplayOrder(structure.pages);

    const linkedEvaluationPages = pagesInDisplayOrder.filter(isLinkedEvaluationPage);

    const linkableRfxPages = pagesInDisplayOrder.filter(canAddEvaluationForPage);

    const unlinkedRfxPages = linkableRfxPages.filter(page =>
      !linkedEvaluationPages.some(evaluationPage => evaluationPage.linkedPageId === page._id),
    );

    const canDisableEvaluation = !linkedEvaluationPages.some(page => page.isLive);

    const tabIds = [
      ...(isEmpty(linkedEvaluationPages) ? ['no-pages'] : linkedEvaluationPages.map(page => page._id)),
      ...(canManageEvaluation && !isEmpty(unlinkedRfxPages) && !isEmpty(linkedEvaluationPages) ? ['add-page-button'] : []),
      'settings',
    ];

    return {
      linkedEvaluationPages,
      tabIds,
      tabIdsHash: getUuidV5(tabIds.join(':')),
      unlinkedRfxPages,
      canDisableEvaluation,
    };
  }, [canManageEvaluation, structure.pages]);

  const hasLinkedEvaluationPages = !isEmpty(linkedEvaluationPages);

  // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
  const selectedTabIndex = tabIds.indexOf(pageId);

  useEffect(
    () => {
      if (selectedTabIndex < 0) {
        navigateToEvaluationPage(tabIds[0]);
      }
    },
    [selectedTabIndex, tabIds, currentCompanyId, rfqId, navigateToEvaluationPage],
  );

  const onTabChange = useCallback(
    (index: number) => {
      const tabId = tabIds[index];

      if (tabId && tabId !== 'add-page-button') {
        navigateToEvaluationPage(tabId);
      }
    },
    [navigateToEvaluationPage, tabIds],
  );

  const onAddPageSuccess = useCallback(
    (pageId: string) => {
      navigateToEvaluationPage(pageId);
      addEvaluationPageModal.close();
    },
    [addEvaluationPageModal, navigateToEvaluationPage],
  );

  return (
    <>
      <rfx.EvaluationWeightsProvider>
        <Tabs
          key={tabIdsHash}
          index={selectedTabIndex}
          onChange={onTabChange}
          canOverflow
        >
          <Flex flexDirection="column">
            <Flex flexDirection="row" alignItems="flex-start" justifyContent="space-between" width="100%" mb={4} sx={{ gap: '8px' }}>
              <TabList
                style={{
                  backgroundColor: 'inherit',
                  fontSize: theme.fontSizes[2],
                }}
                scrollLeftBackground="linear-gradient(90deg, rgba(247,249,251,1) 0%, rgba(247,249,251,1) 60%, rgba(247,249,251,0.5) 80%, rgba(247,249,251,0) 100%)"
                scrollRightBackground="linear-gradient(90deg, rgba(247,249,251,0) 0%, rgba(247,249,251,0.5) 20%, rgba(247,249,251,1) 40%, rgba(247,249,251,1) 100%)"
              >
                {hasLinkedEvaluationPages ? (
                  linkedEvaluationPages.map(page => (
                    <Tab key={page._id} disabled={isEditingAnyPanel}>
                      <Truncate>
                        {structure.pageById[page.linkedPageId].name}
                      </Truncate>
                    </Tab>
                  ))
                ) : (
                  <Tab disabled={isEditingAnyPanel}>
                    <Text color="danger">
                      <Icon icon="exclamation-circle" mr={1} />
                      {t('enabled.noPages')}
                    </Text>
                  </Tab>
                )}
                {/* The add page button in the tabs is only shown when there is
                    at least one linked evaluation page; if there is none, we
                    show the 'no-pages' tab with an add-page button in the tab
                    content */}
                {canManageEvaluation && !isEmpty(unlinkedRfxPages) && !isEmpty(linkedEvaluationPages) ? (
                  <Tab disabled={isEditingAnyPanel} style={{ padding: 0, ...buttonTabStyle }}>
                    <AddPageButton
                      disabled={isEditingAnyPanel}
                      onClick={addEvaluationPageModal.open}
                    />
                  </Tab>
                ) : (
                  null
                )}
                <Box sx={{ marginLeft: 'auto' }}>
                  <Tab disabled={isEditingAnyPanel}>
                    <Icon icon="cog" mr={1} />
                    {t('enabled.settings')}
                  </Tab>
                </Box>
              </TabList>
            </Flex>
            <Flex>
              {selectedTabIndex > -1 ? (
                <TabPanels style={{ width: '100%' }}>
                  {hasLinkedEvaluationPages ? (
                    linkedEvaluationPages.map((page) => (
                      <TabPanel key={page._id}>
                        <rfx.PageProvider page={page}>
                          <DraftEvaluationTab />
                        </rfx.PageProvider>
                      </TabPanel>
                    ))
                  ) : (
                    <TabPanel>
                      {isEmpty(unlinkedRfxPages) ? (
                        <Text fontSize={14} mt={1}>
                          <Trans i18nKey="enabled.detailsRef" ns="evaluation">
                            {' '}
                            <InlineButton onClick={navigateToDetailsIndex} />
                            {' '}
                          </Trans>
                          <br />
                          {t('enabled.addSomePages')}
                        </Text>
                      ) : (
                        <>
                          <Text fontSize={14} mt={1}>
                            {t('enabled.youMustAddAtLeastOnePage')}
                            <br />
                            {t('enabled.canDisableEvaluation')}
                          </Text>
                          {canManageEvaluation && (
                            <Flex mt={3}>
                              <Button
                                iconLeft="plus"
                                onClick={addEvaluationPageModal.open}
                                disabled={isEditingAnyPanel}
                              >
                                {t('request.pages.addPage', { count: 1, ns: 'translation' })}
                              </Button>
                              {canDisableEvaluation && (
                                <Button
                                  ml={2}
                                  variant="secondary-outline"
                                  iconLeft="close"
                                  onClick={disableEvaluationModalState.open}
                                  disabled={isEditingAnyPanel}
                                >
                                  {t('settings.disableEvaluationPanel.disableButton')}
                                </Button>
                              )}
                            </Flex>
                          )}
                          <Text />
                        </>
                      )}
                    </TabPanel>
                  )}
                  {tabIds.includes('add-page-button') && (
                    <TabPanel />
                  )}
                  <TabPanel>
                    <DraftEvaluationSettingsTab />
                  </TabPanel>
                </TabPanels>
              ) : (
                null
              )}
            </Flex>
          </Flex>
        </Tabs>
      </rfx.EvaluationWeightsProvider>
      {addEvaluationPageModal.isOpen && (
        <AddEvaluationPageModal
          onCancel={addEvaluationPageModal.close}
          onSuccess={onAddPageSuccess}
        />
      )}
      <DisableEvaluationModal {...disableEvaluationModalState} />
    </>
  );
};
