import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { Button, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { ModalHeader, ModalBody, ModalFooter, ModalProps, Modal } from '@deepstream/ui-kit/elements/popup/Modal';
import { TextField } from '../../form/TextField';
import { FieldContainer } from '../../form/FieldContainer';
import { LabelConfig, LabelConfigProvider } from '../../LabelConfigProvider';

export const EditDescriptionModal = ({
  onClose,
  onSubmit,
  initialValue,
  ...props
}: ModalProps &
{
  onClose: any;
  onSubmit: (description: string | null) => void;
  initialValue?: string | null;
}) => {
  const { t } = useTranslation(['request', 'translation']);

  return (
    <Modal
      onRequestClose={onClose}
      style={{ content: { width: '600px' } }}
      {...props}
    >
      <Formik
        initialValues={{ fieldName: initialValue || '' }}
        validationSchema={
          yup.object().shape({
            fieldName: yup.string(),
          })
        }
        onSubmit={(values) => {
          onSubmit(values.fieldName || null);
        }}
      >
        {({ isValid, dirty }) => (
          <Form>
            <ModalHeader onClose={onClose}>
              {t('lineItems.genericLineItemConfig.modalHeadingEdit.description')}
            </ModalHeader>
            <ModalBody>
              <LabelConfigProvider
                variant={LabelConfig.LEFT}
                gap={4}
                width="200px"
              >
                <FieldContainer
                  name="fieldName"
                  label={t('lineItems.genericLineItemConfig.fieldName.label')}
                  description={t('lineItems.genericLineItemConfig.fieldName.description')}
                  sx={{ py: 3, px: '20px', width: '550px' }}
                >
                  <TextField
                    name="fieldName"
                    placeholder={t('general.description', { ns: 'translation' })}
                  />
                </FieldContainer>
              </LabelConfigProvider>
            </ModalBody>
            <ModalFooter justifyContent="flex-end">
              <CancelButton onClick={onClose} mr={2} />
              <Button
                disabled={!isValid || !dirty}
                variant="primary"
                type="submit"
              >
                {t('general.submit', { ns: 'translation' })}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
