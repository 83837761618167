import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { isEmpty, map, reduce, upperFirst } from 'lodash';
import { Text } from 'rebass/styled-components';
import { EXPIRY_DATE_MILESTONE, isMilestoneReminder, Reminder, ReminderRecipientCategory, START_DATE_MILESTONE } from '@deepstream/common/contract';
import { DateFormat, localeFormatDate } from '@deepstream/utils';
import { useContractData } from './contract';
import { NameArray } from '../../NameArray';
import { Bold } from '../../Bold';
import { useCurrentUserLocale } from '../../useCurrentUser';

export const ReminderDescriptionValue = ({ value }: { value: Reminder }) => (
  <ReminderDescription reminder={value} />
);

export const ReminderDescription = ({ reminder }: { reminder: Reminder }) => {
  const { t } = useTranslation(['contracts', 'general']);
  const locale = useCurrentUserLocale();
  const contract = useContractData();

  const userNames = useMemo(
    () => {
      const userIds = reminder.recipientUserIds ?? [];

      const userById = reduce(
        contract.teamById,
        (users, team) => ({
          ...users,
          ...team.users,
        }),
        {},
      );

      return map(
        userIds,
        userId => userById[userId].name,
      );
    },
    [reminder, contract],
  );

  const milestoneNameById = useMemo(
    () => ({
      [START_DATE_MILESTONE]: t('summary.startDate'),
      [EXPIRY_DATE_MILESTONE]: t('summary.expiryDate'),
      ...(reduce(
        contract.customMilestones,
        (nameById, milestone) => {
          nameById[milestone._id] = milestone.name;
          return nameById;
        },
        {},
      )),
    }),
    [contract, t],
  );

  const offsetKey = reminder.unit
    // Ugly: time units are plural (eg `days`) and translation key are singular (eg dayCount),
    // so we need to remove the `s` at the end
    ? `${reminder.unit.slice(0, -1)}Count`
    : undefined;

  const isCounterPartyIncluded = reminder.recipientCategories.includes(ReminderRecipientCategory.COUNTER_PARTY);

  return (
    <Text>
      {isCounterPartyIncluded ? (
        <>
          <Bold>{t('summary.allCounterPartyUsers')}</Bold>
          {reminder.recipientCategories.includes(ReminderRecipientCategory.INTERNAL) && (
            userNames.length === 0 ? (
              null
            ) : (
              <>
                {/* When there is more than 1 user name, the 'and' is rendered at
                the end of the NameArray */}
                {userNames.length === 1 ? ` ${t('and', { ns: 'general' })}` : ','}
                {' '}
                <Bold>
                  <NameArray entities={userNames} visibleEntities={2} />
                </Bold>
              </>
            )
          )}
        </>
      ) : (
        <Bold>
          {isEmpty(userNames) ? (
            t('summary.internalUsers')
          ) : (
            <NameArray entities={userNames} visibleEntities={2} />
          )}
        </Bold>
      )}
      {` ${isCounterPartyIncluded || userNames.length > 1 || isEmpty(userNames) ? (
        t('summary.willBeReminded_other')
      ) : (
        t('summary.willBeReminded', { count: 1 })
      )} `}
      {isMilestoneReminder(reminder) ? (
        <>
          <Bold>
            {offsetKey && (
              <>
                {t(offsetKey, { count: reminder.amount, ns: 'general' })}
                {' '}
              </>
            )}
          </Bold>
          <Trans
            i18nKey={`summary.milestoneOffsetDescription.${reminder.offsetType}`}
            ns="contracts"
            values={{ milestone: milestoneNameById[reminder.milestoneId] }}
            components={{ b: <b /> }}
          />
          .
        </>
      ) : (
        <>
          <Trans
            i18nKey="onTheDateAtTime"
            ns="general"
            values={{
              date: localeFormatDate(new Date(reminder.date), DateFormat.DD_MMM_YYYY, { locale }),
              time: localeFormatDate(new Date(reminder.date), DateFormat.H_MM_A_ZZZ, { locale }),
            }}
            components={{ b: <b /> }}
          />
          {'. '}
          {reminder.isRecurring && (
            <>
              <Trans
                i18nKey="summary.thisReminderWillRepeat"
                ns="contracts"
                components={{ b: <b /> }}
              />
              {' '}
              <Trans
                i18nKey={`summary.every${upperFirst(offsetKey)}`}
                ns="contracts"
                count={reminder.amount}
                components={{ b: <b /> }}
              />
              .
            </>
          )}
        </>
      )}
    </Text>
  );
};
