import * as React from 'react';
import * as Sentry from '@sentry/react';
import { Text } from 'rebass/styled-components';
import { QuestionType, AddressQuestionExchangeDefinition, QuestionExchangeDefinition } from '@deepstream/common/rfq-utils';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { UnexpectedStateError } from '@deepstream/errors';

export const AddressLocation = ({
  exchangeDef,
  fieldMaxWidth,
}: {
  exchangeDef: QuestionExchangeDefinition;
  fieldMaxWidth?: React.CSSProperties['maxWidth'];
}) => {
  const { t } = useTranslation();
  const { questionType, visibleFields } = exchangeDef as AddressQuestionExchangeDefinition;

  if (questionType !== QuestionType.ADDRESS) {
    Sentry.captureException(new UnexpectedStateError('[AddressLocation] Wrong question type passed', { questionType }));
    return null;
  }

  return (
    <Stack gap={1}>
      {map(visibleFields, (fieldKey) => (
        <Text key={fieldKey} maxWidth={fieldMaxWidth} sx={{ borderRadius: 'small', border: 'lightGray2', px: 2, py: 1, display: 'block' }}>
          {t(`request.question.addressField.${fieldKey}`)}
        </Text>
      ))}
    </Stack>
  );
};
