import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useApi, wrap } from '../../api';
import * as Layout from '../../Layout';

export const TemplatePreviewHeader = ({ rfqId }: { rfqId: string }) => {
  const { t } = useTranslation('translation');
  const currentCompanyId = useCurrentCompanyId();
  const api = useApi();

  const { data: template, isSuccess } = useQuery(
    ['template', { currentCompanyId, templateId: rfqId }],
    wrap(api.getTemplate),
  );

  return (
    <Layout.PageHeader
      icon="file-text-o"
      heading={isSuccess ? (
        template?.meta.name || t('requests.untitledTemplate')
      ) : null}
    />
  );
};
