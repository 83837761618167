import { Flex } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { getCompleteResponseItemCount, getTotalResponseItemCount } from '@deepstream/common/bidProgress';
import { LabeledValue } from '../draft/LabeledValue';
import { ProgressPercentage } from '../ui/ProgressPercentage';
import { useHooks } from '../useHooks';

const labelAdjustments = {
  align: 'left',
  gap: 0,
  height: '34px',
  valueProps: { fontWeight: 400 },
} as const;

/**
 * Component to render the *supplier-side* progress in the page footer
 * shown with the exchange modal.
 */
export const PageFooterBidProgress = () => {
  const { t } = useTranslation('translation');
  const hooks = useHooks();

  const bidProgress = hooks.useBidProgress();

  if (!bidProgress) {
    return null;
  }

  const myTaskCount = bidProgress.awaitingRecipient;
  const complete = getCompleteResponseItemCount(bidProgress);
  const totalTasks = getTotalResponseItemCount(bidProgress);

  return (
    <Flex>
      <LabeledValue
        label={t('request.progress.complete')}
        value={<ProgressPercentage progress={complete ? complete / totalTasks : 0} width={14} />}
        ml={4}
        {...labelAdjustments}
      />
      <LabeledValue
        label={t('request.progress.yourActions')}
        value={myTaskCount ? (
          <IconText
            text={myTaskCount}
            icon="exclamation-circle"
            iconColor="danger"
          />
        ) : (
          <IconText
            text={`${t('request.progress.noActions')}`}
            icon="check"
            iconColor="success"
          />
        )}
        ml={4}
        {...labelAdjustments}
      />
    </Flex>
  );
};
