import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { v4 as uuid } from 'uuid';
import { Box, Text, Flex } from 'rebass/styled-components';
import { Milestone } from '@deepstream/common/contract';
import { Button, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { DropdownMenu } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { ModalForm } from '../../ModalForm';
import { TextField } from '../../form/TextField';
import { SwitchField } from '../../form/SwitchField';
import { DatetimeField } from '../../form/DatetimeField';
import { LabelConfig, LabelConfigProvider } from '../../LabelConfigProvider';
import { useContractState } from './contract';

const LABEL_WIDTH = '250px';

const labelStyle = {
  fontSize: 2,
};

const labelConfigStyle = {
  name: labelStyle,
  date: labelStyle,
  isHidden: labelStyle,
};

const RemoveDropdown = ({
  onConfirm,
}: {
  onConfirm: () => void;
}) => {
  const { t } = useTranslation(['contracts', 'general']);

  return (
    <Box overflow="hidden">
      <DropdownMenu
        buttonText={t('remove', { ns: 'general' })}
        variant="danger"
        wrapperStyle={{
          borderTopRightRadius: '4px',
          borderBottomRightRadius: '4px',
        }}
        menuZIndex={300}
        popoverProps={{
          portal: false,
          style: {
            position: 'absolute',
            left: '16px',
            bottom: '42px',
          },
        }}
        header={
          <Box width="265px" p={3} fontSize={2}>
            <Text fontWeight={500} mb={2}>
              {t('dialog.confirmRemoveMilestone.heading')}
            </Text>
            <Text mb={3}>
              {t('dialog.confirmRemoveMilestone.message')}
            </Text>
            <Flex justifyContent="flex-end">
              {/*
                FIXME: Rendering the CancelButton as a `div` element to make the popup
                close when 'Cancel' is clicked. (A <button> element in this place would
                catch the click event and prevent closing.
                We should obviously make this a button and provide the required
                functionality; since our DropdownMenu doesn't forward focus to any header
                items in the popup, keyboard navigation is broken (just like in the
                NavigationDropdown) and so the `div` hack doesn't impact the UX.
              */}
              <CancelButton
                as="div"
                mr={2}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              />
              <Button type="button" variant="danger" onClick={onConfirm}>
                {t('confirm', { ns: 'general' })}
              </Button>
            </Flex>
          </Box>
        }
      />
    </Box>
  );
};

export const MilestoneModal = ({
  milestone,
  isOpen,
  hasAssociatedReminders,
  close,
  onSave,
  onDelete,
}: {
  milestone?: Milestone;
  isOpen: boolean;
  hasAssociatedReminders?: boolean;
  close: () => void;
  onSave: (milestone: Milestone) => void;
  onDelete?: () => void;
}) => {
  const { t } = useTranslation(['contracts', 'general']);

  // @ts-expect-error ts(2339) FIXME: Property 'isTemplate' does not exist on type 'ContractStateContextType | undefined'.
  const { isTemplate } = useContractState();

  const onSubmit = useCallback(
    values => {
      onSave({
        _id: milestone ? milestone._id : uuid(),
        ...values,
      });
      close();
    },
    [milestone, onSave, close],
  );

  const isEditingMilestone = Boolean(milestone);

  return (
    <LabelConfigProvider
      variant={LabelConfig.LEFT}
      width={LABEL_WIDTH}
      style={labelConfigStyle}
    >
      <ModalForm
        heading={isEditingMilestone ? t('summary.editMilestone') : t('summary.createMilestone')}
        initialValues={milestone || {
          name: '',
          date: null,
          isHidden: false,
        }}
        validationSchema={
          yup.object().shape({
            name: yup.string().required(t('required', { ns: 'general' })),
            date: isTemplate ? yup.mixed() : yup.date().required(t('required', { ns: 'general' })),
            isHidden: yup.boolean(),
          })
        }
        disableSubmitIfNotDirty={true}
        disableSubmitIfInvalid={true}
        isOpen={isOpen}
        onCancel={close}
        onSubmit={onSubmit}
        submitLabel={isEditingMilestone
          ? t('saveChanges', { ns: 'general' })
          : t('create', { ns: 'general' })
        }
        footerLeftButton={hasAssociatedReminders ? (
          <RemoveDropdown
            onConfirm={() => {
              // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
              onDelete();
              close();
            }}
          />
        ) : milestone ? (
          <Button
            type="button"
            variant="danger"
            onClick={() => {
              // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
              onDelete();
              close();
            }}
          >
            {t('remove', { ns: 'general' })}
          </Button>
        ) : (
          null
        )}
        style={{
          content: {
            paddingBottom: '50px', // We need more space for the date picker popup
          },
        }}
      >
        <TextField
          name="name"
          label={t('name', { ns: 'general' })}
          description={t('summary.milestoneNameDescription')}
          required
          // quick fix: we can remove setting the inputStyle height once a line height is
          // set for Input
          inputStyle={{ height: 40 }}
        />
        {!isTemplate && (
          <DatetimeField
            name="date"
            label={t('date', { ns: 'general' })}
            description={t('summary.milestoneDateDescription')}
            dateFormat="dd MMM yyyy"
            required
            popperPlacement="bottom"
          />
        )}
        <SwitchField
          name="isHidden"
          label={t('visibility', { ns: 'general' })}
          description={t('summary.milestoneVisibilityDescription')}
          text={t('summary.milestoneVisibilityText')}
          useDefaultLabelConfig={false}
        />
      </ModalForm>
    </LabelConfigProvider>
  );
};
