import { useTranslation } from 'react-i18next';
import { conforms, find, matches, overEvery, overSome } from 'lodash';
import { ActionType, BidOutcomeStatus, Live, PageType, getBidOutcomeStatus, isEvaluationPage } from '@deepstream/common/rfq-utils';
import { useMemo } from 'react';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils';
import { NavigationMenu, NavigationMenuItem } from './NavigationMenu';
import * as rfx from '../../../rfx';
import { useRequestRecipientNavigation } from '../../../appNavigation';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { getExchangeIdsForPage } from '../../../page-headers/BidPageSenderTabs';
import { useRfqId } from '../../../useRfq';
import { NotificationCounter } from '../../Notifications/NotificationCounter';
import * as lotPagesLayout from '../Live/lotPagesLayout';
import { Counter } from '../../../ui/Badge';
import { useBuyerActionRequiredCount } from './useBuyerActionRequiredCount';
import { useBidCommentNotifications } from './useBidCommentNotifications';

const SupplierBidMenuItemCounter = () => {
  const bidCommentNotifications = useBidCommentNotifications();

  return (
    <Counter
      count={bidCommentNotifications.length}
      fontSize={1}
      sx={lotPagesLayout.navigationMenuCounterSx}
      ml={2}
    />
  );
};

const BuyerBidMenuItemCounter = () => {
  const buyerActionRequiredCount = useBuyerActionRequiredCount();
  const bidCommentNotifications = useBidCommentNotifications();

  return (
    <Counter
      // @ts-expect-error ts(18047) FIXME: 'buyerActionRequiredCount' is possibly 'null'.
      count={buyerActionRequiredCount + bidCommentNotifications.length}
      fontSize={1}
      sx={lotPagesLayout.navigationMenuCounterSx}
      ml={2}
    />
  );
};

export const RequestRecipientMenuNavigation = () => {
  const { t } = useTranslation('translation');
  const { pages, meta } = rfx.useStructure<Live>();
  const rfqId = useRfqId();
  const bid = rfx.useBid();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentCompanyGroup = rfx.useCurrentCompanyGroup();
  const isSender = rfx.useIsSender();
  const chatPage = find(pages, { type: PageType.CHAT });
  const navigation = useRequestRecipientNavigation();

  const isAwarded = getBidOutcomeStatus(bid) === BidOutcomeStatus.AWARDED;
  const isRecipient = !isSender;

  const messageNotificationsFilter = useMemo(
    () => {
      // Very old requests don't have a chat page
      if (!chatPage) return null;

      const exchangeIds = getExchangeIdsForPage(chatPage, bid);

      return isRecipient
        ? overEvery([
          matches({
            domain: NotificationDomain.RFQ_RECEIVED,
            to: { companyId: currentCompanyId },
            meta: { rfqId },
          }),
          overSome([
            conforms({
              action: action => action === NotificationAction.EXCHANGE_REPLY_SENT,
              meta: meta => meta.actionType === ActionType.NONE && exchangeIds.includes(meta.exchangeId),
            }),
            conforms({
              action: action => [NotificationAction.REQUEST_BULLETIN_ADDED, NotificationAction.REQUEST_BULLETIN_UPDATED].includes(action),
            }),
          ]),
        ])
        : conforms({
          to: to => to.companyId === currentCompanyId,
          domain: domain => domain === NotificationDomain.RFQ_SENT,
          action: action => action === NotificationAction.EXCHANGE_REPLY_SENT,
          meta: meta => meta.rfqId === rfqId && exchangeIds.includes(meta.exchangeId),
          // There's no need to filter by meta.recipientId because all chats /
          // clarifications with recipients have unique IDs, and the recipient-specific
          // structure only includes the chats/clarifications for the current recipient.
        });
    },
    [chatPage, bid, currentCompanyId, rfqId, isRecipient],
  );

  return (
    <NavigationMenu heading={t('general.menu')} sx={{ gridTemplateColumns: [null, '1fr 1fr'] }}>
      <NavigationMenuItem
        icon="list-check"
        title={
          <>
            {t(`request.recipientPage.bid.title.${currentCompanyGroup}`)}
            {currentCompanyGroup === 'buyer' ? (
              <BuyerBidMenuItemCounter />
            ) : (
              <SupplierBidMenuItemCounter />
            )}
          </>
        }
        body={t(`request.recipientPage.bid.description.${currentCompanyGroup}`)}
        linkProps={navigation.getBidLinkProps()}
      />
      {isAwarded && (
        <NavigationMenuItem
          icon="trophy"
          isIconRegular
          title={t('request.recipientPage.awardSummary.title')}
          body={t(`request.recipientPage.awardSummary.description.${currentCompanyGroup}`)}
          linkProps={navigation.getAwardSummaryLinkProps()}
        />
      )}
      {isSender && pages.some(isEvaluationPage) && (
        <NavigationMenuItem
          icon="balance-scale"
          isIconRegular
          title={t('request.recipientPage.evaluation.title')}
          body={t('request.recipientPage.evaluation.description.buyer')}
          linkProps={navigation.getEvaluationLinkProps()}
        />
      )}
      {meta.hasMessagesPage && (
        <NavigationMenuItem
          icon="comment-o"
          title={
            <>
              {t('request.recipientPage.messages.title')}
              {messageNotificationsFilter && (
                <NotificationCounter
                  filter={messageNotificationsFilter}
                  ml={2}
                  fontSize={1}
                  sx={lotPagesLayout.navigationMenuCounterSx}
                />
              )}
            </>
          }
          body={t(`request.recipientPage.messages.description.${currentCompanyGroup}`)}
          linkProps={navigation.getMessagesLinkProps()}
        />
      )}
      {isRecipient && !meta.hasBulletinsSection && (
        <NavigationMenuItem
          icon="comment-o"
          title={t('request.recipientPage.bulletins.title')}
          body={t('request.recipientPage.bulletins.description.supplier')}
          linkProps={navigation.getLegacyBulletinsLinkProps()}
        />
      )}
      <NavigationMenuItem
        icon="users"
        title={t('request.recipientPage.team.title')}
        body={t(`request.recipientPage.team.description.${currentCompanyGroup}`)}
        linkProps={navigation.getTeamLinkProps()}
      />
      <NavigationMenuItem
        icon="file-magnifying-glass"
        isIconRegular
        title={t('request.recipientPage.audit.title')}
        body={t(`request.recipientPage.audit.description.${currentCompanyGroup}`)}
        linkProps={navigation.getAuditLinkProps()}
      />
    </NavigationMenu>
  );
};
