import * as Layout from '../../../Layout';
import { Header } from './Header';
import { TemplatePageTab } from './utils';
import { ActiveQuestions } from './ActiveQuestions';

export const QuestionnaireTemplateActiveQuestionsPageContent = () => {
  const tabId = TemplatePageTab.QUESTIONS;

  return (
    <Layout.HeaderAndContent
      header={
        <Header selectedTabId={tabId} />
      }
      content={
        <ActiveQuestions />
      }
    />
  );
};
