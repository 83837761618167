import { useCallback } from 'react';
import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Attachment } from '@deepstream/common/rfq-utils';
import { omitNil } from '@deepstream/utils';
import { Box } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { conforms } from 'lodash';
import { isCompanySuperUser } from '@deepstream/common/user-utils';
import { useNavigate } from '@tanstack/react-router';
import { callAll } from '@deepstream/utils/callAll';
import { Panel, PanelText } from '@deepstream/ui-kit/elements/Panel';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils';
import { scrollToTop } from '@deepstream/ui-utils/scrollToTop';
import { useCountryCodes } from '../../../ui/countries';
import { Loading } from '../../../ui/Loading';
import { CompanyProfile as CompanyProfileUI } from './CompanyProfile';
import { useModalState } from '../../../ui/useModalState';
import { useApi, wrap } from '../../../api';
import { useEnv } from '../../../env';
import { useToaster } from '../../../toast';
import { EditBasicInformationModal } from './EditBasicInformationModal';
import { EditRegisteredInformationModal } from './EditRegisteredInformationModal';
import { EditContactDetailsModal } from './EditContactDetailsModal';
import { EditDescriptionModal } from './EditDescriptionModal';
import { DeprecatedProductsModal } from './DeprecatedProductsModal';
import { EditProductsModal } from '../../../EditProductsModal';
import { EditLocationsModal } from './EditLocationsModal';
import { useMutation } from '../../../useMutation';
import { downloadUrl } from '../../../useDownload';
import { useRegionTags } from '../../../useRegionTags';
import { useCurrentUser } from '../../../useCurrentUser';
import { useSystemFeatureFlags } from '../../../systemFeatureFlags';
import { companyProfileRoute } from '../../../AppRouting';
import { useUserFlags } from '../../../UserFlagsContext';
import { useNotificationSubject } from '../../Notifications/useNotificationSubject';
import { Notification } from '../../Notifications/types';

type Document = {
  _id: string;
  expiryDate: string;
  name: string;
  type: string;
  attachment: any;
};

export const CompanyProfileContainer = ({
  companyId,
  bannerStyle,
  currentCompanyId,
  canEdit,
  navigateToDocumentLibrary,
  navigateToAuditTrail,
  show = 'profile',
  tab = 'general',
}: {
  companyId: string;
  currentCompanyId: string;
  bannerStyle: React.CSSProperties,
  canEdit: boolean;
  navigateToDocumentLibrary: () => void;
  navigateToAuditTrail: () => void;
  show: 'profile' | 'pre-qualification';
  tab: 'general' | 'activity';
}) => {
  const { t } = useTranslation(['companyProfile', 'general']);
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const { API_URL, PUBLIC_PAGES_URL } = useEnv();
  const countryCodes = useCountryCodes();
  const editBasicInformationModal = useModalState();
  const editRegisteredInformationModal = useModalState();
  const editContactDetailsModal = useModalState();
  const editDescriptionModal = useModalState();
  const deprecatedProductsModal = useModalState();
  const editProductsModal = useModalState();
  const editLocationsModal = useModalState();
  const systemFeatureFlags = useSystemFeatureFlags();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const { belongsToPayingCompany } = useUserFlags();
  const search = companyProfileRoute.useSearch();

  const isSuperUser = isCompanySuperUser(currentUser, currentCompanyId);

  const { data: company, status: companyStatus } = useQuery(
    ['publicCompany', { companyId }],
    wrap(api.getPublicCompany),
  );

  const { data: documents } = useQuery(
    ['publicDocuments', { companyId }],
    wrap(api.getCompanyPublicDocuments),
  );

  const { data: numMembers } = useQuery(
    ['numMembers', { companyId }],
    wrap(api.getCompanyMembershipCount),
  );

  const { data: questionnaire } = useQuery(
    'legacyQuestionnaire',
    api.getLegacyQuestionnaire,
  );

  const { data: answerSet } = useQuery(
    ['answerSet', { companyId, currentCompanyId }],
    wrap(api.getAnswerSet),
  );

  const { data: requests } = useQuery(
    ['sentRequests', { currentCompanyId, filter: { recipientIds: [companyId] } }],
    wrap(api.getSentRequests),
  );

  const { data: contracts } = useQuery(
    [
      'contracts',
      { currentCompanyId,
        filter: {
          recipients: [companyId],
        },
      },
    ],
    wrap(api.getSentContracts),
  );

  const { data: questionnaires } = useQuery(
    [
      'sentQuestionnaires',
      {
        currentCompanyId,
        filter: {
          recipients: [companyId],
        },
      },
    ],
    wrap(api.getSentQuestionnaires),
  );

  const regionTags = useRegionTags();

  const downloadAttachment = useCallback(
    async (attachment: Attachment) => {
      const { url } = await api.downloadPreQualDocument({
        currentCompanyId,
        attachmentId: attachment._id,
      });

      const { name } = attachment;
      downloadUrl(url, name);

      toaster.success(t('toaster.downloadSuccess', { name }));
    },
    [api, currentCompanyId, toaster, t],
  );

  const [getDocumentDownloadUrl] = useMutation(api.getDocumentDownloadUrl);
  const [downloadPreQualDocument] = useMutation(downloadAttachment);

  const [upload] = useMutation(api.upload);
  const [updateCompany] = useMutation(
    api.updateCompany,
    {
      onSettled: () => queryClient.invalidateQueries(['publicCompany', { companyId }]),
    },
  );
  const [updateAnswerSet] = useMutation(
    api.updateAnswerSet,
    {
      onSuccess: () => queryClient.invalidateQueries(['answerSet', { companyId }]),
    },
  );

  const [updatePreQualAccess] = useMutation(
    api.updatePreQualAccess,
    {
      onSuccess: () => queryClient.invalidateQueries(['answerSet', { companyId }]),
    },
  );

  const { data: preQualAccessStatus } = useQuery(
    ['preQualAccess', { currentCompanyId, companyId }],
    wrap(api.getPreQualAccessStatus),
    {
      enabled: currentCompanyId !== companyId,
    },
  );

  const [requestPreQualAccess] = useMutation(
    api.requestPreQualAccess,
    {
      onSuccess: () => queryClient.invalidateQueries(['preQualAccess', { currentCompanyId, companyId }]),
    },
  );

  const downloadDocument = React.useCallback(
    async (document: Document) => {
      try {
        const { url } = await getDocumentDownloadUrl({ currentCompanyId, targetCompanyId: companyId, documentId: document._id });
        const { name } = document.attachment;
        downloadUrl(url, name);
        toaster.success(t('toaster.downloadSuccess', { name }));
      } catch (error) {
        toaster.error(t('toaster.downloadDocumentError'));
      }
    },
    [companyId, currentCompanyId, getDocumentDownloadUrl, toaster, t],
  );

  const updateCompanyLogo = React.useCallback(
    async (uploadedImage) => {
      if (uploadedImage) {
        try {
          await updateCompany({
            companyId,
            companyPatch: {
              logoUrl: uploadedImage.url,
            },
          });
          toaster.success(t('toaster.updateCompanyLogoSuccess'));
        } catch (error) {
          toaster.error(t('toaster.updateCompanyLogoError'));
        }
      }
    },
    [companyId, updateCompany, toaster, t],
  );

  const updateCompanyBanner = React.useCallback(
    async (uploadedImage) => {
      if (uploadedImage) {
        try {
          await updateCompany({
            companyId,
            companyPatch: {
              heroImg: uploadedImage,
            },
          });
          toaster.success(t('toaster.updateCompanyBannerSuccess'));
        } catch (error) {
          toaster.error(t('toaster.updateCompanyBannerError'));
        }
      }
    },
    [companyId, updateCompany, toaster, t],
  );

  const uploadPreQualDocument = React.useCallback(
    async ({ file, onProgress }) => upload({ companyId, purpose: 'prequal', file, onProgress }),
    [companyId, upload],
  );

  const uploadCompanyBanner = React.useCallback(
    async ({ file, onProgress }) => upload({ companyId, purpose: 'hero', file, onProgress }),
    [companyId, upload],
  );

  const uploadCompanyLogo = React.useCallback(
    async ({ file, onProgress }) => upload({ companyId, purpose: 'logo', file, onProgress }),
    [companyId, upload],
  );

  const savePreQualAnswers = React.useCallback(
    async (answers: any) => {
      let success = false;
      try {
        success = await updateAnswerSet({ companyId, answers });
        toaster.success(t('toaster.updateAnswersSuccess'));
      } catch (error) {
        toaster.error(t('toaster.updateAnswersError'));
      }
      return success;
    },
    [companyId, updateAnswerSet, toaster, t],
  );

  const approvePreQualAccess = React.useCallback(
    async (companyId) => {
      let success = false;
      try {
        success = await updatePreQualAccess({ companyId, currentCompanyId, action: 'approve' });
        toaster.success(t('toaster.approvePreQualAccessSuccess'));
      } catch (error) {
        toaster.error(t('toaster.approvePreQualAccessError'));
      }
      return success;
    },
    [currentCompanyId, updatePreQualAccess, toaster, t],
  );

  const denyPreQualAccess = React.useCallback(
    async (companyId: string) => {
      let success = false;
      try {
        success = await updatePreQualAccess({ companyId, currentCompanyId, action: 'deny' });
        toaster.success(t('toaster.denyPreQualAccessSuccess'));
      } catch (error) {
        toaster.error(t('toaster.denyPreQualAccessError'));
      }
      return success;
    },
    [currentCompanyId, updatePreQualAccess, toaster, t],
  );

  const revokePreQualAccess = React.useCallback(
    async (companyId: string) => {
      let success = false;
      try {
        success = await updatePreQualAccess({ companyId, currentCompanyId, action: 'deny' });
        toaster.success(t('toaster.revokePreQualAccessSuccess'));
      } catch (error) {
        toaster.error(t('toaster.revokePreQualAccessError'));
      }
      return success;
    },
    [currentCompanyId, updatePreQualAccess, toaster, t],
  );

  const searchCompanies = useCallback(
    async (text) => api
      .searchCompanies({ text, pageSize: 7, pageIndex: 0 })
      .then(result => result.companies),
    [api],
  );

  const requestAccess = React.useCallback(
    async () => {
      let success = false;
      try {
        success = await requestPreQualAccess({ companyId, currentCompanyId });
        toaster.success(t('toaster.requestAccessSuccess'));
      } catch (error) {
        toaster.error(t('toaster.requestAccessError'));
      }
      return success;
    },
    [companyId, currentCompanyId, requestPreQualAccess, toaster, t],
  );

  const navigateToLegacyPreQual = React.useCallback(
    () => {
      navigate({
        from: companyProfileRoute.to,
        to: companyProfileRoute.to,
        search: { show: 'pre-qualification' },
      });

      scrollToTop();
    },
    [navigate],
  );

  const navigateToProfile = React.useCallback(
    () => {
      navigate({
        from: companyProfileRoute.to,
        to: companyProfileRoute.to,
        search: { show: 'profile' },
      });
      scrollToTop();
    },
    [navigate],
  );

  const [deleteLegacyPreQual] = useMutation(
    api.deleteLegacyPreQual,
    {
      onSuccess: callAll(
        () => navigateToProfile(),
        () => toaster.success(t('toaster.deletePreQualSuccess')),
      ),
      onError: () => toaster.error(t('toaster.deletePreQualError')),
      onSettled: () => queryClient.invalidateQueries(['answerSet', { companyId }]),
    },
  );

  const notificationsRef = useNotificationSubject({
    filter: conforms<Partial<Notification>>({
      domain: domain => domain === NotificationDomain.SUPPLIER_MANAGEMENT,
      action: action => action === NotificationAction.SUPPLIER_INVITED_CREATES_ACCOUNT,
      to: to => to.companyId === currentCompanyId,
      meta: meta => meta.companyAffected?._id === companyId,
    }),
  });

  const navigateToGeneralTab = React.useCallback(
    () => {
      navigate({
        from: companyProfileRoute.to,
        to: companyProfileRoute.to,
        search: omitNil({ ...search, tab: 'general' }),
      });
    },
    [navigate, search],
  );

  const navigateToActivityTab = React.useCallback(
    () => {
      navigate({
        from: companyProfileRoute.to,
        to: companyProfileRoute.to,
        search: omitNil({ ...search, tab: 'activity' }),
      });
    },
    [navigate, search],
  );

  const switchTab = React.useCallback(
    tabIndex => {
     tabIndex === 0 ? navigateToGeneralTab() : navigateToActivityTab();
    },
    [navigateToActivityTab, navigateToGeneralTab],
  );

  return (
    <>
      {companyStatus === 'loading' ? (
        <Panel>
          <PanelText>
            <Loading />
          </PanelText>
        </Panel>
      ) : companyStatus === 'error' ? (
        <Panel heading={t('error', { ns: 'general' })}>
          <PanelText>{t('error.couldNotLoadCompany')}</PanelText>
        </Panel>
      ) : company ? (
        <Box ref={notificationsRef}>
          <CompanyProfileUI
            currentCompanyId={currentCompanyId}
            company={company}
            canEdit={canEdit}
            documents={documents || []}
            isLoggedIn
            apiUrl={API_URL}
            publicPagesBaseUrl={PUBLIC_PAGES_URL}
            numMembers={numMembers}
            bannerStyle={bannerStyle}
            questionnaire={questionnaire}
            answerSet={answerSet}
            downloadDocument={downloadDocument}
            navigateToDocumentLibrary={navigateToDocumentLibrary}
            openBasicInformationModal={editBasicInformationModal.open}
            openRegisteredInformationModal={editRegisteredInformationModal.open}
            openContactDetailsModal={editContactDetailsModal.open}
            openDescriptionModal={editDescriptionModal.open}
            openDeprecatedProductsModal={deprecatedProductsModal.open}
            openProductsModal={editProductsModal.open}
            openLocationsModal={editLocationsModal.open}
            updateCompanyLogo={updateCompanyLogo as any}
            updateCompanyBanner={updateCompanyBanner as any}
            savePreQualAnswers={savePreQualAnswers}
            uploadPreQualDocument={uploadPreQualDocument}
            uploadCompanyBanner={uploadCompanyBanner}
            uploadCompanyLogo={uploadCompanyLogo}
            downloadPreQualDocument={downloadPreQualDocument}
            searchCompanies={searchCompanies}
            preQualAccessStatus={preQualAccessStatus}
            requestPreQualAccess={requestAccess}
            approvePreQualAccess={approvePreQualAccess}
            denyPreQualAccess={denyPreQualAccess}
            revokePreQualAccess={revokePreQualAccess}
            regionTags={regionTags || []}
            countryCodes={countryCodes}
            toaster={toaster}
            navigateToAuditTrail={navigateToAuditTrail}
            navigateToLegacyPreQual={navigateToLegacyPreQual}
            navigateToProfile={navigateToProfile}
            show={show}
            tab={tab}
            switchTab={switchTab}
            isSuperUser={isSuperUser}
            deleteLegacyPreQual={deleteLegacyPreQual}
            systemFeatureFlags={systemFeatureFlags}
            belongsToPayingCompany={belongsToPayingCompany}
            questionnaires={questionnaires?.overviews || []}
            requests={requests?.rfqs || []}
            contracts={contracts?.overviews || []}
          />
          <EditBasicInformationModal
            onSave={editBasicInformationModal.close}
            onCancel={editBasicInformationModal.close}
            isOpen={editBasicInformationModal.isOpen}
            company={company}
          />
          <EditRegisteredInformationModal
            onSave={editRegisteredInformationModal.close}
            onCancel={editRegisteredInformationModal.close}
            isOpen={editRegisteredInformationModal.isOpen}
            company={company}
          />
          <EditContactDetailsModal
            onSave={editContactDetailsModal.close}
            onCancel={editContactDetailsModal.close}
            isOpen={editContactDetailsModal.isOpen}
            company={company}
          />
          <EditDescriptionModal
            onSave={editDescriptionModal.close}
            onCancel={editDescriptionModal.close}
            isOpen={editDescriptionModal.isOpen}
            company={company}
          />
          <DeprecatedProductsModal
            onClose={deprecatedProductsModal.close}
            isOpen={deprecatedProductsModal.isOpen}
            company={company}
          />
          {editProductsModal.isOpen && (
            <EditProductsModal
              onSave={editProductsModal.close}
              onCancel={editProductsModal.close}
              isOpen={editProductsModal.isOpen}
              company={company}
            />
          )}
          <EditLocationsModal
            onSave={editLocationsModal.close}
            onCancel={editLocationsModal.close}
            isOpen={editLocationsModal.isOpen}
            company={company}
          />
        </Box>
      ) : null}
    </>
  );
};
