import { Draft } from '@deepstream/common/rfq-utils';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { isEmpty, isEqual } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import { MultiStageLineItemsEventType } from '@deepstream/common';
import { Bold } from '../../../../Bold';
import { StageName } from '../../../../StageName';
import * as rfx from '../../../../rfx';
import { RfxStructure } from '../../../../types';
import { HorizontalDivider } from '../../../../ui/HorizontalDivider';
import { useMultiStepFlowData } from '../../../../ui/MultiStepFlow/MultiStepFlowContext';
import { StepNavigationBackLink } from '../../../../ui/MultiStepFlow/StepNavigation';
import { Direction } from '../../../../ui/MultiStepFlow/types';
import * as lotPagesLayout from '../../Live/lotPagesLayout';
import {
  SetupMultiStageResponsesFlowData,
  SetupMultiStageResponsesFlowStepType,
} from '../types';
import { useRequestEditNavigation } from '../../../../appNavigation';
import { useTrackSetupMultiStageResponsesEvent } from '../tracking';

const SubsectionIds = {
  stageUpdates: 'stageUpdates',
  sectionUpdates: 'sectionUpdates',
  exitProcess: 'exitProcess',
};

const FlowUpdatesLayout = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => (
  <>
    <Flex sx={{ columnGap: 4 }} py="12px">
      <Bold width="200px">{title}</Bold>
      <Flex flexDirection="column">{children}</Flex>
    </Flex>
    <HorizontalDivider />
  </>
);

const StageNames = ({ stages }: { stages: RfxStructure<Draft>['stages'] }) => {
  return stages.map((stage, stageIndex) => (
    <Text key={stage._id}>
      <StageName key={stage._id} index={stageIndex} stage={stage} withPrefix />
    </Text>
  ));
};

export const ReviewChanges = () => {
  const { t } = useTranslation('translation');

  const structure = rfx.useStructure<Draft>();
  const { navigateToReview } = useRequestEditNavigation();

  const { data, submitAndNavigate } = useMultiStepFlowData<
    SetupMultiStageResponsesFlowStepType,
    SetupMultiStageResponsesFlowData
  >();

  const onBackClick = useCallback(
    () => submitAndNavigate(null, Direction.BACK),
    [submitAndNavigate],
  );

  const subsections = useMemo(() => {
    return [
      {
        id: SubsectionIds.stageUpdates,
        heading: t(
          'request.setupMultiStageResponsesFlow.steps.reviewChages.stageUpdates',
        ),
      },
      {
        id: SubsectionIds.sectionUpdates,
        heading: t(
          'request.setupMultiStageResponsesFlow.steps.reviewChages.sectionUpdates',
        ),
      },
      {
        id: SubsectionIds.exitProcess,
        heading: t('general.exitProcess'),
      },
    ];
  }, [t]);

  const hasUpdatedStages = useMemo(() => {
    const { initialStages, updatedStages } = data;
    const getSortedStageIds = (stages) =>
      stages.map((stage) => stage._id).sort();

    return !isEqual(
      getSortedStageIds(initialStages),
      getSortedStageIds(updatedStages),
    );
  }, [data]);

  const trackFlowEvent = useTrackSetupMultiStageResponsesEvent({
    eventType: MultiStageLineItemsEventType.FLOW_SUBMITTED,
  });

  return (
    <lotPagesLayout.ContentWrapper2>
      <StepNavigationBackLink onClick={onBackClick} />
      <lotPagesLayout.Section
        heading={t(
          'request.setupMultiStageResponsesFlow.steps.reviewChages.heading',
        )}
      >
        <Stack gap="40px" mt="40px">
          <lotPagesLayout.Subsection2
            heading={t('contents', { ns: 'general' })}
          >
            <lotPagesLayout.Ul listStyleType="—  ">
              {subsections.map(({ id, heading }) => (
                <lotPagesLayout.IndexListItem
                  key={id}
                  id={id}
                  heading={heading}
                  gap="10px"
                />
              ))}
            </lotPagesLayout.Ul>
          </lotPagesLayout.Subsection2>
          <lotPagesLayout.Subsection2
            id={SubsectionIds.stageUpdates}
            heading={t(
              'request.setupMultiStageResponsesFlow.steps.reviewChages.stageUpdates',
            )}
          >
            <Flex flexDirection="column">
              <FlowUpdatesLayout
                title={t(
                  'request.setupMultiStageResponsesFlow.steps.reviewChages.previousStages',
                )}
              >
                <StageNames stages={data.initialStages} />
              </FlowUpdatesLayout>
              <FlowUpdatesLayout
                title={t(
                  'request.setupMultiStageResponsesFlow.steps.reviewChages.updatedStages',
                )}
              >
                {hasUpdatedStages ? (
                  <StageNames stages={data.updatedStages} />
                ) : (
                  t(
                    'request.setupMultiStageResponsesFlow.steps.reviewChages.noUpdates',
                  )
                )}
              </FlowUpdatesLayout>
            </Flex>
          </lotPagesLayout.Subsection2>
          <lotPagesLayout.Subsection2
            id={SubsectionIds.sectionUpdates}
            heading={t(
              'request.setupMultiStageResponsesFlow.steps.reviewChages.sectionUpdates',
            )}
          >
            <FlowUpdatesLayout
              title={t(
                'request.setupMultiStageResponsesFlow.steps.reviewChages.sectionsConfiguredCount',
                { count: data.selectedSectionIds.length },
              )}
            >
              {data.selectedSectionIds
                .map(
                  (sectionId) =>
                    structure.sectionById[sectionId].name ||
                    t('general.untitled'),
                )
                .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))
                .join(', ')}
            </FlowUpdatesLayout>
            {!isEmpty(data.addedSectionIds) && (
              <FlowUpdatesLayout
                title={t(
                  'request.setupMultiStageResponsesFlow.steps.reviewChages.sectionsAddedCount',
                  { count: data.addedSectionIds.length },
                )}
              >
                {data.addedSectionIds
                  .map((sectionId) => structure.sectionById[sectionId].name)
                  .join(', ')}
              </FlowUpdatesLayout>
            )}
          </lotPagesLayout.Subsection2>
          <lotPagesLayout.Subsection2
            id={SubsectionIds.exitProcess}
            heading={t('general.exitProcess')}
          >
            <Text mb="6px">
              {t(
                'request.setupMultiStageResponsesFlow.steps.reviewChages.exitProcess.description1',
              )}
            </Text>
            <Text mb="20px">
              {t(
                'request.setupMultiStageResponsesFlow.steps.reviewChages.exitProcess.description2',
              )}
            </Text>
            <Button
              onClick={async () => {
                await trackFlowEvent();
                navigateToReview();
              }}
            >
              {t('general.exitProcess')}
            </Button>
          </lotPagesLayout.Subsection2>
        </Stack>
      </lotPagesLayout.Section>
    </lotPagesLayout.ContentWrapper2>
  );
};
