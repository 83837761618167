// Auto-generated file created by svgr-cli source svgr-config/template.js
// Do not edit
import type { SVGProps } from 'react';

const SvgLocked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={188}
    height={188}
    fill="none"
    {...props}
  >
    <path
      fill="#F7F9FB"
      stroke="#E2E8EF"
      strokeWidth={0.75}
      d="M184.893 94.274c0 50.035-40.804 90.601-91.143 90.601S2.607 144.309 2.607 94.274c0-50.036 40.804-90.602 91.143-90.602s91.143 40.566 91.143 90.602Z"
    />
    <path
      fill="#F7F9FB"
      stroke="#E2E8EF"
      strokeWidth={0.75}
      d="M93.75 185.625c50.749 0 91.893-40.897 91.893-91.351S144.499 2.922 93.75 2.922 1.857 43.82 1.857 94.274s41.144 91.351 91.893 91.351Z"
    />
    <path
      fill="#BEC3C7"
      fillRule="evenodd"
      d="M93.558 40.75c-14.027 0-25.399 11.371-25.399 25.399v4.742c-10.621 1.385-18.814 10.583-19.595 22.3-.328 4.92-.564 10.207-.564 15.24s.236 10.321.564 15.24c.812 12.19 9.648 21.654 20.894 22.429a353 353 0 0 0 48.361 0c11.246-.775 20.081-10.239 20.894-22.429.327-4.919.563-10.208.563-15.24 0-5.033-.236-10.32-.563-15.24-.785-11.776-9.057-21.008-19.756-22.32v-4.722c0-14.028-11.372-25.399-25.4-25.399m15.239 29.508v-4.11c0-8.416-6.823-15.238-15.24-15.238S78.32 57.732 78.32 66.149v4.116a355 355 0 0 1 30.478-.007"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M98.638 114.786a10.16 10.16 0 0 0 5.079-8.8c0-5.611-4.548-10.16-10.16-10.16-5.61 0-10.159 4.549-10.159 10.16 0 3.76 2.044 7.043 5.08 8.8v6.439a5.08 5.08 0 0 0 10.16 0z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLocked;
