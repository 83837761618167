import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { withProps } from '@deepstream/ui-utils/withProps';
import { assertUnreachable } from '@deepstream/utils/assertUnreachable';
import { BasicTableStyles, StaticTableStyles } from '../../../TableStyles';
import { Table } from '../../../Table';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useLocalStorageState } from '../../../useLocalStorageState';
import { FieldsCell, FieldsCellField, MIN_CELL_HEIGHT } from '../../../FieldsCell';
import { UserAvatar } from '../../../UserAvatar';
import { RequestRole, RequestTeamUser } from '../../../types';
import { TeamUserCellTitle } from '../../../TeamUserCellTitle';
import { useCurrentUser } from '../../../useCurrentUser';
import * as rfx from '../../../rfx';

const RoleFieldValue = ({ value }: { value: RequestRole }) => {
  const { t } = useTranslation();

  switch (value) {
    case RequestRole.OWNER:
      return <>{t('request.team.requestRole.owner')}</>;
    case RequestRole.TEAM_MEMBER:
      return <>{t('request.team.requestRole.teamMember')}</>;
    default:
      return assertUnreachable(value);
  }
};

export const RecipientTeamUsersTable = ({
  users,
}: {
  users: RequestTeamUser[];
  rfqStructureQueryKey?: any;
}) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { isLive, isReview } = rfx.useState();

  const columns = useMemo(
    () => [{ id: 'custom' }],
    [],
  );

  const CustomRowCells = useMemo(
    () => {
      const fields: FieldsCellField[] = [
        {
          label: t('general.email'),
          accessor: 'email',
          // when there's no user name, the email is displayed in the cell's
          // title and thus shouldn't be repeated here
          condition: (user) => !!user.name,
        },
        {
          label: t('general.role'),
          accessor: 'requestRole',
          FieldValueComponent: RoleFieldValue,
        },
      ];

      return withProps(
        FieldsCell,
        {
          fields,
          TitleComponent: TeamUserCellTitle,
          RowIconComponent: UserAvatar,
          actionColumnWidth: 100,
        },
      );
    },
    [t],
  );

  const [initialPageSize, setInitialPageSize] = useLocalStorageState<number>({
    key: `${currentCompanyId}.${currentUser._id}.teamUsers.pageSize`,
    defaultValue: 10,
  });

  const TableStyles = isLive || isReview
    ? StaticTableStyles
    : withProps(
      BasicTableStyles,
      { hoverBackgroundColor: 'lightGray6', hoverCursor: 'default' },
    );

  return (
    <TableStyles>
      <Table
        columns={columns}
        data={users}
        initialPageSize={initialPageSize}
        onPageSizeChange={setInitialPageSize}
        hideHeader
        CustomRowCells={CustomRowCells}
        hasStaticHeight={false}
        minCellHeight={MIN_CELL_HEIGHT}
        smallPageControls
        noDataPlaceholder={t('request.team.noUsersAdded')}
      />
    </TableStyles>
  );
};
