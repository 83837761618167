import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Flex } from 'rebass/styled-components';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useSupplierLists } from './useSupplierLists';
import { LoadingPanel } from '../../ui/Loading';
import { ErrorPanel } from '../../ui/ErrorMessage';
import { SupplierListsGrid } from './SupplierListsGrid';
import { useSupplierListNavigation } from '../../appNavigation';
import { Bold } from '../../Bold';
import { useIsAuthorizedStakeholder } from '../../useIsAuthorizedStakeholder';

export const SupplierLists = () => {
  const { t } = useTranslation();
  const { navigateToCreateList } = useSupplierListNavigation();
  const isAuthorizedStakeholder = useIsAuthorizedStakeholder();

  const { supplierLists, supplierListsStatus } = useSupplierLists();

  if (supplierListsStatus === 'loading') {
    return <LoadingPanel />;
  }

  if (supplierListsStatus === 'error') {
    return <ErrorPanel error={t('errors.unexpected')} />;
  }

  const hasLists = !isEmpty(supplierLists);

  return (
    <Panel>
      <PanelHeader
        heading={hasLists ? t('supplierLists.listCount', { count: supplierLists.length }) : ''}
      >
        {!isAuthorizedStakeholder && (
          <Button small variant="primary" iconLeft="plus" onClick={() => navigateToCreateList()}>
            {t('supplierLists.newList')}
          </Button>
        )}
      </PanelHeader>
      <PanelDivider />
      {hasLists ? (
        <PanelPadding>
          <GridIdPrefixProvider>
            <GridMenuStateProvider>
              <SupplierListsGrid supplierLists={supplierLists} />
            </GridMenuStateProvider>
          </GridIdPrefixProvider>
        </PanelPadding>
      ) : (
        <Flex alignItems="center" justifyContent="center" height="300px">
          <Bold>
            {t('supplierLists.noListsCreated')}
          </Bold>
        </Flex>
      )}
    </Panel>
  );
};
