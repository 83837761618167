import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HirePeriod, HirePeriodExchangeDefinition, isHirePeriodExchangeDef } from '@deepstream/common/rfq-utils';
import { filter, isEqual, map, pick } from 'lodash';
import { ConfirmDeleteDialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { EllipsisMenu, MoveUpMenuItem, MoveDownMenuItem, DeleteMenuItem, MakeNonObsoleteMenuItem, MakeObsoleteMenuItem, DiscardChangesMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { useConfirmDialog } from '../ui/useModalState';
import { hirePeriodCompareLiveVersionKeys, intervalCompareLiveVersionKeys, useHirePeriodsField } from './hirePeriods';
import * as rfx from '../rfx';

/**
 * Controls for performing section actions.
 */
export const HirePeriodPanelActions = ({
  hirePeriod: draftHirePeriod,
  index,
}: {
  hirePeriod: HirePeriod;
  index: number;
}) => {
  const { hirePeriodById } = rfx.useStructure();
  const hirePeriod = hirePeriodById[draftHirePeriod._id];
  const exchangeDefs = rfx.useSectionExchangeDefs();
  const intervals = filter(
    exchangeDefs,
    exchangeDef => (
      isHirePeriodExchangeDef(exchangeDef) &&
      exchangeDef.hirePeriodId === draftHirePeriod._id &&
      exchangeDef.isLive
    ),
  ) as HirePeriodExchangeDefinition[];

  const { t } = useTranslation();

  const {
    hirePeriods,
    intervalsByHirePeriodId,
    moveHirePeriod,
    removeHirePeriod,
    setHirePeriodIsObsolete,
    resetToLiveVersion,
  } = useHirePeriodsField(
    'hirePeriods',
    'intervalsByHirePeriodId',
  );

  const draftIntervals = intervalsByHirePeriodId[draftHirePeriod._id];

  const { confirm, ...dialogProps } = useConfirmDialog();

  const hasStagedChanges = useMemo(() => {
    if (
      !hirePeriod ||
      !hirePeriod.isLive ||
      !hirePeriod.liveVersion ||
      hirePeriod.isObsolete
    ) {
      return false;
    }

    return !isEqual(
      pick(draftHirePeriod, hirePeriodCompareLiveVersionKeys),
      pick(hirePeriod.liveVersion, hirePeriodCompareLiveVersionKeys),
    ) || !isEqual(
      map(draftIntervals, interval => pick(interval, intervalCompareLiveVersionKeys)),
      map(intervals, interval => pick(interval.liveVersion, intervalCompareLiveVersionKeys)),
    );
  }, [draftHirePeriod, hirePeriod, draftIntervals, intervals]);

  return (
    <>
      <EllipsisMenu
        variant="secondary-outline"
        bg="lightGray3"
      >
        <MoveUpMenuItem
          onSelect={() => moveHirePeriod(index, -1)}
          disabled={index === 0}
        />
        <MoveDownMenuItem
          onSelect={() => moveHirePeriod(index, +1)}
          disabled={index === hirePeriods.length - 1}
        />
        {hirePeriod?.isLive ? (
          hirePeriod.liveVersion?.isObsolete ? (
            null // if the live hire period is obsolete, we can't change the state any further
          ) : draftHirePeriod.isObsolete ? (
            <MakeNonObsoleteMenuItem
              onSelect={() => setHirePeriodIsObsolete(draftHirePeriod._id, false)}
            />
          ) : (
            <>
              <MakeObsoleteMenuItem
                disabled={hasStagedChanges}
                onSelect={() => setHirePeriodIsObsolete(draftHirePeriod._id, true)}
              />
              {hasStagedChanges && (
                <DiscardChangesMenuItem
                  onSelect={() => resetToLiveVersion(hirePeriod, intervals)}
                />
              )}
            </>
          )
        ) : (
          <DeleteMenuItem onSelect={() => removeHirePeriod(draftHirePeriod._id)} />
        )}
      </EllipsisMenu>
      <ConfirmDeleteDialog
        heading={t('request.dialog.confirmDeleteHirePeriod.heading')}
        message={t('request.dialog.confirmDeleteHirePeriod.body')}
        {...dialogProps}
      />
    </>
  );
};
