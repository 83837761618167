import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { callAll } from '@deepstream/utils/callAll';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useToaster } from '../../../toast';
import { MutationArgs } from '../../../types';
import { useQuestionnaireTemplateId, useQuestionnaireTemplateState } from './questionnaireTemplateUtils';
import { useQuestionnaireTemplateQueryKey } from './useQuestionnaireTemplate';

export const useQuestionnaireTemplateMutationArgs = ({ name, onSuccess, onError, onSettled }: { name: string } & MutationArgs) => {
  const { t } = useTranslation('preQualification');
  const toaster = useToaster();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const templateId = useQuestionnaireTemplateId();
  // @ts-expect-error ts(2339) FIXME: Property 'isDraft' does not exist on type 'QuestionnaireTemplateStateContextType | undefined'.
  const { isDraft } = useQuestionnaireTemplateState();
  const templateQueryKey = useQuestionnaireTemplateQueryKey({
    templateId,
    currentCompanyId,
    scope: isDraft ? 'draft' : 'current',
  });
  const queryClient = useQueryClient();

  return {
    mutationKey: templateQueryKey,
    onSuccess: callAll(
      onSuccess,
      () => toaster.success(t(`toaster.${name}.success`)),
    ),
    onError: callAll(
      onError,
      () => toaster.error(t(`toaster.${name}.failed`)),
    ),
    onSettled: callAll(
      onSettled,
      () => queryClient.invalidateQueries(templateQueryKey),
    ),
  };
};
