import { useState, useCallback } from 'react';
import * as React from 'react';
import { find, reject, uniqBy } from 'lodash';
import { Text } from 'rebass/styled-components';
import { CompanyMinimized } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { CompanyList } from '../ui/CompanyList';
import { Spinner } from '../ui/Loading';
import { useMutation } from '../useMutation';

type AccessRequestActionsProps = {
  company: any;
  approvedCompanies: any;
  revokeCompanyAccess: any;
  onRevokeStatusChange: any;
};

const AccessRequestActions: React.FC<AccessRequestActionsProps> = ({
  company,
  approvedCompanies,
  revokeCompanyAccess,
  onRevokeStatusChange,
}) => {
  const { t } = useTranslation('companyProfile');
  const [revoke, { status }] = useMutation(revokeCompanyAccess);

  useWatchValue(status, onRevokeStatusChange);

  return (
    find(approvedCompanies, { _id: company._id }) ? (
      status === 'loading' ? (
        <Spinner fontSize={3} />
      ) : status === 'idle' ? (
        <Button
          key="revoke"
          small
          iconLeft="times"
          variant="secondary-outline"
          onClick={() => revoke(company)}
        >
          {t('questionnaire.visibility.revoke')}
        </Button>
      ) : (
        null
      )
    ) : (
      null
    )
  );
};

export const ApprovedCompanyList: React.FC<{ approvedCompanies: any; revokeCompanyAccess: any }> = ({
  approvedCompanies,
  revokeCompanyAccess,
}) => {
  const { t } = useTranslation('companyProfile');
  // When the user revokes a company's access, we store the company here to track all list
  // items that will be removed upon the success of the revoke request
  const [companiesPendingRemoval, setCompaniesPendingRemoval] = useState<CompanyMinimized[]>([]);

  const renderActions = useCallback(
    (company) => (
      <AccessRequestActions
        company={company}
        approvedCompanies={approvedCompanies}
        revokeCompanyAccess={revokeCompanyAccess}
        onRevokeStatusChange={(status: string) => {
          if (status === 'loading') {
            setCompaniesPendingRemoval(companies => uniqBy([company, ...companies], '_id'));
          } else if (status === 'success') {
            setCompaniesPendingRemoval(companies => reject(companies, { _id: company._id }));
          }
        }}
      />
    ),
    [approvedCompanies, revokeCompanyAccess],
  );

  return (
    approvedCompanies.length ? (
      <CompanyList
        companies={approvedCompanies}
        pendingRemovalCompanies={companiesPendingRemoval}
        maxVisibleItems={4.6}
        renderActions={renderActions}
      />
    ) : (
      <Text color="subtext">{t('questionnaire.visibility.noApprovedCompanies')}</Text>
    )
  );
};
