import { find, first, values } from 'lodash';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { documentExchangeTypes } from '@deepstream/common/exchangesConfig';
import {
  DocumentExchangeDefinition,
  DocumentSection,
  Draft,
  ExchangeType,
} from '@deepstream/common/rfq-utils';
import { ExpandablePanelSubSection, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { EditableGridDataProvider, useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';

import * as rfx from '../rfx';
import * as validation from './validation';
import { DocumentsSectionConfigIndicators } from './DocumentsSectionConfigIndicators';
import { DraftPanel } from './DraftPanel';
import { DraftSectionViewPanelHeader } from './DraftSectionViewPanelHeader';
import { SectionConfigHeading } from './SectionConfigHeading';
import * as draft from './draft';
import { useStageName } from './useStageName';
import { Validation } from './validation';
import { ErrorMessage } from '../ui/ErrorMessage';
import { ExchangeDefFieldValueProvider } from '../ExchangeDefFieldValueContext';
import { DocumentExchangeDefsGrid } from '../ui/ExchangeDefsGrid/DocumentExchangeDefsGrid';
import { NO_LOT_PROXY_ID, useLotSelectItems } from './useLotSelectItems';

const ViewSectionConfig = ({ showSectionVisibility }: { showSectionVisibility: boolean }) => {
  const { settings } = rfx.useStructure<Draft>();
  const isSender = rfx.useIsSender();
  const section = rfx.useSectionWithPosition();

  const exchangeDefs = rfx.useSectionExchangeDefs();
  const stageId = section.stages ? section.stages[0] : exchangeDefs[0]?.stages?.[0];
  const stageName = useStageName(stageId);

  const lotSelectItems = useLotSelectItems();
  const selectedLot = settings.areLotsEnabled
    ? find(lotSelectItems, { value: first(section.lotIds) || NO_LOT_PROXY_ID })
    : null;

  return (
    <ExpandablePanelSubSection
      heading={<SectionConfigHeading />}
      renderCollapsedContent={() => (
        <DocumentsSectionConfigIndicators
          isSender={isSender}
          // @ts-expect-error ts(2322) FIXME: Type 'string | number | null' is not assignable to type 'string | number | undefined'.
          stageName={showSectionVisibility ? stageName : null}
          // @ts-expect-error ts(2322) FIXME: Type 'ExchangeProvider | undefined' is not assignable to type 'ExchangeProvider'.
          providedBy={section.providedBy}
          // @ts-expect-error ts(2322) FIXME: Type 'LotSelectItem | null | undefined' is not assignable to type 'LotSelectItem | undefined'.
          selectedLot={selectedLot}
        />
      )}
    />
  );
};

const DocumentsSectionViewPanelContent = () => {
  const section = rfx.useSectionWithPosition<DocumentSection>();
  const showValidationErrors = draft.useShowValidationErrors();
  const { error } = validation.useError('exchangeDefs');

  const {
    rowData: documentExchangeDefs,
  } = useEditableGridData<DocumentExchangeDefinition>();
  const { showSectionVisibility, showExchangeDefVisibility } = rfx.useDocumentSectionVisibilitySettings(documentExchangeDefs);

  return (
    <DraftPanel panelId={section._id}>
      <DraftSectionViewPanelHeader icon="file-text-o" />
      <PanelDivider />
      <ViewSectionConfig showSectionVisibility={showSectionVisibility} />
      <PanelDivider />

      {showValidationErrors && error ? (
        <PanelPadding bg="errorBackground" py="16px">
          <ErrorMessage fontSize={2} error={error} />
        </PanelPadding>
      ) : (
        <DocumentExchangeDefsGrid
          showExchangeDefVisibility={showExchangeDefVisibility}
          isReadOnly
        />
      )}
    </DraftPanel>
  );
};

export const DocumentsSectionViewPanel = () => {
  const { t } = useTranslation('translation');

  const showValidationErrors = draft.useShowValidationErrors();
  const stageId = rfx.useStageId();
  const section = rfx.useSection<DocumentSection>();
  const senderSectionExchangeDefs = rfx.useSectionExchangeDefsByCreator({
    group: 'sender',
  });

  const { isTemplate } = rfx.useState();
  const sectionExchangeDefs = rfx.useSectionExchangeDefs();

  // template exchangeDefs don't have a creatorId so we can't use
  // `useSectionExchangeDefsByCreator`
  const allDocumentExchangeDefs = isTemplate ? sectionExchangeDefs : senderSectionExchangeDefs;

  // in live context, we need to filter out exchangeDefs that belong
  // to other stages if stages are not defined on the section
  const documentExchangeDefs = !first(section.stages) && stageId
    ? allDocumentExchangeDefs.filter(exchangeDef => {
      const exchangeDefStageId = first(exchangeDef.stages);

      // in case the exchangeDef doesn't have a stage ID, it's an
      // 'information' exchange and we show it in all stages
      return !exchangeDefStageId || exchangeDefStageId === stageId;
    })
    : allDocumentExchangeDefs;

  return (
    <Validation
      values={{
        section,
        exchangeDefs: documentExchangeDefs,
      }}
      schema={
        showValidationErrors
          ? yup.object().shape({
              section: yup.object().shape({
                name: yup.string().required(t('general.required')),
              }),
              exchangeDefs: yup
                .array(
                  yup.object().shape({
                    type: yup
                      .string()
                      .oneOf(values(documentExchangeTypes))
                      .required(t('general.required')),
                    category: yup.string().required(t('general.required')), // "description"
                    attachments: yup
                      .array(yup.object().required())
                      .when('type', {
                        is: (type) =>
                          !type ||
                          [
                            ExchangeType.DOCUMENT_REQUEST,
                            ExchangeType.DOCUMENT_REQUEST_LOCKED,
                            ExchangeType.DOCUMENT_REQUEST_CLOSED, // @deprecated
                          ].includes(type),
                        then: (schema) => schema.nullable(),
                        otherwise: (schema) =>
                          schema
                            .min(1, t('general.required'))
                            .required(t('general.required')),
                      }),
                  }),
                )
                .required()
                .min(1, t('request.review.errors.minimumOneRequired')),
            })
          : yup.mixed()
      }
    >
      <ExchangeDefFieldValueProvider>
        <EditableGridDataProvider
          rowData={documentExchangeDefs}
          enableReinitialize
        >
          <GridIdPrefixProvider>
            <GridMenuStateProvider>
              <DocumentsSectionViewPanelContent />
            </GridMenuStateProvider>
          </GridIdPrefixProvider>
        </EditableGridDataProvider>
      </ExchangeDefFieldValueProvider>
    </Validation>
  );
};
