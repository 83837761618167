import { Flex, Box } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { SHOW_CHRISTMAS_LOGO } from '@deepstream/common/constants';
import DeepStreamLogo from '../DeepStreamLogo';
import { DeepStreamLogoXmas } from '../DeepStreamLogoXmas';

import { MainNavWrapper } from './styles';
import DeepStreamIcon from '../DeepStreamIcon';
import { NavbarContainer } from './NavbarContainer';
import { NavLink } from './NavLink';

const ABOUT_US_URL = 'https://www.deep.stream/about';

export const PublicPagesMainNav = () => {
  const { t } = useTranslation(['translation', 'general']);
  const theme = useTheme();

  return (
    <MainNavWrapper style={{ top: 0 }}>
      <NavbarContainer as="nav">
        {/*
         // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'. */}
        <NavLink href={process.env.NX_ONBOARDING_URL} testId="dashboard-nav-button" pl="15px">
          <Box mr="10px">
            {SHOW_CHRISTMAS_LOGO ? (
              <DeepStreamLogoXmas variant="white" />
            ) : (
              <DeepStreamLogo variant="white" />
            )}
          </Box>
        </NavLink>
        <Flex
          flex={1}
          height="100%"
          justifyContent="flex-end"
          alignItems="center"
        >
          <NavLink
            href={ABOUT_US_URL}
            target="_blank"
            testId="about-us-nav-button"
          >
            <Box mr={2}>
              <DeepStreamIcon
                color={theme.colors.white}
                width={18}
                height={18}
              />
            </Box>
            {t('aboutUs', { ns: 'general' })}
          </NavLink>

          <NavLink
            // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
            href={process.env.NX_ONBOARDING_URL}
            target="_blank"
            icon="circle-user"
            iconStyleModifier={{ regular: true }}
            testId="login-nav-button"
            mr={0}
            pr="15px"
          >
            {t('general.logIn', { ns: 'onboarding' })}
          </NavLink>
        </Flex>
      </NavbarContainer>
    </MainNavWrapper>
  );
};

export const RequestFeedbackMainNav = () => {
  const { t } = useTranslation(['translation']);
  const theme = useTheme();

  return (
    <MainNavWrapper style={{ top: 0 }}>
      <NavbarContainer as="nav" justifyContext="space-between" alignItems="center">
        {/*
         // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'. */}
        <NavLink href={process.env.NX_ONBOARDING_URL} testId="dashboard-nav-button" pl="15px">
          <Box mr="10px">
            <DeepStreamIcon
              color={theme.colors.white}
              width={32}
              height={32}
            />
          </Box>
        </NavLink>
        <NavLink
          // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
          href={process.env.NX_ONBOARDING_URL}
          target="_blank"
          icon="arrow-right-to-bracket"
          iconStyleModifier={{ regular: true }}
          testId="login-nav-button"
          mr={0}
          pr="15px"
        >
          {t('general.openDs')}
        </NavLink>
      </NavbarContainer>
    </MainNavWrapper>
  );
};
