import * as React from 'react';
import { SectionType } from '@deepstream/common/rfq-utils';

import { ErrorPanel } from '../../ui/ErrorMessage';
import { ContractBidSection } from './ContractBidSection';
import { useContractSection } from './contract';
import { ContractLineItemBidSection } from './ContractLineItemBidSection';
import { ContractDocumentBidSection } from './ContractDocumentBidSection';

export const BidSection: React.FC = () => {
  const section = useContractSection();

  switch (section.type) {
    case SectionType.LINE_ITEMS:
      return <ContractLineItemBidSection />;
    case SectionType.DOCUMENT:
      return <ContractDocumentBidSection />;
    case SectionType.CONTRACT:
      return <ContractBidSection />;
    default:
      return (
        <ErrorPanel error={`Unknown section type: ${section.type}`} />
      );
  }
};
