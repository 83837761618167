import * as yup from 'yup';
import { TFunction } from 'i18next';

export const fullSchemaGenerator = (dataSchema: yup.ObjectSchema<{ columns: object; data: object }>, t: TFunction, validateAsync?: any) => {
  return yup.object().shape({
    data: validateAsync
      ? yup.array().of(dataSchema.fields.data).test('validateAsync', '', validateAsync)
      : dataSchema,
    columns: dataSchema.fields.columns,
  });
};
