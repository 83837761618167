import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { map, values } from 'lodash';
import { Box, Text, Flex } from 'rebass/styled-components';
import { Draft, ShortTextSchema, ShortTextSchemaType } from '@deepstream/common/rfq-utils';

import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { DisabledInputBox } from '../../ui/Input';
import { CheckboxFieldBase } from '../../form/CheckboxField';
import { FieldContainer } from '../../form/FieldContainer';
import { SelectField } from '../../form/SelectField';
import { TextField } from '../../form/TextField';

const ResponseValidationFields = ({
  fieldName,
  disabled,
}: {
  fieldName: string;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  const schemaTypeItems = useMemo(
    () => map(
      values(ShortTextSchemaType),
      schemaType => ({
        value: schemaType,
        label: t(`request.question.schemaType.${schemaType}`),
      }),
    ),
    [t],
  );

  const [{ value: schema }] = useField<ShortTextSchema<Draft>>(fieldName);

  return (
    <FieldContainer label={t('request.question.responseValidation')}>
      <Flex flexDirection={['column', 'row']}>
        <Box
          mr={[0, 3]}
          mb={[2, 0]}
          flex={1}
          minWidth="100px"
          maxWidth={['100%', '150px']}
        >
          <SelectField
            name={`${fieldName}.type`}
            items={schemaTypeItems}
            disabled={disabled}
          />
        </Box>
        <Box flexShrink={0} mr={[0, 3]} mb={[2, 0]} sx={{ lineHeight: '40px' }}>
          <Text fontSize={2}>
            {t('request.question.between')}
          </Text>
        </Box>
        <Box maxWidth={['100%', '120px']} mr={[0, 3]} mb={[2, 0]}>
          <TextField
            name={`${fieldName}.min`}
            hideLabel
            disabled={disabled}
            format={schema.type === ShortTextSchemaType.INTEGER ? 'integer' : 'number'}
          />
        </Box>
        <Box flexShrink={0} mr={[0, 3]} mb={[2, 0]}>
          <Text fontSize={2} sx={{ lineHeight: '40px' }}>
            {t('general.and')}
          </Text>
        </Box>
        <Box maxWidth={['100%', '120px']}>
          <TextField
            name={`${fieldName}.max`}
            hideLabel
            disabled={disabled}
            format={schema.type === ShortTextSchemaType.INTEGER ? 'integer' : 'number'}
          />
        </Box>
      </Flex>
    </FieldContainer>
  );
};

export const ShortTextField = ({
  fieldName,
  disabled,
}: {
  fieldName?: string;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  const schemaFieldName = fieldName ? `${fieldName}.schema` : 'schema';
  const [{ value: schema },, schemaHelper] = useField<
    ShortTextSchema<Draft> | undefined
  >(schemaFieldName);

  const onHasSchemaChange = useCallback(
    (event) => {
      const newSchema = event.target.checked
        ? { type: ShortTextSchemaType.INTEGER, min: 0, max: 100 }
        : undefined;

      schemaHelper.setValue(newSchema);
      schemaHelper.setTouched(true);
    },
    [schemaHelper],
  );

  return (
    <Stack gap={3}>
      <Flex flexDirection="row" alignItems="center">
        <Box flex={1} mr="24px" maxWidth="500px">
          <DisabledInputBox height="40px">
            {t('request.question.questionType.shortText')}
          </DisabledInputBox>
        </Box>
        <Box flex={1}>
          <CheckboxFieldBase
            value={!!schema}
            fieldLabel={t('request.question.allowANumberOnly')}
            onChange={onHasSchemaChange}
            disabled={disabled}
          />
        </Box>
      </Flex>
      {schema && (
        <ResponseValidationFields fieldName={schemaFieldName} disabled={disabled} />
      )}
    </Stack>
  );
};
