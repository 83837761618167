import { useState } from 'react';
import * as React from 'react';
import { Formik, Form, FormikHelpers, useFormikContext } from 'formik';
import { every, isEmpty, keys, pickBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Text, SxStyleProp } from 'rebass/styled-components';

import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { AwardScenario, ExchangeType } from '@deepstream/common/rfq-utils';
import { Modal, ModalHeader, ModalBody, ModalFooter, CancelButton, SaveButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useModalState } from '../../../ui/useModalState';
import { useLiveRfqStructure } from '../../../useRfq';
import * as rfx from '../../../rfx';
import { useContractData } from '../contract';

import { ImportDetailsPagesList } from './ImportDetailsPagesList';
import { ImportDetailsSectionsList } from './ImportDetailsSectionsList';

import { useImportInitialValues, useExchanges, ImportFormValues, useImportDetails, useVisiblePages, useExchangeDefsToPick } from './utils';
import { ModelSizeLimitDialog, ModelSizeLimitMessages } from '../../../ModelSizeLimitDialog';
import { getSizeRelevantExchangeDefCount, useModelSizeLimits } from '../../../modelSizeLimits';

const TotalSelected = () => {
  const { t } = useTranslation('contracts');
  const { selectedIds, exchangesIds } = useExchanges();

  return (
    <Text>
      {t('details.importRequestContent.totalItemsSelected', {
        count: selectedIds.length,
        total: exchangesIds.length,
      })}
    </Text>
  );
};

const ImportDetailsInfo = () => {
  const { t } = useTranslation('contracts');
  const rfxStructure = rfx.useStructure();
  const pickExchangeDefs = useExchangeDefsToPick();
  const hasAuctionLineItems = React.useMemo(() => {
    const exchanges = pickExchangeDefs();
    return Object.values(exchanges).some(exchange => exchange.type === ExchangeType.AUCTION_LINE_ITEM);
  }, [pickExchangeDefs]);

  let infoTranslationKey: string;

  if (!rfxStructure.meta.outcomeDetails || rfxStructure.meta.outcomeDetails.awardScenario === AwardScenario.REQUEST_LEVEL_AWARD) {
    infoTranslationKey = 'requestLevelAward';
  } else if (rfxStructure.meta.outcomeDetails.awardScenario === AwardScenario.LINE_LEVEL_AWARD) {
    infoTranslationKey = hasAuctionLineItems ? 'lineLevelAward.withAuction' : 'lineLevelAward.withoutAuction';
  } else if (rfxStructure.meta.outcomeDetails.awardScenario === AwardScenario.LOT_LEVEL_AWARD) {
    infoTranslationKey = 'lotLevelAward';
  }

  return (
    <MessageBlock variant="info" m={3}>
      <Text sx={{ whiteSpace: 'pre-line' }}>
        {/*
         // @ts-expect-error ts(2454) FIXME: Variable 'infoTranslationKey' is used before being assigned. */}
        {t(`details.importRequestContent.modalInfo.${infoTranslationKey}`)}
      </Text>
    </MessageBlock>
  );
};

const ImportDetailsSaveButton = () => {
  const { values } = useFormikContext<ImportFormValues>();
  const noneChecked = every(values, value => value === false);

  return (
    <SaveButton disabled={noneChecked} />
  );
};

const ImportDetailsModalContent = ({
  onSubmit,
  onClose,
}: {
  onSubmit: (values: ImportFormValues, formikHelpers: FormikHelpers<ImportFormValues>) => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation('contracts');
  const visiblePages = useVisiblePages();
  const initialValues = useImportInitialValues();
  const [selectedPageId, setSelectedPageId] = useState<string>(visiblePages[0]?._id);

  return (
    <Formik<ImportFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <Form>
        <ModalHeader>{t('details.importRequestContent.modalHeading')}</ModalHeader>
        <ModalBody sx={{ width: '700px' }} p={0}>
          <ImportDetailsInfo />
          {!isEmpty(visiblePages) && (
            <Flex sx={{ alignItems: 'stretch', borderTop: 'lightGray' }}>
              <Box sx={{ flex: '0 0 30%', borderRight: 'lightGray2' }}>
                <ImportDetailsPagesList
                  selectedPageId={selectedPageId}
                  onPageChange={setSelectedPageId}
                />
              </Box>
              <Box sx={{ flex: '0 0 70%' }}>
                <ImportDetailsSectionsList selectedPageId={selectedPageId} />
              </Box>
            </Flex>
          )}
        </ModalBody>
        <ModalFooter>
          <TotalSelected />
          <Box sx={{ marginLeft: 'auto' }}>
            <CancelButton onClick={onClose} />
            <ImportDetailsSaveButton />
          </Box>
        </ModalFooter>
      </Form>
    </Formik>
  );
};

const ImportDetailsModal = ({
  onClose,
  onSubmit,
}: {
  onClose: () => void;
  onSubmit: (values: ImportFormValues, formikHelpers: FormikHelpers<ImportFormValues>) => void;
}) => {
  const { requestId, recipients } = useContractData();
  const currentCompanyId = useCurrentCompanyId();

  const { data: rfxStructure } = useLiveRfqStructure({
    rfqId: requestId,
    recipientId: recipients[0]._id,
    // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string | undefined'.
    currentCompanyId,
  });

  if (!rfxStructure) return null;

  return (
    <rfx.StructureProvider structure={rfxStructure}>
      <Modal isOpen>
        <ImportDetailsModalContent onSubmit={onSubmit} onClose={onClose} />
      </Modal>
    </rfx.StructureProvider>
  );
};

export const ImportDetails = ({
  disabled,
  sx,
}: {
  disabled?: boolean;
  sx?: SxStyleProp;
}) => {
  const { requestSubject, recipients, exchangeDefById } = useContractData();
  const importDetailsModal = useModalState();
  const { t } = useTranslation('contracts');
  const onSubmit = useImportDetails();
  const { maxExchangeDefCount } = useModelSizeLimits();
  const modelSizeLimitModal = useModalState();
  const [modelSizeLimitMessages, setModelSizeLimitMessages] = React.useState<ModelSizeLimitMessages | null>(null);

  const handleSubmit = (values) => {
    const exchanges = pickBy(values, Boolean);
    const exchangeIds = keys(exchanges);
    const existingExchangeDefCount = getSizeRelevantExchangeDefCount(exchangeDefById);

    if (existingExchangeDefCount + exchangeIds.length > maxExchangeDefCount) {
      setModelSizeLimitMessages({
        heading: t('dialog.contractSizeLimit.heading'),
        title: t('dialog.contractSizeLimit.importPreviousItems.title'),
        warning: t('dialog.contractSizeLimit.importPreviousItems.warning'),
        body: t('dialog.contractSizeLimit.importPreviousItems.body', {
          count: existingExchangeDefCount + exchangeIds.length - maxExchangeDefCount,
        }),
      });
      modelSizeLimitModal.open();
    } else {
      return onSubmit({
        exchangeIds,
        onSettled: [() => importDetailsModal.close()],
      });
    }
  };

  return (
    <>
      <Tooltip
        placement="left"
        content={(
          <Box lineHeight={1.3} fontSize={1}>
            <Text mb={2}>{t('details.importRequestContent.info.title')}</Text>
            <Text mb={1}>
              {t('details.importRequestContent.info.linkedRequest')}
              <Text fontWeight={700}>{requestSubject}</Text>
            </Text>
            <Text>
              {t('details.importRequestContent.info.awardedSupplier')}
              <Text fontWeight={700}>{recipients[0].name}</Text>
            </Text>
          </Box>
        )}
      >
        <Button
          variant="primary-outline"
          iconLeft="file-import"
          px={3}
          onClick={importDetailsModal.open}
          disabled={disabled}
          sx={sx}
        >
          {t('details.importRequestContent.buttonLabel')}
        </Button>
      </Tooltip>
      {importDetailsModal.isOpen && (
        <ImportDetailsModal
          onClose={importDetailsModal.close}
          onSubmit={handleSubmit}
        />
      )}
      {modelSizeLimitModal.isOpen && (
        <ModelSizeLimitDialog
          modal={modelSizeLimitModal}
          messages={modelSizeLimitMessages}
        />
      )}
    </>
  );
};
