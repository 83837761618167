import * as React from 'react';
import * as yup from 'yup';
import { ChangeType, AssetFieldsUpdatedChange, Vessel } from '@deepstream/common/rfq-utils';
import { noop } from 'lodash';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { Box, Text } from 'rebass/styled-components';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { callAll } from '@deepstream/utils/callAll';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { ModalHeader, ModalBody, ModalFooter, CancelButton, SaveButton, ModalProps, Modal } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useToaster } from './toast';
import { useApi } from './api';
import { useCurrentCompanyId } from './currentCompanyId';
import { useMutation } from './useMutation';
import * as rfx from './rfx';
import { useLiveRfqStructureQueryKey, useRecipientId, useRfqId } from './useRfq';
import { TextField } from './form/TextField';
import { SwitchField } from './form/SwitchField';
import { Fieldset, Legend } from './form/Field';
import { useHaveDeadlinesPassed } from './deadline';

type EditVesselBidDeliveryAddressModalProps =
  ModalProps & {
    vessel: Vessel;
    isOpen: boolean;
    onClose: () => void;
  };

export const EditVesselBidDeliveryAddressModal: React.FC<EditVesselBidDeliveryAddressModalProps> = ({
  vessel,
  onClose = noop,
  ...props
}) => {
  const { t } = useTranslation();
  const api = useApi();
  const recipientId = useRecipientId();
  const rfqId = useRfqId();
  const section = rfx.useSection();
  const queryClient = useQueryClient();
  const currentCompanyId = useCurrentCompanyId()!;
  const toaster = useToaster();
  const sectionId = section?._id;
  const pagePermissions = rfx.usePagePermissions();
  const isBidding = rfx.useIsBidding();
  const haveDeadlinesPassed = useHaveDeadlinesPassed();
  const hasPassedDeadline = haveDeadlinesPassed && !vessel.extendedStageId;
  const isReadOnly = (
    hasPassedDeadline ||
    !pagePermissions.canRespond ||
    !isBidding
  );

  const rfqStructureQueryKey = useLiveRfqStructureQueryKey({
    currentCompanyId,
    rfqId,
    recipientId,
  });

  const initialValues: any = {
    deliveryPort: vessel.deliveryLocation?.port,
    redeliveryPort: vessel.redeliveryLocation?.port,
    sameRedeliveryAsDelivery: vessel.deliveryLocation?.port === vessel.redeliveryLocation?.port,
  };

  const validationSchema = yup.object().shape({
    deliveryPort: yup.string().trim(),
    redeliveryPort: yup.string().trim(),
  });

  const [updateBidAssets] = useMutation(
    api.updateBidAssets,
    {
      onSuccess: () => toaster.success(t('request.toaster.changesSavedSuccess')),
      onError: () => toaster.error(t('request.toaster.changesSavedError')),
      onSettled: callAll(
        () => queryClient.invalidateQueries(rfqStructureQueryKey),
        onClose,
      ),
    },
  );

  const getAssetsFieldUpdatedChange = (deliveryPort, redeliveryPort, sameRedeliveryAsDelivery): AssetFieldsUpdatedChange => ({
    type: ChangeType.ASSET_FIELDS_UPDATED,
    assetId: vessel._id,
    assetName: vessel.name,
    sectionName: sectionId,
    companyId: currentCompanyId,
    fields: {
      deliveryLocation: { port: deliveryPort },
      redeliveryLocation: { port: sameRedeliveryAsDelivery ? deliveryPort : redeliveryPort },
    } as any,
  });

  return (
    <Modal {...props} >
      <Formik
        initialValues={initialValues}
        validateOnBlur
        validationSchema={validationSchema}
        onSubmit={({ deliveryPort, redeliveryPort, sameRedeliveryAsDelivery }) => {
          updateBidAssets({
            rfqId,
            recipientId: currentCompanyId,
            companyId: currentCompanyId,
            sectionId,
            changes: [
              getAssetsFieldUpdatedChange(deliveryPort, redeliveryPort, sameRedeliveryAsDelivery),
            ],
          });
        }}
      >
        {({ isSubmitting, values, isValid, setFieldValue }) => {
          const toggleRedeliveryPort = (checked) => {
            setFieldValue('redeliveryPort', checked ? values.deliveryPort : '', !checked);
          };

          return (
            <Form>
              <ModalHeader onClose={onClose}>
                {t('request.vesselPricing.editDelivery')}
              </ModalHeader>
              <ModalBody>
                {isReadOnly ? (
                  <Stack gap={3}>
                    <Box>
                      <Legend> {t('request.vesselPricing.deliveryLocation')} </Legend>
                      <Text> {initialValues.deliveryPort ?? <EmDash />} </Text>
                    </Box>
                    <Box>
                      <Legend> {t('request.vesselPricing.redeliveryLocation')} </Legend>
                      <Text> {initialValues.redeliveryPort ?? <EmDash />} </Text>
                    </Box>
                    {hasPassedDeadline ? (
                      <MessageBlock variant="info" mt={0}>
                        {t('request.vesselPricing.errors.deadlineHasPassed')}
                      </MessageBlock>
                    ) : pagePermissions.readOnly ? (
                      <MessageBlock variant="info" mt={0}>
                        {t('request.vesselPricing.errors.readOnly')}
                      </MessageBlock>
                    ) : null}
                  </Stack>
                ) : (
                  <Stack gap={3}>
                    <Fieldset>
                      <Stack gap={3}>
                        <Legend>
                          {t('request.vesselPricing.deliveryLocation')}
                        </Legend>
                        <TextField
                          name="deliveryPort"
                          label={t('request.vesselPricing.port')}
                          required
                        />
                      </Stack>
                    </Fieldset>
                    <Fieldset>
                      <Stack gap={3}>
                        <Legend>
                          {t('request.vesselPricing.redeliveryLocation')}
                        </Legend>
                        <SwitchField
                          label={t('request.vesselPricing.sameAsDeliveryLocation')}
                          name="sameRedeliveryAsDelivery"
                          onChange={toggleRedeliveryPort}
                        />
                        {!values.sameRedeliveryAsDelivery && (
                          <TextField
                            label={t('request.vesselPricing.port')}
                            name="redeliveryPort"
                            required={!values.sameRedeliveryAsDelivery}
                          />
                        )}
                      </Stack>
                    </Fieldset>
                  </Stack>
                )}
              </ModalBody>
              <ModalFooter>
                <CancelButton onClick={onClose} />
                <SaveButton label={t('general.save')} disabled={isSubmitting || !isValid || isReadOnly} />
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
