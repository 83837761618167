import { useTranslation } from 'react-i18next';
import { Heading, Text } from 'rebass/styled-components';
import { Attachment } from '@deepstream/common/rfq-utils';
import { isEmpty } from 'lodash';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { RfqAttachmentList } from './ExchangeModal/AttachmentLink';
import { RfqIdProvider } from './useRfq';

// NB this shows the message and the attachments of legacy
// requests. New requests don't have these properties.

export const BidAward = ({
  isBuyer,
  message,
  attachments,
  rfqId,
}: {
  isBuyer?: boolean;
  message?: string;
  attachments?: Attachment[];
  rfqId: string;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {!isBuyer && (
        <Heading as="h1" fontSize={6} lineHeight="normal" fontWeight="500" mb={4}>
          <Icon icon="trophy" mr={2} /> {t('request.bidAward.bidAward')}
        </Heading>
      )}
      <Panel>
        <PanelHeader heading={t('request.bidAward.bidAward')} />
        {message || !isEmpty(attachments) ? (
          <PanelDivider />
        ) : (
          null
        )}
        <PanelPadding>
          {message && (
            <>
              <Heading as="h3" mb={2}>
                {isBuyer ? t('request.bidAward.message') : t('request.bidAward.messageFromBuyer')}
              </Heading>
              <Text>
                {message}
              </Text>
            </>
          )}
          {attachments && !isEmpty(attachments) && (
            <>
              <Heading as="h3" mb={2} mt={4}>
                {isBuyer ? t('request.bidAward.attachments') : t('request.bidAward.attachmentsFromBuyer')}
              </Heading>
              <RfqIdProvider rfqId={rfqId}>
                <RfqAttachmentList attachments={attachments} />
              </RfqIdProvider>
            </>
          )}
        </PanelPadding>
      </Panel>
    </>
  );
};
