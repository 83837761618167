import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import { useReportingNavigation } from '../../AppRouting';
import * as Layout from '../../Layout';
import { useCurrentUser } from '../../useCurrentUser';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useApi, wrap } from '../../api';
import { ErrorPanel } from '../../ui/ErrorMessage';
import { LoadingPanel } from '../../ui/Loading';
import { SelectedCompanyIdsContext } from '../../selectedCompanyIds';
import { useSelectParentChildCompanies } from '../../useSelectParentChildCompanies';
import { SelectParentAndChildCompanies } from '../../SelectParentAndChildCompanies';
import { Company } from '../../ui/types';

const InactiveReportingLayout = ({
  selectedTabId,
  tabs,
  inactiveStatus,
}: {
  selectedTabId: 'requests' | 'contracts';
  tabs: {
    id: string;
    name: string;
    navigate: () => void;
  }[];
  inactiveStatus: 'loading' | 'error' | null;
}) => {
  const { t } = useTranslation('translation');

  return (
    <Layout.HeaderAndContent
      header={
        <Layout.PageHeader
          heading={t('pageHeader.reporting')}
          icon="chart-bar"
          tabs={tabs}
          selectedTabId={selectedTabId}
        />
      }
      content={inactiveStatus === 'loading' ? (
        <LoadingPanel />
      ) : inactiveStatus === 'error' ? (
        <ErrorPanel error={t('errors.unexpected')} />
      ) : (
        null
      )}
    />
  );
};

const ActiveReportingLayout = ({
  selectedTabId,
  tabs,
  currentCompany,
  childCompanies,
  children,
  isSuperUserWithReportingAccess,
} : {
  selectedTabId;
  tabs;
  currentCompany: Company;
  childCompanies?: { _id: string; name: string; isPending?: boolean }[];
  children;
  isSuperUserWithReportingAccess: boolean;
}) => {
  const { t } = useTranslation(['translation', 'reporting']);
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();

  const selectParentChildCompanies = useSelectParentChildCompanies({
    storageKey: `${currentCompanyId}.${currentUser._id}.selectedParentChildCompanyIds`,
    parentCompany: currentCompany,
    childCompanies,
  });

  const selectedCompanyIds = useMemo(() => {
    if (isEmpty(selectParentChildCompanies.items)) {
      return [currentCompanyId];
    } else if (isEmpty(selectParentChildCompanies.selectedItems)) {
      return selectParentChildCompanies.items.map(item => item._id);
    } else {
      return selectParentChildCompanies.selectedItems.map(item => item._id);
    }
  }, [selectParentChildCompanies.items, selectParentChildCompanies.selectedItems, currentCompanyId]);

  return (
    <Layout.HeaderAndContent
      header={
        <Layout.PageHeader
          heading={t('pageHeader.reporting')}
          icon="chart-bar"
          tabs={tabs}
          selectedTabId={selectedTabId}
        >
          {isSuperUserWithReportingAccess && selectParentChildCompanies.items.length > 1 && currentCompany && childCompanies && (
            <Flex>
              <Text mt="5px" mr={2} fontSize={1} fontWeight={500} lineHeight={1.5}>
                {t('showDataFor', { ns: 'reporting' })}
              </Text>
              <SelectParentAndChildCompanies
                {...selectParentChildCompanies}
              />
            </Flex>
          )}
        </Layout.PageHeader>
      }
      content={
        <SelectedCompanyIdsContext.Provider value={selectedCompanyIds}>
          {children}
        </SelectedCompanyIdsContext.Provider>
      }
    />
  );
};

export const ReportingLayout = ({
  selectedTabId,
  children,
}: {
  selectedTabId: 'requests' | 'contracts';
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation('translation');
  const { navigateToContractsReporting, navigateToRequestReportings } = useReportingNavigation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const api = useApi();

  const roles = currentUser.roles[currentCompanyId];

  const isSuperUserWithReportingAccess = (
    roles && roles.admin && (roles.accessReportingForRequests || roles.accessReportingForContracts)
  );

  const { data: currentCompany, status: currentCompanyStatus } = useQuery(
    ['publicCompany', { companyId: currentCompanyId }],
    wrap(api.getPublicCompany),
  );

  const { data: childCompanies, status: childCompaniesStatus } = useQuery(
    ['childCompanies', { currentCompanyId }],
    wrap(api.getChildCompanies),
    { enabled: isSuperUserWithReportingAccess },
  );

  const tabs = useMemo(
    () => [{
      id: 'requests',
      name: t('general.request_other'),
      navigate: () => navigateToRequestReportings(),
    }, {
      id: 'contracts',
      name: t('general.contract_other'),
      navigate: () => navigateToContractsReporting(),
    }],
    [navigateToContractsReporting, navigateToRequestReportings, t],
  );

  const inactiveStatus = currentCompanyStatus === 'loading' || childCompaniesStatus === 'loading' ? (
    'loading'
  ) : currentCompanyStatus === 'error' || childCompaniesStatus === 'error' ? (
    'error'
  ) : (
    null
  );

  return currentCompany && (!isSuperUserWithReportingAccess || (!inactiveStatus && childCompanies)) ? (
    <ActiveReportingLayout
      selectedTabId={selectedTabId}
      tabs={tabs}
      currentCompany={currentCompany}
      childCompanies={childCompanies}
      isSuperUserWithReportingAccess={isSuperUserWithReportingAccess}
    >
      {children}
    </ActiveReportingLayout>
  ) : (
    <InactiveReportingLayout
      selectedTabId={selectedTabId}
      tabs={tabs}
      inactiveStatus={inactiveStatus}
    />
  );
};
