import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Text } from 'rebass/styled-components';
import { CellProps } from 'react-table';
import { RfqStatus, UniversalBidStatus, requestStatusesConfig, universalBidStatusesConfig } from '@deepstream/common/rfq-utils';
import { Pictogram } from '@deepstream/ui-kit';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { DateFormat } from '@deepstream/utils';
import { useDeadline } from '../../../deadline';
import { Datetime2 } from '../../../Datetime';
import { TruncateCell } from '../../../TruncateCell';
import { SentRequestOverview } from '../../../types';
import { Bold } from '../../../Bold';
import { BasicTableStyles } from '../../../TableStyles';
import { Table } from '../../../Table';
import { useNavigate } from '../../../tanstackRouter';
import { requestSentSuppliersViewRoute } from '../../../AppRouting';

const displayedStatuses = [
  UniversalBidStatus.NO_RESPONSE,
  UniversalBidStatus.ACTIVE,
  UniversalBidStatus.AWARDED,
];

export const CompanyProfileRequestsActivity = ({
  requests,
  currentCompanyId,
  companyId,
}: {
  requests: SentRequestOverview[],
  currentCompanyId: string,
  companyId: string,
}) => {
  const { t } = useTranslation(['translation', 'companyProfile']);
  const navigate = useNavigate();

  const totalItems = requests.length;

  const bidStatusCountMap = useMemo(
    () => requests
      .reduce((acc, { universalStatusesByRecipientId }) => {
        const bidStatus = universalStatusesByRecipientId[companyId];
        // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
        acc[bidStatus] = (acc[bidStatus] || 0) + 1;
        return acc;
      }, {}),
    [requests, companyId],
  );

  const stats = useMemo(
    () => displayedStatuses
      .map(
        status => ({
          icon: universalBidStatusesConfig[status].icon,
          name: t(`request.universalBidStatus.${status}`),
          value: bidStatusCountMap[status],
        }),
      ),
    [bidStatusCountMap, t],
  );

  const columns = useMemo(
    () => [
      {
        id: 'name',
        Header: t('activity.name', { ns: 'companyProfile' }),
        accessor: 'sentDashboard.subject',
        Cell: TruncateCell,
      },
      {
        id: 'status',
        Header: t('activity.status', { ns: 'companyProfile' }),
        accessor: 'meta.extendedStatus',
        Cell: RequestStatusCell,
      },
      {
        id: 'bidStatus',
        Header: t('activity.bidStatus', { ns: 'companyProfile' }),
        accessor: (request: SentRequestOverview) => {
          return request.universalStatusesByRecipientId[companyId];
        },
        Cell: BidStatusCell,
      },
      {
        id: 'currentDeadline',
        Header: t('activity.currentDeadline', { ns: 'companyProfile' }),
        accessor: (request: SentRequestOverview) => {
          return request.currentDeadlineByRecipientId[companyId];
        },
        Cell: CurrentDeadlineCell,
      },
    ],
    [companyId, t],
  );

  return (
    <Panel>
      <PanelHeader
        heading={
          <Flex alignItems="center">
            <Text>{t('activity.requests', { ns: 'companyProfile' })}</Text>
            <Tooltip content={t('activity.tooltip.requests', { ns: 'companyProfile' })}>
              <Icon icon="info-circle" mx={2} fontSize={2} />
            </Tooltip>
          </Flex>
        }
      >
        <Flex sx={{ columnGap: '24px' }}>
          {stats.map(stat => (
            <Box key={stat.name} sx={{ textAlign: 'center' }}>
              <Text fontSize={1} fontWeight={500} mb={1}>
                {stat.name}
              </Text>
              <Flex alignItems="center" fontSize={2}>
                {/*
                 // @ts-expect-error ts(18048) FIXME: 'stat.icon' is possibly 'undefined'. */}
                <Icon fixedWidth icon={stat.icon.value as IconProps['icon']} color={stat.icon.color} mr={1} />
                <Bold>
                  {stat.value ?? 0}
                </Bold>
              </Flex>
            </Box>
            ))}
        </Flex>
      </PanelHeader>
      <PanelDivider />
      {totalItems > 0 ? (
        <BasicTableStyles>
          <Table
            columns={columns}
            data={requests}
            isPaginated
            enforcePagination
            isSortable
            smallPageControls
            onRowClick={request => {
              navigate({
                to: requestSentSuppliersViewRoute.to,
                params: { currentCompanyId, rfqId: request._id },
              });
            }}
          />
        </BasicTableStyles>
      ) : (
        <Flex height="350px">
          <PanelPadding>
            <Pictogram
              variant="empty-state"
              label={t('activity.noSentRequests', { ns: 'companyProfile' })}
              labelPosition="right"
            />
          </PanelPadding>
        </Flex>
      )}
    </Panel>
  );
};

const RequestStatusCell = ({ value }: CellProps<SentRequestOverview, RfqStatus>) => {
  const { t } = useTranslation();
  const icon = requestStatusesConfig[value]?.icon;

  return (
    <IconText
      icon={icon.value}
      iconColor={icon.color}
      text={t(`request.status.${value}`)}
    />
  );
};

const BidStatusCell = ({ value }: CellProps<SentRequestOverview, RfqStatus>) => {
  const { t } = useTranslation();
  const icon = universalBidStatusesConfig[value]?.icon;

  return (
    <IconText
      // @ts-expect-error ts(18048) FIXME: 'icon' is possibly 'undefined'.
      icon={icon.value as IconProps['icon']}
      // @ts-expect-error ts(18048) FIXME: 'icon' is possibly 'undefined'.
      iconColor={icon.color}
      text={t(`request.universalBidStatus.${value}`)}
    />
  );
};

const CurrentDeadlineCell = ({ value: deadline }: CellProps<SentRequestOverview, Date | null | undefined>) => {
  const { t } = useTranslation();

  const { hasPassed } = useDeadline({
    // @ts-expect-error ts(2322) FIXME: Type 'Date | null | undefined' is not assignable to type 'Date | undefined'.
    deadline,
    refreshRateMs: 500,
  });

  return (
    <>
      {!deadline ? (
        <Text as="span">
          {t('general.notApplicableShort')}
        </Text>
      ) : hasPassed ? (
        <Text as="span">
          {t('request.deadline.passed')}
        </Text>
      ) : (
        <Datetime2 value={deadline} format={DateFormat.DD_MMM_YYYY} />
      )}
    </>
  );
};
