import * as React from 'react';
import styled from 'styled-components';
import { Text, Box, Flex, FlexProps } from 'rebass/styled-components';
import { isEmpty, noop } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { Button, EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Panel, PanelText, PanelHeading, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { DropdownMenuItem, EllipsisMenu } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { StructuredTags } from '../../../ui/StructuredTags';
import { PublicDocuments } from '../../../ui/PublicDocuments';
import { Company, Document } from '../../../ui/types';
import { SignUpLink } from '../../../ui/SignUpLink';
import { Show } from '../../../ui/Show';
import { DeliveryCountries } from '../../../ui/DeliveryCountries';
import { createDeprecatedProductTagTree } from '../../../utils';
import { useSystemFeatureFlags } from '../../../systemFeatureFlags';
import { HelpCenterInlineLink } from '../../../HelpCenterLink';
import { useEnv } from '../../../env';
import { ViewProductsPanel } from '../../../ViewProductsPanel';
import { CallToActionPanel } from '../../../ui/CallToActionPanel';

const BulletedList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-position: inside;
`;

const PublicDocumentsCta: React.FC<FlexProps> = (props) => {
  return (
    <Flex {...props}>
      <Text fontSize={2}>
        <Trans i18nKey="company.createAFreeAccount">
          <SignUpLink style={{ display: 'inline', fontWeight: 500, color: 'primary' }} />
          {' '}
        </Trans>
      </Text>
    </Flex>
  );
};

const SectionPlaceholder: React.FC<{ placeholder: string }> = ({ placeholder }) => {
  const theme = useTheme();

  return (
    <PanelText style={{ color: theme.colors.subtext }}>
      {placeholder}
    </PanelText>
  );
};

type SectionPanelProps = {
  title: string;
  placeholder: string;
  actionButton: React.ReactElement | null;
  dataTest?: string;
  children: React.ReactNode;
};

const SectionPanel = ({ title, placeholder, actionButton, dataTest, children }: SectionPanelProps) => (
  <Panel mb={20} data-test={dataTest}>
    <PanelHeader heading={title}>
      {actionButton}
    </PanelHeader>
    <PanelDivider />
    {children || <SectionPlaceholder placeholder={placeholder} />}
  </Panel>
);

type CompanyProfileGeneralContentProps = {
  company: Company;
  currentCompanyId: string;
  documents: Document[];
  isLoggedIn?: boolean;
  canEdit?: boolean;
  downloadDocument?: (document: Document) => void;
  openDescriptionModal?: () => void;
  openProductsModal?: () => void;
  openDeprecatedProductsModal?: () => void;
  openLocationsModal?: () => void;
  navigateToDocumentLibrary?: () => void;
  regionTags: any[];
};

export const CompanyProfileGeneralContent: React.FC<CompanyProfileGeneralContentProps> = ({
  company,
  currentCompanyId,
  documents,
  downloadDocument,
  isLoggedIn,
  canEdit,
  openDescriptionModal,
  openProductsModal,
  openDeprecatedProductsModal = noop,
  openLocationsModal,
  navigateToDocumentLibrary,
  regionTags,
}) => {
  const { t } = useTranslation();
  const systemFeatureFlags = useSystemFeatureFlags();
  const { ONBOARDING_URL } = useEnv();

  const deprecatedTags = React.useMemo(
    () => createDeprecatedProductTagTree(company.products),
    [company],
  );
  const isTeamMember = React.useMemo(
    () => company._id === currentCompanyId,
    [company._id, currentCompanyId],
  );

  const navigateToUpdateProductTagsWizard = () => {
    window.location.href = `${ONBOARDING_URL}/company/${currentCompanyId}/update-product-tags`;
  };

  const hasSelectedNewProducts = !isEmpty(company.productsAndServices);
  const showNewProductsPanel = systemFeatureFlags && (
    !deprecatedTags.length ||
    systemFeatureFlags?.productTagMigrationDone ||
    hasSelectedNewProducts
  );

  return (
    <>
      <SectionPanel
        title={t('company.description')}
        placeholder={t('company.descriptionPlaceholder')}
        actionButton={canEdit || isTeamMember ? (
          <EditButton small onClick={openDescriptionModal} disabled={!canEdit} />
        ) : (
          null
        )}
      >
        {company.statement && (
          <PanelText style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
            {company.statement}
          </PanelText>
        )}
      </SectionPanel>

      {!isLoggedIn && (
        <CallToActionPanel
          heading={t('company.viewProfile', { companyName: company.name })}
          mb={3}
        >
          <Text fontSize={2}>
            {t('company.joinNetwork')}
          </Text>
        </CallToActionPanel>
      )}

      <SectionPanel
        title={t('productsAndServices.productsAndServices')}
        placeholder={t('company.productsAndServicesPlaceholder')}
        dataTest="products-and-services-panel"
        actionButton={systemFeatureFlags && (canEdit || isTeamMember) ? (
          !deprecatedTags.length || systemFeatureFlags?.productTagMigrationDone ? (
            <EditButton
              small
              onClick={openProductsModal}
              disabled={!canEdit}
            />
          ) : hasSelectedNewProducts ? (
            <ButtonGroup marginBetween="-1px">
              <EditButton
                small
                onClick={openProductsModal}
                disabled={!canEdit}
              />
              <EllipsisMenu small disabled={!canEdit}>
                <DropdownMenuItem icon="history" onSelect={openDeprecatedProductsModal}>
                  {t('productsAndServices.viewOldTags')}
                </DropdownMenuItem>
              </EllipsisMenu>
            </ButtonGroup>
          ) : (
            <Button
              small
              iconLeft="refresh"
              variant="primary-outline"
              disabled={!canEdit}
              onClick={navigateToUpdateProductTagsWizard}
            >
              {t('productsAndServices.updateTags')}
            </Button>
          )
        ) : (
          null
        )}
      >
        <PanelPadding
          height={showNewProductsPanel && !hasSelectedNewProducts ? '' : '428px'}
          overflowY="auto"
        >
          {showNewProductsPanel ? (
            hasSelectedNewProducts ? (
              <ViewProductsPanel productsAndServices={company.productsAndServices} />
            ) : (
              <PanelText color="subtext" p={0}>
                {t('productsAndServices.noProductsAndServices')}
              </PanelText>
            )
          ) : (
            <>
              <MessageBlock
                fontSize={2}
                variant={isTeamMember || canEdit ? 'warn' : 'info'}
                mt={0}
                mb="20px"
              >
                {isTeamMember || canEdit ? (
                  t('productsAndServices.deprecationWarningBeforeMigration')
                ) : (
                  t('productsAndServices.deprecationInfoBeforeMigration')
                )}
                {' '}
                <HelpCenterInlineLink path="/en/articles/6337679-search-for-new-suppliers-using-product-services-tags">
                  {t('general.moreInfo')}
                </HelpCenterInlineLink>
              </MessageBlock>
              <StructuredTags tags={deprecatedTags} mb={-3} />
            </>
          )}
        </PanelPadding>
      </SectionPanel>

      <SectionPanel
        title={t('company.locations')}
        placeholder={t('company.locationsPlaceholder')}
        actionButton={canEdit || isTeamMember ? (
          <EditButton small onClick={openLocationsModal} disabled={!canEdit} />
        ) : (
          null
        )}
      >
        {company.deliveryCountries?.length && (
          <DeliveryCountries
            deliveryCountries={company.deliveryCountries}
            regionTags={regionTags}
          />
        )}
      </SectionPanel>

      <Panel mb={3}>
        <Show xlarge>
          <Flex alignItems="center" justifyContent="space-between">
            <PanelHeading>
              {t('company.publicDocuments')}
            </PanelHeading>
            {!isLoggedIn && <PublicDocumentsCta py={20} pr={20} />}
            {isTeamMember && (
              <Button small variant="primary-outline" iconLeft="file" onClick={navigateToDocumentLibrary} mr={3}>
                {t('company.openDocuments')}
              </Button>
            )}
          </Flex>
        </Show>
        <Show small medium large>
          <Box p={20}>
            <PanelHeading p={0} mb={2}>
              {t('company.publicDocuments')}
            </PanelHeading>
            {!isLoggedIn && <PublicDocumentsCta />}
            {isTeamMember && (
              <Button small variant="primary-outline" iconLeft="file" onClick={navigateToDocumentLibrary} mr={3}>
                {t('company.openDocuments')}
              </Button>
            )}
          </Box>
        </Show>
        <PanelDivider />
        {documents && documents.length ? (
          <PublicDocuments documents={documents} isLoggedIn={isLoggedIn} downloadDocument={downloadDocument} />
        ) : (
          <SectionPlaceholder placeholder={t('company.publicDocumentsPlaceholder')} />
        )}
      </Panel>

      {!isLoggedIn && (
        <CallToActionPanel heading={t('company.createAnAccount')}>
          <Text fontFamily="primary" fontSize={2} lineHeight={1.75}>
            <BulletedList>
              <li>{t('company.getStaffDetails')}</li>
              <li>{t('company.viewComplianceData')}</li>
              <li>{t('company.createOwnProfile')}</li>
            </BulletedList>
          </Text>
        </CallToActionPanel>
      )}
    </>
  );
};
