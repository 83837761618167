import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Box, Text } from 'rebass/styled-components';
import { Field } from '@deepstream/common/exchangesConfig';
import { FieldData, getRevisionChangesTableData } from '@deepstream/common/rfq-utils/exchangeAction';
import { ExchangeSnapshot } from '../types';
import { RfqAttachmentList } from './AttachmentLink';

interface BulletinRevisionChangesProps {
  action: any;
  exchange: ExchangeSnapshot;
  changedFields: Field[];
}

const Change = ({ newValue }: FieldData) => {
  if (Array.isArray(newValue)) {
    if (isEmpty(newValue)) {
      return null;
    }
    return <RfqAttachmentList attachments={newValue} mt={1} iconColor="subtext" width="100%" />;
  }

  return (
    <Text sx={{ whiteSpace: 'pre-wrap', borderRadius: '4px' }} fontSize={1} lineHeight={1.4} mt={1} px={2} py={1} width="fit-content" bg="lightGray2">
      {newValue}
    </Text>
  );
};

export const BulletinRevisionChanges = ({ action, exchange, changedFields }: BulletinRevisionChangesProps) => {
  const { t } = useTranslation();

  const data: FieldData[] = useMemo(
    () => getRevisionChangesTableData(action, exchange, changedFields, t),
    [action, exchange, changedFields, t],
  );

  return (
    <Box pt={1}>
      {data.map((props, index) => (
        <Change {...props} isFirst={index === 0} key={String(index)} />
      ))}
    </Box>
  );
};
