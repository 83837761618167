import { find } from 'lodash';
import { useMemo } from 'react';
import { RfxStructure } from '../../../types';

/**
 * Hook to get the page on which the exchange with the provided ID
 * is located.
 */
export const useExchangeModalPage = (
  structure: RfxStructure,
  exchangeId?: string,
) => useMemo(() => {
  if (!structure || !exchangeId) {
    return null;
  }

  const section = find(
    structure.sectionById,
    section => section.exchangeDefIds.includes(exchangeId),
  );

  if (!section) {
    return null;
  }

  return find(
    structure.pages,
    page => page.sections.includes(section._id),
  );
}, [structure, exchangeId]);
