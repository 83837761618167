import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Form, Formik, FormikProps } from 'formik';
import {
  ChangeType,
  Draft,
  MinimumReductionType,
  SectionType,
  RfxAuctionLineItemsSection,
} from '@deepstream/common/rfq-utils';
import { find, isNumber, pick } from 'lodash';
import * as yup from 'yup';

import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { callAll } from '@deepstream/utils/callAll';
import { CancelButton, EditButton, SaveButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelHeader, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import * as rfx from '../rfx';
import { SwitchField } from '../form/SwitchField';
import { FieldContainer } from '../form/FieldContainer';
import { MoneyField } from '../form/MoneyField';
import { CurrencyAmount, CurrencyCodeProvider } from '../ui/Currency';
import { SelectField } from '../form/SelectField';
import { TextField } from '../form/TextField';
import { AuctionConfigLabelConfigProvider } from './AuctionConfigLabelConfigProvider';
import { auctionLineItemSectionKeys } from './draft';
import { Number } from '../ui/Number';
import { LeavePageModal } from './LeavePageModal';
import { AuctionTabId } from './AuctionTabId';

const panelId = AuctionTabId.BID_RULES;

const fieldNames = ['minimumReduction', 'tieBids', 'ceilingPrice'];

const useMinimumReductionTypeOptions = () => {
  const { t } = useTranslation();

  return useMemo(() => Object.values(MinimumReductionType).map(type => ({
    value: type,
    label: t(`request.auction.bidRules.minimumReduction.type.${type}`),
  })), [t]);
};

const AuctionConfigBidRulesView = () => {
  const { t } = useTranslation();
  const pagePermissions = rfx.usePagePermissions();
  const { startEditing } = rfx.useActions();
  const section = rfx.useSection<RfxAuctionLineItemsSection<Draft>>();
  const isAuctionEditable = rfx.useIsAuctionEditable();
  const { auctionRules } = section;

  return (
    <AuctionConfigLabelConfigProvider fieldNames={fieldNames}>
      <CurrencyCodeProvider code={auctionRules.currency}>
        <PanelHeader
          heading={t('request.auction.sectionTitle.bidRules')}
          description={t('request.auction.sectionDescription.bidRules')}
          py={1}
        >
          {(pagePermissions.canEdit && isAuctionEditable) && (
            <EditButton
              small
              onClick={() => startEditing(panelId)}
            />
          )}
        </PanelHeader>
        <PanelDivider />
        <PanelPadding>
          <FieldContainer
            label={t('request.auction.bidRules.minimumReduction.label')}
            description={t('request.auction.bidRules.minimumReduction.description')}
            name="minimumReduction"
          >
            {auctionRules.minimumReduction.type === MinimumReductionType.AMOUNT ? (
              <Text fontWeight={auctionRules.minimumReduction.value ? 500 : undefined}>
                <CurrencyAmount value={auctionRules.minimumReduction.value} />
              </Text>
            ) : (
              <Text fontWeight={auctionRules.minimumReduction.value ? 500 : undefined}>
                {isNumber(auctionRules.minimumReduction.value) ? (
                  <Number value={auctionRules.minimumReduction.value} suffix="%" />
                ) : (
                  <EmDash />
                )}
              </Text>
            )}
          </FieldContainer>
        </PanelPadding>
        <PanelDivider />
        <PanelPadding>
          <FieldContainer
            name="tieBids"
            label={t('request.auction.bidRules.tieBids.label')}
            description={t('request.auction.bidRules.tieBids.description')}
          >
            {auctionRules.tieBids ? (
              t('general.allowed')
            ) : (
              t('general.notAllowed')
            )}
          </FieldContainer>
        </PanelPadding>
        <PanelDivider />
        <PanelPadding>
          <FieldContainer
            name="ceilingPrice"
            label={t('request.auction.bidRules.ceilingPrice.label')}
            description={t('request.auction.bidRules.ceilingPrice.description')}
          >
            <Flex flexDirection="column">
              <Text color="text">
                {auctionRules.ceilingPrice ? (
                  t('general.enabled')
                ) : (
                  t('general.disabled')
                )}
              </Text>
              {auctionRules.ceilingPrice && (
                <Text
                  mt="12px"
                  fontWeight={auctionRules.ceilingPrice.amount ? 500 : undefined}
                >
                  <CurrencyAmount value={auctionRules.ceilingPrice.amount} />
                </Text>
              )}
            </Flex>
          </FieldContainer>
        </PanelPadding>
      </CurrencyCodeProvider>
    </AuctionConfigLabelConfigProvider>
  );
};

const AuctionConfigBidRulesInnerForm = ({
  isSubmitting,
  isValid,
  dirty,
  resetForm,
  values,
  setFieldValue,
}: FormikProps<{
  minimumReduction: {
    type: MinimumReductionType;
    value: number | null;
  }
  tieBids: boolean;
  ceilingPrice: {
    amount: number | null;
  } | null
}>) => {
  const minimumReductionTypeOptions = useMinimumReductionTypeOptions();
  const minimumReductionDescriptionId = useUniqueId();
  const ceilingPriceEnabledDescriptionId = useUniqueId();
  const { t } = useTranslation();
  const { stopEditing } = rfx.useActions();
  const section = rfx.useSection<RfxAuctionLineItemsSection<Draft>>();
  const { auctionRules } = section;

  rfx.useFormResetOnAuctionStart();

  return (
    <AuctionConfigLabelConfigProvider fieldNames={fieldNames}>
      <CurrencyCodeProvider code={auctionRules.currency}>
        <Form style={{ width: '100%' }}>
          <PanelHeader
            heading={t('request.auction.sectionTitle.bidRules')}
            description={t('request.auction.sectionDescription.bidRules')}
            py={1}
          />
          <PanelDivider />
          <PanelPadding>
            <FieldContainer
              label={t('request.auction.bidRules.minimumReduction.label')}
              description={t('request.auction.bidRules.minimumReduction.description')}
              name="minimumReduction"
              descriptionId={minimumReductionDescriptionId}
            >
              <Flex flexDirection="row">
                <Box width="90px" flexShrink={0} mr={2}>
                  <SelectField
                    name="minimumReduction.type"
                    items={minimumReductionTypeOptions}
                    hideLabel
                    aria-label={`${t('request.auction.bidRules.minimumReduction.label')} - ${t('general.type')}?`}
                    aria-describedby={minimumReductionDescriptionId}
                    menuZIndex={10}
                    onChange={callAll(
                      (type) => setFieldValue('minimumReduction.type', type),
                      () => setFieldValue('minimumReduction.value', null),
                    )}
                  />
                </Box>
                {values.minimumReduction.type === MinimumReductionType.AMOUNT ? (
                  <MoneyField
                    name="minimumReduction.value"
                    hideLabel
                    aria-label={`${t('request.auction.bidRules.minimumReduction.label')} - ${t('general.amount')}`}
                    aria-describedby={minimumReductionDescriptionId}
                    symbol
                    sx={{ width: 146 }}
                    // quick fix: we can remove setting the inputStyle height once a line height is
                    // set for Input
                    inputStyle={{ height: 40 }}
                  />
                ) : (
                  <TextField
                    name="minimumReduction.value"
                    format="number.positive"
                    hideLabel
                    aria-label={`${t('request.auction.bidRules.minimumReduction.label')} - ${t('general.percent')}`}
                    aria-describedby={minimumReductionDescriptionId}
                    suffix="%"
                    sx={{ width: 146 }}
                    // quick fix: we can remove setting the inputStyle height once a line height is
                    // set for Input
                    inputStyle={{ height: 40 }}
                    convertNonNumbersToNull
                  />
                )}
              </Flex>
            </FieldContainer>
          </PanelPadding>
          <PanelDivider />
          <PanelPadding>
            <SwitchField
              name="tieBids"
              label={t('request.auction.bidRules.tieBids.label')}
              description={t('request.auction.bidRules.tieBids.description')}
              useDefaultLabelConfig={false}
              checkedIcon={false}
              uncheckedIcon={false}
              checkedText={t('general.allowed')}
              uncheckedText={t('general.notAllowed')}
              width={42}
            />
          </PanelPadding>
          <PanelDivider />
          <PanelPadding>
            <FieldContainer
              label={t('request.auction.bidRules.ceilingPrice.label')}
              description={t('request.auction.bidRules.ceilingPrice.description')}
              name="ceilingPrice"
              descriptionId={ceilingPriceEnabledDescriptionId}
            >
              <Flex flexDirection="column">
                <SwitchField
                  name="ceilingPrice"
                  hideLabel
                  aria-label={`${t('request.auction.bidRules.ceilingPrice.label')} - ${t('general.enabled')}?`}
                  aria-describedby={ceilingPriceEnabledDescriptionId}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  checkedText={t('general.enabled')}
                  uncheckedText={t('general.disabled')}
                  width={42}
                  onChange={(enabled: boolean) => setFieldValue('ceilingPrice', enabled ? { amount: null } : null)}
                />
                {values.ceilingPrice && (
                  <Box mt={3} width="146px">
                    <MoneyField
                      name="ceilingPrice.amount"
                      hideLabel
                      aria-label={`${t('request.auction.bidRules.ceilingPrice.label')} - ${t('general.amount')}`}
                      aria-describedby={ceilingPriceEnabledDescriptionId}
                      symbol
                      // quick fix: we can remove setting the inputStyle height once a line height is
                      // set for Input
                      inputStyle={{ height: 40 }}
                    />
                  </Box>
                )}
              </Flex>
            </FieldContainer>
          </PanelPadding>
          <PanelDivider />
          <PanelPadding>
            <Flex justifyContent="flex-end">
              <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
              <SaveButton disabled={isSubmitting || !dirty || !isValid} />
            </Flex>
          </PanelPadding>
          <LeavePageModal />
        </Form>
      </CurrencyCodeProvider>
    </AuctionConfigLabelConfigProvider>
  );
};

const AuctionConfigBidRulesForm = () => {
  const { t } = useTranslation();

  const { stopEditing } = rfx.useActions();
  const saveChanges = rfx.useSaveChanges();

  const section = rfx.useSection<RfxAuctionLineItemsSection<Draft>>();
  const { auctionRules } = section;

  return (
    <Formik<{
      minimumReduction: {
        type: MinimumReductionType;
        value: number | null;
      }
      tieBids: boolean;
      ceilingPrice: {
        amount: number | null;
      } | null
    }>
      validateOnBlur
      enableReinitialize
      initialValues={{
        minimumReduction: auctionRules.minimumReduction,
        tieBids: auctionRules.tieBids,
        ceilingPrice: auctionRules.ceilingPrice,
      }}
      validationSchema={
        yup.object().shape({
          minimumReduction: yup.object().shape({
            type: yup.string().oneOf(Object.values(MinimumReductionType)),
            value: yup.number().nullable().when('type', {
              is: MinimumReductionType.PERCENT,
              then: schema => schema.lessThan(100, t('errors.lessThan', { max: 100 })),
              otherwise: schema => schema.moreThan(0, t('errors.greaterThan', { min: 0 })),
            }),
          }),
          tieBids: yup.boolean(),
          ceilingPrice: yup.object().shape({
            amount: yup.number().nullable().min(1, t('errors.min1')),
          }).nullable(),
        })
      }
      onSubmit={values => saveChanges({
        changes: [{
          type: ChangeType.SECTION_UPDATED,
          section: {
            ...pick(section, auctionLineItemSectionKeys),
            auctionRules: {
              ...section.auctionRules,
              ...values,
            },
          },
        }],
      }, {
        onSuccess: stopEditing,
      })}
      component={AuctionConfigBidRulesInnerForm}
    />
  );
};

export const AuctionConfigBidRulesPanel = () => {
  const { sectionById } = rfx.useStructure<Draft>();

  const section = find(sectionById, { type: SectionType.AUCTION_LINE_ITEMS });

  const { editingPanelId } = rfx.useState();

  const isEditingThisPanel = editingPanelId === panelId;

  return section ? (
    <rfx.SectionProvider section={section}>
      <Panel>
        {isEditingThisPanel ? (
          <AuctionConfigBidRulesForm />
        ) : (
          <AuctionConfigBidRulesView />
        )}
      </Panel>
    </rfx.SectionProvider>
  ) : (
    null
  );
};
