import { useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useField } from 'formik';
import { v4 as uuid } from 'uuid';
import { CellProps } from 'react-table';
import { isEmpty, reject } from 'lodash';

import { GridQuestionColumn, GridQuestionExchangeDefinition } from '@deepstream/common/rfq-utils';
import { FieldType, fieldIconByType } from '@deepstream/common/exchangesConfig';
import { withProps } from '@deepstream/ui-utils/withProps';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { DeleteMenuItem, EllipsisMenu, MoveDownMenuItem, MoveUpMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { swap } from '@deepstream/utils/swap';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { DEFAULT_CURRENCY } from '@deepstream/common/constants';
import { CheckboxField } from '../../form/CheckboxField';
import { TextField } from '../../form/TextField';
import { Bold } from '../../Bold';
import { WordWrap } from '../../WordWrap';
import { Required } from '../../Required';
import { SelectFieldCell, TextFieldCell } from '../cell';
import { FormTableStyles } from '../../TableStyles';
import { Table } from '../../Table';
import { MultiSelectField } from '../../form/MultiSelectField';
import { useCurrencySelectItems } from '../../ui/currencies';
import { getItemValue } from '../../form/SelectField';
import { LabelConfig, LabelConfigProvider } from '../../LabelConfigProvider';
import { DisabledInputBox, ReadOnlyTextAreaBox } from '../../ui/Input';
import { DEFAULT_MAX_GRID_ROWS } from '../../ui/ExchangeDefsGrid/QuestionResponseGrid';

export const ColumnActionsCell = ({
  row: { index },
}: CellProps<GridQuestionColumn>) => {
  const [{ value: columns },, helpers] = useField<GridQuestionColumn[]>('columns');
  const [,, columnHelpers] = useField<GridQuestionColumn>(`columns.${index}`);

  const moveColumn = useCallback(
    (delta: number) => {
      helpers.setValue(swap(columns, index, index + delta));
    },
    [columns, index, helpers],
  );

  const removeColumn = useCallback(
    () => {
      helpers.setValue(reject(columns, (_, columnIndex) => index === columnIndex));
      columnHelpers.setTouched(false);
    },
    [columns, index, helpers, columnHelpers],
  );

  return (
    <Flex height="40px" width="40px" justifyContent="flex-end" alignItems="center">
      <EllipsisMenu
        variant="secondary-outline"
        menuZIndex={202}
        width="40px"
      >
        <MoveUpMenuItem
          onSelect={() => moveColumn(-1)}
          disabled={index === 0}
        />
        <MoveDownMenuItem
          onSelect={() => moveColumn(+1)}
          disabled={index === columns.length - 1}
        />
        <DeleteMenuItem onSelect={() => removeColumn()} />
      </EllipsisMenu>
    </Flex>
  );
};

const FieldColumnHeader = ({ column }) => (
  <WordWrap>
    {column.label}
    {column.required && <Required />}
  </WordWrap>
);

const ColumnTableField = () => {
  const { t } = useTranslation();
  const [{ value: columns },, helpers] = useField<GridQuestionColumn[]>('columns');

  const columnTypeOptions = useMemo(
    () => {
      return [
        FieldType.STRING,
        FieldType.NUMBER,
        FieldType.DATE,
        FieldType.PRICE,
        FieldType.PERCENTAGE,
      ].map(fieldType => ({
        value: fieldType,
        label: (
          <IconText
            gap={2}
            // @ts-expect-error ts(2322) FIXME: Type '"function" | "upload" | "sort" | "filter" | "history" | "search" | "link" | "file" | "sync" | "question" | "clock" | "angle-left" | "angle-right" | "archive" | "arrow-down" | "arrow-down-short-wide" | ... 187 more ... | undefined' is not assignable to type '"function" | "upload" | "sort" | "filter" | "history" | "search" | "link" | "file" | "sync" | "question" | "clock" | "angle-left" | "angle-right" | "archive" | "arrow-down" | "arrow-down-short-wide" | ... 186 more ... | "person-circle-check"'.
            icon={fieldIconByType[fieldType]}
            iconColor="subtext"
            text={t(`request.question.grid.columnType.${fieldType}`)}
            fixedWidth
          />
        ),
      }));
    },
    [t],
  );

  const addColumn = useCallback(
    () => {
      helpers.setValue([
        ...columns,
        {
          id: uuid(),
          name: '',
          type: FieldType.STRING,
        },
      ]);
    },
    [columns, helpers],
  );

  const tableColumns = useMemo(
    () => [
      {
        id: 'name',
        Header: FieldColumnHeader,
        label: t('request.question.grid.columnName'),
        accessor: 'name',
        Cell: withProps(TextFieldCell, { fieldName: 'columns' }),
        required: true,
      },
      {
        id: 'type',
        Header: FieldColumnHeader,
        label: t('request.question.grid.type'),
        accessor: 'type',
        Cell: withProps(SelectFieldCell, { fieldName: 'columns' }),
        required: true,
        options: columnTypeOptions,
      },
      {
        id: 'menu',
        Header: () => '',
        accessor: null,
        width: 48,
        Cell: ColumnActionsCell,
      },
    ],
    [columnTypeOptions, t],
  );

  return (
    <Stack gap={2}>
      {columns.length > 0 ? (
        <FormTableStyles>
          <Table columns={tableColumns} data={columns} />
        </FormTableStyles>
      ) : (
        <Text color="subtext" fontSize={2}>
          {t('request.question.grid.emptyColumnsTable')}
        </Text>
      )}
      <Button
        type="button"
        small
        variant="secondary-outline"
        iconLeft="plus"
        onClick={addColumn}
        width="fit-content"
      >
        {t('request.question.grid.addColumn')}
      </Button>
    </Stack>
  );
};

const rowConfigPositionStyle = {
  position: 'relative',
  top: '10px',
};

const currenciesLabelStyle = {
  fontSize: 2,
  fontWeight: 500,
  color: 'text',
};

export const RowsConfig = ({
  rowsConfig,
  isReadOnly,
  isDisabled,
}: {
  rowsConfig: GridQuestionExchangeDefinition['rowsConfig'];
  isReadOnly?: boolean;
  isDisabled?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Stack gap={1}>
      <Box width="fit-content">
        {(isReadOnly || isDisabled) ? (
          <Flex alignItems="center">
            <Checkbox checked={rowsConfig.isCustom} disabled />
            <Text ml={2}>
              {t('request.question.grid.configureRows')}
            </Text>
          </Flex>
        ) : (
          <CheckboxField
            fieldLabel={t('request.question.grid.configureRows')}
            name="rowsConfig.isCustom"
          />
        )}
      </Box>
      {rowsConfig.isCustom && (
        <>
          <Bold color="lightNavy" fontSize={1}>
            {t('request.question.grid.responseValidation')}
          </Bold>
          <Flex sx={{ gap: '50px' }}>
            <Flex flex="0 0 auto" sx={{ gap: 3 }}>
              <Text sx={rowConfigPositionStyle}>
                {t('minimumShort', { ns: 'general' })}
              </Text>
              <Box width="80px">
                {isReadOnly ? (
                  <ReadOnlyTextAreaBox>
                    {rowsConfig.min}
                  </ReadOnlyTextAreaBox>
                ) : isDisabled ? (
                  <DisabledInputBox>
                    {rowsConfig.min}
                  </DisabledInputBox>
                ) : (
                  <TextField
                    name="rowsConfig.min"
                    maxValue={DEFAULT_MAX_GRID_ROWS}
                    inputType="number"
                    format="integer.positive"
                  />
                )}
              </Box>
              <Text sx={{ textTransform: 'lowercase', ...rowConfigPositionStyle }}>
                {t('request.question.grid.row_other')}
              </Text>
            </Flex>
            <Flex flex="0 0 auto" sx={{ gap: 3 }}>
              <Text sx={rowConfigPositionStyle}>
                {t('maximumShort', { ns: 'general' })}
              </Text>
              <Box width="80px">
                {isReadOnly ? (
                  <ReadOnlyTextAreaBox>
                    {rowsConfig.max}
                  </ReadOnlyTextAreaBox>
                ) : isDisabled ? (
                  <DisabledInputBox>
                    {rowsConfig.max}
                  </DisabledInputBox>
                ) : (
                  <TextField
                    name="rowsConfig.max"
                    inputType="number"
                    format="integer.positive"
                    maxValue={DEFAULT_MAX_GRID_ROWS}
                  />
                )}
              </Box>
              <Text sx={{ textTransform: 'lowercase', ...rowConfigPositionStyle }}>
                {t('request.question.grid.row_other')}
              </Text>
            </Flex>
          </Flex>
        </>
      )}
    </Stack>
  );
};

export const GridField = () => {
  const { t } = useTranslation(['translation', 'general']);
  const [{ value: columns }] = useField<GridQuestionColumn[]>('columns');
  const [{ value: rowsConfig }] = useField<GridQuestionExchangeDefinition['rowsConfig']>('rowsConfig');
  const [,, minHelpers] = useField<number>('rowsConfig.min');
  const [,, maxHelpers] = useField<number>('rowsConfig.max');
  const [{ value: currencies },, currenciesHelpers] = useField<string[]>('currencies');

  const currencySelectItems = useCurrencySelectItems();

  const hasPriceColumns = useMemo(
    () => columns.some(column => column.type === FieldType.PRICE),
    [columns],
  );

  // Side effects for cleaning up the form fields
  useWatchValue(
    rowsConfig.isCustom,
    isCustom => {
      if (!isCustom) {
        // @ts-expect-error ts(2345) FIXME: Argument of type 'undefined' is not assignable to parameter of type 'number'.
        minHelpers.setValue(undefined);
        // @ts-expect-error ts(2345) FIXME: Argument of type 'undefined' is not assignable to parameter of type 'number'.
        maxHelpers.setValue(undefined);
      }
    },
  );

  useEffect(
    () => {
      if (hasPriceColumns && isEmpty(currencies)) {
        currenciesHelpers.setValue([DEFAULT_CURRENCY]);
      } else if (!hasPriceColumns) {
        // @ts-expect-error ts(2345) FIXME: Argument of type 'undefined' is not assignable to parameter of type 'string[]'.
        currenciesHelpers.setValue(undefined);
      }
    },
    // We're intentionally not including `currencies` in the dependencies because we only want to set the default currency once
    [hasPriceColumns], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Stack gap="20px">
      <ColumnTableField />
      {hasPriceColumns && (
        <LabelConfigProvider width="275px" variant={LabelConfig.LEFT} style={{ currencies: currenciesLabelStyle }}>
          <MultiSelectField
            name="currencies"
            label={t('request.question.grid.supplierCurrency')}
            description={t('request.question.grid.supplierCurrencyDescription')}
            required
            variant="secondary-outline"
            items={currencySelectItems}
            itemToString={getItemValue}
            buttonWidth={200}
            menuWidth={270}
            menuZIndex={202}
            placeholder={t('request.question.grid.supplierCurrencyPlaceholder')}
            getButtonText={(items) => items.length === 1 ? (
              items[0].label
            ) : items.length > 1 ? (
              items.map(item => item.value).join(', ')
            ) : null}
            alwaysShowCaret
          />
        </LabelConfigProvider>
      )}
      <RowsConfig rowsConfig={rowsConfig} />
    </Stack>
  );
};
