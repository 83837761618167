import { useState, useMemo } from 'react';
import { values } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { v4 as uuid } from 'uuid';
import { Flex } from 'rebass/styled-components';
import { DocumentExchangeDefinition, ExchangeType } from '@deepstream/common/rfq-utils';
import { documentExchangeTypes } from '@deepstream/common/exchangesConfig';
import { ContractSection } from '@deepstream/common/contract/contract';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton, Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { PanelDivider, PanelPadding, PanelSubHeader } from '@deepstream/ui-kit/elements/Panel';

import { ContractDocumentTable, ContractDocumentTableField } from './ContractDocumentTable';
import { usePreventEnterKeyHandler } from '../../usePreventEnterKeyHandler';
import { Validation } from '../../draft/validation';
import { useIsEditing, useSaveDocumentsSection, useShowValidationErrors } from './draftContract';
import { useContractActions, useContractData, useContractSection, useSectionExchangeDefsByCreator } from './contract';
import { ContractDraftPanel } from './ContractDraftPanel';
import { ModelSizeLimitDialog, ModelSizeLimitMessages } from '../../ModelSizeLimitDialog';
import { getSizeRelevantExchangeDefCount, useModelSizeLimits } from '../../modelSizeLimits';
import { useModalState } from '../../ui/useModalState';
import { DraftSectionEditPanelHeader } from '../../draft/DraftSectionEditPanelHeader';
import { ContractDraftSectionViewPanelHeader } from './ContractDraftSectionViewPanelHeader';
import { AddDocumentButton } from '../../draft/AddDocumentButton';
import { openFilePicker } from '../../uploads/openFilePicker';
import { useSystemFeatureFlags } from '../../systemFeatureFlags';
import { useExchangeDefsField } from '../../draft/exchangeDefs';
import { LeavePageModal } from '../../draft/LeavePageModal';

const BulkUploadButton = (props: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button small variant="secondary-transparent-outline" iconLeft="upload" {...props}>
      {t('request.question.bulkUpload.btnLabel')}
    </Button>
  );
};

const DocumentSectionEditButtons = ({ fieldName = 'exchangeDefs' }: { fieldName?: string }) => {
  const systemFeatureFlags = useSystemFeatureFlags();
  const section = useContractSection();

  const { addExchangeDef, addExchangeDefs } = useExchangeDefsField(
    fieldName,
    () => ({
      _id: uuid(),
      category: '',
      attachments: [],
      sectionId: section._id,
    }),
  );

  const handleImportClick = async () => {
    const files = await openFilePicker({ multiple: true });

    // HACK: Set each file on the corresponding document exchange def
    // so that they can be threaded through the table component into the
    // `initialFiles` props of each `FilesFieldCell` and immediately start
    // uploading. Probably the easiest way to do it but not very pretty.
    const exchangeDefs = Array
      .from(files)
      .map(file => ({
        _id: uuid(),
        category: '',
        attachments: [],
        sectionId: section._id,
        file,
      }));

    addExchangeDefs(exchangeDefs);
  };

  return (
    <>
      {/*
       // @ts-expect-error ts(18048) FIXME: 'systemFeatureFlags' is possibly 'undefined'. */}
      {systemFeatureFlags.bulkImportEnabled && (
        <BulkUploadButton
          type="button"
          onClick={handleImportClick}
          mr={2}
        />
      )}
      <AddDocumentButton
        type="button"
        onClick={() => addExchangeDef()}
      />
    </>
  );
};

/**
 * Displays a panel for the current documents section
 */
export const ContractDocumentsSectionPanel = () => {
  const { t } = useTranslation(['contracts', 'general', 'translation']);

  const { stopEditing } = useContractActions();
  const isEditing = useIsEditing();
  const { exchangeDefById } = useContractData();
  const showValidationErrors = useShowValidationErrors();
  const section = useContractSection();
  const senderExchangeDefs = useSectionExchangeDefsByCreator<DocumentExchangeDefinition>({
    group: 'sender',
  });
  const recipientExchangeDefs = useSectionExchangeDefsByCreator<DocumentExchangeDefinition>({
    group: 'recipient',
  });
  const [saveSection] = useSaveDocumentsSection();
  const onKeyDown = usePreventEnterKeyHandler();
  const { maxExchangeDefCount } = useModelSizeLimits();
  const modelSizeLimitModal = useModalState();
  const [modelSizeLimitMessages, setModelSizeLimitMessages] = useState<ModelSizeLimitMessages | null>(null);

  const initialValues = useMemo(
    () => ({
      section,
      senderExchangeDefs,
      recipientExchangeDefs,
    }),
    [senderExchangeDefs, recipientExchangeDefs, section],
  );

  const handleSubmit = async ({ section, senderExchangeDefs, recipientExchangeDefs }, { setSubmitting }) => {
    const totalExchangeDefCount = getSizeRelevantExchangeDefCount(exchangeDefById);

    const previousSectionExchangeDefCount = getSizeRelevantExchangeDefCount([
      ...initialValues.senderExchangeDefs,
      ...initialValues.recipientExchangeDefs,
    ]);
    const newSectionExchangeDefCount = getSizeRelevantExchangeDefCount([
      ...senderExchangeDefs,
      ...recipientExchangeDefs,
    ]);
    const addedCount = newSectionExchangeDefCount - previousSectionExchangeDefCount;

    if (
      newSectionExchangeDefCount > previousSectionExchangeDefCount &&
      totalExchangeDefCount + addedCount > maxExchangeDefCount
    ) {
      setModelSizeLimitMessages({
        heading: t('dialog.contractSizeLimit.heading'),
        title: t('dialog.contractSizeLimit.saveDocumentsSection.title'),
        warning: t('dialog.contractSizeLimit.saveDocumentsSection.warning'),
        body: t('dialog.contractSizeLimit.saveDocumentsSection.body', {
          count: totalExchangeDefCount + addedCount - maxExchangeDefCount,
        }),
      });
      modelSizeLimitModal.open();
      setSubmitting(false);
    } else {
      await saveSection({
        section,
        exchangeDefs: senderExchangeDefs,
        recipientExchangeDefs,
      }, {
        onSuccess: stopEditing,
        onError: () => setSubmitting(false),
      });
    }
  };

  return isEditing ? (
    <>
      <Formik<{
        section: ContractSection;
        senderExchangeDefs: DocumentExchangeDefinition[];
        recipientExchangeDefs: DocumentExchangeDefinition[];
      }>
        validateOnBlur
        enableReinitialize
        initialValues={initialValues as any}
        validationSchema={
          yup.object().shape({
            section: yup.object().shape({
              name: yup.string(),
              description: yup.string(),
            }),
          })
        }
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, dirty, resetForm }) => (
          <Form style={{ width: '100%' }} onKeyDown={onKeyDown}>
            <ContractDraftPanel panelId={section._id}>
              <DraftSectionEditPanelHeader icon="file-text-o" />
              <PanelDivider />
              <PanelSubHeader height="52px">
                <DocumentSectionEditButtons fieldName="senderExchangeDefs" />
              </PanelSubHeader>
              <PanelDivider />
              <PanelPadding>
                <ContractDocumentTableField fieldName="senderExchangeDefs" />
              </PanelPadding>
              <PanelDivider />
              <PanelPadding>
                <Flex justifyContent="flex-end">
                  <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
                  <SaveButton disabled={isSubmitting || !dirty} />
                </Flex>
              </PanelPadding>
            </ContractDraftPanel>
            <LeavePageModal />
          </Form>
        )}
      </Formik>
      <ModelSizeLimitDialog
        modal={modelSizeLimitModal}
        messages={modelSizeLimitMessages}
      />
    </>
  ) : (
    <Validation
      values={{
        section,
        senderExchangeDefs,
      }}
      schema={showValidationErrors ? (
        yup.object().shape({
          section: yup.object().shape({
            name: yup.string().required(t('required', { ns: 'general' })),
          }),
          senderExchangeDefs: yup.array(
            yup.object().shape({
              type: yup.string().oneOf(values(documentExchangeTypes)).required(t('required', { ns: 'general' })),
              category: yup.string().required(t('required', { ns: 'general' })), // "description"
              attachments: yup
                .array(yup.object().required())
                .when('type', {
                  is: type => !type || [
                    ExchangeType.DOCUMENT_REQUEST,
                    ExchangeType.DOCUMENT_REQUEST_LOCKED,
                    ExchangeType.DOCUMENT_REQUEST_CLOSED, // @deprecated
                  ].includes(type),
                  then: schema => schema.nullable(),
                  otherwise: schema => schema.min(1, t('required', { ns: 'general' })).required(t('required', { ns: 'general' })),
                }),
            }),
          ).required().min(1, t('review.errors.minimumOneRequired')),
        })
      ) : (
        yup.mixed()
      )}
    >
      <ContractDraftPanel panelId={section._id}>
        <ContractDraftSectionViewPanelHeader icon="file-text-o" />
        <PanelDivider />
        <ContractDocumentTable />
      </ContractDraftPanel>
    </Validation>
  );
};
