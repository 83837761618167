import * as React from 'react';
import { Box, FlexProps, SxStyleProp } from 'rebass/styled-components';
import { IconValue } from '@deepstream/common';
import { Link, LinkProps } from '@tanstack/react-router';
import styled from 'styled-components';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { Color } from '@deepstream/ui-kit/theme/types';
import { TitledListItem } from '../Live/lotPagesLayout';

const NavigationMenuItemContainer = styled.li`
  width: 100%;

  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    width: 100%;
    text-decoration: none;
    color: inherit;
  }
`;

const DisabledLink = ({ children }) => <Box>{children}</Box>;

export const NavigationMenuItem = ({
  icon,
  isIconRegular,
  title,
  titleColor = 'primary',
  disabledTitleColor = 'text',
  body,
  disabled,
  linkProps,
  ...props
}: Pick<FlexProps, 'alignItems'> & {
  icon?: IconValue;
  isIconRegular?: boolean;
  title: string | JSX.Element;
  titleColor?: Color;
  disabledTitleColor?: Color;
  body?: React.ReactNode;
  linkProps: LinkProps;
  disabled?: boolean;
}) => {
  const theme = useTheme();

  const LinkComponent = disabled
    ? DisabledLink
    : Link;

  return (
    <NavigationMenuItemContainer>
      <LinkComponent style={{ height: '100%', display: 'flex' }} {...linkProps}>
        <TitledListItem
          icon={icon}
          isIconRegular={isIconRegular}
          iconRight={disabled ? undefined : 'chevron-right'}
          title={
            <Box as="span" sx={disabled ? undefined : { textDecoration: 'underline' }}>
              {title}
            </Box>
          }
          titleColor={disabled ? disabledTitleColor : titleColor}
          body={body}
          sx={{
            width: '100%',
            borderBottom: 'lightGray',
            ':hover': disabled
              ? undefined
              : {
                backgroundColor: 'lightGray3Hover',
                boxShadow: `0 0 16px 16px ${theme.colors.lightGray3Hover}`,
                clipPath: 'inset(0px -16px 0px -16px)',
              },
          }}
          {...props}
        />
      </LinkComponent>
    </NavigationMenuItemContainer>
  );
};

export const NavigationMenu = ({
  heading,
  children,
  sx,
}: {
  heading: string;
  children: React.ReactNode;
  sx?: SxStyleProp;
}) => {
  return (
    <Box
      as="nav"
      aria-label={heading}
    >
      <Box
        as="ol"
        sx={{
          listStyleType: 'none',
          padding: 0,
          display: 'grid',
          gap: '0 32px',
          ...sx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
