import { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Text } from 'rebass/styled-components';

import { AuctionStatus } from '@deepstream/common/rfq-utils';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useDeviceSize } from '../ui/useDeviceSize';
import { Tab, TabListPanel, TabPanel, TabPanels, Tabs } from '../ui/TabsVertical';
import { SidebarLayout } from '../ui/ProfileLayout';
import * as rfx from '../rfx';
import { useAuctionRouteSearch } from '../AppRouting';
import { useRequestEditNavigation } from '../appNavigation';
import { AuctionConfigAwardingPrinciplesPanel } from './AuctionConfigAwardingPrinciplesPanel';
import { AuctionConfigBidderAgreementPanel } from './AuctionConfigBidderAgreementPanel';
import { AuctionConfigBidFeedbackPanel } from './AuctionConfigBidFeedbackPanel';
import { AuctionConfigBidRulesPanel } from './AuctionConfigBidRulesPanel';
import { AuctionConfigLineItemsPanel } from './AuctionConfigLineItemsPanel';
import { AuctionConfigTimingRulesPanel } from './AuctionConfigTimingRulesPanel';
import { AuctionTabId } from './AuctionTabId';

const auctionTabIds = [
  AuctionTabId.AWARDING_PRINCIPLES,
  AuctionTabId.BIDDER_AGREEMENT,
  AuctionTabId.BID_FEEDBACK,
  AuctionTabId.LINE_ITEMS,
  AuctionTabId.BID_RULES,
  AuctionTabId.TIMING_RULES,
] as const;

const panelComponents = [
  AuctionConfigAwardingPrinciplesPanel,
  AuctionConfigBidderAgreementPanel,
  AuctionConfigBidFeedbackPanel,
  AuctionConfigLineItemsPanel,
  AuctionConfigBidRulesPanel,
  AuctionConfigTimingRulesPanel,
];

export const AuctionConfigPanels = () => {
  const { t } = useTranslation();
  const auction = rfx.useAuction();
  const { isExtraSmall, isSmall } = useDeviceSize();
  const { isTemplate, editingPanelId } = rfx.useState();
  const isAuctionEditable = rfx.useIsAuctionEditable();
  const pagePermissions = rfx.usePagePermissions();
  const { navigateToAuction } = useRequestEditNavigation();
  const search = useAuctionRouteSearch({ isTemplate });
  const selectedTabIndex = auctionTabIds.indexOf(search.tab);

  const isEditing = Boolean(editingPanelId);

  useEffect(
    () => {
      if (!search.tab) {
        navigateToAuction(auctionTabIds[0], true);
      }
    },
    [navigateToAuction, search.tab],
  );

  const onTabsChange = useCallback(
    (index: number) => navigateToAuction(auctionTabIds[index]),
    [navigateToAuction],
  );

  return (
    <Tabs index={selectedTabIndex} onChange={onTabsChange}>
      <SidebarLayout
        sidebar={(
          <TabListPanel>
            <Box height="2px" />
            {auctionTabIds.map(panelId => (
              <Tab
                key={panelId}
                color="subtext"
                disabled={isEditing}
                style={{ fontSize: 14, lineHeight: 1 }}
              >
                <Flex alignItems="center" pt={1} pb={1}>
                  <Text flex={1}>{t(`request.auction.sectionTitle.${panelId}`)}</Text>
                </Flex>
              </Tab>
            ))}
          </TabListPanel>
        )}
        main={(
          <TabPanels>
            {panelComponents.map((PanelComponent, index) => (
              <TabPanel key={index}>
                <PanelComponent />
                {(pagePermissions.canEdit && !isAuctionEditable) && (
                  <MessageBlock mt={2} variant="info">
                    {auction.status === AuctionStatus.CANCELLED ? (
                      t('request.auction.panelInfoReviseCancelledAuction')
                    ) : (
                      t('request.auction.panelInfoReviseStartedAuction')
                    )}
                  </MessageBlock>
                )}
              </TabPanel>
            ))}
          </TabPanels>
        )}
        sidebarStyle={!isExtraSmall && !isSmall ? { maxWidth: '274px', flex: '0 0 auto' } : undefined}
        mainStyle={!isExtraSmall && !isSmall ? { flex: '1 1 auto' } : undefined}
      />
    </Tabs>
  );
};
