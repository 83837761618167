import { Box, Flex, Text } from 'rebass/styled-components';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { IconTextButton } from '@deepstream/ui-kit/elements/button/IconTextButton';
import { useContext, useMemo } from 'react';
import { sumBy } from 'lodash';
import { localeFormatPrice } from '@deepstream/utils';
import { CurrencyExchangeDefinition, ExchangeType, RfxSection } from '@deepstream/common/rfq-utils';
import { useSection, useStructure } from '../../rfx';
import { Section2, Subsection2 } from '../../modules/Request/Live/lotPagesLayout';
import * as lotPagesLayout from '../../modules/Request/Live/lotPagesLayout';
import { Disclosure2 } from '../Disclosure';
import { BulkSubmitContext } from './BulkSubmitContext';
import { computeFormulaValue } from '../../modules/Request/computeFormula';
import { useExchangeRates } from '../../useExchangeRates';
import { useCurrentUserLocale } from '../../useCurrentUser';

const formatCurrency = (currency: string, locale: string) => {
  const currencyNames = new Intl.DisplayNames([locale], { type: 'currency' });
  return `${currency} - ${currencyNames.of(currency)}`;
};

type Props = {
  setIsCollapsed: (isCollapsed: boolean) => void;
};

export const BulkSubmitSectionDetails = ({ setIsCollapsed }: Props) => {
  const structure = useStructure();
  const section = useSection<RfxSection>();
  const exchangeRates = useExchangeRates();
  const { validationErrors, lineItems } = useContext(BulkSubmitContext);
  const totalCostSum = useMemo(() => sumBy(lineItems, (item) => {
    const value = computeFormulaValue({
      exchange: item,
      formula: item.def.fields.totalCost.source.formula,
      valuesMap: item.latestReply,
      exchangeRates,
    });
    return value || 0;
  }), [exchangeRates, lineItems]);
  const locale = useCurrentUserLocale();
  const formattedValue = localeFormatPrice(totalCostSum, 'USD', { locale, showCode: true });
  const currencies = (section.exchangeDefIds
    .map((id) => structure.exchangeDefById[id])
    .find(exchangeDef => exchangeDef.type === ExchangeType.CURRENCY) as CurrencyExchangeDefinition)?.currencies;
  const formattedCurrency = currencies ? formatCurrency(currencies?.[0], locale) : '';

  return (
    <Box p="20px" bg="lightGray3" height="100%">
      <Flex justifyContent="flex-end" alignItems="center" mb="20px">
        <IconTextButton
          fontSize={2}
          icon="chevron-left"
          color="primary"
          onClick={() => setIsCollapsed(true)}
          iconSx={{ position: 'static' }}
        >
          Hide sidebar
        </IconTextButton>
      </Flex>
      <Section2 heading="Section details" overflowY="auto" height="100%">
        <Subsection2 heading="Description" headingMargin="8px" mb="20px">
          {section.description}
        </Subsection2>
        <Subsection2 heading="Currency" headingMargin="8px" mb="20px">
          All prices in this section must be submitted in <Text display="inline" fontWeight={700}>{formattedCurrency}</Text>.
        </Subsection2>
        <Subsection2 heading="Total cost" headingMargin="8px" mb="20px">
          The total cost of complete requirements in this section is <Text display="inline" fontWeight={700}>{formattedValue}</Text>
        </Subsection2>
        <Subsection2 heading="Requirements" headingMargin="8px" mb="20px">
          <Flex sx={{ gap: '10px' }}>
            <Flex sx={{ gap: '8px' }}>Total <Text fontWeight={700}>{lineItems?.length}</Text></Flex>
            <Flex sx={{ gap: '8px' }}>
              <IconText icon="exclamation-circle" iconColor="danger" text="Action required" iconSx={{ position: 'static' }} />
              <Text fontWeight={700}>{validationErrors?.exchangeDefs?.filter(Boolean)?.length || 0}</Text>
            </Flex>
          </Flex>
        </Subsection2>

        <Disclosure2
          width="100%"
          summary="Guidance on completing the requirements"
        >
          <lotPagesLayout.Ul>
            <li>Guidance 1</li>
            <li>Guidance 2</li>
            <li>Guidance 3</li>
            <li>Guidance 4</li>
            <li>Guidance 5</li>
            <li>Guidance 6</li>
            <li>Guidance 7</li>
          </lotPagesLayout.Ul>
        </Disclosure2>
      </Section2>
    </Box>
  );
};
