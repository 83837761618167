import { CellProps } from 'react-table';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { DeleteMenuItem, DropdownMenu, DuplicateMenuItem, EditMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { useCurrentCompanyId } from './currentCompanyId';
import { useApi } from './api';
import { useToaster } from './toast';
import { useMutation } from './useMutation';
import { DashboardTemplate } from './types';
import { useRequestsNavigation } from './RequestsNavigationContext';
import { useCurrentUser, useCurrentUserLocale } from './useCurrentUser';
import { useExternalSystem, useUnmappedExternalSupplierIds } from './useExternalSystems';
import { UnmappedExternalSuppliersWarningDialog } from './UnmappedExternalSuppliersWarningDialog';
import { useConfirmDialog } from './ui/useModalState';
import { waitForUnlock } from './useWaitForUnlock';
import { useGlobalProcessing } from './GlobalProcessingProvider';

export type RequestsPageSearch = {
  tabId?: string;
  systemId?: string;
  requestName?: string;
  supplierIds?: string[];
  requestOverview?: string;
};

export const RequestTemplateActionButtons = ({
  row,
  openDeleteModal,
  onExpandedStateChange,
  search,
}: CellProps<DashboardTemplate> & {
  openDeleteModal: (template: DashboardTemplate) => void;
  onExpandedStateChange?: (isExpanded: boolean) => void;
  search: RequestsPageSearch;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId()!;
  const api = useApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const template = row.original;
  const locale = useCurrentUserLocale();
  const {
    navigateToSenderRequest,
    navigateToTemplateEdit,
  } = useRequestsNavigation();

  const { confirm, ...dialogProps } = useConfirmDialog();
  const currentUser = useCurrentUser();
  const { setProcessingContext } = useGlobalProcessing();

  const {
    systemId,
    supplierIds,
    requestName,
    requestOverview,
  } = search;

  const { data: unmappedExternalSupplierIds, isLoading: isLoadingUnmappedExternalSuppliers } = useUnmappedExternalSupplierIds({
    // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
    systemId,
    // @ts-expect-error ts(2322) FIXME: Type 'string[] | undefined' is not assignable to type 'string[]'.
    externalSupplierIds: supplierIds,
  });

  const { data: externalSystem } = useExternalSystem({ systemId });

  const isAdmin = currentUser.roles[currentCompanyId]?.admin;

  const [createRequestFromTemplate, { status }] = useMutation(
    async ({
      currentCompanyId,
      templateId,
      options,
    }) => {
      const rfqId = await api.createEventlessRequestFromTemplate({ currentCompanyId, templateId });

      await waitForUnlock({
        command: () => api.addRequestCreatedEventFromTemplate({
          currentCompanyId,
          rfqId,
          templateId,
          options,
        }),
        getLockState: () => api.getRfqLockState({
          currentCompanyId,
          rfqId,
        }),
        // @ts-expect-error ts(2322) FIXME: Type '((isProcessing: ProcessingContext) => void) | undefined' is not assignable to type '((processingContext: ProcessingContext | null) => void) | undefined'.
        setIsProcessingModalVisible: setProcessingContext,
      });

      return rfqId;
    },
    {
      onSuccess: callAll(
        requestId => navigateToSenderRequest({ requestId, isDraft: true }),
        () => toaster.success(t(
          'requests.toaster.createRequestFromTemplate.success',
          { name: template.meta.name },
        )),
      ),
      onError: () => toaster.error(t('requests.toaster.createRequestFromTemplate.failed')),
    },
  );

  const [duplicateTemplate] = useMutation(
    async ({
      currentCompanyId,
      templateId,
      locale,
    }: {
      currentCompanyId: string;
      templateId: string;
      locale: string;
    }) => {
      const newTemplateId = await api.createEventlessTemplateFromTemplate({ currentCompanyId, templateId, locale });

      await waitForUnlock({
        command: () => api.addRequestCreatedEventFromTemplateToTemplate({
          currentCompanyId,
          templateId: newTemplateId,
          modelTemplateId: templateId,
        }),
        getLockState: () => api.getRfqLockState({
          currentCompanyId,
          rfqId: newTemplateId,
          isTemplate: true,
        }),
        // @ts-expect-error ts(2322) FIXME: Type '((isProcessing: ProcessingContext) => void) | undefined' is not assignable to type '((processingContext: ProcessingContext | null) => void) | undefined'.
        setIsProcessingModalVisible: setProcessingContext,
      });
    },
    {
      onSettled: () => queryClient.invalidateQueries(['templates', { companyId: currentCompanyId }]),
      onSuccess: () => toaster.success(t('requests.toaster.duplicateTemplate.success')),
      onError: () => toaster.error(t('requests.toaster.duplicateTemplate.failed')),
    },
  );

  return (
    <>
      <ButtonGroup marginBetween="-1px">
        <Button
          small
          variant="primary-outline"
          disabled={status === 'loading' || isLoadingUnmappedExternalSuppliers}
          onClick={e => {
            e.stopPropagation();
            unmappedExternalSupplierIds?.length
              ? confirm(() => createRequestFromTemplate({
                  currentCompanyId,
                  templateId: template._id,
                  options: {
                    requestName,
                    requestOverview,
                    externalSupplierIds: supplierIds,
                    externalSystemId: systemId,
                  },
                }))
              : createRequestFromTemplate({
                  currentCompanyId,
                  templateId: template._id,
                  options: {
                    requestName,
                    requestOverview,
                    externalSupplierIds: supplierIds,
                    externalSystemId: systemId,
                  },
                });
          }}
        >
          <Icon fontSize={1} mr={2} icon="plus" />
          {t('requests.newRequest')}
        </Button>
        <DropdownMenu
          small
          variant="primary-outline"
          iconLeft="ellipsis-h"
          onExpandedStateChange={onExpandedStateChange}
          stopClickEvents
          onClick={stopPropagation}
          menuZIndex={10}
        >
          <EditMenuItem onSelect={() => navigateToTemplateEdit(template._id)} />
          <DuplicateMenuItem
            onSelect={() => duplicateTemplate({
              currentCompanyId,
              templateId: template._id,
              locale,
            })}
          />
          <DeleteMenuItem onSelect={() => openDeleteModal(template)} />
        </DropdownMenu>
      </ButtonGroup>
      {externalSystem && (
        <UnmappedExternalSuppliersWarningDialog
          externalSystem={externalSystem}
          externalSupplierIds={unmappedExternalSupplierIds}
          isAdmin={isAdmin}
          {...dialogProps}
        />
      )}
    </>
  );
};
