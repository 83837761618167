import { useTranslation } from 'react-i18next';

import { DisabledInputBox } from '../../ui/Input';

export const LongTextField = () => {
  const { t } = useTranslation();

  return (
    <DisabledInputBox sx={{ height: '100px', maxWidth: '500px' }}>
      {t('request.question.questionType.longText')}
    </DisabledInputBox>
  );
};
