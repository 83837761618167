import { useMemo } from 'react';
import { Text, Flex, Box } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { useModalState } from '../../../ui/useModalState';
import { useToaster } from '../../../toast';
import { useMutation } from '../../../useMutation';
import { useApi } from '../../../api';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { PageFooter } from '../../../PageFooter';
import { useQuestionnaireTemplateData, useQuestionnaireTemplateState } from './questionnaireTemplateUtils';
import { usePreQualNavigation } from '../../../AppRouting';
import { PreQualTab } from '../utils';
import { useUserFlags } from '../../../UserFlagsContext';

export const DraftFooter = ({
  onBack,
  onContinue,
  onPublish,
  publishDisabled,
  isInvalid,
}: {
  onBack?: () => void,
  onContinue?: () => void,
  onPublish?: () => void,
  publishDisabled?: boolean;
  isInvalid?: boolean;
}) => {
  const { t } = useTranslation(['preQualification', 'general']);
  const api = useApi();
  const toaster = useToaster();
  const deleteQuestionnaireModal = useModalState();
  // @ts-expect-error ts(2339) FIXME: Property 'editingPanelId' does not exist on type 'QuestionnaireTemplateStateContextType | undefined'.
  const { editingPanelId, isRevising } = useQuestionnaireTemplateState();
  const template = useQuestionnaireTemplateData();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { navigateToPreQualificationQuestionnaires } = usePreQualNavigation();
  const { hasManagePreQualPermission } = useUserFlags();

  const [deleteTemplateMutation] = useMutation(
    api.deleteQuestionnaireTemplate,
    {
      onSettled: deleteQuestionnaireModal.close,
      onSuccess: callAll(
        () => toaster.success(t('toaster.templateDeleted.success')),
        () => navigateToPreQualificationQuestionnaires(PreQualTab.DRAFT_QUESTIONNAIRES),
      ),
      onError: () => toaster.error(t('toaster.templateDeleted.failed')),
    },
  );

  const publishTooltip = useMemo(
    () => {
      if (isInvalid) {
        return t('review.publishTooltip');
      } else {
        return null;
      }
    },
    [isInvalid, t],
  );

  return !editingPanelId ? (
    <>
      <PageFooter>
        {!isRevising && hasManagePreQualPermission && (
          <Button
            iconLeft="trash"
            variant="danger-outline"
            onClick={deleteQuestionnaireModal.open}
          >
            {t('deleteTemplate')}
          </Button>
        )}
        <Flex sx={{ gap: 2 }} ml="auto">
          {onBack && (
            <Button iconLeft="arrow-left" onClick={onBack}>
              {t('back', { ns: 'general' })}
            </Button>
          )}
          {onContinue && (
            <Button iconRight="arrow-right" onClick={onContinue}>
              {t('continue', { ns: 'general' })}
            </Button>
          )}
          {onPublish && (
            <Tooltip content={publishTooltip}>
              <Box as="span">
                <Button
                  variant="success"
                  iconRight="arrow-right"
                  onClick={onPublish}
                  disabled={publishDisabled || !hasManagePreQualPermission}
                >
                  {t('publish')}
                </Button>
              </Box>
            </Tooltip>
          )}
        </Flex>
      </PageFooter>
      <Dialog
        heading={t('dialog.confirmTemplateDeletion.heading')}
        body={(
          <>
            <Text mb={2}>{t('dialog.confirmTemplateDeletion.body')}:</Text>
            <Text fontWeight={500}>{template.summary.name}</Text>
            <MessageBlock variant="warn" mt={3}>
              {t('dialog.confirmTemplateDeletion.warning')}
            </MessageBlock>
          </>
        )}
        okButtonText={t('dialog.confirmTemplateDeletion.okButtonText')}
        okButtonVariant="danger"
        isOpen={deleteQuestionnaireModal.isOpen}
        onOk={() => deleteTemplateMutation({
          companyId: currentCompanyId,
          templateId: template._id,
        })}
        onCancel={deleteQuestionnaireModal.close}
        showCloseIcon
      />
    </>
  ) : (
    null
  );
};
