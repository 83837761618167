import { memo, Fragment } from 'react';
import { Box } from 'rebass/styled-components';
import { Action, ActionType } from '@deepstream/common/rfq-utils';
import { HistoryAction } from './HistoryAction';
import { useScrollToBottomOnChange } from './useScrollToBottom';
import { useExchange } from '../useExchange';

const { NONE } = ActionType;

const shouldHideAvatar = (index: number, actions: Action[]) => {
  const [current, previous] = actions.slice().reverse().slice(actions.length - 1 - index);

  return (
    previous?.value === NONE &&
    current.value === NONE &&
    previous.user._id === current.user._id &&
    previous.companyId === current.companyId
  );
};

export const ExchangeTimeline = memo(({
  canRespond,
  canRenderLatestResponseStageLink,
}: {
  canRespond: boolean;
  canRenderLatestResponseStageLink?: boolean;
}) => {
  const exchange = useExchange();
  const timelineScrollRef = useScrollToBottomOnChange(exchange?.history.length);

  return (
    <Box
      px={3}
      flex={1}
      overflowY="auto"
      ref={timelineScrollRef}
    >
      {exchange.history.map((action, index, actions) => (
        <Fragment key={index}>
          <HistoryAction
            canRespond={canRespond}
            historySlice={exchange.history.slice(0, index + 1)}
            action={action}
            canRenderLatestResponseStageLink={canRenderLatestResponseStageLink}
            hideAvatar={shouldHideAvatar(index, actions)}
            my="12px"
          />
        </Fragment>
      ))}
    </Box>
  );
});
