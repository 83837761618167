import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import * as draft from './draft';
import * as validation from './validation';
import { DefaultRow } from '../PropertyList';

export const ValidationPropertyRow = ({
  fieldName,
  useShowValidationErrors = draft.useShowValidationErrors,
  ...props
}) => {
  const theme = useContext(ThemeContext);
  const showValidationErrors = useShowValidationErrors();
  const { error } = validation.useError(fieldName);

  return (
    <DefaultRow
      {...props}
      style={{
        backgroundColor: showValidationErrors && error
          ? theme.colors.errorBackground
          : undefined,
      }}
    />
  );
};
