import { useTranslation } from 'react-i18next';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Dialog, DialogProps } from '@deepstream/ui-kit/elements/popup/Dialog';

export const AsyncFileDownloadDialog = (props: Pick<DialogProps, 'isOpen' | 'onOk'>) => {
  const { t } = useTranslation();

  return (
    <Dialog
      heading={t('asyncFileDownloadDialog.header', { ns: 'translation' })}
      body={(
        <MessageBlock variant="info" mt={0}>
          {t('asyncFileDownloadDialog.body', { ns: 'translation' })}
        </MessageBlock>
      )}
      okButtonText={t('general.done', { ns: 'translation' })}
      okButtonVariant="primary"
      style={{ content: { width: '375px', minWidth: '375px' } }}
      {...props}
    />
  );
};
