import styled from 'styled-components';
import { Text, Flex, Box } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { BidStatus, StageType } from '@deepstream/common/rfq-utils';
import { supplierBidStatuses } from '@deepstream/common/rfq-utils/statusConfigs';
import { first } from 'lodash';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { useCurrentCompanyId } from './currentCompanyId';
import { useLiveRfqStructure, RfqIdProvider, RecipientIdProvider } from './useRfq';
import * as rfx from './rfx';
import { BidProgressDetails } from './BidProgressDetails';

const Heading = styled(Text)`
  font-size: ${props => props.theme.fontSizes[4]}px;
  font-weight: 500;
  line-height: normal;
`;

const SubText = styled(Text)`
  color: ${props => props.theme.colors.subtext};
  font-size: ${props => props.theme.fontSizes[2]}px;
`;

export const BidProgressPanel = ({ rfqId }: { rfqId: string }) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { t } = useTranslation();

  const { data: rfxStructure } = useLiveRfqStructure({
    rfqId,
    recipientId: currentCompanyId,
    currentCompanyId,
  });

  const hasOnlyAuctionStage = rfxStructure?.stages.length === 1 && first(rfxStructure?.stages)?.type === StageType.AUCTION;
  const rfxBid = rfxStructure?.bidById[currentCompanyId];

  if (!rfxStructure || !rfxBid || !rfxBid.progress || !rfxBid.status || hasOnlyAuctionStage) {
    return null;
  }

  const { icon } = supplierBidStatuses[rfxBid.status];

  const label = t(`request.supplierBidStatus.${rfxBid.status}`);

  return (
    <RfqIdProvider rfqId={rfqId}>
      <RecipientIdProvider recipientId={currentCompanyId}>
        <rfx.StructureProvider structure={rfxStructure}>
          <Panel mb="20px" p="20px">
            <Flex
              alignItems={['flex-start', null, 'center']}
              flexDirection={['column', null, 'row']}
            >
              <Box flex="1 1 auto" mb={[3, null, 0]} mr={[0, null, '30px']}>
                <Flex alignItems="center" mb={1}>
                  {icon && (
                    <Icon
                      icon={icon.value as IconProps['icon']}
                      color={icon.color}
                      regular={icon.isRegular}
                      fontSize={3}
                      mr={1}
                    />
                  )}
                  <Heading>
                    {label}
                  </Heading>
                </Flex>
                <SubText>
                  {[BidStatus.IN_PROGRESS, BidStatus.INTEND_TO_BID].includes(rfxBid.status) ? (
                    t('request.supplierBidProgress.description.incomplete')
                  ) : rfxBid.status === BidStatus.COMPLETE ? (
                    t('request.supplierBidProgress.description.complete')
                  ) : rfxBid.status === BidStatus.WAITING_FOR_BUYER ? (
                    t('request.supplierBidProgress.description.waitingForBuyer')
                  ) : (
                    null
                  )}
                </SubText>
              </Box>
              {rfxBid.progress && (
                <Flex flex={[0, 0, '0 1 270px']} justifyContent="flex-end">
                  <BidProgressDetails progress={rfxBid.progress} />
                </Flex>
              )}
            </Flex>
          </Panel>
        </rfx.StructureProvider>
      </RecipientIdProvider>
    </RfqIdProvider>
  );
};
