import { map } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { Text } from 'rebass/styled-components';
import { withProps } from '@deepstream/ui-utils/withProps';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { CompanyNamesByIdFieldValue, CompanyNamesFieldValue, DeadlineFieldValue, FieldsCell, MIN_CELL_HEIGHT } from '../../../FieldsCell';
import { SentRequestStatus } from '../../../SentRequestStatus';
import { Table } from '../../../Table';
import { BasicTableStyles } from '../../../TableStyles';
import { DEFAULT_TABLE_PAGE_SIZES } from '../../../constants';
import { PublicRequestOverview } from '../../../types';

const columns = [{ id: 'custom' }];

export const PublicRequestTitle: React.FC<CellProps<PublicRequestOverview>> = ({
  row,
}) => {
  const { description: details, subject } = row.original;

  const description =
    details?.length > 300
      ? `${details.slice(0, 300)} ...`
      : details;

  return (
    <Stack gap="6px" mb={2}>
      {subject}
      <Text fontWeight="400" fontSize={1}>
        {description}
      </Text>
    </Stack>
  );
};

export const PublicRequestsTable = ({
  navigateToRequest,
  requests,
  isPublicRoute,
}: {
  navigateToRequest: (request: PublicRequestOverview) => void;
  requests: PublicRequestOverview[];
  isPublicRoute: boolean;
}) => {
  const { t } = useTranslation('translation');

  const CustomRowCells = React.useMemo(
    () => {
      const fields = [
        {
          label: t('requests.from'),
          accessor: (request: PublicRequestOverview) =>
            isPublicRoute
              ? map(request.senders, 'name')
              : map(request.senders, '_id'),
          FieldValueComponent: isPublicRoute
            ? CompanyNamesFieldValue
            : CompanyNamesByIdFieldValue,
        },
        {
          label: t('deadline', { ns: 'general' }),
          accessor: 'firstStageBidDeadline',
          FieldValueComponent: DeadlineFieldValue,
        },
      ];

      return withProps(
        FieldsCell,
        {
          fields,
          titleAccessor: 'subject',
          RowIconComponent: SentRequestStatus,
          TitleComponent: PublicRequestTitle,
        },
      );
    },
    [t, isPublicRoute],
  );

  return (
    <BasicTableStyles hoverBackgroundColor="lightGray6">
      <Table
        columns={columns}
        isPaginated
        pageSizes={DEFAULT_TABLE_PAGE_SIZES}
        hideHeader
        CustomRowCells={CustomRowCells}
        hasStaticHeight={false}
        enforcePagination
        minCellHeight={MIN_CELL_HEIGHT}
        smallPageControls
        onRowClick={navigateToRequest}
      />
    </BasicTableStyles>
  );
};
