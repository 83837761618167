import * as React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import {
  Company,
  getOrderedAddressResponse,
  isDateTimeQuestion,
  QuestionExchangeDefinition,
  QuestionType,
  SectionType,
  RfxSection,
} from '@deepstream/common/rfq-utils';
import { find, flatMap, isArray, isEmpty, isFinite, isString, omitBy, propertyOf } from 'lodash';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { RfqUtils } from '@deepstream/common';
import { localeFormatNumber } from '@deepstream/utils';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Clamp } from '@deepstream/ui-kit/elements/text/Clamp';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { KeyCode } from '@deepstream/ui-utils/KeyCode';
import { ComparisonGrid } from '@deepstream/ui-kit/grid/core/ComparisonGrid';
import { DefaultComparisonGridStyles } from '@deepstream/ui-kit/grid/core/ComparisonGridStyles';
import {
  ColumnData,
  DataCellProps,
  GridDataAndCommands,
  isExpandable,
  RowData,
} from '@deepstream/ui-kit/grid/core/utils';
import { stopEvent } from '@deepstream/ui-utils/domEvent';
import { DEFAULT_FROZEN_LEFT_COLUMN, DEFAULT_FROZEN_LEFT_COLUMN_IDS } from '@deepstream/ui-kit/grid/core/constants';
import { ExchangeSnapshot, RfxStructure } from '../../../../types';
import {
  twoLinesRowLineClamp,
  exchangeLineClampByRowHeight,
  exchangeLineClampByActiveRowHeight,
  RowHeight,
  rowHeightMeasurements,
} from '../rowHeight';
import { getExchangeSnapshotStatus } from '../../../../exchangeStatus';
import { CurrencyAmount, CurrencyCodeProvider } from '../../../../ui/Currency';
import { UseExchangeModalStateProps } from '../../../../useExchangeModalState';
import * as rfx from '../../../../rfx';
import { StatusIcon } from '../../../../ExchangeStatusCell';
import { PreWrap } from '../../../../PreWrapCell';
import { CommentCount } from '../CommentCount';
import { InlineLabel } from '../InlineLabel';
import { createRecipientHeaderCell } from '../createRecipientHeaderCell';
import { useCurrentCompanyId } from '../../../../currentCompanyId';
import { useRfqId } from '../../../../useRfq';
import { useCurrentUserLocale } from '../../../../useCurrentUser';
import { useNavigate } from '../../../../tanstackRouter';
import { legacyRequestSentRecipientBidIndexRoute, requestSentRecipientBidIndexRoute } from '../../../../AppRouting';
import { SectionCellContent } from '../SectionCellContent';

type SectionWithQuestionSubRows = RfxSection & {
  lotId?: string | null;
  lotName?: string | null;
  subRows: QuestionExchangeDefinition[];
};

const extendedDateTimeFactory = new RfqUtils.ExtendedDateTimeFactory();

const LockedAnswer = ({ exchange }: { exchange: ExchangeSnapshot }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <StatusIcon type={exchange.def.type} status={exchange.status} currentCompanyGroup="buyer" mr={2} />
      <Box as="span" color="subtext">
        <Icon icon="lock" mr={1} fontSize="12px" />
        {t('general.locked')}
      </Box>
    </Box>
  );
};

const QuestionResponse = ({ exchange, maxLines }) => {
  const { t } = useTranslation('general');
  const def = exchange.def as QuestionExchangeDefinition;
  const locale = useCurrentUserLocale();

  const response = exchange.latestResponse;

  return response.noAnswer ? (
    <Box
      as="span"
      color="subtext"
      width="100%"
    >
      {t('notApplicable')}
    </Box>
  ) : isDateTimeQuestion(def) && response.value ? (
    <>{extendedDateTimeFactory.fromRepresentation(response.value).toString({ locale })}</>
  ) : isArray(response.value) && !isEmpty(response.value) ? (
    <Clamp lines={maxLines} style={{ flexGrow: 1 }}>
      {response.value.join('; ')}
    </Clamp>
  ) : isString(response.value) && response.value ? (
    <Clamp lines={maxLines} style={{ flexGrow: 1 }}>
      {def.questionType === QuestionType.LONG_TEXT ? <PreWrap>{response.value}</PreWrap> : response.value}
    </Clamp>
  ) : def.questionType === QuestionType.PRICE && response.value.currencyCode && isFinite(response.value.amount) ? (
    <Box as="span" width="100%">
      <CurrencyCodeProvider code={response.value.currencyCode}>
        <CurrencyAmount value={response.value.amount} />
      </CurrencyCodeProvider>
    </Box>
  ) : isFinite(response.value) ? (
    <Box as="span" width="100%">
      {localeFormatNumber(response.value, { locale })}
    </Box>
  ) : def.questionType === QuestionType.ADDRESS && !isEmpty(omitBy(response.value, isEmpty)) ? (
    <Clamp lines={maxLines} style={{ flexGrow: 1 }}>
      <PreWrap>
        {getOrderedAddressResponse(response.value, locale).join('\n')}
      </PreWrap>
    </Clamp>
  ) : (
    <Box
      as="span"
      color="subtext"
      width="100%"
    >
      <EmDash />
    </Box>
  );
};

const Answer = ({ exchange, maxLines }: { exchange: ExchangeSnapshot; maxLines: number }) => {
  const exchangeStatus = getExchangeSnapshotStatus(exchange);

  return (
    <Flex flex={1}>
      <StatusIcon
        type={exchange.def.type}
        status={exchangeStatus}
        currentCompanyGroup="buyer"
        sx={{ top: '1px' }}
        mr={2}
      />
      <QuestionResponse exchange={exchange} maxLines={maxLines} />
    </Flex>
  );
};

const createFirstColumnCell = (t: TFunction) =>
  ({ row }: DataCellProps<Company, QuestionExchangeDefinition, SectionWithQuestionSubRows>) => {
    if (isExpandable(row)) {
      return (
        <SectionCellContent
          icon="question"
          sectionName={row.original.name}
          // @ts-expect-error ts(2322) FIXME: Type 'string | null | undefined' is not assignable to type 'string | undefined'.
          lotName={row.original.lotName}
        />
      );
    }

    return (
      <Flex flexDirection="column" justifyContent="space-between" sx={{ height: '100%' }}>
        <Clamp lines={twoLinesRowLineClamp[row.height]}>
          {row.original.description}
        </Clamp>
        {row.height === rowHeightMeasurements[RowHeight.SHORT] ? (
          null
        ) : (
          <Box fontSize="9px" fontWeight="normal" letterSpacing="0.3px">
            <InlineLabel>{t('general.type')}</InlineLabel>
            {t(`request.question.questionType.${row.original.questionType}`)}
            <InlineLabel ml={2}>{t('general.required')}</InlineLabel>
            <Icon
              icon={row.original.isRequired ? 'check' : 'times'}
              fontSize="8px"
            />
          </Box>
        )}
      </Flex>
    );
  };

const createDataCell = ({
  exchanges,
  openExchangeModal,
  t,
}: {
  exchanges: ExchangeSnapshot[];
  openExchangeModal: (
    row: RowData<QuestionExchangeDefinition, SectionWithQuestionSubRows>,
    column: ColumnData<Company>
  ) => void;
  t: TFunction;
}) => ({ row, column, isActive }: DataCellProps<Company, QuestionExchangeDefinition, SectionWithQuestionSubRows>) => {
  if (isExpandable(row)) {
    return null;
  }

  const recipientId = column.original._id;
  const exchangeDefId = row.original._id;

  const exchange = find(exchanges, { _id: exchangeDefId, recipientId });

  if (!exchange) {
    return null;
  }

  return (
    <Flex justifyContent="space-between" alignItems="flex-start">
      {exchange.isLocked ? (
        <LockedAnswer exchange={exchange} />
      ) : (
        <Answer
          exchange={exchange}
          maxLines={isActive ? (
            exchangeLineClampByActiveRowHeight[row.height]
          ) : (
            exchangeLineClampByRowHeight[row.height]
          )}
        />
      )}
      <Box
        minWidth="28px"
        ml={3}
        textAlign="right"
        className="focus-descendant-primary"
      >
        {isActive ? (
          <Tooltip content={t('request.exchange.openExchange') as string}>
            <Icon
              icon="expand"
              sx={{ cursor: 'pointer', right: '2px' }}
              onClick={() => openExchangeModal(row, column)}
            />
          </Tooltip>
        ) : (
          <CommentCount exchange={exchange} />
        )}
      </Box>
    </Flex>
  );
};

export const useRowData = (structure: RfxStructure) => {
  const { t } = useTranslation('translation');

  return React.useMemo(() => {
    const { pages, settings, lots, lotById, sectionById } = structure;

    const allSectionIds = flatMap(
      pages,
      page => page.sections,
    );

    const questionSections: RfxSection[] = allSectionIds
      .map(propertyOf(sectionById))
      .filter(section => section.type === SectionType.QUESTION);

    const { areLotsEnabled } = settings;

    const sectionsWithSubRows = questionSections.map(section => ({
      ...section,
      lotId: !areLotsEnabled
        ? null
        : section.lotIds?.[0] || 'general',
      lotName: !areLotsEnabled ? (
        null
      ) : section.lotIds?.[0] ? (
        // @ts-expect-error ts(18048) FIXME: 'section.lotIds' is possibly 'undefined'.
        `${t('request.lot', { count: 1 })} ${lots.findIndex(lot => lot._id === section.lotIds[0]) + 1} – ${lotById[section.lotIds[0]].name}`
      ) : (
        t('request.generalRequirement_other')
      ),
      subRows: section.exchangeDefIds
        .map(propertyOf(structure.exchangeDefById))
        .filter(exchangeDef => !exchangeDef.isObsolete) as QuestionExchangeDefinition[],
    }));

    return sectionsWithSubRows.filter(({ subRows }) => !isEmpty(subRows));
  }, [structure, t]);
};

export const QuestionsComparisonGrid = ({
  rowData,
  columnData,
  subRowHeight,
  collapsedRowIds,
  setCollapsedRowIds,
  setExchangeModalProps,
  gridRef,
}: {
  rowData: ReturnType<typeof useRowData>;
  columnData: Company[];
  subRowHeight: number;
  collapsedRowIds: string[];
  setCollapsedRowIds: React.Dispatch<React.SetStateAction<string[]>>;
  setExchangeModalProps: (props: UseExchangeModalStateProps) => void;
  gridRef?: React.MutableRefObject<any>;
}) => {
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const navigate = useNavigate();
  const rfqId = useRfqId();
  const structure = rfx.useStructure();
  const exchanges = rfx.useExchanges();

  const openExchangeModal = React.useCallback((
    row: RowData<
      QuestionExchangeDefinition,
      SectionWithQuestionSubRows
    >,
    column: ColumnData<Company>,
  ) => {
    setExchangeModalProps({
      exchangeId: row.original._id,
      recipientId: column.original._id,
    });
  }, [setExchangeModalProps]);

  const FrozenHeaderCell = React.useMemo(() => {
    return createRecipientHeaderCell({
      structure,
      showBidStatus: true,
      navigateToBidPage: (recipientId: string) => {
        navigate({
          to: structure.newFeaturesDisabled
            ? legacyRequestSentRecipientBidIndexRoute.to
            : requestSentRecipientBidIndexRoute.to,
          params: { currentCompanyId, rfqId, recipientId },
        });
      },
    });
  }, [currentCompanyId, navigate, rfqId, structure]);

  const FirstColumnCell = React.useMemo(() => createFirstColumnCell(t), [t]);

  const DataCell = React.useMemo(
    () => createDataCell({ exchanges, openExchangeModal, t }),
    [exchanges, openExchangeModal, t],
  );

  const handleDataCellKeyboardAction = React.useCallback((
    data: GridDataAndCommands<
      (typeof columnData)[number],
      (typeof rowData)[number]['subRows'][number],
      (typeof rowData)[number]
    >,
    event: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    // @ts-expect-error ts(18047) FIXME: 'data.activeCellIndices' is possibly 'null'.
    const row = data.rows[data.activeCellIndices.rowIndex];
    // @ts-expect-error ts(18047) FIXME: 'data.activeCellIndices' is possibly 'null'.
    const column = data.columns[data.activeCellIndices.columnIndex];

    if ([KeyCode.ENTER, KeyCode.SPACE].includes(event.code as KeyCode)) {
      stopEvent(event);

      // @ts-expect-error ts(2345) FIXME: Argument of type 'RowData<QuestionExchangeDefinition, { lotId: string | null; lotName: string | null; subRows: QuestionExchangeDefinition[]; _id: string; name: string; ... 11 more ...; liveVersion?: RfxEvaluationSection | undefined; } | { ...; } | { ...; } | { ...; }> | null' is not assignable to parameter of type 'RowData<QuestionExchangeDefinition, SectionWithQuestionSubRows>'.
      openExchangeModal(row, column);
    }
  }, [openExchangeModal]);

  const columnDataWithFirstColumn = React.useMemo(() => {
    return [
      DEFAULT_FROZEN_LEFT_COLUMN as any,
      ...columnData,
    ];
  }, [columnData]);

  const frozenLeftColumnIds = DEFAULT_FROZEN_LEFT_COLUMN_IDS;

  return (
    <DefaultComparisonGridStyles>
      <ComparisonGrid
        gridRef={gridRef}
        columnData={columnDataWithFirstColumn}
        rowData={rowData}
        subRowHeight={subRowHeight}
        collapsedRowIds={collapsedRowIds}
        setCollapsedRowIds={setCollapsedRowIds}
        onDataCellKeyboardAction={handleDataCellKeyboardAction}
        FrozenHeaderCell={FrozenHeaderCell}
        FirstColumnCell={FirstColumnCell}
        DataCell={DataCell}
        frozenLeftColumnIds={frozenLeftColumnIds}
      />
    </DefaultComparisonGridStyles>
  );
};
