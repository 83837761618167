import { CellProps } from 'react-table';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { RfqAttachment } from './ExchangeModal/AttachmentLink';
import { ContractAttachment } from './modules/Contracts/ContractAttachment';
import { ExchangeSnapshot } from './types';
import { ContextType, useHooks } from './useHooks';

export const LatestDocumentCell = ({
  row: { original: exchange },
  column,
  truncate = true,
}: CellProps<ExchangeSnapshot> & { column: { showTooltip?: boolean } }) => {
  const { contextType } = useHooks();

  const isContract = contextType === ContextType.CONTRACT;

  return (
    <>
      {exchange.latestAttachment ? (
        isContract ? (
          <ContractAttachment truncate={truncate} attachment={exchange.latestAttachment} showTooltip={column.showTooltip} />
        ) : (
          <RfqAttachment truncate={truncate} attachment={exchange.latestAttachment} showTooltip={column.showTooltip} />
        )
      ) : (
        <EmDash />
      )}
    </>
  );
};

export const LatestBuyerDocumentCell = ({
  row: { original: exchange },
  truncate = true,
}: CellProps<ExchangeSnapshot>) => {
  const { contextType } = useHooks();

  const isContract = contextType === ContextType.CONTRACT;

  return (
    <>
      {exchange.latestBuyerAttachment ? (
        isContract ? (
          <ContractAttachment truncate={truncate} attachment={exchange.latestBuyerAttachment} />
        ) : (
          <RfqAttachment truncate={truncate} attachment={exchange.latestBuyerAttachment} />
        )
      ) : (
        <EmDash />
      )}
    </>
  );
};

export const LatestSupplierDocumentCell = (props: CellProps<ExchangeSnapshot>) => {
  const {
    row: { original: exchange },
    truncate = true,
  } = props;
  const { contextType } = useHooks();

  const isContract = contextType === ContextType.CONTRACT;

  return (
    <>
      {exchange.latestSupplierAttachment ? (
        isContract ? (
          <ContractAttachment truncate={truncate} attachment={exchange.latestSupplierAttachment} />
        ) : (
          <RfqAttachment truncate={truncate} attachment={exchange.latestSupplierAttachment} />
        )
      ) : (
        <EmDash />
      )}
    </>
  );
};
