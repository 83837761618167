import { isNil } from 'lodash';
import { BidIntentionStatus, BidOutcomeStatus, BidStatus, UniversalBidStatus } from './types';

/**
 * Active means interest was expressed at some point. It is easier to define this in terms of what it means for a
 * bid to be inactive.
 * The domain definition of inactive means that implicitly(BidStatus.NO_RESPONSE) or
 * explicitly(BidStatus.NO_INTEND_TO_BID, BidStatus.WITHDRAWN) the supplier does not want to engage in the Rfx.
 */
export const isBidActive = (status: BidStatus): boolean =>
  ![BidStatus.NO_RESPONSE, BidStatus.NO_INTEND_TO_BID, BidStatus.WITHDRAWN].includes(status);

export const getUniversalBidStatus = (status?: BidStatus | null | undefined): UniversalBidStatus | null | undefined => {
  if (isNil(status)) return status;

  switch (status) {
    case BidStatus.INTEND_TO_BID:
    case BidStatus.IN_PROGRESS:
    case BidStatus.WAITING_FOR_BUYER:
    case BidStatus.COMPLETE:
      return UniversalBidStatus.ACTIVE;
    default: {
      return status as unknown as UniversalBidStatus;
    }
  }
};

export const getBidIntentionStatus = (bid: { status: BidStatus | null, prevStatus: BidStatus | null }) => {
  const status = [BidStatus.AWARDED, BidStatus.UNSUCCESSFUL].includes(bid.status!)
    ? bid.prevStatus
    : bid.status;

  if (!status) {
    return BidIntentionStatus.NO_RESPONSE;
  }

  switch (status) {
    case BidStatus.NO_RESPONSE:
    case BidStatus.DELIVERED:
    case BidStatus.OPENED:
    case BidStatus.BOUNCED:
    case BidStatus.INTERESTED:
      return BidIntentionStatus.NO_RESPONSE;
    case BidStatus.NO_INTEND_TO_BID:
      return BidIntentionStatus.DECLINED_TO_BID;
    case BidStatus.WITHDRAWN:
      return BidIntentionStatus.BID_WITHDRAWN;
    default:
      return BidIntentionStatus.BIDDING;
  }
};

export const getBidOutcomeStatus = (bid: { status: BidStatus | null }) => {
  if (bid.status === BidStatus.AWARDED) {
    return BidOutcomeStatus.AWARDED;
  }

  if (bid.status === BidStatus.UNSUCCESSFUL) {
    return BidOutcomeStatus.UNSUCCESSFUL;
  }

  return BidOutcomeStatus.PENDING;
};
