import { Trans, useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';
import { AwardScenario, ExchangeSource, Live, PageType, SectionType } from '@deepstream/common/rfq-utils';
import { chatExchangeTypes } from '@deepstream/common/exchangesConfig';
import { find } from 'lodash';
import { Clamp2 } from '@deepstream/ui-kit/elements/text/Clamp';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import * as rfx from '../../../rfx';
import * as lotPagesLayout from '../Live/lotPagesLayout';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useLiveRfqStructure, useRecipientId, useRfqId } from '../../../useRfq';
import { LoadingPanel } from '../../../ui/Loading';
import { ErrorPanel } from '../../../ui/ErrorMessage';
import { awardScenarioIconProps } from '../../../draft/SummaryLotsPanel';
import { useRequestRecipientNavigation } from '../../../appNavigation';
import { AwardedMessageSectionContent } from './AwardedMessageSectionContent';
import { AwardedLineItemsSectionContent } from './AwardedLineItemsSectionContent';

const OverviewSection = () => {
  const { t } = useTranslation('translation');
  const currentCompanyGroup = rfx.useCurrentCompanyGroup();

  return (
    <lotPagesLayout.Section heading={t('request.awardSummary.overview.heading')}>
      <Box mt="6px">
        {t(`request.awardSummary.overview.description.${currentCompanyGroup}`)}
      </Box>
    </lotPagesLayout.Section>
  );
};

const AwardScenarioSection = () => {
  const { t } = useTranslation('translation');
  const { meta, settings } = rfx.useStructure<Live>();
  const currentCompanyGroup = rfx.useCurrentCompanyGroup();

  // We render legacy requests without outcomeDetails the same way as newer
  // requests with AwardScenario.REQUEST_LEVEL_AWARD.
  const awardScenario = meta.outcomeDetails?.awardScenario || AwardScenario.REQUEST_LEVEL_AWARD;
  const { icon, regular } = awardScenarioIconProps[awardScenario];

  return (
    <lotPagesLayout.Section heading={t('request.summary.awardScenario', { count: 1 })}>
      <lotPagesLayout.OrderedListContainer>
        <lotPagesLayout.TitledListItem
          icon={icon}
          isIconRegular={regular}
          key={awardScenario}
          title={t(`request.awardScenarios.awardScenarios.${awardScenario}.label`)}
          body={awardScenario === AwardScenario.REQUEST_LEVEL_AWARD ? (
            t(`request.awardScenarios.awardScenarios.${awardScenario}.postAwardDescription.${currentCompanyGroup}`)
          ) : awardScenario === AwardScenario.LINE_LEVEL_AWARD && settings.canSplitAwards ? (
            t(`request.awardScenarios.awardScenarios.${awardScenario}.postAwardSplitDescription`)
          ) : (
            t(`request.awardScenarios.awardScenarios.${awardScenario}.postAwardDescription`)
          )}
          pt={3}
          pb={0}
          sx={{ borderBottom: 0 }}
        />
      </lotPagesLayout.OrderedListContainer>

    </lotPagesLayout.Section>
  );
};

const AwardedLotsSection = () => {
  const { t } = useTranslation('translation');
  const { meta, lots } = rfx.useStructure<Live>();

  // @ts-expect-error ts(2339) FIXME: Property 'awardDecisionByLotId' does not exist on type 'Pick<AwardOrCloseRequestDetails, "awardScenario" | "awardDecisionByLotId" | "awardDecisionByExchangeId" | "splitDecisionByExchangeId"> | undefined'.
  const { awardDecisionByLotId } = meta.outcomeDetails;

  return (
    <lotPagesLayout.Section heading={t('request.awardSummary.awardedLots.heading')}>
      <lotPagesLayout.OrderedListContainer>
        {lots.map((lot, index) => {
          return awardDecisionByLotId[lot._id] ? (
            <lotPagesLayout.TitledListItem
              key={lot._id}
              // @ts-expect-error ts(2322) FIXME: Type '"ban" | null' is not assignable to type '"function" | "angle-left" | "angle-right" | "archive" | "arrow-down" | "arrow-down-short-wide" | "arrow-left" | "arrow-right" | "arrow-right-arrow-left" | "arrow-right-to-bracket" | ... 193 more ... | undefined'.
              icon={lot.isObsolete ? 'ban' : null}
              title={`${t('request.lot_one')} ${index + 1} – ${lot.name}`}
              titleColor="text"
              body={
                <Box color="text">
                  <Clamp2 lines={1}>
                    {lot.description}
                  </Clamp2>
                </Box>
              }
              pt={3}
              pb={3}
            />
          ) : (
            null
          );
        })}
      </lotPagesLayout.OrderedListContainer>
    </lotPagesLayout.Section>
  );
};

const AwardedLineItemsSection = () => {
  const { t } = useTranslation('translation');
  const { canAwardOrCloseRfx } = rfx.useRfxPermissions();

  return (
    <lotPagesLayout.Section heading={t('request.awardSummary.awardedLineItems.heading')}>
      {canAwardOrCloseRfx ? (
        <AwardedLineItemsSectionContent />
      ) : (
        <>
          <Box mt="12px">
            <Icon icon="eye-slash" mr={2} />
            {t('request.awardSummary.awardedLineItems.noAccessInfo1')}
          </Box>
          <Box mt="6px" mb={3}>
            {t('request.awardSummary.awardedLineItems.noAccessInfo2')}
          </Box>
        </>
      )}
    </lotPagesLayout.Section>
  );
};

const MessageSection = ({ awardedChatExchangeId }: { awardedChatExchangeId: string }) => {
  const { t } = useTranslation('translation');
  const { pages, sectionById } = rfx.useStructure<Live>();
  const navigation = useRequestRecipientNavigation();
  const currentCompanyGroup = rfx.useCurrentCompanyGroup();

  const chatPage = pages.find(page => page.type === PageType.CHAT);

  return (
    <lotPagesLayout.Section heading={t('general.message')}>
      <Box mt="12px">
        {t(`request.awardSummary.message.info1.${currentCompanyGroup}`)}
      </Box>
      <Box mt="6px" mb={3}>
        <Trans
          i18nKey={`request.awardSummary.message.info2.${currentCompanyGroup}`}
          ns="translation"
          components={{
            a: (
              <lotPagesLayout.TanstackInlineLink
                {...chatPage ? (
                  navigation.getBidLinkProps(null, null, chatPage._id, awardedChatExchangeId)
                ) : (
                  navigation.getMessagesLinkProps(find(sectionById, { type: SectionType.CHAT })?._id)
                )}
              />
            ),
          }}
        />
      </Box>
      <AwardedMessageSectionContent awardedChatExchangeId={awardedChatExchangeId} />
    </lotPagesLayout.Section>
  );
};

const MessagingSection = () => {
  const { t } = useTranslation('translation');
  const { sectionById } = rfx.useStructure<Live>();
  const currentCompanyGroup = rfx.useCurrentCompanyGroup();
  const navigation = useRequestRecipientNavigation();

  return (
    <lotPagesLayout.Section heading={t('request.awardSummary.messaging.heading')}>
      <Box mt="12px">
        <Trans
          t={t}
          i18nKey={`request.awardSummary.messaging.info.${currentCompanyGroup}`}
          ns="translation"
          components={{
            a: (
              <lotPagesLayout.TanstackInlineLink
                {...navigation.getMessagesLinkProps(find(sectionById, { type: SectionType.CLARIFICATIONS })?._id)}
              />
            ),
          }}
        />
      </Box>
    </lotPagesLayout.Section>
  );
};

const RequestAwardSummaryContent = () => {
  const { exchangeDefById } = rfx.useStructure<Live>();
  const { meta } = rfx.useStructure<Live>();
  const canReadMessagesPage = rfx.useCanReadPageOfType(PageType.CHAT);

  const awardedChatExchange = Object.values(exchangeDefById).find(exchangeDef => (
    chatExchangeTypes.includes(exchangeDef.type) &&
    (exchangeDef as any).source === ExchangeSource.BID_AWARDED
  ));

  return (
    <lotPagesLayout.ContentWrapper>
      <OverviewSection />
      <AwardScenarioSection />
      {meta.outcomeDetails?.awardScenario === AwardScenario.LOT_LEVEL_AWARD ? (
        <AwardedLotsSection />
      ) : meta.outcomeDetails?.awardScenario === AwardScenario.LINE_LEVEL_AWARD ? (
        <AwardedLineItemsSection />
      ) : (
        null
      )}
      {!canReadMessagesPage ? (
        null
      ) : awardedChatExchange ? (
        <MessageSection awardedChatExchangeId={awardedChatExchange._id} />
      ) : (
        <MessagingSection />
      )}
    </lotPagesLayout.ContentWrapper>
  );
};

/*
* Recipient-specific award summary page.
*/
export const RequestRecipientAwardSummary = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const recipientId = useRecipientId();
  const rfqId = useRfqId();
  const { t } = useTranslation('translation');

  const { data: structure, isLoading, isError, isSuccess } =
    useLiveRfqStructure({ rfqId, recipientId, currentCompanyId });

  return isLoading ? (
    <LoadingPanel />
  ) : isError ? (
    <ErrorPanel error={t('errors.unexpected')} />
  ) : isSuccess && structure ? (
    <rfx.StructureProvider structure={structure}>
      <rfx.StateProvider isLive>
        <RequestAwardSummaryContent />
      </rfx.StateProvider>
    </rfx.StructureProvider>
  ) : (
    null
  );
};
