import { useTranslation } from 'react-i18next';
import { Box } from 'rebass/styled-components';

type Props = {
  count: number;
  selectedCount?: number;
};

export const RequestCount = ({ count, selectedCount }: Props) => {
  const { t } = useTranslation();

  return (
    <Box fontSize={2}>
      {/*
       // @ts-expect-error ts(18048) FIXME: 'selectedCount' is possibly 'undefined'. */}
      {selectedCount > 0
        ? t('requests.requestCountSelected', { count, selectedCount })
        : t('requests.requestCount', { count })}
    </Box>
  );
};
